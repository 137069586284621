import Vue, { VueConstructor } from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import Login from "@/views/auth/Login.vue";
import PasswordReset from "../views/auth/PasswordReset.vue";
import RecoverPassword from "../views/auth/RecoverPassword.vue";
import { BootstrapVue } from "bootstrap-vue";
import { AuthService } from "@/Services/auth/Auth.Service";
import Layout from "../components/Atom/Layout/Layout.vue";
import Logout from "@/views/auth/Logout.vue";
import Dash from "../components/Atom/Layout/Dashboard.vue";
import { coldManagerRoutes } from "@/views/ColdManager/Module/ColdManager.route";
import { eaglecargoRoutes } from "@/views/EagleCargo/Modulos/eaglecargo.route";
import { administrativoRoutes } from "../views/Adminstrativo/Module/administrativo.route";
import { coletivosRoutes } from "../views/Coletivos/Modulos/coletivos.route";
import { jornadaRoutes } from "../views/Jornada/Modulos/jornada.route";
import { manutencaoRoutes } from "../views/Manutencao/Modulos/manutencao.route";
import { roteirizadorRoutes } from "../views/Roteirizador/Modulos/roteirizador.route";
import { abastecimentosRoutes } from "../views/Abastecimentos/Module/Abastecimentos.route";
import { cadeadosRoutes } from "@/views/Cadeados/Modulos/cadeados.route";
import { GestaoDesempenhoRoutes } from "@/views/GestaoDesempenho/Modulos/GestaoDesempenho.route";
import { mapaRoutes } from "@/views/Mapa/modulos/mapa.route";
import { mapaPlusRouter } from "@/views/MapaPlus/Modulos/mapaplus.route";
import { performanceRouter } from "@/views/Performance/Modulos/performance.route";
import { checkpointRoutes } from "@/views/Checkpoint/Modulos/checkpoint.route";
import Vuex from "@/store/index";
Vue.use(BootstrapVue);
Vue.use(VueRouter);

/**
    * checa se o sistema esta autenticado. caso esteja retorna para o Dashboard. utilizado no Login
    @example routes:[{path:'/login', beforeEnter:ifAuthenticated}]
    @author Daniel Bruch
*/
const ifNotAuthenticated = (_to: any, _from: any, next: any) => {
    if (!new AuthService().isAuthenticated()) {
        next();
        return;
    }
    next("/dashboard");
};

/**
    * efetua a verificação se esta autenticado em caso de forçar a URL no navegador. nao verifica em navegação. 
    * caso nao esteja autenticado, vai para a tela de Login
    @example routes:[{path:'/route', beforeEnter:ifAuthenticated}]
    @author Daniel Bruch
*/
export const ifAuthenticated = (_to: any, _from: any, next: any) => {
    if (new AuthService().isAuthenticated()) {
        next();
        return;
    }
    next("/login");
};

export const IfMenu = (_to: any, _from: any, next: any) => {
    if (new AuthService().isAuthenticated()) {
        next("/dashboard");
        return;
    }
    next("/login");
};

/**
    * aqui o bagulho fica louco. 
    * o sistema de rotas define qual Modulo esta sendo usado, e os componentes. 
    * sempre que for utilizar um Child, definir o component dentro do content, caso contrario, utilizar no defaul
    @example { path: '/dashboard', name: 'Dashboard',beforeEnter: ifAuthenticated  , component: Dash },// sistema single page, single routerview e single component
    @example{path: '/administrativo', name: 'administrativo-home', beforeEnter: ifAuthenticated, meta: { Module: "administrativo" }, components: { default: Layout}, children: []},
    // sistema MultiPage(child) com Menu Lateral(Layout) e Single Component(sem Paths internos)
    @example{path: '/telemetria', name: 'telemetria-home', beforeEnter: ifAuthenticated, meta: { requiresAuth: true, Module: "telemetria" }, components: { default: Layout }, 
    children: [{ path: 'relatorios/acionamento/portas', meta: {  Module: "telemetria" }, components: {content: Mapa } }]},
    // sistema MultiPage(child) com Menu Lateral(Layout) e MultiCOmponents Component(Path dentro do slot Child)
    @example {path: '/telemetria', name: 'telemetria-home', beforeEnter: ifAuthenticated, meta: { requiresAuth: true, Module: "telemetria" }, components: { default: Layout }, children: [
    { path: '/relatorios', meta: {  Module: "telemetria" }, children:[
    { path: '/acionamento', meta: {  Module: "telemetria" }, children:[
    { path: '/portas', meta: {  Module: "telemetria" }, components: {content: Mapa }
    ]]}]},
    // sistema MultiPage(child) com Menu Lateral(Layout) e MultiCOmponents Component multi Child(Path contendo um child que contem um ou mais paths com child, todos dentro do slot Child)
    @author Daniel Bruch
*/
export const routes: RouteConfig[] = [
    {
        path: "/",
        name: "index",
        component: Home,
        meta: { canLoad: true },
        beforeEnter: IfMenu,
    },
    {
        path: "/login",
        name: "login",
        component: Login,
        beforeEnter: ifNotAuthenticated,
        meta: { canLoad: true },
    },
    {
        path: "/passwordReset",
        name: "PasswordReset",
        component: PasswordReset,
        beforeEnter: ifNotAuthenticated,
        meta: { canLoad: true },
    },
    {
        path: "/RecoverPassword/:token/:newuser?",
        name: "RecoverPassword",
        component: RecoverPassword,
        meta: { canLoad: true },
    },
    {
        path: "/logout",
        name: "logout",
        component: Logout,
        beforeEnter: ifAuthenticated,
        meta: { canLoad: true },
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        beforeEnter: ifAuthenticated,
        component: Dash,
        meta: {
            breadcrumbName: "",
            icon: "android-chrome-512x512.png",
            canLoad: true,
            requiresAuth: true,
        },
    },
    administrativoRoutes,
    coletivosRoutes,
    coldManagerRoutes,
    jornadaRoutes,
    roteirizadorRoutes,
    manutencaoRoutes,
    abastecimentosRoutes,
    cadeadosRoutes,
    mapaRoutes,
    eaglecargoRoutes,
    mapaPlusRouter,
    performanceRouter,
    GestaoDesempenhoRoutes,
    checkpointRoutes,
    { path: "/:another/*", name: "404", component: Home },
];

/*routes.push(telemetriaRoutes)
routes.push(administrativoRoutes)
routes.push(coletivosRoutes)*/
//routes.push({ path: '/:another/*', name: '404', component: Home })
const router = new VueRouter({
    //mode: 'history',
    routes,
});

/**
  * efetua a verificação se esta autenticado durante as navegações. nao verifica caso voce
* largue a URL no navegador.
  * caso nao esteja autenticado, vai para a tela de Login
  @example routes:[{path:'/route', beforeEnter:ifAuthenticated}]
  @author Daniel Bruch
*/
router.beforeEach((to: any, from: any, next: any) => {
    let documentTitle = `${
        to.meta.breadcrumbName ? to.meta.breadcrumbName : ""
    } - Eagle Track`.replace(/^\s-\s/, "");
    const link = document.querySelector("[rel='icon']");
    if (link) {
        link.setAttribute("href", to.meta.icon ?? "android-chrome-512x512.png");
    }
    if (to.params.title) {
        documentTitle += ` - ${to.params.title}`;
    }
    document.title = documentTitle;

    if (to.matched.some((record: any) => record.meta.requiresAuth)) {
        if (new AuthService().isAuthenticated()) {
            if (new AuthService().usucheckpoint()) {
                if (to.meta.Module === "checkpoint") {
                    return next();
                } else {
                    return next({ name: "checkpoint" });
                }
            } else {
                return next();
            }
        }
        return next("/login");
    }
    return next();
});
export default router;

// router.afterEach(() => {
//   Vuex.dispatch('refresh')
// })
