<template>
    <panelEagle id='eventos' :loading="loadingPanel">
        <div class="col-md-12 row">
            <div class="col-md-8 nopadding">
                <tituloPage
                :icon='mdiCalendarEdit'
                titulo='Eventos'></tituloPage>
            </div>
            <div class="col-md-4 divButtonsBasic nopadding">
                <basicEIG
                    :loading           ='carregando'
                    @exportarRelatorio ='exportarRel'
                    @gerarRelatorio    ='gerarRelatorio'
                    :disabledDropdown  ="!relatorio.length"
                    :disabled          ="$v.$invalid">
                    <!-- relatorio -->
                </basicEIG>
            </div>
        </div>
        <slide>
            <slot slot='filters'>
                <div class="col-md-12"><hr></div>
                <div class="col-sm-12 row nopadding">
                    <div class="col-md-3 col-sm-6 nopadding">
                        <range 
                            titulo="Intervalo*"
                            opens='rigth'
                            name="dataEvento"
                            @changeInput='mudaData'>
                        </range>
                    </div>
                    <div class="col-md-3 col-sm-6 nopadding">
                        <selectAll
                            nameForRadio="empresas"
                            :hasSelectAll="true"
                            :isMultiple='true'
                            :labels='labelSelectEmpresas'
                            :optionsArray='optSelectEmpresas'
                            :selected="selectedEmpresa"
                            @changeSelect='mudaSelectEmpresas'>
                        </selectAll>
                    </div>
                    <div class="col-md-3 col-sm-6 nopadding">
                        <selectAll
                            nameForRadio  ='colaboradores'
                            firstSelected ="C"
                            :hasSelectAll ='true'
                            :isMultiple   ='true'
                            :optionsArray ='optSelectGrupoColab'
                            :labels       ='labelsSelectColaboradores'
                            @changeCheck  ='mudaCheckColab'
                            @changeSelect ='mudaFiltroColab'
                            ref           ='C' 
							:loading      ="carregandoColaboradores"
                            :disabled     ="$v.valueSelectEmpresa.$invalid">
                        </selectAll>
                    </div>
                    <div class="col-md-3 col-sm-6 nopadding">
                        <selectAll
                            nameForRadio  ='eventos'
                            :hasSelectAll ='true'
                            :isMultiple   ='true'
                            :optionsArray ='optSelectEventos'
                            :labels       ='labelSelectEventos'
                            @changeSelect ='mudaEvento'
                            ref           ='E' 
                            :disabled     ="$v.valueSelectEmpresa.$invalid">
                        </selectAll>
                    </div>
                </div>
            </slot>
        </slide>
        <div class="col-md-12"><hr></div>
        <tableRel :htmlRel='relatorio'>
            <slot slot='thead'>
                <th>Evento</th>
                <th>Periodo</th>
                <th>Total de Horas</th>
            </slot>
            <slot slot="tbody">
                <statusInfo typeBar="tr" :statusBar="statusbar"></statusInfo>
            </slot>
        </tableRel>
    </panelEagle>
</template>
<script>
import {mdiCalendarEdit } from '@mdi/js'
import { required } from 'vuelidate/lib/validators'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { conectionError } from '@/Services/Helpers/swellHeper.js'
import { mapGetters } from 'vuex'
import basicEIG   from '@/components/Atom/Buttons/BasicButtonsRelatoriosEIG'
import range  from '@/components/Atom/Datas/InputRangeNoLimits'
import slide  from '@/components/Atom/SlideUpAndDown/SlideUpAndDown'    
import statusInfo from '@/components/Atom/StatusInformation/StatusInformation'
import Vue from 'vue'
export default Vue.extend({
	name:'eventos',
	components:{
		'panelEagle' : require('@/components/Atom/Panel/PanelEagle').default,
		'tituloPage' : require('@/components/Atom/Header/Titulo').default,
		basicEIG,
		range,
		'selectAll'  : require('@/components/Atom/Select/SelectAll').default,
		slide,
		tableRel   : require('@/components/Atom/Table/TableRelatorio').default,
		statusInfo,
	},
	validations:{
		valueSelectEmpresa: { required },
		valueColab: { required },
	},
	data(){
		return{
			mdiCalendarEdit:mdiCalendarEdit,
			// opt:
			optSelectGrupoColab    : [],
			optSelectEventos       : [],
			optSelectEmpresas      : [],
			// labels:
			labelSelectEmpresas:[
				{indexDFH: 'EM',  description: 'Empresas*'}
			],
			labelsSelectColaboradores:[
				{indexDFH: 'C',  description: 'Colaboradores*'},
				{indexDFH: 'GC', description: 'Grupos*'},
			],
			labelSelectEventos:[
				{indexDFH:'E',    description: 'Eventos'}
			],
			// values:
			valueSelectEmpresa      : [],
			valueData               : '',
			valueEvento             : '',
			// links:
			linkRelatorio    : '/controle/jornada/relatorios/eventos/relatorio',
			linkEventos      : '/controle/jornada/relatorios/eventos/eventos',
			linkcolabEgrupo : '/controle/jornada/relatorios/eventos/colabgrupo',
			linkRel          : '/controle/jornada/relatorios/eventos/exportar',
			// aux
			relatorio               : '',
			info                    : '',
			colaboradores           : [],
			gruposColabs            : [],
			selecao                 : 'C',
			valueColab              : [],
			dataTable               : '',
			statusbar               : 'info',
			carregandoColaboradores : false,
			carregandoEventos       : false,
			carregando              : [
				false,
				false,
				false
			],
			loadingPanel            : false,
			selectedEmpresa         : [],
		}
	},
	methods:{
		...mapGetters(['getMaster']),

		async gerarRelatorio(){
			this.relatorio = ''
			if(this.verificaCampos()){
				try{
					this.statusbar = 'info'
					this.loadingPanel = true
					var obj = this.criaObjeto()
					await new HttpRequest()
						.Post(this.linkRelatorio, obj)
						.then((data)=>{
							if(data.data.info.length > 0){
								this.statusbar = 'info'
								this.relatorio = data.data.rel
								this.info = data.data.info
							}else{
								this.statusbar = 'error'
							}
						})
				}catch{
					conectionError()
				}finally{
					this.loadingPanel = false
				}
			}
		},
		verificaCampos(){
			return this.valueSelectEmpresa.length > 0 &&
					this.valueColab.length > 0
		},
		criaObjeto(value=''){
			var obj
			if(value){
				obj={
					tipo    : value,
					data    : this.info,
					html    : this.relatorio
				}
				return obj
			}
			obj={
				data    : this.valueData,
				opt     : this.selecao,
				codigos : this.valueColab,
				eventos : this.valueEvento
			}
			return obj
		},
		mudaCheckColab(value){
			if(value ==='C'){
				this.optSelectGrupoColab = this.colaboradores
			}else{
				this.optSelectGrupoColab = this.gruposColabs
			}
			this.selecao = value
		},
		mudaFiltroColab(value){
			this.limpaCampos()
			this.valueColab = value
		},
		mudaData(value){
			this.valueData = value
			this.limpaCampos()
		},
		mudaEvento(value){
			this.valueEvento = value
			this.limpaCampos()
		},
		mudaSelectEmpresas(value){
			this.$refs.C.clearAll()
			this.$refs.E.clearAll()
			this.valueSelectEmpresa = value
			this.limpaCampos()
			if(this.valueSelectEmpresa.length > 0){
				this.carregarColabGrupos()
				return
			}
		},
		limpaCampos(){
			this.relatorio = ''
		},
		async carregarColabGrupos(){
			var obj = {codigos:this.valueSelectEmpresa}
			this.carregandoColaboradores = true
			new HttpRequest().Post(this.linkcolabEgrupo, obj).then((data)=>{
				this.colaboradores   = data.data.colabs.C
				this.gruposColabs    = data.data.grupos.GC
				this.carregandoColaboradores = false
			}).finally(()=>{
				this.verificaSelecao()
				this.carregandoColaboradores = false
			})
		},
		verificaSelecao(){
			if(this.selecao === 'C'){
				this.optSelectGrupoColab = this.colaboradores
				return
			}
			this.optSelectGrupoColab = this.gruposColabs
			return
		},
		async exportarRel(value){
			if(this.verificaCampos()){
				var root =  process.env.VUE_APP_ROOT
				var obj = this.criaObjeto(value)
				if(value === 'pdf'){
					this.carregando = [true, false, false]
				}else if(value === 'csv'){
					this.carregando = [false, false, true]
				}else{
					this.carregando = [false, true, false]
				}
				await new HttpRequest().Post(this.linkRel, obj).then((data)=>{
					window.open(root+'/'+data.data.local)
					this.carregando = [false, false, false]
				})
			}
		},
	},
	async mounted(){
		this.optSelectEmpresas = await new EmpresasService().Get()
		if(!this.getMaster()){
			this.selectedEmpresa = this.optSelectEmpresas
		}
		this.carregandoEventos = true
		await new HttpRequest().Post(this.linkEventos).then((data)=>{
			this.optSelectEventos =  data.data.eventos
		});
		this.carregandoEventos = false
	}
})
</script>
<style lang="scss" scoped>
.somepadding{
    padding: 2px 10px 10px 10px
}
.tr-fantasma{
	background-color: white;
	color: white;
	height: 20px;
}
</style>
