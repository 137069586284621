export var linha = {
  lidescricao:'',
  livelocidademedia:'',
  lidistancia:'',
  litempoestimado:'',
  liseguirordeminsercao:'',
  licliente:'',
  lirotaosrm:'',
  livalidador:'',
  liitinerario:'',
  liprincipal:false,
  licodigoprincipal:'',
  livelocidademediainfo:'',
  licor:'',
  liprefixo:'',
  liatrasotolerado:'',
  listringrota:'',
  lidescitinerario:'',
  licategoria:'',
  limanual:'',
  litipo:'',
  liappintegracao:false
}
export const preselect ={
  preEmpresa:[],
  preCategoria:[],
  preTipo:[],
  preLinhaPrincipal:[],
  preDiaDaSemana:[],
}

export var listagem = {
  optSelectCalendario:[],
  titulosTableFeriado:[
    {'number':'fcdescricao', 'name':'Descrição'},
    {'number':'clfantasia', 'name':'Empresa'  },
    {'number':'fcdata', 'name':'Data'     }
  ],
  feriado:{
    fcdata:'',
    fcdescricao:'',
    fccliente:'',
    fccalendariooriginal:'',
    fccalendariosubstituto:''
  },
  tableCategoriasTitles:[
    {number:'lcdescricao',  name:'Descrição'},
    {number:'clfantasia',      name:'Empresa'},
  ],

  titlesTableCalendarios : [
    {'number': 'licdescricao' ,     'name': 'Descrição'},
    {'number': 'licdiasdisponivel', 'name': 'Disponivel'},
    {'number': 'licdatainicio' ,    'name': 'Inicio'},
    {'number': 'licdatafim' ,       'name': 'Fim'},
  ],


  calendario : {
    licdescricao      :'',
    licdatainicio     :'',
    licdatafim        :'',
    liccliente        :'',
    licdiasdisponivel :[]
  },

  optDiasDasemana:[
    { text: 'Dom', value: '0' },
    { text: 'Seg', value: '1' },
    { text: 'Ter', value: '2' },
    { text: 'Qua', value: '3' },
    { text: 'Qui', value: '4' },
    { text: 'Sex', value: '5' },
    { text: 'Sab', value: '6' },
  ]
}

export var optSeletores = {
  optSelectVeiculo       : [],
  optSelectEmpresa       : [],
  optSelectCategoria     : [],
  optSelectBuscaPontos   : [],
  optSelectCalendario    : [],
  optSelectDiaDaSemana   : [
    {'description':'Todos'          , 'value':'9'},
    {'description':'Segunda a sexta', 'value':'8'},
    {'description':'Segunda-Feira'  , 'value':'1'},
    {'description':'Terça-Feira'    , 'value':'2'},
    {'description':'Quarta-Feira'   , 'value':'3'},
    {'description':'Quinta-Feira'   , 'value':'4'},
    {'description':'Sexta-Feira'    , 'value':'5'},
    {'description':'Sábado'         , 'value':'6'},
    {'description':'Domingo'        , 'value':'0'},
  ],
  optSelectLinhaPrincipal: [],
  optSelectTipo          : [],
  optionsCheckBox:[
    {text:'Pontos de refência', value:'P'},
    {text:'Pontos de coleta',   value:'C'},
    {text:'Pontos de entrega',  value:'E'},
    {text:'Regiões',            value:'reg'},
  ],
  tableTitles:[
    {number:'hrdiasemana',      name:'Dia da Semana'},
    {number:'hrhorario',        name:'Horário'},
    {number:'hrvelocidademedia',name:'Velocidade'},
    {number:'tempoEstimado',    name:'Tempo Estimado'},
    {number:'hrcalendario',     name:'Calendário'}
  ],
  
  objHorario:{
    hrdiasemana:'',
    hrhorario:'',
    hrvelocidademedia:'',
    hrcalendario:'' 
  },
  pontosLinha:[],
  allRegioes :[],
}


