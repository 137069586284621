/* eslint-disable no-alert */
import axios from "axios";
import { RouteModel } from "../route/RouteModel";
import { RouterService } from "../route/Route.Service";
import { Crypto } from "./Crypto.Service";
import { EmpresasService } from "./Empresas.service";
import { DateTime } from "luxon";

/**
 * Efetua todos os tratamentos referentes a User e Token
 * @author Daniel Bruch
 */
export class AuthService {
    TOKEN_KEY = "34G1ETR4CK_4936";
    private urlLogin = process.env.VUE_APP_ROOT_API + "/login";
    private urlNovoUser = process.env.VUE_APP_ROOT_API + "/novo/user";
    private urlSalvaSenha = process.env.VUE_APP_ROOT_API + "/salva/senha";
    private urlRecuperar = process.env.VUE_APP_ROOT_API + "/resetpassword";
    private urlValidaToken = process.env.VUE_APP_ROOT_API + "/valida/token";
    private urlMudaValidacaoUser = process.env.VUE_APP_ROOT_API + "/muda/validacao";
    private urlAtualiza = process.env.VUE_APP_ROOT_API + "/atualiza/permissoes";

    constructor() {}
    /**
     * @description Retorna os dados enviados durante a autenticação. Caso não
     * tenha sido efetuado ainda, retorna null.
     * @param none
     * @returns Token or null
     * @author Daniel Bruch
     */
    public getToken(): Token | null {
        try {
            var token = new Crypto().Get(this.TOKEN_KEY);
            return new Token({
                token: token.token,
                name: token.name,
                username: token.username,
                email: token.email,
                client: token.client,
                expires_dt: token.expires_dt,
                expireIn: token.expireIn,
                urlImage: token.urlImage,
                idUser: token.idUser,
                idClient: token.idClient,
                usuMaster: token.usuMaster,
                expireInObjt: token.expireInObjt,
                perfil: token.perfil,
                usucheckpoint: token.usucheckpoint,
                usuadmcheckpoint: token.usuadmcheckpoint,
            });
        } catch (e) {
            return null;
        }
    }

    /**
     * @description verifica se o usuário é do
     * tipo checkpoint para decidir parte da navegação.
     * @returns usuário do tipo checkpoint
     */
    public usucheckpoint(): boolean {
        const token = this.getToken();
        if (token) return token.usucheckpoint;
        else return false;
    }

    /**
     * @description verifica se o usuário é do
     * tipo ADM checkpoint para mostrar menu Cadastro -> Usuários.
     * @returns usuário do tipo checkpoint
     */
    public admCheckpoint(): boolean {
        const token = this.getToken();
        if (token) return token.usuadmcheckpoint ?? false;
        else return false;
    }

    /**
     * @description Retorna os dados de usuario enviados durante a
     * autenticação. Caso nao tenha sido efetuado ainda, retorna null.
     * @param none
     * @returns AcessToken or null
     * @author Daniel Bruch
     * @deprecated utilizar Vuex.Store para funcionar com o Watch
     */
    public getUser(): UserData | null {
        const token = this.getToken();
        if (token) {
            return new UserData({
                username: token.username,
                name: token.name,
                client: token.client,
                email: token.email,
                urlImage: token.urlImage,
                idUser: token.idUser,
                idClient: token.idClient,
                usuMaster: token.usuMaster,
                perfil: token.perfil,
                usucheckpoint: token.usucheckpoint,
                usuadmcheckpoint: token.usuadmcheckpoint,
            });
        }
        return null;
    }

    /**
     * @description Efetua o Login com o servidor, utilizando a variavel "url"
     * para rota.
     * @param username string
     * @param password string
     * @returns .{status: boolean}
     * @author Daniel Bruch
     */
    public async login(username: string, password: string) {
        var param = {
            grant_type: "password",
            username: username,
            password: password,
        };
        var Token: Token;
        var key = this.TOKEN_KEY;
        let config = { headers: { "Content-Type": "application/json" } };
        return axios
            .post<AuthViewModel>(this.urlLogin, param, config)
            .then(async function (result) {
                // let palavraChave:any = result.data.data
                if (result.data.status) {
                    Token = result.data.data!.token!;
                    Token.username = username;
                    Token.expires_dt = new Date(Date.now() + Token.expireIn!);
                    new Crypto().GenerateKey(Token.token);
                    new Crypto().Commit(key, JSON.stringify(Token));
                    new Crypto().Commit(key, JSON.stringify(Token));
                    // new Crypto().Commit('palavraChave',
                    // 	JSON.stringify(palavraChave.palavrasChaveModulos))
                    new EmpresasService().Refresh();
                    //vuex.dispatch('refresh')
                    new RouterService().SetRoutes(result.data.data!.routes!);
                    new RouterService().SetModulos(result.data.data!.modulos!);
                    let versao = result.data.data!.versao;
                    versao = versao ? versao : "";
                    localStorage.setItem("versaoRecente", versao);
                    return { status: true };
                }
                return { status: false, mensagem: result.data };
            })
            .catch(function (result) {
                return {
                    status: false,
                    mensagem: {
                        message: "Falha na conexão, tente novamente em instantes.",
                    },
                };
            });
    }

    /**
     * @description Função para atualizar os dados de permissão dos módulos e
     * telas quando editado o perfil itens.
     * @param  { number }     cliente - código do cliente
     * @param  { string }     perfil  - código do perfil do usuário
     * @param  { string }     master  - se o usuário é master ou não
     * @return { Object.statusError } - Status da requisição
     * @return { Object.data }        - Resposta do servidor
     * @author Vitor Hugo 🐨
     */
    public async atualizaPermissoes(
        cliente: number,
        perfil: string,
        master: string,
        idUser: number
    ) {
        var param = {
            cliente: cliente,
            perfil: perfil,
            usumaster: master,
            idUser: idUser,
        };
        let config = { headers: { "Content-Type": "application/json" } };
        return axios
            .post(this.urlAtualiza, param, config)
            .then(function (result) {
                new RouterService().SetRoutes(result.data!.routes!);
                new RouterService().SetModulos(result.data!.modulos!);
                let versao = result.data!.versao;
                versao = versao ? versao : "";
                localStorage.setItem("versaoRecente", versao);
                return {
                    status: result.status,
                    message: "Perfil atualizado com sucesso",
                    data: result.data,
                };
            })
            .catch(function (error) {
                return {
                    status: error.response.status,
                    message: error.response.data.message,
                    data: {},
                };
            });
    }

    /**
     * @description Essa função valida se o token de acesso para a recuperação
     * de senha é valido. Utiliza a variavel { urlValidaToken } para saber o
     * caminho da rota.
     * @param { String } token - token de acesso da pagina
     * @return { Object.statusError } - Status da requisição
     * @return { Object.data } - Resposta do servidor
     * @author Lucas Eduardo
     */
    public async ResetMail(email: string) {
        var param = {
            email: email,
        };
        let config = { headers: { "Content-Type": "application/json" } };
        return axios
            .post(this.urlRecuperar, param, config)
            .then(function (resposta) {
                return {
                    status: resposta.status,
                    message: resposta.data.message,
                    data: JSON.parse(resposta.data.retornoApiEmail),
                };
            })
            .catch(function (error) {
                if (error.response) {
                    return {
                        status: error.response.status,
                        message: error.response.data.message,
                        data: {},
                    };
                }
            });
    }

    /**
     * @description Essa função valida se o token de acesso para a recuperação
     * de senha é valido. Utiliza a variavel { urlValidaToken } para saber o
     * caminho da rota.
     * @param { String } token - token de acesso da pagina
     * @return { Object.statusError } - Status da requisição
     * @return { Object.data } - Resposta do servidor
     * @author Lucas Eduardo
     */
    public async validaToken(token: string) {
        var param = {
            token: token,
        };
        let config = { headers: { "Content-Type": "application/json" } };
        return axios
            .post(this.urlValidaToken, param, config)
            .then(function (response) {
                return {
                    data: response.data,
                    statusError: 200,
                };
            })
            .catch(function (error) {
                if (error.response) {
                    return {
                        statusError: error.response.status,
                        data: error.response.data,
                    };
                }
            });
    }

    /**
     * @description Essa função valida se os tokens de acesso para criar
     * usuario são validos. Utiliza a variavel { urlNovoUser } para saber o
     * caminho da rota.
     * @param { String } token - token de acesso da pagina
     * @param { String } newUser - token de novo usuario
     * @return { Object.statusError } - Status da requisição
     * @return { Object.data } - Resposta do servidor
     * @author Lucas Eduardo
     */
    public async validaTokenNovoUser(token: string, newUser: string) {
        var param = {
            token: token,
            newUser: newUser,
        };
        let config = { headers: { "Content-Type": "application/json" } };
        return axios
            .post(this.urlNovoUser, param, config)
            .then(function (response) {
                return {
                    data: response.data,
                    statusError: 200,
                };
            })
            .catch(function (error) {
                if (error.response) {
                    return {
                        statusError: error.response.status,
                        data: error.response.data,
                    };
                }
            });
    }

    /**
     * @description Essa função modifica a a validação do email verificado
     * para true. Utiliza a variavel { urlMudaValidacaoUser } para saber o
     * caminho
     * @param { String } email - Email do usuario
     * @return { Object.statusError } - Status da requisição
     * @return { Object.data } - Resposta do servidor
     * @author Lucas Eduardo
     */
    alteraValidacao(email: string) {
        var param = {
            email: email,
        };
        let config = { headers: { "Content-Type": "application/json" } };
        return axios
            .post(this.urlMudaValidacaoUser, param, config)
            .then(function (response) {
                return {
                    data: response.data,
                    statusError: 200,
                };
            })
            .catch(function (error) {
                if (error.response) {
                    return {
                        statusError: error.response.status,
                        data: error.response.data,
                    };
                }
            });
    }

    /**
     * @description Responsavel por fazer a requisição de uma senha nova, ela
     * precisa do token e do email para validações no backend, utiliza a
     * variavel { urlSalvaSenha } para saber o caminho da request.
     * @param { String } token - token de acesso da pagina, para fazer a
     * validação no backend
     * @param { String } email - email do usuario, para fazer a validação no
     * backend
     * @param { String } senha - nova senha do usuario
     * @return { Object.status } - Status da requisição
     * @return { Object.message } - Resposta do servidor
     * @author Lucas Eduardo
     */
    public async salvaNovaSenha(token: string, email: string, senha: string) {
        var param = {
            token: token,
            email: email,
            senha: senha,
        };
        let config = { headers: { "Content-Type": "application/json" } };
        return axios
            .post(this.urlSalvaSenha, param, config)
            .then(function (resposta) {
                return {
                    status: resposta.status,
                    message: resposta.data.message,
                };
            })
            .catch(function (error) {
                if (error.response) {
                    return {
                        status: error.response.status,
                        message: error.response.data.message,
                    };
                }
            });
    }

    /**
     * @description Efetua o logout do sistema
     * @param none
     * @return none
     * @author Daniel Bruch
     */
    public logout(): any {
        new Crypto().Clear();
    }

    /**
     * @description Verifica se o usuario atual ainda esta com um token valido.
     * @param none
     * @return boolean
     * @author Daniel Bruch
     */
    public isAuthenticated(): boolean {
        return !this.expired();
    }

    /**
     * @description Verifica o tempo de sessão do usuário, pega a diferença
     * entre a data de expiração do token e a data atual e se
     * for menor que 12 (estipulado que o usuário pode ficar
     * 12 horas logado no sistema) vais deslogar o usuário.
     * @param none
     * @return boolean
     * @author Lucas Eduardo, comentado e alterado pelo Vitor 🐨
     */
    private expired(): boolean {
        const token = this.getToken();
        if (token?.expireInObjt) {
            var tempoToken = DateTime.fromJSDate(new Date(token?.expireInObjt.date));
            var tempoAgr = DateTime.now();
            var diffTempo: any = tempoToken
                .diff(tempoAgr, ["hours", "minutes", "second"])
                .toObject();
            if (diffTempo.hours <= 12) {
                return true;
            }
        }
        return token == null || token.expires_dt!.getMilliseconds < Date.now().valueOf;
    }
}

/**
 * @description Objeto received durante a autenticação
 * @author Daniel Bruch
 */
export class AuthViewModel {
    status!: boolean;
    data?: {
        token?: Token;
        routes?: RouteModel;
        modulos?: RouteModel;
        versao?: string;
    };
    error?: {
        code: number;
        message: string;
    };
}

/**
 * @description Objeto Token do sistema
 * @author Daniel Bruch
 */
export class Token {
    public token!: string;
    public expireIn?: number; // seconds
    public expireInObjt?: any; // objt
    public name!: string; // Nome do Usuario
    public username!: string; // Login(userName)
    public email!: string;
    public client!: string; // Nome do cliente
    public expires_dt?: Date; // Data e hora de Expire do Token
    public urlImage!: string;
    public idUser!: number;
    public idClient!: number;
    public usuMaster!: string;
    public perfil!: number;
    public usucheckpoint: boolean;
    public usuadmcheckpoint: boolean;
    constructor(option: {
        token: string;
        expireIn?: number;
        expireInObjt?: any;
        name: string;
        username: string;
        email: string;
        client: string;
        expires_dt?: Date;
        urlImage: string;
        idUser: number;
        idClient: number;
        usuMaster: string;
        perfil: number;
        usucheckpoint: boolean;
        usuadmcheckpoint: boolean;
    }) {
        this.token = option.token;
        this.expireIn = option.expireIn;
        this.expireInObjt = option.expireInObjt;
        this.name = option.name;
        this.username = option.username;
        this.email = option.email;
        this.client = option.client;
        this.expires_dt = option.expires_dt;
        this.urlImage = option.urlImage;
        this.idUser = option.idUser;
        this.idClient = option.idClient;
        this.usuMaster = option.usuMaster;
        this.perfil = option.perfil;
        this.usucheckpoint = option.usucheckpoint;
        this.usuadmcheckpoint = option.usuadmcheckpoint;
    }
}

/**
 * @description Objeto de Dados de usuario
 * @author Daniel Bruch
 */
export class UserData {
    public username!: string;
    public email!: string;
    public client!: string;
    public name!: string;
    public urlImage?: string;
    public idUser: number;
    public idClient: number;
    public usuMaster: string;
    public perfil: number;
    public usucheckpoint: boolean;
    public usuadmcheckpoint: boolean;
    constructor(option: {
        username: string;
        email: string;
        client: string;
        name: string;
        urlImage?: string;
        idUser: number;
        idClient: number;
        usuMaster: string;
        perfil: number;
        usucheckpoint: boolean;
        usuadmcheckpoint: boolean;
    }) {
        this.username = option.username;
        this.email = option.email;
        this.client = option.client;
        this.name = option.name;
        this.urlImage = option?.urlImage || "images/logosClientes/laMVTeQCqguv.png";
        this.idUser = option.idUser;
        this.idClient = option.idClient;
        this.usuMaster = option.usuMaster;
        this.perfil = option.perfil;
        this.usucheckpoint = option.usucheckpoint;
        this.usuadmcheckpoint = option.usuadmcheckpoint;
    }
}
