/**
    Este componente é para retrair os filtros de uma página para melhor compreensão de um relatorio

    -> Propriedades:
        1- isOpen - Boolean - Se esta aberto inicialmente ou não
        2- duration - Number - o tempo de demora para abrir ou fechar em milisseconds

    -> Slots:
        1- filters - Slot para colocar os filtros que vão ser retraidos

    -> Eventos:
        Sem eventos
 */

<template>
    <div class="col-sm-12 nopadding divDontPrint">
        <slide-up-down class="" :active="active" :use-hidden="true" :duration="duration">
            <slot name="filters"></slot>
            <slot></slot>
        </slide-up-down>
        <div id="botaoTeste" class="col-sm-12">
            <slot name="descricao">
                <simpleButton width="100%" :icon="icon" type="white" id="buttonOpenClose" event="click" @click="openClose">
                </simpleButton>
            </slot>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
import SlideUpDown from 'vue-slide-up-down'
import {mdiChevronDoubleDown, mdiChevronDoubleUp} from '@mdi/js'
export default Vue.extend({
    components:{
        SlideUpDown,
        'simpleButton': require('../Buttons/SimpleButton').default,
    },
    props:{
        isOpen:{
            type: Boolean,
            required: false,
            default: true
        },
        duration:{
            type: Number,
            required: false,
            default: 800,
        },
    },
    data(){
        return{
            active: this.isOpen,
            icon: this.isOpen ? mdiChevronDoubleUp : mdiChevronDoubleDown,
        }
    },
    methods:{
        openClose(){
            this.active = !this.active;
            this.icon = this.active ? mdiChevronDoubleUp : mdiChevronDoubleDown;
        }
    }
})
</script>

<style lang="scss">
    #botaoTeste{
        #buttonOpenClose{
            border: none;
            background-color: #f7f7f7;
            span{
                &::before{
                    font-size: 9px!important;
                }
            }
            line-height: 5px!important;
        }

        #buttonOpenClose:focus{
            outline-color: #4285F4;
        }
    }
</style>
