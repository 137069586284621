<template>
    <panelEagle id="horariosLinha"
        :loading="loadingPanel">
        <div class="col-sm-12 nopadding ">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiClockFast" titulo="Horários linhas"></tituloPage>
                </div>
                <div class="col-sm-6 divButtonsBasic divDontPrint">
                    <basicButtonsRelatoriosEIG
                        :disabledDropdown="!Object.keys(dadosHorariosLinhas).length"
                        @exportarRelatorio ="exportarRelatorio" 
                        @gerarRelatorio    ="gerarRelatorio"
                        :loading           ='loadingExportar'
                        :disabled          ="$v.$invalid">
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12"><hr></div>
                    <div class="col-sm-12 justpaddingbottom row">
                        <div class="col-sm-4 nopadding">
                            <selectAll 
                                @changeSelect ="SelectEmpresas" 
                                :isMultiple   ="true" 
                                nameForRadio  ="radiosEmpresas" 
                                :labels       ="labelsSelectEmpresa" 
                                :optionsArray ="optionsSelectEmpresa" 
								:selected     ="selectedEmpresa"
                                firstSelected ="E" 
                                :hasSelectAll ="true">
                            </selectAll>
                        </div>
                        <div class="col-sm-4 nopadding">
                            <selectAll 
                                @selectCriado ="selectCriado" 
                                @changeCheck  ="changeCheckLinhas" 
                                @changeSelect ="SelectLinhas" 
                                :isMultiple   ="true" 
                                nameForRadio  ="radiosLinhasLinhasPrincipais" 
                                :labels       ="labelsSelectLinhas" 
                                :optionsArray ="optionsSelectLLP" 
                                firstSelected ="L" 
                                :loading      ="carregando"
                                :hasSelectAll ="true"
                                :disabled          ="$v.empresasArray.$invalid">
                            </selectAll>
                        </div>
                        <div class="col-sm-4 nopadding">
                            <selectAll 
                                @changeSelect ="SelectDiasDeSemana" 
                                :isMultiple   ="false" 
                                nameForRadio  ="radiosDiasSemana" 
                                :labels       ="labelsSelectDiasSemana" 
                                :optionsArray ="optionsSelectDS" 
                                firstSelected ="ST" 
                                :hasSelectAll ="true">
                            </selectAll>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
            <div class="col-sm-12 divHrDeBaixo"><hr></div>
        </div>
        <tableRelatorio >
            <slot slot="tbody">
                <statusInformation 
                    v-if="tamanhoArrayLinhas == 0"
                    typeBar="tr" 
                    :statusBar="statusbar">
                </statusInformation>
                <template v-for="(linha, l) in dadosHorariosLinhas">
                    <tr :key="l+'_linha'"
                        class="backColorTdPDF stayTh">
                        <th colspan="4" class="nopadding">
                            <span class="newbadge">{{l}}</span>
                        </th>
                    </tr>

                    <template v-for="(diasLinha, d) in linha">
                        <tr :key="d+'_diaLinha'+l">
                            <td colspan="4" class="thDados colorTurn">
                                <span>{{d}}</span>
                            </td>
                        </tr>
                        <tr v-for="(horaLinha, hl) in diasLinha"
                            :key="hl+'_horaLinha'+l"
                            class="dadosRelatorio">
                                <div class="col-sm-12 horarioRelatorio">Horário de início: {{hl}}</div>
                                <div v-for="(dadosLinha, dl) in horaLinha"
                                    :key="dl+'_dadosLinha'+l"
                                    class="col-sm-12 row pontosRelatorio">
                                    <div class="col-sm-1">{{dadosLinha.horario}}</div>
                                    <div class="col-sm-11">{{dadosLinha.ponto}}</div>
                                </div>
                        </tr>
                    </template>
                </template>
            </slot>
        </tableRelatorio>
    </panelEagle>
</template>

<script>
import {mdiClockFast } from '@mdi/js'
import Vue               from 'vue'
import {required}        from 'vuelidate/lib/validators'
import {EmpresasService} from '../../../Services/auth/Empresas.service'
import {HttpRequest}     from '../../../Services/auth/HttpRequest.Service'
import {FiltrosService}  from '../../../Services/filtros/filtros.Service'
import { mapGetters } from 'vuex'

export default Vue.extend({
	name:'horariosLinhas',
	components:{
		'panelEagle'                : require('@/components/Atom/Panel/PanelEagle').default,
		'tituloPage'                : require('@/components/Atom/Header/Titulo').default,
		'basicButtonsRelatoriosEIG' : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
		'selectAll'                 : require('@/components/Atom/Select/SelectAll').default,
		'slideUpAndDown'            : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
		'statusInformation'         : require('@/components/Atom/StatusInformation/StatusInformation').default,
		'tableRelatorio'            : require('@/components/Atom/Table/TableRelatorio').default,
	},

	validations:{
		empresasArray:{
			required
		},
		linhasArray:{
			required
		},
		diaDaSemana:{
			required
		},
	},

	data(){
		return {
			mdiClockFast:mdiClockFast,
			labelsSelectEmpresa:[
				{indexDFH: 'E', description: 'Empresa *'},
			],
			optionsSelectEmpresa: new EmpresasService().Get(),
			labelsSelectLinhas:[
				{indexDFH: 'L', description: 'Linhas *'},
				{indexDFH: 'LP', description: 'Principais *'},
			],
			optionsSelectLLP: [],
			labelsSelectDiasSemana:[
				{indexDFH: 'D', description: 'Dias da semana'},
			],
			optionsSelectDS:[
				{
					'value':'7',
					'description': 'Segunda a Sexta'
				},
				{
					'value':'1',
					'description': 'Segunda-Feira'
				},
				{
					'value':'2',
					'description': 'Terça-Feira'
				},
				{
					'value':'3',
					'description': 'Quarta-Feira'
				},
				{
					'value':'4',
					'description': 'Quinta-Feira'
				},
				{
					'value':'5',
					'description': 'Sexta-Feira'
				},
				{
					'value':'6',
					'description': 'Sábado'
				},
				{
					'value':'0',
					'description': 'Domingo'
				}
			],
			statusbar: 'info',
			// variaveis dos select
			empresasArray:[],
			linhasArray:[],
			linhasTipo:'L',
			linhasSelect: [],
			diaDaSemana:[0,1,2,3,4,5,6],
			// Variavel de armazenamento de dados do relatorio
			dadosHorariosLinhas:[],
			tamanhoArrayLinhas: 0,
			htmlHorariosLinhas:'',
			arrayOptionsSelects     : [],
			selectedRadioslinhas    : 'L',
			loadingPanel            : false,
			loadingExportar         : [false, false, false],
			carregando              : false,
			selectedEmpresa         : [],
		}
	},methods: {
		...mapGetters(['getMaster']),

		SelectEmpresas(event){
			this.empresasArray = event;
			this.linhasSelect.clearAll();
			this.dados_filtros()
		},
		SelectLinhas(event){
			this.linhasArray = event;
		},
		selectCriado(select){
			this.linhasSelect = select;
		},
		SelectDiasDeSemana(event, value){
			if(value == 'ST'){
				this.diaDaSemana = [0,1,2,3,4,5,6];
			}else if(value == '7'){
				this.diaDaSemana = [1,2,3,4,5];
			}else{
				this.diaDaSemana = [value];
			}
		},

		changeCheckLinhas(event){
			this.linhasTipo = event
			this.selectedRadioslinhas = event;
			if(this.arrayOptionsSelects.length != 0){
				this.optionsSelectLLP = this.arrayOptionsSelects[event];
			}
		},

		async dados_filtros(){
			this.carregando = true
			var dados = [];
			dados = await new FiltrosService().dados_filtros(this.empresasArray, ['L', 'LP'])
			this.arrayOptionsSelects = dados;
			this.changeCheckLinhas(this.selectedRadioslinhas);
			this.carregando = false
		},
		async gerarRelatorio(){
			if(this.diaDaSemana == []){
				this.diaDaSemana = [0,1,2,3,4,5,6];
			}
			if(this.empresasArray.length > 0 && this.linhasArray.length > 0){
				this.htmlHorariosLinhas = '';
				this.dadosHorariosLinhas = [];
				var onlyPrincipal = this.linhasTipo == 'LP' ? true : false;
				this.loadingPanel = true;
				var data = await new HttpRequest().Post('/coletivos/relatorios/horarios/linhas/gerar/horario', 
					{'clientes': this.empresasArray, 
						'dias': this.diaDaSemana, 
						'onlyPrincipal': onlyPrincipal, 
						'linhas': this.linhasArray})
				var dados = data.data;
				this.tamanhoArrayLinhas = dados.tamanho
				if(dados.tamanho > 0){
					this.dadosHorariosLinhas = dados.linhas
					this.htmlHorariosLinhas = '';
				}else{
					this.htmlHorariosLinhas = '';
					this.dadosHorariosLinhas = [];
					this.statusbar = 'error'
				}
				this.loadingPanel = false
			}
		},
		async exportarRelatorio(type){
			var root =  process.env.VUE_APP_ROOT;
			if(this.tamanhoArrayLinhas > 0){
				switch(type){
					case 'pdf':
						this.loadingExportar = [true, false, false]
						break;
					case 'xls':
						this.loadingExportar = [false, true, false]
						break;
					case 'csv':
						this.loadingExportar = [false, false, true]
						break;
					default:
						break;
				}
				var data = await new HttpRequest().Post('/coletivos/relatorios/horarios/linhas/exportar/horario', 
					{'arrayDados':JSON.stringify(this.dadosHorariosLinhas), 
						'tipo':type});
				window.open(root+'/'+data.data.local);
				this.loadingExportar = [false, false, false]
			}
		}
	},

	mounted() {
		if(!this.getMaster()){
			this.selectedEmpresa = this.optionsSelectEmpresa
		}
	}
})
</script>

<style lang="scss">
    #horariosLinha{
        .newbadge{
            min-width: 30%;
            // border-top-left-radius: 5px;
            // border-top-right-radius: 5px;
        }
        .divHrDeBaixo{
            hr{
                margin-bottom: 0rem;
            }
        }
        .divButtonsBasic{
            padding-right: 0px;
        }
        .thDados {
            font-size: 12px;
            font-weight: 900;
            padding-left: 10px;
        }
        .colorDays {
            background: #DDD;
        }
        .colorTurn {
            background: #CCC;
        }
        .tdEspaco {
            width: 30px!important;
        }
        .tdDados {
            border-left: 1px solid #E5E5E5;
            font-size: 12px;
            padding-left: 10px;
        }
        .tdCol {
            width: 100px;
            font-size: 12px;
            padding-left: 10px;
        }

        .fixed{
            position: sticky;
            top: 0;
        }

        .dadosRelatorio{
            text-align: left;
            font-size: 12px;
            div.horarioRelatorio{
                font-weight: bold;
                background: #dddddd;
            }
            div.pontosRelatorio{
                padding: 0px;
                background: #fff;
                div{
                    border: 1px solid #d5d5d5
                }
            }
        }
    }    
</style>