Esse componente é utilizado como popup de marcadores, mas
para poder ser montado em tempo de renderização, ele é implementado como
div, empacotando essa div com uma popup usando o método bindPopup
do próprio Leaflet
props: 
    infoPonto: Objeto que contem todas as informações usadas aqui de um ponto
        ->poraio = Raio do ponto
        ->pocodigo = código do ponto do banco de dados 
        ->podescricao = nome ou descrição do ponto
        ->potipo = tipo do ponto (C=>coleta, P=>referência, E=>entrega)
eventos: 
    recarregarPontos: Emitido quando um ponto é editado ou excluido
<template>
    <div id='facilitadorPonto'>
        <div class='flex-column
			popMarcador'
			v-if='vizualisar'>
            <div v-text="ponto.podescricao"/>
            <div v-if="ponto.poendereco" 
				v-text='`Endereço: ${ponto.poendereco}`'/>
            <div v-text='`Raio: ${ponto.poraio}`'/>
			<div v-if='ponto.poregiao' v-text='`Região: ${ponto.poregiao}`'/>
            <div class='d-flex justify-content-center'>
                <simple-button
				v-if='permissoes.ppeditar'
                text="Editar"
                type="green"
                event="click"
                :icon="iconEditar"
                @click="editaPonto"/>
                <loading-button
				v-if='permissoes.ppexcluir'
                text="Excluir"
                type="red"
                event="click"
                :isLoading="excluindo"
                :icon="iconExcluir"
                @click="confirmarExclusao(ponto.pocodigo)"/>
            </div>
        </div>
        <div class="flex-column" v-else>
            <div class="d-flex justify-content-around"> 
                <div class="col-2 nopadding text-left">
                    <label for="tipoPonto" class="py-2 mt-2">Tipo:</label>
                    <label for="nomePonto" class="py-2">Nome:</label>
                    <label for="raioPonto" class="py-2">Raio:</label>
                </div>
                <div class="col-10 nopadding">
                    <multiselect
                    v-model="infoEdicao.potipo"
                    trackBy="value"
                    id="tipoPonto"
                    class="mt-1"
                    track="value"
                    style="width: 200px;"
                    label="text"
                    placeholder=" "
                    deselectLabel=" "
                    selectedLabel=" "
                    selectLabel=" "
                    :showLabels="false"
                    :options="optTiposDePonto"
                    />
                    <input 
                    :class="{
                        'form-control':true,
                        'mt-1':true,
                    }" 
                    v-model="infoEdicao.podescricao"
                    id="nomePonto">
                    <basic-slider 
                    style="border: 0px"
                    :maxValue="500"
                    :value="ponto.poraio"
                    @change="changeRaio"
					@dragging="dragging = true"
					@drag-end="dragend"
					:lazy="true"
                    class="form-control align-top"/>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <loading-button
                text="Salvar"
                type="green"
                :isLoading="salvando"
                event="click"
                :icon="iconSalvar"
                @click="salvaEdicao"/>
                <simple-button
                text="Cancelar"
                type="red"
                event="click"
                :icon="iconCancelar"
                @click="cancelaEdicao"/>
            </div>
        </div>
		<eagle-modal title='Confirmar exclusão de ponto' id='modal-confirmar' @confirmButton='excluirPonto(ponto.pocodigo)'>
			<template #modalBody>
				Essa ação não pode ser revertida
			</template>
		</eagle-modal>
    </div>
</template>
<script>
import Vue from 'vue'
import Multiselect from 'vue-multiselect';
import basicSlider from '@/components/Atom/Buttons/BasicSlider.vue'
import simpleButton from '@/components/Atom/Buttons/SimpleButton.vue'
// import loadingButton from '@/components/Atom/Buttons/LoadingButton.vue'
import eagleModal from '@/components/Atom/Modal/ModalEagle.vue'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { conectionError } from '@/Services/Helpers/swellHeper'
import {
	mdiPencilOutline,
	mdiTrashCanOutline,
	mdiCheckBold,
	mdiCloseThick} from '@mdi/js'
export default {
	components: {
		// loadingButton,
		loadingButton : require('@/components/Atom/Buttons/LoadingButton.vue').default,
		simpleButton,
		Multiselect,
		basicSlider,
		eagleModal
	},
	props: {
		ponto: {
			type: Object,
			required: true,
		},
		permissoes:{
			type: Object,
			required:true
		},
		eventoExclusao:{
			type: Boolean,
			default: true
		},
		abreEmEditar:{
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			optTiposDePonto:[
				{value: 'C', text:'Ponto de coleta'},
				{value: 'E', text:'Ponto de Entrega'},
				{value: 'P', text:'Ponto de Referência'},
			],
			urlBase:'/finder/mapa/',
			iconExcluir : mdiTrashCanOutline,
			iconEditar: mdiPencilOutline,
			iconSalvar: mdiCheckBold,
			iconCancelar: mdiCloseThick,
			vizualisar:true,
			excluindo:false,
			salvando:false,
			infoEdicao:{
				pocodigo: this.ponto.pocodigo,
				potipo:'',
				podescricao:'',
				poraio:0,
				poendereco:'',
			},
			dragging: false,
		}
	},
	methods: {

		confirmarExclusao(){
			this.$bvModal.show('modal-confirmar')
		},
		/**
		 * @listens click - botão de cancelar edição
		 * @description alterna de volta pro modo de vizualização
		 */
		cancelaEdicao(){
			this.vizualisar = true
		},

		/**
		 * @description Leva os dados de edição para o back-end
		 * O objeto ponto tem de ter o atributo 'potipo' sobreescrito
		 * porque eu não queria fazer uma função pra lidar com
		 * o seletor de tipo.
		 * @author Gui 🍺🍺
		 */
		salvaEdicao(){
			if(this.eventoExclusao){
				this.salvando = true
				let obj ={
					ponto:{
						...this.infoEdicao,
						potipo:this.infoEdicao.potipo.value
					}
				}
				let uri = this.urlBase+'editar/ponto'
				new HttpRequest().Post(uri, obj).then((data)=>{
					this.salvando = false
					if(data.data?.success){
						this.pontoEditado(data.data.ponto)
						this.cancelaEdicao()
					}else 
						conectionError()
				})
			}else{
				this.pontoEditado(this.infoEdicao)
				this.vizualisar = true
			}
			//ehis.menuMapa.emiteCarregaPontos()
		},

		/**
		 * @fires ponto-editado
		 * @param {object} ponto - informações que serão enviadas junto com o evento.
		 * @description esse evento, por default, só é disparado dps que o componente
		 * já alterou as informações do ponto no banco de dados.
		 */
		pontoEditado(ponto){
			this.$emit('ponto-editado', ponto)
		},

		/**
		 * @description Tenta excluir um ponto do bd. 
		 * Se o ponto estiver associado a alguma outra coisa
		 * o mesmo não é excluido e em vez disso é retornado 
		 * um erro mais específico para o front, 
		 * @author Gui 🍺🍺
		 */
		excluirPonto(codigo) {
			this.$bvModal.hide('modal-confirmar')
			if(this.eventoExclusao){
				this.excluindo = true
				let uri = this.urlBase+'excluir/ponto'
				new HttpRequest().Post(uri, {codigo}).then((data)=>{
					this.excluindo = false
					if(data.data?.success)
						this.pontoExcluido()
					else if(data.data?.exception==='query')
						conectionError(
							`Esse ponto esta associado a uma ou mais
							rotas e não pode ser excluido`,
							'warning'
						)
					else
						conectionError()
				})
			}else
				this.pontoExcluido()
		},

		/**
		 * @fires ponto-excluido
		 */
		pontoExcluido(){
			this.$emit('ponto-excluido', this.ponto.pocodigo)
		},

		/**
		 * por algum motivo, quando altero o modo
		 * de vizualisar o ponto, a popup fecha, então
		 * não sei pq na moral, mas como pseudo correção,
		 * estou emitindo esse evento, que deve ser utilizado
		 * p/ abrir a popup de novo.
		 */
		editaPonto(){
			this.vizualisar = false
			this.$emit('editando-ponto')
		},

		changeRaio(value) {
			if (!this.dragging) {
				this.$emit('edit-raio', value)
			}
			this.infoEdicao.poraio = value
		},

		dragend() {
			this.dragging = false
			this.$emit('edit-raio', this.infoEdicao.poraio)
		},

	},
	mounted() {
		this.infoEdicao.potipo = this.optTiposDePonto.find(t=>{
			return t.value === this.ponto.potipo
		})
		this.infoEdicao.poraio = this.ponto.poraio
		this.infoEdicao.podescricao = this.ponto.podescricao
		this.infoEdicao.poendereco = this.ponto.poendereco
		if(this.abreEmEditar){
			this.vizualisar = false
			this.$forceUpdate()
		}
	},
}
</script>

<style lang="scss" scoped>
#facilitadorPonto{
    .popMarcador{
        font-weight: bolder;
        width: 300px;
    }
    .form-control{
        width: 200px;
        font-size: 13px !important;
        height: 35px !important;
    }
}
</style>