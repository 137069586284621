/** 
    Este é um compoenete de gráfico de barra para ser usado nas dashboards

    Propriedades:
        1  -> id - String, Valor único para diferenciar os indicadores - EX:'indicador1'
        2  -> series - Array, Valores que irão ser usados para gerar as barras do gráfico - Ex:'[
                                {
                                    name: 'PRODUCT A',
                                    data: [14, 25, 21, 17, 12, 13, 11, 19]
                                }, {
                                    name: 'PRODUCT B',
                                    data: [13, 23, 20, 8, 13, 27, 33, 12]
                                }, {
                                    name: 'PRODUCT C',
                                    data: [11, 17, 15, 15, 21, 14, 15, 13]
                                }]'
        3  -> categories - Array, Valores para gerar as categorias do gráfico - Ex: '['2011 Q1', '2011 Q2', '2011 Q3']'
        4  -> columnWidth - String, Largura das barras  do gráfico - Ex:'50%'
        5  -> horizontal - Boolean, Se o gráfico será na horizontal ou não - Ex: 'false'
        6  -> height - Number, Altura do bloco do gráfico - Ex: 450
        7  -> backgroundColor - String, Cor de fundo do gráfico - Ex: 'black'
        8  -> colors - Array, Cores que irão na barra - Ex:'['#4C83FF', '#FD6585', '#5961F9']'
        9  -> legendColor - String, Cor da legenda - Ex: '#000'
        10 -> LegendPosition - String, Posição que a legenda irá ficar - Ex: 'bottom/left'
        11 -> typeChart - String, tipo que o gráfico vai ser - Ex: 'bar/line'
        12 -> titleText - String, título do gráfico - Ex: 'Título'
        13 -> pontilhados - Tamanho pontilhados gráfico linhas - Ex: 2
        14 -> widthLinhas - Largura das linhas do gráfico - Ex: 5
        15 -> dataLabels - Habilitar dataLabels - Ex: 'true'
        16 -> offsetYChart - Posicionamento do gráfico em Y - Ex: '50'
        17 -> sizeMarkers - Tamano dos markers do gráfico de linhas - Ex: 2
    
    Obs:
        O mesmo tamanho que a array de data das series tiver a array de categories tem que ter
        As cores que são inseridas nas barras do gráfico são correspondentes a posição da array, ou seja, 
            a primeira posição sera a primeira cor da barra de baixo para cima e assim por diante


  documetação
    https://apexcharts.com/docs/installation/
    https://apexcharts.com/docs/options
    https://apexcharts.com/vue-chart-demos/column-charts/stacked/
*/
<template>
    <div id='chartColumn'>
        <div class="col-sm-12 pl-1 pr-1">
            <div :class="'backgound-'+backgroundColor">
            <div :id="'barchart'+id"></div>
            </div>
        </div>
    </div>
</template>
<script>
    import Vue  from 'vue'
    import ApexCharts from 'apexcharts'
    import { required } from 'vuelidate/lib/validators'
    export default Vue.extend({
        name:'ChartsColumn',

        data(){
            return {
                optionsBar: {
                    chart: {
                        height: this.height,
                        width: this.width,
                        type: this.typeChart,
                        stacked: false,
                        offsetY: this.offsetYChart,
                        toolbar: {
                            show: true,
                            tools: {
                                download: true,
                                selection: false,
                                zoom: true,
                                zoomin: true,
                                zoomout: true,
                                pan: false,
                            },
                        },
                        zoom: {
                            enabled: true
                        },
                        fontFamily:  'nexabook, sans-serif',
                    },
                    markers: {
                        size: this.sizeMarkers,
                    },
                    plotOptions: {
                        bar: {
                            columnWidth: this.columnWidth,
                            horizontal: this.horizontal,
                            dataLabels: {
                                position: this.labelsPosition,
                            },
                        },
                    },
                    dataLabels: {
                        enabled: this.dataLabels,
                        offsetY: this.labelsOffset,
                        style: this.labelsStyle
                    },
                    series: this.series,
                    xaxis: {
                        labels: {
                            style: {
                                colors: this.legendColor,
                                fontSize: this.legendSize,
                            },
                            // offsetX: -5,
                        },
                        categories: this.categories,
                    },
                    colors: this.colors,
                    legend: {
                        position: this.LegendPosition,
                        labels: {
                            colors: this.legendColor,
                            fontSize: this.legendSize,
                        },
                        fontSize: '2px'
                    },
                    title: {
                        text: this.titleText,
                        align: 'left',
                        margin: 10,
                        offsetX: 0,
                        offsetY: 0,
                        floating: false,
                        style: {
                            fontWeight:  'bold',
                            fontFamily:  'nexabook, sans-serif',
                        },
                    },
                    // Largura da linha
                    stroke: {
                        width: this.widthLinhas,
                        curve: 'straight',
                        dashArray: this.pontilhados,
                    },
                    // annotations: {
                    //     points: [
                    //         {
                    //             x: 220,
                    //             y: 135,
                    //             marker: {
                    //                 size: 0,
                    //             },
                    //             label: {
                    //                 borderColor: '#c2c2c2',
                    //                 borderWidth: 2,
                    //                 borderRadius: 15,
                    //                 text: "135",
                    //                 textAnchor: 'middle',
                    //                 offsetX: 0,
                    //                 offsetY: 0,
                    //                 mouseEnter: undefined,
                    //                 mouseLeave: undefined,
                    //                 style: {
                    //                     color: "#000",
                    //                     background: "#fff",
                    //                     padding: {
                    //                         left: 5,
                    //                         right: 5,
                    //                         top: 8,
                    //                         bottom: 5,
                    //                     }
                    //                 },
                    //             }
                    //         }
                    //     ]
                    // }
                },
            }
        },

        props:{
            id:{
                type: String,
                required,
            },
            series:{
                type: Array,
                required,
            },
            categories:{
                type: Array,
                required,
            },
            columnWidth:{
                type: String,
                default:'50%',
            },
            horizontal:{
                type:Boolean,
                default:false,
            },
            height:{
                type: Number,
                default: 450
            },
            width:{
                type: Number,
                default: 450
            },
            backgroundColor:{
                type: String,
                default: ''
            },
            colors:{
                type: Array,
            },
            legendColor:{
                type: String,
                default:'#000',
            },
            legendSize:{
                type: String,
                default:'18px',
            },
            LegendPosition:{
                type: String,
                default:'bottom'
            },
            labelsPosition:{
                type: String,
                default:'center' // top, center, bottom
            },
            labelsOffset:{
                type: Number,
                default:0,
            },
            labelsStyle:{
                type: Object,
                default:() => {
                    return {
                        fontSize: '14px',
                        colors: ["#ffffff"]
                    }
                },
            },
            titleText:{
                type: String,
                default:'',
            },
            typeChart:{
                type: String,
                default:'bar'
            },
            pontilhados:{
                type: Number,
                default: 0,
            },
            widthLinhas:{
                type: Number,
                default: 2,
            },
            dataLabels:{
                type: Boolean,
                default: false,
            },
            offsetYChart:{
                type: Number,
                default: 0,
            },
            sizeMarkers:{
                type: Number,
                default: 0,
            }
        },

        mounted(){
            var chartBar = new ApexCharts(
            document.querySelector("#barchart"+this.id), this.optionsBar
            );
            chartBar.render();
        }
    })
</script>
<style lang='scss'>
#chartColumn{
    .backgound-gray{
        background: #f8f8f8;
        box-shadow: 1px 1px 1px 1px #e0e0e0;
    }
    .apexcharts-title-text{
        font-size: 14px !important;
    }

    // fonte graficooooo
    .apexcharts-text tspan{
        font-size: 8px;
    }

    // Tamanho dados da legenda
    .apexcharts-legend-series{
        .apexcharts-legend-text{
            font-size: 8px !important;
        }
        .apexcharts-legend-marker{
            height: 8px !important;
            width: 8px !important;
        }
    }

    // tamanho dados dentro colunas gráfico
    .apexcharts-bar-area{
        font-size: 5px !important;
    }
}
</style>