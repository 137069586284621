<template>
    <panelEagle id="envioComandos" :loading="loadingPanel">
        <div class="col-lg-12 row nopadding">
            <div class="col-lg-6 ">
                <titulo titulo="Envio de comandos" :icon="mdiSimOutline" />
            </div>
        </div>
        <div class="col-sm-12"><hr /></div>
        <div class="col-lg-12 row">
            <div class="col-sm-3 nopadding">
                <slectAll
                    :labels="[{indexDFH: 'M', description: 'Módulo*'}]"
                    nameForRadio="Modulo"
                    :optionsArray="optionsModulos"
                    ref="selectM"
                    class="nopadding"
                    :hasSelectAll="false"
                    :isMultiple="false"
                    @changeSelect="changeModulos"
                />
            </div>
            <div class="col-sm-3 nopadding">
                <slectAll
                    :labels="[{indexDFH: 'PF', description: 'Perfil*'}]"
                    nameForRadio="Perfil"
                    :optionsArray="optionsPerfil"
                    :disabled="$v.moduloSelecionado.$invalid || checkComando"
                    ref="selectPF"
                    class="pr-0"
                    :hasSelectAll="false"
                    :isMultiple="false"
                    :loading="loadingPerfis"
                    @changeSelect="changePerfil"
                />
            </div>
            <div class="col-sm-6 nopadding">
                <slectAll
                    :labels="[{indexDFH: 'PF', description: 'Comando*'}]"
                    nameForRadio="Comando"
                    :optionsArray="optionsComandos"
                    :disabled="$v.moduloSelecionado.$invalid || !checkComando"
                    ref="selectC"
                    :hasSelectAll="false"
                    :isMultiple="false"
                    :loading="loadingComandos"
                    @changeSelect="changeComando"
                />
            </div>
            <div class="col-sm-6 nopadding mt-2 row">
                <div class="col-sm-6 mt-3 nopadding">
                    <inputSimple
                        label="Saldo"
                        name="Saldo"
                        id="Saldo"
                        class="pl-0 pr-0"
                        :value="saldoMensagem"
                        :disabled="true"
                    />
                </div>
                <div class="col-sm-4 pt-5 pl-0 pr-0 ml-5">
                    <simpleButton
                        text="Inserir"
                        type="green"
                        width="100%"
                        event="click"
                        :disabled="$v.$invalid"
                        @click="listarEnvios"
                    />
                </div>
            </div>
            <div class="col-sm-6 nopadding row">
                <div class="col-sm-6 nopadding mt-5">
                    <b-checkbox
                        v-model="checkComando"
                        id="checkComando"
                        class="nopadding"
                        @change="changeCheck"
                    >
                        Inserir somente um comando
                    </b-checkbox>
                    <b-checkbox v-model="checkServidor" id="checkServidor" class="nopadding">
                        Enviar comando por servidor
                    </b-checkbox>
                </div>
                <div class="col-sm-6" align="right">
                    <subTitle :items="legenda" />
                </div>
            </div>
        </div>
        <div class="col-sm-12"><hr /></div>
        <div class="col-sm-12 row nopadding">
            <div class="col-sm-10 row">
                <div class="col-sm-12 row cabecalhoTabela py-2 pr-0 pl-0">
                    <div class="col-sm-1">Ordem</div>
                    <div class="col-sm-2">Módulo</div>
                    <div class="col-sm-2">Chip</div>
                    <div class="col-sm-3">Descrição</div>
                    <div class="col-sm-3">Comando</div>
                    <div class="col-sm-1">Status</div>
                </div>
            </div>
            <div class="col-sm-2">
                <simpleButton
                    text="Enviar"
                    type="green"
                    width="100%"
                    event="click"
                    :disabled="listagemEnvios.length == 0"
                    @click="enviarComandos"
                />
            </div>
            <div v-if="listagemEnvios.length == 0" class="col-sm-10">
                <statusInformation typeBar="div" :statusBar="statusbar"> </statusInformation>
            </div>
            <div v-else class="col-sm-10">
                <draggable v-model="listagemEnvios" @change="endDraggin">
                    <template v-for="(item, i) in listagemEnvios">
                        <div :key="i" class="col-sm-12 row bodyTable pr-0 pl-0">
                            <div class="col-sm-1 py-2 p-1">
                                {{i+1}}
                            </div>
                            <div class="col-sm-2 py-2 p-1">
                                {{item.mocodigo}}
                            </div>
                            <div class="col-sm-2 py-2 p-1">
                                {{item.chnumero}}
                            </div>
                            <div class="col-sm-3 py-2 p-1">
                                {{item.mcdescricao}}
                            </div>
                            <div class="col-sm-3 py-2 p-1">
                                {{item.mccomando}}
                            </div>
                            <div class="col-sm-1 py-2 p-1" :title="item.mensagem">
                                <baseIcon
                                    :class="'status-'+item.status"
                                    :icon="mdiCheckboxBlankCircle"
                                    size="18"
                                />
                            </div>
                        </div>
                    </template>
                </draggable>
            </div>
        </div>
        <div class="col-sm-2"></div>
    </panelEagle>
</template>
<script>
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import draggable from 'vuedraggable'
import { mdiSimOutline, mdiCheckboxBlankCircle } from '@mdi/js'
import { required, requiredIf } from 'vuelidate/lib/validators'
import {FiltrosService}  from '../../../Services/filtros/filtros.Service'
import Vue from 'vue'
import { DateTime } from 'luxon';
import axios from 'axios'

export default Vue.extend({
	name:'enviarComandos',
	components:{
		draggable,
		'panelEagle'        : require('@/components/Atom/Panel/PanelEagle').default,
		'titulo'            : require('@/components/Atom/Header/Titulo').default,
		'slectAll'          : require('@/components/Atom/Select/SelectAll').default,
		'simpleButton'      : require('@/components/Atom/Buttons/SimpleButton').default,
		'baseIcon'          : require('@/components/Atom/Icon/BaseIcon').default,
		'inputSimple'       : require('@/components/Atom/Inputs/InputSimple').default,
		'statusInformation' : require('@/components/Atom/StatusInformation/StatusInformation').default,
		'subTitle'          : require('@/components/Atom/Subtitle/Subtitle').default,
	},

	validations:{
		moduloSelecionado : { required },
		perfilSelecionado : { required: requiredIf(function(){
			if(this.checkComando) {
				return false
			}
			return true
		}),
		},
		comandoSelecionado : { required: requiredIf(function(){
			if(this.checkComando) {
				return true
			}
			return false
		}),
		},
	},

	data(){
		return{
			mdiSimOutline   : mdiSimOutline,
			mdiCheckboxBlankCircle : mdiCheckboxBlankCircle,
			link : '/administrativo/envio/comandos/',
			optionsModulos    : [],
			optionsPerfil     : [],
			optionsComandos   : [],
			moduloSelecionado : '',
			perfilSelecionado : '',
			loadingPanel      : false,
			red:'green',
			legenda           : [
				{description: 'Enviado', spanColor:'green',  colWidth: 12},
				{description: 'Integrado', spanColor:'blue',   colWidth: 12},
				{description: 'Fila', spanColor:'ligth-yellow', colWidth: 12},
				{description: 'Erro', spanColor:'red',    colWidth: 12}],
			listagemEnvios    : [],
			listFim           : [],
			statusbar         : 'info',
			saldoMensagem     : '',
			controler         : 0,
			atualiza          : undefined,
			loadingPerfis     : false,
			loadingComandos   : false,
			cont              : 0,
			comandoSelecionado : [],
			checkComando  : false,
			checkServidor : false,
		}
	},

	beforeDestroy(){
		clearInterval(this.atualiza)
	},

	methods:{
		changeModulos(modulo){
			this.moduloSelecionado = modulo
			this.$refs.selectPF.clearAll()
			this.$refs.selectC.clearAll()
			if(modulo.length > 0){
				this.buscaPerfis()
			}
		},

		changePerfil(perfil){
			this.perfilSelecionado = perfil
		},

		changeComando(comando){
			this.comandoSelecionado = comando
		},

		changeCheck(){
			this.$refs.selectPF.clearAll()
			this.$refs.selectC.clearAll()
		},

		async buscaModulos(){
			this.loadingPanel = true
			var dados = await new HttpRequest().Post(this.link+'buscar/modulos')
			if(dados.code == 200){
				this.optionsModulos = dados.data.modulos
				this.loadingPanel = false
			} else {
				this.optionsModulos = []
				this.loadingPanel = false
			}
		},

		/**
         * @description Método para buscar o saldo de créditos de sms da conta
         * @author Vitor Hugo 🐨
         */
		buscarSaldo(){
			this.loadingPanel = true
			new HttpRequest().Post(this.link+'buscar/saldo')
				.then((dados)=>{
					if(dados.code == 200){
						this.saldoMensagem = dados.data.saldo
						this.loadingPanel = false
					} else {
						this.saldoMensagem = 'Sem dados'
						this.loadingPanel = false
					}
				})
		},

		/**
         * @description Método busca os perfis e comandos
         *  relacionados ao módulo selecionado
         * @author Vitor Hugo 🐨
         */
		async buscaPerfis(){
			this.loadingPerfis = true
			var dados = await new HttpRequest().Post(this.link+'buscar/perfis',
				{'modulo': this.moduloSelecionado})
			this.optionsPerfil = dados.data.perfis
			this.optionsComandos = dados.data.comandos
			this.loadingPerfis = false
		},

		/**
         * @description Método para listar os dados dos comandos associados
         * ao perfil selecionado. Como podem ser selecionados vários módulos
         * é feito um tratamento na hora da listagemEnvios receber os dados
         * @param {Array}
         *      moduloSelecionado = modulo
         *      perfilSelecionado = perfil
         * @author Vitor Hugo 🐨
         */
		async listarEnvios(){
			this.loadingPanel = true
			var dados = await new HttpRequest().Post(this.link+'listar/envios',
				{'modulo':this.moduloSelecionado[0],
					'perfil':this.perfilSelecionado,
					'comando' : this.comandoSelecionado})
			if(dados.code == 200){
				var dadosRequest = []
				dadosRequest.push(dados.data.comandos)
				dadosRequest.forEach(element => {
					element.forEach(el => {
						this.listagemEnvios.push(el)
					});
				});
				this.listFim = this.listagemEnvios
				this.$refs.selectM.clearAll()
				this.$refs.selectPF.clearAll()
				this.$refs.selectC.clearAll()
				this.loadingPanel = false
			} else {
				this.statusbar = 'error'
				this.loadingPanel = false
			}
		},

		/**
         * @description Método para enviar as mensagens para a API comtele
         * @param {Array} listFim = array com o modulo, chip e comando a serem enviados
         * @author Vitor Hugo 🐨
         */
		enviarComandos(){
			this.controler = 0
			this.loadingPanel = true
			this.fazRequest()
		},

		fazRequest(){
			this.loadingPanel = true
			if(this.listFim[this.controler]['status'] == 'blue'){
				new HttpRequest().Post(this.link+'enviar/comandos',
					{'dados':this.listFim[this.controler], 'servidor' : this.checkServidor})
					.then((dados)=>{
						if(dados.code == 200){
							if(dados.data.retorno.Success){
								this.listFim[this.controler]['status'] = this.checkServidor ?
									'Delivered' : 'Processed'
								this.listFim[this.controler]['id'] =
                                    dados.data.retorno.Object['requestUniqueId']
								this.listFim[this.controler]['dateInicio'] =
                                    DateTime.now().toFormat('yyyy-LL-dd HH:mm:ss')
							} else {
								this.listFim[this.controler]['status'] = 'Error'
							}
							if(this.controler < this.listFim.length-1){
								this.controler++
								this.fazRequest()
							} else {
								this.buscarSaldo()
								this.controleIntervalo('criar')
								this.loadingPanel = false
							}
						} else {
							this.loadingPanel = false
						}
					})
			} else {
				if(this.controler < this.listFim.length-1){
					this.controler++
					this.fazRequest()
				} else {
					this.buscarSaldo()
					this.controleIntervalo('criar')
					this.loadingPanel = false
				}
				this.loadingPanel = false
			}
		},

		/**
         * @description Método para verificar o status do comando enviado
         *  retorna o status do back apartir do campo status. Também destroi
         *  o intervvalo e limpa as variáveis que criam a tabela caso os
         *  status não estejam pendentes
         * @param {Array} listFim = array com os comandos enviados
         * @author Vitor Hugo 🐨
         */
		verificaStatusComando(){
			this.cont = 0
			new HttpRequest().Post(this.link+'verifica/status',
				{'lista' : this.listFim, 'servidor' : this.checkServidor})
				.then((dados)=>{
					if(dados.code == 200){
						var dadosRetorno = dados.data.statusComando
						for (let index = 0; index < this.listFim.length; index++){
							if(dadosRetorno[index].id === this.listFim[index].id){
								this.listFim[index].status =
                                    dadosRetorno[index].status
								this.listFim[index].mensagem =
                                    dadosRetorno[index].mensagem
							}
							if(this.listFim[index].status != 'Processed' &&
                                this.listFim[index].status != 'blue'){
								this.cont++
								if(this.listFim.length == this.cont){
									this.controleIntervalo('destruir')
									setTimeout(() => {
										this.listFim = []
										this.listagemEnvios = []
									}, 40000);
								}
							}
						}
					}
				})
		},

		/**
         * @description Método altera as posições dos objetos na array de
         *  acordo com o dragging. Retira o objeto da posição antiga e insere
         *  na informada como nova
         * @param {Object} oldIndex = index em que o objeto estava na arrray
         *  newIndex = index em que o objeto foi deixado na array
         *  element = valores do objeto
         * @author Vitor Hugo 🐨
         */
		endDraggin(list){
			this.listFim.splice(list.moved.oldIndex, 1)
			this.listFim.splice(list.moved.newIndex, 0, list.moved.element)
		},

		/**
         * @description Método para atualizar relatório a cada 5s
         * @param {String} func = string com oq deve ser feito (criar ou destruir)
         * @return Variável atualiza como undefined
         * @author Vitor Hugo
         */
		controleIntervalo(func){
			if(func == 'criar'){
				if(this.atualiza == undefined){
					this.atualiza = setInterval(() => {
						this.verificaStatusComando()
						this.buscarSaldo()
					}, 35000);
				}
			}else{
				//destruir intervalo
				if(this.atualiza !== undefined){
					clearInterval(this.atualiza)
					this.atualiza = undefined
				}
			}
		}

	},

	mounted(){
		this.buscaModulos()
		this.buscarSaldo()
	}
})
</script>

<style lang="scss">
#envioComandos{
    .cabecalhoTabela{
        background: #f5f5f5;
        color: #514e4e;
        font-weight: bold;
        height: 34px;
        text-align: left;
    }

    .bodyTable{
        text-align: left;
        color: #898a8d;
        div{
            word-break: break-all;
            border: 2px solid #f5f5f5;
            .status-blue{
                color: #428BCA;
            }
            .status-Processed{
                color: #eedf3e;
            }
            .status-Delivered{
                color: #1CAF9A;
            }
            .status-Error{
                color: #E04B4A;
            }
        }
    }

    .bodyTable:hover{
        cursor: grab;
        background: #f5f5f5;
    }
}
</style>
