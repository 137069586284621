import { render, staticRenderFns } from "./CadastrarFeriados.vue?vue&type=template&id=e3329552"
import script from "./CadastrarFeriados.vue?vue&type=script&lang=js"
export * from "./CadastrarFeriados.vue?vue&type=script&lang=js"
import style0 from "./CadastrarFeriados.vue?vue&type=style&index=0&id=e3329552&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports