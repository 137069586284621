<template>
    <b-overlay
        id="overlay-background-recuperar-senha"
        :show="showOverlay"
        :variant="'secondary'/*varianteFundo*/"
        :opacity="1"
        blur="1rem"
        rounded="sm">
        <div id="resetSenha" class="d-flex justify-content-center align-items-center">
            <form class="resetSenha" @submit.prevent="passwordReset">
                <div class="col-sm-12 divCentralLogin">
                    <div class="col-sm-9 divImgLogo">
                        <img class="imgLogo" src="../../assets/logo.png">
                    </div>
                    <div class="col-sm-12 divInputs">
                        <span class="col-sm-12 row nopadding">
                            E-mail:
                        </span>
                        <input
                            disabled
                            required
                            type="text"
                            id="email-recuperar"
                            placeholder="E-mail"
                            v-model="form.email"
                            class="nomargin form-control"
                        />
                    </div>
                    <div class="col-sm-12 divInputs mt-3">
                        <span class="col-sm-12 row nopadding">
                            Nova senha:
                        </span>
                        <div class="input-senha">
                            <input
                                id="input-nova-senha"
                                required
                                :type="!novaSenhaMostrar ? 'password' : 'text'"
                                minlength="6"
                                placeholder="Senha"
                                v-model="form.novaSenha" 
                                class="nomargin form-control" 
                            />
                            <span class="icone-zoin" @click="novaSenhaMostrar = !novaSenhaMostrar">
                                <baseIcon :size='20' :icon="novaSenhaMostrar ? mdiEye : mdiEyeOff"/>
                            </span>
                        </div>
                        
                    </div>
                    <div class="col-sm-12 divInputs mt-3">
                        <span class="col-sm-12 row nopadding">
                            Confirme sua senha:
                        </span>
                        <div class="input-senha">
                            <input
                                :class="senhaDiferente?'border border-danger':''"
                                required
                                minlength="6"
                                placeholder="Senha"
                                id="input-confirma-senha"
                                v-model="form.confirmarSenha"
                                class="nomargin form-control"
                                :type="!confirmarSenhaMostrar ? 'password' : 'text'"
                            />
                            <span class="icone-zoin" @click="confirmarSenhaMostrar = !confirmarSenhaMostrar">
                                <baseIcon :size='20' :icon="confirmarSenhaMostrar ? mdiEye : mdiEyeOff"/>
                            </span>
                        </div>
                        <b-tooltip
                            variant="danger"
                            :show="senhaDiferente"
                            :disabled="!senhaDiferente"
                            placement="bottomleft"
                            target="input-confirma-senha">
                            As <strong> senhas </strong> devem ser iguais
                        </b-tooltip>
                    </div>
                    <div class="col-sm-12 divButtonLogin">
                        <b-button type="submit" class="buttonSendEmail" :disabled="loadingEnviar">
                            <span v-show="!loadingEnviar">Enviar</span>
                            <b-spinner v-show="loadingEnviar" class="m-1" small></b-spinner>
                        </b-button>
                    </div>
                </div>
            </form>
        </div>
        <template #overlay>
            <div id="overlay-senha">
                <div class="col-sm-12 row nopadding d-flex justify-content-center align-items-center" style="height:100%; width: 100%;">
                    <div class="col-sm-12 nopadding">
                        <b-alert show :variant="varianteFundo" class="row d-flex justify-content-center align-items-center">
                            <div class="col-sm-1">
                                <b-icon 
                                    font-scale="3" 
                                    icon="exclamation-circle-fill"
                                    variant="info"
                                    v-show="varianteFundo == 'info'
                                "/> 
                                <b-icon 
                                    font-scale="3"
                                    icon="x-circle"
                                    variant="danger"
                                    v-show="varianteFundo == 'danger'
                                "/> 
                                <b-icon
                                    font-scale="3"
                                    icon="exclamation-triangle-fill"
                                    variant="warning"
                                    v-show="varianteFundo == 'warning'
                                "/> 
                            </div>
                            <div class="col-sm-10 nopadding row d-flex align-items-center justify-content-center">
                                <div style="font-size:20px" class="col-sm-12">
                                    {{mensagemAviso}}
                                </div>
                            </div>
                            <div class="col-sm-1 nopadding">
                                <b-spinner v-show="mostrarLoading" class="m-5"/>
                            </div>
                        </b-alert>
                    </div>
                </div>
                
                <!-- 
                <div class="div-texto-aviso d-flex justify-content-center align-content-center" :class="varianteFundo">
                    <div class="div-texto-escrito">
                        {{mensagemAviso}}
                    </div>
                </div> 
                -->
            </div>
        </template>
    </b-overlay>
</template>
<script>
import Swal from 'sweetalert2'
import { mdiEyeOff, mdiEye } from '@mdi/js'; 
export default {
    name: "RecoverPassword",
    components: {
        baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
    },
    data() {
        return{
            loadingEnviar:false,
            senhaDiferente:false,
            confirmarSenhaMostrar:false,
            novaSenhaMostrar:false,
            mdiEye:mdiEye,
            mdiEyeOff:mdiEyeOff,
            mensagemAviso:'Por favor aguarde enquanto verificamos seus dados.',
            form: {
                email: "",
                novaSenha: "",
                confirmarSenha: "",
            },
            mostrarLoading:true,
            showOverlay:true,
            token:'',
            varianteFundo:'info',
        }; 
    },
    methods:{
        /**
         * Descrição , entrando em alguns detalhes do contrato social da função
         * @listen confimação do fomulario - espera a confirmação do usuario que o formulario esta completo
         * @fires salvaNovaSenha|montaSwall - espera o retorno da salvaNovaSenha e chama montaSwall passando o codigo da request
         * @fires alteraValidacao - caso seja um novo usuario
         * @return none
         * @author Lucas Eduardo
         */
        async passwordReset(){
            this.senhaDiferente = false
            if(this.form.novaSenha != this.form.confirmarSenha){
                this.senhaDiferente = true
                return
            }
            this.loadingEnviar = true
            var retorno = await this.auth.salvaNovaSenha(
                this.token,
                this.form.email.toString(),
                this.form.novaSenha.toString()
            )
            if(retorno.status == 201){
                await this.auth.alteraValidacao(this.form.email.toString())
            }
            this.montaSwall(retorno.status)
            this.loadingEnviar = false
        },
        
        /**
         * Escolhe qual alerta de erro/confimação vai chamar
         * @fires returnToLogin - quando o codigo recebido for 201
         * @param {Number} qual - codigo de status http
         * @return none
         * @author Lucas Eduardo
         */
        montaSwall(qual){
            switch (qual) {
                case 201:
                    Swal.fire({
                        icon: 'success',
                        title: 'Sua senha foi alterada com sucesso!',
                        showConfirmButton: false,
                        timer: 4500
                    })
                    this.returnToLogin()
                    break;
                default:
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Parece que tivemos um erro, tente novamente mais tarde!',
                        html: `<div>
                                    <span style="text-align: center">Ocorreu um dos seguintes erros</span>
                                    <ul style="text-align: left">
                                        <li>O seu link perdeu a validade</li>
                                        <li>O seu link já foi usado</li>
                                        <li>A integridade dos seus dados foi comprometida</li>
                                    </ul>
                                    <span style="text-align: center">Para a sua segurança, nós interrompemos o processo</span>
                                </div>`,
                        footer: '<span>Se o erro persistir, entre em contato com nosso suporte pelos telefones (49) 3340-0770 | (49) 99172 9123.</span>'
                    })
                    break;
            }
        },

        /**
         * limpa a sessão logada e retorno o usuario para o login
         * @author Lucas Eduardo
         */
        returnToLogin(){
            this.auth.logout()
            this.$router.push('/login')
        },

        /**
         * Responsavel por dar o elerta do token para os usuairo
         * @param { Number } retorno - codigo http
         * 1°) 406 - quando o servidor não encontrou o token 
         * 2°) 422 - quando o token perdeu a validadde
         * 3°) 200 - tnc (tudo nos conformes)
         * 4°) outro - caso não seja retornado um dos codigo esperados ele assume um erro e mantem a pagina sem carregar
         * @return none
         * @author Lucas Eduardo
         */
        verificaAviso(retorno){
            switch(retorno.statusError){
                case 406:
                    this.varianteFundo = 'danger'
                    this.mostrarLoading = false
                    this.mensagemAviso = 'O seu link de acesso é inválido, contate o suporte.'
                    break;
                case 422:
                    this.varianteFundo = 'warning'
                    this.mostrarLoading = false
                    this.mensagemAviso = 'O seu link de acesso único expirou, faça uma nova requisição.'
                    break;
                case 200:
                    this.form.email = retorno.data.dados[0].email
                    this.token = this.$route.params.token.toString()
                    this.mostrarLoading = false
                    this.showOverlay = false
                    break;
                default:
                    this.varianteFundo = 'warning'
                    this.mostrarLoading = false
                    this.mensagemAviso = 'Tivemos alguns problemas! Tente novamente mais tarde, se o erro persistir, entre em contato com nosso suporte pelos telefones (49) 3340-0770 | (49) 99172 9123.'
                    break;
            }
        },


        /**
         * Faz a parte de validar se o token para recuperação de senha é valida
         * @fires validaToken - passando o token da pagina
         * @fires verificaAviso - passando o status http do retorno
         * @return none
         * @author Lucas Eduardo
         */
        async validaTokenRecuperarSenha(){
            this.auth.validaToken(this.$route.params.token.toString())
            .then((retorno) =>{
                this.verificaAviso(retorno)
            })
        },

        /**
         * Faz a parte de validar se os token para mudar a senha
         * da primeira vez o usuairo foi cadastrado
         * @fires validaToken - passando o token da pagina
         * @fires verificaAviso - passando o status http do retorno
         * @return none
         * @author Lucas Eduardo
         */
        async validaTokenNovoUser(){
            this.auth.validaTokenNovoUser(
                this.$route.params.token.toString(), 
                this.$route.params.newuser.toString()
            ).then((retorno) =>{
                this.verificaAviso(retorno)
            })
        }
    },
    
    /**
     * Iniciado quando a pagina termina de carregar
     * verifica qual funcão chamar pelos tokens fornecidos a pagina
     */
    mounted(){
        if(this.$route.params.newuser){
            this.validaTokenNovoUser()
        }else{
            this.validaTokenRecuperarSenha()
        }
        
    }
};
</script>

<style lang="scss">
    #overlay-background-recuperar-senha{
        .bg-secondary{
            background-image: url("../../assets/fundoEagleLogin.png") !important;
            background-repeat: no-repeat !important;
            background-size: cover;
            background-position: center;
        }
    }

    #overlay-senha{
        // border: 1px solid rgba(7, 7, 7, 0.568);
        height: 750px;
        width: 850px;
        // background-color: rgba(0, 0, 0, 0.8);
        background-image: url("../../assets/logo.png") !important;
        background-size: 70% !important;
        background-repeat: no-repeat !important;
        background-size: cover;
        background-position: center;
        .div-texto-aviso{
            height: 100% !important;
            width: 100% !important;
            font-family: nexabold;
            font-size: 30px;
            overflow: hidden;
            // background-color: rgba(0, 0, 0, 0.568);
            .div-texto-escrito{
                width: 100% !important;
                height: 12%;
                background-color: rgba(0, 0, 0, 0.842);
                box-shadow: 0px 0px 30px 0px #000;
                font-size: 30px;
                margin-top: 40%;
            }
        }
        .danger{
            background-color: rgba(241, 86, 86, 0.527) !important;
            // animation: tremilico linear infinite alternate .5s;
        }
        .warning{
            animation: brilo-malero linear infinite alternate 2s;
            background-color: rgba(238, 241, 86, 0.527) !important;
        }
    }
    @keyframes brilo-malero{
        to{
            box-shadow: 0px 0px 30px 0px rgba(251, 255, 0, 0.952);
        }
        25%{
            box-shadow: 0px 0px 15px 0px rgba(251, 255, 0, 0.952);
        }
        50%{
            box-shadow: 0px 0px 0px 0px rgba(251, 255, 0, 0.952);
        }
        75%{
            box-shadow: 0px 0px 15px 0px rgba(251, 255, 0, 0.952);
        }
        from{
            box-shadow: 0px 0px 30px 0px rgba(251, 255, 0, 0.952);
        }
    }

    @keyframes tremilico{
        to{
            box-shadow: 0px 0px 30px 0px rgba(255, 0, 0, 0.952);
            transform: scale(1.009);
        }
        50%{
            box-shadow: 0px 0px 0px 0px rgba(255, 0, 0, 0.952);
            transform: scale(1);
        }
        from{
            box-shadow: 0px 0px 30px 0px rgba(255, 0, 0, 0.952);
            transform: scale(.998);
        }
    }

    #resetSenha{
        overflow: hidden;
        height:93vh;
        background-image: url("../../assets/fundoEagleLogin.png") !important;
        background-repeat: no-repeat !important;
        background-size: cover;
        background-position: center;
    }

    .resetSenha{
        border: 1px solid #0f4352;
        background: rgba(0, 0, 0, 0.842);
        .divCentralLogin>.divImgLogo{
            margin: auto;
        }
    }

    .divCentralLogin>.divReturnToLogin{
        padding: 5px; 
        border-bottom: 1px solid #d5d5d5;
        padding-bottom: 20px;
    }

    .divCentralLogin>.divReturnToLogin>.buttonReturnToLogin{
        float: left;
        text-decoration: none;
        color: #6c757d !important;
        font-weight: 100 !important;
        font-size: 13px;
        cursor: pointer;
    }

    .divCentralLogin>.divImgLogo>.imgLogo{
      width:50%;
    }

    .divCentralLogin{
        .divInputs{
            .input-senha{
                position: relative;
                span.icone-zoin{
                    cursor: pointer;
                    right: 2%;
                    // float: right !important;
                    top:10px;
                    position:absolute !important;
                }
            }
            input{
                background: transparent;
                border: 1px solid #0f4352;
                border-radius: 0px;
                margin-top: 10px;
                color: #fff;
            }
        }
    }

    .divCentralLogin>.divButtonLogin>.buttonSendEmail{
        border-radius:0px;
        text-align: center;
        width: 50%;
        background: #2e759a;
        border: 1px solid #0f4352;
        margin-top: 15px;
        color: #fff;
        font-weight: bold;
        /* border-bottom: 1px solid #d5d5d5; */
        margin-bottom: 10px;
        cursor: pointer;
    }

    .divCentralLogin{
        width: 40%;
        margin: auto;
        // box-shadow: 0px 0px 30px 0px #000;
        padding-bottom: 10px;
    }
    
</style>
