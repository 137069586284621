import {
    mdiCellphone,
    mdiMoped,
    mdiBusSide,
    mdiTractor,
    mdiTruck,
    mdiCarSports,
    mdiAlert,
    mdiCloseThick,
    mdiChevronLeftCircleOutline,
    mdiCarCog,
    mdiCarChildSeat,
    mdiSeatbelt,
    mdiCarSpeedLimiter,
    mdiTruckAlertOutline,
    mdiSnowflakeThermometer,
    mdiMoleculeCo2,
    mdiThermometerWater,
    mdiSpeedometer,
    mdiCogs,
    mdiCarEmergency,
    mdiCarConnected,
    mdiCarBrakeTemperature,
    mdiCreditCardWirelessOffOutline,
    mdiCheckBold,
    mdiGaugeFull,
    mdiMapMarkerPath,
    mdiWifiOff,
    mdiCarTractionControl,
    mdiSteeringOff,
    mdiCarOff,
    mdiMapMarkerRadius,
    mdiBatteryOffOutline,
    mdiCarWash,
    mdiCallSplit,
    mdiMapMarkerRemove,
    mdiTransitConnectionVariant
} from "@mdi/js";

export class IconManager {

    private static icons = {
        mdiCellphone: mdiCellphone,
        mdiMoped: mdiMoped,
        mdiBusSide: mdiBusSide,
        mdiTractor: mdiTractor,
        mdiTruck: mdiTruck,
        mdiCarSports: mdiCarSports,
        mdiAlert: mdiAlert,
        mdiCloseThick: mdiCloseThick,
        mdiChevronLeftCircleOutline: mdiChevronLeftCircleOutline,
        mdiCarCog: mdiCarCog,
        mdiCarChildSeat: mdiCarChildSeat,
        mdiSeatbelt: mdiSeatbelt,
        mdiCarSpeedLimiter: mdiCarSpeedLimiter,
        mdiTruckAlertOutline: mdiTruckAlertOutline,
        mdiSnowflakeThermometer: mdiSnowflakeThermometer,
        mdiMoleculeCo2: mdiMoleculeCo2,
        mdiThermometerWater: mdiThermometerWater,
        mdiSpeedometer: mdiSpeedometer,
        mdiCogs: mdiCogs,
        mdiCarEmergency: mdiCarEmergency,
        mdiCarConnected: mdiCarConnected,
        mdiCarBrakeTemperature: mdiCarBrakeTemperature,
        mdiCreditCardWirelessOffOutline: mdiCreditCardWirelessOffOutline,
        mdiCheckBold: mdiCheckBold,
        mdiGaugeFull: mdiGaugeFull,
        mdiMapMarkerPath: mdiMapMarkerPath,
        mdiWifiOff: mdiWifiOff,
        mdiCarTractionControl: mdiCarTractionControl,
        mdiSteeringOff: mdiSteeringOff,
        mdiCarOff: mdiCarOff,
        mdiMapMarkerRadius: mdiMapMarkerRadius,
        mdiBatteryOffOutline: mdiBatteryOffOutline,
        mdiCarWash: mdiCarWash,
        mdiCallSplit: mdiCallSplit,
        mdiMapMarkerRemove: mdiMapMarkerRemove,
        mdiTransitConnectionVariant: mdiTransitConnectionVariant
    }

    public static getIcons(): object {
        return this.icons
    }

    public static getIconeAlerta(tipo: string | null): string {
        if (tipo && this.icons.hasOwnProperty(tipo)) {
            return this.icons[tipo]
        }
        return ''
    }
}