import Cadastros from '../../Children.vue'
import NaoMenu from '../../Children.vue';
import Relatorio from '../../Children.vue'
import { RouteConfig } from 'vue-router'
import { AuthService } from '../../../Services/auth/Auth.Service';
import Layout from '../../../components/Atom/Layout/Layout.vue'
///

import ListagemRotas from '@/views/EagleCargo/Cadastros/ListagemRotas.vue'
import CadastroRotas from '@/views/EagleCargo/Cadastros/CadastroRotas.vue'
import PainelControle from '@/views/EagleCargo/NaoMenus/PainelControle.vue'
import Timeline       from '@/views/EagleCargo/Relatorios/Timeline.vue'

// src/views/EagleCargo/Cadastros/CadastroRotas.vue


export const ifAuthenticated = (_to: any, _from: any, next: any) => {
	if (new AuthService().isAuthenticated()) {
		next()
		return
	}
    type numero= 'N' | 'E';
    next('/login')
}
export const eaglecargoRoutes: RouteConfig =
{
	path: '/eagle/cargo',
	name: 'eagle cargo',
	beforeEnter: ifAuthenticated,
	meta: {
		requiresAuth: true,
		Module: 'eagle/cargo',
		breadcrumbName: '/eagle/cargo'
	},
	components: { default: Layout },
	children: [
      
		// Cadastros
		{
			path: 'cadastros', 
			meta:{ 
				Module: 'eagle/cargo', 
				breadcrumbName: 'Cadastros' 
			}, 
			components: 
			{ 
				content: Cadastros 
			}, 
			children: [
				{ 
					path: 'rotas',
					name: 'listagemDeRotas',
					meta: {
						Module: 'eagle/cargo',
						breadcrumbName: 'Cadastros de rotas'
					}, 
					component: ListagemRotas
				},
				{ 
					path: 'novas/rotas/:id?',
					name: 'cadastroDeRotas',
					meta: {
						Module: 'eagle/cargo',
						breadcrumbName: 'Cadastros de rotas'
					}, 
					component: CadastroRotas
				},
			]
		},

		// Nao menu
		{
			path: '', 
			meta: {
				Module: 'eagle/cargo',
				breadcrumbName: ''
			},
			components: {
				content: NaoMenu
			},
			children: [
				{ 
					path: 'painel/controle',
					name: 'painelControle',
					meta: {
						Module: 'eagle/cargo',
						breadcrumbName: 'Painel de Controle'
					}, 
					component: PainelControle
				},
			]
		},

		// Relatorios 
		{
			path: 'relatorios',
			meta:
			{
				Module: 'eagle/cargo',
				breadcrumbName: 'Relatorios'
			},
			components:
			{
				content: Relatorio
			},
			children: [
				{ 
					path: 'timeline',
					name: 'timeline',
					meta: {
						Module: 'eagle/cargo',
						breadcrumbName: 'Timeline'
					}, 
					component: Timeline
				},
			]
		},
	]
}