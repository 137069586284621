<template>
    <eaglePanel id="Veículos" :loading="carregando">
        <div class="col-sm-12 row nopadding">
            <div class="col-sm-6">
                <titulo titulo="Veículos" :icon="mdiCarMultiple" />
            </div>
            <div class="col-sm-6 divDontPrint">
                <botoes
                    :disabledDropdown="!Object.keys(dataT).length"
                    linkNew="cadastrarVeiculo"
                    tipoNovo="button"
                    :loading="loading"
                    @exportarCadastro="exportar"
                />
            </div>
            <div class="col-sm-12"><hr /></div>
            <div class="col-lg-12 row nopadding">
                <div class="col-lg-4 nopadding divDontPrint">
                    <selectAll
                        nameForRadio="selectEmpresa"
                        :selected="firstOne"
                        :labels="labelSeletorEmpresas"
                        :isMultiple="true"
                        :hasSelectAll="true"
                        :optionsArray="optSelectEmpresas"
                        @changeSelect="mudaFiltroEmpresa"
                    />
                </div>
                <div class="col-lg-5 nopadding left divDontPrint">
                    <bfilter
                        label="Status"
                        :onlyOneSelected="true"
                        :arrayButtons="botoesStatus"
                        @buttonsFiltersChange="mudaBotaoStatus"
                    />
                    <div class="filtro">
                        <bfilter
                            label="Módulo"
                            :onlyOneSelected="true"
                            :arrayButtons="botoesModulos"
                            @buttonsFiltersChange="mudaBotaoModulo"
                        />
                    </div>
                </div>
                <div class="col-sm-12"><hr /></div>
            </div>
        </div>
        <tableL :titles="titulosT" :data="dataT">
            <template #acoes="{ row }">
                <simpleButton
                    v-if="getEditar()"
                    type="green"
                    :icon="mdiPencil"
                    title="Editar Veículo"
                    event="editarCadastro"
                    width="31%"
                    @editarCadastro="editar(row.codigo)"
                />
                <template v-if="getCadastrar()">
                    <simpleButton
                        type="orange"
                        :icon="mdiCarCog"
                        title="Solicitar Manutenção"
                        event="solicitarManutencao"
                        width="31%"
                        @solicitarManutencao="
                            modalSolicitarManutencao(
                                row.veplaca,
                                row.veprefixo,
                                row.codigo
                            )
                        "
                    />
                </template>
                <simpleButton
                    v-if="getMaster() && getExcluir()"
                    :type="row.status == 'A' ? 'red' : 'light-green'"
                    :icon="row.status == 'A' ? mdiCircleOffOutline : mdiCheckBold"
                    :title="row.status == 'A' ? 'Desativar Veículo' : 'Ativar Veículo'"
                    event="alterarStatus"
                    width="31%"
                    @alterarStatus="mudaStatus(row.codigo)"
                />
            </template>
        </tableL>
        <ModalSolicitaManutencaoVeiculos
            :veiculo="{
                veplaca: placaVeiculo,
                veprefixo: prefixoVeiculo,
                vecodigo: codigoVeiculo,
            }"
        >
        </ModalSolicitaManutencaoVeiculos>
    </eaglePanel>
</template>
<script lang="js">
import { EmpresasService } from '@/Services/auth/Empresas.service';
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import Vue from 'vue';
import { mdiCarMultiple, mdiCheckBold, mdiCarCog, mdiPencil, mdiCircleOffOutline } from '@mdi/js'
import { mapGetters } from 'vuex'
import { conectionError } from '@/Services/Helpers/swellHeper';
export default Vue.extend({
	name:'listagemVeiculos',
	components:{
		'eaglePanel'             		 : require('@/components/Atom/Panel/PanelEagle').default,
		'titulo'                 		 : require('@/components/Atom/Header/Titulo').default,
		'botoes'                 		 : require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
		'selectAll'              		 : require('@/components/Atom/Select/SelectAll').default,
		'bfilter'                		 : require('@/components/Atom/Buttons/ButtonsFilters').default,
		'tableL'                 		 : require('@/components/Atom/Table/TableListagem').default,
		'simpleButton'           		 : require('@/components/Atom/Buttons/SimpleButton').default,
		'ModalSolicitaManutencaoVeiculos': require('@/components/Atom/ModalSpecific/ModalSolicitaManutencaoVeiculos').default,

	},
	data(){
		return{
			codigoVeiculo: 0,
			placaVeiculo: '',
			prefixoVeiculo:'',
			mdiCarMultiple: mdiCarMultiple,
			mdiCheckBold  : mdiCheckBold,
			mdiCarCog : mdiCarCog,
			mdiPencil : mdiPencil,
			mdiCircleOffOutline: mdiCircleOffOutline,
			//misc
			firstOne : [],
			loading: [false, false, false],
			// opt
			optSelectEmpresas  : [],
			// labels:
			labelSeletorEmpresas :[{
				indexDFH     :'EM',
				description  :'Empresas'}],
			// botoes
			botoesStatus:[
				{
					'value':'A',
					'title':'Ativos',
					'text':'Ativos',
					'width':'32%',
					'isSelected':true,
					'icon':''},
				{
					'value':'I',
					'title':'Inativos',
					'text': 'Inativos',
					'width': '32%',
					'isSelected':false,
					'icon':''},
				{
					'value':'T',
					'title':'Todos',
					'text': 'Todos',
					'width': '32%',
					'isSelected':false,
					'icon':''
				},
			],
			botoesModulos:[
				{
					'value':'A',
					'title':'Com',
					'text': 'Com',
					'width': '32%',
					'isSelected':true,
					'icon':''
				},
				{
					'value':'I',
					'title':'Sem',
					'text': 'Sem',
					'width': '32%',
					'isSelected':false,
					'icon':''
				},
				{
					'value':'T',
					'title':'Todos',
					'text': 'Todos',
					'width': '32%',
					'isSelected':false,
					'icon':''
				},
			],

			formulario:{
				empresas : [],
				status   : 'T',
				modulo   : 'T',
			},
			// links adminstrativo
			urlBase     : '/administrativo/cadastros/veiculos/',
			linkExporta : '/administrativo/cadastros/veiculos/exportar',
			// data         adminstrativo
			titulosT : [
				{ 'number': 'veplaca'     ,'name': 'Placa'     },
				{ 'number': 'veprefixo'   ,'name': 'Prefixo'   },
				{ 'number': 'vchodometro' ,'name': 'Hodômetro' },
				{ 'number': 'vemodulo'    ,'name': 'Módulo'    },
				{ 'number': 'vedescricao' ,'name': 'Descrição' },
				{ 'number': 'clfantasia'  ,'name': 'Empresa'   },
			],
			dataT : [],
			carregando:false,
			preSelectEmpresas : []
		}
	},
	methods:{

		...mapGetters(['getMaster', 'getCadastrar', 'getEditar', 'getExcluir']),

		ativaRodinha(tipo=null){
			if(tipo==='pdf'){
				this.loading = Array(true, false, false)
			}else if(tipo==='xls'){
				this.loading = Array(false, true, false)
			}else if(tipo==='csv'){
				this.loading = Array(false, false, true)
			}else{
				this.loading = Array(false, false, false)
			}
		},
		mudaFiltroEmpresa(value){
			this.formulario.empresas = value
			this.carregaCarros()
		},

		mudaBotaoStatus(value){
			if(value[0]!=='T'){
				this.formulario.status = value;
			}else{
				this.formulario.status = ['A', 'I']
			}
			this.botoesStatus[0].icon = ''
			this.botoesStatus[1].icon = ''
			this.botoesStatus[2].icon = ''
			if(value[0] === 'A'){
				this.botoesStatus[0].icon = mdiCheckBold
			} else if(value[0] === 'I'){
				this.botoesStatus[1].icon = mdiCheckBold
			} else if(value[0] === 'T'){
				this.botoesStatus[2].icon = mdiCheckBold
			}
			this.carregaCarros()
		},

		mudaBotaoModulo(value){
			this.formulario.modulo = value[0];
			this.botoesModulos[0].icon = ''
			this.botoesModulos[1].icon = ''
			this.botoesModulos[2].icon = ''
			if(value[0] === 'A'){
				this.botoesModulos[0].icon = mdiCheckBold
			} else if(value[0] === 'I'){
				this.botoesModulos[1].icon = mdiCheckBold
			} else if(value[0] === 'T'){
				this.botoesModulos[2].icon = mdiCheckBold
			}
			this.carregaCarros()
		},

		carregaCarros(){
			var obj = {'info': this.formulario};
			if(this.formulario.empresas.length > 0){
				this.carregando = true
				let rota = this.urlBase+'listar'
				new HttpRequest().Post(rota, obj).then((data)=>{
					this.carregando = false
					this.dataT = data.data.carros
				})
			}else{
				this.dataT = []
			}
		},

		mudaStatus(value){
			var obj = {
				codigo:value
			}
			let rota = this.urlBase+'altstatus'
			new HttpRequest().Post(rota, obj).then((data) => {
				if (data.status && data.data.sucess) {
					this.carregaCarros()
				} else {
					if (!data.data.limite) {
						let msg = 'Quantidade de veículos excedeu o limite do contrato!'
						conectionError(msg, 'warning');
					}
				}
				if(data.data.errors){
					let msg = 'Erro ao remover veiculo do grupo!'
						conectionError(msg, 'warning');
				}

			})
		},

		exportar(value){
			var root =  process.env.VUE_APP_ROOT
			var obj={
				para  : value,
				dados : this.dataT
			}
			if(this.formulario.empresas.length > 0){
				this.ativaRodinha(value)
				new HttpRequest().Post(this.linkExporta, obj).then((data)=>{
					window.open(root+'/'+data.data.local)
					this.ativaRodinha()
				})
			}
		},
		editar(value){
			this.$router.push({name:'editarVeiculo', params:{'id':value}})
		},

		selectAllClientes(){
			if(this.getMaster())
				this.firstOne = []
			else
				this.firstOne =  new EmpresasService().Get()
		},

		/**
        * @description - Este método abre o modal que visa solicitar uma manutenção.
		*  Esse método também leva a placa e o prefixo do veículo selecionado para o título do modal.
        * @param {string} placa - Recebe a 'veplaca', que é o dado que contém a placa de um veículo;
		* @param {string} prefixo - Recebe o 'veprefixo', que é o dado que contém o prefixo de um veículo;
        * @author Daniel Vitor Nascimento
 		*/
		modalSolicitarManutencao(placa,prefixo,codigoveiculo){
			this.$bvModal.show('modal-solicitacao-manutencao');
			this.placaVeiculo = placa
			this.prefixoVeiculo = prefixo
			this.codigoVeiculo = codigoveiculo
		},
	},

	mounted(){
		this.optSelectEmpresas = new EmpresasService().Get()
		this.selectAllClientes()
	}
})
</script>
<style lang="scss" scoped>
.left {
    text-align: left !important;
}
.filtro {
    padding: 1.5% 0 0 0;
}
</style>
