<template>
    <panelEagle id="justificativas"
    :loading="loadingPanelEagle">
        <div class="col-sm-12 nopadding">
           <div class="col-sm-12 row nopadding">
                <div class="col-sm-6 row ">
                    <tituloPage titulo='Justificativas' 
                    :icon='mdiFileDocumentEdit'/>
                </div>
               <div class="col-sm-6 divDontPrint">
                    <botoesEIN
                    :loading="loadingExporta"
                    tipoNovo="button"
                    linkNew="CadastroJustificativaJornada"
                    @exportarCadastro="exportarJustificativas"
                    :disabledDropdown="$v.dataTable.$invalid">
                    </botoesEIN>
                </div>
                <div class="col-sm-12"><hr></div>
                <div class="col-sm-5 nopadding divDontPrint">
                   <selectAll
                    :selected="selectedEmpresa"
                    nameForRadio='FiltroEmpresas'
                    :labels="labels"
                    :isMultiple='true'
                    :optionsArray='optSelectEmpresas'
                    :hasSelectAll='true'
                    @changeSelect='selecionaEmpresa'/>
                </div>
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <tableListagem
        :loading='loadingTableListagem'
        :titles='titulosTable'
        :data='dataTable'
        deleteEdit='editDelete'
        @excluirCadastro='excluirCadastro'
        @editarCadastro='editarJustificativas'>
        </tableListagem>
    </panelEagle>
</template>

<script>
import Vue from 'vue'
import { mdiFileDocumentEdit } from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { required } from 'vuelidate/lib/validators'
import { conectionError }from '@/Services/Helpers/swellHeper'
import { mapGetters } from 'vuex'

export default {
    components: {
        panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
        tituloPage: require('@/components/Atom/Header/Titulo').default,
        botoesEIN: require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
        selectAll: require('@/components/Atom/Select/SelectAll').default,
        tableListagem: require('@/components/Atom/Table/TableListagem').default,
    },

    validations: {
        dataTable: { required },
    },

    data() {
        return {
            mdiFileDocumentEdit: mdiFileDocumentEdit,
            loadingExporta: [false, false, false],
            loadingPanelEagle : false,
            optEmpresas: new EmpresasService().Get(),
            url: '/controle/jornada/cadastros/justificativas/jornada/',
            loadingTableListagem: false,
            valueFiltroStatus:[],
            valueEmpresasSelecionadas:[],
            optSelectEmpresas: [],
            selectedEmpresa: [],
            dataTable: [],
            labels:[
                { indexDFH : 'EM', description : 'Empresas' }
            ],
            titulosTable:[
                {'name' : 'Empresa',       'number' : 'clfantasia'},
                {'name' : 'Descrição',     'number' : 'jjdescricao'},
            ],
        }
    },

    methods: {
        editarJustificativas(value){
            this.$router.push({name:'CadastroJustificativaJornada', params:{id:value}})
        },

        selecionaEmpresa(value){
            this.valueEmpresasSelecionadas = value;
            this.listarJustificativas();
        },

        /**
         *  @description Método para buscar dados para listagem de justificativas
         *  também cria o objeto a ser levado ao back
         *  @author Marcos
         */
        async listarJustificativas(){
            this.limpaDados();
            if(this.valueEmpresasSelecionadas.length > 0){
                this.loadingPanelEagle = true
                var obj = {
                    'empresas': this.valueEmpresasSelecionadas,
                    'status'  : this.valueFiltroStatus,
                }; 
                new HttpRequest().Post(this.url+'listar', obj)
                .then( dados =>{
                    this.dataTable = dados.data.dados
                    this.loadingPanelEagle = false
                }).catch(()=>{
                    conectionError();
                }).finally(() => this.loadingPanelEagle = false );
            }
        },

        limpaDados(){
            this.dataTable = [];
        },

        exportarJustificativas(value){
            switch (value) {
                case 'pdf': this.loadingExporta = [true, false, false]; break;
                case 'xls': this.loadingExporta = [false, true, false]; break;
                default: this.loadingExporta = [false, false, true]; break;
            }
            var root =  process.env.VUE_APP_ROOT;
            var obj = {
                tipo:value,
                justificativas:this.dataTable
            }
            new HttpRequest().Post(this.url+'exportar', obj)
                .then(dados=>{
                    window.open(root+'/'+dados.data.local); 
                }).catch(() => {
                    conectionError();
                }).finally(()=>{ this.loadingExporta = [false, false, false]} )
        },

        excluirCadastro(value){
            this.loadingPanelEagle = true
            new HttpRequest().Post(this.url+'excluir', {'dados':value})
                    .catch(() =>{
                        conectionError()
                    }).finally(() => {
                        this.loadingPanelEagle = false
                        this.listarJustificativas()
                    })

        },

        /**
         *  @description Método para quando for um usuário comum vir todas as empresas selecionadas
        */
        selectAllClientes(){
            this.optSelectEmpresas =  new EmpresasService().Get()
            if(!this.getMaster()) this.selectedEmpresa = this.optSelectEmpresas
        },
        ...mapGetters(['getMaster']),
    },
    mounted(){
        this.selectedEmpresa = this.optSelectEmpresas
        this.selectAllClientes()
    }
}
</script>

<style lang="">
    
</style>