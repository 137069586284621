<template>
    <div class="col-sm-12 " :class="extraClassParent">
        <div class="col-sm-12 nopadding subTitleSelect divUnicaNoRadio">
            <b-form-group>
                <b-form-radio-group
					v-model="labelSelected"
					:name="nameForRadio">
                    <b-form-radio
						v-for="(label) in labels"
						v-bind:key="label.indexDFH"
						v-model="labelSelected"
						checked
						class="mb-03"
						:name="nameForRadio"
						:value="label.indexDFH">
						{{label.description}}
				</b-form-radio>
                </b-form-radio-group>
            </b-form-group>
        </div>
        <div class="col-sm-12 nopadding" >
            <Multiselect
                v-bind='$attrs'
                :class='extraClass'
                v-model="selectedItens"
				:title="loading ? 'Aguarde...' : ''"
				:disabled="loading"
                :value="'value'"
                :label="'description'"
                :options="optionsSelect"
                :placeholder="'Selecione...'"
                selectLabel="•"
                selectedLabel="✔"
                deselectLabel="x"
                :multiple="isMultiple"
                :taggable="taggable"
                track-by="value"
                :max-height="600"
                :limit="taggable ? 20 : 1"
                :limitText="count => `e mais ${count}`"
                :closeOnSelect="!isMultiple"
                v-on="$listeners"
                @input="changeInput"
                @select="selectAll"
                @close='closeSelect'
                @open='open'
				@tag="addTag"
                :preselect-first="false">
                <template v-if="isMultiple" slot="clear" slot-scope="props">
                    <div
                        class="multiselect__clear"
                        v-if="selectedItens.length"
                        @mousedown.prevent.stop="clearAll(props.search)">
                        <BIcon icon="x-square-fill"></BIcon>
                    </div>
                </template>
                <template slot="noResult">
                    Nenhum elemento encontrado
                </template>
                <template slot="noOptions">
                    Nenhum elemento encontrado
                </template>
                <template 
                    slot="option" 
                    slot-scope="props">
                    <div class="customOption">
                        <!-- <div class="circleOption" 
                            :style="{'background-color':props.option.cor}">
                        </div> -->
                        <div class="descOption"
							:style="{'background-color':props.option.cor}">
							{{ props.option.description }}
						</div>
						<BIcon 
							v-if="taggable"
							:id="'iconeExcluir' + props.option.value"
							class="cursor-pointer"
							@click.stop.prevent="excluirMarcador(props.option.value)" 
							icon="trash"
						/>
                    </div>
                </template>
				<template 
                    slot="tag" 
                    slot-scope="props">
                    <div class="customOption">
                        <!-- <div class="circleOption" 
                            :style="{'background-color':props.option.cor, margin: '3px'}">
                        </div> -->
                        <div class="descOption"
							:style="{'background-color':props.option.cor}">
							{{ props.option.description }}
						</div>
                    </div>
                </template>
            </Multiselect>
        </div>
		<modalDeleta 
			@confirmaExclusao="confirmarExcluirMarcador" 
			:id="id"
			:title="'Excluir marcador'" >
			<slot name="modalBody">
				<div class="col-sm-12 nopadding">
					Você tem certeza que deseja realizar essa operação?
				</div>
				<div class="col-sm-12 nopadding">
					<br>
					<h5>
					<template v-if="loadingMarcadores">
						Verificando pedidos associados, aguarde...
					</template>
					<template v-else>
						Há <strong>{{ totalMarcadores }}</strong> pedidos vinculados a esse marcador.
					</template>
					</h5>
				</div>
			</slot>
		</modalDeleta>
    </div>
</template>

<script>
import Vue from 'vue'
import Multiselect from 'vue-multiselect'
import { isArray } from 'util'
import { pickColor } from '@/views/Roteirizador/NaoMenus/RotaManual'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'

export default {
	inheritAttrs: false,
	components: {
		Multiselect,
		modalDeleta: require("@/components/Atom/ModalSpecific/ModalDeleta").default,
	},
	
	props: {
		id: {
			type: String,
			default: 'select-marcadores',
		},
		cliente: {
			type: Number,
			default: 0,
		},
		extraClassParent:{
			type: [String, Object],
			default: '',
		},
		extraClass:{
			type: [String, Object],
			default: '',
		},
		nameForRadio: {
			type: String,
			required: true,
		},
		isMultiple: {
			type: Boolean,
			default:false
		},
		hasSelectAll: {
			type: Boolean,
			default: false
		},
		taggable: {
			type: Boolean,
			default: false
		},
		firstSelected: {
			type:String,
			default: ''
		},
		labels: {
			type: Array,
			required: false,
			default: function(){return []}
		},
		checks: {
			type: Array,
			required: false,
			default: function(){return []}
		},
		optionsArray: {
			type: Array,
			required: true,
		},
		typeInput: {
			type: String,
			required: false,
			default: ''
		},
		selected:{
			type: Array,
			required: false,
			default: function(){return []}
		},
		loading: {
			type: Boolean,
			default: false
		},
	},

	data(){
		var selectedItens = this.selected;
		var checked = [];
		return {
			selectedItens,
			labelSelected: this.firstSelected,
			checked,
			valorExcluir: '',
			totalMarcadores: 1,
			loadingMarcadores: false,
		}
	},

	methods: {
		closeSelect(direto=false){
			if(direto) this.$emit('close-select', [])
			var itensSelecionados = []
			if(this.selectedItens) {
				if(this.isMultiple){
					itensSelecionados = this.selectedItens.map((item)=>{
						return item.value
					})
				}else
					itensSelecionados = [this.selectedItens.value]
			}
			this.$emit('close-select', itensSelecionados)
		},

		clearAll(){
			this.selectedItens = []
			this.changeLabelSelect()
			this.changeInput([])
		},

		selectAll(event){
			var arraySelect = []
			if(!this.isMultiple) return 
			if(this.selectedItens != null){
				for(let index in this.selectedItens){
					arraySelect.push(this.selectedItens[index].value)
				}
			}
			if(event.value === 'ST' && this.isMultiple){
				for (let item of this.optionsSelect){
					if (item.value !== 'ST' && arraySelect.indexOf(item.value) === -1){
						this.selectedItens.push(item)
					}
				}
			}
		},

		open(event){
			this.$emit('open', event)
		},

		changeInput(event){
			var arraySelect = [];
			if(Array.isArray(event)){
				for(let selecionado of event){
					if(selecionado.value !== 'ST'){
						arraySelect.push(selecionado.value);
					}
				}
			} else if(event != null){
				if(event.value !== 'ST'){
					arraySelect.push(event.value);
				}
			}
			if(event != null){
				this.$emit('changeSelect',
					arraySelect,
					event.value,
					event.description,
					event?.vinculado
				);
			}else{
				this.$emit('changeSelect', arraySelect, '', '');
			}
		},

		changeLabelSelect(){
			this.$emit('changeCheck', this.labelSelected, true);
		},

		addTag(tag) {
			if (tag) {
				tag = tag.trim()
				let tags = tag.split(' ').filter(e => e)
				tags.forEach(e => {
					let item = { value: e, description: e, cor: pickColor(e) }
					if (!this.optionsArray.some(op => e === op.value)) {
						this.optionsArray.push(item)
						this.selectedItens.push(item)
					}
				})
			}
		},

		excluirMarcador(value) {
			this.valorExcluir = value
			this.$bvModal.show('modal-deleta-' + this.id)
			this.buscarTotalMarcadores(value)
		},

		async buscarTotalMarcadores(value) {
			let url = '/roteirizador/rota/manual/itens/marcador/total'
			let body = { value, cliente: this.cliente }
			this.loadingMarcadores = true
			await new HttpRequest().Post(url, body)
				.then(({status, data}) => {
					if (status) {
						this.totalMarcadores = data.total
					}
				})
				.catch((e) => {
					conectionError()
				})
				.finally(() => {
					this.loadingMarcadores = false
				})
		},

		confirmarExcluirMarcador() {
			this.$emit('excluir', this.valorExcluir)
			this.valorExcluir = ''
		},
	},

	mounted: function(){
		this.$emit('changeCheck', this.labelSelected, false);
		this.$emit('selectCriado', this);
	},

	computed:{
		optionsSelect(){
			if(this.hasSelectAll && !this.taggable){
				return [
					{
						value: 'ST',
						description:'Selecionar Todos'
					},
					...this.optionsArray
				]
			}else{
				return this.optionsArray
			}
		}
	},

	watch:{
		selected(){
			if(isArray(this.selected)){
				this.selectedItens = this.selected
				this.changeInput(this.selected)
			}
		},
	}
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
    .subTitleSelect{
        font-size: 13px;
        text-align: left;
        padding-left: 5px !important;
		font-family: nexabook, sans-serif !important;
        .form-group{
            margin-bottom: 0rem !important;
        }

        &.divUnicaNoRadio{
            .custom-control{
                padding-left: 0rem;
                .custom-control-label{
                    &::before{
                        display: none;
                    }
                    &::after{
                        display: none;
                    }
                }
            }
        }
    }
.customOption {
	display: inline-flex;
	margin-right: 5px;
	margin-bottom: 5px;

	.circleOption {
		width: 15px;
		height: 15px;
		border-radius: 15px;
		margin-right: 5px;
	}

	.descOption {
		border-radius: 5px;
		padding: 4px 6px 0 6px;
		overflow: hidden;
		word-wrap: break-word;
	}

	.bi-trash {
		margin: 3px 2px 0 3px;
		color: red;
	}
}
</style>
