<template>
  <PanelEagle id="bancoTickets" :loading="loadingPanel">
    <div class="col-sm-12 row nopadding ">
      <div class="col-sm-12 row nopadding">
        <div class="col-sm-6">
          <tituloPage :icon="mdiMonitorDashboard" titulo="Controle de cargas" />
        </div>

        <div class="col-sm-6">
          <SimpleButton text="Gerar" :icon="mdiMonitorDashboard" type="green" event="gerar" @gerar="getItensRotas"
            style="float:right;" :disabled="$v.$invalid || loadingRotas" />
        </div>

        <slideUpAndDown>
          <slot slot="filters">
            <div class="col-sm-12">
              <hr />
            </div>

            <div class="col-sm-12 row nopadding divDontPrint">
              <div class="col-sm-3 nopadding">
                <InputRangeWithLimit name="selectData" titulo="Intervalo" :isObrigatorio="true"
                  @changeInput="changeData" />
              </div>

              <div class="col-sm-3 nopadding">
                <SelectAll ref="selectEmpresasTickets" nameForRadio="seletorEmpresas" :labels="[{
                  indexDFH: 'E',
                  description: 'Empresas*'
                }]" :optionsArray="optionsSelectEmpresas" firstSelected="E" :hasSelectAll="false" :isMultiple="false"
                  @changeSelect="changeEmpresas" />
              </div>

              <div class="col-lg-3 nopadding">
                <selectAll nameForRadio='seletorVeiculos' ref="seletorVeiculos" :disabled='optionsVeiculos.length <= 0'
                  :isMultiple='true' :optionsArray='optionsVeiculos' :labels='labelsVei' :hasSelectAll='true'
                  @changeSelect="changeVeiculos" :loading="loadingVeiculos" />
              </div>

              <div class="col-lg-3 nopadding">
                <selectAll nameForRadio='seletorRota' ref="seletorRota" :isMultiple='true' :optionsArray='optionsRotas'
                  :loading="loadingRotas" :labels='labelsRot' :hasSelectAll='true' @changeSelect="changeRotas"
                  :disabled='optionsRotas.length <= 0' />
              </div>
            </div>
          </slot>
        </slideUpAndDown>

        <div class="styleDivMapaListaPedidos col-sm-12 mt-2">
          <!-- Lista com os itens da rota -->
          <div style="width: 40%; padding: 0px" v-if="expandirMapa">
            <div style="height: 350px; overflow-y: auto; padding: 0px;">
              <draggable class="list-group" v-model="itensRota" tag="ul" v-bind="dragOptions" :group="{
                name: 'description',
                pull: 'clone',
                put: false,
              }" @end="endDrag">
                <!-- v-show="e.show != false" -->
                <li class="list-group-item" v-for="(item, i) in itensRota" :key="i + '_pedido_' + item.ircodigo"
                  @click="flyToPedido(item)">
                  <cardPedido :disabledTempoParado="true" :check="false" :pedido="item" :rota="rota"
                    @selecionado="selecionaPedido(item)" />
                </li>
              </draggable>
            </div>
          </div>

          <!-- mapa de visualizar rota -->
          <div :style="{ width: tamanhoDivMapa + '%' }">
            <mapaSimples height="350" ref="mapaSimples">
              <!-- Alerta informando o usuário para relaizar uma nova roteirização -->
              <div v-show="showTop">
                <b-alert v-model="showTop" class="m-0 rounded-0" style="z-index: 2000" variant="warning">
                  <div class="d-flex justify-content-center align-items-center nopadding">
                    <span class="nopadding">
                      <b-icon icon="circle-fill" animation="throb" font-scale="1"></b-icon>
                      Você precisa realizar a roteirização novamente!
                      <b-icon icon="circle-fill" animation="throb" font-scale="1"></b-icon>
                    </span>
                    <span class="styleTextRoteirizarNovamente nopadding"
                      @click="roteirizarPedidosPorTipo(opcaoEscolhidaRoteirizar)">
                      , <span style="text-decoration: underline;">clique aqui!</span>
                    </span>
                  </div>
                </b-alert>
              </div>

              <!-- Rastro de rota com carga agrupada -->
              <div v-if="arrayRastro">
                <polilinhaCorDiferente :colorPolilyne='geraCor()' :latLng="arrayRastro" :poliEditavel="false" />
              </div>

              <!-- Rastro de rota para desagrupar -->
              <div>
                <polilinhaCorDiferente :colorPolilyne='geraCor()' :latLng="rotaDesagrupar || []" :poliEditavel="false" />
                <!-- Ponto inicial e final -->
                <div v-if="showPontosMapaInicialFinalRotaAgrupada">
                  <MarkerPoint :latitude="parseFloat(inicialFinalDesagrupar.rolatInicio) || 0"
                    :longitude="parseFloat(inicialFinalDesagrupar.roLonInicio) || 0" :icon="getIconInicialFinal(0)"
                    typeIcon="divIcon">
                    <popupMarker>
                      <div class="col-sm-12 row nopadding div-popup">
                        <div class="d-flex align-items-center">
                          <baseIcon size='30' class="styleIcon mr-1" :icon="mdiMapMarkerCircle" />
                          <p class="fs-4">{{ inicialFinalDesagrupar.ropontosaida
                          }}</p>
                        </div>

                        <div class="styleDivider mb-2 mt-2">
                        </div>

                        <div class="d-flex align-items-center">
                          <baseIcon size='30' class="styleIcon mr-1" :icon="mdiOfficeBuildingMarker" />
                          <p class="fs-4">{{ inicialFinalDesagrupar.poenderecoInicio }}</p>
                        </div>
                      </div>
                    </popupMarker>
                  </MarkerPoint>

                  <MarkerPoint :latitude="parseFloat(inicialFinalDesagrupar.rolatRetorno) || 0"
                    :longitude="parseFloat(inicialFinalDesagrupar.roLonRetorno) || 0" :icon="getIconInicialFinal(1)"
                    typeIcon="divIcon">
                    <popupMarker>
                      <div class="col-sm-12 row nopadding div-popup">
                        <div class="d-flex align-items-center">
                          <baseIcon size='30' class="styleIcon mr-1" :icon="mdiMapMarkerCircle" />
                          <p class="fs-4">{{ inicialFinalDesagrupar.ropontoretorno }}</p>
                        </div>

                        <div class="styleDivider mb-2 mt-2">
                        </div>

                        <div class="d-flex align-items-center">
                          <baseIcon size='30' class="styleIcon mr-1" :icon="mdiOfficeBuildingMarker" />
                          <p class="fs-4">{{ inicialFinalDesagrupar.poenderecoRetorno }}</p>
                        </div>
                      </div>
                    </popupMarker>
                  </MarkerPoint>
                </div>

                <!-- Pontos da rota -->
                <div v-if="showPontosCargaMapa">
                  <ClusterSelecionados :disableClusterZoom="16" :maxClusterRadius="20" :chunkDelay="50"
                    :chunkInterval="50">
                    <div v-for="(pontos, index) in inicialFinalDesagrupar.itens" v-bind:key="index">
                      <LMarker :lat-lng="[pontos.polatitude, pontos.polongitude]" :icon="getIcon(pontos.irordem)">
                        <popupMarker>
                          <div class="col-sm-12 row nopadding div-popup">
                            <div class="d-flex align-items-center">
                              <baseIcon size='30' class="styleIcon mr-1" :icon="mdiMapMarkerCircle" />
                              <p class="fs-4">{{ pontos.podescricao }}</p>
                            </div>

                            <div class="styleDivider mb-2 mt-2">
                            </div>

                            <div class="d-flex align-items-center">
                              <baseIcon size='30' class="styleIcon mr-1" :icon="mdiOfficeBuildingMarker" />
                              <p class="fs-4">{{ pontos.poendereco }}</p>
                            </div>
                          </div>
                        </popupMarker>
                      </LMarker>
                    </div>
                  </ClusterSelecionados>
                </div>
              </div>

              <!-- Rastro de rota das cargas individuais -->
              <div>
                <polilinhaCorDiferente :colorPolilyne='geraCor()' :latLng="arrayRastrosMapa[0] || []"
                  :poliEditavel="false" />
                <div v-for="(pontos, index) in arrayRastrosMapa" :key="index">
                  <polilinhaCorDiferente :colorPolilyne='geraCor()' :latLng="arrayRastrosMapa[index + 1] || []"
                    :poliEditavel="false" />
                </div>

                <div v-for="(rota) in rotaSelecionadaMostrarMapa" v-bind:key="rota.rocodigo">
                  <div v-if="mostraRotasAntigas">
                    <MarkerPoint :latitude="parseFloat(rota.pontoInicial[0])"
                      :longitude="parseFloat(rota.pontoInicial[1])" :icon="getIconInicialFinal(0)" typeIcon="divIcon">
                      <popupMarker>
                        <div class="col-sm-12 row nopadding div-popup">
                          <div class="d-flex align-items-center">
                            <baseIcon size='30' class="styleIcon mr-1" :icon="mdiMapMarkerCircle" />
                            <p class="fs-4">{{ rota.pontoInicial[2] }}</p>
                          </div>

                          <div class="styleDivider mb-2 mt-2">
                          </div>

                          <div class="d-flex align-items-center">
                            <baseIcon size='30' class="styleIcon mr-1" :icon="mdiOfficeBuildingMarker" />
                            <p class="fs-4">{{ rota.pontoInicial[3] }}</p>
                          </div>
                        </div>
                      </popupMarker>
                    </MarkerPoint>

                    <MarkerPoint :latitude="parseFloat(rota.pontoFinal[0])" :longitude="parseFloat(rota.pontoFinal[1])"
                      :icon="getIconInicialFinal(1)" typeIcon="divIcon">
                      <popupMarker>
                        <div class="col-sm-12 row nopadding div-popup">
                          <div class="d-flex align-items-center">
                            <baseIcon size='30' class="styleIcon mr-1" :icon="mdiMapMarkerCircle" />
                            <p class="fs-4">{{ rota.pontoFinal[2] }}</p>
                          </div>

                          <div class="styleDivider mb-2 mt-2">
                          </div>

                          <div class="d-flex align-items-center">
                            <baseIcon size='30' class="styleIcon mr-1" :icon="mdiOfficeBuildingMarker" />
                            <p class="fs-4">{{ rota.pontoFinal[3] }}</p>
                          </div>
                        </div>
                      </popupMarker>
                    </MarkerPoint>
                  </div>

                  <!-- Pontos das cargas individuais -->
                  <div v-if="showPontosCargaMapa">
                    <ClusterSelecionados :disableClusterZoom="16" :maxClusterRadius="20" :chunkDelay="50"
                      :chunkInterval="50">
                      <div v-for="(itemRota, index) in rota.itens" v-bind:key="index">
                        <LMarker :lat-lng="[itemRota.polatitude, itemRota.polongitude]" :icon="getIcon(itemRota.irordem)">
                          <popupMarker>
                            <div class="col-sm-12 row nopadding div-popup">
                              <div class="d-flex align-items-center">
                                <baseIcon size='30' class="styleIcon mr-1" :icon="mdiMapMarkerCircle" />
                                <p class="fs-4">{{ itemRota.podescricao }}</p>
                              </div>

                              <div class="styleDivider mb-2 mt-2">
                              </div>

                              <div class="d-flex align-items-center">
                                <baseIcon size='30' class="styleIcon mr-1" :icon="mdiOfficeBuildingMarker" />
                                <p class="fs-4">{{ itemRota.poendereco }}</p>
                              </div>
                            </div>
                          </popupMarker>
                        </LMarker>
                      </div>
                    </ClusterSelecionados>
                  </div>
                </div>
              </div>

              <!-- Pontos das cargas agrupadas -->
              <div v-if="showPontosCargaMapa">
                <ClusterSelecionados :disableClusterZoom="16" :maxClusterRadius="20" :chunkDelay="50" :chunkInterval="50">
                  <div v-for="(itemRota, index) in itensRota" v-bind:key="index">
                    <LMarker :lat-lng="[itemRota.polatitude, itemRota.polongitude]" :icon="getIcon(itemRota.irordem)"
                      :ref="'markerRef_' + index">
                      <popupMarker>
                        <div class="col-sm-12 row nopadding div-popup">
                          <div class="d-flex align-items-center">
                            <baseIcon size='30' class="styleIcon mr-1" :icon="mdiMapMarkerCircle" />
                            <p class="fs-4">{{ itemRota.podescricao }}</p>
                          </div>

                          <div class="styleDivider mb-2 mt-2">
                          </div>

                          <div class="d-flex align-items-center">
                            <baseIcon size='30' class="styleIcon mr-1" :icon="mdiOfficeBuildingMarker" />
                            <p class="fs-4">{{ itemRota.poendereco }}</p>
                          </div>
                        </div>
                      </popupMarker>
                    </LMarker>
                  </div>
                </ClusterSelecionados>
              </div>

              <!-- Informações da rota -->
              <infoRotaOsrm :dados="rotaOsrm" :alertaRotaFixa="showAlertaRotaFixa" @click="desfazerAcaoRotaFixa" />

              <!-- Botão lateral para expandir e diminuir o mapa -->
              <div class="buttonMap" @click="abreEfechaMapa()"
                :title="expandirMapa ? 'Expandir mapa' : 'Exibir lista de pontos'" v-show="!buttonAgruparDesagrupar">
                <div :class="expandirMapa ? '' : 'rotaciona'">
                  <baseIcon size='20' class="ml-2" :icon="mdiArrowCollapseLeft" />
                </div>
              </div>

              <!-- Ponto inicial e final da rota agrupada -->
              <div v-for="(ponto, index) in pontoInicialFinal" v-bind:key="ponto.rocodigo">
                <MarkerPoint :latitude="parseFloat(ponto.latRetorno)" :longitude="parseFloat(ponto.lngRetorno)"
                  :icon="getIconInicialFinal(index)" typeIcon="divIcon">
                  <popupMarker>
                    <div class="col-sm-12 row nopadding div-popup">
                      <div class="d-flex align-items-center">
                        <baseIcon size='30' class="styleIcon mr-1" :icon="mdiMapMarkerCircle" />
                        <p class="fs-4">{{ ponto.podescricao }}</p>
                      </div>

                      <div class="styleDivider mb-2 mt-2">
                      </div>

                      <div class="d-flex align-items-center">
                        <baseIcon size='30' class="styleIcon mr-1" :icon="mdiOfficeBuildingMarker" />
                        <p class="fs-4">{{ ponto.poendereco }}</p>
                      </div>
                    </div>
                  </popupMarker>
                </MarkerPoint>
              </div>

              <!-- Ações do mapa -->
              <div class="acoesMapa">
                <simpleButton :disabled="this.rotasAgruparSelecionadas.length <= 0" text="Roteirizar" type="gray"
                  width="100px" />
                <simpleButton :disabled="this.rotasAgruparSelecionadas.length <= 0"
                  @click="abreModalDefinirPontoSaidaRetorno(1)" event="click" text="Opção 1" :icon="mdiVectorSquare"
                  type="green" width="100px" title="Melhor opção para rotas de coleta" />
                <simpleButton :disabled="this.rotasAgruparSelecionadas.length <= 0"
                  @click="abreModalDefinirPontoSaidaRetorno(2)" event="click" text="Opção 2" :icon="mdiVectorSquare"
                  type="orange" width="100px" title="Melhor opção para rotas entre cidades" />
                <simpleButton event="click" text="" :icon="iconBotaoVisualizarPontosMapa" type="black" width="100px"
                  class="mt-5" @click="showPontosCarga" :title="titleBotaoVerPontosMapa" />
              </div>
            </mapaSimples>
          </div>
        </div>

        <!-- ABAS -->
        <div class="mt-3 pr-3" style="margin-bottom: -30px; z-index: 10; position: sticky; left: 100%;">
          <SimpleButton text="Agrupar cargas" type="blue" event="agrupar" @agrupar="agruparCargas"
            v-if="!buttonAgruparDesagrupar" style="width: auto" />

          <SimpleButton text="Desagrupar carga" type="red" v-else style="width: auto" event="desagrupar"
            @desagrupar="desagruparCargas" />
        </div>

        <b-tabs class="col-sm-12">
          <!-- ROTAS PENDENTES -->
          <b-tab title="Rotas Pendentes" class="col-sm-12 p-0 mt-2" v-on:click="trocaBotaoAgruparDesagrupar(1)">
            <tableRelatorio>
              <slot slot="thead">
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th colspan="2">Capacidade</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>

                <tr class="text-center">
                  <th></th>
                  <th>Código</th>
                  <th>Veículo</th>
                  <th>Data</th>
                  <th>Origem</th>
                  <th>Destino</th>
                  <th>Peso</th>
                  <th>Cubagem</th>
                  <th>Km</th>
                  <th>Tempo</th>
                  <th>Custo</th>
                  <th>Entregas</th>
                </tr>

              </slot>

              <slot slot="tbody">
                <template>
                  <tr v-for="(dataRota, index) in  rotasPendentesAgrupar " :key="index">
                    <td class="styleColum text-center" title="Selecionar rota">
                      <input class="form-check-input styleColumCheck" type="checkbox" value="" id="flexCheckDefault"
                        name="checkboxItensRota" @click="changeItensRotaAgrupar(dataRota)">
                    </td>

                    <td>{{ dataRota.rocodigo }}</td>
                    <td>{{ dataRota.roplaca }}</td>
                    <td>{{ dataRota.rodata }}</td>
                    <td class="text-truncate" :title="dataRota.ropontosaida" style="max-width: 150px;">{{
                      dataRota.ropontosaida }}</td>
                    <td class="text-truncate" :title="dataRota.ropontoretorno" style="max-width: 150px;">{{
                      dataRota.ropontoretorno }}</td>

                    <td style="width: 130px;">
                      <p class="w-full m-auto text-center">
                        {{ dataRota.pesoMax }}%
                      </p>

                      <div class="progress border" role="progressbar" aria-valuemin="0" style="height: 10px">
                        <div :class='dataRota.pesoMax < 50 ? "progress-bar overflow-x-visible text-dark bg-danger" :
                          dataRota.pesoMax >= 100 ? "progress-bar overflow-x-visible text-dark bg-danger" :
                            dataRota.pesoMax >= 50 && dataRota.pesoMax < 80 ? "progress-bar overflow-x-visible text-dark bg-warning" :
                              dataRota.pesoMax >= 80 && "progress-bar overflow-x-visible text-dark bg-success"'
                          :style="{ width: dataRota.pesoMax + '%' }">
                        </div>

                      </div>
                    </td>

                    <td style="width: 130px;">
                      <p class="w-full m-auto text-center">
                        {{ dataRota.cubagemMax }}%
                      </p>

                      <div class="progress border position-relative" role="progressbar" aria-valuemin="0"
                        style="height: 10px">
                        <div :class='dataRota.cubagemMax < 50 ? "progress-bar bg-danger" :
                          dataRota.cubagemMax >= 100 ? "progress-bar bg-danger" :
                            dataRota.cubagemMax >= 50 && dataRota.cubagemMax < 80 ? "progress-bar bg-warning" :
                              dataRota.cubagemMax >= 80 && "progress-bar bg-success"'
                          :style="{ width: dataRota.cubagemMax + '%' }">
                          .
                        </div>
                      </div>
                    </td>

                    <td class="text-truncate" :title="dataRota.rokm + ' km'" style="max-width: 80px;">{{ dataRota.rokm }}
                      km</td>
                    <td>{{ dataRota.rotempo }}</td>
                    <td>
                      <span v-if="dataRota.rocusto > 0" class="text-truncate" :title="'R$ ' + dataRota.rocusto"
                        style="max-width: 80px;">
                        R$ {{ dataRota.rocusto }}
                      </span>
                    </td>
                    <td>{{ dataRota.totalPedidos }}</td>
                  </tr>
                </template>

                <statusInformation v-if="rotasPendentesAgrupar.length === 0" typeBar="tr" :statusBar="statusbar">
                </statusInformation>
              </slot>
            </tableRelatorio>
          </b-tab>

          <!-- ROTAS AGRUPADAS -->
          <b-tab title="Rotas Agrupadas" class="col-sm-12 p-0 mt-2" v-on:click="trocaBotaoAgruparDesagrupar(2)">
            <tableRelatorio>
              <slot slot="thead">
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th colspan="2">Capacidade</th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
                <tr class="text-center">
                  <th></th>
                  <th>Código</th>
                  <th>Veículo</th>
                  <th>Data</th>
                  <th>Origem</th>
                  <th>Destino</th>
                  <th>Peso</th>
                  <th>Cubagem</th>
                  <th>Km</th>
                  <th>Tempo</th>
                  <th>Custo</th>
                  <th>Entregas</th>
                </tr>

              </slot>
              <slot slot="tbody">
                <template>
                  <tr v-for="(dataRota, index) in  rotasAgrupadas" :key="index">
                    <td class="styleColum text-center" title="Selecionar rota">
                      <input class="form-check-input styleColumCheck" type="radio" name="rotaAgrupada" value=""
                        :id="'flexCheckDefault' + index" @click="changeItensRotaDesgrupar(dataRota, index)"
                        :checked="selectedItemIndex === index">
                    </td>

                    <td>{{ dataRota.rocodigo }}</td>
                    <td>{{ dataRota.roplaca }}</td>
                    <td>{{ dataRota.rodata }}</td>
                    <td class="text-truncate" :title="dataRota.ropontosaida" style="max-width: 150px;">{{
                      dataRota.ropontosaida }}</td>
                    <td class="text-truncate" :title="dataRota.ropontoretorno" style="max-width: 150px;">{{
                      dataRota.ropontoretorno }}</td>

                    <td style="width: 130px;">
                      <p class="w-full m-auto text-center">
                        {{ dataRota.pesoMax }}%
                      </p>

                      <div class="progress border" role="progressbar" aria-valuemin="0" style="height: 10px">
                        <div :class='dataRota.pesoMax < 50 ? "progress-bar overflow-x-visible text-dark bg-danger" :
                          dataRota.pesoMax >= 100 ? "progress-bar overflow-x-visible text-dark bg-danger" :
                            dataRota.pesoMax >= 50 && dataRota.pesoMax < 80 ? "progress-bar overflow-x-visible text-dark bg-warning" :
                              dataRota.pesoMax >= 80 && "progress-bar overflow-x-visible text-dark bg-success"'
                          :style="{ width: dataRota.pesoMax + '%' }">
                        </div>

                      </div>
                    </td>

                    <td style="width: 130px;">
                      <p class="w-full m-auto text-center">
                        {{ dataRota.cubagemMax }}%
                      </p>

                      <div class="progress border position-relative" role="progressbar" aria-valuemin="0"
                        style="height: 10px">
                        <div :class='dataRota.cubagemMax < 50 ? "progress-bar bg-danger" :
                          dataRota.cubagemMax >= 100 ? "progress-bar bg-danger" :
                            dataRota.cubagemMax >= 50 && dataRota.cubagemMax < 80 ? "progress-bar bg-warning" :
                              dataRota.cubagemMax >= 80 && "progress-bar bg-success"'
                          :style="{ width: dataRota.cubagemMax + '%' }">
                          .
                        </div>
                      </div>
                    </td>

                    <td class="text-truncate" :title="dataRota.rokm + ' km'" style="max-width: 80px;">{{ dataRota.rokm }}
                      km</td>
                    <td>{{ dataRota.rotempo }}</td>
                    <td>
                      <span v-if="dataRota.rocusto > 0" class="text-truncate" :title="'R$ ' + dataRota.rocusto"
                        style="max-width: 80px;">
                        R$ {{ dataRota.rocusto }}
                      </span>
                    </td>
                    <td>{{ dataRota.totalPedidos }}</td>
                  </tr>
                </template>
                <statusInformation v-if="rotasAgrupadas.length <= 0" typeBar="tr" :statusBar="statusbar">
                </statusInformation>
              </slot>
            </tableRelatorio>
          </b-tab>
        </b-tabs>
      </div>

      <!-- modal selecionar ponto inicial e final -->
      <modalEagle @confirmButton="roteirizarPedidosPorTipo(tipoRoteirizacaoSelecionado)" id="modalConfirmarCarregamento"
        title="Selecione a origem e destino da rota:" :textoConfirmar="defineTextoBotaoAviso" :tamanhoBtn="'110px'">
        <template #modalBody>
          <div class="d-flex justify-content-start">
            <div class="col-lg-6 nopadding">
              <selectAll nameForRadio='seletorOrigem' ref="seletorOrigem" :isMultiple='false' :optionsArray='pontosOrigem'
                :loading="loadingRotas" :labels='labelsPontOrige' :hasSelectAll='false'
                @changeSelect="changePontoOrigem" />
            </div>

            <div class="col-lg-6 nopadding">
              <selectAll nameForRadio='seletorDestino' ref="seletorDestino" :isMultiple='false'
                :optionsArray='pontosDestino' :loading="loadingRotas" :labels='labelsPontDest' :hasSelectAll='false'
                @changeSelect="changePontoDestino" />
            </div>
          </div>
        </template>
      </modalEagle>

      <!-- modal selecionar veículo -->
      <modalEagle id="modalVincularVeiculo" title="Selecionar veículo" :textoConfirmar="defineTextoBotaoAviso"
        :tamanhoBtn="'110px'" size="xl">
        <template #modalBody>
          <b-overlay :show='loadingSelecionarVeiculo' :opacity='0.65' class='bemAlto'>
            <div class="d-flex col-sm-12">
              <div class="col-sm-6">
                <InputSingleDay name="selectData" label="Data de início de rota*" :isObrigatorio="true"
                  @changeInput="changeDataInicioRota" />
              </div>

              <div class="col-sm-6">
                <InputSearch placeholder="Pesquise um veículo" inputClass="nopadding pl-2 mt-4"
                  @changeInput="changeInputSearchVeiculo" name="searchOrder" id="searchOrder" />
              </div>
            </div>

            <b-container>
              <b-row>
                <b-col v-for="(veiculo, index) in  valueVeiculos" :key="index" md="6">
                  <CardVeiculo :dados="veiculo" @click="selecionarVeiculoAgruparCarga(veiculo)"></CardVeiculo>
                </b-col>
              </b-row>
            </b-container>
          </b-overlay>
        </template>
      </modalEagle>

      <!-- modal confirmar para carregar no veículo que ja tenha uma carga -->
      <modalEagle @confirmButton="confirmarAgrupamentoCargaVeiculoExiste" @cancelButton="cancelButtonModalVeiculoCarga"
        id="modalAlertaVeiculoComCarga" title="Confirmar Carregamento" :textoConfirmar="defineTextoBotaoAviso"
        :tamanhoBtn="'110px'">
        <template #modalBody>
          <div v-if="avisoVeiculoCarregado">
            <div class="col-sm-12 nopadding">
              {{ avisoVeiculoCarregado }}
            </div>
            <div class="col-sm-12 nopadding">Deseja criar uma nova rota?</div>
          </div>
          <div v-if="avisoItemCarregado">
            <div class="col-sm-12 nopadding">
              {{ avisoItemCarregado }}
            </div>
          </div>
        </template>
      </modalEagle>
    </div>
  </PanelEagle>
</template>
<script>

import Vue from 'vue'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import {
  mdiFolderArrowDown,
  mdiMonitorDashboard,
  mdiShapePolygonPlus,
  mdiVectorSquare,
  mdiCheckOutline,
  mdiEye,
  mdiEyeOff,
  mdiArrowDecisionOutline,
  mdiPlus,
  mdiTrashCanOutline,
  mdiCheckBold,
  mdiCloseThick,
  mdiMagnify,
  mdiMapMarkerPlusOutline,
  mdiCarCog,
  mdiPencil,
  mdiMapMarkerCircle,
  mdiOfficeBuildingMarker,
  mdiArrowCollapseLeft
} from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service';
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import slideUpAndDown from '@/components/Atom/SlideUpAndDown/SlideUpAndDown.vue';
import InputRangeWithLimit from '@/components/Atom/Datas/InputRangeWithLimit.vue';
import InputSingleDay from '@/components/Atom/Datas/InputSingleDay.vue';
import SelectAll from '@/components/Atom/Select/SelectAll.vue';
import PanelEagle from '@/components/Atom/Panel/PanelEagle.vue';
import SimpleButton from '@/components/Atom/Buttons/SimpleButton.vue';
import { conectionError } from '@/Services/Helpers/swellHeper';
import { LMarker } from "vue2-leaflet";
import { v4 as uuidv4 } from 'uuid';
import draggable from 'vuedraggable'

var decodePoly = require('@mapbox/polyline')

export default Vue.extend({
  name: 'ControleCargas',

  validations: {
    empresaSelecionada: { required },
  },

  components: {
    ClusterSelecionados: require(
      "@/components/Atom/SpecificComponents/ControleCargas/ClusterSelecionados.vue")
      .default,
    tituloPage: require("@/components/Atom/Header/Titulo").default,
    slideUpAndDown,
    InputRangeWithLimit,
    SelectAll,
    PanelEagle,
    SimpleButton,
    tableRelatorio: require('@/components/Atom/Table/TableRelatorio').default,
    'statusInformation': require('@/components/Atom/StatusInformation/StatusInformation').default,
    modalEagle: require("@/components/Atom/Modal/ModalEagle").default,
    InputSingleDay,
    InputSearch: require("@/components/Atom/Inputs/InputSearch").default,
    CardVeiculo: require(
      "@/components/Atom/SpecificComponents/RotaManual/CardVeiculo.vue").default,
    'MarkerPoint': require('@/components/Atom/Mapa/MarkerPoint').default,
    'MapaSimples': require('@/components/Atom/Mapa/MapaSimples').default,
    'popupMarker': require('@/components/Atom/Mapa/PopupMarker').default,
    'baseIcon': require('@/components/Atom/Icon/BaseIcon.vue').default,
    infoRotaOsrm: require(
      "@/components/Atom/SpecificComponents/RotaManual/InfoRotaOsrm").default,
    polilinhaCorDiferente: require("@/components/Atom/Mapa/PolilinhaCorDiferente").default,
    LMarker,
    cardPedido: require(
      "@/components/Atom/SpecificComponents/RotaManual/CardPedido.vue").default,
    draggable,
  },

  mounted() {
    if (this.$refs.mapaSimples) {
      this.$refs.mapaSimples.validateSize()
    }

    this.$refs.mapaSimples.validateSize();
  },

  data() {
    return {
      loadingSelecionarVeiculo: false,
      pontosTeste: [],
      tipoRoteirizacaoSelecionado: 1,
      showPontosMapaInicialFinalRotaAgrupada: false,
      selectedItemIndex: null,
      selectedItem: null,
      selectedItemRadio: null,
      rotaDesagrupar: [],
      inicialFinalDesagrupar: [],
      veiculoSelecionadoAgruparCarga: [],
      rotasSelecionadasAgruparAux: [],
      titleBotaoVerPontosMapa: 'Visualizar pontos',
      mostraRotasAntigas: true,
      pontosItensRotasNovas: [],
      showPontosMapa: false,
      coordenadas: [],
      mdiMapMarkerCircle: mdiMapMarkerCircle,
      mdiOfficeBuildingMarker: mdiOfficeBuildingMarker,
      showAlertaRotaFixa: false,
      url: '/roteirizador/relatorios/banco/tickets/',
      iconBotaoVisualizarPontosMapa: mdiEye,
      mdiFolderArrowDown: mdiFolderArrowDown,
      mdiMonitorDashboard: mdiMonitorDashboard,
      mdiShapePolygonPlus: mdiShapePolygonPlus,
      mdiVectorSquare: mdiVectorSquare,
      mdiCheckOutline: mdiCheckOutline,
      mdiArrowDecisionOutline: mdiArrowDecisionOutline,
      mdiEye: mdiEye,
      mdiEyeOff: mdiEyeOff,
      mdiPlus: mdiPlus,
      mdiTrashCanOutline: mdiTrashCanOutline,
      mdiCheckBold: mdiCheckBold,
      mdiCloseThick: mdiCloseThick,
      mdiMagnify: mdiMagnify,
      mdiMapMarkerPlusOutline: mdiMapMarkerPlusOutline,
      mdiPencil: mdiPencil,
      mdiCarCog: mdiCarCog,
      mdiArrowCollapseLeft: mdiArrowCollapseLeft,
      loadingPanel: false,
      loadingPontos: false,
      loadingRotas: false,
      loadingVeiculos: false,
      optionsPontos: [],
      optionsContratos: [],
      optionsVeiculos: [],
      optionsRotas: [],
      optionsRotasConsulta: [],
      pontosSelecionados: [],
      rotasAgruparSelecionadas: [],
      contratosSelecionados: [],
      dataIntervalo: '',
      empresaSelecionada: [],
      veiculosArray: [],
      veiculosSelect: [],
      dadosVeiculo: [],
      dadosRota: [],
      dadosRelatorio: '',
      optionsSelectEmpresas: new EmpresasService().Get(),
      labelsSelectPontos: [
        { indexDFH: 'P', description: 'Pontos' },
      ],
      labelsVei: [{
        indexDFH: 'VEI',
        description: 'Veículos'
      }],
      labelsRot: [{
        indexDFH: 'ROT',
        description: 'Rotas'
      }],
      labelsPontOrige: [{
        indexDFH: 'PontOrige',
        description: 'Origem*'
      }],
      labelsPontDest: [{
        indexDFH: 'PontDest',
        description: 'Destino*'
      }],
      rotaOsrm: {
        tempo: 0,
        distancia: 0,
        volume: 0,
        peso: 0,
        valor: 0,
        cubagem: 0,
      },
      rotaProgramada: [],
      arrayRastro: [],
      tabsPag: [
        { 'title': 'Rotas Pendentes', 'value': 'rotasPendentes', 'disabled': false },
        { 'title': 'Rotas Programadas', 'value': 'rotasProgramadas', 'disabled': false },
      ],
      modelTab: 0,
      buscandoPedido: true,
      pedidos: {
        draggable: [], // valuePontosEItens markers e pedidos
        markers: [], // markers nao selecionados
        markersX: [], // markersSelecionados
      },
      valueBuscaPedido: '',
      totalPedidos: 0,
      limitePedidos: 500,
      dragOptions: {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
        forceFallback: true,
        fallbackTolerance: 5,
        emptyInsertThreshold: 5,
        // fallbackOnBody: true,
      },
      statusBarPedido: 'info',
      valueVeiculos: [],
      statusBarVeiculos: 'info',
      valueCargas: [],
      statusBarCargas: 'info',
      buttonAgruparDesagrupar: false,
      titulosT: [
        { 'number': 'rocodigo', 'name': 'Código', 'width': '100px' },
        { 'number': 'roplaca', 'name': 'Veículo', 'width': '100px' },
        { 'number': 'rodata', 'name': 'Data', 'width': '100px' },
        { 'number': 'ropontosaida', 'name': 'Origem', 'width': '300px' },
        { 'number': 'ropontoretorno', 'name': 'Destino', 'width': '300px' },
        { 'number': 'ropeso', 'name': 'Peso', },
        { 'number': 'rocubagem', 'name': 'Cubagem', },
        { 'number': 'rokm', 'name': 'Km', },
        { 'number': 'rotempo', 'name': 'Tempo', },
        { 'number': 'rocusto', 'name': 'Custo', },
        { 'number': 'romotorista', 'name': 'Entregas CONFERIR', },
      ],
      rotasPendentesAgrupar: [],
      rotasAgrupadas: [],
      statusbar: 'info',
      dados: [],
      visualizarMapa: true,
      avisoItemCarregado: '',
      avisoVeiculoCarregado: '',
      pontosOrigem: [],
      pontosDestino: [],
      pontoOrigemSelecionado: [],
      pondoDestinoSelecionado: [],
      pontoInicialFinal: [],
      pontoInicialFinalAux: [],
      veiculoSemFiltro: [],
      dataInicioRota: '',
      showPontosCargaMapa: false,
      rotaSelecionadaDesagrupar: [],
      ordemRota: 0,
      rotaSelecionadaMostrarMapa: [],
      arrayRastrosMapa: [],
      expandirMapa: false,
      tamanhoDivMapa: 100,
      rota: {
        empresa: '',
        pontoSaida: '',
        pontoRetorno: '',
        motorista: '',
        ajudante: '',
        data: '',
        hora: '',
        dataHoraLimite: '',
        tempoParada: '',
        velocidade: 0,
      },
      itensRota: [],
      itensRotaAux: [],
      showTop: false,
      opcaoEscolhidaRoteirizar: 1,
      rotasAgruparSelecionadasAux: [],
      arrayRastroAux: [],
      itensRotaAntigoAux: [],
      abaSelecionada: 1,
      mostrarRotasAntigas: false,
      editandoItens: 0,
    }
  },

  methods: {
    ...mapGetters(['getMaster', 'getCadastrar', 'getEditar', 'getExcluir']),

    /**
    * @description Função para gerar uma chave UUID unida para data item.
    * @return {string} chave UUID
    * @author Yuri 🇧🇷
    */
    generateUniqueKey(item) {
      return item.id || uuidv4();
    },

    /**
    * @description Retorna o html para mostrar um marker no mapa.
    * @param ordem
    * @return {string} html do marker
    * @author Desconhecido
    * 
    */
    getIcon(ordem) {
      return L.divIcon({
        className: 'custom-div-icon',
        html: `<div>
                  <span class='markerRotaOrdem'>${ordem}</span>
                  <img src='img/pontoSelecionado.png' />
               </div>`,
        iconSize: [32, 32],
        iconAnchor: [16, 32],
      });
    },

    /**
    * @description Retorna o html para mostrar um marker no mapa do ponto inicial ou final.
    * @return {string} html do marker
    * @author Yuri 🇧🇷
    */
    getIconInicialFinal(ordem) {
      if (ordem == 0) {
        return `<div>
        <img src='img/inicio.png'/>
        </div>`;
      } else {
        return `<div>
        <img src='img/fim.png'/>
        </div>`;
      }
    },

    /**
     * @description Chama função de zoom do mapa até as coordenadas da polyline/rota
     * @author Desconhecido
     */
    flyToCoordenadas() {
      try {
        // let option = {animate:true, duration:0.8, easeLinearity:0.8}
        let latLng = [
          this.rota.latSaida,
          this.rota.lngSaida
        ]
        if (latLng[0] && latLng[1]) {
          this.$refs.mapaSimples.flyTo(latLng, 22)
        }
      } catch (error) {
        ''
      }
    },

    /**
     * @description Chama função de remover layer do mapa. Remover todas as layers.
     * @author Desconhecido
     */
    removelAllLayers() {
      try {
        let layers = this.$refs.polyUnicaRota.mapObject._layers
        for (let l in layers) {
          this.$refs.mapaSimples.removeLayer(layers[l])
        }
      } catch (error) { '' }
    },

    /**
     * @description Faz a requisição para verificar se o veículo selecionado para ser
     * carregado já tem carga no dia, ou se algum dos pedidos já foi carregado (essa
     * validação existe porque duas pessoas podem estar cadastrando ao mesmo tempo).
     * @param {array} codigosItens
     * @author Rafael
     */
    async verificaVeiculoCarregado(codigosItens) {
      try {
        this.loadingPanel = true;
        this.avisoItemCarregado = '';
        this.avisoVeiculoCarregado = '';
        const url = `${this.url}veiculo/verificar`;
        const body = {
          placa: this.placaCarregamento,
          cliente: this.rota.empresa,
          codigosItens,
        };
        const { data, status } = await new HttpRequest().Post(url, body);
        if (!status) {
          this.toastShow('Ops!', 'Não foi possível concluir a operação!', 'danger');
          return;
        }
        const { verificacao } = data;
        if (verificacao.rota) {
          const dataStr = verificacao.rota.split('-').reverse().join('/');
          const msg = `O veículo com a placa ${this.placaCarregamento} já possui uma carga para o dia ${this.dataInicioRota}.`;
          this.avisoVeiculoCarregado = msg;
          this.modalConfirmarCarregamento();
          this.loadingPanel = false;
        } else if (verificacao.itens) {
          const msg = 'Um ou mais pedidos já estão associados a uma rota. Recarregue a página e monte a rota novamente!';
          this.avisoItemCarregado = msg;
          this.modalConfirmarCarregamento();
          this.loadingPanel = false;
        } else {
          await this.carregarVeiculo(codigosItens);
        }
      } catch (error) {
        this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
        this.loadingPanel = false;
      }
    },

    /**
    * @description Atualiza a lista de veículos exibidos com base no valor fornecido.
    * @param {string} value - O valor de filtro para buscar veículos por placa ou prefixo.
    * @author Yuri 🇧🇷
    */
    changeInputSearchVeiculo(value) {
      this.valueVeiculos = this.veiculoSemFiltro.filter((v) => {
        if (v.veplaca.toLowerCase().includes(value.toLowerCase())) {
          return true
        } else if (v.veprefixo.toLowerCase().includes(value.toLowerCase())) {
          return true
        }
        return false
      })
    },

    /**
   * @description Atualiza o estado da aplicação ao trocar a empresa selecionada.
   * @param {string} empresa - O identificador da nova empresa selecionada.
    * @author Yuri 🇧🇷
    */
    changeEmpresas(empresa) {
      this.relatorio = [];
      this.empresaSelecionada = empresa;
      this.getVeiculos();
      this.getRotas();
      this.rotasPendentesAgrupar = [];
      this.rotasAgrupadas = [];
      this.visualizarMapa = true;
      this.pontosSelecionados = [];
      this.rotasAgruparSelecionadas = [];
      this.arrayRastro = [];
      this.rotaProgramada = [];
      this.rotaOsrm = [];
      this.pontoInicialFinal = [];
      this.pontoOrigemSelecionado = [];
      this.pontoDestinoSelecionado = [];
      this.arrayRastrosMapa = [];
      this.rotaSelecionadaMostrarMapa = [];
      this.inicialFinalDesagrupar = [];
      this.showPontosMapaInicialFinalRotaAgrupada = false;
      this.rotaDesagrupar = [];
      this.ordemRota = 0;
      this.selectedItemIndex = null;
      this.itensRota = [];
      this.tamanhoDivMapa = 100;
      this.expandirMapa = false;
    },

    /**
     * @description Atualiza o estado da aplicação ao trocar o veículo selecionado.
     * @param {string} value - O identificador do novo veículo selecionado.
    * @author Yuri 🇧🇷
    */
    changeVeiculos(value) {
      this.dadosVeiculo = value;
      this.dadosRelatorio = [];
      this.getRotas();
      this.dadosRota = [];
      this.rotasPendentesAgrupar = [];
      this.rotasAgrupadas = [];
      this.rotaProgramada = [];
      this.visualizarMapa = true;
      this.pontosSelecionados = [];
      this.rotasAgruparSelecionadas = [];
      this.arrayRastro = [];
      this.rotaOsrm = [];
      this.pontoInicialFinal = [];
      this.pontoOrigemSelecionado = [];
      this.pontoDestinoSelecionado = [];
      this.arrayRastrosMapa = [];
      this.rotaSelecionadaMostrarMapa = [];
      this.inicialFinalDesagrupar = [];
      this.showPontosMapaInicialFinalRotaAgrupada = false;
      this.rotaDesagrupar = [];
      this.ordemRota = 0;
      this.selectedItemIndex = null;
      this.itensRota = [];
      this.tamanhoDivMapa = 100;
      this.expandirMapa = false;
    },

    /**
    @description Atualiza o estado da aplicação ao trocar a rota selecionada.
    * @param {string} value - O identificador da nova rota selecionada.
    * @author Yuri 🇧🇷
    */
    changeRotas(value) {
      this.dadosRota = value;
      this.dadosRelatorio = [];
      this.rotasPendentesAgrupar = [];
      this.rotasAgrupadas = [];
      this.rotaProgramada = [];
      this.visualizarMapa = true;
      this.pontosSelecionados = [];
      this.rotasAgruparSelecionadas = [];
      this.arrayRastro = [];
      this.rotaOsrm = [];
      this.pontoInicialFinal = [];
      this.pontoOrigemSelecionado = [];
      this.pontoDestinoSelecionado = [];
      this.arrayRastrosMapa = [];
      this.rotaSelecionadaMostrarMapa = [];
      this.inicialFinalDesagrupar = [];
      this.showPontosMapaInicialFinalRotaAgrupada = false;
      this.rotaDesagrupar = [];
      this.ordemRota = 0;
      this.selectedItemIndex = null;
      this.itensRota = [];
      this.tamanhoDivMapa = 100;
      this.expandirMapa = false;
    },

    /**
   * Pega o valor da data do input de Data e salva na variável this.dataIntervalo e limpa as demais informações do filtro e mapa
   * @param Data String
   * @author Yuri 🇧🇷
   */
    changeData(data) {
      this.dataIntervalo = data;
      this.rotasPendentesAgrupar = [];
      this.rotasAgrupadas = [];
      this.rotaProgramada = [];
      this.visualizarMapa = true;
      this.pontosSelecionados = [];
      this.rotasAgruparSelecionadas = [];
      this.arrayRastro = [];
      this.rotaOsrm = [];
      this.pontoInicialFinal = [];
      this.pontoOrigemSelecionado = [];
      this.pontoDestinoSelecionado = [];
      this.arrayRastrosMapa = [];
      this.rotaSelecionadaMostrarMapa = [];
      this.inicialFinalDesagrupar = [];
      this.showPontosMapaInicialFinalRotaAgrupada = false;
      this.rotaDesagrupar = [];
      this.ordemRota = 0;
      this.selectedItemIndex = null;
      this.itensRota = [];
      this.tamanhoDivMapa = 100;
      this.expandirMapa = false;
    },

    /**
   * Pega o valor da data do input de Data de início de rota e salva na variável this.dataInicioRota
   * @param Data String
   * @author Yuri 🇧🇷
   */
    changeDataInicioRota(data) {
      this.dataInicioRota = data;
    },

    /**
    * @description Atualiza os contratos selecionados na aplicação.
    * @param {Array} contratos - Um array contendo os contratos a serem selecionados.
    * @author Yuri 🇧🇷
    */
    changeContratos(contratos) {
      this.contratosSelecionados = contratos
    },

    /**
    * @description Atualiza o ponto de origem selecionado na aplicação.
    * @param {Array} value - Um array contendo o código do ponto de origem selecionado.
    * @author Yuri 🇧🇷
    */
    changePontoOrigem(value) {
      this.pontoOrigemSelecionado = [];
      let codPontoOrigemSelecionado = value[0];

      this.pontoOrigemSelecionado = this.pontosOrigem.filter((ponto) => {
        if (ponto.value == codPontoOrigemSelecionado) {
          return ponto;
        }
      });

    },

    /**
    * @description Atualiza o ponto de destino selecionado na aplicação.
    * @param {Array} value - Um array contendo o código do ponto de destino selecionado.
    * @author Yuri 🇧🇷
    */
    changePontoDestino(value) {
      this.pontoDestinoSelecionado = [];
      let codPontoDestinoSelecionado = value[0];

      this.pontoDestinoSelecionado = this.pontosDestino.filter((ponto) => {
        if (ponto.value == codPontoDestinoSelecionado) {
          return ponto;
        }
      });

    },

    /**
    * @description Abre o modalVincularVeiculo para selecionar um veiculo para referenciar a carga, condição se o this.arrayRastro for menor ou igual a zero emite um alerta e não abre o modal
    * @author Yuri 🇧🇷
    */
    agruparCargas() {
      const valoresRotaAgrupada = {
        "ropeso": 0,
        "rocubagem": 0,
      };

      this.loadingSelecionarVeiculo = false;

      if (this.rotaProgramada.length > 0) {
        this.rotaProgramada.map((rota) => {
          valoresRotaAgrupada.ropeso += rota.ropeso
          valoresRotaAgrupada.rocubagem += rota.rocubagem
        })

        for (var indexVeiculo in this.valueVeiculos) {
          // ADICIONANDO NOVOS ELEMENTOS EM VALUEVEICULOS
          this.valueVeiculos[indexVeiculo] = {
            ...this.valueVeiculos[indexVeiculo],
            'progresso': parseFloat(valoresRotaAgrupada.ropeso > 0 && this.valueVeiculos[indexVeiculo].vemaxpeso > 0 ? ((valoresRotaAgrupada.ropeso / this.valueVeiculos[indexVeiculo].vemaxpeso) * 100).toFixed(2) : 0)
          };
          this.valueVeiculos[indexVeiculo].progresso = parseFloat(this.valueVeiculos[indexVeiculo].progresso);

          this.valueVeiculos[indexVeiculo] = {
            ...this.valueVeiculos[indexVeiculo],
            'disponivelcub': valoresRotaAgrupada.rocubagem > 0 && this.valueVeiculos[indexVeiculo].vecubagem > 0 ? ((valoresRotaAgrupada.rocubagem / this.valueVeiculos[indexVeiculo].vecubagem) * 100).toFixed(2) : 0
          };
          this.valueVeiculos[indexVeiculo].disponivelcub = parseFloat(this.valueVeiculos[indexVeiculo].disponivelcub);

          this.valueVeiculos[indexVeiculo] = {
            ...this.valueVeiculos[indexVeiculo],
            'progressocub': valoresRotaAgrupada.rocubagem > 0 && this.valueVeiculos[indexVeiculo].vemaxpeso > 0 ? ((valoresRotaAgrupada.rocubagem / this.valueVeiculos[indexVeiculo].vemaxpeso) * 100).toFixed(2) : 0
          };
          this.valueVeiculos[indexVeiculo].progressocub = parseFloat(this.valueVeiculos[indexVeiculo].progressocub);

          this.valueVeiculos[indexVeiculo] = {
            ...this.valueVeiculos[indexVeiculo],
            'disponivel': valoresRotaAgrupada.rocubagem > 0 && this.valueVeiculos[indexVeiculo].vecubagem > 0 ? ((valoresRotaAgrupada.rocubagem / this.valueVeiculos[indexVeiculo].vecubagem) * 100).toFixed(2) : 0
          };
          this.valueVeiculos[indexVeiculo].disponivel = parseFloat(this.valueVeiculos[indexVeiculo].disponivel);
          //FIM DE ADIÇÃO DE NOVOS ELEMENTNOS VALUEVEICULOS

          // ADICIONANDO NOVOS ELEMENTOS EM VEICULO SEM FILTRO
          this.veiculoSemFiltro[indexVeiculo] = {
            ...this.veiculoSemFiltro[indexVeiculo],
            'progresso': valoresRotaAgrupada.ropeso > 0 && this.veiculoSemFiltro[indexVeiculo].vemaxpeso > 0 ? ((valoresRotaAgrupada.ropeso / this.veiculoSemFiltro[indexVeiculo].vemaxpeso) * 100).toFixed(2) : 0
          };
          this.veiculoSemFiltro[indexVeiculo].progresso = parseFloat(this.veiculoSemFiltro[indexVeiculo].progresso);

          this.veiculoSemFiltro[indexVeiculo] = {
            ...this.veiculoSemFiltro[indexVeiculo],
            'disponivelcub': valoresRotaAgrupada.rocubagem > 0 && this.veiculoSemFiltro[indexVeiculo].vecubagem > 0 ? ((valoresRotaAgrupada.rocubagem / this.veiculoSemFiltro[indexVeiculo].vecubagem) * 100).toFixed(2) : 0
          };
          this.veiculoSemFiltro[indexVeiculo].disponivelcub = parseFloat(this.veiculoSemFiltro[indexVeiculo].disponivelcub);

          this.veiculoSemFiltro[indexVeiculo] = {
            ...this.veiculoSemFiltro[indexVeiculo],
            'disponivel': valoresRotaAgrupada.rocubagem > 0 && this.veiculoSemFiltro[indexVeiculo].vemaxpeso > 0 ? ((valoresRotaAgrupada.rocubagem / this.veiculoSemFiltro[indexVeiculo].vemaxpeso) * 100).toFixed(2) : 0
          };
          this.veiculoSemFiltro[indexVeiculo].disponivel = parseFloat(this.veiculoSemFiltro[indexVeiculo].disponivel);

          this.veiculoSemFiltro[indexVeiculo] = {
            ...this.veiculoSemFiltro[indexVeiculo],
            'progressocub': valoresRotaAgrupada.rocubagem > 0 && this.veiculoSemFiltro[indexVeiculo].vecubagem > 0 ? ((valoresRotaAgrupada.rocubagem / this.veiculoSemFiltro[indexVeiculo].vecubagem) * 100).toFixed(2) : 0
          };
          this.veiculoSemFiltro[indexVeiculo].progressocub = parseFloat(this.veiculoSemFiltro[indexVeiculo].progressocub);
          // FIM DE ADIÇÃO DE NOVOS ELEMENTOS VEICULOSSEMFILTRO
        }
      }

      if (this.arrayRastro.length <= 0) {
        this.toastShow('Ops!', 'Você precisa roteirizar os pontos!', 'danger')
        return;
      } else {
        this.$bvModal.show('modalVincularVeiculo')
      }
    },

    /**
    * @description Limpa alguns parametros chama a rota para desagrupar as cargas
    * @author Yuri 🇧🇷
    */
    async desagruparCargas() {
      if (!this.rotaSelecionadaDesagrupar[0]) {
        this.toastShow('Ops!', 'Você precisa selecionar uma rota!', 'danger')
      } else {
        this.loadingPanel = true;
        this.rotasPendentesAgrupar = [];
        this.rotaProgramada = [];
        this.pontosSelecionados = [];
        this.rotasAgruparSelecionadas = [];
        this.rotasAgrupadas = [];
        this.arrayRastro = [];
        this.rotaOsrm = [];
        this.pontoInicialFinal = [];
        this.dadosRota = [];
        this.selectedItemIndex = null;
        this.itensRota = [];
        this.tamanhoDivMapa = 100;
        this.expandirMapa = false;
        const url = `/roteirizador/controle/cargas/desagrupar`;
        const body = this.rotaSelecionadaDesagrupar[0];

        try {
          await new HttpRequest().Post(url, body).then((data) => {
            this.getRotas();
            if (data.data.status == 200) {
              this.toastShow('Sucesso', 'Rota desagrupada com sucesso!', 'success');
            }
          });
        } catch (error) {
          this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
          this.loadingPanel = false;
        } finally {
          this.loadingPanel = false;
          this.ordemRota = 0;
          this.getItensRotas();
        }
      }
    },

    /**
     * @description Abre o modalAlertaVeiculoComCarga verificar se o veiculo tem carga para o dia selecionado em this.dataInicioRota emite um alerta para o usuário
     * @param {Array} veiculo - Um array contendo os dados do veículo
     * @author Yuri 🇧🇷
     */
    async selecionarVeiculoAgruparCarga(veiculo) {
      this.loadingSelecionarVeiculo = true;
      const uri = '/roteirizador/controle/cargas/verificar/carga/veiculo'
      const obj = {
        veplaca: veiculo.veplaca,
        dataInicioRota: this.dataInicioRota
      }

      new HttpRequest().Post(uri, obj).then((data) => {
        if (data.data >= 1) {
          this.$bvModal.show('modalAlertaVeiculoComCarga')
          let message = `O veículo com a placa ${veiculo.veplaca} já possui uma carga para o dia ${this.dataInicioRota}.`;
          this.avisoVeiculoCarregado = message;
          this.veiculoSelecionadoAgruparCarga = veiculo;
        } else {
          this.confirmarAgrupamentoCarga(veiculo);
        }
      })
    },

    /**
    * @description Caso veículo tenha carga para o dia selecionado em this.dataInicioRota, função para confirmação do usuário para carregar neste dia.
    * @author Yuri 🇧🇷
    */
    confirmarAgrupamentoCargaVeiculoExiste() {
      this.confirmarAgrupamentoCarga(this.veiculoSelecionadoAgruparCarga);
      this.loadingSelecionarVeiculo = false;
    },

    /**
    * @description Função para agrupar as cargas depois de passar por todas as validações.
    * @param {Array} veiculo - Um array contendo os dados do veículo
    * @author Yuri 🇧🇷
    */
    async confirmarAgrupamentoCarga(veiculo) {
      this.avisoVeiculoCarregado = '';
      this.avisoItemCarregado = '';
      this.loadingPanel = true;

      var itens = [];

      this.itensRota.forEach(item => {
        itens.push({
          'ircodigo': item.ircodigo,
          'irordem': item.irordem,
          'irpeso': item.irpeso,
          'ircubagem': item.ircubagem,
          'irqtde': item.irqtde,
          'irvalor': item.irvalor,
          'irtempoprevisto': item.duracao,
          'irtempoparado': item.irtempoparado,
          'irdistancia': item.distancia,
          'irrota': item.irrota,
          'irlatitude': item.irlatitude,
          'irlongitude': item.irlongitude,
          'ircodigoexterno': item.ircodigoexterno,
          'irdocumento': item.irdocumento,
          'irnome': item.irnome,
        })
      });

      const kmRota = (this.rotaOsrm.distancia / 1000).toFixed(2);
      const rocusto = parseFloat(veiculo.vecusto) * kmRota;

      const body = {
        'kms': kmRota,
        'data': this.dataInicioRota,
        'pontoIni': this.pontoOrigemSelecionado[0].value,
        'pontoFim': this.pontoDestinoSelecionado[0].value,
        'placa': veiculo.veplaca,
        'cliente': this.empresaSelecionada[0],
        'infoRastro': this.dadosRotaOsrm,
        'cor': this.geraCor(),
        'datahoraFinalizacao': "",
        'tempoParada': "",
        'tempoHora': "",
        'velMedia': "",
        'motorista': [],
        'ajudante': [],
        'codigosItens': this.rotasAgruparSelecionadasAux,
        'itens': itens,
        'rocusto': parseFloat(rocusto.toFixed(2)),
      };
      const url = `/roteirizador/controle/cargas/agrupar`;
      this.$bvModal.hide('modalAlertaVeiculoComCarga')

      try {
        await new HttpRequest().Post(url, body).then((data) => {
          this.getRotas();
          this.toastShow('Sucesso', 'Rota agrupada com sucesso!', 'success');
        });
      } catch (error) {
        this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
        this.loadingPanel = false;
      } finally {
        this.pontosItensRotasNovas = [];
        this.mostraRotasAntigas = true;
        this.rotasPendentesAgrupar = [];
        this.rotaSelecionadaMostrarMapa = [];
        this.rotasAgrupadas = [];
        this.pontosSelecionados = [];
        this.rotasAgruparSelecionadas = [];
        this.rotasAgruparSelecionadasAux = [];
        this.editandoItens = 0;
        this.arrayRastro = [];
        this.rotaOsrm = [];
        this.pontoInicialFinal = [];
        this.arrayRastrosMapa = [];
        this.ordemRota = 0;
        this.getItensRotas();
        this.$bvModal.hide('modalVincularVeiculo')
        this.$bvModal.hide('modalAlertaVeiculoComCarga')
        this.loadingSelecionarVeiculo = false;
      }
    },

    /**
    * @description Gerador de cor para rota
    * @returns {string} hex da cor gerada.
    * @author Desconhecido
    */
    geraCor() {
      var cores = [
        '#6A5ACD', '#483D8B', '#191970', '#000080', '#00008B',
        '#0000CD', '#0000FF', '#6495ED', '#4169E1', '#1E90FF',
        '#00BFFF', '#87CEEB', '#ADD8E6', '#4682B4', '#708090',
        '#778899', '#00FFFF', '#00CED1', '#40E0D0', '#48D1CC',
        '#20B2AA', '#008B8B', '#5F9EA0', '#2F4F4F', '#00FF7F',
        '#90EE90', '#8FBC8F', '#00FF00', '#3CB371', '#2E8B57',
        '#006400', '#008000', '#228B22', '#32CD32', '#BDB76B',
        '#7CFC00', '#7FFF00', '#9ACD32', '#6B8E23', '#556B2F',
        '#808000', '#D2691E', '#DAA520', '#B8860B', '#8B4513',
        '#A0522D', '#BC8F8F', '#CD853F', '#9400D3', '#DEB887',
        '#D2B48C', '#7B68EE', '#9370DB', '#8A2BE2', '#4B0082',
        '#DC143C', '#9932CC', '#BA55D3', '#800080', '#8B008B',
        '#EE82EE', '#DA70D6', '#FFA07A', '#FF1493', '#FF69B4',
        '#C71585', '#FFB6C1', '#F08080', '#CD5C5C', '#FF8C00',
        '#800000', '#8B0000', '#B22222', '#A52A2A', '#FA8072',
        '#E9967A', '#FF4500', '#FF7F50', '#FF6347', '#FF0000',
      ]
      var cor = cores[Math.floor(Math.random() * (0 + cores.length))]
      return cor
    },

    /**
  * Seleciona uma rota para agrupar
  * @param {object} row linha selecionada (rota para agrupar)
  * @author Yuri 🇧🇷
  */
    changeItensRotaAgrupar(row) {
      if (this.mostrarRotasAntigas) {
        this.arrayRastrosMapa = this.rotasSelecionadasAgruparAux;
        this.mostrarRotasAntigas = false;
      }
      this.itensRotaAux = [];
      this.showTop = false;
      this.showAlertaRotaFixa = false;
      this.arrayRastro = [];
      this.pontoInicialFinal = [];
      this.visualizarMapa = true;
      this.mostraRotasAntigas = true;
      this.rotaOsrm = [];
      this.inicialFinalDesagrupar = [];
      this.showPontosMapaInicialFinalRotaAgrupada = false;
      this.rotaDesagrupar = [];
      this.ordemRota = 0;
      this.selectedItemIndex = null;
      this.itensRota = [];
      this.tamanhoDivMapa = 100;
      this.expandirMapa = false;
      const existeNaLista = this.rotasAgruparSelecionadas.some(item => item.rocodigo === row.rocodigo);

      if (existeNaLista) {
        this.rotasAgruparSelecionadas = this.rotasAgruparSelecionadas.filter(item => item.rocodigo !== row.rocodigo);
        const coordenadas = this.rotaProgramada.coordenadas;
        this.rotaProgramada = this.rotaProgramada.filter((item) => {
          if (item.rocodigo != row.rocodigo) {
            return item;
          }
        });
        this.rotaSelecionadaMostrarMapa = this.rotaSelecionadaMostrarMapa.filter((item) => {
          if (item.rocodigo != row.rocodigo) {
            return item;
          }
        });

        this.arrayRastrosMapa = [];

        for (var indexItemRota in this.rotaSelecionadaMostrarMapa) {
          let rastrRota = this.rotaSelecionadaMostrarMapa[indexItemRota].ropolyline ? Array.from(this.rotaSelecionadaMostrarMapa[indexItemRota].ropolyline) : [];

          this.arrayRastrosMapa.push(rastrRota);
        }

        this.ordemRota--;
      } else {
        const rotaAgrupar = { 'ordemRota': this.ordemRota, ...row };
        this.rotasAgruparSelecionadas.push(rotaAgrupar);
        this.dados.forEach((item) => {
          if (item[0].rocodigo == row.rocodigo) {
            this.rotaProgramada.push({
              coordenadas: [],
              pontos: [],
              latRetorno: item[3][0] ? item[3][0].polatitude : '',
              lngRetorno: item[3][0] ? item[3][0].polongitude : '',
              latSaida: item[2][0] ? item[2][0].polatitude : '',
              lngSaida: item[2][0] ? item[2][0].polongitude : '',
              itens: item[1],
              ...row
            });

            const decoded = Array.isArray(item[0].ropolyline) ? item[0].ropolyline : decodePoly.decode(item[0].ropolyline);
            const rota = [
              {
                rocodigo: item[0].rocodigo,
                pontoInicial:
                  [
                    parseFloat(item[0].polatsaida),
                    parseFloat(item[0].polonsaida),
                    item[0].podescricao,
                    item[0].poendretorno
                  ],
                pontoFinal:
                  [
                    parseFloat(item[0].polatretorno),
                    parseFloat(item[0].polonretorno),
                    item[0].podescricao,
                    item[0].poendsaida
                  ],
                itens: item[1],
                ropolyline: decoded,
              }
            ]

            this.rotaSelecionadaMostrarMapa.push(rota[0]);
            let rastroMapa = rota[0].ropolyline ? Array.from(rota[0].ropolyline) : [];
            this.$refs.mapaSimples.flyToBounds(rastroMapa);
          }
          this.ordemRota++;
        });

        setTimeout(() => {
          this.arrayRastrosMapa = [];
          for (var index in this.rotaSelecionadaMostrarMapa) {
            let rastrRota = this.rotaSelecionadaMostrarMapa[index].ropolyline ? Array.from(this.rotaSelecionadaMostrarMapa[index].ropolyline) : [];
            this.arrayRastrosMapa.push(rastrRota);
          }
        }, 200);
      }

      let itensRotaSelecionados = [];

      this.rotaProgramada.map((rota) => {
        rota.itens.map((itens) => {
          itensRotaSelecionados.push(itens);
        })
      })

      itensRotaSelecionados = itensRotaSelecionados.map(function (objeto) {
        return {
          ...objeto,
          marcadores: [objeto.irmarcador],
        };
      });
    },

    /**
    * Seleciona uma rota para desagrupar
    * @param {object} row linha selecionada (rota para desagrupar)
    * @author Yuri 🇧🇷
    */
    changeItensRotaDesgrupar(row, index) {
      this.arrayRastrosMapa = [];
      this.inicialFinalDesagrupar = [];
      this.showPontosMapaInicialFinalRotaAgrupada = false;
      this.rotaSelecionadaMostrarMapa = [];
      this.rotaDesagrupar = [];
      this.arrayRastro = [];
      this.pontoInicialFinal = [];
      this.rotaSelecionadaDesagrupar = [];
      if (this.selectedItemIndex === index) {
        this.selectedItemIndex = -1;
        this.rotaOsrm = [];
      } else {
        this.selectedItemIndex = index;
      }
      if (this.selectedItem != null && this.selectedItem.rocodigo == row.rocodigo) {
        this.selectedItem = null;
        this.selectedItemRadio = null;
      } else {
        this.selectedItem = row;
        const decoded = Array.isArray(row.ropolyline) ? row.ropolyline : decodePoly.decode(row.ropolyline);
        this.rotaDesagrupar = decoded;
        this.$refs.mapaSimples.flyToBounds(decoded);
        this.inicialFinalDesagrupar = row;
        this.showPontosMapaInicialFinalRotaAgrupada = true;
        this.rotaSelecionadaDesagrupar.push(row);
        const tempoEmHorasString = row.rotempo;
        const tempoArray = tempoEmHorasString.split(':');
        const horas = parseInt(tempoArray[0]);
        const minutos = parseInt(tempoArray[1]);
        const segundos = parseInt(tempoArray[2]);
        const segundosTotal = horas * 3600 + minutos * 60 + segundos;

        var peso = isNaN(row.ropeso) ? 0 : row.ropeso;
        var valor = isNaN(row.rocusto) ? 0 : row.rocusto;
        var volume = isNaN(row.roqtde) ? 0 : row.roqtde;
        var cubagem = isNaN(row.rocubagem) ? 0 : row.rocubagem;
        var distancia = isNaN(row.rokm) ? 0 : row.rokm;
        var tempo = segundosTotal;

        const infoRota = {
          tempo: tempo,
          distancia: distancia * 1000,
          peso: peso,
          valor: parseFloat(valor),
          volume: volume,
          cubagem: cubagem,
        }

        this.rotaOsrm = [];
        this.rotaOsrm = infoRota;
      }
    },

    /**
    * Realiza uma consulta no banco para salvar na variável this.valueVeiculos e this.veiculoSemFiltro todos
    * os veículos com base na empresa selecionada na variável this.empresaSelecionada.
    * @author Yuri 🇧🇷
    */
    getVeiculos() {
      this.loadingVeiculos = true;
      this.optionsVeiculos = [];
      var objEmpresas = {
        empresas: this.empresaSelecionada,
        modulo: ['T'],
        status: ['A', 'I']
      }
      var obj = { 'info': objEmpresas };
      let uri = '/administrativo/cadastros/veiculos/listar'

      new HttpRequest().Post(uri, obj).then((data) => {
        this.loadingVeiculos = false;

        this.valueVeiculos = data.data.carros;
        this.veiculoSemFiltro = data.data.carros;

        data.data.carros.map((item) => {
          let carro = { value: item.veplaca, description: item.veplaca };
          this.optionsVeiculos.push(carro);
        });
      })
        .catch(() => conectionError())
        .finally(() => {
          this.loadingVeiculos = false;
        })

    },

    /**
    * Realiza uma consulta no banco para salvar na variável this.optionsRotas todas as rotas pendentes e disponíveis para agrupar
    * com base no intervalo, this.dataIntervalo, empresa, this.empresaSelecionada[0] e veículo this.dadosVeiculo.
    * @author Yuri 🇧🇷
    */
    getRotas() {
      this.loadingRotas = true;
      let urlBase = '/roteirizador/controle/cargas/listar'
      let datas = this.dataIntervalo.split('-');
      let obj = { 'clcodigo': this.empresaSelecionada[0], 'data': datas, 'veiculos': this.dadosVeiculo };
      let rotas = [];

      new HttpRequest().Post(urlBase, obj).then((data) => {
        rotas = data.data;
      }).finally(() => {
        this.optionsRotas = [];
        for (let itensRotas in rotas) {
          this.optionsRotasConsulta.push(rotas[itensRotas]);
          this.optionsRotas.push(
            {
              value: rotas[itensRotas].rocodigo, description: rotas[itensRotas].rocodigo
            }
          )
        }

        this.loadingRotas = false;
      });
    },

    /**
    * Realiza uma consulta no banco para salvar na variável this.rotasPendentesAgrupar todas os itens de cada rota, realiza também 
    * uma consulta para retornar os itens agrupadas de cada rota.
    * @author Yuri 🇧🇷
    */
    getItensRotas() {
      this.itensRotaAux = [];
      this.loadingPanel = true;
      this.rotasPendentesAgrupar = [];
      this.rotaProgramada = [];
      this.pontosSelecionados = [];
      this.rotasAgruparSelecionadas = [];
      this.rotasAgrupadas = [];
      this.arrayRastro = [];
      this.rotaOsrm = [];
      this.pontoInicialFinal = [];
      this.inicialFinalDesagrupar = [];
      this.showPontosMapaInicialFinalRotaAgrupada = false;
      this.rotaDesagrupar = [];
      this.ordemRota = 0;
      this.selectedItemIndex = null;
      this.itensRota = [];
      this.tamanhoDivMapa = 100;
      this.expandirMapa = false;
      this.rotaSelecionadaDesagrupar = [];
      this.selectedItemIndex = null;
      this.itensRota = [];
      this.tamanhoDivMapa = 100;
      this.expandirMapa = false;
      this.iconBotaoVisualizarPontosMapa = mdiEye;
      this.arrayRastrosMapa = [];
      this.rotaSelecionadaMostrarMapa = [];

      let urlBase = '/roteirizador/controle/cargas/listar/itens/rotas'
      let obj = {};
      let datas = this.dataIntervalo.split('-');
      obj = { 'rotas': this.dadosRota, 'veiculos': this.dadosVeiculo, 'empresa': this.empresaSelecionada, 'data': datas };
      if (this.optionsRotas.length <= 0) {
        this.visualizarMapa = false;
      }

      new HttpRequest().Post(urlBase, obj).then((data) => {
        this.dados = data.data;

        if (data.data.length <= 0) {
          this.statusbar = 'error';
        }

        let urlBase = '/roteirizador/controle/cargas/listar/itens/rotas/veiculos'
        let obj = { 'empresa': this.empresaSelecionada };

        new HttpRequest().Post(urlBase, obj).then((data) => {
          // Ordenar this.valueVeiculos por veplaca
          this.valueVeiculos.sort((a, b) => (a.veplaca > b.veplaca) ? 1 : -1);

          // Ordenar data.data por veiculo
          data.data.sort((a, b) => (a.veiculo > b.veiculo) ? 1 : -1);

          // Agora, podemos aplicar o filtro
          this.valueVeiculos.filter((veiculoSelect, indexVeiculo) => {
            this.valueVeiculos[indexVeiculo] = {
              ...this.valueVeiculos[indexVeiculo],
              'totalpedidos': data.data[indexVeiculo].totalItensRotasPendentes
            };
            this.valueVeiculos[indexVeiculo].totalpedidos = parseInt(this.valueVeiculos[indexVeiculo].totalpedidos);
            //FIM DE ADIÇÃO DE NOVOS ELEMENTNOS VALUEVEICULOS

            // ADICIONANDO NOVOS ELEMENTOS EM VEICULO SEM FILTRO
            this.veiculoSemFiltro[indexVeiculo] = {
              ...this.veiculoSemFiltro[indexVeiculo],
              'totalpedidos': data.data[indexVeiculo].totalItensRotasPendentes
            };
            this.veiculoSemFiltro[indexVeiculo].totalpedidos = parseInt(this.veiculoSemFiltro[indexVeiculo].totalpedidos);
          });
        })

        for (var itemRota in data.data) {
          if (data.data[itemRota][0].roagrupada != true) {
            const ano = data.data[itemRota][0].rodata.split('-')[0];
            const mes = data.data[itemRota][0].rodata.split('-')[1];
            const dia = data.data[itemRota][0].rodata.split('-')[2];
            const dataCompleta = dia + '/' + mes + '/' + ano;

            let objItemnsRotaSalvar = {
              'rocodigo': parseInt(data.data[itemRota][0].rocodigo),
              'ropeso': parseInt(data.data[itemRota][0].ropeso),
              'rotempo': data.data[itemRota][0].rotempo,
              'roplaca': data.data[itemRota][0].roplaca,
              'rovalor': data.data[itemRota][0].rovalor,
              'roqtde': data.data[itemRota][0].roqtde,
              'rocusto': parseFloat(data.data[itemRota][0].rocusto) ? parseFloat(data.data[itemRota][0].rocusto) : 0,
              'rodata': dataCompleta,
              'ropontosaida': data.data[itemRota][2][0] ? data.data[itemRota][2][0].podescricao : '',
              'ropontoretorno': data.data[itemRota][3][0] ? data.data[itemRota][3][0].podescricao : '',
              'rocubagem': parseFloat(data.data[itemRota][0].rocubagem),
              'rokm': parseFloat(data.data[itemRota][0].rokm),
              'totalPedidos': data.data[itemRota][4].totalPedidos,
              'podescricao': data.data[itemRota][3][0] ? data.data[itemRota][3][0].podescricao : '',
              'poendereco': data.data[itemRota][3][0] ? data.data[itemRota][3][0].poendereco : '',
              'pesoMax': parseFloat(data.data[itemRota][0].ropeso) > 0 && parseFloat(data.data[itemRota][4].vemaxpeso) > 0 ? ((parseFloat(data.data[itemRota][0].ropeso) / parseFloat(data.data[itemRota][4].vemaxpeso)) * 100).toFixed(2) : 0,
              'cubagemMax': parseFloat(data.data[itemRota][0].rocubagem) > 0 && parseFloat(data.data[itemRota][4].vecubagem) ? ((parseFloat(data.data[itemRota][0].rocubagem) / parseFloat(data.data[itemRota][4].vecubagem)) * 100).toFixed(2) : 0
            };

            this.rotasPendentesAgrupar.push(objItemnsRotaSalvar);
            this.pontosOrigem = data.data[itemRota][5];
            this.pontosDestino = data.data[itemRota][5];
          }
        }

      }).finally(() => {
        this.loadingPanel = false;
        if (this.rotasPendentesAgrupar.length <= 0) {
          this.visualizarMapa = false;
        } else {
          this.visualizarMapa = true;
        }
      });

      obj = { 'empresa': this.empresaSelecionada, 'data': datas };

      new HttpRequest().Post('/roteirizador/controle/cargas/listar/itens/rotas/agrupadas', obj).then((data) => {
        for (var itemRota in data.data) {
          const rotaAgrupadaReturn = data.data[itemRota][0][0];
          const pontoSaidaReturn = data.data[itemRota][2][0]
          const pontoRetornoReturn = data.data[itemRota][3][0]
          const veiculoReturn = data.data[itemRota][4]
          const ano = rotaAgrupadaReturn.rodata.split('-')[0];
          const mes = rotaAgrupadaReturn.rodata.split('-')[1];
          const dia = rotaAgrupadaReturn.rodata.split('-')[2];
          const dataCompleta = dia + '/' + mes + '/' + ano;

          let objItemnsRotaSalvar = {
            'rocodigo': parseInt(rotaAgrupadaReturn.rocodigo),
            'ropeso': parseInt(rotaAgrupadaReturn.ropeso),
            'rotempo': rotaAgrupadaReturn.rotempo,
            'roplaca': rotaAgrupadaReturn.roplaca,
            'rodata': dataCompleta,
            'ropontosaida': pontoSaidaReturn ? pontoSaidaReturn.podescricao : '',
            'rolatInicio': pontoSaidaReturn ? parseFloat(pontoSaidaReturn.polatitude) : '',
            'poenderecoInicio': pontoSaidaReturn ? pontoSaidaReturn.poendereco : '',
            'poenderecoRetorno': pontoRetornoReturn ? pontoRetornoReturn.poendereco : '',
            'roLonInicio': pontoSaidaReturn ? parseFloat(pontoSaidaReturn.polongitude) : '',
            'rolatRetorno': pontoRetornoReturn ? parseFloat(pontoRetornoReturn.polatitude) : '',
            'roLonRetorno': pontoRetornoReturn ? parseFloat(pontoRetornoReturn.polongitude) : '',
            'ropontoretorno': pontoRetornoReturn ? pontoRetornoReturn.podescricao : '',
            'rocubagem': parseFloat(rotaAgrupadaReturn.rocubagem),
            'rokm': parseFloat(rotaAgrupadaReturn.rokm),
            'rocusto': rotaAgrupadaReturn.rocusto,
            'rovalor': parseFloat(rotaAgrupadaReturn.rovalor),
            'roqtde': parseInt(rotaAgrupadaReturn.roqtde),
            'ropolyline': rotaAgrupadaReturn.ropolyline,
            'romotorista': rotaAgrupadaReturn.romotorista,
            'podescricao': pontoRetornoReturn ? pontoRetornoReturn.podescricao : '',
            'poendereco': pontoRetornoReturn ? pontoRetornoReturn.poendereco : '',
            'pesoMax': rotaAgrupadaReturn.ropeso > 0 && veiculoReturn.vemaxpeso > 0 ? ((rotaAgrupadaReturn.ropeso / veiculoReturn.vemaxpeso) * 100).toFixed(2) : 0,
            'cubagemMax': rotaAgrupadaReturn.rocubagem > 0 && veiculoReturn.vecubagem > 0 ? ((rotaAgrupadaReturn.rocubagem / veiculoReturn.vecubagem) * 100).toFixed(2) : 0,
            'itens': data.data[itemRota][1],
            'totalPedidos': veiculoReturn.totalPedidos,
          };

          this.rotasAgrupadas.push(objItemnsRotaSalvar);
        }
      })
    },

    /**
     * @description Método para mostrar o toast na tela
     * @param {string} msg   - mensagem que vai aparecer no corpo do toast
     * @param {string} type  - qual o tipo do toast
     * @param {string} title - título para inserir no cabeçalho
     * @author Vitor Hugo 🐨
     */
    toastShow(title, msg, type) {
      this.$bvToast.toast(msg, {
        autoHideDelay: 2500,
        variant: type,
        title: title,
      });
    },

    /**
    * Limpa as informações do mapa
    * @author Yuri 🇧🇷
    */
    limpaInfoMapa() {
      this.rotaOsrm = {
        tempo: 0,
        distancia: 0,
        volume: 0,
        peso: 0,
        valor: 0,
        cubagem: 0,
      }
    },

    /**
    * Limpa as informações da rota selecionada para agrupar
    * @author Yuri 🇧🇷
    */
    limparRotaProgramada() {
      this.rotaProgramada.latSaida = 0
      this.rotaProgramada.lngSaida = 0
      this.rotaProgramada.latRetorno = 0
      this.rotaProgramada.lngRetorno = 0
      this.rotaProgramada.veiculo = ''
      this.rotaProgramada.coordenadas = []
      this.rotaProgramada.pontos = []
      this.tabsPag[0].disabled = false
      this.tabsPag[1].disabled = false
    },

    /**
     * Função para mostrar o rastro da rota no mapa ajusta o zoom do mapa para caber a rota completa no mapa
    * @param {array|string} rota
    * @param {boolean} fly
    * @param {int} timeout
    * @author Desconhecido
    */
    setRastroMapa(rota, fly = true, timeout = 500) {
      const decoded = Array.isArray(rota) ? rota : decodePoly.decode(rota);
      this.arrayRastro = decoded ? Array.from(decoded) : [];
      this.arrayRastroAux = decoded ? Array.from(decoded) : [];
      this.$refs.mapaSimples.flyToBounds(this.arrayRastro);
    },

    /**
    * Função de ordenação de pedidos (irordem).
    * 
    * - a ordenação dos itens deve sempre respeitar o atributo irordem, que indica a posicao 
    * 		que o item fica na lista
    * - itens com atributo "selecionado == true" sejam ordenados de forma crescente
    * - itens com atributos "irinterjornada == true && irintrajornada == true" sejam 
    * 		ordenados de forma crescente, desde que não fiquem antes de itens com 
    * 		"irordem != 0 || selecionado == true"
    * - os itens com "irordem == 0 || selecionado != false" ficam no fim da lista ordenada
    * 
    * @param {object} a objeto item rota
    * @param {object} b objeto item rota
    * @author Desconhecido
    */
    sortFnOrdemPedidos(a, b) {
      if (a != undefined && b != undefined) {

        const isSelected = (item) => item.selecionado === true;
        const isInterIntraJornada = (item) => item.irinterjornada === true && item.irintrajornada === true;
        const isOrdemZeroOrSelected = (item) => item.irordem === 0 || item.selecionado !== false;
        if (isSelected(a) && isSelected(b)) {
          return a.irordem - b.irordem
        }
        if (isSelected(a) && !isSelected(b)) {
          return -1;
        }
        if (!isSelected(a) && isSelected(b)) {
          return 1;
        }
        if (isInterIntraJornada(a) && !isInterIntraJornada(b)) {
          if (!isOrdemZeroOrSelected(a)) {
            return a.irordem - b.irordem;
          }
          return 1;
        }
        if ((a.irinterjornada || a.irintrajornada) && !(b.irinterjornada || b.irintrajornada)) {
          if (isSelected(a) && isSelected(b)) {
            if (!isOrdemZeroOrSelected(a)) {
              return a.irordem - b.irordem;
            }
            return 1;
          }
          return -1;
        }
        if (!(a.irinterjornada || a.irintrajornada) && (b.irinterjornada || b.irintrajornada)) {
          if (isSelected(a) && isSelected(b)) {
            if (!isOrdemZeroOrSelected(a)) {
              return a.irordem - b.irordem;
            }
            return 1;
          }
          return 1;
        }
        if (!isSelected(a) && !isSelected(b)) {
          if (isInterIntraJornada(a) && isInterIntraJornada(b)) {
            if (!isOrdemZeroOrSelected(a)) {
              return a.irordem - b.irordem;
            }
            return 1;
          }
          return a.irnome?.localeCompare(b.irnome) ?? 0; // Comparação alfabética das strings irnome
        }
        return 0;
      }
    },

    /**
    * Função para organizar os pontos da rota e reformular a ordem que vai mostrar no mapa, mudando o irordem para o novo
    * @param {object} value objeto com os pontos da rota
    * @author Desconhecido
    */
    arrumaOrdemPontosRoteirizados(value) {
      var pontoRoteirizados = []
      if (value.length < 2) {
        return
      }
      if (!Array.isArray(value)) {
        var aux = Object.keys(value).map((k) => {
          return value[k]
        })
        value = aux
      }
      pontoRoteirizados = value
      pontoRoteirizados.shift()
      pontoRoteirizados.pop()
      var selecionados = this.rotaProgramada;
      pontoRoteirizados.map((e, key) => {
        let origem = key
        if (typeof selecionados[e.index_origem] !== 'undefined') {
          origem = e.index_origem
        }
        selecionados[origem] ? selecionados[origem].irordem = e.waypoint_index : 0
        selecionados[origem] ? selecionados[origem].distancia = e.distancia : 0
        selecionados[origem] ? selecionados[origem].distanciaseq = e.distanciaseq : 0
        selecionados[origem] ? selecionados[origem].duracao = e.duracao : 0
        selecionados[origem] ? selecionados[origem].duracaoseq = e.duracaoseq : 0
        selecionados[origem] ? selecionados[origem].show = true : true
      })
      selecionados.sort(this.sortFnOrdemPedidos)
      let duracaototal = 0
      selecionados.map((e, key) => {
        e.duracaoseq = e.duracao + duracaototal
        duracaototal = e.duracaoseq + e.irtempoparado
      })

      var itensSelecionados = [];
      this.pontosItensRotasNovas = [];

      selecionados.forEach((item) => {
        const itens = item.itens;
        itens.forEach((itemSelecionado) => {
          itensSelecionados.push(itemSelecionado);
        });
      })

      for (var indexValue in value) {
        var item = itensSelecionados[indexValue];
        item.irordem = value[indexValue].waypoint_index;
      }
    },

    /**
    * calcula os valores de peso, volume e valor dos pedidos
    * @param {any} itensRota objeto item rota
    * @author Desconhecido
    */
    calculaValoresPedidos(itensRota) {
      var peso = 0.00
      var valor = 0.00
      var volume = 0
      var cubagem = 0.00
      for (const pedido of itensRota) {
        peso = peso + parseFloat(pedido.ropeso)
        valor = valor + parseFloat(pedido.rovalor)
        volume = volume + parseInt(pedido.roqtde)
        cubagem = cubagem + parseFloat(pedido.rocubagem)
      }
      return {
        peso: peso,
        valor: valor,
        volume: volume,
        cubagem: cubagem,
      }
    },

    /**
    * Soma todos os valores da rota, peso, valor, volume, cubagem e salva na variável this.rotaOsrm
    * @author Desconhecido
    */
    calculaValoresAux() {
      let valores = this.calculaValoresPedidos(this.rotaProgramada);
      this.rotaOsrm.peso = valores.peso;
      this.rotaOsrm.valor = valores.valor;
      this.rotaOsrm.volume = valores.volume;
      this.rotaOsrm.cubagem = valores.cubagem;
    },

    /**
    * recebe o tempo parado ou 0
    * calcula o tempo total da rota
    * @param {number} tempo
    * @author Desconhecido
    */
    calculaTempoRotaOsrm(tempo) {
      let tempoMedioParado = parseInt(tempo);
      const paradas = this.rotaProgramada;
      if (paradas.length > 0) {
        for (const pedido of paradas) {
          if (pedido.irtempoparado) {
            tempoMedioParado += parseInt(pedido.irtempoparado ?? 0);
          }
        }
      }
      this.rotaOsrm.tempo = tempoMedioParado;
      this.dadosRotaOsrm.tempoSegundos = tempoMedioParado;
    },

    /**
  * @description Faz a requisição para roteirizar os pedidos selecionados, e trata
  * os dados de retorno da requisição.
  * @param {string} url
  * @param {object} param
  * @param {array} param.pontos
  * @param {boolean} param.obedecerOrdenacao
  * @param {number} param.velocidadeMedia
  * @author Desconhecido
  */
    async roteirizarPedidosAux(url, param) {
      try {
        const { data, status } = await new HttpRequest().Post(url, param);
        if (status && data.rota.codigo !== 500) {
          this.arrumaOrdemPontosRoteirizados(data.ponto);
          this.calculaValoresAux();
          this.dadosRotaOsrm = data;
          const veiculos = this.valueVeiculos;
          this.valueVeiculos = [];

          const veiculosSemFiltro = this.veiculoSemFiltro;
          this.veiculoSemFiltro = [];

          veiculos.filter(((item, index) => {
            this.valueVeiculos.push({ ...item, totaldistancia: data.kms });
          }));

          veiculosSemFiltro.filter(((item, index) => {
            this.veiculoSemFiltro.push({ ...item, totaldistancia: data.kms });
          }));

          this.rotaOsrm.distancia = data.distancia;
          this.calculaTempoRotaOsrm(data.tempoSegundos);
          this.setRastroMapa(data.rota);
          this.showAlertaRotaFixa = false;
        }
      } catch (error) {
        this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
      } finally {
        this.loadingPanel = false;
      }
    },

    /**
    * ela interpreta como se fosse 3 valores
    * o primeiro valor, vai ser o ponto de saida
    * o segundo valor (opcional), vão ser os pontos selecionados
    * o terceiro valor, vai ser o ponto de retorno
    * ela pega esse valores, junta eles na ordem,
    * e devolve para o roteirizarPedidosPorTipo
    */
    preparaRota() {
      this.mostrarRotasAntigas = true;
      let gerarRota = [];

      if (this.pontoOrigemSelecionado[0]) {
        const pontoSaida = {
          'polatitude': parseFloat(this.pontoOrigemSelecionado[0].polatitude),
          'polongitude': parseFloat(this.pontoOrigemSelecionado[0].polongitude),
          'regiao': 0,
          'peso': 0,
          'nome': this.pontoOrigemSelecionado[0].podescricao,
          'codigo': this.pontoOrigemSelecionado[0].rocodigo
        };
        gerarRota.push(pontoSaida);
      }
      let selecionados = [];

      if (this.itensRota.length > 0) {
        selecionados.push(this.itensRota);
      } else {
        for (var indexItemRotaSelecionado in this.rotaSelecionadaMostrarMapa) {
          if (this.rotaSelecionadaMostrarMapa[indexItemRotaSelecionado].itens.length > 0) {
            selecionados.push(this.rotaSelecionadaMostrarMapa[indexItemRotaSelecionado].itens);
          }
        }
      }
      this.showTop = false;

      let pontos = [];

      for (var index in selecionados) {
        for (var indexItem in selecionados[index]) {
          pontos.push({
            'polatitude': selecionados[index][indexItem].polatitude ? parseFloat(selecionados[index][indexItem].polatitude) : 0,
            'polongitude': selecionados[index][indexItem].polongitude ? parseFloat(selecionados[index][indexItem].polongitude) : 0,
            'peso': selecionados[index][indexItem].irpeso,
            'podescricao': selecionados[index][indexItem].podescricao,
            'poendereco': selecionados[index][indexItem].poendereco,
            'codigo': selecionados[index][indexItem].ircodigo,
            'irordem': selecionados[index][indexItem].irordem,
            'ircodigo': selecionados[index][indexItem].ircodigo,
            'irmarcador': selecionados[index][indexItem].irmarcador,
            ...selecionados[index][indexItem],
          });
        }
      }

      for (var indexItemPonto in pontos) {
        pontos[indexItemPonto].irordem = indexItemPonto;
      }
      this.pontosItensRotasNovas = pontos;
      this.itensRota = pontos.map(function (objeto) {
        return {
          ...objeto,
          irordem: parseInt(objeto.irordem) + 1,
          marcadores: [objeto.irmarcador],
          selecionado: true
        };
      });

      if (this.itensRota.length > 0) {
        this.tamanhoDivMapa = 90;
        this.expandirMapa = true;
      } else {
        this.tamanhoDivMapa = 100;
        this.expandirMapa = false;
      }

      this.itensRotaAux = [...this.itensRota];
      this.itensRotaAntigoAux = JSON.parse(JSON.stringify(this.itensRota));

      if (this.rotasAgruparSelecionadas.length > 0) {
        this.rotasAgruparSelecionadasAux = JSON.parse(JSON.stringify(this.rotasAgruparSelecionadas));
      }
      this.pontoInicialFinalAux = JSON.parse(JSON.stringify(this.pontoInicialFinal));

      var itensSelecionados = [];
      this.rotaSelecionadaMostrarMapa.forEach((item) => {
        const itens = item.itens;
        itens.forEach((itemSelecionado) => {
          itensSelecionados.push(itemSelecionado);
        });
      })

      const pontosMap = new Map();
      this.pontosItensRotasNovas.forEach((ponto, index) => {
        pontosMap.set(ponto, index);
      });

      itensSelecionados.sort((a, b) => {
        const indexA = pontosMap.get(a);
        const indexB = pontosMap.get(b);
        return indexA - indexB;
      });

      for (var indexItemPontoSelecionado in itensSelecionados) {
        itensSelecionados[indexItemPontoSelecionado].irordem = indexItemPontoSelecionado;
      }

      itensSelecionados.forEach((itemSelecionado) => {
        const ircodigo = itemSelecionado.ircodigo;
        const itemCorrespondente = this.rotaSelecionadaMostrarMapa.find((item) => item.ircodigo === ircodigo);

        if (itemCorrespondente) {
          itemSelecionado.irordem = itemCorrespondente.irordem;
        }
      });

      gerarRota.push(...pontos);
      if (this.pontoDestinoSelecionado[0]) {
        const pontoRetorno = {
          'polatitude': parseFloat(this.pontoDestinoSelecionado[0].polatitude),
          'polongitude': parseFloat(this.pontoDestinoSelecionado[0].polongitude),
          'regiao': 0,
          'peso': 0,
          'nome': this.pontoDestinoSelecionado[0].podescricao,
          'codigo': this.pontoDestinoSelecionado[0].rocodigo
        };
        gerarRota.push(pontoRetorno);
      }

      if (this.pontoOrigemSelecionado.length <= 0 || this.pontoDestinoSelecionado.length <= 0) {
        this.toastShow('Ops!', 'Informe um ponto de origem e destino!', 'danger');
        return;
      } else {
        this.pontoInicialFinal.push({
          latRetorno: this.pontoOrigemSelecionado[0].polatitude,
          lngRetorno: this.pontoOrigemSelecionado[0].polongitude,
          podescricao: this.pontoOrigemSelecionado[0].podescricao,
          poendereco: this.pontoOrigemSelecionado[0].poendereco
        })

        this.pontoInicialFinal.push({
          latRetorno: this.pontoDestinoSelecionado[0].polatitude,
          lngRetorno: this.pontoDestinoSelecionado[0].polongitude,
          podescricao: this.pontoDestinoSelecionado[0].podescricao,
          poendereco: this.pontoDestinoSelecionado[0].poendereco,
        })

        this.mostraRotasAntigas = false;
        this.pontosTeste = gerarRota;

        this.pontoInicialFinalAux = [...this.pontoInicialFinal];
        this.itensRotaAux = [...this.itensRota];

        return gerarRota;
      }
    },

    /**
  * @description Prepara e valida os dados para roteirizar os pedidos. Chama a
  * função [roteirizarPedidosAux] para fazer a requisição.
  * @param {int} timdiPluspo Tipo de roteirização: 1(default)|2
  * @author Desconhecido
  */
    async roteirizarPedidosPorTipo(tipo = 1) {
      this.opcaoEscolhidaRoteirizar = tipo;
      this.mostraRotasAntigas = false;
      this.rotasSelecionadasAgruparAux = this.arrayRastrosMapa;
      this.arrayRastrosMapa = [];

      if (this.pontoOrigemSelecionado.length <= 0 || this.pontoDestinoSelecionado.length <= 0) {
        this.toastShow('Ops!', 'Informe um ponto de origem e destino!', 'danger');
        return;
      } else {
        this.pontoInicialFinal = [];
        let gerarRota = this.preparaRota();
        this.arrayRastro = [];
        this.limparRotaProgramada();
        this.limpaInfoMapa();
        const obedecerOrdenacao = this.obedecerOrdem;
        let url = '';
        this.loadingPanel = true;
        const rotaCurta = gerarRota.length < 503;

        if (rotaCurta) {
          url = `${this.url}rota/tipo/gerar`;
        } else {
          url = `${this.url}roteiriza`;
          gerarRota = gerarRota.map((ponto) => {
            return {
              lat: ponto.polatitude,
              lon: ponto.polongitude,
              codigo: ponto.codigo,
              peso: ponto.irpeso,
              nome: ponto.irnome,
              regiao: ponto.regiao
            };
          });
        }
        const obj = {
          inicio: [gerarRota[0].lat, gerarRota[0].lon],
          pontos: gerarRota,
          velocidadeMedia: 0,
          obedecerOrdenacao,
          tipo
        };

        await this.roteirizarPedidosAux('/roteirizador/rota/manual/rota/tipo/gerar', obj);
        this.$bvModal.hide('modalConfirmarCarregamento');
      }
    },

    /**
    * Abre o modal para selecionar o ponto Inicial e de Destino da nova rota agrupada
    * @param {int} tipo Tipo de roteirização: 1(default)|2
    * @author Yuri 🇧🇷
    */
    abreModalDefinirPontoSaidaRetorno(tipo) {
      this.pontoOrigemSelecionado = [];
      this.pontoDestinoSelecionado = [];
      this.tipoRoteirizacaoSelecionado = tipo;
      this.$bvModal.show('modalConfirmarCarregamento')
    },

    /**
    * @listens changeInput botão "Buscar"
    * @author Desconhecido
    */
    changeInputSearchPedido(value) {
      this.valueBuscaPedido = value;
      if (this.loadingPanel) return;
      if (!value) {
        this.showAllPedidos();
        return;
      }
      this.filterPedidos(value);
    },

    /**
    * Troca o valor da variável this.buttonAgruparDesagrupar, para mudar qual botão vai aparecer
    * em tela nas abas Rotas Pendentes e Rotas Agrupadas
    * @author Yuri 🇧🇷
    */
    trocaBotaoAgruparDesagrupar(aba) {
      if (aba != this.abaSelecionada) {
        this.buttonAgruparDesagrupar = !this.buttonAgruparDesagrupar;
        this.abaSelecionada = aba;
      }
      this.itensRota = [];
      this.itensRotaAux = [];
      this.showAlertaRotaFixa = false;
      this.showPontosCargaMapa = false;
      this.showTop = false;
      this.rotaOsrm = [];
      this.arrayRastro = [];
      this.pontoInicialFinal = [];
      this.statusbar = 'error';
      this.selectedItem = null;
      this.selectedItemRadio = null;
      this.rotaDesagrupar = [];
      this.arrayRastrosMapa = [];
      this.inicialFinalDesagrupar = [];
      this.showPontosMapaInicialFinalRotaAgrupada = false;
      this.rotaSelecionadaMostrarMapa = [];
      this.rotasAgruparSelecionadas = [];
      this.selectedItemIndex = null;
      this.itensRota = [];
      this.tamanhoDivMapa = 100;
      this.expandirMapa = false;
      var elements = document.getElementsByName("checkboxItensRota");

      for (var i = 0; i < elements.length; i++) {
        elements[i].checked = false;
      }

      if (this.buttonAgruparDesagrupar) {
        if (this.rotasAgrupadas.length <= 0) {
          this.visualizarMapa = false;
        } else {
          this.visualizarMapa = true;
        }
      } else {
        if (this.rotasPendentesAgrupar.length <= 0) {
          this.visualizarMapa = false;
        } else {
          this.visualizarMapa = true;
        }
      }
    },

    /**
    * Mostrar e esconde todos os pontos da rota no mapa
    * @author Yuri 🇧🇷
    */
    showPontosCarga() {
      this.showPontosCargaMapa = !this.showPontosCargaMapa;

      if (this.showPontosCargaMapa) {
        this.titleBotaoVerPontosMapa = 'Ocultar pontos';
        this.iconBotaoVisualizarPontosMapa = mdiEyeOff;
      } else {
        this.titleBotaoVerPontosMapa = 'Visualizar pontos';
        this.iconBotaoVisualizarPontosMapa = mdiEye;
      }
    },

    /**
    * Cancela o loadingo da tela quando o usuário clica em cancelar após o modal informando que o veículo
    * já tem carga para o dia selecionado.
    * @author Yuri 🇧🇷
    */
    cancelButtonModalVeiculoCarga() {
      this.loadingSelecionarVeiculo = false;
    },

    /**
    * Função para expandir e diminuir o mapa, clicando no botão na lateral do mapa.
    * @author Desconhecido
    */
    abreEfechaMapa() {
      if (this.itensRotaAux.length > 0) {
        if (this.tamanhoDivMapa == 90) {
          this.tamanhoDivMapa = 100;
          this.expandirMapa = false;
        } else {
          this.expandirMapa = true;
          this.tamanhoDivMapa = 90;
        }
      } else {
        this.toastShow('Atenção', 'Você precisa roteirizar os pontos!!', 'warning');
      }
    },

    /**
    * Função oa clicar no item do pedido, vai até o local e mostra para o usuário no mapa.
    * @param {item} array com dados do item
    * @author Desconhecido
    */
    flyToPedido(item) {
      try {
        const latlng = [item.polatitude, item.polongitude];
        const zoom = 20;
        const options = {
          animate: true,
          duration: 3,
          easeLinearity: 0.8
        };
        this.showPontosCargaMapa = true;
        this.$refs.mapaSimples.flyTo(latlng, zoom, options);
      } catch (error) {
        this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
      }
    },

    /**
     * @param {int|null} count
     */
    setTotalPedidos(count = null) {
      this.totalPedidos = count ?? 0;
      if (count >= this.limitePedidos) {
        const msg = `Parece que o total de pedidos selecionados atingiu o limite (${this.limitePedidos} pedidos)`;
        this.toastShow('Ops!', msg, 'danger');
      }
    },

    /**
   * Cria um novo objt marker para por na tela caso seja preciso, ou remove e
   * adiciona aos markers selecionados essa função só deve ser chamada quando
   * for um pedido ou uma popup emitindo evento.
   * @param { Pedido } pedido - os valores do ponto
   * @author Lucas Eduardo, Rafael
   */
    selecionaPedido(pedido) {
      if (!pedido.selecionado) {
        if (this.totalPedidos + 1 > this.limitePedidos) {
          this.setTotalPedidos(this.limitePedidos)
          return;
        }
      }

      pedido.selecionado = !pedido.selecionado;
      let count = 1;

      this.itensRota.forEach(e => {
        if (e.selecionado) {
          e.irordem = count;
          e.show = true;
          count++;
        } else {
          e.irordem = 0;
        }
      });

      this.setTotalPedidos();

      if (pedido.selecionado) {
        this.$refs.refCluster?.removeLayer(pedido.marker)
        this.$refs.refClusterX?.addLayer(pedido.marker)
      } else {
        this.$refs.refClusterX?.removeLayer(pedido.marker)
        this.$refs.refCluster?.addLayer(pedido.marker)
      }

      this.obedecerOrdem = true;
    },

    /**
     * evento de arrastar o componente
     * dentro do evento tem os valores, que sao usados para pegar a posicao do item
     * na array e selecionar ela, ele chama outras func para deixar a ordem certa
     * newDraggableIndex
     * newIndex
     * oldDraggableIndex
     * oldIndex
     * @author alterado por Rafael
     */
    endDrag(value) {
      this.showAlertaRotaFixa = true;
      let newDraggableIndex = value.newDraggableIndex;
      let oldDraggableIndex = value.oldDraggableIndex;
      if (newDraggableIndex === oldDraggableIndex) {
        return;
      }
      const totalPedidos = this.totalPedidos + 1;
      if (totalPedidos > this.limitePedidos) {
        this.setTotalPedidos(this.limitePedidos);
        return;
      }
      this.setTotalPedidos();

      let pedido = this.itensRota[newDraggableIndex];
      pedido.fixo = true
      if (!pedido.selecionado) {
        this.selecionaPedido(pedido, true)
      } else {
        this.$refs.refClusterX?.removeLayer(pedido.marker)
        pedido.selecionado = false
        this.selecionaPedido(pedido, true)
      }

      if (this.editandoItens == 0) {
        this.itensRota[newDraggableIndex].fixo = true;
        this.rotasAgruparSelecionadasAux = JSON.parse(JSON.stringify(this.rotasAgruparSelecionadas));
        this.rotasAgruparSelecionadas = [];
        this.arrayRastro = [];
        this.pontoInicialFinal = [];
        this.showPontosCargaMapa = false;
      }
      this.showTop = true;
      this.editandoItens++;
    },

    /**
     * Função responsável por desfazer o item que está fixo e retornar como estava a antiga rota.
    * @author Desconhecido
    */
    desfazerAcaoRotaFixa() {
      this.itensRota = this.itensRotaAntigoAux;
      this.rotasAgruparSelecionadas = this.rotasAgruparSelecionadasAux;
      this.arrayRastro = this.arrayRastroAux;
      this.pontoInicialFinal = this.pontoInicialFinalAux;
      this.showTop = false;
      this.showPontosCargaMapa = false;
      this.editandoItens = 0;

      if (this.arrayRastro.length > 0) {
        this.$refs.mapaSimples.flyToBounds(this.arrayRastro);
      }

      this.showAlertaRotaFixa = false;
      this.itensRota.forEach(e => {
        e.fixo = false;
      })
    },
  },

  computed: {
    defineTextoBotaoAviso() {
      return this.avisoItemCarregado ? 'Recarregar' : 'Confirmar'
    },

    /**
   * @description Faz o cálculo do custo da rota e retorna o valor formatado.
   * @return {string} R$ (valor do custo)
   */
    calcularCustoRota() {
      var custo = ''
      if (this.rota.custoVeiculo && this.rota.km) {
        custo = parseFloat(this.rota.custoVeiculo) * parseFloat(this.rota.km)
      }
      return custo ? 'R$ ' + custo : ''
    },

    center() {
      return L.latLng(this.latitude, this.longitude);
    },
  },

  watch: {
    'rota.coordenadas'(a, b) {
      this.$refs.mapaSimples.validateSize();
      this.removelAllLayers();
      this.coordenadas = this.rota.length > 0 ? this.rota[0].coordenadas : [];
      this.flyToCoordenadas();
    },
  }
})
</script>

<style>
.acoesMapa {
  display: grid;
  float: right;
  position: inherit;
  z-index: 401;
}

.styleColum {
  width: 50px;
}

.styleColumCheck {
  width: 1.5%;
  margin: auto;
}

.cursorPointer {
  cursor: pointer;
}

.divDadosMapa {
  font-family: nexabook, sans-serif !important;
  z-index: 900;
  text-align: left;
  font-weight: bold;
  border: 1px solid #e5e5e5;
  background: #00000054;
  padding-right: 0px !important;
  padding-top: 5px !important;
  padding-left: 0px !important;
  color: #fff;
  position: absolute !important;
}

.markerRotaOrdem {
  position: absolute;
  top: 4px;
  background-color: rgba(255, 255, 255, 0.24);
  padding: 0 6px;
  border-radius: 43px;
  font-size: 12px;
  left: 8px;
  width: 25px;
  text-align: center;
  font-weight: bold;
}

img {
  height: 30px;
  width: 40px;
}

.styleIcon {
  color: #428bca;
}

.div-popup {
  width: 500px !important;
}

.styleDivider {
  height: 1px;
  width: 100%;
  margin-bottom: 20px;
  background-color: #000;
}

.bemAlto {
  z-index: 999;
}

.panelEagle {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  background: #fff;
  border-top-color: #F5F5F5;
  border-top-width: 1px;
  color: #22262e;
}

.buttonMap {
  background-color: rgba(255, 255, 255, 0.767);
  opacity: 0.5;
  height: 100%;
  min-width: 40px;

  float: left;
  position: inherit;
  z-index: 401;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: rgb(10, 10, 10);
  }
}

.rotaciona {
  transform: rotate(180deg);
}

/* .infoMapa {
  transform: translateX(30%);
  z-index: 402;
  position: absolute;
  margin-left: 10px;
  color: rgb(255, 253, 253);
  font-weight: bolder;
  background: gray;
  text-align: left;
  min-width: 170px;
  opacity: 1 !important;
} */

.styleDivMapaListaPedidos {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.styleTextRoteirizarNovamente {
  font-weight: 750;
  margin-left: 3px;
  cursor: pointer
}
</style>
