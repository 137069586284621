<template>
    <panelEagle 
        id="cancelamentos"
        :loading ="loadingPanel">
        <div class="col-sm-12 nopadding ">          
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiCloseCircle" titulo="Cancelamentos"></tituloPage>
                </div>
                <div class="col-sm-6 divButtonsBasic divDontPrint">
                    <basicButtonsRelatoriosEIG
                        :disabledDropdown ="!Object.keys(dadosRelatorio).length"
                        @gerarRelatorio    ="gerar" 
                        @exportarRelatorio ="exportar"
                        :loading           ="loadingExportar"
                        :disabled          ="$v.selectedLVE.$invalid">
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12"><hr></div>
                    <div class="col-sm-12 justpaddingbottom row">
                        <div class="col-sm-2 nopadding">
                            <inputSingleDay 
                                @changeInput="changeInput" 
                                name="dataCancelamentos" 
                                :isObrigatorio="true">
                            </inputSingleDay>
                        </div>
                        <div class="col-sm-4 nopadding">
                            <selectAll 
                                @changeSelect="changeEmpresa" 
                                :isMultiple="true" 
                                nameForRadio="empresasCancelamento" 
                                :hasSelectAll="true" 
                                :labels="[{indexDFH: 'EM', description: 'Empresas*'}]" 
                                :optionsArray="optionsArrayEmpresas" 
								:selected="selectedEmpresa"
                                firstSelected="EM">
                            </selectAll>
                        </div>
                        <div class="col-sm-4 nopadding">
                            <selectAll 
                                @selectCriado ="createdLVE" 
                                @changeCheck  ="changeCheckLVE" 
                                @changeSelect ="changeLVE" 
                                :isMultiple   ="true" 
                                nameForRadio  ="lveselect" 
                                :hasSelectAll ="true" 
                                :labels       ="labelsSelectLVE" 
                                :optionsArray ="optionsLVE" 
                                :loading      ="carregando"
                                ref           ="LVE"
                                :disabled     ="$v.selectedEmpresas.$invalid"
                                firstSelected="L">
                            </selectAll>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
            <div class="col-sm-12 divHrDeBaixo"><hr></div>
        </div>
        <tableRelatorio>
            <slot slot="thead">
                <tr>
                    <th>Linha</th>
                    <th>Programado</th>
                    <th>Placa</th>
                    <th>Colaborador</th>
                    <th>Escala</th>
                    <th>Ações</th>
                </tr>
            </slot>
            <slot slot="tbody">
                <statusInformation 
                    v-if="tamanhoRelatorio == 0" 
                    :statusBar="statusBar">
                </statusInformation>
                <template>
                    <br>
                    <tr v-for="(dados, d) in dadosRelatorio"
                        :key="d"
                        class="dadosTabelaCancelamentos">
                        <td>
                            {{dados.lidescricao}}
                        </td>
                        <td>
                            {{dados.hrhorario}}
                        </td>
                        <td>
                            {{dados.rcplaca}}
                        </td>
                        <td>
                            {{dados.mtnome}}
                        </td>
                        <td>
                            {{dados.esdescricao}}
                        </td>
                        <td>
                            <simpleButton
								v-if="getCadastrar() || getEditar()"
                                title="Confirmar escala"
                                :icon="mdiCellphoneWireless"
                                type="blue"
                                event="click"
                                @click="modalConfirmarLinha(dados.rchorarioconfirmacao, dados.rccodigo,
                                    dados.rccliente)">
                            </simpleButton>
                        </td>
                    </tr>
                </template>
            </slot>
        </tableRelatorio>
        <!-- Modal confirmar linha -->
        <div>
            <modalEagle 
                @confirmButton="ajustarRota"
                @modalOpen="getMotoristasVeiculo" 
                title="Ajustar Rota" 
                :id="'modal-cancelamento-'" 
                :message="statusMessage" 
                :status="status">
                <slot slot="modalBody">
                    <b-overlay :show="loading">
                        <div class="col-sm-12 nopadding row">
                            <div class="col-sm-12">
                                Data: {{dataAtual}}
                            </div>
                            <div class="col-sm-4 nopadding">
                                <selectAll 
                                    @changeSelect="changeVeiculo" 
                                    :isMultiple="false" 
                                    nameForRadio="veiculosModalCancel" 
                                    :hasSelectAll="false" 
                                    :labels="[{indexDFH: 'V', 
                                    description: 'Veículos*'}]" 
                                    :optionsArray="optionsVeiculosModal" 
                                    :loading ="carregando"
                                    firstSelected="V">
                                </selectAll>
                            </div>
                            <div class="col-sm-4 nopadding">
                                <selectAll 
                                    @changeSelect="changeMotorista" 
                                    :isMultiple="false" 
                                    nameForRadio="motoristaModalCancel" 
                                    :hasSelectAll="false" 
                                    :labels="[{indexDFH: 'C', description: 'Motoristas*'}]" 
                                    :optionsArray="optionsMotoristaModal" 
                                    :loading ="carregando"
                                    firstSelected="C">
                                </selectAll>
                            </div>
                            <div class="col-sm-4 nopadding">
                                <inputSimple 
                                    name="nameEscala" 
                                    inputDisabled="disabled" 
                                    :value="escalaColaboradorModalConfirmar.esdescricao" 
                                    label="Escalas">
                                </inputSimple>
                            </div>
                        </div>
                    </b-overlay>
                </slot>
            </modalEagle>
        </div>
    </panelEagle>
</template>

<script>
import {mdiCloseCircle, mdiCellphoneWireless } from '@mdi/js'
import Vue               from 'vue'
import { required }      from 'vuelidate/lib/validators'
import { DateTime }      from 'luxon';
import {EmpresasService} from '../../../Services/auth/Empresas.service'
import {HttpRequest}     from '../../../Services/auth/HttpRequest.Service'
import {FiltrosService}  from '../../../Services/filtros/filtros.Service'
import { mapGetters } from 'vuex'

export default Vue.extend({
	name:'Cancelamentos',
	components:{
		'panelEagle'                : require('@/components/Atom/Panel/PanelEagle').default,
		'slideUpAndDown'            : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
		'tituloPage'                : require('@/components/Atom/Header/Titulo').default,
		'basicButtonsRelatoriosEIG' : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
		'inputSingleDay'            : require('@/components/Atom/Datas/InputSingleDay').default,
		'selectAll'                 : require('@/components/Atom/Select/SelectAll').default,
		'tableRelatorio'            : require('@/components/Atom/Table/TableRelatorio').default,
		'statusInformation'         : require('@/components/Atom/StatusInformation/StatusInformation').default,
		'simpleButton'              : require('@/components/Atom/Buttons/SimpleButton').default,
		'modalEagle'                : require('@/components/Atom/Modal/ModalEagle').default,
		'inputSimple'               : require('@/components/Atom/Inputs/InputSimple').default,

	},

	validations: {

		selectedEmpresas:{
			required
		},

		selectedLVE:{
			required
		},

		colaboradorSelecionadoModalConfirmar:{
			required
		},

		selectedVeiculo: {
			required
		},

	},

	data(){
		return {
			mdiCellphoneWireless:mdiCellphoneWireless,
			mdiCloseCircle:mdiCloseCircle,
			data                 : '',
			dataRelatorio        : '',
			optionsArrayEmpresas : [],
			selectedEmpresas     : [],
			selectLVE            : [],
			labelsSelectLVE: [
				{indexDFH: 'L', description: 'Linhas'},
				{indexDFH: 'V', description: 'Veículos'},
				{indexDFH: 'E', description: 'Escalas'},
			],
			radioSelectedLVE   : '',
			optionsLVE         : [],
			arrayAllOptionsLVE : [],
			selectedLVE        : [],
			htmlRelatorio      : '',
			dadosRelatorio     : [],
			tamanhoRelatorio   : 0,
			statusBar          : 'info',
			loadingPanel       : false,
			loading            : false,
			openModal          : false,
			carregando         : false,
			loadingExportar    : [false, false, false],

			// Modal confirmar linha
			dataAtual                            : '',
			optionsVeiculosModal                 : [],
			selectedVeiculo                      : '',
			optionsMotoristaModal                : [],
			colaboradorSelecionadoModalConfirmar : '',
			statusMessage                        : '',
			status                               : 'none',
			isLoading                            : false, 
			arrayDados                           : [],
			escalaColaboradorModalConfirmar      : [],
			selectedEmpresa                      : [],
		}
	},
	methods:{
        ...mapGetters(['getMaster','getCadastrar','getEditar']),

		changeInput(data){
			this.limpaRelatorio()
			this.data = data;
		},

		changeEmpresa(select){
			this.limpaRelatorio()
			this.selectedEmpresas = select;
			this.$refs.LVE.clearAll()
			this.dados_filtros();
		},

		createdLVE(select){
			this.limpaRelatorio()
			this.selectLVE = select;
		},

		changeLVE(selected){
			this.limpaRelatorio()
			this.selectedLVE = selected;
		},

		changeCheckLVE(check){
			this.limpaRelatorio()
			this.selectLVE = [];
			this.radioSelectedLVE = check;
			if(this.arrayAllOptionsLVE.length != 0){
				this.optionsLVE = this.arrayAllOptionsLVE[check];
			}
		},

		limpaRelatorio() {
			this.dadosRelatorio = [];
			this.tamanhoRelatorio = 0;
			this.statusBar = 'info'
		},

		async dados_filtros(){
			this.carregando = true
			var dados = [];
			dados = await new FiltrosService().dados_filtros(
				this.selectedEmpresas, ['L', 'V', 'E']);
			if(dados){
				this.arrayAllOptionsLVE = dados
				this.changeCheckLVE(this.radioSelectedLVE);
			}
			this.carregando = false
		},

		async gerar(){
			if(this.selectedEmpresas.length > 0 && this.data.length > 0){
				this.htmlRelatorio = '';
				this.dadosRelatorio = [];
				this.tamanhoRelatorio = 0;
				this.loadingPanel = true
				var data = await new HttpRequest().Post('/coletivos/cancelamento/gerar/cancelamentos', 
					{'veiculoOuEscalaOuLinha' : this.selectedLVE, 
						'data'                   : this.data, 
						'cliente'                : this.selectedEmpresas, 
						'tipobusca'              : this.radioSelectedLVE})
				this.tamanhoRelatorio = data.data.tamanho;
				if(this.tamanhoRelatorio > 0){
					this.dataRelatorio = this.data;
					this.dadosRelatorio = data.data.rotas
					// this.montaHtml(this.dadosRelatorio);
				}else{
					this.statusBar = 'error'
				}
				this.loadingPanel = false
			}
		},

		async exportar(type){
			var root =  process.env.VUE_APP_ROOT;
			if(this.tamanhoRelatorio > 0){
				switch(type){
					case 'pdf':
						this.loadingExportar = [true, false, false]
						break;
					case 'xls':
						this.loadingExportar = [false, true, false]
						break;
					case 'csv':
						this.loadingExportar = [false, false, true]
						break;
					default:
						break;
				}
				var data = await new HttpRequest().Post('/coletivos/cancelamento/exportar/cancelamentos', 
					{'arrayDados' : this.dadosRelatorio, 
						'tipo'       : type, 
						'data'       : this.dataRelatorio});
				window.open(root+'/'+data.data.local);
				this.loadingExportar = [false, false, false]
			}
		},

		//Modal confirmar linha
		modalConfirmarLinha(rchorarioconfirmacao, rccodigo, rccliente){
			var dataModal = DateTime.now()
			this.dataAtual = dataModal.toFormat('dd/LL/yyyy')
			this.arrayDados = []
			this.arrayDados.push({'rchorarioconfirmacao' : rchorarioconfirmacao,
				'rccodigo' : rccodigo, 'rccliente' : rccliente})
			this.$bvModal.show('modal-cancelamento-')
		},

		changeMotorista(invalido, motorista){
			this.statusMessage = ''
			this.colaboradorSelecionadoModalConfirmar = motorista;
			this.buscaEscalas(motorista)
		},

		changeVeiculo(invalido, veiculo){
			this.statusMessage = ''
			this.selectedVeiculo = veiculo;
		},

		async getMotoristasVeiculo(){
			this.carregando = true;
			this.statusMessage = '';
			this.status = '';
			var dados = await new HttpRequest().Post('/coletivos/cancelamento/get/motorista/veiculos', 
				{'rccliente': this.arrayDados[0].rccliente})
			if(dados){
				this.optionsVeiculosModal = dados.data.veiculos
				this.optionsMotoristaModal = dados.data.motoristas
			}
			this.carregando = false
		},


		async buscaEscalas(colaborador){
			this.loading = true
			var dados = await new HttpRequest().Post('/coletivos/cancelamento/busca/escalas',
				{'colaborador': colaborador}) 
			if(dados.data.escala){
				this.escalaColaboradorModalConfirmar = dados.data.escala
			}
			this.loading = false
		},

		async ajustarRota(){
			this.loading = true
			var dataModal = DateTime.now()
			var data = dataModal.toFormat('yyyy-LL-dd')
			// var data = moment(horarioConfirmacao).format('YYYY-MM-DD');
			if(this.selectedVeiculo != '' && this.colaboradorSelecionadoModalConfirmar != ''){
				var dados = await new HttpRequest().Post('/coletivos/cancelamento/ajustar/rota', 
					{'rccliente' : this.arrayDados[0].rccliente, 
						'veiculo'   : this.selectedVeiculo, 
						'motorista' : this.colaboradorSelecionadoModalConfirmar, 
						'data'      : data, 
						'rccodigo'  : this.arrayDados[0].rccodigo})
				this.statusMessage = dados.data.message;
				this.status = dados.data.status;
			}
			this.loading = false
		},

	},

	mounted() {
		this.optionsArrayEmpresas = new EmpresasService().Get()
		if(!this.getMaster()){
            this.selectedEmpresa = this.optionsArrayEmpresas
        }
	},
})
</script>

<style lang="scss">
    #cancelamentos{
        .dadosTabelaCancelamentos{
            background: #fff;
            td{
                border: 1px solid #d5d5d5;
            }
        }
    }
</style>