<template>
    <div>
        <div :id="`popupavelocidade${dadoVelocidade.bvcodigo}`">
            <div class='alerta-descricao'
                v-show="temExcessoVelocidade(dadoVelocidade.velocidade, dadoVelocidade.velocidademax)">
                <h6 style="color: red !important;"><strong>Excesso de velocidade</strong></h6>
            </div>
            <div v-if="dadoVelocidade.data">
                <strong>Data: </strong>{{ formataHoraSql(dadoVelocidade.data) }}
            </div>
            <div class="velocidadesPopup">
                <div v-if="dadoVelocidade.velocidade" class="textoVelocidade">
                    <strong v-if="temExcessoVelocidade(dadoVelocidade.velocidade, dadoVelocidade.velocidademax)">
                        Excesso:
                    </strong>
                    <strong v-else>
                        Velocidade:
                    </strong>
                    {{ dadoVelocidade.velocidade }}km/h
                </div>
                <div v-if="dadoVelocidade.velocidademax" class="textoVelocidade">
                    <strong>Permitido: </strong>{{ dadoVelocidade.velocidademax }}km/h
                </div>
            </div>
            <div v-if="dadoVelocidade.endereco">
                <strong>Em: </strong>{{ dadoVelocidade.endereco }}
            </div>
            <panelEagle :id="'id-modal-' + dadoVelocidade.bvcodigo" :loading="loadingPanelModal">
            </panelEagle>
        </div>
    </div>
</template>

<script>
import { DateTime, Duration } from 'luxon'
export default {
    components: {
        'panelEagle': require('@/components/Atom/Panel/PanelEagle.vue').default
    },
	props:{
		dadoVelocidade:{
			required:true
		}
	},
    data() {
        return {
            loadingPanelModal: false,
            excessoVelocidade: false,
        }
    },
	methods:{
		/**
		 * @param {string} data - quando o veículo parou, em formato SQL
		 * @return {string} data no formato dia/mes/ano hora:minuto
		 */
		formataHoraSql(data){
            if(data === null){
                return '';
            }
			var realizado = DateTime.fromSQL(data);
			return realizado.toFormat('dd/LL/yyyy T');
		},
/**
		 * @description valida se velocidade é maior que a velocidade máxima do veículo,
         * caso seja é considerado um excesso de velocidade
         * @return {boolean} 
		 * @author Otávio 🦆 
		*/
        temExcessoVelocidade(velocidade, velocidadeMax){
            if(velocidade && velocidadeMax){
                return this.excessoVelocidade = velocidade > velocidadeMax ? true : false;
            }else{
                return false;
            }
        }
    },
}
</script>

<style lang="scss">
.velocidadesPopup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    .textoVelocidade {
        display: flex;
    }
}
[id^='popupalertas'] {
    min-height: 100px;
    min-width: 330px;
    height: auto; 
    width: auto;
    display: flex;
    flex-direction: column; 
    .alerta-descricao{
        color: red;
    }
}
</style>