<template>
    <div id="podium">
        <div class="pl-2 pt-2" :style="{'text-align':'left'}">
            {{titleIndicador}}
        </div>
        <div class="items">
            <div class="item">
                <p class="info-top">{{dataRankCmp[3].infoTop}}</p>
                <div :style="{'background':color}" class="rank four">4º</div>
                <div class="div-info-down">
                    <div class="info-down" 
                        :title="dataRankCmp[3].infoDown">
                        {{dataRankCmp[3].infoDown}}
                    </div>
                </div>
            </div>
            <div class="item">
                <p class="info-top">{{dataRankCmp[1].infoTop}}</p>
                <div :style="{'background':color}" class="rank two">2º</div>
                <div class="div-info-down">
                    <div class="info-down" 
                        :title="dataRankCmp[1].infoDown">
                        {{dataRankCmp[1].infoDown}}
                    </div>
                </div>
            </div>
            <div class="item">
                <p class="info-top">{{dataRankCmp[0].infoTop}}</p>
                <div :style="{'background':color}" class="rank one">1º</div>
                <div class="div-info-down">
                    <div class="info-down" 
                        :title="dataRankCmp[0].infoDown">
                        {{dataRankCmp[0].infoDown}}
                    </div>
                </div>
            </div>
            <div class="item">
                <p class="info-top">{{dataRankCmp[2].infoTop}}</p>
                <div :style="{'background':color}" class="rank three">3º</div>
                <div class="div-info-down">
                    <div class="info-down" 
                        :title="dataRankCmp[2].infoDown">
                        {{dataRankCmp[2].infoDown}}
                    </div>
                </div>
            </div>
            <div class="item">
                <p class="info-top">{{dataRankCmp[4].infoTop}}</p>
                <div :style="{'background':color}" class="rank five">5º</div>
                <div class="div-info-down">
                    <div class="info-down" 
                        :title="dataRankCmp[4].infoDown">
                        {{dataRankCmp[4].infoDown}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Vue  from 'vue'
    export default Vue.extend({
        name:'Podium',
        components:{
            // 'baseIcon': require('@/components/Atom/Icon/BaseIcon.vue').default,
        },
        props:{
            titleIndicador:{
                type: String,
                required: true,
            },
            data:{
                type: [Array, Object],
                default: ()=> '',
            },
            color:{
                type: String,
                default:'gray'
            },
            footer:{
                type: String,
                default: '',
            },
            height:{
                type: [String, Number],
                default: '200',
            },
            width:{
                type: String,
                default: '500'
            },
            sizeTitle:{
                type: [String, Number],
                default: 13
            },      
        },
        data() {
            return {
                dataRank: [
                    {'infoTop':'','infoDown':''},
                    {'infoTop':'','infoDown':''},
                    {'infoTop':'','infoDown':''},
                    {'infoTop':'','infoDown':''},
                    {'infoTop':'','infoDown':''},
                ]
            }
        },
        mounted() {
            if(this.data.dados && this.data.dados.length) {
                var dados = this.data.dados
                var labels = this.data.label
                for (let i = 0; i < dados.length; i++) {
                    this.dataRank[i] = {
                        'infoTop':dados[i],
                        'infoDown':labels[i],
                    }
                }
                this.$forceUpdate()
            }
        },
        computed: {
            dataRankCmp() {
                return this.dataRank
            }
        }
    })
</script>
<style lang='scss'>
#podium {
    border: 1px solid #eee;
    .items {
        display: flex;
        align-items: flex-end;
        padding: 10px;
        .item {
            width: 100px;
            margin: 0 auto 0 auto;
            padding: 0 10px 0 10px;
            .rank {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                color: #fff;
            }
            .info-top {
                text-align: center;
                border: 2px solid grey;
                background-color: #eee;
                border-radius: 50px;
                height: 50px;
                width: 50px;
                line-height: 50px;
                margin: 5px auto 5px auto;
            }
            .div-info-down {
                background-color: #f5f5f5;
                margin: 5px 0 5px 0;
                .info-down {
                    padding: 5px;
                    display: block;
                    display: -webkit-box;
                    height: 38px;
                    line-height: 16px;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    background-color: #f5f5f5;
                    font-size: 12px;
                }
            }
            .one {
                min-height: 150px;
            }
            .two {
                min-height: 125px;
            }
            .three {
                min-height: 100px;
            }
            .four {
                min-height: 75px;
            }
            .five {
                min-height: 50px;
            }
        }
    }
}
</style>