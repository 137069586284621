/**
    Este componente é um input de data alterado para utilizar o el-date-picker

    -> Propriedades:
        1- id - String - Id do input - EX: 'inputSingleDay'
        2- name - String - Name do input - EX: 'inputSingleDay'
        3- label - String - subtitulo do input
        6- isObrigatorio - Boolean - verifica se o campo é obrigatorio, se for ele coloca o (*) - EX: true/false
        7- value - String - Passa uma data para o componente - Ex: '17/09/2021'

    -> Slot:
        - Sem slots

    -> Eventos:
        1- changeInput - Emite quando o mes é alterado - Parametros:
            1.1- dateRangeFinal - data em formato 'DD/MM/YYYY'

    -> Obs:
        1- format - Formato que o calendário mostra no input
        2- value-format - Formato que o calendario envia valores
        3- Type - Se o calendário será para selecionar periodo ou valor único
        4- clearable - Se tem a opção de apagar a data

 */

<template>
    <div class="col-sm-12" :class="extraClass">
        <div
		v-if="label == undefined"
		class="col-sm-12 col-xs-4 subTitle nopadding">
            Data
			<span
			v-if="titulo == 1">
				Início
			</span>
			<span
			v-else-if="titulo == 2">
				Fim
			</span>
			<span v-if="isObrigatorio">*</span>
        </div>
        <div v-else class="col-sm-12 subTitle nopadding">
            {{label}}
        </div>
        <div>
            <el-date-picker
                v-bind="$attrs"
                v-model='date'
                type='date'
                style='width: 100%; height: 41px;'
                format='dd/MM/yyyy'
                value-format='dd/MM/yyyy'
                :clearable='clearable'
                size='large'
                :align='opens'
                @change="changeInput"
            />
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import { DateTime } from 'luxon'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
export default Vue.extend({
	name: 'inputSingleDay',
	inheritAttrs:false,
	data(){
		return {
			date:DateTime.now().toFormat('dd/LL/yyyy'),
		}
	},
	methods: {
		changeInput(){
			this.$emit('changeInput', this.date);
		},
		reset(){
			this.date = DateTime.now().toFormat('dd/LL/yyyy')
		}
	},
	mounted(){
		// Melhorar isso watch não estava funcionando
		if(this.value !== ''){
			this.date = this.value
		}
		this.changeInput();
	},
	watch: {
		value: function(){
			if(this.value !== ''){
				this.date = this.value
			} else {
				this.date = ''
			}
		}
	},
	props:{
		id:{
			type: String,
			required: false,
			default: '',
		},
		name:{
			type: String,
			required: true,
			default: '',
		},
		label:{
			type: String,
			required: false,
			default: 'Data',
		},
		isObrigatorio:{
			type:Boolean,
			required: false,
			default: false
		},
		titulo: {
			type: Number,
			required: false,
			default: 0
		},
		value: {
			type: String,
			required: false,
			default: '',
		},
		opens:{
			type: String,
			default: 'center'
		},
		extraClass:{
			type: String,
			required: false,
			default: '',
		},
		clearable:{
			type: Boolean,
			required: false,
			default: false,
		},
	}
})
</script>
<style lang="scss">
    .subTitle{
        font-size: 13px;
        text-align: left;
        margin-bottom: 0px;
        font-family: nexabook, sans-serif !important;
    }
    .vue-daterange-picker{
        display: block !important;
    }
</style>