import { AuthService } from '../../../Services/auth/Auth.Service';
import { RouteConfig } from 'vue-router'
import Layout from '../../../components/Atom/Layout/Layout.vue'
import Relatorio from '../../Children.vue'
import NaoMenu from '../../Children.vue'
import Cadastros from '../../Children.vue'
import ListagemColaborador from '@/views/Checkpoint/Cadastros/Colaborador/ListagemColaborador.vue'
import CadastroColaborador from '@/views/Checkpoint/Cadastros/Colaborador/CadastroColaborador.vue'
import ListagemCargos from '@/views/Checkpoint/Cadastros/Cargos/ListagemCargos.vue'
import CadastroCargos from '@/views/Checkpoint/Cadastros/Cargos/CadastroCargos.vue'
import EspelhoPonto from '@/views/Checkpoint/Relatorios/EspelhoPonto.vue'
import Processamento from '@/views/Checkpoint/NaoMenus/Processamento.vue'
import Extrato from '@/views/Checkpoint/Relatorios/Extrato.vue'
import Absenteismo from '@/views/Checkpoint/Relatorios/Absenteismo.vue'
import SolicitacaoAjustes from '@/views/Checkpoint/NaoMenus/SolicitacaoAjustes.vue'
import ListagemParametros from '@/views/Checkpoint/Cadastros/Parametros/ListagemParametros.vue'
import CadastroParametros from '@/views/Checkpoint/Cadastros/Parametros/CadastroParametros.vue'
import listagemDepartamentos from '@/views/Checkpoint/Cadastros/Departamentos/ListagemDepartamentos.vue'
import CadastroDepartamentos from '@/views/Checkpoint/Cadastros/Departamentos/CadastroDepartamento.vue'
import listagemFeriados from '@/views/Checkpoint/Cadastros/Feriados/Feriados.vue'
import CadastroFeriados from '@/views/Checkpoint/Cadastros/Feriados/CadastrarFeriados.vue'
import ListagemFeriasEAfastamento from "@/views/Checkpoint/Cadastros/FeriasEAfastamento/ListagemFeriasEAfastamento.vue"
import CadastroFeriasEAfastamento from "@/views/Checkpoint/Cadastros/FeriasEAfastamento/CadastroFeriasEAfastamento.vue"
import ResumoJornada from '@/views/Checkpoint/Relatorios/ResumoJornada.vue'
import Fechamento from '@/views/Checkpoint/NaoMenus/Fechamento.vue'
import Registros from '@/views/Checkpoint/Relatorios/Registros.vue'
import CadastroUsuarios from '@/views/Checkpoint/Cadastros/Usuarios/CadastroUsuarios.vue'
import ListagemUsuarios from '@/views/Checkpoint/Cadastros/Usuarios/ListagemUsuarios.vue'
import Pontualidade from '@/views/Checkpoint/Relatorios/Pontualidade.vue'
import Inconformidades from '@/views/Checkpoint/Relatorios/Inconformidades.vue'
import AFDCheckpoint from '@/views/Checkpoint/Relatorios/AFDCheckpoint.vue'

export const ifAuthenticated = (_to: any, _from: any, next: any) => {
	if (new AuthService().isAuthenticated()) {
		next()
		return
	}
	next('/login')
}
export const isAdmCheckpoint = (_to: any, _from: any, next: any) => {
	if (new AuthService().admCheckpoint()) {
		next()
		return
	}
	next({name: 'checkpoint'})
}
export const checkpointRoutes: RouteConfig =
{
    path: '/checkpoint',
    name: 'checkpoint',
    beforeEnter: ifAuthenticated,
    meta: {
        requiresAuth: true,
        Module: "checkpoint",
        breadcrumbName: "Checkpoint"
    },
    components: {
        default: Layout,
    },
    children: [
        {
            path: 'cadastros',
            meta: {
                Module: 'checkpoint',
                breadcrumbName: 'Cadastros'
            },
            components: {
                content: Cadastros
            },
            children: [
                // /#/checkpoint/cadastros/ferias/afastamento
                {
                    path: 'ferias/afastamento',
                    name: 'ListagemFeriasEAfastamento',
                    meta: {
                        Module: 'checkpoint',
                        breadcrumbName: 'Férias e afastamento'
                    },
                    component: ListagemFeriasEAfastamento
                },
                {
                    path: 'ferias/afastamento/cadastrar/:id?',
                    name: 'CadastroFeriasEAfastamento',
                    meta: {
                        Module: 'checkpoint',
                        breadcrumbName: 'Férias e afastamento'
                    },
                    component: CadastroFeriasEAfastamento
                },
                

                ///////////////////////////////////////////////////////////

                {
                    path: 'colaboradores',
                    name: 'listagemColaborador',
                    meta: {
                        Module: 'checkpoint',
                        breadcrumbName: 'Colaboradores'
                    },
                    component: ListagemColaborador
                },
                {
                    path: 'colaboradores/cadastrar/:id?',
                    name: 'cadastrarColaboradores',
                    meta: {
                        Module: 'checkpoint',
                        breadcrumbName: 'Cadastro Colaboradores'
                    },
                    component: CadastroColaborador
                },

                //Cargos
                {
                    path: 'cargos',
                    name: 'listagemCargos',
                    meta: {
                        Module: 'checkpoint',
                        breadcrumbName: 'Cargos'
                    },
                    component: ListagemCargos
                },
                {
                    path: 'cargos/cadastrar/:id?',
                    name: 'cadastrarCargos',
                    meta: {
                        Module: 'checkpoint',
                        breadcrumbName: 'Cadastro Cargos'
                    },
                    component: CadastroCargos
                },

                //Parâmetros
                {
                    path: 'parametros',
                    name: 'listagemParametros',
                    meta: {
                        Module: 'checkpoint',
                        breadcrumbName: 'Parâmetros'
                    },
                    component: ListagemParametros
                },
                {
                    path: 'parametros/cadastrar/:id?',
                    name: 'cadastrarParametros',
                    meta: {
                        Module: 'checkpoint',
                        breadcrumbName: 'Cadastro Parâmetros'
                    },
                    component: CadastroParametros
                },

                //Departamentos
                {
                    path: 'departamentos',
                    name: 'listagemDepartamentos',
                    meta: {
                        Module: 'checkpoint',
                        breadcrumbName: 'Departamentos'
                    },
                    component: listagemDepartamentos
                },
                {
                    path: 'departamentos/cadastrar/:id?',
                    name: 'cadastrarDepartamentos',
                    meta: {
                        Module: 'checkpoint',
                        breadcrumbName: 'Cadastro Departamentos'
                    },
                    component: CadastroDepartamentos
                },

                //Feriados
                {
                    path: 'feriados',
                    name: 'listagemFeriadosCheckpoint',
                    meta: {
                        Module: 'checkpoint',
                        breadcrumbName: 'Feriados'
                    },
                    component: listagemFeriados
                },
                {
                    path: 'feriados/cadastrar/:id?',
                    name: 'cadastrarFeriadosCheckpoint',
                    meta: {
                        Module: 'checkpoint',
                        breadcrumbName: 'Cadastro Feriados'
                    },
                    component: CadastroFeriados
                },

                // Usuarios
                {
                    path: 'usuarios',
                    name: 'listagemUsuariosCP',
                    meta: {
                        Module: 'checkpoint',
                        breadcrumbName: 'Usuários'
                    },
                    component: ListagemUsuarios
                },
                {
                    path: 'usuarios/cadastrar/:id?',
                    name: 'cadastrarUsuariosCP',
                    beforeEnter: isAdmCheckpoint,
                    meta: {
                        Module: 'checkpoint',
                        breadcrumbName: 'Cadastro Usuários'
                    },
                    component: CadastroUsuarios
                },
            ]
        },
        {
            path: '',
            meta: {
                Module: "checkpoint",
                breadcrumbName: ''
            },
            components: { content: NaoMenu},
            children: [
                {
                    path: 'solicitacao/ajustes/:date?/:id?/:cliente?',
                    name: 'solicitacaoAjustesCheckpoint',
                    meta: {
                        Module: 'checkpoint',
                        breadcrumbName: 'Solicitação Ajustes'
                    },
                    component: SolicitacaoAjustes
                },

                {
                    path: 'processamento',
                    name: 'processamentoCheckpoint',
                    meta: {
                        Module: 'checkpoint',
                        breadcrumbName: 'Processamento'
                    },
                    component: Processamento 
                },

                {
                    path: 'fechamento',
                    name: 'fechamentoCheckpoint',
                    meta: {
                        Module: 'checkpoint',
                        breadcrumbName: 'Fechamento'
                    },
                    component: Fechamento
                },
            ]
        },
        {
            path: 'relatorios',
            meta:{
                Module:'checkpoint',
                breadcrumbName: 'Relatórios'
            },
            components: {
                content: Relatorio
            },
            children: [
                {
                    path: 'pontualidade',
                    name: 'pontualidadeCheckpoint',
                    meta: {
                        Module: 'checkpoint',
                        breadcrumbName: 'Relatório Pontualidade'
                    },
                    component: Pontualidade
                },
                {
                    path: 'espelho/ponto',
                    name: 'espelhoPontoCheckpoint',
                    meta: {
                        Module: 'checkpoint',
                        breadcrumbName: 'Espelho Ponto'
                    },
                    component: EspelhoPonto
                },
                {
                    path: 'absenteismo',
                    name: 'absenteismo',
                    meta: {
                        Module: 'checkpoint',
                        breadcrumbName: 'Absenteísmo'
                    },
                    component: Absenteismo
                },
				{
					path: 'extrato',
					name: 'extrato',
					meta: {
						Module: 'checkpoint',
						breadcrumbName:'Controle de Registros'
					},
					component: Extrato 
				},
                {
					path: 'resumo/jornada',
					name: 'resumoJornadaCheckpoint',
					meta: {
						Module: 'checkpoint',
						breadcrumbName:'Resumo de Jornada'
					},
					component: ResumoJornada  
                },
                {
					path: 'registros',
					name: 'relatoriosRegistrosCheckpoint',
					meta: {
						Module: 'checkpoint',
						breadcrumbName:'Relatório de Registros'
					},
					component: Registros  
                },
                {
					path: 'inconformidades',
					name: 'inconformidadesCheckpoint',
					meta: {
						Module: 'checkpoint',
						breadcrumbName:'Relatorio de Inconformidades'
					},
					component: Inconformidades  
                },
                {
					path: 'afd',
					name: 'AFDCheckpoint',
					meta: {
						Module: 'checkpoint',
						breadcrumbName:'AFD'
					},
					component: AFDCheckpoint  
                }
            ]
        },
    ]
}
