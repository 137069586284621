<template>
    <panelEagle id="ocorrenciaPonto" :loading="loadingPanel">
        <div class="col-sm-12 nopadding ">
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-6">
                    <tituloPage 
                        :icon="mdiMapMarkerAlert" 
                        titulo="Ocorrência em ponto" >
                    |</tituloPage>
                </div>
                <div class="col-sm-6 divDontPrint">
                    <basicButtonsRelatoriosEIG
                        :loading="loadingButton"
                        :disabled="$v.veiculosArray.$invalid"
                        @exportarRelatorio="exportarRelatorio" 
                        @gerarRelatorio="gerarRelatorio"
                        :disabledDropdown="!Object.keys(dadosOcorrenciaPonto).length">
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12"><hr></div>
                    <div class="col-sm-12 row nopadding mb-2">
                        <div class="col-sm-3 nopadding">
                            <inputRangeWithLimit
                                opens="right"
                                :isObrigatorio='true'
                                @changeInput="changeData" 
                                id="dataOcorrenciaPonto" 
                                name="dataOcorrenciaPonto">
                            </inputRangeWithLimit>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll
                                @changeSelect="SelectEmpresas" 
                                :isMultiple="true" 
                                nameForRadio="radiosEmpresas" 
                                :labels="labelsSelectEmpresa" 
                                :optionsArray="optionsSelectEmpresa" 
                                firstSelected="E" 
                                :hasSelectAll="true"
                                :selected="selectedEmpresa">
                            </selectAll>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll
                                :loading="loadingVeiculos"
                                @selectCriado="selectCriado" 
                                @changeSelect="SelectVeiculos" 
                                :isMultiple="true" 
                                @changeCheck="registraCheckveiculos" 
                                nameForRadio="radiosVeiculos" 
                                :labels="labelsSelectVeiculos" 
                                :optionsArray="optionsSelectVeiculos" 
                                firstSelected="V" 
                                :hasSelectAll="true">
                            </selectAll>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll
                                :loading="loadingPontos"
                                @selectCriado="selectCriadoPontos" 
                                @changeSelect="SelectPontos" 
                                :isMultiple="true" 
                                nameForRadio="radiosPontos" 
                                :labels="labelsSelectPontos" 
                                :optionsArray="optionsSelectPontos" 
                                firstSelected="P" 
                                :hasSelectAll="true">
                            </selectAll>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
        </div>
        <div class="col-sm-12"><hr></div>
        <tableRelatorio>
            <slot slot="thead">
                <br>
                <tr>
                    <th colspan="7">
                        <div class="col-sm-12 row cabecalho nopadding">
                            <div class="col-sm-2">Data</div>
                            <div class="col-sm-5">Ponto</div>
                            <div class="col-sm-1">Tipo</div>
                            <div class="col-sm-1">Entrada</div>
                            <div class="col-sm-1">Saída</div>
                            <div class="col-sm-1">Tempo</div>
                            <div class="col-sm-1">Colaborador</div>
                        </div>
                    </th>
                </tr>
            </slot>
            <slot slot="tbody">
                <template v-for="(placa, o) in dadosOcorrenciaPonto">
                    <tr :key="o"
                        v-b-toggle="(placa.placa)"
                        class="placa">
                        <td colspan="7"
                            @click="placa.clicado = !placa.clicado">
                            <span> 
                                <b-icon
                                    :id="actived"
                                    class="h5" 
                                    shift-v="-6"
                                    shift-h="0"
                                    style="font-weight: bold;"
                                    :icon="!placa.clicado ? 
                                        'chevron-down' : 'chevron-right'"/>
                                {{placa.placa}} | {{placa.prefixo}} - {{placa.ocorrencias.length+' Ocorrências'}}
                            </span>
                        </td>
                    </tr>
                    <tr :key="o+'_Body'">
                        <td colspan="7" class="nopadding">
                        <b-collapse :id="(placa.placa)"
                            visible
                            class="col-sm-12 nopadding"
                            :key="o+'_collapse'">
                            <div v-for="(dados, d) in placa.ocorrencias"
                                :key="d" 
                                class="col-sm-12 row nopadding corpo">
                                <div class="col-sm-2">
                                    {{dados.data}}
                                </div>
                                <div class="col-sm-5">
                                    {{dados.ponto}}
                                </div>
                                <div :title="dados.tipo2" class="col-sm-1">
                                    <span :class="'pontoTipo '+dados.tipo">{{dados.tipoL}}</span>
                                </div>
                                <div class="col-sm-1">
                                    {{dados.entrada}}
                                </div>
                                <div class="col-sm-1">
                                    {{dados.saida}}
                                </div>
                                <div class="col-sm-1">
                                    {{dados.tempo}}
                                </div>
                                <div class="col-sm-1">
                                    {{dados.motorista[0]}}
                                </div>
                            </div>
                        </b-collapse>
                        </td>
                    </tr>
                </template>
                <statusInformation v-show="dadosOcorrenciaPonto.length == 0" typeBar="tr" :statusBar="statusbar"></statusInformation>
            </slot>
        </tableRelatorio>
    </panelEagle>
</template>

<script>
    import Vue from 'vue'
    import {mdiMapMarkerAlert } from '@mdi/js'
    import {EmpresasService} from '@/Services/auth/Empresas.service'
    import {HttpRequest} from '@/Services/auth/HttpRequest.Service'
    import {FiltrosService} from '@/Services/filtros/filtros.Service'
    import { required } from 'vuelidate/lib/validators'
    import { mapGetters } from 'vuex'

    export default Vue.extend({
        name:'ocorenciaEmPonto',
        validations:{
            veiculosArray:{ required },
            empresasArray:{ required },
            htmlOcorrenciaPonto:{ required },
        },
        components:{
            'tituloPage' : require('@/components/Atom/Header/Titulo').default,
            'selectAll' : require('@/components/Atom/Select/SelectAll').default,
            'panelEagle' : require('@/components/Atom/Panel/PanelEagle').default,
            'tableRelatorio' : require('@/components/Atom/Table/TableRelatorio').default,
            'slideUpAndDown' : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
            'inputRangeWithLimit' : require('@/components/Atom/Datas/InputRangeWithLimit').default,
            'statusInformation' : require('@/components/Atom/StatusInformation/StatusInformation').default,
            'basicButtonsRelatoriosEIG' : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
        },
        data(){
            return{
                mdiMapMarkerAlert:mdiMapMarkerAlert,
                loadingButton:[false, false, false],
                loadingPanel:false,
                loadingPontos:false,
                loadingVeiculos:false,
                labelsSelectEmpresa:[
                    {indexDFH: 'E', description: 'Empresas*'},
                ],
                optionsSelectEmpresa: new EmpresasService().Get(),
                labelsSelectVeiculos:[
                    {indexDFH: 'V', description: 'Veiculos*'},
                    {indexDFH: 'GV', description: 'Grupos*'},

                ],
                labelsSelectPontos:[
                    {indexDFH: 'P', description: 'Pontos'},

                ],
                statusbar: 'info',
                VeiculosTipo:'V',
                PontosTipo:'P',
                optionsSelectVeiculos: [],
                optionsSelectPontos: [],
                empresasArray:[],
                veiculosArray:[],
                PontosArray:[],
                veiculosSelect: [],
                PontosSelect: [],
                dadosOcorrenciaPonto:[],
                dataSelecionada:'',
                tamanhoArrayOcorrenciaPonto: 0,
                htmlOcorrenciaPonto:'',
                actived: false,
                selectedEmpresa: [],
            }
        },
        methods:{
            ...mapGetters(['getMaster']),

            SelectEmpresas(event){
                this.empresasArray = event;
                this.veiculosSelect.clearAll();
                this.PontosSelect.clearAll();
                this.dadosOcorrenciaPonto = [];
                this.dados_filtros();
            },
            SelectVeiculos(event){
                this.dadosOcorrenciaPonto = []
                this.veiculosArray = event;
            },
            selectCriado(select){
                this.veiculosSelect = select;
            },
            selectCriadoPontos(select){
                this.PontosSelect = select;
            },
            SelectPontos(event){
                this.dadosOcorrenciaPonto = []
                this.PontosArray = event;
            },
            changeData(event){
                this.dadosOcorrenciaPonto = []
                this.dataSelecionada = event;
            },
            registraCheckveiculos(check, needRecharge){
                this.VeiculosTipo = check;
                if(needRecharge){
                    this.dados_filtros();
                }
            },
            async dados_filtros(){
                this.loadingPontos   = true
                this.loadingVeiculos = true
                var dados = [];
                dados = await new FiltrosService()
                    .dados_filtros(this.empresasArray, [this.VeiculosTipo, this.PontosTipo])
                this.optionsSelectVeiculos = dados[this.VeiculosTipo];
                this.optionsSelectPontos = dados[this.PontosTipo];
                this.loadingVeiculos = false
                this.loadingPontos   = false
            },
            async gerarRelatorio(){
                this.loadingPanel = true
                if(this.empresasArray.length > 0 && this.veiculosArray.length > 0 && this.dataSelecionada != ''){
                    this.htmlOcorrenciaPonto = '';
                    this.dadosOcorrenciaPonto = [];
                    var tipoBusca = this.VeiculosTipo;
                    var data = await new HttpRequest()
                        .Post('/telemetria/relatorios/ocorrencia/pontos/gerar', 
                            {
                                'cliente': this.empresasArray, 
                                'veiculos':this.veiculosArray, 
                                'data': this.dataSelecionada, 
                                'tipo': tipoBusca, 
                                'pontos':this.PontosArray
                            }).catch(() =>
                                conectionError()
                            ).finally(() =>{
                                this.loadingPanel = false
                            });
                    var dados = data.data;
                    this.tamanhoArrayOcorrenciaPonto = dados.tamanho
                    if(dados.tamanho > 0){
                        this.statusbar = 'info'
                        this.dadosOcorrenciaPonto = dados.ocorrenciaemponto
                        this.htmlOcorrenciaPonto = '';
                    }else{
                        this.htmlOcorrenciaPonto = '';
                        this.dadosOcorrenciaPonto = [];
                        this.statusbar = 'error'
                    }
                    // this.loadingPanel = false
                }
            },

            async exportarRelatorio(tipo){
                switch (tipo) {
                    case 'pdf':
                        this.loadingButton = [true, false, false]
                        break;
                    case 'xls':
                        this.loadingButton = [false, true, false]
                        break;
                    default:
                        this.loadingButton = [false, false, true]
                        break;
                }
                var root =  process.env.VUE_APP_ROOT;
                if(this.tamanhoArrayOcorrenciaPonto > 0){
                    var data = await new HttpRequest()
                        .Post('/telemetria/relatorios/ocorrencia/pontos/exportar', 
                            {
                                'arrayDados':JSON.stringify(this.dadosOcorrenciaPonto),
                                'tipo':tipo,
                                'data': this.dataSelecionada
                            });
                    window.open(root+'/'+data.data.local);
                }
                this.loadingButton = [false, false, false]
            }
        },

        mounted() {
            if(!this.getMaster()){
                this.selectedEmpresa = this.optionsSelectEmpresa
            }
        }
    })
</script>

<style lang="scss">
    #ocorrenciaPonto{
        .cabecalho{
            // position: sticky !important;
            // top:-1px !important;
            text-align: left;
            background: #f5f5f5;
            div{
                padding: 3px;
            }
        }
        .corpo{
            background: #f3f3f3;
            border-bottom: 1px solid #e5e5e5;
            div{
                padding: 3px;
                vertical-align: center;
            }
        }
        .placa{
            background: #fff;
            td{
                padding-left: 0px;
                padding-top: 15px;
                span{
                    display: block;
                    width: 30%;
                    height: 30px;
                    background-color: #777;
                    padding-left: auto;
                    padding-right: auto;
                    color: white;
                    font-size: 15px !important;
                    padding-left: 5px;
                }
            }
        }
        .blueIconLocalizar{
            color: #428bca;
            cursor: pointer;
        }
        .pontoTipo {
            background: white;
            padding: 3px 4px 0px 5px;
            border-radius: 50px;
            font-size: 10px;
            font-weight: 800;
            margin-top: 5px;
            &.pontoEntrega{
                border: 2px solid #7cad63;
            }
            &.pontoColeta{
                border: 2px solid #e82c10;
            }
            &.pontoReferencia{
                border: 2px solid #348bb1;
            }
        }
    }
</style>