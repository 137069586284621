<template>
    <panelEagle id="cadGrupoMotoristaView" :loading="loadingPanel">
        <div class="col-sm-12 nopadding divDontPrint">
            <div class="col-sm-12 nopadding row">
                <div class="col-lg-6 col-md-12">
                    <tituloPage 
                        :icon="mdiAccountGroup" 
                        titulo="Grupo de colaboradores">
                    </tituloPage>
                </div>
                <div class="col-lg-6 col-md-12">
                    <basicButtonsCadastrosSC
                        :disabled="$v.$invalid"
                        :isLoading="true"
                        tipoCancela="button"
                        linkCancel="listarGrupoMotorista" 
                        @salvarCadastro="salvarGrupoMotoristas">
                    </basicButtonsCadastrosSC>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-4 nopadding">
                    <inputSimple
                        :inputClass="{'border border-danger':$v.descricaoGrupoColaboradores.$anyError}"
                        @blur="$v.descricaoGrupoColaboradores.$touch()"
                        @changeInput="changeDescricao" 
                        :value="descricaoSelected" 
                        name="descricaoCadastroGrupoColaborador" 
                        id="descricaoCadastroGrupoColaborador" 
                        label="Descrição*">
                    </inputSimple>
                </div>
                <div class="col-sm-4 nopadding">
                    <selectAll
                        :extraClass="{'border border-danger':$v.empresaSelecionadas.$anyError}"
                        @close="$v.empresaSelecionadas.$touch()"
                        @changeSelect="changeEmpresa" 
                        :selected="empresaSelected" 
                        :isMultiple="false" 
                        nameForRadio="empresasCadastroGrupoColaboradores" 
                        :hasSelectAll="false" 
                        :labels="[{indexDFH: 'EM', description: 'Empresa*'}]" 
                        :optionsArray="optionsArrayEmpresas" 
                        firstSelected="EM">
                    </selectAll>
                </div>
            </div>
        </div>
        <div class="col-sm-12 pl-0">
            <requiredFields></requiredFields>
        </div>
        <div class="col-sm-12"><hr></div>
    </panelEagle>
</template>

<script>
import Vue from 'vue'
import {EmpresasService} from '@/Services/auth/Empresas.service'
import {HttpRequest} from '@/Services/auth/HttpRequest.Service'
import { required } from 'vuelidate/lib/validators'
import {mdiAccountGroup } from '@mdi/js'
export default Vue.extend({
	name:'CadastroGrupoColaboradores',
	components:{
		'tituloPage':              require('@/components/Atom/Header/Titulo').default,
		'selectAll':               require('@/components/Atom/Select/SelectAll').default,
		'panelEagle':              require('@/components/Atom/Panel/PanelEagle').default,
		'inputSimple':             require('@/components/Atom/Inputs/InputSimple').default,
		// 'alertSimple':             require('@/components/Atom/Alerts/AlertSimple').default,
		'requiredFields':          require('@/components/Atom/Footer/RequiredFields').default,
		'basicButtonsCadastrosSC': require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
	},
	validations:{
		empresaSelecionadas:{ required },
		descricaoGrupoColaboradores:{ required },
	},
	data(){
		return {
			mdiAccountGroup:mdiAccountGroup,
			loadingPanel:false,
			statusAlert: 'blue',
			messageAlert: '',
			isLoading: false,
			empresaSelecionadas: [],
			optionsArrayEmpresas : new EmpresasService().Get(),
			descricaoGrupoColaboradores: '',
			returnStatus: '',
			returnClass: '',
			status: 'T',
			gmcodigo: '',
			empresaSelected: [],
			descricaoSelected: '',
		}
	},
	methods:{
		changeEmpresa(selecionados){
			this.empresaSelecionadas = selecionados;
		},
		changeDescricao(descricao){
			this.descricaoGrupoColaboradores = descricao;
		},
		async salvarGrupoMotoristas(){
			if(this.empresaSelecionadas !== '' && this.descricaoGrupoColaboradores !== ''){
				this.loadingPanel = true
				this.isLoading= true;
				this.statusAlert = 'blue';
				this.messageAlert = 'Carregando...';
				var param = {
					'codigo': this.gmcodigo,
					'empresa': this.empresaSelecionadas,
					'gmdescricao': this.descricaoGrupoColaboradores,
					'gmstatus': 'A',
				}
				var data = await new HttpRequest()
					.Post('/telemetria/cadastros/grupos/motoristas/salvar/informacoes/grupo/colaboradores',
						param
					);
				this.statusAlert = data.data.status;
				this.messageAlert = data.data.message;
				this.isLoading = false;
				var cadGrupoColaborador = this;
				this.loadingPanel = false
				this.$router.push({name:'listarGrupoMotorista'});
			}
		},
		async getInformationGrupoColaboradores(grupoColaborador){
			var data = await new HttpRequest()
				.Post('/telemetria/cadastros/grupos/motoristas/pegar/informacoes/grupo/colaboradores', 
					{
						'grupoColaborador': grupoColaborador
					}
				);
			this.gmcodigo = data.data.grupo.gmcodigo;
			this.descricaoSelected = data.data.grupo.gmdescricao;
			this.empresaSelected = data.data.cliente;
		}
	},

	mounted(){
		if(this.$route.params.id){
			this.getInformationGrupoColaboradores(this.$route.params.id)
		}
	}
})
</script>

<style lang="scss">
    #cadGrupoMotoristaView{
        .result-status{
            text-align: center;
            font-size: 12px;
            padding-top: 5px !important;

            &.status-succes{
                color: #95b65c;
            }
            &.status-error{
                color: #e04b4a;
            }
        }
    }
</style>