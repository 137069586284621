/**
    Componente do rota manual para mostrar informações de um veículo e pedido (item rota)

    -> Propriedades:
        1- dados - Object - Objeto com informações de item rota e veículo
        2- isOpen - Boolean - controle para mostrar ou esconder os detalhes

    -> Slot:
        Sem slot

    -> Eventos:
        1- click - Emite quando clica na div dos detalhes - Sem parâmetro
 */
<template>
	<div class="cardVeiculo col-sm-12 row nopadding 
			borda-tab text-left p-2">
		<div class="col-sm-12 placa-prefixo hh row" @click="openClose">
			<div class="flecha p-0">
				<b-icon class="h5 mb-0" :icon="active ? 'chevron-down' : 'chevron-right'" />
			</div>
			<div class="placa p-0 d-flex">
				{{ dados.veplaca }}
				| {{ dados.veprefixo }}
			</div>
			<div class="entregas row p-0">
				Entregas:
				<div :class="pedidosAcima">{{ totalPedidos }}</div>
				<div class="nroEntregas">{{ totalEntregas }}</div>
			</div>
			<div class="custo row p-0 d-flex">
				Custo da rota:
				<div class="nroEntregas">&nbsp;{{ defineTextoCustoDaRota() }}</div>
			</div>
		</div>
		<SlideUpDown :key="'slide_veiculo_' + dados.veplaca" class="col-sm-12 divAccordion row" :active="active"
			:use-hidden="true" :duration="500">
			<div class="row nopadding cursor-pointer gr-kg-cub" @click="carregar">
				<div class="row gr-cub">
					<div class="info-cub">
						<div class="col-sm-12 nopadding border-bottom">
							Capacidade: <b>(m³)</b>
						</div>
						<div class="row col-sm-12 p-0 pt-1">
							<b>Disponível:&nbsp;</b>
							<div>{{ defineTextoDisponivelM3 }}</div>
						</div>
					</div>
					<div class="progressCircle">
						<ProgressCircle :value="progressoCubagem">
						</ProgressCircle>
					</div>
				</div>
				<div class="row gr-kg">
					<div class="info-kg">
						<div class="col-sm-12 nopadding border-bottom">
							Capacidade <b>(Kg)</b>
						</div>
						<div class="row col-sm-12 p-0 pt-1">
							<b>Disponível:&nbsp;</b>
							<div>{{ defineTextoDisponivelKg }}</div>
						</div>
					</div>
					<div class="progressCircle">
						<ProgressCircle :value="progressoKg">
						</ProgressCircle>
					</div>
				</div>
			</div>
		</SlideUpDown>
	</div>
</template>

<script>

import SlideUpDown from 'vue-slide-up-down'

export default {
	name: 'CardVeiculo',
	components: {
		SlideUpDown,
		ProgressCircle: require(
			'@/components/Atom/SpecificComponents/RotaManual/ProgressCircle').default,
	},
	props: {
		dados: {
			type: Object,
			required: true
		},
		isOpen: {
			type: Boolean,
			required: false,
			default: true
		},
	},

	data() {
		return {
			active: this.isOpen,
			layout: {
				height: 45,
				width: 45,
				verticalTextAlign: 36,
				horizontalTextAlign: 10,
				zeroOffset: 5,
				strokeWidth: 12,
				progressPadding: 0,
				type: 'circle'
			},
			text: {
				color: 'black',
				shadowEnable: false,
				shadowColor: '#000000',
				fontSize: 12,
				fontFamily: 'nexabook',
				dynamicPosition: false,
				hideText: false
			},
		}
	},

	methods: {
		/**
		 * @description Emite evento de click
		 */
		carregar() {
			this.$emit('click')
		},

		/**
		 * @description Garante que o valor de retorno será do tipo number
		 * @return {number}
		 */
		toNumberInt(value) {
			if (value > 0) {
				let str = Number(value).toFixed(0)
				return Number(str)
			}
			return 0
		},

		/**
		 * @description Mostra/esconde os detalhes do veículo
		 */
		openClose() {
			this.active = !this.active;
		},

		/**
 * @description Retorna texto formatado com a informação do custo da rota
 * @return {string}
 */
		defineTextoCustoDaRota() {
			if (!this.dados.vecusto || !this.dados.totaldistancia) {
				return '';
			}
			let atual = parseFloat(this.dados.vecusto);
			let total = this.dados.totaldistancia / 1000;

			if (!atual || !total) {
				return '';
			}
			let custo = parseFloat(atual * total).toFixed(2)
			return `R$ ${custo}`
		},
	},

	computed: {
		/**
		 * @description Retorna o número do progresso/carga de cubagem do veículo
		 * @return {number}
		 */
		progressoCubagem() {
			return this.toNumberInt(this.dados.progressocub)
		},

		/**
		 * @description Retorna o número do progresso/carga de peso Kg do veículo
		 * @return {number}
		 */
		progressoKg() {
			return this.toNumberInt(this.dados.progresso)
		},

		/**
		 * @description Retorna o número total de pedidos selecionados
		 * @return {number}
		 */
		totalPedidos() {
			return this.toNumberInt(this.dados.totalpedidos)
		},

		/**
		 * @description Retorna o número máximo de entregas do veículo
		 * @return {number}
		 */
		totalEntregas() {
			return this.toNumberInt(this.dados.vemaxentregas)
		},

		/**
		 * @description Retorna texto formatado com a informação de disponibilidade 
		 * de cubagem do veículo - atual/máximo cubagem
		 * @return {string}
		 */
		defineTextoDisponivelM3() {
			let atual = parseFloat(Number(this.dados.disponivelcub).toFixed(2))
			let total = parseFloat(Number(this.dados.vecubagem).toFixed(2))
			return `${atual}/${total}m³`
		},

		/**
		 * @description Retorna texto formatado com a informação de disponibilidade 
		 * de peso do veículo - atual/máximo peso Kg
		 * @return {string}
		 */
		defineTextoDisponivelKg() {
			let atual = parseFloat(Number(this.dados.disponivel).toFixed(2))
			let total = parseFloat(Number(this.dados.vemaxpeso).toFixed(2))
			return `${atual}/${total}Kg`
		},

		/**
		 * @description Retorna classes da div que mostra o total de pedidos selecionados
		 * @return {string}
		 */
		pedidosAcima() {
			let classes = 'nroEntregas ml-1'
			if (this.totalPedidos > this.totalEntregas) {
				classes += ' pedidosAcima'
			}
			return classes
		},
	}
}
</script>

<style lang="scss">
.cardVeiculo {
	.placa-prefixo {
		padding: 10px 5px 5px 5px;
		border-bottom: 1px solid #ccc;
		border-right: 1px solid #ccc;
		background-color: #ddd;

		.flecha {
			width: 5%;
		}

		.placa {
			width: 30%;
		}

		.custo {
			width: 40%;
		}

		.entregas {
			width: 25%;
			// justify-content: center;

			// padding: 2px 5px 0px 5px;
			// align-content: center;
			// border-bottom: 1px solid #ccc;
			.nroEntregas {
				border: 1px solid;
				padding: 2px 2px;
				height: 18px;
				margin-top: -2px;
			}

			.pedidosAcima {
				color: red;
				font-weight: bold;
			}
		}
	}

	.borda-tab {
		border: 1px solid #428bca;
		border-top: none;
	}

	.divAccordion {
		padding: 0;
		border: 1px solid lightgrey;

		&:hover {
			border: 1px solid cornflowerblue;
			background-color: rgba(66, 139, 202, 0.2);
		}

		.gr-kg-cub {
			width: 100%;
			justify-content: space-between;

			.gr-kg {
				width: 50%;
				justify-content: space-between;
				padding: 0.5rem 0.3rem 0.5rem 0.3rem !important;

				.info-kg {
					* {
						font-size: 12px !important;
					}

					margin: auto;
					min-width: 160px;
				}
			}

			.gr-cub {
				width: 50%;
				justify-content: space-between;
				padding: 0.5rem 0.3rem 0.5rem 0.3rem !important;

				.info-cub {
					* {
						font-size: 12px !important;
					}

					margin: auto;
					min-width: 160px;
				}
			}

			.progressCircle {
				min-width: 45px;
				margin: auto;
			}
		}
	}
}
</style>