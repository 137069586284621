<!-- 
    Este componente é o marker do mapa

    -> Propriedades:
        1- latitude - Number - latitude do marcador - EX: -27.1001389
        2- longitude - Number - longitude do marcador - EX: -52.6270369
        3- icon - String - a rota para a imagem a ser colocada no marker - EX: 'img/markerPonto.png'
        4- isDraggable - Boolean - Se o ponto vai se draggable ou não - EX: true/false
        5- direction - Number - direção que é para estar virado o marker - EX: 0 a 8
        6- typeIcon - String - se o icone é para ser icon ou divIcon - EX: 'icon'/'divIcon'

    -> Slots:
        1 - Sem nome

    -> Eventos:
        1- clickMarker - Emitido quando o marcador é clicado - Parametros:
            1.1- events - Dados do evento
            1.2- this - o proprio componente
-->

<template>
    <v-rotated-marker 
    v-on='$listeners' 
    :lat-lng='coordinates' 
    :icon='iconMarker' 
    @click='clickMarker' 
    :draggable='isDraggable' 
    :rotationAngle='rotation' 
    rotationOrigin='center'>
        <slot></slot>
    </v-rotated-marker>
</template>

<script>
import Vue from 'vue'
import L from 'leaflet';
import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker'
export default Vue.extend({
    components:{
        'v-rotated-marker': Vue2LeafletRotatedMarker
    },
    inheritAttrs:false,
    props:{
        latitude:{
            type: [Number, String],
            default: -27.1001389
        },
        longitude:{
            type: [Number, String],
            default: -52.6270369
        },
        icon:{
            type: String,
            default: 'img/markerPonto.png'
        },
        isDraggable:{
            type: Boolean,
            default: false,
        },
        direction:{
            type: Number,
            default: 0
        },
        typeIcon:{
            type: String,
            default: 'icon'
        },
        iconSize:{
            type: Array,
            default: ()=>{return [44, 48]}
        },
    },
    data(){
        var iconMarker
        if(this.typeIcon == 'icon'){
            iconMarker = L.icon({
                iconUrl:      this.icon,
                iconSize:     this.iconSize,
                iconAnchor:   [20, 38], // point of the icon which will correspond to marker's location
                shadowAnchor: [0, 0],  // the same for the shadow
            })
        }else if(this.typeIcon == 'divIcon'){
            iconMarker = L.divIcon({
                className:    'divIcon',
                html:         this.icon,
                // iconSize:     this.iconSize,
                iconAnchor:   [20, 38], // point of the icon which will correspond to marker's location
                shadowAnchor: [0, 0],// point from which the popup should open relative to the iconAnchor
            });
        }
        var rotation = 0;
        rotation = this.direction * 45;
        return{
            coordinates: L.latLng(Number(this.latitude),Number(this.longitude)),
            iconMarker,
            rotation,
        }
    },
    methods:{
        clickMarker(events){
            this.$emit('clickMarker', events, this);
        }
    },
    watch:{
        direction(){
            this.rotation = this.direction * 45;
        },
        icon(){
            var newIcon
            if(this.typeIcon == 'icon'){
                newIcon = L.icon({
                    iconUrl: this.icon,
                    iconSize: [38, 40],
                    iconAnchor:   [20, 38], // point of the icon which will correspond to marker's location
                    shadowAnchor: [0, 0],  // the same for the shadow
                })
            }else if(this.typeIcon == 'divIcon'){
                newIcon = L.divIcon({
                    className: "divIcon",
                    html: this.icon,
                    iconSize: this.iconSize,
                    iconAnchor:   [20, 38], // point of the icon which will correspond to marker's location
                    shadowAnchor: [0, 0],// point from which the popup should open relative to the iconAnchor
                });
            }
            this.iconMarker = newIcon
        },
        latitude(){
            this.coordinates = L.latLng(Number(this.latitude),Number(this.longitude))
        },
        longitude(){
            this.coordinates = L.latLng(Number(this.latitude),Number(this.longitude))
        },
    },
})
</script>

<style lang="sass" scoped>

.marker-pin 
    width: 25px
    height: 25px
    border-radius: 50% 50% 50% 0
    background: #800080
    position: absolute
    transform: rotate(-45deg)
    left: 50%
    top: 50%
    margin: -15px 0 0 -15px
.marker-pin::after 
    margin: 3px 0 0 3px
    background: #fff
    position: absolute
    margin: 4px 4px 4px 4px
    right: 0
    margin: 10px auto
    text-align: center
.centroMarker
    border-radius: 400px
    font-weight: bolder
    background: white
    margin: 4px 4px 4px 4px
.marker-circle 
    width: 30px
    height: 30px
    border-radius: 50%
    background: red
    position: absolute
.marker-circle::after 
    background: #fff
    margin: 4px 4px 4px 4px
    right: 0
    margin: 10px auto
    text-align: center
.centroMarkerGrande
    border-radius: 50%
    background: white
    margin: 6px 6px 6px 6px
</style>
