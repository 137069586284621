/** 
  Este é um compoenete de gráfico pizza/pie e donut para dashboard

  Propriedades:
    1  -> label - Descrição da legenda - Ex: ['Classe A', 'Classe B']
    2  -> value - Valores inseridos no gráfico correspondente ao label 
            - Ex: [25, 25]
    3  -> widthGrafico - Tamanho do gráfico - Ex: 600
    4  -> heightLegenda - Comprimento da legenda - Ex: 400
    5  -> offsetYLegenda - Posicionamento da legenda - Ex: 0
    6  -> widthLegenda - Comprimento da legenda - Ex: 300
    7  -> offsetXLegenda - Posicionamento da legenda em X - Ex: 0
    8  -> textTitle - Texto de título - Ex: 'Título'
    9  -> alignTitle - Onde posicionar o title - Ex: 'left'/'center'/'right'
    10 -> offsetXTitle - Posicionamento do título em X - Ex: -322
    11 -> offsetYTitle - Posicionamento do título em Y - Ex: -200
    12 -> textSubtitle - Texto do subtítulo - Ex: 'Subtítulo'
    13 -> offsetXSubtitle - Posicionamento do subtítulo em X - Ex: -322
    14 -> offsetYSubtitle - Posicionamento do subtítulo em Y - Ex: -100
    15 -> offsetXSubtitleResponsive - Posicionamento do subtítulo quando tiver 
        breakpoint - Ex:'25'
    16 -> typeChart - Qual o tipo do gráfico - Ex:'pie/donut'
    17 -> backgound - Qual a cor de fundo - Ex:'gray'
    18 -> offsetYChart - Posicionamento do gráfico em Y - Ex:'20'
    19 -> scale - Escala do gráfico - Ex: 1.3
    20 -> sizeDonut - Tamanho do donut - Ex:'65%'

  documetação
    https://apexcharts.com/docs/installation/
    https://apexcharts.com/docs/series/
    https://apexcharts.com/docs/options/series/
    https://apexcharts.com/docs/chart-types/pie-donut/
*/
<template>
    <div :class="'col-sm-12 row nopadding background-'+backgound">
        <div id="chartDashboard" class="col-sm-12 nopadding">
            <apexchart 
                ref="chart" 
                :width="widthGrafico" 
                :options="options" 
                :series="series"
                style="min-height: 400px;">
            </apexchart>
        </div>
    </div>
</template>
<script>
export default {
	name:'chartsPizzaDashboard',
	data() {
		return {
			series: this.value,
			options : {
				// responsive: [{
				//     enabled: true,
				//     breakpoint: 1323,
				//     options: {
				//         legend: {
				//             offsetX: 120,
				//             position: 'bottom',
				//             height: 50,
				//         },
				//         title: {
				//             offsetY: 0,
				//             offsetX: -200,
				//         },
				//         subtitle: {
				//             offsetY: 0,
				//             offsetX: 20,
				//         }
				//     }
				// }],
				chart: {
					type: this.typeChart,
					offsetY: 0,
					offsetX: 0,
					animations: {
						enabled: true,
						easing: 'easeinout',
						speed: 600,
					},
					fontFamily: 'nexabook, sans-serif',
				},

				colors:['#a9f38c', '#79b0ee', '#f8e37a', '#f0abab'],

				labels : this.label,

				legend: {
					position: 'right',
					offsetY: this.offsetYLegenda,
					offsetX: this.offsetXLegenda,
					height: this.heightLegenda,
					width: this.widthLegenda,
				},

				title: {
					text: this.textTitle,
					align: this.alignTitle,
					margin: 10,
					offsetX: this.offsetXTitle,
					offsetY: this.offsetYTitle,
					floating: false,
					style: {
						fontWeight:  'bold',
						fontFamily:  'nexabook, sans-serif',
						color:  '#263238'
					},
				},

				subtitle: {
					text: this.textSubtitle,
					align: 'right',
					margin: 10,
					offsetX: this.offsetXSubtitle,
					offsetY: this.offsetYSubtitle,
					floating: false,
					style: {
						fontWeight:  'bold',
						fontFamily:  'nexabook, sans-serif',
						color:  '#000'
					},
				}
			},

			// Gradiente que a fer não gostou
			// fill: {
			//     type: 'gradient',
			//     gradient: {
			//         shade: 'dark',
			//         type: "horizontal",
			//         shadeIntensity: 1,
			//         gradientToColors: undefined, // optional, if not
			//           defined - uses the shades of same color in series
			//         inverseColors: true,
			//         opacityFrom: 0.9,
			//         opacityTo: 0.9,
			//         stops: [0, 100, 0],
			//     }
			// }
		}
	},

	props:{
		label:{
			type: Array,
			defalt: [],
		},

		value:{
			type: Array,
			required: true,
		},

		widthGrafico:{
			type: Number,
			required: true,
		},
			
		heightLegenda:{
			type: Number,
			default: 100
		},

		offsetYLegenda:{
			type: Number,
			default: 0,
		},

		offsetXLegenda:{
			type: Number,
			default: 0,
		},

		widthLegenda:{
			type: Number,
			default: 210,
		},

		textTitle:{
			type: String,
			default: '',
		},

		alignTitle:{
			type: String,
			default: 'center',
		},

		offsetXTitle:{
			type: Number,
			default: 0,
		},

		offsetYTitle:{
			type: Number,
			default: 0,
		},

		textSubtitle:{
			type: [String, Number],
			defalt:'',
		},

		offsetXSubtitle:{
			type: Number,
			defalt: 0,
		},

		offsetYSubtitle:{
			type: Number,
			defalt: 0,
		},

		offsetXSubtitleResponsive:{
			type: Number,
			defalt: 0,
		},

		typeChart:{
			type: String,
			default: 'pie'
		},

		backgound:{
			type: String,
			default: 'gray',
		},

		offsetYChart:{
			type: Number,
			default: -8,
		},
		scale:{
			type: Number,
			default: 1,
		},
		sizeDonut:{
			type: String,
			default: '50%'
		},
		offsetXChart:{
			type: Number,
			default: 0,
		},
		heightGrafico:{
			type: Number,
			default: 200,
		},
		breakpointChart:{
			type: Number,
			default: 1323,
		}

	},

	methods:{
		/**
		 * @description Método para atualizar o gráfico,
		 *      cria o gráfico novamente toda vez que for passado um valor
		 *      novo para ele, pegando o gráfico pela referência e 
		 *      utilizando o refresh do apexchart é atualizado
		 * @author Vitor Hugo 🐨
		*/
		atualizaGrafico(){
			this.series = this.value
			this.options = {
				responsive: [{
					enabled: true,
					breakpoint: this.breakpointChart,
					options: {
						legend: {
							show: false,
						},
						chart: {
							type: this.typeChart,
							offsetX: -10,
							offsetY: -10,
							fontFamily: 'nexabook, sans-serif',
							animations: {
								enabled: true,
								easing: 'easeinout',
								speed: 600,
							},
						},
					}
				}],

				chart: {
					type: this.typeChart,
					offsetY: this.offsetYChart,
					offsetX: this.offsetXChart,
					width: this.widthGrafico,
					height: this.heightGrafico,
					animations: {
						enabled: true,
						easing: 'easeinout',
						speed: 600,
					},
					fontFamily: 'nexabook, sans-serif',
				},

				dataLabels: {
					enabled: true,
					offsetX: 200,
					offsetY: -100,
					textAnchor: 'start',
					style: {
						fontSize: '10px',
						fontWeight: '100',
						fontFamily: 'nexabook, sans-serif',
						colors: ['#000'],
					},
				},

				plotOptions: {
					pie: {
						customScale: this.scale,
						donut: {
							size: this.sizeDonut,

							// labels: {
							//     show: true,
							//     name: {
							//         show: true
							//     },
							//     value: {
							//         show: true
							//     }
							// }
						}
					}
				},

				labels : this.label,
				
				legend: {
					position: 'right',
					offsetY: this.offsetYLegenda,
					offsetX: this.offsetXLegenda,
					height: this.heightLegenda,
					width: this.widthLegenda,
				},

				title: {
					text: this.textTitle,
					align: this.alignTitle,
					margin: 10,
					offsetX: this.offsetXTitle,
					offsetY: this.offsetYTitle,
					floating: false,
					style: {
						fontWeight:  'bold',
						fontFamily:  'nexabook, sans-serif',
						color:  '#000'
					},
				},

				subtitle: {
					text: this.textSubtitle,
					align: 'right',
					margin: 10,
					offsetX: this.offsetXSubtitle,
					offsetY: this.offsetYSubtitle,
					floating: false,
					style: {
						fontWeight:  'bold',
						fontFamily:  'nexabook, sans-serif',
						color:  '#263238'
					},
				}
			}
			if(this.$refs.hasOwnProperty('chart')){
				this.$refs.chart.refresh()
			}
		},
	},

	mounted(){
		this.atualizaGrafico()
	},

	watch:{
		value(){
			this.atualizaGrafico()
		}
	}
};
</script>
<style lang="scss">
#chartDashboard{
    max-width: 600px;
    .apexcharts-canvas{
        width: 100% !important;
        .apexcharts-svg{
            width: 100% !important; 
            foreignObject{
                .apexcharts-legend{
                    padding-right: 0px;
                    padding-left: 0px;
                    .apexcharts-legend-series{
                        .apexcharts-legend-text{
                            font-size: 10px !important;
        
                        }
                    }
                    .apexcharts-legend-marker{
                        border-radius: 0px !important;
                    }
                }
            }  
            .apexcharts-title-text{
                width: 100% !important;
                font-size: 14px !important;
            }
        }
    }
    .background-gray{
        background: #f8f8f8;
    }

    // tamanho porcentagem
    .apexcharts-pie-label, .apexcharts-datalabels{
        font-size: 10px !important;
    }
}
</style>