<template>
  <panelEagle id='resumoHorimetro' :loading="loadingPanelEagle">
    <div class="col-sm-12 row  nopadding">
      <div id='titulo_botoes' class="col-sm-12 row nopadding">
          <div class="col-sm-6 ">
              <titulo titulo='Resumo Horímetro' :icon='mdiProgressClock'/>
          </div>
          <div class="col-sm-6 divDontPrint">
              <botoesEIG
              :loading           ='loadingExportar'
              :disabled          ="$v.veiculosSelecionados.$invalid"
              @gerarRelatorio    ='gerarRelatorio'
              @exportarRelatorio ='exportarRelatorio'
              :disabledDropdown  ='$v.relatorio.$invalid'
              />
          </div>
      </div>
      <slideUpAndDown>
          <template #filters>
              <div class="col-sm-12 row nopadding">
              <div class="col-sm-12"><hr></div>
                  <div class="col-sm-4 nopadding">
                    <!-- Input para seleconar o mês -->
                      <inputMonth
                          label='Mês*'
                          name='Mes'
                          @changeInput='changeMes'
                      />
                  </div>
                  <div class="col-sm-4 nopadding" id='seletorEmpresa'>
                    <!-- Select de empresas -->
                      <selectAll
                          nameForRadio  ='seletorEmpresas'
                          :hasSelectAll ='true'
                          :labels       ='[{"description":"Empresas*"}]'
                          :isMultiple   ='true'
                          :optionsArray ='optSelectEmpresa'
                          :selected     ="selectedEmpresa"
                          @changeSelect ="changeEmpresa"
                      />
                  </div>
                  <div class="col-sm-4 nopadding">
                      <!-- Select de veículos ou grupo de veículos -->
                      <selectAll
                          nameForRadio  ='seletorVeiculosGrupos'
                          firstSelected ='V'
                          v-bind="{
                              hasSelectAll:true,
                              isMultiple:true,
                              optionsArray:optSelectVeiculos,
                              labels:labelSeletorVeiculoGrupos,
                              ref:'selectVG'
                          }"
                          v-on="{changeCheck:mudaCheck}"
                          @changeSelect ='changeVeiculo'
                          :disabled     ="$v.empresasSelecionadas.$invalid"
                          :loading      ='carregando'
                      />
                  </div>
              </div>
          </template>
        </slideUpAndDown>
      <div class="col-sm-12"><hr></div>
      <div class="col-sm-12 row"
        v-if="relatorio.length == 0">
        <statusInformation 
          class="col-sm-12"
          typeBar="div" 
          :statusBar="statusbar"
          colspanForTd="12">
        </statusInformation>
      </div>
      <div class="col-sm-12 row nopadding"
        v-else>
        <div v-for="(placa, k) in relatorio"
            :key="k"
          class="col-md-12 col-lg-6 row nopadding " >
          <!-- tabela em formato de calendário -->
          <!-- Início -->
          <template>
            <div
              class="col-sm-12 text-center section">
              <!-- Cabeçalho do calendário, onde fica a placa e total de km -->
              <div
                class=" cabecalho col-sm-12 row">
                <span class="col-sm-6 ">
                  {{k}}
                </span> 
                <span
                  class="col-sm-6 kmTotal"> 
                  Total: {{calculaTotal(placa)}} Hora(s)
                </span>
              </div>
              <!-- Início do componente calendário 
                Masks coloca o formato dos dias da semana 
                From-page seleciona o mês do calendário (sempre utilizar Number antes da variável month
                se não o mês de Janeiro não vai funcionar)
                Attributes onde passo os dados que vão ser gerados dentro dos dias -->
              <vc-calendar
                :from-page  ="{month: Number(mesCalendar[1]), year: mesCalendar[2]}" 
                class       ="custom-calendar max-w-full"
                :masks      ="masks"
                disable-page-swipe
                :attributes ="gerarAtributos(placa)"
                is-expanded 
              >
              >
              <!-- Começo das datas 
                Span coloca os dias referentes ao mês 
                Primeiro <p> é onde fica o km do veículo -->
                <template 
                  v-slot:day-content="{day, attributes}">
                  <div class="fundoWhite">
                    <span>{{ day.day }}</span> 
                    <p
                      v-for="attr in attributes"
                      :key="attr.key"
                      class="fraseDia tamKm"
                    > 
                      {{attr.customData.title}}
                    </p>
                    <p class="fraseDia">
                      Horas(s)
                    </p>
                  </div>
                </template>
              </vc-calendar>
            </div>
          </template> 
          <!-- Fim -->
        </div>
      </div>
    </div>
  </panelEagle>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import {mdiProgressClock } from '@mdi/js'
import { required } from 'vuelidate/lib/validators'
import panelEagle from '@/components/Atom/Panel/PanelEagle'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { Map } from 'leaflet'
import { mapGetters } from 'vuex'


export default {
    name:'resumoHorimetro',
    components: {
      panelEagle,
      titulo            : require('@/components/Atom/Header/Titulo').default,
      botoesEIG         : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
      selectAll         : require('@/components/Atom/Select/SelectAll').default,
      slideUpAndDown    : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
      inputMonth        : require('@/components/Atom/Datas/InputMonth').default,
      statusInformation : require('@/components/Atom/StatusInformation/StatusInformation').default,   
    },
    validations:{
        empresasSelecionadas:{
            required
        },
        veiculosSelecionados:{
            required
        },
        mesAnoSelecionado:{
            required
        },
        relatorio:{
          required
        }
    },
    data() {
      return {
        mdiProgressClock:mdiProgressClock,
        loadingPanelEagle:false,
        optSelectEmpresa          : new EmpresasService().Get(),
        optSelectVeiculos         : [],
        empresasSelecionadas      : [],
        veiculosSelecionados      : [],
        mesAnoSelecionado         : [],
        carregando                : false,
        dataGEV                   : [],
        status                    : "info",
        loadingExportar           : [false, false, false],
        data_inicio               : [],
        data_fim                  : [],
        tipo                      : "V",
        relatorio                 : [],
        mes                       : [],
        ano                       : [],
        data_mes                  : [],
        mesCalendar               : [],
        mesGerar                  : [],
        statusbar                 : 'info',
        labelSeletorVeiculoGrupos : [
          {'indexDFH':'V' ,'description':'Veiculos*'},
          {'indexDFH':'GV','description':'Grupos*'}
        ],
        masks: {
          weekdays: 'WWW',
        },
        selectedEmpresa: [],
      }
    },
    methods: {
      ...mapGetters(['getMaster']),
      // Início dos metódos para pegar informações dos selects/input
      changeEmpresa(empresas){
          this.empresasSelecionadas = empresas
          this.procuraVeiculosEGrupos(empresas)
      },
      changeVeiculo(veiculos){
          this.veiculosSelecionados = veiculos
      },
      changeMes(mesAno){
          this.mesAnoSelecionado = mesAno
      },
      // Fim dos métodos
      // Método para calcular o total de Km rodado pelo veículo
      calculaTotal(calendario){
        var somaTotal = 0
        var teste = calendario.dias
        teste = teste.filter(k => k.data != '')
        var totalRodado = teste.map(element =>  {
          somaTotal = Number(somaTotal) + Number(element.valor)
        })
        return somaTotal.toFixed(0)
      },
      // Método para transformar o objeto da forma que o calendário aceita
      gerarAtributos(calendario){
        var teste = calendario.dias
        teste = teste.filter(k => k.data != '')
        var retorno = teste.map(element =>  {
            var formatData = element.data.split('/')
            var dia = formatData[0]
            var mes = formatData[1]
            var ano = formatData[2]
            var attributes = {
                key : element.valor+element.data,
                customData : {
                title : element.valor,
                },
                // usando mes-1 pois a data começa em 0 e a que estou passando em 01
                dates : new Date(ano, mes-1, dia),
            }
            return attributes
        })
        return retorno
      },
      // Método para gerar relatório, onde utilizando a biblioteca moment é feita a transformação
      // de datas para serem usadas no back
      async gerarRelatorio(){
        this.loadingPanelEagle = true
        moment.updateLocale('pt', {
          months : [
            "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho",
            "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
          ]
        });
        this.statusbar   = 'info'
        this.data_inicio = moment(this.mesAnoSelecionado._d)
          .set("date", 1)
          .format("DD-MM-YYYY")
        this.data_fim    = moment(this.mesAnoSelecionado._d)
          .endOf("month")
          .format("D-MM-YYYY")
        this.data_mes = moment(this.mesAnoSelecionado._d)
          .set("date", 1)
          .format("D [de] MMMM/YYYY")
        this.mesGerar = moment(this.mesAnoSelecionado._d)
        .set("date", 1)
        .format("DD-MM-YYYY")
        this.mesCalendar = this.mesGerar.split('-')
        this.mes         = this.data_mes.split(' ')
        this.mes         = this.mes[2].split('/')
        this.ano         = this.mes[1]
        var dados        = await new HttpRequest().Post('/telemetria/relatorios/resumo/horimetro/gerarRelatorio',
          {'clientes'      : this.empresasSelecionadas,
            'tipoPesquisa' : this.tipo,
            'veiculos'     : this.veiculosSelecionados,
            'mes'          : this.mes[0],
            'ano'          : this.ano, 
          })
        if(dados.data.status){
          if(dados.data.dados.length == 0){
            this.statusbar = 'error'
          } else {
            this.relatorio    = dados.data.dados
          }
        } else {
          this.statusbar = 'error2'
        }
        this.loadingPanelEagle = false
      },
      
      async exportarRelatorio(type){
        this.setLoading(type)
        var arrayDados = this.relatorio
        var root =  process.env.VUE_APP_ROOT;
        var dados = await new HttpRequest().Post('/telemetria/relatorios/resumo/horimetro/exportarRelatorio',
          {arrayDados,
           'tipo'        : type,
           'data_inicio' : this.data_inicio,
           'data_fim'    : this.data_fim,
           'titulo'      : 8,
          },)
        this.setLoading()
        if(dados.status){
          window.open(root+'/'+dados.data.dados);
        }
      },
      // Método para trazer do helper os veículos e grupos de veículos
      procuraVeiculosEGrupos(arr){
        this.carregando = true
        if(arr.length > 0){
          new FiltrosService().dados_filtros(arr, ['V', 'GV'])
          .then((data)=>{
            this.dataGEV = data
            this.optSelectVeiculos = this.dataGEV[this.$refs.selectVG.labelSelected]
            this.carregando = false
          })
        }else{
            this.carregando = false
          this.$refs.selectVG.clearAll()
        }
      },
      mudaCheck(value){
        this.tipo = value
        // this.relatorio.busca = value
        if(this.dataGEV[value]!== undefined){
          this.optSelectVeiculos = this.dataGEV[value]
        }
      },
      setLoading(tipo=null){
        let arr = [false, false, false]
        if(tipo=='pdf'){
          arr[0] = true;
        }else if(tipo=='xls'){
          arr[1] = true;
        }else if(tipo=='csv'){
          arr[2] = true;
        }
        this.loadingExportar =  arr;
      },
    },
    
    mounted() {
      if(!this.getMaster()){
        this.selectedEmpresa = this.optSelectEmpresa
      }
    }
  }
</script>

<style lang="scss" scoped>
  .cabecalho span{
    margin: 0px;
    padding-top: 11px;
    padding-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }
  .cabecalho{
    color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    min-width: 60px;
    padding: 0px;
    height: 40px;
    max-height: 40px;
    background: #428bca;
    overflow: hidden;
    text-overflow: ellipsis !important;
    font-size: 16px;
  }
  .section{
    max-width: 100%;
    padding: 15px;
    span.kmTotal{
      max-height: 40px;
      font-size: 16px;
      padding-left: 0px;
      padding-right: 0px;
      background: #0059b3;
      border-top-right-radius: 5px;
      max-width: 50%;
      overflow: hidden;
      text-overflow: ellipsis !important;
    }
  }
/deep/ .custom-calendar.vc-container {
  --day-width: 12%;
  --day-height: 60px;
  --weekday-bg: ridge #f8fafc;
  border-radius: 0;
  width: 100%;
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    font-size: 17px;
    color: #498ed6;
    background-color: #ededed;
    padding: 5px 0;
  }
  & .vc-day:hover{
    box-sizing: border-box;
    box-shadow: 2px 0px 5px #b8b8b8;
    font-weight: bold;
    // .tamKm{
    //   cursor: help;
    // }
  }
  & .vc-day {
    border-radius: 5px;
    border: 1px ridge #d9d9d9;
    color: #0059b3;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    background: #f3f3f3;
    &.weekday-1, 
    &:not(.on-right) {
      border-right: var(--day-border);
    }
    .tamKm{
      font-size: 15px !important;
    }
    .fraseDia{
      color: black;
      margin: 0px;
      height: 15px;
      font-family: nexabook,sans-serif;
      font-size: 13px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis !important;
    }
  }
  .vc-header{
    display: none;
  }
  .vc-arrows-container{
    display: none;
  }
  .fundoWhite{
    border-radius: 5px;
    height: 60px;
    padding: 0 5px 3px 5px;
    width: 100% !important;
    background: white !important;
    border-bottom: 1px ridge #d9d9d9;
  }
}
</style>
