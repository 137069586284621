import { provide, ref, Ref, watch } from 'vue'
import { DateTime, Interval } from 'luxon'

import { getTelemetryData, TelemetryData } from '@/Services/vehicle/telemetry.service'	

const DEFAULT_START_DATE = DateTime.now().startOf('day')
 // Desconsidera os segundos para evitar requisições desnecessárias (ex.: 31/05/24 23:59:00)
const DEFAULT_END_DATE = DateTime.now().endOf('day').startOf('minute')

export interface UsableTelemetry {
    data: Ref<TelemetryData>,
    hasError: Ref<boolean>,
    isLoading: Ref<boolean>,
    interval: Ref<Interval>,
    setIntervalFromDates: (start: DateTime, end: DateTime) => void
}

export const useTelemetry = (options = { start: DEFAULT_START_DATE, end: DEFAULT_END_DATE }): UsableTelemetry => {
    const interval = ref(Interval.fromDateTimes(options.start, options.end))
    const isLoading = ref(false)
    const hasError = ref(false)
    const data = ref<TelemetryData>({})

    const plate = ref<string>('')

    /**
     * Atualiza o intervalo
     * @param start Data de início
     * @param end Data de fim
     */
    const setIntervalFromDates = (start: DateTime, end: DateTime): void => {
        interval.value = Interval.fromDateTimes(start, end)
    }

    /**
     * Atualiza os dados de telemetria 
     */
    const updateTelemetryData = (): void => {
        if (isLoading.value || !plate.value || !interval.value) return
        
        isLoading.value = true

        getTelemetryData(interval.value, plate.value)
            .then((telemetryData) => {
                data.value = telemetryData
                hasError.value = false
            })
            .catch(() => hasError.value = true)
            .finally(() => isLoading.value = false)
    }

    // Watcher é chamado sempre que o veículo ou intervalo selecionado é alterado
    watch([plate, interval], async ([newPlate, newInterval], oldValues) => {
        const oldPlate = oldValues[0] ?? ''
        const oldInterval = oldValues[1] ?? null

        // Remova o comentário da linha abaixo para visualizar os valores antigos e novos
        // para investigar possíveis problemas de requisições desnecessárias
        // console.table({
        //     oldPlate,
        //     newPlate,
        //     oldInterval: oldInterval?.toISO(),
        //     newInterval: newInterval?.toISO()
        // })

        // Redefine o intervalo caso o veículo tenha sido alterado
        if (oldPlate && newPlate !== oldPlate) {
            interval.value = Interval.fromDateTimes(DEFAULT_START_DATE, DEFAULT_END_DATE)
        }

        // Atualiza os dados de telemetria nos seguintes casos:
        // - O veículo foi alterado e não é igual ao veículo anterior
        // - O intervalo foi alterado, é valido e não é igual ao anterior
        if (newPlate && newInterval.isValid 
            && newPlate != oldPlate || (!oldInterval || !newInterval.equals(oldInterval))) {
            updateTelemetryData()
        }

    }, { immediate: true })

    provide('plate', plate)
    provide('telemetryData', data)
    provide('telemetryHasError', hasError)
    provide('telemetryInterval', interval)
    provide('telemetryIsLoading', isLoading)
    provide('updateTelemetryData', updateTelemetryData)
    
    return {
        data,
        hasError,
        interval,
        isLoading,
        setIntervalFromDates
    }
}
