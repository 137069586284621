<template>
	<div>
		<div id='second-step'>
			<div class='informacoes-horario'>
				<draggable
				v-model='pontosData'
				:disabled='existeAjuste'
				@change='mudaOrdemPontos'>
					<div
					v-for='(linha, index) in pontosData'
					:disabled='existeAjuste'
					:key='`${index}__linha_info`'
					class='info-ponto'>
						<span class='dragthing'>
							<base-icon
							:icon='iconDrag'
							:size='17'/>
						</span>
						<div class='linha-info justify-content-between'>
							<div class='entrada-saida'>
								{{decideNumero(linha)}} - {{resolveEntra(index)}}
							</div>
							<div class="horario">
								<!-- :pattern="/^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/" -->
								<input
								type="time"
								v-model='linha.hora'
								:disabled='existeAjuste'
								class='form-time'
								@blur='mudaHora(index, linha.hora)'>
							</div>
						</div>
						<span
						@click='removeHorario(index)'
						:disabled='existeAjuste'
						title='Remover'
						class='button-remove'>
							<base-icon
							:size='17'
							:icon='iconClose'/>
						</span>
					</div>
				</draggable>
			</div>
			<div class="botoes">
				<simple-button
				type='light-blue'
				width='170px'
				:disabled='existeAjuste'
				:icon='iconNovoEvento'
				text='Adicionar novo evento'
				event='click'
				@click='adicionaNovoEvento'/>
				<simple-button
				:disabled='existeAjuste'
				type='aqua-marine'
				width='170px'
				:icon='iconAnexar'
				text='Adicionar anexo'
				event='click'
				@click='onPickFile'/>
				{{imgValue}}
			</div>
		</div>
		<label
		for="motivo_solicitacao">
			<span class='unselectable'>
				Motivo da Solicitação*:
			</span>
		</label>
		<textarea
		name='motivo-solicitacao'
		id='motivo_solicitacao'
		:disabled='existeAjuste'
		cols='49'
		v-model='motivoAjuste'
		rows='2'
		@keyup='mudaMotivo'/>
	</div>
</template>

<script>
import {
	mdiCloseCircle,
	mdiPaperclip,
	mdiMenu,
	mdiPlusThick } from '@mdi/js'
import draggable from 'vuedraggable'
export default {
	name:'secondStep',
	/* eslint-disable max-len */
	components:{
		simpleButton: require('@/components/Atom/Buttons/SimpleButton.vue').default,
		baseIcon    : require('@/components/Atom/Icon/BaseIcon.vue').default,
		draggable,
	},
	/* eslint-enable max-len */
	props:{
		pontos:{
			type: Array,
			require: true,
		},
		motivo:{
			type: String,
			default:'',
		},
		imgValue:{
			type: String,
			default: ''
		},
		existeAjuste:{
			type: Boolean,
			default:false
		},
		codigoM:{
			type: Number,
			required:true
		},
		dataEvento:{
			type: String,
			required:true
		}
	},
	data(){
		return {
			iconClose:mdiCloseCircle,
			iconAnexar: mdiPaperclip,
			iconNovoEvento: mdiPlusThick,
			iconDrag: mdiMenu,
			pontosData:this.pontos,
			motivoAjuste:this.motivo,
		}
	},
	methods:{
		decideNumero(n){
			var retorno;
			this.paresEventos.forEach((tupla, index)=>{
				var found = _.find(tupla, n)
				if(found )
					retorno = index+1
			})
			return retorno
		},
		
		/**
		 * @listens blur - input de hora na lista de eventos
		 * @param {number} index 
		 * @param {string} hora 
		 * @author Gui
		 */
		mudaHora(index, hora){
			this.$emit('edita-evento', index)
		},

		onPickFile(){
			this.$emit('pick-file')
		},

		mudaMotivo(){
			this.$emit('muda-motivo', this.motivoAjuste)
		},
		/**
		 * @param {number} index do ponto a ser removido
		 * @emits remove-evento 
		 * @listens click botão de remover evento
		 * @description avisa o ajusteStepper que um evento deve ser removido
		 */
		removeHorario(index){
			if(!this.existeAjuste)
				this.$emit('remove-evento', index)
		},
		
		/**
		 * @description pelo valor do índice resolve
		 * se o ponto é de entrada ou de saída.
		 */
		resolveEntra(index){
			if(index % 2){
				return 'Saída'
			}
			return 'Entrada'
		},

		/**
		 * @listens click no botão de adicionar novo evento
		 * @emits adc-evento
		 * @description gera um novo evento de ponto, com
		 * os campos corretos. O campo "key" é p/ todo
		 * evento ser diferente, é utilizado na ordenação
		 * dos pontos.
		 */
		adicionaNovoEvento(){
			var evento = {
				crcodigo:0,
				crcodigocolaborador: this.codigoM,
				data:this.dataEvento,
				crlatitude:'',
				crlongitude:'',
				crendereco:'',
				crajustado:'',
				hora:'00:00',
				key: this.randomNumber()
			}
			this.$emit('adc-evento', evento)
		},
		mudaOrdemPontos(){
			this.$emit('nova-order', this.pontosData)
		},
		
		randomNumber(min=1, max=1000){
			let number = Math.random() * (max - min) + min
			return Math.floor(number)
		}

	},
	watch: {
		pontos(newValue){
			this.pontosData = newValue
		},
	},
	computed:{
		paresEventos(){
			var pares = []
			this.pontosData.forEach((e, i)=>{
				let index_par = i % 2 === 0
				if(index_par){
					pares.push([e, this.pontosData[i+1]])
				}
			})
			return pares;
		}
	}
}
</script>

<style lang="scss" scoped>
#second-step{
	padding-top: 10px;
	display: flex;
	.info-ponto{
		display: flex;
		border-bottom: 1px solid gray;
		max-width: fit-content;
	}
	.informacoes-horario{
		max-height: 150px;
		padding: 1px;
		width: 300px;
		overflow-x: hide;
		overflow-y:auto;
		.linha-info{
			margin-bottom: 1px;
			display: flex;
			flex-wrap: nowrap;
			height: 33px;
			width: 200px;
		}
		.entrada-saida{
			padding-top: 5px;
			padding-left: 2px;
		}
		.horario{
			margin-top: 3px;
			padding-right: 3px;
			.form-time{
				width: 105px;
			}
		}
		.button-remove{
			color:red;
			margin-top: 9px;
			margin-left: 2px;
		}
	}
	.botoes{
		padding-top: 25px;
		align-items: center;
		vertical-align: center;
		display: flex;
		flex-direction: column;
	}
	.dragthing{
		padding-top: 6px;
		padding-right: 4px;
		cursor: grab;
	}
}
.unselectable{
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}
</style>