export default class Relatorio {
    
    /**
     * Instância um atributo da classe para
     * cada placa que esta sendo processada
     * @param placa chave onde os dados serão inseridos
     * ==> Não como array, mas cada placa individualmente
     * ===> dica: new Relatorio(...arrayComTodasASPlacas)
     * @author Gui 🍺🍺😰
     */
    constructor(){
        for(var i=0; i< arguments.length; i++){
            this[arguments[i]] = ''
        }
    }
    /**
     * Marca o campo paraProcessar de todos os veiculos
     * que possuam algum tipo de erro
     * @param value Boolean true ou false
     */
    selecionarTodosComErro(value){
        for(var placa in this){
            if(this[placa].existeErro){
                this[placa].paraProcessar = value
            }
        }
    }

    /**
     * Faz com que a placa em questão
     * contenha os dados sobre aquele veiculo
     * @param placa a placa a quem os dados pertencem
     * @param data  dados a inserir
     * @author Gui 🍺🍺🍺
     */
    addData(placa, data){
        this[placa]                  = data
        this[placa].placa            = placa
        this[placa].paradaCor        = this.defineCor(data.paradas)
        this[placa].ignicaoCor       = this.defineCor(data.ignicoes)
        this[placa].pacotesCor       = this.defineCor(data.pacotes[0])
        this[placa].legendaPacote    = data.pacotes[3]
        this[placa].legendaIgnicao   = data.pacotes[2]
        this[placa].legendaParadas   = data.pacotes[1]
        this[placa].processamentoCor = 'gray'
        this[placa].reprocessar      = this.anyError(this[placa])
        this[placa].paraProcessar    = false
        this[placa].existeErro       = this.verificaErro(data)
    }

    /**
     * Para fazer update nas informações de uma placa
     * depois de reprocessar os dados
     * @param placa que foi reprocessada
     * @param data retorno da função de processamento
     * @author Gui 🍺🍺
     */
    updateData(placa, data){
        this[placa]                  = data
        this[placa].placa            = placa
        this[placa].paradaCor        = this.defineCor(data.paradas)
        this[placa].ignicaoCor       = this.defineCor(data.ignicoes)
        this[placa].pacotesCor       = this.defineCor(data.pacotes[0])
        this[placa].legendaPacote    = data.pacotes[3]
        this[placa].legendaIgnicao   = data.pacotes[2]
        this[placa].legendaParadas   = data.pacotes[1]
        this[placa].processamentoCor = this.defineCorProcessamanto(data.paradas, data.ignicoes)
        this[placa].reprocessar      = this.anyError(this[placa])
        this[placa].paraProcessar    = false
        this[placa].existeErro       = this.verificaErro(data)
    }

    /**
     * Define a cor da bolinha de status processamento
     * @param data status do processamento
     * @returns string com a classe CSS que representa
     * o retorno da função
     */
    defineCorProcessamento(data){
        if(data == 'success')
            return 'green'
        if(data == 'error')
            return 'pink'
    }

    /**
     * Verifica se a placa em questão possui algum dos
     * erros que podem ser reprocessador na página de 
     * integridade
     * @param data dados do veículo 
     * @returns Boolen de acordo com o status da
     * placa
     */
    verificaErro(data){
        if(data.ignicoes == 'error'){
            return true
        }
        if(data.paradas == 'error'){
            return true
        }
        return false
    }

    /**
     * Decide a cor da bolinha na tabela de acordo
     * com os dados que a placa possui
     * @param {*} status paradas, ignicoes 
     * @returns cor do circulo que deve aparecer no relatório
     */
     defineCorProcessamanto(paradas, ignicoes){
        if(paradas == 'no-dados' && ignicoes == 'no-dados')
            return 'blue'
        if(paradas == 'success' && ignicoes == 'success')
            return 'green'
        return 'pink'
    }

    /**
     * Decide a cor da bolinha na tabela de acordo
     * com os dados que a placa possui
     * @param {*} data 
     * @returns cor do circulo que deve aparecer no relatório
     */
     defineCor(data){
        if(data == 'no-dados')
            return 'blue'
        if(data == 'success')
            return 'green'
        return 'pink'
    }

    anyError(veiculo){
        if(veiculo.paradaCor == 'pink')
            return false
        if(veiculo.ignicaoCor == 'pink')
            return false
        return true
    }

    /**
     * Retorna true ou false de acordo com o
     * 'tamanho' do objeto
     * @returns Booelan 
     */
    show(){
        return Object.keys(this).length > 0
    }

    /**
     * @returns quantidade de placas no objeto
     */
    length(){
        return Object.keys(this).length
    }

    /**
     * 
     * @returns array com todos as placas de 
     * todos os veículos que estão marcados para
     * ser reprocessados
     */
    getTodosProcessar(tipo){
        let processar = []
        let checks = []
        Object.keys(this)
        .forEach((p)=>{
            if(tipo == 'PA' && this[p].paraProcessar){
                if(this[p].paradas != 'success'){
                    processar.push(this[p].placa)
                } else {
                    checks.push(this[p].placa)
                }
            } else if (tipo == 'IG' && this[p].paraProcessar){
                if(this[p].ignicoes != 'success'){
                    processar.push(this[p].placa)
                } else {
                    checks.push(this[p].placa)
                }
            }
        })
        return {processar, checks}
    }

    /**
     * @returns Boolean true caso exista pelo menos
     * uma placa com pelo menos um erro
     */
    existemErros(){
        let existeErro = false
        Object.keys(this).forEach(el=>{
            if(this[el].existeErro){
                existeErro =  true
            }
        })
        return existeErro
    }
}