<!-- 
    * dados:
    NSR - 000000000 (valor fixo)
    Tipo reg. - 1 (Valor fixo)
    Tipo doc. - valor 1 para CNPJ | 2 para CPF
    Documento - CNPJ | CPF (cldocumento)
    Razão social - Nome da empresa (clnome)
    Convenção coletiva - Valor convenção coletiva caso não tenha deixar 99999999999999999 (jtconvencao)
    Data inicial - data de início do relatório (primeira data do filtro)
    Data final - data de fim do relatório (segunda data do filtro)
    Data / hora geração  - data e hora geração do relatório (Data e hora da requisição)
    Versão do leiaute AFD - 003 (Valor fixo)
    Iden. Eagle - 1 (Valor fixo)
    CNPJ Eagle - CNPJ da eagle 
    CRC-16 - Valor verificação cíclica de redundância (será adicionado para frente) 
-->
<template>
    <div class="mt-4 col-12 nopadding">
        <tableRelatorio>
            <template slot="thead">
            </template>
            <template slot="tbody">
                <tr class="backColorTdPDF">
                    <td colspan="13" class="nopadding">
                        <span class="newbadge">
                            {{dados.descricao}}
                        </span>
                    </td>
                </tr>
                <tr class="trTdHeader">
                    <td>NSR</td>
                    <td>Tipo reg.</td>
                    <td>Tipo doc.</td>
                    <td>Documento</td>
                    <td>Razão social</td>
                    <td>Convenção coletiva</td>
                    <td>Data inicial</td>
                    <td>Data final</td>
                    <td>Data / hora geração</td>
                    <td>Versão do leiaute AFD</td>
                    <td>Iden. Eagle</td>
                    <td>CNPJ Eagle</td>
                    <td>CRC-16</td>
                </tr>
                <tr>
                    <td>{{dados.nsr}}</td>
                    <td>{{dados.tiporegistro}}</td>
                    <td>{{dados.tipodocumento}}</td>
                    <td>{{dados.documento}}</td>
                    <td>{{dados.razaosocial}}</td>
                    <td>{{dados.convencao}}</td>
                    <td>{{dados.dataini}}</td>
                    <td>{{dados.datafim}}</td>
                    <td>{{dados.datahorageracao}}</td>
                    <td>{{dados.versaoleiaute}}</td>
                    <td>{{dados.ideneagle}}</td>
                    <td>{{dados.cnpjeagle}}</td>
                    <td>{{dados.crc16}}</td>
                </tr>
            </template>
        </tableRelatorio>
    </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
    name:'registroTipo1',
    components:{
		tableRelatorio: require('@/components/Atom/Table/TableRelatorio').default,
    },
    props:{
        dados:{
            type: Object,
            required: true,
            default: function(){return {}}
        },
    },
})
</script>
    
<style lang="scss">
    
</style>