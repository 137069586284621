import { DateTime } from 'luxon'

/**
 * Faz a validacao se a hora respeita o valor maximo de 23:59:59
 * @param {string} hora 
 * @param {("HH" | "HH:mm" | "HH:mm:ss") | null} formato
 * @return { Boolean } - true se valido, false se nao valido
 */
export function verifica24Horas(hora: string): boolean {
    try {
        return DateTime.fromObject({
            hour: Number(hora.split(':')[0]??0),
            minute: Number(hora.split(':')[1]??0),
            second: Number(hora.split(':')[2]??0),
        }).isValid
    } catch {
        return false
    }
}

/**
 * @param horaInicial 
 * @param horaFinal 
 * @returns
 * @author Chat GPT @link https://chat.openai.com/chat
 */
export function validarHoras(horaInicial: string, horaFinal: string):
    { inicioOk: boolean, fimOk: boolean } {
    let inicioOk = true
    let fimOk = true
    if (!horaInicial) {
        inicioOk = false
    }
    if (!horaFinal) {
        fimOk = false
    }
    const dataInicial = new Date(`1970-01-01T${horaInicial}`)
    const dataFinal = new Date(`1970-01-01T${horaFinal}`)
    if (dataInicial > dataFinal) {
        fimOk = inicioOk = false
    }
    if (!isValidDate(dataInicial)) {
        inicioOk = false
    }
    if (!isValidDate(dataFinal)) {
        fimOk = false
    }
    return { inicioOk, fimOk }
}

function isValidDate(dateObject: any){
    return new Date(dateObject).toString() !== 'Invalid Date';
}