<template>
  <eaglePanel id="regiao" :loading="gerandoRelatorio">
    <div class="col-sm-12 row nopadding">
      <div class="col-sm-12 nopadding row">
        <div class="col-sm-5 ">
          <titulo titulo="Região" :icon="mdiEarth" />
        </div>
        <div class="col-sm-7 divDontPrint">
          <botoes
            @exportarRelatorio='exportarRelatorio'
            @gerarRelatorio='gerarRelatorio'
            :loading='loadingEx'
            :disabled='gerarInvalido'
            :disabledDropdown='relatorioResumido ? $v.dataRelResumido.$invalid : $v.dataRelDetalhado.$invalid'
          >
            <simpleButton
              :type="relatorioResumido ? 'grey' : 'green'"
              text="Detalhado"
              event="click"
              @click="defineTipoRel(false)"
            />
            <simpleButton
              :type="relatorioResumido ? 'green' : 'grey'"
              text="Resumido"
              event="click"
              @click="defineTipoRel(true)"
            />
          </botoes>
        </div>
        <slide>
          <div class="col-sm-12 nopadding row">
          <div class="col-sm-12"><hr/></div>
            <div class="col-sm-3 nopadding">
              <div v-if="relatorioResumido">
                <rangeWLimit 
                  name="SeletorPeriodo" 
                  opens="right" 
                  @changeInput='value=> relatorio.intervalo = value'
                  />
              </div>
              <div class="row nopadding" v-else>
                <div class="col-sm-12 nopadding" style="margin-bottom: 2%">
                  <diaUnico
                    label="Data*"
                    name="pickerDiaUnico"
                    @changeInput="(value) => (relatorio.diaUnico = value)"
                  />
                </div>
                <div class="col-sm-6 nopadding">
                  <inputS 
                    value='00:00'
                    type="time" 
                    label="Hora Inicial" 
                    @changeInput='mudaHoraI'
                  />
                </div>
                <div class="col-sm-6 nopadding">
                  <inputS
                    value='23:59'
                    type="time"
                    @changeInput='mudaHoraF'
                    label="Hora Final" />
                </div>
              </div>
            </div>
            <div class="col-sm-3 nopadding">
              <selectAll
                ref="refEmpresa"
                :isMultiple="true"
                :hasSelectAll="true"
                nameForRadio="seletorDeEmpresas"
                :labels="[{ description: 'Empresas*' }]"
                :optionsArray="optSelectEmpresa"
                :selected="selectedEmpresa"
                @changeSelect="selecionaEmpresa"
              />
            </div>
            <div class="col-sm-3 nopadding">
              <selectAll
                :disabled="!optSelectVeiculo.length"
                :loading="loadingVeiculos"
                :isMultiple="!relatorioResumido"
                :hasSelectAll="!relatorioResumido"
                nameForRadio="seletorVeiculo"
                ref="seletorVeiculo"
                :labels="[
                    {indexDFH:'V',  description: 'Veículos*'}, 
                    {indexDFH:'GV', description: 'Grupos*'}
                ]"
                firstSelected="V"
                @changeCheck="atribuiVeiculos"
                :optionsArray="optSelectVeiculo"
                @changeSelect='mudaVeiculoSelecionado'
              />
            </div>
            <div class="col-sm-3 nopadding" v-if="relatorioResumido">
              <b-check class="check" v-model="relatorio.regioes"
                >Mostrar apenas Regiões que o veiculo passou</b-check
              >
              <b-check v-model="relatorio.horimetro" class="check"
                >Utilizar horímetro como base de cálculo das horas</b-check
              >
            </div>
            <div class="col-sm-3 nopadding" v-else>
              <selectAll
                :disabled="!optSelectRegiao.length"
                :loading="loadingRegiao"
                :isMultiple="true"
                ref="selectRegiao"
                nameForRadio="seletorRegiao"
                :labels="[{ description: 'Região' }]"
                :optionsArray="optSelectRegiao"
                @changeSelect='mudaRegiaoSelecionado'
              />
            </div>
            <div class="col-sm-12 msg" v-if="!relatorioResumido">
              *Quando o tempo dentro da região for menor do que um minuto, não
              será considerado para a visualização na linha do tempo
            </div>
          </div>
        </slide>
        <div class="col-sm-12"><hr/></div>
      </div>
    </div>
    <div class="col-sm-12">
      <tableSimples v-if="relatorioResumido">
        <br>
        <thead>
          <th>Região</th>
          <th>Km</th>
          <th>Km(%)</th>
          <th>Hora</th>
          <th>Hora(%)</th>
        </thead>
        <tbody v-if="exibirRelResumido">
          <template v-for="(data, placa) in dataRelResumido">
            <tr :key="placa+'__placa'">
              <td class="placaRelResumido pl-0" colspan="5"><span v-text="placa"></span></td>
            </tr>
            <template v-for="(info, i) in data.regioes">
              <tr :key="placa+'__info__'+i">
                <td v-text="i"/>
                <td v-text="info.km+' Km'"/>
                <td v-text="info.porcentKm"/>
                <td v-text="info.tempo"/>
                <td v-text="info.porcentTempo"/>
              </tr>
            </template>
            <tr :key='placa+"__totais"'>
              <td style='font-weight: bold'>Total:</td>
              <td v-text='data.totais.km'/>
              <td v-text='data.totais.porcentKm'/>
              <td v-text='data.totais.tempo'/>
              <td v-text='data.totais.porcentTempo'/>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <status :statusBar="infoRelResumido" colspanForTd="5" />
        </tbody>
      </tableSimples>
      <div v-else class="col-sm-12 nopadding tabela">
        <template v-if='exibirRelDetalhado'>
          <template v-for="(data, placa) in dataRelDetalhado">
            <div class="col-sm-12 nopadding linha" :key="placa + '__placa'">
              <div class="col-sm-4 placa">
                {{ placa }}
              </div>
            </div>
            <div
              class="col-sm-12 row pt-4"
              :key="placa + '__progress'"
              style="background-color: white"
            >
              <div class="col-sm-2 tempoInicioBarra">00:00:00</div>
              <div class="col-sm-8 nopadding">
                <b-progress 
                  height="20px"
                  class="mt-1" 
                  :max="60*60*24"  
                  style='border: 1px solid #d5d5d5'
                >
                  <b-progress-bar
                    v-for='(info, i) in data'
                    :key='i+"__progress__"+placa'
                    :value='transformaTempoEmSegundos(info.tempo)'
                    :style='"background-color:"+info.legenda'
                    :title='info.porcentagem.toFixed(2)+"%"'
                  />
                </b-progress>
              </div>
              <div class="col-sm-2 tempoFimBarra" >23:59:59</div>
            </div>
            <tableSimples :key="placa+'__table'">
              <thead>
                  <th class="tituloTabelaD">Area</th>
                  <th class="tituloTabelaD">Entrada</th>
                  <th class="tituloTabelaD">Saída</th>
                  <th class="tituloTabelaD">Tempo</th>
                  <th class="tituloTabelaD">Km</th>
                  <th class="tituloTabelaD">Paradas</th>
                  <th class="tituloTabelaD">Colaborador</th>
                  <th class="tituloTabelaD">Legenda</th>
              </thead>
              <tbody :key="'body__' + placa">
                <br>
                <template v-for="(info, i) in data">
                  <tr class='rowInfo' :key="placa+i+'__info'" v-if="info.mostrar">
                    <td v-text="info.regiao" />
                    <td v-text="info.entrada" />
                    <td v-text="info.saida" />
                    <td v-text="info.tempo" />
                    <td v-text="info.km" />
                    <td v-text="info.paradas" />
                    <td v-text="info.motorista" />
                    <td>
                      <span 
                        :style="'background-color:'+info.legenda" 
                        class="" />
                    </td>
                  </tr>
                </template>
              </tbody>
            </tableSimples>
          </template>
        </template>
        <template v-else>
          <status :statusBar="infoRelDetalhado" typeBar='div'/>
        </template>
      </div>
    </div>
  </eaglePanel>
</template> 

<script>
import {mdiEarth } from '@mdi/js'
import { required } from 'vuelidate/lib/validators' 
import { FiltrosService } from '@/Services/filtros/filtros.Service';
import { EmpresasService } from '@/Services/auth/Empresas.service';
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { mapGetters } from 'vuex'

export default {
	name: 'RelatorioDeRegiao',
	components: {
		diaUnico: require('@/components/Atom/Datas/InputSingleDay').default,
		inputS: require('@/components/Atom/Inputs/InputSimple').default,
		simpleButton: require('@/components/Atom/Buttons/SimpleButton').default,
		eaglePanel: require('@/components/Atom/Panel/PanelEagle').default,
		titulo: require('@/components/Atom/Header/Titulo').default,
		botoes: require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG')
			.default,
		rangeWLimit: require('@/components/Atom/Datas/InputRangeWithLimit').default,
		selectAll: require('@/components/Atom/Select/SelectAll').default,
		tableSimples: require('@/components/Atom/Table/TableSimples').default,
		status: require('@/components/Atom/StatusInformation/StatusInformation')
			.default,
		slide: require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default
	},
	data() {
		return {
			mdiEarth:mdiEarth,
			loadingRegiao:false,
			loadingVeiculos:false,
			relatorioResumido: false,
			gerandoRelatorio: false,
			optSelectEmpresa: new EmpresasService().Get(),
			dataRelResumido  : [],
			dataRelDetalhado : [],
			optSelectVeiculo : [],
			optSelectRegiao  : [],
			relatorio: {
				cliente   : [],
				veiculo   : [],
				regiao    : [],
				intervalo : '',
				horaI     : '',
				horaF     : '',
				diaUnico  : '',
				regioes   : false,
				horimetro : false,
				grupoVeiculo: false,
			},
			infoRelResumido: 'info',
			infoRelDetalhado: 'info',
			urlBase:'/telemetria/relatorios/regiao/',
			loadingEx : [false, false, false],
			veiculosGerais:[],
			grupoVeiculos:[],
			tipoVeiculos:'V',
			selectedEmpresa: [],  
		};
	},
	validations:{
		relatorio:{
			cliente: { required },
			veiculo: { required },
			regiao : { required },
		},
		dataRelResumido  : { required },
		dataRelDetalhado : { required }
	},
	methods: {
		...mapGetters(['getMaster']),
		/**
     * Decide qual dos relatórios gerar de acordo com
     * o valor de relatorioResumido
     */
		gerarRelatorio(){
			this.gerandoRelatorio = true
			this.dataRelResumido = []
			this.dataRelDetalhado = []
			let uri = this.urlBase+ (this.relatorioResumido?
				'resumido':
				'detalhado')
			new HttpRequest().Post(uri, this.objRelatorio)
				.then(data=>{
					if(this.relatorioResumido){
						this.dataRelResumido = data.data.dados
						if(!this.exibirRelResumido)
							this.infoRelResumido = 'error'
					}else{
						this.dataRelDetalhado = data.data.dados
						if(!this.exibirRelDetalhado)
							this.infoRelDetalhado = 'error'
					}
					this.gerandoRelatorio = false
				})
		},

		selecionaEmpresa(arr) {
			this.relatorio.cliente = arr;
			if (arr.length)
				this.loadingVeiculos = true
			this.loadingRegiao   = true
			new FiltrosService()
				.dados_filtros(this.relatorio.cliente, ['V', 'RE', 'GV'])
				.then((data) => {
					this.loadingVeiculos = false
					this.loadingRegiao   = false
					this.optSelectRegiao = data.RE;
					this.veiculosGerais = data.V
					this.grupoVeiculos  = data.GV
					this.atribuiVeiculos(this.tipoVeiculos)
					// this.optSelectVeiculo = data.V;
				});
		},

		atribuiVeiculos(tipo){
			this.tipoVeiculos = tipo
			if(tipo == 'V'){
				this.optSelectVeiculo = this.veiculosGerais
				return
			}
			this.optSelectVeiculo = this.grupoVeiculos
		},

		defineTipoRel(value){
			this.relatorioResumido = value
			this.$refs.refEmpresa.clearAll()
			this.$nextTick(()=>{
				this.$refs.seletorVeiculo.$forceUpdate()
			})
		},

		transformaTempoEmSegundos(tempo){
			let total = 0
			var y = tempo.split(':')
			let hS = (y[0] * 60)* 60
			let mS = y[1] * 60
			total = hS + mS + y[2]
			return total
		},

		mudaVeiculoSelecionado(arr){
			if(this.tipoVeiculos == 'V')
				this.relatorio.grupoVeiculo = false
			else
				this.relatorio.grupoVeiculo = true
			this.relatorio.veiculo = arr
		},

		mudaRegiaoSelecionado(arr){
			this.relatorio.regiao = arr
		},

		mudaHoraF(value){
			this.relatorio.horaF = value
		},

		mudaHoraI(value){
			this.relatorio.horaI = value
		},

		/**
     * Pra gerar o PDF é necessário fazer um pequeno tratamento dos
     * dados do relatório detalhado, a função de extração faz isso
     * quando necessário, depois envia as informações relevantes
     * para o back-end
     */
		exportarRelatorio(tipo){
			let dataRelDetalado
			if(!this.relatorioResumido)
				dataRelDetalado = this.extraiDadosRelatorio(this.dataRelDetalhado);
			let obj = {
				'resumido': this.relatorioResumido,
				'tipo': tipo,
				'data': this.relatorioResumido? this.dataRelResumido:dataRelDetalado,
				'periodo': this.relatorioResumido? this.relatorio.intervalo: 
					(this.relatorio.diaUnico + ' ' + this.relatorio.horaI+'-'+this.relatorio.horaF)
			}
			this.mudaLoadingExportar(tipo)
			let uri = this.urlBase + 'exportar'
			new HttpRequest().Post(uri, obj)
				.then(data=>{
					this.mudaLoadingExportar()
					var root =  process.env.VUE_APP_ROOT
					if(data.status)
						window.open(root+'/'+data.data.local)
				})
		},

		mudaLoadingExportar(tipo=false){
			if(tipo == 'pdf')
				this.loadingEx =  [true, false, false]
			if(tipo == 'xls')
				this.loadingEx =  [false, true, false]
			if(tipo == 'csv')
				this.loadingEx =  [false, false, true]
			if(!tipo){
				this.loadingEx =  [false, false, false]
			}
		},

		extraiDadosRelatorio(data){
			var cpData = JSON.stringify(data);
			cpData = JSON.parse(cpData);
			Object.keys(cpData).forEach(v=>{
				cpData[v] =  cpData[v].filter(r=> r.mostrar)
			})
			return cpData
		}
	},
	computed: {
		gerarInvalido(){
			if(this.$v.relatorio.cliente.$invalid){
				return true
			}else{
				if(this.relatorioResumido){
					if(this.$v.relatorio.veiculo.$invalid){
						return true
					}
				}else{
					if(this.$v.relatorio.veiculo.$invalid && this.$v.relatorio.regiao.$invalid){
						return true
					}
				}
			}
			return false
		},

		/**Decide se o relatório deve ser exibido, caso não possua nenhuma chave,
     * Se presume que ou não foi gerado ou nenhuma informação foi encontrada
     */
		exibirRelResumido() {
			return Object.keys(this.dataRelResumido).length > 0;
		},

		/**Decide se o relatório deve ser exibido, caso não possua nenhuma chave,
     * Se presume que ou não foi gerado ou nenhuma informação foi encontrada
     */
		exibirRelDetalhado() {
			return Object.keys(this.dataRelDetalhado).length > 0;
		},

		/**
     * Cria o obj que é enviado para o back-end.
     * Que deus me perdoe pela gambiarra no obj pra rel detalhado.
     * Mas teve de ser feito assim para minimizar as alterações no back-end
     */
		objRelatorio(){
			let obj = {}
			if(this.relatorioResumido){
				return {
					'data'    : this.relatorio.intervalo,
					'clientes': this.relatorio.cliente,
					'veiculos': this.relatorio.veiculo,
					'apenasRegioesPassadas': !this.relatorio.regioes,
					'onlyhormetro': this.relatorio.horimetro
				}
			}else{
				obj =  {
					'ini'          : this.relatorio.diaUnico,
					'horaIni'      : this.relatorio.horaI,
					'horaFim'      : this.relatorio.horaF,
					'cli'          : this.relatorio.cliente,
					'grupoVeiculo' : this.relatorio.grupoVeiculo,
					'vei'          : this.relatorio.veiculo.map( el=> {
						return this.optSelectVeiculo.find(v => v.value == el).description.split(' | ')[0]
					}),
				}
				if(this.relatorio.regiao.length) obj = {...obj, 'regiao':  this.relatorio.regiao}
				return obj
			}
		}
	},
	watch: {
		'relatorio.cliente'(newValue, oldValue) {
			if (this.$refs.selectRegiao) this.$refs.selectRegiao.clearAll()
			this.$refs.seletorVeiculo.clearAll()
			this.optSelectVeiculo = []
			this.optSelectRegiao = []
			this.dataRelDetalhado = [] 
			this.dataRelResumido = []
		},
		relatorioResumido(newValue, oldValue){
			if (this.$refs.selectRegiao) this.$refs.selectRegiao.clearAll()
			this.$refs.seletorVeiculo.clearAll()
		}
	},

	mounted() {
		if(!this.getMaster()){
			this.selectedEmpresa = this.optSelectEmpresa
		}
	}
};
</script>

<style lang="scss" scoped>
.check {
  font-size: 13px;
  padding-top: 5px;
  text-align: left;
}
.msg {
  padding-top: 1%;
  color: red;
  font-size: 12px;
}
.tabela {
  max-height: 700px;
  overflow-x: auto;
}
.tempoInicioBarra{
  margin-top: 0.5%;
  text-align: right;
}
.tempoFimBarra{
  margin-top: 0.5%;
  text-align: left;
}
div[class*=placa] {
  background-color: #777;
  color: white;
  padding-top: 8px !important; 
  font-size: 15px !important;
  min-height: 40px;
}
.placaRelResumido{
  background: #fff;
  padding-top: 15px !important;
  span{
    display: block;
    width: 20%;
    background-color: #777;
    color: white;
    text-align: center;
    padding-top: 4px !important; 
    font-size: 15px !important;
    min-height: 30px;
  }
}
.linha {
  border-bottom: 1px solid #555;
  margin-top: 15px;
}
.rowInfo {
  background-color: white !important;
}
.tituloTabelaD {
  background-color: #efefef !important;
  padding-left: 6px !important;
}
.bolinha{
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: inline-block;
}
</style>