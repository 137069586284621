import { mdiThumbUpOutline, mdiDatabaseSync, mdiCloseThick } from '@mdi/js'
import { required } from 'vuelidate/lib/validators'
import { DateTime } from "luxon"

export const components = {
    eagleModal : require('@/components/Atom/Modal/ModalEagle').default,
    statusbar : require('@/components/Atom/StatusInformation/StatusInformation').default,
    tableSimples : require('@/components/Atom/Table/TableSimples').default,
    selectAll : require('@/components/Atom/Select/SelectAll').default,
    dayPicker : require('@/components/Atom/Datas/InputRangeWithLimit').default,
    eaglePanel : require('@/components/Atom/Panel/PanelEagle').default,
    titulo : require('@/components/Atom/Header/Titulo').default,
    simpleButton : require('@/components/Atom/Buttons/SimpleButton').default,
}

export const data = {
    labelsSeletorDeGrupoAhSerProcessado:[
        {indexDFH:"MR", description:"Motorista" },
        {indexDFH:"MA", description:"Ajudante" },
        {indexDFH:"AA", description:"Administrativo" },
    ],  
    optSelectGrupo:[],
    mdiCloseThick:mdiCloseThick,
    mdiDatabaseSync:mdiDatabaseSync,
    mdiThumbUpOutline:mdiThumbUpOutline,
    loadingPanel:false,
    data:{
        data : '',
        cliente : [],
        mot : []
    },
    carregandoGrupos : false,
    dataParaSeletor : [],
    dataGerar : [],
    dataProcessamento : [],
    statusBar : 'info',
    todosSelecionados : false,
    todosRFID : false,
    value : 0,
    max : 0,
    objsAProcessar : [],
    arrayDias : [],
    arrayErros : [],
    selectedEmpresa : [],
    habilitarProcessar: false,
    timeoutProcessamento: null,
}

export const validations = {
    data:{
        data : { required },
        cliente : { required },
        mot : { required }
    },
    dataGerar : { required }
}

/**
* Retorna a array com todos os dias que devem ser
* inclusos nas requests para o back-end
*/
export function arrayDatas(ini, fim) {
    let d1 = _toDate(ini),
    d2 = _toDate(fim),
    intervalos = [];
    
    while ( d1 <= d2 ) {
        intervalos.push(_toString(d1) );
        d1.setDate( d1.getDate() + 1 );
    }
    return intervalos
}

function _toDate(texto) {
    let partes = texto.split('/');
    return new Date(partes[2], partes[1]-1, partes[0]);
}

function _toString(date) {
    return date.getFullYear() +'-'+
        ('0' + (date.getMonth() + 1)).slice(-2) +'-'+
        ('0' + date.getDate()).slice(-2)
}

/**
 * Para transformar os dados do dia em um
 * formato entendivel para o usuário
 * @param dia data, podendo ser qualquer fomato
 * que o luxon entenda
 * @param completo true se deve incluir o ano
 * @returns string formatada com a data
 * @author Gui 🍺🍺
 */
export function displayDia(dia, completo=false){
    if(completo)
        return DateTime.fromFormat(dia, 'yyyy-MM-dd').toFormat('dd/MM/yyyy')
    return DateTime.fromFormat(dia, 'yyyy-MM-dd').toFormat('dd/MM')
}