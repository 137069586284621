Esse elemento foi feito para ser utilizado na tabela de rotas no mapa. 
O calculo de progresso (%) da rota considera que: 
Pontos com status 'J'(justificado) e 'F' (finalizado) contam como pontos 
concluidos, os pontos com status 'P'(pulados) e 'R'(roterizado) contam como 
não concluidos. 
Os pontos com status J devem compor a barra amarela. 
Os pontos com status F devem compor a barra verde. 
Caso tenha carga considerar status R de realizadas no lugar de finalizadas
Os outros pontos não concluidos não possuem barra,
ficam com a parte "branca" do gráfico.
<template>
	<div id='progresso-rota'>
		<b-progress :max="totalItens" height="25px" animated :title='`${(itensJ +itensF ).toFixed(0)}|${totalItens}`'>
			<b-progress-bar :value="itensF" variant="success">
			</b-progress-bar>
			<b-progress-bar :value='itensJ' variant="warning">
			</b-progress-bar>
			<div class='percentil-progresso'>
				{{ calculaPercentil() }}
			</div>
		</b-progress>
	</div>
</template>

<script>
export default {
	props: {
		rota: {
			required: true
		}
	},
	data() {
		return {
			totalItens: 0,
		}
	},
	methods: {
		/**
		 * @description função que calcula a % já concluído na rota.
		 * @returns {string} percentagem da rota já realizada
		 */
		calculaPercentil() {
			let retorno = (this.itensF + this.itensJ) / this.totalItens * 100;
			if (isNaN(retorno)) return '0.00%';
			return `${retorno.toFixed(2)}%`;
		}
	},
	mounted() {
		this.atualizaTotalItens();
	},
	computed: {
		itensF() {
			if (this.rota.cargas && this.rota.cargas.length > 0) {
				return this.rota.cargas.filter(r => r.status_flag === 'R').length;
			}
			return this.rota.itens_rota.filter(r => r.irstatus === 'F').length;
		},
		itensJ() {
			if (this.rota.cargas && this.rota.cargas.length > 0) {
				return this.rota.cargas.filter(r => r.status_flag === 'J').length;
			}
			return this.rota.itens_rota.filter(r => r.irstatus === 'J').length;
		},
	},
	methods: {
		atualizaTotalItens() {
			if (this.rota.cargas && this.rota.cargas.length > 0) {
				this.totalItens = this.rota.cargas.length;
			} else {
				this.totalItens = this.rota.itens_rota.length;
			}
		},
		calculaPercentil() {
			let totalConcluido = this.itensF + this.itensJ;
			let retorno = (totalConcluido / this.totalItens) * 100;
			if (isNaN(retorno)) return '0.00%';
			return `${retorno.toFixed(2)}%`;
		}
	},
	watch: {
		'rota.cargas': function () {
			this.atualizaTotalItens();
		},
		'rota.itens_rota': function () {
			this.atualizaTotalItens();
		}
	}
}
</script>

<style lang="scss" scoped>
#progresso-rota {
	.percentil-progresso {
		position: absolute;
		top: 50%;
		left: 43%;
		font-size: 14px;
	}

	.progress-bar {
		color: black !important;
		font-size: 13px;
	}
}
</style>
