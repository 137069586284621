<template>
    <panelEagle id="detalhesTelemetria" :loading="loadingPanel">
        <div class="col-sm-12 nopadding ">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage 
                        :icon="mdiCarCruiseControl" 
                        titulo="Detalhes telemetria">
                    </tituloPage>
                </div>
                <div class="col-sm-6 divButtonsBasic divDontPrint">
                    <basicButtonsRelatoriosEIG
                        :loading="loadingExporta"
                        :disabled="$v.selectedVeiculos.$invalid"
                        @exportarRelatorio="exportarRelatorio" 
                        @gerarRelatorio="gerarRel"
                        :disabledDropdown='$v.dadosTelemetria.$invalid'>
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12"><hr></div>
                    <div class="col-sm-12 row nopadding mb-2">
                        <div class="col-sm-3 nopadding">
                            <inputSingleDay 
                                name="dataDetalhesTelemetria" 
                                :isObrigatorio="true"
                                opens='rigth'
                                @changeInput="changeData" >
                            </inputSingleDay>
                        </div>
                        <div class="col-sm-4 nopadding">
                            <selectAll
                                :isMultiple="true" 
                                nameForRadio="desperdicioCombustivelEmpresas" 
                                :hasSelectAll="true" 
                                :labels="[{indexDFH: 'EM', description: 'Empresas*'}]" 
                                :optionsArray="optionsArrayEmpresas" 
                                :selected="selectedEmpresa"
                                firstSelected="EM"
                                @changeSelect="changeEmpresa" >
                            </selectAll>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll
                                :loading="loadingButton"
                                :disabled="!selectedEmpresas.length"
                                ref="refVeiculos"
                                :isMultiple="false" 
                                nameForRadio="radiosVeiculosGrupoDC" 
                                :labels="labelsSelectVeiculos" 
                                :optionsArray="optionsSelectVeiculos" 
                                firstSelected="V" 
                                :hasSelectAll="false"
                                @changeSelect="changeVeiculos" >
                            </selectAll>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
            <div class="col-sm-12"><hr></div>
        </div>
        <!-- htmlDesperdicioDeCombustivel -->
        <tableRelatorio htmlRel="">
            <slot slot="thead">
                <tr class="cabecalhoTabela">
                    <th>Data</th>
                    <th>Temp. Óleo</th>
                    <th>Temp. Motor</th>
                    <th>Marcha</th>
                    <th>Nível T. 1</th>
                    <th>Nível T. 2</th>
                    <th>Cons. Comb.</th>
                    <th>RPM</th>
                    <th>Veloc.</th>
                    <th>Embr.</th>
                    <th>Freio</th>
                    <th>Estac.</th>
                    <th>Freio Motor</th>
                    <th>Limpa Vidros</th>
                    <th>Porta Fechada</th>
                    <th>Porta Trancada</th>
                    <th>Cinto Seg.</th>
                    <th>Farois</th>
                    <th>Motor Ligado</th>
                    <th>Rpm Verde</th>
                    <th>Rpm Azul</th>
                    <th>Rpm Verm.</th>
                    <th>Bang.</th>
                    <th>Exc. tempo parado</th>
                </tr>
            </slot>
            <slot slot="tbody">
                <template v-for="(a, index) in dadosTelemetria">
                    <tr class="backColorTdPDF" :key="index">
                        <td colspan="24" class="nopadding mt-2">
                            <span class="newbadge">
                                {{index}}
                            </span>
                        </td>
                    </tr>
                    <template v-for="(b, indexx) in dadosTelemetria[index]">
                        <tr :key="indexx+b['teldataevento']"
                            class="corpoTabela">
                            <td id="dataEvento">{{b['teldataevento']}}</td>
                            <td>{{b['teltemperaturaoleo']}}</td>
                            <td>{{b['teltemperaturamotor']}}</td>
                            <td>{{b['telmarcha']}}</td>
                            <td>{{b['telniveltanque1']}}</td>
                            <td>{{b['telniveltanque2']}}</td>
                            <td>{{b['telconsumo']}}</td>
                            <td>{{b['telrpm']}}</td>
                            <td>{{b['telvelocidade']}}</td>
                            <td>
                                <b-icon
                                    :class="b['telembreagem'] ? 'iconVerde h5 mb-0' : 'iconVermelho h5 mb-0'" 
                                    shift-v="0"
                                    shift-h="0"
                                    style="font-weight: bold;"
                                    :icon="b['telembreagem'] ? 
                                        'check2' : 'x'"/>
                            </td>
                            <td>
                                <b-icon
                                    :class="b['telfreio'] ? 'iconVerde h5 mb-0' : 'iconVermelho h5 mb-0'"  
                                    shift-v="0"
                                    shift-h="0"
                                    style="font-weight: bold;"
                                    :icon="b['telfreio'] ? 
                                        'check2' : 'x'"/>
                            </td>
                            <td>
                                <b-icon
                                    :class="b['telestacionario'] ? 'iconVerde h5 mb-0' : 'iconVermelho h5 mb-0'"  
                                    shift-v="0"
                                    shift-h="0"
                                    style="font-weight: bold;"
                                    :icon="b['telestacionario'] ? 
                                        'check2' : 'x'"/>
                            </td>
                            <td>
                                <b-icon
                                    :class="b['telfreiomotor'] ? 'iconVerde h5 mb-0' : 'iconVermelho h5 mb-0'"  
                                    shift-v="0"
                                    shift-h="0"
                                    style="font-weight: bold;"
                                    :icon="b['telfreiomotor'] ? 
                                        'check2' : 'x'"/>
                            </td>
                            <td>
                                <b-icon
                                    :class="b['tellimpavidros'] ? 'iconVerde h5 mb-0' : 'iconVermelho h5 mb-0'"  
                                    shift-v="0"
                                    shift-h="0"
                                    style="font-weight: bold;"
                                    :icon="b['tellimpavidros'] ? 
                                        'check2' : 'x'"/>
                            </td>
                            <td>
                                <b-icon
                                    :class="b['telportafechada'] ? 'iconVerde h5 mb-0' : 'iconVermelho h5 mb-0'"  
                                    shift-v="0"
                                    shift-h="0"
                                    style="font-weight: bold;"
                                    :icon="b['telportafechada'] ? 
                                        'check2' : 'x'"/>
                            </td>
                            <td>
                                <b-icon
                                    :class="b['telportatrancada'] ? 'iconVerde h5 mb-0' : 'iconVermelho h5 mb-0'"  
                                    shift-v="0"
                                    shift-h="0"
                                    style="font-weight: bold;"
                                    :icon="b['telportatrancada'] ? 
                                        'check2' : 'x'"/>
                            </td>
                            <td>
                                <b-icon
                                    :class="b['telcintoseguranca'] ? 'iconVerde h5 mb-0' : 'iconVermelho h5 mb-0'"  
                                    shift-v="0"
                                    shift-h="0"
                                    style="font-weight: bold;"
                                    :icon="b['telcintoseguranca'] ? 
                                        'check2' : 'x'"/>
                            </td>
                            <td>
                                <b-icon
                                    :class="b['telfarois'] ? 'iconVerde h5 mb-0' : 'iconVermelho h5 mb-0'"  
                                    shift-v="0"
                                    shift-h="0"
                                    style="font-weight: bold;"
                                    :icon="b['telfarois'] ? 
                                        'check2' : 'x'"/>
                            </td>
                            <td>
                                <b-icon
                                    :class="b['telmotorligado'] ? 'iconVerde h5 mb-0' : 'iconVermelho h5 mb-0'"  
                                    shift-v="0"
                                    shift-h="0"
                                    style="font-weight: bold;"
                                    :icon="b['telmotorligado'] ? 
                                        'check2' : 'x'"/>
                            </td>
                            <td>
                                <b-icon
                                    :class="b['telrpmverde'] ? 'iconVerde h5 mb-0' : 'iconVermelho h5 mb-0'"  
                                    shift-v="0"
                                    shift-h="0"
                                    style="font-weight: bold;"
                                    :icon="b['telrpmverde'] ? 
                                        'check2' : 'x'"/>
                            </td>
                            <td>
                                <b-icon
                                    :class="b['telrpmazul'] ? 'iconVerde h5 mb-0' : 'iconVermelho h5 mb-0'"  
                                    shift-v="0"
                                    shift-h="0"
                                    style="font-weight: bold;"
                                    :icon="b['telrpmazul'] ? 
                                        'check2' : 'x'"/>
                            </td>
                            <td>
                                <b-icon
                                    :class="b['telrpmvermelho'] ? 'iconVerde h5 mb-0' : 'iconVermelho h5 mb-0'"  
                                    shift-v="0"
                                    shift-h="0"
                                    style="font-weight: bold;"
                                    :icon="b['telrpmvermelho'] ? 
                                        'check2' : 'x'"/>
                            </td>
                            <td>
                                <b-icon
                                    :class="b['telbanguela'] ? 'iconVerde h5 mb-0' : 'iconVermelho h5 mb-0'"  
                                    shift-v="0"
                                    shift-h="0"
                                    style="font-weight: bold;"
                                    :icon="b['telbanguela'] ? 
                                        'check2' : 'x'"/>
                            </td>
                            <td>
                                <b-icon
                                    :class="b['telexcessotempoparado'] ? 'iconVerde h5 mb-0' : 'iconVermelho h5 mb-0'"  
                                    shift-v="0"
                                    shift-h="0"
                                    style="font-weight: bold;"
                                    :icon="b['telexcessotempoparado'] ? 
                                        'check2' : 'x'"/>
                            </td>
                        </tr>
                    </template>
                </template>
                <statusInformation 
                    v-if='$v.dadosTelemetria.$invalid' 
                    typeBar="tr" 
                    :statusBar="statusbar"
                    colspanForTd="24">
                </statusInformation>
            </slot>
        </tableRelatorio>
    </panelEagle>
</template>

<script lang="js">
import Vue from 'vue'
import { mdiCarCruiseControl } from '@mdi/js'
import {EmpresasService} from '@/Services/auth/Empresas.service'
import {HttpRequest} from '@/Services/auth/HttpRequest.Service'
import {FiltrosService} from '@/Services/filtros/filtros.Service'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name:'detalhesTelemetria',
    validations:{
        selectedEmpresas:{ required },
        selectedVeiculos:{ required },
        dadosTelemetria : { required },
    },
    components:{
        // baseIcon:require('@/components/Atom/Icon/BaseIcon.vue').default,
        'panelEagle'                : require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage'                : require('@/components/Atom/Header/Titulo').default,
        'basicButtonsRelatoriosEIG' : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
        'slideUpAndDown'            : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
        'inputSingleDay'            : require('@/components/Atom/Datas/InputSingleDay').default,
        'selectAll'                 : require('@/components/Atom/Select/SelectAll').default,
        'tableRelatorio'            : require('@/components/Atom/Table/TableRelatorio').default,
        'statusInformation'         : require('@/components/Atom/StatusInformation/StatusInformation').default,
    },

    data(){
        return{
            optionsArrayEmpresas: new EmpresasService().Get(),
            baseUri:'/performance/relatorios/detalhes/telemetria/',
            mdiCarCruiseControl:mdiCarCruiseControl,
            labelsSelectVeiculos:[
                {indexDFH: 'V', description: 'Veiculo*'},
            ],
            optionsSelectVeiculos: [],
            dataDC: '',
            selectedEmpresas: [],
            selectedVeiculos: '',
            dadosTelemetria : [],
            statusbar: 'info',
            loadingExporta:[false, false, false],
            loadingButton:false,
            loadingPanel:false,
            selectedEmpresa: [],
        }
    },

    methods:{
        ...mapGetters(['getMaster']),

        changeData(data){
            this.dataDC = data;
            this.dadosTelemetria = []
        },

        changeEmpresa(selecionados){
            this.$refs.refVeiculos.selectedItens = []
            this.selectedEmpresas = selecionados;
            this.dadosTelemetria = []
            this.dados_filtros();
        },
        changeVeiculos(veiculos){
            this.selectedVeiculos = veiculos;
            this.dadosTelemetria = []
        },
        async dados_filtros(){
            this.loadingButton = true
            var dados = [];
            dados = await new FiltrosService().dados_filtros(this.selectedEmpresas, ['V', 'GV'])
            this.optionsSelectVeiculos = dados.V;
            this.loadingButton = false
        },
        async gerarRel(){
            this.loadingPanel= true
            var objt = {
                data     : this.dataDC,
                clientes : this.selectedEmpresas,
                veiculo  : this.selectedVeiculos[0]
            }
            new HttpRequest().Post(this.baseUri+'gerar/relatorio', objt)
                .then(dados=>{
                    if(dados.code == 200){
                        this.dadosTelemetria = dados.data.dados
                        if(this.dadosTelemetria.length == 0){
                            this.statusbar = 'error'
                        }
                        this.loadingPanel = false
                    } else {
                        this.statusbar = 'error2'
                        this.loadingPanel = false
                    }
                })
        },
        async exportarRelatorio(tipo){
            switch (tipo) {
                case 'pdf': this.loadingExporta = [true, false, false]; break;
                case 'xls': this.loadingExporta = [false, true, false]; break;
                default:    this.loadingExporta = [false, false, true]; break;
            }
            var root =  process.env.VUE_APP_ROOT;
                var dados = await new HttpRequest()
                    .Post(this.baseUri+'exportar', 
                        {
                            'arrayDados':this.dadosTelemetria, 
                            'tipo':tipo
                        }
                    );
            window.open(root+'/'+dados.data.local);
            this.loadingExporta = [false, false, false]
        },
    },

    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optionsArrayEmpresas
        }
    }
})
</script>

<style lang="scss">
    #detalhesTelemetria{
        .iconVermelho{
            color: red;
        }
        .iconVerde{
            color: green;
        }
        .cabecalhoTabela{
            th{
                vertical-align: middle;
                text-align: center !important;
            }
        }
        .corpoTabela{
            td{
                vertical-align: middle;
                text-align: center;
                border: 1px solid #dee2e6;
            }
        }
        #dataEvento{
            width: 15% !important;
        }
    }
</style>