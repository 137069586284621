<!-- 
    * dados:
    NSR - 999999999 (Valor fixo)
    Qtd. reg tipo 2 - Valor da quantidade de registros do tipo 2
    Qtd. reg tipo 3 - Valor da quantidade de registros do tipo 3
    Qtd. reg tipo 4 - Valor da quantidade de registros do tipo 4
    Qtd. reg tipo 5 - Valor da quantidade de registros do tipo 5
    Tipo do registro - 9 (Valor fixo)
 -->
 <template>
    <div class="mt-4 col-12 nopadding">
        <tableRelatorio>
            <template slot="thead">
            </template>
            <template slot="tbody">
                <tr class="backColorTdPDF">
                    <td colspan="6" class="nopadding">
                        <span class="newbadge">
                            {{dados.descricao}}
                        </span>
                    </td>
                </tr>
                <tr class="trTdHeader">
                    <td>NSR</td>
                    <td>Qtd. reg tipo 2</td>
                    <td>Qtd. reg tipo 3</td>
                    <td>Qtd. reg tipo 5</td>
                    <td>Qtd. reg tipo 9</td>
                    <td>Tipo do registro</td>
                </tr>
                <tr class="paddingTd">
                    <td>{{dados.nsr}}</td>
                    <td>{{dados.qtdtipo2}}</td>
                    <td>{{dados.qtdtipo3}}</td>
                    <td>{{dados.qtdtipo5}}</td>
                    <td>{{dados.qtdtipo9}}</td>
                    <td>{{dados.tiporegistro}}</td>
                </tr>
            </template>
        </tableRelatorio>
    </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
    name:'registroTipo2',
    components:{
		tableRelatorio: require('@/components/Atom/Table/TableRelatorio').default,
    },
    props:{
        dados:{
            type: Object,
            required: true,
            default: function(){return {}}
        },
    },
})
</script>
    
<style lang="scss">
    
</style>