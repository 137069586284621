<template>
    <modalEagle @modalOpen="modalOpen" title="Integração" id="modal-integracoes">
        <slot slot="modalBody">
            <b-overlay :show="showOverlay" rounded="sm">
                <b-row>
                    <div class="col-sm-12 nopadding row">
                        <div class="col-sm-4 nopadding">
                            <selectAll ref="refSistemaIntegrado" nameForRadio="selectSistemaIntegrado" :isMultiple="false"
                                :optionsArray="sistemaIntegradorOpt" @changeSelect="changeSistema"
                                :labels="sistemaIntegradorLabel">
                            </selectAll>
                        </div>
                        <div class="col-sm-4 nopadding">
                            <selectAll ref="refMatriz" @changeSelect="changeMatriz" nameForRadio="selectMatriz"
                                :extraClassParent="'nopadding'" :isMultiple="false" :optionsArray="matrizOpt"
                                :labels="matrizLabel">
                            </selectAll>
                        </div>
                        <div class="col-sm-4 nopadding">
                            <inputSingleDay label="Data Referência" @changeInput="changeData" name="inputDataRota">
                            </inputSingleDay>
                        </div>
                    </div>
                </b-row>
                <b-row>
                    <b-col>
                        <b-table :items="rowsTableLog" :fields="columnsTableLog" :hidden="hiddenTableLog">

                        </b-table>
                    </b-col>
                </b-row>
            </b-overlay>
        </slot>
        <slot slot="modalFooter">
            <div style="color:red">{{ mensagem }}</div>
            <simpleButton :icon="mdiCloseThick" @click="cancelButton" text="Cancelar" event="click" type="red">
            </simpleButton>
            <simpleButton :disabled="$v.$invalid || showOverlay" :icon="mdiCheckBold" @click="integrar" text="Integrar"
                event="click" type="blue">
            </simpleButton>
        </slot>
    </modalEagle>
</template>
<script lang="js">

import Vue from 'vue'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { conectionError } from '@/Services/Helpers/swellHeper.js'
import { mdiCheckBold, mdiCloseThick } from '@mdi/js'
import { required } from 'vuelidate/lib/validators'

export default Vue.extend({
    components: {
        selectAll: require('../Select/SelectAll').default,
        modalEagle: require('../Modal/ModalEagle').default,
        inputSingleDay: require('../Datas/InputSingleDay').default,
        simpleButton: require('../../../components/Atom/Buttons/SimpleButton').default,
    },

    props: {
        empresaValue: {
            type: [String, Number],
            required: true,
            default: ''
        }
    },

    validations: {
        empresa: { required },
        sistema: { required },
        data: { required },
    },

    data() {
        return {
            url: '/roteirizador/rota/manual/modal/integracoes',
            mdiCloseThick: mdiCloseThick,
            mdiCheckBold: mdiCheckBold,
            showOverlay: false,
            sistemaIntegradorLabel: [{ indexDFH: 'II', description: 'Sistema' }],
            sistemaIntegradorOpt: [
                { value: 'I', description: 'Interfoc - Coletas' },
                { value: 'GR', description: 'Interfoc - Grupos de Resíduos' },
                { value: 'consinco', description: 'Consinco - Importar Pedidos' },
            ],
            dadosEmpresas: [],
            matrizOpt: [],
            matrizLabel: [{ indexDFH: 'E', description: 'Filial' }],
            codigoempresa: '',
            empresa: null,
            sistema: null,
            data: '',
            mensagem: '',
            columnsTableLog: [
                {
                    key: 'status'
                },
                {
                    key: 'mensagem'
                }
            ],
            rowsTableLog: [],
            hiddenTableLog: true
        }
    },

    watch: {
        empresaValue(value, old) {
            if (value != old) {
                this.resetarVariaveis()
            }
            this.codigoempresa = value
        }
    },

    methods: {
        cancelButton() {
            this.$bvModal.hide('modal-integracoes')
            this.rowsTableLog = []
            this.hiddenTableLog = true
        },

        changeData(data) {
            this.data = data
        },

        changeSistema(value) {
            if (value && value.length) {
                this.sistema = value[0]
            } else {
                this.sistema = null
            }
        },

        changeMatriz(value) {
            if (value && value.length) {
                this.setEmpresa(value[0])
            } else {
                this.empresa = null
            }
        },

        modalOpen() {
            this.resetarVariaveis()
            this.buscarDados()
        },

        resetarVariaveis() {
            this.empresa = null
            this.mensagem = ''
            this.data = ''
            this.rowsTableLog = []
        },

        setEmpresa(codigo) {
            let filtro = this.dadosEmpresas.filter(e => e.clcodigo == codigo)
            this.empresa = filtro && filtro.length ? filtro[0] : null
        },

        setMatrizOpt() {
            this.matrizOpt = this.dadosEmpresas.map(e => {
                return {
                    'value': e.clcodigo,
                    'description': e.clfantasia
                }
            })
        },

        async buscarDados() {
            this.showOverlay = true
            await new HttpRequest()
                .Get(this.url)
                .then(({ data, status, code }) => {
                    if (status && data && data.empresas) {
                        this.dadosEmpresas = data.empresas
                        this.setMatrizOpt()
                    }
                })
                .catch((e) => {
                    conectionError()
                })
                .finally(() => {
                    this.showOverlay = false
                })
        },

        async integrar() {
            this.mensagem = ''
            if (this.empresa && this.data && this.sistema) {
                this.showOverlay = true
                // let e = this.codigoempresa
                let e = this.empresa.clcodigo
                let m = this.empresa.clcodigointerfocmatriz
                let url = ''
                if (this.sistema == 'GR') {
                    url = `/interfoc/importar/tipos/coletas/${e}/${m}`
                }
                else if (this.sistema == 'I') {
                    let f = this.empresa.clcodigointerfocfilial
                    let data = this.data.split('/').reverse().join('')
                    url = `/interfoc/importar/pontos/${e}/${m}/${f}/${data}`
                }
                else if (this.sistema == 'consinco') {
                    let data = this.data.replace(/\//g, '-')
                    url = `/consinco/importar/pedidos/${data}/${e}`
                }
                try {
                    var dados = await new HttpRequest().Get(url)
                    let retornoCorreto = typeof dados.data != 'string'
                    if (dados.code == 200 && dados.status && retornoCorreto) {
                        this.hiddenTableLog = false
                        for (let k in dados.data.error) {
                            this.rowsTableLog.push({
                                status: "Erro de importação",
                                mensagem: dados.data.error[k]
                            })
                        }
                        for (let k in dados.data.success) {
                            this.rowsTableLog.push({
                                status: "Ok",
                                mensagem: dados.data.success[k]
                            })
                        }
                        this.$emit('rechargeList')
                        // this.$bvModal.hide('modal-integracoes')
                    } else if (dados.code == 400) {
                        this.mensagem = dados.data.message
                    } else {
                        this.mensagem = "Ocorreu um erro não esperado!"
                        conectionError()
                    }
                } catch {
                    conectionError()
                } finally {
                    this.showOverlay = false
                }
            }
        }
    },
})
</script>