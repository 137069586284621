<template>
    <panelEagle 
        id="cadastroGrupoVeiculos"
        :loading="loadingPanel">
        <div class="col-sm-12 nopadding">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-4">
                    <tituloPage :icon="mdiCarMultiple" titulo="Grupo de veículos"></tituloPage>
                </div>
                <div class="col-sm-5 divDontPrint">
                    <alertSimple v-if="needShowAlert" 
                        :type="statusAlert" 
                        :isLoading="isLoading" 
                        :message="messageAlert" 
                        :hasClick="false">
                    </alertSimple>
                </div>
                <div class="col-sm-3 divButtonsBasic">
                    <basicButtonsCadastroSC
                        :disabled="$v.$invalid"
                        @salvarCadastro="salvarGrupoVeiculos" 
                        tipoCancela="button"
                        linkCancel="listarGrupoVeiculos">
                    </basicButtonsCadastroSC>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-3 nopadding divDontPrint">
                    <selectAll
                        :extraClass="{'border border-danger':$v.empresaSelecionada.$anyError}"
                        @close="$v.empresaSelecionada.$touch()"
                        @changeSelect="changeEmpresa" 
                        :isMultiple="false" 
                        nameForRadio="empresasCadGrupoVeiculo" 
                        :hasSelectAll="false" 
                        :labels="[{indexDFH: 'V', description: 'Empresa*'}]" 
                        :selected="empresasSelected" 
                        :optionsArray="optionsArrayEmpresas" 
                        firstSelected="EM">
                    </selectAll>
                </div>
                <div class="col-sm-3 nopadding">
                    <inputSimple 
                        :inputClass="{'border border-danger':$v.inputDescricao.$anyError}"
                        @blur="$v.inputDescricao.$touch()"
                        @changeInput="changeDescricao" 
                        :value="descricaoSelected" 
                        name="descricaoCadGrupoVeiculos" 
                        id="descricaoCadGrupoVeiculos" 
                        label="Descrição*">
                    </inputSimple>
                </div>
                <div class="col-sm-3 nopadding">
                    <selectAll
                        :loading="loadingButtonVeiculo"
                        :extraClass="{'border border-danger':$v.veiculosSelecionados.$anyError}"
                        @close="$v.veiculosSelecionados.$touch()"
                        @changeSelect="changeVeiculos" 
                        ref='selectVeiculo'
                        :isMultiple="true" 
                        nameForRadio="empresasListGrupoVeiculo" 
                        :hasSelectAll="true" 
                        :labels="[{indexDFH: 'V', description: 'Veículos*'}]" 
                        :selected="veiculosSelected" 
                        :optionsArray="optionsArrayVeiculos" 
                        firstSelected="EM"
                        :disabled="$v.empresaSelecionada.$invalid">
                    </selectAll>
                </div>
                <div class="col-sm-3 nopadding">
                    <inputColor 
                        @changeInput="changeColor" 
                        :value="corDoGrupo" 
                        label="Cor" 
                        name="colorCadGrupoVeiculos" 
                        id="colorCadGrupoVeiculos">
                    </inputColor>
                </div>
            </div>
            <div class="col-sm-12 nopadding">
                <requiredFields></requiredFields>
            </div>
            <div class="col-sm-12"><hr></div>
            <div id="app">
                <modalEagle
                    :hardToClose="true"
                    :hideHeaderClose="true"
                    id="confirmaDesvinculacao"
                    @cancelButton="cancelButtonTodos"
                    @confirmButton="confirmButtonTodos" 
                    title="Conflito de grupo de veículo" >
                    <slot
                        v-if="cont < 11"
                        slot="modalBody">
                        <tableListagem
                            :data = 'placaConflito'
                            :titles ='titles'>
                            <template #acoes="row"
                                :id="String(row.scope)">
                                <div class="col-sm-12 row nopadding">
                                    <div class="col-sm-6 nopadding">
                                        <simpleButton 
                                            :id="String(row.index)"
                                            @click="cancelButton(row)" 
                                            :icon="mdiCloseThick" 
                                            text="Cancelar" 
                                            event="click" 
                                            type="red"
                                            width='95%'
                                            class="pl-1">
                                        </simpleButton>
                                    </div>
                                    <div class=" col-sm-6 nopadding">
                                        <simpleButton 
                                            :id="String(row.index)"
                                            @click="confirmButton(row)" 
                                            :icon="mdiCheckBold" 
                                            text="Confirmar" 
                                            event="click" 
                                            type="blue"
                                            width='95%'
                                            class="pl-1">
                                        </simpleButton>
                                    </div>
                                </div>
                            </template>
                        </tableListagem>
                    </slot>
                    <slot slot="modalFooter">
                    </slot>
                    <slot 
                        v-if="cont > 10"
                        slot="modalBody">
                        <div class="col-sm-12 nopadding">
                            Os veículos estão associados a algum grupo:
                        </div>
                        <div class="col-sm-12 nopadding">
                            Deseja desassociá-los? 
                        </div>
                    </slot>
                    <slot slot="modalFooter">
                    </slot>
                </modalEagle>
            </div>
        </div>
    </panelEagle>
</template>

<script lang="js">
import Vue from 'vue'
import {mdiCarMultiple, mdiCloseThick, mdiCheckBold } from '@mdi/js'
import {EmpresasService} from '../../../../Services/auth/Empresas.service'
import {HttpRequest} from '../../../../Services/auth/HttpRequest.Service'
import { required, requiredIf } from 'vuelidate/lib/validators';
import {FiltrosService} from '../../../../Services/filtros/filtros.Service'
import { conectionError } from '@/Services/Helpers/swellHeper'
export default Vue.extend({
	name:'CadastroGrupoVeiculos',
	components:{
		'tituloPage'             : require('@/components/Atom/Header/Titulo').default,
		'panelEagle'             : require('@/components/Atom/Panel/PanelEagle').default,
		'modalEagle'             : require('@/components/Atom/Modal/ModalEagle').default,
		'selectAll'              : require('@/components/Atom/Select/SelectAll').default,
		'inputColor'             : require('@/components/Atom/Inputs/InputColor').default,
		'inputSimple'            : require('@/components/Atom/Inputs/InputSimple').default,
		'alertSimple'            : require('@/components/Atom/Alerts/AlertSimple').default,
		'tableListagem'          : require('@/components/Atom/Table/TableListagem').default,
		'requiredFields'         : require('@/components/Atom/Footer/RequiredFields').default,
		'simpleButton'           : require('@/components/Atom/Buttons/SimpleButton.vue').default,
		'basicButtonsCadastroSC' : require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
	},

	validations:{
		inputDescricao: {
			required
		},

		empresaSelecionada: {
			required
		},

		veiculosSelecionados: {
			required,
		},
	},

	data(){
		return{
			mdiCheckBold:mdiCheckBold,
			mdiCloseThick:mdiCloseThick,
			mdiCarMultiple:mdiCarMultiple,
			loadingButtonVeiculo:false,
			optionsArrayEmpresas : new EmpresasService().Get(),
			optionsArrayVeiculos:[],
			empresasSelected: [],
			veiculosSelected: [],
			descricaoSelected: '',
			inputDescricao: '',
			inputColor: '',
			veiculosSelecionados: '',
			empresaSelecionada: '',
			grupoPlacas: [],
			// variaveis para o alert
			statusAlert: 'blue',
			messageAlert: '',
			needShowAlert: false,
			isLoading: false,
			// variaveis de informações do grupo
			corDoGrupo: '',
			codigoVeiculos: [],
			taOuNao: [],
			loadingPanel:false,
			placaConflito: [],
			cont: '',
			codigoCancelar: [],
			titles :[
				{'number':'veplaca'   , 'name':'Veículo'},
				{'number':'gvdescricao'  , 'name':'Grupo do Veículo'},
			],
			bloqueiaBotao: false,
		}
	},

	methods:{
		async changeEmpresa(value){
			this.loadingButtonVeiculo = true
			this.$refs.selectVeiculo.clearAll()
			this.empresaSelecionada = value[0];
			await new FiltrosService().dados_filtros(value, ['V']).then(data=>{
				this.optionsArrayVeiculos =  data.V
			})
			this.loadingButtonVeiculo = false
		},

		async changeVeiculos(veiculos){
			this.veiculosSelecionados = veiculos;
			this.consultaVeiculo()
		},

		changeDescricao(descricao){
			this.inputDescricao = descricao;
		},

		changeColor(color){
			this.inputColor = color;
		},

		async confirmButton(codigo){
			this.loadingPanel = true
			var codigoPlacas  = []
			var codigoExcluir = []
			codigoExcluir = codigo.row.vecodigo
			var param = {
				'codigo': codigoExcluir,
			}
			var dados = await new HttpRequest()
				.Post('/telemetria/cadastros/grupos/veiculos/alterar', param);
			this.placaConflito.forEach(el => {
				codigoPlacas.push(el.vecodigo)
			});
			var contar = codigoPlacas.indexOf(codigo.row.vecodigo)
			codigoPlacas.splice(contar, 1)
			this.placaConflito.splice(contar, 1)
			this.loadingPanel = false
			if(codigoPlacas.length == 0){
				this.$bvModal.hide('confirmaDesvinculacao')
			}
		},

		cancelButton(cancel){
			var codigoPlacas = []
			var indice = this.veiculosSelecionados.indexOf(cancel.row.vecodigo)
			this.veiculosSelecionados.splice(indice, 1)
			this.placaConflito.forEach(el => {
				codigoPlacas.push(el.vecodigo)
			});
			var contar = codigoPlacas.indexOf(cancel.row.vecodigo)
			codigoPlacas.splice(contar, 1)
			this.placaConflito.splice(contar, 1)
			this.filterVeiculos(this.veiculosSelecionados)

			if(codigoPlacas.length == 0){
				this.$bvModal.hide('confirmaDesvinculacao')
			}
		},

		async confirmButtonTodos(){
			var param = {
				'codigo': this.codigoCancelar,
			}
			var dados = await new HttpRequest()
				.Post('/telemetria/cadastros/grupos/veiculos/alterar', param);
			this.$bvModal.hide('confirmaDesvinculacao')

		},

		cancelButtonTodos(){
			var semConflito = this.veiculosSelecionados.filter(el=>{
				return !this.codigoCancelar.includes(el)
			})
			this.veiculosSelecionados = semConflito
			this.filterVeiculos(this.veiculosSelecionados)
		},

		filterVeiculos(vselect){
			this.$refs.selectVeiculo.selectedItens =  this.optionsArrayVeiculos.filter(ve=>{
				return vselect.includes(ve.value)
			})
		},

		async salvarGrupoVeiculos(){
			this.loadingPanel = true
			var param = {
				'gvempresa'          : this.empresaSelecionada,
				'gvdescricao'        : this.inputDescricao,
				'veplacas'           : this.veiculosSelecionados,
				'veplacasExistentes' : this.codigoVeiculos,
				'cor'                : this.inputColor
			}
			var deuCerto = {
				'success': this.success
			}
			if(this.gvcodigo){
				param['gvcodigo'] = this.gvcodigo
			}
			var data = await new HttpRequest()
				.Post('/telemetria/cadastros/grupos/veiculos/salvar', param);
			if(data.data.success){
				this.$router.push({name:'listarGrupoVeiculos'})
			} else {
				conectionError()
			}
			this.loadingPanel = false
		},

		async editarGrupoVeiculos(codigo){
			this.loadingPanel = true
			var dados = await new HttpRequest()
				.Post('/telemetria/cadastros/grupos/veiculos/editar', 
					{
						'gv': codigo
					});
			var grupoVeiculos      = dados.data.grupo 
			this.corDoGrupo        = grupoVeiculos.gvcor
			this.grupoPlacas       = dados.data.placa
			this.gvcodigo          = grupoVeiculos.gvcodigo
			this.descricaoSelected = grupoVeiculos.gvdescricao
			this.empresasSelected  = [{'value':grupoVeiculos.clcodigo, 'description':grupoVeiculos.clfantasia}]
			this.codigoEmpresa     = grupoVeiculos.clcodigo
			this.veiculosSelected  = dados.data.placa
			var existentes         = this.grupoPlacas.forEach(el => {
				this.codigoVeiculos.push(el.value)
			});
			this.loadingPanel      = false
		},

		async consultaVeiculo(){
			this.loadingPanel=true
			var param = {
				'codigo':      this.veiculosSelecionados,
				'codigoGrupo': [this.gvcodigo],
			}
			var dados = await new HttpRequest()
				.Post('/telemetria/cadastros/grupos/veiculos/consultaVeiculo', param);
			var condicao = dados.data.consulta.forEach(el => {
				this.taOuNao.push(el.vegrupoveiculo)
			});
			var cancelar = dados.data.consulta.forEach(el => {
				this.codigoCancelar.push(el.vecodigo)
			});

			// Criação do cont para saber se o número de conflitos é maior que 10
			this.cont = 0
			for (let index = 0; index < this.taOuNao.length; index++) {
				if(this.taOuNao[index] != null){
					this.cont++
				} 
			}
			if(this.cont > 0){
				this.placaConflito = dados.data.consulta
				this.$bvModal.show('confirmaDesvinculacao')
			}
			this.taOuNao = []
			this.loadingPanel=false
		},

	},

	mounted(){
		if(this.$route.params.id){         
			this.editarGrupoVeiculos(this.$route.params.id)
		}
	}
    
})

</script>

<style lang="scss">
    #cadastroGrupoVeiculos{

    }
</style>