<template>
    <section class="telemetry">
        <!-- Loader -->
        <div v-if="isLoading" class="telemetry__loader">
            <b-spinner label="Carregando dados de telemetria..." />
        </div>

        <!-- Erro -->
        <div v-else-if="hasError || !plate">
            <b-alert variant="danger" show>
                <p v-if="!plate"> Veículo não selecionado. </p>
                <p v-else-if="!interval || !interval.isValid"> Intervalo invalido. </p>
                <div v-else> 
                    <p> Erro ao carregar os dados de telemetria. </p>
                    <b-button @click="updateTelemetryData"> Tentar Novamente </b-button> 
                </div>
            </b-alert>
        </div>

        <!-- Gráfico -->
        <div v-else class="telemetry__content">
            <telemetry-list 
                :event-codes="filteredEventCodes" 
                @show-location="(latlog) => $emit('show-location', latlog)"
            />
            <div class="telemetry__chart-container">
                <telemetry-chart :data="data" :options="options"/>
            </div>
        </div>
        
    </section>
</template>

<script lang="ts" setup>
import { ChartEvent, ChartOptions, LegendItem } from 'chart.js'
import { DateTime, Interval } from 'luxon'
import { computed, ComputedRef, inject, onMounted, ref, Ref, watch, provide } from 'vue'

import { EventCodes, TelemetryData, TelemetryEvent} from '@/Services/vehicle/telemetry.service'
import { createEventsConfig, AllowedChartDataset, eventColors } from './helpers/events'
import TelemetryChart from './TelemetryChart.vue'
import TelemetryList from './TelemetryList.vue'

defineEmits<{
    'show-location': (latlog: [number, number]) => void
}>()

const props = defineProps<{
    plate: { type: String, required: true },
}>()

const plateRef = inject('plate') as Ref<string>    
const telemetryData = inject('telemetryData') as Ref<TelemetryData>
const hasError = inject('telemetryHasError') as Ref<boolean>
const interval = inject('telemetryInterval') as Ref<Interval>
const isLoading = inject('telemetryIsLoading') as Ref<boolean>
const updateTelemetryData = inject('updateTelemetryData') as () => void

/**  Códigos dos eventos a serem exibidos no gráfico e na lista*/
const filteredEventCodes = ref<EventCodes[]>(Object.keys(EventCodes).map(Number))

// #region Chart options
/**
 * Formata os 'ticks' do eixo X para o formato 'dd/MM HH:mm:ss'
 * @param val - O valor a ser formatado
 * @returns Label do 'tick' formatado
 */
const formatXAxisTick = (val: number | string): string => {
    return DateTime.fromMillis(val as number)
        .setZone('America/Sao_Paulo')
        .toFormat('dd/MM HH:mm:ss')
}

/**
 * Define o valor mínimo do eixo X apenas se não houver dados
 * Atualmente, o valor mínimo é dado pelo primeiro evento encontrado,
 * para que o gráfico não fique com um vazio no início
 */
const xAxisMin = computed(() => {
    return !hasAnyData.value || !filteredEventCodes.value.length 
        ? interval.value.start.toMillis() 
        : undefined
})

/**
 * Define o valor máximo do eixo X apenas se não houver dados
 * Atualmente, o valor máximo é dado pelo último evento encontrado,
 * para que o gráfico não fique com um vazio no fim
 */
const xAxisMax = computed(() => {
    return !hasAnyData.value || !filteredEventCodes.value.length 
        ? interval.value.end.plus({ minutes: 1 }).toMillis() 
        : undefined
})

/**
 * Informa se há algum dado a ser exibido no gráfico
 */
const hasAnyData = computed(() => {
    return telemetryData.value && Object.values(telemetryData.value)
        .some((events) => events.length > 0)
})

/**
 * Informa se há dados para o evento informado
 * @param eventCode - Código do evento
 */
const hasEventDataDisplayed = (eventCode: EventCodes): boolean => {
    // @ts-ignore
    return !!(filteredEventCodes.value.includes(eventCode) && telemetryData.value[eventCode]?.length > 0)
}

/**
 * Informa se o evento de velocidade está sendo exibido
 */
const hasSpeedDataDisplayed = computed(() => hasEventDataDisplayed(EventCodes.Speed))

/**
 * Informa se o evento de RPM está sendo exibido
 */
const hasRPMDataDisplayed = computed(() => hasEventDataDisplayed(EventCodes.RPM))

/**
 * Callback chamado ao clicar em um item da legenda
 * @param event - Evento do click
 * @param legendItem - O item de legenda clicado
 */
const onLegendClick = (e: ChartEvent, legendItem: LegendItem): void => {
    // @ts-ignore
    const chart: Chart = e.chart;
    
    // Exibe/esconde os dados do dataset clicado
    const dataset = chart.data.datasets[legendItem.datasetIndex];
    dataset.hidden = !dataset.hidden;

    // Atualiza a lista de eventos filtrados
    filteredEventCodes.value = chart.data.datasets
        .map((_, index) => (index + 1) as EventCodes)
        .filter((_, index) => !chart.data.datasets[index].hidden)
    
    // Atualiza o gráfico
    chart.update();
}
    interface ZoomRange {
        min: number | null;
        max: number | null;
    }

    const zoomRange = ref<ZoomRange>({ min: null, max: null });
    provide('zoomRange', zoomRange);


/**
 * Opções do gráfico
 * Estas opções são passadas diretamente para o componente 'Chart'
 */
const options: ComputedRef<ChartOptions> = computed(() => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            onClick: onLegendClick,
            labels: {
                font: { size: 10 },
                usePointStyle: true,
            }
        },
        
        zoom: {
            zoom: {
                drag: {
                    enabled: true,
                },
                wheel: {
                    enabled: true,
                },
                pinch: {
                    enabled: true
                },
                mode: 'x',
                onZoomComplete({ chart }) {
                    const xAxis = chart.scales.x;
                    zoomRange.value = {
                        min: xAxis.min ?? null,
                        max: xAxis.max ?? null
                    };
                }
            }
        },
    },
    scales: {
        x: {
            type: 'time',
            time: {  tooltipFormat: 'DD HH:mm:ss' },
            min: xAxisMin.value,
            max: xAxisMax.value,
            ticks: {
                callback: formatXAxisTick,
                font: { size: 10 },
            }
        },
        y: {
            type: 'linear',
            display: false,
            position: 'left',
            max: 15
        },
        y1: {
            type: 'linear',
            display: hasSpeedDataDisplayed.value,
            position: 'left',
            ticks: { color: eventColors[EventCodes.Speed] },
            grid: { drawOnChartArea: hasSpeedDataDisplayed.value }
        },
        y2: {
            type: 'linear',
            display: hasRPMDataDisplayed.value,
            position: 'right',
            ticks: { color: eventColors[EventCodes.RPM] },
            grid: { drawOnChartArea: !hasSpeedDataDisplayed.value && hasRPMDataDisplayed.value }
        },
    }
}))
// #endregion

// #region Dataset
const eventsConfig = createEventsConfig();
const data = ref<{ datasets: AllowedChartDataset[] }>({
    datasets: [],
});

/**
 * Map the telemetry event to the chart dataset
 * // TODO: Explicit return type 
 */
 const mapTelemetryEventToChartDataset = (key: string) => {
    return telemetryData.value[key]?.map((data: TelemetryEvent) => ({
        x: data.dateTime,
        y: parseInt(key) <= 2 ? data.value : parseInt(key)
    })) ?? []
}

/**
 * Map the event config to the chart dataset
 */
 const mapEventConfigToChartDataset = ([key, event]: [string, Partial<AllowedChartDataset>]): AllowedChartDataset => {
    return {
        ...event,
        data: mapTelemetryEventToChartDataset(key)
    }
}

/**
 * Mount dataset, based on the events exported in events.ts,
 * adding the data from the datasets in the telemetryData
 */
const mountDataset = (): AllowedChartDataset[] => {
    return Object.entries(eventsConfig)
        .map(mapEventConfigToChartDataset)
};

const updateDataset = () => {
    data.value = {
        datasets: mountDataset()
    }
}

watch(isLoading, (newValue, oldValue) => {
    if (!newValue && oldValue) updateDataset()
}, { immediate: true });

watch(() => props.plate, (newPlate, oldPlate) => {
    plateRef.value = newPlate;
    if (newPlate !== oldPlate) updateTelemetryData()
}, { immediate: true });

onMounted(() => {
    if (!hasAnyData.value) {
        updateTelemetryData()
    }
})

// #endregion
</script>

<style lang="scss" scoped>
.telemetry {
    display: flex;
    flex-direction: column;
    background-color: rgb(245, 245, 245);
    max-height: 25vh;
    min-height: 250px;

    &__loader {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__content {
        flex-grow: 1;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-start;
        overflow-y: hidden;
        max-width: 100%;
    }

    &__chart-container {
        flex: 1 1;
        overflow: auto hidden;
        min-width: 600px;
    }
}
</style>