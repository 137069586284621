<template>
    <panelEagle id="motoristaEconomico" :loading="loadingPanel">
        <div class="col-sm-12 nopadding ">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-4">
                    <tituloPage 
                        :icon="mdiAccountCashOutline" 
                        titulo="Motorista econômico">
                    </tituloPage>
                </div>
                <div class="col-sm-8 divButtonsBasic divDontPrint">
                    <basicButtonsRelatoriosEIG
                        :loading="relatorios.det.selecionado ? 
                            filtros.det.exportar : filtros.res.exportar"
                        :disabled="!filtrosValidos"
                        @exportarRelatorio="exportarRelatorio" 
                        @gerarRelatorio="gerarRel"
                        :disabledDropdown='!temDados'>
                        <simpleButton
                            id="bt1"
                            :key="'bt11'"
                            :icon='relatorios.det.selecionado ? mdiCheckBold : ""'
                            text="Detalhado"
                            event="click"
                            :type='relatorios.det.selecionado ? "green" : "grey"'
                            width="105px"
                            extraClass="botaoTipoRel"
                            @click="mudaFormatoRel"
                        />
                        <simpleButton
                            id="bt2"
                            :key="'bt22'"
                            :icon='relatorios.res.selecionado ? mdiCheckBold : ""'
                            width="105px"
                            extraClass="botaoTipoRel"
                            :type='relatorios.res.selecionado ? "green" : "grey"'
                            text="Resumido"
                            event="click"
                            @click="mudaFormatoRel"
                        />
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <slideUpAndDown
                v-show="relatorios.det.selecionado">
                <slot slot="filters">
                    <div class="col-sm-12"><hr></div>
                    <div class="col-sm-12 row nopadding mb-2">
                        <div class="col-sm-3 nopadding mt-2">
                            <inputRangeWithLimit
                                opens="right"
                                id='Intervalo'
                                name='Intervalo'
                                @changeInput='changeData'
                                :isObrigatorio='false'/>
                        </div>
                        <div class="col-sm-4 nopadding mt-2">
                            <selectAll
                                :isMultiple="true" 
                                nameForRadio="desperdicioCombustivelEmpresas" 
                                :hasSelectAll="true" 
                                :labels="[{indexDFH: 'EM', description: 'Empresas*'}]" 
                                :optionsArray="filtros.det.selectEmpresas.options" 
                                :selected="filtros.det.selectEmpresas.selected"
                                firstSelected="EM"
                                @changeSelect="changeEmpresa" >
                            </selectAll>
                        </div>
                        <div class="col-sm-3 nopadding mt-2">
                            <selectAll
                                ref="refColabs"
                                class="nopadding"
                                nameForRadio="selectColaboradores"
                                :value='filtros.det.selectColabs.dados'
                                :isMultiple="false"
                                :optionsArray="filtros.det.selectColabs.options"
                                :labels="[{indexDFH: 'C', description: 'Colaborador*'}]"
                                :loading="filtros.det.selectColabs.loading"
                                :hasSelectAll='false'
                                @changeSelect="changeColabs">
                            </selectAll>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
            <slideUpAndDown
                v-show="relatorios.res.selecionado">
                <slot slot="filters">
                    <div class="col-sm-12"><hr></div>
                    <div class="col-sm-12 row nopadding mb-2">
                        <div class="col-sm-3 nopadding mt-2">
                            <inputRangeWithLimit
                                opens="right"
                                id='Intervalo2'
                                name='Intervalo2'
                                @changeInput='changeDataRes'
                                :isObrigatorio='false'/>
                        </div>
                        <div class="col-sm-4 nopadding mt-2">
                            <selectAll
                                :isMultiple="true" 
                                nameForRadio="desperdicioCombustivelEmpresas2" 
                                :hasSelectAll="true" 
                                :labels="[{indexDFH: 'EM', description: 'Empresas*'}]" 
                                :optionsArray="filtros.res.selectEmpresas.options" 
                                :selected="filtros.res.selectEmpresas.selected"
                                firstSelected="EM"
                                @changeSelect="changeEmpresaRes" >
                            </selectAll>
                        </div>
                        <div class="col-sm-3 nopadding mt-2">
                            <selectAll
                                ref="refColabsRes"
                                class="nopadding"
                                nameForRadio="selectColaboradores2"
                                :value='filtros.res.selectColabs.dados'
                                :isMultiple="true"
                                :optionsArray="filtros.res.selectColabs.options"
                                :labels="[{indexDFH: 'C', description: 'Colaboradores*'}]"
                                :loading="filtros.res.selectColabs.loading"
                                :hasSelectAll='true'
                                @changeSelect="changeColabsRes">
                            </selectAll>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
            <div class="col-sm-12"><hr></div>
        </div>
        <div class="col-sm-12 nopadding">
            <tableRelatorio htmlRel="" v-show="relatorios.det.selecionado">
                <slot slot="thead">
                    <tr class="cabecalhoTabela">
                        <th>Associação</th>
                        <th>Desassociação</th>
                        <th>Km. rodados</th>
                        <th>Consumo combustível</th>
                        <th>Média</th>
                    </tr>
                </slot>
                <slot slot="tbody">
                    <template v-for="(dados, motorista) in relatorios.det.dados">
                        <tr v-if='true' class="backColorTdPDF" 
                            :key="motorista+'__badge'">
                            <td colspan="5" class="nopadding mt-2">
                                <span class="newbadge">
                                    {{motorista}}
                                </span>
                            </td>
                        </tr>
                        <template v-for="(val, placa) in dados">
                            <tr v-if='true' class="backColorTdPDF nopadding" 
                                :key="`${placa}__badge`">
                                <td colspan="5" class="nopadding">
                                    <span class="badgeSecundaria">
                                        {{placa}}
                                    </span>
                                </td>
                            </tr>
                            <template v-for="(linha, lkey) in val.dados">
                                <tr v-if='true' 
                                    :key="`${motorista+placa}__badge__${lkey}`">
                                    <td>{{linha.datahoraini}}</td>
                                    <td>{{linha.datahorafim}}</td>
                                    <td>{{linha.km}} Km</td>
                                    <td>{{linha.consumo}} L</td>
                                    <td>{{linha.media}} Km/L</td>
                                </tr>
                            </template>
                            <tr v-if='true'
                                :key="`${placa}_totalizador`"
                                class="corpoTabela fundoTotais">
                                <td colspan="2"></td>
                                <td>{{val.totais.totalkm}} Km</td>
                                <td>{{val.totais.totalconsumo}} L</td>
                                <td>{{val.totais.totalmedia}} Km/L</td>
                            </tr>
                        </template>
                    </template>
                    <statusInformation 
                        v-if='!relatorios.det.dados || !temDados' 
                        typeBar="tr" 
                        :statusBar="relatorios.det.info"
                        colspanForTd="5">
                    </statusInformation>
                </slot>
            </tableRelatorio>
            <tableRelatorio htmlRel="" v-show="relatorios.res.selecionado">
                <slot slot="thead">
                    <tr class="cabecalhoTabela">
                        <th>Veículo</th>
                        <th>Km. rodados</th>
                        <th>Consumo combustível</th>
                        <th>Média</th>
                    </tr>
                </slot>
                <slot slot="tbody">
                    <template v-for="(dados, motorista) in relatorios.res.dados">
                        <tr v-if='true' class="backColorTdPDF"
                            :key="motorista+'__badge'">
                            <td colspan="4" class="nopadding mt-2">
                                <span class="newbadge">
                                    {{dados.nome}}
                                </span>
                            </td>
                        </tr>
                        <template v-for="(registros, veiculo) in dados.dados">
                            <tr v-if='true' :key="`${motorista+veiculo}_badge`">
                                <td>{{registros.index}}</td>
                                <td>{{registros.totalkm}} Km</td>
                                <td>{{registros.totalconsumo}} L</td>
                                <td>{{registros.totalmedia}} Km/L</td>
                            </tr>
                        </template>
                        <tr v-if='true' :key="`${motorista}_totais`"
                            class="corpoTabela fundoTotais">
                            <td colspan="1"></td>
                            <td>{{dados.totais.totalkm}} Km</td>
                            <td>{{dados.totais.totalconsumo}} L</td>
                            <td>{{dados.totais.totalmedia}} Km/L</td>
                        </tr>
                    </template>
                    <statusInformation 
                        v-if='!relatorios.res.dados || !temDados' 
                        typeBar="tr" 
                        :statusBar="relatorios.res.info"
                        colspanForTd="4">
                    </statusInformation>
                </slot>
            </tableRelatorio>
        </div>
    </panelEagle>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { mdiAccountCashOutline, mdiCheckBold } from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
export default Vue.extend({
    name:'motoristaEconomico',
    components:{
        tituloPage:require('@/components/Atom/Header/Titulo').default,
        selectAll:require('@/components/Atom/Select/SelectAll').default,
        panelEagle:require('@/components/Atom/Panel/PanelEagle').default,
        tableRelatorio:require('@/components/Atom/Table/TableRelatorio').default,
        slideUpAndDown:require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
        inputRangeWithLimit:require('@/components/Atom/Datas/InputRangeWithLimit').default,
        statusInformation:require('@/components/Atom/StatusInformation/StatusInformation').default,
        basicButtonsRelatoriosEIG:require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
        simpleButton: require('@/components/Atom/Buttons/SimpleButton').default,
    },

    data(){
        return{
            baseUri:'/performance/relatorios/motorista/economico/',
            mdiAccountCashOutline:mdiAccountCashOutline,
            mdiCheckBold:mdiCheckBold,
            loadingPanel:false,
            filtros: {
                det: {
                    intervalo: '',
                    selectEmpresas: {
                        dados: [],
                        selected: [],
                        options: [],
                    },
                    selectColabs: {
                        dados: [],
                        selected: [],
                        options: [],
                        loading: false,
                    },
                    exportar:[false, false, false],
                },
                res: {
                    intervalo: '',
                    selectEmpresas: {
                        dados: [],
                        selected: [],
                        options: [],
                    },
                    selectColabs: {
                        dados: [],
                        selected: [],
                        options: [],
                        loading: false,
                    },
                    exportar:[false, false, false],
                },
            },
            relatorios: {
                det: {
                    dados: [],
                    info: 'info',
                    selecionado: true,
                },
                res: {
                    dados: [],
                    info: 'info',
                    selecionado: false,
                },
            }
        }
    },

    methods:{
        ...mapGetters(['getMaster']),

         mudaFormatoRel() {
            this.relatorios.det.selecionado = !this.relatorios.det.selecionado
            this.relatorios.res.selecionado = !this.relatorios.res.selecionado
        },

        changeData(value){
            this.auxChangeData('det', value)
        },

        changeDataRes(value){
            this.auxChangeData('res', value)
        },

        changeEmpresa(value){
            this.$refs.refColabs.selectedItens = []
            this.auxChangeEmpresa('det', value)
        },

        changeEmpresaRes(value){
            this.$refs.refColabsRes.selectedItens = []
            this.auxChangeEmpresa('res', value)
        },

        changeColabs(colaboradores){
            this.auxChangeColabs('det', colaboradores)
        },

        changeColabsRes(value){
            this.auxChangeColabs('res', value)
        },

        auxChangeEmpresa(tipo, empresas) {
            this.filtros[tipo].selectEmpresas.dados = empresas
            this.filtros[tipo].selectColabs.dados = []
            this.filtros[tipo].selectColabs.options = []
            this.relatorios[tipo].dados = []
            this.relatorios[tipo].info = 'info'
            this.dados_filtros(tipo)
        },

        auxChangeColabs(tipo, colaboradores) {
            this.filtros[tipo].selectColabs.dados = colaboradores
            this.relatorios[tipo].dados = []
            this.relatorios[tipo].info = 'info'
        },

        auxChangeData(tipo, data) {
            this.filtros[tipo].intervalo = data
            this.relatorios[tipo].dados = []
            this.relatorios[tipo].info = 'info'
        },

        async dados_filtros(tipo){
            if(this.filtros[tipo].selectEmpresas.dados.length) {
                this.filtros[tipo].selectColabs.options = []
                this.filtros[tipo].selectColabs.loading = true
                var dados = await new FiltrosService()
                    .dados_filtros(this.filtros[tipo].selectEmpresas.dados, ['C'])
                if(dados) {
                    this.filtros[tipo].selectColabs.options = dados.C
                }
                this.filtros[tipo].selectColabs.loading = false
            }
        },

        async gerarRel(){
            let tipo = this.relatorios.det.selecionado ? 'det' : 'res'
            this.loadingPanel= true
            this.relatorios[tipo].info = ''
            var body = {
                data: this.filtros[tipo].intervalo,
                clientes: this.filtros[tipo].selectEmpresas.dados,
                colabs: this.filtros[tipo].selectColabs.dados,
                tipoRelatorio: tipo == 'det' ? 'D' : 'R',
                tipoFiltro: 'C',
            }
            new HttpRequest().Post(this.baseUri+'gerar/relatorio', body)
                .then(dados=>{
                    if(dados.status && dados.data.dados){
                        if(dados.data.dados && Object.values(dados.data.dados).length) {
                            this.relatorios[tipo].dados = dados.data.dados
                            this.relatorios[tipo].info = ''
                        } else {
                            this.relatorios[tipo].dados = []
                            this.relatorios[tipo].info = 'error'
                        }
                    } else {
                        this.relatorios[tipo].info = 'error2'
                    }
                })
                .catch((e)=>{
                    this.loadingPanel = false
                })
                .finally((e) => {
                    this.loadingPanel = false
                })
        },

        async exportarRelatorio(tipo){
            let tipoRel = this.relatorios.det.selecionado ? 'det' : 'res'
            this.filtros[tipoRel].exportar = [tipo=='pdf', tipo=='xls', tipo=='csv']
            var body = {
                'arrayDados':this.relatorios[tipoRel].dados, 
                'tipoRelatorio':tipoRel == 'det' ? 'D' : 'R',
                'tipo':tipo,
                'data':this.filtros[tipoRel].intervalo
            }
            var dados = await new HttpRequest()
                .Post(this.baseUri+'exportar', body)
            if(dados.status) {
                window.open(process.env.VUE_APP_ROOT+'/'+dados.data.local)
            }
            this.filtros[tipoRel].exportar = [false, false, false]
        },
    },

    mounted() {
        let options = new EmpresasService().Get()
        this.filtros.det.selectEmpresas.options = options
        this.filtros.res.selectEmpresas.options = options
        if(!this.getMaster()){
            this.filtros.det.selectEmpresas.selected = options
            this.filtros.res.selectEmpresas.selected = options
        }
    },

    computed: {
        filtrosValidos() {
            if(this.relatorios.det.selecionado) {
                return this.filtros.det.selectColabs.dados.length > 0
            }
            if(this.relatorios.res.selecionado) {
                return this.filtros.res.selectColabs.dados.length > 0
            }
            return false
        },

        temDados() {
            let tipo = this.relatorios.det.selecionado ? 'det' : 'res'
            return Object.values(this.relatorios[tipo].dados).length
        }
    }
})
</script>
<style lang="scss">
    #motoristaEconomico{
        .cabecalhoTabela{
            th{
                vertical-align: middle;
                text-align: left !important;
            }
        }
        .corpoTabela{
            td{
                vertical-align: middle;
                text-align: left;
                border: 1px solid #dee2e6;
            }
        }
        .fundoTotais{
            td{
                background: #d0d0d0 !important;
            }
        }
    }
</style>
