<template>
    <panelEagle id='Modulos' :loading='carregando'>
        <div class="col-sm-12 nopadding row">
            <div class="col-sm-6">
                <tituloPage :icon='mdiCellphoneDock' titulo='Modelos módulos'></tituloPage>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 divDontPrint">
                <basicButtonsCadastrosEIN
                    :loading="loadingExporta"
                    @exportarCadastro="exportarCadastro"
                    :disabledDropdown='$v.dadosTabela.$invalid'
                    tipoNovo='button'
                    linkNew="cadastrarModulo">
                </basicButtonsCadastrosEIN>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <tableListagem
            :data = 'dadosTabela'
            :titles ='titles' 
            deleteEdit ='editStatus'
            @alterarStatus='alterarStatusCadastro'
            @editarCadastro='editarCadastro'>
        </tableListagem>
    </panelEagle>
</template>
<script>
import Vue  from 'vue'
import{mdiCellphoneDock } from '@mdi/js'
import { HttpRequest } from '../../../../Services/auth/HttpRequest.Service'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
export default Vue.extend({
	name: 'modulosModelos',
	components:{
		'panelEagle'               : require('@/components/Atom/Panel/PanelEagle').default,
		'tituloPage'               : require('@/components/Atom/Header/Titulo').default,
		'BasicButtonsCadastrosEIN' : require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
		'tableListagem'            : require('@/components/Atom/Table/TableListagem').default,
	},

	validations:{
		dadosTabela:{ required },
	},

	data(){
		return {
			loadingExporta:[false, false, false],
			mdiCellphoneDock:mdiCellphoneDock,
			titles :[
				{'number':'codigo'   , 'name':'Código'},
				{'number':'descricao', 'name':'Modelo'},
			],
			dadosTabela : [] ,
			id : '',
			carregando:false,
		}
	},
	methods:{
		...mapGetters(['getEditar']),
		async exportarCadastro(type){
			switch (type) {
				case 'pdf':
					this.loadingExporta = [true, false, false]
					break;
				case 'xls':
					this.loadingExporta = [false, true, false]
					break;
				case 'csv':
					this.loadingExporta = [false, false, true]
					break;
				default:
					this.loadingExporta = [false, false, false]
					break;
			}
			var tipo = {'tipo':type,
				'arraydados':this.dadosTabela}
			var root =  process.env.VUE_APP_ROOT;
			var exporta = await new HttpRequest()
				.Post('/administrativo/cadastros/modulos/pdf', tipo);
			window.open(root+'/'+exporta.data.dados);
			this.loadingExporta = [false, false, false]
		},
		editarCadastro(value){
			this.$router.push({name:'cadastrarModulo', params:{'id':value}})
		},
		alterarStatusCadastro(valor){
			let uri = '/administrativo/cadastros/modulos/status'
			new HttpRequest().Post(uri, {valor:Number(valor)})
				.then((data)=>{
					this.procuraModulos()
				})
		},
		procuraModulos(){
			this.carregando = true
			let uri = '/administrativo/cadastros/modulos/modulo'
			new HttpRequest().Post(uri).then((data)=>{
				this.dadosTabela = data.data.codigo
				this.carregando = false
			})
		}
	},
	mounted(){
		this.procuraModulos()
	}
})
</script>