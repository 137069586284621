<template>
    <tr>
        <td>Ponto não encontrado</td>
        <td>{{dadosPonto[0]}}</td>
        <td>{{dadosPonto[2]}}</td>
        <td class="">
            <simpleButton typeButton="a" title="Criar um novo ponto" :link="'#/telemetria/cadastros/pontos/cadastrar?cd='+dadosPonto[0]+'&cl='+dadosPonto[9]+'&de='+dadosPonto[2]" :icon="mdiPlus" type="light-green" width="100%"></simpleButton>
        </td>
    </tr>
</template>

<script lang="ts">
import Vue from 'vue'
import {mdiPlus } from '@mdi/js'
export default Vue.extend({
    props:{
        dadosPonto:{
            type: Array,
            required: true,
            default: function(){return []}
        }
    },
    data() {
        return {
            mdiPlus:mdiPlus,
        }
    },
    components:{
        'simpleButton': require('../../Buttons/SimpleButton').default,
    }    
})
</script>

<style lang="scss">
</style>