<template>
     <panelEagle id='cadastroJustificativas' :loading='loadingPanelEagle'>
        <div class="col-sm-12  nopadding">
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-6 row ">
                    <tituloPage titulo='Justificativas' 
                    :icon='mdiFileDocumentEdit'>
                    </tituloPage>
                </div>
                <div class="col-sm-6 row divDontPrint">
                    <basicButtonsCadastrosSC 
                    linkCancel="ListagemJustificativaJornada"
                    tipoCancela="button" 
                    :disabled='$v.$invalid'
                    @salvarCadastro="salvarCadastro">
                    </basicButtonsCadastrosSC>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 nopadding row">
               <div class="col-sm-4 nopadding">
                   <selectAll
                    nameForRadio="nameForRadio"
                    :isMultiple="false"
                    :hasSelectAll="false"
                    firstSelected ="EM"
                    :labels="[{'indexDFH':'EM', 'description':'Empresa*'}]"
                    :optionsArray="optEmpresas"
                    :selected="selectedEmpresa"
                    :extraClass   ="{'border border-danger':$v.empresaSelecionada.$anyError}"
                    @close        ="$v.empresaSelecionada.$touch()" 
                    @changeSelect='changeSelectEmpresa'
                    />
               </div>
               <div class="col-sm-4 nopadding">
                   <inputSimple
                    type="text"
                    label="Descrição*"
                    :inputClass='{"boder border-danger" : $v.valueDescricao.$anyError }'
                    :value="valueDescricao"
                    @blur='$v.valueDescricao.$touch()'
                    @changeInput="changeDescricao"
                    />
               </div>
            </div>
            <requiredFields/>
            <div class="col-sm-12"><hr></div>
        </div>
    </panelEagle>
</template>
<script>
import Vue from 'vue'
import { EmpresasService } from '@/Services/auth/Empresas.service';
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { required } from 'vuelidate/lib/validators'
import { mdiFileDocumentEdit } from '@mdi/js'
import { conectionError } from '@/Services/Helpers/swellHeper'
export default Vue.extend({
    name: 'CadastroJustificativasRoteirizador',
    components:{
        selectAll: require('@/components/Atom/Select/SelectAll').default,
        panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
        tituloPage: require('@/components/Atom/Header/Titulo').default,
        requiredFields: require('@/components/Atom/Footer/RequiredFields').default,
        inputSimple: require('@/components/Atom/Inputs/InputSimple').default,
        basicButtonsCadastrosSC: require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
    },
    validations:{
        empresaSelecionada : { required },
        valueDescricao     : { required },
    },

    data(){
        return {
            mdiFileDocumentEdit: mdiFileDocumentEdit,
            loadingPanelEagle : false,
            optEmpresas: new EmpresasService().Get(),
            url : '/controle/jornada/cadastros/justificativas/jornada/',
            selectedEmpresa    : [],
            empresaSelecionada : [],
            valueDescricao     : '',
            id                 : '',
        }
    },
    
    methods: {
        changeSelectEmpresa(empresa){
            this.empresaSelecionada = empresa
        },

        changeDescricao(descricao){
            this.valueDescricao = descricao
        },

        salvarCadastro(){
            this.loadingPanelEagle = true
            var obj = {
                'id' : this.id,
                'jjdescricao' : this.valueDescricao,
                'jjcodigoempresa' : this.empresaSelecionada[0],
            }
            new HttpRequest().Post(this.url+'salvar', obj)
                .then(dados=>{
                    if(dados.code == 201){
                        this.$router.push({name:'ListagemJustificativaJornada'})
                    } 
                    else{
                        conectionError()
                    }
                    this.loadingPanelEagle = false
            })
        },
    },
    
    async mounted(){
        if(this.$route.params.id != undefined){
            this.loadingPanelEagle = true
            this.id = this.$route.params.id
            new HttpRequest().Post(this.url+'busca/dados', {'id':this.id})
                .then(({data, status}) => {
                    if(status) {
                        this.valueDescricao = data.dados.jjdescricao
                        this.selectedEmpresa = [{
                            value: data.dados.jjcodigoempresa,
                            description: data.dados.clfantasia
                        }]
                    } else {
                        conectionError()
                    }
                }).catch((error) => {
                    conectionError()
                }).finally(() => {
                    this.loadingPanelEagle = false
                })
        }
    },
})
</script>

<style lang="scss">
    
</style>