<template>
    <panelEagle id='CadastroNovoUsuario' :loading="carregandoUser">
        <div class="col-sm-12 row nopadding">
            <div class="col-sm-6 row nopadding">
                <tituloPage titulo='Usuários' :icon='icone'>
                </tituloPage>
            </div>
            <div class='col-sm-6 row nopadding'>
                <BBCadastroSC :disabled='$v.$invalid || !cpfValido || quantidadeUsuariosContrato'
                    linkCancel='listarUsuarios' tipoCancela='button' @salvarCadastro='salvarCadastro' />
            </div>
            <div class="col-sm-12 nopadding">
                <hr>
            </div>
        </div>
        <div class='col-sm-12 row nopadding'>
            <div class="col-sm-12 row nopadding">
                <!-- Primeira Coluna -->
                <div class="col-sm-4 nopadding">

                    <div class="col-12 nopadding">
                        <inputSimple name='usunome' label='Nome*' id='usunome' :value='usuario.usunome' @changeInput="setNome"/>
                    </div>

                    <div class="col-sm-12 nopadding mt-2">
                        <inputSimple name='mailInput' label='Email*' :value='usuario.email'
                            :inputClass="{ 'border border-danger': $v.usuario.email.$anyError }"
                            @blur='$v.usuario.email.$touch()' @changeInput='v => usuario.email = v' />
                        <simpleAlert v-if="!$v.usuario.email.email" type='red' :isLoading='false' message='Email inválido'
                            :hasClick='false' />
                        <simpleAlert v-if="saveError" type='red' :isLoading='false' :message='saveError' :hasClick='true' />
                    </div>


                    <div class="col-sm-12 nopadding mt-2">
                        <inputSimple :inputClass='{ "boder border-danger": cpfValidationBorder }' id='cpf'
                            :value="usuario.usucpf" :hasMask="true" :mask="['NNN.NNN.NNN-NN']" label="CPF*"
                            @changeInput="setCpf" />
                    </div>

                    <div class="col-12 nopadding mt-2">
                        <inputSimple name='login' label='Login*' id='login' inputDisabled='disabled'
                            :value='usuario.name' />
                    </div>
                    <div class="col-sm-12 nopadding mt-2">
                        <inputSimple  id='usutelefone'
                            :value="usuario.usutelefone" :hasMask="true" :mask="['(NN) NNNNN-NNNN']" label="Telefone*"
                            @changeInput="setTelefone" />
                    </div>
                    <div v-if="this.usuario.usutipo == 'E'" class='mt-2'>
                        <inputSimple name='senha' label='Senha*' type='password' id='senha' :inputClass='{
                            "border border-danger":
                                $v.usuario.password.$anyError
                        }' @blur='$v.usuario.password.$touch()' @changeInput='mudaSenhaInput' />
                        <simpleAlert v-if="!$v.usuario.password.minLength" type='red' :isLoading='false'
                            message='Senha deve conter mo mínimo 6 caracteres' :hasClick='false' />
                    </div>
                </div>

                <!-- Segunda Coluna -->
                <div class="col-sm-4 nopadding">
                    <div class="col-sm-12 nopadding">
                        <selectAll nameForRadio='seletorMultiempresa' :labels='[{ description: "Empresas*" }]'
                            :selected='preEmpresas' :optionsArray='optSelectEmpresas' :isMultiple='true'
                            :hasSelectAll='true' :extraClass='{ "border border-danger": $v.valueEmpresaMultipla.$anyError }'
                            @close='$v.valueEmpresaMultipla.$touch()' @changeSelect="v => valueEmpresaMultipla = v"
                            :clearOnSelect="false" />
                    </div>

                    <div class="col-12 nopadding mt-2">
                        <selectAll nameForRadio='seletorEmpresaPrincipal' :labels='[{ description: "Empresa principal*" }]'
                            :disabled='!optEmpresaPrincipal.length'
                            :extraClass='{ "border border-danger": $v.usuario.usucliente.$anyError }'
                            :optionsArray='optEmpresaPrincipal' :isMultiple='false' ref='seletorEmpresaPrincipal'
                            :selected='preEmpresaPrincipal' @close='$v.usuario.usucliente.$touch()'
                            @changeSelect="v => usuario.usucliente = v[0]" />
                    </div>

                    <div class="col-12 nopadding mt-2">
                        <selectAll nameForRadio='seletorPerfilAcesso' :labels='[{ description: "Perfil de acesso*" }]'
                            :isMultiple='false' :selected='prePerfilAcesso' :optionsArray='optPerfilAcesso'
                            :loading='buscandoPerfil' ref='seletorPerfilAcesso' :disabled='!optPerfilAcesso.length'
                            :extraClass='{ "border border-danger": $v.usuario.usuperfil.$anyError }'
                            @close='$v.usuario.usuperfil.$touch()' @changeSelect='v => usuario.usuperfil = v[0]' />
                    </div>

                    <div class="col-12 nopadding mt-2">
                        <selectAll nameForRadio='seletorCargos' :labels='[{ description: "Cargo*" }]'
                            :isMultiple='false' :selected='preCargos' :optionsArray='optCargos' :loading='this.carregandoCargos'
                            ref='seletorCargos' :disabled='!optPerfilAcesso.length'
                            @changeSelect='setCargo' />
                    </div>
                    
                    <div class="col-12 mt-2 nopadding strech" >
                        <b-checkbox v-model='usuario.usualertaemail' id='emailCheck' class='mt-1 space'>
                            Email
                        </b-checkbox>
                        <b-checkbox id='checkTelegram' v-model='usuario.usualertatelegram' class='mt-1 space'>
                            Telegram
                        </b-checkbox>
                        <b-checkbox id='checkEagleApp' v-model='usuario.usualertaapp' class='mt-1 space' >
                            Aplicativo Eagle Track
                        </b-checkbox>
                    </div>

                </div>

                <!-- Terceira Coluna -->
                <div class="col-sm-4 nopadding">
                    <selectAll nameForRadio='seletorTipoUsuario' :labels='[{ description: "Tipo de usuário*" }]'
                        :isMultiple='false' :optionsArray='optTipoDeUsuario' :selected='preTipoUsuario'
                        :extraClass='{ "border border-danger": $v.usuario.usutipo.$anyError }'
                        @close='$v.usuario.usutipo.$touch()' @changeSelect='v => usuario.usutipo = v[0]' />
                    <buttonsFilters label="Alertas por email" class=" mt-3" :arrayButtons='botoesAlertaEmail'
                        @buttonsFiltersChange='v => usuario.usurecebealerta = v[0]' />
                    <buttonsFilters label="Status do usuário" class=' mt-3' :arrayButtons='this.botoesStatusUsuario'
                        @buttonsFiltersChange='v => usuario.usuativo = v[0]'>
                    </buttonsFilters>
                    <buttonsFilters v-if="getMaster()" label="Usuário master" class=' mt-3'
                        :arrayButtons='this.botoesUsuarioMaster' @buttonsFiltersChange='v => usuario.usumaster = v[0]'>
                    </buttonsFilters>
                </div>
                <requiredFields />
            </div>
        </div>
        <div class="col-sm-12">
            <hr>
        </div>
    </panelEagle>
</template>
<script>

import Vue from 'vue';
import { Usuario } from './Usuario.ts'
import { EmpresasService } from '@/Services/auth/Empresas.service';
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { conectionError } from '@/Services/Helpers/swellHeper'
import { validaCpfCnpj } from '@/Services/Helpers/ValidatorHelper'
import { required, email } from 'vuelidate/lib/validators'
import { mdiAccount, mdiCheckBold, mdiCloseThick } from '@mdi/js'
import {FiltrosService}  from '@/Services/filtros/filtros.Service'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name: 'cadastrarUsuarios',

    components: {
        baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
        'selectAll': require('@/components/Atom/Select/SelectAll').default,
        'buttonsFilters': require('@/components/Atom/Buttons/ButtonsFilters').default,
        'BBCadastroSC': require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
        'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage': require('@/components/Atom/Header/Titulo').default,
        'InputSimple': require('@/components/Atom/Inputs/InputSimple').default,
        'requiredFields': require('@/components/Atom/Footer/RequiredFields').default,
        'simpleAlert': require('@/components/Atom/Alerts/AlertSimple').default,
        'modal': require('@/components/Atom/Modal/ModalEagle').default,
    },

    validations: {
        valueEmpresaMultipla: { required },
        usuario: {
            usucargos: { required },
            usunome: { required },
            usutelefone: { required },
            email: { required, email },
            usucliente: { required },
            usuperfil: { required },
            usutipo: { required },
            usucpf: { required },
        }
    },

    data() {
        return {
            mdiCloseThick: mdiCloseThick,
            mdiCheckBold: mdiCheckBold,
            icone: mdiAccount,
            prePerfilAcesso: [],
            preCargos: [],
            preEmpresas: [],
            preEmpresaPrincipal: [],
            preTipoUsuario: [{ description: 'Normal', value: 'N' }],
            usuario: Usuario,
            valuePerfilDeAcesso: [],
            valueEmpresaMultipla: [],
            valueLogin: '',
            valueEmpresaPrincipal: [],
            buscandoPerfil: false,
            carregandoUser: false,
            carregandoCargos: false,
            optPerfilAcesso: [],
            optCargos: [],
            optTipoDeUsuario: [
                { description: 'Normal', value: 'N' },
            ],
            optSelectEmpresas: [],
            botoesAlertaEmail: [
                { value: 'S', width: '48.6%', text: 'Sim', isSelected: false },
                { value: 'N', width: '48.6%', text: 'Não', isSelected: true },
            ],
            botoesStatusUsuario: [
                { value: 'S', width: '48.6%', text: 'Ativo', isSelected: true },
                { value: 'N', width: '48.6%', text: 'Inativo', isSelected: false },
            ],
            botoesUsuarioMaster: [
                { value: 'S', width: '48.6%', text: 'Sim', isSelected: false },
                { value: 'N', width: '48.6%', text: 'Não', isSelected: true },
            ],
            uriBase: '/administrativo/cadastros/usuarios/',
            saveError: false,
            gerandoRel: false,
            quantidadeUsuariosContrato: false,
            empresasSelecionadasExedeLimiteUsuario: [],
            quantidadeUsuariosDisponiveisEmpresas: [],
            baseUri: "/administrativo/cadastros/clientes/",
            statusInicial: '',
        }
    },

    methods: {
        ...mapGetters(['getMaster']),

        salvarCadastro() {
            this.carregandoUser = true;
            let user = this.usuario;
            let uri = this.uriBase + (this.$route.params.id ? 'update' : 'salvar');
            if (this.$route.params.id) {
                uri = this.uriBase + 'update'
            } else {
                uri = this.uriBase + 'salvar'
                delete user.id
            }
            let obj = {
                usuario: this.usuario,
                multiEmpresa: this.valueEmpresaMultipla
            };
            let uriConsultaUsuariosEmpresa = this.baseUri + 'empresas';

            if (this.statusInicial != 'S' && obj.usuario.usuativo == 'S') {
                new HttpRequest().Post(uriConsultaUsuariosEmpresa, { codigo: obj.usuario.usucliente })
                    .then(dataEmpresa => {
                        this.empresasSelecionadasExedeLimiteUsuario = [];
                        let empresa = dataEmpresa.data[0].original.cliente;
                        let usuariosAtivos = 0;

                        if (empresa.clusuarioscontratados > 0) {
                            for (var usuario in dataEmpresa.data[1]) {
                                if (dataEmpresa.data[1][usuario].usuativo == 'S') {
                                    usuariosAtivos++;
                                }
                            }

                            if (usuariosAtivos >= empresa.clusuarioscontratados) {
                                let msg = 'Quantidade de usuários excedeu o limite do contrato!'
                                conectionError(msg, 'warning');
                                this.carregandoUser = false;
                            } else {
                                let rota = this.baseUri + 'status'
                                new HttpRequest().Post(rota, obj)
                                    .then((data) => {
                                        this.salvarUsuarioCadastro(uri, obj);
                                    })
                            }
                        } else {
                            this.salvarUsuarioCadastro(uri, obj);
                        }
                    })
            } else {
                this.salvarUsuarioCadastro(uri, obj);
            }
        },

        /**
         * @description Salva os dados do usuário novo ou editado, feita para não repetir código para a função salvarCadastro.
         * @param {obj} obj - Dados do usuário novo ou editado.
         * @param {uri} uri - URI para salvar ou editar o usuário.
        * @author Yuri 🇧🇷
        */
        salvarUsuarioCadastro(uri, obj) {
            this.carregandoUser = true;
            new HttpRequest().Post(uri, obj)
                .then(data => {
                    if (data.status && data.data.success) {
                        this.limparUsuario();
                        this.$router.push({ name: 'listarUsuarios' });
                    } else {
                        if (data.data?.error == 'email') {
                            this.saveError = 'Email já cadastrado';
                            setTimeout(() => { this.saveError = false }, 5000);
                        } else if (data.data.cpfDupli) {
                            conectionError('CPF já cadastrado');
                        }
                    }
                })
                .catch((e) => {
                    conectionError();
                    this.carregandoUser = false;
                })
                .finally(() => {
                    this.carregandoUser = false;
                })
        },

        mudaSenhaInput(value) {
            // this.usuario.password = value;
        },

        setCpf(cpf) {
            this.usuario.usucpf = cpf
        },

        setNome(usunome) {
            this.usuario.usunome = usunome
        },

        setTelefone(usutelefone) {
            this.usuario.usutelefone = usutelefone
        },

        setCargo(usucargos) {
            this.usuario.usucargos = usucargos[0];
        },

		async dados_filtros(){
			this.carregandoCargos = true

            new FiltrosService().dados_filtros([], ['CG'])
				.then((dados)=>{
					this.optCargos = dados.CG
                })
			this.carregandoCargos = false
		},

        carregarPerfilsAcesso() {
            this.buscandoPerfil = true;
			this.dados_filtros();
            let uri = this.uriBase + 'listar/perfis';
            let uriConsultaUsuariosEmpresa = this.baseUri + 'empresas';
            let obj = { cliente: this.valueEmpresaMultipla };
            let codigoEmpresa;


            new HttpRequest().Post(uri, obj)
                .then(data => {
                    this.optPerfilAcesso = data.data.perfil;
                }).finally(() => {
                    this.buscandoPerfil = false
                    // for (var indexEmpresa in this.valueEmpresaMultipla) {
                    //     codigoEmpresa = { codigo: this.valueEmpresaMultipla[indexEmpresa] };

                    //     new HttpRequest().Post(uriConsultaUsuariosEmpresa, codigoEmpresa)
                    //         .then(dataEmpresa => {
                    //             let empresa = dataEmpresa.data[0].original.cliente;
                    //             let usuariosAtivos = 0;

                    //             if (parseInt(empresa.clusuarioscontratados) > 0) {

                    //                 for (var usuario in dataEmpresa.data[1]) {
                    //                     if (dataEmpresa.data[1][usuario].usuativo == 'S') {
                    //                         usuariosAtivos++;
                    //                     }
                    //                 }

                    //                 if (usuariosAtivos >= parseInt(empresa.clusuarioscontratados)) {
                    //                     let verificacao = this.empresasSelecionadasExedeLimiteUsuario.filter((item) => {
                    //                         if (item.clcodigo == empresa.clcodigo) {
                    //                             return item;
                    //                         }
                    //                     });

                    //                     if (verificacao.length == 0) {
                    //                         this.empresasSelecionadasExedeLimiteUsuario.push(empresa);
                    //                         this.quantidadeUsuariosContrato = true;
                    //                     }

                    //                 } else {
                    //                     this.quantidadeUsuariosContrato = false;
                    //                 }
                    //             }
                    //         }).finally(() => {
                    //             this.buscandoPerfil = false
                    //         })
                    // }

                })
        },

        /**
         * @description Função responsável por iniciar os dados do usuário e dolocar em cada select.
         * @param {obj} data - Informações do usuário selecionado para edição.
        * @author Desconhecido.
        */
        setValores(data) {
            Object.keys(data).forEach(k => {
                this.usuario[k] = data[k];
            });

            this.statusInicial = this.usuario.usuativo;

            var y = this.optTipoDeUsuario.find(e => {
                return e.value.trim() == this.usuario.usutipo.trim()
            });

            if (y !== undefined) {
                this.preTipoUsuario = [y];
            }

            this.iniciaAlertas();
            this.iniciaStatus();
            this.iniciaMaster();
        },

        iniciaMaster() {
            if (this.usuario.usumaster == 'S') {
                this.botoesUsuarioMaster[0].isSelected = true
                this.botoesUsuarioMaster[1].isSelected = false
            }
        },

        iniciaStatus() {
            if (this.usuario.usuativo == 'N') {
                this.botoesStatusUsuario[0].isSelected = false
                this.botoesStatusUsuario[1].isSelected = true
            }
        },

        iniciaAlertas() {
            if (this.usuario.usurecebealerta == 'S') {
                this.botoesAlertaEmail[0].isSelected = true
                this.botoesAlertaEmail[1].isSelected = false
            }
        },

        limparUsuario() {
            this.usuario = {
                usunome: '',
                usutelefone: '',
                usucargos: '',
                name: '',
                email: '',
                usucliente: '',
                usumaster: 'N',
                usuativo: 'S',
                usuperfil: '',
                usutipo: '',
                usurecebealerta: '',
                usvalidacao: false,
                usualertaemail: false,
                usualertatelegram: false,
                usualertaapp: false,
                usucodigotelegram: '',
                usucpf: ''
            }
        },
    },

    mounted() {
        this.limparUsuario()
        this.optSelectEmpresas = new EmpresasService().Get();
        this.preTipoUsuario = [{ description: 'Normal', value: 'N' }];
        if (this.$route.params.id) {
            this.carregandoUser = true;
            let uri = this.uriBase + 'getuser';
            new HttpRequest().Post(uri, { codigo: this.$route.params.id })
                .then(data => {
                    this.prePerfilAcesso = [data.data.perfilUser];
                    this.preCargos = [{value: data.data.usuario.usucargos, description: data.data.usuario.nomeCargo}];
                    this.preEmpresaPrincipal = [data.data.cliente];
                    this.optPerfilAcesso = data.data.perfis;
                    this.preEmpresas = data.data.multi;
                    this.setValores(data.data.usuario);
                })
                .catch((e) => {
                    conectionError();
                })
                .finally(() => {
                    this.carregandoUser = false;
                })
        }
    },

    computed: {
        optEmpresaPrincipal() {
            return this.optSelectEmpresas.filter(e => {
                return this.valueEmpresaMultipla.includes(e.value)
            })
        },

        cpfValido() {
            return validaCpfCnpj(this.usuario.usucpf)
        },

        cpfValidationBorder() {
            return this.usuario.usucpf &&
                (!this.cpfValido || this.$v.usuario.usucpf.$anyError)
        }
    },

    watch: {
        'usuario.email'(newValue, oldValue) {
            this.usuario.name = newValue
        },

        valueEmpresaMultipla(antes, depois) {
            this.optPerfilAcesso = []
            this.$refs.seletorPerfilAcesso.clearAll()
            this.$refs.seletorEmpresaPrincipal.clearAll()
            this.$v.usuario.usucliente.$reset()
            this.$v.usuario.usuperfil.$reset()
            if (this.valueEmpresaMultipla.length)
            this.carregarPerfilsAcesso()
        }
    },
})

</script>

<style scoped>
.usuTelegram {
    color: black !important;
}

.strech{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.space{
    margin-left: 10px;
    margin-right: 10px;
}
.listNode {
    list-style: none
}

.lista {
    display: block;
    text-align: left;
    padding-left: 15px;
}

#labelUsuario {
    text-align: left;
    font-size: 15px;
}
</style>