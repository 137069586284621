<template>
    <panel-eagle id="listPerifericos"
         :loading="loadingPanel">
        <div class="col-sm-12 nopadding">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiChip" titulo="Periféricos"></tituloPage>
                </div>
                <div class="col-sm-6 divDontPrint">
                    <basicButtonsCadastrosEIN 
                        :loading="carregandoE"
                        tipoNovo='button'
                        @exportarCadastro="exportarPerifericos" 
                        linkNew="cadastrarPeriferico">
                    </basicButtonsCadastrosEIN>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
        </div>
        <tableListagem 
            @editarCadastro="editarPerifericos" 
            @excluirCadastro="excluirPerifericos"
            deleteEdit="editDelete" 
            :data="listarPerifericos" 
            :titles="titles">
        </tableListagem>
    </panel-eagle>
</template>

<script lang="js">
import Vue from 'vue'
import Swal from 'sweetalert2'
import {mdiChip } from '@mdi/js'
import {EmpresasService} from '../../../../Services/auth/Empresas.service'
import {HttpRequest} from '../../../../Services/auth/HttpRequest.Service'
export default Vue.extend({
    name: 'listagemPerifericos',
    components:{
        'panelEagle': require('../../../../components/Atom/Panel/PanelEagle').default,
        'tituloPage': require('../../../../components/Atom/Header/Titulo').default,
        'basicButtonsCadastrosEIN': require('../../../../components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
        'tableListagem': require('../../../../components/Atom/Table/TableListagem').default,
    },

     methods:{
         async listagemPerifericos(){
            this.loadingPanel = true
            var dados = await new HttpRequest().Post(
                '/administrativo/cadastros/perifericos/listar');
            this.listarPerifericos = dados.data.dados;
            this.loadingPanel = false
        },
        editarPerifericos(codigo){
            this.$router.push({name:'cadastrarPeriferico', params:{id:codigo}})
        },
        async excluirPerifericos(codigo){
            this.loadingPanel = true
            var data = await new HttpRequest().Post(
                '/administrativo/cadastros/perifericos/excluir', {'id': codigo, 'success': this.success});
            if(data.data.success){
                this.listagemPerifericos()
            } else {
                this.modalOpsErro()
            }
            this.loadingPanel = false
            this.listagemPerifericos()
        },

         modalOpsErro(){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `
                Tente novamente mais tarde, se o erro persistir
                <br>Entre em contato com o suporte nos números: 
                <br>49 2049-4936 e 49 99118-2530 ou nos emails suporte@eagletrack.com.br e comercial@eagletrack.com.br.`,
                footer: ''
            })
        },

        async exportarPerifericos(type){
            var root =  process.env.VUE_APP_ROOT;
            this.setCarregando(type)
            if(this.listarPerifericos.length > 0){
                var dados = await new HttpRequest().Post(
                    '/administrativo/cadastros/perifericos/exportar', 
                    {'arrayDados': this.listarPerifericos, 'tipo':type});
                window.open(root+'/'+dados.data.exportar);
                this.setCarregando()
            }
        },

        setCarregando(type=null){
                if(type == 'pdf'){
                    this.carregandoE = [true, false, false]
                }else if(type == 'xls'){
                    this.carregandoE = [false, true, false]
                }else if(type == 'csv'){
                    this.carregandoE = [false, false, true]
                }else{
                    this.carregandoE = [false, false, false]
                }
            },
    },

    data(){
        return {
            mdiChip:mdiChip,
            carregandoE:[false,false,false],
            produto: [],
            titles:[
                {'name': 'Código', 'number': 'codigo', 'width':'100px'},
                {'name': 'Descrição', 'number': 'descricao'},
            ],
            listarPerifericos: [],
            loadingPanel: false,
            success: false,
        }
    },

    async mounted(){
        this.listagemPerifericos()
    }
})
</script>
