<template>
    <modalEagle 
        :id="idModal"
        title="Ações: Adicionar Evento"
        size='lg'>
        <template slot="modalBody">
            <b-overlay :show="loading">
                <div class="col-sm-12 row divInputJornada nopadding">
                    <div class="col-sm-12 row divInputJornada nopadding">
                        <selectAll 
                            class="divInputJornada"
                            nameForRadio="seletorVeiculosModalAdcEvento"
                            :isMultiple="false" 
                            :labels="labelsSelectEventos" 
                            :optionsArray="optionsEventos" 
                            firstSelected="EV" 
                            :hasSelectAll="false"
                            :loading='carregandoModal'
                            ref='selectEV'
                            @changeSelect="changeEventos" >
                        </selectAll>
                    </div>
                    <!-- <div class="col-sm-12 row divInputJornada nopadding">
                        <selectAll 
                            class="divInputJornada"
                            nameForRadio="seletorVeiculosModalAdcEvento"
                            :isMultiple="false" 
                            :labels="labelsSelectJustificativa" 
                            :optionsArray="optionsJustificativas" 
                            firstSelected="EV" 
                            :hasSelectAll="false"
                            :loading='carregandoModal'
                            ref='selectEV'
                            @changeSelect="changeJustificativa" >
                        </selectAll>
                    </div> -->
                </div>
                <div class="col-sm-12 hrJornada"><hr></div>
                <!-- Inputs de Intervalo de data -->
                <div v-show="mostrarInputIntervalo">
                    <div class="col-sm-12 row nopadding">
                        <div class="pt-3">
                            <span class="col-sm-1 row divInputHorasEventos">
                                De:
                            </span>
                        </div>
                        <div class="col-sm-5 row inputMesEventos">  
                            <inputSingleDay 
                                :disabled="disableDataInicio"
                                id='inputInicioEventoModalOpcoesEvento'
                                name='inputInicioEventoModalOpcoesEvento'
                                class='inputAdicionarJornada'
                                label=''
                                :value="dataEventosModal"
                                @changeInput="changeDataInicioModal">
                            </inputSingleDay>
                        </div>
                        <div class="pt-3">
                            <span class="col-sm-1 row divInputHorasEventos">
                                até: 
                            </span>
                        </div>
                        <div class="col-sm-5 row inputMesEventos">
                            <inputSingleDay 
                            :disabled="disableDataFim"
                            id='inputFimEventoModalOpcoesEvento'
                            name='inputFimEventoModalOpcoesEvento'
                            class='inputAdicionarJornada nopadding'
                            label=''
                            :value='dataEventosModal'
                            @changeInput="changeDataFimModal">
                            </inputSingleDay>
                        </div>
                    </div>
                </div>
                <!-- Inputs de Intervalo de data -->
                <div v-show="mostrarInputDataHora">
                    <div class="col-sm-12 row nopadding">
                        <div class="col-sm-12 boldDataModalEventos pt-4 pb-2">
                            <span>Data selecionada: {{dataEventosModal}}
                            </span>
                        </div>
                        <div class="col-sm-6 divInputHorasEventos pt-3">
                            <span>Informe o total de horas: </span>
                        </div>
                        <div class="col-sm-6 nopadding">
                            <inputSimple 
                            name='inputTimeEventoModalOpcoesEventos'
                            class='inputAdicionarJornada'
                            type="time"
                            value='00:00'
                            @changeInput="changeTempoJornadaModal">
                            </inputSimple>
                        </div>
                    </div>
                </div>
            </b-overlay>
        </template>
        <template>
            <span :class="foiSalvoModal != 200 ?
                'mensagemVermelho':
                'mensagemVerde'">
                {{mensagem}}
            </span>
            <simpleButton 
                :icon="mdiCloseThick" 
                @click="fecharModalAdicionarEvento" 
                text="Cancelar" 
                event="click" 
                type="red">
            </simpleButton>
            <simpleButton 
                :icon="mdiCheckBold" 
                text="Salvar" 
                event="salvar" 
                @salvar="salvarEventoModalAdicionarEvento"
                :disabled="$v.$invalid"
                type="blue">
                </simpleButton>
        </template>
    </modalEagle>
</template>
<script>
const urlModulo = '/controle/jornada/relatorios/folha/ponto'
import Vue from 'vue'
import { mdiCheckBold, mdiCloseThick} from '@mdi/js'
import { required } from 'vuelidate/lib/validators'
import {HttpRequest} from '../../../Services/auth/HttpRequest.Service'

export default Vue.extend({
    name:'ModalAdicionarEvento',
    components:{
        selectAll:require('@/components/Atom/Select/SelectAll').default,
        modalEagle:require('@/components/Atom/Modal/ModalEagle').default,
        inputSimple:require('@/components/Atom/Inputs/InputSimple').default,
        simpleButton:require('@/components/Atom/Buttons/SimpleButton').default,
        inputSingleDay:require('@/components/Atom/Datas/InputSingleDay').default,
    },
    props: {
		idModal: {
			type: [Number, String],
			required: true
		},
	},
    validations:{
        tempoJornadaModal:{required},
        dataInicialModal:{required},
        // justificativaSelecionada: { required },
        eventoSelecionado: { required },
    },
    data(){
        return{
            mdiCloseThick:mdiCloseThick,
            mdiCheckBold:mdiCheckBold,
            labelsSelectEventos:[{
                indexDFH: 'EV', 
                description: 'Tipo de evento*'
            }],
            optionsEventos: [],
            tipoTratamentoEvento:[],
            eventoSelecionado:null,
            tempoJornadaModal:[],
            dataInicialModal: [],
            empresasSelecionadas:[],
            colabSelecionado:null,
            dataEventosModal:[],
            dataFinalModal:[],
            foiSalvoModal:200,
            carregandoModal:false,
            disableDataInicio:false,
            loadingEventos:false,
            disableDataFim:false,
            loading:false,
            mensagem: '',

            // labelsSelectJustificativa:[{
            //     indexDFH: 'J', 
            //     description: 'Justificativa*'
            // }],
            // optionsJustificativas: [],
            // justificativaSelecionada: null,
        }
    },
    methods: {

        /**
         * @description Essa função deve ser utilizada por uma referência para
         * "ativar" essa modal, os argumentos da função são os necessários para
         * conseguir popular a modal com as infomações corretas
         * @param {array} empresasSelecionadas empresas selecionadas no 
         * relatório
         * @param {string} dataEventosModal data do campo selecionado
         * @param {int} colabSelecionado colaborador selecionado no relatório
         * @author Vitor Hugo 🐨 
         */
        preparaModalAdicionarEventos(empresas,data,colaborador){
            this.empresasSelecionadas = empresas
            this.dataEventosModal = data
            this.colabSelecionado = colaborador
            this.modalOpcoesAdicionarEvento()
            this.$forceUpdate()
        },

        /**
         * @description Modal Adicionar Evento
         * @author Rafael
         */
        modalOpcoesAdicionarEvento(){
            this.buscaEventosModalEventos()
            this.eventoSelecionado = null
            // this.justificativaSelecionada = null
            this.tipoTratamentoEvento = null
            this.mensagem = ''
            this.$bvModal.show(this.idModal)
        },

        changeEventos(eventos) {
            this.mensagem = ''
            this.optionsEventos.map(k => {
                if (k.value == eventos) {
                    this.tipoTratamentoEvento = k.tefmodotratamento
                }
            })
            this.disableDataInicio = eventos == 9
            this.disableDataFim = eventos == 9
            this.eventoSelecionado = eventos.length ? eventos[0] : null
            this.$forceUpdate()
        },

        changeTempoJornadaModal(tempo){
            this.tempoJornadaModal = tempo
        },

        changeDataInicioModal(data){
            this.dataInicialModal = data
        },

        changeDataFimModal(data){
            this.dataFinalModal = data
        },

        // changeJustificativa(value) {
        //     this.justificativaSelecionada = value && value.length ? value[0] : null
        // },


        /**
         * @description Método para buscar Eventos para o select
         * @author Rafael
         */
        async buscaEventosModalEventos(){
            var dados = await new HttpRequest()
            .Post(urlModulo+'/buscaEventos', {
                'empresa' : this.empresasSelecionadas
            })
            if(dados.status) {
                this.optionsEventos = dados.data.EV
                // this.optionsJustificativas = dados.data.JJ
            } else {
                let msg = 'Falha ao cominicar com o servidor!'
                this.toastShow('Ops!', msg, 'danger')
            }
        },
        
        /**
         * @description Método para salvar evento. Verifica se o tipo é H para
         * enviar a mesma data na data inicial e final
         * @author Rafael
         */
        async salvarEventoModalAdicionarEvento(){
            this.loading = true
            this.mensagem = ''
            if(this.tipoTratamentoEvento == 'H'){
                this.dataInicialModal = this.dataEventosModal
                this.dataFinalModal   = this.dataEventosModal
            }
            let body = {
                'tipo'       : this.eventoSelecionado,
                // 'justificativa': this.justificativaSelecionada,
                'dataInicio' : this.dataInicialModal,
                'dataFim'    : this.dataFinalModal,
                'horas'      : this.tempoJornadaModal,
                'motorista'  : this.colabSelecionado,
                'tratamento' : this.tipoTratamentoEvento
            }
            var dados = await new HttpRequest().Post(urlModulo+'/salvaEventos', body)
            if(dados.status && dados.data) {
                let sucesso = false
                if(dados.data.status == 400){
                    this.mensagem = dados.data.mensagem
                    this.foiSalvoModal = dados.data.status
                } else if(this.verificaSeDeveProcessar()){
                    var dadosScript = await new HttpRequest()
                        .Post('/controle/jornada/processamento/processar', {
                            'codigo': this.colabSelecionado,
                            'data': this.dataEventosModal.split('/').reverse().join('-')
                        })
                    if(dadosScript.status && dadosScript.data.processamento) {
                        this.foiSalvoModal = 200
                        sucesso = true
                    } else {
                        this.mensagem = 'Houve um problema no processamento deste dia!'
                    }
                } else {
                    sucesso = true
                }
                if(sucesso) {
                    this.fecharModalAdicionarEvento()
                    this.$emit('atualizarRelatorio')
                }
            }
            this.loading = false
        },

        verificaSeDeveProcessar() {
            let array = [9, 8, 7, 2]
            return this.eventoSelecionado
                && array.includes(this.eventoSelecionado)
        },

        fecharModalAdicionarEvento(){
            this.mensagem = ''
            this.eventoSelecionado = null
            // this.justificativaSelecionada = null
            this.tipoTratamentoEvento = ''
            this.$bvModal.hide(this.idModal)
        },

		toastShow(titulo, msg, type){
			this.$bvToast.toast(msg, {
				title: titulo,
				autoHideDelay: 2500,
				variant: type,
			})
        },

    },
    computed: {
        adcDataHora() {
            return this.eventoSelecionado == 2
        },
        mostrarInputDataHora() {
            return this.eventoSelecionado
                // && this.justificativaSelecionada
                && (this.tipoTratamentoEvento == 'H' || this.adcDataHora)
        },
        mostrarInputIntervalo() {
            return this.eventoSelecionado
                // && this.justificativaSelecionada
                && (this.tipoTratamentoEvento == 'P' || this.adcDataHora)
        },
    }
})
</script>

<style lang="scss">
#modalAdicionarEventoJornada{
    .divInputJornada{
        margin-top: 5px !important;
        padding-right: 5px !important;
        padding-left: 5px !important;
    }
    .mensagemVerde{
        color: green !important;
    }
    .mensagemVermelho{
        color: #e04b4a;
    }
    .tamSelectsAdicionarEvento{
        margin-top    : 10px;
        padding-right : 0px !important;
        padding-left  : 0px !important;
    }
}
</style>