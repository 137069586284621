<template>
    <eaglePanel id='ListagemEmpresas' :loading='gerandoLista'>
        <div class="col-sm-12 nopadding">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6 nopadding">
                    <titulo titulo="Listagem de empresas" :icon='mdiOfficeBuildingMarker' />
                    <!-- O icone correto não existe mais no pack -->
                </div>

                <div class="col-sm-6 nopadding divDontPrint">
                    <botoes tipoNovo='button' linkNew='cadastrarCliente' @exportarCadastro='exportarLista'
                        :loading='loadingExportar' />
                </div>
            </div>

            <div class="col-sm-12 nopadding">
                <hr>
            </div>

            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6 nopadding divDontPrint">
                    <filtros label='Tipo de Pessoa' :arrayButtons='botoesFiltroTipo'
                        @buttonsFiltersChange='mudaFiltroTipo' />
                </div>

                <div class="col-sm-6 nopadding divDontPrint">
                    <filtros label='Status' :arrayButtons='botoesFiltroStatus' @buttonsFiltersChange='mudaFiltroStatus' />
                </div>
            </div>

            <div class="col-sm-12 nopadding">
                <hr>
            </div>

            <div class="col-sm 12 nopadding">
                <tableLista :titles='titulosListagem' :data='dataListagem' :habilitado='true'>
                    <template #acoes='{ row }'>
                        <simpleButton v-if='$store.getters.getEditar' title='Editar' width='45%' :icon='iconEditar'
                            type='green' event='click' @click='editarCliente(row.clcodigo)' />
                        <simpleButton v-if='row.clstatus == "A" && $store.getters.getEditar' title='Desativar' width='45%'
                            type='red' :icon='iconDesativar' event='click' @click='mudarStatusCliente(row.clcodigo, "I")' />
                        <simpleButton v-else-if='$store.getters.getEditar' title='Ativar' width='45%' type='light-green'
                            :icon='iconAtivar' event='click' @click='mudarStatusCliente(row.clcodigo, "A")' />
                    </template>
                </tableLista>
            </div>
        </div>

    </eaglePanel>
</template>

<script>
import { mdiOfficeBuildingMarker } from '@mdi/js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { listagem } from './EmpresasHelpers'
import { mapActions } from 'vuex'
import { mdiPencilOutline, mdiCircleOffOutline, mdiCheck, mdiCheckBold } from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
export default {
    name: 'ListagemClientes',
    components: {
        eaglePanel: require("@/components/Atom/Panel/PanelEagle").default,
        titulo: require('@/components/Atom/Header/Titulo').default,
        botoes: require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
        filtros: require('@/components/Atom/Buttons/ButtonsFilters').default,
        tableLista: require('@/components/Atom/Table/TableListagem').default,
        simpleButton: require('@/components/Atom/Buttons/SimpleButton').default,
    },
    data() {
        return {
            mdiOfficeBuildingMarker: mdiOfficeBuildingMarker,
            mdiCheckBold: mdiCheckBold,
            iconEditar: mdiPencilOutline,
            iconDesativar: mdiCircleOffOutline,
            iconAtivar: mdiCheck,
            ...listagem,
            baseUri: '/administrativo/cadastros/clientes/',
            gerandoLista: false,
            dataListagem: [],
            dados: {
                cltipo: [],
                clstatus: [],
            },
            loadingExportar: [false, false, false]
        }
    },
    methods: {
        /**
         * Muda o filtro de tipo de cliente,
         * Se for T, é TODOS, por isso vira uma arr
         * com os dois valores
         */
        mudaFiltroTipo(tipo) {
            if (tipo[0] == 'T') {
                this.dados.cltipo = ['F', 'J']
            } else {
                this.dados.cltipo = tipo
            }
            this.botoesFiltroTipo[0].icon = ''
            this.botoesFiltroTipo[1].icon = ''
            this.botoesFiltroTipo[2].icon = ''
            if (tipo[0] == 'F') {
                this.botoesFiltroTipo[0].icon = mdiCheckBold
            } else if (tipo[0] == 'J') {
                this.botoesFiltroTipo[1].icon = mdiCheckBold
            } else if (tipo[0] == 'T') {
                this.botoesFiltroTipo[2].icon = mdiCheckBold
            }
            this.buscaClientes()
        },

        /**
         * Filtro de status, a razão pelo T é a mesma do filtro
         * de tipo de cliente
         */
        mudaFiltroStatus(status) {
            if (status[0] == 'T') {
                this.dados.clstatus = ['I', 'A']
            } else {
                this.dados.clstatus = status
            }
            this.botoesFiltroStatus[0].icon = ''
            this.botoesFiltroStatus[1].icon = ''
            this.botoesFiltroStatus[2].icon = ''
            if (status[0] == 'A') {
                this.botoesFiltroStatus[0].icon = mdiCheckBold
            } else if (status[0] == 'I') {
                this.botoesFiltroStatus[1].icon = mdiCheckBold
            } else if (status[0] == 'T') {
                this.botoesFiltroStatus[2].icon = mdiCheckBold
            }
            this.buscaClientes()
        },

        /**
         * Faz a busca pelos clientes que atendem aos filtros
         * estabelecidos
         */
        buscaClientes() {
            this.gerandoLista = true
            this.dataListagem = []
            let uri = this.baseUri + 'listar'
            new HttpRequest().Post(uri, this.dados)
                .then((data) => {
                    this.gerandoLista = false
                    if (data.status)
                        this.dataListagem = data.data.clientes
                })
        },

        editarCliente(codigo) {
            this.$router.push({ name: 'cadastrarCliente', params: { id: codigo } })
        },

        mudarStatusCliente(codigo, status) {
            let uri = this.baseUri + 'status'
            new HttpRequest().Post(uri, { 'codigo': codigo, 'status': status.toUpperCase() })
                .then((data) => {
                    new EmpresasService().Refresh()
                    if (data.data.success)
                        this.buscaClientes()
                })
        },

        /**
         * Faz a exportação dos dados listados
         */
        exportarLista(tipo) {
            this.mudaLoading(tipo)
            let uri = this.baseUri + 'exportar'
            var root = process.env.VUE_APP_ROOT;
            let obj = {
                'tipo': tipo,
                'dados': this.dataListagem
            }
            new HttpRequest().Post(uri, obj)
                .then(data => {
                    this.mudaLoading()
                    if (data.status)
                        open(root + '/' + data.data.local)
                })
        },

        mudaLoading(tipo = false) {
            switch (tipo) {
                case 'pdf':
                    this.loadingExportar = [true, false, false]
                    return
                case 'xls':
                    this.loadingExportar = [false, true, false]
                    return
                case 'csv':
                    this.loadingExportar = [false, false, true]
                    return
                default:
                    this.loadingExportar = [false, false, false]
                    return
            }
        },
        ...mapActions(['refresh'])
    },
    mounted() {
        //this.refresh()
    },
}
</script>

<style lang="scss" scoped></style>