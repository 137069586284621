import Vue from 'vue'
import Vuex from 'vuex'
import { AuthService } from '@/Services/auth/Auth.Service'
import { PermissionsService } from '@/Services/route/Route.Service'
import { Permissions } from '@/Services/route/RouteModel'
Vue.use(Vuex)
/**
 * Varias funcões foram alteradas para incluir mutações e
 * gerar um comportamento mais previsivel dentro da página
 */

export default new Vuex.Store({
	state: {
		isAuth: new AuthService().isAuthenticated(),
		user: new AuthService().getUser() || {
			username: '',
			name: 'Não Autenticado',
			urlImage: 'https://track.eagletrack.com.br/images/logosClientes/laMVTeQCqguv.png',
			idUser: '',
			usuMaster: 'N',
			client: '',
			usucheckpoint:false,
			usuadmcheckpoint:false,
		},
		permissoes:new PermissionsService().GetPermissions(),
		modulos: new PermissionsService().GetModulos(),

		/**
		 * @description Versão atual do usuário
		 * @author Vitor Hugo 🐨
		 */
		versao: localStorage.getItem('versaoAtual'),

		/**
		 * @description Última versão do sistema
		 * @author Vitor Hugo 🐨
		 */
		versaoRecente: localStorage.getItem('versaoRecente'),
	},
	mutations: {
		REFRESH_IS_AUTH(state, auth) {
			state.isAuth = auth;
		},
		REFRESH_USER(state, user) {
			state.user = user;
		},
		REFRESH_PERMISSOES(state, permissoes) {
			state.permissoes = permissoes;
		},
		REFRESH_MODULOS(state, modulos) {
			state.modulos = modulos
		},
		UPDATE_PERMISSIONS(state, permissoes) {
			state.permissoes = permissoes
		},

		
		UPDATE_VERSION(state, versao) {
			state.versao = versao
		},

		UPDATE_VERSION_RECENTE(state, versaoRecente) {
			state.versaoRecente = versaoRecente
		},
	},
	actions: {
		/**
		 * Ação para dar refresh nas informações do usuario armazenadas no VUEX
		 * Essa função foi refeita, com mesmo nome da função original do Daniel,
		 * mas agora dentro dos padrões do vuex, utilizando de mutações para
		 * alterar o estado da aplicação
		 * @author Gui 🍺🍺
		 */
		refresh({ commit }) {
			
			var auth = new AuthService().isAuthenticated();
			commit('REFRESH_IS_AUTH', auth);

			var user = new AuthService().getUser();
			commit('REFRESH_USER', user);

			var permissoes = new PermissionsService().GetPermissions();
			commit('REFRESH_PERMISSOES', permissoes);

			var modulos = new PermissionsService().GetModulos();
			commit('REFRESH_MODULOS', modulos);

			var versao = localStorage.getItem('versaoAtual');
			versao = versao ? versao : ''
			commit('UPDATE_VERSION', versao);

			var versaoRecente = localStorage.getItem('versaoRecente');
			versaoRecente = versaoRecente ? versaoRecente : ''
			commit('UPDATE_VERSION_RECENTE', versaoRecente);
		},

		/**
		* Atualiza as permissões dos módulos na dashboard
		* @author Vitor Hugo 🐨
		*/
		refreshModulos({ commit }) {
			var modulos = new PermissionsService().GetModulos();
			commit('REFRESH_MODULOS', modulos);
			var permissoes = new PermissionsService().GetPermissions();
			commit('REFRESH_PERMISSOES', permissoes);
		},

		/**
		* atualiza as permissoes da pagina. utilizar
		* no MenuNavigate em Route.Service
		* @author Daniel Bruch
		* @author Gui 🍺🍺
		*FUNÇÃO TAMBÉM FOI ALTERADA PRA ENTRAR NOS PADROES DO VUEX
		*/
		updatePermissions({commit}) {
			var permissoes = new PermissionsService().GetPermissions();
			commit('UPDATE_PERMISSIONS', permissoes);
		}
	},
	modules: {
		// carros
	},
	getters: {
		getPermicoes: state => {
			return state.permissoes
		},
		getCadastrar: state=>{
			return state.permissoes.cadastrar
		},
		getVisualizar: state=>{
			return state.permissoes.visualizar
		},
		getEditar: state=>{
			return state.permissoes.editar
		},
		getExcluir: state=>{
			return state.permissoes.excluir
		},
		getMaster: state=>{
			return state.user?.usuMaster == 'S'
		},
		getImportar: state=>{
			return state.permissoes.importar 
		},
		getModulos: state=>{
			return state.modulos
		},
		getUser: state => {
			return state.user
		},
		getClient: state => {
			return state.user?.client
		},
		getVersao: state => {
			return state.versao
		},
		getUsucheckpoint: state => {
			return state.user.usucheckpoint
		},
		getAdmCheckpoint: state => {
			return state.user.usuadmcheckpoint
		},
		getVersaoRecente: state => {
			return state.versaoRecente
		},
	},
})