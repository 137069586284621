<template>
    <panelEagle id="inconformidadesJornada"
        :loading="loadingPanel">
        <div class="col-sm-12 nopadding">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiBadgeAccountAlert" titulo="Inconformidades de jornada"></tituloPage>
                </div>
                <div class="col-sm-6 divButtonsBasic divDontPrint pr-3">
                    <basicButtonsRelatoriosEIG 
                        @exportarRelatorio ="exportarRelatorio" 
                        @gerarRelatorio    ="gerarRelatorio"
                        :loading           ='loadingExportar'
                        :disabled          ="$v.$invalid"
                        :disabledDropdown  ="dadosIJ.length == 0 ? true : false">
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12 justpaddingbottom row">
                        <div class="col-sm-3 nopadding">
                            <inputData 
                                opens        ="right"
                                @changeInput ="InputData" 
                                :id          ="'dataRegistroJornada'" 
                                :name        ="'dataRegistroJornada'">
                            </inputData>
                        </div>
                        <div class="col-sm-3 nopadding">
							<!-- :optionsArray ="optionsEmpresas"  -->
                            <selectAll 
                                @changeSelect ="SelectEmpresas" 
                                :isMultiple   ="true" 
                                nameForRadio  ="radiosEmpresas" 
                                :labels       ="labelsSelectEmpresa" 
								:optionsArray ="optionsEmpresas"
                                firstSelected ="E" 
                                :selected="selectedEmpresa"
                                :hasSelectAll ="true">
                            </selectAll>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll 
                                @changeSelect ="SelectColaborador" 
								ref='selectFuncionario'
                                @selectCriado ="selectCriado" 
                                :isMultiple   ="ehMultiplo" 
                                nameForRadio  ="radiosColaborador" 
                                :labels       ="labelsSelectColaborador" 
                                :optionsArray ="optionsSelectColaborador" 
                                firstSelected ="E" 
								:hasSelectAll ="ehMultiplo"
								:loading      ="loadingColaboradores"
                                :disabled     ="$v.empresasArray.$invalid">
                            </selectAll>
							<b-checkbox
							class='col-8 mt-2 ml-4'
							v-model='ehMultiplo'>
								Selecionar mais de um colaborador
							</b-checkbox>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <buttonsFilters
                                @buttonsFiltersChange ="changeButtons" 
                                :onlyOneSelected      ="ehMultiplo" 
                                label                 ="Inconformidades*"
                                :arrayButtons         ="labelsButtons">
                            </buttonsFilters>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
            <div class="col-sm-12 divHrDeBaixo"><hr></div>
        </div>
        <tableRelatorio>
            <slot slot="thead">
                <th>Data</th>
                <th></th>
                <th>Inconformidade</th>
                <th>Veículo</th>
                <th>Prefixo</th>
                <th>Início</th>
                <th>Fim</th>
                <th>Tempo</th>
                <th>Velocidade atingida</th>
                <th>Local</th>
            </slot>
            <slot slot="tbody">
                <statusInformation 
                    v-if="dadosIJ.length == 0"
                    typeBar="tr" 
                    :statusBar="statusbar">
                </statusInformation>
                <br>
                <template v-for="(inconformidade, i) in dadosIJ">
                    <template v-if="inconformidade.Inconformidades.length">
						<tr :key="i+'_Nome'"
							class="badgeInconformidades"
							v-if="true">
							<td colspan="10">
								<span class="newbadge">
									{{inconformidade.Nome}} | {{inconformidade.Inconformidades.length}} Inconformidades
								</span>
							</td>
						</tr>
						<tr v-for="(dados, d) in inconformidade.Inconformidades" 
							:key="`${inconformidade.Nome}|
								DATA:${dados.Data}|
								INDEX:${d}|
								VEICULO:${dados.Placa}-${dados.Prefixo}
							`"
							class="dadosTabelaInconformidade">
							<td>
								{{dados.Data}}
							</td>
							<td class="iconAlign">
								<baseIcon size='18' :icon="icon(dados.Icon)"/>
							</td>
							<td class="inconformidade">
								{{dados.Descricao}}
							</td>
							<td>
								{{dados.Placa}}
							</td>
							<td>
								{{dados.Prefixo}}
							</td>
							<td>
								{{dados.Inicio}}
							</td>
							<td>
								{{dados.Fim}}
							</td>
							<td>
								{{dados.Tempo}}
							</td>
							<td>
								{{dados.Velocidade}}
							</td>
							<td>
								{{dados.Local}}
							</td>
						</tr>
					</template>
                </template>
            </slot>
        </tableRelatorio>
		<b-toast
		id="change-multiple"
		variant='warning'
		auto-hide-delay='3666'
		title="Inconformidades de jornada">
			Ao selecionar mais de um colaborador, somente será possível filtrar por uma inconformidade
		</b-toast>
    </panelEagle>
</template>

<script>
import Vue from 'vue'
import { 
	mdiBadgeAccountAlertOutline, 
	mdiSteering, 
	mdiAccountClock, 
	mdiAlarmSnooze,
	mdiSpeedometer,
	mdiAlarmMultiple,
	mdiMapMarkerQuestion, 
	mdiNoteMultiple
} from '@mdi/js'
import { required }      from 'vuelidate/lib/validators'
import {EmpresasService} from '../../../Services/auth/Empresas.service'
import {HttpRequest}     from '../../../Services/auth/HttpRequest.Service'
import {FiltrosService}  from '../../../Services/filtros/filtros.Service'
import { mapGetters } from 'vuex'
import { conectionError } from '@/Services/Helpers/swellHeper'
// import empresas from '@/mixins/relatorios'

var mdiSteerings = mdiSteering
var mdiSpeedometers = mdiSpeedometer
var mdiNoteMultiples = mdiNoteMultiple
var mdiAccountClocks = mdiAccountClock
var mdiAlarmMultiples = mdiAlarmMultiple
var mdiSilverwareForkKnifes = mdiAlarmSnooze
var mdiMapMarkerQuestions = mdiMapMarkerQuestion
export default Vue.extend({
	name: 'inconformidades',
	// mixins: [empresas],
	components:{
		panelEagle                : require('@/components/Atom/Panel/PanelEagle').default,
		tituloPage                : require('@/components/Atom/Header/Titulo').default,
		slideUpAndDown            : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
		basicButtonsRelatoriosEIG : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
		selectAll                 : require('@/components/Atom/Select/SelectAll').default,
		inputData                 : require('@/components/Atom/Datas/InputRangeWithLimit').default,
		statusInformation         : require('@/components/Atom/StatusInformation/StatusInformation').default,
		tableRelatorio            : require('@/components/Atom/Table/TableRelatorio').default,
		buttonsFilters            : require('@/components/Atom/Buttons/ButtonsFilters').default,
		baseIcon                  : require('@/components/Atom/Icon/BaseIcon.vue').default,
	},

	validations:{

		empresasArray:{
			required
		},
		colaboradorSelected:{
			required
		},
		inconformidades:{
			required
		},
	},

    
	data(){
		return {
			ehMultiplo:false,
			mdiBadgeAccountAlert:mdiBadgeAccountAlertOutline,
			labelsSelectEmpresa:[
				{indexDFH: 'E', description: 'Empresas*'},
			],
			labelsSelectColaborador:[
				{indexDFH: 'E', description: 'Colaboradores*'},
			],
			labelsButtons: [
				{
					class:'iconBtnIJ', 
					value:'A', 
					width:'23.3%', 
					isSelected:true, 
					text:'', 
					icon:mdiSteerings, 
					title:'Excedeu limite de direção contínua'
				},
				{
					class:'iconBtnIJ', 
					value:'B', 
					width:'23.3%', 
					isSelected:true, 
					text:'', 
					icon:mdiAccountClocks, 
					title:'Não cumpriu tempo mínimo de interjornada'
				},
				{
					class:'iconBtnIJ', 
					value:'D', 
					width:'23.3%', 
					isSelected:true, 
					text:'', 
					icon:mdiSilverwareForkKnifes, 
					title:'Não realizou intervalo durante a jornada'
				},
				{
					class:'iconBtnIJ', 
					value:'C', 
					width:'23.3%', 
					isSelected:true, 
					text:'', 
					icon:mdiSpeedometers, 
					title:'Excedeu o limite de velocidade'
				},
				{
					class:'iconBtnIJ', 
					value:'E', 
					width:'31.5%', 
					isSelected:true, 
					text:'', 
					icon:mdiAlarmMultiples, 
					title:'Excedeu 12 horas de jornada'
				},
				{
					class:'iconBtnIJ', 
					value:'F', 
					width:'31.5%', 
					isSelected:true, 
					text:'', 
					icon:mdiMapMarkerQuestions, 
					title:'Abertura/Fechamento de jornada fora ponto referência'
				},
				{
					class:'iconBtnIJ', 
					value:'G', 
					width:'31.5%', 
					isSelected:true, 
					text:'', 
					icon:mdiNoteMultiples, 
					title:'Registro de jornada'
				},
			],
			optionsEmpresas     : new EmpresasService().Get(),
			optionsSelectColaborador : [],
			statusbar                : 'info',
			dataValue                : new Date(),
			empresasArray            : [],
			selectColaboradores      : [],
			colaboradorSelected      : [],
			colaboradores            : 'C',
			dadosIJ                  : [],
			tamanhoArrayIJ           : 0,
			htmlIJ                   : '',
			inconformidades          : ['A','B','C','D','E','F', 'G'],
			// tamanhoRelatorio         : 0,
			loadingExportar          : [false, false, false],
			loadingPanel             : false,
			selectedEmpresa          : [],
			loadingColaboradores     : false,
		}
	},
    
	methods: {
		...mapGetters(['getMaster']),

		SelectEmpresas(event){
			this.empresasArray = event;
			this.dadosIJ = []
			this.selectColaboradores.clearAll();
			this.dados_filtros();
		},

		SelectColaborador(value){
			this.colaboradorSelected = value;
			this.dadosIJ = []
		},

		selectCriado(select){
			this.selectColaboradores = select;
			this.dadosIJ = []
		},

		InputData(event){
			this.dataValue = event;
			this.dadosIJ = []
		},

		changeButtons(value){
			this.inconformidades = value
			this.dadosIJ = []
		},

		async dados_filtros(){
			var dados = [];
			this.loadingColaboradores = true
			dados = await new FiltrosService().dados_filtros(this.empresasArray, [this.colaboradores])
			this.optionsSelectColaborador = dados[this.colaboradores];
			this.loadingColaboradores = false
		},

		gerarRelatorio(){
			if (this.$v.$invalid??this.loadingPanel) return
			this.loadingPanel = true
			this.htmlIJ = '';
			this.dadosIJ = [];
			new HttpRequest().Post('/controle/jornada/relatorios/inconformidade/jornada/gerar', 
				{
					'clientes': this.empresasArray, 
					'motoristas': this.colaboradorSelected, 
					'data':this.dataValue,
					'inconformidade':this.inconformidades
				}).then(({data}) => {
				if(!data.inconformidades.length) {
					this.dadosIJ = []
					this.statusbar = 'error'
					return
				}
				this.dadosIJ = data.inconformidades
				this.tamanhoArrayIJ = data.inconformidades.length
			}).catch(() => {
				this.dadosIJ = []
				this.statusbar = 'error'
				conectionError()
			}).finally(() => this.loadingPanel = false)
		},

        
		async exportarRelatorio(tipo){
			var root =  process.env.VUE_APP_ROOT;
			this.setLoading(tipo)
			if(this.tamanhoArrayIJ){
				var data = await new HttpRequest().Post(
					'/controle/jornada/relatorios/inconformidade/jornada/exportar', 
					{'arrayDados':JSON.stringify(this.dadosIJ), 
						'tipo':tipo, 
						'data':this.dataValue});
				window.open(root+'/'+data.data.local);
			}
			this.setLoading()
		},

		setLoading(tipo){
			let arr = [false, false, false]
			if(tipo==='pdf'){
				arr[0] = true;
			} else if(tipo==='xls'){
				arr[1] = true;
			} else if(tipo==='csv'){
				arr[2] = true;
			}
			this.loadingExportar =  arr;
		},

		icon(icon){
			switch (icon) {
				case 'mdiSteering':
					return mdiSteerings
				case 'mdiAccountClock':
					return mdiAccountClocks
				case 'mdiAlarmSnooze':
					return mdiSilverwareForkKnifes
				case 'mdiSpeedometer':
					return mdiSpeedometers
				case 'mdiAlarmMultiple':
					return mdiAlarmMultiples
				case 'mdiMapMarkerQuestion':
					return mdiMapMarkerQuestions
				case 'mdiNoteMultiple':
					return mdiNoteMultiples
				default:
					break;
			}
		},
	},
	watch: {
		ehMultiplo(newValue) {
			this.$refs.selectFuncionario.clearAll()
			this.inconformidades = []
			if(newValue){
				this.$bvToast.show('change-multiple')	
			}else{
				this.$bvToast.hide('change-multiple')
			}
		}
	},

	mounted() {
		if(!this.getMaster()){
			this.selectedEmpresa = this.optionsEmpresas
		}
	}
})
</script>

<style lang="scss">
    #inconformidadesJornada{
		.no-head{
			visibility: hidden;
		}
        .divHrDeBaixo{
            hr{
                margin-bottom: 0rem;
            }
        }
        .divButtonsBasic{
            padding-right: 0px;
        }
        .badgeTd {
            padding: 0px !important;
        }
        .iconBtnIJ span::before{
            font-size: 23px;
            vertical-align: middle;
        }
        .badgeInconformidades{
            background: #fff;
            td{
                padding-left: 0px;
                margin-top: 10px;
                color: #fff;
                text-align: left;
                vertical-align: middle;
                span{
                    display: inline-block;
                    margin-top: 10px;
                    height: 31px !important;
                    padding-left: 10px;
                    padding-right: 10px;
                    background: #777777;
                    min-width: 270px !important;
                    vertical-align: middle;
                    padding-top: 7px;
                }
            }
        }
        .dadosTabelaInconformidade{
            td{
                border: 1px solid #d5d5d5;
                vertical-align: middle;
            }
            td.inconformidade{
                min-width: fit-content;
            }
            td.iconAlign{
                text-align: center;

            }
        }
    }
</style>
