<template>
    <panelEagle id='Produtos' :loading="loadingPanel">
        <div class="col-sm-12 nopading row p-0">
            <div class="col-sm-6">
                <tituloPage :icon='mdiPackageVariantClosed' titulo='Produtos'></tituloPage>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                <basicButtonsCadastrosSC
                    :disabled='$v.$invalid'
                    linkCancel ='listarProdutos'
                    tipoCancela='button'
                    @salvarCadastro='salvarCadastro'>
                </basicButtonsCadastrosSC>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class='col-sm-12 row p-0'>
            <div class="col-lg-6 p-0">
                <inputSimple
                    name='descricao'
                    :inputClass='{"border border-danger":$v.valueDescricao.$anyError}'
                    @blur='$v.valueDescricao.$touch()'
                    label ='Descrição*'
                    :value ='valueDescricao'
                    @changeInput ='inputDescricao'/>
            </div>
            <div class="col-lg-6 p-0">
                <inputMoney
                    name='mensalidade'
                    :inputClass='{"border border-danger":$v.valueMensalidade.$anyError}'
                    label ='Valor da mensalidade*'
                    :value ='valueMensalidade'
                    @blur='$v.valueMensalidade.$touch()'
                    @changeInput='inputMensalidade'/>
            </div>
            <div class="col-lg-2 campoObri">
                <span>(*) Campos obrigatórios</span>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
    </panelEagle>
</template>
<script>
import Vue  from 'vue'
import Vuex from '@/store/index'
import {mdiPackageVariantClosed } from '@mdi/js'
import { required, minValue} from 'vuelidate/lib/validators'
import { HttpRequest } from '../../../../Services/auth/HttpRequest.Service'
export default Vue.extend({
	name: 'editarProdutos',
	components:{
		'panelEagle'               : require('@/components/Atom/Panel/PanelEagle').default,
		'basicButtonsCadastrosSC'  : require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
		'tituloPage'               : require('@/components/Atom/Header/Titulo').default,
		'inputSimple'              : require('@/components/Atom/Inputs/InputSimple').default,
		'inputMoney'               : require('@/components/Atom/Inputs/InputMoney').default,
	},
	validations:{
		valueMensalidade :{ required, minValue:minValue(0.01) },
		valueDescricao   :{ required },
	},
	data(){
		return {
			loadingPanel:false,
			mdiPackageVariantClosed:mdiPackageVariantClosed,
			id:0,
			valueDescricao:'',
			valueMensalidade:'',
		}
	},
	methods:{
		salvarCadastro(){
			this.loadingPanel = true
			var obj = {
                
				'descricao':this.valueDescricao,
				'mensalidade':parseFloat(this.valueMensalidade),
			}
			let rota = '/administrativo/cadastros/produtos/'
			if(this.$route.params.id){
				rota+='editar'
				obj = {...obj, 'id':this.$route.params.id}
			}else{
				rota+='salvar'
				delete obj.id
			}
			new HttpRequest().Post(rota, obj).then((data)=>{
				if(data?.data?.success){
					this.loadingPanel = false
					this.$router.push({name:'listarProdutos'})
				}
				else{
					this.loadingPanel = false
					this.conectionError();
				}
			})
		},
		conectionError(){
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				html: `
                Tente novamente mais tarde, se o erro persistir
                <br>Entre em contato com o suporte nos números: 
                <br>49 2049-4936 e 49 99118-2530 ou nos emails suporte@eagletrack.com.br e comercial@eagletrack.com.br.`,
				footer: ''
			})
		},
		inputMensalidade(value){
			this.valueMensalidade = value
		},
		inputDescricao(value){
			this.valueDescricao = value
		}
	},
	async mounted(){
		if(this.$route.params.id){
			let uri ='/administrativo/cadastros/produtos/busca'
			new HttpRequest().Post(uri, {id:this.$route.params.id})
				.then((data)=>{
					this.valueDescricao   = data.data.produto.msdescricao
					this.valueMensalidade = data.data.produto.msmensalidade
				}) 
		}
	}
})
</script>
<style lang="scss">
    .campoObri{
        margin-top: 6px;
        font-size: 14px;
        text-align: left;
        margin-left: 10px;
    }
</style>