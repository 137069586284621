<template>
    <panelEagle id="espelhoPonto" 
        :loading="loadingPanel">
        <div class="col-sm-12 nopadding">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage 
                        :icon="mdiTimetable" 
                        titulo="Espelho Ponto"
                    />
                </div>
                <div class="col-sm-6 divButtonsBasic divDontPrint">
                    <basicButtonsRelatoriosEIG 
                    @exportarRelatorio ="exportarRelatorio" 
                    @gerarRelatorio    ="gerar"
                    :loading           ='loadingExportar'
                    :disabled          ="$v.$invalid"
                    :disabledDropdown  ="dadosRelatorio.length == 0">
                    <slot slot="exportacao">
                        <b-dropdown-item
                        @click="exportarRelatorio('portaria')" 
                        class="exportPdfPortaria">
                        <!-- <basicIcon 
                        v-if='!loadingExportar[3]' 
                        size='18' 
                        :icon='iconPdf'/> -->
                        <b-spinner 
                        v-if='loadingExportar[3]' small label="Exportando"/>
                            Portaria 671
                        </b-dropdown-item>
                    </slot>
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12"><hr></div>
                    <div class="col-sm-12 nopadding row">
                        <div class="col-sm-4 nopadding"> 
                            <inputRangeWithLimit 
                            :isObrigatorio="true" 
                            name="IntervaloEspelhoPonto"
                            @changeInput="changeIntervalo"/>
                        </div>
                        <div class="col-sm-4 nopadding"> 
                            <selectAll 
                            nameForRadio="EmpresasEspelhoPonto" 
                            firstSelected="E" 
                            :labels="[{indexDFH: 'E', description: 'Empresas*'}]" 
                            :isMultiple="true" 
                            :hasSelectAll="true"
                            :selected="selectedEmpresa"
                            :optionsArray="optionsEmpresa" 
                            @changeSelect="changeEmpresa"/>
                        </div>
                        <div class="col-sm-4 nopadding"> 
                            <selectAll 
                            ref="selectColaboradores"
                            nameForRadio="ColaboradoresEspelhoPonto" 
                            firstSelected="E" 
                            :labels="[{indexDFH: 'E', description: 'Colaboradores*'}]"
                            :isMultiple="true" 
                            :hasSelectAll="true"
                            :selected="selectedColaboradores"
                            :loading="loadingColaboradores"
                            :disabled="$v.empresaSelecionada.$invalid"
                            :optionsArray="optionsColaboradores" 
                            @changeSelect="changeColaboradores"/>
                        </div>
                    </div>
                </slot>
                <div class="col-sm-3 row 
                    mt-3
                    divFeriadoSumarioTabelaDetalhada">
                    <!-- mdiCheckboxBlankCircle -->
                    <span class="col-sm-12 row pt-2 pl-1 pb-1">
                        <div>
                            <baseIcon
                            size='18'
                            class="azulIcone"  
                            :icon="mdiCheckboxBlankCircle"/>
                        </div>
                        <div>
                            Feriado
                        </div>
                    </span>
                    <span
                    class="col-sm-12 row pt-1 pl-1 pb-2" 
                    title="Dias trabalhados fora de jornada">
                        <div>
                            <baseIcon
                            size='18'
                            class="verdeIcone" 
                            :icon="mdiCheckboxBlankCircle"/>
                        </div>
                        <div>
                            Dias trabalhados fora de jornada
                        </div>
                    </span>
                            </div>
            </slideUpAndDown>
            <div class="col-sm-12"><hr></div>
        </div>
        <tableRelatorio>
            <slot slot="thead">
                <tr class="cabecalho">
                    <th rowspan="2">Data</th>
                    <th :colspan="quantRegistros*2">Registros</th>
                    <th colspan="4">Totais da jornada</th>
                    <th colspan="3">Horas extras</th>
                    <th rowspan="2">Saldo</th>
                    <th rowspan="2">Eventos</th>
                </tr>
                <tr class="detalhesCabecalho">
                    <!-- Registros -->
                    <template v-for="n in quantRegistros">
                        <th :key="n+'_entrada'">{{n}}° Entrada</th>
                        <th :key="n+'saida'">{{n}}° Saída</th>
                    </template>
                    <!-- Totais da jornada -->
                    <th>Horas normais</th>
                    <th>Intervalo</th>
                    <th>Horas falta</th>
                    <th>Horas noturnas</th>
                    <!-- Horas extras -->
                    <th>Extra 1</th>
                    <th>Extra 100%</th>
                    <th>Extra Noturna</th>
                </tr>
            </slot>
            <slot slot="tbody">
                <statusInformation 
                    v-if="dadosRelatorio.length == 0"
                    typeBar="tr" 
                    colspanForTd="21"
                    :statusBar="statusbar">
                </statusInformation>
                <template v-for="(colaboradores, mtcodigo) in dadosRelatorioShow">
                    <tr :key="mtcodigo+'badge'"
                        class="backColorTdPDF">
                        <td colspan="21" style="padding:0px;">
                            <span class="newbadge">
                               {{separaCodigoNome(mtcodigo)}}
                            </span>
                        </td>
                    </tr>
                    <template v-for="(dados, dia) in colaboradores">
                        <tr :key="dia+mtcodigo+'tabela'" 
                            :style = "retornaFundo(dados)"
                            v-show="dia != 'totalHoras'">
                            <td>
                                <span class="nowrap">
                                    {{formataData(dia)}}
                                </span>
                            </td>
                            <template v-for="n in quantRegistros">
                                <td :key="n +'_entrada1'">
                                    <span
                                    :class="{
                                        'sublinhado':pegaCrajustado(
                                            n, dados.registros, true
                                        )
                                    }">
                                        {{entradaSaida(n, dados.registros, true)}}
                                    </span>
                                </td>
                                <td :key="n+'_saida1'">
                                    <span
                                    :class="{
                                        'sublinhado':pegaCrajustado(
                                            n, dados.registros, false
                                        )
                                    }">
                                        {{entradaSaida(n, dados.registros, false)}}
                                    </span>
                                </td>
                            </template>
                            <td>
                                {{dados.fphoranormal ? dados.fphoranormal : '00:00:00'}}
                            </td>
                            <td>
                                {{dados.fpintervalo ? dados.fpintervalo : '00:00:00'}}
                            </td>   
                            <td>
                                {{dados.fphorasfalta ? dados.fphorasfalta: '00:00:00'}}
                            </td>
                            <td>
                                {{
                                    dados.fptotalnoturnas ? 
                                    dados.fptotalnoturnas: '00:00:00'
                                }}
                            </td>
                            <td>
                                {{dados.fpextra1 ? dados.fpextra1: '00:00:00'}}
                            </td>
                            <td>
                                {{dados.fpextra100 ? dados.fpextra100: '00:00:00'}}
                            </td>
                            <td>
                                {{ dados.fpextranoturno }}
                            </td>                            
                            <td>
                                {{ dados.saldo }}
                            </td>
                            <td>
                                {{ dados.evento }}
                            </td>
                        </tr>
                    </template>
                    <tr :key="mtcodigo+'_totalizadores'"
                        class="totalizador">
                        <td :colspan="quantRegistros*2+1"/>
                        <td>
                            {{colaboradores.totalHoras[0]}}
                        </td>
                        <td>
                            {{colaboradores.totalHoras[1]}}
                        </td>
                        <td>
                            {{colaboradores.totalHoras[2]}}
                        </td>
                        <td>
                            {{colaboradores.totalHoras[3]}}
                        </td>
                        <td>
                            {{colaboradores.totalHoras[4]}}
                        </td>
                        <td>
                            {{colaboradores.totalHoras[5]}}
                        </td>
                        <td>{{ colaboradores.totalHoras[6] }}
                        </td>
                        <td>{{retornarSaldo(colaboradores)}}</td>
                        <td></td>
                    </tr>
                </template>
            </slot>
        </tableRelatorio>
    </panelEagle>
</template>
<script>
import Vue from 'vue'
import {
	mdiTimetable,
	mdiCheckboxBlankCircle,
    mdiFilePdfBox
} from '@mdi/js'
import { DateTime } from 'luxon'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { conectionError } from '@/Services/Helpers/swellHeper'

import { EmpresasService } from '@/Services/auth/Empresas.service'
export default Vue.extend({
	name:'espelhoPontoCheckpoint',
	components:{
		panelEagle:require( '@/components/Atom/Panel/PanelEagle').default,
		tituloPage:require( '@/components/Atom/Header/Titulo').default,
		basicButtonsRelatoriosEIG:require( '@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
		slideUpAndDown:require( '@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
		selectAll:require( '@/components/Atom/Select/SelectAll').default,
		inputRangeWithLimit:require( '@/components/Atom/Datas/InputRangeWithLimit').default,
		// basicIcon:require('@/components/Atom/Icon/BaseIcon.vue').default,
		statusInformation:require( '@/components/Atom/StatusInformation/StatusInformation').default,
		tableRelatorio:require( '@/components/Atom/Table/TableRelatorio').default,
		baseIcon:require( '@/components/Atom/Icon/BaseIcon').default,

	},

	validations:{
		empresaSelecionada : { required },
		colaboradoresSelecionados : { required },
	},

	data(){
		return{
			mdiTimetable:mdiTimetable,
			mdiCheckboxBlankCircle:mdiCheckboxBlankCircle,
            iconPdf: mdiFilePdfBox,
			urlBase:'/checkpoint/relatorios/espelho/ponto/',

			// loading
			loadingPanel         : false,
            // loadingPortaria      : false,
			loadingExportar      : [false, false, false, false],
			loadingColaboradores : false,

			// Options
			optionsEmpresa       : new EmpresasService().Get(),
			optionsColaboradores : [],

			// Valores
			intervaloSelecionado      : '',
			empresaSelecionada        : [],
			colaboradoresSelecionados : [],

			// Selecteds
			selectedEmpresa           : [],
			selectedColaboradores     : [],

            // dados
            dadosRelatorio: [],
            statusbar: 'info',
            quantRegistros: 2,

        }
    },
    methods: {
        ...mapGetters(['getMaster']),

        /**
         * @author Guih
        */
        separaCodigoNome(strnome){
			let reg = /\|\s\d+/
			let name = strnome.replace(reg, '')
			return name
		},

		pegaCrajustado(n, dados, condicao){
			if(dados === undefined) return false
			if(condicao === true && dados[n - 1]){ 
				return dados[n - 1].ajusteentrada
			}else if(condicao === false && dados[n - 1]){
				return dados[n - 1].ajustesaida
			}else{
				return false
			}
		},

		retornaDados(dados){
			return dados
		},  

		/**
         * Faz uma busca pelo ultimo valor nao nulo na parte de saldo
         * da tabela
         * @param {object} dados - O objt de colaborador na tabela
         * @returns { String } - O ultimo saldo nao nulo
         * @author Lucas Eduardo
         */
		retornarSaldo(dados){
			var novos = {...dados}
			delete novos.totalHoras
			var ultimo = Object.keys(novos).filter((v) => novos[v].saldo != null)
			return novos[ultimo.at(-1)].saldo
		},
		changeIntervalo(intervalo){
			this.intervaloSelecionado = intervalo
			this.dadosRelatorio = []
		},

		changeEmpresa(empresa){
			this.empresaSelecionada = empresa
			this.$refs.selectColaboradores.clearAll()
			this.dadosRelatorio = []
			if(empresa.length > 0)
				this.buscaColaborador(empresa)
		},

		changeColaboradores(colaboradores){
			this.colaboradoresSelecionados = colaboradores 
			this.dadosRelatorio = []
		},

		buscaColaborador(empresa){
			this.loadingColaboradores = true
			new FiltrosService().dados_filtros(empresa, ['MAD'])
				.then((dados)=>{
					this.optionsColaboradores = dados.MAD
					this.loadingColaboradores = false
				})
		},

		/**
         * func para fazer um pre tratamento dos dados no front
         * no caso ela foi criada para deixar o saldo sempre vindo o anterior
         * caso ele seja nulo
         * nao foi mudado no backend para n mudar a logica dele
         * teria que mudar isso no backend futuramente
         * @param { Object } dados - dados para gerar a tabela
         * @author Lucas Eduardo
         */
		arrumaDadosRelatorio(dados){
			this.dadosRelatorio = []
			for(const colab in dados){
				var saldo = '00:00:00'
				for(const dia in dados[colab]){
					if(dia !== 'totalHoras'){
						dados[colab][dia].saldo = dados[colab][dia].saldo??saldo
						saldo = dados[colab][dia].saldo??saldo
					}
				}
			}
			this.dadosRelatorio = dados
		},

		gerar(){
			this.loadingPanel = true
			var obj = {
				'empresas'      : this.empresaSelecionada,
				'colaboradores' : this.colaboradoresSelecionados,
				'data'          : this.intervaloSelecionado,
			}
			new HttpRequest().Post(this.urlBase+'gerar/relatorio', obj)
				.then((dados)=>{
					if(dados.code == 200){
						// this.dadosRelatorio = dados.data.dados
						this.arrumaDadosRelatorio(dados.data.dados)
						this.quantRegistros = dados.data.registros
						if(this.dadosRelatorio.length == 0){
							this.statusbar = 'error'
						}
					} else {
						this.statusbar = 'error2'
					}
				}).catch((err) => {
					conectionError()
				}).finally(() => { this.loadingPanel = false })
		},

		exportarRelatorio(value){
			switch (value) {
				case 'pdf': this.loadingExportar = [true, false, false, false]; break;
				case 'xls': this.loadingExportar = [false, true, false, false]; break;
                case 'portaria' : this.loadingExportar = [false, false, false, true]; break;
				default: this.loadingExportar = [false, false, true, false]; break;
			}
			var root =  process.env.VUE_APP_ROOT;
			var obj = {
				'colaboradores': this.colaboradoresSelecionados,
				'empresas': this.empresaSelecionada,
				data: this.intervaloSelecionado,
				tipo: value,
				dados: this.dadosRelatorio,
				registros: this.quantRegistros,
			}
			new HttpRequest().Post(this.urlBase+'exportar', obj)
				.then(dados=>{
					window.open(root+'/'+dados.data.local); 
				}).catch(() =>{
					conectionError()
				}).finally(() => this.loadingExportar = [false, false, false])

		},

		formataData(data){
			var diaSemana = [
				'',
				'Seg',
				'Ter',
				'Qua',
				'Qui',
				'Sex',
				'Sab',
				'Dom'
			];
			var dia = DateTime.fromFormat(data, 'dd/LL/yyyy');
			var diaMes = DateTime.fromFormat(data, 'dd/LL/yyyy');
			dia = dia.toFormat('c');
			diaMes = diaMes.toFormat('dd');
			return `${diaSemana[dia]} (${diaMes})`;
		},

		retornaFundo(dados){
			if(dados.jornadafora && dados.feriado){
				return 'background-color:#82b3dd'
			}else if(!dados.jornadafora && dados.feriado){
				return 'background-color:#82b3dd'
			}else if(dados.jornadafora && !dados.feriado){
				return 'background-color:#b5cd8e'
			}
		},

		entradaSaida(n, dados, condicao){
			if(dados === undefined) return '00:00:00';
			if(condicao === true && dados[n - 1]){ 
				return dados[n - 1].entrada;
			}else if(condicao === false && dados[n - 1]){
				return dados[n - 1].saida;
			}else{
				return '00:00:00';
			}
		},
                
	},

    computed:{
        dadosRelatorioShow(){
            return this.dadosRelatorio
        }
    },

	mounted(){
		if(!this.getMaster()){
			this.selectedEmpresa = this.optionsEmpresa
		}
	}
})
</script>

<style lang="scss">
#espelhoPonto {
    .sublinhado {
        // text-decoration: underline;
        // ta com bg pq o underline
        // corta algumas vezes nessa fonte
        white-space: nowrap;
        padding-left: 5px;
        padding-right: 5px;
        background: linear-gradient(to left, black);
        background-position: 0 100%;
        background-size: 100% 1px;
        background-repeat: repeat-x;

        &::after {
            content: "*";
        }
    }

    .cabecalho {
        th {
            text-align: center !important;
            vertical-align: middle;
        }
    }

    .detalhesCabecalho {
        th {
            top: 30.5px;
        }
    }


    .totalizador {
        // color: #fff;
        background-color: #afafaf;
    }

    .exportPdfPortaria {
        text-align: center !important;
        word-wrap: initial;
    }

    .divFeriadoSumarioTabelaDetalhada {
        width: 100%;
        background: rgba(204, 215, 231, 0.979);
        height: 100%;
        padding-left: 5px !important;
        padding-right: 5px !important;
        margin-left: 15px !important;
    }

    .azulIcone {
        color: #82b3dd;
    }

    .verdeIcone {
        color: #b5cd8e;
    }
}
</style>
s