<template>
<div>
    <template>
        <modalEagle 
            :id="idModal"
            title="Ações: Adicionar Jornada"
            size='lg'>
            <template slot="modalBody">
                <b-overlay :show="loading">
                    <div class="col-sm-12 row divInputJornada">
                        <div class="col-sm-12 row divInputJornada">
                            <selectAll 
                                class="divInputJornada"
                                nameForRadio="SeletorVeiculosModalAdicionarEvento"
                                :isMultiple="true" 
                                :labels="labelsSelectVeiculosModal" 
                                :optionsArray="optSelectVeiculosModalJornada" 
                                firstSelected="E" 
                                :hasSelectAll="true"
                                :loading='carregando'
                                @changeSelect="changeVeiculoModalOpcoesJornada" >
                            </selectAll>
                        </div>
                        <div class="col-sm-12 row divInputJornada">
                            <selectAll 
                                class="divInputJornada"
                                nameForRadio="SeletorJustificativa"
                                :isMultiple="false" 
                                :labels="labelsSelectJustificativa" 
                                :optionsArray="optJustificativas" 
                                firstSelected="J" 
                                :hasSelectAll="false"
                                :loading='carregando'
                                @changeSelect="changeJustificativaModal" >
                            </selectAll>
                        </div>
                    </div>
                    <div class="col-sm-12 hrJornada"><hr></div>
                    <!-- Inputs modal -->
                    <div v-if="!$v.$invalid">
                        <!-- Inputs de Jornada -->
                        <div>
                            <div class="pl-3"> Jornada </div>
                            <div class="col-sm-12 row divInputJornada">
                                <div class="col-sm-2 row divInputJornada">
                                    <inputSimple 
                                        name='inputInicioJornadaModal'
                                        class='inputAdicionarJornada pr-1 pl-1'
                                        type="time"
                                        label="Inicio"
                                        value='00:00:00' 
                                        @changeInput="changeInicioJornadaModal">
                                    </inputSimple>
                                </div>
                                <div class="col-sm-2 row divInputJornada">
                                    <inputSimple 
                                        name='inputFimJornadaModal'
                                        class='inputAdicionarJornada pr-1 pl-1'
                                        type="time"
                                        label="Fim" 
                                        value='00:00:00'
                                        @changeInput="changeFimJornadaModal">
                                    </inputSimple>
                                </div>
                                <div class="col-sm-2 row divInputJornada">
                                    <inputSimple 
                                        name='inputTotalJornadaModal'
                                        class='inputAdicionarJornada pr-1 pl-1'
                                        type="time"
                                        label="Total" 
                                        value='00:00:00'
                                        @changeInput="changeTotalJornadaModal">
                                    </inputSimple>
                                </div>
                                <div class="col-sm-2 row divInputJornada">
                                    <inputSimple 
                                        name='inputNormalJornadaModal'
                                        class='inputAdicionarJornada pr-1 pl-1'
                                        type="time"
                                        label="Normal" 
                                        value='00:00:00'
                                        @changeInput="changeNormalJornadaModal">
                                    </inputSimple>
                                </div>
                                <div class="col-sm-2 row divInputJornada">
                                    <inputSimple 
                                        name='inputEsperaJornadaModal'
                                        class='inputAdicionarJornada pr-1 pl-1'
                                        type="time"
                                        label="Espera" 
                                        value='00:00:00'
                                        @changeInput="changeEsperaJornadaModal">
                                    </inputSimple>
                                </div>
                                <div class="col-sm-2 row divInputJornada">
                                    <inputSimple 
                                        name='inputIntervaloJornadaModal'
                                        class='inputAdicionarJornada pr-1 pl-1'
                                        type="time"
                                        label="Intervalo" 
                                        value='00:00:00'
                                        @changeInput="changeIntervaloJornadaModal">
                                    </inputSimple>
                                </div>
                            </div>
                            <div class="col-sm-12 row divInputJornada">
                                <div class="col-sm-2 row divInputJornada">
                                    <inputSimple 
                                        name='inputDiurnaJornadaModal'
                                        class='inputAdicionarJornada pr-1 pl-1'
                                        type="time"
                                        label="Diurna" 
                                        value='00:00:00'
                                        @changeInput="changeDiurnaJornadaTotal">
                                    </inputSimple>
                                </div>
                                <div class="col-sm-2 row divInputJornada">
                                    <inputSimple 
                                        name='inputNoturnaJornadaModal'
                                        class='inputAdicionarJornada pr-1 pl-1'
                                        type="time"
                                        label="Noturna" 
                                        value='00:00:00'
                                        @changeInput="changeNoturnaJornadaTotal">
                                    </inputSimple>
                                </div>
                                <div class="col-sm-2 row divInputJornada">
                                    <inputSimple 
                                        name='inputFaltaJornadaTotal'
                                        class='inputAdicionarJornada pr-1 pl-1'
                                        type="time"
                                        label="Falta" 
                                        value='00:00:00'
                                        @changeInput="changeFaltaJornadaTotal">
                                    </inputSimple>
                                </div>
                                <!-- Input de data -->
                                <div class="col-sm-4 row divInputJornada">
                                    <inputRangeWithLimit 
                                        :titulo= "'Data Início'"
                                        :intervalo= "3"
                                        :value= "dataExcluirJornada+' - '+dataExcluirJornada"
                                        name="inputDataModalAdcJornada"
                                        opens="right"
                                        :extraClass="'nopadding'"
                                        @changeInput="changeDataIntervalo" > 
                                    </inputRangeWithLimit>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12"><hr></div>
                        <!-- Input dos Extras -->
                        <div>
                            <div class="pl-3"> Extras </div>
                            <div class="col-sm-12 row divInputJornada">
                                <div class="col-sm-2 row divInputJornada">
                                    <inputSimple 
                                        name='inputHE1ExtrasModalJornada'
                                        class='inputAdicionarJornada pl-1 pr-1'
                                        type="time"
                                        label="H.E.1 " 
                                        value='00:00:00'
                                        @changeInput="changeHE1ExtrasModalJornada">
                                    </inputSimple>
                                </div>
                                <div class="col-sm-2 row divInputJornada">
                                    <inputSimple 
                                        name='inputHE2ExtrasModalJornada'
                                        class='inputAdicionarJornada pl-1 pr-1'
                                        type="time"
                                        label="H.E.2 " 
                                        value='00:00:00'
                                        @changeInput="changeHE2ExtrasModalJornada">
                                    </inputSimple>
                                </div>
                                <div class="col-sm-2 row divInputJornada">
                                    <inputSimple 
                                        name='inputHE3ExtrasModalJornada'
                                        class='inputAdicionarJornada pl-1 pr-1'
                                        type="time"
                                        label="H.E.3" 
                                        value='00:00:00'
                                        @changeInput="changeHE3ExtrasModalJornada">
                                    </inputSimple>
                                </div>
                                <div class="col-sm-2 row divInputJornada">
                                    <inputSimple 
                                        name='input100%ExtrasModalJornada'
                                        class='inputAdicionarJornada pl-1 pr-1'
                                        type="time"
                                        label="Extra 100%" 
                                        value='00:00:00'
                                        @changeInput="changeCemExtrasModalJornada">
                                    </inputSimple>
                                </div>
                                <div class="col-sm-2 row divInputJornada">
                                    <inputSimple 
                                        name='inputExtraNoturnaExtrasModalJornada'
                                        class='inputAdicionarJornada pl-1 pr-1'
                                        type="time"
                                        label="Extra Noturna" 
                                        value='00:00:00'
                                        @changeInput="changeExtraNoturnaExtrasModalJornada">
                                    </inputSimple>
                                </div>
                                <div class="col-sm-2 row divInputJornada">
                                    <inputSimple 
                                        name='inputExtraNoturnaExtrasModalJornada'
                                        class='inputAdicionarJornada pl-1 pr-1'
                                        type="time"
                                        label="Extra 100% Noturna" 
                                        value='00:00:00'
                                        @changeInput="changeExtraCemNoturnaExtrasModalJornada">
                                    </inputSimple>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12"><hr></div>
                        <!-- Input Outros -->
                        <div>
                            <div class="pl-3"> Outros </div>
                            <div class="col-sm-12 row divInputJornada ">
                                <div class="col-sm-3 row divInputJornada">
                                    <inputSimple 
                                        name='inputEmDirecaoJornadaModal'
                                        class='inputAdicionarJornada pl-1 pr-1'
                                        type="time"
                                        label="Em direção" 
                                        value='00:00:00'
                                        @changeInput="changeEmDirecaoOutrosJornadaModal">
                                    </inputSimple>
                                </div>
                                <div class="col-sm-3 row divInputJornada">
                                    <inputSimple 
                                        name='inputParadaJornadaModal'
                                        class='inputAdicionarJornada pl-1 pr-1'
                                        type="time"
                                        label="Parado em jornada" 
                                        value='00:00:00'
                                        @changeInput="changeParadaOutrosJornadaModal">
                                    </inputSimple>
                                </div>
                                <div class="col-sm-3 row divInputJornada">
                                    <inputSimple 
                                        name='inputNoturnaReduzidaJornadaModal'
                                        class='inputAdicionarJornada pl-1 pr-1'
                                        type="time"
                                        label="Noturna reduzida" 
                                        value='00:00:00'
                                        @changeInput="changeNoturnaReduzidaOutrosJornadaModal">
                                    </inputSimple>
                                </div>
                                <div class="col-sm-3 row divInputJornada">
                                    <inputSimple 
                                        name='inputDescansoJornadaModal'
                                        class='inputAdicionarJornada pl-1 pr-1'
                                        type="time"
                                        label="Descanso remunerado" 
                                        value='00:00:00'
                                        @changeInput="changeDescansoOutrosJornadaModal">
                                    </inputSimple>
                                </div>
                            </div>
                            <div class="col-sm-12 row divInputJornada">
                                <div class="col-sm-3 row divInputJornada">
                                    <inputSimple 
                                        name='inputInterjornadaJornadaModal'
                                        class='inputAdicionarJornada pl-1 pr-1'
                                        type="time"
                                        label="Interjornada" 
                                        value='00:00:00'
                                        @changeInput="changeInterjornadaOutrosJornadaModal">
                                    </inputSimple>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-overlay>
            </template>
            <template>
                <span :class="foiSalvoModalOpcoesAdicionarEvento == 200 ?
                    'mensagemModalAdicionarEventoVerde':
                    'mensagemModalAdicionarEventoVermelho'"> {{mensagem}} </span>
                <simpleButton 
                    :icon="mdiCloseThick" 
                    text="Cancelar" 
                    event="click" 
                    @click="fecharModalAdicionarJornada" 
                    type="red">
                </simpleButton>
                <simpleButton 
                    :icon="mdiCheckBold" 
                    text="Confirmar" 
                    event="click" 
                    @click="salvarJornadaModalAdicionarJornada"
                    :disabled="$v.$invalid"
                    type="blue">
                    </simpleButton>
            </template>
        </modalEagle>
    </template>
</div>
</template>
<script>
import Vue from 'vue'
import { mdiCheckBold, mdiCloseThick} from '@mdi/js'
import { required } from 'vuelidate/lib/validators'
import {HttpRequest} from '../../../Services/auth/HttpRequest.Service'
import {FiltrosService} from '../../../Services/filtros/filtros.Service'
export default Vue.extend({
    name:'ModalAdicionarJornada',
    components:{
        'simpleButton': require('@/components/Atom/Buttons/SimpleButton').default,
        'modalEagle': require('@/components/Atom/Modal/ModalEagle').default,
        'selectAll': require('@/components/Atom/Select/SelectAll').default,
        'inputSimple': require('@/components/Atom/Inputs/InputSimple').default,
		'inputRangeWithLimit': require('@/components/Atom/Datas/InputRangeWithLimit').default,
    },

    props: {
		idModal: {
			type: [Number, String],
			required: true
		},
	},

    validations:{
        veiculosSelecionados:{
            required,
        },
        justificativaSelecionada:{
            required,
        },
    },

    data(){
        return{
            mdiCloseThick : mdiCloseThick,
            mdiCheckBold : mdiCheckBold,
            labelsSelectVeiculosModal:[
                {indexDFH: 'V', description: 'Veículos*'},
            ],
            labelsSelectJustificativa:[
                {indexDFH: 'J', description: 'Justificativa*'},
            ],
            veiculosSelecionados : [],
            justificativaSelecionada : null,
            colabSelecionado : null,
            inicioJornadaModalJornada : [],
            fimJornadaModalJornada : [],
            totalJornadaModalJornada : [],
            normalJornadaModalJornada : [],
            esperaJornadaModalJornada : [],
            intervaloJornadaModalJornada : [],
            noturnaJornadaModalJornada : [],
            faltaJornadaModalJornada : [],
            dataExcluirJornada : '',
            dataInicio : [],
            optSelectVeiculosModalJornada : [],
            loading : false,
            carregando : false,
            mensagem : '',
            foiSalvoModalOpcoesAdicionarEvento: [],
            optJustificativas: [],
        }
    },

    methods:{

		changeDataIntervalo(data){
			this.dataInicio = data
		},

        /**
         * @description Essa função deve ser utilizada por uma referência para "ativar"
         * essa modal, os argumentos da função são os necessários para 
         * conseguir popular a modal com as infomações corretas
         * @param {array}  empresas - empresas selecionadas no relatório
         * @param {string} dataExcluirJornada - data do campo selecionado
         * @param {int}  colabSelecionado - colaborador selecionado no relatório
         * @author Vitor Hugo 🐨 
         */
        preparaModalAdicionarJornada(empresas, colabSelecionado, dataExcluirJornada){
            this.limpaInformacoesModal()
            this.colabSelecionado = colabSelecionado
            this.dataExcluirJornada = dataExcluirJornada
            this.modalAdicionarJornada()
            this.buscaVeiculosModalJornada(empresas)
        },

        limpaInformacoesModal(){
            this.veiculosSelecionados = []
            this.justificativaSelecionada = null
            this.colabSelecionado = null
            this.inicioJornadaModalJornada = []
            this.fimJornadaModalJornada = []
            this.totalJornadaModalJornada = []
            this.normalJornadaModalJornada = []
            this.esperaJornadaModalJornada = []
            this.intervaloJornadaModalJornada = []
            this.noturnaJornadaModalJornada = []
            this.faltaJornadaModalJornada = []
            this.dataExcluirJornada = ''
            this.optSelectVeiculosModalJornada = []
            this.optJustificativas = []
        },

        // Modal Adicionar Jornada ---------------------------------------------------------------------------
        modalAdicionarJornada(){
            this.veiculosSelecionados = []
            this.justificativaSelecionada = null
            this.mensagem = ''
            this.$bvModal.show(this.idModal)
        },

        changeVeiculoModalOpcoesJornada(veiculos){
            this.veiculosSelecionados = veiculos
        },
        changeJustificativaModal(value){
            this.justificativaSelecionada = value && value.length ? value[0] : null
        },
        changeInicioJornadaModal(inicio){
            this.inicioJornadaModalJornada = inicio
        },
        changeFimJornadaModal(fim){
            this.fimJornadaModalJornada = fim
        },
        changeTotalJornadaModal(total){
            this.totalJornadaModalJornada = total
        },
        changeNormalJornadaModal(normal){
            this.normalJornadaModalJornada = normal
        },
        changeEsperaJornadaModal(espera){
            this.esperaJornadaModalJornada = espera
        },
        changeIntervaloJornadaModal(intervalo){
            this.intervaloJornadaModalJornada = intervalo
        },
        changeDiurnaJornadaTotal(diurna){
            this.diurnaJornadaTotal = diurna
        },
        changeNoturnaJornadaTotal(noturna){
            this.noturnaJornadaModalJornada = noturna
        },
        changeFaltaJornadaTotal(falta){
            this.faltaJornadaModalJornada = falta
        },
        changeHE1ExtrasModalJornada(HE1){
            this.HE1ExtrasModalJornada = HE1
        },
        changeHE2ExtrasModalJornada(HE2){
            this.HE2ExtrasModalJornada = HE2
        },
        changeHE3ExtrasModalJornada(HE3){
            this.HE3ExtrasModalJornada = HE3
        },
        changeCemExtrasModalJornada(cem){
            this.cemExtrasModalJornada = cem
        },
        changeExtraNoturnaExtrasModalJornada(noturna){
            this.notunaExtrasModalJornada = noturna
        },
        changeExtraCemNoturnaExtrasModalJornada(noturnaCem){
            this.notunaCemExtrasModalJornada = noturnaCem
        },
        changeEmDirecaoOutrosJornadaModal(direcao){
            this.emDirecaoOutrosModalJornada = direcao
        },
        changeParadaOutrosJornadaModal(parada){
            this.paradoJornadaOutrosModalJornada = parada
        },
        changeNoturnaReduzidaOutrosJornadaModal(reduzida){
            this.noturnaReduzidaOutrosModalJornada = reduzida
        },
        changeDescansoOutrosJornadaModal(descanso){
            this.desRemuneradoOutrosModalJornada = descanso
        },
        changeInterjornadaOutrosJornadaModal(interjornada){
            this.interjornadaOutrosJornada = interjornada
        },
        async salvarJornadaModalAdicionarJornada(){
            this.loading = true
            var body = {
                'dataInicio' : this.dataInicio.split(' - ')[0],
                'dataFim' : this.dataInicio.split(' - ')[1],
                'inicio' : this.inicioJornadaModalJornada,
                'fim' : this.fimJornadaModalJornada,
                'total' : this.totalJornadaModalJornada,
                'normal' : this.normalJornadaModalJornada,
                'espera' : this.esperaJornadaModalJornada,
                'intervalo' : this.intervaloJornadaModalJornada,
                'diurna' : this.diurnaJornadaTotal,
                'noturna' : this.noturnaJornadaModalJornada,
                'falta' : this.faltaJornadaModalJornada,
                'he1' : this.HE1ExtrasModalJornada,
                'he2' : this.HE2ExtrasModalJornada,
                'he3' : this.HE3ExtrasModalJornada,
                'ex100' : this.cemExtrasModalJornada,
                'exnoturna' : this.notunaExtrasModalJornada,
                'excemnoturna' : this.notunaCemExtrasModalJornada,
                'direcao' : this.emDirecaoOutrosModalJornada,
                'parado' : this.paradoJornadaOutrosModalJornada,
                'reduzida' : this.noturnaReduzidaOutrosModalJornada,
                'descremunerado' : this.desRemuneradoOutrosModalJornada,
                'interjornada' : this.interjornadaOutrosJornada,
                'veiculos' : this.veiculosSelecionados,
                'colab' : this.colabSelecionado,
                'data' : this.dataExcluirJornada,
                'justificativa' : this.justificativaSelecionada,
            }
            var dados = await new HttpRequest()
                .Post('/controle/jornada/relatorios/folha/ponto/salvarJornada', body)
            this.foiSalvoModalOpcoesAdicionarEvento = dados.code
            if(dados.code == 200){
                this.mensagem = dados.data.message
                this.$emit('atualizarRelatorio')
                this.$bvModal.hide(this.idModal)
            } else {
                this.mensagem = 'Erro, contate o suporte'
            }
            this.loading = false
        },

        //Métodos para buscar veículos para o select do modal Jornada
        buscaVeiculosModalJornada(arr){
            if(arr.length > 0){
                this.carregando = true
                new FiltrosService().dados_filtros(arr, ['V', 'JJ'])
                .then((data)=>{
                    this.optSelectVeiculosModalJornada = data.V
                    this.optJustificativas = data.JJ
                })
                this.carregando = false
            }else{
                // this.$refs.selectVMJ.clearAll()
            }
        },

        fecharModalAdicionarJornada(){
            this.veiculosSelecionados = []
            this.justificativaSelecionada = null
            this.$bvModal.hide(this.idModal)
        },

    },
})
</script>

<style lang="scss">
// Modal Adicionar Jornada
#modalAdicionarJornada{
    //Modal Ações Jornada
    .divInputJornada{
        margin-top: 5px !important;
        padding-right: 5px !important;
        padding-left: 5px !important;
    }
    .mensagemModalAdicionarEventoVerde{
        color: green !important;
    }
    .mensagemModalAdicionarEventoVermelho{
        color: #e04b4a;
    }
}
</style>