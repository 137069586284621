<template>
	<div class="col-sm-12 row nopadding mt-3">
		<div class="col-sm-12 row nopadding" id="aba2PrimeiraLinha">
			<div class="col-sm-3 nopadding">
				<SelectAll ref="refDiaDaSemana" nameForRadio="seletorDia" :isMultiple="false"
					:labels="[{ description: 'Dia da Semana*' }]" :optionsArray="optDiaDaSemana"
					@changeSelect="changeSelectDiaSemana" />
			</div>
			<div class="col-sm-2 nopadding nopadding">
				<InputSimple name="inputHorario" label="Horario*" type="time" @changeInput="mudaHorario" />
			</div>
			<div class="col-sm-2 nopadding">
				<InputSimple name="inputVelocidademedia" label="Vel. Média*" :hasMask="true" :mask="['NNN']"
					@changeInput="mudaVelMedia" />
			</div>
			<div class="col-sm-4 nopadding">
				<SelectAll ref="calendario" :loading="controleLoading" nameForRadio="seletorCelendario" :isMultiple="false"
					:labels="[{ description: 'Calendário*' }]" :optionsArray="optCalendario"
					@changeSelect="changeSelectCalendario" />
			</div>
			<div class="col-sm-1 nopadding d-flex justify-content-start align-items-center">
				<div class="col-sm-1 nopadding">
					<SimpleButton :disabled="$v.$invalid" class="buttonAdicionar mt-2" text="Adicionar" type="blue" event="click"
						@click="adicionarHorario" />
				</div>
			</div>
		</div>
		<div class="col-sm-12 nopadding">
			<div class="
									col-12
									mt-2
									mb-1
									cursor-pointer
							" :class="erroInsercao ? 'erroInsercao' : ''" @click="removeErro">
				<span class="my-auto">{{ erroInsercao }}</span>
			</div>
			<b-overlay :show="carregando" class="nopadding">
				<TableListagem :filter="false" :titles="tableTitles" :data="tableHorariosExibir">
					<template #acoes="{ scope }">
						<span @click="excluirObjDia(scope.row)">
							<BaseIcon style="cursor:pointer" size='18' class="" :icon="mdiCloseThick" />
						</span>
					</template>
				</TableListagem>
			</b-overlay>
		</div>
	</div>
</template>
<script>
import { Duration } from 'luxon'
import { mdiCloseThick } from '@mdi/js'
import { required } from 'vuelidate/lib/validators'
import Vue from 'vue'

export default Vue.extend({
	name: 'TabLinhasDefinirHorario',

	components: {
		SelectAll: require('@/components/Atom/Select/SelectAll.vue').default,
		SimpleButton: require('@/components/Atom/Buttons/SimpleButton.vue').default,
		BaseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
		TableListagem: require('@/components/Atom/Table/TableListagem.vue').default,
		InputSimple: require('@/components/Atom/Inputs/InputSimple.vue').default,
	},

	props: {
		optCalendario: {
			type: Array,
			required: false,
			default: function () { return [] }
		},

		distanciaCalculo: {
			type: Number,
			default: 0
		},

		controleLoading: {
			type: Boolean,
			default: false
		}
	},

	validations: {
		objHorario: {
			hrhorario: { required },
			hrdiasemana: { required },
			hrcalendario: { required },
			hrvelocidademedia: { required },
		},
	},

	data() {
		return {
			mdiCloseThick: mdiCloseThick,
			tableTitles: [
				{ number: 'diaDaSemana', name: 'Dia da Semana' },
				{ number: 'hrhorario', name: 'Horário' },
				{ number: 'hrvelocidademediaTabela', name: 'Velocidade' },
				{ number: 'tempoEstimado', name: 'Tempo Estimado' },
				{ number: 'diaDoCalendario', name: 'Calendário' }
			],
			optDiaDaSemana: [
				{ 'description': 'Todos', 'value': '9' },
				{ 'description': 'Segunda a sexta', 'value': '8' },
				{ 'description': 'Segunda-Feira', 'value': '1' },
				{ 'description': 'Terça-Feira', 'value': '2' },
				{ 'description': 'Quarta-Feira', 'value': '3' },
				{ 'description': 'Quinta-Feira', 'value': '4' },
				{ 'description': 'Sexta-Feira', 'value': '5' },
				{ 'description': 'Sábado', 'value': '6' },
				{ 'description': 'Domingo', 'value': '0' },
			],
			tableHorariosExibir: [],
			erroInsercao: '',
			objHorario: {
				hrdiasemana: '',
				hrhorario: '',
				hrvelocidademedia: '',
				hrcalendario: ''
			},
			carregando: false
		}
	},

	watch: {
		distanciaCalculo() {
			this.atualizaHorarioTabela()
		}
	},

	methods: {
		async arrumaValoresEditar(dados) {
			var tabela = []
			var valores = dados.horarios
			for (const index in valores) {
				var obj = {
					diaDaSemana: valores[index].diaDaSemana,
					diaDoCalendario: valores[index].diaDoCalendario,
					dias: valores[index].dias,
					hrcalendario: valores[index].hrcalendario,
					hrdiasemana: valores[index].hrdiasemana,
					hrhorario: valores[index].hrhorario,
					hrvelocidademedia: valores[index].hrvelocidademedia,
					hrvelocidademediaTabela: valores[index].hrvelocidademediaTabela,
					tempoEstimado: valores[index].tempoEstimado,
					// index:index
				}
				obj.dias = this.arrayDias(obj)
				obj = await this.arrumaObjTabela(obj)
				tabela.push(obj)
			}
			this.tableHorariosExibir = tabela
			this.emiteTabela()
		},

		removeErro() {
			this.erroInsercao = '';
		},

		atualizaHorarioTabela() {
			for (const index in this.tableHorariosExibir) {
				var distanciaTotal = Number(this.distanciaCalculo);
				var velMedia = Number(this.tableHorariosExibir[index].hrvelocidademedia); // .split(' km/h')[0]
				var tempoSeg = distanciaTotal / velMedia;
				var dur = Duration.fromObject({ hours: tempoSeg });
				var tempoHora = dur.shiftTo(`hours`, 'minutes', 'seconds').toObject();
				var tempoEstimado = `${duasCasa(tempoHora.hours)}:${duasCasa(tempoHora.minutes)}:${duasCasa(tempoHora.seconds)}`
				this.tableHorariosExibir[index].tempoEstimado = tempoEstimado;
			}
			function duasCasa(value) {
				value = String(value).split('.')[0];
				if (value.length == 1) {
					return '0' + value;
				}
				return value;
			}
		},

		limpaVariaveis() {
			this.tableHorariosExibir = [];
			this.$refs.calendario.clearAll();
			this.$refs.refDiaDaSemana.clearAll();
		},

		emiteTabela() {
			this.$emit('objTabela', this.tableHorariosExibir, 'tab-2');
		},

		/**
* Função para salvar os dados informados pelo usuário e salva o valor dentro da variável this.objHorario.hrhorario.
* Após salvar limpa os erros de this.erroInsercao.
* @Param valur, Array com o dados do Horário para o Calendário.
* @author Desconhecido 
*/
		mudaHorario(valor) {
			this.erroInsercao = ''
			this.objHorario.hrhorario = valor
		},

		/**
* Função para salvar os dados informados pelo usuário e salva o valor dentro da variável this.objHorario.hrvelocidademedia.
* Após salvar limpa os erros de this.erroInsercao.
* @Param valur, Array com o dados da Velocidade Média.
* @author Desconhecido 
*/
		mudaVelMedia(valor) {
			this.erroInsercao = ''
			this.objHorario.hrvelocidademedia = valor
		},

		/**
	* Função para pegar os dados informados no select de dias da semana e salva na variável
	* this.objHorario.hrdiasemana e limpa os erros que estejam em this.erroInsercao.
	* @Param value,array com os valores do Select dos Dias da Semana.
	* @author Desconhecido 
	*/
		changeSelectDiaSemana(value) {
			this.erroInsercao = ''
			this.objHorario.hrdiasemana = value[0]
		},

		/**
		* Função para pegar os dados informados no select do calendário e salva na variável
		* this.objHorario.objHorario e limpa os erros que estejam em this.erroInsercao.
		* @Param value,array com os valores de 
		* @author Desconhecido 
		*/
		changeSelectCalendario(value) {
			this.erroInsercao = ''
			this.objHorario.hrcalendario = value[0]
		},

		excluirObjDia(dadosTabela) {
			this.carregando = true
			this.erroInsercao = '';
			let index = this.tableHorariosExibir.findIndex(v => {
				return Object.is(v, dadosTabela)
			})
			this.tableHorariosExibir.splice(index, 1);
			this.carregando = false
			this.emiteTabela()
		},

		/**
				 * Uso essa array quando um novo horário é inserido
				 * A array deve conter todos os dias representados
				 * pela equivalência do hrdiasemana
		 * @Autor Desconhecido.
				 */
		arrayDias(obj) {
			if (obj.hrdiasemana < 7) {
				var arr = [Number(obj.hrdiasemana), 9];
				if (obj.hrdiasemana > 0 && obj.hrdiasemana < 6) arr.push(8);
				return arr;
			}
			if (obj.hrdiasemana == 8) {
				return [1, 2, 3, 4, 5, 8, 9];
			}
			if (obj.hrdiasemana == 9) {
				return [0, 1, 2, 3, 4, 5, 6, 8, 9];
			}
		},

		/**
				 * Adiciona o objHorario a lista de horários da linha.
				 * Para isso tem de ser criado um código, baseado no dia
				 * e no horário, garantindo que o usuário não vai adicionar
				 * 2 horarios iguais.
				 * Depois, verifico se o dia em questão esta disponível no
				 * calendário escolhido pelo usuário.
		 * @Autor Desconhecido.
				 */
		async adicionarHorario() {
			this.carregando = true
			this.erroInsercao = ''
			// mudei o bagui que o gui pediu
			let obj = { ...this.objHorario }
			var velMedia = 0
			if (obj.hrvelocidademedia != '') {
				velMedia = (Number(obj.hrvelocidademedia) / 3.6).toFixed(2)
			} else {
				velMedia = this.linha.livelocidademedia
			}
			if (this.verificaSeEhNovo(obj)) {
				if (this.verificaSeDiaUnico(obj)) {
					if (this.disponibilidadeCalendario(obj.hrcalendario, obj.hrdiasemana)) {
						obj['dias'] = this.arrayDias(obj)
						obj = await this.arrumaObjTabela(obj)
						this.tableHorariosExibir.push(obj)
					} else this.erroInsercao = 'Calendário escolhido não contem esse dia'
				} else {
					if (this.verificaMultiplosDias(obj, obj.hrcalendario)) {
						obj['dias'] = this.arrayDias(obj)
						obj = await this.arrumaObjTabela(obj)
						this.tableHorariosExibir.push(obj)
					} else {
						this.erroInsercao = 'Calendário escolhido não contem um ou mais dos dias selecinados'
					}
				}
			} else {
				this.erroInsercao = 'Horário já inserido'
			}
			this.carregando = false
			this.atualizaHorarioTabela()
			this.emiteTabela()
		},

		arrumaObjTabela(value) {
			var diaCalendario = this.optCalendario.filter((v) => {
				if (v.value == value.hrcalendario) {
					return v.description
				}
			})
			var diaSemana = this.optDiaDaSemana.filter((v) => {
				if (v.value == value.hrdiasemana) {
					return v.description
				}
			})
			value.diaDaSemana = diaSemana[0].description
			value.diaDoCalendario = diaCalendario[0].description
			value.tempoEstimado = '00:00:00'
			value.hrvelocidademediaTabela = value.hrvelocidademedia + ' km/h'
			return value
			// optCalendario // hrcalendario
			// optDiaDaSemana // hrdiasemana
		},

		/**
				 * Se o hrdiasemana for maior do que 7
				 * o dado representa segunda a
				 * sexta ou todos os dias.
		 * @Autor Desconhecido.
				 */
		verificaSeDiaUnico() {
			if (this.objHorario.hrdiasemana > 7) {
				return false
			}
			return true
		},

		/**
				 * Verifica se já não existe uma mesma entrada igual
				 * ou similar.
		 * @Autor Desconhecido.
				 */
		verificaSeEhNovo(obj) {
			var existe = this.tableHorariosExibir.find((el) =>
				this.verificaIgualdade(el, obj)
			)
			if (existe === undefined) {
				return true
			}
			return false
		},

		/**
				 * Verifica se o novo objeto (obj)
				 * é igual ou "similar" ao elemento (el)
				 * codigo (0,...,6) - segunda,...,domingo
				 * codigo 8         - segunda a sexta
				 * codigo 9         - todos
				 * @author Gui 🍺
				 */
		verificaIgualdade(el, obj) {
			if (el.hrhorario == obj.hrhorario)
				if (el.dias.includes(Number(obj.hrdiasemana))) return true;
			return false;
		},

		/**
				 * Verifica se o calendário comporta o dia da semana escolhido.
		 * @Autor Desconhecido.
				 */
		disponibilidadeCalendario(calendario, hrdiasemana) {
			let y = this.optCalendario.find((el) => el.value === calendario);
			if (y.diasdisponiveis.split(',')[hrdiasemana] == 1) {
				return true
			} else {
				return false
			}
		},

		/**
				 * Aqui eu verifico se o calendário contempla o dia selecionado;
				 * codigo 8 - segunda a sexta;
				 * codigo 9 - todos;
		 * @Autor Desconhecido.
				 */
		verificaMultiplosDias(obj, calendario) {
			let diasCalendario = this.optCalendario.find(
				(el) => el.value == calendario
			)
			diasCalendario = diasCalendario.diasdisponiveis.split(',')
			var i
			if (obj.hrdiasemana == 8) {
				for (i = 1; i < 6; i++) {
					if (!this.disponibilidadeCalendario(calendario, i)) {
						return false
					}
				}
			} else if (obj.hrdiasemana == 9) {
				for (i = 0; i < 7; i++) {
					if (!this.disponibilidadeCalendario(calendario, i)) {
						return false;
					}
				}
			}
			return true
		},
	},
	computed: {
	},
})
</script>

<style lang="scss">
.erroInsercao {
	color: #FFF;
	height: 20px;
	background-color: rgba(255, 0, 0, 0.637) !important;
}
</style>
