var render = function render(){var _vm=this,_c=_vm._self._c;return _c('panelEagle',{attrs:{"id":"listagemGrupoVeiculos","loading":_vm.loadingPanel}},[_c('div',{staticClass:"col-sm-12 nopadding divDontPrint"},[_c('div',{staticClass:"col-sm-12 nopadding row"},[_c('div',{staticClass:"col-sm-6"},[_c('tituloPage',{attrs:{"icon":_vm.mdiCarMultiple,"titulo":"Grupo de veículos"}})],1),_c('div',{staticClass:"col-sm-6 divButtonsBasic"},[_c('basicButtonsCadastrosEIN',{attrs:{"loading":_vm.carregandoE,"tipoNovo":"button","linkNew":"editarGrupoVeiculos","disabledDropdown":_vm.$v.gruposVeiculo.$invalid},on:{"exportarCadastro":_vm.exportarGrupoVeiculo}})],1)]),_c('div',{staticClass:"col-sm-12"},[_c('hr')]),_c('div',{staticClass:"col-sm-12 nopadding row"},[_c('div',{staticClass:"col-sm-4 nopadding"},[_c('selectAll',{attrs:{"isMultiple":true,"nameForRadio":"empresasListGrupoVeiculo","hasSelectAll":true,"labels":[{indexDFH: 'EM', description: 'Empresas'}],"selected":_vm.selectedEmpresa,"optionsArray":_vm.optionsArrayEmpresas,"firstSelected":"EM"},on:{"changeSelect":_vm.changeEmpresa}})],1)]),_c('div',{staticClass:"col-sm-12"},[_c('hr')])]),_c('div',{staticClass:"col-sm-12 nopadding"},[_c('tableListagemDetalhes',{staticClass:"col-sm-12",attrs:{"statusBar":"error","botoesAcoes":true,"conteudo":_vm.gruposVeiculo,"titulo":[
                {
                    key: 'descricao', 
                    label: 'Descrição', 
                    sortable: true, 
                    sortDirection: 'asc', 
                    class: 'text-left'
                },
                { 
                    key: 'empresa',    
                    label: 'Empresa', 
                    sortable: true, 
                    sortDirection: 'asc',
                    class: 'text-left' 
                },
            ]},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [_c('basicButtonsListagemED',{attrs:{"id":String(row.index)},on:{"editarCadastro":function($event){return _vm.editarGrupoVeiculos(row)},"excluirCadastro":function($event){return _vm.excluirGrupoVeiculos(row)}}})]}},{key:"sanfona",fn:function({row}){return [_vm._l((_vm.placas[row.item.codigo]),function(placa,index){return _c('span',{key:index},[(placa.veplaca != null)?_c('span',{staticClass:"badge badge-secondary"},[_vm._v(" "+_vm._s(placa.veplaca)+" | "+_vm._s(placa.veprefixo)+" ")]):_vm._e()])}),_vm._l((_vm.placas[row.item.codigo]),function(placa,index){return _c('span',{key:index+'a'},[(placa.veplaca == null)?_c('span',{staticClass:"span"},[_c('h6',[_vm._v(" Nenhum veículo associado ")])]):_vm._e()])})]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }