Controla a exibição dos paineis (controle, rotas e coletivos) no mapa. 
<template>
	<div class='painelMapa'>
		<div v-if="mostrarLegenda" class="square">
			<b style="margin-bottom: 10px; display: inline-block;">Legenda</b><br>
			<b style="margin-bottom: 5px; display: inline-block;">Data</b><br>
			<div style="display: flex; justify-content: start; margin-bottom: 5px;">
				<b style="width: 20px;">P - </b><span>Previsto</span>
			</div>
			<div style="display: flex; justify-content: start; margin-bottom: 10px;">
				<b style="width: 20px;">R - </b><span>Realizado</span>
			</div>
			<b style="margin-bottom: 5px; display: inline-block;">Pontos</b><br>
			<div style="display: flex; justify-content: start; margin-bottom: 5px;">
				<b style="width: 20px;">S - </b><span>Saída</span>
			</div>
			<div style="display: flex; justify-content: start;">
				<b style="width: 20px;">R - </b><span>Retorno</span>
			</div>
		</div>


		<div class='d-flex justify-content-between align-items-end' id='botoesAbaPainel'>
			<div class='btn-abas ml-1'>
				<b-button :disabled='verpaineldecontrole' @click="mudaAba(0)"
					:variant='abaAtual == 0 && menuVisivel ? "primary" : ""' aria-label='Painel de controle'>
					Painel de Controle
					<baseIcon size='20' :icon='defineIconeAba(0)' />
				</b-button>
				<b-button @click='mudaAba(1)' :disabled='ocultarrotas' aria-label='Rotas'
					:variant='abaAtual == 1 && menuVisivel ? "primary" : ""' class='ml-1'>
					Rotas
					<baseIcon size='20' :icon='defineIconeAba(1)' />
				</b-button>
				<b-button :disabled='ocultarcoletivos' @click='mudaAba(2)' aria-label='Coletivos'
					:variant='abaAtual == 2 && menuVisivel ? "primary" : ""' class='ml-1'>
					Coletivos
					<baseIcon size='20' :icon='defineIconeAba(2)' />
				</b-button>
				<b-button v-if="veiculoSelecionado && infoVeiculoVisivel" @click='mudaAba(3)' aria-label='Telemetria'
					:variant='abaAtual == 3 && menuVisivel ? "primary" : ""' class='ml-1'>
					Telemetria
					<baseIcon size='20' :icon='defineIconeAba(3)' />
				</b-button>
			</div>
		</div>
		<b-collapse id='menuPainel' v-model='menuVisivel'>
			<div v-show='abaAtual == 0'>
				<painelControle :permissoes='permissoes' :veiculoSelecionado="veiculoSelecionado"
					:clienteSelecionado='clienteSelecionado' ref='painelControle' @localizaVeiculo='localizaVeiculo'
					@limpar-rastros-gv='$emit("limpar-rastros-gv")' @allVeiculos='v => $emit("allVeiculos", v)'
					@criarLegenda='v => $emit("criarLegenda", v)' @addMarker='v => $emit("addMarker", v)'
					@remMarker='v => $emit("remMarker", v)' @rem-all-markers='$emit("rem-all-markers")'
					@muda-cliente='mudaCliente' @update-veiculo='(v) => $emit("update-veiculo", v)'
					@rastro-carregado='v => $emit("rastro-carregado", v)' @fecha-info="$emit('fecha-info')" />
			</div>
			<div v-show='abaAtual == 1'>
				<painelRotas v-if='!ocultarrotas' :clienteSelecionado='clienteSelecionado'
					:alertaLido="chamarRequisicaoDadosRota" ref='painelRotas' @rastros-rotas='rastrosRota'
					@alertas-rotas="rastrosAlertas" @muda-cliente='mudaCliente' @exibe-alertas-lidos="alertasLido"
					@att-marcador-rota='v => $emit("att-marcador-rota", v)'
					@remove-rota='(v, p) => $emit("remove-rota", v, p)' @limpa-rotas='$emit("limpa-rotas")'
					@limpa-alertas-rotas="$emit('limpa-alertas-rotas')" @limpa-alertas-rotas-id="limpaAlertasId"
					@remove-rastros-rotas='$emit("remove-rastros-rotas")' @fecha-info="$emit('fecha-info')"
					@desenhar-rota='v => $emit("desenhar-rota", v)' @paradas-veiculo-rota="paradasRotas"
					@exibe-legenda-painel='exibeLegenda' @limpa-paradas-rotas="$emit('limpa-paradas-rotas')"
					@limpa-paradas-id="limpaParadaId" @excesso-velocidade="excessoVelocidade"
					@limpa-excesso-velocidade-id="limpaExcessoVelocidadeId"
					@limpa-excesso-velocidade="$emit('limpa-excesso-velocidade')" />
			</div>
			<div v-show='abaAtual == 2'>
				<painel-coletivo v-if='!ocultarcoletivos' :clienteSelecionado='clienteSelecionado'
					@muda-cliente='mudaCliente' @desvio-rota-coletivo='v => $emit("desvio-rota-coletivo", v)'
					@rastro-veiculo-coletivo='v => $emit("rastro-veiculo-coletivo", v)'
					@remove-todos-coletivos='$emit("remove-todos-coletivos")'
					@remove-linha-coletivo='v => $emit("remove-linha-coletivo", v)'
					@linha-coletivo='v => $emit("linha-coletivo", v)' @fecha-info="$emit('fecha-info')" />
			</div>

			<!-- Painel Telemetria -->
			<telemetry-panel v-show="abaAtual == 3 && veiculoSelecionado && infoVeiculoVisivel"
				:plate="veiculoSelecionado" @show-location="(latlog) => $emit('show-location', latlog)" />
		</b-collapse>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import {
	mdiChevronDown,
	mdiChevronRight,
	mdiBusClock,
	mdiCallSplit,
	mdiBusMultiple
} from '@mdi/js'

import PainelColetivo from '@/components/Atom/Painel/Coletivos/PainelColetivo.vue'
import painelControle from '@/components/Atom/Painel/PainelControle.vue'
import painelRotas from '@/components/Atom/Painel/Rotas.vue'
import TelemetryPanel from '@/components/Atom/Painel/Telemetry/TelemetryPanel.vue'

export default Vue.extend({
	name: 'painelMapa',
	components: {
		PainelColetivo,
		painelControle,
		painelRotas,
		TelemetryPanel,
		baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
	}, 
	emits: [
		'addMarker',
		'alertas-rotas',
		'allVeiculos',
		'att-marcador-rota',
		'change-vehicle',
		'criarLegenda',
		'desenhar-rota',
		'desvio-rota-coletivo',
		'excesso-velocidade',
		'exibe-alertas-lidos',
		'fecha-info',
		'limpa-alertas-rotas-id',
		'limpa-alertas-rotas',
		'limpa-excesso-velocidade-id',
		'limpa-excesso-velocidade',
		'limpa-paradas-id',
		'limpa-paradas-rotas',
		'limpa-rotas',
		'limpar-rastros-gv',
		'linha-coletivo',
		'muda-cliente',
		'muda-cliente',
		'paradas-veiculo-rota',
		'rastro-carregado',
		'rastro-veiculo-coletivo',
		'rastros-rotas',
		'rem-all-markers',
		'remMarker',
		'remove-linha-coletivo',
		'remove-rastros-rotas',
		'remove-rota',
		'remove-todos-coletivos',
		'show-location',
		'update-veiculo',
	],
	props: {
		permissoes: {
			type: [Object, Array],
			required: true
		},
		clienteSelecionado: {
			type: Array,
			default: () => { return [] }
		},
		veiculoSelecionado: {
			type: String,
			default: ''
		},
		abriuViagem: {
			type: Boolean,
			default: false
		},
		chamarRequisicaoDadosRota:{
			type: Boolean,
			default: false
		},
		infoVeiculoVisivel: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			iconSizeC: 25,
			abaAtual: 0,
			iconChevronClose: mdiChevronRight,
			iconChevronOpen: mdiChevronDown,
			dataPainelControle: [],
			menuVisivel: false,
			btnPainelBusClock: mdiBusClock,
			btnPainelSplit: mdiCallSplit,
			btnPainelBusMul: mdiBusMultiple,
			mostrarLegenda: false
		}
	},
	methods: {
		/**
		 * @description controla se abre ou fecha o painel 
		 * quando viagens é aberto
		 * @author Otávio 🦆 
		 */
		exibeLegenda(status){
			this.mostrarLegenda = status;
		},
		togglePainelControle() {
			this.$refs.painelControle.toggleSelect()
		},

		/**
		 * @description controla se abre ou fecha o painel 
		 * quando viagens é aberto
		 * @author Otávio 🦆 
		 */
		esconderPainelControleAbrirViagem(){
			if(this.abriuViagem){
				this.menuVisivel = false;
			}else{
				this.menuVisivel = true;
			}
		},

		rastrosRota(rastros) {
			this.$emit('rastros-rotas', rastros)
		},

		/**
		 * @description emite os dados de alerta  
		 * @author Otávio 🦆 
		*/
		rastrosAlertas(alertas) {
			this.$emit('alertas-rotas', alertas);
		},

		/**
		 * @description emite os dados de paradas 
		 * @author Otávio 🦆 
		*/
		paradasRotas(paradas){
			this.$emit('paradas-veiculo-rota', paradas);
		},

		/**
		 * @description emite os dados de excesso de velocidade
		 * @author Otávio 🦆 
		*/
		excessoVelocidade(excessoVelocidade){
			this.$emit('excesso-velocidade', excessoVelocidade);
		},

		/**
		 * @description emite se é para exibir os alertas lidos ou não
		 * @author Otávio 🦆 
		*/
		alertasLido(exibeAlertaLido){
			this.$emit('exibe-alertas-lidos', exibeAlertaLido);
		},


		/**
		 * @description emite o id do agrupamento de parada para limpar por rota
		 * @author Otávio 🦆 
		*/
		limpaParadaId(id){
			this.$emit('limpa-paradas-id', id);
		},

		limpaAlertasId(id){
			this.$emit('limpa-alertas-rotas-id', id);
		},

		/**
		 * @description emite o id do agrupamento de excesso de velocidade para limpar por rota
		 * @author Otávio 🦆 
		*/
		limpaExcessoVelocidadeId(id){
			this.$emit('limpa-excesso-velocidade-id', id);
		},

		mudaCliente(modelCliente) {
			this.$emit('muda-cliente', modelCliente);
			this.$emit('criarLegenda');
		},

		/**
		 * @listens click - em qualquer dos botões de trocar de aba
		 * @param {number} aba - número da aba a ser ativada
		 * @param {boolean} forcarMenuVisivel - força o menu a ficar visível
		 */
		mudaAba(aba, forcarMenuVisivel = false) {
			if (forcarMenuVisivel) {
				this.menuVisivel = true
			} else if (aba === this.abaAtual) {
				this.menuVisivel = !this.menuVisivel
			} else {
				this.menuVisivel = true
			}

			this.abaAtual = aba
		},

		/**
		 * @fires localizaVeiculo
		 */
		localizaVeiculo(value) {
			this.$emit('localizaVeiculo', value)
		},

		/**
		 * @param {number} aba - qual delas que esta sendo decidida.
		 * @description decide se o ícone da aba deve ser o chevron
		 * virado para o lado ou pra cima.
		 */
		defineIconeAba(aba) {
			if (this.abaAtual === aba && this.menuVisivel)
				return this.iconChevronOpen
			return this.iconChevronClose
		},

		...mapGetters(['getModulos']),
	},
	watch: {
		abriuViagem: {
			handler: 'esconderPainelControleAbrirViagem',
			deep: true
		},

		/**
		 * @description monitora se o alerta foi lido para receber um boolean 
		 * se for true envia para props alertaLido em rotas
		 * @author Otávio 🦆 
		 */
		chamarRequisicaoDadosRota(valor) {
			if (valor) {
				this.chamarRequisicaoDadosRota = valor;
			}
		},

		veiculoSelecionado(placa) {
			if (placa && this.infoVeiculoVisivel) this.mudaAba(3, true)
		},

		infoVeiculoVisivel(visivel) {
			if (visivel && this.veiculoSelecionado) {
				this.mudaAba(3, true)
			} else if (this.abaAtual === 3){
				this.mudaAba(0, true)
			}
		}
	},
	computed: {
		// O nome dessas proximas 2 propriedades n ta bom,
		// mas fica pro futuro mudar
		gotWay() {
			return typeof this.getModulos()['roteirizador'] === 'undefined'
		},
		gotCity() {
			return typeof this.getModulos()['coletivos'] === 'undefined'
		},
		verpaineldecontrole() {
			if (typeof this.permissoes['mappainelcontrole'] === 'undefined')
				return true
			return !this.permissoes['mappainelcontrole'].ppvisualizar
		},
		ocultarrotas() {
			if (this.gotWay) return true
			if (typeof this.permissoes['maprotas'] === 'undefined')
				return true
			return !this.permissoes['maprotas'].ppvisualizar
		},
		ocultarcoletivos() {
			if (this.gotCity) return true
			if (typeof this.permissoes['mapcoletivos'] === 'undefined')
				return true
			return !this.permissoes['mapcoletivos'].ppvisualizar
		}
	}
})
</script>

<style lang="scss" scoped>
.painelMapa {
	z-index: 666 !important;
	border-top: 1px solid #bbbbbb70;
	background-color: #eeeeee70;

	.background-btn-painel {
		padding: 2px;
		margin-right: 15px;
		background-color: rgba(245, 245, 245, 0.7);
		width: 400px;
		border-radius: 2px;
	}

	.btn-coletivos {
		padding: 4px;
		color: grey;
		cursor: pointer;

		:hover {
			color: black;
		}
	}

	.btn-coletivos-disabled {
		padding: 4px;
		color: grey;
		cursor: not-allowed
	}
	.square {
		width: 150px;
		margin-top: -170px;
		background-color: #d9dfe4;
		float:right;
		margin-right: 1%;
		position: relative;
		padding: 10px;
		font-size: 14px;
		min-height: 200px;		
	}
}
</style>