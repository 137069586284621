<template>
    <panelEagle id="inconformidadeEscala"
        :loading="loadingPanel">
        <div class="col-sm-12 nopadding divDontPrint">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiFileAlert" titulo="Inconformidades de escala"></tituloPage>
                </div>
                <div class="col-sm-6 divButtonsBasic">
                    <basicButtonsRelatoriosEIG
                        :disabledDropdown="!Object.keys(dadosInconformidadeForHtml).length"
                        @exportarRelatorio="exportarRelatorio" 
                        @gerarRelatorio="gerarRel"
                        :loading           ='loadingExportar'
                        :disabled          ="$v.$invalid">
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12"><hr></div>
                    <div class="col-sm-12 justpaddingbottom row">
                        <div class="col-sm-3 nopadding">
                            <inputRangeWithLimit 
                                @changeInput="changeDataIntervalo" 
                                :isObrigatorio="true" 
                                name="inputDataAbsenteismo"
                                opens="right">
                            </inputRangeWithLimit>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll 
                                @changeSelect="SelectEmpresas" 
                                :isMultiple="true" 
                                nameForRadio="radiosEmpresas" 
                                :labels="labelsSelectEmpresa" 
                                :optionsArray="optionsSelectEmpresa" 
                                :selected="selectedEmpresa"
                                firstSelected="EM" 
                                :hasSelectAll="true">
                            </selectAll>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll 
                                @changeSelect ="changeSelectColaboradores" 
                                @selectCriado ="selectCriado" 
                                :isMultiple   ="true" 
                                nameForRadio  ="radiosLinhasLinhasPrincipais" 
                                :labels       ="labelsSelectColaboradores" 
                                :optionsArray ="optionsSelectEscalas" 
                                firstSelected ="C" 
                                :loading      ="carregando"
                                :disabled     ="$v.optionsSelectE.$invalid"
                                :hasSelectAll ="true">
                            </selectAll>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <buttonsFilters 
                                @buttonsFiltersChange="changeFilterButtons" 
                                label="Inconformidades" 
                                :onlyOneSelected="false" 
                                :arrayButtons="arrayButtons">
                            </buttonsFilters>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
        </div>
        <tableRelatorio>
            <slot slot="thead">
                <tr >
                    <th class="col-sm-1">Data</th>
                    <th class="col-sm-2">Inconformidade</th>
                    <th class="col-sm-2">Programado</th>
                    <th class="col-sm-3">Realizado</th>
                    <th class="col-sm-1">Turno</th>
                    <th class="col-sm-3">Colaborador</th>
                </tr>
            </slot>
            <slot slot="tbody">
                <statusInformation 
                    v-if="tamanhoInconformidadeEscala == 0"
                    typeBar="tr" 
                    :statusBar="statusbar">
                </statusInformation>
                <br>


                <template v-for="(escala , e) in dadosInconformidadeForHtml">
                    <!-- Badge com a escala e número de inconformidades -->
                    <tr :key="e+'_escala'"
                        class="escalaTitulo">
                        <td colspan="3"
                            v-b-toggle="(escala[0].escodigo+'')" 
                            @click="escala[0].clicado = !escala[0].clicado">
                            <div class="col-sm-12 nopadding badgeEscalas row">
                                <div class="col-sm-1 p-0 pt-1">
                                    <b-icon
                                        class="h6 mb-0" 
                                        shift-v="-4"
                                        shift-h="2"
                                        style="font-weight: bold;"
                                        :icon="escala[0].clicado ? 
                                            'chevron-down' : 'chevron-right'"/>
                                </div>
                                <div class="col-sm-11 p-0 pt-2"> 
                                    {{e+' | '+escala.length+' Inconformidades'}}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <!-- Dados da tabela dentro do collapse -->
                    <tr :key="e+'_collapse'">
                        <td colspan="6"
                            class="tdCollapse">
                            <b-collapse 
                                :key="e+'_collapse'"
                                class="col-sm-12"
                                :id="(escala[0].escodigo+'')">
                                <div v-for="(dados, d) in escala"
                                    :key="d+'_dados'"
                                    class="dadosRelatorio col-sm-12 row">
                                    <div class="col-sm-1 alinharDados">
                                        {{dados.data}}
                                    </div>
                                    <div class="col-sm-2">
                                        <baseIcon 
                                            size='18' 
                                            :icon="getIcon(dados.inconf)"
                                        />
                                        {{dados.inconf}}
                                    </div>
                                    <div class="col-sm-2 alinharDados">
                                        {{dados.programado}}
                                    </div>
                                    <div class="col-sm-3 alinharDados">
                                        {{dados.realizado}}
                                    </div>
                                    <div class="col-sm-1 alinharDados">
                                        {{dados.turno}}
                                    </div>
                                    <div class="col-sm-3 alinharDados">
                                        {{dados.mtnome}}
                                    </div>
                                </div>
                            </b-collapse>
                        </td>
                    </tr>
                </template>
            </slot>
        </tableRelatorio>
    </panelEagle>
</template>

<script>
import {
	mdiFileAlert, 
	mdiCarArrowLeft, 
	mdiAccountConvert,
	mdiRoadVariant } from '@mdi/js'
import Vue               from 'vue'
import {required}        from 'vuelidate/lib/validators'
import {EmpresasService} from '../../../Services/auth/Empresas.service'
import {HttpRequest}     from '../../../Services/auth/HttpRequest.Service'
import {FiltrosService}  from '../../../Services/filtros/filtros.Service'
import { mapGetters } from 'vuex'

// var mdiCarArrowLefts    = mdiCarArrowLeft
// var mdiAccountConverts  = mdiAccountConvert
// var mdiRoadVariants     = mdiRoadVariant
export default ({
	name:'InconformidadeEscalas',
	components:{
		'panelEagle'                : require('@/components/Atom/Panel/PanelEagle').default,
		'tituloPage'                : require('@/components/Atom/Header/Titulo').default,
		'basicButtonsRelatoriosEIG' : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
		'buttonsFilters'            : require('@/components/Atom/Buttons/ButtonsFilters').default,
		'slideUpAndDown'            : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
		'selectAll'                 : require('@/components/Atom/Select/SelectAll').default,
		'inputRangeWithLimit'       : require('@/components/Atom/Datas/InputRangeWithLimit').default,
		'statusInformation'         : require('@/components/Atom/StatusInformation/StatusInformation').default,
		'tableRelatorio'            : require('@/components/Atom/Table/TableRelatorio').default,
		'baseIcon'                  :require('@/components/Atom/Icon/BaseIcon.vue').default,
	},

	validations:{
		optionsSelectE:{
			required
		},
		optionsSelectedEscaslas:{
			required
		},
		filtrosButtons:{
			required
		},
	},

	data(){
		return{
			mdiCarArrowLeft:mdiCarArrowLeft,
			mdiAccountConvert:mdiAccountConvert,
			mdiRoadVariant:mdiRoadVariant,
			mdiFileAlert:mdiFileAlert,
			labelsSelectEmpresa:[
				{indexDFH: 'EM', description: 'Empresa *'},
			],
			optionsSelectEmpresa: new EmpresasService().Get(),
			labelsSelectColaboradores:[
				{indexDFH: 'E', description: 'Escalas *'},
			],
			arrayButtons:[
				{'value':'A', 'title':'Alteração de veículo', 'width': '31%', 'icon': mdiCarArrowLeft, 'extraClass': 'buttonInconfEscalas', 'isSelected':true},
				{'value':'B', 'title':'Alteração de colaborador', 'width': '31%', 'icon': mdiAccountConvert, 'extraClass': 'buttonInconfEscalas', 'isSelected':true},
				{'value':'C', 'title':'Inconformidade de quilometragem', 'width': '31%', 'icon': mdiRoadVariant, 'extraClass': 'buttonInconfEscalas', 'isSelected':true},
			],
			optionsSelectE              : [],
			optionsSelectEscalas        : [],
			optionsSelectedEscaslas     : [],
			selectEscalas               : [],
			filtrosButtons              : [],
			dataIntervalo               : '',
			htmlInconformidadeEscalas   : '',
			statusbar                   : 'info',
			dadosInconformidadeEscala   : [],
			dadosInconformidadeForHtml  : [],
			tamanhoInconformidadeEscala : 0,
			dataToExport                : '',
			loadingExportar             : [false, false, false],
			loadingPanel                : false,
			carregando                  : false,
            selectedEmpresa             : [],
		}
	},
	methods:{
        ...mapGetters(['getMaster']),

		SelectEmpresas(event){
			this.optionsSelectE = event;
			this.selectEscalas.clearAll();
			this.dados_filtros();
		},
		changeSelectColaboradores(event){
			this.optionsSelectedEscaslas = event;
		},
		changeDataIntervalo(data){
			this.dataIntervalo = data;
		},
		selectCriado(select){
			this.selectEscalas = select;
		},
		changeFilterButtons(values){
			this.filtrosButtons = values;
		},
        getIcon(nonconformity) {
            const nonconformityIcons = {
                ['Alteração de veículo']: mdiCarArrowLeft,
                ['Alteração de motorista']: mdiAccountConvert,
                ['Dist. percorrida acima do prog']: mdiRoadVariant,
                ['Dist. percorrida abaixo do prog']: mdiRoadVariant
            };


            return nonconformityIcons[nonconformity] ?? '';
        },
		async dados_filtros(){
			this.carregando = true
			var dados = [];
			dados = await new FiltrosService().dados_filtros(this.optionsSelectE, ['E'])
			this.optionsSelectEscalas = dados['E'];
			this.carregando = false
		},
		async gerarRel(){
			if(this.optionsSelectE.length > 0 && this.optionsSelectEscalas.length > 0 &&
                this.dataIntervalo.length > 0){
				this.loadingPanel = true;
				this.dadosInconformidadeEscala = [];
				this.dadosInconformidadeForHtml = [];
				this.tamanhoInconformidadeEscala = 0;
				var data = await new HttpRequest().Post(
					'/coletivos/relatorios/inconformidade/escala/gerar/inconformidades', 
					{'clientes' : this.optionsSelectE, 
						'escalas'  : this.optionsSelectedEscaslas, 
						'data'     : this.dataIntervalo, 
						'inconf'   : this.filtrosButtons})
				var dados = data.data;
				this.tamanhoInconformidadeEscala = dados.tamanho
				if(this.tamanhoInconformidadeEscala > 0){
					this.dadosInconformidadeEscala = dados.inconformidades.array
					this.dadosInconformidadeForHtml = dados.inconformidades.arrayExportHtml
					this.dataToExport = this.dataIntervalo;
					this.htmlInconformidadeEscalas = dados.inconformidades.html;
				}else{
					this.dadosInconformidadeEscala = [];
					this.htmlInconformidadeEscalas = '';
					this.statusbar = 'error'
				}
				this.loadingPanel = false
			}
		},
		async exportarRelatorio(type){
			var root =  process.env.VUE_APP_ROOT;
			if(this.tamanhoInconformidadeEscala > 0){
				switch(type){
					case 'pdf':
						this.loadingExportar = [true, false, false]
						break;
					case 'xls':
						this.loadingExportar = [false, true, false]
						break;
					case 'csv':
						this.loadingExportar = [false, false, true]
						break;
					default:
						break;
				}
				var data = await new HttpRequest().Post(
					'/coletivos/relatorios/inconformidade/escala/exportar/inconformidades', 
					{'arrayDados' :this.dadosInconformidadeEscala, 
						'tipo'        :type, 
						'data'        :this.dataToExport, 
						'colunas'     : 6, 
						'arrayHtml'   : this.dadosInconformidadeForHtml});
				window.open(root+'/'+data.data);
				this.loadingExportar = [false, false, false]
			}
		}
	},

    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optionsSelectEmpresa
        }
    }
})
</script>

<style lang="scss">
#inconformidadeEscala{
    .buttonInconfEscalas{
        span{
            &::before{
                font-size: 20px;
            }
        }
    }

    .tableRelatorio{
        th{
            width:16%;
            border: 1px solid #0000 !important;
        }
    }

    table tbody tr.escalaTitulo{
        color: #fff;
        font-weight: bold;
        background: #f5f5f5 !important;
        border: none !important;
        td{
            padding-top: 15px;
            padding-left: 0px;
            font-size: 14px !important;
            border: none !important;
            .badgeEscalas{
                background: #777777;
                width: 100% !important;
                height: 30px;
            }
        }
    }

    .collapse{
        padding: 0px;
    }

    .tdCollapse{
        padding: 0px;
    }

    .dadosRelatorio{
        vertical-align: middle;
        background: #fff;
        padding: 0px;
        div{
            border: 1px solid #d5d5d5;
        } 
        .alinharDados{
            padding-top: 7px;
        }
    }
}
</style>