/**
  Esse é o componente de de tabela com detalhes, (aquela sanfona que aparece mais coisas)
  Documentação: https://bootstrap-vue.org/docs/components/table

  -> Aviso:
      Se um numero tiver mais que 20 digitos ele vai ser transformado em conotação cientifica, só colocar como string que ele resolve

  -> Eventos:
      Sem eventos
  -> Propriedades:
      1 - loading - Boolean - A tabela precisa infornar que esta carregando? Se sim: true, se não: false
      2 - statusBar - String - Status da barra a ser mostrada - EX: 'info'/'loading'/'error'
      2 - botoesAcoes - Boolean- Vai ter algum botão de ação? apagar ou editar? Se sim: true, se não: false
      3 - titulo - Array de objetos - Os titulos que vao aparecer na sua tabela: 
        Ex: [{key: 'name', label: 'Nome do caco', sortable: true, sortDirection: 'desc', class: 'text-center'}]
          * key: String - como ele vai saber quais itens do conteudo por, (OBRIGATORIO)
          * label: String - nome da coluna que vai aparecer na tabela, (OBRIGATORIO)
          * sortable: Boolean - se vai ter os botoes de ordenar
          * sortDirection: String - 'asc'/'desc'
          * class: String - pode ser passado uma classe, ou utilizar as do Bootstrap EX: 'text-center'
      4 - conteudo - Array de objetos - o conteudo que sua tabela vai ter:
        Ex: [{idade: 9,  name: 'monica', _rowVariant: '', _cellVariants: { age: 'danger'}}]
          * key: vai ser o valor que tu colocou no na key do titulo 
              EX: key: 'name' === name: 'monica' | key: 'name' !== idade: 9
              se não for informado o valor da key do titulo, a td vai aparece em branco
          * _rowVariant - String - coloca uma cor na linha, as cores do bootstrap (tem prioridade no _cellVariants)
              EX: _rowVariant: 'success'
              - Valores: primary, secondary, success, danger, warning, info, light, dark
          * _cellVariants: - Object - atribui uma cor para a td, as cores do bootstrap
              EX: _cellVariants: { key: 'danger', key2: 'secondary'} - pode 
              - Valores: primary, secondary, success, danger, warning, info, light, dark
    
    -> Slot:
        1 - name "sanfona" - para por coisas na sanfona
        2 - Sem nome - para por algum botão de ação

    -> Exemplo de como usar na sua pagina: 
        
        <tableListagemDetalhes
          :botoesAcoes="true"
          class="col-sm-12"
          :conteudo = "[
            {age: 40, name: 'aldair' },
            {age: 21, name: 'claudio' }, 
            {age: 9,  name: 'monica', _rowVariant: '', _cellVariants: { age: 'danger'},},
            {age: 89, name: 'glauber' },
            {age: 38, name: 'rrererererer' },
            {age: 27, name: 'sidiney' },
            {age: 40, name: 'Nego Ney' },
            {age: 87, name: 'brucisfild', },
            {age: 26, name: 'claudinho' },
            {age: 22, name: 'camile' },
            {age: 38, name: 'karimbo' },
            {age: 29, name: 'boca' }
          ]"
          :titulo = "[
            {key: 'name', label: 'Nome do caco', sortable: true, sortDirection: 'desc', class: 'text-center'},
            { 
                key: 'age',    
                label: 'Idade do jaguara', 
                sortable: true, 
                sortDirection: 'desc',
                class: 'text-center' 
            },
            { 
                key: 'batata',    
                label: 'hmmm', 
                sortable: true, 
                sortDirection: 'desc',
                class: 'text-center' 
            },
          ]"
        />
*/
<template>
  <div class="col-sm-12 nopadding">
    <b-row class="row marging-grandona divDontPrint">
        <div class="col-md-8 col-sm-12"/>
        <b-col lg="4" class="col-md-4 col-sm-12">
            <b-input-group size="sm divDontPrint">
                <b-form-input
                    v-if='temFiltro'
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Buscar">
                </b-form-input>
            </b-input-group>
        </b-col>
    </b-row>
    <b-table
        class="tamanho100"
        v-show="!semDados"
        v-if='!loading'
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        stacked="md"
        show-empty
        small
        @filtered="onFiltered">
        <!-- Detalhes -->
      <template #cell(acoes)="row" class="arrumar-lado">
          <b-button class="botao-sumir" size="sm" @click="detalhes(row)">
            <div>
              <b-icon class="pose" :icon="row.detailsShowing ? 'chevron-down' : 'chevron-right'"/>
            </div>
          </b-button>
      </template>
        <!-- Conteudo da pagina  -->
      <template #cell(name)="row">
          {{ row.value }}
      </template>
      <!-- slot dos botoes -->
      <template class="arrumar-outro-lado divDontPrint" #cell(botoes)="row">
        <slot  :row="row" >{{row}} </slot>
      </template>
      <!-- slot pra por coisas na sanfona -->
      <template #row-details='row'>
        <div class="divSanfona">
            <slot name="sanfona"  :row="row" >{{row}}</slot>
            <!-- conteudo sanfona -->
    <!-- 
      <template slot='sanfona' slotProps='{row}'> row  </template>
    -->
        </div>
      </template>
    </b-table>
    <div class='col-sm-12' v-else>
      <status slot='tbody' typeBar='div' statusBar='loading'/>
    </div>
    <div class='col-sm-12 marge' v-show="semDados">
      <b-table :fields="fields"/>
      <status slot='tbody' typeBar='div' :statusBar='statusBar' :msgCentro='msgCentro'/>
    </div>
    <!-- parte da paginacao -->
    <div class="col-12 row">
      <b-col class="col-sm-7">
        <!-- numero da pagina -->
        <b-pagination class="pagination"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm">
        </b-pagination>
      </b-col>
      <!-- quantidade de itens por pagina -->
      <b-col class="col-sm-5 text-left">
        <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm">
        </b-form-select>
        <span class="espaco-pequeno">
          Total {{totalItens}}
        </span>
      </b-col>
    </div>
  </div>
</template>

<script>
import SlideUpAndDown from '../SlideUpAndDown/SlideUpAndDown.vue'
// documentação da tabela, com mais coisas
// https://bootstrap-vue.org/docs/components/table

  export default {
    components:{
      'status' : require('@/components/Atom/StatusInformation/StatusInformation').default,
    },
    props:{
  
        loading:{
            type: Boolean,
            default: false,
        },
        titulo:{
          type: Array,
          required: true,
        },
        conteudo:{
          type: Array,
          default: function(){return []},
        },
        botoesAcoes:{
          type: Boolean,
          default: false,
        },
        statusBar:{
          type: String,
          default: 'info'
        },
        msgCentro:{
          type: String,
          default: 'Sem informações',
        },
        temFiltro:{
          type: Boolean,
          default: true
        }

    },
    data() {
      return {
        totalItens:0,
        semDados:false,
        // dados que vao aparecer
        items: [],
        // titulos
        fields: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [
            { value: 10, text: "10/página" },
            { value: 25, text: "25/página" },
            { value: 50, text: "50/página" }, 
        ],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
      }
    },
    computed: {
      sortOptions() {
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      }
    },
    watch:{
      conteudo:function(){
        this.items = this.conteudo
        this.totalItens = this.items.length
        this.totalRows = this.items.length
        if(this.conteudo.length == 0){
          this.semDados = true
        }else{
          this.semDados = false
        }
      },
      botoesAcoes:function(){
        this.arrumaTitulo()
      },
    },
    mounted(){
      this.arrumaTitulo()
      this.items     = this.conteudo
      this.totalRows = this.items.length
      if(this.conteudo.length == 0){
        this.semDados = true
      }else{
        this.semDados = false
      }
    },
    methods:{
      
      detalhes(row) {
        row.toggleDetails()
        this.$emit('toggleDetails', row)
      },

      arrumaTitulo(){
        this.fields    = this.titulo
        this.fields.unshift({ key: 'acoes',  label: 'Detalhes', class: 'text-left font-weight-bold' })
        if(this.botoesAcoes){
          this.fields.push({ key: 'botoes', label: 'Ações' })
        }
      },
      onFiltered(filteredItems){
        // var salvaDados = 
        if(filteredItems.length == 0){
          // this.items = []
          this.semDados = true
        }else{
          this.semDados = false
          this.totalRows = filteredItems.length
          this.currentPage = 1
        }
      }
    }
  }
</script>
<style 
lang="scss">
    .espaco-pequeno{
      margin-left: 10px;
      color: #737f95;
      /* margin-bottom: 500px !important; */
    }
    .marging-grandona{
      margin-bottom: 30px;
    }
    .marge{
      margin-bottom: 10px;
    }
    .arrumar-outro-lado{
      text-align: right !important;
    }
    .arrumar-lado{
      text-align: left !important;
    }
    .container{
      // text-align: justify !important;
      // contain: content;
    }
    .tamanho100{
      width: 100% !important;
    }
    .divSanfona{
      min-height: 100px;
      text-align: left;
      padding: 10px;
      background-color:#ffffff!important;
    }
    .botao-sumir{
      font-size: 12px!important;
      text-align: center !important;
      border-color: #21252900 !important;
      color: black !important;
      background: #21252900 !important;
    }
    .pagination{

      width: 250px !important;
      float: right;
    }
    /* botao */
    .page-link{
      border:none !important;
      padding: 0px;
      color: black !important;
      /* font-style: none; */
    }
    /* quando botão esta clicado */
    .page-item.active .page-link{
      color: rgb(79, 191, 232) !important;
      background-color: #FFF !important;
    }
    .page-link:hover{
      background: none !important;
    }

    .custom-select{
      /* display: none !important; */
      color:#737f95;
      border-color: #737f95;
      width: 18% !important;
      border-radius: 0rem !important;
      float: left;
      margin-bottom: 5px;
    }
    .custom-select:hover{
      border-color: rgb(79, 191, 232);
    }
</style>