<!-- 
    Este botão é um botão com span de carregamento

    -> Propriedades:
        1- text - String - Texto que vai para o botão - EX: 'Gerar'
        2- isLoading - Boolean - Está carregando? - EX: true/false
        3- icon - String - Icone que vai para o botão - EX: 'mdiFacebook'
        4- type - String - Tipo de botão, a cor a ser escolhida - EX: orange/green/red/blue/white/light-green/light-blue
        5- extraClass - String - Classe extra do botão se necessario - EX: 'buttonLoading'
        6- event - String - Evento a ser emitido - EX: 'click'
        7- id - String - Id do botão a ser escolhido - EX: 'buttonLoading'
        8- width - String - Tamanho do botão - EX: '100px'/'100%'
        9- title - String - Title a ser mostrado no hover do botão - EX: 'Carregando...'
        10- value - String - Valor do botão a ser emitido quando clicado - EX: '2'

    -> Slot:
        -Sem slot

    -> Eventos:
        1- Evento a ser escolhido - emitido o evento passo pelo event - Parametros:
            1.1- this - emitido o botão em si
            1.2- value - o valor do value passado
-->
<template>
    <button
        v-if="!isLoading"
        v-bind="$attrs"
        :class="'bt-eagle bt-' + type + ' ' + extraClass"
        :id="id"
        @click="click(value)"
        :style="'width:' + width"
        data-test="loading-button"
        :title="title"
    >
        <baseIcon size="18" :icon="icon" />
        {{ text }}
    </button>
    <button
        v-else
        disabled
        :class="'bt-eagle bt-' + type + ' ' + extraClass"
        :id="id"
        data-test="loading-button-loading"
        @click="click(value)"
        :style="'width:' + width"
        :title="title"
    >
        <b-spinner
            :loading="isLoading"
            name="spinner"
            small
            color="#fff"
            data-test="spinner-loading"
        ></b-spinner>
        {{ text }}
    </button>
</template>

<script lang="js">
import Vue from "vue";
export default Vue.extend({
    components: {
        baseIcon: require("@/components/Atom/Icon/BaseIcon.vue").default,
        // 'PulseLoader' : require('vue-spinner/src/PulseLoader').default
    },
    inheritAttrs: false,
    props: {
        text: {
            type: String,
            required: false,
        },
        isLoading: {
            type: Boolean,
            required: true,
            default: false,
        },
        icon: {
            type: String,
            required: false,
            default: "",
        },
        type: {
            type: String,
            required: true,
            default: "blue",
        },
        extraClass: {
            type: String,
            required: false,
            default: "",
        },
        event: {
            type: String,
            required: false,
            default: "",
        },
        id: {
            type: String,
            required: false,
            default: "",
        },
        width: {
            type: String,
            required: false,
            default: "100px",
        },
        title: {
            type: String,
            required: false,
            default: "",
        },
        value: {
            type: String,
            required: false,
            default: "",
        },
    },
    methods: {
        click(value) {
            this.$emit(this.event, this, value);
        },
    },
});
</script>

<style lang="scss" scoped>
.bt-eagle {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    padding: 4px 15px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    display: inline-block;
    margin-bottom: 0;
    touch-action: manipulation;
    user-select: none;
    background-image: none;

    .v-spinner {
        float: left;
    }

    &:hover,
    &:focus {
        color: #fff;
        text-decoration: none;
    }

    &:disabled {
        opacity: 0.6;
        background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3));
        color: -internal-light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3));
        border-color: -internal-light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3));
    }

    span {
        margin: 0px;

        &::before {
            font-size: 12px;
            margin: 0;
        }

        &.icon {
            line-height: 16px;

            &:before {
                font-size: 11px;
            }
        }

        &.fa-spinner.fa-spin.fa-3x {
            font-size: 0.5em;
            width: 14px;
        }
    }

    &.bt-orange {
        background-color: #f0ad4e;
        border: 1px solid #fff;
        color: #fff;
    }
    &.bt-green {
        background-color: #1caf9a;
        border: 1px solid #fff;
        color: #fff;
    }
    &.bt-red {
        background-color: #e04b4a;
        border: 1px solid #fff;
        color: #fff;
    }
    &.bt-blue {
        background-color: #428bca;
        border: 1px solid #fff;
        color: #fff;
    }
    &.bt-white {
        background-color: #fff;
        border: 1px solid #d5d5d5;
        color: #000000;
    }
    &.bt-light-green {
        background-color: #95b65c;
        border: 1px solid #fff;
        color: #fff;
    }
    &.bt-light-blue {
        background-color: #5bc0de;
        border: 1px solid #fff;
        color: #fff;
    }
}
</style>
