<template>
    <panelEagle id="cadTipoManutencao" :loading="isLoading">
        <div class="col-sm-12 nopadding divDontPrint">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage 
                        :icon="mdiTools" 
                        titulo="Tipos Manutenções">
                    </tituloPage>
                </div>
                <div class="col-sm-6">
                    <BasicButtonsCadastrosSC 
                        :disabled="$v.$invalid"
                        linkCancel="ListarTipoManutencao" 
                        tipoCancela='button'
                        @salvarCadastro="salvarTipoManutencao">
                    </BasicButtonsCadastrosSC>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-4 nopadding">
                    <inputSimple 
                        :inputClass="{'border border-danger':
                            $v.descricaoTipoManutencao.$anyError}"
                        @blur="$v.descricaoTipoManutencao.$touch()"
                        @changeInput="changeDescricao" 
                        :value="descricaoSelected" 
                        name="descricaoTipoManutencao" 
                        id="descricaoTipoManutencao"
                        label="Descrição*">
                    </inputSimple>
                </div>
                <div class="col-sm-4 nopadding">
                    <selectAll
                        :extraClass="{'border border-danger':
                            $v.empresaSelecionadas.$anyError}"
                        @close="$v.empresaSelecionadas.$touch()"
                        @changeSelect="changeEmpresa"
                        :selected="empresaSelected" 
                        :isMultiple="false" 
                        nameForRadio="empresasTipoManutencao" 
                        :hasSelectAll="false" 
                        :labels="[{indexDFH: 'EM', description: 'Empresa*'}]" 
                        :optionsArray="optionsArrayEmpresas" 
                        firstSelected="EM">
                    </selectAll>
                </div>
                <div class="col-sm-4 nopadding">
                    <inputSimple
                        :inputClass="{'border border-danger':
                            $v.kmTipoManutencao.$anyError}"
                        @blur="$v.kmTipoManutencao.$touch()"
                        @changeInput="changeKmPadrao" 
                        :value="kmTipoManutencao"
                        :hasMask="true"
                        :mask="['NNNNNNNNNN']"
                        name="kmTipoManutencao" 
                        id="kmTipoManutencao"
                        label="Km Padrão*">
                    </inputSimple>
                </div>
            </div>
        </div>
        <div class="col-sm-12 pl-0">
            <requiredFields/>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
    </panelEagle>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators';
import { EmpresasService } from '@/Services/auth/Empresas.service';
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { conectionError } from '@/Services/Helpers/swellHeper'
import { mdiTools } from '@mdi/js'
import Vue from 'vue'
export default Vue.extend({
    name:'cadastroTipoManutencao',
    components:{
        'inputSimple': require('@/components/Atom/Inputs/InputSimple').default,
        'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
        'selectAll': require('@/components/Atom/Select/SelectAll').default,
        'tituloPage': require('@/components/Atom/Header/Titulo').default,
        'BasicButtonsCadastrosSC': require(
            '@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
        'requiredFields': require(
            '@/components/Atom/Footer/RequiredFields').default,
    },
    validations:{
        descricaoTipoManutencao: { required },
        empresaSelecionadas    : { required },
        kmTipoManutencao       : { required, numeric },
    },
    methods: {
        changeEmpresa(value){
            this.empresaSelecionadas = value;
        },
        changeDescricao(descricao){ 
            this.descricaoTipoManutencao = descricao;
        },
        changeKmPadrao(kmpadrao){ 
            this.kmTipoManutencao = kmpadrao;
        },
        
        async salvarTipoManutencao(){
            this.isLoading = true
            let obj = {
                timproprietario: this.empresaSelecionadas[0],
                timdescricao: this.descricaoTipoManutencao,
                timkmpadrao: this.kmTipoManutencao,
                ticodigo: this.ticodigo,
            }
            let uri = this.uriBase+'salvar'
            let params = {tipoManutencao:obj}
            var dados = await new HttpRequest().Post(uri, params)
            if(dados.code == 201) {
                this.$router.push({name:'ListarTipoManutencao'})
            } else {
                conectionError()
            }
            this.isLoading = false
        },

        editarTipoManutencao(codigo){
            this.isLoading = true
            let uri  = this.uriBase+'registro'
            new HttpRequest().Post(uri, {'id': codigo}).then((dados)=>{
                var tipoManutencao = dados.data.dados;
                this.ticodigo = tipoManutencao.ticodigo;
                this.descricaoSelected = tipoManutencao.timdescricao;
                this.kmTipoManutencao = tipoManutencao.timkmpadrao;
                this.empresaSelected = [{
                    'value':tipoManutencao.ticliente, 
                    'description':tipoManutencao.clfantasia
                }]
                this.empresaSelecionadas = dados.data.dados['clcodigo']
                this.isLoading = false
            })
        },

    },
    data(){
        return{
            mdiTools:mdiTools,
            uriBase:'/manutencoes/cadastros/tipo/manutencao/',
            descricaoTipoManutencao: '',
            isLoading: false,
            statusAlert: 'blue',
            needShowAlert: false,
            messageAlert: '',
            descricaoSelected: '',
            kmTipoManutencao: '',
            empresaSelected: [],
            empresaSelecionadas: [],
            optionsArrayEmpresas : new EmpresasService().Get(),
            ticodigo: null,
        }
    },
    mounted(){
        if(this.$route.params.id){       
            this.ticodigo = this.$route.params.id
            this.editarTipoManutencao(this.$route.params.id)
        }
    }
})
</script>
