<template>
    <panelEagle id="cadTipoManutencaoView" :loading='gerandoRel'>
        <div class="col-sm-12 nopadding ">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage 
                        :icon="mdiTools" 
                        titulo="Tipos Manutenções">
                    </tituloPage>
                </div>
                <div class="col-sm-6 divDontPrint">
                    <basicButtonsCadastrosEIN
                        :loading="carregandoE"
                        @exportarCadastro="exportarTipoManutencao"
                        :dadosExportar='dadosExportar' 
                        tipoNovo='button'
                        linkNew="CadastrarTipoManutencao"
                        :disabledDropdown='listagemTipoManutencao.length == 0'>
                    </basicButtonsCadastrosEIN>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-4 nopadding divDontPrint">
                    <selectAll 
                        @changeSelect="changeEmpresa" 
                        :isMultiple="true" 
                        nameForRadio="empresasCadTipoManutencao" 
                        :hasSelectAll="true" 
                        :labels="[{indexDFH: 'EM', description: 'Empresas'}]" 
                        :optionsArray="optionsArrayEmpresas"
                        :selected="selectedEmpresa"
                        firstSelected="EM">
                    </selectAll>
                </div>
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <tableListagem 
            @editarCadastro="editarTipoManutencao" 
            @excluirCadastro="deletarTipoManutencao"
            deleteEdit="editDelete"
            :data="listagemTipoManutencao" 
            :titles="titles">
        </tableListagem>
    </panelEagle>
</template>

<script>
import { mdiTools } from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service';
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import Vue from 'vue'
import { mapGetters } from 'vuex';
export default Vue.extend({
    name:'listagemTipoManutencao',
    components:{
        'selectAll': require('@/components/Atom/Select/SelectAll').default,
        'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage': require('@/components/Atom/Header/Titulo').default,
        'tableListagem': require(
            '@/components/Atom/Table/TableListagem').default,
        'basicButtonsCadastrosEIN': require(
            '@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
    },
    methods:{
        ...mapGetters(['getMaster']),
        changeEmpresa(selecionados){
            this.empresasSelecionadas = selecionados;
            this.listagem();
        },
        clickBotao(event){
            this.status = event;
            this.listagem();
        },
        async listagem(){
            this.gerandoRel = true
            this.listagemTipoManutencao = []
            let uri = this.uriBase+'listagem'
            let params = {obj:this.empresasSelecionadas}
            var dados = await new HttpRequest().Post(uri, params)
            if(dados.status) {
                this.listagemTipoManutencao = dados.data.dados
            }
            this.gerandoRel = false 
        },
		async exportarTipoManutencao(type){
			var root =  process.env.VUE_APP_ROOT;
			this.setCarregando(type)
			if(this.empresasSelecionadas.length){
				let uri = this.uriBase+'exportar'
                let params = {'dados':this.listagemTipoManutencao,'tipo':type}
				var dados = await new HttpRequest().Post(uri, params)
                if(dados.status) {
                    open(root+'/'+dados.data.local)
                }
			} else {
				this.listagemTipoManutencao = []
			}
			this.dadosExportar = this.listagemTipoManutencao
			this.setCarregando()
		},
		setCarregando(type=null){
            this.carregandoE = [type == 'pdf', type == 'xls', type == 'csv']
		},
		editarTipoManutencao(codigo){
			this.$router.push({
                name:'CadastrarTipoManutencao', 
                params:{id:codigo}
            })
		},
        async deletarTipoManutencao(codigo){
            this.gerandoRel = true
            let uri = this.uriBase+'deletar'
            await new HttpRequest().Post(uri, codigo)
            this.listagem()
            this.gerandoRel = false
        },
    },
    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optionsArrayEmpresas
        }
    },
    data() {
        return {
            mdiTools:mdiTools,
            carregandoE:[false,false,false],
            gerandoRel:false,
            empresasSelecionadas: [],
            optionsArrayEmpresas : new EmpresasService().Get(),
            selectedEmpresa:[],
            dadosExportar: [],
            dadosListagem: [],
            listagemTipoManutencao: [],
            titles:[
                {'name': 'Descrição', 'number': 'descricao'},
                {'name': 'Km Padrão', 'number': 'kmPadrao'},
                {'name': 'Empresa', 'number': 'empresa'},
            ],
            status:'',
            uriBase:'/manutencoes/cadastros/tipo/manutencao/'
        }
    },
})
</script>