<template>
    <div id='popup-eventos'>
        <div class='titulo-popup'>{{tituloPopup()}}</div>
        <div>
            <span>Data:</span>{{data}} {{hora}}
        </div>
        <!-- <div>
            <span>Hora:</span>{{hora}}
        </div> -->
        <div>
            <span>Endereço:</span>{{endereco}}
        </div>
        <div>
            <span>Nome:</span> {{mtnome}}
        </div>
    </div>
</template>

<script>
export default {
    name:'popupEventos',
    props:{
        endereco:{
            type: String,
            required:true
        },
        data:{
            type: String,
            required:true
        },
        hora:{
            type: String,
            required:true
        },
        mtnome:{
            type: String,
            required:true
        },
        tipoEvento:{
            type: Number,
            required:true
        }
    },
    methods:{
        /**
         * @description decide o título da popup de acordo
         * com o tipo de evento que ocorreu.
         * @return {string} titulo da popup
         */
        tituloPopup(){
            var titulo = '';
            switch(this.tipoEvento){
                case 405:
                    titulo = 'Colisão'
                    break
                case 82:
                    titulo = 'Freada brusca'
                    break
                case 80:
                    titulo = 'Aceleração brusca'
                    break
                case 88:
                    titulo = 'Freada brusca em curva'
                    break
                case 87:
                    titulo = 'Curva em alta velocidade'
                    break
                case 89:
                    titulo = 'Aceleração brusca em curva'
                    break
            }
            return titulo
        }
    }
    
}
</script>

<style lang="scss" scoped>
#popup-eventos{
    span{
        font-weight: bolder;
        padding-right: 2px;
    }
    .titulo-popup{
        font-weight: bolder;
        font-size: 14px;
        color:red;
    }
}
</style>