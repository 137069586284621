<template>
    <panelEagle id="importarCargasView" :loading="loadingPanel">
        <div class="col-sm-12 nopadding row ">
            <div class="col-sm-4">
                <tituloPage :icon="mdiTruck" titulo="Importar Pedidos + Cargas" />
            </div>

            <div class="col-sm-8" align="right">
                <simpleButton :disabled="$v.$invalid" @click="gravarImportacaoDePontosECargas" event="click" text="Salvar"
                    :icon="mdiCheckBold" type="blue" width="20%">
                </simpleButton>
            </div>
        </div>

        <div class="col-sm-12">
            <hr>
        </div>

        <div class="col-sm-12 row nopadding">
            <div class="col-sm-4 nopadding">
                <selectAll @changeSelect="changeEmpresa" :isMultiple="false" nameForRadio="empresaImportacoes"
                    :disabled="cadastrar" :hasSelectAll="false" :labels="[{ indexDFH: 'EM', description: 'Empresas*' }]"
                    :optionsArray="optionsArrayEmpresas" :selected="selectedEmpresas" firstSelected="EM">
                </selectAll>
            </div>

            <div class="col-sm-4 nopadding">
                <selectAll ref="refSelectPontosEcargas" :isSelected="selectedTipoPontoECargas"
                    @changeSelect="changeTipoPonto" :isMultiple="false" :disabled="cadastrar" nameForRadio="tipoDePonto"
                    :hasSelectAll="false" :labels="[{
                        indexDFH: 'TP',
                        description: 'Selecione o tipo de ponto*'
                    }]" :optionsArray="optionsTipoDePonto" firstSelected="TP">
                </selectAll>
            </div>

            <div class="col-sm-4 nopadding">
                <selectAll ref="refSelectTipoArquivo" :isSelected="selectedTipoArquivo" @changeSelect="changeTipoArquivo"
                    :isMultiple="false" :disabled="cadastrar" nameForRadio="tipoDeArquivo" :hasSelectAll="false" :labels="[{
                        indexDFH: 'TPA',
                        description: 'Selecione o tipo de arquivo*'
                    }]" :optionsArray="optionsTipoDeArquivo" firstSelected="TPA">
                </selectAll>
            </div>
        </div>

        <div class="col-sm-12">
            <hr>
        </div>

        <div class="col-sm-12 nopadding row">
            <inputDropZone v-if="getCadastrar() && selectedTipoArquivo == 'P'" ref="inputDropZone" @files="dropzoneNewFiles"
                msgCentro="Arraste e solte o seu arquivo (.txt)">
            </inputDropZone>

            <inputDropZone v-if="getCadastrar() && selectedTipoArquivo == 'N'" ref="inputDropZone" @files="dropzoneNewFiles"
                arquivosPermitidos=".xml" msgCentro="Arraste e solte seu arquivo (.xml)">
            </inputDropZone>

            <div v-if="getCadastrar() && selectedTipoArquivo == ''" class="statusRel status-info margin">
                Selecione os compos obrigatórios para importar!
            </div>

            <div v-if="!getCadastrar()" class="statusRel status-error margin">
                Você não possui permissão para importar um ponto.
            </div>
        </div>

        <div class="col-sm-12">
            <hr>
        </div>
        <b-row>
            <div
                :class="totalSucesso > 0 && totalErros > 0 ? 'styleDivNumbers border border-secondary rounded mt-2 pl-3 pr-3 pt-1 pb-1 col-sm-6' : 'styleDivWithoutError mt-2 pl-3 pr-3 pt-1 pb-1 col-sm-6'">
                <div class="d-flex justify-content-center">
                    <div v-if="totalSucesso > 0" class="bgSuccess border border-success py-2 text-center col-sm-6 rounded">
                        <p class="ml-3 textSuccess styleTextNumbers">{{ totalSucesso }}</p>
                        <p class="textStyleInfo">{{ totalSucesso > 1 ? 'Pedidos importados' : 'Pedido importado' }} com
                            sucesso</p>
                    </div>

                    <div v-if="totalErros > 0" class="bgError border border-danger py-2 text-center col-sm-6 rounded">
                        <p class="ml-3 textError styleTextNumbers">{{ totalErros }}</p>
                        <p class="textStyleInfo">{{ totalErros > 1 ? 'Pedidos importados' : 'Pedido importado' }} com erro
                        </p>
                    </div>
                </div>
            </div>
        </b-row>

        <tableRelatorio :htmlRel="''" v-if="getCadastrar() && tamanhoItensImportacao > 0">
            <slot slot="thead">
                <tr>
                    <th colspan="4">Alertas</th>
                </tr>
            </slot>
            <slot slot="tbody">
                <br>

                <tr v-show="mostrarStatusBar">
                    <td colspan="15" v-if="statusbar == 'info'" class="statusRel status-info">
                        {{ msgCentro }}
                    </td>

                    <td colspan="15" v-else-if="statusbar == 'loading'" class="statusRel status-loading">
                        <pulse-loader :loading="true" size="12px" color="#fff" />
                        Carregando
                    </td>

                    <td colspan="15" v-else-if="statusbar == 'error'" class="statusRel status-error">
                        {{ msgCentro }}
                    </td>
                </tr>

                <dontFinded :key="ponto[0]" v-for="(ponto) in pontosNaoEncontrados" :dadosPonto="ponto">
                </dontFinded>

                <lineError :key="index" v-for="(error, index) in pontosError" :error="error">
                </lineError>

                <lineDontSave :key="index" v-for="(Nsaved, index) in pontosNaoSalvos" :dados="Nsaved">
                </lineDontSave>
            </slot>
        </tableRelatorio>

        <!-- modal informações de itens duplicados -->
        <modalEagle @confirmButton="salvarItemDuplicado(true)" @cancelButton="salvarItemDuplicado(false)"
            :disabledButton="this.itensDuplicadosParaSalvar.length <= 0" id="modalInformacoesItensDuplicados"
            title="Já possui um pedido importado com o mesmo número de Nota Fiscal! Tem certeza que deseja fazer isso?"
            textoConfirmar="Salvar" :tamanhoBtn="'110px'" size="xl">
            <template #modalBody>
                <!-- <p>Total XMLs duplicados {{ itensDuplicados.length }}</p> -->
                <tableRelatorio>
                    <slot slot="thead">
                        <tr class="text-center">
                            <th title="Marcar todos">
                                <b-check v-model='marcarTodos' @change="selecionarTodosItensDuplicados">
                                </b-check>
                            </th>
                            <th>Documento</th>
                            <th>Nome</th>
                            <th>CNPJ</th>
                            <th>Data</th>
                            <th>Valor {{ itensDuplicadosParaSalvar.length }}</th>
                        </tr>
                    </slot>

                    <slot slot="tbody">
                        <template>
                            <tr v-for="(itemDuplicado, index) in  itensDuplicados" :key="index">
                                <td title="Marcar item">
                                    <b-check @change='selecionarItemDuplicado(itemDuplicado)'
                                        v-model="itemDuplicado.fechar">
                                    </b-check>
                                </td>
                                <td class="text-truncate" :title="itemDuplicado.documento[0]">{{ itemDuplicado.documento[0]
                                }}</td>
                                <td class="text-truncate" :title="itemDuplicado.nomeRemetente[0]">{{
                                    itemDuplicado.nomeRemetente[0] }}</td>
                                <td class="text-truncate" :title="formatarCNPJ(itemDuplicado.cnpj)">{{
                                    formatarCNPJ(itemDuplicado.cnpj) }}</td>
                                <td class="text-truncate" :title="formatarData(itemDuplicado.data)">{{
                                    formatarData(itemDuplicado.data) }}</td>
                                <td class="text-truncate" :title="itemDuplicado.valor">R$ {{ itemDuplicado.valor }}</td>
                            </tr>
                        </template>
                    </slot>
                </tableRelatorio>
            </template>
        </modalEagle>
    </panelEagle>
</template>

<script lang="js">
/* eslint-disable */
import Vue from 'vue'
import { mdiTruck, mdiCheckBold } from '@mdi/js'
import { EmpresasService } from '../../../Services/auth/Empresas.service'
import { required } from 'vuelidate/lib/validators'
// import { HttpRequest } from '../../../Services/auth/HttpRequest.Service'
import { AuthService } from '../../../Services/auth/Auth.Service';
import { conectionError } from '@/Services/Helpers/swellHeper';
import { mapGetters } from 'vuex'

export default Vue.extend({
    name: 'ImportacaoCargas',

    components: {
        PulseLoader: require('vue-spinner/src/PulseLoader').default,
        tituloPage: require('@/components/Atom/Header/Titulo').default,
        selectAll: require('@/components/Atom/Select/SelectAll').default,
        panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
        inputRadio: require('@/components/Atom/Inputs/InputRadio').default,
        simpleButton: require('@/components/Atom/Buttons/SimpleButton').default,
        inputDropZone: require('@/components/Atom/Inputs/InputDropZone').default,
        tableRelatorio: require('@/components/Atom/Table/TableRelatorio.vue').default,
        // 'statusInformation': require('@/components/Atom/StatusInformation/StatusInformation').default,
        lineError: require('@/components/Atom/SpecificComponents/ImportarCargas/LineWithError').default,
        lineDontSave: require('@/components/Atom/SpecificComponents/ImportarCargas/LineDontSave').default,
        dontFinded: require('@/components/Atom/SpecificComponents/ImportarCargas/LinePointDontFinded').default,
        modalEagle: require("@/components/Atom/Modal/ModalEagle").default,
    },

    data() {
        return {
            mdiCheckBold: mdiCheckBold,
            mdiTruck: mdiTruck,
            mostrarStatusBar: true,
            msgCentro: "Adicione algum arquivo(.txt) e selecione os campos obrigatórios(*) para importar",
            loadingPanel: false,
            archives: [],
            optionsArrayEmpresas: new EmpresasService().Get(),
            selectedEmpresa: [],
            statusbar: 'info',
            tamanhoItensImportacao: 0,
            htmlImportacao: '',
            pontosNaoEncontrados: [],
            pontosError: [],
            totalSucesso: 0,
            totalErros: 0,
            pontosNaoSalvos: [],
            // VARIAVEIS PARA O INPUT RADIO
            selectedRadioOpt: false,
            // Variaveis para o select de tipo de ponto
            optionsTipoDePonto: [{ 'value': 'C', 'description': 'Coleta' },
            { 'value': 'E', 'description': 'Entrega' },
            { 'value': 'P', 'description': 'Referência' }],
            optionsTipoDeArquivo: [{ 'value': 'P', 'description': 'Padrão' },
            { 'value': 'N', 'description': 'NFE' }],
            selectedTipoPontoECargas: [],
            selectedTipoPonto: [],
            selectedEmpresas: [],
            selectedTipoArquivo: [],
            index: 0,
            itensDuplicados: [],
            indexItensDuplicados: 0,
            marcarTodos: false,
            itensDuplicadosParaSalvar: [],
            itensDuplicadosXML: [],
            archivesAux: [],
            arquivosDuplicadosSalvar: [],
            salvarItemDuplicadoBoolean: false,
        }
    },

    validations: {
        archives: { required },
        selectedEmpresa: { required },
        selectedTipoPonto: { required },
        selectedTipoArquivo: { required },
    },

    methods: {
        ...mapGetters(['getMaster', 'getCadastrar', 'getEditar']),

        /**
         * Função para formatar um cnpj que tenha somente números, e retorna o cnpj formatado.
         * @return {string} cnpj formatado com a pontuação correta.
         * @param {string | número} cnpj para realizar a formação e deixar ele com a pontução correta.
         * @author Yuri 🇧🇷
         */
        formatarCNPJ(cnpj) {
            cnpj = cnpj.replace(/\D/g, '');

            cnpj = cnpj.replace(/^(\d{2})(\d)/, '$1.$2');
            cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
            cnpj = cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2');
            cnpj = cnpj.replace(/(\d{4})(\d)/, '$1-$2');

            return cnpj;
        },

        /**
         * Função para formatar uma data, e retorna o data formatada.
         * @return {string} data formatado com a pontuação correta.
         * @param {string } data para realizar a formação e deixar ela no formato 24/05/2002.
         * @author Yuri 🇧🇷
         */
        formatarData(data) {
            var partes = data.split('-');

            var dataFormatada = partes[2] + '/' + partes[1] + '/' + partes[0];

            return dataFormatada;
        },

        /**
         * Função para selecionar todos os itens duplicados, e salvar na variável this.itensDuplicadosParaSalvar.
         * @author Yuri 🇧🇷
         */
        selecionarTodosItensDuplicados() {
            this.itensDuplicados.forEach((e) => {

                e.fechar = this.marcarTodos

                this.$forceUpdate()
            })

            if (this.marcarTodos) {
                this.itensDuplicadosParaSalvar = [];
                this.itensDuplicadosParaSalvar = [...this.itensDuplicados];
            } else {
                this.itensDuplicadosParaSalvar = [];
            }

        },

        /**
         * Função para selecionar um item duplicado somente, consulta se o item já existe em this.itensDuplicadosParaSalvar
         * caso existe ele remove e caso não tenha adiciona em this.itensDuplicadosParaSalvar.
         * @author Yuri 🇧🇷
         */
        selecionarItemDuplicado(item) {
            this.marcarTodos = false;

            const indice = this.itensDuplicadosParaSalvar.findIndex(
                (elemento) => elemento.documento[0] === item.documento[0]
            );

            if (indice !== -1) {
                this.itensDuplicadosParaSalvar.splice(indice, 1);
            } else {
                this.itensDuplicadosParaSalvar.push(item);
            }
        },

        /**
         * Função para salvar o item mesmo duplicado, chama a mesma função que usamos para importar no começo, this.gravarImportacaoDePontosECargas()
         * porem para determinar se vai ser salvo mesmo duplicado está pela variável this.salvarItemDuplicadoBoolean, quando ela estiver true
         * no back-end vai deixar de consultar se esse item já existe e deixa salvar, mesmo duplicado.
         * @author Yuri 🇧🇷
         */
        salvarItemDuplicado(salvarItens) {
            this.marcarTodos = false;
            this.resetaTotalizadores();

            let arquivosDuplicadosSalvar = [];

            if (salvarItens) {
                this.salvarItemDuplicadoBoolean = true;
                arquivosDuplicadosSalvar = this.archivesAux.filter((item1) => {
                    let documentoArray1 = [];
                    if (item1.upload.data.itens != null) {
                        documentoArray1 = item1.upload.data.itens[0].documento[0];
                    }

                    return this.itensDuplicadosParaSalvar.some((item2) => {
                        if (item2 && item2.documento[0] === documentoArray1) {
                            return true;
                        }
                        return false;
                    });
                });
            } else {
                this.salvarItemDuplicadoBoolean = false;
            }

            this.itensDuplicados = [];
            this.itensDuplicadosParaSalvar = [];
            this.$bvModal.hide('modalInformacoesItensDuplicados');
            this.archives = arquivosDuplicadosSalvar;
            if (arquivosDuplicadosSalvar.length > 0) {
                this.gravarImportacaoDePontosECargas();
            } else {
                this.salvarItemDuplicadoBoolean = false;
            }
        },

        limpaAlertas() {
            this.resetaTotalizadores();
            this.pontosError = []
            this.pontosNaoSalvos = []
            this.pontosNaoEncontrados = []
            this.mostrarStatusBar = true
        },

        /**
   * @description limpa os valores das variáveis dos totalizadores
   * @author Otávio 🦆 
  */
        resetaTotalizadores() {
            this.totalErros = 0;
            this.totalSucesso = 0;
        },

        changeEmpresa(empresa) {
            this.resetaTotalizadores();
            this.selectedEmpresa = empresa
            this.limpaAlertas()
        },

        changeTipoPonto(tipo) {
            this.resetaTotalizadores();
            this.selectedTipoPonto = tipo
            this.limpaAlertas()
        },

        changeTipoArquivo(tipo) {
            this.resetaTotalizadores();
            this.selectedTipoArquivo = tipo
            this.limpaAlertas()
        },

        dropzoneNewFiles(dropZone) {
            this.resetaTotalizadores();
            this.archives = dropZone;
            this.archivesAux = [...this.archives];
            this.indexItensDuplicados = this.archives.length;
            this.limpaAlertas()
        },

        // changeRadioTipoImportacao(input) {
        //     this.$refs.refSelectPontosEcargas.clearAll()
        //     this.selectedRadioOpt = !this.selectedRadioOpt
        // },

        // gravarImportacao(){
        //     this.gravarImportacaoDePontosECargas();
        // },

        // Não está chamando a função (chama a função gravarImportacaoDePontosECargas)
        // async gravarImportacaoDeCargas() {
        //     this.limpaAlertas()
        //     if(this.selectedEmpresa.length > 0 && this.archives.length > 0){
        //         this.loadingPanel = true
        //         var service = new AuthService();
        //         var Token = service.getToken();
        //         var input = this.$refs.inputDropZone
        //         var url =  process.env.VUE_APP_ROOT+'/api/roteirizador/importar/cargas/cargas';
        //         var dropZone = input.$refs.vueFileAgent;
        //         var strBearer = `Bearer ${Token.token || ""}`;
        //         var header = {
        //             'Authorization': strBearer,
        //         }
        //         var empresas = this.selectedEmpresa[0];
        //         var tipoArquivo = this.selectedTipoArquivo;
        //         this.statusbar = 'loading'
        //         this.pontosNaoEncontrados = [];
        //         this.pontosError = [];
        //         this.pontosNaoSalvos = [];
        //         this.tamanhoItensImportacao = 0;
        //         var retorno = await dropZone.upload(url, header, this.archives, function createFormData(fileData){
        //             var formData = new FormData();
        //             formData.append('action', 'upload');
        //             formData.append('file', fileData.file);
        //             formData.append('empresa', empresas) // this is important! - the actual file for upload
        //             formData.append('tipoArquivo', tipoArquivo)
        //             return formData; // edit: fixed. Thanks to Chauhan-Nitesh for the comment below
        //         });
        //         input.clearArchives();
        //         if(retorno[0].data.codigo == '415'){
        //             this.statusbar = 'error'
        //             this.msgCentro = 'Formato de arquivo invalido!'
        //         }else{
        //             this.statusbar = 'info'
        //             this.msgCentro = 'Adicione algum arquivo(.txt) e selecione os campos obrigatórios(*) para importar'
        //             for(var a in retorno){
        //                 var dados = retorno[a].data
        //                 var nomeArquivo = retorno[a].fileRecord.name();
        //                 if(dados.codigo == 415){
        //                     this.tamanhoItensImportacao = 1;
        //                     this.pontosError
        //                         .push('O arquivo '+nomeArquivo+' está com o formato inválido, por favor verifique!')
        //                 }else if(dados.codigo == 417){
        //                     this.tamanhoItensImportacao = 1;
        //                     this.pontosError
        //                         .push('O arquivo '+nomeArquivo+' está com o codigo externo inválido, por favor verifique!')
        //                 }else{
        //                     this.tamanhoItensImportacao = 1;
        //                     this.pontosNaoEncontrados = dados.naoEncontrados;
        //                     this.pontosNaoSalvos = dados.naoSalvo;
        //                 }
        //             }
        //         }
        //         this.loadingPanel = false
        //     }
        // },

        async gravarImportacaoDePontosECargas() {
            try {
                if (this.selectedEmpresa.length > 0 && this.archives.length > 0 && this.selectedTipoPonto.length > 0) {
                    this.loadingPanel = true
                    this.indexItensDuplicados--;

                    var service = new AuthService();
                    var Token = service.getToken();
                    var input = this.$refs.inputDropZone
                    var url = process.env.VUE_APP_ROOT + '/api/roteirizador/importar/cargas/pontos/cargas';
                    var dropZone = input.$refs.vueFileAgent;
                    var strBearer = `Bearer ${Token.token || ""}`;
                    var header = {
                        'Authorization': strBearer,
                    }
                    var empresas = this.selectedEmpresa[0];
                    var tipoPonto = this.selectedTipoPonto[0];
                    var tipoArquivo = this.selectedTipoArquivo
                    var arquivo = [this.archives[this.index]];
                    var salvarItemDuplicado = this.salvarItemDuplicadoBoolean;
                    this.pontosNaoEncontrados = [];
                    this.pontosNaoSalvos = [];

                    var retorno = await dropZone.upload(url, header, arquivo, function createFormData(fileData) {
                        var formData = new FormData();
                        formData.append('action', 'upload');
                        formData.append('file', fileData.file);
                        formData.append('empresa', empresas)
                        formData.append('tipoPonto', tipoPonto)
                        formData.append('tipoArquivo', tipoArquivo)
                        formData.append('salvarItemDuplicado', salvarItemDuplicado)
                        return formData; // edit: fixed. Thanks to Chauhan-Nitesh for the comment below
                    });

                    if (retorno[0].data) {
                        if (retorno[0].data.itens != null) {
                            this.itensDuplicados.push(retorno[0].data.itens[0]);
                        }
                    }

                    // verifica se arquivo NFE teve sucesso e mostra mensagem de sucesso
                    if (retorno[0].data.success && !retorno[0].data.itensDuplicados) {
                        // this.mostrarStatusBar = false
                        this.totalSucesso += retorno[0].data.sucessos;
                        this.totalErros += retorno[0].data.erro;
                        this.$bvToast.toast('Importado com sucesso!', {
                            title: 'Ok',
                            autoHideDelay: 3000,
                            variant: 'success'
                        })
                        // verifica se arquivo NFE teve problema de importação
                        // e adiciona a array de erros
                    } else if (retorno[0].data.success && retorno[0].data.itensDuplicados) {
                        this.totalSucesso += retorno[0].data.sucessos;
                        this.totalErros += retorno[0].data.erro;
                        this.$bvToast.toast('Problema ao importar!!', {
                            title: 'Atenção',
                            autoHideDelay: 3000,
                            variant: 'warning'
                        })
                    } else if (retorno[0].data.success == false) {
                        this.totalSucesso += retorno[0].data.sucessos;
                        this.totalErros += retorno[0].data.erro;
                        this.tamanhoItensImportacao = 1;
                        var mensagem = String(retorno[0].data.erros[0])
                        this.pontosError.push(retorno[0].data.erros[0])
                        this.mostrarStatusBar = false
                        this.$bvToast.toast(mensagem, {
                            title: 'Problema ao importar!',
                            autoHideDelay: 5000,
                            variant: 'warning'
                        })
                    } else if (retorno[0].data.status != 415 && !retorno[0].data.itensDuplicados) {
                        input.clearArchives()
                        this.mostrarStatusBar = false
                        for (var a in retorno) {
                            var dados = retorno[a].data
                            if (dados) {
                                this.totalSucesso += dados.sucessos;
                                this.totalErros += dados.erros;
                                for (var b in dados.mensagensErro) {
                                    this.tamanhoItensImportacao = 1;
                                    this.pontosError.push(dados.mensagensErro[b])
                                }
                            }
                        }
                        this.$bvToast.toast('Importado com sucesso!', {
                            title: 'Ok',
                            autoHideDelay: 3000,
                            variant: 'success'
                        })

                    } else if (retorno[0].data.status != 415 && retorno[0].data.itensDuplicados) {
                        this.totalErros = 1;
                        this.$bvToast.toast('Problema ao importar!!', {
                            title: 'Atenção',
                            autoHideDelay: 3000,
                            variant: 'warning'
                        })
                    } else if (retorno[0].data.status == 415) {
                        this.totalErros = 1;
                        this.mostrarStatusBar = true
                        this.statusbar = 'error'
                        this.msgCentro = 'Valores inválidos no arquivo'
                    } else {
                        this.$bvToast.toast('Não foi possível importar o arquivo!', {
                            title: 'Ops!',
                            autoHideDelay: 3000,
                            variant: 'error'
                        })
                    }

                    this.loadingPanel = false
                    this.index += 1
                    // Verifica se foi adicionado vários arquivos para chamar novamente a função 
                    // e mandar o próximo arquivo se não limpa a variável de arquivos

                    if (this.archives[this.index]) {
                        this.gravarImportacaoDePontosECargas();

                    } else {
                        input.clearArchives()
                        this.archives = []

                        this.index = 0
                        this.gravarImportacaoDePontosECargas();

                        if (this.itensDuplicados.length > 0) {
                            this.marcarTodos = true;
                            this.selecionarTodosItensDuplicados();
                            this.$bvModal.show('modalInformacoesItensDuplicados');
                        }

                        this.salvarItemDuplicadoBoolean = false;
                    }

                }
            } catch {
                this.mostrarStatusBar = true
                this.statusbar = 'error'
                this.msgCentro = 'Valores inválidos no arquivo'
                this.loadingPanel = false
            }
        }
    },

    computed: {
        cadastrar() {
            return !this.getCadastrar()
        },
        // desativaSalvar: function(){
        //     if(this.selectedRadioOpt == 'C'){
        //         return !(this.selectedEmpresa.length > 0 && 
        //             this.archives.length > 0)
        //     }else if(this.selectedRadioOpt == 'PC'){
        //         return !(this.selectedEmpresa.length > 0 && 
        //             this.archives.length > 0 && 
        //             this.selectedTipoPonto.length > 0)
        //     }
        // },
    },

    mounted() {
        if (!this.getCadastrar() && !this.getMaster()) {
            let msg = "Apenas usuarios com a permissão de cadastro podem utilizar essa tela"
            let title = "Você não tem o perfil de acesso necessário para usar essa tela"
            conectionError(msg, 'info', title)
        }
        if (!this.getMaster()) {
            if (this.optionsArrayEmpresas && this.optionsArrayEmpresas.length) {
                this.selectedEmpresas = [this.optionsArrayEmpresas[0]]
            }
        }
    }
})
</script>

<style lang="scss">
#importarCargasView {

    // pra n subir o index do loading panel
    .vue-file-agent.has-multiple .file-preview,
    .vue-file-agent.is-single .is-deletable .file-preview {
        z-index: 7;
    }

    // a regra mais grande e pq se n ele n deixa remover o arquivo dps
    // pra n subir o index do loading panel
    .vue-file-agent .file-input {
        z-index: 5;
    }

    .statusRel {
        padding: 23px !important;
        font-size: 14px;
        color: #FFF;
        text-align: center;

        &.status-info {
            background: #1caf9a;
        }

        &.status-error {
            background: #E04B4A;
        }

        &.status-loading {
            background: #428BCA;
        }

        .v-spinner {
            float: right;
        }
    }

    .margin {
        width: 100% !important;
        margin: 0px 15px !important;
    }

    .styleDivNumbers {
        width: 30%;
        margin: auto;
        background-color: #f5f5f5;
    }

    .styleDivWithoutError {
        width: 30%;
        margin: auto;
    }

    .bgSuccess {
        background-color: #e3ffe6;
        margin-left: 10px;
        margin-right: 10px;
    }

    .textSuccess {
        color: #5bae79;
    }

    .bgError {
        background-color: #ffe9e3;
        margin-left: 10px;
        margin-right: 10px;
    }

    .textError {
        color: #ed441d;
    }

    .styleTextNumbers {
        font-size: 30px;
        font-weight: 900;
    }

    .textStyleInfo {
        font-size: 12px;
        font-weight: 700;
    }
}
</style>
