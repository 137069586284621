import { render, staticRenderFns } from "./FiltrosPainelColetivo.vue?vue&type=template&id=196afba2"
import script from "./FiltrosPainelColetivo.vue?vue&type=script&lang=js"
export * from "./FiltrosPainelColetivo.vue?vue&type=script&lang=js"
import style0 from "./FiltrosPainelColetivo.vue?vue&type=style&index=0&id=196afba2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports