/**
    Este componente é a polyline mais utilizada do sistema

    -> Propriedades:
        1- latLng - Object/Array - conjunto de latitudes e longitudes - EX: [[lat, lng], [lat, lng]]
        2- polylineClass - String - Classe extra para o componente - EX: 'polyline'
        3- id - String - id para o componente - EX: 'polyline'
        4- color - String - Hex da cor da linha - EX: '#428bca'
        5- hoverColor - String - Hex da cor da linha enquanto tiver hover - EX: '#428bca'
        6- visible - Boolean - Se o objeto deve estar na tela ou não - EX: true/false
        7- opacity - Number/String - Opacidade da pilyline - Ex: '0.8' ou 0.8

    -> Slots:
        1- Sem nome - Não lembro pq fiz esse slot mas ta ai para colocar oq precisarem

    -> Eventos:
        1- rotaClick - Emite quando o polyline tiver sido clicado - Parametros:
            1.1 - latLng - Conjunto de latitudes e longitudes em array
            1.2 - polylineClass - A classe que foi passada como parametro
            1.3 - id - O id que foi passado como parametro
            1.4 - 'PAP' - Identificador de *PolylineAntPath*
 */

<template>
    <div>
        <slot></slot>
    </div>
</template>
<script>
import L from 'leaflet';
import {antPath} from 'leaflet-ant-path';
import { propsBinder } from 'vue2-leaflet';

const props = {
	latLng: {
		type: [Object, Array],
		required: true,
	},
	polylineClass:{
		type: String,
		required: false,
		default: '',
	},
	id:{
		type: String,
		required: false,
		default: '',
	},
	color:{
		type: String,
		required: false,
		default: '#428bca',
	},
	hoverColor:{
		type: String,
		required: false,
		default: '#428bca',
	},
	visible: {
		type: Boolean,
		custom: true,
		default: true
	},
	opacity:{
		type: [Number, String],
		required: false,
		default: '0.9',
	},
};

export default {
	props: props,
	mounted: function() {
		var polylineInfo = this
		this.mapObject = antPath(this.latLng, {
			'delay': 1500,
			'weight': 8,
			'color': polylineInfo.color,
			'opacity':this.opacity
		}).on('mouseover', function (e) {
			e.target.setStyle({
				color: polylineInfo.hoverColor,
				delay: 1500,
			});
		}).on('mouseout', function (e) {
			e.target.setStyle({
				color: polylineInfo.color,
				delay: 1500,
			});
		})
			.on('click', function(e){
				polylineInfo.$emit('rotaClick', polylineInfo.latLng, polylineInfo.polylineClass, polylineInfo.id, 'PAP')
			});
		propsBinder(this, this.mapObject, props);
		if (this.$parent._isMounted) {
			this.deferredMountedTo(this.$parent.mapObject);
		}
		polylineInfo.$emit('rotaClick', polylineInfo.latLng, polylineInfo.polylineClass, polylineInfo.id, 'PAP')
	},
	beforeDestroy() {
		this.setVisible(false);
	},
	methods: {
		deferredMountedTo(parent) {
			this.parent = parent;
			var that = this.mapObject;
			for (var i = 0; i < this.$children.length; i++) {
				if (typeof this.$children[i].deferredMountedTo == 'function') {
					this.$children[i].deferredMountedTo(that);
				}
			}
			if (this.visible) {
				this.mapObject.addTo(parent);
			}
		},
		setVisible(newVal, oldVal) {
			if (newVal === oldVal) return;
			if (this.mapObject) {
				if (newVal) {
					this.mapObject.addTo(this.parent);
				} else {
					this.parent.removeLayer(this.mapObject);
				}
			}
		},
		trataLatLng(coord){
			var coordenadas = [];
			for(var a in coord){
				coordenadas.push([coord[a].lat, coord[a].lng])
			}

			return coordenadas;
		},

	},
	watch:{
		latLng(){
			var polylineInfo = this
			this.mapObject = antPath(this.latLng, {
				'delay': 1500,
				'weight': 8,
				'color': polylineInfo.color,
				'opacity':this.opacity
			}).on('mouseover', function (e) {
				e.target.setStyle({
					color: polylineInfo.hoverColor,
					delay: 1500,
				});
			}).on('mouseout', function (e) {
				e.target.setStyle({
					color: polylineInfo.color,
					delay: 1500,
				});
			})
				.on('click', function(e){
					polylineInfo.$emit('rotaClick', polylineInfo.latLng, polylineInfo.polylineClass, polylineInfo.id, 'PAP')
				});
			propsBinder(this, this.mapObject, props);
			if (this.$parent._isMounted) {
				this.deferredMountedTo(this.$parent.mapObject);
			}
			polylineInfo.$emit('rotaClick', polylineInfo.latLng, polylineInfo.polylineClass, polylineInfo.id, 'PAP')
		},
		visible:function(){
			this.setVisible(this.visible)  
		}
	},
};
</script>

<style lang="scss">
</style>
