<template>
    <panel-eagle id='resumo-jornada-checkpoint' :loading='carregando'>
		<div class="col-12 row">
			<div class="col-6 nopadding">
				<titulo 
				:icon='iconTitulo'
				titulo='Resumo de Jornada'/>
			</div>
			<div class="col-6 nopadding">
				<basicButtonsRelatoriosEIG 
				:disabled='$v.$invalid'
				:disabledDropdown='!mostrarTabela'
				:loading='loadingExportar'
				@exportarRelatorio='exportarRelatorio'
				@gerarRelatorio="gerarRelatorio"/>
			</div>
		</div>
		<div class="col-sm-12"><hr></div>
		<slide-up-and-down>
			<div class="col-12 nopadding row mb-2">
				<div class="col-3 nopadding">
					<periodo
					name='jorge2'
					titulo='Intervalo*'
					@changeInput='mudaIntervalo'/>
				</div>
				<div class="col-3 nopadding">
					<select-all
					nameForRadio='jorge'
					:labels="labelsSelectEmpresa" 
					:optionsArray='optionsSelectEmpresa'
					:extraClass='{
						"border border-danger":$v.clientesSelecionados.$anyError
					}'
					:hasSelectAll='true'
					:isMultiple='true'
					@close='$v.clientesSelecionados.$touch()'
					@changeSelect='mudaClienteSelecionado'/>
				</div>
				<div class="col-3 nopadding">
					<select-all 
					nameForRadio='jorge3'
					ref='seletorJornada'
					:labels='labelSelectJornada'
					:isMultiple='true'
					:hasSelectAll='true'
					:disabled='desabilitaSeletorJ'
					:loading='buscandoInformacoes'
					:optionsArray='optionsSelectJornada'
					:extraClass='{
						"border border-danger":$v.jornadasSelecionadas.$anyError
					}'
					@close='$v.jornadasSelecionadas.$touch()'
					@changeSelect='mudaJornadaSelecionada'/>
				</div>
				<div class="col-3 nopadding">
					<select-all
					nameForRadio='jorge4'
					ref='seletorEmpregado'
					:isMultiple='true'
					:hasSelectAll='true'
					:loading='buscandoFuncionarios'
					:labels='labelSelectEmpregado'
					:disabled='desabilitaSeletorC'
					:optionsArray='optionsSelectEmpregado'
					@changeSelect='mudaFuncionarioSelecionado'/>
				</div>
			</div>
		</slide-up-and-down>
		<div class="col-12"><hr></div>
		<div class="col-12">
			<table-simples>
				<br>
				<thead>
					<th>Colaborador</th>
					<th>Horas trabalhadas</th>
					<th>Intervalo</th>
					<th>Horas Faltas</th>
					<th>Horas nortunas</th>
					<th>Extra 1</th>
					<th>Extra 100%</th>
					<th>Saldo</th>
				</thead>
				<tbody v-if='mostrarTabela'>
					<tr>
						<td colspan="1" class='titulo-resumo-jornada'>
							{{arrumaFuckingPeriodo()}}
						</td>
					</tr>
					<template v-for='(wk, index) in tableDataShow'>
						<tr :key="`${index}_info`">
							<td>
								{{wk.mtnome}}
							</td>
							<td>
								{{wk.fphoranormal}}
							</td>
							<td>
								{{wk.fpintervalo}}
							</td>
							<td>
								{{wk.fphorasfalta}}
							</td>
							<td>
								{{wk.fptotalnoturnas}}
							</td>
							<td>
								{{wk.fpextra1}}
							</td>
							<td>
								{{wk.fpextra100}}
							</td>
							<td>
								{{wk.saldo}}
							</td>
						</tr>
					</template>
					<tr class='totalizadores'>
						<td>Total:</td>
						<td>{{totais.fphoranormal}}</td>
						<td>{{totais.fpintervalo}}</td>
						<td>{{totais.fphorasfalta}}</td>
						<td>{{totais.fptotalnoturnas}}</td>
						<td>{{totais.fpextra1}}</td>
						<td>{{totais.fpextra100}}</td>
						<td>{{totais.cshsaldo}}</td>
					</tr>
				</tbody>
				<tbody v-else>
					<statusBar
					:statusBar='statusBar'/>
				</tbody>
			</table-simples>
		</div>
	</panel-eagle>
</template>

<script>
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { mdiCalendarMonth } from '@mdi/js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { required } from 'vuelidate/lib/validators'
import { conectionError } from '@/Services/Helpers/swellHeper'
const urlBase = '/checkpoint/relatorios/resumo/jornada/'
export default {
	name:'ResumoJornadaCheckpoint',
	components: {
		panelEagle	  : require('@/components/Atom/Panel/PanelEagle').default,
		titulo		  : require('@/components/Atom/Header/Titulo').default,
		basicButtonsRelatoriosEIG: require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
		periodo		  : require('@/components/Atom/Datas/InputRangeWithLimit.vue').default,
		selectAll	  : require('@/components/Atom/Select/SelectAll').default,
		slideUpAndDown: require('@/components/Atom/SlideUpAndDown/SlideUpAndDown.vue').default,
		tableSimples  : require('@/components/Atom/Table/TableSimples').default,
		statusBar     : require('@/components/Atom/StatusInformation/StatusInformation.vue').default,
	},
	data() {
		return {
			buscandoInformacoes:false,
			buscandoFuncionarios:false,
			carregando:false,
			loadingExportar:[false, false, false],
			iconTitulo: mdiCalendarMonth,
			optionsSelectJornada: [],
			optionsSelectEmpresa: new EmpresasService().Get(),
			optionsSelectEmpregado: [],
			labelsSelectEmpresa:[
				{indexDFH: 'E', description: 'Empresas*'},
			],
			labelSelectJornada:[
				{indexDFH: 'JO', description: 'Jornada*'},
			],
			labelSelectEmpregado:[
				{indexDFH: 'MAD', description: 'Colaboradores'},
			],
			//controles
			clientesSelecionados:[],
			jornadasSelecionadas:[],
			funcionariosSelecionados:[],
			statusBar:'info',
			intervalo: '',
			tableData: [],
			totais:{},
		}
	},

	validations:{
		clientesSelecionados: { required },
		jornadasSelecionadas: { required }
	},
	methods: {

		arrumaFuckingPeriodo(){
			var reg = /\/\d\d\d\d/g
			return this.intervalo.replace(reg, '')
		},

		mudaIntervalo(intervalo){
			this.intervalo = intervalo
			this.tableData = []
		},

		/**
		 * @param {'pdf'|'csv'|'xls'} formato
		 */
		exportarRelatorio(formato){
			var url = `${urlBase}exportar/${formato}`
			var obj = {
				data: this.tableData,
				totais: this.totais,
				periodos: this.intervalo
			}
			this.mudaloadingexportar(formato)
			new HttpRequest().Post(url, obj).then((res)=>{
				var root =  process.env.VUE_APP_ROOT;
				window.open(root+'/'+res.data.local); 
				this.mudaloadingexportar('none')
			})
		},

		/**
		 * @param {'pdf'|'xls'|'csv'|'none'} tipo 
		 */
		mudaloadingexportar(tipo){
			this.loadingExportar = [
				tipo==='pdf',
				tipo==='xls',
				tipo==='csv'
			]
		},

		/**
		 * @param {number[]} arr clientes selecionados. 
		 * @description muda os clientes selecionados e já busca
		 * as informações de jornadas e funcionários para os 
		 * seletores.
		 * @author Gui 🧟
		 */
		mudaClienteSelecionado(arr){
			this.clientesSelecionados = arr
			this.jornadasSelecionadas = []
			this.funcionariosSelecionados = []
			this.tableData = []
			this.$refs.seletorJornada.clearAll()
			this.$refs.seletorEmpregado.clearAll()
			this.$v.jornadasSelecionadas.$reset()
			if(this.clientesSelecionados.length){
				this.buscandoInformacoes = true
				new FiltrosService()
					.dados_filtros(this.clientesSelecionados,['JOC'])
					.then((res)=>{
						this.optionsSelectJornada = res.JOC
					}).finally(()=> this.buscandoInformacoes = false)
			}
		},

		mudaJornadaSelecionada(arr){
			this.jornadasSelecionadas = arr
			this.$refs.seletorEmpregado.clearAll()
			this.tableData = []
			if(this.jornadasSelecionadas.length){
				this.buscandoFuncionarios = true
				var url = `${urlBase}funcionarios`
				var obj = {
					jornadas : this.jornadasSelecionadas
				}
				new HttpRequest().Post(url, obj).then((res)=>{
					this.optionsSelectEmpregado = res.data.funcionarios
					this.buscandoFuncionarios = false
				})
			}
		},

		mudaFuncionarioSelecionado(arr){
			this.funcionariosSelecionados = arr
			this.tableData = []
		},

		gerarRelatorio(){
			this.carregando = true
			this.tableData = []
			var url = `${urlBase}gerar`
			var obj = {
				clientes:this.clientesSelecionados,
				jornadas:this.jornadasSelecionadas,
				empregados: this.funcionariosSelecionados,
				intervalo:this.intervalo
			}
			new HttpRequest().Post(url, obj).then((res)=>{
				this.tableData = res.data.consulta
				this.totais = res.data.totais
				this.statusBar = 'error'
				setTimeout(()=>{
					this.statusBar = 'info'
				}, 6666)}).catch(()=>{
					conectionError()
				}).finally(()=>this.carregando = false)
		}
	},
	computed: {
		desabilitaSeletorJ(){
			if(this.clientesSelecionados.length)
				return false
			return true
		},
		desabilitaSeletorC(){
			if(this.jornadasSelecionadas.length)
				return false
			return true
		},
		mostrarTabela(){
			var arr = Object.values(this.tableData)
			return !!arr.length 
		},
		tableDataShow(){
			return _.sortBy(this.tableData, ['mtnome'])
		}
	},
}
</script>
<style lang="scss" scoped>
.titulo-resumo-jornada{
	background-color: gray;
	color: white;
	font-size: 14px;
	font-weight: bolder;
	text-align: center;
}
.totalizadores{
	background-color: #cac7c7 !important;
}
</style>