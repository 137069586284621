<template>
    <panelEagle id='RegistrarAbastecimento' :loading='loadingPanel'>
        <div class="col-sm-12 nopadding row">
            <div class="col-sm-6">
                <tituloPage 
                    :icon='mdiGasStation' 
                    titulo='Registrar abastecimento'>
                </tituloPage>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                <buttonsCadastrosSC
                    :inputDisabled='desabilitarSalvar'
                    tipoCancela='button'
                    linkCancel ='registrarFuel'
                    :disabled='$v.$invalid || !inputValorTt'
                    @salvarCadastro='salvarCadastroRegistro'>
                </buttonsCadastrosSC>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class='col-sm-12 nopadding row'>
            <div class="col-sm-3 nopadding">
                <selectAll
				:isMultiple ='false'
				:selected     ='clienteSelecionado'
				:value        ='valueEmpresa' 
				nameForRadio  ='Empresa*'
				:optionsArray ='optEmpresas'
				:labels       ='labelsEmp'
				:extraClass='{"border border-danger":$v.valueEmpresa.$anyError}'
				@close='$v.valueEmpresa.$touch()'
				@changeSelect ='changeEmpresa'/>
            </div>
            <div class="col-sm-3 nopadding">
                <selectAll
				ref='veiculo'
				:selected     ='veiculoSelecionado'
				:value        ='valueVeiculo'
				:isMultiple   ='false'
				nameForRadio  ='Veiculo*'
				:optionsArray ='optVeiculo'
				:labels       ='labelsVei'
				:loading='procurandoOpts'
				:disabled='!optVeiculo.length'
				:extraClass="{'border border-danger':$v.valueVeiculo.$anyError}"
				@close='$v.valueVeiculo.$touch()'
				@changeSelect ='changeVeiculo'/>
            </div>
            <div class="col-sm-3 nopadding">
                <selectAll
                    ref='colaborador'
                    :selected     ='colaboradorSelecionado'
                    :value        ='valueColaborador'  
                    nameForRadio  ='Colaborador*'
                    :isMultiple   ='false'
                    :optionsArray ='optColaborador'
                    :labels       ='labelsCol'
                    :loading='procurandoOpts'
                    :hasSelectAll ='false'
                    :disabled='!optColaborador.length'
                    :extraClass='{"border border-danger":$v.valueColaborador.$anyError}'
                    @close='$v.valueColaborador.$touch()'
                    @changeSelect ='changeColaborador'/>
            </div>
            <div class="col-sm-3 nopadding">
                <selectAll
                    ref='combustivel'
                    :isMultiple   ='false'
                    :optionsArray ='optTipo'
                    :disabled='!optTipo.length'
                    :selected     ='combustivelSelecionado'
                    :value        ='valueCombustivel'
                    :loading='procurandoOpts'
                    nameForRadio  ='Tipo de combustível*'
                    :labels       ='labelsTip'
                    :extraClass='{"border border-danger":$v.valueCombustivel.$anyError}'
                    @close='$v.valueCombustivel.$touch()'
                    @changeSelect ='changeTipo'/>
            </div>
        </div>
        <br>
        <div class='col-sm-12 nopadding row'>
            <div class="col-sm-3 nopadding">
                <datePicker
                    id='inputSingleDay'
                    name = 'inputSingleDay'
                    :label ='data'
                    :value ='valueData'
                    :isObrigatorio='true'
                    @changeInput='changeDia'/>
            </div>
            <div class="col-sm-3 nopadding">
                <inputSimple
                    :hasMask ='true'
                    :mask ="['hN:MN:MN']"
                    id='inputSimpleTime'
                    name    ='inputSimpleTime'
                    :value ='hora'
                    @changeInput='changeHora'
                    :label ='horario' />
            </div>
            <div class="col-sm-3 nopadding">
                <inputSimple
                    :hasMask ='true'
                    :mask ="['NNNNNNNNNNNNNNNNNNNNNNNNNN']"
                    id='inputSimpleHod'
                    name = 'inputSimpleHod'
                    :value ="valueHodVelho"
                    :label ="baseCalculoMedia == 2 ? 'Horímetro antigo' : 'Hodômetro antigo'"
                    inputDisabled ='disabled'
                    :loading='loadingHodometro'/>
            </div>
            <div class="col-sm-3 nopadding">
                <inputSimple
                    @changeInput='inputHodoNov'
                    type="number"
                    :value ='valueHodNovo'
                    id     ="popoverAtualizar"
                    name = 'inputSimpleHodN'
                    :label ="baseCalculoMedia == 2 ? 'Horímetro*' : 'Hodômetro*'"/>
            </div>
        </div>
        <br>
        <div class='col-sm-12 nopadding row'>
            <div class="col-sm-3 nopadding">
                <inputMoney
                    @changeInput='inputPrel'
                    :value='inputPrell'
                    type="number"
                    id='inputMoneyPreL'
                    name = 'inputMoneyPreL'
                    :label ='precoLitro'/>
            </div> 
            <div class="col-sm-3 nopadding">
                <inputSimple
                    @changeInput='inputPreQtl'
                    :value='inputPreQtll'
                    id='inputMoneyQTL'
                    name = 'inputMoneyQTL'
                    :inputClass='{"border border-danger": $v.inputPreQtll.$anyError}'
                    @blur='$v.inputPreQtll.$touch()'
                    :label ='quantidadeLT'/>
            </div>
            <div class="col-sm-3 nopadding">
                <inputMoney
                    @changeInput='inputValorT'
                    :value='inputValorTt'
                    type="number"
                    :id='valorT'
                    name = 'valorT'
                    :label ='valorT'/>
            </div>
            <div class="col-sm-3 nopadding">
                <inputSimple
                    disabled
                    :value='mediaKm'
                    :id='valorT'
                    name = 'valorT'
                    :label ='labelMedia'/>
            </div>
        </div>
        <br>
        <div class='col-sm-12 nopadding row'>
            <div class="col-sm-5 nopadding position-relative">
                <inputSearch
                    id ='inputSearch'
                    ref= 'inputProcuraCampo'
                    :inputDisabled  ="!valueEmpresa.length ? 'disabled' : ''"
                    :buttonDisabled ="valueEmpresa.length ? true : false"
                    @changeInput ='changeInputProcura'
                    @clickButtonSearch ='inputProcura'
                    nameForRadio ='BuscarLocal'
                    :label       ='labelBucL'
                    :value='valueProcuraLocal'
                    name         ='buscaLocal'/>
                    <template v-if='condIFPonto'>
                        <b-list-group class="position-absolute"   >
                            <template v-for="(dados, index) in optPonto">
                                <b-list-group-item
                                    @click="changePonto(dados.pocodigo, dados.endereco, dados.centro)"
                                    :key="index" 
                                    button 
                                    class="item">
                                        {{dados.endereco}}
                                </b-list-group-item>
                            </template>
                        </b-list-group>
                    </template>
                <div v-if='condIFAviso' class="col-row-3 nopadding lado">
                    <span class="aviso col-lg-3 nopadding espaco">{{aviso}}</span>
                </div>
                <div v-if='condIFSpin' class="divSpin">
                    <b-button variant="warning">
                        <b-spinner small type="grow"></b-spinner>
                        <span class="spanSpin">Carregando, aguarde</span>
                    </b-button>
                </div>
            </div>
            <div class="col-sm-7 nopadding">
                <inputSimple
                    :value       ='valueObs'
                    nameForRadio ='Observações'
                    :label       ='labelsObs'
                    name         = 'obs'
                    @changeInput ='changeObs'/>
            </div>
            <requiredF/>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div v-if='condIF' class="col-sm-2 row corFundo nopadding">
            <p class="letra nopadding">Este ponto ainda não está cadastrado</p>
            <inputSimple
                class        ="espaco nopadding"
                :value       ='valueDescricaoSalvarPonto'
                nameForRadio ='valueProcura'
                label        ='Descrição*'
                name         ='valueProcura'
                @changeInput  ='changeSalvarPonto'/>
            <selectAll
                class="espaco nopadding"
                :selected     ="[{value: 'P', description: 'Ponto de referencia'}]"
                :optionsArray ='optMapa'
                :isMultiple     ='false'
                nameForRadio  ='a'
                :value ='valueSelectPonto'
                :labels       ='labelTipoPonto'/>
            <p class="raio">Raio (max 50 Mts) </p>
            <custom-slider class="slide" min="0" max="50" step="1" @change="changeSlider" :value="String(valueSlider)"/>
            <!-- v-model="valueSlider" -->
			<div class="col-sm-12 row nopadding" align="right">
				<div class="col-sm-6 pl-0" align="right">
					<simpleButton
						:disabled ="!valueDescricaoSalvarPonto.length"
						class="pl-1"
						text="Salvar" 
						:icon="mdiCheckBold" 
						type="blue"
						id="btnSalvado" 
						event='click'
						width="117%"
						@click="salvarCadastroPonto">
					</simpleButton>
				</div>
				<div class="col-sm-6 pl-0" align="right">
					<simpleButton
						class="pl-1"
						text="Cancelar" 
						:icon="mdiCloseThick" 
						type="red"
						id="btnCacelado" 
						event='click'
						width="117%"
						@click="cancelarCadastroPonto">
					</simpleButton>
				</div>
			</div>
        </div>
        <div :class="condIF ? 'col-sm-10' : 'col-sm-12'">
            <mapaSimples height="400" class=""
                ref='mapaEndereco'>
                <markerPoint
                    icon="img/matriz.png"
                    :isDraggable="false"/>
                <markerPoint
                    :latitude="latMarker"
                    :longitude="longMarker"
                    :isDraggable="false">
                </markerPoint>
				<circleSimple
					:center="[latMarker, longMarker]"
					:radius="String(valueSlider)"/>
            </mapaSimples>
        </div>
		<b-popover
			v-if="!can"
			target="popoverAtualizar"
			:show.sync="popover"
			placement="bottom"
			ref="popover">
			<div class="col-sm-12 nopadding"
				v-show="!can">
				Deseja atualizar o {{baseCalculoMedia == 2 ? 'horímetro' : 'hodômetro'}} 
					do veículo com base nessa informação?
				<div class="col-sm-12 row mt-2 mb-2">
					<div class="col-sm-6 nopadding">
						<simpleButton
							type="red"
							event="click"
							text="Não"
							@click="popover = false"/>
					</div>
					<div class="col-sm-6 nopadding"
						align="right">
						<simpleButton
							type="blue"
							event="click"
							text="Sim"
							@click="atualizaDados()"/>
					</div>
				</div>
			</div>
		</b-popover>
    </panelEagle>
</template>
<script>
import Vue  from 'vue'
import CustomSlider from 'vue-custom-range-slider'
import { required, decimal } from 'vuelidate/lib/validators'
import datePicker from '@/components/Atom/Datas/InputSingleDay'
import 'vue-custom-range-slider/dist/vue-custom-range-slider.css'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { conectionError } from  '@/Services/Helpers/swellHeper.js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import {mdiGasStation, mdiCloseThick, mdiCheckBold} from '@mdi/js'
import { DateTime } from 'luxon'
export default Vue.extend({
	name: 'registrarCadastro',
	components:{
		datePicker,
		CustomSlider,
		tituloPage         : require('@/components/Atom/Header/Titulo').default,
		// simpleMapa         : require('@/components/Atom/Mapa/SimpleMapa').default,
		selectAll          : require('@/components/Atom/Select/SelectAll').default,
		panelEagle         : require('@/components/Atom/Panel/PanelEagle').default,
		inputMoney         : require('@/components/Atom/Inputs/InputMoney').default,
		inputSimple        : require('@/components/Atom/Inputs/InputSimple').default,
		inputSearch        : require('@/components/Atom/Inputs/InputSearch').default,
		simpleButton       : require('@/components/Atom/Buttons/SimpleButton').default,
		requiredF          : require('@/components/Atom/Footer/RequiredFields').default,
		buttonsCadastrosSC : require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
		mapaSimples        : require('@/components/Atom/Mapa/MapaSimples').default,
		markerPoint        : require('@/components/Atom/Mapa/MarkerPoint').default,
		circleSimple       : require('@/components/Atom/Mapa/CircleSimple').default,
		// 'inputSingleDay'     : require('@/components/Atom/Datas/InputSingleDay').default,
	},
	data(){
		return {
			mdiCheckBold:mdiCheckBold,
			mdiCloseThick:mdiCloseThick,
			mdiGasStation:mdiGasStation,
			optEmpresas     : new EmpresasService().Get(),
			condIF          : false,
			condIFPonto     : false,
			valueSelectPonto: [] ,
			clienteSelecionado     : [] ,
			slider                 : '0',
			valueSlider            : '0',
			valueEmpresa           : '',
			veiculoSelecionado     : [],
			valueVeiculo           : '',
			colaboradorSelecionado : [],
			valueColaborador       : '',
			combustivelSelecionado : [],
			valueCombustivel       : '',
			inputPrell     : '',
			inputPreQtll   : '',
			inputValorTt   : '',
			valueDescricaoSalvarPonto : '',
			hora           : '', 
			optVeiculo     : [],
			optColaborador : [],
			optTipo        : [],
			optMapa        : [
				{value: 'C', description: 'Ponto de coleta'},
				{value: 'E', description: 'Ponto de entrega'},
				{value: 'P', description: 'Ponto de referencia'}
			],
			optPonto       : [{value: 'A', description: 'Sem pontos'}] ,
			labelsPonto    : [{
				indexDFH    : 'PO',
				description : 'Há pontos cadastrados com esta descrição, selecione abaixo:'
			}],
			labelTipoPonto   :[{
				indexDFH    : 'AA',
				description : 'Tipo do ponto*'
			}],
			labelsEmp   :[{
				indexDFH    : 'EM',
				description : 'Empresa*'
			}],
			labelsVei   :[{
				indexDFH    : 'VE',
				description : 'Veiculo*'
			}],
			labelsCol   :[{
				indexDFH    : 'CO',
				description : 'Colaborador*'
			}],
			labelsTip   :[{
				indexDFH    : 'TC',
				description : 'Tipo de combustível*'
			}],
			horario       : 'Horário',
			hodometro     : 'Hodômetro*',
			hodometroAnt  : 'Hodômetro antigo',
			precoLitro    : 'Preço do litro*',
			quantidadeLT  : 'Quantidade de Litros*',
			valorT        : 'Valor total*',
			labelsObs     : 'Observações',
			labelBucL     : 'Buscar local*',
			data          : 'Data*',
			hodoValor     : '' ,
			lat           : -27.100206,
			long          : -52.627104,
			latMarker     : -27.100206,
			longMarker    : -52.627104,
			valueObs      : '',
			valueData     : '',
			valueHodVelho : '',
			valueHodNovo  : '',
			valueProcura  : '',
			valueMetros   : '',
			valuePonto    : '',
			aviso         : '',
			condIFAviso   : false,
			condIFSpin    : false,
			disabled      : 'disabled',
			desabilitaBP  : 'disabled',
			valueProcuraLocal   : '' ,
			desabilitarSalvar   : 'disabled',
			inputProcuraCampo   : '',
			veiculo             : '',
			colaborador         : '',
			combustivel         : '',
			enderecoSalvarPonto : '',
			idUser              : 0,
			idSoma              : 1,
			procurandoOpts      : false,
			uriBase             : '/abastecimento/menu/registrar/',
			loadingPanel        : false,
			tipoCombustivel     : '',
			loadingHodometro    : false,
			mediaKm             : '',
			baseCalculoMedia    : '',
			labelMedia          : '',
			popover             : false,
			can                 : false,
		}
	},
	validations:{
		valuePonto        : { required },
		valueEmpresa      : { required },
		valueVeiculo      : { required },
		valueColaborador  : { required },
		valueCombustivel  : { required },
		valueProcuraLocal : { required },
		inputPreQtll      : { required, decimal },
		valueHodNovo      : { required },
	},
	methods:{
		changeSlider(value){
			this.valueSlider = value
		},
        
		changeInputProcura(value){
			this.valueProcuraLocal = value
		},

		changeSalvarPonto(value){
			this.valueDescricaoSalvarPonto = value
		},

		async changePonto(value, descricao, dados){
			if(value == undefined){
				this.condIF = true
			} else {
				this.condIF = false
				this.valueProcuraLocal = descricao
				this.valuePonto = [value]
			}
			this.$refs.mapaEndereco.flyTo(
				[dados[1],dados[0]]
			)
			this.latMarker = dados[1]
			this.longMarker = dados[0]
			this.enderecoSalvarPonto = descricao
			this.condIFPonto = false
		},

		changeHora(value){
			var hm = value.split(':')
			if(this.valueCombustivel.length > 0 && value.length == 8)
				this.buscaHodometro(this.valueCombustivel)
			if(hm[0]>=24){
				this.hora = '23:59:59'
			}
			else if(hm[0]<=23){
				this.hora = value
			}
		},

		changeObs(value){
			this.valueObs = value
		},

		cancelarCadastroPonto(){
			this.disabled = ''
			this.condIF = !this.condIF
		},

		async salvarCadastroPonto(){
			this.loadingPanel = true
			if(this.valueSelectPonto == ''){
				this.valueSelectPonto = ['P']
			}
			this.valueProcura = []
			var obj = {
				'podescricao'     : this.valueDescricaoSalvarPonto,
				'poendereco'      : this.enderecoSalvarPonto,
				'latitude'        : this.latMarker,
				'longitude'       : this.longMarker,
				'potipo'          : this.valueSelectPonto,
				'pocodigocliente' : this.valueEmpresa,
				'poraio'          : this.valueSlider,
			}
			var dados = await new HttpRequest()
				.Post('/abastecimento/menu/registrar/salvarPonto', {'obj':obj})
			this.inputProcura(this.valueProcuraLocal, 'novo')
			this.loadingPanel = false
            
		},
		changeMetros(value){
			var soma = parseFloat(value)
			if(soma <= 50){
				this.valueMetros = value
			}
			else if(soma >50){
				this.valueMetros = '50'
			}
		},
        
		centralizaMapa(latlng){
			this.$refs.mapaSimples.center = (latlng)
		},

		async inputProcura(value, local){
			if(local != 'novo')
				this.valueDescricaoSalvarPonto = value
			this.condIFSpin = !this.condIFSpin
			this.condIF =  false
			this.condIFPonto =  false
			this.disabled = ''
			if(value){
				var dados = await new HttpRequest()
					.Post('/abastecimento/menu/registrar/mapa', {'valor':value, 'cliente':this.valueEmpresa})
					this.disabled    = ''
					this.condIFPonto = dados.data.condicaoPonto
					this.optPonto    = dados.data.pontos
					
				}
			this.condIFSpin  = false
			this.condIFAviso = false
		},
		inputPrel(value){
			this.inputPrell = value
			var valorD = this.inputPreQtll
			var soma = valorD*value
			this.inputValorT(soma)
		},
		inputPreQtl(value){
			var valor = value.replace(',', '.')
			this.inputPreQtll = valor
			var valorD = this.inputPrell
			var soma = valorD*valor
			this.inputValorT(soma)
			this.calculaMedia(this.valueHodNovo, this.valueHodVelho, this.inputPreQtll)
		},
		inputValorT(value){
			this.inputValorTt = value
		},
		inputHodoNov(value){
			this.valueHodNovo = value
			if(!this.can){
				this.popover = true
			}
			this.calculaMedia(this.valueHodNovo, this.valueHodVelho, this.inputPreQtll)
		},

		salvarCadastroRegistro(){
			this.loadingPanel = true
			var obj = this.criaObjSalvar()
			let uri = this.uriBase+'salvarDados'
			new HttpRequest()
				.Post(uri, {'obj':obj}).then(data=>{
					if(data.code == 200){
						this.$router.push({name:'registrarFuel'})
						this.loadingPanel = false
					}else{
						conectionError()
						this.loadingPanel = false
					}
				})
		},

		/**
		 * Cria o objeto que é enviado para o back-end
		 * Já deleta a propriedade id caso não for edição
		 */
		criaObjSalvar(){
			let obj = {
				'abcliente'         : this.valueEmpresa[0],     // empresa
				'abveiculo'         : this.valueVeiculo[0],     // veiculo
				'abmotorista'       : this.valueColaborador[0], // motorista
				'abtipocombustivel' : this.valueCombustivel[0], // tipo do combustivel
				'abdata'            : this.valueData,           // data
				'abhora'            : this.hora,                // hora 
				'abhodometroVelho'  : this.valueHodVelho,       // hodometro antigo
				'abhodometro'       : this.valueHodNovo,        // hodometro novo
				'abprecolitro'      : this.inputPrell,          // preco do litro
				'abquantidade'      : this.inputPreQtll,        // quantidade de litros
				'abvalortotal'      : this.inputValorTt,        // valor total
				'ablocal'           : this.valuePonto[0],       // local selecionado
				'abobservacoes'     : this.valueObs,            // obs
				'id'                : this.idUser,              // id
				'abmedia'           : this.mediaKm,
			}
			if(this.$route.params.id === undefined) delete obj.id
			return obj
		},

		/**
		 * Quando o usuário muda a empresa selecionada
		 * essa função limpa os seletores (veiculo, colaborador e tipo combustivel)
		 * para garantir que o usuário só consegue selecionar dados que sejam referentes a empresa
		 * @param value array de empresas
		 * @return void 
		 * @author Gui 🍺
		 */
		changeEmpresa(value){
			this.valueEmpresa = value
			this.$refs.colaborador.clearAll()
			this.$refs.combustivel.clearAll()
			this.$refs.veiculo.clearAll()
			this.$v.$reset()
			if(value.length){
				this.procurandoOpts = true
				new FiltrosService().dados_filtros(value, ['V', 'C', 'CT'])
					.then(data=>{
						this.optVeiculo = data.V
						this.optColaborador = data.C
						this.optTipo = data.CT
						this.procurandoOpts = false
					})
			}
		},

		selectPontosSelect(value){
			this.valueSelectPonto = value
		},
 
		changeDia(value){
			this.valueData = value
			if(this.valueCombustivel.length > 0){
				this.buscaHodometro(this.valueCombustivel, true)
			}
		},

		changeVeiculo(value){
			this.valueVeiculo = value
			if(this.valueCombustivel.length > 0){
				this.buscaHodometro(this.valueCombustivel)
			}
			if(value != ''){
				this.buscaTipoVeiculo(value)
			}
		},

		changeColaborador(value){
			this.valueColaborador = value
		},

        
		changeTipo(value){
			this.valueCombustivel = value
			if(value.length > 0){
				this.buscaHodometro(value)
			}
		},

		/**
		 * @description Função para buscar os hodometros no banco (hodometro da data e o antigo)
		 *  É verificado se esta sendo feita em uma edição ou não pela variável userId, e
		 *  se esta sendo alterado pela data (caso sim hodometro atual não deve ser alterado)
		 * @param {array}   value        - tipo de combustível
		 * @param {Boolean} data         - para saber se a requisição vai ser feita a partir da alteração de data
		 * @param {String}  hora         - horaio do abastecimento
		 * @param {String}  valueData    - data do abastecimento
		 * @param {String}  valueVeiculo - código do veiculo do abastecimento
		 * @return valueHodNovo          - hodometro atual (seguindo a data da edição ou cadastro)
		 * @return valueHodVelho         - hodometro anterior ao atual 
		 * @author Vitor Hugo 🐨
		 */
		async buscaHodometro(value, data = false){
			this.loadingHodometro = true
			var date = DateTime.fromFormat(this.valueData, 'dd/MM/yyyy').toFormat('yyyy-MM-dd')
			var dados = await new HttpRequest()
				.Post('/abastecimento/menu/registrar/hodometro', 
					{'abveiculo':this.valueVeiculo[0], 'abtipocombustivel':value[0], 'data':date, 'hora':this.hora,'codigo':this.idUser})
			if(dados.data.hodometroAntigo.length > 0){
					this.valueHodVelho = dados.data.hodometroAntigo[0]?.abhodometro
					this.calculaMedia(this.valueHodNovo, this.valueHodVelho, this.inputPreQtll)
			} else {
				this.valueHodVelho = ''
			}
			this.loadingHodometro = false
		},

		/**
		 * @description Função para calcular a media de quilometragem do veículo
		 * @param {string} valueHodNovo  - hodômetro atual
		 * @param {string} valueHodVelho - hodômetro anterior
		 * @param {string} inputPreQtll  - quantidade de litros
		 * @return string mediaKm      - media do veículo
		 * @author Vitor Hugo 🐨
		 */
		calculaMedia(valueHodNovo, valueHodVelho, inputPreQtll){
			if(this.baseCalculoMedia == 2){
				if(valueHodVelho == '' || valueHodNovo == '' || inputPreQtll == '' || valueHodVelho == undefined){
					this.mediaKm = ''
				} else {
					this.mediaKm = Math.abs((inputPreQtll/(valueHodNovo - valueHodVelho)).toFixed(3))
					this.labelMedia = 'Média l/Hr'
				}
			} else {
				this.labelMedia = 'Média Km/l'
				if(valueHodVelho == '' || valueHodNovo == '' || inputPreQtll == '' || valueHodVelho == undefined){
					this.mediaKm = ''
				} else {
					this.mediaKm = Math.abs(((valueHodNovo - valueHodVelho)/ inputPreQtll).toFixed(3))
				}
			}
		},

		/**
		 * @description Função para para buscar o tipo da base de calculo da media
		 * @param {number} codigo  - codigo do veículo
		 * @return number baseCalculoMedia - qual vai ser a base de calculo da media 
		 *  (1 hodômetro / 2 - Horímetro)
		 * @author Vitor Hugo 🐨
		 */
		buscaTipoVeiculo(codigo){
			let uri = this.uriBase+'base/calculo'
			new HttpRequest()
				.Post(uri, {'codigo':codigo[0]}).then(dados=>{
					this.baseCalculoMedia = dados.data.tipoVeiculo[0].vebasecalculomedia
					this.can = dados.data.can.vpican
				})
		},

		limpaCampo(){
			if(this.idSoma != 1){ 
				this.valueVeiculo = ''
				this.veiculoSelecionado = []
				this.valueColaborador = ''
				this.colaboradorSelecionado = []
				this.valueCombustivel = ''
				this.combustivelSelecionado = []

				this.$refs.veiculo.inputValue = ''
				this.$refs.colaborador.inputValue = ''
				this.$refs.combustivel.inputValue = ''
			}else{
				this.idSoma = 0
			}
		},

		/**
         * @listens click - emitido quando clicado no botão de sim do popover
		 * @description Função para alterar o valor do horimetro 
         *  ou hodometro na tabela veiculos_contadores
		 * @author Vitor Hugo 🐨
		 */
		atualizaDados(){
			var obj = {
				'codigo'      : this.valueVeiculo[0],
				'hodometro'   : Number(this.valueHodNovo),
				'baseCalculo' : this.baseCalculoMedia,
			}
			new HttpRequest()
				.Post(this.uriBase+'atualiza/hodometro', obj).then(dados=>{
					if(dados.code == 201){
						this.popover = false
					} else {
						this.toastShow('Entre em contato com o suporte',
							'danger',
							'Erro ao salvar dados')
					}
				})
		},

		/**
         * @description Método para mostrar o toast na tela
         * @param {string} msg   - mensagem que vai aparecer no corpo do toast
         * @param {string} type  - qual o tipo do toast 
         * @param {string} title - título para inserir no cabeçalho
         * @author Vitor Hugo 🐨
        */
		toastShow(msg, type, title = ''){
			this.$bvToast.toast(`${msg}`, {
				autoHideDelay: 3000,
				variant: type,
				title: title
			})
		},
		
	},

	async mounted(){
		this.inputProcuraCampo = this.$refs.inputProcuraCampo.inputValue
		var id = this.$route.params.id
		if(id >0){
			this.idUser = id
		}
		if(id  !== undefined){
			this.loadingPanel = true
			var respostaID = await new HttpRequest()
				.Post('/abastecimento/menu/registrar/dados', {'id':id})
			this.valuePonto = [respostaID.data.dados.ablocal]
			this.clienteSelecionado     = [respostaID.data.cliente]
			this.veiculoSelecionado     = [respostaID.data.veiculo]
			this.colaboradorSelecionado = [respostaID.data.colaborador]
			this.combustivelSelecionado = respostaID.data.combustivel == 0 ? [] : [respostaID.data.combustivel]
			this.inputPrell     = respostaID.data.dados['abprecolitro']
			this.inputPreQtll   = respostaID.data.dados['abquantidade']
			this.valueObs       = respostaID.data.dados['abobservacoes']
			this.valueData      = respostaID.data.data
			this.valueHodNovo   = respostaID.data.dados['abhodometro'] // está em km (não sei pq)
			this.hora           = respostaID.data.dados['abhora']
			this.valueProcuraLocal   = respostaID.data.ponto[0]['podescricao']
			this.changeInputProcura(respostaID.data.ponto[0]['podescricao'])
			this.latMarker      = parseFloat(respostaID.data.ponto[0]['polatitude'])
			this.longMarker     = parseFloat(respostaID.data.ponto[0]['polongitude'])
			this.valueSlider    = respostaID.data.ponto[0].poraio
			this.$refs.mapaEndereco.flyTo(
				[this.latMarker,this.longMarker]
			)
			this.loadingPanel = false
		}
		else if(id == undefined){
			this.loadingPanel = true
			var dados = [];
			dados = await new HttpRequest()
				.Post('/abastecimento/menu/registrar/data')
			this.hora = dados.data
			this.loadingPanel = false
		}
	},
	watch:{
		// slider:function(){
		//     this.valueSlider = parseFloat(this.slider)
		// },
		valueEmpresa(){
			if(this.idUser == 0){
				this.valueVeiculo = ''
				this.veiculoSelecionado = []
				this.valueColaborador = ''
				this.colaboradorSelecionado = []
				this.valueCombustivel = ''
				this.combustivelSelecionado = []
				this.$refs.veiculo.inputValue = ''
				this.$refs.colaborador.inputValue = ''
				this.$refs.combustivel.inputValue = ''
			}
		}
	}
})
/////////////////////////// ANOTAÇÃO //////////////////////////////////////////////////////////////////
//                                                                                                   //
// documentação do vue para mapas                                                                    //
// https://br.vuejs.org/v2/cookbook/practical-use-of-scoped-slots.html                               //
//                                                                                                   //
// exemplo do botao slide                                                                            //
// https://codesandbox.io/embed/vue-template-glwlb                                                   //
// documentaçao                                                                                      //
// https://vuejsexamples.com/a-custom-range-slider-for-vue-that-builds-on-the-native-range-slider/   //
//                                                                                                   //
///////////////////////////////////////////////////////////////////////////////////////////////////////
</script>
<style lang="scss" scoped>
    .position-relative{
        position: relative;
        .position-absolute{
            transform: translate(2.5%);
            border-radius: 0px;
            text-align: left;
            overflow: auto;
            max-height:200px;
            width: 95%;
            z-index: 900;
            position: absolute;
            .item{
                &:hover{
                    background-color:#2196f3 !important;
                    color:#fff !important;
                }
            }
        }
    }
    .divSpin{
        margin-top: 5px;
        margin-right: 59%;
        margin-left: 15px
    }
    .spanSpin{
        padding: 10px;
        margin-top: 10px!important;
    }
    .lado{
        margin-top: 5px;
        float: left;
    }
    .ladoMarg{
        margin-left: 11px;
    }
    .aviso{
        color: rgb(250, 0, 0);

    }
    .letra{
        color: brown;
        font-size: 13px;
        padding-left: 0px;
        margin-bottom: 0px;
    }
    .corFundo{
        float: right;
        display:-moz-popup;
        margin-bottom: 0px;
        margin-right: 0px !important;
        background-color: #fffffe;
        box-sizing: border-box;
        box-shadow: 0.1px 0.5px 0.5px rgb(24, 23, 23);
    }
    .espaco{
        margin-top: 7px;
    }
    .slide{
        padding: 0%;
        border: 10px;
        margin: auto;
        width: 90%;
    }
    .raio{
        margin-bottom: 13px;
        margin-left: 15px;
        font-size: 13px;
    }
    .espaco2{
        margin-bottom: 20px;
    }
</style>
