<template>
        <!-- @confirmButton="salvarJustificativaRota"  -->
    <modalEagle 
        @modalOpen="pegarInformacoesRota" 
        title="Encerrar Rota" 
        :id="'encerrarRotaModal'+rota"
        :arrayJustificarTodos="arrayJustificativasRota">

        <slot slot="modal-header">
				<h5 class="modal-title">Encerrar Rota</h5>
				<simpleButton id="popoverJustificarTodos" class="bntJustificativa" :text='textoJustificarTodos' :width="'350 px'" event="click"
					type="blue"></simpleButton>
				<button type="button" aria-label="Close" class="close" event="click" @click="cancelButton">×</button>
                <b-popover
                    target="popoverJustificarTodos"
                    triggers="hover click"
					placement="bottomleft"
					custom-class="popoverJustificativa"
                    ref="popover">
                    <div class="col-sm-12 nopadding row">
                        <div class="col-sm-12 nopadding">
							<div class="textoJustificativas">Selecione uma justificativa para todos os itens pendentes</div>
							<selectAll @changeSelect="justificarTodos" 
								:isMultiple="false" 
								nameForRadio="justificarTodos" 
								:hasSelectAll="false" 
								:optionsArray="arrayJustificativasRota">
							</selectAll>
                        </div>
                    </div>
                </b-popover> 
        </slot>

        <slot slot="modalBody">
            <panelEagle id="modalEncerrarRota" :loading="isLoading">
                <div class="corpo-modal">
                    <div class="col-sm-12 nopadding">
                        <alertSimple v-if="needShowAlert" :type="statusAlert" :isLoading="isLoading" :message="messageAlert" :hasClick="false"></alertSimple>
                    </div>
                    <statusInformation 
                        typeBar="div" 
                        v-if="statusInformation" 
                        :statusBar="statusbar">
                    </statusInformation>
                    <div class="col-sm-12 justpaddingbottom" 
                        :key="item.ircodigo" 
                        v-for="item in arrayDadosRota">
                        <justificativasModalEncerrarRota 
                            @changeSelectJustificativaEncerrarRota="v => changeJustificativaRota(v, item)" 
                            :arrayDados="item" 
                            :arrayJustificativas="arrayJustificativasRota"
                            :justificativaSelecionada="justificativasSelected">
                        </justificativasModalEncerrarRota>
                    </div>
                </div>
            </panelEagle>
        </slot>

        <slot slot="modalFooter">
            <simpleButton 
                :icon="mdiCloseThick" 
                @click="cancelButton" 
                text="Cancelar" 
                event="click" 
                type="red">
            </simpleButton>
            <simpleButton
                :disabled="arrayDadosRota.length == arrayPontosJustificados.length ? false : true"
                :icon="mdiCheckBold" 
                @click="salvarJustificativaRota" 
                text="Confirmar" 
                event="click" 
                type="blue">
            </simpleButton>
        </slot>
    </modalEagle>
</template>
<script lang="js">
import {mdiCloseThick, mdiCheckBold} from '@mdi/js'
import Vue from 'vue'
import {HttpRequest} from '../../../Services/auth/HttpRequest.Service'
import {EmpresasService} from '../../../Services/auth/Empresas.service'
export default Vue.extend({
    name:'ModalEncerrarRota',
    components:{
        'selectAll': require('@/components/Atom/Select/SelectAll').default,
        'modalEagle': require('../Modal/ModalEagle').default,
        'alertSimple': require('../Alerts/AlertSimple').default,
        'panelEagle': require('@/components/Atom/Panel/PanelEagle.vue').default,
        'simpleButton': require('@/components/Atom/Buttons/SimpleButton').default,
        'statusInformation': require('../StatusInformation/StatusInformation').default,
        'justificativasModalEncerrarRota': require('../SpecificComponents/MonitoramentoRotas/JustificativasModalEncerrarRota').default,
    },
    data(){
        return{
            mdiCheckBold:mdiCheckBold,
            mdiCloseThick:mdiCloseThick,
            loadingPanel:false,
            arrayDadosRota: [],
            arrayJustificativasRota: [],
            arrayPontosJustificados: [],
            needShowAlert: false,
            isLoading: false,
            statusbar: 'info',
            statusAlert: 'blue',
            messageAlert: '',
            tamanho: 0,
            statusInformation: false,
            justificativasSelected: [],
            textoJustificarTodos: 'Justificar Todos',
        }
    },    
    props:{
        rota:{
            type: [String, Number],
            required: true,
            default: '',
        },
        cliente:{
            type: [String, Number],
            required: true,
            default: '',
        }
    },
    methods:{

        /**
         * Metodo para inserir os valores dos selects na arrayPontosJustificados (É feito verificações para 
         * validação do botão confirmar, se a justificativa foi selecionada irá ver se já existe na array caso sim
         * altera a justificativa na posição do ponto se não só insere uma nova, já se a justificativa foi 
         * desselecionada irá pegar e excluir o objeto na posição daquele ponto)
         * @param Objetos
         *  justificativa = justificativa selecionada
         *  ponto = ponto onde esta sendo selecionada a justificativa
         * @return Array com os dados da rota alterados (arrayDados)
         * @author Vitor Hugo 🐨
         */
        changeJustificativaRota(justificativa, ponto){
            var posicao = this.arrayPontosJustificados.findIndex((element) => {
                return element.ponto.ircodigo == ponto.ircodigo
            })
            if(justificativa != undefined){
                if(posicao == -1){
                    this.arrayPontosJustificados.push({'justificativa': justificativa, 'ponto': ponto})
                } else {
                    this.arrayPontosJustificados[posicao] = {'justificativa': justificativa, 'ponto': ponto}
                }
            } else {
                this.arrayPontosJustificados.splice(posicao, 1)
            }
        },
        async pegarInformacoesRota(){
            this.statusInformation = false;
            if(this.cliente != null && this.rota != 0){
                this.isLoading= true;
                var data = await new HttpRequest()
                    .Post('/roteirizador/monitoramento/justificativas/modal/dados/pegar', 
                        {
                            'cliente': this.cliente, 
                            'rota':this.rota
                        })
                this.tamanho = data.data.tamanho;
                this.isLoading= false;
                this.arrayPontosJustificados = []
                if(data.data.itens.length > 0){
                    this.arrayDadosRota = data.data.itens;
                    this.arrayJustificativasRota = data.data.justificativas;
                }else{
                    this.statusbar = 'error';
                    this.statusInformation = true;
                    this.isLoading= false;
                }
            }
           
        },
        cancelButton(){
            this.$bvModal.hide('encerrarRotaModal'+this.rota)
        },
        async salvarJustificativaRota(){
            if( this.getSizeOfMap(this.arrayPontosJustificados) > 0){
                this.isLoading= true;
                var param = {
                    'itens': this.arrayPontosJustificados,
                }
                var data = await new HttpRequest()
                    .Post('/roteirizador/monitoramento/justificativas/modal/dados/salvar', param);
                
                if(data.status){
                    this.$emit('recarregaDados')
                    this.toastModal('Salvo', data.data.message, 'success')
                    this.$bvModal.hide('encerrarRotaModal'+this.rota)
                    this.isLoading= false;
                } else {
                    this.toastModal('Erro', 'Erro ao salvar, tente novamente mais tarde', 'danger')
                    this.isLoading= false;
                }
            }
        },

        toastModal(titulo, menssagem, variante){
            this.$bvToast.toast(menssagem, {
                title: titulo,
                autoHideDelay: 3000,
                variant: variante
            })
        },

        getSizeOfMap(mapArray){
            var len = 0;
            for (var count in mapArray) {
                    len++;
            }
            return len;
        },

        //Methodo para popular os combobox de justificativa.
         /**
         * Metodo para inserir o valor da justificativa nos selects da popUP justificativasSelected 
         * @return Array com os dados das justificativas selecionadas (justificativasSelected)
         * @author VeCoⓇ
         */

        justificarTodos(xyz, value, description){           
            this.justificativasSelected = [{
                value: value,
                description: description
            }]
        },
    },
})
</script>

<style>
	.popoverJustificativa{
		font-size: 13px;
		min-width: 450px !important;
		padding-bottom: 20px;
	}
	.textoJustificativas{
		font-size: 14px;
		text-align: center;
		justify-content: center;
		margin: 10px;
	}
	.bntJustificativa{
		position: absolute;
		right: 50px;
	}

</style>



<style lang="scss">
    .justpaddingbottom{
        padding: 0 0 10px 0!important;
    }
    .paddingTopAndLeft{
        padding-top: 21px;
        padding-right: 3px!important;
    }
    .corpo-modal{
        height: 380px;
        max-height: 420px;
        overflow-y: auto;
    }
</style>