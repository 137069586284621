<template>
    <panelEagle :loading="loadingPanel" id="conferenciaintegracao">
        <div class="col-sm-12 row nopadding">
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiSendCheck" titulo="Conferencia de Integração" />
                </div>
                <div class="col-sm-6">
                    <SimpleButton text="Gerar" :icon="mdiThumbUpOutline" type="green" event="gerar"
                        @gerar="gerarRelatorio" style="float:right;" :disabled="$v.$invalid" />
                    <SimpleButton text="Imprimir" :icon="mdiPrinter" type="light-blue" event="imprimir"
                        @gerar="imprimir" style="float:right;" :disabled="true" />
                    <SimpleButton text="Exportar" :icon="mdiFileReplaceOutline" type="blue" event="exportar"
                        @gerar="exportar" style="float:right;" :disabled="true" />

                </div>
                <slideUpAndDown>
                    <slot slot="filters">
                        <div class="col-sm-12">
                            <hr />
                        </div>
                        <div class="col-sm-12 row nopadding divDontPrint mb-3">
                            <div class="col-sm-3 nopadding">
                                <InputRangeWithLimit name="selectData" titulo="Intervalo" :isObrigatorio="true"
                                    @changeInput="changeData" />
                            </div>
                            <div class="col-sm-3 nopadding">
                                <selectAll @changeSelect="selectEmpresas" :isMultiple="true"
                                    nameForRadio="radiosEmpresas" :labels="labelsSelectEmpresa"
                                    :optionsArray="optionsSelectEmpresas" firstSelected="E" :selected="selectedEmpresa"
                                    :hasSelectAll="false">
                                </selectAll>
                            </div>
                            <div class="col-sm-3 nopadding">
                                <selectAll ref="selectVT" :disabled="$v.empresasArray.$invalid" :loading="loadingVel"
                                    @selectCriado="selectCriadoVeiculosTags" @changeCheck="changeCheckVeiculosTags"
                                    @changeSelect="selectVeiculosTags" :isMultiple="true"
                                    nameForRadio="radiosVeiculosTags" :labels="labelsSelectVeiculosTags"
                                    :optionsArray="optionsVeiculosTags" firstSelected="V" :hasSelectAll="true">
                                </selectAll>
                            </div>
                            <div class="col-sm-3 nopadding">
                                <selectAll @changeSelect="selectPontos" :isMultiple="true" nameForRadio="radiosPontos"
                                    :labels="labelSelectPontos" :optionsArray="optionsSelectPontos" firstSelected="P"
                                    :selected="selectedPonto" :hasSelectAll="false" :loading="loadingVel"
                                    :disabled="$v.empresasArray.$invalid">
                                </selectAll>
                            </div>
                            <div class="col-sm-3 nopadding mt-2">
                                <selectAll @changeSelect="selectContratos" :isMultiple="true"
                                    nameForRadio="radiosContratos" :labels="labelSelectContratos"
                                    :optionsArray="optionsSelectContratos" firstSelected="C"
                                    :selected="selectedContrato" :hasSelectAll="false"
                                    :disabled="$v.empresasArray.$invalid" :loading="loadingVel">
                                </selectAll>
                            </div>
                            <div class="col-sm-3 nopadding mt-2" style="margin-top: 20px;">
                                <selectAll @changeSelect="selectRotas" :isMultiple="true" nameForRadio="radiosRotas"
                                    :labels="labelSelectRotas" :optionsArray="optionsSelectRotas" firstSelected="R"
                                    :selected="selectedRotas" :hasSelectAll="false"
                                    :disabled="$v.empresasArray.$invalid" :loading="loadingVel">
                                </selectAll>
                            </div>
                        </div>
                    </slot>
                </slideUpAndDown>
                <div class="col-sm-12 mt-2">
                    <hr />
                </div>
                <div class="divRetangulos col-sm-12">
                    <div class="retanguloVerde col-sm-3">
                        <div>
                            <span @click="sucesso" 
                                :class="{ link: ativarBotaoIntegrarTodos }" 
                                style="color: white; font-size: 16px; 
                                font-weight: bold;">
                                <span style="font-size: 25px;">{{ relatorio.totais.total_integradas }}</span><br>
                                    Integradas com sucesso
                            </span>
                        </div>

                        <div style="color: #3c7c53;">
                            <baseIcon size='60' :icon="mdiCheckAll" />
                        </div>
                    </div>
                    <div class="retanguloAzul ml-5 col-sm-3">
                        <div>
                            <span @click="total" :class="{ link: ativarBotaoIntegrarTodos }" style="color: white; font-size: 16px; font-weight: bold;">
                                <span style="font-size: 25px;">{{ relatorio.totais.total_coletas }}</span><br>
                                Total
                            </span>
                        </div>
                        <div style="color: #23509e;">
                            <baseIcon size='60' :icon="mdiSyncCircle" />
                        </div>
                    </div>
                    <div class="retanguloVermelho ml-5 col-sm-3">
                        <div>
                            <span @click="erro" :class="{ link: ativarBotaoIntegrarTodos }" style="color: white; font-size: 16px; font-weight: bold;">
                                <span style="font-size: 25px;">{{ relatorio.totais.total_nao_integradas }}</span> <br> 
                                Não integrado, com erro
                            </span>
                        </div>
                        <div style="color: #a44935;">
                            <baseIcon size='60' :icon="mdiClose" />
                        </div>
                    </div>
                </div>

                <div class="col-sm-12" v-if="tamanhoRelatorio == 0">
                    <statusInformation typeBar="div" :statusBar="statusBar" />
                </div>
                <div style="margin-top: 30px; text-align: center; width: 100%;" v-else>
                    <div class="table-responsive" style="width:100%; margin-left: auto; margin-right: auto;">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="ml-2">
                                        <div v-if="ativarBotaoIntegrarTodos" style="color: #23509e;"
                                            class="botao-reintegrar-todos">
                                            <button @click="reintegrarTodos()"
                                                style="background: none; border: none; cursor: pointer; padding: 0; color: inherit;">
                                                <baseIcon size='24' :icon="mdiSyncCircle" />
                                            </button>
                                        </div>
                                    </th>
                                    <th>Data de Integração</th>
                                    <th>Nome do Ponto | Contrato</th>
                                    <th>Rota</th>
                                    <th>Status</th>
                                    <th>Motivo</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in relatorio.original" :key="item.dataIntegracao" class="tabela-integracao">

                                    <td>
                                        <div v-if="!item.integrado" style="color: #23509e;">
                                            <button @click="reintegrar(item)" style="background: none; border: none; cursor: pointer; padding: 0; color: inherit;">
                                                <baseIcon size='24' :icon="mdiSyncCircle" />
                                            </button>
                                        </div>
                                    </td>
                                    <td>{{ item.data_integracao }}</td>
                                    <td>{{ item.nome_ponto }} - {{ item.contrato }}</td>
                                    <td>{{ item.codigo_rota }} - {{ item.nome_rota }}</td>
                                    <td>
                                        <div v-if="item.integrado" style="color: #37764d;">
                                            <baseIcon size='24' :icon="mdiCheck" />
                                        </div>
                                        <div v-else style="color: #a34935;">
                                            <baseIcon size='24' :icon="mdiClose" />
                                        </div>
                                    </td>
                                    <td>{{ item.motivo }}</td>


                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-lg-2 campoObri">
                    <span>(*) Campos obrigatórios</span>
                </div>
                <div class="col-sm-12 col-md-12">
                    <hr>
                </div>
            </div>
        </div>
    </PanelEagle>
</template>
<script>

import Vue from 'vue'
import { required } from 'vuelidate/lib/validators'
import { mdiSendCheck, mdiThumbUpOutline, mdiClose, mdiSyncCircle, mdiCheckAll, mdiCheck, mdiPrinter, mdiFileReplaceOutline } from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service';
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import slideUpAndDown from '@/components/Atom/SlideUpAndDown/SlideUpAndDown.vue';
import InputRangeWithLimit from '@/components/Atom/Datas/InputRangeWithLimit.vue';
import SelectAll from '@/components/Atom/Select/SelectAll.vue';
import PanelEagle from '@/components/Atom/Panel/PanelEagle.vue';
import SimpleButton from '@/components/Atom/Buttons/SimpleButton.vue';
import { conectionError } from '@/Services/Helpers/swellHeper';
import { mapGetters } from 'vuex'
import { FiltrosService } from '@/Services/filtros/filtros.Service'

export default Vue.extend({
    name: 'relconferenciaintegracao',

    validations: {
        empresasArray: { required },
    },

    components: {
        tituloPage: require("@/components/Atom/Header/Titulo").default,
        statusInformation: require("@/components/Atom/StatusInformation/StatusInformation").default,
        baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
        slideUpAndDown,
        InputRangeWithLimit,
        SelectAll,
        PanelEagle,
        SimpleButton
    },
    data() {
        return {
            url: '/roteirizador/relatorios/conferencia/integracao',
            mdiSendCheck: mdiSendCheck,
            mdiThumbUpOutline: mdiThumbUpOutline,
            mdiClose: mdiClose,
            mdiSyncCircle: mdiSyncCircle,
            mdiCheckAll: mdiCheckAll,
            mdiPrinter: mdiPrinter,
            mdiCheck: mdiCheck,
            mdiFileReplaceOutline: mdiFileReplaceOutline,
            optionsSelectEmpresas: new EmpresasService().Get(),
            optionsSelectPontos: [],
            optionsSelectContratos: [],
            optionsSelectRotas: [],
            empresasArray: [],
            veiculosArray: [],
            pontosArray: [],
            contratosArray: [],
            rotasArray: [],
            dataInput: "",
            optionsSelectVeiculos: [],
            optionsVeiculosTags: [],
            labelsSelectVeiculosTags: [
                { indexDFH: "V", description: "Veiculos" },
            ],
            checkSelecionadoVeiculosTags: 'V',
            loadingVel: false,
            optionsContratos: [],
            loadingContratos: false,
            labelsSelectContratos: [],
            labelsSelectEmpresa: [
                { indexDFH: "E", description: "Empresa*" },
            ],
            labelSelectPontos: [
                { indexDFH: "P", description: "Pontos" },
            ],
            labelSelectContratos: [
                { indexDFH: "C", description: "Contratos" },
            ],
            labelSelectRotas: [
                { indexDFH: "R", description: "Rotas" },
            ],
            selectedEmpresa: [],
            selectedPonto: [],
            selectedContrato: [],
            selectedRotas: [],
            loadingPanel: false,
            relatorio: {
                original: [],
                totais: {
                    total_coletas: 0,
                    total_integradas: 0,
                    total_nao_integradas: 0
                }
            },
            relatorioORiginal: [],
            relatorioArray:[],
            statusBar: 'info',
            tamanhoRelatorio: 0,
            ativarBotaoIntegrarTodos: false
        }
    },

    methods: {
        ...mapGetters(["getMaster"]),

        /**
        * @description faz a requisição para o endpoint com um único item 
        * para fazer a reintegração
        * @param object item
        * @author Otávio 🦆 
        */
        async reintegrar(item) {
            this.loadingPanel = true;
            const reintegracaoData = [{
                codigo: item.codigo_item,
                tipo: item.tipo_codigo,
                icrcodigo: item.icrcodigo
            }];
            try {
                const response = await new HttpRequest().Post(this.url + '/reintegrar', reintegracaoData);
                if(response.code == '200'){
                    this.gerarRelatorio();
                    this.statusBar = 'success';
                }else {
                    this.statusBar = 'error';
                }
            } catch (error) {
                this.statusBar = 'error';
            }

            this.loadingPanel = false;
        },

        /**
        * @description Filtra para tentar identificar se existe algum item
        * para integrar, caso não existe para o carregamento(esse caso foi uma
        * tratativa a mais, já tem outra tratativa para evitar isso). Caso encontre
        * é montado objeto com os itens para integrar e faz a requisiççao para o endpoint
        * integrar
        * @param object item
        * @author Otávio 🦆 
        */
        async reintegrarTodos() {
            this.loading = true;
            const itensParaReintegrar = this.relatorio.original.filter(item => !item.integrado);

            const reintegracoes = itensParaReintegrar.map(item => ({
                codigo: item.codigo_item,
                tipo: item.tipo_codigo,
                icrcodigo: item.icrcodigo
            }));

            if (reintegracoes.length === 0) {
                this.loading = false;
                return;
            }
            const response = await new HttpRequest().Post(this.url + '/reintegrar', reintegracoes);
            if(response.code == '200'){
                this.gerarRelatorio();
            }else{
                conectionError();
            }
            this.loading = false;
        },

        /**
        * @description essa função monta o payload para fazer a requisição no endpoint de gerarRelatorio
        * @author Otávio 🦆 
        */
        async gerarRelatorio() {
            this.loadingPanel = true;
            let chaveTipoVeiculo = this.checkSelecionadoVeiculosTags == 'V' ? 'veiculos' : 'tags';
            const filtros = {
                dataInput: this.dataInput,
                empresas: this.empresasArray,
                pontos: this.pontosArray,
                contratos: this.contratosArray,
                rotas: this.rotasArray,
                [chaveTipoVeiculo]: this.veiculosArray,
            };
            try {
                const response = await new HttpRequest().Post(this.url + '/gerarRelatorio', filtros);
                if(response.code == '200'){
                    this.relatorio = {
                        original: response.data.integracao.original.detalhes,
                        totais: response.data.integracao.original.totais,
                    };
                    this.relatorioORiginal = this.relatorio.original;
                    this.ativarBotaoIntegrarTodos = this.relatorio.original.some(item => !item.integrado);
                    this.tamanhoRelatorio = response.data.integracao.original.totais.total_coletas;
                    this.statusBar = this.tamanhoRelatorio == 0 ? 'error' : 'success';
                }else {
                    this.statusBar = 'error';
                }
            } catch (error) {
                this.statusBar = 'error';
                conectionError();
            }
            this.loadingPanel = false;
        },

        /**
        * @description recebe os valores do select de veiculos com tags
        * @author Otávio 🦆 
        */
        selectVeiculosTags(event) {
            this.limparRelatorio();
            this.veiculosArray = event;
        },

        /**
        * @description monitora quando o rádio foi alterado
        * @author Otávio 🦆 
        */
        changeCheckVeiculosTags(check) {
            this.limparRelatorio();
            this.checkSelecionadoVeiculosTags = check;
            this.buscaDadosSelect();
        },

        /**
        * @description Captura os dados do do input de data
        * @author Otávio 🦆 
        */
        changeData(data) {
            this.limparRelatorio();
            this.dataInput = data;
            if (this.empresasArray.length) {
                this.buscaDadosSelect();
            }
        },

        /**
        * @description força atualização no mounted, caso precise
        * @author Otávio 🦆 
        */
        selectCriadoVeiculosTags(select) {
            this.limparRelatorio();
            this.veiculosSelect = select;
        },

        /**
        * @description Captura os dados do select de empresa
        * @author Otávio 🦆 
        */
        selectEmpresas(event) {
            this.limparFiltros();
            this.limparRelatorio();
            this.empresasArray = event;
            if (event.length) {
                this.buscaDadosSelect();
            }
        },

        total(){
            //Caso exista algum resultado na consulta ativa a funcao
            if(this.ativarBotaoIntegrarTodos){
                this.relatorio.original = this.relatorioORiginal
            }
        },

        erro(){
            //Caso exista algum resultado na consulta ativa a funcao
            if(this.ativarBotaoIntegrarTodos){

                this.relatorioArray = Object.values(this.relatorioORiginal);
                this.relatorio.original = this.relatorioArray.filter(item => item.integrado === false);

            }
        },

        sucesso(){
            //Caso exista algum resultado na consulta ativa a funcao
            if(this.ativarBotaoIntegrarTodos){

                this.relatorioArray = Object.values(this.relatorioORiginal);
                this.relatorio.original = this.relatorioArray.filter(item => item.integrado === true);

            }
        },


        //TODO será criada posteriormente
        exportar(){

        },

        //TODO será implementada posteriormente
        imprimir(){
            window.print();
        },

        /**
        * @description limpa o array de relatório, altera o statusBar para 'info' e seta o tamanho
        * padrão para o tamanhoRelatório.
        * @author Otávio 🦆 
        */
        limparRelatorio(){
            this.relatorio = {
                original: [],
                totais: {
                    total_coletas: 0,
                    total_integradas: 0,
                    total_nao_integradas: 0
                }
            };
            this.statusBar = 'info';
            this.tamanhoRelatorio = 0;
        },

        /**
        * @description limpa os arrays dos options dos selects dos filtros 
        * @author Otávio 🦆 
        */
        limparFiltros(){
            this.optionsSelectPontos = [];
            this.optionsVeiculosTags= [];
            this.optionsSelectContratos = [];
            this.optionsSelectRotas = [];
        },

        /**
        * @description Captura os dados do select de pontos
        * @author Otávio 🦆 
        */
        selectPontos(event) {
            this.limparRelatorio();
            this.pontosArray = event;
        },

        /**
        * @description Captura os dados do select de contrato
        * @author Otávio 🦆 
        */
        selectContratos(event) {
            this.limparRelatorio();
            this.contratosArray = event;
        },

        /**
        * @description Captura os dados do select de rotas
        * @author Otávio 🦆 
        */
        selectRotas(event) {
            this.limparRelatorio();
            this.rotasArray = event;
        },

        /**
        * @description faz uma requisição para buscar os filtros dos selects usando o FiltrosServices 
        * @author Otávio 🦆 
        */
        async buscaDadosSelect() {
            this.loadingVel = true;
            this.loadingButtonSelect = true;
            const empresas = this.empresasArray;
            const filtroSelecionado = this.checkSelecionadoVeiculosTags;
            this.veiculosSelect = [];
            this.optionsVeiculosTags = [];
            const tiposFiltro = ['P', filtroSelecionado, 'CCA', 'RT'];
            const intervalo = [this.dataInput];
            const dados = await new FiltrosService().dados_filtros(empresas, tiposFiltro, intervalo);
            if (dados) {
                this.optionsSelectPontos = dados['P'];
                this.optionsVeiculosTags = dados[filtroSelecionado];
                this.optionsSelectContratos = dados['CCA'];
                this.optionsSelectRotas = dados['RT'];
            }
            this.loadingVel = false;
        }
    },

    watch: {
        /**
        * @description monitora qual radio está preenchido 
        * @author Otávio 🦆 
        */
        checkSelecionadoVeiculosTags(value) {
            if (this.optionsSelectVeiculos[value]) {
                this.limparRelatorio();
                this.optionsVeiculosTags = this.optionsSelectVeiculos[value]
            }
        }
    },
    mounted() {
        if (!this.getMaster()) {
            if (this.optionsSelectEmpresas && this.optionsSelectEmpresas.length) {
                this.selectedEmpresa = this.optionsSelectEmpresas;
            }
        }
    }
});
</script>

<style scoped>
    .botao-reintegrar-todos {
        margin-left: 5px;
    }

    .divRetangulos {
        display: flex;
        margin-bottom: 10px;
        justify-content: center;
    }

    .retanguloVerde {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100px;
        width: 300px;
        background-color: #53976b;
        border-radius: 5px;
        padding: 0 10px;
    }

    .retanguloVerde>div:first-child {
        text-align: left;
        flex-grow: 1;
    }

    .retanguloVerde>div:first-child>span {
        display: block;
        font-size: 20px;
        color: white;
    }

    .retanguloAzul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100px;
        width: 300px;
        border-radius: 5px;
        padding: 0 10px;
        background-color: #5989db;
    }

    .retanguloAzul>div:first-child {
        text-align: left;
        flex-grow: 1;
    }

    .retanguloAzul>div:first-child>span {
        display: block;
        font-size: 20px;
        color: white;
    }

    .retanguloVermelho {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100px;
        width: 300px;
        border-radius: 5px;
        padding: 0 10px;
        background-color: #e06347;
    }

    .retanguloVermelho>div:first-child {
        text-align: left;
        flex-grow: 1;
    }

    .retanguloVermelho>div:first-child>span {
        display: block;
        font-size: 20px;
        color: white;
    }

    .campoObri {
        margin-top: 6px;
        font-size: 14px;
        text-align: left;
        margin-left: 10px;
        position: relative;
        bottom: 0;
    }


    .tabela-integracao{
        background: #e5e5e5;
        border-bottom: 2px solid #c3c3c3;
    }

    .link {
        cursor: pointer;
    }

</style>