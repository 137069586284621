<template>
    <div id='input-horario'>
        <div>
            <label for='inicio-evento'>Inicio</label>
            <input
			id='inicio-evento'
			v-model='inicio'
			type='time'>
        </div>
        <div>
            <label for='final-evento'>Final</label>
            <input
			id='final-evento'
			v-model='final'
			type='time'>
        </div>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
	name:'inputHorarioInicioFinal',
	data() {
		return {
			inicio: '',
			final : ''
		}
	},
	methods:{
		emiteIntervalo(){
			this.$emit('intervalo', [
				this.inicio,
				this.final
			])
		}
	},
	validations:{
		inicio: { required },
		final : { required }
	},
	watch: {
		inicio(){
			if(!this.$v.$invalid)
				this.emiteIntervalo()
		},
		final(){
			if(!this.$v.$invalid)
				this.emiteIntervalo()
		}
	},

}
</script>

<style lang="scss" scoped>
#input-horario{
    padding: 2px;
	// background-color: blue;
    input{
        width: 100px;
        margin-left: 10px;
    }
    label{
		padding-top: 2px;
        width: 40px;
    }
}
</style>