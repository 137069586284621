/**
    Este componente de botões básicos de listagem quando a listagem necessita de status,
        tendo seus botões de edição e alteração de status
    
    -> Propriedades:
        1- id - código do cadastro em questão - EX: '2'

    -> Slot:
        1- Sem Nome: espaço para colocar algo a mais
    
    -> Eventos:
        1- editarCadastro - Emite quando o botão de editar for clicado - Parametros:
            1.1- id - Código do cadastro clicado
        2- alterarStatus - Emite quando o botão de alteração de status for clicado - Parametros:
            2.1- id - Código do cadastro clicado
            2.2- status - Novo status para ser alterado
 */

<template>
    <div>
        <slot></slot>
        <simpleButton 
            v-if="editarVal" 
            @editar="EditarClick(id)" 
            event="editar" 
            width="50px" 
            title="Editar" 
            type="green" 
            :icon="iconEditar"/>
        <simpleButton 
            v-if="editarVal && status.trim() == 'A'" 
            event="alterarStatus"
            type="red" 
            width="50px" 
            title="Inativar" 
            :icon="iconDesativar"
            @alterarStatus="AlterarStatus(id, 'I')"/>
        <simpleButton 
            @alterarStatus="AlterarStatus(id, 'A')" 
            event="alterarStatus" 
            v-else-if="editarVal && status.trim() == 'I'" 
            type="light-green" 
            width="50px" 
            title="Ativar" 
            :icon="iconAtivar"/>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
import Vuex from '../../../store/index'
import {mdiPencilOutline, mdiCircleOffOutline, mdiCheck} from '@mdi/js'
export default Vue.extend({
    name:'BasicButtonsListagemEDE',
    components: {
        'simpleButton': require('./SimpleButton').default
    },
    props:{
        id:{
            type:[String, Number],
            required: true,
            default: ''
        },
        status:{
            type:[String, Number],
            required: true,
            default: ''
        },
    },
    data() {
        return {
            iconEditar: mdiPencilOutline,
            iconDesativar: mdiCircleOffOutline,
            iconAtivar: mdiCheck
        }
    },
    methods: {
        EditarClick(id:any){
            this.$emit('editarCadastro', id)
        },
        AlterarStatus(id:any, newStatus: any){
            this.$emit('alterarStatus', id, newStatus);
        }

    },
    computed:{
        editarVal: function(){
            return Vuex.getters.getEditar
        },
    }
})
</script>

<style lang="scss">
</style>