<!-- 
    * dados:
    valor: string - Assinatura digital
 -->
 <template>
    <div class="mt-4 col-12 nopadding">
        <tableRelatorio>
            <template slot="thead">
            </template>
            <template slot="tbody">
                <tr class="backColorTdPDF">
                    <td colspan="1" class="nopadding">
                        <span class="newbadge">
                            {{dados.descricao}}
                        </span>
                    </td>
                </tr>
                <tr class="trTdHeader">
                    <td>Assinatura</td>
                </tr>
                <tr class="paddingTd">
                    <td>{{dados.valor}}</td>
                </tr>
            </template>
        </tableRelatorio>
    </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
    name:'assinaturaDigital',
    components:{
		tableRelatorio: require('@/components/Atom/Table/TableRelatorio').default,
    },
    props:{
        dados:{
            type: Object,
            required: true,
            default: function(){return {}}
        },
    },
})
</script>
    
<style lang="scss">
    
</style>