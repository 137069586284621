<template>
    <panelEagle id='Modulos' :loading='carregando'>
        <div class="col-sm-12 nopadding row">
            <div class="col-sm-6">
                <tituloPage :icon='mdiCellphoneDock' titulo='Modelos módulos'></tituloPage>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                <basicButtonsCadastrosSC
                    @salvarCadastro='salvarCadastro'
                    :disabled='$v.$invalid'
                    tipoCancela='button'
                    linkCancel="listarModulo">
                </basicButtonsCadastrosSC>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class="col-lg-12 nopadding row">
            <div class="col-3 nopadding">
                <inputSimple
                    @changeInput='v=>Mm.mmdescricao = v'
                    :value='valueDesc'
                    :inputClass='{"border border-danger": $v.Mm.mmdescricao.$anyError}'
                    @blur="$v.Mm.mmdescricao.$touch()"
                    label="Descrição*"
                    name='descricao'/>
            </div>
            <div class="col-2 nopadding">
                <inputSimple
                    :value='valueEntr'
                    label="Entradas*"
                    name='entradas'
                    :inputClass="{'border border-danger': $v.Mm.mmentrada.$anyError}"
                    @blur='$v.Mm.mmentrada.$touch()'
                    @changeInput='v=>Mm.mmentrada = v'
                />
            </div>
            <div class="col-2 nopadding">
                <inputSimple
                    class="danger"
                    :value='valueSaid'
                    label="Saidas*"
                    name='saidas'
                    :inputClass='{"border border-danger": $v.Mm.mmsaida.$anyError}'
                    @blur="$v.Mm.mmsaida.$touch()"
                    @changeInput='v=> Mm.mmsaida = v'
                />
            </div>              
            <div class="col-2 nopadding">
                <inputMoney 
                    :value='valueMone'
                    :inputClass='{"border border-danger": $v.Mm.mmvalorinstalacao.$anyError}'
                    label="Valor de instalação*"
                    name='inputValor'
                    @blur="$v.Mm.mmvalorinstalacao.$touch()"
                    @changeInput="v=>Mm.mmvalorinstalacao = v"
                />
            </div>
            <div class="col-3 nopadding">
                <botaoSelectAll
                    nameForRadio  ='Módulos compatíveis'
                    :isMultiple   ='true'
                    :labels       ='labelsMod'
                    :selected     ='selected'
                    :optionsArray ='optionsArray'
                    :hasSelectAll ='true'
                    :loading='buscandoModulos'
                    :disabled='!optionsArray.length'
                    @changeSelect ='v=> mododulosCompativeos = v'/>
            </div>
            <div class='col-sm-12 nopadding'>
                <campoObrigatorio/>
            </div>
            <div class="col-sm-12"><hr></div>
        </div>
    </panelEagle>
</template>
<script>
import {mdiCellphoneDock } from '@mdi/js'
import Vue  from 'vue'
import { HttpRequest } from '../../../../Services/auth/HttpRequest.Service'
import { required, minValue, numeric } from 'vuelidate/lib/validators'
import Swal from 'sweetalert2'
export default Vue.extend({
    name:'cadastrarModulosModelos',
    components:{
        'panelEagle'             :require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage'             :require('@/components/Atom/Header/Titulo').default,
        'BasicButtonsCadastrosSC':require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
        'inputMoney'             :require('@/components/Atom/Inputs/InputMoney').default,
        'InputSimple'            :require('@/components/Atom/Inputs/InputSimple').default,
        'botaoSelectAll'         :require('@/components/Atom/Select/SelectAll').default,
        'campoObrigatorio'       :require('@/components/Atom/Footer/RequiredFields').default,
    },
    validations:{
        Mm:{
            mmdescricao       : { required },
            mmentrada         : { required, numeric },
            mmsaida           : { required, numeric },
            mmvalorinstalacao : { required, minValue:minValue(0.01)},
        }
    },
    data(){
        return {
            mdiCellphoneDock:mdiCellphoneDock,
            label:"Descrição*",
            name:'descricao',
            optionsArray:  [],
            labelsMod   :  [{
                    indexDFH    : 'MC',
                    description : 'Módulos compatíveis'
                            }],
            selected    :  [],
            descricao   :  '',
            entrada     :  '',
            saida       :  '',
            valor       :  '',
            valueMone   :  '',
            valueSaid   :  '',
            valueEntr   :  '',
            valueDesc   :  '',
            carregando:false,
            buscandoModulos:false,
            Mm:{
                mmdescricao         :  '',
                mmentrada           :  '',
                mmsaida             :  '',
                mmvalorinstalacao   :  '',
            },
            mododulosCompativeos:[],
        }
    },
    methods:{
        /**
         * Salva um novo modelo ou as edições de um modelo existente no banco de dados
         */
        salvarCadastro(){
            this.carregando = true
            let uri = '/administrativo/cadastros/modulos/salvar'
            let obj = {'Mm': this.Mm, 'compativeis':this.mododulosCompativeos}
            if(this.$route.params.id){
                uri = '/administrativo/cadastros/modulos/editar'
                obj = {...obj, id:this.$route.params.id}
                new HttpRequest().Post(uri, obj).then((data)=>{
                   this.lidaComRetorno(data)

                })
            }else{
                new HttpRequest().Post(uri, obj).then((data)=>{
                   this.lidaComRetorno(data)
                })
            }
        },

        /**
         * As rotas para criar um novo módulo e para editar um são 
         * diferentes, mas o tratamento do resultado é igual. 
         * Essa função faz isso, o tratamento do resultado
         * @param Response data
         * @author Gui 🍺
         */
        lidaComRetorno(data){
            if(data?.data?.success){
                this.$router.push({name:'listarModulo'})
                this.carregando = false
            }else{
                var msg = '<p>Algo de inesperado aconteceu</p><p>Tente novamente mais tarde</p>'
                Swal.fire({
                    title : 'oops',
                    icon  : 'error',
                    html  : msg
                })
                this.carregando = false
            }
        }
    },
    async mounted(){
        let uri = '/administrativo/cadastros/modulos/mod'
        this.buscandoModulos = true
        new HttpRequest().Post(uri).then((data)=>{
            this.optionsArray = data.data.modulos
            this.buscandoModulos = false
        })
        if(this.$route.params.id){
            this.carregando = true
            uri = '/administrativo/cadastros/modulos/edita'
            let obj = {id:this.$route.params.id}
            new HttpRequest().Post(uri, obj).then((data)=>{
                if(data.data.info != null){
                    this.valueDesc = data.data.info.mmdescricao 
                    this.valueEntr = data.data.info.mmentrada
                    this.valueSaid = data.data.info.mmsaida
                    this.valueMone = data.data.info.mmvalorinstalacao
                    this.Mm.mmvalorinstalacao = data.data.info.mmvalorinstalacao
                }
                uri = '/administrativo/cadastros/modulos/modid'
                new HttpRequest().Post(uri, obj).then((data)=>{
                    if(data.data != null){
                        this.selected = data.data.modulos
                    }
                    this.carregando = false
                })
            })
            
        }
    }
})
</script>
<style lang="scss">
</style>
