<template>
    <panelEagle id='listagemUsuariosCP'
        :loading="loadingPanel">
        <div class="col-sm-12 nopading row">
            <div class="col-sm-6 nopadding">
                <tituloPage :icon="mdiAccount" titulo='Usuários'></tituloPage>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 nopadding divDontPrint">
                <basicButtonsCadastrosEIN
                    tipoNovo='button'
                    linkNew ='cadastrarUsuariosCP'
                    @exportarCadastro="exportar"
                    :loading ='loadingExportar'
                    :disabledDropdown='$v.dadosTabela.$invalid'
                    >
                </basicButtonsCadastrosEIN>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class="col-sm-12 nopadding row divDontPrint">
            <div class="col-sm-4 nopadding divDontPrint">
                <selectAll
                    nameForRadio='empresasUsuariosCP'
                    :isMultiple="true"
                    :hasSelectAll="true"
                    :optionsArray="optEmpresa"
                    :selected="selectedEmpresa"
                    :labels="[{indexDFH:'EM', description: 'Empresas'}]"
                    @changeSelect="changeEmpresa"/>
            </div>
            <div class="col-sm-6 nopadding divDontPrint">
                <buttonsFilters
                    :arrayButtons='botoes'
                    label='Status'
                    @buttonsFiltersChange='buttonsFiltersChange'
                />
            </div>
            <div class='col-sm-2 nopadding divDontPrint'>
                <simpleAlert 
                    v-if='usuariosPendentes' 
                    type='red'
                    :isLoading='false'
                    :message='errorMessage' 
                    :hasClick='false'
                    class='error'/>
            </div>
        </div>
        <div class="col-sm-12 nopadding">
            <tableListagem
                :titles="titlesTabela"
                :data="dadosTabela"
                deleteEdit ='editStatus'
                :habilitado ="true">
                <template #acoes="{row}" >
                    <simpleButton 
                        v-if="editar" 
                        v-bind='decideBotaoEmail(row)'
                        @click='confirmaEmail(row)'
                    />
                    <simpleButton
                        v-if="editar" 
                        width='30%'
                        type='green'
                        event='click'
                        title='Editar usuário'
                        :icon='mdiPencil'
                        @click="editarCadastro(row.codigo)"
                    />
                    <simpleButton
                        v-if="excluir" 
                        width='30%'
                        :type='row.status=="N" ? "light-green" : "red"'
                        :icon='row.status=="N" ? mdiCheckBold : mdiCircleOffOutline'
                        :title='row.status=="N" ? 
                            "Ativar usuário" : "Desativar usuário"'
                        event='click'
                        @click="alterarStatus(row.codigo, row.status)"
                    />
                </template>
            </tableListagem>
        </div>
        <modalEagle
            :hardToClose="true"
            id='confirmarEmailUsuario'
            title='Reenvio de email'>
            <template #modalBody>
                <div class="col-12 nopadding">
                    <p>{{`Confira se o email ${userEmail.login} está correto!
                    Para reenviá-lo, confirme o email no campo abaixo:`}}</p>
                    <input 
                        :disabled="loadingButtonEmail"
                        class='form-control' 
                        v-model='valueEmailEdicao'>
                </div>
            </template>
            <template #modalFooter>
               <div class="col-12 row nopadding">
                   <loadingButton
                        :isLoading="loadingButtonEmail"
                        :disabled='(userEmail.login != valueEmailEdicao)
                            || loadingButtonEmail'
                        text='Reenviar'
                        type='blue'
                        :icon='mdiCheck'
                        event='click'
                        @click='enviarConfirmacao(userEmail)'
                   />
                   <simpleButton
                        :disabled="loadingButtonEmail" 
                        text='Editar'
                        type='green'
                        :icon='mdiPencilOutline'
                        event='click'
                        @click='editarCadastro(userEmail.codigo)'
                   />
                   <simpleButton
                        :disabled="loadingButtonEmail"
                        text='Cancelar'
                        :icon='mdiCancel'
                        type='red'
                        event='click'
                        @click='fecharModal'
                   />
               </div>
            </template>
        </modalEagle>
    </panelEagle>
</template>
<script lang='js'>
import Vue  from 'vue'
import { mapGetters } from 'vuex'
import {
    mdiAccount, mdiCircleOffOutline, mdiThumbUp, mdiPencilOutline, 
    mdiPencil, mdiCheckBold, mdiEmail, mdiCancel, mdiCheck
} from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { required }        from 'vuelidate/lib/validators'
import { conectionError } from '@/Services/Helpers/swellHeper'

export default Vue.extend({
    name:"listagemUsuariosCP",
    components:{
        'basicButtonsCadastrosEIN' : require(
            '@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
        'buttonsFilters' : require('@/components/Atom/Buttons/ButtonsFilters').default,
        'tableListagem' : require('@/components/Atom/Table/TableListagem').default,
        'loadingButton' : require('@/components/Atom/Buttons/LoadingButton').default,
        'simpleButton' : require('@/components/Atom/Buttons/SimpleButton').default,
		'simpleAlert' : require('@/components/Atom/Alerts/AlertSimple').default,
        'panelEagle' : require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage' : require('@/components/Atom/Header/Titulo').default,
        'selectAll' : require('@/components/Atom/Select/SelectAll').default,
		'modalEagle' : require('@/components/Atom/Modal/ModalEagle').default,
    },

    validations:{
        dadosTabela: { required },
    },

    data(){
        return {
            url:'/checkpoint/cadastros/usuarios/',
            mdiAccount : mdiAccount,
            mdiPencil : mdiPencil,
            mdiCircleOffOutline : mdiCircleOffOutline,
            mdiCheckBold : mdiCheckBold,  
			mdiEmail: mdiEmail,
			mdiThumbUp: mdiThumbUp,
			mdiCancel: mdiCancel,
			mdiPencilOutline: mdiPencilOutline,
			mdiCheck: mdiCheck,
            titlesTabela:[
                {'number': 'login',  'name': 'Login'},
                {'number': 'empresa',  'name': 'Empresa'},
                {'number': 'departamento', 'name': 'Departamento'},
            ],
            dadosTabela : [],
            loadingExportar : [false, false, false],
            optEmpresa : new EmpresasService().Get(),
            selectedEmpresa:[],
            status : [],
            loadingPanel : false,
            empresaSelecionada : [],
            botoes : [
                {'value': 'S',
                'width': '32.4%',
                'isSelected': true,
                'title': 'Ativos',
                'text': 'Ativos',
                'icon': ''},
                {'value': 'N',
                'width': '32.4%',
                'isSelected': false,
                'title': 'Inativos',
                'text': 'Inativos',
                'icon': ''},
                {'value': 'T',
                'width': '32.4%',
                'isSelected': false,
                'title': 'Todos',
                'text': 'Todos',
                'extraClass':'lado',
                'icon': ''},
            ],
			userEmail:{},
			errorMessage : 'Há usuários pendentes, verifique email para validação.',
			valueEmailEdicao: '',
			usuariosPendentes: false,
			loadingButtonEmail:false,
        }
    },

    methods:{

    ...mapGetters(['getMaster',
                    'getAdmCheckpoint',
                    'getUsucheckpoint',
                    'getEditar',
                    'getCadastrar',
                    'getImportar',
                    'getExcluir']),

        async changeEmpresa(empresas){
            this.empresaSelecionada = empresas
            this.listarUsuarios(empresas)
        },

        buttonsFiltersChange(value){
            let index = 2
            this.botoes.map(e => e.icon = '')
            if(value == "S") {
                index = 0
                this.status = ["S"]
            } else if(value == "N") {
                index = 1
                this.status = ["N"]
            } else {
                this.status = ["S", "N"]
            }
            this.botoes[index].icon = mdiCheckBold
            this.changeEmpresa(this.empresaSelecionada)
        },
 
        /**
         * Lista os usuarios para tabela ;)
         */
        listarUsuarios(empresas){
            if(empresas.length) {
                let body = {'empresas': empresas, 'status': this.status}
                this.loadingPanel = true
                new HttpRequest().Post(this.url+'listar', body)
                    .then(dados=>{
                        if(dados.status && dados.data.dados) {
                            this.dadosTabela = dados.data.dados
                            this.usuariosPendentes = dados.data.invalid
                        } else {
                            this.dadosTabela = []
                            conectionError()
                        }
                    })
                    .finally(() => {
                        this.loadingPanel = false
                    })
            } else {
                this.dadosTabela = []
				this.usuariosPendentes = false;
            }
        },

        async exportar(type){
            this.loadingExportar = [type=='pdf', type=='xls', type=='csv']
            let obj = {
                'arrayDados': this.dadosTabela, 
                'tipo':type
            }
            let root =  process.env.VUE_APP_ROOT;
            new HttpRequest().Post(this.url+'exportar', obj)
                .then(dados=>{
                    if(dados.status) {
                        window.open(root+'/'+dados.data.local);
                    } else {
                        conectionError()
                    }
                    this.loadingExportar = [false, false, false]
                })
        },

        /** 
         * @description abre a tela de cadastrar usuario checkpoint
         * @param {number} codigo
         * @author Rafael
         */
        editarCadastro(codigo){
            this.$router.push({name:'cadastrarUsuariosCP', params:{id:codigo}})
        },

        /** 
         * @description altera o status de um perfil
         * @param {number} value
         * @param {string} status
         * @author Rafael
         */
        async alterarStatus(value, status){
            this.loadingPanel = true
            let obj = {
                codigo:value,
                status:status
            }
            new HttpRequest().Post(this.url+'alterar/status', obj)
                .then(dados=>{
                    if(dados.status) {
                        this.listarUsuarios(this.empresaSelecionada)
                    } else {
                        this.loadingPanel = false
                        conectionError()
                    }
                })
                .catch(()=>{
                    this.loadingPanel = false
                })
        },

        /** 
         * @description retorna um objeto de acordo com {row.usvalidacao}
         * @param {object} row
         * @param {boolean} row.usvalidacao
         * @author Rafael
         */
		decideBotaoEmail(row){
			if(row.usvalidacao){
				return {
					type:'light-green',
					icon:this.mdiThumbUp,
					title:'Usuário validado com sucesso!',
					width:'30%'
				}
			}
			return {
				type : 'yellow',
				icon : this.mdiEmail,
				width: '30%',
				event: 'click',
				title: 'Verifique a caixa de email do endereço cadastrado ou'+
                    ' clique para reenviar'
			}
		},

        /** 
         * @description abre modal de confirmar email
         * @param {object} row
         * @author Rafael
         */
		confirmaEmail(row){
			this.userEmail = row
			this.$bvModal.show('confirmarEmailUsuario')
		},

        /** 
         * @description envia comfirmacao por email
         * @param {object} user
         * @author Rafael
         */
        async enviarConfirmacao(user){
			let url = this.url+'confirmar/email'
			this.loadingButtonEmail = true
			try {
				let retorno = await new HttpRequest().Post(url, {codigo:user.codigo})
				if(retorno.data.result){
					this.fecharModal()
				}else{
					conectionError()
				}
			} catch {
				conectionError()
			}
			this.loadingButtonEmail = false
		},

        /** 
         * @description fecha modal de confirmar email e limpa algumas variaveis
         * @param {object} user
         * @author Rafael
         */
        fecharModal(){
			this.$bvModal.hide('confirmarEmailUsuario')
			this.valueEmailEdicao = ''
			this.userEmail = {}
		},
    },
    computed:{
        editar() {
            return this.getEditar();
        },
        excluir() {
            return this.getExcluir();
        }
    },
    async mounted(){
        if(!this.getMaster()){
            this.selectedEmpresa = this.optEmpresa
        }
    }
})
</script>
<style lang="scss">
</style>