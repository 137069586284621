<template>
    <panelEagle id="aprovacaoDePontos" :loading="loadingPanel">
        <div class='col-12 row nopadding'>
            <div class='col-12 row nopadding'>
                <div class='col-6 nopadding'>
                    <titulo-page
                        :icon='iconTitulo'
                        titulo='Aprovação de Pontos'/>
                </div>
                <div class='col-6 d-flex flex-row-reverse nopadding'>
                    <simple-button
                        v-if="getCadastrar()"
                        text='Aprovar todos'
                        event='click'
                        type='green'
                        width='120px'
                        :disabled='!this.pontosPendentesMostrar.length'
                        @click='abreModalAprovaTodos'/>
                    <simpleButton 
                        id="aplicarFiltros" 
                        width="120px" 
                        :icon="mdiPlus" 
                        type="orange" 
                        text="Aplicar filtros" 
                        title="Aplicar filtros">
                    </simpleButton>
                </div>
            </div> 
            <div class='col-sm-12 nopadding'><hr></div>
            <div class='col-12 row nopadding'>
                <div class='col-7 divfull nopadding'>
                    <div
                        v-if='this.pontosPendentesMostrar.length'
                        class='campo-busca-pontos'>
                        <div class='d-flex'>
                            <input
                                placeholder='Buscar...'
                                ref='campoBusca'
                                class='form-search'
                                v-model='valorBuscaRegiao'
                                @keyup.enter='procuraRegiao'/>
                            <div class="col-sm-2 nopadding">
                                <b-button
                                    :disabled="valorBuscaRegiao == ''"
                                    @click="procuraRegiao"
                                    class='search-button ml-1'
                                    variant="info">
                                    <b-spinner
                                        v-if='searchRegiao'
                                        label='Spinning'/>
                                    <baseIcon
                                        v-else
                                        :size='25'
                                        :icon='iconSearch'/>
                                </b-button>
                            </div>
                        </div>
                        <div>
                            <ul class='resultados-busca'>
                                <li
                                v-for='res, i in resultadoBusca'
                                :key='i'
                                @click='mudaLocalPonto(res)'>
                                    {{res.endereco}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <simple-map
                        height='83vh'
                        :newHeight='true'
                        ref='mapaSimples'>
                        <circleSimple
                        v-if="retornaMostrarCirulo"
                        :center="centroCirculo"
                        :radius="raioCirculo"/>
                    </simple-map>
                    <!-- :height='false'/> -->
                </div>
                <div class='col-5 pr-0' style="height: 83vh;">
                    <table-simples style="max-height: 83vh;">
                        <thead>
                            <tr>
                                <th style="width:50px"></th>
                                <th>Código</th>
                                <th>Descrição</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for='(cliente, i) in pontosPendentesMostrar'>
                                <tr :key='cliente.nome+i+"_accordion"' v-if="true"
                                    @click="clickCliente(cliente)"
                                    style="background-color:#bbb">
                                    <td colspan="1"
                                        style="text-align:center">
                                        <b-icon
                                            :id="cliente.nome+i+'_icon'"
                                            class="h5" 
                                            shift-v="-6"
                                            shift-h="0"
                                            style="font-weight: bold;"
                                            :icon="defineChevron(cliente.clicado===false)">
                                        </b-icon>
                                    </td>
                                    <td colspan="3">
                                        <span>{{cliente.nome}}</span>
                                    </td>
                                </tr>
                                <template v-for="iTipo in ['Pedidos', 'Pontos']">
                                    <tr v-show="cliente.clicado!==false"
                                        v-if="cliente[iTipo]"
                                        :key='cliente.nome+i+iTipo'
                                        style="background-color:#ddd">
                                        <td colspan="1"
                                            style="text-align:center"
                                            @click="clickItem(cliente,iTipo+'clicado')">
                                            <b-icon
                                                :id="cliente.nome+i+'_icon'"
                                                class="h5" 
                                                shift-v="-6"
                                                shift-h="0"
                                                style="font-weight: bold;"
                                                :icon="defineChevron(
                                                    cliente[iTipo+'clicado']===false)">
                                            </b-icon>
                                        </td>
                                        <td colspan="3">
                                            <span>{{ iTipo }}</span>
                                        </td>
                                    </tr>
                                    <tr v-for="(item, p) in cliente[iTipo]"
                                        v-show="mostratItens(cliente, iTipo)"
                                        :key='cliente.nome+i+p+iTipo'
                                        class='linha-branca'
                                        :class="{'ponto-marcado':pontoMarcado == p}"
                                        @click="clickItemIndex(p)">
                                        <td class='clicavel'
                                            @click='clicaLista(item, p)'>
                                            <div :class='`marker-circle-${tipoPonto(item.ppatipo)}`'>
                                                <div class='centroMarker'>
                                                    {{ retornaTipo(item.ppatipo) }}
                                                </div>
                                            </div>
                                        </td>
                                        <td class='clicavel'
                                            @click='clicaLista(item, p)'
                                            v-text='item.ppacodigoexterno'>
                                        </td>
                                        <td class='clicavel'
                                            @click='clicaLista(item, p)'
                                            v-text='item.ppadescricao'>
                                        </td>
                                        <td class='acoes-pontos'>
                                            <div class='d-flex'>
                                                <simple-button
                                                    :id='`acoes_editar_${p}`'
                                                    type='green'
                                                    width='43px'
                                                    event='click'
                                                    :icon='iconEditar'
                                                    v-if='podeEditar'
                                                    @click='clicaBotaoEditar(item)'/>
                                                <simple-button
                                                    :id='`acoes_confirmar_${p}`'
                                                    width='43px'
                                                    :icon='iconConfirmar'
                                                    event='click'
                                                    type='blue'
                                                    v-if='podeCadastrar'
                                                    @click='clicaBotaoConfirmar(item.ppacodigo)'/>
                                                <simple-button
                                                    width='43px'
                                                    :id='`acoes_excluir_${p}`'
                                                    :icon='iconExcluir'
                                                    event='click'
                                                    type='red'
                                                    v-if='podeExcluir'
                                                    @click='clicaBotaoExcluir(item.ppacodigo)'/>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </tbody>
                        <status-information
                            v-if='!pontosPendentesMostrar.length'
                            type='tr'
                            :msgCentro='msgCentro'
                            :statusBar='statusBar'/>
                    </table-simples>
                </div>
                <modalEagle
                    @confirmButton="aprovaTodosPontos"
                    title=""
                    id="modal-confirma">
                    <slot slot="modalBody">
                        <div class="col-sm-12 nopadding">
                            Ao confirmar essa operação não será mais possível revertê-la!
                        </div>
                        <div class="col-sm-12 nopadding">
                            Quando aprovado os pontos ou pedidos serão salvos nos locais informados na
                            importação. Deseja mesmo salvar todos os pontos ou pedidos sem
                            verificar?
                        </div>
                    </slot>
                </modalEagle>
            </div>

            <!-- Popover Filtros -->
            <b-popover
                target="aplicarFiltros"
                triggers="hover click"
                :show.sync="popoverFiltrosShow"
                placement="top"
                ref="popover"
                @hidden="onHidden">
                <div class="col-sm-12 row nopadding p-2">
                    <div class="col-sm-12 nopadding mt-2">
                        <selectAll 
                            @changeSelect="changeEmpresa" 
                            :isMultiple="true" 
                            nameForRadio="empresasMonitoramentoRota" 
                            :hasSelectAll="true" 
                            class="nopadding"
                            :labels="[
                                {indexDFH: 'EM', description: 'Empresas*'}]" 
                            :optionsArray="optionsEmpresas" 
                            :selected="popoverData.empresas"
                            firstSelected="EM">
                        </selectAll>
                    </div>
                    <div class="col-sm-12 mt-2 nopadding">
                        <inputSimple 
                            @changeInput="searchItem" 
                            :value="popoverData.search"
                            label="Buscar pedidos e pontos"
                            placeholder="Informe descrição ou código"
                            :inputClass="'nopadding'"
                            class="nopadding"
                            name="searchOrder" 
                            id="searchOrder">
                        </inputSimple>
                    </div>
                    <div class="col-sm-12 mt-2 nopadding">
                        <buttonsFilters 
                            @buttonsFiltersChange="changeFilterTipoItem" 
                            :arrayButtons="arrayTipoItens" 
                            class="nopadding"
                            label="Tipo" 
                            :onlyOneSelected="false">
                        </buttonsFilters>
                    </div>
                    <div class="col-sm-12 mt-2 nopadding">
                        <buttonsFilters 
                            @buttonsFiltersChange="changeFilterTipoPonto" 
                            :arrayButtons="arrayTipoPonto" 
                            class="nopadding"
                            label="Tipo de ponto" 
                            :onlyOneSelected="false">
                        </buttonsFilters>
                    </div>
                    <div class="col-sm-12 nopadding mt-3" align="center">
                        <simpleButton 
                            :disabled="$v.$invalid"
                            id="filtrar" 
                            width="48%" 
                            type="blue" 
                            text="Filtrar" 
                            title="Filtrar"
                            event='click'
                            @click='filtrar'>
                        </simpleButton>
                        <simpleButton 
                            id="fecharFiltros" 
                            width="48%" 
                            :icon="iconExcluir"
                            type="red" 
                            text="Fechar" 
                            title="Fechar"
                            event='click'
                            @click='popoverFiltrosShow = false'>
                        </simpleButton>
                    </div>
                </div>
            </b-popover>
        </div>
    </panelEagle>
</template>

<script>
import {
    mdiMapMarkerCheck,
    mdiPencil,
    mdiFilterMenu,
    mdiCheckBold,
    mdiCloseThick,
    mdiMapSearchOutline,
    mdiPlus,
} from '@mdi/js'
import { EmpresasService } from '../../../Services/auth/Empresas.service'
import { HttpRequest } from '../../../Services/auth/HttpRequest.Service'
import Vue from 'vue'
import { conectionError } from '@/Services/Helpers/swellHeper'
import L from 'leaflet'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { DateTime } from 'luxon'

export default {
    name:'RoteirizadorAprovacaoDePontos',
    components:{
        circleSimple: require('@/components/Atom/Mapa/CircleSimple').default,
        baseIcon: require('@/components/Atom/Icon/BaseIcon').default,
        modalEagle:require('@/components/Atom/Modal/ModalEagle').default,
        simpleButton: require('@/components/Atom/Buttons/SimpleButton').default,
        tituloPage: require('@/components/Atom/Header/Titulo').default,
        panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
        simpleMap: require('@/components/Atom/Mapa/MapaSimples.vue').default,
        tableSimples: require('@/components/Atom/Table/TableSimples').default,
        statusInformation: require('@/components/Atom/StatusInformation/StatusInformation').default,
        buttonsFilters: require('@/components/Atom/Buttons/ButtonsFilters').default,
        selectAll: require('@/components/Atom/Select/SelectAll').default,
        inputSimple: require('@/components/Atom/Inputs/InputSimple').default,
    },
    
	validations:{
		selectedEmpresas:{ required },
    },
    
    data(){
        return {
            url:'/roteirizador/aprovacao/pontos/',
            //bools
            loadingPanel:false,
            searchRegiao:false,
            //icons
            iconTitulo: mdiMapMarkerCheck,
            iconFiltros: mdiFilterMenu,
            iconEditar: mdiPencil,
            iconConfirmar: mdiCheckBold,
            iconExcluir: mdiCloseThick,
            iconSearch: mdiMapSearchOutline,
            mdiPlus: mdiPlus,

            statusBar: 'info',
            valorBuscaPontos:'',
            valorBuscaRegiao:'',
            msgCentro:'Nenhum ponto pendente',
            //dados
            pontosPendentes:[],
            pontoNoMapa:{},
            resultadoBusca: [],
            centroCirculo:[0, 0],
            raioCirculo: 0,
            mostraCirculo:true,

            selectedEmpresas: [],
            filtroTipoItem: ['PE', 'PO'],
            filtroTipoPonto: ['E', 'C', 'P'],
            pontoMarcado: 0,
			popoverData: {
				search: '',
				empresas: [],
				tipoItem: [],
				tipoPonto: [],
			},
			popoverFiltrosShow: false,
			optionsEmpresas: new EmpresasService().Get(),
			arrayTipoItens:[
				{'value':'PO', 'title':'Ponto',  'width': '48%', 'text': 'Ponto', 
				'isSelected':true},
				{'value':'PE', 'title':'Pedido', 'width': '48%', 'text': 'Pedido', 
				'isSelected':true},
			],
			arrayTipoPonto:[
				{'value':'E', 'title':'Entrega', 	'width': '48%', 'text': 'Entrega', 
				'isSelected':true},
				{'value':'C', 'title':'Coleta', 	'width': '48%', 'text': 'Coleta', 
				'isSelected':true},
				{'value':'P', 'title':'Referência', 'width': '48%', 'text': 'Referência', 
				'isSelected':true},
			],
        }
    },

    methods:{
        ...mapGetters(['getMaster', 'getExcluir', 'getEditar', 'getCadastrar']),

        clickCliente(cliente) {
            cliente.clicado = !cliente.clicado
            this.$forceUpdate()
        },

        clickItem(cliente, tipo) {
            cliente[tipo] = !cliente[tipo]
            this.$forceUpdate()
        },

        clickItemIndex(index) {
            this.pontoMarcado = index
            this.$forceUpdate()
        },

        defineChevron(esconder) {
            if (esconder) return 'chevron-right'
            return 'chevron-down'
        },
        
		changeEmpresa(empresas){
			this.selectedEmpresas = empresas
		},

        searchItem(search){
            this.popoverData.search = search
        },

		/**
        * @description Evento de fechar o modal, quando este é executado,
        * as informações dos filtros são armazenadas em *this.popoverData*,
        * pois ao fechar e abrir os filtros, deve mostrar as mesmas
        * informações selecionadas. Não consegui salvar o estado do popover.
        * @author Rafa 🐈
        */
		onHidden() {
			this.popoverData.empresas = this.optionsEmpresas
				.filter((e) => this.selectedEmpresas.includes(e.value))
			this.popoverData.tipoItem = this.filtroTipoItem
			this.popoverData.tipoPonto = this.filtroTipoPonto
            this.popoverFiltrosShow = false
        },
        
        mostratItens(cliente, iTipo) {
            return !(cliente.clicado === false || cliente[iTipo+'clicado'] === false)
        },

		changeFilterTipoItem(values){
			if(values != undefined){
				this.filtroTipoItem = values
			} else {
				this.filtroTipoItem = []
			}
		},

		changeFilterTipoPonto(values){
			if(values != undefined){
				this.filtroTipoPonto = values;
			} else {
				this.filtroTipoPonto = []
			}
        },
        
		filtrar(){
			this.popoverFiltrosShow = false
			this.getPontos()
		},

        /**
         * @listens click em qualquer resultado da lista de busca por
         * endereço.
         * @param {object}resultado
         * @param {number[]} resultado.centro vem como longitude-latitude, deve ser
         * intertido p/ passar para o componente de marker
         * @description altera o local do ponto no mapa, p/ o centro
         * do local pesquisado na caixa de busca e logo depois esvazia os resultados
         * e query de busca.
         * @author Gui 🍺
         */
        mudaLocalPonto(resultado){
            var latlng = resultado.centro.reverse()
            this.pontoNoMapa.setLatLng(latlng)
            this.$refs.mapaSimples.flyTo(latlng)
            this.alteraLatLang()
            this.resultadoBusca = []
            this.valorBuscaRegiao = ''
        },

        /**
         * @listens click no botão de mapa dentro da caixa de busca
         * @listens keyup.enter no input onde o usuário informa a query
         * @description procura por uma query do usuário no
         * OSMR, retorna uma lista de locais encontrados.
         */
        procuraRegiao(){
            this.searchRegiao = true
            new HttpRequest()
                .Post('/telemetria/cadastros/regioes/procurar', {'q':this.valorBuscaRegiao})
                .then(data=>{
                    this.resultadoBusca = data.data.info
                }).finally(()=>{this.searchRegiao = false})
        },

        abreModalAprovaTodos(){
            this.$bvModal.show('modal-confirma')
        },

        /**
         * @listens click botão de aprovar todos
         * @description faz uma request que envia o código
         * de todos os clientes cujo usuário tem acesso
         * para aprovar todos, da maneira como estão.
         */
        aprovaTodosPontos() {
            this.$bvModal.hide('modal-confirma')
            let obj = {
                clientes: this.selectedEmpresas,
                search: this.popoverData.search,
                tipoItem: this.filtroTipoItem,
                tipoPonto: this.filtroTipoPonto,
            }
            let urlTodos = `${this.url}aprovar/todos`
            this.loadingPanel = true
            new HttpRequest().Post(urlTodos, obj)
                .then((dados) => {
                    if(dados.data.codigoDupli) {
                        let title = "Há códigos já cadastrados"
                        let msg = "Codigos cadastrados: "
                        dados.data.resultado.forEach(mensagem => {
                            if(mensagem.hasOwnProperty('codigo')){
                                msg += mensagem.codigo + " "
                            }
                        });
                        conectionError(msg, "info", title)
                    }
                    this.getPontos()
                    var mapa = this.$refs.mapaSimples.returnMapObject()
                    mapa.eachLayer(e=>{
                        if (e.ponto) {
                            e.remove()
                        }
                    })
                }).catch((err) => {
                    conectionError()
                }).finally(() => { this.loadingPanel = false })
        },

        /**
         * @param {number} ppacodigo código do ponto a ser 
         * salvo.
         * @description envia as novas informações do ponto p/
         * p back-end, se der td certo, recarrega a lista de pontos.
         */
        clicaBotaoConfirmar(ppacodigo){
            // let ponto = _.find(this.pontosPendentes, { ppacodigo })
            let objFind = { ppacodigo }
            let ponto = this.findItemPendente(objFind)
            let urlConfirma = `${this.url}confirmar/${ponto.ppacodigo}`
            this.loadingPanel = true
            this.removeMarcadores()
            new HttpRequest().Post(urlConfirma, ponto)
                .then(({ data })=>{
                    if(data.success){
                        this.getPontos()
                    } else {
                        conectionError(data.message)  
                    }
                }).catch(() => {
                    conectionError();
                }).finally(()=>{this.loadingPanel = false})
        },

        removeMarcadores() {
            this.mostraCirculo = false
            var mapa = this.$refs.mapaSimples.returnMapObject()
            mapa.eachLayer(l=>{
                if(l?.ponto)
                    l.remove()
            })
        },

        clicaBotaoEditar(ponto){
            this.montaPonto(ponto, 0, true)
            
        },

        clicaBotaoExcluir(ppacodigo){
            // let ponto = this.pontosPendentesMostrar[index]
            let objFind = { ppacodigo }
            let ponto = this.findItemPendente(objFind)
            this.excluirPonto(ponto.ppacodigo)
        },

        clicaLista(ponto, index) {
            // this.pontoMarcado = index
            this.montaPonto(ponto, index)
        },

        /**
         * @param {object} ponto
         * @param {number} ponto.ppalatitude
         * @param {number} ponto.ppalongitude
         * @param {string} ponto.ppatipo
         * @param {number} index - posição na lista.
         * @description cria o marcador do item na lista que o 
         * usuário clicou
         * @author Gui 🧟
         */
        montaPonto(ponto, index, editar=false){
            let latlag = [ponto.ppalatitude, ponto.ppalongitude]
            let mapa = this.$refs.mapaSimples.returnMapObject()            
            this.removeMarcadores()
            let icone = this.criaDivIcon(ponto)
            var marker = L.marker(latlag, {
                icon: icone,
                draggable:true,     
            }).addTo(mapa)
            marker.on('dragstart', () => { this.mostraCirculo = false})
            marker.on('dragend', this.alteraLatLang)
            Object.defineProperty(marker, 'ponto', {value:true})
            Object.defineProperty(marker, 'infoPonto', {value:ponto, writable:true})
            var popup = this.criaPopupPonto(ponto, editar)
            marker.bindPopup(popup, {
                autoPan:false,
            })
            marker.openPopup()
            this.$nextTick(function () {
                this.$refs.mapaSimples.flyTo(latlag)
            });
            this.pontoNoMapa = marker
            this.raioCirculo = ponto.pparaio
            this.centroCirculo = latlag
            this.mostraCirculo = true
            return marker
        },

        /** 
         * @description Decide o ícone do marcador baseado no tipo do mesmo.
         * @param {string} potipo
         * @return {string} caminho até o ícone correto
         */
        defineIcone(potipo){
            const limpo = potipo.replace(/\s|\n/g, '')
            switch (limpo) {
                case 'C':
                    return 'img/markerPontoC.png'
                case 'E':
                    return 'img/markerPontoE.png'
                case 'P':
                    return 'img/markerPontoP.png'
                // se não tiver um tipo, volta o marcador padrão
                default:
                    return 'img/markerPonto.png'
            }
        },

        /**
         * @param {'C'|'P'|'E'} tipo
         * @return {string} "legenda" do tipo
         */
        tipoPonto(tipo){
            const limpo = tipo.replace(/\s|\n/g, '')
            switch (limpo) {
                case 'C':
                    return 'coleta'
                case 'P':
                    return 'referencia'
                case 'E':
                    return 'entrega'
            }
        },

        /**
         * @description faz a request que vai trazer todos os pontos dos
         * clientes selecionados.
         */
        getPontos(){
            let urlGet = `${this.url}get/pontos`
            let obj = {
                clientes: this.selectedEmpresas,
                search: this.popoverData.search,
                tipoItem: this.filtroTipoItem,
                tipoPonto: this.filtroTipoPonto,
                // clientes:this.optionsEmpresas.map((c)=> c.value)
            }
            this.loadingPanel = true
            this.statusBar = 'loading'
            new HttpRequest().Post(urlGet, obj)
                .then((resposta) => {
                    this.statusBar = 'info'
                    this.pontosPendentes = Object.values(resposta.data ?? [])
                    if(this.pontosPendentes.length)
                        this.montaPrimeiroPontoLista()
                    else
                        this.removeMarcadores()
                }).finally(()=>{
                    this.loadingPanel = false
                })
        },

        montaPrimeiroPontoLista() {
            let emp = this.pontosPendentes[0]
            let ponto = null
            if (emp.Pedidos) {
                ponto = emp.Pedidos[0]
            } else if (emp.Pontos) {
                ponto = emp.Pontos[0]
            }
            this.montaPonto(ponto, 0)
        },

        findItemPendente(objFind) {
            let ponto = null
            this.pontosPendentes.forEach(e => {
                if (!ponto) {
                    ponto = _.find(e.Pontos, objFind)
                    if (!ponto) {
                        ponto = _.find(e.Pedidos, objFind)
                    }
                }
            })
            return ponto
        },

        /**
         * @param {object} obj
         * @param {number} obj.pocodigo      - código do ponto.
         * @param {('C'|'E'|'P')} obj.potipo - tipo do ponto.
         * @description cria o marcador do ponto, do tipo div.
         * @returns {L.DivIcon} ícone do ponto.
         */
        criaDivIcon({ppacodigo, ppatipo}){
            var html = `
                <div id='${ppacodigo}' class='marcador-ponto-padrao'>
                    <div>
                        <img src='${this.defineIcone(ppatipo)}'>
                    </div>
                </div>`
            return L.divIcon({
                html: html,
                popupAnchor : [-3, -38],
                iconSize    : [0, 0],
                iconAnchor  : [20, 38],
                shadowAnchor: [0, 0]
            })
        },

        /**
         * @param {object} pontoPendente com as informações p/ montar a popup.
         * @param {number} pontoPendente.pparaio 
         * @param {string} pontoPendente.ppadescricao
         * @param {number} pontoPendente.ppacodigo
         * @param {string} pontoPrendete.ppatipo
         * @param {bolean} editar se a popup deve aparecer em modo de edição
         * @description cria a popup do ponto, com as informações corretas e 
         * com os eventos de edição/exclusão preparados.
         * @return $el da popupponto, pronto para dar bind com o marcado 
         */
        criaPopupPonto(pontoPendente, editar=false){
            var popupPonto = require('@/components/Atom/Painel/facilitadorPonto').default
            popupPonto = Vue.extend(popupPonto);
            popupPonto = new popupPonto({
                propsData:{
                    ponto:{
                        poraio:pontoPendente.pparaio,
                        podescricao: pontoPendente.ppadescricao,
                        pocodigo: pontoPendente.ppacodigo,
                        potipo: pontoPendente.ppatipo.replace(/\s|\n/g, ''),
                        poregiao: pontoPendente.redescricao,
                        poendereco: pontoPendente.ppaendereco,
                    },
                    permissoes:{
                        ppeditar:this.podeEditar,
                        ppexcluir:this.podeExcluir
                    },
                    eventoExclusao:false,
                    abreEmEditar: editar
                }
            })
            popupPonto.$mount()
                .$on('ponto-excluido', this.excluirPonto)
                .$on('editando-ponto', this.editaPonto)
                .$on('ponto-editado', this.confirmaEdicao)
                .$on('edit-raio', this.editaRaio)
            return popupPonto.$el
        },

        editaPonto(info){
            let tempo = 50
            setTimeout(()=>{
                this.pontoNoMapa.openPopup()
            }, tempo)
        },

        alteraLatLang() {
            var latlng = this.pontoNoMapa.getLatLng()
            let objFind = { ppacodigo: this.pontoNoMapa.infoPonto.ppacodigo }
            let ponto = this.findItemPendente(objFind)
            // var ponto = _.find(this.pontosPendentes, objFind)
            ponto.ppalatitude = latlng.lat
            ponto.ppalongitude = latlng.lng
            this.pontoNoMapa.infoPonto = ponto
            this.centroCirculo = [latlng.lat, latlng.lng]
            this.mostraCirculo = true
            this.salvaEdicao(ponto)
        },

        confirmaEdicao(pontoEditado) {
            let objFind = { ppacodigo: pontoEditado.pocodigo }
            let ponto = this.findItemPendente(objFind)
            // ponto = _.find(this.pontosPendentes, {ppacodigo: pontoEditado.pocodigo})
            ponto.ppadescricao = pontoEditado.podescricao
            ponto.ppaendereco = pontoEditado.poendereco
            ponto.pparaio = pontoEditado.poraio
            ponto.ppatipo = pontoEditado.potipo.value
            this.montaPonto(ponto, 0)
            this.salvaEdicao(ponto)
        },

        editaRaio(raio) {
            this.raioCirculo = raio
        },
        
        /**
         * @param {object} ppa com toas as informações do ponto pendente.
         * @description salva as edições feitas ao ponto no bando de dados.
         */
        salvaEdicao(ppa){
            let urlEdicao = `${this.url}edita/${ppa.ppacodigo}`
            new HttpRequest().Post(urlEdicao, {ponto:ppa}).then((resposta)=>{
                let novo_ponto = resposta.data.ponto;
                if(resposta.data.success){
                    let popup_atualizada = this.criaPopupPonto(novo_ponto)
                    this.pontoNoMapa.setPopupContent(popup_atualizada)
                }else{
                    this.$bvToast.toast(`erro ao salvar ponto ${ppa.ppadescricao}`, {
                        autoHideDelay: 3500,
                        variant: 'warning',
                    })
                    this.montaPonto(novo_ponto, 0)
                }
            })
        },
        
        excluirPonto(ppacodigo) {
            var urlExcluir = `${this.url}excluir/${ppacodigo}`
            this.loadingPanel = true
            new HttpRequest().Get(urlExcluir).then((resposta)=>{
                if(resposta.data.success){
                    this.removeMarcadores()
                    this.getPontos()
                }else{
                    conectionError()
                }
            }).finally(()=>{this.loadingPanel = false})
        },

        retornaTipo(tipo) {
            return tipo == "P" ? "R":tipo
        },

    },

    computed:{

        retornaMostrarCirulo() {
            if (!this.pontosPendentes.length) {
                return false
            }
            return this.mostraCirculo
        },
        
        podeEditar(){
            return this.getEditar()
        },

        podeExcluir(){
            return this.getExcluir()
        },
        
        podeCadastrar(){
            return this.getCadastrar()
        },

        pontosPendentesMostrar(){
            // let query = new RegExp(this.valorBuscaPontos, 'ig')
            // return query.test(ppa.ppadescricao) 
            return this.pontosPendentes
        },
    },
    mounted(){
		if(!this.getMaster()){
			this.optionsEmpresas.forEach(element => {
				this.selectedEmpresas.push(element.value)
            })
            this.onHidden()
            this.getPontos()
        }
    }
}
</script>

<style lang="scss">
#aprovacaoDePontos{
    --square :21px;
    .ponto-marcado{
        transition: .2s;
        background-color: rgba(62, 62, 62, 0.1);
    }
    .campo-busca-pontos{
        position: absolute;
        z-index: 300;
        background: white;
        padding: 5px;
        height: fit-content;
        .form-search{
            padding: 2px;
        }
        .spinner-border{
            height: var(--square);
            width: var(--square);
        }
    }
    .filtros-aprovacao{
        width: 200px;
        background: green;
    }
    table{
        tr{
            border: none;
            background: white;
            th{
                background: #efefef;
                border-bottom: 1px solid #e5e5e5;
            }
            td{
                vertical-align: middle;
            }
        }
        .acoes-pontos{
            content-align: middle;
            width: 100px;
            block-size: fit-content;
            background-color:white !important;
        }
    }
    .clicavel{
        cursor:pointer;
        background-color:white !important;
    }
    .marker-circle-coleta{
        width: 26px !important;
        height: 26px !important;
        border-radius: 50% !important;
        padding-top: 3px;
        align-content: center !important;
        margin: 4px 4px 4px 4px !important;
        right: 0 !important;
        background: rgb(255, 0, 0) !important;
        margin: 5px auto !important;
        text-align: center !important;
    }
    .marker-circle-entrega{
        width: 26px !important;
        height: 26px !important;
        border-radius: 50% !important;
        padding-top: 3px;
        align-content: center !important;
        margin: 4px 4px 4px 4px !important;
        right: 0 !important;
        background: #078f1f !important;
        margin: 5px auto !important;
        text-align: center !important;
    }
    .marker-circle-referencia{
        width: 26px !important;
        height: 26px !important;
        border-radius: 50% !important;
        padding-top: 3px;
        align-content: center !important;
        margin: 4px 4px 4px 4px !important;
        right: 0 !important;
        background: #00a3c6 !important;
        margin: 5px auto !important;
        text-align: center !important;
    }
    .centroMarker{
        border-radius: 50% !important;
        background: white !important;
        margin: 0px 5px 5px 3px !important;
        height: 20px;
        width: 20px;
        padding-top: 2px;
        font-size: 14px;
    }
    ul[class=resultados-busca]{
        list-style-type: none;
        display: table;
        margin: 0 auto;
        padding-left: 0px;
        width: 90%;
        li{
            decoration: none;
            margin: 1px;
            padding:1px;
            cursor: pointer;
            height: 25px;
            width: 263px;
            white-space: nowrap;
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            border: 1px solid black;
        }
    }
    .leaflet-marker-icon{
        width: 0 !important;
        height: 0 !important;
        margin-left: -18px !important;
        margin-top: -36px !important;
        border: none !important;
        background: none !important;
    }
}
</style>
