<template>
	<eaglePanel id='listagemPontos' :loading="loadingPanel">
		<div id='divBotoes' class="col-sm-12 divDontPrint nopadding">
			<div id='tituloPagina' class="col-sm-12 row">
				<div class="col-sm-4 nopadding" id='divTitulo'>
					<titulo titulo='Pontos' :icon='mdiMapMarkerRadius' />
				</div>
				<div class="col-sm-8 nopadding">
					<botoes tipoNovo="button" :loading='carregandoE' @exportarCadastro='exportarPontos'
						@novoCadastro="direcionaNovo" :disabledDropdown='$v.dataTabela.$invalid'>
						<!-- linkNew='cadastrarPonto' -->
						<template v-if="getEditar()">
							<SimpleButton event="click" @click="(v) => { $bvModal.show('modalReidentificarPontos') }"
								text='Reidentificar pontos' icon='' width="25%" type='green' typeButton='button' />
						</template>
						<template slot='right' v-if="getCadastrar()">
							<SimpleButton text='Importar' :icon='mdiCloudUpload' type='light-green' typeButton='button' event='click'
								@click="direcionaImportar" />
						</template>
					</botoes>
				</div>
			</div>
			<div class="col-sm-12">
				<hr>
			</div>
			<div class='col-sm-12 row nopadding '>
				<div class="col-sm-12 nopadding row" id='divMenu'>
					<div class="col-sm-4 nopadding" id='divSeletorDeEmpresa'>
						<selectAll nameForRadio='seletorEmpresa' :hasSelectAll="true" :isMultiple='true'
							:optionsArray='optSelectEmpresas' :selected="cliente" :labels='[{ description: "Empresa" }]'
							@changeSelect='mudaEmpresa' />
					</div>
					<div class="col-sm-4 nopadding">
						<bfilter @buttonsFiltersChange='mudaFiltro' :arrayButtons='botoesFiltro' />
					</div>
				</div>
			</div>
			<div class="col-sm-12">
				<hr>
			</div>
		</div>
		<div id='table' class="col-sm-12 nopadding">
			<tableL deleteEdit="editDelete" :data='dataTabela' :titles='titulosTabela' :loading='carregando'
				@excluirCadastro='excluirPonto' @editarCadastro='editarPonto'
				:paramFilters="['pocodigoexterno', 'podescricao', 'poendereco']" />
		</div>
		<modalReidentificarPontos />
	</eaglePanel>
</template>

<script>
import { mdiMapMarkerRadius, mdiCloudUpload } from '@mdi/js'
import { mapGetters } from 'vuex'
import botoes from '@/components/Atom/Buttons/BasicButtonsCadastrosEIN.vue'
import tableL from '@/components/Atom/Table/TableListagem.vue'
import eaglePanel from '@/components/Atom/Panel/PanelEagle.vue'
import titulo from '@/components/Atom/Header/Titulo.vue'
import SimpleButton from '@/components/Atom/Buttons/SimpleButton.vue'
import selectAll from '@/components/Atom/Select/SelectAll.vue'
import bfilter from '@/components/Atom/Buttons/ButtonsFilters.vue'
import modalReidentificarPontos from '@/components/Atom/ModalSpecific/modalReidentificarPontos.vue'

// import modalEagle from '@/components/Atom/Modal/ModalEagle.vue'
// import inputRangeWithLimit from '@/components/Atom/Datas/InputRangeWithLimit.vue'
// import progressBar from '@/components/Atom/SpecificComponents/progressBarReidentificarPontos.vue'
// // import { DateTime } from "luxon"
// import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { required } from 'vuelidate/lib/validators'
export default {
	name: 'ListagemPontos',
	components: {
		eaglePanel,
		titulo,
		botoes,
		SimpleButton,
		selectAll,
		bfilter,
		tableL,
		modalReidentificarPontos,
		// modalEagle,
		// progressBar,
		// // inputSingleDay,
		// inputRangeWithLimit,
		// // inputSimple,
	},

	validations: {
		dataTabela: {
			required,
		}
	},

	data() {
		return {
			// mdiCheckBold:mdiCheckBold,
			// mdiCloseThick:mdiCloseThick,
			mdiCloudUpload: mdiCloudUpload,
			mdiMapMarkerRadius: mdiMapMarkerRadius,
			loadingPanel: false,
			filtros: {
				empresa: [],
				tipo: []
			},
			optSelectEmpresas: [],
			botoesFiltro: [
				{
					'value': 'A',
					'width': '48%',
					'text': 'Todos',
					'isSelected': true
				},
				{
					'value': 'C',
					'width': '48%',
					'text': 'Coleta',
					'isSelected': false
				},
				{
					'value': 'E',
					'width': '48%',
					'text': 'Entrega',
					'isSelected': false
				},
				{
					'value': 'P',
					'width': '48%',
					'text': 'Referência',
					'isSelected': false
				}
			],
			titulosTabela: [
				{ 'number': 'pocodigoexterno', 'name': 'Código externo' },
				{ 'number': 'podescricao', 'name': 'Descrição' },
				{ 'number': 'potipo', 'name': 'Tipo' },
				{ 'number': 'poendereco', 'name': 'Endereço' },
				{ 'number': 'poraio', 'name': 'Raio (m)' },
				{ 'number': 'clfantasia', 'name': 'Empresa' }
			],
			dataTabela: [],
			rota: '/telemetria/cadastros/pontos/',
			carregando: false,
			carregandoE: [false, false, false],
			cliente: [],

			// optPontosModal:[],
			// laodingBtnModal:false,
			// valueEmpresaModal:[],
			// msgPontosInvalidos:false,
			// reidentificandoPontos:false,
			// // valores possiveis para cada index '0', '1', '2'
			// loadingReidentificarPontos:[0, 0, 0, 0],
			// diasSelecionadosModal:[],
			// periodoDias:false,
			// valueDataModal:'',
		}
	},
	methods: {
		...mapGetters(['getEditar', 'getMaster', 'getCadastrar']),
		/**
		 * Essa tela possui uma regra diferente e ela existe me dois modulos
		 * nesse caso para nao ser direcionado para o outro modulo ela faz a
		 * comparacao para saber qual tela ela precisa direcionar
		 * no link tem a tarefa escrita
		 * @link https://app.clickup.com/t/2ykugg1
		 */
		direcionaNovo() {
			if (this.$route.name === "listagemPontosWay") {
				this.$router.push({ name: 'cadastrarPontoWay' })
				return
			}
			this.$router.push({ name: 'cadastrarPonto' })
		},

		/**
		 * Essa tela possui uma regra diferente e ela existe me dois modulos
		 * nesse caso para nao ser direcionado para o outro modulo ela faz a
		 * comparacao para saber qual tela ela precisa direcionar
		 * no link tem a tarefa escrita
		 * @link https://app.clickup.com/t/2ykugg1
		 */
		direcionaImportar() {
			if (this.$route.name === "listagemPontosWay") {
				this.$router.push({ name: 'importarPontosWay' })
				return
			}
			this.$router.push({ name: 'importarPontos' })
		},


		exportarPontos(tipo) {
			let obj = {
				'tipo': tipo,
				'data': this.dataTabela
			}
			if (this.dataTabela) {
				this.exportando(tipo)
				new HttpRequest().Post(this.rota + 'exportar', obj)
					.then(data => {
						this.exportando()
						var root = process.env.VUE_APP_ROOT;
						window.open(root + '/' + data.data.local);
					})
			}

		},
		exportando(tipo = null) {
			if (tipo === 'pdf') {
				this.carregandoE = [true, false, false]
			} else if (tipo === 'xls') {
				this.carregandoE = [false, true, false]
			} else if (tipo === 'csv') {
				this.carregandoE = [false, false, true]
			} else {
				this.carregandoE = [false, false, false]
			}
		},
		mudaFiltro(value) {
			this.dataTabela = []
			this.filtros.tipo = value
			this.listarPontos()
		},
		mudaEmpresa(arr, value, description) {
			this.dataTabela = []
			this.filtros.empresa = arr
			this.listarPontos()
		},
		async listarPontos() {
			if (this.filtros.empresa.length > 0) {
				this.loadingPanel = true
				await new HttpRequest().Post(
					this.rota + 'listar', { ...this.filtros })
					.then(data => {
						this.carregando = false
						this.dataTabela = data.data.pontos
					})
				this.loadingPanel = false
			}
		},

		/**
		 * Essa tela possui uma regra diferente e ela existe me dois modulos
		 * nesse caso para nao ser direcionado para o outro modulo ela faz a
		 * comparacao para saber qual tela ela precisa direcionar
		 * no link tem a tarefa escrita
		 * @link https://app.clickup.com/t/2ykugg1
		 */
		editarPonto(ponto) {
			let rota = this.$route.name === "listagemPontosWay" ?
				"cadastrarPontoWay" :
				"cadastrarPonto"
			this.$router.push({ name: rota, params: { 'id': ponto } })
		},

		excluirPonto(codigo) {
			new HttpRequest().Post(
				this.rota + 'excluir', { 'codigo': codigo })
				.then(() => {
					this.listarPontos()
				})
		}
	},
	mounted() {
		this.optSelectEmpresas = new EmpresasService().Get()
		if (!this.getMaster())
			this.cliente = this.optSelectEmpresas
	},
}
</script>

<style lang="scss" scoped>
// .reidentificar-pontos{
//   border:1px solid rgba(0, 0, 0, 0.253);
//   min-height: 100px;
//   padding:10px;
//   .fundo-azul{
//     max-height: 20px;
//     background-color: #428bca;
//     color: #fff;
//   }
// }
</style>
