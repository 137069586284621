<template>
    <panelEagle id='Registrar' :loading='gerandoRel'>
        <div class="col-sm-12 nopadding row">
            <div class="col-sm-6 ">
                <tituloPage 
                    :icon='mdiGasStation' 
                    titulo='Registrar'/>
            </div>
            <div class="col-6 divDontPrint">
                <basicButtonsCadastrosEIN
                    :loading="loadingBtn"
                    linkNew ='registrarNovoFuel'
                    tipoNovo ='button'
                    :disabledDropdown='$v.dadosTabela.$invalid'
                    @exportarCadastro="exportarRelatorio"
                    />
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class='col-sm-12 row nopadding'>
            <div class="col-sm-4 nopadding divDontPrint">
                <selectAll
                    nameForRadio  ='Empresas'
                    :isMultiple   ='true'
                    :optionsArray ='optEmpresas'
                    :selected="selectedEmpresa"
                    :labels       ='labelsEmp'
                    :hasSelectAll ='true'
                    @changeSelect ='changeEmpresa'/>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class="col-12 nopadding">
            <tableListagem
                :data = 'dadosTabela'
                :titles ='titles' 
                deleteEdit ='editDelete'
                @excluirCadastro='excluirCadastro'
                @editarCadastro='editarCadastro'>
            </tableListagem>
        </div>
    </panelEagle>
</template>
<script>

import Vue  from 'vue'
import { mdiGasStation } from '@mdi/js'
import { HttpRequest } from '../../../../Services/auth/HttpRequest.Service'
import { EmpresasService } from '../../../../Services/auth/Empresas.service'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
export default Vue.extend({
	name: 'Registrar',
	components:{
		'panelEagle'               : require('@/components/Atom/Panel/PanelEagle')
			.default,
		'basicButtonsCadastrosEIN' : 
			require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
		'tituloPage'               : require('@/components/Atom/Header/Titulo').default,
		'selectAll'                : 
			require('@/components/Atom/Select/SelectAll').default,
		'tableListagem'            : 
			require('@/components/Atom/Table/TableListagem').default,
	},

	validations:{
		dadosTabela:{ required },
	},

	data(){
		return {
			loadingBtn:[false, false, false],
			mdiGasStation:mdiGasStation,
			titles :[
				{'number':'veiculo'    , 'name':'Veiculo'},
				{'number':'colaborador', 'name':'Colaborador'},
				{'number':'empresa'    , 'name':'Empresa'},
				{'number':'data'       , 'name':'Data'},
				{'number':'tipo'       , 'name':'Tipo combustível'},
			],
			optEmpresas : new EmpresasService().Get(),
			selectedEmpresa:[],
			dadosTabela : [],
			labelsEmp   :[{
				indexDFH    : 'SE',
				description : 'Empresas'
			}],
			uriBase : '/abastecimento/menu/registrar/',
			valorEmpresas   : [],
			gerandoRel: false,
		}
	},
	methods:{
		...mapGetters(['getMaster']),

		exportarRelatorio(value){
			if(this.loadingBtn.find(v=> v == true)){
				return
			}
			if(this.dadosTabela.length != 0){
				switch (value) {
					case 'pdf':
						this.loadingBtn = [true, false, false]
						break;
					case 'xls':
						this.loadingBtn = [false, true, false]
						break;
					default:
						this.loadingBtn = [false, false, true]
						break;
				}
				var root =  process.env.VUE_APP_ROOT;
				let uri = this.uriBase+'exportar'
				let obj = {data:this.dadosTabela, para:value}
				new HttpRequest().Post(uri, obj)
					.then(data=>{
						this.loadingBtn = [false, false, false]
						open(root+'/'+data.data.local)
					})
			}
		},
		async excluirCadastro(value){
			var dados = await new HttpRequest()
				.Post('/abastecimento/menu/registrar/deletar', value)
			var a = this.changeEmpresa(this.valorEmpresas)
		},
		editarCadastro(value){
			this.$router.push({name:'registrarNovoFuel', params:{id:value}})
		},
		changeEmpresa(value){
			this.valorEmpresas = value
			this.dadosTabela = []
			if(value.length){
				this.gerandoRel = true
				new HttpRequest()
					.Post('/abastecimento/menu/registrar/tabela', value)
					.then((data)=>{
						this.dadosTabela = data.data.tabela
						this.gerandoRel = false
					})
			}
		}
	},
	mounted() {
		if(!this.getMaster()){
			this.selectedEmpresa = this.optEmpresas
		}
	},
})
</script>
<style lang="scss" scoped>
    
</style>
