<template>
    <div id='painel-coletivos-mapa'>
		<div>
			<filtros-painel-coletivo
			:clienteSelecionado='clienteSelecionado'
			@muda-cliente='mudaCliente'
			@filtro-usuario='updateFiltroUsuario'
			@filtros-coletivos='procuraLinhas'
			@fecha-info="$emit('fecha-info')"/>
		</div>
		<div v-if='Object.keys(tableData).length'>
			<el-table
			:max-height='tamTable'
			:data='dadosFiltrados'
			:border='true'
			:row-style='tableRowStyle'
			@select-all='selecionaTudo'
			@select='selecionaLinha'>
				<el-table-column
				type='selection'/>
				<el-table-column
				:sortable='true'
				width='90'
				align='center'
				label='Placa'
				prop='placa'/>
				<el-table-column
				:sortable='true'
				align='center'
				width='90'
				label='Prefixo'
				prop='prefixo'/>
				<el-table-column
				:sortable='true'
				width='90'
				align='center'
				label='Horário'
				prop='horarioProgramado'/>
				<el-table-column
				:sortable='true'
				align='center'
				label='Inicio'
				width='90'
				prop='rotaHoraInicio'/>
				<el-table-column
				:sortable='true'
				align='center'
				width='200'
				resizable
				label='Linha'
				:show-overflow-tooltip='true'
				prop='descLinha'/>
				<el-table-column
				:sortable='true'
				align='center'
				label='Colaborador'
				width='125'
				:show-overflow-tooltip='true'
				prop='motorista'/>
				<el-table-column
				:sortable='true'
				align='center'
				width='100'
				label='Escala'
				:show-overflow-tooltip='true'
				prop='descEscala'/>
				<el-table-column
				width='100'
				align='center'
				label='Progresso'
				:show-overflow-tooltip='true'
				prop='progresso'>
					<template slot-scope="{row}">
						<b-progress height='25px'>
							<b-progress-bar
							:label="`${row.progresso.toFixed(2)}%`"
							:value='row.progresso'
							:title='`${row.progresso}%`'
							max='100'
							animated/>
						</b-progress>
					</template>
				</el-table-column>
				<el-table-column
				:sortable='true'
				width='98'
				align='center'
				label='Ponto'
				:show-overflow-tooltip='true'
				prop='descPonto'/>
				<el-table-column
				width='93'
				:sortable='true'
				align='center'
				label='Tempo'
				:show-overflow-tooltip='true'
				prop='tempo'/>
				<el-table-column
				align='center'
				:sortable='true'
				width='110'
				label='Distância'
				:formatter='formataDistancia'
				:show-overflow-tooltip='true'
				prop='distancia'/>
				<el-table-column
				width='70'
				label='Status'>
					<template slot-scope='{row}'>
						<div
						class='d-flex justify-content-center'>
							<div :class='`${row.statusLinha} circle`' />
						</div>
					</template>
				</el-table-column>
				<el-table-column
				align='center'
				label='Ações'
				prop=''>
					<template slot-scope="{row}">
						<span
						title='Trocar veículo da rota'
						class='botao-acoes-coletivos'
						@click='clicaRow(row)'>
							<base-icon
							size='18'
							:icon='iconAcoes'/>
						</span>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div v-else>
			<status-information
			typeBar='div'
			:statusBar='info'
			:msgCentro='msgCentro'/>
		</div>
    </div>
</template>

<script>
import FiltrosPainelColetivo from
	'@/components/Atom/Painel/Coletivos/FiltrosPainelColetivo.vue'
import StatusInformation
	from '@/components/Atom/StatusInformation/StatusInformation.vue'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import {mdiCarSide} from '@mdi/js'
import BaseIcon from '@/components/Atom/Icon/BaseIcon.vue'
import { conectionError } from '@/Services/Helpers/swellHeper';
export default {
	name:'painelColetivo',
	components:{
		FiltrosPainelColetivo,
		StatusInformation,
		BaseIcon,
	},
	props:{
		clienteSelecionado:{
			Type: Array,
			required:true
		}
	},
	data(){
		return {
			tamTable:200,
			userQ:'',
			urlBase:'/finder/coletivos/',
			iconAcoes:mdiCarSide,
			tableData:[],
			itensRota:[],
			info:'info',
			msgCentro:'Selecione uma empresa',
			filtros:{},
			tempoIntervalo:30000
		}
	},
	methods:{
		mudaCliente(v){
			this.$emit('muda-cliente', v)
        },
        
		atualizarColetivos(){
			var uri = `${this.urlBase}`
			new HttpRequest().Post()
		},

		/**
		 * @param {object} data 
		 * @param {number} data.linha - código da linha
		 * @param {object} data.info
		 * @param {number} data.info.rccodigohorario
		 * @param {string} data.info.listringrota
		 * @description emite a linha para o mapa, já com
		 * os campos prontos para ser só desenhada no mapa.
		 * @fires linha-coletivo
		 */
		emiteLinhaColetivo(data){
			var itens = this.itensRota[data.linha]
			var linha = this.tableData.find((d)=>{
				return d.codigoRota === data.linha
			})
			var obj = {
				'poli':data.info.listringrota,
				'rccodigohorario': data.info.rccodigohorario,
				'rccodigo':data.linha,
				'rccor': linha.corLinha,
				'itens': itens.map(e=>{
					return {
						...e,
						rccodigo:data.linha
					}
				})
			}
			this.$emit('linha-coletivo', obj)
			if(data.rastroVeiculo.length < 3)
				this.emiteRastroVeiculo(data)
			if(data.desviosRota.length)
				this.emiteDesvioDeRota(data)
		},
		/**
		 * @listens select-all
		 * @todo fazer funcionar
		 */
		selecionaTudo(selected){
			if(selected.length){
				var arrEnviar = selected.map(s=>{
					return{
						codigo:s.codigoRota,
						modulo:s.modulo
					}
				})
				var uri = `${this.urlBase}bulk/linhas`
				new HttpRequest().Post(uri, {linhas:arrEnviar})
					.then((data)=>{
						data.data.linhas.forEach(this.emiteLinhaColetivo)
					})
			}else{
				this.$emit('remove-todos-coletivos')
			}
		},

		/**
		 * @listens select
		 * @param {array} all
		 * @param {object} clicked 
		 * @param {number} clicked.codigoRota
		 * @param {number} clicked.modulo
		 * @param {string} clicked.corLinha
		 * @description procura pela poli no back-end e 
		 * envia para o mapa desenhar.
		 * @author Gui 🍺
		 */
		selecionaLinha(all, clicked){
			if(all.includes(clicked)){
				var uri = `${this.urlBase}rastro/linha`
				var obj = {
					codigo:clicked.codigoRota,
					modulo:clicked.modulo
				}
				new HttpRequest().Post(uri, obj).then((data)=>{
					if(data.data?.info){
						var itens = this.itensRota[clicked.codigoRota]
						this.$emit('linha-coletivo', {
							'poli':data.data.info.info.listringrota,
							'rccodigohorario':
								data.data.info.info.rccodigohorario,
							'rccodigo':clicked.codigoRota,
							'rccor':clicked.corLinha,
							'itens': itens.map(e=>{
								return {
									...e,
									rccodigo:clicked.codigoRota
								}
							})
						})
						if(data.data.info.rastroVeiculo.length > 3)
							this.emiteRastroVeiculo(data.data.info)

						if(data.data.info.desviosRota.length)
							this.emiteDesvioDeRota(data.data.info)
					}
				})
			}else{
				this.$emit('remove-linha-coletivo', clicked.codigoRota)
			}
		},

		/**
		 * @param {object} data
		 * @param {object[]} data.desviosRota
		 * @param {number}   data.desviosRota[].codigo - código desvio
		 * @param {number[]} data.desviosRota[].latlng
		 * @param {number}   data.linha - código da linha
		 */
		emiteDesvioDeRota(data){
			var obj = {
				latlng:data.desviosRota,
				codigo:data.linha,
			}
			this.$emit('desvio-rota-coletivo', obj)
		},

		/**
		 * @param {object} 	   data 
		 * @param {number} 	   data.linha - código da rota
		 * @param {number[][]} data.rastroVeiculo
		 * @fires rastro-veiculo-coletivo
		 */
		emiteRastroVeiculo(data){
			var linha = this.tableData.find((d)=>{
				return d.codigoRota === data.linha
			})
			var obj = {
				cor    : linha.corLinha,
				codigo : linha.codigoRota,
				latlng : data.rastroVeiculo.map(pos=>{
					return pos.reverse()
				}),
			}
			this.$emit('rastro-veiculo-coletivo', obj)
		},

		/**
		 * @param {number} obj.distancia
		 * @return {string} distancia.0 km
		 */
		formataDistancia({distancia}){
			return `${distancia.toFixed(1)} km`
		},

		/**
		 * @listens filtros-coletivos
		 * @param {object}    newFiltros
		 * @param {number[]}  newFiltros.clientes
		 * @param {string}    newFiltros.data - dd/mm/yyyy
		 * @param {string[]}  newFiltros.statusRota
		 * @description faz a busca no back-end caso exista pelo menos uma
		 * empresa selecionada, e já atribui os retornos as variáveis certas.
		 * @author Gui 🍺
		 */
		procuraLinhas(newFiltros){
			this.$emit('remove-todos-coletivos')
			this.filtros = newFiltros
			this.tableData = []
			if(this.filtros.clientes.length){
				var uri = `${this.urlBase}consultar/linhas`
				this.info = 'loading'
				new HttpRequest().Post(uri, this.filtros).then((data)=>{
					this.tableData = data.data?.coletivos ?? []
					this.itensRota = data.data?.itens ?? []
				}).finally(()=>{
					if(this.tableData.length) this.info = 'info'
					else {
						this.info = 'error'
						setTimeout(()=>this.info= 'info', 6660)
					}
				})
			}
		},

		/**
		 * @param {object}   newQ
		 * @param {number[]} newQ.clientes
		 * @param {string}   newQ.data - dd/mm/yyyy
		 * @param {string[]} newQ.statusRotas
		 * @description atualiza os filtros aplicados
		 * as informações exibidas.
		 */
		updateFiltroUsuario(newQ){
			this.userQ = newQ
		},
	
		/**
		 * @param {object} object
		 * @param {object} object.row
		 * @param {string} object.row.corLinha - hexadeciaml
		 * @return {object} estilo da linha da tabela.
		 */
		tableRowStyle({row}){
			if(!row.corLinha) return
			if(this.wc_hex_is_light(row.corLinha)){
				return  { 
					'background-color':row.corLinha,
					'color':'black'
				}
			}else{
				var bgcolor = this.rgbaColor(row.corLinha)
				return {
					'background-color': bgcolor,
					'color': 'black'
				}
			}
		},

		/**
		 * @param {string} color - cor em hexa
		 * @return {string} rgba - a cor no formato rgba
		 */
		rgbaColor(color){
			const hex = color.replace('#', '')
			const c_r = parseInt(hex.substr(0, 2), 16)
			const c_g = parseInt(hex.substr(2, 2), 16)
			const c_b = parseInt(hex.substr(4, 2), 16)
			return `rgba(${c_r}, ${c_g}, ${c_b}, 0.4)`
		},
    
		/**
		 * @param {string} color - cor em hexa
		 * @returns {boolean} Se a cor é clara dms
		 */
		wc_hex_is_light(color){
			if(color === undefined) color = '#ffffff'
			const hex = color.replace('#', '')
			const c_r = parseInt(hex.substr(0, 2), 16)
			const c_g = parseInt(hex.substr(2, 2), 16)
			const c_b = parseInt(hex.substr(4, 2), 16)
			const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000
			return brightness > 155
		},

		/**
		 * @param {object} row
		 * @param {string} row.placa 
		 * @param {string} row.prefixo
		 * @param {string} row.descLinha
		 * @param {string} row.motorista
		 * @param {string} row.descEscala
		 * @param {string} row.descPonto
		 * @param {RegExp} regex
		 * @description verifica se algum dos campos da
		 * linha batem com a regex que foi passado
		 * @return {boolean}
		 */
		filtraTableData(row, regex){
			if(row?.placa.match(regex)) return true
			if(row?.prefixo.match(regex)) return true
			if(row?.descLinha) //🙄
				if(row.descLinha.match(regex)) return true
			if(row?.motorista.match(regex)) return true
			if(row?.descEscala.match(regex)) return true
			if(row?.descPonto.match(regex)) return true
			return false
		},

		clicaRow() {
			conectionError('Operação indisponível!')
		},
	},
	computed: {
		dadosFiltrados(){
			var regex = new RegExp(this.userQ, 'i')
			return this.tableData.filter(e=>{
				return this.filtraTableData(e, regex)
			})
		}
	},
}
</script>

<style lang='scss'>
#painel-coletivos-mapa{
	.botao-acoes-coletivos{
		cursor: pointer;
	}
	.progress-bar{
		color:black;
		font-size: 13px;
	}
	.circle{
		border: 2px solid white;
		height: 20px;
		width: 20px;
		border-radius: 50%;
	}
	.bgreen{
		background-color: #90ee90;
	}
	.bgrey{
		background-color: gray;
	}
	.breg{
		background-color: red;
	}
	.byellow{
		background-color: yellow;
	}
	.el-table{
		margin-bottom: 0px;
	}
}

</style>