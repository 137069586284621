<template>
    <panelEagle id='Perfil-de-acesso' :loading='loadingPanel'>
        <div class="col-sm-12 nopadding row">
            <div class="col-sm-12 col-md-12 col-lg-6">
                <tituloPage
                :icon='mdiShieldAccount'
                titulo='Perfil de acesso'></tituloPage>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6">
                <basicButtonsCadastrosSC
                    :disabled='$v.$invalid'
                    linkCancel="perfilAcesso"
                    tipoCancela='button'
                    @salvarCadastro="salvarCadastro">
                </basicButtonsCadastrosSC>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class='col-sm-12 row nopadding'>
            <div class="col-lg-3 nopadding">
                <selectAll
                    :extraClass='{
                        "border border-danger":$v.valueEmpresaUnica.$anyError}'
                    @close='$v.valueEmpresaUnica.$touch()'
                    :labels="[{indexDFH:'EM', description: 'Empresas*'}]"
                    nameForRadio='selectAll'
                    :isMultiple='false'
                    @changeSelect='changeEmpresaUnica'
                    :selected ="empresaUnicaSelected"
                    ref='refEmpresaUnica'
                    :optionsArray="optionsSelectEmpresaUnica"/>
            </div>
            <div class="col-lg-3 nopadding">
                <inputSimple
                    :inputClass='{
                        "border border-danger":$v.valueDescricao.$anyError
                    }'
                    @blur='$v.valueDescricao.$touch()'
                    :value="valueDescricao"
                    @changeInput='changeInputDescricao'
                    label="Descrição*"/>
            </div>
            <div class="col-lg-3 nopadding">
                <b-form-checkbox
                    @change="changeCheckBoxEmpresaMultipla"
                    class="alinhar-left ml-3"
                    id="checkbox-1"
                    v-model="checkbox"
                    name="checkbox-1"
                    :value="false"
                    :unchecked-value="true">
                    Multiempresa
                </b-form-checkbox>
                <selectAll
                    ref='refEmpresaMultipla'
                    :disabled="checkbox"
                    nameForRadio='selectAll'
                    :hasSelectAll='true'
                    :isMultiple='true'
                    :selected="empresaMultiplaSelected"
                    @changeSelect='changeEmpresaMultipla'
                    :optionsArray="optionsSelectEmpresaMultipla"/>
            </div>
            <div class="col-lg-3 nopadding">
                <buttonsFilters
                    ref='refButtonsFilters'
                    :zeroValue="['0']"
                    :arrayButtons='arrayButtons'
                    @buttonsFiltersChange='buttonsFiltersChange'/>
            </div>
        </div>
        <br>
        <div class="col-sm-12 row" v-show="mostraLoading">
            <div class="col-sm-12">
                <statusInformation
                    typeBar='div'
                    statusBar='loading'
                />
            </div>
        </div>
        <div class="col-sm-12 row nopadding">
            <div
            class="col-sm-12 row tamanho-minimo nopadding"
            v-show="mostrarModulos">
                <div
                class="col-sm-12 col-md-12 col-lg-3 sem-borda tamanho-minimo">
                    <b-card-group deck class="tamanho-minimo">
                        <!-- parte onde seleciona os modulos para aparecer aquela tabela do lado -->
                        <b-card no-body header="Módulos">
                            <b-list-group flush class="border-none tamanho-maximo">
                                <b-list-group-item v-for="(dados, index) in dadosModulos" :key="index" class="text-left border-none">
                                    <simpleButton
                                        :text='index'
                                        extraClass='sumir-botao'
                                        :value='index'
                                        event='click'
                                        @click="clicaBotaoModulos(dados, index)"/>
                                        <b-icon :icon='dados.clicado == true ? "chevron-double-right": ""' class="icone"/>
                                </b-list-group-item>
                            </b-list-group>
                        </b-card>
                    </b-card-group>
                </div>
                <div class="col-md-12 col-lg-8 estilo-tabela tamanho-corpo-tabela nopadding">
                    <table class="tamanho-tabela ">
                        <!-- tabela com os checkbox formulario -->
                        <thead>
                            <tr>
                                <th class="th-tabela" align="left" colspan="4">Opções</th>
                                <th class="th-tabela" colspan="2">
                                    <b-form-checkbox 
                                        @change="v => changeCheckboxFiltro('ppvisualizar', v)"
                                        id="checkbox-Visualizar"
                                        v-model="FiltroStatusVisualizar"
                                        name="checkbox-Visualizar"
                                        :value="true"
                                        :unchecked-value="false"
                                        class="pl-0">
                                        Visualizar
                                    </b-form-checkbox>
                                </th>
                                <th class="th-tabela" align="left" colspan="2">
                                    <b-form-checkbox
                                        @change="v => changeCheckboxFiltro('ppcadastrar', v)"
                                        id="checkbox-Cadastrar"
                                        v-model="FiltroStatusCadastrar"
                                        name="checkbox-Cadastrar"
                                        :value="true"
                                        :unchecked-value="false"
                                        class="pl-0">
                                        Cadastrar
                                    </b-form-checkbox>
                                </th>
                                <th class="th-tabela" align="left" colspan="2">
                                    <b-form-checkbox
                                        @change="v => changeCheckboxFiltro('ppeditar', v)"
                                        id="checkbox-Editar"
                                        v-model="FiltroStatusEditar"
                                        name="checkbox-Editar"
                                        :value="true"
                                        :unchecked-value="false"
                                        class="pl-0">
                                        Editar
                                    </b-form-checkbox>
                                </th>
                                <th class="th-tabela" align="left" colspan="2">
                                    <b-form-checkbox
                                        @change="v => changeCheckboxFiltro('ppexcluir', v)"
                                        id="checkbox-Excluir"
                                        v-model="FiltroStatusExcluir"
                                        name="checkbox-Excluir"
                                        :value="true"
                                        :unchecked-value="false"
                                        class="pl-0">
                                        Excluir
                                    </b-form-checkbox>
                                </th>
                            </tr>
                        </thead>
                        
                        <tbody>
                            <!-- os checkbox para as permi -->
                            
                            <template v-for="(dados, index) in tabelaGrande">
                                <tr :key="index">
                                    <td v-if="index != 'clicado'" colspan="12" align="left" class="th-tabela td-titulo">{{index}}</td>
                                </tr>
                                <template v-for="(menus, indice) in dados">
                                    <!-- essas sao as linhas da tabela -->
                                    <tr :key="'linhasTabela_'+menus.picodigo" class="tr-conteudo">
                                        <td class="pl-0" colspan="4">{{menus.pidescricao}}</td>
                                        <td class="pl-3" align="left" colspan="2">
                                                <b-form-checkbox v-if="menus.ppvisualizar != null && (menus.pidescricao != 'Parâmetros' || isMaster)"
                                                :id="indice+menus.picodigo+
                                                    menus.pidescricao+
                                                    'visualizar'"
                                                v-model="menus.ppvisualizar"
                                                name="checkbox-visualizar-perm"
                                                :value="true"
                                                @change="v => changeCheckBoxTabela(v, indice, 'ppvisualizar', menus.pidescricao, index)"
                                                :unchecked-value="false">
                                            </b-form-checkbox>
                                            <div v-else class="fazer-risco">
                                                <div class=""></div>
                                            </div>
                                        </td>
                                        <td class="pl-3" align="left" colspan="2">
                                            <b-form-checkbox v-if="menus.ppcadastrar != null && (menus.pidescricao != 'Parâmetros' || isMaster)"
                                                :disabled='menus.desabilitar'
                                                :id="indice+menus.picodigo+
                                                    menus.pidescricao+
                                                    'cadastrar'"
                                                v-model="menus.ppcadastrar"
                                                name="checkbox-cadastrar-perm"
                                                :value="true"
                                                @change="v => changeCheckBoxTabela(v, indice, 'ppcadastrar', menus.pidescricao, index)"
                                                :unchecked-value="false">
                                            </b-form-checkbox>
                                            <div v-else class="fazer-risco">
                                                <div class=""></div>
                                            </div>
                                        </td>
                                        <td class="pl-1" align="left" colspan="2">
                                            <b-form-checkbox v-if="menus.ppeditar != null && (menus.pidescricao != 'Parâmetros' || isMaster)"
                                                :disabled='menus.desabilitar'
                                                :id="indice+menus.picodigo+
                                                    menus.pidescricao+
                                                    'editar'"
                                                v-model="menus.ppeditar"
                                                name="checkbox-editar-perm"
                                                :value="true"
                                                @change="v => changeCheckBoxTabela(v, indice, 'ppeditar', menus.pidescricao, index)"
                                                :unchecked-value="false">
                                            </b-form-checkbox>
                                            <div v-else class="fazer-risco">
                                                <div class=""></div>
                                            </div>
                                        </td>
                                        <td class="pl-2" align="left" colspan="2">
                                            <b-form-checkbox v-if="menus.ppexcluir != null && (menus.pidescricao != 'Parâmetros' || isMaster)"
                                                :disabled='menus.desabilitar'
                                                :id="indice+menus.picodigo+
                                                    menus.pidescricao+
                                                    'excluir'"
                                                v-model="menus.ppexcluir"
                                                name="checkbox-excluir-perm"
                                                :value="true"
                                                @change="v => changeCheckBoxTabela(v, indice, 'ppexcluir', menus.pidescricao, index)">
                                            </b-form-checkbox>
                                            <div v-else class="fazer-risco">
                                                <div class=""></div>
                                            </div>
                                        </td>        
                                    </tr>
                                </template>
                            </template>
                             <tr>
                                <td colspan="12" class="th-tabela td-titulo alinha-tamanho"/>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <br>
        <div class="col-sm-12 row container nopadding">
            <b-form-checkbox
                @change="changeCheckBoxVeiculos"
                class="alinhar-left-bastante col-sm-12"
                id="checkbox-2"
                v-model="checkboxVeiculos"
                name="checkbox-2"
                :value="true"
                :unchecked-value="false">
                Selecionar veículos que o perfil <span class="cor-vermelha">NÃO</span> terá acesso
            </b-form-checkbox>
            <!-- <div class="col-sm-12 espaco"></div> -->
            <div class="col-md-6 col-sm-12 col-lg-3 mt-3 nopadding">
                <selectAll
                    @changeSelect="changeGrupoVeiculo"
                    ref='refGrupoVeiculo'
                    :labels="[{indexDFH:'GV', description: 'Grupo de veículos'}]"
                    :disabled="!checkboxVeiculos"
                    nameForRadio='selectAll'
                    :hasSelectAll='false'
                    :isMultiple='false'
                    :optionsArray="optGrupoVeiculos"/>
            </div>
            <div class="col-md-6 col-sm-12 col-lg-9 mt-3 nopadding">
                <selectAll
                    :selected='selectedVeiculos'
                    @changeSelect="changeVeiculo"
                    ref='refVeiculo'
                    :labels="[{indexDFH:'V', description: 'Veículos'}]"
                    :disabled="!checkboxVeiculos"
                    nameForRadio='selectAll'
                    :hasSelectAll='true'
                    :isMultiple='true'
                    :optionsArray="optVeiculos"/>
            </div>
            <div class="col-sm-12 pl-0">
                <requiredFields/>
            </div>
            <div class="col-sm-12"><hr></div>
        </div>
    </panelEagle>
</template>
<script lang='js'>
    import Vue  from 'vue'
    import Vuex from '@/store/index'
    import {mdiShieldAccount, mdiCheckBold} from '@mdi/js'
    import { required, minLength } from 'vuelidate/lib/validators'
    import { EmpresasService } from '@/Services/auth/Empresas.service'
    import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
    import { mapGetters } from 'vuex'
    import { mapActions } from 'vuex'
    import {MenuComponent} from '@/components/Atom/Menu/Menu.ts'
    export default Vue.extend({
        name:'CadastrarPerfilDeAcesso',
        components:{
            'panelEagle'               : require('@/components/Atom/Panel/PanelEagle').default,
            'requiredFields'           : require('@/components/Atom/Footer/RequiredFields').default,
            'tituloPage'               : require('@/components/Atom/Header/Titulo').default,
            'selectAll'                : require('@/components/Atom/Select/SelectAll').default,
            'basicButtonsCadastrosSC'  : require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
            'inputSimple'              : require('@/components/Atom/Inputs/InputSimple').default,
            'buttonsFilters'           : require('@/components/Atom/Buttons/ButtonsFilters').default,
            'simpleButton'             : require('@/components/Atom/Buttons/SimpleButton').default,
            'statusInformation'        : require('@/components/Atom/StatusInformation/StatusInformation').default,
        },
        validations:{
            valueDescricao    : {required},
            valueEmpresaUnica : {required},
        },
        data(){
            return {
                mdiCheckBold:mdiCheckBold,
                mdiShieldAccount:mdiShieldAccount,
                id:null,
                empresaUnicaSelected:[],
                empresaMultiplaSelected:[],
                valueDescricao:'',
                selectedVeiculos:[],

                mostraLoading:false,
                mostrarModulos:false,
                refButtonsFilters:'',
                loadingPanel:false,

                FiltroStatusCadastrar:false,
                FiltroStatusVisualizar:false,
                FiltroStatusEditar:false,
                FiltroStatusExcluir:false,
                filtrosFacilitador : [],

                dadosModulos:[],
                checkbox:true,
                checkboxVeiculos:false,
                optionsSelectEmpresaUnica: new EmpresasService().Get(),
                optionsSelectEmpresaMultipla: new EmpresasService().Get(),
                arrayButtons: [
                    {
                        value: '1', 
                        width: '100%', 
                        isSelected: false, 
                        extraClass: 'botao-preto', 
                        title: '', 
                        text: 'Acesso total', 
                        icon: ''
                    },
                    {
                        value: '2', 
                        width: '100%', 
                        isSelected: false, 
                        extraClass: 'botao-preto', 
                        title: '', 
                        text: 'Só visualizar', 
                        icon: ''
                    },
                    {
                        value: '3', 
                        width: '100%', 
                        isSelected: false, 
                        extraClass: 'botao-preto', 
                        title: '', 
                        text: 'Menos excluir', 
                        icon: ''
                    },
                ],
                totalModul:'',
                totalModulosCad:'',
                totalModulosEdit:'',
                totalModulosEx:'',
                salvarClickAnterior:'',
                tabelaGrande:[],
                valueEmpresaUnica:[],
                valueEmpresaMultipla:[],
                optGrupoVeiculos:[],
                optVeiculos:[],
                isMaster: false,
                modulos: Vuex.getters.getModulos,
            }
        },
        methods:{
            ...mapGetters(['getMaster', 'getUser']),
            ...mapActions(['updatePermissions']),
            changeCheckBoxEmpresaMultipla(){
                this.valueEmpresaMultipla = []
                this.$refs.refEmpresaMultipla.clearAll()
            },
            changeCheckBoxVeiculos(){
                this.$refs.refVeiculo.clearAll()
                this.$refs.refGrupoVeiculo.clearAll()
                this.valueGrupoVeiculos = []
                this.valueVeiculos = []
            },
            changeVeiculo(value){
                this.valueVeiculos = value
            },
            changeInputDescricao(value){
                this.valueDescricao = value
            },
            async changeGrupoVeiculo(value){
                if(value.length > 0){
                    var dados = await new HttpRequest()
                        .Post('/administrativo/cadastros/perfil/acesso/grupos/veiculos/select', {'dados': value[0]}) 
                    var veiculoSelecionado = this.selectedVeiculos
                    for(const veiculos in dados.data){
                        var adicionarVeiculo = true
                        for(const index in this.selectedVeiculos){
                            if(this.selectedVeiculos[index].value == dados.data[veiculos].value){
                                adicionarVeiculo = false
                                break
                            }else{
                                adicionarVeiculo = true
                            }
                        }
                        if(adicionarVeiculo){
                            veiculoSelecionado.push(dados.data[veiculos]) 
                        }
                    }
                    this.selectedVeiculos = veiculoSelecionado
                }
            },
            async changeEmpresaUnica(value){
                this.loadingPanel = true
                this.mostrarModulos = false
                this.tabelaGrande = []
                this.valueEmpresaUnica = value
                this.valueVeiculos      = []
                this.valueGrupoVeiculos = []
                this.$refs.refVeiculo.clearAll()
                this.$refs.refGrupoVeiculo.clearAll()
            
                this.buscaVeiculos()
                if(value.length > 0){
                    if(this.$route.params.id == undefined){
                        var dados = await new HttpRequest()
                            .Post('/administrativo/cadastros/perfil/acesso/modelos/tabela', {'dados':value})
                        this.dadosModulos = dados.data
                        this.mostrarModulos = true
                        var valor = Object.keys(dados.data)[0]
                        this.clicaBotaoModulos(dados.data[valor], valor)
                    } else {
                        this.mostrarModulos = true
                        var modulo = Object.keys(this.dadosModulos)[0]
                        this.clicaBotaoModulos(this.dadosModulos[modulo], modulo)
                    }
                }
                if(!this.id){
                    this.arrayButtons[2].isSelected = true
                    this.buttonsFiltersChange('3')
                }
                this.loadingPanel = false
            },
            changeEmpresaMultipla(value){
                this.valueVeiculos      = []
                this.valueGrupoVeiculos = []
                // this.$refs.refVeiculo.clearAll()
                // this.$refs.refGrupoVeiculo.clearAll()

                this.valueEmpresaMultipla = value
                this.buscaVeiculos()
            },  
            async buscaVeiculos(){
                var obj = {
                    'empresaUnica':this.valueEmpresaUnica,
                    'empresaMultipla':this.valueEmpresaMultipla,
                }
                var veiculo = await new HttpRequest()
                    .Post('/administrativo/cadastros/perfil/acesso/grupos/veiculos', {'dados':obj})
                this.optGrupoVeiculos = veiculo.data.grupoVeiculo
                this.optVeiculos = veiculo.data.veiculo
            },
            async salvarCadastro(){
                this.loadingPanel = true
                var obj = {
                    'permissao':this.dadosModulos,
                    'descricao':this.valueDescricao,
                    'empresaUnica':this.valueEmpresaUnica,
                    'empresaMultipla':this.valueEmpresaMultipla,
                    'veiculos':this.valueVeiculos,
                    'id':this.id,
                }
                var salvar = await new HttpRequest()
                    .Post('/administrativo/cadastros/perfil/acesso/salvar', {'dados':obj})
                this.atualizaPermissoes(salvar.data.codigo)
                this.$router.push({name:'perfilAcesso'})
                this.loadingPanel = false
            },

            /**
             * @description Metodo para buscar os dados atualizados dos modulos e permissões de acesso
             * e atualizar informações do menu emitindo evento atualizarMenu
             * @param {string} codigo - código do perfil de acesso
             * @author Vitor Hugo 🐨
             */
            async atualizaPermissoes(codigo){
                var user = this.getUser()
                var atualizaPermissoes = await this.auth.atualizaPermissoes(
                    user.idClient,
                    codigo,
                    user.usuMaster,
                    user.idUser
                )
                this.updatePermissions()
                // Emite evento para atualizar o menu
                // Evento é recebido no mounted do menu.vue
                this.$root.$emit('atualizarMenu');
            },

            async clicaBotaoModulos(value, index){
                this.moduloAtual = index
                this.FiltroStatusVisualizar = false
                this.FiltroStatusCadastrar  = false
                this.FiltroStatusEditar     = false
                this.FiltroStatusExcluir    = false
                value['clicado'] = !value['clicado']
                if(this.salvarClickAnterior != ''){
                    this.salvarClickAnterior.clicado = !this.salvarClickAnterior.clicado
                }
                this.salvarClickAnterior = value
                this.tabelaGrande = value
                this.verificaTotalChecks()
            },
            buttonsFiltersChange(value){
                if(value != undefined){
                    this.arrayButtons[0].isSelected = false
                    this.arrayButtons[1].isSelected = false
                    this.arrayButtons[2].isSelected = false
                    if(value[0] == '0'){
                        this.arrayButtons[0].icon = ' '
                        this.arrayButtons[1].icon = ' '
                        this.arrayButtons[2].icon = ' '
                        this.arrayButtons[0].isSelected = false
                        this.arrayButtons[1].isSelected = false
                        this.arrayButtons[2].isSelected = false
                    }
                    else if(value[0] == '1'){
                        this.mudaValoresGeral(value[0])
                        this.arrayButtons[0].isSelected = true
                        this.arrayButtons[0].icon = mdiCheckBold
                        this.arrayButtons[1].icon = ' '
                        this.arrayButtons[2].icon = ' '
                    }
                    else if(value[0] == '2'){
                        this.mudaValoresGeral(value[0])
                        this.arrayButtons[0].icon = ' '
                        this.arrayButtons[1].icon = mdiCheckBold
                        this.arrayButtons[1].isSelected = true
                        this.arrayButtons[2].icon = ' '
                    }
                    else if(value[0] == '3'){
                        this.mudaValoresGeral(value[0])
                        this.arrayButtons[0].icon = ' '
                        this.arrayButtons[1].icon = ' '
                        this.arrayButtons[2].icon = mdiCheckBold
                        this.arrayButtons[2].isSelected = true
                    }
                }
            },
            mudaValoresGeral(value){
                if(this.valueEmpresaUnica.length > 0){
                    if(!this.getMaster()){
                        var jornadaVisualizar = this.dadosModulos["Jornada de trabalho"]["Cadastros"][2]["ppvisualizar"]
                        var jornadaCadastrar = this.dadosModulos["Jornada de trabalho"]["Cadastros"][2]["ppcadastrar"]
                        var jornadaEditar = this.dadosModulos["Jornada de trabalho"]["Cadastros"][2]["ppeditar"]
                        var jornadaExcluir = this.dadosModulos["Jornada de trabalho"]["Cadastros"][2]["ppexcluir"]
                    }
                    var visualizar = true
                    var cadastrar  = true
                    var editar     = true
                    var excluir    = true
                    if(value == '2'){
                        cadastrar = false
                        editar    = false
                        excluir   = false
                    }
                    else if(value == '3'){
                        excluir = false
                    }
                    this.FiltroStatusVisualizar = visualizar
                    this.FiltroStatusCadastrar  = cadastrar
                    this.FiltroStatusEditar     = editar
                    this.FiltroStatusExcluir    = excluir
                    for(const modulo in this.dadosModulos){
                        for(const index in this.dadosModulos[modulo]){
                            if(this.dadosModulos[modulo][index].length != undefined){
                                for (const indice in this.dadosModulos[modulo][index]) {
                                    this.dadosModulos[modulo][index][indice]['desabilitar']  = false
                                    this.dadosModulos[modulo][index][indice]['ppvisualizar'] = 
                                        this.dadosModulos[modulo][index][indice]['ppvisualizar'] != null ? visualizar : null
                                    this.dadosModulos[modulo][index][indice]['ppcadastrar']  = 
                                        this.dadosModulos[modulo][index][indice]['ppcadastrar']  != null ? cadastrar : null
                                    this.dadosModulos[modulo][index][indice]['ppexcluir']    = 
                                        this.dadosModulos[modulo][index][indice]['ppexcluir']    != null ? excluir : null
                                    this.dadosModulos[modulo][index][indice]['ppeditar']     = 
                                        this.dadosModulos[modulo][index][indice]['ppeditar']     != null ? editar : null
                                }
                            }
                        }
                    }
                    if(!this.getMaster() && this.dadosModulos["Jornada de trabalho"]["Cadastros"][2]["pidescricao"] == "Parâmetros"){                              
                        this.dadosModulos["Jornada de trabalho"]["Cadastros"][2]["ppvisualizar"] = jornadaVisualizar
                        this.dadosModulos["Jornada de trabalho"]["Cadastros"][2]["ppcadastrar"] = jornadaCadastrar
                        this.dadosModulos["Jornada de trabalho"]["Cadastros"][2]["ppeditar"] = jornadaEditar
                        this.dadosModulos["Jornada de trabalho"]["Cadastros"][2]["ppexcluir"] = jornadaExcluir
                    }
                    
                }
            },
            
            changeCheckBoxTabela(valor, index, alterar, subLinhaAtual, linhaAtual){
                this.FiltroStatusVisualizar = false
                this.FiltroStatusCadastrar  = false
                this.FiltroStatusEditar     = false
                this.FiltroStatusExcluir    = false
                this.buttonsFiltersChange(['0'])
                if(alterar != 'ppvisualizar'){
                    this.dadosModulos[this.moduloAtual][linhaAtual][index][alterar] = valor
                    this.dadosModulos[this.moduloAtual][linhaAtual][index]['ppvisualizar'] = true
                }else{
                    this.dadosModulos[this.moduloAtual][linhaAtual][index]['ppvisualizar'] = valor
                    this.dadosModulos[this.moduloAtual][linhaAtual][index]['ppcadastrar']  = 
                        this.dadosModulos[this.moduloAtual][linhaAtual][index]['ppcadastrar'] != null ? false : null
                    this.dadosModulos[this.moduloAtual][linhaAtual][index]['ppeditar']     = 
                        this.dadosModulos[this.moduloAtual][linhaAtual][index]['ppeditar']    != null ? false : null
                    this.dadosModulos[this.moduloAtual][linhaAtual][index]['ppexcluir']    = 
                        this.dadosModulos[this.moduloAtual][linhaAtual][index]['ppexcluir']   != null ? false : null
                    this.dadosModulos[this.moduloAtual][linhaAtual][index]['desabilitar']  = !valor
                }
                this.verificaTotalChecks()
            },

            /**
             * Percorre o objeto que gera os check verificando se todos estão selecionados para deixar 
             * as opções gerais com o check selecionado
             * @param Objeto com os valores do checkbox
             * @return Variaveis das opções com valor booleano (FiltroStatusVisualizar, FiltroStatusCadastrar,
             * FiltroStatusEditar, FiltroStatusExcluir)
             * @author Gui 🍺🍺
             */
            verificaTotalChecks(){
                var totalLength = 0
                var filtroCadastrarLength = 0
                var filtroEditarLength = 0
                var filtroExcluirLength = 0
                var filtroVisualizar = []
                var filtroCadastrar = []
                var filtroEditar = []
                var filtroExcluir = []
                for (const key in this.dadosModulos[this.moduloAtual]) {
                    if(key != 'clicado'){
                        totalLength = totalLength + this.dadosModulos[this.moduloAtual][key].length
                        this.dadosModulos[this.moduloAtual][key].forEach(element => {
                            if(element.ppvisualizar){
                                filtroVisualizar.push(true)
                            }
                            if(element.ppcadastrar){
                                filtroCadastrar.push(true)
                            } else if(element.ppcadastrar == null){
                                filtroCadastrarLength = filtroCadastrarLength - 1
                            }
                            if(element.ppeditar){
                                filtroEditar.push(true)
                            }else if(element.ppeditar == null){
                                filtroEditarLength = filtroEditarLength - 1
                            }
                            if(element.ppexcluir){
                                filtroExcluir.push(true)
                            }else if(element.ppexcluir == null){
                                filtroExcluirLength = filtroExcluirLength - 1
                            }
                        });
                    }
                }
                filtroCadastrarLength = totalLength + filtroCadastrarLength
                filtroEditarLength = totalLength + filtroEditarLength
                filtroExcluirLength = totalLength + filtroExcluirLength
                if(filtroVisualizar.length == totalLength){
                    this.FiltroStatusVisualizar = true
                }
                if(filtroCadastrar.length == filtroCadastrarLength && filtroCadastrarLength != 0){
                    this.FiltroStatusCadastrar = true
                }
                if(filtroEditar.length == filtroEditarLength && filtroEditarLength != 0){
                    this.FiltroStatusEditar = true
                }
                if(filtroExcluir.length == filtroExcluirLength && filtroExcluirLength != 0){
                    this.FiltroStatusExcluir = true
                }
                this.verificaFiltrosFacilitador()
            },

            /**
             * Percorre o objeto que gera os check verificando em todos os modulos quais 
             *  as opções que estão selecionadas para poder selecionar o filtro facilitador
             *  da página corretamente, foi criado um totalizador para cada filtro para 
             *  conseguir fazer a verificação de qual esta selecionado
             * @author Vitor Hugo 🐨
             */
            verificaFiltrosFacilitador(){
                // Foi a forma que encontrei para selecionar os filtros
                var totalModulos = []
                var totalModulosCadastro = []
                var totalModulosEditar = []
                var totalModulosExcluir = []
                var facilitadorVizualizar = []
                var facilitadorCadastrar = []
                var facilitadorEditar = []
                var facilitadorExcluir = []
                for (const modulo in this.dadosModulos) {

                    var totalLength = 0
                    var filtroCadastrarLength = 0
                    var filtroEditarLength = 0
                    var filtroExcluirLength = 0
                    var filtroVisualizar = []
                    var filtroCadastrar = []
                    var filtroEditar = []
                    var filtroExcluir = []
                    totalModulos.push(modulo)
                    for (const key in this.dadosModulos[modulo]) {
                        if(key != 'clicado'){
                            totalLength = totalLength + this.dadosModulos[modulo][key].length
                            this.dadosModulos[modulo][key].forEach(element => {
                                if(element.ppvisualizar){
                                    filtroVisualizar.push(true)
                                }
                                if(element.ppcadastrar){
                                    filtroCadastrar.push(true)
                                } else if(element.ppcadastrar == null){
                                    filtroCadastrarLength = filtroCadastrarLength - 1
                                }
                                if(element.ppeditar){
                                    filtroEditar.push(true)
                                }else if(element.ppeditar == null){
                                    filtroEditarLength = filtroEditarLength - 1
                                }
                                if(element.ppexcluir){
                                    filtroExcluir.push(true)
                                }else if(element.ppexcluir == null){
                                    filtroExcluirLength = filtroExcluirLength - 1
                                }
                            });
                        }
                    }
                    filtroCadastrarLength = totalLength + filtroCadastrarLength
                    filtroEditarLength = totalLength + filtroEditarLength
                    filtroExcluirLength = totalLength + filtroExcluirLength
                    // cria o total de modulo para cada tipo de filtro
                    if(filtroCadastrarLength > 0){
                        totalModulosCadastro.push(modulo)
                    }
                    if(filtroEditarLength > 0){
                        totalModulosEditar.push(modulo)
                    }
                    if(filtroExcluirLength > 0){
                        totalModulosExcluir.push(modulo)
                    }

                    // Verifica em cada módulo se todos os checks estão preenchidos
                    if(filtroVisualizar.length == totalLength){
                        facilitadorVizualizar.push(modulo)
                    }
                    if(filtroCadastrar.length == filtroCadastrarLength && filtroCadastrarLength != 0){
                        facilitadorCadastrar.push(modulo)
                    }
                    if(filtroEditar.length == filtroEditarLength && filtroEditarLength != 0){
                        facilitadorEditar.push(modulo)
                    }
                    if(filtroExcluir.length == filtroExcluirLength && filtroExcluirLength != 0){
                        facilitadorExcluir.push(modulo)
                    }

                }         

                var totalModul = ''
                var totalModulosCad = ''
                var totalModulosEdit = ''
                var totalModulosEx = ''

                // if(this.dadosModulos["Jornada de trabalho"]["Cadastros"][2]["ppvisualizar"] == false &&
                // this.dadosModulos["Jornada de trabalho"]["Cadastros"][2]["ppcadastrar"] == false &&
                // this.dadosModulos["Jornada de trabalho"]["Cadastros"][2]["ppexcluir"] == false &&
                // this.dadosModulos["Jornada de trabalho"]["Cadastros"][2]["ppeditar"] == false){
                //     totalModul = totalModulos.length - 1
                //     totalModulosCad = totalModulosCadastro.length - 1 
                //     totalModulosEdit = totalModulosEditar.length - 1
                //     totalModulosEx = totalModulosExcluir.length - 1
                // }else{
                //     totalModul = totalModulos.length 
                //     totalModulosCad = totalModulosCadastro.length 
                //     totalModulosEdit = totalModulosEditar.length 
                //     totalModulosEx = totalModulosExcluir.length 
                // }


                // verifica quando cada filtro deve ser selecionado
                if(facilitadorVizualizar.length == totalModul && facilitadorCadastrar.length == totalModulosCad &&
                facilitadorEditar.length == totalModulosEdit && facilitadorExcluir.length == totalModulosEx ){
                    this.arrayButtons[0].isSelected = true
                    this.arrayButtons[0].icon = mdiCheckBold
                    this.arrayButtons[1].icon = ' '
                    this.arrayButtons[2].icon = ' '
                } else if(facilitadorVizualizar.length == totalModul && facilitadorCadastrar.length == totalModulosCad &&
                facilitadorEditar.length == totalModulosEdit && facilitadorExcluir.length == 0){
                    this.arrayButtons[0].icon = ' '
                    this.arrayButtons[1].icon = ' '
                    this.arrayButtons[2].icon = mdiCheckBold
                    this.arrayButtons[2].isSelected = true
                } else if(facilitadorVizualizar.length == totalModul && facilitadorCadastrar.length == 0 &&
                facilitadorEditar.length == 0 && filtroExcluir.length == 0){
                    this.arrayButtons[0].icon = ' '
                    this.arrayButtons[1].icon = mdiCheckBold
                    this.arrayButtons[1].isSelected = true
                    this.arrayButtons[2].icon = ' '
                } else {
                    this.buttonsFiltersChange(['0'])
                }
            },

            changeCheckboxFiltro(checkbox, value){
                if(this.moduloAtual != undefined){
                    this.buttonsFiltersChange(['0'])
                    if(checkbox == 'ppvisualizar' && value == false){
                        for (const modulo in this.dadosModulos[this.moduloAtual]){
                            if(this.dadosModulos[this.moduloAtual] != 'clicado'){
                                for (const key in this.dadosModulos[this.moduloAtual][modulo]){
                                    if(this.dadosModulos[this.moduloAtual][modulo][key][checkbox] != null){
                                        this.FiltroStatusCadastrar = false
                                        this.FiltroStatusEditar    = false
                                        this.FiltroStatusExcluir   = false
                                        this.dadosModulos[this.moduloAtual][modulo][key]['desabilitar']  = true
                                        this.dadosModulos[this.moduloAtual][modulo][key]['ppvisualizar'] = 
                                            this.dadosModulos[this.moduloAtual][modulo][key]['ppvisualizar'] != null ? value : null
                                        this.dadosModulos[this.moduloAtual][modulo][key]['ppcadastrar']  = 
                                            this.dadosModulos[this.moduloAtual][modulo][key]['ppcadastrar'] != null ? false : null
                                        this.dadosModulos[this.moduloAtual][modulo][key]['ppeditar']     = 
                                            this.dadosModulos[this.moduloAtual][modulo][key]['ppeditar'] != null ? false : null
                                        this.dadosModulos[this.moduloAtual][modulo][key]['ppexcluir']    = 
                                            this.dadosModulos[this.moduloAtual][modulo][key]['ppexcluir'] != null ? false : null
                                    }
                                }
                            }
                        }
                    }else{
                        for (const modulo in this.dadosModulos[this.moduloAtual]){
                            if(this.dadosModulos[this.moduloAtual] != 'clicado'){
                                for (const key in this.dadosModulos[this.moduloAtual][modulo]){
                                    if(this.dadosModulos[this.moduloAtual][modulo][key][checkbox] != null){
                                        if(checkbox === 'ppvisualizar'){
                                            this.dadosModulos[this.moduloAtual][modulo][key]['desabilitar'] = false
                                        }
                                        if(!this.dadosModulos[this.moduloAtual][modulo][key]['desabilitar']){
                                            this.dadosModulos[this.moduloAtual][modulo][key][checkbox] = value
                                        }
                                    }
                                }
                            }
                        }
                    }
                    this.verificaFiltrosFacilitador()
                }
            }
        },
        async mounted(){
            this.isMaster = this.getMaster()

            if(this.$route.params.id != undefined){
                this.loadingPanel = true
                this.id = this.$route.params.id
                var dados = await new HttpRequest()
                    .Post('/administrativo/cadastros/perfil/acesso/usuario', {'dados':this.$route.params.id})
                if(dados.data.veiculos.length > 0){
                    this.checkboxVeiculos = true
                }
                if(dados.data.empresa.length > 0){
                    this.checkbox = false
                }
                this.empresaUnicaSelected = [dados.data.perfil[0]]
                this.valueEmpresaUnica = [dados.data.perfil[0].value]
                this.empresaMultiplaSelected = dados.data.empresa
                this.valueDescricao = dados.data.perfil[0].pedescricao
                this.selectedVeiculos = dados.data.veiculos
                if(this.selectedVeiculos.length > 0){
                    this.checkboxVeiculos = true
                }
                this.dadosModulos = dados.data.modulos
                var valor = Object.keys(dados.data.modulos)[0]
                this.buscaVeiculos()
                this.clicaBotaoModulos(dados.data.modulos[valor], valor)
                this.mostrarModulos = true
                this.verificaTotalChecks()
            }
            this.loadingPanel = false
        }
    })
</script>
<style lang="scss">
    #Perfil-de-acesso{
        .pad-left-20px{
            padding-left: 20px;
        }
        .alinha-tamanho{
            height: 100% !important;
        }
        .td-tamanho-maximo{
            max-height: 37px !important;
        }
        .tamanho-maximo{
            max-height: 360px !important;
            overflow-y: auto !important;
        }
        .tamanho-minimo{
            min-height: 400px !important;
        }
        .tamanho-corpo-tabela{
            max-height: 400px !important;
            overflow-y: auto !important;
            table {
                min-height: 400px !important;
                background: #f6f5f5;
                thead {
                    background: #f6f5f5;
                    tr{
                        background: #f6f5f5;
                        th {
                            position: sticky;
                            top: -1px;
                            z-index: 10;
                            background: #f6f5f5;
                        }
                    }
                }
                tbody {
                    min-height: 400px !important;
                    background-color: #f6f5f5;
                }
            }
        }
        .icone{
            width: 9%;
            border: none;
            margin: none;
        }
        .fazer-risco{
            width: 15px !important;
            height: 5px !important;
            background-color: #b6b6b6 !important;
        }
        .td-titulo{
            color: black;
        }
        .tr-conteudo{
            border: 1px solid #d6d3d3 !important;
            background-color: #FFF;
        }
        .th-tabela{
            padding: 5px;
            border: 1px solid #d6d3d3 !important;
        }
        .tamanho-tabela{
            width: 100% !important;
            background-color: #f6f5f5;
        }
        .estilo-tabela{
            font-size: 15px !important;
        }
        .sumir-botao{
            font-size: 15px !important;
            border: none;
            margin: 0px !important;
            padding: 0px !important;
            background: none !important;
            color: black !important;
            width: 88% !important;
            text-align: left !important;
        }
        .border-none{
            padding: 5px !important;;
            margin: 0px !important;
            border: none !important;
        }
        .container{
            display: relative;
        }
        .espaco{
            margin: 10px;
        }
        .alinhar-left{
            transform: translate(2%);
            text-align: left!important;
        }
        .alinhar-left-bastante{
            text-align: left!important;
            margin-left: 1% !important;
        }
        .cor-vermelha{
            background-color: #fb746b;
            padding: 5px;
        }
    }
</style>