<template>
    <modalEagle
        :title="'Solicitações'"
        id="modal-detalhes-solicitacao-manutencao"
        size="xl"
        @modalOpen="modalOpen"
        @modalClose="modalClose">
        <slot slot="modalBody">
            <b-overlay :show="show" rounded="sm">
                <div class="col-sm-12 row nopadding">
                    <div class="col-12 row nopadding">
                        <div class="col-4 nopadding mt-1,5">
                            <selectAll
                            :labels="[{indexDFH: 'EM', description: 'Empresas'}]"
                            nameForRadio ="select"
                            :optionsArray ="optSelectEmpresas"
                            @changeSelect ="selecionarEmpresasModal"
                            :isMultiple="true"
                            :hasSelectAll="true"
                            >
                            </selectAll>
                        </div>
                        <div class="col-4 nopadding">
                            <InputRangeWithLimit
                            :valueVazia ="true"
                            id = "inputDataSolicitacaoManutencao"
                            name ="InputRangeWithLimit" 
                            @changeInput="selecionarIntervaloDataModal" 
                            >
                            </InputRangeWithLimit>
                        </div>
                        <div class="col-4 visualizarSolicitacoesLidas ">
                            <b-form-checkbox
                            v-model="statusCheckbox"
                            id="checkbox-1"
                            @change="visualizarSolicitacoesLidas"
                            name="checkbox-1"
                            >
                            Visualizar solicitações lidas
                            </b-form-checkbox>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12"><hr></div>
                <div class="col-12 row nopadding">
                    
                    <tableListagem
                        :titleScope="statusCheckbox ? 'Lido em' : 'Confirmar Leitura'"
                        :hasSearch="true"
                        :titles="titulosT"
                        :data="qtdSolicitacoes"
                    >
                        <template #acoes='{row}'>
                            <b-form-checkbox
							v-show ="!row.smlido"
                            class ="confirmarLeitura"
                            size ="lg"
                            :id="'checkbox-2'+row.codigo"
                            v-model="row.smlido"
                            @change="confirmaLeitura(row.codigo)"
                            name="Confirmar Leitura"
                            >
                            </b-form-checkbox>
							<div
							v-show ="row.smlido"
							>
							{{row.smdatahoraconfirmacaoleitura}}
							</div>
                        </template>
                    </tableListagem>
                </div>
            </b-overlay>
        </slot>
        <slot slot="modalFooter">
            <div class="d-flex flex-row nopadding">
                <simpleButton
                    class ="p-2"
                    :icon="mdiCloseThick"
                    @click="cancelButton"
                    text="Cancelar"
                    event="click"
                    type="red"
                >
                </simpleButton>
            </div>
        </slot>
    </modalEagle>
</template>
<script lang="js">
import Vue                      from 'vue';
import { FiltrosService }       from '@/Services/filtros/filtros.Service';
import {required, requiredIf}   from 'vuelidate/lib/validators';
import {HttpRequest}            from '../../../Services/auth/HttpRequest.Service';
import { EmpresasService }      from '@/Services/auth/Empresas.service';
import {mdiCloseThick}          from '@mdi/js';
import {inputRangeWithLimit}    from '@/components/Atom/Datas/InputRangeWithLimit';

export default Vue.extend({
	name: 'ModalDetalhesSolicitacaoManutencaoVeiculos',

	components:{
		selectAll                 :   require('@/components/Atom/Select/SelectAll').default,
		modalEagle                :   require('@/components/Atom/Modal/ModalEagle').default,
		simpleButton              :   require('@/components/Atom/Buttons/SimpleButton').default,
		tableListagem             :   require('@/components/Atom/Table/TableListagemModal').default,
		InputRangeWithLimit       :   require('@/components/Atom/Datas/InputRangeWithLimit').default,
	},
    
	props:{

		solicitacoesManutencao:{
			type: Array,
			required: true,
		},
	},

	data(){
		return{
			optSelectEmpresas: new EmpresasService().Get(),
			show: false,
			opcoesEmpresaSelecionada: [],
			opcoesIntervaloSelecionada: [],
			mdiCloseThick:mdiCloseThick,
			textoTextArea:'',
			link: '/administrativo/cadastros/agendamento/',

			titulosT : [
				{ 'number': 'smveiculo'         ,'name': 'Placa'              },
				{ 'number': 'tsmdescricao'      ,'name': 'Tipo'               },
				{ 'number': 'smobservacao'      ,'name': 'Observação'         },
				{ 'number': 'name'              ,'name': 'Usuário'            },
				{ 'number': 'clfantasia'        ,'name': 'Cliente'            },
				{ 'number': 'smdatahoraevento'  ,'name': 'Data solicitação'   },
			],
			statusCheckbox: false,
			checkboxConfirmarLeitura: false,
			qtdSolicitacoes: [],
			recebeDados: [],
		}
	},

	methods: {

		/**
        * @description - O parâmetro representa o id do objeto.
        * @param {number} value - Recebe o id do campo selecionado,
        *  que no caso é o id de uma opção do Select (empresas);
        * @author Daniel Vitor Nascimento
 		*/
		selecionarEmpresasModal(value){
			this.opcoesEmpresaSelecionada = value
			this.filtrosTabelaModal()
		},

		/**
        * @description - O parâmetro representa o id do objeto.
        * @param {number} value - Recebe o value do campo selecionado,
        *  que no caso é uma opção do Input (InputRangeWithLimit);
        * @author Daniel Vitor Nascimento
 		*/
		selecionarIntervaloDataModal(value){
			this.opcoesIntervaloSelecionada = value
			this.filtrosTabelaModal()
		},
		/**
        * @description - filtro que visualiza as solicitações de manutenção que receberam
        * confirmação de leitura.
        * @author Daniel Vitor Nascimento
        */
		visualizarSolicitacoesLidas(){
			this.carregaListaFalseSoliManu(this.recebeDados)
		},

		confirmaLeitura(dadosDeUmaColuna){
			this.show = true
			new HttpRequest()
				.Post(this.link+'confirmar/leitura', {codigo:dadosDeUmaColuna})
				.then(dados=>{
					if(dados.code == 200){
						this.filtrosTabelaModal()
						this.carregaListaFalseSoliManu(this.recebeDados)
						this.show = false
					}
					else{
						this.show = false
						this.toastShow('Erro, tente novamente mais tarde', 'danger')
					}
				});
		},

		// /**
		// * @description - Não permitir alteração de leitura
		// * ou seja, uma vez confirmado, não será possível reverter.
		// * Estamos permitindo o usuário desmarcar o check.
		// * Dessa forma a regra da coluna “confirmar leitura” será a seguinte:
		// * Se o check do filtro estiver desmarcado, você irá mostrar o checkbox.
		// * Se o check do filtro estiver marcado, você irá mostrar Lido em + data e hora da leitura.
		// * @author Daniel Nascimento
		// */
		/**
        * @description - 
        * @author Daniel Vitor Nascimento
        */
       
		filtrosTabelaModal(){
			this.show = true
			var obj = {
				'idempresa'             : this.opcoesEmpresaSelecionada,
				'dataintervalo'         : this.opcoesIntervaloSelecionada,
			}

			new HttpRequest()
				.Post(this.link+'get/solicitacao/manutencao', obj)
				.then(dados=>{
					if(dados.code == 200){
						this.carregaListaFalseSoliManu(dados.data.dadosSolicitacaoManutencao)
						this.recebeDados = dados.data.dadosSolicitacaoManutencao
						this.show = false
					}
					else{
						this.show = false
						this.toastShow('Erro, tente novamente mais tarde', 'danger')
					}
				});
		},

		/**
        * @description - É uma prop que foi feita no 'Agendamento.vue'. 
        * Ela possui os dados que compõem uma solicitação de manutenção.
        * Aqui ela está servindo para preencher (listar) a tabela do modal com
        * os dados que ainda não receberam a confirmação de leitura: no caso, são
        * aqueles que têm 'smlido' (coluna de uma das consultas, boolean) = false.
        * @author Vitor Hugo, Daniel Vitor COPIÃO
        */
		carregaListaFalseSoliManu(dados){
			this.qtdSolicitacoes = []
			if(dados.length > 0){
				dados.forEach(element => {
					if(element.smlido == this.statusCheckbox){
						this.qtdSolicitacoes.push(element)
					}
				})
			} 
			else{
				this.qtdSolicitacoes = []
			}
		},

		toastShow(msg, type, title = ''){
			this.$bvToast.toast(msg, {
				autoHideDelay: 2500,
				variant: type,
				title: title
			})
		},

		cancelButton(){
			this.$bvModal.hide('modal-detalhes-solicitacao-manutencao')
			this.filtrosTabelaModal()
		},

		modalOpen(){
			this.statusCheckbox = false
			this.opcoesIntervaloSelecionada = [],
			this.opcoesEmpresaSelecionada = [],
			this.textoTextArea = ''
			this.filtrosTabelaModal()
		},
		modalClose(){
			this.$emit('modalClose')
		},
	},    
    
	validations:{

		opcoesEmpresaSelecionada:{
			required,
		},
	},

	mounted(){
		this.filtrosTabelaModal()
	},
})

</script>

<style lang="scss" scoped>
    .observacaoModal {
        textarea{
            overflow: auto;
            resize: vertical;
            width: 100%;
            min-height: 42px;
        }
    }

    .float-right {
        color: red;
    }

    .visualizarSolicitacoesLidas {
        padding-top: 26px;
        padding-left: 50px;
        // text-align: center;
    }

    .confirmarLeitura {
        margin-left: 55px;
        height: auto;
    }

    .el-input__inner{
        width: 98%;
    }
</style>
