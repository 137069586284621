/**
    Input de text com mascara e botão de pesquisa

    -> Propriedades:
        1- label - String - Descricao do input/Legenda que fica em cima - EX: 'Logo'
        2- hasMask - Boolean - tem mascara? - EX: true/false
        3- mask - Array - array de opções de mascara seguindo as tokens abaixo - EX: ['###.##.###']
        4- name - String - Name do input - EX: 'inputSimple'
        5- value - String - Rota para o arquivo ja selecionado - EX: '999.99.153'
        6- inputClass - String- Classe extra para o input - EX: 'inputArchive'
        7- inputDisabled - String - se ele esta desabilitado - EX: 'disabled'/''
        8- id - String - Id do input - EX: 'inputSimple'
        9- extraClass - String - adicionar classe na div pai - EX: 'nopadding'

        **Tokens**
            '#': {pattern: /\d/},
            'h': {pattern: /[0-2]}
            'X': {pattern: /[0-9a-zA-Z]/},
            'S': {pattern: /[a-zA-Z]/},
            'A': {pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase()},
            'a': {pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase()},
            '!': {escape: true},
            'N': {pattern: /[0-9]/},
            'h': {pattern: /[0-2]/},
            'H': {pattern: /[0-4]/},
            'M': {pattern: /[0-5]/},
            'P': {pattern: /[0-9 ]/},
            'L': {pattern: /[0-50]/},
            'l': {pattern: /[a-jA-J]/,
            'q': {pattern: /[a-zA-Z0-9-?]/, transform: v => v.toLocaleUpperCase()},
            /* PARA NOVOS TOKENS INSERIR NA VARIAVEL TOKENS DENTRO DO DATA */


    -> Slot:
        Sem slot

    -> Eventos:
        1- changeInput - Emite quando o input é alterado - Parametros:
            1.1- inputValue - o valor do input
 */
<template>
	<div :class="'col-sm-12 ' + extraClass" id='inputSimple'>
		<div class="col-sm-12 subtitle nopadding quebraTexto" :title="label">
			{{ label }}
		</div>
		<the-mask :placeholder="placeholder" :tokens="tokens" :masked="true" :mask="mask" v-model="inputValue"
			:value="inputValue" :name="name" v-if="hasMask && inputDisabled == 'disabled'" disabled
			class="form-control border-is" :id="id" :class="inputClass" />

		<the-mask v-on='$listeners' :placeholder="placeholder" :tokens="tokens" :masked="true" :mask="mask"
			v-model="inputValue" :value="inputValue" :name="name" v-else-if="hasMask && inputDisabled != 'disabled'"
			class="form-control border-is" :id="id" :class="inputClass" v-bind='$attrs' />

		<input :placeholder="placeholder" v-else-if="!hasMask && inputDisabled == 'disabled'" disabled
			class="form-control border-is" :class="inputClass" v-model="inputValue" :name="name" v-bind='$attrs'
			v-on='$listeners' :id="id">

		<input :placeholder="placeholder" v-else-if="!hasMask && inputDisabled != 'disabled'" class="form-control border-is"
			:class="inputClass" v-model="inputValue" v-bind='$attrs' :name="name" v-on='$listeners' :id="id">
	</div>
</template>

<script lang="js">
import Vue from 'vue'
import { TheMask } from 'vue-the-mask'
export default Vue.extend({
	components: {
		TheMask
	},
	inheritAttrs: false,
	props: {
		label: {
			type: String,
			required: false,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		hasMask: {
			type: Boolean,
			required: false,
			default: false
		},
		mask: {
			type: Array,
			required: false,
			default: function () { return [] },
		},
		name: {
			type: String,
			default: '',
		},
		value: {
			type: [String, Number],
			required: false,
			default: '',
		},
		inputClass: {
			type: [String, Object],
			required: false,
			default: '',
		},
		inputDisabled: {
			type: String,
			required: false,
			default: ''
		},
		id: {
			type: String,
			required: false,
			default: ''
		},
		extraClass: {
			type: String,
			required: false,
			default: ''
		}
	},

	data() {
		var inputValue = this.value;
		var tokens = {
			'#': { pattern: /\d/ },
			'X': { pattern: /[0-9a-zA-Z]/ },
			'S': { pattern: /[a-zA-Z]/ },
			'A': { pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase() },
			'a': { pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase() },
			'!': { escape: true },
			'h': { pattern: /[0-2]/ },
			'H': { pattern: /[0-4]/ },
			'D': { pattern: /[0-3]/ },
			'M': { pattern: /[0-5]/ },
			'N': { pattern: /[0-9]/ },
			'P': { pattern: /[0-9 ]/ },
			'L': { pattern: /[0-49]/ },
			'l': { pattern: /[a-zA-Z0-9]/, transform: v => v.toLocaleUpperCase() },
			'q': { pattern: /[a-zA-Z0-9-?]/, transform: v => v.toLocaleUpperCase() },
		};
		return {
			inputValue,
			tokens
		}
	},
	methods: {
		emiteBlur(event) {
			this.$emit('blur', event)
		},
		clearAll() {
			this.inputValue = '';
		},
		// keyup(event:any){
		//     this.$emit('changeInput', this.inputValue);
		// }
	},
	mounted() {
		this.$emit('changeInput', this.inputValue);
	},
	watch: {
		value: function () {
			this.inputValue = this.value;
			this.$emit('changeInput', this.inputValue);
		},
		inputValue: function () {
			this.$emit('changeInput', this.inputValue);
		}
	}
})

</script>

<style lang="scss">
#inputSimple {
	.subtitle {
		font-size: 13px;
		text-align: left;
		margin-bottom: 0;
	}

	.altura {
		height: 26px !important;
	}

	input {
		height: 42px;
		padding: 0 5px !important;
	}

	.quebraTexto {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	.border-is {
		// não colocar estilos de bordas se n vai quebrar o resto do trak
		// quando ele usar o border-danger do bootstrap
		// border-color: #e5e5e5 !important;
	}
}
</style>
