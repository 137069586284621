<template>
    <div class="col-sm-12 divMasterRotaPrincipal row">
        <div :class="'col-sm-12 divTituloRP nopadding row'">

            <div class="col-sm-11 nopadding"
                :title="dados.porcent"
                v-b-tooltip.hover.html="titlePrincipal"
                @click="openCloseBlockLinhasSeparadas"
                style="min-width: 97% !important">
                <div class="col-sm-12 divInlineInfo row">
                    <div class="col-sm-1 divButtonFlecha pr-1 pl-1" style="display: flex;" >
                        <b-icon :icon="iconFlecha" style="margin-top: 10px;" font-scale="2.5"/>
                        <div style="padding-top: 19px;">{{dados.rota}}</div>
                    </div>
                    <div class="col-sm-2 pr-1 pl-1 overflowHidden">
                        <div class="col-sm-12 nopadding">
                            Placa: {{dados.carro}}
                        </div>
                        <div class="col-sm-12 nopadding">
                            Data: {{dados.rodata}}
                        </div>
                    </div>
                   
                    
                    <div class="col-sm-1 pr-1 pl-1 overflowHidden">
                        <div class="col-sm-12 nopadding">
                            Ini.: {{dados.horaInicio}}
                        </div>
                        <div class="col-sm-12 nopadding">
                            Fim: {{dados.horaFim}}
                        </div>
                    </div>

                    <div class="col-sm-2 pr-1 pl-1 overflowHidden">
                        <div class="col-sm-12 nopadding"
                        :title="dados.pontoSaida">
                            Saida: {{ dados.pontoSaida }}
                        </div>
                        <div class="col-sm-12 nopadding"
                        :title="dados.pontoRetorno">
                            Retorno: {{ dados.pontoRetorno }}
                        </div>
                    </div>

                    <div class="col-sm-1 pr-1 pl-1 overflowHidden">
                        <div class="col-sm-12 nopadding"
                        :title="'Tempo Estimado: '+dados.rotaTempo">
                            T. Estim.: {{dados.rotaTempo}}
                        </div>
                        <div class="col-sm-12 nopadding"
                        :title="'Custo da Rota: R$ '+dados.rotaCusto">
                            Custo: R$ {{dados.rotaCusto}}
                        </div>
                        <div class="col-sm-12 nopadding"
                        :title="'Peso: '+dados.rotaPeso+' Kg'">
                            Peso: {{dados.rotaPeso}} Kg
                        </div>
                    </div>

                    <div class="col-sm-1 pr-1 pl-1 overflowHidden">
                        <div class="col-sm-12 nopadding"
                            :title="'Motorista: '+dados.motorista">
                            Mot.: {{dados.motorista}}
                        </div>
                        <div class="col-sm-12 nopadding"
                            :title="'Ajudante: '+dados.ajudante">
                            Ajud.: {{dados.ajudante}}
                        </div>
                    </div>
                    <div class="col-sm-2 pr-1 pl-1 overflowHidden"
                        :title="'Ultimo: '+dados.ultimo">
                        <div class="col-sm-12 nopadding">
                            Ultimo: {{dados.ultimo}}
                        </div>
                        <div class="col-sm-12 nopadding"
                            :title="'Próximo: '+dados.proximo">
                            Próximo: {{dados.proximo}}
                        </div>
                    </div>
                    <div class="col-sm-1 row pr-1 pl-1 overflowHidden">
                         <div class="col-sm-6 nopadding" title="total">
                            T: {{dados.total}}
                        </div>
                        <div class="col-sm-6 nopadding" title="finalizado">
                            F: {{dados.realizados}}
                        </div>
                         <div class="col-sm-6 nopadding" title="pendente">
                            P: {{dados.pendentes}}
                        </div>
                        <div class="col-sm-6 nopadding" title="justificado">
                            J: {{dados.justificados}}
                        </div>
                    </div>
                    <div class="col-sm-1 row pr-1 pl-1 overflowHidden"
                        align="center" style="top:-2px;">
                        <span :class="'pt-1 circle '+dados.status"
                                align="center"
                                :title="dados.status == 'P' ?
                                'Rota pendente' : dados.status == 'I' ?
                                'Rota iniciada' : dados.status == 'F' ?
                                'Rota finalizada' : 'Rota em carregamento'">
                            <span>{{dados.status}}</span>
                        </span>
                    </div>
                </div>
                <div :style="'width:'+dados.porcent"
                    :class="'col-sm-12 opacidadeRota nopadding '+
                        'statusRota-'+dados.statusCarro">
                </div>
            </div>
            <div class="col-sm-1 nopadding zIndex"
                style="max-width: 3% !important">
                <div class="col-sm-12 nopadding" align="right">
                    <simpleButton 
                        id="encerrarRota" 
                        v-if="dados.status != 'F' && excluir" 
                        v-b-modal="'encerrarRotaModal'+dados.rota" 
                        extraClass="heightBotaoEncerraRota" 
                        width="50px"
                        :icon="mdiPowerStandby" 
                        :disabled="!excluir"
                        type="red" 
                        title="Encerrar rota">
                    </simpleButton>
                    <encerrarRotaModal 
                        @recarregaDados="recarregaDados"
                        :cliente="dados.cliente" 
                        :rota="dados.rota">
                    </encerrarRotaModal>
                   
                </div>
            </div>
        </div>
        <div class="col-sm-12 nopadding divBlockLinhasSeparadas">
            <slide-up-down 
                :key="'slide_rota_'+dados.rota+'_'+dados.carro" 
                class="divAccordion" 
                :active="internalActiveLinhasParadas" 
                :use-hidden="true" 
                :duration="500">
                <div class="widthRow d-flex justify-content-start">
                    <div class="div-todos-marker">
                        <div :class="'row div-marker border-'+statusCarro+''" :style="'width: '+95.15*dados.total+'px;'">
                            <div :style="getTamanhoVeiculo"
                                class="caminhao">
                                <baseIcon
                                    size='38' 
                                    class="iconBusao" 
                                    :icon="mdiTruckOutline"
                                />
                            </div>
                            <pontosRota
                                class="markers"
                                @pontoCriado="pontoCriado" 
                                :tamanho="(100/dados.total)"
                                
                                :key="dados.rota+'|'+ponto.ponto" 
                                v-for="(ponto) in dados.pontos" 
                                :dados="ponto">
                            </pontosRota>
                        </div>
                    </div>
                </div>
            </slide-up-down>
        </div>
    </div>
</template>

<script lang="js">
import {mdiPowerStandby, mdiTruckOutline } from '@mdi/js'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import SlideUpDown from 'vue-slide-up-down'
export default Vue.extend({
    name:'monitoramentoRotaPrincipal',
    components:{
        baseIcon:require('@/components/Atom/Icon/BaseIcon.vue').default,
        SlideUpDown,
        'pontosRota': require('./PontosRota').default,
        'simpleButton': require('../../Buttons/SimpleButton').default,
        'encerrarRotaModal': require('../../ModalSpecific/ModalEncerrarRota').default,

    },
    props:{
        activeLinhasParadasCollaps:{
            type: Boolean,
            required: true
        },
        dados:{
            type: Object,
            required: true,
            default: function(){return {}}
        }
    },
    data(){
        return {
            internalActiveLinhasParadas: this.activeLinhasParadas,

            mdiTruckOutline:mdiTruckOutline,
            mdiPowerStandby:mdiPowerStandby,
            iconFlecha: 'chevron-right',
            countOnibus: 0,
            onibus: [],
            countLinhasSeparadas: 0,
            linhasSeparadas : [],
            pontosCriados : 0,
            arrayPontos : [],
            tamanhoCaminhao : 0,
            statusCarro : '',
        }
    },
    
    beforeUpdate() {
        this.verificaPontosPercorridos()
    },
    mounted(){
        this.itensCriados(this.dados.rota)
        this.verificaPontosPercorridos()
    },
    methods:{
        ...mapGetters(['getMaster','getCadastrar','getExcluir']),

        openCloseBlockLinhasSeparadas(){
            this.internalActiveLinhasParadas = !this.internalActiveLinhasParadas;
            this.iconFlecha = this.internalActiveLinhasParadas ? 'chevron-down' : 'chevron-right';

        },

        itensCriados(item){
            this.$emit('linhaCriada', item)
        },
        pontoCriado(ponto){
            if(this.pontosCriados == (this.dados.totalPontos - 1)){
                this.arrayPontos[ponto.dados.ponto] = ponto;
                this.$emit('linhaSeparadaCriada', this)
            }else{
                this.arrayPontos[ponto.dados.ponto] = ponto;
                this.pontosCriados++;
            }
        },
        recarregaDados(){
            this.$emit('recarregaDados');
        },

        /**
        * Verifica em qual ponto o caminhão deve estar
        * @param Objeto com os pontos
        * @return largura da div em pixels (tamanhoCaminhao) 
        * @author Vitor Hugo 🐨
        */
        verificaPontosPercorridos(){
            let pontoPercorrido = this.dados.realizados + this.dados.justificados
            for (let index = 0; index < this.dados.pontos.length; index++) {
                if(this.dados.pontos[index].status != 'R'){
                    pontoPercorrido = index + 1
                }
            }
            if(pontoPercorrido) {
                this.tamanhoCaminhao = 95.15 * pontoPercorrido
            } else {
                this.tamanhoCaminhao = 40
            }
            this.statusCarro = this.dados.statusCarro
        },

    },
    watch: {
        activeLinhasParadasCollaps() {
            this.activeLinhasParadas = !this.activeLinhasParadas;
            this.internalActiveLinhasParadas = this.activeLinhasParadas;
            this.iconFlecha = this.activeLinhasParadas ? 'chevron-down' : 'chevron-right';
        },

        dados(){
            this.verificaPontosPercorridos()
            this.itensCriados(this.dados.rota)
        },
    },
    computed: {
        titlePrincipal() {
            let porcent = this.dados ? this.dados.porcent : ''
            let html = `
                <div class='tooltip-rota''>
                    ${porcent}
                </div>`
            return html
        },
        excluir() {
            return this.getExcluir();
        },
        getTamanhoVeiculo() {
            return {
                width:`${this.tamanhoCaminhao}px`
            }
        },
    }
})
</script>

<style lang="scss">
    .overflowHidden{
        div{
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .circle{
        border: none;
        border-radius: 100%;
        width: 36px;
        height: 36px;
        color: #fff;
        font-size: 21px;
        box-shadow: 1px 2px 3px #000;
        cursor: pointer;
    }
    .F{
        background: #1caf9a;
        font-size: 21px;
    }
    .I{
        background: #428bca;
    }
    .P{
        background: #f0ad4e;
    }
    .C{
        background: #f0ad4e;
    }
    .divInlineInfo{
        padding: 8px 0 8px 0!important;
        max-height: 48px;
        z-index: 1;
        div{
            font-size: 11px;
        }
    }
    .zIndex{
        z-index: 2;
    }
    .heightBotaoEncerraRota{
        height: 48px!important;
        margin: 0!important;
    }
    .statusRota-red{
        background-color: #E04B4A !important;
        opacity: 0.4;
    }
    .statusRota-green{
        background-color: #1CAF9A !important;
        opacity: 0.4;
    }
    .statusRota-blue{
        background-color: #428BCA !important;
        opacity: 0.4;
    }
    .widthRow{
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-scrollbar {
            width: 12px;
        }
        .div-todos-marker{
            min-height: 55px;
            white-space: nowrap!important;
            display: inline-block;
            padding: 0px;
            .div-marker{
                vertical-align: middle;
                display: inline-block;
                padding: 0px;
                height: 34px;
            }
        }
    }
    .beforeBusao{
        position: absolute;
    }
    .opacidadeRota{
        position: absolute!important;
        height: 60px;
        bottom: 0;
    }
    .divMasterRotaPrincipal{
        text-align: left !important;
        font-size: 12px;
        padding: 5px 0 0 0 !important;
        .divTituloRP{
            padding-top: 5px !important;
            font-size: 11px;
            background-color: #f2f2f2;
            font-weight: bold;
            // border-bottom: 1px solid #d5d5d5;
            height: 60px;
        }

        .divBlockLinhasSeparadas{
            .divAccordion{
                padding-top: 0px !important;
                padding-bottom: 0px !important;
                border: 1px solid #d5d5d5;
            }
            .iconBusao{
                float: right;
                &::before{
                    // position: absolute;
                    font-size: 31px!important;
                    margin-left: 0px;
                }
            }
            .timeLineBusao{
                position: absolute;
                padding-right: 50px!important;
                padding-bottom: 0px;
                padding-top: 0px;

                &.statusPontoProgress-red{
                    border-color: #E04B4A !important;
                }
                &.statusPontoProgress-orange{
                    border-color: #F0AD4E !important;
                }
                &.statusPontoProgress-green{
                    border-color: #95b65c !important;
                }
                &.statusPontoProgress-blue{
                    border-color: #428BCA !important;
                }
            }
        }
    }
    #encerrarRota{
        position:relative;
        float:right;
    }
    .tooltip-rota{
        font-size: 18px;
    }
</style>
