/**
    Este é o botão de expandir para deixar a tela em tela cheia

    -> Propriedades:
        - Sem priedades

    -> Slot:
        1- Sem Nome: espaço para colocar algo a mais

    -> Eventos:
        - Sem eventos
 */

<template>
    <div align="right" class="col-sm-12 nopadding">
        <slot></slot>
        <simpleButton width="50px" :icon="icon" type="white" id="btnExpand" event="expandir" @expandir="ExpandirPagina"></simpleButton>
    </div>    
</template>

<script lang="ts">
import {mdiArrowExpandAll, mdiArrowCollapseAll } from '@mdi/js'
import Vue from 'vue'
export default Vue.extend({
    components: {
        'simpleButton': require('./SimpleButton').default,
    },
    data(){
        return {
            iconExpand: mdiArrowExpandAll,
            iconCollapse: mdiArrowCollapseAll,
            expanded: false,
            icon: mdiArrowExpandAll,
        }
    },
    methods:{
        ExpandirPagina(){
            if(this.expanded){
                this.closeFullscreen();
                this.icon = mdiArrowExpandAll;
            }else{
                this.openFullscreen();
                this.icon = mdiArrowCollapseAll;
            }
            this.expanded = !this.expanded
        },
        openFullscreen() {
            if (!document.fullscreenElement) {
                // && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                // } else if (document.documentElement.msRequestFullscreen) {
                //     document.documentElement.msRequestFullscreen();
                // } else if (document.documentElement.mozRequestFullScreen) {
                //     document.documentElement.mozRequestFullScreen();
                // } else if (document.documentElement.webkitRequestFullscreen) {
                //     document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                }
            }
        },
        closeFullscreen() {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            // } else if (document.msExitFullscreen) {
            //     document.msExitFullscreen();
            // } else if (document.mozCancelFullScreen) {
            //     document.mozCancelFullScreen();
            // } else if (document.webkitExitFullscreen) {
            //     document.webkitExitFullscreen();
            }
        },
    }
})
</script>

<style lang="scss">
    #btnExpand{
        span{
            &::before{
                font-size: 20px;
                top: 4px;
                position: relative;
            }
        }
    }
</style>