import { AuthService } from '../../../Services/auth/Auth.Service';
import Layout from '../../../components/Atom/Layout/Layout.vue';
import Relatorio from '../../Children.vue';
import Cadastros from '../../Children.vue';
import NaoMenu from '../../Children.vue';
import { RouteConfig } from 'vue-router'

import ControleTemperatura from '@/views/ColdManager/Relatorios/ControleTemperatura.vue'


export const ifAuthenticated = (_to: any, _from: any, next: any) => {
	if (new AuthService().isAuthenticated()) {
		next()
		return
	}
	type numero= 'N' | 'E';
	next('/login')
}

export const coldManagerRoutes: RouteConfig =
{
	path: '/controle/temperatura',
	name: 'controle-temperatura',
	beforeEnter: ifAuthenticated,
	meta:
	{
		requiresAuth: true,
		Module: 'controle/temperatura',
		name:'controle de temperatura',
		breadcrumbName: ''
	},
	components: { default: Layout }, // ColdManager.route
	children: [
		{
			path: 'relatorios',
			meta: {
				Module: 'controle/temperatura',
				breadcrumbName: 'Relatorios'
			},
			components: { content: Relatorio },
			children: [
				{
					path: 'temperatura',
					name: 'controleTemperatura',
					meta: {
						Module: 'controle/temperatura',
						breadcrumbName: 'Controle de Temperatura'
					},
					component: ControleTemperatura
				},
			]
		},
	]
}