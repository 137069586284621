import L from "leaflet";
/**
 * Uma espécie de facilitador, possue todos os métodos do L.Marker
 * e com o tempo estou adicionando novos métodos para o uso da Eagle.
 */
export class EagleMarker extends L.Marker {
    /**
     * @description A ideia dessa classe é que só passando as informações do
     * ponto, a classe já devolva um marcador completo, com ícone correto
     * e todas as opções padronizadas, com os métodos da Eagle e pã.
     * @constructs marker de ponto do usuário.
     * @augments L.Marker - marcador do leaflet.
     * @class EagleMarker.
     * @param {Object} infoPonto.
     * @param {Object=} [mapObject=false] - usando a função do mapa
     * simples 'returnMapObject',
     * é exatamente isso que precisa aqui.
     * @throws não possuei uma das coordenadas.
     * @author Gui 🐙🍷🍺.
     */
    constructor(infoPonto, mapObject = false) {
        //Verificando se a latitude e a longitude estão presentes
        if (!infoPonto?.polatitude || !infoPonto?.polongitude)
            throw Error("Ponto não possue uma ou as duas coordenadas");
        /**
         * Construtor da classe L.Marker, mandando as informações do ponto
         * E já com o ícone correto selecionado
         */
        super([infoPonto.polatitude, infoPonto.polongitude], {
            icon: criaDivIcon(infoPonto),
            // title: infoPonto.podescricao,
            alt: infoPonto.podescricao,
            riseOnHover: true,
        });
        this.mapObject = mapObject;
        this.circle = "";
        this.id = infoPonto.pocodigo;
        this.infoPonto = infoPonto;
        let tooltip = this.criaTooltip(infoPonto);
        this.bindTooltip(tooltip);
        if (mapObject) this.addEvents();
    }

    /**
     * @description Já que todo ponto tem um raio, em vez
     * de adicionar um circulo p/ cada ponto
     * estou adicionando apenas 1 circulo quando dispara
     * o evento de mouseover, e quando dispara o mouseout,
     * removo esse circulo do mapa.
     * @todo melhorar a maneira como o circulo é excluido/removido do mapa.
     * @author Gui 🍺🍺🍺
     */
    criarCirculo() {
        this.circle = L.circle([this.infoPonto.polatitude, this.infoPonto.polongitude], {
            radius: this.infoPonto.poraio,
            interactive: false,
        }).addTo(this.mapObject);
        var circleLocal = this.circle;
        /**Gambiara estranha, eu sei, tem de ser corrigido em algum momento */
        setTimeout(this.removeCirculoTime, 5000, circleLocal);
    }

    /**
     * @description altera o ícone do ponto baseado nas informações do mesmo
     */
    atualizaIcone() {
        if (this.mapObject) {
            this.setIcon(criaDivIcon(this.infoPonto));
        } else {
            this.setIcon(criaIcone(this.infoPonto));
        }
    }

    /**
     * @description Atualiza informações do ponto
     * @param {object} nInfo - novas informações do ponto
     */
    atualizaPonto(nInfo) {
        this.infoPonto = nInfo;
        let tooltip = this.criaTooltip(nInfo);
        this.bindTooltip(tooltip);
        this.atualizaIcone();
        this.addEvents();
    }

    /**
     * @param {object} ponto
     * @param {string} ponto.podescricao
     * @description cria a tooltip, que substitui o title
     * do ponto, pois é mais fácil controlar.
     * @return {L.tooltip} com a descrição do ponto
     * @author Gui 🍺
     */
    criaTooltip(ponto) {
        /**Add a positive x offset to move the tooltip to the right,
         * and a positive y offset to move it to the bottom.
         * Negatives will move to the left and top.
         */
        return L.tooltip({
            offset: [10, -20],
            className: "tooltipMapa",
            content: ponto.podescricao,
        });
    }

    /**
     * Isso aqui é gambia, mas é temporaria até eu achar
     * uma maneira melhor de arrumar.🦕
     */
    removeCirculoTime(circle) {
        circle.remove();
    }

    /**
     * @description Adiciona todos os eventos que o marcador precisa p/
     * funcionar de acordo com as vontades da Fer 👑
     * @author Gui 🍺
     */
    addEvents() {
        this.on("mouseover", this.criarCirculo);
        this.on("mouseout", this.escondeCirculo);
    }

    /**
     * Quando o ponteiro do mouse sai do ponto,
     * remove o círculo do mapa
     */
    escondeCirculo() {
        this.circle.remove();
    }
}

/**
 * @description Decide o ícone do marcador baseado no tipo do mesmo.
 * @param {string} potipo
 * @return {string} caminho até o ícone correto
 */
function defineIcone(potipo) {
    switch (potipo) {
        case "C":
            return "assets/mapa_finder/markerPontoC.svg";
        case "E":
            return "assets/mapa_finder/markerPontoE.svg";
        case "P":
            return "assets/mapa_finder/markerPontoP.svg";
        // se não tiver um tipo, volta o marcador padrão
        default:
            return "assets/mapa_finder/markerPonto.svg";
    }
}

/**
 * @param {object} obj
 * @param {number} obj.pocodigo      - código do ponto.
 * @param {string} obj.podescricao   - descrição do ponto.
 * @param {('C'|'E'|'P')} obj.potipo - tipo do ponto.
 * @description cria o marcador do ponto, do tipo div.
 * @returns {L.DivIcon} ícone do ponto.
 */
function criaDivIcon({ pocodigo, podescricao, potipo }) {
    var html = `
		<div id='${pocodigo}' class='marcador-ponto-padrao'>
			<div>
				<img src='${defineIcone(potipo)}'>
			</div>
			<div class='legenda-marcador' id='${pocodigo}_${potipo}'>
				${podescricao}
			</div>
		</div>`;
    return L.divIcon({
        html: html,
        iconSize: [0, 0],
        iconAnchor: [20, 38],
        shadowAnchor: [10, 10],
    });
}

/**
 *
 * @param {object} infoPonto - objeto com as informações do ponto
 * @param {('C'|'E'|'P')} infoPonto.potipo - tipo do ponto
 * @returns {L.Icon} icone do ponto
 */
function criaIcone(infoPonto) {
    return new L.Icon({
        iconUrl: defineIcone(infoPonto.potipo),
        iconSize: [34, 34],
        iconAnchor: [20, 38],
        shadowAnchor: [10, 10],
    });
}
