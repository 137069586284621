<template>
    <panelEagle id='cadastroDeAgendamento' :loading='loadingPanel'>
        <div class="col-lg-12 row">
        <div class="col-lg-6 nopadding ">
            <titulo titulo='Agendar manutenção' :icon='icone'/>
        </div>
        <div class="col-lg-6 divDontPrint nopadding">
            <basicButtonsCadastrosEIN
            :loading='loading'
            tipoNovo="button"
            :disabledDropdown="dataTable.length == 0"
            @novoCadastro="novoCadastro"
            @exportarCadastro='exportar'/>
        </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <div class="col-lg-12 row nopadding">
            <div class="col-lg-4  left divDontPrint nopadding">
                <selectAll
                    nameForRadio='FiltroEmpresas'
                    :labels="labelsEmpresas"
                    :isMultiple='true'
                    :optionsArray='optSelectEmpresas'
                    :selected="selectedEmpresa"
                    :hasSelectAll='true'
                    @changeSelect='changeSelectEmpresas'>
                </selectAll>
            </div>
            <div class="col-lg-2  left divDontPrint nopadding">
                <selectAll
                    nameForRadio='FiltroTipoManutencao'
                    :labels="labelsTipo"
                    :isMultiple='false'
                    :optionsArray='optionsTipoManutencao'
                    :hasSelectAll='false'
                    @changeSelect='changeSelectTipoManutencao'>
                </selectAll>
            </div>
            <div class="col-lg-6  left divDontPrint nopadding">
                <buttonsFilters
                    label='Status'
                    :arrayButtons='optFiltroStatus'
                    @buttonsFiltersChange='mudaFiltroStatus'/>
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <div class="col-lg-12 nopadding">
            <tableListagem
                :data='dataTable'
                :titles='tituloTable'
                :hablitado='true'
                :tableRowClassName='backgroundTable'
                deleteEdit='editDelete'>
                <template #acoes="{row}">
                    <simpleButton 
                        type='light-green'
                        width='30%'
                        event='click'
                        title='Realizado'
                        :icon='mdiCheckBold'
                        :disabled="filtroStatus == 'R'"
                        @click="confirmarManutencao(row.macodigo, row.timdescricao, row.vchodometro)"
                    />
                    <simpleButton
						v-if="getEditar()"
                        type="green"
                        :icon='mdiPencil'
                        title='Editar'
                        width='30%'
                        event='click'
                        :disabled="filtroStatus == 'R'"
                        @click="editarManutencao(row.macodigo, row.timdescricao, row.mapkmprogramado)"
                    />
                    <simpleButton
						v-if="getExcluir()"
                        type="red"
                        :icon='mdiTrashCanOutline'
                        title='Deletar'
                        width='30%'
                        event='click'
                        @click="modalDeletarAgendamentoManutencao(row.macodigo)"
                    />
                </template>
            </tableListagem>
        </div>
        <!-- Modal Cadastro de manutenção -->
        <template>
            <modalEagle 
                id ="modalCadastro"
                title="Cadastrar manutenção"
                size  ='xl'
                :lazy='false'
                @show="modalCadastro()">
                <template slot="modalBody">
                    <b-overlay :show="loadingModal">
                        <div class="col-lg-12 row nopadding">
                            <div class="col-lg-6 divDontPrint">
                                <selectAll
                                    nameForRadio='FiltroEmpresas'
                                    :labels="labelsEmpresas"
                                    :isMultiple='false'
                                    :optionsArray='optSelectEmpresas'
                                    :hasSelectAll='false'
                                    ref="filtroEmpresasModalCadastro"
                                    @changeSelect='changeSelectEmpresasModal'>
                                </selectAll>
                            </div>
                        </div>
                        <div class="col-lg-12 row nopadding mt-3">
                            <div class="col-lg-6 divDontPrint">
                                <selectAll
                                    nameForRadio='FiltroTipoManutencao'
                                    :labels="labelsTipo"
                                    :isMultiple='false'
                                    :optionsArray='optionsTipoManutencaoModal'
                                    :hasSelectAll='false'
                                    :loading='loadingVeiculos'
                                    ref="filtroTipoManutencaoCadastro"
                                    :disabled="$v.empresasSelecionadasModal.$invalid"
                                    @changeSelect='changeSelectTipoManutencaoModal'>
                                </selectAll>
                            </div>
                            <div class="col-lg-6 divDontPrint">
                                <selectAll
                                    nameForRadio='FiltroVeiculos'
                                    :labels="[{indexDFH: 'V', description  : 'Veículos'}]"
                                    :isMultiple='false'
                                    :optionsArray='optionsVeiculosModal'
                                    :hasSelectAll='false'
                                    :loading='loadingVeiculos'
                                    ref="filtroVeiculosCadastro"
                                    :disabled="$v.empresasSelecionadasModal.$invalid"
                                    @changeSelect='changeSelectVeiculosModal'>
                                </selectAll>
                            </div>
                        </div>
                        <div class="col-lg-12 row nopadding mt-3">
                            <div class="col-lg-4 divDontPrint">
                                <inputSimple
                                    name="kmAtual"
                                    label="Km atual do veículo"
                                    type="text"
									:hasMask="true"
									:mask="['NNNNNNNNN']"
                                    :disabled="veiculosSelecionadosModal.length == 0"
                                    :loading="loadingKmVeiculo"
                                    :value="hodometroCadastro"
                                    @changeInput="changeKmAtualVeiculo">
                                </inputSimple>
                            </div>
                            <div class="col-lg-4  left divDontPrint">
                                <inputSimple
                                    name="kmPadrao"
                                    label="Km padrão manutenção"
                                    type="text"
									:hasMask="true"
									:mask="['NNNNNNNNN']"
                                    :value="kmPadraoCadastro.timkmpadrao"
                                    @changeInput="changeKmPadraoManutencao">
                                </inputSimple>
                            </div>
                            <div class="col-lg-4  left divDontPrint">
                                <inputSimple
                                    name="kmProximaManutencao"
                                    label="Próxima manutenção"
                                    type="text"
									:hasMask="true"
									:mask="['NNNNNNNNN']"
                                    :value="Number(hodometroCadastro)+Number(kmPadraoCadastro.timkmpadrao)"
                                    @changeInput="changeKmProximaManutencao">
                                </inputSimple>
                            </div>
                        </div>
                    </b-overlay>
                </template>
                <template>
                    <simpleButton
                        type="red"
                        :icon='mdiTrashCanOutline'
                        text='Cancelar'
                        event='click'
                        @click="cancelarModalCadastro"
                    />
                    <simpleButton 
                        type='blue'
                        event='click'
                        text='Salvar'
                        :icon='mdiCheckBold'
                        :disabled="$v.$invalid"
                        @click="salvarCadastro"
                    />
                </template>
            </modalEagle>
        </template>

        <!-- Modal Editar agendamento de manutenção -->
        <template>
            <modalEagle 
                id ="modalEditarCadastro"
                title="Editar agendamento de manutenção "
                size  ='lg'
                @show="modalEditarCadastro()">
                <template slot="modalBody">
                    <b-overlay :show="loadingModal">
                        <div class="col-lg-12 divDontPrint row">
                            <div class="col-lg-6">
                                Digite os Kms da próxima: {{dadosModalEdicao}}
                            </div>
                            <div class="col-lg-6">
                                <inputSimple
                                    name="kmAtual"
                                    type="text"
									:hasMask="true"
									:mask="['NNNNNNNNN']"
                                    :value="kmAtualEdicao"
                                    @changeInput="changeKmModalEdicao">
                                </inputSimple>
                            </div>
                        </div>
                    </b-overlay>
                </template>
                <template>
                    <simpleButton
                        type="red"
                        :icon='mdiTrashCanOutline'
                        text='Cancelar'
                        event='click'
                        @click="cancelarModalEditar"
                    />
                    <simpleButton 
                        type='blue'
                        event='click'
                        text='Salvar'
                        :icon='mdiCheckBold'
                        @click="editarCadastro"
                    />
                </template>
            </modalEagle>
        </template>

        <!-- Modal confirmar agendamento de manutenção -->
        <template>
            <modalEagle 
                id ="modalConfirmarCadastro"
                title="Confirmar agendamento de manutenção "
                size  ='lg'
                @show="modalConfirmarCadastro()"
                @confirmButton="salvarCadastro">
                <template slot="modalBody">
                    <b-overlay :show="loadingModal">
                        <div class="col-lg-12 divDontPrint row">
                            <div class="col-lg-6">
                                Informe a quilometragem atual do veículo para confirmar a realização da 
                                {{dadosModalConfirmacao}}
                            </div>
                            <div class="col-lg-6">
                                <inputSimple
                                    name="kmAtual"
                                    type="text"
									:hasMask="true"
									:mask="['NNNNNNNNN']"
                                    :value="kmAtualConfirmar"
                                    @changeInput="changeKmModalConfirmacao">
                                </inputSimple>
                            </div>
                        </div>
                    </b-overlay>
                </template>
                <template>
                    <simpleButton
                        type="red"
                        :icon='mdiTrashCanOutline'
                        text='Cancelar'
                        event='click'
                        @click="cancelarModalConfirmar"
                    />
                    <simpleButton 
                        type='blue'
                        event='click'
                        text='Salvar'
                        :icon='mdiCheckBold'
                        @click="confirmarManutencaoSalvar"
                    />
                </template>
            </modalEagle>
        </template>

        <!-- Modal Remover agendamento de manutenção -->
        <template>
            <modalDeleta
                id="1"
                @confirmaExclusao="excluirAgendamento">
            </modalDeleta>
        </template>
    </panelEagle>
</template>
<script>
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { required } from 'vuelidate/lib/validators'
import { mdiCalendarClock, mdiPencil, mdiTrashCanOutline, mdiCheckBold } 
	from '@mdi/js'
import { conectionError } from '@/Services/Helpers/swellHeper'
import Vue from 'vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
	name:'agendarManutencao',
	components:{
		'basicButtonsCadastrosEIN': require(
			'@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
		'modalDeleta': require(
			'@/components/Atom/ModalSpecific/ModalDeleta').default,
		'buttonsFilters': require(
			'@/components/Atom/Buttons/ButtonsFilters').default,
		'tableListagem': require(
			'@/components/Atom/Table/TableListagem').default,
		'simpleButton': require(
			'@/components/Atom/Buttons/SimpleButton').default,
		'modalEagle': require(
			'@/components/Atom/Modal/ModalEagle.vue').default,
		'inputSimple': require('@/components/Atom/Inputs/InputSimple').default,
		'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
		'selectAll': require('@/components/Atom/Select/SelectAll').default,
		'titulo': require('@/components/Atom/Header/Titulo').default,
	},

	validations:{
		empresasSelecionadasModal:{
			required
		},
		tipoManutencaoModalSelecionado:{
			required
		},
		veiculosSelecionadosModal:{
			required
		},
		hodometroCadastro:{
			required
		},
		kmPadraoManutencaoInformadoModal:{
			required
		},
		proximaManutencaoInformadoModal:{
			required
		},
	},

	data(){
		return{
			icone              : mdiCalendarClock,
			mdiPencil          : mdiPencil,
			mdiTrashCanOutline : mdiTrashCanOutline,
			mdiCheckBold       : mdiCheckBold,
			optSelectEmpresas: new EmpresasService().Get(),
			selectedEmpresa:[],
			labelsEmpresas:[
				{indexDFH     : 'EM', 
					description  : 'Empresas'}
			],
			labelsTipo:[
				{indexDFH     : 'TM', 
					description  : 'Tipo de manutenção'}
			],

			empresasSelecionadas      : [],
			tipoManutencaoSelecionado : [],
			optionsTipoManutencao     : [],
			backgroundTable           : '',
			codigoExcluir             : '',
			loadingModal              : false,

			// Modal cadastro
			optionsTipoManutencaoModal       : [],
			optionsVeiculosModal             : [],
			empresasSelecionadasModal        : [],
			tipoManutencaoModalSelecionado   : [],
			veiculosSelecionadosModal        : [],
			kmPadraoManutencaoInformadoModal : '',
			proximaManutencaoInformadoModal  : '',
			loadingVeiculos                  : false,
			hodometroCadastro                : '',
			kmPadraoCadastro                 : {},
			loadingKmVeiculo                 : '',

			//Modal Edição
			dadosModalEdicao                 : '',
			codigoEdicao                     : '',
			kmProximaManutencaoEditar        : '',
			kmAtualEdicao                    : '',

			// Modal Confirmação
			dadosModalConfirmacao            : '',
			kmAtuaModalConfirmar             : '',
			kmAtualConfirmar                 : '',

			//misc
			loading       : Array(false, false, false),
			dataTable     : [],
			filtroStatus  : '',

			//Botoes:
			optFiltroStatus:[
				{text:'Agendados',  value:'A', width:'24%', isSelected:true,  extraClass:''},
				{text:'Próxima',    value:'PR', width:'24%', isSelected:false, extraClass:''},
				{text:'Vencida',    value:'V', width:'24%', isSelected:false, extraClass:''},
				{text:'Realizadas', value:'R', width:'24%', isSelected:false, extraClass:''},
			],
			tituloTable: [
				{name:'Placa',           number:'veplaca',      width:'90',},
				{name:'Prefixo',         number:'veprefixo',    width:'90'},
				{name:'Descrição',       number:'vedescricao'},
				{name:'Tipo manutenção', number:'timdescricao', width:'180'},
				{name:'Km atual',        number:'vchodometro'},
				{name:'Km manutenção',   number:'mapkmprogramado'},
				{name:'Empresa',         number:'clfantasia'},
			],
			//link
			link  : '/manutencoes/nao/menus/agendamento/manutencao/',
			// misc
			loadingPanel: false,
		}
	},
	methods:{
		...mapGetters(['getMaster', 'getEditar', 'getExcluir']),
		novoCadastro(){
			this.empresasSelecionadasModal = []
			this.tipoManutencaoModalSelecionado = []
			this.veiculosSelecionadosModal = []
			this.kmPadraoManutencaoInformadoModal = ''
			this.hodometroCadastro = ''
			this.kmPadraoCadastro  = ''
			this.$bvModal.show('modalCadastro')
		},

		async salvarCadastro(){
			this.loadingModal = true
			var body = {'dados':{
				'mapcodigoveiculo': this.veiculosSelecionadosModal[0],
				'mapkmprogramado': this.proximaManutencaoInformadoModal,
				'maptipomanutencao': this.tipoManutencaoModalSelecionado[0],
				'mapcliente': this.empresasSelecionadasModal[0],
			}}
			new HttpRequest().Post(this.link+'salvar', body)
				.then((data)=>{
					if(data.status && data.data == 201){
						this.$bvModal.hide('modalCadastro')
						this.listagem(this.empresasSelecionadas)
					} else {
						conectionError()
					}
				})
				.finally(() => this.loadingModal = false)
		},

		changeSelectEmpresas(empresas){
			this.empresasSelecionadas = empresas
			this.listagem(empresas)
			this.buscaTipoManutencao(empresas)
		},

		changeSelectTipoManutencao(tipoManutencao){
			this.tipoManutencaoSelecionado = tipoManutencao
			this.listagem(this.empresasSelecionadas)
		},
        
		excluirAgendamento(){
			new HttpRequest().Post(this.link+'excluir', 
				{'codigo' : this.codigoExcluir})
				.then((data)=>{
					if(data.data){
						this.listagem(this.empresasSelecionadas)
					} else {
						conectionError()
					}
				})
		},

		mudaFiltroStatus(value){
			this.filtroStatus = value[0];
			switch (value[0]) {
				case 'A':
					this.optFiltroStatus[0].extraClass = 'borderblueButton'
					this.optFiltroStatus[1].extraClass = 'orangeButton'
					this.optFiltroStatus[2].extraClass = 'redButton'
					this.optFiltroStatus[3].extraClass = 'greenButton'
					this.backgroundTable               = 'bluee'
					break;

				case 'PR':
					this.optFiltroStatus[0].extraClass = 'blueButton'
					this.optFiltroStatus[1].extraClass = 'borderOrangeButton'
					this.optFiltroStatus[2].extraClass = 'redButton'
					this.optFiltroStatus[3].extraClass = 'greenButton'
					this.backgroundTable               = 'orange'
					break;

				case 'V':
					this.optFiltroStatus[0].extraClass = 'blueButton'
					this.optFiltroStatus[1].extraClass = 'orangeButton'
					this.optFiltroStatus[2].extraClass = 'borderRedButton'
					this.optFiltroStatus[3].extraClass = 'greenButton'
					this.backgroundTable               = 'red'
					break;

				case 'R':
					this.optFiltroStatus[0].extraClass = 'blueButton'
					this.optFiltroStatus[1].extraClass = 'orangeButton'
					this.optFiltroStatus[2].extraClass = 'redButton'
					this.optFiltroStatus[3].extraClass = 'borderGreenButton'
					this.backgroundTable               = 'green'
					break;
            
				default:
					break;
			}
			if(this.empresasSelecionadas.length > 0){
				this.listagem(this.empresasSelecionadas);
			}
		},

		async listagem(empresa){
			this.loadingPanel = true
			new HttpRequest().Post(this.link+'listar', 
				{'clientesbusca' : empresa, 
					'filtro'         : this.filtroStatus,
					'tipoManutencao' : this.tipoManutencaoSelecionado})
				.then((data)=>{
					this.dataTable = data.data.manutencoes;
					this.loadingPanel = false
				})
		},

		async buscaTipoManutencao(empresas){
			new HttpRequest().Post(this.link+'tipo/manutencao', 
				{'clientesbusca': empresas})
				.then((data)=>{
					this.optionsTipoManutencao = data.data.tipo_manutencao
				})
		},

		// Modal Cadastrar Manutenção
		cancelarModalCadastro(){
			this.$bvModal.hide('modalCadastro')
		},

		changeSelectEmpresasModal(empresasModal){
			this.empresasSelecionadasModal = empresasModal
			this.buscaTipoManutencaoVeiculos(empresasModal)
			this.kmPadraoManutencaoInformadoModal = ''
			this.hodometroCadastro = ''
			this.kmPadraoCadastro  = ''
			this.$refs.filtroTipoManutencaoCadastro.clearAll()
			this.$refs.filtroVeiculosCadastro.clearAll()
		},

		changeSelectTipoManutencaoModal(tipoManutencaoModal){
			this.tipoManutencaoModalSelecionado = tipoManutencaoModal
			this.kmPadraoManutencaoInformadoModal = ''
			this.kmPadraoCadastro  = ''
			if(tipoManutencaoModal.length > 0){
				this.buscaKmPadrao(tipoManutencaoModal)
			}
		},

		changeSelectVeiculosModal(veiculosModal){
			this.hodometroCadastro = ''
			this.veiculosSelecionadosModal = veiculosModal
			if(veiculosModal.length > 0){
				this.buscaHodometro(veiculosModal)
			}
		},

		changeKmAtualVeiculo(kmAtualVeiculo){
			this.hodometroCadastro = kmAtualVeiculo
		},

		changeKmPadraoManutencao(kmPadraoManutencao){
			this.kmPadraoManutencaoInformadoModal = kmPadraoManutencao
		},

		changeKmProximaManutencao(kmAtual){
			this.proximaManutencaoInformadoModal = kmAtual
		},

		async buscaTipoManutencaoVeiculos(empresas){
			this.loadingVeiculos = true
			new HttpRequest().Post(this.link+'tipo/manutencao', 
				{'clientesbusca': empresas})
				.then((data)=>{
					this.optionsTipoManutencaoModal = data.data.tipo_manutencao
					this.optionsVeiculosModal       = data.data.veiculos
					this.loadingVeiculos = false
				})
		},

		async buscaHodometro(veiculosModal){
			this.loadingKmVeiculo = true
			new HttpRequest().Post(this.link+'busca/hodometro', 
				{'veiculo': veiculosModal})
				.then((data)=>{
					this.hodometroCadastro = data.data.vchodometro
					this.loadingKmVeiculo = false
				})
		},

		buscaKmPadrao(tipoManutencaoModal){
			this.kmPadraoCadastro = this.optionsTipoManutencaoModal.find(a=>{
				return a.value == tipoManutencaoModal
			})
		},

		// Modal Edição
		editarManutencao(codigo, descricao, kmAtualEdicao){
			this.codigoEdicao = codigo
			this.dadosModalEdicao = descricao
			this.kmAtualEdicao    = kmAtualEdicao
			this.$bvModal.show('modalEditarCadastro')
		},

		changeKmModalEdicao(dados){
			this.kmProximaManutencaoEditar = dados
		},

		async editarCadastro(){
			this.loadingModal = true
			var body = {'dados': {
				'macodigo': this.codigoEdicao,
				'mapkmprogramado': this.kmProximaManutencaoEditar,
			}}
			new HttpRequest().Post(this.link+'salvar', body)
				.then((data)=>{
					if(data.status && data.data == 201){
						this.$bvModal.hide('modalEditarCadastro')
						this.listagem(this.empresasSelecionadas)
					} else {
						conectionError()
					}
				})
				.finally(() => this.loadingModal = false)
		},

		cancelarModalEditar(){
			this.$bvModal.hide('modalEditarCadastro')
		},

		/**
		 * @description Modal Confirmação
		 */
		confirmarManutencao(codigo, descricao, kmAtualConfirmar){
			this.codigoEdicao = codigo
			this.dadosModalConfirmacao = descricao
			this.kmAtualConfirmar = kmAtualConfirmar
			this.$bvModal.show('modalConfirmarCadastro')
		},

		changeKmModalConfirmacao(dados){
			this.kmAtuaModalConfirmar = dados
		},

		async confirmarManutencaoSalvar(){
			this.loadingModal = true
			let url = this.link+'confirmar/manutencao'
			var params = {'dados' : {
				'macodigo': this.codigoEdicao,
				'mapkmrealizado': this.kmAtuaModalConfirmar
			}}
			let dados = await new HttpRequest().Post(url, params)
			if(dados.status && dados.data == 201){
				this.$bvModal.hide('modalConfirmarCadastro')
				this.listagem(this.empresasSelecionadas)
			} else {
				conectionError()
			}
			this.loadingModal = false
		},

		cancelarModalConfirmar(){
			this.$bvModal.hide('modalConfirmarCadastro')
		},

		/**
		 * @description Modal deletar Agendamento Manutenção
		 */
		modalDeletarAgendamentoManutencao(codigo){
			this.codigoExcluir = codigo
			this.$bvModal.show('modal-deleta-'+1)
		},

		async exportar(value){
			this.setLoading(value)
			let url = this.link+'exportar'
			let params = {
				'tipo': value, 
				'dados': this.dataTable, 
				'filtros': this.filtroStatus}
			let dados = await new HttpRequest().Post(url, params)
			if(dados.status) {
				let root =  process.env.VUE_APP_ROOT
				window.open(root+'/'+dados.data.local)
			}
			this.setLoading()
		},

		setLoading(tipo=null){
			this.loading = Array(tipo=='pdf', tipo=='xls', tipo=='csv')
		},
	},
	mounted() {
		if(!this.getMaster()){
			this.selectedEmpresa = this.optSelectEmpresas
		}
	},
})
</script>

<style lang="scss">
    #cadastroDeAgendamento{
        .greenButton{
            background: #dff0d8;
        }
        .orangeButton{
            background: #fcf8e3;
        }
        .redButton{
            background: #f2dede;
        }
        .blueButton{
            background: #b3d7ff;
        }

        .borderGreenButton{
            background: #dff0d8;
            color: #000;
            border: 2.3px solid #000;
        }
        .borderOrangeButton{
            background: #fcf8e3;
            color: #000;
            border: 2.3px solid #000;
        }
        .borderRedButton{
            background: #f2dede;
            color: #000;
            border: 2.3px solid #000;
        }
        .borderblueButton{
            background: #b3d7ff;
            color: #000;
            border: 2.3px solid #000;
        }
    }
</style>