<template>
    <modalEagle id="modalAdicionarEvento" title="Adicionar evento" size="lg">
        <slot slot="modalBody">
            <b-overlay :show="loadingEventos">
                <div class="col-sm-12 row">
                    <div class="col-sm-4 row tamSelectsAdicionarEvento nopadding">
                        <!-- Eventos -->
                        <selectAll
                            nameForRadio="seletorEventosModalAdicionarEvento"
                            :isMultiple="false"
                            :labels="labelsSelectEventosModal"
                            :optionsArray="optSelectEventos"
                            class="nopadding"
                            firstSelected="EV"
                            :hasSelectAll="false"
                            @changeSelect="changeEventosModal"
                        >
                        </selectAll>
                    </div>
                    <div class="col-sm-4 row tamSelectsAdicionarEvento">
                        <!-- Tipo -->
                        <selectAll
                            nameForRadio="seletorTipoModalAdicionarEvento"
                            firstSelected="TI"
                            :hasSelectAll="false"
                            :isMultiple="false"
                            :optionsArray="optSelectTipoModal"
                            :labels="labelsSelectTipo"
                            ref="selectCM"
                            :loading="carregando"
                            @changeSelect="changeTipoModal"
                        />
                    </div>
                    <div class="col-sm-4 row tamSelectsAdicionarEvento nopadding">
                        <!-- Veiculo -->
                        <selectAll
                            nameForRadio="SeletorVeiculosModalAdcEvento1"
                            :isMultiple="false"
                            :labels="labelsSelectVeiculosModal"
                            :optionsArray="optSelectVeiculos"
                            firstSelected="E"
                            :hasSelectAll="false"
                            :loading="carregando"
                            class="nopadding"
                            ref="selectVM"
                            @changeSelect="changeVeiculoModal"
                        >
                        </selectAll>
                    </div>
                </div>
                <div class="col-sm-12 row mt-2">
                    <div class="col-sm-4 row tamSelectsAdicionarEvento nopadding">
                        <!-- Data -->
                        <inputSingleDay
                            @changeInput="changeDataSelecionado"
                            name="inputDataRota"
                            class="nopadding"
                            :value="dataInicial"
                        >
                        </inputSingleDay>
                        <div
                            class="col-sm-12 fechamento"
                            v-show="possuiFechamento[1]"
                            :title="msgFechamento"
                        >
                            Possui fechamento de {{ possuiFechamento[1] }}
                            <baseIcon :icon="mdiHelpCircleOutline" size="16" />
                        </div>
                    </div>
                    <div class="col-sm-4 row tamSelectsAdicionarEvento">
                        <!-- Hora -->
                        <inputSimple
                            name="inputHorarioModalAdicionarEvento"
                            type="time"
                            value="00:00:00"
                            label="Horário"
                            @changeInput="changeHorarioSelecionado"
                        >
                        </inputSimple>
                    </div>
                    <div class="col-sm-4 row tamSelectsAdicionarEvento nopadding">
                        <!-- Ponto -->
                        <selectAll
                            nameForRadio="SeletorVeiculosModalAdcEvento2"
                            :isMultiple="false"
                            :labels="labelsSelectPontos"
                            :optionsArray="optSelectPontos"
                            firstSelected="E"
                            :hasSelectAll="false"
                            :loading="carregando"
                            class="nopadding"
                            ref="selectVM"
                            @changeSelect="changePontosModal"
                        >
                        </selectAll>
                    </div>
                    <div class="col-sm-12 mt-3" style="color: red" v-if="msgValidation">
                        {{ msgValidation }}
                    </div>
                </div>
            </b-overlay>
        </slot>
        <template>
            <simpleButton
                :icon="mdiCloseThick"
                @click="cancelarHorasColaborador"
                text="Cancelar"
                event="click"
                type="red"
            />
            <loadingButton
                :icon="mdiCheckBold"
                class="loadingButtonBancoHoras"
                @click="adicionarHoras"
                text="Confirmar"
                event="click"
                :isLoading="carregandoHora"
                :disabled="$v.$invalid || msgValidation != '' || possuiFechamento[0]"
                type="blue"
            />
        </template>
    </modalEagle>
</template>
<script>
import Vue from "vue";
import {
    mdiCarSettings,
    mdiAccountSettings,
    mdiHelpCircleOutline,
    mdiCheckBold,
    mdiCloseThick,
} from "@mdi/js";
import { required } from "vuelidate/lib/validators";
import { validaUltimoFechamentoPorDia } from "@/Services/Helpers/FechamentoHelper";
import { HttpRequest } from "../../../Services/auth/HttpRequest.Service";
import { FiltrosService } from "../../../Services/filtros/filtros.Service";
import { conectionError } from "@/Services/Helpers/swellHeper";
import { DateTime } from "luxon";

export default Vue.extend({
    name: "ModalAdicionarEvento",
    components: {
        simpleButton: require("@/components/Atom/Buttons/SimpleButton").default,
        modalEagle: require("@/components/Atom/Modal/ModalEagle").default,
        selectAll: require("@/components/Atom/Select/SelectAll").default,
        loadingButton: require("@/components/Atom/Buttons/LoadingButton").default,
        inputSimple: require("@/components/Atom/Inputs/InputSimple").default,
        baseIcon: require("@/components/Atom/Icon/BaseIcon").default,
        inputSingleDay: require("@/components/Atom/Datas/InputSingleDay").default,
    },

    validations: {
        tipoSelecionado: { required },
        empresaSelecionadas: { required },
        veiculoSelecionado: { required },
        horarioSelecionado: { required },
        dataInput: { required },
    },

    props: {
        idModal: {
            type: [Number, String],
            required: true,
        },
    },

    data() {
        return {
            url: "/controle/jornada/relatorios/folha/ponto",
            mdiCarSettings: mdiCarSettings,
            mdiAccountSettings: mdiAccountSettings,
            mdiCheckBold: mdiCheckBold,
            mdiCloseThick: mdiCloseThick,
            mdiHelpCircleOutline: mdiHelpCircleOutline,
            labelsSelectEventosModal: [{ indexDFH: "EV", description: "Eventos*" }],
            labelsSelectTipo: [{ indexDFH: "TI", description: "Tipo*" }],
            labelsSelectVeiculosModal: [{ indexDFH: "V", description: "Veículos*" }],
            labelsSelectPontos: [{ indexDFH: "P", description: "Pontos" }],
            optSelectEventos: [
                { description: "Abertura", value: "A" },
                { description: "Fechamento", value: "F" },
            ],
            optSelectTipoModal: [
                { description: "Jornada", value: "N" },
                { description: "Intervalo", value: "I" },
                { description: "Espera", value: "E" },
            ],
            colabSelecionado: null,
            optSelectColaboradores: [],
            empresaSelecionadas: [],
            veiculoSelecionado: [],
            optSelectVeiculos: [],
            eventoSelecionado: [],
            pontoSelecionado: [],
            tipoSelecionado: [],
            dataSelecionada: "",
            optSelectPontos: [],
            ultimoFechamento: [],
            possuiFechamento: [],
            msgFechamento:
                "Após realizar fechamento não é possível alterar jornada dos colaboradores",
            baseCalculo: "",
            horarioSelecionado: "",
            dataInput: "",
            msgValidation: "",
            dataInicial: null,
            carregandoHora: false,
            loadingEventos: false,
            carregando: false,

            justificativaSelecionada: null,
        };
    },

    watch: {
        dataSelecionada(value) {
            if (value) {
                let datas = value.split(" - ");
                if (datas && datas.length) {
                    this.dataInicial = datas[0];
                } else this.dataInicial = null;
            } else this.dataInicial = null;
            this.dataInput = this.dataInicial;
        },

        dataInput(value) {
            let msg = "";
            if (this.dataSelecionada && value) {
                let datas = this.dataSelecionada.split(" - ");
                let dataIni = DateTime.fromFormat(datas[0], "dd/LL/yyyy");
                let dataFim = DateTime.fromFormat(datas[1], "dd/LL/yyyy");
                let data = DateTime.fromFormat(value, "dd/LL/yyyy");
                let diffIni = dataIni.diff(data);
                let diffFim = dataFim.diff(data);
                if (diffIni.as("days") != 0 && diffFim.as("days") != 0) {
                    msg = "A data selecionada está fora do período informado!";
                }
            }
            this.msgValidation = msg;
        },
    },

    methods: {
        /**
         * @description Essa função deve ser utilizada por uma referência para "ativar"
         * essa modal, os argumentos da função são os necessários para
         * conseguir popular a modal com as infomações corretas
         * @param {array}  empresaSelecionadas - empresas selecionadas no relatório
         * @param {string} dataSelecionada - data do campo selecionado
         * @param {int}  colabSelecionado - colaborador selecionado no relatório
         * @param {int}  justificativa - justificativa selecionada no modal
         * @author Vitor Hugo 🐨
         */
        preparaModalAdicionarEventos(empresa, data, colaborador, justificativa, ultimoFechamento) {
            this.resetarModal();
            this.empresaSelecionadas = empresa;
            this.dataSelecionada = data;
            this.colabSelecionado = colaborador;
            this.justificativaSelecionada = justificativa;
            this.ultimoFechamento = ultimoFechamento;
            this.$bvModal.show(this.idModal);
            this.buscarVeiculos(empresa);
            this.buscarPontos(empresa);
        },
        changeEventosModal(evento) {
            this.eventoSelecionado = evento;
        },
        changeTipoModal(tipo) {
            this.tipoSelecionado = tipo;
        },
        changeVeiculoModal(veiculo) {
            this.veiculoSelecionado = veiculo;
        },
        changeHorarioSelecionado(horario) {
            this.horarioSelecionado = horario;
        },
        changeDataSelecionado(data) {
            this.dataInput = data;
            this.validaFechamentoDentroDoIntervalo();
        },
        changePontosModal(ponto) {
            this.pontoSelecionado = ponto;
        },
        cancelarHorasColaborador() {
            this.$bvModal.hide(this.idModal);
        },
        validaFechamentoDentroDoIntervalo() {
            if (this.colabSelecionado && this.ultimoFechamento) {
                let formato = "dd/LL/yyyy";
                this.possuiFechamento = validaUltimoFechamentoPorDia(
                    this.ultimoFechamento,
                    this.dataInput,
                    formato
                );
            }
        },
        async adicionarHoras() {
            this.loadingEventos = true;
            this.carregandoHora = true;
            try {
                var carro = [];
                this.optSelectVeiculos.forEach((element) => {
                    if (this.veiculoSelecionado == element.value) {
                        carro = element.description;
                    }
                });
                this.veiculoSelecionado = carro.split("|");
                var dados = await new HttpRequest().Post(this.url + "/adicionarHoras", {
                    tipoEvento: this.eventoSelecionado[0],
                    evento: this.tipoSelecionado[0],
                    horario: this.horarioSelecionado,
                    data: this.dataInput,
                    empresa: this.empresaSelecionadas[0],
                    colaborador: this.colabSelecionado,
                    veiculo: this.veiculoSelecionado[0],
                    perfilMotorista: "M",
                    ponto: this.pontoSelecionado[0],
                    justificativa: this.justificativaSelecionada,
                });
                if (dados.code == 200) {
                    this.$emit("atualizaAjustarColaborador");
                    this.$bvModal.hide("modalAdicionarEvento");
                }
            } catch {
                conectionError();
            } finally {
                this.loadingEventos = false;
                this.carregandoHora = false;
            }
        },
        buscarVeiculos(arr) {
            if (arr.length > 0) {
                this.carregando = true;
                new FiltrosService().dados_filtros(arr, ["V"]).then((data) => {
                    this.dataV = data.V;
                    this.optSelectVeiculos = this.dataV;
                });
                this.carregando = false;
            } else {
                this.colabSelecionado = null;
                this.veiculoSelecionado = [];
                this.carregando = false;
            }
        },
        async buscarPontos(arr) {
            this.carregando = true;
            var dados = await new HttpRequest().Post(this.url + "/buscaPontos", {
                cliente: arr[0],
            });
            this.optSelectPontos = dados.data.pontos;
            this.optSelectColaboradores = dados.data.colabs;
            if (this.optSelectColaboradores.length > 0) {
                this.baseCalculo = dados.data.colabs[0].basecalculo;
            }
            this.carregando = false;
        },
        /**
         * @description reseta as informações da modal
         * de volta para os valores default.
         */
        resetarModal() {
            this.justificativaSelecionada = null;
            this.colabSelecionado = null;
            this.optSelectColaboradores = [];
            this.empresaSelecionadas = [];
            this.veiculoSelecionado = [];
            this.optSelectVeiculos = [];
            this.eventoSelecionado = [];
            this.pontoSelecionado = [];
            this.tipoSelecionado = [];
            this.dataInput = "";
            this.horarioSelecionado = "";
            this.dataSelecionada = "";
            this.optSelectPontos = [];
        },
    },
});
</script>

<style lang="scss"></style>
