<template>
    <PanelEagle id="bancoTickets" :loading="loadingPanel">
        <div class="col-sm-12 row nopadding">
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiFolderArrowDown" titulo="Banco de Tickets" />
                </div>
                <div class="col-sm-6">
                    <SimpleButton
                        text="Gerar"
                        :icon="mdiThumbUpOutline"
                        type="green"
                        event="gerar"
                        @gerar="gerarArquivo"
                        style="float:right;"
                        :disabled="$v.$invalid"
                    />
                </div>
                <slideUpAndDown>
                    <slot slot="filters">
                        <div class="col-sm-12"><hr /></div>
                        <div class="col-sm-12 row nopadding divDontPrint">
                            <div class="col-sm-3 nopadding">
                                <InputRangeWithLimit
                                    name="selectData"
                                    titulo="Intervalo"
                                    :isObrigatorio="true"
                                    @changeInput="changeData"
                                />
                            </div>
                            <div class="col-sm-3 nopadding">
                                <SelectAll
                                    ref="selectEmpresasTickets"
                                    nameForRadio="seletorEmpresas"
                                    :labels=" [{ 
                                        indexDFH: 'E', 
                                        description: 'Empresas*'
                                    }]"
                                    :optionsArray="optionsSelectEmpresas"
                                    firstSelected="E"
                                    :hasSelectAll="true"
                                    :isMultiple="true"
                                    @changeSelect="changeEmpresas"
                                />
                            </div>
                            <div class="col-sm-3 nopadding">
                                <SelectAll
                                    ref="selectContratos"
                                    nameForRadio="radioContratos"
                                    :disabled="$v.empresasSelecionadas.$invalid"
                                    :labels="labelsSelectContratos"
                                    :isMultiple="true"
                                    :hasSelectAll="true"
                                    :optionsArray="optionsContratos"
                                    :loading="loadingContratos"
                                    @changeSelect="changeContratos"
                                />
                            </div>
                            <div class="col-sm-3 nopadding">
                                <SelectAll
                                    nameForRadio="radioPontos"
                                    ref="selectPontos"
                                    :disabled="$v.empresasSelecionadas.$invalid"
                                    :labels="labelsSelectPontos"
                                    :isMultiple="true"
                                    :hasSelectAll="true"
                                    :optionsArray="optionsPontos"
                                    :loading="loadingPontos"
                                    @changeSelect="changePontos"
                                />
                            </div>
                        </div>
                    </slot>
                </slideUpAndDown>
                <div class="col-sm-12"><hr /></div>
                <div class="col-sm-12">
                    <statusInformation typeBar="div" statusBar="info" />
                </div>
            </div>
        </div>
    </PanelEagle>
</template>
<script>

import Vue from 'vue'
import { required, requiredIf} from 'vuelidate/lib/validators'
import { mdiFolderArrowDown, mdiThumbUpOutline } from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service';
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import slideUpAndDown from '@/components/Atom/SlideUpAndDown/SlideUpAndDown.vue';
import InputRangeWithLimit from '@/components/Atom/Datas/InputRangeWithLimit.vue';
import SelectAll from '@/components/Atom/Select/SelectAll.vue';
import PanelEagle from '@/components/Atom/Panel/PanelEagle.vue';
import SimpleButton from '@/components/Atom/Buttons/SimpleButton.vue';
import { conectionError } from '@/Services/Helpers/swellHeper';

export default Vue.extend({
    name: 'BancoTickets',

    validations: {
        empresasSelecionadas: { required },
        pontosSelecionados: {
            requiredIf: requiredIf(function() {
                return !this.contratosSelecionados.length
            }),
        },
        contratosSelecionados: {
            requiredIf: requiredIf(function() {
                return !this.pontosSelecionados.length
            }),
        },
    },

    components: {
        tituloPage: require("@/components/Atom/Header/Titulo").default,
        statusInformation: require("@/components/Atom/StatusInformation/StatusInformation").default,
        slideUpAndDown,
        InputRangeWithLimit,
        SelectAll,
        PanelEagle,
        SimpleButton
    },

    data() {
        return {
            url: '/roteirizador/relatorios/banco/tickets/',
            mdiFolderArrowDown: mdiFolderArrowDown,
            mdiThumbUpOutline: mdiThumbUpOutline,
            loadingPanel: false,
            loadingContratos: false,
            loadingPontos: false,
            optionsPontos: [],
            optionsContratos: [],
            pontosSelecionados: [],
            contratosSelecionados: [],
            dataIntervalo: '',
            empresasSelecionadas: [],
            optionsSelectEmpresas: new EmpresasService().Get(),
            labelsSelectPontos: [
                { indexDFH: 'P', description: 'Pontos' },
            ],
            labelsSelectContratos: [
                { indexDFH: 'C', description: 'Contratos' },
            ],
        }
    },

    methods: {

        changeEmpresas(empresas) {
            this.relatorio = []
            this.empresasSelecionadas = empresas
            this.clearInput()
            this.getPontos()
            this.getContratos()
        },

        changeData(data) {
            this.dataIntervalo = data
            this.clearInput()
            this.getContratos()
        },

        changePontos(pontos) {
            this.pontosSelecionados = pontos
        },

        changeContratos(contratos) {
            this.contratosSelecionados = contratos
        },

        clearInput() {
            this.$refs.selectContratos.clearAll()
            this.$refs.selectPontos.clearAll()
        },

        getPontos() {
            const params = { empresa: this.empresasSelecionadas, }
            this.pontosSelecionados = []
            this.loadingPontos = true

            new HttpRequest().Post(this.url+'getPontos', params)
                .then((dados) => {
                    this.optionsPontos = dados.data.pontos
                })
                .catch(() => conectionError())
                .finally(() => this.loadingPontos = false)
        },

        getContratos() {
            if (!this.dataIntervalo || !this.empresasSelecionadas)
                return;

            const params = {
                data: this.dataIntervalo,
                empresa: this.empresasSelecionadas
            }
            this.contratosSelecionados = []
            this.loadingContratos = true

            new HttpRequest().Post(this.url+'getContratos', params)
                .then((dados) => {
                    this.optionsContratos = dados.data.contratos
                })
                .catch(() => conectionError())
                .finally(() => this.loadingContratos = false)
        },

        gerarArquivo() {
            const params = {
                data: this.dataIntervalo,
                empresas: this.empresasSelecionadas,
                contratos: this.contratosSelecionados,
                pontos: this.pontosSelecionados,
            }
            this.loadingPanel = true

            new HttpRequest().Post(this.url+'gerarArquivo', params)
                .then((response) => this.geraResposta(response))
                .catch(() => conectionError())
                .finally(() => this.loadingPanel = false)
        },

        geraResposta(response) {
            let msg, title, type = ''
            const HTTP_NO_CONTENT = 204
            const HTTP_OK = 200
            if (response.code == HTTP_OK) {
                msg = "Solicitação realizada com sucesso, " +
                    "irá receber uma notificação assim que estiver disponível para download"
                title = "Sucesso!"
                type = "success"
            } else if (response.code == HTTP_NO_CONTENT){
                msg = "Não foi possivel criar o arquivo"
                title = "Ops!"
                type = "danger"
            }
            this.toastShow(title, msg, type)
        },

        toastShow(title, msg, type) {
            this.$bvToast.toast(msg, {
                autoHideDelay: 2500,
                variant: type,
                title: title
            });
        },
    },
})
</script>
<style></style>
