<template>
    <panelEagle id='dashboardJornada' :loading='loadingPanel'>
       
    </panelEagle>
</template>
<script>
import Vue  from 'vue'
import {mdiArrowUpThick, mdiArrowDownThick} from '@mdi/js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { DateTime } from 'luxon'
export default Vue.extend({
	name:'DashboardJornada',
	
	components:{
		'panelEagle'           : require('@/components/Atom/Panel/PanelEagle').default,
		'indicador'            : require('@/components/Atom/Grafico/Indicador').default,
		'chartsColumn'         : require('@/components/Atom/Grafico/ChartsColumn').default,
		'chartsPizzaDashboard' : require('@/components/Atom/Grafico/ChartsPizzaDashboard').default,
		// 'simpleButton'         : require('@/components/Atom/Buttons/SimpleButton').default,
	},

	data(){
		return {
			mdiArrowUpThick: mdiArrowUpThick,
			mdiArrowDownThick: mdiArrowDownThick,
			horasExtrasRealizadasDados       : [],
			horasExtrasRealizadasLabel       : [],
			horasFaltaRealizadasLabel        : [],
			horasFaltasRealizadasDados       : [],
			colaboradoresHorasExtrasLabel    : [],
			colaboradoresHorasExtrasDados    : [],
			colaboradoresHorasFaltaLabel     : [],
			colaboradoresHorasFaltaDados     : [],
			horasFaltaHorasJustificadasLabel : [],
			horasFaltaHorasJustificadasDados : [],
			labelColaboradores               : [],
			valueGraficoColaboradores        : [],                  
			loadingPanel: false,
			semHorasFaltaHorasJustificadasDados : false,
			semValueGraficoColaboradores : false,
			semHorasExtrasRealizadasDados : false,
			semColaboradoresHorasExtrasDados : false,
			semHorasFaltasRealizadasDados : false,
			semColaboradoresHorasFaltaDados : false,
			colaboradoresAtivos :{
				'dados'   : '',
				'message' : ''
			},
			semColaboradoresAtivos: false,
			totalHorasExtra : {
				'dados'   : '',
				'message' : ''
			},
			semTotalHorasExtra: false,
			totalHorasFalta : {
				'dados'   : '',
				'message' : ''
			},
			semTotalHorasFalta: false,
			colaboradoresTipo : {
				'dados'   : '',
				'message' : ''
			},
			taxaAbsenteismo : {
				'dados'   : '',
				'message' : ''
			},
			semTaxaAbsenteismo: false,

			dataAtualizacao: '',
		}
	},

	methods:{

		/**
         * @description Método ajustar os dados como o indicador de
         *  colaboradores ativos precisa
         * @param  {array} colaboradores - 
         *  dados ( quantidade de colaboradores ativos )
         *  message ( variação correspondente ao mês passado )
         * @author Vitor Hugo 🐨
        */
		trataDadosColaboradoresAtivos(colaboradores){
			if(colaboradores[0] != undefined){
				this.colaboradoresAtivos = JSON.parse(colaboradores[0].ddvalores)
				this.semColaboradoresAtivos = false
			} else {
				this.semColaboradoresAtivos = true
			}
		},

		/**
         * @description Método ajustar os dados como o indicador de
         *  total de horas extras precisa
         * @param  {array} totalHorasExtra - 
         *  dados ( total de horas extras )
         *  message ( variação correspondente ao mês passado )
         * @author Vitor Hugo 🐨
        */
		trataDadosTotalHorasExtra(totalHorasExtra){
			if(totalHorasExtra[0] != undefined){
				this.totalHorasExtra = JSON.parse(totalHorasExtra[0].ddvalores)
				this.semTotalHorasExtra = false
			} else {
				this.semTotalHorasExtra = true
			}
		},

		/**
         * @description Método ajustar os dados como o indicador de
         *  total de horas falta precisa
         * @param  {array} totalHorasFalta - 
         *  dados ( total de horas falta )
         *  message ( variação correspondente ao mês passado )
         * @author Vitor Hugo 🐨
        */
		trataDadosTotalHorasFalta(totalHorasFalta){
			if(totalHorasFalta[0] != undefined){
				this.totalHorasFalta = JSON.parse(totalHorasFalta[0].ddvalores)
				this.semTotalHorasFalta = false
			} else {
				this.semTotalHorasFalta = true
			}
		},

		/**
         * @description Método ajustar os dados como o gráfico de
         *  gráfico de colaboradores por tipo precisa
         * @param  {array} colaboradoresTipo - 
         *  ddvalores ( JSON com os dados do gráfico )
         * @author Vitor Hugo 🐨
        */
		trataDadoscolaboradoresTipo(colaboradoresTipo){
			if(colaboradoresTipo[0] != undefined){
				this.colaboradoresTipo = JSON.parse(colaboradoresTipo[0].ddvalores)
				this.labelColaboradores = this.colaboradoresTipo.label
				this.valueGraficoColaboradores = this.colaboradoresTipo.data
				this.semValueGraficoColaboradores = false
			} else {
				this.semValueGraficoColaboradores = true
			}
		},

		/**
         * @description Método ajustar os dados como o gráfico de
         *  horas extras realizadas precisa
         * @param  {array} horasExtrasRealizadas - 
         *  ddvalores ( JSON com os dados do gráfico )
         * @author Vitor Hugo 🐨
        */
		trataHorasExtrasRealizadas(horasExtrasRealizadas){
			if(horasExtrasRealizadas[0] != undefined){
				this.horasExtrasRealizadas = JSON.parse(horasExtrasRealizadas[0].ddvalores)
				this.horasExtrasRealizadasLabel = this.horasExtrasRealizadas.label
				this.horasExtrasRealizadasDados = [this.horasExtrasRealizadas.dados[0]]
				this.semHorasExtrasRealizadasDados = false
			} else {
				this.semHorasExtrasRealizadasDados = true
			}
		},

		/**
         * @description Método ajustar os dados como o gráfico de
         *  horas falta realizadas precisa
         * @param  {array} horasFaltaRealizadas - 
         *  ddvalores ( JSON com os dados do gráfico )
         * @author Vitor Hugo 🐨
        */
		trataHorasFaltaRealizadas(horasFaltaRealizadas){
			if(horasFaltaRealizadas[0] != undefined){
				this.horasFaltaRealizadas = JSON.parse(horasFaltaRealizadas[0].ddvalores)
				this.horasFaltaRealizadasLabel = this.horasFaltaRealizadas.label
				this.horasFaltasRealizadasDados = [this.horasFaltaRealizadas.dados[0]]
				this.semHorasFaltasRealizadasDados = false
			} else {
				this.semHorasFaltasRealizadasDados = true
			}
		},

		/**
         * @description Método ajustar os dados como o gráfico de
         *  colaboradores com mais hora extra precisa
         * @param  {array} colaboradoresHorasExtras - 
         *  ddvalores ( JSON com os dados do gráfico )
         * @author Vitor Hugo 🐨
        */
		trataColaboradoresHorasExtras(colaboradoresHorasExtras){
			if(colaboradoresHorasExtras[0] != undefined){
				this.colaboradoresHorasExtras = JSON.parse(colaboradoresHorasExtras[0].ddvalores)
				this.colaboradoresHorasExtrasLabel = this.colaboradoresHorasExtras.label
				this.colaboradoresHorasExtrasDados = this.colaboradoresHorasExtras.dados
				this.semColaboradoresHorasExtrasDados = false
			} else {
				this.semColaboradoresHorasExtrasDados = true
			}
		},

		/**
         * @description Método ajustar os dados como o gráfico de
         *  colaboradores com mais hora falta precisa
         * @param  {array} colaboradoresHorasFalta - 
         *  ddvalores ( JSON com os dados do gráfico )
         * @author Vitor Hugo 🐨
        */
		trataColaboradoresHorasFalta(colaboradoresHorasFalta){
			if(colaboradoresHorasFalta[0] != undefined){
				this.colaboradoresHorasFalta = JSON.parse(colaboradoresHorasFalta[0].ddvalores)
				this.colaboradoresHorasFaltaLabel = this.colaboradoresHorasFalta.label
				this.colaboradoresHorasFaltaDados = this.colaboradoresHorasFalta.dados
				this.semColaboradoresHorasFaltaDados = false
			} else {
				this.semColaboradoresHorasFaltaDados = true
			}
		},

		/**
         * @description Método ajustar os dados como o gráfico de
         *  horas falta x horas justificadas precisa
         * @param  {array} horasFaltaHorasJustificadas - 
         *  ddvalores ( JSON com os dados do gráfico )
         * @author Vitor Hugo 🐨
        */
		trataHorasFaltaHorasJustificadas(horasFaltaHorasJustificadas){
			if(horasFaltaHorasJustificadas[0] != undefined){
				this.horasFaltaHorasJustificadas = JSON.parse(horasFaltaHorasJustificadas[0].ddvalores)
				if(this.horasFaltaHorasJustificadas.data[0] > 0 &&
                    this.horasFaltaHorasJustificadas.data[1] > 0){
					this.horasFaltaHorasJustificadasLabel = this.horasFaltaHorasJustificadas.label
					this.horasFaltaHorasJustificadasDados = this.horasFaltaHorasJustificadas.data
					this.semHorasFaltaHorasJustificadasDados = false
				} else {
					this.semHorasFaltaHorasJustificadasDados = true
				}
			} else {
				this.semHorasFaltaHorasJustificadasDados = true
			}
		},

		/**
         * @description Método ajustar os dados como o indicador de
         *  absenteísmo precisa
         * @param  {array} taxaAbsenteismo - 
         *  dados ( absenteísmo )
         *  message ( variação correspondente ao mês passado )
         * @author Vitor Hugo 🐨
        */
		trataTaxaAbsenteismo(taxaAbsenteismo){
			if(taxaAbsenteismo[0] != undefined){
				this.taxaAbsenteismo = JSON.parse(taxaAbsenteismo[0].ddvalores)
				this.semTaxaAbsenteismo = false
			} else {
				this.semTaxaAbsenteismo = true
			}
		},

		/**
         * @description Método faz a requisição para trazer os dados da dashboard
         *  chama os métodos referentes a cada gráfico da tela para os dados serem tatados
         * @author Vitor Hugo 🐨
        */
		buscaDadosDashboard(){
			this.loadingPanel = true
			new HttpRequest().Post('/controle/jornada/dashboard/gerar')
				.then((dados)=>{
					if(dados.code == 200){
						var dado = dados.data
						this.trataDadosColaboradoresAtivos(
							dado.colaboradoresAtivos)
						this.trataDadosTotalHorasExtra(dado.totalHorasExtra)
						this.trataDadosTotalHorasFalta(dado.totalHorasFalta)
						this.trataDadoscolaboradoresTipo(dado.colaboradoresTipo)
						this.trataHorasExtrasRealizadas(
							dado.horasExtrasRealizadas)
						this.trataHorasFaltaRealizadas(
							dado.horasFaltaRealizadas)
						this.trataColaboradoresHorasExtras(
							dado.colaboradoresHorasExtras)
						this.trataColaboradoresHorasFalta(
							dado.colaboradoresHorasFalta)
						this.trataHorasFaltaHorasJustificadas(
							dado.horasFaltaHorasJustificadas)
						this.trataTaxaAbsenteismo(dado.taxaAbsenteismo)
                        if(dado.dataHoraAtualizacao != null){
                            this.dataAtualizacao = DateTime
                                .fromFormat(
                                    dado.dataHoraAtualizacao.dddatahoraevento,
                                    'yyyy-LL-dd HH:mm:ss')
                                .toFormat('dd/LL/yyyy HH:mm:ss')
                        }
						this.loadingPanel = false
					} else {
						this.toastShow('Entre em contato com o suporte',
							'danger',
							'Erro ao gerar gráfico')
						this.loadingPanel = false
					}
				})       
		},

		toastShow(msg, type, title = ''){
			this.$bvToast.toast(`${msg}`, {
				autoHideDelay: 3000,
				variant: type,
				title: title
			})
		},

		atualizaDash(){
			this.horasExtrasRealizadasDados = []
			this.horasFaltasRealizadasDados = []
			this.colaboradoresHorasExtrasDados = []
			this.colaboradoresHorasFaltaDados = []
			this.horasFaltaHorasJustificadasDados = []
			this.valueGraficoColaboradores = []
			this.colaboradoresAtivos.dados = ''
			this.colaboradoresAtivos.message = ''
			this.totalHorasExtra.dados = ''
			this.totalHorasExtra.message = ''
			this.totalHorasFalta.dados = ''
			this.totalHorasFalta.message = ''
			this.colaboradoresTipo.dados = ''
			this.colaboradoresTipo.message = ''
			this.taxaAbsenteismo.dados = ''
			this.taxaAbsenteismo.message = ''
			this.buscaDadosDashboard()
		},
	},

	mounted(){
		this.buscaDadosDashboard()
	}
})


</script>
<style lang="scss">
#dashboardJornada{
    .backgound-gray{
        background: #f8f8f8;
        box-shadow: 1px 1px 1px 1px #e0e0e0;
        height: 100%;
        .font{
            font-size: 10px;
        }
        div{
            padding-top: 5px;
        }
    }
    
    .indicador{
        height: 85px;
    }
}

</style>
