/** 
  Este é um compoenete de gráfico para indicadores da dashboard

  Propriedades:
    1  -> id - String, valor único para diferenciar os indicadores - EX:'indicador1'
    2  -> data - Array, valores que irão ser usados para gerar alinha do indicador - Ex:'[25, 44, 12, 36, 9, 21]'
    3  -> curve - String, tipo da linha do indicador - Ex:'smooth'
    4  -> gridTop - String, Posicionamento do indicador em Y (padding top) - Ex:'-20'
    5  -> gridBottom - String, Posicionamento do indicador em Y (padding bottom) - Ex:'0'
    6  -> gridLeft - String, Posicionamento do indicador em X (padding left) - Ex:'10'
    7  -> colorLinha - String, Cor da linha do indicador - Ex: '#000'
    8  -> subtitleOffsetX - Number, Posicionamento do subtitulo em X - Ex: 0
    9  -> subtitleOffsetY - Number, Posicionamento do subtitulo em Y - Ex: 0
    10 -> subtitleText - String, Texto do subtitulo - Ex:'subtitulo'
    11 -> titleText - String, Texto do titulo - Ex:'titulo'
    12 -> titleOffsetX - Number, Posicionamento do texto em X - Ex: 0
    13 -> titleOffsetY - Number, Posicionamento do texto em Y - Ex: 0
    14 -> chartHeight - Number, Altura do quadro do indicador - Ex: 80
    15 -> typeChart - String, tipo do indicador que ira aparecer - Ex:'line/bar'
    16 -> backgroundColor - String, cor de fundo do quadro - Ex:'blue/red/purple'
    17 -> gridRight - Posicionamento do indicador em X (padding right) - Ex:'10'



  documetação
    https://apexcharts.com/docs/installation/
    https://apexcharts.com/docs/options
*/

<template id="chartsIndicators">
    <div class="sparkboxes content-area">
        <div class="col-sm-12 nopadding">
            <div :class="'p-1 box background-'+backgroundColor">
                <div :id="'spark'+id"></div>
            </div>
        </div>
    </div>
</template>
<script>
    import Vue  from 'vue'
    import ApexCharts from 'apexcharts'
    export default Vue.extend({
        name:'chartsIndicator',

        data(){
            return {
                // Quadros superiores
                spark1 : {
                    series: [{
                        data: this.data,
                    }],
                    chart: {
                        id: 'spark'+this.id,
                        group: 'sparks',
                        type: this.typeChart,
                        height: this.chartHeight,
                        sparkline: {
                            enabled: true
                        },
                        dropShadow: {
                            enabled: true,
                            top: 10,
                            left: 5,
                            blur: 5,
                            opacity: 0.2,
                        },
                    },
                    stroke: {
                        curve: this.curve
                    },
                    markers: {
                        size: 0
                    },
                    grid: {
                        padding: {
                            top: this.gridTop,
                            bottom: this.gridBottom,
                            left: this.gridLeft,
                            right: this.gridRight,
                        }
                    },
                    colors: [this.colorLinha],
                    tooltip: {
                        x: {
                            show: false
                        },
                        y: {
                            title: {
                                formatter: function (seriesName) {
                                    return ''
                                }
                            }
                        },
                    },
                    title: {
                        text: this.titleText,
                        offsetX: this.titleOffsetX,
                        offsetY: this.titleOffsetY,
                        style: {
                            fontSize: '10px',
                            fontFamily:  'nexabook, sans-serif',
                        }
                    },
                    subtitle: {
                        text: this.subtitleText,
                        offsetX: this.subtitleOffsetX,
                        offsetY: this.subtitleOffsetY,
                        align: 'center',
                        style: {
                            fontWeight:  'bold',
                            fontFamily:  'nexabook, sans-serif',
                        }
                    },
                },
            }
        },

        props:{
            id:{
                type:String,
                required: true,
            },
            data:{
                type: Array,
                required: false,
            },
            curve:{
                type: String,
                default:'smooth'
            },
            gridTop:{
                type: Number,
                default: -25,
            },
            gridBottom:{
                type: Number,
                default: 0,
            },
            gridLeft:{
                type: Number,
                default: 40,
            },
            gridRight:{
                type: Number,
                default: 15,
            },
            colorLinha:{
                type: String,
                default:'#000',
            },
            subtitleOffsetX:{
                type: Number,
                default: -10,
            },
            subtitleOffsetY:{
                type: Number,
                default: 20,
            },
            subtitleText:{
                type: String,
                default: '350',
            },
            titleText:{
                type: String,
                default: 'Condutores',
            },
            titleOffsetX:{
                type: Number,
                default: -10,
            },
            titleOffsetY:{
                type: Number,
                default: 0,
            },
            chartHeight:{
                type: Number,
                default: 52
            },
            typeChart:{
                type: String,
                default: 'bar'
            },
            backgroundColor:{
                type:String,
                default:'gray'
            }
        },

        mounted(){
            // Gera os quadrinhos
            new ApexCharts(document.querySelector("#spark"+this.id), this.spark1).render();
        },
    })


</script>
<style lang="scss">
        .box {
            padding: 25px 20px;
            .apexcharts-canvas .apexcharts-svg .apexcharts-title-text{
                font-size: 12px !important;
            }
            .apexcharts-canvas .apexcharts-svg .apexcharts-subtitle-text{
                font-size: 20px !important;
            }
        }

        .shadow {
            color: #fff !important;
            box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
        }
        .sparkboxes .box {
            padding-top: 10px;
            padding-bottom: 10px;
            box-shadow: 0px 1px 15px 1px rgba(69, 65, 78, 0.08);
            position: relative;
        }

        .sparkboxes .box .details {
            position: absolute;
            color: #fff;
        }
        .sparkboxes strong {
            position: relative;
            color: #fff;
        }


        .sparkboxes .background-gray {
            background: #f8f8f8 !important;
            box-shadow: 1px 1px 1px 1px #e0e0e0;
        }

        .sparkboxes .background-ciano {
            background-image: linear-gradient( 135deg, #2AFADF 10%, #4C83FF 100%);
        }

        .sparkboxes .background-red {
            background-image: linear-gradient( 135deg, #FFD3A5 10%, #FD6585 100%);
        }

        .sparkboxes .background-purple {
            background-image: linear-gradient( 135deg, #EE9AE5 10%, #5961F9 100%);
        }
</style>