<!-- Modal Detalhes da operação -->
<template>
    <modalEagle  
        id ="modalCriarRota-"
        title="Para criar uma nova rota, selecione as informações abaixo:"
        size  ='lg'>
        <template slot="modalBody">
            <b-overlay :show="loadingModal">
                <div class="col-sm-12 row mb-3">
                    <div class="col-sm-6 nopadding divDontPrint">
                        <selectAll 
                            :isMultiple   ="false" 
                            :hasSelectAll ="false" 
                            nameForRadio  ="rotapadrao" 
                            :labels       ="[{indexDFH: 'RP', description: 'Rota padrão*'}]" 
                            :optionsArray ="optionsArrayRota" 
                            firstSelected ="EM"
                            :loading      ="loadingModal"
                            @changeSelect ="changeRota"/>
                    </div>
                    <div class="col-sm-3 nopadding divDontPrint">
                        <inputSingleDay 
                            id="inputData"
                            name="inputData"
                            :isObrigatorio="true"
                            @changeInput="changeData"/>
                    </div>
                    <div class="col-sm-3 nopadding divDontPrint">
                        <inputSimple 
                            id="inputHora"
                            name="inputHora"
                            label="Hora *"
                            type="time"
                            :value="hora"
                            @changeInput="changeHora"/>
                    </div>
                </div>
                <div class="col-sm-12 row">
                    <div class="col-sm-6 nopadding divDontPrint">
                        <selectAll 
                            :isMultiple   ="false" 
                            :hasSelectAll ="false" 
                            nameForRadio  ="motoristas" 
                            :labels       ="[{indexDFH: 'M', description: 'Motorista *'}]" 
                            :optionsArray ="optionsArrayMotorista" 
                            :loading      ="loadingModal"
                            firstSelected ="M"
                            @changeSelect ="changeMotorista"/>
                    </div>
                    <div class="col-sm-6 nopadding divDontPrint">
                        <selectAll 
                            :isMultiple   ="false" 
                            :hasSelectAll ="false" 
                            nameForRadio  ="status" 
                            :labels       ="[{indexDFH: 'S', description: 'Status *'}]" 
                            :optionsArray ="optionsArrayStatus" 
                            firstSelected ="S"
                            @changeSelect ="changeStatus"/>
                    </div>
                </div>
            </b-overlay>
        </template>
        <template>
            <simpleButton 
                :icon="mdiCloseThick" 
                text="Cancelar" 
                event="click" 
                type="red"
                @click="fechaModalDetalhesOperacao">
            </simpleButton>
            <loadingButton 
                :icon="mdiCheckBold" 
                text="Confirmar" 
                event="click" 
                type="blue"
                :isLoading="loadingCriar"
                :disabled="$v.$invalid"
                @click="confirmaCriacaoRota">
            </loadingButton>
        </template>
    </modalEagle>
</template>
<script>
    import Vue from 'vue'
    import {HttpRequest}              from '../../../Services/auth/HttpRequest.Service'
    import { required }               from 'vuelidate/lib/validators'
    import { mdiCheckBold, mdiCloseThick }     from '@mdi/js'
    import { DateTime }      from "luxon";
    import { AuthService } from '@/Services/auth/Auth.Service'
export default Vue.extend({
    name:'modalOperacaoPainelControle',
    components:{
        'modalEagle'            : require('@/components/Atom/Modal/ModalEagle').default,
        'selectAll'             : require('@/components/Atom/Select/SelectAll').default,
        'inputSingleDay'        : require('@/components/Atom/Datas/InputSingleDay').default,
        'inputSimple'           : require('@/components/Atom/Inputs/InputSimple').default,
        'simpleButton'          : require('@/components/Atom/Buttons/SimpleButton').default,
        'loadingButton'         : require('@/components/Atom/Buttons/LoadingButton').default,
        // 'buttonDropup'          : require('@/components/Atom/Buttons/ButtonDropup').default,
        // 'inputRangeNoLimits'    : require('@/components/Atom/Datas/InputRangeNoLimits').default,
        // 'baseIcon'              : require('@/components/Atom/Icon/BaseIcon').default,
        // 'statusInformation'     : require('@/components/Atom/StatusInformation/StatusInformation').default,
    },

    validations:{
        rotaPadraoSelecionada:{ 
            required
        },

        motoristaSelecionado:{ 
            required
        },

        statusSelecionado:{ 
            required
        },

        dataSelecionada:{ 
            required
        },

        horaSelecionada:{ 
            required
        },
    },

    data(){
        return{
            link: '/eagle/cargo/nao/menu/painel/controle/',
            mdiCloseThick       : mdiCloseThick,
            mdiCheckBold        : mdiCheckBold,
            optionsArrayRota      : [],
            optionsArrayMotorista : [],
            optionsArrayStatus    : [{'value' : 7, 'description' : 'Agendado carregamento'},
                                     {'value' : 2, 'description' : 'Agendado venda'},],
            empresasSelecinadas   : [],
            loadingModal          : false,
            rotaPadraoSelecionada : '',
            motoristaSelecionado  : '',
            statusSelecionado     : '',
            dataSelecionada       : '',
            horaSelecionada       : '',
            placaCriarRota        : '',
            loadingCriar          : false,
            hora                  : '',
        }
    },
    
    methods:{
        preparaModalCriarRota(empresas, placa){
            this.placaCriarRota      = placa
            this.empresasSelecinadas = empresas
            this.hora = DateTime.now().toFormat('HH:mm')
            this.$bvModal.show('modalCriarRota-')
            this.buscaRotasMotoristas()
        },

        async buscaRotasMotoristas(){
            this.loadingModal = true
            var dados = await new HttpRequest()
                .Post(this.link+'dados/rota', {'clientes' : this.empresasSelecinadas})
            this.optionsArrayMotorista = dados.data.motoristas
            this.optionsArrayRota      = dados.data.rotas
            this.loadingModal = false
        },

        changeRota(rota){
            this.rotaPadraoSelecionada = rota
        },

        changeMotorista(motorista){
            this.motoristaSelecionado = motorista
        },

        changeStatus(status){
            this.statusSelecionado = status
        },

        changeData(data){
            this.dataSelecionada = data
        },

        changeHora(hora){
            this.horaSelecionada = hora
        },

        async confirmaCriacaoRota(){
            this.loadingCriar = true
            var dados = await new HttpRequest()
                .Post(this.link+'criar/rota', 
                {'rotapadrao' : this.rotaPadraoSelecionada[0],
                 'placa'      : this.placaCriarRota,
                 'motorista'  : this.motoristaSelecionado[0],
                 'status'     : this.statusSelecionado[0],
                 'data'       : this.dataSelecionada,
                 'tempo'      : this.horaSelecionada,})
            if(dados.status && dados.data.historico && dados.data.padrao 
                && dados.data.rota && dados.data.itensRota && dados.data.veiculo){
                this.$emit('atualizaKanban', 'atualizar')
                this.loadingCriar = false
                this.fechaModalDetalhesOperacao()
                this.toastShow('Rota criada com sucesso', 'success')
            } else {
                this.loadingCriar = false
                this.fechaModalDetalhesOperacao()
                this.toastShow('Tente novamente mais tarde', 'danger')
            }
        },

        toastShow(msg, type){
            this.$bvToast.toast(`${msg}`, {
                autoHideDelay: 2500,
                variant: type,
            })
        },

        fechaModalDetalhesOperacao(){
            this.$bvModal.hide('modalCriarRota-')
        },
    },
})
</script>