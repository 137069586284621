<template>
    <panel-eagle id="AFDCheckpoint" :loading='loading'>
        <div class="col-12 row">
            <div class="col-4 nopadding">
                <titulo 
                    :icon='mdiClipboardClockOutline'
                    titulo='Arquivo Fonte de Dados'/>
            </div>
            <div class="col-8 nopadding divDontPrint">
                <basicButtonsRelatoriosEIG
                    :loading='loadingExportar'
                    :disabledDropdown='semDados'
                    :disabled='$v.$invalid'
                    @gerarRelatorio='gerarRelatorio'
                    @exportarRelatorio='exportarRelatorio'>
                </basicButtonsRelatoriosEIG>
            </div>
        </div>
        <slideUpAndDown class="divDontPrint">
            <div class="col-sm-12"><hr></div>
            <div class="col-12 nopadding row mb-2">
                <div class="col-4 nopadding">
                    <inputRange
                        :isObrigatorio='true'
                        name='range-extrato'
                        @changeInput='changeIntervalo'/>
                </div>
                <div class="col-4 nopadding">
                    <selectAll
                        nameForRadio='selCliente'
                        :isMultiple='false'
                        :hasSelectAll='false'
                        :labels='labelSelectCliente'
                        :optionsArray='optClientes'
                        :selected="selectedCliente"
                        firstSelected='E'
                        :extraClass='{
                            "border border-danger":$v.clienteSelecionado.$anyError
                        }'
                        @close='$v.clienteSelecionado.$touch()'
                        @changeSelect='changeEmpresa'/>
                </div>
            </div>
        </slideUpAndDown>
        <div class="col-sm-12"><hr></div>
        <div class="mt-2 col-12 nopadding" v-if="!semDados">
            <registroTipo1 :dados="this.tableData.tipo1"></registroTipo1>
            <registroTipo2 :dados="this.tableData.tipo2"></registroTipo2>
            <registroTipo3 :dados="this.tableData.tipo3"></registroTipo3>
            <registroTipo5 :dados="this.tableData.tipo5"></registroTipo5>
            <registroTipo9 :dados="this.tableData.tipo9"></registroTipo9>
            <assinaturaDigital :dados="this.tableData.assinatura"></assinaturaDigital>
        </div>
        <div v-else class="statusBar"> 
            <statusInformation
                typeBar='div'
                :statusBar='statusBar'/>
        </div>
        <requiredFields class="divDontPrint"/>
        <div class="col-sm-12"><hr></div>
	</panel-eagle>
</template>

<script>
import { mdiClipboardClockOutline } from '@mdi/js';
import { required } from 'vuelidate/lib/validators'
import { EmpresasService } from '../../../Services/auth/Empresas.service'
import { HttpRequest }     from '../../../Services/auth/HttpRequest.Service'
import { conectionError } from '@/Services/Helpers/swellHeper'
import { isArray } from '@vue/shared';
import { mapGetters } from 'vuex'

export default {
    name:'AFDCheckpoint',
    components:{
		slideUpAndDown: require('@/components/Atom/SlideUpAndDown/SlideUpAndDown.vue').default,
		panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
		basicButtonsRelatoriosEIG : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
		titulo: require('@/components/Atom/Header/Titulo').default,
		selectAll: require('@/components/Atom/Select/SelectAll').default,
		inputRange: require('@/components/Atom/Datas/InputRangeWithLimit').default,
		statusInformation: require('@/components/Atom/StatusInformation/StatusInformation').default,
		requiredFields: require('@/components/Atom/Footer/RequiredFields').default,
        registroTipo1: require('@/components/Atom/SpecificComponents/RelatorioAFD/RegistroTipo1').default,
        registroTipo2: require('@/components/Atom/SpecificComponents/RelatorioAFD/RegistroTipo2').default,
        registroTipo3: require('@/components/Atom/SpecificComponents/RelatorioAFD/RegistroTipo3').default,
        registroTipo5: require('@/components/Atom/SpecificComponents/RelatorioAFD/RegistroTipo5').default,
        registroTipo9: require('@/components/Atom/SpecificComponents/RelatorioAFD/RegistroTipo9').default,
        assinaturaDigital: require('@/components/Atom/SpecificComponents/RelatorioAFD/AssinaturaDigital').default,
    },

    data(){
		return{
			mdiClipboardClockOutline: mdiClipboardClockOutline,
            link: '/checkpoint/relatorios/afd/',
			statusBar: 'info',
			loading: false,
			loadingExportar: [false, false, false],
			tableData: [],
			optClientes: new EmpresasService().Get(),
			labelSelectCliente:[
				{indexDFH:'E', description:'Empresa*'}
			],
			clienteSelecionado: [],
            selectedCliente: [],
			intervalo:'',
		}
	},

	validations:{
		clienteSelecionado:{ required },
	},

    methods:{
		...mapGetters(['getMaster']),
		
		/**
         * @listens changeSelect
         * @param {number[]} arr - cliente selecionado
         * @description muda o cliente selecionado
         */
         changeEmpresa(arr){
			this.clienteSelecionado = arr 
			this.tableData = []
		},

		/**
		 * @listens changeInput
         * @param {string} intervalo formato dd/mm/yyyy - dd/mm/yyyy
         */
		changeIntervalo(intervalo){
			this.intervalo = intervalo
			this.tableData = []
		},

		/**
         * @description carrega informações do relatório e atribui os dados a tableData. 
		 * @listens gerarRelatorio
         */
		gerarRelatorio(){
			var url = `${this.link}gerar/relatorio`
			var obj = {
				intervalo: this.intervalo,
				empresa: this.clienteSelecionado[0],
			}
			this.tableData = []
			this.loading = true
			new HttpRequest().Post(url,obj)
                .then((res)=>{
                    if(res.status) {
                        this.tableData = res.data
                        if(Object.values(this.tableData).length == 0){
                            this.statusBar = 'error';
                        }else {
                            this.exportarRelatorio('txt')
                            this.statusbar = 'error2'
                        }
                    } else {
                        this.statusBar = 'error';
                        conectionError()
                    }
                }).catch(() => {
                    conectionError()
                }).finally(() => this.loading = false )
		},

		/**
         * @description exporta os dados no formato pdf|xls|csv|txt
		 * @listens exportarRelatorio
		 * @param {'pdf'|'xls'|'csv'|'txt'} formato
         */
		exportarRelatorio(formato){
			let uri = `${this.link}exportar`
			let obj = {
				empresa: this.findNomeEmpresa(),
				intervalo: this.intervalo,
				arrayDados:this.tableData,
                tipo:formato
			}
			this.decideExportar(formato)
			new HttpRequest().Post(uri, obj)
				.then((res)=>{
                    if(res.status) {
                        if(formato == 'txt') {
                            this.downloadAFD(res.data.local)
                        } else {
                            var root =  process.env.VUE_APP_ROOT;
                            open(root+'/'+res.data.local)
                        }
                    } else {
                        conectionError()
                    }
				})
				.finally(() => this.decideExportar('none') )
		},

        /**
         * @description Faz o download de arquivo txt no navegador.
         * @param {String} text 
         */
        downloadAFD(text) {
            let newBlob = new Blob([text], { type: "text/plain" })
            var FILE = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = FILE;
            link.setAttribute('download', 'AFD.txt')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        },

		/**
         * @description modifica loadingExportar
		 * @param {'pdf'|'xls'|'csv'|'none'} formato
		 */
		decideExportar(formato){
			this.loadingExportar = [
				formato === 'pdf',
				formato === 'xls',
				formato ==='csv'
			]
		},

		/**
         * @description busca nome da empresa selecionada para enviar na exportação
         */
        findNomeEmpresa() {
            if(this.clienteSelecionado && this.clienteSelecionado.length) {
                let empresa = this.optClientes
                    .filter(e => e.value == this.clienteSelecionado[0])
                return empresa && empresa.length ? empresa[0].description : ''
            }
            return ''
        }
    },

    computed: {

		/**
         * @description verifica se existem dados para validar os botões e listagem
         */
        semDados(){
            return isArray(this.tableData) && !this.tableData.length
                || !Object.values(this.tableData).length
        }
    },

    mounted() {
		if(!this.getMaster()) {
            if(this.optClientes && this.optClientes.length) {
                this.selectedCliente = [this.optClientes[0]]
            }
        }
    },
}
</script>

<style lang ='scss'>
#AFDCheckpoint{
    .sc-table {
        border: 1px solid #ddd;
        .el-table {
            margin: 0 !important;
        }
    }
    .tableDataEmpty{
        padding: 0px !important;
        font-size: 14px;
        color: #FFF;
        text-align: center;
        background: #e04b4a !important;
        .textEmpty{
            color: #FFF !important;
            line-height: 60px;
        }
    }
    .trTdHeader{
        background-color: #ccc;
        td {
            padding: 5px 0.5rem 0 0.5rem !important;;
        }
    }
    #tableListagem {
        .el-table th {
            background-color: #ccc !important;
            border: 1px solid #e5e5e5;
            padding: 0 !important;
            .caret-wrapper{
                height: 30px;
            }
        }
        .el-table__row td {
            background-color: #efefef !important;
            border: 1px solid #e5e5e5 !important;
        }
    }
    .tableFixHead tr:not(.backColorTdPDF) td {
        padding: 5px 0.5rem 0 0.5rem !important;;
    }
}
</style>