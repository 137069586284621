import { DateTime } from 'luxon'

export function validaUltimoFechamento(ultimoFechamento, dataSelecionada) {
    if (!ultimoFechamento.jffim) return false;
    const format = "dd/LL/yyyy";
    const dataInicioIntervalo = transformaData(dataSelecionada.split(" - ")[0], format);
    const dataFimUltimoFechamento = transformaData(ultimoFechamento.jffim, format);
    const periodo = `${ultimoFechamento.jfinicio} - ${ultimoFechamento.jffim}`;
    if (dataInicioIntervalo <= dataFimUltimoFechamento) {
        return [true, periodo]
    }
    return false
}

export function validaUltimoFechamentoPorDia(ultimoFechamento, diaSelecionado, formatoDia = "yyyy-LL-dd") {
    if (!ultimoFechamento.jffim) return false;
    const format = "dd/LL/yyyy";
    const diaAtual = transformaData(diaSelecionado, formatoDia)
    const dataFimUltimoFechamento = transformaData(ultimoFechamento.jffim, format);
    const periodo = `${ultimoFechamento.jfinicio} - ${ultimoFechamento.jffim}`;
    if (diaAtual <= dataFimUltimoFechamento) {
        return [true, periodo]
    }
    return false
}

export function validaFechamentoDataFim(ultimoFechamento, dataSelecionada) {
    if (!ultimoFechamento.jffim) return false;
    const format = "dd/LL/yyyy";
    const dataFimIntervalo = transformaData(dataSelecionada.split(" - ")[1], format);
    const dataFimUltimoFechamento = transformaData(ultimoFechamento.jffim, format);
    const periodo = `${ultimoFechamento.jfinicio} - ${ultimoFechamento.jffim}`;
    if (dataFimIntervalo <= dataFimUltimoFechamento) {
        return [true, periodo]
    }
    return false
}

/**
 * 
 * Transforma a data string passada 
 * em um objeto dateTime conforme 
 * especificado o formato
 * @returns Objeto DateTime
 */
function transformaData(dateString, format) {
    return DateTime.fromFormat(dateString, format)
}

