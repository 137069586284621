<template>
	<panel-eagle id='processamento-checkpoint' :loading='loading'>
		<div class='col-12 row divDontPrint'>
			<div class="col-6 nopadding" >
				<titulo
				titulo='Processamento'
				:icon='mdiDatabaseSync'/>
			</div>
			<div class="col-6 nopadding" align='right'>
				<simple-button
				text='Gerar'
				:disabled='$v.$invalid'
				type='green'
				:icon='mdiThumbUpOutline'
				event='click'
				@click="gerarRelatorio"/>
			</div>
		</div>
        <div class="col-sm-12"><hr></div>
		<!-- FILTROS -->
		<div class='col-12 nopadding row'>
			<div class='col-3 nopadding'>
				<input-range
				name='intervaloProcessamento'
				titulo='Intervalo *'
				:dataMaxima='hoje'
				@changeInput='changeIntervalo'/>
			</div>
			<div class="col-3 nopadding">
				<select-all 
				nameForRadio='selCliente'
				:isMultiple='true'
				:hasSelectAll='true'
				:labels='labelSelectCliente'
				:optionsArray='optClientes'
				firstSelected='MR'
				:extraClass='{
					"border border-danger":$v.clienteSelecionado.$anyError
				}'
				@close='$v.clienteSelecionado.$touch()'
				@changeSelect='changeEmpresa'>
					<template #option>
						asdasd
					</template>
				</select-all>
			</div>
			<div class="col-4 nopadding">
				<select-all
				nameForRadio='selTrabalhador'
				ref='selTrabalhador'
				:disabled='desabilitaSeletorT'
				:labels='labelSelectTrabalhador'
				:isMultiple='true'
				:hasSelectAll='true'
				:loading='loadingTrabalhador'
				:optionsArray='optTrabalhador'
				:extraClass='{
					"border border-danger":$v.trabalhadorSelecionado.$anyError
				}'
				@close='$v.trabalhadorSelecionado.$touch()'
				@changeSelect='changeTrabalhador'/>
			</div>
			<div class='col-2 nopadding'>
				<simple-button
				v-if='podeProcessar'
				text='Processar'
				:disabled='processarDesabilitado'
				class='mt-4'
				type='blue'
				event='click'
				@click='processWorkers()'/>
			</div>
		</div>
		<div class='col-12 nopadding'>
		</div>
        <div class='col-sm-12'><hr></div>
		<div
		class='col-12'>
			<table-simples>
				<thead>
					<th>Colaborador</th>
					<th>
						<b-checkbox 
						v-model='checkSelTodos' >
							Selecionar Todos
						</b-checkbox>
					</th>
					<th>Status Processamento</th>
				</thead>
				<tbody v-if='showTable'>
					<br>
					<tr
					v-for='(line, index) in selectedWorkers'
					:key='`${index}_processamento`'>
						<td>{{line.mtnome}}</td>
						<td style="display:flex;">
							<b-check 
								v-model='line.selecionado' 
								v-bind="bindMtnome(line)"/>
							<span
							v-if="line.temFechamento"
							style="color:#e04d50;"> 
								Possui fechamento na data {{ line.dataFechamento }} 
							</span>
						</td>
						<td>
							<div :class='line.status+" bolinha"'/>
						</td>
					</tr>
				</tbody>
				<tbody v-else>
					<statusbar
					:statusBar='info'
					typeBar='tr'/>
				</tbody>
			</table-simples>
		</div>
		<b-modal
		id='modal-processamento-erros'
		header-class='header-modal'
		size='lg'
		title="Inconformidades de processamento ">
			<template #modal-header >
				Inconformidades de processamento 
			</template>
			<div class="d-flex flex-column fingtable"
			:style='{
				"max-height":"700px",
				"overflow-x":"auto"
			}'>
				<table>
				<thead>
					<tr>
						<th>Data</th>
						<th>Colaborador</th>
						<th>Motivo</th>
					</tr>
				</thead>
				<tbody>
					<tr
					v-for='(erro, i) in errosProcessamento'
					:key='`${i}_erro_processamento`'>
						<td v-text='formataData(erro.data)'/>
						<td v-text='erro.mtnome'/>
						<td v-text='decideErro(erro)'/>
					</tr>
				</tbody>
				</table>
			</div>
			<template #modal-footer >
				<simple-button
				text='Fechar'
				:icon='closeModal'
				type='red'
				event='click'
				@click='$bvModal.hide("modal-processamento-erros")'/>
			</template>
		</b-modal>
		<b-modal
		id='modal-processando'
		:no-close-on-backdrop='true'
		:hide-header-close='true'
		:no-close-on-esc='true'
		title='Inconformidades de processamento '>
			<template #modal-header >
				Informativo do processamento
			</template>
			<div id='body-progresso' >
				<div class="col-12 row mb-3">
					<b-badge
					class='badge-day'
					v-for='day in progressAllDaysSet'
					:variant="decideVarianteBadge(day)"
					:key='day'>
						{{formataData(day, false)}}
					</b-badge>
				</div>
				<b-progress  height="2rem">
					<b-progress-bar
					:value='progressValue'
					:max='progressTotal'
					variant="success"
					striped
					:label='labelProgress()'/>
				</b-progress>
			</div>
			<template #modal-footer>
				<div v-if='loading'>
					Carregando...
					<b-spinner small></b-spinner>
				</div>
				<div v-else class='d-flex justify-content-between'>
					<div
					class='erros-processamento-texto mt-2 mr-2'
					v-if='errosProcessamento.length'
					:style='{
						"color":"red",
						"text-decoration":"underline",
						"cursor":"pointer"
					}'
					event='click'
					@click="$bvModal.show('modal-processamento-erros')">
						Existem erros de processamento.
					</div>
					<simple-button
					text='Confirmar'
					event='click'
					@click="$bvModal.hide('modal-processando')"/>
				</div>
			</template>
		</b-modal>
	</panel-eagle>
</template>

<script>
import { mdiThumbUpOutline, mdiDatabaseSync, mdiCloseThick } from '@mdi/js'
import { required } from 'vuelidate/lib/validators'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { DateTime } from 'luxon'
import { mapGetters } from 'vuex'
const urlBase = '/checkpoint/nao/menu/processamento/'
export default {
	name:'ProcessamentoCheckpoint',
	components:{
		panelEagle   : require('@/components/Atom/Panel/PanelEagle').default,
		titulo       : require('@/components/Atom/Header/Titulo').default,
		simpleButton : require('@/components/Atom/Buttons/SimpleButton').default,
		inputRange   : require('@/components/Atom/Datas/InputRangeWithLimit').default,
		selectAll    : require('@/components/Atom/Select/SelectAll').default,
		statusbar    : require('@/components/Atom/StatusInformation/StatusInformation').default,
		tableSimples : require('@/components/Atom/Table/TableSimples').default,
	},

	validations:{
		trabalhadorSelecionado: { required },
		clienteSelecionado: { required }
	},

	data() {
		return {
			//opts
			optClientes: new EmpresasService().Get(),
			optTrabalhador:[],
			//icons
			mdiThumbUpOutline: mdiThumbUpOutline,
			mdiDatabaseSync: mdiDatabaseSync,
			closeModal: mdiCloseThick,
			//booleans
			loadingTrabalhador:false,
			checkSelTodos:true,
			loading:false,
			//labels
			labelSelectTrabalhador:[
				{indexDFH:'MR', description:'Colaborador*'},
			],
			labelSelectCliente:[
				{indexDFH:'cc', description:'Empresas*'}
			],
			//controle
			clienteSelecionado:[],
			trabalhadorSelecionado:[],
			selectedWorkers:[],
			intervalo:'',
			errosProcessamento:[],
			info: 'info',
			//pra fazer a modal de progresso fica loka 😃
			progressValue:0,
			progressTotal:0,
			progressCurentDay:'',
			progressAllDaysSet: [],
			progressDaysDone: new Set(),
			hoje:'',
		}
	},
	methods: {
		/**
		 * @param {object} erro
		 * @param {string[]} erro.erro
		 * @returns {string} erros formatados.
		 */
		decideErro(erro) {
			if(erro?.erro){
				return erro.erro.join(', ')
			}
			return ''
		},
		...mapGetters(['getPermicoes']),
		
		/**
		 * @description calcula a % atual da lista de requests,
		 * @returns {string} #%
		 */
		labelProgress() {
			var perce = this.progressValue/this.progressTotal
			perce = perce * 100
			perce = Math.floor(perce)
			return `${perce}%`
		},

		bindMtnome(wr) {
			var nao_pode_fechar = !this.podeFechar(wr)
			if(nao_pode_fechar) {
				wr.temFechamento = true
				wr.selecionado = false
				return {
					disabled:true,
					title: 'Já possui fechamento neste período'
				}
			} else {
				wr.temFechamento = false
			}
		},

		/**
		 * @description confirma se o funcionário pode ou não
		 * fazer um processamento 😃
		 * @returns {boolean}
		 */
		podeFechar(wr) {
			if (!wr.fechamento.length)
				return true
			var formato = 'dd/LL/yyyy'
			var fechamentos = this.achaPeriodoUltimoFechamento(wr.fechamento)
			var intervalo_relatorio = this.criaArrayDatas(
				...this.intervalo.split(' - '),
				formato
			)
			let dataInicioIntervalo = DateTime.fromFormat(intervalo_relatorio[0], formato)
			let ultimoFechamento = DateTime.fromFormat(fechamentos.at(-1), formato)
			var ini = DateTime.fromISO(wr.fechamento[0].cfinicio).toFormat(formato)
			var fim = DateTime.fromISO(wr.fechamento[0].cffim).toFormat(formato)
			wr.dataFechamento = ini + " - " + fim
			if (dataInicioIntervalo <= ultimoFechamento)
				return false
			return true
		},

		/**
		 * @param {object[]} fechamentos
		 * @return {string[]} inicio, fim, no formato dd/mm/yyyy, com TODAS
		 * as datas de fechamento do carinha.
		 * @author Gui 🍺
		 */
		achaPeriodoUltimoFechamento(fechamentos) {
			var arr_todas_datas = []
			var format = 'dd/LL/yyyy'
			fechamentos.forEach((f) => {
				var ini = DateTime.fromISO(f.cfinicio).toFormat(format)
				var fim = DateTime.fromISO(f.cffim).toFormat(format)
				var dias = this.criaArrayDatas(ini, fim, format)
				arr_todas_datas =  arr_todas_datas.concat(dias)
			})
			return arr_todas_datas
		},

		/**
		 * @param {string} ini inicio do período
		 * @param {string} final final do periodo
		 * @param {string} format formato da data
		 * @return {string[]}	todas as datas incluindo o inico e fim
		 * @author Gui 🧟
		 */
		criaArrayDatas(ini, final, format){
			var ini_data = DateTime.fromFormat(ini, format)
			var fim_data = DateTime.fromFormat(final, format)
			var retorno = [];
			var diff = fim_data.diff(ini_data, 'days').toObject().days
			for(let i = 0; i <= diff; i++){
				var novo_dia = ini_data.plus({days: i})
				retorno.push(novo_dia.toFormat(format))
			}
			return retorno
		},


		/**
		 * @param {string} data no formato yyyy/mm/dd
		 * @return {string} no formato dd/mm/yyyy
		 */
		formataData(data, year=true){
			var reg = /(\d{4})-(\d{2})-(\d{2})/
			var subs
			if(year) subs = '$3/$2/$1'
			else subs = '$3/$2'
			return data.replace(reg, subs)
		},

		/**
		 * @listens click botão Processar
		 * @description separa os funcionarios selecionados p/
		 * enviar para processamento.
		 * Essa função também cuida p/ que os badges dos dias
		 * que aparecem na modal mudem de cor de acordo com 
		 * o processamento. 
		 * @param {boolean} reset se essa é a última request, o reset
		 * faz com que o loading e etc parem. 
		 * @param {number} indice qual é a próxima request a ser feita
		 * @param {object[]} arr lista de todas as requests a serem feitas
		 * @author Gui 🍺
		 */
		processWorkers(reset=false, indice = 0, arr = []){
			this.loading = true
			if(indice === 0){
				this.progressAllDaysSet = []
				this.progressDaysDone = new Set()
				this.errosProcessamento = []
				arr = this.criaObjetos();
				if(arr.length === 0){
					this.loading = false 
					this.$bvModal.show('modal-processamento-erros')
					return
				}
				this.progressTotal = arr.length
				this.progressValue = 0
				this.progressCurentDay = ''
				this.progressCurentDay = arr[indice].day
				this.progressDaysDone = new Set([arr[indice].day])
				if(this.progressTotal === 1)
					reset = true
				setTimeout(()=>{
					this.$bvModal.show('modal-processando')
				}, 500)
			}
			if(arr.at(indice) !== undefined)
				var obj = arr.at(indice)
			var url = urlBase+'processar'
			new HttpRequest().Post(url, obj).then((data)=>{
				if(data.data?.dados)
					this.attWorker(data.data.dados, obj)
				indice++
				this.progressValue++
				if(arr.at(indice)!== undefined){
					this.progressCurentDay = arr[indice].day
					this.progressDaysDone.add(arr[indice].day)
				}
				if(indice < this.progressTotal-1){
					this.processWorkers(false, indice, arr)
				}else if(indice === this.progressTotal-1 && !reset){
					this.processWorkers(true, indice, arr)
				}
			}).finally(()=>{
				if(reset){
					this.ordenarErros()
					let cpCurrent = this.progressCurentDay
					this.progressDaysDone.add(cpCurrent)
					this.progressCurentDay = ''
					this.loading = false
				}
			})
		},

		/**
		 * @param {object} info - resultado do processamento.
		 * @param {boolean} info.processamento - se deu boa.
		 * @param {string[]} info.erros - array de msg de erros
		 * @param {object} obj
		 * @param {number} obj.worker - código do funcionário.
		 * @param {string} obj.day    - data do processamento
		 * @description atualiza o trabalhador na tabela e já
		 * adiciona os erros (caso tiver) na array de erros.
		 * @author Gui 🍺
		 */
		attWorker(info, {worker, day}){
			var w = _.find(this.selectedWorkers, {mtcodigo:worker})
			if(info.processamento){
				if(w.status === 'gray')
					w.status = 'green'
			}
			// foi pedido para quando nao retornar um motivo do erro
			// nao dar alerta de inconformidade
			// pq ele faz isso pq atualmente o script gera erros se
			// nao tiver pontos em sabado e domingo
			// por isso o if
			if(info.erros && info.erros[0]){
				w.status = 'red'
				this.errosProcessamento.push({
					mtnome:w.mtnome,
					mtcodigo:w.mtcodigo,
					data:day,
					erro:info.erros
				})
			}
		},

		/**
		 * @description cria a array de objs que representam todos
		 * os funcionários selecionados. Antes de adicionar na array,
		 * verifica se a data selecionada é depois da data de contratação
		 * do funcionário, não adicionando na array caso for antes, mas
		 * adiciona diretamente na array de erros, p/ que o usuário possa ver
		 * que o dia não foi processado.
		 * @return {array} com os campos worker:mtocodigo & day:data
		 * @author Gui 🧟
		 */
		criaObjetos(){
			var arr = this.selectedWorkers.filter((w)=>w.selecionado)
			var arrDias = this.criaArrayPeriodo()
			this.progressAllDaysSet = new Set(arrDias)
			var arrObjectos = []
			for(var d of arrDias){
				for(var w of arr){
					var data_ini = DateTime.fromISO(w.data)
					var data_req = DateTime.fromISO(d)
					let obj = {
						'worker':w.mtcodigo,
						'day':d,
						'processado':false
					}
					if(data_req >= data_ini){
						arrObjectos.push(obj)
					}else{
						var erro = {
							mtnome: w.mtnome,
							data: d,
							erro:['Data menor que início de controle do colaborador'] 
						}
						this.errosProcessamento.push(erro)
					}
				}
			}
			return arrObjectos
		},

		/**
		 * @description ordena a array de erros,
		 * primariamente pela data e, em seguida, pelo nome do usuário
		 * @author Gui 🧟‍♀️
		 */
		ordenarErros(){
			this.errosProcessamento = _.orderBy(
				this.errosProcessamento,
				['data', 'mtnome'],
				['asc', 'asc']
			)
		},

		/**
		 * @param {string} day - a data que o badge representa
		 * @return {string} com a variante do bad
		 * @description verifica se o badge em questão já foi processado
		 * ou já esta sendo processado no momento, e decide a variante de
		 * acordo com isso.
		 */
		decideVarianteBadge(day){
			if(day === this.progressCurentDay)
				return 'primary'
			if(this.progressDaysDone.has(day))
				return 'success'
			return 'secondary'
		},

		/**
		 * @listens changeSelect - seletor de empresa.
		 * @param {number[]} arr - código dos clientes selecionados.
		 * @description Mantem a variavel de controle atualizada
		 * com os clientes selecionados e limpa as opts de trabalhador
		 * reseta a validação do campo de funcionário.
		 */
		changeEmpresa(arr){
			this.selectedWorkers = []
			this.checkSelTodos = false
			this.clienteSelecionado = arr
			this.optTrabalhador = []
			this.$refs.selTrabalhador.clearAll()
			this.$v.trabalhadorSelecionado.$reset()
			this.errosProcessamento = []
			if(arr.length)
				this.procuraTrabalhador(arr)
		},
		
		/**
		 * @listens changeSelect - seletor de funcionario.
		 * @param {number[]} arr - código dos funcionarios selecionados.
		 */
		changeTrabalhador(arr){
			this.selectedWorkers = []
			this.errosProcessamento = []
			this.checkSelTodos = true
			this.trabalhadorSelecionado = arr
		},

		/**
		 * @listen changeInput - input de data
		 */
		changeIntervalo(data){
			this.intervalo = data
			this.tableData = []
		},

		/**
		 * @param {number[]} arr - código dos clientes selecionados.
		 * @description procura pelos funcionários do tipo
		 * AD no banco de dados, cuida para o loading do campo
		 * de trabalhador funcionar da maneira carreta.
		 * @author Gui 🍺
		 */
		procuraTrabalhador(arr){
			this.loadingTrabalhador = true
			new FiltrosService().dados_filtros(arr, ['MAD'])
				.then((res)=>{
					this.optTrabalhador = res['MAD']
				})
				.finally(()=>this.loadingTrabalhador = false)
		},

		/**
		 * @listens click botão gerar
		 * @description gera a tabela que mostra
		 * os funcionários selecionados.
		 * @author Gui 🧟
		 */
		gerarRelatorio(){
			this.selectedWorkers = []
			this.errosProcessamento = []
			this.loading = true
			this.checkSelTodos = true
			var obj = {
				'workers':this.trabalhadorSelecionado,
				'intervalo':this.intervalo
			}
			var url = urlBase+'listar'
			new HttpRequest().Post(url, obj).then((res)=>{
				this.selectedWorkers = res.data.workers.map(w=>{
					return {
						...w,
						selecionado:true,
						temFechamento:false,
						status:'gray'
					}
				})
			}).finally(()=>this.loading = false)
		},

		/**
		 * @description cria a array com todas as datas dentro
		 * do periodo selecionado.
		 * @returns {string[]} todas as datas no formato yyyy-mm-dd
		 * @author Gui 🍺
		 */
		criaArrayPeriodo(){
			let fromFormat = 'dd/LL/yyyy'
			let toFormat = 'yyyy-LL-dd'
			let ini = this.intervalo.split(' - ')[0]
			ini = DateTime.fromFormat(ini, fromFormat)
			let fim = this.intervalo.split(' - ')[1]
			fim = DateTime.fromFormat(fim, fromFormat)
			var diff = fim.diff(ini, 'days').toObject().days
			var arrPeriodo = [ini.toFormat(toFormat)]
			for(let i = 1; i < diff; i++){
				var date = ini.plus({days: i})
				date = date.toFormat(toFormat)
				arrPeriodo.push(date)
			}
			if(diff !== 0)
				arrPeriodo.push(fim.toFormat(toFormat))
			return arrPeriodo
		}
	},
	computed:{
		/**
		 * O usuário só pode processar as informações caso ele tenha
		 * permissão de cadastrar ou editar
		 */
		podeProcessar(){
			return this.getPermicoes()['cadastrar'] || this.getPermicoes()['editar']
		},

		/**Decide se existem informações na tabela p/ mostrar pro user */
		showTable(){
			return !!this.selectedWorkers.length
		},

		/**Decide se o seletor de trabalhador deve estar habilitado */
		desabilitaSeletorT(){
			if(this.optTrabalhador.length)
				return false
			return true
		},

		/**Decide se o botão de processar esta habilitado ou não */
		processarDesabilitado(){
			if(this.selectedWorkers.length)
				if(this.selectedWorkers.some((l)=>{
					return l.selecionado
				})){
					return false
				}else return true
			return true
		}
	},
	watch:{
		checkSelTodos(newValue){
			this.selectedWorkers.forEach((w)=>{
				w.selecionado = newValue
			})
		},
	},
	mounted () {
		this.hoje = DateTime.now().toFormat('dd/LL/yyyy')
	},
}
</script>

<style lang="scss">
#processamento-checkpoint{
	--bolinha: 18px;
	div[class*=gray]{
		background-color: #b5b5b5;
	}
	div[class*=green]{
		background-color: #6db000;
	}
	div[class*=red]{
		background-color: #f8a1a2;
	}
	.bolinha{
		margin:1px;
		height: var(--bolinha);
		width: var(--bolinha);
		border-radius: 50%;
	}
	.checktodos{
		padding-left: 12%;
	}
	.fingtable{
		max-height: 400px;
		overflow-x: auto;
	}
	.erros-processamento-texto{
		color: red;
		text-decoration: underline;
	}
}
#body-progresso{
	.badge-day{
		width: 65px;
        margin: 3px;
        padding: 4px;
        overflow-wrap: normal;
        font-size: 12px !important;
	}
}
.fingtable{
	border: 1px solid #d4d4d4;
	padding: 5px;
	max-height: 400px;
	overflow-y: auto;
}
.header-modal{
	font-size: 13;
	background: #6db000;
}
.modal-header{
	font-size: 13px;
	background: purple;
}
</style>