/**
    Este componente é um DropZone

    -> Propriedades:
        1- msgCentro - String - Mensagem que fica no meio do dropzone - EX: 'Arraste aqui seus arquivos'
        2- arquivosPermitidos - String - Tipos de arquivos permitidos separados por virgula - EX: '.txt,image/*'

    -> Slot:
        Sem slot

    -> Eventos:
        1- files - Emite quando for colocado ou tirado um arquivo do dropzone - Parametros:
            1.1- fileRecordsForUpload - Array de arquivos do dropzone 
 */
<template>
    <div class="col-sm-12">
        <VueFileAgent 
            v-model="fileRecordsForUpload"  
            :errorText="{type:'Formato de arquivo inválido', size:'Tamanho máximo de arquivo excedido', 'Network Error':'Tente novamente mais tarde'}"
            :accept="arquivosPermitidos" 
            ref="vueFileAgent" 
            :helpText="msgCentro" 
            :multiple="multiple" 
            :deletable="true" 
            v-bind="$attrs"
            v-on='$listeners'
            :meta="true" 
            @beforedelete="fileDeleted" 
            @select="fileAdd"
        >
        <slot></slot>
        </VueFileAgent>
    </div>
</template>
<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
    props:{
        msgCentro:{
            type: String,
            required: false,
            default: 'MAH LARGUE AQUI O TEU ARQUIVO PIA'
        },
        arquivosPermitidos:{
            type: String,
            required: false,
            default: '.txt'
        },
        multiple:{
            type: Boolean,
            required: false,
            default: true
        }
    },
    components: {
    },
    data(){
        return{
            fileRecordsForUpload: [] as any,
        }
    },
    methods:{
        fileDeleted(fileRecord) {
            var i = this.fileRecordsForUpload.indexOf(fileRecord);
            this.fileRecordsForUpload.splice(i, 1);
            this.getArchivesForUpload();
        },
        fileAdd(){
            this.getArchivesForUpload();
        },
        getArchivesForUpload(){
            this.$emit('files', this.fileRecordsForUpload)
        },
        clearArchives(){
            this.fileRecordsForUpload = [];
        }
    },
})
</script>
<style lang="scss">
    .vue-file-agent{
        border: 1px solid #e8e8e8 !important;
    }
</style>