<template>
    <panelEagle id="CadastroJustificativas" :loading="loadingPanelEagle">
        <div class="col-sm-12 nopadding">
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-6 row">
                    <tituloPage titulo="Justificativas" :icon="mdiCommentAlert"> </tituloPage>
                </div>
                <div class="col-sm-6 row divDontPrint">
                    <basicButtonsCadastrosSC
                        linkCancel="listarJustificativas"
                        tipoCancela="button"
                        :disabled="$v.$invalid"
                        @salvarCadastro="salvarCadastro"
                    >
                    </basicButtonsCadastrosSC>
                </div>
            </div>
            <div class="col-sm-12"><hr /></div>
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-4 nopadding">
                    <selectAll
                        nameForRadio="nameForRadio"
                        :isMultiple="false"
                        :hasSelectAll="false"
                        firstSelected="EM"
                        :labels="[{ indexDFH: 'EM', description: 'Empresa*' }]"
                        :optionsArray="optEmpresas"
                        :selected="selectedEmpresa"
                        :extraClass="{ 'border border-danger': $v.empresaSelecionada.$anyError }"
                        @close="$v.empresaSelecionada.$touch()"
                        @changeSelect="changeSelectEmpresa"
                    />
                </div>
                <div class="col-sm-4 nopadding">
                    <inputSimple
                        type="text"
                        label="Descrição*"
                        :inputClass="{ 'boder border-danger': $v.valueDescricao.$anyError }"
                        :value="valueDescricao"
                        @blur="$v.valueDescricao.$touch()"
                        @changeInput="changeDescricao"
                    />
                </div>
                <div class="col-sm-4 nopadding">
                    <b-form-group class="checkbox">
                        <b-form-checkbox-group
                            class="checkbox-item"
                            v-model="selectedCheckbox"
                            :options="optionsCheckbox"
                        > </b-form-checkbox-group>
                    </b-form-group>
                </div>
            </div>
            <requiredFields />
            <div class="col-sm-12"><hr /></div>
        </div>
    </panelEagle>
</template>
<script>
import { EmpresasService } from "@/Services/auth/Empresas.service";
import { HttpRequest } from "@/Services/auth/HttpRequest.Service";
import { required } from "vuelidate/lib/validators";
import Vue from "vue";
import { mdiCommentAlert } from "@mdi/js";
import { conectionError } from "@/Services/Helpers/swellHeper";
export default Vue.extend({
    name: "CadastroJustificativasRoteirizador",
    components: {
        selectAll: require("@/components/Atom/Select/SelectAll").default,
        panelEagle: require("@/components/Atom/Panel/PanelEagle").default,
        tituloPage: require("@/components/Atom/Header/Titulo").default,
        requiredFields: require("@/components/Atom/Footer/RequiredFields").default,
        inputSimple: require("@/components/Atom/Inputs/InputSimple").default,
        basicButtonsCadastrosSC: require("@/components/Atom/Buttons/BasicButtonsCadastrosSC")
            .default,
    },
    validations: {
        empresaSelecionada: { required },
        valueDescricao: { required },
    },

    data() {
        return {
            optionsCheckbox: [
                { value: 'junaorealizada', text: 'Coleta/Entrega não realizada' },
                { value: 'juretornarroteirizador', text: 'Retornar coleta/entrega para roteirizador' },
                { value: 'juobrigatoriofoto', text: 'Obrigatório tirar foto' },
                { value: 'juobrigatorioassinatura', text: 'Obrigatório realizar assinatura' },
                { value: 'juenvioemail', text: 'Enviar e-mail de confirmação para cliente?' }
            ],
            selectedCheckbox: [],
            mdiCommentAlert: mdiCommentAlert,
            loadingPanelEagle: false,
            optEmpresas: new EmpresasService().Get(),
            url: "/roteirizador/cadastros/justificativas/",
            selectedEmpresa: [],
            empresaSelecionada: [],
            valueDescricao: "",
            id: "",
            junaorealizada: false,
            juretornarroteirizador: false,
            juobrigatoriofoto: false,
            juobrigatorioassinatura: false,
            juenvioemail:false
        };
    },

    methods: {
        changeSelectEmpresa(empresa) {
            this.empresaSelecionada = empresa;
        },

        changeDescricao(descricao) {
            this.valueDescricao = descricao;
        },

        salvarCadastro() {
            this.loadingPanelEagle = true;
            var obj = {
                id: this.id,
                judescricao: this.valueDescricao,
                jucliente: this.empresaSelecionada[0],
                optionCheckbox: this.selectedCheckbox
            };
            new HttpRequest().Post(this.url + "salvar", obj).then((dados) => {
                if (dados.code == 201) {
                    this.$router.push({ name: "listarJustificativas" });
                } else {
                    conectionError();
                }
                this.loadingPanelEagle = false;
            });
        },
        preencheCheckbox(data) {
        const checkboxJustificativas = JSON.parse(data.dados.checkboxjustificativas)
                for (const key in (checkboxJustificativas[0])) {
                    if (checkboxJustificativas[0][key]) {
                        this.selectedCheckbox.push(key)
                    }
                }
        },
    },

    async mounted() {
        if (this.$route.params.id != undefined) {
            this.loadingPanelEagle = true;
            this.id = this.$route.params.id;
            new HttpRequest()
                .Post(this.url + "busca/dados", { id: this.id })
                .then(({ data, status }) => {
                    if (status) {
                        this.valueDescricao = data.dados.judescricao;
                        this.selectedEmpresa = [
                            {
                                value: data.dados.jucliente,
                                description: data.dados.clfantasia,
                            },
                        ];
                        this.preencheCheckbox(data)
                     
                    } else {
                        conectionError();
                    }
                })
                .catch((error) => {
                    conectionError();
                })
                .finally(() => {
                    this.loadingPanelEagle = false;
                });
        }
    },
});
</script>

<style lang="scss">
.checkbox-item {
    display: flex;
    flex-direction: column;
}
</style>
