import {
	mdiMapMarkerDistance,
	mdiClipboardArrowLeft,
	mdiVectorSquare,
	mdiMagnify,
	mdiCheckBold,
	mdiTrashCanOutline,
	mdiPlus,
	mdiArrowDecisionOutline,
	mdiCloseThick,
	mdiReload,
	mdiShapePolygonPlus,
	mdiCheckOutline,
	mdiChevronDoubleRight,
	mdiCogs,
	mdiMapMarkerPlusOutline,
} from '@mdi/js'
import draggable from 'vuedraggable'
import { LPolygon, } from 'vue2-leaflet'
import { DateTime, Duration } from 'luxon'
import { Pedido } from '../models'
import { RecycleScroller, DynamicScroller } from 'vue-virtual-scroller';

const icons = <object>{
	mdiMapMarkerDistance: mdiMapMarkerDistance,
	mdiClipboardArrowLeft: mdiClipboardArrowLeft,
	mdiVectorSquare: mdiVectorSquare,
	mdiMagnify: mdiMagnify,
	mdiCheckBold: mdiCheckBold,
	mdiTrashCanOutline: mdiTrashCanOutline,
	mdiPlus: mdiPlus,
	mdiArrowDecisionOutline: mdiArrowDecisionOutline,
	mdiCloseThick: mdiCloseThick,
	mdiReload: mdiReload,
	mdiShapePolygonPlus: mdiShapePolygonPlus,
	mdiCheckOutline: mdiCheckOutline,
	mdiChevronDoubleRight: mdiChevronDoubleRight,
	mdiCogs: mdiCogs,
	mdiMapMarkerPlusOutline: mdiMapMarkerPlusOutline,
}

const data = <object>{
	url: '/roteirizador/rota/manual/',

	// NÃO APAGAR ISTO AQUI (dragOptions), MODIFICAR SÓ SE TIVER CERTEZA
	dragOptions: {
		animation: 200,
		group: 'description',
		disabled: false,
		ghostClass: 'ghost',
		forceFallback: true,
		fallbackTolerance: 5,
		emptyInsertThreshold: 5,
		// fallbackOnBody: true,
	},

	optPonto: [],
	optFiltroRegiao: [],
	optSeletorRegiao: [],
	optMotorista: [],
	optAjudante: [],
	optFiltroMarcador: [],
	optSeletorMarcador: [],
	regiaoFreeDraw: [],

	tabsPag: [
		{ 'title': 'Pedidos', 'value': 'pedidos', 'disabled': false },
		{ 'title': 'Veículos', 'value': 'veiculos', 'disabled': false },
		{ 'title': 'Cargas', 'value': 'cargas', 'disabled': false },
	],
	modelTab: 0,

	// value selects e inputs
	rota: {
		empresa: '',
		pontoSaida: '',
		pontoRetorno: '',
		motorista: '',
		ajudante: '',
		data: '',
		hora: '',
		dataHoraLimite: '',
		tempoParada: '',
		velocidade: 0,
	},
	filtro: {
		regiao: [],
		marcador: [],
		dataini: null,
		datafim: null,
	},

	valueRegiao: [],
	valueMarcador: [],
	valueBuscaPedido: '',

	// quando ta visualizando uma rota
	visualizarPontos: false,
	rotaVisualizadaAtual: '',
	rotaProgramada: {
		motorista: '',
		ajudante: '',
		veiculo: '',
		tempo: '',
		volume: '',
		valor: '',
		peso: '',
		km: '',
		cubagem: '',
		custoVeiculo: '',
		coordenadas: [],
		pontos: [],
		latRetorno: 0,
		lngRetorno: 0,
		latSaida: 0,
		lngSaida: 0,
	},

	// controle
	loadingButtonSelect: false,
	formatoDtErrado: false,
	loadingPanel: false,
	buscandoPedido: true,
	statusBarPedido: 'info',
	statusBarVeiculos: 'info',
	statusBarCargas: 'info',
	veiculoSemFiltro: [],
	desenhando: false,
	loadingBtnImprimirRota: false,
	loadingBtnExcluirCarga: false,
	regiaoErrada: '',
	controleAttPonto: false,


	// variaveis e objs dos dados
	pedidos: {
		draggable: <Pedido>[], // valuePontosEItens markers e pedidos
		markers: [], // markers nao selecionados
		markersX: [], // markersSelecionados
	},
	valueVeiculos: [],
	valueCargas: [],
	arrayRastro: [],
	latitudeSaida: 0,
	longitudeSaida: 0,
	latitudeRetorno: 0,
	longitudeRetorno: 0,
	rotaOsrm: {
		tempo: 0,
		distancia: 0,
		volume: 0,
		peso: 0,
		valor: 0,
		cubagem: 0,
	},

	dadosRotaOsrm: [],
	freeDrawObject: [],
	coordenadasRegioes: [],
	desenhoRegioes: [],
	selectedEmpresa: [],
	pontoSaida: {}, //guarda as informações do ponto de saída 
	pontoRetorno: {}, //guarda as informações do ponto de retorno

	// determina quando a rota precisa ser roteiziada ou ponto a ponto
	showAlertaRotaFixa: false,
	controleEtapas: null,
	listaDeRegioes: new Map(),
	dataCarregado: '',
	titleBtn: 'Buscar informações conforme etapa anterior',
	limitePedidos: 500,
	totalPedidos: 0,

	placaCarregamento: '',
	avisoItemCarregado: '',
	avisoVeiculoCarregado: '',
	dadosExcluirCarga: {
		rota: '',
		index: '',
	},

	format: 'yyyy-LL-dd HH:mm:ss',
	format2: 'dd/LL/yyyy HH:mm:ss',
	formatBr: 'dd/LL/yy HH:mm',
	// mostrarMapa: false,
}

const components = {
	'dayPicker': require('@/components/Atom/Datas/InputRangeWithLimit').default,
	draggable,
	LPolygon,
	RecycleScroller,
	DynamicScroller,
	tabsEagle: require("@/components/Atom/Tabs/TabsEagle").default,
	tituloPage: require("@/components/Atom/Header/Titulo").default,
	BCopiar: require("@/components/Common/Buttons/BCopiar").default,
	selectAll: require("@/components/Atom/Select/SelectAll").default,
	panelEagle: require("@/components/Atom/Panel/PanelEagle").default,
	modalEagle: require("@/components/Atom/Modal/ModalEagle").default,
	mapaSimples: require("@/components/Atom/Mapa/MapaSimples").default,
	markerPoint: require("@/components/Atom/Mapa/MarkerPoint").default,
	BImprimir: require("@/components/Common/Buttons/BImprimir").default,
	inputSimple: require("@/components/Atom/Inputs/InputSimple").default,
	markerCluster: require("@/components/Atom/Mapa/MarkerCluster").default,
	simpleButton: require("@/components/Atom/Buttons/SimpleButton").default,
	inputSingleDay: require("@/components/Atom/Datas/InputSingleDay").default,
	loadingButton: require("@/components/Atom/Buttons/LoadingButton").default,
	leafletFreeDraw: require("@/components/Atom/Mapa/LeafletFreeDraw").default,
	modalDeleta: require("@/components/Atom/ModalSpecific/ModalDeleta").default,
	polilinhaEditavel: require("@/components/Atom/Mapa/PolilinhaEditavel").default,
	modalCopiaRota: require("@/components/Atom/ModalSpecific/ModalCopiaRota").default,
	slideUpAndDown: require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
	modalIntegracoes: require("@/components/Atom/ModalSpecific/ModalIntegracoes").default,
	modalAddItensFimRota: require(
		"@/components/Atom/ModalSpecific/ModalAddItensFimRota").default,
	statusInformation: require(
		"@/components/Atom/StatusInformation/StatusInformation").default,
	modalAddItemRota: require(
		"@/components/Atom/ModalSpecific/ModalAdicionaItemRota").default,
	modalAddItemRotaParada: require(
		"@/components/Atom/ModalSpecific/ModalAddItemRotaParada").default,
	infoRotaOsrm: require(
		"@/components/Atom/SpecificComponents/RotaManual/InfoRotaOsrm").default,
	TabCargas: require(
		"@/components/Atom/SpecificComponents/RotaManual/TabCargas.vue").default,
	TabVeiculos: require(
		"@/components/Atom/SpecificComponents/RotaManual/TabVeiculos.vue").default,
	cardPedido: require(
		"@/components/Atom/SpecificComponents/RotaManual/CardPedido.vue").default,
	CardVeiculo: require(
		"@/components/Atom/SpecificComponents/RotaManual/CardVeiculo.vue").default,
	AccordionEtapas: require(
		"@/components/Atom/SpecificComponents/RotaManual/AccordionEtapas.vue").default,
	SelectMarcadores: require(
		"@/components/Atom/SpecificComponents/RotaManual/SelectMarcadores.vue").default,
	MarkerSelecionado: require(
		"@/components/Atom/SpecificComponents/RotaManual/MarkerSelecionado.vue").default,
	MapaRotaProgramada: require(
		"@/components/Atom/SpecificComponents/RotaManual/MapaRotaProgramada.vue")
		.default,
	ClusterSelecionados: require(
		"@/components/Atom/SpecificComponents/RotaManual/ClusterSelecionados.vue")
		.default,
	InfoEtapaRotaManual: require(
		"@/components/Atom/SpecificComponents/RotaManual/InfoEtapaRotaManual.vue")
		.default,
}

function converteSegundosEmHoras(segundos: number, formato: string = 'hh:mm:ss'): string {
	let tempo = Duration.fromObject({ seconds: segundos })
	// let tempo = DateTime.fromSeconds(segundos)
	if (tempo.isValid) {
		return `${tempo.toFormat(formato)}`
	}
	return ''
}

function converteSegundosEmDataHora(segundos: number): string {
	let tempo = DateTime.fromSeconds(segundos)
	if (tempo.isValid) {
		return `${tempo.toFormat('dd/MM/yyyy HH:mm:ss')}`
	}
	return ''
}

function geraCor(): string {
	var cores = [
		'#6A5ACD', '#483D8B', '#191970', '#000080', '#00008B',
		'#0000CD', '#0000FF', '#6495ED', '#4169E1', '#1E90FF',
		'#00BFFF', '#87CEEB', '#ADD8E6', '#4682B4', '#708090',
		'#778899', '#00FFFF', '#00CED1', '#40E0D0', '#48D1CC',
		'#20B2AA', '#008B8B', '#5F9EA0', '#2F4F4F', '#00FF7F',
		'#90EE90', '#8FBC8F', '#00FF00', '#3CB371', '#2E8B57',
		'#006400', '#008000', '#228B22', '#32CD32', '#BDB76B',
		'#7CFC00', '#7FFF00', '#9ACD32', '#6B8E23', '#556B2F',
		'#808000', '#D2691E', '#DAA520', '#B8860B', '#8B4513',
		'#A0522D', '#BC8F8F', '#CD853F', '#9400D3', '#DEB887',
		'#D2B48C', '#7B68EE', '#9370DB', '#8A2BE2', '#4B0082',
		'#DC143C', '#9932CC', '#BA55D3', '#800080', '#8B008B',
		'#EE82EE', '#DA70D6', '#FFA07A', '#FF1493', '#FF69B4',
		'#C71585', '#FFB6C1', '#F08080', '#CD5C5C', '#FF8C00',
		'#800000', '#8B0000', '#B22222', '#A52A2A', '#FA8072',
		'#E9967A', '#FF4500', '#FF7F50', '#FF6347', '#FF0000',
	]
	var cor = cores[Math.floor(Math.random() * (0 + cores.length))]
	return cor
}

/**
 * @description ray-casting
 * @link http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html
 */
function isPointInsidePolygon(point: number[], polygonVertices: number[][]): boolean {
	const x = point[0], y = point[1];
	let inside = false;
	for (let i = 0, j = polygonVertices.length - 1; i < polygonVertices.length; j = i++) {
		const [xi, yi] = polygonVertices[i];
		const [xj, yj] = polygonVertices[j];
		const intersect =
			yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
		if (intersect) inside = !inside;
	}
	return inside;
}

function validarDataHora(value: string): boolean {
	try {
		const [data, hora] = value.split(" ");
		const [dia, mes, ano] = data.split("/").map(Number);
		const [horaNum, minuto, segundo] = hora.split(":").map(Number);
		const dt = DateTime.fromObject({
			day: dia,
			month: mes,
			year: ano,
			hour: horaNum,
			minute: minuto,
			second: segundo,
		}).isValid;
		return dt;
	} catch {
		return false;
	}
}

function pickColor(str: string) {
	if (!str) {
		return "rgb(66, 134, 244)";
	}
	const hue = _hashCode(str) % 360;
	const saturation = "75%";
	const lightness = "60%";
	const alpha = 0.8;
	return `hsl(${hue}, ${saturation}, ${lightness}, ${alpha})`;
}

function _hashCode(str: string) {
	let hash = 0;
	if (!str) {
		return hash;
	}
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	return hash;
}

/**
 * calcula os valores de peso, volume e valor dos pedidos
 * @param {any} itensRota objeto item rota
 */
function calculaValoresPedidos(itensRota: any) {
	var peso = 0.00
	var valor = 0.00
	var volume = 0
	var cubagem = 0.00
	for (const pedido of itensRota) {
		peso = peso + parseFloat(pedido.irpeso)
		valor = valor + parseFloat(pedido.irvalor)
		volume = volume + parseInt(pedido.irqtde)
		cubagem = cubagem + parseFloat(pedido.ircubagem)
	}
	return {
		peso: peso,
		valor: valor,
		volume: volume,
		cubagem: cubagem,
	}
}

/**
 * Função de ordenação de pedidos (irordem).
 * 
 * - a ordenação dos itens deve sempre respeitar o atributo irordem, que indica a posicao 
 * 		que o item fica na lista
 * - itens com atributo "selecionado == true" sejam ordenados de forma crescente
 * - itens com atributos "irinterjornada == true && irintrajornada == true" sejam 
 * 		ordenados de forma crescente, desde que não fiquem antes de itens com 
 * 		"irordem != 0 || selecionado == true"
 * - os itens com "irordem == 0 || selecionado != false" ficam no fim da lista ordenada
 * 
 * @param {object} a objeto item rota
 * @param {object} b objeto item rota
 */
function sortFnOrdemPedidos(
	a: { irordem: number; irinterjornada: boolean; irintrajornada: boolean; selecionado: boolean, irnome: string },
	b: { irordem: number; irinterjornada: boolean; irintrajornada: boolean; selecionado: boolean, irnome: string }
): number {
	const isSelected = (item) => item.selecionado === true;
	const isInterIntraJornada = (item) => item.irinterjornada === true && item.irintrajornada === true;
	const isOrdemZeroOrSelected = (item) => item.irordem === 0 || item.selecionado !== false;
	if (isSelected(a) && isSelected(b)) {
		return a.irordem - b.irordem
	}
	if (isSelected(a) && !isSelected(b)) {
		return -1;
	}
	if (!isSelected(a) && isSelected(b)) {
		return 1;
	}
	if (isInterIntraJornada(a) && !isInterIntraJornada(b)) {
		if (!isOrdemZeroOrSelected(a)) {
			return a.irordem - b.irordem;
		}
		return 1;
	}
	if ((a.irinterjornada || a.irintrajornada) && !(b.irinterjornada || b.irintrajornada)) {
		if (isSelected(a) && isSelected(b)) {
			if (!isOrdemZeroOrSelected(a)) {
				return a.irordem - b.irordem;
			}
			return 1;
		}
		return -1;
	}
	if (!(a.irinterjornada || a.irintrajornada) && (b.irinterjornada || b.irintrajornada)) {
		if (isSelected(a) && isSelected(b)) {
			if (!isOrdemZeroOrSelected(a)) {
				return a.irordem - b.irordem;
			}
			return 1;
		}
		return 1;
	}
	if (!isSelected(a) && !isSelected(b)) {
		if (isInterIntraJornada(a) && isInterIntraJornada(b)) {
			if (!isOrdemZeroOrSelected(a)) {
				return a.irordem - b.irordem;
			}
			return 1;
		}
		return a.irnome?.localeCompare(b.irnome) ?? 0; // Comparação alfabética das strings irnome
	}
	return 0;
}

function criaMarcadores(markers: string[]) {
	markers = markers.filter((marker: string) => marker !== "");
	return markers.map((marker: string) => ({
		description: marker,
		value: marker,
		color: pickColor(marker),
	}));
}

export {
	components,
	data,
	icons,
	converteSegundosEmHoras,
	converteSegundosEmDataHora,
	geraCor,
	isPointInsidePolygon,
	validarDataHora,
	pickColor,
	calculaValoresPedidos,
	sortFnOrdemPedidos,
	criaMarcadores,
}

// BACKUP
// function sortFnOrdemPedidos(a: { irordem: number }, b: { irordem: number }): number {
//     if (a.irordem === 0) {
//         return 1;
//     }
//     if (b.irordem === 0) {
//         return -1;
//     }
//     return a.irordem - b.irordem;
// }

/** BACKUP 
	calculateDistance(lat1, lon1, lat2, lon2, unit) {
		var radlat1 = Math.PI * lat1/180
		var radlat2 = Math.PI * lat2/180
		var radlon1 = Math.PI * lon1/180
		var radlon2 = Math.PI * lon2/180
		var theta = lon1-lon2
		var radtheta = Math.PI * theta/180
		var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
		dist = Math.acos(dist)
		dist = dist * 180/Math.PI
		dist = dist * 60 * 1.1515
		if (unit=='K') { dist = dist * 1.609344 }
		if (unit=='N') { dist = dist * 0.8684 }
		return dist
	},
*/