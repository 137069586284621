<template>
    <panelEagle id='listarUsuariosApp' :loading='gerandoRel'>
        <div class='col-sm-12 nopadding '>
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage titulo='Usuários app' :icon='mdiCellphoneText'></tituloPage>
                </div>
                <div class="col-sm-6 divDontPrint">
                    <botoesEIN
                        :disabledDropdown="!Object.keys(dataListagem).length"
                        linkNew='cadastrarUsuarioApp'
                        tipoNovo='button'
                        :loading='criandoExportacao'
                        @exportarCadastro='exportaUsuarios'>
                    </botoesEIN>
                </div>
                <div class="col-sm-12"><hr></div>
            </div>
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-4 nopadding divDontPrint">
                    <selectAll
                        nameForRadio='SelecaoEmpresas'
                        :labels='[{"description":"Empresas"}]'
                        :selected='firstOne'
                        :optionsArray='optSelectEmpresas'
                        :isMultiple='true'
                        :hasSelectAll='true'
                        @changeSelect='mudaSelectEmpresas'
                    ></selectAll>
                </div>
                <div class="col-sm-4 nopadding divDontPrint">
                    <buttonsFilters
                        label='Status'
                        :arrayButtons='botoesFiltros'
                        @buttonsFiltersChange='mudaBotaoStatus'>
                    </buttonsFilters>
                </div>
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <tableListagem
            :titles='titulosLista'
            :data='dataListagem'
            deleteEdit='editStatus'
            @editarCadastro='editaUsuario'
            @alterarStatus='alterarStatusUsuario'>
        </tableListagem>
    </panelEagle>
</template>
<script lang="ts">
import { EmpresasService } from '@/Services/auth/Empresas.service';
import {mapGetters} from 'vuex'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { mdiCellphoneText, mdiCheckBold } from '@mdi/js'
import Vue from 'vue'
export default Vue.extend({
    name:'listarUsuariosApp',
    components:{
        'panelEagle'     : require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage'     : require('@/components/Atom/Header/Titulo').default,
        'botoesEIN'      : require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
        'selectAll'      : require('@/components/Atom/Select/SelectAll').default,
        'buttonsFilters' : require('@/components/Atom/Buttons/ButtonsFilters').default,
        'tableListagem'  : require('@/components/Atom/Table/TableListagem').default,
    },
    data(){
        return{
            mdiCellphoneText:mdiCellphoneText,
            mdiCheckBold    : mdiCheckBold,
            // links:
            urlBase         : '/administrativo/cadastros/usuarios/app/',
            // botões
            botoesFiltros:[
                {'value':'A', 'width': '32%', 'text': 'Ativo',   'isSelected':true,  'icon':''},
                {'value':'I', 'width': '32%', 'text': 'Inativo', 'isSelected':false, 'icon':''},
                {'value':'T', 'width': '32%', 'text': 'Todos',   'isSelected':false, 'icon':''},
            ],
            // Labels
            labelUsuarios:[
                {indexDFH: 'EM', 
                 description: 'Empresa'}
            ],
            titulosLista:[
                {'name': 'Código' , 'number': 'usacodigo' },
                {'name': 'Nome'   , 'number': 'name'      },
                {'name': 'Perfil' , 'number': 'usaperfil' },
                {'name': 'Empresa', 'number': 'usacliente'}
            ],
            //Opções para seletores
            optSelectEmpresas: new EmpresasService().Get(),
            //Local
            firstOne:[],
            dataListagem        : [] as any,
            filtroStatus        : '',
            empresasSelecionadas: [] as any,
            criandoExportacao   : [false, false, false],
            gerandoRel: false,
        }
    },
    methods:{
        /**
         * Envia os dados para o back-end para processar a exportação
         * @param value com o formato de exportação (pdf, xls, csv)
         * @return void
         * @author Gui 🍺
         */
        exportaUsuarios(value:any){
            switch (value) {
                case 'pdf':
                    this.criandoExportacao = [true, false, false]
                    break;
                case 'xls':
                    this.criandoExportacao = [false, true, false]
                    break;
                default:
                    this.criandoExportacao = [false, false, true]
                    break;
            }
            var root =  process.env.VUE_APP_ROOT;
            var obj = {arrayDados : this.dataListagem, tipo : value};
            let uri = this.urlBase+'exportar'
            new HttpRequest().Post(uri, obj).then((data)=>{
                this.criandoExportacao = [false, false, false]
                window.open(root+'/'+data.data.local);
            })
        },

        alterarStatusUsuario(value:any, newStatus:any){
            var obj = {codigo : value, status: newStatus}
            let uri = this.urlBase+'alterastatus'
            new HttpRequest().Post(uri, obj).then(()=>{
                this.listarUsuariosApp();
            })
        },

        mudaSelectEmpresas(value:any){
            this.limpaCampos();
            this.empresasSelecionadas = value;
            this.listarUsuariosApp()
        },
        
        mudaBotaoStatus(value: any){
            this.botoesFiltros[0].icon = ''
            this.botoesFiltros[1].icon = ''
            this.botoesFiltros[2].icon = ''
            if(value[0] == 'A'){
                this.botoesFiltros[0].icon = mdiCheckBold
            } else if(value[0] == 'I'){
                this.botoesFiltros[1].icon = mdiCheckBold
            } else if(value[0] == 'T'){
                this.botoesFiltros[2].icon = mdiCheckBold
            }
            if(value[0] == 'T') value = ['I', 'A']
            this.filtroStatus = value;
            this.listarUsuariosApp()
        },
        
        limpaCampos(){
            this.dataListagem = [];
        },
        
        /**
         * "prepara" os dados a serem listados para
         * facilitar a leitura para o usuário
         * @param data, com a Array com os dados
         * a serem exibidos
         * @return void
         * @author Gui 🍺
         */
        arrumaDados(data){
            this.dataListagem = [];
            data?.usuarios.forEach(usuario => {
                if(!usuario.nome){
                    usuario.nome = 'Sem motorista';
                }
                if(usuario.usaperfil == 'M'){
                    usuario.usaperfil = 'Motorista / Ajudante'
                }else if(usuario.usaperfil=="A"){
                    usuario.usaperfil = 'Administrativo'
                }else{
                    usuario.usaperfil = 'Roterizador'
                }
                usuario['status'] = usuario.usastatus
                this.optSelectEmpresas.forEach(empresa => {
                    if(empresa.value == usuario.usacliente){
                        usuario.usacliente = empresa.description
                    }
                });
                usuario.codigo = String(usuario['usacodigo']);
                this.dataListagem.push(usuario);
            });
        },
        
        editaUsuario(value:any){
            this.$router.push({name:'cadastrarUsuarioApp', params:{id:value}})
        },
        
        /**
         * Faz a req para o back-end com os filtros selecionados
         * @param null
         * @return null
         * @author Gui 🍺
         */
        listarUsuariosApp(){
            if(this.empresasSelecionadas.length != 0){
                this.gerandoRel = true
                var request =  {
                    clientes: this.empresasSelecionadas, 
                    status: this.filtroStatus
                }
                let uri = this.urlBase+'listar'
                new HttpRequest().Post(uri, request)
                .then((data)=>{
                    this.arrumaDados(data.data)
                    this.gerandoRel = false
                })
            }
        },
        selectAllClientes(){
            if(this.getMaster())
                this.firstOne = []
            else  
                this.firstOne =  new EmpresasService().Get()
        },
        ...mapGetters(['getMaster']),
    },
    mounted(){
        this.selectAllClientes()
    }
})
</script>
<style lang="scss" scoped>

</style>
