<template>
    <modalEagle 
        @modalClose="modalClose"
        :hardToClose='true' 
        title="Reidentificar pontos" 
        id="modalReidentificarPontos">
        <slot slot="modalBody">
            <div class="col-sm-12 row nopadding"> 
                <div class="col-sm-12 row nopadding" v-show="!reidentificandoPontos">
                    <div class="col-sm-6 row nopadding" id='inputRangeWithLimit'>
                        <div class="col-sm-12 nopadding">
                            <inputRangeWithLimit
                                @changeInput="changeInputDataModal"
                                title="Limite máximo de 7 dias"
                                name='inputRangeWithLimit'
                                opens='right'
                                titulo="Inicio* / Fim*"
                                :extraClass="{'border border-danger':(periodoDias)}"
                                :ComTitulo='false'
                            />
                        </div>
                        <div class="col-sm-12" v-show="periodoDias">
                            <span class="small text-danger">O período informado é superior a 7 dias</span>
                        </div>
                    </div>
                    <div class="col-sm-6 nopadding">
                        <selectAll
                            :hasSelectAll="true"
                            :isMultiple="true"
                            @changeSelect="changeEmpresaModal"
                            nameForRadio="name3"
                            :labels="[{indexDFH:'EM', description:'Empresa*'}]"
                            :optionsArray="optSelectEmpresas"
                        />
                    </div>
                    <div class="col-sm-6 nopadding mt-3">
                        <selectAll
                            title="Limite máximo de 10 pontos"
                            ref="refPontosModal"
                            :disabled="!valueEmpresaModal.length"
                            @changeSelect="changePontosModal"
                            :hasSelectAll="true"
                            :isMultiple="true"
                            :loading="laodingBtnModal"
                            nameForRadio="name3"
                            :labels="[{indexDFH:'PO', description:'Pontos*'}]"
                            :optionsArray="optPontosModal"
                        />
                    </div>
                </div>
                <!-- reidentificandoPontos -->
                <div class="col-sm-12 reidentificar-pontos " v-show="reidentificandoPontos">
                    <!--  -->
                    <div class="col-sm-12  fundo-azul mt-1 d-flex align-items-center">
                        <span class="mt-1">
                            <span class="my-auto">
                                Bilhetes
                            </span>
                        </span>
                        <template v-if="loadingReidentificarPontos[0] == 2">
                            <span class=" ml-1 mr-1"> - </span>
                            <span class="">processamento concluído</span>
                        </template>
                        <template v-if="loadingReidentificarPontos[0] == 1">
                            <b-spinner small label="Small Spinner" class=" ml-1"></b-spinner>
                        </template>
                    </div>
                    <!--  -->
                    <div class="col-sm-12 mt-1 my-auto" v-show="loadingReidentificarPontos[0] == 1">
                        <!--  -->
                        <!--  -->
                        <progressBar
                            :diaProcessado="diaProcessadoBilhetes" 
                            :diasSelecionadosModal="diasSelecionadosSemAnoModal"
                            :progresso="progressoBilhetes"
                            :qtdPontos="qtdPontosBilhetes"
                        />
                    </div>
                    <!--  -->
                    <div class="col-sm-12  fundo-azul mt-1 d-flex align-items-center">
                        <span class="mt-1">
                            <span class="my-auto">
                                Paradas
                            </span>
                        </span>
                        <template v-if="loadingReidentificarPontos[1] == 2">
                            <span class="ml-1 mr-1 "> - </span>
                            <span class="">processamento concluído</span>
                        </template>
                        <template v-if="loadingReidentificarPontos[1] == 1">
                            <b-spinner small label="Small Spinner" class=" ml-1"></b-spinner>
                        </template>
                    </div>
                    <div class="col-sm-12 mt-1 " v-show="loadingReidentificarPontos[1] == 1">
                        <progressBar
                            :diaProcessado="diaProcessadoParadas" 
                            :diasSelecionadosModal="diasSelecionadosSemAnoModal"
                            :progresso="progressoParadas"
                            :qtdPontos="qtdPontosParadas"
                        />
                    </div>
                    <!--  -->
                    <div class="col-sm-12  fundo-azul mt-1 d-flex align-items-center">
                        <span class="mt-1">
                            <span class="my-auto">
                                Ignição
                            </span>
                        </span>
                        <template v-if="loadingReidentificarPontos[2] == 2">
                            <span class=" ml-1 mr-1"> - </span>
                            <span class="">processamento concluído</span>
                        </template>
                        <template v-if="loadingReidentificarPontos[2] == 1">
                            <b-spinner small label="Small Spinner" class=" ml-1"></b-spinner>
                        </template>
                    </div>
                    <div class="col-sm-12 mt-1 " v-show="loadingReidentificarPontos[2] == 1">
                        <progressBar
                            :diaProcessado="diaProcessadoIgnicao" 
                            :diasSelecionadosModal="diasSelecionadosSemAnoModal"
                            :progresso="progressoIgnicao"
                            :qtdPontos="qtdPontosIgnicao"
                        />
                    </div>
                    <!--  -->
                    <div class="col-sm-12  fundo-azul mt-1 d-flex align-items-center">
                        <span class="mt-1">
                            <span class="my-auto">
                                RFID
                            </span>
                        </span>
                        <template v-if="loadingReidentificarPontos[3] == 2">
                            <span class=" ml-1 mr-1"> - </span>
                            <span class="">processamento concluído</span>
                        </template>
                        <template v-if="loadingReidentificarPontos[3] == 1">
                            <b-spinner small label="Small Spinner" class=" ml-1"></b-spinner>
                        </template>
                    </div>
                    <div class="col-sm-12 mt-1 " v-show="loadingReidentificarPontos[3] == 1">
                        <progressBar
                            :diaProcessado="diaProcessadoRFID" 
                            :diasSelecionadosModal="diasSelecionadosSemAnoModal"
                            :progresso="progressoRFID"
                            :qtdPontos="qtdPontosRFID"
                        />
                    </div>
                    <!--  -->
                </div>
            </div>
        </slot>
        <slot slot="modalFooter">
            <span class="text-danger" v-show="msgPontosInvalidos">
                Selecione no máximo 10 pontos!
            </span>
            <b-overlay :show="desativarBtnRe" rounded="sm" :opacity="0.4">
                <template #overlay><div/></template>
                <SimpleButton
                    event="click"
                    @click="(v)=>{$bvModal.hide('modalReidentificarPontos')}"
                    type="red" 
                    text="Fechar" 
                    :icon="mdiCloseThick"
                    width="120px"
                />
                <SimpleButton
                    :disabled="$v.$invalid"
                    event="click"
                    @click="reidentificarPontosModal"
                    type="blue" 
                    text="Reidentificar" 
                    :icon="mdiCheckBold"
                    width="120px"
                />
            </b-overlay>
        </slot>
    </modalEagle>
</template>
<script>
import SimpleButton from '@/components/Atom/Buttons/SimpleButton.vue'
import selectAll from '@/components/Atom/Select/SelectAll.vue'
import modalEagle from '@/components/Atom/Modal/ModalEagle.vue'
import inputRangeWithLimit from '@/components/Atom/Datas/InputRangeWithLimit.vue'
import progressBar from '@/components/Atom/SpecificComponents/progressBarReidentificarPontos.vue'
import { conectionError } from '@/Services/Helpers/swellHeper.js'
import { DateTime } from "luxon"
import {mdiCloseThick, mdiCheckBold } from '@mdi/js'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { required } from 'vuelidate/lib/validators'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
export default {
    name:'modalReidentificarPontos',
    validations:{
        valueEmpresaModal:{required},
        valueDataModal:{required},
        valuePontosModal:{required},
    },
    components: {
        SimpleButton,
        selectAll,
        modalEagle,
        progressBar,
        inputRangeWithLimit,
    },
    props:{},
    data() {
        return {
            mdiCheckBold:mdiCheckBold,
            mdiCloseThick:mdiCloseThick,

            optSelectEmpresas: new EmpresasService().Get(),
            optPontosModal:[],

            laodingBtnModal:false,
            msgPontosInvalidos:false,
            reidentificandoPontos:false,
            // valores possiveis para cada index '0', '1', '2'
            loadingReidentificarPontos:[0, 0, 0, 0],
            diasSelecionadosModal:[],
            diaAtual:0,
            diasSelecionadosSemAnoModal:[],
            periodoDias:false,

            valueEmpresaModal:[],
            valueDataModal:'',
            valuePontosModal: [],

            progressoBilhetes:'',
            qtdPontosBilhetes:'',
            diaProcessadoBilhetes:'',

            progressoParadas:'',
            qtdPontosParadas:'',
            diaProcessadoParadas:'',

            progressoIgnicao:'',
            qtdPontosIgnicao:'',
            diaProcessadoIgnicao:'',

            progressoRFID:'',
            qtdPontosRFID:'',
            diaProcessadoRFID:'',

            desativarBtnRe:false,

            tabelaConsultaAtual:'',
            todosPontos:{},
        }
    },
    mounted(){
        var data = DateTime.now().toFormat('dd/MM/yyyy')
        this.changeInputDataModal(`${data} - ${data}`)
    },

    methods: {
        modalClose(){
            this.valueEmpresaModal = []
            this.valuePontosModal = []
            this.optPontosModal = []
        },
        reidentificarPontosModal(){
            this.todosPontos = {}
            this.loadingReidentificarPontos[0] = 0
            this.loadingReidentificarPontos[1] = 0
            this.loadingReidentificarPontos[2] = 0
            this.loadingReidentificarPontos[3] = 0
            this.progressoBilhetes = 0
            // this.qtdPontosBilhetes = 0
            this.progressoParadas = 0
            // this.qtdPontosParadas = 0
            this.progressoIgnicao = 0
            // this.qtdPontosIgnicao = 0
            this.progressoRFID = 0
            // this.qtdPontosRFID = 0
            this.desativarBtnRe = true
            this.reidentificandoPontos = true
            var retornobilhetes = this.arrumaObjDeRequest('bilhetes')
            var retornoparadas = this.arrumaObjDeRequest('paradas')
            var retornoignicao = this.arrumaObjDeRequest('ignicao')
            var retornorfid = this.arrumaObjDeRequest('rfid')
            var separadoPorDiaBilhetes = this.arrumaPontos(retornobilhetes)
            var separadoPorDiaParadas = this.arrumaPontos(retornoparadas)
            var separadoPorDiaIgnicao = this.arrumaPontos(retornoignicao)
            var separadoPorDiaRfid = this.arrumaPontos(retornorfid)
            this.todosPontos.bilhetes = separadoPorDiaBilhetes
            this.todosPontos.paradas = separadoPorDiaParadas
            this.todosPontos.ignicao = separadoPorDiaIgnicao
            this.todosPontos.rfid = separadoPorDiaRfid
            this.tabelaConsultaAtual = 'bilhetes'
            this.fazRequestGeral()
        },

        arrumaPontos(tabela){
            var arrSeparadoPorDia = {}
            for(const dia in this.diasSelecionadosModal){
                var diaNN = this.diasSelecionadosModal[dia]
                var arrDias = []
                tabela.forEach((v, index)=>{
                    if(v[0].data == diaNN){
                        arrDias.push(v[0])
                    }
                })
                arrSeparadoPorDia[diaNN] = arrDias
            }
            return arrSeparadoPorDia
        },
        
        loadingPontosInicio(value){
            switch (value) {
                case 'bilhetes': 
                    this.loadingReidentificarPontos[0] = 1
                    break;
                case 'paradas': 
                    this.loadingReidentificarPontos[1] = 1
                    break;
                case 'ignicao': 
                    this.loadingReidentificarPontos[2] = 1
                    break;
                case 'rfid': 
                    this.loadingReidentificarPontos[3] = 1
                    break;
                default:
                    break;
            }
        },

        loadingPontosFim(value){
            switch (value) {
                case 'bilhetes': 
                    this.loadingReidentificarPontos[0] = 2
                    break;
                case 'paradas': 
                    this.loadingReidentificarPontos[1] = 2
                    break;
                case 'ignicao': 
                    this.loadingReidentificarPontos[2] = 2
                    break;
                case 'rfid': 
                    this.loadingReidentificarPontos[3] = 2
                    break;
                default:
                    break;
            }
        },

        fazRequestGeral(){
            this.diaAtual = 0 
            let titulo = "Status reidentificação de pontos."
            try {
                let type = "success"
                let msg = "Ponto reidentificado com sucesso"
                switch(this.tabelaConsultaAtual){
                    case 'bilhetes':
                        this.loadingPontosInicio('bilhetes')
                        this.fazRequestDosPontos(this.todosPontos.bilhetes, 'bilhetes')
                        this.tabelaConsultaAtual = 'paradas'
                        break;
                    case 'paradas':
                        this.loadingPontosInicio('paradas')
                        this.fazRequestDosPontos(this.todosPontos.paradas, 'paradas')
                        this.tabelaConsultaAtual = 'ignicao'
                        break;
                    case 'ignicao':
                        this.loadingPontosInicio('ignicao')
                        this.fazRequestDosPontos(this.todosPontos.ignicao, 'ignicao')
                        this.tabelaConsultaAtual = 'rfid'
                        break;
                    case 'rfid':
                        this.loadingPontosInicio('rfid')
                        this.fazRequestDosPontos(this.todosPontos.rfid, 'rfid')
                        this.tabelaConsultaAtual = ''
                        break;
                    default:
                        this.loadingReidentificarPontos[3] = 2
                        this.desativarBtnRe = false
                        this.reidentificandoPontos = false
                        this.toastShow(titulo, msg, type) 
                        break;
                } 
            } catch (error) {
                let type = "danger"
                let msg = "Erro ao reidentificar pontos"
                this.toastShow(titulo, msg, type) 
            }
        },


        /**
         * Um toast simples que faz abri uma
         * pop no canto da tela com uma msg
         */
        toastShow(titulo, msg, type){
            this.$bvToast.toast(msg, {
                title: titulo,
                autoHideDelay: 2500,
                variant: type,
            })
        },

        async fazRequestDosPontos(obj, tabelaAtual){
            var newObj = obj[this.diasSelecionadosModal[this.diaAtual]]
            this.diaAtual += 1       
            var obj2 = newObj.length
            this.deixaNoEsquemaAPorcentagem(obj2, newObj.length, tabelaAtual, newObj[0].data)
            for(const index in newObj){
                obj2 -=1
                var dados = await new HttpRequest().Post('/telemetria/cadastros/pontos/reidentificar', newObj[index])
                if(obj2){
                    this.deixaNoEsquemaAPorcentagem(obj2, newObj.length, tabelaAtual, newObj[index].data)
                }
            }
            if(obj[this.diasSelecionadosModal[this.diaAtual]]){
                // esses intervalos são para esperar a animação da loadingBar, se tirar ela vai fica meio estranha quando n tiver dados em um dia
                // pq vai fazer as request muito rapido e o delay da animação nao acompanha
                setTimeout(()=>{
                    this.resetaPorce(tabelaAtual, newObj.length)
                }, 500)
                setTimeout(() => {
                    this.fazRequestDosPontos(obj, tabelaAtual)
                }, 1500);
            }else{
                this.fazRequestGeral()
                this.loadingPontosFim(tabelaAtual)
            }
        },

        resetaPorce(tabela, calculo){
            switch (tabela) {
                case 'bilhetes':
                    this.progressoBilhetes = 0
                    this.qtdPontosBilhetes =  `0/${calculo}`
                    break;
                case 'paradas':
                    this.progressoParadas = 0
                    this.qtdPontosParadas =  `0/${calculo}`
                    break;
                case 'ignicao':
                    this.progressoIgnicao = 0
                    this.qtdPontosIgnicao =  `0/${calculo}`
                    break;
                case 'rfid':
                    this.progressoRFID = 0
                    this.qtdPontosRFID =  `0/${calculo}`
                    break;
                default:
                    break;
            }
        },

        // o newObj.length-calculo vai sempre ficar negativo, por isso o *-1 e como começa conta em 0 tem o +1
        deixaNoEsquemaAPorcentagem(length, calculo, tabela, dia){
            var data = DateTime.fromFormat(dia, "dd/MM/yyyy")
            switch (tabela) {
                case 'bilhetes':
                    this.diaProcessadoBilhetes = data.toFormat('dd/MM')
                    this.progressoBilhetes = ((100 * (((length-calculo)*-1)+1))/calculo).toFixed(0)
                    this.qtdPontosBilhetes = `${((length-calculo)*-1)+1}/${calculo}`
                    break;
                case 'paradas':
                    this.diaProcessadoParadas = data.toFormat('dd/MM')
                    this.progressoParadas = ((100 * (((length-calculo)*-1)+1))/calculo).toFixed(0)
                    this.qtdPontosParadas = `${((length-calculo)*-1)+1}/${calculo}`
                    break;
                case 'ignicao':
                    this.diaProcessadoIgnicao = data.toFormat('dd/MM')
                    this.progressoIgnicao = ((100 * (((length-calculo)*-1)+1))/calculo).toFixed(0)
                    this.qtdPontosIgnicao = `${((length-calculo)*-1)+1}/${calculo}`
                    break;
                case 'rfid':
                    this.diaProcessadoRFID = data.toFormat('dd/MM')
                    this.progressoRFID = ((100 * (((length-calculo)*-1)+1))/calculo).toFixed(0)
                    this.qtdPontosRFID = `${((length-calculo)*-1)+1}/${calculo}`
                    break;
                default:
                    break;
            }
        },

        arrumaObjDeRequest(value){
            var arrRetorno = []
            for(var dia in this.diasSelecionadosModal){
                for(var pontos in this.valuePontosModal){
                    var obj = [{
                        empresas:this.valueEmpresaModal,
                        tabela:value,
                        data:this.diasSelecionadosModal[dia],
                        ponto:this.valuePontosModal[pontos],
                    }]
                    arrRetorno.push(obj)
                }
            }
            return arrRetorno
        },

        async changeEmpresaModal(value){
            this.valueEmpresaModal = value
            this.optPontosModal = []
            this.$refs.refPontosModal.clearAll()
            if(value.length){
                this.laodingBtnModal = true
                var dados = await new FiltrosService().dados_filtros(value, ['P'])
                this.optPontosModal = dados['P']
                this.laodingBtnModal = false
            }
        },

        changeInputDataModal(value){
            var datas = value.split(' - ')
            var d1 = DateTime.fromFormat(datas[0], "dd/MM/yyyy")
            var d2 = DateTime.fromFormat(datas[1], "dd/MM/yyyy")
            var difDias = d2.diff(d1, 'days')
            var intervalodDeDatas = []
            var intervaloDeDatasExibir = []
            this.periodoDias = false
            if(difDias.values.days >= 7){
                this.periodoDias = true
                this.valueDataModal = ''
            }else{
                for (let index = 0; index <= difDias.values.days; index++){
                    var dia = d1.plus({days: index})
                    intervalodDeDatas.push(dia.toFormat('dd/MM/yyyy'))
                    intervaloDeDatasExibir.push(dia.toFormat('dd/MM'))
                }
                this.valueDataModal = value
            }
            this.diasSelecionadosSemAnoModal = intervaloDeDatasExibir
            this.diasSelecionadosModal = intervalodDeDatas
        },
        
        changePontosModal(value){
            this.msgPontosInvalidos = false
            this.valuePontosModal = []
            if(value.length > 10){
                this.msgPontosInvalidos = true
            }else{
                this.valuePontosModal = value
            }
        },
    },
}
</script>
<style lang="scss">
    .reidentificar-pontos{
        border:1px solid rgba(0, 0, 0, 0.253);
        min-height: 100px;
        padding:10px;
        .fundo-azul{
            max-height: 20px;
            background-color: #428bca;
            color: #fff;
        }
    }
    // pra tirar o x que fica no canto da tela
    #modalReidentificarPontos___BV_modal_header_{
        button.close{
            display: none !important;
        }
    }
</style>