var render = function render(){var _vm=this,_c=_vm._self._c;return _c('panelEagle',{attrs:{"id":"resumoHorimetro","loading":_vm.loadingPanelEagle}},[_c('div',{staticClass:"col-sm-12 row nopadding"},[_c('div',{staticClass:"col-sm-12 row nopadding",attrs:{"id":"titulo_botoes"}},[_c('div',{staticClass:"col-sm-6"},[_c('titulo',{attrs:{"titulo":"Resumo Horímetro","icon":_vm.mdiProgressClock}})],1),_c('div',{staticClass:"col-sm-6 divDontPrint"},[_c('botoesEIG',{attrs:{"loading":_vm.loadingExportar,"disabled":_vm.$v.veiculosSelecionados.$invalid,"disabledDropdown":_vm.$v.relatorio.$invalid},on:{"gerarRelatorio":_vm.gerarRelatorio,"exportarRelatorio":_vm.exportarRelatorio}})],1)]),_c('slideUpAndDown',{scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('div',{staticClass:"col-sm-12 row nopadding"},[_c('div',{staticClass:"col-sm-12"},[_c('hr')]),_c('div',{staticClass:"col-sm-4 nopadding"},[_c('inputMonth',{attrs:{"label":"Mês*","name":"Mes"},on:{"changeInput":_vm.changeMes}})],1),_c('div',{staticClass:"col-sm-4 nopadding",attrs:{"id":"seletorEmpresa"}},[_c('selectAll',{attrs:{"nameForRadio":"seletorEmpresas","hasSelectAll":true,"labels":[{"description":"Empresas*"}],"isMultiple":true,"optionsArray":_vm.optSelectEmpresa,"selected":_vm.selectedEmpresa},on:{"changeSelect":_vm.changeEmpresa}})],1),_c('div',{staticClass:"col-sm-4 nopadding"},[_c('selectAll',_vm._g(_vm._b({attrs:{"nameForRadio":"seletorVeiculosGrupos","firstSelected":"V","disabled":_vm.$v.empresasSelecionadas.$invalid,"loading":_vm.carregando},on:{"changeSelect":_vm.changeVeiculo}},'selectAll',{
                            hasSelectAll:true,
                            isMultiple:true,
                            optionsArray:_vm.optSelectVeiculos,
                            labels:_vm.labelSeletorVeiculoGrupos,
                            ref:'selectVG'
                        },false),{changeCheck:_vm.mudaCheck}))],1)])]},proxy:true}])}),_c('div',{staticClass:"col-sm-12"},[_c('hr')]),(_vm.relatorio.length == 0)?_c('div',{staticClass:"col-sm-12 row"},[_c('statusInformation',{staticClass:"col-sm-12",attrs:{"typeBar":"div","statusBar":_vm.statusbar,"colspanForTd":"12"}})],1):_c('div',{staticClass:"col-sm-12 row nopadding"},_vm._l((_vm.relatorio),function(placa,k){return _c('div',{key:k,staticClass:"col-md-12 col-lg-6 row nopadding"},[[_c('div',{staticClass:"col-sm-12 text-center section"},[_c('div',{staticClass:"cabecalho col-sm-12 row"},[_c('span',{staticClass:"col-sm-6"},[_vm._v(" "+_vm._s(k)+" ")]),_c('span',{staticClass:"col-sm-6 kmTotal"},[_vm._v(" Total: "+_vm._s(_vm.calculaTotal(placa))+" Hora(s) ")])]),_c('vc-calendar',{staticClass:"custom-calendar max-w-full",attrs:{"from-page":{month: Number(_vm.mesCalendar[1]), year: _vm.mesCalendar[2]},"masks":_vm.masks,"disable-page-swipe":"","attributes":_vm.gerarAtributos(placa),"is-expanded":""},scopedSlots:_vm._u([{key:"day-content",fn:function({day, attributes}){return [_c('div',{staticClass:"fundoWhite"},[_c('span',[_vm._v(_vm._s(day.day))]),_vm._l((attributes),function(attr){return _c('p',{key:attr.key,staticClass:"fraseDia tamKm"},[_vm._v(" "+_vm._s(attr.customData.title)+" ")])}),_c('p',{staticClass:"fraseDia"},[_vm._v(" Horas(s) ")])],2)]}}],null,true)},[_vm._v(" > ")])],1)]],2)}),0)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }