<template>
    <panelEagle id="acionamentoSensores" :loading="loadingPanel">
        <div class="col-sm-12 nopadding ">
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-6">
                    <tituloPage
                        :icon="mdiCarConnected"
                        titulo="Acionamento de sensores"
                    ></tituloPage>
                </div>
                <div class="col-sm-6 divDontPrint">
                    <basicButtonsRelatoriosEIG
                        :disabled="$v.veiculosArray.$invalid || $v.portaSelecionada.$invalid"
                        :loading="loadingButton"
                        @exportarRelatorio="exportarRelatorio"
                        @gerarRelatorio="gerarRelatorio"
                        :disabledDropdown="$v.dadosRelatorios.$invalid"
                    >
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12"><hr /></div>
                    <div class="col-sm-12 row nopadding mb-2">
                        <div class="col-sm-3 nopadding">
                            <inputRangeNoLimits
                                opens="rigth"
                                :isObrigatorio="true"
                                @changeInput="changeData"
                                id="dataInicioAcionamento"
                                name="dataInicioAcionamento"
                            >
                            </inputRangeNoLimits>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll
                                @changeSelect="SelectEmpresas"
                                :isMultiple="true"
                                nameForRadio="radiosEmpresas"
                                :labels="labelsSelectEmpresa"
                                :optionsArray="optionsSelectEmpresa"
                                firstSelected="E"
                                :selected="selectedEmpresa"
                                :hasSelectAll="true"
                            >
                            </selectAll>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll
                                :loading="loadingBtn"
                                :disabled="!optionsSelectVeiculos.length"
                                @changeCheck="checaBotao"
                                @selectCriado="selectCriado"
                                @changeSelect="SelectVeiculos"
                                :isMultiple="true"
                                nameForRadio="radiosVeiculos"
                                :labels="labelsSelectVeiculos"
                                :optionsArray="optionsSelectVeiculos"
                                firstSelected="V"
                                :hasSelectAll="true"
                            >
                            </selectAll>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <buttonsFilters
                                @buttonsFiltersChange="clickBotao"
                                :arrayButtons="arrayButtons"
                                label="Portas"
                                :onlyOneSelected="false"
                            >
                            </buttonsFilters>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <div class="col-sm-3 nopadding" style="float:right">
                            <b-checkbox v-model="acionamentoForaDePonto">
                                Acionamentos fora de ponto
                            </b-checkbox>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
            <div class="col-sm-12"><hr /></div>
        </div>
        <tableRelatorio htmlRel="">
            <slot slot="thead">
                <tr>
                    <th>Porta</th>
                    <th>Sensor</th>
                    <th>Data/Hora início</th>
                    <th>Data/Hora fim</th>
                    <th>Tempo de Acionamento</th>
                    <th>Endereço</th>
                    <th>Ponto</th>
                    <th class="divDontPrint">Localizar</th>
                </tr>
            </slot>
            <!-- QJZ-8491|20321  -->
            <slot slot="tbody">
                <template v-for="(a, index) in dadosRelatorios">
                    <tr class="badgetr backColorTdPDF" :key="index">
                        <td colspan="8" class="p-0">
                            <span class="newbadge">
                                {{index}}
                            </span>
                        </td>
                    </tr>
                    <template v-for="(dados, indice) in dadosRelatorios[index]['acionamentos']">
                        <!-- {{dados}} -->
                        <!-- {{indice}} -->
                        <tr :key="indice+dados['dataHoraInicio']">
                            <td>{{dados['porta']}}</td>
                            <td>{{dados['sensor']}}</td>
                            <td>{{dados['dataHoraInicio']}}</td>
                            <td>{{dados['dataHoraFim']}}</td>
                            <td>{{dados['tempo']}}</td>
                            <td>{{dados['endereco']}}</td>
                            <td>{{dados['ponto']}}</td>
                            <td class="hidden-print tdDaLocalizacao">
                                <a
                                    class="cursor-pointer "
                                    title="Clique para ver local no mapa"
                                    @click="clickLinkMapa(dados['coordenadas'])"
                                >
                                    <baseIcon class="cor-azul " size="18" :icon="mdiTarget" />
                                </a>
                            </td>
                        </tr>
                    </template>
                    <template>
                        <tr :key="'Totalizador_'+index">
                            <td colspan="3"></td>
                            <td class="calculaTempo">
                                {{calculaTempoTotalAcionamento(dadosRelatorios[index]['acionamentos'])}}
                            </td>
                            <td colspan="3"></td>
                        </tr>
                    </template>
                </template>
                <br />
                <statusInformation
                    v-show="dadosRelatorios.length == 0"
                    typeBar="tr"
                    :statusBar="statusbar"
                >
                </statusInformation>
            </slot>
        </tableRelatorio>
    </panelEagle>
</template>

<script lang="js">
import Vue from 'vue'
import {mdiCarConnected, mdiTarget } from '@mdi/js'
import {EmpresasService} from '@/Services/auth/Empresas.service'
import {HttpRequest} from '@/Services/auth/HttpRequest.Service'
import {FiltrosService} from '@/Services/filtros/filtros.Service'
import { required } from 'vuelidate/lib/validators'
import { DateTime, Duration } from "luxon"
import { mapGetters } from 'vuex'
import { conectionError } from '@/Services/Helpers/swellHeper'

export default Vue.extend({
    name:'AcionamentoDeSensores',
    validations: {
        empresasArray  :{ required },
        veiculosArray  :{ required },
        dadosRelatorios :{ required },
        portaSelecionada: { required },
    },
    components:{
        baseIcon:require('@/components/Atom/Icon/BaseIcon.vue').default,
        'panelEagle' : require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage' : require('@/components/Atom/Header/Titulo').default,
        'basicButtonsRelatoriosEIG' : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
        'selectAll' : require('@/components/Atom/Select/SelectAll').default,
        'statusInformation' : require('@/components/Atom/StatusInformation/StatusInformation').default,
        'tableRelatorio' : require('@/components/Atom/Table/TableRelatorio').default,
        'inputRangeNoLimits' : require('@/components/Atom/Datas/InputRangeNoLimits').default,
        'buttonsFilters' : require('@/components/Atom/Buttons/ButtonsFilters').default,
        'slideUpAndDown' : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
    },
    data(){
        return{
            loadingBtn:false,
            mdiTarget:mdiTarget,
            dadosRelatorios:[],
            mdiCarConnected:mdiCarConnected,
            loadingPanel: false,
            loadingButton:[false, false, false],
            labelsSelectEmpresa:[
                {
                    indexDFH: 'E', description: 'Empresas*'
                },
            ],
            optionsSelectEmpresa: new EmpresasService().Get(),
            labelsSelectVeiculos:[
                {
                    indexDFH: 'V', description: 'Veiculos*'
                },
                {
                    indexDFH: 'GV',
                    description: 'Grupos*'
                },
                {
                    indexDFH: 'TAG',
                    description: 'Tags*'
                }
            ],
            listaVeiculos: [],
            tipoVeiculos: 'V',
            statusbar: 'info',
            optionsSelectVeiculos: [],
            empresasArray:[],
            veiculosArray:[],
            url: '/finder/plus/relatorios/acionamento/sensores',
            veiculosSelect: [] ,
            portaSelecionada: '' ,
            dadosAcionamentoPortas:[] ,
            acionamentoForaDePonto:false,
            dataSelecionada:'',
            tamanhoArrayAcionamentoPortas: 0,
            htmlAcionamentoPortas:'',
            arrayButtons:[
                {
                    'value':'13,14',
                    'title':'Porta 1',
                    'width': '23.3%',
                    'text': '1',
                    'isSelected':true
                },
                {
                    'value':'15,16',
                    'title':'Porta 2',
                    'width': '23.3%',
                    'text': '2',
                    'isSelected':false
                },
                {
                    'value':'17,18',
                    'title':'Porta 3',
                    'width': '23.3%',
                    'text': '3',
                    'isSelected':false
                },
                {
                    'value':'19,20',
                    'title':'Porta 4',
                    'width': '23.3%',
                    'text': '4',
                    'isSelected':false
                },
            ],
            selectedEmpresa: [],
        }
    },
    methods:{
    ...mapGetters(['getMaster']),

        clickLinkMapa(cord){
            window.open('http://maps.google.com/maps?q=loc:'+cord)
        },

        SelectEmpresas(event){
            this.empresasArray = event;
            this.veiculosSelect.clearAll();
            this.getVeiculos();
            this.limpaRelatorio()
        },

        SelectVeiculos(event){
            this.veiculosArray = event;
            this.limpaRelatorio()
        },

        selectCriado(select){
            this.veiculosSelect = select;
            this.limpaRelatorio()
        },

        clickBotao(event){
            this.portaSelecionada = event;
            this.limpaRelatorio()
        },

        changeData(event){
            this.dataSelecionada = event;
            this.limpaRelatorio()
        },

        async getVeiculos(){
            let value = this.empresasArray 
            this.loadingBtn = false
            new FiltrosService()
                .dados_filtros(value, ['V', 'GV', 'TAG'])
                .then((dados) => {
                    this.listaVeiculos = dados
                    this.optionsSelectVeiculos= this.listaVeiculos[this.tipoVeiculos]
                })

        },

        checaBotao(value) {
            this.tipoVeiculos = value
            if (this.listaVeiculos[this.tipoVeiculos])
                this.optVeiculos = this.listaVeiculos[this.tipoVeiculos];
            this.getVeiculos(this.valueEmpresa)
        },
        
        async gerarRelatorio(){
            this.loadingPanel = true
            let obj = {
                'clientes': this.empresasArray,
                'veiculos': this.veiculosArray,
                'porta': this.portaSelecionada,
                'data': this.dataSelecionada,
                'acionamentoForaDePonto': this.acionamentoForaDePonto,
                'tipoVeiculo': this.tipoVeiculos
            }
            this.dadosAcionamentoPortas = [];
            this.statusbar = 'info';
            let data = await new HttpRequest()
                .Post('/telemetria/relatorios/acionamento/portas/gerar', obj);
            let dados = data.data;
            if(data.code == 200){
                this.tamanhoArrayAcionamentoPortas = dados.portas.length
                this.dadosAcionamentoPortas = dados.portas
                this.dadosRelatorios = dados.portas
                if(dados.portas.length == 0){
                    this.statusbar = 'error'
                }
            }else{
                this.dadosAcionamentoPortas = [];
                this.statusbar = 'error2'
            }
            this.loadingPanel = false
        },

        limpaRelatorio() {
            this.statusbar = 'info';
            this.dadosAcionamentoPortas = [];
            this.tamanhoArrayAcionamentoPortas = 0;
            this.dadosRelatorios = []
        },

        /**
         * @listens td - coluna
         * @description Método para calcular o tempo total de acionamento de portas
         *  soma em segundos todos os acionamentos e transforma para horas com o Duration
         * @param {object} dados = objetos coms os dados de tempode acionamento
         * @return calculo do tempo formatado em horas
         * @author Vitor Hugo 🐨
         */
        calculaTempoTotalAcionamento(dados){
            var tempoTotal = 0
            dados.forEach(element => {
                tempoTotal += element.tempoSegundos
            });
            return Duration.fromObject({seconds:tempoTotal}).toFormat("hh:mm:ss")
        },

        async exportarRelatorio(tipo){
            switch (tipo) {
                case 'pdf':
                    this.loadingButton = [true, false, false]
                    break;
                case 'xls':
                    this.loadingButton = [false, true, false]
                    break;

                default:
                    this.loadingButton = [false, false, true]
                    break;
            }
            var root =  process.env.VUE_APP_ROOT;
            let url = '/telemetria/relatorios/acionamento/portas/exportar'
            let obj = {
                'arrayDados': JSON.stringify(this.dadosAcionamentoPortas),
                'tipo': tipo
            }
            new HttpRequest().Post(url, obj)
                .then((data) => {
                    window.open(root+'/'+data.data.local);
                })
                .catch((error) => {
                    conectionError('Ocorreu erro na exportação')
                })
                .finally(() => {
                    this.loadingButton = [false, false, false]
                })

        }
    },

    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optionsSelectEmpresa
        }
    }
})
</script>

<style lang="scss">
#acionamentoSensores {
    .blueIconLocalizar {
        color: #428bca;
        cursor: pointer;
    }
    .tdDaLocalizacao{
        a {
            .cor-azul{
                color: #428bca;
                text-align: right;
            }
        }
    }
    .paddingBot{
        padding-bottom: 10px!important;
    }
    .calculaTempo{
        font-weight: bold;
    }
}
</style>
