<!-- 
	Cada um dos marcadors, polilinhas e poligonos que são inseridos no mapa usando 
	JS, possuem um atributo específico, booleano, e que é usado para
	controle dessas variáveis, para saber o que excluir/deixar na tela de acordo com
	as interações do usuário.
	Coisas que são resultados de busca possuem a flag
			'busca' => true,
	Pontos e polilines de rotas possuem a flag
			'rota' => true.
	poligono de merdir área possue a flag
			'medirArea' => true.
	Rastro do veículo
			'rastroVeiculo' => true
	Pontos novos:
			'pontoVobo' => true
	Regioes do usuario:
			'regiaoUsuario' => true 
-->

<template>
    <eaglePanel id="mapaEagleFinder">
        <div class="centralizaTool" v-if="atividade != '' && atividade != 'buscar'">
            <div class="facilitadorCadastrosMapa d-flex justify-content-center">
                <span class="cadastro-ponto" v-if="atividade === 'cadastro-ponto'">
                    Clique no mapa para cadastrar um ponto
                </span>
                <span v-if="atividade == 'historico-posicoes'">
                    Selecione no mapa a área onde deseja verificar o histórico de posições
                    dos veículos
                </span>
                <facilitador-nova-regiao ref="facilitadorRegiao" v-if="atividade === 'cadastro-regiao'"
                    :pontos="coordenadasFree" :arrayEmpresas="optClientes" @regiao-editada="atualizarRegiao"
                    @cancela-regiao="cancelaRegiao(false)" @regiao-salva="regiaoSalva" />
                <div class="medir-area d-flex justify-content-around" v-if="atividade === 'medir-area'">
                    <span class="mt-2">Medir área</span>
                    <div class="d-flex">
                        <b-button variant="danger" title="Cancelar" @click="cancelaMedirArea">
                            <base-icon :size="18" :icon="iconClose" />
                        </b-button>
                        <b-button variant="info" class="ml-1" title="Medir" @click="fazerMedirArea">
                            <base-icon :icon="iconCheck" :size="18" />
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
        <info-veiculo v-show="veiculoSelecionado" ref="infoVeiculo" :optVeiculos="optVeiculos" :seguindo="seguindoVeiculo"
            :permissoes="userPermission" :voltarViagem="voltarViagem" :abriuViagem="visualizarViagens" 
            :telemetry-interval="telemetry.interval" @change-interval="telemetry.setIntervalFromDates"
            @listar-posicoes="listarPosicoesVeiculo" @visualiza-viagens="visualizaViagens"
            @seguir-veiculo="seguirVeiculo" @show-info-veiculo="showInfoVeiculo" @rastro-veiculo="mostrarRastroVeiculo"
            @limpa-rastro-veiculo="limpaRastroVeiculo" @rastro-comparar="compararRastros"
            @local-alerta="(v) => mostrarLocal(v, 20, true)" @excesso-velocidade="mostraExcessoVelocidade"
            @limpa-excesso-velocidade="limpaExcessoVelocidade" @paradas-veiculo="paradasVeiculo"
            @limpa-paradas-veiculo="limpaParadas" @remove-comparacao="limpaComparacao" @ac-portas="acionamentoDePortas"
            @limpa-ac-portas="limpaAcionamentoDePortas" @eventos-veiculo="eventosVeiculo"
            @limpa-eventos="limpaEventosVeiculo" @fecha-info="fechaJanela" @limpa-rastro="limpaRastroVeiculo"
            @limpa-parada="limpaParadas"/>
        <historico-de-posicoes v-show="listarPosicoes" class="ml-1" ref="histPosicoes"
            @fecha-posicoes="fechaPosicoesVeiculo" @local-posicao="posicaoHistorico" />
        <div class="d-flex justify-content-center">
            <div>
                <visualizar-viagens v-show="visualizarViagens" class="ml-1 visualizar-viagens" ref="visualizaViagens"
                    @fecha-posicoes="fechaPosicoesVeiculo" @rastro-viagem="mostrarRastroVeiculo" @voltar-viagem="voltaViagem"
                    :pontoInicial="pontoInicial" :pontoFinal="pontoFinal" 
                    @criar-trajeto="criarTrajeto" :exibeInfoVeiculo="infoVeiculoExibir"
                    @paradas-veiculo-viagem="paradasVeiculo" @excesso-velocidade="mostraExcessoVelocidade"
                    @limpa-rastro-viagem="limpaRastroVeiculo" @limpa-paradas-viagem="limpaParadas" @limpa-excesso-velocidade="limpaExcessoVelocidade"
                    @dados-grafico="dadosMontaGrafico" @dados-popula-grafico-velocidade="dadosPopulaGraficoVelocidades"
                    @pontos-inicial-final-viagem="exibirPontoInicialFinalViagem" @requisicao-viagem="monitoraRequisicoesViagem">
                </visualizar-viagens>
            </div>

        </div>

        <menu-mapa :permissoes="userPermission" ref="menuMapa" :legendaGrupo="legendaGrupoVeiculos"
            :legendaRastro="legendaRastros" classeMenu="menuMapa" :atividade="atividade" :qtdPontos="qtdPontosOnMapa"
            @mostrar-legendas="controleExibirLegendas" @cadastrar-regiao="cadastrarRegiao" @cadastrar-ponto="cadastrarPonto"
            @mostrarLegendas="(v) => (ocultaLegenda = v)" @buscar="buscaCliente" @pontos-coleta="(b) => mudaPontos('C', b)"
            @pontos-referencia="(b) => mudaPontos('P', b)" @pontos-entrega="(b) => mudaPontos('E', b)"
            @regioes="regioesUsuario" @medir-area="medirArea" @historico-posicoes="historicoPosicoes" />

        <div v-if="atividade == 'buscar'" class="search-field">
            <div>
                <input placeholder="Buscar..." ref="campoBusca" class="form-search" @keyup.enter="buscaRegiao"
                    @keyup.esc="limpaBuscaRegiao(true)" v-model="queryRegiaoUser" />
                <b-button :disabled="searchRegiao || !queryRegiaoUser.length" squared @click="buscaRegiao"
                    class="search-button ml-1" variant="info">
                    <b-spinner v-if="searchRegiao" label="Spinning" />
                    <base-icon v-else :size="25" :icon="iconMapSearch" />
                </b-button>
            </div>
            <div class="search-result">
                <ul class="search-list">
                    <!-- Regiões do usuário -->
                    <li v-for="reg in regioesBuscaCliente" :key="reg.recodigo + '_regiao_search'" class="search-item"
                        :title="reg.redescricao" v-text="reg.redescricao" event="click"
                        @click="selecionaRegiaoCliente(reg)" />
                    <!-- Pontos do usuário -->
                    <li v-for="ponto in pontosBuscaCliente" :key="ponto.pocodigo + '_ponto_search'" class="search-item"
                        v-text="ponto.podescricao" :title="ponto.podescricao" event="click"
                        @click="selecionaPontoCliente(ponto)" />
                    <!-- Regiões do OSMR -->
                    <li v-for="(reg, index) in regioesBusca" :key="index + '_regiao_busca'" class="search-item"
                        :title="reg.endereco" v-text="reg.endereco" event="click" @click="selecionaRegiaoBusca(reg)" />
                </ul>
            </div>
        </div>
        <mapa-simples :height="false" posicaoControles="topleft" ref="mapaPrincipal" :percentHeight="true" id="mapaFinder"
            :class="ocultaLegenda ? 'sem-legenda' : 'com-legenda'" @click.right="googleStreet" @click-mapa="novoPonto"
            @update-zoom="updateZoom">

            <free-draw v-show="atividade === 'historico-posicoes' ||
                atividade === 'medir-area' ||
                atividade === 'cadastro-regiao'
                " ref="freeDraw" :regiao="regiaoEditavel" :mostrarControles="mostraControleFree"
                @desenho="emiteDesenho" />
            <!-- @editando='emiteDesenho' -->
            <!--Marcadores de ponto-->
            <cluster :chunkInterval="500" :chunkDelay="500" ref="mapCluster" id="popWrapper" />
            <!-- :chunkInterval="500"
            :chunkDelay="500" -->
            <ClusterVeiculos :disableClusterZoom="22" :maxClusterRadius="50" ref="mapVeiculos" id="popWrapper2">
            </ClusterVeiculos>
            <!-- <marker-point
                v-for="(marker, i) in markerVeiculosFiltrados"
                ref="marker"
                :key="marker.veiculoId + '_marker_point' + i"
                typeIcon="divIcon"
                v-bind="marker"
                @click="selecionaVeiculo(marker)"
            >
            </marker-point> -->
            <marker-point v-if="veiculoComparacao" typeIcon="divIcon" v-bind="veiculoComparacao"
                @click="selecionaVeiculo(veiculoComparacao)" />
        </mapa-simples>
        <div class="fixed-bottom">
            <div v-show="visualizarViagens">
                <graficoViagem v-if="grafico" id='graficoVeiculo' title='graficoVeiculo' :data="[]" :voltarViagem="voltarViagem" altura="150"
                    :dadosMontasGrafico='dadosGrafico' :dadosPopulaGraficoVelocidade='dadosPopulaGraficoVelocidade'>
                </graficoViagem>
            </div>
            <div class="divPainelMapa">
                <painel-mapa ref="painelMapa" :permissoes="userPermission" :veiculoSelecionado="placaVeiculoSelecionado"
                    :clienteSelecionado="clienteSelecionado" :abriuViagem="visualizarViagens" :chamarRequisicaoDadosRota="chamaRequisicaoRota"
                    :info-veiculo-visivel="infoVeiculoExibir"
                    @remove-rastros-rotas="RemoveRastrosRotas" @rem-all-markers="removeAllVeiculos"
                    @desvio-rota-coletivo="desvioColetivo" @rastro-veiculo-coletivo="rastroColetivo"
                    @remove-todos-coletivos="removeTodosColetivos" @remove-linha-coletivo="rmvLinhaColetivo"
                    @linha-coletivo="linhaColetivo" @att-marcador-rota="atualizaMarcadoRota"
                    @rastro-carregado="desenharRastroDeGrupo" @limpar-rastros-gv="limparRastrosGrupo"
                    @limpa-alertas-rotas="limpaAlertasRotas" @limpa-alertas-rotas-id="removerAlertaPorId"
                    @allVeiculos="allVeiculos" @addMarker="addMarker" @remMarker="remMarker" @desenhar-rota="desenharRota"
                    @limpa-rotas="removeTodasRotas" @remove-rota="removerRota" @localizaVeiculo="localizaVeiculo"
                    @update-veiculo="updateMarcador" @muda-cliente="mudaCliente" @fecha-info="fechaJanela"
                    @rastros-rotas="rastrosRota"  @alertas-rotas="alertasVeiculo" @exibe-alertas-lidos="exibirAlertasRotasLidos"
                    @paradas-veiculo-rota="paradasVeiculo"  @limpa-paradas-rotas="limpaParadas" @limpa-paradas-id="limpaParadasPorId"
                    @excesso-velocidade="mostraExcessoVelocidade" 
                    @limpa-excesso-velocidade-id="limpaExcessoVelocidadePorId" @limpa-excesso-velocidade="limpaExcessoVelocidade" 
                    @show-location="(latlog) => mostrarLocal(latlog, 15, true)"
                />
            </div>
        </div>
        <modal-hist-posicao-regiao @fecha-hist-pos-regiao="fechaHist" :clienteSelecionado="clienteSelecionado"
            @abre-info-veiculo="abreInfoVeiculo"
            ref="modalHistPosicao" />
        <div v-if="construirAlertasVeiculos">
            <PopoverAlertas :markerVeiculos="markerVeiculos" />
        </div>
        <!-- <div v-else> -->
        <PopoverMarcador :marcadorSelecionado="marcadorSelecionado" />
        <!-- </div> -->
    </eaglePanel>
</template>

<script>
const TEMPO_CONSTRUIR_ALERTAS_VEICULOS = 1000;

import Vue from "vue";
import L from "leaflet";
import PopupRegiao from "@/components/Atom/Popup/PopupRegiaoMapa.vue";
import PopUpPonto from "@/components/Atom/Painel/facilitadorPonto.vue";
import PopParada from "@/components/Atom/Painel/ParadaInfo.vue";
import {IconManager} from "./Alertas.ts";
import NProgress from "nprogress";
import { HttpRequest } from "@/Services/auth/HttpRequest.Service";
import { EagleMarker } from "./helpers/marker.js";
import { conectionError } from "@/Services/Helpers/swellHeper";
import { FiltrosService } from "@/Services/filtros/filtros.Service";
import { EmpresasService } from "@/Services/auth/Empresas.service";
import { antPath } from "leaflet-ant-path";
import { components, data } from "./Mapa.ts";
import {
    criarMarkerRastroGrupo,
    criarMarkerIORota,
    defineComoRota,
    updateMaker,
    criaMarcadorAlerta,
    criarPolilyneHistorico,
    criaMarcadorPadrao,
    defineObjetoComoBusca,
    criarMarkerCentroRegiao,
    criarRegiaoCliente,
    criarInicioRastroVeiculo,
    criaMarcadorExcessoVelocidade,
    criarMarcadorParada,
    criarMarcadorAlerta,
    definePropriedadesRegiao,
    criarMarkersAcionamentoPorta,
    criarMarkerColetivo,
    defineParteColetivo,
    criarPolylineColetivo,
    criaMarcadorEventoVeiculo,
    criarRastrosRota,
    criarPolylineRastroVeiculo,
    criarPolylineDesvio,
    criarPolylineRastroGrupo,
    definePropsMarcadorItemRota,
    definePropsMarcadorCarga,
    criarMarcadorItemRota,
    criarMarcadorCarga
} from "./helpers/helpers";
import "leaflet-measure";
import { useTelemetry } from "./composables/useTelemetry";

export default Vue.extend({
    name: "mapaFinder",
    components: components,

    data() {
        return data;
    },
    provide() {
        return {
            mapaRequisicaoViagem: this.mapaRequisicaoViagem
        };
    },

    methods: {
        /** 
         * @param {object} veiculo
         * @description chama e envia as informações para o infoVeiculo 
         * @author Otávio 🦆
         */
        abreInfoVeiculo(veiculo){
            if (this.userPermission?.mappainelinformacoes.ppvisualizar) {
                this.fechaHist();
                this.veiculoSelecionado = true;
                this.$refs.infoVeiculo.iniciaInfoVeiculoRegiao(veiculo);
                this.placaVeiculoSelecionado = veiculo.placa; 
                this.marcadorSelecionado.placa = veiculo.placa;
            }
        },
        
        /**
         * @listens requisicao-viagem
         * @param {Boolean} requisicao
         * @description atualiza quando é iniciado ou finalizada uma requisição
         * em viagens.
         */
        monitoraRequisicoesViagem(requisicao){
            this.mapaRequisicaoViagem.fechaViagem = requisicao;
        },

        updateZoom(zoom) {
            this.construirAlertasVeiculos = false;
            if (this.tickerTimerZoom) {
                clearInterval(this.tickerTimerZoom);
                this.tickerTimerZoom = null;
            }
            this.tickerTimerZoom = setInterval(() => {
                this.construirAlertasVeiculos = this.markerVeiculos.length > 0;
            }, TEMPO_CONSTRUIR_ALERTAS_VEICULOS);
        },

        RemoveRastrosRotas() {
            let mapa = this.$refs.mapaPrincipal.returnMapObject();
            mapa.eachLayer((ly) => {
                if (ly?.rastro_rota) ly.remove();
            });
        },

        /** */
        rastrosRota(rastros) {
            let info = criarRastrosRota(rastros);
            let mapa = this.$refs.mapaPrincipal.returnMapObject();
            info.forEach((v) => {
                mapa.addLayer(v);
            });
        },

        /**
        * @description recebe os dados inicias emitido pela viagem com os
        * dados inciais do gráfico
        * @author Otávio 🦆 
        */
        dadosMontaGrafico(dados) {
            this.grafico = true;
            this.dadosGrafico = dados;
        },

        /**
        * @description recebe os dados de velocidade emitido pelo componente
        * Viagens, para popular o gráfico
        * @author Otávio 🦆 
        */
        dadosPopulaGraficoVelocidades(dados) {
            this.dadosPopulaGraficoVelocidade = dados;
        },

        removeAllVeiculos() {
            this.limparClusterVeiculos();
            // this.markerVeiculos = [];
        },

        /**
         * @description cria um marcador p/ cada evento
         * na array, cada um com sua popop e tals.
         * @param { object[] } eventos
         * @param { string } eventos[].endereco
         * @param { string } eventos[].data dd/mm/yyyy
         * @param { string } eventos[].hora hh:mm:ss
         * @param { string } eventos[].lat latitude
         * @param { string } eventos[].lng longitude
         * @param { string? } eventos[].mtnome
         * @param { number } eventos[].bicodigo
         * @param { number } tipo
         * @author Gui
         */
        eventosVeiculo(eventos, tipo) {
            var popup =
                require("@/components/Atom/Painel/Especificos/PopupEventos.vue").default;
            var ponto_popup = Vue.extend(popup);
            this.limpaMarcadoresDeEventos();
            eventos.forEach((ev) => {
                ev.mtnome = ev.mtnome ?? "";
                var marcador = criaMarcadorEventoVeiculo(tipo, ev, ponto_popup);
                this.pontosOnMapa.push(marcador);
                var mapa = this.$refs.mapaPrincipal.returnMapObject();
                mapa.addLayer(marcador);
            });
        },

        limpaEventosVeiculo() {
            this.pontosOnMapa.forEach((pm) => {
                if (pm.evento) pm.remove();
            });
            if (this.$refs.histPosicoes) this.$refs.histPosicoes.fechaPosicoesVeiculo();
        },

        limpaMarcadoresDeEventos() {
            var mapa = this.$refs.mapaPrincipal.returnMapObject();
            this.pontosOnMapa.forEach((m) => {
                if (m?.evento) mapa.removeLayer(m);
            });
        },

        /**
         * @listens click.rigth - click do usuário no mapa
         * @param {object} options.latlng
         * @param {number} options.latlng.lat
         * @param {number} options.latlng.lng
         * @description Quando o usuário clica com o botão direito do mouse
         * no mapa, leva ele pro google Street view naquelas coordenas
         */
        googleStreet({ latlng }) {
            window.open(
                `https://www.google.com/maps/@${latlng.lat},${latlng.lng},3a,75y,90t/data=!3m3!1e1!3m1!2e0`
            );
        },

        /**
         * @param {object} marker
         * @param {string} marker.placa
         * @description decide se cada marcador deve aparecer ou não no mapa.
         * Caso uma ou mais rotas estiverem desenhadas no mapa, apenas os
         * veículos que pertecem a ela(s) vão aparecer.
         * @return {void}
         */
        mostrarVeiculo(marker) {
            if (this.veiculosRota.length) return this.carroEstaNaRota(marker.placa);
            return !this.veiculoSelecionado && this.verveiculos;
        },

        /**
         * @param {string} placa - do veículo a ser testado
         * @description compara os veículos na array de marcadores com os
         * que pertemcem a alguma das rotas selecionadas.
         * @returns {boolean} se o veículo esta em alguma rota selecionada.
         * @author Gui 🍺
         */
        carroEstaNaRota(placa) {
            var existe = this.veiculosRota.find((el) => {
                return el.veplaca === placa;
            });
            if (typeof existe === "undefined") return false;
            return true;
        },
        // COLETIVOS

        /**
         * @listens desvio-rota-coletivo
         * @param {object[]} desvios
         * @param {object[]} desvios[].latlng
         * @param {number}   desvios[].latlng.codigo
         * @param {number[]} desvios[].latlng.latlng
         * @param {number}   desvios[].codigo
         * @description cria os marcadores para os desvios de rota de uma
         * linha (coletivos), adiciona a poli na variáel de controle
         * e define ela como parte de uma linha.
         * @return {void}
         * @author Gui 🍺
         */
        desvioColetivo(desvios) {
            var mapa = this.$refs.mapaPrincipal.returnMapObject();
            var popup =
                require("@/components/Atom/Popup/PopupDesvioColetivo.vue").default;
            popup = Vue.extend(popup);
            desvios.latlng.forEach((d) => {
                let poli = criarPolylineDesvio(d, desvios.codigo, popup);
                mapa.addLayer(poli);
                this.polisOnMapa.push(poli);
            });
        },

        /**
         * @param {object}    rastro
         * @param {string}    rastro.cor
         * @param {number[]}  rastro.latlng
         * @param {number}    rastro.codigo
         * @description cria a anthpath do rastro do
         * veículo ao seguir uma linha (coletivos).
         * @return {void}
         */
        rastroColetivo(rastro) {
            var poli = antPath(rastro.latlng, {
                color: rastro.cor,
                interactive: false,
                delay: 2000,
                weight: 4,
            });
            var mapa = this.$refs.mapaPrincipal.returnMapObject();
            poli = defineParteColetivo(poli, rastro.codigo);
            this.polisOnMapa.push(poli);
            mapa.addLayer(poli);
        },

        /**
         * @description remove todos os rastros e pontos que tenham
         * o atributo rotaColetivo.
         * @return {void}
         */
        removeTodosColetivos() {
            this.polisOnMapa.forEach((poli) => {
                if (poli.rotaColetivo) poli.remove();
            });
            this.polisOnMapa = this.polisOnMapa.filter((p) => {
                return !p.rotaColetivo;
            });
            this.pontosOnMapa.forEach((p) => {
                if (p.rotaColetivo) p.remove();
            });
            this.pontosOnMapa = this.pontosOnMapa.filter((p) => {
                return !p.rotaColetivo;
            });
        },

        /**
         * @listens remove-linha-coletivo
         * @param {number} codigo - da rota a ser removida
         * @description remove uma rota do mapa baseado no
         * código passado como paramêtro.
         * @return {void}
         */
        rmvLinhaColetivo(codigo) {
            this.polisOnMapa.forEach((poli) => {
                if (poli.rccodigo === codigo) poli.remove();
            });
            this.pontosOnMapa.forEach((m) => {
                if (m.rotaColetivo) if (m.rccodigo === codigo) m.remove();
            });
            this.pontosOnMapa = this.pontosOnMapa.filter((e) => {
                return !(e.rccodigo === codigo);
            });
        },

        /**
         * @listens linha-coletivo
         * @param {object} rota
         * @param {string} rota.poli
         * @param {number} rota.rccodigohorario
         * @param {number} rota.rccodigo
         * @param {array}  rota.itens
         * @description adiciona uma poli no mapa e na variável de
         * controle, já identificando a poli como:
         * ->rotaColetivo - boolean
         * ->rcccodigohorario - number, param
         * ->rccodigo - number, param
         * Para poder remover ela baseado em diversas situações.
         * @return {void}
         * @author Gui 🍺
         */
        linhaColetivo(rota) {
            var mapa = this.$refs.mapaPrincipal.returnMapObject();
            if (rota.poli) {
                let polylines = criarPolylineColetivo(rota);
                polylines.p1.addTo(mapa);
                polylines.p2.addTo(mapa);
                mapa.flyToBounds(polylines.p1.getBounds());
                this.polisOnMapa.push(polylines.p1, polylines.p2);
            }
            var popup = require("@/components/Atom/Popup/PopupPontoColetivo.vue").default;
            popup = Vue.extend(popup);
            rota.itens.forEach((item) => {
                let marker = criarMarkerColetivo(item, popup);
                marker.addTo(mapa);
                marker = defineParteColetivo(marker, item.rccodigo);
                this.pontosOnMapa.push(marker);
            });
        },
        // FIM_COLETIVOS

        /**
         * @description remove todos os marcadores que contenham o campo
         * acPorta
         */
        limpaAcionamentoDePortas() {
            this.pontosOnMapa.forEach((p) => {
                if (p.acPorta) p.remove();
            });
            this.pontosOnMapa = this.pontosOnMapa.filter((p) => {
                return !p.acPorta;
            });
        },

        /**
         * @listens ac-portas - emitido pelo info-veiculo.
         * @param {object[]} eventos - lista de todos os eventos de acionamento
         * de portas.
         * @description cria o marcador de acionamento de porta.
         * @return {void}
         * @author Gui 🍺
         */
        acionamentoDePortas(eventos) {
            this.limpaAcionamentoDePortas();
            let markers = criarMarkersAcionamentoPorta(eventos);
            var mapa = this.$refs.mapaPrincipal.returnMapObject();
            markers.forEach((m) => {
                Object.defineProperty(m, "acPorta", {
                    value: true,
                    writable: false,
                    enumerable: true,
                    configurable: false,
                });
                m.addTo(mapa);
                this.pontosOnMapa.push(m);
            });
        },

        /**
         * @listens update-veiculo
         * @param {number} veiculoId - identificador único do veículo.
         * @param {object}  info      - informações novas do veículo.
         * @param {objec[]} info.alertas
         * @param {number}  info.clcodigo
         * @param {string}  info.gvcor
         * @param {string}  info.icon - html do ícone
         * @param {number}  info.latitude
         * @param {number}  info.longitude
         * @param {string}  info.placa
         * @param {number}  info.veiculoId
         * @description atualiza as informações de um veículo de acordo
         * com o resultado de uma nova consulta.
         * @return {void}
         * @author Gui 🍺
         */
        updateMarcador(veiculo) {
            this.pontoInicial = {
                'lat': veiculo.latitude,
                'lng': veiculo.longitude 
            };
            var veiculoAtual = this.markerVeiculos.find((el) => {
                return el.veiculoId == veiculo.veiculoId;
            });
            if (typeof veiculoAtual === "undefined") return;
            veiculoAtual.atualizarDados(veiculo);
            this.marcadorSelecionado = { ...veiculoAtual };
        },
        // updateMarcador(veiculoId, info) {
        //     var veiculo = this.markerVeiculos.find((el) => {
        //         return el.veiculoId === veiculoId;
        //     });
        //     if (typeof veiculo === "undefined") return;
        //     var keys = Object.keys(veiculo);
        //     keys.forEach((k) => {
        //         veiculo[k] = info[k];
        //     });
        //     if (veiculoId === this.marcadorSelecionado.veiculoId) {
        //         keys.forEach((k) => {
        //             this.marcadorSelecionado[k] = info[k];
        //         });
        //     }
        // },

        /**
         * @listens mostrar-legendas - icone com o olho no menu do mapa.
         * @param {boolean} hidden   - se deve mostrar as legendas ou não.
         * @return {void}
         */
        controleExibirLegendas(hidden) {
            this.ocultaLegenda = hidden;
        },

        voltaViagem(dados){
            this.voltarViagem = dados;
        },

        /**
         * @listens click  - botão de medir.
         * @description cria o poligono com as medidas de área, tanto total.
         * quanto distância entre cada uma das arestas.
         * O poligono vem do freeDraw, e é iniciado lá mesmo.
         * @return {void}
         * @author Gui 🍺
         */
        fazerMedirArea() {
            if (this.$refs.freeDraw) {
                var poli = this.$refs.freeDraw.medeDesenho();
                if (!poli) return;
            }
            var map = this.$refs.mapaPrincipal.returnMapObject();
            map.addLayer(poli);
            poli.enableEdit();
            map.on(
                "editable:vertex:drag editable:vertex:deleted",
                poli.updateMeasurements,
                poli
            );
            Object.defineProperty(poli, "medirArea", {
                value: true,
                writable: false,
                enumerable: true,
                configurable: false,
            });
            poli.on(
                "dblclick",
                function () {
                    this.remove();
                }.bind(poli)
            );
            this.regsOnMap.push(poli);
        },

        /**
         * @listens click - botão de cancelar medir área.
         * @description limpa o mapa dos poligonos que o usuário esteja
         * visualizando.
         * @return {void}
         */
        cancelaMedirArea() {
            this.excluirRegiao(0);
            if (this.$refs.freeDraw) {
                this.$refs.freeDraw.deletarDesenho();
                this.$refs.freeDraw.paraDesenhoSemEvento();
            }
            this.atividade = "";
            this.regsOnMap.forEach((r) => {
                if (r.medirArea) r.remove();
            });
        },

        /**
         * @listens fecha-hist-pos-regiao
         */
        fechaHist() {
            this.excluirRegiao(0);
            if (this.$refs.freeDraw) {
                this.$refs.freeDraw.deletarDesenho();
            }
            this.atividade = "";
        },

        /**
         * @param {[][]} arrCorrd - emitido pelo freeDraw,
         * melhor sempre usar diretamente de lá.
         * @description abre a modal de posições do mapa. Onde o usuário
         * pode desenhar uma região no mapa e ver que veículos passaram por lá.
         * @return {void}
         */
        modalHistoricoPosicoes(arrCoord) {
            if (this.atividade === "historico-posicoes") {
                this.$refs.modalHistPosicao.showModal(arrCoord[0]);
            }
        },

        /**
         * @listens regioes      - componente de menu do mapa
         * @param {boolean} bool - se deve procurar as
         * regiões ou limpar elas do mapa.
         * @return {void}
         * @author Gui 🍺
         */
        regioesUsuario(bool = false) {
            this.controleVerRegioes = bool;
            let mapa = this.$refs.mapaPrincipal.returnMapObject();
            mapa.eachLayer((l) => {
                if (l?.regiaoUsuario) l.remove();
            });
            if (!this.clienteSelecionado.length) return;
            if (bool) {
                var uri = `${this.baseUri}carregar/regioes`;
                let obj = {
                    clientes: this.clienteSelecionado.map((c) => {
                        return c.value;
                    }),
                };
                this.$refs.menuMapa.loadingPontos("a");
                new HttpRequest()
                    .Post(uri, obj)
                    .then((data) => {
                        if (data.status) {
                            var regioes = data.data?.regioes;
                            regioes = regioes.map(
                                function (reg) {
                                    var coord = reg.regioes_coordenadas.map((c) => {
                                        return [c.rclatitude, c.rclongitude];
                                    });
                                    var obj = {
                                        ...reg,
                                        coord,
                                    };
                                    delete obj.regioes_coordenadas;
                                    return obj;
                                }.bind(this)
                            );
                            this.criaRegioesUsuario(regioes);
                        }
                    })
                    .finally(() => {
                        this.$refs.menuMapa.endLoadingPontos();
                    });
            }
        },

        /**
         * @listens click - menu, hexagono de histórico de posições.
         * @description se a atividade já for o hist., a função cancela
         * a atividade, caso não for, inicia o hist.
         * @author Gui 🍺
         */
        historicoPosicoes() {
            if (this.atividade === "historico-posicoes") {
                if (this.$refs.freeDraw) {
                    this.$refs.freeDraw.deletarDesenho();
                    this.$refs.freeDraw.paraDesenhoSemEvento();
                }
                this.atividade = "";
            } else {
                this.atividade = "historico-posicoes";
                this.mostraControleFree = false;
                this.$nextTick(() => {
                    if (this.$refs.freeDraw) this.$refs.freeDraw.continuaDesenho();
                });
            }
        },

        /**
         * @description remove as regiões do usuário do mapa, mantendo
         * as que são resultado de busca.
         */
        limpaRegioesUsuarioMapa() {
            let mapa = this.$refs.mapaPrincipal.returnMapObject();
            mapa.eachLayer((l) => {
                if (l?.regiaoUsuario) l.remove();
            });
        },

        /**
         * @param {object[]} regioes - lista com todas as
         * regiões a serem criadas.
         * @description cria as regiões uma por uma,
         * adiciona no mapa e depois na array de controle.
         */
        async criaRegioesUsuario(regioes) {
            for (let r of regioes) {
                await this.montaRegiao(r);
            }
        },

        /**
         * @param {object} regiao
         * @param {string} regiao.recor
         * @param {number} regiao.recodigo
         * @description cria uma região (do usuário), definindo
         * propriedades de cada uma delas e adiciona ela no
         * mapa e na variavel de controle (regsOnMap)
         * @return {void}
         * @author Gui 🍺
         */
        async montaRegiao(regiao) {
            var reg = await L.polygon(regiao.coord, {
                color: regiao.recor,
                interactive: true,
            });
            this.addToMapa(reg);
            reg = await definePropriedadesRegiao(regiao, reg);
            await this.ligaPopUpRegiao(regiao, reg);
            await this.regsOnMap.push(reg);
        },

        /**
         * @param {object}    regiao
         * @param {number}    regiao.recodigo
         * @param {string}    regiao.redescricao
         * @param {number}    regiao.revelocidade
         * @param {number}    regiao.rearea
         * @param {('S'|'N')} regiao.rerisco
         * @param {number}    regiao.recliente
         * @param {L.polygon} reg
         * @description liga a popup de região ao polygon e prepara os
         * eventos da região
         * @return {void}
         * @author Gui 🍺
         */
        ligaPopUpRegiao(regiao, reg) {
            var popup = Vue.extend(PopupRegiao);
            popup = new popup({
                propsData: {
                    regiao: regiao,
                    permissoes: this.userPermission.mapregiao,
                    empresas: this.optClientes,
                },
            });
            popup
                .$mount()
                .$on("editar-regiao", this.editarRegiaoUsuario)
                .$on("excluir-regiao", this.excluirRegiao);
            reg.bindPopup(popup.$el);
        },

        /**
         * @listens regiao-salva
         * @author Gui 🍺
         */
        regiaoSalva(regiao, pontos) {
            this.excluirRegiao(0);
            if (!this.controleVerRegioes) {
                this.regioesUsuario(false);
            }
            var existemRegsMapa = this.regsOnMap.some((e) => {
                return e.regiaoUsuario;
            });
            if (this.$refs.freeDraw) {
                this.$refs.freeDraw.deletarDesenho();
                this.$refs.freeDraw.paraDesenhoSemEvento();
            }
            this.atividade = "";
            if (existemRegsMapa && this.controleVerRegioes) {
                regiao.coord = pontos;
                this.montaRegiao(regiao);
            }
        },

        /**
         * @param {object} 	   regiao - dados novos da região
         * @param {string} 	   regiao.rearea
         * @param {number} 	   regiao.recodigo
         * @param {('S'|'N')}  regiao.rerisco
         * @param {boolean}    regiao.regeojson
         * @param {string} 	   regiao.recor
         * @param {string} 	   regiao.redescricao
         * @param {number[][]} pontos - novos pontos, [lat, lng]
         * @description atualiza as informações de exibição
         * @author Gui 🍺
         * de uma região do usuário
         */
        atualizarRegiao(regiao, pontos) {
            var regAtualizar = this.regsOnMap.find((r) => {
                return r.recodigo === regiao.recodigo;
            });
            regAtualizar.setLatLngs(pontos);
            regAtualizar.setStyle({
                color: regiao.recor,
            });
            regAtualizar.info = regiao;
            regAtualizar.info.coord = pontos;
            if (this.$refs.freeDraw) this.$refs.freeDraw.paraDesenhoSemEvento();
            regAtualizar.unbindPopup();
            this.ligaPopUpRegiao(regiao, regAtualizar);
            this.cancelaRegiao();
            this.atividade = "";
        },

        /**
         * @param {number} recodigo
         * @listens clickEditar no popup de região
         * @description envia os dados da região com recodigo
         * para o FreeDraw para a região poder ser editada.
         */
        editarRegiaoUsuario(recodigo) {
            var reg = this.regsOnMap.find((r) => {
                return r.recodigo === recodigo;
            });
            this.regsOnMap.forEach((r) => {
                r.remove();
            });
            this.atividade = "cadastro-regiao";
            this.$nextTick(() => {
                this.regiaoEditavel = reg.info.coord;
                this.$refs.facilitadorRegiao.editaRegiao(reg.info);
                this.$nextTick(() => {
                    if (this.$refs.freeDraw) this.$refs.freeDraw.continuaDesenho();
                });
            });
        },

        /**
         * @param {number} recodigo - código região
         * @description deleta a região do banco de dados, enviando
         * o código p/ o back-end e espera o retorno para confirmar que
         * a região foi de fato excluida.
         * @author Gui 🍺
         */
        excluirRegiao(recodigo) {
            var index = this.regsOnMap.findIndex((r) => {
                return r.recodigo === recodigo;
            });
            if (index < 0) return;
            this.regsOnMap[index].remove();
            this.regsOnMap.splice(index, 1);
        },

        /**
         * @listens medir-area
         * @param {boolean} bool
         * @description muda a atividade pra medir area.
         */
        medirArea(bool) {
            if (bool) {
                if (this.atividade === "cadastro-regiao") this.cancelaRegiao();
                if (this.atividade === "cadastro-ponto") this.cancelaNovoPonto();
                this.mostraControleFree = true;
                this.atividade = "medir-area";
                this.$nextTick(() => {
                    if (this.$refs.freeDraw) this.$refs.freeDraw.continuaDesenho();
                });
            } else {
                this.atividade = "";
                this.cancelaMedirArea();
            }
        },

        /**
         * @description Tanto cria quanto destroi os marcadores de
         * paradas do veículo,
         * os marcadores de paradas são guardados na array markerParadaOnMapa
         *(criativo af).
         */
         paradasVeiculo(dados) {
            let paradas, id;
            if (Array.isArray(dados)) {
                this.limpaParadas();
                paradas = dados;
                id = null;
            } else {
                paradas = dados.paradas;
                id = dados.id;
            }

            
            if (id && this.markerParadaOnMapa[id]) {
                this.markerParadaOnMapa[id].forEach(marker => marker.remove());
            }

            var markersParadas = paradas.map(parada => this.criaMarcadorParada(parada));
            var mapObject = this.$refs.mapaPrincipal.returnMapObject();

            markersParadas.forEach(marker => {
                if (marker && marker.marcador && typeof marker.marcador.addTo === 'function') {
                    marker.marcador.addTo(mapObject);
                }
            });
            const key = id || 'default';
            this.markerParadaOnMapa[key] = markersParadas;
        },


        /**
         * @description Cria o marcador que aparece no mapa representado
         * as paradas do veículo
         * @param {object}  	    parada
         * @param {(number|string)} parada.lat
         * @param {(number|string)} parada.lng
         * @return {L.Marker} marcador pronto para ser utilizado.
         */
        criaMarcadorParada(parada, id = null) {
            let marcador = criarMarcadorParada(parada);
            var popParadaV = PopParada;
            popParadaV = Vue.extend(popParadaV);
            var instance = new popParadaV({
                propsData: { parada: parada, cliente: this.clienteSelecionado },
            });
            instance.$mount().$on("novo-ponto", this.transformaParadaEmPonto);
            marcador.bindPopup(instance.$el);
            this.todasParadas.push(marcador);

            return {
                marcador: marcador,
                id: id 
            };
        },

        limpaParadas() {
                this.todasParadas.forEach(marcador => {
                marcador.remove(); 
            });
            this.todasParadas = [];
        },

        /**
         * @listens limpa-paradas-id
         * @description Função apaga o agrupamento de paradas por id individual 
         * isso facilita para apagar os marcadores de parada de forma mais seletiva
         * @param id 
         * @author Otávio 🦆 
         */
         limpaParadasPorId(id) {
            if (this.markerParadaOnMapa[id]) {
                this.markerParadaOnMapa[id].forEach(objetoMarcador => {
                    objetoMarcador.marcador.remove();
                });
                delete this.markerParadaOnMapa[id];
            }
        },

        /**
		 * @description Recebe os status do checkbox de 'Visualizar alertas lidos' para controlar se exibe alertas 
         * do tipo L
		 * @author Otávio 🦆 
		*/
        exibirAlertasRotasLidos(exibeAlertasLido){
            this.exibeAlertasRotasLidos = exibeAlertasLido;
            this.alertasVeiculo(this.alertasRotas)
        },

        /**
         * @listens alertas-rotas
         * @description recebe o objeto de alerta para montar os icones e 
         * descrição no mapa
         * @author Otávio 🦆 
         */
        alertasVeiculo(alertas) {
            this.alertasRotas = alertas;

            if (this.markerAlertaOnMapa) {
                this.markerAlertaOnMapa.forEach(marcador => marcador.remove());
            }
            const alertasFiltrados = this.alertasRotas.filter(alerta => 
                alerta.alstatus === 'L' ? this.exibeAlertasRotasLidos : (alerta.alstatus === 'P' || alerta.alstatus === 'M')
            );

            this.markerAlertaOnMapa = alertasFiltrados.map(alerta => {
                const iconeMdi = this.decideIcone(alerta.taicone);
                const marcador = criarMarcadorAlerta(
                    alerta,
                    iconeMdi,
                    this.removerAlertaPorId,
                    (valor) => {
                        this.chamaRequisicaoRota = valor;
                        setTimeout(() => {
                            this.chamaRequisicaoRota = false;
                            this.$forceUpdate();
                        }, 600);
                    });
                this.mapaDeMarcadores[`popupalertas${alerta.alcodigo}`] = marcador;
                return marcador;
            });
            var mapObject = this.$refs.mapaPrincipal.returnMapObject();
            
            this.markerAlertaOnMapa.forEach(marcador => marcador.addTo(mapObject));
        },

        /**
		 * @description Remove o alerta de acordo com o id do popup que recebe um alcodigo para torna-lo único, é disparado
         * quando o usuário confirma a leitura de um alerta
		 * @author Otávio 🦆 
		*/
        removerAlertaPorId(alcodigo) {
            const idPopup = `popupalertas${alcodigo}`;
            const marcador = this.mapaDeMarcadores[idPopup];
            if (marcador) {
                marcador.remove();
                delete this.mapaDeMarcadores[idPopup];
            }
        },

        /**
         * @listens limpa-alertas-rotas
         * @description remove os ícones de alerta do mapa
         * @author Otávio 🦆 
         */
        limpaAlertasRotas(){
            if (this.markerAlertaOnMapa) {
                this.markerAlertaOnMapa.forEach(marcador => marcador.remove());
            }
        },

        /**
         * Pega o codigo do
         * tipo de alerta e devolve um icone
         * @param {int} alerta
        */
        decideIcone(alerta) {
			return IconManager.getIconeAlerta(alerta)
        },

        /**
         * @description Evento que dispara quando o usuário quer transformar o
         * local onde o veículo ficou parado em um ponto.
         * @param {object} parada - Objeto enviado pelo ParadaInfo
         * @author Gui 🍺
         */
        transformaParadaEmPonto(parada) {
            this.novoPonto(
                {
                    latlng: [parada.lat, parada.lng],
                },
                true
            );
        },

        /**
         * Mostra os marcadores de excesso de velocidade no mapa.
         * Esta função pode processar tanto um array simples de dados
         * quanto um objeto contendo os dados e um id associado.
         * @param {Array|Object} dados - Os dados a serem processados. 
         * @author Otávio 🦆 
        */
        mostraExcessoVelocidade(dados) {
            var mapObject = this.$refs.mapaPrincipal.returnMapObject();
            var marcadores;

            if (Array.isArray(dados)) {
                marcadores = dados.map((item) => criaMarcadorExcessoVelocidade(item));
                this.limpaExcessoVelocidade();
                marcadores.forEach((marcador) => marcador.addTo(mapObject));
                this.markerVelocidadeOnMapa = marcadores;
            } else if (dados && dados.excessoVelocidade && Array.isArray(dados.excessoVelocidade)) {
                const { excessoVelocidade, id } = dados;
                marcadores = excessoVelocidade.map((item) => {
                    let marcador = criaMarcadorExcessoVelocidade(item);
                    marcador.addTo(mapObject);
                    return { marcador, id };
                });
                this.limpaExcessoVelocidadePorId(id);
                this.markerVelocidadeOnMapaId[id] = marcadores;
            }
        },

        /**
         * @description * Passa por cada um dos marcadores (de excesso de velocidade,
         * os rastros e as paradas respectivamente) e os remove do mapa
         */
        limpaExcessoVelocidade() {
            this.markerVelocidadeOnMapa.forEach((p) => p.remove());
            Object.keys(this.markerVelocidadeOnMapaId).forEach(id => {
                let arrayParaRemover = this.markerVelocidadeOnMapaId[id];
                arrayParaRemover.forEach(elemento => {
                    elemento.marcador.remove();
                });
            });
        },

        /**
         * Remove os marcadores de excesso de velocidade do mapa com base em um id específico.
         * Esta função é usada para limpar marcadores de excesso de velocidade que foram
         * adicionados ao mapa e estão associados a um id específico.
         * @param {string|number} id - O identificador único usado para associar um grupo de marcadores.
         * @author Otávio 🦆 
         */
        limpaExcessoVelocidadePorId(id) {
            if (this.markerVelocidadeOnMapaId[id]) {
                this.markerVelocidadeOnMapaId[id].forEach(objetoMarcador => {
                    if (objetoMarcador && objetoMarcador.marcador) {
                        objetoMarcador.marcador.remove();
                    }
                });
                delete this.markerVelocidadeOnMapaId[id];
            }
        },


        /**
         * @listen limpa-rastro-veiculo
         */
        limpaRastroVeiculo(rastro) {
            this.polisOnMapa.forEach((p) => {
                p.remove()
            });
            this.limpaParadas();
            this.pontosOnMapa.forEach((p) => {
                  p.remove();
            });
            this.polisOnMapa = [];
            this.veiculoComparacao = "";
            this.rastroViagem = rastro;
            this.$emit('limpa-rastro', this.rastroViagem);
            // this.comparandoRastro = false;
        },


        limpaRegioesOnMapa() {
            this.regsOnMap.forEach((r) => {
                r.remove();
            });
            this.regsOnMap = [];
        },

        /**
         * @listens show-info-veiculo
         * @description captura o estado da 
         * variável showInfoVeiculo de infoVeiculo
         * @author Otávio 🦆 
         */
        showInfoVeiculo(data) {
            this.infoVeiculoExibir = data;
        },

        /**
         * @listens criar-trajeto
         * @description escuta o evento de criarTrajeto
         * @author Otávio 🦆 
         */
        criarTrajeto(data) {
            this.criarTrajetos = data;
        },

        /**
         * @listens rastro-veiculo
         * @description Cria antpath mostrando o rastro do veículo
         * selecionado
         * 1 - Se for o veículo principal do rastro (veiculo selecionado)
         * já liga a popup de endereço do rastro e já prepara
         * os eventos e props.
         * 2 - Se for o veículo secundário, só desenha o rastro.
         * @param {array}  rastro       - todas as posições do veículo
         * @param {string} [cor='blue'] - cor da polyline
         * @param {string} [novoPontoInicio='true'] - se vai exibir no inicio do rastro o svg de novo ponto
         * @author Gui 🍺
         */
        mostrarRastroVeiculo(rastro, veiculo, cor = "blue", novoPontoInicio = true) {
            if (!rastro.length) return;
            var arrRastro = rastro.map((el) => {
                if (el?.posicao) return el.posicao.split(",");
                else return el;
            });
            if(novoPontoInicio){
                let inicioRastro = criarInicioRastroVeiculo(arrRastro[0]);
                this.addToMapa(inicioRastro);
                this.pontosOnMapa.push(inicioRastro);
            }
            let polyline = criarPolylineRastroVeiculo(arrRastro, cor);
            if ((veiculo.placa && veiculo.prefixo)) {
                if (cor === "blue") {
                    polyline.on(
                        "click",
                        function (click) {
                            var popupRastro =
                                require("@/components/Atom/Popup/PopupRastroVeiculo.vue").default;
                            popupRastro = Vue.extend(popupRastro);
                            popupRastro = new popupRastro({
                                propsData: {
                                    arrayPosicoes: rastro,
                                    latlngClick: click.latlng,
                                },
                            });
                            popupRastro.$mount();
                            this.bindPopup(popupRastro.$el);
                        }.bind(polyline)
                    );
                }

                let tooltip = L.tooltip({
                    className: "tooltipMapa",
                    content: `${veiculo.placa} | ${veiculo.prefixo}`,
                    direction: "auto",
                    sticky: true,
                });
                polyline.bindTooltip(tooltip);
            }
            this.polisOnMapa.push(polyline);
            this.addToMapa(polyline);
            this.$refs.mapaPrincipal.fitBounds(polyline.getBounds());
        },

        //  * @listens rastro-comparar
        /**
         * @description cria o rastro de comparação entre 2 veículos,
         * como o primeiro rastro já existe, só desenha o segundo
         * @param {array}  rastros - com todos os rastros de comparação
         * @param {object} veiculo - para criar o marcador do segundo veiculo
         * (comparado com o primeiro)
         * @author Gui 🍺
         */
        compararRastros(rastros, veiculo) {
            if (rastros !== undefined && veiculo !== undefined) {
                if (this.polisOnMapa.length > 1) this.limpaComparacao();
                let rastrod = [];
                var decodepoly = require("@mapbox/polyline");
                for (var [placa, rastro] of Object.entries(rastros)) {
                    if (placa === veiculo.placa) {
                        rastrod = decodepoly.decode(rastro);
                    }
                }
                // this.comparandoRastro = true;
                this.veiculoComparacao = veiculo;
                this.mostrarRastroVeiculo(rastrod, veiculo, "red");
            }
        },

        /**
         * @listens remove-comparacao
         * @description caso existir uma segunda poli no mapa
         * (que veio da comparação)
         * e existir um segundo ponto (também da comparação)
         * essa função remove esses
         * objetos do mapa e da array de controle.
         * @author Gui 🍺
         */
        limpaComparacao() {
            // this.comparandoRastro = false;
            this.veiculoComparacao = "";
            if (this.polisOnMapa[1]) {
                var poli = this.polisOnMapa.pop();
                poli.remove();
            }
            if (this.pontosOnMapa[1]) {
                var ponto = this.pontosOnMapa.pop();
                ponto.remove();
            }
            this.veiculocomparacao = {};
        },

        limparPolisOnMap() {
            this.polisOnMapa.forEach((p) => {
                if (p.rastroVeiculo) p.remove();
                if (p.seguir_veiculo) p.remove();
                if (p.posHistorico) p.remove();
            });
        },

        /**
         * @listens fecha-info
         * @description Dispara quando o usuário fecha a janela/popUp/coisa de
         * informações do veículo, quando a paradinha é fechada,
         * limpo algumas das variáveis atreladas
         * pra garantir que não vai ficar nada no caminho
         * @author Gui 🍺
         */
        fechaJanela() {
            if (!this.mapaRequisicaoViagem.fechaViagem) {
                this.limpaEventosVeiculo();
                this.limpaMarcadoresDeAlerta();
                this.limpaAcionamentoDePortas();
                this.limparPolisOnMap();
                this.limpaRastroVeiculo();
                this.fechaPosicoesVeiculo();
                this.limpaExcessoVelocidade();
                this.limpaParadas();
                this.limpaComparacao();
                this.polisOnMapa = [];
                this.veiculoRastro = {};
                this.veiculoSelecionado = false;
                this.marcadorSelecionado = {};
                // this.comparandoRastro = false;
                this.veiculoComparacao = "";
                this.seguindoVeiculo = false;

                this.infoVeiculoExibir = false;
            }
        },

        /**
         * @description Dispara quando o usuário acessa a tela novamente
         * para limpar os registros do mapa das consultas anteriores
         * @author Otávio 🦆 
         */
        limpaCache(){
            this.fechaJanela();
            this.limpaRegioesBusca();
            this.limpaPontosBusca();
            this.limpaBuscaRegiao(true);
            this.visualizarViagens = false;

        },

        /**
         * @listens click - na lista de resultados da busca do cliente
         * @description dispara quando o usuário clica em uma região
         * dele na lista
         * de resultados da busca por um endereço/palavra
         * @param {Object} regiao - resultado da função buscaRegiao
         * @author Gui 🍺
         */
        selecionaRegiaoCliente(regiao) {
            this.limpaRegioesBusca();
            this.limpaPontosBusca();
            let reg = criarRegiaoCliente(regiao);
            this.addToMapa(reg);
            this.regsOnMap.push(reg);
            try {
                this.$refs.mapaPrincipal.fitBounds(reg.getBounds());
            } catch (e) {
                ("");
            }
        },

        /**
         * @description Limpa as regiões resultantes de buscas
         * já desenhadas no mapa.
         */
        limpaRegioesBusca() {
            this.regsOnMap.map((r) => {
                if (r.busca) r.remove();
            });
        },

        /**
         * @description Limpa todos os pontos na array pontosOnMapa que
         * tenham a bandeira de busca
         */
        limpaPontosBusca() {
            this.pontosOnMapa.forEach((p) => {
                if (p.busca) p.remove();
            });
        },

        /**
         * @description Cria marcador fora do cluster p/ resultado
         * do campo de busca
         * @param {Object} ponto - objeto que vem do back-end, resultado
         * da pesquisa pelos pontos do cliente.
         */
        selecionaPontoCliente(ponto) {
            this.limpaPontosBusca();
            this.limpaRegioesBusca();
            this.mostrarLocal([ponto.polatitude, ponto.polongitude]);
            var mapObject = this.$refs.mapaPrincipal.returnMapObject();
            let p = new EagleMarker(ponto, mapObject);
            var popup = Vue.extend(PopUpPonto);
            var instance = new popup({
                propsData: {
                    ponto: ponto,
                    permissoes: this.userPermission.mappontos,
                },
            });
            instance.$mount();
            p.bindPopup(instance.$el);
            this.pontosOnMapa.push(p);
            p = defineObjetoComoBusca(p);
            this.addToMapa(p);
        },

        /**
         * @listens click-mapa
         * @description facilitador para criar um novo ponto
         * diretamente no mapa,
         * a instancia do facilitador e o marcador que representa o ponto são
         * criados aqui em tempo real, os $on é para associar os eventos "v-on"
         * Também estabelece os handlers de certos eventos,
         * tanto com o ponto quanto como com a popup de edição:
         * ->salvar-ponto => emitido quando o usuário clica no botão de salvar
         * ->cancelar-ponto => clica no botão de cancelar
         * ->muda-raio => quando o usuário altera o raio do ponto(default:50)
         * ->dragend => emitido no momento em que o usuário
         * termina de mover o ponto.
         * @param {object} options
         * @param {(object|number[])} options.latlng - que contenha latlng,
         * que é utilizado pra criar o marcado.
         * @param {boolean} out    - caso a função for chamada por outro motivo
         * além do click no mapa e mesmo assim deve criar o ponto.
         * @author Gui 🍺
         */
        novoPonto({ latlng }, out = false) {
            if (this.atividade === "cadastro-ponto" || out) {
                this.raiosOnMapa.forEach((r) => r.remove());
                this.raiosOnMapa = [];
                this.pontosOnMapa.forEach((p) => {
                    if (p.novoPonto) p.remove();
                });
                this.pontosOnMapa = [];
                var marker = this.criarMarkerPonto(latlng);
                this.pontosOnMapa.push(marker);
                this.atividade = "";
                this.criaRaio(latlng);
            }
            if (this.criarTrajetos) {
                this.pontosDadosDaViagem(latlng);
            }
        },

        /**
         * @description captura o valor de
         * latitude e longitude
         * @author Otávio 🦆 
         */
        pontosDadosDaViagem(latlng) {
            this.pontoFinal = latlng;
        },

        criarMarkerPonto(latlng) {
            var PopupNovoPonto =
                require("@/components/Atom/Painel/PopupNovoPonto.vue").default;
            var pop = Vue.extend(PopupNovoPonto);
            var instance = new pop({
                propsData: { selectEmpresas: this.optClientes },
            });
            instance
                .$mount()
                .$on("salvar-ponto", this.salvarPonto)
                .$on("cancelar-ponto", this.cancelaNovoPonto)
                .$on("muda-raio", this.novoRaioPontoEdicao);
            var m = criaMarcadorPadrao(latlng, true, true);
            m.bindPopup(instance.$el);
            this.addToMapa(m);
            m.on("dragend", this.alteraRaioOnMapa);
            m.openPopup();
            return m;
        },

        /**
         * @listens cancela-regiao
         * @listens salva-regiao
         * @param {boolean} bool - Se é para criar um novo ponto
         * ou cancelar a criação
         */
        cadastrarPonto(bool) {
            if (bool) {
                if (this.atividade === "cadastro-regiao") this.cancelaRegiao();
                if (this.atividade === "medir-area") this.cancelaMedirArea();
                this.atividade = "cadastro-ponto";
            } else {
                this.atividade = "";
                this.cancelaNovoPonto();
            }
        },

        /**
         * @description Cria o círculo que fica no mapa e que representa
         * o raio de um novo ponto. Começa pelo valor padrão dessa
         * função de 50m.
         * @param {(object|number[])} latlng - obj com as chaves lat lng ou uma
         * array com os valor nessa ordem.
         */
        criaRaio(latlng) {
            var raio = L.circle(latlng, {
                radius: 50,
                interactive: false,
            });
            this.addToMapa(raio);
            this.raiosOnMapa.push(raio);
        },

        /**
         * @listens dragend - emitido pelo marcador depois do usuário mover ele.
         * @description Altera a posição do circulo que representa o raio
         * do ponto sendo criado/editado, usando a lat-long do ponto.
         */
        alteraRaioOnMapa() {
            this.raiosOnMapa[0].setLatLng(this.pontosOnMapa[0].getLatLng());
        },

        /**
         * @listens muda-raio - enquanto cria um novo ponto
         * @param {number} raio - novo raio do ponto
         * @description Altera o ráio do círculo,
         * com o valor que o usuário colocar no slider
         */
        novoRaioPontoEdicao(raio) {
            this.raiosOnMapa[0].setRadius(raio);
        },

        /**
         * @listens salvar-ponto
         * @description Emitido quando o usuário tenta salvar um novo ponto,
         * envia as informações relativas a ele
         * pro back-end e espera um retorno
         * caso der tudo certo ele remove o ponto do mapa, e caso
         * estiver exibindo os pontos do usuário já adiciona o novo ponto
         * a array markerPontos.
         * @param {object} infoPonto - informações do ponto a ser salvo.
         * @author Gui 🍺
         */
        salvarPonto(infoPonto) {
            var obj = {
                info: {
                    ...infoPonto,
                    polatitude: this.pontosOnMapa[0].getLatLng().lat,
                    polongitude: this.pontosOnMapa[0].getLatLng().lng,
                },
            };
            var uri = `${this.baseUri}novo/ponto`;
            new HttpRequest().Post(uri, obj).then((data) => {
                if (data.data.success) {
                    this.pontosOnMapa.forEach((p) => {
                        if (p.novoPonto) p.remove();
                    });
                    this.raiosOnMapa.forEach((r) => r.remove());
                    this.pontosOnMapa = [];
                    this.raiosOnMapa = [];
                    /** Caso o usuário estiver exibindo os pontos dele,
                     * recarrega pra ter as informações mais atualizadas */
                    var tipoIncl = this.tiposMarcadoresOnMapa.includes(
                        data.data.ponto.potipo
                    );
                    if (tipoIncl) {
                        var m = new EagleMarker(
                            data.data.ponto,
                            this.$refs.mapaPrincipal.returnMapObject()
                        );
                        this.preparaPonto(m);
                        this.$refs.mapCluster.addLayer(m);
                        this.markerPontos.push(m);
                    }
                }
            });
        },

        /**
         * @listens cancelar-ponto
         * @description Caso o usuário decidir não criar mais um novo ponto
         */
        cancelaNovoPonto() {
            this.cancelaMedirArea();
            this.pontosOnMapa.forEach((p) => {
                if (p.novoPonto) p.remove();
            });
            this.atividade = "";
            this.raiosOnMapa.forEach((r) => r.remove());
            this.pontosOnMapa = [];
            this.raiosOnMapa = [];
        },

        /**
         * @listens click lista de regiões resultado do OSMR
         * @description Desenha uma região resultado de uma busca no
         * OSMR, usando os métodos do leaflet diretamente
         * para melhorar o desempenho o máximo possível.
         * @param {object} regiao - retorno do OSMR.
         * @author Gui 🍺
         */
        selecionaRegiaoBusca(regiao) {
            this.limpaRegioesBusca();
            this.limpaPontosBusca();
            let obj = criarMarkerCentroRegiao(regiao);
            this.addToMapa(obj.regiao);
            this.addToMapa(obj.centro);
            this.pontosOnMapa.push(obj.centro);
            this.regsOnMap.push(obj.regiao);
            this.$refs.mapaPrincipal.fitBounds(obj.regiao.getBounds());
        },

        /**
         * @listens buscar - menu do mapa
         * @description altera a atividade que esta sendo
         * realizada pelo usuário para buscar.
         * @author Gui 🧟
         */
        buscaCliente() {
            if (this.atividade === "buscar") {
                this.atividade = "";
                this.limpaBuscaRegiao(true);
            } else {
                this.atividade = "buscar";
                this.$nextTick(() => {
                    this.$refs.campoBusca.focus();
                });
            }
        },

        /**
         * @listen keyup.enter - input de busca.
         * @listem click - botao ao lado do input de busca.
         * @description Envia uma request para procurar por
         * A) Regiões do cliente
         * B) Pontos do cliente
         * C) Regiões determinadas pelo OSMR
         * @author Gui 🍺
         */
        buscaRegiao() {
            this.limpaBuscaRegiao();
            this.searchRegiao = true;
            let uri = `${this.baseUri}search`;
            let obj = {
                search: this.queryRegiaoUser,
                cli: this.clienteSelecionado.map((cli) => {
                    return cli.value;
                }),
            };
            new HttpRequest()
                .Post(uri, obj)
                .then((data) => {
                    if (data.code === 200) {
                        /**OSMR */
                        this.regioesBusca = data.data.resultado;
                        /**Regioes do cliente */
                        this.regioesBuscaCliente = data.data.reg;
                        /**Pontos cliente */
                        this.pontosBuscaCliente = data.data.pontos;
                    }
                })
                .finally(() => (this.searchRegiao = false));
        },

        /**
         * @listens keyup.esc - do input de busca
         * @description Limpa resultados E campo de busca
         * @author Gui 🧟
         */
        limpaBuscaRegiao(q = false) {
            this.regioesBusca = [];
            this.regioesBuscaCliente = [];
            this.pontosBuscaCliente = [];
            if (q) {
                this.pontosOnMapa.forEach((p) => {
                    if (p.busca) p.remove();
                });
                this.queryRegiaoUser = "";
                this.atividade = "";
                this.limpaRegioesBusca();
            }
        },

        limpaHistPosicao() {
            this.polisOnMapa.forEach((p) => {
                if (p.posHistorico) p.remove();
            });
        },

        posicaoHistorico(pos) {
            this.limpaHistPosicao();
            let poli = criarPolilyneHistorico(pos);
            this.polisOnMapa.push(poli);
            var mapa = this.$refs.mapaPrincipal.returnMapObject();
            mapa.addLayer(poli);
            this.$refs.mapaPrincipal.flyToBounds(poli.getBounds());
        },

        listarPosicoesVeiculo(obj) {
            this.listarPosicoes = true;
            this.visualizarViagens = false;
            this.$refs.histPosicoes.iniciaHistoricoDePosicoes(obj);
        },

        visualizaViagens(obj) {
            this.visualizarViagens = true;
            this.listarPosicoes = false;
            this.$refs.visualizaViagens.iniciaVisualizarViagens(obj);
        },

        fechaPosicoesVeiculo() {
            this.limpaHistPosicao();
            this.listarPosicoes = false;
            this.visualizarViagens = false;
        },

        /**lat-long local
         * @listens local-alerta
         * @param {number[]} array lat-long
         * @param {number} zoom nível de zoom
         * @param {bool} criaMarker se deve ou não criar um marcador do tipo alerta no local
         * @description redireciona o mapa até o local do alerta, com o nível de zoom
         * passado por parâmetro
         * @author Gui 🍺
         */
        mostrarLocal(array, zoom = 15, criaMarker = false) {
            if (!array) {
                this.limpaMarcadoresDeAlerta();
                return;
            }
            this.$refs.mapaPrincipal.flyTo(array, zoom);
            if (criaMarker) {
                this.limpaMarcadoresDeAlerta();
                let marker_alerta = criaMarcadorAlerta(array);
                this.addToMapa(marker_alerta);
            }
        },

        /**
         * @description percorre todas as layers do mapa e remove aquelas
         * que tem a flag de alerta_veiculo
         * @author Gui 🍺
         */
        limpaMarcadoresDeAlerta() {
            let mapa = this.$refs.mapaPrincipal?.returnMapObject();
            mapa?.eachLayer((a) => {
                if (a?.alerta_veiculo) a.remove();
            });
        },

        /**
         * @description Essa função deve fazer com que o mapa passe a sempre
         * manter o veiculo, recebido por parametro, centralizado.
         */
        seguirVeiculo(veiculo, seguir) {
            this.seguindoVeiculo = seguir;
            if (seguir) {
                var position = [
                    this.marcadorSelecionado.latitude,
                    this.marcadorSelecionado.longitude,
                ];
                this.arrayPosicoes.push(position);
                this.criaPoliSeguir();
                this.$refs.mapaPrincipal.flyTo(position);
            } else {
                this.arrayPosicoes = [];
                this.polisOnMapa.filter((p) => {
                    if (p.seguir_veiculo === veiculo) {
                        p.remove();
                        return false;
                    }
                    return true;
                });
            }
        },

        criaPoliSeguir() {
            var poli = antPath(this.arrayPosicoes, {
                interactive: false,
                color: "purple",
                delay: 2000,
                weight: 6,
            });
            Object.defineProperty(poli, "seguir_veiculo", {
                value: this.marcadorSelecionado.veiculoId,
                enumerable: true,
                writable: false,
                configurable: false,
            });
            this.polisOnMapa.push(poli);
            var mapa = this.$refs.mapaPrincipal.returnMapObject();
            mapa.addLayer(poli);
        },

        /**
         * @listens click - veículo no mapa.
         * @param veiculo
         * @description inicia a modal/popup/parada de veículo e abre ela
         */
        selecionaVeiculo(veiculo) {
            var veiculoSemReferencia = { ...veiculo };
            if (this.userPermission?.mappainelinformacoes.ppvisualizar) {
                this.veiculoSelecionado = veiculoSemReferencia.placa;
                this.marcadorSelecionado = veiculoSemReferencia;
                this.$refs.infoVeiculo.iniciaInfoVeiculo(veiculoSemReferencia);
            }
        },

        /** Quando atualizar as informações do mapa se faz necessário. */
        recarregarMapa() {
            this.$refs.menuMapa.emiteCarregaPontos();
        },

        buscaOptVeiculos() {
            if (this.clienteSelecionado.length) {
                new FiltrosService()
                    .dados_filtros(
                        this.clienteSelecionado.map((c) => {
                            return c.value;
                        }),
                        ["V"]
                    )
                    .then((data) => {
                        this.optVeiculos = data.V;
                    });
            }
        },

        /**
         * @listens pontos-coleta
         * @listens pontos-referencia
         * @listens pontos-entrega
         * @param {('E'|'P'|'C')} tipos - tipo de ponto,
         * @param {boolean} buscar - se a função deve buscar os pontos
         * do tipo ou remover.
         * @description controle dos pontos do usuário no mapa,
         * de acordo com as alterações
         * do usuário a função busca ou limpa os pontos de acordo com o tipo 🦕
         * @author Gui 🧟
         */
        mudaPontos(tipos, buscar) {
            if (buscar) {
                let clientes = this.clienteSelecionado.map((e) => e.value);
                if (!clientes.length) return;
                NProgress.configure({
                    trickle: false,
                    showSpinner: false,
                });
                NProgress.start();
                var obj = {
                    tipos: [tipos],
                    cliente: clientes,
                };
                var uri = this.baseUri + "buscar/pontos";
                if (!this.tiposMarcadoresOnMapa.includes(tipos))
                    this.tiposMarcadoresOnMapa.push(tipos);
                this.$refs.menuMapa.loadingPontos("a");
                new HttpRequest()
                    .Post(uri, obj)
                    .then((retorno) => {
                        if (retorno.status) {
                            this.buscaProxPaginaPontos(obj, retorno.data.data);
                            var mapObject = this.$refs.mapaPrincipal.returnMapObject();
                            var novosMarcadores = retorno.data.data.data.map((p) => {
                                return new EagleMarker(p, mapObject);
                            });
                            this.$refs.mapCluster.addMarkerBulk(novosMarcadores);
                            this.markerPontos = this.markerPontos.concat(novosMarcadores);
                            this.markerPontos.forEach(this.preparaPonto);
                        }
                    })
                    .finally(() => this.$refs.menuMapa.endLoadingPontos());
            } else {
                var index = this.tiposMarcadoresOnMapa.findIndex((x) => {
                    return x === tipos;
                });
                if (index !== undefined) this.tiposMarcadoresOnMapa.splice(index, 1);
                var filtro = this.markerPontos.filter((p) => {
                    return p.infoPonto.potipo === tipos;
                });
                this.$refs.mapCluster.removeLayers(filtro);
                filtro = this.markerPontos.filter((p) => {
                    return p.infoPonto.potipo !== tipos;
                });
                this.markerPontos = filtro;
            }
        },

        /**
         * @param {obejct} obj - mesmo obj enviado na request inicial.
         * @param {object} resposta - resposta da request anterior
         * @param {number} resposta.current_page - página atual
         * @param {number} resposta.last_page    - última página
         * @description faz a request da próxima pagina da paginação dos
         * pontos. Cada página traz 2 mil pontos, fazendo dessa maneira
         * garante que não vai ter informação demais sendo adiciona ao mapa
         * e aumenta um bom tanto da performance da página.
         * @author Gui 🧟
         */
        buscaProxPaginaPontos(obj, { current_page, last_page }) {
            if (current_page !== last_page) {
                var uri = `${this.baseUri}buscar/pontos?page=${current_page + 1}`;
                new HttpRequest().Post(uri, obj).then((retorno) => {
                    var mapObject = this.$refs.mapaPrincipal.returnMapObject();
                    var novosMarcadores = retorno.data.data.data.map((p) => {
                        return new EagleMarker(p, mapObject);
                    });
                    this.$refs.mapCluster.addMarkerBulk(novosMarcadores);
                    this.markerPontos = this.markerPontos.concat(novosMarcadores);
                    this.markerPontos.forEach(this.preparaPonto);
                    NProgress.inc();
                    this.buscaProxPaginaPontos(obj, retorno.data.data);
                });
            } else {
                NProgress.done();
            }
        },

        /**
         * @param {object[]} pontos - array de objetos
         * com os atributos de um ponto
         * @deprecated não é utilizada por enquanto.
         * @description Primeiro limpa todo o cluster, depois transforma
         * as informações de cada ponto em um marcador (usando a classe
         * EagleMarker, que extende L.Marker) e os insere no mapa.
         * Depois disso chama a função que liga as popUps de cada
         * marcador.
         * @author Gui 🍺
         */
        addMarkersBulk(pontos) {
            var mapObject = this.$refs.mapaPrincipal.returnMapObject();
            this.$refs.mapCluster.clearLayers();
            this.markerPontos = pontos.map((p) => new EagleMarker(p, mapObject));
            this.markerPontos.forEach(this.preparaPonto);
            this.$refs.mapCluster.addMarkerBulk(this.markerPontos);
        },

        /**
         * @param {EagleMarker} ponto
         * @description adiciona um evento para quando o usuário clilcar
         * no ponto pela primeira vez, criar a popup e liga ela ao
         * ponto. Dessa maneira as popups não existem até o ponto ser
         * clicado, aumentando um pouco a performance da página.
         * @author Gui 🍺
         */
        preparaPonto(ponto) {
            ponto.addEventListener("click", () => {
                ponto.removeEventListener("click"); // mesmo com o once:true. estava executando essa função toda vez.
                var popup = Vue.extend(PopUpPonto);
                popup = new popup({
                    propsData: {
                        ponto: ponto.infoPonto,
                        permissoes: this.userPermission.mappontos,
                    },
                });
                popup
                    .$mount()
                    .$on("ponto-excluido", this.excluidoPonto)
                    .$on("ponto-editado", this.editaPonto)
                    .$on(
                        "editando-ponto",
                        function () {
                            let tempo = 50;
                            setTimeout(() => {
                                this.openPopup();
                            }, tempo);
                        }.bind(ponto)
                    );
                ponto.bindPopup(popup.$el);
                ponto.openPopup();
            });
        },

        /**
         * @param {EagleMarler} ponto, já contendo as informações
         * atualizadas.
         * @description altera a popup do ponto refletir alterações
         * nas informações do ponto.
         * @author Gui 🍺
         */
        atualizaPopupPonto(ponto) {
            ponto.unbindPopup();
            var popupAtualizada = Vue.extend(PopUpPonto);
            popupAtualizada = new popupAtualizada({
                propsData: {
                    ponto: ponto.infoPonto,
                    permissoes: this.userPermission.mappontos,
                },
            });
            popupAtualizada
                .$mount()
                .$on("ponto-excluido", this.excluidoPonto)
                .$on("ponto-editado", this.editaPonto)
                .$on(
                    "editando-ponto",
                    function () {
                        let tempo = 50;
                        setTimeout(() => {
                            this.openPopup();
                        }, tempo);
                    }.bind(ponto)
                );
            ponto.bindPopup(popupAtualizada.$el);
            this.$nextTick(() => {
                ponto.openPopup();
            });
        },

        /**
         * @param {number} pocodigo código do ponto sendo excluido
         * @description Se um ponto for excluido com sucesso, essa função
         * vai procurar pelo ponto no mapa e remover ele para refletir
         * a alteração.
         * @author Gui 🍺
         */
        excluidoPonto(pocodigo) {
            var index = this.markerPontos.findIndex((r) => {
                return r.infoPonto.pocodigo === pocodigo;
            });
            var rm = this.markerPontos.splice(index, 1);
            rm.forEach((m) => {
                this.$refs.mapCluster.removeLayer(m);
            });
            if (this.$refs.mapaPrincipal) {
                this.$refs.mapaPrincipal.closePopup();
            }
        },

        /**
         * @param {object} ponto
         * @param {number} ponto.pocodigo
         * @description atualiza as informações de um ponto dps dele
         * ser atualizado.
         * @author Gui 🧟
         */
        editaPonto(ponto) {
            var a = this.markerPontos.find((p) => {
                return p.infoPonto.pocodigo === ponto.pocodigo;
            });
            a.atualizaPonto(ponto);
            this.atualizaPopupPonto(a);
            var existe = this.tiposMarcadoresOnMapa.includes(a.infoPonto.potipo);
            if (!existe) {
                this.excluidoPonto(ponto.pocodigo);
            }
        },

        /**
         * @description Os marcadores são gerados dinamicamente e inseridos
         * no cluster, então, isso quer dizer que a popup
         * relacionada ao ponto também tem de ser inserido dessa maneira.
         * A PopUpPonto é importada no inicio do script, mas só é montada
         * nessa função, e logo depois ligo ela ao ponto com o bindPopup.
         * @author Gui 🍺
         */
        ligaPopUpPontos() {
            //extendendo um componente com Vue.extend
            var componentClass = Vue.extend(PopUpPonto);
            this.markerPontos.forEach((p) => {
                /**Iniciando o componente e passando as props */
                var instance = new componentClass({
                    propsData: {
                        ponto: p.infoPonto,
                        permissoes: this.userPermission.mappontos,
                    },
                });
                /**montando o componente para ele ser reconhecido*/
                instance.$mount();
                //evento de edição bem sucedida
                instance
                    .$on("ponto-editado", (ponto) => {
                        var a = this.markerPontos.find((p) => {
                            return p.infoPonto.pocodigo === ponto.pocodigo;
                        });
                        a.atualizaPonto(ponto);
                        this.preparaPonto(a);
                    })
                    .$on("ponto-excluido", (pocodigo) => {
                        var index = this.markerPontos.findIndex((r) => {
                            r.infoPonto.pocodigo === pocodigo;
                        });
                        var rm = this.markerPontos.splice(index, 1);
                        rm.forEach((m) => {
                            this.$refs.mapCluster.removeLayer(m);
                        });
                    });
                /**Ligando o elemento com o ponto */
                p.bindPopup(instance.$el);
            });
        },

        allVeiculos(all) {
            if (!all) return;
            this.limparClusterVeiculos();
            var markers = all.map((e) => e.marker);
            const { selecionaVeiculo } = this;
            markers.forEach((veiculo) => {
                veiculo.addEventListener("click", function (e) {
                    selecionaVeiculo(e.target.info);
                });
            });
            this.$refs.mapVeiculos.addMarkerBulk(markers);
            this.$nextTick(() => {
                this.markerVeiculos = [...all];
            });
        },

        limpaCluster() {
            if (typeof this.$refs.mapCluster !== "undefined")
                this.$refs.mapCluster.clear();
            this.markerPontos = [];
        },

        limparClusterVeiculos(limparVariavel = true) {
            if (typeof this.$refs.mapVeiculos !== "undefined")
                this.$refs.mapVeiculos.clear();
            if (limparVariavel) {
                this.markerVeiculos = [];
            }
        },

        /**
         * @description Dispara quando muda o
         * cliente selecionado no painel de controle.
         * @param {number[]} cli - array de clientes selecionados.
         */
        mudaCliente(cli) {
            // this.comparandoRastro = false;
                this.limpaCluster();
                this.limparClusterVeiculos();
                this.clienteSelecionado = cli;
                this.criarLegenda(cli);
        },

        /**
         * @description Função para adicionar 1 marcador de veículo,
         * disparada quando o usuário reseleciona um veículo
         */
        addMarker(veiculo) {
            this.pontoInicial = {
                'lat': veiculo.latitude,
                'lng': veiculo.longitude 
            };
            this.$refs.mapVeiculos.addLayer(veiculo.marker);
            this.markerVeiculos.push(veiculo);
        },

        /**
         * @param {number} veiculoId - código do marcador a ser removido.
         * @description remove um marcador da lista sendo exibida.
         */
        remMarker(veiculoId) {
            const index = this.markerVeiculos.findIndex((m) => {
                return m.veiculoId == veiculoId;
            });
            if (index || index == 0) {
                var rm = this.markerVeiculos.splice(index, 1);
                if (rm && rm.length) {
                    this.$refs.mapVeiculos.removeLayer(rm[0].marker);
                }
            }
        },

        /**
         * @description Muda a viewport do mapa pra onde o objCoor levar.
         * @param objCoor  [latitude, longitude, placa]
         * @author Gui 🍺
         */
        localizaVeiculo(veiculo) {
            this.$refs.mapaPrincipal.flyTo([veiculo.latitude, veiculo.longitude], 15);
            this.placaVeiculoSelecionado = veiculo.placa;
        },

        /**
         * @param {number[]} cliente
         * @description Faz a request que traz as informações para montar
         * a legenda dos grupos de veículos do usuário
         * @author Gui 🍺🍺
         */
        criarLegenda(cliente) {
            let uri = this.baseUri + "criar/legenda";
            this.legendaGrupoVeiculos = [];
            if (cliente.length)
                new HttpRequest()
                    .Post(uri, {
                        cliente: cliente.map((c) => {
                            return c.value;
                        }),
                    })
                    .then((data) => {
                        if (data.data?.legenda && data.status)
                            this.legendaGrupoVeiculos = data.data.legenda;
                        else conectionError();
                    });
        },

        /**
         * @listens salva-regiao
         * @listens cancela-regiao
         * @param {boolean} [bool=false] se deve redesenhar as regiões
         * do usuário ainda guardadas em controle.
         * @author Gui 🧟
         */
        cancelaRegiao(bool = false) {
            this.excluirRegiao(0);
            this.regiaoEditavel = {};
            if (!this.controleVerRegioes) {
                this.regsOnMap.forEach((e) => {
                    return e.remove();
                });
                this.regsOnMap = [];
                this.regioesUsuario(false);
            }
            // if(!bool){
            if (this.$refs.freeDraw) {
                this.$refs.freeDraw.deletarDesenho();
            }
            this.atividade = "";
            this.regsOnMap.forEach((reg) => {
                if (reg.regiaoUsuario)
                    reg.addTo(this.$refs.mapaPrincipal.returnMapObject());
            });
            // }
        },

        //FREE DRAW
        /**
         * @listens cadastrar-regiao
         * @param {boolean} bool - se deve iniciar o cadastro ou cancelar
         */
        cadastrarRegiao(bool) {
            if (bool) {
                if (this.atividade === "cadastro-ponto") this.cancelaNovoPonto();
                if (this.atividade === "medir-area") this.cancelaMedirArea();
                this.atividade = "cadastro-regiao";
                this.mostraControleFree = true;
                this.$nextTick(() => {
                    if (this.$refs.freeDraw) this.$refs.freeDraw.continuaDesenho();
                });
            } else {
                this.cancelaRegiao();
                this.atividade = "";
            }
        },

        /**
         * @listens desenho -  evento de quando o usuário completa
         * um desenho no free draw
         * @param {array[]} coordenadas - array contendo 1 nova array p/
         * cada poligono desenhado.
         * @author Gui 🍺
         */
        async emiteDesenho(coordenadas) {
            this.excluirRegiao(0);
            this.coordenadasFree = coordenadas;
            await this.criarRegiaoFreeDraw(coordenadas);
            if (!this.mostraControleFree && this.atividade === "historico-posicoes") {
                if (this.$refs.freeDraw) this.$refs.freeDraw.paraDesenhoSemEvento();
                this.modalHistoricoPosicoes(coordenadas);
            }
        },

        async criarRegiaoFreeDraw(coordenadas) {
            this.excluirRegiao(0);
            var reg = L.polygon(coordenadas, {
                color: "#63a3db",
                interactive: false,
            });
            this.addToMapa(reg);
            var regiao = { recodigo: 0, recor: "#63a3db" };
            reg = await definePropriedadesRegiao(regiao, reg);
            await this.regsOnMap.push(reg);
        },

        //ROTAS
        /**
         * @listens att-marcador-rota
         * @param {object} marcador
         * @param {number} marcador.ircodigo
         * @description procura pelo marker com o mesmo ircodigo
         * remove o marcador e cria um novo com as novas informações.
         */
        atualizaMarcadoRota(marcador) {
            var marker = this.pontosOnMapa.find((m) => {
                return m.ircodigo === marcador.ircodigo;
            });
            updateMaker(marker, marcador);
        },

        /**
         * @param {number} ircodigo  código do item a ser removido.
         * @description procura por um ponto com o código passado e
         * remove esse ponto do mapa.
         */
        removeMarcadorRota(ircodigo) {
            var index = this.pontosOnMapa.findIndex((p) => {
                return p.ircodigo === ircodigo;
            });
            if (typeof index !== "undefined") {
                this.$refs.mapaPrincipal.removeLayer(this.pontosOnMapa[index]);
                this.pontosOnMapa.splice(index, 1);
            }
        },

        /**
         * @listens desenhar-rota
         * @param {object} rota - objeto com as informações da rota.
         * @param {string} rota.ropolyline - string com a roda encodada.
         * @param {string} rota.rocor      - string com a cor da rota.
         * @param {number} rota.rocodigo   - código da rota.
         * @author Gui 🍺
         */
        desenharRota(rota) {
            if (rota?.roplaca) this.filtroVeiculos.push(rota.roplaca);
            if (rota.ropolyline) {
                var decodePoly = require("@mapbox/polyline");
                var arrLatLang = decodePoly.decode(rota.ropolyline);
                var poliline = antPath(arrLatLang, {
                    interactive: false,
                    delay: 2000,
                    weight: 6,
                    color: rota.rocor ?? "blue",
                });
                this.addToMapa(poliline);
                poliline = defineComoRota(poliline, rota.rocodigo);
                this.rotasOnMap.push(poliline);
                this.$refs.mapaPrincipal.flyToBounds(poliline.getBounds());
            }
            this.marcadoresRota(rota);
            this.veiculoRota(rota);
        },

        /**
         * @param {object} obj
         * @param {string} obj.roplaca - placa do veículo
         * @param {number} obj.rocodigo
         * @description inseri o veículo na array que controla
         * a exibição de veículos de rota.
         * @author Gui 🍺
         */
        veiculoRota({ roplaca, rocodigo }) {
            var placaRota = {
                veplaca: roplaca,
                rocodigo: rocodigo,
            };
            this.veiculosRota.push(placaRota);
        },

        /**
         * @listens remove-rota
         * @param {number} rocodigo - código da rota a ser removida.
         * @description remove uma rota do mapa baseada no código da mesma
         */
        removerRota(rocodigo, roplaca) {
            var index = this.rotasOnMap.findIndex((r) => {
                return r.rocodigo === rocodigo;
            });
            if (index !== -1) {
                //Como existem rotas SEM poli, esse IF é pra não dar erro
                this.rotasOnMap[index].remove();
                this.rotasOnMap.splice(index, 1);
            }
            this.pontosOnMapa.forEach((el) => {
                if (el.rocodigo === rocodigo) {
                    el.remove();
                }
            });
            var indexVeiculo = this.veiculosRota.findIndex((el) => {
                return el.rocodigo === rocodigo;
            });
            if (typeof indexVeiculo !== -1) this.veiculosRota.splice(indexVeiculo, 1);
            let mapa = this.$refs.mapaPrincipal.returnMapObject();
            mapa.eachLayer((ly) => {
                if (ly?.rastro_rota) {
                    if (ly?.rocodico == rocodigo) ly.remove();
                }
            });
            this.filtroVeiculos = this.filtroVeiculos.filter((e) => e != roplaca);
            this.$refs.painelMapa.togglePainelControle();
            setTimeout(() => {
                this.$refs.painelMapa.togglePainelControle();
            }, 200);
        },

        /**
         * @description Remove tudo que tem a ver com rotas do mapa
         * e das variáveis de controle.
         * @todo arrumar remover pontos
         */
        removeTodasRotas() {
            this.filtroVeiculos = [];
            for (var x of this.pontosOnMapa) if (x.rota) x.remove();
            this.pontosOnMapa = this.pontosOnMapa.filter((p) => {
                return !p?.rota;
            });
            this.veiculosRota = [];
            this.rotasOnMap.forEach((r) => r.remove());
            this.rotasOnMap = [];
            this.$refs.painelMapa.togglePainelControle();
            setTimeout(() => {
                this.$refs.painelMapa.togglePainelControle();
            }, 200);
        },

        /**
         * @listens pontos-inicial-final-viagem 
         * @param Object pontoInicio - informações do ponto inicial
         * @param Object pontoFim - informações do ponto final
         * envia as informações para função que cria o marcador e da um push na pontosOnMapa.
         * @author Otávio 🦆 
         */
        exibirPontoInicialFinalViagem(pontoInicio, pontoFim){
            if (pontoInicio) {
                var marker_saida = this.markerIORota(
                    pontoInicio,
                    null,
                    "assets/mapa_finder/inicio.svg",
                    null,
                    'saida',
                    pontoInicio.hora
                );
                this.pontosOnMapa.push(marker_saida);
            }
            if (pontoFim) {
                var marker_retorno = this.markerIORota(
                    pontoFim,
                    null,
                    "assets/mapa_finder/fim.svg",
                    null,
                    'retorno',
                    pontoFim.hora
                );
                this.pontosOnMapa.push(marker_retorno);
            }
        },

        /**
         * @param {object} obj
         * @param {array} obj.itens_rota
         * - todos os pontos que fazem parte da rota.
         * @param {object} obj.ponto_saida   - ponto de saída da rota
         * @param {object} obj.ponto_retorno - ponto final da rota.
         * @param {number} obj.rocodigo      - código da rota.
         * @description cria todos os marcadores da rota e adiciona eles nas
         * variáveis de controle.
         * @author Gui 🍺
         */
        marcadoresRota({ itens_rota, ponto_saida, cargas, ponto_retorno, rocodigo, rodatahorainicio, rodatahorafim, roAtraso, rotempo }) {
            if (ponto_saida !== null) {
                var marker_saida = this.markerIORota(
                    ponto_saida,
                    rocodigo,
                    "assets/mapa_finder/inicio.svg",
                    itens_rota,
                    cargas,
                    'saida',
                    rodatahorainicio
                );
                this.pontosOnMapa.push(marker_saida);
            }
            if (ponto_retorno) {
                var marker_retorno = this.markerIORota(
                    ponto_retorno,
                    rocodigo,
                    "assets/mapa_finder/fim.svg",
                    itens_rota,
                    cargas,
                    'retorno',
                    rodatahorafim
                );
                this.pontosOnMapa.push(marker_retorno);
            }
            itens_rota.forEach(item => {
                item.roAtraso = roAtraso;
                item.rotempo = rotempo;
            });
            itens_rota.forEach(this.criaMarcadorItemRota);
            cargas.forEach(this.criarMarcadorCargaMapa);
            this.controleExibirLegendas(this.ocultaLegenda);
        },

        /**
         * @param {object} ponto
         * @param {number} rocodigo
         * @param {string} caminhoIcone
         * @description cria os marcadores de inicio e final de rota
         * e adiciona eles ao mapa.
         * @returns {L.Marker} marcador com propriedas já definidas.
         * @author Gui 🍺
         */
        markerIORota(ponto, rocodigo, caminhoIcone, itens_rota, status, hora) {
            let marker = criarMarkerIORota(ponto, rocodigo, caminhoIcone, itens_rota, status, hora);
            this.addToMapa(marker);
            marker = defineComoRota(marker, rocodigo);
            return marker;
        },

        /**
         * @param {object} item  - item da rota, de onde sera gerado o marcado
         * @param {object} item.ponto - informações do ponto daquele item
         * @description cria o marcador e já adiciona ele na array de controle
         * dos pontos no mapa. Os marcadores são criados com o
         * atribudo rocodigo, que é o código da rota que eles pertencem,
         * então para fazer o controle e retirar * eles no mapa basta
         * usar esse atributo.
         * @author Gui 🍺
         */
        criaMarcadorItemRota(item) {
            if (!item.ponto) return;
            let marker = criarMarcadorItemRota(item);
            this.addToMapa(marker);
            marker = definePropsMarcadorItemRota(marker, item);
            this.pontosOnMapa.push(marker);
        },
        
        /**
         * @param {object} carga  - carga da rota
         * @description cria o marcador e já adiciona ele na array de controle
         * dos pontos no mapa. Os marcadores são criados com o
         * atribudo rocodigo, que é o código da rota que eles pertencem,
         * então para fazer o controle e retirar * eles no mapa basta
         * usar esse atributo.
         * @author Otávio 🦆          */
        criarMarcadorCargaMapa(carga){
            if (!carga.ponto) return;
            let marker = criarMarcadorCarga(carga);
            this.addToMapa(marker);
            marker = definePropsMarcadorCarga(marker, carga);
            this.pontosOnMapa.push(marker);

        },

        // RASTRO GRUPO DE VEÍCULOS.

        /**
         * @param {object[]} rastros - todos os objetos, sendo sempre:
         * {placa: rastro}
         * @param {boolean}  reset   - se é necessário limpar as rotas
         * @description cria os rastros de todos os veículos de um grupo.
         * @author Gui 🧟
         */
        desenharRastroDeGrupo(rastros, reset) {
            if (reset) this.limparRastrosGrupo();
            for (var [placa, rastro] of Object.entries(rastros)) {
                var decodePoly = require("@mapbox/polyline");
                let prefixo = this.markerVeiculos.filter((e) => e.placa == placa);
                prefixo = prefixo && prefixo[0] ? prefixo[0].prefixo : "";
                if (rastro.length) {
                    var arrRastro = decodePoly.decode(rastro);
                    this.criaPirulitoRastroGrupo(arrRastro);
                    this.criaPoliRastroGrupo(placa, prefixo, arrRastro);
                }
            }
        },

        /**
         * @param {string} placa
         * @param {number[][]} arrRastro
         * @description cria o pirulito que aparece no começo
         * do rastro dos veículos.
         * @author Gui 🧟
         */
        criaPirulitoRastroGrupo(arrRastro) {
            let marker = criarMarkerRastroGrupo(arrRastro[0]);
            this.polisOnMapa.push(marker);
            this.addToMapa(marker);
        },

        /**
         * @param {string} placa - veículo a qm o rastro pertence.
         * @param {number[][]} rastro - array com as coordenadas do rastro.
         * @description prepara tanto o rastro quanto a legenda do mesmo.
         */
        criaPoliRastroGrupo(placa, prefixo, rastro) {
            var existe = this.legendaRastros.findIndex((l) => {
                return l.titulo === placa;
            });
            if (existe >= 0) this.legendaRastros.splice(existe, 1);
            if (rastro.length === 0) return;
            let obj = criarPolylineRastroGrupo(rastro, placa, prefixo);
            this.legendaRastros.push(obj.legenda);
            this.polisOnMapa.push(obj.polyline);
            var mapa = this.$refs.mapaPrincipal.returnMapObject();
            mapa.addLayer(obj.polyline);
        },

        /**
         * @listens limpar-rastros-gv
         * @description remove todas as polis que tenham a
         * flag de rastro de grupo.
         */
        limparRastrosGrupo() {
            this.legendaRastros = [];
            this.polisOnMapa.forEach((r) => {
                if (r.rastroGrupo) r.remove();
            });
        },

        addToMapa(object) {
            if (!this.$refs.mapaPrincipal) return;
            let mapa = this.$refs.mapaPrincipal.returnMapObject();
            if (!mapa) return;
            object.addTo(mapa);
        },

        getEmpresas() {
            try {
                this.optClientes = new EmpresasService().Get() ?? [];
            } catch (e) {
                this.optClientes = [];
            }
        },
    },

    computed: {
        // markerVeiculosFiltrados() {
        //     var filtros = this.filtroVeiculos;
        //     if (this.atividade) {
        //         filtros = [""];
        //     }
        //     if (filtros.length)
        //         return this.markerVeiculos.filter((mv) => {
        //             return filtros.includes(mv.placa);
        //         });
        //     else return this.markerVeiculos;
        // },

        qtdPontosOnMapa() {
            var n1 = 0,
                n2 = 0;
            if (this.polisOnMapa.length === 0 || this.markerPontos.length > 0) {
                n1 = this.pontosOnMapa.length;
                n2 = this.markerPontos.length;
            }
            return n1 + n2;
        },

        verveiculos() {
            if (typeof this.userPermission?.mapveiculos === "undefined") return false;
            return this.userPermission.mapveiculos.ppvisualizar;
        },
    },

    watch: {
        /**
         * @description quando o marcador de veículo selecionado é
         * atualizado, esse watcher redireciona o mapa
         * para o novo local do marker
         */
        "marcadorSelecionado.latitude"() {
            if (this.seguindoVeiculo) {
                var position = [
                    this.marcadorSelecionado.latitude,
                    this.marcadorSelecionado.longitude,
                ];
                this.arrayPosicoes.push(position);
                this.$refs.mapaPrincipal.flyTo(position, 17);
            } else this.arrayPosicoes = [];
        },

        arrayPosicoes(nValue) {
            if (!this.seguindoVeiculo) return;
            var poli = this.polisOnMapa.find((el) => {
                return el.seguir_veiculo === this.marcadorSelecionado.veiculoId;
            });
            if (typeof poli === "undefined") return;
            poli.addLatLng(nValue.at(-1));
        },

        clienteSelecionado(nv) {
            this.buscaOptVeiculos();
            this.limpaRegioesOnMapa();
            this.$refs.menuMapa.resetMenu();
        },

        queryRegiaoUser(n) {
            if (n === "") this.limpaBuscaRegiao(true);
        },

        veiculoSelecionado(newValue, oldValue) {
            if (newValue) {
                this.placaVeiculoSelecionado = this.marcadorSelecionado.placa;
            }
            else {
                this.placaVeiculoSelecionado = "";
            }
        },
        atividade(value) {
            if (value) {
                this.limparClusterVeiculos(false);
            } else {
                this.allVeiculos(this.markerVeiculos);
            }
        },
        filtroVeiculos(value) {
            if (value && value.length) {
                this.markerVeiculos.forEach((e) => {
                    if (!value.includes(e.placa)) {
                        this.$refs.mapVeiculos.removeLayer(e.marker);
                        // e.marker.remove()
                    }
                });
            }
            // else {
            //     this.allVeiculos(this.markerVeiculos)
            // }
        },
    },

    // beforeDestroy() {
    //     // this.clienteSelecionado = []
    //     // this.mudaCliente([])
    // },

    mounted() {
        this.limpaCache();
        this.$refs.mapaPrincipal.flyTo(this.latlngUSer, 15);
        this.getEmpresas();
    },

    /**
     * @param {object} routeTo   - Pra onde estamos indo
     * @param {object} routeFrom - De onde estamos vindo
     * @param {function} next    - executar navegação
     * @description executado antes da página ser montada,
     * a função não tem acesso ao "this", a função de callback dentro
     * do next() é como eu passo as informações para o mapa.
     * Procura as permissões do usuário
     * @author Gui 🍺
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        NProgress.start();
        new HttpRequest()
            .GetPermissions()
            .then((data) => {
                next((vm) => {
                    vm.userPermission = data.data.permissoes;
                    vm.latlngUSer = [
                        data.data.latlng.cllatitude,
                        data.data.latlng.cllongitude,
                    ];
                });
            })
            .catch(() => {
                conectionError();
            })
            .finally(() => NProgress.done());
    },
    setup() {
        // Tudo o que for retornado pela função setup() ficará
        // disponível no template do componente. Isso é útil para
        // melhorar a organização do código e evitar a poluição do
        // escopo do componente. Assim, conseguimos separar lógicas
        // de negócio e reaproveitar lógicas em outros componentes.
        return {
            telemetry: useTelemetry(),
        }
    }
});
</script>
<style>
.flex-container {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.visualizar-viagens {
    flex: 1;
}

.styleDivGrafico {
    position: relative !important;
    bottom: 0 !important;
}

.fixed-bottom {
    pointer-events: none;
}

.divPainelMapa {
    pointer-events: auto;
}
</style>
<style lang="scss">
@import "./Mapa.scss";
</style>