<template>
    <div>
        <div class="popup-container" :id="`popuppontorota${carga.cacodigo}`">
            <div class="popup-header">
                <strong>Carga: <span class="carga-codigo" :style="{ backgroundColor: randomColor }">{{ carga.cacodigo
                        }}</span></strong>
            </div>
            <div class="popup-content">
                <div>
                    <strong>Ponto:</strong> {{ carga.ponto.podescricao }}
                </div>
                <div>
                    <span><strong>Peso:</strong> {{ formatNumber(carga.capeso, 2) }} kg</span>
                    <span class="ml-3"><strong>Cubagem:</strong> {{ roundCubagem(carga.cacubagem) }} m³ </span>
                </div>
                <div>
                    <strong>Valor:</strong> R$: {{ formatNumber(carga.cavalor, 2) }}
                </div>
                <div class="quantidade-container d-flex justify-content-start">
                    <div class="quantidade-pedido">
                        <strong>Qtd. Pedido:</strong> {{ carga.catotalitem }}
                    </div>
                    <div class="quantidade-entrega">
                        <strong>Qtd. Entrega:</strong> {{ carga.caentrega }}
                    </div>
                </div>

                <div>
                    <strong>Obs:</strong> {{ carga.cainformacaoadicional || ' ' }}
                </div>
            </div>
        </div>
    </div>
</template>
  
  <script>
import { DateTime } from 'luxon'
export default {
    props: {
        carga: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            randomColor: this.randomColors()
        };
    },
    methods: {
        /**

        * Formata um número para o número especificado de casas decimais.
        *
        * @param { number } value - O valor numérico a ser formatado.
        * @param { number } decimals - O número de casas decimais para o arredondamento.
        * @returns { string | number } - O valor formatado como string ou o valor original se não for um número.
        * @author Otávio 🦆
        */
        formatNumber(value, decimals) {
            if (typeof value === 'number') {
                return value.toFixed(decimals);
            }
            return value !== undefined && value !== null ? value : '';
        },

        /**
         * Gera uma cor hexadecimal aleatória.
         *
         * @returns {string} - A cor gerada no formato hexadecimal.
         * @author Otávio 🦆
         */
        randomColors() {
            const letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },

        /**
         * Arredonda o valor de cubagem para cima e formata para duas casas decimais.
         *
         * @param {number} value - O valor da cubagem a ser arredondado.
         * @returns {string} - O valor arredondado e formatado com duas casas decimais.
         * @author Otávio 🦆
         */
        roundCubagem(value) {
            let rounded = Math.ceil(value * 100) / 100;
            return rounded.toFixed(2);
        }
    }
}

</script>
  
<style scoped>
.popup-container {
    background-color: #ffffff;
    padding: 10px;
    font-family: Arial, sans-serif;
    min-height: 100px;
    min-width: 270;
    height: auto;
}

.popup-header {
    margin-bottom: 5px;
}

.carga-codigo {
    color: #fff;
    padding: 2px 4px;
    border-radius: 3px;
}

.quantidade-container {
    margin-top: 10px;
}


.quantidade-entrega {
    margin-left: 10px;
}


.popup-content strong {
    margin-right: 5px;
}

.popup-content div {
    margin-bottom: 5px;
}
</style>