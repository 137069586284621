Componente para fazer a 'tooltip' dentro do marcador do mapa 
Não possue eventos. 
slots: 
 sem nome - Qualquer conteudo pode ser posto aqui, para usar mais de um componente dentro do slot 
 pode ser necessário usar a tag 'template'

sim, eu copiei a documentação da pop up
<template>
    <l-tooltip>
      <slot><span>Oi gatinha, tudo bem?</span></slot>
    </l-tooltip>
</template>

<script>
  import Vue from 'vue'
  import {LTooltip} from 'vue2-leaflet'
  export default Vue.extend({
    name:'tooltipMarcador',
    components: {
      LTooltip,
    },
  })
</script>
