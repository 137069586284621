<template>
    <panelEagle id="listagemChecklist" :loading='loadingPanel'>
        <div class="col-sm-12 nopadding ">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiOrderBoolAscendingVariant" titulo="Checklist"></tituloPage>
                </div>
                <div class="col-sm-6 divDontPrint">
                    <basicButtonsCadastrosEIN
                        tipoNovo          ='button'
                        linkNew           ="cadastrarChecklist"
                        :disabledDropdown ="$v.dadosListagem.$invalid"
                        :loading          ="loadingBotao"
                        @exportarCadastro ="exportarChecklist"
                        >
                    </basicButtonsCadastrosEIN>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-4 nopadding divDontPrint">
                    <selectAll 
                        :isMultiple="true" 
                        nameForRadio="empresasCadTipoManutencao" 
                        :hasSelectAll="true" 
                        :labels="[{indexDFH: 'EM', description: 'Empresa'}]" 
                        :optionsArray="optionsArrayEmpresas"
                        :selected="selectedEmpresa"
                        firstSelected="EM"
                        @changeSelect="changeEmpresa" 
                        >
                    </selectAll>
                </div>
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <tableListagem 
            :titles          ="titles"
            :data            ='dadosListagem'
            deleteEdit       ="editDelete"
            @editarCadastro  ="editarCheckList" 
            @excluirCadastro ="deletarChecklist"
            >
        </tableListagem>
    </panelEagle>
</template>

<script>
import { mdiOrderBoolAscendingVariant } from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service';
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { conectionError } from '@/Services/Helpers/swellHeper'
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex'
import Vue from 'vue'
export default Vue.extend({
    name:'listagemChecklist',
    components:{
        'selectAll'                : require('@/components/Atom/Select/SelectAll').default,
        'panelEagle'               : require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage'               : require('@/components/Atom/Header/Titulo').default,
        'tableListagem'            : require('@/components/Atom/Table/TableListagem').default,
        'basicButtonsCadastrosEIN' : require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
    },

    validations:{
        dadosListagem   : { required },
    },

    data() {
        return {
            optionsArrayEmpresas : new EmpresasService().Get(),
            selectedEmpresa:[],
            mdiOrderBoolAscendingVariant: mdiOrderBoolAscendingVariant,
            titles:[
                {'name': 'Ordem', 'number': 'codigo'},
                {'name': 'Descrição', 'number': 'descricao'},
                {'name': 'Tipo', 'number': 'tipo'},
            ],
            uriBase:'/manutencoes/cadastros/checklist/',
            loadingPanel       : false,
            dadosListagem      : [],
            empresaSelecionada : [],
            loadingBotao       : [false, false, false],
        }
    },

    methods: {
        ...mapGetters(['getMaster']),
        changeEmpresa(empresa){
            this.empresaSelecionada = empresa
            this.listagem()
        },

        listagem(){
            this.loadingPanel = true
            new HttpRequest().Post(this.uriBase+'listar', {'empresa':this.empresaSelecionada})
                .then((dados=>{
                    if(dados.code == 200){
                        this.dadosListagem = dados.data.dados
                        this.loadingPanel = false
                    } else {
                        this.dadosListagem = []
                        this.loadingPanel = false
                    }
                }))
        },

        exportarChecklist(tipo){
            switch(tipo){
                    case 'pdf':
                        this.loadingBotao = [true, false, false]
                        break;
                    case 'xls':
                        this.loadingBotao = [false, true, false]
                        break;
                    case 'csv':
                        this.loadingBotao = [false, false, true]
                        break;
                    default:
                        break;
                }
            var root =  process.env.VUE_APP_ROOT;
                new HttpRequest().Post(this.uriBase+'exportar', {'arrayDados':this.dadosListagem, 'tipo':tipo})
                    .then((dados => {   
                        window.open(root+'/'+dados.data.local);
                        this.loadingBotao = [false, false, false]
                    }))
        },

        editarCheckList(codigo){
            this.$router.push({name:'cadastrarChecklist', params:{id:codigo}})
        },

        deletarChecklist(codigo){
            new HttpRequest().Post(this.uriBase+'deletar', {'id':codigo})
                .then((dados=>{
                    if(dados.code == 200){
                        this.listagem()
                    } else {
                        conectionError()
                    }
                }))
        },
    },
    mounted(){
        if(!this.getMaster()){
            this.selectedEmpresa = this.optionsArrayEmpresas
        }
    }
})
</script>