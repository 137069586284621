<template>
    <panelEagle id="folhaPonto" :loading="loadingPanel">
        <!-- Tabela datelhada -->
        <div v-show="muda">
            <div class="col-sm-12 nopadding">
                <div class="col-sm-12 nopadding row">
                    <div class="col-sm-5">
                        <tituloPage :icon="mdiCardAccountDetails" titulo="Folha Ponto">
                        </tituloPage>
                    </div>
                    <div class="divDontPrint col-sm-7 divButtonsBasic">
                        <basicButtonsRelatoriosEIG
                            @gerarRelatorio="gerarRelatorioDetalhado"
                            @exportarRelatorio="exportarRelatorioDetalhado"
                            :loading="loadingExportar"
                            :disabled="$v.colabSelecionado.$invalid"
                            :disabledDropdown="$v.relatorio.$invalid"
                        >
                            <template>
                                <simpleButton
                                    text="Detalhado"
                                    :type="'green'"
                                    event="mudar"
                                    :width="'110px'"
                                    :icon="mdiCheckBold"
                                    @mudar="muda = !muda"
                                >
                                </simpleButton>
                                <simpleButton
                                    text="Resumido"
                                    :type="'grey'"
                                    event="mudar"
                                    :width="'110px'"
                                    @mudar="muda = !muda"
                                >
                                </simpleButton>
                            </template>
                        </basicButtonsRelatoriosEIG>
                    </div>
                </div>
                <slideUpAndDown>
                    <template slot="filters">
                        <div class="col-sm-12"><hr /></div>
                        <div class="col-sm-12 justpaddingbottom row">
                            <!-- Input de data -->
                            <div class="col-sm-3 nopadding">
                                <inputRangeWithLimit
                                    name="inputDataJornadaTrabalho"
                                    opens="right"
                                    :value="valueIntervalo"
                                    :isObrigatorio="true"
                                    @changeInput="changeDataIntervalo"
                                >
                                </inputRangeWithLimit>
                            </div>
                            <!-- Select de empresas -->
                            <div class="col-sm-3 nopadding">
                                <selectAll
                                    nameForRadio="radiosEmpresas"
                                    :isMultiple="true"
                                    :labels="labelsSelectEmpresa"
                                    :optionsArray="optionsSelectEmpresa"
                                    :selected="selectedEmpresa"
                                    firstSelected="E"
                                    :hasSelectAll="true"
                                    @changeSelect="changeEmpresa"
                                >
                                </selectAll>
                            </div>
                            <!-- Select Jornada -->
                            <div class="col-sm-3 nopadding">
                                <selectAll
                                    ref="selectJ"
                                    nameForRadio="radiosJornada"
                                    firstSelected="E"
                                    :labels="labelsSelectJornada"
                                    :optionsArray="optionsSelectJ"
                                    :selected="selectedJornada"
                                    :isMultiple="false"
                                    :hasSelectAll="false"
                                    :disabled="$v.empresasSelecionadas.$invalid"
                                    :loading="carregandoJornada"
                                    @changeSelect="changeJornada"
                                >
                                </selectAll>
                            </div>
                            <!-- Select Colaboradores -->
                            <div class="col-sm-3 nopadding">
                                <selectAll
                                    ref="selectCG"
                                    nameForRadio="seletorColaboradorGrupo"
                                    firstSelected="C"
                                    :hasSelectAll="false"
                                    :isMultiple="false"
                                    :optionsArray="optionsSelectCGC"
                                    :selected="selectedColabs"
                                    :labels="labelsSelectColaboradores"
                                    :disabled="$v.jornadaSelecionada.$invalid"
                                    :loading="carregando"
                                    @changeSelect="changeColaboradores"
                                />
                            </div>
                        </div>
                        <!-- Tabela de sumário -->
                        <div class="col-sm-12 justpaddingbottom row">
                            <!-- Botões de Banco de horas e Ajustes -->
                            <div class="col-sm-3 row divBotoesBancoAjustes">
                                <div class="col-xl-6 col-lg-12 nopadding">
                                    <simpleButton
                                        class="buttonBancoHoras"
                                        text="Banco de horas"
                                        width="100%"
                                        event="gerar"
                                        :disabled="$v.relatorio.$invalid"
                                        @gerar="modalBanco"
                                    >
                                    </simpleButton>
                                </div>
                                <div class="col-xl-6 col-lg-12 nopadding">
                                    <simpleButton
                                        class="buttonAjustes"
                                        text="Ajustes"
                                        width="100%"
                                        event="gerar"
                                        @gerar="modalAjustes()"
                                    >
                                    </simpleButton>
                                </div>
                            </div>
                        </div>
                    </template>
                </slideUpAndDown>
                <div class="col-sm-12"><hr /></div>
                <div v-if="temInsercao" class="insersaoLegendaTabelaDetalhada">
                    <span>(*) Indica inserção de horas</span>
                </div>
                <!-- Tabela Detalhada -->
                <tableRelatorio>
                    <!-- Cabeçalho -->
                    <template slot="thead">
                        <tr class="trCabecalhoFolhaPonto">
                            <!-- Placa -->
                            <th class="thCabecalhoFolhaPontoPlaca" rowspan="2">Placa</th>
                            <th :colspan="contColspanJornada">Jornada</th>
                            <th :colspan="contColspanExtras">Extras</th>
                            <th v-if="detalhesJornada" colspan="3">Em direção</th>
                            <th v-if="detalhesJornada" colspan="3">Parada em jornada</th>
                            <th v-if="detalhesEspera" colspan="3">Em espera</th>
                            <th v-if="detalhesIntervalo" colspan="3">Em intervalo</th>
                            <th rowspan="2">Interjorn.</th>
                            <th rowspan="2" style="min-width: 50px !important">
                                Folga Banco de Horas
                            </th>
                        </tr>
                        <tr
                            :class="{ trCabecalhoDetalhesNulo: nulo }"
                            class="trCabecalhoDetalhes"
                        >
                            <!-- Jornada -->
                            <th>Início</th>
                            <th>Fim</th>
                            <th>Tempo</th>
                            <th>H.Normal</th>
                            <th>Espera</th>
                            <th>Intervalo</th>
                            <th>Falta</th>
                            <th v-if="detalhesTempoExtra">Tempo Extra</th>
                            <th>Noturna</th>
                            <th v-if="detalhesReduzida">Reduzida</th>
                            <!-- Extras -->
                            <th v-if="extrasParametros[1]">H.E.1</th>
                            <th v-if="extrasParametros[2]">H.E.2</th>
                            <th v-if="extrasParametros[3]">H.E.3.</th>
                            <th>100%</th>
                            <th>Not.</th>
                            <th>100% Not.</th>
                            <!-- Em direção -->
                            <th v-if="detalhesJornada">Início</th>
                            <th v-if="detalhesJornada">Fim</th>
                            <th v-if="detalhesJornada">Tempo</th>
                            <!-- Parada em jornada -->
                            <th v-if="detalhesJornada">Início</th>
                            <th v-if="detalhesJornada">Fim</th>
                            <th v-if="detalhesJornada">Tempo</th>
                            <!-- Em espera -->
                            <th v-if="detalhesEspera">Início</th>
                            <th v-if="detalhesEspera">Fim</th>
                            <th v-if="detalhesEspera">Tempo</th>
                            <!-- Em intervalo -->
                            <th v-if="detalhesIntervalo">Início</th>
                            <th v-if="detalhesIntervalo">Fim</th>
                            <th v-if="detalhesIntervalo">Tempo</th>
                        </tr>
                    </template>
                    <template v-if="nulo == true" slot="tbody">
                        <statusInformation
                            typeBar="tr"
                            :colspanForTd="contColspanTotal"
                            :statusBar="statusbar"
                        >
                        </statusInformation>
                    </template>
                    <!-- Corpo da tabela -->
                    <template v-else slot="tbody">
                        <template v-for="(item, k) in relatorio">
                            <!-- Linha do nome -->
                            <tr :key="k + '_nome'" v-if="k != 'extrasParametros'">
                                <td
                                    class="fundoDataDetalhado"
                                    :colspan="contColspanTotal"
                                >
                                    <span class="dataDetalhado">
                                        <span class="tamanhoDataDetalhado">
                                            {{ k }}
                                        </span>
                                        <span
                                            event="click"
                                            @click="modalAcoes(item)"
                                            class="iconModalAcoes"
                                        >
                                            <baseIcon :icon="mdiTimetable" size="18" />
                                        </span>
                                    </span>
                                </td>
                            </tr>
                            <tr
                                :key="k + '_jornada'"
                                v-if="true"
                                class="trTabelaDetalhada"
                                colspan="20"
                            >
                                <!-- Placa -->
                                <td
                                    class="tdTabelaPlaca"
                                    :colspan="defineColspanTrDetalhado(item)"
                                    style="min-width: 70px"
                                >
                                    <div v-if="item.placa">
                                        {{ item.placa }}
                                    </div>
                                    <div
                                        v-if="item.observacao"
                                        :class="{ textRed: item.temObservacao }"
                                    >
                                        {{ item.observacao }}
                                    </div>
                                    <div
                                        v-if="!item.placa && !item.observacao"
                                        style="height: 15px !important"
                                    ></div>
                                    <div v-if="ehDsr(item, k)" class="texto-dsr">DSR</div>
                                </td>
                                <td
                                    v-if="item.inicio == '' && item.folgaBH"
                                    class="tdTabelaDetalhadaColunas"
                                >
                                    {{ item.folgaBH }}
                                </td>
                                <template v-if="item.inicio != ''">
                                    <!-- jornada 
                                        São feitas verificações para deixar o texto vermelho -->
                                    <td
                                        v-if="item.inicio != item.fim"
                                        :id="`${item.placa}_inicio_jornada`"
                                        class="tdTabelaDetalhadaColunas"
                                    >
                                        {{ item.inicio }}
                                    </td>
                                    <td
                                        v-if="item.inicio != item.fim"
                                        :id="`${item.placa}_fim_jornada`"
                                        class="tdTabelaDetalhadaColunas"
                                    >
                                        {{ item.fim }}
                                    </td>
                                    <td
                                        class="tdTabelaDetalhadaColunas"
                                        :class="{ textRed: item.temInsercao }"
                                    >
                                        {{
                                            item.tempo
                                                ? item.tempo +
                                                  (item.temInsercao ? "*" : "")
                                                : ""
                                        }}
                                    </td>
                                    <td
                                        class="tdTabelaDetalhadaColunas"
                                        :class="{ textRed: item.temInsercao }"
                                    >
                                        {{
                                            item.tempoValido
                                                ? item.tempoValido +
                                                  (item.temInsercao ? "*" : "")
                                                : ""
                                        }}
                                    </td>
                                    <td class="tdTabelaDetalhadaColunas">
                                        {{ item.tempoEspera }}
                                    </td>
                                    <td class="tdTabelaDetalhadaColunas">
                                        {{ item.intervalo }}
                                    </td>
                                    <td
                                        class="tdTabelaDetalhadaColunas"
                                        :class="{
                                            textRed: item.temInsercao || item.temFalta,
                                        }"
                                    >
                                        {{
                                            item.falta
                                                ? item.falta +
                                                  (item.temInsercao || item.temFalta
                                                      ? "*"
                                                      : "")
                                                : ""
                                        }}
                                    </td>
                                    <td
                                        v-if="detalhesTempoExtra"
                                        class="tdTabelaDetalhadaColunas"
                                        v-text="item.tempoExtra"
                                    ></td>
                                    <td class="tdTabelaDetalhadaColunas">
                                        {{ item.adicionalNoturno }}
                                    </td>
                                    <td
                                        v-if="detalhesReduzida"
                                        class="tdTabelaDetalhadaColunas"
                                    >
                                        {{ item.reduzida }}
                                    </td>
                                    <!-- Extras fazemos uma verificação de se o cliente optou por mostrar os extras -->
                                    <td
                                        v-if="extrasParametros[1]"
                                        class="tdTabelaDetalhadaColunas"
                                        :class="{ textRed: item.temInsercao }"
                                    >
                                        {{
                                            item.extra1
                                                ? item.extra1 +
                                                  (item.temInsercao ? "*" : "")
                                                : ""
                                        }}
                                    </td>
                                    <td
                                        v-if="extrasParametros[2]"
                                        class="tdTabelaDetalhadaColunas"
                                        :class="{ textRed: item.temInsercao }"
                                    >
                                        {{
                                            item.extra2
                                                ? item.extra2 +
                                                  (item.temInsercao ? "*" : "")
                                                : ""
                                        }}
                                    </td>
                                    <td
                                        v-if="extrasParametros[3]"
                                        class="tdTabelaDetalhadaColunas"
                                        :class="{ textRed: item.temInsercao }"
                                    >
                                        {{
                                            item.extra3
                                                ? item.extra3 +
                                                  (item.temInsercao ? "*" : "")
                                                : ""
                                        }}
                                    </td>
                                    <td
                                        class="tdTabelaDetalhadaColunas"
                                        :class="{
                                            textRed: item.temInsercao || item.temFalta,
                                        }"
                                    >
                                        {{
                                            item.extra100
                                                ? item.extra100 +
                                                  (item.temInsercao || item.temFalta
                                                      ? "*"
                                                      : "")
                                                : ""
                                        }}
                                    </td>
                                    <td class="tdTabelaDetalhadaColunas">
                                        {{ item.extraNoturna }}
                                    </td>
                                    <!-- Extra 100% Noturno -->
                                    <td class="tdTabelaDetalhadaColunas">
                                        {{ item.extra100not }}
                                    </td>
                                    <!-- Em direcao -->
                                    <template v-if="detalhesJornada && !item.detalhes.T">
                                        <td
                                            colspan="3"
                                            class="tdTabelaDetalhadaColunas"
                                        ></td>
                                    </template>
                                    <template
                                        v-else-if="detalhesJornada && item.detalhes.T"
                                    >
                                        <td class="tdTabelaDetalhadaColunas nopadding">
                                            <div
                                                v-for="(item, k) in item.detalhes.T
                                                    .detalhes"
                                                :key="k + 'ji'"
                                                v-show="k != 'total'"
                                                class="tdTabelaDetalhada"
                                            >
                                                {{ item.inicio }}
                                            </div>
                                        </td>
                                        <td class="tdTabelaDetalhadaColunas nopadding">
                                            <div
                                                v-for="(item, k) in item.detalhes.T
                                                    .detalhes"
                                                :key="k + 'jf'"
                                                v-show="k != 'total'"
                                                class="tdTabelaDetalhada"
                                            >
                                                {{ item.fim }}
                                            </div>
                                        </td>
                                        <td class="tdTabelaDetalhadaColunas nopadding">
                                            <div
                                                v-for="(item, k) in item.detalhes.T
                                                    .detalhes"
                                                :key="k + 'jt'"
                                                v-show="k != 'total'"
                                                class="tdTabelaDetalhada"
                                            >
                                                {{ item.tempo }}
                                            </div>
                                        </td>
                                    </template>
                                    <!-- Parado em Jornada -->
                                    <template v-if="detalhesJornada && !item.detalhes.P">
                                        <td
                                            colspan="3"
                                            class="tdTabelaDetalhadaColunas"
                                        ></td>
                                    </template>
                                    <template
                                        v-else-if="detalhesJornada && item.detalhes.P"
                                    >
                                        <td class="tdTabelaDetalhadaColunas nopadding">
                                            <div
                                                v-for="(item, k) in item.detalhes.P
                                                    .detalhes"
                                                :key="k + 'ji'"
                                                v-show="k != 'total'"
                                                class="tdTabelaDetalhada"
                                            >
                                                {{ item.inicio }}
                                            </div>
                                        </td>
                                        <td class="tdTabelaDetalhadaColunas nopadding">
                                            <div
                                                v-for="(item, k) in item.detalhes.P
                                                    .detalhes"
                                                :key="k + 'jf'"
                                                v-show="k != 'total'"
                                                class="tdTabelaDetalhada"
                                            >
                                                {{ item.fim }}
                                            </div>
                                        </td>
                                        <td class="tdTabelaDetalhadaColunas nopadding">
                                            <div
                                                v-for="(item, k) in item.detalhes.P
                                                    .detalhes"
                                                :key="k + 'jt'"
                                                v-show="k != 'total'"
                                                class="tdTabelaDetalhada"
                                            >
                                                {{ item.tempo }}
                                            </div>
                                        </td>
                                    </template>
                                    <!-- Espera -->
                                    <template v-if="detalhesEspera && !item.detalhes.E">
                                        <td
                                            colspan="3"
                                            class="tdTabelaDetalhadaColunas"
                                        ></td>
                                    </template>
                                    <template
                                        v-else-if="detalhesEspera && item.detalhes.E"
                                    >
                                        <td class="tdTabelaDetalhadaColunas nopadding">
                                            <div
                                                v-for="(item, k) in item.detalhes.E
                                                    .detalhes"
                                                :key="k + 'ji'"
                                                v-show="k != 'total'"
                                                class="tdTabelaDetalhada"
                                            >
                                                {{ item.inicio }}
                                            </div>
                                        </td>
                                        <td class="tdTabelaDetalhadaColunas nopadding">
                                            <div
                                                v-for="(item, k) in item.detalhes.E
                                                    .detalhes"
                                                :key="k + 'jf'"
                                                v-show="k != 'total'"
                                                class="tdTabelaDetalhada"
                                            >
                                                {{ item.fim }}
                                            </div>
                                        </td>
                                        <td class="tdTabelaDetalhadaColunas nopadding">
                                            <div
                                                v-for="(item, k) in item.detalhes.E
                                                    .detalhes"
                                                :key="k + 'jt'"
                                                v-show="k != 'total'"
                                                class="tdTabelaDetalhada"
                                            >
                                                {{ item.tempo }}
                                            </div>
                                        </td>
                                    </template>
                                    <!-- Intervalo -->
                                    <template
                                        v-if="detalhesIntervalo && !item.detalhes.I"
                                    >
                                        <td
                                            colspan="3"
                                            class="tdTabelaDetalhadaColunas"
                                        ></td>
                                    </template>
                                    <template
                                        v-else-if="detalhesIntervalo && item.detalhes.I"
                                    >
                                        <td class="tdTabelaDetalhadaColunas nopadding">
                                            <div
                                                v-for="(item, k) in item.detalhes.I
                                                    .detalhes"
                                                :key="k + 'ji'"
                                                v-show="k != 'total'"
                                                class="tdTabelaDetalhada"
                                            >
                                                {{ item.inicio }}
                                            </div>
                                        </td>
                                        <td class="tdTabelaDetalhadaColunas nopadding">
                                            <div
                                                v-for="(item, k) in item.detalhes.I
                                                    .detalhes"
                                                :key="k + 'jf'"
                                                v-show="k != 'total'"
                                                class="tdTabelaDetalhada"
                                            >
                                                {{ item.fim }}
                                            </div>
                                        </td>
                                        <td class="tdTabelaDetalhadaColunas nopadding">
                                            <div
                                                v-for="(item, k) in item.detalhes.I
                                                    .detalhes"
                                                :key="k + 'jt'"
                                                v-show="k != 'total'"
                                                class="tdTabelaDetalhada"
                                            >
                                                {{ item.tempo }}
                                            </div>
                                        </td>
                                    </template>
                                    <td class="tdTabelaDetalhadaColunas">
                                        {{ item.interjornada }}
                                    </td>
                                    <td class="tdTabelaDetalhadaColunas">
                                        {{ item.folgaBH }}
                                    </td>
                                </template>
                            </tr>
                            <!-- Total que aparece no fim de cada dia 
                                É feito uma verificação para que apareça 
                                somente o total correspondente ao evento -->
                            <tr
                                :key="k + '_Total'"
                                class="trTotalizadorDia"
                                v-if="
                                    item.detalhes.length != 0 &&
                                    (detalhesJornada ||
                                        detalhesEspera ||
                                        detalhesIntervalo)
                                "
                            >
                                <td :colspan="contColspanTotaisDia"></td>
                                <!-- Direção -->
                                <template v-if="detalhesJornada && !item.detalhes.T">
                                    <td colspan="3" class="tdTabelaDetalhada"></td>
                                </template>
                                <template v-else-if="detalhesJornada && item.detalhes.T">
                                    <td colspan="2" class="totalTabelaDetalhada">
                                        Total:
                                    </td>
                                    <td class="dadosTabelaDetalhada">
                                        {{ item.detalhes.T.total }}
                                    </td>
                                </template>
                                <!-- Parada -->
                                <template v-if="detalhesJornada && !item.detalhes.P">
                                    <td colspan="3" class="tdTabelaDetalhada"></td>
                                </template>
                                <template v-else-if="detalhesJornada && item.detalhes.P">
                                    <td colspan="2" class="totalTabelaDetalhada">
                                        Total:
                                    </td>
                                    <td class="dadosTabelaDetalhada">
                                        {{ item.detalhes.P.total }}
                                    </td>
                                </template>
                                <!-- Espera -->
                                <template v-if="detalhesEspera && !item.detalhes.E">
                                    <td colspan="3" class="tdTabelaDetalhada"></td>
                                </template>
                                <template v-else-if="detalhesEspera && item.detalhes.E">
                                    <td colspan="2" class="totalTabelaDetalhada">
                                        Total:
                                    </td>
                                    <td class="dadosTabelaDetalhada">
                                        {{ item.detalhes.E.total }}
                                    </td>
                                </template>
                                <!-- Intervalo -->
                                <template v-if="detalhesIntervalo && !item.detalhes.I">
                                    <td colspan="3" class="tdTabelaDetalhada"></td>
                                </template>
                                <template
                                    v-else-if="detalhesIntervalo && item.detalhes.I"
                                >
                                    <td colspan="2" class="totalTabelaDetalhada">
                                        Total:
                                    </td>
                                    <td class="dadosTabelaDetalhada">
                                        {{ item.detalhes.I.total }}
                                    </td>
                                </template>
                                <!-- Últimas 2 colunas -->
                                <td colspan="2"></td>
                            </tr>
                        </template>
                    </template>
                    <!-- Totalizadores -->
                    <template v-if="!nulo" slot="tbody">
                        <tr key="_separador">
                            <td
                                class="fundoDataDetalhado"
                                :colspan="contColspanTotal"
                            ></td>
                        </tr>
                        <tr class="trTotalizadoresTabelaDetalhada">
                            <td :colspan="contColspanTotal" class="tituloTotaisDetalhado">
                                Eventos folha
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" class="subtituloTotaisFP">Hora Normal</td>
                            <td
                                colspan="2"
                                class="totalizadorFP"
                                :class="{ textRed: temInsercao }"
                            >
                                {{
                                    totalizadores.horaNormal
                                        ? totalizadores.horaNormal +
                                          (temInsercao ? "*" : "")
                                        : ""
                                }}
                            </td>
                            <td colspan="2" class="subtituloTotaisFP">Total em espera</td>
                            <td colspan="2" class="totalizadorFP">
                                {{ totalizadores.espera }}
                            </td>
                            <td colspan="3" class="subtituloTotaisFP">
                                Folga banco de horas
                            </td>
                            <td colspan="2" class="totalizadorFP">
                                {{
                                    totalizadores.folgaBH
                                        ? totalizadores.folgaBH
                                        : "00:00:00"
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" class="subtituloTotaisFP">Falta</td>
                            <td
                                colspan="2"
                                class="totalizadorFP"
                                :class="{ textRed: temInsercao || temFalta }"
                            >
                                {{
                                    totalizadores.falta
                                        ? totalizadores.falta +
                                          (temInsercao || temFalta ? "*" : "")
                                        : ""
                                }}
                            </td>
                            <td colspan="2" class="subtituloTotaisFP">Horas noturnas</td>
                            <td colspan="2" class="totalizadorFP">
                                {{ totalizadores.noturnas }}
                            </td>
                            <td colspan="3" class="subtituloTotaisFP">
                                Hora noturna reduzida
                            </td>
                            <td colspan="2" class="totalizadorFP">
                                {{ totalizadores.reduzidas }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" class="subtituloTotaisFP">Extra noturnas</td>
                            <td colspan="2" class="totalizadorFP">
                                {{ totalizadores.exnoturna }}
                            </td>
                            <td colspan="2" class="subtituloTotaisFP">Extra 100%</td>
                            <td
                                colspan="2"
                                class="totalizadorFP"
                                :class="{ textRed: temInsercao }"
                            >
                                {{
                                    totalizadores.extra100
                                        ? totalizadores.extra100 +
                                          (temInsercao ? "*" : "")
                                        : ""
                                }}
                            </td>
                            <td colspan="3" class="subtituloTotaisFP">
                                Extra 100% noturnas
                            </td>
                            <td colspan="2" class="totalizadorFP">
                                {{ totalizadores.extra100not }}
                            </td>
                        </tr>
                        <tr>
                            <template v-if="extrasParametros[1]">
                                <td colspan="2" class="subtituloTotaisFP">H.E.1</td>
                                <td
                                    colspan="2"
                                    class="totalizadorFP"
                                    :class="{ textRed: temInsercao }"
                                >
                                    {{
                                        totalizadores.extra1
                                            ? totalizadores.extra1 +
                                              (temInsercao ? "*" : "")
                                            : ""
                                    }}
                                </td>
                            </template>
                            <template v-if="extrasParametros[2]">
                                <td colspan="2" class="subtituloTotaisFP">H.E.2</td>
                                <td
                                    colspan="2"
                                    class="totalizadorFP"
                                    :class="{ textRed: temInsercao }"
                                >
                                    {{
                                        totalizadores.extra2
                                            ? totalizadores.extra2 +
                                              (temInsercao ? "*" : "")
                                            : ""
                                    }}
                                </td>
                            </template>
                            <template v-if="extrasParametros[3]">
                                <td colspan="3" class="subtituloTotaisFP">H.E.3</td>
                                <td
                                    colspan="2"
                                    class="totalizadorFP"
                                    :class="{ textRed: temInsercao }"
                                >
                                    {{
                                        totalizadores.extra3
                                            ? totalizadores.extra3 +
                                              (temInsercao ? "*" : "")
                                            : ""
                                    }}
                                </td>
                            </template>
                        </tr>
                        <tr>
                            <td colspan="2" class="subtituloTotaisFP">Tempo Extra</td>
                            <td colspan="2" class="totalizadorFP">
                                {{
                                    totalizadores.tempoExtra
                                        ? totalizadores.tempoExtra
                                        : "00:00:00"
                                }}
                            </td>
                            <td colspan="2" class="subtituloTotaisFP">
                                Saldo Hora Extra
                            </td>
                            <td colspan="2" class="totalizadorFP">
                                {{
                                    totalizadores.saldoExtra
                                        ? totalizadores.saldoExtra
                                        : "00:00:00"
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td :colspan="contColspanTotal" class="tituloTotaisDetalhado">
                                Eventos Gerenciais
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" class="subtituloTotaisFP">Horas diurnas</td>
                            <td
                                colspan="2"
                                class="totalizadorFP"
                                :class="{ textRed: temInsercao }"
                            >
                                {{
                                    totalizadores.diurnas
                                        ? totalizadores.diurnas + (temInsercao ? "*" : "")
                                        : ""
                                }}
                            </td>
                            <td colspan="2" class="subtituloTotaisFP">
                                Total em direção
                            </td>
                            <td colspan="2" class="totalizadorFP">
                                {{ totalizadores.direcao }}
                            </td>
                            <td colspan="3" class="subtituloTotaisFP">
                                Total complementares
                            </td>
                            <td
                                colspan="2"
                                class="totalizadorFP"
                                :class="{ textRed: temInsercao }"
                            >
                                {{
                                    totalizadores.complementares
                                        ? totalizadores.complementares +
                                          (temInsercao ? "*" : "")
                                        : ""
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2" class="subtituloTotaisFP">Total intervalo</td>
                            <td colspan="2" class="totalizadorFP">
                                {{ totalizadores.intervalo }}
                            </td>
                            <td
                                colspan="2"
                                class="subtituloTotaisFP"
                                style="min-width: 115px"
                            >
                                Total Extra diurnas
                            </td>
                            <td
                                colspan="2"
                                class="totalizadorFP"
                                :class="{ textRed: temInsercao }"
                            >
                                {{
                                    totalizadores.exdiurna
                                        ? totalizadores.exdiurna +
                                          (temInsercao ? "*" : "")
                                        : ""
                                }}
                            </td>
                            <td
                                colspan="3"
                                class="subtituloTotaisFP"
                                style="min-width: 145px"
                            >
                                Total parada em jornada
                            </td>
                            <td colspan="2" class="totalizadorFP">
                                {{ totalizadores.paradoEmJornada }}
                            </td>
                        </tr>
                        <tr>
                            <td :colspan="contColspanTotal" class="tituloTotaisDetalhado">
                                Legendas Horas Extras
                            </td>
                        </tr>
                        <tr v-for="(legenda, l) in legendas" :key="l + '_legendas'">
                            <td :colspan="contColspanTotal" style="padding: 0 !important">
                                <div class="totalizadorFP">
                                    <span
                                        class="totalizadorFP"
                                        style="font-weight: bolder"
                                    >
                                        H.E.{{ l }}:
                                    </span>
                                    <span class="totalizadorFP">
                                        {{ legenda }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="ajustes && ajustes.length">
                            <td :colspan="contColspanTotal" class="tituloTotaisDetalhado">
                                Alterações da jornada
                            </td>
                        </tr>
                        <tr v-if="ajustes && ajustes.length">
                            <td :colspan="contColspanTotal" class="nopadding">
                                <table id="tableAjustes">
                                    <thead>
                                        <tr>
                                            <th style="text-align: left !important">
                                                Data
                                            </th>
                                            <th style="text-align: left !important">
                                                Hora
                                            </th>
                                            <th style="text-align: left !important">
                                                Ação
                                            </th>
                                            <th
                                                style="
                                                    text-align: left !important;
                                                    width: 60%;
                                                "
                                            >
                                                Justificativa
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(ajuste, a) in ajustes"
                                            :key="a + '_ajustes'"
                                        >
                                            <td>{{ ajuste.data }}</td>
                                            <td>{{ ajuste.hora }}</td>
                                            <td>{{ ajuste.acao }}</td>
                                            <td>{{ ajuste.justificativa }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </template>
                </tableRelatorio>
                <!-- Modal Banco de Horas -->
                <ModalBancoHoras idModal="modalBanco" ref="modalBancoHoras" />
                <!-- Modal Ajustes -->
                <ModalAjustes idModal="modalAjustes" ref="modalAjustes" />
                <!-- Modal Opções -->
                <ModalAcoes
                    idModal="modalAcoes"
                    ref="modalAcoes"
                    @atualizarRelatorio="gerarRelatorioDetalhado"
                >
                </ModalAcoes>
            </div>
        </div>
        <!-- Tabela resumida -->
        <div v-show="!muda">
            <div class="col-sm-12 nopadding divDontPrint">
                <div class="col-sm-12 nopadding row">
                    <div class="col-sm-4">
                        <tituloPage :icon="mdiCardAccountDetails" titulo="Folha Ponto" />
                    </div>
                    <div class="col-sm-8 divButtonsBasic row">
                        <basicButtonsRelatoriosEIG
                            @gerarRelatorio="gerarRelatorioResumido"
                            @exportarRelatorio="exportarRelatorioResumido"
                            :loading="loadingExportar"
                            :disabled="$v.colabsSelecionadosResumido.$invalid"
                            :disabledDropdown="$v.relatorioResumido.$invalid"
                        >
                            <slot>
                                <simpleButton
                                    text="Detalhado"
                                    :type="'grey'"
                                    event="mudar"
                                    :width="'110px'"
                                    @mudar="muda = !muda"
                                >
                                </simpleButton>
                                <simpleButton
                                    text="Resumido"
                                    :type="'green'"
                                    event="mudar"
                                    :width="'110px'"
                                    :icon="mdiCheckBold"
                                    @mudar="muda = !muda"
                                >
                                </simpleButton>
                            </slot>
                        </basicButtonsRelatoriosEIG>
                    </div>
                </div>
                <slideUpAndDown>
                    <slot slot="filters">
                        <div class="col-sm-12"><hr /></div>
                        <div class="col-sm-12 justpaddingbottom row">
                            <!-- Input de data -->
                            <div class="col-sm-3 nopadding">
                                <inputRangeWithLimit
                                    name="inputDataJornadaTrabalhoResumido"
                                    :isObrigatorio="true"
                                    opens="right"
                                    @changeInput="changeDataIntervaloResumido"
                                >
                                </inputRangeWithLimit>
                            </div>
                            <!-- Select de empresas -->
                            <div class="col-sm-3 nopadding">
                                <selectAll
                                    nameForRadio="radiosEmpresasResumido"
                                    :isMultiple="true"
                                    :labels="labelsSelectEmpresaResumido"
                                    :optionsArray="optionsSelectEmpresaResumido"
                                    :selected="selectedEmpresaResumido"
                                    firstSelected="ER"
                                    :hasSelectAll="true"
                                    @changeSelect="changeEmpresaResumido"
                                >
                                </selectAll>
                            </div>
                            <!-- Select Jornada -->
                            <div class="col-sm-3 nopadding">
                                <selectAll
                                    nameForRadio="radiosJornadaResumido"
                                    :labels="labelsSelectJornadaResumido"
                                    :optionsArray="optionsSelectJResumido"
                                    firstSelected="ER"
                                    :isMultiple="false"
                                    :hasSelectAll="false"
                                    :disabled="$v.empresasSelecionadasResumido.$invalid"
                                    :loading="carregandoResumidoJornada"
                                    ref="selectJResumido"
                                    @changeSelect="changeJornadaResumido"
                                >
                                </selectAll>
                            </div>
                            <div class="col-sm-3 nopadding">
                                <!-- Select Colaboradores -->
                                <selectAll
                                    nameForRadio="seletorColaboradorGrupoResumido"
                                    firstSelected="C"
                                    v-bind="{
                                        hasSelectAll: true,
                                        isMultiple: true,
                                        optionsArray: optionsSelectCGCResumido,
                                        labels: labelsSelectColaboradoresResumido,
                                        ref: 'selectCGResumido',
                                    }"
                                    v-on="{ changeCheck: mudaCheckResumido }"
                                    :disabled="$v.jornadaSelecionadaResumido.$invalid"
                                    :loading="carregandoResumidoColaborador"
                                    @changeSelect="changeColaboradoresResumido"
                                />
                            </div>
                        </div>
                    </slot>
                </slideUpAndDown>
            </div>
            <div class="col-sm-12"><hr /></div>
            <tableRelatorio>
                <slot slot="thead">
                    <!-- Cabeçalho do relatório v-if para mostrar ou não algumas colunas 
                        Isso depende doq o cliente escolhe mostrar-->
                    <tr class="cabecalhoTabelaResumida">
                        <th>Colaborador</th>
                        <th v-if="extrasParametrosResumido.extra1">H.E.1</th>
                        <th v-if="extrasParametrosResumido.extra2">H.E.2</th>
                        <th v-if="extrasParametrosResumido.extra3">H.E.3</th>
                        <th>Extra 100%</th>
                        <th>Extra 100% Noturna</th>
                        <th>Extra Diurna</th>
                        <th>Extra Noturna</th>
                        <th>Diurna</th>
                        <th v-if="someHaveTempoExtraResumido()">Tempo Extra</th>
                        <th>Noturna</th>
                        <th>Complementares</th>
                        <th v-if="parametrosReduzidaResumido">Reduzida</th>
                        <th>Folga banco de horas</th>
                        <th>Saldo extra</th>
                        <th>H. Normais</th>
                        <th>Esperas</th>
                        <th>Em direção</th>
                        <th>P. em jornada</th>
                        <th>Faltas</th>
                    </tr>
                </slot>
                <slot v-if="nuloResumido == true" slot="tbody">
                    <statusInformation
                        typeBar="tr"
                        colspanForTd="20"
                        :statusBar="statusbarResumido"
                    >
                    </statusInformation>
                </slot>
                <slot v-else slot="tbody">
                    <!-- Corpo da tabela, mostra os dados do relatório seguindo mesmo princípo do cabeçalho -->
                    <template v-for="(item, i) in relatorioResumido">
                        <tr v-if="i != 'extrasParametrosResumido'" :key="i + '_total'">
                            <td class="tdTabelaResumidaNome">
                                {{ item.nome }}
                            </td>
                            <td
                                v-if="extrasParametrosResumido.extra1"
                                class="tdTabelaResumida"
                            >
                                {{ item.extras1 }}
                            </td>
                            <td
                                v-if="extrasParametrosResumido.extra2"
                                class="tdTabelaResumida"
                            >
                                {{ item.extras2 }}
                            </td>
                            <td
                                v-if="extrasParametrosResumido.extra3"
                                class="tdTabelaResumida"
                            >
                                {{ item.extras3 }}
                            </td>
                            <td class="tdTabelaResumida">
                                {{ item.extras100 }}
                            </td>
                            <td class="tdTabelaResumida">
                                {{ item.extras100not }}
                            </td>
                            <td class="tdTabelaResumida">
                                {{ item.totalExtDiurnas }}
                            </td>
                            <td class="tdTabelaResumida">
                                {{ item.totalextranoturno }}
                            </td>
                            <td class="tdTabelaResumida">
                                {{ item.totaldiurnas }}
                            </td>
                            <td
                                class="tdTabelaResumida"
                                v-if="someHaveTempoExtraResumido()"
                            >
                                {{ item.tempoExtra }}
                            </td>
                            <td class="tdTabelaResumida">
                                {{ item.totalnoturnas }}
                            </td>
                            <td class="tdTabelaResumida">
                                {{ item.complementares }}
                            </td>
                            <td
                                class="tdTabelaResumida"
                                v-if="parametrosReduzidaResumido"
                            >
                                {{ item.reduzida }}
                            </td>
                            <td class="tdTabelaResumida">
                                {{ item.folgaBanco }}
                            </td>
                            <td class="tdTabelaResumida">
                                {{ item.saldoExtra }}
                            </td>
                            <td class="tdTabelaResumida">
                                {{ item.horasnormal }}
                            </td>
                            <td class="tdTabelaResumida">
                                {{ item.totalespera }}
                            </td>
                            <td class="tdTabelaResumida">
                                {{ item.totaldirecao }}
                            </td>
                            <td class="tdTabelaResumida">
                                {{ item.totalparadotrabalhando }}
                            </td>
                            <td class="tdTabelaResumida">
                                {{ item.totalfalta }}
                            </td>
                        </tr>
                    </template>
                    <!-- Mostra o total de horas geral -->
                    <template>
                        <tr class="fundoTotal">
                            <td>Total:</td>
                            <td v-if="extrasParametrosResumido.extra1">
                                {{ totalizadoresResumido.extras1 }}
                            </td>
                            <td v-if="extrasParametrosResumido.extra2">
                                {{ totalizadoresResumido.extras2 }}
                            </td>
                            <td v-if="extrasParametrosResumido.extra3">
                                {{ totalizadoresResumido.extras3 }}
                            </td>
                            <td>
                                {{ totalizadoresResumido.extras100 }}
                            </td>
                            <td>
                                {{ totalizadoresResumido.extras100not }}
                            </td>
                            <td>
                                {{ totalizadoresResumido.extradiurno }}
                            </td>
                            <td>
                                {{ totalizadoresResumido.extranoturno }}
                            </td>
                            <td>
                                {{ totalizadoresResumido.diurnas }}
                            </td>
                            <td v-if="someHaveTempoExtraResumido()">
                                {{ totalizadoresResumido.tempoExtra }}
                            </td>
                            <td colspan="2">
                                {{ totalizadoresResumido.noturnas }}
                            </td>
                            <td v-if="parametrosReduzidaResumido">
                                {{ totalizadoresResumido.reduzida }}
                            </td>
                            <td>
                                {{ totalizadoresResumido.folgaBanco }}
                            </td>
                            <td>
                                {{ totalizadoresResumido.saldoExtra }}
                            </td>
                            <td>
                                {{ totalizadoresResumido.horasNormal }}
                            </td>
                            <td>
                                {{ totalizadoresResumido.espera }}
                            </td>
                            <td>
                                {{ totalizadoresResumido.direcao }}
                            </td>
                            <td>
                                {{ totalizadoresResumido.paradoTrabalhando }}
                            </td>
                            <td>
                                {{ totalizadoresResumido.falta }}
                            </td>
                        </tr>
                    </template>
                </slot>
            </tableRelatorio>
            <div v-if="nuloResumido == false" class="templateLegendas col-sm-12">
                <div class="legendas">Legendas Horas Extras</div>
                <template v-for="(e, i) in legendaRelatorioResumido">
                    <div v-if="i" :key="i + '_legResumido'">
                        <span class="bold">H.E.{{ i }}: </span>
                        <span class="legendaRelatorioResumido">
                            {{ e }}
                        </span>
                    </div>
                </template>
            </div>
        </div>
    </panelEagle>
</template>
<script lang="js">
import Vue from 'vue'
import {
    mdiCardAccountDetails,  mdiChevronLeft, mdiChevronRight,
    mdiTimetable, mdiCarSettings, mdiAccountSettings,
    mdiCheckBold, mdiCloseThick, mdiThumbUpOutline,
    mdiSwapVertical, mdiCloseCircleOutline, mdiAlert
} from '@mdi/js'
import { required } from 'vuelidate/lib/validators'
import { EmpresasService } from '../../../Services/auth/Empresas.service'
import { HttpRequest } from '../../../Services/auth/HttpRequest.Service'
import { DateTime } from 'luxon'
import { mapGetters } from 'vuex'
import { conectionError } from '@/Services/Helpers/swellHeper'
export default Vue.extend({
    name:'folhaPonto',
    components:{
        baseIcon: require('@/components/Atom/Icon/BaseIcon').default,
        tituloPage: require('@/components/Atom/Header/Titulo').default,
        selectAll: require('@/components/Atom/Select/SelectAll').default,
        panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
        simpleButton: require('@/components/Atom/Buttons/SimpleButton').default,
        tableRelatorio: require('@/components/Atom/Table/TableRelatorio').default,
        ModalAcoes: require('@/components/Atom/ModalJornadaFolha/ModalAcoes').default,
        ModalAjustes: require('@/components/Atom/ModalJornadaFolha/ModalAjustes').default,
        slideUpAndDown: require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
        inputRangeWithLimit: require('@/components/Atom/Datas/InputRangeWithLimit').default,
        ModalBancoHoras: require('@/components/Atom/ModalJornadaFolha/ModalBancoHoras').default,
        statusInformation: require('@/components/Atom/StatusInformation/StatusInformation').default,
        basicButtonsRelatoriosEIG: require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
    },

    validations:{
        // Detalhado
        empresasSelecionadas:{
            required
        },
        colabSelecionado:{
            required
        },
        jornadaSelecionada:{
            required
        },
        dataIntervalo:{
            required
        },
        relatorio:{
            required
        },
        // Resumido
        empresasSelecionadasResumido:{
            required
        },
        colabsSelecionadosResumido:{
            required
        },
        jornadaSelecionadaResumido:{
            required
        },
        relatorioResumido:{
            required
        },
    },

    data(){
        return{
            // !!! IMPORTANTE contabilizar certo para não quebrar layout
            // Limite total de colunas
            contColspanTotal: 31,
            // Limite de colunas "Extras"
            contColspanExtras: 6,
            // Limite de colunas "Jornada"
            contColspanJornada: 10,
            // Limite totalizadores dia
            contColspanTotaisDia: 0,
            url: '/controle/jornada/relatorios/folha/ponto/',
            // icons
            ...{
                mdiCardAccountDetails: mdiCardAccountDetails,
                mdiChevronLeft: mdiChevronLeft,
                mdiChevronRight: mdiChevronRight,
                mdiTimetable: mdiTimetable,
                mdiCarSettings: mdiCarSettings,
                mdiAccountSettings: mdiAccountSettings,
                mdiCheckBold: mdiCheckBold,
                mdiThumbUpOutline: mdiThumbUpOutline,
                mdiCloseThick: mdiCloseThick,
                mdiSwapVertical: mdiSwapVertical,
                mdiCloseCircleOutline: mdiCloseCircleOutline,
                mdiAlert: mdiAlert,
            },
            // Detalhado
            // arrays
            ...{
                optionsSelectEmpresa: new EmpresasService().Get(),
                labelsSelectEmpresa:[
                    {indexDFH: 'E', description: 'Empresas *'},
                ],
                labelsSelectColaboradores:[
                    {indexDFH: 'C', description: 'Colaborador *'},
                ],
                labelsSelectJornada:[
                    {indexDFH: 'J', description: 'Jornada *'}
                ],
                optionsSelectCGC: [],
                optionsSelectJ: [],
                empresasSelecionadas: [],
                colabSelecionado: null,
                jornadaSelecionada: [],
                dataCG: [],
                dataJ: [],
                relatorio: [],
                dataTitulo: [],
                dataTituloFinal: [],
                selectedEmpresa: [],
                selectedJornada: [],
                selectedColabs: [],
                valueIntervalo: '',
                totalizadores: [],
                legendas: [],
                ajustes: [],
                infoAssinatura: [],
                detalhesJornadaRel: [],
            },
            // strings
            ...{
                searchMotoristas: '',
                dataIntervalo: '',
                eventoFolha: '',
                mensagem: '',
                statusbar: 'info',
            },
            // booleans
            ...{
                loadingPanel: false,
                loading: false,
                muda: true,
                nulo: true,
                carregando: false,
                loadingExportar: [false, false, false],
                detalhesJornada: true,
                detalhesEspera: true,
                detalhesIntervalo: true,
                detalhesReduzida: true,
                detalhesTempoExtra: true,
                temInsercao: false,
                temFalta: false,
                extrasParametros: {1: true,2: true,3: true},
            },
            // Fim Detalhado

            // Resumido
            // arrays
            ...{
                optionsSelectEmpresaResumido : new EmpresasService().Get(),
                labelsSelectEmpresaResumido:[
                    {indexDFH: 'E', description: 'Empresas *'},
                ],
                labelsSelectColaboradoresResumido:[
                    {indexDFH: 'C', description: 'Colaboradores *'},
                    {indexDFH: 'GC', description: 'Grupo *'},
                ],
                labelsSelectJornadaResumido:[
                    {indexDFH: 'J', description: 'Jornada *'}
                ],
                optionsSelectJResumido: [],
                optionsSelectCGCResumido: [],
                empresasSelecionadasResumido: [],
                colabsSelecionadosResumido: [],
                dataIntervaloResumido: [],
                jornadaSelecionadaResumido: [],
                relatorioResumido: [],
                totalResumido: [],
                extrasParametrosResumido: [],
                selectedEmpresaResumido: [],
                totalizadoresResumido: [],
            },
            // booleans
            ...{
                nuloResumido: true,
                carregandoResumidoColaborador: false,
                carregandoResumidoJornada: false,
                carregandoJornada: false,
                parametrosReduzidaResumido: true
            },
            // strings
            ...{
                statusbarResumido: 'info',
            },
            // Fim Resumido
            parametros: null,
        }
    },

    methods:{
        ...mapGetters(['getMaster']),

        limpaDetalhado() {
            this.nulo = true
            this.legendas = []
            this.ajustes = []
            this.infoAssinatura = []
            this.relatorio = []
            this.totalizadores = []
            this.detalhesJornadaRel = []
            this.resetConfigTabelaDetalhado()
            this.$forceUpdate()
        },

        resetConfigTabelaDetalhado() {
            this.contColspanTotal = 31
            this.contColspanExtras = 6
            this.contColspanJornada = 10
            this.contColspanTotaisDia = 0
            this.detalhesJornada = true
            this.detalhesEspera = true
            this.detalhesIntervalo = true
            this.detalhesReduzida = true
            this.detalhesTempoExtra = true
            this.temInsercao = false
            this.temFalta = false
            this.extrasParametros = {1: true,2: true,3: true}
        },

        limpaResumido() {
            this.nuloResumido = true
            this.parametrosReduzidaResumido = true
            this.legendaRelatorioResumido = []
            this.relatorioResumido = []
            this.totalizadoresResumido = []
            this.extrasParametrosResumido = []
            this.$forceUpdate()
        },

        someHaveTempoExtraResumido(){
            return this.relatorioResumido.some((mt)=>{
                return !!mt.tempoExtra
            })
        },

        ehDsr(item, k){
            // let data = new String(k)
            let reg = /\d\d\/\d\d\/\d{4}/
            let data = reg.exec(k)[0]
            data = DateTime.fromFormat(data, 'dd/LL/yyyy')
            data = data.weekday
            /**
             * Luxon weekday vai de 1 a 7, começando na segunda, nosso bd vai de 0 a 6,
             * começando na domingo
             * então, só diferencia nesse último dia ai kkk
             */
            if(data===7) data = 0
            var hjt = this.detalhesJornadaRel['horas_jornada_trabalho']
            var jornada = _.find(hjt, {hjtdiasemana:data})
            return jornada['hjtdsr'] === 1
        },

        // MÉTODOS RELATÓRIO DETALHADO
        /**
         * @description Ação de selecionar empresas no relatório detalhado
         * @fires buscaJornada
         * @listens changeSelect
         * @param {array} empresa
         * @author Rafael
         */
        async changeEmpresa(empresa){
            this.empresasSelecionadas = empresa
            this.jornadaSelecionada = []
            this.colabSelecionado = null
            this.optionsSelectJ = []
            this.optionsSelectCGC = []
            this.$refs.selectCG.clearAll();
            this.$refs.selectJ.clearAll()
            this.limpaDetalhado()
            await this.buscaJornada()
        },

        changeColaboradores(value){
            this.colabSelecionado = value && value.length ? value[0] : null
            this.limpaDetalhado()
        },

        /**
         * @description Ação de selecionar intervalo no relatório detalhado
         * @listens changeInput
         * @param {string} data "01-01-2022 - 01-01-2022"
         * @author Rafael
         */
        changeDataIntervalo(data){
            this.dataIntervalo = data;
            this.dataTitulo = this.dataIntervalo.split("-");
            this.dataTituloFinal = this.dataTitulo[0].split("/");
            this.limpaDetalhado()
        },

        /**
         * @description Ação de selecionar jornada no relatório detalhado
         * @fires buscaColaboradores
         * @listens changeSelect
         * @param {array} jornada
         * @author Rafael
         */
        changeJornada(jornada){
            this.jornadaSelecionada = jornada
            this.buscaColaboradores(jornada)
            this.$refs.selectCG.clearAll()
            this.limpaDetalhado()
        },

        /**
         * @description Ação gerar relatório detalhado
         * @listens atualizarRelatorio
         * @listens gerarRelatorio
         * @author Rafael
         */
        async gerarRelatorioDetalhado(){
            this.resetConfigTabelaDetalhado()
            this.loadingPanel = true
            this.$bvModal.hide('modalOpcoesAdicionarJornada')
            this.$bvModal.hide('modalOpcoesAdicionarEvento')
            this.$bvModal.hide('modalAcoes')
            this.nulo = true
            var editData = this.dataIntervalo.split("-")
            var dataInicio = editData[0]
            var dataFim = editData[1]
            let params = {
                'motorista': this.colabSelecionado,
                'inicio': dataInicio,
                'fim': dataFim
            }
            let url = `${this.url}gerarRelatorioDetalhado`
            await new HttpRequest().Post(url, params)
                .then(({ data, status }) => {
                    if(status && data){
                        this.temInsercao = data.temInsercao
                        this.temFalta = data.temFalta
                        this.relatorio = data.info.folha
                        this.extrasParametros = data.flagExtras
                        this.totalizadores = data.info.totalizadores
                        this.detalhesJornadaRel = data.detalhesJornada
                        this.detalhesJornada = data.detalhesJornada.detjornada
                        this.detalhesEspera = data.detalhesJornada.detespera
                        this.detalhesIntervalo = data.detalhesJornada.detintervalo
                        this.detalhesReduzida = data.detalhesJornada.detreduzida
                        this.detalhesTempoExtra = data.detalhesJornada.jttempoextra
                        this.legendas = data.legendas
                        this.ajustes = data.ajustes
                        this.infoAssinatura = data.infoAssinatura
                        if(!this.extrasParametros[1]){
                            this.contColspanExtras -= 1
                            this.contColspanTotal -= 1
                        }
                        if(!this.extrasParametros[2]){
                            this.contColspanExtras -= 1
                            this.contColspanTotal -= 1
                        }
                        if(!this.extrasParametros[3]){
                            this.contColspanExtras -= 1
                            this.contColspanTotal -= 1
                        }
                        // Coluna "Reduzida"
                        if(!this.detalhesReduzida){
                            this.contColspanTotal -= 1
                            this.contColspanJornada -= 1
                        }
                        // Colunas "Em direção" e "Parada em jornada"
                        if(!this.detalhesJornada){
                            this.contColspanTotal -= 6
                        }
                        // Colunas "Em espera"
                        if(!this.detalhesEspera){
                            this.contColspanTotal -= 3
                        }
                        // Colunas "Em intervalo"
                        if(!this.detalhesIntervalo){
                            this.contColspanTotal -= 3
                        }
                        // Coluna "Tempo Extra"
                        if(!this.detalhesTempoExtra){
                            this.contColspanTotal -= 1
                            this.contColspanJornada -= 1
                        }
                        this.contColspanTotaisDia = this.contColspanJornada
                            + this.contColspanExtras + 1
                    } else {
                        this.statusbar = 'error'
                    }
                })
                .catch(() => {
                    conectionError()
                })
                .finally(() => {
                    this.mensagem = ''
                    this.nulo = false
                    this.loadingPanel = false
                })
        },

        /**
         * @description Ação exportar relatório detalhado
         * @listens exportarRelatorio
         * @param {'pdf'|'csv'|'xls'} tipo
         * @author Rafael
         */
        async exportarRelatorioDetalhado(tipo) {
            if(Object.values(this.relatorio).length){
                this.setLoading(tipo)
                let nomeCondutor = this.getNomeCondutor()
                let url = `${this.url}exportarRelatorio`
                let params = {
                    'tipo': tipo,
                    'tipoRel': 'A',
                    'motorista': nomeCondutor,
                    'relatorio': this.relatorio,
                    'data': this.dataIntervalo,
                    'temInsercao': this.temInsercao,
                    'temFalta': this.temFalta,
                    'extra': this.extrasParametros,
                    'totalizadores' : this.totalizadores,
                    'detalhesJornada': this.detalhesJornadaRel,
                    'legendas': this.legendas,
                    'ajustes': this.ajustes,
                    'infoAssinatura': this.infoAssinatura,
                }
                await new HttpRequest().Post(url, params)
                    .then(({ data, status }) => {
                        if (status) {
                        let root =  process.env.VUE_APP_ROOT
                            window.open(root+'/'+data.local)
                        } else {
                            conectionError()
                        }
                    })
                    .catch(() => {
                        conectionError()
                    })
                    .finally(() => {
                        this.setLoading()
                    })
            }
        },

        /**
         * @description Buscar nome do condutor selecionado
         * @return { String } Nome do condutor
         * @author Rafael
         */
        getNomeCondutor() {
            let aux = this.colaboradoresSelectModal
            if (!aux || !aux.length) {
                return ''
            }
            let colab = this.colabSelecionado
            let filter = this.colaboradoresSelectModal.filter(function(e) {
                return e.value == colab
            })
            return filter && filter.length ? filter[0].description : ''
        },

        /**
         * @description Buscar as jornadas referentes a empresa
         * @param {array} empresa
         * @author Rafael
         */
        async buscaJornada(){
            if(this.empresasSelecionadas.length > 0){
                this.carregandoJornada = true
                let url = `${this.url}buscaJornada`
                let params = {'clientes': this.empresasSelecionadas}
                await new HttpRequest().Post(url, params)
                    .then(({ data, status }) => {
                        if (status) {
                            this.dataJ = data.J
                            this.optionsSelectJ = this.dataJ
                            if (this.parametros?.jornadas) {
                                this.selectedJornada = this.optionsSelectJ.filter((e) => {
                                    return this.parametros?.jornadas?.includes(e.value)
                                })
                            } else {
                                if(this.optionsSelectJ.length == 1) {
                                    this.$refs.selectJ.selectedItens = this.optionsSelectJ
                                    this.changeJornada([this.optionsSelectJ[0].value])
                                }
                            }
                        } else {
                            conectionError()
                        }
                    })
                    .catch(() => {
                        conectionError()
                    })
                    .finally(() => {
                        this.carregandoJornada = false
                    })
            }else{
                this.$refs.selectCG.clearAll()
                this.$refs.selectJ.clearAll()
            }
        },

        /**
         * @description Buscar os colaboradores ligados a jornada selecionada
         * @author Rafael
         */
        async buscaColaboradores() {
            let taOk = this.jornadaSelecionada.length > 0
                && this.empresasSelecionadas.length > 0
            if (!taOk) {
                this.$refs.selectCG.clearAll()
                return
            }
            this.carregando = true
            let url = `${this.url}buscaColaboradores`
            let params = {
                'clientes': this.empresasSelecionadas,
                'jornada' : this.jornadaSelecionada
            }
            await new HttpRequest().Post(url, params)
                .then(({ data, status }) => {
                    if (status) {
                        this.dataCG = data
                        let tipo = this.$refs.selectCG.labelSelected
                        this.optionsSelectCGC = this.dataCG[tipo]
                        this.colaboradoresSelectModal = data.C
                        if (this.parametros?.colaboradores) {
                            let filter = this.optionsSelectCGC.filter((e) => {
                                return this.parametros?.colaboradores?.includes(e.value)
                            })
                            this.selectedColabs = filter && filter.length ?
                                [filter[0]] : []
                        }
                        this.parametros = null
                    } else {
                        conectionError()
                    }
                })
                .catch(() => {
                    conectionError()
                })
                .finally(() => {
                    this.carregando = false
                })
        },

        defineColspanTrDetalhado(item) {
            return item.inicio == '' && !item.folgaBH
                ? this.contColspanTotal
                : item.inicio == '' && item.folgaBH
                    ? this.contColspanTotal - 1
                    : item.inicio == item.fim
                        ? 3
                        : 1
        },
        // FIM MÉTODOS RELATÓRIO DETALHADO

        // MÉTODOS RELATÓRIO RESUMIDO
        /**
         * @listens changeSelect
         * @description Ação de selecionar empresa no relatório resumido
         * @param {array} empresa
         * @author Rafael
         */
        async changeEmpresaResumido(empresa){
            this.empresasSelecionadasResumido = empresa;
            this.jornadaSelecionadaResumido = []
            this.colabsSelecionadosResumido = []
            this.optionsSelectJResumido = []
            this.optionsSelectCGCResumido = []
            this.$refs.selectCGResumido.clearAll();
            this.$refs.selectJResumido.clearAll()
            this.limpaResumido()
            await this.buscaJornadaResumido();
        },

        /**
         * @listens changeSelect
         * @description Ação de selecionar colaborador no relatório resumido
         * @param {array} colaborador
         * @author Rafael
         */
        changeColaboradoresResumido(colaborador){
            this.colabsSelecionadosResumido = colaborador;
            this.limpaResumido()
        },

        /**
         * @description Ação de selecionar intervalo no relatório resumido
         * @listens changeInput
         * @param {string} data "01-01-2022 - 01-01-2022"
         * @author Rafael
         */
        changeDataIntervaloResumido(data){
            this.dataIntervaloResumido = data;
            this.limpaResumido()
        },

        /**
         * @listens changeSelect
         * @description Ação de selecionar jornada no relatório resumido
         * @param {array} jornada
         * @author Rafael
         */
        changeJornadaResumido(jornada){
            this.jornadaSelecionadaResumido = jornada;
            this.buscaColaboradoresResumido()
            this.$refs.selectCGResumido.clearAll()
            this.limpaResumido()
        },

        /**
         * @listens gerarRelatorio
         * @description Ação gerar relatório resumido
         * @author Rafael
         */
        async gerarRelatorioResumido(){
            this.nuloResumido = true
            this.loadingPanel = true
            var dataEdit = this.dataIntervaloResumido.split("-")
            var dataInicio = dataEdit[0]
            var dataFim = dataEdit[1]
            let url = `${this.url}gerarRelatorioResumido`
            let params = {
                'clientes': this.empresasSelecionadasResumido,
                'motorista' : this.colabsSelecionadosResumido,
                'tipo': this.searchMotoristas,
                'jornada': this.jornadaSelecionadaResumido,
                'inicio': dataInicio,
                'fim': dataFim,
            }
            await new HttpRequest().Post(url, params)
                .then(({ data, status }) => {
                    if(status && data){
                        this.relatorioResumido = data.dadosRelatorio
                        this.totalizadoresResumido = data.totalizadores
                        this.extrasParametrosResumido = data.flagExtras[0]
                        this.legendaRelatorioResumido = data.flagExtras[1]
                        this.parametrosReduzidaResumido = data.flagExtras[2]
                        this.nuloResumido = false
                    } else {
                        this.statusbarResumido = 'error'
                    }
                })
                .catch(() => {
                    conectionError()
                })
                .finally(() => {
                    this.loadingPanel = false
                })
        },

        /**
         * @description Exportar informações do relatório resumido
         * @listens exportarRelatorio
         * @param {string} tipo ('pdf', 'csv', 'xls')
         * @author Rafael
         */
        async exportarRelatorioResumido(tipo){
            if(this.relatorioResumido.length){
                this.setLoading(tipo)
                let url = `${this.url}exportarRelatorioResumido`
                let params = {
                    'tipo': tipo,
                    'relatorio': this.relatorioResumido,
                    'extraParametros' : this.extrasParametrosResumido,
                    'reduzida': this.parametrosReduzidaResumido,
                    'totalResumido': this.totalizadoresResumido,
                    'data': this.dataIntervaloResumido,
                    'legenda': this.legendaRelatorioResumido
                }
                await new HttpRequest().Post(url, params)
                    .then(({ data, status }) => {
                        if (status && data.local) {
                            let root = process.env.VUE_APP_ROOT
                            window.open(root+'/'+data.local)
                        } else {
                            conectionError()
                        }
                    })
                    .catch(() => {
                        conectionError()
                    })
                    .finally(() => {
                        this.setLoading()
                    })
            }
        },

        /**
         * @description Mostrar loading e bloquear ação nos botões de exportar
         * @param {string} tipo ('pdf', 'csv', 'xls')
         * @author Rafael
         */
        setLoading(tipo){
            this.loadingExportar = [tipo == 'pdf', tipo == 'xls', tipo == 'csv'];
        },

        /**
         * @description Buscar as jornadas do filtro do relatório resumido
         * @author Rafael
         */
        async buscaJornadaResumido(){
            if(this.empresasSelecionadasResumido.length > 0){
                this.carregandoResumidoJornada = true
                let url = `${this.url}buscaJornada`
                let params = {'clientes': this.empresasSelecionadasResumido}
                await new HttpRequest().Post(url, params)
                    .then(({ data, status }) => {
                        if (status) {
                            this.dataJ = data.J
                            this.optionsSelectJResumido = this.dataJ
                            if(this.dataJ.length == 1) {
                                this.$refs.selectJResumido.selectedItens = this.dataJ
                                this.changeJornadaResumido([this.dataJ[0].value])
                            }
                        } else {
                            conectionError()
                        }
                    })
                    .catch(() => {
                        conectionError()
                    })
                    .finally(() => {
                        this.carregandoResumidoJornada = false
                    })
            }else{
                this.$refs.selectJResumido.clearAll()
            }
        },

        /**
         * @description Buscar os colaboradores do filtro do relatório resumido
         * @param {array} jornada
         * @author Rafael
         */
        async buscaColaboradoresResumido(){
            let taOk = this.jornadaSelecionadaResumido.length > 0
                && this.empresasSelecionadasResumido.length > 0
            if (!taOk) {
                this.$refs.selectCGResumido.clearAll()
            }
            this.carregandoResumidoColaborador = true
            let url = `${this.url}buscaColaboradores`
            let params = {
                'clientes': this.empresasSelecionadasResumido,
                'jornada' : this.jornadaSelecionadaResumido
            }
            await new HttpRequest().Post(url, params)
                .then(({ data, status }) => {
                    if (status) {
                        this.dataCG = data
                        let tipo = this.$refs.selectCGResumido.labelSelected
                        this.optionsSelectCGCResumido = this.dataCG[tipo]
                    } else {
                        conectionError()
                    }
                })
                .catch(() => {
                    conectionError()
                })
                .finally(() => {
                    this.carregandoResumidoColaborador = false
                })
        },

        /**
         * @description Buscar os colaboradores do filtro do relatório resumido
         * @param {string} value ('C', 'GC')
         * @author Rafael
         */
        mudaCheckResumido(value) {
            this.searchMotoristas = value
            if(this.dataCG[value]!== undefined) {
                this.optionsSelectCGCResumido = this.dataCG[value]
            }
        },

        // FIM MÉTODOS RELATÓRIO RESUMIDO

        // MODAIS
        /**
		 * @listens click - botão de edição de motorista dentro da tabela
		 * @description Muda as informações de editavel, a prop que alimenta
		 * a modal de edição de motorista/ajudante, fazendo isso
		 * antes de conjurar 🪄 a modal, tentando manter tudo atualizado
		 * @param {object} row - representa linha da tabela
		 * @author Gui 🍺🍺
		 */
		async modalBanco(){
            let colaboradores = await this.getFechamentoColaboradores()
			this.$refs.modalBancoHoras
				.preparaModalBancoHoras(
                    colaboradores,
                    [this.colabSelecionado],
                    this.dataTituloFinal)
		},

        async getFechamentoColaboradores() {
            let obj = { 'arrayFuncionarios': this.colaboradoresSelectModal }
            const url = `${this.url}getUltimoFechamentoColaboradores`
            const dados = await new HttpRequest().Post(url, obj);
            return dados.data
        },

        modalAjustes(){
            this.$refs.modalAjustes.preparaModalAjustes(this.empresasSelecionadas)
        },

        modalAcoes(eventos) {
            this.$refs.modalAcoes
                .preparaModalAcoesFolhaPonto(
                    this.dataTitulo,
                    this.empresasSelecionadas,
                    eventos)
            this.$refs.modalAcoes.modalAcoes(eventos)
        },
        // FIM MODAIS
    },

    mounted() {
        this.parametrosReduzidaResumido = true
        this.extrasParametrosResumido.extra1 = true
        this.extrasParametrosResumido.extra2 = true
        this.extrasParametrosResumido.extra3 = true
        this.detalhesJornada = true
        this.detalhesEspera = true
        this.detalhesIntervalo = true
        this.detalhesReduzida = true
        this.detalhesTempoExtra = true
        this.parametros = null
        if (this.$route.params) {
            this.parametros = this.$route.params
            if (this.parametros?.intervalo) {
                this.valueIntervalo = this.parametros?.intervalo
            }
            if (this.parametros?.clientes) {
                this.selectedEmpresa = this.optionsSelectEmpresa.filter((e) => {
                    return this.parametros?.clientes?.includes(e.value)
                })
            }
        } else {
            if(!this.getMaster()){
                this.selectedEmpresa = this.optionsSelectEmpresa
            }
        }
    },
})
</script>

<style scope lang="scss">
#folhaPonto {
    .texto-dsr {
        color: red;
    }
    //Botões Banco de Horas e Ajustes
    .divBotoesBancoAjustes {
        max-height: 98%;
        padding-left: 15px !important;
    }
    .buttonBancoHoras {
        background-color: rgb(84, 155, 212) !important;
    }
    .buttonAjustes {
        background: rgb(110, 177, 125) !important;
    }

    // Relatório Detalhado
    .insersaoLegendaTabelaDetalhada {
        color: #e15a59;
        text-align: left;
        font-size: 14px !important;
        padding-left: 15px;
    }
    //Cabeçalho
    .trCabecalhoFolhaPonto {
        background: #f5f5f5 !important;
        th {
            font-size: 10px;
        }
    }
    .trCabecalhoDetalhes {
        background: #f5f5f5 !important;
        th {
            background: #f5f5f5 !important;
            top: 24px !important;
            font-size: 10px;
            height: 25px;
        }
    }
    .trCabecalhoDetalhesNulo {
        border: none;
    }
    //Corpo da tabela
    .fundoDataDetalhado {
        padding: 15px 0 0 0 !important;
        vertical-align: bottom !important;
        background: white !important;
        border: none !important;
    }
    .dataDetalhado {
        display: table-cell;
        background: #777777 !important;
        width: 350px !important;
        color: white;
        text-align: center !important;
        padding: 4px 5px 2px 5px !important;
    }
    .tamanhoDataDetalhado {
        display: inline-block;
        width: 220px !important;
        font-size: 12px;
    }
    .iconModalAcoes:hover {
        cursor: pointer;
    }
    .tdTabelaDetalhada {
        font-size: 10px !important;
        padding: 2px 2px 0 2px !important;
        text-align: center !important;
        vertical-align: middle !important;
        border-bottom: 1px solid #e5e5e5 !important;
    }
    .tdTabelaDetalhadaColunas {
        font-size: 10px !important;
        padding: 2px 2px 0 2px !important;
        text-align: center !important;
        vertical-align: top !important;
        border-right: 1px solid #e4e4e4 !important;
    }
    .tdTabelaPlaca {
        font-size: 10px !important;
        padding: 2px !important;
        border-left: 1px solid #e4e4e4 !important;
        div {
            font-size: 10px !important;
            text-align: left !important;
        }
    }
    .trTotalizadorDia {
        background: #fff;
        border-bottom: none !important;
    }
    .textRed {
        color: #db3939;
    }
    .totalTabelaDetalhada {
        background: #cdcdcd !important;
        text-align: center !important;
        font-size: 11px !important;
        padding: 2px 2px 0 2px !important;
    }
    .dadosTabelaDetalhada {
        vertical-align: middle !important;
        font-size: 10px !important;
        background: #cdcdcd !important;
        text-align: center !important;
        padding: 2px 2px 0 2px !important;
    }
    // Totalizadores tabela detalhada
    .tituloTotaisDetalhado {
        color: #fff;
        font-weight: bold;
        border: none !important;
        background: #777777 !important;
        padding: 3px 0px 0px 5px !important;
    }
    .subtituloTotaisFP {
        font-weight: 600;
        font-size: 10px !important;
        background: #cdcdcd !important;
        padding: 3px 0px 0px 5px !important;
    }
    .totalizadorFP {
        font-size: 10px !important;
        vertical-align: middle !important;
        background: #fff !important;
        padding: 3px 0px 0px 5px !important;
    }

    //Relatório resumido
    //Cabeçalho
    .cabecalhoTabelaResumida {
        th {
            font-size: 10px !important;
            text-align: left !important;
            padding-left: 0px;
        }
    }
    //Corpo
    .tdTabelaResumidaNome {
        width: 180px !important;
        text-align: left !important;
        vertical-align: middle !important;
        border: 1px solid #e4e4e4 !important;
        padding-left: 5px;
        text-align: center;
        font-size: 10px !important;
        padding-left: 3px !important;
        padding-right: 3px !important;
    }
    .tdTabelaResumida {
        text-align: left !important;
        vertical-align: middle !important;
        border: 1px solid #e4e4e4 !important;
        padding-left: 5px;
        text-align: center;
        font-size: 10px !important;
        padding-left: 3px !important;
        padding-right: 3px !important;
        text-align: left !important;
    }
    //Totalizador
    .fundoTotal {
        td {
            border: none !important;
            background: #d0d0d0 !important;
            font-size: 10px !important;
            padding-left: 3px !important;
            padding-right: 3px !important;
            text-align: left;
            padding-top: 4px !important;
        }
    }
    //Legendas
    .legendaRelatorioResumido {
        font-size: 10px !important;
    }
    .templateLegendas {
        padding-left: 15px !important;
        margin-top: 5px;
        div {
            text-align: left;
        }
        .legendas {
            font-size: 10px !important;
            font-weight: bold !important;
        }
    }
    .bold {
        font-size: 10px !important;
        font-weight: bold !important;
    }

    // estilo do bootstrap substituído
    table thead th {
        border-bottom: none !important;
        padding: 2px 2px 0px 2px !important;
        height: 25px !important;
        text-align: center !important;
        vertical-align: middle !important;
        min-width: 40px !important;
        text-align: center !important;
    }
    table.table {
        width: 99.9% !important;
    }
    #tableAjustes {
        th {
            text-align: left !important;
            padding: 3px 7px 0 7px !important;
            font-size: 12px;
        }
        td {
            text-align: left !important;
            padding: 3px 7px 0 7px !important;
            font-size: 12px;
        }
    }
}
</style>
