<template>
    <panelEagle id="ListagemFeriasEAfastamento" :loading="loadingPanel">
        <div class="col-sm-12 row nopadding">
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-6">
                    <tituloPage 
                        :icon='mdiCalendarCursor' 
                        titulo='Férias e afastamento'
                    />
                </div>
                <div class="col-sm-6 divDontPrint">
                    <btnEIN
                        :disabledDropdown="dataTable.length == 0"
                        :loading="loadingExportar"
                        linkNew ='CadastroFeriasEAfastamento'
                        tipoNovo='button'
                        @exportarCadastro="exportarCadastro">
                    </btnEIN>
                </div>
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <div class="col-sm-12 row nopadding divDontPrint">
            <div class="col-sm-6 nopadding divDontPrint">
                <selectAll
                    nameForRadio="radiosEmpresas"
                    :labels="[{indexDFH: 'E', description: 'Empresa*'}]"
                    @changeSelect="changeEmpresas"
                    :optionsArray="optEmpresas"
                    :selected="empresaSelecionada"
                    :hasSelectAll="true"
                    :isMultiple="true"
                />
            </div>
            <div class="col-sm-6 nopadding divDontPrint">
                <buttonsFilters
                    @buttonsFiltersChange="buttonsFiltersChangeStatus"
                    label="Tipo"
                    :onlyOneSelected="true"
                    :arrayButtons="arrayButtonsStatusTabDados"
                />
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <div class="col-sm-12 nopadding mt-5">
            <tableListagem
                :titles="tableTitles"
                :data="dataTable"
            >
                <!-- @excluirCadastro='excluirCadastro'
                @editarCadastro='editarCadastro' -->
                <template #acoes='{row}'>
                    <!-- <template v-if="dataValida(row)"></template> -->
                        <simpleButton
                            v-if='getEditar()'
                            type='green'
                            :icon='mdiPencil'
                            event='editarCadastro'
                            width='31%'
                            @editarCadastro='editarCadastro(row.codigo)'
                        />
                        <simpleButton
                            type='red'
                            v-if='getExcluir()'
                            :icon='mdiTrashCanOutline'
                            event='alterarStatus'
                            width='31%'
                            @alterarStatus='confirmarExcluir(row.codigo)'
                        />  
                    <div v-else/>
                </template>
            </tableListagem>
        </div>
        <modalDeleta
            id="modal-deletar-ferias"
            @confirmaExclusao="excluirCadastro(idExcluir)"
        />
    </panelEagle>
</template>

<script lang="ts">
import Vue from 'vue'
import { 
	mdiCalendarCursor, 
	mdiCloseThick, 
	mdiPencil, 
	mdiTrashCanOutline 
} from '@mdi/js'
import { DateTime, Duration } from 'luxon'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { mapGetters } from 'vuex'
import { conectionError } from '@/Services/Helpers/swellHeper'
export default Vue.extend({
	name:'ListagemFeriasEAfastamento',
	components:{
		panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
		btnEIN: require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
		tituloPage: require('@/components/Atom/Header/Titulo').default,
		selectAll: require('@/components/Atom/Select/SelectAll').default,
		buttonsFilters: require('@/components/Atom/Buttons/ButtonsFilters.vue').default,
		tableListagem: require('@/components/Atom/Table/TableListagem.vue').default,
		simpleButton: require('@/components/Atom/Buttons/SimpleButton').default,
		modalDeleta: require('@/components/Atom/ModalSpecific/ModalDeleta').default,
	},
	data() {
		return {
			mdiTrashCanOutline:mdiTrashCanOutline,
			mdiPencil:mdiPencil,
			mdiCloseThick:mdiCloseThick,
			mdiCalendarCursor:mdiCalendarCursor,
			loadingPanel:false,
			urlBase:'checkpoint/cadastros/ferias/afastamento',

			loadingExportar:[false, false, false],
			arrayButtonsStatusTabDados:[
				{
					'value': 'T', 
					'width': '32.5%', 
					'isSelected': true, 
					'extraClass':'', 
					'title':'Todos', 
					'text':'Todos', 
					'icon': ''
				},
				{
					'value': 'FE', 
					'width': '32.5%', 
					'isSelected': false, 
					'extraClass': '', 
					'title':'Férias', 
					'text':'Férias', 
					'icon': ''
				},
				{
					'value': 'AF', 
					'width': '32.5%', 
					'isSelected': false, 
					'extraClass': '', 
					'title':'Afastamento', 
					'text':'Afastamento', 
					'icon': ''
				},
			],
			empresaSelecionada:[],

			valueTipo:[],
			valueEmpresas:[],
			optEmpresas : new EmpresasService().Get(),

			dataTable:[],
			tableTitles:[
				{ number:'mtnome', name:'Nome' },
				{ number:'ceinicio', name:'Data de início' },
				{ number:'cefim', name:'Data de fim' },
				{ number:'clfantasia', name:'Empresa' },
				{ number:'cetipo', name:'Tipo' },
			],

			idExcluir:null,
		}
	},
	methods: {
		...mapGetters([
			'getMaster', 
			'getCadastrar', 
			'getEditar', 
			'getExcluir'
		]),

		/**
		 * retorna se o dia inicial eh >= 30
		 * @returns Boolean
		 * @deprecated nao esta mais sendo usado pq nao precisa mais dessa validacao
		 */
		dataValida({ceinicio, cefim, cetipo}){
			var dataAtual = DateTime.now()
			var dataInicio = DateTime.fromFormat(ceinicio, 'dd/LL/yyyy')
			var difDiaAtualEInicio:any = dataInicio.diff(dataAtual, 'days')
			var diasIni = difDiaAtualEInicio.values.days
			return diasIni >= 30
		},

		changeEmpresas(empresa){
			this.dataTable = []
			this.valueEmpresas = empresa
			this.fazConsulta()
		}, 

		confirmarExcluir(id){
			this.idExcluir = id
			this.$nextTick(() => {
				this.$bvModal.show('modal-deleta-modal-deletar-ferias')
			})
		},

		excluirCadastro(val){
			this.idExcluir = null
			new HttpRequest().Post(
				`/${this.urlBase}/excluir`, val
			).then(() => {
				this.fazConsulta()
			}).catch(() => {
				conectionError()
			})
		},

		editarCadastro(val){
			this.$router.push({
				name:'CadastroFeriasEAfastamento', 
				params:{id:val}
			})
		},

		exportarCadastro(tipo){
			var root =  process.env.VUE_APP_ROOT;
			this.loadingExportar = [
				tipo === 'pdf',
				tipo === 'xls',
				tipo === 'csv' 
			]
			new HttpRequest().Post(
				`/${this.urlBase}/exportar`, {tipo, 'dataTable':this.dataTable}
			).then(({ data }) => {
				window.open(root+'/'+data.dados);
			}).catch(() => {
				conectionError()
			}).finally(() => {
				this.loadingExportar = [false, false, false]
			})
		},

		buttonsFiltersChangeStatus(value){
			this.valueTipo = value
			this.fazConsulta()
		},

		fazConsulta(){
			if(!this.valueEmpresas.length) return
			var obj = {
				empresa:this.valueEmpresas,
				tipo:this.valueTipo
			}
			this.loadingPanel = true
			new HttpRequest().Post(
				`/${this.urlBase}/listagem`, obj
			).then(({ data }) => {
				this.dataTable = data.dados
			}).catch(() => {
				conectionError()
			}).finally(() => this.loadingPanel = false)
		}
	},
	mounted(){
		if(!this.getMaster()){
			this.empresaSelecionada = this.optEmpresas
		}
		this.fazConsulta()
	},
})
</script>
<style>
#ListagemFeriasEAfastamento{

}
</style>