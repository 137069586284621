<template>
    <eaglePanel id='Espelhamento' :loading='loading'>
        <div class="col-sm-12">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6 nopadding">
                    <titulo
                        titulo="Espelhamento"
                        :icon='mdiCarConnected'
                    />
                    <!-- O icone correto não existe mais no pack -->
                </div>
                <div class="col-sm-6 nopadding divDontPrint"
                    align="right">
                    <simpleButton 
                        v-if="getCadastrar()"
                        :icon="mdiPlus"
                        text="Novo"
                        event="click"
                        type="green"
                        @click="novoEspelhamento"
                    />
                </div>
            </div>
            <div class="col-sm-12 nopadding"><hr></div>
            <div class="col-sm-12 nopadding">
                <div class="col-sm-6 nopadding">
                    <selectAll
                        nameForRadio='seletorEmpresas'
                        :labels='[{description:"Empresas"}]'
                        :isMultiple='true'
                        :hasSelectAll = 'true'
                        :optionsArray='optselectEmpresas'
                        @changeSelect='changeEmpresas'
                    />
                </div>
            </div>
            <div class="col-sm-12 nopadding"><hr></div>
            <div class="col-sm 12 nopadding">
                <tableLista
                    :titles='titulosListagem'
                    :data='dataListagem'
                    :habilitado='true'>
                    <template #acoes='{row}'>
                        <simpleButton
                            v-if='getEditar()'
                            title='Editar'
                            width='45%'
                            :icon='mdiPencilOutline'
                            type='green'
                            event='click'
                            @click='editarEspelhamento(row.escodigo)'
                        />
                        <simpleButton
                            v-if='getExcluir()'
                            title='Excluir'
                            width='45%'
                            type='red'
                            :icon='mdiTrashCanOutline'
                            event='click'
                            @click='modalExcluirEspelhamento(row.escodigo)'
                        />
                    </template>
                </tableLista>
            </div>
            <template>
                <modalEagle
                    :title="'Cadastrar espelhamento'"
                    id="modal-espelhamento"
                    size="lg">
                    <slot slot="modalBody">
                        <b-overlay :show="show" rounded="sm">
                            <div class="col-sm-12 row nopadding">
                                <div class="col-sm-6 nopadding">
                                    <selectAll
                                        ref="modalEmpresas"
                                        nameForRadio='seletorEmpresas'
                                        :labels='[{description:"Empresa*"}]'
                                        :isMultiple='false'
                                        :optionsArray='optselectEmpresas'
                                        :selected="selectEmpresa"
                                        @changeSelect='changeEmpresasModal'
                                    />
                                </div>
                                <div class="col-sm-6 nopadding">
                                    <selectAll
                                        ref="selectModalGerenciadoras"
                                        nameForRadio='seletorGerenciadora'
                                        :labels='[{description:"Gerenciadora*"}]'
                                        :isMultiple='false'
                                        :optionsArray='optselectGerenciadoras'
                                        :disabled="$v.empresasModalSelecionadas.$invalid"
                                        :loading="loadingSelect"
                                        :selected="selectGerenciadora"
                                        @changeSelect='changeGerenciadorasModal'
                                    />
                                </div>
                                <div class="col-sm-6 mt-2 nopadding">
                                    <selectAll
                                        ref="selectModalVeiculos"
                                        nameForRadio='seletorVeiculos'
                                        :labels='[{description:"Veiculos*"}]'
                                        :isMultiple='true'
                                        :optionsArray='optselectVeiculos'
                                        :disabled="$v.empresasModalSelecionadas.$invalid"
                                        :loading="loadingSelect"
                                        :selected="selectVeiculos"
                                        @changeSelect='changeVeiculosModal'
                                    />
                                </div>
                            </div>
                        </b-overlay>
                    </slot>
                    <slot slot="modalFooter">
                        <div class="d-flex flex-row nopadding">
                            <simpleButton
                                class ="p-2"
                                :icon="mdiCloseThick"
                                text="Cancelar"
                                event="click"
                                type="red"
                                @click="fecharModal"
                            />
                            <simpleButton
                                class ="p-2"
                                :icon="mdiCheckBold"
                                text="Salvar"
                                event="click"
                                type="blue"
                                :disabled="$v.$invalid"
                                @click="salvarEspelhamento"
                            />
                        </div>
                    </slot>
                </modalEagle>
            </template>
            <modalDeleta
                :id="1"
                @confirmaExclusao="excluirEspelhamento">
            </modalDeleta>
        </div>
    </eaglePanel>
</template>

<script>
import {mdiCarConnected, mdiPlus,
         mdiPencilOutline, mdiCloseThick, 
         mdiCheckBold, mdiTrashCanOutline } from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import {mapGetters} from 'vuex'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { required } from 'vuelidate/lib/validators'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'

export default {
    name:'ListagemGerenciadoras',
    components:{
        'eaglePanel'   : require("@/components/Atom/Panel/PanelEagle").default,
        'titulo'       : require('@/components/Atom/Header/Titulo').default,
        'selectAll'    : require('@/components/Atom/Select/SelectAll').default,
        'tableLista'   : require('@/components/Atom/Table/TableListagem').default,
        'simpleButton' : require('@/components/Atom/Buttons/SimpleButton').default,
        'modalEagle'   : require('@/components/Atom/Modal/ModalEagle').default,
        'modalDeleta'  : require('@/components/Atom/ModalSpecific/ModalDeleta').default,
    },
    data() {
        return {
            mdiCarConnected:mdiCarConnected,
            mdiPlus:mdiPlus,
            mdiPencilOutline:mdiPencilOutline,
            mdiCloseThick:mdiCloseThick,
            mdiCheckBold:mdiCheckBold,
            mdiTrashCanOutline:mdiTrashCanOutline,
            baseUrl:'/administrativo/espelhamento/',
            optselectEmpresas: new EmpresasService().Get(),
            optselectGerenciadoras : [],
            optselectVeiculos: [],
            empresasSelecionadas : [],
            titulosListagem:[
                {name: 'Veículos',     number: 'esveiculos'},
                {name: 'Gerenciadoras',     number: 'genomefantasia'},
                {name: 'Empresa', number: 'clfantasia'},
                {name: 'Data ativação', number: 'data'},
            ],
            dataListagem: [],
            loadingSelect: false,
            loading: false,
            show: false,
            escodigo : '',
            gerenciadorasSelecionadas : [],
            empresasModalSelecionadas : [],
            veiculosSelecionados      : [],
            selectEmpresa : [],
            selectGerenciadora: [],
            selectVeiculos: [],
        }
    },

    validations:{
		empresasModalSelecionadas:{ required },
        veiculosSelecionados:{ required },
        gerenciadorasSelecionadas:{ required },
	},

    methods: {
        ...mapGetters(['getExcluir', 'getEditar', 'getCadastrar']),

        changeEmpresas(empresas){
            this.empresasSelecionadas = empresas
            this.listarEspelhamentos()
        },

        changeEmpresasModal(empresas){
            this.empresasModalSelecionadas = empresas
            if(empresas.length > 0){
                this.buscaVeiculos(empresas)
            } else {
                this.gerenciadorasSelecionadas = []
                this.veiculosSelecionados = []
                this.selectGerenciadora = []
                this.selectVeiculos = []
            }
        },

        changeGerenciadorasModal(gerenciadoras){
            this.gerenciadorasSelecionadas = gerenciadoras
        },

        changeVeiculosModal(veiculos){
            this.veiculosSelecionados = veiculos
        },

        listarEspelhamentos(){
            this.loading = true
            new HttpRequest().Post(this.baseUrl+'listar', this.empresasSelecionadas)
            .then((dados)=>{
                this.dataListagem = dados.data.dados
                this.loading = false
            })
        },

        novoEspelhamento(){
            this.empresasModalSelecionadas = []
            this.gerenciadorasSelecionadas = []
            this.veiculosSelecionados = []
            this.selectEmpresa = []
            this.selectGerenciadora = []
            this.selectVeiculos = []
            this.escodigo = ''
            this.$bvModal.show('modal-espelhamento');
        },

        fecharModal(){
            this.$bvModal.hide('modal-espelhamento');
        },

        salvarEspelhamento(){
            this.show = true
            var obj = {
                'codigo' : this.escodigo,
                'esveiculos' : this.veiculosSelecionados,
                'escodigogerenciadora' : this.gerenciadorasSelecionadas[0],
                'escodigocliente' : this.empresasModalSelecionadas[0],
            }
            new HttpRequest().Post(this.baseUrl+'salvar', obj)
            .then((dados)=>{
                if(dados.code === 201){
                    this.$bvModal.hide('modal-espelhamento');
                    this.listarEspelhamentos()
                }
                this.show = false
            })
        },

        editarEspelhamento(codigo){
            this.escodigo = codigo
            this.$bvModal.show('modal-espelhamento');
            this.buscaDadosEdicao()
        },

        modalExcluirEspelhamento(codigo){
            this.escodigo = codigo
            this.$bvModal.show('modal-deleta-'+1)
        },

        excluirEspelhamento(){
            this.loading = true
            new HttpRequest().Post(this.baseUrl+'excluir', {'codigo': this.escodigo})
            .then((dados)=>{
                this.listarEspelhamentos()
                this.loading = false
            })
        },

        buscaDadosEdicao(){
            this.show = true
            new HttpRequest().Post(this.baseUrl+'editar', this.escodigo)
            .then((dados)=>{
                this.selectEmpresa = dados.data.empresa
                this.selectGerenciadora = dados.data.gerenciadora
                this.selectVeiculos = dados.data.veiculos
                this.show = false
            })
        },

        buscaVeiculos(empresa){
            this.loadingSelect = true
            new FiltrosService().dados_filtros(empresa, ['V', 'G'])
                .then((dados)=>{
                    this.optselectVeiculos = dados.V
                    this.optselectGerenciadoras = dados.G
                    this.loadingSelect = false
                })
        },
    },
}
</script>

<style lang="scss" scoped>

</style>