<template>
    <div>
        <div class="w100" style="display: inline-flex">
            <div class="prw w100" style="display: inline-flex">
                <td v-if="arrayDados.irstatus != 'R'" class="pdRow" style="width: 30px !important"
                    @click="toggleClicado(arrayDados)" v-b-toggle="arrayDados.ircodigo + '' + arrayDados.ropontosaida">
                    <span>
                        <b-icon :icon="arrayDados.clicado ? 'chevron-down' : 'chevron-right'" font-scale="1.1" />
                    </span>
                </td>

                <td v-else style="width: 58px !important" class="pdRow">
                </td>

                <div class="qw pdRow">{{ arrayDados.irordem }}</div>
                <div class="pw pdRow">{{ arrayDados.irnome }}</div>

                <div class="sw pdRow d-flex justify-content-center align-content-center">
                    <baseIcon :class="arrayDados.iconeCor" size="18" :icon="descobreIcone(arrayDados.icone)" />
                </div>

                <div class="qw pdRow">{{ arrayDados.irordemrealizado }}</div>
                <div class="pw pdRow">{{ arrayDados.irnome }}</div>

                <div class="ew nopadding pdRow">
                    <div class="styleBorderEstimativa">
                        {{ arrayDados.irdata }}
                        {{ arrayDados.irtempoprevisto }} - P
                    </div>
                    <div class="styleBorderEstimativa">
                        {{ arrayDados.irdata_hora_evento ? ( arrayDados.irdata_hora_evento  + '- R') : ('Em andamento') }}
                    </div>
                </div>
                <div class="ew pdRow">{{ arrayDados.irtempoparado == null ? ('00:00:00') : (arrayDados.irtempoparado) }}
                </div>
                <div class="tpw pdRow">{{ arrayDados.tipoParada }}</div>
                <div class="dow pdRow">{{ arrayDados.irdocumento == null ? ('Não há documento') : (arrayDados.irdocumento)
                }}
                </div>
                <div @click="changeDocumentos(arrayDados.ircodigo)" class="sdw icon-style d-flex justify-content-center">
                    <baseIcon class="cursor-pointer nopadding" :icon="mdiEyePlusOutline" size="20" />
                </div>
                <div v-if="coleta" @click="changeTicket(arrayDados)" class="sdw icon-style d-flex justify-content-center">
                    <baseIcon class="cursor-pointer nopadding" :icon="mdiPaperclip" size="20" />
                </div>
            </div>
        </div>

        <!-- judescricao -->

        <div :key="arrayDados.ircodigo + '' + arrayDados.ropontosaida">
            <div colspan="12" class="p-0 pb-3">
                <b-collapse class="w100" :id="arrayDados.ircodigo + '' + arrayDados.ropontosaida">
                    <div v-if="arrayDados.detalhes.length > 0">
                        <div v-for="(item, index) in arrayDados.detalhes" v-bind:key="index" :class="cor"
                            class="styleBorderItemDetalhes"
                            style="padding: 5px; width: 98%; margin: auto; margin-bottom: 5px;">
                            <div
                                class="d-flex justify-content-between align-items-center styleTableDetalhesItens mt-2 font-bold">
                                <div style="width: 30%">Data/Hora</div>
                                <div style="width: 30%">Quantidade</div>
                                <div style="width: 30%">Unidade</div>
                                <div style="width: 30%">Cubagem</div>
                                <div style="width: 30%">Peso</div>
                                <div style="width: 30%">Valor</div>
                                <div style="width: 30%">Observações</div>
                            </div>

                            <div class="d-flex justify-content-between align-items-center styleTableDetalhesItens mt-2">
                                <div style="width: 30%">{{ arrayDados.irdata_hora_evento }}</div>
                                <div style="width: 30%">{{ item.ircquantidade.length > 0 ? (item.ircquantidade) :
                                    (0) }}
                                </div>
                                <div style="width: 30%">{{ item.ircum.length > 0 ? (item.ircum) : (0) }}</div>
                                <div style="width: 30%">{{ arrayDados.ircubagem }} m³</div>
                                <div style="width: 30%">{{ arrayDados.irpeso }} Kg</div>
                                <div style="width: 30%">R$ {{ arrayDados.irvalor }}</div>
                                <div style="width: 30%">{{ item.ircobservacao }}</div>
                            </div>
                        </div>
                    </div>

                    <div v-else-if='arrayDados.irstatus == "J"'>
                        <div class="P styleBorderItemDetalhesJustificadas"
                            style="padding: 5px; width: 98%; margin: auto; margin-bottom: 5px;">
                            <div
                                class="d-flex justify-content-start align-items-center styleTableDetalhesItens mt-2 font-bold">
                                <div style="width: 20%">Data/Hora</div>
                                <div style="width: 20%">Justificativa do motorista</div>
                                <div style="width: 60%">Observação do motorista</div>
                            </div>

                            <div class="d-flex justify-content-start align-items-center styleTableDetalhesItens mt-2">
                                <div style="width: 20%">{{ arrayDados.irdata_hora_evento }}</div>
                                <div style="width: 20%">{{ arrayDados.judescricao }}</div>
                                <div style="width: 60%">{{ arrayDados.justificativaMotorista }}</div>
                            </div>
                        </div>
                    </div>

                    <div v-else>
                        <div class="styleSemItem">
                            Nenhuma informações
                        </div>
                    </div>

                </b-collapse>
            </div>
        </div>
    </div>
</template>

<script lang="js">
import {
    mdiCheckBold, mdiMinusThick, mdiCloseThick, mdiClipboardEditOutline,
    mdiDownloadOutline, mdiEyePlusOutline, mdiPaperclip
} from '@mdi/js'
import Vue from 'vue'

export default Vue.extend({
    components: {
        baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
    },

    data() {
        return {
            mdiCheckBold: mdiCheckBold,
            mdiMinusThick: mdiMinusThick,
            mdiCloseThick: mdiCloseThick,
            mdiClipboardEditOutline: mdiClipboardEditOutline,
            mdiDownloadOutline: mdiDownloadOutline,
            mdiEyePlusOutline: mdiEyePlusOutline,
            mdiPaperclip: mdiPaperclip,
        }
    },

    props: {
        arrayDados: {
            type: Object,
            required: true,
            default: () => [],
        },
        coleta: {
            type: Boolean,
            default: false,
        },
        cor: {
            type: String,
            default: '',
        }
    },

    methods: {
        toggleClicado(item) {
            this.$set(item, 'clicado', !item.clicado);
        },

        descobreIcone(icone) {
            switch (icone) {
                case 'mdiCheckBold': return this.mdiCheckBold;
                case 'mdiMinusThick': return this.mdiMinusThick;
                case 'mdiCloseThick': return this.mdiCloseThick;
                case 'mdiClipboardEditOutline': return this.mdiClipboardEditOutline;
                default: return;
            }
        },

        changeDocumentos(codigo) {
            this.$emit('changeDocumentos', codigo);
        },

        changeTicket(item) {
            this.$emit('changeTicket', item);
        }
    },
})
</script>

<style lang="scss">
.icon-style {
    text-align: center;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
}

.styleTableDetalhesItens {
    font-size: 13px;
    width: 98%;
    margin: auto;
}

.font-bold {
    font-weight: bold;
}

.styleBorderEstimativa {
    border: 2px solid #acacac;
    padding: 1.8px;
}

.styleBorderItemDetalhes {
    border: 1px solid #90ee90;
    margin: 5px;
    padding: 10px
}

.styleBorderItemDetalhesJustificadas {
    border: 1px solid #e3ee90;
    margin: 5px;
    padding: 10px
}


.styleSemItem {
    border: 1px solid #adadad;
    margin: 5px;
    padding: 10px;
    background-color: #a5a4a4;
    padding: 5px;
    width: 98%;
    margin: auto;
    margin-bottom: 5px;
    text-align: center;
    font-weight: bold;
}
</style>