<template>
    <panelEagle id='Fornecedores' :loading='carregando'>
        <div class="col-sm-12 nopadding row">
            <div class="col-sm-6">
                <tituloPage :icon='mdiAccountSupervisorCircle' titulo='Listagem de fornecedores'></tituloPage>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 divDontPrint">
                <basicButtonsCadastrosEIN
                    linkNew ='cadastrarFornecedor'
                    tipoNovo='button'
                    @exportarCadastro="exportarCadastro"
                    :loading ='loadingBotao'
                    :disabledDropdown="$v.dadosTabela.$invalid"
                />
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class='col-sm-12 row nopadding'>
            <div class="col-lg-4 col-md-12 nopadding divDontPrint">
                <selectAll
                    nameForRadio  ='Selecione*'
                    :isMultiple   ='true'
                    :optionsArray ='optEmpresas'
                    :labels       ='labelsEmp'
                    :hasSelectAll ='true'
                    :selected     ="selectedEmpresa"
                    @changeSelect ='changeEmpresa'/>
            </div>
            <div class="col-lg-8 col-md-12 nopadding divDontPrint">
                <buttonsFilters
                    :label='labelBot'
                    :arrayButtons='botoes'
                    @buttonsFiltersChange='buttonsFiltersChange'/>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class="col-12 nopadding">
            <tableListagem
                :data = 'dadosTabela'
                :titles ='titles' 
                deleteEdit ='editStatus'
                @alterarStatus='alterarStatusCadastro'
                @editarCadastro='editarCadastro'>
            </tableListagem>
        </div>
    </panelEagle>
</template>
<script>
import Vue  from 'vue'
import Vuex from '@/store/index'
import {mdiAccountSupervisorCircle, mdiCheckBold } from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default Vue.extend({

    components:{
        'basicButtonsCadastrosEIN' : require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
        'buttonsFilters'           : require('@/components/Atom/Buttons/ButtonsFilters').default,
        'tableListagem'            : require('@/components/Atom/Table/TableListagem').default,
        'panelEagle'               : require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage'               : require('@/components/Atom/Header/Titulo').default,
        'selectAll'                : require('@/components/Atom/Select/SelectAll').default,

    },

    validations:{
        dadosTabela    : { required },
    },

    data(){
        return {
            mdiCheckBold:mdiCheckBold,
            mdiAccountSupervisorCircle:mdiAccountSupervisorCircle,
            usuraio:'',
            verifica : 0,
            optEmpresas   : new EmpresasService().Get(),
            selectedEmpresa : [],
            valueEmpresas :[],
            labelsEmp     :[{
                indexDFH    : 'EM',
                description : 'Empresas'
            }],
            carregando:false,
            loadingTabela : false,
            dadosTabela   : [],
            titles :[
                {'number':'nome'      , 'name':'Nome'},
                {'number':'telefone'  , 'name':'Telefone'},
                {'number':'empresa'   , 'name':'Empresa'},
            ],

            loadingBotao  :[false, false, false],
            valueBotaoFiltros  : '',
            labelBot : 'Ativo/Inativo',
            botoes      : [
                {
                    'isSelected': true,
                    'value' : 'A',
                    'width' : '32.6%',
                    'title' : 'Fornecedores ativos',
                    'text'  : 'Ativos',
                    'icon'  : ''
                },
                {
                    'isSelected': false,
                    'value' : 'I',
                    'width' : '32.6%',
                    'title' : 'Fornecedores inativos',
                    'text'  : 'Inativos',
                    'icon'  : ''
                },
                {
                    'isSelected': false,
                    'value' : 'T',
                    'width' : '32.6%',
                    'title' : 'Todos os fornecedores',
                    'text'  : 'Todos',
                    'icon'  : ''
                },
            ],
            uriBase: '/manutencoes/cadastros/forncedores/',            
        }
    },

    methods:{
        changeEmpresa(value){
            this.valueEmpresas = value
            let uri = this.uriBase+'tabela'
            this.carregando = true
            let obj = {'empresa':this.valueEmpresas, 'filtro':this.valueBotaoFiltros}
            new HttpRequest().Post(uri, obj)
            .then(dados=>{
                this.dadosTabela = dados.data.retorno
                this.carregando = false
            })
        },
        async exportarCadastro(value){   
            var root =  process.env.VUE_APP_ROOT;
            if(this.valueEmpresas.length != 0){
                switch(value){
                    case 'pdf':
                        this.loadingBotao = [true, false, false]
                        break;
                    case 'xls':
                        this.loadingBotao = [false, true, false]
                        break;
                    case 'csv':
                        this.loadingBotao = [false, false, true]
                        break;
                    default:
                        break;
                }
                let uri = this.uriBase+'exportar'
                let obj = {'empresa':this.valueEmpresas, 'filtro':this.valueBotaoFiltros, 'tipo':value}
                var dados = await new HttpRequest().Post(uri, obj)
                window.open(root+'/'+dados.data.exportar)
                this.loadingBotao = [false, false, false]
            }
        },
        
        alterarStatusCadastro(value){
            let uri = this.uriBase+'status'
            new HttpRequest()
            .Post(uri, {'id':value}).then(()=>{
                this.changeEmpresa(this.valueEmpresas)
            })
        },
        editarCadastro(value){
            this.$router.push({name:'cadastrarFornecedor', params:{id:value, cp:false}})
        },
        buttonsFiltersChange(value){
            this.valueBotaoFiltros = value
            this.botoes[0].icon = ''
            this.botoes[1].icon = ''
            this.botoes[2].icon = ''
            if(value == "A") {
                this.botoes[0].icon = mdiCheckBold
            }
            else if(value == "I"){
                this.botoes[1].icon = mdiCheckBold
            }
            else if(value == "T"){
                this.botoes[2].icon = mdiCheckBold
            }
            if(this.valueEmpresas.length != 0){
                var d = this.changeEmpresa(this.valueEmpresas)
            }             
        },

        ...mapGetters(['getMaster']),
    },
    async mounted(){ 
        this.usuraio = this.$store.getters.getMaster
        if(!this.getMaster()){
			this.selectedEmpresa = this.optEmpresas
		}
        // this.$router.go(0)
        
    }
})
</script>
