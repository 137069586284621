<template>
    <div>
        <div v-show="mostrarTrajeto" class="visualizarTrajeto px-0 margemViagemCardSemInfoVeiculo ml-1 mt-1"
            :class="{ margemViagemCardComInfoVeiculo: exibeInfoVeiculo == true }">
            <div class="col-sm-12 p-0 pb-2 styleDivBlock">
                <div class='col-sm-12 m-0 px-0'>
                    <div class="displayFlex col-sm-12">
                        <div class="col-sm-11">
                            <span> Informações do trajeto </span>
                        </div>
                        <div class='m-0 p-0 col-sm-1  mt-0' style="pointer-events: auto;">
                            <b-button variant='danger' squared class='mr-2' size='sm' @click="fecharTrajeto"
                                style="margin-left: 5px;">
                                <base-icon :size='16' :icon='iconFechar' />
                            </b-button>
                        </div>
                    </div>
                    <div class="linhaTrajeto"></div>
                    <div class="tamanhoFonte">
                        <p class="ml-2" style="text-align: start;">{{ 'Endereço: ' + enderecoViagemTrajeto }}</p>
                    </div>
                    <div class=" d-flex justify-content-between mt-4    ">
                        <div class="tamanhoFonte ml-2 mt-1 mb-1">
                            <span> Distância: <b>{{ + kmViagemTrajeto + "km" }} </b></span>
                        </div>
                        <div class="tamanhoFonte  mr-2  mt-1 mb-1">
                            <span> Tempo: <b>{{ tempoViagemTrajeto }}</b> </span>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-sm-12 row p-0 m-0">
                <b-button squared block class="botaoRetornarViagem" @click="voltarViagem">
                    <b>Retornar viagens</b>
                </b-button>
            </div>
        </div>
        <div v-show="!criarTrajeto">
            <div id='visualizarViagens' class="visualizarViagens margemViagemCardSemInfoVeiculo ml-1 mt-1"
                :class="{ margemViagemCardComInfoVeiculo: exibeInfoVeiculo == true }">

                <div v-show="!criarTrajeto" class='displayFlex justify-content-between m-0 pb-3 bg-white'
                    style="z-index: 999; position: sticky; top: -10px;">
                    <div class='col-sm-7 row m-0 p-0 mt-2'>
                        <base-icon :size='18' id='icone_draggable' class='ml-3 mr-2' :icon='iconTitutlo' />
                        <span class="mt-0" id='titulo_draggable'>
                            Visualizar Viagens
                        </span>
                    </div>
                    <div class='m-0 p-0 mt-1'>
                        <b-button variant='danger' squared class='mr-2' size='sm' @click='fechaPosicoesVeiculo'>
                            <base-icon :size='16' :icon='iconFechar' />
                        </b-button>
                    </div>
                </div>
                <panelEagle id="painelViagem" :loading="loadingPanel">
                </panelEagle>
                <!-- Checkbox de excesso de velocidade -->
                <div v-if="requisicaoViagemSucesso" class="checksExcessoVelocidade">
                <b-checkbox
                    v-model="exibirExcessoVelocidade"
                    id="selecionarTodos"
                    class="checkBoxCabecalho"
                    @change="exibeExcessoVelocidade"
                    >
                    Visualizar excessos de velocidade
                </b-checkbox>
                </div>
          
                <div v-if="requisicaoViagemSucesso">
                    <div class="divShowViagens" v-show="!criarTrajeto">
                        <div v-if="!loadingPanel" class="mt-3">
                            <div class='col-sm-12 cardViagem ml-0 mr-0 pl-0 pr-0 mt-2 '
                                :class="{ corBotaoSelecionado: botaoSelecionado === item }" v-for="item, index in data.data"
                                :key="index" @click="mostraRastro(item)">
                                <!-- Informações da viagem -->
                                <div
                                    class='col-sm-12 d-flex justify-content-between row m-0 p-0 mb-1 pb-1 pt-1 linhaViagemRealizada'>
                                    <div class='col-sm-3 m-0 p-0 tamanhoFonte mt-1'>
                                        <template v-if="item.enderecoDestino"> {{ index + 1 }} ° Viagem</template>
                                        <template v-else>Viagem Atual</template>
                                    </div>
                                    <div class='col-sm-3 m-0 p-0 tamanhoFonte mt-1 text-truncate'>
                                        {{ item.data }}
                                    </div>
                                    <div class='col-sm-3 m-0 p-0 tamanhoFonte row'>
                                        <div class='col-sm-1 m-0 p-0 ml-1'>
                                            <base-icon :size='18' id='icone_draggable' class='m-0 p-0 colorGreen'
                                                :icon='iconFlag' />
                                        </div>
                                        <div class='col-sm-10 m-0 p-0'>
                                            <span class="col-sm-9 tamanhoFonte">
                                                {{ item.horaInicio }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class='col-sm-3 m-0 p-0 tamanhoFonte row' v-if="item.enderecoDestino">
                                        <div class='col-sm-1 m-0 p-0'>
                                            <base-icon :size='18' id='icone_draggable' class='m-0 p-0 colorRed'
                                                :icon='iconFlagCheckered' />
                                        </div>
                                        <div class='col-sm-10 m-0 p-0 ml-1'>
                                            <span class="col-sm-9 tamanhoFonte">
                                                {{ item.horaFim }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- início e fim de viagem -->
                                <div class='col-sm-12 row m-0  nopadding mb-2'>
                                    <div class='col-sm-12  m-0 tamanhoFonte nopadding text-truncate text-left'>
                                        <base-icon :size='16' id='icone_draggable' class='col-sm-1 m-0 nopadding colorGreen'
                                            :icon='iconFlag' />
                                        <span class="col-sm-11 tamanhoFonte nopadding text-truncate"
                                            :title="item.enderecoOrigem">
                                            {{ item.enderecoOrigem }}
                                        </span>
                                    </div>
                                    <div class='col-sm-12 m-0 p-0 tamanhoFonte nopadding text-truncate text-left'
                                        v-if="item.enderecoDestino">
                                        <base-icon :size='16' id='icone_draggable' class='col-sm-1 m-0  nopadding colorRed'
                                            :icon='iconFlagCheckered' />
                                        <span class="col-sm-11 tamanhoFonte nopadding text-truncate"
                                            :title="item.enderecoDestino">
                                            {{ item.enderecoDestino }}
                                        </span>
                                    </div>
                                </div>
                                <!-- Informação de movimentação e paradas -->
                                <div class='col-sm-12 d-flex justify-content-between row m-1 p-0'>
                                    <div class='col-sm-3 m-0 p-0 tamanhoFonte row'>
                                        <div class='col-sm-3 m-0 p-0'>
                                            <base-icon :size='16' id='icone_draggable' class='m-0 p-0'
                                                :icon='iconMapClockOutline' />
                                        </div>
                                        <div class='col-sm-9 m-0 p-0'>
                                            <span class="tamanhoFonte">
                                                {{ item.tempoTotalViagem }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class='col-sm-3 m-0 p-0 tamanhoFonte row'>
                                        <div class='col-sm-3 m-0 p-0'>
                                            <base-icon :size='16' id='icone_draggable' class='m-0 p-0 ml-1 colorGreen'
                                                :icon='iconClockFast' />
                                        </div>
                                        <div class='col-sm-9 m-0 p-0'>
                                            <span class="tamanhoFonte">
                                                {{ item.tempoPercorrido }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class='col-sm-3 m-0 p-0 tamanhoFonte row'>
                                        <div class='col-sm-3 m-0 p-0'>
                                            <base-icon :size='16' id='icone_draggable' class='m-0 p-0 colorYellow'
                                                :icon='iconClockTimeNineOutline' />
                                        </div>
                                        <div class='col-sm-9 m-0 p-0'>
                                            <span class="tamanhoFonte">
                                                {{ item.tempoParaIgnicaoLigada }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class='col-sm-3 m-0 p-0 tamanhoFonte row'>
                                        <div class='col-sm-3 m-0 p-0'>
                                            <base-icon :size='16' id='icone_draggable' class='m-0 p-0 colorRed'
                                                :icon='iconClockTimeNineOutline' />
                                        </div>
                                        <div class='col-sm-9 m-0 p-0 text-truncate'>
                                            <span class="tamanhoFonte">
                                                {{ item.tempoParaIgnicaoDesligada }}
                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <!-- Informações de velocidade -->
                                <div class='col-sm-12 row m-0 d-flex justify-content-between p-0 pb-2'>
                                    <div class='col-sm-4 m-0 p-0 row'>
                                        <div class="ml-1">
                                            <base-icon :size='16' id='icone_draggable' class='m-0 p-0'
                                                :icon='iconRoadVariant' />
                                        </div>
                                        <div class='col-sm-5 m-0 p-0'>
                                            <span class="tamanhoFonte">
                                                {{ item.distanciaPercorrida + ' km' }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class='col-sm-4 m-0 p-0 row'>
                                        <div class="">
                                            <base-icon :size='16' id='icone_draggable' class='m-0 p-0 colorGreen'
                                                :icon='iconFlagSpeedometerMedium' />
                                        </div>
                                        <div class='col-sm-6 m-0 p-0'>
                                            <span class="tamanhoFonte">
                                                {{ item.velocidadeMedia + ' km/h' }}
                                            </span>
                                        </div>
                                    </div>
                                    <div class='col-sm-4 m-0 p-0 row'>
                                        <div>
                                            <base-icon :size='16' id='icone_draggable' class='m-0 p-0 colorRed'
                                                :icon='iconSpeedometer' />
                                        </div>
                                        <div class='col-sm-6 m-0 p-0'>
                                            <span class="tamanhoFonte">
                                                {{ item.maiorVelocidade + 'km/h' }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div v-else-if="requisicaoViagemSucesso == false">
                    <statusInformation v-show="true" typeBar="div" statusBar="error" />
                </div>
                <div v-if="requisicaoViagemSucesso == true">
                    <div style="position: fixed; margin-top: -18px; width: 330px; margin-left: -5px">
                        <div class='displayFlex justify-content-between m-0 pb-3 bg-white'
                            style="z-index: 999; position: sticky; top: 0; height: 58px;">
                            <div>
                                <b-button squared block class="botaoCriarTrajeto tamanhoFonte" style="width: 310px; margin-left: 10px;"
                                    @click="criaTrajeto">
                                    <b style="font-size: 11px;"> Criar trajeto </b>
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import {
    mdiFlag,
    mdiFlagCheckered,
    mdiClockTimeNineOutline,
    mdiMapClockOutline,
    mdiRoadVariant,
    mdiClockFast,
    mdiSpeedometerMedium,
    mdiSpeedometer,
    mdiMapMarker,
    mdiCloseThick
} from '@mdi/js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { conectionError } from '@/Services/Helpers/swellHeper'
import BaseIcon from '@/components/Atom/Icon/BaseIcon.vue'
var decodePoly = require('@mapbox/polyline')
export default {
    name: 'VisualizarViagens',
    components: {
        BaseIcon,
        'panelEagle': require('@/components/Atom/Panel/PanelEagle.vue').default,
        statusInformation: require("@/components/Atom/StatusInformation/StatusInformation").default,
    },
    props: {
        placa: {
            type: String,
            default: '',
        },
        dataInicio: {
            type: String,
            default: '',
            required: false
        },
        dataFim: {
            type: String,
            default: '',
            required: false
        },
        exibeInfoVeiculo: {
            type: Boolean,
            default: true,
        },
        pontoInicial: {
            type: Object,
        },
        pontoFinal: {
            type: Object,
        },
    },
    data() {
        return {
            iconFlag: mdiFlag,
            iconFlagCheckered: mdiFlagCheckered,
            iconClockTimeNineOutline: mdiClockTimeNineOutline,
            iconMapClockOutline: mdiMapClockOutline,
            iconRoadVariant: mdiRoadVariant,
            iconClockFast: mdiClockFast,
            iconFlagSpeedometerMedium: mdiSpeedometerMedium,
            iconSpeedometer: mdiSpeedometer,
            iconTitutlo: mdiMapMarker,
            iconFechar: mdiCloseThick,
            data: [],
            dataRastros: [],
            dadosOsmr: [],
            msgErroPeriodo: null,
            arrayRastro: [],
            arrayParadas: [],
            botaoSelecionado: null,
            requisicaoViagemSucesso: true,
            semDados: false,
            criarTrajeto: false,
            mostrarTrajeto: true,
            objRetornoOsmr: {},
            enderecoViagemTrajeto: '',
            kmViagemTrajeto: '0',
            tempoViagemTrajeto: '0h 0m',
            exibirExcessoVelocidade: false,
            arrayExcessoVelocidade: [],
            rotaTrajeto: '',
            loadingPanel: false,
            primeiraChamadaConcluida: false,
        }
    },
    methods: {
        /**
        * @param {object} obj.placa
        * @param {object} obj.dataCompletaInicio
        * @param {object} obj.dataCompletaFinal
        * @author Otávio 🦆
        * @description Envia os dados para rastro.
        * @return {obj} dataRastro 
        */
        async getRastroVeiculo(obj) {
            let uri = '/finder/viagem/rastro';
            this.loadingPanel = true;
            this.$emit('requisicao-viagem', true);
            this.$emit('limpa-rastro-viagem', this.arrayRastro);
            this.$emit('limpa-excesso-velocidade');
            let objRetornoConsulta;
            await new HttpRequest()
                .Post(uri, obj)
                .then((dataRastros) => {
                    objRetornoConsulta = dataRastros.data;
                    this.arrayRastro = dataRastros.data.rotas.map((el) => {
                        return el.posicao.split(",").map(Number);
                    });

                    this.arrayParadas = dataRastros.data.paradas;
                    this.arrayExcessoVelocidade = dataRastros.data.excessoVelocidade;
                    this.emiteParadas();
                }).catch(() => {
                    conectionError();
                })
                .finally(() => {
                    let dataFim = objRetornoConsulta.rotas[objRetornoConsulta.rotas.length - 1].data;
                    let dadosMontaGrafico = {
                        dataIni: obj.dataIni,
                        dataFim: dataFim,
                        velocidadeMaxima: obj.velocidadeMaxima
                    }
                    this.exibeExcessoVelocidade();
                    this.emiteDadosGrafico(dadosMontaGrafico);
                    this.emiteDadosVelocidade(objRetornoConsulta);
                    this.loadingPanel = false
                    setTimeout(() => {
                        this.loadingPanel = false;
                        this.$emit('requisicao-viagem', false);
                    }, 2000); 
                });
            this.$emit('rastro-viagem', this.arrayRastro, obj, 'blue', false);
        },

        /**
        * @author Otávio 🦆
        * @description Faz o controle se será exibido ou não alertas de velocidade no mapa.
        */
        exibeExcessoVelocidade(){
            if(this.exibirExcessoVelocidade){
                this.$emit('excesso-velocidade', this.arrayExcessoVelocidade)
            }else{
                this.$emit('limpa-excesso-velocidade') 
            }
        },

        /**
        * @param {object} dadosViagem - informações da viagem.
        * @author Otávio 🦆
        * @description monta o objeto que o marcador dos pontos iniciais e finais adiciona no mapa 
        * e dispara para o mapa as informações dos pontos.
        */
        capturarPontoInicialFinalRota(dadosViagem){
            let pontoInicialRota = {
                polatitude: dadosViagem.latInicioRota,
                polongitude: dadosViagem.lonInicioRota,
                poendereco: dadosViagem.enderecoOrigem,
                podescricao: dadosViagem.nomePontoInicio,
                hora: dadosViagem.dataCompletaInicio
            }
            let pontoFinalRota = {
                polatitude: dadosViagem.latFimRota,
                polongitude: dadosViagem.lonFimRota,
                poendereco: dadosViagem.enderecoDestino,
                podescricao: dadosViagem.nomePontoFim,
                hora: dadosViagem.dataCompletaFinal
            }
            if (typeof dadosViagem.latFimRota !== 'undefined') {
                this.$emit('pontos-inicial-final-viagem', pontoInicialRota, pontoFinalRota);
            }else{
                this.$emit('pontos-inicial-final-viagem', pontoInicialRota);
            }
        },

        /**
        * @param {object} obj - objeto a ser enviado para o back-end.
        * @author Otávio 🦆
        * @description Função chamada dentro do onClick que recebe os dados do card e dispara as funções
        * botaoSelecionadoCor() e getRastroVeiculo()
        */
        mostraRastro(newValue) {
            let dadosReq = {};
            let dadosMontaGrafico = {};
            dadosReq = {
                placa: newValue.placa,
                dataIni: newValue.dataCompletaInicio,
                dataFim: newValue.dataCompletaFinal,
                emAndamento: newValue.emAndamento,
                velocidadeMaxima: newValue.velocidadeMaxima
            }

            dadosMontaGrafico = {
                dataIni: newValue.dataCompletaInicio,
                dataFim: newValue.dataCompletaFinal,
                velocidadeMaxima: newValue.velocidadeMaxima
            }

            if (newValue && this.primeiraChamadaConcluida) {
                this.botaoSelecionadoCor(newValue);
                this.$emit('limpa-excesso-velocidade');
                this.getRastroVeiculo(dadosReq);
                this.emiteDadosGrafico(dadosMontaGrafico);
                this.cardViagemPontoSelecionado();
                this.capturarPontoInicialFinalRota(newValue);
            } else {
                this.$emit("limpa-rastro-veiculo");
            }
        },

        /**
        * @author Otávio 🦆
        * @description reseta as informações do card de 
        * criar trajeto quando é realizada uma nova requisição
        */
        limpaInformacoesTrajeto() {
            this.tempoViagemTrajeto = '0h 0m';
            this.kmViagemTrajeto = '0';
            this.enderecoViagemTrajeto = '';
        },

        /**
        * @author Otávio 🦆
        * @description aguarda a variavel pontoFinal ser alterada, conforme é alterada
        * é feito a requisição para gerar a polyline e converte para a rota para os pontos de 
        * latitude e longitude no mapa.
        * @return {obj} dataRastro 
        */
        async cardViagemPontoSelecionado() {
            if (this.criarTrajeto) {
                this.$emit("limpa-rastro-viagem", [])
                let pontosIniciaisFinais = [{
                    "polatitude": this.pontoInicial.lat,
                    "polongitude": this.pontoInicial.lng,
                    "regiao": 0,
                    "peso": 0,
                    "nome": "",
                    "codigo": null
                },
                {
                    "polatitude": this.pontoFinal.lat,
                    "polongitude": this.pontoFinal.lng,
                    "regiao": 0,
                    "peso": "0",
                    "nome": "",
                    "fixo": false,
                    "codigo": null
                }
                ];
                if (Object.values(this.pontoFinal).length > 0) {
                    const obj = {
                        inicio: [null, null],
                        pontos: pontosIniciaisFinais,
                        velocidadeMedia: 0,
                        obedecerOrdenacao: true,
                        tipo: 1,
                    };
                    let uri = '/roteirizador/rota/manual/rota/tipo/gerar';
                    await new HttpRequest()
                        .Post(uri, obj)
                        .then((dadosOsmr) => {
                            this.kmViagemTrajeto = Math.round(dadosOsmr.data.totalKms);
                            this.enderecoViagemTrajeto = dadosOsmr.data.ponto[1].name;
                            this.tempoViagemTrajeto = this.formatarSegundosParaHoras(dadosOsmr.data.tempoSegundos);
                            this.rotaTrajeto = dadosOsmr.data.rota;
                        }).catch(() => {
                            conectionError()
                        })
                    let arrayRastro = decodePoly.decode(this.rotaTrajeto);
                    this.$emit('rastro-viagem', arrayRastro, this.placa, 'blue', false);
                }
            }
        },

        /**
        * @param {String} segundos - com o segundos para ser convertido
        * @author Otávio 🦆
        * @description formata e converte os segundos para o padrão 0h 0m 
        */
        formatarSegundosParaHoras(segundos) {
            let horas = Math.floor(segundos / 3600);
            let minutos = Math.floor((segundos % 3600) / 60);
            let formatoTempo = `${horas}h ${minutos}m`;
            return formatoTempo;
        },

        /**
        * @author Otávio 🦆
        * @description Faz o controle de estado da variável criarTrajeto 
        * e dispara os emit para limpar paradas e viagem quando o botão
        * 'Criar trajeto' é pressionado
        */
        criaTrajeto() {
            this.$emit("limpa-paradas-viagem");
            this.criarTrajeto = true;
            this.mostrarTrajeto = true;
            this.limpaInformacoesTrajeto();
            this.$emit('voltar-viagem', false);
            this.$emit('limpa-rastro-viagem', []);
            this.$emit("criar-trajeto", this.criarTrajeto);
        },

        /**
        * @author Otávio 🦆
        * @description Faz o controle de estado da variável criarTrajeto 
        * e dispara os emit para limpar viagem e avisar que a aba de viagens
        * ação ocorre quando o botão 'Retornar viagens' é pressionado
        */
        voltarViagem() {
            this.criarTrajeto = false;
            this.limpaInformacoesTrajeto();
            this.$emit('limpa-rastro-viagem', []);
            this.$emit('voltar-viagem', true);
        },

        /**
        * @author Otávio 🦆
        * @description Emite o evento que avisa o mapa que o trajeto foi fechado 
        */
        fecharTrajeto() {
            this.$emit('limpa-rastro-viagem', []);
            this.mostrarTrajeto = false;
            this.criarTrajeto = false;
            this.limpaInformacoesTrajeto();
            this.$emit('limpa-rastro-viagem', []);
            this.fechaPosicoesVeiculo();
        },

        /**
        * @author Otávio 🦆
        * @description Emite o evento que avisa o mapa as paradas 
        * e localização das paradas
        */
        emiteParadas() {
            var paradas = this.arrayParadas;
            this.$emit("paradas-veiculo-viagem", paradas);
        },

        /**
        * @author Otávio 🦆
        * @description Emite o evento para receber os dados de velocidade
        * para geração dos gráficos
        */
        emiteDadosVelocidade(dataRastro) {
            let dataVelocidade = dataRastro.rotas.map(rotas => {
                return { 0: rotas.data, 1: rotas.velocidade, 2: rotas.endereco };
            });
            this.$emit('dados-popula-grafico-velocidade', dataVelocidade);
        },

        /**
        * @param {object} obj - objeto a ser enviado para o back-end.
        * @param {string} obj.dataFim - contendo a data + horario do fim da
        * pesquisa. ex: 20/08/2021 14:00:00
        * @param {data} obj.dataIni - contento a data + horario fo final 
        * da pesquisa - ex: 23/08/2021 10:00:00
        * @param {string} obj.placa
        * @author Otávio 🦆
        * @description procura pelas posições do veículo.
        * Se não vier a placa, não faz a busca
        */
        iniciaVisualizarViagens(obj) {
            this.arrPosicoes = []
            this.obj = obj
            this.criarTrajeto = false;
            if (obj.placa) {
                this.$emit('limpa-rastro-viagem', this.arrayRastro);
                this.loadingPanel = true;
                this.$emit('requisicao-viagem', true);
                this.requisicaoViagemSucesso = true;
                let uri = '/finder/viagem/info';
                let requisicao = {
                    placa: obj.placa,
                    dataIni: obj.dataIni,
                    dataFim: obj.dataFim,
                }
                new HttpRequest().Post(uri, requisicao).then((data) => {
                    this.data = data;
                    if (data.data.length == 0) {
                        this.requisicaoViagemSucesso = false;
                        return false;
                    } else {
                        this.requisicaoViagemSucesso = true;
                    }
                    this.primeiraChamadaConcluida = true;
                }).finally(() => {
                    if (this.data.data.length > 0) {
                        const objViagemAtual = {
                            placa: this.data.data[0].placa,
                            dataIni: this.data.data[0].dataCompletaInicio,
                            dataFim: this.data.data[0].dataCompletaFinal,
                            velocidadeMaxima: this.data.data[0].velocidadeMaxima,
                            emAndamento: this.data.data[0].emAndamento
                        }
                        this.getRastroVeiculo(objViagemAtual);
                        this.botaoSelecionadoCor(this.data.data[0]);
                        this.capturarPontoInicialFinalRota(this.data.data[0]);
                        this.$emit('voltar-viagem', true);
                    } else {
                        this.$emit('voltar-viagem', false);
                    }
                    setTimeout(() => {
                        this.loadingPanel = false;
                        this.$emit('requisicao-viagem', false);
                    }, 2000); 
                });
            }
        },

        /**
        * @author Otávio 🦆
        * @description Emite o evento para receber os dados iniciais do grafico
        * data e inicio da viagem e velocidade maxima do veiculo
        */
        emiteDadosGrafico(dadosGrafico) {
            this.$emit('dados-grafico', dadosGrafico);
        },

        /**
        * @param {obj} item
        * @author Otávio 🦆
        * @description pegamos o valor atual do item para deixar o mesmo selecionado 
        */
        botaoSelecionadoCor(item) {
            this.botaoSelecionado = item;
        },

        /**
        * @listens click - botão de fechar a janela de posições.
        * @author Desconhecido
        * @description avisa o mapa que a janela deve ser fechada 
        * e limpa a array de posições.
        * @fires fecha-posicoes.
        */
        fechaPosicoesVeiculo() {
            if (!this.loadingPanel) {
                this.$emit("limpa-paradas-viagem");
                this.$emit('limpa-rastro-viagem', this.arrayRastro);
                this.arrPosicoes = [];
                this.$emit('fecha-posicoes');
                this.$emit('limpa-excesso-velocidade');
            }
        },
    },
    watch: {
        pontoFinal: {
            handler: 'cardViagemPontoSelecionado',
            deep: true
        },
    },
}
</script>
<style lang="css">
    .visualizarViagens {
    height: 372px;
    width: 330px;
    overflow-x: hidden;
    border-radius: 3px;
    background-color: white;
    padding: 5px;
    position: absolute;
    z-index: 315;
    top: 0.5%;
    }

    .divShowViagens {
    height: 310px;
    }
    .checksExcessoVelocidade {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 2px;
    }

    .visualizarTrajeto {
    height: 160px;
    width: 320px;
    overflow: hidden;
    border-radius: 3px;
    background-color: white;
    padding: 5px;
    position: absolute;
    z-index: 315;
    top: 0.5%;
    border: 1px solid rgb(93, 225, 152);
    }

    .styleDivBlock {
    display: flex;
    justify-content: end !important;
    position: relative;
    }

    .displayFlex {
    display: flex;
    }

    .linhaTrajeto {
    height: 1.5px;
    width: 320px;
    margin: auto;
    margin-top: 8px;
    margin-bottom: 12px;
    background-color: #6c6f73;
    }

    .bloco .tamanhoFonte {
    margin-left: 5px;
    }

    .botaoCriarTrajeto {
    background-color: #007bff !important;
    color: white;
    margin-top: 25px;
    }

    .botaoRetornarViagem{
    background-color: #007bff !important;
    color: white;
    margin-top: 0px;
    }

    .margemViagemCardSemInfoVeiculo {
    left: 60px
    }

    .margemViagemCardComInfoVeiculo {
    left: 300px;
    }

    .cardViagemAtual {
    border: 2px solid #00e16b;
    height: 100px;
    background: #9fffa8;

    }

    .cardViagem {
    border: 2px solid #6c6f73;
    height: 120px;
    background: #edf2f6;
    }

    .cardViagem:hover {
    background-color: #e2e2e2;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }

    .corBotaoSelecionado {
    background-color: #bee1ff;
    }

    .tamanhoFonte {
    font-size: 10px !important;
    font-weight: bold;
    }

    .linhaViagemAtual {
    border-bottom: 2px solid #00e16b;
    }

    .linhaViagemRealizada {
    border-bottom: 2px solid #6c6f73;
    }

    .colorRed {
    color: red;
    }

    .colorGreen {
    color: green;
    }

    .colorYellow {
    color: yellow;
    }

    .botaoFechar {
    z-index: 999
    }
</style>
