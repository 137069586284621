<template>
    <panelEagle id='tempoParado' :loading='carregandoRel'>
        <div class="col-sm-12 row ">
            <div class="col-sm-6 nopadding">
                <titulo 
                    titulo='Tempo Parado'
                    :icon='mdiBusStop'
                />
            </div>
            <div class="col-sm-6 nopadding divDontPrint">
                <botoes
                    :loading="loadingExporta"
                    :disabled='$v.relInfo.$invalid'
                    @gerarRelatorio='gerarRelatorio'
                    @exportarRelatorio='exportarRelatorioTempo'
                    :disabledDropdown="$v.dataRelatorio.$invalid"
                />
            </div>
        </div>
        <slideUpAndDown>
            <slot name="filters">
                <div class="col-sm-12"><hr></div>
                <div class="col-sm-12 row nopadding">
                    <div class="col-sm-3 nopadding">
                        <intervalPicker 
                            name='pickerIntervalo'
                            titulo='Intervalo*'
                            opens='rigth'
                            @changeInput='mudaDataIntervalo'
                        />
                    </div>
                    <div class="col-sm-3 nopadding">
                        <selectAll
                            :hasSelectAll="true"
                            nameForRadio='seletorEmpresa'
                            :isMultiple='true' 
                            :labels="[{'description': 'Empresas*'}]"
                            :optionsArray='optSelectEmpresa'
                            :selected="selectedEmpresa"
                            @changeSelect='mudaClienteSelecionado'
                        />
                    </div>
                    <div class="col-sm-3 nopadding">
                        <selectAll
                            ref="selectVeiculo"
                            nameForRadio='seletorGrupo'
                            :disabled='!optSeletorVeiculos.length'
                            :loading='carregandoDados'
                            firstSelected='V'
                            :hasSelectAll="true"
                            :isMultiple='true' 
                            :labels="labelSelectVeiculos"
                            :optionsArray='optSeletorVeiculos'
                            @changeSelect='mudaVeiculoSelecionado'
                            @changeCheck='mudaCheckBox'
                        />
                    </div>
                    <div class="col-sm-3 nopadding">
                        <filters 
                            label='Tempo parado acima de'
                            :arrayButtons='btsFiltroTempo'
                            zeroValue='0'
                            @buttonsFiltersChange='mudaFiltroTempo'
                        />
                    </div>
                     <div class="col-sm-2 nopadding">
                        <inputSimple
                            label='Hora Inicial'
                            type='time'
                            value='00:00:00'
                            @changeInput="mudaHoraInicial"/>
                        </div>
                    <div class="col-sm-2 nopadding">
                        <inputSimple
                            label="Hora Final"
                            type="time"
                            value="23:59:59"
                            @changeInput="mudaHoraFinal"/>
                    </div>
                </div>
            </slot>
        </slideUpAndDown>
        <div class="col-sm-12"><hr></div>
        <div class="col-sm-12">
            <tableSimples>
                <thead>
                    <br>
                    <tr>
                        <th>Horário Inicial</th>
                        <th>Horário Final</th>
                        <th>Tempo Parado</th>
                        <th>Endereço</th>
                        <th>Ponto</th>
                        <th>Região</th>
                        <th>Localizar</th>
                    </tr>
                </thead>
                <tbody v-if="exibirRel">
                    <template 
                    v-for='(reg, index) in dataRelatorio'
                    >
                        <tr :key='index+"__relatorio"'
                            class="backColorTdPDF p-0">
                            <td colspan="7" class="p-0">
                                <span class="newbadge">
                                    {{index}}
                                </span>
                            </td>
                        </tr>
                        <template
                        v-for="(dia, i) in reg"
                        >
                            <tr :key="index+i+'__info_data'">
                                <td 
                                colspan="7" 
                                class='diaRelatorio'
                                >
                                    {{getData(i)}}
                                </td>
                            </tr>
                            <template v-for="(occ, ii) in dia">
                                <tr :key='ii+i+index+"__data"' v-if="ii!='tempoTotalDia'">
                                    <td>{{getStringHorario(occ.dataInicio)}}</td>
                                    <td>{{getStringHorario(occ.dataFim)}}</td>
                                    <td>{{occ.tempo}}</td>
                                    <td>{{occ.endereco}}</td>
                                    <td>{{occ.ponto}}</td>
                                    <td>{{occ.regiao}}</td>
                                    <td @click="abreGoogleMaps(occ)" style="color:#2196f3">
                                        <baseIcon
                                            v-if="occ.lat"
                                            title='Clique para vero o local no mapa'
                                            size='18' 
                                            class="iconRel cursor-pointer" 
                                            :icon="mdiTarget"
                                        />
                                    </td>
                                </tr>
                                
                            </template>
                            <tr :key="i+index+'__endofrow'">
                                <td>Total tempo parado</td>
                                <td>   </td>
                                <td colspan="5">{{dia.tempoTotalDia}}</td>
                            </tr>
                        </template>
                    </template>
                </tbody>
                <tbody v-else>
                    <statusbar
                        colspanForTd='7'
                        :statusBar='statusRel'
                    />
                </tbody>
            </tableSimples>
        </div>
    </panelEagle>
</template>

<script>
import moment from 'moment'
import {mdiBusStop, mdiTarget } from '@mdi/js'
import { required } from 'vuelidate/lib/validators'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { titulosEhBotoes } from './HelpersTempoParado'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { conectionError } from '@/Services/Helpers/swellHeper'
import { mapGetters } from 'vuex'

export default {
    name:'tempoParado',
    components:{
        titulo         : require('@/components/Atom/Header/Titulo').default,
        panelEagle     : require('@/components/Atom/Panel/PanelEagle').default,
        selectAll      : require('@/components/Atom/Select/SelectAll').default,
        baseIcon       : require('@/components/Atom/Icon/BaseIcon.vue').default,
        tableSimples   : require('@/components/Atom/Table/TableSimples').default,
        inputSimple    : require('@/components/Atom/Inputs/InputSimple').default,
        filters        : require('@/components/Atom/Buttons/ButtonsFilters').default,
        intervalPicker : require('@/components/Atom/Datas/InputRangeWithLimit').default,
        slideUpAndDown : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
        statusbar      : require('@/components/Atom/StatusInformation/StatusInformation').default,
        botoes         : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,

    },
    validations:{
        relInfo:{
            clientes  : { required },
            veiculos  : { required }
        },
        dataRelatorio:{ required },
    },
    data() {
        return {
            mdiTarget:mdiTarget,
            mdiBusStop:mdiBusStop,
            urlBase:'/telemetria/relatorios/tempo/parado/',
            carregandoDados:false,
            carregandoRel: false,
            ...titulosEhBotoes,
            optSelectEmpresa:[],
            optSeletorVeiculos:[],
            optSelectGrupo:[],
            dataRelatorio:[],
            dadosFiltroHelper:[],
            statusRel:'info',
            intervalo:'',
            relInfo:{
                clientes:[],
                veiculos:[],
                horaInicial:'00:00:00',
                horaFinal:'23:59:59',
                data_inicio:'',
                data_fim:'',
            },
            tempoParado:'',
            checkSeletor:'',
            exibirRel:false,
            loadingExporta:[false, false, false],
            selectedEmpresa: [],
        }
    },
    methods: {
        ...mapGetters(['getMaster']),
        /*
         * Função para exportar o relatório
         */
        exportarRelatorioTempo(value){
            switch (value) {
                case 'pdf':
                    this.loadingExporta = [true, false, false]
                    break;
                case 'xls':
                    this.loadingExporta = [false, true, false]
                    break;
                default:
                    this.loadingExporta = [false, false, true]
                    break;
            }
            var root =  process.env.VUE_APP_ROOT;
            let url = this.urlBase +'exportar'
            let obj = {
                'tipo':value,
                'arrayDados': JSON.stringify(this.dataRelatorio),
                'intervalo':this.intervalo,
            }
            new HttpRequest().Post(url, obj).then(data=>{
                window.open(root+'/'+data.data.dados);
                // this.loadingExporta = [false, false, false]
            }).catch(()=>{
                conectionError()
            }).finally(()=>{this.loadingExporta = [false, false, false]})
        },

        /**
         * Gera o relatório e, caso venha vazio, já mostra 
         * a tela de nenhuma informação encontrada
         */
        gerarRelatorio(){
            this.carregandoRel = true
            this.exibirRel = false
            this.relInfo.horaInicial = 
                !this.relInfo.horaInicial ? '00:00:00' : this.relInfo.horaInicial
            this.relInfo.horaFinal = 
                !this.relInfo.horaFinal ? '23:59:59' : this.relInfo.horaFinal  
            // this.statusRel = 'loading'
            let uri = this.urlBase+'relatorio'
            let obj = {
                ...this.relInfo,
                searchVeiculos: this.checkSeletor,
                inp_time_parado:this.tempoParado[0]
            }
            new HttpRequest().Post(uri, obj)
            .then(data=>{
                this.statusRel = 'info'
                if(data.status){
                    this.dataRelatorio = data.data.tempoParado
                    if(Object.keys(this.dataRelatorio).length){
                        this.exibirRel = true
                    }else{
                        this.statusRel = 'error'
                    }
                }else{
                    this.statusRel = 'error'
                }
            }).catch(()=>{
                conectionError()
            }).finally(()=>{this.carregandoRel = false})
        },
        
        limpaRelatorio() {
            this.dataRelatorio = []
            this.exibirRel = false
        },

        mudaDataIntervalo(value){
            this.limpaRelatorio();
            this.intervalo = value
            let values = value.split(' - ')
            this.relInfo.data_inicio = values[0]
            this.relInfo.data_fim = values[1]
        },
        mudaFiltroTempo(value){
            this.limpaRelatorio();
            this.tempoParado = value
        },
        mudaClienteSelecionado(value){
            this.$refs.selectVeiculo.clearAll()
            this.limpaRelatorio();
            this.relInfo.clientes = value
            this.getVeiculosEhGruposCliente()
        },

        mudaVeiculoSelecionado(value){
            this.limpaRelatorio();
            this.relInfo.veiculos = value
        },

        mudaCheckBox(value){
            this.limpaRelatorio();
            this.checkSeletor = value
            if(this.dadosFiltroHelper[value] !== undefined)
                this.optSeletorVeiculos = this.dadosFiltroHelper[value]
        },

        mudaHoraInicial(value) {
            this.limpaRelatorio();
            this.relInfo.horaInicial = value 
        },

        mudaHoraFinal(value) {
            this.limpaRelatorio();
            this.relInfo.horaFinal = value 
        },

        getStringHorario(data){
            return moment(data).format('HH:mm:ss')
        },
        
        getData(data){
            return moment(data).format('DD/MM/YYYY')
        },

        /*
         * Gera link do google maps com a lat long do ponto
         */
        abreGoogleMaps(occ){
            window.open(`https://www.google.com/maps/place/${occ.lat},${occ.lng}`)
        },

        /**
         * Faz a busca dos veiculos 'V' e grupos de veiculos 'GV'
         * relacionados ao cliente selecionado.
         * Caso uma das opções do checkbox do segundo seletor já
         * estiver selecionado, ele  alimenta o seletor com as
         * opções apropriadas
         * @author Gui 🔫
         */
        getVeiculosEhGruposCliente(){
            this.carregandoDados = true
            new FiltrosService()
            .dados_filtros(this.relInfo.clientes, ['V', 'GV'])
            .then(data=> {
                this.dadosFiltroHelper = data 
                if(this.checkSeletor != '')
                    this.optSeletorVeiculos = this.dadosFiltroHelper[this.checkSeletor]
                this.carregandoDados = false
            })
        }
    },
    mounted () {
        this.optSelectEmpresa = new EmpresasService().Get()
        if(!this.getMaster()){
            this.selectedEmpresa = this.optSelectEmpresa
        }
    },
}
</script>

<style lang="scss" >
#tempoParado{
    font-size: 13px !important;
    .iconRel::before{
        font-size: 13px !important;
        color:#428bca;
        cursor: pointer;
    }
    .diaRelatorio{
        background-color: #cccccc;
        font-weight: bolder;
    }
    .placaRelatorio{
        background-color: #000 !important;
        margin-top: 20px;
        td{
            background-color: #777777;
            color:white;
            font-size: 13px !important;
            text-align: center;
            vertical-align: middle;
            height: 35px;
        }
    }
}
</style>