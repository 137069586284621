<template>
    <modalEagle @modalOpen="resetarVariaveis" title="Justificativas Monitoramento Rota" id="justificativaRotaModal">
        <slot slot="modalBody">
            <panelEagle id="modal-justificativas-rotas" :loading="isLoading">
                <div class="col-sm-12 justpaddingbottom row">
                    <div class="col-sm-5 nopadding">
                        <selectAll 
                            @changeSelect="changeEmpresa" 
                            :isMultiple="false" 
                            nameForRadio="empresasMonitoramentoRota" 
                            :hasSelectAll="false" 
                            :labels="[{indexDFH: 'EM', description: 'Empresa *'}]" 
                            :optionsArray="optionsEmpresas" 
                            firstSelected="EM">
                        </selectAll>
                    </div>
                    <div class="col-sm-5 nopadding">
                        <inputSimple 
                            @changeInput="changeDescricao" 
                            label="Descrição" 
                            :value="descricaoJustificativa" 
                            :hasMask="false" 
                            name="judescricao" 
                            id="descricaoJUstificativa">
                        </inputSimple>
                    </div>
                    <div class="col-sm-2 paddingTopAndLeft">
                        <simpleButton 
                            event="click" 
                            @click="saveJustificativa" 
                            id="salvaJustificativas" 
                            width="97px" 
                            :icon="mdiCheckBold" 
                            type="blue" 
                            text="Salvar" 
                            title="Salvar">
                        </simpleButton>
                    </div>
                </div>
                <div class="col-sm-12 nopadding">
                    <tableModal 
                        @excluirCadastro="excluirJustificativa" 
                        :hasAction="true" 
                        :titles="titlesTable" 
                        :data="arrayDadosJustificativas">
                    </tableModal>
                </div>
            </panelEagle>
        </slot>
        <slot slot="modalFooter">
            <simpleButton
                event="click" 
                @click="fecharModal" 
                id="fecharmodal" 
                width="97px" 
                :icon="mdiCloseThick" 
                type="red" 
                text="Fechar" 
                title="Salvar">
            </simpleButton>
        </slot>
    </modalEagle>
</template>
<script lang="js">
import {mdiCheckBold, mdiCloseThick } from '@mdi/js'
import Vue from 'vue'
import {HttpRequest} from '../../../Services/auth/HttpRequest.Service'
import {EmpresasService} from '../../../Services/auth/Empresas.service'
export default Vue.extend({
    name:'modalJustificativaRota',
    components:{
        'modalEagle': require('../Modal/ModalEagle').default,
        'selectAll': require('../Select/SelectAll').default,
        'tableModal': require('../Table/TableListagemModal').default,
        'simpleButton': require('../Buttons/SimpleButton').default,
        'inputSimple': require('../Inputs/InputSimple').default,
        // 'alertSimple': require('../Alerts/AlertSimple').default,
        'panelEagle': require('@/components/Atom/Panel/PanelEagle.vue').default,
        // src/components/Atom/Panel/PanelEagle.vue
    },
    data(){
        return{
            mdiCloseThick:mdiCloseThick,
            mdiCheckBold:mdiCheckBold,
            url: '/roteirizador/monitoramento/justificativas/',
            loadingPanel:false,
            optionsEmpresas: new EmpresasService().Get(),
            descricaoJustificativa: '',
            needShowAlert: false,
            isLoading: false,
            statusAlert: 'blue',
            messageAlert: '',
            arrayDadosJustificativas: [],
            selectedEmpresasJustificativas: [],
            titlesTable: [
                {'number': 'judescricao', 'name': 'Descrição'},
                {'number': 'clfantasia', 'name': 'Empresa'},
            ]
        }
    },
    methods:{
        fecharModal(){
            this.$bvModal.hide('justificativaRotaModal')
        },
        changeEmpresa(empresas){
            this.selectedEmpresasJustificativas = empresas;
            this.getInformationJustificativas();
        },
        changeDescricao(desc){
            this.descricaoJustificativa = desc;
        },
        async getInformationJustificativas(){
            this.isLoading = true
            if(this.selectedEmpresasJustificativas.length > 0){
                let param = {'clientes': this.selectedEmpresasJustificativas}
                var data = await new HttpRequest().Post(this.url, param)
                if(data.status && data.data)
                    this.arrayDadosJustificativas = data.data.justificativa;
                this.isLoading= false
            }
            this.isLoading = false
        },
        resetarVariaveis(){
            this.descricaoJustificativa = ''
            this.arrayDadosJustificativas = []
        },
        async saveJustificativa(dado){
            if(this.selectedEmpresasJustificativas.length > 0 && this.descricaoJustificativa != ''){
                this.isLoading= true;
                this.statusAlert = 'blue';
                var param = {
                    'empresa': this.selectedEmpresasJustificativas,
                    'descricao': this.descricaoJustificativa,
                }
                var data = await new HttpRequest().Post(this.url+'salvar', param);
                if(data.status && data.data){
                    this.statusAlert = data.data.status;
                    this.messageAlert = data.data.message;
                }
                if(this.statusAlert == 'red'){
                    this.toastModal('Erro ao salvar', this.messageAlert, 'danger')
                    this.getInformationJustificativas()
                } else {
                    this.toastModal('Salvo com sucesso', this.messageAlert, 'success')
                    this.getInformationJustificativas()
                }
                this.isLoading= false;
            }
        },
        async excluirJustificativa(cod){
            this.isLoading= true;
            this.statusAlert = 'blue';
            this.needShowAlert = true;
            var data = await new HttpRequest().Post(this.url+'excluir', 
                {'codigo': cod});
            if(data.status && data.data){
                this.statusAlert = data.data.status;
                this.messageAlert = data.data.message;
            }
            if(this.statusAlert == 'red'){
                this.toastModal('Erro ao excluir', this.messageAlert, 'danger')
                this.getInformationJustificativas()
            } else {
                this.toastModal('Excluído com sucesso!', this.messageAlert, 'success')
                this.getInformationJustificativas()
            }
            this.isLoading= false;
        },

        toastModal(titulo, menssagem, variante){
            this.$bvToast.toast(menssagem, {
                title: titulo,
                autoHideDelay: 3000,
                variant: variante
            })
        },
    },
})
</script>

<style lang="scss">
    .justpaddingbottom{
        padding: 0 0 10px 0!important;
    }
    .paddingTopAndLeft{
        padding-top: 21px;
        padding-right: 3px!important;
    }
</style>