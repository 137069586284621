/**
    Componente do rota manual para mostrar informações referentes a uma rota

    -> Propriedades:
        1- info - Object - Informações de uma rota

    -> Slot:
        Sem slot

    -> Eventos:
        1- click - Emite quando clica na div dos detalhes - Sem parâmetro
 */
<template>
    <div class="divDadosMapa"> <!-- col-sm-3 row  -->
        <div class="col-sm-12 text-sm">
            Tempo: {{ tempo }}
        </div>
        <div class="col-sm-12 text-sm">
            Distância: {{ distancia }}
        </div>
        <div class="col-sm-12 text-sm">
            Volumes: {{ volume }}
        </div>
        <div class="col-sm-12 text-sm">
            Peso: {{ peso }}
        </div>
        <div class="col-sm-12 text-sm">
            Valor: {{ valor }}
        </div>
        <div class="col-sm-12 text-sm">
            Cubagem: {{ cubagem }}
        </div>
        <div class="col-sm-12 text-sm divAlertaRotaFixa" v-show="alertaRotaFixa">
            <div>
                Ordem modificada, será gerada uma rota fixa!
            </div>
            <u @click="clickDesfazerAcao" style="cursor: pointer;">
                Desfazer ação
            </u>
        </div>
    </div>
</template>

<script lang="js">

import { converteSegundosEmHoras } from '@/views/Roteirizador/NaoMenus/RotaManual'
import Vue from 'vue'

export default Vue.extend({
    name: 'infoRotaOsrm',
    props: {
        dados: {
            type: [Array, Object],
            required: true,
            default: function () { return [] }
        },
        alertaRotaFixa: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            info: {}
        }
    },

    watch: {
        dados(value) {
            this.info = value
        },
        'dados.distancia'(value) {
            this.info.distancia = value
        },
        'dados.tempo'(value) {
            this.info.tempo = value
        },
        'dados.volume'(value) {
            this.info.volume = value
        },
        'dados.peso'(value) {
            this.info.peso = value
        },
        'dados.valor'(value) {
            this.info.valor = value
        },
        'dados.cubagem'(value) {
            this.info.cubagem = value
        },
    },

    computed: {
        distancia() {
            if (this.info.distancia > 0) {
                let km = this.info.distancia / 1000
                return `${km.toFixed(2)} Km`
            }
            return '';
        },
        tempo() {
            if (this.info.tempo > 0) {
                return converteSegundosEmHoras(this.info.tempo)
            }
            return '';
        },
        volume() {
            if (this.info.volume > 0) {
                return this.info.volume
            }
            return '';
        },
        peso() {
            if (this.info.peso > 0) {
                return `${this.info.peso.toFixed(2)} Kg`
            }
            return '';
        },
        valor() {
            if (this.info.valor > 0) {
                return `R$ ${this.info.valor.toFixed(2)}`
            }
            return '';
        },
        cubagem() {
            if (this.info.cubagem > 0) {
                return `${this.info.cubagem.toFixed(2)} m³`
            }
            return '';
        },
    },

    methods: {
        /**
         * @description Emite evento para "desfazer ação de obedecer ordenação".
         */
        clickDesfazerAcao() {
            this.$emit('click')
        }
    },
})
</script>

<style lang="scss">
.divDadosMapa {
    font-family: nexabook, sans-serif !important;
    z-index: 900;
    text-align: left;
    font-weight: bold;
    border: 1px solid #e5e5e5;
    background: #00000054;
    padding-right: 0px !important;
    padding-top: 5px !important;
    padding-left: 0px !important;
    color: #fff;
    position: absolute !important;
    font-size: 10px !important;
}

.text-sm {
    font-size: 11.5px
}
</style>