<template>
    <panelEagle id='Perfis' :loading="loadingPanel">
        <div class="col-sm-12 row">
            <div class="col-md-12 col-lg-6 nopadding">
                <tituloPage :icon='mdiMessageCogOutline' titulo='Perfis de Configuração'></tituloPage>
            </div>
            <div class="col-md-12 col-lg-6 nopadding divDontPrint">
                <basicButtonsCadastrosSC
                    :disabled='$v.$invalid'
                    @salvarCadastro='salvar'
                    tipoCancela='button'
                    linkCancel='listarPerfis'/>
                
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class='col-sm-12 row nopadding divDontPrint'>
            <div class="col-sm-4 nopadding">
                <inputSimple
                    id='Descricao'
                    name='Descricao'
                    label='Descrição*'
                    :value="editDescricao"
                    :inputClass='{"boder border-danger" : $v.perfis.mcpdescricao.$anyError }'
                    @blur='$v.perfis.mcpdescricao.$touch()'
                    @changeInput='changeDescricao'/>
            </div>
            <div class="col-sm-4 nopadding">
                <selectAll
                    :labels="[{indexDFH:'MO', description: 'Modelo de rastreador*'}]"
                    nameForRadio='selectAll'
                    :hasSelectAll='false'
                    :isMultiple='false'
                    :optionsArray="optionsSelectModelos"
                    :selected="editModelo"
                    :extraClass='{ "border border-danger": $v.perfis.mcpmodelo.$anyError }'
                    @close='$v.perfis.mcpmodelo.$touch()'
                    @changeSelect="changeModelo"/>
            </div>
            <div class="col-sm-4 nopadding">
                <selectAll
                    :labels="[{indexDFH:'C', description: 'Comandos*'}]"
                    nameForRadio='selectAll'
                    ref="selectComandos"
                    :hasSelectAll='true'
                    :isMultiple='true'
                    :loading='loadingComandos'
                    :disabled="$v.perfis.mcpmodelo.$invalid"
                    :optionsArray="optionsSelectComandos"
                    :selected="editComandos"
                    :extraClass='{ "border border-danger": $v.comandosSelecionados.$anyError }'
                    @close='$v.comandosSelecionados.$touch()'
                    @changeSelect="changeComandos"/>
            </div>
        </div>
        <div class="col-sm-12 nopadding">
            <requiredFields></requiredFields>
        </div>
        <div class="col-sm-12"><hr></div>
    </panelEagle>
</template>
<script lang='js'>
    import Vue  from 'vue'
    import Vuex from '@/store/index'
    import { mdiMessageCogOutline } from '@mdi/js'
    import {EmpresasService} from '@/Services/auth/Empresas.service'
    import {FiltrosService}  from '@/Services/filtros/filtros.Service'
    import {HttpRequest} from '@/Services/auth/HttpRequest.Service'
    import { required } from 'vuelidate/lib/validators'
    import { conectionError }      from '@/Services/Helpers/swellHeper'
    export default Vue.extend({
        name:'CadastrarPerfisAdministrativo',
        components:{
            'panelEagle'              : require('@/components/Atom/Panel/PanelEagle').default,
            'tituloPage'              : require('@/components/Atom/Header/Titulo').default,
            'inputSimple'             : require('@/components/Atom/Inputs/InputSimple').default,
            'selectAll'               : require('@/components/Atom/Select/SelectAll').default,
            'requiredFields'          : require('@/components/Atom/Footer/RequiredFields').default,
            'basicButtonsCadastrosSC' : require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
        },

        validations:{
            perfis:{
                mcpdescricao :{ required }, 
                mcpmodelo    :{ required }, 
            }, 
            comandosSelecionados:{
                required
            },
        },
        data(){
            return {
                mdiMessageCogOutline    : mdiMessageCogOutline,
                link : '/administrativo/cadastros/perfis/',
                optionsSelectModelos  : [],
                optionsSelectComandos : [],
                perfis:{
                    mcpdescricao  : [],
                    mcpmodelo     : '',
                },
                comandosSelecionados  : [],
                loadingPanel          : false,
                loadingComandos       : false,
                id                    : '',
                editDescricao         : '',
                editModelo            : [],
                editComandos          : [],
            }
        },
        methods:{
            changeDescricao(descricao){
                this.perfis.mcpdescricao = descricao
            },

            changeModelo(modelo){
                this.perfis.mcpmodelo = modelo[0]
                this.$refs.selectComandos.clearAll()
                this.buscaComandos()
            },

            changeComandos(comandos){
                this.comandosSelecionados = comandos
            },

            async dadosFiltros(){
                var dados = await new FiltrosService().dados_filtros('',['MO'])
                this.optionsSelectModelos = dados['MO'];
            },

            async buscaComandos(){
                this.loadingComandos = true
                var dados = await new HttpRequest().Post(this.link+'busca/comandos', 
                    {'modelo':this.perfis.mcpmodelo})
                this.optionsSelectComandos = dados.data.comandos
                this.loadingComandos = false
            },

            async salvar(){
                var dados = await new HttpRequest().Post(this.link+'salvar/perfis', 
                    {'perfis'   : this.perfis,
                     'comandos' : this.comandosSelecionados,
                     'id'       : this.id})
                if(dados.data.success){
                    this.$router.push({name:'listarPerfis'})
                } else {
                    conectionError()
                }
            },

            async buscaEdicao(){
                this.loadingPanel = true
                var dados = await new HttpRequest().Post(this.link+'busca/edicao', 
                    {'id' : this.id})
                this.editDescricao = dados.data.edit[0].mcpdescricao
                this.editModelo    = dados.data.edit
                this.editComandos  = dados.data.comandos
                this.loadingPanel = false
            }
        },

        mounted(){
            this.dadosFiltros()
            if(this.$route.params.id){
                this.id = this.$route.params.id
                this.buscaEdicao();
            }
        }
    })
</script>
<style lang="scss">
</style>