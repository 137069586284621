/**
 * Implementação do algorítimo SoundEx em Javascript.
 * ela é utilizada igual uma func padrão do js
 * esse algoritimo pode ter alguns problemas, de quando palavras tem RR, wh ou ou afins com w
 * peguei no git hub
 * 
 * SoundEX é um algorítimo fonético para indexação de nomes pelo som segundo
 * sua pronúncia. O algorítimo foi desenvolvido por Robert C. Russell e
 * Margaret K. Odell e patenteado em 1918 e 1922.
 * 
 * @link https://gist.github.com/netojoaobatista/2161104
 * @link https://en.wikipedia.org/wiki/Soundex
 * @example stringAleatoria1.soundex == stringAleatoria2.soundex 
 * @return {String}
 */
String.prototype.soundex = function() {
	var string = this.toUpperCase().replace(/[^A-Z]/g, '');
	string = [
		string.substr(0, 1),
		string.substr(1)
			.replace(/A|E|H|I|O|U|W|Y/g, 0)
			.replace(/B|F|P|V/g, 1)
			.replace(/C|G|J|K|Q|S|X|Z/g, 2)
			.replace(/D|T/g, 3)
			.replace(/L/g, 4)
			.replace(/M|N/g, 5)
			.replace(/R/g, 6)
			.replace(/1{2}|2{2}|3{2}|4{2}|5{2}|6{2}/g, '')
			.replace(/0/g, '')
	].join('').substr(0, 4)
	return string+(string.length == 4 ? '' : (new Array(5-string.length)).join('0'))
}

export function comparaString(str1, str2){
	return str1.soundex() == str2.soundex()
}

export function comparaArrayString(array, str){
	return Boolean(array.filter((v) => comparaString(str, v)).length)
}