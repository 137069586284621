<template>
	<div id='popup-rastro-veiculo'>
		<div>
			<b>Endereço Completo:</b> {{posicaoAtual.endereco}}
		</div>
		<div>
			<b>Data/Hora:</b> {{formataData(posicaoAtual.data)}}
		</div>
	</div>
</template>

<script>
import { DateTime } from 'luxon'
import L from 'leaflet'
export default {
	name:'popupRastroVeiculo',
	props:{
		arrayPosicoes:{
			type: Array,
			required: true
		},
		latlngClick:{
		}
	},
	data(){
		return{
			//Deve possuir o evento de para mais prox do ponto clicado.
			posicaoAtual:{},
		}
	},
	methods:{
		/**
		 * @description organiza a array de posições para o primeiro
		 * evento listado ser o mais próximo do lugar do click
		 * Copiei essa função do mapa antigo e fiz algumas alterações
		 * para funcionar melhor no Vue.js
		 * @return {object[]} eventos organizados
		 */
		findEventoMaisProx() {
			let ult = null
			let maisProximo = null
			this.arrayPosicoes.forEach((el, index) => {
				if (typeof this.arrayPosicoes[index + 1] !== 'undefined') {
					let el2 = this.arrayPosicoes[index + 1]
					var coordAuxA = el.posicao.split(',')
					var coordAuxB = el2.posicao.split(',')
					var coordA = {'lat':coordAuxA[0], 'lng':coordAuxA[1]}
					var coordB = { 'lat': coordAuxB[0], 'lng': coordAuxB[1] }
					let p1 = new L.Point(parseFloat(this.latlngClick.lat), parseFloat(this.latlngClick.lng))
					let p2 = new L.Point(parseFloat(coordA.lat), parseFloat(coordA.lng))
					let p3 = new L.Point(parseFloat(coordB.lat), parseFloat(coordB.lng))
					let distance = L.LineUtil.pointToSegmentDistance(p1, p2, p3) * 10000
					if (distance && distance < ult || ult == null) {
						ult = distance
						maisProximo = el
					}
				}
			})
			return maisProximo
		},

		/**
		 * @param {string} data - no formato SQL
		 * @return {string} no formato dd/mm/yyyy hh:mm:ss
		 */
		formataData(data){
			var dataFinal = DateTime.fromSQL(data)
			return dataFinal.toFormat('dd/LL/yyyy HH:mm:ss')
		},

		/**
		 * @param {object} position1
		 * @param {number} position1.lat 
		 * @param {number} position1.lng 
		 * @param {object} position2
		 * @param {number} position2.lat 
		 * @param {number} position2.lng 
		 * @description compara a distância entre 2 pontos.
		 * Copiei essa função do mapa antigo também
		 * @return {number} distância entre pontos
		 * @todo esta funcao eh um backup
		 */
		getDistanceFromLatLonInKm(position1, position2) {
			var deg2rad = function (deg) { return deg * (Math.PI / 180); },
				R = 6371,
				dLat = deg2rad(position2.lat - position1.lat),
				dLng = deg2rad(position2.lng - position1.lng),
				a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
					+ Math.cos(deg2rad(position1.lat))
					* Math.cos(deg2rad(position1.lat))
					* Math.sin(dLng / 2) * Math.sin(dLng / 2),
				c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
			return (R * c *1000)
		},

	},
	mounted(){
		this.posicaoAtual = this.findEventoMaisProx()
	}
}
</script>
<style lang="scss" scoped>
#popup-rastro-veiculo{
	min-width: 300px;
}

</style>