/**
* Componenete para criar a lista do controle da manutenção
* é criado uma tabela para cada status já que alguns status
* pode ser utilizado o draggable
*/

<template>
    <!-- Lista -->
    <div v-show="!listaKanban" id="tableListaManutencao" class="col-sm-12 row nopadding tableListagemManutencao">
        <div class="col-sm-12 row">
            <div class="col-sm-12 row cabecalhoTabela pr-0 pl-0">
                <div class="col-sm-1">
                    OS
                </div>
                <div class="col-sm-1">
                    Placa
                </div>
                <div class="col-sm-1">
                    Prefixo
                </div>
                <div class="col-sm-2">
                    Descrição
                </div>
                <div class="col-sm-1">
                    Atual
                </div>
                <div class="col-sm-2 pt-0 row">
                    <span class="col-sm-9 pt-1 pl-1">
                        Programado
                    </span>
                    <span class="col-sm-3 pt-1 pl-1" style="border-left: 2px solid #e9e9e9">
                        Tipo
                    </span>
                </div>
                <div class="col-sm-2">
                    Empresa
                </div>
                <div class="col-sm-2">
                    Últ.Atualização
                </div>
            </div>
        </div>
        <div v-if="Object.values(dadosKanban).length == 0" class="col-sm-12">
            <statusInformation typeBar="div" :statusBar="statusbar">
            </statusInformation>
        </div>
        <div v-if="Object.values(dadosKanban).length > 0" class="col-sm-12">
            <!-- Cadastradas -->
            <div v-show="dadosKanban.Cadastradas ? true : false" class="col-sm-12 mt-3 nopadding">
                <div class="header Cadastradas cursor-pointer row" @click="chevronCadastradas =
        !chevronCadastradas">
                    <span class="col-sm-2 nopadding">
                        <b-icon shift-v="-1" shift-h="0" style="font-weight: bold;" :icon="chevronCadastradas ?
        'chevron-down' : 'chevron-right'" />
                    </span>
                    <span class="col-sm-10" align="left">
                        Cadastradas
                    </span>
                </div>
            </div>
            <b-collapse :visible="chevronCadastradas">
                <div v-for="(item, i) in dadosKanban.Cadastradas" :key="i + '_Cadastradas'"
                    class="col-md-12 row bodyTable pr-0 pl-0">
                    <div class="col-sm-1 p-1">
                        {{ item.mcordemservico }}
                    </div>
                    <div class="col-sm-1 p-1">
                        {{ item.veplaca }}
                    </div>
                    <div class="col-sm-1 p-1">
                        {{ item.veprefixo }}
                    </div>
                    <div class="col-sm-2 p-1">
                        {{ item.mcdescricao }}
                    </div>
                    <div class="col-sm-1 p-1">
                        <span v-if="item.mcapuracao != 3">
                            {{
        item.mcapuracao == 1 ?
            parseInt(item.vchodometro / 1000) :
            item.vchorimetro
    }}
                        </span>
                        <span v-else>
                            Dia atual
                        </span>
                    </div>
                    <div class="col-sm-2 pt-0 pl-1 row">
                        <span class="col-sm-9 pt-1 pl-1">
                            {{ item.mcapuracao == 1 ?
        parseInt(item.mcprogramado / 1000) :
        item.mcprogramado }}
                        </span>
                        <span class="col-sm-3 pl-1" style="border-left: 2px solid #e9e9e9">
                            <div :class="'col-sm-8 pt-1 ' + item.mtidescricao">
                                {{ item.mtidescricao == 'Preventiva' ?
        'P' : 'C' }}
                            </div>
                        </span>
                    </div>
                    <div class="col-sm-2 p-1">
                        {{ item.clnome }}
                    </div>
                    <div class="col-sm-2 p-1 row">
                        <span class="col-sm-10 nopadding">
                            {{ item.dataatualizacao }}
                        </span>
                        <span class="olinho col-sm-2 nopadding" align="right" title="Visualizar manutenção"
                            event="click" @click="redirecionaManutencao(item)">
                            <baseIcon size='20' :icon="mdiEyeOutline" />
                        </span>
                    </div>
                </div>
            </b-collapse>
            <!-- Vencidas -->
            <div v-show="dadosKanban.Vencidas ? true : false" class="col-sm-12 mt-3 nopadding">
                <div class="header Vencidas cursor-pointer row" @click="chevronVencidas =
        !chevronVencidas">
                    <span class="col-sm-2 nopadding">
                        <b-icon shift-v="-1" shift-h="-14" style="font-weight: bold;" :icon="chevronVencidas ?
        'chevron-down' : 'chevron-right'" />
                    </span>
                    <span class="col-sm-10">
                        Vencidas
                    </span>
                </div>
            </div>
            <b-collapse :visible="chevronVencidas">
                <draggable class="list-group" :list="dadosKanban.Vencidas" group="status"
                    @change="v => endDraggin(v, 'vencidas')">
                    <div v-for="(item, i) in dadosKanban.Vencidas" :key="i + '_Vencidas'"
                        class="col-sm-12 row bodyTable pr-0 pl-0">
                        <div class="col-sm-1 p-1">
                            {{ item.mcordemservico }}
                        </div>
                        <div class="col-sm-1 p-1">
                            {{ item.veplaca }}
                        </div>
                        <div class="col-sm-1 p-1">
                            {{ item.veprefixo }}
                        </div>
                        <div class="col-sm-2 p-1">
                            {{ item.mcdescricao }}
                        </div>
                        <div class="col-sm-1 p-1">
                            <span v-if="item.mcapuracao != 3">
                                {{
        item.mcapuracao == 1 ?
            parseInt(item.vchodometro / 1000) :
            item.vchorimetro
    }}
                            </span>
                            <span v-else>
                                Dia atual
                            </span>
                        </div>
                        <div class="col-sm-2 pt-0 pl-1 row">
                            <span class="col-sm-9 pt-1 pl-1">
                                {{ item.mcapuracao == 1 ?
        parseInt(item.mcprogramado / 1000) :
        item.mcprogramado }}
                            </span>
                            <span class="col-sm-3 pl-1" style="border-left: 2px solid #e9e9e9">
                                <div :class="'col-sm-8 pt-1 ' + item.mtidescricao">
                                    {{ item.mtidescricao == 'Preventiva' ?
        'P' : 'C' }}
                                </div>
                            </span>
                        </div>
                        <div class="col-sm-2 p-1">
                            {{ item.clnome }}
                        </div>
                        <div class="col-sm-2 p-1 row">
                            <span class="col-sm-10 nopadding">
                                {{ item.dataatualizacao }}
                            </span>
                            <span class="olinho col-sm-2 nopadding" align="right" title="Visualizar manutenção"
                                event="click" @click="redirecionaManutencao(item)">
                                <baseIcon size='20' :icon="mdiEyeOutline" />
                            </span>
                        </div>
                    </div>
                </draggable>
            </b-collapse>
            <!-- Programar -->
            <div v-show="dadosKanban.Programar ? true : false" class="col-sm-12 mt-3 nopadding">
                <div class="header Programar cursor-pointer row" @click="chevronProgramar =
        !chevronProgramar">
                    <span class="col-sm-2 nopadding">
                        <b-icon shift-v="-1" shift-h="-8" style="font-weight: bold;" :icon="chevronProgramar ?
        'chevron-down' : 'chevron-right'" />
                    </span>
                    <span class="col-sm-10">
                        Programar
                    </span>
                </div>
            </div>
            <b-collapse :visible="chevronProgramar">
                <draggable :list="dadosKanban.Programar" group="status" @change="v => endDraggin(v, 'programar')">
                    <div v-for="(item, i) in dadosKanban.Programar" :key="i + '_Vencidas'"
                        class="col-sm-12 row bodyTable pr-0 pl-0">
                        <div class="col-sm-1 p-1">
                            {{ item.mcordemservico }}
                        </div>
                        <div class="col-sm-1 p-1">
                            {{ item.veplaca }}
                        </div>
                        <div class="col-sm-1 p-1">
                            {{ item.veprefixo }}
                        </div>
                        <div class="col-sm-2 p-1">
                            {{ item.mcdescricao }}
                        </div>
                        <div class="col-sm-1 p-1">
                            <span v-if="item.mcapuracao != 3">
                                {{
        item.mcapuracao == 1 ?
            parseInt(item.vchodometro / 1000) :
            item.vchorimetro
    }}
                            </span>
                            <span v-else>
                                Dia atual
                            </span>
                        </div>
                        <div class="col-sm-2 pt-0 pl-1 row">
                            <span class="col-sm-9 pt-1 pl-1">
                                {{ item.mcapuracao == 1 ?
        parseInt(item.mcprogramado / 1000) :
        item.mcprogramado }}
                            </span>
                            <span class="col-sm-3 pl-1" style="border-left: 2px solid #e9e9e9">
                                <div :class="'col-sm-8 pt-1 ' + item.mtidescricao">
                                    {{ item.mtidescricao == 'Preventiva' ?
        'P' : 'C' }}
                                </div>
                            </span>
                        </div>
                        <div class="col-sm-2 p-1">
                            {{ item.clnome }}
                        </div>
                        <div class="col-sm-2 p-1 row">
                            <span class="col-sm-10 nopadding">
                                {{ item.dataatualizacao }}
                            </span>
                            <span class="olinho col-sm-2 nopadding" align="right" title="Visualizar manutenção"
                                event="click" @click="redirecionaManutencao(item)">
                                <baseIcon size='20' :icon="mdiEyeOutline" />
                            </span>
                        </div>
                    </div>
                </draggable>
            </b-collapse>
            <!-- Em execução -->
            <div v-show="dadosKanban['Em execução'] ? true : false" class="col-sm-12 mt-3 nopadding">
                <div class="header execucao cursor-pointer row" @click="chevronExecucao =
        !chevronExecucao">
                    <span class="col-sm-2 nopadding">
                        <b-icon shift-v="-1" shift-h="0" style="font-weight: bold;" :icon="chevronExecucao ?
        'chevron-down' : 'chevron-right'" />
                    </span>
                    <span class="col-sm-10">
                        Em execução
                    </span>
                </div>
            </div>
            <b-collapse :visible="chevronExecucao">
                <draggable :list="dadosKanban['Em execução']" group="status" :disabled="!getEditar()"
                    @change="v => endDraggin(v, 'execucao')">
                    <div v-for="(item, i) in dadosKanban['Em execução']" :key="i + '_Execucao'"
                        class="col-sm-12 row bodyTable cursor-pointer pr-0 pl-0" event="click"
                        @click="openModalConfirmar(item)">
                        <div class="col-sm-1 p-1">
                            {{ item.mcordemservico }}
                        </div>
                        <div class="col-sm-1 p-1">
                            {{ item.veplaca }}
                        </div>
                        <div class="col-sm-1 p-1">
                            {{ item.veprefixo }}
                        </div>
                        <div class="col-sm-2 p-1">
                            {{ item.mcdescricao }}
                        </div>
                        <div class="col-sm-1 p-1">
                            <span v-if="item.mcapuracao != 3">
                                {{
        item.mcapuracao == 1 ?
            parseInt(item.vchodometro / 1000) :
            item.vchorimetro
    }}
                            </span>
                            <span v-else>
                                Dia atual
                            </span>
                        </div>
                        <div class="col-sm-2 pt-0 pl-1 row">
                            <span class="col-sm-9 pt-1 pl-1">
                                {{ item.mcapuracao == 1 ?
        parseInt(item.mcprogramado / 1000) :
        item.mcprogramado }}
                            </span>
                            <span class="col-sm-3 pl-1" style="border-left: 2px solid #e9e9e9">
                                <div :class="'col-sm-8 pt-1 ' + item.mtidescricao">
                                    {{ item.mtidescricao == 'Preventiva' ?
        'P' : 'C' }}
                                </div>
                            </span>
                        </div>
                        <div class="col-sm-2 p-1">
                            {{ item.clnome }}
                        </div>
                        <div class="col-sm-2 p-1 row">
                            <span class="col-sm-10 nopadding">
                                {{ item.dataatualizacao }}
                            </span>
                            <span class="olinho col-sm-2 nopadding" align="right" title="Visualizar manutenção"
                                event="click" @click="redirecionaManutencao(item)">
                                <baseIcon size='20' :icon="mdiEyeOutline" />
                            </span>
                        </div>
                    </div>
                </draggable>
            </b-collapse>
            <!-- Realizadas -->
            <div v-show="checkRealizadas &&
        dadosKanban.Realizadas ? true : false" class="col-sm-12 mt-3 nopadding">
                <div class="header Realizadas cursor-pointer row" @click="chevronRealizadas =
        !chevronRealizadas">
                    <span class="col-sm-2 nopadding">
                        <b-icon shift-v="-1" shift-h="-8" style="font-weight: bold;" :icon="chevronRealizadas ?
        'chevron-down' : 'chevron-right'" />
                    </span>
                    <span class="col-sm-10">
                        Realizadas
                    </span>
                </div>
            </div>
            <b-collapse :visible="chevronRealizadas">
                <div v-show="checkRealizadas && dadosKanban.Realizadas.length != 0"
                    v-for="(item, i) in dadosKanban.Realizadas" :key="i + '_Realizadas'"
                    class="col-sm-12 row bodyTable pr-0 pl-0">
                    <div class="col-sm-1 p-1">
                        {{ item.mcordemservico }}
                    </div>
                    <div class="col-sm-1 p-1">
                        {{ item.veplaca }}
                    </div>
                    <div class="col-sm-1 p-1">
                        {{ item.veprefixo }}
                    </div>
                    <div class="col-sm-2 p-1">
                        {{ item.mcdescricao }}
                    </div>
                    <div class="col-sm-1 p-1">
                        <span v-if="item.mcapuracao != 3">
                            {{
        item.mcapuracao == 1 ?
            parseInt(item.vchodometro / 1000) :
            item.vchorimetro
    }}
                        </span>
                        <span v-else>
                            Dia atual
                        </span>
                    </div>
                    <div class="col-sm-2 pt-0 pl-1 row">
                        <span class="col-sm-9 pt-1 pl-1">
                            {{ item.mcapuracao == 1 ?
        parseInt(item.mcprogramado / 1000) :
        item.mcprogramado }}
                        </span>
                        <span class="col-sm-3 pl-1" style="border-left: 2px solid #e9e9e9">
                            <div :class="'col-sm-8 pt-1 ' + item.mtidescricao">
                                {{ item.mtidescricao == 'Preventiva' ?
        'P' : 'C' }}
                            </div>
                        </span>
                    </div>
                    <div class="col-sm-2 p-1">
                        {{ item.clnome }}
                    </div>
                    <div class="col-sm-2 p-1 row">
                        <span class="col-sm-10 nopadding">
                            {{ item.dataatualizacao }}
                        </span>
                        <span class="olinho col-sm-2 nopadding" align="right" title="Visualizar manutenção"
                            event="click" @click="redirecionaManutencao(item)">
                            <baseIcon size='20' :icon="mdiEyeOutline" />
                        </span>
                    </div>
                </div>
            </b-collapse>
        </div>
    </div>
</template>
<script>
import { mdiEyeOutline }
    from '@mdi/js'
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import Vue from 'vue'
export default Vue.extend({
    name: 'ListagemControleManutencao',
    components: {
        draggable,
        'baseIcon': require('@/components/Atom/Icon/BaseIcon').default,
        'statusInformation': require('@/components/Atom/StatusInformation/StatusInformation').default,
    },

    data() {
        return {
            link: '/manutencoes/nao/menus/controle/manutencao/',
            mdiEyeOutline: mdiEyeOutline,
            drag: {},
            chevronCadastradas: true,
            chevronVencidas: true,
            chevronProgramar: true,
            chevronExecucao: true,
            chevronRealizadas: true,
            status: '',
        }
    },

    props: {
        dadosKanban: {
            type: [Object, Array],
            required: true,
        },

        checkRealizadas: {
            type: Boolean,
            required: true,
        },

        listaKanban: {
            type: Boolean,
            required: true,
        },

        statusbar: {
            type: String,
            default: 'info'
        }
    },

    methods: {
        ...mapGetters(['getMaster', 'getEditar', 'getCadastrar']),

        /**
         * @description Função para redirecionar para 
         *  página de cadastro de manutenção
         * @author Vitor Hugo 🐨
         */
        redirecionaManutencao(dados) {
            if (!this.getEditar()) return;
            window.open(`#/manutencao/cadastros/manutencao/cadastrar/${dados.mhcodigomanutencao}`, '_blank')
        },

        /**
         * @description Emite um evento para abrir o modal na página principal
         * @author Vitor Hugo 🐨
         */
        openModalConfirmar(dados) {
            if (!this.getEditar()) return;
            this.$emit('openModalConfirmar', dados)
        },

        /**
         * @description Método altera as posições dos objetos nas arrays de
         *  acordo com o dragging. Emite um evento para atualizar as listas.
         *  Verifica também se foi solto somente nas listas de execução ou programar,
         *  no caso de ser a programar não pode ter vindo como vencida
         * @param {Object} list = elemento de adição ou remoção da lista
         * @param {Object} status = em qual tipo de lista foi adicionado ou removido
         * @author Vitor Hugo 🐨
         */
        endDraggin(list, status) {
            if (!this.getEditar()) return;
            if (list.added && status == 'programar' && list.added.element.mhcodigostatus != 3) {
                this.drag = list.added.element
                this.status = status
            } else if (list.added && status == 'execucao') {
                this.drag = list.added.element
                this.status = status
            } else if (list.removed && Object.values(this.drag).length == 0) {
                this.toastShow('Não é possível realizar a ação', 'warning')
                this.$emit('listarKanban')
            }
            if (list.removed && Object.values(this.drag).length > 0) {
                this.$emit('onDrop', this.drag, this.status)
                this.drag = {}
            }
        },

        toastShow(msg, type, title = '') {
            if (!this.getEditar()) return;
            this.$bvToast.toast(`${msg}`, {
                autoHideDelay: 3000,
                variant: type,
                title: title
            })
        },

        alteraListaKanban(status) {
            if (status == 'kanban') {
                this.listaKanban = true
            } else {
                this.listaKanban = false
            }
            this.listarKanban()
        }
    },
})
</script>

<style lang="scss"></style>