import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueGeolocation from 'vue-browser-geolocation'

import VueApexCharts from 'vue-apexcharts'
import 'nprogress/nprogress.css'
// import ProgressBar from 'vuejs-progress-bar'
// Vue.use(ProgressBar)




import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import 'vue-progress-path/dist/vue-progress-path.css'
import 'element-ui/lib/theme-chalk/index.css'
import 'vue-file-agent/dist/vue-file-agent.css'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

import VCalendar from 'v-calendar'
import { AuthService } from "./Services/auth/Auth.Service"; 
import { HttpRequest }from "./Services/auth/HttpRequest.Service";
import { PermissionsService,RouterService }from "./Services/route/Route.Service";
import { BootstrapVueIcons, BootstrapVue, BIcon } from 'bootstrap-vue';
import VueSidebarMenu from 'vue-sidebar-menu';
import VueDataTables from 'vue-data-tables'
import ExTableColumn from 'ex-table-column';
import ElementUI from 'element-ui'
import VueProgress from 'vue-progress-path'
import VueFileAgent from 'vue-file-agent';
import Vuelidate from 'vuelidate'
import PortalVue from 'portal-vue'
import VueFullscreen from 'vue-fullscreen'
import VueMeta from 'vue-meta'

Vue.use(VCalendar, {
  componentPrefix: 'vc'
})

Vue.use(VCalendar)
Vue.use(PortalVue)
Vue.use(Vuelidate)
Vue.use(ElementUI)
Vue.component(ExTableColumn.name, ExTableColumn)
Vue.use(VueDataTables)
Vue.use(VueSidebarMenu)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueProgress)
Vue.use(VueFileAgent);
Vue.use(require('vue-moment'))
Vue.component('BIcon', BIcon)
Vue.use(VueApexCharts)
Vue.use(VueFullscreen)

Vue.component('apexchart', VueApexCharts)
 
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})


// Vue.config.productionTip = true

Vue.prototype.auth = new AuthService()
Vue.prototype.request = new HttpRequest();
Vue.prototype.route = new RouterService();
Vue.prototype.permisison = new PermissionsService().GetPermissions()
Vue.prototype.user = new AuthService().getUser();
Vue.prototype.store = store.state;

// Load the core build.
var _ = require('lodash/core');
// Load the FP build for immutable auto-curried iteratee-first data-last methods.
var fp = require('lodash/fp');
 
// Load method categories.
var array = require('lodash/array');
var object = require('lodash/fp/object');
 
// Cherry-pick methods for smaller browserify/rollup/webpack bundles.
var at = require('lodash/at');
var curryN = require('lodash/fp/curryN');

// Decodificar e encodificar polyline
var decodePoly = require('@mapbox/polyline');

import lang from 'element-ui/lib/locale/lang/pt-br'
import locale from 'element-ui/lib/locale'

// Id da App do telecom. Esse Id fica na URL de seu app
// exemplo: https://app.intercom.com/a/apps/{sua_id}/..
import VueIntercom from 'vue-intercom';
Vue.use(VueIntercom, {
  appId: 'kj3eegx3',
});

locale.use(lang)
Vue.config.devtools = process.env.NODE_ENV === 'development'
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
