<template>
    <div>
        <div class="col-sm-12 row borda-tab nopadding">
            <div class="col-sm-12 row 
                borda-tab justify-content-center">
                <div class="col-sm-9 row nopadding">
                    <InputSimple placeholder=" Buscar" inputClass="buscando-pedido nopadding" @changeInput="changeInput"
                        name="searchOrder" id="searchOrder" />
                </div>
                <SimpleButton extraClass="col-sm-2" :icon="mdiMagnify" title="Buscar Veículos" type="orange" event="none"
                    width="100%" />
            </div>
            <div class="col-sm-12 row tab-pedidos nopadding">
                <template v-for="(dados) in valueVeiculos">
                    <CardVeiculo v-if="true" :key="dados.veplaca + '_veiculo'" :dados="dados"
                        @click="carregarVeiculo(dados)">
                    </CardVeiculo>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mdiMagnify } from '@mdi/js'
export default {
    name: 'TabVeiculos',
    components: {
        CardVeiculo: require(
            "@/components/Atom/SpecificComponents/RotaManual/CardVeiculo.vue").default,
        SimpleButton: require("@/components/Atom/Buttons/SimpleButton").default,
        InputSimple: require("@/components/Atom/Inputs/InputSimple").default,
    },
    props: {
        valueVeiculos: {
            required: true,
            type: Array,
            default: function () { return [] },
        },
        statusBarVeiculos: {
            required: false,
            type: String,
            default: '',
        },
    },
    data() {
        return {
            mdiMagnify,
        }
    },
    methods: {
        changeInput(value) {
            this.$emit('changeInput', value)
        },
        carregarVeiculo(value) {
            this.$emit('carregarVeiculo', value)
            this.$emit('buscarMarcadores')
        },
    },
}
</script>