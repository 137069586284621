<template>
    <tr>
        <td>Salvo com sucesso</td>
        <td>{{dados.documento}}</td>
        <td colspan="2">Atenção {{dados.erro}}</td>
    </tr>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
    props:{
        dados:{
            type: [Array, Object],
            required: true,
            default: function(){return {}}
        }
    },
})
</script>

<style lang="scss">

</style>