<template>
    <div id="excluir-fechamento">
        <eagle-modal
            :id="id"
            title="Excluir fechamento"
            @confirmButton="confirmaExclusao"
        >
            <template #modalBody>
                <b-overlay :show="loading">
                    <div v-if="podeExcluir">
                        <div>
                            Será realizada a exclusão do fechamento no periodo
                            de {{ periodoFechamento(ultFechamento) }}
                        </div>
                        <div class="fechamentos-title">
                            Colaboradores associados ao fechamento:
                        </div>
                        <div
                            v-for="(mt, i) in ultFechamento?.mts"
                            :key="`${i}_${mt.mtcodigo}`"
                            class="fechamentos-lista-trab"
                            v-text="mt.mtnome"
                        />
                    </div>
                    <div v-else>
                        {{ pretexto }}
                        {{ periodoFechamento(ultFechamento) }}
                    </div>
                </b-overlay>
            </template>
            <template #modalFooter>
                <simpleButton
                    :icon="iconRed"
                    @click="cancelButton"
                    text="Cancelar"
                    event="click"
                    type="red"
                />
                <simpleButton
                    :icon="iconBlue"
                    @click="confirmaExclusao"
                    text="Confirmar"
                    :disabled="!podeExcluir"
                    event="click"
                    type="blue"
                />
            </template>
        </eagle-modal>
        <eagle-modal
            id="confirma-excluir-fechamento"
            @confirmButton="excluiFechamento"
            :title="'Excluir fechamento'"
        >
            <template #modalBody>
                <div class="col-sm-12 nopadding">
                    Ao confirmar essa operação não será mais possível revertê-la!
                </div>
                <div class="col-sm-12 nopadding">
                    Tem certeza que deseja fazer isso?
                </div>
            </template>
        </eagle-modal>
    </div>
</template>

<script>
import { mdiCloseThick, mdiCheckBold } from "@mdi/js";
import { conectionError } from "@/Services/Helpers/swellHeper";
import { HttpRequest } from "@/Services/auth/HttpRequest.Service";
import { DateTime } from "luxon";
const limite_deletar_fechamento = 10;
export default {
    name: "ExcluirFechamentoModal",
    components: {
        eagleModal: require("@/components/Atom/Modal/ModalEagle.vue").default,
        simpleButton: require("@/components/Atom/Buttons/SimpleButton").default,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            //icons
            iconRed: mdiCloseThick,
            iconBlue: mdiCheckBold,
            //controle
            fechamentoAntigo: false,
            loading: false,
            fechamentosTable: [],
            todosFechamentos: {},
            ultFechamento: {},
            situacaoFechamento: "",
            pretexto: `Limite de ${limite_deletar_fechamento} dias para excedido\nUltimo periodo de fechamento`,
            origem:'checkpoint',
        };
    },
    methods: {
        /**
         * @param {object} ultFechamento
         * @param {string} ultFechamento.inicio
         * @param {string} ultFechamento.fim
         * @param {object[]} ultFechamento.mts
         * @param {number} ultFechamento.codigo
         */
        listaFechamentos(ultFechamento, origem='checkpoint') {
            this.resetaModal();
            this.origem = origem
            this.ultFechamento = ultFechamento;
        },

        /**
         * @param {object} obj
         * @param {string} obj.inicio formato SQL
         * @param {string} obj.fim formato SQL
         * @description retorna o período do fechamento formatado
         * @return {string} inicio - final
         */
        periodoFechamento({ inicio, fim }) {
            let formato = "dd/LL/yyyy";
            let inicioDt = DateTime.fromSQL(inicio);
            let finalDt = DateTime.fromSQL(fim);
            return `${inicioDt.toFormat(formato)} - ${finalDt.toFormat(formato)}`;
        },

        getFechamentosCod() {
            return [this.ultFechamento.codigo];
        },

        confirmaExclusao() {
            this.$bvModal.show("confirma-excluir-fechamento");
        },

        /**
         * @description deleta um fechamento e todas as informações ligadas a ele
         * checkpoint_fechamento, checkpoint_fechamento_detalhes e checkpoint_saldo_horas
         */
        excluiFechamento() {
            let url = `/checkpoint/nao/menu/fechamento/deleta/fechamento`;
            if (this.origem == 'job') {
                url = `/controle/jornada/fechamento/deleta/fechamento`;
            }
            let obj = { codigos: this.getFechamentosCod() };
            this.loading = true;
            new HttpRequest()
                .Post(url, obj)
                .then((res) => {
                    if (res.data?.success) {
                        this.$bvModal.hide("confirma-excluir-fechamento");
                        this.$bvModal.hide(this.id);
                        this.$emit("recarregar-tabela");
                    } else conectionError();
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        resetaModal() {
            this.loading = false;
            this.ultFechamento = {};
        },

        cancelButton() {
            this.$bvModal.hide(this.id);
        },
    },

    computed: {
        podeExcluir() {
            if (this.ultFechamento?.fim) {
                let fechamento = DateTime.fromSQL(this.ultFechamento.fim);
                let hoje = DateTime.now();
                let diff = hoje.diff(fechamento, ["days"]).toObject();
                if (diff.days < limite_deletar_fechamento) return true;
            }
            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
.fechamentos-title {
    margin-top: 3%;
    margin-left: 3%;
    padding: 0.5%;
    font-weight: bolder;
    width: 90%;
    background: #f6f6f6;
    border-bottom: 1px solid rgb(160, 158, 158);
}
.fechamentos-lista-trab {
    margin-left: 3.6%;
    width: 89.4%;
    padding-left: 3px;
    border-bottom: 1px solid rgb(235, 228, 228);
}
</style>
