<template>
    <panel-eagle id="inconformidadesCheckpoint" :loading='loading'>
        <div class="col-12 row">
            <div class="col-4 nopadding">
                <titulo 
                    :icon='mdiClipboardClockOutline'
                    titulo='Inconformidades'/>
            </div>
            <div class="col-8 nopadding divDontPrint">
                <basicButtonsRelatoriosEIG
                    :loading='loadingExportar'
                    :disabledDropdown='!Object.values(tableData).length'
                    :disabled='$v.$invalid'
                    @gerarRelatorio='gerarRelatorio'
                    @exportarRelatorio='exportarRelatorio'>
                </basicButtonsRelatoriosEIG>
            </div>
        </div>
        <slideUpAndDown class="divDontPrint">
            <div class="col-sm-12"><hr></div>
            <div class="col-12 nopadding row mb-2">
                <div class="col-4 nopadding">
                    <inputRange
                        :isObrigatorio='true'
                        name='range-extrato'
                        @changeInput='changeIntervalo'/>
                </div>
                <div class="col-4 nopadding">
                    <selectAll
                        nameForRadio='selCliente'
                        :isMultiple='true'
                        :hasSelectAll='true'
                        :labels='labelSelectCliente'
                        :optionsArray='optClientes'
                        firstSelected='MR'
                        :extraClass='{
                            "border border-danger":$v.clienteSelecionado.$anyError
                        }'
                        @close='$v.clienteSelecionado.$touch()'
                        @changeSelect='changeEmpresa'/>
                </div>
                <div class="col-4 nopadding">
                    <selectAll
                        nameForRadio='selTrabalhador'
                        ref='selTrabalhador'
                        :labels='labelSelectTrabalhador'
                        :isMultiple='true'
                        :disabled='desabilitaSeletorT'
                        :hasSelectAll='true'
                        :loading='loadingTrabalhador'
                        :optionsArray='optSelectWorker'
                        :extraClass='{"border border-danger":
                            $v.trabalhadorSelecionado.$anyError}'
                        firstSelected='MAD'
                        @close='$v.trabalhadorSelecionado.$touch()'
                        @changeSelect='changeTrabalhador'
                        @changeCheck ='changeCTrabalhador'/>
                </div>
            </div>
        </slideUpAndDown>
        <div class="col-sm-12"><hr></div>
        <div class="mt-2 col-12 nopadding">
            <tableRelatorio>
                <template slot="thead">
                    <tr>
                        <th>Data</th>
                        <th>Inconformidade</th>
                        <th>Departamento</th>
                        <th>Cargo</th>
                        <th>Empresa</th>
                    </tr>
                </template>
                <template slot="tbody">
                    <statusBar
                        v-if ="tableData.length == 0"
                        style=""
                        colspan="0"
                        :statusBar='statusBar'
                        typeBar='tr'/>
                    <template v-else
                        v-for="(dados, a) in tableData">
                        <tr v-if="true"
                            :key="a+'_badge'"
                            class="backColorTdPDF">
                            <td colspan="6" style="padding:0px;">
                                <span class="newbadge">
                                    {{dados[0].colaborador}}  | {{dados.length}}
                                </span>
                            </td>
                        </tr>
                        <tr v-for="(dado, b) in dados"
                            :key="a+b+'_item'"
                            class="paddingTd">
                            <td>{{dado.data}}</td>
                            <td>{{dado.inconformidade}}</td>
                            <td>{{dado.departamento}}</td>
                            <td>{{dado.cargo}}</td>
                            <td>{{dado.empresa}}</td>
                        </tr>
                    </template>
                </template>
            </tableRelatorio>
        </div>
        <requiredFields class="divDontPrint"/>
        <div class="col-sm-12"><hr></div>
	</panel-eagle>
</template>

<script>
import { mdiClipboardClockOutline } from '@mdi/js';
import { DateTime } from 'luxon'
import { required } from 'vuelidate/lib/validators'
import { EmpresasService } from '../../../Services/auth/Empresas.service'
import { FiltrosService }  from '../../../Services/filtros/filtros.Service'
import { HttpRequest }     from '../../../Services/auth/HttpRequest.Service'
import { conectionError } from '@/Services/Helpers/swellHeper'

export default {
    name:'InconformidadesCheckpoint',
    components:{
		slideUpAndDown: require('@/components/Atom/SlideUpAndDown/SlideUpAndDown.vue').default,
		panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
		basicButtonsRelatoriosEIG : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
		titulo: require('@/components/Atom/Header/Titulo').default,
		selectAll: require('@/components/Atom/Select/SelectAll').default,
		inputRange: require('@/components/Atom/Datas/InputRangeWithLimit').default,
		tableRelatorio: require('@/components/Atom/Table/TableRelatorio').default,
		statusBar: require('@/components/Atom/StatusInformation/StatusInformation.vue').default,
		requiredFields: require('@/components/Atom/Footer/RequiredFields').default,
    },
    data(){
		return{
			mdiClipboardClockOutline: mdiClipboardClockOutline,
            link: '/checkpoint/relatorios/inconformidades/',
			statusBar: 'info',
			loading: false,
			loadingExportar: [false, false, false],
			mostraTabela:false,
			loadingTrabalhador:false,
			quitarTodos: false,
			fecharTodos: false,
			tableData: [],
			//opts
			optClientes: new EmpresasService().Get(),
			optTrabalhador:{
				'MAD':[],
				'D':[]
			},
			labelTrabalhador:'MAD',
			//labels
			labelSelectTrabalhador:[
				{indexDFH:'MAD', description:'Colaboradores*'},
				{indexDFH:'D', description:'Departamentos*'}
			],
			labelSelectCliente:[
				{indexDFH:'cc', description:'Empresas*'}
			],
			//selecionados
			clienteSelecionado: [],
			trabalhadorSelecionado: [],
			intervalo:'',
		}
	},
	validations:{
		clienteSelecionado:{ required },
		trabalhadorSelecionado:{ required }
	},
    methods:{
		
		gerarRelatorio(){
			var url = `${this.link}gerar/relatorio`
			var obj = {
				intervalo: this.intervalo,
				empresas: this.clienteSelecionado,
				colaboradores: this.trabalhadorSelecionado,
				tipo: this.labelTrabalhador,
			}
			this.tableData = []
			this.loading = true
			new HttpRequest().Post(url,obj)
                .then((res)=>{
                    if(res.status) {
                        this.tableData = res.data
                        if(Object.values(this.tableData).length == 0){
                            this.statusBar = 'error';
                        }else {
                            this.statusbar = 'error2'
                        }
                    } else {
                        this.statusBar = 'error';
                        conectionError()
                    }
                }).catch(() => {
                    conectionError()
                }).finally(() => this.loading = false )
		},

		/**
		 * @param {object} obj
		 * @param {string} obj.fpultima  data no formato ISO
		 * @return {string} no formato dd/mm/yyyy
		 */
		formataData({fpultima}){
			var data = DateTime.fromISO(fpultima)
			return data.toFormat('dd/LL/yyyy')
		},

		/**
         * @listens changeCheck 
         * @param {'MAD'|'D'} label
         */
		changeCTrabalhador(label){
			this.tableData = []
			this.labelTrabalhador = label
		},

		/**
         * @listens changeSelect
         * @param {number[]} arr
         */
		changeTrabalhador(arr){
			this.tableData = []
			this.trabalhadorSelecionado = arr
		},
        
		/**
         * @listens changeSelect
         * @param {number[]} arr - clientes selecionados
         * @description muda o cliente selecionado e busca pelas
         * opts do seletor de funcionário
         */
		changeEmpresa(arr){
			this.clienteSelecionado = arr 
			this.tableData = []
			this.trabalhadorSelecionado = []
			this.optTrabalhador = {
				'MAD':[],
				'D':[]
			}
			this.$v.trabalhadorSelecionado.$reset()
			this.$refs.selTrabalhador.clearAll()
			if(arr.length){
				this.buscarFiltros()
			}
		},

        buscarFiltros() {
            this.loadingTrabalhador = true
            new FiltrosService()
                .dados_filtros(this.clienteSelecionado, ['D', 'MAD'])
                .then((data)=>{
                    if(data && data.MAD && data.D) {
                        this.optTrabalhador.MAD = data.MAD
                        this.optTrabalhador.D = data.D
                    } else {
                        conectionError()
                    }
                }).catch(()=>
                    conectionError()
                ).finally(()=> this.loadingTrabalhador = false )
        },

		/**
		 * @listens changeInput
         * @param {string} intervalo formato dd/mm/yyyy - dd/mm/yyyy
         */
		changeIntervalo(intervalo){
			this.tableData = []
			this.intervalo = intervalo
		},

		exportarRelatorio(formato){
			let uri = `${this.link}exportar`
			let obj = {
				intervalo: this.intervalo,
				arrayDados:this.tableData,
                tipo:formato
			}
			this.decideExportar(formato)
			new HttpRequest().Post(uri, obj)
				.then((res)=>{
                    if(res.status) {
                        var root =  process.env.VUE_APP_ROOT;
                        open(root+'/'+res.data.local)
                    } else {
                        conectionError()
                    }
				})
				.finally(() => this.decideExportar('none') )
		},

		/**
		 * @param {'pdf'|'xls'|'csv'|'none'} formato
		 */
		decideExportar(formato){
			this.loadingExportar = [
				formato === 'pdf',
				formato === 'xls',
				formato ==='csv'
			]
		},		
  
    },

	computed:{
		optSelectWorker(){
			return this.optTrabalhador[this.labelTrabalhador]
		},
		desabilitaSeletorT(){
			return !this.optSelectWorker || this.optSelectWorker.length == 0
		},
	}
}
</script>

<style lang ='scss'>
#inconformidadesCheckpoint{	
    .horarios{
        text-align:justify !important;
        font-size: 2vh;
        width:100vw;
    }
}
</style>