/**
    Este é o componente com background branco - Usar ele sempre

    -> Propriedades:
        1 - id - String - Id da página para separar o css - EX: 'panelEagle'
        2 - loading - Boolean - Aciona o overlay sob a página
        3 - opacity - Number/String - Opacidade do overlay

    -> Slots:
        1- Slot sem nome - Para colocar as coisas da página

    -> Eventos:
        Sem eventos
 */

<template>
    <div class="col-sm-12 panelEagle nopadding" :id="id">
        <b-overlay :show='loading' :opacity='opacity' class='bemAlto'>
            <slot></slot>
        </b-overlay>
    </div>    
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
	props:{
		id:{
			type: String,
			required: true,
			default: '',
		},
		loading: {
			type: Boolean,
			default: false
		},
		opacity:{
			type: [String, Number],
			default: 0.65
		}
	}
})

</script>

<style lang="scss">
    .no-wrap{
        white-space: nowrap !important;
    }
    .panelEagle{
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        background: #fff;
        border-top-color: #F5F5F5;
        border-top-width: 1px;
        color: #22262e;
        // overflow-y: auto;
        // height: 41em;
    }

    .form-control{
        font-size: 13px !important;
        border-radius: 0px !important;
        padding: 12px 5px !important;
        height: 41px !important;
    }

    .row{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    @media print{
        .divDontPrint{
            display: none;
        }
    }

    .divHrDeBaixo{
        margin-bottom: 15px;
    }

    hr{
        border-top: 1px solid rgb(66, 139, 202) !important;
    }
    
    .row{
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
    .bemAlto{
        z-index: 500;
    }

    /// cursor
        .cursor-auto{
            cursor: auto !important;
        }
        .cursor-default{
            cursor: default !important;
        }
        .cursor-none{
            cursor: none !important;
        }
        .cursor-context-menu{
            cursor: context-menu !important;
        }
        .cursor-help{
            cursor: help !important;
        }
        .cursor-pointer{
            cursor: pointer !important;
        }
        .cursor-progress{
            cursor: progress !important;
        }
        .cursor-wait{
            cursor: wait !important;
        }
        .cursor-cell{
            cursor: cell !important;
        }
        .cursor-crosshair{
            cursor: crosshair !important;
        }
        .cursor-text{
            cursor: text !important;
        }
        .cursor-vertical-text{
            cursor: vertical-text !important;
        }
        .cursor-alias{
            cursor: alias !important;
        }
        .cursor-copy{
            cursor: copy !important;
        }
        .cursor-move{
            cursor: move !important;
        }
        .cursor-no-drop{
            cursor: no-drop !important;
        }
        .cursor-not-allowed{
            cursor: not-allowed !important;
        }
        .cursor-all-scroll{
            cursor: all-scroll !important;
        }
        .cursor-col-resize{
            cursor: col-resize !important;
        }
        .cursor-row-resize{
            cursor: row-resize !important;
        }
        .cursor-n-resize{
            cursor: n-resize !important;
        }
        .cursor-e-resize{
            cursor: e-resize !important;
        }
        .cursor-s-resize{
            cursor: s-resize !important;
        }
        .cursor-w-resize{
            cursor: w-resize !important;
        }
        .cursor-ne-resize{
            cursor: ne-resize !important;
        }
        .cursor-nw-resize{
            cursor: nw-resize !important;
        }
        .cursor-se-resize{
            cursor: se-resize !important;
        }
        .cursor-sw-resize{
            cursor: sw-resize !important;
        }
        .cursor-ew-resize{
            cursor: ew-resize !important;
        }
        .cursor-ns-resize{
            cursor: ns-resize !important;
        }
        .cursor-nesw-resize{
            cursor: nesw-resize !important;
        }
        .cursor-nwse-resize{
            cursor: nwse-resize !important;
        }
        .cursor-zoom-in{
            cursor: zoom-in !important;
        }
        .cursor-zoom-out{
            cursor: zoom-out !important;
        }
        .cursor-grab{
            cursor: grab !important;
        }
        .cursor-grabbing{
            cursor: grabbing !important;
        }
</style>