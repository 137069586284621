/** 
  Este é um compoenete de indicadores para a dashboard

  Propriedades:
    1  -> titleIndicador - Título do indicador - Ex: 'Indicador'
    2  -> mensagem - Mensagem que irá ficar em negrito - Ex: 'Mensagem indicador'
    3  -> info - Campo extra para informações - Ex: 'Mais dados'
    4  -> backgroundColor - Cor de fundo do indicador - Ex: 'gray'
    5  -> footer - Mensagem de footer - Ex: 'Esse é o footer'
    6  -> sizeMensagem - Tamanho da mensagem - Ex: 10
    7  -> sizeInfo - Tamanho da info - Ex: 5
    8  -> icon - Campo para colocar ícone - Ex: 'mdiicon'
    9  -> height - Altura do indicador - Ex: '500'
    10 -> width - Largura do indicadorm X - Ex: 600
    11 -> sizeIcon - Tamanho do ícone Y - Ex: 20
    12 -> colorIcon - Cor do ícone - Ex: 'green'
    13 -> colorInfo - Cor da info - Ex: 'green'
    14 -> sizeTitle - tamanho do título - Ex: '20'
    15 -> colorMensagem - Cor da mensagemt - Ex:'red'
*/

<template id="indicators">
    <div :style="'height:'+height+'px; width:'+width+'%; vertcal-aling:center'"
        :class="'col-sm-12 p-0 bloco background-'+backgroundColor">
        <div :style="'font-size:'+sizeTitle+'px;'"
            class="pt-3">
            {{titleIndicador}}
        </div>
        <div>
            <span :style="'font-size:'+sizeMensagem+'px;'"
                :class="'negrito icon-'+colorMensagem">
                {{mensagem}}
            </span>
            <span v-if="icon"
                :class="'icon-'+colorIcon">
                <baseIcon 
                    :icon='icon' :size='sizeIcon'/>
            </span>
            <span 
                :class="'icon-'+colorInfo"
                :style="'font-size:'+sizeInfo+'px; font-weight:700;'">
                {{info}}
            </span>
        </div>
        <div class="nopadding">
            {{footer}}
        </div>
    </div>
</template>
<script>
    import Vue  from 'vue'
    export default Vue.extend({
        name:'Indicador',

        components:{
            'baseIcon': require('@/components/Atom/Icon/BaseIcon.vue').default,
        },

        props:{
            titleIndicador:{
                type: String,
                required: true,
            },
            mensagem:{
                type: [String, Number],
                required: true,
            },
            info:{
                type: [String, Number],
                default: '',
            },
            backgroundColor:{
                type: String,
                default:'gray'
            },
            footer:{
                type: String,
                default: '',
            },
            sizeMensagem:{
                type: String,
                default: '20',
            },
            sizeInfo:{
                type: String,
                default: '11',
            },
            icon:{
                type: String,
                default: '',
            },
            height:{
                type: [String, Number],
                default: 58,
            },
            width:{
                type: String,
                default: '100'
            },
            sizeIcon:{
                type: [Number, String],
                default: 20,
            },
            colorIcon: {
                type: String,
                default: '',
            },
            colorInfo: {
                type: String,
                default: '',
            },
            sizeTitle:{
                type: [String, Number],
                default: 13
            },
            colorMensagem:{
                type: String,
                default: '',
            },         
        },
    })
</script>
<style lang='scss'>
    .background-gray {
        background: #f8f8f8 !important;
        box-shadow: 1px 1px 1px 1px #e0e0e0;
    }
    .background-red {
        background: #F5C2C2 !important;
        box-shadow: 1px 1px 1px 1px #e0e0e0;
    }
    .background-ciano {
        background-image: linear-gradient( 135deg, #89c5f0 50%, #097aca 100%);
    }
    .bloco{
        font-family: nexabook, sans-serif;
        font-size: 20px !important;
    }
    .footer{
        background: #f5f5f5;
    }
    .icon-green{
        color: green;
    }
    .icon-red{
        color: red;
    }
    .negrito{
        font-weight: bold;
    }
</style>