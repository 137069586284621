<template>
    <panelEagle id="listagemRotas" :loading="loadingPanel">
        <div class="col-sm-12 nopadding">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiMapMarkerDistance" titulo="Rotas"></tituloPage>
                </div>
                <div class="col-sm-6 divDontPrint">
                    <basicButtonsCadastrosEIN
                        :disabledDropdown="!Object.keys(dataTable).length"
                        @exportarCadastro="exportarCadastro"
                        :loading="loadingButton"
                        linkNew=""
                        @novoCadastro="editarCadastro"
                        tipoNovo="button"/>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-4 nopadding divDontPrint">
                    <selectAll 
                        @changeSelect="changeEmpresa"
                        :isMultiple="true" 
                        :hasSelectAll="true" 
                        nameForRadio="empresas" 
                        :labels="[{indexDFH: 'EM', description: 'Empresas'}]" 
                        :optionsArray="optionsArrayEmpresas"
                        :selected="selectedEmpresa"
                        firstSelected="EM"/>
                </div>
                <div class="col-sm-4 nopadding divDontPrint">
                    <selectAll
                        :disabled="!optionsArrayRotas.length"
                        ref="refRotas"
                        :loading="loadingRotas"
                        @changeSelect="changeRotas"
                        :isMultiple="true" 
                        :hasSelectAll="true" 
                        nameForRadio="rotas" 
                        :labels="[{indexDFH: 'RO', description: 'Rotas'}]" 
                        :optionsArray="optionsArrayRotas" 
                        firstSelected="RO"/>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <tableListagem
                @excluirCadastro="excluirCadastro"
                @editarCadastro="editarCadastro"
                :titles="titles"
                :data='dataTable'
                deleteEdit='editDelete'/>
        </div>
    </panelEagle>
</template>

<script lang="js">
import Vue from 'vue'
import { mdiMapMarkerDistance } from '@mdi/js'
import {EmpresasService} from '../../../Services/auth/Empresas.service'
import {HttpRequest} from '../../../Services/auth/HttpRequest.Service'
import { conectionError } from '@/Services/Helpers/swellHeper.js'
import { mapGetters } from 'vuex'
export default Vue.extend({
	components:{
		'tituloPage' : require('@/components/Atom/Header/Titulo').default,
		'selectAll'  : require('@/components/Atom/Select/SelectAll').default,
		'panelEagle' : require('@/components/Atom/Panel/PanelEagle').default,
		'tableListagem' : require('@/components/Atom/Table/TableListagem').default,
		'basicButtonsCadastrosEIN' :require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,

    },
    data(){
        return{
            mdiMapMarkerDistance:mdiMapMarkerDistance,
            dataTable:[],
            valueRotas:[],
            valueEmpresa:[],
            loadingPanel:false,
            loadingRotas:false,
            optionsArrayRotas:[],
            loadingButton:[false, false, false],
            optionsArrayEmpresas: new EmpresasService().Get(),
            selectedEmpresa:[],
            titles:[
                {'number':'descricao', 'name':'Descrição'},
                {'number':'origem',    'name':'Origem'},
                {'number':'destino',   'name':'Destino'},
                {'number':'distancia', 'name':'Distância'},
                {'number':'tempo',     'name':'Tempo'},
                {'number':'empresa',   'name':'Empresa'},
            ]
        }
    },
    computed: {
        retornaNomeTela() {
            if(this.$route.name === "ListagemRotasWay"){
                return "CadastroRotasWay"
            }
            return "cadastroDeRotas"
        }
    },
    methods:{
        ...mapGetters(['getMaster']),
        async exportarCadastro(value){
            switch (value) {
                case 'pdf':
                    this.loadingButton = [true, false, false]
                    break;
                case 'xls':
                    this.loadingButton = [false, true, false]
                    break;
                default:
                    this.loadingButton = [false, false, true]
                    break;
            }
            var root =  process.env.VUE_APP_ROOT;
            var dados = await new HttpRequest()
                .Post('/eagle/cargo/cadastros/rotas/exporta', 
                        {
                            'empresa':this.valueEmpresa,
                            'rotas':this.valueRotas,
                            'tipo':value
                        }
                    )
            window.open(root+'/'+dados.data.dados);
            this.loadingButton = [false, false, false]
        },

		changeEmpresa(value){
			this.dataTable = []
			this.valueEmpresa = value
			this.$refs.refRotas.clearAll()
			this.listagemRotas()
		},

		changeRotas(value){
			this.valueRotas = value
			this.listagemRotas()
		},

        editarCadastro(value = null) {
            let nome = this.retornaNomeTela
			this.$router.push({name:nome, params:{'id':value}})
		},

		async excluirCadastro(value){
			var dados = await new HttpRequest()
				.Post('/eagle/cargo/cadastros/rotas/excluir', 
					{
						'rpcodigo':value,
					}
				)
			this.listagemRotas()
		},

        async listagemRotas(){
            if(this.valueEmpresa.length > 0){
                this.loadingPanel = true
                this.loadingRotas = true
                try {
                    var dados = await new HttpRequest()
                        .Post('/eagle/cargo/cadastros/rotas/listagem', 
                            {
                                'empresa':this.valueEmpresa,
                                'rotas':this.valueRotas,
                            }
                        )
                    this.dataTable = dados.data.tabela
                    this.optionsArrayRotas = dados.data.rotas
                }catch{
                    conectionError()
                }finally{
                    this.loadingRotas = false
                    this.loadingPanel = false
                }
            }
        }
    },
    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optionsArrayEmpresas
        }
    },
})
</script>

<style lang="scss">

</style>