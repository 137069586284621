var render = function render(){var _vm=this,_c=_vm._self._c;return _c('eaglePanel',{attrs:{"id":"Veículos","loading":_vm.carregando}},[_c('div',{staticClass:"col-sm-12 row nopadding"},[_c('div',{staticClass:"col-sm-6"},[_c('titulo',{attrs:{"titulo":"Veículos","icon":_vm.mdiCarMultiple}})],1),_c('div',{staticClass:"col-sm-6 divDontPrint"},[_c('botoes',{attrs:{"disabledDropdown":!Object.keys(_vm.dataT).length,"linkNew":"cadastrarVeiculo","tipoNovo":"button","loading":_vm.loading},on:{"exportarCadastro":_vm.exportar}})],1),_c('div',{staticClass:"col-sm-12"},[_c('hr')]),_c('div',{staticClass:"col-lg-12 row nopadding"},[_c('div',{staticClass:"col-lg-4 nopadding divDontPrint"},[_c('selectAll',{attrs:{"nameForRadio":"selectEmpresa","selected":_vm.firstOne,"labels":_vm.labelSeletorEmpresas,"isMultiple":true,"hasSelectAll":true,"optionsArray":_vm.optSelectEmpresas},on:{"changeSelect":_vm.mudaFiltroEmpresa}})],1),_c('div',{staticClass:"col-lg-5 nopadding left divDontPrint"},[_c('bfilter',{attrs:{"label":"Status","onlyOneSelected":true,"arrayButtons":_vm.botoesStatus},on:{"buttonsFiltersChange":_vm.mudaBotaoStatus}}),_c('div',{staticClass:"filtro"},[_c('bfilter',{attrs:{"label":"Módulo","onlyOneSelected":true,"arrayButtons":_vm.botoesModulos},on:{"buttonsFiltersChange":_vm.mudaBotaoModulo}})],1)],1),_c('div',{staticClass:"col-sm-12"},[_c('hr')])])]),_c('tableL',{attrs:{"titles":_vm.titulosT,"data":_vm.dataT},scopedSlots:_vm._u([{key:"acoes",fn:function({ row }){return [(_vm.getEditar())?_c('simpleButton',{attrs:{"type":"green","icon":_vm.mdiPencil,"title":"Editar Veículo","event":"editarCadastro","width":"31%"},on:{"editarCadastro":function($event){return _vm.editar(row.codigo)}}}):_vm._e(),(_vm.getCadastrar())?[_c('simpleButton',{attrs:{"type":"orange","icon":_vm.mdiCarCog,"title":"Solicitar Manutenção","event":"solicitarManutencao","width":"31%"},on:{"solicitarManutencao":function($event){return _vm.modalSolicitarManutencao(
                            row.veplaca,
                            row.veprefixo,
                            row.codigo
                        )}}})]:_vm._e(),(_vm.getMaster() && _vm.getExcluir())?_c('simpleButton',{attrs:{"type":row.status == 'A' ? 'red' : 'light-green',"icon":row.status == 'A' ? _vm.mdiCircleOffOutline : _vm.mdiCheckBold,"title":row.status == 'A' ? 'Desativar Veículo' : 'Ativar Veículo',"event":"alterarStatus","width":"31%"},on:{"alterarStatus":function($event){return _vm.mudaStatus(row.codigo)}}}):_vm._e()]}}])}),_c('ModalSolicitaManutencaoVeiculos',{attrs:{"veiculo":{
            veplaca: _vm.placaVeiculo,
            veprefixo: _vm.prefixoVeiculo,
            vecodigo: _vm.codigoVeiculo,
        }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }