/**
* Componenete para criar o Kanban do controle de manutenção
*/

<template>
    <!-- Kanban -->
    <div id="divDados" 
        class="col-sm-12"
        v-show="listaKanban">
        <statusInformation
            v-if="dadosKanban.length === 0" 
            typeBar="div" 
            :statusBar="statusbar">
        </statusInformation>
        <table v-else
            class="col-sm-12">
            <header>
                <tr class="row-card-headers">
                    <th
                        class="card-title card-header-cadastradas"
                        colspan="1">
                        Cadastradas
                    </th>
                    <th
                        class="card-title card-header-vencidas"
                        colspan="1">
                        Vencidas
                    </th>
                    <th
                        class="card-title card-header-programar"
                        colspan="1">
                        Programar
                    </th>
                    <th
                        class="card-title card-header-execucao"
                        colspan="1">
                        Em execução
                    </th>
                    <th v-show="checkRealizadas"
                        class="card-title card-header-realizadas"
                        colspan="1">
                        Realizadas
                    </th>
                </tr>
            </header>
            <body class="bodyKanbamAgendamento">
                <tr class="row-card-columns col-sm-12">
                    <!-- Cadastradas -->
                    <td class="card-column cadastrada">
                        <div class="clear-list col-sm-12 row">
                            <div v-for="(dados, d) in dadosKanban.Cadastradas"
                                :key="d+'_kanbanCadastradas'"
                                class="item item-disponivel row">
                                <!-- Placa -->
                                <div class="carro-placa">
                                    <div
                                    class="placa div-disponivel col-sm-7 ml-0 nopadding"
                                    :title="dados.veplaca+' | '+dados.veprefixo">
                                        {{dados.veplaca+' | '+dados.veprefixo}}
                                    </div>
                                    <div class="os div-disponivel col-sm-4 ml-0 nopadding"
                                        :title="dados.mcordemservico">
                                        {{dados.mcordemservico != null ?
                                            'OS: '+dados.mcordemservico : ''}}
                                    </div>
                                    <div :class="'col-sm-1 nopadding mt-1 '+dados.mtidescricao"
                                    align='center'
                                    :title="dados.mtidescricao">
                                        {{dados.mtidescricao == 'Preventiva' ? 'P' : 'C'}}
                                    </div>
                                </div>
                                <!-- Descrição -->
                                <div class="div-disponivel row">
                                    <div class="col-sm-11 nopadding"
                                        :title="dados.mcdescricao"> 
                                        <span class="negrito"> Desc: </span>
                                            {{dados.mcdescricao}}
                                    </div>
                                    <div class="col-sm-1 olinho nopadding mb-1"
                                        align="center"
                                        title="Visualizar manutenção"
                                        event="click"
                                        @click="redirecionaManutencao(dados)"> 
                                        <baseIcon 
                                            size='17 ' 
                                            :icon="mdiEyeOutline"/>
                                    </div>
                                </div>
                                <!-- Atual e programado -->
                                <div class="div-disponivel row">
                                    <span v-if="dados.mcapuracao != 3">
                                        <div class="col-sm-12 nopadding mr-3"
                                        :title="dados.mcapuracao == 1 ?
                                                parseInt(dados.vchodometro/1000) : dados.vchorimetro"> 
                                            <span class="negrito"> Atual:</span>
                                            {{dados.mcapuracao == 1 ?
                                                    parseInt(dados.vchodometro/1000) : dados.vchorimetro}}
                                        </div>
                                    </span>
                                    <div class="nopadding" :class="dados.mcapuracao != 3 ? ('col-sm-7') : ('col-sm-12')"
                                        :title="dados.mcapuracao == 1 ? 
                                            parseInt(dados.mcprogramado/1000) : 
                                            dados.mcprogramado"> 
                                        <span class="negrito"> Programado: </span>
                                            {{dados.mcapuracao == 1 ? 
                                                parseInt(dados.mcprogramado/1000) : 
                                                dados.mcprogramado}}
                                    </div>
                                </div>
                                <!-- Observações -->
                                <div class="div-disponivel negrito"
                                    :title="dados.mcobservacao">
                                    Observação: {{dados.mcobservacao}}
                                </div>
                                <!-- Empresa -->
                                <div class="col-sm-12 nopadding row">
                                    <div class="col-sm-6 nopadding div-disponivel cadastro"
                                        :title="dados.clnome">
                                        {{dados.clnome}}
                                    </div>
                                    <div class="col-sm-6 nopadding div-disponivel cadastro"
                                        align='right'
                                        :title="dados.dataatualizacao">
                                        Ult. atualização: {{dados.dataatualizacao}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <!-- Vencidas -->
                    <td class="card-column vencida">
                        <div class="clear-list col-sm-12 row">
                            <div v-for="(dados, d) in dadosKanban.Vencidas"
                                :key="d+'_kanbanVencidas'"
                                draggable
                                @dragstart="onDrag(dados)"
                                class="item item-disponivel row">
                                <!-- Placa -->
                                <div class="carro-placa">
                                    <div
                                    class="placa div-disponivel col-sm-7 ml-0 nopadding"
                                    :title="dados.veplaca+' | '+dados.veprefixo">
                                        {{dados.veplaca+' | '+dados.veprefixo}}
                                    </div>
                                    <div class="os div-disponivel col-sm-4 ml-0 nopadding"
                                    :title="dados.mcordemservico">
                                        {{dados.mcordemservico != null ?
                                            'OS: '+dados.mcordemservico : ''}}
                                    </div>
                                    <div
                                    :class="'col-sm-1 nopadding mt-1 '+dados.mtidescricao"
                                    align='center'
                                    :title="dados.mtidescricao">
                                        {{dados.mtidescricao == 'Preventiva' ? 'P' : 'C'}}
                                    </div>
                                </div>
                                <!-- Descrição -->
                                <div class="div-disponivel row">
                                    <div class="col-sm-11 nopadding"
                                        :title="dados.mcdescricao"> 
                                        <span class="negrito"> Desc: </span>
                                            {{dados.mcdescricao}}
                                    </div>
                                    <div class="col-sm-1 olinho nopadding mb-1"
                                        align="center"
                                        title="Visualizar manutenção"
                                        event="click"
                                        @click="redirecionaManutencao(dados)"> 
                                        <baseIcon 
                                            size='17 ' 
                                            :icon="mdiEyeOutline"/>
                                    </div>
                                </div>
                                <!-- Atual e programado -->
                                <div class="div-disponivel row">
                                    <span v-if="dados.mcapuracao != 3">
                                        <div class="col-sm-12 nopadding mr-3"
                                        :title="dados.mcapuracao == 1 ?
                                                parseInt(dados.vchodometro/1000) : dados.vchorimetro"> 
                                            <span class="negrito"> Atual:</span>
                                            {{dados.mcapuracao == 1 ?
                                                    parseInt(dados.vchodometro/1000) : dados.vchorimetro}}
                                        </div>
                                    </span>
                                    <div class="nopadding" :class="dados.mcapuracao != 3 ? ('col-sm-7') : ('col-sm-12')"
                                        :title="dados.mcapuracao == 1 ? 
                                            parseInt(dados.mcprogramado/1000) : 
                                            dados.mcprogramado"> 
                                        <span class="negrito"> Programado: </span>
                                            {{dados.mcapuracao == 1 ? 
                                            parseInt(dados.mcprogramado/1000) : 
                                            dados.mcprogramado}}
                                    </div>
                                </div>
                                <!-- Observações -->
                                <div class="div-disponivel negrito"
                                    :title="dados.mcobservacao">
                                    Observação: {{dados.mcobservacao}}
                                </div>
                                <!-- Empresa -->
                                <div class="col-sm-12 nopadding row">
                                    <div class="col-sm-6 nopadding div-disponivel cadastro"
                                        :title="dados.clnome">
                                        {{dados.clnome}}
                                    </div>
                                    <div class="col-sm-6 nopadding div-disponivel cadastro"
                                        align='right'
                                        :title="dados.dataatualizacao">
                                        Ult. atualização: {{dados.dataatualizacao}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <!-- Programar -->
                    <td class="card-column programar"
                        @dragover="dragOver"
                        @drop="onDrop('programar')">
                        <div class="clear-list col-sm-12 row">
                            <div v-for="(dados, d) in dadosKanban.Programar"
                                :key="d+'_kanbanProgramar'"
                                draggable
                                @dragstart="onDrag(dados)"
                                class="item item-disponivel row">
                                <!-- Placa -->
                                <div class="carro-placa">
                                    <div class="placa div-disponivel col-sm-7 ml-0 nopadding"
                                        :title="dados.veplaca+' | '+dados.veprefixo">
                                        {{dados.veplaca+' | '+dados.veprefixo}}
                                    </div>
                                    <div class="os div-disponivel col-sm-4 ml-0 nopadding"
                                        :title="dados.mcordemservico">
                                        {{dados.mcordemservico != null ?
                                            'OS: '+dados.mcordemservico : ''}}
                                    </div>
                                    <div :class="'col-sm-1 nopadding mt-1 '+dados.mtidescricao"
                                        align='center'
                                        :title="dados.mtidescricao">
                                        {{dados.mtidescricao == 'Preventiva' ? 'P' : 'C'}}
                                    </div>
                                </div>
                                <!-- Descrição -->
                                <div class="div-disponivel row">
                                    <div class="col-sm-11 nopadding"
                                        :title="dados.mcdescricao"> 
                                        <span class="negrito"> Desc: </span>
                                            {{dados.mcdescricao}}
                                    </div>
                                    <div class="col-sm-1 olinho nopadding mb-1"
                                        align="center"
                                        title="Visualizar manutenção"
                                        event="click"
                                        @click="redirecionaManutencao(dados)"> 
                                        <baseIcon 
                                            size='17 ' 
                                            :icon="mdiEyeOutline"/>
                                    </div>
                                </div>
                                <!-- Atual e programado -->
                                <div class="div-disponivel row">
                                    <span v-if="dados.mcapuracao != 3">
                                        <div class="col-sm-12 nopadding mr-3"
                                        :title="dados.mcapuracao == 1 ?
                                                parseInt(dados.vchodometro/1000) : dados.vchorimetro"> 
                                            <span class="negrito"> Atual:</span>
                                            {{dados.mcapuracao == 1 ?
                                                    parseInt(dados.vchodometro/1000) : dados.vchorimetro}}
                                        </div>
                                    </span>
                                    <div class="nopadding" :class="dados.mcapuracao != 3 ? ('col-sm-7') : ('col-sm-12')"
                                        :title="dados.mcapuracao == 1 ? 
                                            parseInt(dados.mcprogramado/1000) : 
                                            dados.mcprogramado"> 
                                        <span class="negrito"> Programado: </span>
                                            {{dados.mcapuracao == 1 ? 
                                            parseInt(dados.mcprogramado/1000) : 
                                            dados.mcprogramado}}
                                    </div>
                                </div>
                                <!-- Observações -->
                                <div class="div-disponivel negrito"
                                    :title="dados.mcobservacao">
                                    Observação: {{dados.mcobservacao}}
                                </div>
                                <!-- Empresa -->
                                <div class="col-sm-12 nopadding row">
                                    <div
                                    class="col-sm-6 nopadding div-disponivel cadastro"
                                    :title="dados.clnome">
                                        {{dados.clnome}}
                                    </div>
                                    <div
                                    class="col-sm-6 nopadding div-disponivel cadastro"
                                    align='right'
                                    :title="dados.dataatualizacao">
                                        Ult. atualização: {{dados.dataatualizacao}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <!-- Em execução -->
                    <td class="card-column execucao"
                        @dragover="dragOver"
                        @drop="onDrop('execucao')">
                        <div class="clear-list col-sm-12 row">
                            <div v-for="(dados, d) in dadosKanban['Em execução']"
                                :key="d+'_kanbanExecucao'"
                                class="item item-disponivel realizadas row"
                                event="click"
                                @click="openModalConfirmar(dados)"
                                draggable
                                @dragstart="onDrag(dados)">
                                <!-- Placa -->
                                <div class="carro-placa">
                                    <div
                                    class="placa div-disponivel col-sm-7 ml-0 nopadding"
                                    :title="dados.veplaca+' | '+dados.veprefixo">
                                        {{dados.veplaca+' | '+dados.veprefixo}}
                                    </div>
                                    <div class="os div-disponivel col-sm-4 ml-0 nopadding"
                                        :title="dados.mcordemservico">
                                        {{dados.mcordemservico != null ?
                                            'OS: '+dados.mcordemservico : ''}}
                                    </div>
                                    <div
                                    :class="'col-sm-1 nopadding mt-1 '+dados.mtidescricao"
                                    align='center'
                                    :title="dados.mtidescricao">
                                        {{dados.mtidescricao == 'Preventiva' ? 'P' : 'C'}}
                                    </div>
                                </div>
                                <!-- Descrição -->
                                <div class="div-disponivel row">
                                    <div class="col-sm-11 nopadding"
                                        :title="dados.mcdescricao"> 
                                        <span class="negrito"> Desc: </span>
                                            {{dados.mcdescricao}}
                                    </div>
                                    <div
                                    class="col-sm-1 olinho nopadding mb-1"
                                    align="center"
                                    title="Visualizar manutenção"
                                    event="click"
                                    @click="redirecionaManutencao(dados)"> 
                                        <baseIcon 
                                            size='17 ' 
                                            :icon="mdiEyeOutline"/>
                                    </div>
                                </div>
                                <!-- Atual e programado -->
                                <div class="div-disponivel row">
                                    <span v-if="dados.mcapuracao != 3">
                                        <div class="col-sm-12 nopadding mr-3"
                                        :title="dados.mcapuracao == 1 ?
                                                parseInt(dados.vchodometro/1000) : dados.vchorimetro"> 
                                            <span class="negrito"> Atual:</span>
                                            {{dados.mcapuracao == 1 ?
                                                    parseInt(dados.vchodometro/1000) : dados.vchorimetro}}
                                        </div>
                                    </span>
                                    <div class="nopadding" :class="dados.mcapuracao != 3 ? ('col-sm-7') : ('col-sm-12')"
                                        :title="dados.mcapuracao == 1 ? 
                                            parseInt(dados.mcprogramado/1000) : 
                                            dados.mcprogramado"> 
                                        <span class="negrito"> Programado: </span>
                                            {{dados.mcapuracao == 1 ? 
                                            parseInt(dados.mcprogramado/1000) : 
                                            dados.mcprogramado}}
                                    </div>
                                </div>
                                <!-- Observações -->
                                <div class="div-disponivel negrito"
                                    :title="dados.mcobservacao">
                                    Observação: {{dados.mcobservacao}}
                                </div>
                                <!-- Empresa -->
                                <div class="col-sm-12 nopadding row">
                                    <div class="col-sm-6 nopadding div-disponivel cadastro"
                                        :title="dados.clnome">
                                        {{dados.clnome}}
                                    </div>
                                    <div class="col-sm-6 nopadding div-disponivel cadastro"
                                        align='right'
                                        :title="dados.dataatualizacao">
                                        Ult. atualização: {{dados.dataatualizacao}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <!-- Realizadas -->
                    <td v-show="checkRealizadas"
                        class="card-column realizadas">
                        <div class="clear-list col-sm-12 row">
                            <div v-for="(dados, d) in dadosKanban.Realizadas"
                                :key="d+'_kanbanRealizadas'"
                                class="item item-disponivel row">
                                <!-- Placa -->
                                <div class="carro-placa">
                                    <div class="placa div-disponivel col-sm-7 ml-0 nopadding"
                                        :title="dados.veplaca+' | '+dados.veprefixo">
                                        {{dados.veplaca+' | '+dados.veprefixo}}
                                    </div>
                                    <div class="os div-disponivel col-sm-4 ml-0 nopadding"
                                        :title="dados.mcordemservico">
                                        {{dados.mcordemservico != null ?
                                            'OS: '+dados.mcordemservico : ''}}
                                    </div>
                                    <div :class="'col-sm-1 nopadding mt-1 '+dados.mtidescricao"
                                        align='center'
                                        :title="dados.mtidescricao">
                                        {{dados.mtidescricao == 'Preventiva' ? 'P' : 'C'}}
                                    </div>
                                </div>
                                <!-- Descrição -->
                                <div class="div-disponivel row">
                                    <div class="col-sm-11 nopadding"
                                        :title="dados.mcdescricao"> 
                                        <span class="negrito"> Desc: </span>
                                            {{dados.mcdescricao}}
                                    </div>
                                    <div class="col-sm-1 olinho nopadding mb-1"
                                        align="center"
                                        title="Visualizar manutenção"
                                        event="click"
                                        @click="redirecionaManutencao(dados)"> 
                                        <baseIcon 
                                            size='17 ' 
                                            :icon="mdiEyeOutline"/>
                                    </div>
                                </div>
                                <!-- Atual e programado -->
                                <div class="div-disponivel row">
                                    <span v-if="dados.mcapuracao != 3">
                                        <div class="col-sm-12 nopadding mr-3"
                                        :title="dados.mcapuracao == 1 ?
                                                parseInt(dados.vchodometro/1000) : dados.vchorimetro"> 
                                            <span class="negrito"> Atual:</span>
                                            {{dados.mcapuracao == 1 ?
                                                    parseInt(dados.vchodometro/1000) : dados.vchorimetro}}
                                        </div>
                                    </span>
                                    <div class="nopadding" :class="dados.mcapuracao != 3 ? ('col-sm-7') : ('col-sm-12')"
                                        :title="dados.mcapuracao == 1 ? 
                                            parseInt(dados.mcprogramado/1000) : 
                                            dados.mcprogramado"> 
                                        <span class="negrito"> Programado: </span>
                                            {{dados.mcapuracao == 1 ? 
                                            parseInt(dados.mcprogramado/1000) : 
                                            dados.mcprogramado}}
                                    </div>
                                </div>
                                <!-- Observações -->
                                <div class="div-disponivel negrito"
                                    :title="dados.mcobservacao">
                                    Observação: {{dados.mcobservacao}}
                                </div>
                                <!-- Empresa -->
                                <div class="col-sm-12 nopadding row">
                                    <div class="col-sm-6 nopadding div-disponivel cadastro"
                                        :title="dados.clnome">
                                        {{dados.clnome}}
                                    </div>
                                    <div
									class="col-sm-6 nopadding div-disponivel cadastro"
									align='right'
									:title="dados.dataatualizacao">
                                        Ult. atualização: {{dados.dataatualizacao}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </body>
        </table>
    </div>
</template>
<script>
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { mdiCalendarClock, mdiFilterMenuOutline, 
	mdiEyeOutline, mdiCloseThick, mdiCheckBold } 
	from '@mdi/js'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { mapGetters } from 'vuex'
import Vue from 'vue'
export default Vue.extend({
	name:'controleManutencao',
	components:{
		'baseIcon': require('@/components/Atom/Icon/BaseIcon').default,
		'statusInformation': require('@/components/Atom/StatusInformation/StatusInformation').default,
	},

	data(){
		return{
			link: '/manutencoes/nao/menus/controle/manutencao/',
			mdiEyeOutline:mdiEyeOutline,
			loadingPanel : false,
			drag         : {},
            
		}
	},

	props:{
		dadosKanban:{
			type: [Object, Array],
			required: true,
		},

		checkRealizadas:{
			type: Boolean,
			required: true,
		},

		listaKanban:{
			type: Boolean,
			required: true,
		},

		statusbar:{
			type: String,
			default: 'info'
		}
	},

	methods:{
		...mapGetters(['getMaster','getEditar']),

		/**
		 * @description Função para redirecionar para 
		 *  página de cadastro de manutenção
		 * @author Vitor Hugo 🐨
		 */
		redirecionaManutencao(dados){
            if(!this.getEditar()) return;
			window.open(`#/manutencao/cadastros/manutencao/cadastrar/${dados.mhcodigomanutencao}`, '_blank')
		},
		openModalConfirmar(dados){
            if(!this.getEditar()) return;
			this.$emit('openModalConfirmar', dados)
		},

		onDrag(object){
			this.drag = {}
			this.drag = object
		},

		/**
		 * @listens drop - emitido quando é solto um card na coluna 
		 *  em execução ou programar
		 * @description Função para alterar o status da manutenção 
		 *  quando o card é mudado de kanban
		 * @author Vitor Hugo 🐨
		 */
		onDrop(onde){
            if(!this.getEditar()) return;

			this.$emit('onDrop', this.drag, onde)
		},

		/**
		 * @description Função para funcionar o drop
		 *  sem o dragover o drop na div não funciona
		 * @author Vitor Hugo 🐨
		 */
		dragOver(event){
            if(!this.getEditar()) return;

			event.preventDefault();
		},
	},
})
</script>

<style lang="scss">

</style>