/* eslint-disable */
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex'
/**
 * @param {string} msg mensagem que deve aparecer p/ o usuário.
 * Se não passar nenhum parametro a função mostra uma mensagem
 * padrão
 * @param {string("warning"|"error"|"success"|"info"|"question")} icon 
 * icone que deve aparecer p/ o usuário.
 * Se não passar nenhum parametro a função mostra o ícone padrão
 * https://sweetalert2.github.io/
 * @param {string|false} title - titulo personalizado ou deixar em false
 * para o titulo padrão 
 * @author Gui 
 */
export function conectionError(msg=false, icon='error', title=false){
    var email = ['customer@eagletrack.com.br']
    var telefone = ['(49) 3340-0770' ,'(49) 991525025']
    if(!title){
        title = 'Não foi possível concluir a operação.'
    }
    Swal.fire({
        title : title,
        icon  : icon,
        html  : msg,
        background: '#2A2A2E',
        color:'#FFF',
        // backdrop:` Um dia vc será parte do sistema .-.
        // rgba(0,0,123,0.4)
        // url("https://gizmodo.uol.com.br/wp-content/blogs.dir/8/files/2021/02/nyan-cat-1.gif")
        // left top 
        // no-repeat`,
        footer:`
            <span class="text-center">
                Em caso de dúvida entre em contato <br>
                com o suporte técnico nos números: <br>
                ${telefone.join(', ')} ou <br> no email ${email.join(', ')}.
            </span>`
      })
}
