/**
    Esta é a modal de confirmação de exclusão

    -> Propriedades:
        1- id - String - Codigo do item - EX:'1'
    
    -> Slot:
        Sem slots

    -> Eventos:
        1- confirmaExclusao - Emite quando o usuario aceitar a exclusão - Parametros:
            1.1- id - Código do item passado como propriedade
 */

<template>
	<modalEagle @confirmButton="confirmaExclusao" :title="title" :id="'modal-deleta-' + id">
		<slot slot="modalBody">
			<div class="col-sm-12 nopadding">
				Ao confirmar essa operação não será mais possível revertê-la!
			</div>
			<div class="col-sm-12 nopadding">
				{{ frase }}
			</div>
		</slot>
	</modalEagle>
</template>
<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
	components: {
		'modalEagle': require('../../../components/Atom/Modal/ModalEagle').default,
	},
	props: {
		id: {
			type: [String, Number],
			required: true,
			default: 'Desativar',
		},
		title: {
			type: String,
			default: ' '
		},
		frase: {
			type: String,
			required: false,
			default: 'Tem certeza que deseja fazer isso?'
		}
	},
	methods: {
		confirmaExclusao() {
			this.$emit('confirmaExclusao', this.id);
			this.$bvModal.hide('modal-deleta-' + this.id)
		},
	}
})
</script>

<style lang="scss"></style>