<template>
    <div class="col-sm-12">
        <div class="col-sm-12 justpaddingbottom">
            <div class="col-sm-12">
                {{arrayDados.irnome}}
            </div>
            <selectAll @changeSelect="changeJustificativa" 
            :isMultiple="false"    
            nameForRadio="justificativaModalEncerraRota" 
            :hasSelectAll="false" 
            :selected="justificativaSelecionada"
            :optionsArray="arrayJustificativas">
            </selectAll>
        </div>

    </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
    name:'modalJustificativaEncerrarRotas',
    components:{
        'selectAll': require('../../Select/SelectAll').default,
    },
    props:{
        justificativaSelecionada:{
            type: Array,
            default: function(){return []}
        },
        arrayDados:{
            type: Object,
            required: true,
            default: function(){return {}}
        },
        arrayJustificativas:{
            type: Array,
            required: true,
            default: function(){return []},
        },
    },
    methods:{
        changeJustificativa(justificativa:any){
            this.justificativaSelected = justificativa
            this.$emit('changeSelectJustificativaEncerrarRota', this.justificativaSelected[0])
        },
    },
    data(){
        return{
            justificativaSelected: [] as any,
        }
    }
})
</script>

<style lang="scss">
  
</style>