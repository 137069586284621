/**
    Este é o componente básico com os botões de salvar e cancelar
    -> Propriedades:
        1- linkCancel - String - rota para a listagem do conjunto - EX: 'administrativo/cadastros/usuario'
            1.1- linkCancel - String - nome da rota para onde o cancelar deve levar. Caso passar essa prop, ele não dispara o evento de cancelar
        2- tipoCancela - String - Pra mudar o "tipo" do botão cancela, default ainda é 'a' para não quebrar as páginas antigas

        -----Usando a prop linkCancel de duas maneiras de acordo com a prop tipoCancela ----------------

    -> Slot:
        1- Sem Nome: espaço para colocar algo a mais
    -> Eventos:
        1- cancelarCadastro: Emite quando o botão de cancelar for clicado - Sem parametros a mais
        2- salvarCadastro: Emite quando o botão de salvar for clicado - Sem parametros a mais
 */
<template>
    <div align="right" class="col-sm-12 nopadding">
        <slot></slot>
        <slot name='salvar'>
            <simpleButton v-bind='$attrs' text="Salvar" :icon="iconSave" type="blue" id="btnSalvar" event="salvar" @salvar="salvarClick"></simpleButton>
        </slot>
        <slot name='cancelar' pushTo:pushTo>
            <simpleButton :typeButton="tipoCancela" event='click' :link="linkCancel"  @click="cancelarClick" text="Cancelar"  :icon='iconCancela' type="red" id="btnCancelar"></simpleButton>
        </slot>
    </div>    
</template>
<script lang="ts">
import {mdiCheckBold, mdiCloseThick } from '@mdi/js'
import Vue from 'vue' 
export default Vue.extend({
        inheritAttrs:false,
        components: {
            'simpleButton': require('./SimpleButton').default
        },
        props:{
            linkCancel:{
                type:String,
                default: '',
            },
            tipoCancela:{
                type: String,
                default: 'a' 
            },
        },
        data() {
            return {
                iconSave: mdiCheckBold,
                iconCancela: mdiCloseThick,
            }
        },
        methods: {
            cancelarClick(){
                if(this.linkCancel){
                    this.$router.push({name:this.linkCancel})
                }else{
                    this.$emit('cancelarCadastro');
                }
            },
            salvarClick(){
                this.$emit('salvarCadastro');
            },
        }
})
</script>
<style lang="scss">
</style>