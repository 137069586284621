<template>
    <eaglePanel id='listagemLinha' :loading='loading'>
        <div class="col-sm-12 row">
            <div class="col-sm-3 nopadding">
                <titulo titulo='Linhas' :icon='mdiBusClock' />
            </div>

            <div class="col-sm-9 nopadding divDontPrint">
                <botoesEIN tipoNovo='button' linkNew='cadastrarLinha' @exportarCadastro="exportar"
                    :disabledDropdown='tableData.length === 0 ? true : false' :loading="loadingExportar">
                    <simpleButton v-if="getCadastrar()" width="120px" :icon='mdiPlus' text='Feriado' type='green'
                        event='click' @click="openModalT('cadastroFeriados')" />
                    <simpleButton v-if="getCadastrar()" width="120px" :icon='mdiPlus' text='Calendário' type='green'
                        event='click' @click='openModalT("calendario")' />
                    <simpleButton v-if="getCadastrar()" width="120px" :icon='mdiPlus' text='Categorias' type='green'
                        event='click' @click="openModalT('categorias')" />
                </botoesEIN>
            </div>
        </div>

        <div class="col-sm-12">
            <hr>
        </div>

        <div class="col-sm-12 nopadding row">
            <div class="col-sm-4 nopadding divDontPrint">
                <selectAll :hasSelectAll='true' nameForRadio='seletorEmpresa' :labels='[{ description: "Empresas" }]'
                    :isMultiple='true' :optionsArray='optSelectEmpresa' :selected="selectedEmpresa"
                    @changeSelect='mudaEmpresa' />
            </div>
            <div class="col-sm-4 nopadding divDontPrint">
                <selectAll ref="linhaPrincipal" :labels='[{ description: "Linha principal" }]' nameForRadio='seletorLinhas'
                    :isMultiple='true' :optionsArray='optSelectLinha' @changeSelect='mudaLinha' />
            </div>
            <div class="col-sm-1 nopadding divDontPrint">
                <b-checkbox v-model="seletores.appIntegracao" class='checkBox divDontPrint' @change="mudaCheck">
                    Integração/App
                </b-checkbox>
            </div>
        </div>

        <div class="col-sm-12">
            <hr>
        </div>

        <div class="col-sm-12 nopadding">
            <tableListagem widthSlotLivre="40px" widthAcoes="350px" :data='tableData' :titles='tableTitles'
                :slotLivre='true'>
                <template #slotLivre="{ row }" class='firstColumn'>
                    <b-icon scale="1.7" icon="exclamation-triangle-fill" title='Faltam informações nesta linha'
                        v-if="row.hasError" variant="danger">
                    </b-icon>
                </template>
                <template #acoes='row'>
                    <simpleButton type='blue' title='Copiar linha' :icon='mdiNoteMultiple' event='click'
                        v-if='$store.getters.getCadastrar' @click="copiarLinha(row.row.licodigo)" />
                    <simpleButton type='green' title='Editar linha' :icon='mdiPencil' event='click'
                        v-if='$store.getters.getEditar' @click='editarLinha(row.row.licodigo)' />
                    <simpleButton title='Excluir linha' type='red' :icon='mdiTrashCanOutline' event='click'
                        v-if='$store.getters.getExcluir' @click='excluirLinha(row.row.licodigo)' />
                </template>
            </tableListagem>
        </div>

        <!-- modal de deleção de linha -->
        <modalEagle id='confirmarDelecao' :title='"Deletar " + linha.lidescricao + "?"'
            @confirmButton='confirmaExclusao(linha.licodigo)'>
            <template #modalBody>
                <p>Ao confirmar essa operação não será mais possível revertê-la!<br>
                    Tem certeza que deseja fazer isso? </p>
            </template>
        </modalEagle>

        <!-- modal de deleção de linha -->
        <modalEagle id='confirmarDelecao' :title='"Deletar " + linha.lidescricao + "?"'
            @confirmButton='confirmaExclusao(linha.licodigo)'>
            <template #modalBody>
                <p>Ao confirmar essa operação não será mais possível revertê-la!<br>
                    Tem certeza que deseja fazer isso? </p>
            </template>
        </modalEagle>

        <!-- Modal calendario -->
        <modalEagle id='calendario' title='Calendário' @confirmButton='confirmaExclusao(linha.licodigo)' size='xl'>
            <template #modalBody>
                <b-overlay :show="loading">
                    <div class="col-sm-12 nopadding row">
                        <div class="col-sm-3 nopadding">
                            <selectAll :labels='[{ "description": "Empresa*" }]' nameForRadio='seletorEmpresaCalendario'
                                :isMultiple='false' :optionsArray='optSelectEmpresa'
                                @changeSelect='mudaEmpresaCalendario' />
                        </div>

                        <div class="col-sm-3">
                            <inputSimple name="inputDescricaoModalCalendario" label='Descrição*'
                                @changeInput='value => calendario.licdescricao = value' :value="calendario.licdescricao" />
                        </div>

                        <div class="col-sm-2 nopadding">
                            <inputDate name='inputDayIncioCalendario' label='Inicio*'
                                @changeInput='value => calendario.licdatainicio = value'
                                :value="calendario.licdatainicio" />
                        </div>

                        <div class="col-sm-2 nopadding">
                            <inputDate name='inputDayFimCalendario' label='Fim*'
                                @changeInput='value => calendario.licdatafim = value' :value="calendario.licdatafim" />
                        </div>

                        <div class="col-sm-2 nopadding styleTeste">
                            <template v-if="getCadastrar()">
                                <simpleButton :style="{ marginTop: showAlertEdicaoLinha ? '0' : '17%' }"
                                    :icon='mdiCheckBold' text='Salvar' type='blue' event='click' width='70%'
                                    :disabled="$v.calendario.$invalid" @click="salvaNovoCalendario(edicaoCalendario)" />

                                <div v-show="showAlertEdicaoLinha">
                                    <simpleButton :icon='mdiCloseThick' text='Cancelar' type='red' event='click' width='70%'
                                        @click="cancelarEdicaoCalendario" />
                                </div>
                            </template>
                        </div>

                        <div class="col-sm-12 mt-2">
                            <label>Selecione os dias válidos*</label>
                            <b-check-group :options="optDiasDasemana" v-model="calendario.licdiasdisponivel" />
                        </div>
                    </div>

                    <div class="col-sm-12 nopadding">
                        <div class="styleDivCarAlert" v-show="showAlertEdicaoLinha">
                            <div class="badVisualizar">
                                <h3 class="col-sm-12">Você está editando o calendário.</h3>
                            </div>
                        </div>

                        <tableListagem :data='dataTableCalendarios' :titles='titlesTableCalendarios'>
                            <template #acoes='row' v-if="getExcluir()">
                                <div class="d-flex justify-content-center p-1">
                                    <simpleButton :icon='mdiTrashCanOutline' type='red' event='click'
                                        @click='confirmaExclucaoCalendario(row.row)' />

                                    <simpleButton :icon='mdiPencil' type='blue' event='click'
                                        @click='selecionaItemEdicao(row.row)' />
                                </div>
                            </template>
                        </tableListagem>
                    </div>
                </b-overlay>
            </template>

            <template #modalFooter>
                <simpleButton :icon='mdiCloseThick' text='Fechar' type='red' event='click'
                    @click='closeModalT("calendario")' />
            </template>
        </modalEagle>

        <!-- Modal confirmar Deleção de Calendário -->
        <modalEagle id='confirmarDelecaoCalendario' :title='"Deletar " + calendarioToDestoy.licdescricao + "?"'
            @confirmButton='excluirCalendario(calendarioToDestoy.liccodigo)'>
            <template #modalBody>
                <p>
                    Ao confirmar essa operação não será mais possível revertê-la! <br>
                    Tem certeza que deseja fazer isso?
                </p>
            </template>
        </modalEagle>

        <!-- modal de deleção de categoria -->
        <modalEagle id='confirmarDelecaoCategoria' :title='"Deletar " + catToDestroy.lcdescricao + "?"'
            @confirmButton='excluiCategoria()'>
            <template #modalBody>
                <p>
                    Ao confirmar essa operação não será mais possível revertê-la!<br>
                    Tem certeza que deseja fazer isso?
                </p>
            </template>
        </modalEagle>

        <!-- Modal categorias -->
        <modalEagle id='categorias' title='Categorias'>
            <template #modalBody>
                <b-overlay :show="loading">
                    <div class="col-sm-12 nopadding row">
                        <div class="col-sm-5 nopadding row">
                            <selectAll ref='selectEmpresaCategoria' :labels='[{ "description": "Empresa*" }]'
                                :isMultiple='false' nameForRadio='empresaCategorias' :optionsArray='optSelectEmpresa'
                                @changeSelect='mudaEmpresaCategoria' />
                        </div>
                        <div class="col-sm-5 nopadding">
                            <inputSimple name="inputDescricaoModalCategorias" label='Descrição*'
                                :value='categoria.lcdescricao' @changeInput='value => categoria.lcdescricao = value' />
                        </div>
                        <div class="col-sm-2 nopadding row">
                            <template v-if="getCadastrar()">
                                <simpleButton :disabled='$v.categoria.$invalid' type='blue' extraClass='buttonModal'
                                    text='Salvar' :icon='mdiCheckBold' event='click' @click='adicionarCategoria' />
                            </template>
                        </div>
                        <div class="col-sm-12 nopadding">
                            <tableListagem :titles='tableCategoriasTitles' :data='tableCategoriaData' :filter='false'>
                                <template #acoes='{ row }' v-if="getExcluir()">
                                    <simpleButton type='red' text='' :icon='mdiTrashCanOutline' event='click'
                                        @click='confirmaExclucao(row)' />
                                </template>
                            </tableListagem>
                        </div>
                    </div>
                </b-overlay>
            </template>
            <template #modalFooter>
                <simpleButton :icon='mdiCloseThick' text='Fechar' type='red' event='click'
                    @click='closeModalT("categorias")' />
            </template>
        </modalEagle>

        <!-- Modal de cadastro de feriados -->
        <modalEagle id='cadastroFeriados' title='Feriados'>
            <template #modalBody>
                <b-overlay :show="loading">
                    <slideUD>
                        <template #filters>
                            <div class="col-sm-12 nopadding row">
                                <div class="col-sm-4 nopadding">
                                    <selectAll nameForRadio='seletorEmpresaFeriado'
                                        :labels='[{ "description": "Empresa*" }]' :isMultiple='false'
                                        :optionsArray='optSelectEmpresa' @changeSelect='mudaEmpresaCalendarioFeriados' />
                                </div>
                                <div class="col-sm-4 nopadding">
                                    <inputSimple name="inputDescricaoModalFeriados" label='Descrição*'
                                        :value='feriado.fcdescricao' @changeInput='value => feriado.fcdescricao = value' />
                                </div>
                                <div class="col-sm-4 nopadding">
                                    <inputDay name='pedro' label='Data*' @changeInput='value => feriado.fcdata = value' />
                                </div>
                            </div>
                            <div class="col-sm-12 nopadding row mt-2">
                                <div class="col-sm-4 nopadding row">
                                    <selectAll nameForRadio='seletorCalendarioOriginal' ref='seletorCalendarioOriginal'
                                        :labels='[{ "description": "Calendário Original*" }]' :isMultiple='false'
                                        :disabled='!optSelectCalendario.length' :loading='carregandoC'
                                        :optionsArray='optSelectCalendario' @changeSelect='mudaCalendarioOriginal' />
                                </div>
                                <div class="col-sm-4 nopadding">
                                    <selectAll nameForRadio='seletorCalendarioSub' ref='seletorCalendarioSub' :labels='[{
                                        description: "Calendário Substituto*"
                                    }]' :isMultiple='false' :loading='carregandoC'
                                        :disabled='!optSelectCalendario.length' :optionsArray='optSelectCalendario'
                                        @changeSelect='mudaCalendarioSubstituto' />
                                </div>
                                <div class="col-sm-4" style="margin-top:3.4%">
                                    <template v-if="getCadastrar()">
                                        <simpleButton type='blue' text='Salvar' :icon='mdiCheckBold' event='click'
                                            width='100%' @click='cadastrarFeriado' :disabled='$v.feriado.$invalid' />
                                    </template>
                                </div>
                            </div>
                        </template>
                    </slideUD>
                    <div class="col-sm-12 nopadding">
                        <tableListagem :data='dataFeriados' :titles='titulosTableFeriado' :filter='false'>
                            <template #acoes='{ row }'>
                                <simpleButton v-show='$store.getters.getExcluir' title='Excluir' type='red'
                                    :icon='mdiTrashCanOutline' event='click' @click='confirmaExclusaoFeriado(row)' />
                            </template>
                        </tableListagem>
                    </div>
                </b-overlay>
            </template>
            <template #modalFooter>
                <simpleButton type='red' text='Fechar' event='click' :icon='mdiCloseThick'
                    @click="closeModalT('cadastroFeriados')" />
            </template>
        </modalEagle>

        <!-- Modal de deleção de feriados -->
        <modalEagle id='confirmarDelecaoFeriado' :title='"Deletar " + holydayToDestroy.fcdescricao + "?"'
            @confirmButton='excluirferiado(holydayToDestroy.fccodigo)'>
            <template #modalBody>
                <p>
                    Ao confirmar essa operação não será mais possível revertê-la!<br>
                    Tem certeza que deseja fazer isso?
                </p>
            </template>
        </modalEagle>

        <!-- Modal de confirmar deletar calendário com linha associada -->
        <modalEagle id='calendarioTemLinhaAssociada' :title='"Deletar " + calendarioToDestoy.licdescricao + "?"'
            @confirmButton='excluirCalendarioLinhaAssociada()'>
            <template #modalBody>
                <p>
                    Calendário tem linhas associadas, deseja apagar mesmo assim?
                </p>
            </template>
        </modalEagle>
    </eaglePanel>
</template>
<script>
import {
    mdiPlus,
    mdiAlert,
    mdiPencil,
    mdiBusClock,
    mdiCheckBold,
    mdiCloseThick,
    mdiNoteMultiple,
    mdiTrashCanOutline
} from '@mdi/js'
import { required } from 'vuelidate/lib/validators'
import { listagem } from './helpers'
import selectAll from '@/components/Atom/Select/SelectAll'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { linha } from './helpers'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { mapGetters } from 'vuex'
import { conectionError } from '@/Services/Helpers/swellHeper'

export default {
    name: 'listagemLinhas',

    components: {
        inputSimple: require('@/components/Atom/Inputs/InputSimple').default,
        inputDay: require('@/components/Atom/Datas/InputSingleDay').default,
        modalEagle: require('@/components/Atom/Modal/ModalEagle').default,
        simpleButton: require('@/components/Atom/Buttons/SimpleButton').default,
        botoesEIN: require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
        eaglePanel: require('@/components/Atom/Panel/PanelEagle').default,
        tableListagem: require('@/components/Atom/Table/TableListagem').default,
        titulo: require('@/components/Atom/Header/Titulo').default,
        inputDate: require('@/components/Atom/Datas/InputSingleDay').default,
        slideUD: require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
        selectAll
    },

    validations: {
        calendario: {
            licdescricao: { required },
            licdatainicio: { required },
            licdatafim: { required },
            liccliente: { required },
            licdiasdisponivel: { required }
        },
        categoria: {
            lccliente: { required },
            lcdescricao: { required }
        },
        feriado: {
            fcdata: { required },
            fcdescricao: { required },
            fccliente: { required },
            fccalendariooriginal: { required },
            fccalendariosubstituto: { required }
        }
    },

    data() {
        return {
            mdiPlus: mdiPlus,
            mdiAlert: mdiAlert,
            mdiPencil: mdiPencil,
            mdiBusClock: mdiBusClock,
            mdiCheckBold: mdiCheckBold,
            mdiCloseThick: mdiCloseThick,
            mdiNoteMultiple: mdiNoteMultiple,
            mdiTrashCanOutline: mdiTrashCanOutline,
            tableCategoriaData: [],
            categoria: {
                lccliente: '',
                lcdescricao: ''
            },
            catToDestroy: {
                lcdescricao: '',
                lccodigo: ''
            },
            ...listagem,
            loading: false,
            seletores: {
                clientes: [],
                appIntegracao: true,
                linhasPrincipais: []
            },
            loadingExportar: [false, false, false],
            dataFeriados: [],
            linha: linha,
            baseUri: '/coletivos/cadastros/linhas/',
            optSelectEmpresa: new EmpresasService().Get(),
            selectedEmpresa: [],
            optSelectLinha: [],
            carregandoC: false,
            //Controles da tabela
            tableData: [],
            dataTableCalendarios: [],
            tableTitles: [
                { number: 'lidescricao', name: 'Descrição' },
                { number: 'clfantasia', name: 'Empresa' },
            ],
            holydayToDestroy: Object,
            calendarioToDestoy: Object,
            showAlertEdicaoLinha: false,
            edicaoCalendario: false,
            calendarioEdicao: [],
        }
    },

    methods: {
        ...mapGetters(['getMaster', 'getCadastrar', 'getExcluir']),

        mudaCalendarioSubstituto(arr, value, description) {
            this.feriado.fccalendariosubstituto = value
        },

        mudaCalendarioOriginal(arr, value, description) {
            this.feriado.fccalendariooriginal = value
        },

        confirmaExclusaoFeriado(row) {
            this.loading = true
            this.holydayToDestroy = row
            this.loading = false
            this.$bvModal.show('confirmarDelecaoFeriado')
        },

        excluirferiado(codigo) {
            this.$bvModal.hide('confirmarDelecaoFeriado')
            let uri = this.baseUri + 'feriados/excluir'
            new HttpRequest().Post(uri, { 'feriado': codigo })
                .then(data => { this.listarFeriados() })
        },

        async cadastrarFeriado() {
            this.loading = true
            let uri = this.baseUri + 'feriados/cadastrar'
            var dados = await new HttpRequest().Post(uri, { 'feriado': this.feriado })
            this.listarFeriados()
            this.$nextTick(function () {
                this.$refs.seletorCalendarioOriginal.clearAll()
                this.$refs.seletorCalendarioSub.clearAll()
                this.feriado.fcdescricao = ''
            })
            this.loading = false
        },

        openModalT(id) {
            this.dataTableCalendarios = []
            this.dataFeriados = []
            this.tableCategoriaData = []
            this.calendario.licdiasdisponivel = []
            this.$bvModal.show(id)

            if (id == 'calendario') {
                this.cancelarEdicaoCalendario();
            }
        },

        closeModalT(id) {
            this.dataTableCalendarios = []
            this.$bvModal.hide(id)
            if (id == 'calendario') {
                this.cancelarEdicaoCalendario();
            }
        },

        mudaEmpresaCalendario(arr, value, description) {
            this.calendario.liccliente = value
            this.getCalendarios()
        },

        /**
         * Função recebe os dados do calendário, criar um OBJ e manda para o back-end
         * salvar ou aualizar caso tenha liccodigo.
         * @param {novo} novo array com informações do calendário para salvar
         * @autor Desconhecido
         */
        salvaNovoCalendario(novo) {
            this.loading = true

            var obj = {
                calendario: {
                    ...this.calendario,
                    'licdiasdisponivel': this.decideArrayDias(),
                    'liccodigo': novo ? (this.calendarioEdicao.liccodigo) : (false),
                }
            }

            let uri = this.baseUri + 'calendarios/salvar'
            new HttpRequest().Post(uri, obj).then(data => {
                if (data.status)
                    this.getCalendarios()
            })

            this.edicaoCalendario = false;
            this.showAlertEdicaoLinha = false;
            this.loading = false
        },

        decideArrayDias() {
            let y = [0, 0, 0, 0, 0, 0, 0]
            if (this.calendario.licdiasdisponivel.includes('0'))
                y[0] = 1
            if (this.calendario.licdiasdisponivel.includes('1'))
                y[1] = 1
            if (this.calendario.licdiasdisponivel.includes('2'))
                y[2] = 1
            if (this.calendario.licdiasdisponivel.includes('3'))
                y[3] = 1
            if (this.calendario.licdiasdisponivel.includes('4'))
                y[4] = 1
            if (this.calendario.licdiasdisponivel.includes('5'))
                y[5] = 1
            if (this.calendario.licdiasdisponivel.includes('6'))
                y[6] = 1
            return y.toString()
        },

        confirmaExclucaoCalendario(data) {
            this.calendarioToDestoy = data;
            this.$bvModal.show('confirmarDelecaoCalendario');
        },

        /**
         * Função para carregar os dados do calendário selecionado para edição
         * ativa o aviso que está realizando uma edição no calendário.
         * @param {data} data array com informações do calendário.
         * @author Yuri 🇧🇷
         */
        selecionaItemEdicao(data) {
            let diasSeparados = data.licdiasdisponivel.split(',');

            diasSeparados = diasSeparados.map((item) => {
                return item.replace(/\s/g, '');
            });

            const diasSelecionadosEdicao = this.optDiasDasemana.filter(item => diasSeparados.includes(item.text));

            const numerosDiasSemana = diasSelecionadosEdicao.map((item) => {
                return item.value;
            });

            this.calendarioEdicao = data;
            this.calendario.licdescricao = data.licdescricao;
            this.calendario.licdatainicio = data.licdatainicio;
            this.calendario.licdatafim = data.licdatafim;
            this.calendario.licdiasdisponivel = numerosDiasSemana;

            this.showAlertEdicaoLinha = true;
            this.edicaoCalendario = true;
        },

        /**
         * Função para limpar os dados dos inputs no modal de calendário
         * @author Yuri 🇧🇷
         */
        cancelarEdicaoCalendario() {
            this.showAlertEdicaoLinha = false;
            this.edicaoCalendario = false;
            this.calendario.licdescricao = '';
            this.calendario.licdatainicio = '';
            this.calendario.licdatafim = '';
            this.calendario.licdiasdisponivel = [];
        },

        excluirCalendario() {
            this.loading = true;
            let uri = this.baseUri + 'calendarios/deletar';
            new HttpRequest()
                .Post(uri, {
                    codigo: this.calendarioToDestoy.liccodigo
                }).then((data) => {
                    if (data.status) {
                        this.$nextTick(() => {
                            this.$bvModal.hide('confirmarDelecaoCalendario');
                            this.getCalendarios();
                        })
                    } else {
                        this.$bvModal.show('calendarioTemLinhaAssociada');
                    }
                }).catch(() => {
                    conectionError();
                }).finally(() => { this.loading = false });
        },

        excluirCalendarioLinhaAssociada() {
            this.loading = true;
            let uri = this.baseUri + 'calendarios/deletarLinhaAssociada';
            new HttpRequest()
                .Post(uri, {
                    codigo: this.calendarioToDestoy.liccodigo
                }).then((data) => {
                    if (data.status) {
                        this.$nextTick(() => {
                            this.$bvModal.hide('confirmarDelecaoCalendario');
                            this.$bvModal.hide('calendarioTemLinhaAssociada');
                            this.getCalendarios();
                        })
                    }
                }).catch(() => {
                    conectionError();
                }).finally(() => { this.loading = false });
        },

        getCalendarios() {
            this.loading = true
            let uri = this.baseUri + 'calendarios/listar'
            let obj = { cliente: this.calendario.liccliente }
            new HttpRequest().Post(uri, obj)
                .then((data) => {
                    if (data.status) {
                        this.dataTableCalendarios = data.data.calendarios
                        this.calendariosExibir()
                    }
                }).catch(() => {
                    conectionError()
                }).finally(() => {
                    this.edicaoCalendario = false;
                    this.showAlertEdicaoLinha = false;
                    this.cancelarEdicaoCalendario();
                    this.loading = false;
                })
        },

        adicionarCategoria() {
            this.loading = true
            let uri = this.baseUri + 'categorias/nova'
            new HttpRequest().Post(uri, { 'categoria': this.categoria }).then(() => {
                this.categoria.lcdescricao = ''
                this.procuraCategorias()
            })
            this.loading = false
        },

        confirmaExclucao(row) {
            this.catToDestroy = row
            this.$bvModal.show('confirmarDelecaoCategoria')
        },

        excluiCategoria() {
            let uri = this.baseUri + 'categorias/excluir'
            new HttpRequest().Post(uri, { 'categoria': this.catToDestroy.lccodigo })
                .then(() => {
                    this.procuraCategorias()
                    this.$bvModal.hide('confirmarDelecaoCategoria')
                })
        },

        mudaEmpresaCategoria(arr, value, description) {
            this.categoria.lccliente = value
            this.procuraCategorias();
        },

        procuraCategorias() {
            let uri = this.baseUri + 'categorias/listar'
            this.loading = true
            new HttpRequest().Post(uri, { 'cliente': this.categoria.lccliente })
                .then(data => {
                    if (data.status)
                        this.tableCategoriaData = data.data.categorias
                    this.loading = false
                })
        },

        mudaCheck() {
            this.$nextTick(() => {
                this.buscaLinhas()
            })
        },

        mudaEmpresaCalendarioFeriados(arr, value, description) {
            this.loading = true
            this.feriado.fccliente = value
            this.listarFeriados()
            this.loading = false
        },

        listarFeriados() {
            let uri = this.baseUri + 'feriados/listar'
            this.carregandoC = true
            new HttpRequest()
                .Post(uri, { 'cliente': this.feriado.fccliente })
                .then(data => {
                    this.carregandoC = false
                    this.dataFeriados = data.data.feriados
                    this.optSelectCalendario = data.data.calendarios
                })
        },

        mudaEmpresa(arr) {
            this.tableData = [];
            this.optSelectLinha = [];
            this.seletores.clientes = [];
            this.seletores.linhasPrincipais = [];
            if (arr.length > 0)
                this.seletores.clientes = arr;
            this.buscaLinhasPrincipais();
            this.buscaLinhas();
            this.$refs.linhaPrincipal.clearAll();
        },

        mudaLinha(arr) {
            this.tableData = [];
            this.seletores.linhasPrincipais = arr;
            this.buscaLinhas();
        },

        copiarLinha(codigo) {
            this.$router.push({ name: 'cadastrarLinha', params: { id: codigo, cp: 'cp' } })
        },

        editarLinha(codigo) {
            this.$router.push({ name: 'cadastrarLinha', params: { id: codigo } })
        },

        excluirLinha(codigo) {
            var reg = this.tableData.filter(el => {
                return el.licodigo === codigo
            })
            this.linha = reg[0]
            this.openModalT('confirmarDelecao')
        },

        confirmaExclusao(value) {
            let uri = this.baseUri + 'deletar'
            this.closeModalT('confirmarDelecao')
            new HttpRequest().Post(uri, { 'id': value }).then(() => {
                this.buscaLinhas()
            })
        },

        buscaLinhasPrincipais() {
            new FiltrosService()
                .dados_filtros(this.seletores.clientes, ['LP'])
                .then(data => {
                    this.optSelectLinha = data.LP
                })
        },

        buscaLinhas() {
            if (this.seletores.clientes.length > 0) {
                this.mudaLoading()
                let uri = this.baseUri + 'listagem'
                new HttpRequest().Post(uri, this.obj)
                    .then(data => {
                        this.mudaLoading()
                        if (data.status) {
                            if (data?.data) {
                                this.tableData = data.data.linhas
                            } else
                                conectionError()
                        }
                    })
            }
        },

        mudaLoading() {
            this.loading = !this.loading
        },

        decideDias(dias) {
            var arrayDias = dias.split(',')
            let diasExibir = []
            if (arrayDias[0] === "1")
                diasExibir.push('Dom')
            if (arrayDias[1] === "1")
                diasExibir.push(' Seg')
            if (arrayDias[2] === "1")
                diasExibir.push(' Ter')
            if (arrayDias[3] === "1")
                diasExibir.push(' Qua')
            if (arrayDias[4] === "1")
                diasExibir.push(' Qui')
            if (arrayDias[5] === "1")
                diasExibir.push(' Sex')
            if (arrayDias[6] === "1")
                diasExibir.push(' Sab')
            return diasExibir.toString().trim()
        },

        async exportar(type) {
            if (this.tableData.length > 0) {
                switch (type) {
                    case 'pdf':
                        this.loadingExportar = [true, false, false]
                        break;
                    case 'xls':
                        this.loadingExportar = [false, true, false]
                        break;
                    case 'csv':
                        this.loadingExportar = [false, false, true]
                        break;
                    default:
                        break;
                }
                var root = process.env.VUE_APP_ROOT;
                let uri = this.baseUri + 'exportar'
                var dados = await new HttpRequest().Post(uri, {
                    'tipo': type, 'dados': this.tableData
                })
                window.open(root + '/' + dados.data.dados)
            }
            this.loadingExportar = [false, false, false]
        },

        calendariosExibir() {
            var aux = this.dataTableCalendarios.map(cal => {
                return {
                    ...cal,
                    'licdatainicio': cal.licdatainicio,
                    'licdatafim': cal.licdatafim,
                    'licdiasdisponivel': this.decideDias(cal.licdiasdisponivel)
                }
            })
            this.dataTableCalendarios = aux
        },
    },

    mounted() {
        if (!this.getMaster()) {
            this.selectedEmpresa = this.optSelectEmpresa
        }
    },

    computed: {
        obj() {
            var obj
            if (this.seletores.linhasPrincipais.length > 0) {
                obj = {
                    ...this.seletores
                }
            } else {
                obj = {
                    clientes: this.seletores.clientes,
                    appIntegracao: this.seletores.appIntegracao
                }
            }
            return obj
        },
    },
}
</script>

<style>
.styleDivCarAlert {
    width: 100%;
    height: 100%;
    margin: auto;
    position: absolute;
    z-index: 270;
    background-color: rgba(218, 218, 218, 0.219);
}

.badVisualizar {
    width: 400px;
    height: 60px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.582);
    color: white;
    z-index: 270;
    margin-top: 100px;
}

.styleTeste {
    text-align: center;
}
</style>

<style lang="sass" scoped>
  .checkBox
    margin-top: 35%
  span[class~='error']
    color: red
  .buttonModal
    margin-top: 23%
    max-height: 50%
</style>
