<template>
    <eaglePanel id='cartoesRFID' :loading='gerandoRel'>
        <div class="col-sm-12 nopadding ">
            <div class="col-md-12 row">
                <div class="col-md-4 nopadding">
                    <titulo
                    titulo='Gestão de cartões RFID'
                    :icon='mdiCardAccountDetails'/>
                </div>
                <div class="col-md-8 divDontPrint nopadding">
                    <botoes
                        tipoNovo='button'
                        :loading='loading'
                        linkNew='cadastrarCartaoRFID'
                        @exportarCadastro='exportar'
                        :disabledDropdown='dataCartoes.length == 0 ?true:false'
                    >
                        <slot  id='aparece'>
                            <simpleB 
                                text="Tipo" 
                                :icon="mdiPlus" 
                                type="green" 
                                id="btnSalvar" 
                                event='click'
                                @click='novoTipo'
                                v-if='apacere'
                            />
                            <!-- nao da pra usar o ?? por algum motivo -->
                            <simpleB
                                :title="
                                    !valueEmpresa.length          ?
                                    'selecione uma empresa antes' :
                                    ''
                                "
                                :disabled="!valueEmpresa.length"
                                width="120px"
                                text="Permissões" 
                                :icon="mdiPlus" 
                                type="green" 
                                id="" 
                                event='click'
                                @click="() => { 
                                    $bvModal.show('permissao-veiculo') 
                                }"
                            />
                            <!-- v-if='apacere' -->
                        </slot>
                    </botoes>
                </div>
            </div>
            <slideUandD>
                <div class="col-sm-12"><hr></div>
                <div class="col-md-12 row nopadding">    
                    <div class="col-md-4 nopadding mb-2 nopadding">
                        <selectAll
                        nameForRadio='seletorEmpresa'
                        :isMultiple='true'
                        :hasSelectAll='true'
                        :selected='firstOne'
                        :labels='labelFiltroEmpresas'
                        :optionsArray='optEmpresas'
                        @changeSelect='mudaSeletorEmpresa'/>
                    </div>
                    <div class="col-md-4 nopadding mb-2 nopadding">
                        <selectAll
                        nameForRadio='seletorColaborador'
                        :isMultiple='true'
                        :hasSelectAll='true'
                        :labels='labelFiltroColaboradores'
                        :optionsArray='optSelectTrabalhador'
                        :disabled='valueEmpresa.length == 0 ? true : false'
                        ref='selectColaborador'
                        @changeSelect='changeTrabalhador'/>
                    </div>
                    <div class="col-4 nopadding">
                        <input
                        type='text'
                        v-model='buscaUsuario'
                        placeholder="Filtrar resultados"
                        class='form-control mt-4'>
                    </div>
                </div>
            </slideUandD>
            <div class="col-sm-12"><hr></div>
        </div>
        <div class="col-sm-12">
            <tableD>
                <thead>
                    <br>
                    <tr>
                        <th>Nº Cartão</th>
                        <th>Tipo de Cartão</th>
                        <th style="width:300px">Associado a</th>
                        <th>Ajudante</th>
                        <th>Empresa</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody v-if='mostrarRel'>
                    <template v-for="(cartao, index) in dadosRelatorio">
                        <tr :key="index+'__cartaoEdicao'" 
                        v-if='cartao.edit'
                        class='linhaTabela'>
                            <td class='exibir' v-text="cartao.cccartao"/>
                            <td>
                                <select v-model='cartao.ctcodigo'>
                                    <option 
                                    v-for='tipo in optSelectTipoCartao'
                                    :key='tipo.value'
                                    v-text="tipo.description"
                                    :value='tipo.value'/>
                                </select>
                            </td>
                            <td>
                                <select v-model='cartao.mtcodigo'>
                                    <option 
                                    v-for='tra in optSelectTrabalhador'
                                    :key="tra.value+'__selectOpt'"
                                    :value='tra.value'
                                    v-text='tra.description'
                                    :selected='tra.description==cartao.mtnome'/>
                                </select>
                            </td>
                            <td>
                                <select v-model='cartao.ccajudante'>
                                    <option
                                    value='Sim' 
                                    label='Sim'/>
                                    <option
                                    value='Não'
                                    label='Não'/>
                                </select>
                            </td>
                            <td>
                                <select v-model='cartao.clcodigo'>
                                    <option 
                                    v-for="emp in optEmpresas"
                                    :key='emp.value+"__empresas"'
                                    :value='emp.value'  
                                    v-text='emp.description'/>
                                </select>
                            </td>
                            <td>
                                <div class="row actions">
                                    <buttonL
                                    v-if="getEditar()"
                                    :icon='mdiCheckBold'
                                    type='blue'
                                    width='45%'
                                    event='click'
                                    @click='salvarEdicao(cartao.cccartao)'
                                    :isLoading='salvandoEd'/>
                                    <simpleB
                                    v-if="getExcluir()"
                                    :icon='mdiCloseThick'
                                    type='red'
                                    width='45%'
                                    event='click'
                                    @click="cancelarEdicao(cartao.cccartao)"/>
                                </div>
                            </td>
                        </tr>
                        <tr :key="index+'__cartao_RFID'" 
                            v-else 
                            class='linhaTabela'>
                            <td class='exibir' v-text="cartao.cccartao"/>
                            <td class='exibir' v-text="cartao.ctdescricao"/>
                            <td class='exibir' v-text="cartao.mtnome"/>
                            <td class='exibir' v-text="cartao.ccajudante"/>
                            <td class='exibir' v-text="cartao.clfantasia"/>
                            <td >
                                <div class="row actions">
                                    <simpleB
                                    v-if="getEditar()"
                                    :icon='mdiPencil'
                                    type='green'
                                    width='45%'
                                    event='click'
                                    title='Editar cartão'
                                    @click='editarLinha(cartao.cccartao)'/>
                                    <simpleB
                                    v-if="getExcluir()"
                                    :icon='mdiTrashCanOutline'
                                    type='red'
                                    width='45%'
                                    event='click'
                                    title='Excluir cartão'
                                    @click="excluirCartao(cartao.cccartao)"/>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tbody v-else>
                    <status 
                    :statusBar='infoRel'
                    colspanForTd='6'/>
                </tbody>
            </tableD>
        </div>
        <modalEagle id='novoTipo' title='Tipos de cartão'>
            <slot slot='modalBody'>
                <b-overlay :show='loadingTipoCartao'>
                    <div class="col-sm-12 row nopadding">
                        <div class="col-md-4 col-sm-6 nopadding">
                            <selectAll
                                nameForRadio='yetAnotherCompanySelector'
                                :isMultiple='false'
                                :hasSelectall='false'
                                :extraClass='{
                                    "border border-danger":
                                    $v.valueSeletorModal.$anyError
                                }'
                                :labels='labelFiltroEmpresas'
                                :optionsArray='optEmpresas'
                                @close='$v.valueSeletorModal.$touch()'
                                @changeSelect='mudaSeletorModal'
                            /> 
                                <!-- valueSeletorModal -->
                        </div>
                        <div class="col-md-6 col-sm-10 nopadding">
                            <simpleI
                                name='descricaoTipoCartao'
                                label='Descrição*'
                                :inputClass='{
                                    "border border-danger": $v.valueDescricao
                                        .$anyError
                                }'
                                @blur='$v.valueDescricao.$touch()'
                                @changeInput='mudaDescricaoModal'
                            />
                        </div>
                        <div class="col-md-2 col-sm-12 almostnopadding mb-3">
                            <simpleB
                                :disabled='$v.$invalid'
                                text='Salvar'
                                type='blue'
                                :icon='mdiCheckBold'
                                event='click'
                                @click='salvaNovoCartao'
                            />
                        </div>
                        <div class="col-sm-12 nopadding">
                            <span class='erroExclusao' v-text="msg"></span>
                            <tableL
                            :filter='false'
                            deleteEdit='delete'
                            :data='dataTiposDeCartao'
                            :titles='titulosTableTipos'
                            :optSeletor='optEmpresas'
                            @excluirCadastro='excluirTipoCartao'>
                                <template #acoes='{row}'>
                                    <simpleB
                                    type='red'
                                    :icon='mdiTrashCanOutline'
                                    event='click'
                                    @click='excluirTipoCartao(row.codigo)'/>
                                </template>
                            </tableL>
                        </div>
                    </div>
                </b-overlay>
            </slot>
            <slot slot='modalFooter'>
                <div class="col-sm-12 row nopadding">
                    <div class="col-sm-10"></div>
                    <div class="col-sm-2">
                        <simpleB 
                        :icon="mdiCheckBold" 
                        @click="confirmButton" 
                        text="Confirmar" 
                        event="click" 
                        type="blue"/>
                    </div>
                </div>
            </slot>
        </modalEagle>
        <modalEagle 
            id='deletarRFID' 
            title='Confirmação Necessaria'
            @confirmButton='excluirCartaoConfirmado(codExcluir)'
            @cancelButton='cancelarExclusao'>
            <template #modalBody>
                <p>Ao confirmar essa operação não será mais possível revertê-la!
                    Tem certeza que deseja fazer isso?</p>
            </template>
        </modalEagle>
        <modalPermissoes
            :empresaSelecionada="valueEmpresa"
            :veiculos="optVeiculos"
        />
    </eaglePanel>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { 
	mdiCardAccountDetails,
	mdiPlus,
	mdiCloseThick,
	mdiCheckBold,
	mdiPencil,
	mdiTrashCanOutline 
} from '@mdi/js'
import { conectionError } from '@/Services/Helpers/swellHeper'
import botoes from '@/components/Atom/Buttons/BasicButtonsCadastrosEIN'
import tableL from '@/components/Atom/Table/TableListagem'
import simpleB from '@/components/Atom/Buttons/SimpleButton'
import slideUandD from '@/components/Atom/SlideUpAndDown/SlideUpAndDown'
import buttonL from '@/components/Atom/Buttons/LoadingButton'
import status from '@/components/Atom/StatusInformation/StatusInformation'
export default Vue.extend({
	name: 'CartoesRFID',
	components:{ 
		eaglePanel:require('@/components/Atom/Panel/PanelEagle').default,
		titulo:require('@/components/Atom/Header/Titulo').default,
		modalEagle:require('@/components/Atom/Modal/ModalEagle').default,
		simpleI:require('@/components/Atom/Inputs/InputSimple').default,
		tableD:require('@/components/Atom/Table/TableSimples').default,
		selectAll:require('@/components/Atom/Select/SelectAll').default,
		modalPermissoes:require('@/components/Atom/ModalSpecific/ModalPermissoesGestaoDeCartoesRfid').default,
		botoes, tableL, simpleB, slideUandD, buttonL, status,
	},

	validations:{
		valueDescricao: { required },
		valueSeletorModal : { required },
         
	},

	data(){
		return {
			mdiTrashCanOutline:mdiTrashCanOutline,
			mdiPencil:mdiPencil,
			mdiCheckBold:mdiCheckBold,
			mdiCloseThick:mdiCloseThick,
			mdiPlus:mdiPlus,
			mdiCardAccountDetails:mdiCardAccountDetails,
			// opt
			optEmpresas : new EmpresasService().Get(),
			// labels
			labelFiltroEmpresas:[
				{ indexDFH : 'EM', description : 'Empresas*' }
			],
			labelFiltroColaboradores:[
				{ indexDFH : 'C', description : 'Colaborador' }
			],
			titulosTable:[
				{'name': 'N° Cartão',      'number': 'cccartao'   },
				{'name': 'Tipo De Cartão', 'number': 'ctdescricao'},
				{'name': 'Associado à',    'number': 'mtnome'     },
				{'name': 'Ajudante',       'number': 'ccajudante' },
				{'name': 'Empresa',        'number': 'clfantasia' }
			],
			titulosTableTipos:[
				{'name': 'Descrição',  'number': 'ctdescricao' },
				{'name': 'Empresa',    'number': 'clfantasia'  }
			],
			// data
			dataTiposDeCartao : [],
			firstOne:[],
			dataCartoes       : [],
			valueEmpresa      : [],
			valueSeletorModal : '',
			valueDescricao    : '',
			msg: '',
			colaboradorSelecionado : [],
			optVeiculos:[],
			// links
			urlBase: '/administrativo/gestao/cartoes/rfid/',
			// errros
			errorInputDescricao  : '',
			errorSeletorModal    : '',
			infoRel              : 'info',
			apacere              : false,
			loading              : Array(false, false, false),
			salvandoEd           : false,
			codExcluir           : '',
			optSelectTipoCartao  : [],
			optSelectTrabalhador : [],
			gerandoRel: false,
			loadingTipoCartao:false,
			buscaUsuario:''
		}
	},

	methods:{

		/**
         * @description A tabela usa o campo edit pra controlar 
         * se a linha esta sendo editada ou não 
         * */
		editarLinha(codigo){
			let i = this.IndexCartao(codigo)
			this.dataCartoes[i].edit = true
			this.$forceUpdate()
		},

		/**
         * @param {number} codigo
         * @description envia as informações novas
         * do cartão para o back-end salvar.
         */
		salvarEdicao(codigo){
			this.salvandoEd = true
			let uri = this.urlBase+'attcartao'
			let cartao = this.findCartao(codigo)
			let obj = {
				cartao: codigo,
				tipo: cartao.ctcodigo,
				associado: cartao.mtcodigo,
				cliente: cartao.clcodigo,
				ajudante: cartao.ccajudante === 'Sim' 
			}
			new HttpRequest()
				.Post(uri, obj).then((data)=>{
					if(data.data.status === 200){
						var cartao = this.findCartao(codigo)
						this.subInfoCartao(cartao, data.data.cartao)
						this.getCartoes()
					}else if(data.data.status === 500){
						this.cancelarEdicao(codigo)
						conectionError(data.data.message, 'warning')
					}
				}).finally(()=>{
					this.salvandoEd = false
				})
		},

		/**
         * @param {object} cartao - a ser alterado
         * @param {object} nInfo  - novos dados do cartão
         */
		subInfoCartao(cartao, nInfo){
			Object.keys(nInfo).forEach(k=>{
				cartao[k] = nInfo[k]
			})
			cartao.edit = false
		},

		/**
         * @deprecated não utilizar.
         * agora as funções que precisam mudar algum cartão RFID
         * fazem isso com find, alterando o objeto diretamente.
         */
		IndexCartao(codigo){
			return this.dataCartoes.findIndex(c=> c.cccartao === codigo)
		},

		/**
         * @param {number} codigo 
         * @return {object} cartão RFID
         */
		findCartao(codigo){
			return this.dataCartoes.find((c)=> c.cccartao === codigo)
		},

		/**
         * @param {number} codigo
         * @description procura pelas informações originais do cartão
         * para garantir que as informações sendo exibidas estão corretas.
         * @author Gui 🍺
         */
		cancelarEdicao(codigo){
			let uri = this.urlBase+'recuperacartao'
			new HttpRequest().Post(uri, {codigo:codigo}).then(data=>{
				var cartao = this.dataCartoes.find(c=>{
					return c.cccartao === codigo
				})
				Object.keys(cartao).forEach(k=>{
					cartao[k] = data.data.cartao[k]
				})
				cartao.edit = false
			})
		},

		/**
         * @deprecated não é mais utilizada
         */
		desativaEdicao(codigo){
			let i = this.IndexCartao(codigo)
			this.dataCartoes[i].edit = false
		},

		/**
         * @description faz a request para ter as informações de
         * edição dos cartões:
         * TC -> Tipos de Cartão
         * C  -> Colaboradores
         * */
		getInfo(){
			this.optVeiculos = []
			new FiltrosService()
				.dados_filtros(this.valueEmpresa, ['TC', 'C', 'VRFID4'])
				.then(data=>{
					this.optSelectTipoCartao = data.TC
					this.optVeiculos = data.VRFID4
					this.optSelectTrabalhador = data.C
					this.optSelectTrabalhador.push({
						description: 'Sem motorista',
						value: null
					})
				})
		},

		/**
         * Add o campo edit que é utilizado
         * para controlar se a linha esta sendo
         * editada ou não.
         */
		preparaCartoes(cartoes){
			let tratCartoes = cartoes.map(c=>{
				return {...c, 'edit':false}
			})
			return tratCartoes
		},

		/**
         * @listens exportarCadastro
         * @param {('pdf'|'xls'|'csv')} value - formato a ser
         * exportado.
         * @description faz a request para o back-end montar
         * o arquivo no formato que o usuário espera.
         * @author Gui 🍺
         */
		exportar(value){
			if(this.dataCartoes.length > 0 ){
				this.setLoading(value)
				var root =  process.env.VUE_APP_ROOT;
				let uri = this.urlBase+'exportar'
				new HttpRequest()
					.Post(uri, {tipo:value, data:this.dataCartoes})
					.then((data)=>{
						this.setLoading(value, false)
						window.open(root+'/'+data.data.local)
					})
			}
		},

		/**
         * @param {('pdf'|'xls'|'csv')} tipo
         * @param {boolean} valor
         * @description altera o loading do botão de exportação
         * de acordo com o formato escolhido pelo usuário. 
         */
		setLoading(tipo, valor = true){
			if(valor){
				if(tipo === 'pdf'){
					this.loading = [true, false, false]
				}else if(tipo==='xls'){
					this.loading = [false, true, false]
				}else{
					this.loading = [false, false, true]
				}
			}else{
				this.loading = [false, false, false]
			}
		},

		async excluirTipoCartao(value){
			this.msg =''
			let uri = this.urlBase+'excluirtipocartao'
			this.loadingTipoCartao = true
			await new HttpRequest()
				.Post(uri, {codigo:value}).then(async (data)=>{
					if(data.data.success)
						await this.getTipoCartao()
					else
						this.msg = `Esse tipo de cartão esta associado
                        a um ou mais colaboradores e não pode ser excluido`
				})
			this.loadingTipoCartao = false
		},

		/**
         * @listens click
         * @param {number} value - código do cartão a ser
         * excluido.
         */
		excluirCartao(value){
			this.$bvModal.show('deletarRFID')
			this.codExcluir = value
		},

		excluirCartaoConfirmado(value){
			this.$bvModal.hide('deletarRFID')
			let uri = this.urlBase+'excluircartao'
			new HttpRequest().Post(uri, {codigo:value}).then(()=>{
				this.getCartoes()
			})
		},

		cancelarExclusao(){
			this.$bvModal.hide('deletarRFID')
			this.codExcluir = ''
		},

		async salvaNovoCartao(){
			if(this.verificaCamposModal()){
				this.loadingTipoCartao = true
				var obj = {
					descricao : this.valueDescricao,
					cliente   : this.valueSeletorModal
				}
				await this.fazPostCartao(obj)
				await this.getTipoCartao()
				this.loadingTipoCartao = false
			}
		},

		async fazPostCartao(obj){
			let uri = this.urlBase+'salvatipocartao'
			return await new HttpRequest().Post(uri, obj)
		},
        
		verificaCamposModal(){
			var valido = true
			this.errorInputDescricao = this.errorSeletorModal = ''
			if(this.valueDescricao===''){
				this.errorInputDescricao = 'border border-danger'
				valido = false
			}
			if(this.valueSeletorModal===''){
				this.errorSeletorModal = 'border border-danger'
				valido = false
			}
			return valido
		},

		mudaDescricaoModal(value){
			this.errorInputDescricao = ''
			this.valueDescricao = value
		},

		confirmButton(){
			this.$bvModal.hide('novoTipo')
			this.msg = ''
		},

		mudaSeletorModal(value){
			this.errorSeletorModal = ''
			this.valueSeletorModal = value[0]
		},

		/**
         * @listens click - botão do lado esquerdo do
         * exportar.
         */
		novoTipo(){
			this.getTipoCartao()
			this.msg=  ''
			this.$bvModal.show('novoTipo')
		},

		/**
         * @listens changeSelect
         * @param {number[]} value
         */
		mudaSeletorEmpresa(value){
			this.infoRel = 'info'
			this.valueEmpresa = value
			this.$refs.selectColaborador.clearAll()
			this.dataCartoes = []
			this.optSelectTipoCartao = []
			this.optVeiculos = []
			if(this.valueEmpresa.length > 0){
				this.getCartoes()
				this.getInfo()
			}
		},

		/**
         * @listens changeSelect
         * @param {number[]} trabalhador
        */
		changeTrabalhador(trabalhor){
			this.trabalhorSelecionado = trabalhor
			if(this.valueEmpresa.length > 0){
				this.getCartoes()
				// this.getInfo() ??
			}
		},

		getCartoes(){
			this.infoRel = 'info'
			this.gerandoRel = true
			var obj= this.criaObj()
			let uri = this.urlBase+'cartoesrfid'
			new HttpRequest().Post(uri, obj).then((data)=>{
				if(data.code === 201){
					this.dataCartoes = this.preparaCartoes(data.data.cartoes)
					if(this.dataCartoes.length === 0)
						this.infoRel = 'error'
					this.gerandoRel = false
				} else {
					this.gerandoRel = false
					this.infoRel = 'error2'
				}
			})
		},

		criaObj(){
			var obj ={
				codigos : this.valueEmpresa,
				colaborador : this.trabalhorSelecionado,
			}
			return obj
		},

		getTipoCartao(){
			let uri = this.urlBase+'tiposcartao'
			new HttpRequest().Get(uri).then((data)=>{
				this.dataTiposDeCartao = data.data.tipos
			})
			return
		},

		selectAllClientes(){
			if(this.getMaster())
				this.firstOne = []
			else  
				this.firstOne =  new EmpresasService().Get()
		},
		...mapGetters(['getMaster', 'getEditar', 'getExcluir']),
	},

	mounted() {
		this.apacere = this.getMaster()
		this.selectAllClientes()
	},

	computed: {
		mostrarRel(){
			return !!this.dataCartoes.length
		},

		/**
         * @description filtra os resultados da tabela
         * de acordo com a pesquisa do usuário.
         */
		dadosRelatorio(){
			var reg = new RegExp(this.buscaUsuario, 'i')
			return this.dataCartoes.filter((c=>{
				if(typeof c.cccartao === 'number')
					return c.cccartao.toString().match(reg)
			}))
		}
	},
})
</script>
<style lang="scss" scoped>
    table{
        height: 100%;
    }
    .almostnopadding{
        padding-top: 25px;
    }
    .linhaTabela{
        max-height: 35px !important;
    }
    th{
        padding-left: 10px !important;
        text-align: left !important;
    }
    td{
        width: 200px !important;
        height: 35px !important;
        select{
            max-width: 140px !important;
        }
    }
    .exibir{
        padding-top: 7px !important;
        width: 210px !important;
        height: 35px !important;
        padding-bottom: 0!important;
    }
    .actions{
    width: 100px;
    }
    .erroExclusao{
        padding-left: 15px;
        color: red
    }
</style>
