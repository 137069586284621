<template>
    <panelEagle 
        id="listagemGrupoVeiculos"
        :loading="loadingPanel">
        <div class="col-sm-12 nopadding divDontPrint">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiCarMultiple" titulo="Grupo de veículos"></tituloPage>
                </div>
                <div class="col-sm-6 divButtonsBasic">
                    <basicButtonsCadastrosEIN 
                        :loading="carregandoE"
                        @exportarCadastro="exportarGrupoVeiculo"
                        tipoNovo="button"
                        linkNew="editarGrupoVeiculos"
                        :disabledDropdown='$v.gruposVeiculo.$invalid'>
                    </basicButtonsCadastrosEIN>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-4 nopadding">
                    <selectAll 
                        @changeSelect="changeEmpresa" 
                        :isMultiple="true" 
                        nameForRadio="empresasListGrupoVeiculo" 
                        :hasSelectAll="true" 
                        :labels="[{indexDFH: 'EM', description: 'Empresas'}]"
                        :selected="selectedEmpresa"
                        :optionsArray="optionsArrayEmpresas" 
                        firstSelected="EM">
                    </selectAll>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
        </div>

        <div class="col-sm-12 nopadding">
            <tableListagemDetalhes
                statusBar='error'
                :botoesAcoes="true"
                class="col-sm-12"
                :conteudo = 'gruposVeiculo'
                :titulo = "[
                    {
                        key: 'descricao', 
                        label: 'Descrição', 
                        sortable: true, 
                        sortDirection: 'asc', 
                        class: 'text-left'
                    },
                    { 
                        key: 'empresa',    
                        label: 'Empresa', 
                        sortable: true, 
                        sortDirection: 'asc',
                        class: 'text-left' 
                    },
                ]">
                <template  
                    slot-scope={row}>
                    <basicButtonsListagemED
                        :id="String(row.index)"
                        @editarCadastro="editarGrupoVeiculos(row)"
                        @excluirCadastro="excluirGrupoVeiculos(row)">
                    </basicButtonsListagemED>
                </template>
                <template 
                    #sanfona='{row}'
                    :id="String(row.item.codigo)"> 
                    <span
                        v-for="(placa,index) in placas[row.item.codigo]"
                        :key="index">
                        <span class="badge badge-secondary"
                            v-if="placa.veplaca != null">
                            {{ placa.veplaca }} | {{ placa.veprefixo }}
                        </span>
                    </span>
                    <!-- Se o grupo de veículos não tiver veículos este SPAN faz o collapse informa que não a veículo -->
                    <span 
                        v-for="(placa,index) in placas[row.item.codigo]"
                        :key="index+'a'">
                        <span class="span"
                            v-if="placa.veplaca == null">
                            <h6> Nenhum veículo associado </h6>
                        </span>
                    </span>
                </template>
            </tableListagemDetalhes>
        </div>

    </panelEagle>
</template>
<script lang="js">
import Vue from 'vue'
import BasicButtonsListagemED from '@/components/Atom/Buttons/BasicButtonsListagemED.vue'
import BasicButtonsListagemEDE from '@/components/Atom/Buttons/BasicButtonsListagemEDE.vue'
import SimpleButton from '@/components/Atom/Buttons/SimpleButton.vue'
import {mdiCarMultiple } from '@mdi/js'
import {EmpresasService} from '@/Services/auth/Empresas.service'
import {HttpRequest} from '@/Services/auth/HttpRequest.Service'
import { required } from 'vuelidate/lib/validators'
import Swal from 'sweetalert2'
import { conectionError } from '@/Services/Helpers/swellHeper'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name:'ListagemGrupoVeiculo',
    props: ['source'],
    components:{
        'panelEagle'               : require('@/components/Atom/Panel/PanelEagle').default, 
        'tituloPage'               : require('@/components/Atom/Header/Titulo').default,
        'basicButtonsCadastrosEIN' : require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
        'selectAll'                : require('@/components/Atom/Select/SelectAll').default,
        'basicButtonsListagemED'   : require('@/components/Atom/Buttons/BasicButtonsListagemED').default,
        'tableListagemDetalhes'    : require('@/components/Atom/Table/TableListagemDetalhes').default,
        
    },

    validations:{
        gruposVeiculo: {
            required
        },
    },

    data(){
        return{
            mdiCarMultiple:mdiCarMultiple,
            optionsArrayEmpresas : new EmpresasService().Get(),
            selectedEmpresa:[],
            empresasSelected: [],
            veiculosSelected: [],
            gruposVeiculo: [],
            placas: [],
            valueBusca: [],
            loadingPanel: false,
            clicado: false,
            linha: 0,
            gvcodigo: [],
            empresas: [],
            codigo:[],
            carregandoE:[false,false,false],
        }
    },

    methods:{
        busca(value){
            this.valueBusca = value;
            this.changeEmpresa(this.empresasSelected);
        },
        changeEmpresa(empresas){
            this.empresasSelected = empresas;
            this.ListagemGrupoVeiculo();
        },

        async ListagemGrupoVeiculo(){
            this.loadingPanel = true
            this.gruposVeiculo = []
            this.placas = []
            var data = await new HttpRequest().Post('/telemetria/cadastros/grupos/veiculos/listagem', 
                {'clientes':this.empresasSelected, 'busca':this.valueBusca});
            this.placas = data.data.placas
            this.gruposVeiculo = data.data.listagem
            this.gvcodigo = data.data.codigo
            this.grupoCodigo = this.gvcodigo.forEach(el => {
                this.codigo.push(el.gvcodigo)
            });
            this.loadingPanel = false
        },

        editarGrupoVeiculos(codigo){
            this.$router.push({name:'editarGrupoVeiculos', params:{id:codigo.item.codigo}})
        },
        async excluirGrupoVeiculos(codigo){
            this.loadingPanel = true
            var deuCerto = {
                    'success': this.success
                }
            var data = await new HttpRequest()
                .Post('/telemetria/cadastros/grupos/veiculos/excluir', 
                    {
                        'codigo':codigo
                    }
                );
            if(data.data.success){
                    this.ListagemGrupoVeiculo();
                } else {
                    conectionError()
                }
            this.loadingPanel = false
        },
        async exportarGrupoVeiculo(type){
            var root =  process.env.VUE_APP_ROOT;
            this.setCarregando(type)
            if(this.gruposVeiculo.length > 0){
                var data = await new HttpRequest()
                .Post('/telemetria/cadastros/grupos/veiculos/exportacao', 
                        {
                            'clientes':this.empresasSelected, 
                            'tipo':type
                        }
                    );
                window.open(root+'/'+data.data.exportar);
                this.setCarregando()
            }
        },
        setCarregando(type=null){
                if(type == 'pdf'){
                    this.carregandoE = [true, false, false]
                }else if(type == 'xls'){
                    this.carregandoE = [false, true, false]
                }else if(type == 'csv'){
                    this.carregandoE = [false, false, true]
                }else{
                    this.carregandoE = [false, false, false]
                }
        },

        ...mapGetters(['getMaster']),
    },
    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optionsArrayEmpresas
        }
    },
});

</script>

<style scoped>

    .badge.badge-secondary{
        font-size: 13px;
        font-weight: bold;
        margin: 5px;
        width: 200px;
        height: 30px;
        padding-top: 10px;
    }

    .span{
        text-align: center;
        font-size: 13px;
        font-weight: bold;
    }
    
</style>
