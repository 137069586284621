<template>
    <div>
        <b-popover
            v-for='(veiculo, i) in markerVeiculos'
            :key='veiculo.veiculoId+"_popover_status"+i'
            :target="`status_alertas_${veiculo.veiculoId}`"
            triggers="hover focus">
            <div
                :key='`${veiculo.veiculoId}___${alerta["tacor"]}`'
                v-for='alerta in veiculo.alertas'>
                {{formataAlerta(alerta)}}
            </div>
            <div v-if="!veiculo.alertas || !veiculo.alertas.length">
                Veículo não possui alertas
            </div>
            <div class='d-flex justify-content-center'
                v-if="veiculo.alertas && veiculo.alertas.length > 0">
                <b-button
                    squred
                    style="background-color:#007bff; color:white;"
                    @click='irParaAlertas(veiculo)'>
                    Verificar
                </b-button>
            </div>
        </b-popover>
    </div>
</template>

<script>

import Vue from 'vue'
import { required } from 'vuelidate/lib/validators'

export default Vue.extend({
    name: 'popoverAlertas',

    props: {
        markerVeiculos: {
            type: [Object, Array],
            required: true
        },
    },

    validations: {
        ponto: { required }
    },
    
    methods: {
        /**
         * @description formata os alertas do veículo
         * @param {object} 				   alerta
         * @param {number} 				   alerta.count quantidade de alertas.
         * @param {('amarelo'|'vermelho')} alerta.tacor - cor do alerta
         * @return {string} alertas formatados.
         * @author Gui 🍺🏴
         */
        formataAlerta(alerta){
            return Object.values(alerta)
                .reverse()
                .join(': ')
                .replace(/vermelho/i, 'Grave')
                .replace(/amarelo/i, 'Atenção')
        },

		/**
         * @param {object} veiculo - dados do veículo 
         * @param {string} veiculo.placa,
         * @param {number} veiculo.clcodigo
         * @description abre o não menu de alertas em uma nova aba.
		 * @return {void}
         */
        irParaAlertas(veiculo){
			let routeData = this.$router.resolve({
				name  :'nao/menu/alertas',
				params:{
					cli:veiculo.clcodigo,
					placa:veiculo.placa,
				}
			})
			window.open(routeData.href, '_blank')
		},

    },
})
</script>