<template>
    <div>
        <slot></slot>
    </div>
</template>
<script>
import L from 'leaflet'
import './PolilinhaEditavelPlugin.js'
import { antPath } from 'leaflet-ant-path';
// import "leaflet-editable-polyline";
import { findRealParent, propsBinder } from 'vue2-leaflet'

const props = {
    latLng: {
        type: [Object, Array]
    },

    options: {
        type: Object,
        default() {
            return {
                maxMarkers: 50,
                color: '#428bca',
                weight: 8,
                pointIcon: L.divIcon({
                    className: 'divIconEditable',
                    html: '<span id="iconEditablePoliline" class="iconEditablecircle point-12"></span>',
                    iconAnchor: [0, 0],
                    popupAnchor: [0, 0]
                }),
                newPointIcon: L.divIcon({
                    className: 'divIconEditable',
                    html: '<span id="newIconEditablePoliline" class="iconEditableNewcircle new-point-12"></span>',
                    iconAnchor: [0, 0],
                    popupAnchor: [0, 0]
                }),
                // newPolylines:false,
                // newPolylineConfirmMessage:'olaaaa',
                className: this.polylineClass
            }
        },
    },

    visible: {
        type: Boolean,
        // custom: true,
        default: true
    },

    poliEditavel: {
        type: Boolean,
        default: true
    }
};

export default {
    props: props,
    // mounted: function (){
    //   this.$nextTick(()=>{
    //     this.funcLoca()
    //   })
    // },

    data() {
        return {
            polylineClass: '',
        }
    },

    watch: {
        latLng: function () {
            this.funcLoca()
        },
        poliEditavel: function () {
            // this.setVisible(this.visible)  
            this.funcLoca()
        }
    },

    beforeDestroy() {
        this.setVisible(false)
    },

    methods: {
        funcLoca() {
            this.setVisible(false)
            if (this.poliEditavel) {
                this.criaEditavel()
            } else {
                this.criaAnimada()
            }
        },

        atualizar() {
            // this.$forceUpdate()
        },

        criaAnimada() {
            var polylineInfo = this
            this.mapObject = antPath(this.latLng, {
                "delay": 1500,
                "weight": 8,
                "color": '#428bca',
                "opacity": 0.9
            }).on('mouseover', function (e) {
                e.target.setStyle({
                    color: '#E0484A',
                    delay: 1500,
                });
            }).on('mouseout', function (e) {
                e.target.setStyle({
                    color: '#428bca',
                    delay: 1500,
                });
            })
                .on('click', function (e) {
                    // polylineInfo.$emit('rotaClick', polylineInfo.latLng, polylineInfo.polylineClass, polylineInfo.id, 'PAP')
                    polylineInfo.devolveLatLang(polylineInfo.latLng, polylineInfo.polylineClass, polylineInfo.id, 'PAP')
                });
            propsBinder(this, this.mapObject, props);
            if (this.$parent._isMounted) {
                this.deferredMountedTo(this.$parent.mapObject);
            }
        },

        criaEditavel() {
            var polylineInfo = this
            this.mapObject = L.Polyline.PolylineEditor(this.latLng, this.options)
                .on('mouseover', function (e) {
                    e.target.setStyle({
                        color: '#E0484A',
                        delay: 2500,
                    })
                }).on('mouseout', function (e) {
                    e.target.setStyle({
                        color: '#428bca',
                        delay: 2500,
                    })
                }).on('click', function (e) {
                    var latlng = this._latlngs
                    // polylineInfo.$emit('rotaClick', latlng, polylineInfo.polylineClass, polylineInfo.id, 'PE')
                    polylineInfo.devolveLatLang(latlng, polylineInfo.polylineClass, polylineInfo.id, 'PE')
                })
            propsBinder(this, this.mapObject, props)
            if (this.$parent._isMounted) {
                this.deferredMountedTo(this.$parent.mapObject)
            }
        },

        // esse componente ta trabalhando com 2 plugins, entao os dois tem retornos diferente
        // essa func padroniza isso pra n gerar problema pra quem for usar
        // cuide se tu for mudar aqui, pra n ter que mudar em outros lugares do sistema
        devolveLatLang(latlng, polylineClass, id, identificador) {
            if (identificador == 'PAP') {
                this.$emit('rotaClick', latlng, polylineClass, id, identificador)
                return
            }
            var arrLatLon = []
            for (const index in latlng) {
                arrLatLon.push([latlng[index].lat, latlng[index].lng])
            }
            this.$emit('rotaClick', arrLatLon, polylineClass, id, identificador)
            return
        },

        deferredMountedTo(parent) {
            this.parent = parent
            var that = this.mapObject
            for (var i = 0; i < this.$children.length; i++) {
                if (typeof this.$children[i].deferredMountedTo == "function") {
                    this.$children[i].deferredMountedTo(that);
                }
            }
            if (this.visible) {
                this.mapObject.addTo(parent)
            }
        },

        setVisible(newVal, oldVal) {
            if (newVal === oldVal) {
                return
            }
            if (this.mapObject) {
                if (newVal) {
                    this.mapObject.addTo(this.parent)
                } else {
                    this.parent.removeLayer(this.mapObject)
                }
            }
        }
    }
}
</script>

<style lang="scss">
.divIconEditable {
    .iconEditablecircle {
        background-color: #fff;
        border-radius: 200px;
        padding-right: 6px;
        padding-top: 6px;
        min-width: 12px;
        border: 5px solid #38f;
        position: absolute;
        transform: translate(-50%, -50%);

        &:hover {
            transform: scale(1.2) translate(-50%, -50%) !important;
        }
    }

    // .iconEditablecircleHover{
    //   background-color: #fff;
    //   border-radius: 200px;
    //   padding-right: 6px;
    //   padding-top: 6px;
    //   min-width: 12px;
    //   border: 5px solid #E0484A;
    //   position: absolute;
    //   transform: scale(2) translate(-50%, -50%) !important;
    // }

    .iconEditableNewcircle {
        background-color: #fff;
        border-radius: 200px;
        padding-right: 3px;
        padding-top: 4px;
        min-width: 12px;
        border: 4px solid #98c0f7;
        position: absolute;
        transform: translate(-50%, -50%);

        &:hover {
            transform: scale(1.2) translate(-50%, -50%) !important;
        }
    }

    // .iconEditableNewcircleHover{
    //   background-color: #fff;
    //   border-radius: 200px;
    //   padding-right: 3px;
    //   padding-top: 4px;
    //   min-width: 12px;
    //   border: 4px solid #f79898;
    //   position: absolute;
    //   transform: translate(-50%, -50%);
    // }
}
</style>
  