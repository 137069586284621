Componente para icones no sistema
props: 
    icon => Icone importado do MDI, lista com todos https://materialdesignicons.com/
        Sempre importar o ícone, passar para uma variavel e passar essa variavel
			pra prop.
        Importar da mesma maneira em que esta importado aqui
    size => Tamanho do ícone, pode ser número ou string numérica mesmo
eventos: 
    O componente não possue nenhum evento próprio, mas todos os eventos do
		ícone podem ser acessados


<template>
    <svg-icon 
    v-on='$listeners' 
    v-bind='$attrs' 
    type="mdi" 
    :path="icon" 
    :size="tam"/>
</template>
<script>
import SvgIcon from '@jamescoyle/vue-icon'
import { mdiHumanGreeting } from '@mdi/js'; 
import Vue from 'vue'
export default Vue.extend({
	name:'baseIcon',
	components:{
		SvgIcon
	},
	inheritAttrs:false,
	props:{
		icon:{
			type: String,
			default:mdiHumanGreeting
		},
		size:{
			type: [Number, String],
			default: 42
		},
	},
	data(){
		return{
			tam: Number(this.size)
		}
	}
})
</script>

<style lang="scss" scoped>

</style>