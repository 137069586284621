<template>
    <div id="resetSenha" class="d-flex justify-content-center align-items-center">
        <form class="resetSenha" @submit.prevent="passwordReset">
            <div class="col-sm-12 divCentralLogin">
                <div class="col-sm-12 divReturnToLogin">
                    <a class="buttonReturnToLogin " @click="returnToLogin">
                        <b-icon icon="chevron-compact-left" />
                        Voltar
                    </a>
                </div>
                <div class="col-sm-12 divImgLogo">
                    <img class="imgLogo" src="../../assets/logo.png">
                </div>
                <div class="col-sm-12 divInputs">
                    <span class="col-sm-12 row nopadding">
                        Insira o e-mail da sua conta:
                    </span>
                    <input required class="form-control inputLogin" v-model="form.email" type="text" placeholder="E-mail" />
                </div>
                <div class="col-sm-12 divButtonLogin">
                    <b-button type="submit" class="buttonSendEmail" :disabled="loadingEnviar">
                        <span v-show="!loadingEnviar">Enviar</span>
                        <b-spinner v-show="loadingEnviar" class="m-1" small></b-spinner>
                    </b-button>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import Swal from 'sweetalert2'

export default {
    name: 'PasswordReset',

    data() {
        return {
            form: {
                email: '',
            },
            loadingEnviar: false,
        };
    },

    methods: {
        /**
         * Espera a confirmação que o usuário terminou o formulario
         * caso de algum erro no processamento da função ela passa um 500 para o montaSwall
         * @listen click no botão enviar ou enter - confirmação do formulario
         * @fires ResetMail|montaSwall - chama a ResetMail para fazer a validação envir um email de resgate de senha
         * @fires montaSwall - espera o retorno da ResetMail para as validações de status com a montaSwall
         * @return none
         * @author Lucas Eduardo
         */
        async passwordReset() {
            try {
                if (this.loadingEnviar) {
                    return
                }
                this.loadingEnviar = true
                var email = await this.auth.ResetMail(this.form.email.toString())
                this.loadingEnviar = false
                this.montaSwall(email.status)
            } catch (error) {
                this.montaSwall(500)
            }
        },

        /**
         * Escolhe qual alerta de erro/confimação vai chamar
         * @fires returnToLogin - quando o codigo recebido for 202
         * @param {Number} qual - codigo de status http
         * @return none
         * @author Lucas Eduardo
         */
        montaSwall(qual) {
            if (qual == '202' || qual == '200') {
                Swal.fire({
                    icon: 'success',
                    title: 'E-mail enviado com sucesso',
                    html: `
                            <span>
                                Verirfique sua caixa de entrada, ou spam
                            </span>
                        `,
                    showConfirmButton: false,
                    timer: 4500
                })
                this.returnToLogin()
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Tivemos alguns problemas, tente novamente mais tarde',
                    footer: '<span>Se o erro persistir, entre em contato com nosso suporte pelos telefones (49) 3340-0770 | (49) 99172 9123.</span>'
                })
            }
        },
        
        returnToLogin() {
            this.route.Navigate('login')
        }
    }
};
</script>

<style lang="scss">
#resetSenha {
    overflow: hidden;
    height: 93vh;
    // width:100vw;
    background-image: url("../../assets/fundoEagleLogin.png") !important;
    background-repeat: no-repeat !important;
    background-size: cover;
    background-position: center;
}

.resetSenha {
    border: 1px solid #0f4352;
    background: rgba(0, 0, 0, 0.842);

    .divCentralLogin>.divImgLogo {
        margin: auto;
    }
}

.divCentralLogin>.divReturnToLogin {
    padding: 5px;
    border-bottom: 1px solid #d5d5d5;
    padding-bottom: 20px;
}

.divCentralLogin>.divReturnToLogin>.buttonReturnToLogin {
    float: left;
    text-decoration: none;
    color: #6c757d !important;
    font-weight: 100 !important;
    font-size: 13px;
    cursor: pointer;
}

.divCentralLogin>.divImgLogo>.imgLogo {
    width: 50%;
}

.divCentralLogin>.divInputs>.inputLogin {
    background: transparent;
    border: 1px solid #0f4352;
    border-radius: 0px;
    margin-top: 10px;
    color: #fff;
}

.divCentralLogin>.divButtonLogin>.buttonSendEmail {
    border-radius: 0px;
    text-align: center;
    width: 50%;
    background: #2e759a;
    border: 1px solid #0f4352;
    margin-top: 15px;
    color: #fff;
    font-weight: bold;
    /* border-bottom: 1px solid #d5d5d5; */
    margin-bottom: 10px;
    cursor: pointer;
}

.divCentralLogin {
    width: 40%;
    margin: auto;
    // box-shadow: 0px 0px 30px 0px #000;
    padding-bottom: 10px;
}

.imgLogo {
    width: 40%;
    height: auto;
}
</style>
