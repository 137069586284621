<template>
    <v-marker-cluster :options='options' id='clusterPontosMapa' ref='CompCluster'>
        <slot></slot>
    </v-marker-cluster>
</template>

<script>
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
export default {
	//https://github.com/Leaflet/Leaflet.markercluster <- marker cluster
	//https://github.com/jperelli/vue2-leaflet-markercluster  <- marker cluster vue
	//A documentação do vue só explica como adc o elemento ao vue, a outra como ele funciona.
	name:'marker-cluster',
	components: {
		'v-marker-cluster': Vue2LeafletMarkerCluster,
	},
	props:{
		disableClusterZoom:{
			type: [Number, Boolean],
			default: 16
		},
		spiderfyOnMaxZoom:{
			type: Boolean,
			default: false
		},
		showCoverageOnHover:{
			type: Boolean,
			default: true
		},
		clusterRadius:{
			type: Number,
			default: 100
		},
		/**
         * @param {number} i   - número de marcadores processados.
         * @param {number} ii  - número total de marcadores sendo adicionados
         * @param {number} iii - tempo passado (ms)
         * @description essa função é chamada toda vez que o cluster
         * termina de adicinar um "chunk" ou pedaço de uma array
         * de marcadores.
         */
		chunkProgress:{
			type: Function,
			default: ()=>{return}
		},
		/** intervalo entre periodos consecutivos processando addLayer (ms)*/
		chunkDelay:{
			type: Number,
			default: 200
		},
		/** tempo de intervalo entre cada 'chunk' (ms)*/
		chunkInterval:{
			type: Number,
			default: 200
		}
	},
	data() {
		return {
			options:{
				disableClusteringAtZoom: this.disableClusterZoom,
				singleMarkerMode:false,
				showCoverageOnHover:this.showCoverageOnHover,
				spiderfyOnMaxZoom: this.spiderfyOnMaxZoom,
				chunkedLoading:true,
				removeOutsideVisibleBounds:true,
				maxClusterRadius:this.clusterRadius,
				chunkInterval:this.chunkInterval,
				chunkDelay: this.chunkDelay,
				chunkProgress:this.chunkProgress
			}
		}
	},
	methods: {
		/**
         * Redesenha todos, um ou um grupo de markers.
         * @param {(L.marker|boolean)} markers um ou uma array de markers a serem redesenhados.
         * Não passar nada caso o objetivo seja desenhar todos os markers
         * novamente.
         * @author Gui 🍺
         */
		refreshClusters(markers=false) {
			if(markers)
				this.$refs.CompCluster?.mapObject?.refreshClusters(markers)
			else
				this.$refs.CompCluster?.mapObject?.refreshClusters()
		},

		clearLayers(){
			this.$refs.CompCluster?.mapObject?.clearLayers()
		},

		/**
         * @description Procura o pai do marcador passado por parametro, mas apenas se
         * o marcador esta visível ou dentro de um cluster que visível 
         * (literalmente, se esta na parte onde a viewport se encontra).
         * @param marker
         * @returns pai do marcador ou null.
         */
		getVisibleParent(marker){
			return this.$refs.CompCluster?.mapObject?.getVisibleParent(marker)
		},

		/**
         * @description adiciona um novo marker no mapa (método para adc apenas um marker).
         * @param marker marcador a ser adicionado.
         */
		addLayer(marker){
			this.$refs.CompCluster?.mapObject?.addLayer(marker)
		},

		/**
         * @description remove um marcador do cluster.
         * @param {marker} marker - marcador a ser removido.
         */
		removeLayer(marker){
			this.$refs.CompCluster?.mapObject?.removeLayer(marker)
		},

		/**
         * @description remove vários marcadores do cluster.
         * @param {array} markers - marcadores a serem removidos.
         */
		removeLayers(markers){
			this.$refs.CompCluster?.mapObject?.removeLayers(markers)
		},
		/** 
         * Função para adicionar mais de um marker por vez.
         * Testei com até 23k markers e tipo, susse no musse.
         * Só passar os markers "prontos" pra cá.
         * @param {array} arrayMarkers
         * @author Gui 🍺 
        */
		addMarkerBulk(arrayMarkers){
			this.$refs.CompCluster?.mapObject?.addLayers(arrayMarkers)
		},

		/**
         * @description remove todos os marcadores do cluster
         */
		clear(){
			this.$refs.CompCluster?.mapObject?.clearLayers()
		}
	},
}
</script>

<style lang="scss" scoped>
#clusterPontosMapa{
    @import "~leaflet.markercluster/dist/MarkerCluster.css";
    @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
}
</style>