<template>
    <panelEagle id='Produtos' :loading='salvando'>
        <div class="col-sm-12 nopadding row">
            <div class="col-sm-6">
                <tituloPage :icon='mdiOffer' titulo='Descontos'></tituloPage>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                <basicButtonsCadastrosSC
                    :disabled='$v.$invalid'
                    linkCancel ='listarDescontos'
                    tipoCancela='button'
                    @cancelarCadastro="cancelarCadastro"
                    @salvarCadastro='salvarCadastro'>
                </basicButtonsCadastrosSC>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class="col-sm-12 row nopadding">
            <div class="col-sm-4 nopadding">
                <inputSimple
                   :inputClass='{"border border-danger":$v.valueDescricao.$anyError}'
                    @blur='$v.valueDescricao.$touch()'
                    label='Descrição*'
                    name='descrição'
                    @changeInput='changeDescricao'
                    :value='valueDescricao'/>
            </div>
            <div class="col-sm-4 nopadding">
                <inputSimple
                    :inputClass='{"border border-danger":$v.valueVeiculo.$anyError}'
                    @blur='$v.valueVeiculo.$touch()'
                    label='Quantidade de veículos*'
                    name='veiculos'
                    @changeInput='changeVeiculo'
                    :value='valueVeiculo'
                    :hasMask='true'
                    :mask='["NNNNNNNNNNNNNNNNNNNNNNNN"]'/>
            </div>
            <div class="col-sm-4 nopadding">
                <inputSimple
                    :inputClass='{"border border-danger":$v.valueDesconto.$anyError}'
                    @blur='$v.valueDesconto.$touch()'
                    label='Desconto em (%)*'
                    name='desconto'
                    @changeInput='changeDesconto'
                    :value='valueDesconto'
                    :hasMask='true'
                    :mask='["NNN"]'/>
            </div>
            <span class="campo nopadding">(*) Campos obrigatórios</span>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
    </panelEagle>
</template>
<script>
import Vue  from 'vue'
import {mdiOffer } from '@mdi/js'
import Vuex from '@/store/index'
import { required } from 'vuelidate/lib/validators'
import { HttpRequest } from '../../../../Services/auth/HttpRequest.Service'
export default Vue.extend({
	name:'cadastrarDesconto',
	components:{
		'panelEagle'              : require('@/components/Atom/Panel/PanelEagle').default,
		'basicButtonsCadastrosSC' : require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
		'tituloPage'              : require('@/components/Atom/Header/Titulo').default,
		'inputSimple'             : require('@/components/Atom/Inputs/InputSimple').default,
	},
	validations:{
		valueDescricao :{required},
		valueVeiculo   :{required},
		valueDesconto  :{required},
	},
	data(){
		return {
			mdiOffer:mdiOffer,
			valueDescricao: '',  
			valueVeiculo: '',  
			valueDesconto: '',  
			id : '',
			salvando:false,
		}
	},
	methods:{
		changeDescricao(value){
			this.valueDescricao =  value
		},
		changeDesconto(value){
			this.valueDesconto =  value
		},
		changeVeiculo(value){
			this.valueVeiculo =  value
		},
		cancelarCadastro(){
			this.$router.push({name:'listarDescontos'})

		},
		async salvarCadastro(){
			var obj = [
				{'dedescricao' :this.valueDescricao},
				{'dedesconto'  :this.valueDesconto},
				{'deveiculos'  :this.valueVeiculo},  
			]
			this.salvando = true
			let uri = ''
			if(this.id == undefined) {
				uri = '/administrativo/cadastros/descontos/salvar' 
				var salvar = await new HttpRequest()
					.Post('/administrativo/cadastros/descontos/salvar', {'obj':obj})
			}else{
				var update = await new HttpRequest()
					.Post('/administrativo/cadastros/descontos/update', {'obj':obj, 'id':this.id}, )
			}
			this.salvando = false
			this.$router.push({name:'listarDescontos'})
		},
            
	},
	watch:{
		valueDesconto:function(){
			if(this.valueDesconto >=100){
				this.valueDesconto = '100'
			}
		}
	},
	async mounted(){
		this.id = this.$route.params.id
		var dados = []
		if(this.id != undefined){
			dados = await new HttpRequest()
				.Post('/administrativo/cadastros/descontos/dados', {'obj': this.id})
			this.valueDescricao = dados.data[0]['dedescricao']
			this.valueVeiculo = dados.data[0]['deveiculos']+''
			this.valueDesconto = dados.data[0]['dedesconto']+''
		}
	}
})

</script>
<style lang="scss">
    .campo{
        font-size: 12px !important;
        margin-left: 15px!important;
        margin-top: 5px!important;
    }
</style>
