<template>
  <panelEagle 
    id='gestaoDeCadeados'
    :loading='carregando'
  >
    <div class="col-sm-12 row">
      <div class="col-sm-6 nopadding">
        <titulo titulo='Gestão de Cadeados' :icon='mdiSortVariantLock'/>
      </div>
      <div class="col-sm-6 nopadding divDontPrint">
        <botoesEIN
          :disabledDropdown ="tableData == 0"
          :loading          ="carregandoE"
          @exportarCadastro ='exportar'>
          <template #novo><span hidden/></template>
          <!-- Essa gambiarra é necessaria para poder usar o mesmo componente sem o botão de salvar -->
        </botoesEIN>
      </div>
    </div>
    <div class="col-sm-12"><hr></div>
      <div class="col-sm-12 nopadding row ">
        <div class="col-sm-4 divDontPrint nopadding">
          <selectAll
            nameForRadio='seletorEmpresa'
            :isMultiple='true'
            :hasSelectAll='true'
            :labels='[{"description":"Empresas*"}]'
            :optionsArray='optSeletorEmpresa'
            :selected="selectedEmpresa"
            @changeSelect='mudaEmpresaSelecionada'
          />  
        </div>
        <div class="col-sm-6">
        </div>
        <div class="col-sm-2 divDontPrint nopadding">
          <inputSimple
            @changeInput='changeFiltro'
            label='Filtrar resultados'/>
            <!-- @changeInput='value => busca= value' -->
        </div>
      </div>
    <div class="col-sm-12"><hr></div>
      <div class="col-12">
        <tableSimples>
          <thead>
            <br>
            <tr>
              <th>Serial</th>
              <th>Cadeado</th>
              <th>Prefixo</th>
              <th>Último comando</th>
              <th>Empresa</th>
              <th>Bateria</th>
              <th>Último evento</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody v-if='exibirTable'>
            <tr v-for='(item, index) in tableData' 
              :key="index"
              class="tableGestao">
              <td v-text='item.modulo'/>
              <td v-text='item.placa' />
              <td>
                <input 
                  v-if='item.showInput'
                  maxlength='20'
                  class='inputTable'
                  @keyup.enter='confirmaEdicao(index)'
                  @keyup.esc='cancelarEdicao(index)'
                  v-model='item.prefixoEdicao'
                />
                <span  v-else v-text="item.prefixo"/>
              </td>
              <td>
                <div>
                  <baseIcon 
                    :style="'color:'+item.classeSpan+';content-alig:left'"
                    size="18" 
                    :icon="verificaIcone(item.span)"/>
                  <span v-text='item.ultimoComando' />
                </div>
              </td>
              <td v-text='item.empresa' />
              <td v-text='item.bateria' />
              <td v-text='item.ultimoEvento' />
              <td class='editavel'>
                <template v-if='item.showInput'>
                  <div class='col-sm-12 nopadding row'>
                    <div class='col-sm-6 nopadding'>
                      <simpleButton
                        width='49px'
                        type='red'
                        :icon='mdiCloseThick'
                        title='Cancelar edição'
                        event='click'
                        @click="cancelarEdicao(index)"
                      />
                    </div>
                    <div class='col-sm-6 nopadding'>
                      <simpleButton 
                        type='green'
                        width='49px'
                        title='Confirmar edição'
                        :icon='mdiCheckBold'
                        event='click'
                        @click='confirmaEdicao(index)'
                      />
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class='col-sm-12 nopadding row'>
                    <div class='col-sm-6 pl-0 pr-1'>
                      <simpleButton
                        width='50px'
                        type='yellow'
                        :icon='mdiProgressWrench'
                        title='Enviar comando'
                        event='click'
                        @click='listarComandos(index)'
                      />
                    </div>
                    <div class='col-sm-6 pl-0 pr-1'>
                      <simpleButton 
                        type='green'
                        width='50px'
                        title='Editar cadeado'
                        :icon='mdiPencilLock'
                        event='click'
                        @click='editarInLine(index)'
                      />
                    </div>
                  </div>
                </template>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <statusInformation 
              :statusBar='sBar'
              colspanForTd='8'
            />
          </tbody>
        </tableSimples>
      </div>
     <br>
    <!-- Modal enviar comando -->
    <modalEagle
      @modalClose="closeModal"
      id='modalComandos'
      :title="currentTitle">
      <template #modalBody>
        <b-overlay :show="carregandoModalComandos"> 
          <div class="col-sm-12 row nopadding">
            <div class="col-sm-5 nopadding">
              <selectAll
                nameForRadio='tipoComando'
                :isMultiple='false'
                class='nopadding'
                :labels='[{description:"Tipo Comando"}]'
                :optionsArray='optTipoComando'
                @changeSelect='selecionaComando'
              />
            </div>
            <div class="col-sm-5 nopadding">
              <selectAll
                :disabled='comandoNaoUsaCartao'
                nameForRadio='cartao'
                :loading='loadingCartoes'
                :isMultiple='false'
                ref='seletorCartao'
                :optionsArray='optCartoes'
                :labels='[{description:"Cartão"}]'
                @changeSelect='selecionaCartao'
              />
            </div>
            <div class="col-sm-2 nopadding divBotaoModal">
              <simpleButton
                :disabled='comandoInvalido'
                text='Enviar'
                :icon='mdiCheckBold'
                event='click'
                width="100%"
                @click="enviarComando"
              />
            </div>
            <div class="col-sm-12 nopadding row legendaModal">
              <div class="col-sm-3">
                <baseIcon 
                  size='18' 
                  :icon="mdiClockTimeFourOutline"
                  style="color: gray;"
                />
                <span class='textoModal'>Pendente</span>
              </div>
              <div class="col-sm-3">
                <baseIcon 
                  size='18' 
                  :icon="mdiShare"
                  style="color: #6c8bca;"
                />
                <span class='textoModal'>Enviado</span>
              </div>
              <div class="col-sm-3">
                <baseIcon 
                  size='18' 
                  :icon="mdiCheckBold"
                  style="color: green;"
                />
                <span class='textoModal'>Confirmado</span>
              </div>
              <div class="col-sm-3">
                <baseIcon 
                  size='18' 
                  :icon="mdiCloseThick"
                  style="color: red;"
                />
                <span class='textoModal'>Falha</span>
              </div>
            </div>
          </div>
          <div class="col-sm-12 row controlesModa nopadding mb-1">
            <div class="col-sm-6 nopadding tableText">
              Lista de comandos
            </div>
            <div class="col-sm-6 nopadding ">
              <datePicker
                name='afonso'
                label=''
                class='datePickerModal nopadding'
                @changeInput='mudaData'
              />
            </div>
          </div>
          <tableSimples id='oi'>
            <thead>
              <tr>
                <th>Comando</th>
                <th>Parâmetro</th>
                <th>Status</th>
                <th>Resposta</th>
                <th>Ultima atualização</th>
              </tr>
            </thead>
            <tbody>
              <br>
              <template v-if='dataModal.length > 0'>
                <tr v-for='(comando, index) in dataModal' :key='index'>
                  <td>{{comando.comando}}</td>
                  <td>{{comando.parametro}}</td>
                  <td>
                    <baseIcon 
                      :style="'color: '+comando.class"
                      size="18" 
                      :icon="verificaIcone(comando.status)"/>
                    
                  </td>
                  <td>{{comando.resposta}}</td>
                  <td>{{comando.ultimaAtualizacao}}</td>
                </tr>
              </template>
              <statusInformation
                v-else
                typeBar='tr'
                colspanForTd='5'
                :statusBar='sBarModal'
              />
            </tbody>
          </tableSimples>
        </b-overlay>
      </template>
      <template #modalFooter>
        <simpleButton
          text='fechar'
          :icon='mdiCloseThick'
          type='red'
          event='click'
          @click="closeModal"
        />
      </template>
    </modalEagle>
  </panelEagle>
</template>

<script>

  import Vue from 'vue'
  import Swal from 'sweetalert2'
  import { EmpresasService } from '@/Services/auth/Empresas.service'
  import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
  import titulo from '@/components/Atom/Header/Titulo'
  import selectAll from '@/components/Atom/Select/SelectAll'
  import botoesEIN from '@/components/Atom/Buttons/BasicButtonsCadastrosEIN'
  import panelEagle from '@/components/Atom/Panel/PanelEagle'
  import tableSimples from '@/components/Atom/Table/TableSimples'
  import simpleButton from '@/components/Atom/Buttons/SimpleButton'
  import statusInformation from '@/components/Atom/StatusInformation/StatusInformation'
  import datePicker from '@/components/Atom/Datas/InputSingleDay'
  import inputSimple from '@/components/Atom/Inputs/InputSimple'
  import { mapGetters } from 'vuex'
  import { 
    mdiSortVariantLock, 
    mdiCloseThick, 
    mdiCheckBold, 
    mdiProgressWrench, 
    mdiPencilLock, 
    mdiClockTimeFourOutline,
    mdiShare,
  } from '@mdi/js'
  export default Vue.extend({
    name:'GestaoDeCadeados',
    components: {
      inputSimple,
      datePicker,
      tableSimples,
      statusInformation,
      panelEagle,
      titulo,
      botoesEIN,
      selectAll,
      simpleButton,
      baseIcon   : require('@/components/Atom/Icon/BaseIcon.vue').default,
      modalEagle : require('@/components/Atom/Modal/ModalEagle').default,
    },
    data() {
      return {
        mdiShare:mdiShare,
        mdiClockTimeFourOutline:mdiClockTimeFourOutline,
        mdiPencilLock:mdiPencilLock,
        mdiProgressWrench:mdiProgressWrench,
        mdiCheckBold:mdiCheckBold,
        mdiCloseThick:mdiCloseThick,
        mdiSortVariantLock:mdiSortVariantLock,
        /**
         * Variaveis para a tabela
         * e para paginação
         */
        currentPage  : 1,
        itensPerPage : 5,
        tableFields  : [
          {key:'modulo',        label:'Serial',         sortable:true},
          {key:'placa',         label:'Cadeado',        sortable:true},
          {key:'prefixo',       label:'Prefixo',        sortable:true, class:'editavel'},
          {key:'ultimoComando', label:'Último Comando', sortable:true},
          {key:'empresa',       label:'Empresa',        sortable:true},
          {key:'bateria',       label:'Status Bateria', sortable:true},
          {key:'ultimoEvento',  label:'Último Evento',  sortable:true},
          {key:'acoes',         label:'Ações',          sortable:false},
        ],
        /**Coisas da página */
        carregando        : false,
        optSeletorEmpresa : new EmpresasService().Get(),
        uriBase           : '/cadeados/gestao/cadeados/',
        tableData         : [],
        ultimaAtt         : '',
        sBar              : 'info',
        busca :'',
        //coisas da modal
        dataModal     : [],
        optCartoes    : [],
        sBarModal     : 'info',
        carregandoModalComandos : false,
        loadingCartoes : false,
        optTipoComando: [
          {'value' : 1, 'description' : 'Adicionar cartão'},
          {'value' : 2, 'description' : 'Remover cartão'},
          {'value' : 3, 'description' : 'Listar cartoes'},
          {'value' : 4, 'description' : 'Abrir cadeado'}
        ],
        currentTitle : '',
        currentDate  : '',
        lastAttrModal: '',
        comandoQ     : '',
        cartaoQ      : '',
        currentLock  : [],
        //para controle dos intervalos:
        intAttModal  :undefined,
        intAttMain   :undefined,

        tabelaSemFiltro:[],
        valueInputFiltro:'',
        valueEmpresa:[],
        carregandoE :[false, false, false],
        selectedEmpresa: [],
      }
    },
    methods: {
      ...mapGetters(['getMaster']),

      changeFiltro(value){
        this.tableData = this.tabelaSemFiltro
        var filtro = this.tableData.filter(
          (f) => String(f.placa).toLowerCase().includes(value.toLowerCase())        || 
                 String(f.modulo).toLowerCase().includes(value.toLowerCase())       || 
                 String(f.empresa).toLowerCase().includes(value.toLowerCase())      || 
                 String(f.prefixo).toLowerCase().includes(value.toLowerCase())      || 
                 String(f.bateria).toLowerCase().includes(value.toLowerCase())      || 
                 String(f.ultimoEvento).toLowerCase().includes(value.toLowerCase()) ||
                 String(f.ultimoComando).toLowerCase().includes(value.toLowerCase())  
        );
        this.tableData = filtro
        // bateria
        // empresa
        // modulo
        // placa
        // prefixo
        // prefixoEdicao
        // ultimoComando
        // ultimoEvento
        // terminar o filtro
      },
      /**
       * ve qual icone vem e retorna ele
       */
      verificaIcone(icone){
        switch (icone) {
          case 'mdiClockTimeFourOutline': return this.mdiClockTimeFourOutline;
          case 'mdiShare':                return this.mdiShare;
          case 'mdiCheckBold':            return this.mdiCheckBold;
          case 'mdiCloseThick':           return this.mdiCloseThick;
          default: return '';
        }
      },

      fetchAttModal(){
        var uri = this.uriBase+'fetchAtt/modal'
        var obj = {
          'ultimaAtt':this.getUltimaAtt(this.lastAttrModal),
          'modulo':this.currentLock.codigo
        }
        new HttpRequest().Post(uri, obj)
        .then(data=>{
          if(data.status)
            if(data.data.att)
              this.procuraInfoSobreCadeado()
        })
      },

      /**
       * Metodos modal
       */
      selecionaCartao(arr, value){
        this.cartaoQ = ''
        if(arr.length > 0)
          this.cartaoQ = value
      },

      selecionaComando(arr, value){
        this.$refs.seletorCartao.clearAll()
        this.optCartoes = []
        this.comandoQ = ''
        if (arr.length >0){
          this.comandoQ = value
          this.getCartoes();
        }
      },

      enviarComando(){
        this.$refs.seletorCartao.clearAll()
        var obj = {
          'comandoTxt' :this.defineComandoTexto(),
          'cadeado'    :this.currentLock.codigo,
          'cartao'     :this.cartaoQ
        }
        let uri = this.uriBase+'comando'
        new HttpRequest().Post(uri, obj)
        .then(data=>{
          if(data.status)
            this.procuraInfoSobreCadeado()
          else
            this.showSwell()
        })
      },

      defineComandoTexto(){
        switch(this.comandoQ){
          case 1:
            return 'adicionarCartao'
          case 2:
            return 'removerCartao'
          case 3:
            return 'listarCartao'
          case 4:
            return 'abrirCadeado'
        }
      },

      listarComandos(index){
        this.currentLock = this.tableData[index]
        this.currentTitle = 'Comandos | Serial '+this.currentLock.modulo
        this.openModal()
      },

      openModal(){
        this.$bvModal.show('modalComandos')
        this.comandoNaoUsaCartao
      },

      closeModal(){
        this.controleIntervalo('modal', 'destruir')
        this.currentLock = []
        this.dataModal = []
        this.optCartoes = []
        let obj = {'clientes' : this.valueEmpresa}
        this.procuraCadeados(obj)
        this.$bvModal.hide('modalComandos')
      },

      mudaData(value){
        this.currentDate = []
        if(value !== undefined){
          this.currentDate = value
          this.procuraInfoSobreCadeado()
        }
      },

      /**
       * Para os comandos na modal que precisam de um cartão
       * (opt 1 e 2)
       */
      getCartoes(){
        this.loadingCartoes = true
        var obj = { 'veiculo': this.currentLock.codigo }
        new HttpRequest().Post(this.uriBase+'cartoes', obj)
        .then(data=>{
          if (data.status){
            if(data.data.length > 0 )
            this.loadingCartoes = false
            this.optCartoes = data.data
          }
          this.loadingCartoes = false
        })
      },
      
      procuraInfoSobreCadeado(){
        this.lastAttrModal = new Date()
        this.dataModal = []
        this.carregandoModalComandos = true
        var obj = {
          'veiculo' : this.currentLock.codigo,
          'data'    : this.currentDate
        }
        new HttpRequest().Post(this.uriBase+'info', obj)
        .then(data => {
          if(data.status){
            this.controleIntervalo('modal', 'criar')
            if(data.data.length > 0){
              this.dataModal = data.data
              this.sBarModal = 'info'
              this.carregandoModalComandos = false
            }
            else
            this.carregandoModalComandos = false
              this.sBarModal = 'error'
          }else{
            this.showSwell()
            this.closeModal()
          }
        })
      },
      
      /**
       * Metodos página principal
       */
      mudaEmpresaSelecionada(arr) {
        this.valueEmpresa = arr
        this.controleIntervalo('main', 'destruir')
        this.sBar = 'info'
        this.tableData = []
        let obj = { 'clientes' : arr }
        if(arr.length > 0)
          this.procuraCadeados(obj)
      },

      /**
       * Procura os cadeados da(s) empresa(s) selecionadas
       */
      procuraCadeados(obj){
        this.mudaCarregando()
        this.sBar = 'info'
        let uri = this.uriBase+'listar'
        new HttpRequest().Post(uri, obj)
          .then(data=>{
            this.mudaCarregando()
            if(data.status){
              if(data.data.length == 0)
                this.sBar = 'error'
              else{
                this.controleIntervalo('main', 'criar')
                this.tableData = this.arrayRelatorio(data.data)
                this.ultimaAtt = new Date()
                if(!this.exibirTable)
                  this.sBar = 'error'
              }
            }else{
              this.showSwell()
            }
            this.tabelaSemFiltro = this.tableData            
          })
      },
      confirmaEdicao(index){
        this.tableData[index].showInput = false
        this.tableData[index].prefixo = this.tableData[index].prefixoEdicao
        this.atualizaPrefixo(this.tableData[index].codigo,this.tableData[index].prefixo)
      },
      cancelarEdicao(index){
        this.tableData[index].showInput = false
        this.tableData[index].prefixoEdicao = this.tableData[index].prefixo
      },
      mudaCarregando(){
        this.carregando = !this.carregando
      },
      editarInLine(index){
        this.tableData[index].showInput = !this.tableData[index].showInput
      },

      /** 
       * Essa função envia as alterações de prefixo feitas
       * inline na tabela para o back-end
       */
      atualizaPrefixo(codV, novoP){
        var uri = this.uriBase+'atualizaprefixo'
        var obj = {
          'veiculo':codV,
          'novoPrefixo':novoP
        }
        new HttpRequest().Post(uri, obj)
      },

      /**Metodos variados */
      showSwell(msg=''){
        if(msg=='')
          msg = '<p>Algo de inesperado aconteceu</p><p>Tente novamente mais tarde</p>'
        Swal.fire({
          title : 'oops',
          icon  : 'error',
          html  : msg
        })
      },

      /**
       * Método que altera a array que vem do back-end
       * para adicionar algumas capacidades e facilitar
       * minha vida
       * @author Gui 🍺🍺
       */
      arrayRelatorio(data) {
        var arrR = data.map(cadeado => {
          var obj = {
            ...cadeado,
            'showInput':false,
            'prefixoEdicao':cadeado['prefixo']
          }
          return obj
        })
        return arrR 
      },

      exportar(tipo){
        this.setCarregando(tipo)
        var uri = this.uriBase+'exportar'
        var obj = {
          'tipo':tipo,
          'arrayDados':JSON.stringify(this.tableData)
        }
        new HttpRequest().Post(uri, obj)
        .then(data =>{
          if(data.status){
            var root =  process.env.VUE_APP_ROOT;
            window.open(root+'/'+data.data);
            this.setCarregando()
          }else{
            this.showSwell()
            this.setCarregando()
          }
        })
      },

      setCarregando(type=null){
        if(type == 'pdf'){
          this.carregandoE = [true, false, false]
        }else if(type == 'xls'){
          this.carregandoE = [false, true, false]
        }else if(type == 'csv'){
          this.carregandoE = [false, false, true]
        }else{
          this.carregandoE = [false, false, false]
        }
      },

      getCodigos(){
        return this.tableData.map(el=>{
          return el.codigo
        })
      },

      /**
       * Essa função deve ser executada dentro de um
       * intervalo, é o que vai ficar buscando se aconteceu
       * alguma alteração nas informações no cadeado em questão
       */
      fetchAttMain(){
        var uri = this.uriBase+'fetchAtt'
        if(this.tableData.length > 0){
          var obj = {
            'ultimaAtt':this.getUltimaAtt(this.ultimaAtt),
            'modulos':this.getCodigos()
          }
          new HttpRequest().Post(uri, obj)
          .then(data=>{
            if(data.status)
              if(data.data.att)
                this.procuraCadeados()
          })
        }
      },
      /**
       * Essa função é utilizada para ter certeza que só vai
       * existir um intervalo para cada tabela(main e modal)
       * e também serve para garantir que quando o usuário sair
       * da página, esses intervalos serão destruido
       * É importante que as variáveis intAttMain e intAttModal só
       * sejam acessadas atravez dessa função
       * @param qual qual intervalo sera editado.
       * -> Por só existirem 2 possibilidades aqui, 
       * eu deixei como 'main' para o intervalo principal e para a modal
       * sempre que cair no else.
       * @param func criar para gerar o intervalo
       * -> De novo, só duas possíbilidades, então o intervalo vai 
       * ser destruido sempre que cair no else 
       * @param inter tamanho do intervalo, em milesegundos
       * @author Gui 🍺
       */
      controleIntervalo(qual, func, inter=30000){
        if(qual == 'main'){
          if(func == 'criar'){
            //criar Intervalo main
            if(this.intAttMain === undefined)
              this.intAttMain = setInterval(
                this.fetchAttMain, 
                inter
              )
          }else{
            //destruir intervalo main
            if(this.intAttMain !== undefined){
              clearInterval(this.intAttMain)
              this.intAttMain = undefined
            }
          }
        }else{
          if(func == 'criar'){
            //criar intervalo da modal
            if(this.intAttModal === undefined)
              this.intAttModal = setInterval(
                this.fetchAttModal,
                inter
                )
          }else{
            //destruir intervalo da modal
            if(this.intAttModal != undefined){
              clearInterval(this.intAttModal)
              this.intAttModal = undefined
            }
          }
        }
      },
      /**
       * Não é a coisa da qual eu mais me orgulho
       * Mas essa função serve para pegar o objeto Date
       * e transformar em uma string com a data 
       * no formado "certo"
       * @param att Objeto de data
       */
      getUltimaAtt(att){
        return att.toLocaleDateString()+' '+att.toLocaleTimeString()
      },
    },

    /**
     * Usando o beforeDestroy para ter certeza
     * que os intervalos seram destruidos antes
     * do usuário sair da página, já que do contrário
     * as requests continuam sendo feitas
     */
    beforeDestroy(){
      this.controleIntervalo('main', 'destruir')
      this.controleIntervalo('modal', 'destruir')
    },
    computed:{
      exibirTable(){
        return !!this.tableData.length
      },
      tableSize(){
        return this.tableData.length
      },

      /**
       * Os únicos comandos que usam cartão
       * são os de adicinar e de remover cartões
       * @author Gui 🍺🍺
       */
      comandoNaoUsaCartao(){
        if(this.comandoQ == '1'){
          return false
        }
        if(this.comandoQ == '2'){
          return false
        }
        return true
      },

      /**
       * Comando invalido SE:
       * 1 - Nenhum comando selecionado
       * 2 - Comando que usa cartão e não tem um cartão selecionado
       * @author Gui 🍺🍺
       */
      comandoInvalido(){
        if(this.comandoQ == ''){
          return true
        }else if(this.comandoNaoUsaCartao){
          return false
        }else if(this.cartaoQ != ''){
          return false
        }else{
          return true
        }
      }
    },

    mounted() {
      if(!this.getMaster()){
        this.selectedEmpresa = this.optSeletorEmpresa
      }
    }
  })
</script>
        

<style lang="scss">
#gestaoDeCadeados{
  li {
    padding: 2px;
    border: none !important;
  }
  th{
    text-align: left !important;
    padding-left: 1% !important;
  }
  input{
    width: 100%;
  }
  .tableGestao{
    td{
      min-width: 70px;
      text-align: left;
      .editavel{
        min-width: 100px !important;
        max-width: 100px !important;
      }
    }
  }
  .inputTable{
    max-width: 100px;
  }
}
  /**
    estilos da modal
   */
  .controlesModal{
    background-color: #f5f5f5;
    // margin-bottom: -2%;
  }
  .divBotaoModal{
    margin-top: 3.5% ;
  }
  .legendaModal{
    margin: 2%;
    background: #dae1e9;
    text-align: center;
    padding-left: 10%;
    div{
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
  .textoModal{
    vertical-align: middle;
    padding-top: 5px;
    color: black;
  }
</style>