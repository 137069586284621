<template>
    
    <div id="timePorPonto" 
        :class="'col-sm-12 timePorPonto pr-0 pl-0 border-bottom-'+dados.statusChegadaPonto" 
        :style="'width:'+tamanho+'%'">

        <div class="col-sm-12 row aling-rigth pr-0 pt-1">
            <baseIcon
                :class="'iconPonto iconStatus-'+dados.statusPonto" 
                size='28' 
                :icon="dados.iconParada ? mdiMapMarkerCircle : mdiMapMarker "
                :id="'popover-'+dados.ponto"
            />

            <b-popover 
                :target="'popover-'+dados.ponto" 
                triggers="hover click"
                custom-class="popoverTamanho"
                placement="auto">
                <div class="col-sm-12 nopadding" style="display: inline-flex !important;">
                    <div style="width:100%" v-html="html"></div>
                    <!-- v-if="dados.temDocumento" -->
                    <button 
                        :disabled="loading"
                        class="btn-eye-ponto"
                        event='click'
                        @click='buscaDocumentos(dados.ponto)'>
                        <baseIcon :icon="mdiEyePlusOutline" :size='20'/>
                    </button>
                </div>
            </b-popover>

        </div>
        <div class="col-sm-12 row pt-2 horaPonto">
            {{horaChegada ? horaChegada : ''}}
        </div>

        <!-- v-if="habilitaModal" -->
        <b-modal 
            :size="'xl'" 
            :id="'modalDocsItemRota'+pontoAtual" 
            ref="modal" 
            title="Documentos">
            <slot name="modal-body">
                <b-overlay :show='loading'>
                    <b-tabs v-model="pagina">
                        <b-tab title="Documentos">
                            <div v-if="documentos.length"
                                class="col-sm-12 nopadding" 
                                style="height:470px!important">
                                <carrossel
                                    :id="'refCarouselDetalhesRota'"
                                    :dados="documentos">
                                </carrossel>
                            </div>
                            <div v-else class="pt-5 pb-3 text-center">
                                <h5>Nenhum documento encontrado</h5>
                            </div>
                        </b-tab>
                    </b-tabs>
                </b-overlay>
            </slot>
            <slot slot="modal-footer">
                <template>
                    <div>
                        <simpleButton 
                            text='Fechar'
                            type='red'
                            :icon='mdiCloseThick'
                            event='click'
                            @click='fecharModalDocumentos'/>
                    </div>
                </template>
            </slot>
        </b-modal>

    </div>
    
</template>

<script lang="js">
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { mdiMapMarker, mdiEyePlusOutline, mdiCloseThick, mdiMapMarkerCircle } from '@mdi/js'
import Vue from 'vue'
export default Vue.extend({
    name:'PontosRotas',
    components:{
        baseIcon:require('@/components/Atom/Icon/BaseIcon.vue').default,
		simpleButton:require('@/components/Atom/Buttons/SimpleButton').default,
        carrossel: require('@/components/Atom/SpecificComponents/DetalhesRota/CarrosselDocsItemRota').default,
    },

    props:{
        tamanho:{
            type: Number,
            required: true,
            default: 0,
        },
        dados: {
            type: Object,
            required: true,
            default: function(){return {}}
        },
    },

    data(){
        return{
            mdiMapMarkerCircle,
            mdiMapMarker,
            mdiEyePlusOutline,
            mdiCloseThick,
            horaChegada: '',
            pagina: 0,
            documentos: [],
            loading: false,
            pontoAtual: '',
            iconeParada: '',
        }
        
    },

    mounted(){
        this.$emit('pontoCriado', this)
        this.horaChegada = this.dados.chegada.split(" ");
        this.horaChegada = this.horaChegada[1]
    },

    methods:{

        /**
        * @listen openModalDIR - executado quando selecionado um documento da rota
        * @description Método para buscar os documentos das rotas ou itens rotas
        * @param {String} status - Tipo do documento (R para Rotas e IR para Itens Rotas)
        * @param {String} codigo - Código da rota ou item da rota
        * @return {Array} detalhesItemRota - Com todos os documentos 
        * @author Vitor Hugo 🐨
        **/ 
        async buscaDocumentos(codigo){
            this.$root.$emit('bv::hide::popover')
            this.loading = true
            this.pontoAtual = codigo
            this.$nextTick(() => {
                this.$bvModal.show('modalDocsItemRota'+this.pontoAtual)
            })
            let body = {'tipo': 'IR', 'codigo': codigo}
            let url = '/roteirizador/relatorios/detalhes/rota/buscar/documentos'
            await new HttpRequest().Post(url, body)
                .then(({status, data})=>{
                    let temDados = data.dados && data.dados.documentos
                    if(status && temDados){
                        this.documentos = data.dados.documentos
                        this.$nextTick(()=>{
                            this.$bvModal.show('modalDocsItemRota'+this.pontoAtual)
                        })
                    } else {
                        this.$bvModal.hide('modalDocsItemRota'+this.pontoAtual)
                        this.documentos = []
                        this.pontoAtual = ''
                        this.toastShow('Erro ao carregar arquivos', 'danger', 'Erro')
                    }
                })
                .catch((e)=>{
                    this.toastShow('Erro ao carregar arquivos', 'danger', 'Erro')
                })
                .finally(() => {
                    this.loading = false 
                })
        },

        fecharModalDocumentos(){
            this.$bvModal.hide('modalDocsItemRota'+this.pontoAtual)
        },

        /**
         * Monta o HTML padrão dos dados que sempre tem que 
         * aparecer na pop-up
        */
        montaPopUpPontoCodigoHoraKm(pontoDados) {
            let html = ''
            html += '<div style="width:100%"><b>Ponto</b>: '+pontoDados.nome+'</div>'
            html += '<div style="width:100%"><b>Código</b>: '+pontoDados.codigoEx+'</div>'
            html += '<div style="width:100%"><b>Hora prevista</b>: '+pontoDados.previsto+'</div>'
            let km = pontoDados.kmPercorrido ? pontoDados.kmPercorrido : '0'
            html += '<div style="width:100%"><b>Km</b>: ' + (km) + ' Km</div>'
            html += '<div style="width:100%" class="text-truncate nopadding" title=""><b>Chegada: </b>'+ pontoDados.chegada +'</div>'

            return html
        }, 

    
        montaHtmlPontoColetado(pontoColetado) {
        
            let html = ''
            html += '<div class="text-truncate nopadding" title="'+this.dados.observacoes+'"><b>Obs: </b>'+ this.dados.observacoes +'</div>'
            if (pontoColetado.length > 0) {

            html += '<div class="tabeladiv">' +
                '<div class="linhah">' +
                '<div class="celula"><b>Tipo Coleta</b></div>' +
                '<div class="celula"><b>Qtd.</b></div>' +
                '<div class="celula"><b>Unid.</b></div>' +
                '<div class="celula"><b>Obs Motorista</b></div>' +
                '</div>' +
                '<br>';

                html += pontoColetado.map(function (item) {
                    return '<div class="linhac">' +
                        '<div class="celula text-truncate nopadding" title="' + item.descricao + '">' + item.descricao + '</div>' +
                        '<div class="celula text-truncate nopadding" title="' + item.quantidade + '">' + item.quantidade + '</div>' +
                        '<div class="celula text-truncate nopadding" title="' + item.unidade + '">' + item.unidade + '</div>' +
                        '<div class="celula text-truncate nopadding" title="' + item.observacao + '">' + item.observacao + '</div>' +
                        '</div>';
                }).join('');
            }
            html += '<div style="display:block; clear:both"></div>'
            html += '</div>'
            return html
        },
        
       

        /**
         * Caso tenha não tenha ocorrido uma entrega ou coleta,
         * monta o HTML da justificativa e observação motorista
         */
        montaHtmlPontoJustificado(pontoDados) {
            let html = ''
            html += '<div style="width:100%" class="text-truncate nopadding" title="'+pontoDados.justificativa+'"><b>Justificativa</b>: '+pontoDados.justificativa+'</div>'
            html += '<div style="width:100%" class="text-truncate nopadding" title="'+pontoDados.obsMotoristaIR+'"><b>Obs Motorista</b>: '+pontoDados.obsMotoristaIR+'</div>'
            return html
        },

        montaHtmlTempoParadoChegada(pontoDados) {
            let html = ''
            html += '<div style="width:100%" class="text-truncate nopadding" title="'+pontoDados.parado+'"><b>Tempo parado</b>: '+pontoDados.parado+'</div>'
            //html += '<div class="text-truncate nopadding" title="'+pontoDados.chegada+'"><b>Chegada</b>: '+pontoDados.chegada+'</div>'
            html += '<div style="width:100%" class="text-truncate nopadding" title="'+pontoDados.observacoes+'"><b>Obs </b>: '+pontoDados.observacoes+'</div>'
            html += '<div style="width:100%" class="text-truncate nopadding" title="'+pontoDados.obsMotoristaIR+'"><b>Obs. Motorista </b>: '+pontoDados.obsMotoristaIR+'</div>'
            
            return html
        },
        
        toastShow(msg, type, titulo){
            this.$bvToast.toast(`${msg}`, {
                autoHideDelay: 2500,
                variant: type,
                title: titulo,
            })
        },
    },
    
    computed:{

        html(){
            const pontoDados = this.dados
            let html = ''
            html += this.montaPopUpPontoCodigoHoraKm(pontoDados)
            if (pontoDados.tipocoleta) {
                const pontoColetado = pontoDados.pontoColeta
                if(pontoColetado) {
                    html += this.montaHtmlPontoColetado(pontoColetado)
                    if (!pontoColetado && !['D', 'F'].includes(pontoDados.status)){
                        html += this.montaHtmlPontoJustificado(pontoDados)
                    }else if(['P', 'J'].includes(pontoDados.status)) {
                        const pontoJustificado = 'J'
                        if (pontoDados.status == pontoJustificado) {
                            html += this.montaHtmlPontoJustificado(pontoDados)
                        } else {
                            html += '<div>'+pontoDados.mensagem+'</div>'
                        }
                    }
                } else if (!pontoColetado && !['D', 'F'].includes(pontoDados.status)){
                    html += this.montaHtmlPontoJustificado(pontoDados)
                }
            } else {
                if(['D', 'F'].includes(pontoDados.status)) {
                    html += this.montaHtmlTempoParadoChegada(pontoDados)
                } 
                else if(['P', 'J'].includes(pontoDados.status)) {
                    const pontoJustificado = 'J'
                    if (pontoDados.status == pontoJustificado) {
                        html += this.montaHtmlPontoJustificado(pontoDados)
                    } else {
                        html += '<div>'+pontoDados.mensagem+'</div>'
                    }
                }
            }
            return html
        },
        
    }
})
</script>

<style scoped>
.popoverTamanho{
    min-width: 350px;
}
</style>


<style lang="scss" scoped>

    .tabeladiv{
        background-color:rgb(255, 145, 145);
        width: 100% !important;
        margin: 0px;
        padding: 0px;
    }

    .linhah{   
        background-color: #e2dddd;
        float: left;
        height: 20px;
        width: 100%;
        display: block;
        margin: 0px;
        padding: 0px;
    }

    .linhac{   
        background-color: #ffffff;
        float: left;
        height: 20px;
        width: 100%;
        display: block;
        margin: 0px;
        padding: 0px;
        border-bottom: 1px solid #e2dddd;
        border-left: 1px solid #e2dddd;
        border-right: 1px solid #e2dddd;
    }

    .celula{
        float: left;
        width: 25%;
        margin: 0px;
        padding: 0px;
        text-align: center;
   }

    .timePorPonto{
        vertical-align: top;
        height: 35px;
        display: inline-block;
        padding: 0px;
        max-width: 95.15px !important;
        position: relative;
        border-bottom: 4px solid #d5d5d5;
        .horaPonto{
            height: 25px;
            text-align: right;
        }
        .aling-rigth{
            padding-left: 32px;
        }
    }
    .border-bottom-red{
        border-bottom: 4px solid #e7aaad !important;
    }
    .border-bottom-blue{
        border-bottom: 4px solid #acc9e3 !important;
    }
    .border-bottom-green{
        border-bottom: 4px solid #95b65c !important;
    }
    .iconStatus-blue{
        color: #428BCA!important;
    }
    .iconStatus-green{
        color: #95b65c!important;
    }
    .iconStatus-orange{
        color: #F0AD4E!important;
    }
    .iconStatus-red{
        color: #E04B4A!important;
    }
    .iconPonto{
        top: 3px!important;
        right: 30px;
        float: right;
        &::before{
            margin-left: 0;
        }
    }
    .btn-eye-ponto{
        position: absolute;
        right: 0;
        background: none;
        border: none;
        width: 28px;
        height: 28px;
        padding: 0;
        margin-top: -5px;
        margin-right: -5px;
    }

</style>








































































