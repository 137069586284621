<template>
    <div 
    v-if="showToastUpdate && visibleToast" 
    class="customToast"> 
        <b-button 
        style="float: right;"
        variant="link"
        title='Fechar'
        @click="closeToast()">
            <base-icon
            :icon='mdiCloseThick'
            :size="14"/>
        </b-button>
        <div class="divBodyToast">
            <img 
                src="../../../../public/img/atualizarPagina.png" 
                alt="" 
                width="90px" 
                height="90px">
            <div style="display: block">
                <p class="textTitle">
                    O SISTEMA FOI ATUALIZADO!
                </p>
                <p class="textDescription">
                    Recarregue a página para visualizar a nova versão.
                </p>
            </div>
        </div>
        <div class="row divBtnAtualizar">
            <b-button 
            class="btnAtualizar"
            title='Atualizar'
            @click="setVersion()">
                <div style="color:white">
                    ATUALIZAR
                </div>
            </b-button>
        </div>
        <div class="textVersion">
            {{versaoAtual ? 
                `versão ${versaoAtual} => ${versaoRecente}`
                : versaoRecente}}
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mdiCloseThick } from '@mdi/js'; 
import BaseIcon from '@/components/Atom/Icon/BaseIcon.vue'
import store from '@/store/index';
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { DateTime, Duration } from 'luxon'
import { mapGetters } from 'vuex';

export default Vue.extend({
	name: 'toastcustom',
	components: {
		BaseIcon,
	},
	data() {
		return {
			mdiCloseThick: mdiCloseThick,
			visibleToast: true,
			intervalo: null
		}
	},
	created() {
		if(!this.versaoAtual) {
			this.consultarVersao()
		} 
		this.intervaloVersao()
	},
	methods: {
        
		/**
         * Função que salva a versão do projeto no localStorage
         ** Key => 'versaoAtual'
         * Opção 1: this.$router.go(0)
         * Opção 2: window.location.reload(true)
         * Opção 3: location.reload(true) com this.$forceUpdate()
         * @author Rafael | Lucas Eduardo
         * Bom, pelo oq eu vi da pra usa o parametro de 
         * forceGet no location.reload(). É um param
         * que funciona no Firefox para fazer o nav
         * ignorar o cache.
         * Como isso é "garantido" de q n funfa
         * no chrome, vou tentar usar umas func de controlar
         * a url para reloadar a pagina mais de uma vez.
         * pra tentar garantir  que essa praga vai reloadar 
         * like it or not >:(
         */
		async setVersion() {
			let versao = this.versaoRecente ? this.versaoRecente : ''
            localStorage.setItem('versaoAtual', versao)
			localStorage.setItem('versaoRecente', versao)
			store.dispatch('refresh')
			this.intervaloVersao()
            this.$forceUpdate()
            this.emitirAcaoReload()
        },

        emitirAcaoReload() {
            this.$emit('reload')
        },

		/**
         * @listens closeToast - Executado quando o usuário fecha o toast
         * @description Método para fechar o toast, também salva no localStorage
         *  a data que foi fechado
         * @author Rafael, alterado pelo Vitor Hugo 🐨
         */
		closeToast() {
			this.visibleToast = false
			var dataToast = DateTime.now()
			localStorage.setItem('dataToast', dataToast)
			this.verificaVersao()
			this.intervaloVersao(true)
		},
        
		async consultarVersao() {
			var data = await new HttpRequest().Post('/versao/sistema')
			let versao = data.data ? data.data : ''
			localStorage.setItem('versaoAtual', versao)
			localStorage.setItem('versaoRecente', versao)
			store.dispatch('refresh')
		},

		/**
         * @description Método calcula a diferença de tempo da data
         *  em que o toast foi fechado para a data atual, se essa diferença
         *  de tempo for maior que 1 hora o toast deverá aparecer novamente
         * @author Vitor Hugo 🐨
         */
		verificaVersao(){
			var dataAtual = DateTime.now()
			var dataFechouToast = DateTime.fromJSDate(
				new Date(localStorage.getItem('dataToast')))
			var diffTempo = dataAtual.diff(
				dataFechouToast, ['hours', 'minutes', 'second']).toObject()
			if(diffTempo.hours > 0){
				this.visibleToast = true
			}
		},

		intervaloVersao(criarNovo = false) {
			if(this.intervalo) {
				clearInterval(this.intervalo)
			}
			let fiveMinutes = (5 * 60) * 1000 // cinco minutos
			if(criarNovo) {
				this.intervalo = setInterval(() => {
					this.verificaVersao()
				}, fiveMinutes)
			}
		},

		...mapGetters(['getVersao', 'getVersaoRecente']),

	},
	computed: {

		/**
         * @description Variável computada para verificar se 
         *  aconteceu alteração de versão
         * @return {boolean} - Se aconteceu alteração de versão
         * @author Vitor Hugo 🐨
         */
		showToastUpdate() {
			return this.versaoAtual != this.versaoRecente && this.versaoAtual != ''
		},

		/**
         * @description Variável computada para atualizar a versão
         *  Pega valor do localStorage através do vuex
         *  É a última versão do sistema
         * @return {string} - Última versão do sistema
         * @author Vitor Hugo 🐨
         */
		versaoRecente(){
			return this.getVersaoRecente()
		},

		/**
         * @description Variável computada para atualizar a versão
         *  Pega valor do localStorage através do vuex
         *  É a versão em que o usuário esta no momento
         * @return {string} - Versão atual do usuário
         * @author Vitor Hugo 🐨
         */
		versaoAtual(){
			return this.getVersao()
		}
	},
});
</script>

<style lang="scss">
    .customToast {
        position: fixed;
        width: 320px;
        height: 185px;
        z-index: 1100;
        background-color: #212529;
        margin: auto 20px 20px auto;
        bottom: 2.5%;
        right: 0%;
        border: 1px solid #007bff;
        box-shadow: 1px 1px 1px #3b8adf;
    }
    .textTitle {
        text-align: left;
        font-weight: bold;
        padding: 5px 5px 0 0;
        font-size: 14px;
    }
    .textDescription {
        text-align: left;
        padding: 0 5px 5px 0;
        font-size: 14px;
        margin: 0;
    }
    .divBtnAtualizar {
        display: flex; 
        justify-content: center;
        .btnAtualizar {
            width: 100px;
            background-color: #007bff;
            opacity: 1;
            cursor: pointer;
            border-radius: 0px;
        }
    }
    .divBodyToast {
        display: inline-flex;
    }
    .textVersion {
        padding: 5px;
        font-size: 10px;
    }
</style>
