Eventos: expandir => evento propagado do filtro painel controle. -> ainda não tenho
certeza de sobre qual componente que essa tarefa deve recair, então por hora só esta
propagando o evento pra frente. localizaVeiculo => dispara esse evendo quando o usuário
clica no ícone mdiCrosshairs -> Evento traz um objeto com as propriedades de lat e long
Documentação table: https://element.eleme.io/#/en-US/component/table

<template>
    <div id="tablePainelControle">
        <filtros-painel-controle
            class-name="table-mapa"
            :clienteSelecionado="clienteSelecionado"
            :veiculoSelecionado="veiculoSelecionado"
            :loadingGrupo="loadingGrupo"
            ref="filtroPainel"
            :tags="arrayTags"
            :loadingEmpresa="requestControl"
            :title="requestControl ? 'Carregando informações' : ''"
            @filtrar-tag="filtrarPorTag"
            @limpar-rastros-gv="$emit('limpar-rastros-gv')"
            @criarLegenda="(v) => $emit('criarLegenda', v)"
            @filtrar="queryFiltrar"
            @mudaFiltros="mudaFiltros"
            @rastroGrupo="buscaRastroGrupo"
            @muda-cliente="mudaCliente"
            @expandir="emiteExpandir"
            @fecha-info="$emit('fecha-info'), (allSelect = 0)"
        >
        </filtros-painel-controle>
        <div :style="'max-heigth: ' + tamTable">
            <el-table
                :data="dadosFiltrados"
                :max-height="tamTable"
                :border="true"
                v-show="dadosFiltrados.length"
                :row-style="tableRowStyle"
                ref="tablePainel"
                :highlight-current-row="true"
                :row-key="keyFiltrados"
                @select="selectRow"
                @select-all="selectAll"
                :select-on-indeterminate="false"
            >
                <el-table-column type="selection" min-width="55" />
                <el-table-column align="center" min-width="55" label=" ">
                    <span
                        slot-scope="{ row }"
                        class="iconeClicavel"
                        @click="localizar(row)"
                        event="click"
                    >
                        <base-icon :icon="iconLocal" size="17" />
                    </span>
                </el-table-column>
                <el-table-column
                    label="Placa"
                    align="center"
                    prop="placa"
                    min-width="100"
                    :sortable="true"
                />
                <el-table-column
                    label="Prefixo"
                    :sortable="true"
                    align="center"
                    :show-overflow-tooltip="true"
                    min-width="100"
                    prop="prefixo"
                />
                <el-table-column v-if="temTag" width="150" label="Tags">
                    <div
                        slot-scope="{ row }"
                        :id="`linha_veiculo_${row.veiculoId}`"
                        class="lista-tags"
                    >
                        <div
                            v-for="i in row.tags"
                            :key="`tag_${i.vtcodigo}_v_${row.veiculoId}_painel`"
                        >
                            <div
                                class="badge-tag"
                                v-text="i.tadescricao"
                                :style="{ background: i.tacor }"
                            ></div>
                        </div>
                        <b-popover
                            v-if="row.tags.length"
                            :target="`linha_veiculo_${row.veiculoId}`"
                            :ref="`popOver_${row.veiculoId}`"
                            triggers="hover"
                        >
                            <template #title>Tags</template>
                            <div
                                :style="{
                                    display: 'flex',
                                    'flex-direction': 'row',
                                    gap: '2px',
                                    'flex-wrap': 'wrap',
                                    width: '250px',
                                }"
                            >
                                <div
                                    v-for="tag in row.tags"
                                    :key="`tag_${tag.vtcodigo}_v_${row.veiculoId}_1`"
                                    class="badge-tag"
                                    :title="tag.tadescricao"
                                    :style="{
                                        background: tag.tacor,
                                        'border-radius': '0px 5px 0px 5px',
                                        color: 'white',
                                        padding: '3px',
                                    }"
                                >
                                    {{ tag.tadescricao }}
                                </div>
                            </div>
                        </b-popover>
                        <b-popover
                            v-if="row.tags.length"
                            :target="`marker_veiculo_${row.veiculoId}`"
                            placement="top"
                            :ref="`popOver_${row.veiculoId}`"
                            triggers="hover"
                        >
                            <template #title>Tags</template>
                            <div
                                :style="{
                                    display: 'flex',
                                    'flex-direction': 'row',
                                    gap: '2px',
                                    'flex-wrap': 'wrap',
                                    width: '250px',
                                }"
                            >
                                <div
                                    v-for="tag in row.tags"
                                    :key="`tag_${tag.vtcodigo}_v_${row.veiculoId}_2`"
                                    class="badge-tag"
                                    :title="tag.tadescricao"
                                    :style="{
                                        background: tag.tacor,
                                        'font-size': '14px !important',
                                        'border-radius': '0px 5px 0px 5px',
                                        color: 'white',
                                        padding: '3px',
                                        'user-select': 'none',
                                    }"
                                >
                                    {{ tag.tadescricao }}
                                </div>
                            </div>
                        </b-popover>
                    </div>
                </el-table-column>
                <el-table-column
                    label="Descrição"
                    :sortable="true"
                    :show-overflow-tooltip="true"
                    min-width="160"
                    :resizable="true"
                    align="center"
                    prop="descricao"
                />
                <el-table-column
                    align="center"
                    :sortable="true"
                    label="Ignição"
                    prop="ignicao"
                    min-width="90"
                >
                    <baseIcon slot-scope="{ row }" v-bind="defineIgnicao(row)" />
                </el-table-column>
                <el-table-column
                    :sortable="true"
                    label="Km/h"
                    align="center"
                    prop="velocidade"
                    :show-overflow-tooltip="true"
                    min-width="90"
                />
                <el-table-column
                    :sortable="true"
                    label="Comunicou-se á"
                    :show-overflow-tooltip="true"
                    align="center"
                    prop="dataEventoPadraoAmericano"
                    min-width="180"
                >
                    <template slot-scope="{ row }">
                        <span
                            v-text="row.dataEventoDescricao"
                            style="font-size: 11px"
                            :class="row.semcomunicacao ? 'com-antiga' : ''"
                            :title="defineTitlePosicao(row)"
                        />
                        <baseIcon :size="18" v-bind="defineIconePos(row)" />
                    </template>
                </el-table-column>
                <el-table-column
                    label="Referência"
                    :show-overflow-tooltip="true"
                    :sortable="true"
                    min-width="220"
                    align="center"
                >
                    <div
                        slot-scope="{ row }"
                        class="wordWrap"
                        v-text="row.moultimareferencia"
                        :title="row.moultimareferencia"
                    />
                </el-table-column>
                <el-table-column
                    label="Cidade/UF"
                    prop="cidade"
                    :show-overflow-tooltip="true"
                    align="center"
                    :sortable="true"
                    min-width="160"
                />
                <el-table-column
                    v-if="vermotora"
                    label="Mot.|Aj. (bordo)"
                    align="center"
                    :sortable="true"
                    :show-overflow-tooltip="true"
                    min-width="200"
                >
                    <template slot-scope="{ row }">
                        <div class="d-flex justify-content-between">
                            <div
                                :key="row.veiculoId + '_mot_aju'"
                                v-b-popover.hover.html="popOverMotorista(row)"
                                title="Detalhes colaborador a bordo"
                                class="wordWrap"
                            >
                                {{ formataMotoristaAjudante(row) }}
                            </div>
                            <b-button
                                v-if="mudamotora"
                                size="sm"
                                variant="primary"
                                class="botaoMudaMotorista"
                                @click="editarMotorista(row)"
                            >
                                <baseIcon :icon="iconEditar" :size="14" />
                            </b-button>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    v-if="vermotora"
                    label="Ultimo Mot."
                    min-width="100"
                    :show-overflow-tooltip="true"
                    align="center"
                >
                    <div
                        class="wordWrap"
                        :title="row.veultimomotorista"
                        v-text="defineUltimoMotorista(row)"
                        slot-scope="{ row }"
                    />
                </el-table-column>
            </el-table>
        </div>
        <div v-show="!tableData.length" class="statusBar">
            <status-information typeBar="div" :statusBar="info" :msgCentro="msgCentro" />
        </div>
        <modal-edita-motorista
            ref="editarMotorista"
            v-bind="editavel"
            idModal="editarVeiculo"
            @motoristAtualizado="atualizaPainel()"
        >
        </modal-edita-motorista>
        <b-toast
            v-model="showToast"
            :no-auto-hide="true"
            toaster="b-toaster-bottom-right"
            :key="panico.alcodigo + '__panico_painel'"
            variant="danger"
            v-for="panico in panicos"
            :append-toast="true"
            @hide="trataPanico(panico)"
        >
            <template #toast-title> PÂNICO ATIVADO </template>
            <div class="d-flex">
                <div>
                    <base-icon size="42" :icon="iconPanico" />
                </div>
                <div class="d-flex flex-column mx-4">
                    <div v-text="placaEprefixo(panico)" />
                    <div v-text="dataEhora(panico)" />
                </div>
            </div>
        </b-toast>
    </div>
</template>

<script>

import { mapGetters } from "vuex";
import { HttpRequest } from "@/Services/auth/HttpRequest.Service";
import {
    mdiCheckCircle,
    mdiCloseCircle,
    mdiSim,
    mdiSimOff,
    mdiSatelliteVariant,
    mdiCrosshairsGps,
    mdiShieldAlert,
    mdiPencilOutline,
} from "@mdi/js";
import FiltrosPainelControle from "@/components/Atom/Painel/FiltrosPainelControle.vue";
import baseIcon from "@/components/Atom/Icon/BaseIcon.vue";
import ModalEditaMotorista from "@/components/Atom/Painel/ModalEditaMotorista.vue";
import StatusInformation from "@/components/Atom/StatusInformation/StatusInformation.vue";
import { DateTime } from "luxon";
import Vue from "vue";
import { Veiculo } from "@/views/Mapa/models";

export default Vue.extend({
    name: "painelControle",
    props: {
        permissoes: {
            type: [Object, Array],
            required: true,
        },
        veiculoSelecionado: {
            type: String,
            default: "",
        },
        clienteSelecionado: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },

    components: {
        FiltrosPainelControle,
        baseIcon,
        StatusInformation,
        ModalEditaMotorista,
    },
    data() {
        return {
            msgCentro: "Selecione uma empresa",
            tamTable: 200,
            filtros: {
                cliente: [],
                grupoVeiculo: [],
                ignicao: [],
                filtrarTag: [],
            },
            arrayTags: [],
            iconEditar: mdiPencilOutline,
            iconLocal: mdiCrosshairsGps,
            uriBase: "/finder/painel/",
            tableData: [],
            info: "info",
            editavel: {
                veiculo: "",
                cliente: "",
                motAtualId: "",
                ajuAtualId: "",
                veiculoId: "",
            },
            query: "",
            loadingGrupo: false,
            interPainel: "",
            attInterval: 30000,
            rastroEncontrado: false,
            panicos: [],
            iconPanico: mdiShieldAlert,
            showToast: true,
            obj: [],
            allSelect: 0,
            requestControl: false,
        };
    },

    methods: {
        mudaCliente(v) {
            this.arrayTags = [];
            this.query = ""
            this.$emit("muda-cliente", v);
        },
        toggleSelect() {
            this.$refs.tablePainel.toggleAllSelection();
        },

        filtrarPorTag(filtros) {
            this.filtros = filtros;
            this.atualizaPainel();
        },

        abrePopoverTags(row) {
            this.$refs[`popOver_${row.veiculoId}`].doOpen();
        },

        /**
         * @param {object} obj
         * @param {string|null} obj.veultimomotrista
         * @return {string}
         */
        defineUltimoMotorista({ veultimomotorista }) {
            return veultimomotorista ?? "Nenhum";
        },

        trataPanico(panico) {
            if (this.podeTratarPanico) {
                var uri = `${this.uriBase}panico/${panico.alcodigo}`;
                new HttpRequest().Get(uri).then((data) => {
                    this.panicos.findIndex((p) => {
                        return p.alcodigo === data.data.alcodigo;
                    });
                    this.panicos.splice(index, 1);
                });
            }
        },

        placaEprefixo({ veplaca, veprefixo }) {
            return `${veplaca} || ${veprefixo}`;
        },

        dataEhora({ data, hora }) {
            return `${data} ${hora}`;
        },

        /**
         * @description atualiza os dados dos veículos baseado em um timer, pra
         * pegar alterações nas informações dos veículos a medida que novos
         * pacotes são adicionados ao banco de dados.
         * @todo no momento eu to usando Object.keys pra alterar os dados,
         * dessa maneira garante que a tabela esta atualizada,
         * mas seria bom encontrar outra maneira.
         * @author Gui 🍺
         */
        async atualizaPainelIntervalo() {
            if (this.objPainel && !this.requestControl) {
                this.requestControl = true;
                await new HttpRequest()
                    .Post(this.uriBase + "atualizar/painel", {
                        ...this.objPainel,
                        att: true,
                    })
                    .then((data) => {
                        if (!data.status) return;
                        this.tableData.forEach((el, index) => {
                            if (this.$route.name !== "Finder") return;
                            let novo = new Veiculo(data.data.modulos[el.modulo]);
                            this.atualizaMarcadorVeiculo(novo);
                            if (data.data.panicos.length) {
                                this.emitePanicos(data.data.panicos);
                            }
                        });
                    })
                    .finally(() => {
                        this.requestControl = false;
                    });
            }
        },

        emitePanicos(panicos) {
            if (this.podeVerPanico) this.panicos = panicos;
        },

        /**
         * @fires update-veiculo
         * @param {object} marker - informações já preparadas para
         * montar o marcador no mapa.
         * @description dispara o evento para o mapa atualizar
         * as informações de um marcador
         */
        atualizaMarcadorVeiculo(marker) {
            this.$emit("update-veiculo", marker);
        },

        /**
         * @description Cria o conteúdo que aparece na popOver do campo mot.|Aju.
         * Devolve uma string contendo html.
         * @author Gui
         */
        popOverMotorista({ motorista, ajudante }) {
            let str_motoristas = motorista ? motorista : "Sem motorista";
            let str_ajudantes = ajudante ? ajudante : "Sem ajudante";
            return `<span style='font-weight: bold' >Motoristas:</span><br>
					<span>${str_motoristas}<span><br>
					<hr>
					<span style='font-weight:bold'>Ajudantes:</span><br>
					<span>${str_ajudantes}</span>`;
        },

        /**
         * @param {object} obj
         * @param {(string|null)} obj.motorista - vemotorista(nome)
         * @param {(string|null)} obj.ajudante  - veajudante(nome)
         * @description cria uma string para exibir o motorista/ajudante na jornada.
         * @returns {string} formatada p/ a tabela
         */
        formataMotoristaAjudante({ motorista, ajudante }) {
            let str_motoristas = motorista ? motorista : "Sem motorista";
            let str_ajudantes = ajudante ? ajudante : "Sem ajudante";
            return `${str_motoristas} - ${str_ajudantes}`;
        },

        /**
         * @listens select-all - emitido ao clicar no botão de
         * selecionar todos as linhas
         * verifica quando deve emitir todos os veículos da listagem ou
         * quando deve emitir array para deixar mapa vazio
         * @param {array} all - todos elementos da tabela
         * @fires allVeiculos
         */
        selectAll(all) {
            if (this.verveiculos) {
                all = Object.values(all);
                if (
                    all.length > 0 &&
                    (all.length >= this.allSelect || all >= this.allSelect)
                ) {
                    this.allSelect = all.length;
                    this.$emit("allVeiculos", this.dadosFiltrados);
                } else {
                    this.allSelect = 0;
                    this.$emit("allVeiculos", []);
                }
            }
        },

        /**
         * @listens select - emitido ao selecionar uma linha na tabela
         * @param {array} all  - todos os elementos selecionados
         * @param {object} row - linha selecionado
         * @author Gui 🍺
         */
        selectRow(all, row) {
            if (this.verveiculos) {
                if (all.includes(row)) {
                    this.emiteAddMarker(row);
                } else {
                    this.emiteRemMarker(row);
                }
            }
        },

        /**
         * @listens filtrar - emitidoao digitar no campo de busca do painel
         * @description Método para filtrar os veículos que aparecem no mapa
         *  primeiro são removidos todos os veículos do mapa e após inseridos
         *  somente os referêntes a pesquisa realizada
         * @param {string} value  - dados digitados
         * @author Vitor Hugo 🐨
         */
        queryFiltrar(value) {
            this.query = value;
            this.tableData.forEach((element) => {
                this.emiteRemMarker(element);
            });
            this.$refs.tablePainel.clearSelection();
            this.$nextTick(() => {
                this.dadosFiltrados.forEach((element) => {
                    this.$refs.tablePainel.toggleRowSelection(element, true);
                    this.emiteAddMarker(element);
                });
            });
        },

        /**
         * @description Método para pegar a chave dos objetos da tabela
         *  para conseguir utilizar a propriedade reserve-selection
         *  que armazena os dados selecionados na tabela é necessário
         * 	passar as chaves de cada objeto da tabela.
         * @param {object} row  - dados do objeto da linha
         * @author Vitor Hugo 🐨
         */
        keyFiltrados(row) {
            return row.veiculoId + "_row_key";
        },

        /** emite um único marcador para ser removido de veiculo */
        emiteRemMarker(row) {
            this.$emit("remMarker", row.veiculoId);
        },

        /**
         * @description Emite o evento para adicionar um
         * marker no mapa, só envia as informações necessárias para
         * o marker funcionar
         * @fires addMarker
         */
        emiteAddMarker(row) {
            this.$emit("addMarker", row);
        },

        /**
         * @listens rastroGrupo
         * @description cria uma array com todos os veículos na tabela
         * (que devem ser só o grupo de veículos selecionados) e chama a função
         * que vai fazer as requests em si.
         * @param {object} filtrosRastro
         * @author Gui 🍺🍺
         */
        buscaRastroGrupo(filtrosRastro) {
            var veiculos = this.tableData.map((e) => {
                return e.placa;
            });
            this.buscaRastro(veiculos, filtrosRastro, true);
        },

        /**
         * @param {string[]} veiculos   - todas as placas ainda a serem enviadas
         * @param {object} filtros
         * @param {number} filtros.grupo- grupo selecionado
         * @param {string} filtros.data - periodo do rastro.
         * @param {boolean} reset - boolean emitido junto com os rastros,
         * se for true, avisa o mapa que os rastros anteriores devem
         * ser excluidos.
         * @description faz a pesquisa dos rastros do grupo de veículo,
         * procura um máximo de 10 placas
         * por vez, pra não demorar tanto para ter algum retorno.
         * Usa recursão para procurar pelas proximas
         * 10 placas.
         * @author Gui 🍺
         */
        buscaRastro(veiculos, filtros, reset) {
            if (reset) {
                this.rastroEncontrado = false;
                this.$emit("limpar-rastros-gv");
            }
            var arrVeiculosEnviar = veiculos.splice(0, 10);
            let uri = this.uriBase + "rastro/grupo";
            let obj = { ...filtros, placas: arrVeiculosEnviar };
            this.loadingGrupo = true;
            new HttpRequest().Post(uri, obj).then((data) => {
                this.$emit("rastro-carregado", data.data?.rastros, reset);
                if (!this.rastroEncontrado) {
                    var values = Object.values(data.data.rastros);
                    this.rastroEncontrado = values.some((v) => v !== "");
                }
                if (veiculos.length > 9) this.buscaRastro(veiculos, filtros, false);
                else {
                    new HttpRequest()
                        .Post(uri, { ...obj, placas: veiculos })
                        .then((data) => {
                            if (!this.rastroEncontrado) {
                                var values = Object.values(data.data.rastros);
                                this.rastroEncontrado = values.some((v) => {
                                    return v !== "";
                                });
                            }
                            this.loadingGrupo = false;
                            this.$emit("rastro-carregado", data.data?.rastros, reset);
                            if (!this.rastroEncontrado)
                                this.$refs.filtroPainel.toolTipSemRastro();
                        });
                }
            });
        },

        /**
         * @listens click - botão de localizar veículo no mapa
         * @description Emite o evento para centralizar o mapa no ponto em que o
         * veículo enviou a última comunicação
         * @fires localizaVeiculo
         * @author Gui
         */
        localizar(row) {
            this.$emit("localizaVeiculo", row);
        },

        /**
         * @listens click - botão de edição de motorista dentro da tabela
         * @description Muda as informações de editavel, a prop que alimenta
         * a modal de edição de motorista/ajudante, fazendo isso
         * antes de conjurar 🪄 a modal, tentando manter tudo atualizado
         * @param {object} row - representa linha da tabela
         * @author Gui 🍺🍺
         */
        editarMotorista(row) {
            this.editavel.veiculo = row.placa + " | " + row.prefixo;
            this.$refs.editarMotorista.preparaModal(
                row.clcodigo,
                row.motoristaId,
                row.ajudanteId,
                row.veiculoId
            );
        },

        /**
         * @description Serve para definir o ícone da coluna
         * de comunicação. O ícone começa assumindo
         * comunicação por gsm e altera só se estiver "errado".
         * @author Gui 🍺
         */
        defineIconePos(row) {
            var icon = row.semcomunicacao ? mdiSimOff : mdiSim;
            var title = "GSM";
            if (row.moduloSatelital) {
                icon = mdiSatelliteVariant;
                title = "Satelite";
            }
            return { size: 20, icon, title };
        },

        defineTitlePosicao({ moduloSatelital, dataEvento }) {
            if (moduloSatelital) return `${dataEvento} - Comunicação Satelital`;
            else return `${dataEvento} - Comunicação GSM/GPRS`;
        },

        /**
         * @param {number} options.ingincao - 0=desligado; 1=ligado.
         * @description Define o ícone e a cor do mesmo para a coluna de
         * ignição de veículo.
         */
        defineIgnicao({ ignicao }) {
            var icon = mdiCloseCircle;
            var style = "color:red";
            if (ignicao) {
                icon = mdiCheckCircle;
                style = "color:green";
            }
            return {
                size: 20,
                icon,
                style,
            };
        },

        /**
         * @listens motoristAtualizado
         * @description Faz a request que traz as informações para
         * o painel do controle.
         */
        async atualizaPainel() {
            clearInterval(this.interPainel);
            this.tableData = [];
            this.editavel.cliente = this.filtros.cliente;
            this.$emit("rem-all-markers");
            this.toggleSelect();
            if (this.objPainel && !this.requestControl) {
                this.info = "loading";
                this.requestControl = true;
                await new HttpRequest()
                    .Post(this.uriBase + "atualizar/painel", this.objPainel)
                    .then(({ data, status }) => {
                        if (status) {
                            this.arrayTags = data.tags;
                            if (data?.modulos) {
                                this.tableData = data.modulos.map(e => new Veiculo(e));
                                this.tableData.forEach(this.selecionaEhEnvia);
                            }
                            if (data.panicos.length) {
                                this.emitePanicos(data.panicos);
                            }
                            this.toggleSelect();
                        }
                        clearInterval(this.interPainel);
                        this.interPainel = setInterval(
                            this.atualizaPainelIntervalo,
                            this.attInterval
                        );
                        this.info = "error";
                    })
                    .finally(() => {
                        this.requestControl = false;
                    });
            }
        },

        selecionaEhEnvia(linha) {
            this.emiteAddMarker(linha);
        },

        /**
         * @description Verifica a intensidade da cor de fundo,
         * e caso não for uma cor clara, adiciona opacidade
         * @param {Object} info - objeto que representa uma linha,
         * emitido pela tabela.
         * @returns {Object} style - objeto com cor e background da linha
         */
        tableRowStyle(info) {
            if (!info.row.gvcor) return;
            if (this.wc_hex_is_light(info.row.gvcor)) {
                return {
                    "background-color": info.row.gvcor,
                    color: "black",
                };
            } else {
                var bgcolor = this.rgbaColor(info.row.gvcor);
                return {
                    "background-color": bgcolor,
                    color: "black",
                };
            }
        },

        /**
         * @param {string} color - cor em hexa
         * @return {string} rgba - a cor no formato rgba
         */
        rgbaColor(color) {
            const hex = color.replace("#", "");
            const c_r = parseInt(hex.substr(0, 2), 16);
            const c_g = parseInt(hex.substr(2, 2), 16);
            const c_b = parseInt(hex.substr(4, 2), 16);
            return `rgba(${c_r}, ${c_g}, ${c_b}, 0.2)`;
        },

        /**
         * @param {string} color - cor em hexa
         * @returns {boolean} - Se a cor é clara dms
         */
        wc_hex_is_light(color) {
            if (color === undefined) color = "#ffffff";
            const hex = color.replace("#", "");
            const c_r = parseInt(hex.substr(0, 2), 16);
            const c_g = parseInt(hex.substr(2, 2), 16);
            const c_b = parseInt(hex.substr(4, 2), 16);
            const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
            return brightness > 155;
        },

        /**
         * @listens expandir - emitido pelos filtros do painel de controle
         * @description Expande o tamanho da tabela.
         * A tabela só aceita a altura em pixeis,
         * então eu passo 75% do tamanho da janela do usuário
         * (window.screen.height * 0.75)
         * @todo repensar a maneira como ele pega o tamanho da tela
         * @author Gui 🍺
         */
        emiteExpandir() {
            /**O lance da altura também não ta legal, melhorar isso no futuro */
            var view = window.screen.height * 0.7;
            if (this.tamTable === 200) this.tamTable = view;
            else this.tamTable = 200;
            this.$emit("expandir");
        },

        /**
         * @listens mudaFiltros
         * @description Quando o filtros do painel são atualizados
         * essa função garante que o painel vai receber
         * esses novos valores e depois atualizar as informações de acordo
         * @param {object} nFiltros - objeto emitido junto do evento mudaFiltros
         * @author Gui 🍺🍺
         */
        mudaFiltros(nFiltros) {
            this.filtros = nFiltros;
            this.tableData = [];
            this.info = "info";
            if (nFiltros.cliente.length) this.atualizaPainel();
        },

        /**
         * @param {object} el - parte da tabela (?)
         * @param {string} string - parametro de filtro
         * @description Centralizando a maneira com que os veiculos
         * no painel são filtrados
         * @todo melhorar descrição do parâmetro el
         * @author Gui 🍺🍺
         */
        filtrarPorCampos(el, string) {
            let reg = new RegExp(string, "i");
            if (el.placa.match(reg)) return true;
            if (el.cidade.match(reg)) return true;
            if (el.descricao.match(reg)) return true;
            if (el.prefixo.match(reg)) return true;
            if (el?.motorista) {
                if (el.motorista.match(reg)) return true;
            }
            return false;
        },

        ...mapGetters(["getModulos"]),
    },

    computed: {
        temTag() {
            return this.tableData.some((el) => {
                return !!el.tags.length;
            });
        },

        podeVerPanico() {
            if (typeof this.permissoes.mappanico === "undefined") return false;
            return this.permissoes.mappanico.ppvisualizar;
        },

        podeTratarPanico() {
            if (typeof this.permissoes.mappanico === "undefined") return false;
            return this.permissoes.mappanico.ppeditar;
        },

        /**
         * @description Esse objeto é utilizado para preparar as
         * informações dos filtros
         * para fazer request's para o back-end para atualizar as informações de
         * acordo com os filtros estabelecidos pelo usuário
         * @author Gui 🍺🍺
         */
        objPainel() {
            if (Object.values(this.filtros?.cliente ?? []).length) {
                return {
                    id: this.filtros.cliente,
                    status: this.filtros.ignicao,
                    idgv: this.filtros.grupoVeiculo,
                    tags: this.filtros.filtrarTag,
                };
            }
            return false;
        },

        /**
         * Filtra a tabela de dados de acordo com o que o
         * usuário digitar
         * @author Gui 🍺🍺
         */
        dadosFiltrados() {
            if (!this.tableData) return [];
            return this.tableData.filter((el) => {
                return this.filtrarPorCampos(el, this.query);
            });
        },

        vermotora() {
            if (typeof this.permissoes["mapmotorista"] === "undefined") return false;
            return this.permissoes["mapmotorista"].ppvisualizar;
        },

        mudamotora() {
            if (typeof this.permissoes["mapmotorista"] === "undefined") return false;
            return this.permissoes["mapmotorista"].ppeditar;
        },

        verveiculos() {
            if (typeof this.permissoes["mapveiculos"] === "undefined") return false;
            return this.permissoes["mapveiculos"].ppvisualizar;
        },
    },

    beforeDestroy() {
        this.interPainel = clearInterval(this.interPainel);
    },

    mounted() {
        clearInterval(this.interPainel);
    },
});
</script>

<style lang="scss">
#tablePainelControle {
    .colunaLonga {
        white-space: nowrap;
        overflow: hidden;
        align-content: flex-start;
        text-overflow: ellipsis;
    }
    .com-antiga {
        color: red;
    }
    .statusBar {
        width: 100%;
    }
    .iconeClicavel {
        cursor: pointer;
    }
    .wordWrap {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 1px;
    }
    table {
        border-collapse: collapse !important;
    }
    .el-table__body {
        border-collapse: collapse !important;
    }
    .el-table__body,
    .el-table__footer,
    .el-table__header {
        border-collapse: collapse !important;
    }
    .el-table {
        margin-bottom: 0px;
    }
    .botaoMudaMotorista {
        height: 22px;
        width: 22px;
        padding: 2px;
    }
    .lista-tags {
        padding: 2px;
        display: flex;
        flex-direction: row;
        gap: 2px;
        flex-wrap: wrap;
        max-height: 50px;
        overflow: hidden;
        justify-content: baseline;
    }
    #teste {
        background: blue;
    }
}
</style>
