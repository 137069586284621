<template>
    <panelEagle id="cadastrarChecklist" :loading="loadingPanel">
        <div class="col-sm-12 nopadding divDontPrint">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage 
                        :icon="mdiOrderBoolAscendingVariant" 
                        titulo="Checklist">
                    </tituloPage>
                </div>
                <div class="col-sm-6">
                    <basicButtonsCadastrosSC 
                        :disabled       ="$v.$invalid"
                        linkCancel      ="checklist" 
                        tipoCancela     ='button'
                        @salvarCadastro ="salvarChecklist">
                    </basicButtonsCadastrosSC>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-4 nopadding">
                    <selectAll
                        :isMultiple   ="false" 
                        nameForRadio  ="empresasChacklist" 
                        :hasSelectAll ="false" 
                        :labels       ="[{indexDFH: 'EM', description: 'Empresa*'}]" 
                        :optionsArray ="optionsArrayEmpresas" 
                        firstSelected ="EM"
                        :selected     ='empresaSelected'
                        :extraClass   ="{'border border-danger':$v.empresaSelecionadas.$anyError}"
                        @close        ="$v.empresaSelecionadas.$touch()"
                        @changeSelect ="changeEmpresa">
                    </selectAll>
                </div>
                <div class="col-sm-4 nopadding">
                    <selectAll
                        :isMultiple   ="false" 
                        nameForRadio  ="empresasChacklist" 
                        :hasSelectAll ="false" 
                        :labels       ="[{indexDFH: 'EM', description: 'Tipo*'}]" 
                        :optionsArray ="optSelectTipo" 
                        firstSelected ="EM"
                        :selected     ='tipoSelected'
                        :disabled     ='$v.empresaSelecionadas.$invalid'
                        :extraClass   ="{'border border-danger':$v.tipoSelecionado.$anyError}"
                        @close        ="$v.tipoSelecionado.$touch()"
                        @changeSelect ="changeVeiculos">
                    </selectAll>
                </div>
                <div class="col-sm-4 nopadding">
                    <inputSimple 
                        name         ="descricaoChecklist" 
                        id           ="descricaoChecklist"
                        label        ="Descrição*"
                        :value       ='descricaoSelecionada'
                        :inputClass  ="{'border border-danger':$v.descricaoSelecionada.$anyError}"
                        @blur        ="$v.descricaoSelecionada.$touch()"
                        @changeInput ="changeDescricao">
                    </inputSimple>
                </div>
            </div>
            <div class="col-sm-12 mt-3 row">
                <b-form-checkbox v-for="(dia, i) in diasSemana"
                class="mr-3"   
                :key="i"
                v-model ="diasFrequencia"
                :value="dia.value">
                    {{dia.description}}
                </b-form-checkbox>
            </div>
            <div class="col-sm-12 pr-4">
                <div class="col-sm-12 mt-4 divItens pl-0" align="left">
                    <span>Lista dos itens*</span>
                </div>
                <div class="col-sm-12 listaItens">
                    <template>
                        <div class="col-sm-12 m-2 row nopadding">
                            <div class="col-sm-9 mb-1 nopadding">
                                <inputSimple
                                    class        ="nopadding"
                                    name         ="Intens"
                                    label        ="Itens"
                                    placeholder  ="Digite os itens do checklist"
                                    type         ="text"
                                    ref          ='adicionaItem'
                                    @changeInput ="changeItens"
                                    @keyup.enter ="adicionaItens"
                                />
                            </div>
                            <div class="col-sm-3 nopadding mt-4">
                                <simpleButton 
                                    text   ="Adicionar itens" 
                                    :icon  ="mdiPlus" 
                                    type   ="blue"
                                    width  ='93%'
                                    class  ="col-sm-12 heightAdicionar"
                                    event  ="click" 
                                    @click ='adicionaItens'
                                />
                            </div>
                        </div>
                        <div class="col-sm-12 p-0 mt-4 row">
                            <div class="col-sm-12 nopadding">
                                <div class  ="col-sm-12 d-flex cabecalhoListaItens">
                                    <div class="col-sm-7" align='left'>Itens</div>
                                    <div class="col-sm-2 pt-2 pl-0 pr-0 nowrap">Código Externo</div>
                                    <div class="col-sm-2" >Bloqueia veículo</div>
                                    <div class="col-sm-1 pr-0" align='right'>Remover</div>
                                </div>
                            </div>                            
                        </div>
                        <div class="col-sm-12 justpaddingbottom row">
                            <div class="col-sm-12 divListaItens nopadding">
                                <draggable
                                :list="itensAdicionar"
                                class="drag-group"
                                @start = "dragging = true"
                                :icon  = "mdiDragVertical"
                                @end = "dragging = false">
                                    <div v-for ="(itens, i) in itensAdicionar"
                                    :key   ="i+'1'"
                                    class  ="col-sm-12 tdColaboradoresBanco d-flex justify-content-start">
                                        <div
                                        :title='mensagem'>
                                            <baseIcon
                                            class="nopadding"
                                            :icon  = "mdiDragVertical"
                                            size = '20'/>
                                        </div>
                                        <div class="col-sm-7" align='left'>
                                            <p style="overflow:auto;">{{itens.chiitem}}</p>
                                        </div>
                                        <div 
                                        class="col-sm-2 pl-0 pr-0 d-flex justify-content-center"
                                        :title="itens.editar ? 'Clique duas vezes para confirmar' : 'Clique duas vezes para editar'" 
                                        @dblclick="itens.editar =!itens.editar"
                                        @keyup.enter="itens.editar =!itens.editar">
                                            <div v-show="itens.editar"  class="tamanho-max" >
                                                <inputSimple
                                                id="inputCodigoExterno"
                                                class="nopadding input-editar"
                                                label=""
                                                placeholder=""
                                                :value="itens.chicodigoexterno"
                                                maxlength="4"
                                                type="text"
                                                ref='editaCodigoExterno'
                                                @blur="itens.editar = itens.editar?!itens.editar:itens.editar"
                                                @changeInput="v => itens.chicodigoexterno = v"/>
                                            </div>
                                            <div v-show="!itens.editar" class="borda-input tamanho-max">
                                                {{itens.chicodigoexterno}}
                                            </div>
                                        </div>
                                        <div class="col-sm-2">
                                            <b-check
                                            :id="i+'-bloqueio'"
                                            v-model='itens.chibloqueio'
                                            />
                                        </div>
                                        <div class="col-sm-1 retirarItem"
                                        @click ="deletarItem(i)">
                                            <baseIcon 
                                            class="iconRetirar mr-5"
                                            :icon='mdiCloseThick'
                                            size='20'/>
                                        </div>
                                    </div>
                                </draggable>
                            </div>                            
                        </div>
                    </template>
                </div>  
            </div>      
        </div>
        <div class="col-sm-12 pl-0">
            <requiredFields/>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
    </panelEagle>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { conectionError } from '@/Services/Helpers/swellHeper'
import { FiltrosService } from "@/Services/filtros/filtros.Service"
import { mapGetters } from 'vuex'
import { 
    mdiOrderBoolAscendingVariant,
    mdiChevronRight,
    mdiChevronLeft,
    mdiPlus,
    mdiCloseThick,
    mdiDragVertical
} from '@mdi/js'
import draggable from 'vuedraggable'
import Vue from 'vue'
export default Vue.extend({
    name:'cadastroChecklist',
    components:{
        draggable,
        'panelEagle'              : require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage'              : require('@/components/Atom/Header/Titulo').default,
        'selectAll'               : require('@/components/Atom/Select/SelectAll').default,
        'basicButtonsCadastrosSC' : require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
        'inputSimple'             : require('@/components/Atom/Inputs/InputSimple').default,
        'requiredFields'          : require('@/components/Atom/Footer/RequiredFields').default,
        'simpleButton'            : require('@/components/Atom/Buttons/SimpleButton').default,
        'baseIcon'                : require('@/components/Atom/Icon/BaseIcon').default
    },

    validations:{
        descricaoSelecionada   : { required },
        empresaSelecionadas    : { required },
        tipoSelecionado        : { required },
        itensAdicionar         : { required },
    },

    data(){
        return{
            counter: 0,
            optionsArrayEmpresas : new EmpresasService().Get(),
            mdiOrderBoolAscendingVariant:mdiOrderBoolAscendingVariant,
            mdiChevronRight:mdiChevronRight,
            mdiChevronLeft:mdiChevronLeft,
            mdiPlus:mdiPlus,
            mdiCloseThick : mdiCloseThick,
            mdiDragVertical : mdiDragVertical,
            mensagem : 'Para reordenar os itens do checklist segure e arraste.',
            uriBase:'/manutencoes/cadastros/checklist/',
            optSelectTipo       : [{'value': 'A', 'description': 'Automóvel'},
                                    {'value': 'C', 'description': 'Caminhão'},
                                    {'value': 'T', 'description': 'Máquina'},
                                    {'value': 'M', 'description': 'Moto'},
                                    {'value': 'O', 'description': 'Onibus'},
                                    {'value': 'U', 'description': 'Outro'},],
            diasSemana           : [{'value': 0, 'description': 'Domingo'},
                                    {'value': 1, 'description': 'Segunda'},
                                    {'value': 2, 'description': 'Terça'},
                                    {'value': 3, 'description': 'Quarta'},
                                    {'value': 4, 'description': 'Quinta'},
                                    {'value': 5, 'description': 'Sexta'},
                                    {'value': 6, 'description': 'Sábado'}],
            diasFrequencia       : [],
            tipoSelecionado      : [],
            descricaoSelecionada : '',
            itemSelecionado      : '',
            loadingPanel         : false,
            empresaSelecionadas  : [],
            itensAdicionar       : [],
            empresaSelected      : [],
            tipoSelected         : [],
            aux                  : ''
        }
    },
    ...mapGetters(['getMaster','getEditar']),
    methods: {
        changeEmpresa(empresas){
            this.empresaSelecionadas = empresas;
        },

        changeVeiculos(veiculos){
            this.tipoSelecionado = veiculos
        },

        changeDescricao(descricao){ 
            this.descricaoSelecionada = descricao
        },
        
        changeItens(itens){
            this.itemSelecionado = itens
        },
        
        /**
         *  @listen click - botão de salvar
         *  @description Método para salvar os dados do checklist
         *  @param  {Array}  empresaSelecionadas  - empresas selecionada para cadastro
         *  @param  {Array}  tipoSelecionado      - tipo selecionado para cadastro
         *  @param  {String} descricaoSelecionada - descrição do checklist
         *  @param  {Object} itensAdicionar       - objeto dos itens com a descrição dos 
         *      itens e se terá bloqueio
         *  @param  {String} idEditar             - se for uma edição o id de cadastro
         *  @return Status de sucesso ou erro 
         *  @author Vitor Hugo 🐨
         */
        salvarChecklist(){
            this.loadingPanel = true
            var objt={
                'empresa'   : this.empresaSelecionadas[0],
                'tipo'      : this.tipoSelecionado[0],
                'descricao' : this.descricaoSelecionada,
                'itens'     : this.itensAdicionar,
                'id'        : this.idEditar,
                'frequencia': this.diasFrequencia
            }
            new HttpRequest().Post(this.uriBase+'salvar', {'objt':objt})
                .then((dados=>{
                    if(dados.code == 201){
                        this.$router.push({name:'checklist'})
                        this.loadingPanel = false
                    } else {
                        conectionError()
                        this.loadingPanel = false
                    }
                }))
        },

        /**
         * Limpa o input caso o usuario tente edita-lo
         * novamente.
         * @param i - indice do input
         * @param editar - bool dizendo se o carinha tá editando ou não
         * @author Marcos
         * vou deixar comentada essa grande gambiarra que eu fiz 
         * pra poder corrigir o input kkkkkk
         * (meu deus não façam isso em casa  @_@)
        */
        // limpaInput(i, editar) {
        //     if(!editar) {
        //         this.aux = this.$refs.editaCodigoExterno[i].inputValue
        //         this.$refs.editaCodigoExterno[i].clearAll()
        //     }else if(editar && !this.$refs.editaCodigoExterno[i].inputValue)
        //         this.$refs.editaCodigoExterno[i].inputValue = this.aux
        // },

        /**
         *  @listen keyup.enter             - evento para adicionar elementos a array a partir do enter
         *  @listen click                   - evento para adicionar elementos a array a partir do botão
         *  @description Método para adicionar os itens a array
         *  @param  {Array} itemSelecionado - item para adicionar ao checklist
         *  @return Array itemAdicionar
         *  @author Vitor Hugo 🐨
         */
        adicionaItens(){
            if(this.itemSelecionado != ''){
                this.itensAdicionar.unshift({
                    'chiitem' : this.itemSelecionado, 
                    'chibloqueio' : false,
                    'chicodigoexterno':'',
                    'editar':false,
                    'chichecklist': this.$route.params.id?this.$route.params.id:null,
                })
                this.$refs.adicionaItem.clearAll()
                this.itemSelecionado = ''
                // this.ordenaItemAdicionar()
            }
        },

         /**
         *  @listen click         - emitido ao clicar no x de remoção
         *  @description Método para deletar um item do checklist
         *  @param {String} index - posição que o elemento esta na array
         *  @return Array itemAdicionar
         *  @author Vitor Hugo 🐨 | Lucas Eduardo
         */
        deletarItem(index){
            this.itensAdicionar.splice(index, 1)
        },

        /**
         *  @description Método para ordenar em ordem alfabética os elementos da array
         *  @param {Array} itensAdicionar - itens que foram adicionados
         *  @return Array itensAdicionar
         *  @author Vitor Hugo 🐨
         */
        ordenaItemAdicionar(){
            this.itensAdicionar.sort(function(x, y){
                let a = x.chiitem.toUpperCase()
                let b = y.chiitem.toUpperCase()
                return a == b ? 0 : a > b ? 1 : -1
            })
        },

        /**
         *  @description Método para trazer os dados de edição para preencher os inputs
         *  @param {String} id - código do cadastro
         *  @return descricaoSelecionada - input de descrição
         *  @return empresaSelected      - select de empresa
         *  @return tipoSelected         - select de tipo
         *  @return itensAdicionar       - array com os itens do checklist
         *  @author Vitor Hugo 🐨
         */
        editar(id){
            this.loadingPanel = true
            new HttpRequest().Post(this.uriBase+'editar', {'id':id})
                .then((dados=>{
                    if(dados.code == 200){
                        var dadosChecklist = dados.data.dadosChecklist[0]
                        this.itensAdicionar = dados.data.dadosChecklistItens
                        this.diasFrequencia = dados.data.dadosChecklistFrequencia
                        // this.ordenaItemAdicionar()
                        this.arrumaDadosPrincipais(dadosChecklist)
                        this.loadingPanel = false
                    }
                    this.loadingPanel = false
                }))
        },

        /**
         *  @description Atribui valor para as variáveis dos selects e inputs principais
         *  @param {Object} dadosChecklist - dados do checklist a serem passados as variáveis
         *  @return descricaoSelecionada - input de descrição
         *  @return empresaSelected      - select de empresa
         *  @return tipoSelected         - select de tipo
         *  @author Vitor Hugo 🐨
         */
        arrumaDadosPrincipais(dadosChecklist){
            this.descricaoSelecionada = dadosChecklist.descricao
            this.empresaSelected = [{'value':dadosChecklist.clcodigo, 'description':dadosChecklist.clfantasia}];
            this.optSelectTipo.forEach(element => {
                if(element.value == dadosChecklist.tipo.trim()){
                    this.tipoSelected = [{'value':element.value, 'description':element.description}]
                }
            });
        },
    },
    
    mounted(){
        if(this.$route.params.id != undefined){       
            this.editar(this.$route.params.id)
            this.idEditar = this.$route.params.id
        }
    }
})
</script>
<style lang="scss">
#cadastrarChecklist{
    .tamanho-max{
        width: 120px;
    }

    .input-editar{
        input{
            max-height: 23px !important;
        }
    }
    
    .borda-input{
        min-height: 23px;
        border: 1px solid #ced4da;
    }

    .divItens{
        font-weight: bold;
        span{
            font-size: 15px !important;
        }
    }
    .cabecalhoListaItens{
        background: #cccccc;
        height: 25px;
        div{
            padding-top: 4px;
        }
    }
    .listaItens{
        border: 1px solid #dcdbdb;
        .divListaItens{
            min-height: 340px;
            max-height: 340px;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            border: 1px solid #dcdbdb !important;
            overflow: scroll;
            text-overflow: clip;
            overflow-x: auto;
            vertical-align: middle;
            .tdColaboradoresBanco:hover{
                // cursor: pointer !important;
                background: #f3efef;
            }
            .tdColaboradoresBanco{
                padding: 5px;
                height: 10%;
                white-space: nowrap; 
                border: none !important;
                padding-left: 10px;
            }
        }
        .botoesAdicionar{
            margin-top: 120px;
            height: 70px;
        }
        .heightAdicionar{
            height: 40px;
        }
        .retirarItem{
            text-align: right;
            vertical-align: middle;
            .iconRetirar:hover{
                cursor: pointer;
                font-weight: bold;
                color: #585858;
            }
        }
    }
}
</style>
