<template>
	<panelEagle id='agendamento' :loading="loadingPanel">
		<div class="col-sm-12 row divDontPrint nopadding">
			<div id='titulo_botoes' class="col-sm-12 row nopadding">
				<div class="col-sm-3 mt-3">
					<titulo titulo='Agendamento' :icon='mdiCalendarMonthOutline' />
				</div>
				<div class="col-sm-9 row divBotoesImprimirAgendamento">
					<div class="col-sm-6">
						<div class="col-sm-10 divNotificacaoSolicitacaoManutencao" @click='visualizarDetalhesSoliManu'
							v-show="qtdSolicitacoes.length > 0 ? true : false">
							({{ qtdSolicitacoes.length }}) Solicitações
							de manutenção pendentes! <br>
							Clique para visualizar os detalhes
						</div>
					</div>

					<div class="col-sm-2 nopadding mt-2" style="text-align: right;">
						<simpleButton id="popoverInfo" width="100%" :icon="mdiAlertCircleOutline" type="orange"
							text="Legenda" title="Legenda">
						</simpleButton>
					</div>

					<div class="col-sm-2 nopadding mt-2" style="text-align: right;">
						<simpleButton text="Novo" width="100%" :icon="mdiPlus" event="click" type="aqua-marine"
							@click='novoAgendamento' />
					</div>

					<div class="col-sm-2 nopadding mt-2" style="text-align: right;">
						<simpleButton text="Imprimir OS" width="100%" :icon="mdiCloudPrintOutline" event="click"
							type="light-blue" @click='imprimirOs' />
					</div>
				</div>
			</div>
			<slideUpAndDown>
				<template #filters>
					<div class="col-sm-12">
						<hr>
					</div>
					<div class="col-sm-12 nopadding row">
						<div class="col-sm-3 nopadding" id='seletorEmpresa'>
							<!-- Select de empresas -->
							<selectAll nameForRadio='seletorEmpresas' :hasSelectAll='true'
								:labels='[{ "description": "Empresas " }]' :isMultiple='true'
								:optionsArray='optSelectEmpresa' @changeSelect="changeEmpresa" />
						</div>

						<div class="col-sm-3 nopadding">
							<!-- Select de layout -->
							<selectAll nameForRadio='seletorLayout' firstSelected='V' v-bind="{
								hasSelectAll: false,
								isMultiple: false,
								optionsArray: optSelectLayout,
								labels: labelSeletorLayout,
								ref: 'selectL'
							}" @changeSelect='changeLayout' />
						</div>

						<div class="col-sm-2 nopadding">
							<!-- Select de layout -->
							<selectAll nameForRadio='seletorPeriodo' firstSelected='V' v-bind="{
								hasSelectAll: false,
								isMultiple: false,
								optionsArray: optSelectPeriodo,
								labels: labelSeletorPeriodo,
								ref: 'selectP'
							}" :disabled="layoutSelecionado[0] == 'month' ||
								layoutSelecionado == ''" @changeSelect='changePeriodo' />
						</div>

						<div class="col-sm-3 nopadding">
							<!-- Select de Tags -->
								<selectAll nameForRadio='seletorTags' firstSelected='V' v-bind="{
								hasSelectAll: true,
								isMultiple: true,
								optionsArray: optSelectTag,
								labels: labelSeletorTags,
								ref: 'selectTag'
							}" :disabled="$v.empresasSelecionadas.$invalid || carregandoTags || bloquearTags"
							@changeSelect="setTag" :selected="selectedTags" />
						</div>
					</div>
				</template>
			</slideUpAndDown>
			<div class="col-sm-12">
				<hr>
			</div>

			<div v-if="carregandoCalendario" class="col-sm-12">
				<statusInformation typeBar="div" :statusBar="statusbar">
				</statusInformation>
			</div>

			<div v-if="carregandoCalendario == false" class="col-sm-10 divCalendario">
				<CalendarView class="theme-default holiday-us-traditional holiday-us-official"
					:display-period-uom="layoutSelecionado[0]" :enable-drag-drop="true" :show-date="showDate"
					:items="events" :displayPeriodCount="periodoSelecionado" :item-top="theme.top"
					:item-left="theme.left" :item-content-height='theme.height' :enableDateSelection="true"
					@drop-on-date="onDrop" @click-date="onClickDate" @click-item="onClickItems">
					<CalendarViewHeader slot="header" slot-scope="t" :header-props="t.headerProps"
						@input="setShowDate" />
				</CalendarView>
			</div>

			<div v-if="carregandoCalendario == false" class="col-sm-2 listaBadge">
				<div v-for=" (event, p) in editOrdensServico" :key="p" draggable @drag="onDrag(event)">
					<div class="divBadge">
						<span> {{ event.title }} </span>
					</div>
				</div>
			</div>

			<b-popover target="popoverInfo" triggers="hover click" placement="auto" ref="popover">
				<div class="col-sm-12 nopadding row" style="background: #f2f2f2;">
					<div class="col-sm-12 nopadding custom-break-line">
						<tr class="trLegendaAgendamentoTipo">
							<td class="flaticon-icon099 col-sm-6 tdLegendaAgendamentoTipo amarelo text-truncate">
								Pendente
							</td>
							<td class="flaticon-icon099 col-sm-6 tdLegendaAgendamentoTipo azul text-truncate">
								Agendado
							</td>
						</tr>
						<tr class="trLegendaAgendamentoTipo">
							<td class="flaticon-icon099 col-sm-6 tdLegendaAgendamentoTipo verde text-truncate">
								Concluído
							</td>
							<td class="flaticon-icon099 col-sm-6 tdLegendaAgendamentoTipo vermelho text-truncate">
								Cancelado
							</td>
						</tr>
					</div>
				</div>
			</b-popover>

			<!-- Modal Nova OS -->
			<template>
				<modalEagle size='xl' id="novoAgendamento" title="Novo Agendamento" @show="novoAgendamento()">
					<template slot="modalBody">
						<!-- Select de agendamento -->
						<div class="col-sm-12 nopadding row">
							<selectAll :labels="[{ indexDFH: 'STA', description: 'Selecione o tipo de agendamento' }]"
								nameForRadio='seletorAgendamento' :hasSelectAll='false' :isMultiple='false'
								:optionsArray='optSelectAgendamento' @changeSelect="changeAgendamento" />
						</div>
						<div class="col-sm-12">
							<hr>
						</div>
						<!-- Verificações -->
						<!-- checkbox -->
						<div v-if="agendamentoEstaSelecionado" class="col-sm-12 justpaddingbottom row">
							<span class="spanVerificacoesNovoAgendamento mb-3">
								Antes de seguirmos com o agendamento, confirme se você realizou as verificações abaixo.
								Lembre-se que elas podem evitar uma manutenção desnecessária!
							</span>
							<div class="col-sm-3 row">
								<b-checkbox v-model="checkVerificacoesNovoAgendamento1" class='checkBox' key='1'
									value="1">
									Área de cobertura
								</b-checkbox>
							</div>
							<div class="col-sm-5 row">
								<b-checkbox v-model="checkVerificacoesNovoAgendamento2" class='checkBox' key='2'
									value="2">
									Configuração do rastreador (atualização firmware/ conf. enviada)
								</b-checkbox>
							</div>
							<div class="col-sm-4 row">
								<b-checkbox v-model="checkVerificacoesNovoAgendamento3" class='checkBox' key='3'
									value="3">
									Configuração do chip (ativo/inativo)
								</b-checkbox>
							</div>
							<div class="col-sm-12">
								<hr>
							</div>
						</div>
						<!-- select da pagina -->
						<div class="col-sm-12 row nopadding" v-show="checkVerificacoesNovoAgendamento1 &&
		checkVerificacoesNovoAgendamento2 &&
		checkVerificacoesNovoAgendamento3 &&
		agendamentoEstaSelecionado">
							<div class="col-sm-12 row divVisitaTecnicaNovoAgendamento">
								<div class="col-sm-8  divVisitaTecnicaNovoAgendamento">
									<!-- select padrao empresa -->
									<div class="col-sm-12 divSelectAllNovoAgendamento">
										<selectAll ref="refSelectPadraoEmpresaModalOs" nameForRadio='seletorEmpresas'
											:hasSelectAll='false' :labels='[{ "description": "Selecione a empresa *" }]'
											:isMultiple='false' :optionsArray='optSelectEmpresa'
											@changeSelect="changeEmpresaNovoAgendamento" />
									</div>
									<!-- select padrao veiculo (menos no alteração de veículo) -->
									<div v-show="agendamentoSelecionado != 5"
										class="col-sm-12 divSelectAllNovoAgendamento">
										<selectAll ref="refSelectPadraoVeiculoModalOs" nameForRadio='seletorVeiculos'
											:hasSelectAll='false' :labels='[{ "description": "Selecione o veículo *" }]'
											:isMultiple='false' :optionsArray='optSelectVeiculo'
											:disabled="$v.empresaNovoAgendamentoSelecionada.$invalid"
											:loading="loadingSelect" @changeSelect="changeVeiculoNovoAgendamento" />
									</div>
									<!-- Troca de rastreador -->
									<div v-show="agendamentoSelecionado == 2"
										class="col-sm-12 divSelectAllNovoAgendamento">
										<selectAll ref="refSelectRastreadorModalOs" nameForRadio='seletorRastreador'
											:hasSelectAll='false'
											:labels='[{ "description": "Selecione o novo rastreador *" }]'
											:isMultiple='false' :optionsArray='optSelectRastreadorChipAgendamento'
											:disabled="$v.empresaNovoAgendamentoSelecionada.$invalid"
											:loading="loadingSelect" @changeSelect="changeRastreadorNovoAgendamento" />
									</div>
									<!-- Troca de chip -->
									<div v-show="agendamentoSelecionado == 3"
										class="col-sm-12 divSelectAllNovoAgendamento">
										<selectAll ref="refSelectChipModalOs" nameForRadio='seletorChip'
											:hasSelectAll='false' :labels='[{ "description": "Selecione o novo chip *" }]'
											:isMultiple='false' :optionsArray='optSelectRastreadorChipAgendamento'
											:disabled="$v.empresaNovoAgendamentoSelecionada.$invalid"
											:loading="loadingSelect" @changeSelect="changeChipNovoAgendamento" />
									</div>
									<!-- Alteração de veículo -->
									<div v-show="agendamentoSelecionado == 5"
										class="col-sm-12 divSelectAllNovoAgendamento">
										<selectAll nameForRadio='seletorVeiculos' :hasSelectAll='false'
											:labels='[{ "description": "Selecione o veículo atual *" }]'
											:isMultiple='false' :optionsArray='optSelectVeiculo' ref='selectV'
											:disabled="$v.empresaNovoAgendamentoSelecionada.$invalid"
											:loading="loadingSelect" @changeSelect="changeVeiculoNovoAgendamento" />
									</div>
									<!-- Alteração de veículo -->
									<div v-show="agendamentoSelecionado == 5"
										class="col-sm-12 divSelectAllNovoAgendamento">
										<selectAll ref='refSelectVeiculoNovoModalOs' nameForRadio='seletorNovoVeiculos'
											:hasSelectAll='false'
											:labels='[{ "description": "Selecione o veículo novo *" }]' :isMultiple='false'
											:optionsArray='optSelectVeiculo'
											:disabled="$v.empresaNovoAgendamentoSelecionada.$invalid"
											:loading="loadingSelect" @changeSelect="changeNovoVeiculoNovoAgendamento" />
									</div>
								</div>
								<!-- DIV Informações atuais -->
								<div class="col-sm-4">
									<div class="col-sm-12 divTituloTabelaNovoAgend">
										<span>Informações atuais</span>
									</div>
									<div class="col-sm-12 dadosNovoAgendamento">
										<tr style="width: 100%;">
											<td class="col-sm-6 tdDadosNovoAgendTitle">
												Serial:
											</td>
											<td class="col-sm-6 tdDadosNovoAgendDados">
												{{ dadosInfoBox.serial }}
											</td>
										</tr>
										<tr>
											<td class="col-sm-6 tdDadosNovoAgendTitle">
												Modelo:
											</td>
											<td class="col-sm-6 tdDadosNovoAgendDados">
												{{ dadosInfoBox.modelo }}
											</td>
										</tr>
										<tr>
											<td class="col-sm-6 tdDadosNovoAgendTitle">
												Chip:
											</td>
											<td class="col-sm-6 tdDadosNovoAgendDados">
												{{ dadosInfoBox.chip }}
											</td>
										</tr>
										<tr>
											<td class="col-sm-6 tdDadosNovoAgendTitle">
												Operadora:
											</td>
											<td class="col-sm-6 tdDadosNovoAgendDados">
												{{ dadosInfoBox.operadora }}
											</td>
										</tr>
										<tr>
											<td class="col-sm-6 tdDadosNovoAgendTitle">
												Última Posição:
											</td>
											<td class="col-sm-6 tdDadosNovoAgendDados">
												{{ dadosInfoBox.ultimaPosicao }}
											</td>
										</tr>
									</div>
								</div>
								<!-- DIV Informe o motivo da manutenção -->
								<div class="col-sm-12">
									<span>Informe o motivo da visita</span>
									<textarea placeholder="Digite pelo menos 15 caracteres" style="width: 100%;"
										minlength="15" v-model="motivoAgendamento">
                                    </textarea>
								</div>
							</div>
						</div>
					</template>
					<!-- Footer -->
					<template>
						<simpleButton :icon="mdiCloseThick" text="Cancelar" event="click" @click="fecharNovoAgendamento"
							type="red">
						</simpleButton>
						<simpleButton :icon="mdiCheckBold" text="Confirmar" event="click" @click="saveNewOS"
							:isLoading="carregandoSalvarOS" type="blue" :disabled="!desativa">
						</simpleButton>
					</template>
				</modalEagle>
			</template>

			<!-- Modal confirmar OS -->
			<template>
				<modalEagle id="confirmarOs" title="Confirmar OS" size='lg' @show="confirmarOs()">
					<template slot="modalBody">
						<div>
							<span>
								Deseja confirmar a execução da ordem de serviço {{ eventoSelecionadoConfirmarOS }}?
							</span>
						</div>
					</template>
					<template>
						<div class="col-sm-6 row nopadding">
							<div class="col-sm-4 nopadding">
								<simpleButton text="Cancelar" width="97%" :icon="mdiCloseThick" event="click" type="red"
									@click='sairConfirmarOs' />
							</div>
							<div class="col-sm-4 nopadding">
								<simpleButton text="Não" width="97%" :icon="mdiCloseThick" event="click" type="orange"
									@click='cancelarConfirmarOs' />
							</div>
							<div class="col-sm-4 nopadding">
								<loadingButton text="Confirmar" width="97%" :icon="mdiCheckBold" event="click"
									type="blue" :isLoading="carregandoConfirmarOS" @click='confirmarConfirmarOs' />
							</div>
						</div>
					</template>
				</modalEagle>
			</template>

			<!-- Justificar Cancelamento OS -->
			<template>
				<modalEagle id="justificarCancelarConfirmarOS" title="Justificar cancelamento OS" size='lg'
					@show="justificarCancelarConfirmarOS()">
					<template slot="modalBody">
						<div class="col-sm-12">
							<span>Justifique:</span>
							<textarea placeholder="Digite pelo menos 15 caracteres" style="width: 100%;" minlength="15"
								v-model="justificativaModalConfirmarOS">
                            </textarea>
						</div>
					</template>
					<template>
						<div class="col-sm-4 row">
							<div class="col-sm-6 nopadding">
								<simpleButton text="Cancelar" width="97%" :icon="mdiCloseThick" event="click" type="red"
									@click='cancelarJustificativaOS' />
							</div>
							<div class="col-sm-6 nopadding">
								<loadingButton text="Confirmar" width="97%" :icon="mdiCheckBold" event="click"
									type="blue" :disabled="$v.justificativaModalConfirmarOS.$invalid"
									:isLoading="carregandoCancelarOS" @click='confirmarJustificativaOs' />
							</div>
						</div>
					</template>
				</modalEagle>
			</template>

			<!-- Imprimir OS -->
			<template>
				<modalEagle :hardToClose="carregandoImprimirOS" id="imprimirOs" title="Imprimir OS" size='xl'
					@show="imprimirOs()">
					<template slot="modalBody">
						<div class="col-sm-12 row nopadding">
							<!-- Select de empresas -->
							<span class="mb-2 col-sm-12">
								Quais ordens de serviço deseja imprimir?
							</span>
							<selectAll class="" nameForRadio='seletorEmpresas' :hasSelectAll='false'
								:labels='[{ "description": "Empresas " }]' :isMultiple='false'
								:optionsArray='optSelectEmpresa' @changeSelect="changeEmpresaBadgesImprimirOs" />
							<div class="col-sm-12 row">
								<div v-for="(dados, d) in selecionadosBadgesImprimirOS" :key="d" :class="dados.isSelected ?
		'col-sm-2 m-1 p-2 fundoAzul' :
		'col-sm-2 m-1 p-2 fundoBranco'" align="center" event="click" @click="
		changeSelecionadosBadgesImprimirOS(dados.value),
		dados.isSelected = !dados.isSelected">
									<div class="bold">OS: {{ dados.value }}</div>
									<div>{{ dados.placa }}</div>
									<div>{{ dados.data }}</div>
									<div>{{ dados.cliente }}</div>
								</div>
							</div>
						</div>
					</template>
					<template>
						<div class="col-sm-3 row nopadding">
							<div class="col-sm-6 nopadding">
								<simpleButton :disabled="carregandoImprimirOS" text="Cancelar" width="97%"
									:icon="mdiCloseThick" event="click" type="red" @click='cancelarImprimirOS' />
							</div>
							<div class="col-sm-6 nopadding">
								<loadingButton text="Confirmar" width="97%" :icon="mdiCheckBold" event="click"
									type="blue" :disabled="$v.idSelecionadosimprimirOS.$invalid"
									:isLoading="carregandoImprimirOS" @click='confirmarImprimirOs' />
							</div>
						</div>
					</template>
				</modalEagle>
			</template>

			<template>
				<modalInformacaoOS id="informacoesOS-2" ref="informacoesOS">
				</modalInformacaoOS>
			</template>

			<ModalDetalhesSolicitacaoManutencaoVeiculos :solicitacoesManutencao='solicitacoesManutencao'
				@modalClose='modalCloseSoliManu'>
			</ModalDetalhesSolicitacaoManutencaoVeiculos>
		</div>
	</panelEagle>
</template>
<script>
import {
	mdiCalendarMonthOutline,
	mdiPlus,
	mdiCloudPrintOutline,
	mdiCloseThick,
	mdiCheckBold,
	mdiAlertCircleOutline
} from '@mdi/js'
import { required, minLength } from 'vuelidate/lib/validators'
import panelEagle from '@/components/Atom/Panel/PanelEagle'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { conectionError } from "@/Services/Helpers/swellHeper.js";
import { DateTime } from 'luxon';
import { CalendarView, CalendarViewHeader, } from 'vue-simple-calendar'
import { mapGetters } from 'vuex'
// import CalendarMath from "./CalendarMath"
export default {
	name: 'agendamento',

	components: {
		CalendarView,
		CalendarViewHeader,
		panelEagle,
		'titulo': require('@/components/Atom/Header/Titulo').default,
		'selectAll': require('@/components/Atom/Select/SelectAll').default,
		'simpleButton': require('@/components/Atom/Buttons/SimpleButton').default,
		'modalEagle': require('@/components/Atom/Modal/ModalEagle.vue').default,
		'loadingButton': require('@/components/Atom/Buttons/LoadingButton.vue').default,
		'slideUpAndDown': require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
		'modalInformacaoOS': require('@/components/Atom/ModalSpecific/ModalInformacaoOS').default,
		'statusInformation': require('@/components/Atom/StatusInformation/StatusInformation').default,
		'ModalDetalhesSolicitacaoManutencaoVeiculos': require('@/components/Atom/ModalSpecific/ModalDetalhesSolitacaoManutencao').default,
		'selectTag': require('@/components/Atom/Select/SelectTags').default,
	},

	validations: {
		valueVeiculoNovoRastreador: { required },
		empresasSelecionadas: { required },
		layoutSelecionado: { required },
		agendamentoSelecionado: { required },
		empresaNovoAgendamentoSelecionada: { required },
		veiculoSelecionadoAgendamento: { required },
		rastreadorChipSelecionadoAgendamento: { required },
		idSelecionadosimprimirOS: { required },
		motivoAgendamento: {
			required,
			minValue: minLength(15)
		},
		justificativaModalConfirmarOS: {
			required,
			minValue: minLength(15)
		},
	},

	computed: {
		desativa: function () {
			var valid = false
			var condIf = !this.$v.motivoAgendamento.$invalid &&
				!this.$v.empresaNovoAgendamentoSelecionada.$invalid &&
				!this.$v.rastreadorChipSelecionadoAgendamento.$invalid &&
				!this.$v.veiculoSelecionadoAgendamento.$invalid
			var condElseIf = !this.$v.motivoAgendamento.$invalid &&
				!this.$v.empresaNovoAgendamentoSelecionada.$invalid &&
				!this.$v.veiculoSelecionadoAgendamento.$invalid
			if (condIf) {
				valid = true
			} else if (condElseIf) {
				valid = true
			}
			return valid
		}
	},

	data() {
		return {
			valueTipoChipNovo: [],
			valueVeiculoNovoRastreador: [],
			loadingSelect: false,
			mdiCheckBold: mdiCheckBold,
			mdiCloseThick: mdiCloseThick,
			mdiCloudPrintOutline: mdiCloudPrintOutline,
			mdiPlus: mdiPlus,
			mdiCalendarMonthOutline: mdiCalendarMonthOutline,
			optSelectEmpresa: new EmpresasService().Get(),
			empresasSelecionadas: [],
			layoutSelecionado: [],
			periodoSelecionado: 1,
			ordensServico: [],
			arrastar: [],
			adicionou: false,
			theme: '',
			labelSeletorLayout: [
				{ 'indexDFH': 'V', 'description': 'Layout ' },
			],
			labelSeletorPeriodo: [
				{ 'indexDFH': 'P', 'description': 'Período ' },
			],
			labelSeletorTags: [
				{ 'indexTags': 'TAG', 'description': 'Tags ' },
			],
			showDate: new Date(),
			events: [],
			carregandoCalendario: false,
			statusbar: 'loading',
			optSelectLayout: [
				{ 'description': 'Mês', 'value': 'month' },
				{ 'description': 'Semana', 'value': 'week' },
			],
			optSelectPeriodo: [
				{ 'description': '1', 'value': '1' },
				{ 'description': '2', 'value': '2' },
			],
			// Modal Agendamento
			optSelectAgendamento: [
				{ 'description': 'Alteração de veículo', 'value': '5' },
				{ 'description': 'Retirada de rastreador', 'value': '4' },
				{ 'description': 'Troca de chip', 'value': '3' },
				{ 'description': 'Troca de rastreador', 'value': '2' },
				{ 'description': 'Visita técnica', 'value': '1' },
			],
			optSelectVeiculo: [],
			optSelectRastreadorChipAgendamento: [],
			tipoAgendamento: [],
			empresaNovoAgendamentoSelecionada: [],
			veiculoSelecionadoAgendamento: [],
			agendamentoSelecionado: [],
			rastreadorChipSelecionadoAgendamento: [],
			editOrdensServico: [],
			editOrdensServicoDia: [],
			motivoAgendamento: [],
			carregando: false,
			agendamentoEstaSelecionado: false,
			checkVerificacoesNovoAgendamento1: false,
			checkVerificacoesNovoAgendamento2: false,
			checkVerificacoesNovoAgendamento3: false,
			dadosInfoBox: [],
			carregandoSalvarOS: false,
			carregandoChips: false,
			veiculoMostrarNovoAgendamentoDia: [],
			dataDiaNovoAgendamento: [],
			loadingPanel: false,
			// Modal confirmar OS
			eventoSelecionadoConfirmarOS: [],
			dateSelConfirmarOs: [],
			justificativaModalConfirmarOS: [],
			carregandoConfirmarOS: false,
			carregandoCancelarOS: false,
			// Modal imprimor OS
			badgesSelecionados: [],
			badgesSelecionadosConfirmar: [],
			removeuBadge: false,
			carregandoImprimirOS: false,
			idImprimirOS: [],
			selecionadosBadgesImprimirOS: [],
			estaselecionadoBadgeImprimirOS: false,
			idSelecionadosimprimirOS: [],
			empresaSelecionadaBadgesImprimirOs: [],
			qtdSolicitacoes: [],
			solicitacoesManutencao: [],
			link: '/administrativo/cadastros/agendamento/',
			notificacaoVisivel: false,
			mdiAlertCircleOutline: mdiAlertCircleOutline,
			tagsSelecionadas: [],
			optSelectTag: [],
			carregandoTags: false,
			bloquearTags: false,
			selectedTags: [],
		}
	},

	methods: {
		// Início dos metódos para pegar informações dos selects/input
		async changeEmpresa(empresas, a, b) {
			this.selectedTags = [];
			this.editOrdensServico = [];
			this.tagsSelecionadas = [];
			this.empresasSelecionadas = empresas;
			await this.gerarOrdensDeServico('novo', this.showDate);
			this.consultarTags();
		},

		changeLayout(layout) {
			this.$refs.selectP.clearAll()
			this.periodoSelecionado = 1
			this.layoutSelecionado = layout
		},

		changePeriodo(periodo) {
			this.periodoSelecionado = Number(periodo[0])
		},

		// Fim dos métodos
		async gerarOrdensDeServico(qual, date) {
			// this.carregandoCalendario = true
			// this.loadingPanel = true
			var mes = []
			this.ordensServico = []
			this.editOrdensServicoDia = []
			//Se for = a novo significa que foi acionado pelo botão
			if (qual == 'novo') {
				this.editOrdensServico = []
				if (date) {
					// transforma de objeto data pada (yyyy-mm)
					var ano = (date.getFullYear())
					var month = (date.getMonth() + 1)
					var editMonth
					if (month <= 9) {
						editMonth = '0' + month
					} else {
						editMonth = month
					}
					mes = ano + '-' + editMonth
				} else {
					mes = DateTime.now().toFormat('yyyy-LL')
				}
				var layout = ''
				if (this.layoutSelecionado[0] == 'week') {
					layout = 'S'
				}
				if (this.layoutSelecionado[0] == 'month') {
					layout = 'M'
				}
				let dados = await new HttpRequest().Post(this.link + 'gerarOrdensDeServico',
					{
						'clientes': this.empresasSelecionadas,
						'mes': mes,
						'layout': layout,
						'tags': this.tagsSelecionadas
					},)
				if (dados.data != '') {
					this.ordensServico = [];
					this.editOrdensServico = [];
					this.ordensServico = dados.data.ordens;;
					let pendente = [];
					let y = [];
					// Adiciona as ordens pendentes a div ao lado do calendário
					if (this.ordensServico.P) {
						pendente = [this.ordensServico].forEach(element => {
							element.P.forEach(el => {
								let editData = el.data.split('/')
								let data = editData[2] + '/' + editData[1] + '/' + editData[0]
								this.editOrdensServico.push({
									id: el.codigo,
									title: el.cliente + ' ' + el.placa,
									startDate: data,
									endDate: data,
									classes: 'yellow-badge',
									status: el.status,
									codigoCliente: el.codigoCliente,
									originalItem: {
										id: el.codigo,
										title: el.cliente + ' ' + el.placa,
										startDate: data,
										endDate: data,
										classes: 'red-badge',
									}
								})
							})
						});
					}
				} else {
					this.statusbar = 'error'
				}
				this.events = []
				this.getRandomEvent()
			} else if (qual == 'diaCalendario') {
				var data = []
				// transforma de objeto data pada (yyyy-mm)
				var anoDia = (this.dataDiaNovoAgendamento.getFullYear())
				var monthDia = (this.dataDiaNovoAgendamento.getMonth() + 1)
				var editMonthDia
				if (monthDia <= 9) {
					editMonthDia = '0' + monthDia
				} else {
					editMonthDia = monthDia
				}
				data = anoDia + '-' + editMonthDia
				var dadosDia = await new HttpRequest().Post(this.link + 'gerarOrdensDeServico',
					{
						'clientes': this.empresasSelecionadas,
						'mes': data,
						'layout': layout,
						'tags': this.tagsSelecionadas
					},)
				if (dadosDia.data != '') {
					this.ordensServico = dadosDia.data.ordens
					var ordemDia = dadosDia.data.ordens
					var pendenteDia = []
					this.optSelectVeiculo.forEach(element => {
						if (element.value == this.veiculoSelecionadoAgendamento[0]) {
							this.veiculoMostrarNovoAgendamentoDia = element.description

						}
					});
					var veiculos = this.veiculoMostrarNovoAgendamentoDia.split(' | ')
					// Adiciona as ordens direto como agendadas dentro do dia selecionado
					if (ordemDia.P) {
						pendenteDia = [ordemDia].forEach(element => {
							element.P.forEach(el => {
								if (el.placa == veiculos[0]) {
									this.editOrdensServicoDia.push({
										id: el.codigo,
										title: el.cliente + ' ' + el.placa,
										startDate: this.dataDiaNovoAgendamento,
										endDate: this.dataDiaNovoAgendamento,
										classes: 'yellow-badge',
										status: 'P',
										codigoCliente: el.codigoCliente,
										originalItem: {
											id: el.codigo,
											title: el.cliente + ' ' + el.placa,
											startDate: this.dataDiaNovoAgendamento,
											endDate: this.dataDiaNovoAgendamento,
											classes: 'yellow-badge',
										}
									})
								}
							})
						});
						this.events = []
						this.saveDayOs(this.editOrdensServicoDia[0], this.dataDiaNovoAgendamento, 'P')
						this.dataDiaNovoAgendamento = []
					}
				} else {
					this.statusbar = 'error'
				}
			}
			this.loadingPanel = false
		},

		// Método para receber a div que o usuário esta arrastando para dentro do componente
		onDrag(elemento) {
			this.arrastar = elemento
		},

		// Método para inserir badge na data que o usuário arrastou
		async onDrop(events, date) {
			if (this.arrastar.length != 0) {
				events = this.arrastar
				events.startDate = date
				events.endDate = date
				this.adicionou = true
				this.saveDayOs(events, date, 'P')
				// Só pode ser movido entre os dias do calendario se tiver status A
			} else if (events.originalItem.status == 'A') {
				events.originalItem.startDate = date
				events.originalItem.endDate = date
				this.saveDayOs(events, date, 'A')
			}
		},

		// Salva o dia em que a OS foi largada
		// Temos uma verificação de se ela é pendente para poder alterar seu status (caso for pendente e 
		// seja colocada no calendário deve ter o status alterado para Agendado)
		async saveDayOs(events, date, qual) {
			var status = events.originalItem.status
			var formatingDate = date.toLocaleDateString("pt-BR")
			var editDate = formatingDate.split('/')
			let body = {
				'dia': editDate[0],
				'mes': editDate[2] + '-' + editDate[1],
				'ordemDServico': events.id,
				'status': 'A',
				'layout': 'M',
				'semana': ''
			}
			if (qual == 'P') {
				await new HttpRequest().Post(this.link + 'atualizaOrdem', body)
					.then(() => this.getRandomEvent(events))
			} else {
				body.status = status
				await new HttpRequest().Post(this.link + 'atualizaOrdem', body)
			}
			this.arrastar = []
		},

		// Método para gerar o evento no dia
		// Temos os if em cada ordem para verificar se elas existem, se existem serão geradas no calendário
		getRandomEvent(event) {
			var ordens = []
			ordens = this.ordensServico
			// Verifica se o elemento que estava pendente foi movido
			// Se foi movido irá adicionar no calendário (adicionar status A) e remover da div que estava
			if (this.adicionou == true) {
				pendente = [ordens].forEach(element => {
					element.P.forEach(el => {
						if (el.codigo == event.id) {
							var editData = el.data.split('/')
							var data = editData[2] + '/' + editData[1] + '/' + editData[0]
							this.events.push(y = {
								id: event.id,
								title: event.title,
								startDate: event.endDate,
								endDate: event.endDate,
								classes: 'blue-badge',
								status: 'A',
								codigoCliente: el.codigoCliente,
								originalItem: {
									id: event.id,
									title: event.title,
									startDate: event.endDate,
									endDate: event.endDate,
									classes: 'blue-badge',
								}
							})
						}
					});
					// Remove da div ao lado do calendário se o badge foi movido para dentro do calendário
					for (let index = 0; index < this.editOrdensServico.length; index++) {
						if (this.editOrdensServico[index].id == this.arrastar.id) {
							this.editOrdensServico.splice(index, 1)
						}
					}
				});
				this.adicionou = false
			} else if (this.editOrdensServicoDia.length > 0) {
				// Se foi adicionado selecionando uma data no calendário cai aqui para ser gerado na data selecionada
				var OrdensDia = this.editOrdensServicoDia[0]
				if (OrdensDia) {
					this.events.push(y = {
						id: OrdensDia.id,
						title: OrdensDia.title,
						startDate: OrdensDia.startDate,
						endDate: OrdensDia.startDate,
						classes: 'blue-badge',
						status: 'A',
						codigoCliente: OrdensDia.codigoCliente,
						originalItem: {
							id: OrdensDia.id,
							title: OrdensDia.title,
							startDate: OrdensDia.startDate,
							endDate: OrdensDia.startDate,
							classes: 'blue-badge',
						}
					})
				}

				var canceladoDia = []
				var agendadoDia = []
				var pendenteDia = []
				var finalizadoDia = []
				var yDia = []
				var retornoDia = []

				if (ordens.C) {
					canceladoDia = [ordens].forEach(element => {
						element.C.forEach(el => {
							var editData = el.data.split('/')
							var data = editData[2] + '/' + editData[1] + '/' + editData[0]
							this.events.push(yDia = {
								id: el.codigo,
								title: el.cliente + ' ' + el.placa,
								startDate: data,
								endDate: data,
								classes: 'red-badge',
								status: el.status,
								codigoCliente: el.codigoCliente,
								originalItem: {
									id: el.codigo,
									title: el.cliente + ' ' + el.placa,
									startDate: data,
									endDate: data,
									classes: 'red-badge',
								}
							})
						})
					});
				}

				if (ordens.A) {
					agendadoDia = [ordens].forEach(element => {
						element.A.forEach(el => {
							var editData = el.data.split('/')
							var data = editData[2] + '/' + editData[1] + '/' + editData[0]
							this.events.push(yDia = {
								id: el.codigo,
								title: el.cliente + ' ' + el.placa,
								startDate: data,
								endDate: data,
								classes: 'blue-badge',
								status: el.status,
								codigoCliente: el.codigoCliente,
								originalItem: {
									id: el.codigo,
									title: el.cliente + ' ' + el.placa,
									startDate: data,
									endDate: data,
									classes: 'blue-badge',
								}
							})
						})
					});
				}
				if (ordens.F) {
					finalizadoDia = [ordens].forEach(element => {
						element.F.forEach(el => {
							var editData = el.data.split('/')
							var data = editData[2] + '/' + editData[1] + '/' + editData[0]
							this.events.push(yDia = {
								id: el.codigo,
								title: el.cliente + ' ' + el.placa,
								startDate: data,
								endDate: data,
								classes: 'green-badge',
								status: el.status,
								codigoCliente: el.codigoCliente,
								originalItem: {
									id: el.codigo,
									title: el.cliente + ' ' + el.placa,
									startDate: data,
									endDate: data,
									classes: 'green-badge',
								}
							})
						})
					});
				}
			} else {
				var cancelado = []
				var agendado = []
				var pendente = []
				var finalizado = []
				var y = []
				var retorno = []
				if (ordens.C) {
					cancelado = [ordens].forEach(element => {
						element.C.forEach(el => {
							var editData = el.data.split('/')
							var data = editData[2] + '/' + editData[1] + '/' + editData[0]
							this.events.push(y = {
								id: el.codigo,
								title: el.cliente + ' ' + el.placa,
								startDate: data,
								endDate: data,
								classes: 'red-badge',
								status: el.status,
								codigoCliente: el.codigoCliente,
								originalItem: {
									id: el.codigo,
									title: el.cliente + ' ' + el.placa,
									startDate: data,
									endDate: data,
									classes: 'red-badge',
								}
							})
						})
					});
				}
				if (ordens.A) {
					agendado = [ordens].forEach(element => {
						element.A.forEach(el => {
							var editData = el.data.split('/')
							var data = editData[2] + '/' + editData[1] + '/' + editData[0]
							this.events.push(y = {
								id: el.codigo,
								title: el.cliente + ' ' + el.placa,
								startDate: data,
								endDate: data,
								classes: 'blue-badge',
								status: el.status,
								codigoCliente: el.codigoCliente,
								originalItem: {
									id: el.codigo,
									title: el.cliente + ' ' + el.placa,
									startDate: data,
									endDate: data,
									classes: 'blue-badge',
								}
							})
						})
					});
				}
				if (ordens.F) {
					finalizado = [ordens].forEach(element => {
						element.F.forEach(el => {
							var editData = el.data.split('/')
							var data = editData[2] + '/' + editData[1] + '/' + editData[0]
							this.events.push(y = {
								id: el.codigo,
								title: el.cliente + ' ' + el.placa,
								startDate: data,
								endDate: data,
								classes: 'green-badge',
								status: el.status,
								codigoCliente: el.codigoCliente,
								originalItem: {
									id: el.codigo,
									title: el.cliente + ' ' + el.placa,
									startDate: data,
									endDate: data,
									classes: 'green-badge',
								}
							})
						})
					});
				}
			}
			this.carregandoCalendario = false
		},

		// Ajusta tamanho dos bads
		themeOptions() {
			this.theme = {
				top: '2.5em',
				height: '3.8em',
				left: '2em',
			}
		},

		setShowDate(date) {
			this.gerarOrdensDeServico('novo', date)
			this.showDate = date;
		},

		onClickDate(data) {
			this.dataDiaNovoAgendamento = data
			this.novoAgendamento()
		},

		onClickItems(events) {
			if (events.originalItem.status == 'A') {
				this.eventoSelecionadoConfirmarOS = events.id
				this.dateSelConfirmarOs = events.startDate
				this.confirmarOs()
			} else {
				this.buscaInformacoesOS(events.originalItem.id)
			}
		},

		// Imprimir OS -----------------------------------------------------------------------------------------
		imprimirOs() {
			this.buttonsBadgesImprimirOS(this.events)
			this.$bvModal.show('imprimirOs')
		},

		changeEmpresaBadgesImprimirOs(empresa) {
			this.empresaSelecionadaBadgesImprimirOs = empresa
			this.buttonsBadgesImprimirOS(this.events)
		},

		/** 
		 * @description Método para adicionar os id das OS selecionadas
		 *  para impressão
		 * @param {Number} id = código da OS
		 * @author Vitor Hugo
		 */
		changeSelecionadosBadgesImprimirOS(id) {
			var index = this.idSelecionadosimprimirOS.findIndex(element => {
				return element === id
			})
			if (index != -1)
				this.idSelecionadosimprimirOS.splice(index, 1)
			else
				this.idSelecionadosimprimirOS.push(id)
		},

		// Método verifica se o status é A e se foi selecionado uma empresa para ser filtrada 
		// se tiver insere os valores conforme a empresa caso não insere todos os valores conforme o status
		buttonsBadgesImprimirOS(badges) {
			this.selecionadosBadgesImprimirOS = []
			var empresaSelecionada = false
			for (let index = 0; index < badges.length; index++) {
				//Altera a ordem dos dados do título para aparecer no badge
				var dadosTitulo = badges[index].title.split(' ')
				var empresa = ''
				for (let index = 0; index < dadosTitulo.length - 1; index++) {
					empresa = empresa + ' ' + dadosTitulo[index]
				}
				var placa = dadosTitulo[dadosTitulo.length - 1]
				if (badges[index].status == 'A') {
					if (this.empresaSelecionadaBadgesImprimirOs.length == 0) {
						var editDateTodos = DateTime.fromJSDate(badges[index].startDate).toLocaleString(DateTime.DATE_SHORT)
						if (editDateTodos == 'Invalid DateTime') {
							editDateTodos = DateTime.fromFormat(badges[index].startDate, 'yyyy/LL/dd').toLocaleString(DateTime.DATE_SHORT)
						}
						var dateTodos = editDateTodos.toLocaleString(DateTime.DATE_SHORT)
						this.selecionadosBadgesImprimirOS.push(
							{
								'value': badges[index].id,
								'title': badges[index].title,
								'placa': placa,
								'data': dateTodos,
								'cliente': empresa,
								'width': '100%',
								'isSelected': false,
								'extraClass': 'buttonsBadgesimprimirOs col-sm-2 row'
							}
						)
						empresaSelecionada = true
					} else if (this.empresaSelecionadaBadgesImprimirOs == badges[index].codigoCliente &&
						empresaSelecionada == false) {
						// var editDate = DateTime.fromFormat(badges[index].startDate, 'yyyy/LL/dd')
						var editDate = DateTime.fromJSDate(badges[index].startDate).toLocaleString(DateTime.DATE_SHORT)
						if (editDate == 'Invalid DateTime') {
							editDate = DateTime.fromFormat(badges[index].startDate, 'yyyy/LL/dd').toLocaleString(DateTime.DATE_SHORT)
						}
						var date = editDate.toLocaleString(DateTime.DATE_SHORT)
						this.selecionadosBadgesImprimirOS.push({
							'value': badges[index].id,
							'title': badges[index].title,
							'placa': placa,
							'data': date,
							'cliente': empresa,
							'width': '100%',
							'isSelected': false,
							'extraClass': 'buttonsBadgesimprimirOs col-sm-2 row'
						})
					}
				}
			}
			this.$forceUpdate();
		},

		// Adiciona ou remove de uma array para enviar os back e exportar
		// Primeiro if verifica se já tem algum badge selecionado se não tiver adiciona o badge na variavel
		// caso tenha percorre a variavel com todos os badges selecionados e procura se o badge selecionado
		// ja esta na variavel de badges selecionados, se tiver remove ele desta variavel se não adiciona ele
		// a variavel
		BadgeSelecionado(badge, id) {
			if (this.badgesSelecionados.length == 0) {
				this.badgesSelecionados.push(badge)
				this.idImprimirOS.push(id)
			} else {
				for (let index = 0; index < this.badgesSelecionados.length; index++) {
					if (this.badgesSelecionados[index].id == badge.id) {
						this.badgesSelecionados.splice(index, 1)
						this.idImprimirOS.splice(index, 1)
						this.removeuBadge = true
					}
				}
				if (this.removeuBadge == false) {
					this.badgesSelecionados.push(badge)
					this.idImprimirOS.push(id)
				}
			}
			this.estaselecionadoBadgeImprimirOS = this.removeuBadge
			this.removeuBadge = false
		},

		async confirmarImprimirOs() {
			this.carregandoImprimirOS = true
			var root = process.env.VUE_APP_ROOT;
			var dados = await new HttpRequest()
				.Post(this.link + 'imprimeOS',
					{ 'os': this.idSelecionadosimprimirOS },
				)
			if (dados.status) {
				window.open(root + '/' + dados.data)
			}
			this.carregandoImprimirOS = false
		},

		cancelarImprimirOS() {
			this.$bvModal.hide('imprimirOs')
		},

		// Modal Novo Agendamento ------------------------------------------------------------------------------
		async novoAgendamento() {
			this.checkVerificacoesNovoAgendamento1 = false
			this.checkVerificacoesNovoAgendamento2 = false
			this.checkVerificacoesNovoAgendamento3 = false
			await this.$bvModal.show('novoAgendamento')
			this.limpaVar()
		},

		limpaVar() {
			this.$refs.refSelectChipModalOs.clearAll()
			this.$refs.refSelectRastreadorModalOs.clearAll()
			this.$refs.refSelectVeiculoNovoModalOs.clearAll()
			this.$refs.refSelectPadraoEmpresaModalOs.clearAll()
			this.$refs.refSelectPadraoVeiculoModalOs.clearAll()
			this.motivoAgendamento = ''
			this.empresaNovoAgendamentoSelecionada = []
			this.veiculoSelecionadoAgendamento = []
			this.rastreadorChipSelecionadoAgendamento = []
			this.tipoAgendamento = []
			this.editOrdensServicoDia = []
			this.agendamentoEstaSelecionado = false
		},

		// Início dos metódos para pegar informações dos selects/input
		changeAgendamento(agendamento) {

			this.trocarChip = agendamento;

			this.limpaVar()
			if (agendamento.length != 0) {
				this.agendamentoEstaSelecionado = true
			} else {
				this.agendamentoEstaSelecionado = false
			}
			this.agendamentoSelecionado = agendamento[0]
			this.tipoAgendamento = agendamento
		},

		async changeVeiculoNovoAgendamento(veiculo) {
			this.veiculoSelecionadoAgendamento = veiculo
			if (veiculo.length) {
				var dados = await new HttpRequest().Post(this.link + 'getInfoForBox', { 'veiculo': veiculo[0] },)
				this.dadosInfoBox = dados.data
				if ((this.trocarChip != 1 && this.trocarChip[0] != 2) && this.dadosInfoBox.serial == null) {
					conectionError('', 'warning', 'Este veiculo não possui um módulo associado!')
					this.veiculoSelecionadoAgendamento = []
					this.$refs.refSelectPadraoVeiculoModalOs.clearAll()
				}
			}
		},

		async changeEmpresaNovoAgendamento(empresa) {
			this.loadingSelect = true
			this.dadosInfoBox = ""
			this.$refs.refSelectPadraoVeiculoModalOs.clearAll()
			this.$refs.refSelectChipModalOs.clearAll()
			this.getRastChipModal(this.agendamentoSelecionado)
			this.empresaNovoAgendamentoSelecionada = empresa
			var dados = await new FiltrosService().dados_filtros(empresa, ['V'])
			this.optSelectVeiculo = dados['V']
			this.loadingSelect = false
		},

		changeNovoVeiculoNovoAgendamento(novoVeiculo) {
			this.valueVeiculoNovoRastreador = novoVeiculo
			this.rastreadorChipSelecionadoAgendamento = novoVeiculo
		},

		changeRastreadorNovoAgendamento(rastreador) {
			this.rastreadorChipSelecionadoAgendamento = rastreador
		},

		changeChipNovoAgendamento(chip) {
			this.rastreadorChipSelecionadoAgendamento = chip
		},

		// Fim dos métodos
		fecharNovoAgendamento() {
			this.$bvModal.hide('novoAgendamento')
		},

		async getRastChipModal(tipo) {
			this.carregandoChips = true
			var dados = await new HttpRequest()
				.Post(this.link + 'getRastChipModal', { 'tipo': tipo },)
			if (dados.data.itens.length != 0) {
				this.optSelectRastreadorChipAgendamento = dados.data.itens
			}
			this.carregandoChips = false
		},

		// Método salva uma nova OS
		async saveNewOS() {
			this.carregandoSalvarOS = true
			let body = {
				'empresa': this.empresaNovoAgendamentoSelecionada[0],
				'motivo': this.motivoAgendamento,
				'novo': this.rastreadorChipSelecionadoAgendamento[0],
				'veiculo': this.veiculoSelecionadoAgendamento[0],
				'tipo': this.tipoAgendamento[0]
			}
			var dados = await new HttpRequest().Post(this.link + 'saveNewOS', body)
			if (dados.status && dados.data) {
				this.carregandoSalvarOS = false
				// Se existir uma data quer dizer que a forma de cadastro de uma OS foi por meio do calendário
				if (this.dataDiaNovoAgendamento.length == 0) {
					this.gerarOrdensDeServico('novo', this.showDate)
				} else {
					this.gerarOrdensDeServico('diaCalendario')
				}
				this.$bvModal.hide('novoAgendamento')
			}
			this.carregandoSalvarOS = false
		},

		// Modal Confirmar OS --------------------------------------------------------------------------------------------
		confirmarOs() {
			this.$bvModal.show('confirmarOs')
		},

		sairConfirmarOs() {
			this.$bvModal.hide('confirmarOs')
		},

		cancelarConfirmarOs() {
			this.justificativaModalConfirmarOS = []
			this.$bvModal.show('justificarCancelarConfirmarOS')
		},

		// Confirma e execução de uma OS
		async confirmarConfirmarOs() {

			this.carregandoConfirmarOS = true
			var formatingDate = this.dateSelConfirmarOs.toLocaleDateString("pt-BR")
			var editDate = formatingDate.split('/')

			let body = {
				'dia': editDate[0],
				'mes': editDate[2] + '-' + editDate[1],
				'ordemDServico': this.eventoSelecionadoConfirmarOS,
				'status': 'F',
				'layout': 'M',
				'semana': ''
			}

			var dados = await new HttpRequest().Post(this.link + 'atualizaOrdem', body)

			if (dados.data.ok == 'sucesso!') {
				this.$bvModal.hide('confirmarOs')
				this.carregandoConfirmarOS = false
				this.gerarOrdensDeServico('novo', this.dateSelConfirmarOs)
			} else if (dados.data.erro) {
				this.$bvModal.hide('confirmarOs')
				this.carregandoConfirmarOS = false
				conectionError('', 'warning', dados.data.erro)
			}

			this.carregandoConfirmarOS = false
		},

		// Modal Justificativa OS ----------------------------------------------------------------------------------
		// Salva o cancelamento de uma OS
		cancelarJustificativaOS() {
			this.$bvModal.hide('justificarCancelarConfirmarOS')
			this.$bvModal.hide('confirmarOs')
		},

		async confirmarJustificativaOs() {
			this.carregandoCancelarOS = true
			var formatingDate = this.dateSelConfirmarOs.toLocaleDateString("pt-BR")
			var editDate = formatingDate.split('/')
			let body = {
				'dia': editDate[0],
				'mes': editDate[2] + '-' + editDate[1],
				'ordemDServico': this.eventoSelecionadoConfirmarOS,
				'status': 'C',
				'layout': 'M',
				'semana': '',
				'justificativa': this.justificativaModalConfirmarOS
			}
			var dados = await new HttpRequest().Post(this.link + 'atualizaOrdem', body)
			if (dados.data.ok == 'sucesso!') {
				this.$bvModal.hide('confirmarOs')
				this.$bvModal.hide('justificarCancelarConfirmarOS')
				this.carregandoCancelarOS = false
				this.gerarOrdensDeServico('novo', this.dateSelConfirmarOs)
			}
			this.carregandoCancelarOS = false
		},

		selectAllClientes() {
			if (this.getMaster())
				this.firstOne = []
			else
				this.firstOne = new EmpresasService().Get()
		},

		...mapGetters(['getMaster']),

		// Modal Informaçoes OS ----------------------------------------------------------------------------------------------------------------------

		buscaInformacoesOS(idOS) {
			this.$refs.informacoesOS.preparaModal(idOS)
		},

		// Modal Solicitação Manutenções

		/**
		 * @description - A requisicaoSolicitacaoManutencao é um método que foi incluído no mounted. 
		 * O que ela faz: realiza uma requisição para receber os dados das solicitações de manutenção;
		 * Dentro do método, ela faz uma condição para filtrar os dados a partir de uma coluna (smlido) booleana,-
		 * pegando somente os falsos, que representa as solicitações de manutenção não lidas (não necessariamente não lidas, mas-
		 * sim que não receberam a confirmação de leitura, que é um checkbox do modal de solicitação de manutenção, do usuário)
		 * @author Daniel Vitor Nascimento
		 */
		requisicaoSolicitacaoManutencao() {
			this.qtdSolicitacoes = []
			this.loadingPanel = true
			new HttpRequest()
				.Post(this.link + 'get/solicitacao/manutencao')
				.then(dados => {
					if (dados.code == 200) {
						this.solicitacoesManutencao = dados.data.dadosSolicitacaoManutencao
						this.solicitacoesManutencao.forEach(element => {
							if (element.smlido == false) {
								this.qtdSolicitacoes.push(element)
							}
						})
						this.loadingPanel = false
					}
				});
		},

		visualizarDetalhesSoliManu() {
			this.$bvModal.show('modal-detalhes-solicitacao-manutencao');
		},

		modalCloseSoliManu() {
			this.requisicaoSolicitacaoManutencao()
		},

		async setTag(tag) {
			this.tagsSelecionadas = tag;
			await this.gerarOrdensDeServico('novo', this.showDate);
		},

		/**
		 * Consulta e retorna as tags associadas a uma empresa.
		 * 
		 * Esta função envia uma requisição POST para consultar as tags associadas a uma empresa
		 * e atualiza a lista de opções de seleção de tags no frontend.
		 *
		 * @return {void}
		 * @autor Yuri 🇧🇷
		 */
		async consultarTags() {
			this.carregandoTags = true;

			const url = this.link + 'consultar/tags'
			const obj = {
				codigoEmpresa: this.empresasSelecionadas
			}

			try {
				await new HttpRequest().Post(url, obj).then((data) => {
					if(data.data.length <= 0){
						this.bloquearTags = true;
					}else{
						this.optSelectTag = data.data;
						this.bloquearTags = false;
					}
					
				}).catch((error) => {
					this.carregandoTags = false;
				}).finally(() => {
					this.carregandoTags = false;
				});
			} catch (error) {
				this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
				this.carregandoTags = false;
			}
		},
	},

	async mounted() {
		this.requisicaoSolicitacaoManutencao()
		await this.gerarOrdensDeServico('novo')
		this.themeOptions()
	},
}

</script>

<style lang="scss">
.divCalendario {
	height: 600px !important;
	padding-right: 2px !important;
}

div.cv-wrapper {
	div.cv-header {
		height: 55px;
		background: #5597cf;
		color: #fff;

		div.cv-header-nav {
			width: 60%;

			button {
				width: 25%;
				color: #fff;
				background-image: linear-gradient(to bottom left, #2d6494, #75aad8);

				&:hover {
					cursor: pointer;
					background-image: linear-gradient(to bottom left, #75aad8, #2d6494);
				}
			}

			button.currentPeriod {
				display: none;
				width: 50%;
			}
		}

		div.periodLabel {
			text-align: right !important;
			width: 40%;
		}
	}

	div.cv-header-days {
		div.cv-header-day {
			background: #f2f2f2 !important;
		}

		div.cv-header-day.dow6 {
			padding-left: 2%;
			background: #f2f2f2 !important;
		}
	}

	div.cv-weeks {

		// Semana
		div.cv-week {

			// background: black !important;
			// Outra div de semana
			.cv-weekdays {
				// Dia do calendario
				overflow: scroll;
				overflow-x: hidden;

				// display: flex !important;
				div.cv-day {
					// overflow-y: auto !important;
					background: #fff;

					// Número do dia
					div.cv-day-number {
						overflow-y: auto;
						color: #2071b8;
						position: absolute;
						z-index: 1000 !important;
					}
				}

				// span do evento
				div.cv-item {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 11px;
					overflow-y: auto !important;
					color: #fff;
					height: 3.7em !important;
					margin-left: 12px;
					padding-top: 10px;
				}
			}
		}
	}
}

.trLegendaAgendamentoTipo {
	display: inline-block;
	width: 100%;
	padding-top: 3px;
	padding-bottom: 3px;
	background: #f2f2f2;
}

.tdLegendaAgendamentoTipo {
	display: inline-block;
	width: 50%;
	vertical-align: bottom !important;
}

.flaticon-icon099::before {
	font-size: 17px;
}

.amarelo {
	color: #f4b610;
}

.azul {
	color: #2f74b1;
}

.verde {
	color: #43b327;
}

.vermelho {
	color: #ca4b4b;
}

//Bedges calendario
.cv-item.red-badge {
	margin-bottom: 10px !important;
	width: 12% !important;
	background-image: linear-gradient(to bottom left, #ca4b4b, #d87575);
	border: 1px solid #ca4b4b;
	box-shadow: 1px 3px 4px #a34141;
	border-radius: 0px;
	white-space: normal;

	span {
		display: inline-block;
	}

	&:hover {
		cursor: grabbing;
		background-image: linear-gradient(to bottom left, #d87575, #ca4b4b);
		box-shadow: 2px 5px 6px #a34141;
	}
}

.cv-item.green-badge {
	margin-bottom: 10px !important;
	width: 12% !important;
	background-image: linear-gradient(to bottom left, #43b327, #7eca97);
	border: 1px solid #43b327;
	box-shadow: 1px 3px 4px #42912e;
	border-radius: 0px;
	white-space: normal;

	span {
		display: inline-block;
	}

	&:hover {
		cursor: grabbing;
		background-image: linear-gradient(to bottom left, #7eca97, #43b327);
		box-shadow: 2px 5px 6px #42912e;
	}
}

.cv-item.blue-badge {
	margin-bottom: 10px !important;
	width: 12% !important;
	background-image: linear-gradient(to bottom left, #2f74b1, #75aad8);
	border: 1px solid #2f74b1;
	box-shadow: 1px 3px 4px #235581;
	border-radius: 0px;
	white-space: normal;
	font-weight: bold;

	span {
		display: inline-block;
	}

	&:hover {
		cursor: grabbing;
		background-image: linear-gradient(to bottom left, #75aad8, #2f74b1);
		box-shadow: 2px 5px 6px #235581;
	}
}

.divBadge {
	width: 125px;
	margin-bottom: 10px !important;
	margin-right: 10px !important;
	background-image: linear-gradient(to bottom left, #f4b610, #f1d791);
	border: 1px solid #f4b610;
	box-shadow: 1px 3px 4px #d3a526;
	border-radius: 0px;
	font-size: 12px;
	font-weight: bold;

	span {
		display: inline-block;
	}

	&:hover {
		cursor: grabbing;
		background-image: linear-gradient(to bottom left, #f1d791, #f4b610);
		box-shadow: 2px 5px 6px #d3a526;
	}
}

.listaBadge {
	padding-left: 30px !important;
}

// modal agendamento
// Selecionar Agendamento
.spanNovoAgendamentoSelecionar {
	vertical-align: middle !important;
}

// Verificações
.spanVerificacoesNovoAgendamento {
	padding-left: 15px;
	padding-bottom: 10px;
	text-align: center;
}

.borderDangerAgendamento {
	border: 1px solid #ca4b4b;
}

// Visita técnica / Retirada
.divVisitaTecnicaNovoAgendamento {
	padding-left: 0px !important;
}

.divSelectAllNovoAgendamento {
	padding-left: 0px !important;
	margin-bottom: 10px !important;
}

.divTituloTabelaNovoAgend {
	padding-left: 0px !important;
}

.dadosNovoAgendamento {
	border: 1px solid #c0c0c0;
	background: #f5f5f5;
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}

.tdDadosNovoAgendTitle {
	font-weight: bold;
	padding-left: 10px !important;
}

.tdDadosNovoAgendDados {
	padding-right: 10px !important;
}

// Modal imprimir OS
.buttonsBadgesimprimirOsSelecionados {
	height: 87px !important;
	color: #fff !important;
	margin-bottom: 10px !important;
	margin-right: 10px !important;
	background-image: linear-gradient(to bottom left, #2f74b1, #75aad8) !important;
	border: 1px solid #2f74b1 !important;
	box-shadow: 1px 3px 4px #235581 !important;
	font-size: 14px !important;
	font-weight: bold !important;

	&:hover {
		cursor: pointer !important;
		background-image: linear-gradient(to bottom left, #75aad8, #2f74b1) !important;
		box-shadow: 2px 5px 6px #235581 !important;
	}
}

.buttonsBadgesimprimirOs {
	height: 87px !important;
	white-space: normal !important;
	color: #000000;
	margin-top: 10px !important;
	margin-bottom: 10px !important;
	margin-right: 10px !important;
	background-image: linear-gradient(to bottom left, #b1b1b1f1, #ffffff);
	border: 1px solid #afafaff1 !important;
	box-shadow: 1px 3px 4px #8f8f8f !important;
	font-size: 14px !important;
	font-weight: bold;
	contain: content;
}

.descricaoImprimirOS {
	padding-top: 3%;
}

.divNotificacaoSolicitacaoManutencao {
	font-weight: bold;
	padding: 5px;
	border: 1px solid #afafaff1 !important;
	box-shadow: 1px 3px 4px #8f8f8f !important;
	color: #000000;
	text-align: center;
	width: 50%;
	background: #ffc6c6;
	position: center;
	cursor: pointer;
}

#imprimirOs {
	.fundoBranco {
		border: 1px solid #afafaff1 !important;
		box-shadow: 1px 3px 4px #8f8f8f !important;
		vertical-align: middle;
		background: #fff;
		cursor: pointer;
	}

	.fundoAzul {
		border: 1px solid #afafaff1 !important;
		background: #4b90cc;
		box-shadow: 1px 3px 4px #8f8f8f !important;
		color: #fff;
		vertical-align: middle;
		cursor: pointer;
	}

	.bold {
		font-weight: bold;
	}
}
</style>
