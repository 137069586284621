<template>
	<panel-eagle id='extrato-checkpoint' :loading='loading'>
		<div class="col-12 row mb-2">
			<div class="col-6 nopadding">
				<titulo :icon='mdiClipboardTextClock' titulo='Controle de Registros' />
			</div>
			<div class="col-6 nopadding" align='right'>
				<basicButtonsRelatoriosEIG :loading='loadingExportar' :formatEnable='{ pdf: true }' :disabled='$v.$invalid'
					:disabledDropdown='!mostraTabela' @gerarRelatorio='gerarRelatorio' @exportarRelatorio='exportarRelatorio' />
			</div>
		</div>
		<slide-up-and-down>
			<div class="col-sm-12">
				<hr>
			</div>
			<div class="col-12 nopadding row">
				<div class="col-4 nopadding">
					<input-range :isObrigatorio='true' name='range-extrato' :dataMaxima='dataHoje' @changeInput='changeIntervalo' />
				</div>
				<div class="col-4 nopadding">
					<select-all nameForRadio='selCliente' :isMultiple='true' :hasSelectAll='true' :labels='labelSelectCliente'
						:optionsArray='optClientes' firstSelected='MR' :extraClass='{
							"border border-danger": $v.clienteSelecionado.$anyError
						}' @close='$v.clienteSelecionado.$touch()' @changeSelect='changeEmpresa' />
				</div>
				<div class='col-4 nopadding'>
					<select-all nameForRadio='selTrabalhador' :optionsArray='optTrabalhador' ref='selTrabalhador'
						:disabled='desabilitaSeletorT' :labels='labelSelectTrabalhador' :isMultiple='true' :hasSelectAll='true'
						:loading='loadingTrabalhador' :extraClass='{
							"border border-danger": $v.trabalhadorSelecionado.$anyError
						}' firstSelected='MAD' @close='$v.trabalhadorSelecionado.$touch()' @changeSelect='changeTrabalhador'
						@changeCheck='changeCTrabalhador' />
				</div>
			</div>
			<div class='col-12 mt-2'>
				<b-check v-model='diasNaoTrabalhados' class='d-flex justify-content-start'>
					Esconder dias de descanso
				</b-check>
			</div>
		</slide-up-and-down>
		<div class="col-sm-12">
			<hr>
		</div>
		<div class="col-12">
			<table-simples id='table-extrato'>
				<thead>
					<tr>
						<th id='header-clock' class='pdf-situacao'>
							Situação
						</th>
						<th class='pdf-data'>Data</th>
						<th>Entrada-Saída</th>
						<th title='Horas Normais'>H.N.</th>
						<th title='Horas Falta'>H.F.</th>
						<th title='Horas Extra'>H.E.</th>
						<th>Saldo</th>
						<th class="no-pdf">Ações</th>
					</tr>
				</thead>
				<tbody v-if='mostraTabela'>
					<template v-for='(data, mtcodigo) in tableDataShow'>
						<tr :key='`${mtcodigo}_titulo`' id="linha-nome">
							<td colspan='2' class='nome-trabalhador'>
								{{ procuraNome(mtcodigo) }}
							</td>
						</tr>

						<tr :key='`${mtcodigo}_ghost`' id='spacing'>
							<div style="display: none;">a</div>
						</tr>

						<template v-for='(info, dia) in data'>
							<tr :key='`${mtcodigo}_${dia}_informacoes`'>
								<td class="situacao-pdf col-sm-2">
									<div v-bind="decideFormatoSituacao(info, dia)" class='retangulo-status'
										@click="clickStatus(info, mtcodigo, dia)" style="width: 100%; font-weight: 700;" />
								</td>
								<td class='pdf-data'>{{ formataData(dia, info) }}</td>
								<td>{{ entradaSaida(mtcodigo, info) }}</td>
								<td>{{ folhaPontoHorasNormais(info) }}</td>
								<td>{{ folhaPontoHorasFalta(info) }}</td>
								<td>{{ folhaPontoHorasExtra(info) }}</td>
								<td class="col-sm-1">
									<div class='saldo-worker' v-bind="decideSaldoBind(info)">
										{{ folhaPontoSaldo(info) }}
									</div>
								</td>
								<td class="no-pdf col-sm-1">
									<div class='d-flex justify-content-start'>
										<div v-if="info.deletarJornada">
											<div v-if="getExcluir()">
												<simple-button type='red' width='60px' :icon='mdiTrashCan' title='Deletar jornada' event='click'
													@click='abreModalDeletarJornada(info)' />
											</div>
										</div>
										<simple-button type='aqua-marine' width='60px' :icon='iconEye' title='Detalhes da jornada'
											event='click' @click='muda_acordeon(mtcodigo, dia)' />
										<simple-button v-if='podeEditar' :disabled='temAjustePendente(info)' type='aqua-marine' width='60px'
											event='click' :icon='iconPencil' title='Ajustar jornada' @click='abreModal(mtcodigo, dia, info)' />
									</div>
								</td>
							</tr>

							<tr :key='`${mtcodigo}_${dia}_collapse`' class='linha-que-some'>
								<td colspan="9">
									<b-collapse :id='`collapse_${mtcodigo}_${dia}`'>
										<div class='d-flex justify-content-start' v-if='info.registros.length'>
											<div class='entradas-saidas-collapse' :key='`${i}_${mtcodigo}_${dia}`'
												v-for='(etv, i) in dvlPares(info.registros)'>
												<div v-html='formataEvento(
													etv[0], i,
													"Entrada",
													info.ajuste
												)' :class='temAjuste(etv[0])' @click='abreAjuste(mtcodigo, etv[0])' />
												<div v-html='formataEvento(
													etv[1], i,
													"Saida",
													info.ajuste
												)' :class='temAjuste(etv[1])' @click='abreAjuste(mtcodigo, etv[1])' />
											</div>
											<div class='entradas-saidas-collapse unselectable' v-for='(ev, i) in eventosHoje(info.eventos, dia)'
												:key='`${i}__${dia}__evento`'>
												<div class='io-show' id='eventos-com-ponto' v-html='criaHtmlEvento(ev)' />
											</div>
										</div>
										<div v-else class='d-flex justify-content-center'>
											<div id='eventos-sem-ponto' :key='`${i}__evento__${dia}`' class='ev-col unselectable'
												v-for='(evento, i) in infoDiaSemPontos(info, dia)' v-html='criaHtmlEvento(evento)' />
										</div>
									</b-collapse>
								</td>
							</tr>
						</template>
					</template>
				</tbody>
				<status-bar v-else :statusBar='statusBar' typeBar='tr' />
			</table-simples>
		</div>
		<ajuste-stepper ref='ajustes' id='ajuste-stepper' @reload-dados='gerarRelatorio' />
		<modal-files ref='modalFiles' />

		<!-- modal deletar jornada -->
		<modalEagle @confirmButton="confirmaDeletarJornada()" id="modalDeletarJornada" title="Deletar jornada"
			textoConfirmar="Confirmar" :tamanhoBtn="'110px'">
			<template #modalBody>
				<p>
					Ao confirmar essa operação não será mais possível revertê-la! Tem certeza que
					deseja fazer isso?
				</p>
			</template>
		</modalEagle>
	</panel-eagle>
</template>
<script>
import {
	mdiClipboardClock,
	mdiClock,
	mdiChevronDownCircleOutline,
	mdiAlertCircleOutline,
	mdiEye,
	mdiPencil,
	mdiTrashCan
} from '@mdi/js'
import { required } from 'vuelidate/lib/validators'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { DateTime, Duration } from 'luxon'
import { mapGetters } from 'vuex'
import { conectionError } from '@/Services/Helpers/swellHeper'

const urlBase = '/checkpoint/relatorios/extrato/'

export default {
	name: 'extratoCheckpoint',

	components: {
		panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
		titulo: require('@/components/Atom/Header/Titulo').default,
		basicButtonsRelatoriosEIG: require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
		inputRange: require('@/components/Atom/Datas/InputRangeWithLimit').default,
		selectAll: require('@/components/Atom/Select/SelectAll').default,
		slideUpAndDown: require('@/components/Atom/SlideUpAndDown/SlideUpAndDown.vue').default,
		tableSimples: require('@/components/Atom/Table/TableSimples').default,
		ajusteStepper: require('@/components/Atom/SpecificComponents/Checkpoint/AjusteStepper.vue').default,
		statusBar: require('@/components/Atom/StatusInformation/StatusInformation.vue').default,
		modalFiles: require('@/components/Atom/SpecificComponents/Checkpoint/ModalFiles.vue').default,
		simpleButton: require('@/components/Atom/Buttons/SimpleButton.vue').default,
		modalEagle: require("@/components/Atom/Modal/ModalEagle").default,
	},

	data() {
		return {
			//opts
			optClientes: new EmpresasService().Get(),
			feedTrabalhador: {
				'MAD': [],
				'D': []
			},
			labelTrabalhador: '',
			//booleans
			diasNaoTrabalhados: false,
			loadingTrabalhador: false,
			loadingExportar: [false, false, false],
			mostraAcordeon: false,
			loading: false,
			//icons
			mdiClipboardTextClock: mdiClipboardClock,
			iconEye: mdiEye,
			iconPencil: mdiPencil,
			mdiTrashCan: mdiTrashCan,
			mdiClock: mdiClock,
			//labels
			labelSelectCliente: [
				{ indexDFH: 'cc', description: 'Empresas*' }
			],
			labelSelectTrabalhador: [
				{ indexDFH: 'MAD', description: 'Colaborador*' },
				{ indexDFH: 'D', description: 'Departamento' }
			],
			statusBar: 'info',
			//controle
			intervalo: '',
			clienteSelecionado: [],
			trabalhadorSelecionado: [],
			tableData: [],
			horas: [],
			msgErroProcessamento: '',
			order: [],
			jornadaSelecionadaDeletar: 0,
		}
	},

	validations: {
		clienteSelecionado: { required },
		trabalhadorSelecionado: { required }
	},

	methods: {
		...mapGetters(['getPermicoes', 'getExcluir']),

		/**
		 * @param {object} evento
		 * @param {string} evento.evento título do evento: DSR, Férias, etc...
		 * @param {string} evento.ceinicio inicio do evento, formato SQL
		 * @param {string} evento.cefim final do evento, formato SQL
		 * @description formata o htlm que deve aparecer no collapse 
		 * de cada dia na tabela.
		 * @return {string} contendo o HTLM
		 * @author Gui 🍺
		 */
		criaHtmlEvento(evento) {
			let titulo = evento.evento
			let inicio = evento?.ceinicio ?? false
			let final = evento?.cefim ?? false
			let tempo_evento = ''
			if (inicio)
				tempo_evento = this.criaDivsInicioFim(inicio, final);
			let T = titulo === 'Sem informações' ? '' : 'Evento:'
			return `
				<div class='io-show text-center'>
					<div><b>${T}</b> ${titulo}</div>
					${tempo_evento}
				</div>
			`
		},

		/**
		 * @param {string} ini começo do evento, formato SQL
		 * @param {string} final do evento, formato SQL
		 * @param {number} [duracao_maxima=10] do evento, eventos maior que isso 
		 * retornam sem inicio e fim, em horas
		 * @description se a duração dos eventos for mais que {duracao_maxima}
		 * horas retorna string vazia, do contrário, retorna 2 divs
		 * contendo o inicio e final do evento
		 * @return {''|string}
		 * @author Gui 🍺
		 */
		criaDivsInicioFim(ini, final, duracao_maxima = 10) {
			let ini_l = DateTime.fromSQL(ini)
			let fim_l = DateTime.fromSQL(final)
			let duracao = fim_l.diff(ini_l)
			if (duracao.as('hours') > duracao_maxima) return ''
			ini_l = ini_l.toFormat('HH:mm')
			fim_l = fim_l.toFormat('HH:mm')
			return `
				<div><b>Inicio: </b>${ini_l}</div>
				<div><b>Final: </b>${fim_l}</div>
			`
		},

		/**
		 * @param {object[]} arrEventos
		 * @param {string}   arrEventos[].inicio do evento
		 * @param {string}   arrEventos[].fim do evento
		 * @param {string}   data  do dia a ser filtrado formato iso
		 * @param {string}	 formato do  evento
		 * @description retorna os eventos que ocorrem na data passada
		 * @return {object[]}
		 * @author Gui 🍺
		 */
		eventosHoje(arrEventos, data, formato = 'dd/LL/yyyy') {
			let hoje = DateTime.fromISO(data)
			return arrEventos.filter((ev) => {
				let ini = DateTime.fromFormat(ev.inicio, formato)
				let fim = DateTime.fromFormat(ev.fim, formato)
				return ini <= hoje && hoje <= fim
			})
		},

		/**
		 * @param {object} info
		 * @param {object[]} info.eventos
		 * @param {string} info.eventos[].inicio
		 * @param {string} dia yyyy-mm-dd
		 * @description passa pelos eventos do funcionário
		 * e retorna os que acontecem no dia sendo filtrado, já formata os
		 * eventos de DSR e "sem informação"
		 * @return {array} eventos do dia
		 * @author Gui 🍺
		 */
		infoDiaSemPontos(info, dia) {
			let { eventos } = info
			let eventos_hoje = this.eventosHoje(eventos, dia)
			if (eventos_hoje.length) {
				return eventos_hoje
			}
			return [{ evento: 'Sem informações' }]
		},

		/**
		 * @param {string} strnome <codigo>| nome 
		 * @return {string} código do cara
		 */
		separaCodigoNome(strnome) {
			let reg = /^\d{1,}(?=|)/
			let cod = reg.exec(strnome)[0]
			return cod
		},

		/**
		 * @param {number}  mtcodigostr código do trabalhador | nome
		 * @param {object}  evento 
		 * @param {boolean} evento.crajustado se foi ou não ajustado já
		 * @param {string}  evento.crdatahoraevento  data do evento
		 * @param {number}  evento.mtcliente código do cliente
		 */
		abreAjuste(mtcodigostr, evento) {
			let mtcodigo = this.separaCodigoNome(mtcodigostr)
			let { crajustado, crdatahoraevento, mtcliente } = evento
			if (crajustado) {
				let reg_data = /\d{4}-\d{2}-\d{2}/
				let data = reg_data.exec(crdatahoraevento)[0]
				var url = `#/checkpoint/solicitacao/ajustes/${data}/${mtcodigo}/${mtcliente}`
				window.open(url)
			}
		},

		/**
		 * @param {object} evento
		 * @param {string} evento.hora do evento
		 * @param {number} index # do evento
		 * @param {("Entrada"|"Saída")} titulo 
		 * @return {string} html do evento
		 */
		formataEvento(evento, index, titulo, ajustes) {
			if (evento) {
				var hora = evento.hora
				return `<b>${index + 1}ª ${titulo}</b>: ${hora}`
			}
			return ``
		},

		/**
		 * @param {array} eventos - todos os eventos de pontos do dia.
		 * @return {array} com os eventos agrupados em pares.
		 * @description divide a array em uma array contendo pares de evento.
		 * Caso o número de pontos seja impar, o último evento da última array
		 * vai ser null.
		 * @author Gui 🍺
		 */
		dvlPares(eventos) {
			var pares = []
			eventos.forEach((e, i) => {
				let index_par = i % 2 === 0
				if (index_par) {
					pares.push([e, eventos[i + 1]])
				}
			})
			return pares;
		},

		/**
		 * @see decideSaldo
		 * @return {object} obj.class com a classe do elemento
		 */
		decideSaldoBind({ saldo }) {
			saldo = saldo ?? '00:00:00'
			var reg = /^-/
			if (saldo.match(reg))
				return { class: 'saldo-negativo' }
			else
				return { class: 'saldo-positivo' }
		},

		transformaEmHoras(d) {
			var sinal = ''
			if (d < 0) sinal = '-'
			d = Number(d);
			var h = Math.abs(Math.floor(d / 3600));
			var m = Math.abs(Math.floor(d % 3600 / 60))
			var s = Math.abs(Math.floor(d % 3600 % 60))
			h = this.pad2(h)
			m = this.pad2(m)
			s = this.pad2(s)
			return `${sinal}${h}:${m}:${s}`
		},

		pad2(number) {
			return (number < 10 ? '0' : '') + number
		},

		/**
		 * @param {object|null} fp  folha ponto
		 * @param {?string} fp.fphoranormal horas normais
		 */
		folhaPontoHorasNormais({ folha }) {
			return folha?.fphoranormal ?? '00:00:00'
		},

		folhaPontoHorasFalta({ folha }) {
			return folha?.fphorasfalta ?? '00:00:00'
		},

		folhaPontoHorasExtra({ folha }) {
			return folha?.extra ?? '00:00:00'
		},

		folhaPontoSaldo({ saldo }) {
			return saldo ?? '00:00:00'
		},

		/**
		 * @param {object} info
		 * @param {array}  info.registros todos os pontos na data.
		 * @param {object} info.folha dados da folha ponto.
		 * @param {object} info.ajuste dados de ajuste
		 * @param {object} info.jornada jornada do dia
		 * @return {object} com atributo class string
		 * @author Gui 🍺
		 * @todo esses ifs não estão muito bonitos, daria pra melhorar
		 * isso em algum momento... Mas no momento, tá funcionando bem e
		 * não é a pior coisa do mundo...
		 */
		decideFormatoSituacao(info, dia) {
			var { registros, folha, ajuste, jornada } = info
			var tem_registro = registros.length > 0
			var registros_pares = registros.length % 2 === 0
			var dsr = jornada?.hjtdsr !== 0
			var processado = !!folha
			var tem_ajuste = ajuste?.castatus === 'P'
			var tem_evento = this.verificaEventos(dia, info)
			if (tem_evento)
				if (tem_evento.includes('Descanso semanal remunerado'))
					tem_evento = 'dsr'
			/**
			 * Classes:
			 * certo - # ok de pontos, sem ajustes e já processado
			 * falta - deveria trampa mas n foi
			 * processamento - falta processar
			 * aprovacao - tem ajuste e o ajuste n foi aceito ainda
			 * inconformidade - # impar de pontos e sem ajuste
			 * diaAtual - dia de hoje nesse exato momento que vc ta lendo
			 * dsr - Descanso semanal remunerado
			 */
			if (dia === this.dataHojeIso) {
				return {
					class: "diaAtual"
				}
			}
			if (tem_ajuste) {
				return {
					class: 'aprovacao',
					title: 'Clique aqui para ser direcionado para ver o pedido de ajuste'
				}
			}
			if (tem_registro) {
				if (registros_pares) {
					if (processado) {
						if (tem_evento) {
							return { class: `evento-${tem_evento}` }
						}
						return {
							class: 'certo'
						}
					} else {
						return {
							class: 'processamento',
							title: this.podeProcessar ? 'Clique aqui para processar esse dia' : '',
						}
					}
				} else {
					return { class: 'inconformidade' }
				}
			} else {
				if (dsr) {
					if (tem_evento)
						return { class: `evento-${tem_evento}` }
					return {
						class: `evento-dsr`,
						title: 'Descanso semanal remunerado'
					}
				} else if (jornada.hjtiniprimeirot && !tem_evento) {
					return { class: 'falta' }
				} else {
					if (tem_evento) {
						return { class: `evento-${tem_evento}` }
					}
					return { class: 'certo' }
				}
			}
		},

		/**
		 * @param {string}   data no formato yyyy/mm/dd
		 * @param {object}   obj informações sobre a jornada do dia do funcionário
		 * @param {object[]} obj.eventos contando todos os eventos do trabalhador
		 * @param {string}   obj.eventos[].inicio formato dd/mm/yyyy
		 * @param {string}   obj.eventos[].fim formato dd/mm/yyyy
		 * @param {string}   obj.eventos[].evento descrição do evento
		 * @description verifica se algum dos eventos do funcionário
		 * coincide com a data de sendo testada.
		 * @returns {false|string} se existirem eventos, concatena os títulos
		 * @author Gui 🍺
		 */
		verificaEventos(data, { eventos }) {
			let eventos_hoje = this.eventosHoje(eventos, data)
			if (eventos_hoje.length) {
				eventos_hoje = eventos_hoje.map(ev => {
					return ev.evento
				}).join('-')
				return eventos_hoje
			}
			return false
		},

		/**
		 * @listens click situação
		 * @param {object} info objeto com as informações necessárias p/ pegar o status
		 * @param {number} mtcodigo código do funcionário a ser processado ou aprovado
		 * @param {string} dia no formato yyyy-mm-dd
		 * @description decide o que fazer de acordo com o status do usuário.
		 * Aguardando processamento: chama o script, faz o processamento desse dia
		 * p/ esse funcionário
		 * Aguardando aprovação: abre a página de solicitação de ajustes em outra aba
		 * @author Gui 🧟
		 */
		clickStatus(info, mtcodigosrt, dia) {
			var mtcodigo = this.separaCodigoNome(mtcodigosrt)
			var cls = this.decideFormatoSituacao(info)
			if (cls.class === 'processamento' && this.podeProcessar) {
				this.loading = true
				var url = `${urlBase}processar/${dia}/${mtcodigo}`
				new HttpRequest().Get(url).then((res) => {
					if (res.data.processamento) {
						this.gerarRelatorio()
					} else {
						this.loading = false
						this.msgErroProcessamento = res.data?.erros.join(', ') ?? ' '
						dia = DateTime.fromISO(dia);
						dia = dia.toFormat('dd/LL/yyyy')
						var nome = this.procuraNome(mtcodigo)
						this.$bvToast.toast(this.msgErroProcessamento, {
							title: `${nome}, ${dia}`,
							variant: 'danger',
							solid: true,
						})
					}
				})
			} else if (cls.class === 'aprovacao') {
				var url2 = `#/checkpoint/solicitacao/ajustes/${dia}/${mtcodigo}`
				window.open(url2)
			}
		},

		temAjustePendente(info) {
			var { ajuste } = info
			var tem_ajuste = ajuste?.castatus === 'P'
			return tem_ajuste
		},

		/**
		 * @param {string} data - no formato dd/mm/YYYY.
		 * @return {string} data no formato YYYY-mm-dd.
		 */
		formataData(data, { jornada }) {
			let reg = /(\d{4})-(\d{2})-(\d{2})/
			let replace = '$3/$2/$1'
			let data_f = data.replace(reg, replace)
			if (jornada === null) return data_f
			return `${data_f} (${jornada.dsdianome})`
		},

		padronizaDataHora(data, formato = 'dd/LL/yy HH:mm') {
			let data_cru = DateTime.fromSQL(data)
			return data_cru.toFormat(formato)
		},

		/**
		 * @param {string} funcionario 
		 * @param {string} dia
		 * @description muda o estado docolapse com o id
		 * composto por collapse_funcionario_dia.
		 * @author Gui 🍺
		 */
		muda_acordeon(funcionario, dia) {
			var target = `collapse_${funcionario}_${dia}`
			this.$root.$emit('bv::toggle::collapse', target)
		},

		/**
		 * @param {number} len - length da array de entradas-saidas no dia.
		 * @return {object} contento o ícone e a classe
		 */
		decideIcone(len) {
			if (len % 2)
				return {
					icon: mdiAlertCircleOutline,
					class: 'yellow',
				}
			return {
				icon: mdiChevronDownCircleOutline,
				class: 'green',
			}
		},

		/**
		 * @param {number} 	 mtcodigo
		 * @param {object[]} info - dados da linha
		 * @param {object[]} info.registros
		 * @param {string}   info.registros.hora - hora em que o ponto foi batido
		 * @description retorna a string com a data de entra e saída
		 * @returns {string} hora:minuto - hora:minutos.
		 * @author Gui 🍺
		 */
		entradaSaida(mtcodigo, info) {
			var retorno = ''
			if (info.registros.length) {
				var first = info.registros.at(0)
				var last = info.registros.at(-1)
				retorno = `${first.hora} - ${last.hora}`
			}
			return retorno
		},

		/**
		 * @param {string} data - data a ser formatada
		 * @description retorna o horário da data no formato certo.
		 * @return {string} no formado hora:minuto:segundo
		 * @author Gui 🍺
		 */
		deixaNoFormatoCerto(data) {
			let retorno = DateTime.fromSQL(data)
			retorno = retorno.toFormat('HH:mm')
			return retorno
		},

		/**
		 * @param {string} data - no formato dd/mm/yyyy
		 * @param {object} dados - com todos os eventos do trabalhador.
		 * @description prepara os dados da modal de ajuste.
		 */
		abreModal(mtcodigosrt, data, info) {
			let mtcodigo = this.separaCodigoNome(mtcodigosrt)
			mtcodigo = Number(mtcodigo)
			this.$refs.ajustes.iniciaStepper(data, mtcodigo, info.jornada)
			let temEvento = this.verificaEventos(data, info)
			var evento = ''
			var eventos = ''
			if (temEvento) {
				let arrEv = new Set(temEvento.split("-"))
				eventos = arrEv.join(", ")
				this.chamaToast(eventos)
			}
			this.$nextTick(() => {
				this.$bvModal.show('ajuste-stepper')
			})
		},

		/**
		 * @description Abre o modal modalDeletarJornada
		 * @Param {oobj} info, array com informações para deletar a jornada, utilizamos o info.codigoFechamentoDeletar
		 * que é o código da jornada para ser apagada.
		 * @Autor Yuri 🇧🇷
		 */
		abreModalDeletarJornada(info) {
			this.$bvModal.show('modalDeletarJornada');
			this.jornadaSelecionadaDeletar = info.codigoFechamentoDeletar;
		},

		/**
		 * Função para confirmar o delete da jornada do colaborador, após deletar realiza uma nova consulta em this.gerarRelatorio()
		 * e fecha o modal modalDeletarJornada.
		 * @Autor Yuri 🇧🇷
		 */
		confirmaDeletarJornada() {
			this.loading = true;
			let url = `${urlBase}deletar/jornada`
			const obj = { "codigo": this.jornadaSelecionadaDeletar };

			new HttpRequest().Post(url, obj).then((data) => {
			}).finally(() => {
				this.toastShow('Sucesso', 'Jornada deletada com sucesso!', 'success');
				this.$bvModal.hide('modalDeletarJornada');
				this.jornadaSelecionadaDeletar = 0;
				this.gerarRelatorio();
			})
		},

		/**
 * @description Método para mostrar o toast na tela
 * @param {string} msg   - mensagem que vai aparecer no corpo do toast
 * @param {string} type  - qual o tipo do toast
 * @param {string} title - título para inserir no cabeçalho
 * @author Vitor Hugo 🐨
 */
		toastShow(title, msg, type) {
			this.$bvToast.toast(msg, {
				autoHideDelay: 2500,
				variant: type,
				title: title
			});
		},

		/**
		 * @param {string} eventos
		 * @description chama a toast do bootstrap.
		 */
		chamaToast(eventos) {
			this.$bvToast.toast(`O colaborador possui ${eventos} neste dia.`, {
				title: "O colaborador possui eventos neste dia.",
				variant: 'warning',
				solid: true,
			})
		},

		/**
		 * @description faz a request que busca as informações
		 * de acordo com os filtros do relatório.
		 * @author Gui 🍺
		 */
		gerarRelatorio() {
			this.loading = true
			this.statusBar = 'info'
			this.tableData = []
			var obj = {
				'cliente': this.clienteSelecionado,
				'label': this.labelTrabalhador,
				'trabalhador': this.trabalhadorSelecionado,
				'intervalo': this.intervalo,
				'apenasDiasTrabalhados': this.diasNaoTrabalhados
			}
			let url = `${urlBase}gerar`
			new HttpRequest().Post(url, obj).then((resposta) => {
				if (resposta.data?.registros) {
					this.tableData = resposta.data.registros
					this.order = resposta.data.ordem
				}
				if (!this.mostraTabela) this.statusBar = 'error'
			}).finally(() => { this.loading = false })
		},

		/**
		 * @listens changeInput - componente de data
		 * @param {string} intervalo - no formato dd/mm/yyyy - dd/mm/yyyy.
		 */
		changeIntervalo(intervalo) {
			this.intervalo = intervalo
			this.statusBar = 'info'
			this.tableData = []
		},

		/**
		 * @listens changeSelect - seletor de empresa.
		 * @param {number[]} arr - código dos clientes selecionados.
		 * @description Mantem a variavel de controle atualizada
		 * com os clientes selecionados.
		 */
		changeEmpresa(arr) {
			this.clienteSelecionado = arr
			this.statusBar = 'info'
			this.tableData = []
			this.feedTrabalhador = {
				'MAD': [],
				'D': []
			}
			this.$refs.selTrabalhador.clearAll()
			this.$v.trabalhadorSelecionado.$reset()
			if (arr.length)
				this.procuraTrabalhador(arr)
		},

		/**
		 * @listens changeSelect - seletor de funcionario
		 * @param {number[]} arr - código dos funcionarios selecionados.
		 */
		changeTrabalhador(arr) {
			this.trabalhadorSelecionado = arr
			this.statusBar = 'info'
			this.tableData = []
		},

		/**
		 * @param {number[]} arr - código dos clientes selecionados.
		 * @description procura pelos funcionários do tipo
		 * AD no banco de dados, cuida para o loading do campo
		 * de trabalhador funcionar da maneira carreta.
		 * @author Gui 🍺 
		 */
		procuraTrabalhador(arr) {
			this.loadingTrabalhador = true
			this.statusBar = 'info'
			new FiltrosService().dados_filtros(arr, ['MAD', 'D'])
				.then((res) => {
					this.feedTrabalhador = res
				})
				.finally(() => this.loadingTrabalhador = false)
		},

		/**
		 * @param {'pdf'|'xls'|'csv'|'none'} tipo
		 * @description faz o snipper de gerando aparecer no
		 * lugar certo
		 */
		mudaLoadingExportar(tipo) {
			this.loadingExportar = [tipo == 'pdf', tipo == 'xls', tipo == 'csv']
		},

		/**
		 * @param {'pdf'} tipo 
		 * @description faz a exportação do relatório
		 */
		exportarRelatorio(tipo) {
			let html = document.getElementById('table-extrato').cloneNode(true)
			let trs = html.querySelectorAll('tr')
			trs.forEach((t) => {
				let children = t.querySelectorAll('.no-pdf')
				children.forEach((c) => {
					t.removeChild(c)
				})
			})
			let obj = {
				innerHTML: `<table>${html.innerHTML}</table>`,
				intervalo: this.intervalo,
			}
			let url = `${urlBase}exportar/${tipo}`
			this.mudaLoadingExportar(tipo)
			new HttpRequest().Post(url, obj)
				.then((data) => {
					var root = process.env.VUE_APP_ROOT;
					window.open(root + '/' + data.data.local);
				}).finally(() => {
					this.mudaLoadingExportar('none')
				})
		},

		/**
		 * @listens changeCheck - seletor de dpt/trabalhador
		 * @param {'D'|'MAD'} label - opções a serem exibidas
		 */
		changeCTrabalhador(label) {
			this.labelTrabalhador = label
			this.tableData = []
		},

		getHorasNormais(data) {
			var first = data.at(0)
			if (first) {
				if (first.horas !== null) {
					return first.horas.fphoranormal
				}
			}
		},

		getHorasFalta(data) {
			var first = data.at(0)
			if (first) {
				if (first.horas !== null) {
					return first.horas.fphorasfalta
				}
			}
		},

		/**
		 * @param {string} mtcodigosrt - codigo| nome
		 * @return {string} nome do funcionario
		 */
		procuraNome(mtcodigostr) {
			var mtcodigo = this.separaCodigoNome(mtcodigostr)
			var obj = { value: Number(mtcodigo) }
			var w = _.find(this.feedTrabalhador['MAD'], obj)
			if (w)
				return w.description
		},

		temAjuste(evento) {
			if (evento !== undefined) {
				return {
					'io-show': true,
					'unselectable': true,
					'decorate': evento.crajustado
				}
			}
			return { 'io-show': true }
		},
	},

	computed: {
		podeProcessar() {
			return this.getPermicoes()['editar'] || this.getPermicoes()['cadastrar']
		},
		/**
		 * @return { String } yyyy-mm-dd
		 */
		dataHojeIso() {
			return DateTime.now().toISODate()
		},

		dataHoje() {
			return DateTime.now().toFormat('dd/LL/yyyy')
		},

		podeEditar() {
			return this.getPermicoes()['editar']
		},

		tableDataShow() {
			return this.tableData
		},

		/**Decide se existem informações p/ mostrar na tabela */
		mostraTabela() {
			return !!Object.entries(this.tableData).length
		},

		/**Decide se o seletor de trabalhador deve estar habilitado*/
		desabilitaSeletorT() {
			if (this.optTrabalhador.length)
				return false
			return true
		},

		/**Decide quais opções seram exibidas no seletor de trabalhador*/
		optTrabalhador() {
			if (this.feedTrabalhador[this.labelTrabalhador])
				return this.feedTrabalhador[this.labelTrabalhador]
			else return []
		}
	},

	watch: {
		diasNaoTrabalhados() {
			this.statusBar = 'info'
			this.tableData = []
		}
	},
}
</script>

<style lang="scss" scoped>
#extrato-checkpoint {
	--inconformidade: rgb(241, 99, 99);
	--certo: #84cf7d;
	--falta: #e78970;
	--processamento: white;
	--aprovacao: #ffd751;
	--saldonegativo: #e78970;
	--saldopositovo: #84cf7d;
	--saldoneutro: #979797;
	--diaAtual: #FFF;

	.bold-text {
		font-weight: bold !important;
	}

	.yellow {
		color: rgb(214, 192, 66)
	}

	.green {
		color: green
	}

	th {
		text-align: center;
		vertical-align: center;
	}

	td {
		vertical-align: middle;
	}

	.nome-trabalhador {
		padding: 5px;
		max-width: 120px;
		text-overflow: ellipsis;
		text-align: center;
		background-color: gray;
		border-radius: 2px;
		color: white;
		border-collapse: separate;
		border-spacing: 5px;
	}

	.linha-que-some {
		background-color: white;
	}

	.entradas-saidas-collapse {
		display: flex;
		flex-direction: column;
		max-height: 80px;
		margin: 10px;
		padding: 10px;
		padding-right: 30px;
		background: whitesmoke;

		.io-show {
			min-width: fit-content;
		}

		.decorate {
			text-decoration: underline;
			cursor: pointer;
		}
	}

	.ev-col {
		display: flex;
		flex-direction: column;
		max-height: 80px;
		margin: 10px;
		width: 100%;
		padding: 10px;
		padding-right: 30px;
		background: whitesmoke;

		.io-show {
			min-width: fit-content;
		}

		.decorate {
			text-decoration: underline;
			cursor: pointer;
		}
	}

	.text-center {
		text-align: center;
	}

	.no-info {
		text-align: center;
	}

	div[class*=retangulo-status] {
		height: 31px;
		text-align: center;
		width: 90px;
		font-size: 11px;
		padding: 1px;
	}

	div[class*=aprovacao] {
		background-color: var(--aprovacao);
		cursor: pointer;
	}

	.aprovacao::after {
		content: 'Aguardando aprovação'
	}

	div[class*=processamento] {
		background-color: var(--processamento);
		cursor: pointer;
	}

	.processamento::after {
		content: 'Aguardando processamento'
	}

	div[class*=falta] {
		background-color: var(--falta);
		padding-top: 8px;
	}

	.falta::after {
		content: 'Falta'
	}

	div[class*=diaAtual] {
		background-color: var(--diaAtual);
		padding-top: 8px;
	}

	.diaAtual::after {
		content: "Dia atual";
	}

	div[class*=certo] {
		background-color: var(--certo);
	}

	.certo::after {
		content: '✔';
		font-size: 22px;
	}

	div[class*=evento] {
		padding-top: 6px;
		font-size: 12px;
		background-color: var(--certo);
	}

	div[class*=Férias]::after {
		content: 'Férias'
	}

	div[class*=Atestado]::after {
		content: 'Atestado'
	}

	div[class*=Folga]::after {
		content: 'Folga'
	}

	div[class*=Afastamento]::after {
		content: 'Afastamento'
	}

	div[class*=inconformidade] {
		background-color: var(--inconformidade);
		padding-top: 8px;
	}

	div[class*=inconformidade]::after {
		content: 'Incoformidade'
	}

	div[class*=dsr]::after {
		content: 'DSR'
	}

	#spacing {
		height: 10px;
		background-color: #f5f5f5;
	}

	.saldo-worker {
		text-align: center;
		margin-top: 3px;
		vertical-align: middle;
		padding: 3px;
		color: white;
		font-weight: bolder;
	}

	.saldo-negativo {
		background-color: var(--saldonegativo);
	}

	.saldo-positivo {
		background-color: var(--saldopositovo);
		// visibility: hidden;
	}

	.saldo-normal {
		background-color: var(--saldopositovo);
	}
}

.unselectable {
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}
</style>
