<template>
	<panelEagle id='cadastroDeEscalas' :loading='loadingPanel'>
		<div class="col-sm-12 row nopadding">
			<div class="col-sm-6 ">
				<titulo :icon="mdiFileTree" titulo="Escalas" />
			</div>
			<div class="col-sm-6">
				<botoes tipoCancela="button" linkCancel="listarEscalas">
					<template v-slot:salvar>
						<loadingSave :isLoading="loading" :disabled='$v.$invalid' :icon="mdiCheckBold"
							@click="salvar" text="Salvar" event="click" type="blue" />
					</template>
				</botoes>
			</div>
		</div>
		<div class="col-sm-12">
			<hr>
		</div>
		<div class='col-sm-12  row nopadding'>
			<div class="col-sm-3 nopadding">
				<selectAll nameForRadio='SeletorEmpresa' :labels='[{ description: "Empresa*" }]' :allowEmpty='false'
					:isMultiple='false' :hasSelectAll='false' :selected='valueEmpresa' :optionsArray='optSelectEmpresa'
					:extraClass='{ "border border-danger": $v.escalasColetivo.escliente.$anyError }'
					@close='$v.escalasColetivo.escliente.$touch()' @selectCriado='that => seletores.empresa = that'
					@changeSelect='selectEmpresa' />
			</div>
			<div class="col-sm-3 nopadding">
				<selectAll ref="refColaborador" :loading="loadingBtnSelectAll" nameForRadio='SeletorColaborador'
					:labels='[{ indexDFH: "CO", description: "Colaborador" }]' :isMultiple='false' :hasSelectAll='false'
					:selected='valueColaborador' :optionsArray='optSelectColaborador'
					@selectCriado='that => seletores.colaborador = that' :disabled="$v.escalasColetivo.escliente.$invalid"
					@changeSelect='mudaColaborador' />
			</div>
			<div class="col-sm-3 row p-0">
				<div class="col-sm-12 p-0">
					<inputS name='fdp' label='Descrição*'
						:inputClass='{ "border border-danger": $v.escalasColetivo.esdescricao.$anyError }'
						@blur='$v.escalasColetivo.esdescricao.$touch()' :value='escalasColetivo.esdescricao'
						@changeInput='value => escalasColetivo.esdescricao = value' />
				</div>
				<div class="col-sm-1 mt-3">
					<b-form-checkbox :value='false' :unchecked-value="true" v-model='reservas' @change='limpaCamposIniFim'>
						Reservas
					</b-form-checkbox>
				</div>
			</div>
			<div class="col-sm-3 nopadding">
				<selectAll ref="refJornadaDeTrabalho" :loading="loadingBtnSelectAll" nameForRadio='seletorJornadaDeTrabalho'
					:labels='[{ indexDFH: "NA", description: "Jornada de Trabalho" }]' :isMultiple="false" :hasSelectAll="false"
					:selected='jornada' :optionsArray="optSelectJornada" @selectCriado='that => seletores.jornadaTrabalho = that'
					:disabled="$v.escalasColetivo.escliente.$invalid" @changeSelect='changeJornadaDeTrabalho' />
			</div>
			<div class="col-sm-3 nopadding">
				<inputS name='fdp' label='Observação' @changeInput='value => escalasColetivo.esobservacao = value'
					:value='escalasColetivo.esobservacao' />
			</div>
			<div class="col-sm-3 nopadding">
				<inputSD name='vigencia' label='Vigência' :value='escalasColetivo.esvigencia' @changeInput='changeDataVigencia' />
			</div>
			<div class="col-sm-3 nopadding mt-1">
				<div class="col-sm-12 nopadding row">
					<div class="col-sm-6 nopadding">
						<inputS :disabled='reservas' type='time' name='inicioInput' label='Início'
							:value='escalasColetivo.esreservaini' @changeInput='value => escalasColetivo.esreservaini = value' />
					</div>
					<div class="col-sm-6 nopadding">
						<inputS :disabled='reservas' type='time' name='fimInput' label='Fim' :value="escalasColetivo.esreservafim"
							@changeInput='value => escalasColetivo.esreservafim = value' />
					</div>
				</div>
			</div>
			<div class="col-sm-3 nopadding">
				<inputS :hasMask="true" :mask="['NNNNNNNNNN']" name='mkMaximoInput' label='Km máximo'
					:value='escalasColetivo.esdistanciamaxima' @changeInput='value => escalasColetivo.esdistanciamaxima = value' />
			</div>
			<div class="col-sm-3 nopadding mt-2">
				<selectAll ref="refVeiculoPrimeiroTurno" :isMultiple='false' :hasSelectAll='false' nameForRadio='seletorVeiculo'
					:optionsArray='optSelectVeiculo' :selected='valueVeiculo1' @selectCriado='that => seletores.veiculo1 = that'
					@changeSelect='changeVeiculoPrimeiroTurno' :disabled="$v.escalasColetivo.escliente.$invalid"
					:labels='[{ description: "Veículo Primeiro Turno" }]' />
			</div>
			<div class="col-sm-3 underpadding mt-2">
				<selectAll ref="refVeiculoSegundoTurno" :isMultiple='false' :hasSelectAll='false' nameForRadio='seletorVeiculo'
					:selected='valueVeiculo2' :optionsArray='optSelectVeiculo' @selectCriado='that => seletores.veiculo2 = that'
					@changeSelect='changeVeiculoSegundoTurno' :disabled="$v.escalasColetivo.escliente.$invalid"
					:labels='[{ description: "Veículo Segundo Turno" }]' />
			</div>
			<div class="col-sm-3 underpadding mt-2">
				<selectAll ref="refVeiculoTerceiroTurno" :isMultiple='false' :hasSelectAll='false' nameForRadio='seletorVeiculo'
					:selected='valueVeiculo3' :optionsArray='optSelectVeiculo' @selectCriado='that => seletores.veiculo3 = that'
					@changeSelect='changeVeiculoTerceiroTurno' :disabled="$v.escalasColetivo.escliente.$invalid"
					:labels='[{ description: "Veículo Terceiro Turno" }]' />
			</div>
			<div class="col-sm-12">
				<b-tabs>
					<b-tab title='Primeiro Turno'>
						<div class="col-sm-12 padding-tab"></div>
						<formulario :arrayLinhas='optSelectLinhas' :arrayCods='codigosTurnos'
							@formCriado='that => formularios.primeiroT = that' @adicionaTarefa='value => turnos.primeiro.push(value)'>
							<template v-slot:table='{ titulos }'>
								<tableM @excluirCadastro='exluirTurno1' :titles='titulos' :data='turnos.primeiro' />
							</template>
						</formulario>
					</b-tab>
					<b-tab title='Segundo Turno'>
						<div class="col-sm-12 padding-tab">
							<formulario :arrayLinhas='optSelectLinhas' :arrayCods='codigosTurnos'
								@formCriado='that => formularios.segundoT = that' @adicionaTarefa='value => turnos.segundo.push(value)'>
								<template v-slot:table='slotProps'>
									<tableM @excluirCadastro='exluirTurno2' :titles='slotProps.titulos' :data='turnos.segundo' />
								</template>
							</formulario>
						</div>
					</b-tab>
					<b-tab title='Terceiro Turno'>
						<div class="col-sm-12 padding-tab">
							<formulario :arrayLinhas='optSelectLinhas' :arrayCods='codigosTurnos'
								@formCriado='that => formularios.terceiroT = that' @adicionaTarefa='value => turnos.terceiro.push(value)'>
								<template v-slot:table='slotProps'>
									<tableM @excluirCadastro='exluirTurno3' :titles='slotProps.titulos' :data='turnos.terceiro' />
								</template>
							</formulario>
						</div>
					</b-tab>
					<b-tab title='Tarefas'>
						<div class="col-sm-12 padding-tab">
							<tarefas :optTarefas='optselectTarefas' :arrayCodigos='codigosTarefas'
								@inserirTarefa='value => dataTarefas.push(value)'>
								<template v-slot:table='{ titles }'>
									<tableM @excluirCadastro='exluirTarefa' :titles='titles' :data='dataTarefas' />
								</template>
							</tarefas>
						</div>
					</b-tab>
				</b-tabs>
			</div>
			<required />
			<div class="col-sm-12">
				<hr>
			</div>
		</div>
		<modal title='Conflito de escala de motorista' id='modalCadastroEscalas' @cancelButton='cancelaDesassociacaoModal'
			@confirmButton='confirmaDesassociacaoModal'>
			<template slot='modalBody'>
				<div class='col-sm-12'>
					<p>{{ 'O motorista ' + modalMotorista.description + ' já esta associado a(s) escala(s):' }}</p>
					<div>
						<ul v-for='escala in infoModal' :key='escala.escodigo'>{{ escala.esdescricao }}</ul>
					</div>
					<p>Deseja desassociá-lo?</p>
				</div>
			</template>
		</modal>
	</panelEagle>
</template>

<script lang="js">
import Vue from 'vue'
import { mdiFileTree, mdiCheckBold } from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import { conectionError } from '@/Services/Helpers/swellHeper'
import { required } from 'vuelidate/lib/validators'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { DateTime } from 'luxon';
export default Vue.extend({
	name: 'CadastroEscalas',

	components: {
		'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
		'tarefas': require('@/components/Atom/SpecificComponents/CadastroTarefas').default,
		'titulo': require('@/components/Atom/Header/Titulo').default,
		'botoes': require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
		'selectAll': require('@/components/Atom/Select/SelectAll').default,
		'inputS': require('@/components/Atom/Inputs/InputSimple').default,
		// 'inputText'  : require('@/components/Atom/Inputs/InputTextArea').default,
		'inputSD': require('@/components/Atom/Datas/InputSingleDay').default,
		'formulario': require('@/components/Atom/SpecificComponents/Formulario').default,
		'required': require('@/components/Atom/Footer/RequiredFields').default,
		'modal': require('@/components/Atom/Modal/ModalEagle').default,
		'tableM': require('@/components/Atom/Table/TableListagemModal').default,
		'loadingSave': require('@/components/Atom/Buttons/LoadingButton').default,
	},

	validations: {
		escalasColetivo: {
			esdescricao: { required },
			escliente: { required }
		},
	},

	data() {
		return {
			loadingBtnSelectAll: false,
			mdiCheckBold: mdiCheckBold,
			mdiFileTree: mdiFileTree,
			loading: false,
			reservas: true,
			funcao: 'salvar',
			seletores: {
				empresa: Object,
				colaborador: Object,
				jornadaTrabalho: Object,
				veiculo1: Object,
				veiculo2: Object,
				veiculo3: Object
			},
			optSelectEmpresa: [],
			optSelectColaborador: [],
			optSelectJornada: [],
			optSelectDia: [],
			optSelectHora: [],
			optSelectVeiculo: [],
			optSelectLinhas: [],
			optselectTarefas: [],
			loadingPanel: false,
			editandoVinculo: false,
			escalaEditada:'',
			//dados
			escalasColetivo: {
				esdescricao: '',
				esmotorista: '',
				escliente: '',
				esveiculopt: '',
				esveiculost: '',
				esveiculott: '',
				esjornada: '',
				esreservaini: '',
				esreservafim: '',
				esobservacao: '',
				esdistanciamaxima: '',
				esvigencia: '',
			},
			jornada: [],
			valueJornada: '',
			valueEmpresa: [],
			valueColaborador: [],
			valueVeiculo1: [],
			valueVeiculo2: [],
			valueVeiculo3: [],
			turnos: {
				primeiro: [],
				segundo: [],
				terceiro: [],
			},
			formularios: {
				primeiroT: Object,
				segundoT: Object,
				terceiroT: Object
			},
			dataTarefas: [],
			link: '/coletivos/cadastros/escalas/',
			infoModal: [],
			modalMotorista: '',
			deletados: {
				detalhes: [],
				tarefas: []
			}
		}
	},

	methods: {

		/* 
		 * Verfica se o checkbox de reservas foi colocado
		 * como false e limpa os campos de reserva ini e fim
		*/
		limpaCamposIniFim(valueCheckbox) {
			if (valueCheckbox) {
				this.escalasColetivo.esreservaini = ''
				this.escalasColetivo.esreservafim = ''
			}
		},
		changeDataVigencia(value) {
			this.escalasColetivo.esvigencia = value
		},

		changeVeiculoPrimeiroTurno(value) {
			this.escalasColetivo.esveiculopt = value[0] ?? ''
		},

		changeVeiculoSegundoTurno(value) {
			this.escalasColetivo.esveiculost = value[0] ?? ''
		},

		changeVeiculoTerceiroTurno(value) {
			this.escalasColetivo.esveiculott = value[0] ?? ''
		},

		changeJornadaDeTrabalho(value) {
			this.escalasColetivo.esjornada = value[0] ?? ''
		},

		exluirTarefa(value) {
			var index = this.dataTarefas.findIndex(task => { return task.codigo == value })
			if (this.dataTarefas[index]['hetcodigo']) this.deletados.tarefas.push(this.dataTarefas[index]['hetcodigo'])
			this.dataTarefas.splice(index, 1)
		},
		exluirTurno1(value) {
			var index = this.turnos.primeiro.findIndex(atividade => { return atividade.codigo == value })
			if (this.turnos.primeiro[index]['edcodigo'] !== undefined) {
				this.deletados.detalhes.push(this.turnos.primeiro[index]['edcodigo'])
			}
			this.turnos.primeiro.splice(index, 1)
		},
		exluirTurno2(value) {
			var index = this.turnos.segundo.findIndex(atividade => { return atividade.codigo == value })
			if (this.turnos.segundo[index]['edcodigo']) this.deletados.detalhes.push(this.turnos.segundo[index]['edcodigo'])
			this.turnos.segundo.splice(index, 1)
		},
		exluirTurno3(value) {
			var index = this.turnos.terceiro.findIndex(atividade => { return atividade.codigo == value })
			if (this.turnos.terceiro[index]['edcodigo']) this.deletados.detalhes.push(this.turnos.terceiro[index]['edcodigo'])
			this.turnos.terceiro.splice(index, 1)
		},
		cancelaDesassociacaoModal() {
			this.seletores.colaborador.clearAll()
			this.seletores.jornadaTrabalho.clearAll()
		},
		confirmaDesassociacaoModal() {
			new HttpRequest().Post(this.link + 'desassociacao', { motorista: this.modalMotorista.value })
			this.$bvModal.hide('modalCadastroEscalas')
		},

		async selectEmpresa(array, value, description) {
			this.limpaVarDependenteEmpresa()
			this.loadingBtnSelectAll = true
			if (value !== undefined) {
				this.escalasColetivo.escliente = value
			} else {
				this.escalasColetivo.escliente = array[0]
			}
			if (array.length) {
				await this.informacaoCliente(this.escalasColetivo.escliente)
			}
			this.loadingBtnSelectAll = false
		},

		limpaVarDependenteEmpresa() {
			this.$refs.refColaborador.clearAll()
			this.$refs.refJornadaDeTrabalho.clearAll()
			this.$refs.refVeiculoTerceiroTurno.clearAll()
			this.$refs.refVeiculoSegundoTurno.clearAll()
			this.$refs.refVeiculoPrimeiroTurno.clearAll()
		},

		mudaColaborador(array, value, description) {
			this.seletores.jornadaTrabalho.clearAll()
			this.escalasColetivo.esmotorista = value
			this.modalMotorista = { 'description': description, 'value': value }
			if (array.length > 0) {
				var NaoEhmultijornada = true
				new HttpRequest()
					.Post(this.link + 'colaborador', { motorista: this.escalasColetivo.esmotorista })
					.then(data => {
						if (data.data.jt != null) {
							this.jornada = [data.data.jt]
							NaoEhmultijornada = !data.data.jt['jtmotoristapodeassociarmaisescala']
						}
						if (NaoEhmultijornada && value) {
							if (data.data.escala.length > 0) {
								this.infoModal = data.data.escala
								this.$bvModal.show('modalCadastroEscalas')
							}
						}
					})
			}
		},
		criaObjeto() {
			var aux = {
				'escalasColetivo': this.escalasColetivo,
				'turnos': this.turnosCorretos,
				'tarefas': this.tarefasCorretas,
				'jornadaTrabalho': this.valueJornada
			}
			if (this.funcao == 'atualizar') {
				aux['escodigo'] = this.editandoVinculo ? this.escalaEditada : this.$route.params.escala;
				aux['deletados'] = this.deletados
			}
			return aux
		},
		async getInformationEscala(escala, tipo) {
			var data = await new HttpRequest().Post('/coletivos/cadastros/escalas/information', { 'escala': escala, 'tipo': tipo });
			this.escodigo = data.data.escala.codigo
			this.colaborador = data.data.escala.colaborador
			this.dataVigenciaSelected = data.data.escala.dataVigencia
			this.descricaoSelected = data.data.escala.descricao
			this.empresaSelected = data.data.escala.empresa
			this.jornadaSelected = data.data.escala.jornada
			this.kmMax = data.data.escala.kmMax
			this.observacao = data.data.escala.observacao
			this.primeiroTurnoDados = data.data.escala.primeiroTurno
			this.segundoTurnoDados = data.data.escala.segundoTurno
			this.terceiroTurnoDados = data.data.escala.terceiroTurno
			this.tarefasDados = data.data.escala.tarefas
			this.horasReservas = data.data.escala.reserva.reserva
			this.horaInicioR = data.data.escala.reserva.inicio
			this.horaFimR = data.data.escala.reserva.fim
			if (this.funcao == 'atualizar') {
				aux['escodigo'] = this.editandoVinculo ? this.escalaEditada : this.$route.params.escala;
				aux['deletados'] = this.deletados
			}
			return aux
		},
		salvar() {
			this.loadingPanel = true
			var obj = this.criaObjeto()
			new HttpRequest()
				.Post(this.link + this.funcao, obj)
				.then(data => {
					this.loadingPanel = false
					if (data.data.sucess) {
						this.$router.push({ name: 'listarEscalas' })
					} else {
						conectionError()
					}
				}).catch(error => {
					this.loadingPanel = false
					conectionError()
				})
		},
		limpaTodosCampos() {
			this.seletores.veiculo1.clearAll()
			this.seletores.veiculo2.clearAll()
			this.seletores.veiculo3.clearAll()
			this.seletores.colaborador.clearAll()
			this.seletores.jornadaTrabalho.clearAll()
			this.turnos.primeiro = []
			this.turnos.segundo = []
			this.turnos.terceiro = []
		},
		async informacaoCliente(value) {
			await new HttpRequest()
				.Post(this.link + 'informacaoCliente', { 'clientes': value })
				.then((data) => {
					this.optSelectColaborador = data.data.motoristas
					this.optSelectJornada = data.data.jornadaTrabalho
					this.optSelectVeiculo = data.data.veiculos
					this.optSelectLinhas = data.data.linhas
					this.optselectTarefas = data.data.tarefas
				})
		},
		arrumaVeiculos(escala) {
			if (escala.esveiculopt) {
				this.valueVeiculo1.push(this.optSelectVeiculo.find(veiculo => {
					return veiculo.value == escala.esveiculopt
				}))
			}

			if (escala.esveiculost) {
				this.valueVeiculo2.push(this.optSelectVeiculo.find(veiculo => {
					return veiculo.value == escala.esveiculost
				}))
			}

			if (escala.esveiculott) {
				this.valueVeiculo3.push(this.optSelectVeiculo.find(veiculo => {
					return veiculo.value == escala.esveiculott
				}))
			}

		},
		arrumaDetalhes(detalhes) {
			detalhes.forEach(el => {
				var aux = {
					codigo: el.edlinha + '' + el.hrdiasemana + '' + el.hrcodigo,
					dia: {
						description: el.dsdiacompleto,
						value: el.hrdiasemana
					},
					linha: {
						description: el.lidescricao,
						value: el.edlinha
					},
					hora: {
						description: el.hrhorario,
						value: el.hrcodigo
					},
				}
				if (this.funcao == 'atualizar') {
					aux['edcodigo'] = el.edcodigo
				}
				if (el.edturno == 'P') {
					this.turnos.primeiro.push(aux)
				}

				if (el.edturno == 'S') {
					this.turnos.segundo.push(aux)
				}
				
				if (el.edturno == 'T') {
					this.turnos.terceiro.push(aux)
				}
			})
		},
		async arrumaFormEscalas(escala) {	
			Object.keys(this.escalasColetivo).forEach(key => {
				if (escala[key]) {
					this.escalasColetivo[key] = escala[key]
				}
			})
			
			if(this.editandoVinculo){
				this.funcao = 'atualizar'
			}

			if (this.funcao != 'atualizar') {
				this.escalasColetivo.esdescricao = this.escalasColetivo.esdescricao + '_COPIA'
			}

			if (this.escalasColetivo.esreservaini || this.escalasColetivo.esreservafim) {
				this.reservas = false;
			}
			this.valueEmpresa.push(this.optSelectEmpresa.find(empresa => {
				return empresa.value == this.escalasColetivo.escliente
			}))
			await this.informacaoCliente(this.valueEmpresa[0].value)
			if (escala.esmotorista) {
				var aux = this.optSelectColaborador.find(colab => {
					return colab.value == escala.esmotorista
				})
				if (aux != undefined)
					this.valueColaborador.push(aux)
			}
			this.arrumaVeiculos(escala)
			var detalhes = escala.detalhes_detalhados
			this.arrumaDetalhes(detalhes)
			this.arrumaTarefas(escala.tarefas)
			this.transformaData(escala.esvigencia)
			this.loadingPanel = false
		},
		arrumaTarefas(tarefas) {
			tarefas.forEach(el => {
				// var codigo = task.horario+''+task.dia.value
				var aux = {
					'codigo': el.hethorario + '' + el.hetcodigodia,
					'dia': {
						'description': el.dsdiacompleto,
						'value': el.hetcodigodia
					},
					'horario': el.hethorario,
					'tarefa': {
						'description': el.etdescricao,
						'value': el.etcodigo
					}

				}
				if (this.funcao == 'atualizar') {
					aux['hetcodigo'] = el.hetcodigo
				}
				this.dataTarefas.push(aux)
			})
		},

		/**
		 * @description Método para transformar data para 
		 *  o formato do componente de inputSingleDay
		 * @param  {date} data - data no padrão que vem do banco
		 * @author Vitor Hugo 🐨
		*/
		transformaData(data) {
			this.escalasColetivo.esvigencia = DateTime.fromFormat(data, 'yyyy-LL-dd').toFormat('dd/LL/yyyy')
		}
	},

	watch: {
		valueColaborador(newValue) {
			if (newValue !== undefined && newValue[0] !== undefined) {
				new HttpRequest().Post(this.link + 'colaborador', { motorista: newValue[0].value }).then(data => {
					if (data.data.jt != null) {
						this.jornada = [data.data.jt]
					}
				})
			}
		}
	},

	computed: {
		codigosTurnos() {
			var aux = []
			this.turnos.primeiro.forEach(tarefa => {
				var codigo = tarefa.linha.value + '' + tarefa.dia.value + '' + tarefa.hora.value + '';
				aux.push(codigo)
			});
			this.turnos.segundo.forEach(tarefa => {
				var codigo = tarefa.linha.value + '' + tarefa.dia.value + '' + tarefa.hora.value + '';
				aux.push(codigo)
			});
			this.turnos.terceiro.forEach(tarefa => {
				var codigo = tarefa.linha.value + '' + tarefa.dia.value + '' + tarefa.hora.value + '';
				aux.push(codigo)
			});
			return aux
		},
		codigosTarefas() {
			var aux = []
			this.dataTarefas.forEach(task => {
				var codigo = task.horario + '' + task.dia.value
				aux.push(codigo)
			})
			return aux
		},
		turnosCorretos() {
			var aux = []
			this.turnos.primeiro.forEach(turno => {
				var registro = {
					edturno: 'P',
					edlinha: turno.linha.value,
					edhorario: turno.hora.value
				}
				if (turno.edcodigo) {
					registro['edcodigo'] = turno.edcodigo
				}
				aux.push(registro)
			})
			this.turnos.segundo.forEach(turno => {
				var registro = {
					edturno: 'S',
					edlinha: turno.linha.value,
					edhorario: turno.hora.value
				}
				aux.push(registro)
			})
			this.turnos.terceiro.forEach(turno => {
				var registro = {
					edturno: 'T',
					edlinha: turno.linha.value,
					edhorario: turno.hora.value
				}
				aux.push(registro)
			})
			return aux;
		},
		turnoRegistrado() {
			return !(this.turnos.primeiro.length > 0 || this.turnos.segundo.length > 0 || this.turnos.terceiro.length > 0 || this.dataTarefas.length > 0)
		},
		tarefasCorretas() {
			var aux = []
			this.dataTarefas.forEach(el => {
				var tarefa = {
					'hetcodigotarefa': el.tarefa.value,
					'hetcodigodia': el.dia.value,
					'hethorario': el.horario
				}
				if (el['hetcodigo']) {
					tarefa['hetcodigo'] = el['hetcodigo']
				}
				aux.push(tarefa)
			});
			return aux
		},
	},

	async mounted() {
		this.escalaEditada = localStorage.getItem('escalaEditada');
		localStorage.removeItem('escalaEditada');
		if (this.escalaEditada) {
			this.optSelectEmpresa = new EmpresasService().Get()
			new HttpRequest().Post(this.link + 'editar', {'escala': this.escalaEditada})
				.then(async data => {
					await this.arrumaFormEscalas(data.data.escala)
					this.escalasColetivo.esdescricao = this.escalasColetivo.esdescricao
				})
				this.editandoVinculo = true;
		}else{
			this.optSelectEmpresa = new EmpresasService().Get()
			this.funcao = 'salvar'
			if (this.$route.params.escala) {
				this.funcao = 'atualizar'
				this.loadingPanel = true
				new HttpRequest().Post(this.link + 'editar', this.$route.params)
					.then(async data => {
						await this.arrumaFormEscalas(data.data.escala)
						if (this.$route.params.copia) {
							this.funcao = 'salvar'
							this.escalasColetivo.esdescricao = this.escalasColetivo.esdescricao + ' - CÓPIA'
						}
						this.loadingPanel = false
					})
			}
		}

	},


})
</script>

<style lang="scss" scoped>
.padding-tab {
	padding: 0.75% 0 0 0;
}

.underpadding {
	padding: 0 0 20px 0;
}
</style>