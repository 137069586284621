import Vuex from "../../store/index";
import { MarkerVeiculo } from "./MarkerVeiculo";

export class Veiculo {
    latitude?: number;
    longitude?: number;
    vemotorista?: number;
    veajudante?: number;
    direcao?: any;
    ignicao?: number;
    velocidade?: number;
    cidade?: string;
    dataEvento?: string;
    dataEventoDescricao?: string;
    dataEventoPadraoAmericano?: string;
    modulo?: number;
    semcomunicacao?: number;
    placa?: string;
    veplacaantiga?: string;
    vemodulo?: any;
    prefixo?: string;
    descricao?: string;
    veiculoId?: number;
    motoristaId?: number;
    arrayMotoristas?: any[];
    arrayAjudantes?: any[];
    motorista?: string;
    ajudanteId?: number;
    ajudante?: string;
    cliente?: string;
    tipo?: string;
    clcodigo?: number;
    ultimobloqueio?: number;
    ultimopanico?: number;
    modelo?: number;
    moultimomotivotransmissao?: number;
    moultimareferencia?: string;
    moultimatemperaturasensor?: number;
    vpibloqueioignicao?: number;
    vpipanico?: number;
    vpibau?: number;
    vpibetoneira?: number;
    vpiportamotorista?: number;
    vpitemperatura?: number;
    vpisirene?: number;
    vpiveiculo?: number;
    vpirfid?: number;
    veultimomotorista?: string;
    gvcor?: string;
    gvdescricao?: string;
    gvcodigo?: number;
    moduloSatelital?: boolean;
    alertas?: any[];
    tags?: any[];
    icon?: any;
    marker?: MarkerVeiculo | null;

    constructor(json: any) {
        this.latitude = json.lat ?? 1;
        this.longitude = json.lng ?? 1;
        this.vemotorista = json.vemotorista ?? 0;
        this.veajudante = json.veajudante ?? 0;
        this.direcao = json.direcao ?? 0;
        this.ignicao = json.ignicao ?? 0;
        this.velocidade = json.velocidade ?? 0;
        this.cidade = json.cidade ?? "";
        this.dataEvento = json.dataEvento ?? "";
        this.dataEventoDescricao = json.dataEventoDescricao ?? "";
        this.dataEventoPadraoAmericano = json.dataEventoPadraoAmericano ?? "";
        this.semcomunicacao = json.semcomunicacao ?? 1;
        this.modulo = json.modulo ?? 0;
        this.placa = json.placa ?? "";
        this.veplacaantiga = json.veplacaantiga ?? "";
        this.vemodulo = json.vemodulo ?? 0;
        this.prefixo = json.prefixo ?? "";
        this.descricao = json.descricao ?? "";
        this.veiculoId = json.veiculoId ?? 0;
        this.motoristaId = json.motoristaId ?? 0;
        this.arrayMotoristas = json.arrayMotoristas ?? [];
        this.arrayAjudantes = json.arrayAjudantes ?? [];
        this.motorista = json.motorista ?? "";
        this.ajudanteId = json.ajudanteId ?? 0;
        this.ajudante = json.ajudante ?? "";
        this.cliente = json.cliente ?? "";
        this.tipo = json.tipo ?? "";
        this.clcodigo = json.clcodigo ?? 0;
        this.ultimobloqueio = json.ultimobloqueio ?? 0;
        this.ultimopanico = json.ultimopanico ?? 0;
        this.modelo = json.modelo ?? 0;
        this.moultimomotivotransmissao = json.moultimomotivotransmissao ?? 0;
        this.moultimareferencia = json.moultimareferencia ?? "";
        this.moultimatemperaturasensor = json.moultimatemperaturasensor ?? 0;
        this.vpibloqueioignicao = json.vpibloqueioignicao ?? 0;
        this.vpipanico = json.vpipanico ?? 0;
        this.vpibau = json.vpibau ?? 0;
        this.vpibetoneira = json.vpibetoneira ?? 0;
        this.vpiportamotorista = json.vpiportamotorista ?? 0;
        this.vpitemperatura = json.vpitemperatura ?? 0;
        this.vpisirene = json.vpisirene ?? 0;
        this.vpiveiculo = json.vpiveiculo ?? 0;
        this.vpirfid = json.vpirfid ?? 0;
        this.veultimomotorista = json.veultimomotorista ?? 0;
        this.gvcor = json.gvcor ?? "";
        this.gvdescricao = json.gvdescricao ?? "";
        this.gvcodigo = json.gvcodigo ?? "";
        this.moduloSatelital = json.moduloSatelital ?? false;
        this.alertas = json.alertas ?? [];
        this.tags = json.tags ?? [];
        this.icon = this.criaIcon(this);
        this.marker = new MarkerVeiculo(this, this.icon);
    }

    atualizarDados(novo: Veiculo) {
        this.latitude = novo.latitude
        this.longitude = novo.longitude
        this.direcao = novo.direcao
        this.ignicao = novo.ignicao
        this.tipo = novo.tipo
        this.alertas = novo.alertas
        this.tags = novo.tags
        this.placa = novo.placa
        this.prefixo = novo.prefixo
        this.modulo = novo.modulo
        this.gvcor = novo.gvcor
        this.cidade = novo.cidade
        this.moultimareferencia = novo.moultimareferencia
        this.motorista = novo.motorista
        this.vpibloqueioignicao = novo.vpibloqueioignicao
        this.vpipanico = novo.vpipanico
        this.vpitemperatura = novo.vpitemperatura
        this.ultimobloqueio = novo.ultimobloqueio
        this.ultimopanico = novo.ultimopanico
        this.velocidade = novo.velocidade
        this.veultimomotorista = novo.veultimomotorista
        this.arrayMotoristas = novo.arrayMotoristas
        this.arrayAjudantes = novo.arrayAjudantes
        this.dataEvento = novo.dataEvento
        this.dataEventoDescricao = novo.dataEventoDescricao
        this.semcomunicacao = novo.semcomunicacao
        this.updateIcon()
        this.updateMarker()
    }

    updateIcon() {
        this.icon = this.criaIcon(this)
    }

    updateMarker() {
        if(this.marker) {
            this.marker?.atualizaIcone(this)
        } else {
            this.marker = new MarkerVeiculo(this, this.icon)
        }
    }

    /**
     * @param veiculo informações que vem do back-end aqui
     * @description Essa função cria o HTML do ícone do veículo,
     * eu queria não usar o divIcon por ser mais fácil e mais "previsivel"
     * mas pra fazer o prefixo aparecer, acredito que não
     * tenha muitas outras opções, para aumentar os icones do tamanho padrão 
     * foi mapeado todos os ícones de veículo do mapa para definir um estilo diferente para cada formato.
     * @author Rafael e Otávio  🦆 
     */
    criaIcon(veiculo) {
        var icone = this.outraFuncaoPraDefinirUmIcone(veiculo);
        const imagens = [
            { url: 'assets/mapa_finder/ADesligada0.svg', largura: 10, altura: 22},
            { url: 'assets/mapa_finder/ADesligada1.svg', largura: 20, altura: 18, diagonal: true},
            { url: 'assets/mapa_finder/ADesligada2.svg', largura: 22, altura: 10},
            { url: 'assets/mapa_finder/ADesligada3.svg', largura: 19, altura: 19, diagonal: true},
            { url: 'assets/mapa_finder/ADesligada4.svg', largura: 10, altura: 22},
            { url: 'assets/mapa_finder/ADesligada5.svg', largura: 19, altura: 19, diagonal: true},
            { url: 'assets/mapa_finder/ADesligada6.svg', largura: 22, altura: 10},
            { url: 'assets/mapa_finder/ADesligada7.svg', largura: 19, altura: 19, diagonal: true},
            { url: 'assets/mapa_finder/ADesligada8.svg', largura: 10, altura: 22},
            { url: 'assets/mapa_finder/ALigada0.svg', largura: 10, altura: 22},
            { url: 'assets/mapa_finder/ALigada1.svg', largura: 19, altura: 19},
            { url: 'assets/mapa_finder/ALigada2.svg', largura: 22, altura: 10},
            { url: 'assets/mapa_finder/ALigada3.svg', largura: 19, altura: 19, diagonal: true},
            { url: 'assets/mapa_finder/ALigada4.svg', largura: 10, altura: 22},
            { url: 'assets/mapa_finder/ALigada5.svg', largura: 19, altura: 19, diagonal: true},
            { url: 'assets/mapa_finder/ALigada6.svg', largura: 22, altura: 10},
            { url: 'assets/mapa_finder/ALigada7.svg', largura: 19, altura: 19, diagonal: true},
            { url: 'assets/mapa_finder/ALigada8.svg', largura: 10, altura: 22},
            { url: 'assets/mapa_finder/CAMDESligada.svg', largura: 11, altura: 36},
            { url: 'assets/mapa_finder/CAMDESligada2.svg', largura: 31, altura: 30, diagonal: true},
            { url: 'assets/mapa_finder/CAMDESligada3.svg', largura: 36, altura: 11},
            { url: 'assets/mapa_finder/CAMDESligada4.svg', largura: 30, altura: 31, diagonal: true},
            { url: 'assets/mapa_finder/CAMDESligada5.svg', largura: 11, altura: 36},
            { url: 'assets/mapa_finder/CAMDESligada6.svg', largura: 31, altura: 30, diagonal: true},
            { url: 'assets/mapa_finder/CAMDESligada7.svg', largura: 36, altura: 11},
            { url: 'assets/mapa_finder/CAMDESligada8.svg', largura: 31, altura: 30, diagonal: true},
            { url: 'assets/mapa_finder/CAMLigada.svg', largura: 11, altura: 36},
            { url: 'assets/mapa_finder/CAMLigada2.svg', largura: 30, altura: 30, diagonal: true},
            { url: 'assets/mapa_finder/CAMLigada3.svg', largura: 36, altura: 11},
            { url: 'assets/mapa_finder/CAMLigada4.svg', largura: 30, altura: 31, diagonal: true},
            { url: 'assets/mapa_finder/CAMLigada5.svg', largura: 11, altura: 36},
            { url: 'assets/mapa_finder/CAMLigada6.svg', largura: 31, altura: 30, diagonal: true},
            { url: 'assets/mapa_finder/CAMLigada7.svg', largura: 36, altura: 11},
            { url: 'assets/mapa_finder/CAMLigada7_1.svg', largura: 31, altura: 30, diagonal: true},
            { url: 'assets/mapa_finder/CDesligada0.svg', largura: 11, altura: 36},
            { url: 'assets/mapa_finder/CDesligada1.svg', largura: 31, altura: 30, diagonal: true},
            { url: 'assets/mapa_finder/CDesligada2.svg', largura: 36, altura: 11},
            { url: 'assets/mapa_finder/CDesligada3.svg', largura: 31, altura: 30, diagonal: true},
            { url: 'assets/mapa_finder/CDesligada4.svg', largura: 11, altura: 36},
            { url: 'assets/mapa_finder/CDesligada5.svg', largura: 30, altura: 11, diagonal: true},
            { url: 'assets/mapa_finder/CDesligada6.svg', largura: 36, altura: 11},
            { url: 'assets/mapa_finder/CDesligada7.svg', largura: 31, altura: 30, diagonal: true},
            { url: 'assets/mapa_finder/CFDESLIGADA0.svg', largura: 8, altura: 32},
            { url: 'assets/mapa_finder/CFDESLIGADA06.svg', largura: 32, altura: 8},
            { url: 'assets/mapa_finder/CFDESLIGADA1.svg', largura: 24, altura: 26, diagonal: true},
            { url: 'assets/mapa_finder/CFDESLIGADA2.svg', largura: 32, altura: 8},
            { url: 'assets/mapa_finder/CFDESLIGADA3.svg', largura: 24, altura: 26, diagonal: true},
            { url: 'assets/mapa_finder/CFDESLIGADA4.svg', largura: 8, altura: 32},
            { url: 'assets/mapa_finder/CFDESLIGADA5.svg', largura: 24, altura: 26, diagonal: true},
            { url: 'assets/mapa_finder/CFDESLIGADA07.svg', largura: 26, altura: 24, diagonal: true},
            { url: 'assets/mapa_finder/CFligada0.svg', largura: 8, altura: 32},
            { url: 'assets/mapa_finder/CFligada1.svg', largura: 24, altura: 26, diagonal: true},
            { url: 'assets/mapa_finder/CFligada2.svg', largura: 32, altura: 8},
            { url: 'assets/mapa_finder/CFligada3.svg', largura: 25, altura: 26, diagonal: true},
            { url: 'assets/mapa_finder/CFligada4.svg', largura: 8, altura: 32},
            { url: 'assets/mapa_finder/CFligada5.svg', largura: 25, altura: 26, diagonal: true},
            { url: 'assets/mapa_finder/CFligada6.svg', largura: 32, altura: 8},
            { url: 'assets/mapa_finder/CFligada7.svg', largura: 25, altura: 26, diagonal: true},
            { url: 'assets/mapa_finder/CLigada0.svg', largura: 11, altura: 26},
            { url: 'assets/mapa_finder/CLigada1.svg', largura: 31, altura: 30, diagonal: true},
            { url: 'assets/mapa_finder/CLigada2.svg', largura: 36, altura: 11},
            { url: 'assets/mapa_finder/CLigada3.svg', largura: 31, altura: 30, diagonal: true},
            { url: 'assets/mapa_finder/CLigada4.svg', largura: 11, altura: 36},
            { url: 'assets/mapa_finder/CLigada5.svg', largura: 30, altura: 31, diagonal: true},
            { url: 'assets/mapa_finder/CLigada6.svg', largura: 36, altura: 11},
            { url: 'assets/mapa_finder/CLigada7.svg', largura: 31, altura: 30, diagonal: true},
            { url: 'assets/mapa_finder/LIXOdesligada0.svg', largura: 11, altura: 31},
            { url: 'assets/mapa_finder/LIXOdesligada1.svg', largura: 26, altura: 28, diagonal: true},
            { url: 'assets/mapa_finder/LIXOdesligada2.svg', largura: 31, altura: 11},
            { url: 'assets/mapa_finder/LIXOdesligada3.svg', largura: 28, altura: 26, diagonal: true},
            { url: 'assets/mapa_finder/LIXOdesligada4.svg', largura: 11, altura: 31},
            { url: 'assets/mapa_finder/LIXOdesligada5.svg', largura: 27, altura: 28, diagonal: true},
            { url: 'assets/mapa_finder/LIXOdesligada6.svg', largura: 31, altura: 11},
            { url: 'assets/mapa_finder/LIXOdesligada7.svg', largura: 28, altura: 27, diagonal: true},
            { url: 'assets/mapa_finder/LIXOLIGADA0.svg', largura: 11, altura: 31},
            { url: 'assets/mapa_finder/LIXOLIGADA1.svg', largura: 26, altura: 28, diagonal: true},
            { url: 'assets/mapa_finder/LIXOLIGADA2.svg', largura: 31, altura: 11},
            { url: 'assets/mapa_finder/LIXOLIGADA03.svg', largura: 28, altura: 27, diagonal: true},
            { url: 'assets/mapa_finder/LIXOLIGADA04.svg', largura: 11, altura: 31},
            { url: 'assets/mapa_finder/LIXOLIGADA05.svg', largura: 27, altura: 28, diagonal: true},
            { url: 'assets/mapa_finder/LIXOLIGADA06.svg', largura: 31, altura: 11},
            { url: 'assets/mapa_finder/LIXOLIGADA7.svg', largura: 28, altura: 26, diagonal: true},
            { url: 'assets/mapa_finder/MDesligada0.svg', largura: 14, altura: 31},
            { url: 'assets/mapa_finder/MDesligada1.svg', largura: 27, altura: 24, diagonal: true},
            { url: 'assets/mapa_finder/MDesligada2.svg', largura: 31, altura: 14},
            { url: 'assets/mapa_finder/MDesligada3.svg', largura: 26, altura: 26, diagonal: true},
            { url: 'assets/mapa_finder/MDesligada4.svg', largura: 14, altura: 31},
            { url: 'assets/mapa_finder/MDesligada5.svg', largura: 26, altura: 26, diagonal: true},
            { url: 'assets/mapa_finder/MDesligada6.svg', largura: 31, altura: 14},
            { url: 'assets/mapa_finder/MDesligada7.svg', largura: 26, altura: 26, diagonal: true},
            { url: 'assets/mapa_finder/MLigada0.svg', largura: 14, altura: 31},
            { url: 'assets/mapa_finder/MLigada1.svg', largura: 27, altura: 25, diagonal: true},
            { url: 'assets/mapa_finder/MLigada2.svg', largura: 31, altura: 14},
            { url: 'assets/mapa_finder/MLigada3.svg', largura: 26, altura: 26, diagonal: true},
            { url: 'assets/mapa_finder/MLigada4.svg', largura: 14, altura: 31},
            { url: 'assets/mapa_finder/MLigada5.svg', largura: 25, altura: 26, diagonal: true},
            { url: 'assets/mapa_finder/MLigada6.svg', largura: 31, altura: 14},
            { url: 'assets/mapa_finder/MLigada7.svg', largura: 26, altura: 26, diagonal: true},
            { url: 'assets/mapa_finder/ODesligada0.svg', largura: 11, altura: 32},
            { url: 'assets/mapa_finder/ODesligada1.svg', largura: 30, altura: 28, diagonal: true},
            { url: 'assets/mapa_finder/ODesligada2.svg', largura: 32, altura: 11},
            { url: 'assets/mapa_finder/ODesligada3.svg', largura: 29, altura: 29, diagonal: true},
            { url: 'assets/mapa_finder/ODesligada4.svg', largura: 11, altura: 32},
            { url: 'assets/mapa_finder/ODesligada5.svg', largura: 29, altura: 29, diagonal: true},
            { url: 'assets/mapa_finder/ODesligada6.svg', largura: 32, altura: 11},
            { url: 'assets/mapa_finder/ODesligada7.svg', largura: 29, altura: 29, diagonal: true},
            { url: 'assets/mapa_finder/OLigada0.svg', largura: 11, altura: 32},
            { url: 'assets/mapa_finder/OLigada1.svg', largura: 30, altura: 28, diagonal: true},
            { url: 'assets/mapa_finder/OLigada2.svg', largura: 32, altura: 11},
            { url: 'assets/mapa_finder/OLigada3.svg', largura: 29, altura: 29, diagonal: true},
            { url: 'assets/mapa_finder/OLigada4.svg', largura: 11, altura: 32},
            { url: 'assets/mapa_finder/OLigada5.svg', largura: 29, altura: 29, diagonal: true},
            { url: 'assets/mapa_finder/OLigada6.svg', largura: 32, altura: 11},
            { url: 'assets/mapa_finder/OLigada7.svg', largura: 29, altura: 29, diagonal: true},
            { url: 'assets/mapa_finder/OVdesligado0.svg', largura: 10, altura: 29},
            { url: 'assets/mapa_finder/OVdesligado1.svg', largura: 24, altura: 26, diagonal: true},
            { url: 'assets/mapa_finder/OVdesligado2.svg', largura: 29, altura: 11},
            { url: 'assets/mapa_finder/OVdesligado3.svg', largura: 25, altura: 25, diagonal: true},
            { url: 'assets/mapa_finder/OVdesligado4.svg', largura: 11, altura: 29},
            { url: 'assets/mapa_finder/OVdesligado5.svg', largura: 24, altura: 26, diagonal: true},
            { url: 'assets/mapa_finder/OVdesligado6.svg', largura: 29, altura: 10},
            { url: 'assets/mapa_finder/OVdesligado7.svg', largura: 25, altura: 25, diagonal: true},
            { url: 'assets/mapa_finder/OVLIGADA0.svg', largura: 10, altura: 29},
            { url: 'assets/mapa_finder/OVLIGADA1.svg', largura: 24, altura: 26, diagonal: true},
            { url: 'assets/mapa_finder/OVLIGADA2.svg', largura: 28, altura: 11},
            { url: 'assets/mapa_finder/OVLIGADA3.svg', largura: 26, altura: 24, diagonal: true},
            { url: 'assets/mapa_finder/OVLIGADA4.svg', largura: 11, altura: 29},
            { url: 'assets/mapa_finder/OVLIGADA5.svg', largura: 25, altura: 25, diagonal: true},
            { url: 'assets/mapa_finder/OVLIGADA6.svg', largura: 29, altura: 11},
            { url: 'assets/mapa_finder/OVLIGADA7.svg', largura: 25, altura: 25, diagonal: true},
            { url: 'assets/mapa_finder/SEGdesligada0.svg', largura: 8, altura: 31},
            { url: 'assets/mapa_finder/SEGdesligada1.svg', largura: 25, altura: 26, diagonal: true},
            { url: 'assets/mapa_finder/SEGdesligada2.svg', largura: 31, altura: 8},
            { url: 'assets/mapa_finder/SEGdesligada3.svg', largura: 26, altura: 25, diagonal: true},
            { url: 'assets/mapa_finder/SEGdesligada4.svg', largura: 9, altura: 30},
            { url: 'assets/mapa_finder/SEGdesligada5.svg', largura: 24, altura: 26, diagonal: true},
            { url: 'assets/mapa_finder/SEGdesligada6.svg', largura: 31, altura: 8},
            { url: 'assets/mapa_finder/SEGdesligada7.svg', largura: 27, altura: 24, diagonal: true},
            { url: 'assets/mapa_finder/SEGLIGADA0.svg', largura: 8, altura: 31},
            { url: 'assets/mapa_finder/SEGLIGADA1.svg', largura: 24, altura: 26, diagonal: true},
            { url: 'assets/mapa_finder/SEGLIGADA2.svg', largura: 31, altura: 8},
            { url: 'assets/mapa_finder/SEGLIGADA3.svg', largura: 26, altura: 26, diagonal: true},
            { url: 'assets/mapa_finder/SEGLIGADA4.svg', largura: 8, altura: 31},
            { url: 'assets/mapa_finder/SEGLIGADA5.svg', largura: 25, altura: 26, diagonal: true},
            { url: 'assets/mapa_finder/SEGLIGADA6.svg', largura: 31, altura: 8},
            { url: 'assets/mapa_finder/SEGLIGADA07.svg', largura: 26, altura: 25, diagonal: true},
            { url: 'assets/mapa_finder/TDesligada0.svg', largura: 18, altura: 29},
            { url: 'assets/mapa_finder/TDesligada1.svg', largura: 30, altura: 28, diagonal: true},
            { url: 'assets/mapa_finder/TDesligada2.svg', largura: 29, altura: 18},
            { url: 'assets/mapa_finder/TDesligada3.svg', largura: 29, altura: 29, diagonal: true},
            { url: 'assets/mapa_finder/TDesligada4.svg', largura: 18, altura: 29},
            { url: 'assets/mapa_finder/TDesligada5.svg', largura: 29, altura: 29, diagonal: true},
            { url: 'assets/mapa_finder/TDesligada6.svg', largura: 29, altura: 18},
            { url: 'assets/mapa_finder/TDesligada7.svg', largura: 29, altura: 29, diagonal: true},
            { url: 'assets/mapa_finder/TLigada0.svg', largura: 18, altura: 29},
            { url: 'assets/mapa_finder/TLigada1.svg', largura: 30, altura: 28, diagonal: true},
            { url: 'assets/mapa_finder/TLigada2.svg', largura: 29, altura: 18},
            { url: 'assets/mapa_finder/TLigada3.svg', largura: 29, altura: 29, diagonal: true},
            { url: 'assets/mapa_finder/TLigada4.svg', largura: 18, altura: 29},
            { url: 'assets/mapa_finder/TLigada5.svg', largura: 29, altura: 29, diagonal: true},
            { url: 'assets/mapa_finder/TLigada6.svg', largura: 29, altura: 18},
            { url: 'assets/mapa_finder/TLigada7.svg', largura: 29, altura: 29, diagonal: true},
            { url: 'assets/mapa_finder/UDesligada.svg', largura: 10, altura: 22},
            { url: 'assets/mapa_finder/ULigada.svg', largura: 10, altura: 22},
        ];

        var img;

        const imagemInseridaMapa = imagens.find(imgs => imgs.url === icone);

        if(imagemInseridaMapa){     
            if(imagemInseridaMapa.diagonal){
                img = `<img src='${imagemInseridaMapa.url}' style='width:50px; height:32px;'>`;
            }else if(imagemInseridaMapa.largura > imagemInseridaMapa.altura){
                img = `<img src='${imagemInseridaMapa.url}' style='width:35px; height:20px; margin-left: -30px;'>`;
            } else{
                img = `<img src='${imagemInseridaMapa.url}' style='width:22px; height:36px; margin-left: -37px;'>`;
            }        
        }
        var span = `
            <span
                class='veprefixo d-flex justify-content-center align-items-center mt-1'
                style='background-color:${veiculo.gvcor || "white"}; width: 80px; height: 20px;font-size: 11px !important;'>
                    <div title="${veiculo.prefixo}" class="text-truncate position-relative" style="width:95%;font-size: 11px !important; margin-top 2px;"> ${veiculo.prefixo} </div>
                    ${this.criaBolinha(veiculo)}
                </div>
            </span>`;

        let tags = veiculo.tags ? veiculo.tags : []
        var icon = `
            <div
                class='markerVeiculo justify-content-center'
                id='${veiculo.modulo}'
                data-placa='${veiculo.placa}'
                data-codplaca='${veiculo.veiculoId}'> 
                    ${img}
                    ${span}
            </div>
            <div
                id='marker_veiculo_${veiculo.veiculoId}'
                class='marker-linha-badges'>
                    ${this.retornaTags(tags)}
            </div>
            `;
        return icon;
    }
    
    retornaTags(tags: Array<any>) {
        let str_retorno = "";
        tags.forEach((t, i) => {
            let str_tag = "";
            str_tag = `
                <div style='background:${t.tacor}' class='badge-tag mr-2' title='${t.tadescricao}'>
                    ${t.tadescricao}
                </div>
            `;
            str_retorno += str_tag;
        });
        return str_retorno;
    }

    /**
     * @param {object} veiculo
     * @param {number} veiculo.veiculoId
     * @param {array}  veiculo.alertas
     * @description decice se o badge do marcador de veículo vai ter
     * alertas(depende se o cliente tem acesso ao finderPlus)
     * e se sim, decide a cor da bolinha
     * @returns {string} div da bolinha ou nada
     */
    criaBolinha(veiculo: Veiculo): string {
        var modulos = Vuex.getters.getModulos;
        var temPlus = Object.keys(modulos).includes("finder/plus");
        if (temPlus)
            return `<div id='status_alertas_${veiculo.veiculoId}'
            class='bolinha alerta-${this.classeExtra(veiculo.alertas ?? [])} mr-1 mb-1'>`;
        return "";
    }

    /**
     * @param {array} alertas
     * @description decide a classe extra da bolinha
     * (verde/amarelo/vermelho)
     * @return {('vermelho'|'amarelo'|'verde')} classe extra da bolinha
     */
    classeExtra(alertas: Array<any>): string {
        var cVermelho,
            cAmarelo = false;
        alertas.forEach((al, i) => {
            var keys = Object.values(al);
            if (!cVermelho) cVermelho = keys.includes("vermelho");
            if (!cAmarelo) cAmarelo = keys.includes("amarelo");
        });
        if (cVermelho) return "vermelho";
        if (cAmarelo) return "amarelo";
        return "verde";
    }

    /**
     * Essa função "resolve" o ícone do veículo, pegando pelo tipo e
     * direção até agora (ainda preciso pegar ligado/desligado) variando
     * também
     */
    outraFuncaoPraDefinirUmIcone(veiculo: Veiculo): string {
        if (veiculo.tipo == "U") veiculo.direcao = "";
        let path = 'assets/mapa_finder/'
        let ignicao = `${veiculo.ignicao ? "Ligada" : "Desligada"}`
        let nomeArquivo = `${veiculo.tipo}${ignicao}${veiculo.direcao}`
        let caminho = `${path}${nomeArquivo}.svg`;
        return caminho;
    }
}
