<template>
  <PanelEagle id="relentregas" :loading="loadingPanel">
    <div class="col-sm-12 row nopadding" style="color: black">
      <div class="col-sm-12 row nopadding">
        <div class="col-sm-6" style="color:black">
          <tituloPage :icon="mdiCartCheck" titulo="Relatório de Entregas" />
        </div>

        <div class="col-sm-6">
          <basicButtonsRelatoriosEIG :disabled="$v.$invalid || loadingPanel || botaoGerar" :loading="loadingButton"
            @exportarRelatorio="exportarRelatorio" @gerarRelatorio="gerarRelatorio" :exportaTickets="true">
          </basicButtonsRelatoriosEIG>
        </div>

        <!-- Inputs -->
        <slideUpAndDown>
          <slot slot="filters">
            <div class="col-sm-12">
              <hr />
            </div>

            <div class="col-sm-12 row nopadding divDontPrint">
              <div class="col-sm-3 nopadding">
                <InputRangeWithLimit name="selectData" titulo="Intervalo" :isObrigatorio="true"
                  @changeInput="changeData" />
              </div>

              <div class="col-sm-3 nopadding">
                <SelectAll ref="selectEmpresasTickets" nameForRadio="seletorEmpresas" :labels="[{
                  indexDFH: 'E',
                  description: 'Empresas*'
                }]" :optionsArray="optionsSelectEmpresas" firstSelected="E" :hasSelectAll="true" :isMultiple="true"
                  @changeSelect="changeEmpresas" />
              </div>

              <div class="col-sm-3 nopadding">
                <div class="col-sm-12 nopadding">
                  <div class="d-flex justify-content-start" style="margin-top: -5px;">
                    <!-- Radio de Veículos -->
                    <div class="d-flex justify-content-start ml-5">
                      <input style="cursor: pointer;" class="form-check-input" type="radio" name="veiculoGrupoTag"
                        id="radioVeiculo" v-model="selectedRadio" :value="1" @change="selecionaTipo(1)">
                      <label class="select-none" for="radioVeiculo" style="cursor: pointer">Veículos *</label>
                    </div>

                    <!-- Radio de Grupos -->
                    <div class="d-flex justify-content-start ml-5">
                      <input style="cursor: pointer;" class="form-check-input" type="radio" name="veiculoGrupoTag"
                        id="radioGrupo" v-model="selectedRadio" :value="2" @change="selecionaTipo(2)">
                      <label class="select-none" for="radioGrupo" style="cursor: pointer">Grupos *</label>
                    </div>

                    <!-- Radio de Tags -->
                    <div class="d-flex justify-content-start ml-5">
                      <input style="cursor: pointer;" class="form-check-input" type="radio" name="veiculoGrupoTag"
                        id="radioTag" v-model="selectedRadio" :value="4" @change="selecionaTipo(3)">
                      <label class="select-none" for="radioTag" style="cursor: pointer">Tag *</label>
                    </div>
                  </div>

                  <div class="col-sm-12 row nopadding" style="margin-top: -2px;">
                    <!-- Select Veículos -->
                    <div v-if="tipoSelecionado == 1" class="col-sm-12 nopadding">
                      <selectAll :selected="selectedVeiculos" ref="srefSelectAllVeiculos"
                        @changeSelect="changeSelectVeiculos" nameForRadio="veiculoOuGrupoModal" :isMultiple="true"
                        :hasSelectAll="true" :optionsArray="veiculosDoBanco" :loading="loadingVeiculos"
                        :disabled="loadingVeiculos" />
                    </div>

                    <!-- Select Grupo -->
                    <div v-if="tipoSelecionado == 2" class="col-sm-12 nopadding">
                      <selectAll :selected="selectedGrupoVeiculos" ref="srefSelectAllGruposVeiculos"
                        @changeSelect="changeSelectGrupoVeiculos" nameForRadio="veiculoOuGrupoModal" :isMultiple="true"
                        :hasSelectAll="true" :optionsArray="grupoVeiculoDoBanco" :loading="loadingGrupos"
                        :disabled="loadingGrupos" />
                    </div>

                    <!-- Select Tags -->
                    <div v-if="tipoSelecionado == 3" class="col-sm-12 nopadding">
                      <selectAll :selected="selectedTagsVeiculos" ref="selectTags"
                        @changeSelect="changeSelectTagsVeiculos" nameForRadio="veiculoOuGrupoModal" :isMultiple="true"
                        :hasSelectAll="true" :optionsArray="tags" :loading="loadingTags" :disabled="loadingTags" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-3 nopadding">
                <SelectAll ref="selectMarcadores" nameForRadio="seletorMarcadores" :labels="[{
                  indexDFH: 'MA',
                  description: 'Marcador'
                }]" :optionsArray="optionsSelectMarcadores" firstSelected="E" :isMultiple="true" :hasSelectAll="true"
                  @changeSelect="changeMarcadores" :loading="loadingMarcadores" :disabled="loadingMarcadores"
                  :selected="selectedMarcadores" />
              </div>
            </div>
          </slot>
        </slideUpAndDown>

        <!-- Tabela -->
        <div class="col-sm-12 mt-3">
          <tableRelatorio htmlRel="" id="tabelaDetalhesRota">
            <slot slot="thead">
              <tr>
                <th style="width: 2%;"></th>
                <th>Marcador</th>
                <th>Quantidade de entregas</th>
                <th>Total peso</th>
                <th>Total cubagem</th>
                <th>Total valor</th>
              </tr>
            </slot>
            <slot slot="tbody">
              <statusInformation v-if="entregasRelatorio.length <= 0" typeBar="tr" :statusBar="statusbar">
              </statusInformation>

              <template v-for="(itemEntrega, index) in  entregasRelatorio">
                <tr v-if="true" :key="'itemEntrega' + index">
                  <td @click="itemEntrega.clicado = !itemEntrega.clicado" style="padding-top: 20px; text-align: start;"
                    v-b-toggle="index + '' + itemEntrega.ropontosaida">
                    <b-icon :icon="itemEntrega.clicado ? 'chevron-down' : 'chevron-right'" font-scale="1.3" />
                  </td>

                  <td style="width: 25%; padding: 5px; padding-top: 20px; text-align: start;">
                    <p class="styleDivMarcador text-truncate" :title="itemEntrega.marcador"
                      :style="'background-color:' + itemEntrega.cor">
                      {{ itemEntrega.marcador }}
                    </p>
                  </td>

                  <td :title="itemEntrega.qtdentregas == 1 ?
                    (`${itemEntrega.qtdentregas} entrega`) : (itemEntrega.qtdentregas == null ? ('0 entrega') :
                      (`${itemEntrega.qtdentregas} entregas`))"
                    style="width: 20%; padding-top: 20px; text-align: start;">
                    {{ itemEntrega.qtdentregas }}

                    {{ itemEntrega.qtdentregas == 1 ? ('entrega') : (itemEntrega.qtdentregas == null ? ('0 entrega') :
                      ('entregas')) }}
                  </td>

                  <td :title="itemEntrega.totalpeso ? (itemEntrega.totalpeso + ' kg') : ('Não há informação')"
                    style="width: 20%; padding-top: 20px; text-align: start;">{{
                      itemEntrega.totalpeso > 0 ? (itemEntrega.totalpeso + 'Kg') : ('Não há informação') }}
                  </td>

                  <td :title="itemEntrega.totalcubagem ? (itemEntrega.totalcubagem + 'm³') : ('Não há informação')"
                    style="width: 20%; padding-top: 20px; text-align: start;">
                    {{ itemEntrega.totalcubagem > 0 ? (itemEntrega.totalcubagem + 'm³') : ('Não há informação') }}
                  </td>

                  <td :title="itemEntrega.totalvalor ? ('R$' + itemEntrega.totalvalor) : ('Não há informação')"
                    style="width: 20%; padding-top: 20px; text-align: start;">
                    {{ itemEntrega.totalvalor ? ('R$' + itemEntrega.totalvalor) : ('Não há informação') }}</td>
                </tr>

                <template>
                  <tr :key="'itemEntrega' + index + 'adsasad'">
                    <td colspan="11" class="p-0 pb-3">
                      <b-collapse class="w100" :id="index + '' + itemEntrega.ropontosaida">
                        <div :key="'incio' + index" style="width: 95%; margin: auto"
                          class="d-flex justify-content-start align-items-center styleDivCabecalhoEntregas">
                          <div class="styleDivColumEntregas col-sm-4">Nome da entrega</div>
                          <div class="styleDivColumEntregas">Data/Hora</div>
                          <div class="styleDivColumEntregas">Qtd. Volumes</div>
                          <div class="styleDivColumEntregas">Peso</div>
                          <div class="styleDivColumEntregas">Cubagem</div>
                          <div class="styleDivColumEntregas">Valor</div>
                          <div class="styleDivColumEntregas">Status</div>
                        </div>

                        <div v-for="(entrega, index) in  itemEntrega.entregas" :key='index'
                          class="d-flex justify-content-start align-items-center" style="width: 95%; margin: auto">
                          <div :title="entrega.nomeentrega"
                            class="styleDivBodyColunsEntregas text-truncate border border-secondary-subtle col-sm-4">{{
                              entrega.nomeentrega }}
                          </div>
                          <div :title="formatarData(entrega.datahota)"
                            class="styleDivBodyColunsEntregas text-truncate border border-secondary-subtle">{{
                              formatarData(entrega.datahota) }}
                          </div>
                          <div :title="entrega.qtdentrega > 1 ?
                            (`${entrega.qtdentrega} volumes`) :
                            ('Não há informação')"
                            class="styleDivBodyColunsEntregas text-truncate border border-secondary-subtle">
                            {{ entrega.qtdentrega > 1 ?
                              (`${entrega.qtdentrega} volumes`) :
                              ('Não há informação') }}
                          </div>
                          <div :title="entrega.peso ? (entrega.peso + 'Kg') : ('Não há informação')"
                            class="styleDivBodyColunsEntregas text-truncate border border-secondary-subtle">
                            {{ entrega.peso ? (entrega.peso + 'Kg') : ('Não há informação') }}
                          </div>
                          <div :title="entrega.cubagem ? (entrega.cubagem + 'm³') : ('Não há informação')"
                            class="styleDivBodyColunsEntregas text-truncate border border-secondary-subtle">
                            {{ entrega.cubagem ? (entrega.cubagem + 'm³') : ('Não há informação') }}
                          </div>
                          <div :title="entrega.valor ? ('R$' + entrega.valor) : ('Não há informação')"
                            class="styleDivBodyColunsEntregas text-truncate border border-secondary-subtle">
                            {{ entrega.valor ? ('R$' + entrega.valor) : ('Não há informação') }}
                          </div>
                          <div :title="entrega.status"
                            class="styleDivBodyColunsEntregas text-truncate border border-secondary-subtle">{{
                              entrega.status }}
                          </div>
                        </div>
                      </b-collapse>
                    </td>
                  </tr>
                </template>
              </template>
            </slot>
          </tableRelatorio>
        </div>
      </div>
    </div>

    <div class="col-lg-2 campoObri">
      <span>(*) Campos obrigatórios</span>
    </div>

    <div class="col-sm-12 col-md-12">
      <hr>
    </div>
  </PanelEagle>
</template>

<script lang="js">
import { mdiCartCheck, mdiMonitorDashboard, mdiEyePlusOutline, mdiThumbUpOutline } from '@mdi/js'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import slideUpAndDown from '@/components/Atom/SlideUpAndDown/SlideUpAndDown.vue';
import InputRangeWithLimit from '@/components/Atom/Datas/InputRangeWithLimit.vue';
import SelectAll from '@/components/Atom/Select/SelectAll.vue';
import { EmpresasService } from '@/Services/auth/Empresas.service';
import PanelEagle from '@/components/Atom/Panel/PanelEagle.vue';
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { required } from 'vuelidate/lib/validators'

export default Vue.extend({
  name: "relentregas",

  validations: {
    dataIntervalo: { required },
    empresaSelecionada: { required },
  },

  components: {
    'tituloPage': require('@/components/Atom/Header/Titulo').default,
    slideUpAndDown,
    InputRangeWithLimit,
    SelectAll,
    PanelEagle,
    tableRelatorio: require('@/components/Atom/Table/TableRelatorio').default,
    'statusInformation': require('@/components/Atom/StatusInformation/StatusInformation').default,
    'basicButtonsRelatoriosEIG': require(
      '@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
  },

  data() {
    return {
      urlBase: '/roteirizador/relatorios/entregas',
      mdiCartCheck: mdiCartCheck,
      mdiMonitorDashboard: mdiMonitorDashboard,
      mdiEyePlusOutline: mdiEyePlusOutline,
      mdiThumbUpOutline: mdiThumbUpOutline,
      loadingPanel: false,
      dataIntervalo: '',
      empresaSelecionada: [],
      optionsSelectEmpresas: new EmpresasService().Get(),
      tipoSelecionado: 1,
      selectedVeiculos: [],
      valueVeiculos: [],
      veiculosDoBanco: [],
      selectedGrupoVeiculos: [],
      valueGrupoVeiculos: [],
      grupoVeiculoDoBanco: [],
      selectedTagsVeiculos: [],
      tagsSelecionadas: [],
      tags: [],
      optionsSelectMarcadores: [],
      marcadoresSelecionados: [],
      selectedMarcadores: [],
      selectedRadio: 1,
      tagSelecionada: false,
      loadingVeiculos: false,
      loadingGrupos: false,
      loadingTags: false,
      loadingMarcadores: false,
      entregasRelatorio: [],
      statusbar: 'info',
      iconFlecha: "chevron-right",
      relatorioReturn: [],
      mostrarSegundaTabela: false,
      botaoGerar: true,
      loadingButton: [false, false, false],
    }
  },

  methods: {
    ...mapGetters(['getMaster']),

    /**
    * Pega o valor da data do input de Data e salva na variável 
    this.dataIntervalo e limpa as demais informações do filtro e mapa
    * @param Data String
    * @author Yuri 🇧🇷
    */
    changeData(data) {
      this.dataIntervalo = data;
      this.consulta();
      this.limpaTabela();
      this.selectedVeiculos = [];
      this.selectedGrupoVeiculos = [];
      this.selectedTagsVeiculos = [];
    },

    /**
    * @description Atualiza o estado da aplicação ao trocar a empresa 
    selecionada, ao selecionar uma empresa chama a função this.consulta()
    * @param {string} empresa - O identificador da nova empresa selecionada.
    * @author Yuri 🇧🇷
    */
    changeEmpresas(empresa) {
      this.empresaSelecionada = empresa;
      this.consulta();
      this.limpaTabela();
      this.verificaSelecaoVeiculoGrupoTag();
    },

    /**
    * @description Adiciona/Remove o veículo selecionado no input veículos.
    * @param {string} veiculo - Veículo selecionado no input.
    * @author Yuri 🇧🇷
    */
    changeSelectVeiculos(veiculo) {
      this.valueVeiculos = veiculo;
      this.selectedGrupoVeiculos = [];
      this.selectedTagsVeiculos = [];
      this.tagsSelecionadas = [];
      this.limpaTabela();
      this.verificaSelecaoVeiculoGrupoTag();
    },

    /**
    * @description Adiciona/Remove o grupo selecionado no input grupos.
    * @param {string} Grupo - Grupo selecionado no input.
    * @author Yuri 🇧🇷
    */
    changeSelectGrupoVeiculos(grupo) {
      this.valueGrupoVeiculos = grupo;
      this.selectedVeiculos = [];
      this.selectedTagsVeiculos = [];
      this.valueVeiculos = [];
      this.tagsSelecionadas = [];
      this.limpaTabela();
      this.verificaSelecaoVeiculoGrupoTag();
    },

    /**
    * @description Adiciona/Remove a tag selecionada no input tags.
    * @param {string} Tag - Tag selecionado no input.
    * @author Yuri 🇧🇷
    */
    changeSelectTagsVeiculos(tag) {
      this.tagsSelecionadas = tag;
      this.selectedGrupoVeiculos = [];
      this.valueVeiculos = [];
      this.valueGrupoVeiculos = [];
      this.limpaTabela();
      this.verificaSelecaoVeiculoGrupoTag();
    },

    /**
    * @description Adiciona/Remove o marcador selecionado no 
    input marcadores.
    * @param {string} Marcador - Marcador selecionado no input.
    * @author Yuri 🇧🇷
    */
    changeMarcadores(marcador) {
      this.marcadoresSelecionados = marcador;
      this.limpaTabela();
      this.verificaSelecaoVeiculoGrupoTag();
    },

    /**
    * @description Verifica qual item está selecionado em 
    this.tipoSelecionado
    * 1 = Veículos
    * 2 = Grupos
    * 3 = Tags
    * Após isso verifica se o valor referente ao input 
    tem valor e libera o botão de gerar, caso
    * contrário deixa ele bloqueado.
    * @author Yuri 🇧🇷
    */
    verificaSelecaoVeiculoGrupoTag() {
      if (this.tipoSelecionado == 1) {
        if (this.valueVeiculos.length <= 0) {
          this.botaoGerar = true;
        } else {
          this.botaoGerar = false;
        }
      } else if (this.tipoSelecionado == 2) {
        if (this.valueGrupoVeiculos.length <= 0) {
          this.botaoGerar = true;
        } else {
          this.botaoGerar = false;
        }
      } else if (this.tipoSelecionado == 3) {
        if (this.tagsSelecionadas.length <= 0) {
          this.botaoGerar = true;
        } else {
          this.botaoGerar = false;
        }
      }
    },

    /**
     * Função para selecionar qual tipo de filtro foi 
     selecionado e mostra em tela para o usuário o inpput correspondente,
     * variável que salva qual tipo foi selecionado é 
     this.tipoSelecionado.
     * @param {tipo} number tipo para filtro selecionado, 
     1 = Veículo
     2 = Grupo
     3 = Tags
     * @author Yuri 🇧🇷
     */
    selecionaTipo(tipo) {
      this.tipoSelecionado = tipo;
      this.limpaTabela();
      this.verificaSelecaoVeiculoGrupoTag();
    },

    /**
    * @description Função realiza uma consulta no banco 
    para retornar os veículos cadastrados com base na 
    empresa selecionada this.empresaSelecionada
    * @author Yuri 🇧🇷
    */
    async buscaVeiculos() {
      this.loadingVeiculos = true;

      const url = this.urlBase + '/getVeiculos'
      const obj = {
        codigoEmpresa: this.empresaSelecionada
      }

      try {
        await new HttpRequest().Post(url, obj).then((data) => {
          this.veiculosDoBanco = data.data.veiculos;
        }).catch((error) => {

          this.loadingPanel = false;
          this.loadingVeiculos = false;
        }).finally(() => {

          this.loadingPanel = false;
          this.loadingVeiculos = false;

        });
      } catch (error) {
        this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
        this.loadingPanel = false;
        this.loadingVeiculos = false;
      }
    },

    /**
    * @description Função realiza uma consulta no 
    banco para retornar os grupos cadastrados com 
    base na empresa selecionada this.empresaSelecionada
    * @author Yuri 🇧🇷
    */
    async buscaGrupos() {
      this.loadingGrupos = true;

      const url = this.urlBase + '/getGrupos'
      const obj = {
        codigoEmpresa: this.empresaSelecionada
      }

      try {
        await new HttpRequest().Post(url, obj).then((data) => {
          this.grupoVeiculoDoBanco = data.data.grupos;
        }).catch((error) => {

          this.loadingPanel = false;
          this.loadingGrupos = false;
        }).finally(() => {

          this.loadingPanel = false;
          this.loadingGrupos = false;
        });
      } catch (error) {

        this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
        this.loadingPanel = false;
        this.loadingGrupos = false;
      }
    },

    /**
    * @description Função realiza uma consulta no 
    banco para retornar as tags cadastrados com 
    base na empresa selecionada this.empresaSelecionada
    * @author Yuri 🇧🇷
    */
    async buscaTags() {
      if (this.empresaSelecionada.length > 0) {
        this.loadingTags = true;

        const url = this.urlBase + '/getTags'
        const obj = {
          codigoEmpresa: this.empresaSelecionada
        }

        try {
          await new HttpRequest().Post(url, obj).then((data) => {
            this.tags = data.data.tags;
          }).catch((error) => {

            this.loadingPanel = false;
            this.loadingTags = false;
          }).finally(() => {

            this.loadingPanel = false;
            this.loadingTags = false;
          });
        } catch (error) {

          this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
          this.loadingPanel = false;
          this.loadingTags = false;
        }
      }
    },

    /**
    * @description Função realiza uma consulta no 
    banco para retornar os marcadores cadastrados 
    com base na empresa selecionada this.empresaSelecionada
    * @author Yuri 🇧🇷
    */
    async buscaMarcadores() {
      this.loadingMarcadores = true;

      const url = this.urlBase + '/getMarcadores'
      const obj = {
        codigoEmpresa: this.empresaSelecionada,
        intervalo: this.dataIntervalo
      }

      try {
        await new HttpRequest().Post(url, obj).then((data) => {
          this.optionsSelectMarcadores = data.data.marcadores;
        }).catch((error) => {
          this.loadingPanel = false;
          this.loadingMarcadores = false;
        }).finally(() => {

          this.loadingPanel = false;
          this.loadingMarcadores = false;
        });
      } catch (error) {

        this.toastShow('Tente novamente mais tarde', 'danger')
        this.loadingPanel = false;
        this.loadingMarcadores = false;
      }
    },

    /**
    * @description Função para realizar a consulta 
    nos inputs necessários, limpa as informações 
    nos inputs, veículos, grupos, tags e marcadores 
    após isso ele chama as função abaixo:
    * this.buscaVeiculos();
    * this.buscaGrupos();
    * this.buscaTags();
    * this.buscaMarcadores();
    * @author Yuri 🇧🇷
    */
    consulta() {
      this.selectedVeiculos = [];
      this.selectedGrupoVeiculos = [];
      this.selectedTagsVeiculos = [];
      this.selectedMarcadores = [];
      this.tags = [];

      this.buscaVeiculos();
      this.buscaGrupos();
      this.buscaTags();
      this.buscaMarcadores();
    },

    /**
   * @description Método para mostrar o toast na tela
   * @param {string} msg   - mensagem que vai aparecer no corpo do toast
   * @param {string} type  - qual o tipo do toast
   * @param {string} title - título para inserir no cabeçalho
   * @author Vitor Hugo 🐨
   */
    toastShow(title, msg, type) {
      this.$bvToast.toast(msg, {
        autoHideDelay: 2500,
        variant: type,
        title: title
      });
    },

    /**
     * @description Função realiza a consulta do 
     * relatório no back-end e salva as informações na 
     * variável this.entregasRelatorio().
     * @author Yuri 🇧🇷
     */
    async gerarRelatorio() {
      this.loadingPanel = true;
      const url = this.urlBase + '/gerar/relatorio'

      const obj = {
        intervalo: this.dataIntervalo,
        veiculos: this.valueVeiculos,
        grupos: this.valueGrupoVeiculos,
        tags: this.tagsSelecionadas,
        marcadores: this.marcadoresSelecionados
      }
      this.entregasRelatorio = [];
      this.statusbar = 'info';

      try {
        await new HttpRequest().Post(url, obj).then((data) => {
          this.entregasRelatorio = data.data.relatorio;

          if (data.data.relatorio.length <= 0) {
            this.statusbar = 'error';
          }
        }).catch((error) => {
          this.loadingPanel = false;
        }).finally(() => {

          this.loadingPanel = false;
        });
      } catch (error) {
        this.toastShow('Tente novamente mais tarde', 'danger')
        this.loadingPanel = false;
      }
    },

    /**
     * @description Função para limpar os dados da tabela 
     * this.entregasRelatorio.
     * @author Yuri 🇧🇷
     */
    limpaTabela() {
      this.entregasRelatorio = [];
    },

    /**
     * @description Função para formatar a data e hora
     * @return Retorna a data e hora formada, dd/mm/yyyy hh:mm
     * @author Yuri 🇧🇷
     */
    formatarData(data) {
      if (data != null) {
        const dataObj = new Date(data);
        const dia = ('0' + dataObj.getDate()).slice(-2);
        const mes = ('0' + (dataObj.getMonth() + 1)).slice(-2);
        const ano = dataObj.getFullYear();
        const horas = ('0' + dataObj.getHours()).slice(-2);
        const minutos = ('0' + dataObj.getMinutes()).slice(-2);

        return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
      } else {
        return 'Não confirmado';
      }
    },

    /**
     * @description Função para exportar o relatório, em PDF,
     * Excel ou CSV e ao final abre ou faz o download do mesmo.
     * @param string, com o tipo da exportação que será o relatório.
     * @author Yuri 🇧🇷
     */
    async exportarRelatorio(tipo) {
      this.loadingButton = [tipo == 'pdf', tipo == 'xls', tipo == 'csv', tipo == 'tickets']
      this.loadingPanel = false;
      const url = this.urlBase + '/exportar'
      var root = process.env.VUE_APP_ROOT

      const obj = {
        'arrayDados': JSON.stringify(this.entregasRelatorio),
        'intervalo': this.dataIntervalo,
        'tipo': tipo,
        'empresas': this.empresaSelecionada
      }

      try {
        await new HttpRequest().Post(url, obj).then((data) => {
          if (obj.tipo == 'tickets') {
            this.geraResposta(data)
          } else {
            if (data.status) {
              window.open(root + '/' + data.data.local)
            }
          }
        }).catch((error) => {

          this.loadingPanel = false;
          this.loadingButton = [false, false, false, false]
        }).finally(() => {

          this.loadingPanel = false;
          this.loadingButton = [false, false, false, false]
        });
      } catch (error) {

        this.toastShow('Tente novamente mais tarde', 'danger')
        this.loadingPanel = false;
        this.loadingButton = [false, false, false, false]
      }
    },

    /**
     * @description Função para notificar o usuário referente a exportação dos Tickets
     * @param response, Retorno do back-end com local e status da exportação.
     * @author Yuri 🇧🇷
     */
    geraResposta(response) {
      let msg, title, type = ''
      if (response.code == 200) {
        msg = "Solicitação realizada com sucesso, " +
          "irá receber uma notificação assim que estiver disponível para download"
        title = "Sucesso!"
        type = "success"
      } else {
        msg = "Não foi possivel criar o arquivo"
        title = "Ops!"
        type = "danger"
      }
      this.toastShow(title, msg, type)
    },
  },
})
</script>

<style lang="css">
.styleDivCabecalho {
  background-color: #ececec;
  font-weight: bold;
}

.borderLeft {
  border-style: solid;
  border-color: #c5c5c5;
  border-right-width: 0px;
  border-left-width: 2px;
  border-top-width: 0px;
  border-bottom-width: 0px;
}

.divInicio {
  background-color: #cacaca;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px
}

.campoObri {
  margin-top: 14px;
  font-size: 14px;
  text-align: left;
  margin-left: 10px;
}

.styleDivMarcador {
  color: white;
  font-weight: bold;
  padding: 5px
}

.styleDivColumEntregas {
  width: 20%;
  color: black;
  font-weight: bold;
}

.styleDivCabecalhoEntregas {
  padding: 10px;
  background-color: #b4b1b5;
}

.styleDivBodyColunsEntregas {
  width: 20%;
  color: black;
  padding: 5px;

  height: 100%;
  width: 100%;
}
</style>