<template>
    <div>
        <modalEagle :id="idModal" title="Ajustar horas" @modalClose="modalClose" size="xl">
            <slot slot="modalBody">
                <b-overlay :show="loading">
                    <div class="col-sm-12 row pb-3 pl-0">
                        <div class="col-sm-4 row pl-0 pr-0">
                            <!-- Empresas -->
                            <selectAll
                                nameForRadio="radiosEmpresasModalCarro"
                                :isMultiple="false"
                                :labels="labelsSelectEmpresa"
                                :optionsArray="optionsSelectEmpresa"
                                firstSelected="E"
                                :hasSelectAll="false"
                                @changeSelect="changeEmpresaModalAjustes"
                            >
                            </selectAll>
                        </div>
                        <div class="col-sm-4 row pl-0 pr-0">
                            <!-- Colaborador -->
                            <selectAll
                                nameForRadio="seletorColaboradorModalCarro"
                                firstSelected="C"
                                extraClassParent="nopadding"
                                v-bind="{
                                    hasSelectAll: false,
                                    isMultiple: false,
                                    optionsArray: optSelectColaboradoresModalAjustes,
                                    labels: labelsSelectColaboradoresModal,
                                    ref: 'selectCA',
                                }"
                                :disabled="$v.empresaSelecionadasModalAjustes.$invalid"
                                :loading="carregando"
                                @changeSelect="changeColaboradoresModalAjustes"
                            />
                        </div>
                        <div class="col-sm-4 row pl-0 pr-0">
                            <!-- Veiculos -->
                            <selectAll
                                nameForRadio="SeletorVeiculosModalCarro"
                                :isMultiple="false"
                                :labels="labelsSelectVeiculosModal"
                                :optionsArray="optSelectVeiculosModalAjustes"
                                firstSelected="E"
                                ref="selectVC"
                                :hasSelectAll="false"
                                :loading="carregando"
                                :disabled="$v.empresaSelecionadasModalAjustes.$invalid"
                                @changeSelect="changeVeiculoModalAjustes"
                            >
                            </selectAll>
                        </div>
                    </div>
                    <div class="col-sm-12 row pl-0 mb-3">
                        <div class="col-sm-4 row pl-0 pr-0">
                            <!-- Intervalo -->
                            <inputRangeWithLimit
                                @changeInput="changeDataIntervaloModalAjustes"
                                :intervalo="2"
                                :isObrigatorio="true"
                                :titulo="'Intervalo'"
                                title="Limite máximo de 2 dias"
                                name="inputDataJModalCarro"
                                opens="right"
                            />
                        </div>
                        <div class="col-sm-2 row divButtonModalAjustes pl-0 pr-1">
                            <!-- Gerar relatório -->
                            <simpleButton
                                class="buttonGerarModalCarro"
                                :icon="mdiThumbUpOutline"
                                width="100%"
                                text="Gerar"
                                event="click"
                                @click="gerarTabelaAjustarHoraVeiculos"
                                :disabled="
                                    $v.empresaSelecionadasModalAjustes.$invalid ||
                                    $v.colabSelecionadoModalAjustes.$invalid ||
                                    $v.veiculoSelecionadoModalAjustes.$invalid
                                "
                                type="green"
                            >
                            </simpleButton>
                        </div>
                        <div class="col-sm-6 legendaAvisoFechamento" 
                            v-if="temAlgumFechamento">
                            <baseIcon :icon="mdiAlertOctagonOutline" size="20" />
                            Indica que há fechamento e não é permitido fazer alterações.
                        </div>
                    </div>
                    <!-- <div
                        v-if="!tabelaAjustesVeiculos.length"
                        class="col-sm-12 fechamento"
                        v-show="possuiFechamento[1]"
                        :title="msgFechamento"
                    >
                        Possui fechamento de {{ possuiFechamento[1] }}
                        <baseIcon :icon="mdiHelpCircleOutline" size="16" />
                    </div> -->
                    <tableRelatorio>
                        <!-- Cabeçalho -->
                        <slot slot="thead">
                            <tr>
                                <th style="width: 21%">Início</th>
                                <th style="width: 21%">Fim</th>
                                <th style="width: 29%">
                                    <div class="col-sm-12 nopadding row">
                                        <div class="col-sm-8 nopadding motoristaModalCarroTh">
                                            Motorista
                                        </div>
                                        <div class="col-sm-4 p-0 pr-2"
                                            v-if="mostrarBtnFacilitador('M')">
                                            <simpleButton
                                                v-if="
                                                    isMotorista &&
                                                    tabelaAjustesVeiculos.length &&
                                                    mostrarBotaoAssociarTudo(isMotorista)
                                                "
                                                class="associarColaboradorModalCarro"
                                                event="click"
                                                type="blue"
                                                :icon="mdiSwapVertical"
                                                sizeIcon="24"
                                                @click="assocDesasAjustesVeiculo('', 'MA')"
                                            >
                                            </simpleButton>
                                            <simpleButton
                                                v-if="
                                                    tabelaAjustesVeiculos.length &&
                                                    mostrarBotaoDesssociarTudo('M')
                                                "
                                                class="desassociarColaboradorModalCarro"
                                                event="click"
                                                :icon="mdiCloseCircleOutline"
                                                sizeIcon="24"
                                                type="red"
                                                @click="assocDesasAjustesVeiculo('', 'MD')"
                                            >
                                            </simpleButton>
                                        </div>
                                        <div v-else class="col-sm-4 p-0 pr-2">
                                            <baseIcon v-if="tabelaAjustesVeiculos.length"
                                                :icon="mdiAlertOctagonOutline" 
                                                color="orange"
                                                size="20" 
                                                class="iconeAlertaBloqueio"
                                                v-b-tooltip.hover="'Facilitador desabilitado pois há algum fechamento!'"
                                            />
                                        </div>
                                    </div>
                                </th>
                                <th style="width: 29%">
                                    <div class="col-sm-12 nopadding row">
                                        <div class="col-sm-8 nopadding motoristaModalCarroTh">
                                            Ajudante
                                        </div>
                                        <div class="col-sm-4 p-0 pr-2"
                                            v-if="mostrarBtnFacilitador('A')">
                                            <simpleButton
                                                v-if="
                                                    isAjudante &&
                                                    tabelaAjustesVeiculos.length &&
                                                    mostrarBotaoAssociarTudo(isAjudante)
                                                "
                                                class="associarColaboradorModalCarro"
                                                event="click"
                                                type="blue"
                                                :icon="mdiSwapVertical"
                                                sizeIcon="24"
                                                @click="assocDesasAjustesVeiculo('', 'AA')"
                                            >
                                            </simpleButton>
                                            <simpleButton
                                                v-if="
                                                    tabelaAjustesVeiculos.length &&
                                                    mostrarBotaoDesssociarTudo('A')
                                                "
                                                class="desassociarColaboradorModalCarro"
                                                event="click"
                                                :icon="mdiCloseCircleOutline"
                                                sizeIcon="24"
                                                type="red"
                                                @click="assocDesasAjustesVeiculo('', 'AD')"
                                            >
                                            </simpleButton>
                                        </div>
                                        <div v-else class="col-sm-4 p-0 pr-2">
                                            <baseIcon v-if="tabelaAjustesVeiculos.length"
                                                :icon="mdiAlertOctagonOutline" 
                                                color="orange"
                                                size="20" 
                                                class="iconeAlertaBloqueio"
                                                v-b-tooltip.hover="'Facilitador desabilitado pois há algum fechamento!'"
                                            />
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </slot>
                        <slot v-if="tabelaAjustesVeiculos.length == 0 ? true : false" slot="tbody">
                            <statusInformation
                                typeBar="tr"
                                colspanForTd="20"
                                :statusBar="statusBarAjustesVeiculos"
                            >
                            </statusInformation>
                        </slot>
                        <!-- Corpo da tabela -->
                        <slot v-else slot="tbody">
                            <!-- <tr
                                class="col-sm-12 fechamento fechamento__grude"
                                v-show="possuiFechamento[1]"
                                :title="msgFechamento"
                            >
                                <td colspan="20">
                                    Possui fechamento de {{ possuiFechamento[1] }}
                                    <baseIcon :icon="mdiHelpCircleOutline" size="16" />
                                </td>
                            </tr> -->
                            <tr v-for="(ignicao, k) in tabelaAjustesVeiculos" :key="k">
                                <!-- Coluna da data inicial -->
                                <td class="tdMotoristaModalCarro">
                                    {{ transformaDataModal(ignicao.dataIni) }}
                                </td>
                                <!-- Coluna da data final -->
                                <td class="tdMotoristaModalCarro">
                                    {{ transformaDataModal(ignicao.dataFim) }}
                                </td>
                                <!-- Coluna do condutor, spans alteram conforme valores que vem do back
                                os dois últimos são botões -->
                                <td class="tdMotoristaModalCarro">
                                    <div class="col-sm-12 nopadding row">
                                        <div class="col-sm-8 nopadding motoristaModalCarro">
                                            {{
                                                ignicao.motoristanome
                                                    ? ignicao.motoristanome
                                                    : "Sem Motorista"
                                            }}
                                        </div>
                                        <div class="col-sm-4 nopadding">
                                            <baseIcon v-if="ignicao.temFechamento || ignicao.temFechamentoMotorista" 
                                                :icon="mdiAlertOctagonOutline" 
                                                color="orange"
                                                size="20" 
                                                class="iconeAlertaBloqueio"
                                                v-b-tooltip.hover="defineMensagemFechamento(ignicao, 'M')"
                                            />
                                            <!-- Botão associar Condutor -->
                                            <simpleButton
                                                v-if="mostrarBotaoAssociar(ignicao, isMotorista) && !ignicao.temFechamento && !ignicao.temFechamentoMotorista"
                                                class="associarColaboradorModalCarro"
                                                event="click"
                                                type="blue"
                                                :icon="mdiSwapVertical"
                                                sizeIcon="24"
                                                :disabled="ignicao.temFechamento || ignicao.temFechamentoMotorista"
                                                @click="assocDesasAjustesVeiculo(ignicao, 'MA')"
                                            >
                                            </simpleButton>
                                            <!-- Botão desassociar Condutor -->
                                            <simpleButton
                                                v-if="ignicao.motoristanome && !ignicao.temFechamentoMotorista"
                                                class="desassociarColaboradorModalCarro"
                                                event="click"
                                                :icon="mdiCloseCircleOutline"
                                                sizeIcon="24"
                                                type="red"
                                                :disabled="ignicao.temFechamentoMotorista"
                                                @click="assocDesasAjustesVeiculo(ignicao, 'MD')"
                                            >
                                            </simpleButton>
                                        </div>
                                    </div>
                                </td>
                                <!-- Coluna do ajudante, spans alteram conforme valores que vem do back
                                os dois últimos são botões -->
                                <td class="tdMotoristaModalCarro">
                                    <div class="col-sm-12 nopadding row">
                                        <div class="col-sm-8 nopadding motoristaModalCarro">
                                            {{
                                                ignicao.ajudantenome
                                                    ? ignicao.ajudantenome
                                                    : "Sem Ajudante"
                                            }}
                                        </div>
                                        <div class="col-sm-4 nopadding">
                                            <baseIcon v-if="ignicao.temFechamento || ignicao.temFechamentoAjudante" 
                                                :icon="mdiAlertOctagonOutline" 
                                                color="orange"
                                                size="24" 
                                                class="iconeAlertaBloqueio"
                                                v-b-tooltip.hover="defineMensagemFechamento(ignicao, 'A')"
                                            />
                                            <!-- Botão associar Condutor -->
                                            <simpleButton
                                                v-if="mostrarBotaoAssociar(ignicao, isAjudante) && !ignicao.temFechamento && !ignicao.temFechamentoAjudante"
                                                class="associarColaboradorModalCarro"
                                                event="click"
                                                type="blue"
                                                :icon="mdiSwapVertical"
                                                sizeIcon="24"
                                                :disabled="ignicao.temFechamento || ignicao.temFechamentoAjudante"
                                                @click="assocDesasAjustesVeiculo(ignicao, 'AA')"
                                            >
                                            </simpleButton>
                                            <!-- Botão desassociar Condutor -->
                                            <simpleButton
                                                v-if="ignicao.ajudantenome  && !ignicao.temFechamentoAjudante"
                                                class="desassociarColaboradorModalCarro"
                                                event="click"
                                                type="red"
                                                :icon="mdiCloseCircleOutline"
                                                :disabled="ignicao.temFechamentoAjudante"
                                                sizeIcon="24"
                                                @click="assocDesasAjustesVeiculo(ignicao, 'AD')"
                                            >
                                            </simpleButton>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </slot>
                    </tableRelatorio>
                </b-overlay>
            </slot>
            <slot>
                <simpleButton
                    :icon="mdiCheckBold"
                    @click="cancelButtonModalAjustes"
                    text="Finalizar"
                    event="click"
                    type="blue"
                    class="mr-4"
                >
                </simpleButton>
            </slot>
        </modalEagle>
        <modalJustificativaAjuste
            ref="modalJustificativaAjusteAV"
            idModal="modalJustificativaAjusteAV"
            @confirmar="changeJustificativa"
        />
    </div>
</template>
<script>
import Vue from "vue";
import {
    mdiCheckBold,
    mdiThumbUpOutline,
    mdiSwapVertical,
    mdiCloseCircleOutline,
    // mdiHelpCircleOutline,
    mdiAlertOctagonOutline,
} from "@mdi/js";
import { required } from "vuelidate/lib/validators";
import { EmpresasService } from "../../../Services/auth/Empresas.service";
// import { 
//     // validaUltimoFechamento,
//     // validaUltimoFechamentoPorDia,
// } from "@/Services/Helpers/FechamentoHelper";
import { HttpRequest } from "../../../Services/auth/HttpRequest.Service";
import { FiltrosService } from "../../../Services/filtros/filtros.Service";
import { DateTime } from "luxon";
import { conectionError } from "@/Services/Helpers/swellHeper";
export default Vue.extend({
    name: "ModalAjustesVeiculos",
    components: {
        simpleButton: require("@/components/Atom/Buttons/SimpleButton").default,
        modalEagle: require("@/components/Atom/Modal/ModalEagle.vue").default,
        selectAll: require("@/components/Atom/Select/SelectAll").default,
        // 'inputSingleDay'    : require('@/components/Atom/Datas/InputSingleDay').default,
        tableRelatorio: require("@/components/Atom/Table/TableRelatorio").default,
        statusInformation: require("@/components/Atom/StatusInformation/StatusInformation").default,
        inputRangeWithLimit: require("@/components/Atom/Datas/InputRangeWithLimit").default,
        modalJustificativaAjuste:
            require("@/components/Atom/ModalJornadaFolha/ModalJustificativaAjuste").default,
        baseIcon: require("@/components/Atom/Icon/BaseIcon").default,
    },

    props: {
        idModal: {
            type: [Number, String],
            required: true,
        },
    },

    validations: {
        empresaSelecionadasModalAjustes: {
            required,
        },

        colabSelecionadoModalAjustes: {
            required,
        },

        veiculoSelecionadoModalAjustes: {
            required,
        },
    },

    data() {
        return {
            mdiThumbUpOutline: mdiThumbUpOutline,
            mdiCheckBold: mdiCheckBold,
            mdiCloseCircleOutline: mdiCloseCircleOutline,
            mdiSwapVertical: mdiSwapVertical,
            // mdiHelpCircleOutline: mdiHelpCircleOutline,
            mdiAlertOctagonOutline: mdiAlertOctagonOutline,
            // msgFechamento:
            //     "Após realizar fechamento não é possível alterar jornada dos colaboradores",
            url: "/controle/jornada/relatorios/folha/ponto/",
            optionsSelectEmpresa: new EmpresasService().Get(),
            labelsSelectEmpresa: [{ indexDFH: "E", description: "Empresas*" }],
            labelsSelectColaboradoresModal: [{ indexDFH: "C", description: "Colaborador*" }],
            labelsSelectVeiculosModal: [{ indexDFH: "V", description: "Veículos*" }],
            optSelectColaboradoresModalAjustes: [],
            optSelectVeiculosModalAjustes: [],
            empresaSelecionadasModalAjustes: [],
            colabSelecionadoModalAjustes: null,
            veiculoSelecionadoModalAjustes: [],
            tabelaAjustesVeiculos: [],
            statusBarAjustesVeiculos: "info",
            loading: false,
            carregando: false,
            // ultimoFechamento: null,
            justificativa: null,
            // possuiFechamento: [],
            optJustificativas: [],
            temAlgumFechamento: false,
        };
    },

    methods: {
        /**
         * @description Essa função deve ser utilizada por uma referência para "ativar"
         * essa modal, os argumentos da função são os necessários para
         * conseguir popular a modal com as infomações corretas
         * @author Vitor Hugo 🐨
         */
        preparaModalAjustesVeiculos() {
            this.tabelaAjustesVeiculos = [];
            this.temAlgumFechamento = false;
            this.$bvModal.show(this.idModal);
        },

        //Início dos métodos para pegar valor dos inputs
        changeEmpresaModalAjustes(empresa) {
            this.tabelaAjustesVeiculos = [];
            this.temAlgumFechamento = false;
            if (this.veiculoSelecionadoModalAjustes.length > 0) {
                this.$refs.selectVC.clearAll();
            }
            this.empresaSelecionadasModalAjustes = empresa;
            this.$refs.selectCA.clearAll();
            this.buscaColaboradoresModalAjustes(empresa);
            this.buscaPontos(empresa);
        },

        //Método para buscar colaboradores para o select do modal Ajustes
        buscaColaboradoresModalAjustes(arr) {
            if (arr.length > 0) {
                this.carregando = true;
                new FiltrosService().dados_filtros(arr, ["V", "JJ"]).then((data) => {
                    this.dataV = data.V;
                    this.dataJJ = data.JJ;
                    this.optSelectVeiculosModalAjustes = this.dataV;
                    this.optJustificativas = this.dataJJ;
                });
                this.carregando = false;
            } else {
                this.colabSelecionadoModalAjustes = null;
                this.veiculoSelecionadoModalAjustesAdicionarEvento = [];
            }
        },

        async buscaPontos(arr) {
            this.carregando = true;
            var body = { cliente: arr[0], somenteColabs: true };
            var dados = await new HttpRequest().Post(`${this.url}buscaPontos`, body);
            this.optSelectColaboradoresModalAjustes = dados.data.colabs;
            this.carregando = false;
        },

        changeColaboradoresModalAjustes(colaborador) {
            this.tabelaAjustesVeiculos = [];
            this.temAlgumFechamento = false;
            this.colabSelecionadoModalAjustes = null;
            this.optSelectColaboradoresModalAjustes.forEach((element) => {
                if (colaborador[0] == element.value) {
                    this.colabSelecionadoModalAjustes = element;
                    // this.getUltimoFechamento(colaborador[0]);
                }
            });
        },

        // getUltimoFechamento(colabSelecionado) {
        //     let objRequisicao = {
        //         mtcodigo: colabSelecionado,
        //         dataFim: this.dataSelecionadaModalAjustes.split(" - ")[1],
        //     };
        //     const url = "/controle/jornada/relatorios/folha/ponto/";
        //     new HttpRequest().Post(`${url}getUltimoFechamento`, objRequisicao).then((response) => {
        //         this.ultimoFechamento = response.data;
        //         // this.possuiFechamento = validaUltimoFechamento(
        //         //     this.ultimoFechamento,
        //         //     this.dataSelecionadaModalAjustes
        //         // );
        //     });
        // },

        changeVeiculoModalAjustes(veiculo) {
            this.tabelaAjustesVeiculos = [];
            this.temAlgumFechamento = false;
            this.veiculoSelecionadoModalAjustes = veiculo;
        },

        changeDataIntervaloModalAjustes(date) {
            this.tabelaAjustesVeiculos = [];
            this.temAlgumFechamento = false;
            this.dataSelecionadaModalAjustes = date;
            // this.validaFechamentoDentroDoIntervalo();
        },

        /**
         * verifica se o funcionario ainda tem um fechamento dentro do intervalo
         * selecionado sem precisar fazer uma nova requisição.
         */
        // validaFechamentoDentroDoIntervalo() {
        //     if (this.colabSelecionadoModalAjustes && this.ultimoFechamento) {
        //         // this.possuiFechamento = validaUltimoFechamento(
        //         //     this.ultimoFechamento,
        //         //     this.dataSelecionadaModalAjustes
        //         // );
        //         // this.validarDadosTabela()
        //     }
        // },

        //Método para gerar a tabela
        //Feito um foreach para pegar a descrição da placa
        async gerarTabelaAjustarHoraVeiculos() {
            this.loading = true;
            var carro = [];
            this.optSelectVeiculosModalAjustes.forEach((element) => {
                if (this.veiculoSelecionadoModalAjustes == element.value) {
                    carro = element.description;
                }
            });
            this.veiculoAjustesVeiculo = carro?.split("|") ?? " ";
            var datas = this.dataSelecionadaModalAjustes.split(" - ");
            var dataInicio = datas[0].split("/").reverse().join("-") + " 00:00:00";
            var dataFim = datas[1].split("/").reverse().join("-") + " 23:59:59";
            var body = {
                basecalculo: this.colabSelecionadoModalAjustes.basecalculo,
                carro: this.veiculoAjustesVeiculo[0],
                colab: this.colabSelecionadoModalAjustes.value,
                dtinicio: dataInicio,
                dtfim: dataFim,
                clid: this.empresaSelecionadasModalAjustes[0],
            };
            try {
                var dados = await new HttpRequest().Post(`${this.url}veiculos/registros`, body);
                if (dados.data.length > 0) {
                    this.tabelaAjustesVeiculos = dados.data;

                    this.temAlgumFechamento = this.tabelaAjustesVeiculos.some(registro => 
                        registro.temFechamento || registro.temFechamentoAjudante || registro.temFechamentoMotorista
                    );
                } else {
                    this.statusBarAjustesVeiculos = "error";
                }
            } catch (error) {
                conectionError();
            } finally {
                // this.validarDadosTabela()
                this.loading = false;
            }
        },

        /**
         * @description Método para transformar a data para o padrão que usamos
         * @param  {data} date   - data no formato iso
         * @return {data}    - data e hora no nosso padrão
         * @author Vitor Hugo 🐨
         */
        transformaDataModal(date) {
            return DateTime.fromFormat(date, "yyyy-LL-dd HH:mm:ss").toFormat("dd/LL/yyyy HH:mm:ss");
        },

        //Método para associar e desassociar condutor/ajudante ao veículo
        assocDesasAjustesVeiculo(registro, quem) {
            this.objSend = { registro: registro ?? {}, quem };
            this.$refs.modalJustificativaAjusteAV.preparaModal(null, this.optJustificativas);
        },

        async sendAjusteAssociacaoVeiculo() {
            this.loading = true;
            var body = new Map([
                ["selcolab", this.colabSelecionadoModalAjustes.value],
                ["colab", ""],
                ["perfilAcao", "M"],
                ["perfil", this.colabSelecionadoModalAjustes.perfil],
                ["basecalculo", this.colabSelecionadoModalAjustes.basecalculo],
                ["acao", "U"],
                ["carro", this.veiculoAjustesVeiculo[0]],
                ["dtinicio", this.objSend.registro.dataIni ?? ""],
                ["dtfim", this.objSend.registro.dataFim ?? ""],
                ["justificativa", this.justificativa],
            ]);
            switch (this.objSend.quem) {
                case "MA": // associar o condutor do veículo
                    body.set("colab", this.objSend.registro.motorista ?? "");
                    break;
                case "MD": // desassociar o condutor do veículo
                    body.set("acao", "D");
                    body.set("colab", this.colabSelecionadoModalAjustes.value);
                    break;
                case "AA": // associar o ajudante do veículo
                    body.set("perfilAcao", "A");
                    body.set("colab", this.objSend.registro.ajudante ?? "");
                    break;
                case "AD": // desassociar o ajudante do veículo
                    body.set("perfilAcao", "A");
                    body.set("acao", "D");
                    body.set("colab", this.colabSelecionadoModalAjustes.value);
                    break;
            }
            if (body.acao == "D" && body.selcolab == body.colab) {
                body.set("selcolab", "");
            }
            if (!this.objSend.registro.dataIni && !this.objSend.registro.dataFim) {
                var primeiroSemFechamento = this.tabelaAjustesVeiculos.find(e => !e.temFechamento).dataIni
                body.set("dtinicio", primeiroSemFechamento);
                var dtfim =
                    this.tabelaAjustesVeiculos[this.tabelaAjustesVeiculos.length - 1]["dataFim"];
                body.set("dtfim", dtfim);
            }
            var request = await new HttpRequest().Post(
                `${this.url}associarDesassociarAjustesVeiculo`,
                Object.fromEntries(body)
            );
            if (request.status) {
                if (request.code == 200) {
                    this.gerarTabelaAjustarHoraVeiculos();
                } else {
                    var msg = request.data.mensagem;
                    this.toastShow("Não foi possível concluir a operação!", "danger", msg);
                    this.loading = false;
                }
            } else {
                conectionError();
                this.loading = false;
            }
        },

        //Método para fechar o modal Ajustes
        cancelButtonModalAjustes() {
            this.$bvModal.hide("modalAjustes");
            this.$bvModal.hide(this.idModal);
        },

        /**
         * @description Método para mostrar o toast na tela
         * @param {string} msg   - mensagem que vai aparecer no corpo do toast
         * @param {string} type  - qual o tipo do toast
         * @param {string} title - título para inserir no cabeçalho
         * @author Vitor Hugo 🐨
         */
        toastShow(msg, type, title = "") {
            this.$bvToast.toast(`${msg}`, {
                autoHideDelay: 2500,
                variant: type,
                title: title,
            });
        },

        /**
         * Verifica se precisa mostrar o botão de associar todos os registros.
         * @param {object} ignicao utilizados: {'motoristanome', 'ajudantenome'}
         * @param {bool} tipo indica se é ajudante ou motorista
         * @author Rafael
         */
        mostrarBotaoAssociar(ignicao, tipo) {
            return (
                this.colabSelecionadoModalAjustes &&
                this.colabSelecionadoModalAjustes.description != ignicao.motoristanome &&
                this.colabSelecionadoModalAjustes.description != ignicao.ajudantenome &&
                tipo
            );
        },

        /**
         * Verifica se precisa mostrar o botão de associar todos os registros.
         * @param {bool} tipo indica se é ajudante ou motorista
         * @author Rafael
         */
        mostrarBotaoAssociarTudo(tipo) {
            if (!this.colabSelecionadoModalAjustes) return false;
            var desc = this.colabSelecionadoModalAjustes.description;
            return (
                this.tabelaAjustesVeiculos.filter(
                    (e) =>
                        ((e.motoristanome != desc && this.isMotorista) ||
                            (e.ajudantenome != desc && this.isAjudante)) &&
                        tipo && (this.mostrarBotaoAssociar(e, this.isMotorista) || 
                        this.mostrarBotaoAssociar(e, this.isAjudante)) && !e.temFechamento
                ).length > 0
            );
        },

        /**
         * Verifica se precisa mostrar o botão de desassociar todos os registros.
         * @param {string} tipo "A"|"M"
         * @author Rafael
         */
        mostrarBotaoDesssociarTudo(tipo) {
            if (!this.colabSelecionadoModalAjustes) return false;
            return (
                this.tabelaAjustesVeiculos.filter(
                    (e) => (tipo == "M" && e.motoristanome) || (tipo == "A" && e.ajudantenome)
                    && !e.temFechamento
                ).length > 0
            );
        },

        mostrarBtnFacilitador(coluna) {
            if (coluna == 'M') {
                return !this.tabelaAjustesVeiculos
                    .some(e => e.temFechamento || e.temFechamentoMotorista)
            }
            if (coluna == 'A') {
                return !this.tabelaAjustesVeiculos
                    .some(e => e.temFechamento || e.temFechamentoAjudante)
            }
        },

        /**
         * Reseta as variáveis com os valores iniciais.
         * @author Rafael
         */
        resetModal() {
            // this.possuiFechamento = []
            this.tabelaAjustesVeiculos = [];
            this.temAlgumFechamento = false;
            this.optSelectColaboradoresModalAjustes = [];
            this.optSelectVeiculosModalAjustes = [];
            this.empresaSelecionadasModalAjustes = [];
            this.colabSelecionadoModalAjustes = null;
            this.veiculoSelecionadoModalAjustes = [];
            this.statusBarAjustesVeiculos = "info";
            this.loading = false;
            this.carregando = false;
        },

        modalClose() {
            this.resetModal();
        },

        changeJustificativa(value) {
            this.justificativa = value && value.length ? value[0] : null;
            this.sendAjusteAssociacaoVeiculo();
        },

        // validarDadosTabela() {
        //     this.tabelaAjustesVeiculos.forEach(e => {
        //         var data = e.dataIni.split(" ")[0]
        //         var fecha = validaUltimoFechamentoPorDia(this.ultimoFechamento, data)
        //         e.temFechamento = fecha ? fecha[0] : fecha
        //     })
        // } 

        defineMensagemFechamento(ignicao, tipo) {
            if (tipo == 'M') {
                if(ignicao.temFechamento)
                    return 'O colaborador selecionado possui fechamento nesta data!'
                if(ignicao.temFechamentoMotorista)
                    return 'Este colaborador possui fechamento nesta data!' +
                        '\nData final do fechamento: ' + ignicao.temFechamentoMotorista
            }
            if (tipo == 'A') {
                if(ignicao.temFechamento)
                    return 'O colaborador selecionado possui fechamento nesta data!'
                if(ignicao.temFechamentoAjudante)
                    return 'Este colaborador possui fechamento nesta data!' +
                        '\nData final do fechamento: ' + ignicao.temFechamentoAjudante
            }
        }
    },

    computed: {
        isMotorista() {
            return (
                this.colabSelecionadoModalAjustes != null &&
                (this.colabSelecionadoModalAjustes.perfil == "MA" ||
                    this.colabSelecionadoModalAjustes.perfil == "M")
            );
        },

        isAjudante() {
            return (
                this.colabSelecionadoModalAjustes != null &&
                (this.colabSelecionadoModalAjustes.perfil == "MA" ||
                    this.colabSelecionadoModalAjustes.perfil == "A")
            );
        },
    },
});
</script>

<style lang="scss">
// Modal carro
#modalAjusteVeiculos {
    .divButtonModalAjustes {
        padding-right: 0px !important;
        width: 100% !important;
        align-content: flex-end;
    }
    .fechamento {
        text-align: center;
        color: red;
    }
    .fechamento__grude {
        background-color: #f5f5f5;
        position: sticky;
        top: 2.7em;
        z-index: 1 !important;
    }
    //Modal Ajustes Hora Veiculos
    .buttonGerarModalCarro {
        height: 40px !important;
        width: 100%;
    }

    //Relatorio Modal Ajustes Horas Condutor
    .trRelatorioAjustarHoras {
        background: #fff !important;
        td {
            vertical-align: middle !important;
            padding-left: 5px !important;
            border: 1px solid #f0f2f4;
        }
    }

    //Relatorio Modal Ajustes Hora Veiculos
    .tdMotoristaModalCarro {
        vertical-align: middle !important;
        background: #fff !important;
        border: 1px solid #e4e4e4;
    }
    .motoristaModalCarro {
        padding-top: 6px !important;
        vertical-align: middle !important;
    }
    .motoristaModalCarroTh {
        align-self: self-end;
    }
    .associarColaboradorModalCarroCarregando {
        float: right;
        margin-top: 10px !important;
        margin-bottom: 5px !important;
        width: 20px !important;
        height: 20px !important;
    }
    .desassociarColaboradorModalCarro {
        float: right;
        text-align: center;
        padding-left: 2px;
        padding-top: 1px;
        margin-bottom: 2px !important;
        border-radius: 3px;
        border: 1px solid #d36c6c !important;
        width: 26px !important;
        height: 26px !important;
        box-shadow: 0.5px 0.5px 5px #c27373 !important;
        svg {
            width: 20px !important;
            height: 20px !important;
        }
    }
    .associarColaboradorModalCarro {
        float: right;
        padding-right: 2px;
        padding-left: 2px;
        padding-top: 1px;
        margin-bottom: 2px !important;
        border-radius: 3px;
        border: 1px solid #7fafda !important;
        width: 26px !important;
        height: 26px !important;
        box-shadow: 0.5px 0.5px 5px #c27373 !important;
        svg {
            width: 20px !important;
            height: 20px !important;
        }
    }
    .iconeAlertaBloqueio{
        float: right;
        width: 24px !important;
        height: 24px !important;
        padding-left: 2px;
        padding-top: 1px;
        svg {
            width: 16px !important;
            height: 16px !important;
        }
    }
    .legendaAvisoFechamento{
        color:orange;
        align-self:self-end;
        text-align: right;
    }
}
</style>
