<!-- 
    Componente Modal Detalhes da operação, utilizado no painel de controle cargo
-->
<template>
    <modalEagle  
        @modalOpen="modalOpen"
        @modalClose="modalClose"
        id ="modalDetalhesOperacao-"
        :title="'Detalhes da operação: '+rota+' | '+
            placa+' | '+prefixo"
        size  ='xl'>
        <template #modal-header>
            <div class="pt-2"> 
                    Detalhes da operação: {{+rota+' | '+
                    placa+' | '+prefixo}}
            </div>
            <div>
                <template v-if="getCadastrar()">
                    <simpleButton 
                        text="Criar rota"
                        type="blue"
                        width="100%"
                        :icon="mdiPlus"
                        event="click"
                        @click="criarRota">
                    </simpleButton>
                </template>
            </div>
        </template>
        <template slot="modalBody">
            <b-overlay :show="detalhesOperacao.loading">
                <div id="modalLarge">
                    <!-- Informações gerais -->
                    <div class="col-sm-12">
                        <div :title="'Última posição: '+veiculoDetalhesoperacao.endereco ?
                                veiculoDetalhesoperacao.endereco : 'Sem informação'">
                            Última posição: 
                            <span @click="mostrarMapa()"
                                v-if="veiculoDetalhesoperacao.endereco != null"
                                class="ultima-posicao-modal">
                                {{veiculoDetalhesoperacao.endereco}}</span>
                            <span v-else> Sem informação</span>
                        </div>
                        <div v-show="!mapa.show" :title="'Finalização prevista: '+dadosDetalhesOperacao.previsto ?
                                dadosDetalhesOperacao.previsto : 'Sem informação'">
                            Finalização prevista: {{dadosDetalhesOperacao.previsto ?
                                dadosDetalhesOperacao.previsto : 'Sem informação'}}
                        </div>
                        <div v-show="!mapa.show" :title="'Início jornada motorista: '+dadosDetalhesOperacao.jornada ?
                                dadosDetalhesOperacao.jornada : 'Sem informação'">
                            Início jornada motorista: {{dadosDetalhesOperacao.jornada ?
                                dadosDetalhesOperacao.jornada : 'Sem informação'}}
                        </div>
                    </div>
                    <div v-show="!mapa.show" class="col-sm-12 row mt-3">
                        <!-- Rotas vinculadas -->
                        <div class="col-sm-7 nopadding">
                            <!-- Descrição e campo de buscas -->
                            <div class="areaUploadFiles col-sm-12 row nopadding">
                                <div class="div-title col-sm-3">Rotas vinculadas</div>
                                <div class="col-sm-1 nopadding">
                                    <div class="nopadding">
                                        <b-form-checkbox
                                            class="pt-3"
                                            id="checkbox-1"
                                            v-model="detalhesOperacao.filtroTabelaRotas"
                                            name="checkbox-1">
                                            Filtrar
                                        </b-form-checkbox>
                                    </div>
                                </div>
                                <div id="dataRotaModal" class="col-sm-8 row nopadding">
                                    <div class="col-sm-8 row data-intervalo-modal nopadding">
                                        <inputRangeNoLimits 
                                            name="buscaData"
                                            id="buscaData"
                                            :desabilitado="!detalhesOperacao.filtroTabelaRotas"
                                            titulo=""
                                            @changeInput="changeFiltroDataRotasVinculadas"/>
                                    </div>
                                    <div class="col-sm-4 data-intervalo-modal nopadding">
                                        <inputSimple 
                                            name="buscaNota"
                                            placeholder="N° nota"
                                            @changeInput="changeFiltroNumeroNotaRotasVinculadas"/>
                                    </div>
                                </div>
                            </div>
                            <!-- Tabela  de rotas vinculadas -->
                            <div class="col-sm-12 areaListRotas">
                                <div class="areaRotasVeiculo">
                                    <!-- Cabeçalho -->
                                    <div class="item row">
                                        <div class="headListRotas col-sm"></div>
                                        <div class="headListRotas col-sm">Código</div>
                                        <div class="headListRotas col-sm">Data</div>
                                        <div class="headListRotas col-sm">Hora</div>
                                        <div class="headListRotas col-sm">Tempo</div>
                                        <div class="headListRotas col-sm">Origem</div>
                                        <div class="headListRotas col-sm">Destino</div>
                                        <div class="headListRotas col-sm">NF</div>
                                        <div class="headListRotas col-sm">CTE</div>
                                        <div class="headListRotas col-sm"></div>
                                    </div>
                                    <!-- Corpo -->
                                    <div class="item filtro row"
                                        v-show="dadosModalDetalhes.length > 0"
                                        v-for="(dado, dr) in dadosModalDetalhes"
                                        :key="dr"
                                        :class="dado.status">
                                        <div class="iconAlert">
                                            <div v-show="dado.alertaAtrasoEntrega!='' || 
                                                    dado.alertaExcedenteJornada!=''"
                                                :title="dado.alertaAtrasoEntrega != '' ?
                                                        dado.alertaAtrasoEntrega :
                                                        dado.alertaExcedenteJornada"
                                                class="carro-icon div-alert">
                                                <baseIcon 
                                                    :icon ='mdiAlert'
                                                    size  ="20"/>
                                            </div>
                                        </div>
                                        <div class="borderb consultar-historico"
                                            :class="dado.status"
                                            v-on:click="buscaHistoricosModalDO(
                                                dado.codigo, dado.status, dado.placa)">
                                            {{dado.codigo}}
                                        </div>
                                        <div class="borderb" 
                                            :class="dado.status"
                                            :title="dado.data">{{dado.data}}
                                        </div>
                                        <div class="borderb" 
                                            :class="dado.status"
                                            :title="dado.programacao">{{dado.programacao}}
                                        </div>
                                        <div class="borderb" 
                                            :class="dado.status"
                                            :title="dado.tempo">{{dado.tempo}}
                                        </div>
                                        <div class="borderb" 
                                            :class="dado.status"
                                            :title="dado.origem" 
                                            style="min-width:50px!important">
                                            {{dado.origem}}
                                        </div>
                                        <div class="borderb" 
                                            :class="dado.status"
                                            :title="dado.destino" 
                                            style="min-width:50px!important">
                                            {{dado.destino}}
                                        </div>
                                        <div class="borderb" 
                                            :class="dado.status"
                                            :title="dado.nfes">{{dado.nfes}}
                                        </div>
                                        <div class="borderb"
                                            :class="dado.status" 
                                            :title="dado.cfes">{{dado.cfes}}
                                        </div>
                                        <div class="borderb btn-power-modal"
                                            :class="dado.status"
                                            event="click"
                                            v-if="getEditar()"
                                            :id="'popover-reactive-1'+dado.codigo"
                                            v-on:click="ativarPopover(dr)">
                                            <baseIcon 
                                                class="iconCancelarRota cursor-pointer"
                                                :icon ='mdiPower'
                                                size  ="20"/>
                                            <b-popover
                                                :target="'popover-reactive-1'+dado.codigo"
                                                triggers="click"
                                                :show.sync="popoverShow[dr]"
                                                :disabled.sync="disabled"
                                                placement="left"
                                                container="my-container"
                                                ref="popover">
                                                <div>
                                                    <span>Ao confirmar essa operação a 
                                                    rota {{dado.codigo}} será cancelada!
                                                    Tem certeza que deseja fazer isso?
                                                    </span>
                                                </div>
                                                <div>
                                                    <simpleButton 
                                                        text="Cancel"
                                                        event="click"
                                                        @click="cancelar(dr)"
                                                        type="red"
                                                        :icon="mdiCloseThick">
                                                    </simpleButton>
                                                    <simpleButton 
                                                        text="Confirmar"
                                                        event="click"
                                                        @click="cancelarRotaVinculada(
                                                        dado.codigo, dr)" 
                                                        type="blue"
                                                        :icon="mdiCheckBold">
                                                    </simpleButton>
                                                </div>
                                            </b-popover>
                                        </div>
                                    </div>
                                    <div v-show="dadosModalDetalhes.length == 0">
                                        <statusInformation
                                            typeBar="div" 
                                            :statusBar="statusBarRotas">
                                        </statusInformation>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Tabela Documentos vinculados -->
                        <div class="col-sm-5 mostrarMapa nopadding pl-3">
                            <div class="areaFilesTitle">
                                <div class="div-title col-sm-6 pt-2" 
                                    title="Documentos vinculados">Docs. vinculados 
                                    {{detalhesOperacao.documentos.rota ? 
                                        '('+detalhesOperacao.documentos.rota+')' : ''}}
                                </div>
                                <template v-if="getCadastrar()">
                                    <div class="btn-files-title anexar col-sm-3" 
                                        style="text-align:left">
                                        <simpleButton 
                                            text="Anexar"
                                            type="blue"
                                            width="100%"
                                            :icon="mdiUploadOutline"
                                            :disabled='!detalhesOperacao.anexar'
                                            event="click"
                                            @click="onPickFile">
                                        </simpleButton>
                                        <input
                                            type='file'
                                            multiple
                                            style='display:none'
                                            ref='fileInput'
                                            @change='onFilePicked'/>
                                    </div>
                                    <div class="btn-files-title col-sm-3">
                                        <simpleButton 
                                            text="Enviar"
                                            type="blue"
                                            width="100%"
                                            :disabled='imageUrl.length == 0'
                                            :icon="mdiCheckBold"
                                            event="click"
                                            @click="enviarDocumentosVinculados()">
                                        </simpleButton>
                                    </div>
                                </template>
                            </div> 
                            <!-- Documentos Vinculados -->
                            <div v-show="adicionaImage == false"
                                class="areaListFiles">
                                <b-overlay :show="detalhesOperacao.documentos.loading">
                                    <div class="col-sm-12 row borderb nopadding"
                                        v-show="
                                            detalhesOperacao.documentos.dados.length > 0">
                                        <div class="col-sm-5 headListDocs">Nome</div>
                                        <div class="col-sm-2 nopadding headListDocs">
                                            Data/hora
                                        </div>
                                        <div class="col-sm-3 headListDocs">Origem</div>
                                        <div class="col-sm-2 headListDocs"></div>
                                    </div>
                                    <div v-for="(dadosDocumentos, dd) in 
                                        detalhesOperacao.documentos.dados"
                                        :key="dd"
                                        class="col-sm-12 row borderb nopadding">
                                        <div class="col-sm-5 renameFile" 
                                            :title="dadosDocumentos.name">
                                            <div v-show="dadosDocumentos.dbclick == false"
                                                v-on:dblclick="dadosDocumentos.dbclick = 
                                                    !dadosDocumentos.dbclick"
                                                class="nameFile">
                                                {{dadosDocumentos.name}}
                                            </div>
                                            <div v-show="dadosDocumentos.dbclick"
                                                class="inputNomeDocumentosVinculados">
                                                <inputSimple
                                                    inputClass="altura"
                                                    :value="dadosDocumentos.name"
                                                    name="dadosDocumentosNome"
                                                    id="changeDadosDocumentosNome"
                                                    @blur="changeInputNomeModalOperacoes(
                                                        dadosDocumentos.code)"
                                                    @changeInput="
                                                        changeNomeModalOperacoes">
                                                </inputSimple>
                                            </div>
                                        </div>
                                        <div class="col-sm-2 nopadding">
                                            {{dadosDocumentos.data}}
                                        </div>
                                        <div class="col-sm-3">
                                            {{dadosDocumentos.origem}}
                                        </div>
                                        <div class="col-sm-1 nopadding"
                                            v-on:click="downloadFileModalDetalhesOperacao(
                                                    dadosDocumentos)">
                                            <div v-show="dadosDocumentos.download==false">
                                                <baseIcon 
                                                    class="downloadFilePC nopadding"
                                                    :icon ='mdiDownloadOutline'
                                                    size  ="20"/>
                                            </div>
                                            <div v-show="dadosDocumentos.download">
                                                <baseIcon 
                                                    style="color:mediumseagreen;"
                                                    class="downloadFilePC nopadding"
                                                    :icon ='mdiCheckBold'
                                                    size  ="20"/>
                                            </div>
                                        </div>
                                        <!-- dadosDocumentos.code -->
                                        <div class="col-sm-1 nopadding text-left p-0">
                                            <template v-if="getExcluir()">
                                                <b-button 
                                                    class="text-left p-0"
                                                    style="text-aling:left; 
                                                        background-color:transparent; 
                                                        border:none;"
                                                    :id="'popover-button-'+
                                                        dadosDocumentos.name+dd" 
                                                    href="#" 
                                                    tabindex="0">
                                                    <baseIcon 
                                                        class="deleteFilePC nopadding"
                                                        :icon ='mdiTrashCanOutline'
                                                        size  ="20"/>
                                                </b-button>
                                                <b-popover 
                                                    :ref="'popover-button-'+
                                                        dadosDocumentos.name+dd"
                                                    :target="'popover-button-'+
                                                        dadosDocumentos.name+dd" 
                                                    triggers="focus">
                                                    <div class="col-sm-12 row p-0">
                                                        <div class="col-sm-12 p-0">
                                                            Ao confirmar essa operação o documento será excluído 
                                                            permanentemente do servidor! Tem certeza que deseja fazer isso?
                                                        </div>
                                                        <div class="col-sm-12 row p-0">
                                                            <div class="col-sm-6">
                                                                <simpleButton
                                                                    @click="fecharPopover('popover-button-'+dadosDocumentos.name+dd)"
                                                                    text="Fechar"
                                                                    event="click"
                                                                    type="red"
                                                                    :icon="mdiCloseThick">
                                                                </simpleButton>
                                                            </div>
                                                            <div class="col-sm-6 p-0">
                                                                <simpleButton
                                                                    @click="deletaImg(dadosDocumentos.code, 'popover-button-'+dadosDocumentos.name+dd)"
                                                                    text="Confirmar"
                                                                    event="click"
                                                                    type="blue"
                                                                    :icon="mdiCheckBold">
                                                                </simpleButton>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-popover>
                                            </template>
                                        </div>
                                    </div>
                                </b-overlay>
                            </div>
                            <!-- Documentos para Vincular -->
                            <div v-if="adicionaImage == true"
                                class="areaListFiles">
                                <div
                                    v-for="(dadosDocumentosVincular, dv) in 
                                    imageValue"
                                    :key="dv"
                                    class="col-sm-12 row borderb nopadding">
                                    <div class="col-sm-11 renameFile" 
                                        :title="dadosDocumentosVincular.name">
                                        <div v-show="dadosDocumentosVincular.dbclick == false"
                                            v-on:dblclick="dadosDocumentosVincular.dbclick = 
                                                !dadosDocumentosVincular.dbclick"
                                            class="nameFile">
                                            {{dadosDocumentosVincular.name}}
                                        </div>
                                        <div v-show="dadosDocumentosVincular.dbclick"
                                            class="inputNomeDocumentosVinculados">
                                            <inputSimple
                                                inputClass="altura"
                                                name="dadosDocumentosNome"
                                                :value='dadosDocumentosVincular.name'
                                                id="changeDadosDocumentosNome"
                                                @focus="clickInputNome(
                                                    dadosDocumentosVincular.name)"
                                                @blur="changeInputNomeModalVincular(
                                                    nomeSelecionadoModalOperacoes, dv)"
                                                @changeInput="v => 
                                                    changeNomeModalOperacoes(v)">
                                            </inputSimple>
                                        </div>
                                    </div>
                                    <div class="col-sm-1 nopadding"
                                        v-on:click="cancelarVinculacaoDocumentos(dv)">
                                        <baseIcon 
                                            class="downloadFilePC nopadding"
                                            :icon ='mdiCloseThick'
                                            size  ="20"/>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="areaShowFiles" style="display:none"></div> -->
                        </div>
                        <!-- Histórico de operação -->
                        <div v-show="alterarstatusFaturado == false"
                            class="col-sm-12 div-timeline mostrarMapa row nopadding pt-3">
                            <div class="col-sm-9 timeline-title">
                                <div class="title-info">
                                    Histórico da operação 
                                    {{detalhesOperacao.documentos.rota ? 
                                        '('+detalhesOperacao.documentos.rota+')' : ''}}
                                </div>
                                <div 
                                    title="Para vizualizar o histórico, selecione um 
                                        código na listagem a cima"
                                    class="info">
                                    <baseIcon 
                                        class="iconCancelarRota cursor-pointer"
                                        :icon ='mdiInformation'
                                        size  ="20"/>
                                </div>
                            </div>
                            <!-- Button dropUp -->
                            <div class="col-sm-3 timeline-title nopadding">
                                <template v-if="getEditar()">
                                    <buttonDropup 
                                        :disabled='
                                            $v.detalhesOperacao.historico.dados.$invalid'
                                        :dropItem="alterarStatusRota"
                                        :text="detalhesOperacao.documentos.rota ? 
                                            'Alterar status rota ('+
                                            detalhesOperacao.documentos.rota+')' : 
                                            'Alterar status rota'" 
                                        event="click" 
                                        widthDropUp="270px"
                                        @dropItemClick="changeItemStatusRota">
                                    </buttonDropup>
                                </template>
                            </div>
                            <div class="col-sm-12 div-timeline divModal mostrarMapa">
                                <!-- Histórico de operação -->
                                <div class="col-sm-12 nopadding info-timeline">
                                    <div v-show="detalhesOperacao.historico.dados.length > 0"
                                        class="div-tooltip">
                                        <div v-for="(historico, h) in 
                                            detalhesOperacao.historico.dados"
                                            :key="h"
                                            class="tooltip-custom row"
                                            :class="historico.status">
                                            <div>
                                                {{historico.descricao}}
                                            </div>
                                            <div>
                                                {{historico.time}}
                                            </div>
                                        </div>
                                    </div>
                                    <div v-show="detalhesOperacao.historico.dados.length == 0">
                                        <statusInformation
                                            typeBar="div" 
                                            :statusBar="statusBar">
                                        </statusInformation>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Quando alteração de status for faturado -->
                        <div v-show="alterarstatusFaturado"
                            class="div-tooltip row mt-3">
                            <div class="col-sm-12 div-alterar-status-faturado mostrarMapa row" style="">
                                <div class="col-sm-12" style="margin-bottom: 5px;">
                                    Preencha as informações e confirme a alteração da rota 
                                        {{detalhesOperacao.documentos.rota ? 
                                        '('+detalhesOperacao.documentos.rota+')' : ''}}
                                    para "Faturado":
                                </div>
                                <div class="col-sm-6">
                                    <inputSimple
                                        label="CTES"
                                        name="CTES"
                                        id="CTES"
                                        @changeInput="CtesModalDetalhesOperacao">
                                    </inputSimple>
                                </div>
                                <div class="col-sm-6">
                                    <inputSimple
                                        label="NFES"
                                        name="NFES"
                                        id="NFES"
                                        @changeInput="nfesModalDetalhesOperacao">
                                    </inputSimple>
                                </div>
                                <div class="col-sm-12" style="text-align: end;top: 10px;">
                                    <loadingButton 
                                        text="Salvar"
                                        :icon="mdiCheckBold"
                                        event="click"
                                        :isLoading="loadingSaveFaturado"
                                        type="blue"
                                        @click="salvarStatusFaturadoModalDetalhesOperacao">
                                    </loadingButton>
                                    <simpleButton 
                                        text="Cancelar"
                                        type="red"
                                        :icon="mdiCloseThick"
                                        event="click"
                                        @click="cancelarStatusFaturadoModalDetalhesOperacao">
                                    </simpleButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="divMapaDetalhesOperacao" 
                        v-show="mapa.show" class="col-sm-12 row">
                        <mapaSimples height="600" style="width:100%" ref="refMapaRota">
                            <!-- origem -->
                            <div class="col-sm-3" id="divInfoMapa">
                                <div class="col-sm-12 row divInfoMapaDetalhes">
                                        Veículo a {{mapa.distancia}} Km do destino final <br>
                                        Tempo estimado de chegada: {{mapa.tempo}} <br>
                                </div>
                            </div>
                            <markerPoint
                                v-if="mapa.origem && mapa.origem.lat && mapa.origem.lon"
                                icon="/img/inicio.png"
                                :latitude="Number(mapa.origem.lat)"
                                :longitude="Number(mapa.origem.lon)">
                                <popupMarker>
                                    <div class="col-sm-12 row nopadding div-popup"
                                        style="width: 300px">
                                        <div class="col-sm-12 nopadding mb-1">
                                            Descrição: {{mapa.origem.descricao}}
                                        </div>
                                        <div class="col-sm-12 nopadding mb-2">
                                            Lat/Lon: {{mapa.origem.lat}}, {{mapa.origem.lon}}
                                        </div>
                                    </div>
                                </popupMarker>
                            </markerPoint>
                            <!-- destinos -->
                            <template v-if="mapa.destinos.length">
                                <markerPoint
                                    v-for="(value, i) in mapa.destinos"
                                    :key="i+'_marker_destino'"
                                    :typeIcon="'divIcon'"
                                    :icon="getHtmlIcon(value)"
                                    :iconSize="[12,12]"
                                    :latitude="value.lat"
                                    :longitude="value.lon"
                                    @clickMarker="clickMarkerDetalhesOperacao">
                                    <popupMarker>
                                        <div class="col-sm-12 row nopadding div-popup"
                                            style="width: 300px">
                                            <div class="col-sm-12 nopadding mb-1">
                                                Descrição: {{value.descricao}}
                                            </div>
                                            <div v-if="value.condutor" 
                                                class="col-sm-12 nopadding mb-1">
                                                Condutor: {{value.condutor}}
                                            </div>
                                            <div v-if="value.datahora" 
                                                class="col-sm-12 nopadding mb-1">
                                                Data/hora: {{value.datahora}}
                                            </div>
                                            <div class="col-sm-12 nopadding mb-2">
                                                Lat/Lon: {{value.lat}}, {{value.lon}}
                                            </div>
                                            <div class="col-sm-12 nopadding"
                                                style="text-align:center"
                                                v-show="!markerDocs.length">
                                                <loadingButton 
                                                    :isLoading="buscandoImg" 
                                                    :disabled='buscandoImg'
                                                    :icon="mdiInformation"
                                                    @click="buscarImagens(value.codigo)"
                                                    text="Ver documentos"
                                                    width="100%"
                                                    event="click"
                                                    type="blue"
                                                />
                                            </div>
                                            <div v-show="!markerDocs.length"
                                                style="text-align: center;">
                                                {{ msgBuscaDocumento }}
                                            </div>
                                            <div
                                                v-show="markerDocs.length"
                                                style="overflow: auto; display: inline-flex;">
                                                <img 
                                                    v-for="(img, i) in markerDocs" :key="i+'_img'"
                                                    :src="img.data"
                                                    :alt="img.name"
                                                    style="
                                                        border: 2px solid grey; 
                                                        border-radius:5px; 
                                                        height: 250px;
                                                        align-self: self-end;
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </popupMarker>
                                </markerPoint>
                            </template>
                            <!-- veiculo -->
                            <markerPoint
                                v-if="mapa.veiculo.length"
                                icon="/img/markerPainelControle.png"
                                :latitude="mapa.veiculo[0]"
                                :longitude="mapa.veiculo[1]"
                            />
                            <!-- polyline rota -->
                            <polylineAntPath 
                                v-if="mapa.polyline"
                                id="Polilinha"
                                ref="refPolilinha"
                                :latLng="mapa.polyline" 
                                :opacity="'0.5'"
                            />
                            <!-- polyline rastro -->
                            <polylineAntPath 
                                v-if="mapa.rastro.length"
                                id="PolilinhaRastro"
                                ref="refPolilinhaRastro"
                                color="#ff0000cc" 
                                hoverColor="#ff0000cc" 
                                :latLng="mapa.rastro" 
                                :opacity="'0.7'"
                            />
                        </mapaSimples>
                    </div>
                </div>
            </b-overlay>
        </template>
        <template>
            <div class="col-sm-12 row">
                <div class="col-sm-10 pl-0">
                    <template v-if="getEditar()">
                        <buttonDropup 
                            :dropItem="alterarStatusVeiculo"
                            text="Alterar status veículo" 
                            event="click" 
                            widthDropUp="250px"
                            @dropItemClick="changeItemStatusVeiculo">
                        </buttonDropup>
                    </template>
                </div>
                <div class="col-sm pr-0">
                    <simpleButton 
                        class="float-right"
                        :icon="mdiCloseThick" 
                        text="Cancelar" 
                        event="click" 
                        @click="fechaModalDetalhesOperacao"
                        type="red">
                    </simpleButton>
                </div>
            </div>
        </template>
    </modalEagle>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import axios from 'axios'
import { HttpRequest } from '../../../Services/auth/HttpRequest.Service'
import { required } from 'vuelidate/lib/validators'
import { mdiPower, mdiTrashCanOutline,
    mdiInformation, mdiDownloadOutline,
    mdiCheckBold, mdiCloseThick, mdiUploadOutline, 
    mdiPlus, mdiAlert } from '@mdi/js'
import { AuthService } from '@/Services/auth/Auth.Service'
import { conectionError } from '@/Services/Helpers/swellHeper'
var decodePoly = require('@mapbox/polyline')

String.prototype.toHHMMSS = function () {
	var sec_num = parseInt(this, 10);
	var hours   = Math.floor(sec_num / 3600);
	var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
	var seconds = sec_num - (hours * 3600) - (minutes * 60);
	if (hours   < 10) {hours   = '0'+hours;}
	if (minutes < 10) {minutes = '0'+minutes;}
	if (seconds < 10) {seconds = '0'+seconds;}
	return hours+':'+minutes+':'+seconds;
}

export default Vue.extend({
	name:'modalOperacaoPainelControle',
	components:{
		mapaSimples : require('@/components/Atom/Mapa/MapaSimples').default,
		markerPoint : require('@/components/Atom/Mapa/MarkerPoint').default,
		polylineAntPath : require('@/components/Atom/Mapa/PolylineAntPath').default,
		'modalEagle' : require('@/components/Atom/Modal/ModalEagle').default,
		'inputSimple' : require('@/components/Atom/Inputs/InputSimple').default,
		'simpleButton' : require('@/components/Atom/Buttons/SimpleButton').default,
		'buttonDropup' : require('@/components/Atom/Buttons/ButtonDropup').default,
		'inputRangeNoLimits' : require('@/components/Atom/Datas/InputRangeNoLimits').default,
		'baseIcon' : require('@/components/Atom/Icon/BaseIcon').default,
		'statusInformation' : require('@/components/Atom/StatusInformation/StatusInformation').default,
		'popupMarker'  : require('@/components/Atom/Mapa/PopupMarker').default,
        'loadingButton': require('@/components/Atom/Buttons/LoadingButton').default,
	},

	validations: {
		detalhesOperacao: { 
            historico: { 
                dados: { required }
            },
            documentos: { 
                dados: { required }
            },
        },
	},

    data(){
        return{
            mdiTrashCanOutline:mdiTrashCanOutline,
            mdiPower : mdiPower,
            mdiInformation : mdiInformation,
            mdiCheckBold : mdiCheckBold,
            mdiCloseThick : mdiCloseThick,
            mdiDownloadOutline : mdiDownloadOutline,
            mdiUploadOutline : mdiUploadOutline,
            mdiPlus : mdiPlus,
            mdiAlert : mdiAlert,
            alterarStatusRota: [
                {text: 'Agendado Carregamento', value: '7', color: 'purpleLight', widthItem: '268px'},
                {text: 'Agendado venda',        value: '2', color: 'purple', widthItem: '268px'},
                {text: 'Carregado',             value: '6', color: 'blue', widthItem: '268px'},
                {text: 'Faturado',              value: '3', color: 'orange', widthItem: '268px'},
                {text: 'Iniciado',              value: '4', color: 'yellow', widthItem: '268px'},
                {text: 'Finalizado',            value: '5', color: 'green', widthItem: '268px'},
            ],
            alterarStatusVeiculo:[
                {text: 'Disponível', value: '1', color: 'grey', widthItem: '248px'},
                {text: 'Manutenção', value: '8', color: 'red', widthItem: '248px'}
            ],
            link: '/eagle/cargo/nao/menu/painel/controle/',
            statusBar : 'info',
            statusBarRotas : 'info',
            placa : '',
            detalhesOperacao: {
                documentos: {
                    rota: '',
                    status: '',
                    placa: '',
                    dados: [],
                    loading: false,
                },
                historico: {
                    dados: [],
                },
                loading: false,
                anexar: false,
                ctesSelecionado: '',
                nfesSelecionado: '',
                filtroTabelaRotas: false,
                dadosFilter: [],
            },

            currentDataset : {},
            prefixo : '',
            rota : '',
            dadosDetalhesOperacao : [],
            veiculoDetalhesoperacao : [],
            dataFiltroRotasVinculadasSelecionadas : '',
            alterarstatusFaturado : false,
            loadingSaveFaturado : false,

			popoverShow : [],
			disabled : false,

			nomeSelecionadoModalOperacoes : '',
			imageValue : [],
			adicionaImage : false,
			imageUrl : [],
			mensagemSucessoErroDocumentos : '',
            mapa: {
                show: false,
                origem: [],
                destinos: [],
                veiculo: [],
                polyline: [],
                rastro: [],
                tempo:0,
                distancia:0,
            },
			buscandoImg:false,
			markerDocs:[],
            msgBuscaDocumento: '',
		}
	},

	props:{
		id:{
			type: [String, Number],
			required: true,
			default: '',
		},
	},
    
    methods:{
        ...mapGetters(['getCadastrar', 'getEditar', 'getExcluir']),

        async deletaImg(value, reff){
            this.$refs[reff][0].doClose()
            this.detalhesOperacao.loading = true
            try {
                await new HttpRequest()
                    .Post(this.link+'excluir/documento', {'codigo':value})
                    .then((dados)=>{
                        if(dados.status && dados.data.arquivo){
                            this.buscaHistoricosModalDO(
                                this.detalhesOperacao.documentos.rota, 
                                this.detalhesOperacao.documentos.status, 
                                this.detalhesOperacao.documentos.placa,
                            )
                        }else{
                            conectionError()
                        }
                    })
                    .finally(() => {
                        this.detalhesOperacao.loading = false
                    })
            } catch (error){
                this.detalhesOperacao.loading = false
                conectionError()
            }
            
        },
        
        fecharPopover(reff){
            this.$refs[reff][0].doClose()
        },
        
		/**
		 * @listens click - botão 'ver documentos'
		 * @description procura por documentos do item da rota
		 */
		buscarImagens(codigo){
			this.buscandoImg = true
			var uri = `${this.link}get/documentos/${codigo}`
            this.limparDocumentosMapa()
            this.msgBuscaDocumento = 'Aguarde...'
			new HttpRequest().Get(uri)
                .then(({data, status}) => {
                    if(status) {
                        if(data.documentos && data.documentos.length) {
                            let docs = []
                            data.documentos.forEach(e => {
                                let linkSource = {
                                    data: `data:${e.datatype};base64,${e.file}`,
                                    name: e.name,
                                }
                                docs.push(linkSource)
                            })
                            this.markerDocs = docs
                        } else {
                            this.msgBuscaDocumento = 'Nenhum documento encontrado'
                        }
                    } else {
                        this.msgBuscaDocumento = 'Ocorreu um erro, tente novamente!'
                    }
                })
                .catch((error) => {
                    this.msgBuscaDocumento = 'Ocorreu um erro, tente novamente!'
                    conectionError()
                })
                .finally(() => {
                    this.buscandoImg = false
                })
		},

        /**
         * Faz a consulta e prepara os valores para exibir no mapa
         * Restaura as polilinha pra não ficar a antiga
         * Direciona ate o ponto em que o veiculo esta no mapa
         * @listens click - <span> - Última posição:
         * @author Lucas Eduardo
         */
		async mostrarMapa(){
			this.mapa.show = !this.mapa.show
			if(this.$refs.refMapaRota)
                this.$refs.refMapaRota.validateSize()
			if(this.$refs.refPolilinha)
                this.$refs.refPolilinha.setVisible(false)
			if(this.$refs.refPolilinhaRastro)
                this.$refs.refPolilinhaRastro.setVisible(false)
			if(this.mapa.show){
				this.detalhesOperacao.loading = true
				try{
                    let body = {'rota':this.rota}
                    this.mapa.tempo = 0
                    this.mapa.distancia = 0
					var dados = await new HttpRequest().Post(this.link+'info/mapa', body)
					if(dados.status && dados.data && typeof(dados.data) == 'object'){
                        let data = dados.data
                        if(data.origem && data.origem.lat && data.origem.lon) {
                            this.mapa.origem = data.origem
                        }
                        let destinos = []
                        if(data.destinos && data.destinos.length) {
                            destinos = data.destinos
                        }
                        this.mapa.destinos = destinos
                        if(this.mapa.destinos.length) {
                            let ordem = 1
                            this.mapa.destinos.forEach((e) => {
                                if(e.ordem) {
                                    e.ordem = ordem
                                    ordem++
                                }
                            })
                        }
                        if(data.polyline) {
                            let decoded = decodePoly.decode(data.polyline)
                            if(decoded) {
                                this.mapa.polyline = decoded
                            } else {
                                this.mapa.polyline = []
                            }
                        }
						if(data.rastro && typeof(data.rastro) == 'string'){
							this.mapa.rastro = decodePoly.decode(data.rastro)
						}else{
							this.mapa.rastro = data.rastro
						}
						if(data.faltante && data.faltante.code == 'Ok'){
                            let route = data.faltante
                            if(route.routes && route.routes[0]) {
                                let routes = route.routes[0]
                                if(routes.duration) {
                                    this.mapa.tempo = (routes.duration+'').toHHMMSS()
                                }
                                if(routes.distance) {
                                    let distancia = (routes.distance / 1000).toFixed(1)
                                    this.mapa.distancia = distancia
                                }
                            }
						}
					}
                    if(this.mapa.veiculo.length) {
                        let latLng = [this.mapa.veiculo[0], this.mapa.veiculo[1]]
                        this.$refs.refMapaRota.flyTo(latLng, 13)
                    }
				}catch (error){
					conectionError()
				}
				this.detalhesOperacao.loading = false
			}
		},

        limparDocumentosMapa() {
            this.msgBuscaDocumento = ''
            this.markerDocs = []
        },

		modalOpen(){
            this.limparDocumentosMapa()
			this.mapa.show = false
		},

        modalClose() {
			this.limpaDados()
            window.removeEventListener('paste', this.pasteFile)
        },

        clickMarkerDetalhesOperacao() {
            this.limparDocumentosMapa()
        },

		criarRota(){
			this.$bvModal.hide('modalDetalhesOperacao-')
			this.$emit('criarRota')
		},

        getHtmlIcon(value) {
			var estilo = ''
			if(value.status == 'F') {
				estilo += 'background-color: #59d062;'
			}
			return `
				<div>
					<span class='markerRotaOrdem' style='${estilo}'>${value.ordem}</span>
					<img class='markerRotaImg' src='img/markerPonto.png'>
				</div>`
		},

		/**
         * @description Essa função deve ser utilizada por uma referência para "ativar"
         * essa modal, os argumentos da função são os necessários para 
         * conseguir popular a modal com as infomações corretas
         * @param placa placa do veículo
         * @param prefixo prefixo do veículo
         * @param rota código da rota
         * @author Gui 🍺  
         */
		preparaModal(placa, rota, prefixo, status){
			this.limpaDados()
			this.placa   = placa
			this.prefixo = prefixo
			this.rota    = rota
			this.detalhesOperacao.documentos.status = status
			this.$bvModal.show('modalDetalhesOperacao-')
			this.buscaDetalhesOperacao()
			this.buscaHistoricosModalDO(rota, status, placa)
            if(document.getElementById('painelControle')) {
                window.addEventListener('paste', this.pasteFile)
            }
		},

		limpaDados(limparTudo = true){
            if(limparTudo) {
                this.placa   = ''
                this.prefixo = ''
                this.rota    = ''
                this.dataFiltroRotasVinculadasSelecionadas  = ''
            }
            this.limparMapa()
			this.detalhesOperacao = {
                documentos: {
                    rota: '',
                    status: '',
                    placa: '',
                    dados: [],
                    loading: false,
                },
                historico: {
                    dados: [],
                },
                loading: false,
                anexar: false,
                ctesSelecionado: '',
                nfesSelecionado: '',
                filtroTabelaRotas: false,
                dadosFilter: [],
            }
			this.alterarstatusFaturado = false
			this.imageValue = []
			this.statusBar  = 'info'
			this.statusBarRotas = 'info'
			this.dadosDetalhesOperacao = []
			this.imageUrl = []
            this.limparDocumentosMapa()
			for (let index = 0; index < this.popoverShow.length; index++) {
				this.cancelar(index)
			}
		},

        limparMapa(clearAll = true){
            if(clearAll) {
                this.mapa = {
                    show: false,
                    origem: [],
                    veiculo: [],
                    destinos: [],
                    polyline: [],
                    rastro: [],
                    tempo:0,
                    distancia:0,
                }
            } else {
                this.mapa.veiculo = []
            }
        },

		/**
         * Altera status da rota
         * @param String item (valor selecionado no dropUp), evento antigo (último evento da rota),
         * detalhesOperacao.documentos.rota (rota selecionada), ctes, nfes, status para saber se é faturado
         * Obs: se for selecionado no dropUp a opção faturado terá que abilitar a opção de inserir CTES e NFES
         * é isto que o if está fazendo
         * @return Mensagem de sucesso ou erro
         * @author Vitor Hugo 🐨
         */
		async changeItemStatusRota(item, status=false){
			var eventoAntigo = ''
            this.loadingSaveFaturado = true
			this.detalhesOperacao.historico.dados.forEach(element => {
				eventoAntigo = element.evento
			});
			if(item == 3 && status == false){
				this.alterarstatusFaturado = true
                this.loadingSaveFaturado = false
			} else {
                try {
                    this.detalhesOperacao.loading = true
                    let body = {
                        'evento' : item,
                        'eventoAntigo' : eventoAntigo,
                        'rotaSelecionada' : this.detalhesOperacao.documentos.rota,
                        'ctes' : this.detalhesOperacao.ctesSelecionado,
                        'nfes' : this.detalhesOperacao.nfesSelecionado
                    }
                    var dados = await new HttpRequest()
                        .Post(this.link+'alterar/status/rota', body);
                    if(dados.status){
                        this.limpaDados(false)
                        this.buscaDetalhesOperacao()
                        this.$emit('atualizaKanban', 'atualizar')
                    } else {
                        conectionError()
                    }
                } catch (error) {
                    this.loadingSaveFaturado = false
                } finally {
                    this.loadingSaveFaturado = false
                    this.detalhesOperacao.loading = false
                }
			}
		},

		/**
         * Altera status do veículo para manutenção ou disponível
         * @param String evento para o qual vai ser alterado
         * @return Mensagem de sucesso ou erro
         * @author Vitor Hugo 🐨
         */
		async changeItemStatusVeiculo(item){ 
			var dados = await new HttpRequest()
				.Post(this.link+'alterar/status/veiculo', 
					{'evento' : item,
						'placa'  : this.placa,});
			if(dados.status){
				this.limpaDados(false)
				this.fechaModalDetalhesOperacao()
				this.$emit('atualizaKanban', 'atualizar')
			} else {
				conectionError()
			}
		},

		/**
         * Adiciona a data inserida pelo usuário a variável dataFiltroRotasVinculadasSelecionadas
         * @param String data informada
         * @return String variável com a data informada
         * @author Vitor Hugo 🐨
         */
		changeFiltroDataRotasVinculadas(data){
			if(this.detalhesOperacao.filtroTabelaRotas){
				this.dataFiltroRotasVinculadasSelecionadas = data
				this.buscaDetalhesOperacao()
			}
		},

		/**
         * Filtra as rotas vinculadas pelo número da nota
         * @param String número da nota
         * @return Array com as rotas filtradas
         * @author Vitor Hugo 🐨
         */
		changeFiltroNumeroNotaRotasVinculadas(numeroNota){
			if(this.dadosDetalhesOperacao.rotas != undefined){
				var filtro = []
				filtro = this.dadosDetalhesOperacao.rotas.filter(element => {
					return element.nfes.toLowerCase().includes(numeroNota.toLowerCase())
				});
				this.detalhesOperacao.dadosFilter = filtro
				if(filtro.length == 0){
					this.statusBarRotas = 'error'
				}
			}
		},

		CtesModalDetalhesOperacao(ctes){
			this.detalhesOperacao.ctesSelecionado = ctes
		},

		nfesModalDetalhesOperacao(nfes){
			this.detalhesOperacao.nfesSelecionado = nfes
		},

		salvarStatusFaturadoModalDetalhesOperacao(){
			this.changeItemStatusRota(3, true)
		},

		cancelarStatusFaturadoModalDetalhesOperacao(){
			this.alterarstatusFaturado = false
		},

		fechaModalDetalhesOperacao(){
			this.limpaDados()
			this.$bvModal.hide('modalDetalhesOperacao-')
		},

		/**
         * Cancela uma rota vinculada
         * @param String código da rota
         * @return String se cancelou com sucesso retorna 1
         * @author Vitor Hugo 🐨
         */
		async cancelarRotaVinculada(rota, dr){
			var dados = await new HttpRequest()
                .Post(this.link+'cancelar/rota', {'rota':rota})
			if(dados.status && dados.data.status == 1){
				this.popoverShow[dr] = false
                if(rota == this.rota) {
                    this.rota = ''
                    this.detalhesOperacao.historico.dados = []
                }
				this.buscaDetalhesOperacao()
			} else {
				this.popoverShow[dr] = false
				conectionError()
			}
		},

		ativarPopover(dr){
			this.popoverShow[dr] = true
			this.disabled = false
		},

		cancelar(dr){
			this.popoverShow[dr] = false
			this.disabled = true
		},

		/**
         * Busca os dados para gerar a tabela Rotas Vinculadas
         * @param Arrays placa, rota, data
         * @return Objeto com o veículo, data prevista, jornada e rotas
         * @author Vitor Hugo 🐨
         */
		buscaDetalhesOperacao(){
			this.detalhesOperacao.loading = true
            let body = {
                'placa': this.placa,
                'rota': this.rota,
                'data': this.dataFiltroRotasVinculadasSelecionadas
            }
			new HttpRequest().Post(this.link+'detalhes/operacao', body)
				.then(({data, status})=>{
                    if(status) {
                        if(data.veiculo.lat && data.veiculo.lon) {
                            this.mapa.veiculo = [
                                Number(data.veiculo.lat),
                                Number(data.veiculo.lon)]
                        }
                        if(data.veiculo.endereco){
                            this.veiculoDetalhesoperacao = data.veiculo
                        } else {
                            this.veiculoDetalhesoperacao = []
                            this.statusBarRotas          = 'error'
                        }
                        this.dadosDetalhesOperacao = data
                        if(data.rotas && data.rotas.length > 0){
                            this.detalhesOperacao.dadosFilter = data.rotas
                            this.detalhesOperacao.dadosFilter.forEach(element => {
                                this.popoverShow.push(false)
                            })
                        } else {
                            this.detalhesOperacao.dadosFilter = []
                            this.statusBarRotas = 'error'
                        }

                    } else {
                        conectionError()
                    }
				})
                .finally(() => {
					this.detalhesOperacao.loading = false
                })
		},

		/**
         * Busca os dados para gerar a tabela de documentos vinculados e histórico de operação
         * @param Arrays placa e rota
         * @return Objeto com os documentos e timeline (para o histórico de operação)
         * @author Vitor Hugo 🐨
         */
		async buscaHistoricosModalDO(rota, status, placa){
			this.detalhesOperacao.loading = true
			this.adicionaImage = false
			this.imageValue = []
			this.detalhesOperacao.documentos.rota = rota
			this.detalhesOperacao.documentos.status = status
			this.detalhesOperacao.documentos.placa = placa
			this.rota = rota
            let body = {'placa': this.placa, 'rota': rota}
			await new HttpRequest().Post(this.link+'historicos/rota', body)
                .then((dados) => {
                    if(dados.status && dados.data) {
                        this.detalhesOperacao.documentos.dados = dados.data.documentos
                        this.detalhesOperacao.historico.dados = dados.data.timeline
                        if(this.detalhesOperacao.historico.dados.length == 0){
                            this.statusBar = 'error'
                        }
                    } else {
                        this.statusBar = 'error'
                    }
                })
                .catch((error)=>{
                    this.statusBar = 'error'
                })
                .finally(()=>{
                    this.detalhesOperacao.anexar = true
                    this.detalhesOperacao.loading = false
                })
		},

		// Documentos Vinculados

		/**
         * Faz o download do arquivo selecionado
         * @param String código do arquivo
         * @return 
         * @author Vitor Hugo 🐨
         */
		async downloadFileModalDetalhesOperacao(dadosDocumentos){
            let code = dadosDocumentos.code
            dadosDocumentos.download = false
			this.detalhesOperacao.documentos.loading = true
			var root =  process.env.VUE_APP_ROOT;
			await new HttpRequest().Post(this.link+'download', {code})
                .then((dados)=>{
                    if(dados.status && dados.data && dados.data.name) {
                        let file = dados.data
                        const linkSource = 'data:'+file.datatype+';base64,'+file.file
                        const downloadLink = document.createElement('a')
                        downloadLink.href = linkSource
                        downloadLink.download = file.name
                        downloadLink.click()
                        dadosDocumentos.download = true
                        this.toastShow('Sucesso', 'success')
                    } else{
                        this.toastShow('Erro, tente novamente mais tarde', 'danger')
                    }
                })
                .catch((error)=>{
                    this.toastShow('Erro, tente novamente mais tarde', 'danger')
                })
                .finally(()=>{
                    this.detalhesOperacao.documentos.loading = false
                })
		},

		changeNomeModalOperacoes(nome){
			this.nomeSelecionadoModalOperacoes = nome
		},

		/**
         * Altera o nome do arquivo já vinculado
         * @param String código da rota e nome informado
         * @return Objeto com o valor no nome alterado
         * @author Vitor Hugo 🐨
         */
		async changeInputNomeModalOperacoes(code){
			if(this.nomeSelecionadoModalOperacoes != ''){
				var file = await new HttpRequest()
					.Post(this.link+'alterar/nome/documento',
						{'name' :this.nomeSelecionadoModalOperacoes, 'code' :code});
				this.buscaHistoricosModalDO(
					this.detalhesOperacao.documentos.rota, 
					this.detalhesOperacao.documentos.status, 
					this.detalhesOperacao.documentos.placa,
				)
				this.nomeSelecionadoModalOperacoes = ''
                
			} else {
				this.detalhesOperacao.documentos.dados.forEach(element => {
					element.dbclick = false
					this.nomeSelecionadoModalOperacoes = element.name
				})
			}
		},

		/**
         * @listen blur       - executado quando for perdido o foco do input
         * @description Método para alterar o nome dos documento que serão vinculados
         * @param  {String} nome       - Nome digitado para alterar
         * @param  {Number} dv         - Posição em que o arquivo se encontra na array
         * @return {Array}  imageValue - Array com nomes dos arquivos ajustada
         * @return {Array}  imageUrl   - Array com arquivos com o nome alterado
         * @author Vitor Hugo 🐨
         */
		changeInputNomeModalVincular(nome, dv){
			if(nome != ''){
				this.imageUrl[dv] =  new File([this.imageUrl[dv]], nome, 
					{type: this.imageUrl[dv].type})
				this.imageValue[dv].name = nome
				this.imageValue[dv].dbclick = false
			} else {
				this.imageValue[dv].dbclick = false
			}
		},

		/**
         * @listen focus       - executado quando clicado no input
         * @description Método para inserir o nome atual do arquivo para a variável
         * @param  {String} nome       - Nome do arquivo
         * @return {String} nomeSelecionadoModalOperacoes - nome do arquivo selecionado
         * @author Vitor Hugo 🐨
         */
		clickInputNome(nome){
			this.nomeSelecionadoModalOperacoes = nome
		},

		/**
         * Método para acionar o input do tipo file
         * @param
         * @return adicionaImage (para mostar a div com os arquivos para vincular)
         * @author Vitor Hugo 🐨
         */
		onPickFile(){
			this.adicionaImage = true
			this.$refs.fileInput.click()
		},

		/**
         * Recebe os arquivos e armazena na variável imageUrl e o nome na imageValue
         * @param Objeto do tipo file
         * @return variáveis com o objeto file (imageUrl) e nome dos arquivos (imageValue)
         * @author Vitor Hugo 🐨
         */
		onFilePicked(event){
			const files = event.target.files
			files.forEach(element => {
				if(element.type != ''){
					var extArray = element.type.split('/')[1]
					var matchNames = (extArray.match(/png|jpg|jpeg|txt|csv|pdf/g) || [] || '').length
					if(matchNames > 0) {
						this.imageUrl.push(element)
						this.imageValue.push({'name': element.name, 'dbclick': false})
					} else {
						this.toastShow('Formato suportado: txt, csv, png, pdf, jpeg, jpg',
							'danger', 'Formato de arquivo inválido')
					}
				} else {
					this.toastShow('Formato suportado: txt, csv, png, pdf, jpeg, jpg',
						'danger', 'Formato de arquivo inválido')
				}
			});
		},

		/**
         * Salva os documento vinculados, enviado para o backend um objeto do tipo file utilizando
         * axios pois com http não foi possível
         * @param Arrays rota, placa, status, evento e objeto com os arquivos
         * @return status 200 ou 500
         * @author Vitor Hugo 🐨
         */
		async enviarDocumentosVinculados(){
			this.detalhesOperacao.loading = true
			var evento = ''
			this.detalhesOperacao.historico.dados.forEach(element => {
				evento = element.evento
			});

			this.currentDataset = {
				'rota'   : this.detalhesOperacao.documentos.rota,
				'status' : this.detalhesOperacao.documentos.status,
				'placa'  : this.detalhesOperacao.documentos.placa,
				'evento' : evento
			}

			var url = process.env.VUE_APP_ROOT_API+this.link+'salvar/dados/modal'
			var token = new AuthService()
			token = token.getToken()
			var strBearer = `Bearer ${token.token || ''}`;
			let formData = new FormData();
			for (let index = 0; index < this.imageUrl.length; index++) {
				formData.append('files[]', this.imageUrl[index], this.imageUrl[index].name);  
			}
			formData.append('dataset', JSON.stringify(this.currentDataset))
			axios.post(url, formData,{
				headers: {
					'Authorization': strBearer,
					'Content-Type': 'multipart/form-data'
				}
			}).then((data)=>{ 
				if(data.status == 200){
					this.toastShow(data.data.mensagem, 'success')
					this.adicionaImage = false
					this.buscaHistoricosModalDO(
						this.detalhesOperacao.documentos.rota, 
						this.detalhesOperacao.documentos.status, 
						this.detalhesOperacao.documentos.placa)
				} else {
					this.toastShow(data.data.mensagem, 'danger')
				}
			}).catch((error) => {
                conectionError()
            }).finally(() => {
				this.detalhesOperacao.loading = false
            })
		},

		toastShow(msg, type, title = ''){
			this.$bvToast.toast(`${msg}`, {
				autoHideDelay: 2500,
				variant: type,
				title: title
			})
		},

		/**
         * Excluir arquivo da array de arquivos para vincular
         * @param String posição do arquivo na array
         * @return Array sem o arquivo escolhido
         * @author Vitor Hugo 🐨
         */
		cancelarVinculacaoDocumentos(index){
			this.imageValue.splice(index, 1)
			this.imageUrl.splice(index, 1)
			if(this.imageValue.length == 0){
				this.adicionaImage = false
			}
		},

		/**
         * @listen addEventListener   - executado quando o evento de colar na tela é ativado
         * @description Método para gerar um arquivo a partir do evento de colar
         * @param  {Object} pasteEvent - Objeto do tipo paste do addEventListener
         * @return {Array}  imageUrl    - Variável onde é atribuido o File
         * @return {Array}  imageValue  - Array com os nomes dos aquivos para mostrar ao usuário
         * @author Vitor Hugo 🐨
         */
        pasteFile(pasteEvent){
            if(!this.getCadastrar() || this.mapa.show){
                return
            }
            var files = pasteEvent.clipboardData.files
            for (var j = 0; j < files.length; j++) {
                try{
                    var file = files[j]
                    var extArray = file.type.split('/')[1]
                    let match = /png|jpg|jpeg|txt|csv|pdf/g
                    var matchNames = (extArray.match(match) || []).length
                    if(matchNames > 0) {
                        let dtNow = Date.now().toString(36)
                        var name = Math.random().toString(36).replace('0.','') + dtNow
                        this.imageUrl.push(new File([file], name, {type: file.type}))
                        this.imageValue.push({'name': name, 'dbclick': false})
                        this.adicionaImage = true
                    } else {
                        this.toastShow(
                            'Formato suportado: txt, csv, png, pdf, jpeg, jpg',
                            'danger', 
                            'Formato de arquivo inválido')
                    }
                } catch (error) {
                    this.toastShow(
                        'Não foi possível copiar o arquivo da área de transferência!',
                        'danger')
                }
                
            }
            if(files.length == 0){
                this.toastShow('Sem dados na área de tranferência','danger')
            }
        },
    },

    computed: {
        dadosModalDetalhes(){
            this.$forceUpdate()
            return this.detalhesOperacao.dadosFilter
        },
    },
})
</script>
<style lang="scss">
    #divMapaDetalhesOperacao {
        #divInfoMapa{
            left:66%;
            margin-top: -2px;
            // margin-left: -20px;
            // margin-right: 20px ;
            border: 0 0 0 0;
            z-index: 500;
            position: absolute;
            .divInfoMapaDetalhes{
                padding: 10px;
                transform: translateX(30%);
                z-index: 1;
                position: absolute;
                margin-left: 10px;
                color: rgb(255, 253, 253);
                font-weight: bolder;
                background: rgba(29, 27, 27, 0.8);
                text-align: left;
                padding-left: 5px;
                min-width: 170px;
                opacity: 1 !important;
            }
        }
        .divIcon{
            opacity: 0.8;
            .markerRotaOrdem {
                position: absolute;
                top: 3px;
                padding: 0;
                border-radius: 43px;
                font-size: 12px;
                left: 9px;
                width: 22px;
                height: 21px;
                text-align: center;
                font-weight: bold;
                background-color: white;
                line-height: 20px;
            }
            .markerRotaImg{
                height: 38px;
                width: 40px;
            }
        }
        img.leaflet-marker-icon[src*="/img/markerPainelControle.png"] {
            opacity: 0.8;
        }
    }
</style>