/** 
  Este é um compoenete para grafico de data

  Propriedades:
    1 -> namePopUp - String, nome que vai aparecer do lado do valor 
    2 -> categories - Array - valores que vao na parte de baixo             
    2.1 ->values - Array - valores que nao fazer a linha do grafico
    3 -> autoScaleYaxis - Boolean, se o valor vai escalar conforme o aproxime o zoom
    4 -> title - String, titulo que vai no grafico
    5 -> mostraDataLabels - Boolean, se vai mostrar os valores na linha (um quadradinho com o valor do dia timestamp)
    6 -> annotationYaxis - Array de Objetos, se vai aparecer um marcador no grafico, no final da linha, pode ter mais que 1
        * EX: [{
                y: 43,
                borderColor: '#333',
                label: {
                  show: true,
                  text: 'asdasdsa',
                  style: {
                  new Date( color: "#fff),
                    background: '#00E396'
                  }
                }
              }]
      7 -> annotationXaxis - Array de Objetos, mesma coisa que o Y só que aparece em um ponto X do mapa, ele vai ficar um marcador
        * EX: [{
                x: new Date('03/14/2012').getTime(), aqui tem que ter o timestamp onde ele vai aparecer
                borderColor: '#999',
                yAxisIndex: 0,
                label: {
                  show: true,
                  text: 'asdasda',
                  style: {
                  new Date( color: "#fff),
                    background: '#775DD0'
                  }
                }
              }]
      8 -> fill Array de Objetos, não entendi muito bem como ele funciona mas ele coloca cor no grafico
        (NÃO USAR, NÃO TA PRONTO AINDA)
        * EX: [{
                type: 'gradient', -> como vai ser o grafico
                colors: ['red'], -> cores que ele vai tnew Date(r, EX: ["#333), 'red'] 
                gradient: {
                  shadeIntensity: 1,
                  opacityFrom: 0.1,
                  opacityTo: 0.9,
                  stops: [0, 100]
                }
              }]

  documetação
    https://apexcharts.com/docs/installation/
    https://apexcharts.com/vue-chart-demos/area-charts/datetime-x-axis/
    https://apexcharts.com/docs/options/xaxis/
*/

<template>
  <div class="col-sm-12 row nopadding">
    <span class="titulo col-sm-12 nopadding">
      {{title}}
    </span>
    <br>
    <div id="chart-timeline" class="col-sm-12  nopadding">
      <apexchart @render="render" type="area" height="450" ref="chart" :options="chartOptions" :series="series"></apexchart>
    </div>
  </div>
</template>
<script>
  export default {
    methods:{
      render(){
        var chart = new ApexCharts(el, options);
        chart.render()
      },
    },
    data: function() {
      return {
        series: [{
            color:'#7a7575',
            name: this.namePopUp,
            data: this.values
        },
        ],
        chartOptions: {
          chart: {
            defaultLocale: 'pt-br',
            locales: [{
              name: 'pt-br',
              options: {
                months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
                shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
                days: ['Domigo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'],
                shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
                toolbar: {
                  download: 'Download SVG',
                  selection: 'Selecione',
                  selectionZoom: 'Selecione o Zoom',
                  zoomIn: 'Zoom In',
                  zoomOut: 'Zoom Out',
                  pan: 'Arrastar',
                  reset: 'Tirar zoom',
                }
            }
          }],
          // legend: {
          //   horizontalAlign: 'rigth'
          // },
          height: 450,
            zoom: {
              autoScaleYaxis: this.autoScaleYaxis // se faz a escala quando da zoom ou n
            }
          },
          annotations: {  // se vai ter anotaçoes, aquelas que ficam nas linhas
            yaxis: this.annotationYaxis,
            xaxis: this.annotationXaxis  // se vai ter marcação em alguma ponto expecifico, aquela que fica no meio do grafico 
          },
          dataLabels: {
            enabled: this.mostraDataLabels
          },
          markers: {
            size: 0,
            style: 'hollow',
          },
          xaxis: {
            type: 'category',
            categories:this.categories,
            rotateAlways:false,
            tickAmount: -5,
            labels: {
              rotate: 0,
              style: {
                  colors: [],
                  fontSize: '12px',
                  // fontFamily: 'Helvetica, Arial, sans-serif',
                  // fontWeight: 400,
                  // cssClass: 'apexcharts-xaxis-label',
              },
            }
            
          },
        /**
         * category
         * datetime
         * numeric
         */

          // xaxis: {
          //   type: 'string', 
          //   tickAmount: 10,
          // },
          tooltip: {
            x: {
              format: 'dd/MM/yyyy'
            }
          },
            fill: {
              type: 'gradient',
              colors: ['#7a7575'],
              gradient: {
                shadeIntensity: 0.7,
                opacityFrom: 0.5,
                opacityTo: 1,
                stops: [0, 100]
              }
            },
        },
      }
    },
    props:{
      annotationYaxis:{
        type: Array,
        default: function(){
          return []
        }
      },
      categories:{
        type: Array,
        default: function(){
          return []
        }
      },
      annotationXaxis:{
        type: Array,
        default: function(){
          return []
        }
      },
      values:{ // prop para passar os dados
        type: Array,
        default: function (){
          return []
        }
      },
      autoScaleYaxis:{
        type: Boolean,
        default: true
      },
      // falta arrumar uns bagui aqui
      // fill:{
      //   type: Array,
      //   default: function(){
      //     return [{
      //       type: 'gradient',
      //       colors: '#000000',
      //       gradient: {
      //         shadeIntensity: 1,
      //         opacityFrom: 15,
      //         opacityTo: 15,
      //         stops: [0, 100]
      //       }
      //     }]
      //   }
      // },
      mostraDataLabels:{
        type: Boolean,
        default:true
      },
      title:{
        type: String,
        default: ''
      }, 
      namePopUp:{
        type: String,
        default:''
      },
    },
};
</script>
<style lang="scss">
  .titulo{
    font-size: 20px;
    position: absolute;
    left: 12px;
    margin-bottom: 10px !important;
    padding-bottom: 10px;
    
  }
  .gira-texto{
    transform: rotate(90deg) !important;
    background-color: #8e8da4;
  }
</style>