/**
    Modal de fornulário para adicionar/editar um item rota

    -> Propriedades:
        1- rota - Object - Objeto do tipo Rota
        2- selecionados - Array - array de objetos do tipo [ItensRota] com [Pontos]

    -> Slot:
        Sem slot

    -> Eventos:
        1- registroSalvo - Emite quando salva com sucesso - Sem parâmetro
 */
<template>
    <!-- @modalOpen="getPontos"  -->
    <modalEagle
        :hardToClose="true"
        @modalClose="modalClose" 
        title="Adicionar pedido" 
        id="modal-adicionar-pedido">
        <template #modal-cancel><div/></template>
        <template #modal-header-close><div/></template>
        <slot slot="modalBody">
            <panelEagle :loading="loadingPanel" id="panelAddItemPedido">
                <div class="col-sm-12 row nopadding">
                    <!-- <div class="text-center">
                        <span>
                            Quando um pedido é adicionado, a listagem é atualizada 
                            e os pontos selecionados são desmarcados!
                        </span>
                    </div> -->
                    <div class="col-sm-12 nopadding row">
                        <!-- Ponto -->
                        <div class="col-sm-6 nopadding">
                            <selectAll
                                ref="refSelectAlPonto"
                                :disabled="pontoSelecionadoEdicao.length > 0"
                                :extraClass='{"border border-danger": $v.pontoSelecionado.$anyError}'
                                :extraClassParent="'pr-0'"
                                @close='$v.pontoSelecionado.$touch()'
                                @changeSelect="changePonto" 
                                :isMultiple="false" 
                                nameForRadio="pontoCadItemRota" 
                                :hasSelectAll="false" 
                                :labels="[{indexDFH: 'P', description: 'Ponto*'}]" 
                                :optionsArray="arrayPontos"
                                :selected="pontoSelecionadoEdicao"
                                firstSelected="P">
                            </selectAll>
                        </div>
                        <!-- Tempo de parada -->
                        <div class="col-sm-3 nopadding">
                            <inputSimple
                                ref="refTempoParada"
                                type="time"
                                @changeInput="changeInputTempo"
                                :extraClass="' pr-0'"
                                label="Tempo de parada"
                                name="tempoMedioParada"
                                :value="tempoParado"
                            />
                        </div>
                        <!-- Documento -->
                        <div class="col-sm-3 nopadding">
                            <inputSimple
                                ref="refdocumentoItem"
                                @changeInput="changeDocumento" 
                                label="Documento" 
                                name="documentoItemRota" 
                                id="documentoItemRota"
                                :value="documentoItem">
                            </inputSimple>
                        </div>
                    </div>
                    <div class="col-sm-12 row divDeBaixo">
                        <!-- Cubagem -->
                        <div class="col-sm-3 nopadding">
                            <inputSimple
                                ref="refcubagemItem"
                                @changeInput="changeCubagem" 
                                label="Cubagem" 
                                :hasMask="true" 
                                :mask="['####,##']" 
                                :extraClass="' pr-0'"
                                name="cubagemItemRota" 
                                id="cubagemItemRota"
                                :value="cubagemItem">
                            </inputSimple>
                        </div>
                        <!-- Peso -->
                        <div class="col-sm-3 nopadding">
                            <inputSimple
                                ref="refpesoItem"
                                @changeInput="changePeso" 
                                label="Peso(Kg)" 
                                :hasMask="true" 
                                :mask="['########']" 
                                :extraClass="' pr-0'"
                                name="pesoItemRota" 
                                id="pesoItemRota"
                                :value="pesoItem">
                            </inputSimple>
                        </div>
                        <!-- Volumes -->
                        <div class="col-sm-3 nopadding">
                            <inputSimple
                                ref="refquantidadeItem"
                                @changeInput="changeQuantidade" 
                                :hasMask="true" 
                                :mask="['######']" 
                                label="Volumes" 
                                :extraClass="' pr-0'"
                                name="volumesItemRota" 
                                id="volumesItemRota"
                                :value="quantidadeItem">
                            </inputSimple>
                        </div>
                        <!-- Valor -->
                        <div class="col-sm-3 nopadding">
                            <inputMoney
                                ref="refvalorItem"
                                @changeInput="changeValor" 
                                label="Valor" 
                                :extraClass="' pr-0'"
                                name="valorItemRota" 
                                id="valorItemRota"
                                :value="valorItem">
                            </inputMoney>
                        </div>
                    </div>
                    <div class="col-sm-12 row divDeBaixo">
                        <!-- Observação -->
                        <div class="col-sm-9 nopadding">
                            <inputText
                                ref="refobsItem"
                                type="textarea"
                                @changeInput="changeObservacao" 
                                label="Observações" 
                                name="obsItemRota" 
                                id="obsItemRota"
                                :extraClass="' pr-0'"
                                rows="2"
                                :inputClass="'irobservacoes'"
                                :value="observacaoItem">
                            </inputText>
                        </div>
                        <!-- Data -->
                        <div class="col-sm-3 nopadding">
                            <inputSingleDay
                                ref="refDataJanelaIni"
								@changeInput="changeInputDataPedido"
								name="dataRotaManual"
								label="Data pedido"
								id="dataRotaManual"
                                :value="dataPedido"
								:extraClass="' pr-0'"
							/>
                        </div>
                    </div>
                    <div class="col-sm-12 row mt-4">
                        <h5>Janela (agendamento)</h5>
                    </div>
                    <div class="col-sm-12 row nopadding">
						<!-- Data Inicial-->
						<div class="col-sm-4 mt-2 pr-0">
							<inputSingleDay
                                ref="refDataJanelaIni"
								@changeInput="changeInputDataJanelaEntregaIni"
								name="dataRotaManual"
								label="Data Início"
								id="dataRotaManual"
                                :value="janelaEntrega.dataIni"
								:extraClass="'nopadding'"
							/>
						</div>
						<!-- Hora Inicial -->
						<div class="col-sm-4 mt-2 pr-0">
							<inputSimple
                                ref="refHoraJanelaIni"
								type="time"
								@changeInput="changeInputHoraJanelaEntregaIni"
								label="Hora Início"
								name="rohora"
                                :value="janelaEntrega.horaIni"
								:extraClass="'nopadding'"
							/>
						</div>
						<div class="col-sm-4 mt-2 pr-0">
                            <SelectMarcadores
                                :id="'select-marcadores-add-item-rota'"
                                :cliente="clienteCodigo"
                                ref="refSelectMarcadores"
                                :key="'inputTags'"
                                :isMultiple="true"
                                :hasSelectAll="true"
                                nameForRadio="inputTags"
                                :labels="[
                                    { indexDFH: 'marcadores', description: 'Marcadores' }
                                ]"
                                firstSelected="marcadores"
                                :optionsArray="optSelectTag"
                                :extraClassParent="'nopadding'"
                                :selected="tagsSelected"
                                :taggable="true"
                                @changeSelect="changeMarcador" 
                                @excluir="changeExcluirMarcador"
                                :loading="loadingPanel"
                            />
						</div>
                    </div>
                    <div class="col-sm-12 row nopadding">
                        <!-- Data Final-->
						<div class="col-sm-4 mt-3 pr-0">
							<inputSingleDay
                                ref="refDataJanelaFim"
								@changeInput="changeInputDataJanelaEntregaFim"
								name="dataRotaManual"
								label="Data Fim"
								id="dataRotaManual"
                                :value="janelaEntrega.dataFim"
								:extraClass="'nopadding'"
							/>
						</div>
						<!-- Hora Final -->
						<div class="col-sm-4 mt-3 pr-0">
							<inputSimple
                                ref="refHoraJanelaFim"
								type="time"
								@changeInput="changeInputHoraJanelaEntregaFim"
								label="Hora Fim"
								name="rohora"
                                :value="janelaEntrega.horaFim"
								:extraClass="'nopadding'"
							/>
						</div>
                    </div>
                </div>
            </panelEagle>
        </slot>
        <slot name="modal-footer">
            <!-- <div v-show="alertaPontoSalvo" 
                class="text-center cor-vermelha letra-branca">
                <span>
                    Este pedido já está salvo. Você não pode salvá-lo novamente.
                </span>
            </div> -->
            <simpleButton
                :disabled="loadingPanel"
                :icon="mdiCloseThick" 
                @click="cancelButton" 
                text="Fechar" 
                event="click" 
                type="red">
            </simpleButton>
            <!-- :disabled="alertaPontoSalvo" -->
            <simpleButton
                :disabled="$v.$invalid || loadingPanel || (!editado && dadosEdicao)"
                :icon="mdiCheckBold" 
                @click="salvarPedido" 
                text="Confirmar" 
                event="click" 
                type="blue">
            </simpleButton>
        </slot>
    </modalEagle>
</template>

<script lang="js">

import Vue from 'vue'
import { mdiCloseThick, mdiCheckBold } from '@mdi/js'
import { FiltrosService } from '../../../Services/filtros/filtros.Service'
import { HttpRequest } from '../../../Services/auth/HttpRequest.Service'
import { required } from 'vuelidate/lib/validators'
import { Duration } from 'luxon'
import { converteSegundosEmHoras, pickColor } from '@/views/Roteirizador/NaoMenus/RotaManual'
import { conectionError } from '@/Services/Helpers/swellHeper'

export default Vue.extend({
	name:'ModalAddItemRota',
	validations:{
		pontoSelecionado:{ required }
	},

	props:{
		rota:{
			type: [Array, Object],
			required: true,
			default: ()=>{return[]}
		},
		selecionados:{
			type: Array,
			required: true,
		},
		filtros:{
			type: Object,
			required: false,
			default: ()=>{return[]}
		},
	},

	components:{
		'selectAll': require('../Select/SelectAll').default,
		'modalEagle': require('../Modal/ModalEagle').default,
		'inputMoney': require('../Inputs/InputMoney').default,
		'inputSimple': require('../Inputs/InputSimple').default,
		'panelEagle': require('@/components/Atom/Panel/PanelEagle.vue').default,
		'simpleButton': require('@/components/Atom/Buttons/SimpleButton.vue').default,
        'inputSingleDay': require('@/components/Atom/Datas/InputSingleDay').default,
		'inputText': require('@/components/Atom/Inputs/InputTextArea').default,
        'SelectMarcadores': require(
            '@/components/Atom/SpecificComponents/RotaManual/SelectMarcadores').default,
	},

	data(){
		return {
			mdiCheckBold:mdiCheckBold,
			mdiCloseThick:mdiCloseThick,
			loadingPanel:false,
			clienteCodigo: this.rota.empresa,
			arrayPontos: [],
			pontoSelecionado: '',
			documentoItem: '',
			cubagemItem: '',
			pesoItem: '',
			quantidadeItem: '',
            dataPedido: '',
            valorItem: '',
            observacaoItem: '',
            tempoParado: '',
            janelaEntrega:{
                dataIni: '',
                dataFim: '',
                horaIni: '',
                horaFim: '',
            },
			isLoading: false,
			// alertaPontoSalvo:false,

            dadosEdicao: '',
            pontoSelecionadoEdicao: [],
            editado: false,
            tagsSelected: [],
            optSelectTag: [],
            marcadoresItem: [],
		}
	},

    methods: {
        
        /**
         * @description Essa função deve ser chamada antes de abrir o modal,
         * principalmente quando for a edição de um pedido
         * @param {Object} dados objeto com informações de ItensRota e Ponto
         * @author Rafael
         */
        preparaModal(dados, marcadores) {
            this.resetDados()
            this.optSelectTag = marcadores
            if(dados) {
                this.dadosEdicao = dados
                this.colorirMarcadoresSelect()
                this.setDadosEdicao()
            } else {
                this.getPontos()
            }
        },

        /**
         * @listens changeInput
         * @param {Array} value
         */
		changePonto(value, fdc, description){
            this.editado = true
			this.pontoSelecionado = value[0]
			// this.alertaPontoSalvo = false
			// var igual = this.selecionados.find(v=> v.ircodigoexterno ==  value[0])
            // this.alertaPontoSalvo = igual ? true : false
		},
        
        /**
         * @listens changeInput
         * @param {String} value
         */
		changeDocumento(value){
            this.editado = true
			this.documentoItem = value
		},
        
        /**
         * @listens changeInput
         * @param {String} value
         */
		changeCubagem(value){
            this.editado = true
			this.cubagemItem = value
		},
        
        /**
         * @listens changeInput
         * @param {String} value
         */
		changePeso(value){
            this.editado = true
			this.pesoItem = value
		},
        
        /**
         * @listens changeInput
         * @param {String} value
         */
		changeQuantidade(value){
            this.editado = true
			this.quantidadeItem = value
		},
        
        /**
         * @listens changeInput
         * @param {String} value
         */
		changeValor(value){
            this.editado = true
			this.valorItem = value
		},
        
        /**
         * @listens changeInput
         * @param {String} value
         */
        changeObservacao(value) {
            this.editado = true
			this.observacaoItem = value
        },
        
        /**
         * @listens changeInput
         * @param {String} value
         */
		changeInputTempo(value){
            this.editado = true
			this.tempoParado = value
		},

        /**
         * @listens changeInput
         * @param {String} value
         */
        changeInputDataPedido(value){
            this.editado = true
			this.dataPedido = value
        },
        
        /**
         * @listens changeInput
         * @param {String} value
         */
		changeInputDataJanelaEntregaIni(value){
            this.editado = true
			this.janelaEntrega.dataIni = value
		},

        /**
         * @listens changeInput
         * @param {String} value
         */
		changeInputHoraJanelaEntregaIni(value){
            this.editado = true
            this.janelaEntrega.horaIni = value
		},

        /**
         * @listens changeInput
         * @param {String} value
         */
		changeInputDataJanelaEntregaFim(value){
            this.editado = true
			this.janelaEntrega.dataFim = value
		},

        /**
         * @listens changeInput
         * @param {String} value
         */
		changeInputHoraJanelaEntregaFim(value){
            this.editado = true
            this.janelaEntrega.horaFim = value
		},

        /**
         * @description Função para fechar o modal
         * @listens click
         * @author Rafael
         */
		cancelButton(){
			this.$bvModal.hide('modal-adicionar-pedido')
		},
        
        /**
         * @description Quando é edição do pedido, chama essa função para mapear os dados
         * @author Rafael
         */
        setDadosEdicao(){
            if(this.dadosEdicao) {
                let dados = {...this.dadosEdicao} // desacomplamento
                if(dados.ircodigoexterno && dados.irnome) {
                    this.pontoSelecionadoEdicao = [
                        {
                            value:dados.ircodigoexterno, 
                            description:dados.irnome
                        }
                    ]
                    this.arrayPontos = this.pontoSelecionadoEdicao
                    this.pontoSelecionado = dados.ircodigoexterno
                }
                if(dados.irtempoparado) {
                    this.tempoParado = converteSegundosEmHoras(dados.irtempoparado)
                }
                this.documentoItem = dados.irdocumento ?? ''
                // if (dados.irdata) {
                //     this.dataPedido = dados.irdata.split('-').reverse().join('/')
                // }
                this.dataPedido = dados.irdata
                this.cubagemItem = dados.ircubagem ?? ''
                this.pesoItem = dados.irpeso ?? ''
                this.quantidadeItem = dados.irqtde ?? ''
                this.valorItem = dados.irvalor ?? ''
                this.observacaoItem = dados.irobservacoes ?? ''
                if(dados.irinijanelaentrega) {
                    let info = dados.irinijanelaentrega.split(' ')
                    this.janelaEntrega.dataIni = info[0].split('-').reverse().join('/')
                    this.janelaEntrega.horaIni = info[1].substr(0, 5)
                }
                if(dados.irfimjanelaentrega) {
                    let info = dados.irfimjanelaentrega.split(' ')
                    this.janelaEntrega.dataFim = info[0].split('-').reverse().join('/')
                    this.janelaEntrega.horaFim = info[1].substr(0, 5)
                }
                dados.marcadores = Object.values(dados.marcadores)
                this.setMarcadoresOptions(dados.marcadores)
            }
            this.$nextTick(()=>{
                this.editado = false
            })
        },

        setMarcadoresOptions(marcadores) {
            this.marcadoresItem = marcadores ?? []
            this.tagsSelected = this.marcadoresItem.map(e => {
                return { value: e, description: e }
            })
            this.colorirMarcadoresSelect(true)
        },

        /**
         * @description Busca os pontos do cliente para o select de pontos
         * @author Rafael
         */
		async getPontos(){
            // this.alertaPontoSalvo = false
            if(this.rota.empresa) {
                this.loadingPanel = true
                var data = await new FiltrosService().dados_filtros([this.clienteCodigo], ['P'])
                if(data.P){
                    this.arrayPontos = data['P']
                }
                this.loadingPanel = false
            }
		},

        /**
         * @description Formata e envia as informações para o servidor para salvar
         * @listens click
         * @author Rafael
         */
		async salvarPedido(){
            if(this.pontoSelecionado != ''){
                try {
                    this.loadingPanel = true
                    let url = '/roteirizador/rota/manual/itens/salvar'
                    let body = {
                        'ponto': this.pontoSelecionado,
                        'documento': this.documentoItem, 
                        'cubagem': this.cubagemItem, 
                        'peso': this.pesoItem, 
                        'volumes': this.quantidadeItem,
                        'data': this.dataPedido,
                        'valor': this.valorItem,
                        'observacao': this.observacaoItem,
                        'tempoParado': this.tempoParado,
                        'janelaEntrega': this.janelaEntrega,
                        'ircodigo': this.dadosEdicao ? this.dadosEdicao.ircodigo : '',
                        'marcadores': this.marcadoresItem,
                        'edicao': this.dadosEdicao ? true : false,
                        'cliente': this.rota.empresa,
                        'filtros': this.filtros,
                    }
                    const { status, data, code } = await new HttpRequest().Post(url, body)
                    if(code == 201) {
                        this.loadingPanel = false
                        this.mostrarMensagemSalvar()
                        this.setDadosRetorno()
                        this.$refs.refvalorItem.clearAll()
                        this.$refs.refpesoItem.clearAll()
                        this.$refs.refcubagemItem.clearAll()
                        this.$refs.refdocumentoItem.clearAll()
                        this.$refs.refSelectAlPonto.clearAll()
                        this.$refs.refquantidadeItem.clearAll()
                        this.$refs.refSelectMarcadores.clearAll()
                        if(!this.rota.tempoParada)
                            this.$refs.refTempoParada.clearAll()
                        this.$refs.refHoraJanelaFim.clearAll()
                        this.$refs.refHoraJanelaIni.clearAll()
                        if(this.dadosEdicao) {
                            this.$emit('registroSalvo', this.dadosEdicao, true)
                            this.dadosEdicao = ''
                            this.$bvModal.hide('modal-adicionar-pedido')
                        } else {
                            if(data.item) {
                                this.$emit('registroSalvo', data.item)
                            }
                            this.resetForm()
                        }   
                    } else {
                        this.mostrarMensagemSalvar(false)
                    }
                } catch (error) {
                    this.mostrarMensagemSalvar(false)
                }
			}
		},

        setDadosRetorno() {
            if(this.dadosEdicao) {
                this.dadosEdicao.irdocumento = this.documentoItem ?? ''
                this.dadosEdicao.ircubagem = this.cubagemItem ?? ''
                this.dadosEdicao.irpeso = this.pesoItem ?? ''
                this.dadosEdicao.irqtde = this.quantidadeItem ?? ''
                this.dadosEdicao.irvalor = this.valorItem ?? ''
                this.dadosEdicao.irdata = this.dataPedido ?? ''
                this.dadosEdicao.irobservacoes = this.observacaoItem ?? ''
                if(this.janelaEntrega.dataIni && this.janelaEntrega.horaIni) {
                    this.dadosEdicao.irinijanelaentrega = 
                        this.janelaEntrega.dataIni.split('/').reverse().join('-') 
                            +' '+ this.janelaEntrega.horaIni+':00'
                } else {
                    this.dadosEdicao.irinijanelaentrega = ''
                }
                if(this.janelaEntrega.dataFim && this.janelaEntrega.horaFim) {
                    this.dadosEdicao.irfimjanelaentrega = 
                        this.janelaEntrega.dataFim.split('/').reverse().join('-') 
                            +' '+ this.janelaEntrega.horaFim+':00'
                } else {
                    this.dadosEdicao.irfimjanelaentrega = ''
                }
                if(this.tempoParado) {
                    let tempo = this.tempoParado.length == 5 
                        ? this.tempoParado+':00' : this.tempoParado
                    let duracao = Duration.fromISOTime(tempo)
                    let duracaoSeg = ((duracao.hours*60+ duracao.minutes)*60)+ duracao.seconds
                    this.dadosEdicao.irtempoparado = duracaoSeg
                } else {
                    this.dadosEdicao.irtempoparado = 0
                }
                this.dadosEdicao.marcadores = this.marcadoresItem ?? []
            }
        },
        
        /**
         * @description Mostra um toast com mensagem de sucesso ou erro
         * @param {boolean} sucesso Indica se a request teve sucesso ou não
         */
        mostrarMensagemSalvar(sucesso=true) {
            if(sucesso) {
                let tipo = this.dadosEdicao ? 'alterado' : 'registrado'
                this.toastShow(`Pedido ${tipo} com sucesso`, 'success', '')
            } else {
                this.toastShow('Não foi possível concluir a operação', 'danger')
            }
        },

        /**
         * @description Limpa todas as variáveis do componente, exceto [clienteCodigo]
         * @listens modalClose
         * @author Rafael
         */
        modalClose() {
            this.resetDados()
        },

        /**
         * @description Limpa todas as variáveis do componente, exceto [clienteCodigo]
         * @author Rafael
         */
        resetDados() {
            this.resetForm()
            this.loadingPanel = false
			this.arrayPontos = []
			this.isLoading = false
            this.dadosEdicao = ''
            this.pontoSelecionadoEdicao = []
            this.editado = false
			// this.alertaPontoSalvo = false
        },
        
        /**
         * @description Limpa as variáveis com informações do formulário e da validação
         * @author Rafael
         */
        resetForm() {
			this.pontoSelecionado = ''
			this.documentoItem = ''
			this.cubagemItem = ''
			this.pesoItem = ''
            this.quantidadeItem = ''
            this.dataPedido = ''
			this.valorItem = ''
            this.observacaoItem = ''
            this.marcadoresItem = []
            this.tagsSelected = []
            this.janelaEntrega = {
                dataIni: this.janelaEntrega.dataIni,
                dataFim: this.janelaEntrega.dataIni,
                horaIni: '',
                horaFim: '',
            }
            this.setTempoParado()
            this.$v.$reset()
        },

        /**
         * @description Método para mostrar o toast na tela
         * @param {string} msg - mensagem que vai aparecer no corpo do toast
         * @param {string} type - qual o tipo do toast 
         * @param {string} title - título para inserir no cabeçalho
         * @author Rafael
        */
        toastShow(msg, type, title = 'Ops!'){
            this.$bvToast.toast(`${msg}`, {
                autoHideDelay: 2500,
                variant: type,
                title: title
            })
        },

        setTempoParado() {
            if(this.dadosEdicao && this.dadosEdicao.irtempoparado) {
                this.tempoParado = converteSegundosEmHoras(this.dadosEdicao.irtempoparado)
            } else if(this.rota.tempoParada){
                    this.tempoParado = this.rota.tempoParada
            } else {
                this.tempoParado = null
            }
        },
        
        colorirMarcadoresSelect(selected = true) {
            if (selected) {
                this.tagsSelected.forEach((e) => {
                    e.cor = pickColor(e.description)
                })
            } else {
                this.optSelectTag.forEach((e) => {
                    e.cor = pickColor(e.description)
                })
            }
        },

        changeMarcador(tags) {
            this.editado = true
            this.marcadoresItem = tags
        },

        async changeExcluirMarcador(codigo) {
            this.editado = true
            if (codigo) {
                let url = '/roteirizador/rota/manual/itens/marcador/excluir'
                let body = { codigo, cliente: this.clienteCodigo }
                let statusOk = false
                this.loadingPanel = true
                await new HttpRequest().Post(url, body)
                    .then(({status}) => {
                        statusOk = status
                    })
                    .catch((e) => {
                        conectionError()
                    })
                    .finally(() => {
                        if (statusOk) {
                            let index = this.marcadoresItem?.indexOf(codigo)
                            if (typeof index == 'number') {
                                this.marcadoresItem?.splice(index, 1)
                            }
                            let index2 = this.dadosEdicao.marcadores?.indexOf(codigo)
                            if (typeof index2 == 'number') {
                                this.dadosEdicao.marcadores?.splice(index2, 1)
                            }
                            this.setMarcadoresOptions(this.marcadoresItem)
                            this.$emit('removerMarcador', codigo)
                        }
                        this.loadingPanel = false
                    })
            }
        }
	},

	watch:{
		'rota.tempoParada'(){
            this.setTempoParado()
		},
        'rota.empresa'(){
			this.clienteCodigo = this.rota.empresa
        },
        // 'pedido.marcadores'() {
        //     this.optSelectTag = this.pedido.marcadores
        // }
	}
})
</script>

<style lang="scss">
    #modal-adicionar-pedido{
        .divDeBaixo{
            padding-left: 0px !important;
            padding-right: 0px !important;
            padding-top: 10px !important;
        }
        .letra-branca{
            color: #fff !important;
        }
        .irobservacoes{
            width: 100%;
            height: unset;
            max-height: 50px !important;
            max-height: 200px !important;
            padding: 5px !important;
            line-height: inherit !important;
            display: inline-block !important;
            resize: vertical !important;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            border: 1px solid #ced4da;
            border-radius: unset;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            min-height: 35px;
        }
        .irobservacoes:focus{
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 0.2rem #007bff40;
        }
    }
</style>