<template>
    <panelEagle id="horasExtras"
        :loading="loadingPanel">
        <div class="col-sm-12 nopadding ">   
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiClockTimeThree" titulo="Horas extras"></tituloPage>
                </div>
                <div class="col-sm-6 divButtonsBasic divDontPrint">
                    <basicButtonsRelatoriosEIG 
                        @exportarRelatorio ="exportarRelatorio" 
                        @gerarRelatorio    ="gerarRel"
                        :loading           ='loadingExportar'
                        :disabled          ="$v.optionsSelectCGC.$invalid"
                        :disabledDropdown  ="$v.dadosHorasExtras.$invalid">
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12"><hr></div>
                    <div class="col-sm-12 row nopadding mb-2">
                        <div class="col-sm-4 nopadding">
                            <inputRangeWithLimit 
                                opens="right"
                                @changeInput="changeData" 
                                titulo="Data" 
                                :isObrigatorio="true" 
                                name="inputDataIni">
                            </inputRangeWithLimit>
                        </div>
                        <div class="col-sm-4 nopadding">
                            <selectAll 
                                @changeSelect ="SelectEmpresas" 
                                :isMultiple   ="true" 
                                nameForRadio  ="radiosEmpresas" 
                                :labels       ="labelsSelectEmpresa" 
                                :optionsArray ="optionsChangeEmpresas" 
                                firstSelected ="E" 
                                :selected="selectedEmpresa"
                                :hasSelectAll ="true">
                            </selectAll>
                        </div>
                        <div class="col-sm-4 nopadding">
                            <selectAll 
                                @changeSelect ="changeSelectColaboradores" 
                                @changeCheck  ="registraCheckColab" 
                                @selectCriado ="selectCriado" 
                                :isMultiple   ="true" 
                                nameForRadio  ="radiosColaboradoresGrupo" 
                                :labels       ="labelsSelectColaboradores" 
                                :optionsArray ="arraySelectedCGC" 
                                firstSelected ="C" 
                                :loading      ="loadingColaboradores"
                                :disabled     ="$v.optionsSelectE.$invalid"
                                :hasSelectAll="true">
                            </selectAll>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
            <div class="col-sm-12"><hr></div>
        </div>
        <tableRelatorio>
            <slot slot="thead">
                <th>Colaborador</th>
                <th>Extra Diurna</th>
                <th>Extra Noturna</th>
                <th>Extra 100%</th>
            </slot>
            <slot slot="tbody">
                <br>
                <statusInformation 
                    v-if="dadosHorasExtras.length == 0"
                    typeBar="tr" 
                    :statusBar="statusbar">
                </statusInformation>
                <template v-for="(colaboradores, c) in dadosHorasExtras">
                    <tr v-for="(dados, d) in colaboradores"
                        v-show="c == 'detalhes'"
                        :key="d"
                        class="dadosTabelaHorasExtras">
                        <td>
                            {{d}}
                        </td>
                        <td>
                            {{dados.extra}}
                        </td>
                        <td>
                            {{dados.extraNot}}
                        </td>
                        <td>
                            {{dados.horaCem}}
                        </td>
                    </tr>
                    <tr v-if="c == 'totais'"
                        :key="c"
                        class="totalHorasExtras">
                        <td>
                            Total:
                        </td>
                        <td>
                            {{colaboradores.extra}}
                        </td>
                        <td>
                            {{colaboradores.extraNot}}
                        </td>
                        <td>
                            {{colaboradores.horaCem}}
                        </td>
                    </tr>
                </template>
            </slot>
        </tableRelatorio>
    </panelEagle>
</template>

<script>
import Vue from 'vue'
import {mdiClockTimeThree } from '@mdi/js'
import { required }      from 'vuelidate/lib/validators'
import {EmpresasService} from '../../../Services/auth/Empresas.service'
import {HttpRequest}     from '../../../Services/auth/HttpRequest.Service'
import {FiltrosService}  from '../../../Services/filtros/filtros.Service'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name:"horasExtras",
    components:{
        'panelEagle'                : require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage'                : require('@/components/Atom/Header/Titulo').default,
        'basicButtonsRelatoriosEIG' : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
        'slideUpAndDown'            : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
        'selectAll'                 : require('@/components/Atom/Select/SelectAll').default,
        'inputRangeWithLimit'       : require('@/components/Atom/Datas/InputRangeWithLimit').default,
        'statusInformation'         : require('@/components/Atom/StatusInformation/StatusInformation').default,
        'tableRelatorio'            : require('@/components/Atom/Table/TableRelatorio').default,
    },

    validations:{
    
        optionsSelectE:{
            required
        },
        optionsSelectCGC:{ 
            required
        },

        dadosHorasExtras:{
            required
        },
    },

    data(){
        return{
            mdiClockTimeThree:mdiClockTimeThree,
            labelsSelectEmpresa:[
                {indexDFH: 'E', description: 'Empresas*'},
            ],
            optionsChangeEmpresas: new EmpresasService().Get(),
            labelsSelectColaboradores:[
                {indexDFH: 'C', description: 'Colaboradores*'},
                {indexDFH: 'GC', description: 'Grupos*'},
            ],
            optionsSelectE: [],
            optionsSelectCGC: [],
            arraySelectedCGC: [],
            optionRadiusColab: '',
            selectColaboradores: [],
            data: '',
            htmlHorasExtras: '',
            statusbar: 'info',
            dadosHorasExtras: [],
            sizeReturn: 0,
            dataToExport: '',
            loadingExportar     : [false, false, false],
            loadingPanel        : false,
            selectedEmpresa: [],
            loadingColaboradores : false,
        }
    },
    methods:{
        ...mapGetters(['getMaster']),

        SelectEmpresas(event){
            this.optionsSelectE = event;
            this.selectColaboradores.clearAll();
            this.dadosHorasExtras = []
        },
        changeSelectColaboradores(event){
            this.optionsSelectCGC = event;
            this.dadosHorasExtras = []
        },
        changeData(data){
            this.data = data;
            this.dadosHorasExtras = []
        },

        selectCriado(select){
            this.selectColaboradores = select;
        },
        registraCheckColab(check, needRecharge){
            this.optionRadiusColab = check
            if(needRecharge){
                this.dados_filtros();
            }
        },
        async dados_filtros(){
            var dados = [];
            this.loadingColaboradores = true
            dados = await new FiltrosService().dados_filtros(this.optionsSelectE, [this.optionRadiusColab])
            this.optionsSelectCGC = []
            this.arraySelectedCGC = dados[this.optionRadiusColab];
            this.loadingColaboradores= false
        },
        async gerarRel(){
            this.loadingPanel = true
            if(this.optionsSelectE.length && this.optionsSelectCGC.length && this.data.length){
                this.dadosHorasExtras = [];
                this.sizeReturn = 0;
                var editData   = this.data.split("-")
                var dataInicio = editData[0]
                var dataFim    = editData[1]
                var data = await new HttpRequest().Post('/controle/jornada/relatorios/horas/extras/gerar/horas', 
                {
                    'clientes': this.optionsSelectE,
                    'motoristas': this.optionsSelectCGC,
                    'tipeM': this.optionRadiusColab,
                    'dini': dataInicio,
                    'dfim': dataFim
                })
                var dados = data.data;
                if(dados){
                    this.sizeReturn = Object.keys(dados.horasextras.detalhes).length
                } else {
                    this.sizeReturn = 0
                }
                this.htmlHorasExtras = '';
                if(this.sizeReturn > 0){
                    this.dadosHorasExtras = dados.horasextras
                }else{
                    this.dadosHorasExtras = [];
                    this.statusbar = 'error'
                }
            }
            this.loadingPanel = false
        },
        async exportarRelatorio(tipo){
            var root =  process.env.VUE_APP_ROOT;
            this.setLoading(tipo)
            if(this.sizeReturn){
                var data = await new HttpRequest().Post('/controle/jornada/relatorios/horas/extras/exportar/horas', 
                {
                    'arrayDados':JSON.stringify(this.dadosHorasExtras), 
                    'tipo':tipo, 
                    'data':this.data
                });
                window.open(root+'/'+data.data.local);
            }
            this.setLoading()
        },

        setLoading(tipo){
            let arr = [false, false, false]
            if(tipo=='pdf'){
            arr[0] = true;
            }else if(tipo=='xls'){
            arr[1] = true;
            }else if(tipo=='csv'){
            arr[2] = true;
            }
            this.loadingExportar =  arr;
        },
    },

    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optionsChangeEmpresas
        }
    }
})
</script>
<style lang="scss">
    #horasExtras{
        .dadosTabelaHorasExtras{
            background: #fff;
            td{
                border: 1px solid #d5d5d5;
            }
        }

        .totalHorasExtras{
            border: 1px solid #d5d5d5;
        }
    }
</style>
