/**
    Componente compoe a tabela de monitoramento de linhas, contém as principais da rota da linha
 */

<template>
    <div class="col-sm-12 divMasterLinhaPrincipal nopadding">
        <div v-for="(rotas) in principal.rotas" :key="rotas.codigo">
            <div class="col-sm-12 divTituloLP nopadding row mt-3" v-b-popover.hover.top="rotas.porCent"
                style="height: 50px;" @click="rotas.rotaClicada = !rotas.rotaClicada">
                <div :style="'width:' + rotas.porCent" :class="'col-sm-12 opacidadeRota nopadding ' +
                    'progress-bar-' + rotas.statusOnibus">
                </div>

                <!-- Ícone chevron -->
                <div class="divButtonFlecha d-flex justify-contet-center align-items-center font-bold"
                    style="z-index: 40; padding-left: 10px;">
                    <b-icon class="h5 mb-0" :icon="rotas.rotaClicada ? 'chevron-down' : 'chevron-right'" />
                </div>

                <!-- Escala -->
                <div v-b-popover.hover.top="rotas.escala"
                    class="col-sm-3 divNomeLinhaPrincipal d-flex justify-content-start align-items-center">
                    Escala:
                    <div class="text-move text-move-escala ml-1 d-flex justify-content-start align-items-center">
                        <span class="font-bold ml-2">{{ rotas.escala }}</span>
                    </div>
                </div>

                <!-- Linha -->
                <div class="col-sm-3 divNomeLinhaPrincipal d-flex justify-content-start align-items-center">
                    Linha:
                    <div v-b-popover.hover.top="rotas.lidescricao"
                        class="text-move text-move-linha ml-1 d-flex justify-content-start align-items-center">
                        <span class="font-bold ml-2">{{ rotas.lidescricao }}</span>
                    </div>
                </div>

                <!-- Horário de inicio -->
                <div class="col-sm-3 divNomeLinhaPrincipal d-flex justify-content-start align-items-center text-truncate">
                    Horário de inicio:
                    <div v-b-popover.hover.top="rotas.inicioProgramado"
                        class=" ml-1 d-flex justify-content-start align-items-center">
                        <span class="font-bold ml-2">{{ rotas.inicioProgramado }}</span>
                    </div>
                </div>

                <!-- Porcentagem Realizada -->
                <div class="col-sm-2 divNomeLinhaPrincipal text-truncate d-flex justify-contet-center align-items-center">
                    <span class="font-bold">{{ rotas.porCent }} concluída</span>
                </div>
            </div>

            <div class="col-sm-12 nopadding divBlockLinhasSeparadas">
                <!-- Div que tem as informações dos pontos, chama o componente de criação dos dados -->
                <slide-up-down class="divAccordion" :active="rotas.rotaClicada" :use-hidden="true" :duration="500">
                    <linhaSeparada @linhaSeparada="itensCriados" :key="rotas.codigo" :arrayDados="rotas">
                    </linhaSeparada>
                </slide-up-down>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import SlideUpDown from 'vue-slide-up-down'

export default Vue.extend({
    name: 'MonitoramentoLinhaPrincipal',

    components: {
        SlideUpDown,
        // 'onibusUnico': require('./MonitoramentoLinhaOnibusUnico').default,
        'linhaSeparada': require('./MonitoramentoLinhaLinhaSeparada').default,
    },

    data() {
        return {
            countOnibus: 0,
            onibus: [],
            countLinhasSeparadas: 0,
            linhasSeparadas: [],
            principal: this.arrayDados,
        }
    },

    props: {
        arrayDados: {
            type: Object,
            required: true,
            default: function () { return {} }
        }
    },

    watch: {
        arrayDados() {
            this.principal = this.arrayDados;
        }
    },

    methods: {
        itensCriados(item) {
            this.$emit('linhaCriada', item)
        }
    }
})
</script>

<style lang="scss">
.divMasterLinhaPrincipal {
    text-align: left !important;
    font-size: 12px;
    height: 100%;

    .divTituloLP {
        font-size: 14px;
        background-color: #f2f2f2;
        border-bottom: 1px solid #d5d5d5;

        &:hover {
            cursor: pointer !important;
        }

        .divNomeLinhaPrincipal,
        .divIniciadasLinhaPrincipal,
        .divNomeLinhaPrincipal {
            padding-top: 5px !important;
        }
    }

    .divLinesBusoes {
        .divBusaoPrincipal {
            min-height: 65px;

            .divBorderBusao {
                min-height: 60px;
                ;
                border-bottom: 2px solid #888888;

                .busaoRota {
                    position: absolute;

                    .iconBusao {
                        float: right;
                        height: 40px;
                        bottom: 10px;
                        position: relative;

                        &:before {
                            margin-left: 0px;
                            font-size: 40px;
                        }
                    }

                    .divSpanOnibus {
                        height: 29px;

                        .spanPrefixoIdentOnibus {
                            float: right;
                            position: relative;
                            top: 5px;
                            padding-left: 5px;
                            padding-right: 5px;
                            left: 5px;
                            padding-top: 3px;
                            border: 1px solid #d5d5d5;
                            border-radius: 15px;
                            font-weight: bold;

                            &.onibus-none {
                                background-color: #e5e5e5 !important;
                            }

                            &.onibus-red {
                                background-color: #e7aaad !important;
                            }

                            &.onibus-orange {
                                background-color: #f0ad4e !important;
                            }

                            &.onibus-green {
                                background-color: #428BCA !important;
                            }
                        }
                    }
                }
            }

        }
    }

    .divBlockLinhasSeparadas {
        .divAccordion {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
        }

        .divMasterTimeline {

            .timeLineTime {
                height: 54px;
                overflow-x: hidden;
                overflow-y: hidden;
                border: 1px solid #d5d5d5;

                .thisIsTimeLine {
                    display: table-cell;
                    position: absolute;
                    height: 70px;
                    padding-right: 20px;
                    vertical-align: bottom;

                    .timePorPonto {
                        border-bottom: 3px solid #d5d5d5;
                        padding-right: 0px;
                        position: relative;
                        float: left;
                        padding-top: 5px;
                        margin: 0px;
                        height: 35px;

                        .iconPonto {
                            float: right;
                            position: relative;
                            left: 9px;
                            z-index: 2;
                            top: 5px;

                            &::before {
                                margin-left: 0px;
                            }

                            &.iconStatus-green {
                                color: #95b65c;
                            }

                            &.iconStatus-red {
                                color: #E04B4A;
                            }

                            &.iconStatus-blue {
                                color: #428BCA;
                            }
                        }

                        .spanHoraPassou {
                            position: relative;

                            &.buttonTop {
                                top: 2px;
                            }

                            &.buttonBottom {
                                top: 15px;
                            }
                        }
                    }

                    .garagemTimePonto {
                        border-bottom: 3px solid #d5d5d5;
                        padding-right: 0px;
                        width: 30px;
                        position: relative;
                        float: left;
                        padding-top: 5px;
                        margin: 0px;
                        height: 35px;

                        .iconGaragem {
                            float: left;
                            bottom: 7px;
                            position: relative;

                            &::before {
                                margin-left: 0px;
                                font-size: 30px;
                            }
                        }
                    }

                    // 34px
                }

                .thisIsTheBusao {
                    .timeLineBusao {
                        height: 35px;
                        position: absolute;
                        padding-right: 0px;

                        .iconBusao {
                            position: relative;
                            float: right;

                            &::before {
                                font-size: 34px;
                                margin-left: 0px;
                            }
                        }

                        &.statusPontoProgress-red {
                            border-color: #e7aaad !important;
                        }

                        &.statusPontoProgress-orange {
                            border-color: #f0ad4e !important;
                        }

                        &.statusPontoProgress-green {
                            border-color: #428BCA !important;
                        }
                    }
                }

            }
        }
    }
}
</style>

<style lang="css" scoped>
.SI {
    background-color: #a6cfff;
}

.SC {
    background-color: #f5c2c2;
}

.SP {
    background-color: #abe050;
}

.SJ {
    background-color: #ffd08e;
}

.progress-bar-red {
    background: #E04B4A;
    opacity: 0.4;
}

.progress-bar-orange {
    background: #ff9900 !important;
    opacity: 0.4;
}

.progress-bar-green {
    background: #abe050 !important;
    opacity: 0.4;
}

.opacidadeRota {
    position: absolute !important;
    height: 50px;
    bottom: 0;
}

.font-bold {
    font-weight: bold;
}

.text-move {
    overflow: hidden;
    white-space: nowrap;
}

.text-move-escala span {
    display: inline-block;
    animation: moveText 5s infinite linear;
}

.text-move-linha span {
    display: inline-block;
    animation: moveText 15s infinite linear;
}

@keyframes moveText {
    to {
        transform: translateX(-100%);
    }
}
</style>