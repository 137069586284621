<template>
    <div>
        <modalEagle 
            id='adicionar-cartao' 
            title='Adicionar cartão'
            @modalOpen="modalOpen"
        >
            <slot slot='modalBody'>
                <panelEagle :loading="loadingModal" id="adicionar-cartao-panel">
                    <div class="col-sm-12 row nopadding">
                        <div class="col-sm-12 nopadding">
                            <!-- <inputSimple
                                :mask="['NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN']"
                                :hasMask="true"
                                :value="valueNCartao"
                                label="N° cartão"
                                @changeInput="v => valueNCartao = v"
                            /> -->
                            <selectAll
                                :disabled="loadingNCartao"
                                :loading="loadingNCartao"
                                ref="refSelectTipoCartao"
                                :labels="[{
                                    indexDFH:'NCA',
                                    description:'N° cartão'
                                }]"
                                @changeSelect="(v, vv, code) => valueNCartao = code"
                                nameForRadio="nameForRadio"
                                :optionsArray="optNumeroCartao"
                            />
                        </div>
                        <div class="col-sm-12 nopadding mt-3">
                            <selectAll
                                ref="refSelectTipoCartao"
                                :labels="[{
                                    indexDFH:'CA',
                                    description:'Tipo do cartão'
                                }]"
                                @changeSelect="v => tipoCartao = v"
                                nameForRadio="nameForRadio"
                                :optionsArray="optTipoCartao"
                            />
                        </div>
                    </div>
                </panelEagle>
            </slot>
            <slot slot='modalFooter'>
                <simpleButton
                    :disabled="loadingModal"
                    text='Cancelar'
                    type='red'
                    :icon='mdiCloseThick'
                    event='click'
                    @click="() => { 
                        $bvModal.hide('adicionar-cartao') 
                    }"
                />
                <simpleButton
                    :disabled="loadingModal||$v.$invalid"
                    text='Confirmar'
                    type='blue'
                    :icon='mdiCheckBold'
                    event='click'
                    @click="confirmButton"
                />
            </slot>
        </modalEagle>
    </div>
</template>
<script>
import Vue from 'vue'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { mdiCheckBold, mdiCloseThick } from '@mdi/js'
import { required, minLength } from 'vuelidate/lib/validators'
import { conectionError } from '@/Services/Helpers/swellHeper'
export default Vue.extend({
    name:'modalAdicionarCartao',
    components:{
        simpleButton:require('@/components/Atom/Buttons/SimpleButton').default,
        panelEagle:require('@/components/Atom/Panel/PanelEagle.vue').default,
        // inputSimple:require('@/components/Atom/Inputs/InputSimple').default,
        modalEagle:require('@/components/Atom/Modal/ModalEagle').default,
        selectAll:require('@/components/Atom/Select/SelectAll').default,
    },

    validations:{
        tipoCartao:{ 
            required, 
            minLength:minLength(1) 
        },
        valueNCartao:{ 
            required, 
        },
    },

    props:{
        placa:{
            type:String,
            required:true,
        },

        empresaSelecionada:{
            type: Array,
            required:true,
        }
    },

    data() {
        return {
            valueNCartao:'',
            tipoCartao:[],
            optNumeroCartao:[],
            optTipoCartao:[
                { 
                    value:'OP', description:'Operador'
                },
                {
                    value:'GT', description:'Gestor'
                }
            ],
            urlBase: '/administrativo/gestao/cartoes/rfid',
            loadingModal:false,
            loadingNCartao:false,
            mdiCheckBold:mdiCheckBold,
            mdiCloseThick:mdiCloseThick,
        }
    },

    methods: {
        confirmButton(){
            this.loadingModal = true
            new HttpRequest().Post(`${this.urlBase}/salvar/cartao`, {
                ncartao:this.valueNCartao,
                tipo:this.tipoCartao[0],
                placa:this.placa,
            }).then(({ data }) => {
                if(data.erro){
                    conectionError(
                        "Contate o suporte.", 
                        "warning",
                        data.erro
                    )
                }else{
                    let existe = Object.values(data.dados.dados)
                    if(!existe.length){
                        this.toastShow(data.dados.message, 'warning')
                    }else{
                        let cond = existe[0].Sucesso?'success':'warning'
                        let tipo = this.tipoCartao[0] == 'OP' ? 'operador' : 'gestor'
                        let msg = `
                            Comando para gravar cartão de ${tipo} 
                            foi enviado para fila de comandos, 
                            aguarde retorno na tabela
                        `
                        this.toastShow(
                            msg, cond
                        )
                        this.$bvModal.hide('adicionar-cartao')
                        this.$emit('atualiza')
                    }
                }
            }).catch(() => {
                conectionError()
            }).finally(() => {
                this.loadingModal = false
            })
        },

        modalOpen(){
            // @ts-ignore:next-line
            if(this.$refs.refSelectTipoCartao){
                this.$refs.refSelectTipoCartao.clearAll()
            }
            this.valueNCartao = ''
            this.tipoCartao = []
            this.$nextTick(() => {
                this.pegaCartao()
            })
        },
    
        pegaCartao(){
            this.optNumeroCartao = []
            this.loadingNCartao = true
            new HttpRequest().Post(
                `${this.urlBase}/pega/cartao`, {
                    empresaSelecionada:this.empresaSelecionada
            }).then(({ data }) => {
                this.optNumeroCartao = data
            }).catch(() => {
                conectionError()
            }).finally(() => {
                this.loadingNCartao = false
            })
        },
        
        toastShow(msg, type){
            this.$bvToast.toast(msg, {
                autoHideDelay: 2500,
                variant: type,
            })
        },
    },
})
</script>
<style lang="scss">
    
</style>