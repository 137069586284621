<template>
    <panelEagle id='listarUsuariosApp' :loading="loadingPanel">
        <div class='col-sm-12 nopadding  '>
            <div class="col-sm-12 nopadding row ">
                <div class="col-sm-6">
                    <tituloPage titulo='Colaboradores' :icon='mdiAccountSupervisor'></tituloPage>
                </div>
                <div class="col-sm-6 divDontPrint">
                    <botoesEIN
                        :loading="loadingExporta"
                        tipoNovo="button"
                        linkNew='cadastrarMotorista'
                        @exportarCadastro='exportarColboradores'
                        :disabledDropdown='$v.dataTable.$invalid'>
                    </botoesEIN>
                </div>
                <div class="col-sm-12"><hr></div>
                <div class="col-sm-3 nopadding divDontPrint">
                   <selectAll
                        :selected="selectedEmpresa"
                        nameForRadio='FiltroEmpresas'
                        :labels="labels"
                        :isMultiple='true'
                        :optionsArray='optSelectEmpresas'
                        :hasSelectAll='true'
                        @changeSelect='selecionaEmpresa'/>
                </div>
                <div class="col-sm-9 row nopadding divBotoes divDontPrint">
                    <div class="col-sm-5 nopadding row">
                        <buttonsFilters
                            label="Status" 
                            :onlyOneSelected='true'
                            :arrayButtons='filtroStatus'
                            @buttonsFiltersChange='mudaFiltroStatus'>
                        </buttonsFilters>
                    </div>
                    <div class="col-sm-7 nopadding row">
                        <buttonsFilters
                            label="Listar" 
                            :onlyOneSelected='true'
                            :arrayButtons='filtroListar'
                            @buttonsFiltersChange='mudaFiltroListar'>
                        </buttonsFilters>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <tableListagem
            :loading='loadingTableListagem'
            :titles='titulosTable'
            :data='dataTable'
            deleteEdit='editStatus'
            @alterarStatus ='alterarStatus'
            @editarCadastro='editarColaborador'>
        </tableListagem>
    </panelEagle>
</template>

<script>
import {mdiAccountSupervisor, mdiCheckBold } from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import Vue from 'vue'
// import { filter } from 'vue/types/umd';
export default Vue.extend({
    validations:{
        dataTable: { required },
    },
    components:{
        'panelEagle'     : require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage'     : require('@/components/Atom/Header/Titulo').default,
        'botoesEIN'      : require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
        'selectAll'      : require('@/components/Atom/Select/SelectAll').default,
        'buttonsFilters' : require('@/components/Atom/Buttons/ButtonsFilters').default,
        'tableListagem'  : require('@/components/Atom/Table/TableListagem').default,
    },
    data(){
        return{
            firstOne:[],
            loadingExporta:[false, false, false],
            mdiAccountSupervisor:mdiAccountSupervisor,
            mdiCheckBold        : mdiCheckBold,
            loadingPanel:false,


            // melhorias
            // @author Lucas Eduardo
            // data: 10/03/2021
            loadingTableListagem:false,


            // value
            valueFiltroListar:[],
            valueFiltroStatus:[],
            valueEmpresasSelecionadas:[],
            // opt
            optSelectEmpresas: [],
            selectedEmpresa:[],
            // Botões/titulos
            filtroStatus:[
                {'value':'A',  'width': '31.8%', 'text': 'Ativos',   'isSelected':true,  icon:mdiCheckBold},
                {'value':'I',  'width': '31.8%', 'text': 'Inativos', 'isSelected':false, 'icon':''},
                {'value':'T',  'width': '31.8%', 'text': 'Todos',    'isSelected':false, 'icon':''},
            ],
            filtroListar:[
                {'value':'T',  'width': '22%', 'text': 'Todos',          'isSelected':true,  icon:mdiCheckBold},
                {'value':'M',  'width': '22%', 'text': 'Motoristas',     'isSelected':false, 'icon':''},
                {'value':'A',  'width': '22%', 'text': 'Ajudantes',      'isSelected':false, 'icon':''},
                {'value':'AD', 'width': '27%', 'text': 'Administrativo', 'isSelected':false, 'icon':''}
            ],
            titulosTable:[
                {'name' : 'Nome',         'number' : 'mtnome'  },
                {'name' : 'Crachá',       'number' : 'mtcracha'  },
                {'name' : 'Telefone',     'number' : 'mttelefone'},
                {'name' : 'CNH',          'number' : 'mtcnh'},
                {'name' : 'Validade CNH', 'number' : 'mtcnhvalidade'},
                {'name' : 'Tipo',         'number' : 'mtperfil'},
                {'name' : 'Empresa',      'number' : 'mtclfantasia'},
            ],
            labels:[
                {indexDFH     : 'EM', 
                 description  : 'Empresas'}
            ],
            // links
            linkExportColab   :'/administrativo/cadastros/colaboradores/exportar',
            linkListarColab   :'/administrativo/cadastros/colaboradores/listar',
            linkAlterarStatus :'/administrativo/cadastros/colaboradores/mudarStatus',
            // stuff
            dataTable:[],
        }
    },
    methods:{
        // novoCadastro(){
        //     this.$router.push('/administrativo/cadastros/motoristas/cadastrar')
        // },
        editarColaborador(value){
            this.$router.push({name:'cadastrarMotorista', params:{id:value}})
        },
        mudaFiltroListar(value){
            if(value[0] == 'T'){
                this.valueFiltroListar = ['MA', 'M', 'AD', 'A'];
            }else if(value[0] == 'M'){
                this.valueFiltroListar = ['MA', 'M'];
            }else{
                this.valueFiltroListar = value;
            }
            this.filtroListar[0].icon = ''
            this.filtroListar[1].icon = ''
            this.filtroListar[2].icon = ''
            this.filtroListar[3].icon = ''
            if(value[0] == 'T'){
                this.filtroListar[0].icon = mdiCheckBold
            } else if(value[0] == 'M'){
                this.filtroListar[1].icon = mdiCheckBold
            } else if(value[0] == 'A'){
                this.filtroListar[2].icon = mdiCheckBold
            } else if(value[0] == 'AD'){
                this.filtroListar[3].icon = mdiCheckBold
            }
            this.ListarColaborador()
        },
        mudaFiltroStatus(value){
            if(value[0] == 'T'){
                this.valueFiltroStatus = ['A', 'I'];
            }else{
                this.valueFiltroStatus = value;
            }
            this.filtroStatus[0].icon = ''
            this.filtroStatus[1].icon = ''
            this.filtroStatus[2].icon = ''
            if(value[0] == 'A'){
                this.filtroStatus[0].icon = mdiCheckBold
            } else if(value[0] == 'I'){
                this.filtroStatus[1].icon = mdiCheckBold
            } else if(value[0] == 'T'){
                this.filtroStatus[2].icon = mdiCheckBold
            }
            this.ListarColaborador()
        },
        selecionaEmpresa(value){
            this.valueEmpresasSelecionadas = value;
            this.ListarColaborador();
        },
        
        async carregarJornadas(){
        },
        async ListarColaborador(){
            this.loadingPanel = true
            this.limpaDados();
            if(this.valueEmpresasSelecionadas.length > 0){
                var obj = {
                    'empresas': this.valueEmpresasSelecionadas,
                    'status'  : this.valueFiltroStatus,
                    'listar'  : this.valueFiltroListar
                }; 
                var data = await new HttpRequest().Post(this.linkListarColab, obj);
                if(data.code == 201){
                    this.arrumaColaboradores(data.data.colaboradores)
                } else {
                    this.dataTable = []
                }
            }
            this.loadingPanel = false
        },
        arrumaColaboradores(data){
            data.forEach(colaborador => {
                colaborador['codigo'] = colaborador['mtcodigo']+''
                colaborador['status'] = colaborador['mtstatus'];
                colaborador['mtclfantasia'] = colaborador.clfantasia;
                if(colaborador['mtperfil'] == 'M'){
                    colaborador['mtperfil'] ='Motorista'
                }else if(colaborador['mtperfil'] == 'A'){
                    colaborador['mtperfil'] = 'Ajudante';
                }else if(colaborador['mtperfil'] == 'AD'){
                    colaborador['mtperfil'] = 'Administrativo';
                }else{
                    colaborador['mtperfil'] = 'Motorista/Ajudante'
                }
            });
            this.dataTable = data;
        },
        limpaDados(){
            this.dataTable = [];
        },
        async exportarColboradores(value){
            switch (value) {
                case 'pdf': this.loadingExporta = [true, false, false]; break;
                case 'xls': this.loadingExporta = [false, true, false]; break;
                default: this.loadingExporta = [false, false, true]; break;
            }
            var root =  process.env.VUE_APP_ROOT;
            var obj = {
                tipo:value,
                colaboradores:this.dataTable
            }
            if(this.dataTable.length){
                var data = await new HttpRequest().Post(this.linkExportColab, obj);
                window.open(root+'/'+data.data.local); 
            }
            this.loadingExporta = [false, false, false]
        },
        async alterarStatus(value){
            var obj = {codigo:value};
            var data = await new HttpRequest().Post(this.linkAlterarStatus, obj);
            this.ListarColaborador();
        },
        selectAllClientes(){
            this.optSelectEmpresas =  new EmpresasService().Get()
            if(!this.getMaster()){
                this.selectedEmpresa   = this.optSelectEmpresas
            }
        },
        ...mapGetters(['getMaster']),
    },
    mounted(){
        this.selectedEmpresa = this.optSelectEmpresas
        this.selectAllClientes()
    }
})
</script>

<style lang="scss" scoped>
.divBotoes{

    text-align: left;
}

@media print{
    .divDontPrint{
        display: none;
    }
}
</style>