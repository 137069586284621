<template>
    <panelEagle id="Alerta" :loading="loadingPanel">
        <div class="col-sm-12 nopadding">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiAlert" titulo="Alertas"></tituloPage>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-6 divDontPrint">
                    <botaoRelatorio
                        :disabled="$v.veiculos.$invalid"
                        :loading="loadingExporta"
                        @gerarRelatorio="gerarRelatorio"
                        @exportarRelatorio="exportarRelatorio"
                        :disabledDropdown="$v.dadoAlertas.$invalid"
                    >
                        <slot slot="exportacao">
                            <b-dropdown-item
                                @click="exportarRelatorio('resumo')"
                                class="exportPdfPortaria"
                            >
                                <BaseIcon v-if="!loadingExporta[3]" size="18" :icon="iconXls" />
                                <b-spinner v-if="loadingExporta[3]" small label="Exportando" />
                                Resumo
                            </b-dropdown-item>
                        </slot>
                    </botaoRelatorio>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12">
                        <hr />
                    </div>
                    <div class="col-sm-12 row nopadding">
                        <div class="col-sm-3 nopadding">
                            <inputRangeWithLimit
                                opens=""
                                id="Intervalo2"
                                name="Intervalo2"
                                @changeInput="changeData"
                                :isObrigatorio="false"
                            />
                        </div>
                        <div class="col-sm-3 nopadding">
                            <SelectAll
                                nameForRadio="empresas"
                                :isMultiple="true"
                                :optionsArray="optEmpresas"
                                :labels="labelsEmp"
                                :hasSelectAll="true"
                                :selected="selectedEmpresa"
                                @changeSelect="changeEmpresa"
                            />
                        </div>
                        <div class="col-sm-3 nopadding">
                            <SelectAll
                                ref="refVeiculos"
                                nameForRadio="veiculos"
                                :disabled="!valueEmpresa.length"
                                @changeCheck="checaBotao"
                                :isMultiple="true"
                                :labels="labelsVei"
                                :optionsArray="optVeiculos"
                                :hasSelectAll="true"
                                firstSelected="V"
                                :loading="loadingVeiculos"
                                @changeSelect="changeSelectVeiculos"
                            />
                        </div>
                        <div class="col-sm-3 nopadding">
                            <SelectAll
                                nameForRadio="categoria"
                                ref="categoria"
                                :isMultiple="true"
                                :labels="labelsCat"
                                :optionsArray="optionsArray"
                                :hasSelectAll="true"
                                @changeSelect="checkCategoria"
                            />
                        </div>
                        <div class="col-sm-2 mt-1 nopadding">
                            <SelectAll
                                nameForRadio="tipoAlerta"
                                ref="tipoAlerta"
                                :disabled="!valueCategoria.length"
                                :isMultiple="true"
                                :labels="labelsTip"
                                :optionsArray="tipos"
                                :hasSelectAll="true"
                                @changeSelect="tipoAlerta"
                            />
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
        </div>
        <div class="col-sm-12 col-md-12">
            <hr />
        </div>
        <tableRelatorio>
            <slot slot="thead">
                <tr class="">
                    <th></th>
                    <th class="cor-fundo">Tipo alerta</th>
                    <th class="cor-fundo">Informação do alerta</th>
                    <th class="cor-fundo">Lido por</th>
                    <th class="cor-fundo">Quantidade Tratativas</th>
                    <th class="cor-fundo">Localização</th>
                    <th class="cor-fundo">Data evento</th>
                </tr>
            </slot>
            <slot slot="tbody">
                <br />
                <statusInformation
                    class="status-bar"
                    v-show="dadoAlertas.length == 0"
                    typeBar="tr"
                    :statusBar="statusbar"
                />
                <template class="backColorTdPDF" v-for="(comeco, placa) in dadoAlertas">
                    <tr :key="placa+'__tr'" class="backColorTdPDF mt-5">
                        <td colspan="7" class="nopadding">
                            <span class="newbadge badge__style">
                                <span class="badge__placa badge__padding">
                                    {{ placa }}
                                </span>
                                <div class="badge__tag" v-html="retornaTags(comeco)" />
                                <span class="badge__totalAlertas badge__padding">
                                    {{ totalAlertas(comeco) }} alertas
                                </span>
                            </span>
                        </td>
                    </tr>
                    <template v-for="(dados, indice) in comeco" class="text-aling-left">
                        <tr
                            v-if="indice != 'totalAlertas'"
                            :key="dados.talcodigo"
                            class="sem-borda text-aling-left corFundoRelatorio"
                        >
                            <td v-if="dados['tratativas'] > 0">
                                <b-icon
                                    v-b-toggle="dados['id'] + '' + dados['alcodigo'] + indice"
                                    :icon="dados.clicado ? 'chevron-down' : 'chevron-right'"
                                    class="cursor-pointer"
                                    style="font-weight: bold"
                                    @click="abreCollapse(dados)"
                                />
                            </td>
                            <td v-else></td>
                            <td>{{ dados["tadescricao"] }}</td>
                            <td>
                                <BaseIcon
                                    class=""
                                    :icon="decideIcone(dados['taicone'])"
                                    :size="25"
                                />
                                {{ dados["alinfoalerta"] }}
                            </td>
                            <td>
                                <div v-if="dados['alstatus'] == 'L'">
                                    {{ dados["nomeUsuarioQueLeuAlerta"] }}
                                </div>
                            </td>
                            <td>{{ dados["tratativas"] }}</td>
                            <td>
                                <div
                                    style="display: inline-block"
                                    @click="
                                        abreMapa(
                                            dados,
                                            dados['id'] + '' + dados['alcodigo'] + indice
                                        )
                                    "
                                >
                                    <BaseIcon
                                        v-b-toggle="'collapse-' + placa + indice"
                                        :size="25"
                                        class="cursor-pointer mira"
                                        :icon="mdiTarget"
                                    />
                                </div>
                                {{ dados["endereco"] }}
                            </td>
                            <td>{{ dados["datahora"] }}</td>
                        </tr>
                        <tr :key="dados.talcodigo" class="sem-borda corFundoRelatorio">
                            <td colspan="7" class="sem-borda p-0">
                                <table
                                    v-if="dados['mostraMapa']"
                                    class="mapa"
                                    :key="dados.talcodigo"
                                >
                                    <tbody>
                                        <tr :key="dados.talcodigo">
                                            <td>
                                                <mapaSimples
                                                    name="mapa"
                                                    :latitude="Number(dados.lat)"
                                                    :longitude="Number(dados.lon)"
                                                    :key="dados.talcodigo"
                                                    :ref="placa"
                                                    :height="300"
                                                    class="mapa"
                                                >
                                                    <mapMarker
                                                        :key="`${dados.talcodigo}_marker`"
                                                        :latitude="Number(dados.lat)"
                                                        :longitude="Number(dados.lon)"
                                                        :isDraggable="false"
                                                    >
                                                    </mapMarker>
                                                </mapaSimples>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <tr class="row sem-borda" style="border-bottom: 1px solid black">
                                    <b-collapse
                                        :id="dados['id'] + '' + dados['alcodigo'] + indice"
                                        class="row p-0 col-sm-12 sem-borda"
                                    >
                                        <table class="marging-bot sem-borda tableBorderCollapse">
                                            <thead class="">
                                                <tr>
                                                    <th colspan="1" class="">Tratativa</th>
                                                    <th colspan="1" class="">Realizado por</th>
                                                    <th colspan="1" class="">Data</th>
                                                </tr>
                                            </thead>

                                            <tbody
                                                class="col-sm-12 cor-tbo sem-borda"
                                                v-for="(tratativa, index) in dados[
                                                    'todasAsTratativas'
                                                ]"
                                                :key="tratativa.taldataevento + '|' + index"
                                            >
                                                <tr class="sem-borda">
                                                    <td colspan="1" class="p-0">
                                                        <span class="bordaDadosCollapse p-1">
                                                            {{
                                                                dados["todasAsTratativas"][index][
                                                                    "taldescritivo"
                                                                ]
                                                            }}
                                                        </span>
                                                    </td>
                                                    <td colspan="1" class="text-aling-left p-0">
                                                        <span class="bordaDadosCollapse p-1">
                                                            {{
                                                                dados["todasAsTratativas"][index][
                                                                    "nomeUsuarioQueLeuTratativa"
                                                                ]
                                                            }}
                                                        </span>
                                                    </td>
                                                    <td colspan="1" class="text-aling-left p-0">
                                                        <span class="bordaDadosCollapse p-1">
                                                            {{
                                                                dados["todasAsTratativas"][index][
                                                                    "taldataevento"
                                                                ]
                                                            }}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </b-collapse>
                                </tr>
                            </td>
                        </tr>
                    </template>
                </template>
            </slot>
        </tableRelatorio>
    </panelEagle>
</template>
<script lang="js">
import Vue from 'vue'
import {
    mdiAlert,
    mdiFileExcelOutline,
    mdiTarget,
    mdiMapMarkerPath,
    mdiCarEmergency,
    mdiGaugeFull,
    mdiWifiOff,
    mdiCarTractionControl,
    mdiCarOff,
    mdiMapMarkerRadius,
    mdiBatteryOffOutline,
    mdiCarChildSeat,
    mdiCarWash,
    mdiCallSplit,
    // mdiAlert,
    // mdiAlert,
    mdiSpeedometer,
    mdiSeatbelt,
    mdiCarSpeedLimiter,
    mdiCarConnected,
    mdiCarBrakeTemperature,
    mdiCreditCardWirelessOffOutline,
    mdiTruckAlertOutline,
} from '@mdi/js';
import Vuex from '@/store/index'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { EmpresasService } from '../../../Services/auth/Empresas.service'
import { HttpRequest } from '../../../Services/auth/HttpRequest.Service'
import { IconManager } from '../../MapaPlus/Alertas/Alertas.ts'
import { required } from 'vuelidate/lib/validators'
import { conectionError } from '@/Services/Helpers/swellHeper'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name: "RelatorioAlertas",
    components: {
        'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage': require('@/components/Atom/Header/Titulo').default,
        'botaoRelatorio': require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
        'BaseIcon': require ('@/components/Atom/Icon/BaseIcon.vue').default,
        'inputRangeWithLimit': require('@/components/Atom/Datas/InputRangeWithLimit').default,
        'mapaSimples': require('@/components/Atom/Mapa/MapaSimples').default,
        'mapMarker': require('@/components/Atom/Mapa/MarkerPoint').default,
        'SelectAll': require('@/components/Atom/Select/SelectAll').default,
        'slideUpAndDown': require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
        'tableRelatorio': require('@/components/Atom/Table/TableRelatorio').default,
        'statusInformation': require('@/components/Atom/StatusInformation/StatusInformation').default,
    },
    validations: {
        veiculos: { required },
        valueEmpresa: { required },
        dadoAlertas: { required },
    },
    data() {
        return {
            titleAlerta: '',
			iconXls: mdiFileExcelOutline,

            mdiAlert: mdiAlert,
            mdiTarget: mdiTarget,
            mdiGaugeFull: mdiGaugeFull,
            mdiMapMarkerPath: mdiMapMarkerPath,
            mdiCarEmergency: mdiCarEmergency,
            mdiWifiOff: mdiWifiOff,
            mdiCarTractionControl: mdiCarTractionControl,
            mdiCarOff: mdiCarOff,
            mdiMapMarkerRadius: mdiMapMarkerRadius,
            mdiBatteryOffOutline: mdiBatteryOffOutline,
            mdiCarChildSeat: mdiCarChildSeat,
            mdiCarWash: mdiCarWash,
            mdiCallSplit: mdiCallSplit,
            // mdiAlert:    //mdiAlert,
            // mdiAlert:    //mdiAlert,
            mdiSpeedometer: mdiSpeedometer,
            mdiSeatbelt: mdiSeatbelt,
            mdiCarSpeedLimiter: mdiCarSpeedLimiter,
            mdiCarConnected: mdiCarConnected,
            mdiCarBrakeTemperature: mdiCarBrakeTemperature,
            mdiCreditCardWirelessOffOutline: mdiCreditCardWirelessOffOutline,
            mdiTruckAlertOutline: mdiTruckAlertOutline,

            url: '/performance/relatorios/alertas',
            loadingPanel: false,
            loadingExporta: [false, false, false, false],
            refVeiculos: '',
            valueCategoria: [],
            mostrarCarregando: false,
            labelsEmp: [
                {
                    indexDFH: 'EM',
                    description: 'Empresas*'
                }
            ],
            labelsVei: [
                {
                    indexDFH: 'V',
                    description: 'Veículos'
                },
                {
                    indexDFH: 'GV',
                    description: 'Grupos'
                },
                {
                    indexDFH: 'TAG',
                    description: 'Tags'
                }
            ],
            labelsCat: [{
                indexDFH: 'CAT',
                description: 'Categoria'
            }],
            labelsTip: [{
                indexDFH: 'TIP',
                description: 'Tipo Alerta'
            }],
            optionsArray: [
                { value: 'vermelho', description: 'Grave' },
                { value: 'amarelo', description: 'Atenção' }
            ],
            optEmpresas: new EmpresasService().Get(),
            tipoVeiculos: 'V',
            statusbar: 'info',
            htmlAlertas: '',
            data: '',
            optVeiculos: [],
            tipos: [],
            valueEmpresa: [],
            veiculos: [],
            grupoVeiculos: [],
            tipoAlertaVar: [],
            listaVeiculos: [],
            consulta: [],
            dadoAlertas: [],
            clica: [],
            selectedEmpresa: [],
            loadingVeiculos: false,
        }
    },
    methods: {

        retornaTags(dados) {
            let html = ''
            for (const key in dados) {
                if(dados[key].tags != undefined) {
                    let tags = JSON.parse(dados[key].tags)
                    tags = _.orderBy(tags, ['descricao'], ['asc'])
                    for (const k in tags) {
                        html += `
                        <div
                            class="tag-style"
                            title="${tags[k].descricao}"
                            style="
                                background-color:${tags[k].cor};
                            "
                        >
                            ${tags[k].descricao}
                        </div> `
                    }
                    return html
                }
            }
        },

        abreMapa(dados, id) {
            if (!dados["endereco"])
                return;
            dados['mostraMapa'] = !dados['mostraMapa']
            if(dados.clicado) {
                this.$root.$emit('bv::toggle::collapse', id)
                dados["clicado"] = false
            }
        },

        abreCollapse(dados) {
            dados['clicado'] = !dados['clicado'];
            dados['mostraMapa'] =
                dados['mostraMapa']
                    ? !dados['mostraMapa']
                    : dados['mostraMapa'];
        },


        ...mapGetters(['getMaster']),

        changeEmpresa(selecionados) {
            this.limpaRelatorio()
            this.valueEmpresa = selecionados;
            this.getVeiculos(selecionados);
        },

        limpaRelatorio() {
            this.$refs.refVeiculos.clearAll()
            this.$refs.categoria.clearAll()
            this.$refs.tipoAlerta.clearAll()
            this.listaVeiculos = []
            this.grupoVeiculos = []
            this.optVeiculos = []
            this.dadoAlertas = []
            this.valueCategoria = []
            this.tipoAlertaVar = []
            this.statusbar = 'info'
        },

        async getVeiculos(value) {
            var dados = [];
            this.loadingVeiculos = true
            new FiltrosService()
                .dados_filtros(value, ['V', 'GV', 'TAG'])
                .then((dados) => {
                    this.listaVeiculos = dados
                    this.optVeiculos = this.listaVeiculos[this.tipoVeiculos]
                })
            this.loadingVeiculos = false
        },

        checaBotao(value) {
            this.tipoVeiculos = value
            if (this.listaVeiculos[this.tipoVeiculos])
                this.optVeiculos = this.listaVeiculos[this.tipoVeiculos];
            this.getVeiculos(this.valueEmpresa)
        },

        changeData(value) {
            this.data = value
            this.dadoAlertas = []
            this.statusbar = 'info'
        },

        async checkCategoria(value) {
            this.valueCategoria = value
            this.$refs.tipoAlerta.clearAll()
            this.dadoAlertas = []
            this.statusbar = 'info'
            var tiposAlertas = await new HttpRequest()
                .Post('/telemetria/relatorios/alertas/tipos', { 'tipos': value });
            this.tipos = tiposAlertas.data.data
        },

        tipoAlerta(value) {
            this.tipoAlertaVar = value
            this.dadoAlertas = []
            this.statusbar = 'info'
        },

        async exportarRelatorio(type) {
            this.loadingExportacao(type);
            let tipo = {
                'tipo': type,
                'arraydados': this.dadoAlertas,
                'data': this.data,
                'empresas': this.valueEmpresa,
                'veiculos': this.veiculos,
                'alertas': this.tipoAlertaVar,
                'tipoVeiculos': this.tipoVeiculos,
            }
            let root = process.env.VUE_APP_ROOT;
            new HttpRequest().Post(this.url + '/exportar', tipo)
                .then((exporta) => {
                    window.open(root + '/' + exporta.data.exportacao);
                }).catch(() => {
                    conectionError()
                }).finally(() => {
                    this.loadingExporta = [false, false, false, false]
                })
        },

        loadingExportacao(type) {
            switch (type) {
                case "pdf":
                    this.loadingExporta = [true, false, false, false]
                    break;
                case "xls":
                    this.loadingExporta = [false, true, false, false]
                    break;
                case "csv":
                    this.loadingExporta = [false, false, true, false]
                    break;
                case "resumo":
                    this.loadingExporta = [false, false, false, true]
                    break;
                default:
                    break;
            }
        },

        changeSelectVeiculos(value) {
            this.veiculos = value
            this.dadoAlertas = []
            this.statusbar = 'info'
        },

        gerarRelatorio() {
            this.loadingPanel = true
            this.dadoAlertas = []
            let obj = {
                data: this.data,
                cliente: this.valueEmpresa,
                veiculos: this.veiculos,
                tipoVeiculos: this.tipoVeiculos,
                categoria: this.valueCategoria,
                alerta: this.tipoAlertaVar
            }
            new HttpRequest().Post(this.url + '/gerar/relatorio', obj)
                .then((dados) => {
                    this.dadoAlertas = dados.data.relatorio
                    if(this.dadoAlertas.length == 0)
                        this.statusbar = "error"
                })
                .catch((error) => {
                    conectionError()
                })
                .finally(() => {
                    this.loadingPanel = false
                })
        },

        totalAlertas(dados) {
            return dados['totalAlertas']
        },

        /**
         * Pega o codigo do
         * tipo de alerta e devolve um icone
         * @param {int} alerta
        */
        decideIcone(alerta) {
			return IconManager.getIconeAlerta(alerta)
        },
    },
    mounted() {
        if (!this.getMaster()) {
            this.selectedEmpresa = this.optEmpresas
        }
    }
})
</script>
<style lang="scss">
thead > tr {
    width: 500;
}

.tag-style {
    color: #ffff !important;
    border-radius: 5px 0px 5px 0px;
    // width: 25%;
    padding: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: bold;
}

.badge__tag {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    width: 100%;
    max-width: 500px;
}
.mira:hover {
    color: #4285f4;
}
.badge__padding {
    padding:0 0.5em;
}
.badge__totalAlertas {
    color: #ffff;
    font-weight: bold;
}

.badge__style {
    display:inline-flex;
    min-width:50%;
    justify-content:space-around
}

.badge__placa {
    position:relative;
    left:0%;
}

.sem-borda {
    border: none !important;
}

.sumir-botao {
    border: none;
    background: none;
}

.tamanho {
    width: 240%;
}

.mapa {
    width: 100%;
}

.marging-bot {
    margin-bottom: 20px;
}

.borda {
    border: 1px solid black;
}

.cor-tbo {
    tr {
        background-color: #fff;
    }
}

.teste25 {
    width: 25%;
    background-color: blanchedalmond;
    margin-top: -100px;
}

.uiui {
    color: rgb(5, 5, 5);
    font-size: 20px;
    padding: 0% !important;
    margin: 0% !important;
    border: 0% !important;
}

.text-aling-left {
    text-align: left;

    td {
        vertical-align: middle !important;
    }
}

.cor-fundo {
    font-size: 13px !important;
    text-align: left;
}

.div-conteudo {
    width: 100%;
}

.corFundoRelatorio {
    td {
        background: #efefef !important;
    }

    th {
        background: #f5f5f5 !important;
    }
}

.tableBorderCollapse {
    border: none;

    tbody {
        tr {
            td {
                background: #fff !important;
            }
        }
    }
}

.bordaDadosCollapse {
    font-size: 12px;
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #efefef !important;
}
</style>
