<template>
    <div class="col-sm-12 row nopadding mt-3">
        <b-tooltip target="validador" v-if="$v.livalidador.$anyError" triggers="hover">
            Validador tem de ser <strong>numérico</strong>
        </b-tooltip>
        <b-tooltip target="itinerario" v-if="$v.liitinerario.$anyError" triggers="hover">
            Itinerário tem de ser <strong>numérico</strong>
        </b-tooltip>
        <div class="col-sm-12 row nopadding">
            <div class="col-2 nopadding">
                <InputSimple :value="livalidador" @changeInput="mudaValidador" name="inputValidador" id="validador"
                    label="Validador" />
            </div>
            <div class="col-2 nopadding">
                <InputSimple :value="liitinerario" @changeInput="mudaItinerario" name="inputItinerario" label="Itinerário"
                    id="itinerario" />
            </div>
            <div class="col-sm-3 nopadding">
                <SelectAll :selected="selectedLinhaPrincipal" ref="linhaPrincipal" :loading="controleLoading"
                    :disabled="desativaLinhaPrincipal" nameForRadio="seletorLinhaPrincipal"
                    :labels="[{ description: desativaLinhaPrincipal ? ('Linha Principal') : ('Linha Principal *') }]"
                    :isMultiple="false" :optionsArray="optLinhaPrincipalProp" @changeSelect="mudaLinhaPrincipal" />
            </div>
            <div class="col-sm-2 nopadding">
                <InputColor :value="licor" @changeInput="mudaCor" name="inputCorLinha" label="Cor" />
            </div>
            <div class="col-sm-3 nopadding">
                <InputSimple :value="liatrasotolerado" @changeInput="mudaAtraso" name="inputAtrasoTolerado"
                    label="Atraso Tolerado*" type="time" />
            </div>
        </div>
        <div class="col-sm-12 nopadding mt-3">
            <div class="col-sm-12 subtitle">Descritivo Itinerário</div>
            <textarea v-on:change="textAreaInput(lidescricao)" v-model="lidescricao" name="textDescLinha"
                class="textArea" />
        </div>
    </div>
</template>
<script>
import { mdiContentSavePlusOutline } from '@mdi/js';
import Vue from 'vue'
import { required, numeric } from "vuelidate/lib/validators";

export default Vue.extend({
    name: 'TabsLinhasInformacoesAdicionais',

    components: {
        SelectAll: require("@/components/Atom/Select/SelectAll.vue").default,
        InputSimple: require('@/components/Atom/Inputs/InputSimple.vue').default,
        InputColor: require('@/components/Atom/Inputs/InputColor.vue').default,
    },

    validations: {
        licor: { required },
        livalidador: { numeric },
        lidescricao: { required },
        liitinerario: { numeric },
        liatrasotolerado: { required },
        linhaPrincipal: { required }
    },

    watch: {
        optLinhaPrincipal() {
            this.optLinhaPrincipalProp = this.optLinhaPrincipal
        },

        desativaLinhaPrincipal() {
            this.$refs.linhaPrincipal.clearAll()
        },
    },

    props: {
        optLinhaPrincipal: {
            type: Array,
            required: false,
            default: function () { return [] }
        },

        desativaLinhaPrincipal: {
            type: Boolean,
            default: false,
        },

        controleLoading: {
            type: Boolean,
            default: false
        }

    },

    data() {
        return {
            licor: '#0B7DE0',
            livalidador: '',
            lidescricao: '',
            liitinerario: '',
            linhaPrincipal: '',
            liatrasotolerado: '',
            optLinhaPrincipalProp: [],
            selectedLinhaPrincipal: [],
        }
    },

    methods: {
        arrumaValoresEditar(dados) {
            this.licor = dados.linha.licor
            this.livalidador = dados.linha.livalidador
            this.lidescricao = dados.linha.lidescitinerario
            this.liitinerario = dados.linha.liitinerario
            this.selectedLinhaPrincipal = this.optLinhaPrincipalProp.filter(v => dados.linha.licodigoprincipal == v.value)
            this.linhaPrincipal = dados.linha.licodigoprincipal
            this.liatrasotolerado = dados.linha.liatrasotolerado
            this.emiteValores()
        },

        /**
        * Função para emitir os valores novos ou modificados da linha para o componente pai, CadastroLinha.vue,
        * realiza esse emit para validar essa tab como concluída.
        * @author Desconhecido 
        */
        emiteValores() {
            var obj = {
                'licor': this.licor,
                'livalidador': this.livalidador,
                'lidescricao': this.lidescricao,
                'liitinerario': this.liitinerario,
                'liatrasotolerado': this.liatrasotolerado,
                'linhaPrincipal': this.linhaPrincipal,
            }
            
            this.$emit('valoresTab', obj, 'tab-3');
        },

        limparVariaveis() {
            this.$refs.linhaPrincipal.clearAll()
        },

        /**
        * Função para salvar os dados informados pelo usuário e salva o valor dentro da variável this.livalidador.
        * Após salvar realiza a função this.emiteValores.
        * @Param valur, Array com o dados do Validador.
        * @author Desconhecido 
        */
        mudaValidador(value) {
            this.livalidador = value
            this.emiteValores()
        },

        /**
        * Função para salvar os dados informados pelo usuário e salva o valor dentro da variável this.liitinerario .
        * Após salvar realiza a função this.emiteValores.
        * @Param valur, Array com o dados de Itinerário.
        * @author Desconhecido 
        */
        mudaItinerario(value) {
            this.liitinerario = value
            this.emiteValores()
        },

        /**
        * Função para salvar os dados informados pelo usuário e salva o valor dentro da variável this.linhaPrincipal.
        * Após salvar realiza a função this.emiteValores.
        * @Param valur, Array com o dados da Linha Principal.
        * @author Desconhecido 
        */
        mudaLinhaPrincipal(value) {
            this.linhaPrincipal = value[0];
            this.emiteValores();
        },

        /**
        * Função para salvar os dados informados pelo usuário e salva o valor dentro da variável this.licor.
        * Após salvar realiza a função this.emiteValores.
        * @Param valur, Array com o dados da cor selecionada.
        * @author Desconhecido 
        */
        mudaCor(value) {
            this.licor = value
            this.emiteValores()
        },

        /**
        * Função para salvar os dados informados pelo usuário e salva o valor dentro da variável this.liatrasotolerado.
        * Após salvar realiza a função this.emiteValores.
        * @Param valur, Array com o dados do atrado tolerada para a linha.
        * @author Desconhecido 
        */
        mudaAtraso(value) {
            const arrTime = value.split(':')
            const totalSeconds = arrTime[0] * 3600 + arrTime[1] * 60;
            if (totalSeconds > 0) {
                this.liatrasotolerado = value
                this.emiteValores()
            } else {
                this.liatrasotolerado = ''
                this.emiteValores()
            }
        },

        textAreaInput(value) {
            this.emiteValores()
        }
    }
})
</script>

<style lang="scss">
.textArea {
    width: 98%;
    min-height: 200px;
}
</style>
