import { AuthService } from '@/Services/auth/Auth.Service'
import { RouteConfig } from 'vue-router'
import Relatorio from '../../Children.vue'
import Layout from '../../../components/Atom/Layout/Layout.vue';
import GestaoDeCadeados from '@/views/Cadeados/Gestao/GestaoCadeados.vue'
import Eventos from '@/views/Cadeados/Relatorios/Eventos.vue'

export const ifAuthenticated = (_to: any, _from: any, next: any) => {
	if (new AuthService().isAuthenticated()) {
		next()
		return
	}
  type numero= 'N' | 'E';
  next('/login')
}

export const cadeadosRoutes: RouteConfig = {
	path: '/cadeados',
	name: 'cadeados',
	beforeEnter: ifAuthenticated,
	meta: {
		requiresAuth: true,
		Module: 'cadeados',
		breadcrumbName: ''
	},
	components: { default: Layout },
	children: [
		{
			path: 'gestao',
			name: 'gestaoCadeados',
			meta: {
				Module: 'cadeados',
				breadcrumbName: 'Gestão de cadeados'
			},
			components:  {content:Relatorio},
			children: [
				{
					path: 'cadeados',
					name: 'gestaoDeCadeados',
					meta: {
						Module: 'cadeados',
						breadcrumbName: 'Gestão de cadeados'
					},
					component: GestaoDeCadeados
				},
			],
		},
		{
			path: 'eventos',
			name: 'eventosCadeados',
			meta: {
				Module: 'cadeados',
				breadcrumbName: 'Eventos'
			},
			components:  
      {content:Eventos},
		}
	]
}
