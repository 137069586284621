<template>
    <div id="progress-bar-circle" class="text-center small" backgroundcolor="pink" color="ff0000" style="width: 45px;">
        <div class="value-percent">
            {{value}}%
        </div>
        <svg width="45" height="45" viewBox="0 0 120 120"
            style="transform: rotate(-90deg);">
            <circle cx="60" cy="60" r="54" fill="none" stroke="grey" stroke-width="12"></circle>
            <circle cx="60" cy="60" r="54" fill="none" :stroke="progressColor" stroke-width="12" 
                :stroke-dasharray="strokeCircle" 
                :stroke-dashoffset="strokeCircleOffset" class="progress"></circle>
        </svg>
    </div>
</template>

<script>

export default {
	name: 'ProgressCircle',
	props: {
		value: {
            type: Number,
			required: true
		},
	},
	
	data() {
		return {
            active: this.isOpen,
			layout: {
				height: 45,
				width: 45,
				verticalTextAlign: 36,
				horizontalTextAlign: 10,
				zeroOffset: 5,
				strokeWidth: 12,
				progressPadding: 0,
				type: 'circle'
			},
			text: { 
				color: 'black',
				shadowEnable: false,
				shadowColor: '#000000',
				fontSize: 12,
				fontFamily: 'nexabook',
				dynamicPosition: false,
				hideText: false 
			},
		}
	},
	
	computed: {
		progressColor() {
            if(this.value >= 100) {
                return 'red'
            } else {
                return 'blue'
            }
        },

		strokeCircle() {
			return 2 * Math.PI * 54
		},

		strokeCircleOffset(){
			let value = this.strokeCircle * ((100 - this.value) / 100)
			if(value < 0) value = this.strokeCircle * 100
			return value
		},
	}
}
</script>

<style>
	.value-percent{
		color: black;
		font-size: 12px;
		font-family: nexabook;
		position: absolute;
		line-height: 45px;
		width: inherit;
	}
</style>