/**
    Este é o componente apenas com o botão de delete principalmente usado para modais

    -> Propriedades:
        1- id: Number, String - código do item em questão - EX: 2/'2'

    -> Slot:
        1- Sem Nome: espaço para colocar algo a mais

    -> Eventos:
        1- excluirCadastro - Emite quando o botão de excluir for clicado, sem confirmação de exclusão - Parametros:
            1.1- id - código do cadastro
 */

<template>
    <div>
        <slot></slot>
        <simpleButton 
        v-if="excluirVal" 
        @Click="ExcluirClick(id)" 
        event="Click" 
        type="red" 
        width="50px" 
        :title="title" 
        :icon="icon"></simpleButton>
    </div>
</template>

<script lang="ts">
import {mdiTrashCanOutline} from '@mdi/js'
import Vue from 'vue'
import Vuex from '../../../store/index'
export default Vue.extend({
        components: {
            'simpleButton': require('./SimpleButton').default
        },
        props:{
            title:{
                required : false,
                type     : String,
                default  : 'Excluir'   
            },
            id:{
                type: [Number, String],
                required: true,
                default: 0
            },
            loading:{
                type:Array,
                required: false,
                default: function(){[false, false, false]}
            }
        },
        data() {
            return {
                icon: mdiTrashCanOutline
            }
        },
        methods: {
            ExcluirClick(id:any){
                this.$emit('excluirCadastro', id);
            },
        },
        computed:{
            excluirVal: function(){
                return Vuex.getters.getExcluir
            }
        }
})
</script>

<style lang="scss">
</style>