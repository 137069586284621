<template>
	<div class="d-flex flex-column" id='second-step-2'>
		<div class='second-column d-flex justify-content-around'>
			<div class='align-self-center'>
				<simple-button
				text='Adicionar anexo'
				:disabled='existeAjuste'
				:icon='iconPaper'
				width='200px'
				type='aqua-marine'
				event='click'
				@click='onPickFile'/>
				<div class='filename'>
					{{imgValue}}
				</div>
			</div>
			<div class="align-self-center pl-2">
				<input-horario
				v-if='!jornadaCompleta'
				@intervalo='mudaIntervaloEvento'/>
			</div>
		</div>
		<div class='d-flex flex-column mt-4'>
			<label for='motivo-abono'>
				Motivo Solicitação*:
			</label>
			<textarea
			name='motivo'
			id='motivo-abono'
			cols='17'
			rows='5'
			:disabled='existeAjuste'
			v-model='motivoData'
			@input='mudaMotivo'/>
		</div>
	</div>
</template>

<script>
import { mdiPaperclip } from '@mdi/js'
export default {
	name:'secondStepAbono',
	components: {
		simpleButton: require('@/components/Atom/Buttons/SimpleButton.vue').default,
		inputHorario 	   : require('@/components/Atom/Inputs/inputHorarioInicioFinal.vue').default,
	},
	props:{
		motivo:{
			type: String,
			default:''
		},
		imgValue:{
			type:String,
			default:'',
		},
		existeAjuste:{
			type: Boolean,
			default:false
		},
		jornadaCompleta:{
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			iconPaper:mdiPaperclip,
			motivoData:this.motivo,
		}
	},
	methods: {
		/**Avisa o stepper que o usuário vai mandar um arquivo*/
		onPickFile(){
			this.$emit('pick-file')
		},

		/**
		 * @emits muda-motivo
		 * @description avisa o stepper que o motivo foi alterado.
		 */
		mudaMotivo(){
			this.$emit('muda-motivo', this.motivoData)
		},

		/**
		 * @listens intervalo - componente de inicio final
		 * @param {string[]} arrIntervalo horário de inicio e fim
		 */
		mudaIntervaloEvento(arrIntervalo){
			this.$emit('horarios', arrIntervalo)
		},


	},
	watch:{
		motivo(){
			this.motivoData = this.motivo
		}
	}
}
</script>

<style lang="scss" scoped>
#second-step-2{
	.filestuff{
		border: 1px solid #c3c3c3;
		display: flex;
	}
}
</style>