/**
    Componente do rota manual que monta o html do popup de um marker

    -> Propriedades:
        2 - dados - Object - Informações de um item/pedido da rota

    -> Slot:
        Sem slot

    -> Eventos:
        1 - chamaSelecionaItens - Emite quando clica na div dos detalhes - Parâmetros:
			1.1 - dados
        2 - editaPonto - Emite quando clica no botão de editar ponto - Parâmetros:
			2.1 - dados
        3 - editaParada - Emite quando clica no botão de editar parada - Parâmetros:
			3.1 - dados
        4 - editaPedido - Emite quando clica no botão de editar pedido - Parâmetros:
			4.1 - dados
 */
<template>
	<div class="col-sm-12 row nopadding div-popup">
		<div class="col-sm-12 nopadding mb-3">
			<span style="font-size:14px" 
				v-text="dados.irnome"/>
		</div>
		<template v-if="ehTipoParada">
			<div class="col-sm-12 nopadding mb-2">
				<b>{{ defineTextoTipoParada }}</b>
			</div>
			<div class="col-sm-12 nopadding mb-2">
				<b>Tempo:</b> {{ formataTempoParado }}
			</div>
		</template>
		<template v-else>
			<div class="col-sm-6 nopadding">
				<div v-html='definePeso(dados)'/>
				<div v-html='defineCubagem(dados)'/>
			</div>
			<div class="col-sm-6 nopadding">
				<div v-html='defineVolume(dados)'/>
				<div v-html='defineValor(dados)'/>
				<!-- <div class='campo-pedido'
					v-html='definePedido(dados)'/> -->
			</div>
			<div class="col-sm-12 nopadding mb-3">
				<div class='text-long'
					v-html='defineDocumento(dados)'/>
			</div>
			<div v-if="defineTextoEstimativa" 
				class="col-sm-12 nopadding">
				<b>Estimativa:</b>
				{{ defineTextoEstimativa }}
			</div>
			<div v-if="defineTextoJanelaEntrega" 
				class="col-sm-12 nopadding mb-3">
				<div><b>Janela (agendamento):</b></div>
				<div class="text-long">
					{{ defineTextoJanelaEntrega }}
				</div>
			</div>
		</template>
		<div class="col-sm-4 nopadding">
			<simpleButton
				v-show="dados.selecionado"
				@click="chamaSelecionaItens(dados)"
				event="click"
				width="100%"
				:icon="mdiTrashCanOutline"
				text="Remover"
				type="red"/>
			<simpleButton
				v-show="!dados.selecionado"
				@click="chamaSelecionaItens(dados)"
				event="click"
				width="100%"
				:icon="mdiPlus"
				text="Incluir"
				type="blue"/>
		</div>
		<div class="col-sm-4 nopadding">
			<simpleButton
			@click="acaoEditar(dados)"
			event="click"
			width="100%"
			:icon="mdiPencilOutline"
			:text="ehTipoParada ? 'Parada' : 'Pedido'"
			type="green"/>
		</div>
		<div class="col-sm-4 nopadding">
			<simpleButton
			@click="editaPonto(dados.pocodigo)"
			event="click"
			width="100%"
			:icon="mdiPencilOutline"
			text="Ponto"
			type="green"/>
		</div>
	</div>
</template>
<script>
import simpleButton from '@/components/Atom/Buttons/SimpleButton.vue'
import { converteSegundosEmDataHora, converteSegundosEmHoras } 
	from '@/views/Roteirizador/NaoMenus/RotaManual'
import { DateTime } from "luxon";
import { 
	mdiPencilOutline, mdiPlus, mdiTrashCanOutline, mdiCheckBold, mdiCloseThick 
} from '@mdi/js'
export default {
	props:{
		dados:{
			type: Object,
			required: true,
		},
		
        rota: {
            type: Object
        },
	},

	components:{
		simpleButton,
	},

	data() {
		return {
			mdiPencilOutline: mdiPencilOutline,
			mdiPlus: mdiPlus,
			mdiTrashCanOutline: mdiTrashCanOutline,
			mdiCheckBold: mdiCheckBold,
			mdiCloseThick: mdiCloseThick,

			tempoParado: '',
			tipoParada: [],
            tipoParadaEdicao: [],
            format: 'yyyy-LL-dd HH:mm:ss',
            format2: 'dd/LL/yyyy HH:mm:ss',
            formatBr: 'dd/LL/yy HH:mm',
		}
	},

	methods: {
		/**
		 * @description Emite um evento que indica edição de ponto.
		 * @argument {object} dados
		 */
		chamaSelecionaItens(dados){
			this.$emit('chamaSelecionaItens', dados)
		},

		/**
		 * @description Emite um evento que indica edição de ponto.
		 * @argument {object} dados
		 */
		editaPonto(dados){
			this.$emit('editaPonto', dados)
		},

		/**
		 * @description Emite um evento que indica edição de pedido ou parada.
		 * @argument {object} dados
		 */
		acaoEditar(dados){
			if(this.ehTipoParada) {
				this.$emit('editaParada', dados)
			} else {
				this.$emit('editaPedido', dados)
			}
		},

		/**
		 * @param {number} dados.irpeso
		 * @return {string} peso formatado
		 */
		definePeso({irpeso}){
			return `<b>Peso:</b> ${irpeso} Kg`
		},

		/**
		 * @param {number} dados.ircubagem
		 * @return {string} cubagem formatada
		 */
		defineCubagem({ircubagem}){
			return `<b>Cubagem:</b> ${ircubagem}`
		},

		/**
		 * @param {number} dados.irqtd
		 * @return {string} volume formatado
		 */
		defineVolume({irqtde}){
			return `<b>Volume:</b> ${irqtde ?? '0'} m³`
		},

		// /**
		//  * @param {string} dados.irdocumento
		//  * @return {string} pedido formatado
		//  */
		// definePedido({irdocumento}){
		// 	return `<b>Pedido:</b> ${irdocumento}`
		// },

		/**
		 * @param {string} dados.irvalor
		 * @return {string} valor formatado
		 */
		defineValor({irvalor}){
			return `<b>Valor:</b> R$ ${irvalor}`
		},

		/**
		 * @param {string} dados.irdocumento
		 * @return {string} documento formatado
		 */
		defineDocumento({irdocumento}){
			return `<b>Documento:</b>${irdocumento}`
		},
	},

	computed: {
		/**
		 * @description Define de item rota é do tipo parada
		 * @return {bool}
		 */
		ehTipoParada() {
			return this.dados.irintrajornada || this.dados.irinterjornada
		},

		/**
		 * @description Retorna um texto conforme tipo de parada.
		 * @return {string}
		 */
        defineTextoTipoParada() {
            if(this.ehTipoParada) {
                if(this.dados.irintrajornada){
                    return 'Parada de intervalo (intrajornada)'
                }
                if(this.dados.irinterjornada){
                    return 'Parada de descanso (interjornada)'
                }
            }
            return ''
        },

		/**
		 * @description Retorna um texto no formato de horas hh:mm:ss.
		 * @return {string}
		 */
        formataTempoParado() {
            if(!this.dados.irtempoparado) return ''
            return converteSegundosEmHoras(this.dados.irtempoparado)
        },

		/**
		 * @description Retorna um texto com data e hora referente a janela de entrega.
		 * @return {string}
		 */
        defineTextoJanelaEntrega() {
            if(this.dados.irinijanelaentrega && this.dados.irfimjanelaentrega){
                var janelaIni = DateTime
                    .fromFormat(this.dados.irinijanelaentrega, this.format)
                    .toFormat(this.formatBr)
                var janelaFim = DateTime
                    .fromFormat(this.dados.irfimjanelaentrega, this.format)
                    .toFormat(this.formatBr)
                return janelaIni + ' | ' + janelaFim
            }
            return ''
        },
		
		/**
		 * @description Retorna um texto com data e hora referente a estimativa de 
		 * entrega do pedido.
		 * @return {string}
		 */
        defineTextoEstimativa() {
            if(this.dados.selecionado && this.dados.duracaoseq 
				&& this.rota && this.rota.data && this.rota.hora) {
                var dti = DateTime.fromFormat(`${this.rota.data} ${this.rota.hora}:00`, this.format2)
                let duracaodados = dti.toSeconds() + this.dados.duracaoseq
                return converteSegundosEmDataHora(duracaodados)
            }
            return ''
        },
	},
}
</script>
<style lang="scss">
    .div-popup {
		width: fit-content;
        a {
            color: black !important;
        }
		.campo-pedido{
			word-break: break-all;
			word-wrap: break-word;
		}
    }
</style>
