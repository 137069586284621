<template>
    <panelEagle id='controleManutencao' :loading='loadingPanel'>
        <div class="col-lg-12 row">
            <div class="col-lg-5 nopadding ">
                <titulo titulo='Controle da manutenção' :icon='mdiCalendarClock'/>
            </div>
            <div class="col-sm-7 nopadding" align="right">
                <simpleButton 
					id="kanban" 
					width="120px" 
					:type="listaKanban ? 'green' : 'grey'" 
					:icon="listaKanban ? mdiCheckBold : ''"
					text="Kanban" 
					title="Kanban"
					event="click"
					@click="alteraListaKanban('kanban')">
                </simpleButton>
                <simpleButton 
					id="lista" 
					width="120px" 
					:type="!listaKanban ? 'green' : 'grey'" 
					:icon="!listaKanban ? mdiCheckBold : ''"
					text="Lista" 
					title="Lista"
					event="click"
					@click="alteraListaKanban('lista')">
                </simpleButton>
                <simpleButton 
					id="aplicarFiltros" 
					width="120px" 
					:icon="mdiFilterMenuOutline" 
					type="orange" 
					text="Aplicar filtros" 
					title="Aplicar filtros">
                </simpleButton>
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <!-- Popover Filtros -->
        <div>
            <b-popover
				target="aplicarFiltros"
				triggers="click"
				:show.sync="popoverFiltrosShow"
				placement="top"
				ref="popover"
				@hidden="onHidden">
                <div class="col-sm-12 row nopadding">
                    <div class="col-sm-12 nopadding mt-2">
                        <selectAll 
							ref='empresasControle'
							:isMultiple="true" 
							nameForRadio="empresaControleManutencao" 
							:hasSelectAll="true" 
							:labels="[
								{indexDFH: 'EM', description: 'Empresas'}
							]" 
							firstSelected="EM"
							:optionsArray="optionsEmpresas" 
							:selected="selectedEmpresa"
							@changeSelect="changeEmpresa">
                        </selectAll>
                    </div>
                    <div class="col-sm-12 nopadding mt-2">
                        <selectAll 
						ref='veiculosControle'
						:isMultiple='true' 
						nameForRadio='veiculosControleManutencao' 
						:hasSelectAll='true' 
						:labels='labelSeletorVeiculo' 
						:firstSelected='check'
						:optionsArray='optionsVeiculos' 
						:selected='selectedVeiculos'
						:loading='loadingSelect'
						:disabled='empresaSelecionada.length === 0'
						@changeCheck='mudaCheckVeiculo'
							@changeSelect='changeVeiculos'>
                        </selectAll>
                    </div>
                    <div class="col-sm-12 nopadding mt-2">
                        <inputSimple
							name='nos'
							label='Nº da ordem de serviço'
						:value='valueNos'
						type='number'
						@changeInput='changeNos'/>
                    </div>
                    <div class="col-sm-12 nopadding mt-2">
                        <selectAll 
						ref='TipoManutencaoControle'
						:isMultiple='false' 
						nameForRadio='tipoManutencaoControle' 
						:hasSelectAll='false' 
						:labels="[
							{indexDFH: 'TM', description: 'Tipo manutenção'}
						]" 
						firstSelected="TM"
						:optionsArray="optionsTipoManutencao" 
						:selected="selectedTipoManutencao"
						:loading="loadingTipoManutencao"
						@changeSelect="changeTipoManutencao">
                        </selectAll>
                    </div>
                    <div class="col-sm-12 nopadding mt-2" align='center'>
                        <b-checkbox 
							v-model="checkRealizadas"
							id="realizadas">
                            Visualizar manutenções realizadas
                        </b-checkbox>
                    </div>
                    <div class="col-sm-12 nopadding mt-2" align="center">
                        <simpleButton 
							id="filtrar" 
							width="108px" 
							type="blue" 
							text="Filtrar" 
							title="Filtrar"
							event='click'
							@click='filtrar'>
							</simpleButton>
                        <simpleButton 
						id="fecharFiltros" 
						width="108px" 
						:icon="mdiCloseThick" 
						type="red" 
						text="Fechar" 
						title="Fechar"
						event='click'
						@click='popoverFiltrosShow = false'>
                        </simpleButton>
                    </div>
                </div>
            </b-popover>
        </div>
        <KanbanManutencao 
		:dadosKanban     ="dadosKanban"
		:checkRealizadas ="checkRealizadas"
		:listaKanban     ="listaKanban"
		:statusbar       ='statusBar'
		@onDrop          ="onDrop"
		@listarKanban    ="listarKanban"
		@openModalConfirmar ="v => openModalConfirmar(v)"/>

        <ListagemManutencao 
		:dadosKanban     ="dadosKanban"
		:checkRealizadas ="checkRealizadas"
		:listaKanban     ="listaKanban"
		:statusbar       ='statusBar'
		@onDrop          ="onDrop"
		@listarKanban    ="listarKanban"
		@openModalConfirmar ="v => openModalConfirmar(v)"/>
		
        <ModalConclusaoManutencao 
		ref="modalConclusaoManutencao"
		:dadosManutencao ="dadosManutencao"
		@codigoManutencaoCopia = "codigoManutencaoCopia"
		@atualizaKanban="atualizaKanban"/>
		
		<!-- modal confirm Replicar Manutenção -->
		<modalEagle
		@confirmButton="openModalReplicar()"
		id="openModalConfirmCopiarManutencao"
		title="Replicar Manutenção"
		textoConfirmar="Confirmar"
		size="lg"
		class="modal-content">
		<template #modalBody>
			<h5 class="row d-flex justify-content-center">Você deseja replicar essa manutenção?</h5>
		</template>
	</modalEagle>
	
	
		<ModalReplicarManutencao
			ref="modalReplicarManutencao"
		/>






    </panelEagle>
</template>
<script>
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { mdiCalendarClock, mdiFilterMenuOutline, mdiCloseThick, mdiCheckBold } 
	from '@mdi/js'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { mapGetters } from 'vuex'
import Vue from 'vue'
export default Vue.extend({
	name:'controleManutencao',
	components:{
		modalEagle           :   require('@/components/Atom/Modal/ModalEagle').default,
		panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
		titulo: require('@/components/Atom/Header/Titulo').default,
		simpleButton: require('@/components/Atom/Buttons/SimpleButton').default,
		selectAll: require('@/components/Atom/Select/SelectAll').default,
		inputSimple: require('@/components/Atom/Inputs/InputSimple').default,
		ModalConclusaoManutencao : require(
			'@/components/Atom/ModalSpecific/ModalConclusaoManutencao').default,
		ListagemManutencao: require(
			'@/components/Atom/SpecificComponents/ControleManutencao/'+
			'ListagemControleManutencao').default,
		KanbanManutencao: require(
			'@/components/Atom/SpecificComponents/ControleManutencao/'+
			'KanbanControleManutencao').default,
		ModalReplicarManutencao : require('@/components/Atom/ModalSpecific/ModalReplicarManutencao').default,			
	},
	data(){
		return{
			link: '/manutencoes/nao/menus/controle/manutencao/',
			mdiCalendarClock:mdiCalendarClock,
			mdiFilterMenuOutline:mdiFilterMenuOutline,
			mdiCloseThick: mdiCloseThick,
			mdiCheckBold:mdiCheckBold,
			loadingPanel : false,
			optionsEmpresas: new EmpresasService().Get(),
			labelSeletorVeiculo:[
				{indexDFH:'V',  description:'Veiculos'},
				{indexDFH:'GV', description:'Grupos'}
			],
			statusBar : 'info',
			selectedEmpresa: [],
			empresaSelecionada: [],
			optionsVeiculos: [],
			selectedVeiculos: [],
			veiculosSelecionados: [],
			optionsTipoManutencao: [],
			selectedTipoManutencao: [],
			tipoManutencaoSelecionada : [],
			valueNos: '',
			popoverFiltrosShow: false,
			dadosKanban: [],
			atualiza: undefined,
			checkRealizadas: false,
			loadingSelect: false,
			loadingTipoManutencao: false,
			allOpt: [],
			check: 'V',
			dadosManutencao: {},
			drag: {},
			listaKanban: true,
			
			dadosManutencao: {},
      		mhCodigoManutencao: null,

		}
	},

	beforeDestroy(){
		clearInterval(this.atualiza)
	},

	methods:{
		...mapGetters(['getMaster','getEditar']),
		changeEmpresa(empresa){
			this.empresaSelecionada = empresa
			this.$refs.veiculosControle.clearAll()
			this.buscaVeiculos(empresa)
		},

		changeVeiculos(veiculos){
			this.veiculosSelecionados = veiculos
		},

		changeTipoManutencao(tipoManutencao){
			this.tipoManutencaoSelecionada = tipoManutencao
		},

		changeNos(os){
			this.valueNos = os
		},

		mudaCheckVeiculo(check){
			this.check = check
			if(this.allOpt[this.check])
				this.optionsVeiculos = this.allOpt[this.check]
		},

		/** 
		 * Método para listar os dados do kanban assim que a tela é criada
		 * @author Vitor Hugo 🐨
		 */
		async listarKanban(){
			this.loadingPanel = true
			var obj = {
				'empresa': this.empresaSelecionada,
				'veiculo': this.veiculosSelecionados,
				'tipoManutencao' : this.tipoManutencaoSelecionada,
				'os': Number(this.valueNos),
				'check': this.check
			}
			await new HttpRequest().Post(this.link+'listar', obj)
				.then(({data, status, code})=>{
					this.controleIntervalo('criar')
					this.dadosKanban = []
					if(status && code === 200){
						if(Object.values(data.dados).length)
							this.dadosKanban = data.dados
						else
							this.statusBar = 'error'
					} else {
						this.statusBar = 'error2'
					}
				})
				.catch((error) => {
					this.statusBar = 'error2'
				})
				.finally(() => {
					this.loadingPanel = false
				})
		},

		filtrar(){
			this.popoverFiltrosShow = false
			this.atualizaKanban();
		},

		/** 
		 * Método para buscar os veículos do cliente
		 * @param {array} empresa = código da empresa
		 * @author Vitor Hugo 🐨
		 */
		buscaVeiculos(empresa){
			this.loadingSelect = true
			new FiltrosService().dados_filtros(empresa, ['V', 'GV', 'MT'])
				.then((dados)=>{
					this.allOpt = dados
					this.optionsVeiculos = this.allOpt[this.check]
					this.optionsTipoManutencao = dados.MT
					this.loadingSelect = false
				})
		},

		/** 
		 * Método para buscar os tipos de manutenções
		 * @author Vitor Hugo 🐨
		 */
		buscaTipoManutencao(){
			this.loadingTipoManutencao = true
			new FiltrosService().dados_filtros([], ['MT'])
				.then((dados)=>{
					this.optionsTipoManutencao = dados.MT
					this.loadingTipoManutencao = false
				})
		},

		/** 
		 * @listens filtrar|controleIntervalo - emitido quando filtrado 
		 *  dados ou pelo controle de intervalo
		 * Método é chamado para atualizar os dados do kanban a casa 60s
		 * @author Vitor Hugo 🐨
		 */
		async atualizaKanban(){
			if(this.loadingPanel) return;
			this.loadingPanel = true
			var obj = {
				'empresa': this.empresaSelecionada,
				'veiculo': this.veiculosSelecionados,
				'tipoManutencao' : this.tipoManutencaoSelecionada,
				'os': Number(this.valueNos),
				'check': this.check
			}
			await new HttpRequest().Post(this.link+'listar', obj)
				.then(({data, status, code})=>{
					this.dadosKanban = []
					if(status && code === 200){
						if(Object.values(data.dados).length)
							this.dadosKanban = data.dados
						else
							this.statusBar = 'error'
					} else {
						this.statusBar = 'error2'
					}
				})
				.catch((error) => {
					this.statusBar = 'error2'
				})
				.finally(() => {
					this.loadingPanel = false
				})
		},

		/** 
		 * Método para atualizar relatório a cada 60s
		 * @param String func = string com oq deve ser feito
		 * @return Variável atualiza como undefined
		 * @author Vitor Hugo 🐨
		 */
		controleIntervalo(func){
			if(func === 'criar'){
				if(this.atualiza === undefined){
					this.atualiza = setInterval(() => {
						this.atualizaKanban()
					}, 60000);
				}
			}else{
			//destruir intervalo
				if(this.atualiza !== undefined){
					clearInterval(this.atualiza)
					this.atualiza = undefined
				}
			}
		},

		/**
		 * @description Evento de fechar o popover, quando este é executado,
		 * as informações dos filtros são armazenadas em *selectedEmpresa,
		 *  selectedVeiculos, selectedTipoManutencao*,
		 * pois ao fechar e abrir os filtros, deve mostrar as mesmas
		 * informações selecionadas. Não consegui salvar o estado do popover.
		 * @author Rafa 🐈
		 */
		onHidden() {
			this.selectedEmpresa = this.optionsEmpresas
				.filter((e) => this.empresaSelecionada.includes(e.value))
			this.selectedVeiculos = this.optionsVeiculos
				.filter((e) => this.veiculosSelecionados.includes(e.value))
			this.selectedTipoManutencao = this.optionsTipoManutencao
				.filter((e) => this.tipoManutencaoSelecionada.includes(e.value))
		},

		openModalConfirmar(dados){
			this.$refs.modalConclusaoManutencao.preparaModalConclusaoManutencao(dados)
			this.dadosManutencao = dados
		},

		codigoManutencaoCopia(codigoManutencao) {
			this.codigoManutencao = codigoManutencao;
		},

		openModalReplicar(){
			this.$bvModal.hide('openModalConfirmCopiarManutencao')  
			this.$refs.modalReplicarManutencao.preparaModalReplicarManutencao(this.codigoManutencao)
		},




		/**
		 * @listens onDrop - emitido quando os componentes de listagem 
		 *  ou kanban emitem evento
		 * @description Função para alterar o status da manutenção 
		 *  quando o card é mudado de kanban
		 * @author Vitor Hugo 🐨
		 */
		onDrop(drag, local){
			if(!this.getEditar()) return;
			var obj = {
				'drag': drag,
				'local' : local
			}
			this.loadingPanel = true
			new HttpRequest().Post(this.link+'altera/status', obj)
				.then(({data, status, code})  =>  {
					if(status && code === 200){
						if(data.status){
							this.drag = []
							this.dadosKanban = []
							this.listarKanban()
						} else {
							this.toastShow(data.dados, 'warning')
							this.loadingPanel = false
						}
					} else {
						this.loadingPanel = false
					}
				})
				.catch((error) => {
					this.statusBar = 'error2'
					this.loadingPanel = false
				})
		},

		alteraListaKanban(status){
			if(status === 'kanban'){
				this.listaKanban = true
			} else {
				this.listaKanban = false
			}
			this.listarKanban()
		},

		toastShow(msg, type, title = ''){
			this.$bvToast.toast(`${msg}`, {
				autoHideDelay: 3000,
				variant: type,
				title: title
			})
		},
	},
	mounted() {
		if(!this.getMaster()){
			this.selectedEmpresa = this.optionsEmpresas
			this.optionsEmpresas.forEach(el => {
				this.empresaSelecionada.push(el.value)
			});
			this.buscaVeiculos(this.empresaSelecionada)
		}
		this.buscaTipoManutencao()
		this.listarKanban()
	},
})
</script>

<style lang="scss">
    #controleManutencao{
        //Kanbam
        #divDados {
            overflow-y: hidden;
            padding: 0px 0px 10px 0px;
            min-height: 460px;
            .row-card-headers {
                border-spacing: 5px 0;
                border-collapse: separate;
                display: table;
                table-layout: fixed;
                width: 100%;
                padding: 0;
                margin: 0;
                .card-title {
                    padding: 8px 6px 6px 6px;
                    border-bottom: 0;
                    display: table-cell;
                    min-width: 110px;
                    width: 110px;
                    text-align: center !important;
                    vertical-align: middle;
                }
                span {
                    font-size: 14px;
                    color: white;
                    margin: 0;
                    .qtd {
                        font-size: 18px;
                    }
                }
                .card-header-cadastradas{
                    background: #90b1fa;
                    box-shadow: 1px 1px 2px #3572b0;
                }
                .card-header-vencidas{
                    background: #ef8081;
                    box-shadow: 1px 1px 2px #b4494a;
                }
                .card-header-programar{
                    background: #ffe98d;
                    box-shadow: 1px 1px 2px #b8a456;
                }
                .card-header-execucao{
                    background: #d198fb;
                    box-shadow: 1px 1px 2px #724891;
                }
                .card-header-realizadas{
                    background: #95eb87;
                    box-shadow: 1px 1px 2px #5a9c50;
                }
            }
            .row-card-columns {
                border-spacing: 5px 0;
                border-collapse: separate;
                display: table;
                table-layout: fixed;
                width: 100%;
                padding: 0;
                margin: 0;
                height: calc(100vh - 195px);
                .cadastrada{
                    box-shadow: 1px 1px 2px #3572b0;
                }
                .vencida{
                    box-shadow: 1px 1px 2px #b4494a;
                }
                .programar{
                    box-shadow: 1px 1px 2px #b8a456;
                }
                .execucao{
                    box-shadow: 1px 1px 2px #724891;
                }
                .realizadas{
                    box-shadow: 1px 1px 2px #5a9c50;
                }
                .card-column {
                    display: table-cell;
                    border-radius: 0px;
                    border-top: 0;
                    min-width: 110px;
                    width: 110px;
                    background: #dedfdf;
                    .limit-text {
                        display: block;
                        display: -webkit-box;
                        height: 2.6em;
                        line-height: 1.3em;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .clear-list {
                        overflow-y: auto;
                        overflow-x: hidden;
                        padding: 5px;
                        max-height: calc(100vh - 195px);
                        &::-webkit-scrollbar {
                            width: 5px;
                        }
                        &::-webkit-scrollbar-track {
                            box-shadow: inset 0 0 5px rgba(0,0,0,0.5);
                        }
                        &::-webkit-scrollbar-thumb {
                            box-shadow: inset 0 0 5px rgba(0,0,0,0.5);
                        }
                        .carro-placa {
                            .carro-icon {
                                width:50px;
                            }
                            .placa {
                                margin:auto;
                                font-size:14px;
                                text-align:start;
                                font-family: nexabold, sans-serif !important;
                            }
                            .Preventiva{
                                background: #308cff;
                                box-shadow: 1px 1px 2px #616161;
                                border: 1px solid #308cff;
                                color: #fff;
                                font-weight: bold;
                                vertical-align: middle;
                                height: 17px;
                                font-family: nexabold, sans-serif !important;
                            }
                            .Corretiva{
                                background: #e69b19;
                                box-shadow: 1px 1px 2px #616161;
                                border: 1px solid #e69b19;
                                color: #fff;
                                font-weight: bold;
                                vertical-align: middle;
                                height: 17px;
                                font-family: nexabold, sans-serif !important;
                            }
                        }
                        .item-disponivel {
                            background-color: #f8f8f8;
                            border: 1px #318cfe solid;
                            .div-disponivel {
                                color: #3f3f3f;
                                .negrito{
                                    font-weight: bold;
                                }
                                .olinho{
                                    background: #b7b7b7;
                                    border: 1px #9b9b9b;
                                    max-width: 25px;
                                    font-weight: bold;
                                    box-shadow: 1px 1px 2px #616161;
                                    cursor: pointer;
                                }
                            }
                        }
                        .item {
                            padding: 0 5px 5px 5px;
                            text-align: left;
                            font-size: 14px;
                            margin: 3px 6px 3px 0;
                            width: 100% !important;
                            max-width: 298px;
                            opacity: 1;
                            transition: opacity 0.2s;
                            box-shadow: 0px 2px 4px 0px #6d6f72;
                            text-align: flex;
                            vertical-align: top;
                            div {
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                width: 100%;
                                overflow: hidden;
                                font-size: 12px;
                            }
                            .carro-placa {
                                display: flex;
                                height: 28px;
                                .placa {
                                    padding-top:5px;
                                    margin:auto;
                                    font-size:14px;
                                    text-align:start;
                                    font-family: nexabold, sans-serif !important;
                                }
                                .os{
                                    padding-top:5px;
                                    margin:auto;
                                    font-size:12px;
                                    text-align:start;
                                    font-family: nexabold, sans-serif !important;
                                }
                            }
                            .cadastro{
                                font-size: 9px;
                            }
                        }
                        .realizadas{
                                cursor: pointer;
                                &:hover {
                                    opacity: 0.5;
                                }
                            }
                    }
                }
            }
        }
        
        
        
        #tableListaManutencao{
            max-height: calc(100vh - 170px);
            overflow-y: auto;
            overflow-x: hidden;
            .header{
                height: 35px;
                width: 170px;
                border-radius: 0px;
                vertical-align: middle;
                display: table-cell;
            }
            .Cadastradas{
                background: #90b1fa;
                // box-shadow: 1px 1px 2px #3572b0;
            }
            .Vencidas{
                background: #ef8081;
                // box-shadow: 1px 1px 2px #b4494a;
            }
            .Programar{
                background: #ffe98d;
                // box-shadow: 1px 1px 2px #b8a456;
            }
            .execucao{
                background: #d198fb;
                // box-shadow: 1px 1px 2px #724891;
            }
            .Realizadas{
                background: #95eb87;
                // box-shadow: 1px 1px 2px #5a9c50;
            }
            .Execucao{
                background: #cc99ff;
                // box-shadow: 1px 1px 2px #5a9c50;
            }
            .bodyTable{
                text-align: left;
                background: #efefef;
                div{
                    word-break: break-all;
                    border: 1px solid #e5e5e5;
                    font-size: 11px !important;
                    span{
                        font-size: 11px !important;
                    }
                }
            }
            .olinho{
                color: #8b8b8b;
                cursor: pointer;
            }
            .cabecalhoTabela{
                background: #f5f5f5;
                color: #514e4e;
                font-weight: bold;
                height: 45px;
                text-align: left;
                div{
                    border: 1px solid #e9e9e9;
                    padding-top: 5px;
                    padding-left: 2px;
                    // word-break: break-all;
                }
            }
            .sortable-ghost{
                background: #fff;
                border: 1px #b4494a !important;
            }
            .Preventiva{
                background: #308cff;
                box-shadow: 1px 1px 2px #616161;
                border: 1px solid #308cff;
                color: #fff;
                font-weight: bold;
                vertical-align: middle;
                text-align: center;
                height: 25px;
                font-family: nexabold, sans-serif !important;
                padding-left: 11px;
            }
            .Corretiva{
                background: #e69b19;
                box-shadow: 1px 1px 2px #616161;
                border: 1px solid #e69b19;
                color: #fff;
                font-weight: bold;
                vertical-align: middle;
                text-align: center;
                height: 25px;
                font-family: nexabold, sans-serif !important;
                padding-left: 11px;
            }
        }
    }
</style>