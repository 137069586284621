<template>
<ul class="breadcrumb divDontPrint">
    <li v-for="route in routes" :key='route.name'>
    <router-link :to="{name: route.name}">
      {{ route.meta.breadcrumbName }}
    </router-link>
  </li>

</ul>
</template>
<script>
import {BreadcrumbService} from './Breadcrumb.service'
export default {
    computed: {
        routes(){
            return new BreadcrumbService(this).Routes()
        }
    },
}
</script>
<style lang="scss">
ul.breadcrumb {
  padding: 2px 5px;
  list-style: none;
  background-color: transparent;
  display: none !important
}

@media print{
    ul.breadcrumb{
      display: none !important;
    }
}

/* Display list items side by side */
ul.breadcrumb li {
  display: inline;
  font-size: 12px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li+li:before {
  padding: 5px;
  color: 0275d8;
  content: "/\00a0";
}

/* Add a color to all links inside the list */
ul.breadcrumb li router-link  {
  color: #0275d8;
  text-decoration: none;
}

/* Add a color on mouse-over */
ul.breadcrumb li router-link :hover {
  color: #0275d8;
  text-decoration: underline;
}
</style>
