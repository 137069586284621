
<!-- 
    Componente do Card do Kanban no Painel de Controle
    -> Propriedades:
        1 - id - Tipo do card, nome da classe - Ex: 'carregado'/'faturado'...
        2 - item - Objeto com informações para montar o card
            Ex: 
            'item': {
                'veiculo': {'placa':''},
                'alertaAtrasoEntrega': '',
                'alertaExcedenteJornada': '',
                'motorista': '',
                'destino': {'descricao':''},
                'observacao': '',
                'kmMes': '',
                'itens': {'T': 5, 'F':5}
            }

 -->
<template>
    <div>
        <!-- Placa -->
        <div class="carro-placa">
            <div :class="'carro-icon div-icon div-'+id">
                <baseIcon 
                    :icon ='mdiTruck'
                    size ="20"/>
            </div>
            <div :class="'placa div-'+id"
                :title="item.veiculo.placa">
                {{item.veiculo.placa}}
            </div>
            <div v-show="item.alertaAtrasoEntrega != '' || item.alertaExcedenteJornada != ''"
                class="carro-icon div-alert div-icon"
                :title="item.alertaAtrasoEntrega != '' ?
                        item.alertaAtrasoEntrega :
                        item.alertaExcedenteJornada">
                <baseIcon 
                    :icon ='mdiAlert'
                    size ="20"/>
            </div>
        </div>
        <!-- Motorista -->
        <div :class="'div-'+id"
            :title="item.motorista">
            <span>{{item.motorista != '' ? 
                    item.motorista : 'Sem motorista'}}</span>
        </div>
        <!-- Rota -->
        <div :class="'div-'+id"
            :title="item.destino != '' ? 
                    item.destino.descricao : 
                    'Não há rota associada'">
            <span>{{item.destino != '' ? 
                    item.destino.descricao : 
                    'Não há rota associada'}}
            </span>
        </div>
        <!-- Itens -->
        <div v-if="item.itens && (item.itens.F || item.itens.T)"
            :class="'div-'+id"
            :title="item.kmMes">
            <span :title="getTitleItens(item.itens)">
                {{ getDescricaoItens(item.itens) }}
            </span>
        </div>
        <!-- Observações -->
        <div v-show="item.observacao != ''"
            :class="'div-'+id"
            :title="item.observacao">
            <span>{{item.observacao}}</span>
        </div>
        <!-- Km mês -->
        <div v-show="item.kmMes > 0"
            :class="'div-'+id" align='center'
            :title="item.kmMes">
            <span>{{item.kmMes}} Km/mês</span>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mdiAlert, mdiTruck } from '@mdi/js'

export default Vue.extend({
	name:'cardVeiculoKanban',
	components:{
		'baseIcon': require('@/components/Atom/Icon/BaseIcon').default,
	},
    props: {
        id: {
            type: String,
            required: true
        },
        item: {
            type: Object,
            required: true
        }
    },
    data(){
        return {
			mdiAlert: mdiAlert,
            mdiTruck: mdiTruck,
        }
    },
    methods: {
        /**
         * Formata title do item
         * @param item.F Finalizados
         * @param item.T Total
         */
        getTitleItens(item) {
            let realizados = item.F ? item.F : 0
            let total = item.T ? item.T : 0
            return `Realizados: ${realizados} / Destinos: ${total}`
        },

        /**
         * Formata descrição do item
         * @param item.F Finalizados
         * @param item.T Total
         */
        getDescricaoItens(item) {
            let realizados = item.F ? item.F : 0
            let total = item.T ? item.T : 0
            return `Destinos: ${realizados} / ${total}`
        },
    }
})
</script>
