<template>
    <eaglePanel id='processamento' :loading="loadingPanel">
        <div class="col-sm-12 row divDontPrint">
            <div class="col-sm-6 nopadding">
                <titulo
                    titulo='Processamento'
                    :icon='mdiDatabaseSync'
                />
            </div>
            <div class="col-sm-6 nopadding" align='right'>
                <simpleButton
                    :disabled='$v.data.$invalid'
                    text='Gerar'
                    type='green'
                    :icon='mdiThumbUpOutline'
                    event='click'
                    @click="gerarRelatorio"
                />
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <div class="col-sm-12 row nopadding">
            <div class="col-sm-3 nopadding">
                <dayPicker
                    class="pr-0"
                    @changeInput='mudaPeriodo'
                    name='pickerPeriodoProcessamento'
                    opens='right'
                />
            </div>
            <div class="col-sm-3 nopadding">
                <selectAll
                    nameForRadio='seletorEmpresaProcessamento'
                    :isMultiple='true'
                    :hasSelectAll='true'
                    :labels='[{"description":"Empresas"}]'
                    :optionsArray='optSelectEmpresas'
                    :selected="selectedEmpresa"
                    @changeSelect='mudaEmpresa'
                />
            </div>
            <div class="col-md-5 col-sm-10 row nopadding">
                <div class="col-md-10 col-sm-11 nopadding">
                    <selectAll
                        class="nopadding"
                        nameForRadio='seletorGrupoAhSerProcessado'
                        :isMultiple='true'
                        :hasSelectAll='true'
                        ref='seletorMultiplo'
                        :disabled='!optSelectGrupo.length'
                        :labels='labelsSeletorDeGrupoAhSerProcessado'
                        :optionsArray='optSelectGrupo'
                        firstSelected='MR'
                        :loading='carregandoGrupos'
                        @changeCheck='mudaCheckBoxSeletor'
                        @changeSelect='mudaSeletorGrupo'
                    />
                </div>
                <div class="col-md-2"
                    v-if="getEditar()">
                    <simpleButton
                        :disabled='$v.dataGerar.$invalid || !habilitarProcessar'
                        class='botaoFiltros'
                        text='Processar'
                        type='blue'
                        event='click'
                        @click="processarRelatorio"
                    />
                </div>
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <div class="col-sm-12">
            <tableSimples>
                <thead>
                    <tr class="cabecalhoTabela">
                        <th>Colaborador</th>
                        <th>
                            <b-check
                                id='checkSelecionarTodos'
                                v-model='todosSelecionados'
                                @change='selecionarTodos'>
                                Selecionar todos
                            </b-check>
                        </th>
                        <th>Status processamento</th>
                    </tr>
                </thead>
                <tbody v-if="dataGerar.length">
                    <tr 
                        v-for='(col, index) in dataGerar'
                        :key='"table_processamento--"+index'> 
                        <td class="pl-2">{{col.mtnome}}</td>
                        <td class='tdCheck pl-2'>
                            <div class="inline">
                                <b-check
                                    v-if="!col.temFechamento"
                                    :id="index+'--selecionarTodos'"
                                    style='z-index:0'
                                    v-model="col.selecionado"
                                    @change="value=>mudaCheckSelecionado(value, index)"
                                    v-bind="bindMtnome(col)"
                                />
                                <span
                                    v-if="col.temFechamento"
                                    style="color:#e04d50;"> 
                                    Possui fechamento na data {{ col.dataFechamento }} 
                                </span>
                            </div>
                        </td>
                        <td class="pl-2">
                            <span 
                                :class="col.exibirTitulo ? 
                                    'circle '+col.status : 
                                    'circle painel-gray'"
                                :title="col.exibirTitulo ?
                                    'Datas com problema:\n'+
                                    col.titulo.join(', '):
                                    ''"
                            ></span>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <statusbar
                        :statusBar='statusBar'
                        colspanForTd='6'
                        msgCentro='selecione os campos obrigatorios para gerar o relatório'
                    />
                </tbody>
            </tableSimples>
        </div>
        <eagleModal
            title='Informativo do processamento'
            id='modalInformativoProg'
            :hardToClose='true'
            @modalClose="modalClose">
            <template #modalBody>
                <div class="col-sm-12 nopadding row window">
                    <div class="col-sm-12 nopadding">
                        <div class='displayModal'>    
                            <div class="col-sm-12 nopadding">
                                <div class="col-sm-1">
                                    Dia(s): 
                                </div>
                                <div class="col-sm-11">
                                    <b-badge
                                        class='badge-day'
                                        v-for='dia in arrayDias'
                                        :key='dia.dia+"__diasProcessar"'
                                        :class="'diaP '+dia.cor">
                                        {{displayDiaAux(dia.dia)}}
                                    </b-badge>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12"><hr></div>
                    <div class='col-sm-12 mb-4'>
                        Motorista(s): {{sendoProcessadoAg(value)+'/'+qtdeSelecionados()}}
                        <b-progress :max="max" height="2rem" class='progressBar'>
                            <b-progress-bar 
                                :label="`${((value / max) * 100).toFixed(0)}%`" 
                                :value="value" 
                                animated
                                variant="success"
                            />
                        </b-progress>
                    </div>
                </div>
            </template>
            <template #modalFooter v-if='value != max'>
                Processando, aguarde! <b-spinner small title='gira gira gira'/>
            </template>
             <template #modalFooter v-else>
                <div class="col-sm-12 row">
                    <div class="col-sm-8 errosProcessamento">
                        <span v-if='arrayErros.length'>
                            <span>
                               <span class='texto'> Problemas no processamento!</span>
                                <span 
                                    class='link' 
                                    event='click'
                                    @click="abreModalErros"
                                >Confira aqui</span>
                            </span>
                        </span>
                        <span v-if="!arrayErros.length" 
                              class='textoSucesso'> Todos processados com sucesso
                        </span>
                    </div>
                    <div class="col-sm-4 nopadding" align='right'>
                        <simpleButton 
                            type='red'
                            text='Fechar'
                            :icon='mdiCloseThick'
                            event='click'
                            @click="fechaModal"
                        />
                    </div>
                </div>
            </template>
        </eagleModal>
        <eagleModal 
            id='errosProcessamento'>
            <template #modalBody>
                <div class="col-sm-12 nopadding divEmVolta">
                    <table class='tabelaDeErros'>
                        <thead class='tableTitulos'>
                            <tr>
                                <th>Data</th>
                                <th>Motorista</th>
                                <th>Motivo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr 
                                v-for="(erro, index) in arrayErros"
                                :key='"table-erro__"+index'>
                                <td class='dataErro'>{{displayDiaAux(erro.data, true)}}</td>
                                <td class='dataErro'>{{erro.trabalhador.mtnome}}</td>
                                <td class='dataErro'>
                                    {{erro.erro.erros[0].length == 1 ?
                                    erro.erro.erros[0][0] : 
                                    erro.erro.erros[0]}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </template>
            <template #modalFooter>
                <simpleButton 
                    type='red'
                    text='Fechar'
                    :icon='mdiCloseThick'
                    event='click'
                    @click="fechaModalErros"
                />
            </template>
        </eagleModal>
    </eaglePanel>
</template>

<script>

import { EmpresasService } from '@/Services/auth/Empresas.service'
import { DateTime } from "luxon"
import { 
    data, validations, components, 
    arrayDatas, displayDia
} from '@/views/Jornada/NaoMenus/Processamento'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { mapGetters } from 'vuex'

export default {
    name:'processamentoJornada',
    components: components,

    validations: validations,

    data() {
        return {
            baseUri:'/controle/jornada/processamento/',
            optSelectEmpresas: new EmpresasService().Get(),
            ...data,
            intervalo:'',
        }

    },

    methods: {
        ...mapGetters(['getMaster', 'getEditar']),

        /**
         * Verifica qual trabalador esta sendo processado
         * pelo código do mesmo, dá até pra devolver o nome
         * do cara agora
         * @author Gui 🍺
         */
        sendoProcessadoAg(valor){
            let index = 0
            var AhProcessar = this.dataGerar.filter(a => a.selecionado)
            if(this.objsAProcessar[valor]!== undefined){
                let cod = this.objsAProcessar[valor].codigo
                index = AhProcessar.findIndex(a=>{
                    return a.mtcodigo == cod
                })
                // comentado pois estava calculando do processamento a porcentagem errada
                // index += 1
            }else{
                index = AhProcessar.length
            }
            return index
        },

        selecionarTodos(value){
            this.dataGerar.forEach(el=>{
                el.selecionado = value
            })
            this.habilitarProcessar = value
            this.$forceUpdate()
        },

        /**
         * Função que começa o processamento de dados
         * criando a array que de objs a serem enviados para o
         * back-end atravez da função "fazRequestProcessar"
         * também já seta os objetos da array de dias que é 
         * utilizada para mostrar as datas na modal de processamento
         */
        processarRelatorio(){
            if(!this.qtdeSelecionados()) {
                let msg = 'Precisa selecionar um ou mais colaboradores para processar!'
                this.toastShow('Ops!', msg, 'danger')
                return
            }
            this.arrayErros = []
            var objs = []
            let diasAhProcessar = arrayDatas(this.data.data[0], this.data.data[1])
            this.arrayDias = diasAhProcessar.map(dia=>{
                return {'dia':dia, 'cor':'white'}
            })
            diasAhProcessar.forEach(data=>{
                this.dataGerar.forEach(t=>{
                    if(t.selecionado){
                        var obj = {
                            'codigo' :t.mtcodigo,
                            'data' :data,
                            // Transformado em string pois o script 
                            // do folha ponto só aceita true e false como string

                            // Foi removida da tela essa funcionalidade
                            'updates': false, // String(t.considerarRfid)
                        }
                        objs.push(obj)
                    }
                })
            })
            this.objsAProcessar = objs
            this.max = objs.length
            this.value = 0
            this.abreModal()
            this.fazRequestProcessar(0)
        },

        /**
         * Faz uma request e altera a classe da array de dias de acordo com o
         * dia da request, a função funciona de maneira recursiva, verificando
         * se o index da request atual é menor que o número total de requests
         * a ser feito
         * @author Gui 🍺🍺
         */
        fazRequestProcessar(index){
            let uri = this.baseUri+'processar'
            if(!this.objsAProcessar.length) return
            new HttpRequest().Post(uri, this.objsAProcessar[index])
                .then(data=>{
                    if(data.status){
                        this.decideCorDoDia(index)
                        this.salvaErros(index, data.data)
                    }
                    if(this.value < this.max) this.value += 1
                    if(this.value < this.max){
                        this.clearTimeoutProcessamento()
                        this.timeoutProcessamento = window
                            .setTimeout( this.fazRequestProcessar, 700, this.value )
                    }else{
                        this.arrayDias.forEach(dia=> dia.cor = 'green')
                    }
                    if(this.objsAProcessar.length-1 === index){
                        this.dataGerar.forEach(element => {
                            element.exibirTitulo = true
                        })
                    }
                })
                .catch((error)=>{''})
        },

        /**
         * Cria a array de erros que é exibida na modal de erros
         * de processamento, chama a função de update de status
         * para cada erro e também organiza a array de erros
         * para mostrar em ordem cronológica para o usuário
         */
        salvaErros(index, erro){
            var y = this.dataGerar.find(t=>{
                return t.mtcodigo == this.objsAProcessar[index].codigo
            })
            this.updateStatusTrabalhador(erro, index)
            if(!erro.erros.length) return
            let err = {
                'data' : this.objsAProcessar[index].data,
                'trabalhador': y,
                'erro' : erro 
            }
            this.arrayErros.push(err)
            if(this.arrayErros.length)
                this.arrayErros.sort(function(a, b){
                    var dataA = DateTime.fromFormat(a.data, 'yyyy-MM-dd')
                    var dataB = DateTime.fromFormat(b.data, 'yyyy-MM-dd')
                    dataA < dataB
                })
        },

        /**
         * Procura pelo trabalhador dentro da array de dados gerados
         * para atualizar o status de processamento
         */
        updateStatusTrabalhador(data, index){
            let cod =  this.objsAProcessar[index].codigo
            let index2 = this.dataGerar.findIndex(a=>{
                return a.mtcodigo == cod
            })  
            if(!data.erros.length){
                if(this.dataGerar[index2].status == 'painel-pink') return
                this.dataGerar[index2].status = 'painel-green'
            }else{
                this.dataGerar[index2].status = 'painel-pink'
            }
            this.dataGerar[index2].exibirTitulo = false
            let dia = this.displayDiaAux(this.objsAProcessar[index].data, true)
            this.dataGerar[index2].titulo.push(dia)
        },

        /**
         * Função auxiliar para chamar a função importada displayDia
         */
        displayDiaAux(data) {
            return displayDia(data)
        },

        /**
         * Alter a classe dos elementos que representam os dias
         * selecionados na request, fazendo o efeito da cor
         * ir "avançando" junto das requests
         */
        decideCorDoDia(index){
            this.arrayDias.forEach(dia=>{
                if(this.objsAProcessar[index].data==dia.dia) {
                    dia.cor = 'blue'
                }else if(DateTime.fromFormat(this.objsAProcessar[index].data, 'yyyy-MM-dd') > DateTime.fromFormat(dia.dia, 'yyyy-MM-dd')) {
                    dia.cor = 'green'
                }else {
                    dia.cor = 'white'
                }
            })
        },

        /**
         * Gera a tabela de funcionarios e já seta 
         * alguns dos parametros para exibição 
         * de informação para o usuário
         */
        async gerarRelatorio(){
            this.loadingPanel = true
            this.dataGerar = []
            let uri = this.baseUri+'gerar'
            await new HttpRequest().Post(uri, {'data' : this.data, 'gerar' : true})
            .then(data=>{
                if(data.status){
                    this.todosSelecionados = true
                    this.todosRFID = true
                    this.dataGerar = data.data
                    this.dataGerar.forEach(el=>{
                        el.selecionado    = true
                        el.titulo = []
                        el.exibirTitulo = false
                        el.temFechamento = false
                    })
                    this.habilitarProcessar = true
                }
            })
            this.loadingPanel = false
        },

        mudaSeletorGrupo(arr){
            this.dataGerar = []
            this.data.mot = arr
        },

        abreModalErros(){
            this.$bvModal.show('errosProcessamento')
        },

        fechaModalErros(){
            this.fechaModal();
            this.$bvModal.hide('errosProcessamento')
        },

        abreModal(){
          this.$bvModal.show('modalInformativoProg')
        },

        fechaModal(){
          this.$bvModal.hide('modalInformativoProg')
        },

        /**
         * Alteração no intervalo de tempo em que o relatório deve abrangir
         */
        mudaPeriodo(value){
            this.dataGerar = []
            this.intervalo = value
            let intervalo = value.split(' - ')
            this.data.data = intervalo
        },

        /**
         * Quando o check do seletor de mot
         * é alterado entre
         * MR -> motoristas
         * MA -> ajudantes
         * AA -> administrativo
         * essa função altera as opção exibidas
         * no seletor de acordo com a opção marcada
         */
        mudaCheckBoxSeletor(value){
            this.dataGerar = []
            if(this.dataParaSeletor[value])
                this.optSelectGrupo = this.dataParaSeletor[value]
            if(!this.data.cliente.length){
                this.optSelectGrupo = []
            }
        },

        /**
         * Limpa os dados exibidos e já faz o request 
         * que traz as informações do segundo seletor de acordo com
         * a empresa selecionada
         */
        mudaEmpresa(arr){
            this.dataGerar = []
            this.$refs.seletorMultiplo.clearAll()
            this.data.cliente = arr
            this.optSelectGrupo = []
            if(this.data.cliente.length){
                this.carregandoGrupos=true
                new FiltrosService()
                .dados_filtros(this.data.cliente, ['MR', 'MA', 'AA'])
                .then(data=>{
                    this.dataParaSeletor = data
                    this.optSelectGrupo = this.dataParaSeletor[
                        this.$refs.seletorMultiplo.labelSelected
                    ]
                    this.carregandoGrupos = false
                })
            }
        },

        /**
         * Altera o atributo de selecionado do trabalhador
         * e já desmarca o botão de selecionar todos
         */
        mudaCheckSelecionado(value, index){
            if(!value) {
                this.todosSelecionados = false
            } else {
                this.dataGerar[index].selecionado = value
                this.todosSelecionados = this.verificaTodosSelecionados()
            }
            this.habilitarProcessar = this.qtdeSelecionados() > 0
        },

        /**
         * Retorna a quantidade de 'dataGerar' filtrados por [selecionado == true]
         * @author Rafael
         */
        qtdeSelecionados() {
            return this.dataGerar.filter(e=>e.selecionado).length
        },


        /**
         * Verifica se todos os itens estão selecionados
         * @author Rafael
         */
        verificaTodosSelecionados() {
            return this.qtdeSelecionados() === this.data.mot.length
        },

        /**
         * @description Evento de fechar o modal, quando este é executado.
         * @author Rafael
         */
		modalClose() {
            this.clearTimeoutProcessamento()
		},

        /**
         * Finaliza e reseta o objeto de setTimeout [timeoutProcessamento]
         * @author Rafael
         */
        clearTimeoutProcessamento(){
            if(this.timeoutProcessamento) {
                clearTimeout(this.timeoutProcessamento)
                this.timeoutProcessamento = null
            }
        },

        /**
         * @description Método para mostrar o toast na tela
         * @param {string} msg - mensagem que vai aparecer no corpo do toast
         * @param {string} type - qual o tipo do toast 
         * @param {string} title - título para inserir no cabeçalho
         * @author Rafael
         */
        toastShow(titulo, msg, type){
            this.$bvToast.toast(msg, {
                title: titulo,
                autoHideDelay: 2500,
                variant: type,
            })
        },

		bindMtnome(wr) {
			var nao_pode_fechar = !this.podeFechar(wr)
			if(nao_pode_fechar) {
				wr.temFechamento = true
				wr.selecionado = false
				return {
					disabled:true,
					title: 'Já possui fechamento neste período'
				}
			} else {
				wr.temFechamento = false
			}
		},

		/**
		 * @description confirma se o funcionário pode ou não
		 * fazer um processamento 😃
		 * @returns {boolean}
		 */
        podeFechar(wr) {
            if (!wr.fechamento.length)
                return true
            var formato = 'dd/LL/yyyy'
            var fechamentos = this.achaPeriodoUltimoFechamento(wr.fechamento)
            var intervalo_relatorio = this.criaArrayDatas(
                ...this.intervalo.split(' - '),
                formato
            )
            let dataInicioIntervalo = DateTime.fromFormat(intervalo_relatorio[0], formato)
            let ultimoFechamento = DateTime.fromFormat(fechamentos.at(-1), formato)
            var ini = DateTime.fromISO(wr.fechamento[0].jfinicio).toFormat(formato)
            var fim = DateTime.fromISO(wr.fechamento[0].jffim).toFormat(formato)
            wr.dataFechamento = ini + " - " + fim
            if (dataInicioIntervalo <= ultimoFechamento)
                return false
            return true
		},

		/**
		 * @param {object[]} fechamentos
		 * @return {string[]} inicio, fim, no formato dd/mm/yyyy, com TODAS
		 * as datas de fechamento do carinha.
		 */
		achaPeriodoUltimoFechamento(fechamentos) {
			var arr_todas_datas = []
			var format = 'dd/LL/yyyy'
			fechamentos.forEach((f) => {
				var ini = DateTime.fromISO(f.jfinicio).toFormat(format)
				var fim = DateTime.fromISO(f.jffim).toFormat(format)
				var dias = this.criaArrayDatas(ini, fim, format)
				arr_todas_datas =  arr_todas_datas.concat(dias)
			})
			return arr_todas_datas
		},

		/**
		 * @param {string} ini inicio do período
		 * @param {string} final final do periodo
		 * @param {string} format formato da data
		 * @return {string[]}	todas as datas incluindo o inico e fim
		 */
		criaArrayDatas(ini, final, format){
			var ini_data = DateTime.fromFormat(ini, format)
			var fim_data = DateTime.fromFormat(final, format)
			var retorno = [];
			var diff = fim_data.diff(ini_data, 'days').toObject().days
			for(let i = 0; i <= diff; i++){
				var novo_dia = ini_data.plus({days: i})
				retorno.push(novo_dia.toFormat(format))
			}
			return retorno
		},
    },

    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optSelectEmpresas
        }
    },

}
</script>

<style lang="scss" scoped>
    .inline{
        display: inline-flex;
        span{
            padding-top: 2px;
        }
    }
    .errosProcessamento{
        padding-top: 0.5% !important;
        text-align: center;
        .texto{
            color: red
        }
        .link{
            margin: 5px;
            color:blue;
            text-decoration: underline;
            cursor: pointer;
        }
        .textoSucesso{
            color: #2b942b;
        }
    }

    .displayModal{
        display: inline;
        padding: 40px;
        .badge-day{
            width: 65px;
            margin: 3px;
            padding: 4px;
            overflow-wrap: normal;
            font-size: 12px !important;
        }
        .diaP{
            width: 50px;
            border: solid 1px black ;
            margin: 3px;
            padding: 4px;
            overflow-wrap: anywhere;
            font-size: 13px;
        }
        .green{
            color: white;
            background-color: #6db000;
        }
        .white{
            color: black;
            background-color: white;
        }
        .blue{
            color: white;
            background-color: darkblue
        }
    
    }

    td{
        text-align: left;
    }
    .botaoFiltros{
        margin-top: 33%;
    }
    .circle{
        padding-right: 20px;
        margin-right: 7px;
        border: 2px solid #fff;
        border-radius: 50%;
    }
    .painel-green{
        background-color: #6db000;
    }
    .painel-gray{
        background-color: #b5b5b5;
    }
    .painel-pink{
        background-color: #f8a1a2;
    }
    .painel-gray{
        background-color: #b5b5b5;
    }
    .window{
        padding: 10px;
        border: #428bca 1px solid
    }
    .divEmVolta{
        max-height: 400px;
        overflow: auto;
        .tabelaDeErros{
            width: 100%;
            .tableTitulos{
                padding: 1px;
                position: sticky;
                top: -1px;
                background: #f5f5f5;
            }
            .dataErro{
                padding: 10px;
            }
        }
    }
    .cabecalhoTabela{
        th{
            font-size: 13px !important;
        }
    }
</style>