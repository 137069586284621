<template>
    <panelEagle id='OrdemDeServico' :loading='gerandoRel'>
        <div class="col-sm-12 nopadding row">
            <div class="col-sm-6">
                <tituloPage :icon='mdiNoteText' titulo='Ordem de serviço'></tituloPage>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 divDontPrint">
                <basicButtonsRelatoriosEIG
                    :loading           ="loadingButton"
                    :disabled          ='$v.dadosEmpresa.$invalid'
                    :disabledDropdown  ='!Object.keys(dadosRelatorio).length'
                    @exportarRelatorio ="exportarRelatorio"
                    @gerarRelatorio    ='gerarRelatorio'>
                </basicButtonsRelatoriosEIG>
            </div>
        </div>
        <slideUandD>
            <div class="col-sm-12 col-md-12"><hr></div>
            <div class='col-sm-12 row nopadding'>
                <div class="col-lg-3 nopadding">
                    <inputRangeWithLimit
                        opens="right"
                        id='Intervalo*'
                        name='Intervalo'
                        @changeInput='changeData'
                        :isObrigatorio='false'/>
                </div>
                <div class="col-lg-3 nopadding">
                    <selectAll
                        nameForRadio  ='Empresas'
                        :isMultiple   ='true'
                        :optionsArray ='optEmpresas'
                        :labels       ='labelsEmp'
                        :hasSelectAll ='true'
                        @changeSelect ='changeEmpresa'/>
                </div>
                <div class="col-lg-3 nopadding">
                    <selectAll
                        nameForRadio  ='Ordem de serviço'
                        :isMultiple   ='true'
                        :optionsArray ='optOrdem'
                        :disabled='!optOrdem.length'
                        :labels       ='labelsOrd'
                        :hasSelectAll ='true'
                        @changeSelect ='changeOrdem'/>
                </div>
                <div class="col-lg-3 nopadding">
                    <selectAll
                        nameForRadio  ='Veículos'
                        :disabled='!optVeiculos.length'
                        :isMultiple   ='true'
                        :optionsArray ='optVeiculos'
                        :labels       ='labelsVei'
                        :hasSelectAll ='true'
                        @changeSelect ='changeVeiculo'/>
                </div>
                <div class="col-12">
                    <div class="fundo mt-2">
                        <div><span class="dot laranja"></span><span> Pendente</span></div>
                        <div><span class="dot azul"></span><span> Agendada</span></div>
                        <div><span class="dot verde"></span><span> Finalizada</span></div>
                        <div><span class="dot vermelho"></span><span> Cancelada</span></div>
                    </div>
                </div>
            </div>
        </slideUandD>
        <div class="col-sm-12 col-md-12"><hr></div>
        <tableRelatorio>
            <slot slot="thead">
                <tr class="table">
                    <!-- NAO SAO ESPACOS EM BRANCO NA FRENTE DOS NOMES -->
                    <!-- SAO CARACTERES INVISIVEIS PARA DEIXAR ALINHADO -->
                    <th colspan="1">OS</th>
                    <th colspan="1">Placa</th>
                    <th colspan="1">Empresa</th>
                    <th colspan="1">Tipo</th>
                    <th colspan="1">De</th>
                    <th colspan="1">Para</th>
                    <th colspan="1">Motivo</th>
                    <th colspan="1">Justificativa</th>
                    <th colspan="1">Gerado</th>
                    <th colspan="1">Agendado</th>
                    <th colspan="1">Confirmado</th>
                    <th colspan="1">Usuário</th>
                </tr>
            </slot>
            <slot slot="tbody">
                <br>
                <template v-if="$v.dadosRelatorio.$invalid">
                    <statusInformation typeBar="tr" :statusBar="statusBar"></statusInformation>
                </template>
                <template v-else>
                    <tr v-for="(dadosTabela, d) in dadosRelatorio"
                        :key="d"
                        :id="'corTr-'+dadosTabela.osstatus"
                        class="paddingTd">
                        <td>
                            {{dadosTabela.oscodigo}}
                        </td>
                        <td>
                            {{dadosTabela.osplaca}}
                        </td>
                        <td>
                            {{dadosTabela.clfantasia}}
                        </td>
                        <td>
                            {{dadosTabela.ostdescricao}}
                        </td>
                        <td>
                            {{dadosTabela.osatual}}
                        </td>
                        <td>
                            {{dadosTabela.osnovo}}
                        </td>
                        <td>
                            {{dadosTabela.osmotivo}}
                        </td>
                        <td>
                            {{dadosTabela.osstatus == 'C' ? dadosTabela.osjustificativacancelamento : ''}}
                        </td>
                        <td>
                            {{formatDataGerado(dadosTabela.osdataevento)}}
                        </td>
                        <td>
                            {{formatData(dadosTabela.osdataagendado)}}
                        </td>
                        <td>
                            {{formatData(dadosTabela.osdataconfirmacao)}}
                        </td>
                        <td>
                            {{dadosTabela.name}}
                        </td>
                    </tr>
                </template>
            </slot>
        </tableRelatorio>
    </panelEagle>
</template>
<script>
import Vue  from 'vue'
import { DateTime }      from 'luxon';
import {mdiNoteText } from '@mdi/js'
import { required } from 'vuelidate/lib/validators'
import {EmpresasService} from '../../../Services/auth/Empresas.service'
import {HttpRequest}     from '../../../Services/auth/HttpRequest.Service'
export default Vue.extend({
	name:'ordensDeServico',
	components:{
		'slideUandD'                : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
		'panelEagle'                : require('@/components/Atom/Panel/PanelEagle').default,
		'basicButtonsRelatoriosEIG' : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
		'tituloPage'                : require('@/components/Atom/Header/Titulo').default,
		'inputRangeWithLimit'       : require('@/components/Atom/Datas/InputRangeWithLimit').default,
		'selectAll'                 : require('@/components/Atom/Select/SelectAll').default,
		'statusInformation'         : require('@/components/Atom/StatusInformation/StatusInformation').default,
		'tableRelatorio'            : require('@/components/Atom/Table/TableRelatorio').default,
	},

	validations:{
		dadosRelatorio:{ required },
		dadosEmpresa : { required },
	},

	data(){
		return {
			loadingButton: [false, false, false],
			mdiNoteText:mdiNoteText,
			statusBar    : 'info',
			dadosRelatorio   : '',
			dadosData    : '',
			exporta      : '',
			dadosEmpresa : [],
			dadosOrdem   : [],
			dadosVeiculo : [],
			optVeiculos  : [],
			optOrdem     : [],
			optEmpresas  : new EmpresasService().Get(),
			labelsEmp    :[{
				indexDFH    : 'EM',
				description : 'Empresas*'
			}],
			labelsOrd    :[{
				indexDFH    : 'ORD',
				description : 'Ordem de serviço'
			}],
			labelsVei    :[{
				indexDFH    : 'VEI',
				description : 'Veículos'
			}],
			gerandoRel:false,
		}
	},
	methods:{
		async exportarRelatorio(value){
			switch (value) {
				case 'pdf':
					this.loadingButton = [true, false, false]
					break;
				case 'xls':
					this.loadingButton = [false, true, false]
					break;
				default:
					this.loadingButton = [false, false, true]
					break;
			}
			this.exporta = value
			var root =  process.env.VUE_APP_ROOT;
			var obj = {
				tipo    : this.exporta,
				tabela  : this.dadosRelatorio,
				data    : this.dadosData,
				empresa : this.dadosEmpresa,
				ordem   : this.dadosOrdem,
				veiculo : this.dadosVeiculo,
			}
			var html = '';
			html = await new HttpRequest()
				.Post('/administrativo/relatorios/pdf', {'dados': obj})
			window.open(root+'/'+html.data.dados);
			this.loadingButton = [false, false, false]
               
		},
		async gerarRelatorio(){
			this.gerandoRel = true
			var obj = {
				data    : this.dadosData,
				empresa : this.dadosEmpresa,
				ordem   : this.dadosOrdem,
				veiculo : this.dadosVeiculo,
			}
			var dados = '';
			dados = await new HttpRequest()
				.Post('/administrativo/relatorios/tabela', {'dados': obj})
			if(dados.data.length == 0){
				this.statusBar = 'error'
			}
			else if(dados.data != ' '){
				this.dadosRelatorio = dados.data
			}
			this.gerandoRel = false
		},
		changeVeiculo(value){
			this.dadosVeiculo = value
            this.dadosRelatorio = []
		},
		changeOrdem(value){
			this.dadosOrdem = value
            this.dadosRelatorio = []
		},
		changeData(value){
			this.dadosData = value
            this.dadosRelatorio = []
		},
		changeEmpresa(value){
			this.dadosEmpresa = value
			this.getVeiculos(value)
		},
		async getVeiculos(value){
            this.dadosRelatorio = []
			var dados = [];
			dados = await new HttpRequest()
				.Post('/administrativo/relatorios/veiculos', {'valor': value})
			this.optVeiculos = dados.data.retorno
			this.optOrdem = dados.data.ordem
		},

		formatDataGerado(data){
			var dataPadrao = DateTime.fromFormat(data, 'yyyy-MM-dd HH:mm:ss').toFormat('dd/MM/yyyy')
			return dataPadrao
		},

		formatData(data){
			if(data){
				var dataPadrao = DateTime.fromFormat(data, 'yyyy-MM-dd').toFormat('dd/MM/yyyy')
				return dataPadrao
			} else {
				return ''
			}
		},
	},
})
</script>
<style lang="scss">
.table{
    // margin-top: -20px !important;
    padding:5px !important;
    font-size:14px !important;
    text-align: left !important;
    border-bottom: 0 !important;
    background-color: #F5F5F5 !important;
    border-top: 1px solid #dddddd !important;
    th{
        text-align: left !important;
        padding:5px !important;
    }
}
#OrdemDeServico{
    .fundo{
        background-color: #f2f2f2;
        display: flex;
        width: 100%;
        justify-content: space-around;
        div{
            margin: 10px;
        }
        .dot {
            height: 12px;
            width: 12px;
            border-radius: 50%;
            display: inline-block;
        }
        .laranja{
            background-color: #f1b108;
        }
        .azul{
            background-color: #428bca;
        }
        .verde{
            background-color: #41b325;
        }
        .vermelho{
            background-color: #d87676;
        }
    }

    #corTr-P{
        background: #f0e2b1;
    }

    #corTr-A{
        background: #bad0ec;
    }

    #corTr-F{
        background: #c4ecba;
    }

    #corTr-C{
        background: #ecbaba;
    }

    .paddingTd{
        td{
            padding: 5px !important;
        }
    }
}
</style>