<template>
    <div id="menu" :class="[{'collapsed' : collapsed}]">
        <div class="content">
            <app-breadcrumb></app-breadcrumb>
            <router-view name="content" />
        </div>
        <sidebar-menu :menu="menu"
		:show-one-child='true'
		@item-click="OnClick"
		@toggle-collapse="OnCollapse"
		width="245px">
            <div slot="header" >
                <div class="buttonHeader" @click="BackClick">
                    <p :hidden="collapsed">
                        Menu principal
                    </p>
                </div>
                <div>
                    <app-user-data :collapsed="collapsed"> </app-user-data>
                    <hr
					:hidden="collapsed"
					style="border-color: rgba(0, 0, 0, 0.5);margin:10px; margin-top:40px">
                </div>
            
            </div>
            <span slot="dropdown-icon">
                <baseIcon :size='25' class='icon' :icon='iconPlus'/> 
            </span>
            <span slot="toggle-icon">
                <baseIcon :size='30' class='icon' :icon='mdiMenu'/>
            </span>
        </sidebar-menu>
    </div>
</template>
<script>
import { mdiPlusCircleOutline, mdiMenu} from '@mdi/js'
import {MenuComponent} from './Menu'
import UserData from './UserHeader'
import { mapActions, mapGetters } from 'vuex'
import Breadcrumb from '../Header/Breadcrumb'
import {AuthService} from '../../../Services/auth/Auth.Service';
import { EmpresasService } from '@/Services/auth/Empresas.service'
export default {
	components:{
		baseIcon : require('@/components/Atom/Icon/BaseIcon.vue').default,
		'app-user-data':UserData,
		'app-breadcrumb':Breadcrumb
	},
	data() {
		return {
			mdiMenu:mdiMenu,
			collapsed: false,
			menu: this.load(),
			iconPlus: mdiPlusCircleOutline,
			cliente: this.getUser().idClient,
			usumaster: this.getUser().usuMaster,
			perfil: this.getUser().perfil,
			idUser: this.getUser().idUser,
		}
	},
	created(){
		this.refresh()
		this.load()
	},
	methods: {
		...mapGetters(['getPermicoes', 'getModulos', 'getUser']),
		...mapActions(['updatePermissions', 'refresh']),
		load(){
			return new MenuComponent(this).LoadMenu();
		},
		BackClick(event){
			this.emiteVerificarAtualizacao()
			this.atualizarPermissoes()
			this.route.Navigate('/dashboard')
		},
		async OnClick(event, item){  
			this.emiteVerificarAtualizacao()
			this.atualizarPermissoes() 
			new MenuComponent(this.menu).MenuNavigate(item)
			// n tirar isso, ela serve para atualizar os valores das mutations do vuex
			// e as permissões do usuario sem ela vai dar bug
			this.refresh()
			this.menu = this.load()
			// this.$nextTick(()=>{
			new EmpresasService().Refresh()
			// })
		},
		OnCollapse (collapsed) {
			this.collapsed = collapsed
		},

		/**
        * @description Método para atualizar as permissões do usário enquanto
        *   estiver mexendo no menu, faz uma requisição pelo AuthService
        *   buscando as permissões do usuário e atualizando em chache 
        *   pelo updatePermissions
        * @author Vitor Hugo 🐨
        */
		atualizarPermissoes(){
			var service = new AuthService();
			service.atualizaPermissoes(this.cliente, this.perfil, this.usumaster, this.idUser)
			this.updatePermissions()
		},

		/**
        * @description Método emite um evento global para o toastCustom 
        *   saber que deve verificar a atualização do sistema
        * @author Vitor Hugo 🐨
        */
		emiteVerificarAtualizacao(){
			this.$root.$emit('verificaAtualizacao');
		}
	},

	mounted() {
		/**
        * Recebe o evento de alteração do perfil itens
        * e chama a função load para atualizar a variável menu
        * ps: (foi a maneira que encontrei de fazer isso, 
        *   caso tenha uma maneira melho me avisee)
        * @author Vitor Hugo 🐨
        */
		this.$root.$on('atualizarMenu', () => {
			this.menu = this.load()
		});
	}
}
</script>
<style scope lang="scss">
//TESTE PARA FAZER A BOLINHA PARAR DE GIRAR! 😢

.v-sidebar-menu {
    .vsm--arrow.vsm--arrow_open {
        transform: none;
        animation: none
    }
}

// CSSs que já existiam!
    .v-sidebar-menu .vsm--toggle-btn {
        padding: 0;
    }
    #menu {
        padding-left: 237px;
    }
    #menu.collapsed {
        padding-left: 50px;
    } 
    ::-webkit-scrollbar {
        width: 10px;
    }
    ::-webkit-scrollbar-track {
        background: #2a2a2e;;
    }
    ::-webkit-scrollbar-thumb {
        background: #525252;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #4285F4;
    }
    .buttonHeader{
        font-size: 13px;
        background-color: #4285F4;
        height: 40px;
        width: 100%;
        padding: 5px;
        margin-top: 0px;
        justify-content: center;
        text-align: center;
        display: flex;
        cursor: pointer;
        .icon{
            padding-right: 10px !important;
        }
        p{
            margin-top: 5px!important;
        }
    }
    .icon{
        font-weight: 10 !important;
        margin-left: -1rem !important;  
    }
    .v-sidebar-menu .vsm--link {
        padding-right:0 !important;
        padding-left: 0px !important;
        padding-bottom: 10px!important;
        padding-top: 10px!important;
        font-weight: 100 !important;
        font-size: 13px !important;
    }
    .v-sidebar-menu .vsm--link.vsm--link_level-2 {
        font-weight: 50 !important;
        font-size: 12px !important;
    }
    .v-sidebar-menu .vsm--icon {
        background-color: transparent !important;
        margin-left: -0.25rem;
        margin-right: 20px!important;
        margin-top: -4px;
        &.icon{
            margin-right: 10px !important;
            margin-left: 10px !important;
            font-size: 22px !important;
        }
    }
    .router-link-active{
        background-color: #4285F4;
    }
    .v-sidebar-menu .vsm--dropdown .vsm--list {
        font-size: 35px !important;
    }

    .v-sidebar-menu .vsm--item.vsm--item_open {
        padding: 0;
    }

    @media print {
        .v-sidebar-menu{
            display: none !important;
        }

        #menu{
            padding-left: 0px !important;
        }
    }


</style>