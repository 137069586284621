<template>
	<EaglePanel id='historicoDePosicoes' :loading='gerandoRelatorio'>
		<div class="col-sm-12 nopadding ">
			<div class="col-sm-12  row">
				<div class="col-sm-6 nopadding">
					<titulo titulo="Histórico de posições" :icon="mdiMapMarkerPath" />
				</div>
				<div class="col-sm-6 nopadding divDontPrint">
					<botoes :loading='loading' :disabled='formInvalido' @gerarRelatorio='gerarRelatorio'
						@exportarRelatorio='exportarRelatorio' :disabledDropdown='relatorioResumido ?
						$v.dataRelResumido.$invalid :
						$v.dataRelDetalhado.$invalid'>
						<simpleButton :type='relatorioResumido?"grey":"green"' text='Detalhado' event='click'
							@click="relatorioResumido = !relatorioResumido" />
						<simpleButton :type='relatorioResumido?"green":"grey"' event='click' text='Resumido'
							@click="relatorioResumido = !relatorioResumido" />
					</botoes>
				</div>
			</div>
			<div class="col-sm-12 nopadding row">
				<slideUpDown>
					<div class="col-sm-12 somepadding row">
						<div class="col-sm-12">
							<hr>
						</div>
						<div class="col-sm-3 nopadding">
							<div class="col-sm-12 nopadding" id='div_dayPicker'>
								<dayPicker name='periodoRelatorio' :intervalo='7' id='periodoRelatorio' opens='right'
									:isObrigatorio='true' @changeInput='mudaIntervalo' :extraClass="{
									'border border-danger':
									(tamanhoIntervalo>7)
								}" :ComTitulo='false' />
							</div>
							<b-tooltip target="div_dayPicker" triggers="hover" v-if='tamanhoIntervalo>7'>
								período selecionado inválido! (Máximo 7 dias)
							</b-tooltip>
							<div class="col-sm-12 nopadding row">
								<div class="col-sm-6 linha2">
									<inputSimple label='Hora Inicial' type='time' value='00:00'
										@changeInput='v=>relatorio.horaInicial =v' />
								</div>
								<div class="col-sm-6 linha2">
									<inputSimple label='Hora Final' type='time' value='23:59'
										@changeInput='v=> relatorio.horaFinal = v' />
								</div>
							</div>

						</div>
						<div class="col-sm-3 nopadding">
							<selectAll v-bind="{
								nameForRadio:'seletorEmpresaPosicoes',
								labels:labelSeletorEmpresas,
								isMultiple:true,
								optionsArray:optSelectEmpresas,
								hasSelectAll:true,
								selected:selectedEmpresa
							}" v-on="{ changeSelect:mudaSeletorEmpresa }" />

							<div class="col-sm-12 mt-4 nopadding row" v-show='relatorioResumido'
								style="margin-left: 35px !important;">
								<b-check class="nopadding" v-model="check.prefixo"
									@change='() => limpaInfoRelatorios()'>
									Ordenar por prefixo
								</b-check>
							</div>

						</div>
						<div class="col-sm-3 nopadding">
							<selectAll v-bind="{
								loading:loadingBtn,
								nameForRadio:'seletorVeiculo',
								labels:labelSeletorVeiculo,
								isMultiple:true,
								optionsArray:optSelectVeiculos,
								hasSelectAll:true,
								firstSelected:'V',
								disabled: !optSelectVeiculos.length,
								ref:'seletorVeiculo'
							}" v-on="{
								changeSelect:mudaSeletorVeiculo,
								changeCheck:mudaCheckVeiculo,
							}" />

							<div class="col-sm-12 mt-4 nopadding row" v-show='relatorioResumido'
								style="margin-left: 35px !important;">
								<b-check class="nopadding" v-model="check.apenasJornada"
									@change='() => limpaInfoRelatorios()'>
									Visualizar somente informações de jornada
								</b-check>
							</div>
						</div>
						<div class="col-sm-3 nopadding row" v-show='relatorioResumido'>
							<filters label='Tempo parado acima de' :arrayButtons='btsFiltroTempo' zeroValue='0'
								@buttonsFiltersChange='mudaFiltroTempo' />
						</div>
						<div class="col-sm-3 nopadding">
							<selectAll v-show='!relatorioResumido' v-bind="{
								loading:loadingBtn,
								nameForRadio:'seletorTrabalhador',
								labels:labelSeletorTrabalhador,
								optionsArray:optSelectTrabalhador,
								isMultiple:true,
								hasSelectAll:true,
								firstSelected:'C',
								disabled: !optSelectTrabalhador.length,
								ref:'seletorTrabalhador'
							}" v-on="{
								changeSelect:mudaSeletorTrabalhador,
								changeCheck:mudaCheckTrabalhador
							}" />
						</div>
					</div>
				</slideUpDown>
			</div>
			<div class="col-sm-12">
				<hr>
			</div>
		</div>
		<div class="col-sm-12" v-if='relatorioResumido'>
			<tableSimples>
				<thead>
					<tr>
						<th class="fonte-tr-resumido">Data</th>
						<th class="fonte-tr-resumido">Ocorrência</th>
						<th class="fonte-tr-resumido">Inicio</th>
						<th class="fonte-tr-resumido">Fim</th>
						<th class="fonte-tr-resumido">Tempo</th>
						<th class="fonte-tr-resumido">Ponto</th>
						<th class="fonte-tr-resumido">Localização</th>
						<th class="fonte-tr-resumido">{{colunaMedicaoHodometro}}<br>{{colunaMedicaoHorimetro}}</th>
						<th class="fonte-tr-resumido">Motorista</th>
						<th class="fonte-tr-resumido">Ajudante</th>
					</tr>
				</thead>
				<tbody v-if="dataRelResumido.length">
					<tr>
						<td colspan='12' class='cedulaProcurar'>
							<input placeholder='Buscar' v-model='searchQ' />
						</td>
					</tr>
					<template v-for="(data, index) in dataRelResumido">
						<tr :key='index + "__placa"'>
							<td colspan="3" v-text='data.placa' class="badgePlaca"></td>
						</tr>
						<template v-for='(item, i) in data'>
							<tr v-show='oc.localizacao.match(RegExp(searchQ, "i"))' v-for='(oc, j) in item.dados'
								:key='j + "_dados" + oc.unique'>
								<td class="fonte-td-resumido" v-text="oc.data"></td>
								<td class="fonte-td-resumido" v-text="oc.ocorrencia"></td>
								<td class="fonte-td-resumido" v-text="oc.inicio"></td>
								<td class="fonte-td-resumido" v-text="oc.fim"></td>
								<td class="fonte-td-resumido" v-text="oc.tempo"></td>
								<td class="fonte-td-resumido" v-text="oc.ponto"></td>
								<td class="fonte-td-resumido" v-text="oc.localizacao"></td>
								<td class="fonte-td-resumido" v-text="oc.distancia"></td>
								<td class="fonte-td-resumido" v-text="oc.motorista"></td>
								<td class="fonte-td-resumido" v-text="oc.ajudante"></td>
							</tr>
							<tr  :key='item.totais.unique' v-if="item.totais != undefined">
								<td class="fonte-td-resumido negrito totais" colspan="2">Total em jornada: {{
									item.totais.jornada }}</td>
								<td class="fonte-td-resumido negrito totais" colspan="3">Total em movimento: {{
									item.totais.movimento }}</td>
								<td class="fonte-td-resumido negrito totais" colspan="1">Total parado: {{ item.totais.parado }}
								</td>
								<td class="fonte-td-resumido negrito totais" colspan="2">Total em intervalo: {{
									item.totais.intervalo }}</td>
								<td class="fonte-td-resumido negrito totais" colspan="2">Total em descanso: {{
									item.totais.descanso }}</td>
							</tr>
						</template>
					</template>



				</tbody>
				<tbody v-else>
					<status :statusBar='infoResumido' />
				</tbody>
			</tableSimples>
		</div>
		<div class="col-sm-12" v-else>
			<tableSimples>
				<thead>
					<tr>
						<th>Data</th>
						<th>Horário</th>
						<th>Tempo Parado</th>
						<th>Evento</th>
						<th>Local</th>
						<th>Colaborador</th>
						<th>Percorrido</th>
						<th>Hodômetro</th>
						<th>Velocidade</th>
						<th>Ponto</th>
						<th>Região</th>
						<th>Temp(°C)</th>
						<th>Localizar</th>
					</tr>
				</thead>
				<tbody v-if='dataRelDetalhado.length'>
					<tr>
						<td></td>
						<td colspan='12' class='cedulaProcurar'>
							<input placeholder='Buscar' v-model='searchQ' class='inputBusca' />
						</td>
					</tr>
					<template v-for='(rec, index) in dataRelDetalhado'>
						<tr :key='index+"_placa_"+rec.placa'>
							<td class="badgePlaca mt-2" v-text="rec.placa" colspan="4" />
						</tr>
						<template v-for="(info, i) in rec.dados">
							<tr v-show='
								info.endereco.toLowerCase()
								.includes(searchQ.trim().toLowerCase())' :key='rec.placa+"__dados"+i'
								:class="defineClasse(info.evento)+' dadosTabela'">
								<td v-text="info.data" />
								<td v-text="info.hora" />
								<td v-text="info.tempo" />
								<td class='celIconeTexto'>
									<baseIcon :class='info.evento' size="18" :icon="defineIcone(info.evento)" />
									<span v-text='"\n"+defineTexto(info.evento)' />
								</td>
								<td class='enderecoDetalhado' v-text="info.endereco+' / '+info.cidade" />
								<td class='enderecoDetalhado' v-text="info.motorista" />
								<td v-text="info.kms+'kms'" />
								<td v-text="info.hodometro+'km'" />
								<td v-text="info.velocidade" />
								<td v-text="info.ponto" />
								<td v-text="decideRegiao(info.regiao)" />
								<td v-text="info.temperatura" />
								<td event='click' @click="localizar(info.latlon)">
									<baseIcon size="18" class="cursor-pointer colorIconLocalizar" :icon="mdiTarget" />
								</td>
							</tr>
						</template>
					</template>
				</tbody>
				<tbody v-else>
					<status :statusBar='infoDetalhado' :colspanForTd='13' />
				</tbody>
			</tableSimples>
		</div>
	</EaglePanel>
</template>
<script>
import moment from 'moment'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex'
import simpleButton from '@/components/Atom/Buttons/SimpleButton'
import botoes from '@/components/Atom/Buttons/BasicButtonsRelatoriosEIG'
import dayPicker from '@/components/Atom/Datas/InputRangeWithLimit'
import status from '@/components/Atom/StatusInformation/StatusInformation'
import slideUpDown from '@/components/Atom/SlideUpAndDown/SlideUpAndDown'
import {
	mdiMapMarkerPath,
	mdiRoadVariant,
	mdiCarKey,
	mdiSquare,
	mdiCarTractionControl,
	mdiBattery,
	mdiTarget,
	mdiCarConnected
} from '@mdi/js'
import { conectionError } from '@/Services/Helpers/swellHeper'
export default {
	name:'HistoricoDePosicoes',
	components: {
		baseIcon:require('@/components/Atom/Icon/BaseIcon.vue').default,
		EaglePanel   : require('@/components/Atom/Panel/PanelEagle').default,
		titulo       : require('@/components/Atom/Header/Titulo').default,
		simpleButton,
		botoes,
		dayPicker,
		inputSimple  : require('@/components/Atom/Inputs/InputSimple').default,
		selectAll    : require('@/components/Atom/Select/SelectAll').default,
		filters        : require('@/components/Atom/Buttons/ButtonsFilters').default,
		tableSimples : require('@/components/Atom/Table/TableSimples').default,
		status,
		slideUpDown,
	},
	validations: {
		dataRelDetalhado: { required },
		dataRelResumido : { required }
	},
	data() {
		return {
			loadingBtn:false,
			mdiTarget:mdiTarget,
			mdiCarKey:mdiCarKey,
			mdiSquare:mdiSquare,
			mdiBattery:mdiBattery,
			mdiRoadVariant:mdiRoadVariant,
			mdiMapMarkerPath:mdiMapMarkerPath,
			mdiCarTractionControl:mdiCarTractionControl,
			mdiCarConnected: mdiCarConnected,
			optSelectEmpresas: new EmpresasService().Get(),
			baseUri: '/telemetria/relatorios/historico/posicoes/',
			optSelectVeiculos:[],
			optSelectTrabalhador: [],
			gerandoRelatorio: false,
			relatorioResumido: false,
			allOpt:[],
			colunaMedicaoHodometro: 'Hodômetro',
			colunaMedicaoHorimetro: '',
			labelSeletorEmpresas:[{description:'Empresas*'}],
			labelSeletorVeiculo:[
				{indexDFH:'V',  description:'Veiculos*'},
				{indexDFH:'GV', description:'Grupos*'}
			],
			// no back ele ta pegando como GV e tava como GC,
			// n sei pq, mas mudei aqui
			labelSeletorTrabalhador:[
				{indexDFH:'C',  description:'Colaborador'},
				{indexDFH:'GC', description:'Grupos'}
			],
			loading:[false, false, false],
			relatorio:{
				cliente     : [],
				veiculo     : [],
				trabalhador : [],
				intervalo   : '',
				horaInicial : '',
				horaFinal   : '',
			}, 
			check:{
				veiculo     :'',
				trabalhador :'',
				prefixo : false,
				apenasJornada: false
			},
			dataRelResumido  : [],
			dataRelDetalhado : [],
			searchQ          : '',
			infoDetalhado    : 'info',
			infoResumido     : 'info',
			selectedEmpresa  : [],
			tempoParado: '',
			btsFiltroTempo: [
				{ 'value': '1', 'isSelected': false, 'width': '30%', 'text': '1 min' },
				{ 'value': '5', 'isSelected': false, 'width': '30%', 'text': '5 min' },
				{ 'value': '10', 'isSelected': false, 'width': '30%', 'text': '10 min' },
				{ 'value': '15', 'isSelected': false, 'width': '30%', 'text': '15 min' },
				{ 'value': '30', 'isSelected': false, 'width': '30%', 'text': '30 min' },
				{ 'value': '60', 'isSelected': false, 'width': '30%', 'text': '60 min' },
			],
		}
	},
	methods: {
		...mapGetters(['getMaster']),
		/**
		 * @param {string|null} regiao
		 * @return {string} nome da região OU Sem região
		 */
		decideRegiao(regiao){
			if(regiao) return regiao 
			return 'Sem região'
		},

		/**
		* Função que faz o request para gerar o relatório
		*/
		gerarRelatorio(){
			this.dataRelResumido = []
			this.dataRelDetalhado = []
			this.gerandoRelatorio = true
			let uri = this.baseUri +
				(this.relatorioResumido?'resumido':'completo')
			new HttpRequest().Post(uri, this.objRequest, 100000)
				.then(data=>{
					this.gerandoRelatorio = false
					let dados;
					if(data.data.rel.dadosresumido != undefined){
						dados = data.data.rel.dadosresumido;
					}else{
						dados = data.data.rel;
					}
					this.processaRelatorio(dados)
					//ungly code -> refatorar essas condicionais
					if(data.data.rel.horimetrohodometro == 'hodometro/horimetro'){
						this.colunaMedicaoHodometro = 'Hodômetro';
						this.colunaMedicaoHorimetro = 'Horímetro';
					}else if(data.data.rel.horimetrohodometro == 'hodometro'){
						this.colunaMedicaoHodometro = 'Hodômetro';
						this.colunaMedicaoHorimetro = '';
					}else if(data.data.rel.horimetrohodometro == 'horimetro'){
						this.colunaMedicaoHodometro = '';
						this.colunaMedicaoHorimetro = 'Horímetro';
					}else{
						this.colunaMedicaoHodometro = 'Hodômetro';
					}
				}).catch(()=>{
					conectionError()
				})
		},
		
		/**
		* Quando a empresa principal é altera, já faz a request para
		* carregar as opções do seletor de Veiculos e trabalhadores
		*/
		mudaSeletorEmpresa(arr){
			this.limpaRelacaocliente()
			this.relatorio.cliente = arr
			if(this.relatorio.cliente.length){
				this.loadingBtn = true
				new FiltrosService()
					.dados_filtros( this.relatorio.cliente, [
						'V', 'GV', 'C', 'GC'
					]).then(data=>{
						this.loadingBtn = false
						this.allOpt = data
						this.optSelectVeiculos = this.allOpt[this.check.veiculo]
						this.optSelectTrabalhador = this.allOpt[this.check.trabalhador]
					})
			}
		},

		localizar(latLong){
			window.open(`https://www.google.com/maps/place/${latLong}`)
		},
		/**
		* Recebe o valor do filtro de tempo que o usuário seleciona
		* @param value
     	* @author Otávio 🦆
		*/
		mudaFiltroTempo(value) {
			this.tempoParado = value;
			this.limpaInfoRelatorios();
		},
		exportarRelatorio(tipo){
			var root =  process.env.VUE_APP_ROOT;
			this.loadingExportacao(tipo)
			let uri = this.baseUri+'exportar'
			let obj = {
				'tipo':tipo,
				'relResumido': this.relatorioResumido,
				'intervalo': this.relatorio.intervalo,
				'hodometro': this.colunaMedicaoHodometro,
				'horimetro': this.colunaMedicaoHorimetro,
				'dados':(
						this.relatorioResumido?
						this.dataRelResumido :
						this.dataRelDetalhado
					),
			}
			new HttpRequest().Post(uri, obj).then(data=>{
					this.loadingExportacao()
					if(data.status)
						window.open(root+'/'+data.data.local)
				})
		},

		loadingExportacao(tipo=''){
			if(tipo === 'pdf')
				this.loading = [true, false, false]
			if(tipo === 'xls')
				this.loading = [false, true, false]
			if(tipo === 'csv')
				this.loading = [false, false, true]
			if(tipo === '')
				this.loading = this.loading.map(a=> a = false)
		},

		limpaInfoRelatorios(){
			this.dataRelResumido = []
			this.dataRelDetalhado = []
			this.colunaMedicaoHodometro = 'Hodômetro';
			this.colunaMedicaoHorimetro = '';
		},

		/** 
		* Função que existe só para centralizar as coisas que devem 
		* ser "limpas" quando a empresa selecionada muda
		*/
		limpaRelacaocliente(){
			this.optSelectVeiculos = []
			this.optSelectTrabalhador = []
			this.dataRelResumido = []
			this.dataRelDetalhado = []
			this.$refs.seletorTrabalhador.clearAll()
			this.$refs.seletorVeiculo.clearAll()
		},

		mudaSeletorVeiculo(arr){
			this.relatorio.veiculo = arr
			this.limpaInfoRelatorios()
		},

		mudaSeletorTrabalhador(arr){
			this.relatorio.trabalhador = arr
			this.limpaInfoRelatorios()
		},

		mudaCheckVeiculo(check){
			this.check.veiculo = check
			this.limpaInfoRelatorios()
			if(this.allOpt[this.check.veiculo])
				this.optSelectVeiculos = this.allOpt[this.check.veiculo]
		},

		mudaCheckTrabalhador(check){
			this.check.trabalhador = check
			this.limpaInfoRelatorios()
			if(this.allOpt[this.check.trabalhador])
				this.optSelectTrabalhador = this.allOpt[this.check.trabalhador]
		},

		mudaIntervalo(data){
			this.relatorio.intervalo = data
			this.limpaInfoRelatorios()
		},

		/**
		* Processa dados do relatório para 
		* facilitar a exibição
		*/
		processaRelatorio(data){
			var mapa = []
			var obj = {}
			this.infoDetalhado = 'loading'
			this.infoResumido = 'loading'
			if(this.relatorioResumido){
				this.infoDetalhado = 'info'
				this.infoResumido = 'info'
				Object.keys(data).forEach(key => {
					obj = {
						placa:key,
						...data[key],
					}
					mapa.push(obj)
				})
				if(!mapa.length) this.infoResumido = 'error'
				this.dataRelResumido = mapa
			}else{
				this.infoDetalhado = 'info'
				this.infoResumido = 'info'
				Object.keys(data).forEach(key=>{
					obj = {
						'placa': key,
						'dados': data[key],
					}
					mapa.push(obj)
				})
				if(!mapa.length) this.infoDetalhado = 'error'
				this.dataRelDetalhado = mapa
			}
		},

		/** 
		* Define a classe que a linha da tabela vai ter de acordo 
		* com o evento em questão.
		*/
		defineClasse(evento){
			switch(evento.toLowerCase()){
				case 'm':
					return 'fundoVerde'
				default:
					return 'fundoVermelho'
			}
		},

		/** 
		* Define o texto que vai aparecer na cedula 
		* do evento
		*/
		defineTexto(evento){
			switch(evento.toLowerCase()){
				case 'm':
					return 'Movimento'
				case 'il':
					return 'Ignição Ligada'
				case 'fp': 
					return 'Fim Parada'
				case 'ip':
					return 'Inicio Parada'
				case 'id': 
					return 'Ignição Desligada'
				case 'mi':
					return 'Movimento Indevido'
				case 'ec':
					return 'Energia Cortada'
				case 'er' :
					return 'Energia Restaurada'
				case 'p1':
					return 'Porta 1 Acionada'
				case 'p2':
					return 'Porta 2 Acionada'
				case 'p3':
					return 'Porta 3 Acionada'
				case 'p4':
					return 'Porta 4 Acionada'
			}
		},

		/** 
		* Define qual dos ícones vai aparecer ao lado 
		* do texto da celula do evento
		*/
		defineIcone(evento){
			switch(evento.toLowerCase()){
				case 'm':
					// mdiRoadVariant
					return this.mdiRoadVariant 
				case 'il':
					// mdiCarKey
					return this.mdiCarKey 
				case 'fp': 
					// mdiSquare
					return this.mdiSquare 
				case 'ip':
					// mdiSquare
					return this.mdiSquare 
				case 'id': 
					// mdiCarKey
					return this.mdiCarKey 
				case 'mi':
					// mdiCarTractionControl
					return this.mdiCarTractionControl 
				case 'ec':
					// mdiBattery
					return this.mdiBattery 
				case 'er':
					// mdiBattery
					return this.mdiBattery 
				default:
					return this.mdiCarConnected
			}
		}
	},
	computed: {

		/** 
		* Prepara o objeto que deve ser enviado 
		* para o back-end para gerar o relatório
		*/
		objRequest() {
			if(this.relatorioResumido){
				return {
					dataFim    : this.datas.fim,
					dataInicio : this.datas.ini, 
					clientes   : this.relatorio.cliente,
					veiculos   : this.relatorio.veiculo,
					tipeV      : this.check.veiculo,
					filtroTempo : this.tempoParado[0],
					apenasJornada : this.check.apenasJornada,
					prefixo : this.check.prefixo
				}
			}else{
				return {
					dini       : this.datas.ini,
					dfim       : this.datas.fim,
					tipeV      : this.check.veiculo,
					tipeM      : this.check.trabalhador,
					empresas   : this.relatorio.cliente,
					veiculos   : this.relatorio.veiculo,
					motoristas : this.relatorio.trabalhador
				}
			}
		},
        
		/**
		* Separa o campo intervalo em 2 datas 
		* para enviar para o back-end 🍺
		*/
		datas(){
			if(this.relatorio.intervalo === ''){
				return {
					ini:moment(),
					fim:moment()
				}
			}
			var datas =  this.relatorio.intervalo.split(' - ')
			let inicio = datas[0].split('/')
			let fim = datas[1].split('/')
			inicio = inicio.
				reverse()
				.join('-')+' '+this.relatorio.horaInicial+':00'
			fim = fim.reverse().join('-')+' '+this.relatorio.horaFinal+':59'
			return {
				ini: inicio,
				fim: fim
			}
		},

		tamanhoIntervalo(){
			let ini = moment(new Date(this.datas.ini))
			let fim = moment(new Date(this.datas.fim))

			return fim.diff(ini, 'days')
		},

		formInvalido(){
			if(this.tamanhoIntervalo > 7){
				return true
			}
			if(!this.relatorio.veiculo.length
				&&
				!this.relatorio.trabalhador.length){
				return true
			}
			return false
		},
	},
	watch: {
		'relatorio.horaInicial'(){
			this.dataRelDetalhado = []
			this.dataRelResumido = []
		},
		'relatorio.horaFinal'(){
			this.dataRelDetalhado = []
			this.dataRelResumido = []
		},
		relatorioResumido() {
			this.dataRelDetalhado = [] 
			this.dataRelResumido = []
			this.searchQ = ''
			this.$refs.seletorTrabalhador.clearAll()
			this.infoDetalhado = 'info'
			this.infoResumido = 'info'
		}
	},

	mounted() {
		if(!this.getMaster()){
			this.selectedEmpresa = this.optSelectEmpresas
		}
	}
}
</script>
<style lang="scss" scoped>
.cedulaProcurar{
    text-align: right;
}
.inputBusca{
    border: 1px solid black ;
    padding-left: 5px;
    padding-top: 2px;
}
.fundoVerde{
    background-color: #dff0d8 !important;
}
.fundoVermelho{
    background-color: #f2dede !important;
}
.badgePlaca{
    background-color: #777;
    padding-left: auto;
    padding-right: auto;
    color: white;
    margin-top: 5px;
    padding-top: 4px !important;
    font-size: 15px !important;
    text-align: center !important;
}
.linha2{
    padding: 0 !important;
    margin-top: 1%;
    margin-bottom: 1%;
}
.somepadding{
    padding-left: 0.5% !important;
    padding-right: 0.5% !important;
}
.botao{
    cursor: pointer;
}
.fonte-td-resumido{
	font-size: 11px !important;
}
.totais{
	background-color: #d5d5d5 !important;
}
.fonte-tr-resumido{
	font-size: 11px !important;
}
.icone::before{
    font-size: 12px !important;
    padding: 0 !important;
    border: 0 !important;
    margin: 0 !important;
}
.red{
    color:red;
}
.green{ 
    color: green;
}
.busca{
    padding-left:40%;
    max-height: 20px !important;
}
.celIconeTexto{
    align-content: left !important;
    width: 10%;
    padding: 1px;
}
.enderecoDetalhado{
    width: 20%;
    padding: 1px;
}
.dadosTabela{
    td{
        padding: 3px;
    }
}
.square { 
  display: inline-block !important;
  width: 10px !important;
  height: 10px !important;
  background-color: black;
  border-radius: 2px !important;
}
th{
    padding-left: 0.6% !important;
    min-width: 80px;
}
.colorIconLocalizar{
    color: #2196f3;
}
.IL{
    color: #018001;
}
.ID{
    color: #fe0c0c;
}
.EC{
   color: #fe0c0c; 
}
.ER{
    color: #018001;
}
.totalizador{
	background: rgba(213, 213, 213, 0.7);
}
.negrito{
    font-weight: bold;
}
</style>
