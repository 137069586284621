scroll infinito: https://www.javascripttutorial.net/javascript-dom/javascript-infinite-scroll/
<template>
    <VueDragResize  :isActive='true' dragHandle='[id$="_draggable"]'>
        <div id='historicoPosicoesVeiculo'>
            <b-overlay :show='loading' id='overlay_draggable'>
                <div
                class='d-flex flex-column'>
                    <div
                    id='historicoPosicoesVeiculo_draggable'
                    class='pt-2 d-flex flex-row justify-content-between'>
                        <div>
                            <base-icon
                            :size='18'
                            id='icone_draggable'
                            class='ml-3 mr-2'
                            :icon='iconTitutlo'/>
                            <span class="mt-2" id='titulo_draggable'>
                                Lista de Posições
                            </span>
                        </div>
                        <div>
                            <b-button
                            variant='danger'
                            squared
                            class='mr-2'
                            size='sm'
                            @click='fechaPosicoesVeiculo'>
                                <base-icon
                                :size='16'
                                :icon='iconFechar'/>
                            </b-button>
                        </div>
                    </div>
                    <div><hr style="
                        border-top: 1px solid lightgrey !important;
                        width: 95%;
                    "></div>
                    <div class='buttonsAr'>
                        <b-button-group class='d-flex px-2'>
                            <b-button
                            @click='clicaFiltro("movimento")'
                            :class='"mr-1 botaoL "+( 
                                filtroMovimento?"movimento":"pressed"
                            )'>
                                Em movimento
                            </b-button>
                            <b-button
                            @click='clicaFiltro("parada")'
                            :class='"mr-1 botaoL "+(
                                filtroParadas?"parada": "pressed"
                            )'>
                                Paradas
                            </b-button>
                            <b-button
                            @click='clicaFiltro("excesso")'
                            :class='"botaoL "+(
                                filtroExcesso?"velocidade":"pressed"
                            )'>
                                Excessos de Velocidade
                            </b-button>
                        </b-button-group>
                        <div class='py-1 px-3 d-flex justify-content-between'>
                            <span class='mr-2 pt-1'>
                                Buscar por endereço
                            </span>
                            <input class='form-search' v-model='query'>
                        </div>
                    </div>
                </div>
                <div class='d-flex justify-content-center tabelaPos' ref="tablePos">
                    <table>
                        <thead> 
                            <tr class='header'>
                                <th>Data/hora</th>
                                <th>Endereço</th>
                                <th>Velocidade</th>
                                <th>Km</th>
                                <th>Temperatura</th>
                                <th>Ignição</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody ref='bodyTable'>
                            <template  v-for='(pos, index) in filtroBusca'>
                                <tr
                                :key='index'
                                :class='`linhaTable ${defineClasse(pos)}`'>
                                    <td
                                    :id='`celula_data_${index}`'
                                    class='celula'
                                    v-text='pos.data'
                                    :title='pos.data'/>
                                    <td
                                    :id='`celula_endereco_${index}`'
                                    class='celulaEndereco'
                                    v-text='pos.endereco'
                                    :title='pos.endereco'/>
                                    <td
                                    :id="`celula_velocidade_${index}`"
                                    class='celula'
                                    v-text="pos.velocidade+' Km/h'"
                                    :title="pos.velocidade+' Km/h'"/>
                                    <td
                                    :id='`celula_km_${index}`'
                                    class='celula'
									:title='decideKm(pos, index)'
									v-text='decideKm(pos, index)'/>
                                    <td 
                                    class='celula'
                                    :title='defineTemperatura(pos.temperatura)'
                                    v-text='defineTemperatura(pos.temperatura)'
                                    :id='`celula_temperatura_${index}`'/>
                                    <td
                                    :id='`celula_ignicao_${index}`'
                                    class='celula'>
                                        <base-icon
                                        v-bind='defineIgnicao(pos.ignicao)'/>
                                    </td>
                                    <td
                                    :id='`celula_local_${index}`'
                                    class='celula' >
                                        <span class='botaoLocal'
                                        @click='localizacaoPosicao(pos)'>
                                            <base-icon 
                                            :size='14' 
                                            :icon='iconLocalizacao'/>
                                        </span>
                                    </td>
                                </tr>
								<tr :key='`tr_${index}`'>
									<td colspan="7" class='td-linha'>
										<div
										:key='`hr_${index}`'
										class='linha-tabela'/>
									</td>
								</tr>
                            </template>
                        </tbody>
                    </table> 
                </div>
            </b-overlay>
        </div>
    </VueDragResize>
</template>

<script>
import BaseIcon from '@/components/Atom/Icon/BaseIcon.vue'
import {
	mdiMapMarker,
	mdiCloseThick,
	mdiCrosshairsGps,
	mdiCheckCircle,
	mdiCloseCircle } from '@mdi/js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import VueDragResize from 'vue-drag-resize'
import { latLngMaisProximo } from '@/Services/Helpers/latitudeLongitudeHelper'
export default {
	name:'HistoricoDePosicoes',
	components:{
		VueDragResize,
		BaseIcon,
	},
	data(){
		return {
			query:'',
			iconLocalizacao : mdiCrosshairsGps,
			iconTitutlo     : mdiMapMarker,
			iconFechar      : mdiCloseThick,
			arrPosicoes     : [],
			arrPosicoesSoma : [],
			baseUri         : '/finder/veiculo/',
			filtroMovimento : true,
			filtroParadas   : true,
			filtroExcesso   : true,
			loading         : false,
			objBuscarPosicoes: {},
			next_page:'',
			procurandoPos:false,
			obj:{},
		}
	},
	methods:{
		/**
		 * @param {object} posicao
		 * @return {number} kms até essa posição, segundo a array de
		 * controle (arrPosicoesSoma).
		 */
		decideKm(posicao){
			var index_origial = _.findIndex(this.arrPosicoes, posicao)
			var km_atual = this.arrPosicoesSoma[index_origial]
			if(km_atual)
				return `${km_atual}km`
		},
		/**
		 * @param {(number|undefined)}temp
		 * @description define como a temp. vai ser 
		 * exibida.
		 */
		defineTemperatura(temp){
			if(typeof temp === 'number')
				return `${temp}°C`
			return '-'
		},

		/**
		 * @listens click - em qualquer filtro de tipo de posicionamento.
		 * @param {string} tipo - qual motivo filtrar.
		 * @description alterna a vizibilidade de elementos
		 * baseado na classe deles, fiz dessa maneira na expectativa
		 * de ser mais rápido que era filtrando arrays.
		 * Dessa maneira eu sei exatamente quais elementos que
		 * devem ser alterados.
		 */
		clicaFiltro(tipo){
			switch(tipo){
				case 'movimento':
					this.filtroMovimento = !this.filtroMovimento
					break
				case 'parada':
					this.filtroParadas = !this.filtroParadas
					break
				case 'excesso':
					this.filtroExcesso = !this.filtroExcesso
					break
			}
		},

		/**
		 * @listens click - botão de fechar a janela de posições.
		 * @description avisa o mapa que a janela deve ser fechada 
		 * e limpa a array de posições.
		 * @fires fecha-posicoes.
		 */
		fechaPosicoesVeiculo(){
			this.arrPosicoes = []
			this.$emit('fecha-posicoes')
		},

		/**
		 * @param {object} obj - objeto a ser enviado para o back-end.
		 * @param {string} obj.dataFim - contendo a data + horario do fim da
		 * pesquisa. ex: 20/08/2021 14:00:00
		 * @param {data} obj.dataIni - contento a data + horario fo final 
		 * da pesquisa - ex: 23/08/2021 10:00:00
		 * @param {string} obj.placa
		 * @description procura pelas posições do veículo.
		 * Se não vier a placa, não faz a busca
		 */
		iniciaHistoricoDePosicoes(obj){
			this.arrPosicoes = []
			this.obj = obj
			if(obj.placa){
				this.loading = true
				let uri = this.baseUri+'listar/posicoes'
				new HttpRequest().Post(uri, obj).then((res)=>{
					this.arrPosicoes = res.data.data
					this.next_page = res.data.next_page_url
					this.$refs.tablePos.addEventListener('scroll', ()=>{
						const {
							scrollTop,
							scrollHeight,
							clientHeight
						} = this.$refs.tablePos
						if(scrollTop + clientHeight >= scrollHeight - 5){
							this.buscaMaisPosicao()
						}
					})
				}).finally(()=>{
					this.loading = false
				})
			}
		},

		
		/**
		 * @description Caso existir uma próxima página de posições,
		 * essa função busca por essa página e faz o controle do carregamento.
		 * @todo essa função tá um tanto mais complexa do que precisava ser.
		 * Quando der, dava para refatorar e melhorar um pouco.
		 */
		buscaMaisPosicao(){
			var obj = this.obj
			var reg = /\d.?$/
			if(this.next_page && !this.loading){
				this.loading = true
				var number = reg.exec(this.next_page)
				var url = this.baseUri+'listar/posicoes?page='+number
				new HttpRequest().Post(url, obj).then((res)=>{
					this.arrPosicoes = this.arrPosicoes.concat(res.data.data)
					this.next_page = res.data.next_page_url ?? false
					this.loading = false
				})
			}
		},
		
		/**
		 * @param {object} pos - objeto que representa aquela posição no mapa.
		 * @fires local-posicao.
		 * @description envia a localização de uma posição para o mapa.
		 */
		localizacaoPosicao(pos){
			var index = _.findIndex(this.arrPosicoes, pos)
			var posicao
			var pos1 = pos.posicao.split(',')
			if(index === this.arrPosicoes.length-1){
				pos2 = this.arrPosicoes[index-1].posicao.split(',')
			}else{
				var pos2 = this.arrPosicoes[index+1].posicao.split(',')
			}
			posicao = [pos1, pos2]
			this.$emit('local-posicao', posicao)
		},
		
		/**
		 * @param {(0|1)} ignicao - 1 ligado 0 desligado.
		 * @description define ícone e cor da ignição em cada linha
		 * do histórico de posições.
		 * @returns {object} pronto para os v-binds do ícone.
		 * @author Gui 🍺
		 */
		defineIgnicao(ignicao){
			let obj = {}
			if(ignicao){
				obj = {
					size:14,
					icon: mdiCheckCircle,
					style:'color: green'
				} 
			}else{
				obj = {
					size:14,
					icon:mdiCloseCircle,
					style:'color: red'
				}
			}
			return obj
		},

		/**
		 * @param {object} pos - representado uma posição do veículo.
		 * @description define a classe de cada linha da tabela,
		 * baseado no tipo de posição e na velocidade do veículo.
		 * @returns {string} classe correspondente.
		 */
		defineClasse({tipoPos}){
			if(tipoPos){
				if(tipoPos === 1) return 'movimentoCel'
				else return 'velocidadeCel'
			}
			return 'paradaCel'
		},
	},

	watch: {

		/**
		 * @description esse cáculo era feito no back-end, mas depois de ter feito
		 * a páginação, se tornou imprático fazer o calculo lá, então, agora 
		 * essa nova array é montada com o calculo feito p/ cada posição.
		 * @author Gui 🍺
		 */
		arrPosicoes(newValue){
			this.arrPosicoesSoma = newValue.reduce(
				(contador, current, index, array)=>{
					let nao_eh_ultimo = index+1 < array.length
					if(nao_eh_ultimo){
						let ultimo_valor = contador.at(-1)
						let prox_valor = array[index+1].hodometro
						let valor_atual = current.hodometro
						let km = ultimo_valor + prox_valor - valor_atual
						contador.push(km)
					}
					return contador
				}, [0])
		},

		filtroBusca(newValue){
			if(newValue.length < 30){
				this.buscaMaisPosicao()
			}
		}
	},
	computed:{
		filtroTipo(){
			return this.arrPosicoes.filter((p)=>{
				if(this.filtroExcesso){
					if(p.tipoPos === 2)
						return true
				}
				if(this.filtroParadas){
					if(p.tipoPos === 0)
						return true
				}
				if(this.filtroMovimento){
					if(p.tipoPos === 1)
						return true
				}
				return false
			})
		},

		/**
		 * @returns {array} posições em que o endereço bata com a
		 * query do usuário
		 */
		filtroBusca(){
			var regEx = new RegExp(this.query, 'i')
			return this.filtroTipo.filter(el=>{
				return el.endereco.match(regEx)
			})
		},

		/**
		 * @returns {array} com todas as posições que se aplicam aos filtros
		 */
		filtroTipoPos(){
			return this.arrPosicoes.filter((el)=>{
				if(this.filtroMovimento)
					if(el.tipoPos === 1)
						return true
				if(this.filtroParadas)
					if(el.tipoPos === 0)
						return true
				if(this.filtroExcesso)
					if(el.tipoPos === 2)
						return true
				return false
			})
		}
	},
}
</script>
<style lang="scss" scoped>
#historicoPosicoesVeiculo{
	height: 458px;
	width: 420px;
	border-radius: 3px;
	background-color: white;
	position: absolute;
	z-index: 315;
	top: 1.3%;
	left: 300px;
	[id$='_draggable']{
		cursor: move;
	}
	.linha-tabela{
		width: 100%;
		border-bottom: blue 1px solid;
	}
	.td-linha{
		padding: 0;
		margin: 0;
	}
	.movimento{
		background-color: #dff0d8;
		border: 1px solid #80c77e;
	}
	.parada{
		background-color: #ebcccc;
		border: 1px solid #dd6c69;
	}
	.velocidade{
		background-color: #faf2cc;
		border: 1px solid #f4cf9c;
	}
	.movimentoCel{
		background-color: #dff0d8;
	}
	.paradaCel{
		background-color: #ebcccc;
	}
	.velocidadeCel{
		background-color: #faf2cc;
	}
	.tabelaPos{
		padding: 2px;
		overflow: auto;
		max-height: 333px;
	}
	.botaoLocal{
		cursor: pointer; 
	}
	.buttonsAr{
		margin-top: 3px;
	}
	.form-search{
		height: 25px;
		width: 50%;
	}
	.botaoL{
		color: black;
		font-size: 12px;
	}
	.pressed{
		background-color: lightgrey;
	}
	.celula{
		text-align: center;
		color: black;
		z-index: 50;
	}
	.celulaEndereco{
		color: black;
		width: 130px;
		z-index: 50;
	}
	td{
		font-size: 10px !important;
		padding:2px;
	}
	th{
		font-size: 10px;
		background-color: white;
	}
	table{
		width: 100%;
		// border: 1px, solid, #dde1db
	}
	.header{
		position: -webkit-sticky; /* Safari */
		position: -moz-sticky;
		position: -ms-sticky;
		position: -o-sticky;
		position: sticky;
		top: -3px;
		z-index: 3;
	}
	table, td {
		// border: 1px solid #dde1db;
		border-collapse: collapse;
	}
}
</style>
