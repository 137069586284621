/* eslint-disable */
import { EmpresasService } from "@/Services/auth/Empresas.service";
import { HttpRequest } from "@/Services/auth/HttpRequest.Service";
import { RouterService } from "../../../Services/route/Route.Service";
import { MenuItem } from "../../../Services/route/RouteModel"

export class MenuComponent {

    vue: Vue
    constructor(vue: Vue) {
        this.vue = vue;
    }
    /**
    * gera o Objeto de Carregamento do Menu. retorna no formato para carregar o SideBar
    @author Daniel Bruch
    */
    LoadMenu(): any {
        var menu:any = []
        var module = this.vue.$route.meta?.Module || null;
        var item = new RouterService().Menu(module);
        try {
            if (module == "controle/jornada") {
                menu.push({ header: true, title: "Controle de Jornada", hiddenOnCollapse: true }as never)
            } else if (module == "gestao/desempenho") {
                menu.push({ header: true, title: "Gestão de Desempenho", hiddenOnCollapse: true }as never)
            } else if (module == "controle/temperatura") {
                menu.push({ header: true, title: "Controle de temperatura", hiddenOnCollapse: true }as never)
            } else if (module == "eagle/cargo") {
                menu.push({ header: true, title: "Eagle Cargo", hiddenOnCollapse: true } as never)
            }else if (module == 'finder/plus') {
                menu.push({ header: true, title: "Finder Plus", hiddenOnCollapse: true } as never)
            } else {
                menu.push({ header: true, title: module, icon: "icon " + item[0].icon, hiddenOnCollapse: true } as never)
            }
            if (item instanceof Array) {
                item.forEach(p => {
                    if (p.titulo != "Não Menu" && p.titulo != "Dashboard") {
                        p.icon = "icon " + item[0].icon
                        var itens: { title: string; icon: string; href: string; }[] = [];
                        p.itens.forEach(q => {
                            if (q.permissoes.visualizar) {
                                var itemModel = { title: q.titulo, icon: "icon " + q.icon, href: q.route || "" }
                                itens.push(itemModel);
                            }
                        });
                        var model = { title: p.titulo, icon: p.icon, child: itens }
                        menu.push(model as never);
                    } else {
                        p.itens.forEach(q => {
                            if (q.permissoes.visualizar && q.titulo != 'Dashboard') {
                                if (q.icon == "") {
                                    q.icon = " -icon00"
                                }
								var itemModel = { title: q.titulo, icon: "icon " + q.icon, href: q.route || "" }
                                menu.push(itemModel as never)
                            }
                        });
                    }
                });
            }
            // eslint-disable-next-line no-empty
        } catch { }
        return menu
    }

    /**
    * verifica se o item é valido e efetua a navegação
    @author Daniel Bruch
    */
    MenuNavigate(item: any) {
        if (item.href) {
            new RouterService().MenuNavigate(item.href)
        }
    }
    /* eslint-enable */
}
