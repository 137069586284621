<template>
    <div id="filtrosRotas">
        <div>
            <div class="d-flex filtrosRotas py-1 d-flex justify-content-between">
                <div class="col-3 nopadding ml-2">
                    <!-- <div style="color:red;"><h1> &#9773;</h1></div> -->
                    <Multiselect placeholder="Selecione uma empresa" :multiple="true" :close-on-select="true"
                        :options="optClientes" :allow-empty="true" track-by="value" label="description"
                        v-model="modelCliente" id="seletorEmpresaMapa" :limit="1" selectLabel="•" selectedLabel="✔"
                        deselectLabel="x" :limitText="(count) => `e mais ${count}`" :disabled="loadingEmpresa"
                        @input="mudaCliente">
                        <template #noResult> Nenhum resultado encontrado </template>
                        <template #noOptions> Nenhuma opção disponível </template>
                        <template #clear>
                            <div v-if="modelCliente.length" class="multiselect__clear mr-4">
                                <b-button size="sm" variant="danger" @click="limpaSeletorCliente">
                                    <base-icon size="16" :icon="iconClear" />
                                </b-button>
                            </div>
                        </template>
                    </Multiselect>
                </div>
                <div class="my-auto ml-1 d-flex">
                    Rota:
                    <!-- <div class='d-flex flex-column'> -->
                    <div class="checkboxesRota">
                        <b-form-checkbox :checked="true" :disabled="loadingEmpresa" value="1" unchecked-value="0"
                            v-model="filtros.iniciada" @change="emiteFiltros">
                            Iniciada
                        </b-form-checkbox>
                        <b-form-checkbox :checked="true" :disabled="loadingEmpresa" value="1" unchecked-value="0"
                            v-model="filtros.pendente" @change="emiteFiltros">
                            Pendente
                        </b-form-checkbox>
                        <b-form-checkbox :checked="true" :disabled="loadingEmpresa" value="1" unchecked-value="0"
                            v-model="filtros.finalizada" @change="emiteFiltros">
                            Finalizada
                        </b-form-checkbox>
                    </div>
                </div>
                <div>
                    <div class="check-alertas-lido">
                        <b-check v-model="alertaLido" id="visualiza_alerta_lido" :disabled="loadingEmpresa">
                            Visualizar alertas lidos
                        </b-check>
                    </div>
                    <div class="check-rastro">
                        <b-check v-model="compararRota" id="comparar_rastro_rota" :disabled="loadingEmpresa">
                            Comparar rota prevista com realizada
                        </b-check>
                    </div>
                </div>
                <div id="divDatePicker" class="d-flex justify-content-between">
                    <label class="labelData" for="">Data da rota:</label>
                    <div class="divDatePicker">
                        <!-- <date-picker ref="datePicker" :disabled="loadingEmpresa" name="datepickerRotas" label=""
                            @changeInput="mudaDataFiltro" /> -->

                        <!-- <el-date-picker id="periodoPicker" style="height: 30px" v-model="modelPeriodo"
                            :picker-options="pickerOptions" class="datePicker tamanhoFonteInfo" type="daterange"
                            size="mini" range-separator="-" value-format="dd/MM/yyyy" format="dd/MM/yyyy"
                            start-placeholder="" end-placeholder="" @change="mudaDataFiltro">
                        </el-date-picker> -->

                        <el-date-picker v-model="filtros.data" :picker-options="pickerOptions" type="daterange"
                            value-format="dd/MM/yyyy" style="max-width: 153px" format="dd/MM/yyyy" size="large"
                            range-separator="-" start-placeholder=" " :disabled="loadingEmpresa" @change="mudaPeriodo"
                            v-b-tooltip.hover.focus.left="{
                                variant: erroPeriodo ? 'danger' : 'secondary',
                            }" title="Período máximo de 7 dias" end-placeholder="" />


                    </div>
                </div>
                <div class="mr-2">
                    <input type="text" v-model="buscaUsuario" placeholder="Buscar" class="form-control" />
                </div>
                <div class="d-flex gap-3">
                    <div class="mt-1">
                        <b-button squared class="botaoFiltro" variant="outline-secondary"
                            @mouseover="emitirLegenda(true)" @mouseleave="emitirLegenda(false)">
                            <base-icon :size="20" :icon="iconLegend" />
                        </b-button>
                    </div>

                    <div class="mr-1 pt-1 ml-2">
                        <b-button squared class="botaoFiltro" variant="outline-secondary" @click="emiteExpandir">
                            <base-icon :size="20" :icon="iconResize" />
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import datePicker from "@/components/Atom/Datas/InputSingleDay.vue";
import { pickerOptions } from "@/components/Atom/Painel/datePickerHelper.js";
import BaseIcon from "@/components/Atom/Icon/BaseIcon.vue";
import { EmpresasService } from "@/Services/auth/Empresas.service";
import { DateTime } from "luxon";
import { mdiResize, mdiMoveResizeVariant, mdiCloseThick, mdiHelpBoxMultipleOutline } from "@mdi/js";
export default {
    name: "filtrosRota",
    components: {
        Multiselect,
        datePicker,
        BaseIcon,
    },
    props: {
        clienteSelecionado: {
            type: Array,
            required: true,
        },

        loadingEmpresa: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            optSelectEmpresas: new EmpresasService().Get(),
            modelCliente: [],
            filtros: {
                empresas: [],
                data:[
                    DateTime.now().toFormat("dd/LL/yyyy"),
                    DateTime.now().toFormat("dd/LL/yyyy"),
                ],
                iniciada: "1",
                pendente: "1",
                finalizada: "1",
                periodoRastro: []
            },
            erroPeriodo: false,
            pickerOptions: pickerOptions,
            buscaUsuario: "",
            iconResize: mdiResize,
            iconLegend: mdiHelpBoxMultipleOutline,
            iconClear: mdiCloseThick,

            //Controle
            compararRota: false,
            alertaLido: false,
        };
    },
    inject: ['mapaRequisicaoViagem'],
    methods: {
        mudaPeriodo(periodo) {
            if (!periodo) return;
            var com = DateTime.fromFormat(periodo[0], "dd/LL/yyyy");
            var fim = DateTime.fromFormat(periodo[1], "dd/LL/yyyy");
            var dif = fim.diff(com, "days").toObject();
            if (dif.days > 8) {
                fim = com.plus({ days: 7 });
                this.filtros.data[1] = fim.toFormat("dd/LL/yyyy");
                this.erroPeriodo = true;
                setTimeout(() => {
                    this.erroPeriodo = false;
                }, 5666);
                return;
            }
            this.filtros.data = periodo;
            this.emiteFiltros();
            this.erroPeriodo = false;
        },
        resetaCompararRota() {
            this.compararRota = false;
        },

        /**
         * Emite um evento para exibir a legenda no painel com base no status fornecido.
         *
         * @param {boolean} status.
         * @author Otávio 🦆
         */
        emitirLegenda(status){
            this.$emit('exibir-legenda-painel', status);
        },

        /**
		 * @description altera o status da alertaLido para false 
		 * @author Otávio 🦆 
		*/
        resetaAlertaLido(){
            this.alertaLido = false;
        },
        limpaFiltros() {
            this.buscaUsuario = "";
            this.filtros.iniciada = "1";
            this.filtros.pendente = "1";
            this.filtros.finalizada = "1";
            this.filtros.data = new Date().toISOString().substr(0, 10);
            this.resetaCompararRota();
            this.resetaAlertaLido();
            this.filtros.data = [
                DateTime.now().toFormat("dd/LL/yyyy"),
                DateTime.now().toFormat("dd/LL/yyyy"),
            ];
        },
        limpaSeletorCliente() {
            this.$emit("fecha-info");
            this.modelCliente = [];
        },

        /**
         * @fires filtros-rotas
         * @description emite os filtros para o painel-rota realizar
         * a busca das informações
         */
        emiteFiltros() {
            this.resetaCompararRota();
            this.resetaAlertaLido();
            this.$emit("filtros-rotas", this.filtros);
        },

        /**
         * @listens input - seletor de empresas
         * @param {object[]} clientes
         */
        mudaCliente(clientes) {
            var select_all = clientes.some((e) => {
                return e.value === "SA";
            });
            if (select_all) {
                this.modelCliente = this.optSelectEmpresas;
            }
            this.emiteFiltros();
        },

        mudaDataFiltro(data) {
            this.filtros.data = data;
            this.emiteFiltros();
        },

        /**
         * @description o controle do tamanho da tabela em si
         * esta no painel de controle.
         * Essa função só controla o ícone que aparece no botão
         * e emite o evento em si. As duas funções agem em 'sincronia'
         * @author Gui 🍺
         */
        emiteExpandir() {
            if (this.iconResize === mdiResize) this.iconResize = mdiMoveResizeVariant;
            else this.iconResize = mdiResize;
            this.$emit("expandir");
        },
    },
    watch: {
        compararRota(newValue) {
            this.$emit("rastro-rota", newValue);
        },

        /**
		 * @description emite a ação do usuário se clicou para exibir alertas lidos ou não
		 * @author Otávio 🦆 
		*/
        alertaLido(newValue){
            this.$emit("exibe-alertas-lidos", newValue);
        },

        buscaUsuario(newValue) {
            this.$emit("busca-rotas", newValue);
        },

        clienteSelecionado(value) {
            this.modelCliente = value;
            this.limpaFiltros();
        },

        modelCliente(value) {
            this.filtros.empresas = value.map((c) => {
                return c.value;
            });
            this.emiteFiltros();
            this.$emit("muda-cliente", value);
        },
    },
    computed: {
        optClientes() {
            var sa = {
                value: "SA",
                description: "Selecionar Todos",
            };
            return [sa, ...this.optSelectEmpresas];
        },
        
        /**
        * @author Otávio 🦆
        * @description Monitora se a requisição do mapa terminou, para deixar 
        * os botões de fechar desativado
        */
        requisicaoViagem() {
            return this.mapaRequisicaoViagem.fechaViagem;
        },
    },
    mounted() {
        this.modelCliente = this.clienteSelecionado;
    },
};
</script>

<style lang="scss" scoped>
#filtrosRotas {
    height: 60px !important;
    background-color: white;
    .checkboxesRota {
        display: flex;
        flex-direction: column;
        padding-left: 3px;
        align-items: flex-start;
    }
    .check-rastro {
        padding-top: 1.2%;
    }
    .check-alertas-lido{
        padding: 0;
        margin-left: -100px;
    }
    .labelData {
        padding-top: 6%;
        min-width: 100px;
    }
    .divDatePicker {
        max-width: 180px;
    }
    .botaoFiltro {
        height: 39px !important;
    }
    .datePicker {
        max-width: 180px;
        margin-top: 10px;
    }
}
</style>
