<template>
    <div id='popUpParada'>
        <div v-if='info'>
            <div id='tituloPopUp'>
                Informações da Parada
            </div>
            <div v-html="data"/>
            <div v-html="tempoParado"/>
            <div v-html="enderecoParada"/>
            <div id="botoes"
            class='d-flex justify-content-between mt-2'>
                <b-button
                :class="{
                    'mr-1':true,
                    'botaoAssociar':true
                }"
                event='click'
                @click='associarAPonto'>Associar a um ponto</b-button>
                <b-button
                class='botaoNovoPonto'
                event="click"
                @click='novoPonto'>Cadastrar novo ponto</b-button>
            </div>
        </div>
        <div v-else >
            <div v-if='success==""' class='d-flex flex-column'>
                <div class='mx-auto mb-2'>
                    Selecione um ponto já existente:
                </div>
                <div class='mx-auto'>
                    <multiselect
                    v-model='ponto'
                    trackBy='value'
                    id='tipoPonto'
                    class='seletorPonto'
                    track='value'
                    label='description'
                    placeholder=' '
                    deselectLabel=' '
                    selectedLabel=' '
                    selectLabel=' '
                    :showLabels='false'
                    :allowEmpty='false'
                    :loading='isLoading'
                    :disabled='isLoading'
                    :options='optPontos'>
                        <template #noResult>
                            Nenhum resultado encontrado
                        </template>
                    </multiselect>
                </div>
                <div id='botoes' class="mx-auto mt-2">
                    <b-button
                    class='btn-salvar mr-1'
                    event='click'
                    :disabled='$v.$invalid || salvando'
                    @click="salvarAssociacao">
                    <b-spinner
                    v-if='salvando'
                    small/>
                    Salvar
                    </b-button>
                    <b-button
                    event="click"
                    class='btn-cancelar'
                    @click="cancelaCriacao">Cancelar</b-button>
                </div>
            </div>
            <div v-else v-text='success'/>
        </div>
    </div>
</template>

<script>
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { required } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect';
export default {
	name:'infoParada',
	components:{
		Multiselect
	},
	props:{
		parada:{
			type: Object,
			required: true
		},
		cliente:{

		}
	},
	validations:{
		ponto: { required }
	},
	data(){
		return{
			isLoading : false,
			salvando  : false,
			info      : true,
			ponto     : [],
			optPontos : [],
			success   : '',
		}
	},
	methods: {
		salvarAssociacao(){
			var uri = '/finder/veiculo/associar/ponto'
			this.salvando = true
			new HttpRequest().Post(uri, this.objAssociar)
				.then((data)=>{
					if(data.data?.success)
						this.success = 'Ponto salvo com sucesso!'
					else
						this.success = 'Erro ao salvar ponto!'
				}).finally(()=>this.salvando = false)
		},

		cancelaCriacao(){
			this.info = true
		},
		associarAPonto() {
			this.procuraPontosProximos()
			this.info = false
		},
		novoPonto(){
			this.$emit('novo-ponto', this.parada)
		},
		/**
		 * Traz os pontos organizados pela distância deles até
		 * o local onde aconteceu o evento de parada
		 */
		procuraPontosProximos(){
			this.isLoading = true
			var url = '/finder/veiculo/pontos/proximos'
			var obj = {
				cliente:this.cliente.map((c)=>{
                    return c.value
                }),
				lat: this.parada.lat,
				lng: this.parada.lng
			}
			new HttpRequest().Post(url, obj).then((data)=>{
				this.optPontos = data.data.pontos
				this.ponto = this.optPontos[0]
			}).finally(()=>this.isLoading = false)
		}
	},
	computed: {
		/**Pra facilitar um pouco a maneira que essas
		 * informações são exibidas, deixo todas sendo
		 * formatadas aqui.
		 */
		data() {
			return `<b>Parou as:</b> ${this.parada.data}`
		},
		tempoParado(){
			return `<b>Ficou parado:</b> ${this.parada.diferenca}`
		},
		enderecoParada(){
			return `<b>Em:</b> ${this.parada.endereco}`
		},
		/**Objeto que é necessário para associar um ponto */
		objAssociar(){
			return {
				lat:this.parada.lat,
				lng:this.parada.lng,
				codigo:this.ponto.value
			}
		}
	},
    
}
</script>

<style lang="scss" scoped>
#popUpParada{
    .seletorPonto{
        width: 300px;
    }
    #tituloPopUp{
        color:red;
        font-weight: bolder;
    }
    .botaoAssociar{
        background-color: #5fb8ee;
        border: none;
    }
    .botaoNovoPonto{
        border: none;
        background-color: #445bdb;
    }
    .btn-salvar{
        border:none;
        background-color:#4696f4;
        width: 125px;
    }
    .btn-cancelar{
        width: 125px;
        border:none;
        background-color:#f34336;
    }
}
</style>