<template>
    <div>
        <modalEagle 
            @modalOpen="modalOpen"
            @modalClose="modalClose"
            id="listar-cartao" 
            :title="titulo"
            size="xl"
        >
            <slot slot="modalBody">
                <panelEagle
                    id="painel-listar-cartao"
                    :loading="loadingPanel"
                >
                    <div class="col-sm-12 row nopadding">
                        <div class="col-sm-12 row">
                            <div class="col-sm-3 nopadding mr-3">
                                <simpleButton
                                    :disabled="loadingPanel"
                                    width="100%"
                                    text="Listar cartões"
                                    type="green"
                                    :icon="mdiCheckBold"
                                    event="click"
                                    @click="listaCartao"
                                />
                            </div>
                            <div class="col-sm-3 nopadding ml-3">
                                <simpleButton
                                    v-if="getCadastrar()"
                                    :disabled="loadingPanel"
                                    width="100%"
                                    text="Adicionar cartão"
                                    type="green"
                                    :icon="mdiCheckBold"
                                    event="click"
                                    @click="adicionarCartao"
                                />
                            </div>
                        </div>
                        <div class="col-sm-12 nopadding mt-3">
                            <tableListagemModal
                                @excluirCadastro="abreModalExluir"
                                :hasAction="true" 
                                :titles="titlesTable" 
                                :data="dataTable"
                            />
                        </div>
                    </div>
                </panelEagle>
            </slot>
            <slot slot="modalFooter">
                <simpleButton
                    text="Finalizar"
                    type="blue"
                    :icon="mdiCheckBold"
                    event="click"
                    @click="modalClose"
                />
            </slot>
        </modalEagle>
        <modalAdicionarCartao
            :empresaSelecionada="empresasSele"
            @atualiza="atualizaTabela"
            :placa="placaVeiculo"
        />
        <modalDeleta
            :id="idDeltaModal"
            @confirmaExclusao="excluirCadastro"
        />
    </div>
</template>
<script>
import Vue from "vue"
import { mdiCheckBold, mdiPlusThick } from "@mdi/js"
import { HttpRequest } from "@/Services/auth/HttpRequest.Service"
import { conectionError } from "@/Services/Helpers/swellHeper"
import { mapGetters } from "vuex"
export default Vue.extend({
    name:"modalPermissoes",
    components:{
        // selectAll:require("@/components/Atom/Select/SelectAll").default,
        panelEagle:require("@/components/Atom/Panel/PanelEagle").default,
        modalEagle:require("@/components/Atom/Modal/ModalEagle").default,
        simpleButton:require("@/components/Atom/Buttons/SimpleButton").default,
        modalDeleta:require("@/components/Atom/ModalSpecific/ModalDeleta").default,
        tableListagemModal:require("@/components/Atom/Table/TableListagemModal").default,
        modalAdicionarCartao:require("@/components/Atom/ModalSpecific/ModalAdicionarCartaoGestaoDeCartoesRfid").default,
    },

    props:{
        titulo:{
            type: String,
            required:true,
        },

        codigoRastreador:{
            required:true,
        },

        empresaSelecionada:{
            type: Array,
            required:true,
        }
    },

    data() {
        return {
            empresasSele:[],
            mdiCheckBold:mdiCheckBold,
            mdiPlusThick:mdiPlusThick,
            titlesTable: [
                { 
                    number:"vcacartao",        
                    name:"N° cartão"
                },
                { 
                    number:"vcatipo",           
                    name:"Tipo"
                },
                { 
                    number:"vassociado",           
                    name:"Associado"
                },
                { 
                    number:"vcaposicao", 
                    name:"Posição Memória"
                },
                { 
                    number:"vultimaatt", 
                    name:"Última atualização"
                },
            ],
            dataTable:[],
            placaVeiculo:"",
            urlBase: "/administrativo/gestao/cartoes/rfid",
            intervaloTabela:undefined,
            loadingPanel:false,
            idDeltaModal:""
        }
    },

    methods: {
        ...mapGetters(["getCadastrar"]),
        modalOpen(){
            this.$nextTick(() => {
                this.empresasSele = this.empresaSelecionada
            })
            this.dataTable = []
            clearInterval(this.intervaloTabela)
            this.loadingPanel = true
            new HttpRequest().Post(`${this.urlBase}/atualiza/tabela`, {
                placa:this.titulo.split(" | ")[0]
            }).then(({ data }) => {
                this.dataTable = data.tabela
            }).catch(() => {
                conectionError()
            }).finally(() => {
                this.loadingPanel = false
                this.$nextTick(() => {
                    this.atualizaTabela()
                })
            })
        },

        abreModalExluir(codigo){
            this.idDeltaModal = codigo
            this.$nextTick(() => {
                this.$bvModal.show("modal-deleta-"+codigo)
            })
        },

        excluirCadastro(codigo){
            clearInterval(this.intervaloTabela)
            this.loadingPanel = true
            let dados = this.dataTable.find((v) => v.codigo == codigo)
            let enviar = {
                "placa":this.titulo.split(" | ")[0],
                "tipo":dados.vcatipo.toLowerCase(),
                "cartao":dados.vcacartao
            }
            new HttpRequest().Post(
                `${this.urlBase}/apaga/cartao`, 
                enviar
            ).then(({ data }) => {
                let erro = Object.values(data.dados)
                let existe = erro.find((v) => v.Erro)
                if(existe){
                    conectionError()
                }else{
                    let str = `
                        Comando para excluir foi enviado para 
                        fila de comandos aguarde retorno na tabela
                    `
                    this.toastShow(str, "success")
                }
            }).catch(() => {
                conectionError()
            }).finally(() => {
                this.loadingPanel = false
                this.atualizaTabela()
            })
        },

        modalClose(){
            clearInterval(this.intervaloTabela)
            this.$bvModal.hide("listar-cartao") 
        },

        atualizaTabela(){
            clearInterval(this.intervaloTabela)
            this.$nextTick(() => {
                this.intervaloTabela = setInterval(() => {
                    this.atualizaDadosTabela()
                }, 10000)
            })
        },

        atualizaDadosTabela(){
            new HttpRequest().Post(`${this.urlBase}/atualiza/tabela`, {
                placa:this.titulo.split(" | ")[0]
            }).then(({ data }) => {
                this.dataTable = data.tabela
            }).catch(() => {
                clearInterval(this.intervaloTabela)
                conectionError()
            })
        },

        listaCartao(){
            this.loadingPanel = true
            clearInterval(this.intervaloTabela)
            new HttpRequest().Post(`${this.urlBase}/listar/cartao`, {
                    codigo:this.codigoRastreador
                }).then(({ data }) => {
                    if(data.erro){ 
                        conectionError(
                            "Contate o suporte.", 
                            "warning",
                            data.erro
                        )
                    }else{
                        this.arrumaDados(data.fila)
                    }
                }).catch(() => {
                    conectionError()
                }).finally(() => {
                    this.loadingPanel = false
                    this.atualizaTabela()
                })
        },

        arrumaDados(dados){
            if(!dados.length) return
            this.dataTable = dados.listaNova
            let deuBom = dados.respostaFila.find((v) => {
                let x = Object.values(v)
                if(x[0].Sucesso){
                    return v
                }
            })
            if(deuBom){
                let str = `
                    Comando para listagem foi enviado para 
                    fila de comandos aguarde retorno na tabela
                `
                this.toastShow(str, "success")
            }
        },

        toastShow(msg, type){
            this.$bvToast.toast(msg, {
                autoHideDelay: 2500,
                variant: type,
            })
        },

        adicionarCartao(){
            this.placaVeiculo = this.titulo.split(" | ")[0]
            this.$bvModal.show("adicionar-cartao")
        }
    },
})
</script>
<style lang="scss">
    
</style>
