import { render, staticRenderFns } from "./CadastrarUsuarioApp.vue?vue&type=template&id=4e39d506&scoped=true"
import script from "./CadastrarUsuarioApp.vue?vue&type=script&lang=js"
export * from "./CadastrarUsuarioApp.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e39d506",
  null
  
)

export default component.exports