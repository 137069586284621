<template>
    <modalEagle 
        title="Erro ao salvar pontos" 
        id="pontosNSalvos">
        <slot slot="modalBody">
            <div class="col-sm-12 nopadding mt-4">
                <tableListagemModal 
                    :hasAction="false" 
                    :titles="titlesTable" 
                    :data="pontosNSalvo">
                </tableListagemModal>
            </div>
        </slot>
        <slot slot="modalFooter">
            <simpleButton
                :icon="mdiCloseThick" 
                @click="cancelButton" 
                text="Fehcar" 
                event="click" 
                type="red">
            </simpleButton>
        </slot>
    </modalEagle>
</template>
<script lang="js">
import Vue from 'vue'
import{ mdiCloseThick } from '@mdi/js'
export default Vue.extend({
    name:'pontosNSalvos',
    components:{
        modalEagle: require('@/components/Atom/Modal/ModalEagle.vue').default,
        simpleButton: require('@/components/Atom/Buttons/SimpleButton').default,
        tableListagemModal: require('@/components/Atom/Table/TableListagemModal.vue').default,
    },
    props:{
        pontosNSalvo:{
            type: [Array, Object],
            required: true,
        }
    },
    data(){
        return{
            mdiCloseThick:mdiCloseThick,
            titlesTable: [
                {'number': 'linha', 'name': 'Linha'},
                {'number': 'pocodigoexterno', 'name': 'Codigo Externo'},
                {'number': 'podescricao',     'name': 'Descrição'},
                {'number': 'poendereco',      'name': 'Endereço'},
            ]
        }
    },
    methods:{
        cancelButton(){
            this.$bvModal.hide('pontosNSalvos')
        },
    },
})
</script>