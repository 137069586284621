<template>
    <div>

    </div>
</template>
<script>
import { AuthService } from '../../Services/auth/Auth.Service'
import {RouterService} from '../../Services/route/Route.Service'
import vuex from '../../store/index'
export default{
    created(){
      this.init();
    },
    methods:{
        init(){
           new AuthService().logout();
           vuex.dispatch('refresh')
           new RouterService().Navigate("/login");
        }
    }
}
</script>