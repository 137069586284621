export const closeIcon = `<svg style="width:24px;height:24px" class='midIcone' viewBox="0 0 24 24">
<path fill="currentColor" d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z" />
</svg>`;

export const mdiMapMarkerCircle = `<svg width="36" height="36" viewBox="0 0 24 24">
<path d="M12 20a8 8 0 0 1-8-8a8 8 0 0 1 8-8a8 8 0 0 1 8 8a8 8 0 0 1-8 8m0-18A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2m0 10.5a1.5 1.5 0 0 1-1.5-1.5A1.5 1.5 0 0 1 12 9.5a1.5 1.5 0 0 1 1.5 1.5a1.5 1.5 0 0 1-1.5 1.5m0-5.3c-2.1 0-3.8 1.7-3.8 3.8c0 3 3.8 6.5 3.8 6.5s3.8-3.5 3.8-6.5c0-2.1-1.7-3.8-3.8-3.8" fill="color"/>
</svg>`;

export const checkIcon = `<svg style="width:24px;height:24px" class='midIcone' rotateX viewBox="0 0 24 24">
<path fill="currentColor" d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
</svg>`;

export const mdiMapMarkerAlertOutline = `<svg style="width:24px;height:24px" class='midIcone' viewBox="0 0 24 24">
                        <title>map-marker-alert-outline</title>
                        <path fill="currentColor" d="M12,2A7,7 0 0,1 19,9C19,14.25 12,22 12,22C12,22 5,14.25 5,9A7,7 0 0,1 12,2M12,4A5,5 0 0,0 7,9C7,10 7,12 12,18.71C17,12 17,10 17,9A5,5 0 0,0 12,4M11,6H13V11H11V6M11,13H13V15H11V13Z" /></svg>`

export const mdiAlertCircleOutline = `<svg style="width:24px;height:24px" class='midIcone' viewBox="0 0 24 24"><title>alert-circle-outline</title><path fill="currentColor" d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" /></svg>`

export const iconPortas = `<svg style="width:24px;height:24px" class='midIcone' viewBox="0 0 24 24">
<path fill="currentColor" d="M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V10A2,2 0 0,1 6,8H15V6A3,3 0 0,0 12,3A3,3 0 0,0 9,6H7A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,17A2,2 0 0,0 14,15A2,2 0 0,0 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17Z" />
</svg>`;

export const iconEvento89 = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
<path fill="currentColor" d="M12,16A3,3 0 0,1 9,13C9,11.88 9.61,10.9 10.5,10.39L20.21,4.77L14.68,14.35C14.18,15.33 13.17,16 12,16M12,3C13.81,3 15.5,3.5 16.97,4.32L14.87,5.53C14,5.19 13,5 12,5A8,8 0 0,0 4,13C4,15.21 4.89,17.21 6.34,18.65H6.35C6.74,19.04 6.74,19.67 6.35,20.06C5.96,20.45 5.32,20.45 4.93,20.07V20.07C3.12,18.26 2,15.76 2,13A10,10 0 0,1 12,3M22,13C22,15.76 20.88,18.26 19.07,20.07V20.07C18.68,20.45 18.05,20.45 17.66,20.06C17.27,19.67 17.27,19.04 17.66,18.65V18.65C19.11,17.2 20,15.21 20,13C20,12 19.81,11 19.46,10.1L20.67,8C21.5,9.5 22,11.18 22,13Z" />
</svg>`;

export const iconEvento405 = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
<path fill="currentColor" d="M8,11L9.5,6.5H18.5L20,11M18.5,16A1.5,1.5 0 0,1 17,14.5A1.5,1.5 0 0,1 18.5,13A1.5,1.5 0 0,1 20,14.5A1.5,1.5 0 0,1 18.5,16M9.5,16A1.5,1.5 0 0,1 8,14.5A1.5,1.5 0 0,1 9.5,13A1.5,1.5 0 0,1 11,14.5A1.5,1.5 0 0,1 9.5,16M19.92,6C19.71,5.4 19.14,5 18.5,5H9.5C8.86,5 8.29,5.4 8.08,6L6,12V20A1,1 0 0,0 7,21H8A1,1 0 0,0 9,20V19H19V20A1,1 0 0,0 20,21H21A1,1 0 0,0 22,20V12L19.92,6M14.92,3C14.71,2.4 14.14,2 13.5,2H4.5C3.86,2 3.29,2.4 3.08,3L1,9V17A1,1 0 0,0 2,18H3A1,1 0 0,0 4,17V12.91C3.22,12.63 2.82,11.77 3.1,11C3.32,10.4 3.87,10 4.5,10H4.57L5.27,8H3L4.5,3.5H15.09L14.92,3Z" />
</svg>`;

export const iconEvento82 = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
<path fill="currentColor" d="M17 4.5C17 5.9 15.9 7 14.5 7S12 5.9 12 4.5 13.1 2 14.5 2 17 3.1 17 4.5M15 8H14.2C12.1 8 10.1 6.8 9.1 4.9C9 4.8 8.9 4.7 8.9 4.6L7.1 5.4C7.6 6.8 9.2 8.6 11.5 9.5L9.7 14.5L5.8 13.4L3 18.9L5 19.4L6.8 15.8L11.3 17C12.3 17.2 13.3 16.7 13.7 15.8L16 9.4C16.2 8.7 15.7 8 15 8M18.9 7L15.5 16.4C14.9 18 13.4 19 11.8 19C11.5 19 11.1 19 10.8 18.9L7.9 18.1L7 19.9L9 20.4L10.4 20.8C10.9 20.9 11.4 21 11.9 21C14.4 21 16.6 19.5 17.5 17.1L21 7H18.9Z" />
</svg>`;

export const iconEvento80 = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
<path fill="currentColor" d="M12,16A3,3 0 0,1 9,13C9,11.88 9.61,10.9 10.5,10.39L20.21,4.77L14.68,14.35C14.18,15.33 13.17,16 12,16M12,3C13.81,3 15.5,3.5 16.97,4.32L14.87,5.53C14,5.19 13,5 12,5A8,8 0 0,0 4,13C4,15.21 4.89,17.21 6.34,18.65H6.35C6.74,19.04 6.74,19.67 6.35,20.06C5.96,20.45 5.32,20.45 4.93,20.07V20.07C3.12,18.26 2,15.76 2,13A10,10 0 0,1 12,3M22,13C22,15.76 20.88,18.26 19.07,20.07V20.07C18.68,20.45 18.05,20.45 17.66,20.06C17.27,19.67 17.27,19.04 17.66,18.65V18.65C19.11,17.2 20,15.21 20,13C20,12 19.81,11 19.46,10.1L20.67,8C21.5,9.5 22,11.18 22,13Z" />
</svg>`;

export const iconEvento88 = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
<path fill="currentColor" d="M12,2C13.11,2 14,2.9 14,4C14,5.11 13.11,6 12,6A2,2 0 0,1 10,4A2,2 0 0,1 12,2M12.39,14.79C14.03,14.79 15.46,14.89 16.64,15.04C16.7,12.32 16.46,9.92 16,9C15.87,8.73 15.69,8.5 15.5,8.3L7.43,15.22C8.79,15 10.5,14.79 12.39,14.79M7.46,17C7.59,18.74 7.85,20.5 8.27,22H10.34C10.05,21.12 9.84,20.09 9.68,19C9.68,19 12,18.56 14.32,19C14.16,20.09 13.95,21.12 13.66,22H15.73C16.17,20.45 16.43,18.61 16.56,16.79C15.41,16.65 14,16.54 12.39,16.54C10.46,16.54 8.78,16.75 7.46,17M12,7C12,7 9,7 8,9C7.66,9.68 7.44,11.15 7.37,12.96L13.92,7.34C12.93,7 12,7 12,7M18.57,5.67L17.43,4.34L13.92,7.35C14.47,7.54 15.05,7.84 15.5,8.3L18.57,5.67M20.67,15.83C20.58,15.8 19.14,15.33 16.64,15.04C16.63,15.61 16.6,16.2 16.56,16.79C18.81,17.07 20.1,17.5 20.12,17.5L20.67,15.83M7.37,12.96L3.43,16.34L4.32,17.82C4.34,17.81 5.5,17.36 7.46,17C7.35,15.59 7.32,14.2 7.37,12.96Z" />
</svg>`;

export const iconEvento87 = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
<path fill="currentColor" d="M18 15C18 17.6 16.8 19.9 14.9 21.3L14.4 20.8L12.3 18.7L13.7 17.3L14.9 18.5C15.4 17.8 15.8 16.9 15.9 16H14V14H15.9C15.7 13.1 15.4 12.3 14.9 11.5L13.7 12.7L12.3 11.3L13.5 10.1C12.8 9.6 11.9 9.2 11 9.1V11H9V9.1C8.1 9.3 7.3 9.6 6.5 10.1L9.5 13.1C9.7 13.1 9.8 13 10 13C11.11 13 12 13.9 12 15S11.11 17 10 17 8 16.11 8 15C8 14.8 8 14.7 8.1 14.5L5.1 11.5C4.6 12.2 4.2 13.1 4.1 14H6V16H4.1C4.3 16.9 4.6 17.7 5.1 18.5L6.3 17.3L7.7 18.7L5.1 21.3C3.2 19.9 2 17.6 2 15C2 10.58 5.58 7 10 7S18 10.58 18 15M23 5C23 3.34 21.66 2 20 2S17 3.34 17 5C17 6.3 17.84 7.4 19 7.82V11H21V7.82C22.16 7.4 23 6.3 23 5M20 6C19.45 6 19 5.55 19 5S19.45 4 20 4 21 4.45 21 5 20.55 6 20 6Z" />
</svg>`;

import Vue from "vue";
import L from "leaflet";
import { antPath } from "leaflet-ant-path";
import { DateTime, Duration } from 'luxon'
import PopupPontoRota from "@/components/Atom/Painel/PopupPontoRota.vue";
import PopupCarga from "@/components/Atom/Painel/PopupMarcadorCarga.vue";
import PopupAlertaRota from "@/components/Atom/Painel/PopupAlerta.vue";
import PopupExcessoVelocidadeRota from "@/components/Atom/Painel/PopupExcessoVelocidade.vue";

/**
 * @description Gera um número aleatório entre 100 e max
 * @param {number}  max - número máximo
 * @return {number} número aleatório
 */
function getRandomInt(max) {
    var hue = Math.floor(Math.random() * max);
    if (hue < 100) hue += 100;
    return hue;
}

/**
 * @description Gera uma cor pro rastro dos veículos
 * @author Gui 🍺
 */
function corAleatoria() {
    var r = getRandomInt(230);
    var g = getRandomInt(230);
    var b = getRandomInt(230);
    return `rgb(${r}, ${g}, ${b})`;
}

/**
 * @param {number} excesso
 * @param {string} [classCor='marker-red'] classe do marcador.
 * @description cria o html do ícone de excesso de velocidade,
 * colocando a velocidade no centro do ícone
 * @returns {string} html do marcador
 * @author Gui 🍺
 */
function geraIconeMarcadorExcessoVelocidade(excesso,velocidademax = null, classCor = "marker-red") {
    let styleExcesso = excesso > velocidademax ? 'color: red !important;' : '';
    return `<div class='marker-circle ${classCor}'>
                <i>
                    <div class='centroMarker'>
                        <span style='${styleExcesso}'>${excesso}</span>
                    </div>
                </i>
            </div>`;
}

/**
 * @param {object} acp
 * @param {string} acp.endereco
 * @return {string} html do marcador
 */
function criaMarcadorAcPortas(acp) {
    return `<div class='marker-circle green'>
            <i>
                <div class='centroMarker acionamento-portas'>
                    ${iconPortas}
                </div>
            </i>
            <div class='legenda-marcador'>
                ${acp.endereco}
            </div>
        </div>`;
}

/**
 * Percebi que eu tava criando esse ícone em vários lugares do código
 * então decidi centralizar essa função aqui,
 * @description Cria o ícone normal, sem nada de muito especial
 * @return {L.icon} icone padrão
 */
function criaIconePadrao() {
    return L.icon({
        iconUrl: "assets/mapa_finder/markerPonto.svg",
        iconSize: [70, 50], 
        iconAnchor: [25, 25],
        shadowAnchor: [20, 20],
    });
}

/**
 * @description O facilitador de coordenadas_regioes vem como um objeto,
 * para poder desenhar a região no mapa preciso que seja uma array.
 * Aqui eu transformo o objeto na arrau que eu preciso
 * @param  {object} regiao - array que vem com o facilitador
 * @param  {number} regiao.rclatitude
 * @param  {number} regiao.rclongitude
 * @returns {array} [[latitude, longitude]]
 * @author Gui 🍺
 */
function separaCoordenadas(regiao) {
    return regiao.map((a) => {
        return [a.rclatitude, a.rclongitude];
    });
}

/**
 * @param {object} obj - qualquer obj que faça parte do rastroVeiculo.
 * @description Marca um objeto como parte do rastro, serve para
 * controle das coisas que estão no mapa, utilizo esse atributo para
 * limpar os rastros do mapa.
 * @returns {object} mesmo obj de entrada, mas com a bool
 * 'rastroVeiculo'
 * @author Gui 🍺
 */
function defineParteRastro(obj) {
    return Object.defineProperty(obj, "rastroVeiculo", {
        value: true,
        writable: false,
        enumerable: true,
        configurable: false,
    });
}

/**
 * @param {LMarker} ponto
 * @description adiciona um evento para quando o usuário clilcar
 * no ponto pela primeira vez, criar a popup e liga ela ao
 * ponto. Dessa maneira as popups não existem até o ponto ser
 * clicado, aumentando um pouco a performance da página.
 * @author Gui 🍺
 */
function preparaAcionamento(ponto, popup) {
    ponto.addEventListener("click", () => {
        ponto.removeEventListener("click"); // mesmo com o once:true. estava executando essa função toda vez.
        popup = new popup({
            propsData: {
                acionamento: ponto.evento,
            },
        });
        popup.$mount();
        ponto.bindPopup(popup.$el);
        ponto.openPopup();
    });
    return ponto;
}

function criaHtmlMarcadorColetivo(desc) {
    return `<div class='marker-circle coletivo'>
                <div class='centroMarker'>
                P
                </div>
                <div class='legenda-marcador'>
                    ${desc}
                </div>
            </div>`;
}

/**
 * @param {L.polyline} poli
 * @param {object}     info
 * @param {number}     info.rccodigo
 * @param {number}     info.desvio - código do desvio
 * @description define um desvio de rota como pertencente
 * a um rastro de uma linha, é uma função separada da
 * outra que define as polis e os marcadores porque, bem
 * a vida é loka e eu não consegui adapatar a outra função
 * pra lidar com essa parte da linha também.🦕
 * @return {L.polyline} com atribulos para identificar
 * de que linha ela pertence.
 * @author Gui 🍺
 */
function defineParteDesvioColetivo(poli, info) {
    return Object.defineProperties(poli, {
        rccodigo: {
            value: info.rccodigo,
            writable: false,
            enumerable: true,
            configurable: false,
        },
        desvio: {
            value: info.desvio,
            writable: false,
            enumerable: true,
            configurable: false,
        },
        rotaColetivo: {
            value: true,
            writable: false,
            enumerable: true,
            configurable: false,
        },
    });
}

function decideIconeEventoVeiculo(tipo) {
    switch (tipo) {
        case 89:
            return iconEvento89;
        case 405:
            return iconEvento405;
        case 82:
            return iconEvento82;
        case 80:
            return iconEvento80;
        case 88:
            return iconEvento88;
        case 87:
            return iconEvento87;
    }
}

/**
 * @param {L.Marker} marcador
 * @param {object} evento
 * @param {number} evento.bicodigo código do registro na tabela bilhetes.
 * @return {L.Marker} com atributos alterados.
 */
function adcMarcadorComoEvento(marcador, evento) {
    return Object.defineProperties(marcador, {
        evento: {
            value: true,
            enumerable: true,
        },
        id_evento: {
            value: evento.bicodigo,
            enumerable: true,
        },
    });
}

/**
 * @param {number[]} location
 * @description cria um marker (pirulito).
 * @returns {Marker} objeto marker
 * @author Gui 🍺
 */
export function criarMarkerRastroGrupo(location) {
    var icon = L.icon({
        iconUrl: 'assets/mapa_finder/novoPonto.svg',
        iconSize: [50, 50],
        iconAnchor: [25, 25],
        shadowAnchor: [10, 10],
    });
    var marker = L.marker(location, {
        icon: icon,
    });
    Object.defineProperties(marker, {
        rastroGrupo: {
            value: true,
            writable: false,
            enumerable: true,
            configurable: false,
        },
    });
    return marker;
}

/**
 * @param {object} ponto
 * @param {number} rocodigo
 * @param {string} caminhoIcone
 * @description cria os marcadores de inicio e final de rota
 * e adiciona eles ao mapa.
 * @returns {L.Marker} marcador com propriedas já definidas.
 * @author Gui 🍺
 */
export function criarMarkerIORota(ponto, rocodigo, caminhoIcone, itens_rota, cargas, status, hora) {
    var icon = L.icon({
        iconUrl: caminhoIcone,
        iconSize: [50, 50],
        iconAnchor: [20, 38],
        shadowAnchor: [10, 10],
    });
    var marker = L.marker([ponto.polatitude, ponto.polongitude], {
        icon: icon,
    });
    var horaFormatada = '';
    var dataInfo = '';
    if(hora){
        horaFormatada = DateTime.fromSQL(hora);
        horaFormatada = horaFormatada.toFormat('dd/LL/yyyy T');
        dataInfo = horaFormatada
          ? status === "saida"
            ? `<p><strong>Data/hora saída: </strong> ${horaFormatada}</p>`
            : `<p><strong>Data/hora chegada: </strong> ${horaFormatada}</p>`
          : "";
    }

    var irnome = ponto.podescricao ? `<h4>${ponto.podescricao}</h4>` : "";
    var endereco = ponto.poendereco
      ? `<p><strong>Endereço: </strong> ${ponto.poendereco}</p>`
      : "";

    var popupContent = `
    <div>
        ${irnome}
        ${endereco}
        ${dataInfo}
    </div>`;

    marker.bindPopup(popupContent);

    return marker;
}

/**
 * @param {object} item                     - ponto que pertence a rota.
 * @param {number} item.irordem             - ordem do ponto na rota.
 * @param {('J'|'P'|'F'|'R')} item.irstatus - status do ponto.
 * @param {number} item.ircodigo            - código do item.
 * @param {string} item.irnome              - nome do item
 * @description cria o html do marcador do item da rota.
 * @returns {string} html do marcador.
 * @author Gui 🍺
 */

export function criaHtmlMarcadorItemRota(item) {
    var classe = function (item) {
        var retorno = "";
        switch (item.irstatus) {
            case "J": //-> justificado
                retorno = "yellow";
                break;
            case "P": //-> pulado
                retorno = "red";
                break;
            case "F": //-> finalizado
                retorno = "green";
                break;
            case "R": //-> roteriado
                retorno = "";
                break;
        }
        return retorno;
    }.bind(this);

    var meio = function (item) {
        var ordem;
        var retorno = "";
        if (item.irordem) ordem = item.irordem;
        else ordem = "-";
        if (item.irinterjornada || item.irintrajornada) {
            var corIcone = classe(item);
            if (item.irstatus === "R") {
                corIcone = "blue";
            }
            return mdiMapMarkerCircle.replace('color', corIcone);
        } else {
            switch (item.irstatus) {
                case "J":
                case "P":
                    retorno = closeIcon;
                    break;
                case "F":
                    retorno = checkIcon;
                    break;
                case "R":
                    retorno = ordem;
                    break;
                default:
                    retorno = someDefaultIcon;
                    break;
            }
        }
        return retorno;
    }.bind(this);

    var conteudoMeio = meio(item);
    if (item.irinterjornada || item.irintrajornada) {
        return conteudoMeio;
    }
    return `
        <div class='marker-pin ${classe(item)}'>
            <i>
                <div class='centroMarker'>
                    ${conteudoMeio}
                </div>
            </i>
        </div>
        <div class='legenda-marcador' id='${item.ircodigo}'>
            ${item.irnome}
        </div>
    `;
}
/**
 * @param {object} obj - objeto que pertence a rota.
 * @param {number} rocodigo - código da rota que o objeto faz parte.
 * @description define um objeto como parte de uma rota.
 * @return {object} obj de entrada com propriedades rota e rocodigo.
 */
export function defineComoRota(obj, rocodigo) {
    return Object.defineProperties(obj, {
        rota: {
            value: true,
            writable: false,
            enumerable: true,
            configurable: false,
        },
        rocodigo: {
            value: rocodigo,
            writable: false,
            enumerable: true,
            configurable: false,
        },
    });
}

/**
 * @param {L.Marker} marker
 * @param {object} ninfo
 * @description atualiza a popup do marcador enviado
 * @author Gui 🍺
 */
export function updateMaker(marker, ninfo) {
    var icon = L.divIcon({
        html: criaHtmlMarcadorItemRota(ninfo),
        iconSize: [0, 0],
    });
    marker.unbindPopup();
    marker.setIcon(icon);
    var popup = PopupPontoRota;
    popup = Vue.extend(popup);
    popup = new popup({ propsData: { ponto: ninfo } });
    popup = popup.$mount();
    marker.bindPopup(popup.$el);
}

/**
 * @param {number[]} latlng local do alerta
 * @description cria um marcador de pirulito p/
 * a latlng passado por parâmetro.
 * @return {L.Marker} marcador configurado
 * @author Gui 🍺
 */
export function criaMarcadorAlerta(latlng) {
    var iconInicio = L.icon({
        iconUrl: "assets/mapa_finder/novoPonto.svg",
        iconSize: [50, 50],
        iconAnchor: [25, 25],
        shadowAnchor: [10, 10],
    });
    var local_alerta = L.marker(latlng, {
        draggable: false,
        icon: iconInicio,
        interactive: false,
    });
    Object.defineProperty(local_alerta, "alerta_veiculo", {
        value: true,
        enumerable: true,
        writable: false,
        configurable: false,
    });
    return local_alerta;
}

function criaDivIconParada({pocodigo, podescricao, potipo, irmarcador, selecionado, irordem}){
    html = `
        <div class='markerRotaCircle markerRotaTextAjuste'>
            <div class='markerRotaCentro' 
                style='background-color:#6cc377'>
            </div>
            <div id='${pocodigo}_${potipo}' class='markerRotaText'>
                ${selecionado ? irordem : 'P'}
            </div>
        </div>`
	return L.divIcon({
		html:html,
		iconSize    : [0, 0],
		iconShadow  : [0, 0],
		iconAnchor  : [20, 38],
		shadowAnchor: [0, 0]
	})
}


export function criarPolilyneHistorico(pos) {
    var poli = new antPath(pos, {
        color: "red",
        interactive: false,
        delay: 2000,
        weight: 6,
    });
    Object.defineProperty(poli, "posHistorico", {
        value: true,
        writable: false,
        enumerable: true,
        configurable: false,
    });
    return poli;
}

/**
 * @description cria o marcador padrão
 * @param {(number[]|object)} latlng - latitude longitude do ponto
 * @example {lat:22, lng:-54}, [22, -53].
 * @param {boolean} draggable   - se é possível ou não arrastar o ponto
 * @return {L.marker} marcador simples
 */
export function criaMarcadorPadrao(latlng, draggable = false, novoPonto = false) {
    var m = L.marker(latlng, {
        icon: criaIconePadrao(),
        draggable: draggable,
    });
    if (novoPonto) {
        Object.defineProperty(m, "novoPonto", {
            value: true,
            enumerable: true,
            writable: false,
            configurable: false,
        });
    }
    return m;
}

/**
 * @param {object} obj
 * @description adiciona a propriedade de busca como true
 * no objeto passado no parametro.
 * @returns {object} objeto com atributo setado.
 */
export function defineObjetoComoBusca(obj) {
    return Object.defineProperty(obj, "busca", {
        value: true,
        writable: false,
        enumerable: true,
        configurable: false,
    });
}

export function criarMarkerCentroRegiao(regiao) {
    var objOpcoes = {
        color: "blue",
        interactive: false,
        fill: true,
    };
    let objRegiao = L.polygon(regiao.pontos, objOpcoes);
    objRegiao = defineObjetoComoBusca(objRegiao);
    let icon = criaIconePadrao();
    let centro = L.marker([...regiao.centro].reverse(), {
        icon: icon,
        interactive: false,
    });
    centro = defineObjetoComoBusca(centro);
    return {
        regiao: objRegiao,
        centro: centro,
    };
}

export function criarRegiaoCliente(regiao) {
    var objOpcoes = {
        color: "blue",
        interactive: false,
        fill: true,
    };
    var reg;
    var coordenadas;
    /**Inferno de geoJson 🙄 */
    if (regiao.regeojson) {
        let jsonOb = JSON.parse(regiao.regiao_geo_json[0].rggeojson);
        coordenadas = jsonOb.coordinates[0].map((e) => {
            return e.reverse();
        });
    } else coordenadas = separaCoordenadas(regiao.regioes_coordenadas);
    reg = L.polygon(coordenadas, objOpcoes);
    return defineObjetoComoBusca(reg);
}

export function criarInicioRastroVeiculo(location) {
    var iconInicio = L.icon({
        iconUrl: "assets/mapa_finder/novoPonto.svg",
        iconSize: [50, 50],
        iconAnchor: [25, 25],
        shadowAnchor: [10, 10],
    });
    var inicioRastro = L.marker(location, {
        draggable: false,
        icon: iconInicio,
        interactive: false,
    });
    return defineParteRastro(inicioRastro);
}

export function criarPolylineRastroVeiculo(rastro, cor = "blue") {
    var polyline = antPath(rastro, {
        color: cor,
        interactive: true,
        delay: 2000,
        weight: 6,
    });
    return defineParteRastro(polyline);
}

/**
 * @param {object} excesso
 * @param {string} excesso.posicao - lat , lng
 * @param {number} excesso.velocidade
 * @description Cria 1 marcador, ele não pode ser arrastado(draggable)
 * e o usuário não possui nenhuma maneira de interagir com ele, é
 * pra visualização apenas -> menos eventos,
 * melhora um pouco o desempenho
 * @return {L.Marker} marcador com o ícone pronto.
 * @author Gui 🍺
 */
export function criaMarcadorExcessoVelocidade(excesso) {
    var icon = L.divIcon({
        html: geraIconeMarcadorExcessoVelocidade(excesso.velocidade, excesso.velocidademax),
        iconSize: [0, 0],
    });
    var marker =  L.marker(excesso.posicao.split(","), {
        icon: icon,
        title: excesso.endereco,
        alt: excesso.velocidade,
        draggable: false,
        interactive: true,
    });
    var popup = PopupExcessoVelocidadeRota;
    popup = Vue.extend(popup);
    popup = new popup({ propsData: { dadoVelocidade: excesso } });
    
    popup = popup.$mount();
    marker.bindPopup(popup.$el);
    return marker;
}

export function criarMarcadorParada(parada) {
    var icon = L.divIcon({
        html: geraIconeMarcadorExcessoVelocidade(parada.resumido ?? "",null, "marker-yellow"),
        iconSize: [0, 0],
    });
    return L.marker([parada.lat, parada.lng], {
        icon: icon,
        draggable: false,
    });
}

export async function definePropriedadesRegiao(regiao, reg) {
    return await Object.defineProperties(reg, {
        regiaoUsuario: {
            value: true,
            writable: false,
            enumerable: true,
            configurable: false,
        },
        recodigo: {
            value: regiao.recodigo,
            writable: false,
            enumerable: true,
            configurable: false,
        },
        info: {
            value: regiao,
            writable: true,
            enumerable: true,
            configurable: false,
        },
    });
}

export function criarMarkersAcionamentoPorta(eventos) {
    var markers = eventos.map((e) => {
        var icon = L.divIcon({
            html: criaMarcadorAcPortas(e),
            iconSize: [0, 0],
        });
        var m = L.marker(e.posicao.split(","), {
            icon: icon,
            draggable: false,
            interactive: true,
        });
        Object.defineProperty(m, "evento", {
            value: e,
        });
        return m;
    });
    var popUpAcionamento =
        require("@/components/Atom/Painel/infoAcionamentoPorstas.vue").default;
    var popup = Vue.extend(popUpAcionamento);
    let mm = markers.map((ponto) => {
        return preparaAcionamento(ponto, popup);
    });
    return mm;
}

/**
 * @param {object} 			item
 * @param {string} 			item.podescricao
 * @param {(string|number)} item.polatitude
 * @param {(string|number)} item.polongitude
 * @param {number} 			item.rccodigo
 * @description cria um marcador para uma linha
 * vindo do coletivo.
 * @return {void}
 * @author Gui 🍺
 */
export function criarMarkerColetivo(item, popup) {
    var icon = L.divIcon({
        html: criaHtmlMarcadorColetivo(item.podescricao),
        iconSize: [0, 0],
        iconAnchor: [4, 10],
    });
    var marker = L.marker([item.polatitude, item.polongitude], {
        icon: icon,
        riseOnHover: true,
    });
    popup = new popup({
        propsData: { ponto: item },
    });
    popup.$mount();
    marker.bindPopup(popup.$el);
    marker.addEventListener("popupopen", () => {});
    return marker;
}

/**
 * @param {L.polyline} poli
 * @param {object}     rota
 * @param {number}     rota.rccodigohorario
 * @param {number} codigoRotaColetivo
 * @description altera os atributos da poli
 * para ela poder ser identificada como:
 * (A) Parte de rotaColetivo
 * (B) Parte da rota com código rccodigo
 * @return {L.polyline} com atributos alterados.
 * @author Gui 🍺
 */
export function defineParteColetivo(poli, codigoRotaColetivo) {
    return Object.defineProperties(poli, {
        rotaColetivo: {
            value: true,
            writable: false,
            enumerable: true,
            configurable: false,
        },
        rccodigo: {
            value: codigoRotaColetivo,
            writable: false,
            enumerable: true,
            configurable: false,
        },
    });
}

export function criarPolylineColetivo(rota) {
    var decodepoly = require("@mapbox/polyline");
    var latlng = decodepoly.decode(rota.poli);
    var p1 = L.polyline(latlng, {
        color: rota.rccor,
        stroke: true,
        interactive: false,
        weight: 2,
    });
    var p2 = L.polyline(latlng, {
        color: rota.rccor,
        stroke: true,
        interactive: false,
        opacity: 0.5,
        weight: 6,
    });
    p1 = defineParteColetivo(p1, rota.rccodigo);
    p2 = defineParteColetivo(p2, rota.rccodigo);
    return { p1: p1, p2: p2 };
}

export function criarPolylineDesvio(desvio, codigo, popup) {
    var poli = L.polyline(
        d.latlng.map((e) => {
            return e.reverse();
        }),
        {
            color: "red",
            weight: 5,
            lineCap: "butt",
        }
    );
    var info = {
        rccodigo: codigo,
        desvio: desvio.codigo,
    };
    poli = defineParteDesvioColetivo(poli, info);
    var pops = new popup({
        propsData: {
            desvio: desvio.codigo,
        },
    });
    pops.$mount();
    poli.bindPopup(pops.$el);
    return poli;
}

export function criaMarcadorEventoVeiculo(tipo, evento, ponto_popup) {
    let icone = decideIconeEventoVeiculo(tipo);
    let html = `<div class='marker-circle green'>
            <i>
                <div class='centroMarker evento-89'>
                    ${icone}
                </div>
            </i>
        </div>`;
    let icon = L.divIcon({
        html: html,
        iconSize: [0, 0],
        iconAnchor: [4, 10],
    });
    let marcador = L.marker([evento.lat, evento.lng], {
        icon: icon,
        riseOnHover: true,
    });
    ponto_popup = new ponto_popup({
        propsData: {
            endereco: evento.endereco,
            data: evento.data,
            hora: evento.hora,
            mtnome: evento.mtnome,
            tipoEvento: tipo,
        },
    });
    ponto_popup.$mount();
    marcador.bindPopup(ponto_popup.$el);
    return adcMarcadorComoEvento(marcador, evento);
}

export function criarRastrosRota(rastros) {
    var info = _.map(rastros, (r, i) => {
        var path = new antPath(
            r.rastro.map((rr) => {
                return rr.bilatlog.split(",");
            }),
            {
                color: "red",
                interactive: false,
                delay: 2000,
                weight: 4,
            }
        );
        Object.defineProperties(path, {
            veplaca: {
                value: i,
                writable: false,
                enumerable: true,
            },
            rastro_rota: {
                value: true,
                writable: false,
                enumerable: true,
            },
            rocodico: {
                value: r.rocodico,
                writable: false,
                enumerable: false,
            },
        });
        return path;
    });
    return info;
}

export function criarPolylineRastroGrupo(rastro, placa, prefixo) {
    var cor = corAleatoria();
    var polyline = antPath(rastro, {
        weight: 6,
        color: cor,
        delay: 2000,
        interactive: true,
    });
    Object.defineProperties(polyline, {
        rastroGrupo: {
            value: true,
            writable: false,
            enumerable: true,
            configurable: false,
        },
    });
    var legenda = {
        titulo: placa,
        cor: cor,
        key: `rastro_veiculo_${placa}`,
    };
    let tooltip = L.tooltip({
        className: "tooltipMapa",
        content: placa + (prefixo ? ' | ' + prefixo : ''),
        direction: "center",
        sticky: true,
    });
    polyline.bindTooltip(tooltip);
    return {
        polyline: polyline,
        legenda: legenda,
    };
}

export function definePropsMarcadorItemRota(marker, item) {
    marker = defineComoRota(marker, item.irrota);
    Object.defineProperty(marker, "ircodigo", {
        value: item.ircodigo,
        enumerable: true,
        configurable: false,
        writable: false,
    });
    var popup = PopupPontoRota;
    popup = Vue.extend(popup);
    popup = new popup({ propsData: { ponto: item } });
    popup = popup.$mount();
    marker.bindPopup(popup.$el);
    return marker;
}

export function criarMarcadorItemRota(item) {
    var icon = L.divIcon({
        html: criaHtmlMarcadorItemRota(item),
        iconSize: [0, 0],
    });
    var marker = L.marker([item.ponto.polatitude, item.ponto.polongitude], {
        icon: icon,
    });
    return marker;
}

/**
 * Define as propriedades de um marcador Leaflet para uma carga
 * @param {Object} marker - Marcador Leaflet.
 * @param {Object} carga - O objeto de carga com as informações a serem associadas ao marcador.
 * @returns {Object} - Marcador Leaflet com poupup.
 * @author Otávio 🦆 
 */

export function definePropsMarcadorCarga(marker, carga) {
    marker = defineComoRota(marker, carga.cacodigorotatransbordo);
    Object.defineProperty(marker, "cacodigo", {
        value: carga.cacodigo,
        enumerable: true,
        configurable: false,
        writable: false,
    });
    
    var popup = PopupCarga;
    popup = Vue.extend(popup);
    popup = new popup({ propsData: { carga: carga } });
    popup = popup.$mount();

    var leafletPopup = L.popup({
        maxWidth: 400, 
        minWidth: 270, 
        maxHeight: 250 
    })
    .setContent(popup.$el);

    marker.bindPopup(leafletPopup);
    return marker;
}


/**
 * Cria um marcador Leaflet para uma carga com o ícone 
 * baseado no status da carga.
 *
 * @param {Object} carga - O objeto da carga
 * @returns {Object} - O marcador Leaflet com o ícone.
 * @author Otávio 🦆 
 */
export function criarMarcadorCarga(carga) {
    var getClass = function (statusFlag) {
        switch (statusFlag) {
            case "J":
                return "yellow";
            case "P":
                return "blue";
            case "R":
                return "green";
            default:
                return "orange";
        }
    };

    var getIcon = function (statusFlag) {
        var ordem = carga.caordem || "-";
       
            switch (statusFlag) {
                case "J":
                    return mdiAlertCircleOutline;
                case "R":
                    return checkIcon;
                case "P":
                    return ordem;
                default:
                    return someDefaultIcon;
            }
        
    };

    var markerClass = getClass(carga.status_flag);
    var iconHtml = getIcon(carga.status_flag);
    
    var icon = L.divIcon({
        html: `
            <div class='marker-pin ${markerClass}'>
                <i>
                    <div class='centroMarker'>
                        ${iconHtml}
                    </div>
                </i>
            </div>
        `,
        className: '',
        iconSize: [25, 41],
        iconAnchor: [12, 41]
    });

    var marker = L.marker([carga.ponto.polatitude, carga.ponto.polongitude], {
        icon: icon,
    });

    return marker;
}

/**
* @description Trata a lógica de exibição dos alerta de acordo com a cor do mesmo. 
* E monta o html do ícone com o estilo circular ao redor dó icone. 
* @author Otávio 🦆 
*/
export function criaHtmlMarcadorAlerta(alerta, iconeMdi) {
    let classCor;
    if (alerta.alstatus === 'L') {
        classCor = 'marker-green';
    } else {
        switch(alerta.tacor) {
            case 'amarelo':
                classCor = 'marker-yellow';
                break;
            case 'vermelho':
                classCor = 'marker-red';
                break;
            default:
                classCor = '';
                break;
        }
    }

    return `
        <div class='marker-circle ${classCor}'>
            <svg viewBox="0 0 24 24" class="centroMarker" style="font-size: 15px;">
                <path d="${iconeMdi}"></path>
            </svg>
        </div>
    `;
}


/**
* @description cria o ícone no mapa dos alerta e 
* o pop-ups com a descrição dos alertas 
* @author Otávio 🦆 
*/
export function criarMarcadorAlerta(alerta, iconeMdi, callback, callbackAlertaLidoRota) {
    const [latitude, longitude] = alerta.allocalizacao.split(',').map(coord => parseFloat(coord));
    const iconHtml = criaHtmlMarcadorAlerta(alerta, iconeMdi);

    var icon = L.divIcon({
        html: iconHtml,
        className: '',
        iconSize: [60, 60],
        iconAnchor: [30, 30],
    });

    var marker = L.marker([latitude, longitude], { icon: icon });

    var popup = PopupAlertaRota;
    popup = Vue.extend(popup);
    popup = new popup({ propsData: { alertas: alerta } });
    popup.$on('acaoConcluida', (alcodigo) => {
        callback(alcodigo);
    });
    popup.$on('alerta-lido-rota', callbackAlertaLidoRota);


    popup = popup.$mount();
    marker.bindPopup(popup.$el);   
    return marker;
}
