<template>
    <panelEagle id="linhas"
        :loading="loadingPanel">
        <div class="col-sm-12 nopadding">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiBusClock" titulo="Linhas"></tituloPage>
                </div>
                <div class="col-sm-6 divButtonsBasic divDontPrint">
                    <basicButtonsRelatoriosEIG
                        :disabledDropdown="!Object.keys(dadosLinhas).length"
                        @exportarRelatorio="exportarRelatorio" 
                        @gerarRelatorio="gerarRelatorio"
                        :loading           ='loadingExportar'
                        :disabled          ="$v.$invalid">
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12"><hr></div>
                    <div class="col-sm-12 justpaddingbottom row">
                        <div class="col-sm-4 nopadding">
                            <selectAll 
                                @changeSelect="SelectEmpresas" 
                                :isMultiple="true" 
                                nameForRadio="radiosEmpresas" 
                                :labels="labelEmpresas" 
                                :optionsArray="optionEmpresas" 
                                :selected="selectedEmpresa"
                                firstSelected="E" 
                                :hasSelectAll="true">
                            </selectAll>
                        </div>
                        <div class="col-sm-4 nopadding">
                            <selectAll 
                                @selectCriado="selectLinhasCriado"  
                                @changeSelect="SelectLinhas" 
                                :isMultiple="true" 
                                nameForRadio="radiosLinhas" 
                                :labels="labelLinhas" 
                                :optionsArray="optionLinhas" 
                                firstSelected="L" 
                                :loading="carregando"
                                :hasSelectAll="true">
                            </selectAll>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
        </div>
        <div class="col-sm-12 divHrDeBaixo"><hr></div>
        <tableRelatorio>
            <slot slot="tbody">
                <br>
                <statusInformation 
                v-if="tamanhoLinhas == 0"
                    typeBar="div" 
                    :statusBar="statusbar">
                </statusInformation>
                <template v-for="(linha, l) in dadosLinhas">
                    <div :key="l"
                        class="col-sm-7 badgeLinha">
                        {{l}}
                    </div>
                    <div :key="l+'_dadosTabela'"
                        class="col-sm-12 row dadosTabela">
                        <!-- //Informações Gerais -->
                        <div v-for="(geral, g) in linha"
                            v-show="g == 'InformacoesGerais'"
                            :key="g+'_InformacoesGerais'"
                            class="col-sm-12 row dadosSubtitulos">  
                            <div class="col-sm-12 subtitulos">{{g}}</div>
                            <div class="col-sm-12"><span>Descritivo Itinerário:</span> 
                                {{geral.DescritivoItinerario}}</div>

                            <div class="col-sm-3">
                                <span>
                                    Velocidade Média: 
                                </span>
                                {{geral.VelocidadeMedia}}</div>
                            <div class="col-sm-3">
                                <span>
                                    Tipo: 
                                </span>
                                {{geral.Tipo}}</div>
                            <div class="col-sm-3">
                                <span>
                                    Itinerário: 
                                </span>
                                {{geral.Itinerario}}</div>
                            <div class="col-sm-3">
                                <span>
                                    Quantidade de Pontos: 
                                </span>
                                {{geral.QuantidadePontos}}</div>

                            <div class="col-sm-3">
                                <span>
                                    Prefixo: 
                                </span>
                                {{geral.Prefixo}}</div>
                            <div class="col-sm-3">
                                <span>
                                    Principal: 
                                </span>
                                {{geral.Principal}}</div>
                            <div class="col-sm-3">
                                <span>
                                    Atraso tolerado: 
                                </span>
                                {{geral.AtrasoTolerado}}</div>
                            <div class="col-sm-3">
                                <span>
                                    Quantidade de escalas associadas: 
                                </span>
                                {{geral.QuantidadeEscalasAssociadas}}</div>

                            <div class="col-sm-3">
                                <span>
                                    Categoria: 
                                </span>
                                {{geral.Categoria}}</div>
                            <div class="col-sm-3">
                                <span>
                                    Validador: 
                                </span>
                                {{geral.Validador}}</div>
                            <div class="col-sm-3">
                                <span>
                                    App/Integração: 
                                </span>
                                {{geral.AppIntegracao}}</div>
                            <div class="col-sm-3">
                                <span>
                                    Quantidade de variáveis: 
                                </span>
                                {{geral.QuantidadeVariaveis}}</div>
                        </div>

                        <!-- //Sequência de Pontos -->
                        <div v-for="(pontos, p) in linha"
                            v-show="p == 'SequenciaDePontos'"
                            :key="p+'_SequenciaDePontos'"
                            class="col-sm-12 row dadosSubtitulos">  
                            <div class="col-sm-12 subtitulos">{{p}}</div>
                            <div v-for="(dadosPontos, dp) in pontos"
                                :key="dp"
                                class="col-sm-3">
                                {{dadosPontos}}
                            </div>
                        </div>

                        <!-- Horários -->
                        <div v-for="(horarios, h) in linha"
                            v-show="h == 'Horarios'"
                            :key="h+'_horarios'"
                            class="col-sm-12 row dadosSubtitulos">  
                            <div class="col-sm-12 subtitulos">Horários</div>
                            <div class="col-sm-6 subSubtitulos">Dia da semana</div>
                            <div class="col-sm-6 subSubtitulos">Horários</div>
                            <!-- Dados horarios -->
                            <div v-for="(dadosHorarios, dh) in horarios"
                                :key="dh"
                                class="col-sm-12 row dadosHorarios">
                                <div class="col-sm-6">{{dh}}</div>
                                <div class="col-sm-6">{{dadosHorarios}}</div>
                            </div>
                        </div>
                    </div>
                </template>
            </slot>
        </tableRelatorio>
    </panelEagle>
</template>

<script>
import {mdiBusClock } from '@mdi/js'
import Vue               from 'vue'
import {required}        from 'vuelidate/lib/validators'
import {EmpresasService} from '../../../Services/auth/Empresas.service'
import {HttpRequest}     from '../../../Services/auth/HttpRequest.Service'
import {FiltrosService}  from '../../../Services/filtros/filtros.Service'
import { mapGetters } from 'vuex'

export default ({
	components:{
		'panelEagle'                : require('@/components/Atom/Panel/PanelEagle').default,
		'slideUpAndDown'            : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
		'tituloPage'                : require('@/components/Atom/Header/Titulo').default,
		'basicButtonsRelatoriosEIG' : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
		'selectAll'                 : require('@/components/Atom/Select/SelectAll').default,
		'statusInformation'         : require('@/components/Atom/StatusInformation/StatusInformation').default,
		'tableRelatorio'            : require('@/components/Atom/Table/TableRelatorio').default,
	},

	validations:{
		selectedEmpresas:{
			required
		},
		selectedLinhas:{
			required
		},
	},

	data(){
		return{
			mdiBusClock:mdiBusClock,
			labelEmpresas:[{indexDFH: 'E', description: 'Empresa *'}],
			labelLinhas:[{indexDFH: 'L', description: 'Linhas *'}],
			selectedEmpresas: [],
			selectedLinhas: [],
			optionEmpresas: new EmpresasService().Get(),
			optionLinhas: [],
			selectLinhas: [],
			htmlLinhas: '',
			tamanhoLinhas: 0,
			dadosLinhas: [],
			statusbar: 'info',
			loadingPanel                : false,
			loadingExportar             : [false, false, false],
			carregando                  : false,
            selectedEmpresa: [],
		}
	},

	methods:{
        ...mapGetters(['getMaster']),

		SelectEmpresas(event){
			this.selectedEmpresas = event;
			this.selectLinhas.clearAll();
			this.dadosFiltros();
		},
		selectLinhasCriado(event){
			this.selectLinhas = event;
		},
		SelectLinhas(event){
			this.selectedLinhas = event;
		},
		async dadosFiltros(){
			this.carregando = true
			var dados = [];
			dados = await new FiltrosService().dados_filtros(this.selectedEmpresas, ['L'])
			this.optionLinhas = dados['L'];
			this.carregando = false
		},
		async gerarRelatorio(){
			this.loadingPanel = true;
			if(this.selectedEmpresas.length > 0 && this.selectedLinhas.length > 0){
				this.htmlLinhas = '';
				this.dadosLinhas = [];
				var data = await new HttpRequest().Post('/coletivos/relatorios/linhas/gerar/linhas', 
					{'clientes' : this.selectedEmpresas, 
						'linhas'   : this.selectedLinhas})
				var dados = data.data;
				this.tamanhoLinhas = dados.tamanho
				if(this.tamanhoLinhas > 0){
					this.dadosLinhas = dados.relatorio
					this.htmlLinhas = '';
				}else{
					this.htmlLinhas = '';
					this.dadosLinhas = [];
					this.statusbar = 'error'
				}
			}
			this.loadingPanel = false
		},
		async exportarRelatorio(type){
			var root =  process.env.VUE_APP_ROOT;
			if(this.tamanhoLinhas > 0){
				switch(type){
					case 'pdf':
						this.loadingExportar = [true, false, false]
						break;
					case 'xls':
						this.loadingExportar = [false, true, false]
						break;
					case 'csv':
						this.loadingExportar = [false, false, true]
						break;
					default:
						break;
				}
				var data = await new HttpRequest().Post('/coletivos/relatorios/linhas/exportar/linhas', 
					{'arrayDados' :this.dadosLinhas, 
						'tipo'       :type});
				window.open(root+'/'+data.data);
				this.loadingExportar = [false, false, false]
			}
		},
	},

    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optionEmpresas
        }
    }
})
</script>

<style lang="scss">
    #linhas{
        .divMasterBlock{
            .backColorTdPDF{
                background-color: #fff;
            }
            .lineSubtittle{
                background-color: #e7e5e6;
                font-weight: bold;
                border-left: 1px solid #d5d5d5;
                padding-top: 5px !important;
                padding-bottom: 5px !important;
                padding-left: 5px !important;
                border-right: 1px solid #d5d5d5;
                border-top: 1px solid #d5d5d5;
            }
            .subLineSubtitle{
                border: 1px solid #d5d5d5;
                padding-top: 3px !important;
                padding-bottom: 1px !important;
                background: #e8eaec;
                margin-left: 0px;
                font-weight: bold;
            }
            .blockSimple{
                background-color: #f9f9f9;
                border: 1px solid #d5d5d5;
                display: flex;
                flex-wrap: wrap;

                .divLittleDesc{
                    padding-top: 3px;
                    padding-left: 3px;
                    display: flex;
                    // padding-bottom: 3px;
                }
            }

        }
        .badgeLinha{
            text-align: left;
            padding-top: 5px;
            color: #fff;
            background: #777777;
            height: 30px;
            margin-top: 15px;
            vertical-align: middle;
        }
        .dadosTabela{
            background: #fff;
            text-align: left;
            padding-right: 0px;
            padding-left: 0px;
            div.dadosSubtitulos{
                border-right: 1px solid #d5d5d5;
                padding-left: 0px;
                padding-right: 0px;
                div.subtitulos{
                    background: #e7e5e6;
                    font-weight: bold;
                }
                div.subSubtitulos{
                    background: #e8eaec;
                    font-weight: bold;
                }
                div{
                    padding-top: 5px;
                    border-left: 1px solid #d5d5d5;
                    border-bottom: 1px solid #d5d5d5;
                    span{
                        font-weight: bold;
                    }
                }
                div.dadosHorarios{
                    padding: 0px;
                    border: none;
                }
            }
        }
    }
</style>
