<template>
    <LoadingButton
        v-if="loading != null"
        :disabled="disabled"
        :isLoading="loading"
        @click="click"
        event="click"
        :text="text"
        :icon="mdiPrinter"
        type="light-blue"
        :width="width"
    />
    <SimpleButton
        v-else
        :disabled="disabled"
        @click="click"
        event="click"
        :text="text"
        :icon="mdiPrinter"
        type="light-blue"
        :width="width"
    />
</template>

<script lang="js">
import SimpleButton from "@/components/Atom/Buttons/SimpleButton.vue";
import LoadingButton from "@/components/Atom/Buttons/LoadingButton.vue";

import { mdiPrinter } from '@mdi/js';

export default {
    components: {
        SimpleButton,
        LoadingButton,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: [Boolean, null],
            default: null
        },
        width: {
            type: String,
            default: '20%'
        },
        text: {
            type: String,
            default: 'Imprimir'
        },
    },
    data() {
        return {
            mdiPrinter: mdiPrinter,
        };
    },
    methods: {
        click() {
            this.$emit('click')
        },
    },
};
</script>
