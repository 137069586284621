<template>
    <panelEagle id="absenteismo" :loading="loadingPanel">
        <div class="col-sm-12 nopadding">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiAccountClock" titulo="Absenteísmo"></tituloPage>
                </div>
                <div class="col-sm-6 divButtonsBasic divDontPrint">
                    <basicButtonsRelatoriosEIG @exportarRelatorio="exportarRelatorio" @gerarRelatorio="gerarRel"
                        :loading='loadingExportar' :disabled="$v.optionsSelectedCGC.$invalid"
                        :disabledDropdown="$v.dadosAbsenteismo.$invalid">
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12">
                        <hr>
                    </div>
                    <div class="col-sm-12 justpaddingbottom row">
                        <div class="col-sm-4 nopadding">
                            <inputRangeWithLimit @changeInput="changeDataIntervalo" :isObrigatorio="true"
                                name="inputDataAbsenteismo" opens="right">
                            </inputRangeWithLimit>
                        </div>
                        <div class="col-sm-4 nopadding">
                            <selectAll @changeSelect="SelectEmpresas" :isMultiple="true" nameForRadio="radiosEmpresas"
                                :labels="labelsSelectEmpresa" :optionsArray="optionsSelectEmpresa" firstSelected="E"
                                :hasSelectAll="true" :selected="selectedEmpresa">
                            </selectAll>
                        </div>
                        <div class="col-sm-4 nopadding">
                            <selectAll @changeSelect="changeSelectColaboradores" @changeCheck="registraCheckColab"
                                @selectCriado="selectCriado" :isMultiple="true" nameForRadio="radiosLinhasLinhasPrincipais"
                                :labels="labelsSelectColaboradores" :optionsArray="optionsSelectCGC" firstSelected="C"
                                :hasSelectAll="true" :loading="loadingColaboradores" :disabled="$v.optionsSelectE.$invalid">
                            </selectAll>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
            <div class="col-sm-12">
                <hr>
            </div>
        </div>
        <tableRelatorio>
            <slot slot="thead">
                <th>Nome</th>
                <th>Previsto</th>
                <th>Ausência</th>
                <th>Presença</th>
                <th>Absenteísmo</th>
                <th class="esperaTable"></th>
            </slot>
            <slot slot="tbody">
                <statusInformation v-if="tamanhoAbsenteismo == 0" typeBar="tr" :statusBar="statusbar">
                </statusInformation>
                <template>
                    <br>
                    <tr v-for="(dados, d) in dadosAbsenteismo" :key="d" class="dadosTabela">
                        <td>
                            {{ d }}
                        </td>
                        <td>
                            {{ dados.previsto }}
                        </td>
                        <td>
                            {{ dados.ausencia }}
                        </td>
                        <td>
                            {{ dados.presença }}
                        </td>
                        <td>
                            {{ dados.absenteismo }}
                        </td>
                        <td :title="dados.temEspera ? 'Há ' + dados.espera + ' em espera' : ''">
                            <baseIcon v-show="dados.temEspera" size='18' class="iconTitulo" :icon="mdiAlert" />
                        </td>
                    </tr>
                </template>
            </slot>
        </tableRelatorio>
    </panelEagle>
</template>

<script>
import Vue from 'vue'
import { mdiAccountClock, mdiAlert } from '@mdi/js'
import { required } from 'vuelidate/lib/validators'
import { EmpresasService } from '../../../Services/auth/Empresas.service'
import { HttpRequest } from '../../../Services/auth/HttpRequest.Service'
import { FiltrosService } from '../../../Services/filtros/filtros.Service'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name: 'absenteismo',
    components: {
        baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
        'panelEagle': require('../../../components/Atom/Panel/PanelEagle').default,
        'tituloPage': require('../../../components/Atom/Header/Titulo').default,
        'basicButtonsRelatoriosEIG': require('../../../components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
        'slideUpAndDown': require('../../../components/Atom/SlideUpAndDown/SlideUpAndDown').default,
        'selectAll': require('../../../components/Atom/Select/SelectAll').default,
        'inputRangeWithLimit': require('../../../components/Atom/Datas/InputRangeWithLimit').default,
        'statusInformation': require('../../../components/Atom/StatusInformation/StatusInformation').default,
        'tableRelatorio': require('../../../components/Atom/Table/TableRelatorio').default,
    },

    validations: {
        optionsSelectE: {
            required
        },
        optionsSelectedCGC: {
            required
        },
        dadosAbsenteismo: {
            required,
        },
    },

    data() {
        return {
            mdiAlert: mdiAlert,
            mdiAccountClock: mdiAccountClock,
            labelsSelectEmpresa: [
                { indexDFH: 'E', description: 'Empresas*' },
            ],
            optionsSelectEmpresa: new EmpresasService().Get(),
            labelsSelectColaboradores: [
                { indexDFH: 'C', description: 'Colaboradores*' },
                { indexDFH: 'GC', description: 'Grupos*' },
            ],
            optionsSelectCGC: [],
            optionsSelectE: [],
            optionsSelectedCGC: [],
            optionRadiusColab: '',
            selectColaboradores: [],
            dataIntervalo: '',
            htmlAbsenteismo: '',
            statusbar: 'info',
            dadosAbsenteismo: [],
            tamanhoAbsenteismo: 0,
            dataToExport: '',
            loadingPanel: false,
            loadingExportar: [false, false, false],
            selectedEmpresa: [],
            loadingColaboradores: false,
        }
    },
    methods: {
        ...mapGetters(['getMaster']),
        SelectEmpresas(event) {
            this.optionsSelectE = event;
            this.selectColaboradores.clearAll();

            this.limpaRelatorio();
        },
        changeSelectColaboradores(event) {
            this.optionsSelectedCGC = event;

            this.limpaRelatorio();
        },
        changeDataIntervalo(data) {
            this.dataIntervalo = data;

            this.limpaRelatorio();
        },
        selectCriado(select) {
            this.selectColaboradores = select;

            this.limpaRelatorio();
        },
        /**
        * @description consulta a variável this.tamanhoAbsenteismo caso conter dados limpa os dados do relatório
        * @author Yuri 🇧🇷
        */
        limpaRelatorio() {
            if (this.tamanhoAbsenteismo > 0) {
                this.dadosAbsenteismo = []
                this.tamanhoAbsenteismo = 0
            }
        },
        registraCheckColab(check, needRecharge) {
            this.optionRadiusColab = check
            if (needRecharge) {
                this.dados_filtros();
            }
        },
        async dados_filtros() {
            var dados = [];
            this.loadingColaboradores = true
            dados = await new FiltrosService().dados_filtros(this.optionsSelectE, [this.optionRadiusColab])
            this.optionsSelectCGC = dados[this.optionRadiusColab];
            this.loadingColaboradores = false
        },

        async gerarRel() {
            this.loadingPanel = true
            if (this.optionsSelectE.length > 0 && this.optionsSelectCGC.length > 0 && this.dataIntervalo.length > 0) {
                this.dadosAbsenteismo = [];
                this.tamanhoAbsenteismo = 0;

                var data = await new HttpRequest().Post('/controle/jornada/relatorios/absenteismo/gerar/relatorio',
                    {
                        'clientes': this.optionsSelectE,
                        'pesquisarPor': this.optionRadiusColab,
                        'motGrupo': this.optionsSelectedCGC,
                        'data': this.dataIntervalo
                    })
                var dados = data.data;
                if (dados) {
                    this.tamanhoAbsenteismo = dados.tamanho
                }

                if (this.tamanhoAbsenteismo > 0) {
                    this.dadosAbsenteismo = dados.absenteismo
                    this.dataToExport = this.dataIntervalo;
                    this.htmlAbsenteismo = '';
                } else {
                    this.dadosAbsenteismo = [];
                    this.htmlAbsenteismo = '';
                    this.statusbar = 'error'
                }

                this.loadingPanel = false
            }
        },

        async exportarRelatorio(type) {
            var root = process.env.VUE_APP_ROOT;
            this.setLoading(type)
            if (this.tamanhoAbsenteismo > 0) {
                var data = await new HttpRequest().Post('/controle/jornada/relatorios/absenteismo/exportar',
                    {
                        'arrayDados': this.dadosAbsenteismo,
                        'tipo': type,
                        'data': this.dataToExport
                    });
                window.open(root + '/' + data.data.local);
            }
            this.setLoading()
        },

        setLoading(tipo) {
            let arr = [tipo == 'pdf', tipo == 'xls', tipo == 'csv']
            this.loadingExportar = arr;
        },
    },

    mounted() {
        if (!this.getMaster()) {
            this.selectedEmpresa = this.optionsSelectEmpresa
        }
    }
})
</script>

<style lang="scss">
#absenteismo {
    .esperaTable {
        width: 63px;
    }

    .dadosTabela {
        td {
            border: 1px solid #e4e4e4;
        }
    }
}
</style>