<template>
    <panelEagle id="absenteismo" 
        :loading="loadingPanel">
        <div class="col-sm-12 nopadding">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiAccountClock" titulo="Absenteísmo"></tituloPage>
                </div>
                <div class="col-sm-6 divButtonsBasic divDontPrint">
                    <basicButtonsRelatoriosEIG 
                        @exportarRelatorio ="exportarRelatorio" 
                        @gerarRelatorio    ="gerarRel"
                        :loading           ='loadingExportar'
                        :disabled          ="$v.optionsSelectedCGC.$invalid"
                        :disabledDropdown  ="$v.dadosAbsenteismo.$invalid">
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12"><hr></div>
                    <div class="col-sm-12 justpaddingbottom row">
                        <div class="col-sm-4 nopadding">
                            <inputRangeWithLimit 
                                name="inputDataAbsenteismo"
                                opens="right"
                                :isObrigatorio="true" 
                                @changeInput="changeDataIntervalo" 
                            />
                        </div>
                        <div class="col-sm-4 nopadding">
                            <selectAll 
                                :isMultiple="true" 
                                nameForRadio="radiosEmpresas" 
                                :labels="labelsSelectEmpresa" 
                                firstSelected="E" 
                                :hasSelectAll="true"
                                :selected="selectedEmpresa"
                                :optionsArray="optionsSelectEmpresa" 
                                @changeSelect="SelectEmpresas" 
                            />
                        </div>
                        <div class="col-4 nopadding">
                            <select-all
                            nameForRadio='selTrabalhador'
                            ref='selectColaboradores'
                            :labels='labelSelectTrabalhador'
                            :isMultiple='true'
                            :disabled='desabilitaSeletorT'
                            :hasSelectAll='true'
                            :loading='loadingColaboradores'
                            :optionsArray='optSelectWorker'
                            :extraClass='{
                            "border border-danger":$v.optionsSelectedCGC.$anyError
                            }'
                            firstSelected='MAD'
                            @close='$v.optionsSelectedCGC.$touch()'
                            @changeSelect='changeSelectColaboradores'
                            @changeCheck ='changeCTrabalhador'/>
                        </div>
                        <!-- <div class="col-sm-4 nopadding">
                            <selectAll 
                                ref='selectColaboradores'
                                :isMultiple   ="true" 
                                nameForRadio  ="radiosLinhasLinhasPrincipais" 
                                :labels       ="labelsSelectColaboradores" 
                                :optionsArray ="optionsSelectCGC" 
                                firstSelected ="C" 
                                :hasSelectAll ="true"
                                :loading      ="loadingColaboradores"
                                :disabled     ="$v.optionsSelectE.$invalid"
                                @changeSelect ="changeSelectColaboradores" 
                            />
                        </div> -->
                    </div>
                </slot>
            </slideUpAndDown>
            <div class="col-sm-12"><hr></div>
        </div>
        <tableRelatorio>
            <slot slot="thead">
                <th>Nome</th>
                <th>Previsto</th>
                <th>Ausência</th>
                <th>Presença</th>
                <th>Absenteísmo</th>
            </slot>
            <slot slot="tbody">
                <br>
                <statusInformation 
                    v-if="tamanhoAbsenteismo == 0"
                    typeBar="tr" 
                    :statusBar="statusbar">
                </statusInformation>
                <template>
                    <br>
                    <tr v-for="(dados, d) in dadosAbsenteismo"
                        :key="d"
                        class="dadosTabela">
                        <td>
                            {{d}}
                        </td>
                        <td>
                            {{dados.previsto}}
                        </td>
                        <td>
                            {{dados.ausencia}}
                        </td>
                        <td>
                            {{dados.presença}}
                        </td>
                        <td>
                            {{dados.absenteismo}}
                        </td>
                    </tr>
                </template>
            </slot>
        </tableRelatorio>
    </panelEagle>
</template>

<script>
import Vue from 'vue'
import { mdiAccountClock, mdiAlert } from '@mdi/js'
import { required }      from 'vuelidate/lib/validators'
import { EmpresasService } from '../../../Services/auth/Empresas.service'
import { HttpRequest }     from '../../../Services/auth/HttpRequest.Service'
import { conectionError } from '@/Services/Helpers/swellHeper'
import { FiltrosService }  from '../../../Services/filtros/filtros.Service'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name:'Absenteismo',
    components:{
        'panelEagle': require('../../../components/Atom/Panel/PanelEagle').default,
        'tituloPage': require('../../../components/Atom/Header/Titulo').default,
        'basicButtonsRelatoriosEIG': require('../../../components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
        'slideUpAndDown': require('../../../components/Atom/SlideUpAndDown/SlideUpAndDown').default,
        'selectAll': require('../../../components/Atom/Select/SelectAll').default,
        'inputRangeWithLimit': require('../../../components/Atom/Datas/InputRangeWithLimit').default,
        'statusInformation': require('../../../components/Atom/StatusInformation/StatusInformation').default,
        'tableRelatorio': require('../../../components/Atom/Table/TableRelatorio').default,
    },

    validations:{
        optionsSelectE:{ 
            required
        },
        optionsSelectedCGC:{
            required
        },
        dadosAbsenteismo:{
            required,
        },
    },

    data(){
        return {
            mdiAlert:mdiAlert,
            mdiAccountClock:mdiAccountClock,
            labelsSelectEmpresa:[
                {indexDFH: 'E', description: 'Empresas*'},
            ],
            optionsSelectEmpresa: new EmpresasService().Get(),
            labelSelectTrabalhador:[
                {indexDFH:'MAD', description:'Colaborador*'},
                {indexDFH:'D', description:'Departamento'}
            ],
            optTrabalhador:{
				'MAD':[],
				'D':[]
			},
            optionsSelectCGC: [],
            optionsSelectE: [],
            optionsSelectedCGC: [],
            selectColaboradores: [],
            dataIntervalo: '',
            htmlAbsenteismo: '',
            statusbar: 'info',
            dadosAbsenteismo: [],
			labelTrabalhador:'MAD',
            tamanhoAbsenteismo: 0,
            dataToExport: '',
            loadingPanel       : false,
            loadingExportar    : [false, false, false],
            selectedEmpresa    : [],
            loadingColaboradores: false,
            url: '/checkpoint/relatorios/absenteismo'
        }
    },
    methods:{
        limpaTabela(){
            this.tamanhoAbsenteismo = 0;
            this.dadosAbsenteismo = []
        },
        ...mapGetters(['getMaster']),
        SelectEmpresas(event){
            this.limpaTabela()
            this.optionsSelectE = event;
            this.$refs.selectColaboradores.clearAll();
            this.optTrabalhador = {
				'MAD':[],
				'A':[]
			}
            if(event.length){
				this.loadingColaboradores = true
				new FiltrosService()
					.dados_filtros(this.optionsSelectE, ['D', 'MAD'])
					.then((res)=>{
						this.optTrabalhador = res
					}).finally(()=>{this.loadingColaboradores = false})
			}
        },

        changeSelectColaboradores(event){
            this.limpaTabela()
            this.optionsSelectedCGC = event;
        },
        
        changeDataIntervalo(data){
            this.limpaTabela()
            this.dataIntervalo = data;
        },

        selectCriado(select){
            this.selectColaboradores = select;
        },

        /**
         * @listens changeCheck 
         * @param {'MAD'|'D'} label
        */
		changeCTrabalhador(label){
            this.limpaTabela()
			this.labelTrabalhador = label
		},

        gerarRel(){
            this.loadingPanel = true
            this.dadosAbsenteismo = [];
            this.tamanhoAbsenteismo = 0;
            let obj = {
                'clientes': this.optionsSelectE, 
                'tipo': this.labelTrabalhador, 
                'motGrupo': this.optionsSelectedCGC, 
                'data': this.dataIntervalo
            }
            new HttpRequest().Post(this.url+'/gerar/relatorio', obj)
                .then((resposta) => {
                    let dados = resposta.data;
                    this.tamanhoAbsenteismo = dados.tamanho
                    if(this.tamanhoAbsenteismo > 0){
                        this.dadosAbsenteismo = dados.absenteismo
                        this.dataToExport = this.dataIntervalo;
                        this.htmlAbsenteismo = '';
                    }else{
                        this.dadosAbsenteismo = [];
                        this.htmlAbsenteismo = '';
                        this.statusbar = 'error'
                    }
                }).catch(() => {
                    conectionError()
                }).finally(() => { 
                    this.loadingPanel = false 
                })
        },

        async exportarRelatorio(type){
            var root =  process.env.VUE_APP_ROOT;
            this.setLoading(type)
            if(this.tamanhoAbsenteismo > 0){
                var data = await new HttpRequest().Post(this.url+'/exportar', 
                {'arrayDados': this.dadosAbsenteismo, 
                 'tipo':type, 
                 'data':this.dataToExport});
                window.open(root+'/'+data.data.local);
            }
            this.setLoading()
        },

        setLoading(tipo){
            let arr = [tipo=='pdf', tipo=='xls', tipo=='csv']
            this.loadingExportar =  arr;
        },
    },

    computed: {
        //decide se o seletor ta enable ou disabled
        desabilitaSeletorT(){
			if(this.optSelectWorker.length)
				return false
			return true
		},

		optSelectWorker(){
			return this.optTrabalhador[this.labelTrabalhador] ?? []
		},
    },

    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optionsSelectEmpresa
        }
    }
})
</script>

<style lang="scss">
    #absenteismo{
        .esperaTable {
            width: 63px;
        }
        .dadosTabela{
            td{
                border: 1px solid #e4e4e4;
            }
        }
    }
</style>