/**
    Componente compoe a tabela de monitoramento de linhas, gera os ícones de pontos seus status e títulos
*/

<template>
    <div class="timePorPonto" :style="'width:'+tamanho+'%'">
        <!-- Gera os pontos -->
        <baseIcon 
            v-b-popover.hover.html.top="tituloPonto"
            size='25' 
            :class="'iconPonto iconStatus-'+pontoDados.statusPonto" 
            :icon="mdiRoutes"
        />
    </div>
</template>

<script lang="ts">
import {mdiRoutes} from '@mdi/js'
import Vue from 'vue'
export default Vue.extend({
    name:'MonitoramentoLinhaPontos',
    props:{
        tamanho:{
            type: Number,
            required: true,
            default: 0,
        },
        arrayDados: {
            type: Object,
            required: true,
            default: function(){return {}}
        },
    },
    components:{
        baseIcon:require('@/components/Atom/Icon/BaseIcon.vue').default,
    },
    data(){
        var pontoDados = this.arrayDados;
        return{
            mdiRoutes:mdiRoutes,
            pontoDados
        }
    },
    methods:{
        // Método verifica qual tipo de título o ponto deverá receber
        tituloPonto(){
            var pontoDados = this.arrayDados;
            var title = ''
            switch(pontoDados.status){
            case 'E':
                title += '<b>'+pontoDados.nome+'</b><br>'
                title += 'Tempo parado: '+pontoDados.tempoParado+'<br>';
                title += ''+(pontoDados.kmPercorrido != null ? pontoDados.kmPercorrido : '')+' Km <br>';
                title += 'Passou as: '+(pontoDados.horaPassou != null ? pontoDados.horaPassou : '');
                break;
            case 'N':
                title += '<b>'+pontoDados.nome+'</b><br>'
                title += pontoDados.mensagem;
                break;
            case 'P':
                title += '<b>'+pontoDados.nome+'</b><br>'
                title += 'Hora prevista: '+(pontoDados.horaPrevista != null ? pontoDados.horaPrevista : '');
                break;
            }
            return title
        }
    },
    mounted(){
        this.$emit('pontoCriado', this)
        this.tituloPonto()
    },
    watch:{
        arrayDados(){
            this.pontoDados = this.arrayDados;
        }
    },
})
</script>

<style lang="scss">

</style>