import { DateTime } from 'luxon';

export var pickerOptions = {
	shortcuts: [
		{
			text: 'Hoje',
			onClick(picker){
				const today = DateTime.now().toFormat('dd/LL/yyyy')
				picker.$emit('pick', [today, today])
			}
		},
		{
			text: 'Ontem',
			onClick(picker) {
				var end = DateTime.now()
				end = end.plus({'days':-1}).toFormat('dd/LL/yyyy')
				picker.$emit('pick', [end, end]);
			}
		},
		{
			text: 'Ult. Semana',
			onClick(picker) {
				var end = DateTime.now()
				var start = end.plus({'days':-7}).toFormat('dd/LL/yyyy')
				picker.$emit('pick', [start, end.toFormat('dd/LL/yyyy')])
			}
		}, 
		// {
		// 	text: 'Ult. 15 dias',
		// 	onClick(picker) {
		// 		var end = DateTime.now()
		// 		var start = end.plus({'days':-15}).toFormat('dd/LL/yyyy')
		// 		picker.$emit('pick', [start, end.toFormat('dd/LL/yyyy')])
		// 	}
		// }
	]
}