<template>
    <panelEagle id="registroJornada"
        :loading="loadingPanel">
        <div class="col-sm-12 nopadding">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage 
                        :icon="mdiCellphoneText" 
                        titulo="Registro de jornada"/>
                </div>
                <div class="col-sm-6 divButtonsBasic divDontPrint">
                    <basicButtonsRelatoriosEIG 
                        @exportarRelatorio ="exportarRelatorio" 
                        @gerarRelatorio ="gerarRelatorio"
                        :disabledDropdown ="!temDados"
                        :loading ='loadingExportar'
                        :disabled ="$v.$invalid">
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12"><hr></div>
                    <div class="col-sm-12 justpaddingbottom row">
                        <div class="col-sm-4 nopadding">
                            <inputRange
                                :isObrigatorio='true'
                                name='range-reg-jornada'
                                @changeInput='changeIntervalo'/>
                        </div>
                        <div class="col-sm-4 nopadding">
                            <selectAll 
                                @changeSelect ="SelectEmpresas" 
                                :isMultiple ="true" 
                                nameForRadio ="radiosEmpresas" 
                                :labels ="labelsSelectEmpresa" 
                                :optionsArray ="optionsSelectEmpresa" 
                                firstSelected ="E" 
                                :selected ="selectedEmpresa"
                                :hasSelectAll ="true">
                            </selectAll>
                        </div>
                        <div class="col-sm-4 nopadding">
                            <selectAll 
                                @changeSelect ="SelectColaborador" 
                                :isMultiple ="true" 
                                nameForRadio ="radiosColaborador" 
                                :labels ="labelsSelectColaborador" 
                                :optionsArray ="optionsSelectColaborador" 
                                firstSelected ="E" 
                                :hasSelectAll ="true"
                                :loading ="loading"
                                ref ="C"
                                :disabled ="$v.empresasArray.$invalid">
                            </selectAll>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
            <div class="col-sm-12 divHrDeBaixo"><hr></div>
        </div>
        <tableRelatorio>
            <slot slot="thead">
                <th>Data/hora</th>
                <th>Evento</th>
                <th>Perfil</th>
                <th>Intervalo</th>
                <th>Espera</th>
                <th>Inserção manual </th>
                <th>Placa</th>
                <th>Prefixo</th>
                <th>Origem</th>
            </slot>
            <slot slot="tbody">
                <statusInformation 
                    v-if="!temDados"
                    typeBar="tr" 
                    :statusBar="statusbar">
                </statusInformation>
                <template v-for="(colaborador, c) in dadosRegistroJornada">
                    <tr v-if="c"
                        :key="c+'_Nome'"
                        class="condutor">
                        <td colspan="9" align="left">
                            <span>{{c}}</span>
                        </td>
                    </tr>
                    <tr v-for="(dados, d) in colaborador"
                        :key="c+d+'_dadosTabela'+dados.inicio+dados.placa"
                        class="dadosTabela">
                        <td>{{dados.inicio}}</td>
                        <td>{{dados.evento}}</td>
                        <td>{{dados.perfil}}</td>
                        <td>
                            <baseIcon v-show='dados.intervalo' 
                                size="18" :icon="mdiCheck"/>
                        </td>
                        <td>
                            <baseIcon v-show='dados.espera' 
                                size="18" :icon="mdiCheck"/>
                        </td>
                        <td>
                            <baseIcon v-show="dados.manual" 
                                size="18" :icon="mdiCheck"/>
                        </td>
                        <td>{{dados.placa}}</td>
                        <td>{{dados.veprefixo}}</td>
                        <td>{{dados.origem}}</td>
                    </tr>
                </template>
            </slot>
        </tableRelatorio>
    </panelEagle>
</template>

<script>
import Vue from 'vue'
import { required } from 'vuelidate/lib/validators'
import { mdiCellphoneText, mdiCheck } from '@mdi/js'
import {EmpresasService} from '../../../Services/auth/Empresas.service'
import {HttpRequest} from '../../../Services/auth/HttpRequest.Service'
import {FiltrosService} from '../../../Services/filtros/filtros.Service'
import { mapGetters } from 'vuex'
import { conectionError } from '@/Services/Helpers/swellHeper';

export default Vue.extend({
    name:'registroJornada',
    components:{
        'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage': require('@/components/Atom/Header/Titulo').default,
        'basicButtonsRelatoriosEIG': require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
        'selectAll': require('@/components/Atom/Select/SelectAll').default,
        'slideUpAndDown': require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
        'statusInformation': require('@/components/Atom/StatusInformation/StatusInformation').default,
        'tableRelatorio': require('@/components/Atom/Table/TableRelatorio').default,
        'baseIcon': require('@/components/Atom/Icon/BaseIcon.vue').default,
		inputRange: require('@/components/Atom/Datas/InputRangeWithLimit').default,
    },

    validations:{
        empresasArray:{
            required
        },
        colaboradoresArray:{
            required
        },
    },

    data(){
        return {
            mdiCellphoneText:mdiCellphoneText,
            mdiCheck:mdiCheck,
            url: '/controle/jornada/relatorios/registro/jornada/',
            labelsSelectEmpresa:[
                {indexDFH: 'E', description: 'Empresas*'},
            ],
            labelsSelectColaborador:[
                {indexDFH: 'E', description: 'Colaboradores*'},
            ],
            optionsSelectEmpresa: [],
            optionsSelectColaborador: [],
            statusbar: 'info',
            empresasArray:[],
            colaboradoresArray:[],
            colaboradores:'C',
            dadosRegistroJornada:[],
            loadingPanel: false,
            loadingExportar: [false, false, false],
            loading: false,
            selectedEmpresa: [],
        }
    },
    methods: {
        ...mapGetters(['getMaster']),
        
        limpaDados(){
            this.dadosRegistroJornada = []
        },

        SelectEmpresas(event){
            this.limpaDados()
            this.empresasArray = event;
            this.$refs.C.clearAll()
            this.dados_filtros();
        },
        SelectColaborador(event){
            this.limpaDados()
            this.colaboradoresArray = event;
        },
        changeIntervalo(event){
            this.limpaDados()
            this.dataValue = event;
        },
        async dados_filtros(){
            this.loading = true
            var dados = await new FiltrosService()
                .dados_filtros(this.empresasArray, [this.colaboradores])
            this.optionsSelectColaborador = dados[this.colaboradores];
            this.loading = false
        },
        async gerarRelatorio(){
            this.loadingPanel = true
            this.limpaDados()
            try {
                if(this.empresasArray.length > 0 && this.colaboradoresArray.length > 0){
                    this.dadosRegistroJornada = [];
                    let body = {
                        'clientes': this.empresasArray, 
                        'motoristas': this.colaboradoresArray, 
                        'dataBusca':this.dataValue, 
                    }
                    var dados = await new HttpRequest().Post(this.url+'gerar/registro', body)
                    if(dados.status && dados.data && dados.data.registro) {
                        this.dadosRegistroJornada = dados.data.registro
                        this.ordenaRegistrosPorData()
                        if(!this.temDados){
                            this.statusbar = 'error'
                        }
                    } else {
                        this.statusbar = 'error'
                    }
                }
            } catch {
                conectionError()
            } finally{
                this.loadingPanel = false
            }
        },

        ordenaRegistrosPorData() {
            for (const nomeColaborador in this.dadosRegistroJornada)
                this.dadosRegistroJornada[nomeColaborador] = _.orderBy(this.dadosRegistroJornada[nomeColaborador], ['inicio'])
        },

        async exportarRelatorio(tipo){
            var root =  process.env.VUE_APP_ROOT;
            this.setLoading(tipo)
            if(this.temDados){
                let body = {
                    'arrayDados':JSON.stringify(this.dadosRegistroJornada), 
                    'tipo':tipo, 
                    'data':this.dataValue, 
                }
                var data = await new HttpRequest().Post(this.url+'exportar/registro', body)
                if(data.status && data.data) {
                    window.open(root+'/'+data.data.local);
                }
            }
            this.setLoading()
        },

        setLoading(tipo){
            this.loadingExportar = [tipo=='pdf', tipo=='xls', tipo=='csv']
        },
    },

    mounted() {
        this.optionsSelectEmpresa = new EmpresasService().Get()
        if(!this.getMaster()){
            this.selectedEmpresa = this.optionsSelectEmpresa
        }
    },

    computed: {
        temDados() {
            return this.dadosRegistroJornada 
                && Object.values(this.dadosRegistroJornada).length
        }
    }
})
</script>

<style lang="scss">
    #registroJornada{
        .divHrDeBaixo{
            hr{
                margin-bottom: 0rem;
            }
        }
        .divButtonsBasic{
            padding-right: 0px;
        }
        .badgeTd {
            padding: 0px !important;
        }

        .dadosTabela{
            td{
                border: 1px solid #d5d5d5;
                background: #f5f5f5;
            }
        }

        .condutor{
            background: #fff !important;
            text-align: left;
            td{
                padding-left: 0px;
                padding-top: 15px;
                padding-bottom: 1px;
                text-align: left;
                span{
                    padding-left: 5px;
                    padding-top: 7px;
                    display: inline-block;
                    background: #777777;
                    color: #fff;
                    height: 31px;
                    min-width: 300px;
                    text-align: center !important;
                }
            }
        }
    }
</style>