<template> 
    <panelEagle id="CheckpointPontualidade" :loading="loadingPanel">
        <div class="col-sm-12 row nopadding">
            <div class="col-sm-6">
                <tituloPage 
                    :icon="mdiAlarmCheck" 
                    titulo="Pontualidade"
                />
            </div>
            <div class="col-sm-6 divDontPrint">
                <basicButtonsRelatoriosEIG
                    :disabled="$v.$invalid"
                    :loading="loadingBtn"
                    :disabledDropdown="Object.keys(dadosRelatorio).length == 0"
                    @exportarRelatorio="exportarRelatorio"
                    @gerarRelatorio="gerarRelatorio"
                />
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
            <slideUpAndDown>
                <div class="col-sm-12 row nopadding">
                    <div class="col-sm-4 nopadding">
                        <inputRangeWithLimit
                            :valueData="valueData"
                            ref="refData"
                            name="botaoData"
                            titulo="Intervalo*"
                            :value="valueData" 
                            @changeInput="v => valueData = v"
                        />
                    </div>
                    <div class="col-sm-4 nopadding">
                        <selectAll
                            nameForRadio="OOOO ANA JULIA"
                            :labels="[{
                                indexDFH: 'EM', 
                                description: 'Empresas*'
                            }]"
                            @changeSelect="changeEmpresas"
                            :optionsArray="optionsSelectEmpresa"
                            :isMultiple="true" 
                            :hasSelectAll="true"
                        />
                    </div>
                    <div class="col-sm-4 nopadding">
                        <selectAll
                            :disabled="disabledVei"
                            ref="refColab"
                            nameForRadio="Oouououou"
                            firstSelected="CO" 
                            :labels="[
                                {
                                    indexDFH: 'CO', 
                                    description: 'Colaboradores*'
                                },
                                {
                                    indexDFH: 'DE', 
                                    description: 'Departamentos*'
                                }
                            ]"
                            :isMultiple="true" 
                            :hasSelectAll="true"
                            :loading="loadingColab"
                            @changeSelect="v => valueColabEDep = v"
                            @changeCheck="v => checkSelecionado = v"
                            :optionsArray="retornaOptColabEDep" 
                        />
                    </div>
                </div>
            </slideUpAndDown>
            <div class="col-sm-12 nopadding mt-4">
                <tableRelatorio>
                    <slot slot="thead">
                        <template v-if="Object.keys(dadosRelatorio).length !== 0"> 
                            <tr class="backColorTdPDF">
                                <td colspan="7" style="padding:0px;">
                                    <span class="newbadge">
                                        {{valueData}}
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="7">
                                    <div class="row col-sm-12 nopadding mt-5">
                                        <div class="col-sm-4 row">
                                            <div class="col-sm-12 nopadding text-center">
                                                <h4>
                                                    Pontualidade
                                                </h4>
                                            </div>
                                            <div class="col-sm-12 nopadding">
                                                <chartsPizza
                                                    :widthLegenda="103"
                                                    type="donut"
                                                    @changeImagem="(v) => imgBase64 = v"
                                                    :label="['Não Pontual', 'Pontual']"
                                                    :value="pontualidade"
                                                    :colors="['#f0abab', '#bae9a8']"
                                                    :widthGrafico='650'
                                                    larguraGrafico="50%"
                                                />
                                            </div>
                                        </div>
                                        <div class="
                                            col-sm-4
                                            d-flex flex-column
                                        ">
                                            <h5 class="p-2">
                                                Colaboradores com mais pontualidade
                                            </h5>
                                            <div class="
                                                div-lista
                                                div-colb-pontual
                                                p-2
                                                nopadding
                                            ">
                                                <ul>
                                                    <template 
                                                        v-for="(
                                                            dados
                                                        ) in colabMaisPontual
                                                    ">
                                                        <template 
                                                            v-for="(val, key) in dados"
                                                        >
                                                            <li 
                                                            :key="key" 
                                                            class="
                                                                d-flex 
                                                                justify-content-between
                                                            "
                                                            >
                                                                <span>
                                                                    {{key}}
                                                                </span>
                                                                <span>
                                                                    {{val}}%
                                                                </span>
                                                            </li>
                                                        </template>
                                                    </template>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="
                                            col-sm-4
                                            d-flex flex-column
                                        ">
                                            <h5 class="p-2">
                                                Colaboradores com menos pontualidade
                                            </h5>
                                            <div class="
                                                div-lista
                                                div-colb-atrasado
                                                p-2
                                                nopadding
                                            ">
                                                <ul>
                                                    <template 
                                                        v-for="(
                                                            dados
                                                        ) in colabMenosPontual
                                                    ">
                                                        <template 
                                                            v-for="(val, key) in dados"
                                                        >
                                                            <li 
                                                            :key="key" 
                                                            class="
                                                                d-flex 
                                                                justify-content-between
                                                            "
                                                            >
                                                                <span>
                                                                    {{key}}
                                                                </span>
                                                                <span>
                                                                    {{val}}%
                                                                </span>
                                                            </li>
                                                        </template>
                                                    </template>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <tr class="cabecalho">
                            <th>Data</th>
                            <th>Ponto previsto</th>
                            <th>Ponto realizado</th>
                            <th>Tempo de atraso</th>
                            <th>Cargo</th>
                            <th>Departamento</th>
                            <th>% de pontualidade</th>
                        </tr>
                    </slot>
                    <slot slot="tbody">
                        <br>
                        <statusInformation 
                            v-if="Object.keys(dadosRelatorio).length == 0"
                            typeBar="tr" 
                            colspanForTd="21"
                            :statusBar="statusbar">
                        </statusInformation>
                        <template v-else>
                            <template v-for="(colaboradores, c) in dadosRelatorio">
                                <template v-if="colaboradores.length">
                                    <tr :key="c+'badge'"
                                        class="backColorTdPDF">
                                        <td colspan="21" style="padding:0px;">
                                            <span class="newbadge">
                                            {{c}}
                                            </span>
                                        </td>
                                    </tr>
                                    <template v-for="(dados, index) in colaboradores">
                                        <tr :key="`${index}_${c}`">
                                            <td>{{dados.dia}}</td>
                                            <td>{{dados.previsto}}</td>
                                            <td>{{dados.realizado}}</td>
                                            <td>{{dados.tempoAtrasado}}</td>
                                            <td>{{dados.cargo}}</td>
                                            <td>{{dados.departamento}}</td>
                                            <td>{{dados.pontualidade}}%</td>
                                        </tr>
                                    </template>
                                    <tr :key="c+'_totalizadores'"
                                        class="totalizador">
                                        <td colspan="7" class="text-left nopadding">
                                            <span class="rodaPe text-left">
                                                Total de pontualidade: {{
                                                    colaboradores.at(-1).pontualidade
                                                }}%
                                            </span>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </template>
                    </slot>
                </tableRelatorio>
            </div>
    </panelEagle>
</template>
<script>
import Vue from "vue"
import { mdiAlarmCheck } from "@mdi/js"
import { required, minLength } from 'vuelidate/lib/validators'
import { EmpresasService } from "@/Services/auth/Empresas.service"
import { FiltrosService } from "@/Services/filtros/filtros.Service"
import { HttpRequest } from "@/Services/auth/HttpRequest.Service"
import { conectionError } from "@/Services/Helpers/swellHeper"
export default Vue.extend({
    name:"pontualidadeCheckpoint",
    components:{
        tituloPage: require("@/components/Atom/Header/Titulo").default,
        selectAll: require("@/components/Atom/Select/SelectAll").default,
        panelEagle: require("@/components/Atom/Panel/PanelEagle").default,
        chartsPizza: require("@/components/Atom/Grafico/ChartsPizza").default,
        tableRelatorio:require( '@/components/Atom/Table/TableRelatorio').default,
        slideUpAndDown:require( "@/components/Atom/SlideUpAndDown/SlideUpAndDown").default,
        inputRangeWithLimit:require( "@/components/Atom/Datas/InputRangeWithLimit").default,
        statusInformation:require( '@/components/Atom/StatusInformation/StatusInformation').default,
        basicButtonsRelatoriosEIG: require("@/components/Atom/Buttons/BasicButtonsRelatoriosEIG").default,
    },
    validations:{
        valueData:{ required },
        valueEmpresas:{
            required, 
            minLength:minLength(1)
        },
        valueColabEDep:{
            required, 
            minLength:minLength(1) 
        }
    },
    computed:{
        disabledVei(){
            return !this.valueEmpresas.length || this.loadingColab
        },
        retornaOptColabEDep(){
            if(this.checkSelecionado === "CO"){
                return this.optColaboradores
            }
            return this.optDepartamento
        }
    },
    data() {
        return {
            urlBase:"/checkpoint/relatorios/pontualidade",
            optionsSelectEmpresa: new EmpresasService().Get(),
            mdiAlarmCheck:mdiAlarmCheck,
            loadingPanel:false,
            loadingColab:false,
            checkSelecionado:"CO",
            statusbar: "info",
            imgBase64:"",

            optColaboradores:[],
            optDepartamento:[],
            
            valueData:"",
            valueEmpresas:[],
            valueColabEDep:[],

            dadosRelatorio:{},
            colabMaisPontual:[],
            colabMenosPontual:[],
            pontualidade:[],
            loadingBtn:[false, false, false]
        }
    },

    watch:{
        valueColabEDep(){
            this.limpaRel()
        }
    },

    methods: {
        limpaRel(){
            this.pontualidade = [0, 0]
            this.dadosRelatorio = []
            this.colabMaisPontual = []
            this.colabMenosPontual = []
        },

        changeEmpresas(value){
            this.valueEmpresas = value
            this.loadingColab = true
            this.$refs.refColab.clearAll()
            this.limpaRel()
            new FiltrosService().dados_filtros(value, ["C", "D"])
                .then(({ C, D }) => {
                    this.optColaboradores = C
                    this.optDepartamento = D
                }).catch(() => {
                    conectionError()
                }).finally(() => this.loadingColab = false)
        },

        gerarRelatorio(){
            this.loadingPanel = true
            let obj = {
                data:this.valueData,
                empresa:this.valueEmpresas,
                colabEDep:this.valueColabEDep,
                tipoColab:this.checkSelecionado,
            }
            this.limpaRel()
            new HttpRequest().Post(
                `${this.urlBase}/gerar/relatorio`, obj
            ).then(({ data }) => {
                if(!data.relatorio.length) this.statusbar = "error"
                this.pontualidade = [
                    this.retornaNumero(data.pontualidadeTotal, 100),
                    this.retornaNumero(data.pontualidadeTotal),
                ] 
                this.dadosRelatorio = data.relatorio
                this.colabMaisPontual = data.maisPontual
                this.colabMenosPontual = data.menosPontual
            }).catch(() => {
                conectionError()
            }).finally(() => this.loadingPanel = false)
        },

        retornaNumero(str, menos = 0){
            let n = Number(str)
            let r = (menos - n).toFixed(2)
            return Math.abs(r)
        },

        exportarRelatorio(tipo){
            let loading = ["pdf", "xls", "csv"]
            this.loadingBtn = [
                loading[0] === tipo,
                loading[1] === tipo,
                loading[2] === tipo,
            ]
            let obj = {
                tipo:tipo,
                img:this.imgBase64,
                data:this.valueData,
                pontualidade:this.pontualidade,
                dadosRelatorio:this.dadosRelatorio,
                colabMaisPontual:this.colabMaisPontual,
                colabMenosPontual:this.colabMenosPontual,
            }
            let root =  process.env.VUE_APP_ROOT
            new HttpRequest().Post(
                `${this.urlBase}/exporta/relatorio`, obj
            ).then(({ data }) => {
                window.open(root+'/'+data)
            }).catch(() => {
                conectionError()
            }).finally(() => this.loadingBtn = [
                false, false, false
            ])
        }
    },
})
</script>
<style lang="scss">
.div-colb-pontual{
    background-color:#bae9a8;
}
.div-colb-atrasado{
    background-color:#f0abab;
}
.div-lista{
    ul{
        padding: 5px;
        list-style: none;
        border-collapse: collapse;
        li{
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.123) !important;
            padding: 5px;
        }
    }
}
</style>
