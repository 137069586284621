<template>
    <b-navbar :hidden="canLoad" toggleable="lg" type="dark" variant="dark" fixed="bottom">
      <div class="divButtonsRS col-sm-4 ">
        <div class="col-sm-1 divDivButtonsRS">
            <a  
              target="_blank"
              class="buttonIconRedesSociais" 
              href="https://www.instagram.com/eagletrack_br/">
              <baseIcon size='20' class="" :icon="mdiInstagram"/>
            </a>
          </div>
        <div class="col-sm-1 divDivButtonsRS">
          <a  
            target="_blank"
            class="buttonIconRedesSociais" 
            href="https://www.facebook.com/EagleTrackBR">
            <baseIcon size='20' class="" :icon="mdiFacebook"/>
          </a>
        </div>
        <div class="col-sm-1 divDivButtonsRS">
          <a  
            target="_blank"
            class="buttonIconRedesSociais" 
            href="https://eagletrackme.wordpress.com">
            <baseIcon size='20' class="" :icon="mdiWordpress"/>
          </a>
        </div>
      </div>
    </b-navbar>
</template>

<script>
import {mdiWordpress, mdiFacebook, mdiInstagram } from '@mdi/js'
export default {
  name: 'Footer',
  components:{
    baseIcon:require('@/components/Atom/Icon/BaseIcon.vue').default,
  },
  computed: {
    canLoad(vue){
      var canLoad = this.$route.meta.canLoad;
      if(canLoad != undefined){
          return false;
      }
      return true;
    }
  },
  data() {
    return {
      mdiInstagram:mdiInstagram,
      mdiFacebook:mdiFacebook,
      mdiWordpress:mdiWordpress,    
    }
  },
};
</script>
<style lang="scss">
 .divButtonsRS{
    padding-right: 0px !important;
    padding-left: 0px !important;
    float: left;
    
    //display: contents !important;
  }

  .divButtonsRS>.divDivButtonsRS{
    padding: 0px;
    float: left;
    @media (max-width: 575px ) {
      width:10% !important;
    } 
  }

  .divButtonsRS>.divDivButtonsRS>.buttonIconRedesSociais{
    color: #fff;
    text-decoration: none;
  }


  .divButtonsRS>.divDivButtonsRS>.buttonIconRedesSociais>.spanIconRedes::before{
    margin-left: 0px;
  }
</style>