<template>
    <panelEagle id="detalhesRota" :loading="loadingPanel">
        <div class="col-sm-12 nopadding divDontPrint">
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiMapMarkerPath" titulo="Detalhes Rota" />
                </div>
                <div class="col-sm-6">
                    <basicButtonsRelatoriosEIG :disabledDropdown="dadosDetalhesRota.length <= 0" :disabled="$v.$invalid" :loading="loadingButton"
                        @exportarRelatorio="exportarRelatorio" @gerarRelatorio="gerarRelatorio" exportaPersonalizado>
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>

            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12">
                        <hr />
                    </div>
                    <div class="col-sm-12 row nopadding">
                        <div class="col-sm-3 nopadding">
                            <inputRangeWithLimit opens="rigth" @changeInput="changeData" id="dataDetalhesRota"
                                name="dataDetalhesRota">
                            </inputRangeWithLimit>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll @changeSelect="SelectEmpresas" :isMultiple="false" nameForRadio="radiosEmpresas"
                                :labels="labelsSelectEmpresa" :optionsArray="optionsSelectEmpresa" firstSelected="E"
                                :selected="selectedEmpresa" :hasSelectAll="false">
                            </selectAll>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll ref="selectVCR" :disabled="$v.empresasArray.$invalid" :loading="loadingVel"
                                @selectCriado="selectCriado" @changeCheck="changeCheck" @changeSelect="SelectVeiculos"
                                :isMultiple="true" nameForRadio="radiosVeiculos" :labels="labelsSelectVeiculos"
                                :optionsArray="optionsVeiculosRotas" firstSelected="V"
                                :hasSelectAll="optionsVeiculosRotas.length > 0">
                            </selectAll>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll @changeSelect="changeColaborador" :isMultiple="false"
                                nameForRadio="radiosColaboradores" :labels="labelsSelectColaboradores"
                                :optionsArray="arrayColaboradores" firstSelected="CLB" :selected="selectedColaborador"
                                :hasSelectAll="false">
                            </selectAll>
                        </div>

                        <div class="col-sm-3 mt-3">
                            <subtitle title="Legenda" :cssPersonalizado="true" :items="arrayLegenda">
                                <span class="col-sm-12">
                                    P - Previsto | R - Realizado
                                </span>
                            </subtitle>
                        </div>
                        <div class="col-sm-3 nopadding mt-3">
                            <selectAll nameForRadio="radioPontos" ref="selectPontos" :disabled="$v.empresasArray.$invalid"
                                :labels="labelsSelectPontos" :loading="loadingVel" :isMultiple="true"
                                :optionsArray="optionsPontos" :hasSelectAll="optionsPontos.length > 0"
                                @changeSelect="changePontos" :selected="selectedPontos">
                            </selectAll>
                        </div>
                        <div class="col-sm-3 nopadding mt-3">
                            <inputSeartch 
                                name="buscaDocumento" 
                                label="Buscar por Documento"
                                @changeInput="changeDocumento"
                                @clickButtonSearch="buscarDocumento" 
                            />
                        </div>

                        <div class="col-sm-3 nopadding mt-3">
                            <buttonsFilters @buttonsFiltersChange="clickBotao" :arrayButtons="arrayButtons" label="Filtros*"
                                :onlyOneSelected="false">
                            </buttonsFilters>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
        </div>

        <div class="col-sm-12">
            <hr />
        </div>

        <tableRelatorio htmlRel="" id="tabelaDetalhesRota">
            <slot slot="thead">
                <tr>
                    <th></th>
                    <th class="nowrap">Rota</th>
                    <th class="nowrap">Veículo</th>
                    <th class="nowrap">Prefixo</th>
                    <th class="nowrap">Colaborador</th>
                    <th class="nowrap">Distância (PxR)</th>
                    <th class="nowrap">Tempo (PxR)</th>
                    <th class="nowrap">Qtd. (PxR)</th>
                    <th class="nowrap">Peso | Cubagem</th>
                    <th class="nowrap">Temp. desvio</th>
                    <th class="nowrap">Km desvio</th>
                    <!-- <th class="nowrap">Mapa</th> -->
                </tr>
            </slot>
            <slot slot="tbody">
                <statusInformation 
                    v-if="dadosDetalhesRota.length == 0" 
                    :statusBar="statusbar" 
                />

                <template v-else v-for="(rotas, data) in dadosDetalhesRota">
                    <tr v-if="true" class="backColorTdPDF" :key="'badge' + data">
                        <td class="nopadding" colspan="11">
                            <span class="newbadge">{{ data }}</span>
                        </td>
                    </tr>

                    <template v-for="rota in rotas">
                        <tr v-if="true" :key="'rota' + rota.rocodigo" :class="rota.rostatus">
                            <td class="tamanhoIcone" @click="rota.clicado = !rota.clicado"
                                v-b-toggle="rota.rocodigo + '' + rota.ropontosaida">
                                <b-icon :icon="rota.clicado ? 'chevron-down' : 'chevron-right'" font-scale="1.1" />
                            </td>

                            <td class="nowrap">{{ rota.rocodigo }}</td>

                            <td class="nowrap">{{ rota.roplaca }}</td>

                            <td class="nowrap">{{ rota.veprefixo }}</td>

                            <td class="nowrap">
                                {{ rota.romotorista ? rota.romotorista : "Sem Motorista" }}
                            </td>

                            <td class="nowrap">{{ formatarDistancia(rota) }} </td>

                            <td class="nowrap">
                                {{ rota.rotempo + " | " + rota.temporealizado }}
                            </td>

                            <td class="nowrap">
                                {{ rota.realizados + " | " + (rota.totaldeitens - rota.realizados) }}
                            </td>

                            <td class="nowrap">{{ formatarPesoCubagem(rota) }}</td>

                            <td class="nowrap">{{ rota.tempoDesvio == null ? ('00:00:00') : (rota.tempoDesvio) }}</td>

                            <td class="nowrap">{{ rota.kmDeDesvio }} Km</td>

                            <td v-show="rota.documentos" @click="rota.documentos ? openModalDIR('R', rota.rocodigo) : ''"
                                class="nowrap">
                                <baseIcon class="cursor-pointer nopadding" :icon="mdiEyePlusOutline" size="20" />
                            </td>

                            <!-- <td class="nowrap d-flex justify-content-center align-content-center" @click="abreModalMapa">
                                <baseIcon class="cursor-pointer nopadding" :icon="mdiMapPlus" size="20" />
                            </td> -->
                        </tr>
                        <template>
                            <tr :key="'rota' + rota.rocodigo + 'adsasad'">
                                <td colspan="12" class="p-0 pb-3">
                                    <b-collapse class="w100" :id="rota.rocodigo + '' + rota.ropontosaida">
                                        <div class="d-flex justify-content-between styleTextBold">
                                            <div :key="'incio' + rota.rocodigo"
                                                class="w100 divInicioFim d-flex justify-content-start">
                                                <div class="d-flex justify-content-start">
                                                    <div class="mr-3">
                                                        Origem: {{ rota.inicio }}
                                                    </div>
                                                    <div>
                                                        {{ rota.datainicio }}
                                                    </div>
                                                </div>
                                            </div>

                                            <div :key="'fim' + rota.rocodigo"
                                                class="w100 borderDiv divInicioFim d-flex justify-content-end">
                                                <div class="d-flex justify-content-start">
                                                    <div class="mr-3">Destino: {{ rota.fim }}</div>
                                                    <div>
                                                        {{ rota.datafim }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div :key="'previsto' + rota.rocodigo" class="w100" style="display: inline-flex">
                                            <div class="prw">
                                                <div class="w100" style="display: inline-flex">
                                                    <div class="prw pdRow divSubTitle" style="width: 24.8% !important">
                                                        Previsto</div>
                                                    <div class="prw pdRow divSubTitle" style="width: 100% !important">
                                                        Realizado</div>
                                                </div>
                                                <div class="w100" style="display: inline-flex">
                                                    <div style="width: 30px !important" class="pdRow divSubTitle"></div>

                                                    <div class="qw pdRow divSubTitle">Ordem</div>
                                                    <div class="pw pdRow divSubTitle">Ponto</div>

                                                    <div class="sw pdRow divSubTitle">Situação</div>

                                                    <div class="qw pdRow divSubTitle">Ordem</div>
                                                    <div class="pw pdRow divSubTitle">Ponto</div>

                                                    <div class="ew pdRow divSubTitle">Estimativa Chegada (PxR)</div>
                                                    <div class="ew pdRow divSubTitle">Tempo Parado</div>
                                                    <div class="tpw pdRow divSubTitle">Tipo Parada</div>
                                                    <div class="dow pdRow divSubTitle">Documento</div>
                                                    <div class="sdw pdRow divSubTitle">Detalhes</div>
                                                    <div v-if="rota.clcoletadriver" class="sdw pdRow divSubTitle">
                                                        Recibo
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <detalhesRotaLine :key="item.ircodigo" v-for="item in rota.itens"
                                            :cor="rota.rostatus" :arrayDados="item" :coleta="rota.clcoletadriver"
                                            @changeDocumentos="(v) => openModalDIR('IR', v, rota.clcoletadriver)"
                                            @changeTicket="(v) => downloadColetas(v)">
                                        </detalhesRotaLine>
                                    </b-collapse>
                                </td>
                            </tr>
                        </template>
                    </template>
                </template>
            </slot>
        </tableRelatorio>

        <modalDetalhesItemRota :dados="detalhesItemRota" :statusItemSelecionado="statusItemSelecionado"
            :loading="carregaModalDocumentos">
        </modalDetalhesItemRota>

        <!-- <modalDetalhesMapa :dados="detalhesItemRota" :statusMapaSelecionado="statusMapaSelecionado"
            :loading="carregaModalMapa">
        </modalDetalhesMapa> -->

    </panelEagle>
</template>

<script lang="js">
import { DateTime } from "luxon"
import { mdiMapMarkerPath, mdiEyePlusOutline, mdiMapPlus } from '@mdi/js'
import Vue from 'vue'
import { EmpresasService } from '../../../Services/auth/Empresas.service'
import { HttpRequest } from '../../../Services/auth/HttpRequest.Service'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { conectionError } from '@/Services/Helpers/swellHeper';

export default Vue.extend({
    name: "DetalhesRota",
    validations: {
        veiculosArray: { required },
        empresasArray: { required },
        filtroSelecionado: { required },
    },

    components: {
        'tituloPage': require('@/components/Atom/Header/Titulo').default,
        'subtitle': require('@/components/Atom/Subtitle/Subtitle').default,
        'selectAll': require('@/components/Atom/Select/SelectAll').default,
        'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
        'tableRelatorio': require('@/components/Atom/Table/TableRelatorio').default,
        'buttonsFilters': require('@/components/Atom/Buttons/ButtonsFilters').default,
        'InputRangeWithLimit': require(
            '@/components/Atom/Datas/InputRangeWithLimit').default,
        'slideUpAndDown': require(
            '@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
        'statusInformation': require(
            '@/components/Atom/StatusInformation/StatusInformation').default,
        'basicButtonsRelatoriosEIG': require(
            '@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
        'detalhesRotaLine': require(
            '@/components/Atom/SpecificComponents/DetalhesRota/DetalhesRotaLine').default,
        'inputSeartch': require('@/components/Atom/Inputs/InputSearch').default,
        'baseIcon': require('@/components/Atom/Icon/BaseIcon').default,
        modalDetalhesItemRota: require(
            '@/components/Atom/SpecificComponents/DetalhesRota/ModalDetalhesItemRota')
            .default,
        // modalDetalhesMapa: require(
        //     '@/components/Atom/SpecificComponents/DetalhesRota/ModalDetalhesMapa')
        //     .default,
    },

    data() {
        return {
            mdiMapMarkerPath: mdiMapMarkerPath,
            mdiEyePlusOutline: mdiEyePlusOutline,
            mdiMapPlus: mdiMapPlus,
            url: '/roteirizador/relatorios/detalhes/rota/',
            loadingVel: false,
            loadingPanel: false,
            loadingButton: [false, false, false, false],
            labelsSelectEmpresa: [
                { indexDFH: 'E', description: 'Empresa*' },
            ],
            labelsSelectColaboradores: [
                { indexDFH: 'CLB', description: 'Colaboradores' },
            ],
            labelsSelectPontos: [
                { indexDFH: 'P', description: 'Pontos' },
            ],
            optionsSelectEmpresa: new EmpresasService().Get(),
            labelsSelectVeiculos: [
                { indexDFH: 'V', description: 'Veiculos*' },
                { indexDFH: 'RT', description: 'Códigos das rotas*' },
                { indexDFH: 'RMT', description: 'Marcador*' },
            ],
            statusbar: 'info',
            optionsSelectVeiculos: [],
            optionsVeiculosRotas: [],
            optionsPontos: [],
            pontosSelected: [],
            empresasArray: [],
            veiculosArray: [],
            veiculosSelect: [],
            filtroSelecionado: '',
            dadosDetalhesRota: [],
            dataSelecionada: '',
            multiplo: false,
            checkSelecionado: 'V',
            itensRealizados: 0,
            totalItens: 0,
            arrayButtons: [
                {
                    'value': 'F', 'title': 'Finalizadas', 'width': '48.3%',
                    'text': 'Finalizadas', 'isSelected': true
                },
                {
                    'value': 'P', 'title': 'Pendentes', 'width': '48.3%',
                    'text': 'Pendentes', 'isSelected': false
                },
                {
                    'value': 'C', 'title': 'Canceladas', 'width': '48.3%',
                    'text': 'Canceladas', 'isSelected': false
                },
                {
                    'value': 'I', 'title': 'Iniciadas', 'width': '48.3%',
                    'text': 'Iniciadas', 'isSelected': false
                },
            ],
            arrayLegenda: [
                {
                    'description': 'Rota pendente',
                    'spanColor': 'yellow', 'colWidth': '3'
                },
                {
                    'description': 'Rota finalizada',
                    'spanColor': 'green', 'colWidth': '3'
                },
                {
                    'description': 'Rota iniciada',
                    'spanColor': 'blue', 'colWidth': '3'
                },
                {
                    'description': 'Rota cancelada',
                    'spanColor': 'red', 'colWidth': '3'
                },
            ],
            nfeSelecionada: '',
            detalhesItemRota: [],
            detalhesMapa: [],
            statusItemSelecionado: '',
            statusMapaSelecionado: '',
            carregaModalDocumentos: false,
            carregaModalMapa: false,
            selectedEmpresa: [],
            selectedColaborador: [],
            arrayColaboradores: [],
            colaboradorSelecionado: [],
            documentoSelecionado: '',
            selectedPontos: [],
        }
    },

    methods: {
        ...mapGetters(['getMaster']),

        /**
         * Função para formatar números float
         * @param {number|string} number
         * @return {number}
         */
        fnf(number) {
            number = Number(number)
            if (!number || typeof number != 'number') return 0
            return parseFloat(number).toFixed(2)
        },

        formatarPesoCubagem(rota) {
            return `${this.fnf(rota.ropeso)} Kg | ${this.fnf(rota.rocubagem)} m³`
        },

        formatarDistancia(rota) {
            let total = this.calculaHodometro(rota.rohodometroinicio, rota.rohodometrofim)
            return `${rota.rokm} Km | ${this.fnf(total)} Km`
        },

        SelectEmpresas(event) {
            this.empresasArray = event
            this.dadosDetalhesRota = []
            this.$refs.selectPontos.clearAll()
            this.dados_filtros()
            this.getPontos()
            this.selectedPontos = [];
        },

        SelectVeiculos(event) {
            this.veiculosArray = event;
            this.dadosDetalhesRota = [];
            this.getPontos();
            this.selectedPontos = [];
        },

        /**
         * Guarda o código do colaborador em this.colaboradorSelecionado.
         * @param {colaborador} colaborador Código do colaborador
         * @author Yuri 🇧🇷
         */
        changeColaborador(colaborador) {
            this.colaboradorSelecionado = colaborador;
        },

        changePontos(pontos) {
            this.pontosSelected = pontos
            this.dadosDetalhesRota = []
        },

        selectCriado(select) {
            this.veiculosSelect = select
        },

        clickBotao(event) {
            this.filtroSelecionado = event
            this.dadosDetalhesRota = []
        },

        changeData(event) {
            let data = event.split(' - ')
            let format = 'dd/MM/yyyy'
            let startDate = DateTime.fromFormat(data[0], format)
            let endDate = DateTime.fromFormat(data[1], format)
            let dif = endDate.diff(startDate, 'days')
            this.dadosDetalhesRota = []
            if (dif.values.days <= 30) {
                this.dataSelecionada = event;
            } else {
                let dataIni = startDate.toFormat(format)
                let dataFim = DateTime.fromFormat(data[0], format)
                    .plus({ days: 30 }).toFormat(format)
                this.dataSelecionada = dataIni + " - " + dataFim
            }
            this.dados_filtros()
        },

        changeCheck(check, needRecharge) {
            this.checkSelecionado = check
            this.multiplo = this.checkSelecionado == 'RT'
        },

        changeDocumento(documento) {
            this.documentoSelecionado = documento;
        },

        buscarDocumento() {
            if(this.documentoSelecionado.length <= 0){
                this.toastShow('Informe um documento para realizar a consulta!', 'warning', 'Atenção!');
                this.dadosDetalhesRota = [];
            }else{
                this.gerarRelatorio();
            }
        },

        calculaHodometro(inicial, final) {
            var resultado = 0;
            inicial = parseInt(inicial)
            final = parseInt(final)
            if (inicial && final && inicial < final) {
                resultado = (parseInt(final) / 1000) - (parseInt(inicial) / 1000);
            }

            return resultado;
        },

        /**
         * Realiza a consulta dos inputs para gerar o relatório,
         * @author Yuri 🇧🇷
         */
        async dados_filtros() {
            this.loadingVel = true
            let body = {
                'empresa': this.empresasArray,
                'data': this.dataSelecionada
            }
            this.optionsSelectVeiculos = []
            this.veiculosArray = []
            this.veiculosSelect = []
            this.$refs.selectVCR.clearAll()
            let dados = await new HttpRequest().Post(this.url + 'getVeiculos', body)
            if (dados.status) {
                this.arrayColaboradores = dados.data.CLB;
                if (dados.data && dados.data[this.checkSelecionado]) {
                    this.optionsSelectVeiculos = dados.data
                    this.optionsVeiculosRotas = this.optionsSelectVeiculos[this.checkSelecionado]
                }
            }
            this.loadingVel = false
        },

        getPontos() {
            let body = { 
                'empresa': this.empresasArray,
                'intervalo': this.dataSelecionada,
                'filtro': this.veiculosArray,
                'tipoFiltro': this.filtroSelecionado,
                'busca': this.checkSelecionado,
            }
            this.pontosSelected = []
            new HttpRequest().Post(this.url + 'getPontos', body)
                .then((dados) => {
                    this.optionsPontos = dados.data.pontos
                })
        },

        /**
         * Realiza a consulta do relatório, salva nas variáveis e mostra na tabela.
         * @author Yuri 🇧🇷
         */
        async gerarRelatorio() {
            this.loadingPanel = true;
            this.dadosDetalhesRota = [];

            let body = {
                'clientes': this.empresasArray,
                'filtro': this.veiculosArray,
                'tipoFiltro': this.filtroSelecionado,
                'busca': this.checkSelecionado,
                'data': this.dataSelecionada,
                'nfe': this.nfeSelecionada,
                'pontos': this.pontosSelected,
                'colaborador': this.colaboradorSelecionado,
                'documento': this.documentoSelecionado
            }
            var data = await new HttpRequest().Post(this.url + 'gerar', body)
            if (data.status) {
                var dados = data.data;
                if (dados.detalhes && Object.values(dados.detalhes).length > 0) {
                    this.dadosDetalhesRota = dados.detalhes
                } else {
                    this.statusbar = 'error'
                }
            } else {
                this.statusbar = 'error'
            }
            this.loadingPanel = false
        },

        /**
         * Exporta o relatório de acordo com o selecionado.
         * @author Yuri 🇧🇷
         */
        async exportarRelatorio(tipo) {
            this.loadingButton = [tipo == 'pdf', tipo == 'xls', tipo == 'csv', tipo == 'personalizado']
            var root = process.env.VUE_APP_ROOT
            if (Object.values(this.dadosDetalhesRota).length > 0) {
                let body = {
                    'arrayDados': JSON.stringify(this.dadosDetalhesRota),
                    'intervalo': this.dataSelecionada,
                    'tipo': tipo,
                    'empresaSelecionada': this.empresasArray
                }
                var data = await new HttpRequest().Post(this.url + 'exportar', body)
                if (data.status) {
                    window.open(root + '/' + data.data.local)
                }
            }
            this.loadingButton = [false, false, false, false]
        },

        /**
        * @listen changeDocumentos - executado quando selecionado um documento do item da rota
        * @listen click - executado quando selecionado um documento da rota
        * @description Método para abrir modal de modalDetalhesItemRota
        * @param {String} status  - Tipo do documento (R para Rotas e IR para Itens Rotas)
        * @param {String} codigo  - Código da rota ou item da rota
        * @fires buscaDocumentos  - chama o método para buscar os documentos
        * @author Vitor Hugo 🐨
        **/
        openModalDIR(status, codigo, coleta = false) {
            this.detalhesItemRota = []
            this.$bvModal.show('modalDetalhesItemRota')
            this.buscaDocumentos(status, codigo, coleta)
        },

        async downloadColetas(item) {
            this.loadingPanel = true
            try {
                var data = await new HttpRequest()
                    .Post(this.url + 'coletas/download', { codigo: item.ircodigo })
                if (data.status) {
                    let newWindow = window.open()
                    newWindow.document.write(data.data.local)
                }
            } catch (e) {
                conectionError()
            } finally {
                this.loadingPanel = false
            }
        },

        /**
        * @listen openModalDIR - executado quando selecionado um documento da rota
        * @description Método para buscar os documentos das rotas ou itens rotas
        * @param {String} status - Tipo do documento (R para Rotas e IR para Itens Rotas)
        * @param {String} codigo - Código da rota ou item da rota
        * @return {Array} detalhesItemRota - Com todos os documentos
        * @author Vitor Hugo 🐨
        **/
        buscaDocumentos(status, codigo, coleta) {
            this.carregaModalDocumentos = true
            status = coleta ? 'C' : status
            let body = { 'tipo': status, 'codigo': codigo }
            let tipoUrl = 'buscar/documentos'
            if (status == 'C') tipoUrl = 'buscar/detalhes'
            new HttpRequest().Post(this.url + tipoUrl, body)
                .then((dados) => {
                    if (dados.code == 200) {
                        this.detalhesItemRota = dados.data.dados
                        this.statusItemSelecionado = status
                        this.statusMapaSelecionado = status
                    } else {
                        this.toastShow('Erro ao carregar arquivos', 'danger', 'Erro')
                        this.$bvModal.hide('modalDetalhesItemRota')
                    }
                })
                .finally(() => {
                    this.carregaModalDocumentos = false
                })
        },

        toastShow(msg, type, titulo) {
            this.$bvToast.toast(`${msg}`, {
                autoHideDelay: 2500,
                variant: type,
                title: titulo,
            })
        },

        /**
         * Abre o modal para vizualizar o mapa
         * @author Yuri 🇧🇷
         */
        abreModalMapa() {
            this.carregaModalMapa = true;
            this.detalhesMapa = [];

            this.$bvModal.show('modalDetalhesMapa')

            setTimeout(() => {
                this.carregaModalMapa = false;
            }, 1500);
        }
    },

    mounted() {
        if (!this.getMaster()) {
            if (this.optionsSelectEmpresa && this.optionsSelectEmpresa.length) {
                this.selectedEmpresa = [this.optionsSelectEmpresa[0]]
            }
        }
    },

    watch: {
        checkSelecionado(value) {
            if (this.optionsSelectVeiculos[value]) {
                this.optionsVeiculosRotas = this.optionsSelectVeiculos[value]
            }
        }
    }
})
</script>

<style lang="scss">
#detalhesRota {
    .legenda {
        max-width: 40%;
    }

    .P {
        background-color: #faf6c5;
    }

    .F {
        background-color: #d5f3c7;
    }

    .C {
        background-color: #f5c2c2;
    }

    .I {
        background-color: #a6cfff;
    }

    .red {
        color: #e66060;
        background-color: #efefef;
    }

    .green {
        color: #93b465;
        background-color: #efefef;
    }

    .blue {
        color: #7793e2;
        background-color: #efefef;
    }

    .yellow {
        color: #f0ad4e;
        background-color: #efefef;
    }

    .borderDiv {
        border-bottom: 1px solid #e5e5e5;
    }
}

#tabelaDetalhesRota {
    .divInicioFim {
        background-color: #8e8e8e;
        color: white;
        padding: 7px 10px 2px 10px;
    }

    .origem {
        background-color: #aaa;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
    }

    .div2x2 {
        line-height: 40px;
    }

    .divSubTitle {
        background-color: #aaa;
        color: white;
    }

    .pdRow {
        padding: 6px 6px 0 6px;
        border-bottom: 1px solid #ccc;
        border-left: 1px solid #ccc;
    }

    .sdw {
        width: 5.7% !important;
        text-align: center;
    }

    .prw {
        width: 100% !important;
    }

    .ow {
        width: 7% !important;
    }

    .pw {
        width: 15% !important;
    }

    .qw {
        width: 5% !important;
    }

    .sw {
        width: 8% !important;
    }

    .ew {
        width: 15% !important;
    }

    .dow {
        width: 13% !important;
    }

    .tpw {
        width: 13% !important;
    }

    .w100 {
        width: 100% !important;
    }

    .legenda {
        max-width: 40%;
    }

    .tamanhoIcone {
        max-width: 20px;
    }

    .P {
        background-color: #faf6c5;
    }

    .F {
        background-color: #d5f3c7;
    }

    .C {
        background-color: #f5c2c2;
    }

    .I {
        background-color: #a6cfff;
    }

    .red {
        color: #e66060;
        background-color: #efefef;
    }

    .green {
        color: #93b465;
        background-color: #efefef;
    }

    .blue {
        color: #7793e2;
        background-color: #efefef;
    }

    .yellow {
        color: #f0ad4e;
        background-color: #efefef;
    }

    .borderDiv {
        border-bottom: 1px solid #e5e5e5;
    }

    .styleTextBold {
        font-weight: bold;
    }

}
</style>
