<template>
    <modalEagle 
        :id="idModal"
        title="Justificativa"
        size='sm'>
        <slot slot="modalBody">
            <b-overlay :show="loading">
                <div class="col-sm-12 row">
                    <!-- Justificativas -->
                    <selectAll 
                        nameForRadio="seletorJustificativa"
                        :isMultiple="false" 
                        :labels="labelJustificativa" 
                        :optionsArray="optJustificativas" 
                        class="nopadding"
                        firstSelected="EV" 
                        :hasSelectAll="false"
                        @changeSelect="changeJustificativa" >
                    </selectAll>
                </div>
            </b-overlay>
        </slot>
        <template>
            <simpleButton 
                :icon="mdiCloseThick" 
                @click="cancelar"
                text="Cancelar" 
                event="click" 
                type="red"/>
            <loadingButton 
                :icon="mdiCheckBold" 
                class="loadingButtonBancoHoras"
                @click="changeConfirmar"
                text="Confirmar" 
                event="click" 
                :isLoading="loading"
                :disabled="$v.$invalid"
                type="blue"/>
        </template>
    </modalEagle>
</template>

<script lang="js">

import Vue from 'vue'
import { mdiCheckBold, mdiCloseThick } from '@mdi/js'
import { required } from 'vuelidate/lib/validators'
import { FiltrosService } from '../../../Services/filtros/filtros.Service'
import { conectionError } from '@/Services/Helpers/swellHeper'

export default Vue.extend({
    name: 'ModalJustificativaAjuste',
    components: {
        simpleButton: require('@/components/Atom/Buttons/SimpleButton').default,
        modalEagle: require('@/components/Atom/Modal/ModalEagle').default,
        selectAll: require('@/components/Atom/Select/SelectAll').default,
        loadingButton: require('@/components/Atom/Buttons/LoadingButton').default,
    },

    validations: {
        justificativaSelecionada: { required },
    },

    props: {
		idModal: {
			type: [Number, String],
			required: true
		},
	},

    data() {
        return {
            url:'/controle/jornada/relatorios/folha/ponto',
            mdiCheckBold: mdiCheckBold,
            mdiCloseThick: mdiCloseThick,
            labelJustificativa: [
                { indexDFH: 'J', description: 'Justificativa' },
            ],
            justificativaSelecionada: [],
            optJustificativas: [],
            loading: false,
            empresas: [],
        }
    },

    methods:{
        
        preparaModal(empresas, options){
            this.resetarModal()
            this.empresas = empresas
            this.$bvModal.show(this.idModal)
            if (options) {
                this.optJustificativas = options
            } else if(empresas){
                this.buscarJustificativas(empresas)
            }
        },
        
        changeJustificativa(value) {
            this.justificativaSelecionada = value
        },

        changeConfirmar() {
            this.$bvModal.hide(this.idModal)
            this.$emit('confirmar', this.justificativaSelecionada)
        },
        
        cancelar(){
            this.$bvModal.hide(this.idModal)
            this.$emit('cancelar')
        },

        async buscarJustificativas(){
            this.loading = true
            await new FiltrosService().dados_filtros(this.empresas, ['JJ'])
                .then((data)=>{
                    this.optJustificativas = data?.JJ ?? []
                })
                .catch((e)=>{
                    conectionError()
                })
                .finally(()=>{
                    this.loading = false
                })
        },

        resetarModal() {
            this.optJustificativas = []
            this.justificativaSelecionada = []
        }
    },
})
</script>

<style lang="scss">
    
</style>
