/**
    Este componente é um input de cor

    -> Propriedades:
        1- label - String - Descricao do input/Legenda que fica em cima - EX: 'Logo'
        2- name - String - Name do input - EX: 'inputArchive'
        3- value - String - Cor padrão ja selecionada - EX: '#428BCA'
        4- inputClass - String- Classe extra para o input - EX: 'inputArchive'
        5- inputDisabled - String - se ele esta desabilitado - EX: 'disabled'/''
        6- id - String - Id do input - EX: 'inputArchive'

    -> Slot:
        Sem slot

    -> Eventos:
        1- changeInput - Emite quando o input é alterado - Parametros:
            1.1- inputValue - o hexa da cor
 */
<template>
    <div class="col-sm-12">
        <div class="col-sm-12 subtitle nopadding">
            {{label}}
        </div>
        <input type="color" v-bind='$attrs' v-on='$listeners' class="form-control" v-model="inputValue" :class="inputClass" :name="name" @change="changeInput($event)" :id="id">
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
    props:{
        label: {
            type: String,
            required: false,
            default: '',
        },
        name: {
            type: String,
            required: true,
            default: '',
        },
        value: {
            type: String,
            required: false,
            default: '#428BCA',
        },
        inputClass: {
            type: String,
            required: false,
            default: '',
        },
        inputDisabled: {
            type: String,
            required: false,
            default: ''
        },
        id:{
            type: String,
            required: false,
            default: ''
        }
    },
    inheritAttrs:false,
    data(){
        var inputValue: any = this.value;
        return{
            inputValue
        }
    },
    methods:{
        changeInput(event:any){
            this.$emit('changeInput', this.inputValue);
        }
    },
    mounted(){
        this.$emit('changeInput', this.inputValue);
    },
    watch: {
        value: function(){
            this.inputValue = this.value;
            this.$emit('changeInput', this.inputValue);
        }
    }
})
</script>

<style lang="scss">
    .subtitle{
        font-size: 13px;
        text-align: left;
        margin-bottom: 2px;
    }
</style>