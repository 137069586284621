export class FormVeiculo{
  vecodigo='';
  veprefixo='';
  veplaca='';
  vechassi='';
  vemaxpeso='';
  vecubagem='';
  veautonomia='';
  vemaxentregas='';
  vemaxhoras='';
  vecusto='';
  velorameshid='';
  velorameshidrede='';
  veproprietario='';
  vemodulo = '';
  vemodulosecundario = '';
  vemotoristas='';
  vevelocidademax='';
  vedescricao='';
  vetipo='';
  vehorainiciotrabalho='';
  vehorafinaltrabalho='';
  veestradaterra='';
  vebalsas='';
  vepedagios='';
  veajudante='';
  vebem='';
  vefamilia='';
  vecodigointerfoc='';
  vetemperaturaminima='';
  vetemperaturamaxima='';
  veumidademin='';
  veumidademax='';
  veco2min='';
  veco2max='';
  vepontofixo=false;
  vepontofixoabrefecha = false;
  veacessocadeirante = '';
  vedatainstalacao='';
  vebasecalculomedia='';
  veroterizar='';
  vestatus = 'A';
  veplacaantiga='';
  velorameshrede='';
  vecapacidadetanque='';
}

export class FormModulo {
  vchorimetro='';
  vchodometro='';
}

export class FormPerfilInstalacao{
  vpicodigo=null;
  vpibloqueioignicao=null;
  vpipanico=null;
  vpibau=null;
  vpibetoneira=null;
  vpiportamotorista=null;
  vpitemperatura=null;
  vpisirene=null;
  vpiveiculo=null;
  vpirfid=null;
  vpican=false;
  vpitiposensortemperatura='';
  vpiportaajudante=null;
  vpipainel=null;
  vpidesengate=null;
  vpibloqueiocombustivel=null;
  vpitravabau=null;
  vpitipoleitorrfid=null;
  vpicaracol=null;
  vpipneumatico=null;
  vpiparabrisas=null;
}

export class FormVRegiao{
  vrregiao=[];
  vrveiculo='';
}