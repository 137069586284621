var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('panelEagle',{attrs:{"id":"CadastroPontuacao","loading":_vm.loadingPanel}},[_c('div',{staticClass:"col-sm-12 nopading row nopadding"},[_c('div',{staticClass:"col-sm-6"},[_c('tituloPage',{attrs:{"icon":_vm.mdiTrophy,"titulo":"Pontuação"}})],1),_c('div',{staticClass:"col-sm-6 col-md-6 col-lg-6"},[_c('basicButtonsCadastrosSC',{attrs:{"disabled":_vm.$v.$invalid,"tipoCancela":"button","linkCancel":"pontuacao"},on:{"salvarCadastro":_vm.salvarPontuacao}})],1)]),_c('div',{staticClass:"col-sm-12 col-md-12"},[_c('hr')]),_c('div',{staticClass:"col-sm-12 row nopadding"},[_c('div',{staticClass:"col-sm-3 nopadding"},[_c('selectAll',{attrs:{"extraClass":{
        'border border-danger': _vm.$v.empresasSelecionadas.$anyError
      },"nameForRadio":"Selecione*","selected":_vm.empresaSelected,"isMultiple":false,"hasSelectAll":false,"optionsArray":_vm.optEmpresas,"labels":[{ indexDFH: 'SE', description: 'Empresa*' }]},on:{"close":function($event){return _vm.$v.empresasSelecionadas.$touch()},"changeSelect":_vm.changeEmpresa}})],1),_c('div',{staticClass:"col-sm-3 nopadding"},[_c('inputSimple',{attrs:{"name":"descrição","label":"Descrição*","value":_vm.descricaoSelected,"inputClass":{ 'border border-danger': _vm.$v.descricao.$anyError }},on:{"blur":function($event){return _vm.$v.descricao.$touch()},"changeInput":_vm.changeDescricao}})],1),_c('div',{staticClass:"col-sm-6 nopadding"},[_c('buttonsFilters',{attrs:{"label":"Valido para*","arrayButtons":_vm.botoes,"onlyOneSelected":false},on:{"buttonsFiltersChange":_vm.changeTipo}})],1),_c('requiredFields')],1),_c('div',{staticClass:"col-sm-12 col-md-12"},[_c('hr')]),_c('div',{staticClass:"col-sm-12 row tamanho-minimo"},[_c('div',{staticClass:"col-sm-12 col-md-12 col-lg-3 categorias"},[_c('b-card',{staticClass:"tableCategorias",attrs:{"no-body":"","header":"Categorias"}},_vm._l((_vm.categorias),function(dados,p){return _c('b-list-group',{key:dados + p,staticClass:"border-none",attrs:{"flush":""}},[_c('b-list-group-item',{staticClass:"text-left border-none grupoCategoria",attrs:{"disabled":dados[0].pscategoria != 1 && dados[0].pscategoria != 3
            ? _vm.disabled
            : false},on:{"click":function($event){return _vm.clicaBotao(dados, p)}}},[_vm._v(" "+_vm._s(p)+" ")])],1)}),1)],1),_c('div',{staticClass:"col-md-12 col-lg-8 estilo-tabela tamanho-corpo-tabela"},[_c('table',{staticClass:"tamanho-tabela"},[_c('thead',{staticClass:"cabecalho"},[_c('tr',[_c('th',{staticClass:"th",attrs:{"colspan":"1"}},[_vm._v("Opções")]),_c('th',{staticClass:"th",attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(this.categoriaAtual == 'Telemetria avançada' ? 'Valor' : 'Tempo')+" ")]),_c('th',{staticClass:"th",attrs:{"colspan":"1"}},[_vm._v("Pontuação")])])]),_c('tbody',[_vm._l((_vm.categorias),function(subCategoria,index){return [_vm._l((_vm.categorias[index]),function(dados,indexx){return [_c('tr',{directives:[{name:"show",rawName:"v-show",value:(index == _vm.categoriaAtual),expression:"index == categoriaAtual"}],key:indexx + index},[_c('td',{staticClass:"checkbox-opc",attrs:{"colspan":"1"}},[_c('b-checkbox',{staticClass:"checkBox",on:{"change":function($event){return _vm.changeCheck(dados.psdescricao, dados.pscodigo)}},model:{value:(dados.checkSelecionado),callback:function ($$v) {_vm.$set(dados, "checkSelecionado", $$v)},expression:"dados.checkSelecionado"}},[_vm._v(" "+_vm._s(dados.psdescricao)+" ")])],1),_c('td',{attrs:{"colspan":"1"}},[_c('inputSimple',{ref:'inputTempo' + dados.psdescricao,refInFor:true,staticClass:"inputSimpleTable",attrs:{"placeholder":dados.psdescricao == 'Média consumo de combustível'
                    ? '00,00'
                    : '--:--:--',"hasMask":dados.psdescricao == 'Média consumo de combustível'
                      ? false
                      : true,"mask":dados.psdescricao == 'Média consumo de combustível'
                        ? null
                        : ['NN:NN:NN'],"disabled":dados.psdescricao == 'Média consumo de combustível'
                          ? !dados.checkSelecionado
                          : !dados.checkSelecionado || dados.inputDisabled.time,"value":dados.psdescricao == 'Média consumo de combustível'
                            ? dados.ppovalor
                            : dados.ppotempo,"title":_vm.validaHora(dados.ppotempo)
                              ? 'Valor máximo  23:59:59'
                              : '',"inputClass":{
                                'border border-danger':
                                  dados.psdescricao == 'Média consumo de combustível'
                                    ? ''
                                    : _vm.validaHora(dados.ppotempo)
                              }},on:{"changeInput":v => {
                                if (
                                  dados.psdescricao == 'Média consumo de combustível'
                                ) {
                                  dados.ppovalor = v
                                } else {
                                  dados.ppotempo = v
                                }
                              }}})],1),_c('td',{attrs:{"colspan":"1"}},[_c('inputSimple',{ref:'inputPontuacao' + dados.psdescricao,refInFor:true,staticClass:"inputSimpleTable",attrs:{"hasMask":true,"mask":['NNNNNNNNN'],"disabled":!dados.checkSelecionado || dados.inputDisabled.pontuacao,"value":dados.ppopontuacao},on:{"changeInput":v => (dados.ppopontuacao = v)}})],1)])]})]})],2)])])]),_c('div',{staticClass:"col-sm-12 col-md-12"},[_c('table',{staticClass:"text-left"},[_c('thead',[_c('th',[_vm._v("Classificação*")])]),_c('tbody',[_c('tr',[_c('td',{staticClass:"my-auto badge badge-success"},[_vm._v("Classe A")]),_c('td',{staticClass:"my-auto"},[_vm._v("De")]),_c('td',{staticClass:"tamanho-input my-auto",attrs:{"colspan":"2"}},[_c('inputSimple',{attrs:{"type":"number","value":_vm.pontoASelectedIni,"inputClass":{
              'border border-danger': _vm.$v.pontosA.$anyError
            }},on:{"blur":function($event){return _vm.$v.pontosA.$touch()},"changeInput":_vm.pontoA}})],1),_c('td',[_vm._v("até")]),_c('td',{staticClass:"tamanho-input my-auto",attrs:{"colspan":"2"}},[_c('inputSimple',{attrs:{"inputClass":{
              'border border-danger': _vm.$v.pontosFinA.$anyError
            },"type":"number","value":_vm.pontoASelectedFim},on:{"blur":function($event){return _vm.$v.pontosFinA.$touch()},"changeInput":_vm.pontoFinA}})],1),_c('td',[_vm._v("pontos.")])]),_c('tr',[_c('td',{staticClass:"my-auto badge badge-primary"},[_vm._v("Classe B")]),_c('td',{staticClass:"my-auto"},[_vm._v("De")]),_c('td',{staticClass:"tamanho-input my-auto",attrs:{"colspan":"2"}},[_c('inputSimple',{attrs:{"type":"number","value":_vm.pontoBSelectedIni,"inputClass":{
              'border border-danger': _vm.$v.pontosB.$anyError
            }},on:{"blur":function($event){return _vm.$v.pontosB.$touch()},"changeInput":_vm.pontoB}})],1),_c('td',[_vm._v("até")]),_c('td',{staticClass:"tamanho-input my-auto",attrs:{"colspan":"2"}},[_c('inputSimple',{attrs:{"type":"number","value":_vm.pontoBSelectedFim,"inputClass":{
              'border border-danger': _vm.$v.pontosFinB.$anyError
            }},on:{"blur":function($event){return _vm.$v.pontosFinB.$touch()},"changeInput":_vm.pontoFinB}})],1),_c('td',[_vm._v("pontos.")])]),_c('tr',[_c('td',{staticClass:"my-auto badge badge-warning"},[_vm._v("Classe C")]),_c('td',{staticClass:"my-auto"},[_vm._v("De")]),_c('td',{staticClass:"tamanho-input my-auto",attrs:{"colspan":"2"}},[_c('inputSimple',{attrs:{"type":"number","value":_vm.pontoCSelectedIni,"inputClass":{
              'border border-danger': _vm.$v.pontosC.$anyError
            }},on:{"blur":function($event){return _vm.$v.pontosC.$touch()},"changeInput":_vm.pontoC}})],1),_c('td',[_vm._v("até")]),_c('td',{staticClass:"tamanho-input my-auto",attrs:{"colspan":"2"}},[_c('inputSimple',{attrs:{"type":"number","value":_vm.pontoCSelectedFim,"inputClass":{
              'border border-danger': _vm.$v.pontosFinC.$anyError
            }},on:{"blur":function($event){return _vm.$v.pontosFinC.$touch()},"changeInput":_vm.pontoFinC}})],1),_c('td',[_vm._v("pontos.")])]),_c('tr',[_c('td',{staticClass:"my-auto badge badge-danger"},[_vm._v("Classe D")]),_c('td',{staticClass:"my-auto"},[_vm._v("Acima de")]),_c('td',{staticClass:"tamanho-input my-auto",attrs:{"colspan":"2"}},[_c('inputSimple',{attrs:{"type":"number","value":_vm.pontoDSelectedIni,"inputClass":{
              'border border-danger': _vm.$v.pontosD.$anyError
            }},on:{"blur":function($event){return _vm.$v.pontosD.$touch()},"changeInput":_vm.pontoD}})],1),_c('td',[_vm._v("pontos.")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }