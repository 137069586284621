<template>
    <panelEagle id='Perfil-de-acesso' :loading="loadingPanel">
        <div class="col-sm-12 row">
            <div class="col-md-12 col-lg-6 nopadding">
                <tituloPage :icon='mdiShieldAccount' titulo='Perfil de acesso'></tituloPage>
            </div>
            <div class="col-md-12 col-lg-6 nopadding divDontPrint">
                <basicButtonsCadastrosEIN
                    linkNew="cadastrarPerfilAcesso"
                    tipoNovo='button'
                    @novoCadastro="novoCadastro"
                    :loading='exportaRelatorio'
                    :disabledDropdown='dataTable.length == 0 ? true : false'
                    @exportarCadastro='exportarCadastro'>
                </basicButtonsCadastrosEIN>
                
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class='col-sm-12 row nopadding'>
            <div class="col-lg-5 col-md-12 nopadding divDontPrint">
                <selectAll
                    :labels="[{indexDFH:'EM', description: 'Empresas'}]"
                    nameForRadio='selectAll'
                    :hasSelectAll='true'
                    :isMultiple='true'
                    :optionsArray="optionsSelectEmpresa"
                    :selected='firstOne'
                    @changeSelect="changeSelect"/>
            </div>
            <div class="col-lg-7 col-md-12 nopadding divDontPrint">
                <buttonsFilters
                    label='Status'
                    :arrayButtons='arrayButtons'
                    @buttonsFiltersChange='buttonsFiltersChange'/>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class="col-sm-12 row nopadding">
            <tableListagem
                :loading="loadingTable"
                :titles='titles'
                :data='dataTable'
                deleteEdit='editStatus'
                @alterarStatus='alterarStatusCadastro'
                @editarCadastro='editarCadastro'
            />
        </div>
    </panelEagle>
</template>
<script lang='js'>
import Vue  from 'vue'
import Vuex from '@/store/index'
import { mdiShieldAccount, mdiCheckBold } from '@mdi/js'
import {EmpresasService} from '@/Services/auth/Empresas.service'
import {HttpRequest} from '@/Services/auth/HttpRequest.Service'
import {mapGetters} from 'vuex'
export default Vue.extend({
	name:'PerfilDeAcesso',
	components:{
		'panelEagle'               : require('@/components/Atom/Panel/PanelEagle').default,
		'tituloPage'               : require('@/components/Atom/Header/Titulo').default,
		'selectAll'                : require('@/components/Atom/Select/SelectAll').default,
		'basicButtonsCadastrosEIN' : require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
		'buttonsFilters'           : require('@/components/Atom/Buttons/ButtonsFilters').default,
		'tableListagem'            : require('@/components/Atom/Table/TableListagem').default,
	},
	data(){
		return {
			mdiCheckBold:mdiCheckBold,
			loadingPanel:false,
			mdiShieldAccount:mdiShieldAccount,
			loadingTable:false,
			titles :[
				{'number':'descricao', 'name':'Descrição'},
				{'number':'empresa', 'name':'Empresa'},
			],
			dataTable: [],
			valueEmpresa: [],
			exportaRelatorio:[false, false, false],
			valueFiltro:'',
			optionsSelectEmpresa: new EmpresasService().Get(),
			firstOne:[],
			arrayButtons: [
				{
					value: 'ativos', 
					width: '32.50%', 
					isSelected: true, 
					extraClass: '', 
					title: 'Somente perfis ativados', 
					text: 'Ativos', 
					icon: ''
				},
				{
					value: 'inativos', 
					width: '32.50%', 
					isSelected: false, 
					extraClass: '', 
					title: 'Somente perfis inativados', 
					text: 'Inativos', 
					icon: ''
				},
				{
					value: 'todos', 
					width: '32.50%', 
					isSelected: false, 
					extraClass: '', 
					title: 'Todos os perfis', 
					text: 'Todos', 
					icon: ''
				},
			]
		}
	},
	methods:{
		async changeSelect(value){
			this.loadingPanel = true
			this.valueEmpresa = value
			var obj = {
				'empresa':this.valueEmpresa,
				'filtro':this.valueFiltro
			}
			var dados = await new HttpRequest()
				.Post('/administrativo/cadastros/perfil/acesso/tabela',
					{'dados':obj})
			this.dataTable = dados.data
			this.loadingPanel = false
		},
		async alterarStatusCadastro(value){
			var dados = await new HttpRequest()
				.Post('/administrativo/cadastros/perfil/acesso/status',
					{'dados':value})
			this.changeSelect(this.valueEmpresa)
		},
		editarCadastro(value){
			this.$router.push(`/administrativo/cadastros/cadastrar/perfil/${value}`)
		},
		novoCadastro(){
			this.$router.push('/administrativo/cadastros/cadastrar/perfil')
		},
		async exportarCadastro(value){
			switch(value){
				case 'pdf':
					this.exportaRelatorio = [true, false, false]
					break;
				case 'xls':
					this.exportaRelatorio = [false, true, false]
					break;
				case 'csv':
					this.exportaRelatorio = [false, false, true]
					break;
				default:
					break;
			}
			var obj ={
				'tipo':value,
				'tabela':this.dataTable
			}
			var root =  process.env.VUE_APP_ROOT;
			var dados = await new HttpRequest()
				.Post('/administrativo/cadastros/perfil/acesso/exportar', {'dados':obj})
			this.exportaRelatorio = [false, false, false]
			window.open(root+'/'+dados.data.retorno)
		},
		buttonsFiltersChange(value){
			this.arrayButtons[0].icon = ''
			this.arrayButtons[1].icon = ''
			this.arrayButtons[2].icon = ''
			if(value[0] == 'todos'){
				this.valueFiltro = value
				this.arrayButtons[2].icon = mdiCheckBold
			}
			else if(value[0] == 'ativos'){
				this.valueFiltro = [true]
				this.arrayButtons[0].icon = mdiCheckBold
			}
			else{
				this.valueFiltro = [false]
				this.arrayButtons[1].icon = mdiCheckBold
			}
			if(this.valueEmpresa.length != 0){
				this.changeSelect(this.valueEmpresa)
			}
		},
		selectAllClientes(){
			if(this.getMaster())
				this.firstOne = []
			else  
				this.firstOne =  new EmpresasService().Get()
		},
		...mapGetters(['getMaster']),
	},
	mounted(){
		this.selectAllClientes()
	}
})
</script>
<style lang="scss">

</style>