<template>
	<div class="col-sm-12" style="padding: 0px 0px 5px 0">
		<div class="divInfoEtapa">
			{{ mensagens[etapa] }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'InfoEtapaRotaManual',
	props: {
		etapa: {
			required: true,
			type: Number
		}
	},
	data() {
		return {
			mensagens: {
				1: 'Preencha as informações obrigatórias(*) para seguir para a próxima ' +
				'etapa. ',
				2: 'Se necessário, filtre os pedidos para a próxima etapa, de acordo ' +
				'com a sua operação.',
				3: 'Realize o planejamento de sua rota. Lembre-se de clicar em ' +
				'"Roteirizar" sempre que realizar uma ação. Dica: utilize os ' +
				'facilitadores para agilizar o processo de seleção dos pedidos. ',
			}
		}
	}
}
</script>

<style lang="scss">
.divInfoEtapa {
	padding: 8px 8px 5px 10px;
	background-color: #c0efff;
	font-size: 12px;
	border-radius: 5px;
	border: 1px solid lightblue;
	color: dimgrey;
	text-align: left;
}
</style>