Elemento que facilita a lógica por traz da página de cadastro de escalas.
props:{
  arrayLinhas   : Linhas que devem aparecer como opções para o seletor
  arrayVeiculos : Veiculos que irão apareces como opções  
  arrayCods     : códigos (linha.value + dia.value + hora.value) criados para os elementos já selecionados
}

Eventos:{
  inserirTurno : É emitido quando uma linha é adicionada a lista, traz o código do elemento + a lista completa. 
}


<template>
  <div class='col-sm-12 row nopadding'>
    <div class="col-sm-6 nopadding teste row">
      <div class="col-sm-12 nopadding">
        <slot name='principal' :arrayLinhas='arrayLinhas'>
          <selectAll
            nameForRadio='SelectLinha'
            :labels='[{description:"Linha"}]'
            :isMultiple='false'
            :hasSelectAll='false'
            :optionsArray='linhas'
            :extraClass='{"border border-danger":$v.turno.linha.$anyError}'
            @selectCriado='criaSeletorLinha'
            @close='$v.turno.linha.$touch()'
            @changeSelect='mudaLinha'/>
        </slot>
      </div>
      <div class="col-sm-6 nopadding mt-3">
        <slot name='dia'>
          <selectAll
            nameForRadio='selectDia'
            :labels='[{description:"Dia da semana"}]'
            :isMultiple='false'
            :hasSelectAll='false'
            :optionsArray='optDiaSemana'
            :extraClass='{"border border-danger":$v.turno.dia.$anyError}'
            @selectCriado='criaSeletorDia'
            @close='$v.turno.dia.$touch()'
            @changeSelect='mudaDiaSemana'/>
        </slot>
      </div>
      <div class="col-sm-6 nopadding mt-3">
        <slot name='time'>
          <selectAll
            nameForRadio='selectDia'
            :labels='[{description:"Horário"}]'
            :isMultiple='false'
            :hasSelectAll='false'
            :optionsArray='optHorarios'
            :extraClass='{"border border-danger":$v.turno.hora.$anyError}'
            @selectCriado='criaSeletorHora'
            @close='$v.turno.hora.$touch()'
            @changeSelect='mudaHora'/>
        </slot>
      </div>
      <div class="col-sm-12 row">
        <div class="col-sm-8">
          <b-alert
            :show='countDown'
            fade
            class='small'
            @dismiss-count-down='cdAlerta'
            variant="warning">
          Esse horário já foi inserido nesta escala
          </b-alert>
          <b-alert
            :show='aviso'
            fade
            class='small'
            @dismiss-count-down='cdAlerta'
            variant="warning">
          {{ 'O horário já existe na(s) escala(s): ' + mensagem.join(' , ') }} 
          </b-alert>
          <b-alert
            :show='sucesso'
            fade
            class='small'
            @dismiss-count-down='cdSucesso'
            variant="success">
          {{' inserido com sucesso'}} 
          </b-alert>
        </div>
        <div class="col-sm-4 somepadding left">
          <botao
            :disabled='$v.$invalid'
            text='Inserir'
            width='100%'
            type='green'
            event='click'
            :icon='mdiPlus'
            @click='inserirTurno'/>
        </div>
      </div>
      <div class="col-sm-12 nopadding">
        
      </div>
    </div>
    <div class="col-sm-6 nopadding divRightTurno">
      <slot name='table' :titulos='titles'>
      </slot>
    </div>
  </div>
</template> 

<script>
import {mdiPlus } from '@mdi/js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import Vue from 'vue'
import { required } from 'vuelidate/lib/validators'
export default Vue.extend({
  components: {
    'selectAll' : require('@/components/Atom/Select/SelectAll').default,
    'botao'     : require('@/components/Atom/Buttons/SimpleButton').default,
  },
  props: {
    arrayLinhas: {
      type: Array,
      required : true
    },
    arrayCods:{
      type: Array,
      require: true
    },
  },
  validations:{
    turno:{
      linha:{
        value :{ required }
      },
      dia     :{ 
        value :{ required },
      },
      hora    :{
        value :{ required }
      }
    }
  },
  data() {
    return {
      mdiPlus:mdiPlus,
      titles:[
        {'number': 'linha.description', 'name': 'Linha'  },
        {'number': 'dia.description',   'name': 'Dia'    },
        {'number': 'hora.description',  'name': 'Horário'},
        ],
      mensagem  : [],
      aviso    : false,
      turno:{
        linha   :{
          value: '',
          description: ''
        },
        dia     :{
          value: '',
          description: ''
        },
        hora    :{
          value: '',
          description: ''
        },
        veiculo :{
          value: '',
          description: ''
        },
      },
      todosHorarios : [],
      optHorarios   : [],
      optDiaSemana  : [],     
      // misc
      seletores:{
        linha :Object,
        hora  :Object,
        dia   :Object
      },
      countDown : 0,
      sucesso:0,
      segASexta : []
    } 
  },
  methods: {
    verificaSeEstaEmOutraEscala(aux=null){
      if(!aux){
        aux = this.turno.hora.value;
      }
      this.aviso = false
      this.mensagem = []
      var rota ='/coletivos/cadastros/escalas/verificaturno'
      new HttpRequest().Post(rota, {horario:aux})
        .then((data)=>{
          var existe = data.data.existe
          if(existe){
            existe.forEach(el=>{
              this.mensagem.push(el.escalas.esdescricao)
              this.aviso = true
            })
          }
      })
    },
    cdAlerta(value){
      this.countDown = value
    },
    cdSucesso(value){
      this.sucesso = value
    },
    inserirTurno(){
      if(this.turno.dia.description == 'Segunda a Sexta'){
          this.arrumaSegundaASexta(this.turno.hora.value)
      }else {
        this.adicionaTarefa(this.turno)
      }
    },
    adicionaTarefa(tarefa){
      var codigo = tarefa.linha.value+tarefa.dia.value+tarefa.hora.value+''
      var existe = this.codigos.find(cod=>{ return cod === codigo }) //espera função 🙄🌦
      if(existe === undefined){
        var aux = JSON.parse(JSON.stringify(tarefa))
        aux['codigo'] = codigo
        this.$emit('adicionaTarefa', aux)
        this.sucesso = 5
        return
      }
      this.countDown = 5
    },
    arrumaSegundaASexta(stringCodigos){
      var arrayCods = stringCodigos.slice(1,-1).split(',')
      for(var i = 1; i<=5; i++){
        var aux
        aux = {
          'dia'    : this.optDiaSemana.find(dia => {return dia.value == i}),
          'linha'  : this.turno.linha,
          'hora'   : {description:this.turno.hora.description, value:arrayCods[i-1]},
          'veiculo': this.turno.veiculo
        }
        this.verificaSeEstaEmOutraEscala(aux.hora.value)
        this.adicionaTarefa(aux) 
      }
    },
    mudaLinha(array, value, description){
      this.seletores.dia.clearAll()
      this.$v.turno.dia.$reset()
      this.seletores.hora.clearAll()
      this.$v.turno.hora.$reset()
      this.turno.hora.value = ''
      this.turno.hora.description = ''
      this.turno.linha.description = description
      this.turno.linha.value = value
      if(array.length > 0){
        this.procuraDadosLinha(array)
      }
    },
    procuraDadosLinha(array){
      new HttpRequest()
        .Post('/coletivos/cadastros/escalas/informacoesLinha', {'linha':array})
        .then((data)=>{
          this.todosHorarios = data.data.info
          this.optDiaSemana  = data.data.dias
          this.segASexta = data.data.segASexta
        })
    },
    mudaHora(array, value, description){
      this.turno.hora.value = value
      this.turno.hora.description = description
      var diaUnicoEExistente = this.seletores.dia.selectedItens.value != 7 && array.length > 0
      if(diaUnicoEExistente){
        this.verificaSeEstaEmOutraEscala()
      }
    },
    mudaDiaSemana(array, value, description){
      if(value){
        this.seletores.hora.clearAll()
        this.$v.turno.hora.$reset()
        this.turno.hora.value = ''
        this.turno.hora.description = ''
        this.turno.dia.value = value
        this.turno.dia.description = description
        if(value!='7'){
          this.optHorarios = this.todosHorarios[value].sort(function(a, b){
            return a.description >= b.description
          })
          }else{
            this.optHorarios = this.segASexta
        }
      }
      
    },
    clearAllSeletores(){
      Object.keys(this.seletores).forEach(el=>{
        this.seletores[el].clearAll()
      })
      this.$v.$reset()
    },
    criaSeletorLinha(that){
      this.seletores.linha = that
    },
    criaSeletorDia(that){
      this.seletores.dia   = that
    },
    criaSeletorHora(that){
      this.seletores.hora  = that
    }
  },
  computed:{
    linhas: function(){
      if(this.arrayLinhas.length > 0 ){
        return this.arrayLinhas
      }
      return [{value:'0', description:'Selecione uma empresa'}]
    },
    codigos: function(){
      if(this.arrayCods){
        return this.arrayCods
      }
      return []
    }
  }
})
</script>

<style lang="scss" scoped>
.divRightTurno{
  border-left: 1px solid #428bca !important;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.somepadding{
  padding: 1% 0 1% 0
}
.small{
  padding: 0  0 0 0 !important;
  margin:  2% 0 0 0 !important;
}
.teste{
  max-width: 100%;
  max-height: 100%;
}
</style>