<template>
    <modalEagle
        @modalOpen="modalOpen"
        size="lg"
        :title="defineTitleModal"
        :id="id"
    >
        <slot slot="modal-header">
            <div class="col-sm-12 row">
                <div class="col-sm-9 tituloModalHeaderAlertas p-0">
                    <div class="veiculo-alerta">{{ defineTitleModal }}</div>
                    <template v-for="(total, tipo) of veiculoData.totais">
                        <div
                            v-if="total"
                            :key="tipo + '_total_cor'"
                            :class="`numero-alerta alerta-${tipo}`"
                        >
                            {{ total }}
                        </div>
                    </template>
                </div>
                <div class="col-sm-3 solicitarManutencao" v-if="getCadastrar()">
                    <simpleButton
                        text="Solicitar Manutenção"
                        type="orange"
                        :icon="mdiCarCog"
                        title="Solicitar Manutenção"
                        width="170px"
                        event="solicitarManutencao"
                        @solicitarManutencao="modalSolicitarManutencao"
                        :disabled="loadingPanelModal"
                    />
                </div>
            </div>
        </slot>
        <slot slot="modalBody">
            <panelEagle
                class="minH"
                :id="'id-modal-' + defineTitleModal"
                :loading="loadingPanelModal"
            >
                <portal to="aba1" :disabled="disablePortal" v-show="false">
                    <mapaSimples
                        :latitude="latitudeMapa"
                        :longitude="longitudeMapa"
                        height="300"
                    >
                        <markerPoint
                            :latitude="latitudeMapa"
                            :longitude="longitudeMapa"
                        />
                    </mapaSimples>
                </portal>
                <tabsEagle :tabs="tabs" v-show="mostrarTratativas" ref="refTabEagle">
                    <slot slot="tab-desc">
                        <br />
                        <div class="col-sm-12 row">
                            <div class="col-sm-6 row div-tratativa p-0 pr-4">
                                <div class="col-sm-8 p-0">
                                    <span class="col-sm-12 nopadding"
                                        v-html="textoDescritivoTratativa">
                                    </span>
                                    <span class="col-sm-12 nopadding small row">
                                        {{ textoDataHoraTratativa }}
                                    </span>
                                </div>
                                <div class="col-sm-4 p-0">
                                    <div class="alinhar-botao-x">
                                        <simple-button
                                            :disabled="
                                                loadingPanelModal || !valueTratativa
                                            "
                                            @click="salvarTratativa"
                                            :icon="mdiCheckBold"
                                            text="Salvar"
                                            event="click"
                                            title="Salvar tratativa"
                                            width="100px"
                                        />
                                    </div>
                                </div>
                                <div class="col-sm-12 nopadding">
                                    <textarea
                                        id="text"
                                        rows="7"
                                        class="index-gg"
                                        ref="refTextArea"
                                        v-model="valueTratativa"
                                    >
                                    </textarea>
                                </div>
                            </div>
                            <div class="col-sm-6 tableFixHead div-table p-0">
                                <table class="table" style="height: 100%">
                                    <thead>
                                        <tr>
                                            <th class="thTratativas">
                                                <div class="divChild">
                                                    <div>Tratativas salvas</div>
                                                    <simple-button
                                                        :disabled="loadingPanelModal"
                                                        @click="clickLerAlertaAtual"
                                                        event="click"
                                                        :icon="mdiCheckBold"
                                                        type="green"
                                                        text="Ler alerta"
                                                        width="110px"
                                                    />
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="tratativas.length">
                                        <tr
                                            v-for="(item, i) of tratativas"
                                            :key="i + '_desc_tratativa'"
                                        >
                                            <td class="p-1">
                                                {{ item.taldescritivo }}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <div class="col-sm-12 text-center mt-4 mb-4">
                                            <span class="bota-no-meio">
                                                Nenhuma tratativa
                                            </span>
                                        </div>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <br />
                    </slot>
                    <slot slot="tab-acoes">
                        <br />
                        <div class="col-sm-12 text-center alinhar-texto">
                            <span class="bota-no-meio">
                                Em breve novas funcionalidades
                            </span>
                        </div>
                        <br />
                    </slot>
                </tabsEagle>
                <div class="mapa col-sm-12 div-tratativa p-0" v-show="mostrarMapa">
                    <portal-target name="aba1" />
                </div>
                <template v-if="!mostrarTratativas">
                    <div class="col-sm-12 row pt-2" v-if="temAlertas">
                        <tipoAlerta
                            v-for="(item, id) of tipos"
                            :key="item.value + '_Alerta' + id"
                            :title="item.description"
                            :id="item.value"
                            :alerta="dadosAlertas[item.value]"
                            :permissaoEditar="getEditar() || getMaster()"
                            :tituloModal="defineTitleModal"
                            :icon="defineIconeAlerta(item.icone)"
                            @clickIconeLocalizar="clickIconeLocalizar"
                            @clickIconeTratativas="clickIconeTratativas"
                            @clickLerTodosAlertas="clickLerTodosAlertas"
                            @clickLerAlerta="clickLerAlerta"
                            @showCollapse="showCollapseAlerta"
                            :loading="loadingPanelModal"
                        >
                        </tipoAlerta>
                    </div>
                    <div class="col-sm-12 pt-5 text-center" v-else>
                        Nenhum alerta para este veículo.
                    </div>
                </template>
            </panelEagle>
        </slot>
        <slot slot="modalFooter">
            <div class="small arruma-caixa">
                Ao fechar essa tela, todas as informações que não foram salvas, serão
                perdidas.
            </div>
            <simple-button
                :disabled="loadingPanelModal"
                @click="fecharTratativaEmapa"
                :icon="mdiChevronLeftCircleOutline"
                event="click"
                title="Cancelar tratativa"
                text="Voltar"
                v-if="mostrarTratativas || mostrarMapa"
            />
            <simpleButton
                event="click"
                @click="fecharModal"
                type="red"
                :icon="mdiCloseThick"
                text="Fechar"
            />
        </slot>
    </modalEagle>
</template>

<script lang="js" scoped>

import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { mapGetters } from 'vuex'
import { IconManager } from './Alertas'
import Vue from 'vue';
import { conectionError } from '@/Services/Helpers/swellHeper';

export default Vue.extend({
	name: 'ModalAlertasVeiculo',
	components:{
		'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
		'modalEagle': require('@/components/Atom/Modal/ModalEagle').default,
		'simpleButton': require('@/components/Atom/Buttons/SimpleButton.vue').default,
		'tabsEagle': require('@/components/Atom/Tabs/TabsEagle').default,
		'tipoAlerta': require('@/views/MapaPlus/Alertas/TipoAlerta').default,
		'mapaSimples': require('@/components/Atom/Mapa/MapaSimples.vue').default,
		'markerPoint': require('@/components/Atom/Mapa/MarkerPoint').default,
    },

    props: {
        id: {
            type: String,
            default: 'modalAlertasVeiculo',
        },
        veiculo: {
            required: true,
            type: Object,
			default: ()=>{return[]}
        },
        tipos: {
            required: true,
            type: [Array, Object],
			default: ()=>{return[]}
        },
    },

	data(){
		return {
			url: '/telemetria/nao/menu/alerta/',
			...IconManager.getIcons(),
			loadingBotaoAlertas:false,
			latitudeMapa : 0,
			longitudeMapa : 0,
			disablePortal:false,
			mostrarMapa:false,
			mostrarTratativas:false,
			tabs:[
				{'title':'Descrição',  'value':'desc'},
				{'title':'Ações',      'value':'acoes'},
			],
			dadosAlertas:[],
            loadingPanelModal: false,
            veiculoData: {},
            valueTratativa: '',
            tratativas: [],
            tratativa: {},
            textoDescritivoTratativa: '',
            textoDataHoraTratativa: '',
            indexAtual: null,
		}
	},

	methods:{
		...mapGetters(['getMaster', 'getEditar', 'getCadastrar']),

		salvarTratativa(){
			this.salvarTratativaColapse(this.tratativa)
		},

		modalOpen(){
			this.fecharTratativaEmapa()
            this.limpaTratativas()
		},

        /**
		 * @description Limpa algumas variáveis do modal.
		 * @author  Rafael
		 */
        resetModal() {
			this.tratativa = {}
			this.valueTratativa = ''
			this.tratativas = []
			this.dadosAlertas = []
        },

		limpaTratativas(){
			this.tratativa = {}
		},

		fecharTratativaEmapa(){
			this.mostrarMapa = false
			this.mostrarTratativas = false
		},

		fecharModal(){
			this.$bvModal.hide('modalAlertasVeiculo')
			this.fecharTratativaEmapa()
		},

		modalSolicitarManutencao(){
			this.$bvModal.show('modal-solicitacao-manutencao');
        },

        /**
		 * @description Faz validacao da latitude e longitude do alerta.
		 * @param { string } locationString
         * @return {string}
		 * @author  Rafael
		 */
        getLatLng(locationString) {
            const locationRegex = /^([-]?\d{1,2}\.\d+),?\s*([-]?\d{1,3}\.\d+)$/;
            const match = locationString.match(locationRegex);
            if (match) {
                const latitude = parseFloat(match[1]);
                const longitude = parseFloat(match[2]);
                return { lat: latitude, lng: longitude };
            } else {
                return null;
            }
        },

        /**
		 * @description Mostra uma posicao no mapa, conforme latlng do alerta.
		 * @param { object } value alerta
		 * @param { string? } value.latlng
		 * @listens clickIconeLocalizar
		 * @author  Rafael
		 */
        clickIconeLocalizar(value) {
            const locationString = value.latlng
            const latLng = this.getLatLng(locationString ?? '')
            if (latLng) {
                this.latitudeMapa  = latLng.lat
                this.longitudeMapa = latLng.lng
                this.mostrarTratativas = false
                this.mostrarMapa = true
                this.disablePortal = true
                this.$nextTick(() => {
                    this.disablePortal = false
                })
            } else {
                this.toastShow(
                    'A latitude e longitude deste alerta é inválida!',
                    'danger',
                    'Localização'
                )
            }
		},

        /**
		 * @description Evento de click no ícone de tratativa.
		 * @param { object } value alerta
		 * @param { int } index
		 * @listens clickIconeTratativas
		 * @author  Rafael
		 */
		clickIconeTratativas(alerta, index){
            this.tratativa = alerta
			this.indexAtual = index
			this.mostrarTratativas = true
			this.mostrarMapa = false
			this.$refs.refTabEagle.$refs.refTabBootstrap.$data.currentTab = 0
			this.$root.$emit('bv::toggle::collapse', `${alerta.value}_tacodigo`)
            this.buscaTratativas()
        },

        defineIconeAlerta(tipo) {
            return IconManager.getIconeAlerta(tipo)
        },

        /**
		 * @description Função auxiliar para alterar as informações de alertas do veículo
         * no front.
		 * @param { object } value alerta
		 * @param { int } value.tipo
		 * @param { int } index posição do alerta do veículo
		 * @author  Rafael
		 */
        removerAlerta(value, index) {
            if (this.dadosAlertas[value.tipo]) {
                this.dadosAlertas[value.tipo].dados.splice(index, 1)
                this.dadosAlertas[value.tipo].contagem -= 1
                this.veiculoData.contador -= 1
                let tipo = this.tipos.filter(e => e.value == value.tipo)
                if (tipo && tipo.length) {
                    tipo = tipo[0]
                    this.veiculoData.totais[tipo.cor] -= 1
                }
                this.veiculo = this.veiculoData
                return true
            }
            return false
        },

        /**
		 * @description Função auxiliar para alterar as informações de alertas do veículo
         * no front.
		 * @param { int } tipoAlerta
		 * @author  Rafael
		 */
        removerAlertas(tipoAlerta) {
            let at = this.dadosAlertas[tipoAlerta]
            this.veiculoData.contador -= at.contagem
            let tipo = this.tipos.filter(e => e.value == tipoAlerta)
            if (tipo && tipo.length) {
                tipo = tipo[0]
                this.veiculoData.totais[tipo.cor] -= at.contagem
            }
            this.veiculo = this.veiculoData
            at.dados = []
            at.contagem = 0
            this.dadosAlertas[tipoAlerta] = at
            this.$forceUpdate()
        },

        clickLerAlertaAtual() {
            this.fecharTratativaEmapa()
            this.clickLerAlerta(this.tratativa, this.indexAtual)
        },

        showCollapseAlerta(tipo) {
            this.clickLerTodosAlertas(tipo, 'M')
        },

        /**
		 * @description Define o texto do tipo de alerta.
		 * @param { object } value alerta
		 * @param { int? } value.tipo
         * @return {string}
		 * @author  Rafael
		 */
        defineTextoDescritivoTratativa(value) {
            let texto = ''
            if (this.tipos.length && value?.tipo) {
                texto = this.tipos.find(e => e.value == value.tipo).description ?? ''
            }
            return `Descreva a tratativa do alerta: </br>${texto}`
        },

        /**
		 * @description Define o texto de data e hora do alerta.
		 * @param { object } value alerta
		 * @param { string? } value.data
		 * @param { string? } value.hora
         * @return {string}
		 * @author  Rafael
		 */
        defineTextoDataHoraTratativa(value) {
            let texto = ''
            if (value) {
                texto = value?.data + ' ' + value?.hora
            }
            return `Data: ${texto}`
        },

		toastShow(msg, type, title = ''){
			this.$bvToast.toast(`${msg}`, {
				autoHideDelay: 2500,
				variant: type,
				title: title
			})
        },

        requestError() {
            this.toastShow('Não foi possível concluir a operação!', 'danger', 'Erro')
        },

        requestSuccess(msg = '') {
            if(!msg) msg = 'Operação concluída com sucesso!'
            this.toastShow(msg, 'success', 'Sucesso')
        },

        /**
		 * @description Marca todos os registros de alertas como Lido ou Mostrado no 
         * banco, pertencentes ao [veiculo]. 
         * Verifica e altera os dados no front.
		 * @param { number } tipoAlerta
		 * @param { string } statusAlerta 'M'|'L'
		 * @listens clickLerTodosAlertas
		 * @author  Rafael
		 */
		async clickLerTodosAlertas(tipoAlerta, statusAlerta = 'L'){
            this.loadingPanelModal = true
            let url = `${this.url}ler/todas/tratativas`
            let params = { tipo:tipoAlerta, status:statusAlerta, 'veiculo': this.veiculo }
            await new HttpRequest().Post(url, params)
                .then(({ data, status, code }) => {
                    if (status) {
                        if (statusAlerta == 'L') {
                            this.removerAlertas(tipoAlerta)
                            this.consultaAlerta()
                            this.requestSuccess('Alertas lidos com sucesso!')
                            this.fecharTratativaEmapa()
                        }
                    } else {
                        this.requestError()
                    }
                })
                .catch((e) => {
                    this.requestError()
                })
                .finally(() => {
                    this.loadingPanelModal = false
                })
        },

        /**
		 * @description Marca o registro de alerta como Lido ou Mostrado no banco. 
         * Verifica e altera os dados do registro alterado no front.
		 * @param { object } alerta
		 * @param { number } index
		 * @listens clickLerAlerta
		 * @author  Rafael
		 */
		async clickLerAlerta(alerta, index){
			this.loadingPanelModal = true
			let url = `${this.url}ler/uma/tratativa`
            let params = { 'dados': alerta.alcodigo }
            await new HttpRequest().Post(url, params)
                .then(async ({ data, status, code }) => {
                    if (status) {
                        let deleted = this.removerAlerta(alerta, index)
                        if (this.tratativa?.alcodigo == alerta.alcodigo) {
                            this.fecharTratativaEmapa()
                        }
                        if (!deleted) {
                            await this.consultaAlerta(alerta)
                        }
                        this.requestSuccess('Alerta lido com sucesso!')
                    } else {
                        this.requestError()
                    }
                })
                .catch(() => {
                    this.requestError()
                })
                .finally(() => {
                    this.loadingPanelModal = false
                })
		},

        /**
		 * @description Faz a consulta para buscar os alertas do veículo.
		 * @param { object } value alerta
		 * @author Rafael
		 */
		async consultaAlerta(value){
			this.loadingPanelModal = true
			let url = `${this.url}busca/alerta/veiculo`
            let params = { 'dados': { ...value, ...this.veiculoData } }
			await new HttpRequest().Post(url, params)
                .then(({ data, status, code }) => {
                    if (status) {
                        this.dadosAlertas = data.alertas
                    } else {
                        conectionError()
                    }
                })
                .catch(e => {
                    conectionError()
                })
                .finally(() => {
                    this.loadingPanelModal = false
                })
		},

        /**
		 * @description Faz a consulta para buscar tratativas do alerta.
		 * @author Rafael
		 */
		async buscaTratativas(){
			let url = `${this.url}buscar/tratativa`
            let params = { 'dados': this.tratativa }
            this.tratativas = []
			await new HttpRequest().Post(url, params)
                .then(({ data, status, code }) => {
                    if (status) {
                        this.tratativas = data
                    } else {
                        conectionError()
                    }
                })
                .catch(e => {
                    conectionError()
                })
		},

        /**
		 * @description Salva uma tratativa no banco.
         * @param { object } dados tratativa
		 * @author Rafael
		 */
		async salvarTratativaColapse(dados){
			this.loadingPanelModal = true
			let url = `${this.url}salvar/uma/tratativa`
            let params = { 'dados': { ...dados, descricao: this.valueTratativa } }
            await new HttpRequest().Post(url, params)
                .then(async ({ data, status, code }) => {
                    if (status) {
                        this.tratativas?.push({ taldescritivo: this.valueTratativa })
                        this.valueTratativa = ''
                    } else {
                        conectionError()
                    }
                })
                .catch(e => {
                    conectionError()
                })
                .finally(() => {
                    this.loadingPanelModal = false
                })
		},
	},

    computed: {
        defineTitleModal() {
            let placa = this.veiculoData.veplaca ?? ''
            let prefixo = this.veiculoData.veprefixo ?? placa
            let divisor = placa && prefixo ? '|' : ''
			return `${placa} ${divisor} ${prefixo}`
        },

        defineContadorModal() {
			return this.veiculoData?.contador ?? ''
        },

        temAlertas() {
            return this.veiculoData?.contador > 0
        },
    },

    watch: {
        veiculo(value) {
            if (this.veiculoData?.vecodigo != value?.vecodigo) {
                this.resetModal()
            }
            this.veiculoData = value
            this.consultaAlerta(value)
        },

        tratativa(value) {
            this.textoDescritivoTratativa = this.defineTextoDescritivoTratativa(value)
            this.textoDataHoraTratativa = this.defineTextoDataHoraTratativa(value)
        },
    }
})
</script>

<style lang="scss">
#modalAlertasVeiculo {
    .div-table {
        max-height: 189px !important;
        border: 1px solid #428bca;
        tbody td {
            max-width: 0 !important;
            word-wrap: break-word !important;
        }
    }
    .thTratativas {
        padding: 2px 2px 2px 5px !important;
        .divChild {
            display: inline-flex;
            width: 100%;
            justify-content: space-between;
            div {
                align-self: center;
            }
            button {
                margin: 0;
            }
        }
    }
    .minH {
        min-height: 100px !important;
    }
    .tituloModalHeaderAlertas {
        display: inline-flex;
        align-self: center;
        margin-top: 3px !important;
        .veiculo-alerta {
            align-self: center;
            padding-right: 10px;
        }
        .numero-alerta {
            text-align: center;
            color: white;
            min-width: 22px;
            min-height: 26px;
            padding: 6px 6px 2px 6px;
            margin-left: 5px;
        }
        .alerta-vermelho {
            background-color: red;
        }
        .alerta-amarelo {
            background-color: #d8b606;
        }
        .alerta-cinza {
            background-color: grey;
        }
    }
    .solicitarManutencao {
        text-align: end;
        padding-right: 13px;
    }
    .alinhar-botao-x {
        z-index: 2000;
        right: 0;
        top: -1px;
        position: absolute;
    }
    .div-tratativa {
        contain: content;
        z-index: 2;
        margin-bottom: auto;
    }
    .index-gg {
        width: 100%;
        max-height: 133px;
        border-color: #428bca;
    }
    .titulo-tratativa {
        background-color: #f5f3ee;
        padding: 5px;
        margin-top: 10px;
    }
    .borda-desc-tratativa {
        margin-top: 10px;
        border: 10px solid rgb(245, 239, 239);
        // border-top: 15px solid rgb(245, 239, 239);
        // border-bottom: 15px solid rgb(245, 239, 239);
        background-color: #fff;

        padding-top: 5px;
    }
    .position-relative-tratativa {
        position: relative;
        // margin-bottom: 5px;
    }
    .alinha-botao-salvar-tratativa {
        right: 0px;
        top: 0px;
        position: absolute;
    }
    .alinhar-texto {
        margin: 20px;
    }
    .arruma-caixa {
        text-align: left !important;
    }
    .arrumar-botao-data {
        position: absolute;
        left: 90%;
    }
    #mapaSimples{
        .vue2leaflet-map{
            max-height: 220px !important;
        }
    }
}
</style>
