<template>
    <!-- @vue-ignore - Probably a limitation of using vue 2.7 with TS  -->
    <Bar :data="data" :options="options"/>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import {
    CategoryScale,
    Chart as ChartJS,
    ChartData,
    ChartOptions,
    Colors,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
    registerables
} from 'chart.js'
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-luxon'
import { Bar } from 'vue-chartjs'


// ChartJS.register(CategoryScale, Colors, LinearScale, LineElement, PointElement, TimeScale, Title, Tooltip, Legend)
ChartJS.register(...registerables);
ChartJS.register(zoomPlugin);

defineProps({
    /**
     * Chart data
     * @see { @link https://www.chartjs.org/docs/latest/api/interfaces/ChartData.html }
     */
    data: { type: Object as PropType<ChartData>, required: true },
    /**
     * Chart options
     * @see { @link https://www.chartjs.org/docs/latest/api/#chartoptions }
     */
    options: { type: Object as PropType<ChartOptions>,  required: true }
})
</script>
