<template>
    <div class="login">
        <form class="login" @submit.prevent="login">
            <div class="col-sm-4 divCentralLogin" style="margin: auto">
                <div class="col-sm-9 divImgLogo">
                    <img class="imgLogo" src="../../assets/logo.png" />
                </div>
                <div class="col-sm-12 divInputs text-left">
                    <b-alert :show="dismissCountDown" dismissible variant="warning">
                        {{ mensagemErro }}
                    </b-alert>
                </div>
                <div class="col-sm-12 divInputs">
                    <input :class="dismissCountDown != 0 ? 'border boder-warning' : ''" class="form-control inputLogin"
                        required v-model="form.username" type="text" placeholder="Usuário" />
                </div>
                <div class="col-sm-12 divInputs">
                    <input :class="dismissCountDown != 0 ? 'border boder-warning' : ''" class="form-control inputLogin"
                        required v-model.lazy="form.password" :type="tipo" placeholder="Senha" />
                    <span class="iconeOlhinho" @click="trocaIcone()">
                        <base-icon :icon="icone" size="16" />
                    </span>
                </div>
                <div class="col-sm-12 divButtonLogin">
                    <b-button :loading="true" type="submit" class="buttonLogin" :disabled="loadingBtn">
                        <b-spinner v-show="loadingBtn" small label="Small Spinner" />
                        <span v-show="!loadingBtn"> LOGIN </span>
                    </b-button>
                </div>
                <div class="col-sm-12 divButtonForgot">
                    <a class="buttonForgotPassword" @click="resetPassword">Esqueceu a senha?</a>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import baseIcon from "@/components/Atom/Icon/BaseIcon.vue";
import { conectionError } from "@/Services/Helpers/swellHeper.js";
import { mapActions } from "vuex";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import store from "../../store/index";
export default {
    components: {
        baseIcon,
    },
    data() {
        return {
            urlLogin: process.env.VUE_APP_ROOT_API + "/login",
            mensagemErro: "",
            tipo: "password",
            desativaBtn: true,
            icone: mdiEye,
            mdiEye: mdiEye,
            mdiEyeOff: mdiEyeOff,
            loadingBtn: false,
            contaErro: 0,
            dismissSecs: 5,
            dismissCountDown: 0,
            form: {
                username: "",
                password: "",
            },
        };
    },
    mounted() {
        this.toggleBodyClass("addClass", "black");
    },
    destroyed() {
        this.toggleBodyClass("removeClass", "black");
    },
    methods: {
        toggleBodyClass(addRemoveClass, className) {
            const el = document.body;
            if (addRemoveClass === "addClass") {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },
        created() {
            this.init();
        },
        init() {
            store.dispatch("isMobile");
            if (this.auth.isAuthenticated()) {
                this.route.Navigate("dashboard");
            }
        },
        ...mapActions(["refresh"]),

        /**
         * faz um toogle no input de senha para
         * torna-la visivel ou em password de novo
         * @author Marcola
         */
        trocaIcone(icone) {
            if (this.icone != this.mdiEyeOff) {
                this.icone = this.mdiEyeOff;
                this.tipo = "text";
            } else {
                this.icone = this.mdiEye;
                this.tipo = "password";
            }
        },

        /**
         * Método para verificar autenticidade do login do usuário
         * Chama o auth.Service para fazer a request com o retorno é feita a verificação se houve
         * algum erro se sim a variável mensagemErro recebe a mensagem de erro a ser mostrada
         * @param Objeto form = usuário e senha
         * @return String
         *  mensagemErro = mensagem de erro a ser mostrada
         *  conectionError = Swal mostrando mensagem padrão após 5 tentativas falhadas
         * @author Aparentemente Duan, alterado pelo Vitor e Lucas Eduardo 🐨
         */
        async login() {
            this.dismissCountDown = false;
            this.loadingBtn = true;
            var result = await this.auth.login(
                this.form.username.toString(),
                this.form.password.toString()
            );
            
            if (result.status) {
                this.route.Navigate("dashboard");
                store.dispatch("refresh");
                this.loadingBtn = false;
            } else {
                this.form.password = "";
                this.contaErro += 1;
                this.dismissCountDown = this.dismissSecs;
                this.mensagemErro = result.mensagem.message;
                // bem melhor se fosse com status http
                // anotações pra na proxima deixar fazer do inicio
                // ja com status http, pq agr n da de mudar sem ter
                // que mudar em outrso lugares
                
                if (result.mensagem.error == "Unauthorized") {
                    var msgUnauthorized = `
                        <p>
                            <strong> 
                                O seu acesso está inativo. 
                            </strong>
                            Entre em contato e regularize a sua situação.
                        </p>
                    `;
                    conectionError(msgUnauthorized, "error", "Oops");
                } else if (this.contaErro >= 5) {
                    var msg = `
                        <p>
                            Parece que você esta tendo problemas com o login. <br>
                            Verifique sua senha ou usuário e tente novamente, 
                            caso tenha esquecido a senha clique em "Esqueceu a senha?"
                        </p>
                    `;
                    conectionError(msg);
                }
                this.loadingBtn = false;
            }
        },
        resetPassword() {
            this.route.Navigate("passwordReset");
        },
    },
};
</script>

<style scoped>
.iconeOlhinho {
    cursor: pointer;
    position: relative;
    justify-content: center;
    left: 46%;
    margin-right: 6px;
    bottom: 30px;
}

.divCentralLogin>.divImgLogo>.imgLogo {
    width: 40%;
}

.divCentralLogin>.divImgLogo {
    margin: auto;
}

.imgLogo {
    width: 40%;
    height: auto;
}

.divCentralLogin>.divInputs>.inputLogin {
    background: transparent;
    border: 1px solid #0f4352;
    border-radius: 0px;
    margin-top: 10px;
    color: #fff;
}

.divCentralLogin>.divButtonLogin>.buttonLogin {
    border-radius: 0px;
    text-align: center;
    width: 60%;
    background: #2e759a;
    margin-top: 15px;
    border: 1px solid #0f4352;
    color: #fff;
    font-weight: bold;
    /* border-bottom: 1px solid #d5d5d5; */
    margin-bottom: 10px;
    cursor: pointer;
}

.divCentralLogin>.divButtonLogin>.buttonLogin:disabled {
    cursor: not-allowed;
}

.divCentralLogin>.divButtonForgot>.buttonForgotPassword {
    background: transparent;
    border: none;
    margin-top: 15px;
    color: #33bbde !important;
    font-weight: bold;
    /* border-bottom: 1px solid #33bbde; */
    margin-bottom: 15px;
    text-decoration: none;
    font-size: 12px;
    cursor: pointer;
}

.divCentralLogin {
    margin: auto;
    border: 1px solid #0f4352;
    vertical-align: center;
    box-shadow: 0px 0px 30px 0px #000;
    padding-bottom: 10px;
    background: rgba(0, 0, 0, 0.842);
}

.login {
    display: grid;
    height: 95vh;
    background-image: url("../../assets/fundoEagleLogin.png") !important;
    background-repeat: no-repeat !important;
    background-size: cover;
    background-position: center;
}

div #naveg {
    padding: 0px !important;
}

@font-face {
    font-family: nexabold;
    src: url("../../assets/fontes/Nexa-Bold.otf");
}

@font-face {
    font-family: nexabook;
    src: url("../../assets/fontes/Nexa-Book.otf");
}

body {
    /* background-color: #252526 !important; */
    background-size: cover;
    background-repeat: repeat-y;
    font-family: "nexabook", sans-serif !important;
}
</style>
