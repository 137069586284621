<template>
    <panelEagle id='dashboardJornada' :loading='loadingPanel'>
       
    </panelEagle>
</template>
<script>
import Vue  from 'vue'
import {mdiArrowUpThick, mdiArrowDownThick} from '@mdi/js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { DateTime } from 'luxon'
export default Vue.extend({
	name:'DashboardJornada',
	
	components:{
		'panelEagle'           : require('@/components/Atom/Panel/PanelEagle').default,
		'indicador'            : require('@/components/Atom/Grafico/Indicador').default,
		'chartsColumn'         : require('@/components/Atom/Grafico/ChartsColumn').default,
		'chartsPizzaDashboard' : require('@/components/Atom/Grafico/ChartsPizzaDashboard').default,
		// 'simpleButton'         : require('@/components/Atom/Buttons/SimpleButton').default,
	},

	data(){
		return {
			mdiArrowUpThick: mdiArrowUpThick,
			mdiArrowDownThick: mdiArrowDownThick,
			preventivaCorretivasDados    : [],
			preventivaCorretivasLabel    : [],
            preventivaCorretivas         : [],
            labelGastosMensais           : [],
            dadosGastosMensais           : [],
            gastosMensais                : [],
			rankingServicosLabel         : [],
			rankingServicosDados         : [],
            veiculosManutencaoLabel      : [],
            veiculosManutencaoDados      : [],
			loadingPanel                 : false,
			semrankingServicosDados      : false,
			semValueGraficoColaboradores : false,
			semPreventivaCorretivasDados : false,
            semDadosGastosMensais        : false,
            semVeiculosManutencaoDados   : false,
			totalManutencoes :{
				'dados'   : '',
				'message' : ''
			},
            semTotalManutencoes: false,
			gastosTotais : {
				'dados'   : '',
				'message' : ''
			},
			semGastosTotais: false,
			veiculosManutencao : {
				'dados'   : '',
				'message' : ''
			},
			semVeiculosManutencao: false,
			manutencoesVencidas : {
				'dados'   : '',
				'message' : ''
			},
            semManutencoesVencidas: false,
			manutencoesProgramar : {
				'dados'   : '',
				'message' : ''
			},
			semManutencoesProgramar: false,
            veiculosCorretivas: {
                'dados'   : '',
                'message' : '',
            },
            semManutencoesCorretivas: false,
			dataAtualizacao: '',
		}
	},

	methods:{

		/**
         * @description Método ajustar os dados como o indicador de
         *  total de manutenções precisa
         * @param  {array} totalManutencoes - 
         *  dados ( total de manutenções )
         *  message ( variação correspondente ao mês passado )
         * @author Vitor Hugo 🐨
        */
		trataDadosTotalManutencao(totalManutencoes){
			if(totalManutencoes[0] != undefined){
				this.totalManutencoes = JSON.parse(totalManutencoes[0].ddvalores)
				this.semTotalManutencoes = false
			} else {
				this.semTotalManutencoes = true
			}
		},

		/**
         * @description Método ajustar os dados como o indicador de
         *  gastos totais precisa
         * @param  {array} gastosTotais - 
         *  dados ( gastos totais )
         *  message ( variação correspondente ao mês passado )
         * @author Vitor Hugo 🐨
        */
		trataDadosGatosTotal(gastosTotais){
            if(gastosTotais[0] != undefined){
                this.gastosTotais = JSON.parse(gastosTotais[0].ddvalores)
				this.semGastosTotais= false
			} else {
				this.semGastosTotais = true
			}
		},

        /**
         * @description Método ajustar os dados como o indicador de
         *  veiculos manutencao falta precisa
         * @param  {array} veiculosManutencao - 
         *  dados ( total de veículos em manutenção )
         *  message ( variação correspondente ao mês passado )
         * @author Vitor Hugo 🐨
        */
		trataDadosVeiculoManutencao(veiculosManutencao){
			if(veiculosManutencao[0] != undefined){
				this.veiculosManutencao = JSON.parse(veiculosManutencao[0].ddvalores)
				this.semVeiculosManutencao = false
			} else {
				this.semVeiculosManutencao = true
			}
		},

        /**
         * @description Método ajustar os dados como o indicador de
         *  manutenções vencidas precisa
         * @param  {array} manutencoesVencidas - 
         *  dados ( total de manutenções vencidas )
         *  message ( variação correspondente ao mês passado )
         * @author Vitor Hugo 🐨
        */
		trataDadosManutencoesVencidas(manutencoesVencidas){
			if(manutencoesVencidas[0] != undefined){
				this.manutencoesVencidas = JSON.parse(manutencoesVencidas[0].ddvalores)
				this.semManutencoesVencidas = false
			} else {
				this.semManutencoesVencidas = true
			}
		},

        /**
         * @description Método ajustar os dados como o indicador de
         *  manutenções programar precisa
         * @param  {array} manutencoesProgramar - 
         *  dados ( total de manutenções a programar )
         *  message ( variação correspondente ao mês passado )
         * @author Vitor Hugo 🐨
        */
		trataDadosManutencoesProgramar(manutencoesProgramar){
			if(manutencoesProgramar[0] != undefined){
				this.manutencoesProgramar = JSON.parse(manutencoesProgramar[0].ddvalores)
				this.semManutencoesProgramar = false
			} else {
				this.semManutencoesProgramar = true
			}
		},

		/**
         * @description Método ajustar os dados como o gráfico de
         *  preventiva corretivas precisa
         * @param  {array} preventivaCorretivas - 
         *  ddvalores ( JSON com os dados do gráfico )
         * @author Vitor Hugo 🐨
        */
		trataDadosPreventivaCorretiva(preventivaCorretivas){
			if(preventivaCorretivas[0] != undefined){
				this.preventivaCorretivas = JSON.parse(preventivaCorretivas[0].ddvalores)
				this.preventivaCorretivasLabel = this.preventivaCorretivas.label
				this.preventivaCorretivasDados = this.preventivaCorretivas.dados
				this.semPreventivaCorretivasDados = false
			} else {
				this.semPreventivaCorretivasDados = true
			}
		},

        /**
         * @description Método ajustar os dados como o gráfico de
         *  gastos mensais precisa
         * @param  {array} gastosMensais - 
         *  ddvalores ( JSON com os dados do gráfico )
         * @author Vitor Hugo 🐨
        */
		trataDadosGastosMensais(gastosMensais){
			if(gastosMensais[0] != undefined){
				this.gastosMensais = JSON.parse(gastosMensais[0].ddvalores)
				this.labelGastosMensais = this.gastosMensais.label
				this.dadosGastosMensais = this.gastosMensais.dados
				this.semDadosGastosMensais = false
			} else {
				this.semDadosGastosMensais = true
			}
		},

		/**
         * @description Método ajustar os dados de gráfico pizza de
         *  rankingServicos ativos
         * @param  {array} rankingServicos - 
         *  dados ( quantidade de rankingServicos ativos )
         *  message ( variação correspondente ao mês passado )
         * @author Vitor Hugo 🐨
        */
		trataDadosRankingServicos(rankingServicos){
			if(rankingServicos[0] != undefined){
				this.rankingServicos = JSON.parse(rankingServicos[0].ddvalores)
				if(this.rankingServicos.data[0] > 0 &&
                    this.rankingServicos.data[1] > 0){
					this.rankingServicosLabel = this.rankingServicos.label
					this.rankingServicosDados = this.rankingServicos.data
					this.semrankingServicosDados = false
				} else {
					this.semrankingServicosDados = true
				}
			} else {
				this.semrankingServicosDados = true
			}
		},

        /**
         * @description Método ajustar os dados como o indicador
         *  de manutenções corretivas precisa
         * @param  {array} veiculosCorretivas - 
         *  dados ( quantidade de veiculosCorretivas )
         *  message ( variação correspondente ao mês passado )
         * @author Vitor Hugo 🐨
        */
        trataManutencoesCorretivas(veiculosCorretivas){
            if(veiculosCorretivas[0] != undefined){
				this.veiculosCorretivas = JSON.parse(veiculosCorretivas[0].ddvalores)
				this.semManutencoesCorretivas= false
			} else {
				this.semManutencoesCorretivas = true
			}
        },

        /**
         * @description Método ajustar os dados como a grade
         *  de veículos com mais manutenções corretivas
         * @param  {array} veiculosMaisManutencao - 
         *  dados ( 5 veículos com mais manutenções corretivas )
         *  message ( variação correspondente ao mês passado )
         * @author Vitor Hugo 🐨
        */
        trataVeiculosManutencao(veiculosMaisManutencao){
            if(veiculosMaisManutencao[0] != undefined){
				this.veiculosMaisManutencao = JSON.parse(veiculosMaisManutencao[0].ddvalores)
				if(this.veiculosMaisManutencao.data[0] > 0 &&
                    this.veiculosMaisManutencao.data[1] > 0){
					this.veiculosManutencaoLabel = this.veiculosMaisManutencao.label
					this.veiculosManutencaoDados = this.veiculosMaisManutencao.data
					this.semVeiculosManutencaoDados = false
				} else {
					this.semVeiculosManutencaoDados = true
				}
			} else {
				this.semVeiculosManutencaoDados = true
			}
        },

		/**
         * @description Método faz a requisição para trazer os dados da dashboard
         *  chama os métodos referentes a cada gráfico da tela para os dados serem tatados
         * @author Vitor Hugo 🐨
        */
		buscaDadosDashboard(){
			this.loadingPanel = true
			new HttpRequest().Post('/manutencoes/dashboard/gerar')
				.then((dados)=>{
					if(dados.code == 200){
						var dado = dados.data
						this.trataDadosTotalManutencao(
							dado.totalManutencoes)
                        this.trataDadosGatosTotal(
							dado.gastosTotais)
                        this.trataDadosVeiculoManutencao(
							dado.veiculosManutencao)
                        this.trataDadosManutencoesVencidas(
							dado.manutencoesVencidas)
                        this.trataDadosManutencoesProgramar(
							dado.manutencoesProgramar)
                        this.trataDadosPreventivaCorretiva(
							dado.preventivaCorretivas)
                        this.trataDadosGastosMensais(
							dado.gastosMensais)
                        this.trataManutencoesCorretivas(
							dado.veiculosCorretivas)
                        this.trataVeiculosManutencao(
							dado.veiculosMaisManutencao)
                        this.trataDadosRankingServicos(
							dado.rankingServicos)
                        if(dado.dataHoraAtualizacao != null){
                            this.dataAtualizacao = DateTime
                                .fromFormat(
                                    dado.dataHoraAtualizacao.dddatahoraevento,
                                    'yyyy-LL-dd HH:mm:ss')
                                .toFormat('dd/LL/yyyy HH:mm:ss')
                        }
						this.loadingPanel = false
					} else {
						this.toastShow('Entre em contato com o suporte',
							'danger',
							'Erro ao gerar gráfico')
						this.loadingPanel = false
					}
				})       
		},

		toastShow(msg, type, title = ''){
			this.$bvToast.toast(`${msg}`, {
				autoHideDelay: 3000,
				variant: type,
				title: title
			})
		},

		atualizaDash(){
			this.preventivaCorretivasDados = []
            this.dadosGastosMensais = []
			this.rankingServicosDados = []
			this.totalManutencoes.dados = ''
			this.totalManutencoes.message = ''
			// this.gastosTotais.dados = ''
			// this.gastosTotais.message = ''
			this.veiculosManutencao.dados = ''
			this.veiculosManutencao.message = ''
            this.manutencoesVencidas.dados = ''
			this.manutencoesVencidas.message = ''
            this.manutencoesProgramar.dados = ''
			this.manutencoesProgramar.message = ''
			this.buscaDadosDashboard()
		},
	},

	mounted(){
		this.buscaDadosDashboard()
	}
})


</script>
<style lang="scss">
#dashboardJornada{
    .backgound-gray{
        background: #f8f8f8;
        box-shadow: 1px 1px 1px 1px #e0e0e0;
        height: 100%;
        .font{
            font-size: 10px;
        }
        div{
            padding-top: 5px;
        }
    }
    
    .indicador{
        height: 85px;
    }
    .indicadorLista{
        height: 172px;
    }
    #rankingServicosDados{
        height: 100%;
    }
    .backgoundLista{
        background: #f8f8f8;
        box-shadow: 1px 1px 1px 1px #e0e0e0;
    }
}

</style>
