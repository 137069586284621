<template>
	<modalEagle @modalOpen="resetarVariaveis" title="Copiar rota existente" id="modal-copiar-rota">
		<slot slot="modalBody">
			<b-overlay :show="showOverlay" rounded="sm">
				<div class="col-sm-12 nopadding row">
					<div class="col-sm-4 nopadding">
						<inputSingleDay @changeInput="changeDataRota" name="inputDataRota">
						</inputSingleDay>
					</div>
					<div class="col-sm-4 nopadding">
						<selectAll :loading="loadingVeiculos" ref="refVeiculo" @changeSelect="changeVeiculosRota"
							nameForRadio="selectPlaca" :isMultiple="false" :optionsArray="arrayPlacasOpt"
							:labels="[{ indexDFH: 'V', description: 'Veiculo' }]">
						</selectAll>
					</div>
					<div class="col-sm-4 nopadding">
						<selectAll :loading="loadingRota" :disabled="!selectedVeiculo" ref="refRota" @changeSelect="changeRotaRota"
							nameForRadio="selectPlaca" :isMultiple="false" :optionsArray="arrayRotasOpt"
							:labels="[{ indexDFH: 'R', description: 'Rota' }]">
						</selectAll>
					</div>
				</div>
				<div class="col-sm-12 nopadding mt-4">
					<tableListagemModal :hasAction="false" :titles="titlesTable" :data="itensRota">
					</tableListagemModal>
				</div>
			</b-overlay>
		</slot>
		<slot slot="modalFooter">
			<simpleButton :icon="mdiCloseThick" @click="cancelButton" text="Cancelar" event="click" type="red">
			</simpleButton>
			<simpleButton :disabled="itensRota.length && showOverlay" :icon="mdiCheckBold" @click="copiarRota" text="Confirmar"
				event="click" type="blue">
			</simpleButton>
		</slot>
	</modalEagle>
</template>
<script lang="js">
import Vue from 'vue'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { conectionError } from '@/Services/Helpers/swellHeper.js'
import { mdiCheckBold, mdiCloseThick } from '@mdi/js'

export default Vue.extend({
	components: {
		selectAll: require('../Select/SelectAll').default,
		modalEagle: require('../Modal/ModalEagle').default,
		inputSingleDay: require('../Datas/InputSingleDay').default,
		tableListagemModal: require('../Table/TableListagemModal').default,
		simpleButton: require('../../../components/Atom/Buttons/SimpleButton').default,
	},

	props: {
		clientCodigo: {
			type: [String, Number],
			required: true,
			default: ''
		}
	},

	data() {
		return {
			loadingRota: false,
			loadingVeiculos: false,
			mdiCloseThick: mdiCloseThick,
			mdiCheckBold: mdiCheckBold,
			showOverlay: false,
			arrayPlacasOpt: [],
			arrayRotasOpt: [],
			dataRota: null,
			selectedVeiculo: null,
			selectedRota: null,
			itensRota: [],
			titlesTable: [
				{ 'number': 'pontoDescricao', 'name': 'Ponto' },
				{ 'number': 'documento', 'name': 'Pedido' },
				{ 'number': 'volumes', 'name': 'Volumes' },
				{ 'number': 'cubagem', 'name': 'Cubagem' },
				{ 'number': 'peso', 'name': 'Quilos' },
				{ 'number': 'valor', 'name': 'Valor' },
			]

		}
	},

	methods: {
		cancelButton() {
			this.$bvModal.hide('modal-copiar-rota')
		},

		async changeDataRota(data) {
			this.loadingVeiculos = true
			this.$refs.refVeiculo.clearAll()
			this.$refs.refRota.clearAll()
			this.dataRota = data
			this.arrayPlacasOpt = []
			this.arrayRotasOpt = []
			this.itensRota = []
			var dados = await new HttpRequest()
				.Post('/roteirizador/rota/manual/veiculos/dia',
					{
						'data': this.dataRota,
						'cliente': this.clientCodigo
					}
				)
			this.arrayPlacasOpt = dados.data.veiculo
			this.loadingVeiculos = false
		},

		async changeVeiculosRota(carro) {
			this.loadingRota = true
			this.selectedVeiculo = carro[0]
			this.arrayRotasOpt = []
			this.itensRota = []
			this.$refs.refRota.clearAll()
			if (carro[0]) {
				var dados = await new HttpRequest()
					.Post('/roteirizador/rota/manual/rota/dia',
						{
							'data': this.dataRota,
							'veiculo': this.selectedVeiculo,
							'cliente': this.clientCodigo
						})
				this.arrayRotasOpt = dados.data.rotas
			}
			this.loadingRota = false
		},

		async changeRotaRota(rota) {
			this.showOverlay = true
			this.selectedRota = rota[0]
			this.itensRota = []
			if (rota[0]) {
				var dados = await new HttpRequest()
					.Post('/roteirizador/rota/manual/rota/itens',
						{
							'data': this.dataRota,
							'veiculo': this.selectedVeiculo,
							'rota': this.selectedRota,
							'cliente': this.clientCodigo
						})
				this.itensRota = dados.data.itens
			}
			this.showOverlay = false
		},

		resetarVariaveis() {
			this.selectedVeiculo = null
			this.selectedRota = null
		},

		async copiarRota() {
			this.showOverlay = true
			try {
				var data = await new HttpRequest()
					.Post('/roteirizador/rota/manual/rota/copiar',
						{ 'itens': this.itensRota, 'rota': this.selectedRota }
					)
				this.$emit('rechargeList', data.data.status.original)
				this.$bvModal.hide('modal-copiar-rota')
			} catch {
				conectionError()
			}
			this.showOverlay = false
		}
	},
})
</script>