<template>
  <div class='col-sm-12 nopadding right' align="right">
    <slot name='left'></slot>
    <slot name='importar'>
      <simple-button 
        v-if='getImportar()'
        text='Importar' 
        type='blue' 
        event=click 
        :icon='iconImportar'
        @click="importar"
      />
    </slot>
    <simple-button 
      v-if='getCadastrar()'
      text="Novo" 
      :icon='iconNovo'
      type="green" 
      id="btnNovo" 
      event="click"
      @click="novo" 
      v-bind='$attrs' 
      v-on='$listeners'
    />
    <slot name='right'></slot>
  </div>
</template>

<script>
import Vue from 'vue'
import {mdiCloudUpload, mdiPlus} from '@mdi/js'
import {mapGetters} from 'vuex'
import SimpleButton from '@/components/Atom/Buttons/SimpleButton'
export default Vue.extend({
  components: {
    SimpleButton,
  },
  inheritAttrs: false,
  data() {
    return {
      iconImportar: mdiCloudUpload,
      iconNovo:mdiPlus,
    }
  },
  methods: {
    importar() {
      this.$emit('importar')
    },
    novo() {
      this.$emit('novo')
    },

    ...mapGetters(['getImportar', 'getCadastrar']),
  },
})
</script>

<style lang="scss" scoped>
  ;
</style>