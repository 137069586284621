<template>
	<modal-eagle
	id='modal-files'
	:title='tituloModal'
	@modalClose='modalClose'>
		<template #modalBody>
			<div id='modal-files'>
				<div v-if='carregado'>
					<div
					v-for='file in files'
					:key='`${file.cadcodigo}_doc_ajuste`'
					:title='file.name'>
						<iframe
						v-if='file.type === "pdf"'
						class='pdf-display'
						:src='`${root}${file.path}`'/>
						<img
						v-else
						class='img-ajuste'
						:src='`${root}${file.path}`'>
					</div>
				</div>
				<!-- não é idiota se funciona! -->
				<b-spinner v-else/>
			</div>
		</template>
		<template #modalFooter>
			<simpleButton
			:icon='mdiCloseThick'
			@click='cancelButton'
			text='Fechar'
			event='click'
			type='red'/>
		</template>
	</modal-eagle>
</template>

<script>
const urlBase = '/checkpoint/relatorios/extrato/'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { mdiCloseThick } from '@mdi/js'
export default {
	name:'ModalFiles',
	components:{
		simpleButton:require('@/components/Atom/Buttons/SimpleButton').default,
		modalEagle : require('@/components/Atom/Modal/ModalEagle.vue').default,
	},
	data(){
		return{
			mdiCloseThick:mdiCloseThick,
			root : process.env.VUE_APP_ROOT,
			tituloModal:'Documentos',
			files:[],
			loading:false,
			carregado:false,
		}
	},
	methods:{
		modalClose(){
			this.file = []
			this.carregado = false
		},
		
		/**
		 * @param {number} codigo - código do AJUSTE
		 * @description inicia a modal de vizualização de
		 * arquivos.
		 */
		iniciaModalFiles(codigo){
			this.procuraInformacoesDoc(codigo)
			this.$bvModal.show('modal-files')
		},
		cancelButton(){
			this.$bvModal.hide('modal-files')
		},

		/**
		 * @param {number} codigo - código do AJUSTE.
		 * @description busca os documentos do ajuste.
		 */
		procuraInformacoesDoc(codigo){
			var url = urlBase+'get/doc/'+codigo
			new HttpRequest().Get(url).then((res)=>{
				this.files = res.data.files
			}).finally(()=>{
				this.carregado = true
			})
		},
	}
}
</script>

<style lang="scss" scoped>
#modal-files{
	flex:auto;
	align-content: center;
	display: flex;
	padding:10px;
	justify-content: center;
	.img-ajuste{
		max-height: 790px;
		max-width: 780px;
		// border: 15px dotted salmon;
		// rotate: 3deg;
	}
	.pdf-display{
		height: 790px;
		width: 700px;
	}
}
</style>