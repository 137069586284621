<template>
    <panelEagle id="tempoFuncionamento" :loading="loadingPanel">
        <div class="col-sm-12 nopadding ">
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiTimelineClock" titulo="Tempo de funcionamento" ></tituloPage>
                </div>
                <div class="col-sm-6 divDontPrint">
                    <basicButtonsRelatoriosEIG
                        :disabled="$v.veiculosArray.$invalid"
                        :loading="loadingButton"
                        @exportarRelatorio="exportarRelatorio" 
                        @gerarRelatorio="gerarRelatorio"
                        :disabledDropdown="$v.dadosTabela.$invalid">
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
             <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12"><hr></div>
                    <div class="col-sm-12 row nopadding">
                        <div class="col-sm-3 nopadding">
                            <inputRangeNoLimits
                                :isObrigatorio='true'
                                @changeInput="changeData" 
                                id="dataInicioAcionamento" 
                                name="dataInicioAcionamento">
                            </inputRangeNoLimits>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll
                                @changeSelect="SelectEmpresas" 
                                :isMultiple="true" 
                                nameForRadio="radiosEmpresas" 
                                :labels="labelsSelectEmpresa" 
                                :optionsArray="optionsSelectEmpresa" 
                                firstSelected="E" 
								:selected="selectedEmpresa"
                                :hasSelectAll="true">
                            </selectAll>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll
                                @selectCriado="selectCriadoVeiculos" 
                                @changeSelect="SelectVeiculos" 
                                :isMultiple="true" 
                                nameForRadio="radiosVeiculos" 
                                :labels="labelsSelectVeiculos" 
                                :optionsArray="optionsSelectVeiculos" 
                                firstSelected="V" 
                                :loading="carregandoVeiculos"
                                :disabled="$v.empresasArray.$invalid"
                                :hasSelectAll="true">
                            </selectAll>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll 
                                @changeSelect="SelectRegioes" 
                                :isMultiple="true" 
                                nameForRadio="radiosRegiao" 
                                :labels="labelsSelectRegioes" 
                                :optionsArray="optionsSelectRegioes" 
                                firstSelected="RE" 
                                ref="refRegiao"
                                :loading="carregandoVeiculos"
                                :disabled="$v.empresasArray.$invalid"
                                :hasSelectAll="true">
                            </selectAll>
                        </div> 
                    </div>
                </slot>
            </slideUpAndDown>
        </div>
        <div class="col-sm-12"><hr></div>
        <!-- htmlTempoFuncionamento -->
        <tableRelatorio htmlRel="">
            <slot slot="thead">
                <tr>
                    <th>Ligou</th>
                    <th>Desligou</th>
                    <th>Tempo</th>
                    <th>Horímetro Início</th>
                    <th>Horímetro Fim</th>
                    <th>Total</th>
                    <th>Ponto</th>
                    <th>Região</th>
                    <th>Referência(Mapa)</th>
                    <th>Sinal GPS</th>
                    <th class="divDontPrint">Localizar</th>
                </tr>
            </slot>
            <slot slot="tbody">
                <template v-for="(placas, placa) in  dadosTabela">
                    <tr class="backColorTdPDF" :key="placa+'k1'">
                        <td class="nopadding" colspan="11">
                            <span class="newbadge">{{placa}}</span>
                        </td>
                    </tr>
                    <template v-for="(datas, data) in placas">
                        <tr :key="data+placa+'k2'" v-if="true">
                            <td colspan="11">
                                {{data}}
                            </td>
                        </tr>
                        <template v-for="(dado, idx) in datas.dados">
							<!-- <tr :key="'special'+placa+data+idx+'k3'">
								<td colspan="11">
									{{placa+data}}
									{{dad.dados}}
								</td>
							</tr> -->
							<tr :key="idx+''+dado.inicio+''+data+placa" 
								v-if="true"
								:class="dado.sinal == 0 ? 'danger' : ''">
								<td>{{dado.inicio}}</td>
								<td>{{dado.fim}}</td>
								<td>{{dado.tempo}}</td>
								<td>{{dado.horimetroini}}</td>
								<td>{{dado.horimetrofim}}</td>
								<td>{{dado.horimetrototal}}</td>
								<td>{{dado.ponto}}</td>
								<td>{{dado.regiao}}</td>
								<td>{{dado.referencia}}</td>
								<template v-if="dado.sinal == 1">
									<td>OK</td>
								</template>
								<template v-else-if="dado.sinal == 0">
									<td>Inválido</td>
								</template>
								<template v-else>
									<td></td>
								</template>
								<td class='divDontPrint'> 
									<a  title='Clique para ver local no mapa' 
										@click="clickLinkMapa(dado.coordenadas)">
											<baseIcon size="18" class="blueIconLocalizar" :icon="mdiTarget"/>
									</a>
								</td>
							</tr>
                        </template>
						<tr :key="'footer'+data+placa" v-if="true">
							<td colspan="3">
								<b>Total do Dia: {{datas.totais.tempo}}</b>
							</td>
							<td colspan="8">
								<b>Total Horímetro: {{datas.totais.horimetro}} hora(s)</b>
							</td>
						</tr>
                    </template>
                </template>
                <br>
                <statusInformation 
                    v-show="mostraStatusBar" 
                    typeBar="tr" 
                    :statusBar="statusbar">
                </statusInformation>
            </slot>
        </tableRelatorio>
    </panelEagle>
</template>

<script lang="js">
import Vue from 'vue'
import {EmpresasService} from '@/Services/auth/Empresas.service'
import {HttpRequest} from '@/Services/auth/HttpRequest.Service'
import {FiltrosService} from '@/Services/filtros/filtros.Service'
import {mdiTimelineClock, mdiTarget } from '@mdi/js'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default Vue.extend({
	name:'TempoFuncionamento',
	validations:{
		empresasArray : { required },
		veiculosArray : { required },
		dadosTabela   : { required },
	},
	components:{
		baseIcon:require('@/components/Atom/Icon/BaseIcon.vue').default,
		'tituloPage' : require('@/components/Atom/Header/Titulo').default,
		'selectAll' : require('@/components/Atom/Select/SelectAll').default,
		'panelEagle' : require('@/components/Atom/Panel/PanelEagle').default,
		'tableRelatorio' : require('@/components/Atom/Table/TableRelatorio').default,
		'inputRangeNoLimits' : require('@/components/Atom/Datas/InputRangeNoLimits').default,
		'slideUpAndDown' : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
		'statusInformation' : require('@/components/Atom/StatusInformation/StatusInformation').default,
		'basicButtonsRelatoriosEIG' : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
	},
	data(){
		return{
			mostraStatusBar:true,
			mdiTarget:mdiTarget,
			dadosTabela:[],
			mdiTimelineClock:mdiTimelineClock,
			loadingPanel:false,
			loadingButton:[false, false, false],
			labelsSelectEmpresa:[
				{indexDFH: 'E', description: 'Empresas*'},
			],
			optionsSelectEmpresa: new EmpresasService().Get(),
			labelsSelectVeiculos:[
				{indexDFH: 'V', description: 'Veiculos*'},
			],
			labelsSelectRegioes:[
				{indexDFH: 'RE', description: 'Regiões'},
			],
			statusbar: 'info',
			optionsSelectVeiculos: [],
			optionsSelectRegioes: [],
			empresasArray:[],
			veiculosArray:[],
			regioesArray:[],
			veiculosSelect: [],
			regioesSelect: [],
			dadosTempoAcionamento:[] ,
			dataSelecionada:'',
			tamanhoArrayTempoFuncionamento: 0,
			htmlTempoFuncionamento:'',
			carregandoVeiculos : false,
			selectedEmpresa: [],
		}
	},
	methods:{
        ...mapGetters(['getMaster']),

		clickLinkMapa(cord){
			window.open('http://maps.google.com/maps?q=loc:'+cord)
		},

		SelectEmpresas(event){
			this.empresasArray = event;
			this.veiculosSelect.clearAll();
			this.$refs.refRegiao.clearAll()
			this.dados_filtros();
		},
		SelectVeiculos(event){
			this.veiculosArray = event;
		},
		SelectRegioes(event){
			this.regioesArray = event;
		},
		selectCriadoVeiculos(select){
			this.veiculosSelect = select;
		},
		selectCriadoRegioes(select){
			this.regioesSelect = select;
		},
		changeData(event){
			this.dataSelecionada = event;
		},
            
		async dados_filtros(){
			this.carregandoVeiculos = true
			var dados = [];
			dados = await new FiltrosService().dados_filtros(this.empresasArray, ['V', 'RE'])
			this.optionsSelectVeiculos = dados['V'];
			this.optionsSelectRegioes = dados['RE'];
			this.carregandoVeiculos = false
		},
		async gerarRelatorio(){
			this.loadingPanel = true
			this.mostraStatusBar = true
			this.dadosTabela = []
			if(this.empresasArray.length > 0 && this.veiculosArray.length){
				this.htmlTempoFuncionamento = '';
				this.dadosTempoAcionamento = [];
				var dataTratadaParaBack = this.dataSelecionada.split('-');
				var data = await new HttpRequest()
					.Post('/telemetria/relatorios/tempo/funcionamento/gerar', 
						{
							'clientes': this.empresasArray, 
							'veiculos':this.veiculosArray, 
							'regioes': this.regioesArray, 
							'data_inicio': dataTratadaParaBack[0], 
							'data_fim': dataTratadaParaBack[1]
						});
				var dados = data.data;
				if(data.code == 200){
					this.tamanhoArrayTempoFuncionamento = dados.tempofuncionamento.length
					this.dadosTempoAcionamento = dados.tempofuncionamento
					this.htmlTempoFuncionamento = '';
					this.dadosTabela = dados.tempofuncionamento
					if(this.dadosTabela.length == 0)
						this.statusbar = 'error'
					else
						this.mostraStatusBar = false
				}else{
					this.htmlTempoFuncionamento = '';
					this.dadosTempoAcionamento = [];
					this.statusbar = 'error2'
				}
			}
			this.loadingPanel = false
		},
		/**
             * nao usar
             */
		montaRelatorio(dados){
			var html = ''
			for(var prefixo in dados){
				html += '<tr class="backColorTdPDF"><td class="nopadding" colspan="11"><span class="newbadge">'+prefixo+'</span></td></tr>';
				var datas = dados[prefixo];
				for(var data in datas){
					html += '<tr><td class="nopadding" colspan="11"><b>'+data+'</b></td></tr>';
					var linhas = datas[data];
					for(var linha in linhas){
						if(linha != 'tempoTotal'){
							let dado = linhas[linha].dados
							if(dado.sinal == 0)
								html += '<tr class="danger">'
							else
								html += '<tr>'
							html += '<td>'+dado.inicio+'</td>';
							html += '<td>'+dado.fim+'</td>';
							html += '<td>'+dado.tempo+'</td>';
							html += '<td>'+dado.ponto+'</td>';
							html += '<td>'+dado.regiao+'</td>';
							html += '<td>'+dado.referencia+'</td>';
							if(dado.sinal == 1){
								html += '<td>OK</td>'
								html += "<td class='divDontPrint'> <a title='Clique para ver local no mapa' onClick='window.open(\"http://maps.google.com/maps?q=loc:"+dado.coordenadas+"\",\"_blank\")'> <span class='flaticon-icon075 blueIconLocalizar'></span></a> </td>"
							}
							else if(dado.sinal == 0){
								html += '<td>Inválido</td>'
								html += "<td class='divDontPrint'> <a title='Clique para ver local no mapa' onClick='window.open(\"http://maps.google.com/maps?q=loc:"+dado.coordenadas+"\",\"_blank\")'> <span class='flaticon-icon075 blueIconLocalizar'></span></a> </td>"
							}
							else{
								html += '<td></td>'
								html += "<td class='divDontPrint' <a title='Clique para ver local no mapa' onClick='window.open(\"http://maps.google.com/maps?q=loc:"+dado.coordenadas+"\",\"_blank\")'> <span class='flaticon-icon075 blueIconLocalizar'></span></a> </td>"
							}
							html += '</tr>';
						}
					}
					html += '<tr>'
						html += '<td colspan="3"><b>Total do Dia: '+linhas['totais']['tempo']+'</b></td>';
						html += '<td colspan="8"><b>Total do Dia: '+linhas['totais']['horimetro']+'</b></td>';
					html += '</tr>';
				}
			}
			this.htmlTempoFuncionamento = html;
		},
		async exportarRelatorio(tipo){
			switch (tipo) {
				case 'pdf':
					this.loadingButton = [true, false, false]
					break;
				case 'xls':
					this.loadingButton = [false, true, false]
					break;
				default:
					this.loadingButton = [false, false, true]
					break;
			}
			var root =  process.env.VUE_APP_ROOT;
			var data = await new HttpRequest()
				.Post('/telemetria/relatorios/tempo/funcionamento/exportar', 
					{
						'arrayDados':JSON.stringify(this.dadosTempoAcionamento), 
						'tipo':tipo
					});
			window.open(root+'/'+data.data.local);
			this.loadingButton = [false, false, false]
		}
	},

	mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optionsSelectEmpresa
        }
    }
})
</script>

<style lang="scss">
    #tempoFuncionamento{
        .blueIconLocalizar{
            color: #428bca;
            cursor: pointer;
        }
        .danger{
            background-color: #ebcccc!important;
        }
    }
</style>