Filtros do painel de controle, dentro do mapa do Finder. eventos: 1- expandir => Emitido
quando o usuário clica no botão de expandir a tela(canto direito dos filtros) 2- filtrar
=> Emitido quando o usuário altera o texto do campo de busca 3- mudaFiltros => Emitido
quando qualquer um dos outros filtros é alterado. -> Emite um objeto contendo todos os
filtros e seus valores atuais 4- rastroGrupo => Envia o objeto com os filtros para a busca
de rastro de veículos

<template>
    <div class="d-flex justify-content-between filtrosMapa py-1">
        <div class="col-3 nopadding ml-2">
            <Multiselect
                placeholder="Selecione uma empresa"
                :multiple="true"
                :close-on-select="true"
                :options="optClientes"
                :allow-empty="true"
                track-by="value"
                label="description"
                v-model="modelCliente"
                id="seletorEmpresaMapa"
                selectLabel="•"
                selectedLabel="✔"
                deselectLabel="x"
                :disabled="loadingEmpresa || requisicaoViagem"
                :limit="1"
                :limitText="(c) => ` e mais ${c}`"
                @input="mudaCliente"
            >
                <template #noResult> Nenhum resultado encontrado </template>
                <template #noOptions> Nenhuma opção disponível </template>
                <template #clear>
                    <div v-if="modelCliente.length" class="multiselect__clear mr-4">
                        <b-button size="sm" variant="danger" @click="limpaSeletorCliente">
                            <base-icon size="16" :icon="iconClear" />
                        </b-button>
                    </div>
                </template>
            </Multiselect>
        </div>
        <!-- <div class='my-auto ignicao row'> -->
        <div class="ignicao">
            <span class="text-ig">Ignição:</span>
            <div class="checks-filtros">
                <b-checkbox
                    :disabled="loadingEmpresa"
                    :checked="false"
                    :unchecked-value="false"
                    :value="true"
                    v-model="checkIgLigada"
                    @change="igLigada"
                >
                    Ligada
                </b-checkbox>
                <b-checkbox
                    :disabled="loadingEmpresa"
                    :checked="false"
                    :unchecked-value="false"
                    :value="true"
                    v-model="checkIgDesligada"
                    @change="igDesligada"
                >
                    Desligada
                </b-checkbox>
            </div>
        </div>
        <div class="checks-filtros">
            <b-checkbox
                :disabled="loadingEmpresa"
                :checked="false"
                :unchecked-value="false"
                :value="true"
                v-model="checkTags"
                @change="mudaCheckTags"
            >
                Tags
            </b-checkbox>
            <b-checkbox
                :disabled="loadingEmpresa"
                :checked="false"
                :unchecked-value="false"
                :value="true"
                v-model="checkGrupo"
                @change="mudaCheckGrupo"
            >
                Grupos
            </b-checkbox>
        </div>
        <div class="fixedSize mr-2 nopadding">
            <Multiselect
                ref="filtroTagsGrupoPainel"
                :placeholder="checkTags ? 'Tags' : 'Grupo de Veiculos'"
                :multiple="checkTags"
                :close-on-select="true"
                :options="optSeletorGrupoTag"
                track-by="value"
                :disabled="seletorDisable || loadingEmpresa"
                label="description"
                selectLabel="•"
                selectedLabel="✔"
                deselectLabel="x"
                :allow-empty="true"
                :loading="buscandoGrupos"
                :limit="1"
                :limitText="(c) => ` e mais ${c}`"
                v-model="modelGrupo"
                @input="mudaGrupoVeiculos"
            >
                <template #noResult> Nenhum resultado encontrado </template>
                <template #noOptions> Nenhuma opção disponível </template>
                <template #clear>
                    <div
                        v-if="modelGrupo && modelGrupo.length && checkTags"
                        class="multiselect__clear mr-4"
                    >
                        <b-button size="sm" variant="danger" @click="limpaSeletorGrupo">
                            <base-icon size="16" :icon="iconClear" />
                        </b-button>
                    </div>
                </template>
            </Multiselect>
        </div>
        <div class="my-auto">
            <b-checkbox
                :disabled="
                    !filtrosDash.grupoVeiculo.length || loadingGrupo || loadingEmpresa
                "
                :checked="false"
                :unchecked-value="false"
                :value="true"
                v-model="checkGrupoVeiculos"
            >
                Rastro do Grupo
            </b-checkbox>
        </div>
        <div class="mr-1" v-if="!checkGrupoVeiculos" style="display: inline-flex">
            <input
                type="search"
                class="form-control"
                placeholder="Buscar"
                style="width: 300px"
                :disabled="!existeClienteSelecionado || requisicaoViagem"
                v-model="busca"
            />
            <b-button
                style="height: 41px"
                squared
                variant="outline-secondary"
                title="Buscar veículo"
                :disabled="!existeClienteSelecionado || !busca || loadBuscar || requisicaoViagem"
                @click="buscar"
            >
                <baseIcon v-if="!loadBuscar" :icon="iconSearch" size="18" />
                <b-spinner v-if="loadBuscar" small />
            </b-button>
            <!-- v-if="existeClienteSelecionado && busca && !loadBuscar" -->
            <b-button
                v-show="!loadBuscar"
                style="height: 41px"
                squared
                variant="danger"
                title="Limpar busca"
                :disabled="requisicaoViagem"
                @click="clearBuscar"
            >
                <baseIcon :icon="iconClear" size="18" />
            </b-button>
        </div>
        <div class="" v-if="checkGrupoVeiculos">
            <el-date-picker
                v-model="filtrosRastro.periodoRastro"
                :picker-options="pickerOptions"
                type="daterange"
                value-format="dd/MM/yyyy"
                style="max-width: 250px"
                format="dd/MM/yyyy"
                size="large"
                range-separator="-"
                start-placeholder=" "
                :disabled="loadingGrupo || loadingEmpresa"
                @change="mudaPeriodo"
                v-b-tooltip.hover.focus.left="{
                    variant: erroPeriodo ? 'danger' : 'secondary',
                }"
                title="Período máximo de 15 dias"
                end-placeholder=""
            />
            <b-button
                id="procuraRastro"
                squared
                v-show="checkGrupoVeiculos"
                class="ml-1 mb-1 botaoFiltro"
                @click="carregaRastroGrupo"
                :disabled="!filtrosRastro.periodoRastro || loadingGrupo || loadingEmpresa"
                :title="tituloBuscarRastro"
                v-b-tooltip
                variant="outline-secondary"
            >
                <b-spinner v-if="loadingGrupo" class="" small />
                <baseIcon v-else :icon="iconSearch" size="20" />
            </b-button>
        </div>
        <div class="ml-2 my-auto d-flex justify-content-end">
            <b-button
                squared
                class="mr-2 mb-1 botaoFiltro"
                variant="outline-secondary"
                @click="emiteExpandir"
            >
                <baseIcon :icon="iconResize" size="20" />
            </b-button>
        </div>
    </div>
</template>

<script>

import { EmpresasService } from "@/Services/auth/Empresas.service";
import { FiltrosService } from "@/Services/filtros/filtros.Service";
import { DateTime } from "luxon";
import { pickerOptions } from "@/components/Atom/Painel/datePickerHelper.js";
import { mdiResize, mdiMoveResizeVariant, mdiMagnify, mdiCloseThick } from "@mdi/js";
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";

export default {
    name: "FiltrosPainelControle",
    components: {
        Multiselect,
        baseIcon: require("@/components/Atom/Icon/BaseIcon.vue").default,
    },

    props: {
        tags: {
            type: Array,
            default: () => {
                return [];
            },
        },
        clienteSelecionado: {
            type: Array,
            default: () => {
                return [];
            },
        },
        loadingGrupo: {
            type: Boolean,
            default: false,
        },
        veiculoSelecionado: {
            type: String,
            default: "",
        },
        loadingEmpresa: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            erroPeriodo: false,
            buscandoGrupos: false,
            checkGrupoVeiculos: false,
            checkTags: false,
            checkGrupo: true,
            checkIgLigada: true,
            checkIgDesligada: true,
            pickerOptions: pickerOptions,
            iconResize: mdiResize,
            iconSearch: mdiMagnify,
            iconClear: mdiCloseThick,
            optSelectEmpresas: new EmpresasService().Get(),
            optSelectGrupo: [],
            checkboxOptionsIgnicao: [
                { value: "1", text: "Ligada" },
                { value: "0", text: "Desligada" },
            ],
            busca: "",
            modelGrupo: [],
            //Essa model meio que só serve pro multiselect funcionar
            modelCliente: [],
            filtrosDash: {
                cliente: [],
                ignicao: [0, 1],
                grupoVeiculo: [],
                filtrarTag: [],
            },
            rastroGrupo: [],
            filtrosRastro: {
                periodoRastro: [
                    DateTime.now().toFormat("dd/LL/yyyy"),
                    DateTime.now().toFormat("dd/LL/yyyy"),
                ],
            },
			tituloBuscarRastro: "Buscar rastro de grupo",
			loadBuscar: false,
        };
    },
    inject: ['mapaRequisicaoViagem'],
    methods: {
        ...mapGetters(["getMaster"]),
        igLigada(value) {
            if (value) this.filtrosDash.ignicao.push(1);
            else this.filtrosDash.ignicao = _.pull(this.filtrosDash.ignicao, 1);
            this.emiteFiltros();
        },

        igDesligada(value) {
            if (value) this.filtrosDash.ignicao.push(0);
            else this.filtrosDash.ignicao = _.pull(this.filtrosDash.ignicao, 0);
            this.emiteFiltros();
        },

        mudaCheckGrupo(value) {
            this.checkTags = false;
            this.modelGrupo = [];
            this.filtrosDash.filtrarTag = [];
            this.filtrosDash.grupoVeiculo = [];
            this.emiteFiltros();
            // 	let temTags = this.filtrosDash.filtrarTag.length > 0
            // 	let temGrupo = this.filtrosDash.grupoVeiculo.length > 0
            // 	let emitirEvento = temTags && !value || temGrupo
        },

        mudaCheckTags(value) {
            this.checkGrupo = false;
            this.modelGrupo = [];
            this.filtrosDash.filtrarTag = [];
            this.filtrosDash.grupoVeiculo = [];
            this.emiteFiltros();
            // 	let temTags = this.filtrosDash.filtrarTag.length > 0
            // 	let temGrupo = this.filtrosDash.grupoVeiculo.length > 0
            // 	let emitirEvento = temTags && !value || temGrupo
        },

        /**
         * @description reseta os filtros, setando o periodo do
         * rastro para a data de hoje.
         */
        resetaFiltros() {
            this.optSelectGrupo = [];
            this.filtrosRastro.periodoRastro = [
                DateTime.now().toFormat("dd/LL/yyyy"),
                DateTime.now().toFormat("dd/LL/yyyy"),
            ];
            this.modelGrupo = [];
            this.busca = "";
        },

        limpaSeletorCliente() {
            this.$emit("fecha-info");
            this.modelCliente = [];
        },

        limpaSeletorGrupo() {
            this.modelGrupo = [];
            this.filtrosDash.filtrarTag = [];
            this.mudaGrupoVeiculos(false);
        },

        /**
         * @description dispara um evento procurando pelo elemento com id
         * 'procuraRastro' e mostra um tooltip avisando que nenhum rastro
         * foi encontrado. Já seta um timeout para essa mesma mensagem
         * desaparecer e alterar para o title original
         */
        toolTipSemRastro() {
            this.tituloBuscarRastro = "Nenhuma informação encontrada";
            this.$root.$emit("bv::show::tooltip", "procuraRastro");
            setTimeout(() => {
                this.tituloBuscarRastro = "Buscar rastro de grupo";
                this.$root.$emit("bv::hide::tooltip", "procuraRastro");
            }, 5666);
        },

        /**
         * @description função que verifica se o intervalo selecionado pelo
         * usuário é de no máximo 15 dias, caso seja maior do que isso,
         * a função faz com que a tooltip do seletor fique vermelha e seleciona
         * os primeiros 15 dias depois da data inicial selecionada pelo usuário
         * @param {string[]} periodo array gerada pelo componente de
         * seleção de data
         * @author Gui 🍺
         */
        mudaPeriodo(periodo) {
            if (!periodo) return;
            var com = DateTime.fromFormat(periodo[0], "dd/LL/yyyy");
            var fim = DateTime.fromFormat(periodo[1], "dd/LL/yyyy");
            var dif = fim.diff(com, "days").toObject();
            if (dif.days > 15) {
                fim = com.plus({ days: 14 });
                this.filtrosRastro.periodoRastro[1] = fim.toFormat("dd/LL/yyyy");
                this.erroPeriodo = true;
                setTimeout(() => {
                    this.erroPeriodo = false;
                }, 5666);
                return;
            }
            this.erroPeriodo = false;
        },

        /**
         * @description o controle do tamanho da tabela em si esta
         * no painel de controle.
         * Essa função só controla o ícone que aparece no botão
         * e emite o evento em si. As duas funções agem em 'sincronia'
         * @author Gui 🍺
         */
        emiteExpandir() {
            if (this.iconResize === mdiResize) this.iconResize = mdiMoveResizeVariant;
            else this.iconResize = mdiResize;
            this.$emit("expandir");
        },

        /**
         * Única obrigação dessa função é emitir o evento
         * de alteração de filtros.
         * @author Gui 🍺
         */
        emiteFiltros() {
            this.$emit("mudaFiltros", this.filtrosDash);
        },

        /**
         * Emite o evento de busca de rastro junto com os
         * parâmetros de busca.
         * @author Gui 🍺
         */
        carregaRastroGrupo() {
            this.$root.$emit("bv::hide::tooltip", "procuraRastro");
            this.$emit("rastroGrupo", this.objRastroGrupo);
        },

        /**
         * @listens input seletor de empresas.
         * @param {object[]} value empresas selecionadas
         * @description o componente envia o objeto completo, então apenas
         * seleciono o value, código da empresa para ser "salvo". Verifica se um dos
         * valores selecionados é o Selecionar Todos.
         * @author Gui 🍺
         */
        mudaCliente(value) {
            this.filtrosDash.cliente = [];
            // this.filtrosDash.filtrarTag = []
            this.filtrosDash.grupoVeiculo = [];
            var seleciona_todos = value.some((v) => {
                return v.value === "SA";
            });
            if (seleciona_todos) {
                this.modelCliente = this.optSelectEmpresas;
            } else {
                this.$emit("fecha-info");
            }
        },

        emiteMudaCliente(value) {
            this.$emit("muda-cliente", value);
        },

        /**
         * @description guarda o código do grupo de veículo para ser
         * usado nos filtros
         * @author Gui 🍺
         */
        mudaGrupoVeiculos(value) {
            // this.filtrosDash.filtrarTag = []
            this.filtrosDash.grupoVeiculo = [];
            if (value) {
                if (this.checkGrupo) {
                    this.filtrosDash.grupoVeiculo = [value.value];
                } else if (this.checkTags) {
                    var seleciona_todos = value.some((v) => {
                        return v.value === "SA";
                    });
                    if (seleciona_todos) {
                        let filtrados = this.optSeletorGrupoTag.filter(
                            (e) => e.value != "SA"
                        );
                        this.modelGrupo = filtrados;
                        this.filtrosDash.filtrarTag = filtrados.map((e) => e.value);
                    } else {
                        this.filtrosDash.filtrarTag = value.map((e) => e.value);
                    }
                }
            }
            this.emiteFiltros();
        },

		buscar() {
			this.loadBuscar = true
			setTimeout(() => {
				this.$emit("filtrar", this.busca);
				this.loadBuscar = false
			}, 200);
        },

        clearBuscar() {
                this.busca = "";
                this.loadBuscar = true
                setTimeout(() => {
                    this.$emit("filtrar", this.busca);
                    this.$emit("fecha-info");
                    this.loadBuscar = false
                }, 200);
        },
    },

    watch: {
        clienteSelecionado(newValue) {
            this.modelCliente = newValue;
            this.resetaFiltros();
        },

        veiculoSelecionado(newValue) {
            this.busca = newValue;
            this.buscar()
        },

        /**
         * @description watcher para manter as opções de grupos de veículos
         * em sintonia com o cliente selecionado
         * @author Gui 🍺
         */
        "filtrosDash.cliente"(newValue) {
            this.filtrosDash.filtrarTag = [];
            this.filtrosDash.grupoVeiculo = [];
            this.rastroGrupo = [];
            this.modelGrupo = [];
            if (newValue.length) {
                this.buscandoGrupos = true;
                new FiltrosService().dados_filtros(newValue, ["GV"]).then((data) => {
                    this.optSelectGrupo = data.GV;
                    this.buscandoGrupos = false;
                });
            }
        },

        "filtrosDash.filtrarTag"(newValue) {
            this.$emit("filtrar-tag", this.filtrosDash);
        },

        modelCliente(value) {
            this.filtrosDash.filtrarTag = [];
            this.filtrosDash.grupoVeiculo = [];
            this.filtrosDash.cliente = this.modelCliente.map((c) => {
                return c.value;
            });
            this.emiteMudaCliente(value);
            this.emiteFiltros();
        },

        /**
         * Só para garantir que o campo de rastro vai estar desmarcado
         * caso o grupo seja "deselecionado"
         * @author Gui 🍺
         */
        "filtrosDash.grupoVeiculo"(newValue) {
            if (!newValue.length) {
                this.rastroGrupo = [];
            }
            this.checkGrupoVeiculos = false;
        },

        // /**
        //  * @fires filtrar
        //  * @description Garante que toda vez que o campo de busca é alterado,
        //  * o objeto mais recente é propagado pela página.
        //  * @author Gui 🍺
        //  */
        // busca(newValue){
        // 	this.$emit('filtrar', newValue)
        // },

        /**
         * @fires limpar-rastro-gv
         * @description caso o valor for negativo, quer dizer, o check foi
         * desmarcado pelo usuário ou porque o grupo de veículos foi
         * alterado, emite o evento avisando o mapa que todas as
         * polis de grupo de veículos devem ser apagadas.
         */
        checkGrupoVeiculos(newValue) {
            if (!newValue) this.$emit("limpar-rastros-gv");
            else this.checkTags = false;
        },

        checkTags(newValue) {
            if (newValue) this.checkGrupoVeiculos = false;
        },
    },

    computed: {
        optSeletorGrupoTag() {
            if (this.checkGrupo) {
                return this.optSelectGrupo;
            } else if (this.checkTags) {
                if (!this.tags.length) return [];
                var selectAll = {
                    value: "SA",
                    description: "Selecionar Todos",
                };
                return [selectAll, ...this.tags];
            }
            return [];
        },

        /**
        * @author Otávio 🦆
        * @description Monitora se a requisição do mapa terminou, para deixar 
        * os botões de fechar desativado
        */
        requisicaoViagem() {
            return this.mapaRequisicaoViagem.fechaViagem;
        },

        seletorDisable() {
            return !this.optSeletorGrupoTag.length;
        },

        checkTagDisabled() {
            return !this.tags.length;
        },

        /**
         * Mantem o objeto que é enviado para o back
         * atualizado e no formato que a API espera
         */
        objRastroGrupo() {
            return {
                grupos: this.filtrosDash.grupoVeiculo,
                data: this.filtrosRastro.periodoRastro.join("-"),
            };
        },

        existeClienteSelecionado() {
            return this.filtrosDash.cliente !== "";
        },

        optClientes() {
            var selectAll = {
                value: "SA",
                description: "Selecionar Todos",
            };
            return [selectAll, ...this.optSelectEmpresas];
        },

        clienteMapa() {
            return this.getClienteMapa();
        },
    },
    mounted() {
        if (this.getMaster()){
             this.modelCliente = [];
        }else{
            this.modelCliente = new EmpresasService().Get();
        }
        this.emiteFiltros();
        this.emiteMudaCliente(this.modelCliente);
    },
};
</script>
<style lang="scss" scoped>
.filtrosMapa {
    .checks-filtros {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    height: 50px !important;
    background-color: rgba(245, 245, 245, 0.9);
    .botaoFiltro {
        height: 39px !important;
    }
    .ignicao {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 150px;
        .text-ig {
            padding-top: 7%;
            padding-right: 2px;
        }
    }
    .fixedSize {
        width: 200px;
    }
}
</style>
