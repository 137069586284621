<template>
	<v-marker-cluster :options='options' id='clusterPedidosRM' ref='ClusterRM'>
		<slot></slot>
	</v-marker-cluster>
</template>

<script>
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
export default {
	//https://github.com/Leaflet/Leaflet.markercluster <- marker cluster
	//https://github.com/jperelli/vue2-leaflet-markercluster  <- marker cluster vue
	//A documentação do vue só explica como adc o elemento ao vue, a outra como ele funciona.
	name: 'marker-cluster',

	components: {
		'v-marker-cluster': Vue2LeafletMarkerCluster,
	},

	props: {
		disableClusterZoom: {
			type: [Number, Boolean],
			default: 16
		},
		maxClusterRadius: {
			type: Number,
			default: 50
		},
		/**
				 * @param {number} i   - número de marcadores processados.
				 * @param {number} ii  - número total de marcadores sendo adicionados
				 * @param {number} iii - tempo passado (ms)
				 * @description essa função é chamada toda vez que o cluster
				 * termina de adicinar um "chunk" ou pedaço de uma array
				 * de marcadores.
				 */
		chunkProgress: {
			type: Function,
			default: () => { return }
		},
		/** intervalo entre periodos consecutivos processando addLayer (ms)*/
		chunkDelay: {
			type: Number,
			default: 200
		},
		/** tempo de intervalo entre cada 'chunk' (ms)*/
		chunkInterval: {
			type: Number,
			default: 200
		}
	},

	data() {
		return {
			options: {
				disableClusteringAtZoom: this.disableClusterZoom,
				removeOutsideVisibleBounds: true,
				showCoverageOnHover: false,
				spiderfyOnMaxZoom: true,
				singleMarkerMode: false,
				chunkedLoading: true,
				maxClusterRadius: this.maxClusterRadius,
				chunkInterval: this.chunkInterval,
				chunkProgress: this.chunkProgress,
				chunkDelay: this.chunkDelay,
				iconCreateFunction: function (cluster) {
					var childCount = cluster.getChildCount()
					var c = ' marker-cluster-rm-';
					if (childCount == 1) {
						return cluster.getAllChildMarkers()[0].options.icon
					} else if (childCount < 10) {
						c += 'small'
					} else if (childCount < 50) {
						c += 'medium'
					} else {
						c += 'large'
					}
					return new L.DivIcon({
						html: '<div><span>' + childCount + '</span></div>',
						className: ' marker-cluster marker-cluster-rm ' + c, iconSize: new L.Point(30, 30)
					})
				},
			}
		}
	},

	methods: {
		/**
		 * Redesenha todos, um ou um grupo de markers.
		 * @param {(L.marker|boolean)} markers um ou uma array de markers a serem redesenhados.
		 * Não passar nada caso o objetivo seja desenhar todos os markers
		 * novamente.
		 * @author Gui 🍺
		 */
		refreshClusters(markers = false) {
			if (markers)
				this.$refs.ClusterRM?.mapObject?.refreshClusters(markers)
			else
				this.$refs.ClusterRM?.mapObject?.refreshClusters()
		},

		clearLayers() {
			this.$refs.ClusterRM?.mapObject?.clearLayers()
		},

		/**
		 * @description Procura o pai do marcador passado por parametro, mas apenas se
		 * o marcador esta visível ou dentro de um cluster que visível 
		 * (literalmente, se esta na parte onde a viewport se encontra).
		 * @param marker
		 * @returns pai do marcador ou null.
		 */
		getVisibleParent(marker) {
			return this.$refs.ClusterRM?.mapObject?.getVisibleParent(marker)
		},

		/**
		 * @description adiciona um novo marker no mapa (método para adc apenas um marker).
		 * @param marker marcador a ser adicionado.
		 */
		addLayer(marker) {
			this.$refs.ClusterRM?.mapObject?.addLayer(marker)
		},

		/**
		 * @description remove um marcador do cluster.
		 * @param {marker} marker - marcador a ser removido.
		 */
		removeLayer(marker) {
			this.$refs.ClusterRM?.mapObject?.removeLayer(marker)
		},

		/**
		 * @description remove vários marcadores do cluster.
		 * @param {array} markers - marcadores a serem removidos.
		 */
		removeLayers(markers) {
			this.$refs.ClusterRM?.mapObject?.removeLayers(markers)
		},

		/** 
		 * Função para adicionar mais de um marker por vez.
		 * Testei com até 23k markers e tipo, susse no musse.
		 * Só passar os markers "prontos" pra cá.
		 * @param {array} arrayMarkers
		 * @author Gui 🍺 
		*/
		addMarkerBulk(arrayMarkers) {
			this.$refs.ClusterRM?.mapObject?.addLayers(arrayMarkers)
		},

		/**
		 * @description remove todos os marcadores do cluster
		 */
		clear() {
			this.$refs.ClusterRM?.mapObject?.clearLayers()
		}
	},
}
</script>

<style lang="scss">
.accordionPlanejar {
	@import "~leaflet.markercluster/dist/MarkerCluster.css";
	@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

	.marker-cluster-rm-small {
		background-color: rgba(0, 95, 210, 0.7) !important;
	}

	.marker-cluster-rm-small div {
		background-color: rgba(0, 80, 190, 0.7) !important;
	}

	.marker-cluster-rm-medium {
		background-color: rgba(0, 80, 190, 0.7) !important;
	}

	.marker-cluster-rm-medium div {
		background-color: rgba(0, 65, 170, 0.7) !important;
	}

	.marker-cluster-rm-large {
		background-color: rgba(0, 65, 170, 0.7) !important;
	}

	.marker-cluster-rm-large div {
		background-color: rgba(0, 50, 150, 0.7) !important;
	}

	.leaflet-oldie .marker-cluster-rm-small {
		background-color: rgba(0, 95, 210, 0.7) !important;
	}

	.leaflet-oldie .marker-cluster-rm-small div {
		background-color: rgba(0, 80, 190, 0.7) !important;
	}

	.leaflet-oldie .marker-cluster-rm-medium {
		background-color: rgba(0, 80, 190, 0.7) !important;
	}

	.leaflet-oldie .marker-cluster-rm-medium div {
		background-color: rgba(0, 65, 170, 0.7) !important;
	}

	.leaflet-oldie .marker-cluster-rm-large {
		background-color: rgba(0, 65, 170, 0.7) !important;
	}

	.leaflet-oldie .marker-cluster-rm-large div {
		background-color: rgba(0, 50, 150, 0.7) !important;
	}

	.marker-cluster-rm {
		background-clip: padding-box !important;
		border-radius: 20px !important;
		width: 35px !important;
		height: 35px !important;
	}

	.marker-cluster-rm div {
		width: 25px !important;
		height: 25px !important;
		margin-left: 5px !important;
		margin-top: 5px !important;
		text-align: center !important;
		border-radius: 15px !important;
	}

	.marker-cluster-rm span {
		line-height: 25px !important;
		color: lightgray !important;
		font-weight: bold !important;
	}
}
</style>