<template>
    <panelEagle id='RotasRegiao' :loading="loadingPanel">
        <div class="col-sm-12 row nopadding">
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-6">
                    <tituloPage
                    :icon='mdiSelectionMultipleMarker'
                    titulo='Rotas por região'
                    />
                </div>
                <div class="col-sm-6 divDontPrint">
                    <basicButtonsRelatoriosEIG 
                    :disabled="$v.$invalid"
                    :disabledDropdown="relatorio.length == 0"
                    :loading="loadingExporta"
                    @gerarRelatorio="gerar"
                    @exportarRelatorio="exportar"
                    />
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12"><hr></div>
                    <div class="col-sm-12 row nopadding divDontPrint">
                        <div class="col-sm-3 nopadding">
                            <inputRangeWithLimit
                            name="dataRotasRegiao" 
                            titulo="Intervalo"
                            :intervalo='3'
                            :isObrigatorio="true"
                            @changeInput="changeData"
                            />
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll 
                            nameForRadio="seletorEmpresas" 
                            :labels="[
                                {indexDFH: 'E', description: 'Empresas*'
                            }]" 
                            :optionsArray="optionsSelectEmpresas" 
                            firstSelected="E" 
                            :hasSelectAll="true"
                            :isMultiple="true" 
                            @changeSelect="changeEmpresas"
                            />
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll 
                            ref='seletorVeiculo'
                            nameForRadio  ="seletorVeiculos" 
                            :labels="labelsSelectVeiculos" 
                            :optionsArray="optionsSelectCGV" 
                            firstSelected="V" 
                            :hasSelectAll="true"
                            :isMultiple="true" 
                            :loading="loadingVeiculos"
                            :disabled="$v.empresasSelecionadas.$invalid"
                            @changeCheck="registraCheckVeiculos" 
                            @changeSelect="changeVeiculos"
                            />
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll 
                            ref='seletorRegiaoInicial'
                            nameForRadio  ="seletorRegiaoInicial" 
                            :labels="[{
                                indexDFH: 'RI',
                                description: 'Região inicial*'
                            }]" 
                            :optionsArray="optionsRegiao" 
                            firstSelected="E" 
                            :hasSelectAll="false"
                            :isMultiple="false" 
                            :loading="loadingRegioes"
                            :disabled="$v.empresasSelecionadas.$invalid"
                            @changeSelect="changeRegiaoInicial"
                            />
                        </div>
                        <div class="col-sm-3 nopadding mt-2 row">
                            <div class="col-sm-6 linha2 nopadding">
                                <inputSimple
                                label='Hora Inicial'
                                type='time'
                                value='00:00'
                                @changeInput='changeHoraI'
                                />
                            </div>
                            <div class="col-sm-6 linha2 nopadding">
                                <inputSimple
                                label='Hora Final'
                                type='time'
                                value='23:59'
                                @changeInput='changeHoraF'
                                />
                            </div>
                        </div>
                        <div class="col-sm-3 nopadding mt-2">
                            <selectAll 
                            ref='seletorRegiaoFinal'
                            nameForRadio  ="seletorRegiaoFinal" 
                            :labels="[{
                                indexDFH: 'RF',
                                description: 'Região final*'
                            }]" 
                            :optionsArray="optionsRegiao" 
                            firstSelected="E" 
                            :hasSelectAll="false"
                            :isMultiple="false" 
                            :loading="loadingRegioes"
                            :disabled="$v.empresasSelecionadas.$invalid"
                            @changeSelect="changeRegiaoFinal"
                            />
                        </div>
                        <div class="col-sm-3 nopadding mt-2">
                            <selectAll 
                            ref='seletorRegiaoInteresse'
                            nameForRadio="seletorRegiaoInteresse" 
                            :labels="[{
                                indexDFH: 'RT',
                                description: 'Região interesse'
                            }]" 
                            :optionsArray="optionsRegiao" 
                            firstSelected="E" 
                            :hasSelectAll="true"
                            :isMultiple="true" 
                            :loading="loadingRegioes"
                            :disabled="$v.empresasSelecionadas.$invalid"
                            @changeSelect="changeRegiaoInteresse"
                            />
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
        </div>
        <div class="col-sm-12"><hr></div>
        <tableRelatorio>
            <slot slot="thead">
                <br>
                <tr class="tr-tabela">
                    <th colspan="3" class="prmieiro">Saída</th>
                    <th colspan="3" class="prmieiro">Chegada</th>
                    <th rowspan="2">Região</th>
                    <th rowspan="2">Tempo</th>
                    <th rowspan="2">Km percorrido</th>
                    <th rowspan="2">Colaborador</th>
                </tr>
                <tr class="cabecalhoTabela tr-tabela">
                    <th class="segundo">Data</th>
                    <th class="segundo">Hora</th>
                    <th class="segundo">Hodômetro</th>
                    <th class="segundo">Data</th>
                    <th class="segundo">Hora</th>
                    <th class="segundo">Hodômetro</th>
                </tr>
            </slot>
            <slot slot="tbody">
                <template v-for="(veiculos, v) in relatorio">
                    <tr :key='`${v}__rotas_regiao`' class="badgetr backColorTdPDF">
                        <td colspan="10" class="pl-0 badgePadding">
                            <span class="newbadge">
                                {{v}}
                            </span>
                        </td>
                    </tr>
                    <tr v-for="(dados, d) in veiculos" :key="`${d}_info_veiculo_${v}`">
                        <td>{{transformaData(dados['saida']['bidataevento'])}}</td>
                        <td>{{transformaHora(dados['saida']['bidataevento'])}}</td>
                        <td>{{(dados['saida']['bihodometro']/1000).toFixed(2)}}</td>
                        <td>{{transformaData(dados['entrada']['bidataevento'])}}</td>
                        <td>{{transformaHora(dados['entrada']['bidataevento'])}}</td>
                        <td>{{(dados['entrada']['bihodometro']/1000).toFixed(2)}}</td>
                        <td>{{dados['regiao']}}</td>
                        <td>
                            {{
                                subtraiHora(
                                    dados['entrada']['bidataevento'],
                                    dados['saida']['bidataevento']
                                )
                            }}
                        </td>
                        <td>
                            {{
                                ((
                                    dados['entrada']['bihodometro'] -
                                    dados['saida']['bihodometro']
                                ) / 1000).toFixed(2)
                            }}km
                        </td>
                        <td>{{dados['saida']['mtnome']}}</td>
                    </tr>
                </template>
                <statusInformation 
                v-if="relatorio.length == 0"
                typeBar="tr" 
                :statusBar="statusbar" 
                :colspanForTd="10"
                />
            </slot>
        </tableRelatorio>
    </panelEagle>
</template>
<script>
import Vue from 'vue'
import { mdiSelectionMultipleMarker } from '@mdi/js'
import { EmpresasService } from '../../../Services/auth/Empresas.service'
import { FiltrosService } from '../../../Services/filtros/filtros.Service'
import { required } from 'vuelidate/lib/validators'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { DateTime } from 'luxon'
import tableRelatorio from '@/components/Atom/Table/TableRelatorio'
import basicButtonsRelatoriosEIG from '@/components/Atom/Buttons/BasicButtonsRelatoriosEIG'
import inputRangeWithLimit from '@/components/Atom/Datas/InputRangeWithLimit'
import slideUpAndDown from '@/components/Atom/SlideUpAndDown/SlideUpAndDown'
import statusInformation from '@/components/Atom/StatusInformation/StatusInformation'
export default Vue.extend({
    name:'RotasRegiao',

    validations:{
        empresasSelecionadas:{ required },
        veiculosSelecionados:{ required },
        regiaoInicialSelecionada:{ required },
        regiaoFinalSelecionada:{ required },
    },

    components:{ 
        panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
        tituloPage: require('@/components/Atom/Header/Titulo').default,
        selectAll: require('@/components/Atom/Select/SelectAll').default,
        inputSimple: require('@/components/Atom/Inputs/InputSimple').default,
        tableRelatorio,
        basicButtonsRelatoriosEIG,
        inputRangeWithLimit,
        slideUpAndDown,
        statusInformation,
    },

    data() {
        return {
            urlPadrao: '/roteirizador/relatorios/rotas/regiao/',
            optionsSelectEmpresas: new EmpresasService().Get(),
            mdiSelectionMultipleMarker: mdiSelectionMultipleMarker,
            labelsSelectVeiculos: [
                { indexDFH: 'V', description: 'Veículos*' },
                { indexDFH: 'GV', description: 'Grupos*' }
            ],
            optionsSelectCGV: [],
            loadingPanel: false,
            filtros: false,
            empresasSelecionadas: [],
            optionsRadioVeiculo: '',
            veiculosSelecionados: [],
            dataSelecionada: [],
            regiaoInicialSelecionada: [],
            regiaoFinalSelecionada: [],
            regiaoInteresseSelecionada: [],
            optionsRegiao: [],
            relatorio: [],
            loadingExporta: [false, false, false],
            statusbar: 'info',
            loadingRegioes: false,
            loadingVeiculos: false,
            horaInicial: '',
            horaFinal: '',
        }
    },

    methods: {
        changeHoraI(h) {
            this.relatorio = []
            this.horaInicial = h
        },

        changeHoraF(h) {
            this.relatorio = []
            this.horaFinal = h  
        },
        
        /**
         * @listens changeInput - componente de data
         * @param {string} data - formato dd/mm/yyyy - dd/mm/yyyy
         */
        changeData(data){
            this.dataSelecionada = data
            this.relatorio = []
        },

        changeEmpresas(empresas){
            this.relatorio = []
            this.empresasSelecionadas = empresas
            this.$refs.seletorRegiaoFinal.clearAll() 
            this.$refs.seletorRegiaoInicial.clearAll()
            this.$refs.seletorRegiaoInteresse.clearAll()
            this.$refs.seletorVeiculo.clearAll()
            if(empresas.length > 0){
                this.dados_filtros()
                this.buscaRegioes()
            }
        },

        changeVeiculos(veiculos){
            this.relatorio = []
            this.veiculosSelecionados = veiculos
        },

        changeRegiaoInicial(regiaoInicial){
            this.relatorio = []
            this.regiaoInicialSelecionada = regiaoInicial
        },

        changeRegiaoFinal(regiaoFinal){
            this.relatorio = []
            this.regiaoFinalSelecionada = regiaoFinal
        },

        changeRegiaoInteresse(regiaoInteresse) {
            this.relatorio = []
            this.regiaoInteresseSelecionada = regiaoInteresse
        },

        registraCheckVeiculos(check, needRecharge){
            this.optionsRadioVeiculo = check
            if(needRecharge){
                this.dados_filtros();
            }
        },

        /**
         *  @description Método para buscar os veículos
         * 	e grupo de veículos
         *  @author Vitor Hugo 🐨
         */
        async dados_filtros(){
            this.loadingVeiculos = true
            var dados = [];
            dados = await new FiltrosService()
                .dados_filtros(
                    this.empresasSelecionadas,
                    [this.optionsRadioVeiculo]
                )
            this.optionsSelectCGV = dados[this.optionsRadioVeiculo]
            this.loadingVeiculos = false
        },

        /**
         *  @description Método para buscar as regiões 
         * 	referente as empresas selecionas
         *  @author Vitor Hugo 🐨
         */
        buscaRegioes(){
            this.loadingRegioes = true
            new FiltrosService()
                .dados_filtros(this.empresasSelecionadas, ['RE'])
                .then(dados=>{
                    this.optionsRegiao = dados.RE
                    this.loadingRegioes = false
                })
        },

        gerar(){
            this.loadingPanel = true
            if(this.regiaoInteresseSelecionada.length > 0)
                this.regiaoInteresseSelecionada.push(
                    this.regiaoInicialSelecionada[0]
                )
            var obj ={
                'data': this.dataSelecionada,
                'empresas': this.empresasSelecionadas,
                'veiculos': this.veiculosSelecionados,
                'regiaoInicial': this.regiaoInicialSelecionada,
                'regiaoFinal': this.regiaoFinalSelecionada,
                'regiaoInteresse': this.regiaoInteresseSelecionada,
                'checkVeiculos': this.optionsRadioVeiculo,
                'horaInicial': this.horaInicial,
                'horaFinal': this.horaFinal,
            }
            new HttpRequest().Post(this.urlPadrao+'gerar', obj)
                .then(dados=>{
                    if(dados.code === 200){
                        this.relatorio = dados.data.rotas
                        if (this.relatorio.length === 0) {
                            this.statusbar = 'error'
                        }
                        this.loadingPanel = false
                    } else {
                        this.relatorio = []
                        this.loadingPanel = false
                        this.statusbar = 'error2'
                    }
                })
        },

        /**
         *  @description Método para transformar a data para nosso padrão BR
         *  @param  {string} data  - data no padrão do banco
         *  @return data formatada
         *  @author Vitor Hugo 🐨
         */
        transformaData(data){
            return DateTime.fromFormat(data,'yyyy-LL-dd HH:mm:ss')
                .toFormat('dd/MM/yyyy')
        },

        /**
         *  @description Método para transformar a hora para nosso padrão BR
         *  @param  {string} data  - data no padrão do banco
         *  @return {string} hora formatada
         *  @author Vitor Hugo 🐨
         */
        transformaHora(data){
            return DateTime.fromFormat(data,'yyyy-LL-dd HH:mm:ss')
                .toFormat('HH:mm:ss')
        },

        /**
         * @description Método para fazer a subtração da data
         * @param  {string} data1  - data no padrão do banco
         * @param  {string} data2  - data no padrão do banco
         * @return diferença em horas entre as datas
         * @author Vitor Hugo 🐨
         */
        subtraiHora(data1, data2){
            var end = DateTime.fromFormat(data1, 'yyyy-LL-dd HH:mm:ss')
            var start = DateTime.fromFormat(data2, 'yyyy-LL-dd HH:mm:ss');
            var diff = end.diff(start, ['hours', 'minutes', 'second']);
            diff = diff.toFormat('hh:mm:ss')
            return diff
        },

        /**
         * @deprecated ja vem certo o obj
         * @description Método para retirar dados duplicados da array
         * @param {array} regiao  - regioes que o veículo passou
         * @return daods da regiões sem ser duplicados
         * @author Vitor Hugo 🐨
         */
        retornaRegiao(regiao){
            return [...new Set(regiao)]
        },

        exportar(tipo){
            switch (tipo){
                case 'pdf': this.loadingExporta = [true, false, false]; break
                case 'xls': this.loadingExporta = [false, true, false]; break
                default: this.loadingExporta = [false, false, true]; break
            }
            var root =  process.env.VUE_APP_ROOT
            new HttpRequest().Post(this.urlPadrao+'exportar', {
                intervalo:this.dataSelecionada,
                dados:this.relatorio,
                tipo:tipo
            }).then(dados=>{
                window.open(root+'/'+dados.data)
                this.loadingExporta = [false, false, false]
            })
        }
    }
})
</script>
<style lang="scss">
#RotasRegiao{
    .cabecalhoTabela{
        th{
            top: 3.5% !important;
        }
    }
    .tr-tabela{
        th{
            max-height: 30px !important;
        }
        th.prmieiro{
            z-index: 2;
        }
        th.segundo{
            top: 30px !important;
            z-index: 1;
        }
    }
}
</style>
