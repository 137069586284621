/**
    Este é o componente de desenho livre no mapa leaflet e
	segue com o botões de controle do plugin
    -> Propriedades:
      
    -> Slot:
        Sem slot
    -> Eventos:
       
 */
/** eslint-disable */
<template>
    <div :class="classParent">
        <div class="col sm-3 row divSlotFreeDraw">
            <slot></slot>
        </div>
        <div class=" col-sm-3 row divControlesFreeDraw">
            <div class="col-sm-4 nopadding">
                <simpleButton
				@click="paraDesenho"
				event="click"
				type="orange"
				:icon="mdiMinusCircle"
				width="100%"
				title="Parar"/>
            </div>
            <div class="col-sm-4 nopadding">
                <simpleButton
				@click='continuaDesenho'
				event='click'
				type="blue"
				:icon="mdiPencil"
				width="100%"
				title="Desenhar"/>
            </div>
            <div class="col-sm-4 nopadding">
                <simpleButton
				@click='deletarDesenho'
				event='click'
				type="red"
				:icon='mdiTrashCanOutline'
				width='100%'
				title='Limpar'/>
            </div>
        </div>
    </div>
</template>
<script>
/**eslint-enable */
import Vue, { PropType } from 'vue';
import {mdiMinusCircle, mdiPencil, mdiTrashCanOutline } from '@mdi/js'
import 'leaflet-measure'
import L, { LatLng, editableLayers, polygon, GeometryUtil } from 'leaflet';
import FreeDraw, { CREATE, EDIT, DELETE, APPEND, NONE } from 'leaflet-freedraw';
export default Vue.extend({
	name:'FreeDraw',
	components: {
		simpleButton: require('../Buttons/SimpleButton').default,
	},
	props: {
		regiao: {
			type: Array,
			default: ()=>{return []}
		},
		classParent: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			mdiTrashCanOutline:mdiTrashCanOutline,
			mdiPencil:mdiPencil,
			mdiMinusCircle:mdiMinusCircle,
			freeDraw :new FreeDraw({
				mode:NONE, 
				simplifyFactor :1,
				smoothFactor   :1
			}),
			polygons:[]
		};
	},
	methods: {

		paraDesenho(){
			this.freeDraw.mode( NONE )
			this.mode = 'visualização'
			this.emiteDesenho('stop')
		},

		continuaDesenho() {
			if(this.mode === 'Edição'){
				this.freeDraw.mode( NONE )
				this.mode = 'visualização'
				this.emiteDesenho('stop')
			} else {
				this.freeDraw.mode( CREATE | EDIT | APPEND | DELETE )
				this.mode = 'Edição'
				this.$emit('editando')
			}
		},
		
		deletarDesenho(){
			this.freeDraw.mode( NONE )
			this.mode = 'visualização'
			this.freeDraw.clear()
			this.emiteDesenho('delete')
		},

		emiteDesenho(extraInfo = null) {
			this.$emit('desenho', this.getLatLng(), extraInfo)
		},
		
		getLatLng() {
			var polygons = this.freeDraw.all()
			var conjuntoCoord = []
			for(var a in polygons) {
				conjuntoCoord.push(polygons[a]._latlngs[0])
			}
			return conjuntoCoord
		},
	},
	mounted () {
		this.$parent.mapObject.addLayer(this.freeDraw)
		this.freeDraw.on('markers', event => {
			this.$emit('desenho', event.latLngs, 'drawing')
		});
	},
	watch: {
		regiao(newValue) {
			var polygon = this.freeDraw.create(newValue)

			// var aux = []
			// for(var i in newValue){
			// 	if (newValue[i][1] != undefined)
			// 		aux.push([newValue[i][1], newValue[i][0]])
			// }
			// this.freeDraw.clear()
			// var polygon = this.freeDraw.create(aux)
		}
	},
});
</script>
<style lang="scss" scoped>
.divControlesFreeDraw{
    float: right;
    position: absolute !important;
    z-index: 1000;
    right: 0;
    margin:0px;
    padding: 0px;
	top: 0;
}
.divSlotFreeDraw{
    float: left;
    position: absolute !important;
    z-index: 1000;
    left: 0;
    margin:0px;
    padding: 0px;
}
</style>