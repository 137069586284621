<template>
    <div
	v-b-visible.once='verificaData'
	id='popup-coletivos'>
		<div
		data-test='div-podescricao'
		v-text='ponto.podescricao'
		class='titulo-coletivos'/>
		<div
		class='justify-content-center'
		v-if='!buscando'>
			<div
			data-test='div-tempo-parado'
			v-html='tempoParado()'/>
			<div v-html='horaParada()'/>
			<div v-html='horaProgramada()'/>
		</div>
		<div v-else
		class='d-flex justify-content-center'>
			<b-spinner/>
		</div>
    </div>
</template>

<script>
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
export default{
	name:'popup-ponto-coletivo',
	props:{
		ponto:{
			type:Object,
			required:true
		}
	},
	data(){
		return{
			buscando:false,
			teste:'ola ola ola',
			urlBase:'/finder/coletivos/',
			infoPonto:{
				irctempoparado:'',
				irchoraparada:'',
				ircstatus:'',
				irchoraprogramada:'',
			}
		}
	},
	methods:{
		verificaData(visible){
			if(visible){
				this.buscando = true
				var uri = `${this.urlBase}info/ponto`
				new HttpRequest().Post(uri, this.obj).then((data)=>{
					this.infoPonto = data.data.info
				}).finally(()=>this.buscando = false)
			}
		},
		tempoParado(){
			return `<b>Tempo parado:</b> ${this.infoPonto.irctempoparado}`
		},
		horaParada(){
			return `<b>Hora parada:</b> ${this.infoPonto.irchoraparada}`
		},
		horaProgramada(){
			return `<b>Hora programada:</b> ${this.infoPonto.irchoraprogramada}`
		}

		
	},
	computed: {
		obj(){
			return{
				ponto:this.ponto.ircponto,
				irc:  this.ponto.irccodigo
			}
		}
	},
	mounted(){
	}
}
</script>

<style lang="scss">
#popup-coletivos{
	width: 200px;
	.titulo-coletivos{
		font-weight: bolder;
		font-size: 15px;
	}
}

</style>