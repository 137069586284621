<template>
    <panelEagle id="detalhesLinha" :loading="loadingPanel">
        <div class="col-sm-12 nopadding divDontPrint">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiTransitConnectionVariant" titulo="Detalhes linhas"></tituloPage>
                </div>

                <div class="col-sm-6 divButtonsBasic d-flex align-items-center justify-content-end">
                    <div class="btnLegenda">
                        <simpleButton id="popoverInfo" width="100px" :icon="mdiAlertCircleOutline" type="orange"
                            text="Legenda" title="Legenda">
                        </simpleButton>
                    </div>


                    <basicButtonsRelatoriosEIG :disabledDropdown="!Object.keys(dadosRelatorio).length"
                        @exportarRelatorio="exportarRelatorio" @gerarRelatorio="gerarRelatorio"
                        :loading='loadingExportar' :disabled="$v.$invalid">
                    </basicButtonsRelatoriosEIG>
                </div>

            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12">
                        <hr>
                    </div>
                    <div class="col-sm-12 row justpaddingbottom">
                        <div class="col-sm-3 nopadding">
                            <inputRangeWithLimit @changeInput="changeData" name="inputData" opens='right'>
                            </inputRangeWithLimit>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll @changeSelect="SelectEmpresas" :isMultiple="false" nameForRadio="radiosEmpresas"
                                :labels="labelEmpresas" :optionsArray="optionsEmpresas" :selected="selectedEmpresa"
                                firstSelected="EM" :hasSelectAll="false">
                            </selectAll>
                        </div>
                        <div class="col-sm-4 nopadding">
                            <selectAll @selectCriado="SelectCriadoVLLP" @changeCheck="changeCheck"
                                @changeSelect="selectLinhasVeiculos" :isMultiple=true
                                nameForRadio="radiosVeiculosLinhas" :labels="labelVeicLinhasPrincipais"
                                :optionsArray="optionsVLLP" firstSelected="V"
                                :hasSelectAll="(radiosSelected == 'V' ? false : true)" :loading="carregando"
                                :disabled="$v.selectedEmpresas.$invalid">
                            </selectAll>
                        </div>

                        <div class="col-sm-2 nopadding">
                            <selectAll @changeSelect="selectTurnos" :isMultiple="true" nameForRadio="radiosTurnos"
                                :labels="labelTurnos" :optionsArray="optionsTurnos" :selected="selectedEmpresa"
                                firstSelected="TR" :hasSelectAll="true" :disabled="$v.selectedEmpresas.$invalid">
                            </selectAll>
                        </div>

                        <div class="col-sm-3 nopadding">
                            <buttonsFilter @buttonsFiltersChange="changeFiltros" :arrayButtons="arrayButtons"
                                label="Filtros *" :onlyOneSelected="false">
                            </buttonsFilter>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
            <div class="col-sm-12 divHrDeBaixo">
                <hr>
            </div>
        </div>
        <div>
            <b-popover target="popoverInfo" triggers="hover click" placement="auto" ref="popover">
                <div class="col-sm-12 nopadding row">
                    <div class="col-sm-12 nopadding">
                        <subtitle :items="itemsSubtitleLinha" title="Operação">
                        </subtitle>
                    </div>
                    <div class="col-sm-12 nopadding">
                        <subtitle :items="itemsSubtitleStatusLinha" title="Siglas">
                        </subtitle>
                    </div>
                </div>
            </b-popover>
        </div>

        <tableRelatorio>
            <slot slot="thead">
                <th style="width: 30%;">Linha</th>
                <th style="width: 6%;">Veiculo</th>
                <th style="width: 5%;">Prefixo</th>
                <th style="width: 12%;">Colaborador</th>
                <th style="width: 5%;">Turno</th>
                <th style="width: 12%;">Escala</th>
                <th style="width: 5%;">Pesagem</th>
                <th style="width: 5%;">Descarga</th>
                <th style="width: 7%;">Tempo(PxR)</th>
                <th style="width: 7%;">Distância(PxR)</th>
                <th style="width: 5%;">Progresso</th>
                <th style="width: 1%;">Status</th>
            </slot>
            <slot slot="tbody">
                <statusInformation v-if="tamanhoRelatorio == 0" typeBar="tr" :statusBar="statusbar">
                </statusInformation>
                <template v-for="(data, d) in dadosRelatorio">
                    <br :key="d + '_br'">
                    <!-- Badge da data -->
                    <tr :key="d + 'data'" class="trDataTabela ">
                        <td colspan="2" class="dataTabela cinzaClaro" style="width: 5%;">
                            {{ d }}
                        </td>
                    </tr>
                    <template v-for="(linha, l)  in data">
                        <!-- Dados principais -->
                        <tr :key="l + 'dadosTabela' + d" v-b-toggle="(linha.codigo + '' + l)"
                            @click="linha.clicado = !linha.clicado" 
                            :class="linha.classeTr == 'trEmAtrasoTolerado' ?
                            'dadosTabela sem-borda trEmAtrasoTolerado' :
                            linha.classeTr == 'trAtrasada' ?
                            'dadosTabela sem-borda trAtrasada' :
                            linha.classeTr == 'trDeAcordoComOProgramado' ?
                            'dadosTabela sem-borda trDeAcordoComOProgramado' :
                            'dadosTabela cinzaClaro sem-borda'">
                            <td class="fonteTabelaExterna">
                                <div class="chevronTop">
                                    <b-icon :id="actived" class="h5 mb-0 fonteTabelaExterna" shift-v="-6" shift-h="0"
                                        style="font-weight: bold;" :icon="linha.clicado ?
                            'chevron-down' : 'chevron-right'" />
                                </div>
                                <div class="fonteTabelaExterna">
                                    {{ linha.linha }}
                                </div>
                            </td>
                            <td class="fonteTabelaExterna ">{{ linha.placa }}</td>
                            <td class="fonteTabelaExterna ">{{ linha.prefixo }}</td>
                            <td class="fonteTabelaExterna ">{{ linha.motorista }}</td>
                            <td class="fonteTabelaExterna ">{{ linha.turno }}</td>
                            <td class="fonteTabelaExterna ">{{ linha.escala }}</td>
                            <td class="fonteTabelaExterna ">{{ linha.pesagem }}</td>
                            <td class="fonteTabelaExterna ">{{ linha.descarga }}</td>
                            <td class="fonteTabelaExterna ">{{ linha.tempoProg }} / {{ linha.tempoReal }}</td>
                            <td class="fonteTabelaExterna ">{{ linha.distanciaProg }} / {{ linha.distanciaReal
                                }}
                            </td>
                            <td class="fonteTabelaExterna ">{{ linha.progresso + '%' }}</td>
                            <td class="fonteTabelaExterna ">{{ linha.rcstatus }}</td>
                        </tr>
                        <!-- Chevron, dados ocultos  -->

                        <tr :key="l + 'chevron' + d">
                            <td colspan="12" class="sem-borda">
                                <b-collapse :id="(linha.codigo + '' + l)">
                        <tr :key="l + 'chevronHeader' + d" class="dadosChevronHeader cinzaEscuro">
                            <th class="sem-borda fonteTabelaInterna cinzaEscuro" style="width: 400px;">Ordem</th>
                            <th class="sem-borda fonteTabelaInterna cinzaEscuro" style="width: 5%">Situação</th>
                            <th class="sem-borda fonteTabelaInterna cinzaEscuro" style="width: 15%">Ponto</th>
                            <th class="sem-borda fonteTabelaInterna cinzaEscuro" style="width: 15%">Horário</th>
                            <th class="sem-borda fonteTabelaInterna cinzaEscuro" style="width: 15%">Tempo Parado</th>
                            <th class="fonteTabelaInterna" style="width: 40%">Km</th>
                        </tr>

                        <tr v-for="(dadosLinha, dl) in linha.itensRota" :key="dl + 'dadosLinha'"
                            class="dadosChevron fonteTabelaInterna" style="background: #eaecf3;">
                            <td class="descPontoDL cinzaClaro fonteTabelaInterna">{{ dadosLinha.ordenacao }}</td>
                            <td class="descPontoDL cinzaClaro fonteTabelaInterna"
                                :class="dadosLinha.status == 'E' ? 'cor-verde' : 'cor-vermelha'">
                                <baseIcon size='18'
                                    :icon="dadosLinha.status == 'E' ? mdiMapMarkerCheck : mdiMapMarkerRemoveVariant" />
                            </td>
                            <td class="cinzaClaro fonteTabelaInterna">{{ dadosLinha.descricaoPonto }}</td>
                            <td class="descPontoDL cinzaClaro fonteTabelaInterna">{{ dadosLinha.horario }}</td>
                            <td class="cinzaClaro descPontoDL fonteTabelaInterna">{{ dadosLinha.tempoParado }}</td>
                            <td class="cinzaClaro fonteTabelaInterna">{{ dadosLinha.kms ? dadosLinha.kms + ' Km' : '' }}
                            </td>
                        </tr>

                        </b-collapse>
                        </td>
                        </tr>
                    </template>
                </template>
            </slot>
        </tableRelatorio>
    </panelEagle>
</template>

<script>
import {mdiTransitConnectionVariant, mdiCheckBold, mdiAlertCircleOutline,mdiMapMarkerCheck,mdiThumbDownOutline, mdiMapMarkerRemoveVariant} from '@mdi/js'
import Vue               from 'vue'
import {required}        from 'vuelidate/lib/validators'
import {EmpresasService} from '../../../Services/auth/Empresas.service'
import {HttpRequest}     from '../../../Services/auth/HttpRequest.Service'
import {FiltrosService}  from '../../../Services/filtros/filtros.Service'
import { mapGetters } from 'vuex'

export default Vue.extend({
	name:'DetalhesLinhas',
	components:{
		baseIcon:require('@/components/Atom/Icon/BaseIcon.vue').default,
		'panelEagle'                : require('@/components/Atom/Panel/PanelEagle').default,
		'slideUpAndDown'            : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
		'tituloPage'                : require('@/components/Atom/Header/Titulo').default,
		'basicButtonsRelatoriosEIG' : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
		'inputRangeWithLimit'       : require('@/components/Atom/Datas/InputRangeWithLimit').default,
		'selectAll'                 : require('@/components/Atom/Select/SelectAll').default,
        'simpleButton': require('@/components/Atom/Buttons/SimpleButton').default,
		'buttonsFilter'             : require('@/components/Atom/Buttons/ButtonsFilters').default,
		'subtitle'                  : require('@/components/Atom/Subtitle/Subtitle').default,
		'tableRelatorio'            : require('@/components/Atom/Table/TableRelatorio').default,
		'statusInformation'         : require('@/components/Atom/StatusInformation/StatusInformation').default,
	},

	validations:{
		selectedEmpresas:{
			required
		},
		selectedVLLP:{
			required
		},
		arrayValuesFiltros:{
			required
		},
        selectedTurnos:{
            required
        }
	},

	data(){
		return {
			mdiMapMarkerCheck:mdiMapMarkerCheck,
			mdiThumbDownOutline:mdiThumbDownOutline,
            mdiMapMarkerRemoveVariant:mdiMapMarkerRemoveVariant,
			mdiCheckBold:mdiCheckBold,
            mdiAlertCircleOutline:mdiAlertCircleOutline,
			mdiTransitConnectionVariant:mdiTransitConnectionVariant,
			datas: '',
			dataDoRelatorio: '',
			labelEmpresas:[{indexDFH: 'EM', description: 'Empresa *'}],
            labelTurnos:[{indexDFH: 'TR', description: 'Turno *'}],
			optionsEmpresas: new EmpresasService().Get(),
            optionsTurnos: [
                {'value': 'P', 'description': '1° Turno'},
                {'value': 'S', 'description': '2° Turno'},
                {'value': 'T', 'description': '3° Turno'}
            ],
			selectedEmpresas: [],
			labelVeicLinhasPrincipais:[
				{indexDFH: 'V', description: 'Veículos *'},
				{indexDFH: 'L', description: 'Linhas *'},
				{indexDFH: 'LP', description: 'Linhas principais *'},
			],
			selectVLLP: [],
			arrayAllOpt: [],
			optionsVLLP: [],
			selectedVLLP: [],
            selectedTurnos: [],
			radiosSelected: 'V',
			arrayButtons:[
				{'value':'I', 'text':'Iniciadas', 'title':'Iniciadas', 'width': '48%', 'icon': '', 'extraClass': 'buttonFiltrosRotas', 'isSelected':false},
				{'value':'F', 'text':'Finalizadas', 'title':'Finalizadas', 'width': '48%', 'icon': '', 'extraClass': 'buttonFiltrosRotas', 'isSelected':false},
				{'value':'P', 'text':'Pendentes', 'title':'Pendentes', 'width': '48%', 'icon': '', 'extraClass': 'buttonFiltrosRotas', 'isSelected':false},
				{'value':'C', 'text':'Canceladas', 'title':'Canceladas', 'width': '48%', 'icon': '', 'extraClass': 'buttonFiltrosRotas', 'isSelected':false},
			],
			itemsSubtitleLinha: [
				{ 'description': 'Horário programado', 'spanColor': 'light-green', 'icone': '' },
				{ 'description': 'Atraso tolerado', 'spanColor': 'orange', 'icone': '' },
				{ 'description': 'Atrasado', 'spanColor': 'red', 'icone': '' },
			],
			itemsSubtitleStatusLinha: [
				{ 'description': 'C - Canceladas', 'spanColor': '', 'icone': '' },
				{ 'description': 'F - Finalizadas', 'spanColor': '', 'icone': '' },
				{ 'description': 'I - Iniciadas', 'spanColor': '', 'icone': '' },
				{ 'description': 'P - Pendentes', 'spanColor': '', 'icone': '' },
			],
			arrayValuesFiltros: [],
			htmlRelatorio: '',
			statusbar: 'info',
			tamanhoRelatorio: 0,
			dadosRelatorio: [],
			iconFlecha: false,
			actived: false,
			idCollapse : '',
			loadingExportar : [false, false, false],
			loadingPanel    : false,
			carregando      : false,
            selectedEmpresa : [],
            selectedTurno: []
		}
	},
	methods:{
        ...mapGetters(['getMaster']),

		SelectEmpresas(selecionados){
			this.selectedEmpresas = selecionados;
            this.dadosRelatorio = [];
            this.selectVLLP.clearAll();
			this.dados_filtros();
		},
        
        /**
         * recebe o valor dos turnos
         * @param selecionados 
         * @author Otávio 🦆
         */
        selectTurnos(selecionados){
            this.selectedTurnos = selecionados;
            this.dadosRelatorio = [];
        },

		SelectCriadoVLLP(select){
			this.selectVLLP = select
            this.dadosRelatorio = [];
		},

		changeCheck(check){
			this.radiosSelected = check;
			if(this.arrayAllOpt.length != 0){
				this.optionsVLLP = this.arrayAllOpt[check];
			}
		},

		selectLinhasVeiculos(selecionados){
            this.selectedVLLP = selecionados;
            this.dadosRelatorio = [];
		},

		changeFiltros(value){
			this.arrayValuesFiltros = value;
            this.dadosRelatorio = [];
		},

		changeData(data){
			this.datas = data;
		},

		async dados_filtros(){
			this.carregando = true
			var dados = [];
			dados = await new FiltrosService().dados_filtros(this.selectedEmpresas, ['V', 'L', 'LP'])
			this.arrayAllOpt = dados;
			this.changeCheck(this.radiosSelected);
			this.carregando = false
		},

		async gerarRelatorio(){
			this.loadingPanel = true;
			this.htmlRelatorio = '',
			this.dadosRelatorio = [];
			this.dataDoRelatorio = this.datas;
			this.tamanhoRelatorio = 0;
			var data = await new HttpRequest().Post('/coletivos/relatorios/detalhes/linhas/gerar/relatorio', 
				{'cliente': this.selectedEmpresas, 
					'data': this.datas, 
					'TipoStatus': this.arrayValuesFiltros, 
					'tipobusca': this.radiosSelected, 
					'selectVeicLI': this.selectedVLLP,
                    'selectTurnos': this.selectedTurnos
                });
			this.tamanhoRelatorio = data.data.tamanho;
			if(this.tamanhoRelatorio > 0){
				this.dadosRelatorio = data.data.linhas;
			}else{
				this.statusbar = 'error';
			}
			this.loadingPanel = false
		},

		async exportarRelatorio(type){
			var root =  process.env.VUE_APP_ROOT;
			if(this.tamanhoRelatorio > 0){
				switch(type){
					case 'pdf':
						this.loadingExportar = [true, false, false]
						break;
					case 'xls':
						this.loadingExportar = [false, true, false]
						break;
					case 'csv':
						this.loadingExportar = [false, false, true]
						break;
					default:
						break;
				}
				var data = await new HttpRequest().Post('/coletivos/relatorios/detalhes/linhas/exportar/relatorio', 
					{'arrayDados':JSON.stringify(this.dadosRelatorio), 'tipo':type, 'data':this.dataDoRelatorio});
				window.open(root+'/'+data.data.local);
			}
			this.loadingExportar = [false, false, false]
		},
	},

    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optionsEmpresas
        }
    }
})
</script>

<style lang="scss">
    #detalhesLinha{
        .buttonFiltrosRotas{
            &.bt-white{
                span{
                    &::before{
                        display: none;
                    }
                }
            }
        }

        .tbodyDetalhesLinhas{
            background: #fff !important;
        }


        .trDataTabela{
            background: #fff !important;
        }
        .td{
            font-size: 11px !important;
        }
        .tr{
            font-size: 11px !important;
        }
        .fonteTabelaInterna{
            font-size: 11px !important;
        }
        .fonteTabelaExterna{
            font-size: 12px !important;
        }
        .cinzaClaro{
            background: #dbdde2;
        }
        .cinzaEscuro{
            background: #b1b2b5;
        }
        .th-km {
            flex: 1;
        }
        .dataTabela{
            min-width: 30%;
            text-align: center;
            vertical-align: middle;
            background: #777777;
            height: 35px;
            margin-top: 20px !important;
            color: #fff;
            // border-top-left-radius: 5px;
            // border-top-right-radius: 5px;
            font-weight: 500;
            font-size: 1 !important;
        }

        .dadosTabela{
            td{
                border: 1px solid #d5d5d5;
            }
        }
        .fonteTabelaExterna > div {
            display: inline-block;
            text-align: center;
        }

        .fonteTabelaExterna > div:first-child {
            width: 15%;
            padding-right: 8px; 
            
        }
        .chevronTop{
            vertical-align: top;
            display: block;
        }
        .fonteTabelaExterna > div:last-child {
            width: 85%;
            padding-left: 8px; 
        }

        .sem-borda{
            padding: 0px;
            border: none !important;
            
        }
        .color-white{
            background: #fff !important;
        }
        .collapse{
            padding-left: 0px;
            padding-right: 0px;
        }

        .dadosChevron{
            background: #fff !important;
            border-bottom: 1px solid #d5d5d5;
            border-left: 1px solid #d5d5d5;
            border-right: 1px solid #d5d5d5;
            padding-left: 0px;
        }
        
        .cor-vermelha{
            font-size: 14px;
            color: #e04b6e;
        }

        .cor-verde{
            font-size: 14px;
            color: #53a62d;
        }

        .mensagemChevron{
            color: #e04b6e;
        }

        .descPontoDL{
            text-align: center;
        }
        .alertMsgDL{
            text-align: center;
            color: #e04b6e;
        }
        .trDeAcordoComOProgramado{
            background-color: #b5cd8e !important;
        }
        .trEmAtrasoTolerado{
            background-color: #f9c576 !important;
        }
        .trAtrasada{
            background-color: #f0a7a6 !important;
        }
        .btnLegenda{
            position: absolute;
            right: 325px;
            z-index: 9999;
        }
    }
</style>
