Componente para fazer a 'pop up' dentro do marcador do mapa 
Não possue eventos. 
slots: 
 sem nome - Qualquer conteudo pode ser posto aqui, para usar mais de um componente dentro do slot 
 pode ser necessário usar a tag 'template'

<template>
  <l-popup v-on='$listeners' v-bind='$attrs'>
    <slot><h3>Conteudo vai aqui🍻🧩</h3></slot>
  </l-popup>
</template>

<script>
  import Vue from 'vue'
  import {LPopup} from 'vue2-leaflet'
  export default Vue.extend({
    name:'popUpMarcador',
    inheritAttrs:false,
    components: {
      LPopup,
    },
    methods: {

    },
  })
</script>
