<template>
    <panelEagle id="CadastroComandos" :loading="loadingPanel">
        <div class="col-sm-12 row">
            <div class="col-sm-4 nopadding">
                <tituloPage :icon="mdiMessageTextOutline" titulo="Cadastro de comandos"/>
            </div>
            <div class="col-sm-8 nopadding divDontPrint">
                <basicButtonsCadastrosSC
                    :disabled="$v.$invalid"
                    @salvarCadastro='salvarCadastro'
                    linkCancel="listarComandos"
                    tipoCancela="button"/>
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <div class="col-sm-12 row nopadding">
            <div class="col-sm-4 nopadding divDontPrint">
                <inputSimple :value="valueDescricao" label="Descrição*" @changeInput="changeDescricao"/>
            </div>
            <div class="col-sm-4 nopadding divDontPrint">
                <selectAll
                    :selected="selectedModeloRastreador"
                    @changeSelect="changeModelosRastreador"
                    :labels="[{indexDFH:'EM', description: 'Modelo de rastreador*'}]"
                    nameForRadio="rastreador"
                    :optionsArray="optRastreador"/>
            </div>
            <div class="col-sm-4 nopadding divDontPrint">
                <inputSimple :value="valueComando" label="Comando*" @changeInput="changeComando"/>
            </div>
        </div>
        <div class="col-sm-12 nopadding">
            <requiredFields></requiredFields>
        </div>
        <div class="col-sm-12"><hr></div>
    </panelEagle>
</template>
<script lang="js">
import Vue from 'vue'
import { required } from 'vuelidate/lib/validators'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { conectionError } from  '@/Services/Helpers/swellHeper.js'
import { mdiMessageTextOutline } from '@mdi/js'
export default Vue.extend({
    name:'CadastroComandos',
    validations: {
        valueComando:{ required },
        valueRastrador:{ required },
        valueDescricao:{ required },
    },
    components:{
        selectAll   : require('@/components/Atom/Select/SelectAll').default,
        panelEagle  : require('@/components/Atom/Panel/PanelEagle').default,
        tituloPage  : require('@/components/Atom/Header/Titulo').default,
        inputSimple : require('@/components/Atom/Inputs/InputSimple').default,
        'requiredFields': require('@/components/Atom/Footer/RequiredFields').default,
        basicButtonsCadastrosSC : require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
        
    },
    data(){
        return {
            url:'/administrativo/cadastros/comandos/salvar/comando',
            selectedModeloRastreador:[],
            valueComando:'',
            valueRastrador:[],
            valueDescricao:'',
            optRastreador:[],
            loadingPanel:false,
            mdiMessageTextOutline:mdiMessageTextOutline,
        }
    },
    methods: {
        changeDescricao(value){
            this.valueDescricao = value
        },

        changeModelosRastreador(value){
            this.valueRastrador = value
        },

        changeComando(value){
            this.valueComando = value
        },

        async salvarCadastro(){
            this.loadingPanel = true
            var obj = {
                mccodigo    : this.$route.params.id,
                mccomando   : this.valueComando,
                mcmodelo    : this.valueRastrador[0],
                mcdescricao : this.valueDescricao,
            }
            var dados = await new HttpRequest()
                .Post(this.url, {'dados':obj})
            if(dados.data.retorno){
                this.$router.push({name:'listarComandos'})
            }else{
                conectionError()
            }
            this.loadingPanel = false
        },

        arrumaDados(dados){
            this.selectedModeloRastreador = [{
                value:dados[0].value,
                description:dados[0].description
            }]
            this.valueDescricao = dados[0].mcdescricao
            this.valueComando = dados[0].mccomando
        },
    },
    async mounted(){
        this.loadingPanel = true
        try{
            var dados = await new FiltrosService().dados_filtros(this.empresasArray, ['MO'])
            this.optRastreador = dados['MO']
            if(this.$route.params.id){
                this.url = '/administrativo/cadastros/comandos/atualizar/comando'
                var consulta = await new HttpRequest()
                    .Post('/administrativo/cadastros/comandos/busca/dados', {dados:this.$route.params.id})
                this.arrumaDados(consulta.data.consulta)
            }
        }catch{
            conectionError()
        }
        this.loadingPanel = false
    },
})
</script>
<style lang="scss">
    #CadastroComandos{
        
    }
</style>