<template>
    <div ref="divPrimeira" id="app">
        <fullscreen ref="fullscreen">
            <div class="nav-menu">
                <Navbar v-if="$route.name!='Finder'" />
                <div :id="getTelaRestrita()? '':'naveg'">
                    <router-view />
                </div>
                <ToastCustom key="toast_app" @reload="reload" />
            </div>
            <Footer></Footer>
        </fullscreen>
    </div>
</template>

<script>
import Vue from 'vue'
import Navbar from '@/components/Atom/Header/Header.vue';
import Footer from '@/components/Atom/Footer/Footer.vue';
import ToastCustom from '@/components/Atom/Toast/ToastCustom.vue';

export default Vue.extend({
	name: 'naveg',
	components: {
		Navbar,
		Footer,
		ToastCustom,
	},

	data() {
		return {
			idUser: '',
			name: '',
			email: '',
		}
	},
	metaInfo: {},
	methods: {
		reload() {
			this.$meta().refresh()
			const { set, remove } = this.$meta().addApp('custom')
			set({ meta: [{ 'http-equiv': 'refresh', 'content': 0.5 }] })

			// window.location.reload(true)
			
			// let navigFirefox = window.navigator.userAgent.indexOf('Firefox')
            // if(navigFirefox > -1) {
            //     this.$nextTick(() => {
            //         window.location.reload(true)
            //     })
            // } else {
            //     this.$router.go(0)
            // }
		},

		getTelaRestrita() {
			return this.$route.name=='login' || this.$route.name=='Finder' || this.$route.name=='PasswordReset' || this.$route.name=='RecoverPassword'
		},

		montaIntercom() {
			this.setUsuario()
			this.bootIntercom()
		},

		setUsuario() {
			const usuario = this.$store.getters.getUser
			this.idUser = usuario.idUser
			this.name = usuario.name
			this.email = usuario.email
		},

		bootIntercom() {
			this.$intercom.boot({
				user_id: this.idUser,
				name: this.name,
				email: this.email,
			});
		},

		shutdownIntercom() {	
			this.$intercom.shutdown()
		},
	},

	mounted() {
		this.$forceUpdate()
		this.montaIntercom()
	},

	watch: {
		'$route' () {
			if (this.getTelaRestrita() && this.$intercom) {
				this.shutdownIntercom()
			} else {
				this.montaIntercom()
			}
		},
	},

});
</script>

<style lang="scss">
*{
	font-size:13px;
	.subTitle{
		font-size:13px!important;
	}
	.text-10px{
		font-size:10px!important;
	}
}
.nowrap{
	white-space:nowrap;
}
body {
	font-family: nexabook, sans-serif !important;
}
:not(:root):fullscreen::backdrop {
	background: white !important;
}
.nav-menu {
	// font-family: "Avenir", Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #ffffff;
}
#naveg {
	padding: 25px;
	a {
		font-weight: bold;
		// color: #fff;
		&.router-link-exact-active {
			color: #fff;
		}
	}
	.justpaddingbottom{
		padding: 0 0 10px 0 !important;
	}
	.nopadding{
		padding: 0px!important;
	}
	.justpaddingbottom{
		padding: 0 0 5px 0!important;
	}
	.nomargin{
		margin: 0px !important;
	}
}
.justpaddingbottom {
	padding: 0 0 10px 0 !important;
}
.nopadding {
	padding: 0px !important;
}
.justpaddingbottom {
	padding: 0 0 5px 0 !important;
}
.nomargin {
	margin: 0px !important;
}
.text-long {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 100%;
}

// Altera a altura ldos scrolls do sistema todo
*::-webkit-scrollbar {
	height: 10px;
}
</style>
