import { AuthService } from '../../../Services/auth/Auth.Service';
import Layout from '../../../components/Atom/Layout/Layout.vue'
import Relatorio from '../../Children.vue'
import Cadastros from '../../Children.vue'
import NaoMenus from '../../Children.vue'
import HorarioLinha from '../Relatorios/HorarioLinhas.vue'
import EscalasMotorista from '../Relatorios/EscalasMotorista.vue'
import InconformidadeEscalas from '../Relatorios/InconformidadeEscalas.vue'
import Linhas from '../Relatorios/Linhas.vue'
import AnaliseProdutividade from '../Relatorios/AnaliseProdutividade.vue'
import DetalhesLinhas from '../Relatorios/DetalhesLinhas.vue'
import Cancelamento from '../NaoMenus/Cancelamentos.vue'
import Integracao from '../NaoMenus/Integracao.vue'
import Testes from '@/views/Mapa.vue'
import ConfirmacoesEscalas from '../NaoMenus/ConfirmacoesEscalas.vue'
import MonitoramentoLinhas from '../NaoMenus/MonitoramentoLinhas.vue'
import ListagemEscalas from '../Cadastros/Escalas/ListagemEscalas.vue'
import CadastroEscalas from '../Cadastros/Escalas/CadastroEscalas.vue'
import { RouteConfig } from 'vue-router'
import ListagemLinhas from '@/views/Coletivos/Cadastros/Linhas/ListagemLinhas.vue'
import CadastroLinhas from '@/views/Coletivos/Cadastros/Linhas/CadastroLinhas.vue'
export const ifAuthenticated = (_to: any, _from: any, next: any) => {
	if (new AuthService().isAuthenticated()) {
		next()
		return
	}
	next('/login')
}

export const coletivosRoutes: RouteConfig =
{
	path: '/coletivos',
	name: 'Coletivos',
	beforeEnter: ifAuthenticated,
	meta: {
		requiresAuth: true,
		Module: 'coletivos',
		breadcrumbName: 'Coletivos'
	},
	components: {
		default: Layout
	},
	children: [
		{
			path: 'cadastros',
			meta:
			{
				Module: 'coletivos',
				breadcrumbName: 'Cadastros'
			},
			components:
			{
				content: Cadastros
			},
			children: [
				//Linhas
				{
					path: 'linhas', 
					name: 'listarLinha', 
					meta: 
					{
						Module: 'coletivos', 
						breadcrumbName: 'Linhas' 
					}, 
					component: ListagemLinhas 
				},
				{
					path: 'linhas/cadastrar/:id?/:cp?', 
					name: 'cadastrarLinha', 
					meta: 
					{
						Module:'coletivos', 
						breadcrumbName: 'Linhas'
					}, 
					component: CadastroLinhas
				},
				// Escalas
				{
					path: 'escalas',
					name: 'listarEscalas',
					meta: {
						Module: 'coletivos',
						breadcrumbName: 'Escalas'
					},
					component: ListagemEscalas
				},
				{
					path: 'escalas/novo/:id?/:cp?',
					name: 'cadastrarEscalas',
					meta: 
					{
						Module:'coletivos',
						breadcrumbName: 'Escala'
					},
					component: CadastroEscalas
				},
			]
		},

		// Não Menus
		{
			path: '',
			meta:
			{
				Module: 'coletivos',
				breadcrumbName: ''
			},
			components:
			{
				content: NaoMenus
			},
			children: [
				// Integração
				{
					path: 'integracao',
					meta:
					{
						Module: 'coletivos',
						breadcrumbName: 'Integração'
					},
					component: Integracao
				},
				// Monitoramento Linhas
				{
					path: 'monitoramento/linhas',
					meta:
					{
						Module: 'coletivos',
						breadcrumbName: 'Monitoramento linhas'
					},
					component: MonitoramentoLinhas
				},
			]
		},

		//Relatórios
		{
			path: 'relatorios',
			meta:
			{
				Module: 'coletivos',
				breadcrumbName: 'Relatorios'
			},
			components:
			{
				content: Relatorio
			},
			children: [

				// Horários Linhas
				{
					path: 'horarios/linhas',
					name: 'horarioLinha',
					meta: 
					{
						Module:'coletivos', 
						breadcrumbName: 'Horários linhas'
					},
					component: HorarioLinha
				},

				// Escalas Motoristas
				{
					path: 'escalas/motorista',
					name: 'escalaMotorista',
					meta: 
					{
						Module:'coletivos', 
						breadcrumbName: 'Escalas motorista'
					},
					component: EscalasMotorista
				},

				// Inconformidade Escalas
				{
					path: 'inconformidade/escala',
					name: 'inconformidadeEscala',
					meta: 
					{
						Module:'coletivos', 
						breadcrumbName: 'Inconformidades de escala'
					},
					component: InconformidadeEscalas
				},

				// Linhas
				{
					path: 'linhas',
					name: 'linha',
					meta: 
					{
						Module:'coletivos', 
						breadcrumbName: 'Linhas'
					},
					component: Linhas
				},

				// Analise de Produtividade
				{
					path: 'analise/produtividade',
					name: 'analiseProdutividade',
					meta: 
					{
						Module:'coletivos', 
						breadcrumbName: 'Análise de produtividade'
					},
					component: AnaliseProdutividade
				},

				// Detalhes Linhas
				{
					path: 'detalhes/linhas',
					name: 'detalhelinha',
					meta: 
					{
						Module:'coletivos', 
						breadcrumbName: 'Detalhes de linhas'
					},
					component: DetalhesLinhas
				},

				// Cancelamentos (É um não menu porém link do banco chama relatório)
				{
					path: 'cancelamento',
					name: 'cancelamento',
					meta: 
					{
						Module:'coletivos', 
						breadcrumbName: 'Cancelamentos'
					},
					component: Cancelamento
				},

				// Confirmações Escalas (É um não menu porém link do banco chama relatório)
				{
					path: 'escalas/confirmar',
					name: 'escalaConfirmar',
					meta: 
					{
						Module:'coletivos', 
						breadcrumbName: 'Confirmações de escalas'
					},
					component: ConfirmacoesEscalas
				},
			]
		},
	]
}
