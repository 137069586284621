<template>
    <div>
        <modalEagle 
            :id="idModal"
            title="Ajustes"
            size='lg'>
            <slot slot="modalBody">
                <tr> Qual operação deseja realizar? </tr>
                <div class="col-sm-12 justpaddingbottom row">
                    <div class="col-sm-6 row divModalCarro">
                        <simpleButton 
                            title="Ajustar eventos do veículo"
                            :icon='mdiCarSettings'
                            sizeIcon='80'
                            class="buttonModalCarro"
                            event="click" 
                            @click="modalCarro"
                            type="blue">
                        </simpleButton>
                    </div>
                    <div class="col-sm-6 row">
                        <simpleButton 
                            title="Ajustar eventos do colaborador"
                            :icon='mdiAccountSettings' 
                            sizeIcon='80'
                            class="buttonModalColaborador"
                            event="click" 
                            @click="modalColaborador"
                            type="blue">
                        </simpleButton>
                    </div>
                </div>
            </slot>
            <slot >
                <simpleButton 
                    :icon="mdiCheckBold" 
                    @click="cancelButtonModalAjustes"
                    text="Finalizar" 
                    event="click" 
                    type="blue">
                    </simpleButton>
            </slot>
        </modalEagle>

        <!-- <ModalAjustesVeiculos2 
            idModal="modalAjusteVeiculos"
            ref="modalAjusteVeiculos"/> -->

        <ModalAjustesVeiculos 
            idModal="modalAjusteVeiculos"
            ref="modalAjusteVeiculos"/>
        
        <modalAjustesColaborador 
            idModal="modalAjustesColaborador"
            ref="modalAjustesColaborador"/>
    </div>
</template>
<script>
import Vue from 'vue'
import { mdiCarSettings, mdiAccountSettings, mdiCheckBold} from '@mdi/js'
export default Vue.extend({
    name:'ModalAjustes',
    components:{
        'simpleButton'            : require('@/components/Atom/Buttons/SimpleButton').default,
        'modalEagle'              : require('@/components/Atom/Modal/ModalEagle').default,
        'ModalAjustesVeiculos'    : require('@/components/Atom/ModalJornadaFolha/ModalAjustesVeiculos').default,
        // 'ModalAjustesVeiculos2'    : require('@/components/Atom/ModalJornadaFolha/ModalAjustesVeiculos2').default,
        'modalAjustesColaborador' : require('@/components/Atom/ModalJornadaFolha/ModalAjustesColaborador').default,
    },

    props: {
		idModal: {
			type: [Number, String],
			required: true
		},
	},

    data(){
        return{
            // Modal Ajustes
            mdiCarSettings : mdiCarSettings,
            mdiAccountSettings : mdiAccountSettings,
            mdiCheckBold: mdiCheckBold,
            empresas: [],
        }
    },

    methods:{
        /**
         * @description Essa função deve ser utilizada por uma referência para "ativar"
         * essa modal, os argumentos da função são os necessários para 
         * conseguir popular a modal com as infomações corretas
         * @author Vitor Hugo 🐨 
         */
        preparaModalAjustes(empresas) {
            this.empresas = empresas
            this.$bvModal.show(this.idModal)
        },

        //Modal carro
        modalCarro(){
            this.$refs.modalAjusteVeiculos
                .preparaModalAjustesVeiculos(this.empresas)
            this.$bvModal.hide('modalAjustes')
        },

        modalColaborador(){
            this.$refs.modalAjustesColaborador
                .preparaModalAjustesColaborador(this.empresas)
            this.$bvModal.hide('modalAjustes')
        },

        //Método para fechar o modal Ajustes
        cancelButtonModalAjustes(){
            this.$bvModal.hide('modalAjustes')
            this.$bvModal.hide('modalColaborador')
            this.$bvModal.hide('modalCarro')
        },
    },
})
</script>
<style lang="scss">
    // Modal Ajustes
#modalAjustes{
    .divModalCarro{
        text-align: right !important;
    }
    .buttonModalCarro{
        margin-left: 200px;
        height: 150px !important;
        width: 150px !important;
        .flaticon-icon014::before{
            font-size: 70px !important;
        }
    }
    .buttonModalColaborador{
        height: 150px !important;
        width: 150px !important;
        .flaticon-icon010::before{
            font-size: 70px !important;
        }
    }
}

</style>
