<template>
    <panelEagle id="RelatorioChecklist" :loading="loadingPanel">
        <div class="col-sm-12 row">
            <div class="col-sm-6 nopadding">
                <titulo titulo="Checklist diário" :icon="mdiOrderBoolAscendingVariant"/>
            </div>
            <div class="col-sm-6 nopadding divDontPrint">
                <basicButtonsRelatoriosEIG
                    :loading="loadingExportar" 
                    @exportarRelatorio="exportarRelatorio"
                    :disabledDropdown="!Object.keys(tabelaGeral).length"
                    @gerarRelatorio="gerarRelatorio"
                    :disabled="$v.$invalid"/>
            </div>
        </div>
        <slideUpAndDown>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-4 nopadding">
                    <inputRangeWithLimit 
                        @changeInput="changeData"
                        name="InputRangeWithLimit"/>
                </div>
                <div class="col-sm-4 nopadding">
                    <selectAll
                        :isMultiple="true"
                        :hasSelectAll="true"
                        @changeSelect="changeEmpresa"
                        :labels=" [{indexDFH:'EM', description: 'Empresas*'}]"
                        nameForRadio="empresa"
                        :optionsArray="optEmpresa"
                        :selected="selectedEmpresa"/>
                </div>
                <div class="col-sm-4 nopadding">
                    <selectAll
                        @changeSelect="changeVeiculos"
                        :disabled="!valueEmpresa.length"
                        @changeCheck="changeRadioVeiculos"
                        :isMultiple="true"
                        :hasSelectAll="true"
                        ref="refVeiculos"
                        :loading="loadginVeiculos"
                        :labels="[
                            {
                                indexDFH:'V', 
                                description: 'Veículos*'
                            },
                            {
                                indexDFH:'GV', 
                                description: 'Grupos de veículos*'
                            },
                        ]"
                        firstSelected="V"
                        nameForRadio="veiculos"
                        :optionsArray="optVeiculosGeral"/>
                </div>
            </div>
        </slideUpAndDown>
        <div class="col-sm-12"><hr></div>
        <div class="col-sm-12 row nopadding">
            <tableRelatorio style="width: 100%;">
                <slot slot="thead">
                    <br>
                    <tr>
                        <th class="chevron"> </th>
                        <th>Data/hora</th>
                        <th>Contador</th>
                        <th>Motorista</th>
                        <th>Observação</th>
                    </tr>
                </slot>
                <slot slot="tbody">
                    <template v-for="(dados, index) in tabelaGeral">
                        <tr :key="index+'aaaa'" class="backColorTdPDF">
                            <td colspan="5" class="" style="padding:0px;">
                                <span class="newbadge">
                                    {{index}}
                                </span>
                            </td>
                        </tr>
                        <template v-for="(value, ind) in dados">
                            <tr :key="ind+''+value+''+index"
                                class="borda-tr"
                                @click="value.clicado = !value.clicado">
                                <td class="chevron cursor-pointer nopadding pt-1" title="Detalhes">
                                    <b-icon
                                        v-if="value.detalhes.length"
                                        :icon="value.clicado ? 'chevron-down' : 'chevron-right'"/>
                                </td>
                                <td class="cursor-pointer pt-1">{{value.data}}</td> 
                                <td class="cursor-pointer pt-1">{{value.chdcontador}}</td>
                                <!-- {{value.vebasecalculomedia}} -->
                                <td class="cursor-pointer pt-1">{{value.mtnome}}</td> 
                                <td class="cursor-pointer pt-1">{{value.chdobservacao}}</td>
                            </tr>
                            <tr :key="index+''+ind+''+value" 
                                class="tr-colapse backColorTdPDF"
                                v-if="value.detalhes.length">
                                <td colspan="5" class="td-colapse" style="margin:none;padding-left: 0px;padding-right: 0px;border-top:0px hidden;"> 
                                    <b-collapse :visible="value.clicado">
                                        <div class="col-sm-12 row nopadding fundo-colapse">
                                            <b-overlay :show='false' :opacity='0.65' style="width:100%">
                                                <div class="col-sm-12 row nopadding" style="width:100%; max-height:300px; overflow:auto">
                                                    <table style="width:100%">
                                                        <thead>
                                                            <tr>
                                                                <th style="background-color:#f5f5f5">Item</th>
                                                                <th style="background-color:#f5f5f5">Situação</th>
                                                                <th style="background-color:#f5f5f5">Observação</th>
                                                                <th style="background-color:#f5f5f5;max-width:10px"></th> <!-- zoin -->
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <template v-for="(valueDetalhes, indexDetalhes) in value.detalhes">
                                                                <tr :key="indexDetalhes+''+index+''+ind">
                                                                    <td>{{valueDetalhes.cdddescricaoitem}}</td>
                                                                    <td :class="valueDetalhes.cddsituacao?'cor-verde':'cor-vermelho'">
                                                                        <baseIcon
                                                                            :size="12"
                                                                            :icon="valueDetalhes.cddsituacao ? mdiCheckBold:mdiCloseThick"/>
                                                                    </td>
                                                                    <td>{{valueDetalhes.cddobservacao}}</td>
                                                                    <td v-if="valueDetalhes.temimg" 
                                                                        @click="abreModal(
                                                                            valueDetalhes.cddcodigo, 
                                                                            index, 
                                                                            valueDetalhes.cdddescricaoitem,
                                                                            value.data)"
                                                                        class="cursor-pointer cor-azul" 
                                                                        style="max-width:10px">
                                                                        <baseIcon
                                                                            :size="12"
                                                                            :icon="mdiEye"/>
                                                                    </td>
                                                                    <td v-else></td>
                                                                </tr>
                                                            </template>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </b-overlay>
                                        </div>
                                    </b-collapse>
                                </td>
                            </tr>
                        </template>
                    </template>
                    <statusInformation
                        v-show="!Object.keys(tabelaGeral).length" 
                        :statusBar="statusBar"/>
                </slot>
            </tableRelatorio>        
        </div>
        <modalBaixarImagemChecklist 
            :codigo="codigoModal" 
            :placa="placaModal" 
            :item="itemModal"
            :data="dataModal"
        />
    </panelEagle>
</template>
<script lang="js">
import Vue from 'vue'
import { DateTime        } from 'luxon'
import { required        } from 'vuelidate/lib/validators'
import { HttpRequest     } from '@/Services/auth/HttpRequest.Service'
import { 
	mdiOrderBoolAscendingVariant, 
	mdiCheckBold, 
	mdiCloseThick,
	mdiEye } from '@mdi/js'
import { FiltrosService  } from '@/Services/filtros/filtros.Service'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { conectionError  } from '@/Services/Helpers/swellHeper.js'
import { mapGetters } from 'vuex'

export default Vue.extend({
	name:'RelatorioChecklist',
	validations:{
		valueEmpresa:{ required },
		valueVeiculos:{ required },
	},
	components:{
		titulo: require('@/components/Atom/Header/Titulo').default,
		baseIcon:require('@/components/Atom/Icon/BaseIcon.vue').default,
		selectAll: require('@/components/Atom/Select/SelectAll').default,
		panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
		tableRelatorio: require('@/components/Atom/Table/TableRelatorio').default,
		slideUpAndDown: require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
		inputRangeWithLimit: require('@/components/Atom/Datas/InputRangeWithLimit').default,
		statusInformation: require('@/components/Atom/StatusInformation/StatusInformation').default,
		basicButtonsRelatoriosEIG: require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
		modalBaixarImagemChecklist: require('@/components/Atom/ModalSpecific/modalBaixarImagemChecklist').default,

	},
	data() {
		return{
			mdiEye:mdiEye,
			mdiOrderBoolAscendingVariant:mdiOrderBoolAscendingVariant,
			mdiCheckBold:mdiCheckBold,
			mdiCloseThick:mdiCloseThick,
			optEmpresa:new EmpresasService().Get(),
			optVeiculos:[],
			optGrupoVeiculos:[],
			optVeiculosGeral:[],

			// values
			valueEmpresa:[],
			valueVeiculos:[],
			valueData:'',
			tabelaGeral:{},
			codigoModal:0,
			placaModal:'',
			itemModal:'',
			dataModal:'',

			// controle
			loadginVeiculos:false,
			valueRadioVeiculos:'V',
			statusBar:'info',
			loadingPanel:false,
			loadingExportar: [false, false, false],
			urlBase:'/manutencoes/relatorios/checklist',
			selectedEmpresa: [],
		}
	},

	mounted(){
		var dataAtual = DateTime.now().toFormat('dd/MM/yyyy')
		this.valueData = `${dataAtual} - ${dataAtual}`
		if(!this.getMaster()){
			this.selectedEmpresa = this.optEmpresa
		}
	},


	methods:{
		...mapGetters(['getMaster']),

		/**
         * @param {string|Number} codigo - codigo do detalhe
         * @param {string} placa - (placa | prefixo) do veiculo
         * @param {string} item - qual é o item
         * @param {string} data - data e hora que foi feito o checklist 
         * @listens Click - espera um click na td para abir o modal e ver a imagem salva
         * @description Abre o modal de baixar img, e arruma os valores para passar ao modal
         * @author Lucas Eduardo
         */
		abreModal(codigo, placa, item, data){
			this.codigoModal = codigo
			this.placaModal = placa.replace(' | ', '-')
			this.itemModal = item != null ? item : ''
			var dataa = data
			this.dataModal = dataa.replace(/\/|:| /gi, '-')
			this.$nextTick(()=>{
				this.$bvModal.show('modalBaixarImagemChecklist')
			})
		},



		/**
         * @listens gerar-um-relatorio
         * @description faz a consulta para gerar a tabela, precisa de todos os valores da tela pra gerar
         * @author Lucas Eduardo
         */
		async gerarRelatorio(){
			this.loadingPanel = true
			this.tabelaGeral = {}
			var obj = {
				empresas:this.valueEmpresa,
				veiculos:this.valueVeiculos,
				tipo:this.valueRadioVeiculos,
				data:this.valueData
			}
			try{
				new HttpRequest().Post(`${this.urlBase}/gerar/tabela`, obj)
					.then((dados)=>{
						if(dados.status){
							this.tabelaGeral = dados.data.consulta
						}else{
							conectionError()
						}
					}).finally(()=>{
						this.loadingPanel = false
					})
			}catch(error){
				conectionError()
				this.loadingPanel = false
			}
		},

		/**
         * @param {string} value - pdf, xls, csvefefef
         * @listens exportar-um-relatorio
         * @description atribui o loading para o exportar e gera o documento escolhido
         * @author Lucas Eduardo
         */
		async exportarRelatorio(value){
			switch(value){
				case 'pdf':
					this.loadingExportar = [true, false, false]
					break;
				case 'xls':
					this.loadingExportar = [false, true, false]
					break;
				case 'csv':
					this.loadingExportar = [false, false, true]
					break;
				default:
					break;
			}
			var obj = {
				tabela:this.tabelaGeral,
				tipo:value,
                data:this.valueData
			}
			try{
				var root =  process.env.VUE_APP_ROOT
				var dados = await new HttpRequest().Post(`${this.urlBase}/exportar/geral`, obj)
				open(root+'/'+dados.data.resposta)
			}catch(error){
				conectionError()
			}
			this.loadingExportar = [false, false, false]
		},

		/**
         * @param {string} value - V ou GV
         * @listens radio-veiculos
         * @description escolhe qual é o radio atual para atribuir o valores de veiculos
         * @author Lucas Eduardo
         */
		changeRadioVeiculos(value){
			this.tabelaGeral = {}
			this.valueRadioVeiculos = value
			if(value == 'V'){
				this.optVeiculosGeral = this.optVeiculos
				return
			}
			this.optVeiculosGeral = this.optGrupoVeiculos
		},

		changeVeiculos(value){
			this.tabelaGeral = {}
			this.valueVeiculos = value
		},

		changeData(value){
			this.valueData = value
		},


		/**
         * @param {array} value - codigo das empresas
         * @listens trocar-de-empresa
         * @description restaura as variaveis para valor padrao e faz a consulta dos veiculos
         * @author Lucas Eduardo
         */
		async changeEmpresa(value){
			this.loadginVeiculos = true
			this.tabelaGeral = {}
			this.$refs.refVeiculos.clearAll()
			this.valueEmpresa = value
			var dados = await new FiltrosService().dados_filtros(value, ['V', 'GV'])
			this.optVeiculos = dados['V']
			this.optGrupoVeiculos = dados['GV']
			this.changeRadioVeiculos(this.valueRadioVeiculos)
			this.loadginVeiculos = false
		}
	},
})
</script>
<style lang="scss">
    #RelatorioChecklist{
        .fundo-colapse{
            width: 100%;
            margin: 10px;
            background-color: #efefef!important;
        }
        .cor-azul{
            color: #6ebcce;
        }
        .cor-verde{
            color: green;
        }
        .cor-vermelho{
            color: red;
        }
        .borda-tr{
            border-bottom: 1px solid #dee2e6;
        }
        .td-colapse{
            background-color: #efefef !important;
            // border: 1px solid red;
        }
    }
</style>