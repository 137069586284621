import { MarkerRotaManual } from "./NaoMenus/MarkerRotaManual"

class Regiao { 
	//fields
	codigo:number
	cor:string
	coordenadas:any
	
	//constructor
	constructor(codigo:number, cor:string, coordenadas:any) { 
	   this.codigo = codigo
	   this.cor = cor
	   this.coordenadas = coordenadas
	}
	
	getCoordenadas(type = 'array'):any {
		if(type === 'array') {
			return this.coordenadas.map((e) => [e.lat, e.lng])
		}
		return this.coordenadas
	}

	getCor():string {
		return this.cor
	}
}

class Pedido {
	ircodigo?: number;
	ircliente?: number;
	ircodigoexterno?: string;
	irnome?: string;
	podescricao?: string;
	potipo?: string;
	polatitude?: string;
	polongitude?: string;
	poraio?: number;
	pocodigo?: number;
	poregiao?: number;
	irdocumento?: string;
	irqtde?: number;
	ircubagem?: string;
	irpeso?: string;
	irvalor?: string;
	irordem?: number;
	irtempoparado?: number;
	tempoparadoctrl?: number;
	irinijanelaentrega?: string;
	irfimjanelaentrega?: string;
	irintrajornada?: boolean;
	irinterjornada?: boolean;
	irmarcador?: string;
	irobservacoes?: string;
	marcadores?: any[];
	irdata?: string;
	selecionado?: boolean;
	distancia?: number;
	distanciaseq?: number;
	duracao?: number;
	duracaoseq?: number;
	classepedido?: string;
	visualizar?: boolean;
	editando?: boolean;
	salvando?: boolean;
	show?: boolean;
	fixo?: boolean;
	html?: string;
	chaveOrigem?: number;
	marker?: MarkerRotaManual | null;
	size?: number;
	isTipoParada?: boolean;
  
	constructor(json: any) {
		this.ircodigo = json.ircodigo ?? null;
		this.ircliente = json.ircliente ?? null;
		this.ircodigoexterno = json.ircodigoexterno ?? null;
		this.irnome = json.irnome ?? null;
		this.podescricao = json.podescricao ?? null;
		this.potipo = json.potipo ?? null;
		this.polatitude = json.polatitude ?? null;
		this.polongitude = json.polongitude ?? null;
		this.poraio = json.poraio ?? null;
		this.pocodigo = json.pocodigo ?? null;
		this.poregiao = json.poregiao ?? 0;
		this.irdocumento = json.irdocumento ?? ' ';
		this.irqtde = json.irqtde ?? 0;
		this.ircubagem = json.ircubagem ?? 0;
		this.irpeso = json.irpeso ?? 0;
		this.irvalor = json.irvalor ?? 0;
		this.irordem = json.irordem ?? 0;
		this.irtempoparado = json.irtempoparado ?? 0;
		this.tempoparadoctrl = json.tempoparadoctrl ?? 0;
		this.irinijanelaentrega = json.irinijanelaentrega ?? null;
		this.irfimjanelaentrega = json.irfimjanelaentrega ?? null;
		this.irintrajornada = json.irintrajornada ?? false;
		this.irinterjornada = json.irinterjornada ?? false;
		this.irmarcador = json.irmarcador ?? '';
		this.irobservacoes = json.irobservacoes ?? '';
		this.marcadores = json.marcadores ?? '';
		this.irdata = json.irdata ?? null;
		this.selecionado = json.selecionado ?? false;
		this.distancia = json.distancia ?? 0;
		this.distanciaseq = json.distanciaseq ?? 0;
		this.duracao = json.duracao ?? 0;
		this.duracaoseq = json.duracaoseq ?? 0;
		this.classepedido = json.classepedido ?? '';
		this.visualizar = json.visualizar ?? true;
		this.editando = json.editando ?? false;
		this.salvando = json.salvando ?? false;
		this.show = json.show ?? true;
		this.fixo = json.fixo ?? false;
		this.html = json.html ?? '';
		this.chaveOrigem = json.chaveOrigem ?? null;
		const mrk = new MarkerRotaManual(this)
		this.marker = mrk;
		this.isTipoParada = (this.irintrajornada || this.irinterjornada) ?? false;
		this.size = this.isTipoParada ? 52 : 150;
	}
}

export {
	Regiao,
	Pedido,
}