<template>
    <panelEagle id='SolicitacaoAjustes' :loading="loadingPanel">
        <div class="col-sm-12 row nopadding">
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-6">
                    <tituloPage :icon='mdiClipboardClockOutline' titulo='Solicitação de ajustes' />
                </div>

                <div class="col-sm-6 divDontPrint">
                    <basicButtonsCadastrosEIN :disabledDropdown="this.dadosTabela.length == 0" :loading="loadingExportar"
                        linkNew='cadastrarCombustivel' tipoNovo='button' @exportarCadastro="exportarCadastro">
                        <template #novo>
                            <simpleButton id="filtrosPopover" type="orange" event="click" text="Filtrar"
                                :icon="mdiFilterMenuOutline" @click="abrePopover">
                            </simpleButton>
                        </template>
                    </basicButtonsCadastrosEIN>
                </div>
            </div>

            <div class="col-sm-12">
                <hr>
            </div>
        </div>

        <tableRelatorio>
            <slot slot="thead">
                <tr>
                    <th></th>
                    <th>Colaborador</th>
                    <th>Data da jornada</th>
                    <th>Data solicitação</th>
                    <th>Motivo</th>
                    <th>Tipo</th>
                    <th>Anexo</th>
                    <th>Ações</th>
                </tr>
            </slot>
            <slot slot="tbody">
                <br>
                <template v-for="(status) in dadosTabela">
                    <template v-for="(dados, d) in status">
                        <tr :key="d + '_acordeon'" v-if="true">
                            <td class="va-bl" v-b-toggle="'id-collapse-' + d + dados.cacodigo">
                                <b-icon :icon="dados.open ? 'chevron-down' : 'chevron-right'" />
                            </td>
                            <td class="va-bl row">
                                <!-- 
                                    comentado a foto na tarefa 2mdnw4w
                                    https://app.clickup.com/t/2mdnw4w
                                    <div class='col-sm-2 img-worker nopadding'>
                                        <img :src="'data:image/jpeg'+';base64,'+dados.foto">
                                    </div>
                                -->
                                <div class='col-sm-10 pl-1 p-0'>
                                    {{ dados.colaborador }}
                                </div>
                            </td>
                            <td class="va-bl">{{ dados.cadatajornada }}</td>
                            <td class="va-bl">{{ dados.cadatahorasolicitacao }}</td>
                            <td class="va-bl">{{ dados.camotivo }}</td>
                            <td class="va-bl">{{
                                dados.catipo == 'H' ? 'Horas' :
                                dados.catipo == 'A' ? 'Abono' : '' }}
                            </td>
                            <td class="va-bl">
                                <div v-show="dados.temdocumento" @click="modalDocumentos(dados)">
                                    <baseIcon class="cursor-pointer nopadding" :icon='mdiEyePlusOutline' size="20" />
                                </div>
                            </td>
                            <td v-if="dados.castatus == 'P'" align="center" width="150px">
                                <simpleButton v-if="getEditar()" type="green" event="click" :icon="mdiCheckBold"
                                    width="50px" @click="alterarStatus(dados.cacodigo, 'A')" />
                                <simpleButton v-if="getEditar()" type="red" event="click" width="50px" :icon="mdiCloseThick"
                                    @click="alterarStatus(dados.cacodigo, 'R')" />
                            </td>
                            <td v-if="dados.castatus != 'P'" width="150px" class="status">
                                <div v-if="dados.castatus == 'A'" style="text-align: center;
                                            background-color: #1caf9a;
                                            color: #fff;">
                                    Aprovado
                                </div>
                                <div v-if="dados.castatus == 'R'" style="text-align: center;
                                            background-color: #e04b4a;
                                            color: #fff;">
                                    Reprovado
                                </div>
                            </td>
                        </tr>
                        <tr :key="d + '_dados_acordeon'" v-if="true">
                            <td colspan="8" class="collapseAjustes">
                                <b-collapse :id="'id-collapse-' + d + dados.cacodigo"
                                    :key="'key-collapse-' + d + dados.cacodigo" v-model="dados.open">
                                    <template v-if="dados.catipo == 'H'">
                                        <div class="col-sm-12" style="display:inline-flex">
                                            <div class="col-sm-6">
                                                <div class="col-sm-12 mt-1 bold">
                                                    Dados Originais
                                                </div>
                                                <div class="col-sm-12 dadosCollapse row" align="center">
                                                    <div v-if="Object.keys(dados.dados.Originais).length == 0"
                                                        class="col-sm-12 pt-4">
                                                        Sem informações
                                                    </div>
                                                    <div v-for="(originais, tipo) in dados.dados.Originais" :key="tipo"
                                                        class="col-sm-6 row nopadding">
                                                        <div v-for="(dado, qnt) in originais"
                                                            :key="qnt + '_originais' + tipo"
                                                            class="col-sm-12 mt-2 mb-2 row nopadding">
                                                            <div v-if="tipo == 'entrada'" class="col-sm-12 nopadding">
                                                                <b> {{ qnt + 1 }}ª Entrada: </b> {{ dado }}
                                                            </div>
                                                            <div v-if="tipo == 'saida'" class="col-sm-12 nopadding">
                                                                <b> {{ qnt + 1 }}ª Saída: </b> {{ dado }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="col-sm-12 mt-1 bold">
                                                    Dados Solicitados
                                                </div>
                                                <div class="col-sm-12 dadosCollapse row">
                                                    <div v-for="(solicitados, tipo) in dados.dados.Solicitados" :key="tipo"
                                                        class="col-sm-6 row nopadding">
                                                        <div v-for="(dado, qnt) in solicitados"
                                                            :key="qnt + '_originais' + tipo"
                                                            class="col-sm-12 mt-2 mb-2 row nopadding">
                                                            <div v-if="tipo == 'entrada'" class="col-sm-12 nopadding">
                                                                <b> {{ qnt + 1 }}ª Entrada: </b> {{ dado }}
                                                            </div>
                                                            <div v-if="tipo == 'saida'" class="col-sm-12 nopadding">
                                                                <b> {{ qnt + 1 }}ª Saída: </b> {{ dado }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="dados.catipo == 'A'">
                                        <div class="col-sm-12 row">
                                            <div class="col-sm-3 row nopadding">
                                                <div class="col-sm-3 nopadding" style="text-align:right;font-weight:bold">
                                                    Jornada:
                                                </div>
                                                <div class="col-sm-9">
                                                    {{ dados.dados.jornadacompleta
                                                        ? 'Dia todo' : 'Tempo faltante' }}
                                                </div>
                                            </div>
                                            <div class="col-sm-2 row nopadding">
                                                <div class="col-sm-2 nopadding" style="text-align:right;font-weight:bold">
                                                    Tipo:
                                                </div>
                                                <div class="col-sm-10">
                                                    {{ dados.dados.tipo == 5 ? 'Folga' : 'Atestado' }}
                                                </div>
                                            </div>
                                            <template v-if="dados.dados.tipo == 2">
                                                <div class="col-sm-3 row nopadding">
                                                    <div class="col-sm-3 nopadding" style="font-weight:bold">
                                                        Médico:
                                                    </div>
                                                    <div class="col-sm-9">{{ dados.dados.nomemedico }}</div>
                                                </div>
                                                <div class="col-sm-2 row">
                                                    <div class="col-sm-2 nopadding"
                                                        style="text-align:right;font-weight:bold">
                                                        CID:
                                                    </div>
                                                    <div class="col-sm-10">{{ dados.dados.cid }}</div>
                                                </div>
                                                <div class="col-sm-2 row">
                                                    <div class="col-sm-2 nopadding"
                                                        style="text-align:right;font-weight:bold">
                                                        CRM:
                                                    </div>
                                                    <div class="col-sm-10">{{ dados.dados.crm }}</div>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                </b-collapse>
                            </td>
                        </tr>
                    </template>
                </template>
                <statusInformation v-if='dadosTabela.length == 0' typeBar="tr" :statusBar="statusbar">
                </statusInformation>
            </slot>
        </tableRelatorio>

        <b-popover target="filtrosPopover" :show.sync="filtros" triggers="click" placement="bottom" ref="popover"
            @hidden="onHidden">
            <div class="col-sm-12 nopadding">
                <div class="col-sm-12 row mt-2 mb-2 nopadding">
                    <div class="legendaAjustes">
                        <div><span class="dot amarelo"></span>
                            <span> Pendente</span>
                        </div>
                        <div><span class="dot verde"></span>
                            <span> Aprovada</span>
                        </div>
                        <div><span class="dot vermelho"></span>
                            <span> Reprovada</span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 nopadding">
                    <inputRangeWithLimit class="nopadding" name='dataSolicitacaoAjustes' label="Data"
                        :value="popoverData.data" :isObrigatorio="true" @changeInput="changeData" />
                </div>
                <div class="col-sm-12 nopadding mt-2">
                    <selectAll class="nopadding" nameForRadio="radiosEmpresas"
                        :labels="[{ indexDFH: 'E', description: 'Empresas*' }]" :optionsArray="optionsSelectEmpresas"
                        firstSelected="E" :hasSelectAll="true" :isMultiple="true" :selected="popoverData.empresas"
                        @changeSelect="changeEmpresas" />
                </div>
                <div class="col-sm-12 nopadding mt-2">
                    <selectAll class="nopadding" ref="colaboradores" :isMultiple="true" nameForRadio="selectColaboradores"
                        :labels="labelsSelectColaboradores" :optionsArray="optionsSelectCGC" :loading="loadingColaboradores"
                        firstSelected="C" :hasSelectAll="true" :selected="popoverData.colabs"
                        @changeSelect="changeSelectColaboradores" @changeCheck="registraCheckColab" />
                </div>
                <div class="col-sm-12 nopadding mt-2">
                    <ButtonsFilters class="nopadding" label="Tipo de ajuste" :arrayButtons='botoesTipoAjuste'
                        :onlyOneSelected="false" @buttonsFiltersChange='v => tipoSelecionado = v'></ButtonsFilters>
                </div>
                <div class="col-sm-12 nopadding mt-2">
                    <ButtonsFilters class="nopadding" label="Status do ajuste" :arrayButtons='botoesStatusAjuste'
                        :onlyOneSelected="false" @buttonsFiltersChange='v => statusSelecionado = v'></ButtonsFilters>
                </div>
                <div class="col-sm-12 nopadding row mt-2 mb-2">
                    <div class="col-sm-6 nopadding">
                        <simpleButton type="blue" event="click" text="Filtrar" :disabled="$v.$invalid"
                            @click="gerarDados()" />
                    </div>
                    <div class="col-sm-6 nopadding" align="right">
                        <simpleButton type="red" event="click" text="Fechar" :icon="mdiCloseThick"
                            @click="filtros = false" />
                    </div>
                </div>
            </div>
        </b-popover>

        <modalEagle id='documentos' title="Documentos" @modalOpen="buscarDocumento">
            <template #modalBody>
                <b-overlay :show='loadingSearchFile'>
                    <div class="col-sm-12 nopadding">
                        <table id="tabelaDocumentos" class='col-sm-12'>
                            <thead>
                                <tr class='tableHead'>
                                    <th>Nome arquivo</th>
                                    <th>Data/hora</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <template v-if="documento.documento">
                                        <td class="nomeArquivo">{{ documento.nome }}</td>
                                        <td>{{ documento.datahora }}</td>
                                        <td @click="downloadDocumento()">
                                            <baseIcon class="downloadFilePC nopadding" :icon='mdiDownloadOutline'
                                                size="20" />
                                        </td>
                                    </template>
                                    <td v-else colspan="3">Nenhuma informação disponível</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </b-overlay>
            </template>
            <template>
                <div>
                    <simpleButton text='Cancelar' type='red' :icon='mdiCloseThick' event='click'
                        @click='fecharModalDocumentos' />
                </div>
            </template>
        </modalEagle>
    </panelEagle>
</template>
<script>
const urlPadrao = '/checkpoint/nao/menu/solicitacao/ajustes/'

import Vue from 'vue'
import {
    mdiClipboardClockOutline,
    mdiCloseThick,
    mdiCheckBold,
    mdiEyePlusOutline,
    mdiDownloadOutline,
    mdiFilterMenuOutline
} from '@mdi/js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { required } from 'vuelidate/lib/validators'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { conectionError } from '@/Services/Helpers/swellHeper'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name: 'solicitacaoAjustesCheckpoint',

    components: {
        statusInformation: require(
            '@/components/Atom/StatusInformation/StatusInformation'
        ).default,
        basicButtonsCadastrosEIN: require(
            '@/components/Atom/Buttons/BasicButtonsCadastrosEIN'
        ).default,
        inputRangeWithLimit: require(
            '@/components/Atom/Datas/InputRangeWithLimit.vue'
        ).default,
        ButtonsFilters: require(
            '@/components/Atom/Buttons/ButtonsFilters'
        ).default,
        tableRelatorio: require(
            '@/components/Atom/Table/TableRelatorio'
        ).default,
        simpleButton: require(
            '@/components/Atom/Buttons/SimpleButton'
        ).default,
        modalEagle: require(
            '@/components/Atom/Modal/ModalEagle'
        ).default,
        panelEagle: require(
            '@/components/Atom/Panel/PanelEagle'
        ).default,
        selectAll: require(
            '@/components/Atom/Select/SelectAll'
        ).default,
        tituloPage: require(
            '@/components/Atom/Header/Titulo'
        ).default,
        baseIcon: require(
            '@/components/Atom/Icon/BaseIcon'
        ).default,
    },

    validations: {
        colaboradoresSelecionados: { required },
        empresasSelecionadas: { required },
        statusSelecionado: { required },
        tipoSelecionado: { required },
        dataSelecionada: { required },
    },

    data() {
        return {
            optionsSelectEmpresas: new EmpresasService().Get(),
            mdiClipboardClockOutline: mdiClipboardClockOutline,
            mdiCloseThick: mdiCloseThick,
            mdiCheckBold: mdiCheckBold,
            mdiEyePlusOutline: mdiEyePlusOutline,
            mdiDownloadOutline: mdiDownloadOutline,
            mdiFilterMenuOutline: mdiFilterMenuOutline,
            labelsSelectColaboradores: [
                { indexDFH: 'C', description: 'Colaboradores*' },
                { indexDFH: 'GC', description: 'Grupos*' },
            ],
            optionRadiusColab: '',
            optionsSelectCGC: [],
            loadingColaboradores: false,
            loadingPanel: false,
            filtros: false,
            colaboradoresSelecionados: [],
            empresasSelecionadas: [],
            statusSelecionado: [],
            tipoSelecionado: [],
            dataSelecionada: '',

            dadosTabela: [],
            statusbar: 'info',
            labelsEmp: [{
                indexDFH: 'EM',
                description: 'Empresas*'
            }],
            labelsOrd: [{
                indexDFH: 'TA',
                description: 'Tipo de ajuste'
            }],
            botoesTipoAjuste: [
                { value: 'A', width: '48%', text: 'Abono', isSelected: true },
                { value: 'H', width: '48%', text: 'Horas', isSelected: true },
            ],
            botoesStatusAjuste: [
                { value: 'P', width: '98%', text: 'Pendente', isSelected: true },
                { value: 'A', width: '98%', text: 'Aprovado', isSelected: true },
                { value: 'R', width: '98%', text: 'Reprovado', isSelected: true },
            ],
            popoverData: {
                data: '',
                empresas: [],
                colabs: [],
                tipo: [],
                status: [],
            },
            documento: {
                codigo: '',
                nome: '',
                datahora: '',
                documento: '',
                tamanho: '',
                datatype: '',
                tipo: '',
            },
            loadingSearchFile: true,
            loadingExportar: [false, false, false],
            buscainicial: true,
            src: '',
            root: process.env.VUE_APP_ROOT,
        }
    },

    methods: {
        ...mapGetters(['getMaster']),
        ...mapGetters(['getEditar']),

        changeEmpresas(empresas) {
            this.empresasSelecionadas = empresas
            this.$refs.colaboradores.clearAll()
            this.buscainicial = false
        },

        changeSelectColaboradores(colaboradores) {
            this.colaboradoresSelecionados = colaboradores
            this.buscainicial = false
        },

        changeData(data) {
            this.dataSelecionada = data
        },

        registraCheckColab(check, needRecharge) {
            this.optionRadiusColab = check
            if (needRecharge) {
                this.dados_filtros();
            }
        },

        async dados_filtros() {
            this.loadingColaboradores = true
            var dados = [];
            dados = await new FiltrosService().dados_filtros(
                this.empresasSelecionadas, [this.optionRadiusColab])
            this.optionsSelectCGC = dados[this.optionRadiusColab];
            this.loadingColaboradores = false
        },

        /**
        * @listens changeDocumentos - executado quando selecionado um 
        * documento do item da rota
        * @listens click - executado quando selecionado um documento da rota
        * @description Método para abrir modal de documentos
        * @param {int} dados - Código 
        * @fires click - chama o método para buscar os documentos
        * @author Vitor Hugo 🐨
        */
        modalDocumentos(dados) {
            this.documento.codigo = dados.cacodigo
            this.$bvModal.show('documentos')
        },

        /**
        * @listens click - executado quando acionado o ícone de donwload
        * @return Download do arquivo | Erro
        * @author Vitor Hugo 🐨
        */
        downloadDocumento() {
            var downloadLink = document.createElement('a');
            downloadLink.href = `data:${this.documento.datatype};base64,${this.documento.documento}`;
            downloadLink.download = this.documento.nome;
            downloadLink.click();
        },

        fecharModalDocumentos() {
            this.$bvModal.hide('documentos')
        },

        /**
        * @description Função para buscar informações com base nos filtros
        * e controlar a interação com o usuário
        * @author Rafa 🐈
        */
        async gerarDados(obj = false) {
            this.filtros = false
            this.loadingPanel = true
            let body = {
                'clientes': this.empresasSelecionadas,
                'pesquisarPor': this.optionRadiusColab,
                'colaboradores': this.colaboradoresSelecionados,
                'tipo': this.tipoSelecionado,
                'status': this.statusSelecionado,
                'data': this.dataSelecionada,
                'buscainicial': this.buscainicial
            }
            const url = urlPadrao + 'gerar'
            if (obj) body = obj
            try {
                var dados = await new HttpRequest().Post(url, body)
                if (dados.status) {
                    this.dadosTabela = dados.data
                    if (this.dadosTabela.length == 0)
                        this.statusbar = 'error'
                }
            } catch (error) { '' }
            this.loadingPanel = false
        },

        /**
        * @listens click - emitido quando pressionado o botão de filtro
        * @description Método para abrir os filtros, 
        *   adiciona a data selecionada ao value do input de data
        * @author Rafa 🐈
        */
        abrePopover() {
            this.filtros = !this.filtros
            this.popoverData.data = this.dataSelecionada
        },

        /**
        * @description Evento de fechar o modal, quando este é executado,
        * as informações dos filtros são armazenadas em *this.popoverData*,
        * pois ao fechar e abrir os filtros, deve mostrar as mesmas
        * informações selecionadas. Não consegui salvar o estado do popover.
        * @author Rafa 🐈
        */
        onHidden() {
            // this.popoverData.data = this.dataSelecionada
            this.popoverData.empresas = this.optionsSelectEmpresas
                .filter((e) => this.empresasSelecionadas.includes(e.value))
            this.popoverData.colabs = this.optionsSelectCGC
                .filter((e) => this.colaboradoresSelecionados.includes(e.value))
            this.popoverData.tipo = this.tipoSelecionado
            this.popoverData.status = this.statusSelecionado
        },

        /** 
        * @listens modalOpen
        * @description faz a busta da img no banco, gera uma string dinamica para o elemento <img>
        * o atributo espera que venha em base64 e seja compativel com image (png, jpg, gif), n sei se funciona com pdf
        * @author Rafa 🐈
        */
        async buscarDocumento() {
            this.loadingSearchFile = true
            this.strImg = ''
            const url = urlPadrao + 'documento/' + this.documento.codigo
            try {
                this.documento.codigo = ''
                this.documento.nome = ''
                this.documento.datahora = ''
                this.documento.datatype = ''
                this.documento.documento = ''
                this.documento.tamanho = ''
                this.documento.tipo = ''

                var dados = await new HttpRequest().Get(url)
                if (dados.status) {
                    if (dados.data) {
                        this.documento = dados.data
                    }
                } else {
                    this.toastShow('Alteração de Ajuste', 'Ocorreu um erro!', 'danger')
                }
            } catch (error) {
                conectionError()
            }
            this.loadingSearchFile = false
        },

        /** 
        * @listens click - Quado botão de aprovar ou recusar auste é acionado
        * @description Altera o status do ajuste para aprovado ou reprovado
        * @param {int} codigo - Código do ajuste
        * @param {int} status - Status para alterar
        * @author Vitor 🐨
        */
        async alterarStatus(codigo, status) {
            this.loadingPanel = true
            const url = urlPadrao + 'alterar/status'
            const body = { codigo, status }
            try {
                var dados = await new HttpRequest().Post(url, body)
                if (dados.code == 200) {
                    if (dados.data.status) {
                        this.toastShow('Alteração de Ajuste', dados.data.mensagem, 'success')
                        this.gerarDados()
                    } else {
                        this.toastShow('Alteração de Ajuste', dados.data.mensagem, 'danger')
                    }
                } else {
                    this.toastShow('Alteração de Ajuste', 'Ocorreu um erro!', 'danger')
                }
            } catch (error) {
                conectionError()
            }
            this.loadingPanel = false
        },

        toastShow(titulo, msg, type) {
            this.$bvToast.toast(msg, {
                title: titulo,
                autoHideDelay: 2500,
                variant: type,
            })
        },

        async exportarCadastro(tipo) {
            var root = process.env.VUE_APP_ROOT;
            if (this.dadosTabela != []) {
                const url = urlPadrao + 'exportar'
                const body = {
                    // 'dados': this.dadosTabela,
                    'clientes': this.empresasSelecionadas,
                    'pesquisarPor': this.optionRadiusColab,
                    'colaboradores': this.colaboradoresSelecionados,
                    'tipo': this.tipoSelecionado,
                    'status': this.statusSelecionado,
                    'data': this.dataSelecionada,
                    'buscainicial': this.buscainicial,
                    'type': tipo,
                }
                this.setCarregando(tipo)
                var dados = await new HttpRequest().Post(url, body);
                if (dados.status) {
                    window.open(root + '/' + dados.data.local)
                } else {
                    this.msgProblema(dados.message)
                }
                this.setCarregando()
            }
        },

        setCarregando(tipo) {
            this.loadingExportar = [tipo == 'pdf', tipo == 'xls', tipo == 'csv']
        },

        msgProblema(msg) {
            if (msg === 'Network Error') {
                this.toastShow('Ops!', 'Verifique sua conexão com a internet!', 'danger')
            } else {
                conectionError()
            }
        },

        buscaSolicitacao({ id, date, cliente }) {
            let reg_data = /(\d{4})-(\d{2})-(\d{2})/
            let data = date.replace(reg_data, '$3/$2/$1')
            let obj = {
                'clientes': [cliente],
                'pesquisarPor': 'C',
                'colaboradores': [id],
                'tipo': ['A', 'H'],
                'status': ['P', 'A', 'R'],
                'data': data + ' - ' + data,
                'buscainicial': false
            }
            this.gerarDados(obj)
        }
    },

    mounted() {
        if (this.$route.params.id) {
            this.buscaSolicitacao(this.$route.params)
        } else this.gerarDados()
        if (!this.getMaster())
            this.selectedEmpresa = this.optionsArrayEmpresas
    }
})
</script>

<style lang="scss">
#SolicitacaoAjustes {
    .collapseAjustes {
        background: #fff;

        .dadosCollapse {
            background: #f5f5f5;
            min-height: 65px;
        }
    }

    .bold {
        font-weight: bold;
    }

    .img-worker {
        height: 30px;
        max-width: 30px;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        background-color: #aaa;

        img {
            width: 30px;
            height: 45px;
        }
    }

    .status {
        vertical-align: middle;
    }
}

.legendaAjustes {
    background-color: #f2f2f2;
    display: inline-flex;

    div {
        padding: 5px 3px 2px 3px;
        text-align: left;
    }

    .dot {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        display: inline-block;
    }

    .amarelo {
        background-color: #f4c108;
    }

    .verde {
        background-color: #41b325;
    }

    .vermelho {
        background-color: #d87676;
    }
}

#tabelaDocumentos {
    .tableHead {
        background: #f3f3f3;

        td {
            padding-left: 5px;
        }
    }

    .tableBody {
        border-bottom: 1px solid #f3f3f3;

        td {
            padding-left: 7px;

            .nomeArquivo {
                min-width: 300px !important;
            }
        }
    }
}

td.va-bl {
    vertical-align: inherit !important;
    border-bottom: none !important;
}
</style>
