<template>
    <panelEagle id='Produtos' :loading="loadingPanel">
        <div class="col-sm-12 nopadding row">
            <div class="col-sm-6">
                <tituloPage :icon='mdiPackageVariantClosed' titulo='Produtos'></tituloPage>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 divDontPrint">
                <!-- deixar sem o botao novo -->
                <basicButtonsCadastrosEIN
                :loading="loadingExportar"
                @exportarCadastro="exportarCadastro">
                </basicButtonsCadastrosEIN>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <tableListagem
            :data      ='dadosTabela'
            :titles    ='titles' 
            deleteEdit ='editDelete'
            @excluirCadastro='excluirCadastro'
            @editarCadastro='editarCadastro'>
        </tableListagem>
    </panelEagle>
</template>
<script>
import Vue  from 'vue'
import{mdiPackageVariantClosed } from '@mdi/js'
import {conectionError} from '@/Services/Helpers/swellHeper'
import { HttpRequest } from '../../../../Services/auth/HttpRequest.Service'
export default Vue.extend({
    name:'produtos',
    components:{
        'panelEagle'               : require('@/components/Atom/Panel/PanelEagle').default,
        'basicButtonsCadastrosEIN' : require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
        'tituloPage'               : require('@/components/Atom/Header/Titulo').default,
        'tableListagem'            : require('@/components/Atom/Table/TableListagem').default,
    },
    data(){
        return {
            loadingPanel:false,
            loadingExportar:[false, false, false],
            mdiPackageVariantClosed:mdiPackageVariantClosed,
            titles :[
                {'number':'id'  , 'name':'Código', 'width':'100px'},
                {'number':'descricao' , 'name':'Produto'},
            ],
            dadosTabela : [],
        }
    },
    methods:{
        async exportarCadastro(value){
            switch (value) {
                case 'pdf':
                    this.loadingExportar = [true, false, false]
                    break;
                case 'xls':
                    this.loadingExportar = [false, true, false]
                    break;
                default:
                    this.loadingExportar = [false, false, true]
                    break;
            }
            var exportaDados = await new HttpRequest()
                .Post('/administrativo/cadastros/produtos/exporta', {'obj':value})
            var root =  process.env.VUE_APP_ROOT;
            window.open(root+'/'+exportaDados.data.pdf)
            this.loadingExportar = [false, false, false]
        },
        excluirCadastro(value){
            new HttpRequest().Post('/administrativo/cadastros/produtos/excluir', value)
            .then((data)=>{
                if(data.data?.success)
                    this.carregaProdutos()
                else
                    conectionError('Esse produto esta associdado a um ou mais perfis de acesso e não pode ser excluido')                        
            })

        },
        async carregaProdutos(){
            var dados = await new HttpRequest()
                .Post('/administrativo/cadastros/produtos/tabela')
            this.dadosTabela = dados.data
        },
        editarCadastro(value){
            this.$router.push({name:'cadastrarProduto', params:{id:value}});
        }
    },
    async mounted(){
        this.loadingPanel = true
        var dados = await new HttpRequest()
            .Post('/administrativo/cadastros/produtos/tabela')
        this.dadosTabela = dados.data
        this.loadingPanel = false
        
    }
})
</script>
<style lang="scss">
    #Produtos{
        #btnNovo{
            display: none;
        }
    }
    .none{
        display: none !important;
    }
</style>
