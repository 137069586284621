<template>
    <div>
        <panelEagle id="painelGrafico" :loading="loadingPanel">
        </panelEagle>

        <div v-show="!loadingPanel">
            <div class="styleDivMain" v-show="voltarViagem == true">
                <div class="divBotaoShow">
                    <simpleButton event="click" text="" :icon="iconBotaoVisualizarGrafico" type="white" width="70px"
                        class="mt-5 botaoVerGrafico" @click="showGrafico" :title="titleBotaoVerGraficoMapa" />
                </div>

                <div v-show="showGraficoMapa">
                    <div class='col-sm-12 p-0 pb-2 styleDivBlock'>
                        <div v-if="velocidadePermitida !== null && velocidadePermitida !== undefined"
                            class='col-sm-3 m-0 mr-3 bloco'>
                            <div class="tamanhoFonte coluna d-flex justify-content-start">{{ (velocidadePermitida > 0 ? 'Movimento ' : 'Parada ' ) +
                                dataEventoGrafico }}
                            </div>
                            <div v-if="velocidadePermitida > 0" class="styleDivLinha backgroundColorGreen" ></div>
                            <div v-else class="styleDivLinha backgroundColorYellow"></div>

                            <div class="tamanhoFonte text-truncate d-flex justify-content-start mt-1 mb-1"
                                :title="endereco">{{
                                    endereco
                                }}</div>
                            
                            <div class="coluna d-flex justify-content-start align-items-center mt-1" v-if="velocidadePermitida > 0">
                                <base-icon :size='16' id='icone_draggable' class='m-0 p-0 colorGreen'
                                    :icon='iconSpeedometer' />
                                <span class="tamanhoFonte">{{ velocidadePermitida + ' km/h' }}</span>
                                <span class="tamanhoFonte ml-10"> {{ 'Velocidade Permitida ' +
                                    dadosMontasGrafico.velocidadeMaxima
                                    + " km/h" }} </span>
                            </div>
                            <div class="coluna d-flex justify-content-start align-items-center mt-1" v-else>
                                <base-icon :size='16' id='icone_draggable' class='m-0 p-0 colorYellowDessaturado'
                                    :icon='iconClockTimeNineOutline' />
                                <span class="tamanhoFonte">{{ velocidadePermitida + ' km/h' }}</span>
                                <span class="tamanhoFonte ml-10"> {{ 'Velocidade Permitida ' +
                                    dadosMontasGrafico.velocidadeMaxima
                                    + " km/h" }} </span>
                            </div>
                        </div>
                        <div v-else class='col-sm-3 m-0 mr-3 bloco'>
                            <div class="tamanhoFonte coluna d-flex justify-content-start">{{ 'Excesso De Velocidade ' +
                                dataEventoGrafico }}
                            </div>
                            <div class="styleDivLinha backgroundColorRed "></div>

                            <div class="tamanhoFonte text-truncate d-flex justify-content-start mt-1 mb-1"
                                :title="endereco">{{
                                    endereco
                                }}</div>
                            <div>

                            </div>
                            <div class="coluna d-flex justify-content-start align-items-center mt-1">
                                <base-icon :size='16' id='icone_draggable' class='m-0 p-0 colorRed'
                                    :icon='iconSpeedometer' />
                                <span class="tamanhoFonte">{{ velocidadeExcedida + ' km/h' }}</span>
                                <span class="tamanhoFonte ml-10"> {{ 'Permitida ' + dadosMontasGrafico.velocidadeMaxima
                                    + " km/h" }} </span>
                            </div>
                        </div>
                    </div>

                    <div class='chartsAreaMapa'>
                        <div class="col-sm-12 pl-1 pr-1 position-relative">
                            <div :class="'background-' + backgroundColor">
                                <div :id="'chart-timeline' + id" ref="chart"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import {
    mdiSpeedometerMedium,
    mdiSpeedometer,
    mdiEye,
    mdiEyeOff,
    mdiClockTimeNineOutline

} from '@mdi/js'
import ApexCharts from 'apexcharts'
import BaseIcon from '@/components/Atom/Icon/BaseIcon.vue';
import { DateTime } from 'luxon';
import SimpleButton from '@/components/Atom/Buttons/SimpleButton.vue';
export default Vue.extend({
    name: 'chartsAreaMapa',
    components: {
        BaseIcon,
        SimpleButton,
        'panelEagle': require('@/components/Atom/Panel/PanelEagle.vue').default,
    },
    methods: {
        /**
         * @description controla o valor da variavel showGraficoMapa
         * pra setar o titulo e o ícone do estado atual
         * @author Otávio 🦆 
         */
        showGrafico() {
            this.showGraficoMapa = !this.showGraficoMapa;

            if (this.showGraficoMapa) {
                this.titleBotaoVerGraficoMapa = 'Ocultar gráfico';
                this.iconBotaoVisualizarGrafico = mdiEyeOff;
            } else {
                this.titleBotaoVerGraficoMapa = 'Visualizar gráfico';
                this.iconBotaoVisualizarGrafico = mdiEye;
            }
        },

        limparDadosCard() {
            this.velocidadePermitida = '0';
            this.velocidadeExcedida = '0';
            this.dataEventoGrafico = '';
            this.endereco = '';
        },

        /**
         * @description converte a data pra timestampUnix
         * @return unix
         * @author Otávio 🦆 
         */
        converterParaTimestampUnix(dataStr) {
            if (dataStr) {
                var dataFormatada = dataStr.replace(" ", "T");
                var data = new Date(dataFormatada);
                var timestampUnix = data.getTime();
                var timezoneOffset = new Date().getTimezoneOffset() * 60000;
                return timestampUnix - timezoneOffset;
            }
        },

        /**
         * @description trata os dados de velocidade 
         * para definir quando tem excesso de velocidade e quando a velociade é permitida
         * com base na velocidade máxima de um veículo e adiciona para cada velocidade o endereço que será
         * enviado para o series data do gráfico.
         * @return [velocidadePermitida, excessoVelocidade, todasVelocidades]
         * @author Otávio 🦆 
         */
        dadosVelocidade(data) {
            let velocidadePermitida = [];
            let excessoVelocidade = [];
            let todasVelocidades = [];
            let endereco = [];
            let ultimoEstado = null;
            for (let i = 0; i < data.length; i++) {
                let timestamp = this.converterParaTimestampUnix(data[i][0]);
                let velocidade = data[i][1];
                endereco = data[i][2];
                let estadoAtual = velocidade > this.dadosMontasGrafico.velocidadeMaxima ? 'excedida' : 'permitida';

                if (estadoAtual === 'excedida') {
                    excessoVelocidade.push([timestamp, velocidade, endereco]);
                } else {
                    velocidadePermitida.push([timestamp, velocidade, endereco]);
                }
                todasVelocidades.push([timestamp, velocidade, endereco]);
                ultimoEstado = estadoAtual;
            }

            return [velocidadePermitida, excessoVelocidade, todasVelocidades];
        },

        /**
        * @description Gera o gráfico de viagens 
        * com base nos valores da velocidade e endereço 
        * e possui algumas funções da própria biblioteca do apex para 
        * se adequar a regra do card de viagens.
        * @author Otávio 🦆 
        */
        inicializaGrafico() {
            let [velocidadePermitida, excessoVelocidade, todasVelocidades] = this.dadosVelocidade(this.dadosPopulaGraficoVelocidade);
            let annotationsPoints = [];
            this.loadingPanel = true;
            excessoVelocidade.forEach(dataPoint => {
                annotationsPoints.push({
                    x: dataPoint[0],
                    y: dataPoint[1],
                    marker: {
                        size: 3,
                        fillColor: '#fff',
                        strokeColor: 'red',
                        radius: 2,
                        cssClass: 'apexcharts-custom-class'
                    },
                    label: {
                        borderColor: '#FF4560',
                        offsetY: 0,
                        style: {
                            color: '#fff',
                            background: '#FF4560',
                        },
                        text: '',
                    }
                });
            });

            this.optionsBar = {
                dataLabels: {
                    enabled: false
                },

                series: [
                    {
                        name: 'Movimento',
                        data: todasVelocidades,
                        color: 'green'
                    },
                ],
                stroke: {
                    curve: 'straight',

                },
                fill: {
                    type: 'solid',
                    colors: ['#1f8ff3'],
                    opacity: 0.1,
                },
                chart: {
                    id: this.id,
                    height: '120px',
                    width: '100%',
                    type: 'area',
                    stacked: false,
                    toolbar: {
                        show: true,
                        tools: {
                            download: true,
                            selection: false,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: true,
                            reset: true

                        },
                    },
                    locales: [{
                        "name": "pt",
                        "options": {
                            "months": ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
                            "shortMonths": ["Jan", "Feb", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
                            "days": ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
                            "shortDays": ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                            "toolbar": {
                                "exportToSVG": "Download SVG",
                                "exportToPNG": "Download PNG",
                                "menu": "Menu",
                                "selection": "Selection",
                                "selectionZoom": "Selection Zoom",
                                "zoomIn": "Zoom In",
                                "zoomOut": "Zoom Out",
                                "pan": "Panning",
                                "reset": "Reset Zoom"
                            }
                        }
                    }],
                    defaultLocale: "pt",
                    events: {
                        mouseMove: (event, chartContext, config) => {
                            if (config.seriesIndex !== undefined && config.dataPointIndex !== undefined) {
                                let series = config.config.series[config.seriesIndex];
                                if (series) {
                                    let nomeGrafico = series.name;
                                    let dadosGrafico = series.data[config.dataPointIndex];
                                    if (nomeGrafico === 'Velocidade Permitida' || nomeGrafico === 'Excesso de velocidade') {
                                        this.atualizarCaixaInformacoes(dadosGrafico[0], dadosGrafico[1], dadosGrafico[2]);
                                    } else {
                                        this.atualizarCaixaInformacoes(dadosGrafico[0], dadosGrafico[1], dadosGrafico[2]);
                                    }
                                }
                            }
                        },

                    },
                    legend: {
                        show: false
                    },
                    zoom: {
                        enabled: true
                    },
                    fontFamily: 'nexabook, sans-serif',
                },

                xaxis: {
                    type: 'datetime',
                    min: this.converterParaTimestampUnix(this.dadosMontasGrafico.dataIni),
                    max: this.converterParaTimestampUnix(this.dadosMontasGrafico.dataFim),
                },
                yaxis: {
                    labels: {
                        formatter: function (val) {
                            return val !== undefined ? val.toFixed(0) : '';
                        }
                    },
                    title: {
                        text: 'km/h'
                    }

                },
                annotations: {
                    points: annotationsPoints,

                },
                tooltip: {
                    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                        return '';
                    }

                }
            }
            if (this.chart) {
                this.chart.destroy();
            }
            this.chart = new ApexCharts(this.$refs.chart, this.optionsBar);
            this.chart.render();
            this.loadingPanel = false;
        },

        /**
         * @description usa a biblioteca Luxon para gerar uma data 
         * com o padrão do setZone do Brasil(America/São Paulo) 
         * @return data formatada com o horário.
         * @author Otávio 🦆 
         */
        unixParaData(unix_timestamp) {
            const dt = DateTime.fromSeconds(unix_timestamp).setZone('America/Sao_Paulo');
            return dt.toLocaleString(DateTime.DATETIME_SHORT);
        },

        /**
         * @description Atualiza os valores da div que exibe as informações
         * da viagem. 
         * @author Otávio 🦆 
         */
        atualizarCaixaInformacoes(unix, velocidade, endereco) {
            unix = (unix / 1000) + 10800;
            let dataFormatada = this.unixParaData(unix);
            if (velocidade > this.dadosMontasGrafico.velocidadeMaxima) {
                this.velocidadeExcedida = velocidade;
                this.endereco = endereco;
                this.dataEventoGrafico = dataFormatada;
                this.velocidadePermitida = null;
            } else {
                this.velocidadePermitida = velocidade;
                this.endereco = endereco;
                this.dataEventoGrafico = dataFormatada;
                this.velocidadeExcedida = null;
            }
        },
    },
    watch: {
        /**
        * @description monitora os novos valores de velocidade do gráfico
        * e chama as funções inicializaGrafico() e limparDadosCard() para
        * ser gerado um gráfico novo com os valores recebidos
        * @author Otávio 🦆 
        */
        dadosPopulaGraficoVelocidade() {
            this.inicializaGrafico();
            this.limparDadosCard();
        },
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        data: {
            type: Array,
            required: true
        },
        title: {
            type: String,
            required: true,
        },
        backgroundColor: {
            type: String,
            default: 'FFCCE5'
        },
        altura: {
            type: String,
            required: true,
            default: ''
        },
        velocidadeMaxima: {
            type: String,
            default: '0'
        },
        dadosMontasGrafico: {
            type: Object
        },
        dadosPopulaGraficoVelocidade: {
            type: Array
        },
        voltarViagem: {
            type: Boolean,
            default: true
        }
    },
    data: function () {
        return {
            velocidadePermitida: '0',
            velocidadeExcedida: '0',
            dataEventoGrafico: '',
            loadingPanel: true,
            endereco: '',
            chartOptions: null,
            titleBotaoVerGraficoMapa: 'Visualizar gráfico',
            showGraficoMapa: true,
            iconFlagSpeedometerMedium: mdiSpeedometerMedium,
            iconSpeedometer: mdiSpeedometer,
            iconClockTimeNineOutline: mdiClockTimeNineOutline,
            mdiEye: mdiEye,
            mdiEyeOff: mdiEyeOff,
            iconBotaoVisualizarGrafico: mdiEye,
        }
    }
})
</script>

<style>
    .colorYellowDessaturado{
        color:  #e5e619;
    }

    .colorGreen {
        color: green;
    }

    .colorRed {
        color: red;
    }

    .backgroundColorGreen {
        background-color: green;
    }
    .backgroundColorYellow {
        background-color: #e5e619;
    }

    .backgroundColorRed {
        background-color: red;
    }

    .botaoVerGrafico {
        pointer-events: auto;
    }

    .chartsAreaMapa {
        pointer-events: auto;
        background-color: white;
        margin-bottom: 5px;
    }

    .styleDivMain {
        position: relative;
    }

    .styleDivBlock {
        display: flex;
        justify-content: end !important;
        position: relative;
        pointer-events: none;
    }

    .divBotaoShow {
        display: flex;
        justify-content: end;
        margin-right: 10px;
    }

    .bloco {
        border: 1px solid rgb(93, 225, 152);
        border-radius: 2px;
        padding: 10px;
        background-color: #fff;
        position: relative;
    }

    .bloco .tamanhoFonte {
        margin-left: 5px;
    }

    #painelGrafico {
        height: 50px;
        background: transparent;
    }

    .styleDivLinha {
        height: 1.5px;
        width: 100%;
        margin: auto;
        margin-top: 8px;
        margin-bottom: 12px;
    }
</style>