Concetra as ações que o usuário pode usar em casa rota
<template>
    <div id='opt-rotas' class='d-flex flex-row'>
        <div v-if="row.temAlerta">
            <b-button 
            @click="buscarAlerta"
            style="background: transparent; border: none;">
                <base-icon
                :icon='decideIcone("alerta")'
                :size="20"
                style="color: red;"
                />
            </b-button>
        </div>
        <b-button 
        squared
        variant="warning"
        title='Trocar veículo'
        :disabled='!getEditar() || row.rostatus === "F"'
        @click="trocarVeiculo">
           <base-icon
           :icon='decideIcone("trocar")'
           :size="18"/>
        </b-button>
        <b-button 
        squared
        variant="danger"
        title='Excluir rota'
        :disabled='!getExcluir()'
        @click="excluirRota">
            <base-icon
            :icon='decideIcone("excluir")'
            :size="18"/>
        </b-button>
        <b-button
        squared
        title='Imprimir rota'
        variant="info"
        @click='imprimirRota'>
            <b-spinner
            v-if='gerandoFDPF'
            small
            label="Gira gira gira"/>
            <base-icon
            v-else
            :icon='decideIcone("imprimir")'
            :size="18"/>
        </b-button>
        <modal-eagle
        :noCloseOnEsc='true'
        title='Cancelamento de rota'
        :hardToClose="true"
        :noCloseOnBackdrop="true"
        :hideHeaderClose='true'
        :id="`modalExcluir-${row.rocodigo}`"
        size="md"
        @confirmButton='executarExcluirRota'>
            <template #modalBody>
                <b-overlay :show='excluindo'>
                    <div class='d-flex flex-column'>
                        <div>
                            Ao confirmar essa operação a rota sera excluida!
                            Tem certeza que deseja fazer isso?
                        </div>
                        <div style="color:red">
                            {{erro}}
                        </div>
                    </div>
                </b-overlay>
            </template>
        </modal-eagle>
        <modal-eagle
        :noCloseOnEsc='true'
        :hardToClose="true"
        :noCloseOnBackdrop="true"
        :hideHeaderClose='true'
        :id="`modal-trocar-veiculo-${row.rocodigo}`"
        size="xl">
            <template #modalBody>
                <div class="d-flex flex-column">
                    <div>Informe o motivo para a troca do veiculo
                        {{row.roplaca}}</div>
                    <div class='divSeletor'>
                        <multiselect
                        placeholder='Selecione um motivo'
                        :multiple='true'
                        :close-on-select='true'
                        :options='optSelectMotivo'
                        :allow-empty='true'
                        track-by='value'
                        label='description'
                        v-model='modelMotivo'
                        id='seletorEmpresaMapa'
                        selectLabel=''
                        deselectLabel=''
                        :limit='1'
                        @change='mudaMotivo'>
                            <template #noOptions>
                                Nenhuma opção encontrada
                            </template>
                        </multiselect>    
                    </div>
                </div>
            </template>
        </modal-eagle>
    </div>
</template>

<script>
import BaseIcon from '@/components/Atom/Icon/BaseIcon.vue'
import ModalEagle from '@/components/Atom/Modal/ModalEagle.vue'
import Multiselect from 'vue-multiselect';
import {mdiCar, mdiAlertOctagonOutline, mdiTrashCanOutline, mdiPrinter} from '@mdi/js'
import {mapGetters} from 'vuex'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { conectionError } from '@/Services/Helpers/swellHeper';
export default {
	components:{
		BaseIcon,
		Multiselect,
		ModalEagle
	},
	props:{
		row:{
			required:true
		},
		optSelectMotivo:{
			type: Array,
			required:true
		}
	},
	data() {
		return {
			modelMotivo: [],
			erro:'',
			excluindo:false,
			gerandoFDPF:false,
		}
	},
	methods: {
		/**
         * @description escolhe o ícone para os botões de ações.
         * @param {('trocar'|'imprimir'|'excluir')} tipo - qual dos botões.
         * @return {string} ícone correto para o botão.
         * @author Gui 🍺
         */
		decideIcone(tipo) {
			switch(tipo){
				case 'trocar':
					return mdiCar
                case 'alerta':
                    return mdiAlertOctagonOutline
				case 'imprimir':
					return mdiPrinter
				case 'excluir':
					return mdiTrashCanOutline
			}
		},

		/**
		 * @description exibe a modal de troca de veículo.
		 * @todo essa parte ainda não foi implementada.
		 */
        trocarVeiculo() {
			conectionError('Operação indisponível!')
			// this.$bvModal.show(`modal-trocar-veiculo-${this.row.rocodigo}`)
		},

        buscarAlerta() {
            this.$router.push({
                name: 'nao/menu/alertas',
                query: { 
                    cliSelecionadoMapa: this.row.itens_rota[0].ircliente,
                    placaSelecionadaMapa: this.row.roplaca
                }
            });
        },

		/**
		 * @listens click - botão de excluir.
		 * @description abre a modal de confirmação de exclusão.
		 */
		excluirRota(){
			this.$bvModal.show(`modalExcluir-${this.row.rocodigo}`)
		},

		/**
		 * @listens click - botão de imprimir.
		 * @description envia as informações para o back-end gerar um pdf.
		 */
		imprimirRota(){
			this.gerandoFDPF = true
			new HttpRequest().Post('/finder/rotas/exportar/rota',
				{'rota':this.row})
                .then((data) => {
                    if (data.status && data.data) {
                        var root =  process.env.VUE_APP_ROOT
                        window.open(root+'/'+data.data.pdf)
                    } else {
                        conectionError()
                    }
				}).finally(()=>this.gerandoFDPF = false)
		},

		/**
		 * @listens change - seletor de troca de motivo, dentro da modal.
		 * @todo essa parte de troca de veículo ainda não foi implementada.
		 */
		mudaMotivo(motivo){
		},

		/**
		 * @description depois de confirmar a exclusão da rota, essa função
		 * envia o código da rota a ser excluida para o back-end. 
		 * Já faz o tratamento de erro para o caso de não ser possivel
		 * excluir a rota.
		 */
		executarExcluirRota(){
			this.excluindo = true
			new HttpRequest()
				.Post('/finder/rotas/excluir/rota', {rota:this.row.rocodigo})
				.then((data)=>{
					if(data.status && data.data.rota){
						this.emiteRotaExcluida(this.row.rocodigo)
						this.$bvModal.hide(`modalExcluir-${this.row.rocodigo}`)
					}else{
						this.erro = data.data.erro
						setTimeout(()=>this.erro = '', 5000)
					}
				}).finally(()=>this.excluindo = false)
		},

		/**
		 * @param {number} rocodigo - código da rota excluida
		 * @fires rota-excluida
		 * @description tecnicamente deveria avisar o painel de rotas
		 * que uma das rotas sendo exibidas foi excluida
		 */
		emiteRotaExcluida(rocodigo){
			this.$emit('rota-excluida', rocodigo)
        },
        
		...mapGetters(['getExcluir', 'getEditar']),
	},
}
</script>

<style lang="scss" scoped>
#opt-rotas{
    justify-content: center;
}
</style>