import { render, staticRenderFns } from "./Alertas.vue?vue&type=template&id=3e7730ca&scoped=true"
import script from "./Alertas.vue?vue&type=script&lang=js&scoped=true"
export * from "./Alertas.vue?vue&type=script&lang=js&scoped=true"
import style0 from "./Alertas.vue?vue&type=style&index=0&id=3e7730ca&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e7730ca",
  null
  
)

export default component.exports