import { AuthService } from '../../../Services/auth/Auth.Service';
import Layout from '../../../components/Atom/Layout/Layout.vue'
import Relatorio from '../../Children.vue'
import Cadastro from '../../Children.vue'
import NaoMenu from '../../Children.vue';
import { RouteConfig } from 'vue-router'
import ListagemFornecedores from '@/views/Manutencao/Cadastros/Fornecedores/ListagemFornecedores.vue'
import cadastrarFornecedor from '@/views/Manutencao/Cadastros/Fornecedores/EditarListagemFornecedores.vue'
import CadastroManuntencao from '@/views/Manutencao/Cadastros/Manutencao/CadastroManutencao.vue'
import EditarCadastroManutencao from '@/views/Manutencao/Cadastros/Manutencao/EditarCadastroManuentcao.vue'
import TipoServico from '@/views/Manutencao/Cadastros/TipoServico/TipoServico.vue'
import TipoServicoEditar from '@/views/Manutencao/Cadastros/TipoServico/TipoServicoEditar.vue'
import ListagemTipoManutencao from '@/views/Manutencao/Cadastros/TipoManutencao/ListagemTipoManutencao.vue'
import CadastroTipoManutencao from '@/views/Manutencao/Cadastros/TipoManutencao/CadastroTipoManutencao.vue'
import AgendarManutencao from '@/views/Manutencao/NaoMenus/AgendarManutencao.vue' 
import Checklist from '@/views/Manutencao/Cadastros/Checklist/Checklist.vue'
import CadastrarChecklist from '@/views/Manutencao/Cadastros/Checklist/CadastrarChecklist.vue'
import RelatorioChecklist from '@/views/Manutencao/Relatorios/RelatorioChecklist.vue'
import ControleManutencao from '@/views/Manutencao/NaoMenus/ControleManutencao.vue' 
import RelatorioManutencoes from '@/views/Manutencao/Relatorios/Manutencoes.vue'
import DashboardPrevise from '@/views/Manutencao/Dashboard/DashboardPrevise.vue'

export const ifAuthenticated = (_to: any, _from: any, next: any) => {
	if (new AuthService().isAuthenticated()) {
		next()
		return
	}
	next('/login')
}
export const manutencaoRoutes: RouteConfig =
{   
	path: '/manutencao',
	name: 'Manutencoes',
	beforeEnter: ifAuthenticated,
	meta: {
		requiresAuth: true,
		Module: 'manutencao',
		breadcrumbName: 'Manutenções'
	},
	components: { default: Layout },
	children: [
		// Cadastros
		{
			path: 'cadastros',
			meta: {
				Module: 'manutencao',
				breadcrumbName: 'Cadastros'
			},
			components: { content: Cadastro },
			children: [
				{ 
					path: 'fornecedores',
					name: 'listarFornecedores',
					meta: {
						Module: 'manutencao',
						breadcrumbName: 'Fornecedores'
					},
					component: ListagemFornecedores
				},
				{
					path: 'fornecedores/novo/:id?/:cp?',
					name: 'cadastrarFornecedor',
					meta: {
						Module: 'manutencao',
						breadcrumbName: 'Fornecedores'
					},
					component: cadastrarFornecedor
				},
				{
					path: 'manutencao',
					name: 'listarManutencao',
					meta: {
						Module: 'manutencao',
						breadcrumbName: 'Manutenções'
					},
					component: CadastroManuntencao
				},
				{
					path: 'manutencao/cadastrar/:id?/:cp?',
					name: 'cadastrarManutencao',
					meta: {
						Module: 'manutencao',
						breadcrumbName: 'Manutenções'
					},
					component:EditarCadastroManutencao
				},
				{
					path: 'tipo/servico',
					name: 'tipoServico',
					meta: {
						Module: 'manutencao',
						breadcrumbName: 'Tipos de Serviço'
					},
					component: TipoServico
				},
				{
					path: 'tipo/servico/editar/:id?',
					name: 'tipoServicoEditar',
					meta: {
						Module: 'manutencao',
						breadcrumbName: 'Tipos de Serviço'
					},
					component: TipoServicoEditar
				},
				{
					path: 'tipo/manutencao',
					name: 'ListarTipoManutencao',
					meta: {
						Module: 'manutencao',
						breadcrumbName: 'Tipos de Manutenção'
					},
					component: ListagemTipoManutencao
				},
				{
					path: 'tipo/manutencao/cadastrar/:id?',
					name: 'CadastrarTipoManutencao',
					meta: {
						Module: 'manutencao',
						breadcrumbName: 'Tipos de Manutenção'
					},
					component: CadastroTipoManutencao
				},
				// Checklist
				{ 
					path: 'checklist',
					name: 'checklist',
					meta: {
						Module: 'manutencao',
						breadcrumbName: 'Checklist'
					},
					component: Checklist
				},
				{ 
					path: 'checklist/cadastrar/:id?',
					name: 'cadastrarChecklist',
					meta: {
						Module: 'manutencao',
						breadcrumbName: 'Checklist'
					},
					component: CadastrarChecklist
				},
			]
		},

		// Não Menus
		{
			path: '',
			meta: {
				Module: 'manutencao',
				breadcrumbName: ''
			},
			components: { content: NaoMenu },
			children: [
				{ 
					path: 'agendar/manutencao',
					name: 'agendarManutencao',
					meta: {
						Module: 'manutencao',
						breadcrumbName: 'Agendar Manutenção'
					},
					component: AgendarManutencao
				},
				{ 
					path: 'controle/manutencao',
					name: 'controleManutencao',
					meta: {
						Module: 'manutencao',
						breadcrumbName: 'Controle da manutenção'
					},
					component: ControleManutencao
				},
				// Dashboard
				{
                    path: '',
                    name: 'DashboardPrevise',
                    meta: {
                        Module: 'manutencao',
                        breadcrumbName: 'Dashboard Previse'
                    },
                    component: DashboardPrevise
                },
			]
		},

		// Relatoios
		{
			path: 'relatorios',
			meta: {
				Module: 'manutencao',
				breadcrumbName: 'Relatórios'
			},
			components: { content: Relatorio },
			children: [
				{
					path: 'checklist',
					name: 'RelatorioChecklist',
					meta: {
						Module: 'manutencao',
						breadcrumbName: 'Checklist'
					},
					component: RelatorioChecklist
				},
				{
					path: 'manutencoes',
					name: 'RelatorioManutencoes',
					meta: {
						Module: 'manutencao',
						breadcrumbName: 'Relatório Manutenções'
					},
					component: RelatorioManutencoes
				},
			]
		},

	]
    
}
