import { AuthService } from '../../../Services/auth/Auth.Service';
import Layout from '../../../components/Atom/Layout/Layout.vue'
import Relatorio from '../../Children.vue'
import Mapa from '../../Mapa.vue'
import { RouteConfig } from 'vue-router'
import { Modulos } from '@/Services/route/RouteModel';
import Registrar from '@/views/Abastecimentos/Cadastros/Registrar/Registrar.vue';
import RegistrarC from '@/views/Abastecimentos/Cadastros/Registrar/RegistrarCadastro.vue'
import Abastecimentos from '@/views/Abastecimentos/Relatorios/Abastecimentos.vue'
import TipoCombustivel from '@/views/Abastecimentos/Cadastros/TipoCombustivel/TipoCombustivel.vue'
import EditarTipoCombustivel from '@/views/Abastecimentos/Cadastros/TipoCombustivel/EditarTipoCombustivel.vue'

export const ifAuthenticated = (_to: any, _from: any, next: any) => {
	if (new AuthService().isAuthenticated()) {
		next()
		return
	}
	next('/login')
}

export const abastecimentosRoutes: RouteConfig = {
	path: '/abastecimento', name: 'abastecimento', beforeEnter: ifAuthenticated, meta: { requiresAuth: true, Module: 'abastecimento', breadcrumbName: 'Abastecimento' }, components: { default: Layout }, children: [
		{
			path: 'cadastros',
			meta: {
				Module: 'abastecimento',
				breadcrumbName: 'Relatórios'
			},
			components: { content: Relatorio },
			children: [
				{
					path: 'registrar',
					name: 'registrarFuel',
					meta: {
						Module: 'abastecimento',
						breadcrumbName: 'Registrar'
					},
					component: Registrar
				},
				{
					path: 'registrar/cadastro/:id?',
					name: 'registrarNovoFuel',
					meta: {
						Module: 'abastecimento',
						breadcrumbName: 'Registrar'
					},
					component: RegistrarC
				},
				{
					path: 'combustivel',
					name: 'combustivel',
					meta: {
						Module: 'abastecimento',
						breadcrumbName: 'Tipos de Combustivel'
					},
					component: TipoCombustivel
				},
				{
					path: 'combustivel/cadastrar/:id?',
					name: 'cadastrarCombustivel',
					meta: {
						Module: 'abastecimento',
						breadcrumbName: 'Tipos de Combustível'
					},
					component: EditarTipoCombustivel
				},
			]
		},
		{
			path: 'relatorios',
			meta: {
				Module: 'abastecimento',
				breadcrumbName: 'Relatórios'
			},
			components: {
				content: Relatorio
			},
			children: [
				{
					path: 'abastecimento',
					meta: {
						Module: 'abastecimento',
						breadcrumbName: 'Abastecimentos'
					},
					component: Abastecimentos
				},
			]
		},
	]
}
