<template>
    <panelEagle id='dashboardFinderPlus' :loading="loadingPanel">
   
    </panelEagle>
</template>
<script>
import Vue  from 'vue'
import {mdiArrowUpThick, mdiArrowDownThick} from '@mdi/js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { DateTime } from 'luxon'
import { conectionError }  from '@/Services/Helpers/swellHeper'
export default Vue.extend({
	name:'DashboardFinderPlus',
	
	components:{
		'panelEagle'           : require('@/components/Atom/Panel/PanelEagle').default,
		'indicador'            : require('@/components/Atom/Grafico/Indicador').default,
		'chartsColumn'         : require('@/components/Atom/Grafico/ChartsColumn').default,
		'chartsPizzaDashboard' : require('@/components/Atom/Grafico/ChartsPizzaDashboard').default,
		'chartsIndicator'      : require('@/components/Atom/Grafico/ChartsIndicator').default,
		// 'simpleButton'         : require('@/components/Atom/Buttons/SimpleButton').default,
	},

	data(){
		return {
			mdiArrowUpThick: mdiArrowUpThick,
			mdiArrowDownThick: mdiArrowDownThick,
			// Total Veículos
			labelQuantidadeVeiculos : [],
			valueQuantidadeVeiculos : [],
			totalVeiculos : 0,
			semValueQuantidadeVeiculos: false,
			// Veículos por tipo
			quantidadeVeiculos : [],
			veiculosTipo       : [],
			labelVeiculosTipo  : [],
			valueVeiculosTipo  : [],
			semValueVeiculosTipo: false,
			// Sem comunicação
			semCominicacao : {
				'dados'   : '',
				'message' : ''
			},
			semSemCominicacao: false,
			// Maior velocidade atingida
			maiorVelocidadeAtingida : {
				'dados'   : '',
				'message' : ''
			},
			semMaiorVelocidadeAtingida: false,
			// Veículo com mais alertas
			veiculoMaisAlertas : {
				'dados'   : '',
				'message' : ''
			},
			semVeiculoMaisAlertas: false,
			// 5 veículos mais rodaram
			veiculosMaisRodaram      : [],
			labelVeiculosMaisRodaram : [],
			dadosVeiculosMaisRodaram : [],
			semDadosVeiculosMaisRodaram : false,
			// 5 veículos com mais excesso velocidade
			veiculosExcessoVelocidade : [],
			labelVeiculosExcessoVelocidade : [],
			dadosVeiculosExcessoVelocidade : [],
			semDadosVeiculosExcessoVelocidade : false,
			// Infração por tipo
			infracaoTipo : [],
			labelInfracaoTipo : [],
			valueInfracaoTipo : [],
			semValueInfracaoTipo : false,
			// km rodada mês
			kmRodadaMes : [],
			labelKmRodadaMes : '',
			valueKmRodadaMes : [],
			semValueKmRodadaMes : false,
			// Horas trabalhadas
			horasTrabalhadas : [],
			labelHorasTrabalhadas : '',
			valueHorasTrabalhadas : [],
			semValueHorasTrabalhadas : false,
			// 5 regiões com maior atuação
			regioesMaisAtuacoes : [],
			labelRegioesMaisAtuacoes : [],
			dadosRegioesMaisAtuacoes : [],
			semDadosRegioesMaisAtuacoes : false,
			// Total de infrações
			totalInfracoes : {
				'dados' : ''
			},
			semTotalInfracoes: false,
			loadingPanel : false,
			dataAtualizacao: '',
		}
	},

	methods:{

		/**
         * @description Método ajustar os dados como o gráfico de
         *  quantidade de veículos precisa
         * @param  {array} quantidadeVeiculos - 
         *  ddvalores ( JSON com os dados do gráfico )
         * @author Vitor Hugo 🐨
        */
		trataQuantidadeVeiculos(quantidadeVeiculos){
			if(quantidadeVeiculos[0] != undefined){
				this.quantidadeVeiculos = JSON.parse(quantidadeVeiculos[0].ddvalores)
				this.labelQuantidadeVeiculos = this.quantidadeVeiculos.label
				this.valueQuantidadeVeiculos = this.quantidadeVeiculos.data
				this.totalVeiculos = this.quantidadeVeiculos.total
				this.semValueQuantidadeVeiculos = false
			} else {
				this.semValueQuantidadeVeiculos = true
			}
		},

		/**
         * @description Método ajustar os dados como o gráfico de
         *  veículos por tipo precisa
         * @param  {array} veiculosTipo - 
         *  ddvalores ( JSON com os dados do gráfico )
         * @author Vitor Hugo 🐨
        */
		trataVeiculosTipo(veiculosTipo){
			if(veiculosTipo[0] != undefined){
				this.veiculosTipo = JSON.parse(veiculosTipo[0].ddvalores)
				this.labelVeiculosTipo = this.veiculosTipo.label
				this.valueVeiculosTipo = this.veiculosTipo.data
				this.semValueVeiculosTipo = false
			} else {
				this.semValueVeiculosTipo = true
			}
		},

		/**
         * @description Método ajustar os dados como o indicador de
         *  sem comunicação precisa
         * @param  {array} semCominicacao - 
         *  dados ( quantidade de veículo sem comunicação )
         *  message ( quantidade de tempo do veículo a mais tempo sem comunicação )
         * @author Vitor Hugo 🐨
        */
		trataSemCominicacao(semCominicacao){
			if(semCominicacao[0] != undefined){
				this.semCominicacao = JSON.parse(semCominicacao[0].ddvalores)
				this.semSemCominicacao = false
			} else {
				this.semSemCominicacao = true
			}
		},

		/**
         * @description Método ajustar os dados como o indicador de
         *  maior velocidade atingida precisa
         * @param  {array} maiorVelocidadeAtingida - 
         *  dados ( placa do veículo que teve a maior velocidade )
         *  message ( velocidade atingida )
         * @author Vitor Hugo 🐨
        */
		trataMaiorVelocidadeAtingida(maiorVelocidadeAtingida){
			if(maiorVelocidadeAtingida[0] != undefined){
				this.maiorVelocidadeAtingida = JSON.parse(maiorVelocidadeAtingida[0].ddvalores)
				this.semMaiorVelocidadeAtingida = false
			} else {
				this.semMaiorVelocidadeAtingida = true
			}
		},

		/**
         * @description Método ajustar os dados como o indicador de
         *  veículo com mais alertas precisa
         * @param  {array} veiculoMaisAlertas - 
         *  dados ( quantidade de alertas )
         *  message ( placa do veículo )
         * @author Vitor Hugo 🐨
        */
		trataVeiculoMaisAlertas(veiculoMaisAlertas){
			if(veiculoMaisAlertas[0] != undefined){
				this.veiculoMaisAlertas = JSON.parse(veiculoMaisAlertas[0].ddvalores)
				this.semVeiculoMaisAlertas = false
			} else {
				this.semVeiculoMaisAlertas = true
			}
		},

		/**
         * @description Método ajustar os dados como o gráfico de
         *  5 veiculos que mais rodaram precisa
         * @param  {array} veiculosMaisRodaram - 
         *  ddvalores ( JSON com os dados do gráfico )
         * @author Vitor Hugo 🐨
        */
		trataVeiculosMaisRodaram(veiculosMaisRodaram){
			if(veiculosMaisRodaram[0] != undefined){
				this.veiculosMaisRodaram = JSON.parse(veiculosMaisRodaram[0].ddvalores)
				this.labelVeiculosMaisRodaram = this.veiculosMaisRodaram.label
				this.dadosVeiculosMaisRodaram = this.veiculosMaisRodaram.dados
				this.semDadosVeiculosMaisRodaram = false
			} else {
				this.semDadosVeiculosMaisRodaram = true
			}
		},

		/**
         * @description Método ajustar os dados como o gráfico de
         *  5 veiculos com mais excesso de velocidade precisa
         * @param  {array} veiculosExcessoVelocidade - 
         *  ddvalores ( JSON com os dados do gráfico )
         * @author Vitor Hugo 🐨
        */
		trataVeiculosExcessoVelocidade(veiculosExcessoVelocidade){
			if(veiculosExcessoVelocidade[0] != undefined){
				this.veiculosExcessoVelocidade = JSON.parse(veiculosExcessoVelocidade[0].ddvalores)
				this.labelVeiculosExcessoVelocidade = this.veiculosExcessoVelocidade.label
				this.dadosVeiculosExcessoVelocidade = [this.veiculosExcessoVelocidade.dados[0]]
				this.semDadosVeiculosExcessoVelocidade = false
			} else {
				this.semDadosVeiculosExcessoVelocidade = true
			}
		},

		/**
         * @description Método ajustar os dados como o gráfico de
         *  infrações tipo precisa
         * @param  {array} infracaoTipo - 
         *  ddvalores ( JSON com os dados do gráfico )
         * @author Vitor Hugo 🐨
        */
		trataInfracaoTipo(infracaoTipo){
			if(infracaoTipo[0] != undefined){
				this.infracaoTipo = JSON.parse(infracaoTipo[0].ddvalores)
				this.labelInfracaoTipo = this.infracaoTipo.label
				this.valueInfracaoTipo = this.infracaoTipo.data
				this.semValueInfracaoTipo = false
			} else {
				this.semValueInfracaoTipo = true
			}
		},

		/**
         * @description Método ajustar os dados como o indicador de
         *  km rodada precisa
         * @param  {array} kmRodadaMes - 
         *  ddvalores ( JSON com os dados do gráfico )
         * @author Vitor Hugo 🐨
        */
		trataKmRodadaMes(kmRodadaMes){
			if(kmRodadaMes[0] != undefined){
				this.kmRodadaMes = JSON.parse(kmRodadaMes[0].ddvalores)
				this.labelKmRodadaMes = this.kmRodadaMes.label
				this.valueKmRodadaMes = this.kmRodadaMes.data
				this.semValueKmRodadaMes = false
			} else {
				this.semValueKmRodadaMes = true
			}
		},

		/**
         * @description Método ajustar os dados como o indicador de
         *  horas trabalhadas precisa
         * @param  {array} horasTrabalhadas - 
         *  ddvalores ( JSON com os dados do gráfico )
         * @author Vitor Hugo 🐨
        */
		trataHorasTrabalhadas(horasTrabalhadas){
			if(horasTrabalhadas[0] != undefined){
				this.horasTrabalhadas = JSON.parse(horasTrabalhadas[0].ddvalores)
				this.labelHorasTrabalhadas = this.horasTrabalhadas.label
				this.valueHorasTrabalhadas = this.horasTrabalhadas.data
				this.semValueHorasTrabalhadas = false
			} else {
				this.semValueHorasTrabalhadas = true
			}
		},

		/**
         * @description Método ajustar os dados como o indicador de
         *  5 regioẽs com mais atuações precisa
         * @param  {array} regioesMaisAtuacoes - 
         *  ddvalores ( JSON com os dados do gráfico )
         * @author Vitor Hugo 🐨
        */
		trataRegioesMaisAtuacoes(regioesMaisAtuacoes){
			if(regioesMaisAtuacoes[0] != undefined){
				this.regioesMaisAtuacoes = JSON.parse(regioesMaisAtuacoes[0].ddvalores)
				this.labelRegioesMaisAtuacoes = this.regioesMaisAtuacoes.label
				this.dadosRegioesMaisAtuacoes = [this.regioesMaisAtuacoes.dados[0]]
				this.semDadosRegioesMaisAtuacoes = false
			} else {
				this.semDadosRegioesMaisAtuacoes = true
			}
		},

		/**
         * @description Método ajustar os dados como o indicador de
         *  maior velocidade atingida precisa
         * @param  {array} totalInfracoes - 
         *  dados ( quantidade de infrações )
         * @author Vitor Hugo 🐨
        */
		trataTotalInfracoes(totalInfracoes){
			if(totalInfracoes[0] != undefined){
				this.totalInfracoes = JSON.parse(totalInfracoes[0].ddvalores)
				this.semTotalInfracoes = false
			} else {
				this.semTotalInfracoes = true
			}
		},

		/**
         * @description Método faz a requisição para trazer os dados da 
         *  dashboard, chama os métodos referentes a cada gráfico da 
         *  tela para os dados serem tatados
         * @author Vitor Hugo 🐨
        */
		buscaDadosDashboard(){
			this.loadingPanel = true
			new HttpRequest().Post('/telemetria/dashboard/gerar')
				.then((dados)=>{
					if(dados.code == 200){
						var dado = dados.data
						this.trataQuantidadeVeiculos(dado.quantidadeVeiculos)
						this.trataVeiculosTipo(dado.veiculosTipo)
						this.trataSemCominicacao(dado.semCominicacao)
						this.trataMaiorVelocidadeAtingida(dado.maiorVelocidadeAtingida)
						this.trataVeiculoMaisAlertas(dado.veiculoMaisAlertas)
						this.trataVeiculosMaisRodaram(dado.veiculosMaisRodaram)
						this.trataVeiculosExcessoVelocidade(dado.veiculosExcessoVelocidade)
						this.trataInfracaoTipo(dado.infracaoTipo)
						this.trataKmRodadaMes(dado.kmRodadaMes)
						this.trataHorasTrabalhadas(dado.horasTrabalhadas)
						this.trataRegioesMaisAtuacoes(dado.regioesMaisAtuacoes)
						this.trataTotalInfracoes(dado.totalInfracoes)
                        if(dado.dataHoraAtualizacao != null){
                            this.dataAtualizacao = DateTime
                                .fromFormat(
                                    dado.dataHoraAtualizacao.dddatahoraevento,
                                    'yyyy-LL-dd HH:mm:ss')
                                .toFormat('dd/LL/yyyy HH:mm:ss')
                        }
						this.loadingPanel = false
					} else {
						this.toastShow('Não foi possível carregar os dados',
							'danger', 
							'Erro ao gerar')
						this.loadingPanel = false
					}
				})       
		},

		toastShow(msg, type, title = ''){
			this.$bvToast.toast(`${msg}`, {
				autoHideDelay: 2500,
				variant: type,
				title: title
			})
		},

		atualizaDash(){
			this.valueQuantidadeVeiculos = []
			this.totalInfracoes.dados = ''
			this.totalInfracoes.message = ''
			this.semCominicacao.dados = ''
			this.semCominicacao.message = ''
			this.maiorVelocidadeAtingida.dados = ''
			this.maiorVelocidadeAtingida.message = ''
			this.veiculoMaisAlertas.dados = ''
			this.veiculoMaisAlertas.message = ''
			this.valueKmRodadaMes = []
			this.valueHorasTrabalhadas = []
			this.dadosVeiculosMaisRodaram = []
			this.valueVeiculosTipo = []
			this.valueInfracaoTipo = []
			this.dadosRegioesMaisAtuacoes = []
			this.dadosVeiculosExcessoVelocidade = []
			this.buscaDadosDashboard()
		},
	},

	mounted(){
		this.buscaDadosDashboard()
	}
})


</script>
<style lang="scss">
#dashboardFinderPlus{
    .backgound-gray{
        background: #f8f8f8;
        box-shadow: 1px 1px 1px 1px #e0e0e0;
        height: 100%;
        .font{
            font-size: 10px;
        }
        div{
            padding-top: 5px;
        }
    }
    
    .indicador{
        min-height: 85px;
    }
}

</style>
