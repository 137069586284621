import { DateTime, Interval } from "luxon"

import {HttpRequest} from '../auth/HttpRequest.Service';

// import { mockedGetTelemetryData } from './__mocks__/telemetry.mock'
import { 
    EventCodes, 
    RawTelemetryData, 
    RawTelemetryEvent, 
    RawTelemetrySpeedEvent, 
    RawTelemetryRPMEvent, 
    TelemetryData,
    TelemetryPayload
} from './types/telemetry'

export * from './types/telemetry'

const TIMEZONE_SECONDS_DIFFERENCE = 10800

function getDateTime(epochSeconds: string): DateTime {
    const seconds = parseInt(epochSeconds) + TIMEZONE_SECONDS_DIFFERENCE
    return DateTime.fromSeconds(seconds).setZone('America/Sao_Paulo')
}

function getLatlog(latlog: string | undefined): number[] | undefined {
    return latlog?.split(',')
        .map((coord) => parseFloat(coord))
}

function getValue(key: number, event: RawTelemetryEvent): number {
    let value = 0;

    if (key === EventCodes.Speed) {
        value = (event as RawTelemetrySpeedEvent).bvvelocidade
    } else if (key === EventCodes.RPM) {
        value = (event as RawTelemetryRPMEvent).telrpm
    }

    return value
}

export async function getTelemetryData(interval: Interval, plate: string): Promise<TelemetryData> {
    const returnData: TelemetryData = {};
    const payload: TelemetryPayload = {
        dataIni: interval.start.toFormat('yyyy-MM-dd HH:mm:ss'),
        dataFim: interval.end.toFormat('yyyy-MM-dd HH:mm:ss'),
        placa: plate
    }

    // const response = await mockedGetTelemetryData()
    const response = await new HttpRequest().Post('/finder/veiculo/grafico/telemetria', payload)
    const rawData: RawTelemetryData = response.data[0]
    
    // Percorre todos os tipos de eventos
    Object.entries(rawData).forEach(([key, rawEvents]) => {

        // Mapeia os eventos para o formato desejado
        returnData[parseInt(key)] = rawEvents.map((event) => ({
            dateTime: getDateTime(event.dataevento),
            value: getValue(parseInt(key), event),
            latlog: getLatlog(event.latlog)
        }))
    })

    return returnData

}
