import { AuthService } from '../../../Services/auth/Auth.Service';
import Logs from '@/views/Adminstrativo/Relatorios/Logs.vue';
import Mapa from '../../Mapa.vue';
import Layout from '../../../components/Atom/Layout/Layout.vue';
import NaoMenu from '../../Children.vue';
import Relatorio from '../../Children.vue';
import Cadastros from '../../Children.vue';
import CadastroChip from '@/views/Adminstrativo/Cadastros/Chip/CadastroChip.vue';
import ListagemChips from '@/views/Adminstrativo/Cadastros/Chip/ListagemChip.vue'
import CadastroVeiculos from '@/views/Adminstrativo/Cadastros/Veiculos/CadastroVeiculo.vue';
import Veiculos from '@/views/Adminstrativo/Cadastros/Veiculos/ListagemVeiculos.vue';
import VeiculosPorCliente from '../Relatorios/VeiculosPorCliente.vue';
import { RouteConfig } from 'vue-router'
import { Modulos } from '@/Services/route/RouteModel';
import Moduloss from '@/views/Adminstrativo/Cadastros/ModelosModulos/Modulos.vue'
import Cadastro from '@/views/Adminstrativo/Cadastros/ModelosModulos/Cadastro.vue'
import Editados from '@/views/Adminstrativo/Cadastros/Modulos/Editados.vue'
import OrdemDeServico from '@/views/Adminstrativo/Relatorios/OrdensDeServico.vue'
import Produtos from '@/views/Adminstrativo/Cadastros/Produtos/Produtos.vue'
import EditarProdutos from '@/views/Adminstrativo/Cadastros/Produtos/EditarProdutos.vue'
import PerfilDeAcesso from '@/views/Adminstrativo/Cadastros/Perfil/PerfilDeAcesso.vue'
import CadastrarPerfilDeAcesso from '@/views/Adminstrativo/Cadastros/Perfil/CadastrarPerfilDeAcesso.vue'
import Descontos from '@/views/Adminstrativo/Cadastros/Descontos/Descontos.vue'
import CadastrarDesconto from '@/views/Adminstrativo/Cadastros/Descontos/CadastrarDesconto.vue'
import Tutorial from '@/views/Adminstrativo/Tutorial/Tutorial.vue'
import ListagemUsuarios from '../Cadastros/Usuarios/ListagemUsuarios.vue';
import CadastrarUsuarios from '../Cadastros/Usuarios/CadastrarUsuarios.vue';
import ListarUsuariosApp from '../Cadastros/UsuarioApp/ListarUsuariosApp.vue'
import CadastrarUsuarioApp from '../Cadastros/UsuarioApp/CadastrarUsuarioApp.vue';
import cartoesRFID from '@/views/Adminstrativo/Gestao/cartoesRFID/CartoesRFID.vue';
import NovoCartao from '@/views/Adminstrativo/Gestao/cartoesRFID/NovoCartao.vue';
import ListagemPerifericos from '@/views/Adminstrativo/Cadastros/Perifericos/ListagemPerifericos.vue'
import CadastroPerifericos from '@/views/Adminstrativo/Cadastros/Perifericos/CadastroPerifericos.vue'
import Monitor from '@/views/Adminstrativo/NaoMenus/Monitor.vue'
import Agendamento from '@/views/Adminstrativo/Cadastros/Agendamento/Agendamento.vue';
import ListagemClientes from '@/views/Adminstrativo/Cadastros/Empresa/ListagemEmpresas.vue'
import CadastroClientes from '@/views/Adminstrativo/Cadastros/Empresa/CadastrarEmpresa.vue'
import listarColaborador from '@/views/Adminstrativo/Cadastros/Colaborador/ListarColaborador.vue'
import cadastrarColaborador from '@/views/Adminstrativo/Cadastros/Colaborador/CadastrarColaborador.vue'
import relatorioLogs from '@/views/Adminstrativo/Relatorios/Logs.vue'
import ListagemModulos from '@/views/Adminstrativo/Cadastros/Modulos/ListagemModulos.vue'
import CadastrarModulos from '@/views/Adminstrativo/Cadastros/Modulos/CadastrarModulos.vue'
import ListagemComandos from '@/views/Adminstrativo/Cadastros/Comandos/ListagemComandos.vue'
import CadastroComandos from '@/views/Adminstrativo/Cadastros/Comandos/CadastroComandos.vue'
import EnvioComandos    from '@/views/Adminstrativo/NaoMenus/EnvioComandos.vue'
import ListagemPerfis   from '@/views/Adminstrativo/Cadastros/Perfis/ListagemPerfis.vue'
import CadastrarPerfis  from '@/views/Adminstrativo/Cadastros/Perfis/CadastrarPerfis.vue'
import ListagemGR from '@/views/Adminstrativo/Cadastros/Gr/ListagemGR.vue'
import CadastrarGR from '@/views/Adminstrativo/Cadastros/Gr/CadastroGR.vue'
import Espelhamento    from '@/views/Adminstrativo/NaoMenus/Espelhamento.vue'

export const ifAuthenticated = (_to: any, _from: any, next: any) => {
	if (new AuthService().isAuthenticated()) {
		next()
		return
	}
    type numero= 'N' | 'E';
    next('/login')
}
/**
 * Todas as rotas do módulo administrativo devem estar nesse arquivo.
 * Evitar criar rotas duplicadas, por exemplo, duas rotas /cadastrar/ com
 * filhos diferentes
 */
export const administrativoRoutes: RouteConfig =
{
	path: '/administrativo',
	name: 'Administrativo',
	beforeEnter: ifAuthenticated,
	meta:
	{
		requiresAuth: true,
		Module: 'administrativo',
		breadcrumbName: 'Administrativo'
	},
	components: { default: Layout },
	children: [
		{
			/**
			 * TODOS RELATÓRIOS EM ADMINISTRATIVO DEVEM SER FILHOS DESSE
			 */
			path: 'relatorios',
			meta: {
				Module: 'administrativo',
				breadcrumbName: 'Relatórios'
			},
			components: { content: Relatorio },
			children: [
				{
					path: 'logs',
					name: 'relLogs',
					meta: {
						Module: 'administrativo',
						breadcrumbName:'Logs'
					},
					component: relatorioLogs
				},
				{
					path: 'veiculo/cliente',
					meta: {
						Module: 'administrativo',
						breadcrumbName: 'Veículos por cliente'
					},
					component: VeiculosPorCliente
				},
				{
					path: 'ordem/servico',
					meta: {
						Module: 'administrativo',
						breadcrumbName: 'Ordem de serviço'
					},
					component: OrdemDeServico
				},
			]
		},
		{
			/**TODOS OS CADASTROS DO MODULO DEVEM SER FILHOS DESSE */
			path: 'cadastros',
			meta:{
				Module: 'administrativo',
				breadcrumbName: 'Cadastros'
			},
			components: { content: Cadastros },
			children: [
				// CadastroComandos
				{
					path: 'comandos',
					name: 'listarComandos',
					meta: {
						Module: 'administrativo',
						breadcrumbName: 'Comandos'
					},
					component: ListagemComandos
				},
				{
					path: 'comandos/:id?',
					name: 'cadastrarComandos',
					meta: {
						Module: 'administrativo',
						breadcrumbName: 'Comandos'
					},
					component: CadastroComandos
				},
				{
					path: 'clientes',
					name: 'listarClientes',
					meta: {
						Module: 'administrativo',
						breadcrumbName: 'Empresas'
					},
					component: ListagemClientes
				},
				{
					path: 'clientes/cadastrar/:id?',
					name: 'cadastrarCliente',
					meta: {
						Module: 'administrativo',
						breadcrumbName: 'Empresas'
					},
					component: CadastroClientes
				},
				{
					path: 'perfil/acesso',
					name: 'perfilAcesso',
					meta: {
						Module: 'administrativo',
						breadcrumbName: 'Perfil de acesso'
					},
					component: PerfilDeAcesso
				},
				{
					path: 'cadastrar/perfil/:id?',
					name: 'cadastrarPerfilAcesso',
					meta: {
						Module: 'administrativo',
						breadcrumbName: 'Perfil de acesso'
					},
					component: CadastrarPerfilDeAcesso
				},
				{
					path: 'motoristas',
					name: 'listarMotoristas',
					meta: {
						Module: 'administrativo',
						breadcrumbName: 'Colaboradores'
					},
					component: listarColaborador
				},
				{
					path: 'motoristas/cadastrar/:id?',
					name: 'cadastrarMotorista',
					meta: {
						Module: 'administrativo',
						breadcrumbName: 'Colaboradores'
					},
					component: cadastrarColaborador
				},
				{
					path: 'veiculos',
					name: 'listarVeiculo',
					meta:
					{
						Module: 'administrativo',
						breadcrumbName: 'Veículos'
					},
					component: Veiculos
				},
				{
					path: 'veiculos/cadastrar',
					name: 'cadastrarVeiculo',
					meta: 
					{ 
						Module: 'administrativo', 
						breadcrumbName: 'Veículos'
					},
					component: CadastroVeiculos
				},
				{
					path: 'veiculos/cadastrar/:id',
					name: 'editarVeiculo',
					meta: 
					{ 
						Module: 'administrativo', 
						breadcrumbName: 'Veículos'
					},
					component: CadastroVeiculos
				},
				{
					path: 'chips',
					name: 'listarChips',
					meta:
					{
						Module: 'administrativo',
						breadcrumbName: 'Chips'
					},
					component: ListagemChips
				},
				{
					path: 'chips/cadastrar/:id?',
					name:'cadastrarChip',
					meta:
					{
						Module: 'administrativo',
						breadcrumbName: 'Chips'
					},
					component: CadastroChip
				},
				{
					path: 'usuarios',
					name: 'listarUsuarios',
					meta: { 
						Module: 'administrativo', 
						breadcrumbName: 'Usuários'
					},
					component: ListagemUsuarios
				},
					
				{
					path: 'usuarios/cadastrar/:id?',
					name: 'cadastrarUsuario',    
					meta: {
						Module: 'administrativo', 
						breadcrumbName: 'Usuários' },
					component: CadastrarUsuarios },

				{
					path: 'usuarios/app',
					name:'usuariosApp',
					meta: {
						Module: 'administrativo', 
						breadcrumbName: 'Usuários App'
					},
					component: ListarUsuariosApp
				},
				{
					path: 'usuarios/app/cadastrar/:id?',
					name: 'cadastrarUsuarioApp',
					meta: {
						Module:'administrativo', 
						breadcrumbName: 'Usuários App'
					},
					component: CadastrarUsuarioApp
				},
				{
					path: 'perifericos',
					name: 'listarPeriferico',
					meta: { 
						Module: 'administrativo', 
						breadcrumbName: 'Periféricos'
					},
					component: ListagemPerifericos
				},
				{
					path: 'perifericos/cadastro/:id?',
					name: 'cadastrarPeriferico',
					meta: { 
						Module: 'administrativo', 
						breadcrumbName: 'Periféricos'
					},
					component: CadastroPerifericos
				},
				{
					path: 'modelos/modulos',
					name: 'listarModulo',
					meta: {
						Module: 'administrativo',
						breadcrumbName: 'Modelos de Módulos'
					},
					component: Moduloss
				},
				{
					path: 'modelos/cadastro/:id?',
					name: 'cadastrarModulo',
					meta: {
						Module: 'administrativo',
						breadcrumbName: 'Módulos'
					},
					component: Cadastro
				},
				
				{
					path: 'descontos',
					name: 'listarDescontos',
					meta: {
						Module: 'administrativo',
						breadcrumbName: 'Descontos'
					},
					component: Descontos
				},
				{
					path: 'cadastrar/descontos',
					name: 'cadastrarDescontos',
					meta: {
						Module: 'administrativo',
						breadcrumbName: 'Descontos'
					},
					component: CadastrarDesconto
				},
				{
					path: 'produtos',
					name: 'listarProdutos',
					meta: {
						Module: 'administrativo',
						breadcrumbName: 'Produtos'
					},
					component: Produtos
				},
				{
					path: 'editar/produtos/:id?',
					name: 'cadastrarProduto',
					meta: {
						Module: 'administrativo',
						breadcrumbName: 'Produtos'
					},
					component: EditarProdutos
				},
				{
					path: 'cadastrar/descontos/:id',
					meta: {
						Module: 'administrativo',
						breadcrumbName: 'Descontos'
					},
					component: CadastrarDesconto
				},

				//Agendamentos
				{
					path: 'agendamento',
					name: 'agendamento',
					meta:
					{
						Module: 'administrativo',
						breadcrumbName: 'Agendamento'
					},
					component: Agendamento
					// /administrativo/cadastros/agendamento
				},

				// Modulos
				{
					path: 'modulos',
					name: 'listarModulos',
					meta:
					{
						Module: 'administrativo',
						breadcrumbName: 'Módulos'
					},
					component: ListagemModulos
				},

				{
					path: 'modulos/cadastrar/:id?',
					name: 'cadastrarModulos',
					meta:
					{
						Module: 'administrativo',
						breadcrumbName: 'Módulos'
					},
					component: CadastrarModulos
				},

				// Perfis
				{
					path: 'perfis',
					name: 'listarPerfis',
					meta:
					{
						Module: 'administrativo',
						breadcrumbName: 'Perfis'
					},
					component: ListagemPerfis
				},

				{
					path: 'perfis/cadastrar/:id?',
					name: 'cadastrarPerfis',
					meta:
					{
						Module: 'administrativo',
						breadcrumbName: 'Perfis'
					},
					component: CadastrarPerfis
				},

				// GR
				{
					path: 'gr',
					name: 'listarGr',
					meta:
					{
						Module: 'administrativo',
						breadcrumbName: 'Gr'
					},
					component: ListagemGR
				},

				{
					path: 'gr/cadastrar/:id?',
					name: 'cadastrarGr',
					meta:
					{
						Module: 'administrativo',
						breadcrumbName: 'Gr'
					},
					component: CadastrarGR
				},
			]
		},
		{
			path: '',
			meta: {
				Module: 'administrativo',
				breadcrumbName: ''
			},
			components: {
				content: NaoMenu
			},
			children: [
				{
					path: 'monitoramento',
					name: 'monitoramento',
					meta: {
						Module: 'administrativo',
						breadcrumbName: 'Monitoramento'
					},
					component: Monitor
				},
				{
					path: 'gestao/cartoesRFID',
					name: 'gestaoRFID',
					meta: {
						Module: 'administrativo',
						breadcrumbName: 'Gestão RFID'
					},
					component: cartoesRFID
				},
				{
					path: 'gestao/cartoesRFID/criar/:id?',
					name: 'cadastrarCartaoRFID',
					meta: {
						Module: 'administrativo',
						breadcrumbName: 'Gestão RFID'
					},
					component: NovoCartao
				},
				{
					path: 'envio/comandos',
					name: 'envioComandos',
					meta: {
						Module: 'administrativo',
						breadcrumbName: 'Envio de comandos'
					},
					component: EnvioComandos
				},

				//Espelhamento
				{
					path: 'espelhamento',
					name: 'espelhamento',
					meta: {
						Module: 'administrativo',
						breadcrumbName: 'Espelhamento'
					},
					component: Espelhamento
				}
			]
		},
						
		// // tutorial de criação de rotas
		// {
		//     path: 'tutorial', meta: { Module: "administrativo", breadcrumbName: "Tutorial" }, components: { content: Relatorio }, children: [
		//         {path : 'tutorial', meta: {Module:"administrativo", breadcrumbName: "Descontos"}, component: Tutorial},
		//     ]
		// },
		
	]
}

