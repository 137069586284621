<template>
    <panelEagle id='integracao' :loading="loadingPanel">
      <div class="col-sm-12 row divDontPrint nopadding">
          <div class="col-sm-12  row">
              <div class="col-sm-6 nopadding">
                  <titulo titulo='Integração' :icon='mdiCogs'/>
              </div>
              <!-- Botão para gerar relatório -->
              <div class="col-sm-6 nopadding">
                  <simpleButton
                      text      ="Gerar"
                      :icon      ='mdiThumbUpOutline'
                      type      ="green"
                      event     ="gerar"
                      @gerar    ="gerarRelatorio()"
                      style     ="float:right;"
                      :disabled ="$v.$invalid"
                  />
              </div>
          </div>
          <slideUpAndDown>
              <template #filters>
                  <div class="col-sm-12"><hr></div>
                  <div class="col-sm-12 nopadding row mb-2">
                      <!-- Input da data  -->
                      <div class="col-sm-3 nopadding">
                          <rangeWithLimit 
                              titulo='Intervalo*' 
                              name='seletorIntervalo'
                              opens='right'
                              @changeInput='setData'
                          />
                      </div>
                      <!-- Select de empresas -->
                      <div class="col-sm-3 nopadding" id='seletorEmpresa'>
                          <selectAll
                              nameForRadio='seletorEmpresas'
                              :hasSelectAll='true'
                              :labels='[{"description":"Empresas*"}]'
                              :isMultiple='true'
                              :optionsArray='optSelectEmpresa'
                              :selected="selectedEmpresa"
                              @changeSelect="setEmpresa"
                          />
                      </div>
                      <!-- Select de Jornada -->
                      <div class="col-sm-3 nopadding">
                          <selectAll
                              nameForRadio  ='seletorJornada'
                              :hasSelectAll ='true'
                              :labels       ="[{indexDFH: 'JO', description: 'Jornada*'}]"
                              :isMultiple   ='true'
                              ref           ="selectJ"
                              :optionsArray ='optSelectJornada'
                              :disabled     ="$v.empresasSelecionadas.$invalid"
                              :loading      ='carregando'
                              @changeSelect ="setJornada"
                          />
                      </div>
                      <!-- Select de colaboradores -->
                      <div class="col-sm-3 nopadding" id='seletorColaborador'>
                          <selectAll
                              nameForRadio  ='seletorColaborador'
                              :hasSelectAll ='true'
                              :labels       ="[{indexDFH: 'C', description: 'Colaboradores*'}]"
                              :isMultiple   ='true'
                              ref           ="selectC"
                              :optionsArray ='optSelectColaborador'
                              :disabled     ="$v.jornadaSelecionada.$invalid"
                              :loading      ='carregando'
                              @changeSelect ="setColaborador"
                          />
                      </div>
                      <div class="col-sm-12 row nopadding mt-2">
                          <!-- Select do tipo de arquivo -->
                          <div class="col-sm-3 nopadding">
                              <selectAll
                                  nameForRadio  ='seletorTipo'
                                  :hasSelectAll ='false'
                                  :labels       ="[{indexDFH: 'T', description: 'Tipo arquivo*'}]"
                                  :isMultiple   ='false'
                                  ref           ="selectT"
                                  :optionsArray ='optSelectTipoArquivo'
                                  @changeSelect ="tipo"
                              />
                          </div>
                          <!-- Select Formato arquivo -->
                          <div class="col-sm-3 nopadding">
                              <selectAll
                                  nameForRadio  ='seletorFormato'
                                  :hasSelectAll ='false'
                                  :labels       ="[{indexDFH: 'T', description: 'Formato arquivo*'}]"
                                  :isMultiple   ='false'
                                  ref           ="selectT"
                                  :optionsArray ='optSelectFormatoArquivo'
                                  :disabled     ="integracaoQuestor"
                                  @changeSelect ="formato"
                              />
                          </div>
                          <!-- Select Delimitador -->
                          <div class="col-sm-3 nopadding" v-if="formatoSelecionado == 'txt'">
                              <selectAll
                                  nameForRadio  ='seletorDelimitador'
                                  :hasSelectAll ='false'
                                  :labels       ="[{indexDFH: 'D', description: 'Delimitador*'}]"
                                  :isMultiple   ='false'
                                  ref           ="selectD"
                                  :selected     ="[optSelectDelimitador[1]]"
                                  :optionsArray ='optSelectDelimitador'
                                  :disabled     ="integracaoQuestor"
                                  @changeSelect ="delimitador"
                              />
                          </div>
                      </div>
                  </div>
              </template>
          </slideUpAndDown>
          <div class="col-sm-12"><hr></div>
          <div class="col-sm-12">
              <table class="col-sm-12 status">
                  <status 
                      v-show="relatorio == 0"
                      :statusBar='status'
                      :msgCentro = "msgSucesso"
                      typeBar='tr'
                  />
              </table>
          </div>
          <div class="col-sm-12 nopadding" v-show="tipoArquivo == 'resumido'">
              <TableRelatorio>
                  <template #thead v-if='relatorio != 0'>
                      <!-- Cabeçalho da tabela -->
                      <br/>
                      <tr>
                          <th 
                              class="titulo" colspan="13">
                              Descritivo do arquivo
                          </th>
                      </tr>
                      <tr class="cabecalhoTabela">
                          <td>CPF</td>
                          <td>Nome</td>
                          <td>Mês/Ano</td>
                          <td>Horas diurnas</td>
                          <td>Espera</td>
                          <td>Falta</td>
                          <td>Adicional noturno</td>
                          <td v-if="horasExtras[0]">HE 1</td>
                          <td v-if="horasExtras[1]">HE 2</td>
                          <td v-if="horasExtras[2]">HE 3</td>
                          <td>HE 100%</td>
                          <td>HE noturna</td>
                          <td>Folga BH</td>
                      </tr> 
                  </template>
                  <!-- Corpo da tabela -->
                  <template slot="tbody"
                      v-for="(empresa) in relatorio">
                      <template v-for="(data) in empresa">
                          <tr v-for="(dados, da) in data"
                              :key="da">
                              <td class="tbody">{{dados.cpf}}</td>
                              <td class="tbody">{{dados.nome}}</td>
                              <td class="tbody">{{dados.mesano}}</td>
                              <td class="tbody">{{dados.horasDiurnas}}</td>
                              <td class="tbody">{{dados.espera}}</td>
                              <td class="tbody">{{dados.falta}}</td>
                              <td v-if="horasExtras[0]"
                                  class="tbody">{{dados.horaExtra1}}</td>
                              <td v-if="horasExtras[1]"
                                  class="tbody">{{dados.horaExtra2}}</td>
                              <td v-if="horasExtras[2]"
                                  class="tbody">{{dados.horaExtra3}}</td>
                              <td class="tbody">{{dados.adNoturno}}</td>
                              <td class="tbody">{{dados.extra100}}</td>
                              <td class="tbody">{{dados.extraNoturna}}</td>
                              <td class="tbody">{{dados.folgaBancoHoras}}</td>
                          </tr>
                      </template>
                  </template>
              </TableRelatorio>
          </div>
  
          <div class="col-sm-12 nopadding" v-show="tipoArquivo == 'completo'">
              <TableRelatorio>
                  <template #thead v-if='relatorio != 0'>
                      <!-- Cabeçalho da tabela -->
                      <br/>
                      <tr>
                          <th 
                              class="titulo" colspan="19">
                              Descritivo do arquivo
                          </th>
                      </tr>
                      <tr class="cabecalhoTabela">
                          <td>CPF</td>
                          <td>Nome</td>
                          <td>Mês/Ano</td>
                          <td v-if="horasExtras[0]">HE 1</td>
                          <td v-if="horasExtras[1]">HE 2</td>
                          <td v-if="horasExtras[2]">HE 3</td>
                          <td>Extra 1</td>
                          <td>Extra 100%</td>
                          <td>Horas diurnas</td>
                          <td>Total de direção</td>
                          <td>Extra diurnas</td>
                          <td>Horas noturnas</td>
                          <td>Parada em jornada</td>
                          <td>Extra noturna</td>
                          <td>Complementares</td>
                          <td>Espera</td>
                          <td>Saldo hora extra</td>
                          <td>Folga banco de horas</td>
                          <td>Total intervalo</td>
                      </tr> 
                  </template>
                  <!-- Corpo da tabela -->
                  <template slot="tbody"
                      v-for="(empresa) in relatorio">
                      <template v-for="(data) in empresa">
                          <tr v-for="(dados, da) in data"
                              :key="da">
                              <td class="tbody">{{dados.cpf}}</td>
                              <td class="tbody">{{dados.nome}}</td>
                              <td class="tbody">{{dados.mesano}}</td>
                              <td v-if="horasExtras[0]"
                                  class="tbody">{{dados.horaExtra1}}</td>
                              <td v-if="horasExtras[1]"
                                  class="tbody">{{dados.horaExtra2}}</td>
                              <td v-if="horasExtras[2]"
                                  class="tbody">{{dados.horaExtra3}}</td>
                              <td class="tbody">{{dados.horaExtra1}}</td>
                              <td class="tbody">{{dados.extra100}}</td>
                              <td class="tbody">{{dados.horasDiurnas}}</td>
                              <td class="tbody">{{dados.totalDirecao}}</td>
                              <td class="tbody">{{dados.extraDiurnas}}</td>
                              <td class="tbody">{{dados.horasNoturnas}}</td>
                              <td class="tbody">{{dados.paradoJornada}}</td>
                              <td class="tbody">{{dados.extraNoturna}}</td>
                              <td class="tbody">{{dados.complementares}}</td>
                              <td class="tbody">{{dados.espera}}</td>
                              <td class="tbody">{{dados.saldoHoraExtra}}</td>
                              <td class="tbody">{{dados.folgaBancoHoras}}</td>
                              <td class="tbody">{{dados.totalIntervalo}}</td>
                          </tr>
                      </template>
                  </template>
              </TableRelatorio>
          </div>
      </div>
    </panelEagle>
  </template>
  
  <script>
  import { mdiCogs, mdiThumbUpOutline } from '@mdi/js'
  import { required } from 'vuelidate/lib/validators'
  import panelEagle from '@/components/Atom/Panel/PanelEagle'
  import { EmpresasService } from '@/Services/auth/Empresas.service'
  import { FiltrosService } from '@/Services/filtros/filtros.Service'
  import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
  import { mapGetters } from 'vuex'
  
  export default {
      name:'integracao',
      components: {
          panelEagle,
          status           : require('@/components/Atom/StatusInformation/StatusInformation').default,
          rangeWithLimit   : require('@/components/Atom/Datas/InputRangeWithLimit').default,
          titulo           : require('@/components/Atom/Header/Titulo').default,
          selectAll        : require('@/components/Atom/Select/SelectAll').default,
          TableRelatorio   : require('@/components/Atom/Table/TableRelatorio').default,
          slideUpAndDown   : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
          simpleButton     : require('@/components/Atom/Buttons/SimpleButton').default,
      },
      validations:{
          empresasSelecionadas:{
              required,
          },
          colaboradoresSelecionados:{
              required,
          },
          tipoSelecionado:{
              required,
          },
          formatoSelecionado:{
              required,
          },
          jornadaSelecionada:{
              required,
          },
      },
      data() {
          return {
              mdiThumbUpOutline:mdiThumbUpOutline,
              mdiCogs:mdiCogs,
              loadingPanel:false,
              optSelectEmpresa          : new EmpresasService().Get(),
              optSelectColaborador      : [],
              optSelectJornada          : [],
              optSelectTipoArquivo      : [
                  {
                      description :'Jornada Completa',
                      value       :'JC'
                  },
                  {
                      description :'Jornada Resumida',
                      value       :'JR'
                  },
                  {
                      description :'Questor - Variáveis Ponto',
                      value       :'QR'
                  }
              ],
              empresasSelecionadas      : [],
              colaboradoresSelecionados : [],
              tipoSelecionado           : [],
              dataSelecionada           : [],
              formatoSelecionado        : [],
              tipoArquivo               : '',
              formatoArquivo            : '',
              delimitadorSelecionado    : ';',
              relatorio                 : [],
              txt                       : [],
              empresasGerar             : [],
              status                    :'info',
              msgSucesso                :'Selecione os campos obrigatórios (*) para gerar.',
              carregando                : false,
              optSelectFormatoArquivo      : [
                  {
                      description :'txt',
                      value       :'txt'
                  },
                  {
                      description :'xlsx',
                      value       :'xlsx'
                  },
              ],
              optSelectDelimitador      : [
                  {
                      description :'|',
                      value       :'|'
                  },
                  {
                      description :';',
                      value       :';'
                  },
              ],
              selectedEmpresa: [],
              jornadaSelecionada : [],
              horasExtras : [],
              integracaoQuestor: false,
          }
      },
      methods: {
          ...mapGetters(['getMaster']),
          // Início dos métodos para pegar informação de selects
          setEmpresa(empresas){
              this.relatorio = []
              this.status = 'info'
              this.msgSucesso = 'Selecione os campos obrigatórios (*) para gerar.'
              this.empresasSelecionadas = empresas
              this.$refs.selectJ.clearAll()
              this.$refs.selectC.clearAll()
              this.procuraJornada(empresas)
          },
          setColaborador(colaboradores){
              this.relatorio = []
              this.status = 'info'
              this.msgSucesso = 'Selecione os campos obrigatórios (*) para gerar.'
              this.colaboradoresSelecionados = colaboradores
          },
          tipo(tipo){
              this.relatorio = []
              this.status = 'info'
              this.msgSucesso = 'Selecione os campos obrigatórios (*) para gerar.'
              this.tipoSelecionado = tipo
              this.integracaoQuestor = false
              if(tipo == 'QR'){
                  this.integracaoQuestor = true
                  this.formatoSelecionado = 'txt'
                  this.delimitadorSelecionado = ';'
              }
          },
          setData(intervaloData){
              this.relatorio = []
              this.status = 'info'
              this.msgSucesso = 'Selecione os campos obrigatórios (*) para gerar.'
              this.dataSelecionada = intervaloData
          },
          formato(formato){
              this.relatorio = []
              this.status = 'info'
              this.msgSucesso = 'Selecione os campos obrigatórios (*) para gerar.'
              this.formatoSelecionado = formato[0]
          },
          delimitador(delimitador){
              this.delimitadorSelecionado = delimitador[0]
          },
          setJornada(jornada){
              this.relatorio = []
              this.status = 'info'
              this.msgSucesso = 'Selecione os campos obrigatórios (*) para gerar.'
              this.jornadaSelecionada = jornada
              this.$refs.selectC.clearAll()
              if(jornada.length > 0){
                  this.procuraColaborador()
              }
          },
          // Fim dos métodos 
  
          //Função para gerar relatório
          //Pega as empresas, colaboradores, tipo de arquivo e intervalo de tempo para enviar ao back
          //O retorno coloca na variável relatório
          async gerarRelatorio() {
              this.loadingPanel = true
              this.relatorio = []
              this.msgSucesso = 'Selecione os campos obrigatórios (*) para gerar.'
              var dados = await new HttpRequest()
                  .Post('/controle/jornada/integracao/gerarRelatorio', 
                      {
                          'clientes'      : this.empresasSelecionadas, 
                          'colaboradores' : this.colaboradoresSelecionados,
                          'tipoArquivo'   : this.tipoSelecionado,
                          'data'          : this.dataSelecionada,
                          'formato'       : this.formatoSelecionado,
                          'delimitador'   : this.delimitadorSelecionado,
                          'jornada'       : this.jornadaSelecionada,
                      }
                  )
              if(dados.data.status){
                  if(dados.code == 201){
                      if(dados.data.tipo == 'questor'){
                          this.empresasGerar = (dados.data.empresas[0].clfantasia).replace(/ /gi, '_')
                          this.txt        = dados.data.arquivo
                          this.gerarTxt()
                      }else{
                          this.relatorio  = dados.data.dados
                          this.txt        = dados.data.arquivo
                          this.status     = 'info'
                          this.msgSucesso = 'Sucesso!'
                          this.tipoArquivo = dados.data.tipo
                          this.formatoArquivo = dados.data.formato
                          this.empresasGerar = (dados.data.empresas[0].clfantasia).replace(/ /gi, '_')
                          this.horasExtras = dados.data.extras
                          if(this.formatoSelecionado == 'xlsx'){
                              var root =  process.env.VUE_APP_ROOT
                              window.open(root+'/'+dados.data.arquivo[0])
                          }else{
                              this.gerarTxt()
                          }
                      }
                  } else {
                      this.status = 'error'
                  }
                  // this.empresaGerar = dados.data.empresa[0].clfantasia
              } else {
                  this.status = 'error2'
              }
              this.loadingPanel = false
          },
  
          //Função para exportar o aquivo txt quando usuário gerar a tabela
          //A função recebe o aquivo no formato txt pela variável txt cria o elemento e o exporta
          //Para passar ao objeto Blob tem que ser os dados do arquivo!! 
          gerarTxt(){
              const blob = new Blob([this.txt], {type: 'txt'})
              const a = document.createElement('a')
              a.setAttribute('download', this.empresasGerar+' '+this.dataSelecionada+'.'+this.formatoArquivo)
              a.setAttribute('href', window.URL.createObjectURL(blob))
              a.click()
          },
  
          /**
           * @description Método para buscar as jornadas relacionadas 
           *  as empresas selecionadas
           * @param {Array} arr - Empresas selecionadas
           * @author Vitor Hugo 🐨
           */
          procuraJornada(arr){
              if(arr.length > 0){
                  this.carregando= true
                  new FiltrosService().dados_filtros(arr, ['JO'])
                      .then((data)=>{
                          this.optSelectJornada = data.JO
                          this.carregando= false
                      })
              }
          },
  
          /**
           * @description Método para buscar os colaboradores
           *  referentes as jornadas selecionadas
           * @author Vitor Hugo 🐨
           */
          procuraColaborador(){
              this.carregando = true
              new HttpRequest().Post('/controle/jornada/integracao/buscar/colaborador', {'jornada' : this.jornadaSelecionada})
                  .then(dados=>{
                      if(dados.code == 200){
                          this.optSelectColaborador = dados.data.colaborador
                          this.carregando = false
                      }
                      else{
                          this.optSelectColaborador = []
                          this .carregando = false
                      }
                  })
          }
      },
  
      mounted() {
          if(!this.getMaster()){
              this.selectedEmpresa = this.optSelectEmpresa
          }
      }
  }
  </script>
  <style lang="scss" scoped>
      thead{
          td{
              min-width: 117px;
              max-width: 117px;
              border-left: lightgray solid 1px;
              border-right: lightgray solid 1px;
              vertical-align: middle;
          }
      }
      .tbody{
          padding: 2px;
          padding-left: 5px;
          vertical-align: middle;
          background: white;
          border-left: lightgray solid 1px;
          border-right: lightgray solid 1px;
      }
      .cabecalhoTabela{
          td{
              padding: 2px;
              padding-left: 5px;
          }
      }
      .titulo{
          background: #777777;
          color: white;
          font-size: 16px !important;
          font-weight: bold !important;
          height: 40px !important;
          padding-top: 9px !important;
          padding-left: 5px;
      } 
  </style>