<template>
    <panelEagle id='importarPontos' :loading="enviando">
        <div class="col-sm-12 row">
            <div class="col-sm-6 nopadding">
                <titulo
                    titulo='Importação de arquivos de pontos'
                    :icon='mdiMapMarkerRadius'
                />        
            </div>
            <div class="col-sm-6 nopadding">
                <botoesSC
                    tipoCancela='button'
                    @cancelarCadastro="cancelarCadastro"
                >
                    <!-- linkCancel='listarPonto' -->
                    <template #cancelar v-if='dataImportacao.length > 0'>
                        <buttonS
                            :icon='mdiTrashCanOutline'
                            text='Descartar'
                            type='red'
                            event='click'
                            @click='descartar'
                        />
                    </template>
                    <template  #salvar> 
                        <botaoL
                            :isLoading='false'
                            :disabled='$v.$invalid || NaoEnviouArquivo'
                            :icon='mdiCheckBold'
                            type='blue'
                            text='Enviar'
                            event='click'
                            @click="enviar"
                        />
                    </template>
                </botoesSC>
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <div class="col-sm-12 row nopadding">
            <div class="col-sm-4 nopadding">
                <selectAll
                    ref="selectEmpresa"
                    :disabled='!cadastrar'
                    :isMultiple='false'
                    :allowEmpty='false'
                    nameForRadio='seletorEmpresa'
                    :extraClass="{'border border-danger':$v.campos.pocodigocliente.$anyError}"
                    @close='$v.campos.pocodigocliente.$touch()'
                    :labels="[{'description':'Empresa*'}]"
                    :optionsArray='optEmpresa'
                    :selected="cliente"
                    @changeSelect='changeEmpresa'
                />
            </div>
            <div class="col-sm-4 nopadding">
                <selectAll
                    ref="selectPonto"
                    :disabled='!cadastrar'
                    nameForRadio='seletorTipo'
                    :optionsArray='optTiposDePonto'
                    :extraClass="{'border border-danger': $v.campos.potipo.$anyError}"
                    :isMultiple='false'
                    :allowEmpty='false'
                    @close='$v.campos.potipo.$touch()'
                    :labels="[{'description':'Tipo de ponto*'}]"
                    @changeSelect='changeTipo'
                />
            </div>
            <div class="col-sm-4 nopadding">
                <inputS
                    ref='inputRaio'
                    :inputClass="{'border border-danger':$v.campos.poraio.$anyError}"
                    label='Raio padrão para os pontos importados'
                    placeholder='50'
                    @blur='$v.campos.poraio.$touch()'
                    @changeInput='changeRaio'
                />
                <span class='raioErro' v-show='!this.$v.campos.poraio.numeric'>
                    {{' Campo deve ser numérico'}}
                    <br>
                </span>
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <div class="col-sm-12">
            <dropDown
                v-if="cadastrar"
                ref='fileDropDown'
                class='fileDropDown'
                msgCentro='Arraste seus arquivos até aqui'
                @files='arquivo'  
                @beforedelete='beforeDelete'
            />
            <div v-else class="statusRel">
                Você não possui permissão para importar um ponto.
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <div 
            class="col-sm-12 nopadding"
            v-if="cadastrar">
            <h4 class='tituloTabela'> Listagem de conflitos de importação </h4>
            <tableL :titles='titulosTable' :data='dataImportacao'>
                <template #acoes='{row}' >
                    <div class="col-sm-12 nopadding botoesTabela" >
                        <buttonS
                            title='Ignorar e remover ponto'
                            event='click'
                            width='40%'
                            :icon='mdiTrashCanOutline'
                            type='red'
                            @click='removerPonto(row.codigo)'
                        />
                        <buttonS
                            :title="row.sobeescrever?'Ponto sera sobreescrito':'Sobreescrever ponto'"
                            :type="row.sobeescrever?'light-blue':'blue'"
                            :icon='mdiCheckBold'
                            width='40%'
                            event="click"
                            @click='sobreescreverPonto(row.codigo, row)'
                        />
                    </div>
                </template>
            </tableL>
        </div>
        <div class="col-sm-12 nopadding">
            <b-alert :show='anyError' variant="warning" align='left'>
                <h4 class="alert-heading">Erros de Importação</h4>
                <p>
                    Importação interrompida pois um ou mais pontos não possuem os parâmetros desejados.
                    <br>
                    Verifique os pontos a baixo e tente novamente
                </p>
                <ul class='listaErros'>
                    <li v-for="(erro, index) in errosImportancao"
                        :key='index'>
                        {{'Código externo: '+erro.pocodigoexterno}}
                        <ul>
                            <li v-for='(er, index) in errosPonto(erro)'
                                :key='index'>
                                {{er}}
                            </li>
                        </ul>
                    </li>
                    <li v-for='(i, a) in incompleto'
                        :key='a'>
                        {{'O ponto representado na linha '+(i+1)+' não esta no formato correto'}}
                    </li>
                </ul>
            </b-alert>
        </div>
        <div v-if="cadastrar"
            class="col-sm-12 nopadding">
            <requiredFields/>
        </div>
        <div v-if="cadastrar" class="col-sm-12"><hr></div>
        <modalPontosNSalvos :pontosNSalvo="pontosNSalvo"/>
    </panelEagle>
</template> 

<script>
import Vue from 'vue'
import { mdiMapMarkerRadius, mdiTrashCanOutline, mdiCheckBold } from '@mdi/js'
import { required, numeric } from 'vuelidate/lib/validators'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { AuthService } from '@/Services/auth/Auth.Service'
import { conectionError } from '@/Services/Helpers/swellHeper.js'
import Swal from 'sweetalert2'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name:'importarPontos',
    components: {
        titulo         : require('@/components/Atom/Header/Titulo').default, 
        selectAll      : require('@/components/Atom/Select/SelectAll').default,
        panelEagle     : require('@/components/Atom/Panel/PanelEagle').default,
        inputS         : require('@/components/Atom/Inputs/InputSimple').default,
        tableL         : require('@/components/Atom/Table/TableListagem').default,
        buttonS        : require('@/components/Atom/Buttons/SimpleButton').default,
        dropDown       : require('@/components/Atom/Inputs/InputDropZone').default,
        requiredFields : require('@/components/Atom/Footer/RequiredFields').default,
        botaoL         : require('@/components/Atom/Buttons/LoadingButton').default,
        botoesSC       : require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
        modalPontosNSalvos : require('@/components/Atom/ModalSpecific/ModalPontosNSalvos').default,
    },
    validations:{
        campos:{
            pocodigocliente:{ required },
            potipo:         { required },
            poraio:         { numeric }
        },
    },
    data() {
        return {
            mdiCheckBold:mdiCheckBold,
            mdiTrashCanOutline:mdiTrashCanOutline,
            mdiMapMarkerRadius:mdiMapMarkerRadius,
            optEmpresa: [],
            cliente: [],
            dataImportacao:[],
            incompleto:[],
            errosImportancao:[],
            totalPontos:'',
            totalConflitos:'',
            anyError:false,
            enviando:false,
            campos:{
                pocodigocliente:'',
                potipo:'',
                poraio:'',
            },
            optTiposDePonto:[
                {'value':'C', 'description':'Coleta'},
                {'value':'E', 'description':'Entrega'},
                {'value':'P', 'description':'Referência'},
            ],
            rota:'/telemetria/cadastros/pontos/',
            file:[],
            titulosTable:[
                {'name':'Descrição do ponto','number':'podescricao'},
                {'name':'Razão do conflito', 'number':'conflito'},
                {'name':'Tipo do ponto',     'number':'potipo'},
                {'name':'Raio',              'number':'poraio'},
                {'name':'Latitude',          'number':'polatitude'},
                {'name':'Longitude',         'number':'polongitude'},
                {'name':'Código externo',    'number':'pocodigoexterno'}
            ],
            pontosNSalvo:[],
        }
    },

    methods:{
        /**
		 * Essa tela possui uma regra diferente e ela existe me dois modulos
		 * nesse caso para nao ser direcionado para o outro modulo ela faz a
		 * comparacao para saber qual tela ela precisa direcionar
		 * no link tem a tarefa escrita
		 * @link https://app.clickup.com/t/2ykugg1
		 */
        cancelarCadastro(){
			if(this.$route.name === "importarPontosWay"){
				this.$router.push({name:'listagemPontosWay'})
				return
			}
			this.$router.push({name:'listarPonto'})
		},

        changeEmpresa(v) {
            this.limpaRelatorio()
            this.campos.pocodigocliente = v[0]
        },

        changeTipo(v) {
            this.limpaRelatorio()
            this.campos.potipo = v[0]
        },

        changeRaio(v) {
            this.limpaRelatorio()
            this.campos.poraio = v
        },

        limpaRelatorio() {
            this.dataImportacao = []
            this.incompleto = []
            this.totalPontos = []
            this.totalConflitos = []
            this.anyError = false
        },

        beforeDelete(){
            this.enviando = false
        },
        errosPonto(ponto){
            let string = []
            let regex = /-\d.,|^\d.,/
            if(regex.test(ponto.polatitude) || regex.test(ponto.polongitude)){
                string.push('Formato de latitude ou longitude errada')
            }
            if(!ponto.podescricao){
                string.push('Ponto não possui descrição')
            }
            if(!ponto.poendereco){
                string.push('Ponto não possui endereço ou não foi possível encontrar um endereço atravez das coordenadas')
            }
            if(!ponto.polatitude || !ponto.polongitude){
                string.push('Ponto não possui coordenadas ou não foi possível encontrar as coordenadas atravez do endereço')
            }
            return string
        },
        /**
         * Faz o envio do arquivo a ser importando para o back-end
         * O vue-file-agent faz a request, por isso:
         * -> Ele precisa da URL,
         * -> Como a API necessita de autenticação, é necessário
         * usar o token do usuário enviando o arquivo
         */
        async enviar() {
            this.enviando = true
            try{
                var url = process.env.VUE_APP_ROOT_API+this.rota+'importar'
                var fileAgent =  this.$refs.fileDropDown.$refs.vueFileAgent
                var token = new AuthService()
                var txt = this.file
                token = token.getToken()
                var cli = this.campos.pocodigocliente
                var tipo = this.campos.potipo
                var raio = this.campos.poraio
                var strBearer = `Bearer ${token.token || ""}`;
                var header = {
                    'Authorization': strBearer,
                }
                await fileAgent.upload(url, header, txt, function createFormData(fileData){
                    const form = new FormData()
                    form.append('file', fileData.file)
                    form.append('empresa', cli)
                    form.append('tipo', tipo)
                    form.append('raio', raio)
                    return form
                }).then(data=>{
                    this.dataImportacao = data[0].data.conflitos
                    this.errosImportancao = data[0].data.error
                    this.incompleto = data[0].data.incompleto
                    this.totalPontos = data[0].data.pontos
                    this.totalConflitos = data[0].data.nConflitos
                    this.anyError = (this.errosImportancao.length > 0 || this.incompleto.length > 0)
                    if(!this.anyError){
                        let msg = 'Pontos importados com sucesso'
                        this.toastShow('Sucesso', msg, 'success')
                    }
                    this.$refs.fileDropDown.clearArchives()
                    this.file = []
                    if(data[0].data.pontosNSalvos.length){
                        this.pontosNSalvo = data[0].data.pontosNSalvos
                        this.$bvModal.show('pontosNSalvos')
                    }
                    this.enviando = false
                }).catch(erro => {
                    let msg = 'Falha ao importar os pontos'
                    conectionError(msg, 'error', 'Ops!')
                })
            }catch{
                conectionError()
            }
            this.enviando = false

        },

        toastShow(titulo, msg, type){
			this.$bvToast.toast(msg, {
				title: titulo,
				autoHideDelay: 2500,
				variant: type,
			})
		},

        arquivo(file){
            this.file = file
        },

        removerPonto(codigo){
            var index = this.findIndex(codigo);
            this.dataImportacao.splice(index, 1)
        },
      
        sobreescreverPonto(codigo, row){
            row['sobeescrever'] = !row['sobeescrever']
            let obj = {
                'ponto':{ ...row }
            }
            new HttpRequest()
                .Post(this.rota+'sobreescrever', obj)
                .then((data)=>{
                    if(data.data.sucess){
                        this.removerPonto(codigo)
                    }else{
                       conectionError()
                    }
                })
        },

        findIndex(codigo){
            var index = this.dataImportacao.findIndex(el=>{
                return el.codigo == codigo
            })
            return index;
        },
        
        /**
         * Só limpando todos os campos para que o usuário possa descartar todos os conflitos de importação
         * com um único click
         */
        descartar(){
            this.dataImportacao = []
            this.errosImportancao = []
            this.incompleto = []
            this.anyError = false
        },

        ...mapGetters(['getMaster','getCadastrar']),
    },
    computed:{
        cadastrar() {
            return this.getCadastrar()
        },
        NaoEnviouArquivo(){
            if(this.file.length == 0){
                return true
            }else{
                return false
            }
        }
    },

    mounted () {
        this.optEmpresa = new EmpresasService().Get()
        if(!this.getMaster()){
            this.cliente = this.optEmpresa
        }
    },
})
</script>
<style lang="scss" scoped>
    .fileDropDown{
        padding: 5px;
        border-style: dotted;
        border-radius: 10px;
    }

    .botoesTabela{
        display: inline-block;
    }

    .listaErros{
        list-style-type: none
        li;
        text-align: left;
    }
    
    .tituloTabela{
        text-align: left;
        margin-top: 2%;
        margin-left: 1%;
    }
    
    .raioErro{
        color: red;
        text-align: center;
        font-size: 14px;
    }

    .statusRel{
        padding: 23px !important;
        font-size: 14px;
        color: #FFF;
        text-align: center;
        background: #E04B4A;
    }
</style>
