<template>
    <panelEagle id='DashboardGestaoDesempenho' :loading='loadingPanel'>
        
    </panelEagle>
</template>
<script>
import Vue  from 'vue'
import {mdiArrowUpThick, mdiArrowDownThick} from '@mdi/js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { DateTime } from 'luxon'
export default Vue.extend({
	name:'DashboardGestaoDesempenho',
	
	components:{
		'panelEagle'           : require('@/components/Atom/Panel/PanelEagle').default,
		// 'indicador'            : require('@/components/Atom/Grafico/Indicador').default,
		// 'chartsColumn'         : require('@/components/Atom/Grafico/ChartsColumn').default,
		'podium'               : require('@/components/Atom/Grafico/Podium').default,
		// 'chartsPizzaDashboard' : require('@/components/Atom/Grafico/ChartsPizzaDashboard').default,
		// 'simpleButton'         : require('@/components/Atom/Buttons/SimpleButton').default,
	},

	data(){
		return {
            link: '/gestao/desempenho/dashboard',
			mdiArrowUpThick: mdiArrowUpThick,
			mdiArrowDownThick: mdiArrowDownThick,
            melhoresCondutores: [],
			labelMelhoresCondutores: [],
			dadosMelhoresCondutores: [],
            precisamMelhorar: [],
            labelPrecisamMelhorar: [],
            dadosPrecisamMelhorar: [],
			loadingPanel: false,
			dataAtualizacao: '',
		}
	},

	methods:{

		trataDadosMelhoresCondutores(dados){
			if(dados && dados[0]){
				this.melhoresCondutores = JSON.parse(dados[0].ddvalores)
				this.labelMelhoresCondutores = this.melhoresCondutores.label
				this.dadosMelhoresCondutores = this.melhoresCondutores.dados
			}
		},

		trataDadosPrecisamMelhorar(dados){
			if(dados && dados[0]){
				this.precisamMelhorar = JSON.parse(dados[0].ddvalores)
				this.labelPrecisamMelhorar = this.precisamMelhorar.label
				this.dadosPrecisamMelhorar = this.precisamMelhorar.dados
			}
		},

		buscaDadosDashboard(){
			this.loadingPanel = true
			new HttpRequest().Post(this.link+'/gerar')
				.then((dados)=>{
					if(dados.code == 200){
						var dado = dados.data
                        this.trataDadosMelhoresCondutores(dado.melhoresCondutores)
                        this.trataDadosPrecisamMelhorar(dado.precisamMelhorar)
                        if(dado.dataHoraAtualizacao != null){
                            this.dataAtualizacao = DateTime
                                .fromFormat(
                                    dado.dataHoraAtualizacao.dddatahoraevento,
                                    'yyyy-LL-dd HH:mm:ss')
                                .toFormat('dd/LL/yyyy HH:mm:ss')
                        }
						this.loadingPanel = false
					} else {
						this.toastShow('Entre em contato com o suporte',
							'danger',
							'Erro ao gerar gráfico')
						this.loadingPanel = false
					}
				})       
		},

		toastShow(msg, type, title = ''){
			this.$bvToast.toast(`${msg}`, {
				autoHideDelay: 3000,
				variant: type,
				title: title
			})
		},

		atualizaDash(){
			this.dadosMelhoresCondutores = []
            this.dadosPrecisamMelhorar = []
			this.buscaDadosDashboard()
		},
	},

	mounted(){
		this.buscaDadosDashboard()
	}
})


</script>
<style lang="scss">
#DashboardGestaoDesempenho{
    .background-gray{
        background: #f8f8f8;
        box-shadow: 1px 1px 1px 1px #e0e0e0;
        height: 100%;
        .font{
            font-size: 10px;
            padding: 15px;
        }
        div{
            padding-top: 5px;
        }
    }
    .indicador{
        height: 85px;
    }
    .indicadorLista{
        height: 172px;
    }
    #barchartprecisamMelhorar{
        min-width: 400px !important;
    }
    #barchartmelhoresCondutores{
        min-width: 400px !important;
    }
    .backgoundLista{
        background: #f8f8f8;
        box-shadow: 1px 1px 1px 1px #e0e0e0;
    }
    .apexcharts-datalabels{
        g.apexcharts-data-labels {
            border: 1px solid #ccc !important;
            border-radius: 30px !important;
            padding: 15px !important;
            background-color: white !important;
        }
    }
}

</style>
