<template>
    <tr>
        <td colspan="4">{{error}}</td>
    </tr>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
    props:{
        error:{
            type: String,
            required: true,
            default: ''
        }
    }, 
})
</script>

<style lang="scss">

</style>