/**
    Este é um alerta simples para mostrar informações de confirmação ou erro.

    -> Propriedades:
        1- isLoading - Boolean - mostra as bolinhas de carregando - EX: true/false
        2- message - String - Mensagem a ser mostrada no alerta - EX: 'Mensagem a ser mostrada'
        3- icon - String - Icone do alerta - EX: 'mdiFacebook'
        4- type - String - Cor do alerta - EX: red/orange/green/light-green/blue
        5- sizeIcon - String - tamanho do icone - EX: '18'

    -> Eventos:
        1- clickAlert - Emite quando o alerta for clicado - Sem parametros a mais
 */

<template>
    <div :class="'col-sm-12 alertAlert '+(hasClick ? 'clickaball' : '')" @click="clickAlert">
        <div :class="'alertFather alert-'+type">
            <pulse-loader :loading="isLoading" size="12px" color="#fff"></pulse-loader>
            <baseIcon :size='sizeIcon' class="" :icon="icon"/>
            {{message}}
            <!-- <span :class="icon"></span> -->
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
    components:{
        baseIcon:require('@/components/Atom/Icon/BaseIcon.vue').default,
        'PulseLoader' : require('vue-spinner/src/PulseLoader').default
    },
    props:{
        isLoading:{
            type: Boolean,
            required: true,
            default: false,
        },
        message:{
            type: String,
            default: '',
        },
        icon:{
            type: String,
            default: '',
        },
        type:{
            type:String,
            required: true,
            default: 'red',
        },
        sizeIcon:{
            type: String,
            default: '18',
        },
        hasClick:{
            type: Boolean,
            default: false,
        }
    },
    methods:{
        clickAlert(){
            this.$emit('clickAlert');
        }
    }
})
</script>

<style lang="scss">
    .alertAlert{
        font-size: 13px;
        color: #fff;
        border-radius: 5px;
        padding: 8px 10px 6px 10px;
        float: right;
        // display: none;

        &.clickaball{
            cursor: pointer;
        }

        .alertFather{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;

            .v-spinner{
                float: left;
            }
        }

        .alert-red{
            background: rgba(224, 75, 74, 0.76) !important;
            border: 1px solid #e04b4a !important;
        }
        .alert-orange {
            background: rgba(240, 173, 78, 0.76) !important;
            border: 1px solid #F0AD4E;
        }
        .alert-green {
            background: rgba(28, 175, 154, 0.76) !important;
            border: 1px solid #1CAF9A;
        }
        .alert-light-green {
            background: rgba(149, 182, 92, 0.76) !important;
            border: 1px solid #95b65c;
        }
        .alert-blue {
            background: rgba(66, 139, 202, 0.76);
            border: 1px solid #428BCA;
        }
    }
</style>