<template>
    <panelEagle id='analiseGeral' :loading="loaidngPanel">
            <div class="col-sm-12 row divDontPrint">
                <div class="col-sm-6 nopadding">
                    <titulo
                        titulo='Análise geral'
                        :icon='mdiChartPie'
                    />
                </div>
                <div class="col-sm-6 divButtonsBasic divDontPrint">
                    <basicButtonsRelatoriosEIG
                        :disabledDropdown  ="valueGrafico.length == 0 ? true : false"
                        :loading           ='loadingExportar'
                        :disabled          ="$v.$invalid"
                        @gerarRelatorio    ='gerarRelatorio'
                        @exportarRelatorio ='exportar'>
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-3 nopadding">
                    <inputRangeWithLimit
                        class="pr-0"
                        name='pickerPeriodoProcessamento'
                        opens='right'
                        @changeInput='changeData'
                    />
                </div>
                <div class="col-sm-3 nopadding">
                    <selectAll
                        nameForRadio='seletorEmpresaProcessamento'
                        :isMultiple='true'
                        :hasSelectAll='true'
                        :labels='[{"description":"Empresas"}]'
                        :optionsArray='optSelectEmpresas'
                        :selected="selectedEmpresa"
                        @changeSelect='changeEmpresa'
                    />
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12">
                <div v-if="valueGrafico.length == 0">
                    <statusInformation 
                        typeBar="div" 
                        :colspanForTd=12
                        :statusBar="statusbar">
                    </statusInformation>
                </div>
                <div v-else class="col-sm-3 pl-0 pt-3 badgeData" align='left'> 
                    {{dataRelatorio}}
                </div>
                <div v-if="valueGrafico.length > 0"
                    class="col-sm-12 row pl-2 conteudoDados">
                    <div class="col-sm-12 row pt-3">
                        <div class="col-sm-6 nopadding grafico" align='center'>
                            <!-- <div class="totalPontos" align='right'>
                                2058 PONTOS
                            </div> -->
                            <div>
                                <chartsPizza 
                                    id='teste1'
                                    :label="label"
                                    :value='valueGrafico'
                                    :widthGrafico='559'
                                    :heightLegenda='100'
                                    :widthLegenda='250'
                                    :offsetYLegenda='100'
                                    :textTitle="pontuacaoMotoristas.totalPontos+' PONTOS'"
                                    alignTitle='right'
                                    :offsetXTitle='-90'
                                    :offsetYTitle='60'
                                    :offsetXTitleResponsive='-50'
                                    :textSubtitle="totalMotoristas+' COLABORADORES'"
                                    :offsetXSubtitle='-80'
                                    :offsetYSubtitle='80'
                                    :offsetXSubtitleResponsive='-200'
                                    @changeImagem='changeImagem'
                                />
                                <!-- {{valueGrafico}} -->
                            </div>
                        </div>
                        <div class="col-sm-6 nopadding">
                            <!-- Colaborador com menor pontuação -->
                            <div class="col-sm-12 pb-4 tabelas">
                                <tableSimples class="tableVerde">
                                    <thead>
                                        <tr>
                                            <th colspan="2">
                                                Colaborador com MENOR pontuação
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td :class="`classe-${menorPontos.classe}`">
                                                {{menorPontos.nome}}
                                            </td>
                                            <td :class="`classe-${menorPontos.classe}`">
                                                {{menorPontos.pontos}} Pontos
                                            </td>
                                        </tr>
                                    </tbody>
                                </tableSimples>
                            </div>
                            <!-- Colaborador com Maior pontuação -->
                            <div class="col-sm-12 pt-4 tabelas">
                                <tableSimples class="tableVermelha">
                                    <thead>
                                        <tr>
                                            <th colspan="2">
                                                Colaborador com MAIOR pontuação
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td :class="`classe-${maiorPontos.classe}`">
                                                {{maiorPontos.nome}}
                                            </td>
                                            <td :class="`classe-${maiorPontos.classe}`">
                                                {{maiorPontos.pontos}} Pontos
                                            </td>
                                        </tr>
                                    </tbody>
                                </tableSimples>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 row nopadding">
                        <div class="col-sm-12 nopadding listagem">
                            <tableSimples>
                                <br>
                                <thead>
                                    <tr>
                                        <th>Colaborador</th>
                                        <th>Pontuação</th>
                                        <th>Visualizar detalhes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(dados, d) in pontuacaoMotoristasShow"
                                        :key="d"
                                        v-show="d != 'totalPontos'">
                                        <td>{{dados.nome}}</td>
                                        <td>
                                            <span>{{dados.pontos}} pontos</span>
                                            <span 
                                                class="text-center"
                                                :class="`classe-${dados.classe}`"
                                            >
                                                Classe {{dados.classe}}
                                            </span>
                                        </td>
                                        <td 
                                            @click='visualizarDetalhes(dados.codigo)'
                                            class="pl-5 cursor-pointer"
                                        >
                                            <baseIcon 
                                                :icon='mdiRedo'
                                                size='20'
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </tableSimples>
                        </div>
                    </div>
                </div>
            </div>
    </panelEagle>
</template>
<script>
// import Vue from 'vue'
// import { nextTick } from 'process';
// import { type } from 'os';
import {
    mdiThumbUpOutline, 
    mdiChartPie, 
    mdiRedo 
} from '@mdi/js'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { conectionError } from '@/Services/Helpers/swellHeper'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
    export default {
        name:'analiseGeral',
        components:{
            titulo: require('@/components/Atom/Header/Titulo').default,
            baseIcon: require('@/components/Atom/Icon/BaseIcon').default,
            selectAll: require('@/components/Atom/Select/SelectAll').default,
            panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
            tableSimples: require('@/components/Atom/Table/TableSimples').default,
            chartsPizza: require('@/components/Atom/Grafico/ChartsPizza').default,
            inputRangeWithLimit: require('@/components/Atom/Datas/InputRangeWithLimit').default,
            statusInformation: require('@/components/Atom/StatusInformation/StatusInformation').default,
            basicButtonsRelatoriosEIG: require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
        },

        validations:{
            empresasSelecionadas:{ 
                required
            },
        },

        data() {
            return {
                blabla: '28 Colaboradores',
                optSelectEmpresas: new EmpresasService().Get(),
                loaidngPanel: false,
                mdiChartPie: mdiChartPie,
                mdiThumbUpOutline : mdiThumbUpOutline,
                mdiRedo:mdiRedo,
                link:'/gestao/desempenho/relatorios/analise/geral/',
                loadingExportar      : [false, false, false],
                statusbar            : 'info',
                label                : [],
                empresasSelecionadas : [],
                dataSelecionada      : [],
                valueGrafico         : [],
                pontuacaoMotoristas  : [],
                labelGrafico         : [],
                menorPontos          : [],
                maiorPontos          : [],
                imagemPDF            : '',
                dataRelatorio        : '',
                totalMotoristas      : '',
                selectedEmpresa      : [],
            }
        },

        methods:{
            ...mapGetters(['getMaster']),
            
            changeEmpresa(empresa){
                this.empresasSelecionadas = empresa
                this.valueGrafico = []
            },

            changeData(data){
                this.dataSelecionada = data
            },

            changeImagem(imagem){
                this.imagemPDF = imagem
            },

            /**
             * @listen gerarRelatorio    - executado quando clicado em gerar relatório
             * @description Método para buscar os dados das tabelas de pontuação e do gráfico
             *      (valueGrafico        - dados para gerar o gráfico)
             *      (pontuacaoMotoristas - dados para gerar as tabelas de pontuação)
             *      (dataRelatorio       - data para inserir no badge)
             *      (totalMotoristas     - dados de total de colaboradores para mostrar no gráfico)
             * @author Vitor Hugo 🐨
            */
            gerarRelatorio(){
                this.loaidngPanel = true
                this.pontuacaoMotoristas = []
                this.valueGrafico = []
                new HttpRequest().Post(this.link+'gerar/analise', {
                            'data'    : this.dataSelecionada,
                            'cliente' : this.empresasSelecionadas
                    }).then(dados => {
                        this.valueGrafico = dados.data.grafico
                        this.pontuacaoMotoristas = dados.data.pontuacao
                        this.dataRelatorio = dados.data.data
                        this.labelGrafico = dados.data.labelGrafico
                        this.totalMotoristas = dados.data.totalMotoristas
                        this.maiorPontos = []
                        this.menorPontos = []
                        this.maiorPontos.pontos = -1
                        this.menorPontos.pontos = 100
                        this.criaLabelGrafico()
                        for (const key in this.pontuacaoMotoristas) {
                            if(key != 'totalPontos'){
                                this.verificaMaiorMenorPontuacao(key)
                            }
                        }
                        
                    }).catch(() => {
                        conectionError()
                    }).finally(() => {
                        this.loaidngPanel = false
                    })
            },

            /**
             * @description Método para criar a label do gráfico,
             *  a variável label recebe os valores correspondentes de cada classe
             *  e passa para o gráfico
             * @author Vitor Hugo 🐨 
            */
            criaLabelGrafico(){
                this.label = [
                            'Classe A - '+this.labelGrafico[0]+' colaboradores', 
                            'Classe B - '+this.labelGrafico[1]+' colaboradores', 
                            'Classe C - '+this.labelGrafico[2]+' colaboradores', 
                            'Classe D - '+this.labelGrafico[3]+' colaboradores']
            },

            /**
             * @description Método para verificar qual colaborador tem a 
             * maior pontuação e qual tem a menor pontuação
             * @param  {number} key - posição da array pontuacaoMotoristas 
             * (qual é o colaborador que esta verificando)
             * @author Vitor Hugo 🐨
            */
            verificaMaiorMenorPontuacao(key){
                if(this.pontuacaoMotoristas[key].pontos < this.menorPontos.pontos){
                    this.menorPontos = this.pontuacaoMotoristas[key]
                }
                if(this.pontuacaoMotoristas[key].pontos > this.maiorPontos.pontos){
                    this.maiorPontos = this.pontuacaoMotoristas[key]
                }
            },

            /**
             * @description Método para ir para página Analise Colaboradores 
             *      e enviar os dados necessários para aplicar os filtros do relatório
             * @param  {number} colaborador - código do colaborador
             * @author Vitor Hugo 🐨
            */
            visualizarDetalhes(colaborador){
                this.$router.push({name:'analiseColaboradores', params:{'data':this.dataRelatorio, 
                                                                        'empresa':this.empresasSelecionadas, 
                                                                        'colaborador':colaborador}})
            },

            exportar(type){
                var root =  process.env.VUE_APP_ROOT;
                this.setLoading(type)
                new HttpRequest().Post(this.link+'exportar', 
                    {'tabela'         : this.pontuacaoMotoristas,
                     'maiorPontuacao' : this.maiorPontos,
                     'menorPontuacao' : this.menorPontos,
                     'valueGrafico'   : this.imagemPDF,
                     'labelGrafico'   : this.label,
                     'intervalo'      : this.dataSelecionada,  
                     'data'           : this.dataRelatorio,
                     'tipo'           : type,})
                .then(dados=>{
                    window.open(root+'/'+dados.data);
                    this.setLoading()
                })
            },

            setLoading(tipo){
                let arr = [false, false, false]
                if(tipo=='pdf'){
                    arr[0] = true;
                }else if(tipo=='xls'){
                    arr[1] = true;
                }else if(tipo=='csv'){
                    arr[2] = true;
                }
                this.loadingExportar =  arr;
            },
            
        },

        computed:{
            pontuacaoMotoristasShow(){
                return _.orderBy(this.pontuacaoMotoristas, ['pontos'])
            }
        },
        
        mounted() {
            if(!this.getMaster()){
                this.selectedEmpresa = this.optSelectEmpresas
            }
        }
    };
</script>
<style lang="scss">
    #analiseGeral{
        .classe-A{
            background: #a9f38c !important;
        }
        .classe-B{
            background: #79b0ee !important;
        }
        .classe-C{
            background: #f8e37a !important;
        }
        .classe-D{
            background: #f39999 !important;
        }

        .badgeData{
            background: #777777;
            height: 43px;
            text-align: center;
            font-weight: bold;
            font-size: 14px;
            color: #fff;
        }
        .conteudoDados{
            border: 1px solid #ebebeb;
            .grafico{
                .totalPontos{
                    display: table-cell;
                    text-align: center;
                    background: #ebebeb;
                    width: 67%;
                    height: 50px;
                    vertical-align: middle;
                    font-weight: bold;
                    font-size: 18px;
                }
            }
            // .tableVerde{
            //     .table{
            //         background: #bae9a8 !important;
            //         td{
            //             background: #bae9a8;
            //         }
            //     }
            // }
            // .tableVermelha{
            //     .table{
            //         background: #f0abab !important;
            //         td{
            //             background: #f0abab;
            //         }
            //     }
            // }
            .tabelas{
                height: 50%;
                .tableFixHead{
                    height: 100%;
                    .table{
                        height: 100%;
                        thead{
                            max-height: 10%;
                            th{
                                font-size: 18px;
                                text-align: center !important;
                                height: 40px;
                            }
                        }
                        tbody{
                            width: 100%;
                            td{
                                text-align: center;
                                border: none;
                                font-size: 18px;
                                padding-top: 20px;
                                vertical-align: middle;
                            }
                        }
                    }
                }
            }
            .listagem{
                height: 100%;
                .tableFixHead{
                    height: 100%;
                    .table{
                        height: 100%;
                        thead{
                            th{
                                height: 35px;
                            }
                        }
                        tbody{
                            height: 100px !important;
                            overflow: scroll;
                            td{
                                border: bottom;
                                background: #fff;
                                padding-left: 5px;
                                vertical-align: middle;
                                span{
                                    padding-top: 2px;
                                    display: inline-block;
                                    height: 20px !important;
                                    width: 30%;
                                    text-align: left;
                                    margin: 3px;
                                    &.text-center{
                                        text-align: center;
                                    }
                                    // &.A{
                                    //     background: #a9f38c;
                                    //     text-align: center;
                                    // }
                                    // &.B{
                                    //     background: #79b0ee;
                                    //     text-align: center;
                                    // }
                                    // &.C{
                                    //     background: #f8e37a;
                                    //     text-align: center;
                                    // }
                                    // &.D{
                                    //     background: #f39999;
                                    //     text-align: center;
                                    // }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
