<template>
    <panelEagle id='RegistrarAbastecimento' :loading='gerandoRel'>
        <div class="col-sm-12 nopadding row">
            <div class="col-sm-6">
                <tituloPage :icon='mdiCogSync' titulo='Manutenção'/>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 divDontPrint">
                <basicButtonsCadastrosEIN
                    tipoNovo='button'
                    linkNew ='cadastrarManutencao'
                    :loading="carregandoE"
                    :disabledDropdown='dadosTabela.length == 0'
                    @exportarCadastro='exportarCadastro'/>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class="col-sm-12 nopadding row">
            <div class="col-lg-6 col-xl-4 nopadding divDontPrint">
                <selectAll
                    nameForRadio  ='Empresas*'
                    :isMultiple   ='true'
                    :optionsArray ='optEmpresas'
                    :labels       ='labelsEmp'
                    :hasSelectAll ='true'
                    :selected="selectedEmpresa"
                    @changeSelect ='changeEmpresa'
                />
            </div>
            <div class="col-lg-6 col-xl-4 nopadding divDontPrint">
                <selectAll
                    ref="refVeiculos"
                    nameForRadio='veiculo'
                    :isMultiple='true'
                    :hasSelectAll='true'
                    :labels='labelVei'
					:disabled="$v.valueEmpresa.$invalid"
                    :optionsArray='optVeiculos'
                    firstSelected="V"
                    @changeCheck='changeCheck'
                    @changeSelect='changeVeiculo'
                />
            </div>
            <div class="col-lg-12 col-xl-4 row nopadding">
                <div class="col-lg-6 col-sm-12 nopadding divDontPrint">
                    <selectAll
					nameForRadio='tipoManu'
					:isMultiple='false'
					:labels='labelTip'
					:optionsArray='optTipoManu'
					:selected="selectedTipo"
					@changeSelect='changeTipoManu'
                    />
                </div>
                <div class="col-lg-6 col-sm-12 nopadding divDontPrint">
                    <inputSimple
                    name='descricao'
                    label='Descrição'
                    @changeInput='changeDesc'
                    />
                </div>
                <div class="col-lg-6 col-sm-12 margtop nopadding divDontPrint">
                    <inputSimple
                        name='numeroos'
                        label='Número OS'
                        @changeInput='changeNos'
                        :hasMask='true'
                        :mask="['NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN']"
                    />
                </div>
                 <div class="col-lg-6 col-sm-12 margtop nopadding divDontPrint">
                    <inputSimple
                        :hasMask="true"
                        :mask="['NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN']"
                        name='intervalo'
                        label='Intervalo'
                        @changeInput='changeIntervalo'
                    />
                </div>
            </div>
            <div class="col-sm-12 col-md-12"><hr></div>
            <div class="col-sm-12 nopadding"> 
                <tableListagem
				ref='refTabela'
				:data='dadosTabela'
				deleteEdit='detaisCopyEditDelete'
				:titles='tituloTab'
				@editarCadastro='editarCadastro'
				@excluirCadastro='excluirCadastro'
				@copiarCadastro='copiarCadastro'/>
            </div>
        </div>
    </panelEagle>
</template>
<script>
import {mdiCogSync } from '@mdi/js'
import Vue  from 'vue'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service.ts'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default Vue.extend({
	name:'listagemManutencao',
	components:{
		'tituloPage'    : require('@/components/Atom/Header/Titulo').default,
		'panelEagle'    : require('@/components/Atom/Panel/PanelEagle').default,
		'selectAll'     : require('@/components/Atom/Select/SelectAll').default,
		'inputSimple'   : require('@/components/Atom/Inputs/InputSimple').default,
		'tableListagem' : require('@/components/Atom/Table/TableListagem').default,
		'basicButtonsCadastrosEIN' : require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
	},
	data(){
		return {
			mdiCogSync:mdiCogSync,
			optEmpresas : new EmpresasService().Get(),
			optVeiculos : [],
			optTipoManu   : [],
			maskNume      :['NNNNNNNNNNNNNNNNNNNN'],
			valueEmpresa: [],
			valueDesc     : ' ',
			valueIntervalo: '',
			valueNos      : '',
			valueCheckVei : '',
			valueVeiculo  : [],
			valueTipoManu : ['0'],
			selectedTipo  :[{value: '0', description: 'Todos'}],
			labelsEmp   :[{
				indexDFH    : 'EM',
				description : 'Empresas'
			}],
			labelVei: [
				{indexDFH: 'V', description: 'Veiculos'},
				{indexDFH: 'GV', description: 'Grupos'}
			],
			labelTip: [{value: 'T', description: 'Tipo de manutenção'}],
			tituloTab: [
				{'number':'placa',     'name':'Placa'},
				{'number':'tipoManu',  'name':'Tipo'},
				{'number':'intervalo', 'name':'Intervalo'},
				{'number':'tipoApura', 'name':'Apuração'},
				{'number':'descricao', 'name':'Descrição'},
				{'number':'nos',       'name':'N° OS'},
				{'number':'empresa',   'name':'Empresa'},
			],
			dadosTabela:[],
			gerandoRel: false,
			carregandoE:[false,false,false],
			selectedEmpresa:[],
		}
	},
	validations:{
		valueEmpresa : { required },
	},
	methods:{
		...mapGetters(['getMaster']),
		editarCadastro(value){
			this.$router.push({name:'cadastrarManutencao', params:{id:value, cp:false}})
		},
		copiarCadastro(value){
			this.$router.push({name:'cadastrarManutencao', params:{id:value, cp:true}})
		},
		async excluirCadastro(value){
			this.gerandoRel = true
			var deletar = await new HttpRequest()
				.Post('/manutencoes/cadastros/manutencao/deletar', {'value':value})
			var obj = {
				'tipo'      : this.valueCheckVei,
				'cliente'   : this.valueEmpresa,
				'veiculo'   : this.valueVeiculo,
				'tipoManu'  : this.valueTipoManu,
				'valueDesc' : this.valueDesc,
				'nos'       : this.valueNos,
				'intervalo' : this.valueIntervalo
			}
			var dados = await new HttpRequest()
				.Post('/manutencoes/cadastros/manutencao/tabela', {'obj':obj})
			this.dadosTabela = dados.data.retorno
			this.optVeiculos = dados.data.veiculo
			this.gerandoRel = false
		},
		async changeEmpresa(value){
			this.valueEmpresa = value
			this.$refs.refVeiculos.clearAll()
			this.optVeiculos = []
			this.consulta()
		},
		consulta(){
			this.dadosTabela = []
			if(this.valueEmpresa.length > 0){
				var obj = {
					'tipo'      : this.valueCheckVei,
					'cliente'   : this.valueEmpresa,
					'veiculo'   : this.valueVeiculo,
					'tipoManu'  : this.valueTipoManu,
					'valueDesc' : this.valueDesc,
					'nos'       : this.valueNos,
					'intervalo' : this.valueIntervalo
				}
				this.gerandoRel = true
				new HttpRequest().Post(
					'/manutencoes/cadastros/manutencao/tabela',
					{'obj':obj})
					.then((dados=>{
						if(dados.data == null){
							this.dadosTabela = []
							// this.optVeiculos = []
							this.gerandoRel = false
						}else{
							this.dadosTabela = dados.data.retorno
							this.optVeiculos = dados.data.veiculo
							this.gerandoRel = false
						}
					}))
			}
		},
		changeVeiculo(value){
			this.valueVeiculo = value
			this.consulta()
		},
		changeCheck(value){
			this.valueCheckVei =  value
			this.optVeiculos = []
			this.consulta()
		},
		changeTipoManu(value){
			this.valueTipoManu = value
			this.consulta()
		},
		changeDesc(value){
			this.valueDesc = value
			this.consulta()
		},
		changeNos(value){
			this.valueNos = value
			this.consulta()
		},
		changeIntervalo(value){
			this.valueIntervalo = value
			this.consulta()
		},
		async exportarCadastro(value){
			this.setCarregando(value)
			var obj = {
				'tipoExporta' : value,
				'dados'       : this.dadosTabela,
			}
			var exportaDados = await new HttpRequest()
				.Post('/manutencoes/cadastros/manutencao/exportacao', obj)
			var root =  process.env.VUE_APP_ROOT;
			window.open(root+'/'+exportaDados.data.pdf)
			this.setCarregando()
		},

		setCarregando(type=null){
			if(type === 'pdf'){
				this.carregandoE = [true, false, false]
			}else if(type === 'xls'){
				this.carregandoE = [false, true, false]
			}else if(type === 'csv'){
				this.carregandoE = [false, false, true]
			}else{
				this.carregandoE = [false, false, false]
			}
		},
	},

	async mounted(){
		if(!this.getMaster()){
			this.selectedEmpresa = this.optEmpresas
		}
		var dados = await new HttpRequest()
			.Post('/manutencoes/cadastros/manutencao/tipo')
		this.optTipoManu = dados.data
		this.optTipoManu.push({value: '0', description: 'Todos'})
	},
})
</script>
<style lang="scss">
    #RegistrarAbastecimento{
        .margtop{
            margin-top: 5px;
        }
        .bt-orange{
            display: none !important;
        }
    }
</style>
