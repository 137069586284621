<template>
    <panelEagle id="cadastroRotas" :loading="loadingPanel">
        <div class="col-sm-12 nopadding divDontPrint">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiMapMarkerDistance" titulo="Rotas"></tituloPage>
                </div>
                <div class="col-sm-6">
				<basicButtonsCadastrosSC
					@cancelarCadastro="cancelarCadastro"
					:disabled='$v.$invalid'
					@salvarCadastro="salvarCadastro"
					tipoCancela="button"/>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-3 nopadding">
                    <selectAll 
                        @changeSelect="changeEmpresa"
                        :isMultiple="false" 
                        :hasSelectAll="false" 
                        nameForRadio="empresas" 
                        :labels="[{indexDFH: 'EM', description: 'Empresa*'}]"
                        :selected="isSelectedEmpresas"
                        :optionsArray="optionsArrayEmpresas" 
                        firstSelected="EM"/>
                </div>
                <div class="col-sm-3 nopadding">
                    <inputSimple
                        @changeInput="changeDescricao"
                        :value="valueDescricao"
                        label="Descrição*"/>
                </div>
                <div class="col-sm-3 nopadding">
                    <selectAll
                        :disabled="this.optionsArrayPontos.length == 0"
                        ref="refPontoOrigem"
                        @changeSelect="changePontoOrigem"
                        :loading="loadingPontos"
                        :isMultiple="false" 
                        :hasSelectAll="false" 
                        nameForRadio="empresas" 
                        :selected="isSelectedPontoOrigem"
                        :labels="[{indexDFH: 'OR', description: 'Origem*'}]" 
                        :optionsArray="optionsPontosOrigem" 
                        firstSelected="OR"/>
                </div>
                <div class="col-sm-3 nopadding">
                    <selectAll
                        :disabled="this.optionsArrayPontos.length == 0"
                        ref="refPontoDestino"
						@changeSelect="changePontoDestino"
                        :loading="loadingPontos"
                        :isMultiple="true" 
                        :hasSelectAll="false" 
                        nameForRadio="empresas" 
                        :selected="isSelectedPontoDestino"
                        :labels="[{indexDFH: 'DES', description: 'Destino(s)*'}]" 
                        :optionsArray="optionsPontosDestino" 
                        firstSelected="DES"/>
                </div>
                <div class="col-sm-3 nopadding mt-2">
                    <inputSimple
                        :value="valueTempParado"
                        @changeInput="changeTempoParado"
                        :hasMask="true"
                        :mask="['NNNN']"
                        label="Tempo médio de parada (Minutos)"/>
                </div>
                <div class="col-sm-3 nopadding mt-2">
                    <inputSimple
                        :value="valueVelMed"
                        @changeInput="changeInputVelocidade"
                        :hasMask="true"
                        :mask="['NNN']"
                        label="Velocidade média (Km/H)"/>
                </div>
                <div class="col-sm-3 nopadding row mt-2">
                    <div class="col-sm-6 nopadding">
                        <inputSimple
                            :value="valueDistanciaCalc"
                            :disabled="true"
                            label="Distância (Km)"/>
                    </div>
                    <div class="col-sm-6 nopadding">
                        <inputSimple
                            :value="valueTempo"
                            :disabled="true"
                            label="Tempo (Hrs)"/>
                    </div>
                </div>
                <div class="col-sm-3 nopadding mt-2">
                    <inputMoney
                        @changeInput="changePrecoMedio"
                        name="inputMoney"
                        label="Preço médio por eixo"
						:value="valuePrecoMedio"/>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 mapaStyle">
				<div class="listagem">
					<div class="div-dica">
						Obs.: Pontos de desvio não serão registrados
					</div>
					<div class="div-itens">
						<ul v-if="pontos.length"
							class="list-group">
							<li class="list-group-item"
								v-for="(value, index) in pontos"
								:key="index+'-item'"
								v-b-tooltip.hover.html="getTitleItemList(value)">
								<div class="div-item"
									:class="{
										'pontoDesvio': value.nome == 'D',
										'pontoInicio': value.nome == 'I',
									}">
									<div class="div-index">{{value.nome}}</div>
									<div class="div-context">{{value.description}}</div>
								</div>
							</li>
						</ul>
						<ul v-else
							class="list-group">
							<li class="list-group-item">
								<div class="div-item">
									<h4 class="div-context mt-2">Nenhum ponto selecionado</h4>
								</div>
							</li>
						</ul>
					</div>
				</div>
                <mapaSimples height="600" 
					:style="{'width': '100%'}"
					@contextmenu="pegaLatLon" 
					ref="mapa">
                    <div class="div-mapa">
                        <div class="div-btn">
                            <simpleButton
                                @click="clickLimparMapa"
                                event="click"
                                width="180px"
                                :icon="mdiCloseThick"
                                type="red"
                                text="Limpar mapa"
                            />
							<div :style="{'margin-top':'32px','position':'absolute'}">
								<simpleButton
									@click="calculaRota()"
									event="click"
									width="180px"
									:icon="mdiCheckBold"
									type="blue"
									text="Gerar rota"
								/>
							</div>
                        </div>
                        <!-- rota -->
                        <polylineLines 
                            ref='poli'
                            :latLng="latLng"/>
                        <!-- matriz -->
                        <markerPoint 
                            :latitude="latitudeMatriz"
                            :longitude="longitudeMatriz"
                            icon="img/matriz.png"/>
                        <!-- destinos -->
						<markerPoint 
							v-for="(value, index) in selecionados"
							:key="index+'_mkr'"
							:typeIcon="'divIcon'"
							:icon="getHtmlIcon(value)"
							:iconSize="[12,12]"
							:latitude="value.lat"
							:longitude="value.lng"
							@clickMarker="clickMarker(index, value)"
						/>
                        <!-- <markerPoint
                            @clickMarker="clickMarker"
                            v-for="(marker, index) in markers"
                            :key="index"
                            :latitude="marker.lat"
                            :longitude="marker.lng"/> -->
                    </div>
                </mapaSimples>
            </div>
        </div>
		<!-- Modal Posicao Ponto -->
		<!-- <template>
			<ModalPosicaoPonto 
				idModal ="modalPosicao"
				@changeSelect="pegaLatLon"
				:pontos="pontosModal"
				ref ="modalPosicao"/>
		</template> -->
    </panelEagle>
</template>

<script lang="js">
var polyline = require('@mapbox/polyline');
import { mdiMapMarkerDistance, mdiCloseThick, mdiCheckBold } from '@mdi/js'
import Vue from 'vue'
import {EmpresasService} from '../../../Services/auth/Empresas.service'
import {HttpRequest} from '../../../Services/auth/HttpRequest.Service'
import { required } from 'vuelidate/lib/validators'
import Swal from 'sweetalert2'
import { Duration } from 'luxon'
import { conectionError } from '@/Services/Helpers/swellHeper';
export default Vue.extend({
	name:'CadastroRotas',
	components:{
		tituloPage: require('@/components/Atom/Header/Titulo').default,
		selectAll: require('@/components/Atom/Select/SelectAll').default,
		panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
		mapaSimples: require('@/components/Atom/Mapa/MapaSimples').default,
		markerPoint: require('@/components/Atom/Mapa/MarkerPoint').default,
		inputMoney: require('@/components/Atom/Inputs/InputMoney').default,
		polylineLines: require('@/components/Atom/Mapa/PolylineAntPath').default, // com os tchanhanham andando
		// 'polylineLines': require('@/components/Atom/Mapa/PolylineDecorator').default,// setinha
		// 'polylineLines': require('@/components/Atom/Mapa/PolylineLines').default, // normal
		inputSimple: require('@/components/Atom/Inputs/InputSimple').default,
		simpleButton: require('@/components/Atom/Buttons/SimpleButton').default,
		basicButtonsCadastrosSC: require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
		// ModalPosicaoPonto: require('@/components/Atom/SpecificComponents/CadastroRotas/ModalPosicaoPonto').default,
	},

	validations:{
		valueDescricao:{ required },
		valuePontoOrigem:{ required },
		valuePontoDestino:{ required },
	},
    
	data(){
		return{
			mdiCloseThick:mdiCloseThick,
			mdiMapMarkerDistance:mdiMapMarkerDistance,
            mdiCheckBold:mdiCheckBold,

			idRota:'',
			valueTempo:'',
			valueVelMed:'',
			valueDescricao:'',
			valueTempParado:'',
			valueTempoOsmir:'',
			valueDistancia:'',
			valuePrecoMedio:'',
            
			tempoEmMinutos:0,
			latitudeMatriz: 0,
			longitudeMatriz: 0,
			valueDistanciaCalc: '',

			editar:true,
			loadingPanel:false,
			loadingPontos:false,
			bloqueioEdicao:false,
            
			latLng:[],
			markers:[],
			dataTable:[],
			valueEmpresa:[],
			valuePontoOrigem:[],
			valuePontoDestino:[],
			isSelectedEmpresas:[],
			optionsArrayPontos:[],
			isSelectedPontoOrigem:[],
			isSelectedPontoDestino:[],
			selecionados:[],

			optionsArrayEmpresas: new EmpresasService().Get(),
		}
	},
	methods: {
		cancelarCadastro() {
			let nome = this.retornaNomeTela
			this.$router.push({name:nome})
		},
		async changeEmpresa(value){
			this.valueEmpresa = value
			this.optionsArrayPontos = []
			this.clickLimparMapa()
			if(value.length){
				this.loadingPontos = true
				var dados = await new HttpRequest()
					.Post('/eagle/cargo/cadastros/rotas/pontos', { empresa:value })
				if(dados.status) {
					var arr = []
					dados.data.pontos.forEach((e) => {
						arr[e.value] = e
					})
					this.optionsArrayPontos = arr
				}
			}
			this.loadingPontos = false
		},
        
		pegaLatLon(value){
			if(this.latLng.length > 0){
				var last = this.selecionados[this.selecionados.length - 1]
				var novo = {
					value:last.value,
					nome:'D',
					description:'Ponto de desvio',
					lat:value.latlng.lat+'',
					lng:value.latlng.lng+'',
					ordem:this.selecionados.length,
				}
				this.selecionados.splice(last.ordem, 0, novo)
				this.reordenaSelecionados()
				this.markers.push(novo)
			}
			this.calculaRota()
		},

		reordenaSelecionados() {
			var ordem = 0
			this.selecionados.forEach((e, i) => {
				e.ordem = i
				if(this.isOrderType(e.nome)) {
					ordem++
					e.nome = ordem
					e.ordem = ordem
				}
			})
			this.selecionados.sort(function(p1, p2) {
				return p1.ordem - p2.ordem;
			})
		},

		changePrecoMedio(value){
			this.valuePrecoMedio = value
		},
        
		changeDescricao(value){
			this.valueDescricao = value
		},

		changeInputVelocidade(value){
			this.valueVelMed = value
			if(this.valueDistancia){
				this.calculaTempo()
			}
		},

		clickMarker(index, value){
			if(value.nome === 'D') {
				this.markers = this.markers.filter(e => e.ordem != value.ordem)
				this.selecionados.splice(index, 1)
				this.reordenaSelecionados()
				this.calculaRota()
			}
		},

		async salvarCadastro(){
			if(!this.selecionados.length || !this.latLng || !this.latLng.length){
				let icon='warning'
				let title = 'Confira se há pontos de origem e destinos '+
					'selecionados, e se foi gerada a rota no mapa'
				var msg = ''
				conectionError(msg, icon, title)
				return
			}
			this.loadingPanel = true
			var origem = this.valuePontoOrigem[0]
			var metros = String(this.valueDistancia).split('.')[0]+String(this.valueDistancia).split('.')[1]
			var itens = this.selecionados
				.filter(e => this.isOrderType(e.nome) && e.value != origem)
			// itens.forEach((e, i) => {
			// 	e.ordem = i + 1
			// })
			var obj = {
				rpcodigo:this.idRota,
				rptempo:parseInt(this.valueTempoSalvar),
				rpcliente:this.valueEmpresa[0],
				rporigem:origem,
				rpdistancia:parseInt(metros),
				rpdescricao:this.valueDescricao,
				rpprecomedioeixo:this.valuePrecoMedio,
				rppolyline:polyline.encode(this.latLng),
				itens:itens
			}
			var dados = await new HttpRequest()
				.Post('/eagle/cargo/cadastros/rotas/salvar', {'dados':obj})
			this.loadingPanel = false
			if(!dados.data.retorno.ok){
				conectionError()
			} else {
				this.cancelarCadastro()
				// this.$router.push({name:'listagemDeRotas'})
			}
            
		},

		clickLimparMapa(){
			this.$refs.refPontoOrigem.clearAll()
			this.$refs.refPontoDestino.clearAll()
			this.markers = []
			this.latLng = []
			this.valuePontoOrigem  = []
			this.valuePontoDestino = []
			this.valueTempo = ''
			this.valueVelMed = ''
			this.valueDistancia = ''
			this.valueTempParado = ''
			this.valueTempoOsmir = ''
			this.valueDistanciaCalc = ''
			this.$refs.poli.setVisible(false)
		},

		calculaTempo(){
			this.valueTempo = `00:00:00`
			if(this.valueDistancia > 0){
				var tempo =  this.valueTempoOsmir
				var mts = this.valueVelMed/3.6
				if(mts > 0) tempo = this.valueDistancia/mts
				var min = this.valueTempParado
				var dur = Duration.fromObject({seconds:tempo})
				if(min != ''){
					var minutosParados = Duration.fromObject({minutes:min})
					dur = Duration.fromObject({seconds:dur.as('second')+minutosParados.as('second')})
					dur.toObject()
				}
				this.valueTempoSalvar = dur.as('second')
				var tempFotmatado = dur.shiftTo(`hours`, 'minutes', 'seconds').toObject()
				var hora = duas_casas(tempFotmatado.hours)
				var minuto = duas_casas(tempFotmatado.minutes)
				var segundo = duas_casas(String(tempFotmatado.seconds).split('.')[0])
				this.valueTempo = `${hora}:${minuto}:${segundo}`
			}
			function duas_casas(numero){
				if (numero <= 9){
					numero = '0'+numero;
				}
				return numero;
			}
		},

		async changeTempoParado(value){
			this.valueTempParado = value
			if(this.valueTempo){
				await this.calculaTempo(value)
			}
		}, 

		changePontoOrigem(value){
			this.valuePontoOrigem = value
			if(value && value.length) {
				this.selecionados = this.valuePontoOrigem
					.concat(this.valuePontoDestino)
					.filter(e => this.isOrderType(e.nome))
					.map((e) => Object.assign({}, this.optionsArrayPontos[e]))
				this.selecionados.forEach((e, i) => {
					if(i === 0) e.nome = 'I'
				})
				this.trocaPontos(value, 'origem')
				this.reordenaSelecionados()
			} else {
				this.selecionados.splice(0, 1)
			}
		},
        
		changePontoDestino(value){
			this.valuePontoDestino = value
			this.selecionados = this.valuePontoOrigem
				.concat(this.valuePontoDestino)
				.filter(e => this.isOrderType(e.nome))
				.map((e) => Object.assign({}, this.optionsArrayPontos[e]))
			if(this.valuePontoOrigem && this.valuePontoOrigem.length) {
				this.selecionados.forEach((e, i) => {
					if(i === 0) e.nome = 'I'
				})
			}
			this.trocaPontos(this.selecionados, 'destino')
			this.reordenaSelecionados()
		},
        
		trocaPontos(value, qual){
			if(this.bloqueioEdicao) return
			this.valueVelMed = ''
			this.valueTempParado = ''
			if(value[0]){
				this.marker = []
				this.latLng = []
				this.valueTempo = ''
				this.valueDistanciaCalc = ''
				this.$refs.poli.setVisible(false)
			}
		},

		/**
         * faz uma consulta para ver se ja existe uma rota com o ponto de inicio e fim iguais
         * caso exista abre o swall com um html das rotas repetidas
         * @listen changePontoDestino|changePontoOrigem - a troca de pontos de inicio e fim da rota
         * @author Lucas Eduardo
         */
		async verificaRotaJaSalva(){
			var dados = await new HttpRequest()
				.Post('/eagle/cargo/cadastros/rotas/verifica/rota',
					{
						origem:this.valuePontoOrigem,
						destinos:this.valuePontoDestino,
						cliente:this.valueEmpresa, 
						idROta:this.$route.params.id
					}
				)
			if(dados.data.length){
				var htmlTxt = `
                    <div class="col-sm-12 row mb-2">
                        Está rota já foi salva em nosso sistema, você tem certeza que quer salva-la novamente?
                    </div>
                    <div class="col-sm-12 text-center mb-2">
                        Rotas duplicadas
                    </div>
                `
				for(const index in dados.data){
					htmlTxt += `<a  class="text-center"
                                    href="#/eagle/cargo/cadastros/novas/rotas/${dados.data[index].rpcodigo}" 
                                    target="_blank">
                                    ${dados.data[index].rpdescricao}
                                </a>`
				}
				Swal.fire({
					title: 'Rota duplicada!',
					text: '',
					html:htmlTxt,
					icon: 'warning',
					showCancelButton: false,
					confirmButtonText: 'Sim, estou ciente!'
				})
			}
		},

		async arrumaDadosEdicao(dados){
			this.selecionados = []
			this.editar = false
			var rota = dados[0]
			this.idRota = rota.rpcodigo
			var cliente = this.optionsArrayEmpresas.filter(function(r){
				return r.value == rota.rpcliente
			})
			this.isSelectedEmpresas = cliente
			await this.changeEmpresa([rota.rpcliente])
			var pontoOrigem = this.optionsArrayPontos.filter(function(r){
				return r.value == rota.rporigem
			})
			this.isSelectedPontoOrigem = pontoOrigem
			if(rota.itens) {
				this.isSelectedPontoDestino = rota.itens
					.filter(e => e.rpiponto != pontoOrigem[0].value)
					.map(e => {
						return {value:e.rpiponto, description:e.podescricao}
					})
			}
			this.latLng = polyline.decode(rota.rppolyline)
			this.valueDescricao = rota.rpdescricao
			this.valueDistancia = rota.rpdistancia
			this.valueTempoOsmir = rota.rptempo
			this.valuePrecoMedio = rota.rpprecomedioeixo
			this.valueDistanciaCalc = this.formataDistancia(rota.rpdistancia)
			this.calculaTempo()
			this.mostraRota(this.latLng)
			this.reordenaSelecionados()
			this.loadingPanel = false
			setTimeout(() => {
				this.editar = true
				this.bloqueioEdicao = false
			}, 1000);
		},

		/**
		 * formata metros para km, deixa no padrao br
		 * e lida com os valores diferentes do OSRM e o banco
		 * @param {Number|String} value em mts
		 */
		formataDistancia(value){
			var mts = value.toFixed()
			var dist = Number(
				(''+mts).replaceAll(/[^\d]+/g, '')
			)
			return ((dist)/1000).toFixed(2)
		},

		/**
         * @description encaminha o mapa para a polyline desenhada no mapa
         * @author Vitor Hugo 🐨
         */
		mostraRota(latLong = null){
			if(!latLong)
				latLong = this.selecionados.map(e => [e.lat, e.lng])
			var poli = L.polygon(latLong)
			let option = {animate:true,duration:0.8,easeLinearity:0.8}
			this.$refs.mapa.flyToBounds(poli.getBounds(), option)
			this.$refs.mapa.validateSize()
		},

		async calculaRota(){
			this.loadingPanel = true
			if(this.valuePontoOrigem.length > 0 && this.valuePontoDestino.length > 0 && this.editar){
				var url = '/eagle/cargo/cadastros/rotas/calcula/rota'
				var pontos = this.selecionados
				var body = {
					pontos:pontos,
				}
				var dados = await new HttpRequest().Post(url,body)
				if(dados.status && !dados.data.rota.err){
					this.calculaRotaAux(dados)
				}else{
					this.markers.pop()
					conectionError()
				}
			}
			this.loadingPanel = false
		},

		calculaRotaAux(dados) {
			this.$refs.poli.setVisible(false)
			this.latLng = polyline.decode(dados.data.rota.rota)
			if(this.editar){
				this.valueTempoOsmir = dados.data.rota.tempoSegundos
			}
			this.valueDistancia = dados.data.rota.distanciaMetros
			this.valueDistanciaCalc = this.formataDistancia(dados.data.rota.distanciaMetros)
			var pontos = dados.data.rota.pontos
			for (let e of pontos) {
				if(typeof this.selecionados[e.index_origem] !== 'undefined') {
					this.selecionados[e.index_origem].ordem = e.waypoint_index
				}
			}
			this.selecionados.sort(function(p1, p2) {
				return p1.ordem - p2.ordem;
			})
			var ordem = 0
			this.selecionados.forEach((e, i) => {
				if(this.isOrderType(e.nome)) {
					ordem++
					e.nome = ordem
					e.ordem = ordem
				}
			})
			this.mostraRota(this.latLng)
			this.calculaTempo()
		},

		pedePosicaoPonto(value) {
			this.$refs.modalPosicao.openModal(value.latlng)
		},

		getHtmlIcon(value) {
			var classe = ''
			if(!this.isOrderType(value.nome)) {
				classe = 'pontoDesvio'
				if(value.nome === 'I') classe = 'pontoInicio'
			}
			return `
				<div>
					<span class='markerRotaOrdem ${classe}'>${value.nome}</span>
					<img class='markerRotaImg' src='img/markerPonto.png'>
				</div>`
		},

		isOrderType(tipo) {
			return tipo != 'D' && tipo != 'I'
		},

		getTitleItemList(item) {
			let descricao = 'Tipo: Ponto de Destino'
			if(item.nome === 'I') descricao = 'Tipo: Ponto de Início'
			if(item.nome === 'D') descricao = 'Tipo: Ponto de Desvio'
			let text = descricao + 
				'<br> Código: ' + item.value + 
				'<br> Nome: ' + item.description + 
				'<br> Latitude: ' + item.lat + 
				'<br> Longitude: ' + item.lng
			return text
		}
	},

	async mounted(){
		this.loadingPanel = true
		var req = this.$store.getters.getClient
		var id  = this.$route.params.id
		var dados = await new HttpRequest()
			.Post('/eagle/cargo/cadastros/rotas/lat/lon/cliente',
				{
					user:req,
					id:id
				}
			)
		if(dados.data.latLon.length > 0){
			this.latitudeMatriz = dados.data.latLon[0].lat
			this.longitudeMatriz = dados.data.latLon[0].lng
		}
		if(id){
			this.bloqueioEdicao = true
			await this.arrumaDadosEdicao(dados.data.dados)
		}else{
			this.loadingPanel = false
		}
	},

	computed: {
		retornaNomeTela() {
            if(this.$route.name === "CadastroRotasWay"){
                return "ListagemRotasWay"
            }
            return "listagemDeRotas"
        },

		pontos() {
			return this.selecionados
		},
		// pontosModal(){
		// 	var pontos = JSON.parse(JSON.stringify(this.selecionados))
		// 	pontos.forEach((e) => {
		// 		e.description = e.ordem + ' - ' + e.description
		// 	})
		// 	return pontos
		// },
		optionsPontosDestino() {
			if(!this.optionsArrayPontos) return []
			return this.optionsArrayPontos.filter(e => !this.valuePontoOrigem.includes(e.value))
		},
		optionsPontosOrigem() {
			if(!this.optionsArrayPontos) return []
			return this.optionsArrayPontos.filter(e => !this.valuePontoDestino.includes(e.value))
		}
	}
})
</script>
<style lang="scss">
#cadastroRotas{
	.div-mapa{
		position: relative;
		.div-btn{
			button{
				opacity:0.8;
				left: 0%;
				z-index: 800;
				position: absolute;
				&:hover{
					opacity:1;  
				}
			}
		}
	}
	.mapaStyle{
		display:inline-flex;
		.listagem{
			min-width: 300px;
			max-height: 600px;
			overflow-y: auto;
			overflow-x: hidden;
			top: 40px;
			max-width: 300px;
			margin-right: 10px;
			align-content: space-between;
			.list-group-item{
				padding: 0 !important;
				.div-item{
					display: inline-flex;
					align-content: center;
					width: 100%;
					align-items: center;
					.div-index{
						text-align: center;
						width: 50px;
						padding: 10px 10px 5px 10px;
					}
					.div-context{
						text-align: center;
						width: 100%;
						padding: 5px 10px 0px 10px;
					}
				}
			}
			.div-itens{
				margin-bottom: 5px;
			}
			.div-dica{
				padding-top: 5px;
				padding-left: 5px;
				text-align: center;
				height: 30px;
			}
		}
		.divIcon{
			.markerRotaOrdem {
				position: absolute;
				top: 3px;
				padding: 0 6px;
				border-radius: 43px;
				font-size: 12px;
				left: 9px;
				width: 22px;
				height: 21px;
				text-align: center;
				font-weight: bold;
				background-color: white;
				line-height: 20px;
			}
			.markerRotaImg{
				height: 38px;
				width: 40px;
			}
		}
		.pontoDesvio{
			background-color: lightblue !important;
		}
		.pontoInicio{
			background-color: lightgreen !important;
		}
	}
}
</style>