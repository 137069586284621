<template>
    <modalEagle @modalOpen="carregaListagem" title="Tarefas" id="modal-cadastro-tarefas">
        <slot slot="modalBody">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-3 nopadding">
                    <selectAll 
                        @changeSelect="changeEmpresaTarefa" 
                        @close='$v.empresa.$touch()'
                        @selectCriado='that=>seletorEmpresa=that'
                        :extraClass="{'border border-danger':$v.empresa.$anyError}"
                        :isMultiple="false" 
                        nameForRadio="empresaCadTarefa" 
                        :hasSelectAll="false" 
                        :labels="[{indexDFH: 'EM', description: 'Empresa*'}]" 
                        :optionsArray="optionsArrayEmpresas" 
                        firstSelected="EM"/>
                </div>
                <div class="col-sm-4 nopadding">
                    <inputSimple
                        id="descricaoTarefa" 
                        @changeInput="value => descricaoTarefa = value" 
                        @blur='$v.descricaoTarefa.$touch()'
                        :inputClass='{"border border-danger":$v.descricaoTarefa.$anyError}'
                        :hasMask="false" 
                        :value='descricaoTarefa'
                        name="descricaoTarefa"  
                        label="Descrição*"/>
                </div>
                <div class="col-sm-3 nopadding">
                    <inputSimple 
                        id="tempoEstimadoTarefa" 
                        @changeInput="value => tempoEstimadoTarefa = value" 
                        type='time'
                        :value='tempoEstimadoTarefa'
                        name="tempoEstimadoTarefa" 
                        label="Tempo estimado*"/>
                </div>
                <div class="col-sm-2 padding-align">
                    <template v-if="getCadastrar()">
                        <loadingButton 
                            :isLoading="loading" 
                            :disabled='$v.$invalid'
                            :icon="mdiCheckBold"
                            @click="saveTarefa"
                            text="Salvar"
                            width="100%"
                            event="click"
                            type="blue"
                        />  
                    </template>
                </div>
                <div class="col-sm-12 message">
                    <b-alert
                        @dismiss-count-down='dismiss'
                        :show='cdErroInsercao'
                        :variant='statusCad'>
                        {{messageStatus}}
                    </b-alert>
                </div>
                <div class="col-sm-12"></div>
                <div class="col-sm-12"><hr></div>
                <tableListagemModal 
                    @excluirCadastro="excluiTarefa" 
                    :hasSearch="false" 
                    :data="listagemTarefas" 
                    :titles="titlesTarefas"/>
            </div>
        </slot>
        <slot slot="modalFooter">
            <simpleButton
                :icon="mdiCloseThick" 
                @click="hideModal" 
                text="Cancelar" 
                event="click" 
                type="red"/>
        </slot>
    </modalEagle>
</template>

<script lang="js">
import Vue from 'vue'
import { mapGetters } from 'vuex'
import {mdiCheckBold,mdiCloseThick} from '@mdi/js'
import { required } from 'vuelidate/lib/validators'
import { EmpresasService } from '../../../Services/auth/Empresas.service'
import { HttpRequest } from '../../../Services/auth/HttpRequest.Service'
export default Vue.extend({
    components:{
        'loadingButton': require('@/components/Atom/Buttons/LoadingButton').default,
        'simpleButton': require('@/components/Atom/Buttons/SimpleButton').default,
        'modalEagle': require('@/components/Atom/Modal/ModalEagle').default,
        'inputSimple'   : require('@/components/Atom/Inputs/InputSimple').default,
        'tableListagemModal': require('@/components/Atom/Table/TableListagemModal').default,
        'selectAll': require('@/components/Atom/Select/SelectAll').default,
    },
    validations:{
        descricaoTarefa:{required},
        empresa       :{required},
        tempoEstimadoTarefa:{required},
    },
    methods:{
        ...mapGetters(['getCadastrar']),
        dismiss(value){
            this.cdErroInsercao = value
        },
        changeEmpresaTarefa(selecionada){
            this.empresa = selecionada;
            this.carregaListagem();
        },
        hideModal() {
            this.seletorEmpresa.clearAll()
            this.descricaoTarefa = ''
            this.tempoEstimadoTarefa = ''
            this.$v.$reset()
            this.$bvModal.hide('modal-cadastro-tarefas')
        },
        async carregaListagem(){
            new HttpRequest().Post('/coletivos/cadastros/tarefas/listagem', {'clientes': this.empresa}).then(data=>{
                this.listagemTarefas = data.data.tarefas
            })
        },
        saveTarefa(){
            this.loading = true
            var obj = this.criaObj()
            new HttpRequest().Post('/coletivos/cadastros/tarefas/cadastrar', obj).then(data=>{
                this.loading = false
                if(data.data.sucess){
                    this.cdErroInsercao = 10
                    this.statusCad='success'
                    this.messageStatus='Inserido com sucesso'
                    this.descricaoTarefa = ''
                    this.tempoEstimadoTarefa = ''
                    this.$v.$reset()
                    this.$forceUpdate()
                    this.carregaListagem();
                }else{
                    this.messageStatus='Não foi possível inserir a tarefa'
                    this.statusCad = 'danger'
                    this.cdErroInsercao = 10
                }
            })
        },
        criaObj(){
            var aux = {
                'cliente'       :this.empresa[0],
                'descricao'     :this.descricaoTarefa,
                'tempoEstimado' :this.tempoEstimadoTarefa
            }
            return aux
        },
        async excluiTarefa(tarefa){
            var data = await new HttpRequest().Post('/coletivos/cadastros/tarefas/excluir', {'tarefa': tarefa});
            this.carregaListagem();
            this.messageStatus = data.data.return.message;
            this.statusCad = data.data.return.class;
            this.loading = false;
            var modal = this
            setTimeout(function(){
                modal.messageStatus = '';
                modal.statusCad = '';
            }, 5000)
        },
    },
    data(){
        return{
            mdiCloseThick:mdiCloseThick,
            mdiCheckBold:mdiCheckBold,
            seletorEmpresa:Object,
            cdErroInsercao  : 0,
            optionsEmpresas : [] ,
            empresa         : [] ,
            listagemTarefas : [] ,
            titlesTarefas   : [
                {'name': 'Descricao',     'number': '0'},
                {'name': 'Empresa',       'number': '1'},
                {'name': 'Tempo estimado','number': '2'},
            ],
            optionsArrayEmpresas : new EmpresasService().Get(),
            descricaoTarefa      : '',
            tempoEstimadoTarefa  : '',
            messageStatus        : '',
            statusCad            : '',
            loading              : false,
        }
    }
})
</script>

<style lang="scss">
    #modal-cadastro-tarefas{
        .padding-align{
            padding-top: 20px;
        }

        .message{
            font-size: 12px;
            text-align: center;
            margin-top: 15px;
            &.message-succes{
                color: #95b65c;
            }

            &.message-error{
                color: #e04b4a;
            }
        }
    }
</style>