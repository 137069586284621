<template>
    <div
	style='background-color: whitesmoke'
	class='filtro-painel-coletivos d-flex justify-content-between py-1'>
		<div class='col-3 nopadding mx-2'>
			<Multiselect
			placeholder='Selecione uma empresa'
			:multiple='true'
			:close-on-select='true'
			:options='optClientes'
			:allow-empty='true'
			track-by='value'
			label='description'
			v-model='modelCliente'
			id='seletorEmpresaMapa'
			selectLabel="•" 
			selectedLabel="✔" 
			deselectLabel="x" 
			:limit='1'
			:limitText="c=>` e mais ${c}`"
			@input='mudaCliente'>
				<template #noResult>
					Nenhum resultado encontrado
				</template>
				<template #noOptions>
					Nenhuma opção disponível
				</template>
				<template #clear >
					<div
					v-if='modelCliente.length'
					class="multiselect__clear mr-4">
						<b-button
						size='sm'
						variant='danger'
						@click='limpaSeletorCliente'>
							<base-icon
							size='16'
							:icon='iconClear'/>
						</b-button>
					</div>
				</template>
			</Multiselect>
		</div>
		<div class='d-flex justify-content-between align-items-center'>
			<span class='mr-2'>Status:</span>
			<b-checkbox
			v-model='modelStatusIniciado'
			class='mx-1'>Iniciada</b-checkbox>
			<b-checkbox
			v-model='modelStatusPendente'
			class='mx-1'>Pendente</b-checkbox>
			<b-checkbox
			v-model='modelStatusFinalizado'
			class='mx-1'>Finalizada</b-checkbox>
			<b-checkbox
			v-model='modelStatusCancelado'
			class='mx-1'>Cancelada</b-checkbox>
		</div>
		<div class='divDatePicker'>
			<date-picker
			name='datepickerRotas'
			label=''
			@changeInput='mudaDataFiltro'/>
		</div>
		<div>
			<input
			type='text'
			v-model='buscaUsuario'
			placeholder='Buscar'
			class='form-control'>
		</div>
		<div class='my-auto mr-2'>
			<b-button
			squared
			class='botaoFiltro'
			variant='outline-secondary'
			@click='emiteExpandir'>
				<baseIcon
				:icon='iconResize'
				size='20'/>
			</b-button>
		</div>
    </div>
</template>

<script>
import datePicker from '@/components/Atom/Datas/InputSingleDay.vue'
import Multiselect from 'vue-multiselect';
import { mdiResize,
	mdiMoveResizeVariant, 
	mdiCloseThick} from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { mapGetters } from "vuex";
export default {
	name:'filtrosPainelColetivo',
	components:{
		baseIcon   : require('@/components/Atom/Icon/BaseIcon.vue').default,
		Multiselect,
		datePicker,
	},
	props:{
		clienteSelecionado:{
			type: Array,
			required:true
		}
	},
	data() {
		return {
			optSelectEmpresa: new EmpresasService().Get(),
			modelCliente:[],
			buscaUsuario:'',
			iconResize: mdiResize,
			iconClear : mdiCloseThick,
			modelData :'',
			statusRota:[],
			modelStatusIniciado  :false,
			modelStatusPendente  :false,
			modelStatusFinalizado:false,
			modelStatusCancelado :false,
		}
	},
	inject: ['mapaRequisicaoViagem'],
	methods: {
		...mapGetters(["getMaster"]),
		limpaSeletorCliente(){
			this.$emit("fecha-info");
			this.modelCliente = []
		},
		/**
		 * @listens input - alteração no cliente selecionado.
		 * @param {object[]} cliente
		 * @param {string}   cliente[].description - nome do cliente.
		 * @param {number}   cliente[].value       - código do cliente.
		 * @description caso houver pelo menos 1 cliente selecionado
		 * emite os filtros.
		 */
		mudaCliente(cliente){
			var select_all = cliente.some((c)=>{return c.value === 'SA'})
			if(select_all){
				this.modelCliente = this.optSelectEmpresa
			}
			this.emiteFiltros()
		},

		/**
		 * @param {string} data - dd/mm/yyyy
		 * @description atribui o valor do filtro a moda.
		 */
		mudaDataFiltro(data){
			this.modelData = data
			this.emiteFiltros()
		},

		emiteExpandir(){
			if(this.iconResize === mdiResize)
				this.iconResize = mdiMoveResizeVariant
			else 
				this.iconResize = mdiResize
			this.$emit('expandir')
		},

		/**
		 * @description função isolada para emitir o evento
		 */
		emiteFiltros(){
			this.$emit('filtros-coletivos', this.objFiltros)
		}
	},
	watch: {
		modelCliente(value){
			this.$emit('muda-cliente', value)
		},

		clienteSelecionado(value){
			this.modelCliente = value
			this.emiteFiltros()
		},

		modelStatusIniciado(newValue) {
			if(newValue)
				this.statusRota.push('I')
			else{
				var i = this.statusRota.findIndex(x=>{
					return x === 'I'
				})
				this.statusRota.splice(i, 1)
			}
			this.emiteFiltros()
		},

		modelStatusPendente(newValue){
			if(newValue)
				this.statusRota.push('P')
			else{
				var i = this.statusRota.findIndex(x=>{
					return x === 'P'
				})
				this.statusRota.splice(i, 1)
			}
			this.emiteFiltros()
		},

		modelStatusFinalizado(newValue){
			if(newValue)
				this.statusRota.push('F')
			else{
				var i = this.statusRota.findIndex(x=>{
					return x === 'F'
				})
				this.statusRota.splice(i, 1)
			}
			this.emiteFiltros()
		},

		modelStatusCancelado(newValue){
			if(newValue)
				this.statusRota.push('C')
			else{
				var i = this.statusRota.findIndex(x=>{
					return x === 'C'
				})
				this.statusRota.splice(i, 1)
			}
			this.emiteFiltros()
		},

		/**@fires filtrar-coletivos */
		buscaUsuario(newValue){
			this.$emit('filtro-usuario', newValue)
		}
	},
	computed: {
		optClientes(){
			var sa = {
				value:'SA',
				description:'Selecionar Todos'
			}
			return [sa, ...this.optSelectEmpresa]
		},
		objFiltros(){
			return {
				clientes: this.modelCliente.map(c=>c.value),
				data: this.modelData,
				statusRotas:this.statusRota
			}
		},
		requisicaoViagem() {
            return this.mapaRequisicaoViagem.fechaViagem;
        },
	},
	mounted(){
		if (this.getMaster()){
             this.modelCliente = [];
        }else{
            this.modelCliente = this.clienteSelecionado;
        }
	}
}
</script>

<style lang="scss">
#filtro-painel-coletivos{
	height: 50px !important;
	background-color: rgba(245, 245, 245, 0.90) !important;
	.seletor-empresa{
		width: 300px !important;
		background-color: blue;
	}
	.divDatePicker{
		width: 200px;
	}
}

</style>