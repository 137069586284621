<!-- 
    Este botão é um botão com span de carregamento

    -> Propriedades:
        1- text - String - Texto que vai para o botão - EX: 'Gerar'
        2- icon - String - Icone que vai para o botão - EX: 'mdiFacebook'
        3- type - String - Tipo de botão, a cor a ser escolhida -
            EX: orange/green/red/blue/white/light-green/light-blue/aqua-marine
        4- extraClass - String - Classe extra do botão se necessario 
            EX: 'buttonLoading'
        5- event - String - Evento a ser emitido - EX: 'click'
        6- id - String - Id do botão a ser escolhido - EX: 'buttonLoading'
        7- width - String - Tamanho do botão - EX: '100px'/'100%'
        8- title - String - Title a ser mostrado no hover do botão 
            EX: 'Carregando...'
        9- value - String - Valor do botão a ser emitido quando clicado - EX: '2'
        10- typeButton - String - Tipo de botão se é 'a' ou 'button' 
            EX: 'a'/'button'
        11- link - String - Rota para redirecionamento 
            EX: 'administrativo/cadastros/usuario'
        12- sizeIcon - String / Number - Tamanho do ícone - EX:'18'

    -> Slot:
        -Sem slot

    -> Eventos:
        1- Evento a ser escolhido - emitido o evento passo pelo event
        - Parametros:
            1.1- this - emitido o botão em si
            1.2- value - o valor do value passado
-->
<template>
    <a v-if="typeButton == 'a'" :href="link" :class="defineClass" :id="id" @click="click(value)" :style="defineStyle"
        data-test="simple-button-anchor" :title="title">
        <span v-if="icon != ''" :class="icon"></span>
        {{ text }}
    </a>
    <button v-bind="$attrs" v-else :class="defineClass" :id="id" @click="click(value)" :style="defineStyle"
        data-test="simple-button-button" :title="title">
        <baseIcon v-if="icon != ''" :icon="icon" :size="sizeIcon" />
        {{ text }}
    </button>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
    inheritAttrs: false,
    components: {
        baseIcon: require("@/components/Atom/Icon/BaseIcon.vue").default,
    },
    props: {
        text: {
            type: String,
            required: false,
        },
        icon: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "blue",
        },
        extraClass: {
            type: String,
            required: false,
            default: "",
        },
        id: {
            type: String,
            required: false,
            default: "",
        },
        width: {
            type: String,
            required: false,
            default: "100px",
        },
        height: {
            type: String,
            required: false,
            default: "",
        },
        title: {
            type: String,
            required: false,
            default: "",
        },
        value: {
            type: [String, Number],
            required: false,
            default: "",
        },
        typeButton: {
            type: String,
            required: false,
            default: "button",
        },
        link: {
            type: String,
            required: false,
            default: "",
        },
        event: {
            type: String,
            default: "",
        },

        sizeIcon: {
            type: [String, Number],
            default: "18",
        },
    },
    methods: {
        click(value) {
            this.$emit(this.event, this, value);
        },
    },
    computed: {
        defineStyle() {
            let style = "";
            if (this.width) style += "width:" + this.width;
            if (style) style += ";";
            if (this.height) style += "height:" + this.height;
            if (style) style += ";";
            return style;
        },
        defineClass() {
            let classe = "bt-eagle";
            if (this.type) classe += " bt-" + this.type;
            if (this.extraClass) classe += " " + this.extraClass;
            return classe;
        },
    },
});
</script>

<style lang="scss" scoped>
.bt-eagle {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400 !important;
    padding: 4px 10px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    display: inline-block;
    margin-bottom: 0;
    touch-action: manipulation;
    user-select: none;
    background-image: none;
    font-family: nexabook, sans-serif !important;

    &:hover,
    &:focus {
        color: #fff;
        text-decoration: none;
    }

    &:disabled {
        opacity: 0.6;
        background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(19, 1, 1, 0.3));
        color: -internal-light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3));
        border-color: -internal-light-dark(rgba(118, 118, 118, 0.3), rgba(195, 195, 195, 0.3));
    }

    span {
        margin: 0px;

        &::before {
            font-size: 12px;
            margin: 0;
        }

        &.icon {
            line-height: 16px;

            &:before {
                font-size: 11px;
            }
        }

        &.fa-spinner.fa-spin.fa-3x {
            font-size: 0.5em;
            width: 14px;
        }
    }

    &.bt-orange {
        background-color: #f0ad4e;
        border: 1px solid #fff;
        color: #fff;
    }

    &.bt-green {
        background-color: #1caf9a;
        border: 1px solid #fff;
        color: #fff;
    }

    &.bt-yellow {
        background-color: #cbdf18;
        border: 1px solid #fff;
        color: #fff;
    }

    &.bt-red {
        background-color: #e04b4a;
        border: 1px solid #fff;
        color: #fff;
    }

    &.bt-redish {
        background-color: #ce6e6e;
        border: 1px solid #fff;
        color: #fff;
    }

    &.bt-blue {
        background-color: #428bca;
        border: 1px solid #fff;
        color: #fff;
    }

    &.bt-white {
        background-color: #fff;
        border: 1px solid #d5d5d5;
        color: #000000;
    }

    &.bt-black {
        background-color: #000;
        border: 1px solid #d5d5d5;
        color: #fff;
    }

    &.bt-light-green {
        background-color: #95b65c;
        border: 1px solid #fff;
        color: #fff;
    }

    &.bt-light-blue {
        background-color: #5bc0de;
        border: 1px solid #fff;
        color: #fff;
    }

    &.bt-grey {
        background-color: #777777;
        border: 1px solid #fff;
        color: #fff;
    }

    &.bt-gray {
        background-color: #dcdcdc;
        border: 1px solid #fff;
        color: #000;
    }

    &.bt-aqua-marine {
        background-color: #1caf9a;
        border: 1px solid #fff;
        color: #fff;
    }
}
</style>
