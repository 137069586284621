<template>
	<eaglePanel id="cadastroPontos" :loading="salvando">
		<div id="divBotoes" class="col-sm-12 row">
			<div class="col-sm-6 nopadding">
				<titulo :icon="mdiMapMarkerRadius" titulo="Cadastro de Pontos" />
			</div>
			<div class="col-sm-6 nopadding" id="divBotaoSalvaCancela">
				<botoesSC tipoCancela="button" :disabled="$v.$invalid || !telefoneValido || erro" @salvarCadastro="salvarPonto"
					@cancelarCadastro="cancelarCadastro" />
				<!-- linkCancel="listarPonto" -->
			</div>
		</div>
		<div class="col-sm-12">
			<hr />
		</div>
		<div class="col-sm-12">
			<b-tabs v-model="tabIndex" id="tabs">
				<b-tab title="Home">
					<div class="col-sm-12 nopadding row">
						<div class="col-sm-6 nopadding" id="primeiraColunaHome">
							<div class="col-sm-12 nopadding" id="divDescricao">
								<inputS name="inputDescricao" :inputClass="erroInputDescricao()" label="Descrição*"
									:value="ponto.podescricao" class="pl-0" @blur="$v.ponto.podescricao.$touch()"
									@changeInput="mudaDescricao" />
							</div>
							<div class="col-sm-12 nopadding row" id="divTipoECodigo">
								<div class="col-sm-6 nopadding" id="divTipoPonto">
									<selectAll nameForRadio="TipoPonto" :extraClass="erroInputTipoPonto()"
										@close="$v.ponto.potipo.$touch()" @changeSelect="mudaTipoDePonto" :isMultiple="false"
										:allowEmpty="false" class="pl-0" :selected="tipoSelecionado" :labels="[
											{ description: 'Tipo de Ponto*' }
										]" :optionsArray="optTiposDePonto" />
								</div>
								<div class="col-sm-6 nopadding">
									<inputS :label="labelCodigoExterno" :value="ponto.pocodigoexterno" @changeInput="mudaCodigoExterno" />
								</div>
							</div>
						</div>
						<div class="col-sm-6 nopadding row" id="divSegundaColuna">
							<div class="col-sm-12 nopadding" id="divSeletorEmpresa">
								<selectAll nameForRadio="seletorEmpresa" :isMultiple="false" :extraClass="erroSelectEmpresa()"
									@close="$v.ponto.pocodigocliente.$touch()" :selected="empresaSelecionada" class="pr-0"
									:labels="[{ description: 'Empresa*' }]" :optionsArray="optSelectEmpresas"
									@changeSelect="mudaEmpresa" />
							</div>
							<div class="col-sm-12 nopadding" align="center" id="divInicioFimRota">
								<div class="col-sm-5 float-left text-center mt-2">
									<b-form-checkbox @change="pontoInicioFimRota" id="inícioFim" name="inícioFim"
										:checked="ponto.poinifimrotacheck" :value="true">
										Considerar Ponto Início/Fim de Rota
									</b-form-checkbox>
								</div>

								<div class="col-sm-3 float-left text-center mt-2">
									<b-form-checkbox @change="pontoTransbordoRota" id="transbordo" name="transbordo"
										:checked="ponto.potransbordo" :value="true">
										Transbordo
									</b-form-checkbox>
								</div>

								<div class="col-sm-4 float-left text-center mt-2">
									<b-form-checkbox @change="pontoMatrizFilialRota" id="matrizFilial" name="matrizFilial"
										:checked="ponto.pofilialmatriz" :value="true">
										Considerar Ponto Matriz/Filial
									</b-form-checkbox>
								</div>



								<!-- <buttonF 
									@buttonsFiltersChange="pontoInicioFim" 
									label="Considerar ponto inicio/fim de rota"
									:arrayButtons="filtroInicioFim" /> -->
							</div>
						</div>
						<div class="col-sm-12 nopadding">
							<hr />
						</div>
						<div class="col-sm-12 nopadding" id="divLocalizacao">
							<h5>Localização</h5>
							<inputSearch name="procurarPontos" label="Pesquisar" class="pl-0 pr-0" :value="ponto.poendereco"
								@changeInput="mudaInputProcura" @keyupEnter="procuraEndereco" @keyup-esc="limpaEnderecos"
								@clickButtonSearch="procuraEndereco" />
							<b-list-group class="listaResultadoProcura">
								<b-list-group-item v-for="(res, index) in resultadoPesquisa" :key="index" button class="listItem"
									event="click" @click="selecionaPonto(res)">
									{{ res.endereco }}
								</b-list-group-item>
							</b-list-group>
						</div>
						<div class="col-sm-12 nopadding row" style="margin-top: 0px">
							<div class="col-sm-3 nopadding">
								<inputS :value="ponto.polatitude" @changeInput="mudaLatitude" label="Latitude" class="pl-0 pr-0" />
							</div>
							<div class="col-sm-3 nopadding">
								<inputS label="Longitude" :value="ponto.polongitude" @changeInput="mudaInputLongitude" />
							</div>
							<div class="col-sm-2 nopadding">
								<inputS disabled label="Região Associada" :value="regAssociada" class="pl-0 pr-0" />
							</div>
							<div class="col-sm-2 nopadding">
								<inputS @changeInput="(v) => (ponto.pocidade = v)" :value="ponto.pocidade" label="Cidade" class="" />
							</div>
							<div class="col-sm-2 nopadding">
								<inputS @changeInput="(v) => (ponto.poestado = v)" :value="ponto.poestado" label="Estado (UF)"
									:hasMask="true" :mask="['XX']" class="pl-0" />
							</div>
						</div>
					</div>
				</b-tab>
				<!-- Disponibilidade -->
				<b-tab title="Disponibilidade">
					<div class="col-sm-12 nopadding row">
						<div class="col-sm-3 nopadding">
							<inputS :value="ponto.potempoconsideraespera" type="time" class="pl-0" :label="labelEspera"
								@changeInput="mudaTempoEspera" />
						</div>
						<div class="col-sm-12 nopadding">
							<hr />
						</div>
						<h5>Horário de funcionamento</h5>
						<div id="divHorario" class="col-sm-12 nopadding row">
							<div class="col-sm-3 nopadding">
								<inputS label="Hora Inicial" type="time" class="nopadding" @changeInput="mudaHoraInicio" />
							</div>
							<div class="col-sm-3 nopadding">
								<inputS label="Hora Final" type="time" @changeInput="mudaHoraFinal" />
							</div>
							<div class="col-sm-3 nopadding">
								<selectAll nameForRadio="selectDiaDaSeamana" class="nopadding" ref="seletorDia" :isMultiple="true"
									:labels="[{ description: 'Selecione os dias' }]" :optionsArray="optDiasDaSemana"
									@changeSelect="mudaDiaSemana" />
							</div>
							<div class="col-sm-3" style="padding-top: 1.9%" align="left">
								<simpleB :disabled="dispoInvalida" text="Adicionar" type="blue" event="click" @click="adicionaDispo" />
							</div>
						</div>
						<div class="col-sm-12 nopadding">
							<hr />
						</div>
						<div class="col-sm-12 nopadding">
							<tableL :titles="tableTitulos" :data="dataDispo" @excluirCadastro="excluirDispo" />
						</div>
					</div>
					<div class="col-sm-12 nopadding">
						<hr />
					</div>
					<h5>Contato</h5>
					<div class="col-sm-12 nopadding row">
						<div class="col-sm-4 nopadding">
							<inputS @blur="$v.ponto.potelefone.$touch()" class="nopadding" :inputClass='{
								"border border-danger": $v.ponto.potelefone.$anyError
									|| !telefoneValido
							}' :hasMask="true" :mask="[
								'(NN) NNNN-NNNN',
								'(NN) NNNNN-NNNN'
							]" :value="ponto.potelefone" label="Telefone" @changeInput="mudaTelefone" />
						</div>
						<div class="col-sm-4 nopadding row">
							<!-- @blur="$v.ponto.poemail.$touch()" -->
							<inputS :inputClass='{
								"border border-danger": erro
							}' label="E-mail" :value="ponto.poemail" @changeInput="mudaEmail"
								@blur="blurInputEmailsAdicionais(ponto.poemail)" />
						</div>
					</div>
				</b-tab>
			</b-tabs>
		</div>
		<div class="col-sm-12" style="margin-top: 1.0%">
			<mapa-simples v-show="tabIndex == 0" ref="mapa" :height="500" :initialZoom="13">
				<!-- :latitude="latInicial"
			:longitude="longInicial" -->
				<marcador id="matriz" icon="img/matriz.png" :latitude="latLangMatriz[0]" :longitude="latLangMatriz[1]" />
				<marcador :latitude="ponto.polatitude != '-' ?
					Number(ponto.polatitude) : ''" :longitude="ponto.polongitude != '-' ?
						Number(ponto.polongitude) : ''" ref="marker" :isDraggable="true" :icon="icon" @drag="atualizaLatLongMarker"
					@dragend="posicionaMarcador">
					<popUpMarker>
						<div class="col-sm-12 nopadding">
							<p>Arraste para o local desejado</p>
							<basicSlider ref="slider" @change="mudaSlider" :maxValue="500" :value="ponto.poraio"
								:initialValue="ponto.poraio" />
							{{ "Raio: " + ponto.poraio + " Mts" }}
						</div>
					</popUpMarker>
				</marcador>
				<circleS :radius="ponto.poraio" :center="latLon(ponto.polatitude, ponto.polongitude)" />
			</mapa-simples>
		</div>
		<requiredF />
		<div class="col-sm-12">
			<hr />
		</div>
	</eaglePanel>
</template>

<script>
import { mdiMapMarkerRadius } from '@mdi/js';
import marcador from '@/components/Atom/Mapa/MarkerPoint';
import popUpMarker from '@/components/Atom/Mapa/PopupMarker';
import basicSlider from '@/components/Atom/Buttons/BasicSlider';
import inputS from '@/components/Atom/Inputs/InputSimple';
import titulo from '@/components/Atom/Header/Titulo';
import simpleB from '@/components/Atom/Buttons/SimpleButton';
import circleS from '@/components/Atom/Mapa/CircleSimple';
import botoesSC from '@/components/Atom/Buttons/BasicButtonsCadastrosSC';
import selectAll from '@/components/Atom/Select/SelectAll';
import eaglePanel from '@/components/Atom/Panel/PanelEagle';
import inputSearch from '@/components/Atom/Inputs/InputSearch';
import MapaSimples from '@/components/Atom/Mapa/MapaSimples';
import requiredF from '@/components/Atom/Footer/RequiredFields';
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { EmpresasService } from '@/Services/auth/Empresas.service';
import { required, email, minLength } from 'vuelidate/lib/validators';
import { conectionError } from '@/Services/Helpers/swellHeper';
export default {
	name: 'cadastroPontos',
	components: {
		tableL: require('@/components/Atom/Table/TableListagemModal').default,
		popUpMarker,
		eaglePanel,
		requiredF,
		titulo,
		inputS,
		selectAll,
		inputSearch,
		botoesSC,
		simpleB,
		MapaSimples,
		marcador,
		circleS,
		basicSlider,
	},
	data() {
		return {
			mdiMapMarkerRadius: mdiMapMarkerRadius,
			labelEspera: 'Se ponto espera, considerar somente paradas acima de',
			tableTitulos: [
				{ name: 'Inicio', number: 'pdihorainicio' },
				{ name: 'Fim', number: 'pdihorafim' },
				{ name: 'Dia da semana', number: 'pdidiasemana.description' },
			],
			icons: {
				E: 'img/markerPontoE.png',
				P: 'img/markerPontoP.png',
				C: 'img/markerPontoC.png',
			},
			optTiposDePonto: [
				{ value: 'C', description: 'Coleta' },
				{ value: 'E', description: 'Entrega' },
				{ value: 'P', description: 'Referência' },
			],
			// filtroInicioFim: [
			// 	{ value: 'S', text: 'Sim', width: '48.45%', isSelected: true },
			// 	{ value: 'N', text: 'Não', width: '48.45%', isSelected: false },
			// ],
			tipoSelecionado: [],
			empresaSelecionada: [],
			optSelectEmpresas: [],
			optSelectRegiao: [],
			optDiasDaSemana: [],
			ponto: {
				poinifimrota: 'N',
				poinifimrotacheck: false,
				potransbordo: false,
				pofilialmatriz: false,
				poendereco: '',
				pocodigoexterno: '',
				podescricao: '',
				pocodigocliente: '',
				potipo: '',
				polatitude: -27.1001389,
				polongitude: -52.6270369,
				poraio: 50,
				poregiao: '',
				potempoconsideraespera: '',
				pocidade: '',
				poestado: '',
				potelefone: '',
				poemail: '',
			},
			dispo: {
				pdihorainicio: '',
				pdihorafim: '',
			},
			pdidiasemana: [],
			dataDispo: [],
			resultadoPesquisa: [],
			latInicial: -27.1001389,
			longInicial: -52.6270369,
			latLangMatriz: [],
			icon: 'img/markerPonto.png',
			tabIndex: 0,
			regAssociada: '',
			rota: '/telemetria/cadastros/pontos/',
			codigoDipos: [],
			salvando: false,
			exclusao: [],
			erro: false,
			tipoPontoSelecionado: '',
			labelCodigoExterno: 'Código Externo',
			requiredCodigoExterno: false
		};
	},
	validations: {
		ponto: {
			podescricao: { required },
			pocodigocliente: { required },
			potipo: { required },
			// poemail:{ email },
			potelefone: { minLength: minLength(13) },
			pocodigoexterno: function () {
				if (this.requiredCodigoExterno) {
					return required(this.ponto.pocodigoexterno);
				}
				return true;
			},
		},
	},
	methods: {
		/**
		 * Essa tela possui uma regra diferente e ela existe me dois modulos
		 * nesse caso para nao ser direcionado para o outro modulo ela faz a
		 * comparacao para saber qual tela ela precisa direcionar
		 * no link tem a tarefa escrita
		 * @link https://app.clickup.com/t/2ykugg1
		 */
		cancelarCadastro() {
			if (this.$route.name === "cadastrarPontoWay") {
				this.$router.push({ name: 'listagemPontosWay' })
				return
			}
			this.$router.push({ name: 'listarPonto' })
		},

		pontoInicioFimRota(value) {
			if (value) {
				this.ponto.poinifimrota = "S";
			} else {
				this.ponto.poinifimrota = "N";
			}
			this.ponto.poinifimrotacheck = value;
		},
		pontoTransbordoRota(value) {
			this.ponto.potransbordo = value;
		},
		pontoMatrizFilialRota(value) {
			this.ponto.pofilialmatriz = value;
		},

		limpaEnderecos() {
			this.resultadoPesquisa = []
		},

		/**
		 * @description Método retorna a latitude e longitude para o
		 * raio do ponto
				 * @param {string} - latitude
				 * @param {string} - longitude
				 * @return {array} - latitude e longitude que o raio deve estar no mapa
				 * @author Vitor Hugo
				 */
		latLon(latitude, longitude) {
			if (latitude !== '-' && longitude !== '-') {
				this.latInicial = Number(latitude)
				this.longInicial = Number(longitude)
				return [Number(latitude), Number(longitude)]
			} else {
				return ['', '']
			}
		},

		mudaLatitude(value) {
			this.ponto.polatitude = value
		},

		mudaInputLongitude(value) {
			this.ponto.polongitude = value
		},

		mudaInputProcura(value) {
			this.ponto.poendereco = value
		},

		mudaHoraFinal(value) {
			this.dispo.pdihorafim = value
		},

		mudaTempoEspera(value) {
			this.ponto.potempoconsideraespera = value
		},

		erroSelectEmpresa() {
			return {
				'border border-danger': this.$v.ponto.pocodigocliente.$anyError
			}
		},

		mudaCodigoExterno(value) {
			this.ponto.pocodigoexterno = value
		},

		erroInputTipoPonto() {
			return {
				'border border-danger': this.$v.ponto.potipo.$anyError
			}
		},

		erroInputDescricao() {
			return {
				'border border-danger': this.$v.ponto.podescricao.$anyError
			}
		},

		/**
		 * @description a função decide, de acordo com a rota, se vai salvar ou
		 * atualizar um ponto também envia a lista de disponibilidades
		 * (se for atualizar, também envia a lista * a ser destruida e o ID do
		 * ponto a ser att)
		 * @author Gui 🍺
		 **/
		salvarPonto() {
			var funcao = this.$route.params.id ? 'atualizar' : 'salvar';
			this.salvando = true;
			var obj = {
				ponto: {
					...this.ponto,
				},
				dispos: {
					...this.disponibilidades,
				},
			};
			if (this.$route.params.id) {
				obj.ponto['pocodigo'] = this.$route.params.id;
				obj['excluir'] = this.exclusao;
			}
			new HttpRequest().Post(this.rota + funcao, obj).then((data) => {

				if (data.data.success) {
					this.$router.push({ name: 'listarPonto' });
				} else {
					if (data.data.duplicado) {
						conectionError('', 'warning', 'Código externo já cadastrado!')
					} else {
						conectionError()
					}
				}
				this.salvando = false;
			});
		},
		//DISPONIBILIDADE
		mudaHoraInicio(inputValue) {
			this.dispo.pdihorainicio = inputValue;
		},

		mudaHoraFim(inputValue) {
			this.dispo.pdihorafim = inputValue;
		},

		mudaDiaSemana(arr, value, description) {
			this.pdidiasemana = arr;
		},

		mudaEmail(value) {
			this.ponto.poemail = value.replaceAll(/([\w\d.]+)(?=\s{1})/gm, '$1;')
			this.erro = false
		},

		mudaTelefone(value) {
			this.ponto.potelefone = value
		},

		//acabar de fazer a gravação no banco hehe
		/***********************************************************
		 * Pra fazer com que a 'descrição' do dia aparece na tabela*
		 * mas tambem salva o 'valor' do dia pra salvar no bd      *
		 ***********************************************************/
		selecionaDiaSemana(valor) {
			var obj;
			this.optDiasDaSemana.forEach((dia) => {
				if (dia.value === valor) {
					obj = dia;
				}
			});
			return obj;
		},

		/**
		 * @description Prepara as disponibilidades(dispo) para serem
		 * adicionadas a fila. Faz com que exista uma dispo diferente
		 * para cada dia da semana selecionado
		 **/
		adicionaDispo() {
			this.pdidiasemana.forEach((dia) => {
				var aux = {
					...this.dispo,
					pdidiasemana: this.selecionaDiaSemana(dia),
					codigo: dia,
				};
				if (this.achaIndexCodigo(aux.codigo) === -1) {
					this.dataDispo.push(aux);
				}
				this.$refs.seletorDia.clearAll();
			});
		},

		/***********************************************
		 * Procura pelo index de um 'codigo'           *
		 * traz o index do mesmo ou -1 caso não exista *
		 * Função usada para adicionar e excluir dispos*
		 ***********************************************/
		achaIndexCodigo(codigo) {
			var a = this.dataDispo.findIndex((elemento) => {
				return elemento.codigo === codigo;
			});
			return a;
		},

		excluirDispo(codigo) {
			var index = this.achaIndexCodigo(codigo);
			var excluido = this.dataDispo.splice(index, 1);
			if (excluido[0].pdicodigo) {
				this.exclusao.push(excluido[0].pdicodigo);
			}
		},

		//HOME
		mudaDescricao(inputValue) {
			this.ponto.podescricao = inputValue;
		},

		mudaTipoDePonto(tipo) {
			this.ponto.potipo = tipo[0];
			this.icon = this.icons[tipo];
			this.tipoPontoSelecionado = tipo[0];
			if (this.tipoPontoSelecionado == 'C' || this.tipoPontoSelecionado == 'E') {
				this.labelCodigoExterno = 'Código Externo*';
				this.requiredCodigoExterno = true;
			} else {
				this.labelCodigoExterno = 'Código Externo';
				this.requiredCodigoExterno = false;
			}
		},

		mudaEmpresa(arr, value, description) {
			this.ponto.pocodigocliente = '';
			if (arr.length > 0) {
				this.ponto.pocodigocliente = arr[0];
			}
			this.regiaoAssocidada();
		},

		blurInputEmailsAdicionais() {
			let arrayEmails = this.ponto.poemail.split(' ')
			let regexEmail = /\S+@\S+\.\S+/
			this.erro = arrayEmails.some((email) => {
				return email != '' && !regexEmail.test(email)
			})
		},

		/**
		 * @description Método para fazer a busca de um endereço
		 *  Obs: caso for mexer atentar para a api utilizada,
		 * 	deve ser a do google, pois as outras trazem dados limitados
		 * @author Gui, comentado pelo Vitor Hugo 🐨
		 */
		procuraEndereco() {
			this.salvando = true
			var obj = { endereco: this.ponto.poendereco };
			new HttpRequest().Post(this.rota + 'adresstolatlong', obj)
				.then((data) => {
					this.resultadoPesquisa = data.data.resultado;
				})
				.catch((error) => {
					conectionError()
				})
				.finally(() => {
					this.salvando = false
				})
		},

		//MAPA
		/**************************************************
		 *   Procura pela região associada ao ponto       *
		 **************************************************/
		regiaoAssocidada() {
			this.regAssociada = '';
			if (this.ponto.pocodigocliente === '') {
				return;
			}
			var pontos = {
				0: { lat: this.ponto.polatitude, log: this.ponto.polongitude },
			};
			var obj = {
				pontos: pontos,
				cliente: this.ponto.pocodigocliente,
			};
			new HttpRequest().Post(this.rota + 'regiao', obj).then((data) => {
				if (data.data.regiao) {
					this.regAssociada = data.data.regiao.redescricao;
					this.ponto.poregiao = data.data.regiao.recodigo;
				}
			});
		},

		atualizaLatLongMarker(drag) {
			this.ponto.polatitude = drag.latlng.lat;
			this.ponto.polongitude = drag.latlng.lng;
		},

		/**
		 * Quando o marcadador para de se mover, essa função procura
		 * por um endereço correspondente a localização do mesmo
		 */
		posicionaMarcador(marker) {
			var obj = {
				lat: marker.target._latlng.lat,
				lon: marker.target._latlng.lng,
			};
			this.getEndereco(obj);
			this.regiaoAssocidada();
		},

		/********************************************
		 * Procura por um endereço próximo ao ponto *
		 * passado pelo obj do parametro            *
		 ********************************************/
		getEndereco(obj) {
			new HttpRequest()
				.Post(this.rota + 'latLangToAdress', obj)
				.then(({ data }) => {
					this.ponto.poendereco = data.endereco;
					this.ponto.pocidade = data.dados.cidade;
					this.ponto.poestado = data.dados.uf;
				});
		},

		mudaSlider(value) {
			this.ponto.poraio = value;
		},

		/**
		 * @description Função disparada quando o usuário seleciona um ponto
		 * @author Gui
		 */
		selecionaPonto(ponto) {
			this.ponto.polongitude = Number(ponto.centro[0])
			this.ponto.polatitude = Number(ponto.centro[1])
			this.ponto.poendereco = ponto.endereco
			this.resultadoPesquisa = []
			if (this.ponto.polatitude && this.ponto.polongitude)
				this.$refs.mapa?.flyTo([this.ponto.polatitude, this.ponto.polongitude])
		},

		// starting page
		arrumaTipo() {
			this.optTiposDePonto.forEach((el) => {
				if (el.value === this.ponto.potipo) {
					this.tipoSelecionado.push(el);
					return;
				}
			});
		},

		arrumaEmpresa() {
			this.optSelectEmpresas.forEach((el) => {
				if (el.value === this.ponto.pocodigocliente) {
					this.empresaSelecionada.push(el);
					return;
				}
			});
		},

		/**
		 * Faz basicamente a mesma coisa que a função
		 * adicionaDispo. Com a diferença dessa ser executada
		 * para carregar informações do banco de dados
		 * @author Gui
		 **/
		arrumaDispo(dispos) {
			dispos.forEach((d) => {
				var aux = {
					...d,
					pdidiasemana: this.selecionaDiaSemana(d.pdidiasemana),
					codigo: d.pdidiasemana,
				};
				this.dataDispo.push(aux);
			});
		},

		arrumaCampos(data) {
			Object.keys(this.ponto).forEach((key) => {
				this.ponto[key] = data.data.ponto[key] ?? '';
			});
			let latIni = Number(this.ponto.polatitude)
			let lngIni = Number(this.ponto.polongitude)
			if (latIni)
				this.$refs.mapa.flyTo([latIni, lngIni])
			this.latInicial = latIni;
			this.longInicial = lngIni;
			this.ponto.poraio = Number(this.ponto.poraio);
			this.arrumaTipo();
			this.arrumaEmpresa();
		},

		arrumaInicioFim(poinifimrota) {
			if (poinifimrota === 'S') {
				this.ponto.poinifimrotacheck = true;
			} else {
				this.ponto.poinifimrotacheck = false;
			}
		},

		posicionaMarcadorMatriz() {
			new HttpRequest()
				.Get(this.rota + 'matriz/' + this.$store.getters.getClient)
				.then((data) => {
					let lat = Number(data.data.latLong.cllatitude)
					let lng = Number(data.data.latLong.cllongitude)
					this.latLangMatriz[0] = lat
					this.latLangMatriz[1] = lng
				});
		},
		// pontoInicioFim(value) {
		// 	this.ponto.poinifimrota = value[0];
		// },
	},
	async mounted() {
		this.salvando = true;
		this.posicionaMarcadorMatriz();
		this.optSelectEmpresas = new EmpresasService().Get();
		await new HttpRequest().Get(this.rota + 'semana').then((data) => {
			this.optDiasDaSemana = data.data.dias;
		});
		if (this.$route.params.id) {
			new HttpRequest()
				.Get(this.rota + 'getPonto/' + this.$route.params.id)
				.then((data) => {
					this.salvando = true;
					this.arrumaCampos(data);
					this.arrumaInicioFim(data.data.ponto.poinifimrota);
					if (data.data.ponto.disponibilidade) {
						this.arrumaDispo(data.data.ponto.disponibilidade);
					}
					this.salvando = false;
				});
		} else {
			this.salvando = false;
			const objetoStringRecuperado = localStorage.getItem("meuObjeto");
			const objetoRecuperado = JSON.parse(objetoStringRecuperado);

			if (objetoRecuperado) {
				this.ponto.podescricao = objetoRecuperado.irnome;
				this.ponto.pocodigoexterno = objetoRecuperado.ircodigoexterno;
				this.empresaSelecionada = [{
					value: objetoRecuperado.ircliente,
					description: objetoRecuperado.filial
				}];
			}
		}
	},
	computed: {
		/***********************************************************
		 *  Essa propriedade existe para deixar as disponibilidades*
		 * prontas para serem enviadas para o banco de dados       *
		 ***********************************************************/
		disponibilidades() {
			var dispos = [];
			this.dataDispo.forEach((d) => {
				if (!d.pdicodigo) {
					var b = {
						...d,
						pdidiasemana: d.pdidiasemana.value,
					};
					if (this.$route.params.id) {
						b['pocodigo'] = this.$route.params.id;
					}
					delete b.codigo;
					dispos.push(b);
				}
			});
			return dispos;
		},
		/*
		 * Uma validação simples para permitir adicionar uma nova dispo
		 * a lista.Verifica se existe um horário final,
		 * inicial e pelo menos um dia da semana selecionado.
		 */
		dispoInvalida() {
			if (
				this.dispo.pdihorainicio !== '' &&
				this.dispo.pdihorafim !== '' &&
				this.pdidiasemana.length > 0
			) {
				return false;
			}
			return true;
		},
		telefoneValido() {
			const regex = /^\([0-9]{2}\) [0-9]{4,5}-[0-9]{4}$/gm
			return !this.ponto.potelefone || regex.test(this.ponto.potelefone)
		}
	},
};
</script>

<style lang="sass" scoped>
#divLocalizacao
    max-height: 90px

h5
    text-align: left

#tabs div
    margin-top: 0.4%

.listaResultadoProcura
    width: 100% !important
    position: absolute
    list-style-type: none
    text-align: left
    overflow-x: hidden
    overflow-y: auto
    z-index: 45
    max-height: 150%

.listItem
    position: relative
    margin-right: 2%
    font-size: 13px

#divSeletorEmpresa
    z-index: 3
</style>
