<template>
	<div class="col-sm-12 row nopadding tab-pedidos borda-tab">
		<div v-for="(dados, index) in cargas" :key="dados.irordem + '_carga_' + index" class="col-sm-12 row nopadding">
			<div class="tabCargas">
				<div class="divChevron" @click="dados.clicado = !dados.clicado" v-b-toggle="'collapse' + index">
					<b-icon class="h4 mb-2 my-auto" :icon="dados.clicado ? 'chevron-down' : 'chevron-right'" />
				</div>

				<div @click="dados.clicado = !dados.clicado" v-b-toggle="'collapse' + index" :title="dados.carro"
					class="divCarro">
					{{ dados.carro }}
				</div>

				<div class="d-flex justify-content-end row nopadding">
					<div class="col-sm-4 nopadding">
						<simpleButton :disabled="visualizarPontos" @click="apresentarRotaAplicativo(dados, index)" event="click"
							extraClass="removeLoad my-auto mx-auto" :type="'blue'"
							:icon="dados.mostrarRotaAplicativo ? mdiCellphoneOff : mdiCellphoneCheck"
							:title="dados.mostrarRotaAplicativo ? 'Bloqueada rota para aparecer no aplicativo' : 'Liberado rota para aparecer no aplicativo'"
							width="50px" />
					</div>

					<div class="col-sm-4 nopadding">
						<simpleButton @click="mostrarRota(dados, index)" event="click" extraClass="removeLoad my-auto mx-auto"
							:type="dados.visualizarRota ? 'orange' : 'blue'" :icon="dados.visualizarRota ? mdiEyeOff : mdiEye"
							title="Visualizar rota" width="50px" />
					</div>

					<div class="col-sm-4 nopadding">
						<loadingButton :disabled="visualizarPontos" @click="excluirCarga(dados, index)" :isLoading="false"
							title="Remover carregamento" extraClass="removeLoad my-auto mx-auto" event="click" type="red"
							:icon="mdiTrashCanOutline" width="50px" />
					</div>

					<!-- <div class="col-sm-3 nopadding">
						<simpleButton
							:disabled="visualizarPontos"
							@click="editarRota(dados, index)"
							event="click"
							extraClass="removeLoad my-auto mx-auto"
							:type="'orange'"
							:icon="mdiPencil"
							title="Editar rota"
							width="50px"
						/>
					</div> -->
					<!-- <div class="col-sm-3 nopadding">
						<simpleButton
							@click="downloadRota(dados, index)"
							event="click"
							extraClass="removeLoad my-auto mx-auto"
							:type="'orange'"
							:icon="mdiDownload"
							title="Editar rota"
							width="50px"
						/>
					</div> -->

				</div>

			</div>
			<b-collapse class="col-sm-12 nopadding limite-item" :ref="'refcollapse' + index" :id="'collapse' + index">
				<div class="col-sm-12 row text-left nopadding div-item" v-for="(item, ind) in dados.itens"
					:key="ind + '_itens_carga'">
					<div class="col-sm-1 small">
						{{ item.irordem }}
					</div>
					<div class="col-sm-11 small nopadding">
						{{ item.irnome }}
					</div>
				</div>
			</b-collapse>

		</div>
	</div>
</template>

<script>
import {
	mdiTrashCanOutline,
	mdiEye,
	mdiEyeOff,
	mdiPencil,
	mdiDownload,
	mdiCellphoneCheck,
	mdiCellphoneOff
} from '@mdi/js'
import Vue from 'vue'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'

export default Vue.extend({
	name: 'TabCargas',
	components: {
		simpleButton: require('@/components/Atom/Buttons/SimpleButton').default,
		loadingButton: require('@/components/Atom/Buttons/LoadingButton').default,
	},

	props: {
		cargas: {
			required: true,
			type: Array
		},
		visualizarPontos: {
			required: true,
			type: Boolean
		},
	},

	methods: {
		excluirCarga(dados, index) {
			this.$emit('excluir', dados, index);
		},

		mostrarRota(dados, index) {
			dados.visualizarRota = !dados.visualizarRota;
			this.$emit('mostrar', dados, index);
		},

		async apresentarRotaAplicativo(dados) {
			const { data } = await new HttpRequest().Post('/roteirizador/rota/manual/mostrar/aplicativo', dados);

			if (dados.mostrarRotaAplicativo) {
				this.toastShow('Sucesso', 'Liberado rota para aparecer no aplicativo!', 'success');
			} else {
				this.toastShow('Sucesso', 'Bloqueada rota para aparecer no aplicativo!', 'success');
			}

			this.filtrarCargasIntervalo();
		},

		toastShow(titulo, msg, type) {
			this.$bvToast.toast(msg, {
				title: titulo,
				autoHideDelay: 2500,
				variant: type,
			})
		},

		filtrarCargasIntervalo() {
			this.$emit('filtrarCargasIntervalo');
		}

		// editarRota(dados, index) {
		// 	this.$emit('editar', dados, index)
		// },
		// downloadRota(dados, index) {
		// 	this.$emit('download', dados, index)
		// },
	},

	data() {
		return {
			mdiEye,
			mdiEyeOff,
			mdiPencil,
			mdiTrashCanOutline,
			mdiDownload,
			mdiCellphoneCheck: mdiCellphoneCheck,
			mdiCellphoneOff: mdiCellphoneOff
		}
	}
})
</script>

<style lang="scss">
.tabCargas {
	border: 1px solid #428bca;
	background-color: rgba(66, 139, 202, 0.459);
	width: 100%;
	justify-content: space-between;
	padding: 3px 3px;
	display: inline-flex;

	.divChevron {
		padding-top: 3px;
	}

	.divCarro {
		line-height: 30px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
}
</style>