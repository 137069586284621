Essa página contem 2 relatórios, a uma versão resumida e detalhada. 

<template>
  <panelEagle id="excessoDeVelocidade" :loading="gerandoRel">
        <div class="col-sm-12  row">
            <div class="col-sm-5 nopadding">
                <titulo titulo="Excesso Velocidade" :icon="mdiSpeedometer" />
            </div>
            <div class="col-sm-7 nopadding divDontPrint">
                <basicEIG
                    :disabled="$v.relatorio.$invalid || veiculoOrMotorista"
                    :loading="arrLoading"
                    @gerarRelatorio="gerarRelatorio"
                    @exportarRelatorio="exportarRelatorioResumido" 
                    :disabledDropdown='relResumidoSelecionado ?
                    $v.dataRelResumido.$invalid :
                    $v.dataRelDetalhado.$invalid'
                >
                    <simpleButton
                        :icon='!relResumidoSelecionado? iconCheck: ""'
                        text="Detalhado"
                        event="click"
                        :type="relResumidoSelecionado ?'grey':'green'"
                        width="105px"
                        extraClass="botaoTipoRel"
                        @click="mudaFormatoRel"
                    />
                    <simpleButton
                        :icon='relResumidoSelecionado? iconCheck: ""'
                        width="105px"
                        extraClass="botaoTipoRel"
                        :type="relResumidoSelecionado ?'green':'grey'"
                        text="Resumido"
                        event="click"
                        @click="mudaFormatoRel"
                    />
                </basicEIG>
            </div>
        </div>
    <slide-up-and-down>
        <div class="col-sm-12"><hr></div>
        <div class="col-12 row nopadding">
            <div
            class='col-4 nopadding'
            v-if='!relResumidoSelecionado'>
                <singleDay
                name="inputDiaRel"
                label="Data*"
                @changeInput="mudaDataRelatorioDetalhado"/>
            </div>
            <div
            class='col-4 nopadding'
            v-else>
                <interval
                name="intervalPicker"
                titulo="Intervalo"
                ref='intervalo'
                opens="right"
                :isObrigatorio='true'
                @changeInput="mudaPeriodo"/>
            </div>
            <div class="col-4">
                <select-all
                nameForRadio="SeletorEmpresaExcesso"
                :labels="[{ indexDFH: 'C', description: 'Empresas*' }]"
                ref="EmpresaResumido"
                @changeSelect="
                    (value) => mudaEmpresaSelecionada(value)
                "
                :isMultiple="true"
                :hasSelectAll="true"
                :optionsArray="optSelectEmpresa"
                :selected="selectedEmpresa"/>
            </div>
            <div class="col-4 nopadding">
                <selectAll
                nameForRadio="seletorVeiculoResumido"
                :disabled='!optSelectVeiculo.length'
                ref="seletorVeiculoResumido"
                :labels="labelSeletorVeiculo"
                :isMultiple="true"
                :hasSelectAll="true"
                :optionsArray="optSelectVeiculo"
                @changeSelect="mudaVeiculoSelecionado"
                @changeCheck="mudaCheckVeiculo"
                :firstSelected="firstSelected"
                :loading="carregandoVGV"/>
            </div>
            <div class="col-12 nopadding row mt-2">
                <div class="col-4 nopadding">
                    <inputSimple
                    label="Vel. acima de"
                    :hasMask="true"
                    :mask="['NNN']"
                    :value="relatorio.velocidade"
                    :disabled="relatorio.regiao"
                    @changeInput="mudaVelAcima"/>
                </div>
                <div
                class="col-sm-2"
                v-if="!relResumidoSelecionado">
                    <inputSimple
                    label='Hora Inicial'
                    type='time'
                    value='00:00'
                    @changeInput="mudaHoraInicial"/>
                </div>
                <div
                class="col-sm-2"
                v-if="!relResumidoSelecionado">
                    <inputSimple
                    label="Hora Final"
                    type="time"
                    value="23:59"
                    @changeInput="mudaHoraFinal"/>
                </div>
                <div
                class="col-4 nopadding"
                v-if="!relResumidoSelecionado">
                    <selectAll
                    nameForRadio="seletorTrabalhadorRelD"
                    ref="seletorTrabalhadorDetalhado"
                    :labels="labelSelectTrabalhador"
                    firstSelected="C"
                    :hasSelectAll="true"
                    :isMultiple="true"
                    :disabled="!optSelectTrabalhador.length"
                    :loading="carregandoVGV"
                    :optionsArray="optSelectTrabalhador"
                    @changeSelect="mudaTrabalhador"
                    @changeCheck="mudaCheckTrabalhador"/>
                </div>
            </div>
            <div class="col-sm-12 pl-4 ml-4 my-2" align='left'>
                <b-check
                v-if='!relResumidoSelecionado'
                class="nopadding"
                v-model="relatorio.regiao">
                    Verificar excessos com base na velocidade da REGIÃO
                </b-check>
            </div>
        </div>
    </slide-up-and-down>
    <div class="col-sm-12"><hr/></div>
    <template v-if="relResumidoSelecionado">
      <div class="col-sm-12">
        <tableSimples>
          <thead>
            <br>
            <tr>
              <th>Placa</th>
              <th>Quantidade de Excessos (contador)</th>
              <th>Maior velocidade atingida (do dia)</th>
            </tr>
          </thead>
          <tbody v-if="exibirRelatorioResumido">
            <template v-for="(dia, index) in dataRelResumido">
              <tr :key="`${index}__dataRelatorio`">
                <td class="tituloDia"
                    colspan="3">
                    <span class="placaExcesso"> {{ index }}</span>
                </td>
              </tr>
              <template v-for="occ in dia">
                <tr :key="`${index}_${occ.placa}__dataLinhaDia`">
                  <td>{{ `${occ.placa} | ${occ.veprefixo}`}}</td>
                  <td>{{ occ.contador }}</td>
                  <td>{{ occ.velocidade }}</td>
                </tr>
              </template>
            </template>
          </tbody>
          <tbody v-else>
            <statusBar :statusBar="infoRelResumido" colspanForTd="4" />
          </tbody>
        </tableSimples>
      </div>
    </template>
    <!-- Relatório detalhado -->
    <template v-else>
        <div class="col-sm-12">
            <tableSimples >
            <thead style="z-index: 999 !important">
                <br>
                <tr>
                    <th>Velocidade Máxima</th>
                    <th>Horário Inicio</th>
                    <th>Horário Fim</th>
                    <th>Distância</th>
                    <th>Tempo</th>
                </tr>
            </thead>
            <tbody v-if="exibirRelatorioCompleto">
                <template v-for="(item, placa) in dataRelDetalhado">
                <tr
                :key="`${placa}__placa`"
                class='linhaPlaca'>
                    <td colspan="5" class="tituloDia">
                        <span class="placaExcesso">{{ `${placa} | ${item[0].veprefixo}`}}</span>
                    </td>
                </tr>
                <template v-for="(dado, index) in item">
                    <tr 
                    :key="`${index}_${placa}`">
                    <td v-b-toggle="'collapse-' + placa + index"
                        @click='abreMapa(index, placa)'>
                        <b-icon
                        :icon="dado.open?'chevron-down':'chevron-right'"/>
                        {{ dado.maxVel }}
                    </td>
                    <td>{{ devolveHorario(dado.dataI) }}</td>
                    <td>{{ devolveHorario(dado.dataF) }}</td>
                    <td>
                        {{calculaDistancia(dado)}}
                    </td>
                  <td>{{ dado.distancia ? dado.distancia.tempo : ''}}</td>
                </tr>
                <tr
                :key="`${index}_${placa}_collapse_table`"
                class="collapse_table infoOc nopadding">
                  <td colspan="6" class="collapse_table nopadding">
                    <b-collapse
                    menu-class='collapse_table'
                    :lazy='true'
                    :id="'collapse-' + placa + index"
                    :key='`${index}_${placa}_collapse`'
                    class="collapse_table">
                      <table width="100%">
                        <thead>
                          <th class="tableCollapseLocal">Local</th>
                          <th class="tableCollapse">Horário</th>
                          <th class="tableCollapse">Região</th>
                          <th class="tableCollapse">Permitido</th>
                          <th class="tableCollapse">Atingido</th>
                          <th class="tableCollapse">Excedido</th>
                          <th class="tableCollapse">Porcentagem</th>
                          <th class="tableCollapse">Localizar</th>
                        </thead>
                        <tbody>
                            <tr
                            v-for="(oc, i) in dado.ocs"
                            :key="`${i}_${index}_${placa}`"
                            class="infoOc">
                                <td>{{ oc.bvendereco }}</td>
                                <td>{{ oc.bhora }}</td>
                                <td>{{ oc.redescricao }}</td>
                                <td>{{ oc.limitevel }}</td>
                                <td>{{ oc.bvvelocidade }}</td>
                                <td>{{ oc.gexcedido }}</td>
                                <td>{{ oc.porcentagem }}</td>
                                <td>
                                    <span
                                    class="botao"
                                    event="click"
                                    @click="linkGoogleMaps(oc)">
                                        <baseIcon
                                        size="18"
                                        class="cursor-pointer corIcone"
                                        :icon="mdiTarget"/>
                                    </span>
                                </td>
                            </tr>
                          <tr>
                            <td colspan="8">
                                <mapaSimples
                                height="250"
                                :ref="placa"
                                :bounds="dado.bounds"
                                initialZoom="16">
                                    <mapMarker
                                    v-for="(oc, i) in dado.ocs"
                                    :key="`${i}_${placa}_marker`"
                                    :latitude="Number(oc.lat)"
                                    :longitude="Number(oc.long)"
                                    :isDraggable="false"
                                    typeIcon="divIcon"
                                    :icon="decideIconeLinha(oc.bvvelocidade)"/>
                                </mapaSimples>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-collapse>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
          <tbody v-else>
            <statusBar :statusBar="infoRelDetalhado" />
          </tbody>
        </tableSimples>
      </div>
    </template>
  </panelEagle>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import {
    mdiCheckBold,
    mdiSpeedometer,
    mdiTarget } from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { DateTime } from 'luxon'
import { mapGetters } from 'vuex'
import SlideUpAndDown from '@/components/Atom/SlideUpAndDown/SlideUpAndDown.vue'
import SelectAll from '@/components/Atom/Select/SelectAll.vue'
import { conectionError } from '@/Services/Helpers/swellHeper'
export default {
    name: 'excessoVelocidade',
    components: {
        baseIcon:require('@/components/Atom/Icon/BaseIcon.vue').default,
        mapMarker: require('@/components/Atom/Mapa/MarkerPoint').default,
        mapaSimples: require('@/components/Atom/Mapa/MapaSimples').default,
        singleDay: require('@/components/Atom/Datas/InputSingleDay').default,
        statusBar: require(
            '@/components/Atom/StatusInformation/StatusInformation').default,
        tableSimples: require('@/components/Atom/Table/TableSimples').default,
        SelectAll,
        inputSimple: require('@/components/Atom/Inputs/InputSimple').default,
        interval: require(
            '@/components/Atom/Datas/InputRangeWithLimit').default,
        simpleButton: require('@/components/Atom/Buttons/SimpleButton').default,
        panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
        titulo: require('@/components/Atom/Header/Titulo').default,
        basicEIG: require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG')
            .default,
        SlideUpAndDown,
    },
    data() {
        return {
            firstSelected: 'V',
            mdiTarget:mdiTarget,
            mdiSpeedometer:mdiSpeedometer,
            iconCheck: mdiCheckBold,
            labelSeletorVeiculo: [
                { indexDFH: 'V', description: 'Veículos*' },
                { indexDFH: 'GV', description: 'Grupos*' },
                { indexDFH: 'TAG', description: 'Tags*' }
            ],
            labelSelectTrabalhador: [
                { indexDFH: 'C', description: 'Colaborador' },
                { indexDFH: 'GC', description: 'Grupos' }
            ],
            titulosTable: [
                { key: 'velMax',  label: 'Velocidade Máxima' },
                { key: 'hInicio', label: 'Horário Inicio' },
                { key: 'hFim', 	  label: 'Horário Fim' },
                { key: 'di',      label: 'Distância' },
                { key: 't',       label: 'Tempo' }
            ],
            optSelectTrabalhador: [],
            relResumidoSelecionado: false,
            optSelectEmpresa: new EmpresasService().Get(),
            optSelectVeiculo: [],
            infoRelResumido: 'info',
            infoRelDetalhado: 'info',
            carregandoVGV: false,
            clientesVGV: [],
            dataRelResumido: [],
            relatorio: {
                clientes: [],
                veiculos: [],
                motoristas: [],
                tipoV: '',
                tipoM: '',
                velocidade: '',
                regiao: false,
                dataDetalhado: '',
                dataResumido: '',
                horaInicial: '',
                horaFinal: ''
            },
            dataRelDetalhado: [],
            gerandoRel: false,
            arrLoading: [false, false, false],
            selectedEmpresa: []
        };
    },
    validations: {
        relatorio: {
            clientes: { required },
        },
        dataRelResumido: { required },
        dataRelDetalhado : { required }
    },
    methods: {
        /**
         * @param {object} 			 obj
         * @param {object} 			 obj.distancia
         * @param {number|undefined} obj.distancia.distancia
         * @description retorna a distância, se for maior que 1000, transforma
         * em km, caso não exista um distância, devolve como 0
         * @return {string} distância no formato certo.
         */
        calculaDistancia({distancia}){
            var valor_distancia = distancia?.distancia ?? 0
            if(valor_distancia > 1000){
                valor_distancia = (valor_distancia/1000).toFixed(2)
                valor_distancia+='km'
            }else{
                valor_distancia+='m'
            }
            return valor_distancia
        },
        ...mapGetters(['getMaster']),

        /** Quando a sanfona que contém o mapa é aberta 
         * essa função marca a aba como aberta(para importação) e
         * "avisa" o mapa que o tamanho dele foi alterado, para ele
         * validar o novo tamanho e renderizar certo
        */
        abreMapa(index, placa){
            this.dataRelDetalhado[placa][index].open =
                !this.dataRelDetalhado[placa][index].open
            this.$nextTick(function(){
                this.$refs[placa][index].validateSize()
            })
        },

        /**
         * Envia as informações no molde do sistema antigo
         * para fazer a exportação
         */
        exportarRelatorioResumido(tipo) {
            this.loadingExportar(tipo);
            let url = '/telemetria/relatorios/excesso/velocidade/exportar';
            let obj = {
                tipo: tipo,
                relatorio: this.relResumidoSelecionado? 'R': 'D',
                titulo: '4',
                data: this.relResumidoSelecionado ?
                    this.relatorio.dataResumido : 
                    this.relatorio.dataDetalhado,
                colunas: this.relResumidoSelecionado? 4 : 5,  
                arrayDados: this.relResumidoSelecionado?
                    this.dataRelResumido: this.dataRelDetalhado,
            };
            new HttpRequest().Post(url, obj)
            .then((data) => {
                var root = process.env.VUE_APP_ROOT;
                window.open(root + '/' + data.data.dados);
            }).catch(() => {
                conectionError()
            }).finally(() => this.loadingExportar())
        },

        /**
         * Decide qual dos botões (se algum)
         * vai ficar com o spinner de loading
         */
        loadingExportar(tipo = false) {
            if (tipo === 'pdf') this.arrLoading = [true, false, false]
            if (tipo === 'xls') this.arrLoading = [false, true, false]
            if (tipo === 'csv') this.arrLoading = [false, false, true]
            if (!tipo) this.arrLoading =          [false, false, false]
        },

        /**
         * Faz a request que traz as informações que compoem o
         * relatório.
         * A mesma função é responsavel por criar tanto rel resumido
         * quanto o detalhado, a url é alterada de acordo com o tipo
         * selecionado e, o obj enviado pelo back-end é decido por
         * uma propriedade computada.
         */
        gerarRelatorio() {
            this.dataRelResumido = this.dataRelDetalhado = []
            this.gerandoRel = true
            let url = `/telemetria/relatorios/excesso/velocidade/${
                this.relResumidoSelecionado ? 'resumido' : 'completo'
            }`
            new HttpRequest().Post(url, this.objGerarRelatorio).then((data)=>{
                if(data.status){
                    if(this.relResumidoSelecionado){
                        this.dataRelResumido = data.data.arrayDados
                    }else{
                        this.dataRelDetalhado = data.data.arrayDados
                        if(data.data.length == 0 || this.dataRelDetalhado.length == 0)
                            this.infoRelDetalhado = 'error'
                    }
                }else{
                    conectionError()
                    this.infoRelDetalhado = 'error2'
                }
            }).finally(()=>this.gerandoRel = false)
        },

        /**
         * Alterna entre relatório resumido e detalhado
         */
        mudaFormatoRel() {
            this.infoRelResumido = 'info'
            this.infoRelDetalhado = 'info'
            this.relResumidoSelecionado = !this.relResumidoSelecionado;
        },

        /**
         * Da um clearAll em todos os seletores na página
         */
        clearAllRefs(clearEmpresaDetalhado = true, clearEmpresaResumido = true){
            if (this.$refs.EmpresaDetalhado && clearEmpresaDetalhado)
                this.$refs.EmpresaDetalhado.clearAll();
            if (this.$refs.EmpresaResumido && clearEmpresaResumido)
                this.$refs.EmpresaResumido.clearAll();
            if (this.$refs.seletorVeiculoDetalhado)
                this.$refs.seletorVeiculoDetalhado.clearAll();
            if (this.$refs.seletorTrabalhadorDetalhado)
                this.$refs.seletorTrabalhadorDetalhado.clearAll();
            if (this.$refs.seletorVeiculoResumido)
                this.$refs.seletorVeiculoResumido.clearAll();
        },

        /*
        * Muda empresa selecionada, mas no caminho já limpa todas
        * as informações impactadas por isso e também.
        * Em seguida faz uma request para alimentar o seletor de veículos
        * com os dados da empresa relevante
        */
        mudaEmpresaSelecionada(value, clearDetalhado, clearResumido) {
            if(this.relatorio.tipoV != 'TAG')
                this.$refs.seletorVeiculoResumido.clearAll() 
            if(this.$refs.seletorTrabalhadorDetalhado)
                this.$refs.seletorTrabalhadorDetalhado.clearAll()
            this.clientesVGV = []
            this.optSelectVeiculo = []
            this.optSelectTrabalhador = []
            this.relatorio.clientes = value
            this.dataRelResumido = []
            this.dataRelDetalhado = []
            this.infoRelDetalhado = 'info'
            if (value.length) {
                this.carregandoVGV = true
                new FiltrosService()
                    .dados_filtros(value, ['V', 'GV', 'C', 'GC', 'TAG'])
                    .then((data) => {
                        this.clientesVGV = data;
                        this.optSelectVeiculo =
                            this.clientesVGV[this.relatorio.tipoV]
                        this.firstSelected = this.relatorio.tipoV
                        this.optSelectTrabalhador =
                            this.clientesVGV[this.relatorio.tipoM]
                            
                        this.carregandoVGV = false
                    });
            }
        },

        linkGoogleMaps(occ) {
            window.open(`https://www.google.com/maps/place/${occ.lat},${occ.long}`);
        },

        mudaVeiculoSelecionado(value) {
            this.dataRelResumido = []
            this.dataRelDetalhado = []
            this.infoRelDetalhado = 'info'
            this.relatorio.veiculos = value
        },

        /**
         * Verifica se já existem informaçãos para alimentar
         * o seletor antes de tentar, evitando erro de indefinido,
         * só no caso do usuário clicar primeiro no check antes de selecionar
         * uma empresa
         */
        mudaCheckVeiculo(value) {
            this.firstSelected = value
            this.relatorio.tipoV = value
            if(Array.isArray(this.clientesVGV[value]))
                this.optSelectVeiculo = this.clientesVGV[this.relatorio.tipoV]
        },

        mudaPeriodo(value){
            this.dataRelResumido = []
            this.dataRelDetalhado = []
            this.infoRelDetalhado = 'info'
            this.relatorio.dataResumido = value
        },

        mudaVelAcima(value) {
            this.dataRelResumido = []
            this.dataRelDetalhado = []
            this.infoRelDetalhado = 'info'
            this.relatorio.velocidade = value
        },

        /**RELATORIO DETALHADO */

        mudaDataRelatorioDetalhado(value) {
            this.dataRelResumido = []
            this.dataRelDetalhado = []
            this.infoRelDetalhado = 'info'
            this.relatorio.dataDetalhado = value
        },

        mudaTrabalhador(value) {
            this.dataRelResumido = []
            this.dataRelDetalhado = []
            this.infoRelDetalhado = 'info'
            this.relatorio.motoristas = value
        },

        mudaHoraInicial(value) {
            this.dataRelResumido = []
            this.dataRelDetalhado = []
            this.infoRelDetalhado = 'info'
            this.relatorio.horaInicial = value
        },

        mudaHoraFinal(value) {
            this.dataRelResumido = []
            this.dataRelDetalhado = []
            this.infoRelDetalhado = 'info'
            this.relatorio.horaFinal = value
        },

        mudaCheckTrabalhador(value) {
            this.relatorio.tipoM = value;
            if (this.clientesVGV[this.relatorio.tipoM])
                this.optSelectTrabalhador =
                    this.clientesVGV[this.relatorio.tipoM]
        },

        devolveHorario(data) {
            return DateTime
                .fromFormat(data, 'dd/MM/yyyy HH:mm:ss')
                .toFormat('HH:mm:ss')
        },

        /**
         * Cria o marcador que mostra a velocidade atingida pelo motorista 
         * no mapa
         */
        decideIconeLinha(vel) {
            return `<div class='marker-circle'>
                    <i>
                        <div class='centroMarker'>
                            ${vel}
                        </div>
                    </i>
                </div>
                `
        },
    },
    computed: {
        /**
         * Define o objeto a ser enviado para o back-end
         * de acordo com o tipo de relatório escolhido
         */
        objGerarRelatorio() {
            let obj = {}
            if (this.relResumidoSelecionado) {
                obj = {
                    clientes: this.relatorio.clientes,
                    data: this.relatorio.dataResumido,
                    veiculos: this.relatorio.veiculos,
                    tipoV: this.relatorio.tipoV,
                    velocidade: this.relatorio.velocidade,
                }
            } else {
                obj = {
                    cli: this.relatorio.clientes,
                    velocidade: this.relatorio.velocidade,
                    ini: this.relatorio.dataDetalhado
                        + ' ' + this.relatorio.horaInicial,
                    fim: this.relatorio.dataDetalhado
                        + ' ' + this.relatorio.horaFinal,
                    tipoV: this.relatorio.tipoV,
                    veiculo: this.relatorio.veiculos,
                    tipoM: this.relatorio.tipoM,
                    mot: this.relatorio.motoristas,
                    regiao: this.relatorio.regiao,
                }
            }
            return obj
        },

        /**Verifica se o dataRelDetalhado esta vazio */
        exibirRelatorioCompleto: function () {
            if (this.dataRelDetalhado === undefined) return false
            if (Object.keys(this.dataRelDetalhado).length) return true
            else return false
        },

        /**Verifica se o dataRelResumido esta vazio */
        exibirRelatorioResumido(){
            if (this.dataRelResumido === undefined) return false
            if (Object.keys(this.dataRelResumido).length) return true
            return false
        },

        /** É necessário selecionar ALGO para gerar o relatório. 
         * Caso um motorista OU um veiculo tenha sido selecionado 
         * já é o suficiente para gerar o relatório.
         */
        veiculoOrMotorista(){
            if(this.relatorio.veiculos.length) return false
            if(this.relatorio.motoristas.length) return false
            return true
        }
    },
    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optSelectEmpresa
        }
    }
};
</script>
<style lang="scss">
#excessoDeVelocidade{
    tbody{
        .linhaPlaca{
            margin-top: 10px;
            background-color: #fff !important;
        }
    }
    .botaoTipoRel{
        height: 32px 
    }
    .infoOc {
        font-size: 12px;
        padding: 0 !important;
        background: #fff !important;
        td{
            padding-left: 5px;
            white-space: nowrap;
        }
    }
    .botao::before {
        font-size: 16px;
        cursor: pointer;
    }
    .div_botao {
        padding-top: 10px;
        transform: rotate(270deg);
    }
    .div_normal {
        padding-top: 10px;
        padding-right: -10px !important;
        transform: rotate(180deg);
    }
    .div_normal::after {
        padding: 20px;
    }
    .collapse_table {
        td{
            background-color: #fff !important;
        }
        background-color: #fff !important;
        border: none !important;
        margin: none !important;
        padding: none !important;
    }
    .check {
        font-size: 14px;
    }
    .table{
        tbody{
            tr.linhaPlaca{
                background: #fff !important;
            }
        }
        border-spacing: 40px;
    }
    .linha {
        padding: 0;
        margin-top: 2%;
    }
    .tituloDia{
        background: #fff;
        padding-left: 0px;
    }
    .placaExcesso{
        width: 300px;
        background-color: #777;
        padding-left: 0px;
        padding-right: auto;
        color: white;
        padding-top: 4x !important;
        font-size: 15px !important;
        text-align: center !important;
        margin-top: 15px;
        height: 30px;
        vertical-align: middle;
        display: table-cell;
    }
    td {
        text-align: left;
    }
    th {
        text-align: left;
    }
    
    .tableCollapse{
        border-bottom: 3px solid #e2e5e9;
        background: #fff;
    }
    .tableCollapseLocal{
        border-bottom: 3px solid #e2e5e9;
        background: #fff;
        width: 21%;
    }
    .marker-circle {
        width: 35px !important;
        height: 35px !important;
        border-radius: 50% !important;
        position: absolute !important;
        align-content: center !important;
        background: rgb(255, 0, 0) !important;
        margin: 4px 4px 4px 4px !important;
        right: 0 !important;
        margin: 10px auto !important;
        text-align: center !important;
    }
    .centroMarker{
        border-radius: 50% !important;
        background: white !important;
        margin: 5px 5px 5px 5px !important;
        padding: 0px;
        height: 25px;
        width: 25px;
        padding-top: 2px;
    }
    .corIcone{
        color: #2196f3;
    }
}
</style>
