<template>
    <eaglePanel id='ListagemGerenciadoras' :loading='gerandoLista'>
        <div class="col-sm-12 nopadding">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6 nopadding">
                    <titulo
                        titulo="Listagem de Gerenciadoras"
                        :icon='mdiOfficeBuildingMarker'
                    />
                    <!-- O icone correto não existe mais no pack -->
                </div>
                <div class="col-sm-6 nopadding divDontPrint">
                    <botoes
                        tipoNovo='button'
                        linkNew='cadastrarGr'
                        @exportarCadastro='exportarLista'
                        :loading='loadingExportar'
                    />
                </div>
            </div>
            <div class="col-sm-12 nopadding"><hr></div>
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6 nopadding">
                    <selectAll
                        nameForRadio='seletorGerenciadoras'
                        :labels='[{description:"Gerenciadoras"}]'
                        :isMultiple='true'
                        :optionsArray='optselectGerenciadoras'
                        :loading="loadingSelect"
                        @changeSelect='changeGerenciadoras'
                    />
                </div>
                <div class="col-sm-6 nopadding divDontPrint">
                    <filtros
                        label='Status'
                        :arrayButtons='botoesFiltroStatus'
                        @buttonsFiltersChange='mudaFiltroStatus'
                    />
                </div>
            </div>
            <div class="col-sm-12 nopadding"><hr></div>
            <div class="col-sm 12 nopadding">
                <tableLista
                    :titles='titulosListagem'
                    :data='dataListagem'
                    :habilitado='true'>
                    <template #acoes='{row}'>
                        <simpleButton
                            v-if='getEditar()'
                            title='Editar'
                            width='45%'
                            :icon='iconEditar'
                            type='green'
                            event='click'
                            @click='editarCliente(row.gecodigo)'
                        />
                        <simpleButton
                            v-if='row.gestatus == "A" && getEditar()'
                            title='Desativar'
                            width='45%'
                            type='red'
                            :icon='iconDesativar'
                            event='click'
                            @click='mudarStatusGerenciadora(row.gecodigo, "I")'
                        />
                        <simpleButton
                            v-else-if='getEditar()'
                            title='Ativar'
                            width='45%'
                            type='light-green'
                            :icon='iconAtivar'
                            event='click'
                            @click='mudarStatusGerenciadora(row.gecodigo, "A")'
                        />
                    </template>
                </tableLista>
            </div>
        </div>

    </eaglePanel>
</template>

<script>
import {mdiOfficeBuildingMarker } from '@mdi/js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { mapActions } from 'vuex'
import {mdiPencilOutline, mdiCircleOffOutline, mdiCheck, mdiCheckBold} from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import {mapGetters} from 'vuex'
import { FiltrosService } from '@/Services/filtros/filtros.Service'

export default {
    name:'ListagemGerenciadoras',
    components:{
        'eaglePanel'   : require("@/components/Atom/Panel/PanelEagle").default,
        'titulo'       : require('@/components/Atom/Header/Titulo').default,
        'botoes'       : require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
        'filtros'      : require('@/components/Atom/Buttons/ButtonsFilters').default,
        'tableLista'   : require('@/components/Atom/Table/TableListagem').default,
        'simpleButton' : require('@/components/Atom/Buttons/SimpleButton').default,
        'selectAll'    : require('@/components/Atom/Select/SelectAll').default,
    },
    data() {
        return {
            mdiOfficeBuildingMarker:mdiOfficeBuildingMarker,
            mdiCheckBold           : mdiCheckBold,
            iconEditar:mdiPencilOutline,
            iconDesativar: mdiCircleOffOutline,
            iconAtivar: mdiCheck,
            baseUri : '/administrativo/cadastros/gerenciadoras/',
            gerandoLista: false,
            dataListagem: [],
            optselectGerenciadoras: [],
            gerenciadoraSelecionada: [],
            gestatus: [],
            loadingSelect: false,
            loadingExportar:[false, false, false],
            botoesFiltroStatus:[
                {value: 'A', width: '32%', isSelected: true, text:'Ativo',    icon  :''},
                {value: 'I', width: '32%', isSelected: false, text:'Inativos', 'icon':''},
                {value: 'T', width: '32%', isSelected: false,  text:'Todos',    'icon':''},
            ],
            titulosListagem:[
                {name: 'Nome',     number: 'genomefantasia'},
                {name: 'CNPJ',     number: 'gecnpj'},
                {name: 'Endereço', number: 'geendereco'},
            ],
        }
    },
    methods: {
        ...mapGetters(['getEditar']),
        changeGerenciadoras(gerenciadoras){
            this.gerenciadoraSelecionada = gerenciadoras
            this.buscaGerenciadoras()
        },

        buscaGerenciadorasSelect(){
            this.loadingSelect = true
            new FiltrosService().dados_filtros([], ['G'])
                .then((dados)=>{
                    this.optselectGerenciadoras = dados.G
                    this.loadingSelect = false
                })
        },
        /**
         * Filtro de status, a razão pelo T é a mesma do filtro
         * de tipo de cliente
         */
        mudaFiltroStatus(status) {
            if(status[0] == 'T'){
                this.gestatus = ['I', 'A']
            } else {
                this.gestatus = status
            }
            this.botoesFiltroStatus[0].icon = ''
            this.botoesFiltroStatus[1].icon = ''
            this.botoesFiltroStatus[2].icon = ''
            if(status[0] == 'A'){
                this.botoesFiltroStatus[0].icon = mdiCheckBold
            } else if(status[0] == 'I'){
                this.botoesFiltroStatus[1].icon = mdiCheckBold
            } else if(status[0] == 'T'){
                this.botoesFiltroStatus[2].icon = mdiCheckBold
            }
            this.buscaGerenciadoras()
        },

        /**
         * Faz a busca pelas gerenciadoras que atendem aos filtros
         * estabelecidos
         */
        buscaGerenciadoras(){
            this.gerandoLista = true
            this.dataListagem = []
            let uri = this.baseUri+'listar'
            var obj = {
                'gerenciadoras' : this.gerenciadoraSelecionada,
                'status'        : this.gestatus
            }
            new HttpRequest().Post(uri, obj)
            .then((dados)=>{
                this.gerandoLista = false
                if(dados.status)
                    this.dataListagem = dados.data.dados
            })
        },
        editarCliente(codigo){
            this.$router.push({name:'cadastrarGr', params:{id:codigo}})
        },
        mudarStatusGerenciadora(codigo, status){
            this.gerandoLista = true
            let uri = this.baseUri+'status'
            new HttpRequest().Post(uri, {'codigo': codigo, 'status':status.toUpperCase()})
            .then((data)=>{
                if(data.data.success)
                    this.buscaGerenciadoras()
                this.gerandoLista = false
            })
        },
        /**
         * Faz a exportação dos dados listados
         */
        exportarLista(tipo){
            this.mudaLoading(tipo)
            let uri = this.baseUri+'exportar'
            var root =  process.env.VUE_APP_ROOT;
            let obj = {
                'tipo':tipo,
                'dados':this.dataListagem
            }
            new HttpRequest().Post(uri, obj)
            .then(data=>{
                this.mudaLoading()
                if(data.status)
                    open(root+'/'+data.data.local)
            })
        },
        mudaLoading(tipo=false){
            switch(tipo){
                case 'pdf':
                    this.loadingExportar = [true, false, false]
                    return
                case 'xls':
                    this.loadingExportar = [false, true, false]
                    return
                case 'csv':
                    this.loadingExportar = [false, false, true]
                    return
                default:
                    this.loadingExportar = [false, false, false]
                    return
            }
        },
        ...mapActions(['refresh'])
    },
    mounted () {
        this.buscaGerenciadorasSelect()
    },
}
</script>

<style lang="scss" scoped>

</style>