<template>
    <panelEagle 
        id="confirmacoesEscalasView"
        :loading="loadingPanel">
        <div class="col-sm-12 nopadding">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage 
                        :icon="mdiMapCheckOutline" 
                        titulo="Confirmações Escalas">
                    </tituloPage>
                </div>
                <div class="col-sm-6 divButtonsBasic divDontPrint">
                    <basicButtonsRelatoriosEIG
                        :disabledDropdown  ="!Object.keys(dadosConfirmacoes).length" 
                        @exportarRelatorio ="exportar" 
                        :loading           ="loadingExportar"
                        @gerarRelatorio    ="gerar"
                        :disabled          ="$v.selectedColabEsc.$invalid">
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12"><hr></div>
                    <div class="col-sm-12 justpaddingbottom row">
                        <div class="col-sm-2 nopadding">
                            <inputSingleDay 
                                @changeInput="changeInput" 
                                name="dataCancelamentos" 
                                :isObrigatorio="true">
                            </inputSingleDay>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll 
                                @changeSelect="changeEmpresa" 
                                :isMultiple="true" 
                                nameForRadio="empersasConfirmEscala" 
                                :hasSelectAll="true" 
                                :labels="[{indexDFH: 'EM', description: 'Empresas*'}]" 
                                :optionsArray="optionsArrayEmpresas" 
                                :selected="selectedEmpresa"
                                firstSelected="EM">
                            </selectAll>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll 
                                @selectCriado ="createdCE" 
                                @changeCheck  ="changeCheckCE" 
                                @changeSelect ="changeCE" 
                                :isMultiple   ="true" 
                                nameForRadio  ="ColabEscSelect" 
                                :hasSelectAll ="true" 
                                :labels       ="labelsColabEsc" 
                                :optionsArray ="optionsColabEsc" 
                                :loading      ="loading"
                                :disabled     ="$v.selectedEmpresas.$invalid"
                                firstSelected ="C">
                            </selectAll>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <subtitle :items="labelsSubtitle"></subtitle>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
            <div class="col-sm-12 divHrDeBaixo"><hr></div>
        </div>
        <!-- Relatório -->
        <tableRelatorio :htmlRel="''">
            <slot slot="thead">
                <tr>
                    <th>Turno</th>
                    <th>Placa | Prefixo</th>
                    <th>Colaborador</th>
                    <th>Horário de confirmação</th>
                    <th>Ações</th>
                </tr>
            </slot>
            <slot slot="tbody">
                <statusInformation 
                    v-if="tamanhoConfirmacoes == 0" 
                    :statusBar="statusBar">
                </statusInformation>
                <template v-else-if="tamanhoConfirmacoes > 0" 
                        v-for="(turnos, escala) in dadosConfirmacoes">
                    <tr class="backColorTdPDF" 
                        :key="escala+'_escala'">
                        <td class="placa-relatorio nopadding" 
                            colspan="5">
                            <span class="newbadge">
                                {{escala}}
                            </span>
                        </td>
                    </tr>
                    <tr v-for="(dados, d) in turnos"
                        :key="dados.escodigo+'_dados'+d"
                        :class="dados.horario ? 'confirmada dadosTabela' : 'pendente dadosTabela'">
                        <td class="alinhar">
                            {{dados.turno}}
                        </td>
                        <td class="alinhar">
                            {{dados.veiculo ? 
                                dados.veiculo+" | "+dados.prefixo :
                                 ''}}
                        </td>
                        <td class="alinhar">
                            {{dados.motorista}}
                        </td>
                        <td class="alinhar">
                            {{transformaData(dados.horario)}}
                        </td>
                        <td class="alinhar">
                            <template v-if="getCadastrar()">
                                <simpleButton
                                    v-if="!dados.horario"
                                    title="Confirmar escala"
                                    :icon="mdiCheckBold"
                                    type="blue"
                                    event="click"
                                    @click="
                                        modalConfirmarEscala(
                                            'modal-confirmaEscala-', 
                                            dados.esmotorista, 
                                            dados.edturno, 
                                            dados.escodigo, 
                                            dados.escliente, 
                                            dados.esdescricao, 
                                            dados.motorista
                                        )">
                                </simpleButton>
                            </template>
                        </td>
                    </tr>
                </template>
            </slot>
        </tableRelatorio>
        <!-- Modal Confirmar escala -->
        <modalEagle
            :message="messageModal"
            @confirmButton="confirmRota('modal-confirmaEscala-')" 
            @modalOpen="getInfoDetalhes" 
            title="Confirmar Escala" 
            :id="'modal-confirmaEscala-'">
            <slot slot="modalBody">
                <b-overlay :show="loadingPanel">
                    <div class="col-sm-12 nopadding row">
                        <div class="col-sm-6 marginModalConfirmarEscala">
                            Escala: {{escalaModalConfirmarEscala.length > 0 ?
                                escalaModalConfirmarEscala[0].esdescricao : ''}}
                        </div>
                        <div v-if="statusMessageAtencao || messageAssociar"
                            class="col-sm-12 mensagemAtencao">
                            <span>{{statusMessageAtencao ? statusMessageAtencao : messageAssociar}}</span>
                        </div>
                        <!-- Aparece somente se tiver condutor na escala -->
                        <div v-if="statusMessageAtencao == '' && messageAssociar== ''"
                            class="col-sm-6 marginModalConfirmarEscala">
                            Motorista: {{escalaModalConfirmarEscala.length > 0 ? 
                                escalaModalConfirmarEscala[0].mtnome : ''}}
                        </div>
                        <div class="col-sm-6 nopadding marginModalConfirmarEscala">
                            <selectAll 
                                @changeSelect="changeVeiculo" 
                                :isMultiple="false" 
                                nameForRadio="veiculosModalCancel" 
                                :hasSelectAll="false" 
                                :labels="[{indexDFH: 'V', description: 'Veículo*'}]" 
                                :optionsArray="optionsVeiculosModal" 
                                :loading="loading"
                                firstSelected="V">
                            </selectAll>
                        </div>
                        <!-- Só aparece caso o condutor esteja associado a outra 
                            escala ou não tenha condutor nesta escala -->
                        <div v-if="statusMessageAtencao || messageAssociar"
                            class="col-sm-6 nopadding marginModalConfirmarEscala">
                            <selectAll 
                                @changeSelect="changeMotorista" 
                                :isMultiple="false" 
                                nameForRadio="motoristaModalCancel" 
                                :hasSelectAll="false" 
                                :labels="[{indexDFH: 'M', description: 'Motorista*'}]" 
                                :optionsArray="optionsColaboradoresModal" 
                                :loading="loading"
                                firstSelected="M">
                            </selectAll>
                        </div>
                        <tableRelatorio>
                            <slot slot="thead">
                                <tr>
                                    <th>Linha</th>
                                    <th>Horário</th>
                                </tr>
                            </slot>
                            <slot slot="tbody">
                                <br>
                                <tr v-for="(dados, l) in escalaModalConfirmarEscala"
                                    :key="l">
                                    <td class="dadosTabelaModal">{{dados.lidescricao}}</td>
                                    <td class="dadosTabelaModal">{{dados.hrhorario}}</td>
                                </tr>
                            </slot>
                        </tableRelatorio>
                    </div>
                </b-overlay>
            </slot>
            <slot slot="modalFooter">
                <span :class="'spanStatus status-'+status">{{statusMessage}}</span>
                <simpleButton
                    :icon="mdiCloseThick" 
                    @click="()=>{$bvModal.hide('modal-confirmaEscala-')}" 
                    text="Cancelar" 
                    event="click" 
                    type="red">
                </simpleButton>
                <simpleButton 
                    :disabled="$v.selectedVeiculos.$invalid"
                    :icon="mdiCheckBold" 
                    @click="confirmRota('modal-confirmaEscala-')" 
                    text="Confirmar" 
                    event="click" 
                    type="blue">
                </simpleButton>
            </slot>
        </modalEagle>
    </panelEagle>
</template>

<script>
import {mdiMapCheckOutline, mdiCheckBold, mdiCloseThick } from '@mdi/js'
import Vue               from 'vue'
import { required }      from 'vuelidate/lib/validators';
import { DateTime }      from 'luxon';
import {EmpresasService} from '../../../Services/auth/Empresas.service'
import {HttpRequest}     from '../../../Services/auth/HttpRequest.Service'
import {FiltrosService}  from '../../../Services/filtros/filtros.Service'
import { mapGetters } from 'vuex';
export default Vue.extend({
	name:'ConfirmacoesEscalas',
	components:{
		'panelEagle'                : require('@/components/Atom/Panel/PanelEagle').default,
		'tituloPage'                : require('@/components/Atom/Header/Titulo').default,
		'basicButtonsRelatoriosEIG' : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
		'slideUpAndDown'            : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
		'inputSingleDay'            : require('@/components/Atom/Datas/InputSingleDay').default,
		'selectAll'                 : require('@/components/Atom/Select/SelectAll').default,
		'subtitle'                  : require('@/components/Atom/Subtitle/Subtitle').default,
		'tableRelatorio'            : require('@/components/Atom/Table/TableRelatorio').default,
		'statusInformation'         : require('@/components/Atom/StatusInformation/StatusInformation').default,
		'simpleButton'              : require('@/components/Atom/Buttons/SimpleButton').default,
		'modalEagle'                : require('@/components/Atom/Modal/ModalEagle').default,
		// 'confirmacoesEscalasLine': require('@/components/Atom/SpecificComponents/ConfirmacoesEscalasLine').default,
	},

	validations: {

		selectedEmpresas:{
			required
		},

		selectedColabEsc:{
			required
		},

		selectedVeiculos: {
			required
		},

		condutorSelecionado: {
			required
		},

	},

	data(){
		return{
			messageModal:'',
			mdiCheckBold:mdiCheckBold,
			mdiCloseThick:mdiCloseThick,
			mdiMapCheckOutline:mdiMapCheckOutline,
			dataDia              : '',
			dataConfirmacao      : '',
			selectedEmpresas     : [],
			selectColabEsc       : [],
			optionsArrayEmpresas : new EmpresasService().Get(),
			arrayOptionsColabEsc : [],
			optionsColabEsc      : [],
			selectedColabEsc     : [],
			radiosSelectedCE     : '',
			labelsColabEsc       :[
				{indexDFH: 'C', description: 'Colaborador'},
				{indexDFH: 'E', description: 'Escala'},
			],
			labelsSubtitle:[
				{'description': 'Pendente', 'spanColor': 'red'},
				{'description': 'Confirmada', 'spanColor': 'light-green'}
			],
			statusBar            : 'info',
			tamanhoConfirmacoes  : 0,
			dadosConfirmacoes    : [],
			loadingPanel         : false,
			loadingExportar      : [false, false, false],

            //Modal
            dadosModalConfirmarEscala  : [],
            escalaModalConfirmarEscala : [],
            optionsVeiculosModal       : [],
            selectedVeiculos           : '',
            htmlTableLinhas            : '',
            isLoading                  : false,
            statusMessage              : '',
            status                     : '',
            loading                    : false,
            statusMessageAtencao       : '',
            messageAssociar            : '',
            optionsColaboradoresModal  : [],
            selectedEmpresa            : [],
        }
    },
    methods:{
        ...mapGetters(['getCadastrar', 'getMaster']),
        changeInput(data){
            this.dataDia = data;
        },
        changeEmpresa(empresa){
            this.selectedEmpresas = empresa;
            this.selectColabEsc.clearAll();
            this.dados_filtros();
        },
        createdCE(select){
            this.selectColabEsc = select;
        },
        changeCheckCE(check){
            this.radiosSelectedCE = check;
            if(this.arrayOptionsColabEsc.length != 0){
                this.optionsColabEsc = this.arrayOptionsColabEsc[check];
            }
        },
        changeCE(selecionados){
            this.selectedColabEsc = selecionados;
        },
        async dados_filtros(){
            this.loading = true
            this.arrayOptionsColabEsc = await new FiltrosService().dados_filtros(this.selectedEmpresas, ['C', 'E']);
            this.changeCheckCE(this.radiosSelectedCE);
            this.loading = false
        },
        async gerar(){
            this.loadingPanel = true
            if(this.dataDia.length > 0 && this.selectedEmpresas.length > 0){
                var data = DateTime.fromFormat(String(this.dataDia), 'dd/LL/yyyy')
                var dataDia = data.toFormat('E');
                this.tamanhoConfirmacoes = 0;
                this.dadosConfirmacoes = [];
                var dados = await new HttpRequest().Post('/coletivos/escalas/confirmar/gerar/confirmacao', 
                    {'motoOuEscala': this.selectedColabEsc, 
                     'data': dataDia, 
                     'dia': this.dataDia, 
                     'tipobusca': this.radiosSelectedCE, 
                     'cli': this.selectedEmpresas})
                this.tamanhoConfirmacoes = dados.data.tamanho;
                if(this.tamanhoConfirmacoes > 0){
                    this.dataConfirmacao = this.dataDia;
                    this.dadosConfirmacoes = dados.data.escalas;
                }else{
                    this.statusBar = 'error';
                }
            }
            this.loadingPanel = false
        },
        async exportar(type){
            var root =  process.env.VUE_APP_ROOT;
            if(this.tamanhoConfirmacoes > 0){
                switch(type){
                    case 'pdf':
                        this.loadingExportar = [true, false, false]
                        break;
                    case 'xls':
                        this.loadingExportar = [false, true, false]
                        break;
                    case 'csv':
                        this.loadingExportar = [false, false, true]
                        break;
                    default:
                        break;
                }
                var data = await new HttpRequest().Post('/coletivos/escalas/confirmar/exportar/confirmacao', 
                    {'arrayDados': this.dadosConfirmacoes, 
                     'tipo': type, 
                     'data': this.dataConfirmacao})
                this.loadingExportar = [false, false, false]
                window.open(root+'/'+data.data.local)
            }
        },

		/** 
         * Método para abrir o modal e inserir informações na variável dadosModalConfirmarEscala
         * @param array 
         * namemodal   = nome do modal
         * esmotorista = código do motorista
         * edturno     = primeiro ou segundo turno
         * escodigo    = código da escala
         * escliente   = código do cliente
         * esdescricao = nome da escala
         * motorista   = nome do condutor
         * @return String com as escalas(descricao, condutor e linhas associadas), motoristas confirmados 
         * e não confirmados
         * @author Vitor Hugo
         */
		modalConfirmarEscala(nameModal, esmotorista, edturno, escodigo, escliente, esdescricao, motorista){
			this.status = ''
			this.statusMessage = ''
			this.dadosModalConfirmarEscala = []
			this.dadosModalConfirmarEscala.push(
				{'esmotorista' : esmotorista, 
					'edturno'     : edturno, 
					'escodigo'    : escodigo,
					'escliente'   : escliente,
					'esdescricao' : esdescricao,
					'motorista'   : motorista,})
			this.statusMessageAtencao = ''
			this.messageAssociar      = ''
			this.condutorSelecionado  = []
			this.selectedVeiculos     = []
			this.$bvModal.show(nameModal)
		},
		changeVeiculo(veiculos){
			this.statusMessage = ''
			this.selectedVeiculos = veiculos[0];
		},
		changeMotorista(motorista){
			this.condutorSelecionado = motorista
		},

		/** 
         * Método para gerar o modal de confirmar escala
         * @param array dados da linha da tebela selecionada (cliente, codigo, turno) e data selecionada no input
         * @return String com as escalas(descricao, condutor e linhas associadas), motoristas confirmados 
         * e não confirmados
         * @author Aparentemente o Raul e alterado pelo Vitor
         */
		async getInfoDetalhes(){
			this.loadingPanel = true
			this.getVeiculosColab(this.selectedEmpresas)
			this.escalaModalConfirmarEscala = []
			this.motoristasConfirmados      = []
			this.motoristasNaoConfirmados   = []
			var data = DateTime.fromFormat(String(this.dataDia), 'dd/LL/yyyy')
			var dataDia = data.toFormat('E');
			var dados = await new HttpRequest().Post('/coletivos/escalas/confirmar/info/veiculos',
				{'escliente': this.dadosModalConfirmarEscala[0].escliente, 
					'data': dataDia, 
					'escodigo': this.dadosModalConfirmarEscala[0].escodigo, 
					'turno': this.dadosModalConfirmarEscala[0].edturno, 
					'dia': this.dataDia});
			if(dados){
				this.escalaModalConfirmarEscala = dados.data.escalasDetalhe;
				this.motoristasConfirmados      = dados.data.motoristasConfirmados
				this.motoristasNaoConfirmados   = dados.data.motoristasNaoConfirmados
			}
			this.verificaMotoristasConfirmados()
			this.loadingPanel = false
		},

		async getVeiculosColab(arr){
			this.loading = true
			this.optionsVeiculosModal      = []
			this.optionsColaboradoresModal = []
			var dados = [];
			dados = await new FiltrosService().dados_filtros(arr, ['V', 'C']);
			if(dados){
				this.optionsVeiculosModal      = dados.V
				this.optionsColaboradoresModal = dados.C
			}
			this.loading = false
		},

		/** 
         * Método para verificar se o condutor já esta confirmado em outra escala
         * @param array motoristas confirmados vindo do back e o motorista selecionado da tabela
         * @return String com a mendagem de atenção 
         * @author Vitor Hugo
         */
		verificaMotoristasConfirmados(){
			this.motoristasConfirmados.forEach(element => {
				if(element.mtcodigo == this.escalaModalConfirmarEscala[0].esmotorista){
					this.statusMessageAtencao = 'ATENÇÃO: O motorista '+
                        this.escalaModalConfirmarEscala[0].mtnome+' já realizou confirmação em outra escala.'+
                        'Para confirmar a escala é preciso selecionar um novo motorista!'
				}
			});
			if(this.statusMessageAtencao){
				for (let index = 0; index < this.optionsColaboradoresModal.length; index++) {
					if(this.optionsColaboradoresModal[index].value == this.escalaModalConfirmarEscala[0].esmotorista){
						this.optionsColaboradoresModal.splice(index, 1)
					}
				}
			} else if(this.escalaModalConfirmarEscala[0].esmotorista == null){
				this.messageAssociar = 'Associe um motorista a essa escala!'
			}
		},

		/** 
         * Método para confirmar escala
         * @param array dadosModalConfirmarEscala(turno, código escala) condutor e veículo
         * @return Mensagem de sucesso ou erro
         * @author Provável que o Raul, alterado pelo Vitor
         */
		async confirmRota(nameModal){
			this.loadingPanel = true
			this.statusMessage = ''
			if(this.selectedVeiculos != ''){
				this.isLoading = true;
				if(this.condutorSelecionado.length == 0){
					this.condutorSelecionado.push(this.dadosModalConfirmarEscala[0].esmotorista)
				}
				var parametros = {
					'numeroMotorista': this.condutorSelecionado[0],
					'numeroTurno': this.dadosModalConfirmarEscala[0].edturno,
					'numeroEscala': this.dadosModalConfirmarEscala[0].escodigo,
					'numeroCarro': this.selectedVeiculos,
					'flag': true,
					'tipo': 'V',
					'turno': this.dadosModalConfirmarEscala[0].edturno,
					'valor': this.selectedVeiculos[0],
					'motorista': this.condutorSelecionado,
                    'data' : this.dataDia,
				}
				try {
					var dados = await new HttpRequest().Post('/coletivos/escalas/confirmar/confirmar/rota', parametros);
					this.isLoading = false;
					this.statusMessage = dados.data.message;
					this.status = dados.data.status
					var modal = this;
					this.gerar()
					setTimeout(function(){
						modal.$bvModal.hide(nameModal)
					}, 500)
				} catch {
					this.statusMessage = 'Não foi possível confirmar a rota'
					// setTimeout(function(){
					//     this.messageModal = ''
					// }, 1000)
					// this.loadingPanel = false
				}
			}
			this.loadingPanel = false
		},

        transformaData(data){
            if(data != null)
                return DateTime.fromFormat(data, 'yyyy-LL-dd HH:mm:ss').toFormat('dd/MM/yyyy HH:mm:ss')
            else 
                return ''
        }
	},

    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optionsArrayEmpresas
        }
    }
})
</script>

<style lang="scss">
    .spanStatus{
        color: red;
    }
    #confirmacoesEscalasView{
        .dadosTabela{
            td{ 
                border: 1px solid #f5f5f5;
                height: 40px;
            }
        }
        .trRed{
            background: #f0a7a6 !important;
        }

        .trGreen{
            background: #b5cd8e !important;
        }

        .pendente{
            background: #f0a7a6
        }

        .confirmada{
            background: #b5cd8e;
            vertical-align: middle;
        }

        .alinhar{
            vertical-align: middle;
        }

    }
        .dadosTabelaModal{
            border: 1px solid #ebebeb;
            background: #fff !important;
        }
        .mensagemAtencao{
            color: red;
            margin-top: 5px;
            margin-bottom: 5px;
            font-weight: 600;
        }

        .marginModalConfirmarEscala{
            margin-top: 5px;
            margin-bottom: 5px;
        }
</style>