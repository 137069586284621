/**
    Este é o componente básico com os botões de exportar, imprimir, novo.
    -> Propriedades:
        1- linkNew - String - rota para a página do novo item - EX: 'administrativos/cadastros/usuarios/novo' ou nome da rota que o botão vai dar push
        2- loading - Array Boolean - para aparecer o spinner ao lado da exportação -Ex.: Exportar PDF= [true, false, false]
        3- tipoNovo - String - para que o botão de novo não funcione como ancora quando necessário
        4- disabledDropdown - Boolean - para desabilitar o botão de exportar - EX: disabledDropdown="true"

    -> Slot:
        1- Sem Nome: espaço para colocar algo a mais
    -> Eventos:
        1- novoCadastro - Emite quando o botão de novo for clicado - Sem parametros a mais
        2- exportarCadastro - Emite quando um dos botões de exportação for clicado - Parametros:
            2.1- type - Tipo de exportação escolhida
 */
<template>
    <div align="right" :class="disabledDropdown ? 'col-sm-12 nopadding' : 'hoverDropdown col-sm-12 nopadding'">
        <slot></slot>
        <b-dropdown 
        ref='testePdf' 
        size="sm" 
        class="teste" 
        variant="primary"
        :disabled="disabledDropdown">
            <template v-slot:button-content>
                <baseIcon 
                :icon='iconExportar' 
                size='18' /> Exportar
            </template>
            <b-dropdown-item
                :disabled="loading.find(v => v == true)"
                class="exportPdf" 
                @click="ExportClick('pdf')">
                <b-spinner v-if='loading[0]' small/>
                <baseIcon  v-else :icon='iconPdf' size='18'/>
                PDF
            </b-dropdown-item>
            <b-dropdown-item
                :disabled="loading.find(v => v == true)"
                class="exportXls" 
                @click="ExportClick('xls')">
                <b-spinner  v-if='loading[1]' small/> 
                <baseIcon  v-else :icon='iconXls'  size='18'/> 
                Excel
            </b-dropdown-item>
            <b-dropdown-item 
                :disabled="loading.find(v => v == true)"
                class="exportCsv" 
                @click="ExportClick('csv')">
                <b-spinner  v-if='loading[2]' small/>
                <baseIcon  v-else :icon='iconCsv' size='18'/> 
                CSV
            </b-dropdown-item>
        </b-dropdown>
        <simpleButton  
            text="Imprimir" 
            :icon="iconPrint" 
            type="light-blue" 
            id="btnImprimir" 
            event="imprimir" 
            @imprimir="ImprimirClick"/>
        <slot name='novo'>
            <simpleButton 
                v-if="cadastrarVal && usuAdmVal" 
                :typeButton="tipoNovo" 
                :link="linkNew" 
                text="Novo"
                :icon="iconNovo" 
                type="green" 
                id="btnNovo" 
                event="novo" 
                @novo="NovoClick"/>
        </slot>
        <slot name='right'></slot>
    </div>    
</template>
<script lang="ts">
import {mdiFileDelimitedOutline, mdiPlus, mdiPrinter, mdiFileReplaceOutline, mdiFilePdfBox, mdiFileExcelOutline} from '@mdi/js'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import Vuex from '../../../store/index'
export default Vue.extend({
	components: {
		simpleButton  : require('./SimpleButton').default,
		baseIcon      : require('@/components/Atom/Icon/BaseIcon.vue').default,
	},
	props:{
		linkNew:{
			type:String,
			default: '',
		},
		loading:{
			type: Array,
			default:function(){return Array(false, false, false)}
		},
		tipoNovo:{
			type: String,
			default: 'a'
		},
		disabledDropdown:{
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			iconPdf:mdiFilePdfBox,
			iconXls:mdiFileExcelOutline,
			iconCsv:mdiFileDelimitedOutline,
			iconNovo: mdiPlus, 
			iconPrint: mdiPrinter,
			iconExportar:mdiFileReplaceOutline,
		}
	},
	methods: {
    ...mapGetters(['getAdmCheckpoint']),
		NovoClick(){
			if(this.linkNew){
				this.$router.push({name:this.linkNew})
			}else{
				this.$emit('novoCadastro')
			}
		},
		ImprimirClick(){
			window.print();
		},
		ExportClick(type:string){
			this.$emit('exportarCadastro', type)
		}
	},
	computed:{
		cadastrarVal: function(){
			return Vuex.getters.getCadastrar;
		},

        /**
         * Essa computed verifica se o usuario esta na tela de cadastro
         * de user do checkpoint e verifica se ele tem a permissão de admin do
         * checkpoint
        */
        usuAdmVal(){
            if (this.linkNew == 'cadastrarUsuariosCP' &&  !this.getAdmCheckpoint())
                return false
            return true
		},
	}
})
</script>
<style lang="scss">
    .btn-group{
        .btn-primary{
            font-size  :12px !important;
            line-height: 20px !important;
            font-weight: 400 !important;
            padding: 4px 5px !important;
            text-align: center !important;
            white-space: nowrap !important;
            border-radius: 0px !important;
            border: 1px solid #fff !important;
            vertical-align: middle !important;
            display: inline-block !important;
            margin-bottom: 0 !important;
            touch-action: manipulation !important;
            user-select: none !important;
            background-image: none !important;
            height: 30.5px !important;
            top: 1px;
            width: 100px !important;
            background-color: #428BCA !important;
            span{
                &::before{
                    font-size:12px !important;
                    margin-left: 0px;
                }
            }
        }
        .dropdown-menu {
            padding: 0;
            border-radius: 0px;
            width: 100px !important;
            min-width: 100px;
            
            
            .dropdown-item{
                color: #22262e!important;
                font-size: 12px !important;
                border: 1px solid #d5d5d5;
                font-weight: normal !important;
                padding: 2px 10px;
                text-align: center;
                span{
                    &::before{
                        margin-left: 0px;
                    }
                }
                &:active{
                    background-color: #fff;
                }
            }
        }
    }
    .hoverDropdown{
        .dropdown:hover{
            .dropdown-menu {
                display: block;
            }
        }
    }
</style>