
/**
    Componente compoe a tabela de monitoramento de linhas, contém as informações do segundo SlideUpDown
 */
<template>
    <div class="col-sm-12 nopadding divMasterTimeline">
        <div class="col-sm-12 p-2 border" style="background-color: #eeeeee;">
            <div class="col-sm-12 d-flex justify-content-start align-items-center">
                <div class="col-sm-3 font-bold">
                    <baseIcon size='23' :icon="mdiCarBack" />
                    {{ arrayDados.placa }}
                </div>

                <div class="col-sm-2">
                    Turno:
                    <span class="font-bold">{{ arrayDados.turno }}</span>
                </div>

                <div class="col-sm-4">
                    Horário iniciado:
                    <span class="font-bold text-truncate" v-b-popover.hover.top="rotas.horarioIniciado">{{
                        arrayDados.horarioIniciado
                    }}</span>
                </div>

                <div class="col-sm-3 text-truncate">
                    Percorrido:
                    <span class="font-bold" v-b-popover.hover.top="rotas.percorrido + ' km'">
                        {{ arrayDados.percorrido }} km</span>
                </div>
            </div>

            <div class="col-sm-12 d-flex mt-3 justify-content-start align-items-center">
                <div class="col-sm-4 text-truncate">
                    Motorista:
                    <span class="font-bold" v-b-popover.hover.top="rotas.motorista">{{ arrayDados.motorista }}</span>
                </div>

                <div class="col-sm-4">
                    Tempo de execução:
                    <span class="font-bold text-truncate" v-b-popover.hover.top="rotas.tempoExecucao">
                        {{ arrayDados.tempoExecucao }}</span>
                </div>

                <div class="col-sm-4 d-flex justify-content-start align-items-center">
                    <div style="width: 25%">Status:</div>
                    <div style="width: 10%; user-select: none;">
                        <b-col
                            v-b-popover.hover.top="arrayDados.status == 'I' ? ('Iniciada') : (arrayDados.status == 'F' ? ('Finalizada') : (arrayDados.status == 'P' ? ('Pendente') : (arrayDados.status == 'C' ? ('Cancelada') : (''))))"
                            class="font-bold border border-warning-subtle mb-2 bolinhaAzul">
                            {{ arrayDados.status }}
                        </b-col>
                    </div>
                </div>
            </div>

            <div v-if="arrayDados.totalPontos >= 1" class="col-sm-12 timeLineTime mt-3">
                <!-- Posição que o veículo esta em relação aos pontos  -->
                <div class="col-sm-12 thisIsTheBusao">
                    <div :class="'col-sm-12 timeLineBusao statusPontoProgress-' + rotas.statusOnibus"
                        :style="'width:' + rotas.posicaoBusao + '%'">
                        <!-- mdiBusSide -->
                        <baseIcon size='45' class="iconBusao animeBusao" :icon="mdiBusSide" />
                    </div>
                </div>
                <!-- Ícones dos pontos -->
                <div class="col-sm-12 thisIsTimeLine" style="">
                    <ponto @pontoCriado="pontoCriado" :tamanho="(100 / rotas.totalPontos)"
                        :key="rotas.codigo + '|' + ponto.ponto" v-for="(  ponto  ) in   rotas.pontos  " :arrayDados="ponto">
                    </ponto>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mdiBusSide, mdiCarBack } from '@mdi/js'
import Vue from 'vue'

export default Vue.extend({
    name: 'MonitoramentoLinhaSeparada',

    components: {
        baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
        'ponto': require('./MonitoramentoLinhaPonto').default,
    },

    data() {
        return {
            mdiBusSide: mdiBusSide,
            mdiCarBack: mdiCarBack,
            actived: false,
            pontosCriados: 0,
            arrayPontos: [],
            rotas: this.arrayDados,
        }
    },

    props: {
        arrayDados: {
            type: Object,
            required: true,
            default: function () { return {} }
        },
    },

    methods: {
        pontoCriado(ponto) {
            if (this.pontosCriados == (this.rotas.totalPontos - 1)) {
                this.arrayPontos[ponto.arrayDados.ponto] = ponto;
                this.$emit('linhaSeparadaCriada', this)
            } else {
                this.arrayPontos[ponto.arrayDados.ponto] = ponto;
                this.pontosCriados++;
            }
        }
    },

    mounted() {
        this.$emit('linhaSeparada', this.rotas.codigo);
    },

    watch: {
        arrayDados() {
            this.rotas = this.arrayDados;
        }
    },
})
</script>

<style lang="css" scoped>
.font-bold {
    font-weight: bold;
}

.bolinhaAzul {
    background-color: #a6cfff;
    padding: 6px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    text-align: center;
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.5));
}
</style>
