/**
    Este componente é uma barra de status para relatorios

    -> Propriedades:
        1- typeBar - String - Tipo de barra a ser usada - EX: 'tr'/'div'
        2- statusBar - String - Status da barra a ser mostrada - EX: 'info'/'loading'/'error'
        3- colspanForTd - Number - Numero de cols que a barra irá usar por padrão 12 - EX: 1-12
        4- msgCentro - String - a mensagem que fica no centro quando o statusBar for info

    -> Slot:
        Sem slots

    -> Eventos:
        Sem eventos
 */

<template>
    <tr v-if="typeBar == 'tr'" id="statusInformation">
        <td :colspan="colspanForTd" v-if="statusBar == 'info'" class="statusRelatorio status-info">{{msgCentro}}</td>
        <td :colspan="colspanForTd" v-else-if="statusBar == 'loading'" class="statusRelatorio status-loading">
            <pulse-loader :loading="true" size="12px" color="#fff" data-test='pulse-loading'></pulse-loader>
            Carregando
        </td>
        <td :colspan="colspanForTd" v-else-if="statusBar == 'error'" class="statusRelatorio status-error">Nenhuma informação encontrada</td>
        <td :colspan="colspanForTd" v-else-if="statusBar == 'error2'" class="statusRelatorio status-error">Algum imprevisto aconteceu. Tente novamente mais tarde</td>
    </tr>
    <div v-else-if="typeBar == 'div'" class="nopadding" id="statusInformation" data-test='div-status'>
        <div v-if="statusBar == 'info'" class="col-sm-12 statusRelatorio status-info">{{msgCentro}}</div>
        <div v-else-if="statusBar == 'loading'" class="col-sm-12 statusRelatorio status-loading">
            <pulse-loader :loading="true" size="12px" color="#fff"></pulse-loader>
            Carregando
        </div>
        <div v-else-if="statusBar == 'error'" class="col-sm-12 statusRelatorio status-error">Nenhuma informação encontrada</div>
        <div v-else-if="statusBar == 'error2'" class="col-sm-12 statusRelatorio status-error">Algum imprevisto aconteceu. Tente novamente mais tarde</div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
    props:{
        typeBar:{
            type: String,
            required: false,
            default: 'tr'
        },
        statusBar:{
            type: String,
            required: true,
            default: 'info'
        },
        colspanForTd: {
            type: [Number, String],
            required: false,
            default: 12,
        },
        msgCentro:{
            type: String,
            required: false,
            default: 'Selecione os campos obrigatórios (*) para gerar.'
        }
    },
    components: {
        'PulseLoader' : require('vue-spinner/src/PulseLoader').default
    },
})

</script>

<style lang="scss">
    #statusInformation{
        .statusRelatorio{
            padding: 23px !important;
            font-size: 14px;
            color: #FFF;
            text-align: center;
    
            &.status-info{
                background: #1caf9a;
            }
            &.status-error{
                background: #e04b4acc;
            }
            &.status-loading{
                background: #428BCA;
            }
    
            .v-spinner{
                float: right;
            }
        }
    }
</style>