<template>
    <eaglePanel id='criarCartaoRfid' :loading="loadingPanel">
        <div class="col-sm-12 nopadding divDontPrint row">
            <div class="col-md-12 row">
                <div class="col-md-6">
                    <titulo
                    titulo='Gestão de cartões RFID'
                    :icon='mdiCardAccountDetails'/>
                </div>
                <div class="col-md-6">
                    <botoes
                    tipoCancela='button'
                    linkCancel='gestaoRFID'
                    @salvarCadastro='salvar'
                    :disabled='$v.$invalid'
                    :title='$v.$invalid?
						"Preencha os campos obrigatórios":
						"Salvar cartão"'
                    />
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
        </div>
        <div class="col-md-12 row">
            <div class="col-md-4 nopadding">
                <selectAll
				nameForRadio='seletorEmpresa'
				:isMultiple='false'
				:optionsArray='optEmpresas'
				:extraClass='{
					"border border-danger":$v.form.cccliente.$anyError
				}'
				:labels='labelFiltroEmpresas'
				@close='$v.form.cccliente.$touch()'
				@changeSelect='mudaEmpresa'>
                </selectAll>
                <div class="somepadding">
                    <selectAll
                    nameForRadio='seletorAssocidado'
                    :isMultiple='false'
                    :optionsArray='optAssociado'
                    :labels='labelFiltroAssociado'
                    @changeSelect='mudaAssociado'>
                    </selectAll>
                </div>
            </div>
            <div class="col-md-4 nopadding">
                <simpleI
                name='numeroCartao'
                label='Nº Cartão*'
                type='number'
                :inputClass="{
                    'border border-danger':$v.form.cccartao.$anyError
                }"
                @blur='$v.form.cccartao.$touch()'
                @changeInput='mudaNumero'>
                </simpleI>
                <div class="somepadding">
                    <buttonsF
                    label='Ajudante'
                    :arrayButtons='labelBotoesAjudante'
                    @buttonsFiltersChange='mudaAjudante'/>
                </div>
            </div>
            <div class="col-md-4 nopadding">
                <selectAll
                nameForRadio='SeletorTipoDeCartao'
                :isMultiple='false'
                :optionsArray='optTipoCartao'
                :labels='labelTipoDeCartao'
                :extraClass='{"border border-danger": $v.form.cctipo.$anyError}'
                @close="$v.form.cctipo.$touch()"
                @changeSelect='mudaTipoCartao'/>
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
    </eaglePanel>
</template>
<script>
import {mdiCardAccountDetails} from '@mdi/js'
import { required, numeric, maxValue } from 'vuelidate/lib/validators'
import { EmpresasService } from '@/Services/auth/Empresas.service';
import { conectionError } from '@/Services/Helpers/swellHeper' 
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import botoes from '@/components/Atom/Buttons/BasicButtonsCadastrosSC'
import selectAll from '@/components/Atom/Select/SelectAll'
import buttonsF from '@/components/Atom/Buttons/ButtonsFilters'
export default{
	name: 'novoCartaoRFID',
	components:{
		botoes,
		selectAll,
		buttonsF,
		'eaglePanel' : require('@/components/Atom/Panel/PanelEagle').default,
		'titulo'     : require('@/components/Atom/Header/Titulo').default,
		'simpleI'    : require('@/components/Atom/Inputs/InputSimple').default,
	},
	validations:{
		form: {
			cccliente : { required },
			cccartao  : {
				required,
				numeric, maxValue:maxValue(9223372036854775807)
			},
			cctipo    : { required }
		}
	},
	data(){
		return{
			mdiCardAccountDetails:mdiCardAccountDetails,
			loadingPanel:false,
			// value
			form:{
				cccliente     :'',
				cccartao      :'',
				cctipo        :'',
				cccolaborador :'',
				ccajudante    :'',
			},
			// opt
			optEmpresas   : [],
			optTipoCartao : [],
			optAssociado  : [], 
			// Links
			urlBase: '/administrativo/gestao/cartoes/rfid/',
			// labels
			labelFiltroEmpresas:[
				{
					indexDFH: 'EM',
					description: 'Empresa*'
				}
			],
			labelFiltroAssociado:[
				{
					indexDFH: 'M',
					description: 'Associado'
				}
			],
			labelBotoesAjudante:[
				{
					'value': 'true',
					'width': '48.5%',
					'text' : 'Sim',
					'isSelected': false
				},
				{
					'value':'false',
					'width':'48.5%',
					'text':'Não',
					'isSelected':true
				}
			],
			labelTipoDeCartao:[
				{
					indexDFH: 'M',
					description: 'Tipo Cartão*'
				}
			],
			erros:{
				empresa : '',
				cartao  : '',
				tipo    : '',
			}
		}
	},
	methods:{

		async salvar(){
			this.fazPost()
		},

		fazPost(){
			this.loadingPanel = true
			var obj={info:this.form}
			let uri = this.urlBase+'cadastrar'
			new HttpRequest().Post(uri, obj).then((data)=>{
				if(data.code === 201){
					this.$router.push({name:'gestaoRFID'})
				} else if(data.code === 200){
					conectionError(data.data.message, 'warning')
				} else {
					conectionError()
				}
			}).finally(()=>{
				this.loadingPanel = false
			})
		},

		carregaMotoristas(){
			var obj = {codigo: this.form.cccliente}
			let uri = this.urlBase+'motoristas'
			new HttpRequest().Post(uri, obj).then((data)=>{
				this.optAssociado = data.data.colabs.C
			})
		},

		mudaEmpresa(value){
			this.form.cccliente = value[0]
			this.optAssociado = []
			if(value.length > 0)
				this.carregaMotoristas()
		},

		mudaNumero(value){
			this.form.cccartao = value
		},

		mudaTipoCartao(value){
			this.form.cctipo = value[0]
		},

		mudaAssociado(value){
			this.form.cccolaborador = value[0]
		},

		mudaAjudante(value){
			this.form.ccajudante = value[0]
		},

		limpaErros(){
			this.erros.empresa = this.erros.cartao = this.erros.tipo = ''
		},

		validaCampos(){
			var valido = true
			this.limpaErros()
			if(this.form.cccartao === ''){
				this.erros.cartao = 'border border-danger'
				valido = false
			}
			if(this.form.cccliente === ''){
				this.erros.empresa = 'border border-danger'
				valido = false
			}
			if(this.form.cctipo === ''){
				this.erros.tipo ='border border-danger'
				valido = false
			}
			return valido
		}
	},

	mounted(){
		this.optEmpresas = new EmpresasService().Get()
		let uri = this.urlBase+'tipos'
		new HttpRequest().Get(uri).then((data)=>{
			this.optTipoCartao = data.data.tipos
		})
	}
}
</script>
<style lang="scss" scoped>
.somepadding{
    padding: 10px 0px 0px 0px;
}
</style>