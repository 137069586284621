<template>
    <panelEagle id="CheckpointCadastroFeriasEAfastamento" :loading="loadingPanel">
        <div class="col-sm-12 row nopadding">
            <div class="col-sm-6">
                <tituloPage 
                    :icon='mdiCalendarCursor' 
                    titulo='Férias e afastamento'
                />
            </div>
            <div class="col-sm-6 divDontPrint">
                <basicButtonsCadastrosSC
                    linkCancel="ListagemFeriasEAfastamento"
                    tipoCancela="button" 
                    @salvarCadastro="salvar"
                    :disabled='$v.$invalid'
                />
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <div class="col-sm-12 row nopadding">
            <div class="col-sm-4 nopadding">
                <inputRangeNoLimits
                    ref="refData"
                    name="botaoData"
                    titulo="Intervalo*"
                    :value="valueData" 
                    @changeInput="v => valueData = v"
                />
                <!--  changeData -->
            </div>
            <div class="col-sm-4 nopadding">
                <selectAll
                    nameForRadio="claudio"
                    :labels="[{indexDFH: 'E', description: 'Empresa*'}]"
                    :optionsArray="optEmpresas"
                    :hasSelectAll="false"
                    :isMultiple="false"
                    :selected="empresasSelecionada"
                    @changeSelect="changeEmpresas" 
                />
            </div>
            <div class="col-sm-4 nopadding">
                <selectAll
                    :disabled="!valueEmpresa[0]"
                    :loading="loadingColaborador"
                    ref="refColaborador"
                    nameForRadio="wagner"
                    :labels="[{indexDFH: 'E', description: 'Colaborador*'}]"
                    :optionsArray="optColaborador"
                    :hasSelectAll="false"
                    :isMultiple="false"
                    :selected="colaboradorSelecionado"
                    @changeSelect="v => valueColaborador = v" 
                />
            </div>
            <div class="col-sm-4 nopadding mt-3">
                <selectAll
                    nameForRadio="marcelo"
                    :labels="[{indexDFH: 'E', description: 'Tipo*'}]"
                    :optionsArray="optTipo"
                    :selected="tipoSelecionado"
                    :hasSelectAll="false"
                    :isMultiple="false"
                    @changeSelect="changeTipo"
                />
                <!-- 
                    v => valueTipo = v
                -->
            </div>
            <div class="col-sm-4 nopadding mt-3">
                <inputSimple
                    :value="valueObservacao"
                    @changeInput="v => valueObservacao = v"
                    name = "pedro"
                    label = "Observações"
                />
                <!-- @changeInput ="changeHorarioSelecionado" -->
            </div>
            <div class="col-sm-4 nopadding mt-3">
                <div class="col-sm-12" style="text-align:left">
                    <label class='subtitle'>
                        Anexar arquivos<span v-show="arquivoObr">*</span>
                    </label>
                    <div class="divUpload">
                        <input
                            class='form-control'
                            readonly
                            :value='retornaNomeArquivo'
                        />
                        <button @click="onPickFile" class="botaoUpload">
                            <baseIcon size='16' class="icone" :icon="iconeBtnArquivo"/>
                        </button>
                        <!-- style='display:none' -->
                        <input type='file'
                            accept="*"
                            hidden
                            ref='fileInput'
                            @change='onFilePicked'
                        />
                    </div>
                </div>
                <!-- @changeInput ="changeHorarioSelecionado" -->
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
    </panelEagle>
</template>
<script>
import Vue from 'vue'
import { 
    mdiCalendarCursor, 
    mdiCloudUpload,
    mdiLoading 
} from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { required, minLength } from 'vuelidate/lib/validators'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { DateTime } from 'luxon';
import { conectionError } from '@/Services/Helpers/swellHeper'
export default Vue.extend({
    name:'CheckpointCadastroFeriasEAfastamento',
    components:{
        baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
        panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
        tituloPage: require('@/components/Atom/Header/Titulo').default,
        basicButtonsCadastrosSC: require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
        inputRangeNoLimits: require('@/components/Atom/Datas/InputRangeNoLimits').default,
        selectAll: require('@/components/Atom/Select/SelectAll').default,
        inputSimple: require('@/components/Atom/Inputs/InputSimple').default,
        // inputArchive: require('@/components/Atom/Inputs/InputArchive').default,  
    },
    computed:{
        retornaNomeArquivo(){
            return !this.nomeArquivo?"Nenhum arquivo selecionado":this.nomeArquivo
        },
        arquivoObr(){
            return this.valueTipo[0] === "AF"
        }
    },
    validations:{
        valueData:{ required },
        valueColaborador:{ 
            required, 
            minLength:minLength(1)
        },
        valueEmpresa:{ 
            required, 
            minLength:minLength(1)
        },
        valueTipo:{ 
            required, 
            minLength:minLength(1)
        },
        nomeArquivo:(v) => {
            if(v.arquivoObr){
                return v.nomeArquivo
            }
            return true
        }
    },
    data() {
        return {
            id:undefined,
            iconeBtnArquivo:mdiCloudUpload,
            mdiCalendarCursor:mdiCalendarCursor,
            loadingPanel:false,
            loadingColaborador:false,
            urlBase:'checkpoint/cadastros/ferias/afastamento',
            optEmpresas:new EmpresasService().Get(),
            optColaborador: [],
            optTipo:[
                {
                    description: 'Férias',
                    value: 'FE'
                },
                {
                    description: 'Afastamento',
                    value: 'AF'
                }
            ],
            nomeArquivo:"",
            arquivoBase64: '',
            arquivoAnexo:null,
            valueData:'',
            valueColaborador: [], 
            valueEmpresa:[],
            valueObservacao:'',
            valueTipo:['FE'],
            // quando tiver editando
            empresasSelecionada:[],
            colaboradorSelecionado:[],
            tipoSelecionado:[{
                description: 'Férias',
                value: 'FE'
            }],
        }
    },
    methods: {
        salvar(){
            var data = this.arrumaDataSalvar()
            var cedocumento = this.arquivoBase64
            var cednome = this.nomeArquivo
            var cedtipo = null
            var cedtamanho = null
            if(this.arquivoAnexo){
                cedtipo = this.pegaExtensao(this.nomeArquivo)// this.arquivoAnexo.type
                cedtamanho = this.arquivoAnexo.size
            }

            var objSalvar = {
                cecodigo:this.id,
                // checkpoint_eventos
                ceinicio:data[0],
                cefim:data[1],
                cecodigoempresa:this.valueEmpresa[0],
                cecodigocolaborador:this.valueColaborador[0],
                cetipo:this.valueTipo[0],
                ceobservacao:this.valueObservacao,
                // checkpoint_eventos_documentos
                cedocumento:cedocumento,
                cednome:cednome,
                cedtipo:cedtipo,
                cedtamanho:cedtamanho,
            }
            this.loadingPanel = true
            new HttpRequest()
                .Post(`/${this.urlBase}/salvar`, objSalvar)
                .then(({ data }) => {
                    if(data.status){
                        this.$router.push({ 
                            name: 'ListagemFeriasEAfastamento' 
                        })
                    }else if(data.erro === 13){
                        conectionError(data.mensagem, "warning")                 
                    }else{
                        conectionError()
                    }
                }).catch(() => {
                    conectionError()
                }).finally(() => this.loadingPanel = false)
        },
        
        /**
         * Verfica se o usuario selecionou um periodo anterior a hoje
         * @depracated nao usada mais pq agr da pra selecionar qualquer periodo
         */
        changeData(data){
            var datas = data.split(' - ')
            var dataAtual = DateTime.now()
            var dataInicio = DateTime.fromFormat(datas[0], 'dd/LL/yyyy')
            var difDiaAtualEInicio = dataInicio.diff(dataAtual, 'days')
            var diasIni = difDiaAtualEInicio.values.days
            if(diasIni > -1){
                this.valueData = data
                return
            }
            var tipo = this.optTipo.filter(v => v.value === this.valueTipo[0])
            var titulo = `Não é possível cadastrar ${tipo[0].description} no passado`
            var msg = `Não é possível cadastrar datas anteriores a hoje <br>
                ( ${dataAtual.toFormat('dd/LL/yyyy')} )`
            conectionError(msg, 'warning', titulo)
            this.$nextTick(() => {
                // tem um bug no campo de data
                // se tu tentar atribuir o dia atual pra 
                // variavel dps de ser mudada, ele n atribui
                // por isso eu coloquei com mais um dia
                // pra esse caso da bom
                dataAtual = dataAtual.plus({'days':1})
                var dataAtualF = dataAtual.toFormat('dd/LL/yyyy - dd/LL/yyyy')
                this.valueData = dataAtualF
            })
        },

        changeTipo(value){
            if(!value[0]){
                this.tipoSelecionado = this.optTipo.filter(
                    v => v.value === this.valueTipo[0]
                )
            }
            this.valueTipo = value
        },
        
        pegaExtensao(nome){
            var r = /\.([^./]+)$/.exec(nome)
            return r && r[1] || ''
        },

        arrumaDataSalvar(){
            var data = this.valueData.split(' - ')??[]
            var dataI = DateTime.fromFormat(data[0], 'dd/LL/yyyy').toISODate()
            dataI = dataI+' 00:00:00'
            var dataF = DateTime.fromFormat(data[1], 'dd/LL/yyyy').toISODate()
            dataF = dataF+' 23:59:59'
            return [dataI, dataF] 
        },  

        changeEmpresas(empresa){
            this.valueEmpresa = empresa
            this.$refs.refColaborador.clearAll()
            this.buscaFiltros()
        },

        buscaFiltros(){
            this.loadingColaborador = true
            new FiltrosService()
                .dados_filtros(this.valueEmpresa, ['C'])
                .then(({ C }) => {
                    this.optColaborador = C
                }).finally(() => {
                    this.loadingColaborador = false
                })
        },

        /**
         * Quando o usuário clica no botão de upload, ele simula
         * um click no input do tipo file que esta escondido
         */
        onPickFile(){
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            this.$refs.fileInput.click()
        },

        /**
         * Quando um arquivo é carregado para o input
         * do tipo file, ele vai disparar esse evento.
         * Ele "trata" o arquivo e deixa pronto para 
         * ser enviado para o back-end
         */
        onFilePicked(event){
            const files = event.target.files
            this.nomeArquivo = files[0].name
            const fileReader = new FileReader()
            fileReader.addEventListener('load', () => {
                // eu sei que o certo seria enviar o arquivo diferente
                // mas o padrao do banco e apenas a string dps do parte de levar o base64
                // e isso acontece com o tipo do arquivo
                // hj nao e salvo de fato o tipo do arquivo
                // mas sim a extensao, tipo .txt .png e nao
                // text/plain ou image/png
                this.arquivoBase64 = this.retornaApesaBase64(fileReader.result)
            })
            fileReader.readAsDataURL(files[0])
            this.arquivoAnexo = files[0]
        },

        retornaApesaBase64(str){
            return str.substring(
                str.indexOf('base64,') + 'base64,'.length
            )
        },

        buscaDados(){
            this.loadingPanel = true
            new HttpRequest().Post(
                `/${this.urlBase}/pegar/dados`,
                this.id
            ).then(({ data }) => {
                this.atribuiValoresInput(data.dados[0])
            }).finally(() => {
                this.loadingPanel = false
            })
        },

        atribuiValoresInput(dados){
            this.empresasSelecionada = [{
                value:dados.cecodigoempresa,
                description:dados.clfantasia,
            }]
            this.colaboradorSelecionado = [{
                value:dados.cecodigocolaborador,
                description:dados.mtnome
            }]
            this.tipoSelecionado = this.optTipo.filter(
                (v) => v.value === dados.cetipo
            )
            this.valueObservacao = dados.ceobservacao
            var dataI = DateTime.fromISO(
                dados.ceinicio.split(' ')[0]
            ).toFormat('dd/LL/yyyy')
            var dataF = DateTime.fromISO(
                dados.cefim.split(' ')[0]
            ).toFormat('dd/LL/yyyy')
            this.valueData = `${dataI} - ${dataF}`
            this.buscaDocumento(dados.cecodigo)
        },
        
        buscaDocumento(cedcodigoeventos){
            this.iconeBtnArquivo = mdiLoading
            new HttpRequest().Post(
                `/${this.urlBase}/pega/arquivo`, {cedcodigoeventos}
            ).then(({ data }) => {
                this.nomeArquivo = data
            }).catch(() => {
                conectionError()
            }).finally(() => {
                this.iconeBtnArquivo = mdiCloudUpload
            })
        }
    },
    mounted(){
        this.id = this.$route.params.id??null
        if(this.id){
            this.buscaDados()
        }
    },
})
</script>
<style lang="scss">
#CheckpointCadastroFeriasEAfastamento{
    .botaoUpload{
        min-width: 25px;
        background-color: #428bca;
        border: none;
    }
    .divUpload{
        display: flex;
        padding: 0 !important;
        margin: 0 !important;
    }
}
</style>
