import L from 'leaflet'
import { pickColor } from './RotaManual.ts'

/**
 * Uma espécie de facilitador, possue todos os métodos do L.Marker 
 * e com o tempo estou adicionando novos métodos para o uso da Eagle.
 */
export class MarkerRotaManual extends L.Marker{
	/**
     * @description A ideia dessa classe é que só passando as informações do 
     * ponto, a classe já devolva um marcador completo, com ícone correto
     * e todas as opções padronizadas, com os métodos da Eagle e pã.
     * @constructs marker de ponto do usuário.
     * @augments L.Marker - marcador do leaflet.
     * @class EagleMarker.
     * @param {Object} infoPonto.
     * @throws não possuei uma das coordenadas.
     * @author Gui 🐙🍷🍺.
     */
	constructor(infoPonto){
		const pontos = infoPonto
		if(!infoPonto?.polatitude || !infoPonto?.polongitude){
			throw Error('Ponto não possue uma ou as duas coordenadas')
		}
		/**
         * Construtor da classe L.Marker, mandando as informações do ponto
         * E já com o ícone correto selecionado
         */
		super([infoPonto.polatitude, infoPonto.polongitude], {
			title: infoPonto.podescricao,
			icon: criaDivIcon(infoPonto),
			alt: infoPonto.podescricao,
			riseOnHover:true,
		})
		this.id = infoPonto.pocodigo
		this.infoPonto = infoPonto
	}

	/**
	 * @description altera o ícone do ponto baseado nas informações do mesmo
	 */
	atualizaIcone(pedido){
		this.setIcon(criaDivIcon(pedido))
		this.infoPonto = pedido
		this.id = pedido.pocodigo
	}

	abrirPopup() {
		if(!this.isPopupOpen())
			this.openPopup()
	}
}

/** 
 * @description Decide o ícone do marcador baseado no tipo do mesmo.
 * @param {string} potipo
 * @return {string} caminho até o ícone correto
 */
function defineIcone(potipo){
	switch (potipo) {
		case 'C':
			return 'img/markerPontoC.png'
		case 'E':
			return 'img/markerPontoE.png'
		case 'P':
			return 'img/markerPontoP.png'
			// se não tiver um tipo, volta o marcador padrão
		default:
			return 'img/markerPonto.png'
	}
}

/**
 * @description cria o marcador do ponto, do tipo div.
 * @param {object} obj
 * @param {number} obj.pocodigo      - código do ponto.
 * @param {string} obj.podescricao   - descrição do ponto.
 * @param {('C'|'E'|'P')} obj.potipo - tipo do ponto.
 * @returns {L.DivIcon} ícone do ponto.
 */
function criaDivIcon({pocodigo, podescricao, potipo, irmarcador, selecionado, irordem, 
	irintrajornada, irinterjornada}){
	var html = ''
	if(irintrajornada || irinterjornada) {
		html = `
			<div class='markerRotaCircle markerRotaTextAjuste'>
				<div class='markerRotaCentro' 
					style='background-color:#6cc377'>
				</div>
				<div id='${pocodigo}_${potipo}' class='markerRotaText'>
					${selecionado ? irordem : 'P'}
				</div>
			</div>`
	} else if(selecionado) {
		html = `<div class='markerRotaCircle'>
					<div class='markerRotaCentro' 
						style='background-color:${pickColor(irmarcador)}'>
					</div>
					<div id='${pocodigo}_${potipo}' class='markerRotaText'>
						${irordem}
					</div>
				</div>`
	} else if(irmarcador) {
		html = `
			<div class='markerRotaCircle markerRotaTextAjuste'>
				<div class='markerRotaCentro' 
					style='background-color:${pickColor(irmarcador)}'>
				</div>
				<div id='${pocodigo}_${potipo}' class='markerRotaText'>
					${potipo}
				</div>
			</div>`
	} else {
		html = `<div id='${pocodigo}'>
            <div>
                <img src='${defineIcone(potipo)}'>
            </div>
            <div class='' id='${pocodigo}_${potipo}' hidden>
                ${podescricao}
            </div>
        </div>`
	}
	return L.divIcon({
		html:html,
		iconSize    : [0, 0],
		iconShadow  : [0, 0],
		iconAnchor  : [20, 38],
		shadowAnchor: [0, 0]
	})
}
