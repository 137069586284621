/**eslint-disable */
Este é a modal básica e padrão para ser usada em todo o sistema, criando as modais especificas em cima deste componente

-> Propriedades:
	- id - String - Id da modal, para poder ser aberta - EX: 'modalEagle'
	- size - String - Tamanho da modal - EX: 'xl/lg/sm'
	- title - String - Titulo da modal, o que vai no modal-header - EX: 'Deletar'
	- status - String - Status da messagem para ver se a mensagem vai ficar verde, ou vermelha - EX: 'error/succes'
	- message - String - Mensagem extra de retorno de requisição se necessario - 'Sucesso ao salvar' 
	- hardToClose - Boolean - A modal não pode ser fechada só clicando no background ou apertando esc
	- noCloseOnBackdrop - Boolean - Desabilita click na tela fora do modal
	- noCloseOnEsc - Boolean - Desabilita a saída do modal pelo ESC do teclado 
	- hideHeaderClose - Boolean - Retira o X (sair) 
	- textoConfirmar - String - Texto do botão confirmar
	- iconeConfirmar - String - Icone do botão confirmar
	- textoCancelar - String - Texto do botão cancelar
	- tamanhoBtn - String - Texto do botão confirmar
	- typeButtonCancelar - String - Cor do botão cancelar
	-showButtonConfirmar - Boolean - True para mostrar o botao confirmar e False para esconter o botao confirmar

-> Slots:
	1- modalBody - Conjunto do modal-body
	2- modalFooter - Conjunto para colocar o modal-footer, ja vem com um botão de cancelar e confirmar e um espaço para mensagem por padrão

-> Eventos:
	1- modalOpen - Emitido quando o modal abre - Sem parametros 
	1- modalClose - Emitido quando o modal é fechado - Sem parametros 
	2- confirmButton - Emitido quando o botão de confirmar padrão for clicado - Sem parametros
	3- cancelButton - Emitido quando o botão de cancelar padrão for clicado e ja fecha a modal - Sem parametros
/**enlist-enable */
<template>
	<b-modal @hide="modalFechado" @show="modalAberto" :size="size" :id="id" ref="modal" :no-close-on-backdrop='hardToClose'
		:no-close-on-esc='hardToClose' :hideHeaderClose='hideHeaderClose' :title="title" v-bind='$listeners'>
		<template #modal-header>
			<slot name="modal-header"></slot>
		</template>
		<slot name="modalBody">

		</slot>
		<slot slot="modal-footer">
			<slot name="modalFooter">
				<span :class="'spanStatus status-' + status">{{ message }}</span>
				<simpleButton :icon="mdiCloseThick" @click="cancelButton" :text="textoCancelar" :width="tamanhoBtn" event="click"
					:type="typeButtonCancelar"></simpleButton>
				<simpleButton v-show="showButtonConfirmar" :icon="iconeConfirmar" @click="confirmButton" :text="textoConfirmar" :width="tamanhoBtn" event="click"
					type="blue" :disabled="disabledButton"></simpleButton>
			</slot>
		</slot>
	</b-modal>
</template>

<script lang="ts">
import Vue from 'vue'
import { mdiCheckBold, mdiCloseThick } from '@mdi/js'
export default Vue.extend({
	inheritAttrs: false,
	data() {
		return {
			mdiCloseThick: mdiCloseThick,
			mdiCheckBold: mdiCheckBold,
		}
	},
	props: {
		id: {
			type: String,
			default: '',
		},
		size: {
			type: String,
			required: false,
			default: 'lg',
		},
		typeButtonCancelar: {
			type: String,
			required: false,
			default: 'red',
		},
		title: {
			type: String,
			default: ''
		},
		showButtonConfirmar: {
			type: Boolean,
			default: true
		},
		status: {
			type: String,
			default: 'none'
		},
		message: {
			type: String,
			default: ''
		},
		hardToClose: {
			type: Boolean,
			default: false
		},
		noCloseOnBackdrop: {
			type: Boolean,
			required: false,
			default: false,
		},
		noCloseOnEsc: {
			type: Boolean,
			required: false,
			default: false,
		},
		hideHeaderClose: {
			type: Boolean,
			required: false,
			default: false,
		},
		textoConfirmar: {
			type: String,
			default: 'Confirmar'
		},
		iconeConfirmar: {
			type: String,
			default: mdiCheckBold
		},
		textoCancelar: {
			type: String,
			default: 'Cancelar'
		},
		tamanhoBtn: {
			type: String,
			default: '100px'
		},
		disabledButton: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	components: {
		'simpleButton': require('@/components/Atom/Buttons/SimpleButton').default
	},
	methods: {
		modalAberto() {
			this.$emit('modalOpen');
		},
		modalFechado() {
			this.$emit('modalClose');
		},
		cancelButton() {
			this.$emit('cancelButton');
			this.$bvModal.hide(this.id)
		},
		confirmButton() {
			this.$emit('confirmButton');
		}
	}
})
</script>

<style lang="scss">
.modal {
	.modal-content {
		border-radius: 0px !important;

		.modal-header {
			background: #F5F5F5;
			border-color: #d5d5d5;
			font-size: 13px;

			.close {
				padding: 5px 8px !important;
			}
		}

		.modal-body {
			font-size: 13px;
		}

		.modal-footer {
			background: #F5F5F5;
			border-color: #D5D5D5;

			.spanStatus {
				font-size: 13px;

				&.status-error {
					color: #e04b4a;
				}

				&.status-succes {
					color: #95b65c;
				}
			}
		}
	}

	.nopadding {
		padding: 0px;
	}

	.row {
		margin-left: 0px;
	}
}
</style>
