<template>
    <panelEagle id='Descontos' :loading='gerandoRel'>
        <div class="col-sm-12 nopadding row">
            <div class="col-sm-6">
                <tituloPage :icon='mdiOffer' titulo='Descontos'/>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 divDontPrint">
                <basicButtonsCadastrosEIN
                    :disabledDropdown="!Object.keys(dadosTabela).length"
                    linkNew ='cadastrarDescontos'
                    @exportarCadastro="exportarCadastro"
                    tipoNovo='button'
                    :loading='loading'>
                </basicButtonsCadastrosEIN>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <tableListagem
            :habilitado ='false'
            :data      ='dadosTabela'
            :titles    ='titles' 
            deleteEdit ='editDelete'
            @editarCadastro  ='editarCadastro'
            @excluirCadastro ='excluirCadastro'>
        </tableListagem>

    </panelEagle>
</template>
<script>
import Vue  from 'vue'
import {mdiOffer } from '@mdi/js'
import { mapGetters } from 'vuex'
import { HttpRequest } from '../../../../Services/auth/HttpRequest.Service'

export default Vue.extend({
	name:'descontos',
	components:{
		'panelEagle'               : require('@/components/Atom/Panel/PanelEagle').default,
		'basicButtonsCadastrosEIN' : require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
		'tituloPage'               : require('@/components/Atom/Header/Titulo').default,
		'tableListagem'            : require('@/components/Atom/Table/TableListagem').default,

	},
	data(){
		return {
			mdiOffer:mdiOffer,
			gerandoRel:false,
			titles :[
				{'number':'descricao', 'name':'Descrição'},
				{'number':'veiculo'  , 'name':'Quatidade de veiculos'},
				{'number':'desconto' , 'name':'Desconto'},

			],
			dadosTabela : [],
			loading     : [false, false, false], 
		}
	},
	methods:{
		async exportarCadastro(value){
			switch (value) {
				case 'pdf':
					this.loading = [true, false, false]
					break;
				case 'xls':
					this.loading = [false, true, false]
					break;
				default:
					this.loading = [false, false, true]
					break;
			}
			var root =  process.env.VUE_APP_ROOT;
			var exporta = await new HttpRequest()
				.Post('/administrativo/cadastros/descontos/exporta', {'obj':value})
			window.open(root+'/'+exporta.data.retorno);
			this.loading = [false, false, false] // adminstrativo

		},
		editarCadastro(value){
			this.$router.push('/administrativo/cadastros/cadastrar/descontos/'+value)
		},
		async excluirCadastro(value){
			var dados = await new HttpRequest()
				.Post('/administrativo/cadastros/descontos/deleta', {'obj':value})
			this.montaTabela()
		},
		async montaTabela(){
			var dados = []
			this.gerandoRel = true
			new HttpRequest()
				.Post('/administrativo/cadastros/descontos/tabela').then((dados=>{
					this.dadosTabela = dados.data
					this.gerandoRel = false
				}))
		},
	},
	async mounted(){
		this.montaTabela()
	}
})

</script>
<style lang="scss">

</style>
