<template>
    <div class="imagemFundo d-flex align-items-center justify-content-center">
        <div class="menu-container">

            <div class="hex-wrapper">
                <div class="hex-row shift">
                    <div title="Abastecimento"
                        :class="modulos['abastecimento'] !== undefined ? 'hexagon' : 'hexagon disabled gray'"
                        @click="pushRouter('abastecimento')">
                        <!-- @click="pushRouter('/abastecimento')" -->
                        <img src="../../../assets/modulos/Eagle-Fuel-B.png" alt="" class="hex-logo-modulo" />
                    </div>
                    <div title="Jornada de Trabalho"
                        :class="modulos['controle/jornada'] !== undefined ? 'hexagon' : 'hexagon disabled'"
                        @click="pushRouter('controle/jornada')">
                        <img src="../../../assets/modulos/Eagle-Job-B.png" alt="" class="hex-logo-modulo" />
                    </div>
                    <div title="Mapa" :class="modulos['mapa'] !== undefined ? 'hexagon' : 'hexagon disabled'"
                        @click="pushRouter('mapa')">
                        <img src="../../../assets/modulos/Eagle-Finder-B.png" alt="" class="hex-logo-modulo">
                    </div>
                    <div title="Telemetria Básica"
                        :class="modulos['finder/plus'] !== undefined ? 'hexagon' : 'hexagon disabled'"
                        @click="pushRouter('finder/plus')">
                        <img src="../../../assets/modulos/finderplus.png" alt="" class="hex-logo-modulo">
                    </div>
                    <div title="Administrativo"
                        :class="modulos['administrativo'] !== undefined ? 'hexagon' : 'hexagon disabled'"
                        @click="pushRouter('administrativo')">
                        <img src="../../../assets/modulos/Eagle-admin.png" alt="" class="hex-logo-modulo">
                    </div>
                </div>
                <div class="hex-row">
                    <div title="Controle de Fretes"
                        :class="modulos['eagle/cargo'] !== undefined ? 'hexagon' : 'hexagon disabled'"
                        @click="pushRouter('eagle/cargo')">
                        <!-- essa img ta menor que as outras -->
                        <!-- ai tem a classe nova pra deixar certo -->
                        <img src="@/assets/modulos/eagle-cargo.png" alt="" class="hex-logo-modulo-dif">
                    </div>
                    <div title="Roteirizador"
                        :class="modulos['roteirizador'] !== undefined ? 'hexagon' : 'hexagon disabled'"
                        @click="pushRouter('roteirizador')">
                        <img src="../../../assets/modulos/Eagle-Way-B.png" alt="" class="hex-logo-modulo">
                    </div>
                    <div class="hexagnEagle">
                        <img src="../../../assets/logo.png" alt="" class="hex-logo">
                    </div>
                    <div title="Gestão de Desempenho"
                        :class="modulos['gestao/desempenho'] !== undefined ? 'hexagon' : 'hexagon disabled'"
                        @click="pushRouter('gestao/desempenho')">
                        <img src="../../../assets/modulos/Eagle-Driver-B.png" alt="" class="hex-logo-modulo">
                    </div>
                    <div title="Cadeados" :class="modulos['cadeados'] !== undefined ? 'hexagon' : 'hexagon disabled'"
                        @click="pushRouter('cadeados')">
                        <img src="../../../assets/modulos/eagle-lock.png" alt="" class="hex-logo-modulo">
                    </div>
                </div>
                <div class="hex-row shift">
                    <div title="Temperatura"
                        :class="modulos['controle/temperatura'] !== undefined ? 'hexagon' : 'hexagon disabled'"
                        @click="pushRouter('controle/temperatura')">
                        <img src="../../../assets/modulos/Eagle-Cold-Manager-B.png" alt="" class="hex-logo-modulo">
                    </div>
                    <div title="Coletivos" :class="modulos['coletivos'] !== undefined ? 'hexagon' : 'hexagon disabled'"
                        @click="pushRouter('coletivos')">
                        <img src="../../../assets/modulos/Eagle-City-B.png" alt="" class="hex-logo-modulo">
                    </div>
                    <div title="Telemetria Avançada"
                        :class="modulos['performance'] !== undefined ? 'hexagon' : 'hexagon disabled'"
                        @click="pushRouter('performance')">
                        <img src="../../../assets/modulos/Eagle-Performance-B.png" alt="" class="hex-logo-modulo">
                    </div>
                    <div title="Manutenção" :class="modulos['manutencao'] !== undefined ? 'hexagon' : 'hexagon disabled'"
                        @click="pushRouter('manutencao')">
                        <img src="../../../assets/modulos/Eagle-Previse-B.png" alt="" class="hex-logo-modulo">
                    </div>
                    <div title="Checkpoint" :class="modulos['checkpoint'] !== undefined ? 'hexagon' : 'hexagon disabled'"
                        @click="pushRouter('checkpoint')">
                        <img src="../../../assets/modulos/Eagle-Checkpoint-B.png" alt="" class="hex-logo-modulo">
                    </div>
                </div>
            </div>
        </div>

        <template>
            <modalEagle id="modalComplementoUsuario" title="Cadastro Incompleto" size='lg' :hardToClose="true" :hideHeaderClose="true" >
                <template slot="modalBody">
                           
                    <div class="col-sm-12 nopadding mt-2 row">
                        <h5>Informe os dados abaixo para prosseguir!</h5>
                    </div>

                    <div class="col-sm-12 nopadding mt-2 row">
                        <div class="col-sm-6 nopadding mt-2">
                            <inputSimple 
                                name='usunome'
                                label='Nome*'  
                                id='usunome'
                                :value='complementoUsuario.usunome'
                                @changeInput="setNome"
                              />
                        </div>
                        <div class="col-sm-6 nopadding mt-2">
                            <inputSimple 
                                name="telefoneUsuarioModalComplemento" 
                                label='Telefone*'
                                :value='complementoUsuario.usutelefone' 
                                :hasMask="true" 
                                :mask="['(NN) NNNNN-NNNN']" 
                                @changeInput="setTelefone"
                            />

                        </div>
                    </div>
                    
                    <div class="col-sm-12 nopadding mt-2 row">
                        <selectAll 
                            nameForRadio='seletorCargos' 
                            :labels='[{ description: "Cargo*" }]'
                            :isMultiple='false' 
                            :selected='preCargos' 
                            :optionsArray='optCargos' 
                            :loading='carregandoCargos'
                            ref='seletorCargos' 
                            @changeSelect='setCargo' 
                        />
                    </div>

                </template>
                <template>
                    <div class="col-sm-3 row nopadding">
                        <div class="col-sm-12 nopadding">
                            <simpleButton 
                                text=" Salvar " 
                                width="97%" 
                                :icon="mdiCheckBold" 
                                event="click" 
                                type="green"
                                :disabled='$v.$invalid'
                                @click='complementoUsuarioSalvar'
                            />
                        </div>
                    </div>
                </template>
            </modalEagle>
        </template>
        
    </div>
</template>

<script lang="ts">

import Vue from 'vue'
import { Crypto } from '@/Services/auth/Crypto.Service'
import Vuex from '../../../store/index'
import { mapActions, mapGetters } from 'vuex'
import {FiltrosService}  from '@/Services/filtros/filtros.Service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { AuthService } from '../../../Services/auth/Auth.Service';
import { conectionError } from '@/Services/Helpers/swellHeper'
import {mdiCheckBold,} from '@mdi/js'
import { required } from 'vuelidate/lib/validators'


export default Vue.extend({
    name: 'dashboard',
    components: {
        'modalEagle': require('@/components/Atom/Modal/ModalEagle.vue').default,
	    'simpleButton': require('@/components/Atom/Buttons/SimpleButton').default,
        'selectAll': require('@/components/Atom/Select/SelectAll').default,
        'inputSimple': require('@/components/Atom/Inputs/InputSimple').default,
        'simpleAlert': require('@/components/Atom/Alerts/AlertSimple').default,

    },
    validations:{
        complementoUsuario:{
            usucargo:{
                required,
            },
            usutelefone:{
                required,
            },
            usunome:{
                required,
            }
        }
    },



    
    data() {
        return {
            route: String,
            modulos: Vuex.getters.getModulos,
            cliente: Vuex.getters.getUser.idClient,
            usumaster: Vuex.getters.getUser.usuMaster,
            perfil: Vuex.getters.getUser.perfil,
            idUser: Vuex.getters.getUser.idUser,
            mdiCheckBold: mdiCheckBold,
            preCargos: [],
            saveError: false,
            optCargos: [],
            carregandoCargos: false,
            faltaComplementoUsuario: true,
            complementoUsuario: {
                usunome:'',
                usutelefone:'',
                usucargo:'',
            },
        };
    },
    async mounted() {
        this.buscaDadosUsuarios();
        this.dados_filtros();
        this.toggleBodyClass('addClass', 'black');
        this.refreshModulos()
        this.pegaPalavrasChave()
        // Para dar tempo de pegar os dados das mutatios dos getters
        setTimeout(() => {
            this.modulos = Vuex.getters.getModulos
        }, 600);
    },
    destroyed() {
        this.toggleBodyClass('removeClass', 'black');
    },
    methods: {
        ...mapActions(['refreshModulos', 'getUser',
            'updatePermissions', 'refresh']),

        pegaPalavrasChave() {
            new HttpRequest().Post('/palavra/chave', {
                id: this.$store.getters.getUser.idUser
            }).then((dados) => {
                let v = dados.data.versao
                v = v ? v : ''
                localStorage.setItem('versaoRecente', v)
                Vuex.dispatch('refresh')
                new Crypto().Commit('palavraChave',
                    JSON.stringify(dados.data.dados))
            })
        },

        toggleBodyClass(addRemoveClass, className) {
            const el = document.body;
            if (addRemoveClass === 'addClass') {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },
        pushRouter(route: any) {
            var modulos = Vuex.getters.getModulos
            if (modulos[route] !== undefined) {
                this.atualizarPermissoes()
                this.$router.push(modulos[route]);
            } else {
                this.modalOpsSemAcesso()
            }
        },
        modalOpsSemAcesso() {
            conectionError(
                'Você não possui acesso a esse módulo.',
                'error',
                'Oops...'
            )
        },

        /**
        * @description Método para atualizar as permissões do usário enquanto
        *   estiver mexendo no menu, faz uma requisição pelo AuthService
        *   buscando as permissões do usuário e atualizando em chache 
        *   pelo updatePermissions
        * @author Vitor Hugo 🐨
        */
        atualizarPermissoes() {
            var service = new AuthService();
            service.atualizaPermissoes(this.cliente, this.perfil, this.usumaster, this.idUser).then(() => {
                this.updatePermissions()
                this.refresh()
            })
        },

        setNome(usunome) {
            this.complementoUsuario.usunome = usunome
        },

        setTelefone(usutelefone) {  
            this.complementoUsuario.usutelefone = usutelefone
        },

        setCargo(usucargos) {
            this.complementoUsuario.usucargo = usucargos[0];
        },

        buscaDadosUsuarios(){

            new HttpRequest().Post('/valida/complemento', {
                id: this.idUser
            }).then((dados) => {

                if (dados.code == 200) {
                    this.$bvModal.show('modalComplementoUsuario')
                }
            })

        },

        complementoUsuarioSalvar() {

            var obj = {
                idUser: this.idUser,
                usunome: this.complementoUsuario.usunome,
                usufone:  this.complementoUsuario.usutelefone,
                usucargo:  this.complementoUsuario.usucargo,
            };
            new HttpRequest().Post('/salvar/complemento', obj).then((dados) => {
			    this.$bvModal.hide('modalComplementoUsuario')
            });
		},

		async dados_filtros(){
			this.carregandoCargos = true

            new FiltrosService().dados_filtros([], ['CG'])
				.then((dados)=>{
					this.optCargos = dados.CG
                })
			this.carregandoCargos = false
		},
    },

    /**
     * @description Método verifica se existe uma versão para 
     *  buscar e inserir uma versão no localStorage
     * @author Vitor Hugo 🐨
     */
    beforeRouteEnter(routeTo, routeFrom, next) {
        var versaoAtual = localStorage.getItem('versaoAtual')
        if (!versaoAtual) {
            new HttpRequest().Post('/versao/sistema').then((data) => {
                let versao = data.data ? data.data : ''
                localStorage.setItem('versaoAtual', versao)
                localStorage.setItem('versaoRecente', versao)
                next()
            })
        } else {
            next()
        }
    }
})
</script>

<style lang="scss">
@import '~@sweetalert2/themes/dark/dark.scss';


.swal2-modal {
    border: 2px solid #4285F4;

    .swal2-confirm {
        background-color: #4285F4 !important;
        border-radius: 0px !important;

        &:hover {
            background-color: #4285F4 !important;
        }
    }

    .swal2-cancel {
        background-color: #e04b4a !important;
        border-radius: 0px !important;

        &:hover {
            background-color: #e04a4ac0 !important;
        }
    }
    .swal2{
        size: 10px !important;
    }



}

.hex-wrapper {
    display: inline-block;
}

.hex-row {
    clear: left;
    filter: drop-shadow(0px 0px 3px #40b0d5);

    @media(max-width: 1049px) {
        margin-left: 0px;

        .hexagnEagle {
            display: none;
        }
    }
}

.shift {
    @media(min-width: 1050px) {
        margin-left: 69px;
    }
}

$block-size: 150;

.hexagon {
    cursor: pointer;

    @media(min-width: 1050px) {
        clip-path: polygon(50% 0, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
        float: left;
        position: relative;
        margin: -18px -7px;
        width: $block-size + px;
        height: $block-size + px;
        background-color: #4285F4;
        grid-column: 2 span;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: ($block-size/9)+px;
        cursor: pointer;
        box-shadow: inset -70px 80px ($block-size/2)+px #252526;
        transition: clip-path 300ms, background-color 300ms;
        transition: color 500ms ease, box-shadow 300ms, text-shadow 500ms ease, background-color 500ms ease, -webkit-transform 1s ease-in-out;
        color: #fff;
    }

    @media(max-width: 1049px) {
        clip-path: polygon(50% 25%, 95% 25%, 95% 75%, 50% 75%, 5% 75%, 5% 25%);
        float: left;
        position: relative;
        margin: -50px -0px;
        width: 20%;
        min-width: 100px;
        height: 250px; //150px;
        background-color: #4285F4;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: ($block-size/12)+px;
        cursor: pointer;
        color: #fff;
        box-shadow: inset -114px 160px ($block-size/2)+px #262625;
        transition: clip-path 300ms, background-color 300ms;
        transition: color 500ms ease, text-shadow 500ms ease, background-color 500ms ease, -webkit-transform 1s ease-in-out;
    }
}

.hexagon:hover {
    color: #fff;
    text-shadow: 0 0 10px #4285F4 !important;
    box-shadow: inset -75px 0px 85px #252526;
    background-color: #4285F4 !important;
}

.hexagon {
    cursor: pointer;

    &.disabled {
        color: #fff;
        cursor: not-allowed !important;
        text-shadow: 0 0 10px #428bca !important;
        box-shadow: inset 0 0 $block-size + px ($block-size/2)+px #252526;
        background-color: #1d598f !important;
        cursor: pointer;
        filter: none;
        -webkit-filter: grayscale(70%);
    }
}

.hexagnEagle {
    visibility: unset;
    -webkit-clip-path: polygon(50% 0, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
    clip-path: polygon(50% 0, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
    float: left;
    position: relative;
    margin: -18px -6px;
    width: $block-size + px;
    height: $block-size + px;
    background-color: transparent;
    background-position: center center;
    z-index: 1;
    grid-column: 2 span;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: ($block-size/10)+px;

    img {
        width: 75%;
    }

    @media(max-width: 845px) {
        clip-path: polygon(50% 25%, 95% 25%, 95% 75%, 50% 75%, 5% 75%, 5% 25%);
        margin: 0;
        margin-right: 0px;
        width: 0px;
        height: 0px;

        img {
            width: 0px;
            margin-left: 0px;
        }
    }
}

.menu-container {
    // @media(max-width: 575px){
    //     position: absolute; 
    //     top: 35%;
    // }
    // @media(min-width: 1200px){
    //     position: absolute; 
    //     left: 5%;
    //     top: 6%;
    //     right: 5%;
    //     bottom: 5%;

    // }
    // @media(min-width: 1280px){
    //     position: absolute; 
    //     left: 5%;
    //     top: 10%;
    //     right: 5%;
    //     bottom: 5%;

    // }
    // @media(min-width: 1600px){
    //     position: absolute; 
    //     left: 5%;
    //     top: 20%;
    //     right: 5%;
    //     bottom: 5%;

    // }
    // @media(min-width: 1920px){
    //     position: absolute; 
    //     left: 5%;
    //     top: 30%;
    //     right: 5%;
    //     bottom: 5%;

    // }
    // @media(min-width: 3840px){
    //     position: absolute; 
    //     left: 5%;
    //     top: 35%;
    //     right: 5%;
    //     bottom: 5%;

    // }

}

.imagemFundo {
    // @media(max-height: 480px){
    //     top: 9%;
    // }
    // @media(min-height: 500px){
    //     top: 8%;
    // }
    // @media(min-height: 540px){
    //     top: 7%;
    // }
    // @media(min-height: 680px){
    //     top: 6%;
    // }
    // @media(min-height: 720px){
    //     top: 6%;
    // }
    // @media(min-height: 768px){
    //     top: 5%;
    // }
    // @media(min-height: 900px){
    //     top: 4.5%;
    // }
    // @media(min-height: 1080px){
    //     top: 4%;
    // }
    // @media(min-height: 1440px){
    //     top: 3%;
    // }
    // @media(min-height: 1800px){
    //     top: 2%;
    // }
    // @media(min-height: 2160px){
    //     top: 2%;
    // }
    // @media(min-height: 2880px){
    //     top: 1.5%;
    // }
    // @media(min-height: 4320px){
    //     top: 1%;
    // }
    z-index: -1;
    position: absolute;
    left: 0%;
    right: 0%;
    bottom: 0%;
    height: 100%;
    background-image: url("../../../assets/fundoEagleLogin.png") !important;
    background-repeat: no-repeat !important;
    background-size: cover;
    background-position: center;
}

.hex-logo-modulo {
    width: 85%;
    height: auto;
}

.hex-logo-modulo-dif {
    width: 85%;
    height: auto;
}

.hex-logo-modulo-adm {
    width: 100%;
    height: auto;
}

.hex-logo {
    width: 85%;
    height: auto;
}
</style>
