<template>
    <b-modal
        :size="'xl'"
        :id="'modalDetalhesItemRota'"
        ref="modal"
        :title-html="defineTituloModal"
        @hide="modalClose"
    >
        <slot name="modal-body">
            <b-overlay :show="loading">
                <b-tabs v-model="pagina">
                    <b-tab title="Detalhes">
                        <div class="col-sm-12 nopadding">
                            <div v-if="validaCabecalhoColeta()" class="cabecalho">
                                <span class="cabecalho-item">
                                    Data/Hora: {{ dados.datahora }}
                                </span>
                                <span class="cabecalho-item">
                                   Endereço: {{ dados.endereco }}
                                </span>
                                <span class="cabecalho-item">
                                    Previsto no contrato: {{ dados.irpeso }}
                                </span>
                            </div>

                            <table id="tabelaDocumentos" class="col-sm-12">
                                <thead>
                                    <tr v-if="statusItemSelecionado == 'R'" class="tableHead">
                                        <th>Nome arquivo</th>
                                        <th>Data/hora</th>
                                        <th>Origem</th>
                                        <th></th>
                                    </tr>
                                    <tr v-else-if="statusItemSelecionado == 'IR'" class="tableHead">
                                        <th>Data/Hora</th>
                                        <th>Quantidade</th>
                                        <th>Unidade</th>
                                        <th>Cubagem</th>
                                        <th>Peso</th>
                                        <th>Valor</th>
                                        <th>Observações</th>
                                        <th v-if="dados.irstatus == 'J'">Justificativa</th>
                                    </tr>
                                    <template v-else-if="statusItemSelecionado == coleta">
                                        <tr style="line-height: 35px !important">
                                            <!-- <th colspan="2" class="nomeArquivo">
                                                Data/Hora: {{dados.datahora}}
                                            </th> -->
                                            <th colspan="2" v-if="dados.irstatus == 'J'">
                                                Justificativa: {{ dados.justificativa }}
                                            </th>
                                        </tr>
                                        <!-- <tr>
                                            <td class="mb-2">
                                                Documento de coletas:
                                                <div @click="downloadColetas">
                                                    <baseIcon 
                                                        class="downloadFilePC nopadding"
                                                        :icon='mdiDownloadOutline'
                                                        size="20"/>
                                                </div>
                                            </td>
                                        </tr> -->
                                        <tr class="tableHead">
                                            <th>Coleta</th>
                                            <th>Quantidade</th>
                                            <th>Unidade</th>
                                            <th>Observação</th>
                                        </tr>
                                    </template>
                                </thead>
                                <tbody>
                                    <template v-if="statusItemSelecionado == 'R'">
                                        <tr v-for="(dado, d) in dados" :key="d" class="tableBody">
                                            <td class="nomeArquivo">
                                                {{ dado.name }}
                                            </td>
                                            <td>{{ dado.datahora }}</td>
                                            <td>
                                                {{ retornaOperadorOuMotorista(dado) }}
                                            </td>
                                            <td @click="downloadDocumento(dado)">
                                                <baseIcon
                                                    class="downloadFilePC nopadding"
                                                    :icon="mdiDownloadOutline"
                                                    size="20"
                                                />
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else-if="statusItemSelecionado == 'IR'">
                                        <tr class="tableBody">
                                            <td class="nomeArquivo">
                                                {{ dados.datahora }}
                                            </td>
                                            <td>{{ dados.irqtde }}</td>
                                            <td>{{ dados.unidade }}</td>
                                            <td>{{ fnf(dados.ircubagem) }} m³</td>
                                            <td>{{ fnf(dados.irpeso) }} Kg</td>
                                            <td>R$ {{ dados.irvalor }}</td>
                                            <td> {{ dados.irobservacoes }} </td>
                                            <td v-if="dados.irstatus == 'J'">
                                                {{ dados.judescricao }}
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else-if="dados.coletas && dados.coletas.length">
                                        <tr
                                            v-for="(v, i) in dados.coletas"
                                            :key="i + '_coleta'"
                                            class="tableBody"
                                        >
                                            <td>{{ v.coleta }}</td>
                                            <td>{{ v.quantidade }}</td>
                                            <td>{{ v.unidade }}</td>
                                            <td>{{ v.observacao }}</td>
                                        </tr>
                                    </template>
                                    <tr v-else>
                                        <td colspan="4" class="tdVazio">Nenhuma informação</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </b-tab>
                    <b-tab v-if="statusItemSelecionado != 'R'" :title="defineTitleTabDocumentos">
                        <div
                            v-if="temDocumentos"
                            class="col-sm-12 nopadding"
                            style="height: 470px !important"
                        >
                            <carrossel :id="'refCarouselDetalhesRota'" :dados="dados.documentos">
                            </carrossel>
                        </div>
                        <div v-else class="pt-5 pb-3 text-center">
                            <h5>Nenhum documento encontrado</h5>
                        </div>
                    </b-tab>
                </b-tabs>
            </b-overlay>
        </slot>
        <slot slot="modal-footer">
            <template>
                <div>
                    <simpleButton
                        text="Fechar"
                        type="red"
                        :icon="mdiCloseThick"
                        event="click"
                        @click="fecharModalDocumentos"
                    />
                </div>
            </template>
        </slot>
    </b-modal>
</template>

<script>
import { mdiDownloadOutline, mdiCloseThick } from "@mdi/js";
import { HttpRequest } from "@/Services/auth/HttpRequest.Service";
export default {
    name: "ModalDetalhesItemRota",
    components: {
        simpleButton: require("@/components/Atom/Buttons/SimpleButton").default,
        baseIcon: require("@/components/Atom/Icon/BaseIcon").default,
        carrossel:
            require("@/components/Atom/SpecificComponents/DetalhesRota/CarrosselDocsItemRota")
                .default,
    },

    props: {
        dados: {
            type: [Array, Object],
            default: () => [],
        },
        statusItemSelecionado: {
            type: String,
            default: "",
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            url: "/roteirizador/relatorios/detalhes/rota/",
            mdiDownloadOutline: mdiDownloadOutline,
            mdiCloseThick: mdiCloseThick,
            pagina: 0,
            coleta: "C",
        };
    },

    methods: {
        validaCabecalhoColeta() {
            return this.statusItemSelecionado == this.coleta && ['J', 'F'].includes(this.dados.irstatus)
        },  
        /**
         * Função para formatar números float
         * @param {number|string} number
         * @return {number}
         */
        fnf(number) {
            number = Number(number);
            if (!number || typeof number != "number") return 0;
            return parseFloat(number).toFixed(2);
        },

        /**
         * @listen click - executado quando acionado o ícone de donwload
         * @param {Object[]} file - dados do arquivo
         * @param {String} file.datahora  - data e hora em que o arquivo foi salvo
         * @param {Array}  file.documento - Arquivo codificado
         * @param {String} file.name      - Nome do arquivo
         * @param {String} file.type      - Tipo do arquivo
         * @return Download do arquivo | Erro
         * @author Rafael
         */
        downloadDocumento(file) {
            const linkSource = this.getDataSource(file);
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = file.name + "." + (file.type ? file.type : "jpg");
            downloadLink.click();
        },

        getDataSource(documento) {
            let type = documento.type ? documento.type : "image/jpg";
            return `data:${type};base64,${documento.documento}`;
        },

        fecharModalDocumentos() {
            this.$bvModal.hide("modalDetalhesItemRota");
        },

        defineHtmlStatus(status) {
            switch (status) {
                case "J":
                    return '<div class="justificado pd">Justificado</div>';
                case "R":
                    return '<div class="roteirizado pd">Roteirizado</div>';
                case "F":
                    return '<div class="finalizado pd">Finalizado</div>';
                default:
                    return '<div class="pendente pd">Pendente</div>';
            }
        },

        retornaOperadorOuMotorista(dado) {
            return dado.origem == "O" ? "Operador" : dado.origem == "M" ? "Motorista" : "";
        },

        modalClose() {
            this.pagina = 0;
        },
    },

    computed: {
        /**
         * Define título do modal conforme item selecionado
         */
        defineTituloModal() {
            let title = '<div class="titleModalDetalhesRota">';
            if (this.dados.irnome) {
                title += '<div class="pd">' + this.dados.irnome + "</div>";
                title += this.defineHtmlStatus(this.dados.irstatus);
            }
            title += "</div>";
            return title;
        },

        temDocumentos() {
            return this.dados && this.dados.documentos && this.dados.documentos.length;
        },

        defineTitleTabDocumentos() {
            if (this.temDocumentos) {
                return `Documentos (${this.dados.documentos.length})`;
            }
            return "Documentos";
        },
    },
};
</script>

<style lang="scss">
#modalDetalhesItemRota {
    .cabecalho {
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: #ccc;
        width: 100%;
        height: 2rem;
        vertical-align: middle;
    }
    .cabecalho-item {
        font-style: italic;
        font-size: 1rem;
    }
    #tabelaDocumentos {
        padding: 10px;
        .tableHead {
            background: #f3f3f3;
            th {
                padding: 10px 0 5px 10px !important;
            }
        }
        .tableBody {
            border-bottom: 1px solid #f3f3f3;
            td {
                padding: 10px 0 5px 10px !important;
                .nomeArquivo {
                    min-width: 300px !important;
                }
            }
        }
        .downloadFilePC {
            cursor: pointer;
            color: #428bca;
        }
        .tdVazio {
            text-align: center;
            padding: 10px;
            border: 1px solid lightgrey;
        }
    }
    .titleModalDetalhesRota {
        display: inline-flex;
        .pendente {
            background-color: #f39e9e;
        }
        .finalizado {
            background-color: #bae9a8;
        }
        .roteirizado {
            background-color: #94bceb;
        }
        .justificado {
            background-color: #ecdd93;
        }
        .pd {
            padding: 3px 5px 0;
        }
        .btnpd {
            padding: 5px 10px;
        }
    }
}
</style>
