var CryptoJS = require("crypto-js");
//https://www.npmjs.com/package/crypto-js
export class Crypto {
    private static key = "";
    private set key(val: any) {
        Crypto.key = val;
    }
    private get key(): any {
        return Crypto.key;
    }

    constructor() {
        if (this.key == "") {
            var AcessToken = localStorage.getItem("a47d8987136b170a06bf4a974dabf168");
            if (AcessToken != null) this.GenerateKey(AcessToken);
        }
    }
    public GenerateKey(token: string) {
        localStorage.setItem("a47d8987136b170a06bf4a974dabf168", token);
        this.key = CryptoJS.SHA512(token).toString();
    }

    public Commit(place: string, value: string) {
        place = CryptoJS.SHA512(place).toString();
        const valueAES = CryptoJS.AES.encrypt(value, this.key).toString();
        localStorage.setItem(place, valueAES);
    }

    public Get(place: string) {
        place = CryptoJS.SHA512(place).toString();
        var value = localStorage.getItem(place);
        const valueAES = CryptoJS.AES.decrypt(value, this.key);
        return JSON.parse(valueAES.toString(CryptoJS.enc.Utf8) || "");
    }

    public Clear() {
        var versaoAtual: any = localStorage.getItem("versaoAtual");
        var versaoRecente: any = localStorage.getItem("versaoRecente");
        localStorage.clear();
        localStorage.setItem("versaoAtual", versaoAtual);
        localStorage.setItem("versaoRecente", versaoRecente);
        this.key = undefined;
    }
}
