import { Route, Menu, RouteModel, Permissions, Modulos } from "./RouteModel";
import router from '../../router'
import {Crypto} from '../auth/Crypto.Service'
import Vuex from '../../store/index'

let PERMISSION = "P3RM15510NS_0BJ3CT_34GL3_TR4CK";
let MODULES = "M0DUL05_34GL3_TR4CK";
export class RouterService {

    private PERMISSIONSKEY = "P3RM15510N5_K3Y_34GL3_TR4CK";
    private ATUALMODULE = "4TU4L_M0DUL3_34GL3_TR4CK";
    private MODULOKEY = "M0DUL05_34GL3_TR4CK"
    /**
        * seta as rotas brutas recebidas durante a authenticação
        @example SetRoutes(result.data.data.routes)
        @param modulo RouteModel
        @returns Array<Menu> | void
        @author Daniel Bruch
    */
    public SetRoutes(routes: RouteModel) {
        new Crypto().Commit(this.PERMISSIONSKEY, JSON.stringify(routes))
    }
    private GetRoutes(): string {
        return JSON.stringify(new Crypto().Get(this.PERMISSIONSKEY)) || "";
    }

    /**
     * Seta e retorna os modulos do cliente
     * @param modulo 
     */
    public SetModulos(modulos: RouteModel) {
        new Crypto().Commit(this.MODULOKEY, JSON.stringify(modulos))
    }
    private GetModulos(): string {
        return JSON.stringify(new Crypto().Get(this.MODULOKEY)) || "";
    }


    /**
        * retorna os Itens do menu de um Modulo Especifico. passando este modulo o sistema retorna todos os itens do menu
        @example Menu("telemetria")
        @param modulo:string
        @returns Array<Menu> | void
        @author Daniel Bruch
    */
    public Menu(modulo: string): Array<Menu> | void {
        var routes = JSON.parse(this.GetRoutes()!);
        if (routes instanceof Array) {
            var item = routes.filter(p => p.id == modulo || modulo == "jornada") as Route[]
            new Crypto().Commit(this.ATUALMODULE, JSON.stringify(item));
            if (item instanceof Array) {
                return item[0].menu as Menu[];
            }
        }
    }

    /**
        * Efetua a navegação entre as telas a partir da Rota cadastrada no Routes. 
        @example Navigate("Telemetria/Cadastro/pontos/editar/1")
        @param {string} route
        @returns null
        @author Daniel Bruch
    */
    public Navigate(route: string) {
        router.push(route)
    }

    /**
        * Efetua a navegação do menu para a rota cadastrada. 
        @example MenuNavigate("Telemetria/Cadastro/pontos/")
        @param route:string
        @returns null
        @author Daniel Bruch
    */
    public MenuNavigate(route: string) {
        var permission = new Permissions();
        var routes = new Crypto().Get(this.ATUALMODULE) || JSON.parse("{}");
        var ok = 0;
        if (routes) {
            routes[0].menu.forEach((p: { itens: any[]; }) => {
                var item = p.itens.filter(q => q.route == route);
                if(!ok){
                    if (item.length > 1) {
                        throw ("MULTIPLAS ROTAS ENCONTRADAS");
                    } else if (item.length > 0){
                        item.map((j: { permissoes: { visualizar: boolean; cadastrar: boolean; editar: boolean; excluir: boolean; master: string; importar: boolean; }; }) => {
                            permission.visualizar = j.permissoes.visualizar || false;
                            permission.cadastrar = j.permissoes.cadastrar || false;
                            permission.editar = j.permissoes.editar || false;
                            permission.excluir = j.permissoes.excluir || false;
                            permission.master = j.permissoes.master || "N";
                            permission.importar = j.permissoes.importar || false;
                        })
                        ok = 1;
                        new Crypto().Commit(PERMISSION, JSON.stringify(permission));
                    }
                }
            });
        }
    }
}

export class PermissionsService {

    /**
     * retorna as permissoes da rota atual
     * @param none
     * @returns Permissions
     * @deprecated utilizar methodo dentro de Vuex.Store 
     * @author Daniel Bruch
     */
    public  GetPermissions(): Permissions {
        try {
            return new Crypto().Get(PERMISSION)
        } catch (e){
            return new Permissions();
        }
    }

    public GetModulos(): Modulos {
        try {
            return new Crypto().Get(MODULES)
        } catch (e) {
            return new Modulos();
        }
    }
    

}