<template>
    <panelEagle id='Perfis' :loading="loadingPanel">
        <div class="col-sm-12 row">
            <div class="col-md-12 col-lg-6 nopadding">
                <tituloPage :icon='mdiMessageCogOutline' titulo='Perfis de Configuração'></tituloPage>
            </div>
            <div class="col-md-12 col-lg-6 nopadding divDontPrint">
                <basicButtonsCadastrosEIN
                linkNew="cadastrarPerfis"
                tipoNovo='button'
                :loading='loadingExporta'
                :disabledDropdown='$v.dadosListagem.$invalid'
                @exportarCadastro='exportarCadastro'>
                </basicButtonsCadastrosEIN>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class='col-sm-12 row nopadding'>
            <div class="col-lg-6 col-md-12 nopadding divDontPrint">
                <selectAll
                :labels="[{indexDFH:'MO', description: 'Modelo de rastreador'}]"
                nameForRadio='selectAll'
                :hasSelectAll='true'
                :isMultiple='true'
                :loading='loadingModelos'
                :optionsArray="optionsSelectModelos"
                @changeSelect="changeModelo"/>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class="col-sm-12 nopadding">
            <tableListagemDetalhes
            :botoesAcoes="true"
            class="col-sm-12"
            :conteudo = 'dadosListagem'
            :titulo = "[
                {
                    sortable: true, 
                    key:'codigo',
                    label:'Código',
                    class: 'text-left'

                },
                {
                    sortable: true, 
                    key:'descricao',
                    label:'Descrição',
                    class: 'text-left'

                },
                {
                    sortable: true, 
                    key:'modelo',
                    label:'Modelo',
                    class: 'text-left'

                },
            ]"
            >
                <template
                slot-scope={row}
                >
                    <basicButtonsListagemED
                    :id="String(row.index)"
                    @editarCadastro="editarPerfis(row.item.codigo)"
                    @excluirCadastro="excluirPerfis(row.item.codigo)">
                    </basicButtonsListagemED>
                </template>
                <template
                 #sanfona='{row}'
                :id="`${row.item.codigo}`">
                    {{row.item.comando}}
                </template>
            </tableListagemDetalhes>
        </div>
    </panelEagle>
</template>
<script lang='js'>
import Vue  from 'vue'
import Vuex from '@/store/index'
import { mdiMessageCogOutline, mdiEye } from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { FiltrosService }  from '@/Services/filtros/filtros.Service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { required } from 'vuelidate/lib/validators'
import { conectionError }      from '@/Services/Helpers/swellHeper'
export default Vue.extend({
    name:'PerfisAdministrativo',
    components:{
        'panelEagle'               : require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage'               : require('@/components/Atom/Header/Titulo').default,
        'tableListagemDetalhes'    : require('@/components/Atom/Table/TableListagemDetalhes').default,
        'basicButtonsListagemED'   : require('@/components/Atom/Buttons/BasicButtonsListagemED').default,
        'selectAll'                : require('@/components/Atom/Select/SelectAll').default,
        'basicButtonsCadastrosEIN' : require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
    },

    validations:{
        dadosListagem: { required },
    },

    data(){
        return {
            mdiMessageCogOutline   : mdiMessageCogOutline,
            iconEye:mdiEye,
            link : '/administrativo/cadastros/perfis/',
            modeloSelecionado    : [],
            optionsSelectModelos : [],
            loadingPanel         : false,
            loadingModelos       : false,
            loadingExporta       : [false, false, false],
            dadosListagem        : [],
            comandos             : [],
            titles: [
                {name:'Código',    number:'codigo'},
                {name:'Descrição', number:'descricao'},
                {name:'Modelo',    number:'modelo'},
                {name:'Comando',number:'comando'},

            ],
        }
    },
    methods:{

        async changeModelo(modelo){
            this.loadingPanel = true
            this.modeloSelecionado = modelo
            var dados = await new HttpRequest().Post(this.link+'listar', {'modelos':this.modeloSelecionado})
            this.dadosListagem = dados.data.perfis
            this.comandos = this.dadosListagem.comandos
            this.loadingPanel = false
        },

        async dadosFiltros(){
            this.loadingModelos = true
            this.loadingPanel = true
            var dados = await new FiltrosService().dados_filtros('',['MO'])
            this.optionsSelectModelos = dados['MO'];
            this.loadingModelos = false
            this.loadingPanel = false
        },

        editarPerfis(value){
            this.$router.push({name:'cadastrarPerfis', params:{'id':value}})
        },

        editarClick(cod){
            this.$emit('editarCadastro', cod)
        },
        async excluirPerfis(id){
            var dados = await new HttpRequest().Post(this.link+'excluir/perfis', 
                {'id':id})
            if(dados.data.status){
                this.changeModelo()
            } else {
                conectionError()
            }
        },

        exportarCadastro(type){
            if(this.dadosListagem.length > 0){
                switch (type) {
                    case 'pdf':
                        this.loadingExporta = [true, false, false]
                        break;
                    case 'xls':
                        this.loadingExporta = [false, true, false]
                        break;
                    default:
                        this.loadingExporta = [false, false, true]
                        break;
                }
                var obj = {
                    'tipo'    : type,
                    'dados'   : this.dadosListagem
                }
                var root =  process.env.VUE_APP_ROOT;
                new HttpRequest().Post(this.link+'exportar', obj).then((dados)=>{
                    open(root+'/'+dados.data.local);
                    this.loadingExporta = [false, false, false]
                }).catch(() =>{
                    conectionError()
                }).finally(() => {this.loadingExporta = [false, false, false]})
            }
        },
    },
    mounted(){
        this.dadosFiltros()
        this.changeModelo()
    }
})
</script>
<style lang="scss">

</style>