Componente para desenhar circulos no mapa simples 
props:
  radios: O raio do circulo
  center: lat-lang de onde o centro do circulo ira ficar
  color: Cor do circulo, default para azul

Eventos: 
  O componente em si não possui nenhum evento explicito
  mas é possível acessar @click (evento de click no circulo)

Esse componente deve ficar dentro do slot do componente mapa simples. 
Se for necessário usar esse componente junto de outro (ex: raio em volta de um marcador) 
tanto esse como o marcador devem "filhos" do slot do componente mapa. 
Para usar mais de um componente dentro de um slot pode ser necessário usar a tag 'template' 

<template>
  <div>
    <l-circle
      :lat-lng="center"
      :radius="Number(radius)"
      :color="color"
    >
    <slot></slot>
    </l-circle>
  </div>
</template>

<script>
  import Vue from 'vue'
  import {LCircle} from 'vue2-leaflet'
  export default Vue.extend({
    name:'Circulo',
    components: {
      LCircle,
    },
    props: {
      radius: {
        type: [Number, String],
        default: 1000
      },
      center: {
        type: Array,
        default: ()=>{return [-27.1001389, -52.6270369]}
      },
      color:{
        Type: String,
        default: 'blue'
      }
    }
  })
</script>

<style lang="scss" scoped>

</style>