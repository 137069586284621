Componente que vira a popup do ponto de acionamento de portas. 
Props: 
	acionamento: dados de um acionamento de porta
	acionamento.porta = nome da porta que gerou o acionamento 
		(báu, betoneira, porta motorista, etc)
	acionamento.data = data do acionamento, no formato hh:mm dd/mm
	acionamento.endereço = onde aconteceu
	acionamento.mtnome =  nome do motorista. 
<template>
    <div id='info-acionamento-portas-mapa'>
		<div class="col-12 title-popup">
			Informações sobre acionamento de portas
		</div>
		<div class="col-12">
			<span>Porta:</span>
			{{portaAcionamento}}
		</div>
		<div class="col-12">
			<span>Abriu as:</span> {{acionamento.data}}
		</div>
		<div class="col-12">
			<span>Em:</span> {{acionamento.endereco}}
		</div>
		<div class="col-12" v-if='acionamento.mtnome'>
			<span>Motorista:</span>{{acionamento.mtnome}}
		</div>
    </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
	name: 'infoAcionamentoPortasMapa',
	props:{
		acionamento:{
			type: Object,
			required: true,
		}
	},
	computed:{
		portaAcionamento(){
			var porta  = '';
			switch(this.acionamento.bimotivotransmissao){
				case 13:
					porta = 1
					break
				case 15:
					porta = 2
					break
				case 17:
					porta = 3
					break
				case 19:
					porta = 4
					break
				default:
					porta = ''
			}
			var nome = ''
			if(this.acionamento.porta)
				nome = `- ${this.acionamento.porta}`
			return `${porta} ${nome}`
		}
	}
})
</script>

<style lang="scss" scoped>
#info-acionamento-portas-mapa{
	width: 400px;
	min-height: fit-content;
	div{
		inline-size: 300px;
		overflow-wrap: break-word;
	}
	span{
		font-weight: bolder;
	}
	.title-popup{
		font-size: 14px;
		color: red;
	}
}

</style>