<template>
    <panel-eagle id="fechamentoJornada" :loading="loading">
        <div class="col-12 row">
            <div class="col-3 nopadding">
                <titulo :icon="mdiTableCheck" titulo="Fechamento" />
            </div>
            <div class="col-9 nopadding">
                <basicButtonsRelatoriosEIG
                    :loading="loadingExportar"
                    :disabledDropdown="!showTable"
                    :disabled="$v.$invalid"
                    @gerarRelatorio="gerarRelatorio"
                    @exportarRelatorio="exportarRelatorio"
                >
                    <simpleButton
                        v-if="podeExcluir"
                        :disabled="!temFechamento"
                        width="180px"
                        text="Excluir Fechamento"
                        :icon="mdiTrashCan"
                        type="red"
                        event="click"
                        @click="abreModalExcluirFechamento"
                    />
                    <simple-button
                        v-if="podeCadastrar"
                        :icon="mdiCheckBold"
                        :disabled="disableConfirmaFechamento"
                        width="180px"
                        text="Confirmar Fechamento"
                        event="click"
                        @click="abreModalFechamento"
                    />
                </basicButtonsRelatoriosEIG>
            </div>
        </div>
        <slide-up-and-down>
            <div class="col-sm-12"><hr /></div>
            <div class="col-12 nopadding row mb-2">
                <div class="col-3 nopadding">
                    <input-range
                        :isObrigatorio="true"
                        name="range-extrato"
                        @changeInput="changeIntervalo"
                    />
                </div>
                <div class="col-3 nopadding">
                    <select-all
                        nameForRadio="selCliente"
                        :isMultiple="false"
                        :hasSelectAll="false"
                        :labels="labelSelectCliente"
                        :optionsArray="optClientes"
                        firstSelected="E"
                        :selected="selectedClientes"
                        :extraClass="{
                            'border border-danger':
                                $v.clienteSelecionado.$anyError,
                        }"
                        @close="$v.clienteSelecionado.$touch()"
                        @changeSelect="changeEmpresa"
                    />
                </div>
                <div class="col-3 nopadding">
                    <select-all
                        nameForRadio="selJornada"
                        ref="selJornada"
                        :labels="labelSelectJornada"
                        :isMultiple="false"
                        :disabled="!optJornada.length"
                        :hasSelectAll="false"
                        :loading="loadingJornada"
                        :optionsArray="optJornada"
                        :extraClass="{
                            'border border-danger':
                                $v.jornadaSelecionada.$anyError,
                        }"
                        firstSelected="J"
                        @close="$v.jornadaSelecionada.$touch()"
                        @changeSelect="changeJornada"
                    />
                </div>
                <div class="col-3 nopadding">
                    <select-all
                        nameForRadio="selTrabalhador"
                        ref="selTrabalhador"
                        :labels="labelSelectTrabalhador"
                        :isMultiple="true"
                        :disabled="!optTrabalhador.length"
                        :hasSelectAll="true"
                        :loading="loadingTrabalhador"
                        :optionsArray="optTrabalhador"
                        :extraClass="{
                            'border border-danger':
                                $v.colabsSelecionado.$anyError,
                        }"
                        firstSelected="C"
                        @close="$v.colabsSelecionado.$touch()"
                        @changeSelect="changeTrabalhador"
                    />
                </div>
            </div>
        </slide-up-and-down>
        <div class="mt-2 col-12">
            <table-simples>
                <thead>
                    <th>
                        <div class="check-nome">
                            <b-check
                                v-model="fecharTodos"
                                @change="mudaCheckFechar"
                                :disabled="!showTable"
                            >
                                Colaborador
                            </b-check>
                        </div>
                    </th>
                    <th>Total</th>
                    <th>Trabal.</th>
                    <th>Espera</th>
                    <th v-if="mostrarExtra1">H.E.1</th>
                    <th v-if="mostrarExtra2">H.E.2</th>
                    <th v-if="mostrarExtra3">H.E.3</th>
                    <th>100%</th>
                    <th>100% Noturno</th>
                    <th>Falta</th>
                    <th>Noturna</th>
                    <th v-if="mostrarReduzida">Noturna Reduzida</th>
                    <th>Extra Noturno</th>
                    <th v-if="mostrarSaldo">Saldo</th>
                    <th>Ult. quitação do banco</th>
                    <th>
                        <b-check
                            v-model="quitarTodos"
                            @change="mudaCheckQuitarBanco"
                            :disabled="!showTable"
                        >
                            Quitar banco
                        </b-check>
                    </th>
                </thead>
                <tbody v-if="showTable">
                    <tr
                        v-for="(c, index) in tableShow"
                        :key="`${index}_table_data`"
                    >
                        <td v-bind="bindMtnome(c)">
                            <b-check
                                v-model="c.fechar"
                                :disabled="!c.pode_fechar"
                            >
                                {{ c.mtnome }}
                            </b-check>
                        </td>
                        <td v-text="textoOuZero(c.folha.fptempototal)" />
                        <td v-text="textoOuZero(c.folha.fphoranormal)" />
                        <td v-text="textoOuZero(c.folha.fpesperatempo)" />
                        <td
                            v-if="mostrarExtra1"
                            v-text="textoOuZero(c.folha.fpextra1)"
                        />
                        <td
                            v-if="mostrarExtra2"
                            v-text="textoOuZero(c.folha.fpextra2)"
                        />
                        <td
                            v-if="mostrarExtra3"
                            v-text="textoOuZero(c.folha.fpextra3)"
                        />
                        <td v-text="textoOuZero(c.folha.fpextra100)" />
                        <td v-text="textoOuZero(c.folha.fpextra100noturna)" />
                        <td v-text="textoOuZero(c.folha.fphorasfalta)" />
                        <td v-text="textoOuZero(c.folha.fptotalnoturnas)" />
                        <td
                            v-if="mostrarReduzida"
                            v-text="textoOuZero(c.folha.fpreduzida)"
                        />
                        <td v-text="textoOuZero(c.folha.fpextranoturno)" />
                        <td
                            v-if="mostrarSaldo"
                            v-text="textoOuZero(c.saldo.saldo_total)"
                        />
                        <td v-text="ultimoQuitamento(c, true, true)" />
                        <td>
                            <b-check
                                v-model="c.quitar"
                                :disabled="!c.pode_fechar"
                            >
                                Quitar
                            </b-check>
                        </td>
                    </tr>
                    <tr :key="'totais'" style="background-color:#d5d5d5">
                        <td class="text-right">Totais:</td>
                        <td v-text="textoOuZero(this.totais.fptempototal)" />
                        <td v-text="textoOuZero(this.totais.fphoranormal)" />
                        <td v-text="textoOuZero(this.totais.fpesperatempo)" />
                        <td
                            v-if="mostrarExtra1"
                            v-text="textoOuZero(this.totais.fpextra1)"
                        />
                        <td
                            v-if="mostrarExtra2"
                            v-text="textoOuZero(this.totais.fpextra2)"
                        />
                        <td
                            v-if="mostrarExtra3"
                            v-text="textoOuZero(this.totais.fpextra3)"
                        />
                        <td v-text="textoOuZero(this.totais.fpextra100)" />
                        <td
                            v-text="textoOuZero(this.totais.fpextra100noturna)"
                        />
                        <td v-text="textoOuZero(this.totais.fphorasfalta)" />
                        <td v-text="textoOuZero(this.totais.fptotalnoturnas)" />
                        <td
                            v-if="mostrarReduzida"
                            v-text="textoOuZero(this.totais.fpreduzida)"
                        />
                        <td v-text="textoOuZero(this.totais.fpextranoturno)" />
                        <td
                            v-if="mostrarSaldo"
                            v-text="textoOuZero(this.totais.saldo)"
                        />
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
                <status-bar
                    v-else
                    :statusBar="statusBar"
                    typeBar="tr"
                    :colspanForTd="colspanTotal"
                />
            </table-simples>
        </div>
        <modalDeleta
            id="confirmaFechamentoJob"
            title="Confirmar Fechamento"
            @confirmaExclusao="confirmaFechamento"
        />
        <modalEagle
            id="redireciona"
            size="md"
            @cancelButton="cancelaRedirecionamento"
            @modalClose="cancelaRedirecionamento"
            :title="'Fechamento realizado com sucesso'"
        >
            <template #modalBody>
                <div class="col-sm-12 text-center p-3">
                    Deseja analisar a jornada dos colaboradores na Folha Ponto
                    ou Jornada de Trabalho?
                </div>
                <div class="col-sm-12 text-center p-3">
                    <simple-button
                        type="blue"
                        event="click"
                        width="150px"
                        :icon="mdiAccountClock"
                        text="Jornada Trabalho"
                        @click="
                            () => confirmaRedirecionamento('JornadaTrabalho')
                        "
                    />
                    <simple-button
                        type="blue"
                        event="click"
                        width="150px"
                        :icon="mdiCardAccountDetails"
                        text="Folha Ponto"
                        @click="() => confirmaRedirecionamento('FolhaPonto')"
                    />
                </div>
            </template>
            <template #modalFooter>
                <simple-button
                    type="red"
                    event="click"
                    text="Cancelar"
                    :icon="mdiCloseThick"
                    @click="cancelaRedirecionamento"
                />
            </template>
        </modalEagle>
        <modalExcluirFechamento
            ref="modalExcluirFechamento"
            id="modalExcluirFechamento"
            @recarregar-tabela="gerarRelatorio"
        />
    </panel-eagle>
</template>

<script>
import Vue from "vue";
import { DateTime } from "luxon";
import { required } from "vuelidate/lib/validators";
import {
    mdiTableCheck,
    mdiCheckBold,
    mdiCloseThick,
    mdiTrashCan,
    mdiCardAccountDetails,
    mdiAccountClock,
} from "@mdi/js";
import { EmpresasService } from "@/Services/auth/Empresas.service";
import { FiltrosService } from "@/Services/filtros/filtros.Service";
import { HttpRequest } from "@/Services/auth/HttpRequest.Service";
import { mapGetters } from "vuex";
import { conectionError } from "@/Services/Helpers/swellHeper";

export default Vue.extend({
    name: "FechamentoJornada",
    components: {
        modalDeleta: require("@/components/Atom/ModalSpecific/ModalDeleta.vue")
            .default,
        modalEagle: require("@/components/Atom/Modal/ModalEagle.vue").default,
        modalExcluirFechamento:
            require("@/components/Atom/SpecificComponents/Fechamento/ExcluirFechamento.vue")
                .default,
        slideUpAndDown:
            require("@/components/Atom/SlideUpAndDown/SlideUpAndDown.vue")
                .default,
        panelEagle: require("@/components/Atom/Panel/PanelEagle").default,
        basicButtonsRelatoriosEIG:
            require("@/components/Atom/Buttons/BasicButtonsRelatoriosEIG")
                .default,
        titulo: require("@/components/Atom/Header/Titulo").default,
        selectAll: require("@/components/Atom/Select/SelectAll").default,
        inputRange: require("@/components/Atom/Datas/InputRangeWithLimit")
            .default,
        tableSimples: require("@/components/Atom/Table/TableSimples").default,
        statusBar:
            require("@/components/Atom/StatusInformation/StatusInformation.vue")
                .default,
        simpleButton: require("@/components/Atom/Buttons/SimpleButton.vue")
            .default,
    },

    data() {
        return {
            url: "/controle/jornada/fechamento/",
            //icon
            mdiTableCheck: mdiTableCheck,
            mdiCheckBold: mdiCheckBold,
            mdiCloseThick: mdiCloseThick,
            mdiTrashCan: mdiTrashCan,
            mdiCardAccountDetails: mdiCardAccountDetails,
            mdiAccountClock: mdiAccountClock,
            statusBar: "info",
            loading: false,
            loadingExportar: [false, false, false],
            mostraTabela: false,
            loadingJornada: false,
            loadingTrabalhador: false,
            quitarTodos: false,
            fecharTodos: false,
            //controle
            tableData: [],
            totais: {},
            fechamentos: [],
            ultFechamento: {},
            //opts
            optClientes: [],
            selectedClientes: [],
            optTrabalhador: [],
            optJornada: [],
            //labels
            labelSelectTrabalhador: [
                { indexDFH: "C", description: "Colaboradores*" },
            ],
            labelSelectJornada: [{ indexDFH: "J", description: "Jornada*" }],
            labelSelectCliente: [{ indexDFH: "E", description: "Empresa*" }],
            //selecionados
            clienteSelecionado: [],
            colabsSelecionado: [],
            jornadaSelecionada: [],
            intervalo: "",
            colspanTotal: 16,
            permissoes: {},
        };
    },

    mounted() {
        this.optClientes = new EmpresasService().Get();
        if (!this.getMaster()) {
            this.selectedClientes = this.optClientes
                ? [this.optClientes[0]]
                : [];
        }
    },

    validations: {
        clienteSelecionado: { required },
        colabsSelecionado: { required },
        jornadaSelecionada: { required },
    },

    methods: {
        ...mapGetters(["getMaster"]),

        /**
         * @listens changeSelect
         * @param {number[]} arr
         */
        changeTrabalhador(arr) {
            this.tableData = [];
            this.colabsSelecionado = arr;
        },

        /**
         * @listens changeSelect
         * @param {number[]} arr - clientes selecionados
         * @description muda o cliente selecionado e busca pelas
         * opts do seletor de funcionário
         */
        changeEmpresa(arr) {
            this.tableData = [];
            this.clienteSelecionado = arr;
            this.jornadaSelecionada = [];
            this.$refs.selJornada.clearAll();
            this.$v.jornadaSelecionada.$reset();
            this.optJornada = [];
            this.changeJornada([]);
            if (arr.length) {
                this.loadingTrabalhador = true;
                this.loadingJornada = true;
                new FiltrosService()
                    .dados_filtros(this.clienteSelecionado, ["J"])
                    .then((res) => {
                        this.optJornada = res.J ?? [];
                    })
                    .finally(() => {
                        this.loadingTrabalhador = false;
                        this.loadingJornada = false;
                    });
            }
        },

        /**
         * @listens changeSelect
         * @param {number[]} arr
         * @description muda a jornada selecionada
         */
        changeJornada(arr) {
            this.tableData = [];
            this.jornadaSelecionada = arr;
            this.colabsSelecionado = [];
            this.$refs.selTrabalhador.clearAll();
            this.$v.colabsSelecionado.$reset();
            this.optTrabalhador = [];
            if (arr.length) {
                this.loadingTrabalhador = true;
                new FiltrosService()
                    .dados_filtros(this.jornadaSelecionada, ["CJ"])
                    .then((res) => {
                        this.optTrabalhador = res.CJ ?? [];
                    })
                    .finally(() => {
                        this.loadingTrabalhador = false;
                    });
            }
        },

        /**
         * @listens changeInput
         * @param {string} intervalo formato dd/mm/yyyy - dd/mm/yyyy
         */
        changeIntervalo(intervalo) {
            this.intervalo = intervalo;
            this.tableData = [];
        },

        abreModalExcluirFechamento() {
            this.$refs.modalExcluirFechamento.listaFechamentos(
                this.ultFechamento,
                "job"
            );
            this.$bvModal.show("modalExcluirFechamento");
        },

        /**
         * @param {string} texto - a ser exibido.
         * @return {string} texto ou 00:00:00
         */
        textoOuZero(texto) {
            return texto ?? "00:00:00";
        },

        /**
         * @param {'pdf'|'xls'|'csv'|'none'} formato
         */
        decideExportar(formato) {
            this.loadingExportar = [
                formato === "pdf",
                formato === "xls",
                formato === "csv",
            ];
        },

        cancelaRedirecionamento() {
            this.$bvModal.hide("redireciona");
            this.gerarRelatorio();
        },

        abreModalFechamento() {
            this.$bvModal.show("modal-deleta-confirmaFechamentoJob");
        },

        confirmaRedirecionamento(name) {
            var objeto = {
                intervalo: this.intervalo,
                clientes: this.clienteSelecionado,
                jornadas: this.jornadaSelecionada,
                colaboradores: this.colabsSelecionado,
            };
            this.$router.push({ name: name, params: objeto });
        },

        bindMtnome(wr) {
            if (wr?.errors) {
                return {
                    disabled: true,
                    title: "Existe um ou mais erros com os dados desse colaborador",
                };
            }
            var nao_pode_fechar = !this.podeFechar(wr);
            if (nao_pode_fechar) {
                return {
                    disabled: true,
                    title: "Já possui fechamento neste período",
                };
            }
        },

        /**
         * @description procura a data do último quitamento
         * @param { object } wr
         * @param { object[] } wr.fechamentos
         * @param { boolean } formatado - se o retorno deve voltar como string ou
         * obj do tipo DateTime
         * @return { string|DateTime} data do último quitamento do funcionário
         */
        ultimoQuitamento(wr, quitamento, formatado) {
            if (wr.fechamentos?.length) {
                let quitados = wr.fechamentos;
                if (quitamento) {
                    quitados = wr.fechamentos.filter((e) => e.jfdquitado);
                }
                if (!quitados || !quitados.length) {
                    return "";
                }
                let ult_quitado = quitados.at(0).jffim.split(" ")[0];
                ult_quitado = DateTime.fromISO(ult_quitado);
                if (formatado) ult_quitado = ult_quitado.toFormat("dd/LL/yyyy");
                return ult_quitado;
            }
            return "";
        },

        /**
         * @param {object} wr
         * @param {object[]} wr.periodo
         * @param {string} wr.periodo[].jfinicio - inicio do periodo de fechamento
         * @param {string} wr.periodo[].jffim    - final do periodo de fechamento
         * @return {string[]} inicio, fim, no formato dd/mm/yyyy, com TODAS
         * as datas de fechamento do carinha.
         */
        periodoUltimoFechamento(wr) {
            var arr_todas_datas = [];
            var format = "dd/LL/yyyy";
            if (wr.fechamentos?.length) {
                var fechamentos = wr.fechamentos;
                // var fechamentos = wr.fechamentos.filter(e=>e.jfdquitado);
                fechamentos.forEach((f) => {
                    var ini = DateTime.fromISO(
                        f.jfinicio.split(" ")[0]
                    ).toFormat(format);
                    var fim = DateTime.fromISO(f.jffim.split(" ")[0]).toFormat(
                        format
                    );
                    var dias = this.criaArrayDatas(ini, fim, format);
                    arr_todas_datas = arr_todas_datas.concat(dias);
                });
            }
            return arr_todas_datas;
        },

        /**
         * @description confirma se o um funcionário pode ou não
         * ter um fechamento 😃
         * @returns {boolean}
         */
        podeFechar(wr) {
            let ultimoQuitamento = this.ultimoQuitamento(wr, false, false);
            ultimoQuitamento = DateTime.fromISO(ultimoQuitamento);
            let fimIntervalo = this.intervalo
                .split(" - ")[1]
                .split("/")
                .reverse()
                .join("-");
            fimIntervalo = DateTime.fromISO(fimIntervalo);
            if (ultimoQuitamento >= fimIntervalo) return false;

            var formato = "dd/LL/yyyy";
            var fechamentos = this.periodoUltimoFechamento(wr);
            var intervalo_relatorio = this.criaArrayDatas(
                ...this.intervalo.split(" - "),
                formato
            );

            var inter = _.intersection(intervalo_relatorio, fechamentos);
            if (inter.length) return false;
            return true;
        },

        /**
         * @param {string} ini inicio do período
         * @param {string} final final do periodo
         * @param {string} format formato da data
         * @return {string[]}	todas as datas incluindo o inico e fim
         */
        criaArrayDatas(ini, final, format) {
            var ini_data = DateTime.fromFormat(ini, format);
            var fim_data = DateTime.fromFormat(final, format);
            var retorno = [];
            var diff = fim_data.diff(ini_data, "days").toObject().days;
            for (let i = 0; i <= diff; i++) {
                var novo_dia = ini_data.plus({ days: i });
                retorno.push(novo_dia.toFormat(format));
            }
            return retorno;
        },

        mudaCheckFechar() {
            this.tableData.forEach((e) => {
                if (e.pode_fechar) {
                    e.fechar = this.fecharTodos;
                }
            });
        },

        mudaCheckQuitarBanco() {
            this.tableData.forEach((e) => {
                if (e.pode_fechar) {
                    e.quitar = this.quitarTodos;
                }
            });
        },

        /**
         * Realiza fechamento, no futuro
         */
        confirmaFechamento() {
            var url = `${this.url}confirma/fechamento`;
            var obj = {
                selecionados: this.funcionariosFechamento,
                periodo: this.intervalo,
                cliente: this.clienteSelecionado[0],
            };
            this.loading = true;
            new HttpRequest()
                .Post(url, obj)
                .then(({data, status, code}) => {
                    if (status && code === 201) {
                        this.$bvModal.show("redireciona");
                    } else {
                        conectionError()
                    }
                })
                .catch(e => {
                    conectionError()
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        /**
         * @param {'xls'|'csv'|'pdf'} formato
         * @description exporta o relatório p/ o formato
         * desejado.
         */
        exportarRelatorio(formato) {
            let uri = `${this.url}exportar/${formato}`;
            let obj = {
                data: {registros:this.tableData,totais:this.totais},
                periodo: this.intervalo,
            };
            this.decideExportar(formato);
            new HttpRequest()
                .Post(uri, obj)
                .then(({ data, status }) => {
                    if (status) {
                        var root = process.env.VUE_APP_ROOT;
                        open(root + "/" + data.local);
                    }
                })
                .finally(() => {
                    this.decideExportar("none");
                });
        },

        /**
         * @listens click - botão gerar
         * @description gera o relatório de fechamento
         */
        gerarRelatorio() {
            let uri = `${this.url}gerar`;
            let obj = {
                workers: this.colabsSelecionado,
                intervalo: this.intervalo,
                cliente: this.clienteSelecionado,
            };
            this.tableData = [];
            this.fecharTodos = false;
            this.quitarTodos = false;
            this.loading = true;
            new HttpRequest()
                .Post(uri, obj)
                .then(({ data, status }) => {
                    if (status) {
                        this.tableData = Object.values(
                            data?.info?.registros ?? []
                        );
                        this.totais = data?.info?.totais ?? {};
                        this.ultFechamento = data.ultFechamento;
                        if (this.tableData.length === 0) {
                            this.statusBar = "error";
                        }
                    } else {
                        this.statusBar = "error2";
                        conectionError();
                    }
                })
                .catch(() => {
                    conectionError();
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },

    computed: {
        tableShow() {
            this.tableData.forEach((e) => {
                e.pode_fechar = this.podeFechar(e);
            });
            return this.tableData;
        },

        podeCadastrar() {
            return this.permissoes.cadastrar ?? false;
        },

        podeExcluir() {
            return this.permissoes.excluir ?? false;
        },

        disableConfirmaFechamento() {
            return !this.tableData.some((a) => {
                return a.fechar;
            });
        },

        temFechamento() {
            let tem_fechamento = this.tableData.some((b) => {
                return !!b.fechamentos?.length;
            });
            return tem_fechamento;
        },

        showTable() {
            return !!this.tableData.length;
        },

        funcionariosFechamento() {
            return this.tableData.filter((w) => {
                return w.fechar;
            });
        },

        mostrarExtra1() {
            return this.tableData.some((a) => {
                return a.jornada.extra1;
            });
        },

        mostrarExtra2() {
            return this.tableData.some((a) => {
                return a.jornada.extra2;
            });
        },

        mostrarExtra3() {
            return this.tableData.some((a) => {
                return a.jornada.extra3;
            });
        },

        mostrarReduzida() {
            return this.tableData.some((a) => {
                return a.jornada.jtreduzida;
            });
        },

        mostrarSaldo() {
            return this.tableData.some((a) => {
                return a.jornada.jtvisualizarsaldoextras;
            });
        },
    },

    watch: {
        "$store.state.permissoes": {
            immediate: true,
            handler() {
                this.permissoes = this.$store.state.permissoes;
            },
        },
    },
});
</script>

<style lang="scss" scoped>
#fechamentoJornada {
    .check-nome {
        padding-left: 7px;
    }
    .mtnome {
        width: 4000px;
    }
    .custom-control {
        z-index: 0;
    }
}
</style>
