<template>
  <div class="tableFixHead" ref="tableDiv">
    <table class="table" :id="id">
      <slot></slot>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  /**
   * Criei esse event listener para
   * fica escutando o evento de scroll
   * dentro da tabela. Fiz ele para
   * caso seja necessario fazer uma pagination
   * no back-end e um lazy-loading no front.
   * https://www.javascripttutorial.net/javascript-dom/javascript-infinite-scroll/
   * @author Marcos 
  */
  mounted() {
    const tableDiv = this.$refs.tableDiv;
    tableDiv.addEventListener("scroll", () => {
        const { 
            scrollTop,
            scrollHeight, 
            clientHeight
        } = tableDiv;
        const fimDaRolagem = (
            (scrollTop + clientHeight) >= scrollHeight - 5
        )
        if (fimDaRolagem) this.$emit('fimDaRolagem');
    });
  },
};
</script>
<style lang="scss" scoped>
.hrDeBaixo {
  margin-top: 140px;
}

.tableFixHead {
  overflow-y: auto;
  table {
    margin-bottom: 0;
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
    max-width: 100%;
    thead {
      th {
        position: sticky;
        top: -1px;
        z-index: 1;
        padding: 0.2rem;
        background: #f5f5f5;
        color: #514e4e;
        font-weight: bold;
        font-size: 13px;
      }
    }
    tbody tr {
      border-bottom: 1px solid #e5e5e5;
      background-color: #efefef;
    }
  }
}
@media screen and (max-height: 1300px) {
  .tableFixHead {
    max-height: 1140px;
  }
}
@media screen and (max-height: 1250px) {
  .tableFixHead {
    max-height: 1090px;
  }
}
@media screen and (max-height: 1200px) {
  .tableFixHead {
    max-height: 1040px;
  }
}
@media screen and (max-height: 1150px) {
  .tableFixHead {
    max-height: 990px;
  }
}
@media screen and (max-height: 1100px) {
  .tableFixHead {
    max-height: 940px;
  }
}
@media screen and (max-height: 1050px) {
  .tableFixHead {
    max-height: 890px;
  }
}
@media screen and (max-height: 1000px) {
  .tableFixHead {
    max-height: 840px;
  }
}
@media screen and (max-height: 950px) {
  .tableFixHead {
    max-height: 790px;
  }
}
@media screen and (max-height: 900px) {
  .tableFixHead {
    max-height: 740px;
  }
}
@media screen and (max-height: 850px) {
  .tableFixHead {
    max-height: 690px;
  }
}
@media screen and (max-height: 768px) {
  .tableFixHead {
    max-height: 610px;
  }
}
@media screen and (max-height: 720px) {
  .tableFixHead {
    max-height: 560px;
  }
}
@media screen and (min-height: 1300.1px) {
  .tableFixHead {
    max-height: 1170px;
  }
}
.tableFixHead {
  text-align: left;

  .backColorTdPDF {
    border-bottom: 1px solid #e5e5e5;
    background-color: #fff;

    td {
      background-color: #fff;
    }
    th {
      background-color: #fff;
    }

    .newbadge {
      min-width: 30%;
      padding: 8px;
      font-weight: 500;
      line-height: 20px;
      margin-top: 20px;
      height: 31px;
      border-radius: 0px;
      text-align: center;
      color: #fff;
      background-color: #777;
      white-space: nowrap;
      float: left;
      margin-left: 0px;
      margin-bottom: 0px;
    }
  }

  tr {
    border-bottom: 1px solid #e5e5e5;
    background-color: #efefef;

    td {
      padding-top: 0.15rem;
      padding-bottom: 0.15rem;
      font-size: 13px;
    }
    th {
      padding-top: 0.15rem;
      padding-bottom: 0.15rem;
      font-size: 13px;
    }
  }
}
.placa {
  background-color: #777;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  padding-left: auto;
  padding-right: auto;
  color: white;
  padding-top: 4px !important;
  font-size: 15px !important;
  text-align: center !important;
}
</style>
