<!-- eslint-disable vue/require-v-for-key -->
<template>
  <PanelEagle id="montagemdecargas" :loading="loadingRotas">
    <div class="col-sm-12 row nopadding" style="color: black">
      <div class="col-sm-8" style="color: black">
        <tituloPage
          :icon="mdiConsoleNetworkOutline"
          titulo="Controle de cargas"
        />
      </div>

      <div class="col-sm-4">
        <SimpleButton
          text="Gerar"
          :icon="mdiMonitorDashboard"
          type="green"
          event="gerar"
          @gerar="gerar(true, true)"
          style="float: right"
          :disabled="$v.$invalid || loadingRotas"
        />

        <SimpleButton
          text="Controle"
          :icon="mdiCheckBold"
          type="blue"
          event="gerar"
          @gerar="controle"
          style="float: right"
        />

        <SimpleButton
          text="Montagem"
          :icon="mdiConsoleNetworkOutline"
          type="orange"
          event="gerar"
          @gerar="montagem"
          style="float: right"
        />
      </div>

      <!-- Inputs -->
      <slideUpAndDown>
        <slot slot="filters">
          <div class="col-sm-12">
            <hr />
          </div>

          <div class="col-sm-12 row nopadding divDontPrint">
            <div class="col-sm-3 nopadding">
              <InputRangeWithLimit
                name="selectData"
                titulo="Intervalo"
                :isObrigatorio="true"
                @changeInput="changeData"
                :value="dataIntervalo"
              />
            </div>

            <div class="col-sm-3 nopadding">
              <SelectAll
                ref="selectEmpresasTickets"
                nameForRadio="seletorEmpresas"
                :labels="[{ indexDFH: 'E', description: 'Empresas*' }]"
                :optionsArray="optionsSelectEmpresas"
                firstSelected="E"
                :hasSelectAll="true"
                :isMultiple="true"
                @changeSelect="changeEmpresas"
              />
            </div>

            <div class="col-sm-3 nopadding">
              <SelectAll
                ref="selectVeiculosTickets"
                nameForRadio="seletorVeiculos"
                :labels="[{ indexDFH: 'V', description: 'Veículos' }]"
                :optionsArray="optionsSelectVeiculos"
                firstSelected="V"
                :hasSelectAll="true"
                :isMultiple="true"
                @changeSelect="changeVeiculos"
                :disabled="optionsSelectVeiculos.length <= 0"
              />
            </div>

            <div class="col-sm-3 nopadding">
              <SelectMarcadores
                ref="selectMarcadoresTickets"
                nameForRadio="seletorMarcadores"
                :key="'filtroMarcadorRotaManual'"
                :loading="optionsSelectMarcadores.length <= 0"
                :isMultiple="true"
                :hasSelectAll="true"
                :labels="[
                  { indexDFH: 'MR', description: 'Marcadores (Rotas)' }
                ]"
                firstSelected="MR"
                :optionsArray="optionsSelectMarcadores"
                :extraClassParent="'nopadding'"
                @changeSelect="changeMarcadores"
              />
            </div>

            <div class="col-sm-3 mt-2 nopadding mb-2 text-sm">
              <buttonsFilters
                @buttonsFiltersChange="chanceFiltro"
                :arrayButtons="arrayButtons"
                label="Filtros"
                :onlyOneSelected="false"
              >
              </buttonsFilters>
            </div>

            <div class="col-sm-3 nopadding mt-2 mb-2">
              <SelectAll
                ref="selectEmpresasTickets"
                nameForRadio="seletorCodigosCargas"
                :labels="[{ indexDFH: 'COC', description: 'Códigos Cargas' }]"
                :optionsArray="codigosCargas"
                :hasSelectAll="true"
                :isMultiple="true"
                :selected="codigosCargasSelected"
                @changeSelect="changeCodigoCarga"
              />
            </div>
          </div>
        </slot>
      </slideUpAndDown>

      <!-- Mapa -->
      <slideUpAndDown>
        <div class="col-sm-12 mt-3">
          <mapaSimples height="350" ref="mapaSimples">
            <!-- Informações da rota -->
            <infoRotaOsrm :dados="rotaOsrmMapa" />

            <!-- Pontos Selecionados -->
            <ClusterSelecionados
              :disableClusterZoom="16"
              :maxClusterRadius="20"
              :chunkDelay="50"
              :chunkInterval="50"
            >
              <div
                v-for="(
                  cargaPendente, index
                ) in cargasPendentesSelecionadasMapa"
                v-bind:key="index"
              >
                <div
                  v-for="(item, indexItem) in cargaPendente.itensdesagrupados"
                  v-bind:key="indexItem"
                >
                  <span v-if="item.polatitude && item.polongitude">
                    <span
                      v-if="item.polatitude != null && item.polongitude != null"
                    >
                      <l-marker
                        :lat-lng="[item.polatitude, item.polongitude]"
                        :icon="
                          gerarIconeMapa(cargaPendente.posicao, indexItem, true)
                        "
                      >
                        <PopupMarker>
                          <div class="col-sm-12 nopadding div-popup">
                            <div class="d-flex align-items-center">
                              <baseIcon
                                size="30"
                                class="styleIcon mr-1"
                                :icon="mdiMapMarkerCircle"
                              />
                              <p class="fs-4">{{ item.origem }}</p>
                            </div>

                            <div class="styleDivider mb-2 mt-2"></div>

                            <div>
                              <div class="d-flex align-items-center">
                                <baseIcon
                                  size="30"
                                  class="styleIcon mr-1"
                                  :icon="mdiOfficeBuildingMarker"
                                />
                                <p class="fs-4">{{ item.endereco }}</p>
                              </div>

                              <SimpleButton
                                text="Ponto"
                                :icon="mdiPencilOutline"
                                type="green"
                                event="gerar"
                                @gerar="
                                  pontoInconformidade(
                                    item.ircodigoexterno,
                                    cargaPendente
                                  )
                                "
                                :disabled="$v.$invalid || loadingRotas"
                              />
                            </div>
                          </div>
                        </PopupMarker>
                      </l-marker>
                    </span>
                  </span>
                </div>
              </div>
            </ClusterSelecionados>

            <!-- Pontos Rotas Pendentes -->
            <ClusterSelecionados
              :disableClusterZoom="16"
              :maxClusterRadius="20"
              :chunkDelay="50"
              :chunkInterval="50"
            >
              <div v-for="(ponto, index) in pontosRota" v-bind:key="index">
                <l-marker
                  :lat-lng="[ponto.polatitude, ponto.polongitude]"
                  :icon="gerarIconeMapa(ponto.posicao, index, false)"
                >
                  <PopupMarker>
                    <div class="col-sm-12 row nopadding div-popup">
                      <div class="d-flex align-items-center">
                        <baseIcon
                          size="30"
                          class="styleIcon mr-1"
                          :icon="mdiMapMarkerCircle"
                        />
                        <p class="fs-4">{{ ponto.origem }}</p>
                      </div>

                      <div class="styleDivider mb-2 mt-2"></div>

                      <div class="d-flex align-items-center">
                        <baseIcon
                          size="30"
                          class="styleIcon mr-1"
                          :icon="mdiOfficeBuildingMarker"
                        />
                        <p class="fs-4">{{ ponto.poendereco }}</p>
                      </div>
                    </div>
                  </PopupMarker>
                </l-marker>
              </div>
            </ClusterSelecionados>

            <!-- Ponto de Origem -->
            <div v-if="mostrarPontoOrigem">
              <l-marker
                :lat-lng="[pontoInicial.polatitude, pontoInicial.polongitude]"
                :icon="getIconInicialFinal(1)"
              >
                <PopupMarker>
                  <div class="col-sm-12 row nopadding div-popup">
                    <div class="d-flex align-items-center">
                      <baseIcon
                        size="30"
                        class="styleIcon mr-1"
                        :icon="mdiMapMarkerCircle"
                      />
                      <p class="fs-4">{{ pontoInicial.origem }}</p>
                    </div>

                    <div class="styleDivider mb-2 mt-2"></div>

                    <div class="d-flex align-items-center">
                      <baseIcon
                        size="30"
                        class="styleIcon mr-1"
                        :icon="mdiOfficeBuildingMarker"
                      />
                      <p class="fs-4">{{ pontoInicial.poendereco }}</p>
                    </div>
                  </div>
                </PopupMarker>
              </l-marker>
            </div>

            <!-- Ponto de Destino -->
            <div v-if="mostrarPontoDestino">
              <l-marker
                :lat-lng="[pontoFinal.polatitude, pontoFinal.polongitude]"
                :icon="getIconInicialFinal(2)"
              >
                <PopupMarker>
                  <div class="col-sm-12 row nopadding div-popup">
                    <div class="d-flex align-items-center">
                      <baseIcon
                        size="30"
                        class="styleIcon mr-1"
                        :icon="mdiMapMarkerCircle"
                      />
                      <p class="fs-4">{{ pontoFinal.origem }}</p>
                    </div>

                    <div class="styleDivider mb-2 mt-2"></div>

                    <div class="d-flex align-items-center">
                      <baseIcon
                        size="30"
                        class="styleIcon mr-1"
                        :icon="mdiOfficeBuildingMarker"
                      />
                      <p class="fs-4">{{ pontoFinal.poendereco }}</p>
                    </div>
                  </div>
                </PopupMarker>
              </l-marker>
            </div>

            <!-- Rastro Rota Agrupada -->
            <polilinhaCorDiferente
              :colorPolilyne="geraCor()"
              :latLng="rastroMapa || []"
              :poliEditavel="false"
            />

            <div class="acoesMapa">
              <div
                v-b-tooltip.hover
                :title="
                  this.cargasPendentesSelecionadas.length <= 0
                    ? ''
                    : 'Centralizar pontos no mapa'
                "
              >
                <SimpleButton
                  width="50px"
                  text=""
                  :icon="mdiMagnifyPlusOutline"
                  type="gray"
                  event="gerar"
                  @gerar="zoomItensMapa"
                />
              </div>
            </div>
          </mapaSimples>
        </div>
      </slideUpAndDown>

      <!-- Tabela Cargas Pendentes -->
      <div
        class="mt-3 pr-3 sticky-header"
        style="margin-bottom: -30px; z-index: 10; position: sticky; left: 100%"
      >
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center bg-gray-100">
            <div class="text-sm ml-4 mt-2">
              <span style="font-weight: bold">Total cubagem:</span>
              {{ totalCubagem }} m³
            </div>
            <div class="text-sm ml-4 mt-2">
              <span style="font-weight: bold">Total peso:</span>
              {{ totalPeso }} kg
            </div>
            <div class="text-sm ml-4 mt-2">
              <span style="font-weight: bold">Total pedidos:</span>
              {{ totalPedidos }}
            </div>
            <div class="text-sm ml-4 mt-2">
              <span style="font-weight: bold">Total entregas:</span>
              {{ totalEntrega }}
            </div>
            <div class="text-sm ml-4 mr-4 mt-2">
              <span style="font-weight: bold">Total cargas selecionadas:</span>
              {{ totalCargas }}
            </div>
          </div>

          <SimpleButton
            text="Agrupar carga"
            event="agrupar"
            @agrupar="abrirModalAgruparCarga"
            type="blue"
            style="width: 120px"
            extraClass="ml-4"
          />
        </div>
      </div>

      <b-tabs class="col-sm-12">
        <b-tab
          :title="tituloCargaPendente"
          class="col-sm-12 p-0 mt-2 text-sm"
          @click="toggleCollapseCargasPendentes"
        >
          <b-collapse
            id="cargasPendentesCollapse"
            v-model="cargasPendentesCollapseVisible"
            class="mt-2"
          >
            <tableRelatorio>
              <slot slot="thead">
                <tr class="text-center">
                  <th
                    class="text-truncate"
                    style="cursor: pointer; font-size: 10px"
                    @click="sortTableCargaPendente('carga')"
                  >
                    Carga *
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                  <th
                    class="text-truncate"
                    style="cursor: pointer; font-size: 10px"
                    @click="sortTableCargaPendente('marcador')"
                  >
                    Marcadores (Rota)
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                  <th
                    class="text-truncate"
                    style="cursor: pointer; font-size: 10px"
                    @click="sortTableCargaPendente('motorista')"
                  >
                    Motorista *
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                  <th
                    class="text-truncate"
                    style="cursor: pointer; font-size: 10px"
                    @click="sortTableCargaPendente('veiculo')"
                  >
                    Veículo *
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                  <th
                    class="text-truncate"
                    style="cursor: pointer; font-size: 10px"
                    @click="sortTableCargaPendente('informacoesadicionais')"
                  >
                    Informações adicionais
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                  <th
                    class="text-truncate"
                    style="cursor: pointer; font-size: 10px"
                    @click="sortTableCargaPendente('veiculoTransbordo')"
                  >
                    {{ titleVeiculoTransbordo }}
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                  <th
                    class="text-truncate"
                    style="cursor: pointer; font-size: 10px"
                    @click="sortTableCargaPendente('motoristaTransbordo')"
                  >
                    {{ titleMotoristaTransbordo }}
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                  <th
                    class="text-truncate"
                    style="cursor: pointer; font-size: 10px"
                    @click="sortTableCargaPendente('origem')"
                  >
                    {{ titleOrigem }}
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                  <th
                    class="text-truncate"
                    style="cursor: pointer; font-size: 10px"
                    @click="sortTableCargaPendente('peso')"
                  >
                    Peso
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                  <th
                    class="text-truncate"
                    style="cursor: pointer; font-size: 10px"
                    @click="sortTableCargaPendente('cubagem')"
                  >
                    Cubagem
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                  <th
                    class="text-truncate"
                    style="cursor: pointer; font-size: 10px"
                    @click="sortTableCargaPendente('pedido')"
                  >
                    Pedido
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                  <th
                    class="text-truncate"
                    style="cursor: pointer; font-size: 10px"
                    @click="sortTableCargaPendente('entrega')"
                  >
                    Entrega
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                  <th
                    class="text-truncate"
                    style="cursor: pointer; font-size: 10px"
                    @click="sortTableCargaPendente('situacao')"
                  >
                    Situação
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                </tr>
              </slot>

              <slot slot="tbody">
                <template v-for="(cargaPendente, index) in cargasPendentes">
                  <tr :key="cargaPendente.codigounico">
                    <td>
                      <label
                        :for="'flexCheckDefault' + cargaPendente.codigounico"
                        style="cursor: pointer"
                      >
                        <span class="d-flex flex-row align-items-center">
                          <div
                            style="
                              font-weight: bold;
                              padding-right: 30px;
                              padding-top: 10px;
                              font-size: 10px;
                            "
                          >
                            {{ cargaPendente.posicao }}
                          </div>
                          <span class="d-flex flex-row align-items-center">
                            <input
                              style="cursor: pointer"
                              class="form-check-input larger-checkbox p-0"
                              type="checkbox"
                              name="cargaPendente"
                              :id="'cargapendenteeagleselecionar' + index"
                              @click="
                                changeCargasPendentesSelecionar(
                                  cargaPendente,
                                  index
                                )
                              "
                            />
                          </span>
                          <span
                            class="pl-2 pt-2 d-flex text-sm"
                            style="font-size: 10px"
                          >
                            {{ cargaPendente.carga }}

                            <p v-if="cargaPendente.inconformidade">
                              <baseIcon
                                size="14"
                                class="ml-3 styleIconAlert"
                                :icon="mdiAlertCircleOutline"
                              />
                            </p>
                          </span>
                        </span>
                      </label>
                    </td>

                    <td
                      @click="abrirItens(index)"
                      v-b-toggle="cargaPendente.marcador"
                      class="text-center p-1 pt-2 text-truncate"
                      style="
                        font-size: 10px;
                        cursor: default;
                        max-width: 150px;
                        word-wrap: break-word;
                        white-space: normal;
                        cursor: pointer;
                      "
                    >
                      <span
                        class="p-1 rounded-lg text-sm"
                        :style="{
                          'background-color': pickColor(cargaPendente.marcador)
                        }"
                      >
                        {{ cargaPendente.marcador }}
                      </span>
                    </td>

                    <td class="pl-1 pr-1">
                      <span v-if="!cargaPendente.habilitado">
                        <select
                          v-model="cargasPendentes[index].motoristatransbordo"
                          class="styleInputTable"
                          style="font-size: 10px"
                          :disabled="cargaPendente.habilitado"
                          @change="
                            motorista =>
                              changeMotoristaTransbordo(motorista, index)
                          "
                        >
                          <option
                            v-for="motorista in motoristas"
                            :key="motorista.value"
                            :value="motorista.value"
                            v-text="motorista.description"
                            class="styleOption"
                          />
                        </select>
                      </span>
                      <span v-else class="text-truncate text-sm">
                        {{
                          cargaPendente.motoristatransbordonome
                            ? cargaPendente.motoristatransbordonome
                            : 'Selecione um motorista'
                        }}
                      </span>
                    </td>

                    <td class="pl-1 pr-1">
                      <span v-if="!cargaPendente.habilitado">
                        <select
                          class="styleInputTable"
                          style="font-size: 10px"
                          v-model="cargasPendentes[index].veiculotransbordo"
                          @change="
                            veiculo => changeVeiculoTransbordo(veiculo, index)
                          "
                        >
                          <option
                            v-for="veiculo in optionsSelectVeiculos"
                            :key="veiculo.value"
                            v-text="veiculo.description"
                            :value="veiculo.value"
                            class="styleOption"
                          />
                        </select>
                      </span>
                      <span v-else class="text-truncate text-sm">
                        {{
                          cargaPendente.veiculotransbordoplaca
                            ? cargaPendente.veiculotransbordoplaca
                            : 'Selecione um veículo'
                        }}
                      </span>
                    </td>

                    <td class="pl-1 pr-1">
                      <span v-if="!cargaPendente.habilitado">
                        <input
                          v-model="cargaPendente.informacoesadicionais"
                          class="styleInputTable"
                        />
                      </span>
                      <span v-else class="text-truncate text-sm">
                        {{
                          cargaPendente.informacoesadicionais
                            ? cargaPendente.informacoesadicionais
                            : 'Informações adicionais'
                        }}
                      </span>
                    </td>

                    <td class="pl-1 pr-1">
                      <span v-if="!cargaPendente.habilitado">
                        <select
                          class="styleInputTable"
                          style="font-size: 10px"
                          v-model="cargasPendentes[index].veiculo"
                          @change="
                            veiculo =>
                              changeVeiculoSelecionado(veiculo, index, false)
                          "
                        >
                          <option
                            v-for="veiculo in optionsSelectVeiculos"
                            :key="veiculo.value"
                            v-text="veiculo.description"
                            :value="veiculo.value"
                            class="styleOption"
                          />
                        </select>
                      </span>

                      <span v-else class="text-truncate text-sm">
                        {{
                          cargaPendente.veiculoplaca
                            ? cargaPendente.veiculoplaca
                            : 'Selecione um veículo'
                        }}
                      </span>
                    </td>

                    <td class="pl-1 pr-1">
                      <span v-if="!cargaPendente.habilitado">
                        <select
                          class="styleInputTable"
                          style="font-size: 10px"
                          v-model="cargasPendentes[index].motorista"
                          @change="
                            motorista =>
                              changeMotoristaSelecionado(
                                motorista,
                                index,
                                !cargaPendente.habilitado,
                                true
                              )
                          "
                        >
                          <option
                            v-for="motorista in motoristas"
                            :key="motorista.value"
                            v-text="motorista.description"
                            :value="motorista.value"
                            class="styleOption"
                          />
                        </select>
                      </span>

                      <span v-else class="text-truncate text-sm">
                        {{
                          cargaPendente.nomemotorista
                            ? cargaPendente.nomemotorista
                            : 'Selecione um motorista'
                        }}
                      </span>
                    </td>

                    <td class="pl-1 pr-1">
                      <span v-if="!cargaPendente.habilitado">
                        <select
                          class="styleInputTable"
                          style="width: 100px; font-size: 10px"
                          v-model="cargasPendentes[index].origem"
                          @change="
                            origem => changeOrigemTransbordo(origem, index)
                          "
                        >
                          <option
                            v-for="origem in pontosOrigem"
                            :key="origem.value"
                            v-text="origem.description"
                            :value="origem.value"
                            class="styleOption"
                          />
                        </select>
                      </span>

                      <span v-else class="text-truncate text-sm">
                        {{
                          cargaPendente.origemnome
                            ? cargaPendente.origemnome
                            : 'Selecione uma origem'
                        }}
                      </span>
                    </td>

                    <td
                      class="text-center text-truncate pt-3"
                      style="font-size: 10px"
                    >
                      {{ formatNumber(parseFloat(cargaPendente.peso)) }} kg
                    </td>
                    <td
                      class="text-center text-truncate pt-3"
                      style="font-size: 10px"
                    >
                      {{ formatNumber(parseFloat(cargaPendente.cubagem)) }} m³
                    </td>
                    <td
                      class="text-center text-truncate pt-3"
                      style="font-size: 10px"
                    >
                      {{
                        cargaPendente.itensdesagrupados
                          ? cargaPendente.itensdesagrupados.length
                          : '0'
                      }}
                    </td>
                    <td
                      class="text-center text-truncate pt-3"
                      style="font-size: 10px"
                    >
                      {{
                        cargaPendente.itens ? cargaPendente.itens.length : '0'
                      }}
                    </td>
                    <td
                      class="text-center text-truncate pt-3"
                      style="font-size: 10px"
                    >
                      {{ cargaPendente.status }}
                    </td>
                  </tr>

                  <template>
                    <tr>
                      <td colspan="13">
                        <b-collapse
                          class="w100"
                          v-model="cargaPendente.habilitaritens"
                        >
                          <div class="p-2">
                            <tableRelatorio>
                              <slot slot="thead">
                                <tr class="text-center">
                                  <th
                                    class="bg-gray-table fw-bold"
                                    style="font-size: 10px"
                                  >
                                    Ordem
                                  </th>
                                  <th
                                    class="bg-gray-table fw-bold"
                                    style="font-size: 10px"
                                  >
                                    Documento
                                  </th>
                                  <th
                                    class="bg-gray-table fw-bold"
                                    style="font-size: 10px"
                                  >
                                    Nome
                                  </th>
                                  <th
                                    class="bg-gray-table fw-bold"
                                    style="font-size: 10px"
                                  >
                                    Endereço
                                  </th>
                                  <th
                                    class="bg-gray-table fw-bold"
                                    style="font-size: 10px"
                                  >
                                    Peso
                                  </th>
                                  <th
                                    class="bg-gray-table fw-bold"
                                    style="font-size: 10px"
                                  >
                                    Cubagem
                                  </th>
                                  <th
                                    class="bg-gray-table fw-bold"
                                    style="font-size: 10px"
                                  >
                                    Valor
                                  </th>
                                </tr>
                              </slot>
                              <slot slot="tbody">
                                <template
                                  v-for="itens in cargaPendente.itensdesagrupados"
                                >
                                  <tr
                                    :key="
                                      'rota2' +
                                      itens.ircodigo +
                                      itens.codigounico
                                    "
                                  >
                                    <td
                                      class="text-truncate bg-gray-table d-flex"
                                      style="font-size: 10px"
                                    >
                                      {{ itens.irordem }}

                                      <span
                                        v-if="itens.pontoerro"
                                        @click="
                                          pontoInconformidade(
                                            itens.ircodigoexterno,
                                            cargaPendente,
                                            itens
                                          )
                                        "
                                        style="cursor: pointer"
                                      >
                                        <baseIcon
                                          size="14"
                                          class="ml-3 styleIconAlert"
                                          :icon="mdiAlertCircleOutline"
                                        />
                                      </span>
                                    </td>
                                    <td
                                      class="text-truncate bg-gray-table text-sm"
                                      style="font-size: 10px"
                                    >
                                      {{ itens.irdocumento }}
                                    </td>
                                    <td
                                      class="text-truncate bg-gray-table text-sm"
                                      style="font-size: 10px"
                                    >
                                      {{ itens.irnome }}
                                    </td>
                                    <td
                                      class="text-truncate bg-gray-table text-sm"
                                      style="font-size: 10px"
                                    >
                                      {{ itens.endereco }}
                                    </td>
                                    <td
                                      class="text-truncate bg-gray-table text-sm"
                                      style="font-size: 10px"
                                    >
                                      {{
                                        formatNumber(parseFloat(itens.irpeso))
                                      }}
                                      Kg
                                    </td>
                                    <td
                                      class="text-truncate bg-gray-table text-sm"
                                      style="font-size: 10px"
                                    >
                                      {{
                                        formatNumber(
                                          parseFloat(itens.ircubagem)
                                        )
                                      }}
                                      m³
                                    </td>
                                    <td
                                      class="text-truncate bg-gray-table text-sm"
                                      style="font-size: 10px"
                                    >
                                      R$
                                      {{
                                        formatNumber(parseFloat(itens.irvalor))
                                      }}
                                    </td>
                                  </tr>
                                </template>
                              </slot>
                            </tableRelatorio>
                          </div>
                        </b-collapse>
                      </td>
                    </tr>
                  </template>
                </template>

                <statusInformation
                  colspanForTd="13"
                  v-if="cargasPendentes.length === 0"
                  typeBar="tr"
                  :statusBar="statusbar"
                >
                </statusInformation>
              </slot>
            </tableRelatorio>
          </b-collapse>
        </b-tab>
      </b-tabs>

      <!-- Tabela Rotas Pendentes -->
      <div
        class="mt-3 pr-3"
        style="margin-bottom: -30px; z-index: 10; position: sticky; left: 100%"
      >
        <SimpleButton
          text="Desagrupar carga"
          event="desagrupar"
          @desagrupar="desagruparCarga"
          type="red"
          style="width: 120px"
        />
      </div>

      <b-tabs class="col-sm-12">
        <b-tab
          :title="tituloRotaPendente"
          class="col-sm-12 p-0 mt-2 text-sm"
          @click="toggleCollapseRotasPendentes"
        >
          <b-collapse
            id="rotasPendentesCollapse"
            v-model="rotasPendentesCollapseVisible"
            class="mt-2"
          >
            <tableRelatorio>
              <slot slot="thead">
                <tr class="text-center">
                  <th
                    style="font-size: 10px"
                    class="text-truncate"
                    v-if="rotasPendentes.length > 0"
                  ></th>
                  <th
                    @click="sortTableRotaPendente('codigo')"
                    style="cursor: pointer; font-size: 10px"
                    class="text-truncate"
                  >
                    Código
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                  <th
                    @click="sortTableRotaPendente('placa')"
                    style="cursor: pointer; font-size: 10px"
                    class="text-truncate"
                  >
                    Veículo
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                  <th
                    @click="sortTableRotaPendente('motorista')"
                    style="cursor: pointer; font-size: 10px"
                    class="text-truncate"
                  >
                    Motorista
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                  <th
                    @click="sortTableRotaPendente('data')"
                    style="cursor: pointer; font-size: 10px"
                    class="text-truncate"
                  >
                    Data
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                  <th
                    @click="sortTableRotaPendente('origem')"
                    style="cursor: pointer; font-size: 10px"
                    class="text-truncate"
                  >
                    Origem
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                  <th
                    @click="sortTableRotaPendente('destino')"
                    style="cursor: pointer; font-size: 10px"
                    class="text-truncate"
                  >
                    Destino
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                  <th
                    @click="sortTableRotaPendente('peso')"
                    style="cursor: pointer; font-size: 10px"
                    class="text-truncate"
                  >
                    Peso
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                  <th
                    @click="sortTableRotaPendente('cubagem')"
                    style="cursor: pointer; font-size: 10px"
                    class="text-truncate"
                  >
                    Cubagem
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                  <th
                    @click="sortTableRotaPendente('km')"
                    style="cursor: pointer; font-size: 10px"
                    class="text-truncate"
                  >
                    Km
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                  <th
                    @click="sortTableRotaPendente('tempo')"
                    style="cursor: pointer; font-size: 10px"
                    class="text-truncate"
                  >
                    Tempo
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                  <th
                    @click="sortTableRotaPendente('custo')"
                    style="cursor: pointer; font-size: 10px"
                    class="text-truncate"
                  >
                    Custo
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                  <th
                    @click="sortTableRotaPendente('itensrotas')"
                    style="cursor: pointer; font-size: 10px"
                    class="text-truncate"
                  >
                    Entregas
                    <baseIcon size="14" class="ml-3" :icon="mdiSwapVertical" />
                  </th>
                </tr>
              </slot>

              <slot slot="tbody">
                <template v-for="(rotaPendente, index) in rotasPendentes">
                  <tr :key="'rota_eagle' + rotaPendente.codigo">
                    <td class="pl-5 pt-3">
                      <label :for="'flexCheckDefault2' + index">
                        <span class="d-flex flex-row align-items-center">
                          <input
                            class="form-check-input larger-checkbox"
                            type="radio"
                            :id="'flexCheckDefault2' + index"
                            @click="
                              changeRotasPendentesSelecionar(
                                rotaPendente,
                                index
                              )
                            "
                            :checked="selectedItemIndexRotaPendente === index"
                          />
                        </span>
                      </label>
                    </td>
                    <td class="text-truncate pt-3" style="font-size: 10px">
                      {{ rotaPendente.codigo }}
                    </td>
                    <td class="text-truncate pt-3" style="font-size: 10px">
                      {{ rotaPendente.placa }}
                    </td>
                    <td class="text-truncate pt-3" style="font-size: 10px">
                      {{ rotaPendente.motorista }}
                    </td>
                    <td class="text-truncate pt-3" style="font-size: 10px">
                      {{ rotaPendente.data }}
                    </td>
                    <td class="text-truncate pt-3" style="font-size: 10px">
                      {{ rotaPendente.origem }}
                    </td>
                    <td class="text-truncate pt-3" style="font-size: 10px">
                      {{ rotaPendente.destino }}
                    </td>
                    <td class="text-truncate pt-3" style="font-size: 10px">
                      {{ formatNumber(parseFloat(rotaPendente.peso)) }} Kg
                    </td>
                    <td class="text-truncate pt-3" style="font-size: 10px">
                      {{ formatNumber(parseFloat(rotaPendente.cubagem)) }} m³
                    </td>
                    <td class="text-truncate pt-3" style="font-size: 10px">
                      {{ rotaPendente.km }}
                    </td>
                    <td class="text-truncate pt-3" style="font-size: 10px">
                      {{ rotaPendente.tempo }}
                    </td>
                    <td class="text-truncate pt-3" style="font-size: 10px">
                      R$ {{ formatNumber(parseFloat(rotaPendente.custo)) }}
                    </td>
                    <td class="text-truncate pt-3" style="font-size: 10px">
                      {{
                        rotaPendente.itensrotas
                          ? rotaPendente.itensrotas.length
                          : '0'
                      }}
                    </td>
                  </tr>
                  <template>
                    <tr :key="'rota' + rotaPendente.codigounico">
                      <td colspan="13">
                        <b-collapse class="w100" v-model="rotaPendente.clicado">
                          <!-- Tabela de itens da Rota Pendente -->
                          <div class="p-2">
                            <tableRelatorio>
                              <slot slot="thead">
                                <tr class="text-center">
                                  <th
                                    class="text-truncate bg-gray-table"
                                    style="font-size: 10px"
                                  >
                                    Ordem
                                  </th>
                                  <th
                                    class="text-truncate bg-gray-table"
                                    style="font-size: 10px"
                                  >
                                    Carga
                                  </th>
                                  <th
                                    class="text-truncate bg-gray-table"
                                    style="font-size: 10px"
                                  >
                                    Marcadores (Rota)
                                  </th>
                                  <th
                                    class="text-truncate bg-gray-table"
                                    style="font-size: 10px"
                                  >
                                    Motorista
                                  </th>
                                  <th
                                    class="text-truncate bg-gray-table"
                                    style="font-size: 10px"
                                  >
                                    Veículo
                                  </th>
                                  <th
                                    class="text-truncate bg-gray-table"
                                    style="font-size: 10px"
                                  >
                                    Informações adicionais
                                  </th>
                                  <th
                                    class="text-truncate bg-gray-table"
                                    style="font-size: 10px"
                                  >
                                    Peso
                                  </th>
                                  <th
                                    class="text-truncate bg-gray-table"
                                    style="font-size: 10px"
                                  >
                                    Cubagem
                                  </th>
                                  <th
                                    class="text-truncate bg-gray-table"
                                    style="font-size: 10px"
                                  >
                                    Pedido
                                  </th>
                                  <th
                                    class="text-truncate bg-gray-table"
                                    style="font-size: 10px"
                                  >
                                    Entrega
                                  </th>
                                  <th
                                    class="text-truncate bg-gray-table"
                                    style="font-size: 10px"
                                  >
                                    Origem
                                  </th>
                                </tr>
                              </slot>
                              <slot slot="tbody">
                                <template
                                  v-for="cargaAgrupada in rotaPendente.cargas"
                                >
                                  <tr
                                    :key="
                                      'rota' +
                                      cargaAgrupada.ircodigocarga +
                                      cargaAgrupada.ordem +
                                      cargaAgrupada.marcador +
                                      cargaAgrupada.informacoesadicionais
                                    "
                                  >
                                    <td
                                      class="text-truncate bg-gray-table"
                                      style="font-size: 10px"
                                    >
                                      {{ cargaAgrupada.ordem }}
                                    </td>
                                    <td
                                      class="text-truncate bg-gray-table"
                                      style="font-size: 10px"
                                    >
                                      {{ cargaAgrupada.carga }}
                                    </td>
                                    <td
                                      class="text-truncate bg-gray-table"
                                      style="font-size: 10px; padding: 10px"
                                    >
                                      <span
                                        class="p-1 roudend-lg"
                                        :style="{
                                          'background-color': pickColor(
                                            cargaAgrupada.marcador
                                          )
                                        }"
                                      >
                                        {{ cargaAgrupada.marcador }}
                                      </span>
                                    </td>
                                    <td
                                      class="text-truncate bg-gray-table"
                                      style="font-size: 10px"
                                    >
                                      {{ cargaAgrupada.motorista }}
                                    </td>
                                    <td
                                      class="text-truncate bg-gray-table"
                                      style="font-size: 10px"
                                    >
                                      {{ cargaAgrupada.veiculo }}
                                    </td>
                                    <td
                                      class="text-truncate bg-gray-table"
                                      style="font-size: 10px"
                                    >
                                      {{ cargaAgrupada.informacoesadicionais }}
                                    </td>
                                    <td
                                      class="text-truncate bg-gray-table"
                                      style="font-size: 10px"
                                    >
                                      {{
                                        formatNumber(
                                          parseFloat(cargaAgrupada.peso)
                                        )
                                      }}
                                      Kg
                                    </td>
                                    <td
                                      class="text-truncate bg-gray-table"
                                      style="font-size: 10px"
                                    >
                                      {{
                                        formatNumber(
                                          parseFloat(cargaAgrupada.cubagem)
                                        )
                                      }}
                                      m³
                                    </td>
                                    <td
                                      class="text-truncate bg-gray-table"
                                      style="font-size: 10px"
                                    >
                                      {{ cargaAgrupada.pedido }}
                                    </td>
                                    <td
                                      class="text-truncate bg-gray-table"
                                      style="font-size: 10px"
                                    >
                                      {{ cargaAgrupada.entregas }}
                                    </td>
                                    <td
                                      class="text-truncate bg-gray-table"
                                      style="font-size: 10px"
                                    >
                                      {{ cargaAgrupada.origem }}
                                    </td>
                                  </tr>
                                  <template>
                                    <tr>
                                      <td colspan="13">
                                        <b-collapse
                                          class="w100"
                                          v-model="rotaPendente.clicado"
                                        >
                                          <!-- Tabela de itens da Rota Pendente Desagrupados -->
                                          <div class="p-2">
                                            <tableRelatorio>
                                              <slot slot="thead">
                                                <tr class="text-center">
                                                  <th
                                                    class="text-truncate bg-gray-table fw-bold"
                                                    style="font-size: 10px"
                                                  >
                                                    Ordem
                                                  </th>
                                                  <th
                                                    class="text-truncate bg-gray-table fw-bold"
                                                    style="font-size: 10px"
                                                  >
                                                    Documento
                                                  </th>
                                                  <th
                                                    class="text-truncate bg-gray-table fw-bold"
                                                    style="font-size: 10px"
                                                  >
                                                    Nome
                                                  </th>
                                                  <th
                                                    class="text-truncate bg-gray-table fw-bold"
                                                    style="font-size: 10px"
                                                  >
                                                    Endereço
                                                  </th>
                                                  <th
                                                    class="text-truncate bg-gray-table fw-bold"
                                                    style="font-size: 10px"
                                                  >
                                                    Peso
                                                  </th>
                                                  <th
                                                    class="text-truncate bg-gray-table fw-bold"
                                                    style="font-size: 10px"
                                                  >
                                                    Cubagem
                                                  </th>
                                                  <th
                                                    class="text-truncate bg-gray-table fw-bold"
                                                    style="font-size: 10px"
                                                  >
                                                    Valor
                                                  </th>
                                                </tr>
                                              </slot>
                                              <slot slot="tbody">
                                                <template
                                                  v-for="itens in cargaAgrupada.itensdesagrupados"
                                                >
                                                  <tr
                                                    :key="
                                                      'rota' +
                                                      itens.filial +
                                                      itens.codigounico
                                                    "
                                                  >
                                                    <td
                                                      class="text-truncate bg-gray-table"
                                                      style="font-size: 10px"
                                                    >
                                                      {{ itens.irordem }}
                                                    </td>
                                                    <td
                                                      class="text-truncate bg-gray-table"
                                                      style="font-size: 10px"
                                                    >
                                                      {{ itens.documento }}
                                                    </td>
                                                    <td
                                                      class="text-truncate bg-gray-table"
                                                      style="font-size: 10px"
                                                    >
                                                      {{ itens.nome }}
                                                    </td>
                                                    <td
                                                      class="text-truncate bg-gray-table"
                                                      style="font-size: 10px"
                                                    >
                                                      {{ itens.endereco }}
                                                    </td>
                                                    <td
                                                      class="text-truncate bg-gray-table"
                                                      style="font-size: 10px"
                                                    >
                                                      {{
                                                        formatNumber(
                                                          parseFloat(itens.peso)
                                                        )
                                                      }}
                                                      Kg
                                                    </td>
                                                    <td
                                                      class="text-truncate bg-gray-table"
                                                      style="font-size: 10px"
                                                    >
                                                      {{
                                                        formatNumber(
                                                          parseFloat(
                                                            itens.cubagem
                                                          )
                                                        )
                                                      }}
                                                      m³
                                                    </td>
                                                    <td
                                                      class="text-truncate bg-gray-table"
                                                      style="font-size: 10px"
                                                    >
                                                      R$
                                                      {{
                                                        formatNumber(
                                                          parseFloat(
                                                            itens.valor
                                                          )
                                                        )
                                                      }}
                                                    </td>
                                                  </tr>
                                                </template>
                                              </slot>
                                            </tableRelatorio>
                                          </div>
                                        </b-collapse>
                                      </td>
                                    </tr>
                                  </template>
                                </template>
                              </slot>
                            </tableRelatorio>
                          </div>
                        </b-collapse>
                      </td>
                    </tr>
                  </template>
                </template>

                <statusInformation
                  v-if="rotasPendentes.length === 0"
                  typeBar="tr"
                  :statusBar="statusbar"
                >
                </statusInformation>
              </slot>
            </tableRelatorio>
          </b-collapse>
        </b-tab>
      </b-tabs>
    </div>

    <!-- modal agrupar carga -->
    <modalEagle
      @confirmButton="agruparCargas()"
      id="modalAgruparCarga"
      title="Verifique os dados para criar a rota!"
      textoConfirmar="Confirmar"
      textoCancelar="Fechar"
      :tamanhoBtn="'120px'"
      hideHeaderClose
    >
      <template #modalBody>
        <b-overlay :show="loadingPanel" :opacity="0.65" class="bemAlto">
          <div>
            <div class="col-sm-8">
              <InputSingleDay
                name="selectData"
                label="Data de início de rota*"
                :isObrigatorio="true"
                @changeInput="changeDataInicioRota"
                :value="proximodia"
              />
            </div>

            <div class="col-sm-12 mt-3 nopadding d-flex justify-content-start">
              <div class="col-sm-6">
                <SelectAll
                  ref="selectMotoristaTickets"
                  nameForRadio="seletorMotorista"
                  :labels="[{ indexDFH: 'M', description: 'Motorista*' }]"
                  :optionsArray="motoristas"
                  firstSelected="M"
                  :hasSelectAll="false"
                  :isMultiple="false"
                  @changeSelect="
                    motorista =>
                      changeMotoristaSelecionado(motorista, null, false)
                  "
                  :disabled="motoristas.length <= 0"
                  :selected="motoristaSelecionado"
                />
              </div>

              <div class="col-sm-6">
                <SelectAll
                  ref="selectVeiculoTickets"
                  nameForRadio="seletorVeiculo"
                  :labels="[{ indexDFH: 'V', description: 'Veículo*' }]"
                  :optionsArray="optionsSelectVeiculos"
                  firstSelected="V"
                  :hasSelectAll="false"
                  :isMultiple="false"
                  @changeSelect="
                    veiculo => changeVeiculoSelecionado(veiculo, null, true)
                  "
                  :disabled="optionsSelectVeiculos.length <= 0"
                  :selected="veiculoSelecionado"
                />
              </div>
            </div>

            <div class="col-sm-12 mt-3 nopadding d-flex justify-content-start">
              <div class="col-sm-6">
                <SelectAll
                  ref="selectOrigemTickets"
                  nameForRadio="seletorOrigem"
                  :labels="[{ indexDFH: 'O', description: 'Origem*' }]"
                  :optionsArray="pontosOrigem"
                  firstSelected="O"
                  :hasSelectAll="false"
                  :isMultiple="false"
                  @changeSelect="changeOrigemModal"
                  :disabled="pontosOrigem.length <= 0"
                  :selected="origemSelecionada"
                />
              </div>

              <div class="col-sm-6">
                <SelectAll
                  ref="selectDestinoTickets"
                  nameForRadio="seletorDestino"
                  :labels="[{ indexDFH: 'D', description: 'Destino*' }]"
                  :optionsArray="pontosDestino"
                  firstSelected="D"
                  :hasSelectAll="false"
                  :isMultiple="false"
                  @changeSelect="changeDestinoSelecionado"
                  :disabled="pontosDestino.length <= 0"
                  :selected="destinoSelecionado"
                />
              </div>
            </div>
          </div>
        </b-overlay>
      </template>
    </modalEagle>

    <!-- modal carga agrupada com sucesso -->
    <modalEagle
      @cancelButton="limparInformacoesMapa(false, true, true)"
      id="modalConfirmacaoCargaAgrupada"
      title="Cargas agrupadas com sucesso!"
      textoCancelar="Agrupar mais cargas"
      typeButtonCancelar="orange"
      :tamanhoBtn="'160px'"
      hideHeaderClose
      :showButtonConfirmar="false"
    >
      <template #modalBody>
        <b-overlay :opacity="0.65" class="bemAlto">
          <div>
            <p class="fontBold mt-2 mb-2 text-center">
              Listagem das cargas agrupadas:
            </p>

            <tableListagem
              :filter="false"
              :data="cargasAgrupadas"
              :titles="titulosTable"
              :habilitado="true"
            >
              <template #acoes="{ row }">
                <SimpleButton
                  text=""
                  :icon="mdiEyeRefreshOutline"
                  type="blue"
                  event="gerar"
                  @gerar="mostrarRotaMapa(row.codigoRotaAgrupada)"
                  width="50px"
                />
              </template>
            </tableListagem>
          </div>
        </b-overlay>
      </template>
    </modalEagle>

    <!-- modal confirmar carga para o veículo que tem carga no dia selecionado -->
    <modalEagle
      @confirmButton="agruparCargas(false)"
      @cancelButton="limparInformacoesMapa(false, false, false)"
      id="modalAlertaVeiculoComCarga"
      title="Confirmar Carregamento"
      :tamanhoBtn="'110px'"
      textoConfirmar="Agrupar"
      hideHeaderClose
    >
      <template #modalBody>
        <div class="d-flex">
          <p>O veículo com a placa</p>
          <p class="fontBold ml-2 mr-2">
            {{ veiculoSelecionadoModalConfirmacao }}
          </p>
          <p>já possui uma carga para o dia</p>
          <p class="fontBold ml-2">{{ dataCarregamentoCarga }}.</p>
        </div>
      </template>
    </modalEagle>

    <div class="col-lg-12 campoObri">
      <span>(*) Campos obrigatórios</span>
    </div>

    <div class="col-sm-12 col-md-12">
      <hr />
    </div>
  </PanelEagle>
</template>

<script lang="js">
import {
    mdiConsoleNetworkOutline,
    mdiMonitorDashboard,
    mdiCheckBold,
    mdiChevronDown,
    mdiMapMarkerCircle,
    mdiOfficeBuildingMarker,
    mdiEyeRefreshOutline,
    mdiAlertCircleOutline,
    mdiPencilOutline,
    mdiSwapVertical,
    mdiMagnifyPlusOutline,
} from '@mdi/js'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import slideUpAndDown from '@/components/Atom/SlideUpAndDown/SlideUpAndDown.vue';
import InputRangeWithLimit from '@/components/Atom/Datas/InputRangeWithLimit.vue';
import SelectAll from '@/components/Atom/Select/SelectAll.vue';
import { EmpresasService } from '@/Services/auth/Empresas.service';
import PanelEagle from '@/components/Atom/Panel/PanelEagle.vue';
import { required } from 'vuelidate/lib/validators'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import SimpleButton from '@/components/Atom/Buttons/SimpleButton.vue';
import InputSingleDay from '@/components/Atom/Datas/InputSingleDay.vue';
import { LMarker } from "vue2-leaflet";
import Swal from 'sweetalert2'

const decodePoly = require('@mapbox/polyline')

export default Vue.extend({
    // eslint-disable-next-line vue/multi-word-component-names
    name: "montagemdecargas",

    validations: {
        dataIntervalo: { required },
        empresaSelecionada: { required },
    },

    components: {
        'tituloPage': require('@/components/Atom/Header/Titulo').default,
        slideUpAndDown,
        InputRangeWithLimit,
        SelectAll,
        PanelEagle,
        SimpleButton,
        tableRelatorio: require('@/components/Atom/Table/TableRelatorio').default,
        'buttonsFilters': require('@/components/Atom/Buttons/ButtonsFilters').default,
        'statusInformation': require('@/components/Atom/StatusInformation/StatusInformation').default,
        modalEagle: require("@/components/Atom/Modal/ModalEagle").default,
        InputSingleDay,
        'mapaSimples': require('@/components/Atom/Mapa/MapaSimples').default,
        infoRotaOsrm: require("@/components/Atom/SpecificComponents/RotaManual/InfoRotaOsrm").default,
        ClusterSelecionados: require("@/components/Atom/SpecificComponents/ControleCargas/ClusterSelecionados.vue").default,
        'PopupMarker': require('@/components/Atom/Mapa/PopupMarker').default,
        LMarker,
        polilinhaCorDiferente: require("@/components/Atom/Mapa/PolilinhaCorDiferente").default,
        SelectMarcadores: require(
        "@/components/Atom/SpecificComponents/RotaManual/SelectMarcadores.vue").default,
        baseIcon:require('@/components/Atom/Icon/BaseIcon.vue').default,
        'tableListagem': require('@/components/Atom/Table/TableListagem').default,
    },

    data() {
        return {
            loadingPanel: false,
            dataIntervalo: '',
            empresaSelecionada: [],
            optionsSelectEmpresas: new EmpresasService().Get(),
            optionsSelectVeiculos: [],
            veiculosSelecionados: [],
            optionsSelectMarcadores: [],
            marcadoresSelecionados: [],
            mdiPencilOutline: mdiPencilOutline,
            mdiConsoleNetworkOutline: mdiConsoleNetworkOutline,
            mdiMonitorDashboard: mdiMonitorDashboard,
            mdiMapMarkerCircle: mdiMapMarkerCircle,
            mdiOfficeBuildingMarker: mdiOfficeBuildingMarker,
            mdiEyeRefreshOutline: mdiEyeRefreshOutline,
            mdiAlertCircleOutline: mdiAlertCircleOutline,
            mdiChevronDown: mdiChevronDown,
            mdiCheckBold: mdiCheckBold,
            mdiSwapVertical: mdiSwapVertical,
            mdiMagnifyPlusOutline: mdiMagnifyPlusOutline,
            urlBase: '/roteirizador/montagem/cargas/',
            loadingRotas: false,
            filtroSelecionado: '',
            arrayButtons: [
                {
                    'value': 'I', 'title': 'Aguardando', 'width': '48.3%',
                    'text': 'Aguardando', 'isSelected': true
                },
                {
                    'value': 'R', 'title': 'Finalizado', 'width': '48.3%',
                    'text': 'Finalizado', 'isSelected': true
                },
            ],
            rotaOsrm: {
                tempo: 0,
                distancia: 0,
                volume: 0,
                peso: 0,
                valor: 0,
                cubagem: 0,
            },
            rotaOsrmMapa: {
                tempo: 0,
                distancia: 0,
                volume: 0,
                peso: 0,
                valor: 0,
                cubagem: 0,
            },
            showAlertaRotaFixa: false,
            cargasPendentes: [],
            statusbar: 'info',
            veiculoTransbordo: "",
            motoristaTransbordo: "",
            veiculoTransbordoInformado: true,
            motoristaTransbordoInformado: true,
            informacoesadicionais: "",
            cargasPendentesSelecionadas: [],
            posicaoAtualCargasPendentesSelecionadas: 1,
            cargasPendentesSelecionadasMapa: [],
            cargasPendentesCollapseVisible: true,
            rotasPendentesCollapseVisible: true,
            rotasPendentes: [],
            proximodia: '',
            motoristas: [],
            motoristaSelecionado: '',
            motoristaSelecionadoCodigo: '',
            veiculoSelecionado: '',
            veiculoSelecionadoCodigo: '',
            origemSelecionada: [],
            origemSelecionadaCodigo: [],
            destinoSelecionado: [],
            destinoSelecionadoCodigo: [],
            pontosOrigem: [],
            pontosDestino: [],
            cargaComTransbordo: false,
            selectedItemIndexCargaPendente: null,
            selectedItemIndexRotaPendente: null,
            rastroRota: null,
            mostrarPontoOrigem: false,
            mostrarPontoDestino: false,
            pontoInicial: [],
            pontoFinal: [],
            dataCarregamentoCarga: '',
            rastroMapa: null,
            veiculoSelecionadoModalConfirmacao: '',
            informacoesRota: [],
            motoristaSelecionadoAposAgrupar: null,
            itensRotaPendente: [],
            rotasPendenteSelecionado: [],
            tituloCargaPendente: 'Cargas Pendentes -',
            tituloRotaPendente: 'Rotas Pendentes -',
            rotaespecifica: '',
            rotaCodigo: '',
            pontosRota: [],
            veiculoTransbordoSelecionado: false,
            motoristaTransbordoSelecionado: false,
            titleVeiculoTransbordo: 'Veic. Transbordo',
            titleMotoristaTransbordo: 'Mot. Transbordo',
            titleOrigem: 'Origem',
            sortColumn: '',
            sortOrder: 'asc',
            controleAttPonto: false,
            pontoEditado: [],
            cargaPontoAtualizado: [],
            cargasPendentesSelecionadasAux: [],
            cargasPendentesAux: [],
            veiculosSelecionadosFiltro: [],
            codigoExternoPontoNovo: '',
            codigosCargas: [],
            codigosCargasSelected: [],
            codigosCargasSelecionadas: [],
            pressedKey: '',
            cargasSelecionadasControl: [],
            totalCubagem: 0,
            totalPeso: 0,
            totalCargas: 0,
            totalPedido: 0,
            totalEntrega: 0,
            totalPedidos: 0,
            objetosCargasPlacas: [],
            cargaAtualAgrupar: [],
            cargasAgrupadas: [],
            titulosTable: [
              { 'name': 'Data da rota:', 'number': 'data' },
              { 'name': 'Código da rota', 'number': 'codigoRotaAgrupada' }
            ],
        }
    },

    methods: {
        ...mapGetters(['getMaster']),

        /**
        * Pega o valor da data do input de Data e salva na variável
        this.dataIntervalo e limpa as demais informações do filtro e mapa
        * @param Data String
        * @author Yuri 🇧🇷
        */
        changeData(data) {
            this.dataIntervalo = data;
            this.limparDados();
            this.getMarcadores();
            this.consultarCodigosCargas();
            this.codigosCargasSelected = [];
        },

        /**
        * @description Atualiza o estado da aplicação ao trocar a empresa
        selecionada, ao selecionar uma empresa chama a função this.consulta()
        * @param {string} empresa - O identificador da nova empresa selecionada.
        * @author Yuri 🇧🇷
        */
        changeEmpresas(empresa) {
            this.empresaSelecionada = empresa;
            this.getVeiculos();
            this.getMarcadores();
            this.limparDados();
            this.consultarCodigosCargas();
            this.codigosCargasSelected = [];
        },

        /**
        * @description Salva na variável this.veiculosSelecionado os veículos
        * selecionados pelo usuáio
        * @param {string} veiculo - código do veículo selecionado.
        * @author Yuri 🇧🇷
        */
        changeVeiculos(veiculo) {
            this.limparDados();
            this.veiculosSelecionados = veiculo;
            this.veiculosSelecionadosFiltro = veiculo;
            this.getMarcadores();
            this.consultarCodigosCargas();
            this.codigosCargasSelected = [];
        },

        /**
         * Atualiza o veículo selecionado para uma carga pendente.
         *
         * @param {Array} veiculo - Array contendo informações do veículo selecionado. O primeiro elemento deve conter o código do veículo.
         * @param {number} index - O índice da carga pendente na lista `cargasPendentes` que será atualizada com o novo veículo selecionado. Se `null`, nenhuma carga pendente será atualizada.
         * @param {boolean} salvar - Indica se as alterações devem ser salvas. Se for `true`, as alterações serão aplicadas à carga pendente especificada pelo índice `index`.
         * @author Yuri 🇧🇷
         */
        async changeVeiculoSelecionado(veiculo, index, inputNormal) {
            let veiculoSelecionado = '';
            let veiculoSelecionadoPlaca = '';

            if(inputNormal && !veiculo[0]){
                veiculoSelecionado = veiculo.target.value;
                const selectElement = veiculo.target;
                const selectedOption = selectElement.options[selectElement.selectedIndex];
                veiculoSelecionadoPlaca = selectedOption.text;
            }else if(veiculo[0]){
              veiculoSelecionado = veiculo[0];
              veiculoSelecionadoPlaca = await this.consultarVeiculo(veiculoSelecionado);
            }else {
              veiculoSelecionado = veiculo.target.value;
            }

            if(this.veiculoSelecionado == veiculoSelecionado){
                if(index != null){
                    this.cargasPendentes[index].veiculo = null;
                    this.cargasPendentes[index].veiculoplaca = null;
                }

                this.veiculoSelecionado = null;
                this.cargaComTransbordo = false;
                this.veiculoTransbordoSelecionado = false;
                this.titleVeiculoTransbordo = 'Veic. Transbordo';
                this.titleMotoristaTransbordo = 'Mot. Transbordo';
                this.titleOrigem = 'Origem';
            }else if(!inputNormal){
                this.cargaComTransbordo = true;
                this.titleVeiculoTransbordo = 'Veic. Transbordo *';
                this.titleMotoristaTransbordo = 'Mot. Transbordo *';
                this.titleOrigem = 'Origem *';
                this.veiculoTransbordoSelecionado = true;

                if(index != null){
                  this.cargasPendentes[index].veiculo = veiculoSelecionado;
                  this.cargasPendentes[index].veiculoplaca = veiculoSelecionadoPlaca;
                  this.bloquearVeiculoDemaisItens(index, veiculoSelecionado, veiculoSelecionadoPlaca);
                }
            }

            this.veiculoSelecionado = [{
                description: await this.consultarVeiculo(veiculoSelecionado),
                value: veiculoSelecionado
            }];

            this.veiculoSelecionadoCodigo = veiculoSelecionado;

            if(this.cargaAtualAgrupar != undefined){
              this.cargaAtualAgrupar.veiculo = veiculoSelecionado;
              this.cargaAtualAgrupar.placa = veiculoSelecionadoPlaca;
            }
        },

        /**
         * Bloqueia a seleção do veículo para todos os itens de carga pendentes, exceto para um item específico.
         *
         * @param {number} indexNaoBloquear - O índice do item de carga que não deve ter a seleção do veículo bloqueada.
         * @param {string} veiculoSelecionado - O veículo selecionado que será atribuído aos itens de carga.
         * @author Yuri 🇧🇷
         */
        bloquearVeiculoDemaisItens(indexNaoBloquear, veiculoSelecionado, veiculoSelecionadoPlaca){
            const cargaNaoBloquear = this.cargasPendentes[indexNaoBloquear];

            this.cargasPendentesSelecionadas.map((carga, index) => {
                if(carga.codigo == cargaNaoBloquear.codigo){
                    this.cargasPendentesSelecionadas[index].habilitarveiculo = false;
                    this.cargasPendentesSelecionadas[index].veiculo = veiculoSelecionado;
                    this.cargasPendentesSelecionadas[index].veiculoplaca = veiculoSelecionadoPlaca;
                }else{
                    this.cargasPendentesSelecionadas[index].habilitarveiculo = true;
                    this.cargasPendentesSelecionadas[index].veiculo = veiculoSelecionado;
                    this.cargasPendentesSelecionadas[index].veiculoplaca = veiculoSelecionadoPlaca;
                }
            })
        },

      /**
       * Bloqueia a seleção de origem para todos os itens de carga pendentes que não possuem uma origem definida,
       * atribuindo a origem selecionada.
       *
       * @param {string} origemSelecionada - A origem que será atribuída aos itens de carga pendentes.
       * @author Yuri 🇧🇷
       */
        bloquearOrigemDemaisItens(origemSelecionada){
            this.cargasPendentesSelecionadas.map((carga, index) => {
                if(this.cargasPendentesSelecionadas[index].origem == null){
                    this.cargasPendentesSelecionadas[index].origem = origemSelecionada;
                }
            })
        },

        /**
         * Atualiza o motorista selecionado para uma carga pendente.
         *
         * @param {Array} motorista - Array contendo informações do motorista selecionado. O primeiro elemento deve conter o código do motorista.
         * @param {number} index - O índice da carga pendente na lista `cargasPendentes` que será atualizada com o novo motorista selecionado. Se `null`, nenhuma carga pendente será atualizada.
         * @param {boolean} salvar - Indica se as alterações devem ser salvas. Se for `true`, as alterações serão aplicadas à carga pendente especificada pelo índice `index`.
         * @author Yuri 🇧🇷
         */
        async changeMotoristaSelecionado(motorista, index, inputNormal){
            let motoristaSelecionado = '';
            let nomeMotoristaSelecionado = '';

            if(inputNormal){
                const selectElement = motorista.target;
                const selectedOption = selectElement.options[selectElement.selectedIndex];
                nomeMotoristaSelecionado = selectedOption.text;
            }

            if(motorista[0] > 0){
                motoristaSelecionado = motorista[0];
            }else{
                motoristaSelecionado = motorista.target.value;
            }

            if(this.motoristaSelecionado == motoristaSelecionado){
                this.cargasPendentes[index].motorista = null;
                this.cargasPendentes[index].nomemotorista = null;
                this.motoristaSelecionado = null;
                this.motoristaSelecionadoCodigo = null;
                this.cargaComTransbordo = false;
                this.titleVeiculoTransbordo = 'Veic. Transbordo';
                this.titleMotoristaTransbordo = 'Mot. Transbordo';
                this.titleOrigem = 'Origem';
                this.motoristaTransbordoSelecionado = false;
            }else if(inputNormal){
                this.motoristaTransbordoSelecionado = true;
                this.cargaComTransbordo = true;
                this.titleVeiculoTransbordo = 'Veic. Transbordo *';
                this.titleMotoristaTransbordo = 'Mot. Transbordo *';
                this.titleOrigem = 'Origem *';

                if(index != null){
                    this.cargasPendentes[index].motorista = motoristaSelecionado;

                    if(inputNormal){
                        this.cargasPendentes[index].nomemotorista = nomeMotoristaSelecionado;
                        this.bloquearMotoristaDemaisItens(index, motoristaSelecionado, nomeMotoristaSelecionado);
                    }
                }
            }

            this.motoristaSelecionado = [{
              description: await this.consultaMotorista(motoristaSelecionado),
              value: motoristaSelecionado
          }];

          this.motoristaSelecionadoCodigo = motoristaSelecionado;

            if(this.cargaAtualAgrupar != undefined){
              this.cargaAtualAgrupar.motorista = motoristaSelecionado;
            }
        },

        /**
        * Consulta o nome do motorista baseado no código fornecido.
        *
        * @param {string} codigoMotorista - O código do motorista a ser consultado.
        * @returns {Promise<string>} Retorna uma promessa que resolve para o nome do motorista consultado.
        * @throws {Error} Exibe uma mensagem de erro se ocorrer um problema durante a consulta.
        * @author Yuri 🇧🇷
        */
        async consultaMotorista(codigoMotorista){
            try {
                const body = {
                    motorista: codigoMotorista
                };
                const url = this.urlBase + 'consultar/mororista';

                return await new HttpRequest().Post(url, body).then((data) => {
                    return data.data[0].mtnome;
                })
            } catch (error) {
                this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
            }
        },

        /**
        * Bloqueia a seleção do motorista para todos os itens de carga pendentes, exceto para um item específico.
        *
        * @param {number} indexNaoBloquear - O índice do item de carga que não deve ter a seleção do motorista bloqueada.
        * @param {string} motoristaSelecionado - O motorista selecionado que será atribuído aos itens de carga.
        * @author Yuri 🇧🇷
        */
        bloquearMotoristaDemaisItens(indexNaoBloquear, motoristaSelecionado, nomeMotoristaSelecionado){
            const cargaNaoBloquear = this.cargasPendentes[indexNaoBloquear];

            this.cargasPendentesSelecionadas.map((carga, index) => {
                if(carga.codigo == cargaNaoBloquear.codigo){
                    this.cargasPendentesSelecionadas[index].habilitarmotorista = false;
                    this.cargasPendentesSelecionadas[index].motorista = motoristaSelecionado;
                    this.cargasPendentesSelecionadas[index].nomemotorista = nomeMotoristaSelecionado;
                }else{
                    this.cargasPendentesSelecionadas[index].habilitarmotorista = true;
                    this.cargasPendentesSelecionadas[index].motorista = motoristaSelecionado;
                    this.cargasPendentesSelecionadas[index].nomemotorista = nomeMotoristaSelecionado;
                }
            })
        },

        /**
         * Atualiza a origem selecionada para uma carga pendente.
         *
         * @param {Array} origem - Array contendo informações da origem selecionada. O primeiro elemento deve conter o código da origem.
         * @param {number} index - O índice da carga pendente na lista `cargasPendentes` que será atualizada com a nova origem selecionada. Se `null`, nenhuma carga pendente será atualizada.
         * @param {boolean} salvar - Indica se as alterações devem ser salvas. Se for `true`, as alterações serão aplicadas à carga pendente especificada pelo índice `index`.
         * @author Yuri 🇧🇷
         */
        changeOrigemSelecionado(origem, index, salvar){
            this.origemSelecionadaCodigo = origem[0];
            this.loadingRotas = true;

            if(index != null && salvar){
                this.pontosOrigem.map((origemSelecionada) => {
                    if(origemSelecionada.value == origem){
                        this.origemSelecionada = [{
                            value: origemSelecionada.value,
                            description: origemSelecionada.description
                        }];

                        this.origemSelecionadaCodigo = origemSelecionada.value;
                    }
                })
            }

            this.loadingRotas = false;
        },

        /**
         * Atualiza o destino selecionado para uma carga pendente.
         *
         * @param {Array} destino - Array contendo informações do destino selecionado. O primeiro elemento deve conter o código do destino.
         * @author Yuri 🇧🇷
         */
        changeDestinoSelecionado(destino){
            if (this.destinoSelecionadoCodigo !== destino[0]) {
                this.destinoSelecionadoCodigo = destino[0];
                this.consultaDestino(destino[0]);
            }

            if(this.cargaAtualAgrupar != undefined){
              this.cargaAtualAgrupar.destino = destino[0];
            }
        },

        /**
         * Atualiza a origem selecionado para uma carga pendente.
         *
         * @param {Array} destino - Array contendo informações do origem selecionada. O primeiro elemento deve conter o código da origem.
         * @author Yuri 🇧🇷
         */
        changeOrigemModal(origem){
            this.origemSelecionadaCodigo = origem[0];

            if(this.cargaAtualAgrupar != undefined){
              this.cargaAtualAgrupar.origem = origem[0];
            }
        },

        /**
         * Consulta informações sobre a origem fornecido e atualiza a propriedade `origemSelecionada` da instância com os dados recebidos.
         *
         * @param {string} origem - A origem que será consultado.
         * @throws {Error} - Lança um erro se a consulta falhar.
         * @author Yuri 🇧🇷
         */
        async consultaOrigem(origem){
            const url = this.urlBase + 'consultar/origem';
            const body = {"origem": origem};

            await new HttpRequest().Post(url, body).then((data) => {
                this.pontoInicial = data.data[0];
            }).catch(() => {
                this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
            })
        },

        /**
         * Consulta informações sobre o destino fornecido e atualiza a propriedade `destinoSelecionado` da instância com os dados recebidos.
         *
         * @param {string} destino - O destino que será consultado.
         * @throws {Error} - Lança um erro se a consulta falhar.
         * @author Yuri 🇧🇷
         */
         async consultaDestino(destino){
            const url = this.urlBase + 'consultar/destino';
            const body = {"destino": destino};

            await new HttpRequest().Post(url, body).then((data) => {
                this.destinoSelecionado = data.data;
                this.pontoFinal = data.data[0];
            }).catch(() => {
                this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
            })
        },

        /**
         * Consulta informações sobre o veículo fornecido e atualiza a propriedade `veiculoSelecionadoModalConfirmacao` da instância com os dados recebidos.
         *
         * @param {string} veículo - O veículo que será consultado.
         * @throws {Error} - Lança um erro se a consulta falhar.
         * @author Yuri 🇧🇷
         */
        async consultarVeiculo(veiculo){
            const url = this.urlBase + 'consultar/veiculo';
            const body = {"veiculo": veiculo};

            return await new HttpRequest().Post(url, body).then((data) => {
                this.veiculoSelecionadoModalConfirmacao = data.data[0].placa;
                return data.data[0].placa;
            }).catch(() => {
                this.toastShow('Ops!', 'Ocorreu um problema não esperado 22!', 'danger');
            })
        },

        /**
         * Salva o ponto de origem da última carga pendente selecionada.
         * O ponto de origem é definido como o destino da última carga pendente selecionada.
         * Esta função atualiza a origem selecionada com o ponto de origem da última carga pendente.
         * Nota: Esta função não aceita nenhum parâmetro.
         * @author Yuri 🇧🇷
         */
        salvarPontoOrigemUltimaCarga(){
            const ultimaCarga = this.cargasPendentesSelecionadas[this.cargasPendentesSelecionadas.length - 1];
                this.origemSelecionada = [{
                    value: ultimaCarga.pocodigo,
                    description: ultimaCarga.origem
                }];
        },

        /**
         * Atualiza o veículo de transbordo para uma carga pendente.
         *
         * @param {Array} veiculo - Array contendo informações do veículo de transbordo. O primeiro elemento deve conter o código do veículo.
         * @param {number} index - O índice da carga pendente na lista `cargasPendentes` que será atualizada com o novo veículo de transbordo.
         * @author Yuri 🇧🇷
         */
         async changeVeiculoTransbordo(veiculo, index) {
            const veiculoSelecionadoCodigo = veiculo.target.value;
            const selectElement = veiculo.target;
            const selectedOption = selectElement.options[selectElement.selectedIndex];
            const veiculoSelecionadoPlaca = selectedOption.text;

            this.cargasPendentes[index].veiculotransbordo = veiculoSelecionadoCodigo;
            this.cargasPendentes[index].veiculotransbordoplaca = veiculoSelecionadoPlaca;

            if (!this.cargaComTransbordo) {
                this.veiculoSelecionado = [{
                    description: await this.consultarVeiculo(veiculoSelecionadoCodigo),
                    value: veiculoSelecionadoCodigo
                }];
            }

            this.bloquearVeiculoTransbordoDemaisItens(index, veiculoSelecionadoCodigo, veiculoSelecionadoPlaca);
        },

        /**
        * Bloqueia a seleção do motorista para todos os itens de carga pendentes, exceto para um item específico.
        *
        * @param {number} indexNaoBloquear - O índice do item de carga que não deve ter a seleção do motorista bloqueada.
        * @param {string} motoristaSelecionado - O motorista selecionado que será atribuído aos itens de carga.
        * @author Yuri 🇧🇷
        */
        bloquearVeiculoTransbordoDemaisItens(indexNaoBloquear, veiculoSelecionado, placa){
            const cargaNaoBloquear = this.cargasPendentes[indexNaoBloquear];

            this.cargasPendentesSelecionadas.map((carga, index) => {
                if(carga.codigo == cargaNaoBloquear.codigo){
                    if(this.cargasPendentesSelecionadas[index].veiculotransbordo == null){
                        this.cargasPendentesSelecionadas[index].veiculotransbordo = veiculoSelecionado;
                    }

                    if(this.cargasPendentesSelecionadas[index].veiculotransbordoplaca == null){
                        this.cargasPendentesSelecionadas[index].veiculotransbordoplaca = placa;
                    }
                }else{
                    if(this.cargasPendentesSelecionadas[index].veiculotransbordo == null){
                        this.cargasPendentesSelecionadas[index].veiculotransbordo = veiculoSelecionado;
                    }

                    if(this.cargasPendentesSelecionadas[index].veiculotransbordoplaca == null){
                        this.cargasPendentesSelecionadas[index].veiculotransbordoplaca = placa;
                    }
                }
            })
        },

        /**
         * Atualiza o motorista de transbordo para uma carga pendente.
         *
         * @param {Array} motorista - Array contendo informações do motorista de transbordo. O primeiro elemento deve conter o código do motorista.
         * @param {number} index - O índice da carga pendente na lista `cargasPendentes` que será atualizada com o novo motorista de transbordo.
         * @author Yuri 🇧🇷
         */
        async changeMotoristaTransbordo(motorista, index){
            const motoristaSelecionadoCodigo = motorista.target.value;
            const selectElement = motorista.target;
            const selectedOption = selectElement.options[selectElement.selectedIndex];
            const nomeMotorista = selectedOption.text;

            this.cargasPendentes[index].motoristatransbordo = motoristaSelecionadoCodigo;
            this.cargasPendentes[index].motoristatransbordonome = nomeMotorista;

            if(!this.cargaComTransbordo){
              this.motoristaSelecionado = [{
                  description: await this.consultaMotorista(motoristaSelecionadoCodigo),
                  value: motoristaSelecionadoCodigo
              }];
              this.motoristaSelecionadoCodigo = motoristaSelecionadoCodigo;
            }

            this.bloquearMotoristaTransbordoDemaisItens(index, motoristaSelecionadoCodigo, nomeMotorista);
        },

        /**
        * Bloqueia a seleção do motorista para todos os itens de carga pendentes, exceto para um item específico.
        *
        * @param {number} indexNaoBloquear - O índice do item de carga que não deve ter a seleção do motorista bloqueada.
        * @param {string} motoristaSelecionado - O motorista selecionado que será atribuído aos itens de carga.
        * @author Yuri 🇧🇷
        */
        bloquearMotoristaTransbordoDemaisItens(indexNaoBloquear, motoristaSelecionado, nomeMotoristaSelecionado){
            const cargaNaoBloquear = this.cargasPendentes[indexNaoBloquear];

            this.cargasPendentesSelecionadas.map((carga, index) => {
                if(carga.codigo == cargaNaoBloquear.codigo){
                    if(this.cargasPendentesSelecionadas[index].motoristatransbordo == null){
                        this.cargasPendentesSelecionadas[index].motoristatransbordo = motoristaSelecionado;
                    }

                    if(this.cargasPendentesSelecionadas[index].motoristatransbordonome == null){
                        this.cargasPendentesSelecionadas[index].motoristatransbordonome = nomeMotoristaSelecionado;
                    }
                }else{
                    if(this.cargasPendentesSelecionadas[index].motoristatransbordo == null){
                        this.cargasPendentesSelecionadas[index].motoristatransbordo = motoristaSelecionado;
                    }

                    if(this.cargasPendentesSelecionadas[index].motoristatransbordonome == null){
                        this.cargasPendentesSelecionadas[index].motoristatransbordonome = nomeMotoristaSelecionado;
                    }
                }
            })
        },

        /**
         * Atualiza a origem de transbordo para uma carga pendente.
         *
         * @param {Array} origem - Array contendo informações da origem de transbordo. O primeiro elemento deve conter o código da origem.
         * @param {number} index - O índice da carga pendente na lista `cargasPendentes` que será atualizada com a nova origem de transbordo.
         * @param {boolean} salvar - Indica se as alterações devem ser salvas. Se for `true`, as alterações serão aplicadas à carga pendente especificada pelo índice `index`.
         * @author Yuri 🇧🇷
         */
        changeOrigemTransbordo(origem, index){
            const origemSelecionada = origem.target.value;
            const selectElement = origem.target;
            const selectedOption = selectElement.options[selectElement.selectedIndex];
            const origemNomeSelecionada = selectedOption.text;

            this.cargasPendentes[index].origem = origemSelecionada;
            this.cargasPendentes[index].origemnome = origemNomeSelecionada;
            this.cargaComTransbordo = true;

            this.bloquearOrigemDemaisItens(origemSelecionada);
        },

        /**
        * @description Salva na variável this.marcadoresSelecionados os marcadores (rotas)
        * de acordo com as empresas selecionadas em this.empresaSelecionada
        * @param {string} marcador - código do marcador selecionado.
        * @author Yuri 🇧🇷
        */
        changeMarcadores(marcador) {
            this.marcadoresSelecionados = marcador;
            this.limparDados();
            this.consultarCodigosCargas();
            this.codigosCargasSelected = [];
        },

        /**
         * Atualiza o filtro selecionado.
         *
         * @param {string} filtro - O filtro que será selecionado.
         * @author Yuri 🇧🇷
         */
        chanceFiltro(filtro) {
            this.filtroSelecionado = filtro;
            this.consultarCodigosCargas();
            this.codigosCargasSelected = [];
        },

        /**
        * @description Realiza a consulta dos veículos de acordo com as empresas
        * selecionadas em this.empresaSelecionada e após a consulta atribui os dados
        * a variável this.optionsSelectVeiculos.
        * @author Yuri 🇧🇷
        */
        async getVeiculos() {
            try {
                const url = this.urlBase + 'listar/veiculos';
                const body = { empresas: this.empresaSelecionada };

                await new HttpRequest().Post(url, body).then((data) => {
                    this.optionsSelectVeiculos = data.data;
                });
            } catch (error) {
                this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
                this.loadingPanel = false;
            } finally {
                this.loadingPanel = false;
            }
        },

        /**
        * @description Realiza a consulta dos marcadores (rotas) de acordo com as empresas
        * selecionadas em this.empresaSelecionada e após a consulta atribui os dados
        * a variável this.optionsSelectMarcadores.
        * @author Yuri 🇧🇷
        */
        async getMarcadores() {
            try {
                const url = this.urlBase + 'listar/marcadores';
                const body = {
                    empresas: this.empresaSelecionada,
                    veiculos: this.veiculosSelecionados,
                    intervalo: this.dataIntervalo,
                };
                this.optionsSelectMarcadores = [];

                await new HttpRequest().Post(url, body).then((data) => {
                    this.optionsSelectMarcadores = data.data;
                });

                this.optionsSelectMarcadores.forEach((e) => {
                  e.cor = this.pickColor(e.description)
                })
            } catch (error) {
                this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
                this.loadingPanel = false;
            } finally {
                this.loadingPanel = false;
            }
        },

        /**
        * @description Método para mostrar o toast na tela
        * @param {string} msg   - mensagem que vai aparecer no corpo do toast
        * @param {string} type  - qual o tipo do toast
        * @param {string} title - título para inserir no cabeçalho
        * @author Vitor Hugo 🐨
        */
        toastShow(title, msg, type) {
            this.$bvToast.toast(msg, {
                autoHideDelay: 3500,
                variant: type,
                title: title
            });
        },

        /**
         * Limpa os dados relacionados às cargas e rotas pendentes, além de redefinir as variáveis relacionadas à seleção de itens.
         * @author Yuri 🇧🇷
         */
        limparDados(limparCargas = false){
            this.cargaComTransbordo = false;
            this.cargasPendentesSelecionadas = [];
            this.veiculoSelecionado = [];
            this.motoristaSelecionado = [];
            this.cargasPendentes = [];
            this.rotasPendentes = [];
            this.posicaoAtualCargasPendentesSelecionadas = 1;
            this.selectedItemIndexCargaPendente = null;
            this.selectedItemIndexRotaPendente = null;
            this.pontosRota = [];
            this.veiculosSelecionados = [];
            this.veiculosSelecionadosInput = [];

            if(limparCargas){
                this.rastroMapa = [];
                this.rastroRota = [];
                this.rotaOsrmMapa = [];
                this.cargasPendentesSelecionadasMapa = [];
                this.mostrarPontoOrigem = false;
                this.mostrarPontoDestino = false;
                this.pontoInicial = [];
                this.pontoFinal = [];
            }
        },

        /**
        * Reseta todos os totalizadores para seus valores iniciais, zerando as métricas de cubagem, peso,
        * cargas, pedidos e entregas.
        *
        * @author Yuri 🇧🇷
        */
        limparDadosTotalizadores(){
            this.totalCubagem = 0;
            this.totalPeso = 0;
            this.totalCargas = 0;
            this.totalPedido = 0;
            this.totalEntrega = 0;
            this.totalPedidos = 0;
        },

        /**
         * Gera cargas e rotas pendentes com base nos filtros e parâmetros selecionados.
         * Limpa os dados existentes antes de iniciar o processo de geração.
         *
         * @throws {Error} Lança um erro se ocorrer um problema durante a solicitação.
         * @author Yuri 🇧🇷
         */
        async gerar(limparCargas = false, limparRotaEspecifica = true) {
            try {
                this.titleVeiculoTransbordo = 'Veic. Transbordo';
                this.titleMotoristaTransbordo = 'Mot. Transbordo';
                this.loadingRotas = true;
                this.loadingPanel = true;
                this.limparDados(limparCargas);
                this.itensRotaPendente = [];
                this.rotasPendentes = [];
                this.cargasAgrupadas = [];

                this.limparDadosTotalizadores();

                if(limparRotaEspecifica){
                    this.rotaCodigo = '';
                }

                const url = this.urlBase + 'gerar';
                const body = {
                    filtros: this.filtroSelecionado != undefined ? this.filtroSelecionado : [],
                    empresas: this.empresaSelecionada,
                    veiculos: this.veiculosSelecionadosFiltro,
                    intervalo: this.dataIntervalo,
                    marcadores: this.marcadoresSelecionados,
                    rotaespecifica: this.rotaCodigo,
                    codigosCargas: this.codigosCargasSelecionadas
                };

                await new HttpRequest().Post(url, body).then((data) => {
                    this.cargasPendentes = data.data.cargaspendente;
                    this.rotasPendentes = data.data.rotaspendente;
                    this.motoristas = data.data.motoristas;
                    this.pontosOrigem = data.data.pontos;
                    this.pontosDestino = data.data.pontos;
                    this.origemSelecionada = data.data.origem;
                    this.destinoSelecionado = data.data.destino;

                    if(data.data.origem.length > 0){
                        this.origemSelecionadaCodigo = data.data.origem[0].value;
                        this.destinoSelecionadoMatriz = data.data.origem[0].value;
                    }

                    if(data.data.destino.length > 0){
                        this.destinoSelecionadoCodigo = data.data.destino[0].value;
                    }

                    this.rotasPendentes.map((rota, index) => {
                        if(rota.clicado == true){
                            this.changeRotasPendentesSelecionar(rota, index);
                        }
                    });

                }).finally(() => {
                    this.loadingPanel = false;
                    this.loadingRotas = false;
                });
            } catch (error) {
                this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
                this.loadingPanel = false;
                this.loadingRotas = false;
            }
        },

        montagem() {
            this.toastShow('Atenção!', 'Função em desenvolvimento, aguarde!', 'warning');
        },

        controle() {
            this.toastShow('Atenção!', 'Função em desenvolvimento, aguarde!', 'warning');
        },

        /**
         * Agrupa as cargas pendentes selecionadas e atualiza a rota de entrega.
         *
         * Esta função faz uma requisição assíncrona para agrupar as cargas pendentes
         * selecionadas, com base nos dados de origem, destino, motorista, veículo e data
         * de início da rota. Durante o processo, exibe painéis de carregamento e, após a
         * conclusão, exibe modais de confirmação ou mensagens de erro, conforme necessário.
         *
         * @async
         * @throws {Error} Exibe uma mensagem de erro em caso de falha na requisição ou outro problema inesperado.
         * @author Yuri 🇧🇷
         */
        async agruparCargas(verificarCargaDiaSelecionado = true) {
            try {
                this.loadingPanel = true;
                this.loadingRotas = true;
                const url = this.urlBase + 'agrupar/cargas';
                let body = {};

                if(this.cargaComTransbordo){
                  body = {
                    "origem": this.origemSelecionadaCodigo,
                    "destino": this.destinoSelecionadoCodigo,
                    "motorista": parseInt(this.motoristaSelecionadoCodigo),
                    "veiculo": parseInt(this.veiculoSelecionado[0].value),
                    "cargas": this.cargasPendentesSelecionadas,
                    "data": this.dataInicioRota,
                    "verificarCargaDiaSelecionado": verificarCargaDiaSelecionado,
                    "empresa": this.empresaSelecionada,
                    "transbordo": this.cargaComTransbordo,
                    "codigoRotaAgrupada": '',
                    "placa": "",
                    "marcador": ""
                  }

                  this.agruparCargaSalvarBancoDados(url, body);
                }else {
                  this.agruparCargaSalvarBancoDados(url, this.cargaAtualAgrupar);
                }
            } catch (error) {
              this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
              this.loadingPanel = false;
              this.loadingRotas = false;
            }
        },

      /**
       * Remove um objeto do array de cargas com base no código fornecido e, em seguida,
       * exibe o modal de agrupamento de carga.
       *
       * @param {Array} array - O array de objetos de cargas.
       * @param {string|number} codigoDelete - O código do objeto que deve ser removido do array.
       * @author Yuri 🇧🇷
       */
        removeObjetoArrayCargas(array, codigoDelete) {
          const index = array.findIndex(objeto => objeto.codigo === codigoDelete);

          if (index !== -1) {
            array.splice(index, 1);
          }

          this.$bvModal.show('modalAgruparCarga');
        },

      /**
       * Agrupa cargas, salva as informações no banco de dados, e atualiza o estado da aplicação
       * com base na resposta da requisição. Dependendo do status retornado, exibe modais de alerta
       * e manipula o processo de agrupamento de cargas.
       *
       * @param {string} url - A URL para onde a requisição POST será enviada.
       * @param {Object} body - O corpo da requisição que contém os dados da carga a ser agrupada.
       * @author Yuri 🇧🇷
       */
        async agruparCargaSalvarBancoDados(url, body){
          this.motoristaSelecionadoAposAgrupar = this.motoristaSelecionadoCodigo;
          this.cargasPendentesSelecionadasMapa = this.cargasPendentesSelecionadas;
          this.dataCarregamentoCarga = this.dataInicioRota;
          this.consultarVeiculo(this.veiculoSelecionado[0].value);
          this.consultaOrigem(this.origemSelecionadaCodigo);
          this.consultaDestino(this.destinoSelecionadoCodigo);

          let veiculoCargaNoDia = false;

          await new HttpRequest().Post(url, body).then((data) => {
              if(data.data.status == false){
                  this.$bvModal.show('modalAlertaVeiculoComCarga');
                  veiculoCargaNoDia = true;
              }else{
                  const codigoRota = data.data.rotaAgrupada.rocodigo;
                  this.rotaCodigo = codigoRota;
                  body.codigoRotaAgrupada = codigoRota;
                  body.placa = data.data.rotaAgrupada.roplaca;
                  this.cargasAgrupadas.push(body);
                  this.$bvModal.hide('modalAlertaVeiculoComCarga');
                  veiculoCargaNoDia = false;
              }
          }).finally(() => {
              this.loadingPanel = false;
              this.loadingRotas = false;
              this.cargaAtualAgrupar = undefined;
          });

          if(!veiculoCargaNoDia){
            if(this.objetosCargasPlacas.length > 1){
              this.objetosCargasPlacas.shift();
              this.$bvModal.hide('modalAgruparCarga');
              this.chamarCargasSequenciaAgrupar(this.objetosCargasPlacas[0]);
            }else{
              this.objetosCargasPlacas = [];
              this.$bvModal.hide('modalAgruparCarga');
              this.chamarCargasSequenciaAgrupar(this.objetosCargasPlacas[0]);
            }
          }
        },

        /**
        * Agrupa os itens de dados por placa de veículo.
        *
        * @param {Array} data - O array de objetos a ser agrupado, onde cada objeto contém a propriedade `veiculotransbordoplaca`.
        * @returns {Object} Um objeto onde as chaves são as placas dos veículos e os valores são arrays de itens correspondentes.
        * @author Yuri 🇧🇷
        */
        groupByPlate(data) {
          return data.reduce((acc, item) => {
            const plate = item.veiculotransbordoplaca;

            if (!acc[plate]) {
              acc[plate] = [];
            }

              acc[plate].push(item);
              return acc;
            }, {});
        },

      /**
       * Agrupa cargas que possuem a mesma placa em um único objeto, com as informações
       * relacionadas à origem, destino, motorista, veículo, entre outros detalhes.
       *
       * @param {Object} groupedItems - Um objeto onde as chaves são as placas dos veículos e os valores são arrays de itens correspondentes.
       * @returns {Array} Um array de objetos, cada um representando um grupo de cargas associadas à mesma placa.
       * @author Yuri 🇧🇷
       */
        agruparCargasMesmaPlaca(groupedItems) {
          return Object.keys(groupedItems).map(placa => {
            const itens = groupedItems[placa];
            const cargaSelecionada = itens[0];

            return {
              origem: this.origemSelecionadaCodigo,
              destino: this.destinoSelecionadoCodigo,
              motorista: parseInt(cargaSelecionada.motoristatransbordo),
              veiculo: parseInt(cargaSelecionada.veiculotransbordo),
              cargas: itens,
              data: this.proximodia,
              verificarCargaDiaSelecionado: this.verificarCargaDiaSelecionado,
              empresa: this.empresaSelecionada,
              transbordo: this.cargaComTransbordo,
              codigoRotaAgrupada: '',
              placa: "",
              marcador: cargaSelecionada.marcador
            };
          });
        },

        /**
         * Mostra a rota no mapa e atualiza os pontos selecionados.
         *
         * Esta função oculta o modal de confirmação de carga agrupada, atualiza a rota
         * no mapa e define os pontos de origem e destino como visíveis. A função também
         * chama o método `gerar` para atualizar a visualização.
         *
         * @author Yuri 🇧🇷
         */
        mostrarRotaMapa(rotaEspeficia){
          this.$bvModal.hide('modalConfirmacaoCargaAgrupada');
          this.dataIntervalo = this.dataInicioRota + ' - ' + this.dataInicioRota;
          this.rotaCodigo = rotaEspeficia;
          this.gerar(false, false);
        },

        /**
         * Desagrupa a carga selecionada.
         *
         * Esta função verifica se uma rota foi selecionada para desagrupamento. Caso nenhuma rota
         * tenha sido selecionada, exibe uma mensagem de aviso. Atualmente, a função está em
         * desenvolvimento e exibe uma mensagem informando que a funcionalidade está em progresso.
         *
         * @throws {Error} Exibe uma mensagem de aviso se nenhuma rota for selecionada.
         * @author Yuri 🇧🇷
         */
        async desagruparCarga() {
            try {
                if(this.rotasPendenteSelecionado.length <= 0){
                    this.toastShow('Atenção!', 'Você precisa selecionar uma rota!', 'warning');
                }else{
                    this.loadingPanel = true;
                    this.loadingRotas = true;
                    const url = this.urlBase + 'desagrupar/cargas';
                    const body = {
                        "carga": this.rotasPendenteSelecionado
                    };

                    await new HttpRequest().Post(url, body).then((data) => {
                        if(data.data.status){
                            this.toastShow('Sucesso!', 'Carga desagrupada com sucesso!', 'success');
                        }
                    }).finally(() => {
                        this.loadingPanel = false;
                        this.loadingRotas = false;
                    });
                    this.gerar();
                    this.rastroMapa = [];
                    this.mostrarPontoDestino = false;
                    this.mostrarPontoOrigem = false;
                    this.pontoInicial = [];
                    this.pontoFinal = [];
                    this.rotaOsrmMapa = [];
                }
            } catch (error) {
                this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
                this.loadingPanel = false;
                this.loadingRotas = false;
            }
        },

        /**
         * Limpa as informações do mapa e redefine os pontos de rota.
         *
         * Esta função oculta o modal de confirmação de carga agrupada, redefine a
         * visibilidade dos pontos de origem e destino para falso, e limpa as listas
         * de pontos selecionados, rastro do mapa, rastro da rota e cargas pendentes
         * selecionadas. Após limpar as informações, chama o método `gerar` para
         * atualizar a visualização do mapa.
         *
         * @author Yuri 🇧🇷
         */
        async limparInformacoesMapa(gerar = false, reformularCargas = false, limparTotalizadores = false, removerCargaTransbodo = true){
            this.$bvModal.hide('modalConfirmacaoCargaAgrupada');
            this.mostrarPontoDestino = false;
            this.mostrarPontoOrigem = false;
            this.rastroMapa = [];
            this.rastroRota = [];
            this.rotaOsrmMapa = [];
            this.cargasPendentesSelecionadasMapa = [];

            if(removerCargaTransbodo){
              this.cargaComTransbordo = false;
              this.veiculoTransbordoSelecionado = false;
              this.titleVeiculoTransbordo = 'Veic. Transbordo';
              this.titleMotoristaTransbordo = 'Mot. Transbordo';
              this.titleOrigem = 'Origem';
            }

            if(limparTotalizadores){
                this.limparDadosTotalizadores();
            }

            await this.consultaDestino(this.destinoSelecionadoMatriz);
            this.destinoSelecionadoCodigo = this.destinoSelecionadoMatriz;
            this.origemSelecionadaCodigo = this.destinoSelecionadoMatriz;

            if(gerar){
                this.cargasPendentesSelecionadas = [];
                this.gerar(false);
            }else if(reformularCargas){
                this.cargasAgrupadas = [];
                this.cargasPendentes.map((carga, index) => {
                    this.cargasPendentesSelecionadas.map((cargaSelecionada) => {
                        if(carga.codigo == cargaSelecionada.codigo){
                            this.cargasPendentes[index].habilitaritens = false;
                            this.cargasPendentes[index].habilitado = true;
                            this.cargasPendentes[index].irstatus = "R";
                            this.cargasPendentes[index].status = "Finalizado";
                            this.cargasPendentes[index].posicao = null;

                            const checkboxId = 'cargapendenteeagleselecionar' + index;
                            const checkboxElement = document.getElementById(checkboxId);
                            checkboxElement.checked = false;

                            if(this.filtroSelecionado.length < 2){
                                if(this.filtroSelecionado[0] == 'I'){
                                    this.cargasPendentes.splice(index, 1);
                                }
                            }
                        }
                    })
                });

                this.cargasPendentesSelecionadas = [];
            }

            this.trataDadosCargasPendentes(this.cargasPendentes);
        },

        /**
         * Trata e organiza os dados das cargas pendentes, separando-as por status e ordenando.
         *
         * @param array $dados Array contendo os objetos de carga com informações de status.
         * @return array Array contendo as cargas pendentes ordenadas por status. As cargas com status 'I' (aguardando) são listadas antes das com status 'R' (finalizadas).
         * @throws Exception Se houver algum erro inesperado durante o processamento dos dados.
         * @author Yuri 🇧🇷
         */
        trataDadosCargasPendentes(dados) {
            const dadosOrdenados = [];
            const cargasAguardando = [];
            const cargasFinalizadas = [];

            dados.forEach(value => {
                if (value.irstatus === 'I') {
                    cargasAguardando.push(value);
                }

                if (value.irstatus === 'R') {
                    cargasFinalizadas.push(value);
                }
            });

            dadosOrdenados.push(...cargasAguardando, ...cargasFinalizadas);

            this.cargasPendentes = dadosOrdenados;
        },

        /**
         * Alterna o estado de habilitação dos itens de uma carga pendente.
         *
         * @param {number} index - O índice da carga pendente na lista `cargasPendentes`.
         * @author Yuri 🇧🇷
         */
        abrirItens(index){
            const anterior = this.cargasPendentes[index].habilitaritens;

            this.cargasPendentes.map((item, indexItem) => {
                this.cargasPendentes[indexItem].habilitaritens = false;
            });

            this.cargasPendentes[index].habilitaritens = !anterior;
        },

        /**
         * Seleciona ou desseleciona uma carga pendente.
         *
         * @param {Object} row - A carga pendente que será selecionada ou desselecionada.
         * @param {number} index - O índice da carga pendente na lista `cargasPendentes`.
         * @author Yuri 🇧🇷
         */
       async changeCargasPendentesSelecionar(row, index) {
          if(row.irstatus == 'R'){
            const checkboxId = 'cargapendenteeagleselecionar' + index;
            this.cargasPendentes[index].habilitarmotorista = true;
            this.cargasPendentes[index].habilitarveiculo = true;
            this.cargasPendentes[index].habilitarveiculo = false;

            const checkboxElement = document.getElementById(checkboxId);
            checkboxElement.checked = false;

            if(row.rocodigo){
              this.toastShow('Atenção!', `Essa carga já está agrupada a rota ${row.rocodigo} para o dia ${row.rodata}!`, 'warning');

            }else{
              this.toastShow('Atenção!', `Essa carga já está agrupada!`, 'warning');
            }
          }else{
            if(row.itens == null){
              const checkboxId = 'cargapendenteeagleselecionar' + index;
              const checkboxElement = document.getElementById(checkboxId);
              checkboxElement.checked = false;
            }else{
              const indexSelecionado = this.cargasPendentesSelecionadas.findIndex(item => item === row);
              this.cargasPendentes[index].habilitarmotorista = false;
              this.cargasPendentes[index].habilitarveiculo = false;
              this.limparInformacoesMapa(false, false, false, false)

              if(this.cargasPendentesSelecionadas.length > 0 && this.pressedKey == 'Shift'){
                const primeiraCarga = this.cargasPendentesSelecionadas[0];
                const ultimaCarga = this.cargasPendentes[index].codigo;

                let indexPrimeira = -1;
                let indexUltima = -1;

                this.cargasPendentes.forEach((cargaPendente, i) => {
                  if (cargaPendente.codigo === primeiraCarga.codigo) {
                    indexPrimeira = i;
                  }
                  if (cargaPendente.codigo === ultimaCarga) {
                    indexUltima = i;
                  }
                });

                if (indexPrimeira !== -1 && indexUltima !== -1) {
                  let isBottomToTop = false;

                  if (indexPrimeira > indexUltima) {
                    isBottomToTop = true;
                    [indexPrimeira, indexUltima] = [indexUltima, indexPrimeira];
                  }

                  this.cargasSelecionadasControl = this.cargasPendentes.slice(indexPrimeira, indexUltima + 1);

                  this.cargasSelecionadasControl.forEach((carga, i) => {
                    carga.posicaoCargasPendentes = indexPrimeira + i;
                  });

                  if (isBottomToTop) {
                    this.cargasSelecionadasControl = this.cargasSelecionadasControl.reverse();
                  }
                } else {
                    this.cargasSelecionadasControl = [];
                }

                this.pressedKey = '';

                this.cargasSelecionadasControl.forEach((carga, indexCargaSelecionadaControl) => {
                  if (
                    indexCargaSelecionadaControl > 0 &&
                    indexCargaSelecionadaControl < this.cargasSelecionadasControl.length - 1
                  ) {
                    this.changeCargasPendentesSelecionar(carga, carga.posicaoCargasPendentes);
                  }
                });
              }

              const checkboxId = 'cargapendenteeagleselecionar' + index;
              const checkboxElement = document.getElementById(checkboxId);
              checkboxElement.checked = true;

              if (indexSelecionado !== -1 ) {
                this.cargasPendentesSelecionadas.splice(indexSelecionado, 1);
                this.posicaoAtualCargasPendentesSelecionadas--;
                this.cargasPendentes[index].posicao = null;
                this.cargasPendentes[index].habilitado = true;
                this.cargasPendentesSelecionadasMapa = this.cargasPendentesSelecionadas;

                const checkboxId = 'cargapendenteeagleselecionar' + index;
                const checkboxElement = document.getElementById(checkboxId);
                checkboxElement.checked = false;
              } else {
                this.cargasPendentesSelecionadas.push(row);
                this.cargasPendentesSelecionadasMapa = this.cargasPendentesSelecionadas;
                const posicaoAtual = this.posicaoAtualCargasPendentesSelecionadas++;
                this.cargasPendentes[index].posicao = posicaoAtual;
                this.cargasPendentes[index].habilitado = false;

                if(row.itens){
                  this.zoomItensMapa();
                }

                if(this.veiculoSelecionado.length <= 0){
                  this.veiculoSelecionadoCodigo = this.cargasPendentes[index].vecodigo;
                }
              }
            }
          }

          this.organizarSequenciamento();
          this.somatorias();
      },

        /**
         * Função vai percorres os itens selecionados e mudoficar sua posicao e realizar o sequenciamento dos
         * itens selecioandos conforme o usuário selecionou e atualizar o valor dentro do campo posicao dentro das
         * variáveis this.cargasPendentes e this.cargasPendentesSelecionadas
         *
         * @author Yuri 🇧🇷
         */
        organizarSequenciamento(){
            this.cargasPendentesSelecionadas.map((carga, index) => {
                this.cargasPendentesSelecionadas[index].posicao = index + 1;
                this.cargasPendentes.map((cargaPendente, indexPendente) => {
                    if(cargaPendente.codigo == carga.codigo){
                        this.cargasPendentes[indexPendente].posicao = index + 1;
                    }
                })
            })
        },

        /**
         * Calcula e atualiza as somatórias de cubagem, peso, entregas e pedidos
         * com base nas cargas pendentes selecionadas.
         *
         * A função percorre todas as cargas pendentes selecionadas, somando os valores
         * de cubagem e peso, e contando o número de entregas e pedidos. Os resultados
         * são armazenados nas variáveis correspondentes da classe.
         *
         * @author Yuri 🇧🇷
         */
        somatorias(){
            let totalCubagemSomatoria = 0;
            let totalPesoSomatoria = 0;
            let totalEntregasSomatoria = 0;
            let totalPedidosSomatoria = 0;

            this.cargasPendentesSelecionadas.map((carga) => {
                totalCubagemSomatoria = parseFloat((totalCubagemSomatoria + carga.cubagem).toFixed(2));
                totalPesoSomatoria = parseFloat((totalPesoSomatoria + carga.peso).toFixed(2));
                totalEntregasSomatoria = carga.itens.length ? parseFloat((totalEntregasSomatoria + carga.itens.length).toFixed(2)) : 0;
                totalPedidosSomatoria = carga.itensdesagrupados.length ? parseFloat((totalPedidosSomatoria + carga.itensdesagrupados.length).toFixed(2)) : 0;
            });

            this.totalCubagem = totalCubagemSomatoria;
            this.totalPeso = totalPesoSomatoria;
            this.totalEntrega = totalEntregasSomatoria;
            this.totalPedidos = totalPedidosSomatoria;
            this.totalCargas = this.cargasPendentesSelecionadas.length;
        },

        /**
         * Move o mapa para um ponto específico com animação.
         *
         * Esta função posiciona o mapa em um ponto específico definido por latitude e
         * longitude, aplicando um zoom e utilizando opções de animação para suavizar o movimento.
         *
         * @param {number} latitude - A latitude do ponto para onde o mapa deve se mover.
         * @param {number} longitude - A longitude do ponto para onde o mapa deve se mover.
         * @author Yuri 🇧🇷
         */
        flyToPonto(latitude, longitude){
            const latlng = [latitude, longitude];
            const zoom = 20;
            const options = {
              animate: true,
              duration: 1,
              easeLinearity: 0.8
            };
            this.$refs.mapaSimples.flyTo(latlng, zoom, options);
        },

        /**
         * Redefine o estado de colapso das rotas pendentes, desmarcando todas como não clicadas.
         * @author Yuri 🇧🇷
         */
        resetCollapsRotasPendentes(){
            this.rotasPendentes.map((rotaPendente) => {
                rotaPendente.clicado = false
            });
        },

        /**
         * Seleciona uma rota pendente e alterna seu estado de colapso.
         *
         * @param {Object} row - A rota pendente que será selecionada.
         * @param {number} index - O índice da rota pendente na lista `rotasPendentes`.
         * @author Yuri 🇧🇷
         */
        changeRotasPendentesSelecionar(row, index) {
            this.resetCollapsRotasPendentes();
            this.limparInformacoesMapa(false);
            this.rotasPendenteSelecionado = row;
            this.rotasPendentes[index].clicado = !this.rotasPendentes[index].clicado;
            const rota = row.rota;
            const origem = row.pontoorigem;
            const destino = row.pontodestino;
            const itens = row.itensrotas;
            const infoRota = {
                tempo: row.temponumber,
                distancia: parseFloat(row.kmnumber),
                volume: row.entregas,
                peso: parseFloat(row.pesonumber),
                valor: parseFloat(row.valornumber),
                cubagem: parseFloat(row.cubagemnumber),
            };

            this.rotaOsrmMapa = infoRota;
            this.itensRotaPendente = itens;

            const rotaDecodificada = Array.isArray(rota) ? rota : decodePoly.decode(rota);
            this.rastroMapa = rotaDecodificada;
            this.$refs.mapaSimples.flyToBounds(this.rastroMapa);
            this.mostrarPontoOrigem = true;
            this.mostrarPontoDestino = true;
            this.pontoInicial = origem;
            this.pontoFinal = destino;

            this.pontosRota = row.itensrotas;

            if (this.selectedItemIndexRotaPendente === index) {
                this.selectedItemIndexRotaPendente = -1;
                this.resetCollapsRotasPendentes();
                this.itensRotaPendente = [];
                this.rastroMapa = [];
                this.mostrarPontoOrigem = false;
                this.mostrarPontoDestino = false;
                this.pontoInicial = [];
                this.pontoFinal = [];
                this.rotaOsrmMapa = [];
                this.rotasPendenteSelecionado = [];
                this.pontosRota = [];
            } else {
                this.selectedItemIndexRotaPendente = index;
            }
        },

        /**
         * Alterna a visibilidade do colapso das cargas pendentes.
         * Se as cargas pendentes estiverem visíveis, elas serão ocultadas e vice-versa.
         * @author Yuri 🇧🇷
         */
        toggleCollapseCargasPendentes() {
            this.cargasPendentesCollapseVisible = !this.cargasPendentesCollapseVisible;
            if(this.tituloCargaPendente == 'Cargas Pendentes +'){
                this.tituloCargaPendente = 'Cargas Pendentes -';
            }else{
                this.tituloCargaPendente = 'Cargas Pendentes +';
            }
        },

        /**
         * Alterna a visibilidade do colapso das rotas pendentes.
         * Se as rotas pendentes estiverem visíveis, elas serão ocultadas e vice-versa.
         * @author Yuri 🇧🇷
         */
        toggleCollapseRotasPendentes() {
            this.rotasPendentesCollapseVisible = !this.rotasPendentesCollapseVisible;
            if(this.tituloRotaPendente == 'Rotas Pendentes +'){
                this.tituloRotaPendente = 'Rotas Pendentes -';
            }else{
                this.tituloRotaPendente = 'Rotas Pendentes +';
            }
        },

        /**
         * Retorna a próxima data útil, isto é, o próximo dia que não seja sábado ou domingo, em formato de string "dd/mm/aaaa".
         *
         * @returns {string} A próxima data útil no formato "dd/mm/aaaa".
         * @author Yuri 🇧🇷
         */
         proximoDiaUtil() {
            const dataAtual = new Date();
            let proximoDia = new Date(dataAtual);

            if (proximoDia.getDay() === 6) {
                proximoDia.setDate(proximoDia.getDate() + 2);
            } else if (proximoDia.getDay() === 0) {
                proximoDia.setDate(proximoDia.getDate() + 1);
            } else {
                if (proximoDia.getDay() === 5) {
                    proximoDia.setDate(proximoDia.getDate() + 3);
                } else {
                    proximoDia.setDate(proximoDia.getDate() + 1);

                    if (proximoDia.getDay() === 6) {
                        proximoDia.setDate(proximoDia.getDate() + 2);
                    } else if (proximoDia.getDay() === 0) {
                        proximoDia.setDate(proximoDia.getDate() + 1);
                    }
                }
            }

            const dia = proximoDia.getDate().toString().padStart(2, '0');
            const mes = (proximoDia.getMonth() + 1).toString().padStart(2, '0');
            const ano = proximoDia.getFullYear();

            return `${dia}/${mes}/${ano}`;
        },

        /**
         * Abre o modal para agrupar cargas selecionadas, verificando se os campos obrigatórios estão preenchidos e exibindo mensagens de aviso se necessário.
         * @author Yuri 🇧🇷
         */
         abrirModalAgruparCarga(){
            if(this.cargasPendentesSelecionadas.length <= 0){
                this.toastShow('Atenção!', 'Selecione pelo menos uma carga para agrupar!', 'warning');

                return;
            }

            let liberadoMotoristaVeiculo = false;
            let cargaSemInconformidade = true;

            this.cargasPendentesSelecionadas.map((cargaPendente) => {
                cargaPendente.itensdesagrupados.map((carga) => {
                    if(carga.pontoerro){
                        cargaSemInconformidade = false;
                        Swal.fire({
                            icon: 'warning',
                            title: 'Carga com inconformidades!',
                            html: `O item ${carga.ircodigoexterno} - ${carga.irnome} não possuí ponto associado corretamente.`,
                            footer: ''
                        })

                        return;
                    }
                })
            })

            if(cargaSemInconformidade){
                this.cargasPendentesSelecionadas.map((cargaPendente) => {
                    liberadoMotoristaVeiculo = false;

                    if(cargaPendente.motoristatransbordo == null){
                        this.toastShow('Atenção!', `Informe o campo obrigatório, motorista, da carga ${cargaPendente.carga}!`, 'warning');

                        return;
                    }

                    if(cargaPendente.veiculotransbordo == null){
                        this.toastShow('Atenção!', `Informe o campo obrigatório, veículo, da carga ${cargaPendente.carga}!`, 'warning');

                        return;
                    }

                    liberadoMotoristaVeiculo = true;
                })

                if(liberadoMotoristaVeiculo){
                    if(this.cargaComTransbordo){
                        let camposPendentes = null;
                        let motoristaSelecionadoPrimeiro =  this.cargasPendentesSelecionadas[0].motorista;
                        let veiculoSelecionadoPrimeiro =  this.cargasPendentesSelecionadas[0].veiculo;
                        let conferirMotoristaTransbordo = false;
                        let conferirVeiculoTransbordo = false;

                        // eslint-disable-next-line no-unused-vars
                        const verificacaoMotoristaTransbordo = this.cargasPendentesSelecionadas.map(async (carga) =>
                            {
                                if(carga.motorista != motoristaSelecionadoPrimeiro){
                                    conferirMotoristaTransbordo = true;
                                }else{
                                    conferirMotoristaTransbordo = false;
                                }
                            }
                        );

                        // eslint-disable-next-line no-unused-vars
                        const verificacaoVeiculoTransbordo = this.cargasPendentesSelecionadas.map(async (carga, index) =>
                            {
                                if(carga.veiculo != veiculoSelecionadoPrimeiro){
                                    conferirVeiculoTransbordo = true;
                                }else{
                                    conferirVeiculoTransbordo = false;
                                }
                            }
                        );

                        if(conferirMotoristaTransbordo){
                            this.toastShow('Atenção!', 'Os motoristas de transbordo selecionados, devem ser iguais!', 'warning');

                        }else if(conferirVeiculoTransbordo){
                            this.toastShow('Atenção!', 'Os veículos de transbordo selecionados, devem ser iguais!', 'warning');

                        }else{
                            let liberarOrigem = true;

                            this.cargasPendentesSelecionadas.map(async (carga) => {
                                if(carga.origem == null){
                                    liberarOrigem = false;
                                }
                            });

                            if(liberarOrigem){
                                this.cargasPendentesSelecionadas.map(async (carga) => {
                                camposPendentes = null;

                                if(!this.veiculoTransbordoSelecionado){
                                    this.toastShow('Atenção!', 'Informe o campo obrigatório, veículo!', 'warning');
                                    camposPendentes = "veiculo";

                                    return;
                                }

                                if(!this.motoristaTransbordoSelecionado){
                                    this.toastShow('Atenção!', 'Informe o campo obrigatório, motorista!', 'warning');
                                    camposPendentes = "motorista";

                                    return;
                                }

                                if(this.veiculoSelecionado.length <= 0){
                                    this.toastShow('Atenção!', 'Informe o campo obrigatório, veículo!', 'warning');
                                    camposPendentes = "veiculo";

                                    return;
                                }

                                if(this.motoristaSelecionado.length <= 0){
                                    this.toastShow('Atenção!', 'Informe o campo obrigatório, motorista!', 'warning');
                                    camposPendentes = "motorista";

                                    return;
                                }

                                if(carga.origem == null){
                                    this.toastShow('Atenção!', `Informe o campo obrigatório, origem da carga ${carga.carga}!`, 'warning');
                                    camposPendentes = "origem";

                                    return;
                                }else{
                                    const tamanhoCargasPendentes = this.cargasPendentesSelecionadas.length;
                                    const codigoOrigem = this.cargasPendentesSelecionadas[tamanhoCargasPendentes - 1].origem;
                                    const bodyConsultaOrigem = {origem: codigoOrigem};

                                    const url = this.urlBase + 'consultar/origem';

                                    await new HttpRequest().Post(url, bodyConsultaOrigem).then((data) => {
                                        const origem = data.data[0];

                                        if(origem != undefined){
                                            this.destinoSelecionado = [{
                                                value: origem.pocodigo,
                                                description: origem.origem
                                            }];
                                        }else{
                                            this.toastShow('Atenção!', `Informe o campo obrigatório, origem!`, 'warning');
                                        }

                                        this.destinoSelecionadoCodigo = codigoOrigem;
                                    })
                                }
                            })

                            if(camposPendentes == null){
                                this.$bvModal.show('modalAgruparCarga');
                                this.proximodia = this.proximoDiaUtil();
                            }

                            }else{
                                this.toastShow('Atenção!', `Informe o campo obrigatório, origem!`, 'warning');
                            }
                        }
                    }else{
                        let abrirModal = false;

                        this.cargasPendentesSelecionadas.map((carga) => {
                            if(carga.motoristatransbordo == null){
                                this.toastShow('Atenção!', `Informe o campo obrigatório, motorista!`, 'warning');
                                abrirModal = false

                                return;
                            }

                            if(carga.veiculotransbordo == null){
                                this.toastShow('Atenção!', `Informe o campo obrigatório, veículo!`, 'warning');
                                abrirModal = false

                                return;
                            }

                            abrirModal = true;
                        })

                        if(abrirModal){
                          this.proximodia = this.proximoDiaUtil();

                          const groupedItems = this.groupByPlate(this.cargasPendentesSelecionadas);
                          this.objetosCargasPlacas = this.agruparCargasMesmaPlaca(groupedItems);

                          this.chamarCargasSequenciaAgrupar(this.objetosCargasPlacas[0]);
                        }
                    }
                }
            }
        },

      /**
       * Inicia o processo de agrupamento de cargas em sequência, preparando os dados necessários
       * para o modal de agrupamento ou exibindo a confirmação final de carga agrupada.
       *
       * @param {Object} carga - O objeto de carga atual que será utilizado no agrupamento.
       * @author Yuri 🇧🇷
       */
        async chamarCargasSequenciaAgrupar(carga) {
          if (this.objetosCargasPlacas.length > 0 && !this.cargaComTransbordo) {
            this.cargaAtualAgrupar = carga;

            const veiculoPromise = this.consultarVeiculo(carga.veiculo);
            const motoristaPromise = this.consultaMotorista(carga.motorista);

            const [veiculoDescription, motoristaDescription] = await Promise.all([veiculoPromise, motoristaPromise]);

            this.veiculoSelecionado = [{
              description: veiculoDescription,
              value: carga.veiculo
            }];

            this.motoristaSelecionado = [{
              description: motoristaDescription,
              value: carga.motorista
            }];

            this.destinoSelecionado = [{
              value: this.origemSelecionada[0].value,
              description: this.origemSelecionada[0].description
            }];

            this.$bvModal.show('modalAgruparCarga');
          } else {
            this.$bvModal.show('modalConfirmacaoCargaAgrupada');
          }
        },

        /**
        * Pega o valor da data do input de Data de início de rota e salva na variável this.dataInicioRota
        * @param Data String
        * @author Yuri 🇧🇷
        */
        changeDataInicioRota(data) {
            this.dataInicioRota = data;
        },

        /**
         * Realiza uma consulta para obter as informações de origem e destino relacionadas às cargas pendentes selecionadas.
         * Atualiza os campos de origem e destino selecionados com base nos dados recebidos da consulta.
         * Em caso de erro durante a consulta, exibe uma mensagem de erro.
         * @author Yuri 🇧🇷
         */
        async consultaOrigemDestino(){
            try {
                const url = this.urlBase + 'consuta/origem/destino';
                const body = {
                    empresas: this.empresaSelecionada,
                    cargasSelecionadas: this.cargasPendentesSelecionadas
                };

                await new HttpRequest().Post(url, body).then((data) => {
                    this.origemSelecionada = data.data[0].origem;
                    this.destinoSelecionado = data.data[0].destino;

                    this.origemSelecionadaCodigo = data.data[0].origem[0].value;
                    this.destinoSelecionadoCodigo = data.data[0].destino[0].value;
                })
            } catch (error) {
                this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
            }
        },

        /**
        * @description Retorna o html para mostrar um marker no mapa.
        * @param ordem
        * @return {string} html do marker
        * @author Yuri 🇧🇷
        */
        gerarIconeMapa(ordem, index, cargaAgrupada) {
            // eslint-disable-next-line no-undef
            return L.divIcon({
                className: 'custom-div-icon',
                html: `<div>
                        <span class='markerRotaOrdem'>${cargaAgrupada ? (`${ordem}.${index + 1}`) : (`${ordem}`)}</span>
                        <img src='img/pontoSelecionado.png' />
                    </div>`,
                iconSize: [32, 32],
                iconAnchor: [16, 32],
            });
        },

        /**
        * @description Retorna o html para mostrar um marker no mapa do ponto inicial ou final.
        * @return {string} html do marker
        * @author Yuri 🇧🇷
        */
        getIconInicialFinal(ponto) {
            if(ponto == 1){
                // eslint-disable-next-line no-undef
                return L.divIcon({
                className: 'custom-div-icon',
                html: `<div>
                        <img src='img/inicio.png' />
                    </div>`,
                iconSize: [32, 32],
                iconAnchor: [16, 32],
            });
            } else{
                    // eslint-disable-next-line no-undef
                    return L.divIcon({
                    className: 'custom-div-icon',
                    html: `<div>
                            <img src='img/fim.png' />
                        </div>`,
                    iconSize: [32, 32],
                    iconAnchor: [16, 32],
                });
            }
        },

        /**
        * @description Gerador de cor para rota
        * @returns {string} hex da cor gerada.
        * @author Desconhecido
        */
        geraCor() {
            var cores = [
                '#6A5ACD', '#483D8B', '#191970', '#000080', '#00008B',
                '#0000CD', '#0000FF', '#6495ED', '#4169E1', '#1E90FF',
                '#00BFFF', '#87CEEB', '#ADD8E6', '#4682B4', '#708090',
                '#778899', '#00FFFF', '#00CED1', '#40E0D0', '#48D1CC',
                '#20B2AA', '#008B8B', '#5F9EA0', '#2F4F4F', '#00FF7F',
                '#90EE90', '#8FBC8F', '#00FF00', '#3CB371', '#2E8B57',
                '#006400', '#008000', '#228B22', '#32CD32', '#BDB76B',
                '#7CFC00', '#7FFF00', '#9ACD32', '#6B8E23', '#556B2F',
                '#808000', '#D2691E', '#DAA520', '#B8860B', '#8B4513',
                '#A0522D', '#BC8F8F', '#CD853F', '#9400D3', '#DEB887',
                '#D2B48C', '#7B68EE', '#9370DB', '#8A2BE2', '#4B0082',
                '#DC143C', '#9932CC', '#BA55D3', '#800080', '#8B008B',
                '#EE82EE', '#DA70D6', '#FFA07A', '#FF1493', '#FF69B4',
                '#C71585', '#FFB6C1', '#F08080', '#CD5C5C', '#FF8C00',
                '#800000', '#8B0000', '#B22222', '#A52A2A', '#FA8072',
                '#E9967A', '#FF4500', '#FF7F50', '#FF6347', '#FF0000',
            ];

            var cor = cores[Math.floor(Math.random() * (0 + cores.length))];

            return cor;
        },

        /**
         * Gera uma cor HSL baseada em uma string fornecida.
         *
         * @param {string} str - A string para a qual a cor será gerada.
         * @returns {string} - A cor HSL gerada no formato `hsl(hue, saturation, lightness, alpha)`. Retorna uma cor padrão "rgb(66, 134, 244)" se a string for vazia ou nula.
         * @author Yuri 🇧🇷
         */
        pickColor(str) {
            if (!str) {
                return "rgb(66, 134, 244)";
            }

            const hue = this.hashCode(str) % 360;
            const saturation = "75%";
            const lightness = "60%";
            const alpha = 0.8;

            return `hsl(${hue}, ${saturation}, ${lightness}, ${alpha})`;
        },

        /**
         * Gera um código hash para uma string fornecida.
         *
         * @param {string} str - A string para a qual o código hash será gerado.
         * @returns {number} - O código hash gerado para a string. Retorna 0 se a string for vazia ou nula.
         * @author Yuri 🇧🇷
         */
        hashCode(str) {
            let hash = 0;

            if (!str) {
                return hash;
            }

            for (let i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }

            return hash;
        },

        /**
         * Formata um número para duas casas decimais.
         *
         * @param {number} number - O número a ser formatado.
         * @returns {number|string} - Retorna 0 se o número for menor ou igual a 0, nulo, ou não for um número; caso contrário, retorna o número formatado com duas casas decimais.
         * @author Yuri 🇧🇷
         */
        formatNumber(number) {
            if(number <= 0 || number == null || isNaN(number)){
                return 0;
            }else{
                return number.toFixed(2);
            }
        },

        /**
         * Ordena a tabela de cargas pendentes com base na coluna especificada.
         *
         * @param {string} column - O nome da coluna pelo qual a tabela deve ser ordenada.
         *
         * @description
         * Esta função ordena a tabela de cargas pendentes. Se a coluna de ordenação atual for a mesma
         * que a nova coluna especificada, a ordem de ordenação é invertida entre ascendente ('asc')
         * e descendente ('desc'). Caso contrário, a coluna de ordenação é atualizada para a nova coluna
         * e a ordem de ordenação é definida como ascendente ('asc').
         *
         * @author Yuri 🇧🇷
         */
        sortTableCargaPendente(column) {
            if (this.sortColumn === column) {
                this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
            } else {
                this.sortColumn = column;
                this.sortOrder = 'asc';
            }

            this.cargasPendentes.sort((a, b) => {
                let result = 0;

                if (typeof a[column] === 'string') {
                    result = a[column].localeCompare(b[column]);
                } else if (typeof a[column] === 'number') {
                    result = a[column] - b[column];
                }

                return this.sortOrder === 'asc' ? result : -result;
            });
        },

        /**
         * Ordena a tabela de rotas pendentes com base na coluna especificada.
         *
         * @param {string} column - O nome da coluna pelo qual a tabela deve ser ordenada.
         *
         * @description
         * Esta função ordena a tabela de rotas pendentes. Se a coluna de ordenação atual for a mesma
         * que a nova coluna especificada, a ordem de ordenação é invertida entre ascendente ('asc')
         * e descendente ('desc'). Caso contrário, a coluna de ordenação é atualizada para a nova coluna
         * e a ordem de ordenação é definida como ascendente ('asc').
         *
         *
         * @author Yuri 🇧🇷
         */
         sortTableRotaPendente(column) {
            if (this.sortColumn === column) {
                this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
            } else {
                this.sortColumn = column;
                this.sortOrder = 'asc';
            }

            this.selectedItemIndexRotaPendente = null;

            this.rotasPendentes.map((carga, index) => {
                this.rotasPendentes[index].clicado = false;
            });

            this.limparInformacoesMapaRotaPendente();

            this.rotasPendentes.sort((a, b) => {
                let result = 0;

                if (typeof a[column] === 'string') {
                    result = a[column].localeCompare(b[column]);
                } else if (typeof a[column] === 'number') {
                    result = a[column] - b[column];
                }

                return this.sortOrder === 'asc' ? result : -result;
            });
        },

        /**
         * Limpa todas as informações relacionadas ao mapa e à rota pendente.
         *
         * @description
         * Esta função redefine diversos atributos da classe para seus valores iniciais,
         * removendo quaisquer dados de rastro, rota e pontos no mapa. Além disso,
         * desativa a exibição dos pontos de origem e destino.
         *
         * - `rastroMapa`: Array que armazena o rastro do mapa.
         * - `rastroRota`: Array que armazena o rastro da rota.
         * - `rotaOsrmMapa`: Array que armazena dados da rota OSRM no mapa.
         * - `cargasPendentesSelecionadasMapa`: Array que armazena cargas pendentes selecionadas no mapa.
         * - `mostrarPontoOrigem`: Booleano que indica se o ponto de origem deve ser mostrado.
         * - `mostrarPontoDestino`: Booleano que indica se o ponto de destino deve ser mostrado.
         * - `pontoInicial`: Array que armazena o ponto inicial.
         * - `pontoFinal`: Array que armazena o ponto final.
         * - `pontosRota`: Array que armazena os pontos da rota.
         *
         * @author Yuri 🇧🇷
         */
        limparInformacoesMapaRotaPendente(){
            this.rastroMapa = [];
            this.rastroRota = [];
            this.rotaOsrmMapa = [];
            this.cargasPendentesSelecionadasMapa = [];
            this.mostrarPontoOrigem = false;
            this.mostrarPontoDestino = false;
            this.pontoInicial = [];
            this.pontoFinal = [];
            this.pontosRota = [];
        },

        /**
         * Consulta e abre a página de cadastro de pontos.
         *
         * @description
         * Esta função faz uma requisição POST para consultar pontos com base
         * em um código externo fornecido. Dependendo do resultado, ela abre uma nova aba com a
         * página de cadastro de ponto.
         *
         * - `ircodigoexterno`: Código externo utilizado para a consulta.
         * - `empresas`: Empresa selecionada para a consulta.
         *
         * @param {string} ircodigoexterno - O código externo utilizado para a consulta.
         *
         * @throws {Error} - Lança um erro caso ocorra algum problema inesperado durante a requisição.
         *
         * @author Yuri 🇧🇷
         */
        async pontoInconformidade(ircodigoexterno, carga, itens = []){
            try {
                const url = this.urlBase + 'consultar/ponto'
                const body = {
                    ircodigoexterno,
                    empresas: this.empresaSelecionada,
                };

                this.codigoExternoPontoNovo = ircodigoexterno;

                const objetoString = JSON.stringify(itens);
                localStorage.setItem("meuObjeto", objetoString);

                await new HttpRequest().Post(url, body).then((data) => {
                    this.controleAttPonto = true;

                    if(data.data[0]){
                        this.pontoEditado = data.data[0].pocodigo;
                        this.cargaPontoAtualizado = carga;
                        window.open(`#/finder/plus/cadastros/pontos/cadastrar/${data.data[0].pocodigo}`, '_blank');
                    }else{
                        this.cargaPontoAtualizado = carga;
                         window.open(`#/finder/plus/cadastros/pontos/cadastrar`, '_blank');
                    }
                })
            } catch (error) {
                this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
            }
        },

        /**
         * Ajusta o zoom do mapa para incluir todos os itens das cargas pendentes selecionadas.
         *
         * Esta função percorre os itens de rota das cargas pendentes selecionadas, extrai as latitudes e longitudes
         * de cada item, e ajusta o zoom do mapa para incluir todos esses pontos.
         *
         * @throws {Error} Lança um erro se ocorrer um problema durante a operação de ajuste do zoom.
         * @author Yuri 🇧🇷
         */
        zoomItensMapa() {
            const arrayLatitudesLongitudes = [];
            const deslocamento = 0.02;

            if(this.cargasPendentesSelecionadas.length > 0){
                this.cargasPendentesSelecionadas.map((itensRota) => {
                    itensRota.itensdesagrupados.map((item) => {
                        if(item.polatitude != null && item.polongitude != null){
                            const LatLon = [parseFloat(item.polatitude), parseFloat(item.polongitude)];
                            arrayLatitudesLongitudes.push(LatLon);

                            arrayLatitudesLongitudes.push([parseFloat(item.polatitude) + deslocamento, parseFloat(item.polongitude)]);
                            arrayLatitudesLongitudes.push([parseFloat(item.polatitude) - deslocamento, parseFloat(item.polongitude)]);
                            arrayLatitudesLongitudes.push([parseFloat(item.polatitude), parseFloat(item.polongitude) + deslocamento]);
                            arrayLatitudesLongitudes.push([parseFloat(item.polatitude), parseFloat(item.polongitude) - deslocamento]);
                        }
                    });
                });

                this.$refs.mapaSimples.flyToBounds(arrayLatitudesLongitudes);
            }else if(this.rastroMapa.length > 0) {
                this.$refs.mapaSimples.flyToBounds(this.rastroMapa);
            }
        },

        /**
         * Verifica o controle de atualização de pontos e, se habilitado, chama a função de busca e atualização de pontos.
         *
         * Esta função verifica se o controle de atualização de pontos (`controleAttPonto`) está habilitado. Se estiver,
         * chama a função `buscarPontosAtualizados` para buscar e atualizar os pontos específicos. Após a execução,
         * desabilita o controle de atualização de pontos.
         *
         * @throws {Error} Lança um erro se ocorrer um problema inesperado durante a operação de busca ou atualização.
         * @author Yuri 🇧🇷
         */
        async atualizaPontos() {
          if (this.controleAttPonto) {
            await this.buscarPontosAtualizados()
            this.controleAttPonto = false
          }
        },

        /**
         * Busca e atualiza os pontos específicos de uma carga, ajustando os dados das cargas pendentes e suas seleções.
         *
         * Esta função faz uma requisição HTTP para obter dados atualizados de um ponto específico. Após receber os dados,
         * a função percorre as cargas pendentes selecionadas e atualiza as coordenadas (latitude e longitude) e endereço dos
         * itens correspondentes, eliminando erros de ponto. Além disso, atualiza as listas de itens desagrupados e itens das cargas
         * pendentes selecionadas e de todas as cargas pendentes.
         *
         * @throws {Error} Lança um erro se ocorrer um problema inesperado durante a operação de busca ou atualização.
         * @author Yuri 🇧🇷
         */
        async buscarPontosAtualizados() {
            try {
                this.loadingRotas = true;
                const url = this.urlBase + 'consultar/ponto/especifico';
                const body = {
                    codigoPonto: this.pontoEditado,
                    empresas: this.empresaSelecionada,
                };
                const data = await new HttpRequest().Post(url, body);
                const pontoAtualizado = data.data[0];

                const urlPontoNovo = this.urlBase + 'consultar/ponto/novo/especifico';
                const bodyPontoNovo = {
                    ircodigoexterno: this.codigoExternoPontoNovo,
                    empresas: this.empresaSelecionada,
                };

                const dataPontoNovo = await new HttpRequest().Post(urlPontoNovo, bodyPontoNovo);
                const pontoNovo = dataPontoNovo.data[0];

                if(this.cargasPendentesSelecionadas.length > 0){
                    this.cargasPendentesSelecionadas.forEach((carga, index) => {
                        if (carga.codigo === this.cargaPontoAtualizado.codigo) {
                            carga.itensdesagrupados.forEach((item, indexItens) => {
                                if (pontoAtualizado && item.pocodigo === pontoAtualizado.pocodigo) {
                                    this.cargasPendentesSelecionadas[index].itensdesagrupados[indexItens].polatitude = pontoAtualizado.polatitude;
                                    this.cargasPendentesSelecionadas[index].itensdesagrupados[indexItens].polongitude = pontoAtualizado.polongitude;
                                    this.cargasPendentesSelecionadas[index].itensdesagrupados[indexItens].endereco = pontoAtualizado.poendereco;
                                    this.cargasPendentesSelecionadas[index].itensdesagrupados[indexItens].irnome = pontoAtualizado.origem;
                                    this.cargasPendentesSelecionadas[index].itensdesagrupados[indexItens].origem = pontoAtualizado.origem;
                                    this.cargasPendentesSelecionadas[index].itensdesagrupados[indexItens].pontoerro = false;
                                }else if(pontoNovo && item.ircodigoexterno == pontoNovo.pocodigoexterno) {
                                    this.cargasPendentesSelecionadas[index].itensdesagrupados[indexItens].polatitude = pontoNovo.polatitude;
                                    this.cargasPendentesSelecionadas[index].itensdesagrupados[indexItens].polongitude = pontoNovo.polongitude;
                                    this.cargasPendentesSelecionadas[index].itensdesagrupados[indexItens].endereco = pontoNovo.poendereco;
                                    this.cargasPendentesSelecionadas[index].itensdesagrupados[indexItens].irnome = pontoNovo.origem;
                                    this.cargasPendentesSelecionadas[index].itensdesagrupados[indexItens].origem = pontoNovo.origem;
                                    this.cargasPendentesSelecionadas[index].itensdesagrupados[indexItens].pontoerro = false;
                                    this.cargasPendentesSelecionadas[index].itens.push(pontoNovo);
                                }

                                if(this.cargasPendentesSelecionadas[index].itensdesagrupados[indexItens].polatitude != null){
                                    this.cargasPendentesSelecionadas[index].inconformidade = false;
                                }else{
                                    this.cargasPendentesSelecionadas[index].inconformidade = true;
                                }
                            });
                        }
                    });

                    this.cargasPendentesSelecionadas.forEach((carga, index) => {
                        if (carga.codigo === this.cargaPontoAtualizado.codigo) {
                            carga.itens.forEach((item, indexItens) => {
                                if (pontoAtualizado && item.pocodigo === pontoAtualizado.pocodigo) {
                                    this.cargasPendentesSelecionadas[index].itens[indexItens] = pontoAtualizado;
                                }
                            });
                        }
                    });
                }

                this.cargasPendentes.forEach((carga, index) => {
                    if (carga.codigo === this.cargaPontoAtualizado.codigo) {
                        carga.itensdesagrupados.forEach((item, indexItens) => {
                            if (pontoAtualizado && item.pocodigo === pontoAtualizado.pocodigo) {
                                this.cargasPendentes[index].itensdesagrupados[indexItens].polatitude = pontoAtualizado.polatitude;
                                this.cargasPendentes[index].itensdesagrupados[indexItens].polongitude = pontoAtualizado.polongitude;
                                this.cargasPendentes[index].itensdesagrupados[indexItens].endereco = pontoAtualizado.poendereco;
                                this.cargasPendentes[index].itensdesagrupados[indexItens].irnome = pontoAtualizado.origem;
                                this.cargasPendentes[index].itensdesagrupados[indexItens].origem = pontoAtualizado.origem;
                                this.cargasPendentes[index].itensdesagrupados[indexItens].pontoerro = false;
                            }else if(pontoNovo && item.ircodigoexterno == pontoNovo.pocodigoexterno) {
                                this.cargasPendentes[index].itensdesagrupados[indexItens].polatitude = pontoNovo.polatitude;
                                this.cargasPendentes[index].itensdesagrupados[indexItens].polongitude = pontoNovo.polongitude;
                                this.cargasPendentes[index].itensdesagrupados[indexItens].endereco = pontoNovo.poendereco;
                                this.cargasPendentes[index].itensdesagrupados[indexItens].irnome = pontoNovo.origem;
                                this.cargasPendentes[index].itensdesagrupados[indexItens].origem = pontoNovo.origem;
                                this.cargasPendentes[index].itensdesagrupados[indexItens].pontoerro = false;

                                if(this.cargasPendentesSelecionadas[0] == undefined){
                                this.cargasPendentes[index].itens.push(pontoNovo)}
                            }

                            if(this.cargasPendentes[index].itensdesagrupados[indexItens].polatitude != null){
                                this.cargasPendentes[index].inconformidade = false;
                            }else{
                                this.cargasPendentes[index].inconformidade = true;
                            }
                        });
                    }
                });

                this.cargasPendentes.forEach((carga, index) => {
                    if (carga.codigo === this.cargaPontoAtualizado.codigo) {
                        carga.itens.forEach((item, indexItens) => {
                            if (pontoAtualizado && item.pocodigo === pontoAtualizado.pocodigo) {
                                this.cargasPendentes[index].itens[indexItens] = pontoAtualizado;
                            }
                        });
                    }
                });

                this.cargasPendentesSelecionadasMapa = this.cargasPendentesSelecionadas;

                this.zoomItensMapa();
                this.$forceUpdate();

                this.loadingRotas = false;
            } catch (error) {
                this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
            }
        },

        /**
       * @description quando o usuário clica em editar um ponto,
       * e é redirecionado, essa função adiciona eventos para monitorar
       * a volta do usuário e recarregar os pontos quando ele voltar p/
       * mostrar as informações mais atualizadas
       * @author Lucas Eduardo, alterado por Rafael
       */
        addVisibilityChangeListener(callback) {
          var hidden = 'hidden';
          var visibilityChange = null;
          if (hidden in document) {
            visibilityChange = 'visibilitychange';
          } else if ((hidden = 'mozHidden') in document) {
            visibilityChange = 'mozvisibilitychange';
          } else if ((hidden = 'webkitHidden') in document) {
            visibilityChange = 'webkitvisibilitychange';
          } else if ((hidden = 'msHidden') in document) {
            visibilityChange = 'msvisibilitychange';
          } else if ('onfocusin' in document) {
            document.onfocusin = document.onfocusout = onchange;
          } else {
            window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onchange;
          }
          document.addEventListener(visibilityChange, onchange);
          function onchange(evt) {
            var evtMap = {
              focus: true,
              focusin: true,
              pageshow: true,
              blur: false,
              focusout: false,
              pagehide: false
            };
            evt = evt || window.event;
            if (evt.type in evtMap) {
              evtMap[evt.type] ? callback() : '';
            } else {
              this[hidden] ? '' : callback();
            }
          }
        },

        /**
         * Consulta e atualiza os códigos de cargas de acordo com as empresas selecionadas e o intervalo de datas.
         *
         * Esta função realiza uma requisição HTTP para obter os códigos de cargas relacionados às empresas selecionadas
         * dentro do intervalo de datas especificado. Se houver empresas selecionadas, os códigos de cargas são atualizados.
         * Caso contrário, a lista de códigos de cargas selecionados é esvaziada.
         *
         * @throws {Error} Lança um erro se ocorrer um problema inesperado durante a operação de consulta.
         * @author Yuri 🇧🇷
         */
        changeCodigoCarga(codigoCarga){
            this.codigosCargasSelecionadas = codigoCarga;
            this.limparDados();
        },

        /**
         * Atualiza o código de carga selecionado e limpa os dados existentes.
         *
         * Esta função define o código de carga selecionado e, em seguida, chama a função para limpar os dados
         * associados ao código de carga anterior.
         *
         * @param {string} codigoCarga - O novo código de carga a ser selecionado.
         * @author Yuri 🇧🇷
         */
        async consultarCodigosCargas(){
            try {
                const url = this.urlBase + 'consultar/codigos/cargas'
                const body = {
                    empresas: this.empresaSelecionada,
                    intervalo: this.dataIntervalo,
                    veiculos: this.veiculosSelecionadosFiltro,
                    marcadores: this.marcadoresSelecionados,
                    filtros: this.filtroSelecionado != undefined ? this.filtroSelecionado : [],
                };

                if(this.empresaSelecionada.length > 0){
                    await new HttpRequest().Post(url, body).then((data) => {
                        this.codigosCargas = data.data;
                    })
                }else{
                    this.codigosCargasSelected = [];
                }
            } catch (error) {
                this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
            }
        },

      /**
       * Manipula o evento de pressionamento de tecla, atualizando a propriedade `pressedKey`
       * com a tecla que foi pressionada.
       *
       * @param {KeyboardEvent} event - O evento de teclado que contém informações sobre a tecla pressionada.
       * @author Yuri 🇧🇷
       */
        handleKeyDown(event) {
          this.pressedKey = event.key;
        }
    },

    mounted() {
      this.addVisibilityChangeListener(this.atualizaPontos);
      window.addEventListener('keydown', this.handleKeyDown);
    },

    beforeUnmount() {
      window.removeEventListener('keydown', this.handleKeyDown);
    },
})
</script>

<style lang="css" scoped>
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.larger-checkbox {
  transform: scale(1.4);
}

input {
  height: 25px;
  padding: 5px !important;
}

.styleInputTable {
  padding: 5px !important;
  background-color: white;
  border-radius: 5px;
  border: none;
  color: black;
}

.styleInputTable:focus,
.styleInputTable:active {
  outline: none;
  box-shadow: none;
}

.styleInputTable option {
  padding: 10px 0;
}

.styleInputTable option:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.bg-gray-table {
  background-color: rgb(219, 220, 221);
}

.styleOption {
  font-size: 15px;
  color: black;
  line-height: 1.625 !important;
}

.markerRotaOrdem {
  position: absolute;
  top: 3px;
  background-color: rgba(255, 255, 255, 0.24);
  padding: 0 6px;
  border-radius: 43px;
  font-size: 12px;
  left: 8px;
  width: 25px;
  text-align: center;
  font-weight: bold;
}

.fontBold {
  font-weight: bold;
}

.bemAlto {
  z-index: 999;
}

.roudend-lg {
  border-radius: 0.5rem;
  cursor: default;
  font-size: 10px;
}

.styleIconAlert {
  color: red;
  animation: pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.acoesMapa {
  display: grid;
  float: right;
  position: inherit;
  z-index: 401;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}

.text-sm {
  font-size: 11px;
}

.bg-gray-100 {
  background-color: rgb(243 244 246);
}

.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
  padding: 10px;
  border-radius: 0.375rem;
}
</style>
