// Arquivo Helper para implementar funções de validação de dados.

/**
 * Faz validação de documento CPF ou CNPJ
 * @param { string } value CPF | CNPJ
 * @return { bool } true | false
 * @link https://irias.com.br/blog/como-validar-cpf-cnpj-em-node-js/
 * @authors Rafael
 */
export function validaCpfCnpj(value) {
    if (value.length == 14) {
        let cpf = value.trim()
        cpf = cpf.replace(/\./g, '')
        cpf = cpf.replace('-', '')
        cpf = cpf.split('')
        let p = 0, i = 0, v1 = 0, v2 = 0, aux = false
        for (i = 1; cpf.length > i; i++) {
            if (cpf[i - 1] != cpf[i]) {
                aux = true
				break
            }
        } 
        if (aux == false) return false 
        for (i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
            v1 += cpf[i] * p
        } 
        v1 = ((v1 * 10) % 11)
        if (v1 == 10) v1 = 0
        if (v1 != cpf[9]) return false 
        for (i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
            v2 += cpf[i] * p
        } 
        v2 = ((v2 * 10) % 11)
        if (v2 == 10) v2 = 0
		return !(v2 != cpf[10])
    } else if (value.length == 18) {
        let cnpj = value.trim()
        cnpj = cnpj.replace(/\./g, '')
        cnpj = cnpj.replace('-', '')
        cnpj = cnpj.replace('/', '')
        cnpj = cnpj.split('')
        let p1 = 0, p2 = 0, i = 0, v1 = 0, v2 = 0, aux = false
        for (i = 1; cnpj.length > i; i++) { 
            if (cnpj[i - 1] != cnpj[i]) {  
                aux = true
				break
            }
        }
        if (aux == false) return false
        for (i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
			v1 += cnpj[i] * ( p1 >= 2 ? p1 : p2 )
        }
        v1 = (v1 % 11)
		v1 = v1 < 2 ? 0 : (11 - v1)
        if (v1 != cnpj[12]) return false 
        for (i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) { 
			v2 += cnpj[i] * ( p1 >= 2 ? p1 : p2 )
        }
        v2 = (v2 % 11)
		v2 = v2 < 2 ? 0 : (11 - v2)
		return !(v2 != cnpj[13])
    } else {
        return false
    }
}

/**
 * @param { string } cnpj
 * @returns { bool }
 * @authors Feito pelo Lucão, porém escrito aqui pelo Marcão 😯🤝😯
 */
export function validaCNPJ(cnpj){
	cnpj = cnpj.replace(/[\D]+/g,'')
	if (cnpj == '') return false;
	if (cnpj.length != 14) return false;
	if (cnpj == cnpj.split('').reverse().join('')) return false;
	let tamanho = cnpj.length - 2;
	let numeros = cnpj.substring(0,tamanho);
	let	digitos = cnpj.substring(tamanho);
	let soma = 0;
	let pos = tamanho - 7;
	for (let i = tamanho; i>= 1; i--){
		soma += numeros.charAt(tamanho - i) * pos--;
		if (pos < 2) pos = 9
	}
	let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
	if (resultado != digitos.charAt(0)) return false;
	tamanho = tamanho + 1;
	numeros = cnpj.substring(0,tamanho);
	soma = 0;
	pos = tamanho - 7;
	for (let i = tamanho; i >= 1; i--){
		soma += numeros.charAt(tamanho - i) * pos--;
		if (pos < 2) pos = 9;
	}
	resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
	if (resultado != digitos.charAt(1)) return false;
	return true;
}

/**
 * calculo para validar cpf
 * fiz nos representantes e passei pra ca tbm
 * @param { String } value
 * @returns { Boolean }
 * @author Lucas Eduardo
 */
export function validaCPF(value){
    var strCPF = value.replace(/[^0-9]/g, "")
    var soma = 0
    var resto
    if(strCPF.length !== 11) return false
    if(strCPF === strCPF.split("").reverse().join("")) return false
    for(let i = 1; i <= 9; i++){
        soma = soma + parseInt(strCPF.substring(i-1, i)) * (11 - i)
    } 
    resto = (soma * 10) % 11
    if((resto == 10) || (resto == 11)) resto = 0
    if(resto != parseInt(strCPF.substring(9, 10))) return false
    soma = 0
    for(let i = 1; i <= 10; i++){
        soma = soma + parseInt(strCPF.substring(i-1, i)) * (12 - i)
    }
    resto = (soma * 10) % 11
    if((resto == 10) || (resto == 11)) resto = 0
    if(resto != parseInt(strCPF.substring(10, 11))) return false
    return true
}

export function validaEmail(email) {
    const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return reg.test(email)
}