<template>
    <div id="accordionEtapas">
        <button
            @click="clickAccordion()"
            class="col-sm-12 buttonAccordion"
            :aria-expanded="opened"
            :aria-controls="`collapse${_uid}`"
            :aria-disabled="disabled"
            :disabled="disabled"
            :class="{
                disabled: disabled,
                concluded: concluded,
                pendente: !concluded && !disabled,
            }"
        >
            <div class="buttonElement">
                <div style="padding: 3px 10px 0 0; width:25px; font-weight: bold;"
                    :style="{ 'color': 'white' }">
                    <b-icon 
                        v-if="concluded"
                        class="h5 mb-2 my-auto"
                        :icon="'check'"
                        scale="2"
                    />
                    <!-- :icon="'chevron-double-' + (opened ? 'down' : 'right')" -->
                    <b-icon v-else
                        icon="circle-fill"
                        scale="1.2">
                    </b-icon>
                </div>
                <div
                    class="element"
                    :style="{ 'background-color': concluded ? 'green' : '#428BCA' }"
                >
                    <div class="step">
                        {{ step ? step : "&nbsp;" }}
                    </div>
                </div>
                <div class="title">{{ title }}</div>
            </div>
        </button>
        <!-- <div v-show="opened" :id="`collapse${_uid}`">
			<slot name="content" />
		</div> -->
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "",
            required: true,
        },
        step: {
            type: String,
            default: "",
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
            required: true,
        },
        opened: {
            type: Boolean,
            default: false,
            required: true,
        },
        concluded: {
            type: Boolean,
            default: false,
        },
        disabledColor: {
            type: String,
            default: "grey",
        },
    },

    methods: {
        clickAccordion() {
            this.$emit("click");
        },
    },
};
</script>

<style lang="scss" scoped>
#accordionEtapas {
    .buttonAccordion {
        width: 100%;
        height: 40px;
        border: 0;
        text-align-last: left;
        padding: 2px 10px 0px 10px;
    }
    .concluded {
        background-color: #9ed9a6 !important;
    }
    .pendente {
        background-color: #9ebcd9 !important;
    }
    .buttonElement {
        display: inline-flex;
        .element {
            color: white;
            height: 21px;
            padding: 2px;
            min-width: 21px;
            border-radius: 21px;
            background-color: rgb(66, 139, 202);
        }
        .step {
            text-align-last: center;
            font-weight: bold;
        }
        .title {
            padding-left: 10px;
            padding-top: 2px;
        }
    }
	.disabled {
		background-color: #eee !important;
		color: #555 !important;
	}
}
</style>
