<template>
	<div>
		<div class='col-8'>
			<select-all
			nameForRadio='selCliente'
			:isMultiple='false'
			:hasSelectAll='false'
			:labels='labelPeriodo'
			:optionsArray='optSelectTempo'
			extraClass='mb-3'
			:selected='prePeriodoSel'
			:disabled='existeAjuste'
			@changeSelect='mudaPeriodo'/>
			<select-all
			nameForRadio='selCliente'
			:isMultiple='false'
			:hasSelectAll='false'
			:labels='labelTipo'
			:optionsArray='optSelectTipo'
			:disabled='existeAjuste'
			:selected='preTipoSel'
			@changeSelect='mudaTipo'/>
		</div>
	</div>
</template>

<script>
export default {
	components:{
		selectAll: require('@/components/Atom/Select/SelectAll.vue').default,
	},
	props: {
		preTipo:{
			type:[Number, String],
			default:''
		},
		prePeriodo:{
			type:Boolean,
			default: false,
		},
		existeAjuste:{
			type: Boolean,
			default:false
		}
	},
	data() {
		return {
			optSelectTempo: [
				{value:true, description: 'O dia todo'},
				{value:false, description: 'Somente o tempo faltante'},
			],
			optSelectTipo:[
				{value: '2', description: 'Atestado'},
				{value: '5', description: 'Folga'},
			],
			labelPeriodo: [{indexDFH:'JUS', description:'Selecione o período*'}],
			labelTipo:[{indexDFH:'PER',description:'Selecione o tipo*'}],
			prePeriodoSel:[],
			preTipoSel:[],
		}
	},
	methods:{
		mudaPeriodo(arr){
			let retorno = ''
			if(arr.length) retorno = arr[0]
			this.$emit('muda-periodo', retorno)
		},
		mudaTipo(arr){
			let retorno = ''
			if(arr.length) retorno = arr[0]
			this.$emit('muda-tipo', retorno)
		}
	},
	computed:{
	},
	mounted(){
		if(this.preTipo!==''){
			this.preTipoSel = [this.optSelectTipo.find((v)=>v.value == this.preTipo)]
		}
		this.prePeriodoSel = [this.optSelectTempo.find((v)=>v.value == this.prePeriodo)]
	}
}
</script>

<style lang="scss" scoped>

</style>