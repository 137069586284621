<template>
    <panelEagle id="analiseProdutividade"
        :loading="loadingPanel">
        <div class="col-sm-12 nopadding ">        
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiFinance" titulo="Análise de produtividade"></tituloPage>
                </div>
                <div class="col-sm-6 divButtonsBasic divDontPrint">
                    <basicButtonsRelatoriosEIG
                        :disabledDropdown="!Object.keys(dadosRelatorio).length"
                        @exportarRelatorio ="exportar" 
                        @gerarRelatorio    ="gerar"
                        :disabled          ="$v.$invalid"
                        :loading           ='loadingExportar'>
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12"><hr></div>
                    <div class="col-sm-12 row nopadding">
                        <div class="col-sm-2 nopadding">
                            <selectAll 
                                @changeSelect ="changeSelectDiasSemana" 
                                :isMultiple   ="false" 
                                nameForRadio  ="radiosDiasSemana" 
                                :labels       ="[{indexDFH: 'D', description: 'Dias da semana*'}]" 
                                :optionsArray ="optionsDiasSemana" 
                                firstSelected ="D" 
                                :hasSelectAll ="false">
                            </selectAll>
                        </div>
                        <div class="col-sm-2 nopadding">
                            <inputSimple 
                                @changeInput ="changeHoraInicio" 
                                label        ="Inicio *" 
                                :hasMask     ="true" 
                                :mask        ="['##:##:##']" 
                                name         ="inicioRel" 
                                value        ="00:00:00" 
                                id           ="inicioRel">
                            </inputSimple>
                        </div>
                        <div class="col-sm-2 nopadding">
                            <inputSimple 
                                @changeInput ="changeHoraFim"
                                label        ="Fim *" 
                                :hasMask     ="true" 
                                :mask        ="['##:##:##']"
                                name         ="fimRel" 
                                value        ="23:59:59" 
                                id           ="fimRel">
                            </inputSimple>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll 
                                @changeSelect ="changeSelectEmpresas" 
                                :isMultiple   ="true" 
                                nameForRadio  ="radiosEmpresas" 
                                :labels       ="[{indexDFH: 'EM', description: 'Empresas *'}]" 
                                :optionsArray ="optionsEmpresa" 
                                firstSelected ="EM" 
                                :selected="selectedEmpresa"
                                :hasSelectAll ="true">
                            </selectAll>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll 
                                @changeSelect ="changeSelectColabEscala" 
                                @changeCheck  ="changeCheckColabEscala" 
                                :isMultiple   ="true" 
                                :loading      ="carregando"
                                nameForRadio  ="radiosColabEsc" 
                                :labels       ="labelColabEscala" 
                                :optionsArray ="optionsColabEscala" 
                                firstSelected ="C" 
                                :disabled     ="$v.empresasSelecionadas.$invalid"
                                :hasSelectAll ="true"
                                ref="CE">
                            </selectAll>
                        </div>
                        <div class="col-sm-12">
                            <subtitle :items="arrayLegenda"></subtitle>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
            <div class="col-sm-12 divHrDeBaixo"><hr></div>
        </div>
        <!-- Tabela de relatório -->
        <div class="table">
            <!-- Cabeçalho -->
            <div id="titleTable" class="nopadding row col-sm-12">
                <div class="celTitleTitle nameEscala nopadding col-sm-2">Escala</div>
                <div class="celDia celTitleTitle nopadding col-sm-1">Dia</div>
                <div class="conjuntoHours  nopadding row col-sm-9">
                    <div class="celTitleTitle celHour">00:00</div>
                    <div class="celTitleTitle celHour">01:00</div>
                    <div class="celTitleTitle celHour">02:00</div>
                    <div class="celTitleTitle celHour">03:00</div>
                    <div class="celTitleTitle celHour">04:00</div>
                    <div class="celTitleTitle celHour">05:00</div>
                    <div class="celTitleTitle celHour">06:00</div>
                    <div class="celTitleTitle celHour">07:00</div>
                    <div class="celTitleTitle celHour">08:00</div>
                    <div class="celTitleTitle celHour">09:00</div>
                    <div class="celTitleTitle celHour">10:00</div>
                    <div class="celTitleTitle celHour">11:00</div>
                    <div class="celTitleTitle celHour">12:00</div>
                    <div class="celTitleTitle celHour">13:00</div>
                    <div class="celTitleTitle celHour">14:00</div>
                    <div class="celTitleTitle celHour">15:00</div>
                    <div class="celTitleTitle celHour">16:00</div>
                    <div class="celTitleTitle celHour">17:00</div>
                    <div class="celTitleTitle celHour">18:00</div>
                    <div class="celTitleTitle celHour">19:00</div>
                    <div class="celTitleTitle celHour">20:00</div>
                    <div class="celTitleTitle celHour">21:00</div>
                    <div class="celTitleTitle celHour">22:00</div>
                    <div class="celTitleTitle celHour">23:00</div>
                </div>
            </div>
            <!-- Corpo -->
            <div
                v-for="(escala, e) in dadosRelatorio"
                :key="e">
                <div v-for="(dia, d) in escala"
                    :key="d+e"
                    class="col-sm-12 nopadding lineBody row">
                    <!-- Nome da escala -->
                    <div class="col-sm-2 celBodyBodyEscala">
                        {{e}}
                    </div>
                    <!-- Nome do dia -->
                    <div class="col-sm-1 celBodyBody">
                        {{d}}
                    </div>
                    <div class="conjuntoHours  nopadding row col-sm-9">
                        <!-- Para gerar as cédulas (tem que ter o desesho dos quadrados)-->
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <div class="celBodyBody celHourVazio celHour"></div>
                        <!-- Gera a barra de progresso -->
                        <div class="progress">
                            <div v-for="(tempo, t) in dia"
                                :key="t"
                                :class="'progress-bar justBarColor bar-'+tempo.tipoDeTempo"
                                role="progressbar" 
                                :style="'width: '+tempo.porcentagem+'%;'" 
                                :aria-valuenow="tempo.porcentagem" 
                                aria-valuemin="0" 
                                aria-valuemax="100"></div>
                        </div>
                    </div>

                </div>
            </div>
                <statusInformation v-if="tamanhoRelatorio == 0"
                    typeBar="div" 
                    :statusBar="statusbar">
                </statusInformation>
        </div>
    </panelEagle>
</template>

<script>
import {mdiFinance} from '@mdi/js'
import Vue               from 'vue'
import panelEagle        from '@/components/Atom/Panel/PanelEagle'
import {required}        from 'vuelidate/lib/validators'
import {EmpresasService} from '../../../Services/auth/Empresas.service'
import {HttpRequest}     from '../../../Services/auth/HttpRequest.Service'
import {FiltrosService}  from '../../../Services/filtros/filtros.Service'
import { mapGetters } from 'vuex'

export default {
	name:'AnaliseProdutividade',
	components:{
		panelEagle,
		slideUpAndDown            : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
		tituloPage                : require('@/components/Atom/Header/Titulo').default,
		basicButtonsRelatoriosEIG : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
		inputSimple               : require('@/components/Atom/Inputs/InputSimple').default,
		selectAll                 : require('@/components/Atom/Select/SelectAll').default,
		subtitle                  : require('@/components/Atom/Subtitle/Subtitle').default,
		statusInformation         : require('@/components/Atom/StatusInformation/StatusInformation').default,
	},

	validations:{
		empresasSelecionadas:{
			required
		},
		colaboradoresEscalasSelecionados:{
			required
		},
		diasSelecionados:{
			required
		},
		horaInicio:{
			required
		},
		horaFim:{
			required
		},
	},

	data(){
		return{
			mdiFinance:mdiFinance,
			horaInicio        : '',
			horaFim           : '',
			labelColabEscala  : [
				{indexDFH: 'C', description: 'Colaborador *'},
				{indexDFH: 'E', description: 'Escala *'}
			],
			optionsEmpresa    : new EmpresasService().Get(),
			optionsDiasSemana : [
				{
					'value'       : '7',
					'description' : 'Segunda a Sexta'
				},
				{
					'value'       : '0',
					'description' : 'Domingo'
				},
				{
					'value'       : '1',
					'description' : 'Segunda-Feira'
				},
				{
					'value'       : '2',
					'description' : 'Terça-Feira'
				},
				{
					'value'       : '3',
					'description' : 'Quarta-Feira'
				},
				{
					'value'       : '4',
					'description' : 'Quinta-Feira'
				},
				{
					'value'       : '5',
					'description' : 'Sexta-Feira'
				},
				{
					'value'       : '6',
					'description' : 'Sábado'
				}
			],
			optionsColabEscala               : [],
			diasSelecionados                 : [],
			arrayOptionsColabEsc             : [],
			optionsSelectedEmp               : [],
			colaboradoresEscalasSelecionados : [],
			optionRadioColabEsc              : '',
			selectColabEscalas               : [],
			arrayLegenda                     : [
				{'description': 'Horas normais' , 'spanColor': 'light-green' , 'colWidth': '3'},
				{'description': 'Intervalo'     , 'spanColor': 'blue'        , 'colWidth': '3'},
				{'description': 'Ociosidade'    , 'spanColor': 'yellow'      , 'colWidth': '3'},
				{'description': 'Hora extra'    , 'spanColor': 'red'         , 'colWidth': '3'},
			],
			statusbar            : 'info',
			htmlRelatorio        :'',
			dadosRelatorio       :[],
			tamanhoRelatorio     :0,
			loadingPanel         : false,
			carregando           : false,
			loadingExportar      : [false, false, false],
			empresasSelecionadas : [],
            selectedEmpresa      : [],
		}
	},

	methods:{
        ...mapGetters(['getMaster']),

		changeSelectEmpresas(empresas){
			this.empresasSelecionadas = empresas;
			this.$refs.CE.clearAll();
			this.dados_filtros();
		},

		createdSelectColabEscala(select){
			this.selectColabEscalas = select;
		},

		changeSelectColabEscala(value){
			this.colaboradoresEscalasSelecionados = value;
		},

		changeCheckColabEscala(value){
			this.optionRadioColabEsc = value;
			if(this.arrayOptionsColabEsc.length != 0){
				this.optionsColabEscala = this.arrayOptionsColabEsc[value];
			}
		},

		changeSelectDiasSemana(value){
			this.diasSelecionados = value;
		},

		changeHoraInicio(value){
			this.horaInicio = value;
		},

		changeHoraFim(value){
			this.horaFim = value;
		},

		async dados_filtros(){
			this.carregando = true
			var dados = [];
			dados = await new FiltrosService().dados_filtros(this.empresasSelecionadas, ['C', 'E'])
			this.arrayOptionsColabEsc = dados;
			this.changeCheckColabEscala(this.optionRadioColabEsc);
			this.carregando = false
		},

		async gerar(){
			this.loadingPanel = true;
			this.htmlRelatorio = '';
			this.dadosRelatorio = [];
			this.tamanhoRelatorio = 0;
			this.diasSelecionados = this.diasSelecionados == 'ST' ? 8 : this.diasSelecionados;
			var dados = await new HttpRequest().Post(
				'/coletivos/relatorios/analise/produtividade/gerar/relatorio', 
				{'pesquisaPor' : this.optionRadioColabEsc,
					'motGrupo'    : this.colaboradoresEscalasSelecionados, 
					'diaSemana'   : this.diasSelecionados, 
					'horaInicio'  : this.horaInicio, 
					'horaFim'     : this.horaFim});
			this.tamanhoRelatorio = dados.data.tamanho
			if(this.tamanhoRelatorio > 0){
				this.dadosRelatorio = dados.data.linhas
			}else{
				this.statusbar = 'error'
			}
			this.loadingPanel = false;
		},

		async exportar(type){
			var root =  process.env.VUE_APP_ROOT;
			if(this.tamanhoRelatorio > 0){
				switch(type){
					case 'pdf':
						this.loadingExportar = [true, false, false]
						break;
					case 'xls':
						this.loadingExportar = [false, true, false]
						break;
					case 'csv':
						this.loadingExportar = [false, false, true]
						break;
					default:
						break;
				}
				var data = await new HttpRequest().Post(
					'/coletivos/relatorios/analise/produtividade/exportar/relatorio', 
					{'arrayDados' : this.dadosRelatorio,
						'tipo':type});
				window.open(root+'/'+data.data.local);
				this.loadingExportar = [false, false, false]
			}
		},
	},

    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optionsEmpresa
        }
    }
}
</script>

<style lang="scss">
    #analiseProdutividade{
        .custom-control-inline{
            margin-right: 0.3rem !important;
        }

        #tBodyRelatorioCommun{
            font-size: 12px !important;
        }

        #titleTable{
            position: sticky;
            top: 0;
            z-index: 1;
        }

        .table{
            height: 800px;
            padding-top: 0px !important;
            background: #fff !important;
            margin-top: 10px !important;
            overflow: auto !important;
        }

        .celHour{
            width: 4.1663% !important;
            padding-left: 0px;
            text-align: center;
            padding-right: 0px;
        }

        .celTitleTitle{
            font-size: 12px !important;
            border: 1px solid #d5d5d5 !important;
            background: #e5e5e5;
            padding-right: 1px !important;
            padding-left: 1px !important;
        }

        .lineBody{
            font-size: 12px !important;
        }

        .celBodyBody{
            height: 35px;
            border: 1px solid #f3f0f0;
            padding-right: 1px !important;
            padding-left: 1px !important;
        }

        .celBodyBodyEscala{
            border: 1px solid #f3f0f0;
            padding-right: 1px !important;
            padding-left: 1px !important;
        }

        .celDia{
            width: 3.4% !important;
            text-align: center;
        }

        .conjuntoHours{
            width: 83.5% !important;
        }

        .celHourVazio{
            background: #fff !important;
        }

        .nameEscala{
            width: 13% !important;
        }

        .progress{
            width: 100%;
            height: 25px;
            margin-bottom: 0px;
            margin-top: 5px;
            position: absolute;
            box-shadow: none;
            // border: 1px solid #f3f0f0;
            border-radius: 0px !important;
            background: transparent !important;

            .justBarColor{
                // opacity: 0.8;
                box-shadow: none;

                &.bar-O{
                    background: #ffec02 !important;
                }

                &.bar-HN{
                    background: #84e006 !important;
                }

                &.bar-HE{
                    background: #E04B4A !important;
                }

                &.bar-I{
                    background: #2196f3 !important;
                }
                
                &.bar-W{
                    background: transparent !important;
                }
            }
        }
    }
    // .exportXls, .exportCsv{
    //     display: none;
    // }
</style>