/**
    Este é o componente de desenho livre no mapa leaflet e segue com o
	botões de controle do plugin
    -> Propriedades:
      
    -> Slot:
        Sem slot
    -> Eventos:

    ---measure  https://www.npmjs.com/package/leaflet-measure-path
    ---editable https://www.npmjs.com/package/leaflet-editable 
    https://leaflet.github.io/Leaflet.Editable/
    ---drag https://github.com/Leaflet/Path.Drag.js
       
 */
<template>
	<div>
		<div v-if="mostrarControles" class="divControlesFreeDraw">
			<div class="">
				<slot></slot>
			</div>
			<div class="">
				<simpleButton @click="paraDesenho" event="click" type="orange" :icon="mdiMinusCircle" width="100%"
					title="Parar" />
			</div>
			<div class="">
				<simpleButton @click="continuaDesenho" event="click" type="blue" :icon="mdiPencil" width="100%"
					title="Desenhar" />
			</div>
			<div class="">
				<simpleButton @click="deletarDesenho" event="click" type="red" :icon="mdiTrashCanOutline" width="100%"
					title="Limpar" />
			</div>
		</div>
	</div>
</template>
<script>
import Vue from 'vue'
import { mdiMinusCircle, mdiPencil, mdiTrashCanOutline, mdiCheckBold, } from '@mdi/js';
import L, { PolyUtil } from 'leaflet';
import FreeDraw, { CREATE, EDIT, DELETE, APPEND, NONE } from 'leaflet-freedraw';
import measure from 'leaflet-measure-path'
export default Vue.extend({
	name: 'freedrawMapa',
	components: {
		simpleButton: require('../Buttons/SimpleButton').default,
	},
	props: {
		regiao: {
			type: [Array, Object],
			default: () => {
				return [];
			},
		},
		mostrarControles: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			mdiCheckBold: mdiCheckBold,
			mdiTrashCanOutline: mdiTrashCanOutline,
			mdiPencil: mdiPencil,
			mdiMinusCircle: mdiMinusCircle,
			freeDraw: new FreeDraw({
				mode: NONE,
				simplifyFactor: 1,
				smoothFactor: 1,
				// maximumPolygons:1,
				leaveModeAfterCreate: true
			}),
			polygons: [],
		};
	},
	methods: {

		paraDesenho() {
			this.freeDraw.mode(NONE)
			this.mode = 'visualização'
			this.emiteDesenho('stop')
		},

		paraDesenhoSemEvento() {
			this.freeDraw.mode(NONE)
			this.mode = 'visualização'
		},

		continuaDesenho() {
			this.freeDraw.mode(CREATE | EDIT | APPEND | DELETE)
			this.mode = 'Edição'
			// this.emiteDesenho()
			// this.$emit('editando')
		},

		deletarDesenho() {
			this.freeDraw.mode(NONE)
			this.freeDraw.clear()
		},

		emiteDesenho(extraInfo = null) {
			this.$emit('desenho', this.getLatLng(), extraInfo)
		},

		getLatLng() {
			var polygons = this.freeDraw.all()
			var conjuntoCoord = []
			for (var a of polygons)
				conjuntoCoord.push(a._latlngs)
			return conjuntoCoord
		},

		/**
		 * @description Cria um poligono e envia ele para ser adicionado
		 * no mapa com a de mostrar as medidas (tanto de área
		 * quanto distância entre as arestas) ativada.
		 * @author Gui 🍺
		 */
		medeDesenho() {
			var coord = this.getLatLng()
			if (!coord.length) return false
			this.deletarDesenho()
			var polygon = L.polygon(coord[0][0], { showMeasurements: true })
			return polygon
		},
	},
	mounted() {
		L.extend(measure)
		this.$parent.mapObject.addLayer(this.freeDraw)
		this.freeDraw.on('markers', (event) => {
			this.$emit('desenho', event.latLngs, 'drawing')
		})
	},
	watch: {
		regiao(newValue) {
			var aux = []
			for (var i in newValue) {
				aux.push([newValue[i][0], newValue[i][1]])
			}
			var polygon = this.freeDraw.create(aux)
		},
	},
});
</script>
<style lang="scss" >
.divControlesFreeDraw {
	float: right;
	top: 25%;
	position: absolute !important;
	z-index: 1000 !important;
	right: 0;
	margin: 0px;
	padding: 0px;
	right: 1%;
}

.divSlotFreeDraw {
	float: left;
	position: absolute !important;
	z-index: 1000 !important;
	left: 0;
	margin: 0px;
	padding: 0px;
}

.leaflet-measure-path-measurement {
	position: absolute;
	font-size: 10px;
	color: black;
	text-shadow: -1px 0 0 white,
		-1px -1px 0 white,
		0 -1px 0 white,
		1px -1px 0 white,
		1px 0 0 white,
		1px 1px 0 white,
		0 1px 0 white,
		-1px 1px 0 white;
	white-space: nowrap;
	transform-origin: 0;
	pointer-events: none;
}

.leaflet-measure-path-measurement>div {
	position: relative;
	margin-top: -25%;
	left: -50%;
}

.map.mode-create {
	cursor: crosshair;
}

.leaflet-polygon {
	fill: #b4cd8a !important;
	stroke: #50622b !important;
	stroke-width: 2 !important;
	fill-opacity: .75 !important;
}
</style>