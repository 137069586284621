import { DateTime } from 'luxon'

export enum EventCodes {
    /** Velocidade */
    Speed = 1,
    /** Rotação do motor */               
    RPM = 2,
    /** Colisão */
    Collision = 3,
    /** Frenagem Brusca */
    HarshBraking = 4,
    /** Aceleração Brusca */
    HarshAcceleration = 5,
    /** Frenagem Brusca em curva */
    CurveHarshBraking = 6,
    /** Alta velocidade em curva */
    CurveHighSpeed = 7,
    /** Aceleração Brusca em curva */
    CurveHarshAcceleration = 8,
    /** Banguela */
    CoastingInNeutral = 9,
    /** Freio Motor */
    EngineBraking = 10,
    /** Freio */
    Brake = 11,
    /** Limpador de para-brisa */
    WindshieldWiper = 12,
    /** sensor pneumático */
    Pneumatics = 13,
    /** caracol */
    Snail = 14,
}

export const EVENT_LABELS = {
    [EventCodes.Speed]: 'Velocidade',
    [EventCodes.RPM]: 'RPM',
    [EventCodes.Collision]: 'Colisão',
    [EventCodes.HarshBraking]: 'Freada brusca',
    [EventCodes.HarshAcceleration]: 'Aceleração brusca',
    [EventCodes.CurveHarshBraking]: 'Freada brusca em curva',
    [EventCodes.CurveHighSpeed]: 'Alta velocidade em curva',
    [EventCodes.CurveHarshAcceleration]: 'Aceleração brusca em curva',
    [EventCodes.CoastingInNeutral]: 'Banguela',
    [EventCodes.EngineBraking]: 'Freio motor',
    [EventCodes.Brake]: 'Freio',
    [EventCodes.WindshieldWiper]: 'Limpador de para-brisa',
    [EventCodes.Pneumatics]: 'Pneumático',
    [EventCodes.Snail]: 'Caracol',

}

export interface TelemetryPayload {
    dataIni: string, 
    dataFim: string,   
    placa: string
}

export interface RawTelemetryEvent { 
    dataevento: string,
    latlog: string
}

export interface RawTelemetrySpeedEvent extends RawTelemetryEvent { 
    bvvelocidade: number 
}

export interface RawTelemetryRPMEvent extends RawTelemetryEvent { 
    telrpm: number 
}

export interface RawTelemetryData { 
    [eventIndex: string]: RawTelemetryEvent[] 
}

export interface TelemetryEvent {
    dateTime: DateTime,
    value?: number,
    latlog?: [number, number]
}

export type TelemetryData = Partial<Record<EventCodes, TelemetryEvent[]>>
