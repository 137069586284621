/**
    Este componente é um conjunto de input de radio

    -> Propriedades:
        1- options - Array/Object - Opções do radio - EX: [{value: 'A', description: 'Abacate'}]
        2- selected - String - Opção que será a primeira selecionada - EX: 'A'
        3- radioName - String - Nome do radio que vai para o parametro name - EX: 'inputRadio'

    -> Slot:
        Sem slot

    -> Eventos:
        1- changeInput - Emite quando o input é alterado - Parametros:
            1.1- optSelected - o value do radio selecionado
 */
<template>
    <div class="col-sm-12 subtitle subtitleInputRadio">
        <b-form-group>
            <b-form-radio-group v-model="optSelected" :name="radioName" @input="changeRadio">
                <b-form-radio v-for="(opt) in options"  v-bind:key="opt.value" v-model="optSelected" checked class="mb-06" :name="radioName" :value="opt.value">{{opt.description}}</b-form-radio>
            </b-form-radio-group>
        </b-form-group>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
    props:{
        options:{
            type: [Array, Object],
            required: true,
            default: function(){return[]}
        },
        selected:{
            type: String,
            required: false,
            default: '',
        },
        radioName:{
            type: String,
            required: false,
            default: 'radioDefault',
        }
    },
    data(){
        return{
            optSelected: this.selected,
        }
    },
    methods:{
        changeRadio(){
            this.$emit('changeInput', this.optSelected);
        }
    }
})
</script>

<style lang="scss">
    .subtitleInputRadio{
        padding-top: 5px;
    }
</style>