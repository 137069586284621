<template>
    <panel-eagle
	id="fechamento-checkpoint"
	:loading='loading'>
        <div class="col-12 row">
            <div class="col-3 nopadding">
				<titulo 
				:icon='iconTitulo'
				titulo='Fechamento'/>
            </div>
            <div class="col-9 nopadding">
				<basicButtonsRelatoriosEIG
				:loading='loadingExportar'
				:disabledDropdown='!showTable'
				:disabled='$v.$invalid'
				@gerarRelatorio='gerarRelatorio'
				@exportarRelatorio='exportarRelatorio'>
					<simpleButton 
					v-if='podeCadastrar'
					:disabled="!temFechamento"
					width='180px'
					text='Excluir Fechamento'
					:icon="iconExcluirFechamento"
					type='red'
					event='click'
					@click='abreModalExcluirFechamento'/>
					<simple-button
					v-if='podeCadastrar'
					:icon='iconConfirmFechamento'
					:disabled='disableConfirmaFechamento'
					width='180px'
					text='Confirmar Fechamento'
					event='click'
					@click='abreModalFechamento'/>
				</basicButtonsRelatoriosEIG>
            </div>
        </div>
		<slide-up-and-down>
			<div class="col-sm-12"><hr></div>
			<div class="col-12 nopadding row mb-2">
                <div class="col-4 nopaading">
                    <input-range
					:isObrigatorio='true'
					name='range-extrato'
					@changeInput='changeIntervalo'/>
                </div>
                <div class="col-4 nopadding">
                    <select-all
					nameForRadio='selCliente'
					:isMultiple='true'
					:hasSelectAll='true'
					:labels='labelSelectCliente'
					:optionsArray='optClientes'
					firstSelected='MR'
					:extraClass='{
						"border border-danger":$v.clienteSelecionado.$anyError
					}'
					@close='$v.clienteSelecionado.$touch()'
					@changeSelect='changeEmpresa'/>
                </div>
                <div class="col-4 nopadding">
                    <select-all
					nameForRadio='selTrabalhador'
					ref='selTrabalhador'
					:labels='labelSelectTrabalhador'
					:isMultiple='true'
					:disabled='desabilitaSeletorT'
					:hasSelectAll='true'
					:loading='loadingTrabalhador'
					:optionsArray='optSelectWorker'
					:extraClass='{
					"border border-danger":$v.trabalhadorSelecionado.$anyError
					}'
					firstSelected='MAD'
					@close='$v.trabalhadorSelecionado.$touch()'
					@changeSelect='changeTrabalhador'
					@changeCheck ='changeCTrabalhador'/>
                </div>
            </div>
        </slide-up-and-down>
        <div class="mt-2 col-12">
            <table-simples>
                <thead>
					<th>
						<div class="check-nome">
							<b-check
							v-model='fecharTodos'
							@change="mudaCheckFechar">
								Nome
							</b-check>
						</div>
					</th>
                    <th>Normais</th>
                    <th>Extra 1</th>
                    <th>Extra 100</th>
                    <th>Falta</th>
                    <th>H. totais</th>
                    <th>H. noturna</th>
                    <th>Saldo</th>
                    <th title='Última quitação do banco'>Ult. quitação do banco</th>
					<th title='Próxima quitação do banco'>Prox. quitação do banco</th>
                    <th>
                        <b-check
						v-model='quitarTodos'
						@change="mudaCheckQuitarBanco">
							Quitar banco
						</b-check>
                    </th>
                </thead>
                <tbody v-if='showTable'>
                    <tr v-for='(wr, index) in tableDataShow' :key='`${index}_table_data`'>
						<td v-bind="bindMtnome(wr)">
							<b-check @change='mudaCheckFechamentoUnitario(wr)' 
								v-model="wr.fechar" v-bind="bindMtnome(wr)">
								{{wr.mtnome}}
							</b-check>
						</td>
                        <td v-text="textoOuZero(wr.fphoranormal)"></td>
                        <td v-text="textoOuZero(wr.fpextra1)"></td>
                        <td v-text="textoOuZero(wr.fpextra100)"></td>
                        <td v-text="textoOuZero(wr.fphorasfalta)"></td>
                        <td v-text="textoOuZero(wr.fptempototal)"></td>
                        <td v-text="textoOuZero(wr.fptotalnoturnas)"></td>
                        <!-- <td>{{getSaldoHoras(wr)}}</td> -->
						<td v-text='textoOuZero(wr.saldo)'/>
                        <td v-text='ultimoQuitamento(wr, true)'/>
						<td v-text="proximoQuitamento(wr)"/>
                        <td>
                            <b-check @change='mudaQuitarUnitario(wr)' v-model='wr.quitar'>
                                Quitar
                            </b-check>
                        </td>
                    </tr>
                </tbody>
				<status-bar
				v-else
				:statusBar='statusBar'
				typeBar='tr'/>
            </table-simples>
        </div>
		<modal-deleta
		id='confirma-fechamento'
		title="Confirmar Fechamento"
		@confirmaExclusao='confirmaFechamento'/>
		<modal-eagle
		id='redireciona'
		@cancelButton='cancelaRedirecionamento'
		@confirmButton='confirmaRedirecionamento'>
			<template #modalBody>
				Redirecionar ao relatório de espelho ponto?
			</template>
			<template #modalFooter>
				<simple-button
				type='red'
				event='click'
				text='Cancelar'
				:icon='iconCancelar'
				@click='cancelaRedirecionamento'/>
				<simple-button
				type='blue'
				event='click'
				:icon='iconConfirmFechamento'
				text='Confirmar'
				@click='confirmaRedirecionamento'/>
			</template>
		</modal-eagle>
		<modalExcluirF
		ref="modalExcluirFechamento"
		id='modalExcluirFechamento'
		@recarregar-tabela='gerarRelatorio'/>
    </panel-eagle>
</template>

<script>
import Vue from 'vue'
import { DateTime } from 'luxon'
import { required } from 'vuelidate/lib/validators'
import { mdiTableCheck, mdiCheckBold, mdiCloseThick, mdiTrashCan } from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { mapGetters } from 'vuex'
import { conectionError } from '@/Services/Helpers/swellHeper'
const urlBase = '/checkpoint/nao/menu/fechamento/'
export default Vue.extend({
	name:'fechamento-checkpoint',
	components:{
		modalDeleta   : require('@/components/Atom/ModalSpecific/ModalDeleta.vue').default,
		modalEagle    : require('@/components/Atom/Modal/ModalEagle.vue').default,
		modalExcluirF : require('@/components/Atom/SpecificComponents/Fechamento/ExcluirFechamento.vue').default,
		slideUpAndDown: require('@/components/Atom/SlideUpAndDown/SlideUpAndDown.vue').default,
		panelEagle    : require('@/components/Atom/Panel/PanelEagle').default,
		basicButtonsRelatoriosEIG : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
		titulo        : require('@/components/Atom/Header/Titulo').default,
		selectAll     : require('@/components/Atom/Select/SelectAll').default,
		inputRange    : require('@/components/Atom/Datas/InputRangeWithLimit').default,
		tableSimples  : require('@/components/Atom/Table/TableSimples').default,
		statusBar     : require('@/components/Atom/StatusInformation/StatusInformation.vue').default,
		simpleButton  : require('@/components/Atom/Buttons/SimpleButton.vue').default,
	},
	data() {
		return {
			//icon
			iconTitulo: mdiTableCheck,
			iconConfirmFechamento: mdiCheckBold,
			iconCancelar: mdiCloseThick,
			iconExcluirFechamento: mdiTrashCan,
			statusBar: 'info',
			loading: false,
			loadingExportar: [false, false, false],
			mostraTabela:false,
			loadingTrabalhador:false,
			quitarTodos: false,
			fecharTodos: false,
			//controle
			tableData: [],
			fechamentos:[],
			ultFechamento:{},
			//opts
			optClientes: new EmpresasService().Get(),
			optTrabalhador:{
				'MAD':[],
				'D':[]
			},
			labelTrabalhador:'MAD',
			//labels
			labelSelectTrabalhador:[
				{indexDFH:'MAD', description:'Colaborador*'},
				{indexDFH:'D', description:'Departamento'}
			],
			labelSelectCliente:[
				{indexDFH:'cc', description:'Empresas*'}
			],
			//selecionados
			clienteSelecionado: [],
			trabalhadorSelecionado: [],
			intervalo:'',
		}
	},
	validations:{
		clienteSelecionado:{ required },
		trabalhadorSelecionado:{ required }
	},
	methods:{
		abreModalExcluirFechamento(){
			this.$refs.modalExcluirFechamento.listaFechamentos(this.ultFechamento)
			this.$bvModal.show('modalExcluirFechamento')
		},

		...mapGetters(['getPermicoes']),

		/**
		 * @listens change - checkbox de quitar banco de cada linha
		 * @param {object} wr
		 * @param {number} wr.mtcodigo 
		 */
		mudaQuitarUnitario(wr){
			var worker = _.find(this.tableData, {mtcodigo:wr.mtcodigo})
			worker.quitar = !worker.quitar
		},

		/**
		 * @listens change - checkbox de fechamento ao lado do nome
		 * @param {object} wr
		 * @param {number} wr.mtcodigo 
		 */
		mudaCheckFechamentoUnitario(wr){
			var worker = _.find(this.tableData, {mtcodigo:wr.mtcodigo})
			worker.fechar = !worker.fechar
		},

		/**
		 * @param {string} texto - a ser exibido.
		 * @return {string} texto ou 00:00:00
		 * @author Gui 🍺
		 */
		textoOuZero(texto){
			return texto ?? '00:00:00'
		},

		/**
		 * @param {object} obj
		 * @param {string} obj.fpultima  data no formato ISO
		 * @return {string} no formato dd/mm/yyyy
		 */
		formataData({fpultima}){
			var data = DateTime.fromISO(fpultima)
			return data.toFormat('dd/LL/yyyy')
		},

		/**
         * @listens changeCheck 
         * @param {'MAD'|'D'} label
         */
		changeCTrabalhador(label){
			this.tableData = []
			this.labelTrabalhador = label
		},

		/**
         * @listens changeSelect
         * @param {number[]} arr
         */
		changeTrabalhador(arr){
			this.tableData = []
			this.trabalhadorSelecionado = arr
		},
        
		/**
         * @listens changeSelect
         * @param {number[]} arr - clientes selecionados
         * @description muda o cliente selecionado e busca pelas
         * opts do seletor de funcionário
		 * @author Gui 🧟
         */
		changeEmpresa(arr){
			this.tableData = []
			this.clienteSelecionado = arr 
			this.trabalhadorSelecionado = []
			this.$refs.selTrabalhador.clearAll()
			this.$v.trabalhadorSelecionado.$reset()
			this.optTrabalhador = {
				'MAD':[],
				'A':[]
			}
			if(arr.length){
				this.loadingTrabalhador = true
				new FiltrosService()
					.dados_filtros(this.clienteSelecionado, ['D', 'MAD'])
					.then((res)=>{
						this.optTrabalhador = res
					}).finally(()=>{this.loadingTrabalhador = false})
			}
		},

		/**
		 * @listens changeInput
         * @param {string} intervalo formato dd/mm/yyyy - dd/mm/yyyy
         */
		changeIntervalo(intervalo){
			this.intervalo = intervalo
			this.tableData = []
		},
		
		/**
		 * @listens click - botão gerar
		 * @description gera o relatório de fechamento
		 * @author Gui 🍺
		 */
		gerarRelatorio(){
			let uri = `${urlBase}gerar`
			let obj = {
				workers : this.trabalhadorSelecionado,
				intervalo : this.intervalo,
				label	  : this.labelTrabalhador,
				cliente   : this.clienteSelecionado
			}
			this.tableData = []
			this.fecharTodos = false
			this.quitarTodos = false
			this.loading = true
			new HttpRequest().Post(uri, obj).then((res)=>{
				this.tableData = res.data.info
				this.ultFechamento = res.data.ultFechamento
				if(this.tableData.length === 0)
					this.statusBar = 'error'
			}).catch(()=>{
				conectionError()
			}).finally(()=>{this.loading = false})
		},
		
		/**
		 * @param {'xls'|'csv'|'pdf'} formato
		 * @description exporta o relatório p/ o formato
		 * desejado.
		 */
		exportarRelatorio(formato){
			let uri = `${urlBase}exportar/${formato}`
			let obj = {
				data:this.tableDataShow,
				periodo: this.intervalo
			}
			this.decideExportar(formato)
			new HttpRequest().Post(uri, obj)
				.then((res)=>{
					var root =  process.env.VUE_APP_ROOT;
					open(root+'/'+res.data.local)
				})
				.finally(()=>{this.decideExportar('none')})
		},

		/**
		 * @param {'pdf'|'xls'|'csv'|'none'} formato
		 */
		decideExportar(formato){
			this.loadingExportar = [
				formato === 'pdf',
				formato === 'xls',
				formato ==='csv'
			]
		},
		
		/**
		 * @param {string} SaldoTotal 
		 * @param {'-'|'-'} Situacao
		 * @return {string} situacao mais saldo
		 */
		getSaldoHoras({SaldoTotal, Situacao}){
			if(SaldoTotal)
				return `${Situacao}${SaldoTotal}`
			return ''
		},

		cancelaRedirecionamento(){
			this.$bvModal.hide('redireciona');
			this.gerarRelatorio();
		},
		abreModalFechamento(){
			this.$bvModal.show('modal-deleta-confirma-fechamento')
		},

		/**Realiza fechamento, no futuro */
		confirmaFechamento(){
			var url = `${urlBase}confirma/fechamento`
			var obj = {
				selecionados : this.funcionariosFechamento,
				periodo: this.intervalo
			}
			this.loading = true
			new HttpRequest().Post(url, obj)
				.then((res)=>{
					if(res.code === 201){
						this.$bvModal.show('redireciona')
					}
				}).finally(()=>{
					this.loading = false
				})
		},

		/**
		 * @listens confirmButton
		 */
		confirmaRedirecionamento(){
			this.$router.push({name:'espelhoPontoCheckpoint'})
		},

		bindMtnome(wr){
			if(wr?.errors){
				return {
					disabled:true,
					title:'Existe um ou mais erros com os dados desse colaborador'
				}
			}
			var nao_pode_fechar = !this.podeFechar(wr)
			if(nao_pode_fechar){
				return {
					disabled:true,
					title: 'Já possui fechamento neste período'
				}
			}
		},

		/**
		 * @description procura a data do último quitamento
		 * @param { object } wr
		 * @param { object[] } wr.fechamento
		 * @param { boolean } formatado - se o retorno deve voltar como string ou
		 * obj do tipo DateTime
		 * @return { string|DateTime} data do último quitamento do funcionário
		 * @author Gui 🧟
		 */
		ultimoQuitamento(wr, formatado=false){
			if(wr.fechamento.length){
				// let data_limite = this.intervalo.split(' - ')[1]
				// data_limite = data_limite.split('/').reverse().join('-')
				// let data = DateTime.fromISO(data_limite)
				// let quitados = wr.fechamento.filter((f)=>{
				// 	let data_fim = DateTime.fromISO(f.cffim)
				// 	let confere = wr.mtcodigo === f.cfdcolaborador
				// 	return f.cfdquitado && data_fim <= data && confere
				// })
				// quitados = quitados.sort((a,b)=>{
				// 	let a_data = DateTime.fromISO(a.cffim)
				// 	let b_data = DateTime.fromISO(b.cffim)
				// 	return a_data > b_data
				// })
				if(wr.fechamento && wr.fechamento.length){
					// let first = wr.fechamento.at(0)
					let ult_quitado = wr.fechamento.at(0).cffim
					ult_quitado = DateTime.fromISO(ult_quitado)
					if(formatado) ult_quitado = ult_quitado.toFormat('dd/LL/yyyy')
					return ult_quitado
				}
				return ''
			}
			return ''
		},

		/**
		 * @description verifica a data do próximo quitamento do funcionário.
		 * @param { object } wr dados do funcionário
		 * @param { number } wr.jtperiodobancohoras
		 * @param { string } wr.mtiniciocontrolebanco caso o funcionário não tenha o
		 * início de controle padrão, no formato yyyy-mm-dd
		 * @param { string } wr.controle inicio de controle padrão da jornada
		 * @return { string } data que deveria ser o próximo fechamento do funcionário.
		 * @author Gui 🍺
		 */
		proximoQuitamento(wr){
			var ultimo_fechamento = this.ultimoQuitamento(wr)
			if(ultimo_fechamento){
				var proximo_fechamento = ultimo_fechamento.plus({months: wr.jtperiodobancohoras})
				return proximo_fechamento.toFormat('dd/LL/yyyy')
			}else if(wr.mtiniciocontrolebanco){
				var inicio_motora = DateTime.fromSQL(wr.mtiniciocontrolebanco)
				proximo_fechamento = inicio_motora.plus({ months: wr.jtperiodobancohoras})
				var retorno = proximo_fechamento.toFormat('dd/LL/yyyy')
				return retorno
			}else if(wr.controle !== null){
				var inicio_controle = DateTime.fromISO(wr.controle)
				inicio_controle = inicio_controle.plus({months:wr.jtperiodobancohoras}).toFormat('dd/LL/yyyy')
				return inicio_controle
			}
			return ''
		},

		/**
		 * @param {object} wr 
		 * @param {}
		 */
		ultimoFechamento(wr){
			if(wr.fechamento.length){
				var ultimo = wr.fechamento.at(-1).cffim
				ultimo = DateTime.fromFormat(ultimo, 'yyyy-LL-dd')
				return ultimo
			}
			return ''
		},

		/**
		 * @param {object} wr
		 * @param {object[]} wr.periodo
		 * @param {string} wr.periodo[].cfinicio - inicio do periodo de fechamento
		 * @param {string} wr.periodo[].cffim    - final do periodo de fechamento
		 * @return {string[]} inicio, fim, no formato dd/mm/yyyy, com TODAS
		 * as datas de fechamento do carinha.
		 */
		periodoUltimoFechamento(wr){
			var arr_todas_datas = []
			var format = 'dd/LL/yyyy'
			if(wr.fechamento.length){
				var fechamentos = wr.fechamento
				fechamentos.forEach((f)=>{
					var ini = DateTime.fromISO(f.cfinicio).toFormat(format)
					var fim = DateTime.fromISO(f.cffim).toFormat(format)
					var dias = this.criaArrayDatas(ini, fim, format)
					arr_todas_datas =  arr_todas_datas.concat(dias)
				})
			}
			return arr_todas_datas
		},

		/**
		 * @description confirma se o um funcionário pode ou não
		 * ter um fechamento 😃
		 * @returns {boolean}
		 */
		podeFechar(wr){
			var formato = 'dd/LL/yyyy'
			var fechamentos = this.periodoUltimoFechamento(wr)
			var intervalo_relatorio = this.criaArrayDatas(
				...this.intervalo.split(' - '), 
				formato
			)
			var inter = _.intersection(intervalo_relatorio, fechamentos)
			if(inter.length)
				return false 
			return true
		},

		/**
		 * @param {string} ini inicio do período
		 * @param {string} final final do periodo
		 * @param {string} format formato da data
		 * @return {string[]}	todas as datas incluindo o inico e fim
		 * @author Gui 🧟
		 */
		criaArrayDatas(ini, final, format){
			var ini_data = DateTime.fromFormat(ini, format)
			var fim_data = DateTime.fromFormat(final, format)
			var retorno = [];
			var diff = fim_data.diff(ini_data, 'days').toObject().days
			for(let i = 0; i <= diff; i++){
				var novo_dia = ini_data.plus({days: i})
				retorno.push(novo_dia.toFormat(format))
			}
			return retorno
		},

		mudaCheckFechar(){
			this.tableData.forEach((e)=>{
				var pode_fechar = this.podeFechar(e)
				if(pode_fechar){
					e.fechar = this.fecharTodos
				}
				this.$forceUpdate()
			})
		},

		mudaCheckQuitarBanco(){
			this.tableData.forEach((e)=>{
				if(this.podeFechar(e)){
					e.quitar = this.quitarTodos
					this.$forceUpdate()
				}
			})
		}
	},
	computed:{
		disableConfirmaFechamento(){
			return !this.tableData.some((a)=>{
				return a.fechar
			})
		},
		temFechamento(){
			let tem_fechamento = this.tableData.some(b=>{
				return !!b.fechamento.length
			})
			return tem_fechamento
		},
		podeCadastrar(){
			return this.getPermicoes()['cadastrar']
		},
		showTable(){
			return !!this.tableData.length
		},
		/**Decide se o seletor de trabalhador deve estar habilitado*/
		desabilitaSeletorT(){
			if(this.optSelectWorker.length)
				return false
			return true
		},
		optSelectWorker(){
			return this.optTrabalhador[this.labelTrabalhador] ?? []
		},
		
		/**
		 * @description retorna os dados a serem exibidos 
		 * já ordenados
		 */
		tableDataShow(){
			var data = this.tableData.map((e)=>{
				return {
					...e,
					'mtnome': e.mtnome.split(/\s/).map((name)=>{
						return _.capitalize(name)
					}).join(' ')
				}
			})
			return _.sortBy(data, ['mtnome'], ['ASC'])
		},
		funcionariosFechamento(){
			return this.tableData.filter((w)=>{
				return w.fechar
			})
		}
	}
})
</script>

<style lang="scss" scoped>
#fechamento-checkpoint{
	.check-nome{
		padding-left: 7px;
	}
	.mtnome{
		width: 4000px;
	}
	.custom-control{
		z-index: 0;
	}
}
</style>