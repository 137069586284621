<template>
    <modalEagle title="Replicar Manutenção" id="modalCopiarManutencao" size="xl">
        <slot slot="modalBody">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-4 nopadding">
                    <!-- Select das empresas -->
                    <div class="mt-2">
                        <selectAll 
                            @changeSelect="changeEmpresaCopiarManutencao" 
                            :isMultiple="false" 
                            nameForRadio="empresaCopiarManutencao" 
                            :hasSelectAll="false"
                            :selected=empresaSelecionadaCopia
                            :labels="[{indexDFH: 'EM', description: 'Empresa*'}]" 
                            :optionsArray="optionsArrayEmpresas" 
                            firstSelected="EM"
                        />
                    </div>
                    <!-- Select do tipo de apuracao -->
                    <div class="mt-2">
                        <selectAll 
                            @changeSelect="changeTipoApuracaoCopiarManutencao" 
                            :isMultiple="false" 
                            nameForRadio="tipoApuracaoCopiarManutencao" 
                            :selected=apuracaoSelecionadaCopia 
                            :hasSelectAll="false" 
                            :labels="[{indexDFH: 'EM', description: 'Tipo de Apuração*'}]" 
                            :optionsArray="optionsArrayTipoApuracao" 
                            firstSelected="EM"
                        />
                    </div>
                    <!-- campo de texto intervalo -->
                    <div class="mt-2">
                        <inputSimple
                            id="intervaloCopiarManutencao" 
                            name="intervaloCopiarManutencao"  
                            :hasMask="false" 
                            label="Intervalo*"
                            :disabled=disableIntervalo 
                            :value=intervaloCopiarManutencaoValue
                            @changeInput="changeIntervaloCopiarManutencao" 
                        />
                    </div>
                    <!-- campo de texto programado -->
                    <div class="mt-2">
                        <inputSimple
                            id="programadoCopiarManutencao" 
                            name="programadoCopiarManutencao"  
                            :hasMask="false" 
                            label="Programado*"
                            :disabled=disableProgramado
                            :value=programadoCopiarManutencaoValue
                            @changeInput="changeProgramadoCopiarManutencao" 
                        />
                    </div>

                </div>

                <div class="col-sm-4 nopadding">
                    <!-- campo de texto descição -->
                    <div class="mt-2">
                        <inputSimple
                            id="descricaoCopiarManutencao" 
                            name="descricaoCopiarManutencao"  
                            :hasMask="false" 
                            label="Descrição*"
                            :value=descricaoCopiarManutencaoValue
                            @changeInput="changeDescricaoCopiarManutencao" 
                        />
                    </div>
                    <!-- Select do tipo manutencao -->
                    <div class="mt-2">
                        <selectAll 
                            @changeSelect="changeTipoManutencaoCopiarManutencao" 
                            :isMultiple="false" 
                            nameForRadio="tipoManutencaoCopiarManutencao" 
                            :hasSelectAll="false" 
                            :selected=tipoManutecaoSelecionada
                            :labels="[{indexDFH: 'EM', description: 'Tipo de Manutenção*'}]" 
                            :optionsArray="optionsArrayTipoManutencao" 
                            firstSelected="EM"
                        />
                    </div>
                    <!-- campo de texto atual -->
                    <div class="mt-2">
                        <inputSimple
                            id="atualCopiarManutencao" 
                            name="atualCopiarManutencao"  
                            :hasMask="false" 
                            label="Atual*"
                            :disabled=disableAtual
                            :value=atualCopiarManutencaoValue
                            @changeInput="changeAtualCopiarManutencao" 
                        />
                    </div>
                    <!-- campo de texto nun ordem de servico -->
                    <div class="mt-2">
                        <inputSimple
                            id="numOSCopiarManutencao" 
                            name="numOSCopiarManutencao"  
                            :hasMask="false" 
                            label="N° da Ordem de Serviço"
                            :value=numOSCopiarManutencaoValue
                            @changeInput="changeNumOSCopiarManutencao" 
                        />
                    </div>

                </div>
                <!-- select de veiculos -->
                <div class="col-sm-4 nopadding">
                    <div class="mt-2">
                        <selectAll 
                            @changeSelect="changeVeiculoCopiarManutencao" 
                            :isMultiple="false" 
                            :hasSelectAll="false"
                            nameForRadio="veiculoCopiarManutencao" 
                            :selected=veiculoCopiarManutencaoSelecionada
                            :labels="[{indexDFH: 'EM', description: 'Veiculo*'}]" 
                            :optionsArray="optionsArrayveiculo" 
                            firstSelected="EM"
                            :loading="loadingVeiculos"
                        />
                    </div>

                    <div class="mt-2">
                        <hr>
                    </div>
                    <!-- lista de checkbox com os servicos -->
                    <div class="col-12 mt-2 servicos">
                        Selecione os Serviços a serem replicados para a manutenção<br>
                        <div class="col-sm-12 nopadding row confirmaServicos mt-2 "
                            v-for="(dados, index) in servicosCopiarManutencao" :key="index">

                            <div class="col-sm-10">
                                {{ dados.mtsdescricao }}
                            </div>
                            
                            <div class="col-sm-2">
                                <b-form-checkbox                                
                                    v-model="dados.mcsrealizado"
                                    :id="'confirmaServicosReplicar-'+dados.mtscodigo"
                                    name="confirmaServicoscheck"
                                    @change="changeCheckboxTrabalho()"
                                />
                            </div>
                        </div>
                        
                    </div> 
                </div>

            </div>
        </slot>
        <slot slot="modalFooter">
            <div class="d-flex flex-row nopadding">
                <simpleButton
                    class ="p-2"
                    :icon="mdiCheckBold"
                    text="Salvar"
                    event="click"
                    type="blue"
                    :disabled="!validaSevico || $v.$invalid"
                    @click="replicarManutencaoSend"
                />
                <simpleButton
                    class ="p-2"
                    :icon="mdiCloseThick"
                    text="Cancelar"
                    event="click"
                    type="red"
                    @click="fechaModal"
                />
            </div>
        </slot>

    </modalEagle>
</template>

<script lang="js">
import Vue from 'vue'
import { mapGetters } from 'vuex'
import {mdiCheckBold,mdiCloseThick} from '@mdi/js'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { EmpresasService } from '../../../Services/auth/Empresas.service'
import { HttpRequest } from '../../../Services/auth/HttpRequest.Service'
import { conectionError } from '@/Services/Helpers/swellHeper'
import { DateTime } from 'luxon'


export default Vue.extend({

    name: 'modalReplicarManutencao',

    components:{
        'loadingButton': require('@/components/Atom/Buttons/LoadingButton').default,
        'simpleButton': require('@/components/Atom/Buttons/SimpleButton').default,
        'modalEagle': require('@/components/Atom/Modal/ModalEagle').default,
        'inputSimple'   : require('@/components/Atom/Inputs/InputSimple').default,
        'tableListagemModal': require('@/components/Atom/Table/TableListagemModal').default,
        'selectAll': require('@/components/Atom/Select/SelectAll').default,
    },
    validations:{
        descricaoCopiarManutencao       :{required},
        empresaCopiarManutencao         :{required},
        tipoApuracaoCopiarManutencao    :{required},
        tipoManutencaoCopiarManutencao  :{required},
        veiculoCopiarManutencao         :{required},
        programadoCopiarManutencao      :{required},

        // é required caso tipo da manutencao nao seja 2
        intervaloCopiarManutencao: {
            required: requiredIf(function () {
                if (this.tipoManutencaoCopiarManutencao == 2) {
                    return false
                }
                return true
			}),
		},

        // é required caso tipo da apuracao nao seja 3
		atualCopiarManutencao: {
            required: requiredIf(function () {
                if (this.tipoApuracaoCopiarManutencao == 3) {
                    return false
                }
                return true
			}),
		},

    },
    data(){
        return{
            link: '/manutencoes/nao/menus/controle/manutencao/',
            mdiCloseThick:mdiCloseThick,
            mdiCheckBold:mdiCheckBold,
            optionsArrayEmpresas : new EmpresasService().Get(),
            servicosSelecionados: {},
            empresaSelecionadaCopia : [],
            apuracaoSelecionadaCopia : [],
            tipoManutecaoSelecionada : [],
            veiculoCopiarManutencaoSelecionada : [],
            veiculoCopiarManutencao: [],
            intervaloCopiarManutencaoValue: '',
            empresaCopiarManutencao: [],
            tipoApuracaoCopiarManutencao: [],
            intervaloCopiarManutencao: '',
            programadoCopiarManutencaoValue: '',
            descricaoCopiarManutencaoValue: '',
            atualCopiarManutencaoValue: '',
            numOSCopiarManutencaoValue: '',
            descricaoCopiarManutencao: '',
            tipoManutencaoCopiarManutencao: [],
            atualCopiarManutencao: '',
            numOSCopiarManutencao: '',
            programadoCopiarManutencao: '',
            dadosReplicarManutencao: {},
            loading              : false,
            modalCopiarManutencao: false,
            empresa : [],
            servicosCopiarManutencao: [],
            optionsArrayveiculo: [],
            optionsArrayTipoApuracao: [],
            optionsArrayTipoManutencao: [],
            loadingVeiculos: false,
            disableIntervalo: false,
            disableProgramado: false,
            disableAtual: false,
            intervaloCopiarManutencaoValueBkp: '',
            programadoCopiarManutencaoValueBkp: '',
            validaSevico: false,
        }
    },

    methods:{
        ...mapGetters(['getCadastrar']),
        
        preparaModalReplicarManutencao(dados){
            this.replicarManutencao = dados;
            this.listaManutencaoReplicar();
        },

        /**
         * Função para manipular o select Empresa. 
         * @description caso seja alterado chama a funcao para buscar veiculos
         * @param valor array  
         * @author VeCo® 🔱
         */
        async changeEmpresaCopiarManutencao(selecionada){
            this.veiculoCopiarManutencaoSelecionada = [];
            this.loadingVeiculos = true;
            this.empresaCopiarManutencao[0] = selecionada[0];
            this.buscaVeiculos(selecionada[0])
        },       
        
        /**
         * Função para manipular o campo atual. 
         * @description caso seja alterado valida se manutencao 3 
         * executa a soma entre intervalo e atual no campo programado
         * @param valor array  
         * @author VeCo® 🔱
         */
        changeAtualCopiarManutencao(value){
            this.atualCopiarManutencao = value;
            if(this.tipoApuracaoCopiarManutencao != 3){
                this.disableAtual = false
                this.intervaloCopiarManutencaoValue = this.intervaloCopiarManutencaoValue == '' ? this.intervaloCopiarManutencaoValueBkp : this.intervaloCopiarManutencaoValue
                this.programadoCopiarManutencaoValue = this.programadoCopiarManutencaoValue == '' ? this.programadoCopiarManutencaoValueBkp : this.programadoCopiarManutencaoValue
                this.programadoCopiarManutencaoValue = Number(this.intervaloCopiarManutencao) + Number(this.atualCopiarManutencao);
            }
        },

        changeProgramadoCopiarManutencao(value){
            this.programadoCopiarManutencao = value;
        },     

        changeDescricaoCopiarManutencao(value){
            this.descricaoCopiarManutencao = value;
        },


        /**
         * Função para manipular o select Tipo de Manutenção.
         * @description executa a validação caso for tipo 2 disable nos campos copia o valor do campo
         * caso o usuario tenha digitado 
         * @param recebe valor array 
         * @author VeCo® 🔱
         */
         changeTipoManutencaoCopiarManutencao(value){
            this.tipoManutencaoCopiarManutencao = value[0];

            if(this.tipoManutencaoCopiarManutencao == 2){
                this.disableIntervalo = true
                this.disableProgramado = true 
                this.intervaloCopiarManutencaoValueBkp  = this.intervaloCopiarManutencaoValue 
                this.programadoCopiarManutencaoValueBkp = this.programadoCopiarManutencaoValue
                this.intervaloCopiarManutencaoValue = ''
                this.programadoCopiarManutencaoValue = ''
            }else{
                this.disableIntervalo = false
                this.disableProgramado = false 
                this.intervaloCopiarManutencaoValue = this.intervaloCopiarManutencaoValue == '' ? this.intervaloCopiarManutencaoValueBkp : this.intervaloCopiarManutencaoValue
                this.programadoCopiarManutencaoValue = this.programadoCopiarManutencaoValue == '' ? this.programadoCopiarManutencaoValueBkp : this.programadoCopiarManutencaoValue
            }
        },

        changeNumOSCopiarManutencao(value){
            this.numOSCopiarManutencao = value;
        },

        changeVeiculoCopiarManutencao(value){
            this.veiculoCopiarManutencao = value;
        },

        /**
         * Função para manipular o select Tipo de Apuração.
         * @description executa a validação caso for tipo 3 disable no campo copia o valor do campo e calcula data
         * se for caso 2 acessa o valor do vchorimetro caso 1 acessa vchodometro e soma com intervalo
         * @param recebe valor array 
         * @author VeCo® 🔱
         */
         changeTipoApuracaoCopiarManutencao(selecionada){

            this.tipoApuracaoCopiarManutencao = selecionada[0];

            if(this.tipoApuracaoCopiarManutencao == 1){
                this.atualCopiarManutencaoValue = this.dadosReplicarManutencao[0].vchodometro / 1000
            }else if(this.tipoApuracaoCopiarManutencao == 2){
                this.atualCopiarManutencaoValue = this.dadosReplicarManutencao[0].vchorimetro
            }else if(this.tipoApuracaoCopiarManutencao == 3){
                this.disableAtual = true
                this.atualCopiarManutencaoValue = this.atualCopiarManutencaoValue == '' ? this.atualCopiarManutencaoValueBkp : this.atualCopiarManutencaoValue
                this.atualCopiarManutencaoValueBkp  = this.atualCopiarManutencaoValue
                this.atualCopiarManutencaoValue = '';
                


                this.programadoCopiarManutencaoValue = this.calcularDataProgramada(this.intervaloCopiarManutencao || 0);

                if(this.tipoManutencaoCopiarManutencao == 2){
                    this.intervaloCopiarManutencaoValue = ''
                    this.programadoCopiarManutencaoValue = this.calcularDataProgramada(this.tipoApuracaoCopiarManutencao);
                }

            } else {
                this.disableAtual = false
                this.intervaloCopiarManutencaoValue = this.intervaloCopiarManutencaoValue == '' ? this.intervaloCopiarManutencaoValueBkp : this.intervaloCopiarManutencaoValue
                this.programadoCopiarManutencaoValue = this.programadoCopiarManutencaoValue == '' ? this.programadoCopiarManutencaoValueBkp : this.programadoCopiarManutencaoValue
                this.programadoCopiarManutencaoValue = Number(this.intervaloCopiarManutencao) + Number(this.atualCopiarManutencao);
            }
        },

        /**
         * Função para manipular o campo Intervalo.
         * @description executa a validação caso Manutencao for tipo 3 disable no campo copia o valor do campo e calcula data
         * se for caso 2 ou 1 acessa vchodometro/vchorimetro e soma com intervalo
         * @param recebe valor array 
         * @author VeCo® 🔱
         */
        changeIntervaloCopiarManutencao(value){
            this.intervaloCopiarManutencao = value; 

            if(this.tipoApuracaoCopiarManutencao == 3){
                this.disableAtual = true
                this.atualCopiarManutencaoValueBkp  = this.atualCopiarManutencaoValue 
                this.atualCopiarManutencaoValue = ''
                this.programadoCopiarManutencaoValue = this.calcularDataProgramada(this.intervaloCopiarManutencao || 0);
            }else{
                this.disableAtual = false
                this.programadoCopiarManutencaoValue = this.programadoCopiarManutencaoValueBkp
                this.programadoCopiarManutencaoValue = Number(this.intervaloCopiarManutencao) + Number(this.atualCopiarManutencao);
            }
        },

        changeCheckboxTrabalho(){
            if (this.servicosCopiarManutencao.some(servico => servico.mcsrealizado)) {
                this.validaSevico = true;
            } else {
                this.validaSevico = false;
            }
        },
                
        /**
         * Função para manipular a soma data para o campo programado.
         * @description retorna a soma da data atual + o parametro da funcao usando Luxon.
         * @param valor int 
         * @returns retorna a data formatada dd/mm/yyyy 
         * @author VeCo® 🔱
         */
        calcularDataProgramada(intervaloDias) {
            const dataProgramada = DateTime.now().plus({ days: intervaloDias });
            const dataFormatada = dataProgramada.toFormat('dd/MM/yyyy');
            return dataFormatada;
        },

        /** 
		 * Método para buscar os veículos do cliente
		 * @param {array} empresa = código da empresa
		 * @author Vitor Hugo 🐨
		 */
         buscaVeiculos(empresa){
            this.loadingVeiculos = true               
            new FiltrosService().dados_filtros([empresa], ['V'])
                .then((dados)=>{
                    this.optionsArrayveiculo = dados.V
                })
            this.loadingVeiculos = false
		},

        /**
         * Função para ajustar os valores dos campos do modal.
         * @description acessa o objeto do request e monta os dados nos campos de texto/select/checkbox
         * @author VeCo® 🔱
         */
        ajustaDadosModal(){

            this.empresaSelecionadaCopia = [{
                'value': this.dadosReplicarManutencao[0].mcempresa, 
                'description' : this.dadosReplicarManutencao[0].clnome
            }]

            this.empresaCopiarManutencao[0] = this.dadosReplicarManutencao[0].mcempresa

            this.apuracaoSelecionadaCopia = [{
                'value': this.dadosReplicarManutencao[0].mtacodigo,
                'description' : this.dadosReplicarManutencao[0].mtadescricao, 
            }]

            this.tipoApuracaoCopiarManutencao = this.dadosReplicarManutencao[0].mtacodigo 

            this.tipoManutecaoSelecionada = [{
                'value': this.dadosReplicarManutencao[0].mticodigo, 
                'description' : this.dadosReplicarManutencao[0].mtidescricao
            }]

            this.tipoManutencaoCopiarManutencao = this.dadosReplicarManutencao[0].mticodigo

            this.veiculoCopiarManutencaoSelecionada= [{
                'value': this.dadosReplicarManutencao[0].mcveiculo, 
                'description' : this.dadosReplicarManutencao[0].veplaca+' | '+this.dadosReplicarManutencao[0].veprefixo 
            }]

            this.veiculoCopiarManutencao[0] = this.dadosReplicarManutencao[0].mcveiculo;
            this.optionsArrayTipoApuracao = this.dadosReplicarManutencao[0].apuracaoTipo;
            this.optionsArrayTipoManutencao = this.dadosReplicarManutencao[0].manutencaoTipo;
            this.intervaloCopiarManutencaoValue = this.dadosReplicarManutencao[0].mcapuracao == 1 ? parseInt(this.dadosReplicarManutencao[0].mcintervalo / 1000) : this.dadosReplicarManutencao[0].mcintervalo;
            this.programadoCopiarManutencaoValue = this.dadosReplicarManutencao[0].mcprogramado;
            this.descricaoCopiarManutencaoValue = this.dadosReplicarManutencao[0].mcdescricao +'_CÓPIA';

            this.numOSCopiarManutencaoValue = this.dadosReplicarManutencao[0].mcordemservico;
            this.buscaVeiculos(this.dadosReplicarManutencao[0].mcempresa);
            this.servicosCopiarManutencao = this.dadosReplicarManutencao[0].servicos;

            this.atualCopiarManutencaoValue = 
            this.dadosReplicarManutencao[0].mcapuracao == 1 ? parseInt(this.dadosReplicarManutencao[0].vchodometro / 1000) :
            this.dadosReplicarManutencao[0].mcapuracao == 2 ? this.dadosReplicarManutencao[0].vchorimetro : '';

            if(this.dadosReplicarManutencao[0].mctipomanutencao == 2){
                this.disableIntervalo = true;
                this.disableProgramado = true;
            }else{
                this.disableIntervalo = false;
                this.disableProgramado = false;
            }
            if(this.dadosReplicarManutencao[0].mtacodigo == 3){
                this.disableAtual = true;
                this.atualCopiarManutencaoValueBkp  = this.atualCopiarManutencaoValue;
                this.atualCopiarManutencaoValue = '';
                this.programadoCopiarManutencaoValue = this.calcularDataProgramada(this.intervaloCopiarManutencaoValue);
            }else{
                this.disableAtual = false;
            }
            this.changeCheckboxTrabalho();
            this.$bvModal.show('modalCopiarManutencao');
        },

        /**
         * Função para buscar as manutençoes com base no codigo da manutencao 
         * @description busca os dados da manutencao usando  this.replicarManutencao 
         * @param recebe valor int
         * @returns objeto com os dados da manutencao
         * @author VeCo® 🔱
         */
         listaManutencaoReplicar(){
            this.loadingModal = true
            new HttpRequest().Post(this.link+'busca/replicar/manutencao',{'codigoReplicarManutencao' : this.replicarManutencao})
                .then((dados)=>{
                    this.loadingModal = false
                    this.dadosReplicarManutencao = dados.data.dados

                    if (Array.isArray(this.dadosReplicarManutencao) && this.dadosReplicarManutencao.length > 0) {
                        this.ajustaDadosModal()
                    } else {
                        conectionError('Manutenção sem serviços!<br>\
                            Não é possível replicar essa manutenção, \
                            pois, não há serviços vinculados.', 
                        "warning", 
                        'Atenção!')
                    }
                })
        },

        /**
         * Função para salvar os dados do modal
         * @description recebe os dados do modal alterados ou nao 
         * cria o objeto e salva no banco
         * @returns status do response
         * @author VeCo® 🔱
         */
        replicarManutencaoSend(){
            this.loadingModal = true

            var obj = {
                'empresa'           : this.empresaCopiarManutencao[0],
                'mcapuracao'        : this.tipoApuracaoCopiarManutencao,
                'mcintervalo'       : this.tipoApuracaoCopiarManutencao == 1 ? parseInt(this.intervaloCopiarManutencao * 1000) : this.intervaloCopiarManutencao,
                'mcprogramado'      : this.tipoApuracaoCopiarManutencao == 1 ? parseInt(this.programadoCopiarManutencao * 1000) : this.programadoCopiarManutencao,
                'mcdescricao'       : this.descricaoCopiarManutencao,
                'mctipomanutencao'  : this.tipoManutencaoCopiarManutencao,
                'mcordemservico'    : this.numOSCopiarManutencao,
                'mcveiculo'         : this.veiculoCopiarManutencao[0],
                'servicos'          : this.servicosCopiarManutencao,
            }

            new HttpRequest().Post(this.link+'salva/replicar/manutencao', { 'obj': obj })
                .then((dados)=>{

                    if(dados.code === 200){
                        if(!dados.data.erro){
                            this.$bvModal.hide('modalCopiarManutencao')
                            this.$emit('atualizaKanban')
                        }
                    }else if(dados.code === 500){
                        conectionError('','error','Ocorreu um erro ao replicar a manutenção');
                    }
                this.loadingModal = false
            })
        },

        fechaModal(){
            this.$bvModal.hide  ('modalCopiarManutencao')
        },
    },

})
</script>

<style lang="scss">
    #modalCopiarManutencao{

       .confirmaServicos{
            font-family: nexabook, sans-serif !important;
            background: #f5f5f5f5 !important;
            padding: 2px !important;
            line-height: 23px;
        }

    }
</style>
