<template>
	<panelEagle id="quilometrosPercorridos" :loading="panelEagle">
		<div class="col-sm-12 row nopadding">
			<div id="titulo_botoes" class="col-sm-12 nopadding row">
				<div class="col-sm-6">
					<titulo
						titulo="Quilômetros percorridos"
						:icon="mdiCarTractionControl"
					/>
				</div>
				<div class="col-sm-6 divDontPrint">
					<botoesEIG
						:loading="loadingExportar"
						:disabled="$v.veiculosSelecionados.$invalid"
						@gerarRelatorio="gerarRelatorio"
						@exportarRelatorio="exportarRelatorio"
						:disabledDropdown="$v.relatorio.$invalid"
					/>
				</div>
			</div>
			<slideUpAndDown>
				<template #filters>
					<div class="col-sm-12"><hr /></div>
					<div class="col-sm-12 row nopadding">
						<div class="col-sm-3 nopadding">
							<!-- Input para seleconar o mês -->
							<inputMonth
								label="Mês*"
								name="Mes"
								@changeInput="changeMes"
							/>
						</div>
						<div class="col-sm-3 nopadding" id="seletorEmpresa">
							<!-- Select de empresas -->
							<selectAll
								nameForRadio="seletorEmpresas"
								:hasSelectAll="true"
								:labels="[{ description: 'Empresas*' }]"
								:isMultiple="true"
								:optionsArray="optSelectEmpresa"
								:selected="selectedEmpresa"
								@changeSelect="changeEmpresa"
							/>
						</div>
						<div class="col-sm-3 nopadding">
							<!-- Select de veículos ou grupo de veículos -->
							<selectAll
								nameForRadio="seletorVeiculosGrupos"
								firstSelected="V"
								v-bind="{
									hasSelectAll: true,
									isMultiple: true,
									optionsArray: optSelectVeiculos,
									labels: labelSeletorVeiculoGrupos,
									ref: 'selectVG',
								}"
								v-on="{ changeCheck: mudaCheck }"
								@changeSelect="changeVeiculo"
								:disabled="$v.empresasSelecionadas.$invalid"
								:loading="carregando"
							/>
						</div>
						<div class="col-sm-2 ml-auto">
							<div class="legenda p-2">
								<div class="col-sm-12 row p-0">
									<div class="col-sm-10 p-0">
										<b>Legenda</b>
									</div>
									<div class="col-sm-2 p-0">
										<basicIcon 
											id="legendaHodometro"
											size='18' 
											:icon="mdiHelpCircle"
										/>
									</div>
								</div>
								<span class="col-sm-12 row pt-1 pl-1">
									<div class="circuloRoxo mr-2"></div>
									<div>Hodômetro GPS</div>
								</span>
								<span
									class="col-sm-12 row pt-1 pl-1"
									title="Dias trabalhados fora de jornada"
								>
									<div class="circuloVerde mr-2"></div>
									<div>Hodômetro CAN</div>
								</span>
							</div>
						</div>
					</div>
				</template>
			</slideUpAndDown>
			<div class="col-sm-12"><hr /></div>
			<div class="col-sm-12 row" v-if="tamanhoRel">
				<statusInformation
					class="col-sm-12"
					typeBar="div"
					:statusBar="statusbar"
					colspanForTd="12"
				>
				</statusInformation>
			</div>
			<div class="col-sm-12 row nopadding" v-else>
				<div
					v-for="(dadosVeiculo, k) in relatorio"
					:key="k"
					class="col-md-12 col-lg-6 row nopadding"
				>
					<!-- tabela em formato de calendário -->
					<!-- Início -->
					<template>
						<div class="col-sm-12 text-center section">
							<!-- Cabeçalho do calendário, onde fica a placa e total de km -->
							<div class="cabecalho col-sm-12 row">
								<span class="col-sm-4">
									{{ dadosVeiculo[0].placa }} |
									{{ dadosVeiculo[0].prefixo }}
								</span>
								<span class="col-sm-8 kmTotal">
									Totais : GPS({{ calculaTotal(dadosVeiculo) }})/CAN({{
										calculaTotalCAN(dadosVeiculo)
									}})
								</span>
							</div>
							<!-- Início do componente calendário 
                Masks coloca o formato dos dias da semana 
                From-page seleciona o mês do calendário (sempre utilizar Number antes da variável month
                se não o mês de Janeiro não vai funcionar)
                Attributes onde passo os dados que vão ser gerados dentro dos dias -->
							<vc-calendar
								:from-page="{ month: Number(mes[1]), year: mes[2] }"
								class="custom-calendar max-w-full"
								:masks="masks"
								:attributes="gerarAtributos(dadosVeiculo)"
								is-expanded
							>
								<!-- Começo das datas 
                Span coloca os dias referentes ao mês 
                Primeiro <p> é onde fica o km do veículo -->
								<template v-slot:day-content="{ day, attributes }">
									<div class="fundoWhite">
										<span>{{ day.day }}</span>
										<div
											v-for="attr in attributes"
											:key="attr.key"
											class="fraseDia tamKm"
										>
											<div class="kmEstimado divider">
												{{
													attr.customData.km
														? attr.customData.km + 'Km'
														: "-"
												}}
											</div>
											<div class="kmCAN">
												{{
													attr.customData.kmcan
														? attr.customData.kmcan + 'Km'
														: "-"
												}}
											</div>
										</div>
									</div>
								</template>
							</vc-calendar>
						</div>
					</template>
					<!-- Fim -->
				</div>
			</div>
		</div>
		<b-popover
			target="legendaHodometro"
			placement="bottom"
			triggers="hover click">
			<template>
				<div>
					A leitura do hodômetro a partir do rastreador é baseada na sua
					geolocalização (gps), podendo assim variar em determinados
					momentos.
				</div>
				<br>
				<div>
					A leitura do hodômetro a partir da rede can é precisa por levar em
					conta o valor real do hodômetro no veículo, porém, não é sempre que
					conseguimos captar essa leitura.
				</div>
			</template>
		</b-popover>
	</panelEagle>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import panelEagle from "@/components/Atom/Panel/PanelEagle";
import { EmpresasService } from "@/Services/auth/Empresas.service";
import { FiltrosService } from "@/Services/filtros/filtros.Service";
import { HttpRequest } from "@/Services/auth/HttpRequest.Service";
import { conectionError } from "@/Services/Helpers/swellHeper";
import { mapGetters } from "vuex";
import { mdiCarTractionControl, mdiHelpCircle } from '@mdi/js'

export default {
	name: "kmsPercorridos",
	components: {
		panelEagle,
		titulo: require("@/components/Atom/Header/Titulo").default,
		botoesEIG: require("@/components/Atom/Buttons/BasicButtonsRelatoriosEIG").default,
		selectAll: require("@/components/Atom/Select/SelectAll").default,
		slideUpAndDown: require("@/components/Atom/SlideUpAndDown/SlideUpAndDown")
			.default,
		inputMonth: require("@/components/Atom/Datas/InputMonth").default,
		statusInformation:
			require("@/components/Atom/StatusInformation/StatusInformation").default,
		basicIcon:require('@/components/Atom/Icon/BaseIcon.vue').default,
	},
	validations: {
		empresasSelecionadas: {
			required,
		},
		veiculosSelecionados: {
			required,
		},
		mesAnoSelecionado: {
			required,
		},
		relatorio: {
			required,
		},
	},
	data() {
		return {
			url: "/telemetria/relatorios/kms/percorrido/gerarRelatorio",
			mdiCarTractionControl: mdiCarTractionControl,
			mdiHelpCircle: mdiHelpCircle,
			panelEagle: false,
			optSelectEmpresa: new EmpresasService().Get(),
			optSelectVeiculos: [],
			empresasSelecionadas: [],
			veiculosSelecionados: [],
			mesAnoSelecionado: [],
			carregando: false,
			dataGEV: [],
			status: "info",
			loadingExportar: [false, false, false],
			data_inicio: [],
			data_fim: [],
			tipo: "V",
			relatorio: [],
			mes: [],
			customDatas: [],
			statusbar: "info",
			labelSeletorVeiculoGrupos: [
				{ indexDFH: "V", description: "Veiculos*" },
				{ indexDFH: "GV", description: "Grupos*" },
				{ indexDFH: "TAG", description: "Tags*" },
			],
			masks: {
				weekdays: "WWW",
			},
			selectedEmpresa: [],
		};
	},
	methods: {
		...mapGetters(["getMaster"]),
		// Início dos metódos para pegar informações dos selects/input
		changeEmpresa(empresas) {
			this.limpaRelatorio();
			this.empresasSelecionadas = empresas;
			this.procuraVeiculosEGrupos(empresas);
		},
		changeVeiculo(veiculos) {
			this.limpaRelatorio();
			this.veiculosSelecionados = veiculos;
		},
		changeMes(mesAno) {
			this.limpaRelatorio();
			this.mesAnoSelecionado = mesAno;
		},
		// Fim dos métodos
		// Método para calcular o total de Km rodado pelo veículo
		limpaRelatorio() {
			this.relatorio = [];
			this.statusbar = "info";
		},
		calculaTotal(dadosVeiculos) {
			var somaTotal = 0;
			dadosVeiculos.map((element) => {
				somaTotal = Number(somaTotal) + Number(element.kmsTotal);
			});
			if (somaTotal == 0) return "-";
			return somaTotal + "Km";
		},
		calculaTotalCAN(dadosVeiculos) {
			var somaTotal = 0;
			dadosVeiculos.map((element) => {
				somaTotal = Number(somaTotal) + Number(element.kmsCanTotal);
			});
			if (somaTotal == 0) return "-";
			return somaTotal + "Km";
		},
		// Método para transformar o objeto da forma que o calendário aceita
		gerarAtributos(calendario) {
			var retorno = calendario.map((element) => {
				var formatData = element.data.split("/");
				var dia = formatData[0];
				var mes = formatData[1];
				var ano = formatData[2];
				var attributes = {
					key: element.kmsTotal + element.data,
					customData: {
						km: element.kmsTotal,
						kmcan: element.kmsCanTotal,
					},
					// usando mes-1 pois a data começa em 0 e a que estou passando em 01
					dates: new Date(ano, mes - 1, dia),
				};
				return attributes;
			});
			return retorno;
		},

		async gerarRelatorio() {
			this.panelEagle = true;
			this.data_inicio = moment(this.mesAnoSelecionado._d)
				.set("date", 1)
				.format("DD-MM-YYYY");
			this.data_fim = moment(this.mesAnoSelecionado._d)
				.endOf("month")
				.format("DD-MM-YYYY");
			this.mes = this.data_inicio.split("-");
			let obj = {
				clientes: this.empresasSelecionadas,
				tipo: this.tipo,
				veiculos: this.veiculosSelecionados,
				data_inicio: this.data_inicio,
				data_fim: this.data_fim,
			};

			new HttpRequest()
				.Post(this.url, obj)
				.then((dados) => {
					if (Object.values(dados.data.relatorio).length > 0) {
						this.relatorio = dados.data.relatorio;
					} else {
						this.statusbar = "error";
					}
				})
				.catch(() => {
					conectionError();
				})
				.finally(() => (this.panelEagle = false));
		},

		async exportarRelatorio(type) {
			this.setLoading(type);
			var arrayDados = this.relatorio;
			var root = process.env.VUE_APP_ROOT;
			var dados = await new HttpRequest().Post(
				"/telemetria/relatorios/kms/percorrido/exportarRelatorio",
				{
					arrayDados,
					tipo: type,
					data_inicio: this.data_inicio,
					data_fim: this.data_fim,
					titulo: 8,
				}
			);
			this.setLoading();
			if (dados.status) {
				window.open(root + "/" + dados.data.dados);
			}
		},
		// Método para trazer do helper os veículos e grupos de veículos
		procuraVeiculosEGrupos(arr) {
			this.carregando = true;
			if (arr.length > 0) {
				new FiltrosService()
					.dados_filtros(arr, ["V", "GV", "TAG"])
					.then((data) => {
						this.dataGEV = data;
						this.optSelectVeiculos =
							this.dataGEV[this.$refs.selectVG.labelSelected];
						this.carregando = false;
					});
			} else {
				this.carregando = false;
				this.$refs.selectVG.clearAll();
			}
		},
		mudaCheck(value) {
			this.tipo = value;
			// this.relatorio.busca = value
			if (this.dataGEV[value] !== undefined) {
				this.optSelectVeiculos = this.dataGEV[value];
			}
		},
		setLoading(tipo = null) {
			let arr = [false, false, false];
			if (tipo == "pdf") {
				arr[0] = true;
			} else if (tipo == "xls") {
				arr[1] = true;
			} else if (tipo == "csv") {
				arr[2] = true;
			}
			this.loadingExportar = arr;
		},
	},
	computed: {
		tamanhoRel() {
			return !Object.values(this.relatorio).length;
		},
	},

	mounted() {
		if (!this.getMaster()) {
			this.selectedEmpresa = this.optSelectEmpresa;
		}
	},
};
</script>

<style lang="scss" scoped>
#quilometrosPercorridos{

	.cabecalho span {
		font-size: 16px !important;
		margin: 0px;
		padding-top: 8px;
		padding-bottom: 8px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis !important;
	}
	.cabecalho {
		color: white;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		min-width: 60px;
		padding: 0px;
		height: 40px;
		max-height: 40px;
		background: #428bca;
		overflow: hidden;
		text-overflow: ellipsis !important;
	}
	.section {
		max-width: 100%;
		padding: 15px;
		span.kmTotal {
			max-height: 40px;
			font-size: 18px;
			padding-left: 0px;
			padding-right: 0px;
			background: #0059b3;
			border-top-right-radius: 5px;
			max-width: 67%;
			overflow: hidden;
			text-overflow: ellipsis !important;
		}
	}
	/deep/ .custom-calendar.vc-container {
		--day-width: 12%;
		--day-height: 60px;
		--weekday-bg: ridge #f8fafc;
		border-radius: 0;
		width: 100%;
		& .vc-weeks {
			padding: 0;
		}
		& .vc-weekday {
			font-size: 17px;
			color: #498ed6;
			background-color: #ededed;
			padding: 5px 0;
		}
		& .vc-day:hover {
			box-sizing: border-box;
			box-shadow: 2px 0px 5px #b8b8b8;
			font-weight: bold;
			// .tamKm{
			//   cursor: help;
			// }
		}
		& .vc-day {
			border-radius: 5px;
			border: 1px ridge #d9d9d9;
			color: #0059b3;
			text-align: left;
			height: var(--day-height);
			min-width: var(--day-width);
			background: #f3f3f3;
			&.weekday-1,
			&:not(.on-right) {
				border-right: var(--day-border);
			}
	
			.kmEstimado {
				height: 17px;
				color: #a229e0;
				font-size: 15px !important;
			}
			.kmCAN {
				color: #00a763;
				font-size: 15px !important;
			}
			.tamKm {
				font-size: 12px !important;
			}
			.divider {
				border-bottom: 1px solid grey;
			}
			.fraseDia {
				color: black;
				margin: 0px;
				// height: 16px;
				font-family: nexabook, sans-serif;
				font-size: 12px;
				text-align: center;
				overflow: hidden;
				text-overflow: ellipsis !important;
			}
		}
		.vc-header {
			display: none;
		}
		.vc-arrows-container {
			display: none;
		}
		.fundoWhite {
			border-radius: 5px;
			height: 60px;
			padding: 0 5px 3px 5px;
			width: 100% !important;
			background: white !important;
			border-bottom: 1px ridge #d9d9d9;
		}
	}
	// .roxo {
	// 	color: #a229e0;
	// }
	// .verdeNovo {
	// 	color: #00a763;
	// }
	
	.circuloRoxo {
		width: 18px;
		height: 18px;
		border-radius: 50%;
		background-color: #a229e0;
	}
	
	.circuloVerde {
		width: 18px;
		height: 18px;
		border-radius: 50%;
		background-color: #00a763;
	}
	.legenda {
		background-color: #dadada;
	}
}
</style>
