<template>
    <div class="alertaGenerico">
        <div class="d-flex justify-content-between">
            <div class="tempoEhora">
                <div class="tempo" v-text="getDia(true)" :title="getDia(true)" />
                <div class="hora" v-text="getHora(true)" :title="getHora(true)" />
            </div>
            <div class="flex-column">
                <div class="tituloAlerta" v-text="tipoAlerta" />
                <div class="descricaoAlerta" v-text="descAlerta" :title="descAlerta" />
            </div>
            <div class="flex-column botao">
                <div>
                    <span v-if="!loading" event="click" @click="clicaTratamento">
                        <base-icon
                            class="botaoCor"
                            title="Vizualizar"
                            :size="14"
                            :icon="check"
                        />
                    </span>
                </div>
                <div>
                    <span event="click" @click="clicaMostraLocal">
                        <base-icon class="botaoCor" :size="14" :icon="crosshairs" />
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DateTime } from "luxon";
import { mdiCrosshairsGps, mdiCheckBold } from "@mdi/js";
import baseIcon from "@/components/Atom/Icon/BaseIcon.vue";
import { HttpRequest } from "@/Services/auth/HttpRequest.Service";
export default {
    name: "base-alerta-veiculo",
    components: {
        baseIcon,
    },
    props: {
        /**String que vem direto do bd */
        dataHora: {
            type: String,
            default: "1998/25/10 10:35",
        },
        /**tipo ou titlo, descrição */
        tipoAlerta: {
            type: String,
            default: "dont worry about it",
        },
        /**lat lang do local onde o alerta foi gerado */
        local: {
            type: String,
            required: false,
        },
        /** código do alerta */
        alcodigo: {
            type: [Number, String],
            required: true,
        },
        /**coluna alinfoalerta */
        descAlerta: {
            Type: String,
            required: true,
        },
    },
    data() {
        return {
            crosshairs: mdiCrosshairsGps,
            check: mdiCheckBold,
            quando: DateTime.fromSQL(this.dataHora),
            loading: false,
        };
    },
    methods: {
        /**welp, marca o alerta como lido no bd e depois recarrega os alertas */
        clicaTratamento() {
            this.loading = true;
            var uri = `/finder/veiculo/alertas/ler/${this.alcodigo}`;
            new HttpRequest()
                .Get(uri)
                .then((data) => {
					if (data.status) {
						this.$emit("recarregar-alertas", this.alcodigo);
					}
                })
                .catch((error) => {
                    "";
                })
                .finally(() => (this.loading = false));
        },

        /**
         * emite o evento que leva até o local, no mapa,
         * onde o alerta foi gerado
         */
        clicaMostraLocal() {
            let arrLocal = this.local.split(",");
            this.$emit("local-alerta", arrLocal);
        },

        /**
         * Transforma a string da prop dataHora em uma string só com o dia/mês
         * @param comp, se true, retorna dia/mes/ano
         */
        getDia(comp = false) {
            if (comp) return this.quando.toFormat("dd/MM/yyyy");
            return this.quando.toFormat("dd/MM");
        },

        /**
         * Transforma a string da prop dataHora em uma string só com hora:minuto
         * @param comp, retorna com hh:mm:ss
         */
        getHora(comp = false) {
            if (comp) return this.quando.toFormat("HH:mm:ss");
            return this.quando.toFormat("HH:mm");
        },
    },
};
</script>

<style lang="scss" scoped>
.alertaGenerico {
    margin: 2px;
    padding: 3px;
    width: 98%;
    // height: 45px;
    border: 1px solid;
    border-radius: 4px;
    .tituloAlerta {
        font-weight: bold;
    }
    .alerta {
        border: 2px, solid, black;
    }
    .tempo {
        font-size: 12px;
    }
    .hora {
        font-size: 12px;
    }
    .botao {
        padding-right: 3px;
		align-self: center;
    }
    .botaoCor {
        cursor: pointer;
        color: #5bc0de;
    }
    .descricaoAlerta {
        font-size: 12px;
    }
}
</style>
