<template>
    <modalEagle   
        id ="informacoesOS-"
        title="Detalhes ordem serviço"
        size  ='lg'>
        <template slot="modalBody">
            <b-overlay :show="loadingModal">
                <div class="col-sm-12 row">
                    <div class="col-sm-4 pl-0 pr-0 pb-3">
                        <div align='center' class="pb-2 negrito"> Tipo da ordem de serviço</div>
                        <div align='center' class="pb-2"> {{informacaoOS.ostdescricao ?
                                                            informacaoOS.ostdescricao : ''}} </div>
                    </div>
                    <div class="col-sm-4 pl-0 pr-0 pb-3">
                        <div align='center' class="pb-2 negrito"> Equipamento antigo</div>
                        <div align='center' class="pb-2"> {{informacaoOS.osatual ? 
                                                            informacaoOS.osatual :
                                                            'Sem equipamento'}}</div>
                    </div>
                    <div class="col-sm-4 pl-0 pr-0 pb-3">
                        <div align='center' class="pb-2 negrito"> Equipamento novo</div>
                        <div align='center' class="pb-2"> {{informacaoOS.osnovo ? 
                                                            informacaoOS.osnovo : 
                                                            'Sem equipamento'}}</div>
                    </div>
                    <div class="col-sm-4 pl-0 pr-0 pb-3">
                        <div align='center' class="pb-2 negrito"> Data criação</div>
                        <div align='center' class="pb-2"> {{transformadaDataCriacao(informacaoOS.osdataevento)}}</div>
                    </div>
                    <div class="col-sm-4 pl-0 pr-0 pb-3">
                        <div align='center' class="pb-2 negrito"> Data agendamento</div>
                        <div align='center' class="pb-2"> {{transformadaData(informacaoOS.osdataagendado)}}</div>
                    </div>
                    <div class="col-sm-4 pl-0 pr-0 pb-3">
                        <div align='center' class="pb-2 negrito"> Data confirmação</div>
                        <div align='center' class="pb-2"> {{transformadaData(informacaoOS.osdataconfirmacao)}}</div>
                    </div>
                    <div class="col-sm-4 pl-0 pr-0 pb-3">
                        <div align='center' class="pb-2 negrito"> Motivo</div>
                        <div align='center' class="pb-2"> {{informacaoOS.osmotivo ?
                                                            informacaoOS.osmotivo : ''}}</div>
                    </div>
                    <div class="col-sm-4 pl-0 pr-0 pb-3">
                        <div align='center' class="pb-2 negrito"> Status</div>
                        <div align='center' class="pb-2"> {{informacaoOS.osstatus == 'C' ? 'Cancelada' :
                                                            informacaoOS.osstatus == 'F' ? 'Concluido' :  
                                                            informacaoOS.osstatus == 'A' ? 'Agendada' : 
                                                            informacaoOS.osstatus == 'P' ? 'Pendante' : ''}}</div>
                    </div>
                    <div class="col-sm-4 pl-0 pr-0 pb-3">
                        <div align='center' class="pb-2 negrito"> Justificativa cancelamento</div>
                        <div align='center' class="pb-2"> {{informacaoOS.osjustificativacancelamento ?
                                                            informacaoOS.osjustificativacancelamento : ''}}</div>
                    </div>
                </div>
            </b-overlay>
        </template>
        <template>
            <div class="col-sm-2">
                <simpleButton 
                    class="float-right"
                    :icon="mdiCheckBold" 
                    text="Confirmar" 
                    event="click" 
                    @click="fechaModalDetalhesOperacao"
                    type="blue">
                </simpleButton>
            </div>
        </template>
    </modalEagle>
</template>

<script>
import Vue from 'vue'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { DateTime } from 'luxon';
import { mdiCheckBold } from '@mdi/js'
export default {
	name:'modalInformacaoOS',

	data(){
		return {
			mdiCheckBold : mdiCheckBold,
			informacaoOS : [],
			loadingModal : false,
		}
	},

	components:{
		'modalEagle'   : require('@/components/Atom/Modal/ModalEagle').default,
		'simpleButton' : require('@/components/Atom/Buttons/SimpleButton').default,
	},

	methods:{

		/**
         * @description Essa função deve ser utilizada por uma referência para "ativar"
         * essa modal, os argumentos da função são os necessários para 
         * conseguir popular a modal com as infomações corretas
         * @param {number} idOS - codigo da OS
         * @author Vitor Hugo 
         */
		preparaModal(idOS){
			this.$bvModal.show('informacoesOS-')
			this.loadingModal = true
			var objt = {id: idOS}
			new HttpRequest().Post('/administrativo/cadastros/agendamento/buscar/os', objt)
				.then((dados)=>{
					if(dados.code == 200){
						this.informacaoOS = dados.data.informacaoOS
						this.loadingModal = false
					}
				})
		},

		/**
         * @description Método para transformar a data e hora no padrão para ser mostrada
         * @param {date} data - data e hora no formato (yyyy-LL-dd HH:mm:ss)
         * @return data transformada pelo luxon
         * @author Vitor Hugo 
         */
		transformadaDataCriacao(data){
			if(data != undefined){
				return DateTime.fromFormat(data, 'yyyy-LL-dd HH:mm:ss').toFormat('dd/LL/yyyy HH:mm:ss')
			}
		},

		/**
         * @description Método para transformar a data no padrão para ser mostrada
         * @param {date} data - data no formato (yyyy-LL-dd)
         * @return data transformada pelo luxon
         * @author Vitor Hugo 
         */
		transformadaData(data){
			if(data != undefined){
				return DateTime.fromFormat(data, 'yyyy-LL-dd').toLocaleString(DateTime.DATE_SHORT)
			}
		},

		fechaModalDetalhesOperacao(){
			this.$bvModal.hide('informacoesOS-')
		}
	}
}
</script>

<style lang="scss">
    .negrito{
        font-weight: bold;
    }
</style>