<template>
	<b-modal
	:id='id'
	:no-close-on-backdrop='true'  
	:no-close-on-esc='false'>
		<template #modal-header>
			<div id='header-modal-extrato' class='d-flex justify-content-between'>
				<div id='info-trabalhador'>
					<!-- <div class='img-worker d-flex justify-content-center'> -->
						<!--
						<img v-if='worker.src'
						:src='root+worker.src'
						:alt='worker.mtnome'>
						<base-icon
						v-else
						:size='42'
						:icon='iconUserDefault'/> -->
					<!-- </div> -->
					<div class='info-text'>
						<div
						class='mtnome'
						v-text='worker.mtnome'
						:title='worker.mtnome'/>
						<div
						class='mtdpt'
						v-if="worker.departamento"
						v-text='worker.departamento'
						:title='worker.departamento'/>
						<div
						class='mtcargo'
						v-if="worker.cargo"
						v-text='worker.cargo'
						:title='worker.cargo'/>
					</div>
				</div>
				<div class='close-modal-btn' @click='closeModal'>x</div>
				<div class='stepper-step'>
					<div :class='{
					"current":step===0,
					"first-step":true }'/>
					<div class='arrow unselectable'>-></div>
					<div :class='{
					"current":step==1,
					"second-step":true }'/>
					<div class='arrow unselectable' v-if='selJustificativa==="A"'>-></div>
					<div :class='{
					"current":step==2,
					"third-step":true }'
					v-if='selJustificativa==="A"'/>
				</div>
			</div>
		</template>
		<div id='extrato-body'>
				<b-overlay :show='carregando' class='overlay-extrato'>
				<!-- Steps -->
				<first-step
				v-if='step===0'
				:optSelectJustifica='optSelectJustifica'
				:existeAjuste='jaExisteAjuste'
				:selecionado='preJustificativa'
				@motivo-justifica='salvaMotivo'/>

				<inter-step 
				v-else-if='selJustificativa === "A" && step===1'
				:prePeriodo='cadados.jornadacompleta'
				:preTipo='cadados.tipo'
				:existeAjuste='jaExisteAjuste'
				@muda-tipo='mudaTipoAbono'
				@muda-periodo='mudaPeriodo'/>

				<second-step 
				v-else-if='selJustificativa === "H"'
				:pontos='eventos'
				:motivo='dadosAjuste.camotivo'
				:imgValue='imageValue'
				:existeAjuste='jaExisteAjuste'
				:codigoM='worker.mtcodigo'
				:dataEvento='dataAjuste'
				@pick-file='onPickFile'
				@nova-order='novaOrdem'
				@adc-evento='adicionaEvento'
				@remove-evento='rmvEvento'
				@edita-evento='editaEvento'
				@muda-motivo='mudaMotivo'/>	

				<second-step-abono
				v-if='step === 2 && cadados.tipo ==="5"'
				:motivo="dadosAjuste.camotivo"
				:existeAjuste='jaExisteAjuste'
				:imgValue='imageValue'
				:jornadaCompleta='cadados.jornadacompleta'
				@horarios='mudaHorario'
				@pick-file='onPickFile'
				@muda-motivo='mudaMotivo'/>

				<second-step-atestado
				v-bind='cadados'
				v-if='step === 2 && cadados.tipo ==="2"'
				:imgValue='imageValue'
				:existeAjuste='jaExisteAjuste'
				:motivo='dadosAjuste.camotivo'
				:jornadaCompleta='cadados.jornadacompleta'
				@nome-medico='mudaMedico'
				@muda-crm='mudaCrm'
				@muda-cid='mudaCid'
				@muda-motivo='mudaMotivo'
				@horarios='mudaHorario'
				@pick-file='onPickFile'/>
				<dsr-manual
				v-if='selJustificativa === "D" && step === 1'
				:eventoDsr='eventoDsr'
				:data='dataAjuste'
				:mtcodigo='worker.mtcodigo'
				@nova-data='mudaDataDsr'
				@rmv-dsr='removeDsr'
				@adc-dsr='adicionaDsr'/>
			</b-overlay>
			<input
			type='file'
			accept="image/png, image/jpeg"
			style='display:none'
			ref='fileInput'
			@change='onFilePicked'/>
		</div>
		<template #modal-footer>
			<simple-button
			:icon='iconRedButton'
			:text='textRedButton'
			event='click'
			type='red'
			@click='cancelButton'/>
			<simple-button
			:icon='iconBlueButton'
			:text='textBlueButton'
			:disabled='$v.$invalid || !numeroEventosValido'
			event='click'
			type='blue'
			@click='confirmButton'/>
		</template>
	</b-modal>
</template>
<script>
import{
	mdiCloseThick,
	mdiArrowRightBold,
	mdiArrowLeftBold,
	mdiAccount,
	mdiCheckBold} from '@mdi/js'
import {required, requiredIf } from 'vuelidate/lib/validators'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { DateTime } from 'luxon'
import { conectionError } from '@/Services/Helpers/swellHeper'
const urlBase = '/checkpoint/relatorios/extrato/'
const pair = (value, teste) =>{
	return value.length % 2 === 0
} 
export default {
	name:'AjustesStepper',
	components:{
		simpleButton	   : require('@/components/Atom/Buttons/SimpleButton').default,
		FirstStep   	   : require('./StepperStep/FirstStep.vue').default,
		SecondStep  	   : require('./StepperStep/SecondStep.vue').default,
		SecondStepAtestado : require('./StepperStep/SecondStepAtestado.vue').default,
		InterStep		   : require('./StepperStep/InterStep.vue').default,
		SecondStepAbono    : require('./StepperStep/SecondStepAbono.vue').default,
		DsrManual          : require('./StepperStep/DSRManual').default,
	},
	props:{
		id:{
			type: [String, Number],
			required: true
		}
	},

	validations:{
		selJustificativa: { required },
		eventos:{
			requiredIf: requiredIf(function(){
				return this.dadosAjuste.catipo === 'H' && this.step === 1
			}),
		},
		horarios: {
			requiredIf: requiredIf(function(){
				return !this.cadados.jornadacompleta && this.step === 2 
			}),
		},

		imageValue: { requiredIf: requiredIf(function(){
			return this.cadados.tipo === '2' && this.step === 2
		})},

		cadados:{
			tipo: { requiredIf:requiredIf(function(){
				return this.step === 1 && this.dadosAjuste.catipo ==='A'
			})},
			nomemedico: { requiredIf: requiredIf(function(){
				return this.cadados.tipo === '2' && this.step === 2
			})},
			crm: { requiredIf: requiredIf(function(){
				return this.cadados.tipo === '2' && this.step === 2
			})},

			cid: {requiredIf: requiredIf(function(){
				return this.cadados.tipo === '2' && this.step === 2
			})},

		},
		dadosAjuste:{
			camotivo:{ requiredIf: requiredIf(function(){
				if(this.selJustificativa === 'H'){
					return this.step === 1
				}
				return this.step === 2
			})},
		},
	},

	data(){
		return {
			//Controles das fita tudo 
			step:0,
			selJustificativa:'',
			carregando:false,
			jaExisteAjuste:false,
			eventoDsr: false,
			dataAjuste:'',
			preJustificativa:[],
			root : process.env.VUE_APP_ROOT,
			optSecondStep:[
				{value:'0', description: 'O dia todo'},
				{value:'1', description: 'Somente o tempo faltante'},
			],
			iconUserDefault: mdiAccount,

			//informações do funcionário
			worker:{
				mtcodigo:'',
				src:'',
				mtnome:'',
				cargo:'',
				departamento:'',
			},
			jtdsrmanual:false,

			//informações do pedido de ajuste
			cadados:{
				crm:'',
				cid:'',
				nomemedico:'',
				jornadacompleta:'',
				tipo:'',
				datainicio:'',
				datafim:''
			},
			horarios:[],
			dadosAjuste:{
				cadados:'',
				camotivo:'',
				catipo:'',
			},

			//img stuff
			imageValue:'',
			image:'',
			imageUrl:'',
			imgsize:'',
			getExtencao: /(?<=\.)\w+$/,

			//eventos (batida de ponto)
			eventos:[], //Sendo exibidos, pode ser do bando de dados ou criado pelo usuário
			eventosExcluidos:[], //código dos eventos excluidos. 
			eventosOriginais:[], // quando um evento é editado, uma copia do original deve parar aqui
		}
	},
	methods:{
		mudaDataDsr({mtcodigo, data}){
			let data2 = data.replace(/\//g, '-');
			let url = `${urlBase}get/dsr/${mtcodigo}/${data2}`
			this.dataAjuste = data2.replace(/(\d\d)-(\d\d)-(\d{4})/, '$3-$2-$1')
			this.carregando = true
			new HttpRequest().Get(url).then((res)=>{
				if(res.data.dsr.length)
					this.eventoDsr = true
				else 
					this.eventoDsr = false
			}).finally(()=>{this.carregando=false})
		},

		/**
		 * @param {object} obj
		 * @param {int} obj.mtcodigo código do funcionário
		 * @param {string} obj.data  data da DSR
		 * @description remove eventos de DSR do funcionario na data selecionada
		 */
		removeDsr({mtcodigo, data}){
			let url = `${urlBase}rmv/dsr/${mtcodigo}/${data}`
			this.carregando = true
			new HttpRequest().Get(url).then((res)=>{
				if(res.data.success === true)
					this.eventoDsr = false
			}).finally(()=>{this.carregando = false})
		},

		/**
		 * @param {object} dsrData
		 * @param {int} dsrData.mtcodigo código do funcionário
		 * @param {string} dsrData.data  data da DSR
		 * @description adiciona evento de DSR do funcionario na data selecionada
		 */
		adicionaDsr(dsrData){
			var link = `${urlBase}adc/dsr`
			this.carregando = true
			new HttpRequest().Post(link, dsrData).then((res)=>{
				if(res.data.success){
					this.eventoDsr = true
				}
			}).finally(()=>{this.carregando = false})
		},
		/**
		 * @listens horarios - componentes de ajuste
		 * @param {string[]} arrHorarios
		 */
		mudaHorario(arrHorarios){
			this.horarios = arrHorarios
			this.cadados.datainicio = arrHorarios[0]
			this.cadados.datafim = arrHorarios[1]
		},

		mudaTipoAbono(tipo){
			this.cadados.tipo = tipo
		},

		mudaPeriodo(completa){
			this.cadados.jornadacompleta = completa
		},

		onFilePicked (event) {
			const files = event.target.files
			const clean = /^data:image\/.*;base64,/
			this.imageValue = files[0].name
			const fileReader = new FileReader()
			fileReader.addEventListener('load', () => {
				this.imageUrl = fileReader.result.replace(clean, '')
			})
			fileReader.readAsDataURL(files[0])
			this.image = files[0]
			this.imgsize = files[0].size
		},

		onPickFile(){
			this.$refs.fileInput.click()
		},

		cancelButton(){
			if(!this.step) this.$bvModal.hide(this.id)
			this.step--
		},

		closeModal(){
			this.resetaDados()
			this.$bvModal.hide(this.id)
		},
		
		/**
		 * @listens click botão de próximo no footer da modal.
		 * @description ou passa para o próximo estagio do ajuste
		 * ou faz a request para tentar salvar o ajuste.
		 * @author Gui 🍺
		 */
		confirmButton(){
			let ajuste_horas_ready = this.dadosAjuste.catipo === 'H' && this.step === 1
			let abono_ready = this.dadosAjuste.catipo === 'A' && this.step === 2
			let ehDsrManual = this.selJustificativa === 'D'
			let second_step = this.step === 1
			if(ajuste_horas_ready || abono_ready){
				if(this.jaExisteAjuste){
					this.$bvModal.hide(this.id)
					return
				}
				let obj = this.preparaObjSalvarAjuste()
				var link = `${urlBase}ajuste/salvar`
				this.carregando = true
				new HttpRequest().Post(link, obj).then((res)=>{
					this.carregando = false
					if(res.data.success){
						this.$emit('reload-dados')
						this.$bvModal.hide(this.id)
					}else{
						conectionError()
					}
				})
			}else if(ehDsrManual && second_step){
				this.$emit('reload-dados')
				this.$bvModal.hide(this.id)
			}
			else ++this.step
		},

		/**
		 * @description prepara o objeto do ajuste p/
		 * salvar no banco de dados, verificando que tipo de 
		 * ajuste que é, e retornando o objeto de acordo
		 * @returns {object} com os campos prontos e de acordo
		 * com o banco de dados de um ajuste recem saído do forno.
		 * @author Gui 🍺
		 */
		preparaObjSalvarAjuste(){
			if(this.selJustificativa === 'A'){
				// abono
				return {
					'dadosAjuste':{
						...this.dadosAjuste,
						'cadados':JSON.stringify(this.cadados),
						'cacodigocolaborador':this.worker.mtcodigo,
						'castatus': 'P',
						'cadatajornada':this.dataAjuste,
						'cadatahorasolicitacao': DateTime.now()
							.toFormat('y-LL-dd HH:mm:ss'),
					},
					'file':this.imagem
				}
			}else{
				// ajuste de horas
				return {
					'dadosAjuste':{
						...this.dadosAjuste,
						'cadados':JSON.stringify({
							'Excluidos'  : this.eventosExcluidos,
							'Originais'  : this.eventosOriginais.map((e)=>{
								let obj =  {
									...e,
									'crdatahoraevento':this.ajustaDataEHora(e)
								}
								delete obj.hora 
								delete obj.data
								return obj
							}),
							'Solicitados': this.eventos.map((e)=>{
								let obj = {
									...e,
									'crdatahoraevento':this.ajustaDataEHora(e)
								}
								delete obj.hora 
								delete obj.data
								return obj
							}),
						}),
						'cacodigocolaborador':this.worker.mtcodigo,
						'castatus': 'P',
						'cadatajornada':this.dataAjuste,
						'cadatahorasolicitacao': DateTime.now()
							.toFormat('y-LL-dd HH:mm:ss')
					},
					'file':this.imagem
				}
			}
		},

		/**
		 * @description só troca o fromado da data, de um que o
		 * usuário entenda, pra um que fique nos padrões do banco de dados.
		 * @return {string} data no formado certo
		 */
		ajustaDataEHora({data, hora}){
			let reg = /(\d{2})\/(\d{2})\/(\d{4})/g
			let formato =  '$3-$2-$1'
			return `${data.replace(reg, formato)} ${hora+':00'}`
		},

		/**
		 * @listens motivo-justifica
		 * @param {number} codigo
		 */
		salvaMotivo(codigo){
			this.dadosAjuste.catipo = ''
			this.preJustificativa = []
			this.selJustificativa = ''
			if(codigo!== undefined){
				this.selJustificativa = codigo
				this.dadosAjuste.catipo = codigo
				this.preJustificativa = this.optSelectJustifica.filter((p)=>{
					return p.value === codigo
				})
			}
		},
		
		/**
		 * @listens edita-evento
		 * @param {number} index
		 * @description quando o momento de algum dos
		 * eventos é alterado, essa função ordena os eventos já.
		 */
		editaEvento(index){
			this.eventos[index].crajustado = true
			this.eventos = this.eventos.sort((a, b)=>{
				var t1 = DateTime.fromSQL(`${a.data} ${a.hora}`)
				var t2 = DateTime.fromSQL(`${b.data} ${b.hora}`)
				return t1 > t2
			})
		},
		
		/**
		 * @param {string} data - quando foi/sera o ajuste.
		 * @param {number} mtcodigo - código do funcionario.
		 * @param {object} jornada - jornada do trabalhador
		 * @param {boolean} jornada.jtdsrmanual se a jornada tem dsr manual
		 * @description prepara a modal para o caso de já existir um ajuste, e 
		 * procura pelo nome/cargo/departamento do funcionário.
		 * @author Gui 🍺
		 */
		iniciaStepper(data, mtcodigo, jornada){
			this.jtdsrmanual = jornada.jtdsrmanual 
			let url = `${urlBase}get/ajuste/${data}/${mtcodigo}`
			this.resetaDados()
			this.worker.mtcodigo = mtcodigo
			this.dataAjuste = data
			this.horarios = []
			this.carregando = true
			this.cadados = {
				crm:'',
				cid:'',
				nomemedico:'',
				jornadacompleta:'',
				tipo:'',
				datainicio:'',
				datafim:''
			}
			new HttpRequest().Get(url).then((res)=>{
				this.eventosOriginais = [...res.data.eventos]
				this.eventos = res.data.eventos
				if(res.data.ajustes){
					var ajuste = res.data?.ajustes
					if(ajuste){
						var status = ajuste.castatus
						if(status === 'P'){
							this.dadosAjuste = res.data.ajustes
							this.preJustificativa.push(_.find(this.optSelectJustifica, {
								value: this.dadosAjuste.catipo
							}))
							this.selJustificativa = this.dadosAjuste.catipo
							this.motivo = this.dadosAjuste.camotivo
							this.cadados = JSON.parse(this.dadosAjuste.cadados)
							if(this.cadados?.Solicitados){
								let eventos = this.cadados.Solicitados
								eventos = eventos.filter((e)=>{
									return e.crajustado || e.crcodigo === 0
								})
								this.eventos = this.eventos.filter((e)=>{
									return !e.crajustado
								})
								this.eventos = this.eventos.concat(eventos.map((e)=>{
									return {
										...e,
										hora:e.crdatahoraevento.split(' ')[1]
									}
								}))
							}
							if(res.data.doc?.cadnome)
								this.imageValue = res.data.doc.cadnome
							this.jaExisteAjuste = true
						}
					}
				}else{
					this.dadosAjuste = {
						cadados:'',
						camotivo:'',
						catipo:'',
					}
					if(res.data.checkpointEventos.length){
						let checkpointEventos = res.data.checkpointEventos
						checkpointEventos.forEach( ev => {
							if(ev.cetipo === 'DSR'){
								this.eventoDsr = true
								this.preJustificativa = this.optSelectJustifica.filter((opt)=>{
									return opt.description == 'DSR'
								})
								this.step = 1
							}
						});
					}
				}
				this.informacoesFuncionario(res.data.funcionario)
				this.step = 0
			}).finally(()=>{
				this.carregando = false
			})
		},

		/**
		 * @param {object} funcionario
		 * @param {string|null} funcionario.mtfoto      -url da foto
		 * @param {string|null} funcionario.ccdescricao -cargo
		 * @param {string|null} funcionario.cddescricao -departamento
		 * @param {string}		funcionario.mtnome		-nome
		 */
		informacoesFuncionario(funcionario){
			var {mtfoto, mtnome, ccdescricao, cddescricao, jtdsrmanual} = funcionario
			this.worker.src = mtfoto ?? false
			this.worker.mtnome = mtnome
			this.worker.cargo = ccdescricao
			this.worker.departamento = cddescricao
		},

		/**
		 * @param {number} value - # do CID
		 */
		mudaCid(value){
			this.cadados.cid = value
		},

		/**
		 * @param {number} value - # do CRM
		 */
		mudaCrm(value){
			this.cadados.crm = value
		},
		
		/**
		 * @listens nome-medico
		 * @param {string} nome - do médico
		 */
		mudaMedico(nome){
			this.cadados.nomemedico = nome
		},

		/**
		 * @listens muda-motivo - em 3 componentes diferentes.
		 * @param {string} text - motivo do ajuste.
		 */
		mudaMotivo(text){
			this.dadosAjuste.camotivo = text
		},
		
		/**
		 * @param {number} index - do evento a ser excluido.
		 * @description dps de excluir o evento salva o código
		 * do mesmo na variavel de controle. É necessário p/
		 * concluir o ajuste.
		 */
		rmvEvento(index){
			let excluido =  this.eventos.splice(index, 1)
			let codExcluido = excluido.map(e=> {
				return e.crcodigo
			})
			this.eventosExcluidos = this.eventosExcluidos.concat(codExcluido)
		},

		/**
		 * @listens nova-order
		 * @param {object[]} pontos - na nova ordem.
		 */
		novaOrdem(pontos){
			this.pontos = pontos
		},

		adicionaEvento(evento){
			this.eventos.push(evento)
		},

		/**
		 * @description função p/ ser executada quando a modal
		 * é fechada, garantindo que na próxima vez que ela abrir
		 * todos os campos estaram limpos, sem 'lixo'
		 * de outra edição.
		 */
		resetaDados(){
			this.preJustificativa = []
			this.worker.mtnome = ''
			this.worker.src = ''
			this.worker.cargo = ''
			this.worker.departamento = ''
			this.eventosExcluidos = []
			this.jaExisteAjuste = false
			this.step = -1
			this.imageValue = ''
			this.image = ''
			this.imageUrl = ''
			this.imgsize = ''
			this.selJustificativa = ''
			this.eventoDsr = false
			this.dadosAjuste = {
				cadados:'',
				camotivo:'',
				catipo:'',
			}
		},
	},
	computed:{
		optSelectJustifica(){
			let opts =  [
				{value:'A', description:'Solicitar Abono'},
				{value:'H', description:'Ajuste de horas'},
			]
			if(this.jtdsrmanual)
				opts.push({value:'D', description:'DSR'})
			return opts
		},

		/**
		 * @description caso estiver no step 1, e o tipo
		 * do ajuste de jornada for H, quer dizer que o ajuste
		 * é de inserção de eventos de ponto. Nesse caso, o # de
		 * eventos tem de ser par e maior de zero.
		 */
		numeroEventosValido(){
			if(this.step === 1){
				if(this.dadosAjuste.catipo === "H"){
					if(this.eventos.length > 1)
						return this.eventos.length % 2 ===0
					return false
				}
			}
			return true
		},

		/**decide o texto do botão azul */
		textBlueButton(){
			let ajuste_horas = this.dadosAjuste.catipo === 'H'
			let abono = this.dadosAjuste.catipo === 'A'
			let second_step = this.step === 1
			let third_step = this.step === 2
			let ehDsrManual = this.selJustificativa === 'D'
			if( ajuste_horas && second_step){
				return 'Confirmar'
			}
			if(abono && third_step)
				return 'Confirmar'
			if(ehDsrManual && second_step)
				return 'Confirmar'
			return 'Próximo'
				
		},
		/**decide o ícone do botão azul */
		iconBlueButton(){
			let ajuste_horas = this.dadosAjuste.catipo === 'H'
			let abono = this.dadosAjuste.catipo === 'A'
			let second_step = this.step === 1
			let third_step = this.step === 2
			let ehDsrManual = this.selJustificativa === 'D'
			if( ajuste_horas && second_step)
				return  mdiCheckBold
			if(ehDsrManual && second_step)
				return mdiCheckBold
			if(abono && third_step)
				return  mdiCheckBold
			return mdiArrowRightBold 
		},
		/**decide o texto do botão vermelho */
		textRedButton(){
			return this.step? 'Voltar' : 'Cancelar'
		},
		/**decide o ícone do botão vermelho */
		iconRedButton(){
			return this.step?mdiArrowLeftBold :mdiCloseThick
		},
		imagem(){
			if(this.imageValue){
				return {
					caddocumento:this.imageUrl,
					cadnome:this.imageValue,
					cadtipo:this.getExtencao.exec(this.imageValue)[0],
					cadtamanho:this.imgsize
				}
			}else{
				return {}
			}
		},
		interStepDone(){
			let temPeriodo = this.cadados.jornadacompleta !== ''
			let temTipo    = this.cadados.tipo    !== ''
			if(temPeriodo && temTipo)
				return true 
			return false
		},

	},
}
</script>

<style lang="scss" >
#header-modal-extrato{
	--squareddiv : 25px;
	--stepcolor :#B3D7FF;
	--currentstepcolor : #6BA1FB ;
	.close-modal-btn{
		position: absolute;
		right: 11px;
		top: 6px;
		float: right;
		font-size: 11px;
		font-weight: 700;
		line-height: 1;
		user-select: none;
		cursor: pointer;
	}
	#info-trabalhador{
		display: flex;
		.img-worker{
            height: 60px;
            width: 60px;
            border-radius: 50%;
            overflow: hidden;
            background-color: #aaa;
            img{
                width: 52px;
                height: 60px;
            }
			svg{
				align-self: center;
			}
		}
		.info-text{
			margin-top: 5px;
			margin-left: 10px;
			height: 60px;
			.mtnome{
				max-width: 240px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
			.mtdpt{
				margin-left: 0px;
			}
			.mtdpt::before{
				content:'Departamento: ';
				font-weight: bolder;
			}
			.mtcargo{
				margin-left: 0px;
			}
			.mtcargo::before{
				content:'Cargo: ';
				font-weight: bolder;
			}
		}
	}
	.stepper-step{
		position: absolute;
		right: 15px;
		margin-top: 10px;
		display: flex;
		div[class$=step]{
			height: var(--squareddiv);
			width: var(--squareddiv);
			background-color: var(--stepcolor);
			border-radius: 50%;
			padding-top: 4px;
		}
		div[class*=arrow]{
			font-size: 21px;
			margin-left: 5px;
			margin-right: 5px;
			margin-bottom: -20px;
		}
		div[class^=current]{
			background-color: var(--currentstepcolor);
		} 
		div[class*=first]::after{
			padding-left: 10px;
			content: '1';
		}
		div[class*=second]::after{
			padding-left: 8px;
			content: '2';
		}
		div[class*=third]::after{
			content: '3';
			padding-left: 7px;
		}
		.unselectable{
			user-select: none;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
		}
	}
}
#extrato-body{
	min-height: 100px;
	padding-top: 10px;
}
</style>