Componente de slider feito de maneira simples para ser utilizado de maneira genérica. 
Props: 
  min & max Values: Exatamente o que soa, o valor minimo e máximo que o slider pode assumir. 
  disabled: se o componente se encontra desabilitado 
  initialValue: Valor inicial do slider
  value = valor inicial
Eventos: 
  O componente em si não possue enventos, porem é possível acessar todos os eventos do sub componente "vue-slider"
  @change = emitido quando o valor do slider mudar
  @mudaValor

Caso seja necessário mecher no componente no furuto, é recomendado seguir o molde das props que já estão presentes. 
-> Mesmo nome da propriedade que vai ser alterada pela prop 
-> Deixar como valor default o valor que se encontra na propriedade data 

<template>
  <div>
    <vue-slider v-bind="options" v-on="$listeners" v-model='model'><slot></slot></vue-slider>
  </div>
</template>

<script>
  import Vue from 'vue'
  import 'vue-slider-component/theme/default.css'
  import VueSlider   from 'vue-slider-component'
  import 'vue-slider-component/dist-css/vue-slider-component.css'
  export default Vue.extend({
    name:'basicSlider',
    components: {
      VueSlider,
    },
    inheritAttrs:false,
    props: {
      minValue: {
        type: Number,
        default: 0
      },
      maxValue:{
        type: Number,
        default: 200
      },
      height:{
        type: [Number, String],
        default: 4
      },
      direction:{
        type: String,
        default:'ltr',
      },
      disabled:{
        type: Boolean,
        default : false,
      },
      value:{
        type: [String, Number],
        default: 0
      }
    },
    data() {
      return {
        model:this.value,
        options: {
          dotSize: 14,
          width: 'auto',
          height: 4,
          contained: false,
          direction: 'ltr',
          data: null,
          min: this.minValue,
          max: this.maxValue,
          interval: 1,
          // disabled: false,
          disabled: this.disabled,
          clickable: true,
          duration: 0.5,
          adsorb: false,
          lazy: false,
          tooltip: 'none',
          tooltipPlacement: 'top',
          tooltipFormatter:  '0',
          useKeyboard: false,
          keydownHook: null,
          dragOnClick: false,
          enableCross: true,
          fixed: false,
          minRange:  0,
          maxRange:  10000,
          order: true,
          marks: false,
          dotOptions:  0,
          process: true,
          dotStyle:  0,
          railStyle:  0,
          processStyle:  0,
          tooltipStyle:  0,
          stepStyle:  0,
          stepActiveStyle:  0,
          labelStyle:  0,
          labelActiveStyle:  0,
        }
      }
    },
    watch: {
      model(newValue, oldValue) {
        this.$emit('mudaValor', newValue)
      }
    },
  })
</script>

<style lang="scss" scoped>

</style>
