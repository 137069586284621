<template>
	<ModalEagle id="importarLinhas" title="Importar rastro" @modalClose="modalClose" :hardToClose="true">
		<template #modalBody>
			<b-overlay :show='importandoRastro' class='bemAlto'>
				<div class="col-sm-12 nopadding row">
					<div class="col-sm-6 nopadding">
						<SelectAll ref="refEmpresa" nameForRadio="seletorEmpresaImportarRastro"
							:labels="[{ description: 'Empresa*' }]" :optionsArray="optSelectEmpresa" :isMultiple="false"
							@changeSelect="selectEmpresaImportacao" />
						<div class="col-sm-12 nopadding secondline row mt-3">
							<div class="col-sm-6 nopadding">
								<InputSingleDay @changeInput="mudaDataInicialImportacao" name="seletorDataInicioImportacaoRastro"
									label="Data Inicial*" />
							</div>
							<div class="col-sm-6 nopadding">
								<InputSimple name="inputHoraInicial" label="Hora Inicial*" type="time" value="00:00"
									@changeInput="horarioInicial" />
							</div>
						</div>
					</div>

					<div class="col-sm-6 nopadding">
						<SelectAll nameForRadio="seletorVeiculoImportarRastro" :disabled="Boolean(!optSelectVeiculo.length)"
							:labels="[{ description: 'Veículo*' }]" :optionsArray="optSelectVeiculo" ref="veiculoImportacao"
							:isMultiple="false" :loading="carregandoV" @changeSelect="selecionaVeiculoImportacao" />
						<div class="col-sm-12 nopadding secondline row mt-3">
							<div class="col-sm-6 nopadding">
								<InputSingleDay name="seletorDataFinalImportacaoRastro" label="Data Final*"
									@changeInput="mudaDataFinalImportacao" />
							</div>
							<div class="col-sm-6 nopadding">
								<InputSimple name="inputHoraFinal" label="Hora Final*" type="time" value="23:59"
									@changeInput="horarioFinal" />
							</div>
						</div>
					</div>
				</div>
			</b-overlay>
		</template>

		<template #modalFooter>
			<p class="col-sm-8" :class="erroImportacao ? 'erroImportacao text-center cursor-pointer' : ''" event="click"
				@click="erroImportacao = ''">
				{{ erroImportacao }}
			</p>

			<b-overlay :show="importandoRastro" rounded="sm" :opacity="0.4">
				<template #overlay>
					<div />
				</template>
				<SimpleButton text='Importar' :icon="mdiCheckBold" event='click' @click="procuraRastro" type="blue"
					:disabled="$v.veiculo.$invalid && !dataErrada" />
				<SimpleButton type="red" text="cancelar" :icon="mdiCloseThick" event="click" @click="cancelarImportacao" />
			</b-overlay>
		</template>
	</ModalEagle>
</template>
<script>

import Vue from 'vue'
import { DateTime } from 'luxon';
import { required } from 'vuelidate/lib/validators'
import { mdiCheckBold, mdiCloseThick } from '@mdi/js'
import { conectionError } from '@/Services/Helpers/swellHeper.js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { FiltrosService } from '@/Services/filtros/filtros.Service';

export default Vue.extend({
	name: 'ModalImportarPontosLinhas',
	components: {
		SelectAll: require('@/components/Atom/Select/SelectAll.vue').default,
		ModalEagle: require('@/components/Atom/Modal/ModalEagle.vue').default,
		InputSimple: require('@/components/Atom/Inputs/InputSimple.vue').default,
		SimpleButton: require('@/components/Atom/Buttons/SimpleButton.vue').default,
		InputSingleDay: require('@/components/Atom/Datas/InputSingleDay.vue').default,
	},

	validations: {
		veiculo: { required },
	},

    props: {
		velocidadeMedia: {
			type: [Number, String],
			default: ''
		}
	},

	data() {
		return {
			baseUri: '/coletivos/cadastros/linhas/',
			mdiCheckBold: mdiCheckBold,
			mdiCloseThick: mdiCloseThick,
			optSelectVeiculo: [],
			optSelectEmpresa: new EmpresasService().Get(),
			importandoRastro: false,
			carregandoV: false,
			erroImportacao: '',
			importarRastro: {
				empresa: [],
				veiculo: '',
				dataInicial: '',
				dataFinal: '',
				horaInicial: '00:00',
				horaFinal: '23:59',
			},
			veiculo: '',
			dataErrada: false,
		}
	},

	methods: {
		modalClose() {
			this.$refs.refEmpresa.clearAll()
			this.$refs.veiculoImportacao.clearAll()
			this.dataErrada = false
			this.veiculo = ''
		},

		mudaDataInicialImportacao(value) {
			this.importarRastro.dataInicial = value;
			this.validataDataEHora()
		},

		mudaDataFinalImportacao(value) {
			this.importarRastro.dataFinal = value;
			this.validataDataEHora()
		},

		horarioInicial(hr) {
			this.importarRastro.horaInicial = hr;
			this.validataDataEHora()

		},

		horarioFinal(hr) {
			this.importarRastro.horaFinal = hr;
			this.validataDataEHora()
		},

		validataDataEHora() {
			var inicio = DateTime.fromFormat(this.importarRastro.dataInicial + ' ' + this.importarRastro.horaInicial, 'dd/LL/yyyy hh:mm')
			var fim = DateTime.fromFormat(this.importarRastro.dataFinal + ' ' + this.importarRastro.horaFinal, 'dd/LL/yyyy hh:mm')
			var dif = fim.diff(inicio, 'hours').toObject()
			if (dif.hours < 0) {
				this.erroImportacao = 'A data e hora inicial não podem ser menores que a data e hora final'
				this.dataErrada = true
			} else {
				this.erroImportacao = ''
				this.dataErrada = false
			}
		},

		selectEmpresaImportacao(arr, value, description) {
			this.$refs.veiculoImportacao.clearAll()
			this.importarRastro.empresa = value
			this.optSelectVeiculo = []
			if (arr.length) {
				this.carregandoV = true
				new FiltrosService().dados_filtros(arr, ['V'])
					.then((data) => {
						this.optSelectVeiculo = data.V
						this.carregandoV = false
					}).catch((err) => {
						this.carregandoV = false
						conectionError()
					})
			}
		},

		selecionaVeiculoImportacao(arr, value, description) {
			if (description) {
				description = description.split(' | ')[0];
			}
			this.veiculo = description;
		},

		cancelarImportacao() {
			this.$bvModal.hide('importarLinhas');
			this.erroImportacao = '';
		},

		procuraRastro() {
			this.pontosLinha = [];
			this.importandoRastro = true;
			this.erroImportacao = '';
			let obj = {
				placa: this.veiculo,
				dataIni:
					this.importarRastro.dataInicial +
					' ' +
					this.importarRastro.horaInicial,
				dataFim:
					this.importarRastro.dataFinal +
					' ' +
					this.importarRastro.horaFinal,
				velocidadeMedia: this.velocidadeMedia
			};
			let uri = this.baseUri + 'importar/rota';

			new HttpRequest().Post(uri, obj)
				.then((dados) => {
					if (dados.status) {
						if (dados.data.array.length) {
							this.$emit('copiarRastro', dados.data.array)
							// this.rastroImportacao = dados.data.array
							// this.preTipo = [{ value: 2, description: "Sem Pontos" }]
							this.$bvModal.hide('importarLinhas');
						} else {
							this.erroImportacao = 'Não há dados para o período informado';
						}
					} else {
						conectionError()
					}
				}).catch((err) => {
					conectionError()
				}).finally(() => {
					this.importandoRastro = false
				})
		},
	},
})
</script>

<style lang="scss">
.erroImportacao {
	color: #FFF;
	height: 20px;
	background-color: rgba(255, 0, 0, 0.637) !important;
}

#importarLinhas___BV_modal_header_ {
	button.close {
		display: none !important;
	}
}
</style>
