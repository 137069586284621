<template>
    <modalEagle
        :title="defineTitleModal"
        id="modal-solicitacao-manutencao"
        size="xl"
        @modalOpen="modalOpen"
    >
        <slot slot="modalBody">
            <b-overlay :show="show" rounded="sm">
                <div class="col-sm-12 row nopadding">
                    <div class="col-12 row nopadding">
                        <div class="col-4 nopadding mt-1,5">
                            <selectAll
                                :labels="[
                                    {
                                        indexDFH: 'TSM',
                                        description: 'Selecione o tipo de manutenção*',
                                    },
                                ]"
                                nameForRadio="select"
                                :optionsArray="optSelectTipoSolicitacaoManutencoes"
                                @changeSelect="selecionarOpcoesModal"
                            >
                            </selectAll>
                        </div>
                        <div class="col-8 observacaoModal">
                            <span>Observação:</span>
                            <span
                                class="float-right"
                                v-show="opcoesModalSelecionada == '4'"
                                >Especifique o motivo da solicitação.*</span
                            >
                            <textarea title="Observação" v-model="textoTextArea">
                            </textarea>
                        </div>
                    </div>
                </div>
            </b-overlay>
        </slot>
        <slot slot="modalFooter">
            <div class="d-flex flex-row nopadding">
                <simpleButton
                    class="p-2"
                    :icon="mdiCloseThick"
                    @click="cancelButton"
                    text="Cancelar"
                    event="click"
                    type="red"
                >
                </simpleButton>
                <simpleButton
                    class="p-2"
                    :icon="mdiCheckBold"
                    @click="confirmButton"
                    text="Enviar"
                    event="click"
                    type="blue"
                    :disabled="$v.$invalid"
                >
                </simpleButton>
            </div>
        </slot>
    </modalEagle>
</template>
<script lang="js">
import Vue from 'vue';
import{mdiCheckBold, mdiCloseThick } from '@mdi/js'
import { FiltrosService } from '@/Services/filtros/filtros.Service';
import {required, requiredIf} from 'vuelidate/lib/validators'
import {HttpRequest} from '../../../Services/auth/HttpRequest.Service'
import { mapGetters } from 'vuex'

export default Vue.extend({
	name: 'ModalSolicitaManutencaoVeiculos',

	components:{
		selectAll: require('@/components/Atom/Select/SelectAll').default,
		modalEagle: require('@/components/Atom/Modal/ModalEagle').default,
		simpleButton: require('@/components/Atom/Buttons/SimpleButton').default,

	},

	props:{
        veiculo: {
            required: true,
            type: Object,
			default: ()=>{return[]}
        },
	},

	data(){
		return{
			link: '/administrativo/cadastros/veiculos/',
			textoTextArea: '',
			optSelectTipoSolicitacaoManutencoes: [],
			opcoesModalSelecionada: [],
			nomeOpcoesModalSelecionado: '',
			show: false,
			mdiCloseThick: mdiCloseThick,
			mdiCheckBold: mdiCheckBold,
		}
	},

	computed: {
		defineTitleModal() {
			let placa = this.veiculo?.veplaca ?? ''
            let prefixo = this.veiculo?.veprefixo ?? placa
            let divisor = placa && prefixo ? '|' : ''
			return `Solicitação de Manutenção: ${placa} ${divisor} ${prefixo}`
		},
	},

	methods: {
		...mapGetters(['getUser', 'getClient']),
		selecionaTipoSolicitacaoManutencoes() {
			new FiltrosService()
				.dados_filtros([],['TSM'])
				.then((data) => {
					this.optSelectTipoSolicitacaoManutencoes = data.TSM;
				})
				.catch(e => {
					''
				});
		},

		/**
        * @description - Se a opção selecionada for 'Outros', o campo de observação será obrigatório.
        Mostra uma mensagem logo após seleção: “Especifique o motivo da solicitação.
        Os parâmetros representam, em ordem: o id do objeto,de novo id e o nome do campo.
        * @param {Int} value - Recebe o id do campo selecionado, que no caso é o id de uma opção do Select;
		* @param {String} OpcaoDoSelect -  - Recebe o 'veprefixo', que no caso é o nome de uma opção do Select;
        * @author Daniel Vitor Nascimento
 		*/
		selecionarOpcoesModal(value, x, OpcaoDoSelect){
			this.opcoesModalSelecionada = value
			this.nomeOpcoesModalSelecionado = OpcaoDoSelect
		},

		/**
        * @description - Ao clicar em "Enviar" no modal de solicitação de manutenção, um objeto -
        *  é formado e mandado para o back-end. Este objeto possui a placa do veículo do modal (backend = veplaca),
        *  o código(id) das colunas do selectAll do modal (backend = smcodigotipomanutencao),
        * e o texto do input do modal - vazio = '' - (backend = smobservacao).
        * @author Daniel Vitor Nascimento
        */
		confirmButton(){
			var dadosUser = this.getUser()
			this.show = true
			var obj = {
				'smveiculo': this.veiculo.veplaca,
				'smcodigotipomanutencao': this.opcoesModalSelecionada[0],
				'smobservacao': this.textoTextArea,
				'nomeOpcoesModalSelecionado': this.nomeOpcoesModalSelecionado,
				'vecodigo': this.veiculo.vecodigo,
				'dadosUser': dadosUser,
			}
			new HttpRequest()
				.Post(this.link+'solicitar/manutencao', obj)
				.then(dados=>{
					if(dados.status){
						this.show = false
						this.$bvModal.hide('modal-solicitacao-manutencao')
					}
					else{
						this.show = false
						this.toastShow('Erro, tente novamente mais tarde', 'danger')
						this.$bvModal.hide('modal-solicitacao-manutencao')
					}
				})
		},

		toastShow(msg, type, title = ''){
			this.$bvToast.toast(`${msg}`, {
				autoHideDelay: 2500,
				variant: type,
				title: title
			})
		},

		cancelButton(){
			this.$bvModal.hide('modal-solicitacao-manutencao')
		},

		modalOpen(){
			this.nomeOpcoesModalSelecionado = []
			this.opcoesModalSelecionada = []
			this.textoTextArea = ''
		},
	},

	validations:{

		opcoesModalSelecionada:{
			required,
		},

		textoTextArea:{
			required: requiredIf(function(){
				if(this.opcoesModalSelecionada == '4' && this.textoTextArea =='') {
					return true
				}
				return false
			}),
		},
	},

	mounted(){
		this.selecionaTipoSolicitacaoManutencoes()
	},
})
</script>

<style lang="scss" scoped>
.observacaoModal {
    textarea {
        overflow: auto;
        resize: vertical;
        width: 100%;
        min-height: 42px;
    }
}

.float-right {
    color: red;
}
</style>
