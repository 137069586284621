<template>
    <panelEagle id="ListagemComandos" :loading="loadingPanel">
        <div class="col-sm-12 row">
            <div class="col-sm-4 nopadding">
                <tituloPage :icon="mdiMessageTextOutline" titulo="Listagem de comandos"/>
            </div>
            <div class="col-sm-8 nopadding divDontPrint">
                <basicButtonsCadastrosEIN
                    :disabledDropdown="!Object.keys(dataTable).length"
                    linkNew ='cadastrarComandos'
                    @exportarCadastro="exportarCadastro"
                    tipoNovo='button'
                    :loading='loadingPdf'>
                </basicButtonsCadastrosEIN>
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <div class="col-sm-12 row nopadding">
            <div class="col-sm-4 nopadding divDontPrint">
                <selectAll
                    :isMultiple="true"
                    :hasSelectAll="true"
                    @changeSelect="changeRastreador"
                    :labels="[{indexDFH:'EM', description: 'Modelo de rastreador'}]"
                    nameForRadio="rastreador"
                    :optionsArray="optRastreador"/>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 nopadding">
                <tableListagem
                    @excluirCadastro="excluirCadastro"
                    @editarCadastro="editarCadastro"
                    :titles="
                        [
                            {number:'mccodigo',    name:'Código'},
                            {number:'mcdescricao', name:'Descrição'},
                            {number:'mmdescricao', name:'Modelo'},
                            {number:'mccomando',   name:'Comando'},
                        ]
                    "
                    :data="dataTable"
                />
            </div>
        </div>
    </panelEagle>
</template>
<script lang="js">
import Vue from 'vue'
// import { required } from 'vuelidate/lib/validators'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
// import { EmpresasService } from '@/Services/auth/Empresas.service'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { conectionError } from  '@/Services/Helpers/swellHeper.js'
import Swal from 'sweetalert2'
import { mdiMessageTextOutline } from '@mdi/js'
export default Vue.extend({
    name:'ListagemComandos',
    validations: {

    },
    components:{
        selectAll  : require('@/components/Atom/Select/SelectAll').default,
        panelEagle : require('@/components/Atom/Panel/PanelEagle').default,
        tituloPage : require('@/components/Atom/Header/Titulo').default,
        tableListagem : require('@/components/Atom/Table/TableListagem.vue').default,
        basicButtonsCadastrosEIN : require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
    },
    data(){
        return {
            valueRastreadores:[],
            dataTable:[],
            optRastreador:[],
            loadingPdf:[false, false, false],
            loadingPanel:false,
            mdiMessageTextOutline:mdiMessageTextOutline,
        }
    },
    methods: {
        editarCadastro(value){
            this.$router.push({name:'cadastrarComandos', params:{id:value}})
        },
        async excluirCadastro(value){
            try{
                var dados = await new HttpRequest()
                    .Post('/administrativo/cadastros/comandos/deletar/comando', {'dados':value})
                if(dados.data.retorno){
                    this.changeRastreador(this.valueRastreadores)
                }else{
                    if(dados.data.relacionado){
                        this.makeToast(dados.data.relacionado)
                    }else{
                        conectionError()
                    }
                }
            }catch(error){
                conectionError()
            }
        },
        
        makeToast(value){
            var perfil = ''
            for(const index in value){
                perfil += ` ${value[index].mcpdescricao},`
            }
            this.$bvToast.toast(`Para excluir é necessário desassociar o comando do perfil:${perfil.slice(0, -1)+'.'}`, {
                title: `Comando já associado`,
                variant: 'warning',
                solid: true
            })
        },

        async exportarCadastro(value){
            switch (value) {
                case 'pdf': this.loadingPdf = [true,  false, false]; break;
                case 'xls': this.loadingPdf = [false, true,  false]; break;
                case 'csv': this.loadingPdf = [false, false, true];  break;
                default: this.loadingPdf = [false, false, false]; break;
            }
            var exporta = {
                tipo:value,
                arraydados:this.dataTable
            }
            var dados = await new HttpRequest()
                .Post('/administrativo/cadastros/comandos/exportar', {'dados':exporta})
            var root =  process.env.VUE_APP_ROOT
            window.open(root+'/'+dados.data.retorno);
            this.loadingPdf = [false, false, false]
        },

        changeRastreador(value){
            this.valueRastreadores = value
            this.loadingPanel = true
            new HttpRequest()
                .Post('/administrativo/cadastros/comandos/gerar/tabela', value)
                .then(({ data }) => {
                    this.dataTable = data
                }).catch(() => {
                    conectionError()
                }).finally(() => {
                    this.loadingPanel = false
                })
        },
    },
    async mounted(){
        this.loadingPanel = true
        var dados = await new FiltrosService().dados_filtros(this.empresasArray, ['MO'])
        this.optRastreador = dados['MO']
        this.loadingPanel = false
    },
})
</script>
<style lang="scss">
    #ListagemComandos{
        
    }
</style>