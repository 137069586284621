<template>
    <panelEagle id="timeline"
        :loading="loadingPanel">
        <div class="col-sm-12 nopadding ">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiClockFast" titulo="Timeline"></tituloPage>
                </div>
                <div class="col-sm-6 divButtonsBasic divDontPrint">
                    <basicButtonsRelatoriosEIG 
                        @exportarRelatorio ="exportarRelatorio" 
                        @gerarRelatorio    ="gerarRelatorio"
                        :loading           ='loadingExportar'
                        :disabled          ="$v.veiculosSelecionados.$invalid"
                        :disabledDropdown  ='$v.dadosRelatorio.$invalid'>
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12"><hr></div>
                    <div class="col-sm-12 justpaddingbottom row">
                        <div class="col-sm-4 nopadding">
                            <inputRangeWithLimit 
                                name='dataTimeline'
                                @changeInput ="changeData" 
                            />
                        </div>
                        <div class="col-sm-4 nopadding">
                            <selectAll 
                                :isMultiple   ="true" 
                                nameForRadio  ="radiosEmpresas" 
                                :labels       ="labelsSelectEmpresa" 
                                :optionsArray ="optionsSelectEmpresa" 
                                firstSelected ="E" 
                                :hasSelectAll ="true"
                                :selected     ="selectedEmpresa"
                                @changeSelect ="changeEmpresas" 
                            />
                        </div>
                        <div class="col-sm-4 nopadding">
                            <selectAll 
                                nameForRadio  ="radiosVeiculos" 
                                :labels       ="labelsSelectVeiculos" 
                                :optionsArray ="optionsSelectVGV" 
                                firstSelected ="V" 
                                ref           ="VGV"
                                :isMultiple   ="true" 
                                :hasSelectAll ="true"
                                :disabled     ='$v.empresasSelecionadas.$invalid'
                                @changeCheck  ="changeCheckVeiculos" 
                                @changeSelect ="changeVeiculo" 
                                :loading='carregando'
                            />
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
            <div class="col-sm-12 divHrDeBaixo"><hr></div>
        </div>
        <tableRelatorio>
            <slot slot="thead">
                <template>
                    <tr class="cabecalhoHorario">
                        <th colspan="7"></th>
                        <th colspan="12">HORÁRIO DA TIMELINE</th>
                    </tr>
                    <tr class="cabecalho">
                        <th>Data</th>
                        <th>Empresa</th>
                        <th>Placa</th>
                        <th>ID</th>
                        <th>Cliente</th>
                        <th>Origem</th>
                        <th>Destino Final</th>
                        <th colspan="2">Agend. Carreg</th>
                        <th colspan="2">Agend. Venda</th>
                        <th colspan="2">Carregado</th>
                        <th colspan="2">Faturado</th>
                        <th colspan="2">Iniciado</th>
                        <th colspan="2">Finalizado</th>
                    </tr>
                </template>
            </slot>
            <slot slot="tbody">
                <statusInformation 
                    v-if="dadosRelatorio.length == 0"
                    typeBar="tr" 
                    :colspanForTd=19
                    :statusBar="statusbar">
                </statusInformation>
                <template v-else>
                    <tr v-for="(dados, d) in dadosRelatorio"
                        :key="d"
                        class="corpoTabela">
                        <td>{{dados.rodata}}</td>
                        <td id="empresa">{{dados.empresa}}</td>
                        <td>{{dados.placa}}</td>
                        <td>{{dados.id}}</td>
                        <td id="cliente">{{dados.roobservacao}}</td>
                        <td id="origemDestino">{{dados.origem}}</td>
                        <td id="origemDestino">{{dados.destino}}</td>
                        <!-- Agendado Carregamento -->
                        <td>{{verificaEspaco(dados.historico, 7, 'data')}}</td>
                        <td>{{verificaEspaco(dados.historico, 7, 'hora')}}</td>
                        <!-- Agendado Venda -->
                        <td>{{verificaEspaco(dados.historico, 2, 'data')}}</td>
                        <td>{{verificaEspaco(dados.historico, 2, 'hora')}}</td>
                        <!-- Carregado -->
                        <td>{{verificaEspaco(dados.historico, 6, 'data')}}</td>
                        <td>{{verificaEspaco(dados.historico, 6, 'hora')}}</td>
                        <!-- Faturado -->
                        <td>{{verificaEspaco(dados.historico, 3, 'data')}}</td>
                        <td>{{verificaEspaco(dados.historico, 3, 'hora')}}</td>
                        <!-- Iniciado -->
                        <td>{{verificaEspaco(dados.historico, 4, 'data')}}</td>
                        <td>{{verificaEspaco(dados.historico, 4, 'hora')}}</td>
                        <!-- Finalizado -->
                        <td>{{verificaEspaco(dados.historico, 5, 'data')}}</td>
                        <td>{{verificaEspaco(dados.historico, 5, 'hora')}}</td>
                    </tr>
                </template>
            </slot>
        </tableRelatorio>
    </panelEagle>
</template>

<script>
import {mdiClockFast } from '@mdi/js'
import Vue               from 'vue'
import {required}        from 'vuelidate/lib/validators'
import {EmpresasService} from '../../../Services/auth/Empresas.service'
import {HttpRequest}     from '../../../Services/auth/HttpRequest.Service'
import {FiltrosService}  from '../../../Services/filtros/filtros.Service'
import { mapGetters } from 'vuex'

export default Vue.extend({
	name:'timeLine',
	components:{
		'panelEagle'                : require('@/components/Atom/Panel/PanelEagle').default,
		'tituloPage'                : require('@/components/Atom/Header/Titulo').default,
		'basicButtonsRelatoriosEIG' : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
		'selectAll'                 : require('@/components/Atom/Select/SelectAll').default,
		'slideUpAndDown'            : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
		'statusInformation'         : require('@/components/Atom/StatusInformation/StatusInformation').default,
		'tableRelatorio'            : require('@/components/Atom/Table/TableRelatorio').default,
		'inputRangeWithLimit'       : require('@/components/Atom/Datas/InputRangeWithLimit').default,
	},

	validations:{
		empresasSelecionadas:{
			required
		},

		veiculosSelecionados:{
			required
		},

		dadosRelatorio:{
			required
		}
	},

	data(){
		return {
			mdiClockFast:mdiClockFast,
			optionsSelectEmpresa: new EmpresasService().Get(),
			link: '/eagle/cargo/relatorios/timeline/',
			optionsSelectVeiculos : [],
			optionsSelectVGV      : [],
			arrayOptionsSelects   : [],
			labelsSelectEmpresa:[
				{indexDFH: 'E', description: 'Empresa*'},
			],
			labelsSelectVeiculos:[
				{indexDFH: 'V', description: 'Veículos*'},
				{indexDFH: 'GV', description: 'Grupos*'},
			],
			empresasSelecionadas   : [],
			veiculosSelecionados   : [],
			dataSelecionada        : [],
			selectedRadiosVeiculos : [],
			dadosRelatorio         : [],
			statusbar              : 'info',
			loadingPanel           : false,
			loadingExportar        : [false, false, false],
			carregando             : false,
            selectedEmpresa        : [],
		}
	},
    
	methods: {
        ...mapGetters(['getMaster']),

		changeEmpresas(empresas){
			this.empresasSelecionadas = empresas
			this.dados_filtros()
			this.$refs.VGV.clearAll()
			this.dadosRelatorio = []
		},

		changeVeiculo(veiculo){
			this.veiculosSelecionados = veiculo
			this.dadosRelatorio = []
		},

		changeData(data){
			this.dataSelecionada = data
			this.dadosRelatorio  = []
		},

		changeCheckVeiculos(event){
			this.veiculosTipo = event
			this.selectedRadiosVeiculos = event
            if(Array.isArray(this.arrayOptionsSelects[event])) {
                this.optionsSelectVGV = this.arrayOptionsSelects[event]
            }
		},

		async dados_filtros(){
			this.carregando = true
			let dados = await new FiltrosService()
                .dados_filtros(this.empresasSelecionadas, ['V', 'GV'])
			this.arrayOptionsSelects = dados
			this.changeCheckVeiculos(this.selectedRadiosVeiculos)
			this.carregando = false
		},

		gerarRelatorio(){
			this.loadingPanel = true
            let body = {
                'data': this.dataSelecionada, 
                'clientes': this.empresasSelecionadas, 
                'veiculosGrupos': this.veiculosSelecionados, 
                'tipo': this.veiculosTipo,
            }
			new HttpRequest()
				.Post(this.link+'gerar/relatorio', body)
				.then(({data, status, code})=>{
					if(status && code === 200){
						if(data.dados.length == 0){
							this.statusbar = 'error'
						} else {
							this.dadosRelatorio = data.dados
						}
					} else {
						this.statusbar = 'error2'
					}
				})
                .catch((e)=>{
                    this.statusbar = 'error2'
                })
                .finally(()=>{
                    this.loadingPanel = false
                })
		},

		verificaEspaco(dados, local, tipo){
			var retorno = dados.find(element => element.evento == local)
			if(!retorno){
				return ''
			} else {
				if(tipo == 'data'){
					return retorno.data
				} else {
					return retorno.hora
				}
			}
		},

		async exportarRelatorio(type){
			this.setLoading(type)
            let body = {
                'arrayDados': this.dadosRelatorio,
                'data': this.dataSelecionada,
                'tipo': type,
            }
			var dados = await new HttpRequest().Post(this.link+'exportar', body)
            if(dados.status && dados.data) {
                let root = process.env.VUE_APP_ROOT
                window.open(root+'/'+dados.data.local)
            }
			this.setLoading()
		},

		setLoading(type){
			this.loadingExportar = [type=='pdf', type=='xls', type=='csv']
		},

	},

    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optionsSelectEmpresa
        }
    }
})
</script>

<style lang="scss">
    #timeline{
        .cabecalhoHorario{
            th{
                top:0px;
                min-width: 80px;
                vertical-align: middle !important;
                text-align: center !important;
            }
        }
        .cabecalho{
            th{
                top: 29px !important;
                padding-left: 4px !important;
                padding-right: 4px !important;
                text-align: center !important;
            }
        }
        .corpoTabela{
            td{
                vertical-align: middle;
                padding-left: 4px;
                padding-right: 4px;
                border: 1px solid #e6e6e6;
                text-align: center !important;
            }
        }
        #origemDestino{
            width: 25%;
        }
        #empresa{
            width: 15%;
        }
        #cliente{
            width: 8%;
        }
    }    
</style>