<template>
    <panelEagle id="Abastecimento" :loading='gerandoRel'>
        <div class="col-sm-12 nopadding ">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage
					:icon='mdiGasStation'
					titulo='Abastecimentos'></tituloPage>
                </div>
                <div class=" col-sm-6 col-md-6 col-lg-6 divDontPrint">
                    <botaoRelatorio
					:disabled          ='$v.$invalid'
					:loading           ='loadingExporta'
					@gerarRelatorio    ='gerarRelatorio'
					@exportarRelatorio ='exportarRelatorio'
					:disabledDropdown  ='dadosTabela.length == 0 ? true : false' />
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12"><hr></div>
                    <div class='col-sm-12 row nopadding'>
                        <div class="col-lg-3 col-md-6 col-sm-12 nopadding">
                            <inputRangeWithLimit
                                opens        ='rigth'
                                :value       ='valueData'
                                name         ='botaoData'
                                titulo        ='Intervalo*'
                                @changeInput ='changeData'/>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 nopadding">
                            <botaoSelectAll
                                nameForRadio  ='empresas'
                                :isMultiple   ='true'
                                :optionsArray ='optEmpresas'
                                :labels       ='labelsEmp'
                                :hasSelectAll ='true'
                                :extraClass='{
									"border border-danger":$v.valueEmpresa.$anyError
								}'
                                @close='$v.valueEmpresa.$touch()'
                                :selected="selectedEmpresa"
                                @changeSelect ='changeEmpresa'/>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 nopadding">
                            <botaoSelectAll
                                ref="refVeiculos"
                                nameForRadio  ='veiculos'
                                @changeCheck  ='checkVeiculo'
                                :isMultiple   ='true'
                                :labels       ='labelsVei'
                                :optionsArray ='optionsVeiculos'
                                :hasSelectAll ='true'
                                firstSelected ='V'
                                :disabled     ="$v.valueEmpresa.$invalid"
                                :loading      ='carregando'
                                @changeSelect ='changeSelectVeiculos'/>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12 nopadding">
                            <botaoSelectAll
                                ref="refCombustivel"
                                nameForRadio  ='Tipos de combustíveis '
                                :isMultiple   ='true'
                                :optionsArray ='optCombustivel'
                                :labels       ='labelsTip'
                                :hasSelectAll ='true'
                                :disabled     ="$v.valueEmpresa.$invalid"
                                :loading      ='carregando'
                                @changeSelect ='changeTipoCombusteivel'/>
                        </div>
                        <div
						class="alinhar-left mt-3 col-sm-2 nopadding divDontPrint no-wrap">
                            <b-form-checkbox
                            class=""
                            id="checkbox-1"
                            v-model="checkbox"
                            name="checkbox-1"
                            :value="false"
                            :unchecked-value="true">
                                Confrontar com Ticket Log
                            </b-form-checkbox>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
            <div class="col-sm-12 col-md-12"><hr></div>
        </div>
        <div class="col-12 tableFixHeadAbast tamanho-max-tabela">
            <table class="col-sm-12 nopadding">
                <thead class="fixado-na-tela">
                    <tr v-show="!checkbox">
                        <th class="borda-divisa text-center" colspan="11">
                            <div class="nopadding col-sm-12 text-center ">
                                Ticket Log
                            </div>
                        </th>
                        <th class="borda-divisa text-center" colspan="5">
                            <div style="" class="nopadding col-sm-12 text-center">
                                Eagle Track
                            </div>
                        </th>
                    </tr>
                    <!-- ticket log -->
                    <tr v-show="!checkbox" class="borda-divisa text-center">
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">
							Data/Hora
						</th>
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">
							Transação
						</th>
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">
							NºCartão
						</th> 
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">
							Estabelecimento
						</th>
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">
							Cidade/UF
						</th>
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">
							Hodometro/Horimetro
						</th>
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">
							Tipo do combustível
						</th>
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">
							Litros
						</th>
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">Preço P/Litro</th>
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">Total</th>
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">Média</th>
                        <!-- eagle track -->
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">Data/Hora</th>
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">Coordenadas</th>
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">Endereço</th>
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">
							Hodometro/Horimetro
						</th>
                        <th class="text-center pl-0 pr-0 pt-1 pb-2"></th>
                    </tr>
                    <!-- padrao -->
                    <tr class="text-left borda-divisa" v-show="checkbox">
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">Data/Hora</th>
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">Contador</th>
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">Quantidade</th>
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">Preço</th>
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">Valor total</th>
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">Média</th>
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">Local</th>
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">Colaborador</th>
                        <th class="text-center pl-0 pr-0 pt-1 pb-2">Observações</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="dadosTabela.length == 0">
                        <statusInformation
                            colspanForTd="16"
                            typeBar="tr"
                            :statusBar="statusBar"
                        />
                    </template>
                    <template v-else v-for="(dados, index) in dadosTabela">
                        <template v-if="!checkbox">
                            <tr :key='index'>
                                <td colspan="16" class="fundo-branco">
                                    <span class="badginha text-center">
                                        {{index}} 
                                    </span>
                                </td>
                            </tr>
                            <template v-for="valores in dados">
                                <tr :key="valores.codigo">
                                    <td class="borda-td text-center">{{valores.dataHoraAT}}</td>
                                    <td class="borda-td text-center">{{valores.transacao}}</td> 
                                    <td class="borda-td text-center">{{valores.nCartao}}</td>
                                    <td class="borda-td text-center">{{valores.estabelecimento}}</td>
                                    <td class="borda-td text-center">{{valores.cidadeUF}}</td>
                                    <td class="borda-td text-center">{{valores.hodoHoriAT}}</td>
                                    <td class="borda-td text-center">{{valores.tipoComb}}</td>
                                    <td class="borda-td text-center">
										{{Number(valores.litros).toFixed(2)}}L 
									</td>
                                    <td class="borda-td text-center">
										R${{(valores.precoLitro).toFixed(2)}}
									</td>
                                    <td class="borda-td text-center">{{valores.total}}</td>
                                    <td class="borda-td text-center">{{valores.media}}</td>
                                    <td class="borda-td text-center">{{valores.dataHoraIG}}</td>
                                    <td class="borda-td text-center">{{valores.coords}}</td>
                                    <td class="borda-td text-center">{{valores.enderecoIG}}</td>
                                    <td class="borda-td text-center">{{valores.hodoHoriIG}}</td>
                                    <td class="borda-td text-center" style="color:#2196f3;">
                                        <div @click="localizar(valores.coords)" 
                                            class="col-sm-12 nopadding cursor-pointer" 
                                            v-show="valores.coords">
                                            <baseIcon :icon="mdiTarget" size="15"/>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </template>
                        <template v-else-if="checkbox">
                            <tr :key="'badgePlaca'+index">
                                <td colspan="15" class="fundo-branco">
                                    <span class="badginha text-center">
                                        {{index}}
                                    </span>
                                </td>
                            </tr>
                            <template v-for="(valores, v) in dados">
                                <tr v-if="v != 0"
                                    :key="'badgeTipoCombustivel'+v+index">
                                    <td colspan="9" class="fundo-branco">
                                        <span class="badginhainha text-center">
                                            {{v}}
                                        </span>
                                    </td>
                                </tr>
								<template	v-for="(intDados, di) in valores">
									<tr v-show="v != 0"
										:key="'dadosTabela_'+di+v+index"
									class="corpoTabela">
										<td class="borda-td text-center">{{intDados.data}}</td>
										<td class="borda-td text-center">
											{{intDados.abhodometro}} 
											{{intDados.vebasecalculomedia==2?' Hr':' Km'}}
										</td>
										<td class="borda-td text-center" >
											{{Number(intDados.abquantidade).toFixed(2)}} L
										</td>
										<td class="borda-td text-center" style="width:5%;">
											R$ {{Number(intDados.abprecolitro).toFixed(2)}} 
										</td>
										<td class="borda-td text-center" style="width:7%">
											R$ {{Number(intDados.abvalortotal).toFixed(2)}} 
										</td>
										<td class="borda-td text-center">
											{{resolveMedia(intDados)}}
										</td>
										<td class="borda-td text-center">
											{{intDados.podescricao}}
										</td>
										<td class="borda-td text-center">
											{{intDados.mtnome}}
										</td>
										<td class="borda-td text-center">
											{{intDados.abobservacoes}}
										</td>
									</tr>   
                                    <!-- Totalizador Tipo -->
									<tr
									:key="'dadosTabela_2'+di+v+index"
									v-if='di+1 === valores.length'>
										<td
										colspan="9"
										class="badginha-copia text-center">
											Valores de {{v}} | 
											{{resumoTipo(valores)}}
										</td>
									</tr>
								</template>
                            </template>
                            <!-- totalizador final -->
                            <tr :key='index+"a"'>
                                <td colspan="9" class="badginha-copia text-center">
                                    <span>
                                        Valores Totais | 
                                    </span>
                                    <span>
                                        Abastecimento: {{dados[0].totalAbastecimento}} |
                                    </span>
                                    <span>
                                        Total de litros: 
										{{dados[0].totalLitros.toFixed(2)}} L |
                                    </span>
                                    <span>
                                        Valor total: R$ 
										{{(dados[0].totalValor).toFixed(2)}} |
                                    </span>
                                    <span>
                                        {{dados[0].baseMedia}}
                                        Média: {{dados[0].baseMedia == 2 ?
                                            (dados[0].totalMedia).toFixed(2)+' L/h' : 
                                            (dados[0].totalMedia).toFixed(2)+' Km/l'}}
                                    </span>
                                </td>
                            </tr>
                        </template>
                    </template>
                </tbody>
            </table>
        </div>
    </panelEagle>
</template>
<script>
import Vue  from 'vue'
import {EmpresasService} from '../../../Services/auth/Empresas.service'
import {HttpRequest}     from '../../../Services/auth/HttpRequest.Service'
import { required } from 'vuelidate/lib/validators'
import { mdiGasStation, mdiTarget } from '@mdi/js'
import { conectionError } from '@/Services/Helpers/swellHeper.js'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { mapGetters } from 'vuex'

export default Vue.extend({
	name:'relatorioAbastescimentos',
	components:{
		'panelEagle'          : require('@/components/Atom/Panel/PanelEagle').default,
		'tituloPage'          : require('@/components/Atom/Header/Titulo').default,
		'botaoRelatorio'      : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
		'inputRangeWithLimit' : require('@/components/Atom/Datas/InputRangeWithLimit').default,
		'botaoSelectAll'      : require('@/components/Atom/Select/SelectAll').default,
		'slideUpAndDown'      : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
		'statusInformation'   : require('@/components/Atom/StatusInformation/StatusInformation').default,
		baseIcon              : require('@/components/Atom/Icon/BaseIcon').default,
	},
	validations:{
		valueEmpresa: { required },
		valueVeiculo: { required }
	},
	data(){
		return {
			mdiGasStation:mdiGasStation,
			mdiTarget:mdiTarget,
			refCombustivel:'',
			gerandoRel:false,
			carregando: false,
			refVeiculos:'',
			statusBar:'info',
			info:true,
			dadosTabela:[],
			//Essa checkbox é do ticked log, convenção de nomes de variavel: 2/10
			checkbox:true,
			// :( to triste, n gostaram da minha variavel
			loadingExporta:[false, false, false],
			labelsEmp:[{indexDFH: 'EM', description: 'Empresas*'}],
			labelsTip:[{indexDFH: 'CT', description: 'Tipos de combustíveis'}],
			buscandoInfo:false,
			labelsVei:[
				{ indexDFH    : 'V', description : 'Veículos*' },
				{ indexDFH    : 'GV',description : 'Grupos*' }
			],
			optCombustivel : [],
			optEmpresas    : new EmpresasService().Get(),
			valueData      : '',
			optVeiculos    : [],
			optGVeiculos   : [],
			valueEmpresa   : [],
			valueVeiculo   : [],
			checkTipoVeiculo : [],
			valueTipoCombustivel:[],
			optionsVeiculos : [],
			selectedEmpresa : [],
		}
	},
	watch:{
		checkbox:function(){
			this.dadosTabela = []
			this.valueTipoCombustivel = []
			this.$refs.refCombustivel.$data.selectedItens = []
			this.statusBar = 'info'
			this.info = true
			this.changeEmpresa(this.valueEmpresa)
		}
	},
	methods:{
		/**
		 * @param {object[]} valores todos os abastecimentos daquele tipo de combustivel
		 * @description soma as quantidades e valores do tipo de combustível p/ mostrar
		 * o resumo pro user
		 * @returns {string} valores formatados e em ordem
		 * @author Gui 🧟
		 */
		resumoTipo(valores){
			var resumo = valores.reduce((pv, cv, arr)=>{
				if(cv?.abmedia){
					pv.abmedia += Number(cv.abmedia)
					pv.qtdMedia++
				}
				pv.totalQtd += Number(cv.abquantidade)
				pv.totalP += Number(cv.abvalortotal)
				pv.vebasecalculomedia = cv.vebasecalculomedia
				return pv
			}, {
				totalQtd:0,
				totalP:0,
				abmedia:0,
				qtdMedia: 0,
				vebasecalculomedia: '',
			})
			var base = 'L/h'
			if(resumo.vebasecalculomedia === 1)	
				base = 'Km/l'
			var media = (resumo.abmedia / resumo.qtdMedia)
			media  = media.toFixed(2)
			resumo.totalQtd = resumo.totalQtd.toFixed(2)
			resumo.totalP = resumo.totalP.toFixed(2)
			if(media === 'NaN')
				media = 0;
			return `Abastecimentos: ${valores.length} | Total de Litros: ${resumo.totalQtd} L | Total valor: R$ ${resumo.totalP} | Média: ${media} ${base}`
		},
		...mapGetters(['getMaster']),

		/**
         * @param {object} obj
         * @param {number} obj.abmedia - calculo da média já salvo no bc
         * @param {number} obj.vebasecalculomedia - usado para decidir
         * unidades de medida
         * @description decide a string da média, se ela existe 
         * e qual únidade de medida deve ser utilizada.
		 * @author Gui 🧟
         */
		resolveMedia({abmedia, vebasecalculomedia }){
			if(abmedia){
				abmedia = Math.abs(Number(abmedia)).toFixed(2)
				if(vebasecalculomedia === 2)
					return `${abmedia} L/h`
				else
					return `${abmedia} Km/l`
			}else
				return ''
		},

		localizar(coord){
			window.open('http://maps.google.com/maps?q=loc:'+coord);
		},

		changeTipoCombusteivel(value){
			this.dadosTabela = []
			if(this.checkbox){
				this.valueTipoCombustivel = value
			}else{
				this.valueTipoCombustivel = []
				value.forEach(index => {
					var description = this.optCombustivel[index]['description']
					this.valueTipoCombustivel[index] = description
				});
			}
		},

		async gerarRelatorio(){
			this.dadosTabela = []
			this.info = true
			var obj = { 
				'data'            : this.valueData,
				'empresa'         : this.valueEmpresa,
				'veiculos'        : this.valueVeiculo,
				'tipoVeiculo'     : this.checkTipoVeiculo,
				'tipoCombustivel' : this.valueTipoCombustivel,
				'ticketLog'       : !this.checkbox,
			}
			this.gerandoRel = true
			try {
				var dados = await new HttpRequest()
					.Post('/abastecimento/menu/relatorio/tabela', {'dados':obj})
				if(dados.data.length === 0){
					this.info = true
					this.statusBar = 'error'
					setTimeout(() => {
						this.statusBar = 'info'
					}, 5000);
				}else{
					this.statusBar = 'info'
					this.info = false
					this.dadosTabela = dados.data
				}
			} catch {
				conectionError()
			}
            
			this.gerandoRel = false
		},

		async exportarRelatorio(value){
			if(this.loadingExporta.find(v=> v === true)){
				return
			}
			switch(value){
				case 'pdf':
					this.loadingExporta = [true, false, false]
					break;
				case 'xls':
					this.loadingExporta = [false, true, false]
					break;
				default:
					this.loadingExporta = [false, false, true]
					break;
			}
			var obj = {
				'data'            : this.valueData,
				'empresa'         : this.valueEmpresa,
				'veiculos'        : this.valueVeiculo,
				'tipoVeiculo'     : this.checkTipoVeiculo,
				'tipoExporta'     : value,
				'tipoCombustivel' : this.valueTipoCombustivel,
				'ticketLog'       : this.checkbox,
			}
			var root =  process.env.VUE_APP_ROOT;
			var dados = await new HttpRequest()
				.Post('/abastecimento/menu/relatorio/exportar', {'dados':obj})
            if(dados.status) window.open(root+'/'+dados.data.retorno);
			this.loadingExporta = [false, false, false]
		},

		changeData(value){
			this.dadosTabela = []
			this.valueData = value
		},

		/**
         * Quando muda a empresa selecionada já limpa o valor
         * de todos os campos, garantindo acesso apenas as informações
         * da empresa selecionada.
         * Faz a request pelo filtrosService para obter os dados
         * dos seletores
         */
		changeEmpresa(value){
			this.dadosTabela = []
			this.$refs.refVeiculos.clearAll()
			this.$refs.refCombustivel.clearAll()
			this.valueTipoCombustivel = []
			this.valueEmpresa = value
			this.optCombustivel = []
			this.optVeiculos    = []
			this.optGVeiculos   = []
			var tipoCombustivel = this.checkbox ? 'CT' : 'TKL'
			if(value.length){
				this.carregando = true
				new FiltrosService().dados_filtros(value, [tipoCombustivel, 'V', 'GV'])
					.then((data)=>{
						this.optCombustivel = data[tipoCombustivel]
						this.optVeiculos = data.V
						this.optGVeiculos = data.GV
						this.carregando = false
						this.checkVeiculo(this.checkTipoVeiculo)
					})
			}
		},

		checkVeiculo(value){
			this.checkTipoVeiculo = value
			if(this.checkTipoVeiculo === 'V'){
				this.optionsVeiculos = this.optVeiculos
			} else {
				this.optionsVeiculos = this.optGVeiculos
			}
		},

		changeSelectVeiculos(value){
			this.dadosTabela = []
			this.valueVeiculo = value
		}
	},

	mounted() {
		if(!this.getMaster()){
			this.selectedEmpresa = this.optEmpresas
		}
	}
})
</script>
<style lang='scss'>
#Abastecimento{
    #tabelaRelAbast{        
        // #tr-primeira{
        //     z-index: 10 !important;
        //     transform: translateY(1px);
        // }
        #tr-segunda{
            transform: translateY(-1px);
            position: relative;
            z-index: 2 !important;
        }
        thead{
            #th-ticketlog{
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-position: bottom -22px right 0px;
                background-image: linear-gradient(#a097976c);
            }

            #th-eagletrack{
                background-repeat: no-repeat;
                background-size: 1px;
                background-position: 0% 100%;
                background-image: linear-gradient(#a097976c);
            }

            #th-eagleNovo{
                background-repeat: no-repeat;
                background-size: 1px;
                background-position: 0% 100%;
                background-image: linear-gradient(#a097976c);
            }
            #div-eagletrack{
                background-repeat: no-repeat;
                background-size: 100% 100%;
                background-position: bottom -17px right 0px;
                background-image: linear-gradient(#a097976c);
            }
            #th-ticketlogNovo{
            }
        }
    }

    .tamanho-max-tabela{
        max-height: 700px;
        overflow-y: auto;
        overflow-x: auto;
    }

    .tableFixHeadAbast{
        overflow-y: auto;
        text-align: left;

        table{
            margin-bottom: 0;
            padding-right: 10px;
            padding-left: 10px;
            max-height: 100px  !important;

            .borda-divisa{
                border: 1px solid #a097976c;
            }
            
            .borda-divisa-left{
                border-left: 1px solid #a097976c;
            }

            .borda-divisa-right{
                border-right: 1px solid #a097976c;
            }

            .tbody{
                overflow-y: hidden;
                max-height: 100px;
            }

            // @for $i from 1 through 20 {
            //     td:first-child:nth-last-child( #{$i} ),
            //     td:first-child:nth-last-child( #{$i} ) ~ 
            //     td{
            //         width: calc(100% / #{$i} - 10px);
            //         min-width: 120px;
            //     }
            // }

            // @for $i from 1 through 20 {
            //     th:first-child:nth-last-child( #{$i} ),
            //     th:first-child:nth-last-child( #{$i} ) ~ 
            //     th{
            //         width: calc(100% / #{$i} - 10px);
            //         min-width: 120px;
            //         // max-width: 10px;
            //     }
            // }

            .fixado-na-tela{
                position: sticky;
                position: -webkit-sticky;
                top: -1px;
                z-index: 2;
                th{
                    outline: 1px solid rgba(160, 151, 151, 0.2);
                    border:none; 
                    outline-offset: -1px;
                }
            }

            thead{
                th{
                    z-index: 1;
                    background: #f5f5f5;
                    color: #514e4e;
                    font-weight: bold;
                    font-size: 12px;
                }
            }

            tr{
                border-bottom: 1px solid #e5e5e5;
                background-color: #efefef;
                td{
                    padding-top: 0.15rem;
                    padding-bottom: 0.15rem;
                    font-size: 12px;
                }
                th{
                    padding-top: 0.15rem;
                    padding-bottom: 0.15rem;
                }
            }
        }
        
    }
    
    @media screen and (max-height: 1300px) {
        .tableFixHeadAbast {
            max-height: 1140px;
        }
    }
    @media screen and (max-height: 1250px) {
        .tableFixHeadAbast {
            max-height: 1090px;
        }
    }
    @media screen and (max-height: 1200px) {
        .tableFixHeadAbast {
            max-height: 1040px;
        }
    }
    @media screen and (max-height: 1150px) {
        .tableFixHeadAbast {
            max-height: 990px;
        }
    }
    @media screen and (max-height: 1100px) {
        .tableFixHeadAbast {
            max-height: 940px;
        }
    }
    @media screen and (max-height: 1050px) {
        .tableFixHeadAbast {
            max-height: 890px;
        }
    }
    @media screen and (max-height: 1000px) {
        .tableFixHeadAbast {
            max-height: 840px;
        }
    }
    @media screen and (max-height: 950px) {
        .tableFixHeadAbast {
            max-height: 790px;
        }
    }
    @media screen and (max-height: 900px) {
        .tableFixHeadAbast {
            max-height: 740px;
        }
    }
    @media screen and (max-height: 850px) {
        .tableFixHeadAbast {
            max-height: 690px;
        }
    }
    @media screen and (max-height: 768px) {
        .tableFixHeadAbast {
            max-height: 610px;
        }
    }
    @media screen and (max-height: 720px) {
        .tableFixHeadAbast {
            max-height: 560px;
        }
    }
    @media screen and (min-height: 1300.1px) {
        .tableFixHeadAbast {
            max-height: 1170px;
        }
    }

    .borda-td{
        padding: 3px;
        border: 1px solid #ced0d3 !important;
    }

    .confronto-eagle{
        padding-bottom: 10px !important;
        font-size: 13px!important;
    }

    .confronto-tiket-log{
        padding-bottom: 10px !important;
        font-size: 13px!important;
    }

    .tamanho{
        width: 10% !important;
    }
    .sem-fundo{
        background-color: #FFF !important;
    }
    .fundo-branco{
        padding: 0px !important;
        margin: 0px !important;
        height: 60px !important;
        background-color: rgb(251, 251, 251) !important;
    }
    .badginha-copia{
        background-color: rgb(184, 181, 181);
        color: black;
    }
    .alinhar-left{
        margin-left: .7% !important;
    }
    .tamanho-th{
        font-size: 1px !important;
    }
    .borda-lado{
        border-left: 1px solid #dbd9d9 ;
        border-collapse: collapse;
    }
    .badginha{
        margin-top: 20px !important;
        float: left;
        min-width: 30% !important;
        padding: 10px !important;
        font-weight: 500!important;
        color: #fff !important;
        background-color: #777;
        margin-right: 100% !important;
    }
    .badginhainha{
        margin-top: 20px !important;
        // margin-left: 15px;
        float: left;
        min-width: 20% !important;
        padding: 7px !important;
        font-weight: 500!important;
        color: #fff !important;
        background-color: #a1a1a1;
    }
    .primeiro-th{
        font-size: 13px!important;
    }
    .margin-top-10{
        margin-top: 10px !important;
    }
    .corpoTabela{
        td{
            vertical-align: middle !important;
        }
    }
}
</style>