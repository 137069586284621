<template>
    <panelEagle id="integracaoView" :loading="loadingPanel">
        <div class="col-sm-12 nopadding divDontPrint">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage 
                        :icon="mdiCogs" 
                        titulo="Integração">
                    </tituloPage>
                </div>
                <div align="right" class="col-sm-6 divButtonsBasic">
                    <simpleButton
                        :disabled="!selectedEmpresas.length"
                        @gerarGTFS="gerarGTFS" 
                        event="gerarGTFS" 
                        type="blue" 
                        :icon="mdiFileExportOutline" 
                        text="GTFS" 
                        title="GTFS">
                    </simpleButton>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12"><hr></div>
                    <div class="col-sm-12 justpaddingbottom row">
                        <div class="col-sm-4 nopadding">
                            <selectAll 
                                @changeSelect="changeEmpresa" 
                                :isMultiple="true" 
                                nameForRadio="empresasCancelamento" 
                                :hasSelectAll="true" 
                                :labels="[{indexDFH: 'EM', description: 'Empresas*'}]" 
                                :optionsArray="optionsArrayEmpresas" 
                                :selected="selectedEmpresa"
                                firstSelected="EM">
                            </selectAll>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
            <div class="col-sm-12 divHrDeBaixo"><hr></div>
        </div>
        <tableRelatorio :htmlRel="htmlValidacao">
             <slot slot="thead">
                <tr>
                    <th colspan="12">Alertas</th>
                </tr>
            </slot>
            <slot slot="tbody">
                <br>
                <!-- ele ta vindo por string, pq tem umas tags html que vem junto com dados -->
                <!-- e quem fez isso n mudou o retorno das tags, elas vem junto no texto e n achei um jeito de mudar isso no vue -->
                <!-- bom dia -->
                <!-- <template v-for="(dados, index) in dadosValidacao">
                    <tr :key="index">
                        <td class="alertNameArquivo" colspan="12">{{index}}</td>
                    </tr>
                    <template v-for="(valores, i) in dados">
                        <template v-if="valores.length > 0">
                            <tr :key="i+''+valores">
                                <td class="alertNameEmpresa" colspan="12">{{i}}</td>
                            </tr>
                            <template v-for="(val, ind) in valores">
                                <template v-if="val[ind].length > 0">
                                    <tr :key="ind+''+val">
                                        <td class="alertAlertMensagem" colspan="12">- {{dadosValidacao[index][i][ind]}}</td>
                                    </tr>
                                </template>
                            </template>
                        </template>
                    </template>
                </template>  -->
                <statusInformation :statusBar="statusBar"></statusInformation>
            </slot>
            <template>
            </template>
        </tableRelatorio>
    </panelEagle>
</template>

<script>
import Vue from 'vue'
import{mdiCogs, mdiFileExportOutline } from '@mdi/js'
import {EmpresasService} from '../../../Services/auth/Empresas.service'
import {HttpRequest} from '../../../Services/auth/HttpRequest.Service'
import { conectionError } from '@/Services/Helpers/swellHeper'
import { mapGetters } from 'vuex'

export default Vue.extend({
	components:{
		'panelEagle': require('../../../components/Atom/Panel/PanelEagle').default,
		'tituloPage': require('../../../components/Atom/Header/Titulo').default,
		'simpleButton': require('../../../components/Atom/Buttons/SimpleButton').default,
		'slideUpAndDown': require('../../../components/Atom/SlideUpAndDown/SlideUpAndDown').default,
		'selectAll': require('../../../components/Atom/Select/SelectAll').default,
		'tableRelatorio': require('../../../components/Atom/Table/TableRelatorio').default,
		'statusInformation': require('../../../components/Atom/StatusInformation/StatusInformation').default,
	},
	methods:{
        ...mapGetters(['getMaster']),

		changeEmpresa(selecteds){
			this.selectedEmpresas = selecteds;
		},
		async gerarGTFS(){
			this.loadingPanel = true
			if(this.selectedEmpresas.length > 0 ){
				this.htmlValidacao = ''
				this.dadosValidacao = []
				this.tamanhoValidacao= 0
				this.statusBar = 'info'
				try {
					var data = await new HttpRequest().Post('/coletivos/integracao/gerarGTFS', 
						{'clientes': this.selectedEmpresas})
					this.tamanhoValidacao = data.data.tamanho;
					if(this.tamanhoValidacao > 0){
						var locais = data.data.gtfs;
						var root =  process.env.VUE_APP_ROOT;
						for(var a in locais){
							window.open(root+'/'+locais[a]);
						}
						this.dadosValidacao = data.data.validacoes
						this.montaTable(this.dadosValidacao);
					}else{
						this.statusBar = 'error'
					}
				}catch{
					conectionError()
				}
                
			}
			this.loadingPanel = false
		},
		// tem comentario no html do pq eu to mantendo isso, n fui eu que fiz essa func e nem o retorno do backend
		// to apenas arrumando uns bug aqui e vi isso
		// salve do Lucas, boa sorte porgramador do futuro que vai ver isso
		montaTable(alertas){
			var html = '<br>'
			if(alertas.agency == [] && alertas.calendar == [] && alertas.routes == [] && alertas.shapes == [] && alertas.stop_times == [] && alertas.stops == []){
				// html = '<tr><td colspan="4" class="rrel-alert rel-alert-success" role="alert">Nenhum possível erro encontrado</td></tr>';
				this.statusBar = 'error'
			}else{
				for(var a in alertas){
					if(alertas[a].length === undefined){
						html += '<tr><td class="alertNameArquivo" colspan="12"> <span class="cor-branca">'+a+'</span></td></tr>'
						for(var b in alertas[a]){
							if(alertas[a][b].length > 0){
								html += '<tr><td class="alertNameEmpresa" colspan="12">'+b+'</td></tr>'
								for(var c in alertas[a][b]){
									if(alertas[a][b][c].length > 0){
										html += '<tr><td class="alertAlertMensagem" colspan="12">- '+alertas[a][b][c]+'</td></tr>'
									}
								}
							}
						}
					}
				}
			}

			this.htmlValidacao = html;
		}
	},
	data(){
		return{
			loadingPanel:false,
			mdiFileExportOutline:mdiFileExportOutline,
			mdiCogs:mdiCogs,
			optionsArrayEmpresas : new EmpresasService().Get(),
			selectedEmpresas: [],
			tamanhoValidacao: 0,
			dadosValidacao: [],
			htmlValidacao: '',
			statusBar: 'info',
            selectedEmpresa: [],
		}
	},
    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optionsArrayEmpresas
        }
    }
})
</script>

<style lang="scss">
    #integracaoView{

        .cor-branca{
            color: #fff !important;
        }

        .theadRelatorioCommun{
            tr{
                th{
                    text-align: center;
                }
            }
        }

        .alertNameArquivo{
            min-width: 30%;
            text-align: left;
            color: #fff !important;
            background: #8a8a8a;
            font-weight: bold;
            font-size: 15px;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        .alertNameEmpresa{
            padding-left: 30px;
            background: #c5c5c5;
            font-weight: bold;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        .alertAlertMensagem{
            padding-left: 60px;
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
</style>