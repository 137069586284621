<template>
	<PanelEagle id="cadastroLinhas" :loading="loadingPanel">
		<div class="styleDivCarAlert" v-show="showAlertEdicaoLinha">
			<div class="badVisualizar">
				<h3 class="col-sm-12">Você está editando a linha.</h3>
			</div>
		</div>

		<div class="col-sm-12 row divDontPrint">
			<div class="col-sm-6 nopadding">
				<Titulo titulo="Linhas" :icon="mdiBusClock" />
			</div>

			<div class="col-sm-6 nopadding">
				<BasicButtonsCadastrosSC @salvarCadastro="salvarCadastro" tipoCancela="button" linkCancel="listarLinha"
					:disabled="$v.$invalid">
					<SimpleButton :disabled="!valueEmpresa.length || valueVelocidadeMedia == ''" type="green"
						text="Importar" :icon="mdiPlus" event="click" @click="abrirModal"
						:title="valueVelocidadeMedia == '' ? 'Para habilitar, preencha uma velocidade média' : ''" />
				</BasicButtonsCadastrosSC>
			</div>
		</div>

		<div class="col-sm-12">
			<hr />
		</div>

		<!-- inputs -->
		<div class="col-sm-12 nopadding row">
			<div class="col-sm-4 nopadding">
				<SelectAll nameForRadio="seletorDeEmpresa" :selected="selectedEmpresa"
					:labels="[{ description: 'Empresas*' }]" :isMultiple="false" :optionsArray="optEmpresas"
					@changeSelect="changeEmpresa" :disabled="showAlertEdicaoLinha" />
			</div>

			<div class="col-sm-8 nopadding">
				<InputSimple name="descricaoLinhas" label="Descrição*" placeholder="Nome da linha"
					@changeInput="changeDescricao" :value="valueDescricao" :disabled="showAlertEdicaoLinha" />
			</div>
		</div>

		<div class="col-sm-12 nopadding row mt-2">
			<div class="col-sm-4 nopadding row">
				<div class="col-sm-8 nopadding">
					<InputSimple placeholder="40" name="velocidadeMedia" label="Velocidade média*" :hasMask="true"
						:mask="['NNN']" :disabled="showAlertEdicaoLinha" :value="valueVelocidadeMedia"
						@changeInput="changeVelocidadeMedia" />
				</div>

				<div class="col-sm-3 nopadding d-flex align-items-center">
					<!-- v-model="linha.liappintegracao" -->
					<B-checkbox :disabled="showAlertEdicaoLinha" v-model="valueCheckboxAppIntegracao">
						App/integração
					</B-checkbox>
				</div>
			</div>

			<div class="col-sm-2 nopadding">
				<InputSimple :disabled="showAlertEdicaoLinha" name="inputPrefixo" label="Prefixo" placeholder="Prefixo"
					:value="valuePrefixo" @changeInput="changePrefixo" />
			</div>

			<div
				:class="showAlertEdicaoLinha ? 'col-sm-3 nopadding styleIndexMapaAtivo' : 'col-sm-3 nopadding styleIndexMapaDesativo'">
				<SelectAll :disabled="showAlertEdicaoLinha" ref="categoria" :isMultiple="false"
					:selected="selectedCategoria" :loading="controleLoading" :optionsArray="optCategoria"
					@changeSelect="changeCategoria" nameForRadio="seletorDeCategoria"
					:labels="[{ description: 'Categoria' }]" />
			</div>

			<div class="col-sm-2 nopadding">
				<SelectAll :disabled="showAlertEdicaoLinha" ref="tipo" :isMultiple="false" :optionsArray="optTipo"
					:selected="selectedTipo" :loading="controleLoading" @changeSelect="changeTipo"
					nameForRadio="seletorDeTipo" :labels="[{ description: 'Tipo *' }]" />
			</div>

			<div class="col-sm-1 nopadding d-flex align-items-center">
				<div>
					<B-checkbox :disabled="showAlertEdicaoLinha" v-model="valueCheckboxPrincipal">
						Principal
					</B-checkbox>
				</div>
			</div>
		</div>

		<div class="col-sm-12 nopadding mb-5 row mt-2">			
			<div class="col-sm-4 nopadding row">
				<div class="col-sm-8 nopadding">
					<SelectAll 
						nameForRadio="seletorDeRegioes" 
						:selected="selectedRegioes"
						:labels="[{ description: 'Regiões' }]" 
						:isMultiple="true" 
						:optionsArray="optRegioes"
						@changeSelect="changeRegioes" 
					/>
				</div>
			</div>
		</div>

		<!-- tabs -->
		<b-overlay :title="!valueEmpresa.length ? 'selecione uma empresa' : ''" variant="white" :opacity="0.4"
			rounded="sm" :show="!valueEmpresa.length" class="col-sm-12 row nopadding">
			<TabsLinhas ref="tabLinhas" :controleLoading="controleLoading" @validaTab="validaTab"
				:linhaPrincipal="valueCheckboxPrincipal" :tipoPonto="tipoPontoProp"
				:tipoPontoSelecionado="tipoPontoSelecionadoProp" :PontosProp="pontosGerais"
				:clienteProp="valueEmpresa[0]" :optCalendario="optCalendario" :velMedia="valueVelocidadeMediaprop"
				:optLinhaPrincipal="optLinhaPrincipal" @showAlertInfoEdicaoLinha="showAlertInfoEdicaoLinha"
				:tipoPontoSelecionadoNumber="tipoPontoSelecionadoNumber" :selectedTipo="selectedTipo"
				:editandoLinhaProp="editandoLinhaProp" :inputPontoSelecionado="inputPontoSelecionado"
				@alterarTipoPonto="alterarTipoPonto" />
			<template #overlay>
				<div />
			</template>
		</b-overlay>

		<ModalImportarPontosLinhas @copiarRastro="copiarRastro" :velocidadeMedia="valueVelocidadeMedia"/>

		<!-- Modal Confirm Linha Vinculada-->
        <template>
            <modalEagle id="modalLinhaVinculada" title="Linha Vinculada" size='lg'>
                <template slot="modalBody">
					<div class="col-sm-12 nopadding mt-2 row d-flex justify-content-center align-items-center">
						<span class="mr-2"></span>
						<div class="text-center">
							<h4>Há escalas associadas a linha: {{ valueDescricao }}</h4>	
							<h5>Remova esta linha de todas as escalas a seguir:	</h5>
						</div>
						<span class="ml-2"></span>
					</div>
					<div class="col-sm-12 nopadding mt-2 row">
                        <tableListagem :data='arrayTableVinculos' :titles='titulosTableVinculo' :filter='false'>
                            <template #acoes='{ row }'>
								<simpleButton 
											event="addClick" 
											:style="{ backgroundColor: clickBTN[row.edescodigo] ? '#c1c1c1' : '#428bca' }" 
											width="50px" 
											:icon="mdiArrowTopRightBoldBoxOutline"
											@addClick="direcionaEscala(row.edescodigo)"> 
										</simpleButton>
                            </template>
                        </tableListagem>
                    </div>
                </template>
				<template>
                    <div class="col-sm-2 row nopadding">
                        <div class="col-sm-12 nopadding">
                            <simpleButton 
                                text=" Cancelar " 
                                :icon="mdiCloseThick" 
                                event="click" 
                                type="red"
                                @click='hidePopupVinculoEscala'
                            />
                        </div>
                    </div>
                </template>

            </modalEagle>
        </template>

	</PanelEagle>
</template>
<script>
/**
 * 1 - https://www.npmjs.com/package/@mapbox/polyline usar 
 * isso para decodificar a poliline da rota ✅
 * 2 - alterar o calculo de intervalo entre os pontos para 
 * só fazer a request entre o ponto alterado e
 * anterior a ele, o resto só vai somando mesmo ✅
 */
import { mdiBusClock, mdiPlus, mdiCheckBold, mdiArrowTopRightBoldBoxOutline, mdiCloseThick } from '@mdi/js'
import { conectionError } from '@/Services/Helpers/swellHeper.js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { required, minValue } from 'vuelidate/lib/validators';
import { FiltrosService } from '@/Services/filtros/filtros.Service'


export default {
	name: 'cadastroLinhasColetivos',

	components: {
		tableListagem: require('@/components/Atom/Table/TableListagem').default,
		modalEagle: require('@/components/Atom/Modal/ModalEagle.vue').default,
		ModalImportarPontosLinhas: require('@/components/Atom/ModalSpecific/ModalImportarPontosLinhas.vue').default,
		PanelEagle: require('@/components/Atom/Panel/PanelEagle.vue').default,
		SelectAll: require('@/components/Atom/Select/SelectAll.vue').default,
		Titulo: require('@/components/Atom/Header/Titulo.vue').default,
		BasicButtonsCadastrosSC: require('@/components/Atom/Buttons/BasicButtonsCadastrosSC.vue').default,
		SimpleButton: require('@/components/Atom/Buttons/SimpleButton.vue').default,
		InputSimple: require('@/components/Atom/Inputs/InputSimple.vue').default,
		TabsLinhas: require('@/components/Atom/SpecificComponents/CadastroLinhas/TabsLinhas.vue').default,
	},

	validations: {
		valueEmpresa: { required },
		valueDescricao: { required },
		valueTabs: { minValue: minValue(3), required },
		valueVelocidadeMedia: { required },
		valueTipo: { required },
	},

	data() {
		return {
			// icone
			mdiPlus: mdiPlus,
			mdiBusClock: mdiBusClock,
			mdiCheckBold:mdiCheckBold,
			mdiArrowTopRightBoldBoxOutline:mdiArrowTopRightBoldBoxOutline,
			mdiCloseThick:mdiCloseThick,

			// controle
			loadingPanel: false,
			baseUri: '/coletivos/cadastros/linhas/',
			valueTabs: 0,
			controleLoading: false,
			editandoLinha: false,
			salvarOuEditar: 'salvar',

			// options inputs
			optTipo: [],
			optEmpresas: [],
			optCategoria: [],
			optCalendario: [],
			optLinhaPrincipal: [],
			optRegioes:[],

			// values
			valueTipo: [],
			valueEmpresa: [],
			valueCategoria: [],
			valuePrefixo: '',
			valueDescricao: '',
			valueVelocidadeMedia: 40,
			valueVelocidadeMediaprop: 0,
			valueCheckboxAppIntegracao: false,
			valueCheckboxPrincipal: false,

			// pontos
			pontosGerais: [],
			pontosReferencia: [],
			pontosColeta: [],
			pontosEntrega: [],
			pontosRegioes: [],

			// importacao
			erroImportacao: '',
			importarRastro: {
				empresa: [],
				veiculo: '',
				dataInicial: '',
				dataFinal: '',
				horaInicial: '',
				horaFinal: '',
			},
			tipoPontoProp: false,
			tipoPontoSelecionadoProp: false,
			selectedTipo: [],
			selectedCategoria: [],
			selectedEmpresa: [],
			selectedRegioes: [],
			showAlertEdicaoLinha: false,
			tipoPontoSelecionadoNumber: 0,
			editandoLinhaProp: false,
			inputPontoSelecionado: 0,
			escalaCodigo: '',
			escalaEdcodigoNome: '',
			responseData: [],
			arrayTableVinculos:[],
			clickBTN: {},
			titulosTableVinculo:[
				{'number':'escodigo', 'name':'Codigo'},
				{'number':'esdescricao', 'name':'Descrição'},
			],
		}
	},

	methods: {
		async copiarRastro(value) {
			var arrRastro = [];
			value.forEach((v, i) => {
				arrRastro.push(v.posicao.split(','));
			})
			this.$refs.tabLinhas.limparPontos()
			this.$nextTick(() => {
				this.$refs.tabLinhas.copiaRastro(arrRastro);
			})
		},

		/**
		 * Função para abrir o modal importarLinhas, muda o select tipo para ser Sem pontos quando a importação
		 * da linha ser realizada.
		 * @author Desconhecido 
		*/
		abrirModal() {
			this.selectedTipo = [{ 'value': 2, 'description': 'Sem pontos' }];
			this.changeTipo(2);
			this.erroImportacao = '';
			this.importarRastro = {};
			this.$bvModal.show('importarLinhas');
		},

		/**
		 * Função para pegar o item selecionado no select de "Tipo" e salva o tipo de linha 
		 * selecionada em this.valueTipo e em this.tipoPontoProp salva um booleano para ter o
		 * controle para quando limpar os dados do mapa.
		 * @param value Array, com o tipo de linha selecionada, "Com Pontos" ou "Sem Pontos"
		 * @author Desconhecido 
		*/
		changeTipo(value) {
			this.valueTipo = value;
			this.tipoPontoSelecionadoNumber = value[0];
			this.tipoPontoProp = false;
			this.inputPontoSelecionado++;
		},

		validaTab(value) {
			this.valueTabs = value;
		},

		// seletores
		/**
		 * Função para salvar os valores da empresa em this.valueEmpresa, quando alterado limpa os
		 * valores dos pontos, limpado dentro do else.
		 * @param value Array com dados da Empresa Selecionada.
		 * @author Desconhecido 
		*/
		changeEmpresa(value) {
			this.$refs.categoria.clearAll()
			this.valueEmpresa = value;
			if (value.length) {
				this.dadosFiltros();
				this.getInfo();
			} else {
				this.pontosReferencia = [];
				this.pontosGerais = [];
				this.pontosColeta = [];
				this.pontosEntrega = [];
				this.pontosRegioes = [];
				this.optCalendario = [];
				this.optLinhaPrincipal = [];
			}
		},

		/*
		* Função para listar as regioes da empresa 
		* Usando Dados Filtros Helper. Apenas chamando a funcao ja pronta!
		* @param this.valueEmpresa - Numero da empresa.
		*/
		async dadosFiltros(){
			var dados = await new FiltrosService().dados_filtros(this.valueEmpresa, ['RE'])
			this.optRegioes = dados['RE']
		},

		/**
		* Função para salvar o valor das regioes em this.regioesSelecionadas.
		* @param value numero da regiao escolhida no select.
		* @author VeCo® 🔱
		*/
		changeRegioes(value){
			this.regioesSelecionadas = value;
		},


		/**
		 * Função para salvar o valor da descrição em this.valueDescricao.
		 * @param value Valor da Descrição.
		 * @author Desconhecido 
		*/
		changeDescricao(value) {
			this.valueDescricao = value
		},

		/**
		 * Função para salvar o valor da velocidade média em this.valueVelocidadeMedia.
		 * @param value Valor da Velocidade Média.
		 * @author Desconhecido 
		*/
		changeVelocidadeMedia(value) {
			this.valueVelocidadeMedia = value
			if (value) {
				this.valueVelocidadeMediaprop = Number(value)
			} else {
				this.valueVelocidadeMediaprop = Number(40)
			}
		},

		/**
		 * Função para salvar o valor de prefixo em this.valuePrefixo.
		 * @param value Valor do Prefixo.
		 * @author Desconhecido 
		*/
		changePrefixo(value) {
			this.valuePrefixo = value
		},

		/**
		 * Função para salvar o valor de categoria selecionada, em this.valueCategoria.
		 * @param value Array com dados da Categoria Selecionada.
		 * @author Desconhecido 
		*/
		changeCategoria(value) {
			this.valueCategoria = value
		},

		// n sei ainda
		/**
		 * Busca as informações que alimentam os seletores
		 * da página relativos ao cliente
		 */
		async getInfo() {
			this.controleLoading = true
			let uri = this.baseUri + 'getinfocliente';
			try {
				await new HttpRequest()
					.Post(uri, {
						cliente: this.valueEmpresa
					}).then((dados) => {
						this.optTipo = dados.data.tipos
						this.optCategoria = dados.data.categorias
						this.pontosGerais = dados.data.pontos.P
						this.optCalendario = dados.data.calendarios
						this.optLinhaPrincipal = dados.data.principal.LP
					}).finally(() => { this.controleLoading = false })
				return
			} catch (error) {
				conectionError()
				this.controleLoading = false
			}
		},

		//Caso a linha a ser editada possua vinculo com a 
		//escalas
		/**
		 * Função para verificar caso a linha a ser editada possua 
		 * vinculo com uma escala
		*/
		direcionaEscala(value) {
			localStorage.setItem('escalaEditada', value);
			this.$set(this.clickBTN, value, true);
			window.open('#/coletivos/cadastros/escalas/novo', '_blank');
		},

		hidePopupVinculoEscala(){
			this.$bvModal.hide('modalLinhaVinculada');
		},
		
		/**
		 * Função para salvar todos os dados cadastrados novos ou editados da linha.
		 * @author Desconhecido 
		*/
		salvarCadastro() {
			this.loadingPanel = true
			var objSalvar = this.criaObjtSalvar()
			let uri = this.baseUri + this.salvarOuEditar

			new HttpRequest().Post(uri, objSalvar).then((dados) => {
				this.responseData = dados;

				this.arrayTableVinculos = this.responseData.data

				if (dados.data != 'ok') {
					this.escalaEdcodigoNome = dados.data.esdescricao;
					
					this.$bvModal.show('modalLinhaVinculada');
					this.loadingPanel = false
				} else {
					if (dados.code === 201) {
						this.loadingPanel = false
						this.$router.push({ name: 'listarLinha' });
					} else if (dados.data[1] == "22008") {
						let msg = "O tempo estimado excede o limite permitido"
						conectionError(msg, "info")
						this.loadingPanel = false
					} else {
						this.loadingPanel = false
						conectionError()
					}
				}
			}).catch(() => {
				this.loadingPanel = false
				conectionError()
			})

		},

		criaObjtSalvar() {
			var tabs = this.$refs.tabLinhas
			var objSalvar = [tabs.pegaValoresSalvar()]
			// objSalvar = this.limpaTempoEDistancia(objSalvar)
			var objtRetorno = {
				linhas: {
					lidescitinerario: objSalvar[0]['objInfoAdd']['lidescricao'],
					livalidador: objSalvar[0]['objInfoAdd']['livalidador'],
					liitinerario: objSalvar[0]['objInfoAdd']['liitinerario'],
					liatrasotolerado: objSalvar[0]['objInfoAdd']['liatrasotolerado'],
					licodigoprincipal: objSalvar[0]['objInfoAdd']['linhaPrincipal'],
					licor: objSalvar[0]['objInfoAdd']['licor'],
					lidistancia: objSalvar[0]['objEscolherPontos']['distancia'],
					litempoestimado: objSalvar[0]['objEscolherPontos']['tempo'],
					listringrota: objSalvar[0]['objEscolherPontos']['ratro'],
					lidescricao: this.valueDescricao,
					litipo: this.valueTipo[0] ? this.valueTipo[0] : 1,
					licliente: this.valueEmpresa[0],
					liprefixo: this.valuePrefixo,
					liprincipal: this.valueCheckboxPrincipal ? 'S' : 'N',
					licategoria: this.valueCategoria[0],
					liappintegracao: this.valueCheckboxAppIntegracao,
					livelocidademedia:
						this.valueVelocidadeMedia > 0 ?
						this.valueVelocidadeMedia : 40,
				},
				horarios: [...objSalvar[0]['objDefinirHorario']],
				pontos: [...objSalvar[0]['objEscolherPontos']['pontos']],
				regioes: this.regioesSelecionadas ? this.regioesSelecionadas : [],

			}
			if (this.valueCheckboxPrincipal) {
				objtRetorno.linhas.licodigoprincipal = ''
			}
			if (this.$route.params.id && !this.$route.params.cp) {
				objtRetorno.linhas.licodigo = this.$route.params.id
			}
			return objtRetorno
		},

		/**
		 * Valida se o usuario selecionou o ponto como sendo
		 * 'Sem pontos'. 
		 * Caso seja verdadeiro ele limpa os campos de tempo e distancia
		 * para salvar no banco
		 * @author Marcos ._. 
		*/
		limpaTempoEDistancia(objSalvar) {
			if (this.tipoPontoProp) {
				objSalvar[0]['objEscolherPontos']['tempo'] = '00:00:00'
				objSalvar[0]['objEscolherPontos']['distancia'] = 0
			}
			return objSalvar;
		},

		async arrumaDadosEdicaoECopia(dados, edicao) {

			this.selectedRegioes = dados.regioes
			this.editandoLinhaProp = true;
			this.editandoLinha = true;
			this.valueEmpresa = [dados.linha.licliente];
			this.selectedEmpresa = this.optEmpresas.filter(
				v => dados.linha.licliente === v.value
			);
			// this.$nextTick(async()=>{
			await this.getInfo();
			this.selectedCategoria = this.optCategoria.filter(
				v => dados.linha.licategoria === v.value
			);
			this.selectedTipo = this.optTipo.filter(
				v => dados.linha.litipo === v.value
			);
			this.valuePrefixo = dados.linha.liprefixo;
			this.valueDescricao = dados.linha.lidescricao;
			if (this.$route.params.cp) {
				this.valueDescricao = this.valueDescricao + '_COPIA';
			}
			this.valueVelocidadeMedia = dados.linha.livelocidademedia;
			this.valueCheckboxAppIntegracao = dados.linha.liappintegracao;
			this.valueCheckboxPrincipal = dados.linha.liprincipal === 'S';
			this.$refs.tabLinhas.arrumaValoresEditar(dados);
			this.editandoLinha = false;
			// })
			this.loadingPanel = false;
		},

		showAlertInfoEdicaoLinha(value) {
			this.showAlertEdicaoLinha = value;
		},

		/**
		 * Função para receber o valor de tipoPontoProp e mudar seu valor
		 * @Param {boolean} value, booleano.
		 * @author Yuri 🇧🇷
		*/
		alterarTipoPonto(value) {
			this.tipoPontoProp = true;
		}
	},

	async mounted() {
		this.optEmpresas = await new EmpresasService().Get();

		if (this.$route.params.id) {
			this.salvarOuEditar = 'editar'
			if (this.$route.params.cp) {
				this.salvarOuEditar = 'salvar'
			}
			this.loadingPanel = true
			var uri = this.baseUri + 'getInfo';
			new HttpRequest().Post(uri, { licodigo: this.$route.params.id })
				.then((dados) => {
					this.arrumaDadosEdicaoECopia(dados.data, true)
				})
		}
	},

	computed: {
		valorTabPontos() {
			if (this.tipoPontoProp)
				return 3
			else
				return 2
		}
	},
};
</script>

<style lang="css">
.badVisualizar {
	width: 400px;
	height: 60px;
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.582);
	color: white;
	z-index: 100;
	margin-top: 100px;
}

.styleDivCarAlert {
	width: 100%;
	height: 100%;
	margin: auto;
	position: absolute;
	z-index: 4;
	background-color: rgba(218, 218, 218, 0.219);
}

.styleIndexMapaAtivo {
	z-index: 1;
}

.styleIndexMapaDesativo {
	z-index: 6;
}

.vinculos-escalas{
	width: 100%
}
.vinculos-escalas td:last-child {
  width: 80px;
}
</style>
