<template>
    <panelEagle id='EditarFornecedores' :loading='loadingPanel'>
        <div class="col-sm-12 nopadding row">
            <div class="col-sm-6">
                <tituloPage :icon='mdiAccountSupervisorCircle' titulo='Fornecedor'></tituloPage>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                <basicButtonsCadastrosSC
                    @salvarCadastro='salvarCadastro'
                    :disabled='$v.$invalid'
                    tipoCancela='button'
                    linkCancel='listarFornecedores'
                />
            </div>
        </div>
        <slideUpAndDown>
            <div slot="filters">
                <div class="col-sm-12 col-md-12"><hr></div>
                <div class='col-sm-12 row nopadding'>
                    <div class="col-12 nopadding row">
                        <div class="col-lg-4 col-md-12 nopadding">
                            <buttonsFilters
                                :label='labelBot'
                                :arrayButtons='botoes'
                                @buttonsFiltersChange='buttonsFiltersChange'/>
                        </div>
                    </div>
                    <br>
                    <div class="col-12 row marg-top-5px nopadding">
                        <div class="col-xl-4 marg-top-5px nopadding">
                            <div v-if="condiIfNomeFantasia">
                                <inputSearch
                                    :inputClass='{"border border-danger":$v.valueCpfECnpj.$anyError}'
                                    @blur='$v.valueCpfECnpj.$touch()'
                                    :value ='valueCpfECnpj'
                                    :hasMask='true'
                                    :mask="['NN.NNN.NNN/NNNN-NN']"
                                    @clickButtonSearch='clickButtonSearchCNPJ'
                                    @changeInput='changeInputSearchCNPJ'
                                    :label='labelSearch'
                                    name='inputSearch'
                                />
                                <div class="col-sm-9 text-documento">
                                    <span v-if="ifDocumento" class="text-documento">CNPJ inválido.</span>
                                    <span v-if="ifDocumentoErroBrabo" class="text-documento">
                                        ERRO. Aguarde um momento para continuar, se o erro persistir, contate o suporte.
                                    </span>
                                </div>
                            </div>
                            <div v-else>
                                <inputSimple
                                    :inputClass='{"border border-danger":$v.valueCpfECnpj.$anyError}'
                                    @blur='$v.valueCpfECnpj.$touch()'
                                    :value ='valueCpfECnpj'
                                    id='cpf'
                                    :hasMask='true'
                                    :mask="['NNN.NNN.NNN-NN']"
                                    @changeInput='changeInputSearchCPF'
                                    :label='labelSearch'
                                    name='documento'/>
                                <div class="col-sm-9 text-documento nopadding mt-1">
                                    <span v-if="cpfInvalido" class="text-documento col-sm-1">CPF inválido</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 nopadding marg-top-5px">
                            <inputSimple
                                :inputClass='{"border border-danger":$v.valueDocumentos.$anyError}'
                                @blur='$v.valueDocumentos.$touch()'
                                :label='nameDocumento'
                                :value='valueDocumentos'
                                @changeInput='changeInputDocumento'
                                name='documento'/>
                        </div>
                        <div v-if="condiIfNomeFantasia" class="col-xl-4 marg-top-5px nopadding">
                            <inputSimple
                                :value='valueFantasia'
                                label='Nome fantasia'
                                name='nomefantasia'/>
                        </div>
                    </div>
                        <div class="col-sm-4 marg-top-5px">
                            <inputMuiltiple
                                ref='refInputTelefone'
                                @changeInput='changeInputTelefone'
                                :qtBnt ='qtBntTelefone'
                                :hasMask='true'
                                :mask="[`NNPPPNNPPPNNNN`]"
                                label='Telefone'/>
                        </div>
                        <div class="col-lg-4 marg-top-5px">
                            <inputMuiltiple
                                ref='refInputEmail'
                                @changeInput="changeInputEmail"
                                :qtBnt ='qtBntEmail'
                                type='email'                    
                                label='Email'/>
                        </div>
                        <div class="col-lg-4 marg-top-5px nopadding">
                            <selectAll
                                :extraClass   ='{"border border-danger":$v.valueEmpresa.$anyError}'
                                @close='$v.valueEmpresa.$touch()'
                                nameForRadio  ='selectempresa'
                                :isMultiple   ='false'
                                :optionsArray ='optEmpresas'
                                :labels       ='labelsEmp'
                                :hasSelectAll ='false'
                                :selected     ='firstSelectedEmpresa'
                                @changeSelect ='changeEmpresa'/>
                        </div>
                </div>
            </div>
        </slideUpAndDown>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class="row nopadding">
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-12 row nopadding">
                    <div class="col-8 nopadding">
                        <inputSearch
                            :inputClass='{"border border-danger":$v.valueProcuraMapa.$anyError}'
                            @blur='$v.valueProcuraMapa.$touch()'
                            :value='valueProcuraMapa'
                            @changeInput='changeInputSearchMapa'
                            @clickButtonSearch='clickButtonSearchMapa'
                            label='Endereço*'
                            name='inputSearch'
                        />
                    </div>
                    <div class="col-4 nopadding">
                        <selectAll
                            :selected ='selectedCidade'
                            nameForRadio='seletorCidade'
                            :labels='[{description:"Cidade*"}]'
                            :isMultiple='false'
                            :optionsArray='optSelectCidade'
                            :extraClass='{"border border-danger":$v.valueCidade.$anyError}'
                            @close='$v.valueCidade.$touch()'
                            @changeSelect='v => valueCidade = v[0]'
                        />
                    </div>
                </div>
            </div>
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-12 row nopadding">
                    <div class="col-12">
                        <mapaSimples :height='400' ref="refMapa">
                            <markerPoint
                                :latitude='latitudeMarkerInicial'
                                :longitude='longitudeMarkerInicial'
                                :isDraggable='false'
                                icon='img/matriz.png'/>
                            <markerPoint
                                @drag='dragMarker'
                                @dragend='dragMarkerEnd'
                                :latitude='latitude'
                                :longitude='longitude'
                                :isDraggable='true'
                                :direction='8'>
                                <popupMarker>
                                    <p>Arraste para o local desejado</p>
                                    <p>Raio: {{valueBasicSlider}} metros</p>
                                    <basicSlider 
                                        @change='changeSlider'
                                        :maxValue="500"
                                    />
                                </popupMarker>
                            </markerPoint>
                            <circleSimple :center="[latitude, longitude]" :radius="valueBasicSlider"/>
                        </mapaSimples>
                    </div>
                </div>
            </div>
        </div>
        <requiredFields/>
        <div class="col-sm-12"><hr></div>
    </panelEagle>
</template>
<script>
import { mdiAccountSupervisorCircle, mdiCheckBold } from '@mdi/js'
import Vue  from 'vue'
import axios from 'axios'
import jsonpAdapter from 'axios-jsonp'
import { required } from 'vuelidate/lib/validators'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import Swal from 'sweetalert2'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { conectionError } from '@/Services/Helpers/swellHeper'
export default Vue.extend({
    name:'cadastrarFornecedor',
    components:{
        'selectAll'               : require('@/components/Atom/Select/SelectAll').default,
        'panelEagle'              : require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage'              : require('@/components/Atom/Header/Titulo').default,
        'basicSlider'             : require('@/components/Atom/Buttons/BasicSlider').default,
        'popupMarker'             : require('@/components/Atom/Mapa/PopupMarker').default,
        'inputSearch'             : require('@/components/Atom/Inputs/InputSearch').default,
        'inputSimple'             : require('@/components/Atom/Inputs/InputSimple').default,
        'mapaSimples'             : require('@/components/Atom/Mapa/MapaSimples').default,
        'markerPoint'             : require('@/components/Atom/Mapa/MarkerPoint').default,
        'circleSimple'            : require('@/components/Atom/Mapa/CircleSimple').default,
        'slideUpAndDown'          : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
        'buttonsFilters'          : require('@/components/Atom/Buttons/ButtonsFilters').default,
        'inputMuiltiple'          : require('@/components/Atom/Inputs/InputMultiple').default,
        'basicButtonsCadastrosSC' : require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
        'requiredFields'         : require('@/components/Atom/Footer/RequiredFields').default,
    },
    validations:{
        valueCpfECnpj    : { required },
        valueDocumentos  : { required },
        valueProcuraMapa : { required },
        valueEmpresa     : { required },
        valueCidade      : { required }
    },
    data(){
        return {
            selectedCidade:[],
            mdiCheckBold:mdiCheckBold,
            mdiAccountSupervisorCircle:mdiAccountSupervisorCircle,
            loadingPanel : false,
            controle   : 0,
            controleEE : 0,
            firstSelectedEmpresa :[],
            valueEmpresa :[],
            id:'',
            valueCpfECnpj : '',
            arrayValores : [],
            latitude  : -27.1001389,
            longitude : -52.6270369,
            latlng    :[-27.1001389, -52.6270369],
            valueProcuraMapa:'',
            latitudeMarkerInicial  : -27.1001389,
            longitudeMarkerInicial : -52.6270369,
            valueBasicSlider:0,
            valueCidade:'',
            maxValue: Number(200),
            refInputTelefone:'',
            refInputEmail:'',
            qtBntTelefone : [{value:''}],
            qtBntEmail    : [{value:''}],
            valueEmail:'',
            aviso:0,
            ifDocumentoErroBrabo :false,
            ifDocumento: false,
            valueDocumentos:'',
            valueFantasia:'',
            respostaAxios: '',
            cpfInvalido : false,
            condiIfNomeFantasia :false,
            nameDocumento : '',
            inputDisabledSearch :'',
            maskSearch  : [],
            labelSearch : '',
            verifica    : 0,
            optEmpresas   : new EmpresasService().Get(),
            labelsEmp     :[{
                indexDFH    : 'EM',
                description : 'Empresas*'
            }],
            loadingTabela : false,
            dadosTabela   : [],
            titles :[
                {'number':'nome'      , 'name':'Nome'},
                {'number':'telefone'  , 'name':'Telefone'},
                {'number':'empresa'   , 'name':'Empresa'},
            ],
            loadingBotao  :[false, false, false],
            valueBotaoFiltros  : '',
            labelBot : 'Tipo de fornecedor',
            botoes      : [
                {
                    'isSelected': false,
                    'value' : 'F',
                    'width' : '48.5%',
                    'title' : 'Pessoa física',
                    'text'  : 'Física',
                    'icon'  : ''
                },
                {
                    'isSelected': true,
                    'value' : 'J',
                    'width' : '48.5%',
                    'title' : 'Pessoa jurídica',
                    'text'  : 'Jurídica',
                    'icon'  : ''
                },
            ],
            uriBase: '/manutencoes/cadastros/forncedores/',
            optSelectCidade : []
        }
    },
    methods:{
        async salvarCadastro(){
            this.loadingPanel = true
            var cpfjCnpj = ''
            // Transforma o CPF ou CNPJ na formatação correta para enviar ao banco
            if(this.valueBotaoFiltros == 'F'){
                cpfjCnpj = this.valueCpfECnpj.replace(/(\d{3})\.?(\d{3})\.?(\d{3})-?(\d{2})/, '$1.$2.$3-$4')
            } else {
                cpfjCnpj = this.valueCpfECnpj.replace(/(\d{2})\.?(\d{3})\.?(\d{3})\/?(\d{4})-?(\d{2})/, 
                    '$1.$2.$3/$4-$5')
            }
            var obj = {
                'mfnome'      : this.valueDocumentos,
                'mfdocumento' : cpfjCnpj,
                'mffantasia'  : this.valueFantasia,
                'mfendereco'  : this.valueProcuraMapa,
                'mflatitude'  : this.latitude,
                'mflongitude' : this.longitude,
                'mftelefone'  : this.qtBntTelefone,
                'mfemail'     : this.qtBntEmail,
                'mftipo'      : this.valueBotaoFiltros,
                'mfraio'      : this.valueBasicSlider,
                'mfcliente'   : this.valueEmpresa[0],
                'mfcidade'    : this.valueCidade,
                'id'          : this.id,
            }
            let uri = this.uriBase+"salvar"
            var dados = await new HttpRequest()
                .Post(uri, {'obj':obj})
            if(dados?.code == 201){
                this.loadingPanel = false
                this.$router.push({name:'listarFornecedores'})
            } else if(dados?.code == 200){
                this.loadingPanel = false
                Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    html: dados.data.mensagem,
                    footer: '',
                })
            }else{
                this.loadingPanel = false
                conectionError()
            }
        },
        changeInputDocumento(value){
            this.valueDocumentos = value
        },
        changeInputSearchMapa(value){
            this.valueProcuraMapa = value
        },
        dragMarker(value){
            this.latitude  = value.latlng.lat
            this.longitude = value.latlng.lng
            this.latlng    = [Number(this.latitude), Number(this.longitude)]
        },
        async dragMarkerEnd(){
            let uri = this.uriBase+'cord'
            let obj = {'cord':this.latlng[0]+' '+this.latlng[1]}
            var dados = await new HttpRequest().Post(uri, obj)
            this.arrumaCidade(dados.data.local['cidade']) 
            this.valueProcuraMapa = dados.data.local['end']
        },
        changeSlider(value){
            this.valueBasicSlider = value
        },
        async clickButtonSearchMapa(value){
            let uri = this.uriBase+'cord'
            let obj = {'cord':value}
            var dados = await new HttpRequest().Post(uri, obj)
            this.latitude  = Number(dados.data.local['lat'])
            this.longitude = Number(dados.data.local['lon'])
            this.arrumaCidade(dados.data.local.cidade)
            var centerMap = {lat:this.latitude, lng:this.longitude}
            this.$refs.refMapa.flyTo(centerMap)
            this.latlng = [Number(dados.data.local['lat']),Number(dados.data.local['lon'])]
        },
        changeInputTelefone(value){
            if(this.controle == 0){
                this.controle = this.controle + 1
            }
            else{
                setTimeout(() => {
                    if(this.$refs.refInputTelefone.$refs.refInputMenos[0].inputValue == ''){
                        this.qtBntTelefone = [{value: ""}]
                    }else{
                        this.qtBntTelefone = value
                    }
                }, 500);
            }
        },
        changeInputEmail(value){
            if(this.controleEE == 0){
                this.controleEE = this.controleEE + 1
            }
            else{
                setTimeout(() => {
                    if(this.$refs.refInputEmail.$refs.refInputMenos[0].inputValue == ''){
                        this.qtBntEmail = [{value: ""}]    
                    }else{
                        this.qtBntEmail = value
                    }
                }, 500);
            }
        },

        arrumaCidade(cidade){
            var cidadee = cidade.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
            this.selectedCidade = []
            for(const index in this.optSelectCidade) {
                var str = this.optSelectCidade[index].description.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
                if(str == cidadee){
                    this.selectedCidade = [this.optSelectCidade[index]]
                    break
                }
            }
        },

        async clickButtonSearchCNPJ(value){
            if(value.length >= 14){
                axios({
                    url: `https://www.receitaws.com.br/v1/cnpj/${value}`,
                    adapter: jsonpAdapter,
                }).then((resposta) => {
                    if(resposta.data.status != 'ERROR'){
                        var municipio  = resposta.data.municipio
                        var uf         = resposta.data.uf
                        var bairro     = resposta.data.bairro
                        var logradouro = resposta.data.logradouro
                        var numeroRua  = resposta.data.numero
                        var cep = resposta.data.cep
                        this.clickButtonSearchMapa(`${municipio} - ${uf}, ${bairro}, ${logradouro}, ${numeroRua}, ${cep}`)
                        this.valueProcuraMapa = `${municipio} - ${uf}, ${bairro}, ${logradouro}, ${numeroRua}, ${cep}`
                        this.arrumaCidade(municipio)
                        this.valueDocumentos = resposta.data.nome
                        this.valueFantasia   = resposta.data.fantasia
                        this.valueEmail      = resposta.data.email
                        var a = resposta.data.telefone.split('/ ')
                        var b = resposta.data.email.split('/ ')
                        var controleLaco  = a.length-1
                        var controleLacoB = b.length-1
                        var i = 0
                        var array = []
                        while(controleLaco>=i){
                            array.push({value:a[i]})
                            i = i+1
                        }
                        this.qtBntTelefone = array
                        var iB = 0
                        var arrayB = []
                        while(controleLacoB>=iB){
                            arrayB.push({value:b[iB]})
                            iB = iB+1
                        }
                        this.valueCpfECnpj = value 
                        this.qtBntEmail = arrayB
                    }else{
                        this.valueCpfECnpj = ''
                        this.ifDocumento = true
                    }
                }).catch((error)=>{
                    this.aviso = this.aviso+1
                    if(this.aviso < 5){
                        this.ifDocumento = false
                        this.valueCpfECnpj = ''
                    }else{
                        this.valueCpfECnpj = ''
                        this.ifDocumento = false
                        this.ifDocumentoErroBrabo = true
                    }
                })
            }else if(value.length > 0){
                this.valueCpfECnpj = ''
                this.ifDocumento   = true
            }
            this.$refs.refInputTelefone.$forceUpdate();
            this.$refs.refInputEmail.$forceUpdate();
        },
        async changeEmpresa(value){
            this.valueEmpresa = value
        },
        addClassCpf(){
            var elemento = document.getElementById('cpf');
            var classes = elemento.className.split(' ');
            var getIndex = classes.indexOf('cpf');
            if (getIndex === -1) {
                classes.push('cpf');
                elemento.className = classes.join(' ');
            }
        },
        removeClassCpf(){
            var elemento = document.getElementById('cpf');
            var classes = elemento.className.split(' ');
            var getIndex = classes.indexOf('cpf');

            if (getIndex > -1) {
                classes.splice(getIndex, 1);
            }
            elemento.className = classes.join(' ');
        },
        changeInputSearchCPF(value){
            this.valueCpfECnpj = ''
            if(value.length == 14){
                var str = value
                var res = str.replace(".", "")
                var ress = res.replace("-", "")
                var CpfFinal = ress.replace(".", "")
                var invalido = 0
                for (const index in CpfFinal.split('')){
                    if(CpfFinal.split('')[index] == CpfFinal[0])
                    invalido++
                }
                if(invalido != 11 && CpfFinal != '12345678900'){
                    this.testaCpf(CpfFinal, value)
                }else{
                    this.cpfInvalido = true
                    this.addClassCpf()
                }
            }
        },
        testaCpf(strCPF, value){
            var cpf = strCPF
            var i = 0
            var multiplica = 10
            var valorFinal = 0
            while(i < 9){
                var conta = cpf[i]
                var soma = conta*multiplica
                valorFinal = valorFinal+soma
                multiplica = multiplica-1
                i = i+1
            }
            var divisao = valorFinal%11
            divisao = 11-divisao
            if(divisao == cpf[9] || cpf[9] == 0){
                var indice = 0
                var multiplicador = 11
                var valorFinalSegundo = 0
                while(indice < 10){
                    var contaSegunda = cpf[indice]
                    var somaSegunda = contaSegunda*multiplicador
                    valorFinalSegundo = valorFinalSegundo+somaSegunda
                    multiplicador = multiplicador-1
                    indice = indice+1
                }
                var divisaoSegunda = valorFinalSegundo%11
                divisaoSegunda = 11-divisaoSegunda
                if(divisaoSegunda == cpf[10] || cpf[10] == 0){
                    this.cpfInvalido = false
                    this.valueCpfECnpj = value
                    this.removeClassCpf()
                }else{
                    this.cpfInvalido = true
                    this.addClassCpf()
                }
            }else{
                this.cpfInvalido = true
                this.addClassCpf()
            }
        },
        changeInputSearchCNPJ(value){
            if(value.length >= 14){
                // this.clickButtonSearchCNPJ(value)
                this.valueCpfECnpj = value 
            }
            else if(value.length > 0){
                this.valueCpfECnpj = ''
            }
            // var str = '11111111111111'
        },
        buttonsFiltersChange(value){
            this.ifDocumento = false
            this.ifDocumentoErroBrabo = false
            this.valueDocumentos = ''
            this.valueFantasia   = ''
            this.valueEmail      = ''
            this.qtBntTelefone   = [{value:''}],
            this.qtBntEmail      = [{value:''}],
            this.valueBotaoFiltros = value
            if(value == "F") {
                this.labelSearch = 'CPF*'
                this.nameDocumento = 'Nome*'
                this.condiIfNomeFantasia = false
                this.botoes[0].icon = mdiCheckBold
                this.botoes[1].icon = ' '
            }
            else if(value == "J"){
                this.labelSearch = 'CPNJ*'
                this.nameDocumento = 'Razão Social*'
                this.condiIfNomeFantasia = true
                this.botoes[0].icon = ' '
                this.botoes[1].icon = mdiCheckBold
            }          
        },
        arrumaDadosInputMultiple(value){
            var res = value.replace('["', '');
            var rest = res.replace('"]', '');
            var array = rest.split('","')
            var retorno = []
            var i = 0
            var arrayPronta = []
            while(array.length > i){
                arrayPronta.push({value:array[i]})
                i = i+1
            }
            this.arrayValores = arrayPronta 
        }
    },
    async mounted(){
        new FiltrosService().dados_filtros([], ['CI'])
        .then((data)=>{
            this.optSelectCidade = data.CI
        })
        
        var usuario = this.$store.state.user.client
        let uriUser = this.uriBase+'user'
        var consultaLocal = await new HttpRequest()
            .Post(uriUser, {'usuario':usuario})
        if(this.$route.params.id != undefined){
            this.loadingPanel = true
            this.id = this.$route.params.id
            let uri = this.uriBase+'dados/id'
            var consultaDadosExistentes = await new HttpRequest().Post(uri, {'id':this.id})
            if(consultaDadosExistentes.data[0].mfdocumento.length == 14){
                this.botoes[0].isSelected = true
                this.botoes[1].isSelected = false
                this.buttonsFiltersChange('F')
            }else{
                this.botoes[0].isSelected = false
                this.botoes[1].isSelected = true
                this.buttonsFiltersChange('J')
            }
            this.firstSelectedEmpresa = [
                {
                    value: consultaDadosExistentes.data[0].mfcliente, 
                    description: consultaDadosExistentes.data[0].clfantasia
                }
            ]
            this.valueCidade = consultaDadosExistentes.data[0].cicodigo
            this.selectedCidade = [{
                value:consultaDadosExistentes.data[0].cicodigo,
                description:consultaDadosExistentes.data[0].cinome
            }]
            this.valueCpfECnpj    = consultaDadosExistentes.data[0].mfdocumento
            this.valueDocumentos  = consultaDadosExistentes.data[0].mfnome
            this.valueFantasia    = consultaDadosExistentes.data[0].mffantasia
            this.valueProcuraMapa = consultaDadosExistentes.data[0].mfendereco
            this.valueBasicSlider = consultaDadosExistentes.data[0].mfraio
            this.longitude = Number(consultaDadosExistentes.data[0].mflongitude)
            this.latitude  = Number(consultaDadosExistentes.data[0].mflatitude)
            var centerMap = {lat:this.latitude, lng:this.longitude}
            this.$refs.refMapa.flyTo(centerMap)
            this.latlng    = [
                Number(consultaDadosExistentes.data[0].mflatitude), 
                Number(consultaDadosExistentes.data[0].mflongitude)
            ] 
            this.arrumaDadosInputMultiple(consultaDadosExistentes.data[0].mftelefone)
            this.qtBntTelefone = this.arrayValores
            this.arrumaDadosInputMultiple(consultaDadosExistentes.data[0].mfemail)
            this.qtBntEmail = this.arrayValores
            this.arrayValores = []
        }
        else if(consultaLocal.data[0].cllatitude == null || consultaLocal.data[0].cllongitude){
            this.latlng = [
                Number(consultaLocal.data[0].cllatitude), 
                Number(consultaLocal.data[0].cllongitude)
            ]
        }
        this.loadingPanel = false
    },
    watch:{
        ifDocumento:function(){
            setTimeout(() => {
                this.ifDocumento = false
            }, 4000);
        },
        ifDocumentoErroBrabo:function(){
            setTimeout(() => {
                this.ifDocumentoErroBrabo = false
            }, 10000);
        },
        cpfInvalido:function(){
            setTimeout(() => {
                this.cpfInvalido = false
            }, 4000);
        }
    }
})

// cara, fiz o ajuste tecnico pq to muito ruim hj para pensar, eu prometo que arrumo isso
// o bug é do componente, que n pega o evento de quando ele apaga todos os campos
// n sei o que deu, mas ele tava funcionadno perfeitamente e do nada ele parou de funcionar
// 
// a pagina ta funcinando bem só que tem esse ajuste tecnico aqui, se parar do nada alguem mudou
// fiquei umas 3 horas fazendo teste nisso

</script>
<style lang="scss">
    .text-documento{
        text-align: left !important;
        color: red;
    }
    .marg-top-5px{
        margin-top: 5px;
        margin-bottom: 2px;
    }
    .cor{
        background-color: black;
    }
    .cpf{
        border: 1px solid red !important;
    }
    .aviso{
        margin-right: 10%;
        font-size: 12px;
        text-align: right;
    }
</style>