<template>
    <panelEagle 
        id="cadVisitas"
        :loading="loadingPanel">
        <div class="col-sm-12 nopadding divDontPrint">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage 
                        :icon="mdiHomeFlood" 
                        titulo="Visitas">
                    </tituloPage>
                </div>
                <div class="col-sm-3">
                    <alertSimple 
                        v-if="needShowAlert" 
                        :type="statusAlert" 
                        :isLoading="isLoading" 
                        :message="messageAlert" 
                        :hasClick="false">
                    </alertSimple>
                </div>
                 <div class="col-sm-3">
                    <basicButtonsCadastrosSC
                        tipoCancela="buton"
                        :disabled="$v.$invalid"
                        linkCancel="listarVisitas" 
                        @salvarCadastro="salvarVisitas">
                    </basicButtonsCadastrosSC>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-4 nopadding">
                    <inputSimple 
                        :inputClass  ="{'border border-danger':$v.descricaoVisitas.$anyError}"
                        @blur        ="$v.descricaoVisitas.$touch()"
                        @changeInput ="changeDescricao" 
                        :value       ="descricaoSelected" 
                        name         ="descricaoVisitas" 
                        id           ="descricaoVisitas"
                        label        ="Descrição *">
                    </inputSimple>
                </div>
                <div class="col-sm-4 nopadding">
                    <selectAll 
                        :extraClass   ="{'border border-danger':$v.empresaSelecionadas.$anyError}"
                        @close        ="$v.empresaSelecionadas.$touch()"
                        @changeSelect ="changeEmpresa" 
                        :selected     ="empresaSelected" 
                        :isMultiple   ="false" 
                        nameForRadio  ="empresasVisitas" 
                        :hasSelectAll ="false" 
                        :labels       ="[{indexDFH: 'EM', description: 'Empresa *'}]" 
                        :optionsArray ="optionsArrayEmpresas" 
                        firstSelected ="EM">
                    </selectAll>
                </div>
            </div>
        </div>
        <div class="col-sm-12 pl-0">
            <requiredFields></requiredFields>
        </div>
        <div class="col-sm-12"><hr></div>
    </panelEagle>
</template>

<script lang="js">
import {mdiHomeFlood } from '@mdi/js'
import { required } from 'vuelidate/lib/validators';
import { EmpresasService } from '@/Services/auth/Empresas.service';
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import Vue from 'vue'
import Swal from 'sweetalert2'

export default Vue.extend({
    name:'CadastroVisitas',
    components:{
        'tituloPage': require('@/components/Atom/Header/Titulo').default,
        'selectAll': require('@/components/Atom/Select/SelectAll').default,
        'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
        'alertSimple': require('@/components/Atom/Alerts/AlertSimple').default,
        'inputSimple': require('@/components/Atom/Inputs/InputSimple').default,
        'requiredFields': require('@/components/Atom/Footer/RequiredFields').default,
        'BasicButtonsCadastrosSC': require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
    },

    validations:{
        descricaoVisitas: {
            required
        },

        empresaSelecionadas: {
            required
        },
    },

    data: function(){
        return{
            mdiHomeFlood:mdiHomeFlood,
            optionsArrayEmpresas : new EmpresasService().Get(),
            descricaoVisitas     : '',
            isLoading            : false,
            statusAlert          : 'blue',
            needShowAlert        : false,
            messageAlert         : '',
            descricaoSelected    : '',
            empresaSelected      : [],
            empresaSelecionadas  : [],
            vicodigo             : null,
            loadingPanel         : false,
            success              : false,
        }
    },

    methods: {
        changeEmpresa(empresa){
            this.empresaSelecionadas = empresa;
        },

        changeDescricao(descricao){ 
            this.descricaoVisitas = descricao;
        },

        async salvarVisitas(){
            this.loadingPanel = true
            var param = {
                'vicliente': this.empresaSelecionadas[0],
                'videscricao': this.descricaoVisitas,
                'vistatus': 'A',
            }
            if(this.vicodigo){
                param['vicodigo'] = this.vicodigo
            }
            var data = await new HttpRequest()
                .Post('/roteirizador/cadastros/salvar', 
                    {
                        'visita':param, 
                        'success': this.success
                    });
            if(data.data.success){
                this.$router.push({name:'listarVisitas'});
            } else {
                this.modalOpsErro()
            }
            this.loadingPanel = false
        },

        async editarVisitas(codigo){
            this.loadingPanel = true
            var data = await new HttpRequest().Post('/roteirizador/cadastros/editar',
                {'id': codigo});
            var visita = data.data.dados
            this.vicodigo = visita.vicodigo;
            this.descricaoSelected = visita.videscricao;
            this.empresaSelected = [{'value':visita.vicliente, 'description':visita.clfantasia}];
            this.empresaSelecionadas = [visita.vicliente]
            this.descricaoVisitas = visita.videscricao
            this.loadingPanel = false
        },

        modalOpsErro(){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `
                Tente novamente mais tarde, se o erro persistir
                <br>Entre em contato com o suporte nos números: 
                <br>49 2049-4936 e 49 99118-2530 ou nos emails suporte@eagletrack.com.br e comercial@eagletrack.com.br.`,
                footer: ''
            })
        },
    },
    
    mounted(){
        this.loadingPanel = true
        if(this.$route.params.id){
            this.editarVisitas(this.$route.params.id)
        }
        this.loadingPanel = false
    }
})
</script>