<template>
    <panelEagle id='Modulos' :loading='loadingPanel'>
        <div class="col-sm-12 nopading row">
            <div class="col-sm-6 nopadding">
                <tituloPage 
                    :icon='mdiCellphoneWireless' 
                    titulo='Módulos'>
                </tituloPage>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 nopadding">
                <basicButtonsCadastrosSC
                    @salvarCadastro='salvarCadastro'
                    :disabled='$v.$invalid'
                    tipoCancela='button'
                    linkCancel="listarModulos">
                </basicButtonsCadastrosSC>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class="col-sm-12 nopadding mt-3">
            <div class="col-sm-6 divDontPrint nopadding">
                <buttonsFilters
                    label='Status*'
                    :arrayButtons='optFiltroChip'
                    @buttonsFiltersChange='mudaFiltroStatus'/>
            </div>
        </div>
        <div class="col-lg-12 nopadding row mt-3">
            <div class="col-4 nopadding">
                <inputSimple
                    :hasMask="true"
                    :mask="['NNNNNNNNNNNNNNNN']"
                    name='serial'
                    label="Serial*"
                    :value="serialSelecionado"
                    :inputClass='{"border border-danger": $v.serialSelecionado.$anyError}'
                    @keyup.native='$v.serialSelecionado.$touch()'
                    :inputDisabled="disableSerial"
                    @changeInput='changeSerial'>
                </inputSimple>
            </div>
            <div class="col-4 nopadding">
                <inputSimple
                    :hasMask="true"
                    :mask="['NNNNNNNNNNNNNNN']"
                    label="IMEI*"
                    name='imei'
                    :value="imeiSelecionado"
                    :inputClass='{"border border-danger": $v.imeiSelecionado.$anyError}'
                    @keyup.native='$v.imeiSelecionado.$touch()'
                    @changeInput='changeImei'
                />
            </div>
            <div class="col-4 nopadding">
                <selectAll
                    nameForRadio  ='SIM'
                    :isMultiple   ='false'
                    :labels       ='labelsSIM'
                    :optionsArray ='optSelectSim'
                    :hasSelectAll ='false'
                    :selected     ='simSelecionadoEditar'
                    :loading      ='loadingSim'
                    @changeSelect ='changeSim'
                />
                <!-- :extraClass='{"border border-danger": $v.simSelecionado.$anyError}'
                @close='$v.simSelecionado.$touch()' -->
            </div>   
        </div> 
        <div class="col-lg-12 nopadding row mt-3">          
            <div class="col-4 nopadding">
                <selectAll
                    nameForRadio  ='Modelo'
                    :isMultiple   ='false'
                    :labels       ='labelsModelo'
                    :optionsArray ='optSelectModelos'
                    :hasSelectAll ='false'
                    :selected     ='modelosSelecionadosEditar'
                    :loading      ='loadingModelos'
                    :extraClass='{"border border-danger": $v.modeloSelecionado.$anyError}'
                    @close='$v.modeloSelecionado.$touch()'
                    @changeSelect ='changeModelo'
                />
            </div> 
            <div class="col-4 nopadding">
                <selectAll
                    nameForRadio  ='Propriatario'
                    :isMultiple   ='false'
                    :labels       ='labelsEmpresa'
                    :optionsArray ='optSelectEmpresas'
                    :hasSelectAll ='false'
                    :selected     ='empresaSelecionadaEditar'
                    :extraClass='{"border border-danger": $v.empresaSelecionada.$anyError}'
                    @close='$v.empresaSelecionada.$touch()'
                    @changeSelect ='changeEmpresa'
                />
            </div>
            <div class="col-4 nopadding">
                <inputSingleDay
                    id="dataInstalacao"
                    name="dataInstalacao"
                    label="Data instalação"
                    :value ='dataInstalacaoSelecionadaEditar'
                    @changeInput="changeDataInstalacao">
                </inputSingleDay>
            </div>
            <div class='col-sm-12 nopadding'>
                <campoObrigatorio/>
            </div>
            <div class="col-sm-12 col-md-12"><hr></div>
        </div>
    </panelEagle>
</template>
<script>
    import {mdiCellphoneWireless }   from '@mdi/js'
    import Vue                     from 'vue'
    import { HttpRequest }         from '../../../../Services/auth/HttpRequest.Service'
    import { required, minLength } from 'vuelidate/lib/validators'
    import { EmpresasService }     from '@/Services/auth/Empresas.service'
    import { conectionError }      from '@/Services/Helpers/swellHeper'
    import { FiltrosService }      from '@/Services/filtros/filtros.Service'
    import { DateTime }            from "luxon";
export default Vue.extend({
    name:'cadastrarModulos',
    components:{
        'panelEagle'              : require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage'              : require('@/components/Atom/Header/Titulo').default,
        'BasicButtonsCadastrosSC' : require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
        'inputSimple'             : require('@/components/Atom/Inputs/InputSimple').default,
        'selectAll'               : require('@/components/Atom/Select/SelectAll').default,
        'campoObrigatorio'        : require('@/components/Atom/Footer/RequiredFields').default,
        'buttonsFilters'          : require('@/components/Atom/Buttons/ButtonsFilters').default,
        'inputSingleDay'          : require('@/components/Atom/Datas/InputSingleDay').default,
    },

    validations:{
        statusSelecionado:{ 
            required
        },
        serialSelecionado:{ 
            required, 
            minLength:minLength(4)
        },
        imeiSelecionado:{ 
            required, 
            minLength:minLength(15)
        },
        // simSelecionado:{ 
        //     required
        // },
        modeloSelecionado:{ 
            required
        },
        empresaSelecionada:{ 
            required
        },
        dataInstalacaoSelecionada:{ 
            required
        },
    },

    data(){
        return {
            mdiCellphoneWireless :mdiCellphoneWireless,
            optSelectEmpresas      : new EmpresasService().Get(),
            optFiltroChip:[
                {text:'Ativo',    value:'A', width:'49%', isSelected:true},
                {text:'Inativo',   value:'D', width:'49%', isSelected:false},
            ],
            optionsArray:  [],
            optSelectModelos : [],
            optSelectSim     : [],
            labelsSIM   :  [{
                    indexDFH    : 'S',
                    description : 'SIM'
                            }],

            labelsModelo   :  [{
                    indexDFH    : 'M',
                    description : 'Modelo*'
                            }],
            labelsEmpresa   :  [{
                    indexDFH    : 'P',
                    description : 'Proprietário*'
                            }],
            link                            : '/administrativo/cadastros/modulo/',
            statusSelecionado               : '',
            serialSelecionado               : '',
            imeiSelecionado                 : '',
            simSelecionado                  : [],
            modeloSelecionado               : [],
            empresaSelecionada              : [],
            dataInstalacaoSelecionada       : '',
            loadingSim                      : false,
            loadingModelos                  : false,
            simSelecionadoEditar            : [],
            empresaSelecionadaEditar        : [],
            modelosSelecionadosEditar       : [],
            idEditar                        : [],
            dataInstalacaoSelecionadaEditar : '',
            loadingPanel                    : false,
            disableSerial                   : '',
        }
    },
    methods:{

        async salvarCadastro(){
            this.loadingPanel = true
            var obj={
                'id'               : this.idEditar,
                'mostatus'         : this.statusSelecionado[0],
                'mocodigo'         : this.serialSelecionado,
                'moimei'           : this.imeiSelecionado,
                'mosim'            : this.simSelecionado[0],
                'momodelo'         : this.modeloSelecionado[0],
                'moproprietario'   : this.empresaSelecionada[0],
                'modatainstalacao' : this.dataInstalacaoSelecionada
            }
            new HttpRequest().Post(this.link+'salvar', obj).then((data)=>{
                if(data.data == 201){
                    this.$router.push({name:'listarModulos'})
                } else if(data.data.moduloCadastrado) {
                    let serial = this.serialSelecionado
                    conectionError(`${serial} Serial já cadastrado`, 'info')
                }else {
                    conectionError();
                }
                this.loadingPanel = false
            })
        },

        /**
         * Método preencher os selects e inputs na edição
         * @param Number id = código da tabela módulos
         * @return Arrays com os valores dos selects e inputs
         * @author Vitor Hugo 🐨
         */
        async editar(id){
            this.loadingPanel = true
            this.idEditar = id
            new HttpRequest().Post(this.link+'editar', id).then((data)=>{
                this.simSelecionadoEditar = data.data.chip
                this.empresaSelecionadaEditar = data.data.cliente
                this.modelosSelecionadosEditar = data.data.modelos
                this.serialSelecionado = data.data.modulo.mocodigo
                this.imeiSelecionado = data.data.modulo.moimei
                this.statusSelecionado = data.data.modulo.mostatus
                if(this.statusSelecionado == "D"){
                    this.optFiltroChip[0].isSelected = false
                    this.optFiltroChip[1].isSelected = true
                }
                if(data.data.modulo.modatainstalacao != null){
                    var dataEditar = data.data.modulo.modatainstalacao
                    this.dataInstalacaoSelecionadaEditar = DateTime.fromFormat(dataEditar, 'dd/MM/yyyy').toISO()
                }
                this.loadingPanel = false
            })
            this.disableSerial = 'disabled'
        },

        mudaFiltroStatus(status){
            this.statusSelecionado = status
        },

        changeSerial(serial){
            this.serialSelecionado = serial
        },

        changeImei(imei){
            this.imeiSelecionado = imei
        },

        changeSim(sim){
            this.simSelecionado = sim
        },

        changeModelo(modelo){
            this.modeloSelecionado = modelo
        },

        changeEmpresa(empresa){
            this.empresaSelecionada = empresa
        },

        changeDataInstalacao(dataInstatalacao){
            this.dataInstalacaoSelecionada = dataInstatalacao
        },

        /**
         * Método para buscar os modelos de rastreadores
         * @return Array com os modelos de rastreadores em formato para o select aceitar
         * @author Vitor Hugo 🐨
         */
        async buscaModelos(){
            this.loadingModelos = true
            new FiltrosService().dados_filtros([], ['MO'])
                .then((data)=>{
                    this.optSelectModelos = data.MO
                    this.loadingModelos = false
                })
        },

        /**
         * Método para buscar os chips que não estão associados
         * @return Array com os chips em formato para o select aceitar
         * @author Vitor Hugo 🐨
         */
        async buscarSim(){
            this.loadingSim = true
            new HttpRequest().Post(this.link+'buscar/sim').then((data)=>{
                this.optSelectSim = data.data.sim
                this.loadingSim = false
            })
        },
    },
    async mounted(){
        await this.buscaModelos()
        await this.buscarSim()
        if(this.$route.params.id){
            this.editar(this.$route.params.id)
        }
    }
})
</script>
<style lang="scss">
</style>
