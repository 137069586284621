<template>
    <panelEagle id='editarParametros'
        :loading="loadingPanel">
        <div class="col-sm-12 nopading row">
            <div class="col-sm-6 nopading pl-0">
                <tituloPage :icon="mdiAccountClock" titulo='Parâmetros'></tituloPage>
            </div>
            <div class="col-sm-6 nopading pr-0">
                <basicButtonsCadastrosSC
                    tipoCancela='button'
                    linkCancel='listarParametros' 
                    @salvarCadastro="salvar"
                    :disabled="
                        $v.$invalid || validationTable
                    "/>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class="col-sm-12 row tamanho-100">
            <tabsEagle :tabs="tabsPagina" 
                class="col-sm-12">
                <!-- PRIMEIRA TAB -->
                <!-- PRIMEIRA TAB -->
                <!-- PRIMEIRA TAB -->
                <slot slot="tab-horarios">
                    <br>
                    <!-- BOTEOS DE FILTRO -->
                    <div class="row nopadding ">
                        <!-- Comentado pq no sistema novo não esta sendo usado -->
                        <!-- <div class="col-sm-7 nopadding">
                            <buttonsFilters
                                label="Tipo de jornada*"
                                :arrayButtons="botoes"
                                @buttonsFiltersChange="changeHorariosButtonsFilters"/>
                        </div> -->
                        <div class="col-sm-6 margin-top-10px nopadding">
                            <inputSimple
                                label="Descrição*"
                                :inputClass='{"border border-danger":$v.dadosHorarios.jtdescricao.$anyError}'
                                @blur='$v.dadosHorarios.jtdescricao.$touch()'
                                @changeInput="changeHorariosInputDescricao"
                                :value="dadosHorarios.jtdescricao"/>
                        </div>
                        <div class="col-sm-6 margin-top-10px nopadding">
                            <selectAll
                                nameForRadio="Empresas"
                                :isMultiple="false"
                                :optionsArray="optEmpresa"
                                :extraClass='{"border border-danger":$v.dadosHorarios.empresasSelecionadas.$anyError}'
                                @close='$v.dadosHorarios.empresasSelecionadas.$touch()'
                                :labels="[{indexDFH:'EM', description: 'Empresas*'}]"
                                :selected="dadosHorarios.jtcliente"
                                @changeSelect="changeHorariosSelectEmpresas"
                                />
                        </div>
                        <div class="col-sm-12 row margin-top-10px nopadding">
                            <!-- TABELA DAS CHECKBOX -->
                            <b-form-group class="col-sm-12" v-slot="{ ariaDescribedby }">
                                <div class="col-sm-12 fonte-15-px">
                                    <table class="col-sm-12">
                                        <thead>
                                            <tr> 
                                                <th colspan="1"></th>
                                                <th colspan="2" class="espaco-dsr">
                                                    <b-form-radio
                                                        @change="changeRadioHorarioDsr('dsrManual', -1)"
                                                        v-model="dadosHorarios.jtdsrmanual"
                                                        :aria-describedby="ariaDescribedby"
                                                        name="some-radios" 
                                                        :value="true">
                                                        DSR manual
                                                    </b-form-radio>
                                                </th>
                                                <th colspan="4"></th>
                                            </tr>
                                            <tr>
                                                <th class="margin-th">Trabalho</th>
                                                <th class="margin-th text-left espaco-dsr">
                                                    DSR
                                                </th>
                                                <th class="margin-th espaco-dsr">Dia da semana</th>
                                                <!-- Comentado pq no sistema novo não esta sendo usado -->
                                                <!-- <template v-if="buttonsFiltersHorariosSelecionados[0] == 'JF'">
                                                    <th class="margin-th espaco-dsr">Início 1° Turno*</th>
                                                    <th class="margin-th espaco-dsr">Fim 1° Turno*</th>
                                                    <th class="margin-th espaco-dsr">Início 2° Turno*</th>
                                                    <th class="margin-th espaco-dsr">Fim 2° Turno*</th>
                                                </template> -->
                                                <template>
                                                    <th class="margin-th espaco-dsr">Total de horas*</th>
                                                    <th class="margin-th espaco-dsr">Intervalo*</th>
                                                </template>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(tabela, index) in dadosTabela" :key="index">
                                                <td class="espaco-dsr">
                                                    <b-form-checkbox
                                                        :disabled="tabela.model ? true : false"
                                                        @change="changeCheckboxTrabalho(index)"
                                                        :id="tabela.id"
                                                        v-model="checkboxTrabalho[index]"
                                                        :name="tabela.id"
                                                        :value="true"
                                                        :unchecked-value="false"/>
                                                </td>
                                                <td class="text-left espaco-dsr">
                                                    <b-form-radio v-if="tabela.dia != 'Feriado'"
                                                        @change="changeRadioHorarioDsr(tabela.dia, index)"
                                                        :aria-describedby="ariaDescribedby"
                                                        v-model="dadosTabela[index].model"
                                                        name="some-radios"
                                                        :value="true">
                                                    </b-form-radio>
                                                </td>
                                                <td class="espaco-dsr">{{tabela.dia}}</td>
                                                <!-- Comentado pq no sistema novo não esta sendo usado  -->
                                                <!-- <template v-if="buttonsFiltersHorariosSelecionados[0] == 'JF'"> 
                                                    <td>
                                                        <inputSimple
                                                            ref='refInputInicioPrimeiroTurnoHorarios'
                                                            :hasMask="true"
                                                            :mask="['hN:MN']"
                                                            @changeInput="changeInputHorariosPrimeiroTurno(
                                                                index, 'inicio')"
                                                            :disabled="!checkboxTrabalho[index]"/>
                                                    </td>
                                                    <td>
                                                        <inputSimple
                                                            ref='refInputFimPrimeiroTurnoHorarios'
                                                            :hasMask="true"
                                                            :mask="['hN:MN']"
                                                            @changeInput="changeInputHorariosPrimeiroTurno(
                                                                index, 'fim')"
                                                            :disabled="!checkboxTrabalho[index]"/>
                                                    </td>
                                                    <td>
                                                        <inputSimple
                                                            ref='refInputInicioSegundoTurnoHorarios'
                                                            :hasMask="true"
                                                            :mask="['hN:MN']"
                                                            @changeInput="changeInputHorariosSegundoTurno(
                                                                index, 'inicio')"
                                                            :disabled="!checkboxTrabalho[index]"/>
                                                    </td>
                                                    <td>
                                                        <inputSimple
                                                            ref='refInputFimSegundoTurnoHorarios'
                                                            :hasMask="true"
                                                            :mask="['hN:MN']"
                                                            @changeInput="changeInputHorariosSegundoTurno(
                                                                index, 'fim')"
                                                            :disabled="!checkboxTrabalho[index]"/>
                                                    </td>
                                                </template> -->
                                                <template>
                                                    <td>
                                                        <inputSimple
                                                            type="time"
                                                            name="inputTotalHoras"
                                                            ref='refInputTotalHoras'
                                                            @changeInput="v => changeInputHorariosTotalHoras(v, index)"
                                                            @blur="insereHorariosTotalHoras"
                                                            :value="dadosTabela[index].hjttotalhoras"
                                                            :disabled="!checkboxTrabalho[index]"/>
                                                    </td>
                                                    <td>
                                                        <inputSimple
                                                            type="time"
                                                            ref='refInputIntervalo'
                                                            @changeInput="v => changeInputHorariosIntervalo(v, index)"
                                                            @blur="insereHorariosIntervalo"
                                                            :value="dadosTabela[index].hjtintervalo"
                                                            :disabled="!checkboxTrabalho[index]"/>
                                                    </td>
                                                </template>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </b-form-group>
                        </div>
                    </div>
                </slot>
                <!-- SEGUNDA TAB -->
                <!-- SEGUNDA TAB -->
                <!-- SEGUNDA TAB -->
                <slot slot="tab-regras">
                    <br>
                    <div class="row nopadding ">
                    <!-- PRIMEIROS MENUS -->
                        <div class="col-sm-7 row nopadding">
                            <div class="col-sm-6 nopadding">
                                <inputSimple
                                    type="time"
                                    label="Tempo interjornada"
                                    :value="dadosRegras.jtinterjornada"
                                    @changeInput="changeInputInterjornada"/>
                            </div>
                            <div class="col-sm-6 nopadding">
                                <inputSimple
                                    type="time"
                                    label="Direção continua"
                                    :value="dadosRegras.jtdirecaocontinua"
                                    @changeInput="cangeInputDirecaoContinua"/>
                            </div>
                        </div>
                        <div class="col-sm-5 nopadding fonte-15-px">
                            <b-form-group class="text-left" label="Cálculo de horas com base em:" 
                                v-slot="{ ariaDescribedby }">
                                <b-form-radio-group
                                    id="radio-slots"
                                    :aria-describedby="ariaDescribedby"
                                    name="radio-options-slots"
                                    v-model="dadosRegras.jtbasecalculorelatorios" >
                                    <b-form-radio 
                                        value="P">
                                        Paradas
                                    </b-form-radio>
                                    <b-form-radio 
                                        value="I">
                                        Ignições
                                    </b-form-radio>
                                    <b-form-radio 
                                        value="A">
                                        App
                                    </b-form-radio>
                                    <b-form-radio
                                        value="R">
                                        Rfid
                                    </b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                        </div>
                        <div class="col-sm-12 col-md-12"><hr></div>
                        <!-- PERIODO VALIDO DE JORNADA -->
                        <span class="col-sm-12 text-left font-weight-bold">Período válido de jornada</span>
                        <div class="col-sm-12 row margin-top-10px nopadding fonte-15-px">
                            <div class="col-sm-3 nopadding">
                                <inputSimple
                                    type="time"
                                    label="Horário inicial* "
                                    :value="dadosRegras.jtperiodovalidoinicio"
                                    :inputClass='{"border border-danger":$v.dadosRegras.jtperiodovalidoinicio.$anyError}'
                                    @blur='$v.dadosRegras.jtperiodovalidoinicio.$touch()'
                                    @changeInput ="changePeriodoHoraInicial"/>
                            </div>
                            <div class="col-sm-3 nopadding row">
                                <inputSimple
                                    type="time"
                                    label="Horário final*"
                                    :value="dadosRegras.jtperiodovalidofim"
                                    :inputClass='{"border border-danger":$v.dadosRegras.jtperiodovalidofim.$anyError}'
                                    @blur='$v.dadosRegras.jtperiodovalidofim.$touch()'
                                    @changeInput ="changePeriodoHoraFinal"/>

                            </div>
                            <div class="col-sm-2 nopadding">
                                <inputSimple
                                    type="time"
                                    label="Tempo troca jornada"
                                    :value="dadosRegras.jttempoconsideratrocajornada"
                                    @changeInput ="changePeriodoTempoJornada"/>
                            </div>
                            <div class="col-sm-2 nopadding">
                                <!-- v-model="asd" -->
                                <inputSimple
                                label='Tempo tolerância'
                                type='time'
                                :value='dadosRegras.jttempotolerancia'
                                @changeInput="changeTempoTolerancia"/>
                            </div>
                            <div class="col-sm-2 nopadding">
                                <inputSimple
                                label='Tempo Extra'
                                type='time'
                                :value='dadosRegras.jttempoextra'
                                @changeInput="changeTempoExtra"/>
                            </div>
                        </div>
                        <div class="col-sm-12 row nopadding fonte-15-px">
                            <!-- <div class="col-sm-4 text-left margin-top-10px">
                            </div> -->
                            <div class="linha-checks d-flex justify-content-between">
                                <b-form-group label="Origem Registro Jornada" v-slot="{ ariaDescribedby }">
                                    <b-form-radio-group
                                        id="radio-slots-origem"
                                        :aria-describedby="ariaDescribedby"
                                        name="radio-options-slots-origem"
                                        v-model="dadosRegras.jtorigemjornada" >
                                        <b-form-radio 
                                            value="A">
                                            App
                                        </b-form-radio>
                                        <b-form-radio 
                                            value="R">
                                            Rfid
                                        </b-form-radio>
                                    </b-form-radio-group>
                                </b-form-group>
                                <b-form-checkbox
                                    id="check-jornada"
                                    name="check-jornada"
                                    :value="true"
                                    :unchecked-value="false"
                                    v-model="dadosRegras.jtmostradetalhesjornada">
                                    Mostrar detalhes da jornada
                                </b-form-checkbox>
                                <b-form-checkbox
                                    class="espaco-dsr"
                                    id="check-jornada-noturna"
                                    name="check-jornada-noturna"
                                    :value="true"
                                    :unchecked-value="false"
                                    v-model="dadosRegras.jtreduzida">
                                    Noturna reduzida
                                </b-form-checkbox>
                                <b-form-checkbox
                                    class="espaco-dsr"
                                    id="check-jornada-descanso"
                                    name="check-jornada-descanso"
                                    :value="true"
                                    :unchecked-value="false"
                                    v-model="dadosRegras.jtdescansoremunerado"
                                    :disabled="dadosRegras.jtorigemespera == 'A'   ||
                                               dadosRegras.jtorigemespera == 'R'  ?
                                                   true : false">
                                    Descanso Remunerado
                                </b-form-checkbox>
                                <b-form-checkbox
                                    class="espaco-dsr"
                                    id="check-jornada-indenizacao"
                                    name="check-jornada-indenizacao"
                                    :value="true"
                                    :unchecked-value="false"
                                    v-model="dadosRegras.jtindenização">
                                    Indenização
                                </b-form-checkbox>
                            </div>
                        </div>
                        <!-- FIM DO PERIODO VALIDO DE JORNADA -->
                        <div class="col-sm-12 col-md-12"><hr></div>
                        <!-- ESPERA -->
                        <div class="col-sm-12 row nopadding">
                            <span class="col-sm-12 text-left font-weight-bold">Espera</span>
                            <div class="col-sm-4 row margin-top-10px nopadding">
                                <div class="col-sm-12 row nopadding">
                                    <inputSimple
                                        type="time"
                                        class="col-sm-12"
                                        label="Tempo máximo em espera"
                                        :disabled="dadosRegras.jtorigemespera == 'A'   ||
                                                   dadosRegras.jtorigemespera == 'R' ?
                                                   true : false"
                                        :value="dadosRegras.jttempomaximoespera"
                                        @changeInput="changeEsperaTempoMaximo"/>
                                    <span class="text-left small col-sm-12">
                                        O excedente será considerado como hora extra
                                    </span>
                                </div>
                                <div class="col-sm-12 margin-top-10px nopadding">
                                    <inputSimple
                                        type="time"
                                        class="col-sm-12"
                                        label="Considerar somente tempo maior que"
                                        :disabled="dadosRegras.jtorigemespera == 'A'   ||
                                                   dadosRegras.jtorigemespera == 'R' ?
                                                   true : false"
                                        :value="dadosRegras.jttempoconsiderarespera"
                                        @changeInput="changeEsperaConsiderarTempoMaior"/>
                                </div>
                            </div>
                            <div class="col-sm-4 margin-top-10px fonte-15-px">
                                <b-form-group class="text-left" label="Origem espera" v-slot="{ ariaDescribedby }">
                                    <b-form-radio-group
                                        class="row"
                                        id="radio-slots-origem-espera"
                                        :aria-describedby="ariaDescribedby"
                                        name="radio-options-slots-origem-espera"
                                        v-model="dadosRegras.jtorigemespera"
                                        @change="disableRegrasEspera(dadosRegras.jtorigemespera)">
                                        <b-form-radio 
                                            class="col-sm-12 " 
                                            value="A">
                                            App
                                        </b-form-radio>
                                        <b-form-radio 
                                            class="col-sm-12" 
                                            value="R">
                                            Rfid
                                        </b-form-radio>
                                        <b-form-radio 
                                            class="col-sm-12" 
                                            value="P">
                                            Parâmetros
                                        </b-form-radio>
                                    </b-form-radio-group>
                                </b-form-group>
                            </div>
                            <div class="col-sm-4 text-left margin-top-10px nopadding fonte-15-px">
                                <b-form-group v-slot="{ ariaDescribedby }">
                                    <b-form-checkbox
                                        class="espaco-dsr"
                                        id="check-regras-detalhes-espera"
                                        :aria-describedby="ariaDescribedby"
                                        name="check-regras-detalhes-espera"
                                        :value="true"
                                        :unchecked-value="false"
                                        v-model="dadosRegras.jtmostradetalhesespera">
                                        Mostrar detalhes da espera
                                    </b-form-checkbox>
                                    <b-form-checkbox
                                        class="espaco-dsr"
                                        id="check-regras-disponibilidade-ponto"
                                        name="check-regras-disponibilidade-ponto"
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="dadosRegras.jtorigemespera == 'R' ||
                                                    dadosRegras.jtorigemespera == 'A' ? true : false"
                                        v-model="dadosRegras.jtconsideradisponibilidadeespera">
                                        Considerar hora espera por disponibilidade do ponto
                                    </b-form-checkbox>
                                </b-form-group>
                            </div>
                        </div>
                        <!-- FIM ESPERA -->
                        <div class="col-sm-12 col-md-12"><hr></div>
                        <!-- INTERVALO -->
                        <div class="col-sm-12 row nopadding">
                            <span class="col-sm-12 text-left font-weight-bold">Intervalo</span>
                            <div class="col-sm-4 row margin-top-10px nopadding">
                                <div class="col-sm-12 row nopadding">
                                    <inputSimple
                                    type="time"
                                    class="col-sm-12"
                                    label="Tempo minimo de intervalo*"
                                    :value="dadosRegras.jttempominimointervalo"
                                    :inputClass='{"border border-danger":$v.dadosRegras.jttempominimointervalo.$anyError}'
                                    @blur='$v.dadosRegras.jttempominimointervalo.$touch()'
                                    @changeInput="changeRegrasIntervaloTempoMinimo"/>
                                </div>
                                <div class="col-sm-12 margin-top-10px nopadding">
                                    <selectAll
                                    nameForRadio="ExcedenteIntervalo"
                                    :isMultiple="false"
                                    :optionsArray="optExcedenteIntervalo"
                                    :labels="[{indexDFH:'EM', description: 'Excedente de intervalo *'}]"
                                    :selected="dadosRegras.excedenteintervaloconsidera"
                                    :extraClass='{"border border-danger":$v.dadosRegras.jtexcedenteintervaloconsidera.$anyError}'
                                    @close='$v.dadosRegras.jtexcedenteintervaloconsidera.$touch()'
                                    @changeSelect="changeRegrasIntervaloEmpresas"/>
                                </div>
                            </div>
                            <div class="col-sm-4 margin-top-10px fonte-15-px">
                                <b-form-group class="text-left" label="Origem intervalo" 
                                    v-slot="{ ariaDescribedby }">
                                    <b-form-radio-group
                                        class="row"
                                        id="radio-slots-origem-intervalo"
                                        :aria-describedby="ariaDescribedby"
                                        v-model="dadosRegras.jtorigemintervalo"
                                        name="radio-options-slots-origem-intervalo"
                                        @change="disableRegrasIntervalo(dadosRegras.jtorigemintervalo)">
                                        <b-form-radio 
                                        class="col-sm-12 " 
                                        value="A">
                                            App
                                        </b-form-radio>
                                        <b-form-radio 
                                        class="col-sm-12" 
                                        value="R">
                                            Rfid
                                        </b-form-radio>
                                        <b-form-radio 
                                        class="col-sm-12" 
                                        value="P">
                                            Parâmetros
                                        </b-form-radio>
                                    </b-form-radio-group>
                                </b-form-group>
                            </div>
                            <div class="col-sm-4 text-left margin-top-10px nopadding fonte-15-px">
                                <b-form-group v-slot="{ ariaDescribedby }">
                                    <b-form-checkbox
                                        class="espaco-dsr"
                                        id="check-regras-detalhe-intervalo"
                                        :aria-describedby="ariaDescribedby"
                                        name="check-regras-detalhe-intervalo"
                                        :value="true"
                                        :unchecked-value="false"
                                        v-model="dadosRegras.jtmostradetalhesintervalo">
                                        Mostrar detalhes do intervalo
                                    </b-form-checkbox>
                                    <b-form-checkbox
                                        class="espaco-dsr"
                                        id="check-regras-intervalo-fora-ponto"
                                        name="check-regras-intervalo-fora-ponto"
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="dadosRegras.jtorigemintervalo == 'R' ||
                                                    dadosRegras.jtorigemintervalo == 'A' ? true : false"
                                        v-model="dadosRegras.jtconsideraintervaloforaponto">
                                        Considerar intervalo fora de ponto
                                    </b-form-checkbox>
                                    <b-form-checkbox
                                        class="espaco-dsr"
                                        id="check-regras-intervalo-dentro-ponto"
                                        name="check-regras-intervalo-dentro-ponto"
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="dadosRegras.jtorigemintervalo == 'R' ||
                                                    dadosRegras.jtorigemintervalo == 'A' ? true : false"
                                        v-model="dadosRegras.jtconsideraintervalodentroponto">
                                        Considerar intervalo dentro de ponto
                                    </b-form-checkbox>
                                    <b-form-checkbox
                                        class="espaco-dsr"
                                        id="check-regras-intervalo-dentro-ponto-espera"
                                        name="check-regras-intervalo-dentro-ponto-espera"
                                        :value="true"
                                        :unchecked-value="false"
                                        :disabled="dadosRegras.jtorigemintervalo == 'R' ||
                                                    dadosRegras.jtorigemintervalo == 'A' ? true : false"
                                        v-model="dadosRegras.jtconsideraintervalopontoespera">
                                        Considerar intervalo dentro de ponto espera
                                    </b-form-checkbox>
                                </b-form-group>
                            </div>
                        </div>
                        <!-- FIM INTERVALO -->
                        <div class="col-sm-12 col-md-12"><hr></div>
                        <!-- CHECKBOX FINAL -->
                        <div class="col-sm-12 row text-left nopadding fonte-15-px">
                            <div class="col-sm-12">
                                <b-form-checkbox
                                    id="jtmonitorapontojornada"
                                    name="check-monitorar"
                                    :value="true"
                                    :unchecked-value="false"
                                    v-model="dadosRegras.jtmonitorapontojornada"
                                    @change="changeMonitorarAberturaPontoReferencia">
                                    Monitorar abertura/fechamento de jornada com base em um ponto de referência
                                </b-form-checkbox>
                            </div>
                            <div
                            v-show="dadosRegras.jtmonitorapontojornada"
                            class="col-sm-6">
                                <selectAll
                                nameForRadio="PontosRegrasReferencia"
                                :isMultiple="false"
                                :optionsArray="optionsPontosRegras"
                                :selected="dadosRegras.pontos_referencia"
                                :extraClass='{"border border-danger":$v.dadosRegras.pontosReferencia.$anyError}'
                                @close='$v.dadosRegras.pontosReferencia.$touch()'
                                @changeSelect="changeRegrasMonitorarAberturaFechamento"/>
                            </div>
                            <div class="col-sm-12">
                                <b-form-checkbox
                                    id="jtdesassociasomenteporignicao"
                                    name="check-desassociar"
                                    :value="true"
                                    :unchecked-value="false"
                                    v-model="dadosRegras.jtdesassociasomenteporignicao">
                                    Desassociar cartões somente quando ignição for desligada
                                </b-form-checkbox>
                            </div>
                            <div class="col-sm-12">
                                <b-form-checkbox
                                    id="jtvisualizarsaldoextras"
                                    name="check-desassociar"
                                    :value="true"
                                    :unchecked-value="false"
                                    v-model="dadosRegras.jtvisualizarsaldoextras">
                                    Visualizar saldo horas extras
                                </b-form-checkbox>
                            </div>
                            <div class="col-sm-12">
                                <b-form-checkbox
                                    id="jtvisualizarinconformidades"
                                    name="check-desassociar"
                                    :value="true"
                                    :unchecked-value="false"
                                    v-model="dadosRegras.jtvisualizarinconformidades">
                                    Visualizar inconformidades
                                </b-form-checkbox>
                            </div>
                        </div>
                    </div>
                </slot>
                <!-- TERCEIRA TAB -->
                <!-- TERCEIRA TAB -->
                <!-- TERCEIRA TAB -->
                <slot slot="tab-parametros">
                    <br>
                    <div class="col-sm-12 row borda-collapse nopadding fonte-15-px">
                        <!-- Motoristas com ajudante -->
                        <div class="col-sm-4 row linha-lado tamanho-minimo nopadding">
                            <span class="col-sm-12 text-left"><strong>Motoristas com ajudante</strong></span>
                            <b-form-group 
                                class="col-sm-12 text-left " 
                                label="Considerar tempo dentro de ponto:" 
                                v-slot="{ ariaDescribedby }">
                                <b-form-radio-group
                                    class="row"
                                    id="radio-slots-motorista-com-ajudante"
                                    :aria-describedby="ariaDescribedby"
                                    name="radio-options-slots-motorista-com-ajudante"
                                    v-model="dadosParametros.jtjornadamotoristacomajudante">
                                    <b-form-radio 
                                        class="col-sm-12" 
                                        value="E">
                                        Como espera
                                    </b-form-radio>
                                    <b-form-radio 
                                        class="col-sm-12" 
                                        value="T">
                                        Como hora trabalhada
                                    </b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                            <div class="col-sm-12 col-md-12"><hr></div>
                            <b-form-group 
                                class="col-sm-12 text-left" 
                                label="Considerar tempo fora de ponto:" 
                                v-slot="{ ariaDescribedby }">
                                <b-form-radio-group
                                    class="row"
                                    id="radio-slots-tempo-fora"
                                    :aria-describedby="ariaDescribedby"
                                    name="radio-options-slots-tempo-fora"
                                    v-model="dadosParametros.jtmotoristaparadoforaponto">
                                    <b-form-radio 
                                        class="col-sm-12" 
                                        value="E">
                                        Como espera
                                    </b-form-radio>
                                    <b-form-radio 
                                        class="col-sm-12" 
                                        value="T">
                                        Como hora trabalhada
                                    </b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                        </div>
                        <!-- Motoristas sem ajudante -->
                        <div class="col-sm-4 row tamanho-minimo">
                            <span class="col-sm-12 text-left"><strong>Motoristas sem ajudante</strong></span>
                            <b-form-group class="col-sm-12 text-left" 
                                label="Considerar tempo dentro de ponto:" 
                                v-slot="{ ariaDescribedby }">
                                <b-form-radio-group
                                    class="row"
                                    id="radio-slots-motorista-sem-ajudante"
                                    :aria-describedby="ariaDescribedby"
                                    name="radio-options-slots-motorista-sem-ajudante"
                                    v-model="dadosParametros.jtjornadamotoristasemajudante">
                                    <b-form-radio 
                                        class="col-sm-12" 
                                        value="E">
                                        Como espera
                                    </b-form-radio>
                                    <b-form-radio 
                                        class="col-sm-12" 
                                        value="T">
                                        Como hora trabalhada
                                    </b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                            <div v-show="dadosParametros.jtjornadamotoristasemajudante == 'T'"
                                class="col-sm-12" >
                                <selectAll
                                    nameForRadio="PontosHoraEspera"
                                    :isMultiple="true"
                                    :optionsArray="optionsPontosParametros"
                                    :labels="[{indexDFH:'PHE', description: 'Pontos a considerar hora espera'}]"
                                    :disabled="$v.dadosHorarios.empresasSelecionadas.$invalid"
                                    :loading="loadingPontos"
                                    :selected="dadosParametros.pontosEspera"
                                    @changeSelect="changePontosConsiderarHoraEspera"
                                />
                                <selectAll
                                    nameForRadio="PontosNeutros"
                                    :isMultiple="true"
                                    :optionsArray="optionsPontosParametros"
                                    :labels="[{indexDFH:'PN', description: 'Pontos a considerar neutros'}]"
                                    :selected="dadosParametros.pontoNeutro"
                                    :disabled="$v.dadosHorarios.empresasSelecionadas.$invalid"
                                    :loading="loadingPontos"
                                    @changeSelect="changePontosConsiderarNeutros"
                                />
                            </div>
                            <div class="col-sm-12 col-md-12"><hr></div>
                            <b-form-group class="col-sm-12 text-left" 
                                label="Considerar tempo fora de ponto:" 
                                v-slot="{ ariaDescribedby }">
                                <b-form-radio-group
                                    class="row"
                                    id="radio-slots-tempo-fora-ponto-sem-ajudante"
                                    :aria-describedby="ariaDescribedby"
                                    name="radio-options-slots-tempo-fora-ponto-sem-ajudante"
                                    v-model="dadosParametros.jtmotoristasemajudanteparadoforaponto">
                                    <b-form-radio 
                                        class="col-sm-12" 
                                        value="E">
                                        Como espera
                                    </b-form-radio>
                                    <b-form-radio 
                                        class="col-sm-12" 
                                        value="T">
                                        Como hora trabalhada
                                    </b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                        </div>
                        <!-- Ajudantes -->
                        <div class="col-sm-4 row linha-lado tamanho-minimo-diferente">
                            <span class="col-sm-12 text-left"><strong>Ajudantes</strong></span>
                            <b-form-group 
                                class="col-sm-12 text-left" 
                                label="Considerar tempo em movimento:" 
                                v-slot="{ ariaDescribedby }">
                                <b-form-radio-group
                                    class="row"
                                    id="radio-slots-ajudantes"
                                    :aria-describedby="ariaDescribedby"
                                    name="radio-options-slots-ajudantes"
                                    v-model="dadosParametros.jtjornadaajudante">
                                    <b-form-radio 
                                        class="col-sm-12" 
                                        value="E">
                                        Como espera
                                    </b-form-radio>
                                    <b-form-radio 
                                        class="col-sm-12" 
                                        value="T">
                                        Como hora trabalhada
                                    </b-form-radio>
                                </b-form-radio-group>
                            </b-form-group>
                            <div class="col-sm-12 tamanho-div-fake"></div>
                        </div>
                    </div>
                </slot>
                <!-- QUARTA TAB -->
                <!-- QUARTA TAB -->
                <!-- QUARTA TAB -->
                <slot slot="tab-bancoDeHoras">
                    <br>
                    <div class="col-sm-12 row fonte-15-px">
                        <div class="col-sm-12 row nopadding">
                            <span class="col-sm-1"></span>
                            <span class="col-sm-3 text-left font-weight-bold">Parâmetros</span>
                            <span class="col-sm-4 text-left font-weight-bold">Operação</span>
                            <span class="col-sm-4 text-left font-weight-bold">Conversão</span>
                        </div>
                        <template v-for="(tabela, index) in bancoDeHoras" class="col-sm-12">
                            <div :key="index+'a'" class="col-sm-1 margin-top-10px nopadding">
                                <b-form-checkbox 
                                    class="text-left"
                                    :id="tabela.value+''"
                                    name="check-banco-de-horas"
                                    :value="true"
                                    :unchecked-value="false"
                                    @change="changeCheckBoxBancoDeHoras(checkBoxBancoDeHoras[index], index)"
                                    v-model="checkBoxBancoDeHoras[index]">
                                </b-form-checkbox>
                            </div>
                            <div :key="index+'b'" 
                                class="col-sm-3 text-left margin-top-10px">
                                {{tabela.descricao}}
                            </div>
                            <div :key="index" 
                                class="col-sm-4 margin-top-10px nopadding">
                                <selectAll
                                    nameForRadio='selectAllBancoHorasOperação'
                                    :isMultiple="false"
                                    :optionsArray="optionsBancoOperacao"
                                    :disabled="!checkBoxBancoDeHoras[index]"
                                    :selected="bhOperacao[index]"
                                    @changeSelect="v=> changeBancoHorasOperacao(index, v)"
                                    ref="selectBancoHorasOperacao"/>
                            </div>
                            <div :key="index+'c'" 
                                class="col-sm-4 margin-top-10px nopadding">
                                <inputSimple
                                    type="number"
                                    :disabled="!checkBoxBancoDeHoras[index]"
                                    :value="dadosBancoHoras[index].bhptaxaconversao"
                                    @changeInput="v=> changeBancoHorasConversao(index, v)"
                                    ref="inputBancoHorasConversao"/>
                            </div>
                        </template>
                    </div>
                </slot>
                <!-- QUINTA TAB -->
                <!-- QUINTA TAB -->
                <!-- QUINTA TB -->
                <slot slot="tab-coletivo" class="">
                    <br>
                    <div class="col-sm-12 row nopadding fonte-15-px">
                        <div class="col-sm-6">
                            <b-form-checkbox 
                                class="text-left"
                                id="'checkColetivos1"
                                name="check-coletivos"
                                :value="true"
                                :unchecked-value="false"
                                v-model="dadosColetivos.jtmotoristapodeassociarmaisescala">
                                Motorista pode ser associado em mais de uma escala 
                            </b-form-checkbox>
                        </div>
                        <div class="col-sm-6">
                            <b-form-checkbox 
                                class="text-left"
                                id="'checkColetivos2"
                                name="check-coletivos"
                                :value="true"
                                :unchecked-value="false"
                                v-model="dadosColetivos.jtmotoristapodeescolherveiculo">
                                Motorista pode escolher veículo da escala
                            </b-form-checkbox>
                        </div>
                    </div>
                </slot>
                <!-- SEXTA TAB -->
                <!-- SEXTA TAB -->
                <!-- SEXTA TAB -->
                <slot slot="tab-horaExtra">
                    <br>
                    <div class="col-sm-12 row nopadding fonte-15-px" v-for="(dados, index) in horaExtra" 
                        :key="index">
                        <div class="col-sm-2 margin-top-10px pad-top ">
                            <b-form-checkbox 
                            class="text-left"
                            :id="'checkHoraExtras'+index"
                            name="check-hora-extra"
                            :value="true"
                            :unchecked-value="false"
                            @change="changeCheckHoraExtra(checkHoraExtra[index], index)"
                            v-model="checkHoraExtra[index]">
                                {{dados.descricao}}
                            </b-form-checkbox>
                        </div>
                        <div class="col-sm-3 row margin-top-10px nopadding">
                            <span class="col-sm-2 pad-top ">De:</span>
                            <div class="col-sm-10 nopadding">
                                <inputSimple 
                                type="time"
                                step="2"
                                ref="inputHoraExtraDe"
                                :disabled="!checkHoraExtra[index]"
                                :value="dadosHoraExtra[index].hephorainicio"
                                @changeInput="v=> changeHoraExtraDe(index, v)"
                                />
                            </div>
                        </div>
                            <div class="col-sm-3 row margin-top-10px nopadding">
                                <span class="col-sm-2 pad-top">Até:</span>
                                <div class="col-sm-10 nopadding">
                                    <inputSimple 
                                    type="time"
                                    step="2"
                                    ref="inputHoraExtraAte"
                                    :value="dadosHoraExtra[index].hephorafim"
                                    :disabled="!checkHoraExtra[index]"
                                    @changeInput="v=> changeHoraExtraAte(index, v)"
                                    />
                                </div>
                        </div>
                        <div class="col-sm-4 row margin-top-10px">
                            <div style="display:flex" class="col-sm-10">
                                <span class="col-sm-3 pad-top">
                                    Legenda:
                                </span>
                                <textarea
                                class="col-sm-9"
                                name="rogerinho"
                                ref="inputHoraExtraLegenda"
                                :disabled="!checkHoraExtra[index]"
                                v-model="dadosHoraExtra[index].heplegenda"/>
                            </div>
                        </div>
                    </div>
                </slot>
            </tabsEagle>
            <requiredFields/>
            <div class="col-sm-12"><hr></div>
        </div>
    </panelEagle>
</template>
<script>
// import Vue                 from 'vue'
import {mdiAccountClock} from '@mdi/js'
import { conectionError }  from '@/Services/Helpers/swellHeper'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest }     from '@/Services/auth/HttpRequest.Service'
export default {
    name:"EditarParametros",
    components:{
        'panelEagle'               : require('@/components/Atom/Panel/PanelEagle').default,
        'basicButtonsCadastrosSC'  : require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
        'tituloPage'               : require('@/components/Atom/Header/Titulo').default,
        'inputSimple'              : require('@/components/Atom/Inputs/InputSimple').default,
        'tabsEagle'                : require('@/components/Atom/Tabs/TabsEagle').default,
        'selectAll'                : require('@/components/Atom/Select/SelectAll').default,
        'requiredFields'           : require('@/components/Atom/Footer/RequiredFields').default
    },

    validations:{
        dadosHorarios: {
            jtdescricao:{ required },
            empresasSelecionadas:{ required },
        },

        dadosRegras: {
            jtperiodovalidoinicio: { required },
            jtperiodovalidofim: { required },
            jttempominimointervalo : { required },
            jtexcedenteintervaloconsidera : { required },
            pontosReferencia:{
                requiredIf:requiredIf(function(){
                    return this.dadosRegras.jtmonitorapontojornada
                })
            }
        },

        validationTotalHoras:{ required },
        campoVazioBanco:{ required },
        campoVazioHorarios:{ required },
        campoVazioExtra:{ required },
        
    },

    data(){
        return {
                mdiAccountClock:mdiAccountClock,
            link:'/controle/jornada/cadastros/parametros/',
            idEditar : '',
            tabsPagina:[
                {'title' : 'Horários',       'value' : 'horarios'},
                {'title' : 'Regras',         'value' : 'regras'},
                {'title' : 'Parâmetros',     'value' : 'parametros'},
                {'title' : 'Banco de horas', 'value' : 'bancoDeHoras'},
                {'title' : 'Coletivos',      'value' : 'coletivo'},
                {'title' : 'Hora extra',     'value' : 'horaExtra'},
            ],
            loadingPanel     : false,
            checkBoxIntervalo:[
                {'value':false, 'descricao':'Mostrar detalhes do intervalo'},
                {'value':false, 'descricao':'Considerar intervalo fora de ponto'},
                {'value':false, 'descricao':'Considerar intervalo dentro de ponto'},
                {'value':false, 'descricao':'Considerar intervalo dentro de ponto espera'},
            ],
            bancoDeHoras:[
                {'value':1, 'descricao':'Compensação'},
                {'value':2, 'descricao':'Extra 100%'},
                {'value':3, 'descricao':'Extra Noturno'},
                {'value':4, 'descricao':'Falta'},
                {'value':5, 'descricao':'Folga Banco Horas'},
                {'value':6, 'descricao':'Horas extras 1'},
                {'value':7, 'descricao':'Horas extras 2'},
                {'value':8, 'descricao':'Horas extras 3'},
            ],
            horaExtra:[
                {'value':1, 'descricao':'Horas extras 1'},
                {'value':2, 'descricao':'Horas extras 2'},
                {'value':3, 'descricao':'Horas extras 3'},
            ],
            checkboxTrabalho:[
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
            ],
            optEmpresa  : new EmpresasService().Get(),
            valorSelecionadoIntervalo  : '',
            valorSelecionadoTotalHoras : '',
            botoes      : [
                {
                    'value': 'JF',
                    'width': '49%',
                    'isSelected': true,
                    'title': '',
                    'text': 'Jornada fixa',
                    'extraClass':'',
                    'icon': ''
                },
                {
                    'value': 'JL',
                    'width': '49%',
                    'isSelected': false,
                    'title': '',
                    'text': 'Jornada livre',
                    'icon': ''
                },
            ],
            dadosTabela:[
                {
                    'id'                  : 'checkBoxDomingo',
                    'dia'                 : 'Domingo',
                    'model'               : true,
                    'nameRadio'           : 'radio-domingo',
                    'primeiroTurnoInicio' : '',
                    'primeiroTurnoFim'    : '',
                    'segundoTurnoInicio'  : '',
                    'segundoTurnoFim'     : '',
                    'desabiltado'         : '',
                    'hjttotalhoras'       : '',
                    'hjtintervalo'        : '',
                    'hjtcodigo'           : '',
                },
                {
                    'id'                  : 'checkBoxSegunda',
                    'dia'                 : 'Segunda',
                    'model'               : false,
                    'nameRadio'           : 'radio-segunda',
                    'primeiroTurnoInicio' : '',
                    'primeiroTurnoFim'    : '',
                    'segundoTurnoInicio'  : '',
                    'segundoTurnoFim'     : '',
                    'desabiltado'         : '',
                    'hjttotalhoras'       : '',
                    'hjtintervalo'        : '',
                    'hjtcodigo'           : '',
                },
                {
                    'id'                  : 'checkBoxTerca',
                    'dia'                 : 'Terça',
                    'model'               : false,
                    'nameRadio'           : 'radio-terca',
                    'primeiroTurnoInicio' : '',
                    'primeiroTurnoFim'    : '',
                    'segundoTurnoInicio'  : '',
                    'segundoTurnoFim'     : '',
                    'desabiltado'         : '',
                    'hjttotalhoras'       : '',
                    'hjtintervalo'        : '',
                    'hjtcodigo'           : '',
                },
                {
                    'id'                  : 'checkBoxQuarta',
                    'dia'                 : 'Quarta',
                    'model'               : false,
                    'nameRadio'           : 'radio-quarta',
                    'primeiroTurnoInicio' : '',
                    'primeiroTurnoFim'    : '',
                    'segundoTurnoInicio'  : '',
                    'segundoTurnoFim'     : '',
                    'desabiltado'         : '',
                    'hjttotalhoras'       : '',
                    'hjtintervalo'        : '',
                    'hjtcodigo'           : '',
                },
                {
                    'id'                  : 'checkBoxSQuinta',
                    'dia'                 : 'Quinta',
                    'model'               : false,
                    'nameRadio'           : 'radio-quinta',
                    'primeiroTurnoInicio' : '',
                    'primeiroTurnoFim'    : '',
                    'segundoTurnoInicio'  : '',
                    'segundoTurnoFim'     : '',
                    'desabiltado'         : '',
                    'hjttotalhoras'       : '',
                    'hjtintervalo'        : '',
                    'hjtcodigo'           : '',
                },
                {
                    'id'                  : 'checkBoxSexta',
                    'dia'                 : 'Sexta',
                    'model'               : false,
                    'nameRadio'           : 'radio-sexta',
                    'primeiroTurnoInicio' : '',
                    'primeiroTurnoFim'    : '',
                    'segundoTurnoInicio'  : '',
                    'segundoTurnoFim'     : '',
                    'desabiltado'         : '',
                    'hjttotalhoras'       : '',
                    'hjtintervalo'        : '',
                    'hjtcodigo'           : '',
                },
                {
                    'id'                  : 'checkBoxSabado',
                    'dia'                 : 'Sábado',
                    'model'               : false,
                    'nameRadio'           : 'radio-sabado',
                    'primeiroTurnoInicio' : '',
                    'primeiroTurnoFim'    : '',
                    'segundoTurnoInicio'  : '',
                    'segundoTurnoFim'     : '',
                    'desabiltado'         : '',
                    'hjttotalhoras'       : '',
                    'hjtintervalo'        : '',
                    'hjtcodigo'           : '',
                },
                {
                    'id'                  : 'checkBoxFeriado',
                    'dia'                 : 'Feriado',
                    'model'               : false,
                    'nameRadio'           : 'radio-feriado',
                    'primeiroTurnoInicio' : '',
                    'primeiroTurnoFim'    : '',
                    'segundoTurnoInicio'  : '',
                    'segundoTurnoFim'     : '',
                    'desabiltado'         : '',
                    'hjttotalhoras'       : '',
                    'hjtintervalo'        : '',
                    'hjtcodigo'           : '',
                },
            ],

            //Horarios
            campoVazioHorarios   : '',
            validationTotalHoras : false,
            dadosHorarios:{
                'buttonsFiltersHorariosSelecionados' : '',
                'jtdescricao'                        : '',
                'jtcliente'                          : [],
                'jtdsrmanual'                        : false,
                'jtdsr'                              : '',
                'empresasSelecionadas'               : [],
            },


            //Regras
            optExcedenteIntervalo:[
                {value:'E', description:"Extra"}, 
                {value:'EC', description:"Extra 100%"},
                {value:'HE', description:"Hora espera"},
                {value:'HF', description:"Hora falta"},
                {value:'T', description:"Trabalhada"}
            ],
            checkBoxEspera:[
                {'value':'mostrarDetalhesEspera', 'descricao':'Mostrar detalhes da espera'},
                {'value':'ConsiderarHoraEspera', 'descricao':'Considerar hora espera por disponibilidade do ponto'},
            ],
            optionsEspera: [
                { text: 'Mostrar detalhes da espera ', value: 'mostrarDetalhesEspera' },
                { text: 'Considerar hora espera por disponibilidade do ponto', 
                    value: 'ConsiderarHoraEspera', 
                    disabled:true },
            ],
            optionsIntervalo:[
                { text: 'Mostrar detalhes do intervalo '              , value:'mostrarDetalhesIntervalo' },
                { text: 'Considerar intervalo fora de ponto'          , value:'ConsiderarForaPonto', 
                    disabled: true},
                { text: 'Considerar intervalo dentro de ponto'        , value:'ConsiderarDentroPonto', 
                    disabled: true},
                { text: 'Considerar intervalo dentro de ponto espera' , value:'ConsiderarForaPontoEspera',
                    disabled: true},
            ],
            optionsPontosRegras                  : [],
            dadosRegras:{
                'jtinterjornada'                    : '',
                'jtdirecaocontinua'                 : '',
                'jtperiodovalidoinicio'             : '00:00:00',
                'jtperiodovalidofim'                : '23:59:00',
                'jttempoconsideratrocajornada'      : '',
                'tempoMaximoEspera'                 : '',
                'jttempomaximoespera'               : '',
                'considerarTempoMaior'              : '',
                'jttempominimointervalo'            : '00:30:00',  
                'jtbasecalculorelatorios'           : 'P',
                'jtmostradetalhesjornada'           : false,
                'jtorigemjornada'                   : 'A',
                'jtreduzida'                        : false,
                'jtdescansoremunerado'              : false,
                'jtindenização'                     : false,
                'jtorigemespera'                    : 'P',
                'regrasEsperaMostrarDetalhesEspera' : false,
                'jtmostradetalhesespera'            : false,
                'jtconsideradisponibilidadeespera'  : false,
                'jtmostradetalhesintervalo'         : false,
                'jtconsideraintervaloforaponto'     : false,
                'jtconsideraintervalodentroponto'   : false,
                'jtconsideraintervalopontoespera'   : false,
                'jtorigemintervalo'                 : 'P', 
                'jtmonitorapontojornada'            : false,  
                'jtdesassociasomenteporignicao'     : false,
                'pontos_referencia'                 : [],
                'pontosReferencia'                  : [],
                'excedenteintervaloconsidera'       : [],
                'jtexcedenteintervaloconsidera'     : [],
                'jttempoconsiderarespera'           : '',
                'jtvisualizarsaldoextras'           : false,
                'jtvisualizarinconformidades'       : false,
                'jttempotolerancia'                 : '',
                'jttempoextra'                      : '',
            },

            //Parametros
            optionsPontosParametros : [],
            dadosParametros:{
                'jtjornadamotoristacomajudante'         : 'E',
                'jtmotoristaparadoforaponto'            : 'E',
                'jtjornadamotoristasemajudante'         : 'E',
                'jtmotoristasemajudanteparadoforaponto' : 'E',
                'jtjornadaajudante'                     : 'E',
                'pontosEspera'                          : [],
                'pontoNeutro'                           : [],
                'jpeponto'                              : [],
                'jpnponto'                              : [],
            },
            loadingPontos : false,

            //Banco de Horas
            bhOperacao : [],
            checkBoxBancoDeHoras : [false, false, false, false, false, false, false, false],
            optionsBancoOperacao : [{value:1, description:"Crédito"}, {value:2, description:"Débito"}],
            campoVazioBanco      : '',
            dadosBancoHoras:[
                {
                    'id'               : 'checkBoxCompensacao',
                    'parametros'       : 'Compensacao',
                    'bhpdado'          : 5,
                    'model'            : false,
                    'nameRadio'        : 'radio-compensacao',
                    'bhpoperacao'      : '',
                    'bhptaxaconversao' : '',
                    'bhpcodigo'        : '',
                },
                {
                    'id'               : 'checkBoxExtra100',
                    'parametros'       : 'Extra100',
                    'bhpdado'          : 3,
                    'model'            : false,
                    'nameRadio'        : 'radio-extra100',
                    'bhpoperacao'      : '',
                    'bhptaxaconversao' : '',
                    'bhpcodigo'        : '',
                },
                {
                    'id'               : 'checkBoxExtraNoturno',
                    'parametros'       : 'ExtraNoturno',
                    'bhpdado'          : 4,
                    'model'            : false,
                    'nameRadio'        : 'radio-ExtraNoturno',
                    'bhpoperacao'      : '',
                    'bhptaxaconversao' : '',
                    'bhpcodigo'        : '',
                },
                {
                    'id'               : 'checkBoxFalta',
                    'parametros'       : 'Falta',
                    'bhpdado'          : 7,
                    'model'            : false,
                    'nameRadio'        : 'radio-Falta',
                    'bhpoperacao'      : '',
                    'bhptaxaconversao' : '',
                    'bhpcodigo'        : '',
                },
                {
                    'id'               : 'checkBoxFaltaBancoHoras',
                    'parametros'       : 'FaltaBancoHoras',
                    'bhpdado'          : 6,
                    'model'            : false,
                    'nameRadio'        : 'radio-FaltaBancoHoras',
                    'bhpoperacao'      : '',
                    'bhptaxaconversao' : '',
                    'bhpcodigo'        : '',
                },
                {
                    'id'               : 'checkBoxHoraExtra1',
                    'parametros'       : 'HoraExtra1',
                    'bhpdado'          : 1,
                    'model'            : false,
                    'nameRadio'        : 'radio-HoraExtra1',
                    'bhpoperacao'      : '',
                    'bhptaxaconversao' : '',
                    'bhpcodigo'        : '',
                },
                {
                    'id'               : 'checkBoxHoraExtra2',
                    'parametros'       : 'HoraExtra2',
                    'bhpdado'          : 2,
                    'model'            : false,
                    'nameRadio'        : 'radio-HoraExtra2',
                    'bhpoperacao'      : '',
                    'bhptaxaconversao' : '',
                    'bhpcodigo'        : '',
                },
                {
                    'id'               : 'checkBoxHoraExtra3',
                    'parametros'       : 'HoraExtra3',
                    'bhpdado'          : 8,
                    'model'            : false,
                    'nameRadio'        : 'radio-HoraExtra3',
                    'bhpoperacao'      : '',
                    'bhptaxaconversao' : '',
                    'bhpcodigo'        : '',
                },
            ],

            //Coletivos
            dadosColetivos: {
                'jtmotoristapodeassociarmaisescala' : true,
                'jtmotoristapodeescolherveiculo'     : true,
            },

            // Hora Extra
            checkHoraExtra : [false, false, false,],
            campoVazioExtra : '',
            dadosHoraExtra : [
                {
                    'id':'checkBoxHoraExtra1',
                    'parametros':'HoraExtra1',
                    'model':false,
                    'nameRadio':'radio-hora-extra1',
                    'hephorainicio':'',
                    'hephorafim':'',
                    'heplegenda':'',
                    'heptipo' : '',
                },
                {
                    'id':'checkBoxHoraExtra2',
                    'parametros':'HoraExtra2',
                    'model':false,
                    'nameRadio':'radio-hora-extra2',
                    'hephorainicio':'',
                    'hephorafim':'',
                    'heplegenda':'',
                    'heptipo' : '',
                },
                {
                    'id':'checkBoxHoraExtra3',
                    'parametros':'HoraExtra3',
                    'model':false,
                    'nameRadio':'radio-hora-extra3',
                    'hephorainicio':'',
                    'hephorafim':'',
                    'heplegenda':'',
                    'heptipo' : '',
                },
            ],
            
        }
    },

    methods:{
        async salvar(){
            this.loadingPanel = true
            var obj = {
                'dadosTabela':     this.dadosTabela,
                'dadosHorarios' :  this.dadosHorarios,
                'dadosRegras':     this.dadosRegras,
                'dadosBancoHoras': this.dadosBancoHoras,
                'dadosParametros': this.dadosParametros,
                'dadosColetivos' : this.dadosColetivos,
                'dadosHoraExtra' : this.dadosHoraExtra,
                'checkHoraExtra' : this.checkHoraExtra,
                'checkBoxBancoDeHoras' : this.checkBoxBancoDeHoras,
                'bhOperacao'           : this.bhOperacao,
                'jttipo'               : 'L',
                'id' : this.idEditar
            }
            var url = this.link+this.salvaEdita
            new HttpRequest().Post(url, obj).then((data)=>{
                if(data.data?.success)
                    this.$router.push({name: 'listarParametros'})
                else
                    conectionError()
            }).finally(()=>{this.loadingPanel = false})
        },

        //Horarios -------------------------------------------------------------------------------------------
        // changeHorariosButtonsFilters(buttons){
        //     this.buttonsFiltersHorariosSelecionados = buttons
        // },
        changeHorariosInputDescricao(descricao){
            this.dadosHorarios.jtdescricao = descricao
        },
        changeHorariosSelectEmpresas(empresas){
            this.dadosHorarios.empresasSelecionadas = empresas
            this.getPontosReferencia(empresas)
            this.buscaPontosCliente(empresas)
        },
        // Comentado pois, o sistema novo não esta utilizando
        // changeInputHorariosPrimeiroTurno(index, controle){
        //     if(controle == 'inicio'){
        //         var hora = this.$refs.refInputInicioPrimeiroTurnoHorarios[index].$data.inputValue[0]+
        //                 this.$refs.refInputInicioPrimeiroTurnoHorarios[index].$data.inputValue[1]
        //         if(Number(hora) >= 24){
        //             this.$refs.refInputInicioPrimeiroTurnoHorarios[index].$data.inputValue = '23:59'
        //         }
        //         this.dadosTabela[index].primeiroTurnoInicio = this.$refs.refInputInicioPrimeiroTurnoHorarios[index].$data.inputValue 
        //     }else{
        //         var horas = this.$refs.refInputFimPrimeiroTurnoHorarios[index].$data.inputValue[0]+
        //                 this.$refs.refInputFimPrimeiroTurnoHorarios[index].$data.inputValue[1]
        //         if(Number(horas) >= 24){
        //             this.$refs.refInputFimPrimeiroTurnoHorarios[index].$data.inputValue = '23:59'
        //         }
        //         this.dadosTabela[index].primeiroTurnoFim = this.$refs.refInputFimPrimeiroTurnoHorarios[index].$data.inputValue
        //     }
        // },
        // changeInputHorariosSegundoTurno(index, controle){
        //     if(controle == 'inicio'){ // refInputInicioSegundoTurnoHorarios // segundoTurnoInicio
        //        var horaSegundo = this.$refs.refInputInicioSegundoTurnoHorarios[index].$data.inputValue[0]+
        //                 this.$refs.refInputInicioSegundoTurnoHorarios[index].$data.inputValue[1]
        //         if(Number(horaSegundo) >= 24){
        //             this.$refs.refInputInicioSegundoTurnoHorarios[index].$data.inputValue = '23:59'
        //         }
        //         this.dadosTabela[index].segundoTurnoInicio = this.$refs.refInputInicioSegundoTurnoHorarios[index].$data.inputValue
        //     }else{
        //         var horasSegundo = this.$refs.refInputFimSegundoTurnoHorarios[index].$data.inputValue[0]+
        //                 this.$refs.refInputFimSegundoTurnoHorarios[index].$data.inputValue[1]
        //         if(Number(horasSegundo) >= 24){
        //             this.$refs.refInputFimSegundoTurnoHorarios[index].$data.inputValue = '23:59'
        //         }
        //         this.dadosTabela[index].segundoTurnoFim = this.$refs.refInputFimSegundoTurnoHorarios[index].$data.inputValue
        //     }
        // },


        changeInputHorariosTotalHoras(valor, index){
            this.valorSelecionadoTotalHoras = valor  
            this.dadosTabela[index].hjttotalhoras = valor
            this.campoVazioHorarios = this.verificaCamposVaziosHorarios()
        },

        /** 
         * Método para inserir horário nos campos de horas totais que estão selecionados na tabela
         * @param Arrays Checkbox, dadosTabela (hjttotalhoras)
         * @return Array, dadosTabela com os campos hjttotalhoras inseridos com o valor informado
         * @author Vitor Hugo 🐨
         */
        insereHorariosTotalHoras(){
            for (let index = 0; index < this.dadosTabela.length; index++) {
                if(this.checkboxTrabalho[index] && this.dadosTabela[index].hjttotalhoras == ''){
                    this.dadosTabela[index].hjttotalhoras = this.valorSelecionadoTotalHoras
                }
                
            }
        },

        changeInputHorariosIntervalo(valor, index){
            this.valorSelecionadoIntervalo = valor
            this.dadosTabela[index].hjtintervalo = valor
            this.campoVazioHorarios = this.verificaCamposVaziosHorarios()
        },

        /** 
         * Método para inserir horário nos campos de intervalo que estão selecionados na tabela
         * @param Arrays Checkbox, dadosTabela (hjttotalhoras)
         * @return Array, dadosTabela com os campos hjtintervalo inseridos com o valor informado
         * @author Vitor Hugo 🐨
         */
        insereHorariosIntervalo(){
            for (let index = 0; index < this.dadosTabela.length; index++) {
                if(this.checkboxTrabalho[index] && this.dadosTabela[index].hjtintervalo == ''){
                    this.dadosTabela[index].hjtintervalo = this.valorSelecionadoIntervalo
                }
                
            }
        },

        /** 
         * Método para varificar se na TAB Horarios possuí um campo vazio mesmo 
         * com algum input ou check selecionado
         * @param Arrays Checkbox, dadosTabela (hjttotalhoras, hjtintervalo)
         * @return String, vazia se possuír algum campo selecionado vazio e 'false' se não 
         *  tiver campo selecionado vazio
         * @author Vitor Hugo 🐨
         */
        verificaCamposVaziosHorarios(index){
            for (let index = 0; index < this.checkboxTrabalho.length; index++) {
                if(this.checkboxTrabalho[index]){
                    if(this.dadosTabela[index].hjttotalhoras == '' || 
                        this.dadosTabela[index].hjtintervalo == ''){
                            return ''
                        }
                }  
            }
            return 'false' 
        },

        changeRadioHorarioDsr(value, index){
            if(index == -1){
                for (const dados in this.dadosTabela){
                    this.dadosTabela[dados].model = false
                }
            }else{
                this.dadosHorarios.jtdsrmanual = false
                for (const arr in this.dadosTabela) {
                    this.dadosTabela[arr].model = false
                }
                this.dadosTabela[index].model = true
                this.dadosHorarios.jtdsr = index
                this.checkboxTrabalho[index] = false 
                this.$refs.refInputTotalHoras[index].clearAll()
                this.dadosTabela[index].hjttotalhoras = ''
                this.$refs.refInputIntervalo[index].clearAll()
                this.dadosTabela[index].hjtintervalo = ''
            }
        },
        changeCheckboxTrabalho(index){
            this.$refs.refInputTotalHoras[index].clearAll()
            this.dadosTabela[index].hjttotalhoras = ''

            this.$refs.refInputIntervalo[index].clearAll()
            this.dadosTabela[index].hjtintervalo = ''

            this.campoVazioHorarios = this.verificaCamposVaziosHorarios()
            // if(this.checkboxTrabalho[index]){
            //     this.checkboxTrabalho[index] = false
            //     this.dadosTabela[index].desabilitado = ' '
            // }else{
            //     this.checkboxTrabalho[index] = true
            //     this.dadosTabela[index].desabilitado = 'disabled'
            // }
            // this.checkboxTrabalho[index] = !this.checkboxTrabalho[index]
        },

        // Comentado pois o sistema novo não esta utilizando
        // buttonsFiltersChange(value){
        //     if(value == "b1") {
        //         this.botoes[0].icon = 'flaticon-icon003'
        //         this.botoes[1].icon = ' '
        //         this.botoes[2].icon = ' '
        //     }
        //     else if(value == "N"){
        //         this.botoes[0].icon = ' '
        //         this.botoes[1].icon = 'flaticon-icon003'
        //         this.botoes[2].icon = ' '
        //     }
        //     else if(value == "R"){
        //         this.botoes[0].icon = ' '
        //         this.botoes[1].icon = ' '
        //         this.botoes[2].icon = 'flaticon-icon003'
        //     }
        // },

        exportarCadastro(value){
        } ,
        novoCadastro(value){
            
        },

        // Regras --------------------------------------------------------------------------------------------
        changeTempoTolerancia(jttempotolerancia){
            this.dadosRegras.jttempotolerancia = jttempotolerancia
        },

        changeTempoExtra(jttempoextra){
            this.dadosRegras.jttempoextra = jttempoextra
        },

        changeInputInterjornada(tempoInterjornada){
            this.dadosRegras.jtinterjornada = tempoInterjornada
        },

        cangeInputDirecaoContinua(direcaoContinua){
            this.dadosRegras.jtdirecaocontinua = direcaoContinua
        },

        changeRegrasMonitorarAberturaFechamento(ponto){
            this.dadosRegras.pontosReferencia = ponto
        },

        changeMonitorarAberturaPontoReferencia(){
            this.dadosRegras.pontosReferencia = []
            this.dadosRegras.pontos_referencia = []
            this.$v.dadosRegras.pontosReferencia.$reset()
        },

        // Função para buscar os pontos do cliente
        async getPontosReferencia(empresas){
            var dadosPontos = await new HttpRequest()
                    .Post(this.link+'pontos/referencia', 
                    {cliente: empresas})
            this.optionsPontosRegras = dadosPontos.data.pontos
        },

        //Período Valido Jornada
        changePeriodoHoraInicial(horarioInicial){
            this.dadosRegras.jtperiodovalidoinicio = horarioInicial
        },

        changePeriodoHoraFinal(horarioFinal){
            this.dadosRegras.jtperiodovalidofim = horarioFinal
        },

        changePeriodoTempoJornada(tempoTrocaJornada){
            this.dadosRegras.jttempoconsideratrocajornada = tempoTrocaJornada
        },

        //Espera
        changeEsperaTempoMaximo(tempoMaximoEspera){
            this.dadosRegras.jttempomaximoespera = tempoMaximoEspera
        },

        changeEsperaConsiderarTempoMaior(considerarTempoMaior){
            this.dadosRegras.jttempoconsiderarespera = considerarTempoMaior
        },

        disableRegrasEspera(origemespera){
            if(origemespera == 'A' || origemespera == 'R'){
                this.dadosRegras.jttempoconsiderarespera = ''
                this.dadosRegras.jttempomaximoespera     = ''
                this.dadosRegras.jtconsideradisponibilidadeespera = false
                this.dadosRegras.jtdescansoremunerado    = false
            }
        },

        // Intervalo
        changeRegrasIntervaloTempoMinimo(tempoMinimoIntervalo){
            this.dadosRegras.jttempominimointervalo = tempoMinimoIntervalo
        },

        changeRegrasIntervaloEmpresas(empresasIntervaloSelecionado){
            this.dadosRegras.jtexcedenteintervaloconsidera = empresasIntervaloSelecionado
        },

        disableRegrasIntervalo(origemIntervalo){
            if(origemIntervalo == 'A' || origemIntervalo == 'R'){
                this.dadosRegras.jtconsideraintervaloforaponto   = false
                this.dadosRegras.jtconsideraintervalodentroponto = false
                this.dadosRegras.jtconsideraintervalopontoespera = false
            }
        },

        // Parâmetros ----------------------------------------------------------------------------------------

        async buscaPontosCliente(empresa){
            this.loadingPontos = true
            var dados = await new HttpRequest()
                    .Post(this.link+'pontos/cliente', 
                        {'cliente' : empresa})
            this.optionsPontosParametros = dados.data.pontos
            this.loadingPontos = false
        },

        changePontosConsiderarHoraEspera(pontosEspera){
            this.dadosParametros.jpeponto = pontosEspera
        },

        changePontosConsiderarNeutros(pontosNeutros){
            this.dadosParametros.jpnponto = pontosNeutros
        },

        //Banco de Horas -------------------------------------------------------------------------------------
        changeBancoHorasOperacao(index, valor){
            this.dadosBancoHoras[index].bhpoperacao = valor[0]
            this.campoVazioBanco = this.verificaCamposVaziosBanco()
        },

        changeBancoHorasConversao(index, valor){
            this.dadosBancoHoras[index].bhptaxaconversao = valor
            this.campoVazioBanco = this.verificaCamposVaziosBanco()
        },

        /** 
         * Método para varificar se na TAB Banco Horas possuí um campo vazio mesmo 
         * com algum input ou check selecionado
         * @param Arrays Checkbox, dadosBancoHoras (bhpoperacao, bhptaxaconversao)
         * @return String, vazia se possuír algum campo selecionado vazio e 'false' se não 
         *  tiver campo selecionado vazio
         * @author Vitor Hugo 🐨
         */
        verificaCamposVaziosBanco(){
            for (let index = 0; index < this.checkBoxBancoDeHoras.length; index++) {
                if(this.checkBoxBancoDeHoras[index]){
                    if(this.dadosBancoHoras[index].bhpoperacao == undefined ||
                        this.dadosBancoHoras[index].bhptaxaconversao == ''){
                            return ''
                        }
                }
            }
            return 'false'
        },

        changeCheckBoxBancoDeHoras(valor, index){
            if(valor == false){
                this.$refs.selectBancoHorasOperacao[index].clearAll()
                this.$refs.inputBancoHorasConversao[index].clearAll()
            }
            this.campoVazioBanco = this.verificaCamposVaziosBanco()
        },

        changeInputBancoHorasConsiderar(){

        },

        // Hora Extra -----------------------------------------------------------------------------------------
        changeCheckHoraExtra(valor , index){
            this.dadosHoraExtra[index].heptipo = index+1
            this.dadosHoraExtra[index].model = true
            this.$refs.inputHoraExtraDe[index].clearAll()
            this.$refs.inputHoraExtraAte[index].clearAll()
            this.$refs.inputHoraExtraLegenda[index].value = ''
            this.dadosHoraExtra[index].heplegenda = ''
            this.campoVazioExtra = this.verificaCamposVaziosHoraExtra()
        },

        changeHoraExtraDe(index, valor){
            this.dadosHoraExtra[index].hephorainicio = valor
            this.campoVazioExtra = this.verificaCamposVaziosHoraExtra()
        },

        changeHoraExtraAte(index, valor){
            this.dadosHoraExtra[index].hephorafim = valor
            this.campoVazioExtra = this.verificaCamposVaziosHoraExtra()
        },

        /** 
         * Método para varificar se na TAB Horas extras possuí um campo vazio mesmo 
         * com algum input ou check selecionado
         * @param Arrays Checkbox, dadosHoraExtra (hephorainicio, hephorafim)
         * @return String, vazia se possuír algum campo selecionado vazio e 'false' se não 
         *  tiver campo selecionado vazio
         * @author Vitor Hugo 🐨
         */
        verificaCamposVaziosHoraExtra(){
            for (let index = 0; index < this.checkHoraExtra.length; index++) {
                if(this.checkHoraExtra[index]){
                    if(this.dadosHoraExtra[index].hephorainicio == '' ||
                        this.dadosHoraExtra[index].hephorafim == ''){
                            return ''
                    }
                }
            }
            return 'false'
        },

        // Edição --------------------------------------------------------------------------------------------

        /** 
         * Método para inserir informações de edição na TAB Horários
         * @param Object Objeto vindo do back com informações para os inputs e checkbox
         * @return Dois objetos, um com informações para tabela (dadosTabela) 
         * e outro para os inputs (dadosHorarios)
         * @author Vitor Hugo 🐨
         */
        async carregaInformacoesHorarios(dados){
            var horas = dados.hjt
            var jornada = dados.jt
            this.dadosHorarios.jtcliente = [this.optEmpresa.find(a=>{
                return a.value == jornada.jtcliente
            })]
            this.dadosHorarios.jtdescricao = jornada.jtdescricao
            Object.keys(this.dadosTabela).forEach(k=>{
                this.dadosTabela[k].hjttotalhoras = horas[k].hjttotalhoras
                this.dadosTabela[k].hjtintervalo  = horas[k].hjtintervalo
                this.dadosTabela[k].hjtcodigo     = horas[k].hjtcodigo
                this.checkboxTrabalho[k] = horas[k].hjttotalhoras != null ? true : false
                if(horas[k].hjtdsr == 1){
                    this.changeRadioHorarioDsr('Bla', k)
                }
            })
            if(jornada.jtdsrmanual){
                this.changeRadioHorarioDsr('dsrManual', -1)
                this.dadosHorarios.jtdsrmanual = true
            }
        },

        /** 
         * Método para inserir informações de edição na TAB Regras
         * @param Object Objeto vindo do back com informações para os inputs e checkbox
         * @return Dois objetos, um com informações para tabela (dadosTabela) 
         * e outro para os inputs (dadosHorarios)
         * @author Vitor Hugo 🐨
         */
        carregaInformacoesRegras(dados){
            var jornada = dados.jt
            Object.keys(this.dadosRegras).forEach(k=>{
                    this.dadosRegras[k] = jornada[k]
            })
            this.dadosRegras.excedenteintervaloconsidera = [this.optExcedenteIntervalo.find(a=>{
                return a.value.trim() == jornada.jtexcedenteintervaloconsidera.trim()
            })]
            this.dadosRegras.pontos_referencia  = []
                var teste = dados.pontosListRef.find(a=>{
                    if(dados.referencia[0] == a.value){
                        this.dadosRegras.pontos_referencia.push(a)
                    }
                })
        },

        /** 
         * Método para inserir informações de edição na TAB Parâmetros
         * @param Object Objeto vindo do back com informações para os radios (jt) e selects (pontosList)
         * @return Um objeto, com informações dos radios e selects
         * @author Vitor Hugo 🐨
         */
        carregaInformacoesParametros(dados){
            var jornada = dados.jt
            var pontosNeutros = dados.neutro
            var pontoEspera  = dados.espera
            Object.keys(this.dadosParametros).forEach(k=>{
                this.dadosParametros[k] = jornada[k]
            })
            this.dadosParametros.pontosEspera = []
            this.dadosParametros.pontoNeutro  = []
            pontoEspera.forEach(element => {
                var teste = [dados.pontosList.find(a=>{
                    if(a.value == element){
                        this.dadosParametros.pontosEspera.push(a)
                    }
                })]
            });
            pontosNeutros.forEach(element => {
                var teste = [dados.pontosList.find(a=>{
                    if(a.value == element){
                        this.dadosParametros.pontoNeutro.push(a)
                    }
                })]
            });
        },

        /** 
         * Método para inserir informações de edição na TAB Banco de horas
         * @param Object Objeto vindo do back com informações para os selects, inputs e checkbox (bhParametros)
         * @return Três objetos, um com informações dos checkbox (checkBoxBancoDeHoras) ,
         * outro para os selects (bhOperacao), e outro para os inputs (dadosBancoHoras)
         * @author Vitor Hugo 🐨
         */
        carregaInformacoesBancoHoras(dados){
            var banco = dados.bhParametros
            Object.keys(this.dadosBancoHoras).forEach(k=>{
                if(banco[k]){
                    this.dadosBancoHoras[k] = banco[k]
                    this.checkBoxBancoDeHoras[k] = true
                    var teste = [this.optionsBancoOperacao.find(a=>{
                        if(a.value == banco[k].bhpoperacao){
                            this.bhOperacao.push([a])
                        }
                    })]
                } else {
                    this.bhOperacao.push([])
                }
            })
        },

        carregaInformacoesColetivos(dados){
            var jornada = dados.jt
            this.dadosColetivos.jtmotoristapodeassociarmaisescala = jornada.jtmotoristapodeassociarmaisescala
            this.dadosColetivos.jtmotoristapodeescolherveiculo    = jornada.jtmotoristapodeescolherveiculo
        },

        /** 
         * Método para inserir informações de edição na TAB hora extra
         * @param Object Objeto vindo do back com informações para os inputs e checkbox (hep)
         * @return Dois objetos, um com informações dos checkbox (checkHoraExtra) ,
         * e outro para os inputs (dadosHoraExtra)
         * @author Vitor Hugo 🐨
         */
        carregaInformacoesHoraExtra(dados){
            var horasExtras = dados.hep
            Object.keys(this.dadosHoraExtra).forEach(k=>{
                if(horasExtras[k]){
                    this.dadosHoraExtra[k] = horasExtras[k]
                    this.checkHoraExtra[k] = true
                }
            })
        },
    },

    // Verificação dos inputs de horário (não foi achado uma forma melhor de fazer)
    computed:{
        validationTable: function(){
            if(
            (!!this.dadosTabela[0].hjttotalhoras && 
                this.checkboxTrabalho[0]         &&
                !!this.dadosTabela[0].hjtintervalo) ||

            (!!this.dadosTabela[1].hjttotalhoras && 
                this.checkboxTrabalho[1]         &&
                !!this.dadosTabela[1].hjtintervalo) ||

            (!!this.dadosTabela[2].hjttotalhoras && 
                this.checkboxTrabalho[2]         &&
                !!this.dadosTabela[2].hjtintervalo) ||

            (!!this.dadosTabela[3].hjttotalhoras && 
                this.checkboxTrabalho[3]         &&
                !!this.dadosTabela[3].hjtintervalo) ||

            (!!this.dadosTabela[4].hjttotalhoras && 
                this.checkboxTrabalho[4]         &&
                !!this.dadosTabela[4].hjtintervalo) ||

            (!!this.dadosTabela[5].hjttotalhoras && 
                this.checkboxTrabalho[5]         &&
                !!this.dadosTabela[5].hjtintervalo) ||

            (!!this.dadosTabela[6].hjttotalhoras && 
                this.checkboxTrabalho[6]         &&
                !!this.dadosTabela[6].hjtintervalo) ||

            (!!this.dadosTabela[7].hjttotalhoras && 
                this.checkboxTrabalho[7]         &&
                !!this.dadosTabela[7].hjtintervalo)){
                return false
            } else {
                return true
            }
        }
    },

    async mounted(){ 
        if(this.$route.params.id){
            this.salvaEdita = 'atualizar'
            this.idEditar = this.$route.params.id
            this.loadingPanel = true
            var linkE='/controle/jornada/cadastros/parametros/editar'
            var obj = {codigo : this.$route.params.id}
            new HttpRequest()
                .Post(linkE,obj).then( (dados)=>{
                    this.carregaInformacoesHorarios(dados.data)
                    this.carregaInformacoesRegras(dados.data)
                    this.carregaInformacoesParametros(dados.data)
                    this.carregaInformacoesBancoHoras(dados.data)
                    this.carregaInformacoesColetivos(dados.data)
                    this.carregaInformacoesHoraExtra(dados.data)
                    this.loadingPanel = false
                })
        } else {
            this.salvaEdita = 'salvar'
        }
    }
}


</script>
<style lang="scss">
#editarParametros{

    .padd {
        padding:1em 0em;
    }

    .fonte-15-px{
        font-size: 15px !important;
    }
    .espaco-dsr{
        padding-left: 10px;
        padding-right: 10px;
    }
    .pad-top{
        padding-top: 10px;
    }
    .margin-top-10px{
        margin-top: 10px;
    }
    .margin-15{
        margin-left: 15%;
    }
    .margin-lef-100{
        margin-left: 100%;
    }
    .tamanho-100{
        width: 100% !important;
    }
    .tamanho-div-fake{
        height: 30%;
    }
    .tamanho-minimo{
        height: 300px;
    }
    .tamanho-minimo-diferente{
        height: 300px;
    }
    .linha-lado{
        // border-collapse: collapse !important;
        margin-top: 5px;
        border-right: 1px solid #428bca;
        border-left: 1px solid #428bca;
    }
    .borda-collapse{
        border-collapse: collapse;
    }
    .espaco-th-dsr{
        padding-left: 5px;
    }
    .espaco-dsr{
        margin-right: 10px;
        margin-left: 10px;
    }
    .margin-th{
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
    textarea{
        border: 1px solid #d1d6dc;
        min-height: 43px !important;
    }
    .linha-checks{
        width: 100%;
        padding-left: 2%;
        padding-top: 10px;
    }
}
</style>
