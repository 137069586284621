<template>
    <panelEagle id="controleRFID" :loading="loadingPanel">
        <div class="col-sm-12 nopadding ">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage
                    :icon="mdiCreditCardWireless"
                    titulo="Controle RFID"></tituloPage>
                </div>
                <div class="col-sm-6 divButtonsBasic divDontPrint">
                    <basicButtonsRelatoriosEIG
                        :disabledDropdown="dadosCRFID.length == 0"
                        :loading="loadingExporta"
                        :disabled='
                            !($v.arraySelectedE.required &&
                            ($v.arraySelectedVGV.required
                            ||$v.valueColaboradores.required))
                        '
                        @exportarRelatorio="exportarRelatorio" 
                        @gerarRelatorio="gerarRFID">
                        <slot>
                            <simpleButton
                                :disabled="!tamanhoCRFID"
                                width="150px"
                                v-b-modal.modalEmailRfid 
                                id="enviarEmailRfid" 
                                type="orange" 
                                text="Enviar e-mail" 
                                :icon="mdiEmailMultiple">
                            </simpleButton>
                        </slot>
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12"><hr></div>
                    <div class="col-sm-12 justpaddingbottom row">
                        <div class="col-sm-3 nopadding">
                            <inputRangeWithLimit
                                titulo="Intervalo"
                                opens="right"
                                @changeInput="changeDataIntervalo" 
                                :isObrigatorio="true" 
                                name="inputDataCRFID">
                            </inputRangeWithLimit>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll 
                                @changeSelect="SelectEmpresas" 
                                :isMultiple="true" 
                                nameForRadio="radiosEmpresas" 
                                :labels="labelsSelectE" 
                                :optionsArray="optionsSelectE" 
                                firstSelected="E" 
                                :selected="selectedEmpresa"
                                :hasSelectAll="true">
                            </selectAll>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll
                                :disabled="optionsSelectVGV.length <= 0"
                                :loading="loadingBtn"
                                @changeSelect="changeSelectVeiculos" 
                                @selectCriado="selectCriado" 
                                @changeCheck="registraCheckVeiculos" 
                                :isMultiple="true"
                                nameForRadio="radiosVeiculos" 
                                :labels="labelsSelectVeiculos" 
                                :optionsArray="optionsSelectVGV" 
                                firstSelected="V" 
                                :hasSelectAll="true">
                            </selectAll>
                        </div>
                        <div class="col-sm-3 row nopadding">
                            <selectAll
                                ref="refCartoes"
                                :disabled="arraySelectedVGV.length == 0
                                    && valueColaboradores.length == 0"
                                :loading="loadingBtn"
                                @changeSelect="changeSelectColabs" 
                                @selectCriado="selectCriado2" 
                                @changeCheckbox="registraCheckboxCartoes" 
                                :typeInput="'check-down'" 
                                :isMultiple="true" 
                                nameForRadio="radiosColabs" 
                                :checks="labelsSelectColabsCheck" 
                                :labels="labelsSelectColabs" 
                                :optionsArray="optionsSelectC" 
                                firstSelected="C" 
                                :hasSelectAll="true">
                            </selectAll>
                        </div>
                        <div class="col-sm-3 row nopadding">
                            <selectAll
                                ref="refColaboradores"
                                :loading="loadingBtn"
                                @changeSelect="SelectColaboradores" 
                                :isMultiple="true" 
                                nameForRadio="radiosEmpresas" 
                                :labels="[
                                    {indexDFH: 'Colab',
                                    description: 'Colaboradores*'}
                                ]"
                                :optionsArray="optionsSelectColaboradores" 
                                firstSelected="E" 
                                :hasSelectAll="true">
                            </selectAll>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
            <div class="col-sm-12"><hr></div>
        </div>
        <tableRelatorio>
            <slot slot="thead">
                <tr>
                    <th colspan="5" class="thRFID">
                        <div class="pdtop3">Associação cartão</div>
                    </th> 
                    <th colspan="5" class="thRFID">
                        <div class="pdtop3">Desassociação cartão</div>
                    </th> 
                    <th rowspan="2" class="thRFID2">
                        <div class="pdtop widthKm">Total percorridos</div>
                    </th> 
                    <th rowspan="2" class="thRFID2">
                        <div class="pdtop widthTempo">Tempo ligado</div>
                    </th> 
                    <th rowspan="2" class="thVeiculoColab">
                        <div class="pdtop">
                            {{arraySelectedVGV.length > 0
                            ? 'Colaborador' : 'Veículo'}}
                            
                        </div>
                    </th>
                    <th rowspan="2" class="thRFID2">
                        <div class="pdtop">Perfil</div>
                    </th> 
                    <th rowspan="2" class="thRFID2">
                        <div class="pdtop widthCartao">Nº Cartão</div>
                    </th>
                    <th rowspan="2" class="thRFID2">
                        <div class="pdtop widthCartao">Tipo Cartão</div>
                    </th>
                </tr>
                <tr style="z-index:-1">
                    <th class="thRFID topTh">
                        <div class="pdtop3">Data</div>
                    </th> 
                    <th class="thRFID topTh">
                        <div class="pdtop3">Hora</div>
                    </th> 
                    <th class="thRFID topTh">
                        <div class="pdtop3">Coordenadas</div>
                    </th> 
                    <th class="thRFID topTh">
                        <div class="pdtop3 widthCidade">Cidade - UF</div>
                    </th> 
                    <th class="thRFID topTh">
                        <div class="pdtop3">Ponto</div>
                    </th> 
                    <th class="thRFID topTh">
                        <div class="pdtop3">Data</div>
                    </th> 
                    <th class="thRFID topTh">
                        <div class="pdtop3">Hora</div>
                    </th> 
                    <th class="thRFID topTh">
                        <div class="pdtop3">Coordenadas</div>
                    </th> 
                    <th class="thRFID topTh">
                        <div class="pdtop3 widthCidade">Cidade - UF</div>
                    </th> 
                    <th class="thRFID topTh">
                        <div class="pdtop3">Ponto</div>
                    </th>
                </tr>
            </slot>
            <slot slot="tbody">
                <template v-for="(dados, d) in dadosCRFID">
                    <tr :key="d+'badge'"
                        class="badgetr backColorTdPDF">
                        <td colspan="17" class="pl-0 badgePadding">
                            <span class="newbadge">
                                {{d}}
                            </span>
                        </td>
                    </tr>
					<tr class="tr-fantasma" :key='d+"__tr_fantasma"'/>
                    <tr v-for="(dadosRelatorio, dr) in dados.relatorio"
                        :key="dr+'_dados_rel_'+d">
                        <td>{{dadosRelatorio.datainicio}}</td>
                        <td>{{dadosRelatorio.horainicio}}</td>
                        <td>{{dadosRelatorio.coord_inicio}}</td>
                        <td>{{dadosRelatorio.end_inicio}}</td>
                        <td>{{dadosRelatorio.pontoini}}</td>
                        <td>{{dadosRelatorio.datafim}}</td>
                        <td>{{dadosRelatorio.horafim}}</td>
                        <td>{{dadosRelatorio.coord_fim}}</td>
                        <td>{{dadosRelatorio.end_fim}}</td>
                        <td>{{dadosRelatorio.pontofim}}</td>
                        <td>{{distanciaPercorrida(dadosRelatorio.kms)}}</td>
                        <td>{{dadosRelatorio.tempo}}</td>
                        <td>{{dadosRelatorio.porplaca ? dadosRelatorio.motorista : dadosRelatorio.index}}</td>
                        <td>{{verificaPerfil(dadosRelatorio.mtperfil)}}</td>
                        <td>{{dadosRelatorio.jrlcartaomotorista ?
                              dadosRelatorio.jrlcartaomotorista : 
                              dadosRelatorio.jrlcartaoajudante}}</td>
                        <td>{{dadosRelatorio.intervalo ? 'Intervalo' :
                              dadosRelatorio.espera ? 'Espera' : 'Normal'}}</td>
                    </tr>
                    <tr style="background:#d0d0d0" :key="d+'__totais'">
                        <td colspan='10' style='text-align:center'>Totais:</td>
                        <td>{{distanciaPercorrida(dados.totalizadores.kms)}}</td>
                        <td>{{dados.totalizadores.tempo}}</td>
                        <td colspan='5'></td>
                    </tr>
                </template>
                <statusInformation 
                    v-if="dadosCRFID.length == 0"
                    typeBar="tr" 
                    :statusBar="statusbar" 
                    :colspanForTd="16"
                />
            </slot>
        </tableRelatorio>
        <b-modal
            id="modalEmailRfid" 
            ref="modal" 
            title="Envio de email"
            @show="resetModal"
            @hidden="resetModal"
            @ok="handleOk">
            <div class="col-sm-12 nopadding">
                <inputSimple
                    class="nopadding"
                    label="Informe um email válido"
                    @changeInput="changeEmail"
                    @blur='$v.email.$touch()'
                    :inputClass='{"border border-danger": $v.email.$anyError}'
                />
                <span class="text-danger small" v-show="$v.email.$anyError">
                    E-mail válido requerido
                </span>
            </div>
            <slot slot="modal-footer">
                <simpleButton
                    width="110px"
                    :icon="mdiCloseThick" 
                    @click="cancelButton" 
                    text="Cancelar" 
                    event="click" 
                    type="red"
                />
                <simpleButton
                    :disabled="(!$v.email.email || !$v.email.required)"
                    width="110px"
                    :icon="mdiCheckBold" 
                    @click="handleSubmit" 
                    text="Confirmar" 
                    event="click" 
                    type="blue"
                />
            </slot>
        </b-modal>
    </panelEagle>
</template>

<script>
import Vue from 'vue'
import { 
	mdiCreditCardWireless,
	mdiEmailMultiple,
	mdiCheckBold,
	mdiCloseThick } from '@mdi/js'
import {EmpresasService} from '../../../Services/auth/Empresas.service'
import {HttpRequest} from '../../../Services/auth/HttpRequest.Service'
import {FiltrosService} from '../../../Services/filtros/filtros.Service'
import { required, email } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import basicButtonsRelatoriosEIG from
	'@/components/Atom/Buttons/BasicButtonsRelatoriosEIG'
import slideUpAndDown from '@/components/Atom/SlideUpAndDown/SlideUpAndDown'
import inputRangeWithLimit from '@/components/Atom/Datas/InputRangeWithLimit'
import statusInformation from
	'@/components/Atom/StatusInformation/StatusInformation'
import tableRelatorio from '@/components/Atom/Table/TableRelatorio'
import { toNumber } from '@vue/shared'
export default Vue.extend({
	name:'ControleRFID',
	components:{
		'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
		'tituloPage': require('@/components/Atom/Header/Titulo').default,
		'inputSimple': require('@/components/Atom/Inputs/InputSimple').default,
		'selectAll': require('@/components/Atom/Select/SelectAll').default,
		simpleButton: require('@/components/Atom/Buttons/SimpleButton').default,
		basicButtonsRelatoriosEIG,
		slideUpAndDown,
		inputRangeWithLimit,
		statusInformation,
		tableRelatorio,
	},
	validations:{
		arraySelectedE:{ required },
		email:{ email, required },
		arraySelectedVGV: { required },
		valueColaboradores: { required },
	},
	data(){
		return{
			mdiCloseThick:mdiCloseThick,
			mdiCheckBold:mdiCheckBold,
			mdiEmailMultiple:mdiEmailMultiple,
			valueColaboradores:[],
			optionsSelectColaboradores:[],
			loadingBtn:false,
			loadingExporta:[false, false, false],
			loadingPanel:false,
			mdiCreditCardWireless:mdiCreditCardWireless,
			labelsSelectE:[
				{indexDFH: 'E', description: 'Empresas*'},
			],
			optionsSelectE: new EmpresasService().Get(),
			labelsSelectVeiculos:[
				{indexDFH: 'V', description: 'Veículos*'},
				{indexDFH: 'GV', description: 'Grupos*'},
			],
			labelsSelectColabs:[
				{indexDFH: 'E', description: 'Colaboradores associados'},
			],
			labelsSelectColabsCheck:[
				{
					text:'Mostrar cartões sem vinculação',
					value:'C'
				},
			],
			optionsSelectC: [] ,
			optionsSelectVGV: [] ,
			optionRadiusV: 'V' ,
			arraySelectedE: [] ,
			arraySelectedC: [] ,
			arraySelectedVGV: [] ,
			checkboxCartoes: '' ,
			dataIntervalo: '' ,
			dadosCRFID: [] ,
			tamanhoCRFID: 0 ,
			dataToExport: '' ,
			statusbar: 'info' ,
			email: '',
			emailState: false ,
			changeSelectE: [] ,
			changeSelectVGV: [] ,
			selectedEmpresa: [],
		}
	},
	methods:{
		...mapGetters(['getMaster']),

		changeEmail(value){
			this.email = value
		},
		cancelButton(){
			this.$bvModal.hide('modalEmailRfid')
		},
		SelectColaboradores(value){
            this.dadosCRFID = []
			this.valueColaboradores = value
		},
		async SelectEmpresas(event){
			this.loadingBtn = true
			this.arraySelectedE = event;
			this.changeSelectE.clearAll();
			this.$refs.refColaboradores.clearAll()
			await this.dados_filtros();
			this.loadingBtn = false
		},
		changeSelectVeiculos(event){
			this.arraySelectedVGV = event;
			this.changeSelectVGV.clearAll();
            this.dadosCRFID = []
			if(this.arraySelectedE.length && this.arraySelectedVGV.length){
				this.motoristas();
			}
		},
		selectCriado(select){
			this.changeSelectE = select;
		},
		selectCriado2(select){
			this.changeSelectVGV = select;
		},
		changeSelectColabs(event){
            this.dadosCRFID = []
			this.arraySelectedC = event;
		},
		changeDataIntervalo(data){
			this.dataIntervalo = data;
			if(this.arraySelectedE.length && this.arraySelectedVGV.length){
				this.motoristas();
			}
		},
		registraCheckVeiculos(check, needRecharge){
			this.optionRadiusV = check
			if(needRecharge && this.arraySelectedE.length){
				this.dados_filtros();
			}
		},
		registraCheckboxCartoes(check){
			this.checkboxCartoes = check
			this.$refs.refCartoes.clearAll()
		},
        distanciaPercorrida(distancia){
            var distance = toNumber(distancia)
            if(distance > 1) return distance.toFixed(2) +" Km"
            //esse else if é por conta de terem errados no banco 
            //e acabarem retornando um hodometro negativo
            else if(distance < 0) return 0+" M"
            return (distance*1000)+" M"
        },
		resetModal() {
			this.email = ''
			this.emailState = false
		},
		handleOk(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.handleSubmit()
		},
		handleSubmit() {
			this.exportarRelatorioPorEmail()
			this.$nextTick(() => {
				this.$bvModal.hide('modalEmailRfid')
			})
		},
		async dados_filtros(){
            this.dadosCRFID = []
            this.optionsSelectColaboradores = []
            this.optionsSelectC = []
            this.optionsSelectVGV = []
            try {
                var dados = await new FiltrosService()
                    .dados_filtros(this.arraySelectedE, [this.optionRadiusV, 'C'])
                this.optionsSelectColaboradores = dados['C']
                this.optionsSelectC = [];
                this.optionsSelectVGV = dados[this.optionRadiusV];
            } catch (error) {''}
		},
		async motoristas(){
			var dados = [];
			this.loadingBtn = true
			dados = await new HttpRequest()
				.Post('/controle/jornada/relatorios/controle/rfid/motoristas', {
					'veiculos': this.optionRadiusV === 'V' ?
						this.arraySelectedVGV : [], 
					'gveiculos': this.optionRadiusV === 'GV' ?
						this.arraySelectedVGV : [],
					'semMot': this.checkboxCartoes, 
					'data_ini':this.dataIntervalo.split(' - ')[0],
					'data_fim':this.dataIntervalo.split(' - ')[1],
					'origemRequest':'N'
				})
			this.optionsSelectC = dados.data;
			this.loadingBtn = false
		},
		async gerarRFID(){
			this.loadingPanel = true
			this.statusbar = 'info';
			this.dadosCRFID = [];
			this.tamanhoCRFID = 0;
            var body = {
                'veiculos': this.optionRadiusV === 'V' ?
                    this.arraySelectedVGV : [], 
                'gveiculos': this.optionRadiusV === 'GV' ?
                    this.arraySelectedVGV : [],
                'semMot': this.checkboxCartoes, 
                'data_ini':this.dataIntervalo.split(' - ')[0],
                'data_fim':this.dataIntervalo.split(' - ')[1],
                'motoristas':this.arraySelectedC,
                'colaboradores':this.valueColaboradores,
                'porplaca': this.arraySelectedVGV.length > 0
            }
            var url = '/controle/jornada/relatorios/controle/rfid/gerar'
            try {
                var data = await new HttpRequest().Post(url, body)
                var dados = data.data;
                this.tamanhoCRFID = Object.keys(dados.crfid).length
                if(this.tamanhoCRFID){
                    this.dadosCRFID = dados.crfid
                    this.dataToExport = this.dataIntervalo;
                }else{
                    this.dadosCRFID = [];
                    this.statusbar = 'error'
                }
            } catch (error) {
                this.statusbar = 'error'
            }
			this.loadingPanel = false
		},
		async exportarRelatorio(tipo){
			switch (tipo) {
				case 'pdf':
					this.loadingExporta = [true, false, false]
					break;
				case 'xls':
					this.loadingExporta = [false, true, false]
					break;
				default:
					this.loadingExporta = [false, false, true]
					break;
			}
			var root =  process.env.VUE_APP_ROOT;
			var url = '/controle/jornada/relatorios/controle/rfid/exportar'
			if(this.tamanhoCRFID){
				var data = await new HttpRequest()
					.Post(url, {
						'arrayDados':JSON.stringify(this.dadosCRFID), 
						'tipo':tipo, 
						'data':this.dataToExport,
                        'porplaca': this.arraySelectedVGV.length > 0
					});
				window.open(root+'/'+data.data.local);
			}
			this.loadingExporta = [false, false, false]
		},
		async exportarRelatorioPorEmail(){
			var root =  process.env.VUE_APP_ROOT;
			var url = '/controle/jornada/relatorios/controle/rfid/exportar'
			if(this.tamanhoCRFID){
				var data = await new HttpRequest()
                .Post(url, {
                    'arrayDados':JSON.stringify(this.dadosCRFID), 
                    'tipo':'email', 
                    'email':this.email
                }).then(({data})=>{
                    let titulo = "Status de envio de email:"
                    if(data.local === 1){
                        let type = "success"
                        let msg = "E-mail enviado com sucesso"
                        this.toastShow(titulo, msg,type) 
                    }else{
                        let type = "danger"
                        let msg = "Erro ao enviar o e-mail"
                        this.toastShow(titulo, msg,type) 
                    }
                });
			}
		},
        toastShow(titulo, msg, type){
            this.$bvToast.toast(msg, {
                title: titulo,
                autoHideDelay: 2500,
                variant: type,
            })
        },
        verificaPerfil(perfil){
            if(perfil == 'M')
                return 'Motorista'
            else if(perfil == 'A')
                return 'Ajudante'
            else 
                return ''  
        }
	},

	mounted() {
		if(!this.getMaster()){
			this.selectedEmpresa = this.optionsSelectE
		}
	}
})
</script>

<style lang="scss">
    #controleRFID{ 
		.tr-fantasma{
			background-color: white;
			color: white;
			height: 20px;
		}
        .badgeTd {
            padding-left: 0;
        }
        .thRFID {
            div {
                border: solid 1px #d5d5d5 !important;
                height: 31px !important;
            }
        }
        .thRFID2{
            div {
                border: solid 1px #d5d5d5 !important;
                height: 62px !important;
            }
        }
        .thVeiculoColab{
            div {
                border: solid 1px #d5d5d5 !important;
                height: 62px !important;
                min-width: 170px !important;
            }
        }
        .topTh {
            top: 30px !important;
        }
        .pdtop{
            padding-top: 20px !important;
        }
        .pdtop3{
            padding-top: 3px !important;
        }
        .tableFixHead thead {
            tr {
                border: none !important;
                th {
                    div{
                        outline: 1px solid #d5d5d5;
                    }
                    border: none !important;
                    padding: 0 !important;
                    text-align: center !important;
                }
            }
        }
        .widthCidade{
            min-width: 85px;
        }
        .widthCartao{
            min-width: 70px;
        }
        .widthTempo{
            min-width: 100px;
        }
        .widthKm{
            min-width: 65px;
        }
    }
</style>