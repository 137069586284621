props
    legenda -> objeto que vai construir toda a legenda, no formato:
    {
        legenda:[
            {titulo:'titulo da linha', cor: 'cor da linha'},
        ]
    }

<template>
    <div id='legenda-mapa'>
        <div class='d-flex flex-row-reverse'>
            <b-button
            size='sm'
            class='mb-1'
            block
            @click='alteraTabela'>
                <base-icon :size='16' :icon='iconBotao'/>
            </b-button>
        </div>
        <div class='tabelaLegenda' v-if='tabelaAberta'>
            <div 
            v-for='sub in legenda'
            class='pt-1'
            :style="{
                'color':'black',
                'background-color':sub.cor ? sub.cor: 'white',
            }"
            :key='`legenda_grupo_${sub.key}`'
            v-text='sub.titulo'/>
        </div>
    </div>
</template>

<script>
import {mdiArrowCollapseRight, mdiArrowExpandLeft} from '@mdi/js'
export default {
	components: {
		baseIcon : require('@/components/Atom/Icon/BaseIcon.vue').default
	},
	props: {
		legenda:{
			type: Array,
			default(){
				return {
					legenda:[]
				}
			}
		},
		top:{
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			tabelaAberta: true,
			iconBotao: mdiArrowCollapseRight,
		}
	},
	methods: {
		alteraTabela(){
			this.tabelaAberta = !this.tabelaAberta
			if(this.tabelaAberta)
				this.iconBotao = mdiArrowCollapseRight
			else
				this.iconBotao = mdiArrowExpandLeft
		}
	},
}
</script>

<style lang="scss">
#legenda-mapa{
    .tabelaLegenda{
        background-color: rgba(210, 234, 255, 0);
        max-height: 150px;
        overflow-y: auto;
    }
}
</style>