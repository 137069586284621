<template>
    <panelEagle id="painelControle" :loading="loadingPanel">
        <div class="col-sm-12 nopadding row">
            <slideUpAndDown>
                <template v-if="carregandoVeiculos" #descricao>
                    <div class="templateSlide"> 
                        <b-spinner :loading="true" small color="#fff" />
                        <span class="atualizando">
                            {{atualizando}}
                        </span>
                    </div>
                </template>
                <slot slot="filters">
                    <div class="col-sm-12 nopadding row">
                        <div class="col-sm-5 nopadding">
                            <div class="col-sm-12">
                                <tituloPage 
                                    :icon="mdiBulletinBoard" 
                                    titulo="Painel de controle"
                                />
                            </div>
                            <div class="col-sm-12"><hr></div>
                            <!-- Selects e campos de busca-->
                            <div class="col-sm-12 row nopadding">
                                <div class="col-sm-6 nopadding divDontPrint">
                                    <selectAll 
                                        :isMultiple="true" 
                                        :hasSelectAll="true" 
                                        nameForRadio="empresas" 
                                        :labels="[
                                            {indexDFH:'EM', description:'Empresas*'}
                                        ]"
                                        :optionsArray="optionsArrayEmpresas" 
                                        firstSelected="EM"
                                        :selected="selectedEmpresa"
                                        @changeSelect="changeEmpresa"/>
                                </div>
                                <div class="col-sm-6 nopadding divDontPrint">
                                    <selectAll
                                        ref="refGrupoVeiculos"
                                        :loading="loadingGrupoVeiculos"
                                        :isMultiple="true" 
                                        :hasSelectAll="true" 
                                        nameForRadio="gupoVeiculos" 
                                        :labels="[
                                            {indexDFH:'GV', description:'Grupo veículos'}
                                        ]" 
                                        :optionsArray="optSelectGrupoVeiculos" 
                                        firstSelected="GV"
                                        :disabled='$v.empresaSelecionada.$invalid'
                                        @changeSelect="changeGrupoVeiculos"/>
                                </div>
                                <div class="col-sm-6 nopadding mt-4 divDontPrint">
                                    <inputSearch
                                        label='Buscar por placa e prefixo'
                                        name="buscaPlaca"
                                        placeholder="Digite..."
                                        buttonDisabled="disabled"
                                        @changeInput='changePlaca'/>
                                </div>
                                <div class="col-sm-6 nopadding mt-4  divDontPrint">
                                    <selectTable 
                                        messageNoOptions=
                                            'Digite 3 números da NFE para buscar'
                                        ref='buscaNfe'
                                        :optionsArray ='optionsNfe'
                                        :optionsCabecalho ='optionsCabecalho'
                                        :loading ='loadingNfe'
                                        :disabled ='$v.empresaSelecionada.$invalid'
                                        @changeSelect ='changeNFE'
                                        @changeSearch ='buscaNFE'/>
                                </div>
                            </div>
                        </div>
                        <!-- Checkbox -->
                        <div class="col-sm-2 nopadding">
                            <div class="col-sm-12 nopadding text-left">
                                <b-form-group label="Status">
                                    <b-form-checkbox
                                        v-for="(option, o) in options"
                                        v-model="selectedStatus[o]"
                                        :key="o"
                                        :value="option.value"
                                        name="flavour-3a">
                                        {{ option.text }}
                                    </b-form-checkbox>
                                </b-form-group>
                            </div>
                        </div>
                        <!-- Tabela de rotas -->
                        <div class="col-sm-5 nopadding">
                            <div class="col-sm-12 nopadding tablePendentes">
                                <div class="col-md-12 titulosfilt divTitle" 
                                    style="display:inline-flex">
                                    <div class="title1">ROTAS AGENDADAS</div>
                                    <div class="title2">
                                        <!-- 
                                            <span class="fa fa-spinner fa-spin hidden"></span>
                                            <input 
                                                id="dataRotasPendentesPC" 
                                                class="
                                                    form-control 
                                                    mask-date 
                                                    date-empty 
                                                    hidden
                                                "
                                                type="text" 
                                                value="20/07/2021" 
                                                readonly=""
                                            /> 
                                        -->
                                    </div>
                                </div>
                                <div id="tablePendentesPC">
                                    <b-overlay :show="loadingRotasAgendadas">
                                        <table class="table table-hover">
                                            <header>
                                                <tr>
                                                    <th class="tdthPlaca">
                                                        Placa
                                                    </th>
                                                    <th class="tdthData">
                                                        Data
                                                    </th>
                                                    <th class="tdthProgramacao">
                                                        Programação
                                                    </th>
                                                    <th class="tdthOrigemDestino">
                                                        Origem
                                                    </th>
                                                    <th class="tdthOrigemDestino">
                                                        Destino
                                                    </th>
                                                    <th class="thTempo">
                                                        Tempo
                                                    </th>
                                                    <th class="thPower">
                                                    </th>
                                                </tr>
                                            </header>
                                            <template v-if="rotasAgendadas.length == 0">
                                                <statusInformation
                                                    typeBar='div'
                                                    :statusBar='statusBarRotasAgendadas'
                                                />
                                            </template>
                                            <body v-else>
                                                <tr v-for="(agendadas, a) in rotasAgendadas"
                                                    :key="a"
                                                    class="pendente-iniciado"
                                                    :class="agendadas.status">
                                                    <td class="tdthPlaca">
                                                        {{agendadas.placa}}
                                                    </td>
                                                    <td class="tdthData">
                                                        {{agendadas.data}}
                                                    </td>
                                                    <td class="tdthProgramacao">
                                                        {{agendadas.programacao}}
                                                    </td>
                                                    <td class="tdthOrigemDestino">
                                                        {{agendadas.origem}}
                                                    </td>
                                                    <td class="tdthOrigemDestino">
                                                        {{agendadas.destino}}
                                                    </td>
                                                    <td class="thTempo">
                                                        {{agendadas.tempo}}
                                                    </td>
                                                    <template v-if="getEditar()">
                                                        <td class="tdPower"
                                                            event="click"
                                                            v-on:click="cancelarRotaAgendada(agendadas.codigo)">
                                                            <baseIcon 
                                                                class="iconCancelarRota cursor-pointer"
                                                                :icon='mdiPower'
                                                                size="20"/>
                                                        </td>
                                                    </template>
                                                    <template v-else>
                                                        <td></td>
                                                    </template>
                                                </tr>
                                            </body>
                                        </table>
                                    </b-overlay>
                                </div>
                            </div>
                        </div>
                        <template>
                            <modalDeleta
                                id="1"
                                @confirmaExclusao="excluirRotasAgendadas">
                            </modalDeleta>
                        </template>
                    </div>
                </slot>
            </slideUpAndDown>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12" v-if="dadosKanbanTotais.length == 0">
                <statusInformation
                    typeBar="div" 
                    :statusBar="statusBar">
                </statusInformation>
            </div>
            <!-- Tabela do Kanban -->
            <div id="divDados" class="col-sm-12" v-else>
                <table class="col-sm-12">
                    <header>
                        <tr class="row-card-headers">
                            <th v-show="selectedStatus[0]"
                                class="card-title card-header-disponivel"
                                colspan="1">
                                Disponível ({{dadosKanbanTotais.disponivel}})
                            </th>
                            <th v-show="selectedStatus[1]"
                                class="card-title card-header-carregamento"
                                colspan="1">
                                Agendado carregamento ({{dadosKanbanTotais.carregamento}})
                            </th>
                            <th v-show="selectedStatus[2]"
                                class="card-title card-header-venda"
                                colspan="1">
                                Agendado venda ({{dadosKanbanTotais.venda}})
                            </th>
                            <th v-show="selectedStatus[3]"
                                class="card-title card-header-carregado"
                                colspan="1">
                                Carregado ({{dadosKanbanTotais.carregado}})
                            </th>
                            <th v-show="selectedStatus[4]"
                                class="card-title card-header-faturado"
                                colspan="1">
                                Faturado ({{dadosKanbanTotais.faturado}})
                            </th>
                            <th v-show="selectedStatus[5]"
                                class="card-title card-header-iniciado"
                                colspan="1">
                                Iniciado ({{dadosKanbanTotais.iniciado}})
                            </th>
                            <th v-show="selectedStatus[6]"
                                class="card-title card-header-finalizado"
                                colspan="1">
                                Finalizado ({{dadosKanbanTotais.finalizado}})
                            </th>
                            <th v-show="selectedStatus[7]"
                                class="card-title card-header-manutencao"
                                colspan="1">
                                Manutenção ({{dadosKanbanTotais.manutencao}})
                            </th>
                            <th v-show="selectedStatus[8]"
                                class="card-title card-header-veiculo"
                                colspan="1">
                                Sem status ({{dadosKanbanTotais.veiculo}})
                            </th>
                        </tr>
                    </header>
                    <body class="bodyKanbamAgendamento">
                        <tr class="row-card-columns col-sm-12">
                            <!-- Disponível -->
                            <td v-show="selectedStatus[0]"
                                class="card-column card-list-disponivel">
                                <div
                                    class="clear-list col-sm-12 row">
                                    <div v-for="(disponivel, d) in dadosKanbanCpt.disponivel"
                                        :key="d"
                                        class="item item-disponivel"
                                        event="click"
                                        v-on:click="clickCard(disponivel)">
                                        <cardVeiculoKanban
                                            :id="'disponivel'"
                                            :item="disponivel">
                                        </cardVeiculoKanban>
                                    </div>
                                </div>
                            </td>
                            <!-- Agendado carregamento -->
                            <td v-show="selectedStatus[1]"
                                class="card-column card-list-carregamento">
                                <div 
                                    class="clear-list col-sm-12 row">
                                    <div v-for="(carregamento, c) in dadosKanbanCpt.carregamento"
                                        :key="c"
                                        class="item item-carregamento"
                                        event="click"
                                        v-on:click="clickCard(carregamento)">
                                        <cardVeiculoKanban
                                            :id="'carregamento'"
                                            :item="carregamento">
                                        </cardVeiculoKanban>
                                    </div>
                                </div>
                            </td>
                            <!-- Agendado venda -->
                            <td v-show="selectedStatus[2]"
                                class="card-column card-list-venda">
                                <div
                                    class="clear-list col-sm-12 row">
                                    <div v-for="(venda, v) in dadosKanbanCpt.venda"
                                        :key="v"
                                        class="item item-venda"
                                        event="click"
                                        v-on:click="clickCard(venda)">
                                        <cardVeiculoKanban
                                            :id="'venda'"
                                            :item="venda">
                                        </cardVeiculoKanban>
                                    </div>
                                </div>
                            </td>
                            <!-- Carregado -->
                            <td v-show="selectedStatus[3]"
                                class="card-column card-list-carregado">
                                <div 
                                    class="clear-list col-sm-12 row">
                                    <div v-for="(carregado, c) in dadosKanbanCpt.carregado"
                                        :key="c"
                                        class="item item-carregado"
                                        event="click"
                                        v-on:click="clickCard(carregado)">
                                        <cardVeiculoKanban
                                            :id="'carregado'"
                                            :item="carregado">
                                        </cardVeiculoKanban>
                                    </div>
                                </div>
                            </td>
                            <!-- Faturado  -->
                            <td v-show="selectedStatus[4]"
                                class="card-column card-list-faturado">
                                <div 
                                    class="clear-list col-sm-12 row">
                                    <div v-for="(faturado, f) in dadosKanbanCpt.faturado"
                                        :key="f"
                                        class="item item-faturado"
                                        event="click"
                                        v-on:click="clickCard(faturado)">
                                        <cardVeiculoKanban
                                            :id="'faturado'"
                                            :item="faturado">
                                        </cardVeiculoKanban>
                                    </div>
                                </div>
                            </td>
                            <!-- Iniciado -->
                            <td v-show="selectedStatus[5]"
                                class="card-column card-list-iniciado">
                                <div 
                                    class="clear-list col-sm-12 row">
                                    <div v-for="(iniciado, i) in dadosKanbanCpt.iniciado"
                                        :key="i"
                                        class="item item-iniciado"
                                        event="click"
                                        v-on:click="clickCard(iniciado)">
                                        <cardVeiculoKanban
                                            :id="'iniciado'"
                                            :item="iniciado">
                                        </cardVeiculoKanban>
                                    </div>
                                </div>
                            </td>
                            <!-- Finalizado -->
                            <td v-show="selectedStatus[6]"
                                class="card-column card-list-finalizado">
                                <div
                                    class="clear-list col-sm-12 row">
                                    <div v-for="(finalizado, f) in dadosKanbanCpt.finalizado"
                                        :key="f"
                                        class="item item-finalizado"
                                        event="click"
                                        v-on:click="clickCard(finalizado)">
                                        <cardVeiculoKanban
                                            :id="'finalizado'"
                                            :item="finalizado">
                                        </cardVeiculoKanban>
                                    </div>
                                </div>
                            </td>
                            <!-- Manutenção -->
                            <td v-show="selectedStatus[7]"
                                class="card-column card-list-manutencao">
                                <div
                                    class="clear-list col-sm-12 row">
                                    <div v-for="(manutencao, m) in dadosKanbanCpt.manutencao"
                                        :key="m"
                                        class="item item-manutencao"
                                        event="click"
                                        :id="'popoverManutencao'+manutencao.veiculo.placa"
                                        v-on:click="ativarPopover(m)">
                                        <cardVeiculoKanban
                                            :id="'manutencao'"
                                            :item="manutencao">
                                        </cardVeiculoKanban>
                                        <!-- Alterar status manutenção -->
                                        <div>
                                            <b-popover
                                                :target="'popoverManutencao'+manutencao.veiculo.placa"
                                                triggers="click"
                                                :show.sync="popoverShowManutencao[m]"
                                                :disabled.sync="disabled"
                                                placement="top"
                                                ref="popover">
                                                <div>
                                                    <span>Deseja alterar o status do veículo 
                                                        {{manutencao.veiculo.placa}}
                                                        para disponível?</span>
                                                </div>
                                                <div>
                                                    <simpleButton 
                                                        text="Cancel"
                                                        event="click"
                                                        @click="fecharPopover(m)"
                                                        type="red"
                                                        :icon="mdiCloseThick">
                                                    </simpleButton>
                                                    <simpleButton 
                                                        text="Confirmar"
                                                        event="click"
                                                        @click="alterarStatusVeiculoManutencao(
                                                        manutencao.veiculo.placa, m)" 
                                                        type="blue"
                                                        :icon="mdiCheckBold"
                                                        :disabled='loadingManutencao'>
                                                    </simpleButton>
                                                </div>
                                            </b-popover>
                                        </div> 
                                    </div>
                                </div>
                            </td>
                            <!-- Sem status -->
                            <td v-show="selectedStatus[8]"
                                class="card-column card-list-veiculo">
                                <div
                                    class="clear-list col-sm-12 row">
                                    <div v-for="(veiculo, s) in dadosKanbanCpt.veiculo"
                                        :key="s"
                                        class="item item-veiculo"
                                        event="click"
                                        v-on:click="clickCard(veiculo)">
                                        <cardVeiculoKanban
                                            :id="'veiculo'"
                                            :item="veiculo">
                                        </cardVeiculoKanban>
                                    </div>
                                </div>  
                            </td>
                        </tr>
                    </body>
                </table>
            </div>
            <!-- Modal Detalhes da operação -->
            <template>
                <modalDetalhesOperacao
                    id="modalDetalhesOperacao-2"
                    ref="modalDetalhesOperacao"
                    @atualizaKanban ="listagemKanban(empresaSelecionada)"
                    @criarRota      ="openModalCriarRota">
                </modalDetalhesOperacao>
            </template>
            <!-- Modal criar rota -->
            <template>
                <modalCriarRotaPainelControle
                    id="3"
                    ref="modalCriarRota"
                    @atualizaKanban ="listagemKanban(empresaSelecionada)">
                </modalCriarRotaPainelControle>
            </template>
            </div>
    </panelEagle>
</template>

<script lang="js">
import Vue from 'vue'
import { mdiBulletinBoard, mdiPower,
            mdiTruck, mdiInformation, mdiDownloadOutline,
            mdiPencil, mdiCheckBold, mdiCloseThick, mdiUploadOutline, 
            mdiPlus, mdiAlert }     from '@mdi/js'
import {EmpresasService}          from '../../../Services/auth/Empresas.service'
import {HttpRequest}              from '../../../Services/auth/HttpRequest.Service'
import { required }               from 'vuelidate/lib/validators'
import { FiltrosService }         from '@/Services/filtros/filtros.Service'
import { conectionError } from '@/Services/Helpers/swellHeper'
import { DateTime }     from "luxon"
import { mapGetters } from 'vuex'
export default Vue.extend({
	name:'painelControle',
	components:{
		'tituloPage'            : require('@/components/Atom/Header/Titulo').default,
		'selectAll'             : require('@/components/Atom/Select/SelectAll').default,
		'panelEagle'            : require('@/components/Atom/Panel/PanelEagle').default,
		'inputSearch'           : require('@/components/Atom/Inputs/InputSearch').default,
		'statusInformation'     : require('@/components/Atom/StatusInformation/StatusInformation').default,
		'baseIcon'              : require('@/components/Atom/Icon/BaseIcon').default,
		'slideUpAndDown'        : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
		'simpleButton'          : require('@/components/Atom/Buttons/SimpleButton').default,
		'modalDeleta'           : require('@/components/Atom/ModalSpecific/ModalDeleta').default,
		'modalDetalhesOperacao' : require('@/components/Atom/ModalSpecific/ModalOperacaoPainelControle').default,
		'modalCriarRotaPainelControle' :require('@/components/Atom/ModalSpecific/ModalCriarRotaPainelControle').default,
		'selectTable'           : require('@/components/Atom/Select/SelectTable').default,
        'cardVeiculoKanban'     : require('@/components/Atom/SpecificComponents/PainelControleCargo/CardVeiculoKanban').default,
	},

	validations:{
		empresaSelecionada:{ required },
		dadosDocumentosModaldetalhesOperacao: { required},
	},

	data(){
		return{
			mdiBulletinBoard   :mdiBulletinBoard,
			mdiPower   : mdiPower,
			mdiTruck           : mdiTruck,
			mdiInformation     : mdiInformation,
			mdiPencil          : mdiPencil,
			mdiCheckBold       : mdiCheckBold,
			mdiCloseThick      : mdiCloseThick,
			mdiDownloadOutline : mdiDownloadOutline,
			mdiUploadOutline   : mdiUploadOutline,
			mdiPlus            : mdiPlus,
			mdiAlert           : mdiAlert,
			optionsArrayEmpresas: new EmpresasService().Get(),
			options: [
				{ text: 'Disponivel',             value: true },
				{ text: 'Agendado carregamento ', value: true },
				{ text: 'Agendado venda ',        value: true },
				{ text: 'Carregado',              value: true },
				{ text: 'Faturado',               value: true },
				{ text: 'Iniciado ',              value: true },
				{ text: 'Finalizado',             value: true },
				{ text: 'Manutenção',             value: true },
				{ text: 'Sem status ',            value: true },
			],
			alterarStatusVeiculo:[
				{text: 'Disponível', value: '1', color: 'grey', widthItem: '248px'},
				{text: 'Manutenção', value: '8', color: 'red', widthItem: '248px'}
			],
			alterarStatusRota: [
				{text: 'Agendado Carregamento', value: '7', color: 'purpleLight', widthItem: '268px'},
				{text: 'Agendado venda',        value: '2', color: 'purple', widthItem: '268px'},
				{text: 'Carregado',             value: '6', color: 'blue', widthItem: '268px'},
				{text: 'Faturado',              value: '3', color: 'orange', widthItem: '268px'},
				{text: 'Iniciado',              value: '4', color: 'yellow', widthItem: '268px'},
				{text: 'Finalizado',            value: '5', color: 'green', widthItem: '268px'},
			],
			optionsNfe: [],
			optionsCabecalho:[
				{value: 'NFE'},
				{value: 'Código'},
				{value: 'Data'},
				{value: 'Placa'},
			],
			loadingNfe: false,
			selectedStatus: [true, true, true, true, true, true, true, true, true],
			// Kanban
			grupoVeiculoSelecionado : [],
			empresaSelecionada      : [],
			optSelectGrupoVeiculos  : [],
			loadingGrupoVeiculos    : false,
			filtroTabelaRotasModalDetalhesOperacao : false,
			link: '/eagle/cargo/nao/menu/painel/controle/',
			loadingPanel                           : false,
			dadosKanbanDisponivel                  : [],
			dadosKanbanAgendadoCarregamento        : [],
			dadosKanbanAgendadoVenda               : [],
			dadosKanbanCarregado                   : [],
			dadosKanbanFaturado                    : [],
			dadosKanbanIniciado                    : [],
			dadosKanbanFinalizado                  : [],
			dadosKanbanManutencao                  : [],
			dadosKanbanSemStatus                   : [],
			dadosKanbanTotais                      : [],
			semKanban                              : false,
			dadosKanban                            : [],
			dadosFilterKanban                      : [],
			NFESelecionado                         : '',
			statusBar                              : 'info',
			atualiza                               : undefined,
			atualizando                            : '',
			carregandoVeiculos                     : false,
			rotasAgendadas                         : [],
			loadingRotasAgendadas                  : false,
			statusBarRotasAgendadas                : 'info',
			codigoRotaAgendadaExcluir              : '',
			placaCriarRota                         : '',

			// Modal Manutenção
			popoverShowManutencao                  : [],
			disabled                               : false,
			loadingManutencao                      : false,
            selectedEmpresa                        : [],
		}
	},

	beforeDestroy(){
		this.atualiza = clearInterval(this.atualiza)
	},

    methods:{
        ...mapGetters(['getEditar', 'getMaster']),
        
        changeEmpresa(empresa){
            this.empresaSelecionada = empresa
            this.$refs.refGrupoVeiculos.clearAll()
            this.buscaGrupoVeiculos(empresa)
            this.listagemKanban(empresa)
            this.listagemRotasAgendadas()
        },

		changeGrupoVeiculos(grupoVeiculo){
			this.grupoVeiculoSelecionado = grupoVeiculo
			if(grupoVeiculo.length > 0){
				this.listagemKanban(this.empresaSelecionada)
			}
		},

		/**
         * @listen changeSelect       - executado quando é selecionada uma nfe
         * @description Método para abrir o modal de operação quando uma nfe for selecionada
         * @param  {String} nfe       - número da nota fiscal (começa a buscar a partir de três números)
         * @return {Array} optionsNfe - nfe formatadas para o select
         * @author Vitor Hugo 🐨
         */
		changeNFE(nfe){
			var elemento = this.optionsNfe.find(element => {
				return element.ronfes == nfe
			})
			this.modalDetalhesOperacao(elemento.roplaca, 
				elemento.rocodigo,
				elemento.veprefixo,
				elemento.rostatus)
		},

		/**
         * @listen changeSearch       - executado quando input de busca do select for acionado
         * @description Método para buscar as nfe 
         * @param  {String} nfe       - número da nota fiscal (começa a buscar a partir de três números)
         * @return {Array} optionsNfe - nfe formatadas para o select
         * @author Vitor Hugo 🐨
         */
		buscaNFE(nfe){
			if(nfe.length > 2){
				this.loadingNfe = true
				new HttpRequest().Post(this.link+'buscar/nfe', 
					{'nfe'     : nfe,
						'cliente' : this.empresaSelecionada})
					.then(dados=>{
						this.optionsNfe = dados.data
						this.loadingNfe = false
					})
			}
		},

		/**
         * Filtro para busca dos kanbans por placa e prefixo (utilizado JSON pois o javascript estava alocando
         * o mesmo espaço de memória para as duas variáveis e assim não funcionando o filtro)
         * @param Objeto dadosKanban com os array do kanban (disponível, carregamento, agendado, carregado,
         * faturado, iniciado, finalizado, manutencao e veiculos)
         * @return Objeto dadosFilterKanban contém os arrays dos kanbans filtrados
         * @author Vitor Hugo 🐨
         */
		changePlaca(placa){
			var filtro = []
			var aux = this.dadosKanban
			var aux2 = JSON.stringify(aux)
			var obj = JSON.parse(aux2)
			for (const key in this.dadosKanban) {
				filtro = this.dadosKanban[key].filter(element => {
					return element.veiculo.placa.toLowerCase().includes(placa.toLowerCase()) || 
                            element.veiculo.prefixo.toLowerCase().includes(placa.toLowerCase());
				});
				obj[key] = filtro
			}
			this.dadosFilterKanban = obj
		},

		async buscaGrupoVeiculos(arr){
			this.loadingGrupoVeiculos = true
			new FiltrosService().dados_filtros(arr, ['GV'])
				.then((data)=>{
					this.optSelectGrupoVeiculos = data.GV
					this.loadingGrupoVeiculos = false
				})
		},

		/**
         * Busca os kanbans
         * @param Array empresaSelecionada empresa informada
         * @param Array grupoVeiculoSelecionado grupo selecionado
         * @param String filtroNFE a noto fiscal informada
         * @return Array com os kanbans
         * @author Vitor Hugo 🐨
         */
		async listagemKanban(empresas){
            if(empresas && empresas.length) {
                this.loadingPanel = true
                let body = {
                    'clientes': empresas,
                    'grupos': this.grupoVeiculoSelecionado,
                    'filtroNFE': this.NFESelecionado[0]
                }
                var dados = await new HttpRequest().Post(this.link+'consulta', body)
                this.controleIntervalo('criar')
                if(dados.status && dados.data.totais){
                    let data = dados.data
                    this.dadosKanbanTotais = data.totais
                    this.dadosKanban = data.dados
                    this.dadosFilterKanban = this.dadosKanban
                    this.loadingPanel = false
                    data.dados.manutencao.forEach(element => {
                        this.popoverShowManutencao.push(false)
                    });
                } else {
                    this.dadosKanbanTotais = []
                    this.statusBar = 'error'
                    this.loadingPanel = false
                }
            } else {
                this.dadosKanbanTotais = []
                this.dadosKanban = []
                this.dadosFilterKanban = []
                this.popoverShowManutencao = []
            }
		},

		/**
         * Atualiza os kanbans
         * @param Array empresaSelecionada empresa informada
         * @param Array grupoVeiculoSelecionado grupo selecionado
         * @param String filtroNFE a noto fiscal informada
         * @return Array com od kanbans atualizadas
         * @author Vitor Hugo 🐨
         */
		async atualizarListagemKanban(){
			this.atualizando = 'Atualizando veículos' 
			this.carregandoVeiculos = true
            let body = {
                'clientes': this.empresaSelecionada,
                'grupos': this.grupoVeiculoSelecionado,
                'filtroNFE': this.NFESelecionado[0]
            }
            var dados = await new HttpRequest().Post(this.link+'consulta', body)
                .then((dados)=>{
                    if(dados.status && dados.data.totais) {
                        this.dadosKanbanTotais = dados.data.totais
                        this.dadosKanban = dados.data.dados
                        this.dadosFilterKanban = this.dadosKanban
                    } else {
                        this.statusBar = 'error'
                        this.dadosKanbanTotais = []
                    }
                })
                .catch((e)=>{
                    this.statusBar = 'error'
                    this.dadosKanbanTotais = []
                })
                .finally(()=>{
                    this.atualizando = ''
                    this.carregandoVeiculos = false
                })
		},


		async controleIntervalo(func){
			if(func == 'criar'){
				if(this.atualiza === undefined){
					this.atualiza = setInterval(() => {
						this.atualizarListagemKanban()
						this.atualizaListagemRotasAgendadas()
					}, 90000);
				}
			}else{
				//destruir intervalo
				if(this.atualiza !== undefined){
					clearInterval(this.atualiza)
					this.atualiza = undefined
				}
			}
		},

		/**
         * Busca as rotas agendadas
         * @param Array empresaSelecionada empresa informada
         * @param String data  a data atual
         * @return Array com as rotas
         * @author Vitor Hugo 🐨
         */
		async listagemRotasAgendadas(){
            if(this.empresaSelecionada.length) {
                this.loadingRotasAgendadas = true
                var data = DateTime.now().toFormat('dd/LL/yyyy')
                let body = {'clientes': this.empresaSelecionada, 'data': data}
                var dados = await new HttpRequest().Post(this.link+'rotas/agendadas', body)
                if(dados.status && dados.data && dados.data.length){
                    this.rotasAgendadas = dados.data
                    this.loadingRotasAgendadas = false
                } else {
                    this.statusBarRotasAgendadas = 'error'
                    this.loadingRotasAgendadas = false
                }
            } else {
                this.rotasAgendadas = []
            }
		},

		/**
         * Atualiza as rotas agendadas
         * @param Array empresaSelecionada empresa informada
         * @param String data  a data atual
         * @return Array com as rotas atualizadas
         * @author Vitor Hugo 🐨
         */
		async atualizaListagemRotasAgendadas(){
			var data = DateTime.now().toFormat('dd/LL/yyyy');
			var dados = await new HttpRequest()
				.Post(this.link+'rotas/agendadas', 
					{'clientes'  : this.empresaSelecionada,
						'data'      : data,});
            if(dados.status && dados.data && dados.data.length)
			this.rotasAgendadas = dados.data
		},

		cancelarRotaAgendada(rota){
			this.codigoRotaAgendadaExcluir = rota
			this.$bvModal.show('modal-deleta-'+1)
		},

		/**
         * Exclui as rotas agendadas
         * @return Mensagem de erro ou sucesso
         * @author Vitor Hugo 🐨
         */
		async excluirRotasAgendadas(){
			this.loadingRotasAgendadas = true
			var dados = await new HttpRequest()
				.Post(this.link+'cancelar/rota', 
					{'rota'  : this.codigoRotaAgendadaExcluir,});
			if(dados.data.status == 1){
				this.listagemRotasAgendadas()
			} else {
				this.loadingRotasAgendadas = false
				conectionError()
			}
		},

		ativarPopover(m){
			for (let index = 0; index < this.popoverShowManutencao.length; index++) {
				this.fecharPopover(index)
			}
			this.popoverShowManutencao[m] = true
			this.disabled = false
		},

		fecharPopover(m){
			this.popoverShowManutencao[m] = false
			this.disabled = true
		},

		/**
         * Altera status do veículo de manutenção para disponível
         * @param String placa do veículo
         * @return Mensagem de erro ou sucesso
         * @author Vitor Hugo 🐨
         */
		async alterarStatusVeiculoManutencao(placa, m){
			this.loadingManutencao = true
			var dados = await new HttpRequest()
				.Post(this.link+'alterar/status/manutencao', 
					{'placa'  : placa,});
			if(dados.status){
				this.popoverShowManutencao[m] = false
				this.loadingManutencao = false
				this.listagemKanban(this.empresaSelecionada)
			} else {
				this.popoverShowManutencao[m] = false
				this.loadingRotasAgendadas = false
				this.loadingManutencao = false
				conectionError()
			}
		},

		//Modal detalhes da operação ------------------------------------------------------------------------------
        
		/**
         * Chama a modal de detalhes operação, fazendo isso
         * antes de conjurar a modal, tentando manter tudo atualizado
         * @author Vitor 🐨
         */
		modalDetalhesOperacao(placa, rota, prefixo){
			for (let index = 0; index < this.popoverShowManutencao.length; index++) {
				this.fecharPopover(index)
			}
			this.placaCriarRota = placa
			this.$refs.buscaNfe.clearAll()
			this.$refs.modalDetalhesOperacao.preparaModal(placa, rota, prefixo)
		},

        clickCard(dados) {
            this.modalDetalhesOperacao(
                dados.veiculo.placa, dados.rota, dados.veiculo.prefixo)
        },

		//Modal criar rota ----------------------------------------------------------------------------------------
        
		/**
         * Chama o modal de criar rota, fazendo isso
         * antes de conjurar a modal, tentando manter tudo atualizado
         * @author Vitor 🐨
         */
		openModalCriarRota(){
			this.$refs.modalCriarRota.preparaModalCriarRota(this.empresaSelecionada, this.placaCriarRota)
		},
	},
    
	computed: {
		dadosKanbanCpt(){
			return this.dadosFilterKanban
		},

	},

    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optionsArrayEmpresas
        }
    }
})
</script>

<style lang="scss">
#painelControle{
    .templateSlide{
        border: 1px solid #ced4da;
        height: 30px;
        vertical-align: middle;
        .atualizando{
            padding-left: 7px;
            padding-top: 10px;
            vertical-align: middle;
        }
    }
    .tableRotasAgendadas{
        padding-left: 0px;
        border: #bbbaba;
        min-height: 210px;
        width: 512px;
        box-shadow: 0px 3px 8px 0px grey;
    }
    .trHeadRotasAgendadas{
        th{
            text-align: center !important;
            color: #fff;
            background: #808080;
            height: 30px;
            font-size: 14px;
            vertical-align: middle;
            padding-top: 7px;
        }
    }
    .progHeadRotasAgendadas{
        th{
            height: 30px;
            vertical-align: middle;
            padding-right: 15px;
        }
    }
    .trBodyRotasAgendadas{
        td{
            background: #fff;
            padding: 5px;
            vertical-align: middle;
        }
    }

    .iconCancelarRota{
        color: #e04b4a;
    }
    .iconCancelarRota:hover{
        cursor: pointer;
    }

    // Rotas Agendadas
    .titulosfilt {
        padding: 0;
        margin: 10px 0px 0;
        font-size: 14px;
    }
    #tablePendentesPC{
        margin-top: 15px;
    }
    .tablePendentes {
        box-shadow: 0px 3px 8px 0px grey;
        // margin-top: 30px;
        // margin-right: 15px;
        // float: right;
        table {
            display: flex;
            flex-flow: column;
            height: 180px;
            margin-top: 15px;
            overflow: auto;
            tbody {
                height: 100%;
                tr {
                    width: 100%;
                    td {
                        padding: 5px 3px 2px 7px;
                    }
                    &.trRed {
                        background-color: #ffb0b0;
                    }
                }
            }
            thead, tbody tr {
                display: table;
                width: 100%;
            }
            td, th {
                text-align: left;
                padding: 3px;
                &.tdthPlaca {
                    width: 8%;
                    min-width: 80px;
                }
                &.tdthData {
                    width: 8%;
                    min-width: 60px;
                }
                &.tdthOrigemDestino {
                    width: 20%;
                    min-width: 100px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 100px;
                }
                &.tdTempo {
                    width: 8%;
                    min-width: 60px;
                }
                &.thTempo {
                    width: 8%;
                    min-width: 60px;
                }
                &.tdthProgramacao {
                    width: 11%;
                    min-width: 100px;
                }
                &.tdPower {
                    width: 5%;
                    min-width: 25px;
                    padding: 1px 1px 0px 2px;
                    vertical-align: middle;
                    .iconCancelarRota{
                        border-radius: 5px;
                        text-align: center;
                        height: 20px;
                        width: 20px;
                        background: #E04B4A;
                        color: #fff;
                        vertical-align: middle;
                    }
                }
                &.thPower {
                    width: 5%;
                    min-width: 25px;
                    padding: 1px 1px 0px 2px;
                }
            }
            .nenhumaRota {
                text-align: center;
                font-size: 14px;
            }
            tr {
                &.carregamento {
                    background-color: #c389d4;
                }
                &.venda {
                    background-color: #8f2b9a;
                    color: lightgrey;
                }
                &.carregado {
                    background-color: #33bbde;
                }
                &.faturado {
                    background-color: #FFA600;
                }
            }
        }
        .divTitle {
            margin: 0;
            display: inline-flex;
            width: 100%;
            .title1 {
                width: 60%;
                background-color: grey;
                color: white;
                padding: 8px 10px 2px 10px !important;
                text-align: right;
            }
            .title2 {
                width: 40%;
                background-color: grey;
                padding: 3px 8px 1px 2px;
                text-align: end;
                input {
                    width: 100px !important;
                    text-align: center !important;
                    cursor: pointer;
                    height: 24px !important;
                    float:right;
                    color: grey;
                    background-color: #f8f8f8;
                    box-shadow: 1px 1px 2px lightgrey;
                }
                span {
                    padding: 5px 10px 5px 10px;
                    color: white;
                }
            }
        }
    }

    //Kanbam
    #divDados {
        // height: calc(100vh - 420px);
        overflow-y: hidden;
        min-height: 250px;
        // height: 400px;
        padding: 0px 0px 10px 0px;
        // overflow-x: auto;
        .row-card-headers {
            border-spacing: 5px 0;
            border-collapse: separate;
            display: table;
            table-layout: fixed;
            width: 100%;
            padding: 0;
            margin: 0;
            .card-title {
                padding: 8px 6px 6px 6px;
                border-top-right-radius: 5px;
                border-top-left-radius: 5px;
                border: 1px solid #3572b0;
                border-bottom: 0;
                display: table-cell;
                min-width: 110px;
                width: 110px;
                text-align: center !important;
                vertical-align: middle;
                color: #fff;
            }
            span {
                font-size: 14px;
                color: white;
                margin: 0;
                .qtd {
                    font-size: 18px;
                }
            }
            .card-header-disponivel{
                background: #a6a6a6;
            }
            .card-header-carregamento{
                background: #c389d4;
            }
            .card-header-venda{
                background: #8f2b9a;
            }
            .card-header-carregado{
                background: #33bbde;
            }
            .card-header-faturado{
                background: #FFA600;
            }
            .card-header-iniciado{
                background: #d6c433;
            }
            .card-header-finalizado{
                background: #30a82f;
            }
            .card-header-manutencao{
                background: #ee6969;
            }
            .card-header-veiculo{
                background: #000;
            }
        }
        .row-card-columns {
            border-spacing: 5px 0;
            border-collapse: separate;
            display: table;
            table-layout: fixed;
            width: 100%;
            padding: 0;
            margin: 0;
            height: 340px;
            .card-column {
                display: table-cell;
                // padding: 5px;
                border: 1px solid #3572b0;
                border-radius: 0 0 7px 7px;
                border-top: 0;
                min-width: 110px;
                width: 110px;
                .limit-text {
                    display: block;
                    display: -webkit-box;
                    height: 2.6em;
                    line-height: 1.3em;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .clear-list {
                    overflow-y: auto;
                    overflow-x: hidden;
                    padding: 5px;
                    max-height: 340px;
                    &::-webkit-scrollbar {
                        width: 5px;
                    }
                    &::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 5px rgba(0,0,0,0.5);
                        border-radius: 5px;
                    }
                    &::-webkit-scrollbar-thumb {
                        border-radius: 5px;
                        box-shadow: inset 0 0 5px rgba(0,0,0,0.5);
                    }
                    .carro-placa {
                        // display: flex;
                        // height: 28px;
                        .carro-icon {
                            width:50px;
                        }
                        .placa {
                            margin:auto;
                            font-size:16px;
                            text-align:start;
                            font-family: nexabold, sans-serif !important;
                        }
                    }
                    .item-disponivel {
                        background-color: rgba(166,166,166,0.1);
                        border: 2px #a6a6a6 solid;
                        .div-disponivel {
                            color: #a6a6a6;
                        }
                    }
                    .item-carregamento {
                        background-color: rgba(200,125,222,0.1);
                        border: 2px #c389d4 solid;
                        .div-carregamento {
                            color: #c389d4;
                        }
                    }
                    .item-venda {
                        background-color: rgba(169,66,147,0.1);
                        border: 2px #8f2b9a solid;
                        .div-venda {
                            color: #8f2b9a;
                        }
                    }
                    .item-carregado {
                        background-color: rgba(98,200,226,0.1);
                        border: 2px #33bbde solid;
                        .div-carregado {
                            color: #33bbde;
                        }
                    }
                    .item-faturado {
                        background-color: rgba(255,167,4,0.1);
                        border: 2px #FFA600 solid;
                        .div-faturado {
                            color: #FFA600;
                        }
                    }
                    .item-iniciado {
                        background-color: rgba(214, 196, 51, 0.1);
                        border: 2px #d6c433 solid;
                        .div-iniciado {
                            color: #d6c433;
                        }
                    }
                    .item-finalizado {
                        background-color: rgba(48,168,47,0.1);
                        border: 2px #30a82f solid;
                        .div-finalizado {
                            color: #30a82f;
                        }
                    }
                    .item-manutencao {
                        background-color: rgba(238,105,105,0.1);
                        border: 2px #ee6969 solid;
                        cursor: pointer;
                        .div-manutencao {
                            color: #ee6969;
                        }
                    }
                    .item-veiculo {
                        background-color: rgba(0,0,0,0.1);
                        border: 2px black solid;
                        .div-veiculo {
                            color: black;
                        }
                    }
                    .div-alert{
                        color: #f12e2e;
                    }
                    .item {
                        padding: 0 5px 5px 5px;
                        text-align: left;
                        border-radius: 10px;
                        font-size: 14px;
                        margin: 3px 0 3px 0;
                        width: 100% !important;
                        max-width: 298px;
                        opacity: 1;
                        // height: 80px;
                        transition: opacity 0.2s;
                        box-shadow: 0px 2px 4px 0px #858585;
                        text-align: flex;
                        vertical-align: top;
                        cursor: pointer;
                        &:hover {
                            opacity: 0.5;
                        }
                        div {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            width: 100%;
                            overflow: hidden;
                            font-size: 12px;
                        }
                        .carro-placa {
                            display: flex;
                            height: 28px;
                            .carro-icon {
                                padding-top:5px;
                                width:50px;
                            }
                            .placa {
                                padding-top:5px;
                                margin:auto;
                                font-size:16px;
                                text-align:start;
                                font-family: nexabold, sans-serif !important;
                            }
                        }
                        // .marquee {
                        //     margin: 0 auto;
                        //     overflow: hidden;
                        //     box-sizing: border-box;
                        //     span {
                        //         display: inline-block;
                        //         width: max-content;
                        //         padding-left: 100%;
                        //         will-change: transform;
                        //         animation: marquee 10s linear infinite;
                        //         &:hover {
                        //             animation-play-state: paused
                        //         }
                        //     }
                        // }
                        @keyframes marquee {
                            0% { transform: translate(0, 0); }
                            100% { transform: translate(-100%, 0); }
                        }
                        @media (prefers-reduced-motion: reduce) {
                            .marquee span {
                                animation-iteration-count: 1;
                                animation-duration: 0.01; 
                                width: auto;
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
#modalLarge {
    .ultima-posicao-modal{
        color: #428bca;
        cursor: pointer;
    }
    .modal-dialog {
        width: 70%;
        max-width: 1200px;
        min-width: 700px;
        overflow: auto;
    }
    .modal-painel-controle {
        min-height: 520px;
        padding: 0;
        height: 100%;
    }
    .divModal {
        padding: 0px 0px !important;
        .div-tooltip {
            margin-top: 10px;
            display: inline-flex;
            max-height: 100px;
            margin-left: 10px;
            .timeline-bar {
                height: 9px;
                margin: 10px 0 0 0;
                background-color: grey;
                opacity: 0.5;
                width: 100%;
            }
            .tooltip-custom {
                z-index: 1;
                height: 80px;
                width: 120px;
                margin-right: 10px !important;
                text-align: center;
                box-shadow: 2px 2px 6px 1px #585858;
                padding: 8px;
                color: white;
                background-color: grey;
                justify-content: center;
                align-items: center;
                display: flex;

                // EXEMPLO TOOLTIP {
                // border-radius: 30px;
                // height: 30px;
                // width: 30px;
                // position: absolute;
                // .tooltip-text {
                //     z-index: 1;
                //     font-family: "Open Sans", sans-serif;
                //     width: 120px;
                //     color: #fff;
                //     text-align: center;
                //     border-radius: 6px;
                //     padding: 10px;
                //     position: absolute;
                //     bottom: 130%;
                //     left: 50%;
                //     margin-left: -60px;
                //     transition: .3s;
                //     text-transform: capitalize;
                //     p {
                //         margin: 0 !important;
                //     }
                // }
                // .tooltip-text::after {
                //     content: "";
                //     position: absolute;
                //     top: 100%;
                //     left: 50%;
                //     margin-left: -10px;
                //     border-width: 10px;
                //     border-style: solid;
                //     border-color: dimgrey transparent transparent transparent;
                // }
                // EXEMPLO TOOLTIP }
            }
            .tooltip-custom:hover {
                // z-index: 2;
                .tooltip-text {
                    z-index: 2;
                    visibility: visible;
                    opacity: 1;
                    transform: translate3d(0px, 5px, 0px);
                }
            }
            .item-disponivel {
                background-color: #a6a6a6 !important;
                &:after {
                    border-color: #a6a6a6 transparent transparent transparent !important;
                }
            }
            .item-carregamento {
                background-color: #c389d4 !important;
                &:after {
                    border-color: #c389d4 transparent transparent transparent !important;
                }
            }
            .item-venda {
                background-color: #8f2b9a !important;
                &:after {
                    border-color: #8f2b9a transparent transparent transparent !important;
                }
            }
            .item-carregado {
                background-color: #33bbde !important;
                &:after {
                    border-color: #33bbde transparent transparent transparent !important;
                }
            }
            .item-faturado {
                background-color: #FFA600 !important;
                &:after {
                    border-color: #FFA600 transparent transparent transparent !important;
                }
            }
            .item-iniciado {
                background-color: #d6c433 !important;
                &:after {
                    border-color: #d6c433 transparent transparent transparent !important;
                }
            }
            .item-finalizado {
                background-color: #30a82f !important;
                &:after {
                    border-color: #30a82f transparent transparent transparent !important;
                }
            }
            .item-manutencao {
                background-color: #ee6969 !important;
                &:after {
                    border-color: #ee6969 transparent transparent transparent !important;
                }
            }
            .item-veiculo {
                background-color: black !important;
                &:after {
                    border-color: black transparent transparent transparent !important;
                }
            }
        }
        .info-timeline {
            overflow-x: auto;
            overflow-y: hidden;
            height:105px;
            background-color: aliceblue !important;
        }
        .info-timeline::-webkit-scrollbar {
            width: 5px !important;
            height: 5px !important;
        }
        .inputFiles {
            background-color: lightsteelblue;
            text-overflow: ellipsis;
            padding: inherit;
        }
        .headListDocs {
            border-bottom: 1px solid lightgrey;
            height: 25px !important;
            margin-bottom: 5px;
            padding: 0px 0px 0px 14px;
        }
        .emptyResult{
            line-height: 105px;
            text-align: center;
            background-color: #ef9c9c;
            opacity: 0.8;
        }
        .selectResult {
            line-height: 105px;
            text-align: center;
            background-color: aliceblue;
            opacity: 0.8;
        }
        .loadResult {
            line-height: 60px;
            text-align: center;
            background-color: #428BCA;
            opacity: 0.8;
            color: white;
        }
    }
    .div-modal-bg {
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 2;
        background-color: lightgrey;
        opacity: .5;
        display: none;
        font-size: 30px;
        .loading {
            text-align:center;
            line-height: 520px;
        }
    }
    .areaUploadFiles {
        background-color: #c5c5c5;
        display: inline-flex;
        width: 100%;
        .div-title {
            line-height: 50px;
            padding-left: 15px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        #dataRotaModal {
            width: 185px;
            text-align: center;
            height: 31px;
            margin: 2px 0 0 0;
            color: grey;
            // box-shadow: 1px 1px 3px #6b6b6b;
            // cursor: not-allowed;
        }
        .filtro-data-modal {
            height: 31px;
            width: 31px;
            padding: 6px 8px;
            margin: 2px;
            color: #fff;
            background-color: #428bca;
            border-color: #357ebd;
            border-radius: 4px;
        }
    }
    .areaListRotas {
        height: 220px;
        background: #f5f5f5;
        overflow: auto;
        padding-left: 0px;
        .areaRotasVeiculo {
            text-align: center;
            // display: grid;
            // grid-template-columns: 50px 50px 50px auto auto 50px 25px;
            padding: 10px 0px;
            .borderb {
                border-bottom: 1px solid #ddd;
                padding: 5px 2px 0px 2px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                color: white;
            }
            .iconAlert{
                border-bottom: 1px solid #ddd;
                padding: 2px 2px 0px 2px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                color: #f12e2e;
            }
            .btn-power-modal {
                width: 25px;
                padding: 1px 1px 0px 0px;
                height: 27px;
                border-bottom: 1px solid #ddd;
                cursor: pointer;
                vertical-align: middle;
                .iconCancelarRota {
                    padding: 0;
                    border-radius: 5px;
                    text-align: center;
                    height: 24px;
                    width: 24px;
                    background-color: #E04B4A;
                    color: white;
                    vertical-align: middle;
                }
                .popover {
                    width: 240px;
                }
            }
            .headListRotas {
                border-bottom: 1px solid lightgrey;
                height: 25px;
                min-width: 25px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                padding: 1px;;
            }
            .consultar-historico {
                cursor: pointer;
                -webkit-text-stroke-width: medium;
            }
            .alert-icon {
                padding-right: 10px;
                color: orangered;
                height: 27px;
                border-bottom: 1px solid #ddd;
                &:before {
                    margin: 0;
                    padding-left: 5px;
                    font-size: 18px;
                }
            }
        }
        .item {
            display: grid;
            grid-template-columns: 25px 50px 50px 65px 65px 16% 16% 12% 12% 25px;
            min-width: 500px;
        }
    }
    .areaFilesTitle {
        background-color: #c5c5c5;
        display: inline-flex;
        width: 100%;
        height: 50px;
        .div-input {
            float: right;
            color: white;
            height: 35px;
            background-color: #428BCA;
            line-height: 35px;
            text-align: center;
            margin: 0 0 0 auto;
            cursor: not-allowed;
            opacity: 0.7;
            font-size: 12px;
            display: inline-flex;
            min-width: 75px;
            margin-right: 3px;
            .fa-upload::before {
                line-height: 30px;
                padding: 7px;
            }
        }
        .div-title {
            line-height: 35px;
            padding-left: 15px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 135px;
        }
        .btn-files-title {
            padding: 7px;
            font-family:'nexabook', sans-serif !important
        }

        #sendFilesModal {
            border: none;
            height: 35px;
            padding: 0;
            width: 75px;
            min-width: 75px;
        }
        .infoDocumentosRota {
            line-height: 35px;
        }
    }
    .areaListFiles {
        height: 220px;
        background: #f5f5f5;
        padding: 10px 0;
        overflow-x: auto;
        div {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .borderb {
            border-bottom: 1px solid #ddd;
            margin-bottom: 6px;
            a {
                padding: 0 0 0 10px;
            }
        }
        .downloadFilePC {
            cursor: pointer;
            color: #428bca;
        }
        .deleteFilePC {
            cursor: pointer;
            color: red;
        }
        .inputNomeDocumentosVinculados{
            height: 30px;
            vertical-align: middle;
        }
    }
    .areaShowFiles {
        height: 220px;
        background: #f5f5f5;
        padding: 5px 0;
        overflow-x: auto;
        hr {
            margin: 0 0 5px 0;
            background-color: lightgrey;
        }
        .fa-times {
            font-size: 20px;
        }
        .text-ajust {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    .savedFiles {
        background-color: #c5c5c5;
        padding: 10px 12px 5px 12px;
    }
    .timeline-title {
        background-color:#c5c5c5;
        display: inline-flex;
        .title-info {
            padding: 6px 12px 4px 5px !important;
        }
        .info {
            background-color: #c5c5c5;
            padding: 5px 6px 0px 7px !important;
            .fa-info {
                height: 23px;
                width: 23px;
                border-radius: 20px;
                text-align: center;
                line-height: 20px;
                border: 1px #428BCA solid;
                color: white;
                background-color: #428BCA;
            }
        }
    }
    .timeline-button {
        background-color:#c5c5c5;
        button {
            float: right;
            background-color: #428BCA;
            padding: 8px 12px 5px 12px;
            cursor: pointer;
            text-align: center;
            color: white;
        }
    }
    .bottonsStatusModal{
        height: 31px;
        padding: 0;
        color: black;
    }
    .bottonSelectedStatus { color: white; }
    .div-btn-status {
        // display: inline-flex;
        // width: 260px;
        // padding: 10px;
        // text-align: center;
        // min-width: 150px;
        text-align: right;
        margin-left: auto;
        label {
            &:after {
                margin-left: -22px !important;
            }
        }
    }
    .trocar-status-rota {
        // font-size: 12px;
        // line-height: 14px;
        // padding: 2px !important;
        text-align: center;
        color: white;
        background-color: #428BCA;
        cursor: pointer;
        height: 35px;
        line-height: 35px;
        // &:hover {
        //     opacity: 0.7;
        // }
        .fa-spinner {
            line-height: 33px !important;
            font-size: 16px !important;
        }
        span.glyphicon-pencil {
            &:before{
                font-size: 12px;
                padding-right: 5px;
            }
        }
    }
    .trocar-status-veiculo {
        text-align: center;
        color: white;
        background-color: #428BCA;
        cursor: pointer;
        height: 35px;
        line-height: 35px;
        // &:hover {
        //     opacity: 0.7;
        // }
        .fa-spinner {
            line-height: 33px !important;
            font-size: 16px !important;
        }
        .item {
            height: 33px;
            line-height: 33px;
        }
    }
    .loading-status-rota {
        opacity: 0.7;
        cursor: progress !important;
    }
    .disable-status-rota {
        opacity: 0.7;
        cursor: not-allowed;
    }
    .loading-status-veiculo {
        opacity: 0.7;
        cursor: progress;
    }
    .dropbtn-rotas {
        position: absolute;
        float: right;
        z-index: 1;
        top: -198px;
        width: 100%;
        // left: calc(100% - 25% - 8px);
        // padding-right: 7px !important;
        .div-itens {
            height: 100%;
            background-color: white;
            .item {
                padding: 0px 0 3px 10px;
                border-bottom: 1px white solid;
                cursor: pointer;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                height: 33px;
                line-height: 33px;
                &:hover {
                    opacity: 0.8;
                }
            }
            .selected {
                background-color: slategrey !important;
                text-decoration: underline;
            }
        }
    }
    .dropbtn-veiculo {
        position: absolute;
        float: right;
        z-index: 1;
        bottom: 35px;
        width: 100%;
        // padding-right: 8px !important;
        .div-itens {
            height: 65px;
            color: #fff;
            background-color: #428BCA;
            border-color: #428BCA;
            text-align: left;
            .item {
                padding: 0px 0 3px 10px;
                border-bottom: 1px white solid;
                cursor: pointer;
                &:hover {
                    opacity: 0.8;
                }
            }
            .selected {
                background-color: slategrey !important;
                text-decoration: underline;
            }
        }
    }
    .item-manutencao { background-color: #ee6969 !important; }
    .disponivel { background-color: #a6a6a6 !important; }
    .carregamento { background-color: #c389d4 !important; }
    .venda { background-color: #8f2b9a !important; }
    .carregado { background-color: #33bbde !important; }
    .faturado { background-color: #FFA600 !important; }
    .iniciado { background-color: #d6c433 !important; }
    .finalizado { background-color: #30a82f !important; }
    .manutencao { background-color: #ee6969 !important; }
    .veiculo { background-color: black !important; }
    .vazio { color: grey !important; }
    .filtro-nota-modal {
        height: 31px;
        margin: 2px 2px 0 15px;
        width: 70px;
    }
    #mapaPrincipalModalPC{
        height: 450px;
        width: 100%;
        margin-bottom: 20px;
    }
}
</style>
