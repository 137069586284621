<template>
    <div class='d-flex justify-content-around' id='FRegiao'>
        <div id='areaDeRisco' class='d-flex align-items-center'>
            <span
            id='botaoRisco'
            event='click'
            :class='rerisco? "riscoAtivo": "risco"'
            @click="rerisco = !rerisco">
                <base-icon
                :icon='iconArea'
                :size='18'/>
                Área de risco
            </span>
        </div>
        <div id='seletorEmpresa' class='d-flex align-items-center'>
            <multiselect 
            class='seletorFRegiao'
            :options='arrayEmpresas'
            track-by='value'
            label='description'
            placeholder='Selecione uma empresa'
            :preselectFirst='true'
            :showLabels='false'
            :allowEmpty='false'
            v-model='recliente'>
                <template #noResult>
                    Nenhum resultado encontrado
                </template>
            </multiselect>
        </div>
        <div
        id='descricaoRegiao'
        class='d-flex align-items-center'> 
            <input
            placeholder='Descrição'
            type='text'
            class='form-regiao'
            v-model='redescricao'>
        </div>
        <div
        id='velocidadeRegiao'
        class='d-flex align-items-center'> 
            <input
            :class="{
                'form-regiao':true,
                'border border-danger': $v.revelocidade.$invalid
            }"
            type='text'
            id='inputVelocidade'
            placeholder='Velocidade'
            @blur='$v.revelocidade.$touch()'
            v-model="revelocidade">
            <b-tooltip
            target='inputVelocidade'
            triggers='focus'
            variant='danger'
            v-if='!$v.revelocidade.decimal'>
                valor deve ser numérico
            </b-tooltip>
        </div>
        <div
        id='areaRegiao'
        class='d-flex align-items-center'>
            <input
            type='text'
            readonly
            placeholder='Área'
            class='form-regiao'
            v-model='rearea'>
        </div>
        <div
        id='inputCor'
        class='d-flex align-items-center'>
            <input-color
            name='inputcor'
			:value='inputColorValue'
            @changeInput='v=> recor = v'/>
        </div> 
        <div
        id='botoes'
        class='d-flex align-items-center'>
            <loading-button
            type='blue'
            event='click'
            :disabled='regiaoInvalida'
            :icon='iconCheck'
            width='50'
            :title='tituloSalvar'
            :isLoading='salvando'
            @click='salvaRegiao'/>
            <simple-button
            width='50'
            event='click'
            type='red'
            :icon='iconClose'
            @click="cancelaRegiao"/>
        </div>
    </div>
</template>

<script>
import simpleButton from '@/components/Atom/Buttons/SimpleButton.vue'
import baseIcon from '@/components/Atom/Icon/BaseIcon.vue'
import InputColor from '@/components/Atom/Inputs/InputColor.vue'
import Multiselect from 'vue-multiselect'
import loadingButton from '@/components/Atom/Buttons/LoadingButton.vue'
import {conectionError} from '@/Services/Helpers/swellHeper'
import { required, decimal } from 'vuelidate/lib/validators'
import { mdiCheckBold, mdiCloseThick, mdiAlert} from '@mdi/js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
export default {
	components:{
		loadingButton,
		simpleButton,
		Multiselect,
		InputColor,
		baseIcon,
	},
	props: {
		arrayEmpresas: {
			type: Array,
			required: true
		},
		pontos:{
			type: Array,
			default: ()=>{return []}
		}
	},
	validations:{
		redescricao  : { required },
		recliente    : { required },
		rearea		 : { required },
		revelocidade : { decimal },
	},
	data() {
		return {
			iconArea : mdiAlert,
			iconCheck: mdiCheckBold,
			iconClose: mdiCloseThick,
			redescricao  : '',
			revelocidade : '',
			recor        : '',
			recliente    : '',
			rerisco      : false,
			rearea       : '',
			tituloSalvar : '',
			baseUri      : '/finder/mapa/',
			salvando     : false,
			recodigo	 : '',
			regeojson    : false,
			inputColorValue:'#729fcf',
		}
	},
	methods: {
		/**
		 * @param {object}  regiao
		 * @param {string}  regiao.recor 	   - cor da região
		 * @param {number}  regiao.rearea      - área da região
		 * @param {'S'|'N'} regiao.rerisco     - se é região de risco
		 * @param {string}  regiao.redescricao - descrição da região
		 * @param {number}  regiao.recodigo    - código da região
		 * @param {number}  regiao.recliente   - código do cliente
		 * @param {string}  regiao.revelocidade - descrição da região
		 * @param {boolean} regiao.regeojson 
		 * @description Prepara o facilitador no caso de ser uma edição de
		 * região e não a criação de uma regiao nova
		 */
		editaRegiao(regiao){
			this.inputColorValue = regiao.recor
			this.rearea = regiao.rearea
			this.rerisco = regiao.rerisco === 'S'
			this.redescricao = regiao.redescricao
			this.revelocidade = regiao.revelocidade ?? ''
			this.recodigo = regiao.recodigo
			this.regeojson = regiao.regeojson
			this.recliente = this.arrayEmpresas.find((e)=>{
				return e.value === regiao.recliente
			})
		},
		cancelaRegiao() {
			this.$emit('cancela-regiao')
		},

		/**
		 * Faz a request e os escambau pra salvar a região, 
		 * e emite o evento de salvar só depois de, de fato, salvar.
		 * A página principal só vai ter a responsabilidade
		 * de limpar o mapa.
		 */
		salvaRegiao(){
			this.salvando = true
			var uri
			if(this.recodigo === ''){
				uri = `${this.baseUri}salvar/regiao`
				new HttpRequest().Post(uri, this.objRegiao)
					.then((data)=>{
						if(data.code === 201)
							this.$emit(
								'regiao-salva',
								data.data.regiao,
								data.data.pontos
							)
						else 
							conectionError()
					}).finally(()=>{this.salvando = false})
			}else{
				uri = `${this.baseUri}editar/regiao`
				var obj = this.objRegiao
				obj.recodigo = this.recodigo
				new HttpRequest().Post(uri, obj)
					.then((data)=>{
						if(data.data.success)
							this.$emit(
								'regiao-editada', 
								data.data.regiao,
								data.data.pontos
							)
					}).finally(()=>{this.salvando = false})
			}
		},

		/**
		 * @description Faz request pro back-end pra medir uma região,
		 * se der tudo certo, atualiza o campo de área com a informação
		 * @author Gui 🍺
		 */
		medirAreaRegiaoMapa() {
			if(!this.pontos || !this.pontos[0]) return
			var uri = `${this.baseUri}medir/regiao`
			var obj = {
				coord:this.pontos[0]
			}
			new HttpRequest().Post(uri, obj)
				.then((data) => {
					if (data.status && data.code === 200)
						this.rearea = data.data.arrayPontos[0].area
				})
				.catch((e) => { '' });
		}
	},
	computed:{
		regiaoInvalida(){
			return this.$v.$invalid || this.pontos.length != 1
		},

		/**
		 * Encapsula o obj que representa a região e deixa minha vida mais fácil
		 */
		objRegiao(){
			return{
				regiao:{
					recor        : this.recor,
					revelocidade : this.revelocidade,
					recliente    : this.recliente.value,
					redescricao  : this.redescricao,
					rerisco      : this.rerisco?'S':'N',
					rearea       : this.rearea,
				},
				pontos: this.pontos[0]
			}
		},
	},
	watch:{
		pontos(newValue) {
			if(newValue.length === 0 ) this.rearea = 0
			if(newValue.length > 1)
				this.tituloSalvar = 'Apenas uma área por região'
			else{
				this.tituloSalvar = 'Selecione os campos obrigatórios'
				this.medirAreaRegiaoMapa()
			}
		}
	},
}
</script>

<style lang="scss" scoped>
#FRegiao{
    .riscoAtivo{
        color: red
    }
    .risco{ 
        color: black
    }
    #botaoRisco{
        font-size: 10px;
        width: 40px;
        height: 35px;
        margin: 10px;
        opacity: 1;
        cursor: pointer;
    }
    .form-regiao{
        margin-left: 10px;
        height: 42px;
        width: 100px;
        opacity: 1 !important;
    }
    .seletorFRegiao{
        width: 220px;
        opacity: 1;
    }
    #inputCor{
        width: 100px;
        opacity: 1;
    }
}
</style>