/**
    Este é o componente de select que usa em TODO o sistema, tenha cuidado ao mexer nesse aquivo

    -> Propriedades:
        1- nameForRadio - String - name para os componentes de radio/checkbox para não ficarem checados no radio de outros select - EX: 'selectAll'
        2- isMultiple - Boolean - o select é multiplo? - EX: true/false
        3- hasSelectAll - Boolean - tem a opção de selecionar todos? - EX: true/false
        4- firstSelected - String - Index do DadosFiltrosHelper para ser o que ja vem selecionado no select
        5- labels - Array - array das opções de radio / checkbox = EX: [{indexDFH:'Ver index e legenda no DadosFiltrosHelper.php', 'description': Nome por completo da legenda}]
        6- checks - Array - habilita e desabilita seletor (o Rafa que fez, pede pra ele)
        7- optionsArray - Array - array de opções para o select - EX: [{value: 'valor da opção', description: 'Descricao da opção'}]
        8- typeInput - String - tipo de input para colocar na label - EX: 'check'/
        9- selected - Array - array dos itens ja selecionados - EX:[{value: 'valor da opção', description: 'Descricao da opção'}]
        10-extraClass - String, Object, classe extra para o componente
		11-extraClassParent - String, Objectv, classe extra para div pai no componente
        12-clearOnSelect - Boolean, 
			- caso true, mantém o texto do input de busca do select após selecionar uma opção 
			- caso false, limpa o texto do input de busca do select após selecionar uma opção 
    -> Slots:
        Sem slots

    -> Eventos:
        1- changeSelect - Emite quando o select for alterado - Parametros: 
            1.1- arraySelect - Array de selecionados
            1.2- event.value - valor do ultimo selecionado
            1.3- event.description - descricao do ultimo selecionado
        2- changeCheckbox - Emite quando o checked for alterado - Parametros:
            2.1- event - valor do check
        3- changeCheck - Emite quando o radio for alterado - Parametros:
            3.1- labelSelected - IndexDFH do radio selecionado
            3.2- NeedRecharge - Se necessita recarregar as informações
				*não utilizado mais*
        4- selectCriado - Emite no mounted da função com o componente todo,
		para forçar alguma função se necessário
            4.1- this - emite o componente
 */
 <template>
    <div class="col-sm-12" :class="extraClassParent" data-test="select-all-div">
        <div class="col-sm-12 nopadding subTitleSelect" v-if="typeInput == 'check'">
            <b-form-group>
                <b-form-checkbox-group @input="filterCheck" v-model="checked" id="checkboxLabelSelect" :options="checks" name="flavour-1">
                </b-form-checkbox-group>
                <b-form-radio v-for="label in labels" :key="label.indexDFH" v-model="labelSelected" class="mb-03" :name="nameForRadio" :value="label.indexDFH">
                    {{ label.description }}
                </b-form-radio>
            </b-form-group>
            <b-form-group>
                <b-form-radio-group v-model="labelSelected" :name="nameForRadio" @input="clearAll">
                    <b-form-radio v-for="label in labels" :key="label.indexDFH" v-model="labelSelected" class="mb-03" :name="nameForRadio" :value="label.indexDFH">
                        {{ label.description }}
                    </b-form-radio>
                </b-form-radio-group>
            </b-form-group>
        </div>
        <div class="col-sm-12 nopadding subTitleSelect" v-else-if="labels.length > 1">
            <b-form-group>
                <b-form-radio-group v-model="labelSelected" :name="nameForRadio" @input="clearAll">
                    <b-form-radio v-for="label in labels" :key="label.indexDFH" v-model="labelSelected" class="mb-03" :name="nameForRadio" :value="label.indexDFH">
                        {{ label.description }}
                    </b-form-radio>
                </b-form-radio-group>
            </b-form-group>
        </div>
        <div class="col-sm-12 nopadding subTitleSelect divUnicaNoRadio" v-else-if="labels.length == 1">
            <b-form-group>
                <b-form-radio-group v-model="labelSelected" :name="nameForRadio">
                    <b-form-radio v-for="label in labels" :key="label.indexDFH" v-model="labelSelected" class="mb-03" :name="nameForRadio" :value="label.indexDFH">
                        {{ label.description }}
                    </b-form-radio>
                </b-form-radio-group>
            </b-form-group>
        </div>
        <div class="col-sm-12 nopadding subTitleSelect divUnicaNoRadio" v-else></div>
        <div v-if="labelSelected != 'TAG'" class="col-sm-12 nopadding">
            <Multiselect
                data-test="select-all-seletor"
                v-bind="$attrs"
                :class="extraClass"
                v-model="selectedItens"
                :value="'value'"
                :label="'description'"
                :options="optionsSelect"
                :placeholder="'Selecione...'"
                selectLabel="•"
                selectedLabel="✔"
                deselectLabel="x"
                :multiple="isMultiple"
                :taggable="false"
                track-by="value"
                :max-height="600"
                :limit="1"
                :limitText="(count) => `e mais ${count}`"
                :closeOnSelect="!isMultiple"
                :clearOnSelect="clearOnSelect"
                v-on="$listeners"
                @input="changeInput"
                @select="selectAll"
                @close="closeSelect"
                @open="open"
                :preselect-first="false"
            >
                <template v-if="isMultiple" slot="clear" slot-scope="props">
                    <div class="multiselect__clear" v-if="selectedItens.length" @mousedown.prevent.stop="clearAll(props.search)">
                        <BIcon icon="x-square-fill"></BIcon>
                    </div>
                </template>
                <template slot="noResult">
                    Nenhum elemento encontrado
                </template>
                <template slot="noOptions">
                    Nenhum elemento encontrado
                </template>
            </Multiselect>
        </div>
        <div v-else class="col-sm-12 nopadding subTitleSelect">
            <SelectTags
                nameForRadio="seletorVeiculoTags"
                ref="seletorVeiculoTags"
                :taggable="false"
                estiloDivPadrao=""
                :optionsArray="optionsSelect"
                @setTag="changeInput"
            />
        </div>
        <div class="col-sm-12 nopadding subTitleSelect" v-if="typeInput == 'check-down'">
            <b-form-group>
                <b-form-checkbox-group @input="filterCheck" v-model="checked" id="checkboxLabelSelect" :options="checks" name="flavour-1">
                </b-form-checkbox-group>
            </b-form-group>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import SelectTags from "@/components/Atom/Select/SelectTags.vue";
import { isArray } from "util";

export default Vue.extend({
    components: {
        Multiselect,
        SelectTags,
    },
    inheritAttrs: false,
    props: {
        extraClass: {
            type: [String, Object],
            default: "",
        },
        extraClassParent: {
            type: [String, Object],
            default: "",
        },
        nameForRadio: {
            type: String,
            required: true,
        },
        isMultiple: {
            type: Boolean,
            default: false,
        },
        hasSelectAll: {
            type: Boolean,
            default: false,
        },
        firstSelected: {
            type: String,
            default: "",
        },
        labels: {
            type: Array,
            required: false,
            default: function () {
                return [];
            },
        },
        checks: {
            type: Array,
            required: false,
            default: function () {
                return [];
            },
        },
        optionsArray: {
            type: Array,
            required: true,
        },
        typeInput: {
            type: String,
            required: false,
            default: "",
        },
        selected: {
            type: Array,
            required: false,
            default: function () {
                return [];
            },
        },
        clearOnSelect: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        var selectedItens = this.selected;
        var checked = [];
        return {
            selectedItens,
            labelSelected: this.firstSelected,
            checked,
        };
    },
    methods: {
        closeSelect(direto = false) {
            if (direto) this.$emit("close-select", []);
            var itensSelecionados = [];
            if (this.selectedItens) {
                if (this.isMultiple) {
                    itensSelecionados = this.selectedItens.map((item) => {
                        return item.value;
                    });
                } else itensSelecionados = [this.selectedItens.value];
            }
            this.$emit("close-select", itensSelecionados);
        },

        clearAll() {
            this.selectedItens = [];
            this.changeLabelSelect();
            this.changeInput([]);
        },

        selectAll(event) {
            // Se não for múltiplo, não faz nada
            if (!this.isMultiple) return;

            // Selecione todos os itens
            if (event.value === "ST") {
                this.selectedItens = this.optionsSelect.filter(item => item.value !== "ST");
                this.$emit("changeSelect", this.selectedItens.map(item => item.value), event.value, event.description);
            } else {
                // Adiciona o item selecionado ao array se não estiver presente
                if (!this.selectedItens.some(item => item.value === event.value)) {
                    this.selectedItens.push(event);
                }
                this.$emit("changeSelect", this.selectedItens.map(item => item.value), event.value, event.description);
            }
        },

        open(event) {
            this.$emit("open", event);
        },

        changeInput(event) {
            var arraySelect = [];
            if (Array.isArray(event)) {
                for (let selecionado of event) {
                    if (selecionado.value !== "ST") {
                        arraySelect.push(selecionado.value);
                    }
                }
            } else if (event != null) {
                if (event.value !== "ST") {
                    arraySelect.push(event.value);
                }
            }

            if (event != null) {
                this.$emit("changeSelect", arraySelect, event.value, event.description, event.vinculado);
            } else {
                this.$emit("changeSelect", arraySelect, "", "");
            }
        },
        
        filterCheck(event) {
            this.$emit("changeCheckbox", event[0]);
        },
        
        changeLabelSelect() {
            this.$emit("changeCheck", this.labelSelected, true);
        },
    },
    
    mounted: function () {
        this.$emit("changeCheck", this.labelSelected, true);
        this.$emit("selectCriado", this);
    },
    
    computed: {
        optionsSelect() {
            if (this.hasSelectAll) {
                return [
                    {
                        value: "ST",
                        description: "Selecionar Todos",
                    },
                    ...this.optionsArray,
                ];
            } else {
                return this.optionsArray;
            }
        },
    },
    
    watch: {
        selected() {
            if (isArray(this.selected)) {
                this.selectedItens = this.selected;
                this.changeInput(this.selected);
            }
        },
    },
});
</script>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>


<style lang="scss">
.multiselect__clear {
    position: absolute;
    color: #e0484b;
    right: 5px;
    top: 5px;
    height: 21px;
    width: 21px;
    display: block;
    cursor: pointer;
    z-index: 2;
    border-radius: 5px;
    background: #fff;
    font-size: 20px;
}

.multiselect__tags {
    border-radius: 0px;

    .multiselect__tags-wrap {
        .multiselect__tag {
            max-width: 49%;
            background-color: #2196f3;
            border-radius: 0px;

            .multiselect__tag-icon {
                border-radius: 0px;

                &::after {
                    color: #0e5496;
                }

                &:hover {
                    background-color: #1e80da;
                }
            }
        }
    }
}

.multiselect__content-wrapper {
    .multiselect__content {
        .multiselect__element {
            .multiselect__option--highlight {
                background-color: #2196f3;
                z-index: 2;

                &:after {
                    background-color: #2196f3;
                }
            }
        }
    }
}

.subTitleSelect {
    font-size: 13px;
    text-align: left;
    padding-left: 5px !important;
    font-family: nexabook, sans-serif !important;

    .form-group {
        margin-bottom: 0rem !important;
    }

    &.divUnicaNoRadio {
        .custom-control {
            padding-left: 0rem;

            .custom-control-label {
                &::before {
                    display: none;
                }

                &::after {
                    display: none;
                }
            }
        }
    }
}

.multiselect,
.multiselect__input,
.multiselect__single {
    font-size: 13px;
    font-family: nexabook, sans-serif !important;
}

span.multiselect__single {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
}
</style>
