/**
    Modal de fornulário para adicionar/editar um item rota do tipo parada

    -> Propriedades:
        1- rota - Object - Objeto do tipo Rota
        2- selecionados - Array - array de objetos do tipo [ItensRota] com [Pontos]

    -> Slot:
        Sem slot

    -> Eventos:
        1- rotaAlterada - Emite quando salva com sucesso - Sem parâmetro
 */
<template>
    <!-- @modalOpen="getPontos"  -->
    <modalEagle :hardToClose="true" @modalClose="modalClose" size="xl" title="Adicionar pedido ao fim da rota" :id="id"
        class="modal-add-itens-fim-rota">
        <template #modal-cancel>
            <div />
        </template>
        <template #modal-header-close>
            <div />
        </template>
        <slot slot="modalBody">
            <panelEagle :loading="loadingPanel" id="panelAddItemRotaParada">
                <div class="col-sm-12 row nopadding">
                    <div class="col-sm-4 nopadding">
                        <inputRangeWithLimit opens="right" id='Intervalo' name='Intervalo' @changeInput='changeData'
                            :isObrigatorio='false' />
                        <div class="col-sm-12">
                            <h5 class="mt-4">Pedidos selecionados</h5>
                            <div style="max-height:200px;overflow-y: auto;">
                                <table class="table m-0">
                                    <tbody v-if='pedidosFiltrados.length'>
                                        <tr v-for="(pos, index) in pedidosFiltrados" :key="index">
                                            <td style="width:30px">
                                                <b-checkbox @change="updateSelectedItem()" v-model="pos.check" size="lg">
                                                </b-checkbox>
                                            </td>
                                            <td>
                                                {{ pos.novaordem }} &nbsp;
                                                {{ pos.irnome }}
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <statusInformation :statusBar='"error"' />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-8">
                        <h5>Rotas disponíveis</h5>
                        <div style="max-height:280px;overflow-y:auto;">
                            <table class="table">
                                <thead>
                                    <th style="width:30px"></th>
                                    <th>Código</th>
                                    <th>Saída</th>
                                    <th>Retorno</th>
                                    <th>Data</th>
                                    <th>Veiculo</th>
                                    <th>Status</th>
                                </thead>
                                <tbody v-if='rotas.length'>
                                    <tr v-for="(pos, index) in rotas" :key="index">
                                        <td>
                                            <b-checkbox @change="updateSelectedRota(index)"
                                                :checked="selectedRota === index" size="lg">
                                            </b-checkbox>
                                        </td>
                                        <td v-text='pos.codigo' />
                                        <td v-text='pos.saida' />
                                        <td v-text='pos.retorno' />
                                        <td v-text='pos.data' />
                                        <td v-text='pos.veiculo' />
                                        <td :title="defineTitleStatus(pos.status)">
                                            <b-icon :class="`color-${pos.status}`" icon="circle-fill" scale="1.2">
                                            </b-icon>
                                            {{ pos.status }}
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <statusInformation :statusBar='"error"' />
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </panelEagle>
        </slot>
        <slot name="modal-footer">
            <simpleButton :disabled="loadingPanel" :icon="mdiCloseThick" @click="cancelButton" text="Cancelar" event="click"
                type="red">
            </simpleButton>
            <simpleButton :disabled="!podeSalvar || loadingPanel" :icon="mdiCheckBold" @click="salvarRota" text="Confirmar"
                event="click" type="blue">
            </simpleButton>
        </slot>
    </modalEagle>
</template>

<script lang="js">

import Vue from 'vue'
import { mdiCloseThick, mdiCheckBold } from '@mdi/js'
import { HttpRequest } from '../../../Services/auth/HttpRequest.Service'

export default Vue.extend({
    name: 'ModalAddItensFimRota',

    props: {
        rota: {
            type: [Array, Object],
            required: true,
            default: () => { return [] }
        },
        selecionados: {
            type: [Array, Object],
            required: true,
        },
        id: {
            type: String,
            default: "modal-add-itens-fim-rota"
        }
    },

    components: {
        'modalEagle': require('../Modal/ModalEagle').default,
        'panelEagle': require('@/components/Atom/Panel/PanelEagle.vue').default,
        'simpleButton': require('@/components/Atom/Buttons/SimpleButton.vue').default,
        inputRangeWithLimit: require(
            '@/components/Atom/Datas/InputRangeWithLimit').default,
        statusInformation: require(
            "@/components/Atom/StatusInformation/StatusInformation").default,
    },

    data() {
        return {
            mdiCheckBold: mdiCheckBold,
            mdiCloseThick: mdiCloseThick,
            loadingPanel: false,
            clienteCodigo: this.rota.empresa,
            isLoading: false,
            editado: false,
            data: '',
            rotas: [],
            selectedRota: null,
            pedidosFiltrados: [],
        }
    },

    computed: {
        pedidos() {
            if (!this.selecionados || !this.selecionados.length) {
                return [];
            }
            const array = this.selecionados.map((e) => ({
                ...e, check: true, novaordem: e.irordem
            }));
            return array;
        },

        totalRota() {
            // if(!this.rotas || !this.rotas.length){
            if (this.selectedRota == null) {
                return 0
            }
            return this.rotas[this.selectedRota].total
        },

        podeSalvar() {
            return this.selectedRota != null && this.pedidos.some(e => e.check)
        },
    },

    watch: {
        pedidos(value) {
            this.pedidosFiltrados = value
        },
    },

    methods: {

        defineTitleStatus(status) {
            if (status == 'P') return 'Pendente'
            if (status == 'F') return 'Finalizada'
            if (status == 'I') return 'Iniciada'
            return ''
        },

        updateSelectedRota(index) {
            if (this.selectedRota == index) {
                this.selectedRota = null
            } else {
                this.selectedRota = index
            }
            this.updateSelectedItem()
        },

        updateSelectedItem() {
            let count = 1
            this.pedidosFiltrados.forEach(e => {
                if (e.check) {
                    e.novaordem = count + this.totalRota
                    count++
                } else {
                    e.novaordem = '-'
                }
            })
        },

        /**
         * @listens changeInput
         * @param {String} value
         */
        changeData(value) {
            this.data = value
            this.getRotas()
        },

        /**
         * @description Função para fechar o modal
         * @listens click
         * @author Rafael
         */
        cancelButton() {
            this.$bvModal.hide(this.id)
        },

        /**
         * @description Limpa todas as variáveis do componente, exceto [clienteCodigo]
         * @listens modalClose
         * @author Rafael
         */
        modalClose() {
            this.resetDados()
        },

        /**
         * @description Limpa todas as variáveis do componente, exceto [clienteCodigo]
         * @author Rafael
         */
        resetDados() {
            // this.resetForm()
            this.loadingPanel = false
            this.isLoading = false
        },

        /**
         * @description Método para mostrar o toast na tela
         * @param {string} msg - mensagem que vai aparecer no corpo do toast
         * @param {string} type - qual o tipo do toast 
         * @param {string} title - título para inserir no cabeçalho
         * @author Rafael
        */
        toastShow(msg, type, title = 'Ops!') {
            this.$bvToast.toast(`${msg}`, {
                autoHideDelay: 2500,
                variant: type,
                title: title
            })
        },

        /**
         * @description Busca as rotas pendentes e iniciadas do cliente dentro do 
         * intervalo selecionado.
         * @author Rafael
         */
        async getRotas() {
            try {
                this.loadingPanel = true;
                const url = '/roteirizador/rota/manual/rota/buscar';
                const params = { intervalo: this.data, cliente: this.rota.empresa };
                this.selectedRota = null;
                const { data, status } = await new HttpRequest().Post(url, params);
                if (status && data) {
                    this.rotas = data.rotas.map((e) => {
                        e.check = true;
                        return e;
                    });
                }
            } catch (error) {
                // handle error
            } finally {
                this.loadingPanel = false;
            }
        },

        /**
         * @description Salva os novos itens da rota na tabela itens_rotas.
         * @author Rafael
         */
        async salvarRota() {
            this.loadingPanel = true;
            const url = '/roteirizador/rota/manual/rota/itens/novos/salvar';
            const itens = this.pedidosFiltrados.filter((e) => e.check).map(e => {
                return { ircodigo: e.ircodigo }
            });
            const params = { rota: this.rotas[this.selectedRota], itens };
            try {
                const { data, status } = await new HttpRequest().Post(url, params);
                if (status && data) {
                    this.$emit('rotaAlterada', itens)
                    // do something
                }
            } catch (error) {
                // handle error
            } finally {
                this.loadingPanel = false;
            }
        },

    },

})
</script>

<style lang="scss" scoped>
#panelAddItemRotaParada {
    .color-P {
        color: #F0AD4E;
    }

    .color-F {
        color: #1CAF9A;
    }

    .color-C {
        color: #E04B4A;
    }

    .color-I {
        color: #428BCA;
    }
}
</style>