<!-- 
    * dados:
    NSR - Valor sequencial de registro (clmseq)
    Tipo Registro - 5 (Valor fixo)
    Data e hora - data e hora da gravação (clmdatahora)
    Tipo operação - I para inclusão | A para alteração | E para exclusão
    CPF - Valor CPF do empregado (mtcpf)
    Nome - Valor Nome do empregado (mtnome)
    PIS - Valor Pis do empregado (mtpis)
    CPF - valor CPF de quem fez a alteração (clmcpfcadastro)
    CRC-16 - Valor verificação cíclica de redundância (será feito futuramente)
 -->
 <template>
    <div class="mt-4 col-12 nopadding">
        <div class="col-sm-12">
            <span class="newbadge"> {{dados.descricao}} </span>
        </div>
        <tableListagem
            :data='dados.itens'
            :titles='titles'
            :filter='false'
            :acoes="false"
            :pageSizes="[5, 10, 25, 50, 100]"
            :layout="defineLayout">
        </tableListagem>
    </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
    name:'registroTipo2',
    components:{
		tableListagem: require('@/components/Atom/Table/TableListagem').default,
    },
    props:{
        dados:{
            type: Object,
            required: true,
            default: function(){return {}}
        },
    },
    data() {
        return {
			titles:[
				{name:'NSR', number: 'nsr', sortable: false},
				{name:'Tipo reg.', number: 'tiporegistro', sortable: false},
				{name:'Data / hora gravação', number:'datahoragravacao', sortable: false},
				{name:'Tipo operação', number: 'tipooperacao', sortable: false},
				{name:'CPF', number: 'cpf', sortable: false},
				{name:'Nome', number: 'nome', sortable: false},
				{name:'PIS', number: 'pis', sortable: false},
				{name:'CPF cadastro', number: 'cpfcadastro', sortable: false},
				{name:'CRC-16', number: 'crc16', sortable: false},
			],
        }
    },
    computed: {
        defineLayout(){
            let layout = 'tool, table'
            if(this.dados && this.dados.itens && this.dados.itens.length > 5){
                layout += ', pagination'
            }
            return layout
        }
    }
})
</script>
    
<style lang="scss">
    
</style>