<template>
  <panelEagle id='CadastroDeChip' :loading='carregando'>
    <div class="col-lg-12 row nopadding">
      <div class="col-lg-6 ">
        <titulo 
        :icon='icone'
        titulo='Chips'/>
      </div>
      <div class="col-lg-6 ">
        <botoes 
          :disabled='$v.$invalid'
          @salvarCadastro='salvar'
          tipoCancela='button'
          linkCancel='listarChips'/>
      </div>
    </div>
    <div class="col-sm-12"><hr></div>
      <div class="col-lg-12 nopadding row">
        <div class="col-lg-12 nopadding left">
          <div class="col-lg-3 left nopadding ">
            <filtros 
              label='Status'
              :arrayButtons='optFiltroAtivo'
              @buttonsFiltersChange='mudaStatus'/>
          </div>
        </div>
        <div class="col-lg-3 left nopadding mt-3">
          <inputS 
            label='ICCID*'
            v-model='formulario.iccid'
            :hasMask='true'
            :mask="['NNNNN NNNNN NNNNN NNNNN']"
            :inputClass='{"boder border-danger" : $v.formulario.iccid.$anyError }'
            @blur='$v.formulario.iccid.$touch()'
            @changeInput='v=>formulario.iccid = v'/>
        </div>
        <div class="col-lg-3 nopadding  mt-3">
          <inputS 
            :hasMask='true'
            :mask="['(NN) NNNNN-NNNN']"
            label='Número*'
            :inputClass='{"boder border-danger" : $v.formulario.chnumero.$anyError }'
            v-model='formulario.chnumero'
            @blur='$v.formulario.chnumero.$touch()'
            @changeInput='v=>formulario.chnumero = v'
          />
        </div>
        <div class="col-lg-3 nopadding  mt-3">
            <selectAll
              nameForRadio='seletorOperadora'
              :isMultiple='false'
              :labels='selectAllLabel'
              :selected='operadora'
              :allowEmpty='true'
              :optionsArray='optOperadora'
              :extraClass='{ "border border-danger": $v.formulario.choperadora.$anyError }'
              @close='$v.formulario.choperadora.$touch()'
              @changeSelect='v=>formulario.choperadora = v[0]'
            />
        </div>
        <div class="col-lg-3 col-md-0  mt-3"></div>
        <div class="col-lg-3 nopadding  mt-3">
          <inputS
            label='Franquia MB'
            type='number'
            :value='formulario.chfranquiamb'
            @changeInput='v=>formulario.chfranquiamb = v'/>
        </div>
        <div class="col-lg-3 nopadding  mt-3">
          <inputS
            label='Franquia SMS'
            type='number'
            :value='formulario.chfranquiasms'
            @changeInput='v=>formulario.chfranquiasms = v'/>
        </div>
        <div class="col-lg-3 nopadding  mt-3">
          <inputM
            name='inputCusto'
            label='Custo R$'
            :value='valueCusto'
            @changeInput='v=>formulario.chcusto = v'/>
        </div>
      </div>
    <required/>
    <div class="col-sm-12"><hr></div>
  </panelEagle>
</template>
<script lang="js">
import { required, minLength } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import {mdiSim } from '@mdi/js'
import Vue from 'vue'
import Swal from 'sweetalert2'
import {conectionError} from '@/Services/Helpers/swellHeper.js'
export default Vue.extend({
	name:'cadastroChips',
	components:{
		'selectAll'  : require('@/components/Atom/Select/SelectAll').default,
		'inputS'     : require('@/components/Atom/Inputs/InputSimple').default,
		'panelEagle' : require('@/components/Atom/Panel/PanelEagle').default,
		'titulo'     : require('@/components/Atom/Header/Titulo').default,
		'botoes'     : require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
		'filtros'    : require('@/components/Atom/Buttons/ButtonsFilters').default,
		'required'   : require('@/components/Atom/Footer/RequiredFields').default,
		'inputM'     : require('@/components/Atom/Inputs/InputMoney').default,
	},
	validations:{
		formulario:{
			iccid: {required},
			chnumero: { 
				required,
				minLength:minLength(15) 
			},
			choperadora: { required }
		}
	},
	data(){
		return {
			carregando: false,
			icone: mdiSim,
			salvandoCadastro: false,
			ehEdicao : false,
			formulario : {
				chstatus      : '',
				chnumero      : '',
				choperadora   : '',
				iccid         : '',
				chfranquiamb  : '',
				chfranquiasms : '',
				chcusto       : '',
			},
			// Filtro
			optOperadora   : [],
			optFiltroAtivo : [
				{text: 'Ativo',   value:'A', width:'48%', isSelected: true},
				{text: 'Inativo', value:'I', width:'48%', isSelected: false}
			],
			selectAllLabel :[{   
				indexDFH:'NA', 
				description:'Operadora*'
			}],
			valueCusto:'',
			operadora:[],
			link: '/administrativo/cadastros/chips/',
		}
	},
	methods:{
		getOperadoras(){
			var rota = 'getoperadoras'
			new HttpRequest().Get(this.link+rota)
				.then((data)=>{
					if(data.status)
						this.optOperadora = data.data.operadoras
				})
		},

		salvar(){
			this.carregando = true
			var ehEdicao = this.$route.params.id? true:false
			var rota = ehEdicao? 'atualizar':'cadastrar';
			var obj  = this.criaObj();
			new HttpRequest().Post(this.link+rota, obj)
			.then((data)=>{
				if(data.code == 201){
					this.$router.push({name:'listarChips'});
					this.carregando= false
				}else if(data.code == 200){
					this.carregando = false
					conectionError(
						'O chip '+this.formulario.chnumero+' já está cadastrado.',
						'warning')
				}else{
					conectionError()
					this.carregando = false
				}
			}).catch((err)=>{
				conectionError()
			})
			
		},

		criaObj(){
			var obj;
			if(this.$route.params.id){
				return {'formulario' : this.formulario, 'codigo':this.$route.params.id}
			}
			return {'formulario' : this.formulario}
		},

		arrumaStatus(status){
			if(status == 'I'){
				this.optFiltroAtivo[0]['isSelected'] = false
				this.optFiltroAtivo[1]['isSelected'] = true
			}
		},

		preparaCampos(info){
			Object.keys(this.formulario).forEach(key => {
				this.formulario[key] = info[key]
			});
			this.valueCusto = info.chcusto
			this.arrumaStatus(info.chstatus);
		},

		mudaStatus(value){
			this.formulario.chstatus = value[0]
		},

		getChip(id){
			this.carregando = true
			var rota = 'getchip'
			new HttpRequest().Post(this.link+rota, {'codigo':id}).then((data)=>{
				this.preparaCampos(data.data.chip);
				this.operadora = [data.data.operadora]
				this.carregando = false
			})
		}
	},

	mounted(){
		this.getOperadoras();
		if(this.$route.params.id){
			this.ehEdicao = true
			this.getChip(this.$route.params.id);
		}
	}
})
</script>
<style scoped>
.left{
  text-align: left !important;
}
</style>