Mapa feito de maneira mais simples para poder ser utlizado
de maneira mais genérica

Props: 
    height: Altura do mapa
    latitude, longitude : coordenadas iniciais do mapa
    initialZoom : Zoom inicial do mapa
    showZoom: decide se mostra os controles de Zoom do mapaSimples
    bounds: precisa ser uma array de arrays [[lat, long],[lat, long]],
	o mapa tenta cobrir o objeto inteiro
    zoomDelta: quanto de zoom in e out o mapa faz por clique ou mexida do mouse
    dragging: Se o mapa pode ser arrastado com o mouse 

Slots: 
  Um slot sem nome, componentes que precisam saber sua localização dentro
  do mapa devem ficar diretamente dentro desse slot. 
  Para usar mais de um componente dentro desse slot (ex: marcador + circulo
  simples) todos devem ser "filhos" do mapa.

Eventos: 
  O componente em si não possui nenhum evento

Data: 
  tileProviders: Essa é a array com todos os tiles de mapas que são
  oferecidos pelo sistema, 

Componentes : 
  lmap : O mapa em si.
  lcontrolLayers : Exibe os controles do mapa (No caso, +- zoom)
  lTileLayer : Componente que exibe e troca o tile do mapa de maneira dinamica

Documentação: 
    ---leaflet https://leafletjs.com/
    ---measure  https://www.npmjs.com/package/leaflet-measure-path
    ---editable https://www.npmjs.com/package/leaflet-editable 
    https://leaflet.github.io/Leaflet.Editable/
    ---drag https://github.com/Leaflet/Path.Drag.js
    ---draw https://leaflet.github.io/Leaflet.draw/docs/leaflet-draw-latest.html#l-draw
    ---easyPrint https://github.com/rowanwins/leaflet-easyPrint

//Living a life of low expectation
<template>
    <div id="mapaSimples" class="mapaSimples">
        <l-map
            v-bind="$attrs"
            v-on="$listeners"
            ref="mapaSimples"
            :worldCopyJump="true"
            :style="defineEstiloMapa()"
            :zoom="zoom"
            @update:zoom="zoomUpdated"
            @click="clicaMapa"
            :center="center"
            :options="{
                scrollWheelZoom: scrollWheelZoom,
                zoomControl: false,
                dragging: this.dragging,
                editable: true,
                trackResize: true,
            }"
        >
            <l-control-layers
                v-if="showZoom"
                :position="posicaoControles"
                :collapsed="true"
            />
            <l-tile-layer
                :key="tileProvider.name"
                v-for="tileProvider in tileProviders"
                v-bind="tileProvider"
                :options="{
                    maxZoom: tileProvider.maxZoom,
                }"
                layer-type="base"
            />
            <!-- Não mexa no lance do zoom se não o Gui vai chorar,
				assinado Gui 🍺 -->
            <l-control-zoom :position="posicaoControles" v-if="showZoom" />
            <slot></slot>
        </l-map>
    </div>
</template>

<script>
import Vue from "vue";
import { LMap, LTileLayer, LControlZoom, LControlLayers } from "vue2-leaflet";
// import easyPrint from 'leaflet-easyprint'
import "leaflet-editable";
import L from "leaflet";
export default Vue.extend({
    components: {
        LControlLayers,
        LControlZoom,
        LMap,
        LTileLayer,
    },
    inheritAttrs: false,
    props: {
        height: {
            type: [Number, String, Boolean],
            default: 650,
        },
        latitude: {
            type: Number,
            default: -27.1001389,
        },
        longitude: {
            type: Number,
            default: -52.6270369,
        },
        initialZoom: {
            type: [Number, String],
            default: 13,
        },
        dragging: {
            type: Boolean,
            default: true,
        },
        bounds: {
            type: Array,
            required: false,
        },
        showZoom: {
            type: Boolean,
            default: true,
        },
        posicaoControles: {
            type: String,
            require: false,
            default: "bottomright",
        },
        scrollWheelZoom: {
            type: Boolean,
            default: true,
        },
        newHeight: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            zoom: 13,
            /**Pra evitar bugs futuros:
             * O atributo visible não se é possível ver/clicar na layer, mas
             * sim se a layer carrega de cara, então, se todas forem true
             * fica com aquele bug de mostrar várias layers a medida que da
             * zoom in/out
             */
            tileProviders: [
                {
                    name: "Detalhado",
                    visible: false,
                    attribution:
                        '&copy; <a target="_blank" href="https://osm.org/copyright">OpenStreetMap</a> contributors',
                    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                    maxZoom: 18,
                },
                {
                    name: "Satélite",
                    visible: false,
                    attribution:
                        '&copy;<a href="https://maps.google.com">Google Maps</a>',
                    url: "https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
                    subdomains: ["mt0", "mt1", "mt2", "mt3"],
                    maxZoom: 21,
                },
                {
                    name: "Padrão",
                    visible: true,
                    attribution:
                        '&copy;<a href="https://maps.google.com">Google Maps</a>',
                    url: "https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
                    subdomains: ["mt0", "mt1", "mt2", "mt3"],
                    maxZoom: 22,
                },
                // {
                // 	name: 'Metal',
                // 	visible: false,
                // 	attribution:'&copy;<a href="http://maps.google.com">Google Maps</a>',
                // 	url:'https://tile.thunderforest.com/spinal-map/{z}/{x}/{y}.png?apikey=a65b7f338a3445f782b76e4562863e8e',
                // },
            ],
            printPlugin: "",
        };
    },
    methods: {
        /**
         * @todo alterar todos os lugares do mapa p/ sempre ficar dessa maneira
         * o uso do atributo de tamanho e poder remover a maneira como eu
         * habia feito em um primeiro momento.
         */
        defineEstiloMapa() {
            if (this.newHeight) {
                let retorno = `height: ${this.height}; z-index:0; width:100%`;
                return retorno;
            } else if (typeof this.height !== "boolean")
                return `height: ${this.height}px; z-index:0; width:100%`;
            else {
                return `height: 100vh; z-index:0; width:100%`;
            }
        },
        /**
         * @description tenta localizar o usuário usando uma
         * API de geolocalização.
         * @param {object}  options          - opções do locale
         * @param {boolean} options.watch    - escuta por alterações
         * na localização do usuário.
         * @param {boolean} options.setView  - Se true, altera a view do mapa
         * para a localização do usuário.
         * @param {number}  options.maxZoom  - o máximo de zoom automático
         * quando usando setView
         * @param {number}  options.timeout  - tempo para esperar a
         * resposta da API
         * @param {number}  options.maximumAge
         * @param {boolean} options.enableHighAccuracy
         */
        locate(options) {
            this.$refs.mapaSimples.mapObject.locate(options);
        },

        /**
         * @param {L.Layer} layer - qualquer layer do leaflet
         * @return {boolean} se a layer passada por paramêtro esta no mapa.
         */
        hasLayer(layer) {
            return this.$refs.mapaSimples.mapObject.hasLayer(layer);
        },

        /**
         * @param {L.Layer} layer - qualquer layer do leaflet
         * @description remove a layer passada como argumento do mapa.
         */
        removeLayer(layer) {
            this.$refs.mapaSimples.mapObject.removeLayer(layer);
        },

        /**
         * @listen click - mapa
         * @fires click-mapa
         * @param {object} evento - emitido pelo mapa
         */
        clicaMapa(evento) {
            this.$emit("click-mapa", evento);
        },

        /**
         * @description fecha popup anônima no mapa
         */
        closePopup() {
            this.$refs.mapaSimples.mapObject.closePopup();
        },

        /**
         * @return {L.Map} mapObject
         */
        returnMapObject() {
            return this.$refs.mapaSimples.mapObject;
        },

        /**
         * @param {number} nZoom - nível de zoom do mapa.
         * @description muda o zoom do mapa.
         */
        zoomUpdated(nZoom) {
            this.zoom = nZoom;
            this.$emit("update-zoom", this.zoom);
        },

        /**
         * @description Atalho para o método de fitBounds do Leaflet.
         * @param { Array }latLng latitude, longitude que devem "caber"
         * no mapa.
         * @author Gui 🍺
         */
        fitBounds(latLng) {
            if (latLng) this.$refs.mapaSimples.mapObject.fitBounds(latLng);
        },

        /**
         * @description Função do próprio leaflet mas estou implementando
         * essa interface para facilitar a vida
         * @param {(array|object)} latlng -  [lat, lng]
         * de onde o mapa deve dar zoom
         * @param {number} [zoom=15] - o nivel de zoom do mapa
         * @author Gui 🍺
         */
        flyTo(latlng, zoom = 15, option = null) {
            try {
                if (option) {
                    this.$refs.mapaSimples.mapObject.flyTo(latlng, zoom, option);
                } else {
                    this.$refs.mapaSimples.mapObject.flyTo(latlng, zoom);
                }
            } catch (e) {
                ("");
            }
        },

        /**
         * @param {L.bounds} bounds - onde ir
         * @param {L.FitBoundsOptions} - animações e etc
         * @description parecido com flyTo, mas em vez de passar um ponto
         * passa uma região.
         */
        flyToBounds(bounds, opt = {}) {
            this.$refs.mapaSimples.mapObject.flyToBounds(bounds, opt);
        },

        /**
         * @description Quando a janela muda de tamanho, o mapa não sabe como
         * renderizar, causa o bug em que só uma parte pequena
         * renderiza enquanto o resto do mapa é inutilizavel
         * Essa função faz com que o mapa valide o próprio tamanho
         * com um timer porque pareceu functionar melhor assim.
         * @author Gui 🍺
         * @todo alterar o setTimeout por nextTick e textar se funciona
         */
        validateSize() {
            setTimeout(() => {
                this.$refs.mapaSimples.mapObject.invalidateSize();
            }, 400);
        },

        /**
         * @description Antes do mapa ser destruido, essa função remove
         * todas as layers que estão ativas nele, cerve para previnir o
         * erro que disparava no freeDraw ao
         * trocar de tela. 🦕
         * @author Gui 🍺
         */
        removeAllLayers() {
            this.$refs.mapaSimples.mapObject.eachLayer((l) => {
                this.$refs.mapaSimples.mapObject.removeLayer(l);
            });
        },
    },
    computed: {
        center() {
            return L.latLng(this.latitude, this.longitude);
        },
    },

    /**
     * @description O EventListener automatiza o processo de chamar a função de
     * validar o tamanho do mapa, sendo assim não é necessário ficar
     * chamando a função fora do componente.
     * @author Gui 🍺🍺
     */
    mounted() {
        this.zoom = Number(this.initialZoom);
        document
            .getElementById("mapaSimples")
            .addEventListener("resize", this.validateSize);
        this.fitBounds(this.bounds);
    },

    beforeDestroy() {
        this.removeAllLayers();
    },
});
</script>

<style lang="scss">
#mapaSimples {
    // height: 100vh;
    .fonte {
        color: black !important;
    }
    .mapa {
        width: 100%;
        height: 100%;
    }
    div.leaflet-bottom {
        text-align: left !important;
        input {
            width: 30px;
        }
    }
    .easyPrintHolder .a3CssClass {
        background-image: url(data:image/svg+xml;utf8;base64,PD9...go=);
    }
}
</style>
