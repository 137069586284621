<template>
	<panelEagle id='listagemUsuariosVue' :loading='gerandoRel'>
		<div class='col-sm-12 nopadding '>
			<div class='col-sm-12 nopadding row'>
				<div class='col-sm-6 row'>
					<tituloPage titulo='Usuários' :icon='icone'></tituloPage>
				</div>
				<div class='col-sm-6 divDontPrint'>
					<botoesEIN :disabledDropdown="!Object.keys(usuarios).length" :loading='loadingButton' linkNew='cadastrarUsuario'
						tipoNovo='button' @exportarCadastro='exportaUsuarios'>
					</botoesEIN>
				</div>
				<div class='col-sm-12'>
					<hr>
				</div>
				<div class='col-sm-12 nopadding row'>
					<div class='col-sm-4 nopadding divDontPrint'>
						<selectAll :labels='this.labels' nameForRadio='FiltroEmpresas' :isMultiple='true'
							:optionsArray='optSelectEmpresas' :selected='firstOne' :hasSelectAll='true' @changeSelect='mudaSelecao' />
					</div>
					<div class='col-sm-5 nopadding divDontPrint'>
						<buttonsFilters label='Status' :arrayButtons='this.botoes' @buttonsFiltersChange='filtraAtivo' />
					</div>
					<div class='col-sm-3 divDontPrint nopadding'>
						<simpleAlert v-if='usuariosPendentes' type='red' :isLoading='false' :message='errorMessage' :hasClick='false'
							class='error' />
					</div>
				</div>
			</div>
		</div>

		<div class="col-sm-12">
			<hr>
		</div>

		<div class='col-12 nopadding'>
			<tableListagem :data='usuarios' :titles='titulosTable' :habilitado='true'>
				<template #acoes='{ row }'>
					<simpleButton v-bind='decideBotaoEmail(row)' @click='confirmaEmail(row)' />
					<simpleButton v-if='getEditar()' v-bind='preparaBotaoDeStatus(row)'
						@click='mudaStatus(row.codigo, row.status, row.usucliente)' />
					<simpleButton v-if='getEditar()' v-bind="{
						icon: iconEditar,
						type: 'green',
						title: 'Editar Usuário',
						width: '30%',
						event: 'click'
					}" @click='editarUser(row.codigo)' />
				</template>
			</tableListagem>
		</div>

		<modalEagle :hardToClose="true" id='confirmaEmail' title='Reenvio de email'>
			<template #modalBody>
				<div class="col-12 nopadding">
					<p>{{ `Confira se o email ${userEmail.email} está correto!
						Para reenviá-lo, confirme o email no campo abaixo:`}}</p>
					<input :disabled="loadingButtonEmail" class='form-control' v-model='valueEmailEdicao'>
				</div>
			</template>
			<template #modalFooter>
				<div class="col-12 row nopadding">
					<loadingButton :isLoading="loadingButtonEmail"
						:disabled='(userEmail.email != valueEmailEdicao) || loadingButtonEmail' text='Reenviar' type='blue'
						:icon='iconAtivar' event='click' @click='enviarConfirmacao(userEmail)' />
					<simpleButton :disabled="loadingButtonEmail" text='Editar' type='green' :icon='iconEditar' event='click'
						@click='editarUser(userEmail.codigo)' />
					<simpleButton :disabled="loadingButtonEmail" text='Cancelar' :icon='iconCancel' type='red' event='click'
						@click='fecharModal' />
				</div>
			</template>
		</modalEagle>

	</panelEagle>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { EmpresasService } from '../../../../Services/auth/Empresas.service'
import { HttpRequest } from '../../../../Services/auth/HttpRequest.Service';
import {
	mdiAccount, mdiCheck, mdiPencilOutline, mdiCircleOffOutline,
	mdiEmail, mdiThumbUp, mdiCancel, mdiCheckBold
} from '@mdi/js'
import { conectionError } from '@/Services/Helpers/swellHeper';
export default {
	name: 'listagemUsuarios',
	components: {
		loadingButton: require('@/components/Atom/Buttons/LoadingButton').default,
		'simpleButton': require('@/components/Atom/Buttons/SimpleButton').default,
		'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
		'tituloPage': require('@/components/Atom/Header/Titulo').default,
		'botoesEIN': require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
		'selectAll': require('@/components/Atom/Select/SelectAll').default,
		'buttonsFilters': require('@/components/Atom/Buttons/ButtonsFilters').default,
		'tableListagem': require('@/components/Atom/Table/TableListagem').default,
		'simpleAlert': require('@/components/Atom/Alerts/AlertSimple').default,
		'modalEagle': require('@/components/Atom/Modal/ModalEagle').default,
	},
	data() {
		return {
			loadingButton: [false, false, false],
			icon: '',
			iconCancel: mdiCancel,
			firstOne: [],
			iconEditar: mdiPencilOutline,
			iconDesativar: mdiCircleOffOutline,
			iconValidar: mdiEmail,
			iconValido: mdiThumbUp,
			iconAtivar: mdiCheck,
			mdiCheckBold: mdiCheckBold,
			icone: mdiAccount,
			linkNew: '#/administrativo/cadastros/usuarios/sistema/cadastrar',
			usuariosPendentes: false,
			statusFiltro: [],
			usuarios: [],
			empresasFiltro: [],
			optSelectEmpresas: new EmpresasService().Get(),
			labels: [{ indexDFH: 'EM', description: 'Empresas' }],
			botoes: [
				{ 'value': 'S', 'width': '32%', 'text': 'Ativo', 'isSelected': true, 'icon': '' },
				{ 'value': 'N', 'width': '32%', 'text': 'Inativo', 'isSelected': false, 'icon': '' },
				{ 'value': 'T', 'width': '32%', 'text': 'Todos', 'isSelected': false, 'icon': '' },
			],
			titulosTable: [
				{ 'name': 'Login', 'number': 'name' },
				{ 'name': 'Email', 'number': 'email' },
				{ 'name': 'Empresa', 'number': 'clfantasia' },
			],
			urlBase: '/administrativo/cadastros/usuarios/',
			baseUri: "/administrativo/cadastros/clientes/",
			userEmail: {},
			errorMessage: 'Há usuários pendentes, verifique email para validação.',
			valueEmailEdicao: '',
			gerandoRel: false,
			loadingButtonEmail: false,
		}
	},
	methods: {
		...mapActions(['refresh']),
		...mapGetters(['getEditar']),
		...mapGetters(['getMaster']),

		fecharModal() {
			this.$bvModal.hide('confirmaEmail')
			this.valueEmailEdicao = ''
			this.userEmail = {}
		},

		async enviarConfirmacao(user) {
			let rota = this.urlBase + 'confirmaemail'
			this.loadingButtonEmail = true
			try {
				var retorno = await new HttpRequest().Post(rota, { codigo: user.codigo })
				if (retorno.data.result) {
					this.fecharModal()
				} else {
					conectionError()
				}
			} catch {
				conectionError()
			}
			this.loadingButtonEmail = false
		},

		decideBotaoEmail(row) {
			if (row.usvalidacao) {
				return {
					type: 'light-green',
					icon: this.iconValido,
					title: 'Usuário validado com sucesso!',
					width: '30%'
				}
			}
			return {
				type: 'yellow',
				//placeholder icon, não achei o certo
				icon: this.iconValidar,
				width: '30%',
				event: 'click',
				title: 'Verifique a caixa de email do endereço cadastrado ou clique para reenviar'
			}
		},

		mudaStatus(cod, status, codEmpresa) {
			let obj = {
				codigo: cod,
				status: status == 'N' ? 'S' : 'N'
			}
			let uriConsultaUsuariosEmpresa = this.baseUri + 'empresas';
			
			if (obj.status == 'S') {
				new HttpRequest().Post(uriConsultaUsuariosEmpresa, { codigo: codEmpresa })
					.then(dataEmpresa => {
						this.empresasSelecionadasExedeLimiteUsuario = [];
						let empresa = dataEmpresa.data[0].original.cliente;
						let usuariosAtivos = 0;

						if (empresa.clusuarioscontratados > 0) {
							for (var usuario in dataEmpresa.data[1]) {
								if (dataEmpresa.data[1][usuario].usuativo == 'S') {
									usuariosAtivos++;
								}
							}

							if (usuariosAtivos >= empresa.clusuarioscontratados) {
								let msg = 'Quantidade de usuários excedeu o limite do contrato!'
								conectionError(msg, 'warning');
							} else {
								let rota = this.urlBase + 'status'
								new HttpRequest().Post(rota, obj)
									.then((data) => {
										this.listagem();
									})
							}
						} else {
							let rota = this.urlBase + 'status'
							new HttpRequest().Post(rota, obj)
								.then((data) => {
									this.listagem()
								})
						}
					})
			} else {
				let rota = this.urlBase + 'status'
				new HttpRequest().Post(rota, obj)
					.then((data) => {
						this.listagem()
					})
			}
		},

		/**
				 * Preapra os binds do botão de status
				 * variando de acordo com a necessidade
				 */
		preparaBotaoDeStatus(row) {
			if (row.status == 'N') {
				return {
					type: 'green',
					icon: this.iconAtivar,
					title: 'Ativar Usuario',
					width: '30%',
					event: 'click'
				}
			}
			return {
				event: 'click',
				type: 'red',
				width: '30%',
				icon: this.iconDesativar,
				title: 'Desativar Usuario'
			}
		},

		editarUser(codigo) {
			this.$router.push({ name: 'cadastrarUsuario', params: { id: codigo } })
		},

		filtraAtivo(values) {
			this.botoes[0].icon = ''
			this.botoes[1].icon = ''
			this.botoes[2].icon = ''
			if (values[0] == 'S') {
				this.botoes[0].icon = mdiCheckBold
			} else if (values[0] == 'N') {
				this.botoes[1].icon = mdiCheckBold
			} else if (values[0] == 'T') {
				this.botoes[2].icon = mdiCheckBold
			}
			if (values[0] == 'T') values = ['S', 'N']
			this.statusFiltro = values
			this.listagem();
		},

		mudaSelecao(selecao) {
			this.empresasFiltro = selecao;
			this.listagem();
		},

		async listagem() {
			let uri = this.urlBase + 'listagem'
			if (this.empresasFiltro.length > 0) {
				this.gerandoRel = true
				var obj = {
					usuarios: this.empresasFiltro,
					status: this.statusFiltro
				}
				new HttpRequest()
					.Post(uri, obj).then((data) => {
						this.usuarios = data.data.usuarios
						this.usuariosPendentes = data.data.invalid
						this.gerandoRel = false
					})
			} else {
				this.usuarios = [];
				this.usuariosPendentes = false;
			}
		},

		async exportaUsuarios(f) {
			switch (f) {
				case 'pdf':
					this.loadingButton = [true, false, false]
					break;
				case 'xls':
					this.loadingButton = [false, true, false]
					break;
				default:
					this.loadingButton = [false, false, true]
					break;
			}
			let obj = {
				dados: this.usuarios,
				para: f
			}
			let uri = this.urlBase + 'exportar'
			new HttpRequest().Post(uri, obj)
				.then((dados) => {
					this.loadingButton = [false, false, false]
					var root = process.env.VUE_APP_ROOT
					window.open(root + '/' + dados.data.local)
				})
		},

		confirmaEmail(row) {
			this.userEmail = row
			this.$bvModal.show('confirmaEmail')
		},

		selectAllClientes() {
			if (this.getMaster())
				this.firstOne = []
			else
				this.firstOne = new EmpresasService().Get()
		},

	},
	mounted() {
		this.selectAllClientes()
	}
}
</script>

<style lang='scss'>
.error {
	padding: 25px;
}
</style>