<template>
    <panelEagle id="cadVisitasView" :loading="loadingPanel">
        <div class="col-sm-12 nopadding ">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiHomeFlood" titulo="Visitas"></tituloPage>
                </div>
                <div class="col-sm-6 divDontPrint">
                    <basicButtonsCadastrosEIN
                        linkNew           ="editarVisitas"
                        :loading          ="carregandoE"
                        tipoNovo          ="button"
                        @exportarCadastro ="exportar" 
                        @novoCadastro     ="novoCadastro">
                    </basicButtonsCadastrosEIN>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-4 nopadding divDontPrint">
                    <selectAll 
                        @changeSelect="changeEmpresa" 
                        :isMultiple="true" 
                        nameForRadio="empresasCadVisitas" 
                        :hasSelectAll="true" 
                        :labels="[{indexDFH: 'EM', description: 'Empresas*'}]" 
                        :optionsArray="optionsArrayEmpresas" 
                        :selected="selectedEmpresa"
                        firstSelected="EM">
                    </selectAll>
                </div>
                <div class="col-sm-4 nopadding divDontPrint">
                    <buttonsFilters 
                        @buttonsFiltersChange="clickBotao" 
                        :arrayButtons="arrayButtons" 
                        label="Status" 
                        :onlyOneSelected="true">
                    </buttonsFilters>
                </div>
            </div>
        </div>
        <div class="col-sm-12 divHrDeBaixo"><hr></div>
        <tableListagem 
            @alterarStatus="alteraStatus" 
            @editarCadastro="editarVisitas" 
            deleteEdit="editStatus" 
            :data="listagemVisitas" 
            :titles="titles">
        </tableListagem>
    </panelEagle>
</template>

<script lang="js">
import {mdiHomeFlood, mdiCheckBold } from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { mapGetters } from 'vuex'

import Vue from 'vue'
    export default Vue.extend({
        name:'listagemVisitas',
        components:{
            'tituloPage':               require('@/components/Atom/Header/Titulo').default,
            'selectAll':                require('@/components/Atom/Select/SelectAll').default,
            'panelEagle':               require('@/components/Atom/Panel/PanelEagle').default,
            'tableListagem':            require('@/components/Atom/Table/TableListagem').default,
            'buttonsFilters' :          require('@/components/Atom/Buttons/ButtonsFilters').default,
            'basicButtonsCadastrosEIN': require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
        },
        data() {
            return {
                mdiHomeFlood : mdiHomeFlood,
                mdiCheckBold : mdiCheckBold,
                loadingPanel:false,
                empresasSelecionadas: [],
                optionsArrayEmpresas : [],
                dadosListagem: [],
                listagemVisitas: [],
                selectedEmpresa: [],
                status: 'T',
                arrayButtons:[
                    {'value':'A', 'width': '32%', 'text': 'Ativo',   'isSelected':true,  'icon':''},
                    {'value':'I', 'width': '32%', 'text': 'Inativo', 'isSelected':false, 'icon':''},
                    {'value':'T', 'width': '32%', 'text': 'Todos',   'isSelected':false, 'icon':''},
                ],
                titles:[
                    {'name': 'Descrição', 'number': 'descricao'},
                    {'name': 'Empresa', 'number': 'empresa'},
                ],
                carregandoE:[false,false,false],
                loadingTable: false,
            }
        },
        methods: {
            novoCadastro(){
                this.$router.push({name: "cadastrarVisitas"})
            },
            changeEmpresa(selecionados){
                this.empresasSelecionadas = selecionados;
                if(this.empresasSelecionadas.length > 0){
                    this.listagem();
                }
            },

            clickBotao(event){
                this.status = event;
                this.arrayButtons[0].icon = ''
                this.arrayButtons[1].icon = ''
                this.arrayButtons[2].icon = ''
                if(event[0] == 'A'){
                    this.arrayButtons[0].icon = mdiCheckBold
                } else if(event[0] == 'I'){
                    this.arrayButtons[1].icon = mdiCheckBold
                } else if(event[0] == 'T'){
                    this.arrayButtons[2].icon = mdiCheckBold
                }
                if(this.empresasSelecionadas.length > 0){
                    this.listagem();
                }
            },

            async listagem(){
                this.loadingPanel = true
                var data = await new HttpRequest().Post(
                    '/roteirizador/cadastros/listagem', 
                    {'clientes':this.empresasSelecionadas, 'status':this.status[0]});
                this.listagemVisitas = data.data.dados;
                this.loadingPanel = false
            },

            editarVisitas(codigo){
                this.$router.push({name:'editarVisitas', params:{id:codigo}})
            },

            async alteraStatus(grupo, status){
                var data = await new HttpRequest()
                    .Post('/roteirizador/cadastros/status',
                        {
                            'id':grupo, 
                            'status':status
                        });
                if(data.status){
                    this.listagem();
                }
            },

            async exportar(type){
                var root =  process.env.VUE_APP_ROOT;
                if(this.listagemVisitas.length > 0){
                    this.setCarregando(type)
                    var dados = await new HttpRequest()
                        .Post('/roteirizador/cadastros/exportar', 
                            {
                                'arrayDados': this.listagemVisitas, 
                                'tipo':type
                            });
                    window.open(root+'/'+dados.data.local);
                    this.setCarregando()
                }
            },

            setCarregando(type){
                switch (type) {
                    case 'pdf':
                        this.carregandoE = [true, false, false]
                        break;
                    case 'xls':
                        this.carregandoE = [false, true, false]
                        break;
                    case 'csv':
                        this.carregandoE = [false, false, true]
                        break;
                    default:
                        this.carregandoE = [false, false, false]
                        break;
                }
            },

            ...mapGetters(['getMaster']),
        },

        mounted(){
            this.optionsArrayEmpresas =  new EmpresasService().Get()
            if(!this.getMaster()){
                this.selectedEmpresa   = this.optionsArrayEmpresas
            }
        }
    })
</script>

<style lang="scss" scoped>

</style>