<template>
    <panelEagle id='Registrar' :loading ="loadingTable">
        <div class="col-sm-12 nopadding row" >
            <div class="col-sm-6">
                <tituloPage :icon='mdiFuel' titulo='Tipo combustível'></tituloPage>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 divDontPrint">
                <basicButtonsCadastrosEIN
                    :disabledDropdown="!dataTable.length"
                    :loading="loadingExporta"
                    linkNew ='cadastrarCombustivel'
                    tipoNovo='button'
                    @exportarCadastro="exportarCadastro">
                </basicButtonsCadastrosEIN>
            </div>
        </div>
         <div class="col-sm-12 col-md-12"><hr></div>
        <div class="row nopadding">
            <div class="col-sm-4 divDontPrint nopadding">
                <selectAll
                    nameForRadio  ='Empresas'
                    :isMultiple   ='true'
                    :optionsArray ='optEmpresas'
                    :selected="selectedEmpresa"
                    :labels       ='labelsEmp'
                    :hasSelectAll ='true'
                    @changeSelect ='changeEmpresa'
                />
            </div>
             <div class="col-sm-12 col-md-12"><hr></div>
            <div class="col-sm-12 nopadding">
                <tableListagem
                    deleteEdit='editDelete'
                    :titles="[
                        {'number':'descricao', 'name':'Descrição'},
                        {'number':'empresa'  , 'name':'Empresa'},
                    ]"
                    :data="dataTable"
                    @changeSelect ='changeEmpresa'
                    @excluirCadastro='excluirCadastro'
                    @editarCadastro='editarCadastro'
                />
            </div>
        </div>
    </panelEagle>
</template>
<script>
import Vue  from 'vue'
import { mdiFuel } from '@mdi/js'
import { mapGetters } from 'vuex'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
export default Vue.extend({
    name:'ListarTipoCombustivel',
    components:{
        'panelEagle'               : require('@/components/Atom/Panel/PanelEagle').default,
        'basicButtonsCadastrosEIN' : require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
        'tituloPage'               : require('@/components/Atom/Header/Titulo').default,
        'selectAll'                : require('@/components/Atom/Select/SelectAll').default,
        'tableListagem'            : require('@/components/Atom/Table/TableListagem').default,
    },
    data(){
        return {
            mdiFuel:mdiFuel,
            dataTable:[],
            optEmpresas : new EmpresasService().Get(),
            selectedEmpresa:[],
            labelsEmp   :[{
                indexDFH    : 'EM',
                description : 'Empresas'
            }],
            valueEmpresa:[],
            loadingTable:false,
            loadingExporta:[false, false, false],
        }
    },
    methods:{
        ...mapGetters(['getMaster']),
        async changeEmpresa(value){
            this.valueEmpresa = value
            if(value.length < 1){
                this.dataTable = []
            }else{
                this.loadingTable = true
                new HttpRequest()
                .Post('/abastecimento/menu/tipo/combustivel/tabela', {'dados':this.valueEmpresa})
                .then((dados)=>{
                    this.dataTable = dados.data
                    this.loadingTable = false   
                })
            }
        },

        excluirCadastro(value){
            new HttpRequest()
            .Post('/abastecimento/menu/tipo/combustivel/excluir', {'id':value})
            .then(()=>{
                this.changeEmpresa(this.valueEmpresa)
            })
        },

        editarCadastro(value){
            this.$router.push({name:'cadastrarCombustivel', params:{id:value}})
        },
        exportarCadastro(value){
            if(this.loadingExporta.find(v=> v == true)){
                return
            }
            if(this.valueEmpresa.length != 0){
                switch (value) {
                    case 'pdf':
                        this.loadingExporta = [true, false, false]
                        break;
                    case 'xls':
                        this.loadingExporta = [false, true, false]
                        break;
                    default:
                        this.loadingExporta = [false, false, true]
                        break;
                }
                var obj = {
                    'tipo':value,
                    'empresa':this.valueEmpresa,
                }
                var root =  process.env.VUE_APP_ROOT;
                new HttpRequest()
                .Post('/abastecimento/menu/tipo/combustivel/exporta', {'dados':obj})
                .then(dados=>{
                    open(root+'/'+dados.data.retorno);
                    this.loadingExporta = [false, false, false]
                })
            }
        }
    },
    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optEmpresas
        }
    },
})
</script>
<style lang="scss" scoped>
</style>
