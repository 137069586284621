/**
    Este é o componente para dropUp.
    -> Propriedades:
        1- dropItem - Array - com os dados a serem mostrados no b-dropdown-item - EX: 
        dadosDropUp: [
                {text: 'Agendado Carregamento', value: '1', color: 'purpleLight', widthItem: '268px'},
        ]
        2- text - String - texto que aparece no botão - EX: 'Alterar status'
        3- widthDropUp - String - largura que o botão terá - Ex: '100px'

    -> Slot:
        Sem slot

    -> Eventos:
        1- dropItenClick - Emite quando um dropItem for clicado passando o value - Sem parametros a mais
 */

<template>
    <b-dropdown 
        id="dropdown-dropup"
        dropup
        ref='buttonDropUp' 
        size="sm" 
        class="dropup" 
        variant="primary"
        :style="'width:'+widthDropUp"
        :disabled="disabled">
        <template v-slot:button-content>
            <baseIcon 
                :icon='mdiPencil' 
                size='12' /> {{text}}
        </template>
        <div v-for="(itens, i) in dropItem"
            :key="i"
            :style="'width:'+itens.widthItem"
            class="dropUpDiv">
            <b-dropdown-item 
                id="dropUp-item"
                class="dropUp-item"
                :class="itens.color" 
                :disabled="disabled"
                @click="dropItenClick(itens.value)">
                {{itens.text}}
            </b-dropdown-item>
        </div>
    </b-dropdown>
</template>
<script lang="ts">
    import {mdiPencil} from '@mdi/js'
    import Vue from 'vue'
    import Vuex from '../../../store/index'
export default Vue.extend({
    name:'buttonDropup',
    components: {
        baseIcon : require('@/components/Atom/Icon/BaseIcon.vue').default,
        },
        props:{
            dropItem:{
                type: Array,
            },
            text:{
                type: String,
                default: 'Button',
            },
            widthDropUp:{
                type: String,
                default: '100px'
            },
            disabled:{
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                mdiPencil: mdiPencil,
            }
        },
        methods: {
            dropItenClick(value:string){
                this.$emit('dropItemClick', value)
            }
        },
})
</script>
<style lang="scss">
#dropdown-dropup{
    .dropdown-menu{
        margin-bottom: 0px;
        color: #fff !important;
        #dropUp-item, .dropUp-item{
            color: #fff !important;
            font-size: 13px !important;
            border: none !important;
            font-weight: normal !important;
            padding: 3px 10px;
            height: 30px;
            text-align: center;
            cursor: pointer;
        }
        .grey{
            background: #a6a6a6 !important;
        }
        .red{
            background: #ee6969;
        }
        .purpleLight{
            background: #c389d4;
        }
        .purple{
            background: #8f2b9a;
        }
        .blue{
            background: #33bbde;
        }
        .orange{
            background: #ffa600;
        }
        .yellow{
            background: #d6c433 !important;
        }
        .green{
            background: #30a82f;
        }
    }
    #dropUp-item, .dropUp-item:active{
        background: none;
    }
    .dropdown-item:hover{
        background: none;
    }
    .dropUp-item:hover{
        opacity: 0.9;
    }
}
</style>