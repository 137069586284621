<template>
    <panelEagle id="Eventos" :loading="loadingPanel">
        <div class="col-sm-12 nopadding divDontPrint">
             <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon='mdiCalendarLock' titulo='Eventos'></tituloPage>
                </div>
                <div class="col-sm-6 divButtonsBasic">
                    <basicButtonsRelatoriosEIG
                        :disabled="$v.$invalid"
                        :loading="carregandoE"
                        :disabledDropdown="relatorio == 0"
                        @gerarRelatorio="gerarRelatorio"
                        @exportarRelatorio="exportarRelatorio">
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12 col-md-12"><hr></div>
                    <div class='col-sm-12 row mb-2 divDontPrint nopadding'>
                        <div class="col-lg-3 col-md-12 nopadding">
                            <inputRangeWithLimit 
                                @changeInput   ="changeData" 
                                name           ="inputData"
                                :isObrigatorio ="true" 
                                opens          ="right"
                            />
                        </div>
                        <div class="col-lg-3 col-md-12 nopadding">
                            <selectAll
                                :labels       ="[{indexDFH: 'EM', description: 'Empresas*'}]"
                                nameForRadio  ='radiosEmpresas'
                                :optionsArray ='optionsEmpresas'
                                :hasSelectAll ='true'
                                :isMultiple   ='true'
                                :selected     ="selectedEmpresa"
                                @changeSelect ='changeEmpresa'>
                            </selectAll>
                        </div>
                        <div class="col-lg-3 col-md-12 nopadding">
                            <selectAll
                                :labels       ="[{indexDFH: 'CAD', description: 'Cadeados*'}]"
                                nameForRadio  ='radiosCadeados'
                                :optionsArray ='optionsCadeados'
                                ref='selectCadeado'
                                :hasSelectAll ='true'
                                :isMultiple   ='true'
                                :disabled     ="$v.empresasSelecionadas.$invalid"
                                :loading      ="carregandoCadeados"
                                @changeSelect ='changeCadeado'
                            />
                        </div>
                        <div class="col-lg-3 col-md-12">
                            <b-checkbox 
                                v-model="semVinculacao"
                                class='checkBox'>
                                Mostrar cartões sem vinculação
                            </b-checkbox>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
        <div class="col-sm-12 col-md-12"><hr></div>
    </div>
    <tableRelatorio>
        <slot slot="thead">
            <th style="margin-left: 1px;">Serial</th>
            <th>Prefixo</th>
            <th>Data</th>
            <th>Hora</th>
            <th>Evento</th>
            <th>Nº Cartão</th>
            <th>Colaborador</th>
            <th>Coordenadas</th>
            <th>Ponto</th>
            <th>Endereço</th>
            <th>Localizar</th>
        </slot>
        <slot slot="tbody">
            <br>
            <statusInformation 
                v-if="relatorio.length == 0" 
                :statusBar="statusbar"/>
            <template v-for="(placa, index) in relatorio">
                <tr :key="index">
                    <td 
                        colspan="5"
                        style="
                            text-aling: center;
                            margin-top: 10px;
                            height: 30px;
                            background-color:white;
                            border:none;">
                        <span class="badge">
                            {{index}}
                        </span>
                    </td>
                    <td colspan="6"
                        style="background-color:white;
                            border:none;">
                    </td>
                </tr>
                <template v-for="(evento, teste) in placa">
                    <tr :key="teste+index">
                        <td>{{evento.calmodulo}}</td>
                        <td>{{evento.calprefixo}}</td>
                        <td>{{evento.data}}</td>
                        <td>{{evento.hora}}</td>
                        <td> 
                            <span v-if="evento.caltipoevento == 'Abertura'">
                                <baseIcon size='18' :icon="mdiLockOpenVariant"/>
                                {{evento.caltipoevento}}
                            </span>
                            <span v-else-if="evento.caltipoevento == 'Fechamento'">
                                <baseIcon size='18' :icon="mdiLock"/>
                                {{evento.caltipoevento}}
                            </span> 
                            <span v-else-if="evento.caltipoevento == 'Alteração de prefixo' ">
                                <baseIcon size='18' :icon="mdiLockReset"/>
                                {{evento.caltipoevento}}
                            </span>
                            <span v-else-if="evento.caltipoevento == 'Permissão negada'">
                                <baseIcon size='18' :icon="mdiLockRemove"/>
                                {{evento.caltipoevento}}
                            </span>
                        </td>
                        <td>{{evento.calcartao}}</td>
                        <td>{{evento.calcolaborador}}</td>
                        <td>{{evento.callatitude + ' ,' + evento.callongitude}}</td>
                        <td>{{evento.calponto}}</td>
                        <td>{{evento.calendereco}}</td>
                        <td>
                            <span
                                event   ='click'
                                @click  ="maps(evento.callatitude,evento.callongitude)">
                                <baseIcon
                                    size    ='18'
                                    class   ="cor-icone"
                                    :icon   ="mdiTarget" 
                                />
                            </span>
                        </td>
                    </tr>
                </template>
            </template>
        </slot>
    </tableRelatorio>
    </panelEagle>
</template>
<script>
import Vue  from 'vue'
import { 
    mdiCalendarLock,
    mdiLockOpenVariant,
    mdiLock,
    mdiLockReset,
    mdiLockRemove,
    mdiTarget,
} from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest }     from '@/Services/auth/HttpRequest.Service'
import { FiltrosService }  from '@/Services/filtros/filtros.Service'
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex'

export default Vue.extend({
    name:'relatorioEventosCadeados',
    components:{
        baseIcon                   : require('@/components/Atom/Icon/BaseIcon.vue').default,
        'basicButtonsRelatoriosEIG': require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
        'panelEagle'               : require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage'               : require('@/components/Atom/Header/Titulo').default,
        'selectAll'                : require('@/components/Atom/Select/SelectAll').default,
        'InputRangeWithLimit'      : require('@/components/Atom/Datas/InputRangeWithLimit').default,
        'slideUpAndDown'           : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
        'tableRelatorio'           : require('@/components/Atom/Table/TableRelatorio').default,
        'statusInformation'        : require('@/components/Atom/StatusInformation/StatusInformation').default,
    },
    validations:{
        empresasSelecionadas: {required },
        cadeadosSelecionados: { required },
        datas: { required },
    },
    data() {
        return {
            loadingPanel         :false,

            mdiLock            :mdiLock,
            mdiTarget          :mdiTarget,
            mdiLockReset       :mdiLockReset,
            mdiLockRemove      :mdiLockRemove,
            mdiCalendarLock    :mdiCalendarLock,
            mdiLockOpenVariant :mdiLockOpenVariant,
            optionsEmpresas      : new EmpresasService().Get(),
            optionsCadeados      : [],
            datas                : '',
            empresasSelecionadas : [],
            cadeadosSelecionados : [],
            htmlRelatorio        : '',
            tamanhoRelatorio     : 0,
            relatorio            : [],
            statusbar            : 'info',
            semVinculacao        : false, 
            carregandoE          :[false,false,false],
            uriBase              : '/cadeados/relatorio/eventos/',
            carregandoCadeados   : false,
            selectedEmpresa      : [],
        }
    },
    methods: {
        ...mapGetters(['getMaster']),

        changeEmpresa(selecionadas){
            this.empresasSelecionadas = selecionadas
            this.$refs.selectCadeado.clearAll()
            this.relatorio = []
            if(selecionadas.length > 0){
                this.carregandoCadeados = true
                new FiltrosService().dados_filtros(selecionadas, ['CAD']).then(data=>{
                    this.optionsCadeados =  data.CAD
                    this.carregandoCadeados = false
                })
            }
        },
        changeCadeado(selecionados){
            this.cadeadosSelecionados = selecionados
            this.relatorio = []
        },
        changeData(data){
            this.datas = data;
            this.relatorio = []
        },
        async gerarRelatorio(){
            this.loadingPanel = true
            if(this.empresasSelecionadas.length > 0){
                this.statusbar = 'info';
                this.relatorio = [];
                this.dataDoRelatorio = this.datas.split('-');
                var data_ini = this.dataDoRelatorio[0]
                var data_fim = this.dataDoRelatorio[1]
                let uri = this.uriBase+'gerar'
                var dados = await new HttpRequest()
                .Post(uri, 
                    {
                        'data_ini'     : data_ini, 
                        'data_fim'     : data_fim, 
                        'cadeados'     : this.cadeadosSelecionados, 
                        'checkCartoes' : this.semVinculacao
                    });
                this.relatorio = dados.data;
                if(this.relatorio == ''){
                    this.statusbar = 'error';
                }
            }
            this.loadingPanel = false
        },
        exportarRelatorio(type){
            var root =  process.env.VUE_APP_ROOT;
            this.setCarregando(type)
            if(this.relatorio != ''){
                let uri = this.uriBase+'exportar'
                let obj = {'arrayDados':JSON.stringify(this.relatorio), 'tipo':type}
                new HttpRequest()
                .Post(uri, obj).then(data=>{
                    open(root+'/'+data.data);
                    this.setCarregando()
                });
            }
        },
        maps(lat, long){
            var root =  process.env.VUE_APP_ROOT;
            window.open('http://maps.google.com/maps?q=loc:'+lat+','+long);
        },
        setCarregando(type = null){
            if(type == 'pdf'){
                this.carregandoE = [true, false, false]
            }else if(type == 'xls'){
                this.carregandoE = [false, true, false]
            }else if(type == 'csv'){
                this.carregandoE = [false, false, true]
            }else{
                this.carregandoE = [false, false, false]
            }
        },
    },

    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optionsEmpresas
        }
    }
})
</script>
<style lang="css" scoped>
    .checkBox{
        margin-top: 8% !important;
    }
    .table td{
        padding: 0.25rem;
        border: 1px solid #dee2e6;
    }
    .cor-icone:hover{
        cursor: pointer;
        color: #87CEEB;
    }
    .cor-icone{
        color: #428bca;
    }
    .badge{
        width: 470px;
        background-color:#777777;
        color: white;
        height: 33px;
        padding: 12px;
        font-size: 13px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }  

</style>