/**
    Input de text com mascara e botão de pesquisa

    -> Propriedades:
        1- label - String - Descricao do input/Legenda que fica em cima -
		EX: 'Logo'
        2- name - String - Name do input - EX: 'inputSearch'
        3- hasMask - Boolean - tem mascara? - EX: true/false
        4- mask - Array - array de opções de mascara seguindo as tokens abaixo -
			EX: ['###.##.###']
        5- value - String - Rota para o arquivo ja selecionado -
			EX: '999.99.153'
        4- inputClass - String- Classe extra para o input - EX: 'inputArchive'
        5- inputDisabled - String - se ele esta desabilitado - EX: 'disabled'/''
        6- buttonDisabled - String - se ele esta desabilitado -
			EX: 'disabled'/''
        7- id - String - Id do input - EX: 'inputSearch'

        **Tokens**
            '#': {pattern: /\d/},
            'h': {pattern: /[0-2]}
            'X': {pattern: /[0-9a-zA-Z]/},
            'S': {pattern: /[a-zA-Z]/},
            'A': {pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase()},
            'a': {pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase()},
            '!': {escape: true}
            'h': {pattern: /[0-2]/},
            'H': {pattern: /[0-4]/},
            'M': {pattern: /[0-5]/},
            'N': {pattern: /[0-9]/},
            'L': {pattern: /[0-50]/},
            'l': {pattern: /[a-jA-J]/,
            /* PARA NOVOS TOKENS INSERIR NA VARIAVEL TOKENS DENTRO DO DATA */

    -> Slot:
        Sem slot

    -> Eventos:
        1- changeInput - Emite quando o input é alterado - Parametros:
            1.1- inputValue - o valor do input
        2- clickButtonSearch - Emite quando o botão for clicado - Parametros:
            2.2- inputValue - o valor do input
 */
<template>
    <div class="col-sm-12">
        <div class="col-sm-12 subtitle nopadding">
            {{label}}
        </div>
        <the-mask 
            v-bind='$attrs' 
            @keyup.enter="keyupEnter" 
            :tokens="tokens" 
            :mask="mask" 
            v-model="inputValue" 
            :value="inputValue" 
            :name="name" 
            v-if="hasMask && inputDisabled == 'disabled'" 
            disabled 
            class="form-control" 
            :id="id" 
            :class="inputClass"/>
        <the-mask 
            v-bind='$attrs' 
            @keyup.enter="keyupEnter" 
            :tokens="tokens" 
            :mask="mask" 
            v-model="inputValue" 
            :value="inputValue" 
            :name="name" 
            v-else-if="hasMask && inputDisabled != 'disabled'" 
            class="form-control" 
            :id="id" 
            :class="inputClass" />
        <input 
            v-bind='$attrs' 
            v-else-if="!hasMask && inputDisabled == 'disabled'" 
            disabled 
            type="text" 
            class="form-control" 
            :class="inputClass" 
            v-model="inputValue" 
            @keyup.enter="keyupEnter" 
            @keyup.esc='keyupEsc'
            :name="name" 
            :id="id">
        <input 
            v-bind='$attrs' 
            v-else-if="!hasMask && inputDisabled != 'disabled'" 
            type="text" 
            class="form-control" 
            :class="inputClass" 
            v-model="inputValue" 
            @keyup.enter="keyupEnter" 
            @keyup.esc='keyupEsc'
            :name="name" 
            :id="id">
        <!-- <simpleButton 
            v-bind='$attrs' 
            :icon="mdiMagnify" 
            v-if="buttonDisabled == 'disabled'"
            event="click" 
            @click="clickButton"
            width="50" 
            type="blue">
        </simpleButton> -->
        <simpleButton 
            v-bind='$attrs' 
            :icon="mdiMagnify" 
            event="click" 
            @click="clickButton"
            width="50" 
            type="blue">
        </simpleButton>
    </div>
</template>
<script>
import Vue from 'vue'
import { mdiMagnify } from '@mdi/js'
import {TheMask} from 'vue-the-mask'

export default Vue.extend({
	components: {
		TheMask,
		'simpleButton':require('../Buttons/SimpleButton').default
	},
	inheritAttrs :false,
	props:{
		label: {
			type: String,
			required: false,
			default: '',
		},
		name: {
			type: String,
			required: true,
			default: '',
		},
		hasMask: {
			type: Boolean,
			required: false,
			default: false
		},
		mask: {
			type: Array,
			required: false,
			default: function(){return []},
		},
		value: {
			type: [String, Number],
			required: false,
			default: '',
		},
		inputClass: {
			type: [String, Object],
			required: false,
			default: '',
		},
		inputDisabled: {
			type: String,
			required: false,
			default: ''
		},
		buttonDisabled: {
			type: [String, Boolean],
			required: false,
			default: false
		},
		id:{
			type: String,
			required: false,
			default: ''
		}
	},
	data(){
		var inputValue = this.value;
		var tokens = {
			'#': {pattern: /\d/},
			'X': {pattern: /[0-9a-zA-Z]/},
			'S': {pattern: /[a-zA-Z]/},
			'A': {pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase()},
			'a': {pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase()},
			'!': {escape: false},
			'h': {pattern: /[0-2]/},
			'H': {pattern: /[0-4]/},
			'M': {pattern: /[0-5]/},
			'N': {pattern: /[0-9]/},
			'L': {pattern: /[0-49]/},
			'l': {pattern: /[a-zA-Z0-9]/, transform: v => {
				return v.toLocaleUpperCase()
			}
			},
		};
		return{
			mdiMagnify:mdiMagnify,
			inputValue,
			tokens,
		}
	},
	methods:{
		keyupEsc(event){
			this.$emit('keyup-esc', this.inputValue)
		},
		keyupEnter(event){
			this.$emit('keyupEnter', this.inputValue);
		},
		clickButton(event){
			this.$emit('clickButtonSearch', this.inputValue);
		}
	},
	mounted(){
		this.$emit('changeInput', this.inputValue);
	},
	watch:{
		inputValue(nValue){
			this.$emit('changeInput', nValue)
		},
		value:function(){
			this.inputValue = this.value
		},
	}

})

</script>

<style lang="scss" scoped>
    .subtitle{
        font-size: 13px;
        text-align: left;
        margin-bottom: 2px;
    }
    .bt-eagle{
        float: right;
        position: relative;
        bottom: 44px;
        height: calc(2em + 0.75rem + 8.5px);
        margin-right: 0px;
    }
</style>
