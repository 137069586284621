export var Usuario ={
	nome:'',
	telefone:'',
	cargo:'',
	name:'',
	email:'',
	// password:'',
	usucliente:'',
	usumaster:'N',
	usuativo:'',
	usuperfil:'',
	usutipo:'',
	usurecebealerta:'',
	usvalidacao:false,
	usualertaemail:false,
	usualertatelegram:false,
	usualertaapp:false,
	usucodigotelegram:'',
	usucpf:''
}