import { ChartDataset } from 'chart.js'

import { EventCodes } from '@/Services/vehicle/telemetry.service'

type AllowedChartDataset = ChartDataset<'line'|'scatter'>

const icons = {
    carBrakeAbs: require('../assets/bolinha-vermelha.png'),
    carBrakeAlert: require('../assets/bolinha-amarela.png'),
    carChildSeat: require('../assets/bolinha-azul.png'),
    carMultiple: require('../assets/bolinha-rosa.png'),
    carSpeedLimiter: require('../assets/bolinha-laranja.png'),
    engineOffOutline: require('../assets/bolinha-violeta.png'),
    seatbelt: require('../assets/bolinha-cinza.png'),
    speedometer: require('../assets/bolinha-vermelha.png'),
    wiperWash: require('../assets/bolinha-preta.png'),
    pneumatics: require('../assets/bolinha-azul-escura.png'),
    snail: require('../assets/bolinha-verde.png')
}

const eventIcons = {
    [EventCodes.Collision]: icons.carMultiple,
    [EventCodes.HarshBraking]: icons.carChildSeat,
    [EventCodes.HarshAcceleration]: icons.speedometer,
    [EventCodes.CurveHarshBraking]: icons.seatbelt,
    [EventCodes.CurveHighSpeed]: icons.carSpeedLimiter,
    [EventCodes.CurveHarshAcceleration]: icons.speedometer,
    [EventCodes.CoastingInNeutral]: icons.engineOffOutline,
    [EventCodes.EngineBraking]: icons.carBrakeAlert,
    [EventCodes.Brake]: icons.carBrakeAbs,
    [EventCodes.WindshieldWiper]: icons.wiperWash,
    [EventCodes.Pneumatics]: icons.pneumatics,
    [EventCodes.Snail]: icons.snail,
}

const ICON_SIZE = 10

/**
 * Cria uma imagem com o endereço passado.
 * @param src Link da imagem
 * @returns Imagem criada
 */
const createImage = (src: string): HTMLImageElement => {
    const image = new Image(ICON_SIZE, ICON_SIZE)
    image.src = src

    return image
}

/**
 * Cria um objeto de configuração de evento com ícone.
 * 
 * @param eventCode Código do evento
 * @param eventConfig Configurações do evento p/ o gráfico
 * @returns Objeto de configurações de evento com ícone
 */
export function createEventWithIcon(eventCode: EventCodes, eventConfig: Partial<AllowedChartDataset>): 
    Partial<AllowedChartDataset> {
    if (!eventIcons[eventCode]) return { ...eventConfig }

    return {
        ...eventConfig,
        pointStyle: createImage(eventIcons[eventCode]) as unknown as string
    }
}
