/**
    Este é o componente de desenho livre no mapa leaflet e segue com o botões de controle do plugin
    -> Propriedades:
        1- visible - Boolean - Se o objeto deve estar na tela ou não - EX: true/false
        2- polygons - Array - array de poligonos ja criados
    -> Slot:
        Sem slot
    -> Eventos:
        1- changeCoordenades - Emite quando um desenho for feito - Parametros:
            1.1- latlng - Array das coordenadas desenhadas
 */
<template>
    <div :class="classParent">
        <div class="divControlesFreeDraw col-sm-3 row">
            <div class="col-sm-4 nopadding">
                <simpleButton @click="pararDesenho" event="click" type="orange" :icon="mdiMinusCircle" width="100%" title="Parar"></simpleButton>
            </div>
            <div class="col-sm-4 nopadding">
                <simpleButton @click="desenharDesenho" event="click" type="blue" :icon="mdiPencil" width="100%" title="Desenhar"></simpleButton>
            </div>
            <div class="col-sm-4 nopadding">
                <simpleButton @click="apagarDesenho" event="click" type="red" :icon="mdiTrashCanOutline" width="100%" title="Apagar tudo"></simpleButton>
            </div>
        </div>
    </div>
</template>
<script>
import {mdiMinusCircle, mdiPencil, mdiTrashCanOutline } from '@mdi/js'
import Vue, { PropType } from 'vue'
import L, { LatLng, editableLayers } from 'leaflet';
import FreeDraw, { CREATE, EDIT , DELETE, APPEND, NONE }from 'leaflet-freedraw';
import { propsBinder } from 'vue2-leaflet';
const props = {
	visible:{
		type: Boolean,
		required: false,
		default: true
	},
	polygons:{
		type: Array,
		required: false,
		default: function(){return []},
	},
	classParent: {
		type: String,
		default: '',
	},
};
export default Vue.extend({
	props: props,
	components:{
		'simpleButton':require('../Buttons/SimpleButton').default,
	},
	data(){
		return{
			mdiTrashCanOutline:mdiTrashCanOutline,
			mdiPencil:mdiPencil,
			mdiMinusCircle:mdiMinusCircle,
		}
	},
	mounted(){
		this.free = new FreeDraw({ mode: FreeDraw.ALL });

		if (this.$parent._isMounted) {
			this.deferredMountedTo(this.$parent.mapObject);
		}
		this.$emit('freeDrawCriado', this);
		this.free.on('markers', (event) => {
			this.$emit('desenho', this.getLatLng())
		})
	},
	beforeDestroy() {
		this.setVisible(false);
	},
	methods:{    
		
		deferredMountedTo(parent) {
			this.parent = parent;
			var that = this.free;
			for (var i = 0; i < this.$children.length; i++) {
				if (typeof this.$children[i].deferredMountedTo == 'function') {
					this.$children[i].deferredMountedTo(that);
				}
			}
			if (this.visible) {
				this.free.addTo(parent);
				for(var a in this.polygons){
					this.free.create(this.polygons)
				}
			}
		},
		setVisible(newVal, oldVal) {
			if (newVal === oldVal) return
			if (this.free) {
				if (newVal) {
					this.free.addTo(this.parent)
				} else {
					this.parent.removeLayer(this.free)
				}
			}
		},
		pararDesenho(){
			this.free.mode(NONE)
			var latlng = this.getLatLng()
			this.$emit('changeCoordenades', latlng)
		},
		desenharDesenho(){
			this.free.mode(CREATE | EDIT | DELETE | APPEND)
			var latlng = this.getLatLng()
			this.$emit('changeCoordenades', latlng)
		},
		apagarDesenho(){
			this.free.clear()
			var latlng = this.getLatLng()
			this.$emit('changeCoordenades', latlng)
		},
		getLatLng(){
			var polygons = this.free.all()
			var conjuntoCoord = []
			for(var a in polygons){
				conjuntoCoord.push(polygons[a]._latlngs[0])
			}
			return conjuntoCoord
		},
	},
	// watch:{
	// 	polygons: function(){
	// 		// L.Polygon(this.polygons).addTo(this.free)
	// 		var polygon = L.polygon(this.polygons, {})
	// 			.addTo(this.$parent.mapObject);
	// 		//  this.free.create(L.polygon(this.polygons, {}))
	// 		// zoom the map to the polygon
	// 		// this.free.fitBounds(polygon.getBounds());
	// 	}
	// }
})
</script>
<style lang="scss">
    .leaflet-edge {
        background-color: #428BCA;
        box-shadow: 0 0 0 2px white, 0 0 10px rgba(0, 0, 0, 0);
        border-radius: 50%;
        outline: none;
        transition: background-color .25s;
        &.disabled{
            opacity: 0.5;
        }
    }
    .mode-create{
        cursor: crosshair;
    }
    .divControlesFreeDraw{
        float: right;
        position: absolute !important;
        z-index: 1000;
        right: 0;
		top: 0;
    }
</style>