<template>
    <div class="col-sm-12 p-0 cardPedido borda-tab" :style="pedido.isTipoParada ? 'height:50px!important' : 'height:147px'"
        :class="[corFundo, corBorda]">
        <div class="co-sm-12 nopadding row" style="flex-wrap: nowrap">
            <!-- CHECKBOX SELECIONAR ITEM -->
            <div v-show="check" class="col-sm-1 divCheckbox row" :class="corBorda" @click="changeCard">
                <b-checkbox :key="pedido.irnome + pedido.ircodigo" v-model="pedido.selecionado" class='checkBox' size="lg">
                </b-checkbox>
            </div>
            <!-- INFORMAÇÕES GERAIS PEDIDO/PARADA -->
            <div class="col-sm-11 p-0 pt-1 row divInfo">
                <div class="col-sm-12 row nopadding">
                    <!-- INDICADOR ORDEM -->
                    <div class="col-sm-1 text-center font-weight-bold indicadorOrdem">
                        {{ pedido.irordem }}
                    </div>
                    <!-- TIPO PEDIDO -->
                    <template v-if="!ehTipoParada">
                        <div class="col-sm-11 text-left nopadding">
                            <div class="text-long nomePedido" :title="pedido.irnome">
                                {{ pedido.irnome }}
                            </div>
                        </div>
                    </template>
                    <!-- TIPO PARADA -->
                    <template v-if="ehTipoParada">
                        <div class="col-sm-11 text-long text-left p-0">
                            <div class="text-long nomePedido" :title="pedido.irnome">
                                {{ pedido.irnome }}
                            </div>
                        </div>
                        <div class="col-sm-8 divisa p-0 pt-2 pl-3 text-left" :class="corBorda">
                            {{ defineTextoTipoParada }}
                        </div>
                        <div class="col-sm-4 divisa p-0 pt-2 pl-1 text-left" :class="corBorda">
                            Tempo: {{ formataTempoParado }}
                        </div>
                    </template>
                </div>
                <!-- INFORMAÇÕES JANELA ENTREGA E MARCADOR -->
                <div v-if="defineTextoJanelaEntrega || temMarcador" class="col-sm-12 row divJanelaEMarcador">
                    <!-- JANELA ENTREGA -->
                    <span v-if="defineTextoJanelaEntrega" class="spanJanelaEntrega">
                        {{ defineTextoJanelaEntrega }}
                    </span>
                    <!-- MARCADOR -->
                    <template v-if="temMarcador">
                        <span v-for="marcador in marcadores" :key="marcador.value + '_tag_marcador'" class="tagMarcador"
                            :style="{ 'background-color': marcador.color }">
                            {{ marcador.description }}
                        </span>
                    </template>
                </div>
            </div>
        </div>
        <!-- INFORMAÇÕES PEDIDO -->
        <div v-if="!ehTipoParada" class="col-sm-12 nopadding row divisa" :class="corBorda">
            <div class="col-sm-6 text-left p-0 pt-1">
                <!-- DATA DO ITEM -->
                <div class="col-sm-12 p-0 pl-1">
                    <b>Data:</b> {{ formataDadosPedido('data') }}
                </div>
                <!-- ESTIMATIVA DE CHEGADA -->
                <div class="col-sm-12 p-0 pl-1 pt-1 divisa" :class="corBorda">
                    <b>Estimativa:</b> {{ defineTextoEstimativa }}
                </div>
                <!-- TEMPO PARADO -->
                <div class="col-sm-12 row p-0 pl-1 divisa" :class="corBorda">
                    <!-- INPUT TEMPO PARADO -->
                    <div class="pt-1"><b>Tempo de parada:</b> </div>
                    <input class="inputTempoParada" type="time" @focus="pedido.editando = true" @change="changeTempo"
                        v-model="tempoEdicao" :disabled='disabledTempoParado' />
                    <!-- AÇÕES TEMPO PARADO -->
                    <div class="col-sm-12 row p-0">
                        <!-- SALVAR -->
                        <simpleButton v-if="pedido.editando" :disabled="pedido.salvando" :icon="mdiCheckBold" width="35px"
                            height="25px" :extraClass="'btnAcaoTempoParado'" @click="saveButton" text="" event="click"
                            type="blue">
                        </simpleButton>
                        <!-- CANCELAR -->
                        <simpleButton v-if="pedido.editando" :disabled="pedido.salvando" :icon="mdiCloseThick" width="35px"
                            height="25px" :extraClass="'btnAcaoTempoParado'" @click="cancelButton" text="" event="click"
                            type="red">
                        </simpleButton>
                    </div>
                </div>
            </div>
            <!-- INFORMAÇÕES PEDIDO -->
            <div class="col-sm-6 row text-left p-0 pt-1 grupoInfoPedido" :class="corBorda"
                style="border-left: 1px solid #ccc;">
                <div class="col-sm-12 p-0 pl-2">
                    <b>Peso: </b>{{ formataDadosPedido('peso') }}
                </div>
                <div class="col-sm-12 p-0 pl-2">
                    <b>Cubagem: </b>{{ formataDadosPedido('cubagem') }}
                </div>
                <div class="col-sm-12 p-0 pl-2">
                    <b>Volume: </b>{{ formataDadosPedido('volume') }}
                </div>
                <div class="col-sm-12 p-0 pl-2">
                    <b>Valor: </b>{{ formataDadosPedido('valor') }}
                </div>
                <div class="col-sm-12 p-0 pl-2 text-long" :title="pedido.irdocumento">
                    <b>Documento: </b>{{ pedido.irdocumento }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Vue from 'vue'
import { required } from 'vuelidate/lib/validators'
import { converteSegundosEmHoras, converteSegundosEmDataHora, pickColor, criaMarcadores }
    from '@/views/Roteirizador/NaoMenus/RotaManual';
import { DateTime } from "luxon";
import { mdiCloseThick, mdiCheckBold } from '@mdi/js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';

export default Vue.extend({
    components: {
        'simpleButton': require('@/components/Atom/Buttons/SimpleButton.vue').default,
    },

    props: {
        pedido: {
            required
        },
        rota: {
            required
        },
        check: {
            type: Boolean,
            default: true
        },
        disabledTempoParado: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            mdiCloseThick: mdiCloseThick,
            mdiCheckBold: mdiCheckBold,
            format: 'yyyy-LL-dd HH:mm:ss',
            format2: 'dd/LL/yyyy HH:mm:ss',
            formatBr: 'dd/LL/yy HH:mm',
            tempoEdicao: '',
        }
    },

    mounted() {
        if (!this.pedido.irtempoparado) {
            this.tempoEdicao = ''
        } else {
            this.tempoEdicao = converteSegundosEmHoras(this.pedido.irtempoparado, 'hh:mm')
        }
    },

    watch: {
        'pedido.irtempoparado'(newValue, old) {
            if (!this.pedido.irtempoparado) {
                this.tempoEdicao = ''
            } else {
                this.tempoEdicao = converteSegundosEmHoras(this.pedido.irtempoparado, 'hh:mm')
            }
        }
    },

    methods: {
        async saveButton() {
            let url = '/roteirizador/rota/manual/pedido/tempo/editar'
            let body = {
                'codigo': this.pedido.ircodigo,
                'tempo': this.tempoEdicao + ':00',
            }
            this.pedido.salvando = true
            await new HttpRequest().Post(url, body)
                .then(({ data, status, code }) => {
                    if (status && data && code == 200) {
                        this.pedido.editando = false
                        this.pedido.irtempoparado = data.tempo
                    } else {
                        // aviso de falha
                    }
                })
                .catch((error) => {
                    this.pedido.editando = false
                })
                .finally(() => {
                    this.pedido.salvando = false
                })
        },

        cancelButton() {
            this.pedido.editando = false
            this.pedido.salvando = false
        },

        changeTempo(value) {
            // this.tempoEdicao = value
        },

        changeCard(e) {
            e.preventDefault()
            this.$emit('selecionado')
        },

        chamaPickColor(str) {
            return pickColor(str)
        },

        hashCode(str) {
            let hash = 0;
            if (!str) return hash
            for (var i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
            return hash;
        },

        formataDadosPedido(tipo) {
            switch (tipo) {
                case 'volume':
                    if (this.pedido.irqtde > 0) {
                        return this.pedido.irqtde
                    }
                    return '';
                case 'peso':
                    if (this.pedido.irpeso > 0) {
                        return `${this.pedido.irpeso} Kg`
                    }
                    return '';
                case 'valor':
                    if (this.pedido.irvalor > 0) {
                        return `R$ ${this.pedido.irvalor}`
                    }
                    return '';
                case 'cubagem':
                    if (this.pedido.ircubagem > 0) {
                        return `${this.pedido.ircubagem} m³`
                    }
                    return '';
                case 'data':
                    if (this.pedido.irdata) {
                        return `${this.pedido.irdata}`
                    }
                    return '';
                default:
                    return '';
            }
        },
    },

    computed: {
        ehTipoParada() {
            return this.pedido.irintrajornada || this.pedido.irinterjornada
        },

        /**
         * @description Retorna um texto com data e hora referente a estimativa de 
         * entrega do pedido.
         * @return {string}
         */
        defineTextoEstimativa() {
            if (this.pedido.selecionado && this.pedido.duracaoseq && this.rota.data && this.rota.hora) {
                var dti = DateTime.fromFormat(`${this.rota.data} ${this.rota.hora}:00`, this.format2)
                let duracaoPedido = dti.toSeconds() + this.pedido.duracaoseq
                return converteSegundosEmDataHora(duracaoPedido)
            }
            return ''
        },

        /**
         * @description Retorna um texto conforme tipo de parada.
         * @return {string}
         */
        defineTextoTipoParada() {
            if (this.ehTipoParada) {
                if (this.pedido.irintrajornada) {
                    return 'Parada de intervalo (intrajornada)'
                }
                if (this.pedido.irinterjornada) {
                    return 'Parada de descanso (interjornada)'
                }
            }
            return ''
        },

        /**
         * @description Retorna um texto com data e hora referente a janela de entrega.
         * @return {string}
         */
        defineTextoJanelaEntrega() {
            if (this.pedido.irinijanelaentrega && this.pedido.irfimjanelaentrega) {
                var janelaIni = DateTime
                    .fromFormat(this.pedido.irinijanelaentrega, this.format)
                    .toFormat(this.formatBr)
                var janelaFim = DateTime
                    .fromFormat(this.pedido.irfimjanelaentrega, this.format)
                    .toFormat(this.formatBr)
                return janelaIni + ' | ' + janelaFim
            }
            return ''
        },

        /**
         * @description Retorna um texto no formato de horas hh:mm:ss.
         * @return {string}
         */
        formataTempoParado() {
            if (!this.pedido.irtempoparado) return ''
            return converteSegundosEmHoras(this.pedido.irtempoparado, 'hh:mm')
        },

        /**
         * @description Retorna uma string que indica qual a classificação da janela de 
         * entrega do pedido: 'vermelho'|'amarelo'|'verde-selecionado'|''
         * @return {string}
         */
        defineAlertaJanela() {
            let classeAlerta = ''
            let rotaValida = this.rota.data && this.rota.hora
            let pedidoValido = this.pedido.irinijanelaentrega
                && this.pedido.irfimjanelaentrega
                && this.pedido.selecionado
                && this.pedido.duracaoseq
            if (pedidoValido && rotaValida) {
                var janelaIni = DateTime.fromFormat(this.pedido.irinijanelaentrega, this.format)
                var janelaFim = DateTime.fromFormat(this.pedido.irfimjanelaentrega, this.format)
                var rotaIni = DateTime.fromFormat(`${this.rota.data} ${this.rota.hora}:00`, this.format2)
                let estimativa = rotaIni.toSeconds() + this.pedido.duracaoseq
                if (estimativa < janelaIni.toSeconds() || estimativa > janelaFim.toSeconds()) {
                    classeAlerta = 'vermelho'
                } else {
                    let tempo = janelaFim.toSeconds() - estimativa
                    if (tempo >= 0 && tempo < (30 * 60)) {
                        classeAlerta = 'amarelo'
                    } else {
                        classeAlerta = 'verde-selecionado'
                    }
                }
            }
            return classeAlerta
        },

        corBorda() {
            let tipo = ''
            if (this.ehTipoParada) {
                tipo = 'verde'
            } else if (this.defineAlertaJanela) {
                tipo = this.defineAlertaJanela
            } else if (this.pedido.selecionado) {
                tipo = 'selecionado'
            }
            return 'borda-' + tipo
        },

        corFundo() {
            let tipo = ''
            if (this.ehTipoParada) {
                tipo = 'verde'
                if (this.pedido.selecionado) tipo += '-parada'
            } else if (this.defineAlertaJanela) {
                tipo = this.defineAlertaJanela
            } else if (this.pedido.selecionado) {
                tipo = 'selecionado'
            }
            return 'fundo-' + tipo
        },

        marcadores() {
            return criaMarcadores(Object.values(this.pedido.marcadores))
        },

        temMarcador() {
            return Object.values(this.pedido.marcadores).length
        }
    }
})
</script>

<style lang="scss" scoped>
.cardPedido {
    // display: inline-flex;
    width: 100%;
    cursor: move;
    overflow-y: auto;
    overflow-x: hidden;

    .divisa {
        border-top: 1px solid #ccc;

        * {
            font-size: 12px;
        }
    }

    .selecionado {
        background-color: rgba(66, 134, 244, 0.5);
    }

    .divCheckbox {
        border-right: 1px solid #ccc;
        padding: 5px 5px 5px 10px;
        align-content: center;
        cursor: pointer;
        max-width: 35px;
        width: 10%;

        &:hover {
            border-right: 1px solid cornflowerblue;
            background-color: rgba(100, 149, 237, 0.2);
        }

        label {
            &::before {
                width: 18px !important;
                height: 18px !important;
                cursor: pointer;
            }

            &::after {
                width: 18px !important;
                height: 18px !important;
                cursor: pointer;
            }
        }

        .custom-control {
            z-index: unset !important;
        }
    }

    .divInfo {
        align-content: center;
        padding: 5px;
        cursor: ns-resize;
        width: 100%;
    }

    .indicadorOrdem {
        font-size: 14px;
        padding: 0 !important;
        max-width: 35px;
        height: 18px;
    }

    .nomePedido {
        height: 18px;
        position: absolute;
    }

    .inputTempoParada {
        height: 20px;
        padding-top: 1px;
        align-items: normal;
        margin: 1px;
        border-radius: 3px;
        border: 1px solid #aaa;
        opacity: 0.8;
    }

    .divJanelaEMarcador {
        /*justify-content: space-between;*/
        padding: 2px 5px 0 5px !important;

        .spanJanelaEntrega {
            font-size: 12px !important;
            padding: 3px 7px 0px 7px;
            background-color: rgba(255, 165, 0, 0.6);
            border-radius: 5px;
            margin: 0 0.2rem 0.3rem 0;
        }

        .tagMarcador {
            padding: 3px 7px 0px 7px;
            border-radius: 4px;
            border: none;
            font-size: 11px !important;
            color: black;
            margin: 0 0.2rem 0.3rem 0;
        }
    }

    .btnAcaoTempoParado {
        padding: 0 !important;
    }

    .grupoInfoPedido {
        border-left: 1px solid #ccc;
    }
}

// Estas classes precisam ficar aqui, com estilo definido como scoped.
// Isso por que o pedido fica com a for de funco definida nestas classes
// e perdem a classe selecionado, a qual deixa cor de funco azul
.borda-verde-selecionado {
    border-color: #4fb15d !important;
}

.borda-verde {
    border-color: #4fb15d !important;
}

.borda-amarelo {
    border-color: #d2b900 !important;
}

.borda-vermelho {
    border-color: #c78383 !important;
}

.borda-selecionado {
    border-color: #7cafff !important;
}

.fundo-verde-parada {
    background-color: rgba(0, 196, 169, 0.3) !important;
}

.fundo-verde-selecionado {
    border: 2px solid rgba(0, 152, 20, 0.7) !important;
    background-color: rgba(0, 152, 20, 0.2) !important;
}

.fundo-verde {
    background-color: rgba(18, 161, 37, 0.2) !important;
}

.fundo-amarelo {
    border: 2px solid #d2b900 !important;
    background-color: rgba(255, 195, 0, 0.2) !important;
}

.fundo-vermelho {
    border: 2px solid rgba(255, 0, 0, 0.7) !important;
    background-color: rgba(255, 0, 0, 0.2) !important;
}

.fundo-selecionado {
    border: 2px solid rgba(66, 134, 244, 0.7) !important;
    background-color: rgba(66, 134, 244, 0.2) !important;
}
</style>