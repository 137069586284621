<template>
    <modalEagle :id="idModal" :title="'Gerar banco de horas'" size="xl">
        <template slot="modalBody">
            <b-overlay :show="loading">
                <div class="col-sm-12 row mb-3">
                    <div class="col-sm-2 nopadding">
                        <inputMonth
                            :inputDisabled="true"
                            :value="mesReferenciaInit"
                            @changeInput="changeMesReferencia"
                            label="Mês de referência*"
                            name="inputMesReferencia"
                        >
                        </inputMonth>
                    </div>
                    <div class="col-sm-4 nopadding">
                        <inputRange
                            name="inputIntervalo"
                            ref="refInputIntervalo"
                            :isObrigatorio="false"
                            @changeInput="changeIntervalo"
                            @closeCalendar="validaFechamentoFuncionariosSelecionados"
                        />
                    </div>
                </div>
                <div class="col-sm-12 justpaddingbottom row">
                    <div class="col-sm-5">
                        <inputSimple
                            name="Colaboradores"
                            label="Colaboradores"
                            placeholder="Buscar"
                            type="text"
                            @changeInput="buscaColaboradoresModalInput"
                        />
                        <div class="divColaboradoresBanco">
                            <div
                                v-for="(colaborador, key) in filtroNaoSelecionados"
                                :key="key"
                                class="tdColaboradoresBanco col-sm-12"
                                @click="adicionarColabsSelecionados(colaborador)"
                            >
                                <div class="description">{{ colaborador.description }}</div>
                                <div
                                    style="width: 100%"
                                    v-show="colaborador.periodoFechamento"
                                    title="Após realizar fechamento não é possível alterar jornada dos colaboradores"
                                    class="fechamento "
                                >
                                    Possui fechamento de: {{ colaborador.periodoFechamento }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-2 row nopadding botoesModalBanco">
                        <simpleButton
                            class="col-sm-12 buttonModalBanco"
                            @click="mudaArrayModal('adiciona')"
                            text="Adicionar todos"
                            :icon="mdiChevronRight"
                            event="click"
                            :disabled="this.colabs.naoSelecionados.length == 0"
                            type="blue"
                        >
                        </simpleButton>
                        <simpleButton
                            class="col-sm-12 buttonModalBanco"
                            :icon="mdiChevronLeft"
                            @click="mudaArrayModal('remove')"
                            text="Remover todos"
                            event="click"
                            :disabled="this.colabs.selecionados.length == 0"
                            type="blue"
                        >
                        </simpleButton>
                    </div>
                    <div class="col-sm-5">
                        <inputSimple
                            name="Selecionados"
                            label="Selecionados"
                            placeholder="Buscar"
                            type="text"
                            @changeInput="buscaSelecionadosModalInput"
                        />
                        <div class="divColaboradoresBanco">
                            <div
                                v-for="(item, j) in filtroSelecionados"
                                :key="j"
                                @click="removerColabsSelecionados(item.value)"
                                class="tdColaboradoresBanco col-sm-12"
                            >
                                {{ item.description }}
                            </div>
                        </div>
                    </div>
                </div>
            </b-overlay>
        </template>
        <template>
            <span v-if="mensagemMBH" class="informacaoModalBancoHoras">
                <h6>O banco de horas do mês foi gerado com sucesso.</h6>
                <h6>
                    Para imprimi-lo, acesse o relatório de
                    <span
                        class="linkModalBancoHoras"
                        event="click"
                        @click="acessarRelatorioBancoHoras"
                    >
                        Banco de Horas!
                    </span>
                </h6>
            </span>
            <simpleButton
                :icon="mdiCloseThick"
                text="Cancelar"
                event="click"
                type="red"
                @click="cancelButtonModalBancoHoras"
            >
            </simpleButton>
            <loadingButton
                class="loadingButtonBancoHoras"
                :icon="mdiCheckBold"
                text="Gerar"
                event="click"
                type="blue"
                :isLoading="carregandoMBH"
                :disabled="this.filtroSelecionados.length == 0 || mensagemMBH"
                @click="confirmButtonModalBancoHoras"
            >
            </loadingButton>
        </template>
    </modalEagle>
</template>
<script>
import Vue from "vue";
import { mdiChevronLeft, mdiChevronRight, mdiCheckBold, mdiCloseThick } from "@mdi/js";
import { HttpRequest } from "../../../Services/auth/HttpRequest.Service";
import { validaUltimoFechamento } from "@/Services/Helpers/FechamentoHelper";
import { conectionError } from "@/Services/Helpers/swellHeper";
import { DateTime } from "luxon";
import moment from "moment";
export default Vue.extend({
    name: "ModalBancoHoras",
    components: {
        simpleButton: require("@/components/Atom/Buttons/SimpleButton").default,
        loadingButton: require("@/components/Atom/Buttons/LoadingButton").default,
        inputSimple: require("@/components/Atom/Inputs/InputSimple").default,
        modalEagle: require("@/components/Atom/Modal/ModalEagle.vue").default,
        inputMonth: require("@/components/Atom/Datas/InputMonth").default,
        inputRange: require("@/components/Atom/Datas/InputRangeWithLimit").default,
    },

    props: {
        idModal: {
            type: [Number, String],
            required: true,
        },
    },

    data() {
        return {
            mdiChevronRight: mdiChevronRight,
            mdiChevronLeft: mdiChevronLeft,
            mdiCloseThick: mdiCloseThick,
            mdiCheckBold: mdiCheckBold,
            codigosColaboradores: [],
            carregandoMBH: false,
            mensagemMBH: false,
            loading: false,
            colabs: {
                array: [],
                selecionados: [],
                naoSelecionados: [],
            },
            filtroSelecionados: [],
            filtroNaoSelecionados: [],
            mesReferenciaInit: null,
            mesReferencia: "",
            intervalo: "",
        };
    },

    methods: {
        /**
         * @description Essa função deve ser utilizada por uma referência para
         * "ativar" essa modal, os argumentos da função são os necessários
         * para conseguir popular a modal com as infomações corretas
         * @param {array}  colabsArray selecionado no relatório
         * @param {array}  selecionados selecionado no relatório
         * @param {string} data selecionado no relatório
         * @author Vitor Hugo 🐨
         */
        preparaModalBancoHoras(colabsArray, selecionados, data = "") {
            this.codigosColaboradores = [];
            this.colabs.array = [];
            this.colabs.array = colabsArray.slice();
            this.codigosColaboradores = selecionados.slice();
            this.intervalo = DateTime.now().toFormat("dd/LL/yyyy");
            this.modalBanco();
        },

        buscaSelecionadosModalInput(text) {
            this.filtroSelecionados = this.colabs.selecionados.filter((e) => {
                return e.description.toUpperCase().includes(text.toUpperCase());
            });
        },

        buscaColaboradoresModalInput(text) {
            this.filtroNaoSelecionados = this.colabs.naoSelecionados.filter((e) => {
                return e.description.toUpperCase().includes(text.toUpperCase());
            });
        },

        modalBanco() {
            this.mensagemMBH = false;
            this.validaDataFechamento();
            let colabs = this.colabs.array;
            let selecionados = [];
            let naoSelecionados = [];
            for (let i in colabs) {
                const e = colabs[i];
                if (this.codigosColaboradores.includes(e.value) && !e.fechamento) {
                    selecionados.push(e);
                } else {
                    naoSelecionados.push(e);
                }
            }
            this.colabs.selecionados = selecionados;
            this.colabs.naoSelecionados = naoSelecionados;
            this.filtroSelecionados = selecionados;
            this.filtroNaoSelecionados = naoSelecionados;
            this.$bvModal.show(this.idModal);
        },

        changeMesReferencia(value) {
            this.mesReferencia = value.format("MM/YYYY");
        },

        changeIntervalo(value) {
            this.intervalo = value;
            this.validaDataFechamento();
            if (value) {
                const regex = /(\d{2})\/(\d{4})\b/;
                const match = value.match(regex);
                if (match) {
                    let dataMoment = moment(match[0], "MM/YYYY");
                    this.mesReferenciaInit = dataMoment;
                    this.changeMesReferencia(dataMoment);
                }
            }
        },

        /**
         * Pega o array principal de colaboradores
         * e atribui neles o periodo fechamento 
        */
        validaDataFechamento() {
            const funcionarios = this.colabs.array;
            const data = this.intervalo;
            for (const key in funcionarios) {
                let possuiFechamento = validaUltimoFechamento(funcionarios[key], data);
                funcionarios[key].fechamento = possuiFechamento[0] ? possuiFechamento[0] : null;
                funcionarios[key].periodoFechamento = possuiFechamento[1]
                    ? possuiFechamento[1]
                    : null;
            }
        },

        /**
         * Método para adicionar na array selecionados os valores que o usuário
         * selecionaro for percorre a array e quando o codigo da array for o
         * mesmo que o recebido na função coloca os valores na array
         * selecionados e retira da colaboradores
         */
        adicionarColabsSelecionados(funcionario) {
            if (funcionario.fechamento) return;
            let selecionados = this.colabs.selecionados.slice();
            let naoSelecionados = this.colabs.naoSelecionados.slice();
            for (let i in naoSelecionados) {
                const e = naoSelecionados[i];
                if (e.value == funcionario.value) {
                    selecionados.push(e);
                    naoSelecionados.splice(i, 1);
                    break;
                }
            }
            this.colabs.selecionados = selecionados;
            this.colabs.naoSelecionados = naoSelecionados;
            this.filtroSelecionados = selecionados;
            this.filtroNaoSelecionados = naoSelecionados;
            this.ordenaSelecionados()
            this.ordenaNaoSelecionados()
        },

        /**
         * Método para adicionar na array colaboradores os valores que o
         * usuário selecionar segue a mesma lógica da função de cima, só que o
         * inverso nas arrays
        */
        removerColabsSelecionados(codigo) {
            let selecionados = this.colabs.selecionados.slice();
            let naoSelecionados = this.colabs.naoSelecionados.slice();
            for (let i in selecionados) {
                const e = selecionados[i];
                if (e.value == codigo) {
                    naoSelecionados.push(e);
                    selecionados.splice(i, 1);
                    break;
                }
            }
            this.colabs.selecionados = selecionados;
            this.colabs.naoSelecionados = naoSelecionados;
            this.filtroSelecionados = selecionados;
            this.filtroNaoSelecionados = naoSelecionados;
            this.ordenaSelecionados()
            this.ordenaNaoSelecionados()
        },

        /**
         * Valida se, ao trocar o intervalo, os funcionarios
         * possuem um fechamento dentro da nova data.
         * Aqueles que possuirem um fechamento são 
         * filtrados e juntados ao array de funcionarios não 
         * selecionados. Depois os funcionarios selecionados que 
         * possuem fechamento são limpados do array filtroSelecionados,
         * assim ficando somente os funcionarios que não possuem fechamento
         * no array. 
         * @author Marcos 😎
        */
        validaFechamentoFuncionariosSelecionados() {
            if(!this.filtroSelecionados.length) return
            let funcionariosComFechamento = this.filtroSelecionados.filter((funcionario) => {
                return funcionario.fechamento
            })
            this.filtroNaoSelecionados = this.filtroNaoSelecionados.concat(funcionariosComFechamento)
            this.colabs.naoSelecionados = this.filtroNaoSelecionados

            this.filtroSelecionados = this.filtroSelecionados.filter((funcionario) => {
                return !funcionario.fechamento;
            });
            this.colabs.selecionados = this.filtroSelecionados;
            this.ordenaNaoSelecionados()
        },

        filtraFuncionariosSelecionadosPorFechamento() {
            let funcionariosSemFechamento = this.colabs.selecionados.filter((funcionario, indice) => {
                return !funcionario.fechamento;
            });
            let funcionariosComFechamento = this.colabs.selecionados.filter((funcionario, indice) => {
                return funcionario.fechamento;
            });
            return { funcionariosSemFechamento, funcionariosComFechamento }
        },

        /**
         * Método para mudar todos os valores de array
         */
        mudaArrayModal(dado) {
            if (dado == "adiciona") {
                this.atribuiFuncionariosSelecionados()
            } else {
                this.colabs.naoSelecionados = this.colabs.array.slice();
                this.colabs.selecionados = [];
                this.filtroSelecionados = [];
                this.filtroNaoSelecionados = this.colabs.naoSelecionados;
            }
        },

        atribuiFuncionariosSelecionados() {
            const { funcionariosSemFechamento, funcionariosComFechamento } =
                    this.filtraFuncionariosPorFechamento();
                this.colabs.selecionados = funcionariosSemFechamento;
                this.filtroSelecionados = funcionariosSemFechamento;
                this.colabs.naoSelecionados = funcionariosComFechamento;
                this.filtroNaoSelecionados = funcionariosComFechamento;
                this.ordenaNaoSelecionados()
        },

        filtraFuncionariosPorFechamento() {
            let funcionariosSemFechamento = this.getFuncionariosSemFechamento();
            let funcionariosComFechamento = this.getFuncionariosComFechamento();
            return { funcionariosSemFechamento, funcionariosComFechamento };
        },

        /**
         * refaz a ordenação por nome do colaborador
         * usando a lib do lodash :3 
        */
        ordenaNaoSelecionados() {
            this.filtroNaoSelecionados =
                 _.orderBy(this.filtroNaoSelecionados, ['description'], ['asc'])
            this.colabs.naoSelecionados =
                 _.orderBy(this.colabs.naoSelecionados, ['description'], ['asc'])
        },

        ordenaSelecionados() {
            this.filtroSelecionados =
                 _.orderBy(this.filtroSelecionados, ['description'], ['asc'])
            this.colabs.selecionados =
                 _.orderBy(this.colabs.selecionados, ['description'], ['asc'])
        },

        getFuncionariosSemFechamento() {
            let array = this.colabs.array.filter((funcionario, indice) => {
                return !funcionario.fechamento;
            });
            return array;
        },

        getFuncionariosComFechamento() {
            let array = this.colabs.array.filter((funcionario, indice) => {
                return funcionario.fechamento;
            });
            return array;
        },

        /**
         * Método gera relatório Banco de Horas
         */
        async confirmButtonModalBancoHoras() {
            this.loading = true;
            this.carregandoMBH = true;
            let id = this.colabs.selecionados.map((e) => e.value);
            let params = {
                origem: "FP",
                id: id,
                referencia: this.mesReferencia,
                intervalo: this.intervalo,
            };
            let url = "/controle/jornada/relatorios/folha/ponto/gerarBancoHoras";
            await new HttpRequest()
                .Post(url, params)
                .then(({ data, status }) => {
                    this.mensagemMBH = status && data.codigo == 200;
                })
                .catch(() => {
                    conectionError();
                })
                .finally(() => {
                    this.carregandoMBH = false;
                    this.loading = false;
                });
        },

        acessarRelatorioBancoHoras() {
            this.$router.push({ name: "BancoHoras" });
        },

        cancelButtonModalBancoHoras() {
            this.$bvModal.hide(this.idModal);
        },
    },
});
</script>

<style lang="scss">
//Modal Banco Horas
#modalBanco {
    .fechamento {
        color: red;
        font-size: 0.9em;
    }
    .divColaboradoresBanco {
        min-height: 340px;
        max-height: 340px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        margin-left: 15px !important;
        margin-right: 15px !important;
        border: 1px solid #dcdbdb !important;
        overflow: scroll;
        text-overflow: clip;
        .tdColaboradoresBanco:hover {
            cursor: pointer !important;
            background: #f3efef;
        }
        .tdColaboradoresBanco {
            padding: 5px;
            display: flex; 
            width: 100%;
            border: none !important;
        }
    }

    .botoesModalBanco {
        margin-top: 130px;
        height: 180px;
    }
    div {
        .buttonModalBanco {
            height: 45px;
            width: 10px !important;
        }
    }

    .informacaoModalBancoHoras {
        margin-right: 120px !important;
        text-align: center !important;
    }
    .linkModalBancoHoras {
        color: #6ba3d3;
    }
    .linkModalBancoHoras:hover {
        cursor: pointer !important;
    }
    .loadingButtonBancoHoras {
        margin-bottom: 0px !important;
    }
}
</style>
