/* eslint-disable  */
import axios from "axios";
import { Token, AuthService } from "./Auth.Service";
import { RouterService } from "../route/Route.Service";

export class HttpRequest {
    URLBase = process.env.VUE_APP_ROOT_API;

    private Config(param?: string | Object) {
        var service = new AuthService();
        if (service.isAuthenticated()) {
            var Token: Token = service.getToken()!;
            var strBearer = `Bearer ${Token.token || ""}`;
            var header = {
                params: param,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: strBearer,
                },
            };
            return header;
        } else {
            new RouterService().Navigate("/login");
        }
    }

    /**
        * Efetua um Get com a "URLBase" + url e retorna a requisição. utilizar await. 
        @example await Get("/request/example",null)
        @example /request/example
        @param url:string
        @param param?:string | Object
        @returns -{status:boolean,data?:Retorno,code:requestCode,message?:string}
        @author Daniel Bruch
    */
    public async Get(url: string, param?: string | Object) {
        return axios
            .get(this.URLBase + url, this.Config(param))
            .then(async function (result) {
                if (result.data) {
                    return { status: true, data: result.data, code: result.status };
                }
                return {
                    status: false,
                    data: result.data,
                    code: result.status,
                    message: "Return is Undefined",
                };
            })
            .catch(function (error) {
                return { status: false, data: null, code: 500, message: error.message };
            });
    }
    /**
        * Efetua um Post com a "URLBase" + url e retorna a requisição. utilizar await. 
        @example await Post("/request/example",null)
        @example /request/example
        @param url:string
        @param param?:string | Object
		@param {number} timeout - tempo até o axios desistir da req
        @returns -{status:boolean,data?:Retorno,code:requestCode,message?:string}
        @author Daniel Bruch
    */
    public async Post(url: string, param?: string | Object, timeout?: number) {
        return axios
            .post(this.URLBase + url, param, { ...this.Config(), timeout: timeout })
            .then(async function (result) {
                if (result.data) {
                    return { status: true, data: result.data, code: result.status };
                }
                return {
                    status: false,
                    data: result.data,
                    code: result.status,
                    message: "Return is Undefined",
                };
            })
            .catch(function (error) {
                return { status: false, data: null, code: 500, message: error.message };
            });
    }
    /**
        * Efetua um Delete com a "URLBase" + url e retorna a requisição. utilizar await. 
        @example await Delete("/request/example",null)
        @example /request/example
        @param url:string
        @param param?:string | Object
        @returns -{status:boolean,data?:Retorno,code:requestCode,message?:string}
        @author Daniel Bruch
*/
    public async Delete(url: string, param?: string | Object) {
        return axios
            .delete(this.URLBase + url, this.Config(param))
            .then(async function (result) {
                if (result.data) {
                    return { status: true, data: result.data, code: result.status };
                }
                return {
                    status: false,
                    data: result.data,
                    code: result.status,
                    message: "Return is Undefined",
                };
            })
            .catch(function (error) {
                return {
                    status: false,
                    data: null,
                    code: error.status,
                    message: error.message,
                };
            });
    }

    /**
        * Efetua um Put com a "URLBase" + url e retorna a requisição. utilizar await. 
        @example await Put("/request/example",null)
        @example /request/example
        @param url:string
        @param param?:string | Object
        @returns -{status:boolean,data?:Retorno,code:requestCode,message?:string}
        @author Daniel Bruch
*/
    public async Put(url: string, param?: string | Object) {
        return axios
            .put(this.URLBase + url, param, this.Config())
            .then(async function (result) {
                if (result.data) {
                    return { status: true, data: result.data, code: result.status };
                }
                return {
                    status: false,
                    data: result.data,
                    code: result.status,
                    message: "Return is Undefined",
                };
            })
            .catch(function (error) {
                return {
                    status: false,
                    data: null,
                    code: error.status,
                    message: error.message,
                };
            });
    }

    /**
     * @description procura pelas permissões do usuário no
     * back-end, tem um timeout de 30 segundos para evitar
     * esperas muito longas.
     * @returns permissões do usuário para o mapa e latlng
     * da empresa principal associada ao usuário.
     * @author Gui 🍺
     */
    public async GetPermissions() {
        var uri = `/finder/mapa/permissao/usuario`;
        return axios
            .get(this.URLBase + uri, {
                ...this.Config(),
                timeout: 45666,
            })
            .then((data) => {
                return data;
            })
            .catch((error) => {
                throw error;
            });
    }
}
