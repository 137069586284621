export class RouteModel{
    routes!:Route[];
    modulos!:Modulos[]
}
export class Route{
    id!:string;
    titulo!:string
    menu!: Menu[]
    icon!:string;
}


export class Menu{
    titulo!:string;
    icon!:string;
    itens!:MenuItem[]
}

export class MenuItem{
    titulo!:string;
    icon!:string;
    route!:string;
    permissoes!:Permissions;
}


export class Permissions{
    visualizar?:boolean
    cadastrar?:boolean
    editar?:boolean
    excluir?:boolean
    master?:string
    importar?:boolean
}

export class Modulos{
    route?:String
}
