<template>
    <panelEagle id='Modulos' :loading='salvando'>
            <div class="col-12 row">
                <div class="col-sm-6">
                    <tituloPage :icon='mdiTools' titulo='Tipo de Serviço'></tituloPage>
                </div>
                <div class="col-sm-6">
                    <basicButtonsCadastrosSC
                        @salvarCadastro='salvarCadastro'
                        :disabled='$v.$invalid'
                        tipoCancela='button'
                        linkCancel='tipoServico'/>
                </div>
            </div>
            <div class="col-sm-12 col-md-12"><hr></div>
            <div class='col-sm-12 row nopadding'>
                <div class="col-sm-4 nopadding">
                    <selectAll
                        :extraClass='{"border border-danger":$v.valueEmpresa.$anyError}'
                        @close='$v.valueEmpresa.$touch()'
                        nameForRadio  ='Selecione*'
                        :selected='preCliente'
                        :allowEmpty='false'
                        :isMultiple   ='false'
                        :optionsArray ='optEmpresas'
                        :labels       ='labelsEmp'
                        :hasSelectAll ='false'
                        @changeSelect ='changeEmpresa'/>
                </div>
                <div class="col-sm-4 nopadding">
                    <inputSimple
                        :inputClass='{"border border-danger":$v.valueInput.$anyError}'
                        @blur='$v.valueInput.$touch()'
                        label  ='Descrição*'
                        name   ='inputSimple'
                        :value ='valueInput'
                        @changeInput='changeInput'/>
                </div>
                <div class="col-sm-4 nopadding">
                    <span class="marg">Status</span>
                    <buttonsFilters
                        :arrayButtons='arrayButtonsFilters'
                        :onlyOneSelected='true'
                        @buttonsFiltersChange='buttonsFiltersChange'/>
                </div>
            </div>
            <requiredFields/>
            <div class="col-sm-12 col-md-12"><hr></div>
    </panelEagle>
</template>
<script>
import {mdiTools, mdiCheckBold } from '@mdi/js'
import Vue  from 'vue'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service.ts'
import { required } from 'vuelidate/lib/validators'
import { conectionError } from '@/Services/Helpers/swellHeper'
export default Vue.extend({
    name:'CadastroTiposDeServico',
    components:{
        requiredFields : require('@/components/Atom/Footer/RequiredFields.vue').default,
        'panelEagle'   : require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage'   : require('@/components/Atom/Header/Titulo').default,
        'SelectAll'    : require('@/components/Atom/Select/SelectAll').default,
        'inputSimple'  : require('@/components/Atom/Inputs/InputSimple').default,
        'buttonsFilters' : require('@/components/Atom/Buttons/ButtonsFilters').default,
        'basicButtonsCadastrosSC' : require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
    },
    validations:{
        valueInput   :{required},
        valueEmpresa :{required},
    },
    data(){
        return {
            mdiCheckBold:mdiCheckBold,
            mdiTools:mdiTools,
            preCliente: [],
            valueEmpresa : [],
            valueInput   : '',
            optEmpresas  : new EmpresasService().Get(),
            labelsEmp    : [{
                indexDFH    : 'EM',
                description : 'Empresa*'
            }],
            valueButtons : [],
            salvando:false,
            arrayButtonsFilters:[
                {
                    'value' : 'A',
                    'width' : '48%', 
                    'title' : 'Ativo', 
                    'text'  : 'Ativo',
                    'icon'  : '',
                    'isSelected' : true, 
                },
                {
                    'value' : 'I',
                    'width' : '48%', 
                    'title' : 'Inativo', 
                    'text'  : 'Inativo',
                    'icon'  : '',
                    'isSelected' : false, 
                },
            ],
            exportando:[false, false, false]
        }
    },
    methods:{
        salvarCadastro(){
            var obj = [
                {'mtsdescricao' : this.valueInput},
                {'mtscliente'   : this.valueEmpresa},
                {'mtsstatus'    : this.valueButtons}
            ]
            let uri
            this.salvando = true
            if(this.$route.params.id){
                uri ='/manutencoes/cadastros/tipo/servico/atualizar'  
                obj = {
                    mtscodigo    : this.$route.params.id,
                    mtsdescricao : this.valueInput,
                    mtsstatus    : this.valueButtons[0],
                    mtscliente   : this.valueEmpresa[0]
                }
            }else{
                uri ='/manutencoes/cadastros/tipo/servico/salvar' 
            }
            new HttpRequest()
            .Post(uri , {'obj':obj}).then((data)=>{
                this.salvando = false
                if(data.code == 201)
                    this.$router.push({name:'tipoServico'})
                else 
                    conectionError()
            })
        },
        changeInput(value){
            this.valueInput = value
        },
        async changeEmpresa(value){
            this.valueEmpresa = value
        },
        buttonsFiltersChange(value){
            this.valueButtons = value
            this.arrayButtonsFilters[0].icon = ''
            this.arrayButtonsFilters[1].icon = ''
            if(value[0] == 'A'){
                this.arrayButtonsFilters[0].icon = mdiCheckBold
            } else if(value[0] == 'I'){
                this.arrayButtonsFilters[1].icon = mdiCheckBold
            }
            this.changeEmpresa(this.valueEmpresa)
        },
    },
    async mounted(){
        if(this.$route.params.id){
            let uri = '/manutencoes/cadastros/tipo/servico/getServico'
            new HttpRequest().Post(uri, {id:this.$route.params.id})
            .then((data)=>{
                this.valueInput = data.data.servico.mtsdescricao
                this.preCliente = [data.data.cliente]
                if(data.data.servico.mtsstatus == "I"){
                    this.buttonsFiltersChange(["I"])
                    this.arrayButtonsFilters[1].isSelected = true
                    this.arrayButtonsFilters[0].isSelected = false
                }
            })
        }
        
    }
})
</script>
<style lang="scss" scoped>
    .camp{
        margin-top: 10px;
        margin-left: 45px;
        font-size: 12px!important;
    }
    .marg{
        margin-left: 17px;
    }
</style>
