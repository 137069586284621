<template>
	<panelEagle id="cadastroVeiculo" :loading="carregando">
		<div class="col-lg-12 nopadding row">
			<div class="col-lg-6">
				<titulo titulo="Cadastro de veículos" :icon="mdiCarMultiple" />
			</div>
			<div class="col-lg-6">
				<botoes :disabled="$v.formulario.$invalid || limiteVeiculosOk !== true" tipoCancela="button"
					linkCancel="listarVeiculo" @salvarCadastro="salvar()" />
			</div>
			<div class="col-sm-12">
				<hr />
			</div>
		</div>
		<b-tabs>
			<!-- CADASTRO -->
			<b-tab title="Cadastro">
				<div class="col-lg-12 row nopadding">
					<div class="col-lg-12 nopadding row">
						<div class="col-lg-4 parametros">
							<selectAll nameForRadio="selectProprietario" :isMultiple="false" :selected="proprieatarioSelecionado"
								:optionsArray="optSelectProprietario" :labels="labelProprietario" @changeSelect="mudaProprietario" />
						</div>
						<div class="col-lg-4 parametros">
							<selectAll nameForRadio="tipoVeiculo" :isMultiple="false" :optionsArray="optSelectTipoVeiculo"
								:labels="labelSeletorEmpresas" :selected="tipoSelecionado" @changeSelect="changeTipoCadastro" />
						</div>
						<div class="col-lg-4 extrapadding" v-if="formulario.vetipo == 'O'">
							<b-check v-model="formulario.veacessocadeirante">Este veículo tem acessibilidade para cadeirantes</b-check>
						</div>
						<div class="col-lg-4 extrapadding" style="color: red" v-if="limiteVeiculosOk === false">
							<span>Quantidade de veículos excedeu o limite do contrato!</span>
						</div>
						<div class="col-lg-4 extrapadding" style="color: grey" v-if="limiteVeiculosOk === null">
							<span>Verificando, aguarde...</span>
						</div>
					</div>
					<div class="col-lg-12 somepadding row">
						<div class="col-lg-3 somepadding">
							<!-- :mask="['lll-llll']" -->
							<inputS :disabled="!getMaster()" name="inputPlaca" id="inputPlaca" placeholder="Digite a placa"
								:value="formulario.veplaca" :hasMask="true" :mask="['lllqllll']" :inputClass="{
									'border border-danger':
										$v.formulario.veplaca.$anyError,
								}" label="Placa*" @blur="$v.formulario.veplaca.$touch()" @changeInput="mudaPlaca" />
							<div class="col-lg-12 nopadding">
								<span name="erroPlacaRepetida" class="erro" v-show="erroPlaca == 'existe'">
									Já existe um veículo com essa placa cadastrada!
								</span>
							</div>
						</div>

						<div class="col-lg-2 somepadding">
							<inputS name="inputPrefixo" placeholder="Digite o prefixo" label="Prefixo*" :inputClass="{
								'border border-danger':
									$v.formulario.veprefixo.$anyError,
							}" :value="formulario.veprefixo" @blur="$v.formulario.veprefixo.$touch()"
								@changeInput="(value) => (formulario.veprefixo = value)" />
						</div>
						<div class="col-lg-3 somepadding">
							<inputS name="inputChassi" label="Chassi" :value="formulario.vechassi" placeholder="Digite o chassi"
								@changeInput="(value) => (formulario.vechassi = value)" />
						</div>
						<div class="col-lg-3 somepadding">
							<inputS name="placaAntiga" v-if="ehEdicao == 'disabled'" inputDisabled="disabled" label="Placa Antiga"
								:key="formulario.veplacaantiga" :value="formulario.veplacaantiga" />
						</div>
					</div>
					<div class="col-lg-12 row somepadding">
						<div class="col-lg-3 somepadding">
							<inputS name="inputDescricao" label="Descrição*" :inputClass="{
								'border border-danger':
									$v.formulario.vedescricao.$anyError,
							}" :value="formulario.vedescricao" placeholder="Digite a descrição" @blur="$v.formulario.vedescricao.$touch()"
								@changeInput="(value) => (formulario.vedescricao = value)" />
						</div>
						<div class="col-lg-2 somepadding">
							<inputS name="inputVelMax" :hasMask="true" :mask="['NNN']" placeholder="Digite a máxima"
								label="Velocidade Máx*" :value="formulario.vevelocidademax"
								@changeInput="(v) => changeVelocidadeMaxima(v)" />
							<div v-show="$v.formulario.vevelocidademax.$invalid" class="mt-1">
								Velocidade máxima deve ser igual ou maior que 10
							</div>
						</div>
						<div class="col-lg-3 somepadding">
							<inputS name="inputHodrometro" label="Hodômetro atual" :inputDisabled="temModulo && !formPerfil.vpican ? '' : 'disabled'
								" :hasMask="true" :value="formModulo.vchodometro" :mask="['NNNNNN']"
								@changeInput="(value) => (formModulo.vchodometro = value)" />
						</div>
						<div class="col-lg-3 somepadding">
							<inputS name="inputHorimetro" :hasMask="true" :inputDisabled="temModulo && !formPerfil.vpican ? '' : 'disabled'
								" :mask="['NNNNNN']" label="Horímetro atual" :value="formModulo.vchorimetro"
								@changeInput="(value) => (formModulo.vchorimetro = value)" />
						</div>
					</div>
					<div class="col-lg-12 row somepadding">
						<div class="col-lg-2 somepadding">
							<selectAll :disabled="trancaModulo" nameForRadio="selectModulo" :isMultiple="false"
								:optionsArray="optSelectModulo" :selected="moduloSelecionado" :labels="labelSelectModulo"
								@changeSelect="mudaModulo" ref="selectModulo" />
							<div class="col-lg-12 somepadding">
								<span name="erroModuloOcupado" class="erro" v-show="erroModulo == 'existe'">
									Módulo já esta sendo utilizado
								</span>
							</div>
						</div>
						<div class="col-lg-2 somepadding">
							<selectAll :disabled="trancaModulo" nameForRadio="selectModulo" :isMultiple="false"
								:optionsArray="optSelectModulo" :selected="moduloSecundarioSelecionado" :labels="[
									{ indexDFH: 'NAM', description: 'Módulo secundario' },
								]" @changeSelect="(v) =>
								(formulario.vemodulosecundario = v[0]
									? v[0]
									: null)
									" ref="selectModulo" />
							<div class="col-lg-12 somepadding">
								<span name="erroModuloOcupado" class="erro" v-show="erroModulo == 'existe'">
									Módulo já esta sendo utilizado
								</span>
							</div>
						</div>
						<div class="col-lg-1 somepadding" v-if="isMaster && !desbloqueou">
							<buttonS type="blue" :icon="mdiSwapVerticalBold" extraClass="rotated" event="click"
								@click="$bvModal.show('modalTrocaModulo')" />
						</div>

						<div class="col-lg-3 somepadding">
							<inputSD name="inputDataInstalacao" label="Data de instalação" :value="formulario.vedatainstalacao"
								@changeInput="mudaDataInstalacao" />
						</div>
					</div>
					<div class="col-lg-12 row somepadding">
						<div class="col-lg-3 somepadding">
							<inputS name="inputIdLoramesh" placeholder="Digite o ID" label="ID dispositivo loramesh"
								:value="formulario.velorameshid" @changeInput="(value) => (formulario.velorameshid = value)
									" />
						</div>

						<div class="col-lg-3 somepadding">
							<inputS name="inputIdRede" label="ID rede loramesh" placeholder="Digite o ID da rede"
								:value="formulario.velorameshidrede" @changeInput="(value) => (formulario.velorameshidrede = value)
									" />
						</div>

						<div class="col-lg-3 somepadding">
							<selectTag name="inputTags" label="Adicionar tags" ref="selectTags" :selected="tagsSelecionadas"
								:optionsArray="optSelectTag" :isDisabled="proprieatarioSelect" :isLoading="loadingTag" @setTag="setTag"
								@createNewTag="criaTag" @excluiTag="excluiTag" @changeColor="salvaEdicao" @salvaDescricao="salvaEdicao" />
							<!-- {{ tagsSelecionadas }} -->
						</div>
					</div>
				</div>
			</b-tab>
			<b-tab title="Parâmetros">
				<div class="col-lg-12 nopadding row">
					<div class="col-lg-12 nopadding row">
						<div class="col-lg-6 parametros">
							<bfilter label="Disponível para Roterização" :arrayButtons="botoesModulos" @buttonsFiltersChange="(value) => (formulario.veroterizar = value[0])
								" />
						</div>
						<div class="col-lg-6 parametros">
							<bfilter label="Calcular média com base em" :arrayButtons="botoesMedio" @buttonsFiltersChange="(value) => (formulario.vebasecalculomedia = value[0])
								" />
						</div>
					</div>
					<div class="col-sm-12">
						<hr />
					</div>
					<div class="col-lg-12 row nopadding">
						<div class="col-lg-3 somepadding">
							<inputS name="inputAutonomia" label="Autonomia em Km" :hasMask="true" :mask="['NNNNN']"
								placeholder="Total de quilômetros que o veículo pode percorrer em um tanque"
								:value="formulario.veautonomia" @changeInput="(value) => (formulario.veautonomia = value)" />
						</div>
						<div class="col-lg-3 somepadding">
							<inputM name="inputCustoKm" label="Custo por Km" :value="valueCustoKm"
								@changeInput="(value) => (formulario.vecusto = value)" />
						</div>
						<div class="col-lg-3 somepadding">
							<inputS name="inputMaxHoras" label="Máx horas" type="time" :value="formulario.vemaxhoras"
								@changeInput="(value) => (formulario.vemaxhoras = value)" />
						</div>
						<div class="col-lg-3 nopadding b-check-jornada">
							<label for="checkFixo">Jornada</label>
							<b-check name="checkFixo" @input="checkPontoFixo" v-model="formulario.vepontofixo">
								Ponto fixo
							</b-check>
							<b-check name="checkAbreEFecha" v-model="formulario.vepontofixoabrefecha"
								:disabled="!formulario.vepontofixo">
								Abre e fecha
							</b-check>
						</div>
					</div>
					<div class="col-lg-12 row nopadding">
						<div class="col-lg-3 somepadding">
							<inputS name="inputCargaQ" :hasMask="true" :mask="['NNNNN']" label="Carga Quilos"
								placeholder="Carga total que o veículo pode carregar" :value="formulario.vemaxpeso"
								@changeInput="(value) => (formulario.vemaxpeso = value)" />
						</div>
						<div class="col-lg-3 somepadding">
							<inputS name="inputCubagem" :hasMask="true" :mask="['NNNNNNNN']" label="Cubagem"
								:value="formulario.vecubagem" placeholder="Cubagem total que cabe no veículo" @changeInput="(value) => (this.formulario.vecubagem = value)
									" />
						</div>
						<div class="col-lg-3 somepadding">
							<inputS name="inputMaxEntregas" :hasMask="true" :mask="['NNNNNNNN']"
								placeholder="Numero máximo de entregas por rota" label="Máx. Entregas" :value="formulario.vemaxentregas"
								@changeInput="(value) => (formulario.vemaxentregas = value)
									" />
						</div>
						<div class="col-lg-3 mt-1">
							<selectAll class="p-0" nameForRadio="SelectChecklist" :isMultiple="true" :optionsArray="selectChecklist"
								:selected="checklistSelecionado" :labels="[
									{
										indexDFH: 'Cklt',
										description: 'Checklist',
									},
								]" :disabled="!formulario.vetipo.length &&
	!formulario.veproprietario.length /*tipo do veiculo*/
	" :loading="loadingChecklist /*tipo do veiculo*/" @changeSelect="(v) => (this.valueChecklist = v)"
								ref="refselectTipoChecklist" />
							<!-- v-model='valueChecklist' -->
						</div>
						<div class="col-sm-12">
							<hr />
						</div>
						<div class="col-lg-12 row nopadding">
							<div class="titulo-parametros col-lg-12">
								Horário de Trabalho
							</div>
							<div class="col-lg-3 somepadding">
								<inputS type="time" name="inputHoraInicioTrabalho" label="Horário Inicio Trabalho"
									:value="formulario.vehorainiciotrabalho" @changeInput="(value) =>
										(formulario.vehorainiciotrabalho = value)
										" />
							</div>
							<div class="col-lg-3 somepadding">
								<inputS name="inputHoraFimTrabalho" type="time" label="Horario Fim Trabalho"
									:value="formulario.vehorafinaltrabalho" @changeInput="(value) =>
										(formulario.vehorafinaltrabalho = value)
										" />
							</div>
						</div>
						<div class="col-sm-12">
							<hr />
						</div>
						<div class="col-lg-12 row nopadding">
							<div class="col-sm-6 row nopadding">
								<div class="titulo-parametros col-lg-12">
									Faixa de Temperatura
								</div>
								<div class="col-lg-6 somepadding">
									<inputS name="inputTempMin" type="number" label="Temperatura Mínima"
										:value="formulario.vetemperaturaminima" @changeInput="(value) =>
											(formulario.vetemperaturaminima = value)
											" />
								</div>
								<div class="col-lg-6 somepadding">
									<inputS name="inputTempMax" type="number" label="Temperatura Máxima"
										:value="formulario.vetemperaturamaxima" @changeInput="(value) =>
											(formulario.vetemperaturamaxima = value)
											" />
								</div>
							</div>
							
							<div class="col-sm-6 row noppading nonmargin">
								<div class="titulo-parametros col-lg-12">
									Tipo de produto transportado
								</div>
								<div class="col-sm-7 noppading">
									<selectAll class="p-0" nameForRadio="selectTipoProduto" :isMultiple="true"
										:optionsArray="optSelectTipoProduto" :selected="tipoProdutoAssociadosSelecionados"
										:labels="labelSelectProdutoTransportado" :disabled="$v.formulario.veproprietario.$invalid"
										:loading="carregandoProdutos" ref="selectTipoProduto" @changeSelect="changeTipoProduto" />
								</div>
								<div class="col-sm-3">
									<buttonS text="Novo" type="blue" :icon="mdiPlus" extraClass="buttonS"
										:disabled="$v.formulario.veproprietario.$invalid" event="click"
										@click="$bvModal.show('modalCadastraTipoProduto')" />
								</div>
							</div>
						
						</div>

						<!--add-->
						<div class="col-lg-12 row nopadding">
							<div class="col-sm-6 row somepadding">
								<div class="titulo-parametros col-lg-12">
									Faixa de Umidade
								</div>
								<div class="col-lg-6 somepadding">
									<inputS name="inputUmidadeMin" type="number" label="Umidade Mínima"
										:value="formulario.veumidademin" @changeInput="(value) =>
											(formulario.veumidademin = value)
											" />
								</div>
								<div class="col-lg-6 somepadding">
									<inputS name="inputUmidadeMax" type="number" label="Umidade Máxima"
										:value="formulario.veumidademax" @changeInput="(value) =>
											(formulario.veumidademax = value)
											" />
								</div>
							</div>
							
							<div class="col-sm-6 row somepadding">
								<div class="titulo-parametros col-lg-12">
									Faixa de CO²
								</div>
								<div class="col-lg-6 somepadding">
									<inputS name="inputCO2Min" type="number" label="CO² Mínimo"
										:value="formulario.veco2min" @changeInput="(value) =>
											(formulario.veco2min = value)
											" />
								</div>
								<div class="col-lg-6 somepadding">
									<inputS name="inputCO2Max" type="number" label="CO² Máximo"
										:value="formulario.veco2max" @changeInput="(value) =>
											(formulario.veco2max = value)
											" />
								</div>
							</div>
						</div>
						<!--add-->




						<div class="col-sm-12">
							<hr />
						</div>
						<div class="titulo-parametros col-lg-12">Regiões</div>
						<div class="col-12 somepadding">
							<selectAll nameForRadio="selectRegioes" :isMultiple="true" :labels="labelSelectRegiao"
								:optionsArray="optSeletorRegioes" :selected="regioesSelecinada"
								@changeSelect="(value) => (formRegiao.vrregiao = value)" />
						</div>
						<div class="col-sm-12">
							<hr />
						</div>
						<div class="col-lg-12 row nopadding">
							<div class="titulo-parametros col-lg-12">Roterizar por</div>
							<div class="col-lg-4 somepadding">
								<bfilter label="Estradas de Terra" :arrayButtons="botoesTerra" v-model="formulario.veestradaterra"
									@buttonsFiltersChange="(value) => (formulario.veestradaterra = value[0])
										" />
							</div>
							<div class="col-lg-4 somepadding">
								<bfilter label="Balsas" :arrayButtons="botoesBalsas" @buttonsFiltersChange="(value) => (formulario.vebalsas = value[0])
									" />
							</div>
							<div class="col-lg-4 somepadding">
								<bfilter label="Pedagio" :arrayButtons="botoesPedagios" @buttonsFiltersChange="(value) => (formulario.vepedagios = value[0])
									" />
							</div>
						</div>
						<div class="col-sm-12">
							<hr />
						</div>
						<div class="col-lg-12 row nopadding">
							<div class="titulo-parametros col-lg-12">
								Parâmetros de consumo:
							</div>
							<div class="col-lg-4 somepadding">
								<selectAll nameForRadio="selectConversaoConsumo" :isMultiple="false" :labels="labelSelectConversaoConsumo"
									:optionsArray="optSeletorConversaoConsumo" :selected="consumoSelecinado" @changeSelect="(value) =>
										(conversaoConsumo.fcodescricao = value[0])
										" />
							</div>
							<div class="col-lg-4 somepadding">
								<inputS name="tamanhoTanque" type="number" label="Capacidade tanque combustível:"
									:value="formulario.vecapacidadetanque" @changeInput="(value) => (formulario.vecapacidadetanque = value)
										" />
							</div>
							<div class="col-lg-4 somepadding">
								<selectAll nameForRadio="selectRegioes" :isMultiple="false" :labels="labelSelectConversaoCapacidade"
									:optionsArray="optSeletorCapacidadeCombustivel" :selected="capacidadeSelecinada" @changeSelect="(value) =>
									(capacidadeCombustivel.fcadescricao =
										value[0])
										" />
							</div>
						</div>
					</div>
				</div>
			</b-tab>

			<b-tab title="Integração">
				<div class="col-lg-12 nopadding row">
					<div class="col-lg-4 parametros">
						<inputS :hasMask="true" :mask="['NNNNNNNNNN']" name="inputCodigoBem" label="Código bem (TOTVS)"
							placeholder="Digite o código do bem" :value="formulario.vebem"
							@changeInput="(value) => (formulario.vebem = value)" />
					</div>
					<div class="col-lg-4 parametros">
						<inputS :hasMask="true" :mask="['NNNNNNNNNN']" name="inputFamiliaTo" label="Familia (TOTVS)"
							placeholder="Digite o código da familia" :value="formulario.vefamilia"
							@changeInput="(value) => (formulario.vefamilia = value)" />
					</div>
					<div class="col-lg-4 parametros">
						<inputS :hasMask="true" :mask="['NNNNNNNNNN']" name="inputInterfoc" label="Código interfoc"
							placeholder="Digite o código do interfoc" :value="formulario.vecodigointerfoc" @changeInput="(value) => (formulario.vecodigointerfoc = value)
								" />
					</div>
				</div>
			</b-tab>

			<!-- CONFIGURAÇÕES -->
			<b-tab v-if="isMaster" title="Configuração" :active="ativaTabConfig">
				<div class="col-lg-12 row">
					<div class="titulo col-lg-12 row">Entradas</div>
					<div class="col-sm-12 row subtituloConfig">
						<div class="col-6 nopadding row">
							<b-check @change="mudaCheckBau" v-model="tickSensorBau">
								Sensor do <b>baú</b>
							</b-check>
						</div>
						<div class="col-6 nopadding row">
							<b-form-radio-group :disabled="!tickSensorBau" :options="optionsSensorBau" name="checkbox-bau"
								v-model="formPerfil.vpibau" />
						</div>
					</div>

					<div class="col-sm-12 row subtituloConfig">
						<div class="col-6 nopadding row">
							<b-check v-model="tickSensorBetoneira" @change="mudaCheckBetoneira">
								Sensor da <b>betoneira</b>
							</b-check>
						</div>
						<div class="col-6 nopadding row">
							<b-form-radio-group :disabled="!tickSensorBetoneira" :options="optionsSensorBetoneira"
								name="checkbox-betoneira" v-model="formPerfil.vpibetoneira" />
						</div>
					</div>

					<div class="col-sm-12 row subtituloConfig">
						<div class="col-6 nopadding row">
							<b-check v-model="tickSensorPortaMotorista" @change="mudaCheckPortaMotorista">
								Sensor da <b>porta do motorista</b>
							</b-check>
						</div>
						<div class="col-6 nopadding row">
							<b-form-radio-group :disabled="!tickSensorPortaMotorista" :options="optionsSensorPortaMotorista"
								name="checkbox-porta-motorista" v-model="formPerfil.vpiportamotorista" />
						</div>
					</div>
					<div class="col-sm-12 row subtituloConfig">
						<div class="col-6 nopadding row">
							<b-check v-model="tickSensorPortaAjudante" @change="mudaCheckPortaAjudante">
								Sensor da <b>porta do ajudante</b>
							</b-check>
						</div>
						<div class="col-6 nopadding row">
							<b-form-radio-group :disabled="!tickSensorPortaAjudante" :options="optionsSensorPortaAjudante"
								name="checkbox-porta-ajudante" v-model="formPerfil.vpiportaajudante" />
						</div>
					</div>

					<div class="col-sm-12 row subtituloConfig">
						<div class="col-6 nopadding row">
							<b-check v-model="tickSensorPainel" @change="mudaCheckPainel">
								Sensor do <b>painel</b>
							</b-check>
						</div>
						<div class="col-6 nopadding row">
							<b-form-radio-group :disabled="!tickSensorPainel" :options="optionsSensorPainel" name="checkbox-painel"
								v-model="formPerfil.vpipainel" />
						</div>
					</div>

					<div class="col-sm-12 row subtituloConfig">
						<div class="col-6 nopadding row">
							<b-check v-model="tickSensorPanico" @change="mudaCheckPanico">
								Sensor de <b>pânico</b>
							</b-check>
						</div>
						<div class="col-6 nopadding row">
							<b-form-radio-group :disabled="!tickSensorPanico" :options="optionsSensorPanico" name="checkbox-panico"
								v-model="formPerfil.vpipanico" />
						</div>
					</div>

					<div class="col-sm-12 row subtituloConfig">
						<div class="col-6 nopadding row">
							<b-check v-model="tickSensorDesengate" @change="mudaCheckDesengate">
								Sensor de <b>desengate</b>
							</b-check>
						</div>
						<div class="col-6 nopadding row">
							<b-form-radio-group :disabled="!tickSensorDesengate" :options="optionsSensorDesengate"
								name="checkbox-desengate" v-model="formPerfil.vpidesengate" />
						</div>
					</div>

					<div class="col-sm-12 row subtituloConfig">
						<div class="col-6 nopadding row">
							<b-check v-model="tickSensorTemp" @change="mudaCheckTemperatura">
								Sensor de <b>temperatura</b>
							</b-check>
						</div>
						<div class="col-6 nopadding row">
							<b-form-radio-group name="checkbox-temperatura" :disabled="!tickSensorTemp" :options="optionsSensorTemp"
								v-model="formPerfil.vpitemperatura" />
						</div>
					</div>
					
					<div class="col-sm-12 row subtituloConfig">
						<div class="col-6 nopadding row">
							<b-check v-model="tickSensorCaracol" @change="mudaCheckCaracol">
								Sensor do <b>caracol</b>
							</b-check>
						</div>
						<div class="col-6 nopadding row">
							<b-form-radio-group name="checkbox-caracol" :disabled="!tickSensorCaracol" :options="optionsSensorCaracol"
								v-model="formPerfil.vpicaracol" />
						</div>
					</div>

					<div class="col-sm-12 row subtituloConfig">
						<div class="col-6 nopadding row">
							<b-check v-model="tickSensorPneumatico" @change="mudaCheckPneumatico">
								Sensor <b>pneumático</b>
							</b-check>
						</div>
						<div class="col-6 nopadding row">
							<b-form-radio-group name="checkbox-pneumatico" :disabled="!tickSensorPneumatico" :options="optionsSensorPneumatico"
								v-model="formPerfil.vpipneumatico" />
						</div>
					</div>

					<div class="col-sm-12 row subtituloConfig">
						<div class="col-6 nopadding row">
							<b-check v-model="tickSensorParabrisas" @change="mudaCheckParabrisas">
								Sensor do <b>para-brisas</b>
							</b-check>
						</div>
						<div class="col-6 nopadding row">
							<b-form-radio-group name="checkbox-parabrisas" :disabled="!tickSensorParabrisas" :options="optionsSensorParabrisas"
								v-model="formPerfil.vpiparabrisas" />
						</div>
					</div>








					<div class="col-lg-5 nopadding mt-3">
						<selectAll nameForRadio="seletorSensorTemperatura" :isMultiple="false" :labels="labelSelectSensorTemp"
							:selected="sensorTempSelecionado" :optionsArray="optSelectSensorTemp" @changeSelect="(value) =>
								(formPerfil.vpitiposensortemperatura = value[0])
								" />
					</div>

					<div class="titulo col-lg-12 row">Saídas / Atuadores</div>
					<div class="col-lg-12 subtituloConfig row">
						<div class="col-6 nopadding row">
							<b-check v-model="tickSaidaBloqueioIgnicao" @change="mudaCheckBloqueioIgnicao">
								Bloqueio ignicao
							</b-check>
						</div>
						<div class="col-6 nopadding row">
							<b-form-radio-group :disabled="!tickSaidaBloqueioIgnicao" :options="optionsSaidaBloqueioIgnicao"
								name="checkbox-bloqueio-ignicao" v-model="formPerfil.vpibloqueioignicao" />
						</div>
					</div>

					<div class="col-lg-12 subtituloConfig row">
						<div class="col-6 nopadding row">
							<b-check v-model="tickSaidaBloqueioComb" @change="mudaCheckBloqueioComb">
								Bloqueio combustível
							</b-check>
						</div>
						<div class="col-6 nopadding row">
							<b-form-radio-group :disabled="!tickSaidaBloqueioComb" :options="optionsSaidaBloqueioComb"
								name="checkbox-bloqueio-combustivel" v-model="formPerfil.vpibloqueiocombustivel" />
						</div>
					</div>

					<div class="col-sm-12 row subtituloConfig">
						<div class="col-6 nopadding row">
							<b-check v-model="tickSaidaSirene" @change="mudaCheckSirene">
								Sirene
							</b-check>
						</div>
						<div class="col-6 nopadding row">
							<b-form-radio-group :disabled="!tickSaidaSirene" :options="optionsSaidaSirene" name="checkbox-sirene"
								v-model="formPerfil.vpisirene" />
						</div>
					</div>

					<div class="col-lg-12 subtituloConfig row">
						<div class="col-6 nopadding row">
							<b-check v-model="tickSaidaTravaBau" @change="mudaCheckTravaBau">
								Trava de baú
							</b-check>
						</div>
						<div class="col-6 nopadding row">
							<b-form-radio-group :disabled="!tickSaidaTravaBau" :options="optionsSaidaTravaBau" name="checkbox-bloqueio"
								v-model="formPerfil.vpitravabau" />
						</div>
					</div>
					<!-- <div class="col-sm-12 row  subtituloConfig">
                            <div class="col-6 nopadding row">
                                <b-check  
                                    v-model="tickSaidaPanico"
                                    @change='mudaCheckPanico'>
                                        Pânico
                                </b-check>
                            </div>
                            <div class="col-6 nopadding row ">
                                <b-form-radio-group
                                    :disabled='!tickSaidaPanico'
                                    :options="optionsSaidaPanico"
                                    name="checkbox-panico"
                                    v-model='formPerfil.vpipanico'
                                />
                            </div>
                        </div> -->
					<div class="col-lg-12 row titulo">Acessórios</div>

					<div class="col-lg-12 row subtituloConfig">
						<div class="col-lg-6 nopadding row mt-4">
							<b-check v-model="tickAcessRFID" @change="mudaCheckRfid">
								RFID
							</b-check>
						</div>
						<div class="col-lg-5 nopadding">
							<selectAll ref="refRfid" nameForRadio="seletorRFID" class="nopadding" :isMultiple="false"
								:disabled="!tickAcessRFID" :labels="labelSelectRFID" :selected="leitorRfidSelecionado"
								:optionsArray="optSelectRfid" @changeSelect="(value) => (formPerfil.vpitipoleitorrfid = value[0])
									" />
						</div>
					</div>

					<!-- <div class="col-lg-12 row  subtituloConfig">
                            <div class="col-lg-6 nopadding row">
                                <b-check 
                                    v-model='tickAcessRFID13'
                                    @change='mudaCheckRfid13'>
                                    RFID 13 mhz
                                </b-check>
                            </div>
                            <div class="col-lg-6 row nopadding">
                                <b-form-radio-group
                                    :disabled='!tickAcessRFID13'
                                    :options='optionsAcessRfid'
                                    name='checkRFID'
                                    v-model='formPerfil.vpirfid13mhz'
                                />
                            </div>
                        </div> -->
					<div class="col-lg-12 row subtituloConfig">
						<b-checkbox :value="true" :unchecked-value="false" v-model="formPerfil.vpican">
							Leitor de rede CAN
						</b-checkbox>
					</div>
				</div>
			</b-tab>
		</b-tabs>
		<required />
		<div class="col-sm-12">
			<hr />
		</div>

		<modal title="Alteração de Módulo" @confirmButton="confirmaSenhaModal" id="modalTrocaModulo" size="lg">
			<slot slot="modalBody">
				<div class="col-lg-12 row">
					<div class="col-lg-12 alto textoModalSenha">
						Digite a senha para realizar a alteração:
					</div>
					<div class="col-lg-12 alto" v-show="dismissCountDown">
						<b-alert fade :show="dismissCountDown" variant="warning"
							@dismiss-count-down="(value) => (dismissCountDown = value)">
							Senha incorreta!
						</b-alert>
					</div>
					<inputS id="senha" name="InputSenhaTrocaModulo" type="password" @changeInput="(value) => (senhaUsu = value)" />
				</div>
			</slot>
		</modal>
		<!-- Modal Desassociar Modulos -->
		<modal title="Desassociar" @confirmButton="desassociarModulo" @cancelButton="cancelarDesassociacao"
			id="modalDesassociar">
			<slot slot="modalBody">
				<b-overlay :show="loadingModalDesassociar">
					<div class="col-lg-12 row">
						<div class="alto textoModal">
							O veículo ({{ veiculoDesassociar }} -
							{{ empresaDesassociar }}) está vinculado a este módulo ({{
								formulario.vemodulo
							}})! Deseja desassociá-lo?
						</div>
					</div>
				</b-overlay>
			</slot>
		</modal>
		<!-- Cadastro de produtos transportados -->
		<modal title="Cadastrar novo produto" id="modalCadastraTipoProduto" size="xl">
			<slot slot="modalBody">
				<b-overlay :show="carregandoProdutos">
					<div class="col-sm-12 nopadding row">
						<div class="col-sm-12 row">
							<div class="col-sm-2 nopadding">
								<selectAll nameForRadio="selectProprietario" ref="selectProprietarioProdutos" :isMultiple="false"
									:selected="proprieatarioSelecionado" :optionsArray="optSelectProprietario" :labels="labelProprietario"
									@changeSelect="changeProprietarioProduto" />
							</div>
							<div class="col-sm-2 nopadding">
								<inputS id="idNovoTipoProduto" name="InputId" ref="idProdutos" label="Id*"
									@changeInput="changeIdProduto" />
							</div>
							<div class="col-sm-2 nopadding">
								<inputS id="descricaoNovoTipoProduto" name="InputDescricao" ref="descricaoProprietarioProdutos"
									label="Descrição*" @changeInput="changeDescricaoProduto" />
							</div>
							<div class="col-sm-2 nopadding">
								<buttonS text="Salvar" type="blue" :icon="mdiCheckBold" extraClass="buttonS"
									:disabled="$v.produtos.$invalid" event="click" @click="salvarProdutoTransportado" />
							</div>
						</div>
						<div class="col-sm-12 mt-3">
							<tableListagemModal :titles="titulosT" :data="optSelectTipoProduto" :hasSearch="true"
								@excluirCadastro="excluiProdutoTransportado" />
						</div>
					</div>
				</b-overlay>
			</slot>
			<slot>
				<div>
					<buttonS text="Cancelar" type="red" :icon="mdiCloseThick" extraClass="buttonS" event="click"
						@click="$bvModal.hide('modalCadastraTipoProduto')" />
				</div>
			</slot>
		</modal>
	</panelEagle>
</template>
<script lang="js">

import Vue from 'vue'
import {
	mdiCarMultiple,
	mdiSwapVerticalBold,
	mdiPlus,
	mdiCheckBold,
	mdiCloseThick
} from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'
import { required, minLength, minValue } from 'vuelidate/lib/validators'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { FormPerfilInstalacao, FormVeiculo, FormModulo, FormVRegiao } from './formulario';
import Swal from 'sweetalert2'
import { conectionError } from '@/Services/Helpers/swellHeper.js'
import { DateTime } from 'luxon';
export default Vue.extend({
	name: 'cadastroVeiculos',
	components: {
		'modal': require('@/components/Atom/Modal/ModalEagle').default,
		'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
		'titulo': require('@/components/Atom/Header/Titulo').default,
		'botoes': require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
		'selectAll': require('@/components/Atom/Select/SelectAll').default,
		'selectTag': require('@/components/Atom/Select/SelectTags').default,
		'inputS': require('@/components/Atom/Inputs/InputSimple').default,
		'buttonS': require('@/components/Atom/Buttons/SimpleButton').default,
		'bfilter': require('@/components/Atom/Buttons/ButtonsFilters').default,
		'inputM': require('@/components/Atom/Inputs/InputMoney').default,
		'inputSD': require('@/components/Atom/Datas/InputSingleDay').default,
		'required': require('@/components/Atom/Footer/RequiredFields').default,
		'tableListagemModal': require('@/components/Atom/Table/TableListagemModal').default,
	},
	data() {
		const optEntradas = [
			{ text: '1', value: '1' },
			{ text: '2', value: '2' },
			{ text: '3', value: '3' },
			{ text: '4', value: '4' },
			{ text: '5', value: '5' },
			{ text: '6', value: '6' },
		]
		const optSaida = [
			{ text: '1', value: '1' },
			{ text: '2', value: '2' },
			{ text: '3', value: '3' },
			{ text: '4', value: '4' },
		]
		function optSimNao() {
			return [
				{ 'value': 'S', 'title': 'Sim', 'text': 'Sim', 'width': '45%', 'isSelected': false },
				{ 'value': 'N', 'title': 'Não', 'text': 'Não', 'width': '45%', 'isSelected': true }
			]
		}
		return {
			placaAntigaForm:'',
			mdiSwapVerticalBold: mdiSwapVerticalBold,
			mdiCarMultiple: mdiCarMultiple,
			mdiPlus: mdiPlus,
			mdiCheckBold: mdiCheckBold,
			mdiCloseThick: mdiCloseThick,
			// misc
			desbloqueou: false,
			trancaModulo: true,
			senhaUsu: '',
			isMaster: false,
			formPerfil: new FormPerfilInstalacao(),
			formModulo: new FormModulo(),
			formulario: new FormVeiculo(),
			formRegiao: new FormVRegiao(),
			// opt
			optTipoVeiculo: [],
			optSelectModulo: [],
			optSeletorRegioes: [],
			optSelectSensorTemp: [],
			optSelectRfid: [],
			optSelectProprietario: [],
			optSelectTag: [],
			optSelectTipoVeiculo: [
				{ value: 'C', description: 'Caminhão' },
				{ value: 'O', description: 'Ônibus' },
				{ value: 'A', description: 'Automóvel' },
				{ value: 'M', description: 'Moto' },
				{ value: 'T', description: 'Máquina' },
				{ value: 'U', description: 'Outro' }
			],
			optSelectSensorDeTemp: [
				{ value: '', description: 'Normal' },
				{ value: '', description: 'Auto Sender AS0176-C' },
			],
			// labels
			labelSelectSensorTemp: [{
				indexDFH: 'NA',
				description: 'Tipo sensor de temperatura'
			}],
			labelSelectRegiao: [{
				indexDFH: 'NA',
				description: 'Região'
			}],
			labelSeletorEmpresas: [{
				indexDFH: 'NA',
				description: 'Tipo*'
			}],
			labelProprietario: [{
				indexDFH: 'NA',
				description: 'Empresa*'
			}],
			labelSelectModulo: [{
				indexDFH: 'NA',
				description: 'Módulo'
			}],
			labelSelectProdutoTransportado: [{
				indexDFH: 'PT',
				description: 'Selecione os produtos'
			}],
			labelSelectConversaoConsumo: [{
				indexDFH: 'CO',
				description: 'Fator de conversão consumo:'
			}],
			labelSelectConversaoCapacidade: [{
				indexDFH: 'CO',
				description: 'Fator conversão capacidade tanque:'
			}],
			labelSelectRFID: [{
				indexDFH: 'NA',
				description: 'Tipo de RFID'
			}],
			botoesModulos: [
				{ 'value': 'S', 'text': 'Ativo', 'width': '45%', 'isSelected': true },
				{ 'value': 'n', 'text': 'Inativo', 'width': '45%', 'isSelected': false }
			],
			botoesMedio: [
				{ 'value': '1', 'title': 'Hodometro', 'text': 'Hodometro', 'width': '45%', 'isSelected': true },
				{ 'value': '2', 'title': 'Horimetro', 'text': 'Horimetro', 'width': '45%', 'isSelected': false}
			],
			botoesTerra: optSimNao(),
			botoesBalsas: optSimNao(),
			botoesPedagios: optSimNao(),
			// misc
			ehEdicao: '',
			tickleitorDeRedeCan: false,
			tickAcessRFID: false,
			tickAcessRFID13: false,
			tickSensorBau: false,
			tickSensorPortaMotorista: false,
			tickSensorPortaAjudante: false,
			tickSensorPainel: false,
			tickSensorPanico: false,
			tickSensorCaracol: false,
			tickSensorPneumatico: false,
			tickSensorParabrisas: false,
			tickSensorDesengate: false,
			tickSensorBetoneira: false,
			tickSensorTemp: false,
			tickSaidaBloqueioIgnicao: false,
			tickSaidaBloqueioComb: false,
			tickSaidaTravaBau: false,
			tickSaidaSirene: false,
			// opt Radios
			optionsSensorBau: optEntradas,
			optionsSensorBetoneira: optEntradas,
			optionsSensorTemp: optEntradas,
			optionsSensorPortaMotorista: optEntradas,
			optionsSensorPortaAjudante: optEntradas,
			optionsSensorPainel: optEntradas,
			optionsSensorPanico: optEntradas,
			optionsSensorDesengate: optEntradas,
			optionsSensorCaracol: optEntradas,
			optionsSensorPneumatico: optEntradas,
			optionsSensorParabrisas: optEntradas,
			optionsSaidaBloqueioIgnicao: optSaida,
			optionsSaidaBloqueioComb: optSaida,
			optionsSaidaTravaBau: optSaida,
			optionsSaidaSirene: optSaida,
			optionsAcessRfid: [
				{ text: 'Motorista', value: '1' },
				{ text: 'Motorista/Motorista2', value: '2' },
				{ text: 'Motorista/Ajudante', value: '3' },
				{ text: 'Motorista/Passageiro', value: '4' }
			],
			optSelectTipoProduto: [],
			tipoProdutoAssociadosSelecionados: [],
			// misc
			valueCustoKm: '',
			regioesSelecinada: [],
			sensorTempSelecionado: [],
			leitorRfidSelecionado: [],
			proprieatarioSelecionado: [],
			tipoSelecionado: [],
			tagsSelecionadas: [],
			tagsSelecionadasNovas: [],
			tagsSelected: [],
			moduloSelecionado: [],
			moduloSecundarioSelecionado: [],
			dismissCountDown: 0,
			primeiraEdicao: true,
			dismissSecs: 5,
			link: '/administrativo/cadastros/veiculos/',
			// erros:
			erroPlaca: '',
			erroModulo: '',
			placaAA: '',
			temModulo: false,
			carregando: false,
			veiculoDesassociar: '',
			empresaDesassociar: '',
			loadingModalDesassociar: false,
			produtoTransportadoSelecionado: [],
			titulosT: [
				{ 'number': 'description', 'name': 'Descrição' },
				{ 'number': 'ptid', 'name': 'ID' },
				{ 'number': 'clnome', 'name': 'Empresa' },
			],
			produtos: {
				proprietarioProduto: [],
				idProdutoSelecionado: [],
				descricaoProdutoSelecionado: [],
			},
			carregandoProdutos: false,
			objAntes: [],
			ativaTabConfig: false,
			valueChecklist: [],
			todosChecklist: [],
			selectChecklist: [],
			checklistSelecionado: [],
			disabledChecklist: false,
			loadingChecklist: false,

			capacidadeCombustivel: {
				'fcadescricao': []
			},
			conversaoConsumo: {
				'fcodescricao': []
			},
			optSeletorConversaoConsumo: [
				{ 'value': 'Litros a cada 100 km', 'description': 'Litros a cada 100 km' },
				{ 'value': 'Litros por hora', 'description': 'Litros por hora' },
				{ 'value': 'Km por litro', 'description': 'Km por litro' },
			],
			optSeletorCapacidadeCombustivel: [
				{ 'value': 'Litros', 'description': 'Litros' },
				{ 'value': 'Porcentagem ', 'description': 'Porcentagem ' },
			],
			capacidadeSelecinada: [],
			consumoSelecinado: [],
			isDisabled: true,
			loadingTag: false,
			limiteVeiculosOk: true,
			placaExiste:true,
		}
		
	},
	validations: {
		formulario: {
			veplaca: {
				required,
				minLength: minLength(7)
			},
			vetipo: {
				required
			},
			vedescricao: { required },
			veprefixo: { required },
			veproprietario: { required },
			vevelocidademax: {
				required,
				minValue: minValue(10),
			},
		},
		produtos: {
			proprietarioProduto: {
				required
			},

			idProdutoSelecionado: {
				required
			},

			descricaoProdutoSelecionado: {
				required
			}
		}
	},
	methods: {
		...mapGetters(['getMaster', 'getUser']),
		...mapActions(['updatePermissions']),

		setTag(tag) {
			this.tagsSelecionadas = tag;
		},

		/**
		 * Cria uma tag nova caso seja pego o evento
		 * de @tag
		 * @author Marcos
		*/
		criaTag(userInput) {
			let obj = {
				codigo: null,
				tadescricao: userInput,
				tacor: this.generateColor(),
				editar: false
			}

			new HttpRequest().Post(this.link + 'cria/tag', obj)
				.then((dados) => {
					let objRetorno = {
						value: dados.data.tacodigo,
						description: obj.tadescricao,
						cor: obj.tacor,
						editar: false,
						tacodigocliente: dados.data.tacodigocliente,
					}
					this.optSelectTag.push(objRetorno);
					this.tagsSelecionadas.push(objRetorno);
					this.tagsSelecionadasNovas = this.tagsSelecionadas;

				})
				.finally(() => {
					this.tagsSelecionadas = this.tagsSelecionadasNovas;
				})
		},

		/**
		 * Exclui uma tag >.<
		*/
		excluiTag(codigo) {
			this.loadingTag = true;
			let obj = { codigo: codigo };
			let tagSelecionadaExcluir = this.optSelectTag.filter((item) => {
				if (item.value == codigo) {
					return item;
				}
			});
			let aux = [];

			if (this.$refs.selectTags.arrayTagsAux.length > 0) {
				this.tagsSelecionadas = this.$refs.selectTags.arrayTagsAux.filter((item) => {
					if (item.value != tagSelecionadaExcluir[0].value) {
						return item
					}
				})
			}

			this.tagsSelecionadasNovas = this.tagsSelecionadasNovas.filter((tag) => {
				if (tag.value != tagSelecionadaExcluir[0].value) {
					return tag;
				}
			});

			this.optSelectTag = this.optSelectTag.filter((tagExcluir) => {
				if (tagExcluir.value != tagSelecionadaExcluir[0].value) {
					return tagExcluir;
				}
			});

			aux = this.tagsSelecionadas;

			new HttpRequest().Post(this.link + 'exclui/tag', obj)
				.then(() => {
				}).finally(() => {
					this.tagsSelecionadasNovas = aux;
					this.adicionarTags();
					this.loadingTag = false
				})
		},

		/**
		 * salva ediçao do texto e da cor da tag
		 * @Param a tag selecionada
		 * @author Marcos
		*/
		salvaEdicao(tag) {
			this.loadingTag = true
			let obj = {
				'codigo': tag.value,
				'tadescricao': tag.description,
				'tacor': tag.cor,
				'tacodigocliente': tag.tacodigocliente,
				'editar': tag.editar,
			}

			new HttpRequest().Post(this.link + 'edita/tag', obj)
				.finally(() => {
					this.loadingTag = false
				})
		},

		/**
		 * pega as tags do select quando chamada
		 * @param codigo da empresa a qual busca as tags
		 * @author Marcos
		*/
		getTags() {
			this.loadingTag = true
			new HttpRequest().Get(this.link + 'get/tags')
				.then((dados) => {
					while (this.optSelectTag.length > 0) {
						this.optSelectTag.pop();
					}
					dados.data.forEach(element => {
						element.editar = false
						this.optSelectTag.push(element)
						this.tagsSelecionadas.push(element)
					});

					this.loadingTag = false;
				}).catch(() => {
					conectionError()
				}).finally(() => {
					this.loadingTag = false;
					if (this.formulario.vecodigo) {
						this.getTagsVeiculoSelecionado(this.formulario.vecodigo)
					}
				})
		},

		getTotalVeiculosCliente(cliente) {
			if (!cliente) return
			this.limiteVeiculosOk = null
			new HttpRequest().Get(this.link + 'veiculos/' + cliente + '/total')
				.then((dados) => {
					if (dados.status) {
						this.limiteVeiculosOk = dados.data.limiteok ? true : false
					}
				}).catch(() => {
					this.limiteVeiculosOk = null
					conectionError()
				}).finally(() => { '' })
		},

		/**
	* função para consultar as tags selecionadas do veículo
	* @param codigo código do veículo (this.formulario.vecodigo)
	* @author Marcos
	*/
		getTagsVeiculoSelecionado(codigo) {
			this.loadingTag = true
			let obj = { codigo: codigo };
			new HttpRequest().Get(this.link + 'get/tagSelecionada', obj)
				.then((dados) => {
					dados.data.forEach(element => {
						if (this.tagsSelecionadasNovas.length > 0) {
							this.tagsSelecionadas = this.tagsSelecionadasNovas;
						}
						this.tagsSelecionadas.push(element)

					});
				}).catch(() => {
					conectionError()
				}).finally(() => {
					this.loadingTag = false
				})
		},

		adicionarTags() {
			this.tagsSelecionadas = this.tagsSelecionadasNovas;
		},

		/**
		* função generica para gerar uma cor hex
		* que peguei nas interwebs
		* @author Marcos
		*/
		generateColor() {
			const letters = '0123456789ABCDEF';
			let color = '#';

			for (let i = 0; i < 6; i++)
				color += letters[Math.floor(Math.random() * 16)];
			return color;
		},

		async ativaModal() {
			var confirma = await Swal.fire({
				title: '<strong style="font-size:15px">Esse veículo possui configurações para parametrização?</strong>',
				icon: 'question',
				iconHtml: '<div class="mt-3" style="font-size:40px">?</div>',
				showCloseButton: false,
				showCancelButton: true,
				focusConfirm: false,
				confirmButtonText: 'Sim, possui.',
				cancelButtonText: 'Não.',
			})
			return confirma
		},

		pegaValoresAntesConficuracoes() {
			this.objAntes = [
				this.formPerfil.vpibau,
				this.tickSensorBetoneira,
				this.formPerfil.vpibetoneira,
				this.tickSensorPortaMotorista,
				this.tickSensorPortaAjudante,
				this.tickSensorPainel,
				this.formPerfil.vpiportamotorista,
				this.formPerfil.vpiportaajudante,
				this.formPerfil.vpipainel,
				this.formPerfil.vpipanico,
				this.formPerfil.vpidesengate,
				this.tickSensorTemp,
				this.formPerfil.vpitemperatura,
				this.formPerfil.vpitiposensortemperatura,				
				this.tickSaidaBloqueioIgnicao,
				this.tickSaidaBloqueioComb,
				this.tickSaidaTravaBau,
				this.formPerfil.vpibloqueioignicao,
				this.formPerfil.vpibloqueiocombustivel,
				this.formPerfil.vpitravabau,
				this.formPerfil.vpitipoleitorrfid,
				this.tickSaidaSirene,
				this.formPerfil.vpisirene,
				this.tickAcessRFID,
				this.tickAcessRFID13,
				this.formPerfil.vpirfid,
				this.formPerfil.vpican,
				this.formPerfil.vpicaracol,
				this.formPerfil.vpipneumatico,
				this.formPerfil.vpiparabrisas,
			]
		},

		pegaValoresAgoraConfiguracoes() {
			return [
				this.formPerfil.vpibau,
				this.tickSensorBetoneira,
				this.formPerfil.vpibetoneira,
				this.tickSensorPortaMotorista,
				this.tickSensorPortaAjudante,
				this.tickSensorPainel,
				this.tickSensorPanico,
				this.tickSensorDesengate,
				this.tickSensorCaracol,
				this.tickSensorPneumatico,
				this.tickSensorParabrisas,
				this.formPerfil.vpiportamotorista,
				this.formPerfil.vpiportaajudante,
				this.formPerfil.vpipainel,
				this.formPerfil.vpipanico,
				this.formPerfil.vpidesengate,
				this.tickSensorTemp,
				this.formPerfil.vpitemperatura,
				this.formPerfil.vpitiposensortemperatura,
				this.tickSaidaBloqueioIgnicao,
				this.tickSaidaBloqueioComb,
				this.tickSaidaTravaBau,
				this.formPerfil.vpibloqueioignicao,
				this.formPerfil.vpibloqueiocombustivel,
				this.formPerfil.vpitravabau,
				this.formPerfil.vpitipoleitorrfid,
				this.tickSaidaSirene,
				this.formPerfil.vpisirene,
				this.tickAcessRFID,
				this.tickAcessRFID13,
				this.formPerfil.vpirfid,
				this.formPerfil.vpican,
				this.formPerfil.vpicaracol,
				this.formPerfil.vpipneumatico,
				this.formPerfil.vpiparabrisas,
			]
		},

		changeVelocidadeMaxima(velocidadeMaxima) {
			this.formulario.vevelocidademax = velocidadeMaxima
		},

		changeTipoChecklist(value) {
			this.valueChecklist = value
		},

		/**
				 * Faz a consulta de TODAS as checklist do cliente
				 * @author Lucas Eduardo
				 */
		async pegaChecklist(value) {
			this.loadingChecklist = true
			var obj = {
				'empresa': value,
				'tipoVeiculo': this.formulario.vetipo,
			}
			var dados = await new HttpRequest()
				.Post(this.link + 'tipo/checklist', obj)
			this.todosChecklist = dados.data ?? []
			this.selectChecklist = dados.data
			this.loadingChecklist = false
		},


		/**
				 * Recebe os tipos de veiculos e faz um filtro para as checklist
				 * apenas para o tipo selecionado
				 * tem o v.tipochecklist.trim() pq a string por algum motivo vem com 99 espaços em branco
				 * ai da erro no filtro
				 * @param Array - codigo do tipo
				 * @author Lucas Eduardo
				 */
		changeTipoCadastro(value) {
			this.$refs.refselectTipoChecklist.clearAll()
			if (value[0] != undefined) {
				this.formulario.vetipo = value[0]
			} else {
				this.formulario.vetipo = []
			}
			this.valueChecklist = []
			if (this.formulario.veproprietario)
				this.pegaChecklist(this.formulario.veproprietario)
			this.selectChecklist = this.todosChecklist.filter((v) => {
				if (v.tipochecklist.trim() == value[0]) {
					return v
				}
			})
		},

		mudaModulo(value) {
			this.formulario.vemodulo = value[0] ? value[0] : null;
			if (value == '') {
				this.temModulo = false
			} else {
				this.temModulo = true
			}
			// Verifica se o módulo tem alguma associação a um veículo
			if (arguments[3] != 0 && arguments[3] != undefined) {
				this.modalDesassociar(arguments[1])
			}
		},

		/**
				 * Inicia o modal de desassociar veículo do módulo, faz a busca de qual veículo esta associado
				 * ao modulo para apresentar na tela
				 * @param Array modulo = modulo selecionado
				 * @return Objeto com veículo e empresas associados
				 * @author Vitor Hugo
				 */
		async modalDesassociar(modulo) {
			this.loadingModalDesassociar = true
			this.$bvModal.show('modalDesassociar')
			var dados = await new HttpRequest().Post(this.link + 'getVeiculo', { modulo: modulo })
			this.veiculoDesassociar = dados.data.veiculo.placa
			this.empresaDesassociar = dados.data.veiculo.empresa
			this.loadingModalDesassociar = false
		},

		/**
				 * Desassocia o módulo do veículo
				 * @param Array modulo = modulo selecionado
				 * @return mensagem de sucesso ou erro
				 * @author Vitor Hugo
				*/
		async desassociarModulo() {
			var dados = await new HttpRequest().Post(this.link + 'desassociar', { modulo: this.formulario.vemodulo })
			if (dados.code == 200) {
				this.toastShow('Erro', dados.data.message, 'success')
				this.$bvModal.hide('modalDesassociar')
			} else {
				this.formulario.vemodulo = []
				this.$refs.selectModulo.clearAll()
				this.toastShow('Desassociado', dados.data.message, 'danger')
				this.$bvModal.hide('modalDesassociar')
			}

		},

		cancelarDesassociacao() {
			this.formulario.vemodulo = []
			this.$refs.selectModulo.clearAll()
		},

		toastShow(titulo, msg, type) {
			this.$bvToast.toast(msg, {
				title: titulo,
				autoHideDelay: 2500,
				variant: type,
			})
		},

		// top 10 verificação de senha
		confirmaSenhaModal() {
			if (this.senhaUsu == 'Rastrear2701Track') {
				this.getModulos()
				this.trancaModulo = false
				this.desbloqueou = true
				this.$bvModal.hide('modalTrocaModulo')
			} else {
				this.dismissCountDown = this.dismissSecs
			}
		},

		/**
		* @description salva/atualiza os dados do veículo
		* @author Marcos
		**/
		async salvar() {
			var verifica = await this.verificaModConfiguracao()
			if (verifica) return
			var obj = this.criaObjeto()
			var rota = this.$route.params.id ? 'atualizar' : 'cadastrar'

			
			this.carregando = true
			new HttpRequest().Post(this.link + rota, obj).then((data) => {
				if (data.data.success) {
					this.$router.push({ name: 'listarVeiculo' })
				}
				if (data.data.error == 'placaExiste') {
					//this.erroPlaca = 'existe'
					conectionError('', 'warning', 'Já existe um veículo com essa placa cadastrada!')
				}
				if (data.data.error == 'moduloExiste') {
					this.erroModulo = 'existe'
				}
			}).catch(() => {
				conectionError();
			}).finally(() => { this.carregando = false })
		},

		/**
				 * Verifica se é master e se os parametros da tab configuracoes
				 * foi modificado
				 * @return Boolean
				 * @author Lucas Eduardo
				 */
		async verificaModConfiguracao() {
			if (!this.isMaster) return false
			var objAgr = this.pegaValoresAgoraConfiguracoes()
			var vobjAntes = this.objAntes
			var ok = objAgr.every(function (element, index) {
				return element === vobjAntes[index]
			})
			var difObj = (objAgr.length === vobjAntes.length) && ok
			if (difObj && this.$route.params.id == undefined) {
				var confirmaModal = await this.ativaModal()
				if (confirmaModal.dismiss == 'backdrop') return true
				if (confirmaModal.isConfirmed) {
					this.ativaTabConfig = true
					return true
				}
			}
			return false
		},


		criaObjeto() {
			var obj
			if (this.$route.params.id) {
				obj = {
					codigoV: this.$route.params.id,
					veiculo: this.formulario,
					modulo: this.formModulo,
					perfil: this.formPerfil,
					regiao: this.formRegiao,
					tipoProduto: this.produtoTransportadoSelecionado,
					conversaoConsumo: this.conversaoConsumo,
					conversaoCapacidade: this.capacidadeCombustivel,
					tags: this.tagsSelecionadas,
					placaAntigaForm: this.placaAntigaForm,
				}
			} else {
				obj = {
					veiculo: this.formulario,
					modulo: this.formModulo,
					perfil: this.formPerfil,
					regiao: this.formRegiao,
					tipoProduto: this.produtoTransportadoSelecionado,
					conversaoConsumo: this.conversaoConsumo,
					conversaoCapacidade: this.capacidadeCombustivel,
					tags: this.tagsSelecionadas,
				}
			}
			// precisa disso pq o php n conhece undefined ai ele só ignora isso
			obj.veiculo.vechecklist = this.valueChecklist ?? null
			return obj
		},

		/**
				 * @listen click - excluiProdutoTransportado
				 * @description Método para excluir um tipo de produto transportado
				 * @param {Number} produto - código do produto
				 * @return Sucesso ou erro
				 * @author Vitor Hugo 🐨
				 */
		excluiProdutoTransportado(produto) {
			this.carregandoProdutos = true
			var rota = 'excluir/produto'
			new HttpRequest().Post(this.link + rota, { 'produto': produto }).then((dados) => {
				if (dados.code == 200) {
					var index = this.produtoTransportadoSelecionado.findIndex(element => {
						return element == produto
					})
					this.produtoTransportadoSelecionado.splice(index, 1)
					this.getTipoProdutoTransportado(this.formulario.veproprietario, this.$route.params.id)
				}
				this.carregandoProdutos = false
			})
		},

		changeProprietarioProduto(produto) {
			this.produtos.proprietarioProduto = produto
		},

		changeIdProduto(id) {
			this.produtos.idProdutoSelecionado = id
		},

		changeDescricaoProduto(descricao) {
			this.produtos.descricaoProdutoSelecionado = descricao
		},

		/**
				 * @listen click - salvarProdutoTransportado
				 * @description Método para salvar os tipos de produtos transportados
				 * @param {Array} proprietarioProduto          - cliente selecionado
				 * @param {String} idProdutoSelecionado        - id informado
				 * @param {String} descricaoProdutoSelecionado - descrição informado
				 * @return Array  optSelectTipoProduto - tipos de produtos carregados cadastrados
				 * @return Array  tipoProdutoAssociadosSelecionados - tipos de produtos selecionados
				 * @author Vitor Hugo 🐨
				 */
		salvarProdutoTransportado() {
			this.carregandoProdutos = true
			var rota = 'salvar/produto'
			var objt = {
				'ptcliente': this.produtos.proprietarioProduto[0],
				'ptid': this.produtos.idProdutoSelecionado,
				'ptdescricao': this.produtos.descricaoProdutoSelecionado,
			}
			this.$refs.idProdutos.clearAll()
			this.$refs.descricaoProprietarioProdutos.clearAll()
			new HttpRequest().Post(this.link + rota, objt).then((dados) => {
				if (dados.code == 201) {
					this.getTipoProdutoTransportado(this.formulario.veproprietario, this.$route.params.id)
				}
				this.carregandoProdutos = false
			})
		},

		checkPontoFixo(value) {
			if (!value) this.formulario.vepontofixoabrefecha = false
			this.$forceUpdate()
		},

		mudaSelectTipoSensorTemperatura(value) {
			this.formPerfil.vpitiposensortemperatura = value[0]
		},

		mudaDataInstalacao(value) {
			this.formulario.vedatainstalacao = value
		},

		async mudaProprietario(value) {
			let verificarCliente = value[0] && value[0] != this.formulario.veproprietario
			this.formulario.veproprietario = value[0] ?? ''
			this.produtos.proprietarioProduto = value
			this.getRegioes(value)
			if (this.$route.params.id == undefined) {
				this.getTipoProdutoTransportado(value, this.$route.params.id)
				this.getTotalVeiculosCliente(value[0])
			} else {
				if (verificarCliente) {
					this.getTotalVeiculosCliente(value[0])
				}
			}
			this.pegaChecklist(value[0])
			if (this.formulario.vetipo) {
				this.changeTipoCadastro(this.formulario.vetipo)
			}
		},

		getRegioes(value) {
			var rota = 'getRegioes'
			new HttpRequest().Post(this.link + rota, { proprietario: value })
				.then((data) => {
					this.optSeletorRegioes = data.data.regioes
				})
		},

		/**
				 * @description Busca os tipos de produtos transportados
				 * @param {Array} proprietario - cliente selecionado
				 * @param {Array} veiculo      - veiculo selecionado (se for edição)
				 * @return Array  optSelectTipoProduto - tipos de produtos carregados cadastrados
				 * @return Array  tipoProdutoAssociadosSelecionados - tipos de produtos selecionados
				 * @author Vitor Hugo 🐨
				 */
		getTipoProdutoTransportado(proprietario, veiculo) {
			this.carregandoProdutos = true
			var rota = 'produto/transportado'
			new HttpRequest().Post(this.link + rota, { proprietario: proprietario, veiculo: veiculo })
				.then((data) => {
					this.optSelectTipoProduto = data.data.produtos
					if (this.$route.params.id) {
						this.tipoProdutoAssociadosSelecionados = data.data.prodsAssociados
					}
					this.carregandoProdutos = false
				})
		},

		changeTipoProduto(produto) {
			this.produtoTransportadoSelecionado = produto
		},

		mudaPlaca(value) {
			var condicao = value.length == 8 && value != this.formulario.veplaca
			this.formulario.veplaca = value
			if (condicao) {
				this.formulario.veplacaantiga = this.placaAA
				this.$forceUpdate()
			}
		},

		getModulos() {
			var rota = 'getModulos'
			new HttpRequest().Post(this.link + rota)
				.then((data) => {
					this.optSelectModulo = data.data.modulos
				})
		},

		mudaSeletorTipoVeiculo(value) {
			this.formulario.vetipo = value[0]
		},

		mudaBotaoBaseCalculo(value) {
			this.formulario.vebasecalculomedia = value[0]
		},

		mudaCheckBau(value) {
			if (!value) this.formPerfil.vpibau = null
		},

		mudaCheckBetoneira(value) {
			if (!value) this.formPerfil.vpibetoneira = null
		},

		mudaCheckPortaAjudante(value) {
			if (!value) this.formPerfil.vpiportaajudante = null
		},

		mudaCheckPortaMotorista(value) {
			if (!value) this.formPerfil.vpiportamotorista = null
		},

		mudaCheckPainel(value) {
			if (!value) this.formPerfil.vpipainel = null
		},

		mudaCheckPanico(value) {
			if (!value) this.formPerfil.vpipanico = null
		},

		mudaCheckDesengate(value) {
			if (!value) this.formPerfil.vpidesengate = null
		},

		mudaCheckTemperatura(value) {
			if (!value) this.formPerfil.vpitemperatura = null
		},


		mudaCheckCaracol(value) {
			if (!value) this.formPerfil.vpicaracol = null
		},


		mudaCheckPneumatico(value) {
			if (!value) this.formPerfil.vpipneumatico = null
		},


		mudaCheckParabrisas(value) {
			if (!value) this.formPerfil.vpiparabrisas = null
		},






		mudaCheckBloqueioIgnicao(value) {
			if (!value) this.formPerfil.vpibloqueioignicao = null
		},

		mudaCheckBloqueioComb(value) {
			if (!value) this.formPerfil.vpibloqueiocombustivel = null
		},

		mudaCheckTravaBau(value) {
			if (!value) this.formPerfil.vpitravabau = null
		},

		mudaCheckSirene(value) {
			if (!value) this.formPerfil.vpisirene = null
		},

		mudaCheckRfid(value) {
			if (!value) {
				this.$refs.refRfid.clearAll()
				this.formPerfil.vpitipoleitorrfid = null
				this.formPerfil.vpirfid = null
			} else {
				this.formPerfil.vpirfid = true
			}
		},

		mudaCheckRfid13(value) {
			if (!value) this.formPerfil.vpitipoleitorrfid = null
		},

		getSensores() {
			new HttpRequest().Get(this.link + 'getSensor').then((data) => {
				this.optSelectSensorTemp = data.data.sensores;
				this.optSelectRfid = data.data.rfid;
			})
		},

		arrumaTipo(tipo) {
			this.optSelectTipoVeiculo.forEach((el) => {
				if (el['value'] == tipo) {
					this.formulario.vetipo = el['value'];
					this.tipoSelecionado = Array(el)
				}
			})
		},

		arrumaProprieatario(codigo) {
			this.optSelectProprietario.forEach(el => {
				if (el['value'] == codigo) {
					this.formulario.veproprietario = el['value'];
					this.proprieatarioSelecionado = Array(el);
					return;
				}
			});
		},

		arrumaModulo(codigo) {
			if (codigo) this.temModulo = false;
			var mod = { value: codigo, description: codigo };
			this.moduloSelecionado = Array(mod);
		},

		arrumaModuloSecundario(codigo) {
			if (codigo) this.temModulo = false;
			var mod = { value: codigo, description: codigo };
			this.moduloSecundarioSelecionado = Array(mod);
		},

		arrumaTipoSensor(cod) {
			this.optSelectSensorTemp.forEach(el => {
				if (el['value'] == cod) {
					this.sensorTempSelecionado = Array(el);
					return;
				}
			});
		},

		arrumaLeitorRfid(cod) {
			this.optSelectRfid.forEach(el => {
				if (el['value'] == cod) {
					this.leitorRfidSelecionado = Array(el);
					return;
				}
			});
		},

		/**
				 * Método para transformar a data no formato que o calendário aceita
				 * @author Vitor Hugo 🐨
				 */
		arrumaDataInstalacao() {
			if (this.formulario.vedatainstalacao != '') {
				this.formulario.vedatainstalacao = DateTime.fromFormat(
					this.formulario.vedatainstalacao, 'yyyy-LL-dd').toFormat('dd/LL/yyyy')
			}
		},

		preparaBotoes(info) {
			if (info.veestradaterra == 'S') {
				this.botoesTerra[0]['isSelected'] = true;
				this.botoesTerra[1]['isSelected'] = false;
			}
			if (info.veroterizar == 'n') {
				this.botoesModulos[0]['isSelected'] = false;
				this.botoesModulos[1]['isSelected'] = true;
			}
			if (info.vebalsas == 'S') {
				this.botoesBalsas[0]['isSelected'] = true;
				this.botoesBalsas[1]['isSelected'] = false;
			}
			if (info.vepedagios == 'S') {
				this.botoesPedagios[0]['isSelected'] = true;
				this.botoesPedagios[1]['isSelected'] = false;
			}
			if (info.vebasecalculomedia == '1') {
				this.botoesMedio[0]['isSelected'] = true;
				this.botoesMedio[1]['isSelected'] = false;
			} else if (info.vebasecalculomedia == '2') {
				this.botoesMedio[0]['isSelected'] = false;
				this.botoesMedio[1]['isSelected'] = true;
			}
		},

		preparaFormulario(infoF) {
			Object.keys(this.formulario).forEach(key => {
				this.formulario[key] = infoF[key] != null ? infoF[key] : ''
				if (key == 'vecusto') this.valueCustoKm = infoF[key]
			})
			this.placaAntigaForm = infoF.veplaca
			this.arrumaTipo(infoF.vetipo)
			this.arrumaProprieatario(infoF.veproprietario)
			this.arrumaModulo(infoF.vemodulo);
			this.arrumaModuloSecundario(infoF.vemodulosecundario)
			this.arrumaDataInstalacao()
		},

		preparaFormModulo(infoM) {
			if (!infoM) return
			Object.keys(this.formModulo).forEach(key => {
				this.formModulo[key] = infoM[key] ? infoM[key] + '' : '';
			})
		},

		preparaPerfil(infoPerfil) {
			this.tickAcessRFID = infoPerfil.vpirfid ? true : false;
			this.tickSensorBau = infoPerfil.vpibau ? true : false;
			this.tickSensorPortaMotorista = infoPerfil.vpiportamotorista ? true : false;
			this.tickSensorPortaAjudante = infoPerfil.vpiportaajudante ? true : false;
			this.tickSensorPainel = infoPerfil.vpipainel ? true : false;
			this.tickSensorPanico = infoPerfil.vpipanico ? true : false;
			this.tickSensorDesengate = infoPerfil.vpidesengate ? true : false;
			this.tickSaidaBloqueioComb = infoPerfil.vpibloqueiocombustivel ? true : false;
			this.tickSaidaTravaBau = infoPerfil.vpitravabau ? true : false;
			this.tickAcessRFID13 = infoPerfil.vpitipoleitorrfid ? true : false;
			this.tickSensorBetoneira = infoPerfil.vpibetoneira ? true : false;
			this.tickSensorTemp = infoPerfil.vpitemperatura ? true : false;

			this.tickSensorCaracol = infoPerfil.vpicaracol ? true : false;
			this.tickSensorPneumatico = infoPerfil.vpipneumatico ? true : false;
			this.tickSensorParabrisas = infoPerfil.vpiparabrisas ? true : false;


			this.tickSaidaBloqueioIgnicao = infoPerfil.vpibloqueioignicao ? true : false
			this.tickSaidaSirene = infoPerfil.vpisirene ? true : false;
			Object.keys(this.formPerfil).forEach(key => {
				this.formPerfil[key] = infoPerfil[key]
			})
			this.arrumaTipoSensor(infoPerfil.vpitiposensortemperatura);
			this.arrumaLeitorRfid(infoPerfil.vpitipoleitorrfid)
		},

		start() {
			this.isMaster = this.getMaster()
			this.optSelectProprietario = new EmpresasService().Get();
			this.updatePermissions();
			this.getSensores();
		},

		preparaConsumo(consumo, capacidade) {
			this.capacidadeSelecinada = capacidade
			this.consumoSelecinado = consumo
		},


		async preparaEdicao() {
			var obj = { codigo: this.$route.params.id };
			await new HttpRequest().Post(this.link + 'editar', obj)
				.then((data) => {
					this.preparaFormulario(data.data.info);
					this.placaAA = this.formulario.veplaca;
					this.preparaFormModulo(data.data.info.veiculos_contadores);
					data.data.info.perfil_instalacao ? this.preparaPerfil(data.data.info.perfil_instalacao) : '';
					this.preparaBotoes(data.data.info)
					this.getRegioes([data.data.info.veproprietario], this.$route.params.id)
					this.getTipoProdutoTransportado([data.data.info.veproprietario], this.$route.params.id)
					this.regioesSelecinada = data.data.regiao
					this.preparaConsumo(data.data.consumo, data.data.capacidade)
					Object.keys(data.data.checklist).forEach(element => {
						this.checklistSelecionado.push({
							value: data.data.checklist[element].vchcodigochecklist,
							description: data.data.checklist[element].chedescricao,
						})
					});
					
					// n achei um forma melhor de esperar consulta
					// mudar isso no futuro
					// var loop = setInterval(funLoop, 100)
					// var it = this
					// function funLoop(){
					// 	if(it.todosChecklist.length){
					// 		it.$nextTick((v) => {
					// 			it.valueChecklist,
					// 			it.checklistSelecionado =
					//             it.todosChecklist.filter(v => v.value == data.data.checklist)
					//             it.carregando = false
					// 			clearInterval(loop)
					// 		})
					// 	}
					// }
					this.carregando = false
				})
			this.getTagsVeiculoSelecionado(this.formulario.vecodigo)
			this.ehEdicao = 'disabled'
			this.pegaValoresAntesConficuracoes()

		}
	},

	async mounted() {
		this.start()
		this.getTags()
		if (this.$route.params.id) {
			this.carregando = true
			this.preparaEdicao()
		} else {
			this.pegaValoresAntesConficuracoes()
		}
	},

	computed: {
		proprieatarioSelect() {
			return !this.formulario.veproprietario;
		}
	},
})


</script>

<style lang="scss" scoped>
.rotated {
	margin-top: 14px;
	padding-left: 11%;
	// transform: rotate(90deg);
	width: 40% !important;
	height: 80%;
}

.buttonS {
	height: 50%;
	margin-top: 25px;
}

.somepadding {
	padding: 5px 0 0 0;
}

.titulo-parametros {
	text-align: left;
	font-size: 18px;
}

.b-check-jornada {
	text-align: left;
}

.titulo {
	margin-top: 20px;
	// border: 1px solid red;
	transform: translateX(-1%);
	// padding: 10px 0px 5px 10px;
	color: #000000;
	font-weight: bold;
	background-color: #f6f5f5;
	text-align: left;
	position: relative !important;
}

.subtituloConfig {
	padding: 10px 0 5px 0;
	border-bottom-style: solid;
	border-bottom-width: 1px;
}

.alto {
	text-align: center;
	height: 50px;
}

.textoModal {
	margin-top: 15px;
}

.textoModalSenha {
	margin-top: 15px;
	font-size: 16px;
}

.erro {
	color: red;
	text-align: right !important;
	font-size: 12px;
}

.extrapadding {
	padding: 2.4% 0 0 0;
	text-align: left;
}

.parametros {
	padding: 1% 0 0 0;
	text-align: left;
}

.nonmargin{
	padding-left: 0;
}
</style>
