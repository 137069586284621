/*eslint-disable */
import { HttpRequest } from "./HttpRequest.Service";
import { Crypto } from "./Crypto.Service";

export class EmpresasService {
    constructor() {}
    private ServiceKey = "34G1ETR4CK_S3RV1C3_3MPR3S4";
    private Set(empresas: String | Object) {
        if (empresas instanceof String) {
            new Crypto().Commit(this.ServiceKey, empresas as string);
        } else {
            new Crypto().Commit(this.ServiceKey, JSON.stringify(empresas));
        }
    }
    public Get() {
        return new Crypto().Get(this.ServiceKey);
    }
    public async Refresh() {
        new HttpRequest().Get("/filtros/listagem/empresas").then((data) => {
            this.Set(data.data);
        });
    }
}
