<template>
    <panelEagle id="semComunicacao" :loading="loadingPanel">
        <div class="col-sm-12 nopadding ">
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiCarOff" titulo="Sem comunicação" ></tituloPage>
                </div>
                <div class="col-sm-6 divDontPrint">
                    <basicButtonsRelatoriosEIG
                        :disabled="$v.$invalid"
                        :loading="loadingExporta"
                        @exportarRelatorio="exportarRelatorio" 
                        @gerarRelatorio="gerarRelatorio" 
                        :disabledDropdown='dadosSemComunicacao.length == 0 ? true : false'>
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12"><hr></div>
                    <div class="col-sm-12 row nopadding mb-2">
                        <div class="col-sm-3 row nopadding">
                            <div class="col-sm-12 nopadding">
                                <inputSimple
                                    id="tempocomunicacao" 
                                    @changeInput="changeInput" 
                                    :hasMask="false" 
                                    name="tempocomunicacao"  
                                    placeholder='Ex: 123h'
                                    label="Tempo sem comunicar*">
                                </inputSimple>
                            </div>
                        </div>
                        <div class="col-sm-9 nopadding row">
                            <div class="col-sm-4 nopadding">
                                <selectAll
                                    @changeSelect="SelectEmpresas" 
                                    :isMultiple="true" 
                                    nameForRadio="radiosEmpresas" 
                                    :labels="labelsSelectEmpresa"  
                                    :optionsArray="optionsSelectEmpresa" 
                                    firstSelected="E" 
                                    :selected="selectedEmpresa"
                                    :hasSelectAll="true">
                                </selectAll>
                            </div>
                            <div class="col-sm-4 nopadding">
                                <selectAll
                                    :loading="loadingVeiculos"
                                    @selectCriado="selectCriado" 
                                    @changeSelect="SelectVeiculos" 
                                    :isMultiple="true" 
                                    @changeCheck="registraCheckveiculos" 
                                    nameForRadio="radiosVeiculos" 
                                    :labels="labelsSelectVeiculos" 
                                    :optionsArray="optionsSelectVeiculos" 
                                    firstSelected="V" 
                                    :hasSelectAll="true">
                                </selectAll>
                            </div>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
        </div>
        <div class="col-sm-12"><hr></div>
        <tableRelatorio>
            <slot slot="thead">
                <tr>
                    <th colspan="2">Última posição</th>
                    <th colspan="2">Placa</th>
                    <th colspan="1">Prefixo</th>
                    <th colspan="2">Local</th>
                    <th colspan="1">Módulo</th>
                    <th colspan="2">Modelo</th>
                </tr>
            </slot>
            <slot slot="tbody">
                <template v-for="(dados, d) in dadosSemComunicacao">
                    <tr :key="d" class="backColorTdPDF stayTh pt-5">
                        <td colspan="10" class="nopadding">
                            <span class="newbadge">{{d}}</span>
                        </td>
                    </tr>
                    <tr v-for="(dadosEmpresa, de) in dados"
                        :key="de+dadosEmpresa.moultimoevento+dadosEmpresa.veplaca"
                        class="headerRegisterPDF">
                        <td colspan="2">{{transformaData(dadosEmpresa.moultimoevento)}}</td>
                        <td colspan="2">{{dadosEmpresa.veplaca}}</td>
                        <td colspan="1">{{dadosEmpresa.veprefixo}}</td>
                        <td colspan="2">{{dadosEmpresa.moultimoendereco}}</td>
                        <td colspan="1">{{dadosEmpresa.mocodigo}}</td>
                        <td colspan="2">{{dadosEmpresa.mmdescricao}}</td>
                    </tr>
                </template>
                <br>
                <statusInformation 
                    v-if="dadosSemComunicacao.length == 0" 
                    typeBar="tr" 
                    :statusBar="statusbar">
                </statusInformation>
            </slot>
        </tableRelatorio>
    </panelEagle>
</template>
<script>
import {mdiCarOff } from '@mdi/js'
import Vue from 'vue'
import {EmpresasService} from '@/Services/auth/Empresas.service'
import {HttpRequest} from '@/Services/auth/HttpRequest.Service'
import {FiltrosService} from '@/Services/filtros/filtros.Service'
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { conectionError } from '@/Services/Helpers/swellHeper'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name:'semComunicacao',

    validations: {
        empresasArray:{ required },
        // veiculosArray:{ required },
        horasInput: { required },
    },

    components:{
        'selectAll'         : require('@/components/Atom/Select/SelectAll').default,
        'tituloPage'        : require('@/components/Atom/Header/Titulo').default,
        'panelEagle'        : require('@/components/Atom/Panel/PanelEagle').default,
        'inputSimple'       : require('@/components/Atom/Inputs/InputSimple').default,
        'tableRelatorio'    : require('@/components/Atom/Table/TableRelatorio').default,
        'slideUpAndDown'    : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
        'statusInformation' : require('@/components/Atom/StatusInformation/StatusInformation').default,
        'basicButtonsRelatoriosEIG' : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
    },

    data(){
        return{
            link:'/telemetria/relatorios/comunicacao/',
            mdiCarOff:mdiCarOff,
            loadingVeiculos:false,
            loadingPanel:false,
            loadingExporta:[false, false, false],
            labelsSelectEmpresa:[
                {indexDFH: 'E', description: 'Empresas*'},
            ],
            optionsSelectEmpresa: new EmpresasService().Get(),
            labelsSelectVeiculos:[
                {indexDFH: 'V', description: 'Veiculos'},
                {indexDFH: 'GV', description: 'Grupos'},
                {indexDFH: 'TAG', description: 'Tags'},

            ],
            statusbar: 'info',
            VeiculosTipo:'V',

            optionsSelectVeiculos: [],
            optionsSelectPontos: [],
            empresasArray:[],
            veiculosArray:[],
            veiculosSelect: [],
            dadosSemComunicacao:[],
            horasInput:'',
            tamanhoArraySemComunicacao: 0,
            htmlSemComunicacao:'',
            selectedEmpresa: []
        }
    },

    methods:{
        ...mapGetters(['getMaster']),

        SelectEmpresas(event){
            this.clearTable()
            this.empresasArray = event;
            this.veiculosSelect.clearAll();
            this.dados_filtros();
        },

        SelectVeiculos(event){
            this.clearTable()
            this.veiculosArray = event;
        },

        selectCriado(select){
            this.clearTable()
            this.veiculosSelect = select;
        },

        changeInput(event){
            this.clearTable()
            this.horasInput = event;
        },

        clearTable() {
            this.tamanhoArraySemComunicacao = 0
            this.dadosSemComunicacao = []
            this.statusbar = 'info'
        },

        registraCheckveiculos(check, needRecharge){
            this.VeiculosTipo = check;
            if(needRecharge){
                this.dados_filtros();
            }
        },

        async dados_filtros(){
            this.loadingVeiculos = true
            new FiltrosService().dados_filtros(this.empresasArray, [this.VeiculosTipo])
                .then((dados) => {
                    this.optionsSelectVeiculos = dados[this.VeiculosTipo];     
                }).catch(() => {
                    conectionError()
                }).finally(() => this.loadingVeiculos = false )
            // var dados = [];
            // dados = await new FiltrosService().dados_filtros(this.empresasArray, [this.VeiculosTipo])
            // this.optionsSelectVeiculos = dados[this.VeiculosTipo];
            // this.loadingVeiculos = false
        },

        gerarRelatorio(){
            this.dadosSemComunicacao = [];
            this.loadingPanel = true;
            var tipoBusca = this.VeiculosTipo;
            let obj = {
                'cliente': this.empresasArray, 
                'veiculos':this.veiculosArray, 
                'tempo': this.horasInput, 
                'tipo': tipoBusca
            }

            new HttpRequest().Post(this.link + 'gerar', obj)
                .then((dados) => {
                    this.tamanhoArraySemComunicacao = dados.data.tamanho
                    if(dados.data.tamanho > 0) {
                        this.dadosSemComunicacao = dados.data.semComunicacao     
                    } else {
                        this.dadosSemComunicacao = []
                        this.statusBar = 'error'
                    }
                }).catch(() => {
                    conectionError()
                }).finally(() => this.loadingPanel = false)
        },

        exportarRelatorio(tipo){
            switch (tipo){
                case 'pdf':
                    this.loadingExporta = [true, false, false]
                    break;
                case 'xls':
                    this.loadingExporta = [false, true, false]
                    break;
                default:
                    this.loadingExporta = [false, false, true]
                    break;
            }
            var root =  process.env.VUE_APP_ROOT;
            let obj = {
                'arrayDados':JSON.stringify(this.dadosSemComunicacao),
                'tipo':tipo
            }
            new HttpRequest().Post(this.link + 'exportar',obj)
                .then((dados) => {
                    window.open(root+'/'+dados.data.local)
                }).catch(() => {
                    conectionError()
                }).finally(() => {
                    this.loadingExporta = [false, false, false]
                })
        },

        transformaData(data){
            var editData = moment(data).format('DD/MM/YYYY HH:mm:ss')
            return editData
        }
    },

    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optionsSelectEmpresa
        }
    }
})
</script>

<style lang="scss">
    #semComunicacao{
        .paddingLeftRight{
            padding-right: 0px!important;
            padding-left: 30px!important;
        }
    
        .blueIconLocalizar{
            color: #428bca;
            cursor: pointer;
        }
        .headerRegisterPDF{
            td{
                vertical-align: middle;
            }
        }
    }
</style>