/**
    Componente para o select de tags do cadastro de veiculos
    -> Propriedades:
        isMultiple - Select  multiplo
        selected - Array - array dos itens ja selecionados
        cor - cor da tag, usada para salvar emitir o valor do componente
        optionsArray - Array - array de opções para o select
        label - texto titulo do select 
        isDisabled - está desabilitado?
        isLoading - está carregando?
    -> Slots:
        slot para fazer as tags
            para saber mais sobre o slot
            recomendo ver na documentação do multiselect
    -> Eventos:
        setTag - emitido quando Seta uma nova tag
            tag - nome da tag
        createNewTag - emitido quando Cria uma nova tag
            userInput - input do usario com o nome da nova tag
        excluiTag - Exclui uma tag :3
        changeColor - emite um evento com a nova cor

    @author Marcos
*/

<template>
    <div :class="estiloDivPadrao">
        <!-- <div
            class="col-sm-12 nopadding subTitleSelect"
            v-if="labels.length > 1"
        >
            <b-form-group>
                <b-form-radio-group v-model="labelSelected" @input="clearAll">
                    <b-form-radio
                        v-for="label in labels"
                        v-bind:key="label.indexDFH"
                        v-model="labelSelected"
                        checked
                        class="mb-03"
                        :value="label.indexDFH"
                    >
                        {{ label.description }}
                    </b-form-radio>
                </b-form-radio-group>
            </b-form-group>
        </div> -->
        <div v-if="label != ''" class="col-sm-12 nopadding subtitle quebraTexto" :title="label">
            {{ label }}
        </div>
        <div class="col-sm-12 nopadding">
            <Multiselect v-model="selectedItens" :multiple="isMultiple" :taggable="taggable" :options="optionsSelect"
                :close-on-select="false" label="description" :searchable="true" :disabled="isDisabled" :loading="isLoading"
                track-by="value" selectLabel="•" selectedLabel="✔" deselectLabel="x" tag-position="bottom" :limit="1"
                :limitText="(count) => `e mais ${count}`" placeholder="Selecione uma tag" @input="setTag"
                @tag="createNewTag">
                <template slot="tag" slot-scope="props">
                    <div class="multiselect_tag">
                        <div class="tagStyle" :style="'background:' + props.option.cor">
                            <span v-if="!abrirFecharEditar">
                                {{ props.option.description }}
                            </span>
                            <input v-else v-model="props.option.description" class="nopadding input-editar"
                                name="inputCodigoExterno" label="" placeholder="Escreva uma descrição" type="text"
                                ref="editaCodigoExterno" @keyup.enter="
                                    salvaDescricao(props.option)
                                    " />
                            <div class="botoes">
                                <BIcon v-if="taggable" :id="'popEditaTag' + props.option.value"
                                    class="cursor-pointer icone-tres-pontinhos"
                                    @mousedown.prevent.stop="abrePopOver(props.option.value)" icon="three-dots-vertical" />
                                <BIcon class="cursor-pointer close-x" @mousedown.prevent.stop="props.remove(props.option)"
                                    icon="x" />
                            </div>
                        </div>
                        <b-popover :target="'popEditaTag' + props.option.value" triggers="manual" placement="bottom"
                            :ref="'popEditaTag' + props.option.value">
                            <div class="col-sm-12">
                                <span class="iconFechar" @click.stop.prevent="fechaPopOver(props.option.value)">
                                    <BIcon class="cursor-pointer close-x-popup" icon="x" />
                                </span>
                            </div>
                            <div class="col-sm-12">
                                <div>
                                    <span style="cursor: pointer" @click="excluiTag(props.option.value)">
                                        <BIcon class="cursor-pointer" icon="trash-fill" />
                                        Excluir
                                    </span>
                                </div>
                                <div>
                                    <InputColorPicker style="width: 15px; height: 15px" class="inputCor"
                                        v-model="props.option.cor" @change="changeColor(props.option)" />
                                    Cor
                                </div>
                                <div>
                                    <span class="cursor-pointer" @click="
                                        mudaEditar(props.option),
                                        fechaPopOver(props.option.value)
                                        ">
                                        <BIcon icon="pen" />
                                        Editar
                                    </span>
                                </div>
                            </div>
                        </b-popover>
                    </div>
                </template>
                <template slot="noResult"> Nenhum elemento encontrado </template>
                <template slot="noOptions"> Nenhum elemento encontrado </template>
            </Multiselect>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import InputColorPicker from "vue-native-color-picker";
import { isArray } from "util";
import Multiselect from "vue-multiselect";
// import InputSimple from '@/components/Atom/Inputs/InputSimple'
export default {
    components: {
        Multiselect,
        InputColorPicker,
        // InputSimple
    },
    props: {
        estiloDivPadrao: {
            type: String,
            required: false,
            default: "col-sm-12",
        },
        taggable: {
            type: Boolean,
            default: true,
        },

        isMultiple: {
            type: Boolean,
            default: true,
        },
        selected: {
            type: Array,
            required: false,
            default: function () {
                return [];
            },
        },
        cor: {
            type: String,
            required: false,
            default: "#ffff",
        },
        optionsArray: {
            type: Array,
            required: true,
        },
        label: {
            type: String,
            required: false,
            default: "",
        },

        labels: {
            type: Array,
            required: false,
            default: function () {
                return [];
            },
        },

        isDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            selectedItens: this.selected,
            color: this.cor,
            options: this.optionsArray,
            labelSelected: "TAG",
            abrirFecharEditar: false,
            arrayTagsAux: [],
        };
    },
    methods: {
        /**
         * Salva a nova descrição só caso ela seja
         *  != ''
         */
        salvaDescricao(tag) {
            if (tag.description) {
                this.$emit("salvaDescricao", tag);
                this.abrirFecharEditar = !this.abrirFecharEditar
            }
        },

        /**
         * Permite usuario editar ou
         * parar de editar caso ele tenha
         * colocado algo na descript
         */
        mudaEditar(tag) {
            if (tag.description) {
                this.abrirFecharEditar = !this.abrirFecharEditar
                tag.editar = !tag.editar;
            }
        },

        /**
         * Limpa o select
         */
        clearAll() {
            this.selectedItens = [];
            this.changeLabelSelect();
            this.setTag([]);
            this.$forceUpdate();
        },

        /**
         * Só muda nova descrição caso ela seja
         *  != ''
         */
        changeDescricao(v, tag) {
            if (v != "") tag.description = v;
        },

        setTag(tag) {
            this.$emit("setTag", tag);
        },

        createNewTag(userInput) {
            this.$emit("createNewTag", userInput);
        },

        excluiTag(obj) {
            this.arrayTagsAux = this.selectedItens.filter((item) => {
                if (item.value != obj.value) {
                    return item
                }
            })
            this.$emit("excluiTag", obj);
        },

        changeColor(color) {
            this.$emit("changeColor", color);
        },

        /**
         * Abre a popover do icone de
         * 3 pontinhos
         */
        abrePopOver(codigo) {
            let tag = `popEditaTag${codigo}`;
            this.$root.$emit("bv::hide::popover");
            this.$root.$emit("bv::show::popover", tag);
        },

        /**
         * Fecha a popover do icone de
         * 3 pontinhos
         */
        fechaPopOver(codigo) {
            let tag = `popEditaTag${codigo}`;
            this.$root.$emit("bv::hide::popover", tag);
        },

        changeLabelSelect() {
            this.$emit("changeCheck", this.labelSelected, true);
        },
    },
    watch: {
        selected() {
            if (isArray(this.selected)) {
                this.selectedItens = this.selected;
                this.setTag(this.selected);
            }
        },

        optionsArray() {
            this.clearAll();
            if (this.optionsArray[0].value == "ST") this.optionsArray.shift();
            this.options = this.optionsArray;
        },
    },
    computed: {
        optionsSelect() {
            return this.options;
        },
    },
};
</script>

<style lang="scss">
.multiselect_tag {
    display: inline-flex;
    flex-direction: row;
    margin: 1px;
}

.tagStyle {
    display: flex;
    gap: 5px;
    justify-content: space-around;
    color: #ffff;
    border-radius: 5px 0px 5px 0px;
    padding: 3px;
    word-break: break-all;
    font-weight: bold;
    max-width: 300px;

    .icone-tres-pontinhos {
        color: #eeee;
    }
}

.icone-tres-pontinhos:hover {
    transition: all 0.3s ease-in-out;
    font-size: 14px;
}

.close-x:hover {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
    background: #a99ab5;
    border-radius: 50px;
}

.close-x-popup {
    //position:relative;
    font-size: 17px;
    margin-left: 90%;
}

.close-x-popup:hover {
    color: white;
    transition: all 0.3s ease-in-out;
    font-size: 18px;
    border-radius: 50px;
    background: #a99ab5;
}

.subtitle {
    font-size: 13px;
    text-align: left;
    margin-bottom: 1px;
}

.botoes {
    vertical-align: center;
    justify-content: center;
}

.input-editar {
    border-radius: 5px;
    border: 1px solid #eee;
}
</style>
