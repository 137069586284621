<template>
    <panelEagle id='Registrar' :loading='loadingPanel'>
        <div class="col-sm-12 nopadding row">
            <div class="col-sm-6">
                <tituloPage 
                :icon='mdiFuel' 
                titulo='Tipo combustível'/>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 ">
                <basicButtonsCadastrosSC
                    :disabled="$v.$invalid"
                    @salvarCadastro='salvarCadastro'
                    tipoCancela='button'
                    linkCancel ='combustivel'>
                </basicButtonsCadastrosSC>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class="row col-sm-12 nopadding">
            <div class="col-sm-6 nopadding">
                <inputSimple
                    label='Descrição*'
                    :value="valueDescricao"
                    :inputClass='{"border border-danger":$v.valueDescricao.$anyError}'
                    @blur="$v.valueDescricao.$touch()"
                    @changeInput='changeDescricao'/>
            </div>
            <div class="col-sm-6 nopadding">
                <selectAll
                    nameForRadio  ='Selecione*'
                    :isMultiple   ='false'
                    :optionsArray ='optEmpresas'
                    :labels       ='labelsEmp'
                    :selected     ='selectedEmpresa'
                    :extraClass='{"border border-danger":$v.valueEmpresa.$anyError}'
                    @close='$v.valueEmpresa.$touch()'
                    @changeSelect ='changeEmpresa'
                />
            </div>
            <div class="col-sm-6 pl-0">
                <requiredFields/>
            </div>
            <div class="col-sm-12"><hr></div>
        </div>
    </panelEagle>
</template>
<script>
import {mdiFuel } from '@mdi/js'
import Vue  from 'vue'
import { required } from 'vuelidate/lib/validators'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { conectionError } from '@/Services/Helpers/swellHeper'
export default Vue.extend({
	name:'EditarTipoCombustivel',
	components:{
		'panelEagle'               : require('@/components/Atom/Panel/PanelEagle').default,
		'basicButtonsCadastrosSC'  : require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
		'tituloPage'               : require('@/components/Atom/Header/Titulo').default,
		'selectAll'                : require('@/components/Atom/Select/SelectAll').default,
		'inputSimple'              : require('@/components/Atom/Inputs/InputSimple').default,
		'requiredFields'           : require('@/components/Atom/Footer/RequiredFields').default,
	},
	data(){
		return {
			mdiFuel:mdiFuel,
			id:0,
			optEmpresas : new EmpresasService().Get(),
			labelsEmp   :[{indexDFH: 'EM', description: 'Empresa*'}],
			valueEmpresa   :[],
			valueDescricao :'',
			loadingPanel   :false,
			selectedEmpresa:[],
		}
	},
	validations:{
		valueEmpresa  : { required },
		valueDescricao: { required }
	},
	methods:{

		// CREATE SEQUENCE tipo_combustivel_tccodigo_seq OWNED BY tipo_combustivel.tccodigo;
		// ALTER TABLE tipo_combustivel ALTER tccodigo set default nextval('tipo_combustivel_tccodigo_seq');
		// ALTER SEQUENCE tipo_combustivel_tccodigo_seq RESTART WITH 30;
		changeEmpresa(value){
			this.valueEmpresa = value
		},
		async salvarCadastro(){
			this.loadingPanel  = true
			try{
				var obj = {
					'descricao' : this.valueDescricao,
					'empresa'   : this.valueEmpresa[0],
					'id'        : this.id,
				}
				new HttpRequest()
					.Post('/abastecimento/menu/tipo/combustivel/salvar', {'dados':obj})
					.then((dados)=>{
						this.loadingPanel  = false
						this.$router.push({name:'combustivel'})
					}).catch((err)=>{
						this.loadingPanel  = false
						conectionError()
					})
			}catch{
				this.loadingPanel  = false
				conectionError()
			}finally{
				this.loadingPanel  = false
			}
            
		},
		changeDescricao(value){
			this.valueDescricao = value
		}
	},
	async mounted(){
		if(this.$route.params.id != undefined){
			this.loadingPanel  = true
			this.id = this.$route.params.id
			var dados = await new HttpRequest()
				.Post('/abastecimento/menu/tipo/combustivel/buscaDadosId', {'id':this.id})
			this.valueDescricao  = dados.data[0].descricao
			this.selectedEmpresa = [{
				value: dados.data[0].value, 
				description: dados.data[0].description
			}]
			this.valueEmpresa = dados.data[0].value
			this.loadingPanel  = false
		}else{
			this.id = 0 
		}
	}
})
</script>
<style lang="scss" scoped>
    
</style>
