<template>
    <div>
    <div :id="`popuppontorota${ponto.ircodigo}`">
        <div v-if="ponto.irinterjornada || ponto.irintrajornada">
            <div class='nome-ponto' v-if="ponto.irnome">
              <strong>{{ 'Código externo - Nome: ' }}</strong>{{ponto.ircodigoexterno}} - {{ ponto.irnome }}
            </div>
            <div>
              <strong>{{ 'Tipo: ' }}</strong>{{ponto.irinterjornada ?  'Interjornada'  : ''}} {{ ponto.irintrajornada ?  'Intrajornada'  : '' }}
            </div>     
            <div>
              <strong>{{ 'Tempo: ' }}</strong>{{ponto.irtempoparado ?  ponto.irtempoparado : ''}} 
            </div>    
        </div>
        <div v-else>
            <div class='nome-ponto' v-if="ponto.irnome">
                <strong>{{ 'Código externo - Nome: ' }}</strong>{{ponto.ircodigoexterno}} - {{ ponto.irnome }}
            </div>
            <div class='janela-entrega-ponto' v-if="ponto.irinijanelaentrega || ponto.irfimjanelaentrega">
                <strong>{{ 'Janela de entrega: ' }}</strong>{{ ponto.irinijanelaentrega ? formataHoraSql(ponto.irinijanelaentrega) : '' }}{{ ponto.irfimjanelaentrega ? ' | ' + formataHoraSql(ponto.irfimjanelaentrega) : '' }}
            </div>
            <div  v-if="ponto.irtempoprevistohoras">
                <strong>{{ 'Estimativa prevista: ' }}</strong>{{ponto.irtempoprevistohoras}}
            </div>
            <div class="info-group" v-if="ponto.irpeso || ponto.irvalor || ponto.ircubagem">
                <div class='info-item' v-if="ponto.irpeso">
                    <strong>Peso:</strong> {{ Number(ponto.irpeso).toFixed(2) }} Kg
                </div>
                <div class='info-item' v-if="ponto.irvalor">
                    <strong>Valor: R$</strong> {{ Number(ponto.irvalor).toFixed(2) }}
                </div>
                <div class='info-item' v-if="ponto.ircubagem">
                    <strong>Volume:</strong> {{ Number(ponto.ircubagem).toFixed(1) }} m³
                </div>
            </div>
            <div v-if="ponto.irqtde">
            <strong>{{ 'Quantidade: ' }}</strong>{{ ponto.irqtde }}
            </div>
            <div v-if="ponto.ponto.poendereco">
                <strong> {{ 'Endereço: ' }}</strong>{{ ponto.ponto.poendereco }}
            </div>
            <div v-if='ponto.irstatus === "J"'>
                <div v-if="ponto.judescricao">
                    <strong>{{ 'Justificativa: ' }}</strong>{{ ponto.judescricao }}
                </div>
                <div v-if="ponto.irobservacoes">
                    <strong>{{ 'Observação: ' }}</strong>{{ ponto.irobservacoes }}
                </div>
                <simpleButton event="click" text="" :icon="iconBotaoVisualizaDocumentos" type="white" width="70px"
                    class="mt-5" @click="buscaDocumentos(ponto.ircodigo, ponto.ponto.potipo)" :title="titleBotaoVerDocumentos" />
            </div>

            <div v-else-if="ponto.irstatus === 'P'">
                O veículo não passou por esse ponto.
            </div>

            <div v-else-if="ponto.irstatus === 'F'">
                <div v-if="ponto.irdata_hora_evento">
                    <strong>{{ 'Chegada: ' }} </strong>{{formataHoraSql(ponto.irdata_hora_evento) }}
                </div>
                <div v-if="ponto.irTempoAtraso">
                    <strong>{{ 'Atraso: ' }} </strong>{{ ponto.irTempoAtraso }}
                </div>
                <div class="button-documentos">
                <simpleButton event="click" text="" :icon="iconBotaoVisualizaDocumentos" type="white" width="70px"
                    class="mt-5" @click="buscaDocumentos(ponto.ircodigo, ponto.ponto.potipo)" :title="titleBotaoVerDocumentos" />
                </div>
            </div>
        </div>
    </div>
    <panelEagle :id="`painelDocumentosRotas${ponto.ircodigo}`" :loading="loadingDocumentos">
    </panelEagle>
    <div v-if="!loadingDocumentos">
    <div class="carrosel-container" v-if="showDocumento">
        <div v-if="dadosDocumentos.documentos.length > 0">
        <CarrosselDocsItemRota v-if="mostrarCarrossel" :id="`refCarouselPontoRota${ponto.ircodigo}`" :dados="dadosDocumentos.documentos">
        </CarrosselDocsItemRota>
        </div>
        <div v-else>
            <h4>Nenhum documento encontrado</h4>
        </div>
    </div>
    </div>
    </div>

</template>

<script>
import { DateTime, Duration } from 'luxon'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import CarrosselDocsItemRota from '@/components/Atom/SpecificComponents/DetalhesRota/CarrosselDocsItemRota.vue';
import {
    mdiEye,
    mdiEyeOff,

} from '@mdi/js'
import SimpleButton from '@/components/Atom/Buttons/SimpleButton.vue';
export default {
    components: {
        CarrosselDocsItemRota,
        SimpleButton,
        'panelEagle': require('@/components/Atom/Panel/PanelEagle.vue').default,
    },
	props:{
		ponto:{
			required:true
		}
	},
    data() {
        return {
            dadosDocumentos: {
                documentos: [] 
            },
            urlDocumentos: '/roteirizador/relatorios/detalhes/rota/buscar/detalhes',
            mostrarCarrossel: false,
            titleBotaoVerDocumentos: 'Visualizar Documentos',
            iconBotaoVisualizaDocumentos: mdiEye, 
            showDocumento: false,
            loadingDocumentos: false,
        }
    },
	methods:{
		/**
		 * @param {object} tempo - objeto com o tempo que o veículo ficou parado
		 * @return {string} tempo total formatado para o usuário
		 * @author Gui 🍺
		 */
		tempoParado(tempo){
			delete tempo.secondsAll
			var parado = Duration.fromObject(tempo)
			if(parado.get('days'))
				return parado.toFormat('dd\'d\' hh\'h\' mm\'m\'')
			return parado.toFormat('hh\'h\' mm\'m\'') 
		},

		/**
		 * @param {string} data - quando o veículo parou, em formato SQL
		 * @return {string} data no formato dia/mes/ano hora:minuto
		 */
		formataHoraSql(data){
            if(data === null){
                return '';
            }
			var realizado = DateTime.fromSQL(data)
			return realizado.toFormat('dd/LL/yyyy T')
		},

        /**
         * @description controla o valor da variavel showGraficoMapa
         * pra setar o titulo e o ícone do estado atual
         * @author Otávio 🦆 
        */
        showDocumentos() {
            if (this.showDocumento) {
                this.titleBotaoVerDocumentos = 'Ocultar documentos';
                this.iconBotaoVisualizaDocumentos = mdiEyeOff;
            } else {
                this.titleBotaoVerDocumentos = 'Visualizar documentos';
                this.iconBotaoVisualizaDocumentos = mdiEye;
            }
        },

        /**
         * @params ircodigo 
         * @params statusPonto
         * @description faz a requisição com base nos parametros
         * para buscar os documentos na tabela itens_rotas_documentos
         * @author Otávio 🦆 
        */
        buscaDocumentos(irocdigo, statusPonto) {
            this.showDocumento = !this.showDocumento;
            if(this.showDocumento){
                this.loadingDocumentos = true;
                let body = { 'tipo': statusPonto, 'codigo': irocdigo }
                new HttpRequest().Post(this.urlDocumentos, body)
                    .then((dados) => {
                        if (dados.code == 200) {
                            this.dadosDocumentos = dados.data.dados
                            this.statusItemSelecionado = statusPonto
                            this.mostrarCarrossel = true;
                        } else {
                            this.toastShow('Erro ao carregar arquivos', 'danger', 'Erro')
                        }
                    })
                    .finally(() => {
                        this.loadingDocumentos = false;
                    })
            }
            this.showDocumentos();
        }
    },
}
</script>

<style lang="scss">
[id^='popuppontorota'] {
    min-height: 100px;
    min-width: 200px;
    height: auto; 
    width: 310px;
    display: flex;
    flex-direction: column; 

    .nome-ponto, .janela-entrega-ponto, .button-documentos {
        font-size: 13px;
        word-wrap: break-word;
        margin-bottom: 10px;
        width: 300px;
    }
    .info-group {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .info-item {
            margin-left: -13px; 
            padding: 0; 
        }
    }
    .button-documentos {
        align-self: flex-start;
        margin-top: auto;
        margin-top: -26px;
    }
}
</style>