<template>
    <panelEagle id='Modulos' :loading='loadingPanel'>
        <div class="col-sm-12 nopading row">
            <div class="col-sm-5 nopadding">
                <tituloPage :icon='mdiCellphoneWireless' titulo='Módulos'></tituloPage>
            </div>
            <div class="col-sm-7 pr-0 divDontPrint">
                <basicButtonsCadastrosEIN 
                    :disabledDropdown="!Object.keys(dadosTabela).length"
                    tipoNovo='button'
                    linkNew="cadastrarModulos"
                    @exportarCadastro='exportar'
                    :loading="loadingExporta">
                    <template>
                        <loadingButton
                            :disabled="!Object.keys(dadosTabela).length"
                            type='blue'
                            text="Relação Estoque"
                            event='click'
                            width='25%'
                            title='Relação Estoque'
                            :isLoading='carregandoButton'
                            :icon='mdiFilePdfBox'
                            @click='exportaEstoque'>  
                        </loadingButton>
                    </template>
                </basicButtonsCadastrosEIN>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class="col-sm-12 col-md-12 nopadding row">
            <div class="col-sm-4 nopadding row">
                <div class="col-sm-12 nopadding divDontPrint">
                    <selectAll 
                        nameForRadio='FiltroEmpresas'
                        :labels="labels"
                        :isMultiple='true'
                        :optionsArray='optSelectEmpresas'
                        :hasSelectAll='true'
                        @changeSelect='changeEmpresa'>
                    </selectAll>
                </div>
            </div>
            <div class="col-sm-8 nopadding row">
                <div class="col-lg-6  left divDontPrint nopadding">
                    <buttonsFilters
                        label='Chip'
                        :arrayButtons='optFiltroChip'
                        @buttonsFiltersChange='mudaFiltroChip'/>
                </div>
                <div class="col-lg-6  left divDontPrint nopadding">
                    <buttonsFilters
                        label='Status'
                        :arrayButtons='optFiltroStatus'
                        @buttonsFiltersChange='mudaFiltroStatus'/>
                </div>
                <div class="col-lg-6  left divDontPrint nopadding mt-3">
                    <buttonsFilters
                        label='Associado'
                        :arrayButtons='optFiltroAssociado'
                        @buttonsFiltersChange='mudaFiltroAssociado'/>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <tableListagem
            :data ='dadosTabela'
            :titles ='titles'>
            <template #acoes='{row}'>
                <simpleButton 
                    type='green'
                    :icon='mdiEyeOutline'
                    title='Detalhes'
                    event='click'
                    width='31%'
                    v-b-popover.hover.top.html="vizualizarDetalhesPopover(row.veplaca, row.empresa)"
                />
                <simpleButton 
                    type='green'
                    :icon='mdiPencil'
                    title='Editar Módulo'
                    event='click'
                    width='31%'
                    @click='editarModulo(row.mocodigo)'
                />
                <simpleButton 
                    :type='row.mostatus=="A"?"red":"light-green"'
                    :icon='row.mostatus=="A"? mdiCircleOffOutline : mdiCheckBold'
                    :title='row.mostatus=="A"? "Desativar módulo":"Ativar módulo"'
                    event='click'
                    width='31%'
                    @click='mudarStatus(row.mocodigo)'
                />
            </template>
        </tableListagem>
    </panelEagle>
</template>
<script>
import Vue  from 'vue'
import{mdiCellphoneWireless, mdiFilePdfBox, mdiPlus, mdiPencil, mdiEyeOutline,
	mdiCircleOffOutline, mdiCheckBold } from '@mdi/js'
import { EmpresasService }               from '@/Services/auth/Empresas.service'
import { conectionError }                from '@/Services/Helpers/swellHeper'
import { HttpRequest }                   from '../../../../Services/auth/HttpRequest.Service'
export default Vue.extend({
	name: 'modulos',
	components:{
		'simpleButton'             : require('@/components/Atom/Buttons/SimpleButton').default,
		'panelEagle'               : require('@/components/Atom/Panel/PanelEagle').default,
		'tituloPage'               : require('@/components/Atom/Header/Titulo').default,
		'selectAll'                : require('@/components/Atom/Select/SelectAll').default,
		'buttonsFilters'           : require('@/components/Atom/Buttons/ButtonsFilters').default,
		'BasicButtonsCadastrosEIN' : require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
		'tableListagem'            : require('@/components/Atom/Table/TableListagem').default,
		'loadingButton'            : require('@/components/Atom/Buttons/LoadingButton').default,
	},
	data(){
		return {
			mdiCellphoneWireless : mdiCellphoneWireless,
			mdiFilePdfBox    : mdiFilePdfBox,
			mdiPencil            : mdiPencil,
			mdiPlus              : mdiPlus,
			mdiEyeOutline        : mdiEyeOutline,
			mdiCircleOffOutline  : mdiCircleOffOutline,
			mdiCheckBold         : mdiCheckBold,
			optSelectEmpresas      : new EmpresasService().Get(),
			labels:[
				{indexDFH    : 'EM', 
					description  : 'Empresas'}
			],
			optFiltroChip:[
				{text:'Com',   value:'C', width:'31.9%', isSelected:false, 'icon':''},
				{text:'Sem',   value:'S', width:'31.9%', isSelected:false, 'icon':''},
				{text:'Todos', value:'T', width:'31.9%', isSelected:true, 'icon':''}
			],
			optFiltroStatus:[
				{text:'Ativos',   value:'A', width:'31.9%', isSelected:true, 'icon':''},
				{text:'Inativos', value:'D', width:'31.9%', isSelected:false, 'icon':''},
				{text:'Todos',    value:'T', width:'31.9%', isSelected:false, 'icon':''}
			],
			optFiltroAssociado:[
				{text:'Sim',   value:'S', width:'31.9%', isSelected:false, 'icon':''},
				{text:'Não',   value:'N', width:'31.9%', isSelected:false, 'icon':''},
				{text:'Todos', value:'T', width:'31.9%', isSelected:true, 'icon':''}
			],
			titles :[
				{'number':'mocodigo',    'name':'Serial'},
				{'number':'moimei',      'name':'IMEI'},
				{'number':'chnumero',    'name':'CHIP'},
				{'number':'mmdescricao', 'name':'Modelo'},
				{'number':'clfantasia',  'name':'Propietário'},
			],
			dadosTabela                : [],
			modulosUsados              : [],
			carregando                 : false,
			loadingPanel               : false,
			link                       : '/administrativo/cadastros/modulo/',
			empresasSelecionadas       : [],
			detalhesModulos            : [],
			filtroChipSelecionado      : '',
			filtroStatusSelecionado    : '',
			filtroAssociadoSelecionado : '',
			loadingExporta             : [false, false, false],
			carregandoButton           : false,
			objectDetalhesModulos      : new WeakMap(),
		}
	},
	methods:{
		changeEmpresa(empresas){
			this.empresasSelecionadas = empresas
			this.gerarTabela(empresas)
		},

		mudaFiltroChip(filtroChip){
			this.filtroChipSelecionado = filtroChip
			this.optFiltroChip[0].icon = ''
			this.optFiltroChip[1].icon = ''
			this.optFiltroChip[2].icon = ''
			if(filtroChip[0] == 'C'){
				this.optFiltroChip[0].icon = mdiCheckBold
			} else if(filtroChip[0] == 'S'){
				this.optFiltroChip[1].icon = mdiCheckBold
			} else if(filtroChip[0] == 'T'){
				this.optFiltroChip[2].icon = mdiCheckBold
			}
			this.gerarTabela(this.empresasSelecionadas)
		},

		mudaFiltroStatus(filtroStatus){
			this.filtroStatusSelecionado = filtroStatus
			this.optFiltroStatus[0].icon = ''
			this.optFiltroStatus[1].icon = ''
			this.optFiltroStatus[2].icon = ''
			if(filtroStatus[0] == 'A'){
				this.optFiltroStatus[0].icon = mdiCheckBold
			} else if(filtroStatus[0] == 'D'){
				this.optFiltroStatus[1].icon = mdiCheckBold
			} else if(filtroStatus[0] == 'T'){
				this.optFiltroStatus[2].icon = mdiCheckBold
			}
			this.gerarTabela(this.empresasSelecionadas)
		},

		mudaFiltroAssociado(filtroAssociado){
			this.filtroAssociadoSelecionado = filtroAssociado
			this.optFiltroAssociado[0].icon = ''
			this.optFiltroAssociado[1].icon = ''
			this.optFiltroAssociado[2].icon = ''
			if(filtroAssociado[0] == 'S'){
				this.optFiltroAssociado[0].icon = mdiCheckBold
			} else if(filtroAssociado[0] == 'N'){
				this.optFiltroAssociado[1].icon = mdiCheckBold
			} else if(filtroAssociado[0] == 'T'){
				this.optFiltroAssociado[2].icon = mdiCheckBold
			}
			this.gerarTabela(this.empresasSelecionadas)
		},

		async gerarTabela(empresas){
			this.loadingPanel = true
			var obj = {
				'clientesbusca' : empresas,
				'chip'          : this.filtroChipSelecionado[0],
				'associado'     : this.filtroAssociadoSelecionado[0],
				'status'        : this.filtroStatusSelecionado[0]
			}
			new HttpRequest().Post(this.link+'listar', obj).then((data)=>{
				if(data.data.modulos.length > 0){
					this.dadosTabela = data.data.modulos
					this.modulosUsados = data.data.modulosUsados
					this.loadingPanel = false
				} else {
					this.dadosTabela = []
					this.loadingPanel = false
				}
			})
		},

		/**
             * Método mostrar os dados do popover
             * @param Number placa(placa do veículo -) e empresa(empresa associada ao)
             * @return Arrays com os valores dos selects e inputs
             * @author Vitor Hugo 🐨
             */
		vizualizarDetalhesPopover(placa, empresa){
			var dadosPopover = ''
			if(placa == null || empresa == null){
				dadosPopover = '<b>'+'Detalhes'+'</b><br>';
				dadosPopover += 'Em estoque'+'<br>';
			} else {
				dadosPopover = '<b>'+'Detalhes'+'</b><br>';
				dadosPopover += 'Empresa: '+empresa+'<br>';
				dadosPopover += 'Placa: '+placa+'<br>';
			}
			return dadosPopover
		},

		editarModulo(value){
			this.$router.push({name:'cadastrarModulos', params:{'id':value}})
		},

		/**
             * Método mudar status do módulo
             * @param Number código do módulo
             * @author Vitor Hugo 🐨
             */
		mudarStatus(codigo){
			new HttpRequest().Post(this.link+'mudar/status', codigo).then((data)=>{
				if(data.data == 201){
					this.gerarTabela(this.empresasSelecionadas)
				} else {
					conectionError()
				}
			})
		},

		exportar(type){
			if(this.dadosTabela.length != 0){
				switch (type) {
					case 'pdf':
						this.loadingExporta = [true, false, false]
						break;
					case 'xls':
						this.loadingExporta = [false, true, false]
						break;
					default:
						this.loadingExporta = [false, false, true]
						break;
				}
				var obj = {
					'tipo'    : type,
					'empresa' : this.empresasSelecionadas[0],
					'dados'   : this.dadosTabela
				}
				var root =  process.env.VUE_APP_ROOT;
				new HttpRequest().Post(this.link+'exportar', obj).then((dados)=>{
					open(root+'/'+dados.data.local);
					this.loadingExporta = [false, false, false]
				})
			}
		},

		exportaEstoque(){
			if(this.dadosTabela.length != 0){
				this.carregandoButton = true
				var root =  process.env.VUE_APP_ROOT;
				new HttpRequest().Post(this.link+'exporta/estoque',).then((dados)=>{
					open(root+'/'+dados.data.local);
					this.carregandoButton = false

				})
			}
		},

	},
})
</script>

<style>

</style>