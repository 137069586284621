<template>
    <panelEagle id='CadastroUsuariosCP' :loading='loadingPanelEagle'>
        <div class="col-sm-12  nopadding">
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-6 row ">
                    <tituloPage titulo='Usuários' :icon='mdiAccount'>
                    </tituloPage>
                </div>
                <div class="col-sm-6 row divDontPrint">
                    <basicButtonsCadastrosSC 
                        linkCancel="listagemUsuariosCP"
                        :disabled='$v.$invalid || !cpfValido'
                        tipoCancela="button" 
                        @salvarCadastro="salvarCadastro">
                    </basicButtonsCadastrosSC>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-4 nopadding">
                   <inputSimple
                        type="text"
                        label="Email*"
                        :inputClass='{"boder border-danger":$v.email.$anyError}'
                        :value="email"
                        @blur='$v.email.$touch()'
                        @changeInput="setDescricao"
                    />
                    <simpleAlert 
                        v-if="saveError"
                        type='red'
                        :isLoading='false'
                        :message='saveError' 
                        :hasClick='true'/>
                </div>
                <div class="col-sm-4 nopadding">
                    <selectAll
                        nameForRadio="nameForRadio"
                        :isMultiple="false"
                        :hasSelectAll="false"
                        firstSelected ="EM"
                        :labels="[{'indexDFH':'EM', 'description':'Empresa*'}]"
                        :optionsArray="optEmpresas"
                        :selected="selectedEmpresa"
                        :extraClass="{'border border-danger':$v.empresaSelecionada.$anyError}"
                        @close="$v.empresaSelecionada.$touch()"
                        @changeSelect='changeSelectEmpresa'
                    />
                </div>
                <div class="col-sm-4 nopadding">
                   <buttonsFilters
                        label="Status*" 
                        :onlyOneSelected='true'
                        :arrayButtons='filtroStatus'
                        @buttonsFiltersChange='changeFiltros'>
                    </buttonsFilters>
                </div>
                <div class="col-sm-4 nopadding mt-2">
                      <inputSimple
                            :inputClass='{"boder border-danger":cpfValidationBorder}'
                            id='cpf'
                            :value="cpf"
                            :hasMask="true"
                            :mask="['NNN.NNN.NNN-NN']"
                            label="CPF*"
                            @keyup='$v.cpf.$touch()'
                            @changeInput="setCpf"
                        />
                </div>
                <div class="col-sm-4 nopadding mt-2">
                <selectAll
                    ref="refDepartamentos"
                    nameForRadio="departamentos"
                    :isMultiple="false"
                    :hasSelectAll="false"
                    firstSelected ="D"
                    :labels="[{'indexDFH':'D', 'description':'Departamento*'}]"
                    :optionsArray="optDepartamentos"
                    :selected="selectedDepartamentos"
                    :disabled="$v.empresaSelecionada.$invalid"
                    :loading="loadingPanelEagle"
                    :extraClass="{'border border-danger':$v.dptoSelecionado.$anyError}"
                    @close="$v.dptoSelecionado.$touch()"
                    @changeSelect='setDepartamentos'
                />
                </div>
                <div class="col-sm-4 nopadding">
                   <buttonsFilters
                        label="Alerta por e-mail*" 
                        :onlyOneSelected='true'
                        :arrayButtons='filtroAlerta'
                        @buttonsFiltersChange='changeFiltrosAlerta'>
                    </buttonsFilters>
                </div>
            </div>
            <div class="col-sm-12 mt-5"><hr></div>
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-12 mt-4 mb-2">
                    <h4 align="left">Configurações de acesso do checkpoint</h4>
                </div>
                <div class="col-sm-4 nopadding row"
                    style="display: inline-flex; float: left; flex-wrap: nowrap;">
                    <selectAll
                        nameForRadio="nameForRadio"
                        :isMultiple="false"
                        :hasSelectAll="false"
                        firstSelected ="P"
                        :labels="[{'indexDFH':'P', 'description':'Perfil*'}]"
                        :optionsArray="optPerfil"
                        :selected="selectedPerfil"
                        :extraClass="{'border border-danger':
                            $v.perfilSelecionado.$anyError}"
                        @close="$v.perfilSelecionado.$touch()"
                        @changeSelect='setPerfil'
                    />
                    <simpleButton
                        style="height: 43px;margin-top: auto;"
                        event='click'
                        :width="'50px'"
                        @click ='openModalPermissoes'
                        type='blue'
                        :icon='mdiPlus'
                    />
                </div>
            </div>
            <requiredFields/>
            <div class="col-sm-12"><hr></div>
        </div>
        <modalPermissoesUsuario
            :id="'modalPermissoesUsuarioCP'"
            @modalClose="closeModalPermissoes">
        </modalPermissoesUsuario>
    </panelEagle>
</template>
<script lang="js">
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { required, email } from 'vuelidate/lib/validators'
import Vue from 'vue'
import { mdiAccount, mdiCheckBold, mdiPlus } from '@mdi/js'
import { conectionError }  from '@/Services/Helpers/swellHeper'
import { validaCpfCnpj } from '@/Services/Helpers/ValidatorHelper'

export default Vue.extend({
	name: 'CadastroUsuariosCP',
	components:{
		'selectAll' : require('@/components/Atom/Select/SelectAll').default,
		'panelEagle' : require('@/components/Atom/Panel/PanelEagle').default,
		'tituloPage' : require('@/components/Atom/Header/Titulo').default,
		'requiredFields' : require('@/components/Atom/Footer/RequiredFields').default,
		'buttonsFilters' : require('@/components/Atom/Buttons/ButtonsFilters').default,
		'inputSimple' : require('@/components/Atom/Inputs/InputSimple').default,
        'simpleButton' : require('@/components/Atom/Buttons/SimpleButton').default,
		'simpleAlert'    : require('@/components/Atom/Alerts/AlertSimple').default,
		'basicButtonsCadastrosSC': require(
			'@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
		'modalPermissoesUsuario' : require(
            '@/components/Atom/ModalSpecific/ModalPermissoesUsuario').default,
	},
	validations:{
		email : { email, required },
        cpf: { required },
		empresaSelecionada : { required },
		dptoSelecionado : { required },
		perfilSelecionado : { required },
	},
	data(){
		return{
			mdiAccount: mdiAccount,
            mdiPlus: mdiPlus,
			loadingPanelEagle : false,
			optEmpresas: new EmpresasService().Get(),
			optDepartamentos : [],
			optPerfil: [],
			url : '/checkpoint/cadastros/usuarios/',
			filtroStatus:[
				{'value':'S','width':'48.5%','text':'Ativos',
                'isSelected':true,icon:mdiCheckBold},
				{'value':'N','width':'48.5%','text':'Inativos',
                'isSelected':false,'icon':''},
			],
			filtroAlerta:[
				{'value':1,'width':'48.5%','text':'Sim',
                'isSelected':false,icon:mdiCheckBold},
				{'value':0,'width':'48.5%','text':'Não',
                'isSelected':true,'icon':''},
			],
			valueFiltros : [],
			valueFiltrosAlerta : [],
			id : '',
			selectedEmpresa : [],
			selectedDepartamentos : [],
			selectedPerfil : [],
			empresaSelecionada : [],
			dptoSelecionado : [],
			perfilSelecionado : [],
			email : '',
            cpf : '',  
            saveError: '',
		}
	},
	methods:{
		changeSelectEmpresa(empresa){
			this.$refs.refDepartamentos.clearAll()
			this.empresaSelecionada = empresa
			if(empresa.length > 0){
				this.buscarFiltros(empresa)
            } else {
                this.selectedDepartamentos = []
                this.selectedPerfil = []
            }
		},
        
        setCpf(cpf){
            this.cpf = cpf ? cpf : ''
        },
		setDescricao(descricao){
			this.email = descricao
		},

		setPerfil(value){
			this.perfilSelecionado = value
		},

		setDepartamentos(departamentos){
			this.dptoSelecionado = departamentos
		},

		/**
         * @description pega o último valor do filtro e 
         * também alterar qual esta selecionado no momento
         * @param {array} value - valor do filtro selecionado
         * @author Rafael
         */
		changeFiltros(value){
			this.valueFiltros = value
			this.filtroStatus[0].icon = ''
			this.filtroStatus[1].icon = ''
			this.filtroStatus[0].isSelected = false
			this.filtroStatus[1].isSelected = false
			if(value[0] == 'S'){
				this.filtroStatus[0].icon = mdiCheckBold
				this.filtroStatus[0].isSelected = true
			} else{
				this.filtroStatus[1].icon = mdiCheckBold
				this.filtroStatus[1].isSelected = true
			}
		},

		/**
         * @description pega o último valor do filtro e 
         * também alterar qual esta selecionado no momento
         * @param {array} value - valor do filtro selecionado
         * @author Rafael
         */
		changeFiltrosAlerta(value){
			this.valueFiltrosAlerta = value
			this.filtroAlerta[0].icon = ''
			this.filtroAlerta[1].icon = ''
			this.filtroAlerta[0].isSelected = false
			this.filtroAlerta[1].isSelected = false
			if(value[0]){
				this.filtroAlerta[0].icon = mdiCheckBold
				this.filtroAlerta[0].isSelected = true
			} else{
				this.filtroAlerta[1].icon = mdiCheckBold
				this.filtroAlerta[1].isSelected = true
			}
		},

		/**
         * @description salvar ou editar usuário
         * @author Rafael
         */
		salvarCadastro(){
			this.loadingPanelEagle = true
			var obj = {
				'codigo' : this.id,
				'email':this.email,
                'cpf' :this.cpf,
				'usuativo':this.valueFiltros[0],
				'usucliente':this.empresaSelecionada[0],
                'usuperfil': this.perfilSelecionado[0],
				'usualertaemail': this.valueFiltrosAlerta[0],
				'departamentos': this.dptoSelecionado,
			}
			new HttpRequest().Post(this.url+'salvar', obj)
				.then(({data, status, code})=>{
					if(status) {
						if(data.success){
							this.$router.push({name:'listagemUsuariosCP'})
                        } else {
							if(data.error=='email'){
								this.saveError = 'Email já cadastrado'
								setTimeout(()=>{this.saveError= false}, 5000)
							}else if (data.cpfDupli){
                                conectionError('CPF já cadastrado')
                            }
						}
					} else {
						conectionError()
					}
				})
                .finally(()=>{
					this.loadingPanelEagle = false
                })
		},

		/**
         * @description busca os perfis e os departamentos do cliente
         * @param {number} empresa - código da empresa
         * @author Rafael
         */
		buscarFiltros(empresa){
            new HttpRequest().Get(this.url+'dados/filtros/'+empresa)
				.then(({data, status, code})=>{
					if(status) {
						if(data.perfis){
                            this.optPerfil = data.perfis
                        }
						if(data.departamentos){
                            this.optDepartamentos = data.departamentos
                        }
					} else {
						conectionError()
					}
				})
                .catch((error) => {
                    conectionError()
                })
                .finally(()=>{
					this.loadingPanelEagle = false
                })
		},

        openModalPermissoes() {
			this.$bvModal.show('modalPermissoesUsuarioCP')
        },
        
		/**
         * @description se ocorreu alteração de perfil, então recarrega os filtros
         * @param {boolean} recarregarPerfis
         * @author Rafael
         */
        closeModalPermissoes(recarregarPerfis) {
            if(recarregarPerfis) {
                this.selectedPerfil = []
                this.buscarFiltros(this.empresaSelecionada)
            }
        },

		/**
         * @description quando é uma edição, busca e preenche os dados dos campos/inputs
         * @author Rafael
         */
        async preencherDados() {
            await new HttpRequest().Post(this.url+'buscar/dados', {'id':this.id})
				.then(({data, status})=>{
                    if(status && data) {
                        let dados = data.dados[0]
                        this.email = dados.email
                        this.cpf = dados.usucpf ? dados.usucpf : ''
                        if(dados.usuativo)
                            this.changeFiltros([dados.usuativo])
                        if(dados.usualertaemail)
                            this.changeFiltrosAlerta([dados.usualertaemail])
                        if(dados.clcodigo)
                            this.selectedEmpresa = [{
                                value: dados.clcodigo,
                                description: dados.clnome
                            }]
                        if(dados.cdcodigo)
                            this.selectedDepartamentos = [{
                                value: dados.cdcodigo,
                                description: dados.cddescricao
                            }]
                        if(dados.pecodigo)
                            this.selectedPerfil = [{
                                value: dados.pecodigo,
                                description: dados.pedescricao
                            }]
                    } else {
                        conectionError()
                    }
				})
                .catch((error) => {
                    conectionError()
                })
        }
	},
    computed:{
        cpfValido(){
            return validaCpfCnpj(this.cpf)
        },

        cpfValidationBorder() {
            return this.cpf && 
                (!this.cpfValido || this.$v.cpf.$anyError)
        }
    },
	mounted(){
		if(this.$route.params.id){
			this.id = this.$route.params.id
			this.preencherDados()
		}
	},
})
</script>
<style lang="scss">
</style>