<template>
    <div>
        <modalEagle
            @modalOpen="modalOpen"
            id='permissao-veiculo' 
            title='Permissão veículos'
        >
            <slot slot='modalBody'>
                <b-overlay :show='loadingModal'>
                    <selectAll
                        ref="refVeiculos"
                        nameForRadio='select'
                        :isMultiple='false'
                        :hasSelectall='false'
                        :labels='[{indexDFH:"V", description:"Veículos"}]'
                        :optionsArray='optVeiculos'
                        @changeSelect='changeVeiculo'
                    />
                </b-overlay>
            </slot>
            <slot slot='modalFooter'>
                <simpleButton
                    text='Cancelar'
                    type='red'
                    :icon='mdiCloseThick'
                    event='click'
                    @click="() => { 
                        $bvModal.hide('permissao-veiculo') 
                    }"
                />
            </slot>
        </modalEagle>
        <modalCartoes
            :empresaSelecionada="empresas"
            :codigoRastreador="valueVeiculos[0]"
            :titulo="tituloModal"
        />
    </div>
</template>
<script>
import Vue from 'vue'
import { mdiCloseThick } from '@mdi/js'
export default Vue.extend({
    name:'modalPermissoes',
    components:{
        modalEagle:require('@/components/Atom/Modal/ModalEagle').default,
        selectAll:require('@/components/Atom/Select/SelectAll').default,
        simpleButton:require('@/components/Atom/Buttons/SimpleButton').default,
        modalCartoes:require('@/components/Atom/ModalSpecific/ModalCartoesGestaoDeCartoesRfid').default,
    },

    props:{
        veiculos:{
            type: Array,
            required:true,
        },

        empresaSelecionada:{
            type: Array,
            required:true,
        }
    },

    data() {
        return {
            empresas:[],
            mdiCloseThick:mdiCloseThick,
            loadingModal:false,
            optVeiculos:[],
            valueVeiculos:[],
            tituloModal:'',
        }
    },

    watch:{
        veiculos(val){
            // @ts-ignore:next-line
            if(this.$refs.refVeiculos){
                this.$refs.refVeiculos.clearAll()
            }
            this.optVeiculos = val
        }
    },

    methods: {
        modalOpen(){
            this.empresas = this.empresaSelecionada
        },

        changeVeiculo(value, codigo, placaPrefixo){
            this.valueVeiculos = value
            if(!value[0]){
                this.tituloModal = ''
                return
            }
            this.tituloModal = placaPrefixo
            this.$nextTick(() => {
                this.$bvModal.show('listar-cartao')
            })
        }
    },
})
</script>
<style lang="scss">
    
</style>
