/**
    Componente do rota manual para mostrar informações referentes a uma rota, sob o mapa

    -> Propriedades:
        1- rota - Object - Informações de uma rota

    -> Slot:
        Sem slot

    -> Eventos:
        1- click - Emite quando clica na div dos detalhes - Sem parâmetro
 */
<template>
    <MapaSimples ref="mapaVisualizar" id="mapaRotaProgramada">
        <div class="col-sm-7 divDadosMapa row" style="min-width: 170px">
            <div class="col-sm-12 nowrap">
                <h5>Visualizando rota de: {{ rota.veiculo }}</h5>
            </div>
            <div class="col-sm-12">Motorista: {{ rota.motorista }}</div>
            <div class="col-sm-12">Ajudante: {{ rota.ajudante }}</div>
            <div class="col-sm-12">Tempo: {{ rota.tempo }}</div>
            <div class="col-sm-12">Distância: {{ rota.km }} Km</div>
            <div class="col-sm-12">Volume: {{ rota.volume }}</div>
            <div class="col-sm-12">Peso: {{ rota.peso }} Kg</div>
            <div class="col-sm-12">Valor: R$ {{ rota.valor }}</div>
            <div class="col-sm-12">Cubagem: {{ rota.cubagem }} m³</div>
            <div class="col-sm-12">Custo da rota: {{ calcularCustoRota }}</div>
            <div class="col-sm-12">Rota: {{ rota.nomeRota }}</div>
        </div>
        <PolylineAntPath ref='polyUnicaRota' :latLng="coordenadas" />
        <template v-for="(dados, index) in rota.pontos">
            <MarkerPoint :key="index + '_ponto_rota_prog'" v-if="true" :id="'visualizar-' + dados.pocodigo"
                :latitude="parseFloat(dados.polatitude)" :longitude="parseFloat(dados.polongitude)"
                :icon="getIcon(dados.irordem)" typeIcon="divIcon">
                <TooltipMarker>
                    <div class="col-sm-12 row nopadding">
                        <div class="col-sm-12 nopadding">
                            {{ dados.irnome }}
                        </div>
                    </div>
                </TooltipMarker>
            </MarkerPoint>
        </template>
        <MarkerPoint :longitude="parseFloat(rota.lngSaida)" :latitude="parseFloat(rota.latSaida)" icon="img/inicio.png" />
        <MarkerPoint :latitude="parseFloat(rota.latRetorno)" :longitude="parseFloat(rota.lngRetorno)" icon="img/fim.png" />
    </MapaSimples>
</template>

<script>

// import { isArray } from '@vue/shared'
import { required } from 'vuelidate/lib/validators'

export default {
    name: 'MapaRotaProgramada',
    components: {
        PolylineAntPath: require('@/components/Atom/Mapa/PolylineAntPath').default,
        TooltipMarker: require('@/components/Atom/Mapa/TooltipMarker').default,
        MarkerPoint: require('@/components/Atom/Mapa/MarkerPoint').default,
        MapaSimples: require('@/components/Atom/Mapa/MapaSimples').default,
    },

    props: {
        rota: {
            required
        }
    },

    mounted() {
        if (this.$refs.mapaVisualizar) {
            this.$refs.mapaVisualizar.validateSize()
        }
    },

    // updated() {
    // 	this.removelAllLayers()
    // 	setTimeout(() => {
    // 		this.flyTo()
    // 	}, 200)
    // },

    data() {
        return {
            coordenadas: []
        }
    },

    methods: {
        /**
         * @description Retorna o html para mostrar um marker no mapa.
         * @return {string} html do marker
         */
        getIcon(ordem) {
            return `<div>
                <span class='markerRotaOrdem'>${ordem}</span>
                <img src='img/pontoSelecionado.png'/>
            </div>`
        },

        /**
         * @description Chama função de zoom do mapa até as coordenadas da polyline/rota
         */
        flyToCoordenadas() {
            try {
                // let option = {animate:true, duration:0.8, easeLinearity:0.8}
                let latLng = [
                    this.rota.latSaida,
                    this.rota.lngSaida
                ]
                if (latLng[0] && latLng[1]) {
                    this.$refs.mapaVisualizar.flyTo(latLng, 13)
                }
            } catch (error) {
                ''
            }
        },

        /**
         * @description Chama função de remover layer do mapa. Remover todas as layers.
         */
        removelAllLayers() {
            try {
                let layers = this.$refs.polyUnicaRota.mapObject._layers
                for (let l in layers) {
                    this.$refs.mapaVisualizar.removeLayer(layers[l])
                }
            } catch (error) { '' }
        }
    },

    computed: {

        /**
         * @description Faz o cálculo do custo da rota e retorna o valor formatado.
         * @return {string} R$ (valor do custo)
         */
        calcularCustoRota() {
            var custo = ''
            if (this.rota.custoVeiculo && this.rota.km) {
                custo = parseFloat(this.rota.custoVeiculo) * parseFloat(this.rota.km)
            }
            return custo ? 'R$ ' + parseFloat(custo).toFixed(2) : ''
        }
    },

    watch: {
        'rota.coordenadas'(a, b) {
            this.$refs.mapaVisualizar.validateSize()
            this.removelAllLayers()
            this.coordenadas = this.rota.coordenadas
            this.flyToCoordenadas()
        },
    }
}
</script>

<style lang="scss" >
#mapaRotaProgramada {
    .divDadosMapa {
        font-family: nexabook, sans-serif !important;
        z-index: 900;
        text-align: left;
        font-weight: bold;
        border: 1px solid #e5e5e5;
        background: #00000054;
        padding-right: 0px !important;
        padding-top: 5px !important;
        padding-left: 0px !important;
        color: #fff;
        position: absolute !important;
    }

    .divIcon {
        .markerRotaOrdem {
            position: absolute;
            top: 4px;
            background-color: rgba(255, 255, 255, 0.24);
            padding: 0 6px;
            border-radius: 43px;
            font-size: 12px;
            left: 8px;
            width: 25px;
            text-align: center;
            font-weight: bold;
        }

        img {
            height: 38px;
            width: 40px;
        }
    }
}
</style>