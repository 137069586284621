<template>
    <eaglePanel id='cadastrarGr' :loading='loading'>
        <div class="col-sm-12">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6 nopadding">
                    <titulo
                        titulo='Cadastrar Gerenciadora'
                        :icon='mdiOfficeBuildingMarker'
                    />
                </div>
                <div class="col-sm-6 nopadding">
                    <basicButtonsCadastrosSC
                        :disabled='$v.$invalid'
                        tipoCancela='button'
                        linkCancel='listarGr'
                        @salvarCadastro='salvarGerenciadora'
                    />
                </div>
            </div>
            <div class="col-sm-12 nopadding"><hr></div>
            <div class="col-sm-12 nopadding">
                <b-tabs v-model="pagina">
                    <!-- CADASTRO -->
                    <b-tab title="Cadastro">
                        <div class="col-sm-12 nopadding row mt-2">
                            <div class="col-sm-4 nopadding">
                                <inputSimple 
                                    name='cnpj'
                                    label='CNPJ*'
                                    :value='gerenciadora.gecnpj'
                                    :inputClass='{"border border-danger":$v.gerenciadora.gecnpj.$anyError}'
                                    :hasMask='true'
                                    :mask="['NN.NNN.NNN/NNNN-NN']"
                                    @blur='$v.gerenciadora.gecnpj.$touch()'
                                    @changeInput='buscaCNPJ'
                                />
                            </div>
                            <div class="col-sm-4 nopadding">
                                <inputSimple
                                    name='razaoSocial'
                                    label='Razão Social*'
                                    :value='gerenciadora.gerazaosocial'
                                    :inputClass='{"border border-danger":$v.gerenciadora.gerazaosocial.$anyError}'
                                    @blur='$v.gerenciadora.gerazaosocial.$touch()'
                                    @changeInput='v=>gerenciadora.gerazaosocial = v'
                                />
                            </div>
                            <div class="col-sm-4 nopadding">
                                <inputSimple
                                    name='nomeFantasia'
                                    label='Nome Fantasia*'
                                    :value='gerenciadora.genomefantasia'
                                    :inputClass='{"border border-danger":$v.gerenciadora.genomefantasia.$anyError}'
                                    @blur='$v.gerenciadora.genomefantasia.$touch()'
                                    @changeInput='v=>gerenciadora.genomefantasia = v'
                                />
                            </div>
                            <div class="col-sm-8 nopadding margin-bot mt-3">
                                <inputSearch
                                    buttonDisabled='disabled'
                                    inputClass="col-sm-12 "
                                    :value="gerenciadora.geendereco"
                                    label="Endereço*"
                                    name="inputSearch"
                                    @keyupEnter="clickButtonSearchMapa"
                                    @clickButtonSearch="clickButtonSearchMapa"
                                />
                                
                            </div>
                            <div class="col-sm-2 nopadding mt-3">
                                <inputSimple 
                                name='latitude'
                                label='Latitude*'
                                type='number'
                                :value='gerenciadora.gelatitude'
                                @change="procuraEndereco"
                                @changeInput='v => gerenciadora.gelatitude = v'/>
                            </div>
                            <div class="col-sm-2 nopadding mt-3">
                                <inputSimple 
                                name='longitude'
                                label='Longitude*'
                                :value='gerenciadora.gelongitude'
                                type='number'
                                @change="procuraEndereco"
                                @changeInput='v => gerenciadora.gelongitude = v'/>
                            </div>
                            <div class="col-sm-12 mt-3">
                                 <div class="col-sm-8 row div-local-tamanho padd-seletor-rua pl-3" 
                                    v-show="mostraSeletorLocalMapa">
                                    <b-list-group class="div-enderecos col-sm-12">
                                        <b-overlay :show='loadingEndereco'>
                                        <b-list-group-item 
                                            v-show="enderecosMapa.length != 0 && !semEnderecos"
                                            button
                                            @click="clickListaEnderecoMapa(enderecosMapa)">
                                                {{enderecosMapa.end}}
                                        </b-list-group-item>
                                        <b-list-group-item 
                                            v-show="!enderecosMapa"> 
                                            Endereço não encontrado
                                        </b-list-group-item>
                                        </b-overlay>
                                    </b-list-group>
                                </div>
                                <b-overlay :show='procurandoInfo'>
                                <mapaS
                                ref='mapaSimples'
                                height='330'
                                :latitude='Number(posMapaI.lat)'
                                :longitude='Number(posMapaI.long)'>
                                    <markers
                                    :latitude='gerenciadora.gelatitude'
                                    :longitude='gerenciadora.gelongitude'
                                    :isDraggable='true'
                                    @dragend="procuraEndereco"
                                    @drag="atualizaLatLongMarker">
                                        <popMarker>
                                            <div class="col-sm-12 nopadding centraliza">
                                                <div>Me arraste para o local desejado</div>
                                                <div style="font-weight:bold"></div>
                                            </div>
                                        </popMarker>
                                    </markers>
                                </mapaS>
                                </b-overlay>
                            </div>
                        </div>
                    </b-tab>
                    <!-- CONTATOS -->
                    <b-tab title="Contatos">
                        <div class="col-sm-12 nopadding mt-3 row">
                            <div class="col-sm-3 nopadding">
                                <inputSimple 
                                    label='Nome*'
                                    :value="nomes"
                                    @changeInput='alteraNomes'
                                />
                            </div>
                            <div class="col-sm-3 nopadding">
                                <inputSimple 
                                    label='Cargo*'
                                    :value="cargos"
                                    @changeInput='alteraCargos'
                                />
                            </div>
                            <div class="col-sm-3 nopadding">
                                <inputSimple 
                                    :hasMask='true'
                                    label='Telefone*'
                                    :mask="[`(NN) PPPNN-PPPNNNN`]"
                                    :value="telefones"
                                    @changeInput='alteraTelefones'
                                />
                            </div>
                            <div class="col-sm-3 nopadding">
                                <inputSimple 
                                    label='Email*'
                                    :value="emails"
                                    @changeInput="alteraEmails"
                                />
                            </div>
                            <div class="col-sm-12 nopadding mt-2"
                                align="right">
                                <simpleButton
                                    width="100px"
                                    event="click"
                                    text="Adicionar"
                                    :icon="mdiCheckBold"
                                    :disabled="contatosValidos"
                                    @click="clickBotaoAdicionar"
                                />
                            </div>
                            <div class="col-sm-12 mt-3 nopadding">
                                <tableListagem
                                    :data="dataTable"
                                    :titles="titlesTabela"
                                    :habilitado='true'>
                                    <template #acoes='{row}'>
                                        <simpleButton
                                            title='Excluir'
                                            width='45%'
                                            type='red'
                                            :icon='mdiTrashCanOutline'
                                            event='click'
                                            @click='excluirCadastroTable(row)'
                                        />
                                    </template>
                                </tableListagem>
                            </div>
                        </div>
                    </b-tab>
                    <!-- CONFIGURAÇÕES -->
                    <b-tab title="Configurações">
                        <div class="col-sm-12">
                            <div class="col-sm-3 buttonConfig nopadding mt-2">
                                <span class='tituloConfig'>Configurações de API</span>
                            </div>
                            <div class="col-sm-12 nopadding"><hr></div>
                            <div class="col-sm-2 nopadding buttonConfig">
                                <simpleButton
                                :text='apiAtiva?"Desabilitar API":"Habilitar API"'
                                event='click'
                                width='100%'
                                @click='mudaApiKey'/>
                            </div>
                            <div class="col-sm-9 nopadding" v-if="apiAtiva">
                                    <div class='labelKey nopadding'>Chave API*</div>
                                <div class="divApi nopadding">
                                    <input 
                                    readonly 
                                    class='form-control alturaBotoes inputApi' 
                                    :value='gerenciadora.geapikey'>
                                    <button 
                                    class='botao APICopiar alturaBotoes' 
                                    v-text='"Copiar"'
                                    event='click'
                                    @click='copiarChave'/>
                                    <button 
                                    class="alturaBotoes botao APIAtt" 
                                    v-text='"Atualizar Chave"'
                                    event='click'
                                    @click="atualizarChave"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6 nopadding mt-2">
                            <inputSimple
                                ref="inputIps"
                                label="IPs liberados*"
                                :value="gerenciadora.geipsliberados"
                                @changeInput="changeIps"
                                />
                        </div>
                    </b-tab>
                </b-tabs>
                <required/>
                <div class="col-sm-12"><hr></div>
            </div>
        </div>
        <modalEagle
            id='gerarNovaChave'
            title='Alerta!!'
            @confirmButton='confirmaGerarChaveNova'
        >
            <template #modalBody>
                <p>Se você confirmar essa ação, uma nova chave será gerada e os
                    acessos do cliente serão bloqueados. Você deverá informar a nova 
                    chave para o cliente, para que ele possa implementar em suas integrações.
                    Deseja mesmo continuar?</p>
            </template>
        </modalEagle>
    </eaglePanel>
</template>

<script>
import {mdiOfficeBuildingMarker, mdiCheckBold,
	mdiTrashCanOutline } from '@mdi/js'
import { required } from 'vuelidate/lib/validators'
import { mapActions } from 'vuex'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { conectionError } from '@/Services/Helpers/swellHeper'
import axios from 'axios'
import jsonpAdapter from 'axios-jsonp'
import { EmpresasService } from '@/Services/auth/Empresas.service'

export default {
	name:'CadastrarGr',
	components: {
		'eaglePanel'   : require('@/components/Atom/Panel/PanelEagle').default,
		'titulo'       : require('@/components/Atom/Header/Titulo').default,
		'basicButtonsCadastrosSC'       : require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
		'required'     : require('@/components/Atom/Footer/RequiredFields').default,
		// 'SelectAll'    : require('@/components/Atom/Select/SelectAll').default,
		'inputSimple'  : require('@/components/Atom/Inputs/InputSimple').default,
		'mapaS'        : require('@/components/Atom/Mapa/MapaSimples').default,
		'markers'      : require('@/components/Atom/Mapa/MarkerPoint').default,
		'popMarker'    : require('@/components/Atom/Mapa/PopupMarker').default,
		'simpleButton' : require('@/components/Atom/Buttons/SimpleButton').default,
		'modalEagle'   : require('@/components/Atom/Modal/ModalEagle').default,
		'tableListagem': require('@/components/Atom/Table/TableListagem').default,
		'inputSearch'    : require('@/components/Atom/Inputs/InputSearch').default,
	},
	data(){
		return{
			baseUri : '/administrativo/cadastros/gerenciadoras/',
			loading : false,
			mdiOfficeBuildingMarker:mdiOfficeBuildingMarker,
			mdiCheckBold:mdiCheckBold,
			mdiTrashCanOutline:mdiTrashCanOutline,
			pagina:0,
			gerenciadora:{
				gecnpj: '',
				gerazaosocial: '',
				genomefantasia : '',
				gelatitude: -27.1000,
				gelongitude: -52.6269,
				geendereco: '',
				gestatus: 'A',
				geapikey: '',
				geipsliberados: '',
			},
			nomes: '',
			cargos: '',
			telefones: '',
			emails: '',
			procurandoInfo: false,
			posMapaI:{
				lat: -27.1000,
				long: -52.6269
			},
			valueSlide:50,
			apiAtiva: false,
			id: '',
			erro : false,
			contato: false,
			titlesTabela:[
				{name: 'Nome',     number: 'nomes'},
				{name: 'Cargo',    number: 'cargos'},
				{name: 'Telefone', number: 'telefones'},
				{name: 'Email',    number: 'emails'},
			],
			dataTable: [],
			controleCodigo: 0,
			contatosValidos : true,
			mostraSeletorLocalMapa:true,
			loadingEndereco:false,
			enderecosMapa:[],
			semEnderecos:false,
		}
	},
    
	validations:{
		gerenciadora:{
			gecnpj: { required },
			gerazaosocial: { required },
			genomefantasia : { required },
			gelatitude: { required },
			gelongitude: { required },
			geendereco: { required },
			geapikey: { required },
			geipsliberados: { required },
		},
		dataTable : { required },
	},
	methods: {
		alteraNomes(nomes){
			this.nomes = nomes
			this.validaCampos()
		},

		alteraCargos(cargos){
			this.cargos = cargos
			this.validaCampos()
		},

		alteraTelefones(telefones){
			this.telefones = telefones
			this.validaCampos()
		},

		alteraEmails(emails){
			this.emails = emails
			this.validaCampos()
		},

		procuraEndereco(){
			this.posMapaI.lat = this.gerenciadora.gelatitude
			this.posMapaI.long = this.gerenciadora.gelongitude
			new HttpRequest().Post('/checkpoint/cadastros/colaboradores/endereco/proximo', 
				{'local':[this.gerenciadora.gelatitude, this.gerenciadora.gelongitude]})
				.then(dados=>{
					this.gerenciadora.geendereco = dados.data
				})
		},

		atualizaLatLongMarker(drag){
			this.gerenciadora.gelatitude = drag.latlng.lat
			this.gerenciadora.gelongitude = drag.latlng.lng
		},
		/**
         * recebe um cnpj e faz uma consulta na api da receita
         * pra completar os dados da empresa
         * e atribuir pras variaveis
         * @param { String } value - cpnj do brabo
         */
		buscaCNPJ(value){
			if(this.id == ''){
				var cnpj = value.replace(/[^0-9]/g,'')
				if(cnpj.length !== 14){
					return
				} else {
					this.loading = true
					axios({
						url: `https://www.receitaws.com.br/v1/cnpj/${cnpj}`,
						adapter: jsonpAdapter,
					}).then(({data})=>{
						if(data.fantasia != undefined){
							this.gerenciadora.gecnpj = cnpj
							this.gerenciadora.gerazaosocial = data.nome
							this.gerenciadora.genomefantasia = data.fantasia
							this.gerenciadora.geendereco = data.logradouro+', '+
                                data.numero+', '+data.complemento+', '+data.bairro+', '+data.municipio
							this.clickButtonSearchMapa(data.logradouro+', '+
                                data.numero+', '+data.bairro+', '+data.municipio)
							var telefone = (data.telefone).split('/ ')
							var email = (data.email).split('/ ')
						}
						this.loading = false
					}).catch((err)=>{
						this.loading = false
					}).finally(()=>{
						this.gerenciadora.gecnpj = cnpj
						this.loading = false
					})
				}
			}
		},

		salvarGerenciadora(){
			this.loading = true
			var obj ={
				'gerenciadora' : this.gerenciadora,
				'contatos' : this.dataTable,
				'id'       : this.id,
			}
			new HttpRequest().Post(this.baseUri+'salvar', obj)
				.then(dados=>{
					if(dados.code == 201){
						this.$router.push({name:'listarGr'})
					} else if(dados.code == 200) {
						conectionError('Este CNPJ já está cadastrado', 'warning');
					} else {
						conectionError()
					}
					this.loading = false
				})
		},


		mudaApiKey(){
			if(this.apiAtiva){
				let uri = this.baseUri+'apioff'
				this.gerenciadora.geapikey = ''
				this.apiAtiva = false
				if(this.$route.params.id)
					new HttpRequest().Post(uri, {codigo:this.gerenciadora.gecodigo})
			}else{
				this.apiAtiva = true
				this.confirmaGerarChaveNova()
			}
		},

		confirmaGerarChaveNova(){
			this.$bvModal.hide('gerarNovaChave')
			this.loading = true
			let url = this.baseUri+'apikey'
			new HttpRequest().Post(url, {codigo:this.gerenciadora.gecodigo})
				.then(data=>{
					this.gerenciadora.geapikey = data.data.key
					this.loading = false
				})
		},

		copiarChave(){
			navigator.clipboard.writeText(this.gerenciadora.geapikey)
			this.$bvToast.toast('Copiado Com sucesso!', {
				title: 'Chave API',
				autoHideDelay: 2500,
				variant:'success'
			})
		},

		atualizarChave(){
			this.$bvModal.show('gerarNovaChave')
		},

		/** 
		 * @description Adiciona uma (,) ao final de cada ip digitado
		 * @author Vitor Hugo
		 */
		changeIps(value){
			this.gerenciadora.geipsliberados = value.replaceAll(/([\w\d.]+)(?=\s{1})/gm , '$1,').trim()
			if(this.gerenciadora.geipsliberados == '')
				this.mensagemErro = []
			this.erro = false
		},

		validaCampos(){
			if(this.nomes == '')
				this.contatosValidos = true
			else if(this.telefones == '')
				this.contatosValidos = true
			else if(this.emails == '')
				this.contatosValidos = true
			else if(this.cargos == '')
				this.contatosValidos = true
			else
				this.contatosValidos = false
		},

		buscaDadosEdicao(){
			this.loading = true
			let url = this.baseUri+'editar'
			new HttpRequest().Post(url, {codigo:this.id})
				.then(dados=>{
					this.gerenciadora = dados.data.gerenciadora[0]
					this.dataTable = dados.data.contatoGerenciadora
					for (let index = 0; index < this.dataTable.length; index++) {
						this.dataTable[index]['codigo'] = index;
						this.controleCodigo = index
					}
					if(this.gerenciadora.geapikey)
						this.apiAtiva = true
					this.loading = false
				})
		},


		clickBotaoAdicionar(){
			this.dataTable.push({
				'codigo' : this.controleCodigo,
				'nomes' : this.nomes,
				'cargos': this.cargos,
				'telefones':this.telefones,
				'emails'   : this.emails})
			this.controleCodigo++
			this.nomes = ''
			this.cargos = ''
			this.telefones = ''
			this.emails = ''
		},

		excluirCadastroTable(dados){
			for (const index in this.dataTable){
				if(this.dataTable[index].codigo == dados.codigo){
					this.dataTable.splice(index, 1) 
					break
				}
			}
		},

		/**
         *  @listens clickButtonSearch - Emite evento quando botão de 
         *      pesquisa do input é pressionado
         *  @listens keyupEnter - Emite evento quando pressionado 
         *      o botão enter no input 
         *  @description Método para buscar os endereços relacionados a pesquisa
         *  @param {string} value - valor informado no input
         *  @author Vitor Hugo 🐨
         */
		clickButtonSearchMapa(value){
			this.loadingEndereco = true
			new HttpRequest().Post('/checkpoint/cadastros/colaboradores/endereco', {'local':value})
				.then(dados=>{
					this.enderecosMapa = dados.data.enderecos
					if(!this.enderecosMapa)
						this.semEnderecos = true 
					else 
						this.semEnderecos = false
					this.mostraSeletorLocalMapa = true
					this.loadingEndereco = false
				})
		},

		/** @listens click - Emitido quando clicado na lista do mata
         *  @description Método para adicionar valores as variáveis do mapa
         *  @param {object} value - valores com latitude e endereço
         *  @author Vitor Hugo 🐨
         */
		clickListaEnderecoMapa(value){
			this.$refs.mapaSimples.flyTo(
				[value.lat,value.lon]
			)
			this.gerenciadora.gelatitude = value.lat
			this.gerenciadora.gelongitude = value.lon
			this.gerenciadora.geendereco = value.end
			this.mostraSeletorLocalMapa = false
		},
	},
    
	mounted(){
		this.$refs.mapaSimples.validateSize()
		if(this.$route.params.id){
			this.id = this.$route.params.id
			this.buscaDadosEdicao()
		}
	},
}
</script>

<style lang="scss" scoped>
.prospeccao{
    text-align: left !important;
    margin-top: 2.2%;
    font-size: 14px;
}
.labelMapa{
    text-align: left !important;
    vertical-align: bottom;
    font-size: 13px;
    padding: 0 !important;
}
.labelMapa::after{
    content:':'
}
.tituloConfig{
    text-align: left !important;
    padding: 0 !important;
    font-size: 20px;
}
.buttonConfig{
    text-align: left !important;
    padding-left: 0 !important;
}
.centraliza{
    text-align: center !important;
}
table, th, td {
  border: 1px solid #dddddd;
  font-size: 12px;
}
th{
    background-color: #f5f5f5;
}
.alturaBotoes{
    height: 37px !important;
}
.divApi{
    display: flex;
    padding-left: 5px;
    padding-bottom: 5px;
}
.labelKey{
    padding-left: 5px;
    padding-top: 6px;
    text-align: left !important;
    font-size: 14px;
    font-weight: bolder;
}
.botao{
    border: none;
    text-align: center;
    font-size: 13px;
    width: 10%;
    
}
.APICopiar{
    background-color: #fe970a;
    padding: 10px;
    text-align: center;
    color: white;
}
.APIAtt{
    padding: 10px;
    width: 175px !important;
    background-color: #1caf9a;
}
.inputApi{
    font-size: 11.4px !important;
}
.icone::before{
    margin: 0 !important;
    padding: 0 !important;
}
.botaoUpload{
    min-width: 25px;
    background-color: #428bca;
    border: none;
}
.divUpload{
    display: flex;
    padding: 0 !important;
    margin: 0 !important;
}
.subtitle{
    font-size: 13px;
    text-align: left;
    margin-bottom: 2px;
}
</style>