<template>
    <div>
        <modalEagle :id="idModal" title="Ajustar horas" @modalClose="modalClose" size="xl">
            <template slot="modalBody">
                <b-overlay :show="loading">
                    <div class="col-sm-12 row pl-0 pr-1">
                        <div class="col-sm-3 row pl-0 pr-0">
                            <selectAll
                                nameForRadio="radiosEmpresasModalCarro"
                                :isMultiple="false"
                                :labels="labelsSelectEmpresa"
                                :optionsArray="optionsSelectEmpresa"
                                firstSelected="E"
                                :hasSelectAll="false"
                                ref="selectEM"
                                @changeSelect="changeEmpresaModalAjustes"
                            />
                        </div>
                        <div class="col-sm-3 row nopadding">
                            <selectAll
                                ref="selectCA"
                                nameForRadio="seletorColaboradorModalCarro"
                                firstSelected="C"
                                :hasSelectAll="false"
                                :isMultiple="false"
                                :optionsArray="optSelectColaboradores"
                                :labels="labelsSelectColaboradoresModal"
                                :extraClassParent="'nopadding'"
                                :disabled="$v.empresaSelecionada.$invalid"
                                :loading="carregando"
                                @changeSelect="changeColaboradoresModalAjustes"
                            />
                        </div>
                        <div class="col-sm-4 row pl-0 pr-0">
                            <inputRangeWithLimit
                                @changeInput="changeDataIntervaloModalAjustes"
                                :intervalo="2"
                                :isObrigatorio="true"
                                :titulo="'Intervalo'"
                                title="Limite máximo de 2 dias"
                                name="inputDataJModalColab"
                                opens="right"
                            />
                        </div>
                        <div class="col-sm-2 row divButtonModalAjustes pl-0 pr-3">
                            <simpleButton
                                width="100%"
                                class="buttonGerarModalCarro"
                                :icon="mdiThumbUpOutline"
                                text="Gerar"
                                event="click"
                                @click="gerarTabela"
                                :disabled="$v.colabSelecionado.$invalid"
                                type="green"
                            />
                        </div>
                    </div>
                    <div class="col-sm-12 row mb-2 pr-1 mt-2">
                        <div class="col-sm-7 row divEventosModalAjustesColaboradores">
                            <tr
                                v-show="alertasTabela.length > 0"
                                v-for="(alertas, key) in alertasTabela"
                                :key="key"
                                class="trEventosModalAjustesColaboradores"
                            >
                                <span>
                                    <baseIcon :icon="mdiAlert" size="34" />
                                    {{ alertas }}
                                </span>
                            </tr>
                        </div>
                        <div class="col-sm-5 row nopadding">
                            <div class="col-sm-6 row divAdicionarEvento">
                                <simpleButton
                                    class="buttonAdicionarEvento"
                                    text="Adicionar evento"
                                    event="click"
                                    @click="adicionarEvento"
                                    :disabled="
                                        $v.colabSelecionado.$invalid || bloqueiaAdicionarEvento
                                    "
                                    type="blue"
                                >
                                </simpleButton>
                            </div>
                            <div class="col-sm-6 row divRemoverEvento">
                                <loadingButton
                                    class="buttonRemoverEvento"
                                    text="Remover evento"
                                    event="click"
                                    :isLoading="carregandoModal"
                                    :disabled="$v.checkModal.$invalid"
                                    @click="removerEvento"
                                    type="red"
                                >
                                </loadingButton>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="nuloModal"
                        class="col-sm-12 fechamento"
                        v-show="possuiFechamento[0]"
                        :title="msgFechamento"
                    >
                        Possui fechamento de {{ possuiFechamento[1] }}
                        <baseIcon :icon="mdiHelpCircleOutline" size="16" />
                    </div>
                    <tableRelatorio class="pr-1">
                        <!-- Cabeçalho -->
                        <template slot="thead">
                            <tr>
                                <th>Evento</th>
                                <th>Tipo</th>
                                <th>Horário</th>
                                <th>Veículo</th>
                                <th>Colaborador</th>
                                <th>
                                    <b-checkbox
                                        v-model="checkTodosModal"
                                        id="selecionarTodos"
                                        class="checkBoxCabecalho"
                                        :disabled="disabledCheckAll || possuiFechamento[0]"
                                        @change="selecionarTodos()"
                                    >
                                    </b-checkbox>
                                </th>
                            </tr>
                        </template>
                        <template v-if="nuloModal" slot="tbody">
                            <statusInformation
                                typeBar="tr"
                                colspanForTd="20"
                                :statusBar="statusBarModal"
                            >
                            </statusInformation>
                        </template>
                        <!-- Corpo da tabela -->
                        <template v-else slot="tbody">
                            <tr
                                class="col-sm-12 fechamento fechamento__grude"
                                v-show="possuiFechamento[0]"
                                :title="msgFechamento"
                            >
                                <td colspan="20">
                                    Possui fechamento de {{ possuiFechamento[1] }}
                                    <baseIcon :icon="mdiHelpCircleOutline" size="16" />
                                </td>
                            </tr>
                            <tr
                                v-for="(ev, k) in tabelaAjustesCondutores"
                                :key="k"
                                class="trRelatorioAjustarHoras"
                            >
                                <td>{{ ev.evento }}</td>
                                <td class="cursor-pointer">
                                    <span
                                        @click="mudaSelect(ev)"
                                        class="wd-100"
                                        v-show="!ev.selectTipo"
                                        style="height: 22px; display: block"
                                    >
                                        {{ ev.tipo }}
                                    </span>
                                    <!-- @change="alterarEvento(ev)" -->
                                    <select
                                        v-show="ev.selectTipo"
                                        :id="`input_${ev.horario}_${ev.veiculo}_${ev.evento}`"
                                        @click="
                                            () => {
                                                ev.selectTipo = ev.selectTipo;
                                            }
                                        "
                                        @blur="mudaSelect(ev)"
                                        @change="mudaTipoJornada(ev)"
                                        class="wd-100 selectTipoJornada"
                                        v-model="ev.tipo"
                                    >
                                        <option
                                            v-for="tipo in optSelectTipoModal"
                                            :key="tipo.value + ',' + k"
                                            :value="tipo.description"
                                            v-text="tipo.description"
                                            :selected="tipo.description == ev.tipo"
                                        />
                                    </select>
                                </td>
                                <td>{{ ev.horario }}</td>
                                <td>{{ ev.veiculo }}</td>
                                <td>{{ ev.motorista }}</td>
                                <td id="checkBox">
                                    <b-checkbox
                                        :disabled="isValidUltimoFechamento(ev)"
                                        v-model="checkModal"
                                        :key="ev.codigo"
                                        :value="ev.codigo"
                                    >
                                    </b-checkbox>
                                </td>
                            </tr>
                        </template>
                    </tableRelatorio>
                </b-overlay>
            </template>
            <template>
                <span v-if="msgErroRemoverEvento == true" style="color: red">
                    Erro ao remover, contate o suporte!
                </span>
                <simpleButton
                    :icon="mdiCheckBold"
                    @click="cancelButtonModalAjustes"
                    text="Finalizar"
                    event="click"
                    type="blue"
                >
                </simpleButton>
            </template>
        </modalEagle>
        <!-- Adicionar Evento -->
        <ModalAdicionarEvento
            idModal="modalAdicionarEvento"
            ref="modalAdicionarEvento"
            @atualizaAjustarColaborador="gerarTabela"
        >
        </ModalAdicionarEvento>
        <!-- Remover Evento -->
        <modalDeleta id="3" @confirmaExclusao="excluirEvento" />
        <modalJustificativaAjuste
            ref="modalJustificativaAjusteAC"
            idModal="modalJustificativaAjusteAC"
            @confirmar="changeJustificativa"
        />
    </div>
</template>
<script>
import Vue from "vue";
import {
    mdiCarSettings,
    mdiAccountSettings,
    mdiCheckBold,
    mdiThumbUpOutline,
    mdiAlert,
    mdiHelpCircleOutline,
} from "@mdi/js";
import { required } from "vuelidate/lib/validators";
import { EmpresasService } from "../../../Services/auth/Empresas.service";
import { HttpRequest } from "../../../Services/auth/HttpRequest.Service";
import { FiltrosService } from "../../../Services/filtros/filtros.Service";
import {
    validaUltimoFechamento,
    validaUltimoFechamentoPorDia,
    validaFechamentoDataFim,
} from "@/Services/Helpers/FechamentoHelper";
import { conectionError } from "@/Services/Helpers/swellHeper";
export default Vue.extend({
    name: "ModalAjustesColaborador",
    components: {
        baseIcon: require("@/components/Atom/Icon/BaseIcon").default,
        selectAll: require("@/components/Atom/Select/SelectAll").default,
        modalEagle: require("@/components/Atom/Modal/ModalEagle").default,
        simpleButton: require("@/components/Atom/Buttons/SimpleButton").default,
        tableRelatorio: require("@/components/Atom/Table/TableRelatorio").default,
        // inputSingleDay: require('@/components/Atom/Datas/InputSingleDay').default,
        loadingButton: require("@/components/Atom/Buttons/LoadingButton").default,
        modalDeleta: require("@/components/Atom/ModalSpecific/ModalDeleta").default,
        statusInformation: require("@/components/Atom/StatusInformation/StatusInformation").default,
        ModalAdicionarEvento: require("@/components/Atom/ModalJornadaFolha/ModalAdicionarEvento")
            .default,
        inputRangeWithLimit: require("@/components/Atom/Datas/InputRangeWithLimit").default,
        modalJustificativaAjuste:
            require("@/components/Atom/ModalJornadaFolha/ModalJustificativaAjuste").default,
    },

    validations: {
        checkModal: {
            required,
        },
        empresaSelecionada: {
            required,
        },
        colabSelecionado: {
            required,
        },
    },

    props: {
        idModal: {
            type: [Number, String],
            required: true,
        },
    },

    data() {
        return {
            // Modal Ajustes
            mdiCarSettings: mdiCarSettings,
            mdiAccountSettings: mdiAccountSettings,
            mdiCheckBold: mdiCheckBold,
            mdiThumbUpOutline: mdiThumbUpOutline,
            mdiHelpCircleOutline: mdiHelpCircleOutline,
            mdiAlert: mdiAlert,
            msgFechamento:
                "Após realizar fechamento não é possível alterar jornada dos colaboradores",
            url: "/controle/jornada/relatorios/folha/ponto/",
            urlJornadaTrabalho: "/controle/jornada/relatorios/jornada/trabalho/",
            optionsSelectEmpresa: new EmpresasService().Get(),
            labelsSelectEmpresa: [{ indexDFH: "E", description: "Empresas*" }],
            labelsSelectColaboradoresModal: [{ indexDFH: "C", description: "Colaborador*" }],
            optSelectTipoModal: [
                { description: "Jornada", value: "N" },
                { description: "Intervalo", value: "I" },
                { description: "Espera", value: "E" },
            ],
            empresaSelecionada: [],
            tipoSelecionado: [],
            colabSelecionado: null,
            veiculoSelecionadoModal: [],
            dataSelecionada: [],
            optSelectColaboradores: [],
            baseCalculo: "",
            tabelaAjustesCondutores: [],
            alertasTabela: [],
            checkModal: [],
            checkTodosModal: false,
            disabledCheckAll: true,
            colocarAlertasTabela: false,
            veiculoSelecionado: [],
            carregandoModal: false,
            statusBarModal: "info",
            nuloModal: true,
            possuiFechamento: false,
            msgErroRemoverEvento: false,
            loading: false,
            carregando: false,
            ultimoFechamento: null,
            justificativa: null,
            tipoEvento: null,
            // eventoAlterado: null,
        };
    },

    methods: {
        /**
         * @description Essa função deve ser utilizada por uma referência para "ativar"
         * essa modal, os argumentos da função são os necessários para
         * @author Vitor Hugo 🐨
         */
        preparaModalAjustesColaborador() {
            this.resetModal();
            this.tabelaAjustesCondutores = [];
            this.statusBarModal = "info";
            this.$bvModal.show(this.idModal);
            // this.$refs.modalJustificativaAjuste.preparaModal()
        },

        //Início dos métodos para pegar valor dos inputs
        changeEmpresaModalAjustes(empresa) {
            this.limpaDados();
            if (this.veiculoSelecionadoModal.length > 0) {
                this.$refs.selectVC.clearAll();
            }
            this.empresaSelecionada = empresa;
            this.$refs.selectCA.clearAll();
            this.baseCalculo = "";
            this.buscaColaboradoresModalAjustes(empresa);
            this.buscaPontos(empresa);
        },

        mudaSelect(eventos) {
            if (!this.isValidUltimoFechamento(eventos)) {
                eventos.selectTipo = !eventos.selectTipo;
                this.$forceUpdate();
                setTimeout(() => {
                    let id = `input_${eventos.horario}_${eventos.veiculo}_${eventos.evento}`;
                    var el = document.getElementById(id);
                    if (el) el.focus();
                }, 30);
                return;
            }
        },

        /**
         * @description faz uma request para mudar o tipo de jornada do
         * do colaboras
         * @author Marcola, o Marcelo 😳
         */
        async mudaTipoJornada(eventos) {
            this.loading = true;
            let obj = {
                dados: {
                    ...eventos,
                    justificativa: this.justificativa,
                    colaborador: this.colabSelecionado,
                },
            };
            try {
                let dados = await new HttpRequest().Post(
                    this.urlJornadaTrabalho + "salvarTipoJornada",
                    obj
                );
                if (dados.code == 200) {
                    this.verificarAlertasJornada();
                }
            } catch (error) {
                ("");
            } finally {
                this.loading = false;
            }
            return;
        },

        /**
         * Valida se a data do evento
         * está dentro de um fechamento
         */
        isValidUltimoFechamento(eventos) {
            const data = eventos.data;
            const format = "dd/LL/yyyy";
            const ultimoFechamento = validaUltimoFechamentoPorDia(
                eventos.ultimoFechamento,
                data,
                format
            );
            return ultimoFechamento[0];
        },

        /**
         * @description verifica se existem alertas de jornada, se sim, seta os alertas
         * em [alertasTabela]. Função semelhante à função do backend.
         * @author Rafael
         */
        verificarAlertasJornada() {
            this.alertasTabela = [];
            let alertas = new Map();
            let group = new Map();
            let tipos = this.tabelaAjustesCondutores.map((e) => e.tipo);
            tipos.forEach((tipo) => {
                let filtered = this.tabelaAjustesCondutores.filter((e) => {
                    return e.tipo == tipo;
                });
                group.set(tipo, filtered);
            });
            let cont = 0;
            group.forEach((tipos, tipo) => {
                let size = tipos.length;
                if (size > 1) {
                    let eventoCerto = tipos[0].evento;
                    tipos.every((t) => {
                        if (t.evento != eventoCerto) {
                            cont++;
                            alertas.set(cont, "Eventos duplicados (" + t.tipo + ")!");
                            return false;
                        }
                        eventoCerto = eventoCerto == "Abertura" ? "Fechamento" : "Abertura";
                        return true;
                    });
                }
                if (size && tipos[0].evento != "Abertura") {
                    cont++;
                    alertas.set(cont, "Falta abertura (" + tipo + ")!");
                }
                if (size && tipos[size - 1] && tipos[size - 1].evento != "Fechamento") {
                    cont++;
                    alertas.set(cont, "Falta fechamento (" + tipo + ")!");
                }
            });
            let listaDeAlertas = [];
            alertas.forEach((e) => {
                listaDeAlertas.push(e);
            });
            this.alertasTabela = listaDeAlertas;
        },

        //Método para buscar colaboradores para o select do modal Ajustes
        async buscaColaboradoresModalAjustes(arr) {
            if (arr.length > 0) {
                this.carregando = true;
                new FiltrosService().dados_filtros(arr, ["V", "JJ"]).then((data) => {
                    this.optSelectVeiculosModalAjustes = data.V;
                    this.optJustificativas = data.JJ;
                });
                this.carregando = false;
            } else {
                this.colabSelecionado = null;
                this.veiculoSelecionado = [];
            }
        },

        async buscaPontos(arr) {
            this.carregando = true;
            var body = { cliente: arr[0], somenteColabs: true };
            var dados = await new HttpRequest().Post(`${this.url}buscaPontos`, body);
            this.optSelectColaboradores = dados.data.colabs;
            if (this.optSelectColaboradores.length > 0) {
                this.baseCalculo = dados.data.colabs[0].basecalculo;
            }
            this.carregando = false;
        },

        changeColaboradoresModalAjustes(value) {
            this.limpaDados();
            if (value[0]) this.getUltimoFechamento(value[0]);
            this.colabSelecionado = value && value.length ? value[0] : null;
        },

        getUltimoFechamento(colabSelecionado) {
            let objRequisicao = {
                mtcodigo: colabSelecionado,
                dataFim: this.dataSelecionada.split(" - ")[1],
            };
            new HttpRequest()
                .Post(`${this.url}getUltimoFechamento`, objRequisicao)
                .then((response) => {
                    this.ultimoFechamento = response.data;
                    this.possuiFechamento = validaUltimoFechamento(
                        this.ultimoFechamento,
                        this.dataSelecionada
                    );
                })
                .catch((err) => {
                    conectionError();
                });
        },

        changeDataIntervaloModalAjustes(date) {
            this.limpaDados();
            this.dataSelecionada = date;
            this.validaFechamentoDentroDoIntervalo();
        },

        /**
         * Se o funcionario ainda tem um fechamento dentro do intervalo
         * selecionado sem precisar fazer uma nova requisição.
         */
        validaFechamentoDentroDoIntervalo() {
            if (this.colabSelecionado && this.ultimoFechamento) {
                this.possuiFechamento = validaUltimoFechamento(
                    this.ultimoFechamento,
                    this.dataSelecionada
                );
            }
        },

        limpaDados() {
            this.alertasTabela = [];
            this.tabelaAjustesCondutores = [];
            this.possuiFechamento = [];
            this.nuloModal = true;
            this.statusBarModal = "info";
        },

        async gerarTabela() {
            this.loading = true;
            this.nuloModal = true;
            let datas = this.dataSelecionada.split(" - ");
            var body = {
                cliente: this.empresaSelecionada[0],
                motorista: this.colabSelecionado,
                dataini: datas[0],
                datafim: datas[1],
            };
            var dados = await new HttpRequest().Post(`${this.url}gerarTabelaJornadaCondutor`, body);
            (this.disabledCheckAll = false),
                (this.tabelaAjustesCondutores = dados.data.information);
            this.alertasTabela = dados.data.alerts;
            if (this.tabelaAjustesCondutores.length > 0) {
                this.tabelaAjustesCondutores.forEach((element) => {
                    element.selectTipo = false;
                });
                this.nuloModal = false;
            } else {
                this.statusBarModal = "error";
            }
            if (this.alertasTabela == []) {
                this.colocarAlertasTabela = false;
            } else {
                this.colocarAlertasTabela = true;
            }
            this.loading = false;
        },

        selecionarTodos() {
            if (this.checkTodosModal == true) {
                this.tabelaAjustesCondutores.forEach((element) => {
                    this.checkModal.push(element.codigo);
                });
            } else {
                this.checkModal = [];
            }
        },

        /**
         * Mostrar modal adicionar eventos.
         * @listens click
         */
        adicionarEvento() {
            this.tipoEvento = "adicionar";
            this.$refs.modalJustificativaAjusteAC.preparaModal(null, this.optJustificativas);
        },

        /**
         * Método para fechar o modal Ajustes.
         * @listens click
         */
        cancelButtonModalAjustes() {
            this.$bvModal.hide(this.idModal);
        },

        /**
         * Mostrar modal remover eventos.
         * @listens click
         */
        removerEvento() {
            this.tipoEvento = "remover";
            this.$refs.modalJustificativaAjusteAC.preparaModal(null, this.optJustificativas);
        },

        // /**
        //  * Mostrar modal remover eventos.
        //  * @listens click
        //  */
        // alterarEvento(evento) {
        //     this.eventoAlterado = evento
        //     this.tipoEvento = 'alterar'
        //     this.$refs.modalJustificativaAjusteAC
        //         .preparaModal(null, this.optJustificativas)
        // },

        /**
         * Função para excluir registro de um colaborador.
         * @listens confirmaExclusao
         */
        async excluirEvento() {
            this.loading = true;
            this.msgErroRemoverEvento = false;
            this.carregandoModal = true;
            try {
                var body = {
                    colaborador: this.colabSelecionado,
                    excluidos: this.checkModal,
                    justificativa: this.justificativa,
                };
                var dados = await new HttpRequest().Post(
                    `${this.url}excluirEventoColaborador`,
                    body
                );
                if (dados.code == 200) {
                    this.tabelaAjustesCondutores = this.tabelaAjustesCondutores.filter((e) => {
                        return !this.checkModal.includes(e.codigo);
                    });
                    this.checkModal = [];
                    // this.gerarTabela()
                    if (!this.tabelaAjustesCondutores.length) {
                        this.limpaDados();
                    } else {
                        this.verificarAlertasJornada();
                    }
                } else {
                    this.msgErroRemoverEvento = true;
                }
            } catch (error) {
                this.msgErroRemoverEvento = true;
            } finally {
                this.carregandoModal = false;
                this.loading = false;
            }
        },

        /**
         * Reseta as variáveis com os valores iniciais.
         * @author Rafael
         */
        resetModal() {
            this.empresaSelecionada = [];
            this.tipoSelecionado = [];
            this.colabSelecionado = null;
            this.veiculoSelecionadoModal = [];
            this.dataSelecionada = [];
            this.optSelectColaboradores = [];
            this.baseCalculo = "";
            this.tabelaAjustesCondutores = [];
            this.alertasTabela = [];
            this.checkModal = [];
        },

        modalClose() {
            this.resetModal();
        },

        changeJustificativa(value) {
            this.justificativa = value && value.length ? value[0] : null;
            this.openAdcEvento();
        },

        openAdcEvento() {
            if (this.tipoEvento == "remover") {
                this.$bvModal.show("modal-deleta-3");
            }
            if (this.tipoEvento == "adicionar") {
                this.veiculoSelecionado = [];
                this.tipoSelecionado = [];
                this.$refs.modalAdicionarEvento.preparaModalAdicionarEventos(
                    this.empresaSelecionada,
                    this.dataSelecionada,
                    this.colabSelecionado,
                    this.justificativa,
                    this.ultimoFechamento
                );
            }
            // if (this.tipoEvento == 'alterar') {
            //     this.mudaTipoJornada(this.eventoAlterado)
            // }
        },
    },

    computed: {
        bloqueiaAdicionarEvento() {
            let fechamento = [];
            if (this.ultimoFechamento && this.dataSelecionada) {
                fechamento = validaFechamentoDataFim(this.ultimoFechamento, this.dataSelecionada);
            }
            return fechamento[0];
        },
    },
});
</script>
<style lang="scss">
//Modal ajustar horas colaborador
#modalAjustesColaborador {
    #checkBox {
        .custom-control {
            z-index: 0;
        }
    }
    .fechamento {
        text-align: center;
        color: red;
    }
    .fechamento__grude {
        background-color: #f5f5f5;
        position: sticky;
        top: 2.1em;
        z-index: 1 !important;
    }
    //Modal Ajustes Horas Condutor
    .divModalColaboradores {
        div {
            padding-left: 0px;
            .subTitleSelect {
                padding-left: 0px !important;
            }
        }
    }
    .divButtonModalAjustes {
        padding-right: 0px !important;
        width: 100% !important;
        align-content: flex-end;
    }
    .buttonGerarModalCarro {
        height: 40px !important;
        width: 100%;
    }
    .divAdicionarEvento {
        padding-right: 0px !important;
        padding-left: 0px !important;
        .buttonAdicionarEvento {
            height: 40px !important;
            width: 100% !important;
            float: right !important;
        }
    }
    .divRemoverEvento {
        padding-right: 0px !important;
        padding-left: 0px !important;
        .buttonRemoverEvento {
            height: 40px !important;
            width: 100% !important;
        }
    }
    .divEventosModalAjustesColaboradores {
        padding-left: 0px !important;
    }
    .trEventosModalAjustesColaboradores {
        background: #e69292;
        margin: 2px;
        width: 49%;
        span {
            margin: 7px;
        }
    }
    .wd-100 {
        width: 100%;
    }
    .selectTipoJornada {
        padding: 2px;
        height: 100%;
        background-color: #fff;
        box-sizing: border-box;
        border: 0.5px solid #e8e8e8;
        clear: both;
        cursor: pointer;
        display: block;
        float: left;
        font-family: inherit;
        text-align: left !important;
    }
}
</style>
