<template>
    <modalEagle
        :title="'Confirmar manutenção: '+
            dadosManutencao.veplaca+' | '+
            dadosManutencao.veprefixo+
            ' - '+dadosManutencao.mcdescricao"
        id="modal-confirmar-manutencao"
        size="lg">
        <slot slot="modalBody">
            <b-overlay :show="loadingModal" rounded="sm">
                <div class="col-sm-12 row nopadding">
                    <div class="col-6 nopadding mt-1">
                        <inputOnlyDayAndMonth
                            name = 'Data'
                            label="Selecione a data em que a manutenção foi realizada:"
                            :isObrigatorio='true'
                            @changeInput='changeDia'/>
                    </div>
                    <div class="col-6 nopadding mt-1">
                        <inputMoney
                            ref="valorServico"
                            label='Informe o valor do serviço:'
                            name="valorServico" 
                            id="valorServico"
                            @changeInput="changeValorServico"
                        />
                    </div>
                        <div v-if="dadosManutencao.mcapuracao != apuracaoDias" class="col-6 nopadding mt-1">
                            <inputSimple
                            :label="dadosManutencao.mcapuracao == 1 ?
                                            'Informe o hodômetro' :
                                            'Informe o horímetro'"
                            type='number'
                            :value="hodometroHorimetro"
                            @changeInput="changeHodometroHorimetro"/>
                        </div>
                    <div class="col-6 nopadding mt-1">
                        <inputMoney
                            ref="valorPecas"
                            label='Informe o valor em peças:'
                            name="valorPecas" 
                            id="valorPecas"
                            @changeInput="changeValorPecas"
                        />
                    </div>
                    <div class="col-6 mt-1 servicos">
                        Confirme os serviços que foram realizados:
                        <div class="col-sm-12 nopadding row confirmaServicos mt-2"
                            v-for="(dados, d) in servicos"
                            :key="d">
                            <div class="col-sm-10">
                                {{dados.mtsdescricao}}
                            </div>
                            <div class="col-sm-2">
                                <b-form-checkbox
                                    v-model="dados.mcsrealizado"
                                    :id="'confirmaServicos-'+dados.mcscodigo"
                                    name="confirmaServicos"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-6 nopadding mt-1">
                        <div class="col-sm-12 nopadding">
                            <inputMoney
                                ref="valorTotalConclusao"
                                label="Valor total" 
                                name="valorTotalConclusao" 
                                id="valorTotalConclusao"
                                :disabled="true"
                                :value="valorTotal"
                            />
                        </div>
                        <div class="col-sm-12 nopadding mt-1">
                            <inputSimple
                                label='Observação'
                                :value=observacao
                                @changeInput="changeObservacao"
                                />
                        </div>
                    </div>
                </div>
            </b-overlay>
        </slot>
        <slot slot="modalFooter">
            <div class="d-flex flex-row nopadding">
                <simpleButton
                    class ="p-2"
                    :icon="mdiCheckBold"
                    text="Salvar"
                    event="click"
                    type="blue"
                    :disabled="$v.$invalid"
                    @click="confirmaManutencao"
                />
                <simpleButton
                    class ="p-2"
                    :icon="mdiCloseThick"
                    text="Cancelar"
                    event="click"
                    type="red"
                    @click="fechaModal"
                />
            </div>
        </slot>
    </modalEagle>
</template>
<script lang="js">
import Vue                      from 'vue';
import { FiltrosService }       from '@/Services/filtros/filtros.Service';
import {required}   from 'vuelidate/lib/validators';
import {HttpRequest}            from '../../../Services/auth/HttpRequest.Service';
import { EmpresasService }      from '@/Services/auth/Empresas.service';
import {mdiCloseThick, mdiCheckBold} from '@mdi/js';
import {inputRangeWithLimit}    from '@/components/Atom/Datas/InputRangeWithLimit';

export default Vue.extend({
	name: 'modalConclusaoManutencao',

	components:{
		modalEagle           :   require('@/components/Atom/Modal/ModalEagle').default,
		simpleButton         :   require('@/components/Atom/Buttons/SimpleButton').default,
        inputOnlyDayAndMonth : require('@/components/Atom/Datas/InputOnlyDayAndMonth').default,
        inputSimple          : require('@/components/Atom/Inputs/InputSimple').default,
        inputMoney           : require('@/components/Atom/Inputs/InputMoney').default,
	},

    validations: {
        dataConfirmacao: { required },
        valorServico: { required },
        hodometroHorimetro: function(value) {
            if (this.dadosManutencao && this.dadosManutencao.mcapuracao === this.apuracaoDias) {
            return true;
            } else {
            return { required: true };
            }
        }
    },

	data(){
		return{
            link: '/manutencoes/nao/menus/controle/manutencao/',
            mdiCloseThick:mdiCloseThick,
            mdiCheckBold: mdiCheckBold,
            loadingModal       : false,
            confirmaServicos   : false,
            dadosManutencao    : {},
            servicos           : [],
            dataConfirmacao    : '',
            valorServico       : '',
            hodometroHorimetro : '',
            valorPecas         : '',
            valorTotal         : '',
            observacao         : '',
            apuracaoDias: 3
		}
	},

	methods: {
        preparaModalConclusaoManutencao(dados){
            this.dadosManutencao = dados
            this.hodometroHorimetro = this.dadosManutencao.mcapuracao == 1 ?
                                parseInt(this.dadosManutencao.vchodometro/1000) : 
                                this.dadosManutencao.vchorimetro
            this.observacao = this.dadosManutencao.mcobservacao
            this.$bvModal.show('modal-confirmar-manutencao')
            this.buscaServicos()
        },
        
        changeDia(data){
            this.dataConfirmacao = data
        },

        changeValorServico(valorServico){
            this.valorServico = valorServico
            this.somaValorTotal()
        },

        changeHodometroHorimetro(hodometroHorimetro){
            this.hodometroHorimetro = hodometroHorimetro
        },

        changeValorPecas(valorPecas){
            this.valorPecas = valorPecas
            this.somaValorTotal()
        },

        changeValorTotal(valorTotal){
            this.valorTotal = valorTotal
        },

        changeObservacao(observacao){
            this.observacao = observacao
        },

        /** 
         * @description Método para buscar os serviçõs vinculados a manutenção
         * @author Vitor Hugo 🐨
         */
        buscaServicos(){
            this.loadingModal = true
            new HttpRequest().Post(this.link+'busca/servicos',
                {'codigoManutencao' : this.dadosManutencao.mhcodigomanutencao})
                .then((dados)=>{
                    this.loadingModal = false
                    this.servicos = dados.data.dados
                })
        },

        /** 
         * @description Método para salvar a confirmação da manutenção
         * @author Vitor Hugo 🐨
         */
        confirmaManutencao(){
            this.loadingModal = true
            var obj = {
                'data'               : this.dataConfirmacao,
                'valorServico'       : this.valorServico,
                'hodometroHorimetro' : this.hodometroHorimetro,
                'valorPecas'         : this.valorPecas,
                'valorTotal'         : this.valorTotal,
                'observacao'         : this.observacao,
                'servicos'           : this.servicos,
                'codigoManutencao'   : this.dadosManutencao.mhcodigomanutencao,
                'apuracao'           : this.dadosManutencao.mcapuracao,
            }
            new HttpRequest().Post(this.link+'confirma/manutencao', {obj})
                .then((dados)=>{
                    if(dados.code === 200){
                        this.$emit('atualizaKanban')
                        this.$bvModal.hide('modal-confirmar-manutencao')
                        this.$bvModal.show('openModalConfirmCopiarManutencao')
                        // Emitindo o evento para o componente controlemanutencao
                        this.$emit('codigoManutencaoCopia', this.dadosManutencao.mhcodigomanutencao);
                    }
                    this.loadingModal = false
                })
        },

        /** 
         * @description Método soma o valor todal do serviço 
         *  com as peças a atribui a variável valorTotal
         * @author Vitor Hugo 🐨
         */
        somaValorTotal(){
            this.valorTotal = Number(this.valorServico) + Number(this.valorPecas)
        },

        fechaModal(){
            this.$bvModal.hide('modal-confirmar-manutencao')
        }
	}, 
})

</script>

<style lang="scss">
   #modal-confirmar-manutencao{
       .servicos{
           font-family: nexabook, sans-serif !important;
       }
       .confirmaServicos{
           background: #f5f5f5f5 !important;
       }
   }
</style>
