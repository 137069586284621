<template>
    <panelEagle id="cadPerifericos"
        :loading="loadingPanel">
        <div class="col-sm-12 nopadding divDontPrint">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiChip" titulo="Perifericos"></tituloPage>
                </div>
                <div class="col-sm-6 divDontPrint">
                    <BasicButtonsCadastrosSC 
                        :disabled="$v.$invalid"
                        tipoCancela='button'
                        linkCancel="listarPeriferico" 
                        @salvarCadastro="salvarPerifericos">
                    </BasicButtonsCadastrosSC>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 row">
                <div class="col-sm-3 nopadding">
                    <inputSimple
                        class = "mb-2 nopadding"
                        :inputClass="{'border border-danger':$v.descricaoPerifericos.$anyError}"
                        @blur="$v.descricaoPerifericos.$touch()"
                        @changeInput="changeDescricao" 
                        :value="descricaoPerifericos" 
                        name="descricaoPerifericos" 
                        id="descricaoPerifericos" 
                        label="Descrição*"/>

                    <inputMoney
                        class = "mb-2 nopadding"
                        :inputClass="{'border border-danger':$v.valorVendaPerifericos.$anyError}"
                        @blur="$v.valorVendaPerifericos.$touch()"
                        @changeInput="changeValorVenda" 
                        :value="valorVendaPerifericos"
                        name="valorVendaPerifericos" 
                        id="valorVendaPerifericos" 
                        label="Valor venda sugerido*"/>

                    <requiredFields class="pl-0"></requiredFields>
                </div>

                <div class="col-sm-3 nopadding">
                    <inputMoney
                        class = "mb-2"
                        :inputClass="{'border border-danger':$v.custoPerifericos.$anyError}"
                        @blur="$v.custoPerifericos.$touch()"
                        @changeInput="changeCusto" 
                        :value="custoPerifericos"
                        name="custoPerifericos" 
                        id="custoPerifericos"
                        label="Custo*"/>

                    <inputMoney 
                        class = "mb-2"
                        :inputClass="{'border border-danger':$v.mensalidadePerifericos.$anyError}"
                        @blur="$v.mensalidadePerifericos.$touch()"
                        @changeInput="changeMensalidade" 
                        :value="mensalidadePerifericos"
                        name="mensalidadePerifericos" 
                        id="mensalidadePerifericos" 
                        label="Mensalidade*"/>
                </div>

                <div class="col-sm-3 nopadding">
                    <inputMoney 
                        class = "mb-2 pl-0"
                        :inputClass="{'border border-danger':$v.instalacaoPerifericos.$anyError}"
                        @blur="$v.instalacaoPerifericos.$touch()"
                        @changeInput="changeInstalacao" 
                        :value="instalacaoPerifericos" 
                        name="instalacaoPerifericos" 
                        id="instalacaoPerifericos"
                        label="Serviço de instalação*"/>

                    <inputMoney 
                        class = "mb-2 pl-0"
                        :value="custoTotal"
                        disabled
                        name="custoTotalPerifericos" 
                        id="custoTotalPerifericos" 
                        label="Custo total*"/>
                </div>
                <div class="col-sm-3 nopadding">
                    <inputMoney
                        class = "mb-2 nopadding"
                        :inputClass="{'border border-danger':$v.materialPerifericos.$anyError}"
                        @blur="$v.materialPerifericos.$touch()"
                        @changeInput="changeMaterial"
                        :value="materialPerifericos" 
                        name="materialPerifericos" 
                        id="materialPerifericos"
                        label="Material instalação*"
                        />

                    <selectAll 
                        class = "mb-2 nopadding"
                        :extraClass="{'border border-danger':$v.rastreadorSelected.$anyError}"
                        @close="$v.rastreadorSelected.$touch()"
                        @changeSelect="changeRastreador" 
                        :selected="rastreadorSelecionados" 
                        :isMultiple="true" 
                        nameForRadio="rastreadorSelecionados" 
                        :hasSelectAll="true" 
                        :labels="[{indexDFH: 'EM', description: 'Rastreadores Suportados*'}]" 
                        :optionsArray="optionsArrayRastreador" 
                        firstSelected="EM"/>
                </div> 
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
    </panelEagle>
</template>

<script lang="js">
import Vue from 'vue'
import {mdiChip } from '@mdi/js'
import { required, minValue } from 'vuelidate/lib/validators';
import {EmpresasService} from '@/Services/auth/Empresas.service'
import {HttpRequest} from '../../../../Services/auth/HttpRequest.Service'
import { conectionError } from  '@/Services/Helpers/swellHeper.js'
export default Vue.extend({
    name:'cadastroPerifericos',
    components:{
        'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage': require('@/components/Atom/Header/Titulo').default,
        'selectAll': require('@/components/Atom/Select/SelectAll').default,
        'BasicButtonsCadastrosSC': require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
        'inputSimple': require('@/components/Atom/Inputs/InputSimple').default,
        'inputMoney': require('@/components/Atom/Inputs/InputMoney').default,
        'requiredFields': require('@/components/Atom/Footer/RequiredFields').default,
    },

    validations:{
        descricaoPerifericos: {
            required
        },

        custoPerifericos: {
            required,
            minValue: minValue(0.01)
        },

        mensalidadePerifericos: {
            required,
            minValue: minValue(0.01)
        },
        instalacaoPerifericos: {
            required,
            minValue: minValue(0.01)
        },
        materialPerifericos: {
            required,
            minValue: minValue(0.01)
        },
        rastreadorSelected: {
            required
        },
        valorVendaPerifericos: {
            required,
            minValue: minValue(0.01)
        },
    },

     methods:{
        changeDescricao(descricao){
            this.descricaoPerifericos = descricao;
        },

        changeValorVenda(valor){
            this.valorVendaPerifericos = valor;
        },

        changeCusto(custo){
            this.custoPerifericos = custo;
        },
        
        changeMensalidade(mensalidade){
            this.mensalidadePerifericos = mensalidade;
        },

        changeInstalacao(instalacao){
            this.instalacaoPerifericos = instalacao;
        },

        changeMaterial(material){
            this.materialPerifericos = material;
        },

        changeRastreador(arr){
            this.rastreadorSelected = arr;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
        },

        async salvarPerifericos(){
            this.loadingPanel = true
            var dados = {
                'pedescricao'       : this.descricaoPerifericos,
                'pevenda'           : this.valorVendaPerifericos,
                'pecusto'           : this.custoPerifericos,
                'pevalormensalidade': this.mensalidadePerifericos,
                'pevalorinstalacao' : this.instalacaoPerifericos,
                'pecustototal'      : this.custoTotal,
                'pematerial'        : this.materialPerifericos,
                'pecodigo'          : this.pecodigo,
            }
            if(this.$route.params.id){
                dados = {...dados, pecodigo:this.$route.params.id}
            }else{
                delete dados.pecodigo
            }
            var dadosSelect = {
                'mpmodulo'     : this.rastreadorSelected,
                'mpperiferico' : this.pecodigo,
            }
            var obj =  {'dados': dados, 'dadosSelect': dadosSelect, 'success': this.success}
            var uri = '/administrativo/cadastros/perifericos/salvar'
            new HttpRequest().Post(uri, obj).then((data)=>{
                if(data.code == 201){
                    this.loadingPanel = false
                    this.$router.push({name:'listarPeriferico'})
                }
                else{
                    this.loadingPanel = false
                    this.modalOpsErro()
                }
            })
        },

        modalOpsErro(){
            conectionError()
        },

        async editarPerifericos(codigo){
            this.loadingPanel = true
            var dados = await new HttpRequest().Post(
                '/administrativo/cadastros/perifericos/editar', {'id': codigo});
            var perifericos = dados.data.dados
            this.pecodigo = perifericos.pecodigo
            this.descricaoPerifericos = perifericos.pedescricao
            this.valorVendaPerifericos = perifericos.pevenda
            this.custoPerifericos = perifericos.pecusto
            this.mensalidadePerifericos = perifericos.pevalormensalidade
            this.instalacaoPerifericos = perifericos.pevalorinstalacao
            this.materialPerifericos = perifericos.pematerial
            this.rastreadorSelecionados = dados.data.select
            this.mpperiferico = dados.data.select
            this.mpmodulo = dados.data.select
            this.mpcodigo = dados.data.codigo
            this.loadingPanel = false
        },

        async selectTodosPerifericos(){
            this.loadingPanel = true
            var dados = await new HttpRequest().Post(
                '/administrativo/cadastros/perifericos/select')
            this.optionsArrayRastreador = dados.data 
            this.loadingPanel = false
        }
    },

    data(){
        return {
            mdiChip:mdiChip,
            descricaoPerifericos: '',
            valorVendaPerifericos: '',
            custoPerifericos: '',
            mensalidadePerifericos: '',
            instalacaoPerifericos: '',
            materialPerifericos: '',
            rastreadorSelecionados: [],
            selecTodos: [],
            mpcodigo: [],
            mpmodulo: [],
            mpperiferico: [],

            descricaoSelected: '',
            valorVendaSelected: '',
            custoSelected: '',
            mensalidadeSelected: '',
            instalacaoSelected: '',
            custoTotalSelected: '',
            materialSelected: '',
            rastreadorSelected: [],

            loadingPanel:false,
            pecodigo: null,
            success: false,

            custoCheio:'',
            
            optionsArrayRastreador : [],
            codigo: '',
        }
    },

    mounted(){
        if(this.$route.params.id){        
            this.editarPerifericos(this.$route.params.id)
        }

       this.selectTodosPerifericos();
    },

    computed:{
         custoTotal(){
            var total = Number(this.custoPerifericos) + Number(this.instalacaoPerifericos) + Number(this.materialPerifericos)  
            return total.toFixed(2)
        },
    }
})
</script>

<style scoped>
    .margin{
        margin: 15px;
        text-align: center;
    }
</style>
