<template>
    <panelEagle id='Pontuacao' :loading='loadingPanel'>
        <div class="col-sm-12 nopading row">
            <div class="col-sm-6 nopadding">
                <tituloPage :icon='mdiTrophy' titulo='Pontuação'></tituloPage>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 divDontPrint nopadding">
                <basicButtonsCadastrosEIN
                    :disabledDropdown="!Object.keys(dadosTabela).length"
                    tipoNovo = 'button'
                    linkNew ='cadastroPontuacao'
                    @exportarCadastro="exportarPontuacao"
                    :loading ='loading'
                    >
                </basicButtonsCadastrosEIN>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class='col-sm-12 row nopadding'>
            <div class="col-lg-4 col-md-12 divDontPrint nopadding">
                <selectAll
                    nameForRadio  ="Selecione*"
                    :isMultiple   ='true'
                    :optionsArray ='optEmpresas'
                    :selected="selectedEmpresa"
                    :labels       ="[{indexDFH: 'SE', description : 'Empresas*'}]"
                    :hasSelectAll ='true'
                    @changeSelect ='changeEmpresa'/>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
            <tableListagem 
                :data = 'dadosTabela'
                :titles ='titles' 
                deleteEdit ='editDelete'
                @excluirCadastro='excluirPontuacao'
                @editarCadastro='editarPontuacao'>
            </tableListagem>
    </panelEagle>
</template>
<script>
import Vue  from 'vue'
import { mapGetters } from 'vuex'
import { EmpresasService } from '../../../Services/auth/Empresas.service'
import { HttpRequest } from '../../../Services/auth/HttpRequest.Service'
import Swal from 'sweetalert2'
import { mdiTrophy } from '@mdi/js'
import { conectionError } from '@/Services/Helpers/swellHeper'
export default Vue.extend({
    name:'Pontuacao',
    components:{
        'panelEagle'               : require('@/components/Atom/Panel/PanelEagle').default,
        'basicButtonsCadastrosEIN' : require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
        'tituloPage'               : require('@/components/Atom/Header/Titulo').default,
        'selectAll'                : require('@/components/Atom/Select/SelectAll').default,
        'tableListagem'            : require('@/components/Atom/Table/TableListagem').default,
    },
    data(){
        return {
            mdiTrophy:mdiTrophy,
            optEmpresas : new EmpresasService().Get(),
            selectedEmpresa:[],
            loadingTabela : false,
            verifica : 0,
            loading :[false, false, false],
            valorEmpresas   : [],
            titles :[
                {'number':'descricao', 'name':'Pontuação'},
                {'number':'empresa'  , 'name':'Empresa'},

			],
			valueBotao    : '',
			labelBot      : 'Tipo',
			empresasSelecionadas :[],
			dadosTabela   : [],
			usuraio       : '',   
			loadingPanel: false,
			success: false,
			uriBase:'/gestao/desempenho/cadastros/pontuacao/',
		}
	},

    methods:{
        ...mapGetters(['getMaster']),
        changeEmpresa(value){
            this.empresasSelecionadas = value
            this.listagemPontuacao() 
        },

		listagemPontuacao(){
			if(this.empresasSelecionadas.length == 0){
				this.dadosTabela = []
			}else{
				this.loadingPanel = true
				let uri = this.uriBase+'listar'
				new HttpRequest().Post(uri, {dados:this.empresasSelecionadas})
					.then(dados=>{
						this.dadosTabela = dados.data.pontuacao
						this.loadingPanel = false
					})
			}
		},

		async exportarPontuacao(type){
			var root =  process.env.VUE_APP_ROOT;
			this.setCarregando(type)
			if(this.dadosTabela != ''){
				var data = await new HttpRequest().Post('/gestao/desempenho/cadastros/pontuacao/exportar', 
					{'dados':this.empresasSelecionadas, 'tipo':type});
				window.open(root+'/'+data.data.exportar);
			}
			this.setCarregando()
		},

		async excluirPontuacao(codigo){
			this.loadingPanel = true
			new HttpRequest().Post('/gestao/desempenho/cadastros/pontuacao/excluir',
                {'id':codigo}
            ).then(({ data }) => {
                if(data.status){
                    this.listagemPontuacao()
                }else if(!data.status && data.message){
                    var msg = `Não é possível excluir um cadastro que tenha vínculo com o colaborador`
                    conectionError(
                        msg, "warning"
                    )
                }else{
                    conectionError()
                }
            }).catch(() => {
                conectionError()
            }).finally(() => this.loadingPanel = false)
		},

		editarPontuacao(codigo){
			this.$router.push({name:'editarPontuacao', params:{id:codigo}})
		},

		setCarregando(type = null){
			if(type == 'pdf'){
				this.loading = [true, false, false]
			}else if(type == 'xls'){
				this.loading = [false, true, false]
			}else if(type == 'csv'){
				this.loading = [false, false, true]
			}else{
				this.loading = [false, false, false]
			}
		},
    },
    mounted(){ 
        this.usuraio = this.$store.getters.getMaster
        this.dadosTabela = []
        if(!this.getMaster()){
            this.selectedEmpresa = this.optEmpresas
        }
    }
})
</script>
<style lang="scss">
    .titulo{
        padding: 0px;
        margin-bottom: 0px;
        margin-left: 16px;
        text-align: left;
    }
    
</style>
