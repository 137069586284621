/**
    Para desenhar poligonos na tela
    props:
      poligono : Objeto que contem=>{
          latlngs ->array com todas as corndelas do desenho, latitude/longitude
          color   ->código da cor do polígono
      }
    slots: 
      Sem nome: Só um slot para por alguma reatividade nas regioes 
 */


<template>
	<div class="col-sm-12 nopadding" >
		<l-polygon
		v-bind='$attrs'
		v-on='$listeners'
		:lat-lngs="(poligono.latlngs)"
		:color="poligono.color"
		:interactive='true'
		:opacity='0.5'
		:smoothFactor='1.1'
		:noClip='false'
		:name='poligono.descricao'
		:fillOpacity='0.3'
		:fillColor="poligono.color">
			<slot poligono:poligono>
			</slot>
		</l-polygon>
	</div>
</template>

<script>
import Vue from 'vue'
import { LPolygon, } from 'vue2-leaflet';
export default Vue.extend({
	components: {
		LPolygon,
	},
	props: {
		poligono: {
			type: Object,
			required: true
		}
	},
	inheritAttrs: false
})
</script>

<style lang="scss" scoped>

</style>