<template>
    <div id='novoPontoMapa' class="d-flex flex-column">
        <div class='mb-2'>
            <multiselect 
            :options='selectEmpresas'
            :showLabels='false'
            track-by='value'
            placeholder=''
            label='description'
            :preselectFirst='true'
            :allow-empty='false'
            v-model='cliSelecionado'
            />
        </div>
        <div class='mb-2 form'>
            <input
            type="text"
            v-model="descricaoPonto"
            class='formDescricao'
            placeholder="Descrição*">
        </div>
        <div class='mb-2'>
            <multiselect 
            :preselectFirst='true'
            placeholder=''
            :options='optTiposDePonto'
            :showLabels='false'
            track-by='value'
            label='text'
            :allow-empty='false'
            v-model='tipoSelecionado'/>
        </div>
        <div class='mb-2'>
            <basic-slider :value="50" @change="mudaSlider" :maxValue="500"/>
        </div>
        <div class='mb-1 d-flex justify-content-center'>
            <span v-text='getRaio()'/>
        </div>
        <div class='mb-2 d-flex justify-content-center'>
            <simple-button
            type='red'
            :icon="iconCancelar"
            text='cancelar'
            event='click'
            @click='cancelaPonto'/>
            <loading-button
            type='blue'
            :icon="iconSalvar"
            text='Salvar'
            event='click'
            :disabled='$v.$invalid'
            :isLoading='salvando'
            @click='salvarPonto'/>
        </div>
    </div>
</template>

<script>
import simpleButton from '@/components/Atom/Buttons/SimpleButton.vue'
import basicSlider from '@/components/Atom/Buttons/BasicSlider.vue'
import loadingButton from '@/components/Atom/Buttons/LoadingButton.vue'
import Multiselect from 'vue-multiselect'
import { required } from 'vuelidate/lib/validators'
import { mdiCheckBold, mdiCloseThick} from '@mdi/js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
export default {
	name:'popupNovoPonto',
	components:{Multiselect, basicSlider, simpleButton, loadingButton},
	props:{
		selectEmpresas:{
			type: Array,
			required: true
		},
	},
	data(){
		return{
			iconSalvar : mdiCheckBold,
			iconCancelar: mdiCloseThick,
			cliSelecionado:[],
			tipoSelecionado:[],
			descricaoPonto:'',
			raio:50,
			optTiposDePonto:[
				{value: 'C', text:'Ponto de coleta'},
				{value: 'E', text:'Ponto de Entrega'},
				{value: 'P', text:'Ponto de Referência'},
			],
			salvando:false,
		}
	},
	validations:{
		descricaoPonto :{ required },
		cliSelecionado :{ required },
		tipoSelecionado:{ required }
	},
	methods: {
		getRaio(){
			return `Raio de ${this.raio} metros`
		},

		/**
		 * @listens change - slider de raio do ponto
		 * @param {number} raio
		 * @fires muda-raio
		 */
		mudaSlider(raio){
			this.raio = raio
			this.$emit('muda-raio', this.raio)
		},

		/**
		 * @listens click
		 * @fires salvar-ponto
		 * @description emite o evento com as informações pra salvar o ponto.
		 * Não fiz a função de salvar o ponto propriamente dita aqui pois
		 * fica meio gambiarra para acessar a lat-lng do ponto.
		 * @author Gui 🍺
		 */
		salvarPonto(){
			this.salvando = true
			this.$emit('salvar-ponto', {
				pocodigocliente:this.cliSelecionado.value,
				potipo:this.tipoSelecionado.value,
				podescricao:this.descricaoPonto,
				poraio:this.raio,
			})
		},

		/**
		 * @listens click
		 * @fires cancelar-ponto
		 */
		cancelaPonto(){
			this.$emit('cancelar-ponto')
		}
		
	},
}
</script>

<style lang="scss" scoped>
#novoPontoMapa{
    width: 250px;
    .formDescricao{
        width: 100%;
        height: 42px;
        padding-left: 5px;
    }
}
</style>