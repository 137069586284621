import { AuthService } from "@/Services/auth/Auth.Service";
import { RouteConfig } from "vue-router";
import Mapa from "@/views/Mapa/Mapa.vue";

export const ifAuthenticated = (_to: any, _from: any, next: any) => {
    if (new AuthService().isAuthenticated()) {
        next();
        return;
    }
    next("/login");
};

export const mapaRoutes: RouteConfig = {
    path: "/mapa",
    name: "Finder",
    beforeEnter: ifAuthenticated,
    meta: {
        requiresAuth: true,
        Module: "mapa",
        breadcrumbName: "Mapa",
    },
    component: Mapa,
};
