/**
    Este componente só coloca o "Campos obrigatórios" no fim do cadastro

    -> Propriedades:
        Sem parametros

    -> Slot:
        Sem slots

    -> Eventos:
        Sem eventos
 */
<template>
    <div class="col-sm-12 divCadObrigatorios">
        <div class="col-sm-12 nopadding subTitleMini">(*) Campos obrigatórios</div>
    </div>
</template>
<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
})

</script>

<style lang="scss">
    .divCadObrigatorios{
        padding-top: 15px;
        padding-bottom: 5px;
        padding-left: 20px;
        font-size: 12px;
        text-align: left;
    }
</style>