<template>
    <panelEagle id='Cadastro-de-Novo-Usuário-App' :loading='loadingPanelEagle'>
        <div class="col-sm-12  nopadding">
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-6 row ">
                    <tituloPage titulo='Colaboradores' :icon='mdiAccountSupervisor'>
                    </tituloPage>
                </div>
                <div class="col-sm-6 row divDontPrint">
                    <basicButtonsCadastrosSC 
                        linkCancel="listagemColaborador"
                        :disabled='$v.$invalid'
                        tipoCancela="button" 
                        @salvarCadastro="salvarCadastro">
                    </basicButtonsCadastrosSC>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12">
                <tabsEagle :tabs="tabs" ref="refTabs" v-model="modelTab" @activate-tab="clickTab">
                    <slot slot="tab-dados">
                        <div class="col-sm-12 row margi-top-10px nopadding">
                            <div class="col-sm-3 nopadding">
                                <buttonsFilters
                                    label="Status"
                                    :onlyOneSelected="true"
                                    :arrayButtons="arrayButtonsStatusTabDados"
                                    @buttonsFiltersChange="buttonsFiltersChangeStatus"
                                />
                            </div>
                            <div class="col-sm-3 nopadding">
                                <inputSimple
                                    :inputClass='{"boder border-danger" : $v.valueInputNome.$anyError }'
                                    :value="valueInputNome"
                                    label="Nome*"
                                    @blur='$v.valueInputNome.$touch()'
                                    @changeInput="changeInputNome"
                                />
                            </div>
                            <div class="col-sm-3 nopadding">
                                <selectAll
                                    :extraClass='{"border border-danger": $v.valueEmpresaDadosPessoais.$anyError}'
                                    :selected="selectedEmpresaDadosPessoais"
                                    nameForRadio="nameForRadio"
                                    :isMultiple="false"
                                    :hasSelectAll="false"
                                    :labels="[{'indexDFH':'EM', 'description':'Empresa*'}]"
                                    :optionsArray="optEmpresas"
                                    @close='$v.valueEmpresaDadosPessoais.$touch()'
                                    @changeSelect='changeSelectEmpresaDadosPessoais'
                                    />
                            </div>
                            <div class="col-sm-3 nopadding">
                                <inputSimple
                                    :inputClass='{"boder border-danger" : $v.valueInputCPF.$anyError}'
                                    id='cpf'
                                    :value="valueInputCPF"
                                    :title="titleCPF"
                                    :hasMask="true"
                                    :mask="['NNN.NNN.NNN-NN']"
                                    label="CPF*"
                                    @blur='$v.valueInputCPF.$touch()'
                                    @changeInput="changeInputCPF"
                                />
                            </div>
                        </div>
                        <div class="col-sm-12 row margi-top-10px nopadding">
                            <div class="col-sm-3 nopadding">
                                <inputSimple
                                    :value="valueInputPIS"
                                    :hasMask="true"
                                    :mask="['NNN.NNNNN.NN-N']"
                                    label="PIS"
                                    @changeInput="changeInputPIS"
                                />
                            </div>
                            <div class="col-sm-3 nopadding">
                                <inputSimple
                                    :value="valueInputRG"
                                    :hasMask="true"
                                    :mask="['PPPPPPPPPPPPPPPPPP']"
                                    label="RG"
                                    @changeInput="changeInputRG"
                                />
                            </div>
                            <div class="col-sm-3 nopadding">
                                <inputSimple
                                    :value="valueInputTelefone"
                                    :hasMask="true"
                                    :mask="['NNPNPNNNNPNNNN']"
                                    label="Telefone"
                                    @changeInput="changeInputTelefone"
                                    />
                            </div>
                            <div class="col-sm-3 nopadding">
                                <inputSingleDay
                                    label="Data de nascimento"
                                    :value="valueInputDataDadosPessoais"
                                    name="inputSingleDay"
                                    @changeInput="changeInputDataDadosPessoais"
                                    />
                            </div>
                        </div>
                        <div class="col-sm-12 row margi-top-10px nopadding">
                            <div class="col-sm-3 nopadding">
                                <selectAll
                                    ref='selectJornadaTrabalho'
                                    nameForRadio="nameForRadio"
                                    :selected="selectedJornadaTrabalho"
                                    :isMultiple="false"
                                    :hasSelectAll="false"
                                    :labels="[{'indexDFH':'EM', 'description':'Jornada de Trabalho*'}]"
                                    :optionsArray="optJornadaTrab"
                                    :disabled='$v.valueEmpresaDadosPessoais.$invalid'
                                    @changeSelect="changeSelectJornadaDeTrabalho"
                                    />
                            </div>
                            <div class="col-sm-3 nopadding">
                                <inputSimple
                                    class="col-sm-12 padd-lef-0"
                                    type="email"
                                    label="Email*"
                                    :value="valueEmail"
                                    :inputClass='{"boder border-danger" : $v.valueEmail.$anyError }'
                                    @blur='$v.valueEmail.$touch()'
                                    @changeInput="changeInputEmail"
                                />
                            </div>
                            <div class="col-sm-3 nopadding">
                                <selectAll
                                    ref='selectedCargo'
                                    nameForRadio="nameForRadio"
                                    :selected="selectedCargo"
                                    :isMultiple="false"
                                    :hasSelectAll="false"
                                    :labels="[{'indexDFH':'C', 'description':'Cargo*'}]"
                                    :optionsArray="optCargo"
                                    :disabled='$v.valueEmpresaDadosPessoais.$invalid'
                                    @changeSelect="changeSelectCargo"
                                />
                            </div>
                            <div class="col-sm-3 nopadding">
                                <inputSingleDay
                                    label="Data de admissão"
                                    :value="valueDataAdmissao"
                                    name="inputSingleDay"
                                    @changeInput="changeInputDataAdmissao"
                                    />
                            </div>
                        </div>
                        <div class="col-sm-12 row nopadding mt-2">
                            <div class="col-sm-3 nopadding">
                                <selectAll
                                    :extraClass='{"border border-danger": $v.valueTipoPonto.$anyError}'
                                    nameForRadio="nameForRadio"
                                    :selected="selectedTipoPonto"
                                    :isMultiple="false"
                                    :hasSelectAll="false"
                                    :labels="[{'indexDFH':'EM', 'description':'Tipo de registro de ponto*'}]"
                                    :optionsArray="optTipoPonto"
                                    @close='$v.valueTipoPonto.$touch()'
                                    @changeSelect="changeSelectTipoPonto"
                                />
                            </div>
                            <div class="col-sm-3 row nopadding">
                                <inputSingleDay
                                    label="Início de controle"
                                    :value="valueInicioControle"
                                    name="inputSingleDay"
                                    @changeInput="changeInputInicioControle"
                                />
                            </div>
                            <div class="col-sm-3 row nopadding">
                                <inputSingleDay
                                    label="Início controle banco de horas*"
                                    :value="valueInicioControleBancoHoras"
                                    name="inputSingleDay"
                                    @changeInput="(v) => valueInicioControleBancoHoras = v"
                                />
                            </div>
                        </div>
                    </slot>
                    <slot slot="tab-endereco" >
                        <div class="col-sm-12 row margi-top-10px nopadding">
                            <div class="col-lg-8 col-md-12 row margin-bot nopadding">
                                <inputSearch
                                    buttonDisabled='disabled'
                                    inputClass="col-sm-12 "
                                    :value="valueInputSearchMapa"
                                    label="Endereço"
                                    name="inputSearch"
                                    @keyupEnter="clickButtonSearchMapa"
                                    @clickButtonSearch="clickButtonSearchMapa"
                                />
                                
                            </div>
                            <div class="col-lg-2 col-md-6 nopadding margin-bot-grande">
                                <inputSimple
                                    :value="valueLatitude"
                                    label="Latitude"/>
                            </div>
                            <div class="col-lg-2 col-md-6 nopadding margin-bot-grande">
                                <inputSimple
                                    :value="valueLongitude"
                                    label="Longitude"/>
                            </div>
                            <div class="col-sm-12 position">
                            </div>
                        </div>
                    </slot>
                </tabsEagle>
            </div>
            <div v-show="mostraMapa" class="col-sm-12 position-abs">
                <div class="col-sm-8 row div-local-tamanho padd-seletor-rua pl-3" v-show="mostraSeletorLocalMapa">
                    <b-list-group class="div-enderecos col-sm-12">
                        <b-overlay :show='loadingEndereco'>
                        <b-list-group-item 
                            v-show="enderecosMapa.length != 0 && !semEnderecos"
                            button
                            @click="clickListaEnderecoMapa(enderecosMapa)">
                                {{enderecosMapa.end}}
                        </b-list-group-item>
                        <b-list-group-item 
                            v-show="!enderecosMapa"> 
                            Endereço não encontrado
                        </b-list-group-item>
                        </b-overlay>
                    </b-list-group>
                </div>
                <mapaSimples height="500"
                    ref='mapaEndereco'>
                    <markerPoint
                        icon="img/matriz.png"
                        :isDraggable="false"/>
                    <markerPoint
                        :latitude="latitude"
                        :longitude="longitude"
                        @drag='dragMarker'
                        @dragend='dragEnd'
                        :isDraggable="true">
                        <popupMarker>
                            <p>Me arraste para o local desejado. Raio:</p>
                            <basicSlider 
                                @change="changeSlider"
                                :maxValue="500"
                            />
                        </popupMarker>
                    </markerPoint>
                    <circleSimple
                        :center="centroDoCirculo"
                        :radius="valueSlider"/>
                </mapaSimples>
            </div>
            <requiredFields/>
            <div class="col-sm-12"><hr></div>
        </div>
    </panelEagle>
</template>
<script>
import { EmpresasService } from '@/Services/auth/Empresas.service';
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { required, email } from 'vuelidate/lib/validators'
import Vue from 'vue';
import {mdiAccountSupervisor,mdiCheckBold, mdiCloseThick, mdiAlert, mdiPlus } from '@mdi/js'
import PortalVue from 'portal-vue'
import { conectionError }      from '@/Services/Helpers/swellHeper'
Vue.use(PortalVue)
export default Vue.extend({
    components:{
        'selectAll'      : require('@/components/Atom/Select/SelectAll').default,
        'panelEagle'     : require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage'     : require('@/components/Atom/Header/Titulo').default,
        'tabsEagle'      : require('@/components/Atom/Tabs/TabsEagle').default,
        'buttonsFilters' : require('@/components/Atom/Buttons/ButtonsFilters').default,
        'requiredFields' : require('@/components/Atom/Footer/RequiredFields').default,
        'inputSimple'    : require('@/components/Atom/Inputs/InputSimple').default,
        'inputSingleDay' : require('@/components/Atom/Datas/InputSingleDay').default,
        'inputSearch'    : require('@/components/Atom/Inputs/InputSearch').default,
        'basicButtonsCadastrosSC'   : require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
        'mapaSimples'  : require('@/components/Atom/Mapa/MapaSimples').default,
        'markerPoint'  : require('@/components/Atom/Mapa/MarkerPoint').default,
        'popupMarker'  : require('@/components/Atom/Mapa/PopupMarker').default,
        'basicSlider'  : require('@/components/Atom/Buttons/BasicSlider').default,
        'circleSimple' : require('@/components/Atom/Mapa/CircleSimple').default,
    },
    validations:{
        valueEmpresaDadosPessoais : { required },
        valueInputNome            : { required },
        valueInputCPF             : { required },
        valueEmail                : { email, required },
        valueTipoPonto            : { required },
        valueJornadaDeTrabalho    : { required },
        valueCargo                : { required },
        valueInicioControleBancoHoras: {required}
    },
    data(){
        return{
            mdiCheckBold:mdiCheckBold,
            mdiAccountSupervisor:mdiAccountSupervisor,
            url : '/checkpoint/cadastros/colaboradores/',
            modelTab : 0,
            mostraMapa : true,
            // valores primeira tab
            selectedJornadaTrabalho      : [],
            selectedEmpresaDadosPessoais : [],
            valueBotaoFiltroStatus      : [],
            valueEmpresaDadosPessoais   : [],
            valueInputNome              : '',
            valueInputCPF               : '',  
            valueInputRG                : '',  
            valueInputTelefone          : '',  
            valueInputDataDadosPessoais : '',  
            valueEmail                  : '',   
            valueJornadaDeTrabalho      : [],   
            valueInputPIS               : '', 
            valueInicioControleBancoHoras:"",  
            selectedCargo               : [],
            valueCargo                  : [],
            selectedTipoPonto           : [],
            valueTipoPonto              : [],
            valueInicioControle         : '',
            valueDataAdmissao           : '',
            // valores segunda tab
            valueInputSearchMapa        : '',
            valueLongitude              : '',
            valueLatitude               : '',
            valueSlider                 : 50,
            editando                    : false,

            idUsuario                 : '',    
            cpfInvalido :false,
            optTipoPonto : [
                {'value': 'S', 'description': 'Registro simples'},
                {'value': 'F', 'description': 'Reconhecimento facial'},
            ],
            centroDoCirculo:[0,0],
            latitude:0,
            longitude:0,
            optEmpresaModal: new EmpresasService().Get(),
            enderecosMapa:[],
            mostraSeletorLocalMapa:true,
            optJornadaTrab: [],
            optCargo      : [],
            titleCPF:'',
            loadingPanelEagle: false,
            loadingEndereco: false,
            dataTableModal:[],
            semEnderecos: false,
            optEmpresas: new EmpresasService().Get(),
            arrayButtonsStatusTabDados:[
                {
                    'value': 'A', 
                    'width': '47.70%', 
                    'isSelected': true, 
                    'extraClass': '', 
                    'title':'Ativos', 
                    'text':'Ativos', 
                    'icon': ''
                },
                {
                    'value': 'I', 
                    'width': '47.70%', 
                    'isSelected': false, 
                    'extraClass': '', 
                    'title':'Inativos', 
                    'text':'Inativos', 
                    'icon': ''
                },
            ],
            tabs: [
                {
                    title:'Dados pessoais', 
                    value:'dados'
                },
                {
                    title:'Endereço', 
                    value:'endereco'
                },
            ],
        }
    },
    methods:{
        clickTab(value){
            if(value == 1){
                this.mostraMapa = true
                // this.$refs.mapaEndereco.validatesize()
            }else{
                this.mostraMapa = false
            }
        },

        changeInputPIS(value){
            this.valueInputPIS = value
        },

        changeSelectJornadaDeTrabalho(value){
            this.valueJornadaDeTrabalho = value
        },

        changeInputDataDadosPessoais(value){
            this.valueInputDataDadosPessoais = value
        },

        changeInputTelefone(value){
            this.valueInputTelefone = value
        },

        changeInputNome(value){
            this.valueInputNome = value
        },

        changeInputRG(value){
            this.valueInputRG = value
        },

        changeSelectEmpresaDadosPessoais(value){
            this.$refs.selectJornadaTrabalho.clearAll()
            this.$refs.selectedCargo.clearAll()
            this.valueEmpresaDadosPessoais = value
            if(value.length > 0){
                this.buscaJornadaDeTrabalho()
                this.buscaCargo()
            }
        },

        changeSelectCargo(cargo){
            this.valueCargo = cargo
        },

        changeSelectTipoPonto(tipoPonto){
            this.valueTipoPonto = tipoPonto
        },

        changeInputInicioControle(inicioControle){
            this.valueInicioControle = inicioControle
        },

        changeInputDataAdmissao(dataAdmissao){
            this.valueDataAdmissao = dataAdmissao
        },

        changeInputEmail(value){
            this.valueEmail = value
        },

        /**
         *  @description Método para buscar as jornadas relacionadas a empresa
         *  @author Vitor Hugo 🐨
         */
        buscaJornadaDeTrabalho(){
            new HttpRequest().Post(this.url+'busca/jornada', {'dados':this.valueEmpresaDadosPessoais})
                .then(dados=>{
                this.optJornadaTrab = dados.data
            })
        },

        /**
         *  @description Método para buscar os cargos relacionados a empresa
         *  @author Vitor Hugo 🐨
         */
        buscaCargo(){
            new HttpRequest().Post(this.url+'busca/cargo', this.valueEmpresaDadosPessoais)
                .then(dados=>{
                this.optCargo = dados.data.dados
            })
        },

        /**
         *  @listens dragend - Emite evento quando o marker é solto
         *  @description Métodobusca o endereço a partir da latitude e longitude 
         *  @author Vitor Hugo 🐨
         */
        dragEnd(){
            new HttpRequest().Post(this.url+'endereco/proximo', 
                {'local':[this.valueLatitude, this.valueLongitude]})
                .then(dados=>{
                this.valueInputSearchMapa = dados.data
            })
        },

        /**
         *  @listens clickButtonSearch - Emite evento quando botão de 
         *      pesquisa do input é pressionado
         *  @listens keyupEnter - Emite evento quando pressionado 
         *      o botão enter no input 
         *  @description Método para buscar os endereços relacionados a pesquisa
         *  @param {string} value - valor informado no input
         *  @author Vitor Hugo 🐨
         */
        clickButtonSearchMapa(value){
            this.loadingEndereco = true
            new HttpRequest().Post(this.url+'endereco', 
                {'local':value})
                .then(dados=>{
                this.enderecosMapa = dados.data.enderecos
                if(!this.enderecosMapa)
                    this.semEnderecos = true 
                else 
                    this.semEnderecos = false
                this.mostraSeletorLocalMapa = true
                this.loadingEndereco = false
            })
        },

        changeSlider(value){
            this.valueSlider = value
        },

        /**
         *  @listens drag - Emite evento quando o marker é arrastado
         *  @description Método altera latitude e longitude dos campos 
         *  @param {object} value - valores de latitude e longitude a serem alterados
         *  @author Vitor Hugo 🐨
         */
        dragMarker(value){
            this.valueLatitude  = value['latlng'].lat
            this.valueLongitude = value['latlng'].lng
            this.centroDoCirculo = [Number(value['latlng'].lat), Number(value['latlng'].lng)]
        },

        /**
         *  Método copiado da outra tela de colaboradores
         */
        changeInputCPF(value){
            this.valueInputCPF = ''
            if(value.length == 14){
                var str = value
                var res = str.replace('.', '');
                var ress = res.replace('-', '');
                var CpfFinal = ress.replace('.', '');
                var a = '11111111111'
                var b = '22222222222'
                var c = '33333333333'
                var d = '44444444444'
                var e = '55555555555'
                var f = '66666666666'
                var g = '77777777777'
                var h = '88888888888'
                var i = '99999999999'
                var j = '00000000000'
                var k = '12345678900'
                if (CpfFinal != a && 
                    CpfFinal != b &&
                    CpfFinal != c &&
                    CpfFinal != d &&
                    CpfFinal != e &&
                    CpfFinal != f &&
                    CpfFinal != g &&
                    CpfFinal != h &&
                    CpfFinal != i &&
                    CpfFinal != j &&
                    CpfFinal != k){
                    this.testaCpf(CpfFinal, value)
                }else{
                    this.cpfInvalido = true
                    this.addClassCpf()
                }
            }
        },

        /**
         *  Método copiado da outra tela de colaboradores
         */
        testaCpf(strCPF, value){
            var cpf = strCPF
            var i = 0
            var multiplica = 10
            var valorFinal = 0
            while(i < 9){
                var conta = cpf[i]
                var soma = conta*multiplica
                valorFinal = valorFinal+soma
                multiplica = multiplica-1
                i = i+1
            }
            var divisao = valorFinal%11
            divisao = 11-divisao
            if(divisao == cpf[9] || cpf[9] == 0){
                var indice = 0
                var multiplicador = 11
                var valorFinalSegundo = 0
                while(indice < 10){
                    var contaSegunda = cpf[indice]
                    var somaSegunda = contaSegunda*multiplicador
                    valorFinalSegundo = valorFinalSegundo+somaSegunda
                    multiplicador = multiplicador-1
                    indice = indice+1
                }
                var divisaoSegunda = valorFinalSegundo%11
                divisaoSegunda = 11-divisaoSegunda
                if(divisaoSegunda == cpf[10] || cpf[10] == 0){
                    this.cpfInvalido = false
                    this.valueInputCPF = value
                    this.removeClassCpf()
                }else{
                    this.cpfInvalido = true
                    this.addClassCpf()
                }
            }else{
                this.cpfInvalido = true
                this.addClassCpf()
            }
        },

        /**
         *  Método copiado da outra tela de colaboradores
         */
        addClassCpf(){
            this.titleCPF = 'CPF inválido'
            var elemento = document.getElementById('cpf');
            var classes = elemento.className.split(' ');
            var getIndex = classes.indexOf('cpf');
            if (getIndex === -1) {
                classes.push('cpf');
                elemento.className = classes.join(' ');
            }
        },

        /**
         *  Método copiado da outra tela de colaboradores
         */
        removeClassCpf(){
            this.titleCPF = ' '
            var elemento = document.getElementById('cpf');
            var classes = elemento.className.split(' ');
            var getIndex = classes.indexOf('cpf');

            if (getIndex > -1) {
                classes.splice(getIndex, 1);
            }
            elemento.className = classes.join(' ');
        },

        /** @listens click - Emitido quando clicado na lista do mata
         *  @description Método para adicionar valores as variáveis do mapa
         *  @param {object} value - valores com latitude e endereço
         *  @author Vitor Hugo 🐨
        */
        clickListaEnderecoMapa(value){
            this.$refs.mapaEndereco.flyTo(
                [value.lat,value.lon]
            )
            this.latitude = value.lat
            this.longitude = value.lon
            this.valueInputSearchMapa = value.end
            this.valueLatitude = value.lat
            this.valueLongitude = value.lon
            this.centroDoCirculo = [Number(value.lat), Number(value.lon)]
            this.mostraSeletorLocalMapa = false
        },

        salvarCadastro(value){
            this.loadingPanelEagle= true
                var valores =
                    {   
                        // valores primeira tab
                        mtstatus: this.valueBotaoFiltroStatus[0],
                        mtcliente: this.valueEmpresaDadosPessoais[0],
                        mtnome: this.valueInputNome,
                        mtcpf: this.valueInputCPF, 
                        mtrg: this.valueInputRG, 
                        mttelefone: this.valueInputTelefone,
                        mtdatanasc: this.valueInputDataDadosPessoais,
                        mtemail: this.valueEmail,
                        mtjornada: this.valueJornadaDeTrabalho[0],
                        mtpis: this.valueInputPIS,
                        editando: this.editando,
                        mtiniciocontrolebanco:this.valueInicioControleBancoHoras,
                        // valores segunda tab
                        mtraio: this.valueSlider,
                        mtendereco: this.valueInputSearchMapa,
                        mtlatitude: this.valueLatitude,
                        mtlongitude: this.valueLongitude,
                        mttipopontocheckpoint: this.valueTipoPonto[0],
                        mtprimeiroeventocheckpoint: this.valueInicioControle,
                        mtdataadmissao: this.valueDataAdmissao,
                        mtcargo: this.valueCargo[0],
                        mtperfil: 'AD',
                        idUsuario: this.idUsuario
                    }

                new HttpRequest().Post(this.url+'salva/dados', {'dados':valores})
                    .then(dados=>{
                    if(dados.code == 201){
                        this.$router.push({name: 'listagemColaborador'})
                    }else if(dados.data.erro == "user sem CPF"&& !dados.data.success){
                        var msgErro = 'Parece que seu Usuário não tem CPF vinculado a conta! Para continuar, vincule um CPF a sua conta!'
                        conectionError(false, 'warning', msgErro)
                    }else if(dados.code == 200){
                        var title = 'Este CPF já esta cadastrado'
                        conectionError(false, 'warning', title)
                    }else{
                        conectionError()
                    }
                    this.loadingPanelEagle = false
                })
        },


        /**
         *  @description Método pegar o último valor do filtro e 
         *      também alterar qual esta selecionado no momento
         *  @param {array} value - valor do filtro selecionado
         *  @author Vitor Hugo 🐨
         */
        buttonsFiltersChangeStatus(value){
            this.valueBotaoFiltroStatus = value
            if(value[0] == 'A'){
                this.arrayButtonsStatusTabDados[0].icon = mdiCheckBold
                this.arrayButtonsStatusTabDados[1].icon = ''
                this.arrayButtonsStatusTabDados[0].isSelected = true
                this.arrayButtonsStatusTabDados[1].isSelected = false
            }else{
                this.arrayButtonsStatusTabDados[0].icon = ''
                this.arrayButtonsStatusTabDados[1].icon = mdiCheckBold
                this.arrayButtonsStatusTabDados[0].isSelected = false
                this.arrayButtonsStatusTabDados[1].isSelected = true
            }
        },

        /** 
         * @description Método para inserir informações de edição nas tabs
         * @param {object} consulta ojeto da tabela com os campos para edição
         * @author Vitor Hugo 🐨
         */
        arrumaValoresNosCampos(consulta){
            if(consulta.mtlatitude != null){
                this.$refs.mapaEndereco.flyTo(
                    [consulta.mtlatitude,consulta.mtlongitude]
                )
            }
            // valores primeira tab
            this.buttonsFiltersChangeStatus([consulta.mtstatus])           
            this.selectedEmpresaDadosPessoais = [{
                value: consulta.mtcliente,
                description: consulta.clfantasia
            }] 
            this.valueInputNome = consulta.mtnome
            this.changeInputCPF(consulta.mtcpf)
            this.valueInputRG  = consulta.mtrg
            this.editando = true
            this.valueInputTelefone = consulta.mttelefone
            this.valueInputDataDadosPessoais = consulta.mtdatanasc 
            
            if(consulta.mtemail != null){
                this.valueEmail = consulta.mtemail
            }
            this.valueInicioControleBancoHoras = consulta.mtiniciocontrolebanco
            this.selectedJornadaTrabalho = [{
                value: consulta.mtjornada,
                description: consulta.jtdescricao
            }]
            this.valueInputPIS = consulta.mtpis
            this.valueSlider = consulta.mtraio 
            this.valueInputSearchMapa = consulta.mtendereco 
            this.centroDoCirculo = [
                consulta.mtlatitude != null ? consulta.mtlatitude : 0,  
                consulta.mtlongitude != null ? consulta.mtlongitude : 0
            ] 
            this.latitude = consulta.mtlatitude != null ? 
                consulta.mtlatitude : 0
            this.longitude = consulta.mtlongitude != null ? 
                consulta.mtlongitude : 0
                
            this.valueLongitude = consulta.mtlongitude != null ? 
                consulta.mtlongitude : ''
            this.valueLatitude  = consulta.mtlatitude != null ? 
                consulta.mtlatitude : ''
            
            if(consulta.mtcargo != null){
                this.selectedCargo = [{
                    value: consulta.mtcargo,
                    description: consulta.ccdescricao
                }]
            }
            if(consulta.mttipopontocheckpoint != null){
                this.optTipoPonto.forEach(element => {
                    if(element.value == consulta.mttipopontocheckpoint.trim()){
                        this.selectedTipoPonto =[{
                            value: element.value,
                            description : element.description
                        }]
                    }
                });
            }
            this.valueInicioControle = consulta.mtprimeiroeventocheckpoint
            this.valueDataAdmissao = consulta.mtdataadmissao
        },
        
    },
    async mounted(){
        this.$nextTick(() => {
            this.mostraMapa = false
		})
        if(this.$route.params.id != undefined){
            this.loadingPanelEagle = true
            this.idUsuario = this.$route.params.id
            new HttpRequest().Post(this.url+'busca/dados', {'id':this.idUsuario})
                    .then(consulta=>{
                    this.arrumaValoresNosCampos(consulta.data.consultaMotorista[0])
                this.loadingPanelEagle = false
            })
        }
    },
})
</script>
<style lang="scss">
    .borda-vermelha{
        border: red 1px solid !important;
    }
    .margin-bot-grande{
        margin-bottom: 15px;
    }
    .margin-bot{
        margin-bottom: -35px;
    }
    .cpf{
        border: 1px solid red !important;
    }
    .position{
        position: relative;
    }
    .padd-seletor-rua{
        border: 1px solid #ffffff;
        margin-right: 5px !important;
        background-color: #ffffff;
        z-index: 1000 !important;
        top:-12px !important;
        left: 0px !important;
        padding-right: 15px !important;
    }
    .position-abs{
        z-index: 990 !important;
        position: absolute;
    }
    .div-local-tamanho{
        top: -45px;
        padding-left: 30px  !important;
        float: left;
        position: absolute;
        max-height: 200px;
        z-index: 1;
        overflow-x: auto;
    }
    .margin-none{
        margin-bottom: -40px;
        // margin: none;
    }
    div#diferente>div>div>div>div>div>div>table>tbody>tr>td>div>div>button.bt-green{
        display: none !important;
    }
    .padd-lef-0{
        padding-left: 0px;
    }
    .padd-rig-0{
        padding-right: 0px;
    }
    .texto-pequeno{
        font-size: 12px;
    }
    .pad-10px{
        text-align: left;
        padding-left: 35px;
    }
    .margi-top-10px{
        margin-top: 10px;
    }
    .margi-top-30px{
        margin-top: 30px;
    }
    .botaoTelaCNH{
        height      : 50%;
        margin-top  : 30px;
    }
</style>