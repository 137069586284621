<template>
    <div>
        <div :id="`popupalertas${alertas.alcodigo}`">
                <div class='alerta-descricao' v-if="alertas.tadescricao">
                    <h6><strong>{{alertas.tadescricao}}</strong></h6>
                </div>
                <div  v-if="alertas.alinfoalerta">
                    <strong>Detalhes: </strong>{{alertas.alinfoalerta}}
                </div>
                <div  v-if="alertas.endereco">
                    <strong>Endereço: </strong>{{alertas.endereco}}
                </div>
                <panelEagle
                    :id="'id-modal-' + alertas.alcodigo"
                    :loading="loadingPanelModal"
                >
                </panelEagle>
                <div class='info-velocidade-botoes' >
                    <div>
                        <strong>Velocidade: </strong>{{ alertas.alvelocidade ? alertas.alvelocidade + 'km/h' : '' }}
                    </div>
                    <div v-if="alertas.alstatus !== 'L'">
                        <div class='botoesAlertas' v-show="!mostrarCaixaDeTexto">
                            <b-button :id="`botaoTratativa-${alertas.alcodigo}`" squared class='mr-2 botao-amarelo' size='sm' @click="mostrarTratativa">
                                <base-icon :size='16' :icon='iconTratarAlerta' />
                            </b-button>
                            <b-button :id="`botaoLer-${alertas.alcodigo}`" squared class='mr-2 botao-verde' size='sm' @click="clickLerAlerta">
                                <base-icon :size='16' :icon='iconLerAlerta' />
                            </b-button>
                        </div>
                    </div>
                </div>
                <div v-if="alertas.alstatus !== 'L'">
                <div v-show="mostrarCaixaDeTexto" class="tratativa-container">
                    <label :for="`tratativa-${alertas.alcodigo}`"><strong>Tratativa:</strong></label>
                    <textarea
                        :id="`tratativa-${alertas.alcodigo}`"
                        rows="7"
                        class="index-gg"
                        ref="refTextArea"
                        v-model="textoTratativa"
                    >
                    </textarea>
                    <simpleButton
                        class="botoesTratativas"
                        event="click" 
                        :icon="iconLerAlerta"
                        @click="salvarTratativa"
                        width="50x"
                        text="Salvar" 
                        type="blue"
                        :disabled="loadingPanelModal">
                    </simpleButton>      
                    <simpleButton
                        class="botoesTratativas"
                        event="click" 
                        @click="cancelarTratativa"
                        width="60px"
                        text="X Cancelar" 
                        type="red"
                        :disabled="loadingPanelModal">
                    </simpleButton>   
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import BaseIcon from '@/components/Atom/Icon/BaseIcon.vue'
import simpleButton from '@/components/Atom/Buttons/SimpleButton.vue'
import {
    mdiCogs,
    mdiCheckBold,

} from '@mdi/js'
import { conectionError } from '@/Services/Helpers/swellHeper';
export default {
    components: {
        BaseIcon,
        simpleButton,
        'panelEagle': require('@/components/Atom/Panel/PanelEagle.vue').default
    },
	props:{
		alertas:{
			required:true
		}
	},
    data() {
        return {
            iconTratarAlerta: mdiCogs,
            iconLerAlerta: mdiCheckBold,
            mostrarCaixaDeTexto: false,
            textoTratativa: '',
            url: '/telemetria/nao/menu/alerta/',
            loadingPanelModal: false,

        }
    },
	methods:{
        /**
		 * @description altera o estado da mostrarCaixaDeTexto para exibir o campo de tratativa
		 * @author Otávio 🦆 
		*/
        mostrarTratativa() {
            this.mostrarCaixaDeTexto = true;
        },

        /**
		 * @description Dispara um Toast de acordo com a mensagem que será passada por parametro
		 * @author Otávio 🦆 
		*/
        toastShow(msg, type, title = ''){
			this.$bvToast.toast(`${msg}`, {
				autoHideDelay: 2500,
				variant: type,
				title: title
			})
        },

        /**
		 * @description Dispara uma mensagem de erro para o Toast avisando para o usuário que a requisição deu erro
		 * @author Otávio 🦆 
		*/
        requestError() {
            this.toastShow('Não foi possível concluir a operação!', 'danger', 'Erro')
        },

        /**
		 * @description Dispara uma mensagem de sucesso para o Toast avisando para o usuário que a requisição deu erro
		 * @author Otávio 🦆 
		*/
        requestSuccess(msg = '') {
            if(!msg) msg = 'Operação concluída com sucesso!'
            this.toastShow(msg, 'success', 'Sucesso')
        },

        /**
		 * @description altera o status da mostrarCaixaDeTexto para não exibir o campo de tratativa
		 * @author Otávio 🦆 
		*/
        cancelarTratativa() {
            this.mostrarCaixaDeTexto = false;
        },
        
        /**
		 * @description realiza uma requisição para alterar o status do alerta para L(lido) 
		 * @author Otávio 🦆 
		*/
        async clickLerAlerta(){
			this.loadingPanelModal = true;
			let url = '/telemetria/nao/menu/alerta/ler/uma/tratativa';
            let params = { 'dados': this.alertas.alcodigo }
            await new HttpRequest().Post(url, params)
                .then(async ({ data, status, code }) => {
                    if (status) {
                        this.$emit('acaoConcluida', this.alertas.alcodigo);
                        this.requestSuccess('Alerta lido com sucesso!')
                        this.$emit('alerta-lido-rota', true);
                    } else {
                        this.requestError()
                    }
                })
                .catch(() => {
                    this.requestError()
                })
                .finally(() => {
                    this.loadingPanelModal = false
                })
		},

        /**
		 * @description Faz a requisição com o texto do campo das tratativas para salvar uma tratativa
         * e dispara uma mensagem para o usuário indicando se a requisição teve sucesso ou não
		 * @author Otávio 🦆 
		*/
        async salvarTratativa() {
            let url = '/telemetria/nao/menu/alerta/salvar/uma/tratativa';
            this.loadingPanelModal = true;
            let params = {
                'dados': {
                    ...this.alertas,
                    descricao: this.textoTratativa,
                    alterarStatus: true
                }
            }; await new HttpRequest().Post(url, params)
                .then(async ({ data, status, code }) => {
                    if (status) {
                        this.textoTratativa = ''
                        this.requestSuccess('Tratativa salva com sucesso!')
                    } else {
                        conectionError()
                    }
                })
                .catch(e => {
                    conectionError()
                })
                .finally(() => {
                    this.mostrarCaixaDeTexto = false;
                    this.loadingPanelModal = false;
                })
        },

    },
}
</script>

<style lang="scss">
.botao-verde {
    background-color: #53F543 !important;
    color: white;
    border: none !important;
    .base-icon {
        color: white;
    }
}
.index-gg {
        width: 50%;
        max-height: 50px;
        border-color: #428bca;
    }
.botao-amarelo {
    background-color: #D8EE37 !important;
    color: white;
    border: none !important;
    .base-icon {
        color: white;
    }
}
.tratativa-container {
    margin-top: 10px;
    label {
        display: block; 
    }
}
.botoesTratativas {
    font-size: 10px !important;
    padding: 1px !important;
    margin-top: -15px !important;
    font-weight: bold !important;
}

.info-velocidade-botoes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;
    .botoesAlertas {
        display: flex;
    }
}

[id^='popupalertas'] {
    min-height: 100px;
    min-width: 330px;
    height: auto; 
    width: auto;
    display: flex;
    flex-direction: column; 
    .alerta-descricao{
        color: red;
    }
}
</style>