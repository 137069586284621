<template>
	<panelEagle id="listagemRegioes" :loading="carregandoRegioes">
		<div class="col-sm-12 row divDontPrint">
			<div class="col-sm-6 nopadding">
				<titulo titulo="Regiões" :icon="mdiEarth" />
			</div>
			<div class="col-sm-6 nopadding">
				<botao @novo="novaRegiao" />
			</div>
		</div>
		<div class="col-sm-12">
			<hr />
		</div>
		<div class="col-sm-12 row nopadding">
			<div class="col-sm-4 nopadding frente">
				<selectAll
					nameForRadio="seletorEmpresa"
					:hasSelectAll="true"
					:isMultiple="true"
					:selected="cliente"
					:labels="[{ description: 'Empresas' }]"
					:optionsArray="optSelectEmpresa"
					@changeSelect="mudaEmpresa"
				/>
			</div>
		</div>
		<div class="col-sm-12">
			<hr />
		</div>
		<div class="col-sm-12">
			<div class="col-sm-12 nopadding traz">
				<mapa height="700" id="listagemRegMapa" ref="map">
					<markers
						id="matriz"
						icon="img/matriz.png"
						:latitude="local.lat"
						:longitude="local.long"
					/>
					<poligono
						v-for="(poli, index) in regioes"
						:key="index"
						:poligono="poli"
					>
						<popUpRegiao
							:regiao="poli"
							@clickExcluir="excluirRegiao"
							@clickEditar="editarRegiao"
						>
						</popUpRegiao>
					</poligono>
				</mapa>
				<!-- <b-overlay :show='carregandoRegioes'>
        </b-overlay> -->
			</div>
		</div>
	</panelEagle>
</template>

<script>
import Vue from "vue";
import { mdiEarth } from "@mdi/js";
import { EmpresasService } from "@/Services/auth/Empresas.service";
import { HttpRequest } from "@/Services/auth/HttpRequest.Service";
import { conectionError } from "@/Services/Helpers/swellHeper";
import { mapGetters } from "vuex";

export default Vue.extend({
	name: "ListagemRegioes",
	components: {
		botao: require("@/components/Atom/Buttons/BasicButtonsIN").default,
		mapa: require("@/components/Atom/Mapa/MapaSimples").default,
		popUpRegiao: require("@/components/Atom/Popup/PopupRegiao").default,
		poligono: require("@/components/Atom/Mapa/Poligonos").default,
		panelEagle: require("@/components/Atom/Panel/PanelEagle.vue").default,
		titulo: require("@/components/Atom/Header/Titulo").default,
		selectAll: require("@/components/Atom/Select/SelectAll").default,
		markers: require("@/components/Atom/Mapa/MarkerPoint").default,
	},

	data() {
		return {
			mdiEarth: mdiEarth,
			arrayInformacoesRastro: "",
			desenhando: true,
			optSelectEmpresa: [],
			empresaSelecionada: [],
			rota: "/telemetria/cadastros/regioes/",
			regioes: [],
			cliente: [],
			carregandoRegioes: false,
			local: {
				lat: 0,
				long: 0,
			},
		};
	},

	methods: {
		novaRegiao() {
			this.$router.push({ name: "cadastroRegioes" });
		},
		editarRegiao(codigo) {
			this.$router.push({ name: "cadastroRegioes", params: { id: codigo } });
		},
		excluirRegiao(codigo) {
			new HttpRequest()
				.Post(this.rota + "deletar", { codigo: codigo })
				.then((data) => {
					if (data.data.status) {
						this.buscaRegioes();
					} else {
						conectionError();
					}
				});
		},
		mudaEmpresa(array) {
			this.regioes = [];
			this.empresaSelecionada = array;
			if (array.length > 0) {
				this.centralizaCliente(array[0]);
				this.buscaRegioes();
			} else {
				this.carregandoRegioes = false;
			}
		},
		buscaRegioes() {
			this.regioes = [];
			this.carregandoRegioes = true;
			var funcao = "listar/regioes";
			var obj = { cliente: this.empresaSelecionada };
			new HttpRequest().Post(this.rota + funcao, obj).then((data) => {
				this.arrumaRegioes(data.data.regioes);
				this.carregandoRegioes = false;
			});
		},
		arrumaRegioes(regs) {
			var a = [];
			a = regs.map(
				(e) =>
					new Object({
						latlngs: e.regioes_coordenadas.map((f) => [
							f.rclatitude,
							f.rclongitude,
						]),
						color: e.recor,
						descricao: e.redescricao,
						codigo: e.recodigo,
						velocidade: e.revelocidade,
						risco: e.rerisco,
					})
			);
			this.regioes = a;
		},
		centralizaCliente(cliente) {
			var cam = this.rota + "localCliente";
			new HttpRequest().Post(cam, { cliente: cliente }).then((data) => {
				if (
					data.status &&
					data.code == 200 &&
					data.data?.centro?.cllatitude != null
				) {
					this.local.lat = data.data.centro.cllatitude;
					this.local.long = data.data.centro.cllongitude;
					this.$refs.map.flyTo([
						data.data.centro.cllatitude,
						data.data.centro.cllongitude,
					]);
				}
			});
		},

		...mapGetters(["getMaster"]),
	},

	mounted() {
		this.optSelectEmpresa = new EmpresasService().Get();
		if (!this.getMaster()) {
			this.cliente = this.optSelectEmpresa;
		}
		this.centralizaCliente(this.$store.getters.getUser.idUser);
	},
});
</script>

<style lang="scss" scoped>
.frente {
	position: relative;
	z-index: 1;
}

.traz {
	position: relative;
	z-index: 0;
}
</style>
