import { ChartDataset } from 'chart.js'

import { EventCodes, EVENT_LABELS } from '@/Services/vehicle/telemetry.service'
import { createEventWithIcon } from './icons'

/**
 * Tipos de exibição de dados que podem ser adicionados ao gráfico.
 * 
 * IMPORTANTE:
 * - Sempre referenciar a este tipo para evitar erros de tipagem, pois algumas propriedades variam
 * - Caso outros tipos de gráficos sejam adicionados, é necessário adicionar aqui
 * - Também é necessário adicionar as dependências relacionadas no componente TelemetryChart.vue
 */
export type AllowedChartDataset = ChartDataset<'line'|'scatter'>

/**
 * Cada evento possui um objeto de configuração específica para o gráfico.
 * As chaves são os códigos dos eventos definidos em EventCodes.
 */
export type ChartEventsConfig = Partial<Record<EventCodes, Partial<AllowedChartDataset>>>

/**
 * Cores padrão para os eventos.
 * Basta adicionar mais cores aqui para que sejam utilizadas nos eventos.
 */
export const eventColors = {
    [EventCodes.Speed]: '#007BFF',
    [EventCodes.RPM]: '#DC3545'
}

/**
 * Esta configuração será aplicada em eventos que não possuam uma configuração específica.
 */
const defaultEvent: Partial<AllowedChartDataset> = {
    type: 'scatter',
    yAxisID: 'y'
}

/**
 * Eventos que possuem configurações específicas.
 */
const customEvents: ChartEventsConfig = {
    [EventCodes.Speed]: {
        type: 'line',
        yAxisID: 'y1',
        order: 99, // Faz com que o evento seja renderizado atrás dos outros
        
    },
    [EventCodes.RPM]: {
        type: 'line',
        yAxisID: 'y2',
        order: 98, // Na frente da velocidade, atrás dos outros
    },
}

/**
 * Cria o objeto de configurações para todos os eventos.
 */
export const createEventsConfig = (): ChartEventsConfig => {
    const eventsConfig: ChartEventsConfig = {};

    Object.keys(EventCodes)
        .filter(key => !isNaN(Number(key)))
        .forEach(key => {
            eventsConfig[key] = createEventWithIcon(Number(key) as EventCodes, customEvents[key] ?? defaultEvent)
            eventsConfig[key]['label'] = EVENT_LABELS[key] ?? `sem label (${key.toString()})`
            eventsConfig[key]['backgroundColor'] = eventColors[key] ?? undefined
            eventsConfig[key]['borderColor'] = eventColors[key] ?? undefined
        })

    return eventsConfig
}
