/**
    Este componente é o titulo da página

    -> Propriedades:
        1- icon - String - Icone da página - EX: 'mdiRobotDead'
        2- titulo - String - Titulo da página - EX: 'Veículos'

    -> Slot:
        Sem slot

    -> Eventos:
        Sem eventos
 */
<template>
    <div class="col-sm-12 divTitulo nopadding">
        <baseIcon size='30' class="iconTitulo" :icon="icon"/> {{titulo}}
    </div>    
</template>

<script lang="ts">
import Vue from 'vue'
import { mdiRobotDead } from '@mdi/js'; 
export default Vue.extend({
    name:'titulo',
    components:{
        baseIcon:require('@/components/Atom/Icon/BaseIcon.vue').default,
    },
    props: {
        icon: {
            type: String,
            default: mdiRobotDead,
            required: true,
        },
        titulo: {
            type: String,
            required: true,
            default: 'ERRROOOU'
        }
    }
})
</script>

<style lang="scss">
    .divTitulo{
        font-size: 20px;
        text-align: left;

        .iconTitulo{
            &::before{
                font-size: 22px;
                margin-left: 0px;
            }
        }
    }
</style>