/**
    Este componente é um input de texto com mascara de dinheiro
    -> Propriedades:
        1- label - String - Descricao do input/Legenda que fica em cima - EX: 'Logo'
        2- name - String - Name do input - EX: 'inputArchive'
        3- value - String - Cor padrão ja selecionada - EX: '0,00'
        4- inputClass - String- Classe extra para o input - EX: 'inputArchive'
        5- inputDisabled - String - se ele esta desabilitado - EX: 'disabled'/''
        6- id - String - Id do input - EX: 'inputArchive'
    -> Slot:
        Sem slot
    -> Eventos:
        1- changeInput - Emite quando o input é alterado - Parametros:
            1.1- inputValue - o texto que esta no input
 */
<template>
    <div class="col-sm-12" id="inputMoney">
        <div class="col-sm-12 subtitle nopadding">
            {{label}}
        </div>
        <money v-if="inputDisabled == 'disabled'" disabled  class="form-control" v-bind="money" :class="inputClass" v-model="inputValue" :name="name" @keyup.native="keyup" :id="id"></money>
        <money v-else type="text" class="form-control" v-bind='{...$attrs, ...money}' v-on='$listeners' @blur.native="emiteBlur" :class="inputClass" v-model="inputValue" :name="name" @keyup.native="keyup" :id="id"></money>
    </div>
</template>
<script lang="ts">
import Vue from 'vue'
import {Money} from "v-money"
export default Vue.extend({
    name:'inputMoney',
    components:{
        Money
    },
    inheritAttrs: false,
    props:{
        label: {
            type: String,
            required: false,
            default: '',
        },
        name: {
            type: String,
            required: true,
        },
        value: {
            type:  [String, Number],
            required:false,
            default: '',
        },
        inputClass: {
            type: [String, Object],
            required: false,
            default: '',
        },
        inputDisabled: {
            type: String,
            required: false,
            default: ''
        },
        id:{
            type: String,
            required: false,
            default: ''
        }
    },
    data(){
        return{
            inputValue:'' as any,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: false
            }
        }
    },
    methods:{
        emiteBlur(){
            this.$emit('blur')
        },
        keyup(event:any){
            this.$emit('changeInput', this.inputValue);
        },
        
        clearAll(){
            this.inputValue = ''
        },
    },
    mounted(){
        this.inputValue = this.value;
        this.$emit('changeInput', this.inputValue);
    },
    watch:{
        value:function(){
            this.inputValue = Number(this.value);
        }
    }
})
</script>
<style lang="scss">
#inputMoney{
    .subtitle{
        font-size: 13px;
        text-align: left;
        margin-bottom: 1px;
    }
}
</style>