<template>
    <modal-eagle id='historicoPosicoesRegiao' :noCloseOnEsc='true' :hardToClose='true' :noCloseOnBackdrop='true'>
        <template #modal-header>
            <h4>Histórico de posições por região</h4>
        </template>
        <template #modalBody>
            <b-overlay :show='gerando'>
                <div class='d-flex justify-content-around'>
                    <div class='col-4 nopadding'>
                        <input-single-day name='dataHistPosicao' :disabled='gerando' @changeInput='mudaDataInicial' />
                    </div>
                    <div class='col-3 flex-column'>
                        <label for='horaInicio'>Horário inicial</label>
                        <input v-model='modelHoraIncio' type='time' :disabled='gerando' step='3' class='form-control'
                            id='horaInicio'>
                    </div>
                    <div class='col-3'>
                        <label for='horaFinal'>Horário Final</label>
                        <input v-model='modelHoraFinal' type='time' :disabled='gerando' step='3' class='form-control'
                            id='horaFinal'>
                    </div>
                    <div class='col-2 mt-4 pt-1'>
                        <loading-button :isLoading='gerando' :icon='iconGerar' event="click" type='green' text=' Gerar'
                            @click='procuraHistoricoPosicao' />
                    </div>
                </div>
                <hr style="width:97%">
                <div class='mt-2'>
                    <div class="d-flex flex-row-reverse pb-2">
                        <div class='col-4'>
                            <input placeholder='Buscar' class='form-control' v-model='filtroUser'>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th class="largura-tr-td" style="width: 20px;"></th>
                                <th class="largura-tr-td">Veículo</th>
                                <th class="largura-tr-td">Primeiro evento</th>
                                <th class="largura-tr-td">Último evento</th>
                                <th>Paradas</th>
                            </tr>
                        </thead>
                        <tbody v-if='dataHistPosicao.length'>
                            <tr v-for="(pos, index) in dataHistPosicaoShow" :key="index">
                                <td style="text-align: right; width: 20px;">
                                    <span class="botao" @click="abreInfoVeiculo(pos)">
                                        <baseIcon size="18" class="cursor-pointer" :icon="mdiCarInfo" />
                                    </span>
                                </td>
                                <td class="largura-tr-td" v-text='pos.veiculo'></td>
                                <td class="largura-tr-td" v-text='pos.primeiroEvento'></td>
                                <td class="largura-tr-td" v-text='pos.ultimoEvento'></td>
                                <td :class='pos.classe' v-text='pos.paradas'></td>
                            </tr>
                        </tbody>

                        <tbody v-else>
                            <status-information :statusBar='statusStatusInformation'
                                :msgCentro='msgStatusInformation' />
                        </tbody>
                    </table>
                </div>
            </b-overlay>
        </template>
        <template #modalFooter>
            <simple-button :icon='iconClose' text='Fechar' type='red' event='click' @click='fechaModal' />
        </template>
    </modal-eagle>
</template>
<script>
import ModalEagle from '@/components/Atom/Modal/ModalEagle.vue'
import InputSingleDay from '@/components/Atom/Datas/InputSingleDay.vue'
import SimpleButton from '@/components/Atom/Buttons/SimpleButton.vue'
import loadingButton from '@/components/Atom/Buttons/LoadingButton.vue'
import {mdiThumbUp, mdiCloseThick, mdiCarInfo} from '@mdi/js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import StatusInformation from '../StatusInformation/StatusInformation.vue'
import Swal from 'sweetalert2'
export default {
	components:{
        baseIcon:require('@/components/Atom/Icon/BaseIcon.vue').default,
		ModalEagle,
		InputSingleDay,
		loadingButton,
		SimpleButton,
		StatusInformation
	},
	props:{
		clienteSelecionado:{
			type: Array,
			required:true
		}
	},
	data() {
		return {
			uriBase        : '/finder/mapa/',
			iconGerar      : mdiThumbUp,
			iconClose      : mdiCloseThick,
            mdiCarInfo     : mdiCarInfo,
			gerando        : false,
			modelHoraIncio : '00:00:00',
			modelHoraFinal : '23:59:59',
			modelData      : '',
			desenho        : [],
			dataHistPosicao: [],
			msgStatusInformation   :'Clique em gerar para obter as informações',
			statusStatusInformation: 'info',
            filtroUser:'',
		}
	},
	methods:{
        /**
         * @param {object} dadoPosicao
         * @description prepara os dados e monta o objeto para enviar para 
         * o info veiculo
         * @author Otávio 🦆
         */
        abreInfoVeiculo(dadoPosicao) {
            let primeiroEventoDate = new Date("2024-06-14T" + dadoPosicao.primeiroEvento);
            let ultimoEventoDate = new Date("2024-06-14T" + dadoPosicao.ultimoEvento);
            let placaEPrefixo = dadoPosicao.veiculo.split(' | ');

            if (dadoPosicao.modulo && !dadoPosicao.inativo) {
                const obj = {
                    data: this.modelData,
                    horaIni: primeiroEventoDate.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' }),
                    horaFim: ultimoEventoDate.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' }),
                    veiculo: dadoPosicao.vecodigo,
                    placa: placaEPrefixo[0],
                    prefixo: placaEPrefixo[1]
                };
                this.$emit('abre-info-veiculo', obj);
                this.filtroUser = '';
                this.fechaModal();
            } else {
                let message = 'Associe um módulo a esse veículo para ver as informações de rastro e paradas deste evento';
                let title = 'Veículo não possui módulo associado!';
                let icon = 'warning';

                if (dadoPosicao.inativo) {
                    message = 'Ative o veículo ' + (dadoPosicao.modulo ? '' : 'e associe um módulo') + ' para ver as informações de rastro e paradas.';
                    title = 'Veículo Inativo' + (dadoPosicao.modulo ? '' : ' e sem Módulo!');
                    icon = 'warning';
                }

                Swal.fire({
                    title: title,
                    text: message,
                    icon: icon,
                    confirmButtonText: 'Ok'
                });
            }
        },

		fechaModal(){
			this.dataHistPosicao = []
			this.modelHoraIncio = '00:00:00'
			this.modelHoraFinal = '23:59:59'
			this.$emit('fecha-hist-pos-regiao')
			this.$bvModal.hide('historicoPosicoesRegiao')
		},

		/**
		 * @listens click - botão de gerar o relátorio.
		 * @description faz a requisição para o back-end trazendo
		 * as informações sobre os veículos, e já faz o tratamento
		 * para a status-information mostrando a iformação correta.
		 * @author Gui 🍺
		 */
		procuraHistoricoPosicao(){
			this.dataHistPosicao = []
			var obj = {
				data:this.modelData,
				inicio:this.modelHoraIncio,
				final:this.modelHoraFinal,
				coordenadasArray: this.desenho,
				cliente:this.clienteSelecionado.map(e=>e.value),
			}
			// if(!this.clienteSelecionado.length) delete obj.cliente
			var uri = `${this.uriBase}historico/regiao`
			this.gerando = true
			new HttpRequest().Post(uri, obj)
				.then((data)=>{
					if(data.code === 200)
						this.dataHistPosicao = data.data ?? []
					if(this.dataHistPosicao.length)
						this.statusStatusInformation = 'info'
					else 
						this.statusStatusInformation = 'error'
				}).finally(()=>{
					this.gerando = false
				})
		},

		/**
		 * @listens changeInput
		 * @param {string} data  - emitido pelo componente de data.
		 */
        mudaDataInicial(data) {
            this.dataHistPosicao = []
			this.modelData = data
		},

		/**
		 * @param {number[]} arg - desenho da região pra verificar.
		 * @param {number[]} clintes - clientes selecionados no mapa
		 * @description inicia a modal de hist. de posições.
		 */
		showModal(arg, clientes){
			this.dataHistPosicao = []
			this.statusStatusInformation = 'info'
			this.$bvModal.show('historicoPosicoesRegiao')
			this.desenho = arg
			// this.clienteSelecionado = clientes
		},
	},
    computed:{
        dataHistPosicaoShow(){
            let regexp = new RegExp(this.filtroUser, 'gi')
            return this.dataHistPosicao.filter((pos)=>{
                return regexp.test(pos.veiculo)
            })
        }
    },
    watch: {
        modelHoraIncio() {
            this.dataHistPosicao = []
        },

        modelHoraFinal() {
            this.dataHistPosicao = []
        },
    },
}
</script>

<style lang='scss' scoped>
#historicoPosicoesRegiao{
    position: absolute;
	z-index: 300;
	top: 0.3%;
	left: 0.3%;
    label{
        margin-bottom: 0 !important;
    }
    input{
        padding-left: 5px !important;
    }
    table{
        width: 100%;
        tbody{
            max-height: 250px;
            overflow: auto;
            display: block;
            td{
                text-align: left;
                padding-top: 3px;
                padding-bottom: 3px;
                padding-left: 7px;
                font-size: 12px;
            }
        }
        .largura-tr-td{
            width: 200px;
        }
        th{
            padding-top: 5px;
            padding-left: 2px !important;
            background-color: #f5f5f5;
            border-bottom: 2px solid #dddddd;
        }
        tr{
            width: 100%;
            display: table;
            border-bottom: 1px solid #dddddd;
            .redTr{
                color: red;
            }
            .greenTr{
                color: #71c16c;
            }
        }
    }
    table thead, table tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }
}
</style>