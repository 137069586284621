<template>
    <panelEagle id='Feriados' :loading="loadingPanel">
        <div class="col-sm-12 nopadding row ">
            <div class="col-sm-12 col-md-6 ">
                <tituloPage 
                    :icon='mdiCalendarMonth' 
                    titulo='Feriados'>
                </tituloPage>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6 divDontPrint">
                <basicButtonsCadastrosEIN
                    linkNew ='cadastrarFeriadosCheckpoint'
                    @exportarCadastro="exportarCadastro"
                    :loading ='loading'
                    tipoNovo='button'
                    :disabledDropdown="$v.dadosTabela.$invalid">
                </basicButtonsCadastrosEIN>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class='col-sm-12 row nopadding'>
            <div class="col-lg-4 col-md-12 divDontPrint nopadding mb-3">
                <selectAll
                    nameForRadio  ='Selecione*'
                    :isMultiple   ='true'
                    :selected ="selectedEmpresa"
                    :optionsArray ='optEmpresas'
                    :labels       ='labelsEmp'
                    :hasSelectAll ='true'
                    @changeSelect ='changeEmpresa'/>
            </div>
            <div class="col-lg-8 col-md-12 divDontPrint nopadding mb-3">
                <buttonsFilters
                    :label='labelBot'
                    :arrayButtons='botoes'
                    @buttonsFiltersChange='buttonsFiltersChange'/>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <tableListagem
            :data ='dadosTabela'
            :titles ='titles' 
            deleteEdit ='editDelete'
            :loading ='loadingTabela'
            @excluirCadastro='excluirCadastro'
            @editarCadastro='editarCadastro'>
            <template #acoes='{row}'>
                <basicButtonsListagemED
                    v-if="row.habilitado"
                    :id="row.codigo"
                    @editarCadastro="editarCadastro" 
                    @excluirCadastro="excluirCadastro">
                </basicButtonsListagemED>
                <div v-else>
                    <!-- pra n ir para o slot padrao -->
                </div>
            </template>
        </tableListagem>
    </panelEagle>
</template>
<script>
    import Vue  from 'vue'
    import { mapGetters } from 'vuex'
    import { mdiCalendarMonth, mdiCheckBold } from '@mdi/js'
    import { EmpresasService } from '@/Services/auth/Empresas.service'
    import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
    import { required } from 'vuelidate/lib/validators'
    var mdiCheckBolds = mdiCheckBold
    export default Vue.extend({
        name:'listagemFeriadosCheckpoint',
        components:{
            'basicButtonsListagemED'   : require('@/components/Atom/Buttons/BasicButtonsListagemED').default,
            'panelEagle'               : require('@/components/Atom/Panel/PanelEagle').default,
            'basicButtonsCadastrosEIN' : require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
            'tituloPage'               : require('@/components/Atom/Header/Titulo').default,
            'selectAll'                : require('@/components/Atom/Select/SelectAll').default,
            'buttonsFilters'           : require('@/components/Atom/Buttons/ButtonsFilters').default,
            'tableListagem'            : require('@/components/Atom/Table/TableListagem').default,

        },

        validations:{
            dadosTabela:{required},
        },

        data(){
            return {
                loadingPanel:false,
                url:'/checkpoint/cadastros/feriados/',
                mdiCalendarMonth:mdiCalendarMonth,
                loadingTabela : false,
                loading :[false, false, false],
                optEmpresas : new EmpresasService().Get(),
                selectedEmpresa:[],
                labelsEmp   :[{
                    indexDFH    : 'SE',
                    description : 'Empresas'
                }],
                valorEmpresas   : [],
                titles :[
                    {'number':'data'      , 'name':'Data'},
                    {'number':'descricao' , 'name':'Descrição'},
                    {'number':'tipo'      , 'name':'Tipo'},
                    {'number':'empresa'   , 'name':'Empresa'},
                ],
                valueBotao  : '',
                labelBot : 'Tipo',
                valueEmpresas :[],
                dadosTabela : [],
                usuraio     : '',
                botoes      : [
                    {
                        'value': 'b1',
                        'width': '32.5%',
                        'isSelected': true,
                        'title': 'Todos os feriados',
                        'text': 'Todos',
                        'extraClass':'lado',
                        'icon': mdiCheckBolds
                    },
                    {
                        'value': 'N',
                        'width': '32.5%',
                        'isSelected': false,
                        'title': 'Feriado nacional',
                        'text': 'Nacional',
                        'icon': ''
                    },
                    {
                        'value': 'R',
                        'width': '32.5%',
                        'isSelected': false,
                        'title': 'Feriado regional',
                        'text': 'Regional',
                        'icon': ''
                    },
                ]
                
            }
        },

        methods:{
            ...mapGetters(['getMaster']),
            async changeEmpresa(value){
                this.loadingPanel = true
                this.valueEmpresas = value
                var a = this.valueBotao
                var user = this.$store.getters.getMaster
                if(this.valueEmpresas.length == 0){
                    this.dadosTabela = []
                }else{
                    this.dadosTabela = []
                    var dados = await new HttpRequest()
                        .Post(this.url+'tabela', {'dados':value, 'ver':a, 'usuario':user, })
                    this.dadosTabela = dados.data.retorno
                }
                this.loadingPanel = false
            },
            async exportarCadastro(value){
                var emp = this.valueEmpresas
                var a = this.valueBotao
                var user = this.$store.getters.getMaster
                var root =  process.env.VUE_APP_ROOT;
                if(emp[0] != null){
                    switch(value){
                        case 'pdf':
                            this.loading = [true, false, false]
                            break;
                        case 'xls':
                            this.loading = [false, true, false]
                            break;
                        case 'csv':
                            this.loading = [false, false, true]
                            break;
                        default:
                            break;
                    }
                    var dados = await new HttpRequest()
                        .Post(this.url+'exporta', {'dados':this.dadosTabela, 'tipo':value})
                    window.open(root+'/'+dados.data)
                    this.loading = [false, false, false]
                }
            },
            async excluirCadastro(value){
                var dados = await new HttpRequest()
                    .Post(this.url+'deletar', {'valor':value})
                this.changeEmpresa(this.valueEmpresas)
            },
            editarCadastro(value){
                this.$router.push({name:'cadastrarFeriadosCheckpoint', params:{id:value}})
            },
            buttonsFiltersChange(value){
                this.valueBotao = value
                if(value == "b1") {
                    this.botoes[0].icon = mdiCheckBolds
                    this.botoes[1].icon = ' '
                    this.botoes[2].icon = ' '
                }
                else if(value == "N"){
                    this.botoes[0].icon = ' '
                    this.botoes[1].icon = mdiCheckBolds
                    this.botoes[2].icon = ' '
                }
                else if(value == "R"){
                    this.botoes[0].icon = ' '
                    this.botoes[1].icon = ' '
                    this.botoes[2].icon = mdiCheckBolds
                }
                if(this.valueEmpresas.length != 0){
                    var d = this.changeEmpresa(this.valueEmpresas)
                }             
            }
           
        },
        async mounted(){ 
            this.usuraio = this.$store.getters.getMaster
            this.dadosTabela = []
            if(!this.getMaster()){
                this.selectedEmpresa = this.optEmpresas
            }
        }
    })


</script>
<style lang="scss">
    .titulo{
        padding: 0px;
        margin-bottom: 0px;
        margin-left: 16px;
        text-align: left;
    }
    
</style>
