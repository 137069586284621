<template>
    <panelEagle id="cadGrupoMotoristaView" :loading="loadingPanel">
        <div class="col-sm-12 nopadding ">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiAccountGroup" titulo="Grupo de colaboradores"/>
                </div>
                <div class="col-sm-6 divDontPrint">
                    <basicButtonsCadastrosEIN
                        :disabledDropdown="!Object.keys(listagemGrupoColaboradores).length"
                        :loading="loadingExporta"
                        @exportarCadastro="exportarGrupoColaboradores" 
                        linkNew="cadastrarGrupoMotorista" 
                        tipoNovo='button'>
                    </basicButtonsCadastrosEIN>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 nopadding row">
                <div class="col-lg-4 col-md-6 col-sm-12 nopadding divDontPrint">
                    <selectAll 
                        @changeSelect="changeEmpresa" 
                        :isMultiple="true" 
                        nameForRadio="empresasCadGrupoColaboradores" 
                        :hasSelectAll="true" 
                        :labels="[{indexDFH: 'EM', description: 'Empresas'}]" 
                        :optionsArray="optionsArrayEmpresas"
                        :selected="selectedColaboradores"
                        firstSelected="EM">
                    </selectAll>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 nopadding divDontPrint">
                    <buttonsFilters 
                        @buttonsFiltersChange="clickBotao" 
                        :arrayButtons="arrayButtons" 
                        label="Status" 
                        :onlyOneSelected="true">
                    </buttonsFilters>
                </div>
            </div>
        </div>
        <div class="col-sm-12 divHrDeBaixo"><hr></div>
        <tableListagem
            :loading="loadingTable"
            @alterarStatus="alteraStatus" 
            @editarCadastro="editarGrupoColaboradores" 
            deleteEdit="editStatus" 
            :data="listagemGrupoColaboradores" 
            :titles="titles">
        </tableListagem>
    </panelEagle>
</template>

<script lang="ts">
import Vue from 'vue'
import {EmpresasService} from '@/Services/auth/Empresas.service'
import {HttpRequest} from '@/Services/auth/HttpRequest.Service'
import {mdiAccountGroup, mdiCheckBold } from '@mdi/js'
import { mapGetters } from 'vuex'
export default Vue.extend({
    name:'ListagemGrupoColaboradores',
    components:{
        'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage': require('@/components/Atom/Header/Titulo').default,
        'selectAll': require('@/components/Atom/Select/SelectAll').default,
        'tableListagem': require('@/components/Atom/Table/TableListagem').default,
        'buttonsFilters' : require('@/components/Atom/Buttons/ButtonsFilters').default,
        'basicButtonsCadastrosEIN': require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
    },
    data(){
        return {
            mdiAccountGroup:mdiAccountGroup,
            mdiCheckBold   : mdiCheckBold,
            loadingPanel:false,
            loadingExporta:[false, false, false],
            loadingTable:false,
            empresasSelecionadas: [] as any,
            selectedColaboradores:[],
            optionsArrayEmpresas : [],
            titles:[
                {'name': 'Descrição', 'number': 'descricao'},
                {'name': 'Empresa', 'number': 'empresa'},
            ],
            arrayButtons:[
                {'value':'A', 'width': '32%', 'text': 'Ativo',   'isSelected':true,  'icon':''},
                {'value':'I', 'width': '32%', 'text': 'Inativo', 'isSelected':false, 'icon':''},
                {'value':'T', 'width': '32%', 'text': 'Todos',   'isSelected':false, 'icon':''},
            ],
            listagemGrupoColaboradores: [] as any,
            returnStatus: '',
            returnClass: '',
            status: 'T',
        }
    },

    methods:{
        changeEmpresa(selecionados:any){
            this.empresasSelecionadas = selecionados;
            this.listagem();
        },

        clickBotao(event:any){
            this.status = event;
            this.arrayButtons[0].icon = ''
            this.arrayButtons[1].icon = ''
            this.arrayButtons[2].icon = ''
            if(event[0] == 'A'){
                this.arrayButtons[0].icon = mdiCheckBold
            } else if(event[0] == 'I'){
                this.arrayButtons[1].icon = mdiCheckBold
            } else if(event[0] == 'T'){
                this.arrayButtons[2].icon = mdiCheckBold
            }
            this.listagem();
        },

        async listagem(){
            this.loadingPanel = true
            var data = await new HttpRequest()
                .Post('/telemetria/cadastros/grupos/motoristas/listagem/grupo/colaborador', 
                    {
                        'clientes':this.empresasSelecionadas, 
                        'status':this.status
                    });
            this.listagemGrupoColaboradores = data.data.dados;
            this.loadingPanel = false
        },

        editarGrupoColaboradores(gmcodigo:any){
            this.$router.push({name:'cadastrarGrupoMotorista', params:{'id':gmcodigo}})
        },

        async alteraStatus(grupo:any, status:any){
            var data = await new HttpRequest()
                .Post('/telemetria/cadastros/grupos/motoristas/trocar/status/grupo/colaborador', 
                    {
                        'id':grupo, 
                        'status':status
                    });
            this.listagem();
        },

        async exportarGrupoColaboradores(type:any){
            var root =  process.env.VUE_APP_ROOT;
            if(this.listagemGrupoColaboradores.length > 0){
                switch (type) {
                    case 'pdf':
                        this.loadingExporta = [true, false, false]
                        break;
                    case 'xls':
                        this.loadingExporta = [false, true, false]
                        break;
                    default:
                        this.loadingExporta = [false, false, true]
                        break;
                }
                var data = await new HttpRequest()
                    .Post('/telemetria/cadastros/grupos/motoristas/exportar/grupo/motoristas', 
                        {
                            'arrayDados':JSON.stringify(this.listagemGrupoColaboradores), 
                            'tipo':type
                        }
                    );
                window.open(root+'/'+data.data.local);
                this.loadingExporta = [false, false, false]
            }
        },

        ...mapGetters(['getMaster']),
    },
    mounted(){
        this.optionsArrayEmpresas = new EmpresasService().Get()
        if(!this.getMaster()){
            this.selectedColaboradores = this.optionsArrayEmpresas
        }
    },
})
</script>

<style lang="scss">
    #cadGrupoMotoristaView{
        .result-status{
            text-align: center;
            font-size: 12px;
            padding-top: 5px !important;

            &.status-succes{
                color: #95b65c;
            }
            &.status-error{
                color: #e04b4a;
            }
        }
    }
</style>