<template>
    <div id="alertasVeiculo">
        <b-overlay :show="carregando">
            <div>
                <multiselect
                    :options="optTiposAlertas"
                    v-model="tipoAlertaSelecionado"
                    :showLabels="false"
                    track-by="value"
                    label="description"
                    placeholder="Alertas"
                >
                    <template slot="singleLabel" slot-scope="props">
                        <span class="spanBadge">
                            <el-badge
                                type="danger"
                                :max="99"
                                :hidden="nAlertas(props.option.value) < 1"
                                class="mr-3 badgeAlertas"
                                :value="nAlertas(props.option.value)"
                            >
                                <base-icon
                                    :icon="resolveIcone(props.option.value)"
                                    :size="14"
                                />
                            </el-badge>
                        </span>
                        <span
                            class="ml-3 descricaoOpcao"
                            v-text="props.option.description"
                        />
                    </template>
                    <template slot="option" slot-scope="props">
                        <div class="tipoAlerta">
                            <span class="spanBadge">
                                <el-badge
                                    type="danger"
                                    :max="99"
                                    :hidden="nAlertas(props.option.value) < 1"
                                    class="mr-3 badgeAlertas"
                                    :value="nAlertas(props.option.value)"
                                >
                                    <base-icon
                                        :icon="resolveIcone(props.option.value)"
                                        :size="18"
                                    />
                                </el-badge>
                            </span>
                            <span
                                class="ml-3 descricaoOpcao"
                                v-text="props.option.description"
                            />
                        </div>
                    </template>
                    <template slot="noResult"> Nenhum resultado encontrado </template>
                </multiselect>
            </div>
            <div class="listaDeAlertas">
                <base-alerta-veiculo
                    v-for="alerta in alertasExibir"
                    :key="alerta.alcodigo"
                    :tipoAlerta="decideTituloAlerta(alerta.alalerta)"
                    :dataHora="alerta.aldatahora"
                    :local="alerta.allocalizacao"
                    :descAlerta="alerta.alinfoalerta"
                    :alcodigo="alerta.alcodigo"
                    @recarregar-alertas="removerAlerta"
                    @local-alerta="(v) => $emit('local-alerta', v)"
                />
            </div>
        </b-overlay>
    </div>
</template>

<script>
import baseIcon from "@/components/Atom/Icon/BaseIcon.vue";
import baseAlertaVeiculo from "@/components/Atom/Painel/PainelVeiculo/BaseAlertaVeiculo.vue";
import Multiselect from "vue-multiselect";
import {
    mdiChessPawn,
    mdiBatteryOffOutline,
    mdiCarCruiseControl,
    mdiSteeringOff,
    mdiCarOff,
    mdiSeatbelt,
    mdiCarMultiple,
    mdiCrosshairsQuestion,
    mdiClockAlertOutline,
    mdiCarEmergency,
    mdiMapMarkerCircle,
    mdiVectorSquare,
    mdiAccessPointRemove,
    mdiPackageVariant,
    mdiCarSpeedLimiter,
    mdiSpeedometer,
} from "@mdi/js";
import { HttpRequest } from "@/Services/auth/HttpRequest.Service";
export default {
    name: "painel-alertas-veiculo",
    components: {
        baseAlertaVeiculo,
        Multiselect,
        baseIcon,
    },
    props: {
        codigoVeiculo: {
            type: [Number, String],
            require: true,
        },
        tiposAlertas: {
            type: Array,
            require: true,
        },
        temPerformance: {
            type: Boolean,
        },
    },
    data() {
        return {
            uriBase: "/finder/veiculo/alertas/",
            arrayAlertas: [],
            tipoAlertaSelecionado: [],
            carregando: false,
        };
    },
    methods: {
        /**
         * Retorna o # de certo tipo de alerta
         * @param tipo, bom, qual tipo de alerta vc quer contar
         * @author Gui 🍺🍺
         */
        nAlertas(tipo) {
            var n = 0;
            this.arrayAlertas.forEach((alerta) => {
                if (alerta.alalerta === tipo) n++;
            });
            return n;
        },

        /**
         * Cria o titulo do alerta, que aparece no centro do base-alerta-veiculo
         * @param alerta, obj do alerta em questão
         * @return tiulo do alerta
         * @author Gui 🍺
         */
        decideTituloAlerta(alerta) {
            var titulo = this.tiposAlertas.find((el) => el.value === alerta);
            return `${titulo.description}`;
        },

        /**
         * @param {number} alerta - código do TIPO de alerta
         * @description resolve o ícone para cada tipo de alerta.
         * @return {string} ícone para o alerta
         */
        resolveIcone(alerta) {
            switch (alerta) {
                case 1: //Área de risco
                    return mdiVectorSquare;
                case 2: //Pânico
                    return mdiCarEmergency;
                case 3: //Excesso de velocidade
                    return mdiCarCruiseControl;
                case 4: //Sem comunicação
                    return mdiAccessPointRemove;
                case 5: //Movimento indevido
                    return mdiSteeringOff;
                case 6: //Parada proibida
                    return mdiCarOff;
                case 7: //Aproximação ponto de referência
                    return mdiMapMarkerCircle;
                case 8: // falha de energia externa
                    return mdiBatteryOffOutline;
                case 9: //Batida
                    return mdiCarMultiple;
                case 10: //Colisão
                    return mdiCarMultiple;
                case 12: //Desvio de rota
                    return mdiCrosshairsQuestion;
                case 13: //Excede Jornada
                    return mdiClockAlertOutline;
                case 14: //Atraso de entrega
                    return mdiPackageVariant;
                case 15: //Aceleração brusca
                    return mdiSpeedometer;
                case 16: //freada brusca em curva
                    return mdiSeatbelt;
                case 17: //Curva em alta velocidade
                    return mdiCarSpeedLimiter;
                case 18: //Aceleração brusca em curva
                    return mdiSpeedometer;
                default:
                    return mdiChessPawn;
            }
        },

        /**
         * @description faz a request que traz todos os alertas do veiculo,
         * e trata para que o usuário só veja os alertas que ele tem acesso.
         * @author Gui 🍺
         */
        buscarAlertas() {
            if (!this.codigoVeiculo) return;
            this.$emit("local-alerta");
            this.carregando = true;
            let uri = `${this.uriBase}todos/${this.codigoVeiculo}`;
            new HttpRequest().Get(uri).then((data) => {
                this.arrayAlertas = data.data.alertas.filter((al) => {
                    var eh_perfomance = this.taPerformance.find(
                        (ta) => ta == al.alalerta
                    );
                    if (eh_perfomance !== undefined) return this.temPerformance;
                    return true;
                });
                this.carregando = false;
            });
		},
		removerAlerta(codigo) {
			var index = this.arrayAlertas.findIndex(e=>{
				return e.alcodigo == codigo
			})
			if (index > -1) {
				this.arrayAlertas.splice(index, 1)
			}
		},
    },

    mounted() {
        this.buscarAlertas();
    },

    beforeDestroy() {
        this.$emit("local-alerta");
    },

    computed: {
        /**Filtra os alertas exibidos pelo tipo selecionado do multiselect */
        alertasExibir() {
            if (this.tipoAlertaSelecionado?.value)
                var exibir = this.arrayAlertas.filter((el) => {
                    return el.alalerta === this.tipoAlertaSelecionado.value;
                });
            else return this.arrayAlertas;
            return exibir;
        },

        optTiposAlertas() {
            return this.tiposAlertas
                .filter((ta) => {
                    let rel = this.taPerformance.find((a) => ta.value == a);
                    if (rel !== undefined) return this.temPerformance;
                    return true;
                })
                .sort();
        },

        /**
         * @description array com todos os códigos de tipos de alertas
         * que estão associados ao módulo performance.
         * @return {number[]}
         */
        taPerformance() {
            return [15, 16, 17, 18, 9];
        },
    },
};
</script>

<style lang="scss">
#alertasVeiculo {
    max-height: 100%;
    span.multiselect__single {
        height: 25px;
    }
    .descricaoOpcao {
        margin: 3px;
        vertical-align: -webkit-baseline-middle;
    }
    .badgeAlertas {
        font-size: 10px !important;
        .el-badge__content.is-fixed {
            top: 6px !important;
            right: 6px !important;
        }
    }
    .listaDeAlertas {
        max-height: 220px;
        overflow-y: auto;
    }
    .tipoAlerta {
        border: 1px, solid;
    }
    .spanBadge {
        top: 2px;
        position: inherit;
    }
}
</style>
