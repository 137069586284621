/**
    Este é o componente para a listagem de escalas, onde tem os botões de 
        copiar, editar, excluir, e ja contem a modal de delete
    
    -> Propriedades:
        1- id: String, Number - código do item em questão - EX: 2/'2'
    
    -> Slot:
        1- Sem Nome: espaço para colocar algo a mais

    -> Eventos:
        1- editarCadastro - Emite quando o botão de editar for clicado - Parametros:
            1.1- id - Código do cadastro clicado
        2- excluirCadastro - Emite quando a exclusão do cadastro for confirmada na modal - Parametros:
            2.1- id - Código do cadastro clicado
        3- copiarCadastro - Emite quando o botão de cópia for clicado - Parametros:
            3.1- id - Código do cadastro clicado
 */

<template>
    <div>
        <slot></slot>
        <simpleButton 
            @visualizarDetalhes="VisualizarDetalhesClick(id)" 
            event="visualizarDetalhes" 
            width="50px" 
            title="Visualizar detalhes" 
            type="orange" 
            :icon="iconDetalhes">
        </simpleButton>
        <simpleButton 
            v-if="cadastrarVal" 
            @copiarClick="CopiarClick(id)" 
            event="copiarClick" 
            width="50px" 
            title="Copiar" 
            type="blue" 
            :icon="iconCopiar">
        </simpleButton>
        <simpleButton 
            v-if="editarVal" 
            @editarClick="EditarClick(id)" 
            event="editarClick" 
            width="50px" 
            title="Editar" 
            type="green" 
            :icon="iconEditar">
        </simpleButton>
        <simpleButton 
            v-if="excluirVal" 
            v-b-modal="'modal-deleta-'+id" 
            event="excluirClick" 
            type="red" 
            width="50px" 
            title="Excluir" 
            :icon="iconExcluir">
        </simpleButton>
        <modalDeleta 
            @confirmaExclusao="ExcluirClick(id)" 
            :id="id">
            </modalDeleta>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
import Vuex from '../../../store/index'
import {mdiContentCopy, mdiPencilOutline, mdiTrashCanOutline, mdiNoteSearchOutline} from '@mdi/js'
export default Vue.extend({
    components: {
        'simpleButton': require('./SimpleButton').default,
        'modalDeleta':  require('../ModalSpecific/ModalDeleta').default,
    },
    props:{
        id:{
            type:[Number, String],
            required: true,
            default: ''
        },
    },
    data() {
        return {
            iconCopiar :mdiContentCopy,
            iconEditar :mdiPencilOutline,
            iconExcluir:mdiTrashCanOutline,
            iconDetalhes:mdiNoteSearchOutline
        }
    },
    methods: {
        EditarClick(id:any){
            this.$emit('editarCadastro', id);
        },
        ExcluirClick(id:any){
            this.$emit('excluirCadastro', id);
        },
        CopiarClick(id:any){
            this.$emit('copiarCadastro', id);
        },
        VisualizarDetalhesClick(id:any){
            this.$emit('visualizarDetalhes', id);
        }
    },
    computed:{
        editarVal: function(){
            return Vuex.getters.getEditar
        },
        excluirVal: function(){
            return Vuex.getters.getExcluir
        },
        cadastrarVal: function(){
            return Vuex.getters.getCadastrar;
        },
    }
})
</script>

<style lang="scss">
</style>