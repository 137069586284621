<template>
	<panelEagle id="rotaManualView" :loading="loadingPanel">
		<b-overlay :show="visualizarPontos" variant="white" :opacity="0.4" rounded="sm">
			<div class="col-sm-12 nopadding row">
				<div class="col-sm-3">
					<tituloPage :icon="mdiMapMarkerDistance" titulo="Rota manual" />
				</div>
				<div class="col-sm-9" align="right">
					<simpleButton :disabled="$v.rota.empresa.$invalid" @click="modalIntegracoes" event="click" text="Integrações"
						:icon="mdiCogs" type="blue" width="17%" />
					<simpleButton :disabled="$v.$invalid" @click="btnArquivoDeRetorno" event="click" text="Arq. retorno"
						:icon="mdiClipboardArrowLeft" type="light-green" width="17%" />
					<BCopiar :disabled="!botaoCopiar" @click="modalCopiaRota" :text="'Copiar rota'" />
					<BImprimir :disabled="!valueCargas.length" :loading="loadingBtnImprimirRota" @click="imprimirRota" />
				</div>
			</div>
			<div class="col-sm-12">
				<hr />
			</div>
			<!-- pra n ficar o icone nenhum -->
			<template #overlay>
				<div class="badVisualizar col-sm-12 row">
					<h3 class="my-auto col-sm-12">Você está visualizando uma rota</h3>
				</div>
			</template>
			<slideUpAndDown>
				<div class="row col-sm-12 mt-2 mb-2 nopadding">
					<div class="col-sm-12 row nopadding">
						<div class="col-sm-3 nopadding">
							<div class="col-sm-12 nopadding mt-4">
								<AccordionEtapas :step="'1'" :disabled="controleEtapas[1].disabled" :opened="controleEtapas[1].opened"
									:concluded="controleEtapas[1].concluded" :title="'Filtrar pedidos'" style="padding: 0 15px 0 15px"
									@click="clickEtapas(1)">
								</AccordionEtapas>
							</div>
							<div class="col-sm-12 nopadding mt-4">
								<AccordionEtapas :step="'2'" :disabled="controleEtapas[2].disabled" :opened="controleEtapas[2].opened"
									:concluded="controleEtapas[2].concluded" :title="'Roteirizar'" style="padding: 0 15px 0 15px"
									@click="clickEtapas(2)">
								</AccordionEtapas>
							</div>
						</div>
						<div class="col-sm-9 row p-0 pr-4">
							<!-- ETAPA 1 -->
							<!-- Empresa -->
							<div v-show="controleEtapas[1].opened" class="col-sm-4 pr-0">
								<selectAll @changeSelect="changeEmpresa" :isMultiple="false" nameForRadio="empresaRotaManual"
									:hasSelectAll="false" :labels="[
										{ indexDFH: 'EM', description: 'Empresa*' },
									]" :optionsArray="optEmpresa" :selected="selectedEmpresa" firstSelected="EM"
									:extraClassParent="'nopadding'" />
							</div>
							<!-- Região -->
							<div v-show="controleEtapas[1].opened" class="col-sm-4 pr-0" style="min-width: 200px">
								<selectAll ref="refFiltroRegiao" :key="'filtroRegiaoRotaManual'" :loading="loadingButtonSelect"
									:isMultiple="true" nameForRadio="filtroRegiaoRotaManual" :hasSelectAll="true"
									:labels="[{ indexDFH: 'R', description: 'Região' }]" :optionsArray="optFiltroRegiao" firstSelected="R"
									:extraClassParent="'nopadding'" :extraClass="'nopadding'" @changeSelect="changeFiltroRegiao"
									@changeCheck="changeFiltroRegiao" />
							</div>
							<!-- Marcador -->
							<div v-show="controleEtapas[1].opened" class="col-sm-4 pr-0" style="min-width: 200px">
								<SelectMarcadores ref="refFiltroMarcador" :key="'filtroMarcadorRotaManual'" :loading="loadingButtonSelect"
									:isMultiple="true" :hasSelectAll="true" nameForRadio="filtroMarcadorRotaManual" :labels="[
										{
											indexDFH: 'marcadores',
											description: 'Marcadores',
										},
									]" firstSelected="marcadores" :optionsArray="optFiltroMarcador" :extraClassParent="'nopadding'"
									@changeSelect="changeFiltroMarcador" @changeCheck="changeFiltroMarcador" />
							</div>
							<!-- Data Início -->
							<div v-show="controleEtapas[1].opened" class="col-sm-4 mt-2 pr-0">
								<inputSingleDay :label="'Data Início'" @changeInput="changeDataIniFiltro" name="dataIniFiltro"
									id="dataIniFiltro" :extraClass="'nopadding'" v-model="filtro.dataini" />
							</div>
							<!-- Data Fim -->
							<div v-show="controleEtapas[1].opened" class="col-sm-4 mt-2 pr-0">
								<inputSingleDay :label="'Data Fim'" @changeInput="changeDataFimFiltro" name="dataFimFiltro"
									id="dataFimFiltro" :extraClass="'nopadding'" v-model="filtro.datafim" />
							</div>
							<!-- Próxima etapa -->
							<div v-show="controleEtapas[1].opened" class="col-sm-4 mt-4 pr-0">
								<div class="mt-1">
									<simpleButton @click="proximaEtapa(2)" :disabled="$v.$invalid" :extraClass="'nopadding'"
										:icon="mdiChevronDoubleRight" title="Buscar pedidos cadastrados" type="blue" event="click"
										width="100%" height="30px" text="Próxima etapa" />
								</div>
							</div>

							<!-- ETAPA 2 -->
							<!-- Ponto Saída -->
							<div v-show="controleEtapas[2].opened" class="col-sm-3 pr-0">
								<selectAll ref="refSaida" :loading="loadingButtonSelect" @changeSelect="changePontoSaida"
									:isMultiple="false" nameForRadio="pontoSaidaRotaManual" :hasSelectAll="false"
									:labels="[{ indexDFH: 'P', description: 'Saída*' }]" :optionsArray="optPonto" firstSelected="P"
									:extraClassParent="'nopadding'" />
							</div>
							<!-- Ponto Retorno -->
							<div v-show="controleEtapas[2].opened" class="col-sm-3 pr-0">
								<selectAll ref="refRetorno" :loading="loadingButtonSelect" @changeSelect="changePontoRetorno"
									:isMultiple="false" nameForRadio="pontoRetornoRotaManual" :hasSelectAll="false"
									:labels="[{ indexDFH: 'P', description: 'Retorno*' }]" :optionsArray="optPonto" firstSelected="P"
									:extraClassParent="'nopadding'" />
							</div>
							<!-- Motorista -->
							<div v-show="controleEtapas[2].opened" class="col-sm-3 pr-0">
								<selectAll @changeSelect="changeSelectMotorista" :loading="loadingButtonSelect" :isMultiple="false"
									:hasSelectAll="false" nameForRadio="MotoristaRotaManual" ref="refMotorista" :labels="[
										{ indexDFH: 'Motora', description: 'Motorista' },
									]" :optionsArray="optMotorista" firstSelected="Motora" :extraClassParent="'nopadding'" />
							</div>
							<!-- Ajudante -->
							<div v-show="controleEtapas[2].opened" class="col-sm-3 pr-0">
								<selectAll @changeSelect="changeSelectAjudante" ref="refAjudante" :loading="loadingButtonSelect"
									:isMultiple="false" :hasSelectAll="false" nameForRadio="AjudanteRotaManual" :labels="[
										{ indexDFH: 'Motora', description: 'Ajudante ' },
									]" :optionsArray="optAjudante" firstSelected="Motora" :extraClassParent="'nopadding'" />
							</div>
							<!-- Data -->
							<div v-show="controleEtapas[2].opened" class="col-sm-3 mt-2 pr-0">
								<inputSingleDay @changeInput="changeData" name="dataRotaManual" id="dataRotaManual"
									:extraClass="'nopadding'" />
							</div>
							<!-- Hora -->
							<div v-show="controleEtapas[2].opened" class="col-sm-3 mt-2 pr-0">
								<inputSimple type="time" @changeInput="changeInputHora" label="Hora" name="rohora"
									:extraClass="'nopadding'" :value="rota.hora" />
								<!-- 
										placeholder="00:00:00"
										:hasMask="true"
										:mask ="['hN:MN:MN']" -->
							</div>
							<!-- Tempo Médio -->
							<div v-show="controleEtapas[2].opened" class="col-sm-3 mt-2 pr-0">
								<inputSimple :extraClass="'nopadding'" type="time" @changeInput="changeInputTempoMedio"
									label="Tempo médio de parada" name="banana" />
							</div>
							<!-- Velocidade média -->
							<div v-show="controleEtapas[2].opened" class="col-sm-3 mt-2 pr-0">
								<inputSimple type="text" @changeInput="changeInputVelMedia" label="Velocidade" name="rovelocidademedia"
									placeholder="Velocidade média" :hasMask="true" :mask="['NNN']" :extraClass="'nopadding'" />
							</div>
							<!-- <InfoEtapaRotaManual v-show="controleEtapas[1].opened" :etapa="1"/>
								<InfoEtapaRotaManual v-show="controleEtapas[2].opened" :etapa="2"/> -->
						</div>
						<!-- Data Hora Limite -->
						<div v-show="controleEtapas[2].opened" class="col-sm-3 mt-2 pr-0">
							<inputSimple placeholder="--/--/---- -- : -- : --" :inputClass="formatoDtErrado ? 'border border-danger' : ''
								" @changeInput="changeInputDataHoraLimite" :hasMask="true" :mask="['DN/hN/NNNN hN:MN:MN']"
								label="Data/hora limite para finalização da rota" name="abobrinha" :extraClass="'nopadding'" />
							<div class="small row" v-show="formatoDtErrado">
								<span class="col-sm-12 text-danger row">
									Hora ou data inválida
								</span>
							</div>
						</div>
						<div v-show="controleEtapas[2].opened" class="col-sm-3 mt-2 pr-0">
							<inputSimple type="text" @changeInput="changeInputNomeRota" label="Nome da rota" name="ronomerota"
								placeholder="Nome da rota" :extraClass="'nopadding'" />
						</div>
						<!-- Próxima etapa -->
						<div v-show="controleEtapas[2].opened" class="col-sm-2 mt-4 pr-0">
							<div class="mt-1">
								<simpleButton @click="proximaEtapa(3)" :disabled="$v.$invalid ||
									!this.rota.pontoSaida ||
									!this.rota.pontoRetorno
									" :extraClass="'nopadding'" :icon="mdiChevronDoubleRight" title="Seguir para próxima etapa" type="blue"
									event="click" width="100%" height="30px" text="Buscar pedidos" />
							</div>
						</div>
					</div>
				</div>
			</slideUpAndDown>
		</b-overlay>

		<div style="padding: 0 0px 0px 15px" class="col-sm-12 mt-3">
			<InfoEtapaRotaManual :etapa="3" style="padding: 0px 0px 5px 0" />
			<div class="col-sm-4 filtrosPedidos" :class="{ filtrosPedidosHeight: validaFiltroPedidos }">
				<!-- Região -->
				<div class="col-sm-5 pl-0">
					<selectAll ref="refRegiao" :key="'select-regiao-rota-manual'" :loading="loadingButtonSelect" :isMultiple="true"
						nameForRadio="regiaoRotaManual" :disabled="!optSeletorRegiao.length" :hasSelectAll="true"
						:labels="[{ indexDFH: 'R', description: 'Região (seletor)' }]" :optionsArray="optSeletorRegiao"
						firstSelected="R" :extraClassParent="'nopadding'" @changeSelect="changeSeletorRegiao"
						@changeCheck="changeSeletorRegiao" />
				</div>
				<!-- Marcador -->
				<div class="col-sm-6 nopadding">
					<SelectMarcadores ref="refMarcador" :loading="loadingButtonSelect" :isMultiple="true"
						nameForRadio="MarcadoresRotaManual" :disabled="!optSeletorMarcador.length" :hasSelectAll="true"
						:labels="[{ indexDFH: 'M', description: 'Marcadores (filtro)' }]" :optionsArray="optSeletorMarcador"
						firstSelected="M" :extraClassParent="'nopadding'" style="padding: 0 2px 0 5px"
						@changeSelect="changeSeletorMarcador" @changeCheck="changeSeletorMarcador" />
				</div>
				<div class="col-sm-1 nopadding" style="margin-top: 17px">
					<simpleButton extraClass="p-2" :disabled="$v.$invalid" @click="clickFiltrarPedidos" event="click" :icon="mdiMagnify" type="blue"
						width="100%" />
				</div>
			</div>
			<div class="col-sm-12 nopadding accordionPlanejar">
				<div class="col-sm-4 divPedidos">
					<div class="col-sm-12 nopadding">
						<tabsEagle :tabs="tabsPag" v-model="modelTab" @activate-tab="clickTab">
							<!-- ABA DE PEDIDOS -->
							<slot slot="tab-pedidos">
								<div class="col-sm-12 nopadding row borda-tab justify-content-center">
									<simpleButton @click="clickInvertePontos" :disabled="!pedidos.draggable.length" v-show="buscandoPedido"
										extraClass="" :icon="mdiArrowDecisionOutline" title="Inverter ordem dos pedidos selecionados"
										type="light-green" event="click" class="acoesPedidos" />
									<!-- v-b-modal="'modal-adicionar-pedido'" -->
									<simpleButton :disabled="$v.$invalid" v-show="buscandoPedido" extraClass="" :icon="mdiPlus"
										title="Adicionar pedidos ou paradas" type="green" class="acoesPedidos" id="acoesAddItem" />
									<simpleButton :disabled="!pedidos.draggable.length || !totalPedidos
										" @click="clickExcluirPedidos" v-show="buscandoPedido" extraClass="" :icon="mdiTrashCanOutline"
										title="Excluir pedidos selecionados" event="click" type="red" class="acoesPedidos" />
									<simpleButton :disabled="!pedidos.draggable.length" @click="clickSelecionarTodosPedidos"
										v-show="buscandoPedido" extraClass="" :icon="mdiCheckBold" title="Selecionar todos" type="blue"
										event="click" class="acoesPedidos" />
									<simpleButton :disabled="!pedidos.draggable.length || !totalPedidos
										" @click="clickDesselecionarTodosPedidos" v-show="buscandoPedido" extraClass="" :icon="mdiCloseThick"
										title="Desselecionar todos" type="red" event="click" class="acoesPedidos" />
									<div class="col-sm-10 row nopadding" v-show="!buscandoPedido">
										<inputSimple :value="valueBuscaPedido" placeholder="Buscar" inputClass="buscando-pedido nopadding"
											@changeInput="changeInputSearchPedido" name="searchOrder" id="searchOrder" />
									</div>
									<simpleButton :disabled="!pedidos.draggable.length" @click="clickBtnSearch" extraClass=""
										:icon="buscandoPedido ? (mdiMagnify) : (mdiCloseThick)"
										:title="buscandoPedido ? ('Buscar pedido') : ('Fechar busca')"
										:type="buscandoPedido ? ('orange') : ('red')" event="click" class="acoesPedidos" />
									<simpleButton v-show="buscandoPedido" :disabled="!pedidos.draggable.length || !totalPedidos
										" @click="clickAddPedidosFimRota" extraClass="" :icon="mdiMapMarkerPlusOutline"
										title="Adicionar pedidos selecionados a uma rota existente" type="orange" event="click"
										class="acoesPedidos" />
									<div class="limiteTotalPedidos">
										<div class="totalizadorPedidos">
											{{ totalPedidos }}/{{ limitePedidos }}
										</div>
										<!-- <simpleButton
											:disabled="$v.$invalid"
											@click="clickBuscarItensRota"
											v-show="buscandoPedido"
											event="click"
											:icon="mdiReload"
											:title="titleBtn"
											type="blue"
											class="acoesPedidos btnTotPedidos"
										/> -->
									</div>
								</div>
								<div class="col-sm-12 row tab-pedidos borda-tab nopadding" id="dragPedidos"
									v-show="pedidos.draggable.length">
									<draggable class="list-group" tag="ul" v-model="pedidos.draggable" v-bind="dragOptions" :group="{
										name: 'description',
										pull: 'clone',
										put: false,
									}" @end="endDrag">
										<!-- v-show="e.show != false" -->
										<li class="list-group-item" v-for="(e, i) in pedidosSelecionados" :key="i + '_pedido_' + e.ircodigo"
											@click="flyToPedido(e)">
											<cardPedido :pedido="e" :rota="rota" @selecionado="selecionaPedido(e, true)" />
										</li>
									</draggable>

									<ul class="list-group" tag="ul" v-if="pedidosSelecionados.length">
										<li class="h-li-divisor b-li-divisor"></li>
										<li class="h-li-divisor"></li>
									</ul>

									<ul class="list-group" tag="ul">
										<!-- <DynamicScroller
                                            :items="pedidosNaoSelecionados"
                                            :minItemSize="50"
                                            class="scroller"
											key-field="ircodigo"
                                            page-mode
                                        >
                                            <template
                                                slot-scope="{ item, index, active }"
                                            >
                                                <DynamicScrollerItem
                                                    :item="item"
                                                    :active="active"
                                                    :data-index="index"
                                                >
                                                    <li
                                                        class="list-group-item"
                                                        @click="flyToPedido(item)"
                                                    >
                                                        <cardPedido
                                                            :pedido="item"
                                                            :rota="rota"
                                                            @selecionado="
                                                                selecionaPedido(
                                                                    item,
                                                                    true
                                                                )
                                                            "
                                                        />
                                                    </li>
                                                </DynamicScrollerItem>
                                            </template>
                                        </DynamicScroller> -->

										<recycle-scroller :items="pedidosNaoSelecionados" :minItemSize="50" key-field="ircodigo"
											v-slot="{ item }" :pageMode="true" :prerender="10">
											<li class="list-group-item" @click="flyToPedido(item)">
												<cardPedido :pedido="item" :rota="rota" @selecionado="
													selecionaPedido(item, true)
													" />
											</li>
										</recycle-scroller>
									</ul>
								</div>
								<statusInformation v-show="!pedidos.draggable.length" typeBar="div" :statusBar="statusBarPedido" />
							</slot>

							<!-- ABA DE VEÍCULOS -->
							<slot slot="tab-veiculos">
								<TabVeiculos :valueVeiculos="valueVeiculos" :statusBarVeiculos="statusBarVeiculos"
									@changeInput="changeInputSearchVeiculo" @carregarVeiculo="verificarCarregamento" @buscarMarcadores="buscarMarcadores">
								</TabVeiculos>
								<statusInformation v-show="!valueVeiculos.length" typeBar="div" :statusBar="statusBarVeiculos" />
							</slot>

							<!-- ABA DE CARGAS -->
							<slot slot="tab-cargas">
								<div class="col-sm-12 mt-3 mb-3 d-flex justify-content-center">
									<div class="col-sm-10">
										<dayPicker id='dataRelCargas' ref="dataRelCargas" name='data' opens='right' :isObrigatorio='false'
											titulo='Intervalo*' @changeInput='changeIntervaloDataFiltroCarga'
											:value="intervaloFiltroAbaCarga" />
									</div>

									<simpleButton @click="filtrarCargasIntervalo()" event="click" :type="'blue'" title="Visualizar rota"
										text="Filtrar" extraClass="styleButtonFilter" width="100%"
										style="min-width: 55px; padding: 5px; margin-top: 20px" />
								</div>

								<TabCargas :cargas="valueCargas" @mostrar="mostrarCarga" @excluir="excluirCarga"
									:visualizarPontos="visualizarPontos" @filtrarCargasIntervalo="filtrarCargasIntervalo">
								</TabCargas>
								<statusInformation v-show="!valueCargas.length" typeBar="div" :statusBar="statusBarCargas" />
							</slot>
						</tabsEagle>
					</div>
				</div>
				<!-- mapa da rota -->
				<div v-show="!visualizarPontos" class="col-sm-8 nopadding">
					<b-overlay>
						<mapaSimples ref="mapaRotaManual" @contextmenu="(v) => {
							return;
						}
							">
							<infoRotaOsrm :dados="rotaOsrm" :alertaRotaFixa="showAlertaRotaFixa" @click="desfazerAcaoRotaFixa" />
							<!-- ações mapa -->
							<div class="acoesMapa" :class="{ acoesMapaPosicao: desenhando }">
								<simpleButton id="btnAzul" @click="startStopDraw" event="click" :type="desenhando ? 'orange' : 'blue'"
									:icon="desenhando ? mdiCheckOutline : mdiShapePolygonPlus
										" title="Selecionar pontos pela área" width="120px" />
								<simpleButton :disabled="$v.$invalid" v-show="!desenhando" @click="roteirizarPedidosPorTipo(1)"
									event="click" text=" Opção1" :icon="mdiVectorSquare" type="green" width="120px"
									title="Melhor opção para rotas de coleta" />
								<simpleButton :disabled="$v.$invalid" v-show="!desenhando" @click="roteirizarPedidosPorTipo(2)"
									event="click" text="Opção 2" :icon="mdiVectorSquare" type="orange" width="120px"
									title="Melhor opção para rotas entre cidades" />
							</div>
							<!-- free draw -->
							<leafletFreeDraw ref="freeDraw" :polygons="regiaoFreeDraw" :classParent="'divFreeDraw'" v-if="desenhando"
								@freeDrawCriado="setCreatedFreeDraw" @desenho="desenhar" />
							<!-- polyline rota -->
							<polilinhaEditavel :latLng="arrayRastro" :poliEditavel="false" />
							<!-- cluster de pontos não selecionados  -->
							<markerCluster ref="refCluster" :disableClusterZoom="18" :spiderfyOnMaxZoom="true"
								:showCoverageOnHover="false">
							</markerCluster>
							<!-- cluster de pontos selecionados  -->
							<ClusterSelecionados ref="refClusterX" :disableClusterZoom="20" :maxClusterRadius="15">
							</ClusterSelecionados>

							<markerPoint v-if="rota.pontoSaida" :longitude="parseFloat(longitudeSaida)"
								:latitude="parseFloat(latitudeSaida)" icon="img/inicio.png" :iconSize="[36, 36]" />
							<markerPoint v-if="rota.pontoRetorno" :latitude="parseFloat(latitudeRetorno)"
								:longitude="parseFloat(longitudeRetorno)" icon="img/fim.png" :iconSize="[36, 36]" />
							<markerPoint icon="img/matriz.png" :iconSize="[36, 36]" />
							<template v-for="(value, index) in desenhoRegioes">
								<l-polygon :key="`${index}_poly_${value.recodigo}`" v-if="true" :lat-lngs="value.latlngs"
									:color="value.color" :interactive="false" :opacity="0.7" :smoothFactor="1.1" :noClip="false"
									:name="value.descricao" :fillOpacity="0.42" :fillColor="value.color" />
							</template>
						</mapaSimples>
					</b-overlay>
				</div>
				<!-- mapa de visualizar rota -->
				<div v-show="visualizarPontos" class="col-sm-8 nopadding">
					<MapaRotaProgramada :rota="rotaProgramada"> </MapaRotaProgramada>
				</div>
			</div>
		</div>

		<!-- modal -->
		<modalDeleta @confirmaExclusao="confirmarExcluirPedidos" :id="'pedidos'" />
		<modalDeleta @confirmaExclusao="confirmarExcluirCarga" :id="'carga'" frase="Deseja
mesmo excluir essa rota?" />

		<modalEagle @confirmButton="confirmarCarregamento" id="modalConfirmarCarregamento" title="Confirmar Carregamento"
			:textoConfirmar="defineTextoBotaoAviso" :tamanhoBtn="'110px'">
			<template #modalBody>
				<div v-if="avisoVeiculoCarregado">
					<div class="col-sm-12 nopadding">
						{{ avisoVeiculoCarregado }}
					</div>
					<div class="col-sm-12 nopadding">Deseja criar uma nova rota?</div>
				</div>
				<div v-if="avisoItemCarregado">
					<div class="col-sm-12 nopadding">
						{{ avisoItemCarregado }}
					</div>
				</div>
			</template>
		</modalEagle>

		<modalAddItemRota ref="modalAddPedido" :selecionados="pedidos.draggable" :rota="rota" :filtros="filtro"
			@removerMarcador="removerMarcadorOptions" @registroSalvo="confirmarAdicionarPedido" />

		<modalAddItemRotaParada ref="modalAddParada" :selecionados="pedidos.draggable" :rota="rota" :filtros="filtro"
			@registroSalvo="confirmarAdicionarPedido" />

		<modalAddItensFimRota ref="modalAddPedidosFimRota" :selecionados="selectedItensRota"
			@rotaAlterada="confirmarRotaAlterada" :rota="rota" />

		<modalCopiaRota @rechargeList="copiaRota" :clientCodigo="rota.empresa" />

		<modalIntegracoes @rechargeList="buscaItensRota" :empresaValue="rota.empresa" />

		<b-toast id="my-toast" variant="warning" solid>
			<template #toast-title>
				<div class="d-flex flex-grow-1 align-items-baseline">
					<b-img blank blank-color="#ff5555" class="mr-2" width="12" height="12" />
					<strong class="mr-auto">Aviso!</strong>
				</div>
			</template>
			Valores invalidos para a regiao: {{ regiaoErrada }}. Verifique ela e tente
			novamente.
		</b-toast>

		<b-popover target="acoesAddItem" triggers="hover click" placement="top" ref="popoverAddItem" @hidden="() => { }">
			<div class="col-sm-12 nopadding">
				<simpleButton id="filtrar" width="120px" :style="{ display: 'flex', 'justify-content': 'space-around' }"
					:disabled="$v.$invalid" :icon="mdiPlus" type="blue" text="Pedido" title="Novo pedido" event="click"
					@click="clickOpenModalAddPedido()">
				</simpleButton>
			</div>
			<div class="col-sm-12 nopadding mt-2">
				<simpleButton id="fecharFiltros" width="120px" :style="{ display: 'flex', 'justify-content': 'space-around' }"
					:disabled="$v.$invalid" :icon="mdiPlus" type="blue" text="Parada" title="Nova parada" event="click"
					@click="clickOpenModalAddParada()">
				</simpleButton>
			</div>
		</b-popover>
	</panelEagle>
</template>

<script lang="js">

import Vue from 'vue'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import popupMarkerDinamica
	from '@/components/Atom/SpecificComponents/RotaManual/popupMarker'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { DateTime, Duration } from 'luxon'
import { required } from 'vuelidate/lib/validators'
import { conectionError } from '@/Services/Helpers/swellHeper.js'
import { mapGetters } from 'vuex'
import {
	components,
	data,
	converteSegundosEmHoras,
	geraCor,
	isPointInsidePolygon,
	validarDataHora,
	pickColor,
	calculaValoresPedidos,
	sortFnOrdemPedidos,
	icons,
} from './RotaManual.ts'
import { LatLng } from 'leaflet'
import { Regiao, Pedido } from '../models'

var decodePoly = require('@mapbox/polyline')
// var simplificaPoly = require('@/Services/Helpers/polylineHelper.ts');

export default Vue.extend({
	name: 'RotaManual',

	validations: {
		rota: {
			empresa: { required },
			// pontoSaida: { required },
			// pontoRetorno: { required },
		}
	},

	components: components,

	data() {
		return {
			optEmpresa: new EmpresasService().Get(),
			...data,
			...icons,
			selectedItensRota: [],
			regioesOnMap: [],
			intervaloCarga: '',
			intervaloFiltroAbaCarga: '',
			dataCarga: '',
			botaoCopiar: false
		}
	},

	beforeMount() {
		this.limparControleEtapas()
	},

	mounted() {
		// this.addVisibilityChangeListener(this.atualizaPontos)
		if (!this.getMaster()) {
			this.selectedEmpresa = [this.optEmpresa[0]]
		}
		this.rota.hora = (DateTime.now()).toFormat('HH:mm')
	},

	computed: {
		validaFiltroPedidos() {
			return (this.valueRegiao && this.valueRegiao.length > 1)
				|| (this.valueMarcador && this.valueMarcador.length > 1)
		},

		defineTextoBotaoAviso() {
			return this.avisoItemCarregado ? 'Recarregar' : 'Confirmar'
		},

		pedidosSelecionados() {
			return this.pedidos.draggable.filter(e => e.selecionado && e.show)
		},

		pedidosNaoSelecionados() {
			return this.pedidos.draggable.filter(e => !e.selecionado && e.show)
		},
	},

	watch: {
		'pedidos.draggable'(value) {
			this.selectedItensRota = value.filter(e => e.selecionado)
		},

		// 'rota.velocidade'(value) {
		// 	if (value) {
		// 		var distanciaRota = Number(this.rotaOsrm.distancia)
		// 		var velocidadeMedia = Number(value)
		// 		if (velocidadeMedia && distanciaRota) {
		// 			this.rotaOsrm.tempo = Number(distanciaRota / velocidadeMedia)
		// 		}
		// 	}	
		// 	// function duasCasa(value){
		// 	// 	value = String(value).split('.')[0]
		// 	// 	if(value.length == 1){
		// 	// 		return '0'+value
		// 	// 	}
		// 	// 	return value
		// 	// }
		// },
	},

	/**
	 * Métodos organizados por:
	 * - listeners de eventos dos inputs
	 * - funcionalidades da aba pedidos - botões
	 * - funcionalidades da aba pedidos - outros
	 * - funcionalidades da aba veiculos
	 * - funcionalidades da aba cargas
	 * - funcionalidades do mapa e seus objetos
	 * - funcionalidades gerais e outros métodos
	 * - funcionalidades de controle de etapa
	 * - requisições
	 */
	methods: {

		...mapGetters(['getMaster']),

		changeIntervaloData(data) {
			this.intervaloCarga = data;
		},

		changeIntervaloDataFiltroCarga(data) {
			this.intervaloFiltroAbaCarga = data;
		},

		async filtrarCargasIntervalo() {
			this.loadingPanel = true;
			const dataInicialFinalIntervalo = this.intervaloCarga.split('-');
			const dataInicialFinalIntervaloFiltroAbaCarga = this.intervaloFiltroAbaCarga.split('-');

			this.$emit('changeInput', this.intervaloFiltroAbaCarga);

			var obj = {
				'cliente': this.rota.empresa,
				'regioes': this.filtro.regiao,
				'marcadores': this.filtro.marcador,
				'dataini': dataInicialFinalIntervalo[0],
				'datafim': dataInicialFinalIntervalo[1],
				'dia': dataInicialFinalIntervaloFiltroAbaCarga[0],
				'dataInicialFiltroCarga': dataInicialFinalIntervaloFiltroAbaCarga[0],
				'dataFinalFiltroCarga': dataInicialFinalIntervaloFiltroAbaCarga[1]
			}

			this.fetchItensRota(obj, true);
		},

		desenhar(e) {
			this.criarRegiaoFreeDraw(e)
		},

		criarRegiaoFreeDraw(coordenadas) {
			this.excluirRegiao()
			var reg = L.polygon(coordenadas, {
				color: "#63a3db",
				interactive: false,
			});
			this.addToMapa(reg);
			this.regioesOnMap.push(reg);
		},

		excluirRegiao() {
			this.regioesOnMap.forEach((r) => {
				return r.remove()
			})
			this.regioesOnMap = []
		},

		addToMapa(object) {
			if (!this.$refs.mapaRotaManual) return;
			let mapa = this.$refs.mapaRotaManual.returnMapObject();
			if (!mapa) return;
			object.addTo(mapa);
		},

		//////////////////////////////////////////////
		////////// LISTENERS - ETAPAS 1 E 2 //////////

		changeDataIniFiltro(value) {
			this.filtro.dataini = value
			// this.limparCamposEtapas(3)
		},

		changeDataFimFiltro(value) {
			this.filtro.datafim = value
			// this.limparCamposEtapas(3)
		},

		changeFiltroMarcador(value) {
			this.filtro.marcador = value
			// this.limparCamposEtapas(3)
		},

		changeFiltroRegiao(value) {
			this.filtro.regiao = value
			// this.limparCamposEtapas(3)
		},

		changeSeletorMarcador(value) {
			this.valueMarcador = value
		},

		changeSeletorRegiao(value) {
			this.valueRegiao = value
		},

		changeEmpresa(value) {
			this.rota.empresa = value[0]
			if (this.rota.empresa == undefined) {
				this.botaoCopiar = false;
			}
			this.desfazerAcaoRotaFixa()
			this.limparCamposEtapas(2)
			this.limparRotaProgramada()
			this.limpaInfoMapa()
			this.$nextTick(() => {
				this.$refs.refSaida.clearAll()
				this.$refs.refRetorno.clearAll()
				this.$refs.refAjudante.clearAll()
				this.$refs.refMotorista.clearAll()
				this.$refs.refFiltroRegiao.clearAll()
				this.$refs.refFiltroMarcador.clearAll()
			})
			if (value.length) {
				this.buscaDadosSelect()
			}
			if (this.latitudeSaida > 0 && this.latitudeRetorno > 0) {
				this.buscaItensRota()
			}
		},

		changeData(value) {
			this.rota.data = value
			this.dataCarga = value;
			// this.limparCamposEtapas(2, false)
		},

		/**
		 * @param {number[]} value - código do ponto selecionado
		 * @description procura o ponto selecionado e separa a lat-lng
		 * dele.
		 * ---As variáveis latitudeSadida e longitudeSaida são legacy.
		 * Eu não sei onde elas são utilizadas e a esse ponto tenho medo
		 * de procurar e descobrir.
		 */
		changePontoSaida(value) {
			// this.limparControleEtapas()
			this.rota.pontoSaida = value[0]
			if (this.rota.pontoSaida == undefined) {
				this.botaoCopiar = false;
			}
			this.pontoSaida = this.optPonto.find((p) => {
				return p.value === value[0]
			})
			if (typeof this.pontoSaida === 'undefined') return
			this.latitudeSaida = this.pontoSaida.loc.split(',')[0]
			this.longitudeSaida = this.pontoSaida.loc.split(',')[1]
			if (this.latitudeSaida > 0 && this.latitudeRetorno > 0) {
				this.buscaItensRota()
			}
		},

		/**
		 * @param {number[]} value - código do ponto selecionado
		 * @description procura o ponto selecionado e separa a lat-lng
		 * dele.
		 * ---As variáveis latitudeRetorno e longitudeRetorno são legacy.
		 * Eu não sei onde elas são utilizadas e a esse ponto tenho medo
		 * de procurar e descobrir.
		 */
		changePontoRetorno(value) {
			// this.limparControleEtapas()
			this.rota.pontoRetorno = value[0]
			if (this.rota.pontoRetorno == undefined) {
				this.botaoCopiar = false;
			}
			this.pontoRetorno = this.optPonto.find((p) => {
				return p.value === value[0]
			})
			if (typeof this.pontoRetorno === 'undefined') return
			this.latitudeRetorno = this.pontoRetorno.loc.split(',')[0]
			this.longitudeRetorno = this.pontoRetorno.loc.split(',')[1]
		},

		changeInputTempoMedio(value) {
			this.rota.tempoParada = value
			// this.limparCamposEtapas(2, false)
			// this.calculaTempoRotaOsrm(value)
		},

		changeInputHora(value) {
			this.rota.hora = value
		},

		changeInputVelMedia(value) {
			this.rota.velocidade = value
		},

		changeInputNomeRota(value) {
			this.rota.nomeRota = value
		},

		changeSelectMotorista(value) {
			this.rota.motorista = value
		},

		changeSelectAjudante(value) {
			this.rota.ajudante = value
		},

		/**
		 * verifica se a data e hora informada pelo usuario é valida
		 */
		changeInputDataHoraLimite(value) {
			this.rota.dataHoraLimite = ''
			if (value.length >= 19) {
				let dt = validarDataHora(value)
				if (!dt) {
					this.formatoDtErrado = true
				} else {
					this.rota.dataHoraLimite = value
					this.formatoDtErrado = false
				}
			}
			if (!value) {
				this.formatoDtErrado = false
			}
		},
		////////// LISTENERS - ETAPAS 1 E 2 /////////
		/////////////////////////////////////////////


		////////////////////////////////////////////
		////////// FUNCIONALIDADES PÁGINA //////////

		btnArquivoDeRetorno() {
			this.arquivoDeRetorno()
		},

		/**
		 * Atualiza as rotas, copia a rota selecioada, e as coloca em ordem
		 * dependente da func buscaItensRota
		 */
		async copiaRota(value) {
			this.loadingPanel = true
			try {
				await this.buscaItensRota()
				var arr = this.pedidos.draggable
				value.forEach((dados) => {
					const existingItem = arr.find(d => dados.ircodigoexterno === d.ircodigoexterno);

					if (existingItem) {
						existingItem.selecionado = true;
						existingItem.irordem = dados.irordem;
						existingItem.irinijanelaentrega = dados.irinijanelaentrega;
						existingItem.irfimjanelaentrega = dados.irfimjanelaentrega;
						existingItem.irmarcador = dados.irmarcador;
						existingItem.irtempoparado = dados.irtempoparado;
						existingItem.marcadores = [dados.irmarcador];
						existingItem.show = true;
					} else {
						arr.push({
							ircodigoexterno: dados.ircodigoexterno,
							irnome: dados.irnome,
							selecionado: true,
							irordem: dados.irordem,
							irinijanelaentrega: dados.irinijanelaentrega,
							irfimjanelaentrega: dados.irfimjanelaentrega,
							irmarcador: dados.irmarcador,
							irtempoparado: dados.irtempoparado,
							marcadores: [dados.irmarcador],
							show: true
						});
					}
				});

				var primeiraArr = []
				var segundaArr = []
				arr.filter((v) => {
					if (v.selecionado) {
						primeiraArr.push(v)
					} else {
						segundaArr.push(v)
					}
				})
				this.pedidos.draggable = primeiraArr.concat(segundaArr)
				this.sortPedidosDraggable()
				this.roteirizarPedidosPorTipo(1)
			} catch (error) {
				this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger')
			} finally {
				this.totalPedidos = value.length;
				this.loadingPanel = false
			}
		},

		btnImprimirRota() {
			this.imprimirRota()
		},
		////////// FUNCIONALIDADES PÁGINA //////////
		////////////////////////////////////////////


		/////////////////////////////////
		////////// ABA PEDIDOS //////////

		/**
		 * **Anotações**
		 * - limpar listagem de pedidos e objetos do mapa
		 * - pegar os pedidos do array de pedidos.semFiltro de pontos,
		 *   conforme os filtros selecionados
		 * - ordenar pontos selecionados
		 * - mostrar pedidos já organizado na listagem
		 * - criar ou pegar objetos markers e regioes do mapa e guardar num array
		 * - mostrar objetos no mapa
		 */
		clickFiltrarPedidos() {
			this.loadingPanel = true
			setTimeout(() => {
				try {
					this.limparRotaProgramada()
					this.arrayRastro = []
					this.limpaInfoMapa()
					if (!this.valueRegiao.length && !this.valueMarcador.length) {
						this.desenhoRegioes = []
						this.pedidos.draggable.map(e => e.show = true)
						this.criarMarkersPedidos()
					} else {
						this.filtrarPedidosPorMarcadorERegiao()
						if (this.valueRegiao.length) {
							this.mostrarRegioesMapa()
						} else {
							this.desenhoRegioes = []
						}
						this.criarMarkersPedidos()
					}
					if (!this.totalPedidos) {
						this.desfazerAcaoRotaFixa()
					}
				} catch (error) {
					this.loadingPanel = false
					this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger')
				} finally {
					this.loadingPanel = false
				}
			}, 300)
		},

		/**
		 * troca a ordem dos pontos, e chama a func roteirizarPedidosPorTipo
		 */
		clickInvertePontos() {
			this.arrayRastro = [];
			const selecionados = this.getPedidosSelecionados();
			if (selecionados.length) {
				this.loadingPanel = true;
				setTimeout(() => {
					try {
						this.showAlertaRotaFixa = true;
						selecionados.reverse();
						selecionados.forEach((pedido, index) => {
							pedido.irordem = index + 1;
							pedido.marker.atualizaIcone(pedido)
						});
						this.sortPedidosDraggable();
						this.criarMarkersPedidos();
						this.loadingPanel = false;
					} catch (error) {
						this.loadingPanel = false;
					}
				}, 300)
			}
		},

		confirmarAdicionarPedido(dados, edicao = false) {
			this.controleAttPonto = true
			if (edicao) {
				this.atualizaPedido(dados)
			} else {
				this.markerFactoryUnique(dados)
				this.sortPedidosDraggable()
				this.criarMarkersPedidos()
			}
		},

		confirmarRotaAlterada(items) {
			this.controleAttPonto = true;
			if (items) {
				this.$bvModal.hide('modal-add-itens-fim-rota')
				const itemCodes = items.map((item) => item.ircodigo);
				const markers = this.pedidos.draggable
					.filter((e) => e.selecionado && !itemCodes.includes(e.ircodigo))
					.map((pedido) => pedido.marker);
				this.$refs.refClusterX?.clearLayers();
				this.$refs.refClusterX?.addMarkerBulk(markers);
				this.pedidos.draggable = this.pedidos.draggable
					.filter((pedido) => !itemCodes.includes(pedido.ircodigo));
				this.calculaValoresAux();
				this.sortPedidosDraggable()
				this.buscaItensRota(true)
			}
		},

		removerMarcadorOptions(codigo) {
			try {
				const indexFiltro = this.optFiltroMarcador.findIndex(e => e.value === codigo);
				if (indexFiltro !== -1) {
					this.optFiltroMarcador.splice(indexFiltro, 1);
				}
				const indexSeletor = this.optSeletorMarcador.findIndex(e => e.value === codigo);
				if (indexSeletor !== -1) {
					this.optSeletorMarcador.splice(indexSeletor, 1);
				}
				this.pedidos.draggable.forEach(pedido => {
					const indexMarcador = pedido.marcadores.findIndex(e => e === codigo);
					if (indexMarcador !== -1) {
						pedido.marcadores.splice(indexMarcador, 1);
					}
				});
			} catch (error) {
				this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
			}
		},

		atualizaPedido(dados) {
			const update = (list, predicate) => {
				const index = list.findIndex(predicate)
				if (index > -1) {
					list[index] = dados
				}
			}
			update(this.pedidos.draggable, e => e.ircodigo == dados.ircodigo)
			this.$forceUpdate()
		},

		clickOpenModalAddPedido(dados) {
			this.$bvModal.show('modal-adicionar-pedido')
			this.$refs.modalAddPedido.preparaModal(dados, this.optFiltroMarcador)
			this.$refs.popoverAddItem.doClose()
		},

		clickOpenModalAddParada(dados) {
			this.$bvModal.show('modal-adicionar-parada')
			this.$refs.modalAddParada.preparaModal(dados)
			this.$refs.popoverAddItem.doClose()
		},

		clickExcluirPedidos() {
			this.limparRotaProgramada()
			this.arrayRastro = []
			this.limpaInfoMapa()
			this.$refs.refCluster?.clearLayers()
			this.$refs.refClusterX?.clearLayers()
			this.$bvModal.show('modal-deleta-pedidos')
		},

		async confirmarCarregamento() {
			if (this.avisoItemCarregado) {
				this.avisoItemCarregado = ''
				this.modelTab = 0
				this.buscaItensRota()
			} else {
				this.carregarVeiculo()
			}
			this.$bvModal.hide('modalConfirmarCarregamento')
		},

		/**
		 * @listens confirmarExcluirPedidos modal de confirmação de exclusão
		 */
		confirmarExcluirPedidos() {
			this.excluirPedidos()
		},

		async clickSelecionarTodosPedidos() {
			this.limparRotaProgramada()
			this.arrayRastro = []
			this.limpaInfoMapa()
			this.$refs.refCluster?.clearLayers()
			this.$refs.refClusterX?.clearLayers()
			let count = 0
			await this.pedidos.draggable.forEach((v, index) => {
				if (v.selecionado) count++
				if (v.show && !v.selecionado) {
					if (count < this.limitePedidos) {
						count++
						v.selecionado = true
						v.irordem = count
						v.show = true
						v.marker.atualizaIcone(v)
					}
				}
			})
			this.setTotalPedidos(count)
			this.sortPedidosDraggable()
			this.criarMarkersPedidos()
		},

		/**
		 * @listens click botão "Desselecionar todos"
		 */
		clickDesselecionarTodosPedidos() {
			this.buscaItensRota()
		},

		/**
		 * @listens click botão "Buscar pedidos"
		 */
		clickBtnSearch() {
			this.valueBuscaPedido = ''
			this.buscandoPedido = !this.buscandoPedido
			this.changeInputSearchPedido('')
		},

		/**
		 * @listens click botão "Adicionar pedidos selecionados a uma rota existente"
		 */
		clickAddPedidosFimRota() {
			this.$bvModal.show('modal-add-itens-fim-rota')
		},

		/**
		 * @listens changeInput botão "Buscar"
		 */
		changeInputSearchPedido(value) {
			this.valueBuscaPedido = value;
			if (this.loadingPanel) return;
			if (!value) {
				this.showAllPedidos();
				return;
			}
			this.filterPedidos(value);
		},

		showAllPedidos() {
			this.pedidos.draggable.forEach((pedido) => (pedido.show = true));
		},

		filterPedidos(value) {
			const lowerCaseValue = value.toLowerCase();
			this.pedidos.draggable.forEach((pedido) => {
				const irnome = pedido.irnome.toLowerCase();
				const irdocumento = pedido.irdocumento?.toLowerCase();
				// const ircubagem = pedido.ircubagem?.toString().toLowerCase();
				// const irpeso = pedido.irpeso?.toLowerCase();
				// const irvalor = pedido.irvalor?.toLowerCase();
				const match =
					irnome.includes(lowerCaseValue) ||
					irdocumento.includes(lowerCaseValue)
					//  ||
					// ircubagem.includes(lowerCaseValue) ||
					// irpeso.includes(lowerCaseValue) ||
					// irvalor.includes(lowerCaseValue)
					;
				pedido.show = match;
			});
		},

		filterPedidos2(value) {
			const lowerCaseValue = value.toLowerCase();
			this.pedidos.draggable.filter((pedido) => {
				const irnome = pedido.irnome.toLowerCase();
				const irdocumento = pedido.irdocumento?.toLowerCase();
				const match =
					irnome.includes(lowerCaseValue) ||
					irdocumento.includes(lowerCaseValue)
				pedido.show = match;
				return match
			});
		},

		/**
		 * @listens click botão da ação buscar dados (pedidos, cargas, veículos)
		 */
		clickBuscarItensRota() {
			this.buscaItensRota()
		},

		/**
		 * @listens click Prepara os dados e chama a função [fetchItensRota]
		 */
		async buscaItensRota(apenasRota = false) {
			this.loadingPanel = true;
			this.desfazerAcaoRotaFixa();
			if (!apenasRota) {
				this.clearSelects();
				this.limpaEtapa3Dados();
				this.limparRotaProgramada();
				this.limpaInfoMapa();
				this.statusBarPedido = 'info';
				this.statusBarVeiculos = 'info';
				this.statusBarCargas = 'info';
			}
			var obj = {
				'dia': this.rota.data,
				'cliente': this.rota.empresa,
				'regioes': this.filtro.regiao,
				'marcadores': this.filtro.marcador,
				'dataini': this.filtro.dataini,
				'datafim': this.filtro.datafim,
				'apenasRota': apenasRota
			};

			await this.fetchItensRota(obj, apenasRota);
			this.loadingPanel = false
		},

		desfazerAcaoRotaFixa() {
			this.showAlertaRotaFixa = false
			this.pedidos.draggable.forEach(e => {
				e.fixo = false
			})
		},

		////////// ABA PEDIDOS //////////
		/////////////////////////////////


		/////////////////////////////////////////////
		////////// ABA PEDIDOS CONTINUAÇÃO //////////

		filtrarPedidosPorMarcadorERegiao() {
			const regioes = this.valueRegiao;
			const marcadoresFiltro = this.valueMarcador;

			if (!Array.isArray(regioes) || !Array.isArray(marcadoresFiltro)) {
				throw new Error('Parâmetros incorretos!');
			}

			if (marcadoresFiltro.length || regioes.length) {
				this.filtrarPedidosPorMarcador(marcadoresFiltro);
				this.filtrarPedidosPorRegiao(regioes);
			} else {
				this.mostrarTodosPedidos();
			}
			this.setTotalPedidos()
		},

		filtrarPedidosPorMarcador(marcadoresFiltro) {
			if (marcadoresFiltro.length) {
				this.pedidos.draggable.forEach(pedido => {
					const marcadores = Object.values(pedido.marcadores);
					const hasMarcadores = marcadores.length > 0;
					const hasParada = pedido.irinterjornada || pedido.irintrajornada;
					const shouldShow = pedido.selecionado || hasParada || (hasMarcadores
						&& marcadores.some(m => marcadoresFiltro.includes(m)));
					pedido.show = shouldShow;
				});
			} else {
				this.mostrarTodosPedidos();
			}
		},

		filtrarPedidosPorRegiao(regioes) {
			if (regioes.length) {
				this.setTotalPedidos()
				let total = this.totalPedidos
				regioes.forEach(regiao => {
					const regiaoObj = this.listaDeRegioes.get(regiao);
					if (!regiaoObj) return;
					const coords = regiaoObj.getCoordenadas('array');
					this.pedidos.draggable
						.filter(e => !e.selecionado && e.show)
						.forEach(pedido => {
							const point = [pedido.polatitude, pedido.polongitude];
							const dentroRegiao = isPointInsidePolygon(point, coords);
							if (total < this.limitePedidos) {
								if (dentroRegiao)
									total++
								pedido.selecionado = dentroRegiao
								pedido.show = pedido.show || !this.valueMarcador.length;
							}
						});
				});
				this.atualizarPedidosFiltradosSelecionados();
				this.setTotalPedidos()
				this.sortPedidosDraggable()
				this.calculaValoresAux();
			}
		},

		atualizarPedidosFiltradosSelecionados() {
			let ordem = 1;
			this.pedidos.draggable.forEach(pedido => {
				if (pedido.selecionado) {
					pedido.irordem = ordem;
					pedido.marker.atualizaIcone(pedido);
					pedido.show = true;
					ordem++;
				}
			});
		},

		mostrarTodosPedidos() {
			this.pedidos.draggable.forEach(pedido => {
				pedido.show = true;
			});
		},

		sortPedidosDraggable() {
			this.pedidos.draggable.sort(sortFnOrdemPedidos)
		},

		/**
		 * @description essa função "arruma" os desenhos e depois faz a
		 * request que tenta buscar pontos na região para montar a rota do
		 * usuário. Essa request tem como parâmetro:
		 * latLong: lista de todas as lat/longs que fazem parte da rota,
		 * incluindo o ponto de saída e de retorno.
		 * @author Rafael
		 */
		mostrarRegioesMapa() {
			this.desenhoRegioes = this.desenhoRegioes.filter((dr) => {
				return this.valueRegiao.includes(dr.recodigo);
			});
			const desenhos = this.valueRegiao.map((codRegiao) => {
				const regiao = this.listaDeRegioes.get(codRegiao);
				if (!regiao) return null;
				const latlngs = regiao.getCoordenadas('');
				const color = regiao.getCor();
				return {
					latlngs,
					color,
					recodigo: regiao.codigo,
				};
			}).filter(Boolean);
			this.desenhoRegioes = desenhos;
		},

		/**
		 * @param {array[]}  coords
		 * @param {object[]|number[]} coords[]
		 * @param {number|undefined}   coords[].lat
		 * @param {number|undefined}   coords[].lng
		 * @description Usa umas ideia estranha pra descobrir se existem pontos
		 * dentro da array de coordenadas e caso positivo, roteirizar eles.
		 * @author alterado por Rafael
		 */
		selecionaItensFreeDraw(coords, transformaCoordenadas = true) {
			try {
				const selecionados = this.getPedidosSelecionados()
				let countSelecionados = selecionados.length
				if (countSelecionados >= this.limitePedidos) return
				for (const a of this.pedidos.draggable.filter(e => !e.selecionado && e.show)) {
					for (const b of coords) {
						if (countSelecionados >= this.limitePedidos) break
						let auxCoords = b
						if (transformaCoordenadas) {
							auxCoords = this.arrumaCoordenadas(b)
						}
						if (auxCoords) {
							const point = [a['polatitude'], a['polongitude']]
							const dentroRegiao = isPointInsidePolygon(point, auxCoords)
							if (dentroRegiao) {
								countSelecionados++
								a.selecionado = true
								a.irordem = countSelecionados
								a.marker.atualizaIcone(a)
								break
							}
						}
					}
				}
				this.setTotalPedidos(countSelecionados)
				this.sortPedidosDraggable()
				this.criarMarkersPedidos()
				if (selecionados.length === 0) {
					this.desfazerAcaoRotaFixa()
				}
			} catch (error) {
				this.loadingPanel = false
				this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger')
			} finally {
				this.loadingPanel = false
			}
		},

		/**
		 * evento de arrastar o componente
		 * dentro do evento tem os valores, que sao usados para pegar a posicao do item
		 * na array e selecionar ela, ele chama outras func para deixar a ordem certa
		 * newDraggableIndex
		 * newIndex
		 * oldDraggableIndex
		 * oldIndex
		 * @author alterado por Rafael
		 */
		endDrag(value) {
			this.showAlertaRotaFixa = true;
			this.rotaOsrm.tempo = 0;
			this.rotaOsrm.distancia = 0;
			this.arrayRastro = [];
			let newDraggableIndex = value.newDraggableIndex;
			let oldDraggableIndex = value.oldDraggableIndex;
			if (newDraggableIndex === oldDraggableIndex) {
				return;
			}
			const totalPedidos = this.totalPedidos + 1;
			if (totalPedidos > this.limitePedidos) {
				this.setTotalPedidos(this.limitePedidos);
				return;
			}
			this.setTotalPedidos();

			let pedido = this.pedidos.draggable[newDraggableIndex]

			pedido.fixo = true
			if (!pedido.selecionado) {
				this.selecionaPedido(pedido, true)
			} else {
				this.$refs.refClusterX?.removeLayer(pedido.marker)
				pedido.selecionado = false
				this.selecionaPedido(pedido, true)
			}
			this.loadingPanel = false;
		},

		/**
		 * Cria um novo objt marker para por na tela caso seja preciso, ou remove e
		 * adiciona aos markers selecionados essa função só deve ser chamada quando
		 * for um pedido ou uma popup emitindo evento.
		 * @param { Pedido } pedido - os valores do ponto
		 * @author Lucas Eduardo, Rafael
		 */
		selecionaPedido(pedido, teste = false) {
			this.rotaOsrm.tempo = 0
			this.rotaOsrm.distancia = 0
			this.arrayRastro = []
			if (teste) {
				pedido = this.pedidos.draggable.find(e => e.ircodigo == pedido.ircodigo)
			}
			if (!pedido.selecionado) {
				if (this.totalPedidos + 1 > this.limitePedidos) {
					this.setTotalPedidos(this.limitePedidos)
					return;
				}
			}
			pedido.selecionado = !pedido.selecionado
			let count = 1
			this.pedidos.draggable.forEach(e => {
				if (e.selecionado) {
					e.irordem = count
					e.marker.atualizaIcone(e)
					e.show = true
					count++
				} else {
					e.irordem = 0
				}
			})
			this.sortPedidosDraggable()
			this.calculaValoresAux()
			this.setTotalPedidos()

			pedido.marker.atualizaIcone(pedido)
			if (pedido.selecionado) {
				this.$refs.refCluster?.removeLayer(pedido.marker)
				this.$refs.refClusterX?.addLayer(pedido.marker)
			} else {
				this.$refs.refClusterX?.removeLayer(pedido.marker)
				this.$refs.refCluster?.addLayer(pedido.marker)
			}
			if (!this.totalPedidos) {
				this.desfazerAcaoRotaFixa()
			}
		},

		getPedidosSelecionados() {
			return this.pedidos.draggable.filter((v) => v.selecionado)
		},

		getPedidosNaoSelecionados() {
			return this.pedidos.draggable.filter((v) => !v.selecionado)
		},

		arrumaCoordenadas(coordenadas) {
			var coordenadasArray = []
			for (var coo of coordenadas) {
				coordenadasArray.push([coo.lat, coo.lng])
			}
			return coordenadasArray
		},

		corrigeOrdemPedidos(inicio, fim) {
			for (let i = inicio; i <= fim; i++) {
				const pedido = this.pedidos.draggable[i];
				if (pedido.selecionado) {
					pedido.irordem = i + 1;
					pedido.marker.atualizaIcone(pedido);
				}
			}
		},

		arrumaOrdemPedidosMais(index) {
			for (let i = index; i < this.pedidos.draggable.length; i++) {
				const pedido = this.pedidos.draggable[i];
				if (pedido.selecionado) {
					pedido.irordem = i + 1;
					pedido.marker.atualizaIcone(pedido);
				}
			}
		},

		arrumaOrdemPontosRoteirizados(value) {
			var pontoRoteirizados = []
			if (value.length < 2) {
				return
			}
			if (!Array.isArray(value)) {
				var aux = Object.keys(value).map((k) => {
					return value[k]
				})
				value = aux
			}
			pontoRoteirizados = value
			pontoRoteirizados.shift()
			pontoRoteirizados.pop()
			var selecionados = this.getPedidosSelecionados()
			// this.pedidos.draggable.forEach(e => {
			// 	pontoRoteirizados.forEach(e2 => {
			// 		if(e.ircodigo == e2.ircodigo)
			// 	})
			// })
			pontoRoteirizados.map((e, key) => {
				let origem = key
				if (typeof selecionados[e.index_origem] !== 'undefined') {
					origem = e.index_origem
				}
				selecionados[origem].irordem = e.waypoint_index
				selecionados[origem].distancia = e.distancia
				selecionados[origem].distanciaseq = e.distanciaseq
				selecionados[origem].duracao = e.duracao
				selecionados[origem].duracaoseq = e.duracaoseq
				selecionados[origem].show = true
				selecionados[origem].marker.atualizaIcone(selecionados[origem])

			})
			selecionados.sort(sortFnOrdemPedidos)
			let duracaototal = 0
			selecionados.map((e, key) => {
				e.duracaoseq = e.duracao + duracaototal
				duracaototal = e.duracaoseq + e.irtempoparado
				// this.pedidos.draggable.forEach((e2, i) => {
				// 	if (e.ircodigo == e2.ircodigo) {
				// 		this.pedidos.draggable[i] = e
				// 	}
				// })
			})
			// selecionados.sort(sortFnOrdemPedidos)
			// this.sortPedidosDraggable()
			this.criarMarkersPedidos()
			// this.$forceUpdate()
		},
		////////// ABA PEDIDOS CONTINUAÇÃO //////////
		/////////////////////////////////////////////


		//////////////////////////////////
		////////// ABA VEÍCULOS //////////

		changeInputSearchVeiculo(value) {
			if (!value) {
				this.valueVeiculos = this.veiculoSemFiltro
				return
			}
			this.valueVeiculos = this.veiculoSemFiltro.filter((v) => {
				if (v.veplaca.toLowerCase().includes(value.toLowerCase())) {
					return true
				} else if (v.veprefixo.toLowerCase().includes(value.toLowerCase())) {
					return true
				}
				return false
			})
		},
		//////////////////////////////////


		////////////////////////////////
		////////// ABA CARGAS //////////

		/**
		 * @param {Object} value
		 * @param {Number} index
		 */
		mostrarCarga(value, index) {
			this.limparRotaProgramada()
			let atualizar = value.visualizarRota
			if (value.visualizarRota) {
				atualizar = true
				this.valueCargas.map(e => e.visualizarRota = false)
				this.valueCargas[index].visualizarRota = atualizar
				this.setRotaProgramada(value)
			}
			this.visualizarPontos = atualizar
			this.tabsPag[0].disabled = atualizar
			this.tabsPag[1].disabled = atualizar
		},

		setRotaProgramada(value) {
			let vr = value.valoresRota[0];
			let pSaida = this.pegaPonto(vr.ropontosaida);
			let pRetorno = this.pegaPonto(vr.ropontoretorno);
			this.rotaProgramada.ajudante = vr.roajudante;
			this.rotaProgramada.nomeRota = vr.ronomerota;
			this.rotaProgramada.veiculo = value.carro;
			this.rotaProgramada.pontos = value.itens;
			this.rotaProgramada.volume = vr.roqtde;
			this.rotaProgramada.valor = vr.rovalor ?
				parseFloat(vr.rovalor).toFixed(2) : '';
			this.rotaProgramada.peso = vr.ropeso ?
				parseFloat(vr.ropeso).toFixed(2) : '';
			this.rotaProgramada.km = vr.rokm;
			this.rotaProgramada.cubagem = vr.rocubagem ?
				parseFloat(vr.rocubagem).toFixed(2) : '';
			this.rotaProgramada.custoVeiculo = value.itens ? value.itens[0].vecusto : '';
			this.rotaProgramada.latSaida = pSaida[0];
			this.rotaProgramada.lngSaida = pSaida[1];
			this.rotaProgramada.latRetorno = pRetorno[0];
			this.rotaProgramada.lngRetorno = pRetorno[1];
			this.rotaProgramada.motorista = this.pegaColaborador(vr.romotorista);
			this.rotaProgramada.ajudante = this.pegaAjudante(vr.roajudante);
			this.rotaProgramada.coordenadas = decodePoly.decode(vr.ropolyline);
			let tempo = vr.rotemposegundos ? parseInt(vr.rotemposegundos) : 0;
			this.rotaProgramada.tempo = converteSegundosEmHoras(tempo);
		},

		limparRotaProgramada() {
			this.rotaProgramada.latSaida = 0
			this.rotaProgramada.lngSaida = 0
			this.rotaProgramada.latRetorno = 0
			this.rotaProgramada.lngRetorno = 0
			this.rotaProgramada.veiculo = ''
			this.rotaProgramada.coordenadas = []
			this.rotaProgramada.pontos = []
			this.tabsPag[0].disabled = false
			this.tabsPag[1].disabled = false
		},

		excluirCarga(value, index) {
			this.dadosExcluirCarga.rota = value.rota
			this.dadosExcluirCarga.index = index
			this.$bvModal.show('modal-deleta-carga')
		},

		modalConfirmarCarregamento() {
			this.$bvModal.show('modalConfirmarCarregamento')
		},
		////////// ABA CARGAS //////////
		////////////////////////////////


		////////////////////////////////////
		////////// MAPA PRINCIPAL //////////

		criarMarkersPedidos() {
			this.$refs.refCluster?.clearLayers();
			this.$refs.refClusterX?.clearLayers();
			const mkrs = this.pedidos.draggable
				.filter(e => !e.selecionado && e.show)
				.map(e => e.marker)
			this.$refs.refCluster?.addMarkerBulk(mkrs);
			const mkrsX = this.pedidos.draggable
				.filter(e => e.selecionado)
				.map(e => e.marker)
			this.$refs.refClusterX?.addMarkerBulk(mkrsX);
			this.calculaValoresAux();
		},

		markerFactory() {
			const { pedidos, rota, selecionaPedido, editaPonto, editaPedido, editaParada } = this;
			const PopupMarkerDinamica = Vue.extend(popupMarkerDinamica);
			pedidos.draggable.forEach((pedido) => {
				let popup = new PopupMarkerDinamica({
					propsData: {
						dados: pedido,
						rota: rota
					},
				});
				popup.$mount();
				popup.$on('chamaSelecionaItens', selecionaPedido);
				popup.$on('editaPonto', editaPonto);
				popup.$on('editaPedido', editaPedido);
				popup.$on('editaParada', editaParada);
				pedido.marker.addEventListener('contextmenu', function (e) {
					selecionaPedido(e.target.infoPonto);
				});
				L.DomEvent.disableClickPropagation(popup.$el);
				pedido.marker.bindPopup(popup.$el);
			});
		},

		markerFactoryUnique(dados) {
			const { rota, selecionaPedido, editaPonto, editaPedido, editaParada } = this;
			const PopupMarkerDinamica = Vue.extend(popupMarkerDinamica);
			var pedido = new Pedido(dados)
			let popup = new PopupMarkerDinamica({
				propsData: {
					dados: pedido,
					rota: rota
				},
			});
			popup.$mount();
			popup.$on('chamaSelecionaItens', selecionaPedido);
			popup.$on('editaPonto', editaPonto);
			popup.$on('editaPedido', editaPedido);
			popup.$on('editaParada', editaParada);
			pedido.marker.addEventListener('contextmenu', function (e) {
				selecionaPedido(e.target.infoPonto);
			});
			L.DomEvent.disableClickPropagation(popup.$el);
			pedido.marker.bindPopup(popup.$el);
			this.pedidos.draggable.push(pedido)
		},

		editaPonto(value) {
			this.controleAttPonto = true
			window.open(`#/finder/plus/cadastros/pontos/cadastrar/${value}`, '_blank')
		},

		editaParada(dados) {
			this.clickOpenModalAddParada(dados)
		},

		editaPedido(dados) {
			this.clickOpenModalAddPedido(dados)
		},

		startDrawing() {
			this.desenhando = true;
			this.statusPesquisa = 'blue';
			if (this.freeDrawObject.length > 0) {
				this.freeDrawObject.desenharDesenho();
			}
		},

		stopDrawing() {
			this.desenhando = false;
			this.statusPesquisa = 'white';
			this.freeDrawObject.pararDesenho();
			var coords = this.freeDrawObject.getLatLng();
			this.freeDrawObject.apagarDesenho();
			if (coords && coords.length) {
				this.loadingPanel = true;
				setTimeout(() => {
					this.selecionaItensFreeDraw(coords);
				}, 500);
			}
		},

		startStopDraw() {
			if (this.desenhando) {
				this.arrayRastro = [];
				this.limparRotaProgramada();
				this.limpaInfoMapa();
				this.stopDrawing();
			} else {
				this.startDrawing();
			}
		},

		setCreatedFreeDraw(freeDraw) {
			this.freeDrawObject = freeDraw
		},

		fecharPopUp() {
			this.$refs.mapaRotaManual?.closePopup()
		},

		flyToPedido(pedido) {
			this.fecharPopUp()
			try {
				const latlng = [pedido.polatitude, pedido.polongitude];
				// const zoom = this.$refs.mapaRotaManual.zoom;
				const zoom = 15
				const options = {
					animate: false,
					duration: 0.5,
					easeLinearity: 0.8
				};
				this.$refs.mapaRotaManual.flyTo(latlng, zoom, options);
				pedido.marker.abrirPopup()
			} catch (error) {
				this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
			}
		},

		////////// MAPA PRINCIPAL //////////
		////////////////////////////////////


		////////////////////////////////////
		////////// MÉTODOS GERAIS //////////

		/**
		 * @param {Number} codigo Código do ponto
		 */
		pegaColaborador(codigo) {
			let colab = this.optMotorista.find(e => e.value === codigo)
			return colab ? colab.description : ''
		},

		/**
		 * @param {Number} codigo Código do ponto
		 */
		pegaAjudante(codigo) {
			let colab = this.optAjudante.find(e => e.value === codigo)
			return colab ? colab.description : ''
		},

		/**
		 * @param {Number} codigo Código do colaborador
		 */
		pegaPonto(codigo) {
			let ponto = this.optPonto.find(e => e.value === codigo)
			return ponto ? ponto.loc.split(',') : [0, 0]
		},

		clickTab(value) {
			if (value === 1) {
				this.valueVeiculos.forEach((veiculo) => {
					this.updateVeiculoDisponibilidade(veiculo)
					this.updateVeiculoProgresso(veiculo)
					this.updateVeiculoProgressoCubagem(veiculo)
					this.updateVeiculoTotalPedidos(veiculo)
					this.updateVeiculoTotalDistancia(veiculo)
				})
				this.sortVeiculosByProgresso()
			}
		},

		updateVeiculoDisponibilidade(veiculo) {
			const disp = veiculo.vemaxpeso - this.rotaOsrm.peso
			veiculo.disponivel = disp < 0 ? 0 : disp
			const dispc = veiculo.vecubagem - this.rotaOsrm.cubagem
			veiculo.disponivelcub = dispc < 0 ? 0 : dispc
		},

		updateVeiculoProgresso(veiculo) {
			const maxPeso = parseInt(veiculo.vemaxpeso)
			const calculo = parseFloat(this.rotaOsrm.peso / maxPeso)
			const progresso = isNaN(calculo) || calculo === Infinity ? 0 : calculo * 100
			veiculo.progresso = progresso
		},

		updateVeiculoProgressoCubagem(veiculo) {
			const maxCub = parseInt(veiculo.vecubagem)
			const calculoCub = parseFloat(this.rotaOsrm.cubagem / maxCub)
			const progressocub = isNaN(calculoCub) || calculoCub === Infinity ? 0 : calculoCub * 100
			veiculo.progressocub = progressocub
		},

		updateVeiculoTotalPedidos(veiculo) {
			veiculo.totalpedidos = this.totalPedidos
		},

		updateVeiculoTotalDistancia(veiculo) {
			veiculo.totaldistancia = this.rotaOsrm.distancia
		},

		/**
		 * @description Essa função ordena um array de objetos valueVeiculos com base em
		 * seus atributos progresso e progressocub, dando prioridade aos veículos com
		 * maior progresso.
		 */
		sortVeiculosByProgresso() {
			const hasZeroProgress = (veiculo) => veiculo.progresso === 0 || veiculo.progressocub === 0;
			this.valueVeiculos.sort((a, b) => {
				if (hasZeroProgress(a)) {
					return 1;
				} else if (hasZeroProgress(b)) {
					return -1;
				} else if (a.progresso > b.progresso || a.progressocub > b.progressocub) {
					return -1;
				} else if (a.progresso < b.progresso || a.progressocub < b.progressocub) {
					return 1;
				} else {
					return 0;
				}
			});
		},

		toastShow(titulo, msg, type) {
			this.$bvToast.toast(msg, {
				title: titulo,
				autoHideDelay: 2500,
				variant: type,
			})
		},

		/**
		 * @deprecated Refazer, deve atualizar somente o ponto em edição
		 */
		async atualizaPontos() {
			if (this.controleAttPonto) {
				await this.buscaItensRota()
				this.controleAttPonto = false
			}
		},

		clearSelects() {
			if (typeof this.$refs.refCluster !== 'undefined')
				this.$refs.refCluster.clearLayers()
			if (typeof this.$refs.refRegiao !== 'undefined')
				this.$refs.refRegiao.clearAll()
			if (typeof this.$refs.refMarcador !== 'undefined')
				this.$refs.refMarcador.clearAll()
		},

		colorirMarcadoresSelect() {
			this.optFiltroMarcador.forEach((e) => {
				e.cor = pickColor(e.description)
			})
		},

		modalCopiaRota() {
			this.$bvModal.show('modal-copiar-rota')
		},

		modalIntegracoes() {
			this.$bvModal.show('modal-integracoes')
		},

		/**
		 * @description Essa função faz o download de um arquivo com o nome e conteúdo
		 * especificados.
		 */
		downloadFile(filename, content) {
			const blob = new Blob([content], { type: 'text/plain' });
			const link = document.createElement('a');
			link.setAttribute('download', filename);
			link.setAttribute('href', window.URL.createObjectURL(blob));
			link.click();
		},

		/**
		 * recebe o tempo parado ou 0
		 * calcula o tempo total da rota
		 */
		calculaTempoRotaOsrm(tempo) {
			let tempoMedioParado = parseInt(tempo);
			const paradas = this.getPedidosSelecionados();
			if (paradas.length > 0) {
				for (const pedido of paradas) {
					if (pedido.irtempoparado) {
						tempoMedioParado += parseInt(pedido.irtempoparado ?? 0);
					}
				}
			}
			this.rotaOsrm.tempo = tempoMedioParado;
			this.dadosRotaOsrm.tempoSegundos = tempoMedioParado;
		},

		setTempoParadaPedidos(tempoSegundos = null) {
			if (this.rota.tempoParada) {
				if (!tempoSegundos) {
					tempoSegundos = Duration.fromObject({
						hour: this.rota.tempoParada.split(':')[0],
						minute: this.rota.tempoParada.split(':')[1],
					}).as('seconds')
				}
				this.pedidos.draggable.map(e => {
					if (!e.tempoparadoctrl) {
						e.irtempoparado = tempoSegundos
					}
				})

			}
		},

		/**
		 * @param {array|string} rota
		 * @param {boolean} fly
		 * @param {int} timeout
		 */
		setRastroMapa(rota, fly = true, timeout = 500) {
			const decoded = Array.isArray(rota) ? rota : decodePoly.decode(rota);
			this.arrayRastro = decoded ? Array.from(decoded) : [];
			if (fly && this.arrayRastro.length) {
				this.$refs.mapaRotaManual.flyToBounds(this.arrayRastro);
			}
		},

		/**
		 * @description quando o usuário clica em editar um ponto,
		 * e é redirecionado, essa função adiciona eventos para monitorar
		 * a volta do usuário e recarregar os pontos quando ele voltar p/
		 * mostrar as informações mais atualizadas
		 * @author Lucas Eduardo, alterado por Rafael
		 */
		addVisibilityChangeListener(callback) {
			var hidden = 'hidden';
			var visibilityChange = null;
			if (hidden in document) {
				visibilityChange = 'visibilitychange';
			} else if ((hidden = 'mozHidden') in document) {
				visibilityChange = 'mozvisibilitychange';
			} else if ((hidden = 'webkitHidden') in document) {
				visibilityChange = 'webkitvisibilitychange';
			} else if ((hidden = 'msHidden') in document) {
				visibilityChange = 'msvisibilitychange';
			} else if ('onfocusin' in document) {
				document.onfocusin = document.onfocusout = onchange;
			} else {
				window.onpageshow = window.onpagehide = window.onfocus = window.onblur = onchange;
			}
			document.addEventListener(visibilityChange, onchange);
			function onchange(evt) {
				var evtMap = {
					focus: true,
					focusin: true,
					pageshow: true,
					blur: false,
					focusout: false,
					pagehide: false
				};
				evt = evt || window.event;
				if (evt.type in evtMap) {
					evtMap[evt.type] ? callback() : '';
				} else {
					this[hidden] ? '' : callback();
				}
			}
		},

		/**
		 * ela interpreta como se fosse 3 valores
		 * o primeiro valor, vai ser o ponto de saida
		 * o segundo valor (opcional), vão ser os pontos selecionados
		 * o terceiro valor, vai ser o ponto de retorno
		 * ela pega esse valores, junta eles na ordem,
		 * e devolve para o roteirizarPedidosPorTipo
		 */
		preparaRota() {
			let gerarRota = [];
			if (this.pontoSaida) {
				const pontoSaida = {
					'polatitude': parseFloat(this.latitudeSaida),
					'polongitude': parseFloat(this.longitudeSaida),
					'regiao': 0,
					'peso': 0,
					'nome': this.pontoSaida.description,
					'codigo': this.pontoSaida.value
				};
				gerarRota.push(pontoSaida);
			}
			const selecionados = this.getPedidosSelecionados();
			let pontos = [];
			if (selecionados.length) {
				pontos = selecionados.map(e => {
					return {
						'polatitude': parseFloat(e.polatitude),
						'polongitude': parseFloat(e.polongitude),
						'regiao': e.poregiao,
						'peso': e.irpeso,
						'nome': e.irnome,
						'fixo': e.fixo,
						'codigo': e.pocodigo
					};
				});
			}
			gerarRota.push(...pontos);
			if (this.pontoRetorno) {
				const pontoRetorno = {
					'polatitude': parseFloat(this.latitudeRetorno),
					'polongitude': parseFloat(this.longitudeRetorno),
					'regiao': 0,
					'peso': 0,
					'nome': this.pontoRetorno.description,
					'codigo': this.pontoRetorno.value
				};
				gerarRota.push(pontoRetorno);
			}
			return gerarRota;
		},

		calculaValoresAux() {
			let valores = calculaValoresPedidos(this.getPedidosSelecionados())
			this.rotaOsrm.peso = valores.peso
			this.rotaOsrm.valor = valores.valor
			this.rotaOsrm.volume = valores.volume
			this.rotaOsrm.cubagem = valores.cubagem
		},
		////////// MÉTODOS GERAIS //////////
		////////////////////////////////////


		////////////////////////////////////////
		////////// CONTROLE DE ETAPAS //////////

		/**
		 * Mostrar conteúdo das etapas
		 * @param {number} etapa
		 */
		clickEtapas(etapa) {
			this.controleEtapas[1].opened = etapa === 1 && !this.controleEtapas[1].opened;
			this.controleEtapas[2].opened = etapa === 2 && !this.controleEtapas[2].opened;
			// this.controleEtapas[3].opened = etapa === 3;
		},

		/**
		 * Mostrar conteúdo das etapas e fazer o controle de mostrar e concluir da etapa
		 * Não deve ser chamada na última etapa
		 * @param {number} etapa
		 */
		proximaEtapa(etapa) {
			if (etapa !== 3) {
				this.controleEtapas[etapa - 1].opened = false
				this.controleEtapas[etapa - 1].concluded = true
				this.controleEtapas[etapa].disabled = false
				this.controleEtapas[etapa].opened = true
			}
			if (etapa === 3) {
				this.controleEtapas[etapa - 1].concluded = true
				this.preencherSeletoresEtapa3()
				this.buscaItensRota()
				this.$refs.mapaRotaManual.validateSize()
			}
		},

		preencherSeletoresEtapa3() {
			this.optSeletorMarcador = this.filtro.marcador.length
				? this.optFiltroMarcador.filter(e => this.filtro.marcador.includes(e.value))
				: this.optFiltroMarcador;

			this.optSeletorRegiao = this.filtro.regiao.length
				? this.optFiltroRegiao.filter(e => this.filtro.regiao.includes(e.value))
				: this.optFiltroRegiao;
		},

		limparControleEtapas() {
			this.controleEtapas = {
				1: {
					disabled: false,
					opened: true,
					concluded: false,
				},
				2: {
					disabled: true,
					opened: false,
					concluded: false,
				},
				// 3: {
				// 	disabled: true,
				// 	opened: false,
				// 	concluded: false,
				// },
			}
		},

		limpaInfoMapa() {
			this.rotaOsrm = {
				tempo: 0,
				distancia: 0,
				volume: 0,
				peso: 0,
				valor: 0,
				cubagem: 0,
				nomeRota: '',
			}
		},

		limparCamposEtapas(etapa, todos = true) {
			if (etapa === 2) {
				this.limparControleEtapas()
				this.limparEtapa2(todos)
			}
			if (etapa === 3) {
				this.limparEtapa3()
			}
		},

		limparEtapa2(todos) {
			if (todos) {
				this.optFiltroRegiao = []
				this.optFiltroMarcador = []
			}
			this.limparEtapa3(todos)
		},

		limparEtapa3(todos = true) {
			// this.controleEtapas[3] = {
			// 	disabled: true,
			// 	opened: false,
			// 	concluded: false,
			// }
			if (todos) {
				this.optSeletorRegiao = []
				this.optSeletorMarcador = []
			}
			this.limpaEtapa3Dados()
		},

		limpaEtapa3Dados() {
			this.valueVeiculos = []
			this.valueCargas = []
			this.limparPedidos()
			this.limparMapa()
		},

		limparPedidos() {
			this.pedidos = {
				draggable: [],
				markers: [],
				markersX: [],
			}
		},

		limparMapa() {
			this.arrayRastro = []
			this.desenhoRegioes = []
			if (this.$refs.refCluster) this.$refs.refCluster.clearLayers()
			if (this.$refs.refClusterX) this.$refs.refClusterX.clearLayers()
		},

		/**
		 * @param {int|null} count
		 */
		setTotalPedidos(count = null) {
			if (!count) {
				count = this.getPedidosSelecionados().length
			}
			this.totalPedidos = count ?? 0;
			if (count >= this.limitePedidos) {
				const msg = `Parece que o total de pedidos selecionados atingiu o limite (${this.limitePedidos} pedidos)`;
				this.toastShow('Ops!', msg, 'danger');
			}
		},

		////////// CONTROLE DE ETAPAS //////////
		////////////////////////////////////////


		/////////////////////////////////
		////////// HttpRequest //////////

		/**
		 * @description Responsável por buscar dados e atribuir esses dados a variáveis e
		 * chamar outras funções para continuar o processo de busca de dados.
		 */
		async buscaDadosSelect() {
			this.loadingButtonSelect = true;
			const empresas = [this.rota.empresa];
			const tiposFiltro = ['PIF', 'RE', 'MA', 'MR', 'MAIRI'];
			const dados = await new FiltrosService().dados_filtros(empresas, tiposFiltro);
			if (dados) {
				this.optPonto = dados['PIF'];
				this.optFiltroRegiao = dados['RE'];
				this.optMotorista = dados['MR'];
				this.optAjudante = dados['MA'];
				this.optFiltroMarcador = dados['MAIRI'];
				this.colorirMarcadoresSelect();
				await this.buscaRegioesClientes();
			}
		},

		buscarMarcadores(){
			this.loadingPanel = true;
			this.filtro.marcador = [];
			this.optFiltroMarcador = [];
			this.valueMarcador = [];
			this.optSeletorMarcador = [];
			const obj = {
				"empresa": this.rota.empresa 
			};
			const urlBase = '/roteirizador/rota/manual/buscar/marcadores'

			setTimeout(() => {
				new HttpRequest().Post(urlBase, obj).then((data) => {
					this.optFiltroMarcador = data.data;
					this.optSeletorMarcador = data.data;
					this.$nextTick(() => {
						this.$refs.refSaida.clearAll()
						this.$refs.refRetorno.clearAll()
						this.$refs.refAjudante.clearAll()
						this.$refs.refMotorista.clearAll()
						this.$refs.refFiltroRegiao.clearAll()
						this.$refs.refFiltroMarcador.clearAll()
					})
				}).finally(() => {
					this.loadingPanel = false;
				});
			}, 2000);
		},

		/**
		 * @description Prepara e valida os dados para roteirizar os pedidos. Chama a
		 * função [roteirizarPedidosAux] para fazer a requisição.
		 * @param {int} tipo Tipo de roteirização: 1(default)|2
		 */
		async roteirizarPedidosPorTipo(tipo = 1) {
			let gerarRota = this.preparaRota();
			this.arrayRastro = [];
			this.limparRotaProgramada();
			this.limpaInfoMapa();
			const obedecerOrdenacao = this.showAlertaRotaFixa;
			if (!this.rota.pontoSaida || !this.rota.pontoRetorno) {
				return;
			}
			this.loadingPanel = true;
			let url = '';
			const rotaCurta = gerarRota.length < 503;
			if (rotaCurta) {
				url = `${this.url}rota/tipo/gerar`;
			} else {
				url = `${this.url}roteiriza`;
				gerarRota = gerarRota.map((ponto) => {
					return {
						lat: ponto.polatitude,
						lon: ponto.polongitude,
						codigo: ponto.codigo,
						peso: ponto.irpeso,
						nome: ponto.irnome,
						regiao: ponto.regiao
					};
				});
			}
			const obj = {
				inicio: [gerarRota[0].lat, gerarRota[0].lon],
				pontos: gerarRota,
				velocidadeMedia: Number(this.rota.velocidade),
				obedecerOrdenacao,
				tipo
			};

			await this.roteirizarPedidosAux(url, obj);

		},

		/**
		 * @description Faz a requisição para roteirizar os pedidos selecionados, e trata
		 * os dados de retorno da requisição.
		 * @param {string} url
		 * @param {object} param
		 * @param {array} param.pontos
		 * @param {boolean} param.obedecerOrdenacao
		 * @param {number} param.velocidadeMedia
		 */
		async roteirizarPedidosAux(url, param) {
			try {
				const { data, status } = await new HttpRequest().Post(url, param);
				if (status && data.rota.codigo !== 500) {
					this.arrumaOrdemPontosRoteirizados(data.ponto);
					this.calculaValoresAux();
					this.dadosRotaOsrm = data;
					this.rotaOsrm.distancia = data.distancia;
					this.calculaTempoRotaOsrm(data.tempoSegundos);
					this.setRastroMapa(data.rota);
					this.sortPedidosDraggable()
				}
			} catch (error) {
				this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
			} finally {
				this.loadingPanel = false;
			}
		},

		/**
		 * @description Faz a requisição para gerar um arquivo de retorno e faz download.
		 * @author Rafael
		 */
		async arquivoDeRetorno() {
			try {
				const url = `${this.url}arquivo/retorno/gerar`;
				const body = {
					cliente: this.rota.empresa,
					data: this.rota.data,
				};
				const data = await new HttpRequest().Post(url, body);
				if (data.status) {
					this.downloadFile(data.data.arquivo[0], data.data.arquivo[1]);
					this.buscaItensRota();
				}
			} catch (error) {
				this.loadingPanel = false;
				this.toastShow("Ops!", "Ocorreu um problema não esperado!", "danger");
			}
		},

		/**
		 * @description Faz a requisição para buscar informações de pedidos, cargas e
		 * veículos, com base nos filtros.
		 * @param {object} params
		 * @param {int} params.cliente
		 * @param {int} params.regioes
		 * @param {string} params.dia
		 * @param {string} params.marcador
		 * @param {string} params.dataini
		 * @param {string} params.datafim
		 * @author Rafael
		 */
		async fetchItensRota(params, apenasRota) {
			try {
				const url = `${this.url}itens`;
				const { data } = await new HttpRequest().Post(url, params);
				this.botaoCopiar = true;
				if (apenasRota) {
					const { veiculos, roteirizados } = data.itens;
					const veiculosSemFiltro = veiculos.map((veiculo) => ({ ...veiculo, vemaxpeso: veiculo.vemaxpeso || 0 }));
					// const veiculosSemFiltro = veiculos
					this.valueVeiculos = veiculosSemFiltro;
					this.veiculoSemFiltro = veiculosSemFiltro;
					const hasVeiculos = veiculosSemFiltro.length > 0;
					this.valueCargas = roteirizados;
					const hasCargas = roteirizados.length > 0;
					this.statusBarVeiculos = hasVeiculos ? '' : 'error';
					this.statusBarCargas = hasCargas ? '' : 'error';
				} else {
					const { itens, veiculos, roteirizados } = data.itens;
					const pedidos = itens.map((item) => new Pedido(item));
					const veiculosSemFiltro = veiculos.map((veiculo) => ({ ...veiculo, vemaxpeso: veiculo.vemaxpeso || 0 }));
					// const veiculosSemFiltro = veiculos
					const hasPedidos = pedidos.length > 0;
					const hasVeiculos = veiculosSemFiltro.length > 0;
					const hasCargas = roteirizados.length > 0;
					this.totalPedidos = 0;
					this.pedidos.draggable = pedidos;
					this.valueVeiculos = veiculosSemFiltro;
					this.veiculoSemFiltro = veiculosSemFiltro;
					this.criarMarkersPedidos();
					this.markerFactory();
					this.valueCargas = roteirizados;
					this.statusBarPedido = hasPedidos ? '' : 'error';
					this.statusBarVeiculos = hasVeiculos ? '' : 'error';
					this.statusBarCargas = hasCargas ? '' : 'error';
					this.setTempoParadaPedidos();
				}
			} catch (error) {
				this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
			} finally {
				this.loadingPanel = false;
			}
		},

		/**
		 * @description Faz a requisição para excluir os pedidos selecionados.
		 * @author Rafael
		 */
		async excluirPedidos() {
			this.loadingPanel = true;
			const selecionados = this.getPedidosSelecionados();
			const codigos = selecionados.map((e) => e.ircodigo);
			const url = `${this.url}itens/deletar`;
			const obj = { itens: codigos };
			try {
				const { data, code } = await new HttpRequest().Post(url, obj);
				if (data && code == 202) {
					const msg = "Pedido(s) excluído(s) com sucesso!";
					this.toastShow("Sucesso", msg, "success");
					this.pedidos.draggable = this.pedidos.draggable
						.filter(e => !codigos.includes(e.ircodigo))
					this.criarMarkersPedidos();
					this.setTotalPedidos()
				} else {
					conectionError();
				}
			} catch (error) {
				this.toastShow("Ops!", "Ocorreu um problema não esperado!", "danger");
			} finally {
				this.loadingPanel = false;
			}
		},

		/**
		 * @description Faz a requisição para verificar se o veículo selecionado para ser
		 * carregado já tem carga no dia, ou se algum dos pedidos já foi carregado (essa
		 * validação existe porque duas pessoas podem estar cadastrando ao mesmo tempo).
		 * @param {array} codigosItens
		 * @author Rafael
		 */
		async verificaVeiculoCarregado(codigosItens) {
			try {
				this.loadingPanel = true;
				this.avisoItemCarregado = '';
				this.avisoVeiculoCarregado = '';
				const url = `${this.url}veiculo/verificar`;
				const body = {
					placa: this.placaCarregamento,
					cliente: this.rota.empresa,
					codigosItens,
					dataCarga: this.dataCarga
				};
				const { data, status } = await new HttpRequest().Post(url, body);
				if (!status) {
					this.toastShow('Ops!', 'Não foi possível concluir a operação!', 'danger');
					return;
				}
				const { verificacao } = data;
				if (verificacao.cargaDia) {
					const msg = `O veículo com a placa ${this.placaCarregamento} já possui uma carga para o dia ${this.dataCarga}.`;
					this.avisoVeiculoCarregado = msg;
					this.modalConfirmarCarregamento();
					this.loadingPanel = false;
				} else if (verificacao.itens) {
					const msg = 'Um ou mais pedidos já estão associados a uma rota. Recarregue a página e monte a rota novamente!';
					this.avisoItemCarregado = msg;
					this.modalConfirmarCarregamento();
					this.loadingPanel = false;
				} else {
					await this.carregarVeiculo(codigosItens);
				}
			} catch (error) {
				this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
				this.loadingPanel = false;
			}
		},

		/**
		 * @description Faz a validação dos dados da rota e dos pedidos antes de chamar a
		 * função [verificaVeiculoCarregado].
		 * @param {object} value
		 * @param {int} value.tempoSegundos
		 * @param {string} value.veplaca
		 * @listens carregarVeiculo aba veículos
		 * @author Rafael
		 */
		async verificarCarregamento(value) {
			if (!this.dadosRotaOsrm.tempoSegundos) {
				this.toastShow('Ops!', 'Você precisa roteirizar os pontos!', 'danger')
				return;
			}
			const itensSelecionados = this.getPedidosSelecionados()
			if (itensSelecionados.length > 0) {
				this.placaCarregamento = value.veplaca
				let codigos = itensSelecionados.map(e => e.ircodigo)
				await this.verificaVeiculoCarregado(codigos)
			} else {
				this.toastShow('Ops!', 'Você precisa selecionar pedidos!', 'danger')
			}
		},

		/**
		 * @description Faz validação, prepara dos dados e faz requisição para carregar o
		 * veículo com os pedidos selecionados.
		 * @param {array} codigosItens
		 * @author Rafael
		 */
		async carregarVeiculo(codigosItens) {
			this.avisoVeiculoCarregado = '';
			this.avisoItemCarregado = '';
			// this.loadingPanel = true;

			const itensSelecionados = this.getPedidosSelecionados();
			if (!itensSelecionados.length) {
				this.toastShow('Ops!', 'Você precisa selecionar pedidos!', 'danger');
				return;
			}
			if (!codigosItens) {
				codigosItens = itensSelecionados.map(item => item.ircodigo);
			}
			const itens = itensSelecionados.map(item => ({
				'ircodigo': item.ircodigo,
				'irordem': item.irordem,
				'irpeso': item.irpeso,
				'ircubagem': item.ircubagem,
				'irqtde': item.irqtde,
				'irvalor': item.irvalor,
				'irtempoprevisto': item.duracao,
				'irdistancia': item.distancia,
				'irmarcador': item.irmarcador,
				'irinijanelaentrega': item.irinijanelaentrega,
				'irfimjanelaentrega': item.irfimjanelaentrega,
				'irtempoparado': item.irtempoparado,
				'marcadores': item.marcadores
			}));

			const body = {
				'kms': (this.rotaOsrm.distancia / 1000).toFixed(2),
				'data': this.rota.data,
				'pontoIni': this.rota.pontoSaida,
				'pontoFim': this.rota.pontoRetorno,
				'placa': this.placaCarregamento,
				'cliente': this.rota.empresa,
				'itens': itens,
				'infoRastro': this.dadosRotaOsrm,
				'cor': geraCor(),
				'datahoraFinalizacao': this.rota.dataHoraLimite,
				'tempoParada': this.rota.tempoParada,
				'tempoHora': this.rota.hora,
				'velMedia': this.rota.velocidade,
				'motorista': this.rota.motorista,
				'ajudante': this.rota.ajudante,
				'codigosItens': codigosItens,
				'nomeRota': this.rota.nomeRota
			};

			const url = `${this.url}veiculo/carregar`;
			try {
				const data = await new HttpRequest().Post(url, body);
				if (!data.status || data.data.status === 500) {
					conectionError();
				} else if (data.data.itens) {
					const msg = "Um ou mais pedidos já estão associados a uma rota. Recarregue a página e monte a rota novamente!";
					this.avisoItemCarregado = msg;
					this.modalConfirmarCarregamento();
				} else {
					this.toastShow('Sucesso', 'Veículo carregado com sucesso!', 'success');
					this.modelTab = 2;
					await this.buscaItensRota();
				}
			} catch (error) {
				this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
			} finally {
				this.loadingPanel = false;
			}
		},

		/**
		 * @description Faz validação, prepara dos dados e faz requisição para excluir
		 * uma carga/rota.
		 * @listens confirmaExclusao modal de confirmação de exclusão
		 * @author Rafael
		 */
		async confirmarExcluirCarga() {
			if (!this.dadosExcluirCarga.rota || this.dadosExcluirCarga.index === '') {
				this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger')
				return
			}
			try {
				this.loadingPanel = true
				const url = `${this.url}carga/excluir`
				const obj = { rota: this.dadosExcluirCarga.rota }
				const dados = await new HttpRequest().Post(url, obj)
				if (dados.status && parseInt(dados.data.status.codigo) === 200) {
					this.valueCargas.splice(this.dadosExcluirCarga.index, 1)
				} else {
					conectionError()
				}
			} catch (error) {
				this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger')
			} finally {
				this.loadingPanel = false
			}
		},

		/**
		 * @description Faz validação das informações, prepara dos dados e faz requisição
		 * para gerar um arquivo e retorna para mostrar no navegador.
		 * uma carga/rota.
		 * @listens click botão aba pedidos "Imprimir"
		 * @author Rafael
		 */
		async imprimirRota() {
			try {
				this.loadingBtnImprimirRota = true;
				const codRotas = this.valueCargas.map(carga => carga.rota);
				const url = `${this.url}rota/exportar`;
				const obj = {
					cliente: this.rota.empresa,
					data: this.rota.data,
					codigo_rota: codRotas,
					tipo: 'pdf'
				};
				const { data, status } = await new HttpRequest().Post(url, obj);
				if (status && data) {
					const root = process.env.VUE_APP_ROOT;
					window.open(`${root}/${data.arquivo}`);
				}
			} catch (error) {
				this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
			} finally {
				this.loadingBtnImprimirRota = false;
			}
		},

		/**
		 * @description Prepara dos dados e faz requisição para buscar regiões do cliente.
		 * A requisição retorna um array de objetos de regiões, exemplo:
		 * [{rcregiao, recor, rclatitude, rclongitude}].
		 * @author Rafael
		 */
		async buscaRegioesClientes() {
			try {
				const url = `${this.url}regioes`;
				const obj = { cliente: this.rota.empresa };
				const dadosRegioes = await new HttpRequest().Post(url, obj);
				this.coordenadasRegioes = dadosRegioes.data.rota;
				for (const key in this.coordenadasRegioes) {
					const regiao = this.coordenadasRegioes[key];
					const codigo = regiao[0].rcregiao;
					const cor = regiao[0].recor;
					const latLng = regiao.map(e => new LatLng(e.rclatitude, e.rclongitude));
					const regiaoObj = new Regiao(codigo, cor, latLng);
					this.listaDeRegioes.set(codigo, regiaoObj);
				}
			} catch (error) {
				this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
			} finally {
				this.loadingButtonSelect = false;
			}
		},
		////////// HttpRequest //////////
		/////////////////////////////////

	}
})
</script>

<style lang="scss">
@import "./RotaManual.scss";
</style>
