<template>
    <panelEagle id='analiseColaborador' :loading="loaidngPanel">
            <div class="col-sm-12 row divDontPrint">
                <div class="col-sm-6 nopadding">
                    <titulo
                        titulo='Análise colaborador'
                        :icon='mdiFinance'
                    />
                </div>
                <div class="col-sm-6 divButtonsBasic divDontPrint">
                    <basicButtonsRelatoriosEIG 
                        :disabledDropdown  ="dadosRelatorio.length == 0 || temDados.length == 0 ? true : false"
                        :disabled          ="$v.$invalid"
                        :loading           ='loadingExportar'
                        @exportarRelatorio ="exportarRelatorio" 
                        @gerarRelatorio    ="gerarRelatorio">
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-3 nopadding">
                    <inputRangeWithLimit
                        class="pr-0"
                        name='pickerPeriodo'
                        opens='right'
                        :value='dataAnalise'
                        @changeInput="changeData"
                    />
                </div>
                <div class="col-sm-3 nopadding">
                    <selectAll
                        nameForRadio  ='seletorEmpresa'
                        :isMultiple   ='true'
                        :hasSelectAll ='true'
                        :labels       ='[{"description":"Empresas*"}]'
                        :optionsArray ='optSelectEmpresas'
                        :selected     ='empresaAnalise'
                        @changeSelect ='changeEmpresa'
                    />
                </div>
                <div class="col-sm-2 nopadding">
                    <selectAll
                        nameForRadio  ='seletorColaboradores'
                        class         ="pl-0"
                        ref           ='selectColaboradores'
                        :isMultiple   ='true'
                        :hasSelectAll ='true'
                        :labels       ='[{"description":"Colaboradores*"}]'
                        :optionsArray ='optSelectColaboradores'
                        :disabled     ='$v.empresaSelecionada.$invalid'
                        :selected     ='colaboradorAnalise'
                        :loading      ='loadingColaboradores'
                        @changeSelect ='changeColaboradores'
                    />
                </div>
                <div class="col-sm-4 pl-0 pr-0 pt-4">
                    <buttonsFilter 
                        class="pl-0"
                        :arrayButtons         ="optFiltroStatus" 
                        :zeroValue            ='filtroStatus'
                        @buttonsFiltersChange ='mudaFiltroStatus'>
                    </buttonsFilter>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <!-- Relatório -->
            <tableRelatorio id="relatorioAnaliseColaborador">
                <slot slot="tbody">
                    <statusInformation
                        v-if="dadosRelatorio.length == 0 || temDados.length == 0"
                        typeBar="div" 
                        :colspanForTd=12
                        :statusBar="statusBar">
                    </statusInformation>
                    <template v-for="(colaborador, c) in dadosRelatorio"
                        v-else>
                        <tr :key="'colaborador_'+c"
                            v-if="filtroStatus ? colaborador.classe == filtroStatus : true"
                            class="badgetr backColorTdPDF">
                            <td class="nonpadding badgePadding pl-0">
                                <div class="newbadge row pb-5">
                                    <div class="col-sm-7 pt-2 pl-1">{{c}}</div>
                                    <div :class="'col-sm-5 tipoClasse pt-2 pl-2 '+colaborador.classe">
                                        Classe - {{colaborador.classe}} {{colaborador.pontos}} pontos
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <template v-for="(evento, e) in colaborador.eventos">
                            <tr :key="'evento_'+e+evento.plcodigo"
                                v-if="filtroStatus ? colaborador.classe == filtroStatus : true"
                                v-b-toggle="evento.plcodigo+'collapse'" 
                                @click="evento.ativo = !evento.ativo"
                                class="trDadosTabela row">
                                    <div class="pl-5 col-sm-6">
                                        <b-icon
                                            :id="evento.ativo"
                                            class="h5 m-1 pb-1" 
                                            shift-v="-6"
                                            shift-h="0"
                                            style="font-weight: bold;"
                                            :icon="evento.ativo ? 'chevron-down' : 'chevron-right'"/>
                                        <span>{{e}}</span>
                                    </div>
                                    <div class="col-sm-6 pr-5 pt-2" align="right">
                                        <span>Total: {{somaPontuacao(evento.pontuacao)}}</span>
                                    </div>
                            </tr>
                            <div :key="'dados_'+e+evento.plcodigo"
                                v-show="filtroStatus ? colaborador.classe == filtroStatus : true">
                                <b-collapse :id="evento.plcodigo+'collapse'" class="col-sm-12 nopadding">
                                    <template>
                                        <tableSimples class="tabelaSimples">
                                            <br>
                                            <thead>
                                                <tr>
                                                    <th>Data</th>
                                                    <th>Hora</th>
                                                    <th>Placa</th>
                                                    <th>Info</th>
                                                    <th>Endereço</th>
                                                    <th>Pontos</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(dados, d) in evento.dados"
                                                    :key="'dados_'+d+dados.plcodigo">
                                                    <td>{{transformaData(dados.pldatahoraevento)}}</td>
                                                    <td>{{transformaHora(dados.pldatahoraevento)}}</td>
                                                    <td>
                                                        <span v-for="(placas, p) in separaPlacas(dados.plplaca)"
                                                            :key='p'
                                                            class="col-sm-12 nopadding spanPlacas">
                                                            {{placas}} 
                                                        </span>
                                                    </td>
                                                    <td>{{dados.plinfo}}</td>
                                                    <td id="endereco">{{dados.plendereco}}</td>
                                                    <td>{{dados.plpontos}}</td>
                                                </tr>
                                            </tbody>
                                        </tableSimples>
                                    </template>
                                </b-collapse>
                            </div>
                        </template>
                    </template>
                </slot>
            </tableRelatorio>
    </panelEagle>
</template>
<script>
    import Vue from 'vue'
    import {mdiThumbUpOutline, mdiFinance } from '@mdi/js'
    import { EmpresasService } from '@/Services/auth/Empresas.service'
    import { FiltrosService } from "@/Services/filtros/filtros.Service"
    import { required } from 'vuelidate/lib/validators' 
    import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
    import { DateTime } from "luxon"
    import { mapGetters } from 'vuex'

    export default {
        name:'analiseGeral',
        components:{
            selectAll                 : require('@/components/Atom/Select/SelectAll').default,
            inputRangeWithLimit       : require('@/components/Atom/Datas/InputRangeWithLimit').default,
            panelEagle                : require('@/components/Atom/Panel/PanelEagle').default,
            titulo                    : require('@/components/Atom/Header/Titulo').default,
            tableRelatorio            : require('@/components/Atom/Table/TableRelatorio').default,
            tableSimples              : require('@/components/Atom/Table/TableSimples').default,
            buttonsFilter             : require('@/components/Atom/Buttons/ButtonsFilters').default,
            basicButtonsRelatoriosEIG : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
            statusInformation         : require('@/components/Atom/StatusInformation/StatusInformation').default,
        },

        validations:{
            empresaSelecionada     : { required },
            colaboradorSelecionado : { required },
        },

        data() {
            return {
                optSelectEmpresas: new EmpresasService().Get(),
                loaidngPanel: false,
                mdiFinance: mdiFinance,
                mdiThumbUpOutline : mdiThumbUpOutline,
                link:'/gestao/desempenho/relatorios/analise/colaborador/',
                actived: false,
                statusBar:'info',
                optFiltroStatus:[
                    {value:'A', text:'A', title:'A', width: '24%', extraClass: 'greenButton', isSelected:false},
                    {value:'B', text:'B', title:'B', width: '24%', extraClass: 'blueButton', isSelected:false},
                    {value:'C', text:'C', title:'C', width: '24%', extraClass: 'orangeButton', isSelected:false},
                    {value:'D', text:'D', title:'D', width: '24%', extraClass: 'redButton', isSelected:false},
                ],
                optSelectColaboradores : [],
                filtroStatus           : '',
                dataSelecionada        : '',
                empresaSelecionada     : [],
                colaboradorSelecionado : [],
                loadingColaboradores   : false,

                //Vindo do relatório Análise
                dataAnalise : '',
                colaboradorAnalise : [],
                empresaAnalise     : [],
                empresaFacilitador : [],
                dadosRelatorio         : [],
                plcodigoInformado      : 0,
                temDados               : [],
                loadingExportar        : [false, false, false],
            }
        },

        methods:{
            ...mapGetters(['getMaster']),

            changeData(data){
                this.dataSelecionada = data
                this.dadosRelatorio = []
            },

            changeEmpresa(empresa){
                this.empresaSelecionada = empresa
                this.buscaColaborador()
                this.dadosRelatorio = []
                this.$refs.selectColaboradores.clearAll()
            },

            changeColaboradores(colaboradores){
                this.colaboradorSelecionado = colaboradores
                this.dadosRelatorio = []
            },

            buscaColaborador(){
                this.loadingColaboradores = true
                new FiltrosService().dados_filtros(this.empresaSelecionada, ["C"])
                    .then((dados) => {
                        this.optSelectColaboradores = dados.C
                        this.loadingColaboradores = false
                    });
            },

            gerarRelatorio(){
                this.loaidngPanel = true
                this.dadosRelatorio = []
                new HttpRequest().Post(this.link+'relatorio', 
                        {'empresa'       : this.empresaSelecionada,
                         'colaboradores' : this.colaboradorSelecionado,
                         'data'          : this.dataSelecionada})
                    .then((dados)=>{
                        if(dados.code == 200){
                            if(dados.data.dados.length == 0){
                                this.statusBar = 'error'
                                this.loaidngPanel = false
                            } else{
                                this.dadosRelatorio = dados.data.dados
                                this.loaidngPanel = false
                                this.verificaStatusRelatorio()
                            }
                        } else {
                            this.statusBar = 'error2'
                            this.loaidngPanel = false
                        }
                    })
            },

            /**
             * @description Método para transformar a data no formato utilizado,
             *  é utilizado na coluna de data do relatório
             * @param  {data} data - data em formato americano
             * @return {data}      - data formatada para o padrão utilizado
             * @author Vitor Hugo 🐨
            */
            transformaData(data){
                return DateTime.fromFormat(data, 'yyyy-LL-dd TT').toFormat('dd/LL/yyyy')
            },

            /**
             * @description Método para transformar a hora no formato utilizado,
             *  é utilizado na coluna de data do relatório
             * @param  {data} hora - data em formato americano
             * @return {hora}      - hora formatada para o padrão utilizado
             * @author Vitor Hugo 🐨
            */
            transformaHora(hora){
                return DateTime.fromFormat(hora, 'yyyy-LL-dd TT').toFormat('TT')
            },

            /**
             * @description Método para somar  pontuação de um evento,
             *  é utilizada na linha do evento
             * @param  {array}  pontuacao - todos os pontos daquele evento
             * @return {number} retorno   - soma das pontuações do evento
             * @author Vitor Hugo 🐨
            */
            somaPontuacao(pontuacao){
                var retorno = 0
                pontuacao.forEach(element => {
                    retorno += element
                });
                return retorno
            },

            /**
             * @description Método para quando a variável trazer mais de uma placa 
             *  fazer a separação dentro de uma array,
             *  é utilizada na coluna de placa do relatório
             * @param  {string} placas  - todos os pontos daquele evento
             * @return {array}  retorno - placas separadas em posições na array
             * @author Vitor Hugo 🐨
            */
            separaPlacas(placas){
                var placa = placas?.split(' ') ?? []
                return placa
            },

            exportarRelatorio(type){
                var root =  process.env.VUE_APP_ROOT
                this.ativaRodinha(type)
                new HttpRequest().Post(this.link+'exportar', 
                    {'tipo'  : type, 
                    'dados'  : this.dadosRelatorio,
                    'filtro' : this.filtroStatus})
                    .then((dados)=>{
                        window.open(root+'/'+dados.data.local)
                        this.ativaRodinha()
                })
            },

            /**
             * @description Método para verificar se existem dados quando selecionado um filtro,
             *  caso não tenha dados não é atribuido valor a variável temDados e a statusBar 
             *  recebe error
             * @author Vitor Hugo 🐨
            */
            verificaStatusRelatorio(){
                this.temDados = []
                for (const key in this.dadosRelatorio) {
                    if(this.filtroStatus == this.dadosRelatorio[key].classe ||
                       this.filtroStatus == undefined || this.filtroStatus == ''){
                        this.temDados.push(1)
                    }
                }
                if(this.temDados.length == 0 && this.filtroStatus != undefined){
                    this.statusBar = 'error'
                    // Deixa todos os chevrons como false, para não ficar invertido no relatório
                    for (const key in this.dadosRelatorio) {
                        for (const k in this.dadosRelatorio[key].eventos) {
                            this.dadosRelatorio[key].eventos[k].ativo = false
                        }
                    }
                }
            },

            ativaRodinha(tipo=null){
                if(tipo=='pdf'){
                    this.loadingExportar = Array(true, false, false)
                }else if(tipo=='xls'){
                    this.loadingExportar = Array(false, true, false)
                }else if(tipo=='csv'){ 
                    this.loadingExportar = Array(false, false, true)
                }else{
                    this.loadingExportar = Array(false, false, false)
                }
            },

            mudaFiltroStatus(value){
                if(value != undefined){
                    if(this.filtroStatus == value[0]){
                        this.filtroStatus = ''
                        this.optFiltroStatus[0].extraClass = 'greenButton'
                        this.optFiltroStatus[1].extraClass = 'blueButton'
                        this.optFiltroStatus[2].extraClass = 'orangeButton'
                        this.optFiltroStatus[3].extraClass = 'redButton'
                    } else{
                        this.filtroStatus = value[0];
                        switch (value[0]) {
                            case 'A':
                                this.optFiltroStatus[0].extraClass = 'borderGreenButton'
                                this.optFiltroStatus[1].extraClass = 'blueButton'
                                this.optFiltroStatus[2].extraClass = 'orangeButton'
                                this.optFiltroStatus[3].extraClass = 'redButton'
                                break;
        
                            case 'B':
                                this.optFiltroStatus[0].extraClass = 'greenButton'
                                this.optFiltroStatus[1].extraClass = 'borderBlueButton'
                                this.optFiltroStatus[2].extraClass = 'orangeButton'
                                this.optFiltroStatus[3].extraClass = 'redButton'
                                break;
        
                            case 'C':
                                this.optFiltroStatus[0].extraClass = 'greenButton'
                                this.optFiltroStatus[1].extraClass = 'blueButton'
                                this.optFiltroStatus[2].extraClass = 'borderOrangeButton'
                                this.optFiltroStatus[3].extraClass = 'redButton'
                                break;
        
                            case 'D':
                                this.optFiltroStatus[0].extraClass = 'greenButton'
                                this.optFiltroStatus[1].extraClass = 'blueButton'
                                this.optFiltroStatus[2].extraClass = 'orangeButton'
                                this.optFiltroStatus[3].extraClass = 'borderRedButton'
                                break;
                        
                            default:
                                break;
                        }
                    }
                    this.verificaStatusRelatorio()
                } else {
                    this.filtroStatus = ''
                    this.verificaStatusRelatorio()
                }
            },

            /**
             * @description Método para inserir os filtros vindo do relatório de análise geral,
             *      é pego o optSelectEmpresas e optSelectColaboradores para retirar os objetos selecionados
             * @example dataAnalise        - data para o input de data
             * @example empresaAnalise     - empresa para o select de empresas
             * @example colaboradorAnalise - colaborador para o select de colaboradores
             * @author Vitor Hugo 🐨
            */
            filtrosAnaliseGeral(){
                this.dataAnalise = this.$route.params.data
                var colaborador  = this.$route.params.colaborador
                var empresa      = this.$route.params.empresa
                new FiltrosService().dados_filtros(empresa, ["C"])
                    .then((dados) => {
                        this.optSelectColaboradores = dados.C
                        this.loadingColaboradores = false
                        var empresasSelecionadas = this.optSelectEmpresas.filter(element=>{
                            empresa.forEach(el => {
                                if(element.value == el)
                                    this.empresaAnalise.push(element)
                            });
                        })
                        this.colaboradorAnalise = this.optSelectColaboradores.filter(element=>{
                            if(element.value == colaborador)
                                return element
                        })
                    });
            }
        },

        mounted(){
            if(this.$route.params.empresa != undefined){
                this.filtrosAnaliseGeral()
            }
            if(!this.getMaster()){
                this.empresaAnalise = this.optSelectEmpresas
            }
        }
    };
</script>
<style lang="scss">
    #analiseColaborador{
        #endereco{
            width: 40% !important;
        }
        #relatorioAnaliseColaborador{
            .badgetr{
                .badgePadding{
                    .newbadge{
                        min-width: 50%;
                        div{
                            font-size: 14px;
                            font-weight: bold;
                        }
                        .tipoClasse{
                            display: inline;
                            background: #bae9a8;
                            height: 29px;
                            color: #000;
                        }
                        .A{
                            background: #bae9a8;
                        }
                        .B{
                            background: #94bceb;
                        }
                        .C{
                            background: #ecdd93;
                        }
                        .D{
                            background: #f39e9e;
                        }
                    }
                }
            }
            .trDadosTabela{
                text-align: left;
                height: 40px;
                padding-top: 5px;
                span{
                    vertical-align: middle;
                }
            }
            .tabelaSimples{
                thead{
                    tr{
                        th{
                            padding-left: 10px !important;
                            padding-top: 10px !important;
                        }
                    }
                }
                tbody{
                    tr{
                        background: #fff;
                        border-bottom: 1px solid #dee2e6;
                        td{
                            vertical-align: middle;
                            .spanPlacas{
                                display: block;
                            }
                        }
                    }
                }
            }
        }
        .greenButton{
            background: #bae9a8;
        }
        .orangeButton{
            background: #ecdd93;
        }
        .redButton{
            background: #f39e9e;
        }
        .blueButton{
            background: #94bceb;
        }
        .borderGreenButton{
            background: #bae9a8;
            color: #000;
            border: 2.3px solid #000;
        }
        .borderOrangeButton{
            background: #ecdd93;
            color: #000;
            border: 2.3px solid #000;
        }
        .borderRedButton{
            background: #f39e9e;
            color: #000;
            border: 2.3px solid #000;
        }
        .borderBlueButton{
            background: #94bceb;
            color: #000;
            border: 2.3px solid #000;
        }
    }
</style>