Esse componente funciona mais p/ organizar a tela em si, e ficar mais separado por finalidade.
Todos os eventos e props são bem simples
props:
    data: a data que o seletor deve começar.
    mtcodigo: o código do funcionário
    eventoDsr: Se existe ou não uma DSR nessa data
eventos:
    rmv-dsr - botão de remover dsr
    adc-dsr - botão de adicionar dsr
    nova-data - seletor de data
<template>
    <div id='dsrManual'>
        <div class='d-flex justify-content-center'>
            <div class='col-6 mt-2'>
                <input-single-day
                label=''
                :value='mudaFormatoDaMerdaDaData(data)'
                name='data-dsr-manual'
                @changeInput='mudaData'/>
            </div>
        </div>
        <div class='d-flex justify-content-around mt-5'>
            <simpleButton
            text='Adicionar DSR'
            width='150px'
            type='aqua-marine'
            :disabled='eventoDsr'
            event='click'
            :icon='add'
            @click='addDsr'/>
            <simpleButton
            text='Remover DSR'
            width='150px'
            type='redish'
            :disabled='!eventoDsr'
            event='click'
            :icon='minus'
            @click='rmvDsr'/>
        </div>
    </div>
</template>

<script>
import { DateTime } from 'luxon'
import { mdiPlus, mdiMinus} from '@mdi/js'
export default {
    name:'DsrManual',
    components:{
        inputSingleDay: require('@/components/Atom/Datas/InputSingleDay').default,
		simpleButton  : require('@/components/Atom/Buttons/SimpleButton').default,
    },
    props:{
        data:{
            type: String,
            required:true
        },
        mtcodigo:{
            type: [String, Number],
            required:true
        },
        eventoDsr:{
            type: Boolean,
            required:true
        }
    },
    data(){
        return {
            add:mdiPlus,
            minus:mdiMinus,
        }
    },
    methods:{
        rmvDsr(){
            this.$emit('rmv-dsr', {mtcodigo: this.mtcodigo, data: this.data})
        },
        addDsr(){
            this.$emit('adc-dsr', {mtcodigo: this.mtcodigo, data: this.data})
        },
        mudaFormatoDaMerdaDaData(data){
            let luxonData = DateTime.fromISO(data)
            return luxonData.toFormat('dd/LL/yyyy');
        },
        mudaData(novaData){
            this.$emit('nova-data', {mtcodigo:this.mtcodigo, data:novaData})
        }
    }
}
</script>

<style lang="scss" scoped>
#dsrManual{}
</style>