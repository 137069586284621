/**
    Input multiplo, com botoes para adionar e remover os campos

    -> Propriedades:
        1 - qtBnt - Array de objetos - EX:quantidadeDeBotoes:[{value:'pipoca'}, {value:'bala de menta'}],
            1.1 - Essa prop precisa ter no minimo 1 elemento, pode ser com valor vazio - EX: [{value:''}].
                  Caso tu não colocar o botão só não aparece e da erro no console
        2 - hasMask - Boolean - tem mascara? - EX: true/false
        3 - mask - Array - array de opções de mascara seguindo os tokens do InputSimple - EX: ['###.##.###']
        4 - label - String - Descricao do input/Legenda que fica em cima - EX: 'Telefones'
        9 - type - Tipo do input - Ex: 'password, text, time' - default  = text

    -> Slot:
        Sem slot

    -> Eventos:
        1 - changeInput - Emite quando compoente é alterado (trocar valor do input, ou clicou em algum botão)
 */
<template>
    <div class="div-grande">
        <div class="col-sm-12 nopadding row">
            <span class="col-sm-12 nopadding alinhar-titulo">{{label}}</span>
        </div>
        <div class="scroll">
            <div v-for="(button, index) in qtBnt" v-bind:key="index">
                <div  class="col-sm-12 nopadding row ">
                    <inputSimple 
                        id='idInput'
                        :type='type'
                        :hasMask='hasMask'
                        :mask='mask'
                        v-bind='$attrs'
                        ref='refInputMenos'
                        class="col-xl-10 nopadding"  
                        @changeInput='changeInputMultiple(button, index)'/>
                    <simpleButton
                        id='idBotao'
                        v-if='index == 0'
                        event  = 'click'
                        @click ='clickMais(button, index)'
                        type   ='blue'
                        class  ="col-xl-2 nopadding"
                        :icon   ='mdiPlus'/>
                    <simpleButton
                        id='idBotao'
                        v-else
                        event  = 'click'
                        @click ='clickMenos(button, index)'
                        type   ='orange'
                        class  ="col-xl-2 nopadding"
                        :icon   ='mdiClose'/>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
import Vue from 'vue'
import {mdiPlus, mdiClose} from '@mdi/js'
import { Watch } from 'vue-property-decorator'
export default Vue.extend({
    components:{
        'inputSimple'  : require('@/components/Atom/Inputs/InputSimple').default,
        'simpleButton' : require('@/components/Atom/Buttons/SimpleButton').default,
    },
    inheritAttrs : false,
    props:{
        hasMask: {
            type: Boolean,
            default: false
        },
        mask: {
            type: Array,
            default: function(){return []},
        },
        label: {
            type: String,
            default: '',
        },
        qtBnt:{
            type : Array,
            required:true,
        },
        type:{
            type: String,
            default: 'text'
        },
    },
    data(){
        return{
            mdiPlus:mdiPlus,
            mdiClose:mdiClose,
            refInputMenos:'',
            valueInput : [],
            salvarNome : '',
        }
    },
    methods:{
        clickMais(value, index){
            this.qtBnt.push({value: ''})
            this.$emit('changeInput', this.valueInput);
        },
        clickMenos(value, index){
            this.qtBnt.splice(index, 1)
            this.salvarNome = this.qtBnt[index]
            if(this.salvarNome != undefined && this.salvarNome != ''){
                this.$refs.refInputMenos[index].inputValue = this.salvarNome.value
            }else{
                this.$refs.refInputMenos[index].inputValue = ''
            }
            this.salvarNome = ''
            this.valueInput = this.qtBnt
            this.$emit('changeInput', this.valueInput);
        },
        changeInputMultiple(button, index){
            if(this.$refs.refInputMenos[index].inputValue != ''){
                this.qtBnt[index] = {value:this.$refs.refInputMenos[index].inputValue}
            }
            this.valueInput = this.qtBnt
            this.$emit('changeInput', this.valueInput);
        },
        arrumaValoresIput(index){
            var arrumaValores = this.$refs.refInputMenos[index].inputValue
            this.qtBnt[index] = {value:arrumaValores}
        },
    },
    mounted(){
        this.$nextTick(function(){
            var index = 0
            while(this.qtBnt.length > index){
                this.$refs.refInputMenos[index].inputValue = this.qtBnt[index]['value']
                this.arrumaValoresIput(index)
                index = index+1
            }
        })
    },
    watch:{
        qtBnt:function(){
            this.$nextTick(function(){
                var index = 0
                while(this.qtBnt.length > index){
                    this.$refs.refInputMenos[index].inputValue = this.qtBnt[index]['value']
                    this.arrumaValoresIput(index)
                    index = index+1
                }
            })
        }
    },
})
</script>

<style lang="scss">
    #idInput{
        margin : 0px;
        // border : 1px;
        padding: 0px;
        width  : 100%;
    }
    #idBotao{
        padding: 0px;
        border : 0px;
        margin : 0px;
        width  : 100%;
    }
    .alinhar-titulo{
        text-align: left;
    }
    .scroll{
        z-index: 1000;
        overflow-y: auto;
        max-height: 200px;
    }
    .button{
        width: 40px;
        text-align: center !important;
        padding: 5px;
    }
    
    .icone::before{
        padding: 0 !important;
        margin: 0 !important;
    }
</style>
