<template>
    <panelEagle id='CadastroCargos' :loading='loadingPanelEagle'>
        <div class="col-sm-12  nopadding">
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-6 row ">
                    <tituloPage titulo='Cargos' :icon='mdiAccountCogOutline'>
                    </tituloPage>
                </div>
                <div class="col-sm-6 row divDontPrint">
                    <basicButtonsCadastrosSC 
                        linkCancel="listagemCargos"
                        :disabled='$v.$invalid'
                        tipoCancela="button" 
                        @salvarCadastro="salvarCadastro">
                    </basicButtonsCadastrosSC>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 nopadding row">
               <div class="col-sm-4 nopadding">
                   <selectAll
                        nameForRadio="nameForRadio"
                        :isMultiple="false"
                        :hasSelectAll="false"
                        firstSelected ="EM"
                        :labels="[{'indexDFH':'EM', 'description':'Empresa*'}]"
                        :optionsArray="optEmpresas"
                        :selected="selectedEmpresa"
                        :extraClass   ="{'border border-danger':$v.empresaSelecionada.$anyError}"
                        @close        ="$v.empresaSelecionada.$touch()"
                        @changeSelect='changeSelectEmpresa'
                    />
               </div>
               <div class="col-sm-4 nopadding">
                   <inputSimple
                        type="text"
                        label="Descrição*"
                        :inputClass='{"boder border-danger":$v.valueDescricao.$anyError}'
                        :value="valueDescricao"
                        @blur='$v.valueDescricao.$touch()'
                        @changeInput="changeDescricao"
                    />
               </div>
               <div class="col-sm-4 nopadding">
                   <buttonsFilters
                        label="Status*" 
                        :onlyOneSelected='true'
                        :arrayButtons='filtroStatus'
                        @buttonsFiltersChange='changeFiltros'>
                    </buttonsFilters>
               </div>
               <div class="col-sm-4 nopadding mt-2">
                <selectAll
                    ref="refDepartamentos"
                    nameForRadio="departamentos"
                    :isMultiple="false"
                    :hasSelectAll="false"
                    firstSelected ="D"
                    :labels="[{'indexDFH':'D', 'description':'Departamentos*'}]"
                    :optionsArray="optDepartamentos"
                    :selected="selectedDepartamentos"
                    :disabled="$v.empresaSelecionada.$invalid"
                    :loading="loadingDepartamentos"
                    :extraClass   ="{'border border-danger':$v.departamentosSelecionados.$anyError}"
                    @close        ="$v.departamentosSelecionados.$touch()"
                    @changeSelect='changeSelectDepartamentos'
                />
               </div>
            </div>
            <requiredFields/>
            <div class="col-sm-12"><hr></div>
        </div>
    </panelEagle>
</template>
<script lang="js">
import { EmpresasService } from '@/Services/auth/Empresas.service';
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { required } from 'vuelidate/lib/validators'
import Vue from 'vue';
import {mdiAccountCogOutline,mdiCheckBold, mdiCloseThick, mdiAlert, mdiPlus } from '@mdi/js'
import { conectionError }  from '@/Services/Helpers/swellHeper'
import { FiltrosService }  from '@/Services/filtros/filtros.Service'
export default Vue.extend({
	name: 'CadastroCargos',
	components:{
		'selectAll'      : require('@/components/Atom/Select/SelectAll').default,
		'panelEagle'     : require('@/components/Atom/Panel/PanelEagle').default,
		'tituloPage'     : require('@/components/Atom/Header/Titulo').default,
		'requiredFields' : require('@/components/Atom/Footer/RequiredFields').default,
		'buttonsFilters' : require('@/components/Atom/Buttons/ButtonsFilters').default,
		'inputSimple'    : require('@/components/Atom/Inputs/InputSimple').default,
		'basicButtonsCadastrosSC': require(
			'@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
	},
	validations:{
		empresaSelecionada : { required },
		valueDescricao     : { required },
		departamentosSelecionados : { required },
	},
	data(){
		return{
			mdiAccountCogOutline: mdiAccountCogOutline,
			loadingPanelEagle : false,
			optEmpresas: new EmpresasService().Get(),
			url : '/checkpoint/cadastros/cargos/',
			filtroStatus:[
				{'value':'A',  'width': '48.5%', 'text': 'Ativos',   'isSelected':true,  icon:mdiCheckBold},
				{'value':'I',  'width': '48.5%', 'text': 'Inativos', 'isSelected':false, 'icon':''},
			],
			selectedEmpresa    : [],
			empresaSelecionada : [],
			valueDescricao     : '',
			valueFiltros       : [],
			id                 : '',
			optDepartamentos   : [],
			selectedDepartamentos : [],
			departamentosSelecionados : [],
			loadingDepartamentos : false,
		}
	},
	methods:{
		changeSelectEmpresa(empresa){
			this.$refs.refDepartamentos.clearAll()
			this.empresaSelecionada = empresa
			if(empresa.length > 0)
				this.buscaDepartamentos(empresa)
		},

		changeDescricao(descricao){
			this.valueDescricao = descricao
		},

		changeSelectDepartamentos(departamentos){
			this.departamentosSelecionados = departamentos
		},

		/**
         *  Método pegar o último valor do filtro e 
         *      também alterar qual esta selecionado no momento
         *  @param {array} value - valor do filtro selecionado
         *  @author Vitor Hugo 🐨
         */
		changeFiltros(value){
			this.valueFiltros = value
			this.filtroStatus[0].icon = ''
			this.filtroStatus[1].icon = ''
			this.filtroStatus[0].isSelected = false
			this.filtroStatus[1].isSelected = false
			if(value[0] == 'A'){
				this.filtroStatus[0].icon = mdiCheckBold
				this.filtroStatus[0].isSelected = true
			} else{
				this.filtroStatus[1].icon = mdiCheckBold
				this.filtroStatus[1].isSelected = true
			}
		},

		/**
         *  Método para salvar ou editar os cargos
         *      também cria o objeto a ser levado ao back
         *  @author Vitor Hugo 🐨
         */
		salvarCadastro(){
			this.loadingPanelEagle = true
			var obj = {
				'cccodigo'   : this.id,
				'ccdescricao':this.valueDescricao,
				'ccstatus':this.valueFiltros,
				'cccliente':this.empresaSelecionada,
				'cccodigodepartamento': this.departamentosSelecionados
			}
            
			new HttpRequest().Post(this.url+'salva', obj)
				.then(dados=>{
					if(dados.code == 201){
						this.$router.push({name:'listagemCargos'})
					} else {
						conectionError()
					}
					this.loadingPanelEagle = false
				})
		},

		/**
         *  Método busca os departamentos do cliente
         *  @param {array} empresa - código da empresa
         *  @author Vitor Hugo 🐨
         */
		buscaDepartamentos(empresa){
			this.loadingDepartamentos = true
			new FiltrosService().dados_filtros(empresa, ['D'])
				.then((dados)=>{
					this.optDepartamentos = dados.D
					this.loadingDepartamentos = false
				})
		},
	},
	async mounted(){
		if(this.$route.params.id != undefined){
			this.id = this.$route.params.id
			new HttpRequest().Post(this.url+'busca/dados', {'id':this.id})
				.then(dados=>{
					this.valueDescricao = dados.data.dados[0].ccdescricao
					this.changeFiltros(dados.data.dados[0].ccstatus)
					this.selectedEmpresa = [{
						value: dados.data.dados[0].clcodigo,
						description: dados.data.dados[0].clnome
					}]
					this.selectedDepartamentos = [{
						value: dados.data.dados[0].cdcodigo,
						description: dados.data.dados[0].cddescricao
					}]
				})
		}
	},
})
</script>
<style lang="scss">
    
</style>