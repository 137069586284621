<template>
    <panelEagle id='ListagemParametros'
        :loading="loadingPanel">
        <div class="col-sm-12 nopading row">
            <div class="col-sm-6 nopadding">
                <tituloPage :icon="mdiAccountClock" titulo='Parâmetros'></tituloPage>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 nopadding divDontPrint">
                <basicButtonsCadastrosEIN
                    tipoNovo='button'
                    linkNew ='cadastrarParametros'
                    @exportarCadastro="exportar"
                    :loading ='loadingExportar'
                    :disabledDropdown='$v.dadosTabela.$invalid'
                    >
                </basicButtonsCadastrosEIN>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class="col-sm-12 nopadding row divDontPrint">
            <div class="col-lg-6 nopadding col-sm-12 divDontPrint">
                <selectAll
                    nameForRadio='empresasParametros'
                    :isMultiple="true"
                    :hasSelectAll="true"
                    :optionsArray="optEmpresa"
                    :selected="selectedEmpresa"
                    :labels="[{indexDFH:'EM', description: 'Empresas'}]"
                    @changeSelect="changeEmpresa"/>
            </div>
            <div class="col-sm-12 nopadding col-lg-6 divDontPrint">
                <buttonsFilters
                    :arrayButtons='botoes'
                    label='Status'
                    @buttonsFiltersChange='buttonsFiltersChange'
                    />
            </div>
        </div>
        <div class="col-sm-12 nopadding">
            <tableListagem
                :titles="titlesTabela"
                :data="dadosTabela"
                deleteEdit ='editStatus'
                :habilitado ="true">
                <template #acoes="{row}" v-if="getEditar()">
                    <simpleButton
                        type='green'
                        width='45%'
                        event='click'
                        title='Editar parâmetro'
                        :icon='mdiPencil'
                        @click="editarCadastro(row.codigo)"
                    />
                    <simpleButton
                        :type='row.status=="I" ? "light-green":"red"'
                        :icon='row.status=="I" ? mdiCheckBold: mdiCircleOffOutline'
                        :title='row.status=="I"?"Ativar parâmetro":"Desativar parâmetro"'
                        width='45%'
                        event='click'
                        @click="alterarStatus(row.codigo, row.status)"
                    />
                </template>
            </tableListagem>
        </div>
    </panelEagle>
</template>
<script lang='js'>
    import Vue  from 'vue'
    import { mapGetters } from 'vuex'
    // import Vuex from '@/store/index'
    import {mdiAccountClock, mdiPencil, mdiCircleOffOutline, mdiCheckBold} from '@mdi/js'
    import { EmpresasService } from '@/Services/auth/Empresas.service'
    import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
    import { required }        from 'vuelidate/lib/validators'
    export default Vue.extend({
        name:"listagemParametros",
        components:{
            'panelEagle'               : require('@/components/Atom/Panel/PanelEagle').default,
            'basicButtonsCadastrosEIN' : require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
            'tituloPage'               : require('@/components/Atom/Header/Titulo').default,
            'selectAll'                : require('@/components/Atom/Select/SelectAll').default,
            'buttonsFilters'           : require('@/components/Atom/Buttons/ButtonsFilters').default,
            'tableListagem'            : require('@/components/Atom/Table/TableListagem').default,
            'simpleButton'             : require('@/components/Atom/Buttons/SimpleButton').default,
        },

        validations:{
            dadosTabela: { required },
           
        },

        data(){
            return {
                url:'/checkpoint/cadastros/parametros/',
                mdiAccountClock     : mdiAccountClock,
                mdiPencil           : mdiPencil,
                mdiCircleOffOutline : mdiCircleOffOutline,
                mdiCheckBold        : mdiCheckBold,  
                titlesTabela:[
                    {'number' : 'jtdescricao', 'name':'Descrição'},
                    {'number' : 'clfantasia',  'name':'Empresa'},
                ],
                dadosTabela        : [],
                loadingExportar    : [false, false, false],
                optEmpresa         : new EmpresasService().Get(),
                selectedEmpresa:[],
                status             : [],
                loadingPanel       : false,
                empresaSelecionada : [],
                botoes      : [
                     {'value': 'A',
                     'width': '32.4%',
                     'isSelected': true,
                     'title': 'Ativos',
                     'text': 'Ativos',
                     'icon': ''},
                     {'value': 'I',
                     'width': '32.4%',
                     'isSelected': false,
                     'title': 'Inativos',
                     'text': 'Inativos',
                     'icon': ''},
                     {'value': 'T',
                     'width': '32.4%',
                     'isSelected': false,
                     'title': 'Todos',
                     'text': 'Todos',
                     'extraClass':'lado',
                     'icon': ''},
                ]
            }
        },

        methods:{
            ...mapGetters(['getMaster', 'getEditar']),
            async changeEmpresa(empresa){
                this.empresaSelecionada = empresa
                this.listarParametros(empresa)
            },

            buttonsFiltersChange(value){
                this.botoes[0].icon = ''
                this.botoes[1].icon = ''
                this.botoes[2].icon = ''
                if(value == "T") {
                    this.status = ["A", "I"]
                    this.botoes[2].icon = mdiCheckBold
                }
                else if(value == "A"){
                    this.status = ["A"]
                    this.botoes[0].icon = mdiCheckBold
                }
                else if(value == "I"){
                    this.status = ["I"]
                    this.botoes[1].icon = mdiCheckBold
                }
                this.changeEmpresa(this.empresaSelecionada)
            },

            listarParametros(empresa){
                this.loadingPanel = true
                new HttpRequest().Post(this.url+'listar/parametros',
                                    {'clientesbusca' : empresa, 
                                     'status'        : this.status})
                    .then(dados=>{
                        this.dadosTabela = dados.data.dados
                        this.loadingPanel = false
                })
            },

            async exportar(type){
                var root =  process.env.VUE_APP_ROOT;
                    switch(type){
                        case 'pdf':
                            this.loadingExportar = [true, false, false]
                            break;
                        case 'xls':
                            this.loadingExportar = [false, true, false]
                            break;
                        case 'csv':
                            this.loadingExportar = [false, false, true]
                            break;
                        default:
                            break;
                    }
                var obj = {
                    'arrayDados': this.dadosTabela, 
                    'tipo':type
                }
                new HttpRequest().Post(this.url+'exportar', obj)
                    .then(dados=>{
                    window.open(root+'/'+dados.data.local);
                    this.loadingExportar = [false, false, false]
                })
            },

            editarCadastro(value){
                this.$router.push({name:'cadastrarParametros', params:{id:value}})
            },

            async alterarStatus(value, status){
                this.loadingPanel = true
                var obj = {
                    codigo:value,
                    status:status};

                new HttpRequest().Post(this.url+'alterar/status', obj)
                .then(dados=>{
                    this.listarParametros(this.empresaSelecionada)
                    this.loadingPanel = false
                })
            },
        },
        async mounted(){
            if(!this.getMaster()){
                this.selectedEmpresa = this.optEmpresa
            }
        }
    })
</script>
<style lang="scss">
</style>