chartsPizza/** 
  Este é um compoenete para grafico de data

  Propriedades:
    1  -> label - Descrição da legenda - Ex: ['Classe A', 'Classe B']
    2  -> value - Valores inseridos no gráfico correspondente ao label - Ex: [25, 25]
    3  -> widthGrafico - Tamanho do gráfico - Ex: 600
    4  -> heightLegenda - Comprimento da legenda - Ex: 400
    5  -> offsetYLegenda - Posicionamento da legenda - Ex: 0
    6  -> widthLegenda - Comprimento da legenda - Ex: 300
    7  -> offsetXLegenda - Posicionamento da legenda em X - Ex: 0
    8  -> textTitle - Texto de título - Ex: 'Título'
    9  -> alignTitle - Onde posicionar o title - Ex: 'left'/'center'/'right'
    10 -> offsetXTitle - Posicionamento do título em X - Ex: -322
    11 -> offsetYTitle - Posicionamento do título em Y - Ex: -200
    12 -> textSubtitle - Texto do subtítulo - Ex: 'Subtítulo'
    13 -> offsetXSubtitle - Posicionamento do subtítulo em X - Ex: -322
    14 -> offsetYSubtitle - Posicionamento do subtítulo em Y - Ex: -100
    15 -> offsetXSubtitleResponsive - Posicionamento do subtítulo quando tiver breakpoint - Ex:'25'
    16 -> colors - array: ['#a9f38c', '#79b0ee', '#f8e37a', '#f0abab']

  documetação
    https://apexcharts.com/docs/installation/
    https://apexcharts.com/docs/series/
    https://apexcharts.com/docs/options/series/
*/
<template>
    <div class="col-sm-12 row nopadding">
        <div id="chart" class="col-sm-12 nopadding">
            <!-- :width="widthGrafico"  -->
            <apexchart 
                ref="chart" 
                :options="options" 
                :series="series"
            />
        </div>
    </div>
</template>
<script>
    export default {
        name:'chartsPizza',
        data() {
            return {
                series: this.value,
                options : {
                    responsive: [{
                        enabled: false,
                    }],
                    chart: {
                        type: this.type,
                        offsetY: 0,
                        offsetX: 0,
                        width: "100%",
                        height: '100%',
                        animations: {
                            enabled: true,
                            easing: 'easeinout',
                            speed: 600,
                        },
                        fontFamily: 'nexabook, sans-serif',
                    },
                    dataLabels: {
                        enabled: true,
                        offsetX: 100,
                        offsetY: -100,
                        textAnchor: 'start',
                        style: {
                            fontSize: '14px',
                            fontWeight: '100',
                            fontFamily: 'nexabook, sans-serif',
                            colors: ['#000'],
                        },
                    },
                    plotOptions: {
                        pie: {
                            customScale: 1,
                            donut: {
                                size: this.larguraGrafico,
                            }
                        }
                    },
                    colors:this.colors, //['#a9f38c', '#79b0ee', '#f8e37a', '#f0abab'],
                    labels : this.label,
                    legend: {
                        position: 'right',
                        offsetY: this.offsetYLegenda,
                        offsetX: this.offsetXLegenda,
                        height: this.heightLegenda,
                        width: this.widthLegenda,
                    },

                    title: {
                        text: this.textTitle,
                        align: this.alignTitle,
                        margin: 10,
                        offsetX: this.offsetXTitle,
                        offsetY: this.offsetYTitle,
                        floating: false,
                        style: {
                            fontWeight:  'bold',
                            fontFamily:  'nexabook, sans-serif',
                            color:  '#263238'
                        },
                    },

                    subtitle: {
                        text: this.textSubtitle,
                        align: 'right',
                        margin: 10,
                        offsetX: this.offsetXSubtitle,
                        offsetY: this.offsetYSubtitle,
                        floating: false,
                        style: {
                            fontWeight:  'bold',
                            fontFamily:  'nexabook, sans-serif',
                            color:  '#000'
                        },
                    }
                },
            }
        },

        props:{
            type:{
                type:String,
                default:"donut"
            },

            larguraGrafico:{
                type:String,
                default:"65"
            },
            
            colors:{
                type: Array,
                default:() => [
                    '#a9f38c', '#79b0ee', 
                    '#f8e37a', '#f0abab'
                ]
            },

            label:{
                type: Array,
                default: () => [],
            },

            value:{
                type: Array,
                required: true,
            },

            widthGrafico:{
                type: Number,
                required: true,
            },
             
            heightLegenda:{
                type: Number,
                default: 100
            },

            offsetYLegenda:{
                type: Number,
                default: 0,
            },

            offsetXLegenda:{
                type: Number,
                default: 0,
            },

            widthLegenda:{
                type: Number,
                default: 210,
            },

            textTitle:{
                type: String,
                default: '',
            },

            alignTitle:{
                type: String,
                default: 'center',
            },

            offsetXTitle:{
                type: Number,
                default: 0,
            },

            offsetYTitle:{
                type: Number,
                default: 0,
            },

            offsetXTitleResponsive:{
                type: Number,
                default: -200,
            },

            textSubtitle:{
                type: String,
                default:'',
            },

            offsetXSubtitle:{
                type: Number,
                default: 0,
            },

            offsetYSubtitle:{
                type: Number,
                default: 0,
            },

            offsetXSubtitleResponsive:{
                type: Number,
                default: 0,
            }

        },

        methods:{
            /**
             * @description Método para atualizar o gráfico,
             *      cria o gráfico novamente toda vez que for passado um valor novo para ele,
             *      pegando o gráfico pela referência e utilizando o refresh do apexchart é atualizado
             * @author Vitor Hugo 🐨
            */
            atualizaGrafico(){
                this.series = this.value
                this.options.chart.type = this.type
                this.options.labels = this.label
                this.options.colors = this.colors
                this.$refs.chart.refresh()
                this.transformaImagem()
            },
            /**
             * @description Método para transformar o gráfico em imagem
             *      pega o gráfico por referencia e chama o método dataURI do apexchart
             *      (timeout necessário para o gráfico consegir ser gerado!)
             * @return string - imagem em base 64
             * @author Vitor Hugo 🐨
            */
            transformaImagem(){
                setTimeout(() => {
                    var dataURL = this.$refs.chart.dataURI().then((uri) => {
                        var imagem = uri.imgURI
                        this.$emit('changeImagem', imagem);
                    }) 
                }, 1000)
            }
        },

        mounted(){
            this.atualizaGrafico()
        },

        watch:{
            value(){
                this.atualizaGrafico()
            }
        }
    };
</script>
<style lang="scss">

#chart{
    max-width: 600px;
}
.apexcharts-canvas{
    width: 100% !important;
    .apexcharts-svg{
        width: 100% !important; 
        foreignObject{
            .apexcharts-legend{
                padding-right: 0px;
                padding-left: 0px;
                .apexcharts-legend-series{
                    .apexcharts-legend-text{
                        font-size: 13px !important;
    
                    }
                }
                .apexcharts-legend-marker{
                    border-radius: 0px !important;
                }
            }
        }  
        .apexcharts-title-text{
            width: 100% !important;
            font-size: 18px !important;
        }
    }
}
</style>
