<template>
    <div class="col-sm-12 row nopadding" id="progressBarReidentificarPontos">
        <div class="row col-sm-12 nopadding d-flex justify-content-start mt-1">
            Dia(s) 
            <span :class="pegaClasseDia(dia, diaProcessado)"
                class="dias-reidentificados col-sm-1 d-flex justify-content-center"
                v-for="(dia, index) in diasSelecionadosModal" 
                :key="index">
                <span class="d-flex justify-content-center">
                    {{dia}}
                </span>
            </span>
        </div>
        <div class="col-sm-12 nopadding"><hr></div>
        <div class="col-sm-12 row nopadding">
            <div class="col-sm-3 nopadding row d-flex justify-content-start">
                <span class="mr-1 my-auto">Ponto(s):</span>
                <span class="dia-destaque my-auto d-flex justify-content-center col-sm-3">{{qtdPontos}}</span>
            </div>
        </div>
        <div class='col-sm-12 nopadding mt-2'>
            <b-progress height="2rem" class='progressBar'>
                <b-progress-bar 
                    :label="`${progresso}%`"
                    :value="`${progresso}%`"
                    animated
                    variant="success"
                />
            </b-progress>
        </div>
    </div>
</template>
<script>
import { DateTime } from "luxon"
export default {
    name:'progressBarReidentificarPontos',
    props:{
        diasSelecionadosModal:{
            type: Array,
            required: true,
        },
        progresso:{
            type: [String, Number],
            required: true,
        },
        qtdPontos:{
            type: String,
            required: true,
        },
        diaProcessado:{
            type: String,
            required: true,
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        pegaClasseDia(dia, diaProcessado){
            var dataAtual = DateTime.fromFormat(dia, "dd/MM")
            var diaPro = DateTime.fromFormat(diaProcessado, 'dd/MM')
            var difDias = dataAtual.diff(diaPro, 'days')
            if(difDias.values){
                if(difDias.values.days == 0){
                    return 'dia-azul'
                }else if(difDias.values.days < 0){
                    return 'dia-verde'
                }
            }
            return "dia-branco"
        }
    },
}
</script>
<style lang="scss">
    .dias-reidentificados{
        padding: 1px;
        margin-left: 5px;
        margin-right: 5px;
    }
    .dia-verde{
        background-color: #6db000;
        color: #fff;
    }
    .dia-azul{
        background-color: #191970;
        color: #fff;
    }
    .dia-branco{
        // color:black;
        border: 1px solid rgba(0, 0, 0, 0.493);
    }
    .dia-destaque{
        border: 1px solid rgba(0, 0, 0, 0.493);
        padding: 2px;
        // min-width: 25px;
    }
    #progressBarReidentificarPontos{
        .bg-success{
            background-color: #6db000 !important;
        }
    }
</style>