<template>
    <panelEagle id="RelatorioManutencoes" :loading="loadingPanel">
        <div class="col-sm-12 row">
            <div class="col-sm-6 nopadding">
                <titulo titulo="Manutenções" :icon="mdiFileCog"/>
            </div>
            <div class="col-sm-6 nopadding divDontPrint">
                <basicButtonsRelatoriosEIG
                :loading="loadingExportar" 
                :disabled="$v.$invalid"
                :disabledDropdown="!Object.keys(dadosFilter).length"
                @gerarRelatorio="gerarRelatorio"
                @exportarRelatorio="exportarRelatorio"/>
            </div>
        </div>
        <slideUpAndDown>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-3 nopadding">
                    <inputRangeWithLimit 
                        @changeInput="changeData"
                        name="InputRangeWithLimit"/>
                </div>
                <div class="col-sm-3 nopadding">
                    <selectAll
                        :isMultiple="true"
                        :hasSelectAll="true"
                        @changeSelect="changeEmpresa"
                        :labels=" [{indexDFH:'EM', description: 'Empresas*'}]"
                        nameForRadio="empresa"
                        :optionsArray="optEmpresa"
                        :selected="selectedEmpresa"/>
                </div>
                <div class="col-sm-3 nopadding">
                    <selectAll
                        @changeSelect="changeVeiculos"
                        :disabled="!valueEmpresa.length"
                        @changeCheck="changeRadioVeiculos"
                        :isMultiple="true"
                        :hasSelectAll="true"
                        ref="refVeiculos"
                        :loading="loadginVeiculos"
                        :labels="[
                            {indexDFH:'V', description: 'Veículos*'},
                            {indexDFH:'GV', description: 'Grupos*'},
                        ]"
                        firstSelected="V"
                        nameForRadio="veiculos"
                        :optionsArray="optVeiculosGeral"/>
                </div>
                <div class="col-sm-3 nopadding">
                    <inputSimple
                        name="Ordem de Serviço"
                        label="Ordem de Serviço"
                        :hasMask="true"
                        :mask="['NNNNNNNNNNNNNN']"
                        @changeInput="changeOs"
                    />
                </div>
                <div class="col-sm-8 nopadding mt-2">
                    <buttonsFilters
                        label='Status'
                        :arrayButtons='optFiltroStatus'
                        zeroValue='0'
                        @buttonsFiltersChange='mudaFiltroStatus'/>
                </div>
                <div class="col-sm-4 nopadding mt-2">
                    <buttonsFilters
                        label='Tipo'
                        :arrayButtons='optFiltroTipo'
                        :zeroValue='["0"]'
                        @buttonsFiltersChange="changeTipoManutencao"/>
                </div>
            </div>
        </slideUpAndDown>
        <div class="col-sm-12"><hr></div>
        <div class="col-sm-12 row nopadding">
            <tableRelatorio style="width: 100%;">
                <slot slot="thead">
                    <tr>
                        <th> </th>
                        <th>Descrição</th>
                        <th>Tipo</th>
                        <th>Apuração</th>
                        <th>Intervalo</th>
                        <th>Programado</th>
                        <th>Realizado</th>
                    </tr>
                </slot>
                <slot slot="tbody">
                    <template v-for="(placa, p) in filterDadosRelatorio">
                        <tr :key="p+'_placa'">
                            <td colspan="7" class="backColorTdPDF" style="padding:0px;">
                                <span class="newbadge">
                                    {{p}}
                                </span>
                            </td>
                        </tr>
                        <template v-for="(dados, d) in placa">
                            <tr :key="p+'_dadosPrincipais_'+dados.mccodigo"
                                :class="defineCorFundo(dados.historicos)"
                                @click="dados.check = !dados.check"
                                v-show="dados.length != 0">
                                <!-- chevron -->
                                <td class="cursor-pointer pt-1" title="Detalhes">
                                    <b-icon 
									:icon="dados.check ? 'chevron-down':'chevron-right'"/>
                                </td> 
                                <!-- Descrição -->
                                <td class="cursor-pointer pt-1">
									{{dados.mcdescricao}}
								</td> 
                                <!-- Tipo -->
                                <td class="cursor-pointer pt-1">
									{{dados.mtidescricao}}
								</td> 
                                <!-- Apuração -->
                                <td class="cursor-pointer pt-1">{{dados.mcapuracao == 1 ? 'Hodômetro' : (dados.mcapuracao == 2 ? 'Horímetro' : 'Dias')}}</td>
                                <!-- Intervalo -->
                                <td class="cursor-pointer pt-1">
                                    {{decideIntervalo(dados)}}
                                </td>
                                <!-- Programado -->
                                <td class="cursor-pointer pt-1">{{dados.mcapuracao == 1 ? parseInt(dados.mcprogramado/1000)+' km' : (dados.mcapuracao == 2 ? dados.mcprogramado+' H' : dados.mcprogramado+'') }}</td>
                                <!-- Realizado -->
                                <td
								class="cursor-pointer pt-1">
									{{verificaRealizado(dados)}}
								</td>
                            </tr>
                            <tr :key="d+'_dadosCollapse_'+p+dados.mccodigo"
                                class="tr-colapse backColorTdPDF pr-0"
                                v-show="dados.length != 0">
                                <td colspan="7" class="td-colapse p-0"> 
                                    <b-collapse :visible="dados.check">
                                        <div
										class="col-sm-12 row nopadding fundo-colapse">
                                            <!-- Time line -->
                                            <div class="col-sm-12 mt-3 row">
                                                <div
												v-for="(dadosTL, dtm) in dados.historicos"
												:key="dtm+'_timeLine'"
												class="col-sm-3 row nopadding historico"> 
                                                    <div class="timeLine"
                                                        align="center">
                                                        <div> {{dadosTL.mstdescricao}} </div>
                                                    </div>
                                                    <div align="center"> 
                                                        <div
														:class="dadosTL.mstdescricao == 'Em execução' ? 
														'circle-executada':'circle-'+dadosTL.mstdescricao">
                                                        </div>
                                                        <div class="mt-2">
                                                            {{dadosTL.dataatualizacao}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Dados serviços -->
                                            <div
											class="col-sm-12 mt-4 row dadosManutencoes">
                                                <div class="col-sm-5">
                                                    <div class="col-sm-12 font-bold">
                                                        Serviços
                                                    </div>
                                                    <div
													v-for="(dadosR, ds) in dados.servicos"
													:key="ds+'_servicosRealizados'"
													class="col-sm-12">
														{{dadosR.mtsdescricao}}
														<base-icon
														:size='15'
														class='mb-1'
														:icon='decideManutencao(dadosR)'>
														</base-icon>
                                                    </div>
                                                </div>
                                                <div class="col-sm-3 p-1">
                                                    <div class="col-sm-12 nopadding">
                                                        <span class="font-bold">
															Valor peças:
														</span>
														<!-- {{dados.mcvalorpecas}} -->
														{{valorOuSem(dados.mcvalorpecas, 'R$: ')}}
                                                    </div>
                                                    <div class="col-sm-12 nopadding">
                                                        <span class="font-bold">
															Valor serviços:
														</span>
														{{valorOuSem(dados.mcvalorservico, 'R$: ')}}
                                                    </div>
                                                    <div class="col-sm-12 nopadding">
                                                        <span class="font-bold">
															Número OS:
														</span>
														{{valorOuSem(dados.mcordemservico)}}
                                                    </div>
                                                </div>
                                                <div class="col-sm-3 p-1">
                                                    <div class="col-sm-12 nopadding">
                                                        <span class="font-bold">
															Valor total:
														</span>
														{{valorOuSem(dados.mcvalortotal, 'R$: ')}}
                                                    </div>
                                                    <div class="col-sm-12 nopadding">
                                                        <span class="font-bold">
															Local:
														</span>
														{{valorOuSem(dados.podescricao)}}
                                                    </div>
                                                    <div class="col-sm-12 nopadding">
                                                        <span class="font-bold">
															Observações:
														</span>
														{{valorOuSem(dados.mcobservacao)}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </td>
                            </tr>
                        </template>
                    </template>
                    <statusInformation
                        v-show="!Object.keys(dadosFilter).length" 
                        :statusBar="statusBar"/>
                </slot>
            </tableRelatorio>        
        </div>
    </panelEagle>
</template>
<script lang="js">
import Vue from 'vue'
import { DateTime } from 'luxon'
import { required } from 'vuelidate/lib/validators'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { mdiFileCog, mdiCheckBold, mdiCloseThick } from '@mdi/js'
import { FiltrosService  } from '@/Services/filtros/filtros.Service'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { conectionError  } from '@/Services/Helpers/swellHeper.js'
import { mapGetters } from 'vuex'

export default Vue.extend({
	name:'RelatorioChecklist',
	validations:{
		valueEmpresa:{ required },
		valueVeiculos:{ required },
	},
	
	/* eslint-disable max-len */
	components:{
		titulo: require('@/components/Atom/Header/Titulo').default,
		baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
		selectAll: require('@/components/Atom/Select/SelectAll').default,
		panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
		inputSimple: require('@/components/Atom/Inputs/InputSimple').default,
		tableRelatorio: require('@/components/Atom/Table/TableRelatorio').default,
		buttonsFilters: require('@/components/Atom/Buttons/ButtonsFilters').default,
		slideUpAndDown: require(
			'@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
		inputRangeWithLimit: require(
			'@/components/Atom/Datas/InputRangeWithLimit').default,
		statusInformation: require(
			'@/components/Atom/StatusInformation/StatusInformation').default,
		basicButtonsRelatoriosEIG: require(
			'@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
	},
	
	/* eslint-enable max-len */
	data() {
		return{
			mdiFileCog:mdiFileCog,
			optEmpresa:new EmpresasService().Get(),
			optVeiculos:[],
			optGrupoVeiculos:[],
			optVeiculosGeral:[],
			optFiltroStatus:[
				{
					text:'Cadastradas',
					value:'Cadastradas', 
					width:'19%',
					isSelected:false,
					extraClass:'blueButton'
				},
				{
					text:'Programar',
					value:'Programar', 
					width:'19%',
					isSelected:false,
					extraClass:'orangeButton'
				},
				{
					text:'Vencidas',
					value:'Vencidas',
					width:'19%',
					isSelected:false,
					extraClass:'redButton'
				},
				{
					text:'Em execução',
					value:'Em execução',
					width:'19%',
					isSelected:false,
					extraClass:'purpleButton'
				},
				{
					text:'Realizadas',
					value:'Realizadas',
					width:'19%',
					isSelected:false,
					extraClass:'greenButton'
				},
			],
			optFiltroTipo:[
				{
					text:'Preventiva',
					value:'1',
					width:'48.5%',
					isSelected:false,
					extraClass:''
				},
				{
					text:'Corretiva',
					value:'2',
					width:'48.5%',
					isSelected:false,
					extraClass:''
				},
			],
			filtroStatus : '',
			// values
			valueEmpresa : [],
			valueVeiculos : [],
			valueData : '',
			valueOs : '',
			valueTipoManutencao : '',

			// controle
			loadginVeiculos:false,
			valueRadioVeiculos:'V',
			loadingPanel:false,
			loadingExportar: [false, false, false],
			urlBase:'/manutencoes/relatorios/manutencoes/',
			statusBar : 'info',
			selectedEmpresa : [],
			dadosRelatorio : [],
			dadosFilter : [],
		}
	},

	mounted(){
		var dataAtual = DateTime.now().toFormat('dd/MM/yyyy')
		this.valueData = `${dataAtual} - ${dataAtual}`
		if(!this.getMaster()){
			this.selectedEmpresa = this.optEmpresa
		}
	},

	methods:{
		...mapGetters(['getMaster']),
		
		/**
		 * @param {boolean} dadosServicosRealizados
		 * @return {string} icone correto
		 */
		decideManutencao(dadosServicosRealizados){
			if(dadosServicosRealizados.mcsrealizado)
				return mdiCheckBold
			return mdiCloseThick
		},

		decideIntervalo({mcapuracao, mcintervalo}){
			if(mcapuracao === 1 ){
				return Math.abs(mcintervalo/1000)+ ' Km'
			}else if(mcapuracao === 2){
				return mcintervalo+' H'
			}
				return mcintervalo
		},

		changeEmpresa(value){
			this.$refs.refVeiculos.clearAll()
			this.dadosFilter = []
			this.valueEmpresa = value
			this.buscaVeiculos(value)
		},

		changeRadioVeiculos(value){
			this.dadosFilter = []
			this.valueRadioVeiculos = value
			if(value === 'V'){
				this.optVeiculosGeral = this.optVeiculos
			} else {
				this.optVeiculosGeral = this.optGrupoVeiculos
			}
		},

		changeVeiculos(value){
			this.dadosFilter = []
			this.valueVeiculos = value
		},

		changeData(value){
			this.dadosFilter = []
			this.valueData = value
		},

		changeOs(os){
			this.dadosFilter = []
			this.valueOs = os
		},

		changeTipoManutencao(tipoManutencao){
			this.dadosFilter = []
			this.valueTipoManutencao = tipoManutencao
		},

		gerarRelatorio(){
			this.loadingPanel = true
			this.dadosFilter = []
			this.dadosRelatorio = []
			var obj = {
				os: this.valueOs,
				data: this.valueData,
				status: this.filtroStatus,
				empresa: this.valueEmpresa,
				veiculos: this.valueVeiculos,
				radioVeiculos: this.valueRadioVeiculos,
				tipoManutencao: this.valueTipoManutencao,
			}
			new HttpRequest()
				.Post(this.urlBase+'gerar', obj)
				.then((dados)=>{
					if(dados.status && dados.code === 200){
						if(Object.keys(dados.data).length > 0){
							this.dadosRelatorio = dados.data
							this.dadosFilter = this.dadosRelatorio
						}else {
							this.statusBar = 'error'
						}
					} else {
						this.statusBar = 'error2'
					}
				}).catch(() => {
					conectionError()
				}).finally(() => {
					this.loadingPanel = false
					this.filtraDadosStatus()
				})
		},

		mudaFiltroStatus(value){
			if(value !== undefined){
				this.filtroStatus = value[0];
				switch (value[0]) {
					case 'Cadastradas':
						this.optFiltroStatus[0].extraClass = 'borderblueButton'
						this.optFiltroStatus[1].extraClass = 'orangeButton'
						this.optFiltroStatus[2].extraClass = 'redButton'
						this.optFiltroStatus[3].extraClass = 'purpleButton'
						this.optFiltroStatus[4].extraClass = 'greenButton'
						break;

					case 'Programar':
						this.optFiltroStatus[0].extraClass = 'blueButton'
						this.optFiltroStatus[1].extraClass = 'borderOrangeButton'
						this.optFiltroStatus[2].extraClass = 'redButton'
						this.optFiltroStatus[3].extraClass = 'purpleButton'
						this.optFiltroStatus[4].extraClass = 'greenButton'
						break;

					case 'Vencidas':
						this.optFiltroStatus[0].extraClass = 'blueButton'
						this.optFiltroStatus[1].extraClass = 'orangeButton'
						this.optFiltroStatus[2].extraClass = 'borderRedButton'
						this.optFiltroStatus[3].extraClass = 'purpleButton'
						this.optFiltroStatus[4].extraClass = 'greenButton'
						break;

					case 'Em execução':
						this.optFiltroStatus[0].extraClass = 'blueButton'
						this.optFiltroStatus[1].extraClass = 'orangeButton'
						this.optFiltroStatus[2].extraClass = 'redButton'
						this.optFiltroStatus[3].extraClass = 'borderpurpleButton'
						this.optFiltroStatus[4].extraClass = 'greenButton'
						break;

					case 'Realizadas':
						this.optFiltroStatus[0].extraClass = 'blueButton'
						this.optFiltroStatus[1].extraClass = 'orangeButton'
						this.optFiltroStatus[2].extraClass = 'redButton'
						this.optFiltroStatus[3].extraClass = 'purpleButton'
						this.optFiltroStatus[4].extraClass = 'borderGreenButton'
						break;

					case '0':
						this.optFiltroStatus[0].extraClass = 'blueButton'
						this.optFiltroStatus[1].extraClass = 'orangeButton'
						this.optFiltroStatus[2].extraClass = 'redButton'
						this.optFiltroStatus[3].extraClass = 'purpleButton'
						this.optFiltroStatus[4].extraClass = 'greenButton'
						break;
				
					default:
						break;
				}
				this.filtraDadosStatus()
			} else {
				this.filtroStatus = '';
			}
		},

		async buscaVeiculos(value){
			this.loadginVeiculos = true
			var dados = await new FiltrosService().dados_filtros(value, ['V', 'GV'])
			if(dados){
				this.optVeiculos = dados['V']
				this.optGrupoVeiculos = dados['GV']
				this.changeRadioVeiculos(this.valueRadioVeiculos)
			}
			this.loadginVeiculos = false
		},

		/**
		 * Filtro para filtrar as manutenções por status, percorre todo o objeto para
		 * chegar na mstdescricao para conseguir fazer a verificação, é deletado
		 * da variável auxiliar cadas objeto que fica vazio para não termos problemas
		 * ao montar a tabela (utilizado JSON pois o javascript estava alocando o
		 * mesmo espaço de memória para as duas variáveis e assim não
		 * funcionando o filtro)
		 * @author Vitor Hugo 🐨
		 */
		filtraDadosStatus(){
			if(this.filtroStatus == 0){
				this.dadosFilter = this.dadosRelatorio
				return
			}
			var aux = this.dadosRelatorio
			var aux2 = JSON.stringify(aux)
			var obj = JSON.parse(aux2)
			var aux3 = {}
			for (const key in this.dadosRelatorio) {
				for (const k in this.dadosRelatorio[key]) {
					let registros = this.dadosRelatorio[key][k].historicos
					let ultimo = registros[registros.length - 1]
					if(this.filtroStatus !== ultimo.mstdescricao){
						delete obj[key][k]
					}
				}
				if(Object.keys(obj[key]).length > 0){
					aux3[key] = obj[key]
				}else {
					delete obj[key]
				}
			}
			this.dadosFilter = aux3
		},

		async exportarRelatorio(value){
			this.loadingExportar = [value == 'pdf', value == 'xls', value == 'csv']
			var obj = {
				tabela:this.dadosFilter,
				tipo:value,
				data:this.valueData
			}
			var root =  process.env.VUE_APP_ROOT
			try {
				var dados = await new HttpRequest().Post(this.urlBase+'exportar', obj)
				if(dados.status) {
					open(root+'/'+dados.data.resposta)
				} else {
					conectionError()
				}
			} catch (error) {
				conectionError()
			} finally {
				this.loadingExportar = [false, false, false]
			}
		},

		/**
		 * @description Método para verificar se o campo realizado
		 *  tem valor nulo ou 0 e inserir Km (transformando em Km) ou H
		 * @return {string} realizado - valor do Km ou H realizado da manutenção
		 * @author Vitor Hugo
		 */
		verificaRealizado({mcapuracao, mcrealizado}){
			if(mcrealizado === 0 || mcrealizado == null)
				return ''
			else if(mcapuracao === 1)
				return parseInt(mcrealizado/1000)+' Km'
			else if(mcapuracao === 2)
				return mcrealizado+' H'
			else
			return mcrealizado
		},

		valorOuSem(texto, pre=''){
			if(texto)
				return pre+texto
			return 'Sem informações'
		},

		defineCorFundo(historicos) {
			let cor = 'borda-tr '
			if(historicos && historicos.length){
				let registro = historicos[historicos.length - 1]
				if(registro.mstdescricao == 'Em execução') {
                    cor += 'fundo-executada'
				} else {
					cor += 'fundo-'+registro.mstdescricao
				}
			}
			return cor
		},
	},

	computed: {
		filterDadosRelatorio(){
			return this.dadosFilter
		},
	},

})
</script>
<style lang="scss">
    #RelatorioManutencoes{
        .greenButton{
            background: #dff0d8;
        }
        .orangeButton{
            background: #fcf8e3;
        }
        .redButton{
            background: #f2dede;
        }
        .blueButton{
            background: #b3d7ff;
        }
        .purpleButton{
            background: #e3bcff;
        }

        .borderGreenButton{
            background: #dff0d8;
            color: #000;
            border: 2.3px solid #000;
        }
        .borderOrangeButton{
            background: #fcf8e3;
            color: #000;
            border: 2.3px solid #000;
        }
        .borderRedButton{
            background: #f2dede;
            color: #000;
            border: 2.3px solid #000;
        }
        .borderblueButton{
            background: #b3d7ff;
            color: #000;
            border: 2.3px solid #000;
        }
        .borderpurpleButton{
            background: #e3bcff;
            color: #000;
            border: 2.3px solid #000;
        }
        .fundo-Cadastradas{
            background: #b3d7ff;
            // background: #b3d7ff;
        }
        .fundo-Vencidas{
            background: #f2dede;
            // background: #fdc2c2;
        }
        .fundo-Programar{
            background: #fcf8e3;
            // background: #fff5c1;
        }
        .fundo-executada{
            background: #eaccff;
            // background: #dcacff;
        }
        .fundo-Realizadas{
            background: #dff0d8;
            // background: #d4ffc0;
        }
        .circle-Cadastradas{
            border: 2px solid #90b1fa;
            background: #90b1fa;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            margin-top: 5px;
        }
        .circle-Vencidas{
            border: 2px solid #ef8081;
            background: #ef8081;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            margin-top: 5px;
        }
        .circle-Programar{
            border: 2px solid #ffe98d;
            background: #ffe98d;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            margin-top: 5px;
        }
        .circle-executada{
            border: 2px solid #d198fb;
            background: #d198fb;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            margin-top: 5px;
        }
        .circle-Realizadas{
            border: 2px solid #95eb87;
            background: #95eb87;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            margin-top: 5px;
        }
        .timeLine{
            div{
                border-bottom: 1px solid #c8c8c8;
                width: 120px;
				margin-top: 5px;
            }
        }
        .tr-colapse{
            padding-right: 0px;
            border: none;
            .dadosManutencoes{
                border: 1px solid #c8c8c8;
                padding: 15px;
                .servicos{
                    border-right: 1px solid #c8c8c8;
                }
            }
        }
        .font-bold{
            font-weight: bold;
        }
		.historico{
			border-left: 1px solid #eee;
			border-bottom: 1px solid #eee;
		}
    }
</style>