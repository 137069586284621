<template>
    <panelEagle id='CadastroColeta' :loading='loadingPanelEagle'>
        <div class="col-sm-12  nopadding">
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-6 row ">
                    <tituloPage titulo='Coletas' :icon='mdiPackageVariantPlus'>
                    </tituloPage>
                </div>
                <div class="col-sm-6 row divDontPrint">
                    <basicButtonsCadastrosSC 
                    linkCancel="listarColeta"
                    tipoCancela="button" 
                    :disabled='$v.$invalid'
                    @salvarCadastro="salvarCadastro">
                    </basicButtonsCadastrosSC>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 nopadding row">
               <div class="col-sm-4 nopadding">
                   <selectAll
                    nameForRadio="nameForRadio"
                    :isMultiple="false"
                    :hasSelectAll="false"
                    firstSelected ="EM"
                    :labels="[{'indexDFH':'EM', 'description':'Empresa*'}]"
                    :optionsArray="optEmpresas"
                    :selected="selectedEmpresa"
                    :extraClass   ="{'border border-danger':$v.empresaSelecionada.$anyError}"
                    @close        ="$v.empresaSelecionada.$touch()" 
                    @changeSelect='changeSelectEmpresa'
                    />
               </div>
               <div class="col-sm-4 nopadding">
                   <inputSimple
                    type="text"
                    label="Descrição*"
                    :inputClass='{"boder border-danger" : $v.valueDescricao.$anyError }'
                    :value="valueDescricao"
                    @blur='$v.valueDescricao.$touch()'
                    @changeInput="changeDescricao"
                    />
               </div>
               <div class="col-sm-4 nopadding">
                   <selectAll
                    nameForRadio="selectUnidade"
                    :isMultiple="true"
                    :hasSelectAll="false"
                    firstSelected ="UM"
                    :labels="[{'indexDFH':'UM', 'description':'Unidade de medida*'}]"
                    :optionsArray="optMedidas"
                    :selected="selectedMedidas"
                    :extraClass   ="{'border border-danger':$v.empresaSelecionada.$anyError}"
                    @changeSelect='setSelectMedidas'/>
               </div>
            </div>
            <requiredFields/>
            <div class="col-sm-12"><hr></div>
        </div>
    </panelEagle>
</template>
<script>
import { EmpresasService } from '@/Services/auth/Empresas.service';
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { required } from 'vuelidate/lib/validators'
import Vue from 'vue';
import {mdiPackageVariantPlus} from '@mdi/js'
import { conectionError }      from '@/Services/Helpers/swellHeper'
export default Vue.extend({
    name: 'TipoColetas',
    components:{
        selectAll: require('@/components/Atom/Select/SelectAll').default,
        panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
        tituloPage: require('@/components/Atom/Header/Titulo').default,
        requiredFields: require('@/components/Atom/Footer/RequiredFields').default,
        inputSimple: require('@/components/Atom/Inputs/InputSimple').default,
        basicButtonsCadastrosSC: require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
    },
    validations:{
        empresaSelecionada : { required },
        medidasSelecionadas: { required },
        valueDescricao     : { required },
    },
    data(){
        return{
            mdiPackageVariantPlus: mdiPackageVariantPlus,
            loadingPanelEagle : false,
            optEmpresas: new EmpresasService().Get(),
            optMedidas: [],
            url : '/roteirizador/cadastros/tipo/coleta/',
            selectedEmpresa    : [],
            selectedMedidas    : [],
            medidasSelecionadas: [],
            empresaSelecionada : [],
            valueDescricao     : '',
            id                 : '',
        }
    },
    methods:{
        changeSelectEmpresa(empresa) {
            // this.getMedidas()
            this.empresaSelecionada = empresa
        },

        changeDescricao(descricao) {
            this.valueDescricao = descricao
        },

        setSelectMedidas(medidas) {
            this.medidasSelecionadas = medidas
        },

        getMedidas() {
            new HttpRequest().Post(this.url+'getMedidas')
                .then(dados =>{ this.optMedidas = dados.data })
        },

        /**
         *  Método para salvar ou editar as coletas
         *  também cria o objeto a ser levado ao back
         *  @authord Vitor Hugo 🐨
         */
        salvarCadastro(){
            this.loadingPanelEagle = true
            let obj = {
                'id' : this.id,
                'tcdescricao' : this.valueDescricao,
                'tccodigocliente' : this.empresaSelecionada[0],
                'tcmcodigotipocoletaunidade' : this.medidasSelecionadas
            }
            new HttpRequest().Post(this.url+'salvar', obj)
                .then(dados=>{
                    if(dados.code == 201){
                        this.$router.push({name:'listarColeta'})
                    } 
                    else{
                        conectionError()
                    }
                    this.loadingPanelEagle = false
            })
        },
    },
    async mounted(){
        this.getMedidas()
        if(this.$route.params.id != undefined){
            this.loadingPanelEagle = true
            this.id = this.$route.params.id
            new HttpRequest().Post(this.url+'busca/dados', {'id':this.id})
                .then(({data, status}) => {
                    if(status) {
                        this.valueDescricao = data.dados.tcdescricao
                        this.selectedEmpresa = [{
                            value: data.dados.clcodigo,
                            description: data.dados.clnome
                        }]
                        Object.keys(data.unidadesDeMedida).forEach(element => {
                            this.selectedMedidas.push({
                                value: data.unidadesDeMedida[element].tcucodigo,
                                description: data.unidadesDeMedida[element].tcudescricao
                            })
                        })
                    } else {
                        conectionError()
                    }
                })
                .catch((error) => {
                    conectionError()
                })
                .finally(() => {
                    this.loadingPanelEagle = false
                })
        }
    },
})
</script>
<style lang="scss">
    
</style>