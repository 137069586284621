<template>
    <modal-eagle
    title='Alteração de motorista | ajudante'
    :id='idModal'
    @confirmButton='salvaAlteracao'>
        <template #modalBody>
            <b-overlay :show='procurandoInfo' opacity='0.8'>
                <div v-text='veiculo' class='ml-4 mt-3'/>
                <div class="d-flex justify-content-center mt-4 mb-4">
                    <div class="col-5 nopadding">
                        <select-all
                        nameForRadio="seletorMotorista"
                        :labels='[{description:"Motorista"}]'
                        :optionsArray='optSelectMotoristas'
                        :selected='preMotorista'
                        :disabled='!optMotoristas.length'
                        @changeSelect='mudaMotorista'/>
                    </div>
                    <div class="col-5 nopadding">
                        <select-all
                        nameForRadio="seletorAjudante"
                        :labels='[{description:"Ajudante"}]'
                        :optionsArray='optSelectAjudante'
                        :selected='preAjudante'
                        :disabled='!optMotoristas.length'
                        @changeSelect='mudaAjudante'/>
                    </div>
                </div>
            </b-overlay>
        </template>
        <template #modalFooter>
            <div >
                <simple-button
                :icon='iconClose'
                event='click'
                type='red'
                text='Cancelar'
                @click='closeModal'/>
                <simple-button
                :disabled='$v.$invalid'
                :icon='iconConfirm'
                text='Salvar'
                event='click'
                @click='salvaAlteracao'/>
            </div>
        </template>
    </modal-eagle>
</template>

<script>
import selectAll from '@/components/Atom/Select/SelectAll.vue'
import modalEagle from '@/components/Atom/Modal/ModalEagle.vue'
import simpleButton from '@/components/Atom/Buttons/SimpleButton.vue'
import { required } from 'vuelidate/lib/validators'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { conectionError } from '@/Services/Helpers/swellHeper'
import { mdiClose, mdiCheck } from '@mdi/js'
import Vue from 'vue'
export default Vue.extend({
	name:'ModalEditaMotorista',
	components:{
		modalEagle,
		selectAll,
		simpleButton,
	},
	props: {
		idModal:{
			type: [Number, String],
			required: true
		},
		veiculo:{
			type: String,
			required: true
		},
	},
	data() {
		return {
			iconClose: mdiClose,
			baseUri:'/finder/painel/',
			iconConfirm: mdiCheck,
			optMotoristas: [],
			todosAjudantes: [],
			optAjudante: [],
			preMotorista:[],
			preAjudante:[],
			procurandoInfo:false,
			novoMotora:'',
			novoAjudante:'',
			idVeiculo:'',
			mtnome:'', 
			ajudante_nome:'',
		}
	},
	validations:{
		novoMotora: { required }
	},
	methods:{

		/**
		 * @param {number[]} arr 
		 * @param {string} mtnome 
		 */
		mudaAjudante(arr, mtnome){
			this.novoAjudante= arr[0]
			this.ajudante_nome = mtnome
		},

		/**
		 * @param {number[]} arr 
		 * @param {string} mtnome 
		 */
		mudaMotorista(arr, mtnome){
			this.novoMotora = arr[0]
			this.mtnome = mtnome
		},

		/**
		 * @description Essa função deve ser utilizada por uma referência
		 * para "ativar" essa modal, os argumentos da função
		 * são os necessários para conseguir popular a modal com as
		 * infomações corretas
		 * @param {int} cliente     - código do cliente
		 * @param {int} idMotorista - motorista atual do veículo
		 * @param {int} idAjudante  - ajudante atual
		 * @param {int} veiculoId   - id do veiculo a ser editado
		 * @author Gui 🍺🍺
		 */
		preparaModal(cliente, idMotorista, idAjudante, veiculoId){
			this.idVeiculo = veiculoId
			var obj = {cliente, idMotorista, idAjudante}
			this.showModal()
			this.fazBuscaMotoras(obj)
		},

		closeModal(){
			this.$bvModal.hide(this.idModal)
		},

		/**
		 * @description Limpa as variáveis de controle e "prepara" o campo
		 * para trocar o motorista associado
		 */
		showModal(){
			this.preMotorista = []
			this.preAjudante = []
			this.novoMotora = ''
			this.novoAjudante = ''
			this.$bvModal.show(this.idModal)
			this.procurandoInfo = true
		},

		/**
		 * @description Procura pelos trabalhadores da empresa selecionada
		 * @param {int} obj.veiculoId    - id do veículo
		 * @param {int} obj.novoMotora   - id do novo motorista
		 * @param {int} obj.novoAjudante - id do novo ajudante
		 * @param {string} obj.origin    - de onde veio a request
		 */
		async fazBuscaMotoras(obj){
			this.optMotoristas = []
			this.preMotorista = []
			this.preAjudante = []
			let uri = this.baseUri + 'procurar/motoristas'
			await new HttpRequest().Post(uri, obj)
				.then((data)=>{
					this.optMotoristas = data.data.motoristas
					this.todosAjudantes = data.data.ajudantes
					if(data.data.mot)
						this.preMotorista = data.data.mot
					if(data.data.ajudante)
						this.preAjudante = data.data.ajudante
				})
				.catch((error) => { '' })
				.finally(() => {
					this.procurandoInfo = false
				})
		},

		/**
		 * @description Envia as alterações do usuário para o back-end,
		 * uma vez que deu certo, ele dispara o evento avisando
		 * que o painel que a informação foi alterada
		 * @fires motoristAtualizado
		 */
		salvaAlteracao(){
			this.procurandoInfo = true
			new HttpRequest()
				.Post('/finder/painel/atualiza/motorista', this.obj)
				.then((data)=>{
					this.procurandoInfo = false
					if(data.data?.success){
						this.$emit('motoristAtualizado')
						this.$bvModal.hide(this.idModal)
					}else
						conectionError()
				})
		}
	},
	computed: {
		/**
		 * @description Objeto a ser enviado para o
		 * back-end para salvar as alterações feitas aqui
		 * @author Gui 🍺🍺
		 */
		obj(){
			var obj = {
				veiculoId:this.idVeiculo,
				motoristaId: this.novoMotora,
				ajudanteId: this.novoAjudante,
				origim: 'M'
			}
			if(!obj.motoristaId)
				obj.motoristaId = ''
			if(!obj.ajudanteId)
				obj.ajudanteId = ''
			return obj
		},

		optSelectMotoristas(){
			var semMotora = {
				'description': 'Sem motorista',
				'value': 0
			}
			let filter = this.optMotoristas
			if (this.novoAjudante)
				filter = filter.filter(e => {
					return e.value != this.novoAjudante
				})
			return [semMotora, ...filter]
		},

		optSelectAjudante(){
			var semAjudante = {
				'description': 'Sem Ajudante',
				'value': 0
			}
			let filter = this.todosAjudantes
			if (this.todosAjudantes)
				filter = filter.filter(e => {
					return e.value != this.todosAjudantes
				})
			return [semAjudante, ...filter]
		},
	},
})
</script>