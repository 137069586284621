<template>
	<panelEagle id="resumoDiario" :loading="carregando">
		<div class="col-sm-12 row nopadding">
			<div id="titulo_botoes" class="col-sm-12 row nopadding">
				<div class="col-sm-6">
					<titulo titulo="Resumo Diário" :icon="mdiHours24" />
				</div>
				<div class="col-sm-6 divDontPrint">
					<botoesEIG
						:loading="loadingExportar"
						@gerarRelatorio="gerarRelatorio"
						@exportarRelatorio="exportarRelatorio"
						:disabled="$v.relatorio.$invalid"
						:title="$v.$invalid ? invalido : valido"
						:disabledDropdown="$v.dataRelatorio.$invalid"
					/>
				</div>
			</div>
			<slideUpAndDown>
				<template #filters>
					<div class="col-sm-12"><hr /></div>
					<div class="col-sm-12 row nopadding">
						<div class="col-sm-3 nopadding">
							<rangeWithLimit
								titulo="Intervalo"
								name="seletorIntervalo"
								:isObrigatorio="true"
								@changeInput="(value) => (relatorio.intervalo = value)"
								opens="right"
							/>
						</div>
						<div class="col-sm-3 nopadding" id="seletorEmpresa">
							<selectAll
								nameForRadio="seletorEmpresas"
								:hasSelectAll="true"
								:labels="[{ description: 'Empresas*' }]"
								:isMultiple="true"
								:optionsArray="optSelectEmpresa"
								:selected="selectedEmpresa"
								@changeSelect="mudaEmpresa"
							/>
						</div>
						<div class="col-sm-3 nopadding">
							<selectAll
								nameForRadio="seletorVeiculosGrupos"
								firstSelected="V"
								v-bind="{
									loading: loadingVeiculos,
									hasSelectAll: true,
									isMultiple: true,
									optionsArray: optSelectVeiculos,
									disabled: $v.relatorio.cliente.$invalid,
									labels: labelSeletorVeiculoGrupos,
									ref: 'selectVG',
								}"
								v-on="{ changeCheck: mudaCheck }"
								@changeSelect="mudaVeiculo"
							/>
						</div>
						<div class="col-sm-3 nopadding">
							<ButtonsFilters
								zeroValue="0"
								label="Considerar como intervalo acima de"
								:arrayButtons="botoesFiltro"
								@buttonsFiltersChange="mudaFiltro"
							/>
						</div>
					</div>
				</template>
			</slideUpAndDown>
			<div class="col-sm-12"><hr /></div>
			<div class="col-sm-12 nopadding">
				<TableRelatorio :htmlRel="''">
					<template #thead>
						<tr>
							<td colspan="4">Saída</td>
							<td colspan="3">Intervalo</td>
							<td colspan="4">Chegada</td>
							<td colspan="3"></td>
						</tr>
						<tr>
							<!-- SAIDA -->
							<td>Data</td>
							<td>Horário</td>
							<td>Local</td>
							<td>Hodômetro</td>
							<!-- INTERVALO -->
							<td>Inicio</td>
							<td>Fim</td>
							<td>Tempo</td>
							<!-- CHEGADA -->
							<td>Data</td>
							<td>Horário</td>
							<td>Local</td>
							<td>Hodômetro</td>
							<!-- LIVRE -->
							<td rowspan="2">Colaborador</td>
							<td rowspan="2">Observações</td>
							<td rowspan="2">Cargas</td>
						</tr>
					</template>
					<template slot="tbody" v-if="RelatorioInvalido">
						<status :statusBar="status" colspanForTd="14" typeBar="tr" />
					</template>
					<template slot="tbody" v-else>
						<template v-for="(veiculo, placa) in dataRelatorio">
							<tr :key="placa" class="trPlaca">
								<td colspan="14" class="pl-0 backColorTdPDF">
									<span v-if="retornaTags(veiculo)" 
										class="newbadge badge__style">
										<span class="badge__placa badge__padding">
											{{ placa }}
										</span>
										<div class="badge__tag" v-html="retornaTags(veiculo)" />
									</span>
									<span v-else class="newbadge">{{ placa }}</span>
								</td>
							</tr>
							<template v-for="(dia, data) in veiculo">
								<tr :key="data + placa + 1">
									<td class="info">
										<!-- {{dia}} -->
										{{ data }}
									</td>
									<td class="info">
										{{ dia.generalInfo.horarioInicio }}
									</td>
									<td class="info">
										{{ dia.generalInfo.localInicio }}
									</td>
									<td class="info">
										{{ formataHodometro(dia, "hodometroInicio") }}
									</td>
									<td class="info">
										<span
											v-for="(ini, i) in dia.InicioIntervalo"
											v-show="showLinhaIntervalo(dia, i)"
											:key="i"
										>
											{{ ini }}
											<br />
										</span>
									</td>
									<td class="info">
										<span
											v-for="(fim, i) in dia.FinalIntervalo"
											v-show="showLinhaIntervalo(dia, i)"
											:key="i"
										>
											{{ fim }}
											<br />
										</span>
									</td>
									<td class="info">
										<span
											v-for="(tempo, i) in dia.TempoTotalIntervalo"
											v-show="showLinhaIntervalo(dia, i)"
											:key="i"
										>
											{{ tempo }}
											<br />
										</span>
									</td>
									<td class="info">{{ dia.generalInfo.dataFim }}</td>
									<td class="info">
										{{ dia.generalInfo.horarioFim }}
									</td>
									<td class="info">
										{{ dia.generalInfo.localFim }}
									</td>
									<td class="info">
										{{ formataHodometro(dia, "hodometroFim") }}
									</td>
									<td class="info">{{ dia.generalInfo.motorista }}</td>
									<td class="tdObs info">
										<!-- mdiPencil -->
										<div event="click" @click="clickObs(dia, data)">
											<baseIcon
												size="18"
												class="iconObs cursor-pointer"
												:icon="mdiPencil"
											/>
										</div>
										<div v-if="dia.generalInfo.showObsBox">
											<textarea
												name="inputObs"
												v-model="obsEmEdicao"
												class="textObs"
												@keyup.enter="clickObs(dia, data)"
												@keyup.esc="cancelaEdicao(dia)"
											/>
										</div>
										<div style="padding-top: 10%" v-else>
											{{ dia.generalInfo.observacao }}
										</div>
									</td>
									<td class="info">{{ dia.generalInfo.cargas }}</td>
								</tr>
								<tr :key="data + placa">
									<td class="p-0 m-0" colspan="14">
										<span class="rodaPe m-0">
											{{
												"Total jornada: " +
												dia.generalInfo.tempoTotalJornada +
												" Total de intervalo: " +
												calculaIntervalo(dia)
											}}
										</span>
									</td>
								</tr>
							</template>
						</template>
					</template>
				</TableRelatorio>
			</div>
		</div>
	</panelEagle>
</template>
<script>
import { mdiHours24, mdiPencil } from "@mdi/js";
import { required } from "vuelidate/lib/validators";
import panelEagle from "@/components/Atom/Panel/PanelEagle";
import { EmpresasService } from "@/Services/auth/Empresas.service";
import { FiltrosService } from "@/Services/filtros/filtros.Service";
import { HttpRequest } from "@/Services/auth/HttpRequest.Service";
import { DateTime, Duration } from "luxon";
import { mapGetters } from "vuex";
import { conectionError } from "@/Services/Helpers/swellHeper";

export default {
	name: "resumoDiario",
	components: {
		panelEagle,
		baseIcon: require("@/components/Atom/Icon/BaseIcon.vue").default,
		status: require("@/components/Atom/StatusInformation/StatusInformation").default,
		rangeWithLimit: require("@/components/Atom/Datas/InputRangeWithLimit").default,
		titulo: require("@/components/Atom/Header/Titulo").default,
		botoesEIG: require("@/components/Atom/Buttons/BasicButtonsRelatoriosEIG").default,
		selectAll: require("@/components/Atom/Select/SelectAll").default,
		ButtonsFilters: require("@/components/Atom/Buttons/ButtonsFilters").default,
		TableRelatorio: require("@/components/Atom/Table/TableRelatorio").default,
		slideUpAndDown: require("@/components/Atom/SlideUpAndDown/SlideUpAndDown")
			.default,
	},

	validations: {
		relatorio: {
			cliente: { required },
			veiculos: { required },
		},
		dataRelatorio: { required },
	},

	data() {
		const Filtro = [
			{
				value: "10",
				width: "31.5%",
				isSelected: false,
				text: "10 min",
			},
			{
				value: "15",
				width: "31.5%",
				isSelected: false,
				text: "15 min",
			},
			{
				value: "30",
				width: "31.5%",
				isSelected: false,
				text: "30 min",
			},
			{
				value: "60",
				width: "31.5%",
				isSelected: false,
				text: "60 min",
			},
			{
				value: "90",
				width: "31.5%",
				isSelected: false,
				text: "90 min",
			},
			{
				value: "120",
				width: "31.5%",
				isSelected: false,
				text: "2 horas",
			},
		];
		return {
			mdiPencil: mdiPencil,
			mdiHours24: mdiHours24,
			loadingVeiculos: false,
			status: "info",
			teste: false,
			invalido: "Selecione uma empresa e um veiculo ou grupo de veiculos",
			valido: "Gerar relatório",
			urlBase: "/telemetria/relatorios/resumo/diario/",
			optSelectVeiculos: [],
			optSelectEmpresa: new EmpresasService().Get(),
			carregando: false,
			labelSeletorVeiculoGrupos: [
				{ indexDFH: "V", description: "Veiculos*" },
				{ indexDFH: "GV", description: "Grupos*" },
				{ indexDFH: "TAG", description: "Tags*" },
			],
			botoesFiltro: Filtro,
			dataGEV: [],
			relatorio: {
				busca: "",
				cliente: [],
				intervalo: "",
				veiculos: [],
				tempoIntervalo: 0,
			},
			loadingExportar: [false, false, false],
			dataRelatorio: [],
			obsEmEdicao: "",
			selectedEmpresa: [],
		};
	},

	methods: {
		...mapGetters(["getMaster"]),

        retornaTags(dados) {
			let html = ''
			for (const key in dados) {
				let dd = dados[key].generalInfo?.tags
				if (dd && typeof dd == 'string') {
                    let tags = JSON.parse(dados[key].generalInfo.tags)
                    tags = _.orderBy(tags, ['descricao'], ['asc'])
                    for (const k in tags) {
                        html += `
                        <div
                            class="tag-style"
                            title="${tags[k].descricao}"
                            style="
                                background-color:${tags[k].cor};
                            "
                        >
                            ${tags[k].descricao}
                        </div> `
                    }
                    return html
                }
			}
			return ''
        },

		formataHodometro(dia, tipo) {
			return Number(dia.generalInfo[tipo]).toFixed(0);
		},

		showLinhaIntervalo(dia, i) {
			return this.relatorio.tempoIntervalo <= dia.TempoTotalIntervaloEmMinutos[i];
		},

		clickObs(dia, data) {
			if (dia.generalInfo.showObsBox) {
				this.obsEmEdicao = this.obsEmEdicao.trim();
				let dif = dia.generalInfo.observacao != this.obsEmEdicao;
				dia.generalInfo.observacao = this.obsEmEdicao;
				if (dif) this.saveObs(dia, data);
			} else {
				this.obsEmEdicao = dia.generalInfo.observacao;
			}
			dia.generalInfo.showObsBox = !dia.generalInfo.showObsBox;
		},

		cancelaEdicao(dia) {
			dia.generalInfo.showObsBox = !dia.generalInfo.showObsBox;
		},

		saveObs(dia, data) {
			this.toogleCarregando();
			let obj = {
				obsplaca: dia.generalInfo.placaEPrefixo[0],
				obsdata: data,
				obsorigem: "RD",
				obsdescricao: dia.generalInfo.observacao,
			};
			new HttpRequest()
				.Post(this.urlBase + "salvaObs", { obj })
				.finally(() => this.toogleCarregando());
		},

		gerarRelatorio() {
			this.toogleCarregando();
			// this.status = 'loading'
			this.dataRelatorio = [];
			new HttpRequest()
				.Post(this.urlBase + "gerar", { ...this.relatorio, ...this.intervalo })
				.then((data) => {
					this.status = "info";
					if (data.status) {
						if (data.data.length != 0) this.dataRelatorio = data.data;
						else this.status = "error";
					} else {
						this.status = "error2";
					}
				})
				.catch(() => conectionError())
				.finally(() => this.toogleCarregando());
		},

		toogleCarregando() {
			this.carregando = !this.carregando;
		},

		procuraVeiculosEGrupos(arr) {
			if (arr.length > 0) {
				this.loadingVeiculos = true;
				new FiltrosService()
					.dados_filtros(arr, ["V", "GV", "TAG"])
					.then((data) => {
						this.dataGEV = data;
						this.optSelectVeiculos =
							this.dataGEV[this.$refs.selectVG.labelSelected];
						this.loadingVeiculos = false;
					});
			} else {
				this.$refs.selectVG.clearAll();
			}
		},

		setLoading(tipo = null) {
			let arr = [tipo == "pdf", tipo == "xls", tipo == "csv"];
			this.loadingExportar = arr;
		},

		exportarRelatorio(value) {
			var obj = {
				data: this.dataRelatorio,
				tipo: value,
				tempo: this.relatorio.tempoIntervalo,
				intervalo: this.intervalo,
			};
			this.setLoading(value);
			var root = process.env.VUE_APP_ROOT;
			new HttpRequest().Post(this.urlBase + "exportar", obj).then((data) => {
				this.setLoading();
				if (data.status) window.open(root + "/" + data.data.local);
			});
		},

		/**
		 * Eventos de mudança de filtros/seletores e etc
		 */
		mudaCheck(value) {
			this.relatorio.busca = value;
			if (this.dataGEV[value] !== undefined) {
				this.optSelectVeiculos = this.dataGEV[value];
			}
		},

		mudaEmpresa(arr) {
			//Manutenção
			this.status = "info";
			this.$refs.selectVG.clearAll();
			this.dataGEV = [];
			this.dataRelatorio = [];
			this.relatorio.cliente = arr;
			this.procuraVeiculosEGrupos(arr);
		},

		mudaFiltro(value) {
			if (value !== undefined) {
				this.relatorio.tempoIntervalo = value[0];
			}
		},

		mudaVeiculo(arr) {
			this.relatorio.veiculos = arr;
			this.status = "info";
			this.dataRelatorio = [];
		},

		calculaIntervalo(dia) {
			let y = "00:00:00";
			let tempoTotalIntervalo = [];
			var arrHorarios = dia["TempoTotalIntervalo"];
			for (const horario of arrHorarios) {
				tempoTotalIntervalo.push(horario);
			}
			if (tempoTotalIntervalo.length > 0) {
				y = this.somaTempo(tempoTotalIntervalo);
			}
			return y;
		},

		/**
		 * Método para somar o total de horas de intervalo
		 * controle utilizado para criar a primeira vez a
		 * variavel total como um objeto data
		 * faz a validacao pelo filtro de tempo tbm
		 * " Considerar como intervalo acima de "
		 * @param Array = arrTempo (array com as horas)
		 * @return String = resposta (horas somadas)
		 * @author Vitor Hugo 🐨 | Lucas Eduardo
		 */
		somaTempo(arrTempo) {
			var minutos = 0;
			for (const horario of arrTempo) {
				var data = DateTime.fromFormat(horario, "HH:mm:ss");
				data = data.toObject();
				var hora = {
					hour: data.hour,
					minute: data.minute,
					second: data.second,
					millisecond: data.millisecond,
				};
				var dur = Duration.fromObject(hora);
				dur = dur.shiftTo("minutes").values.minutes;
				if (dur >= this.relatorio.tempoIntervalo) {
					minutos += dur;
				}
			}
			var horaTotal = Duration.fromObject({ minutes: minutos });
			var { hours, minutes, seconds } = horaTotal.shiftTo(
				"hours",
				"minutes",
				"seconds"
			);
			hours = this.retorna2casa(hours);
			minutes = this.retorna2casa(minutes);
			seconds = this.retorna2casa(seconds);
			return `${hours}:${minutes}:${seconds}`;
		},

		retorna2casa(casa) {
			var x = casa.toFixed(0);
			return ("" + x).length !== 1 ? x : "0" + x;
		},
	},

	computed: {
		RelatorioInvalido() {
			if (this.dataRelatorio.length < 1) {
				return true;
			} else {
				return false;
			}
		},
		intervalo() {
			var intervalo = this.relatorio.intervalo.split(" - ");
			var obj = {
				inicio: intervalo[0],
				fim: intervalo[1],
			};
			return obj;
		},
	},

	mounted() {
		if (!this.getMaster()) {
			this.selectedEmpresa = this.optSelectEmpresa;
		}
	},
};
</script>
<style lang="scss">
#resumoDiario {
	font-size: 13px !important;
	thead {
		position: sticky;
		top: -20px;
		z-index: 1;
		background-color: #f5f5f5;
		td {
			outline: 1px solid lightgray;
			// background: white;
			text-align: center !important;
		}

		.info {
			border-left: lightgray solid 1px;
			background: #eeeeee;
		}

		.trPlaca {
			padding: 50px !important;
		}
		.tdPlaca {
			// background: #fff;
			.placa-aaa {
				border: 1px solid red !important;
				display: block;
				width: 30%;
				font-size: 13px !important;
				margin-top: 3%;
				text-align: center;
				color: #fff;
				background-color: #777;
				text-align: center;
				margin-top: 20px;
				white-space: nowrap;
				font-weight: bold;
				height: 30px !important;
				padding-top: 8px;
			}
		}
		.fimJornada {
			font-size: 13px;
			text-align: center;
			background: #afafaf;
		}

		.textObs {
			max-width: 150px;
			max-height: 80px;
		}

		.tdObs {
			width: 200px;
		}

		.iconObs {
			float: right;
			color: blue;
		}
	}

	.badge__tag {
		display: flex;
		justify-content: center;
		gap: 10px;
		align-items: center;
		width: 100%;
		max-width: 500px;
	}
	.badge__padding {
		padding:0 0.5em;
	}
	.badge__totalAlertas {
		color: #ffff;
		font-weight: bold;
	}

	.badge__style {
		display:inline-flex;
		justify-content:space-around
	}

	.badge__placa {
		position:relative;
		left:0%;
	}
	.tag-style {
		color: #ffff !important;
		border-radius: 5px 0px 5px 0px;
		// width: 25%;
		padding: 3px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: bold;
	}
}
</style>
