<template>
	<div id='first-step'>
		<div class='seletor-justifica'>
			<select-all
			nameForRadio='selCliente'
			:isMultiple='false'
			:hasSelectAll='false'
			:labels='labelSelectJustifica'
			:selected='selecionadoData'
			:optionsArray='optSelectJustifica'
			:disabled='existeAjuste'
			@changeSelect='mudaMotivo'/>
		</div>
	</div>
</template>

<script>
export default {
	name:'firstStep',
	components:{
		selectAll: require('@/components/Atom/Select/SelectAll').default,
	},
	props: {
		selecionado: {
			type: Array,
			required:true,
		},
		optSelectJustifica:{
			type: Array,
			required:true
		},
		existeAjuste:{
			type: Boolean,
			default:false
		}
	},
	data(){
		return{
			labelSelectJustifica:[
				{indexDFH:'JUS', description:'Selecionar*'},
			],
			selecionadoData:[],
		}
	},
	methods: {
		mudaMotivo(arr, cod){
			let motivo = undefined
			if(arr.length) motivo = arr[0]
			this.$emit('motivo-justifica', motivo)
		}
	},
	mounted(){
		this.selecionadoData = this.selecionado
	}
}
</script>

<style lang="scss" scoped>
#first-step{
	.username{
		font-size: 15px;
		margin-left: 10px;
	}
	.cargo{
		font-size: 13;
		margin-left: 25px;
	}
	.seletor-justifica{
		width: 300px;
		margin-top: 15px;
		margin-left: 10px;
	}
}
</style>