/**
 * É responsavel por procurar o ponto da rota mais proximo ao ponto que tu mandou
 * pode ficar um pouquinho dps do ponto que tu mandou
 * faz um filtro pela array do rastro que é enviado para a polilinha
 * e cria uma array que vai ser a rota ate o ponto enviado
 * @param  Array - ponto que tu vai querere fazer a busca - [lat, lng]
 * @param  Array - array onde tu vai buscar - [lat, lng]
 * @return Object - {pontoMaisProximo:[lat, lng], rotaAoPonto:[[lat, lng], [lat, lng]]}
 * @author Lucas Eduardo
 */
export function latLngMaisProximo(ponto, arrayLatLon){
    if(ponto.length != 2 || !arrayLatLon.length){
        throw {
            "primeiro parâmetro inválido":  [ponto.length != 2, ponto],
            "segundo parâmetro inválido":   [!arrayLatLon.length, arrayLatLon]
        }
    }
    var dist = 100
    var latLang = []
    for(const index in arrayLatLon){
        var distancia = distanciaLatLonEmKm(ponto, arrayLatLon[index])
        if(distancia <= dist){
            dist = distancia
            latLang = arrayLatLon[index]
        }
    }
    return latLang
}


/**
 * Função usa a formula de Haversine para calcular a distância
 * entre duas coordenadas.
 * Recebe duas array [lat, lon], [lat, lon]
 * e devolve a distancia entre elas, (em linha reta)
 * entao caso queira pegar a distancia de uma rota, 
 * tem que mandar varias vezes a as lat/lon de toda a rota
 * ai fica show
 * 
 * @author Pitágoras
 * editado por @Lucas_Eduardo
 */
export function distanciaLatLonEmKm(position1, position2){
    if(position1.length != 2 || position2.length != 2){
        throw {
            "primeiro parâmetro inválido": [position1.length != 2, position1],
            "segundo parâmetro inválido":  [position2.length != 2, position2],
        }
    }
    var deg2rad = function(deg){ 
        return deg * (Math.PI / 180) 
    }
    var R = 6371
    var dLat = deg2rad(position2[0] - position1[0])
    var dlon = deg2rad(position2[1] - position1[1])
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
            + Math.cos(deg2rad(position1[0]))
            * Math.cos(deg2rad(position1[0]))
            * Math.sin(dlon / 2) * Math.sin(dlon / 2)
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    return ((R * c*1000))
}