<template>
    <eaglePanel id='Logs' :loading='gerandoRel'>
        <div class="col-lg-12 row">
            <div class="col-lg-6 nopadding">
                <titulo
                titulo='Logs'
                :icon='mdiFileKey'/>
            </div>
            <div class="col-lg-6 nopadding divDontPrint">
                <botoes
                    :disabledDropdown='!this.dataRelatorio.length'
                    :disabled='$v.$invalid'
                    :loading='carregando'
                    @exportarRelatorio='exportar'
                    @gerarRelatorio='geraRelatorio'/>
            </div>
        </div>
        <slide>
            <slot slot='filters'>
                <div class="col-sm-12"><hr></div>
                <div class="col-lg-12 row divDontPrint nopadding">
                    <div class="col-sm-12 praficarmelhor row nopadding">
                        <div class="col-lg-3 nopadding  ">
                            <dayPicker 
                                id='dataRelLog'
                                name='data'
                                opens='right'
                                :isObrigatorio='false'
                                titulo='Intervalo*'
                                @changeInput='mudaData'/>
                        </div>
                        <div class="col-lg-3 nopadding tamanho">
                            <selectAll 
                                nameForRadio='seletorEmpresas'
                                :isMultiple='true'
                                :hasSelectAll='true'
                                :selected='firstOne'
                                :extraClass='{"border border-danger": $v.formulario.empresas.$anyError}'
                                :optionsArray='optSelectEmpresas'
                                :labels='labelSeletorEmpresas'
                                @close='$v.formulario.empresas.$touch()'
                                @changeSelect='selecionaEmpresa'/>
                        </div>
                        <div class="col-lg-3 nopadding">
                            <selectAll
                                ref="refUsuarios"
                                nameForRadio='seletorUsuario'
                                :isMultiple='true'
                                :hasSelectAll='true'
                                :disabled='!optSelectUsuario.length'
                                :optionsArray='optSelectUsuario'
                                :labels='labelSeletorUsuario'
                                @changeSelect='selecionaUsuario'/>
                        </div>
                        <div class="col-lg-3 nopadding">
                            <selectAll 
                                nameForRadio='seletorTipoAcao'
                                :isMultiple='true'
                                :hasSelectAll='true'
                                :optionsArray='optSelectTipoAcao'
                                :labels='labelSeletorTipoAcao'
                                @changeSelect='selecionaTipoAcao'/>
                        </div>
                        <div class="col-lg-3 nopadding mt-2">
                            <inputS
                                name='busca'
                                label='Busca'
                                @changeInput='mudaQuery'/>
                        </div>
                        <required/>
                    </div>
                </div>
            </slot>
        </slide>
        <div class="col-sm-12"><hr></div>
        <div class="col-sm-12">
            <tableR>
                <thead>
                    <br>
                    <tr>
                        <th>Ação</th>
                        <th>Data</th>
                        <th>Usuário</th>
                        <th>IP</th>
                        <th>Dado Antigo</th>
                        <th>Dado Novo</th>
                        <th>Local</th>
                    </tr>
                </thead>
                    <tbody v-if='dataRelatorio.length'>
                        <template v-for='(rl, i  ) in dataRelatorio'>
                            <tr :key="`${i}__log_rel`">
                                <td class='acao'>{{rl.lcodigoevento}}</td> 
                                <td class='displayData'>{{rl.ldata}}</td>
                                <td v-text='rl.name'/>
                                <td v-text='rl.lip'/>
                                <td> 
                                    <span v-html='dadoAntigo(rl)'/>
                                </td>
                                <td>
                                    <span v-html="dadoNovo(rl)"/>
                                </td>
                                <td class='displayData' v-text='rl.log_name'/>
                            </tr>
                        </template>   
                    </tbody> 
                    <tbody v-else>
                        <statusI 
                        typeBar="tr"
                        :statusBar="statusbar"
                        />
                    </tbody>
            </tableR>
        </div>
    </eaglePanel>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { mdiFileKey } from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { DateTime } from 'luxon'
import Vue from 'vue'
import { conectionError } from '@/Services/Helpers/swellHeper';
export default Vue.extend({
    name:'logs',
    components:{
        'statusI'    : require('@/components/Atom/StatusInformation/StatusInformation').default,
        'eaglePanel' : require('@/components/Atom/Panel/PanelEagle').default,
        'titulo'     : require('@/components/Atom/Header/Titulo').default,
        'botoes'     : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
        'dayPicker'  : require('@/components/Atom/Datas/InputRangeWithLimit').default,
        'selectAll'  : require('@/components/Atom/Select/SelectAll').default,
        'inputS'     : require('@/components/Atom/Inputs/InputSimple').default,
        'slide'      : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
        'tableR'     : require('@/components/Atom/Table/TableSimples').default,
        'required'   : require('@/components/Atom/Footer/RequiredFields').default,
    },
    validations:{
        formulario:{
            empresas : { required },
        }
    },
    data(){
        return{
            mdiFileKey:mdiFileKey,
            formulario:{
                intervalo : '',
                empresas  : [] ,
                usuario   : [],
                tipoAcao  : [],
                busca     : '',
            },
            firstOne:[],
            // data
            statusbar     : 'info',
            dataRelatorio : [] ,
            dataExport    : '' ,
            carregando    : Array(false, false, false), 
            // opt
            optSelectUsuario   : [] ,
            optSelectEmpresas  : new EmpresasService().Get(),
            optSelectTipoAcao  : [
                {'value':'1', 'description':'Inserção'},
                {'value':'2', 'description':'Edição'  },
                {'value':'3', 'description':'Exclusão'},
                {'value':'4', 'description':'Acesso'  }
            ],
            // labels
            labelSeletorTipoAcao :[{
                indexDFH     :'', 
                description  :'Tipo de Ação'}],
            
            labelSeletorEmpresas :[{
                indexDFH     :'EM', 
                description  :'Empresas*'}],
            
            labelSeletorUsuario  :[{
                indexDFH     :'US', 
                description  :'Usuários'}],
            // misc
            errorEmpresas: '',
            // links
            urlBase    : '/administrativo/relatorios/logs/',
            gerandoRel: false,
        }
    },
    methods:{
        /**
         * @param {string} data no formato do SQL
         * @return {string} no formato que o usuário entenda.
         */
        formataData(data){
            var dataFinal = DateTime.fromSQL(data)
            return dataFinal.toFormat('dd/LL/yyyy hh:mm:ss')
        },

        /**
         * @param {object} rl
         * @param {object} rl.attributes
         * @return {string} com os dados já formatados
         */
        dadoNovo(rl) {
            var dados = JSON.parse(rl.ldados)
            if (!dados) return ""
            if (dados.attributes) {
                let obj = this.formataObjtHtml(dados.attributes)
                return obj
            }
            return dados
        },

        /**
         * @param {object} rl
         * @param {object} rl.attributes
         * @return {string} com os dados já formatados
         */
         dadoAntigo(rl){
            var dados = JSON.parse(rl.ldados)
            if (!dados) return ""
            if (dados.old) {
                let obj = this.formataObjtHtml(dados.old)
                return obj
            }
            return ""
        },

        /**
         * Faz uma formatação para transformar um obj em uma string
         * formatada para o HTML
         * caso precise formatar para string normal
         * pode fazer um replace de "&nbsp" para "\t"
         * e "<br>" para "\n"
         * ela funciona de maneira recursiva, então se passar um obj com
         * algum obj sendo o filho ele formatará ele bonitinho tbm
         * o parâmetro espacoAdd não deve ser usado
         * a func usa ele recursivamente para que ele fique com as linhas alinhadas
         * quando existem objetos em objetos
         * basicamente essa func pegará um obj assim
         * {"a":"asdasasda", "b":[{"h":23}]}
         * e transformar em um assim 
         *  {
         *      "a":"asdasasda", 
         *      "b":[
         *          {
         *              "h":23
         *          }
         *      ]
         *  }
         * @param {json|array} obj - o obj que vai ser formatado
         * @param {"&nbsp;"} espacoAdd - (não usar) quantidade de espaço que terá por linha
         */
        formataObjtHtml(obj, espacoAdd = "") {
            let espaco = "&nbsp;&nbsp;&nbsp;&nbsp;"
            if (espacoAdd) {
                espaco += espacoAdd
            }
            let br = "<br>"
            let str = ""
            let sinalI = "{"+br
            let sinalF = "}"
            if (Array.isArray(obj)) {
                sinalI = "["+br
                sinalF = "]"
            }
            if (obj == null) {
                sinalI = "null"
                sinalF = ""
            }
            str += sinalI
            let contador = 0
            let tamanho = 0
            if (obj) {
                tamanho = Object.keys(obj).length - 1
            }
            for (const k in obj) {
                let index = `"${k}":`
                if (Array.isArray(obj)) {
                    index = ""
                }
                contador++
                let virgula = ","
                if (contador > tamanho) {
                    virgula = ""
                }
                if (espacoAdd) {
                    if (typeof obj[k] == "object") {
                        let r = this.formataObjtHtml(obj[k], espaco)
                        str += `${espaco}${index}` + r + virgula + br
                    } else {
                        let jsString = JSON.stringify(obj[k])
                        str += `${espaco}${index}${jsString}${virgula}${br}`
                    }
                } else {
                    if (obj[k] === null || obj[k] === undefined) {
                        let jsString = JSON.stringify(obj[k])
                        str += `${espaco}${index}${jsString}${virgula}${br}`
                    } else {
                        let jsString = null
                        try {
                            jsString = JSON.parse(obj[k])
                        } catch (error) {
                            jsString = JSON.stringify(obj[k])
                        }
                        if (typeof jsString != "object") {
                            str += `${espaco}${index}${jsString}${virgula}${br}` 
                        } else {
                            let r = this.formataObjtHtml(jsString, espaco)
                            str += `${espaco}${index}` + r + virgula + br
                        }
                    }
                }
            }
            if (sinalF) {
                str += espacoAdd+sinalF
            }
            return str
        },

        /**
         * @deprecated
         */
        verificaSeDadoMudou(rl, chave){
            var dados = JSON.parse(rl.ldados)
            var nDados = dados.attributes
            var oDados = dados.old ?? false 
            return nDados[chave] !== oDados[chave]
        },

        
        /**
         * @deprecated
         */
        textoAcao(log){
            return this.optSelectTipoAcao.find((opt) => {
                return opt.value === String(log.lcodigoevento)
            }).description
        },

        exportar(value){
            var root =  process.env.VUE_APP_ROOT
            if(this.dataRelatorio.length){
                this.fazBotaoDeExportarGirar(value)
                var obj={
                    'data': this.dataRelatorio,
                    'para': value
                }
                let uri = this.urlBase+'exportar'
                new HttpRequest().Post(uri ,obj).then((data)=>{
                    open(root+'/'+data.data.local)
                }).finally(() => {
                    this.fazBotaoDeExportarGirar()
                })
            }
        },

        fazBotaoDeExportarGirar(value=false){
            if(value == 'pdf'){
                this.carregando = Array(true, false, false)
            }else if(value == 'xls'){
                this.carregando = Array(false, true, false)
            }else if(value=='csv'){
                this.carregando = Array(false, false, true)
            }else{
                this.carregando = Array(false, false, false)
            }
        },

        selecionaEmpresa(value) {
            this.$refs.refUsuarios.clearAll()
            this.limpaRelatorio()
            this.formulario.empresas = value
            if(value.length != 0){
                this.getMotoristas()
            }
        },

        selecionaUsuario(value){
            this.limpaRelatorio()
            this.formulario.usuario = value
        },

        selecionaTipoAcao(value){
            this.limpaRelatorio()
            this.formulario.tipoAcao = value
        },

        mudaQuery(value){
            this.formulario.busca = value
        },

        mudaData(value){
            this.limpaRelatorio()
            this.formulario.intervalo = value
        },

        getMotoristas(){
            var obj={ cliente: this.formulario.empresas }
            let uri = this.urlBase+'listarUsuarios'
            new HttpRequest().Post(uri, obj).then((data)=>{
                this.optSelectUsuario = data.data.users.U
            })
        },

        geraRelatorio(){
            this.limpaRelatorio()
            this.gerandoRel = true
            var obj={'info':this.formulario}
            let uri = this.urlBase+'gerar'
            new HttpRequest().Post(uri, obj)
                .then((data)=>{
                    // this.dataExport = data.data.exp
                    this.dataRelatorio = data.data.logs
                    // if(this.dataExport.length)
                    // 	this.statusbar = 'info'
                    // else
                    // 	this.statusbar = 'error'
                    
                }).catch(() => {
                    conectionError()
                }).finally(() => {
                    this.gerandoRel = false
                })
            
        },

        limpaRelatorio(){
            this.dataRelatorio = []
        },
            
        selectAllClientes(){
            if(this.getMaster())
                this.firstOne = []
            else  
                this.firstOne =  new EmpresasService().Get()
        },
        ...mapGetters(['getMaster']),
    },
    mounted(){
        this.selectAllClientes()
    }
})
</script>
<style lang="scss">
#Logs{
    table {
        th{
            max-width: calc(100%/7) !important;
            min-width: 190px !important;
        }
        th:nth-child(1){
            min-width: 90px !important;
        }
        th:nth-child(2){
            min-width: 160px !important;
        }
        th:nth-child(4){
            min-width: 90px !important;
        }
        th:nth-child(7){
            min-width: 200px !important;
        }
        width: 100% !important;
        overflow-wrap: break-word;  
        word-wrap: break-word; 
        word-break: break-word;
    }
}
</style>