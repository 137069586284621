<template>
    <div class="col-sm-12">
        <modalEagle   
            :id ="idModal"
            title="Cadastro de pontos"
            size  ='lg'>
            <template slot="modalBody">
                <b-overlay :show='salvando'>
                <div class="col-sm-12 row">
                    <div class="col-sm-6 nopadding">
                        <div class="col-sm-12 nopadding">
                            <inputSimple
                            name="inputDescricao"
                            label="Descrição*"
                            :value="ponto.podescricao"
                            class="pl-0"
                            @changeInput="mudaDescricao"/>
                        </div>
                    </div>
                    <div class="col-sm-6 nopadding row">
                        <div class="col-sm-12 nopadding">
                            <selectAll
                            nameForRadio="seletorEmpresa"
                            :isMultiple="false"
                            :selected="empresaSelecionada"
                            class="pr-0"
                            :labels="[{ description: 'Empresa*' }]"
                            :optionsArray="optSelectEmpresas"
                            @changeSelect="mudaEmpresa"/>
                        </div>
                    </div>
                    <div class="col-sm-12 nopadding" id="divLocalizacao">
							<h5>Localização</h5>
							<inputSearch name="procurarPontos" label="Pesquisar" class="pl-0 pr-0" :value="ponto.poendereco"
								@changeInput="mudaInputProcura" @keyupEnter="procuraEndereco" @keyup-esc="limpaEnderecos"
								@clickButtonSearch="procuraEndereco" />
							<b-list-group class="listaResultadoProcura">
								<b-list-group-item v-for="(res, index) in resultadoPesquisa" :key="index" button class="listItem"
									event="click" @click="selecionaPonto(res)">
									{{ res.endereco }}
								</b-list-group-item>
							</b-list-group>
						</div>
                </div>
                <div class="col-sm-12" style="margin-top: 1.0%">
                    <mapa-simples
                    ref="mapaPonto"
                    :height="300"
                    :latitude="latInicial"
                    :longitude="longInicial"
                    :initialZoom="13">
                        <marcador
                        id="matriz"
                        icon="img/matriz.png"
                        :latitude="latLangMatriz[0]"
                        :longitude="latLangMatriz[1]"/>
                        <marcador
                        :latitude="ponto.polatitude != '-' ?
                            Number(ponto.polatitude) : ''"
                        :longitude="ponto.polongitude != '-' ? 
                            Number(ponto.polongitude) : ''"
                        ref="marker"
                        :isDraggable="true"
                        :icon="icon"
                        @drag="atualizaLatLongMarker"
                        @dragend="posicionaMarcador">
                            <popUpMarker>
                                <div class="col-sm-12 nopadding">
                                    <p>Arraste para o local desejado</p>
                                    <basicSlider
                                    ref="slider"
                                    @change="mudaSlider"
                                    :maxValue="500"
                                    :value="ponto.poraio"
                                    :initialValue="ponto.poraio"/>
                                    {{ "Raio: " + ponto.poraio + " Mts" }}
                                </div>
                            </popUpMarker>
                        </marcador>
                        <circleS
                        :radius="ponto.poraio"
                        :center="latLon(ponto.polatitude, ponto.polongitude)"/>
                    </mapa-simples>
                </div>
                </b-overlay>
            </template>
            <template slot="modalFooter">
                <simpleButton 
                    title     ="Salvar"
                    text      ="Salvar"
                    event     ="click" 
                    type      ="blue" 
                    :icon     ="mdiCheckBold"
                    :disabled ="$v.$invalid"
                    @click    ="salvarPonto"
                />
                <simpleButton 
                    title  ="Cancelar"
                    text   ="Cancelar"
                    event  ="click" 
                    type   ="red" 
                    :icon  ="mdiCloseThick"
                    @click ="fecharModal"
                />
            </template>
        </modalEagle>
    </div>
</template>
<script>
    import Vue from 'vue'
    import { required }        from 'vuelidate/lib/validators'
    import { EmpresasService } from '@/Services/auth/Empresas.service'
    import { HttpRequest }     from '@/Services/auth/HttpRequest.Service'
    import {mdiCloseThick, mdiCheckBold} from '@mdi/js'
    import { conectionError }  from '@/Services/Helpers/swellHeper'
export default Vue.extend({
    name:'ModalCadastrarPontos',
    components:{
        'inputSimple' : require('@/components/Atom/Inputs/InputSimple').default,
        'selectAll'   : require('@/components/Atom/Select/SelectAll').default,
        'inputSearch' : require('@/components/Atom/Inputs/InputSearch').default,
        'mapaSimples' : require('@/components/Atom/Mapa/MapaSimples').default,
        'marcador'    : require('@/components/Atom/Mapa/MarkerPoint').default,
        'popUpMarker' : require('@/components/Atom/Mapa/PopupMarker').default,
        'circleS'     : require('@/components/Atom/Mapa/CircleSimple').default,
        'basicSlider' : require('@/components/Atom/Buttons/BasicSlider').default,
        'modalEagle'  : require('@/components/Atom/Modal/ModalEagle').default,
        'simpleButton': require('@/components/Atom/Buttons/SimpleButton').default,
    },
    props: {
		idModal: {
			type: [Number, String],
			required: true
		},
	},
    validations:{
        ponto: {
			podescricao: { required },
			pocodigocliente: { required },
            poendereco : { required },
		},
    },
    data(){
        return{
			empresaSelecionada: [],
            optSelectEmpresas  : new EmpresasService().Get(),
            mdiCheckBold:mdiCheckBold,
            mdiCloseThick:mdiCloseThick,
			ponto: {
				poendereco: '',
				podescricao: '',
				pocodigocliente: '',
				polatitude: -27.1001389,
				polongitude: -52.6270369,
				poraio: 50,
                potipo: 'P',
			},
			resultadoPesquisa: [],
			latInicial: -27.1001389,
			longInicial: -52.6270369,
			latLangMatriz: [],
			icon: 'img/markerPonto.png',
			rota: '/checkpoint/cadastros/parametros/pontos/',
			salvando: false,
        }
    },
    methods:{
        preparaModalCadastrarPonto(){
            this.$bvModal.show(this.idModal)
            this.$nextTick(()=>{
                this.$refs.mapaPonto.validateSize()
            })
            this.ponto.poendereco = ''
            this.ponto.podescricao = ''
            this.ponto.pocodigocliente = ''
            this.ponto.polatitude = -27.1001389
            this.ponto.polongitude= -52.6270369
        },

        limpaEnderecos(){
			this.resultadoPesquisa = []
		},
		/**
		 * @description Método retorna a latitude e longitude para o
		 * raio do ponto
         * @param {string} - latitude
         * @param {string} - longitude
         * @return {array} - latitude e longitude que o raio deve estar no mapa
         * @author Vitor Hugo 🐨
         */
		latLon(latitude, longitude){
			if(latitude !== '-' && longitude !== '-'){
				this.latInicial = Number(latitude)
				this.longInicial = Number(longitude)
				return [Number(latitude), Number(longitude)]
			} else {
				return ['','']
			}
		},

		mudaInputProcura(value){
			this.ponto.poendereco = value
		},

		/**
		 * @description a função decide, de acordo com a rota, se vai salvar ou
		 * atualizar um ponto também envia a lista de disponibilidades
		 * (se for atualizar, também envia a lista * a ser destruida e o ID do
		 * ponto a ser att)
		 * @author Gui 🍺
		 **/
		salvarPonto() {
			this.salvando = true;
			new HttpRequest().Post(this.rota+'salvar', {'obj' : this.ponto}).then((data) => {
				if (data.code == 201) {
                    this.$emit('buscarPontos')
					this.fecharModal()
				} else {
					conectionError();
				}
				this.salvando = false;
			});
		},

		mudaDescricao(inputValue) {
			this.ponto.podescricao = inputValue;
		},

		mudaEmpresa(arr, value, description) {
			this.ponto.pocodigocliente = '';
			if (arr.length > 0) {
				this.ponto.pocodigocliente = arr[0];
			}
		},

		/**
		 * @description Método para fazer a busca de um endereço
		 *  Obs: caso for mexer atentar para a api utilizada,
		 * 	deve ser a do google, pois as outras trazem dados limitados
		 * @author Gui, comentado pelo Vitor Hugo 🐨
		 */
         procuraEndereco(){
			this.salvando = true
			var obj = { endereco: this.ponto.poendereco };
			new HttpRequest().Post(this.rota + 'adresstolatlong', obj)
				.then((data) => {
					this.resultadoPesquisa = data.data.resultado;
				})
				.catch((error) => {
					conectionError()
				})
				.finally(() => {
					this.salvando = false
				})
		},

		atualizaLatLongMarker(drag) {
			this.ponto.polatitude = drag.latlng.lat;
			this.ponto.polongitude = drag.latlng.lng;
		},

		/**
		 * Quando o marcadador para de se mover, essa função procura
		 * por um endereço correspondente a localização do mesmo
		 */
		posicionaMarcador(marker){
			var obj = {
				lat: marker.target._latlng.lat,
				lon: marker.target._latlng.lng,
			};
			this.getEndereco(obj);
		},

		/********************************************
		 * Procura por um endereço próximo ao ponto *
		 * passado pelo obj do parametro            *
		 ********************************************/
		getEndereco(obj) {
			new HttpRequest()
				.Post(this.rota + 'latLangToAdress', obj)
				.then((data) => {
					this.ponto.poendereco = data.data.endereco;
				});
		},

		mudaSlider(value) {
			this.ponto.poraio = value;
		},

		/**
		 * @description Função disparada quando o usuário seleciona um ponto
		 * @author Gui
		 */
         selecionaPonto(ponto) {
			this.ponto.polongitude = Number(ponto.centro[0])
			this.ponto.polatitude = Number(ponto.centro[1])
			this.ponto.poendereco = ponto.endereco
			this.resultadoPesquisa = []
			if (this.ponto.polatitude && this.ponto.polongitude)
				this.$refs.mapa?.flyTo([this.ponto.polatitude, this.ponto.polongitude])
		},

        fecharModal(){
            this.$bvModal.hide(this.idModal)
        }
    },
})
</script>
