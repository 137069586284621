<template>
    <panelEagle id="jornadaTrabalho"
        :loading="loadingPanel">
        <!-- Tabela datelhada -->
        <div v-show="muda">
            <div class="col-sm-12 nopadding">
                <div class="col-sm-12 nopadding row">
                    <div class="col-sm-4">
                        <tituloPage 
                            :icon="mdiAccountClock" 
                            titulo="Jornada de trabalho">
                        </tituloPage>
                    </div>
                    <div class="col-sm-8 divButtonsBasic divDontPrint">
                        <basicButtonsRelatoriosEIG 
                            @gerarRelatorio="gerarRelatorio"
                            @exportarRelatorio="exportarRelatorioDetalhado"
                            :loading='loadingExportar'
                            :disabled="$v.colabsSelDetalhado.$invalid"
                            :disabledDropdown="validaExportarDet">
                            <template>
                                <simpleButton 
                                    text='Detalhado'
                                    :type='"green"'
                                    event="mudar"
                                    :width="'110px'"
                                    :icon='mdiCheckBold'
                                    @mudar="muda = !muda">
                                </simpleButton>
                                <simpleButton 
                                    text='Resumido'
                                    :type='"grey"'
                                    event="mudar"
                                    :width="'110px'"
                                    @mudar="muda = !muda">
                                </simpleButton>
                            </template>
                        </basicButtonsRelatoriosEIG>
                    </div>
                </div>
                <slideUpAndDown>
                    <template slot="filters">
                        <div class="col-sm-12"><hr></div>
                        <div class="col-sm-12 row nopadding">
                            <!-- Input de data -->
                            <div class="col-sm-3 nopadding">
                                <input-range-with-limit
                                name='input-time-detalhado'
                                opens='right'
                                :value="valueIntervalo"
                                :isObrigatorio='true'
                                @changeInput='changeIntervaloDetalhado'/>
                            </div>
                            <!-- Select de empresas -->
                            <div class="col-sm-3 nopadding">
                                <selectAll 
                                nameForRadio="radiosEmpresas"
                                :isMultiple="true" 
                                :labels="labelSelectsEmpresas" 
                                :optionsArray="optionsSelectEmpresa"
                                :selected="selectedEmpresa" 
                                firstSelected="E" 
                                :hasSelectAll="true"
                                @changeSelect="changeEmpresa" >
                                </selectAll>
                            </div>
                            <!-- Select Jornada -->
                            <div class="col-sm-3 nopadding">
                                <selectAll 
                                ref='selectJ'
                                nameForRadio="radiosJornada" 
                                firstSelected="E" 
                                :labels="labelSelectsJornada" 
                                :optionsArray="optionsSelectJ" 
                                :selected="selectedJornada" 
                                :isMultiple="false"
                                :hasSelectAll="false"
                                :disabled="$v.empresasSelecionadas
                                    .$invalid"
                                :loading='carregando'
                                @changeSelect="changeJornada">
                                </selectAll>
                            </div>
                            <!-- Select Colaboradores -->
                            <div class="col-sm-3 nopadding">
                                <selectAll
                                ref='selectCG'
                                nameForRadio='seletorColaboradorGrupo'
                                firstSelected='C'
                                :hasSelectAll="true"
                                :isMultiple="true"
                                :optionsArray="retornaColabAtual"
                                :selected="selectedColabs" 
                                :labels="labelSelectsColabs"
                                :disabled="$v.jornadaSelecionada.$invalid"
                                :loading='carregando'
                                @changeCheck="mudaCheck"
                                @changeSelect="changeColaboradores"/>
                            </div>
                        </div>
                        <!-- Tabela de sumário -->
                        <div class="mt-2 col-sm-12 row tabelaSumarioTabelaDetalhada nopadding">
                            <div class="col-sm-3 row divFeriadoSumarioTabelaDetalhada">
                                <span class="col-sm-12 row pt-1 pl-1">
                                    <div>
                                        <baseIcon
                                        size='18'
                                        class="azulIcone"  
                                        :icon="mdiCheckboxBlankCircle"/>
                                    </div>
                                    <div class="azul">
                                        Feriado
                                    </div>
                                </span>
                                <span
                                class="col-sm-12 row pl-1" 
                                title="Dias trabalhados fora de jornada">
                                    <div>
                                        <baseIcon
                                        size='18'
                                        class="verdeIcone" 
                                        :icon="mdiCheckboxBlankCircle"/>
                                    </div>
                                    <div class="verde">
                                        Dias trabalhados fora de jornada
                                    </div>
                                </span>
                            </div>
                            <!-- Botões de Banco de horas e Ajustes -->
                            <div class="col-sm-3 row divBotoesBancoAjustes">
                                <div v-if="_getCadastrar" 
                                    class="col-xl-6 col-lg-12 nopadding">
                                    <simpleButton
                                    class="buttonBancoHoras"
                                    text='Banco de horas'
                                    width='100%'
                                    event="gerar"
                                    :disabled="$v.relatorio.$invalid"
                                    @gerar="modalBanco()">
                                    </simpleButton>
                                </div>
                                <div v-if="_getCadastrar || _getEditar"
                                    class="col-xl-6 col-lg-12 nopadding">
                                    <simpleButton
                                    class="buttonAjustes"
                                    text='Ajustes'
                                    width='100%'
                                    event="gerar"
                                    @gerar="modalAjustes()">
                                    </simpleButton>
                                </div>
                            </div>
                        </div>
                    </template>
                </slideUpAndDown>
                <div class="col-sm-12"><hr></div>
                <!-- Relatório Detalhado -->
                <tableRelatorio id="teste1">
                    <!-- Cabeçalho -->
                    <slot slot="thead">
                        <tr class="cabecalhoRelatorioDetalhado">
                            <th></th>
                            <th>Dia</th>
                            <th>Início</th>
                            <th class="thFim">Fim</th>
                            <th>Total</th>
                            <th>Trabal.</th>
                            <th v-if='tempoExtra'>Tempo Extra</th>
                            <th>Intervalo</th>
                            <th>Espera</th>
                            <th v-if="extrasParametros[1]">H.E.1</th>
                            <th v-if="extrasParametros[2]">H.E.2</th>
                            <th v-if="extrasParametros[3]">H.E.3</th>
                            <th>100%</th>
                            <th>100% Noturno</th>
                            <th>Falta</th>
                            <th v-if="mostrarReduzida">Reduzida</th>
                            <th>Adicional Noturno</th>
                            <th>Extra Noturno</th>
                            <th v-if="mostrarInterjornada">Interjorn.</th>
                            <th v-if="mostrarIndenizacao">Indeni.</th>
                            <th v-if="mostrarDescansoRem">Descanso Remuner.</th>
                            <th>Eventos</th>
                        </tr>
                    </slot>
                    <slot v-if="nulo == true" slot="tbody">
                        <statusInformation 
                            typeBar="tr" 
                            :colspanForTd ='totalColunas'
                            :statusBar="statusbar">
                        </statusInformation>
                    </slot>
                    <!-- Corpo da tabela -->
                    <slot v-else slot="tbody">
                        <template v-for=" (colab, k) in relatorio">
                            <!-- Linha do nome -->
                            <tr v-if="true" :key="k+'_nome'">
                                <td class="fundoTrNomeMotorista"
                                    :colspan="totalColunas">
                                    <span 
                                        colspan="6"
                                        class="spanNomeMotorista">
                                            {{colab.nome}}
                                    </span>
                                </td>
                            </tr>
                            <tr v-for="(item, y) in colab.jornada"
                                :key="y+k+'_jornada'"
                                :class="defineClassCorTr(item)">
                                <td v-if="_getExcluir || _getCadastrar"
                                    @click="openModalAcoes(item)"
                                    class="tamColIcone tdTabelaDetalhada">
                                    <baseIcon 
                                        :icon="mdiTimetable"
                                        size="18"/> 
                                </td>
                                <td v-else></td>
                                <td class='tdTabelaDetalhada'
                                    v-text='`${item.dia}(${item.diaResumido})`'/>
                                <!-- Inicio da Jornada -->
                                <td class='tdTabelaDetalhada' 
                                    v-html="defineHtmlInicio(item, ehdsr(item))">
                                </td>
                                <!-- Fim da Jornada -->
                                <td class='tdTabelaDetalhada'
                                    v-text='item.fim'/>
                                <!-- Total da Jornada -->
                                <td :class="{tdVermelho:item.temInsercao}"
                                    class='tdTabelaDetalhada'
                                    v-text='decideTextoDetalhadoTotal(item)'/>
                                <!-- Trabalhada da Jornada -->
                                <td class='tdTabelaDetalhada'
                                    :class="{'tdVermelho': item.temInsercao}">
                                    {{item.trabalhada ? item.trabalhada + 
                                    checkAst(item.temInsercao) : ''}}
                                </td>
                                <td v-if='tempoExtra'
                                    class='tdTabelaDetalhada'
                                    v-text='mostraTempoExtra(item)'/>
                                <!-- Horas Intervalo -->
                                <td class='tdTabelaDetalhada'
                                    v-text='item.intervalo'/>
                                <!-- Horas Espera -->
                                <td class='tdTabelaDetalhada'
                                    v-text='item.horaEspera'/>
                                <!-- Horas extras -->
                                <td v-if="extrasParametros[1]"
                                    class='tdTabelaDetalhada'
                                    :class="{tdVermelho: item.temInsercao}">
                                    {{item.extra1 ? item.extra1 + checkAst(item.temInsercao) : ''}}
                                </td>
                                <td v-if="extrasParametros[2]"
                                    class='tdTabelaDetalhada'
                                    :class="{tdVermelho: item.temInsercao}">
                                    {{item.extra2 ? item.extra2 + checkAst(item.temInsercao) : ''}}
                                </td>
                                <td v-if="extrasParametros[3]"
                                    class='tdTabelaDetalhada'
                                    :class="{tdVermelho: item.temInsercao}">
                                    {{item.extra3 ? item.extra3 + 
                                    checkAst(item.temInsercao) : ''}}
                                </td>
                                <!-- Hora extra 100% -->
                                <td class='tdTabelaDetalhada'
                                    :class="{tdVermelho: item.temInsercao || item.temFalta}">
                                    {{decideExtra100Detalhado(item)}}
                                </td>
                                <!-- Hora extra 100% -->
                                <td class='tdTabelaDetalhada'
                                    :class="{tdVermelho: item.temInsercao || item.temFalta}">
                                    {{decideExtra100NoturnaDet(item)}}
                                </td>
                                <!-- Hora falta -->
                                <td class="tdTabelaDetalhada"
                                    :class="{tdVermelho: item.temInsercao
                                        || item.temFalta}">
                                    {{item.horaFalta ? item.horaFalta + 
                                    checkAst(item.temInsercao 
                                    || item.temFalta) : ''}}
                                </td>
                                <!-- Hora reduzida -->
                                <td v-if="mostrarReduzida"
                                    class="tdTabelaDetalhada">
                                    {{item.horaReduzida}}
                                </td>
                                <!-- Adicional Noturno -->
                                <td class="tdTabelaDetalhada">
                                    {{item.adicNoturno}}
                                </td>
                                <!-- Hora Extra Noturna -->
                                <td class="tdTabelaDetalhada">
                                    {{item.horaExtraNot}}
                                </td>
                                <!-- Hora de Interjornada -->
                                <td v-if="mostrarInterjornada"
                                    class="tdTabelaDetalhada">
                                    {{item.interjornada}}
                                </td>
                                <!-- Hora indenização -->
                                <td v-if="mostrarIndenizacao"
                                    class="tdTabelaDetalhada">
                                    {{item.indenizacao}}
                                </td>
                                <!-- Hora descanso remunerado -->
                                <td v-if="mostrarDescansoRem"
                                    class="tdTabelaDetalhada">
                                    {{item.descRemunerado}}
                                </td>
                                <td class="tdTabelaDetalhada">
                                    {{item.horasEventos?item.horasEventos:''}}
                                </td>
                            </tr>
                            <!-- Linha para o total de horas -->
                            <tr v-if="true" :key="k+'_total'"
                                class="fundoTotal">
                                <td colspan="5" 
                                    style="text-align:right!important">
                                    Totalizadores:
                                </td>
                                <!-- Total de horas Trabalhadas-->
                                <td class="tdTabelaDetalhada"
                                    :class="{tdVermelho: 
                                        colab.temInsercaoTotais
                                        || colab.temFaltaTotais}">
                                    {{colab.totalizadores.tottrabalhada ? 
                                    colab.totalizadores.tottrabalhada + 
                                    checkAst(colab.temInsercaoTotais 
                                    || colab.temFaltaTotais) : ''}}
                                </td>
                                <td class='tdTabelaDetalhada' 
                                    v-if='tempoExtra'
                                    v-text='colab.totalizadores.tottempoextra'></td>
                                <!-- Total de horas Intervalo-->
                                <td>{{colab.totalizadores.totintervalo}}</td>
                                <!-- Total de horas Espera-->
                                <td>{{colab.totalizadores.tothoraespera}}</td>
                                <!-- Total de horas Extra 1-->
                                <td class="tdTabelaDetalhada"
                                    v-if="extrasParametros[1]"
                                    :class="{tdVermelho: colab.temInsercaoTotais}">
                                    {{colab.totalizadores.totextra1 ? 
                                    colab.totalizadores.totextra1 + 
                                    checkAst(colab.temInsercaoTotais) : ''}}
                                </td>
                                <!-- Total de horas Extra 2-->
                                <td class="tdTabelaDetalhada"
                                    v-if="extrasParametros[2]"
                                    :class="{tdVermelho: colab.temInsercaoTotais}">
                                    {{colab.totalizadores.totextra2 ? 
                                    colab.totalizadores.totextra2 + 
                                    checkAst(colab.temInsercaoTotais) : ''}}
                                </td>
                                <!-- Total de horas Extra 3-->
                                <td class="tdTabelaDetalhada"
                                    v-if="extrasParametros[3]"
                                    :class="{tdVermelho:colab.temInsercaoTotais}">
                                    {{colab.totalizadores.totextra3 ? 
                                    colab.totalizadores.totextra3 + 
                                    checkAst(colab.temInsercaoTotais) : ''}}
                                </td>
                                <!-- Total de horas Extra 100%-->
                                <td class="tdTabelaDetalhada"
                                    :class="{ tdVermelho: colab.temInsercaoTotais}">
                                    {{colab.totalizadores.totextracem ? 
                                    colab.totalizadores.totextracem + 
                                    checkAst(colab.temInsercaoTotais) : ''}}
                                </td>
                                <!-- Total de horas Extra 100% Noturna-->
                                <td class="tdTabelaDetalhada"
                                    :class="{ tdVermelho: colab.temInsercaoTotais}">
                                    {{colab.totalizadores.totextracemnoturna ? 
                                    colab.totalizadores.totextracemnoturna + 
                                    checkAst(colab.temInsercaoTotais) : ''}}
                                </td>
                                <!-- Total de horas Falta-->
                                <td class="tdTabelaDetalhada"
                                    :class="{tdVermelho: colab.temInsercaoTotais 
                                        || colab.temFaltaTotais}">
                                    {{colab.totalizadores.totfalta ? 
                                    colab.totalizadores.totfalta + 
                                    checkAst(colab.temInsercaoTotais 
                                    || colab.temFaltaTotais) : ''}}
                                </td>
                                <!-- Total de horas Jornada reduzida-->
                                <td class="tdTabelaDetalhada"
                                    v-if="mostrarReduzida">
                                    {{colab.totalizadores.totHrReduzida}}
                                </td>
                                <!-- Total de horas Adicional Noturno-->
                                <td class="tdTabelaDetalhada">
                                    {{colab.totalizadores.totadicnoturno}}
                                </td>
                                <!-- Total de horas Extra Noturno-->
                                <td class="tdTabelaDetalhada">
                                    {{colab.totalizadores.totextnoturno}}
                                </td>
                                <!-- Total de horas Indenização-->
                                <td class="tdTabelaDetalhada"
                                    v-if="mostrarInterjornada">
                                    {{colab.totalizadores.totalInterjornada}}
                                </td>
                                <td class="tdTabelaDetalhada"
                                    v-if="mostrarIndenizacao">
                                    {{colab.totalizadores.totindenizacao}}
                                </td>
                                <!-- Total de horas Descanso Remunerado-->
                                <td class="tdTabelaDetalhada" 
                                    v-if="mostrarDescansoRem">
                                    {{colab.totalizadores.totdescremunerado}}
                                </td>
                                <!-- Total de horas eventos-->
                                <td class="tdTabelaDetalhada">
                                    {{colab.totalizadores.tothoraseventos}}
                                </td>
                            </tr>
                            <!-- Linha para o Saldo hora extra -->
                            <tr :key="k+'_saldoExtra'"
                                class="fundoTotalSaldoExtra"
                                v-if="mostrarSaldoExtras">
                                <td :colspan="totalColunas-4"></td>
                                <td colspan="3" style="text-align:right">
                                    Saldo hora extra:
                                </td>
                                <td style="text-align:center">
                                    {{colab.totalizadores.saldoExtras}}
                                </td>
                            </tr>
                            <tr v-if="true" :key="k+'_alertas1'"
                                class="fundoAlertasTabelaDetalhada">
                                <td v-if="mostrarInconform 
                                        && colab.alertas.direcaoContinua"
                                    :colspan="totalColunas"
                                    class="tdFundoAlertasTabelaDetalhada">
                                    Limite de direção continua ultrapassado 
                                    nos dias:
                                    {{colab.alertas.direcaoContinua}}
                                </td>
                            </tr>
                            <tr v-if="true" :key="k+'_alertas2'"
                                class="fundoAlertasTabelaDetalhada">
                                <td v-if="mostrarInconform 
                                        && mostrarInterjornada 
                                        && colab.alertas.interjornada"
                                    :colspan="totalColunas"
                                    class="tdFundoAlertasTabelaDetalhada">
                                    Tempo obrigatório de interjornada não 
                                    realizado nos dias:
                                    {{colab.alertas.interjornada}}
                                </td>
                            </tr>
                            <tr v-if="colab.ajustes && colab.ajustes.length"
                                :key="k+'_ajustes_espaco'"
                                style="background-color:none!important;">
                                <td style="height:10px; border:none!important; 
                                    background:none!important"></td>
                            </tr>
                            <tr v-if="colab.ajustes && colab.ajustes.length"
                                :key="k+'_ajustes_title'">
                                <td :colspan="totalColunas" 
                                    class="tituloTotaisDetalhado">
                                    Alterações da jornada
                                </td>
                            </tr>
                            <tr v-if="colab.ajustes && colab.ajustes.length"
                                :key="k+'_ajustes_dados'">
                                <td :colspan="totalColunas"
                                    class="nopadding">
                                    <table id="tableAjustes">
                                        <thead>
                                            <tr>
                                                <th style="text-align:left!important">
                                                    Data
                                                </th>
                                                <th style="text-align:left!important">
                                                    Hora
                                                </th>
                                                <th style="text-align:left!important">
                                                    Ação
                                                </th>
                                                <th style="text-align:left!important;width:60%">
                                                    Justificativa
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(ajuste, a) in colab.ajustes"
                                                :key="a+'_ajuste_'+k+'_colab'">
                                                <td>{{ajuste.data}}</td>
                                                <td>{{ajuste.hora}}</td>
                                                <td>{{ajuste.acao}}</td>
                                                <td>{{ajuste.justificativa}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </template>
                    </slot>
                </tableRelatorio>
                <!-- Modal Banco de Horas -->
                <ModalBancoHoras idModal="modalBanco" ref="modalBancoHorasJT"/>
                <!-- Modal Ajustes -->
                <ModalAjustes idModal="modalAjustes" ref="modalAjustes"/>
                <!-- Modal Opções -->
                <ModalAcoes 
                    idModal="modalAcoes"
                    ref="modalAcoes"
                    @atualizarRelatorio="gerarRelatorio">
                </ModalAcoes>
            </div>
        </div>
        <!-- Tabela resumida -->
        <div v-show="!muda">
            <div class="col-sm-12 nopadding divDontPrint">
                <div class="col-sm-12 nopadding row">
                    <div class="col-sm-4">
                        <tituloPage 
                        :icon="mdiAccountClock" 
                        titulo="Jornada de trabalho"/>
                    </div>
                    <div class="col-sm-8 divButtonsBasic row">
                        <basicButtonsRelatoriosEIG 
                            @gerarRelatorio="gerarRelatorioResumido"
                            @exportarRelatorio="exportarRelatorioResumido"
                            :loading='loadingExportar'
                            :disabled="$v.colabsSelResumido.$invalid"
                            :disabledDropdown="validaExportarRes">
                            <slot>
                                <simpleButton 
                                    text='Detalhado'
                                    type='grey'
                                    event="mudar"
                                    :width="'110px'"
                                    @mudar="muda = !muda">
                                </simpleButton>
                                <simpleButton 
                                    text='Resumido'
                                    type='green'
                                    event="mudar"
                                    width="110px"
                                    :icon='mdiCheckBold'
                                    @mudar="muda = !muda">
                                </simpleButton>
                            </slot>
                        </basicButtonsRelatoriosEIG>
                    </div>
                </div>
                <slideUpAndDown>
                    <slot slot="filters">
                        <div class="col-sm-12"><hr></div>
                        <div class="col-sm-12 justpaddingbottom row">
                            <!-- Input de data -->
                            <div class="col-sm-3 nopadding">
                                <input-range-with-limit
                                name='input-time-resumido'
                                opens='right'
                                :isObrigatorio='true'
                                @changeInput='changeIntervaloResumido'/>
                            </div>
                            <!-- Select de empresas -->
                            <div class="col-sm-3 nopadding">
                                <selectAll 
                                    nameForRadio="radiosEmpresasResumido"
                                    firstSelected="ER" 
                                    :isMultiple="true" 
                                    :labels="labelSelectsEmpresas"
                                    :optionsArray="optionsSelectEmpresaResumido" 
                                    :selected="selectedEmpresaResumido"
                                    :hasSelectAll="true"
                                    @changeSelect="changeEmpresaResumido">
                                </selectAll>
                            </div>
                            <!-- Select Jornada -->
                            <div class="col-sm-3 nopadding">
                                <selectAll 
                                    ref='selectJResumido'
                                    nameForRadio="radiosJornadaResumido" 
                                    firstSelected="ER" 
                                    :labels="labelSelectsJornada" 
                                    :optionsArray="optionsSelectJResumido" 
                                    :isMultiple="false"
                                    :hasSelectAll="false"
                                    :disabled="$v.empresasSelResumido.$invalid"
                                    :loading='carregandoResumido'
                                    @changeSelect="changeJornadaResumido">
                                </selectAll>
                            </div>
                            <!-- Select Colaboradores -->
                            <div class="col-sm-3">
                                <selectAll
                                ref="selectCGResumido"
                                nameForRadio='seletorCGCResumido'
                                firstSelected='C'
                                :hasSelectAll="true"
                                :isMultiple="true"
                                :optionsArray="retornaColabResumido"
                                :labels="labelSelectsColabs"
                                :disabled="$v.jornadaSelResumido.$invalid"
                                :loading="carregandoResumido"
                                @changeCheck="mudaCheckResumido"
                                @changeSelect="changeColaboradoresResumido"/>
                            </div>
                        </div>
                    </slot>
                </slideUpAndDown>
            </div>
            <div class="col-sm-12"><hr></div>
            <tableRelatorio>
                <slot slot="thead">
                    <tr class="cabecalhoResumido">
                        <th>Colaborador</th>
                        <th>Trabalhados</th>
                        <th>Espera</th>
                        <th v-if='tempoExtraResumido'>Tempo Extra</th>
                        <th v-if="extrasParametrosResumido[1]">H.E.1</th>
                        <th v-if="extrasParametrosResumido[2]">H.E.2</th>
                        <th v-if="extrasParametrosResumido[3]">H.E.3</th>
                        <th>Extra 100%</th>
                        <th>Extra 100% Noturno</th>
                        <th v-if="mostrarReduzidaR">Reduzida</th>
                        <th>Falta</th>
                        <th>Adicional Noturno</th>
                        <th>Extra Noturno</th>
                        <th v-if="mostrarIndenizacaoR">Indeni.</th>
                        <th v-if="mostrarDescansoRemR">Descanso Remuner.</th>
                        <th v-if="mostrarSaldoExtras">Saldo Horas</th>
                        <th>Eventos</th>
                    </tr>
                </slot>
                <slot v-if="nuloResumido == true"
                    slot="tbody">
                    <statusInformation 
                        typeBar="tr" 
                        :colspanForTd='totalColunas'
                        :statusBar="statusbarResumido">
                    </statusInformation>
                </slot>
                <!-- 
                    Corpo da tabela, mostra os dados do relatório 
                    seguindo mesmo princípo do cabeçalho 
                -->
                <slot v-else slot="tbody">
                    <template 
                        v-for=" (colab, i) in relatorioResumido">
                        <tr v-if="i != 'extrasParametrosResumido'" 
                            :key="i+'_total'"> 
                            <td class="tdTabelaResumida">{{colab.nome}}</td>
                            <td class="tdTabelaResumida">
                                {{colab.trabalhada}}
                            </td>
                            <td class="tdTabelaResumida">
                                {{colab.espera}}
                            </td>
                            <td class="tdTabelaResumida"
                                v-if='tempoExtraResumido'> 
                                {{colab.tempoExtra}}
                            </td>
                            <td class="tdTabelaResumida" 
                                v-if="extrasParametrosResumido[1]">
                                {{colab.extra1}}
                            </td>
                            <td class="tdTabelaResumida" 
                            v-if="extrasParametrosResumido[2]">
                                {{colab.extra2}}
                            </td>
                            <td class="tdTabelaResumida" 
                            v-if="extrasParametrosResumido[3]">
                                {{colab.extra3}}
                            </td>
                            <td class="tdTabelaResumida">
                                {{colab.extra100}}
                            </td>
                            <td class="tdTabelaResumida">
                                {{colab.extra100noturna}}
                            </td>
                            <td class="tdTabelaResumida"
                                v-if="mostrarReduzidaR">
                                {{colab.horaReduzida}}
                            </td>
                            <td class="tdTabelaResumida">
                                {{colab.falta}}
                            </td>
                            <td class="tdTabelaResumida">
                                {{colab.adicionalNoturno}}
                            </td>
                            <td class="tdTabelaResumida">
                                {{colab.extraNoturno}}
                            </td>
                            <td class="tdTabelaResumida" 
                                v-if="mostrarIndenizacaoR">
                                {{colab.indenizacao}}
                            </td>
                            <td class="tdTabelaResumida" 
                                v-if="mostrarDescansoRemR">
                                {{colab.descansoRemunerado}}
                            </td>
                            <td class="tdTabelaResumida" 
                                v-if="mostrarSaldoExtras">
                                {{colab.saldoExtras}}
                            </td>
                            <td class="tdTabelaResumida">
                                {{colab.horasEventos}}
                            </td>
                        </tr>
                    </template>
                    <!-- Mostra o total de horas geral -->
                    <template>
                        <tr class="fundoTotal">
                            <td>Total:</td>
                            <td>
                                {{totalResumido.trabalhada}}
                            </td>
                            <td>
                                {{totalResumido.espera}}
                            </td>
                            <td v-if='tempoExtraResumido'>
                                {{totalResumido.tempoExtra}}
                            </td>
                            <td v-if="extrasParametrosResumido[1]">
                                {{totalResumido.extra1}}
                            </td>
                            <td v-if="extrasParametrosResumido[2]">
                                {{totalResumido.extra2}}
                            </td>
                            <td v-if="extrasParametrosResumido[3]">
                                {{totalResumido.extra3}}
                            </td>
                            <td>{{totalResumido.extra100}}</td>
                            <td>{{totalResumido.extra100noturna}}</td>
                            <td v-if="mostrarReduzidaR">
                                {{totalResumido.horaReduzida}}
                            </td>
                            <td>{{totalResumido.falta}}</td>
                            <td>{{totalResumido.adicionalNoturno}}</td>
                            <td>{{totalResumido.extraNoturno}}</td>
                            <td v-if="mostrarIndenizacaoR">
                                {{totalResumido.indenizacao}}
                            </td>
                            <td v-if="mostrarDescansoRemR">
                                {{totalResumido.descansoRemunerado}}
                            </td>
                            <td v-if="mostrarSaldoExtras">
                                {{totalResumido.saldoExtras}}
                            </td>
                            <td>{{totalResumido.horasEventos}}</td>
                        </tr>
                    </template>
                </slot>
            </tableRelatorio>
            <div v-if="nuloResumido == false"
                class="templateLegendas">
                <div class="legendas">
                        Legendas horas extras
                </div>
                <div v-if="extrasParametrosResumido[1]">
                    <span class="bold">H.E.1: </span>
                    <span> {{legendasResumido[1]}}</span>
                </div>
                <div v-if="extrasParametrosResumido[2]">
                    <span class="bold">H.E.2: </span>
                    <span> {{legendasResumido[2]}}</span>
                </div>
                <div v-if="extrasParametrosResumido[3]">
                    <span class="bold">H.E.2: </span>
                    <span> {{legendasResumido[2]}}</span>
                </div>
            </div>
        </div>
    </panelEagle>
</template>

<script lang="js">
import Vue from 'vue'
import { 
    mdiAlert, 
    mdiCheckBold,
    mdiTimetable, 
    mdiCloseThick,
    mdiCarSettings,
    mdiChevronLeft,
    mdiChevronRight,
    mdiAccountClock,
    mdiSwapVertical,
    mdiThumbUpOutline, 
    mdiAccountSettings, 
    mdiCloseCircleOutline,
    mdiCheckboxBlankCircle 
} from '@mdi/js'
import { required } from 'vuelidate/lib/validators'
import { EmpresasService } from '../../../Services/auth/Empresas.service'
import { HttpRequest }     from '../../../Services/auth/HttpRequest.Service'
import { mapGetters } from 'vuex'
// import { Duration } from 'luxon'
import { conectionError } from '@/Services/Helpers/swellHeper'
export default Vue.extend({
    name:'jornadaTrabalho',
    components: {
        baseIcon:require('@/components/Atom/Icon/BaseIcon').default,
        tituloPage:require('@/components/Atom/Header/Titulo').default,
        selectAll:require('@/components/Atom/Select/SelectAll').default,
        panelEagle:require('@/components/Atom/Panel/PanelEagle').default,
        simpleButton:require('@/components/Atom/Buttons/SimpleButton').default,
        tableRelatorio:require('@/components/Atom/Table/TableRelatorio').default,
        ModalAcoes:require('@/components/Atom/ModalJornadaFolha/ModalAcoes').default,
        ModalAjustes:require('@/components/Atom/ModalJornadaFolha/ModalAjustes').default,
        slideUpAndDown:require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
        inputRangeWithLimit:require('@/components/Atom/Datas/InputRangeWithLimit').default,
        ModalBancoHoras:require('@/components/Atom/ModalJornadaFolha/ModalBancoHoras').default,
        statusInformation:require('@/components/Atom/StatusInformation/StatusInformation').default,
        basicButtonsRelatoriosEIG:require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
    },
    validations:{
        //relatório Detalhado
        empresasSelecionadas:{ required },
        colabsSelDetalhado:{ required },
        jornadaSelecionada:{ required },
        intervaloDetalhado:{ required },
        intervaloResumido:{ required },
        relatorio:{ required },
        //Resumido
        empresasSelResumido:{ required },
        colabsSelResumido:{ required },
        jornadaSelResumido:{ required },
    },
    data(){
        return{
            url: '/controle/jornada/relatorios/jornada/trabalho/',
            optionsSelectEmpresa: new EmpresasService().Get() ?? [],
            optionsSelectEmpresaResumido : new EmpresasService().Get() ?? [],
            retornaColabAtualResumido:{
                C:[],
                GC:[]
            },
            // icons
            ...{
                mdiCheckboxBlankCircle,
                mdiCloseCircleOutline,
                mdiAccountSettings,
                mdiThumbUpOutline,
                mdiAccountClock,
                mdiChevronRight,
                mdiSwapVertical,
                mdiChevronLeft,
                mdiCarSettings,
                mdiCloseThick,
                mdiCheckBold,
                mdiTimetable,
                mdiAlert,
            },
            // labels
            ...{
                labelSelectsEmpresas:[
                    {indexDFH: 'E', description: 'Empresas*'},
                ],
                labelSelectsColabs:[
                    {indexDFH: 'C', description: 'Colaboradores*'},
                    {indexDFH: 'GC', description: 'Grupo*'},
                ],
                labelSelectsJornada:[
                    {indexDFH: 'J', description: 'Jornada*'}
                ],
                labelsSelectColabsModal:[
                    {indexDFH: 'C', description: 'Colaboradores*'},
                ],
                labelsSelectVeiculosModal:[
                    {indexDFH: 'V', description: 'Veículos*'},
                ],
                labelsSelectEventosModal:[
                    {indexDFH: 'EV', description: 'Eventos*'},
                ],
                labelsSelectTipoModal:[
                    {indexDFH: 'TI', description: 'Tipo*'},
                ],
            },
            // IMPORTANTE PARA NÃO QUEBRAR LAYOUT
            // CONTROLE DA QUANTIDADE DE COLUNAS
            totalColunas: 22,
            
            tipoSelecionadoResumido:'C',
            empresasSelecionadas: [],
            colabsSelDetalhado: [],
            jornadaSelecionada: [],
            optionsSelectCGC: [],
            dadosAbsenteismo: [],
            selectedEmpresa: [],
            selectedJornada: [],
            selectedColabs: [],
            optionsSelectCC: [],
            optionsSelectJ: [],
            optionsSelectC: [],
            relatorio: [],
            dataCG: [],
            dataCGRes: [],
            html: '',
            intervaloDetalhado: '',
            optionRadiusColab: '',
            trocaColaborador: '',
            searchMotoristas: '',
            searchMotoristasRes: '',
            htmlAbsenteismo: '',
            dataToExport: '',
            eventoFolha: '',
            tempoExtra: false,
            parametrosDetalhado: null,
            extrasParametros: [false, false, false],
            loadingExportar: [false, false, false],
            condicaoEventoFolha: false,
            loadingPanel: false,
            carregando: false,
            loading: false,
            muda: true,
            nulo: true,
            // Resumido
            extrasParametrosResumido: [],
            colaboradoresSelectModal: [],
            optionsSelectCGCResumido: [],
            selecionadosSelectModal: [],
            selectedEmpresaResumido: [],
            optionsSelectJResumido: [],
            empresasSelResumido: [],
            jornadaSelResumido: [],
            colabsSelResumido: [],
            relatorioResumido: [],
            totalResumido: [],
            htmlResumido: [],
            legendasResumido: [],
            buscaColaboradoresModal: '',
            buscaSelecionadosModal: '',
            intervaloResumido: '',
            mostrarIndenizacao: false,
            mostrarDescansoRem: false,
            mostrarReduzida: false,
            mostrarSaldoExtras: false,
            carregandoResumido: false,
            mostrarIndenizacaoR: false,
            mostrarDescansoRemR: false,
            mostrarReduzidaR: false,
            mostrarInconform: false,
            mostrarInterjornada: false,
            carregandoResumidoR: false,
            nuloResumido: true,
            statusbarResumido: 'info',
            statusbar: 'info',
            colabC:[],
            colabGC:[],
            horariosJornada: [],
            tempoExtraResumido:'',
            valueIntervalo:'',
            parametros:null,
        }
    },
    methods:{
        ...mapGetters(["getMaster", "getExcluir", "getEditar", "getCadastrar"]),
        
        decideExtra100Detalhado({horaExtra100, temInsercao, temFalta, inicio}){
            if(horaExtra100)
                return `${horaExtra100}${this.checkAst(temInsercao, temFalta)}`
            if(inicio)
                return '00:00:00'
            return ''
        },

        decideExtra100NoturnaDet({horaExtra100Noturna, temInsercao, temFalta, inicio}){
            if(horaExtra100Noturna)
                return `${horaExtra100Noturna}${this.checkAst(temInsercao, temFalta)}`
            if(inicio)
                return '00:00:00'
            return ''
        },
        
        /**
         * @description decide se deve ter exibido o tempoExtra na linha.
         * Caso não tenha inicio, quer dizer que não tem registro no dia.
         * @return {string} se tiver registros, o tempo extra, do contrário, string vazia.
         */
        mostraTempoExtra({inicio}){
            if(inicio)
                return this.tempoExtra
            return ''
        },
        decideTextoDetalhadoTotal({total, temInsercao}){
            if(total)
                return `${total}${this.checkAst(temInsercao)}`
            return ''
        },
        /**
         * @param {object} item
         * @param {number} item.diasemana
         * @description descobre se o dia onde o item se passa
         * é uma DRS
         * @return {boolean} é dsr?
         */
        ehdsr(item){
            let predicado = {'hjtdiasemana':Number(item.diasemana)}
            let dia = _.find(this.horariosJornada, predicado)
            return dia.hjtdsr === 1
        },
        // RELATÓRIO DETALHADO
        /**
         * Início dos métodos para receber valores dos selects detalhados
         * @param {array} empresa 
         */
        changeEmpresa(empresas){
            this.relatorio = []
            this.statusbar = 'info'
            this.empresasSelecionadas = empresas
            this.colabsSelDetalhado = []
            this.jornadaSelecionada = []
            this.$refs.selectCG.clearAll()
            this.$refs.selectJ.clearAll()
            this.buscaJornadaDetalhado(empresas)
            this.resetaDetalhado()
        },
        /**
         * @listens changeSelect seletor de empresa do rel resumido.
         * @param {number[]} empresas código das empresas selecionadas
         * @description altera as empresas selecionadas e limpa os dados
         * do relátorio.
         */
        changeEmpresaResumido(empresas){
            this.relatorioResumido = []
            this.statusbarResumido = 'info'
            this.empresasSelResumido = empresas
            this.jornadaSelResumido = []
            this.colabsSelResumido = []
            this.$refs.selectCGResumido.clearAll()
            this.$refs.selectJResumido.clearAll()
            this.buscaJornadaResumido(empresas)
            this.resetaResumido()
        },
        /**
         * @listens changeSelect seletor de funcionário relatório detalhado
         * @param {number[]} colaborador
         */
        changeColaboradores(colaborador) {
            this.relatorio = []
            this.statusbar = 'info'
            this.colabsSelDetalhado = colaborador
            this.resetaDetalhado()
        },
        /**
         * @listens changeSelect seletor de funcionário relatório resumido
         * @param {number[]} colaborador
         */
        changeColaboradoresResumido(colaborador){
            this.relatorioResumido = []
            this.statusbarResumido = 'info'
            this.colabsSelResumido = colaborador
            this.resetaResumido()
        },
        /**
         * @listens changeInput range do relatório detalhado
         * @param {string} data dd/mm/yyyy - dd/mm/yyyy
         */
        changeIntervaloDetalhado(data){
            this.intervaloDetalhado = data
            this.resetaDetalhado()
        },
        /**
         * @listens changeInput range do relatório
         * @param {string} data dd/mm/yyyy - dd/mm/yyyy
         */
        changeIntervaloResumido(data){
            this.intervaloResumido = data
            this.resetaResumido()
        },
        /**
         * @listens changeSelect seletor de jornada.
         * @param {number[]} jornada código das jornadas selecionada.
         */
        changeJornada(jornada){
            this.relatorio = []
            this.statusbar = 'info'
            this.jornadaSelecionada = jornada
            this.buscaColabs(jornada)
            this.$refs.selectCG.clearAll()
            this.resetaDetalhado()
        },
        changeJornadaResumido(jornada){
            this.relatorioResumido = []
            this.statusbarResumido = 'info'
            this.jornadaSelResumido = jornada
            this.buscaColabsResumido(jornada)
            this.$refs.selectCGResumido.clearAll()
            this.resetaResumido()
        },

        /**
         * @description método para gerar relatório detalhado
         */
        gerarRelatorio(){
            const objRequisicao = {
                'jornada': this.jornadaSelecionada, 
                'motoristas': this.colabsSelDetalhado,
                'searchMotoristas' : this.searchMotoristas,
                'clientes': this.empresasSelecionadas,
                'data_intervalo': this.intervaloDetalhado
            }
            this.loadingPanel = true
            this.$bvModal.hide('modalOpcoesAdicionarJornada')
            this.$bvModal.hide('modalOpcoesAdicionarEvento')
            this.$bvModal.hide('modalAcoes')
            this.nulo = true
            new HttpRequest().Post(`${this.url}gerarRelatorio`, objRequisicao)
                .then(({data, status}) => {
                    if(status) {
                        if(data){
                            this.setDadosDetalhado(data)
                        } else {
                            this.statusbar = 'error'
                        }
                    } else {
                        this.statusbar = 'error'
                        let msg = 'Falha na comunicação com o servidor!'
                        this.toastShow('Ops!', msg, 'danger')
                    }
                })
                .catch(() => {
                    conectionError()
                })
                .finally(() => this.loadingPanel = false)
        },
        
        setDadosDetalhado(dados) {
            this.totalColunas = 22
            let paramJornada = dados.parametros.jornada
            let paramExtras = dados.parametros.extras
            this.parametrosDetalhado = dados.parametros
            this.extrasParametros = paramExtras
            this.mostrarDescansoRem = paramJornada.jtdescansoremunerado
            this.mostrarIndenizacao = paramJornada.jtindenizacao
            this.mostrarReduzida = paramJornada.jtreduzida
            this.mostrarSaldoExtras = paramJornada.jtvisualizarsaldoextras
            this.mostrarInconform = paramJornada.jtvisualizarinconformidades
            this.mostrarInterjornada = paramJornada.jtinterjornada
            this.tempoExtra = paramJornada.jttempoextra ? paramJornada.jttempoextra : ''
            this.relatorio = dados.dados
            this.html = dados.html
            this.nulo = false
            this.horariosJornada = dados.horarios
            if(!this.tempoExtra) this.totalColunas -= 1
            if(!this.extrasParametros[1]) this.totalColunas -= 1
            if(!this.extrasParametros[2]) this.totalColunas -= 1
            if(!this.extrasParametros[3]) this.totalColunas -= 1
            if(!this.mostrarReduzida) this.totalColunas -= 1
            if(!this.mostrarIndenizacao) this.totalColunas -= 1
            if(!this.mostrarDescansoRem) this.totalColunas -= 1
            if(!this.mostrarInterjornada) this.totalColunas -= 1
        },
        /**
         * @description método para gerar relatório resumido
         * @todo remover esse await e substituir por um .then
         */
        async gerarRelatorioResumido(){
            this.loadingPanel = true
            this.nuloResumido = true
            // this.statusbarResumido = 'loading'
            var dados = await new HttpRequest()
                .Post(`${this.url}relatorioResumido`, {
                    'tipoPesquisa': this.searchMotoristasRes,
                    'motoristas': this.colabsSelResumido,
                    'clientes': this.empresasSelResumido,
                    'data': this.intervaloResumido,
                    'jornada': this.jornadaSelResumido,
                })
            if(dados.status) {
                if(dados.data){
                    let relatorio = dados.data
                    let legendas = dados.data.parametros.legendas
                    let paramExtras = dados.data.parametros.extras
                    let paramJornada = dados.data.parametros.jornada
                    this.tempoExtraResumido = dados.data.tempoExtra ? dados.data.tempoExtra : ''
                    this.mostrarDescansoRemR = paramJornada.jtdescansoremunerado
                    this.mostrarIndenizacaoR = paramJornada.jtindenizacao
                    this.mostrarReduzidaR = paramJornada.jtreduzida
                    this.mostrarSaldoExtras = paramJornada.jtvisualizarsaldoextras
                    this.extrasParametrosResumido = paramExtras
                    this.totalResumido = relatorio.totalizadores
                    this.relatorioResumido = relatorio.dados
                    this.htmlResumido = dados.data.html
                    this.legendasResumido = legendas
                    this.nuloResumido = false
                } else {
                    this.statusbarResumido = 'error'
                }
            } else {
                this.statusbarResumido = 'error'
                let msg = 'Falha na comunicação com o servidor!'
                this.toastShow('Ops!', msg, 'danger')
            }
            this.loadingPanel = false
        },
        /**
         * @param {'pdf'|'csv'|'xls'} tipo formato da exportação.
         * @description gera a exportação do relatório.
         */
        async exportarRelatorioDetalhado(tipo){
            var root =  process.env.VUE_APP_ROOT;
            this.setLoading(tipo)
            // if (tipo == 'pdf') {
                // if (this.html) {
                    // // Criar um novo documento PDF
                    // var doc = new jsPDF({ orientation: 'landscape' });
                    // // Obter o elemento HTML que você deseja adicionar ao PDF
                    // var element = document.getElementById('teste1');
                    // // Converter o elemento HTML em uma string de HTML usando o método outerHTML
                    // // var html = element.outerHTML;
                    // // var html = this.html
                    // // Adicionar o HTML ao documento PDF
                    // // doc.html(this.html, {
                    // doc.html(element, {
                    //     callback: function (doc) {
                    //         doc.save('JornadaTrabalho.pdf');
                    //     },
                    //     margin: [10, 10, 10, 10],
                    //     autoPaging: 'text',
                    //     x: 0,
                    //     y: 0,
                    //     width: 280, //target width in the PDF document
                    //     windowWidth: 1350 //window width in CSS pixels
                    // });
                    // // Salvar o documento PDF
                    // // doc.save('meu-documento.pdf');



                    // // let page = document.getElementById('teste1');
                    // // var htmlObject = document.createElement(this.html);
                    // // var div = document.createElement('div');
                    // // div.innerHTML = this.html
                    // // html2PDF(div.firstChild, {
                    // //     jsPDF: {
                    // //         format: 'a4',
                    // //     },
                    // //     imageType: 'image/jpeg',
                    // //     output: 'JornadaTrabalho.pdf'
                    // // });


                    // // var doc = new jsPDF({ orientation: 'landscape' });

                    
                    // // var w = document.getElementById("teste1").offsetWidth;
                    // // var h = document.getElementById("teste1").offsetHeight;
                    // // html2canvas(document.getElementById("teste1"), {
                    // //     dpi: 300, // Set to 300 DPI
                    // //     scale: 3, // Adjusts your resolution
                    // //     onrendered: function (canvas) {
                    // //         var img = canvas.toDataURL("image/jpeg", 1);
                    // //         var doc = new jsPDF('L', 'px', [w, h]);
                    // //         doc.addImage(img, 'JPEG', 0, 0, w, h);
                    // //         doc.save('sample-file.pdf');
                    // //     }
                    // // });



                    // // var elementHTML = document.querySelector("#teste1");
                    // // doc.html(elementHTML, {
                    // //     callback: function (doc) {
                    // //         doc.save('JornadaTrabalho.pdf');
                    // //     },
                    // //     x: 15,
                    // //     y: 15,
                    // //     width: 170, //target width in the PDF document
                    // //     windowWidth: 650 //window width in CSS pixels
                    // // });



                    // // var htmlObject = document.createElement('div');
                    // // htmlObject.innerHTML = this.html;
                    // // htmlObject = htmlObject.firstChild;
                    // // doc.html(this.html, {
                    // //     callback: function(doc) {
                    // //         doc.save('JornadaTrabalho.pdf');
                    // //     },
                    // //     margin: [10, 10, 10, 10],
                    // //     autoPaging: 'text',
                    // //     x: 0,
                    // //     y: 0,
                    // //     width: 190, //target width in the PDF document
                    // //     windowWidth: 675 //window width in CSS pixels
                    // // });



                    // // var doc = new jsPDF();
                    // // doc.html(this.html, {
                    // //     callback: function(doc) {
                    // //         doc.save('JornadaTrabalho.pdf');
                    // //     },
                    // //     margin: [10, 10, 10, 10],
                    // //     autoPaging: 'text',
                    // //     x: 0,
                    // //     y: 0,
                    // //     width: 190, //target width in the PDF document
                    // //     windowWidth: 675 //window width in CSS pixels
                    // // });



            //         let newWindow = window.open()
            //         newWindow.document.write(this.html)
            //     }
            // } else {
                let dados = await new HttpRequest()
                    .Post(`${this.url}exportarRelatorio`, {
                        'arrayDados': this.relatorio,
                        'data': this.intervaloDetalhado,
                        'html': this.html,
                        'parametros': this.parametrosDetalhado,
                        'tipo': tipo,
                        'tempoExtra': this.tempoExtra,
                        'horarios': this.horariosJornada,
                    })
                if(dados.status) {
                    window.open(root+'/'+dados.data.local);
                } else {
                    let msg = 'Falha na comunicação com o servidor!'
                    this.toastShow('Ops!', msg, 'danger')
                }
            this.setLoading()
        },
        async exportarRelatorioResumido(tipo){
            var root = process.env.VUE_APP_ROOT;
            this.setLoading(tipo)
            if(this.relatorioResumido.length != []){
                var dados = await new HttpRequest()
                    .Post(`${this.url}exportarRelatorioResumido`, {
                        'extraParametros': this.extrasParametrosResumido,
                        'arrayDados': this.relatorioResumido,
                        'totalResumido': this.totalResumido,
                        'data': this.intervaloResumido,
                        'html': this.htmlResumido,
                        'tipo': tipo,
                        'tempoExtra':this.tempoExtraResumido
                    })
                if(dados.status) {
                    window.open(root+'/'+dados.data.local);
                } else {
                    let msg = 'Falha na comunicação com o servidor!'
                    this.toastShow('Ops!', msg, 'danger')
                }
            }
            this.setLoading()
        },
        /**
         * Método para buscar as jornadas referentes a empresa
         * @param {array} empresa 
         */
        async buscaJornadaDetalhado(empresa){
            this.carregando = true
            if(empresa.length > 0){
                var dados = await new HttpRequest()
                    .Post(`${this.url}buscaJornada`, {'clientes': empresa})
                if(dados.status) {
                    this.optionsSelectJ = dados.data.J
                    if (this.parametros?.jornadas) {
                        this.selectedJornada = this.optionsSelectJ.filter((e) => {
                            return this.parametros?.jornadas?.includes(e.value)
                        })
                    }
                } else {
                    let msg = 'Falha na comunicação com o servidor!'
                    this.toastShow('Ops!', msg, 'danger')
                }
            }else{
                this.$refs.selectCG.clearAll()
                this.$refs.selectJ.clearAll()
            }
            this.carregando = false
        },
        /**
         * Método para buscar jornadas do select no relatório resumido
         * @param {*} empresa 
         */
        async buscaJornadaResumido(empresa){
            this.carregandoResumido = true
            if(empresa.length > 0){
                this.optionsSelectJResumido = []
                var dados = await new HttpRequest()
                    .Post(`${this.url}buscaJornada`, {'clientes': empresa})
                if(dados.status) {
                    this.optionsSelectJResumido = dados.data.J
                } else {
                    let msg = 'Falha na comunicação com o servidor!'
                    this.toastShow('Ops!', msg, 'danger')
                }
            }else{
                this.$refs.selectJResumido.clearAll()
            }
            this.carregandoResumido = false
        },
        /**
         * Método para buscar colaboradores ligados a jornada selecionada
         * @param {array} jornada 
         */
        async buscaColabs(jornada){
            this.carregando = true
            if(jornada.length > 0){
                var dados = await new HttpRequest()
                    .Post(`${this.url}buscaColaboradores`, {
                        'clientes': this.empresasSelecionadas, 
                        'jornada' : jornada
                    })
                if(dados.status) {
                    this.colaboradoresSelectModal = dados.data.C
                    this.optionsSelectCGC = dados.data.C
                    this.optionsSelectC = dados.data.C
                    this.dataCG = dados.data.GC

                    this.colabC = dados.data.C
                    this.colabGC = dados.data.GC
                    if (this.parametros?.colaboradores) {
                        this.selectedColabs = this.optionsSelectCGC.filter((e) => {
                            return this.parametros?.colaboradores?.includes(e.value)
                        })
                    }
                    this.parametros = null
                } else {
                    let msg = 'Falha na comunicação com o servidor!'
                    this.toastShow('Ops!', msg, 'danger')
                }
            }else{
                this.$refs.selectCG.clearAll()
            }
            this.carregando = false
        },
        /**
         * Método para buscar colaboradores do select no relatório resumido
         * @param {*} jornada 
         */
        async buscaColabsResumido(jornada){
            this.carregandoResumido = true
            if(jornada.length > 0){
                var dados = await new HttpRequest()
                    .Post(`${this.url}buscaColaboradores`, {
                        'clientes': this.empresasSelResumido, 
                        'jornada' : jornada
                    });
                if(dados.status) {
                    let tipo = this.$refs.selectCGResumido.labelSelected
                    this.optionsSelectCGCResumido  = dados.data
                    this.retornaColabAtualResumido = dados.data
                    this.dataCGRes = dados.data
                    this.tipoSelecionadoResumido = tipo
                } else {
                    let msg = 'Falha na comunicação com o servidor!'
                    this.toastShow('Ops!', msg, 'danger')
                }
            }else{
                this.$refs.selectCGResumido.clearAll()
            }
            this.carregandoResumido = false
        },
        /**
         * Método para mudar seletor colaboradores / grupo de colaboradores
         * @param {*} value 
         */
        mudaCheck(value){
            this.searchMotoristas = value
            if(this.dataCG[value]!== undefined){
                this.optionsSelectCGC = this.dataCG[value]
            }
        },
        mudaCheckResumido(value){
            this.searchMotoristasRes = value
            this.tipoSelecionadoResumido = value
            if(this.dataCGRes.length > 0 && this.dataCGRes[value]!== undefined){
                this.optionsSelectCGCResumido = this.dataCGRes[value]
            }
        },
        setLoading(tipo){
            let arr = [tipo=='pdf', tipo=='xls', tipo=='csv']
            this.loadingExportar =  arr;
        },
        /**
         * @listens click - botão de edição de motorista dentro da tabela
         * @description Muda as informações de editavel, a prop que alimenta
         * a modal de edição de motorista/ajudante, fazendo isso
         * antes de conjurar 🪄 a modal, tentando manter tudo atualizado
         * @param {object} row - representa linha da tabela
         * @author Gui 🍺🍺 
         */
        async modalBanco(){
            let data = this.intervaloDetalhado.split('-')[0].split('/')
            const colaboradores = await this.getFechamentoColaboradores()
            this.$refs.modalBancoHorasJT
                .preparaModalBancoHoras(
                    colaboradores, 
                    this.colabsSelDetalhado,
                    data
                )
        },

        async getFechamentoColaboradores() {
            let obj = { 'arrayFuncionarios': this.colaboradoresSelectModal }
            const url = `${this.url}getUltimoFechamentoColaboradores`
            const dados = await new HttpRequest().Post(url, obj);
            return dados.data
        },

        modalAjustes(){
            this.$refs.modalAjustes.preparaModalAjustes(this.empresasSelecionadas)
        },
        openModalAcoes(eventos){
            let data = this.intervaloDetalhado.split('-')
            let empresas = this.empresasSelecionadas
            this.$refs.modalAcoes.preparaModalAcoes(data, empresas, eventos)
            this.$refs.modalAcoes.modalAcoesJornada(eventos)
        },
        toastShow(titulo, msg, type){
            this.$bvToast.toast(msg, {
                title: titulo,
                autoHideDelay: 2500,
                variant: type,
            })
        },
        checkAst(value){
            return value ? '*' : ''
        },
        /**
         * @description reseta as informações do relatório
         */
        resetaDetalhado(){
            this.relatorio = []
            this.nulo = true
            this.statusbar = 'info'
        },
        /**
         * @description reseta as informações do relatório resumido
         */
        resetaResumido(){
            this.relatorioResumido = []
            this.nuloResumido = true
            this.statusbarResumido = 'info'
        },

        defineClassCorTr(item) {
            return {
                azulTabela: item.temFeriado,
                verdeTabela: item.trabalhouForaDeJornada,
                brancoTabela: !item.temFeriado 
                    && !item.trabalhouForaDeJornada
            }
        },

        defineHtmlInicio(item, ehDsr) {
            let html = ''
            let temInicio = !!item.inicio
            let temObs = !!item.observacao
            if (temInicio) {
                let style = 'style="border:none !important"'
                html += `<span class="tdTabelaDetalhada" ${style}>${item.inicio}</span>`
            }
            if (temObs) {
                if (temInicio) html += '<br>'
                let obs = item.observacao.replace('; ', '; <br>')
                html += `<span class="tdVermelho 1">${obs}`
                if (ehDsr) html += `; <br>`
                html += `</span>`
            }
            if (ehDsr) {
                html += `<span class="tdVermelho 2">`
                if(!temObs && temInicio) html += '<br>'
                html += `Descanso semanal remunerado</span >`
            }
            return html
        },
    },
    mounted() {
        this.parametros = null
        if (this.$route.params) {
            this.parametros = this.$route.params
            if (this.parametros?.intervalo) {
                this.valueIntervalo = this.parametros?.intervalo
            }
            if (this.parametros?.clientes) {
                this.selectedEmpresa = this.optionsSelectEmpresa.filter((e) => {
                    return this.parametros?.clientes?.includes(e.value)
                })
            }
        } else {
            if(!this.getMaster()){
                this.selectedEmpresa = this.optionsSelectEmpresa
            }
        }
    },
    computed: {
        _getExcluir() { 
            return this.getExcluir()
        },
        _getEditar() { 
            return this.getEditar()
        },
        _getCadastrar() { 
            return this.getCadastrar()
        },
        retornaColabResumido(){
            let tipo = this.tipoSelecionadoResumido
            let retorno = this.retornaColabAtualResumido
            return retorno[tipo]
        },

        retornaColabAtual(){
            if(this.searchMotoristas === "C"){
                return this.colabC
            }
            return this.colabGC
        },

        validaExportarDet() {
            return this.relatorio && this.relatorio.length > 0
        },

        validaExportarRes() {
            return this.relatorioResumido && this.relatorioResumido.length > 0
        }
    },
})
</script>

<style scope lang="scss">
    @import './JornadaTrabalho.scss';
</style>
