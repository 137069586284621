<template>
    <div id='popUpRegiao'>
        <div class="col-sm-12 row">
            <div
			data-test='regiao-redescricao'
			class="col-sm-12 nopadding descricao" >
                {{regiao.redescricao}}
            </div>
        <div
        class="col-sm-12 nopadding velocidade"
        v-if='regiao.revelocidade'>
            Velocidade: {{Number(regiao.revelocidade).toFixed(0)}} Km/h
        </div>
        <div data-test='regiao-cliente' >
            Empresa: {{empresa}}
        </div>
        <div
        class='col-sm-12 nopadding'>
        Area: {{regiao.rearea}} m²
        </div>
        <div
        class="col-sm-12 row nopadding linha">
			<div class='d-flex justify-content-between'>
				<div>
					Cor:
					<div
					class="square linha mt-1"
					:style="'background-color:'+regiao.recor"/>
				</div>
				<span
				v-b-tooltip.hover.focus.left
				title='Área de risco' 
				v-if='regiao.rerisco=="S"'>
					<baseIcon 
					size='18' 
					style="color:red" 
					class="iconTitulo mr-5" 
					:icon="mdiAlert"/>
				</span>
			</div>
		</div>
            <div class="col-sm-12 nopadding row botoes">
                <div class="col-sm-6 nopadding linha">
                    <botao
                    v-if='permissoes["ppeditar"]'
                    id='popupEditar'
                    text='Editar'
                    event='click' 
                    width='80px'
                    type='green'
                    :icon='mdiPencil'
                    @click='clickEditar(regiao.recodigo)'/>
                </div>
                <div class="col-sm-6 nopadding linha">
                    <loadingButton
                    v-if='permissoes["ppexcluir"]'
                    id='popupExcluir'
                    width='80px'
                    type='red'
                    text='Excluir'
                    event='click'
					:isLoading='excluindo'
                    :icon='mdiTrashCanOutline'
                    @click='modalExcluir()'
					data-test='regiao-excluir-btn'/>
					<template>
						<modalDeleta
							id="1"
							@confirmaExclusao="clickExcluir(regiao.recodigo)"
							>
						</modalDeleta>
					</template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import {mdiPencil, mdiTrashCanOutline, mdiAlert} from '@mdi/js'
import { conectionError } from '@/Services/Helpers/swellHeper'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
export default Vue.extend({
	components: {
		modalDeleta: require("@/components/Atom/ModalSpecific/ModalDeleta.vue").default,
		loadingButton:
			require('@/components/Atom/Buttons/LoadingButton.vue').default,
		baseIcon:require('@/components/Atom/Icon/BaseIcon.vue').default,
		'botao': require('@/components/Atom/Buttons/SimpleButton').default,
	},
	props: {
		regiao:{
			type: Object,
			required: true
		},
		permissoes:{
			type: Object,
			required:true
		},
		empresas:{
			type: Array,
			required:true
		}
	},
	data() {
		return {
			mdiAlert:mdiAlert,
			mdiTrashCanOutline:mdiTrashCanOutline,
			mdiPencil:mdiPencil,
			empresa:'',
			excluindo:false,
			baseUri:'/finder/mapa/',
		}
	},
	inheritAttrs: false,
	methods: {
		clickEditar(codigo) {
			this.$emit('editar-regiao', codigo)
		},
		modalExcluir(){
			this.$bvModal.show('modal-deleta-1')
		},
		clickExcluir(codigo) {
			this.excluindo = true
			var uri = `${this.baseUri}excluir/regiao/${codigo}`
			new HttpRequest().Get(uri).then((data)=>{
				// if(data?.data.success){
				this.$emit('excluir-regiao', codigo)
				// }else 
					// conectionError()
			}).finally(()=>{this.excluindo = false})
		},
	},
	mounted() {
		this.empresa = this.empresas.find((e)=>{
			return e.value === this.regiao.recliente
		})?.description ?? ""
	},
})
</script>

<style lang="scss" scoped>
#popUpRegiao{
    .square { 
        width: 10px; 
        height: 10px; 
    }
    .botoes {
        margin-top: 3%;
        display: inline-block;
    }
    .linha {
        display: inline-block;
    }
    .descricao{
        font-weight: bolder;
    }
}
</style>