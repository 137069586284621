<template>
        <modalEagle @modalOpen="carregaListagem" 
            title="Linhas sem associação" 
            id="modal-linhas-sem-associacao">
            <slot slot="modalBody">
                <panelEagle id="modalLinhasSemAssociacao" :loading="loadingPanel">
                    <div class="col-sm-12 nopadding row">
                        <statusInformation 
                            v-if="carregando"
                            class="col-sm-12"
                            typeBar="div" 
                            :statusBar="statusbar"
                            colspanForTd="12">
                        </statusInformation>
                        <tableListagemModal 
                            v-else
                            :hasAction="false"  
                            :hasSearch="true" 
                            :data="listagemHorarios" 
                            :titles="titleLinhas">
                        </tableListagemModal>
                    </div>
                </panelEagle>
            </slot>
            <slot slot="modalFooter">
                <simpleButton 
                    :icon="mdiCloseThick" 
                    @click="hideModal" 
                    text="Cancelar" 
                    event="click" 
                    type="red">
                </simpleButton>
            </slot>
    </modalEagle>
</template>

<script lang="ts">
import {mdiCloseThick } from '@mdi/js'
import Vue from 'vue'
import {HttpRequest} from '../../../Services/auth/HttpRequest.Service'
export default Vue.extend({
    props:{
        cliente:{
            type: Array,
            required: true,
            default: function(){return []}
        }
    },
    components:{
        'simpleButton'       : require('../../../components/Atom/Buttons/SimpleButton').default,
        'modalEagle'         : require('../../../components/Atom/Modal/ModalEagle').default,
        'tableListagemModal' : require('../../../components/Atom/Table/TableListagemModal').default,
        'statusInformation'  : require('@/components/Atom/StatusInformation/StatusInformation').default,
        panelEagle  : require('@/components/Atom/Panel/PanelEagle').default,
    },
    methods:{
        async carregaListagem(){
            this.loadingPanel = true
            this.carregando = true
            this.listagemHorarios = []
            var data = await new HttpRequest().Post('/coletivos/cadastros/escalas/horariosSemAssociar', 
                {'clientes':this.cliente});
            this.listagemHorarios = data.data.horarios;
            this.carregando = false
            this.loadingPanel = false
        },
        hideModal() {
            this.$bvModal.hide('modal-linhas-sem-associacao')
        },
    },
    data(){
        return{
            mdiCloseThick:mdiCloseThick,
            titleLinhas:[
                {'name': 'Linha', 'number': '0'},
                {'name': 'Horário', 'number': '1'},
                {'name': 'Dias', 'number': '2'},
            ],
            listagemHorarios : [] as any,
            statusbar        : 'info',
            carregando       : false,
            loadingPanel:false,
        }
    }
})
</script>

<style lang="scss">
#modal-linhas-sem-associacao{
    min-height: 200px;
}
</style>