<template>
    <carousel3d 
        v-bind='$attrs'
        id="carrossel3D"
        :ref="id"
        :animationSpeed="200"
        :width="width"
        :height="height"
        :space="300"
        :autoplay="false"
        :display="3"
        :loop="false"
        :border="0"
        :perspective="0" 
        :controlsVisible="true"
        :controlsWidth="100"
        :controlsHeight="100"
        :on-main-slide-click="setSizeCarousel()">
        <slide v-for="(v, i) in dados" 
            :index="i"
            :key="'doc'+i">
            <figure>
                <h5 class="itemCount">
                    {{ getPosicaoItem(i + 1) }}
                </h5>
                <img :src="getDataSource(v)" height="370px">
                <figcaption>
                    <div class="col-sm-10 nopadding">
                        {{v.name}}
                    </div>
                    <div class="col-sm-2 nopadding">
                        <button @click="downloadDocumento(v)"
                            class="btn-download">
                            <baseIcon 
                                style="color:white"
                                class="downloadFilePC nopadding"
                                :icon='mdiDownloadOutline'
                                size="26"/>
                        </button>
                    </div>
                </figcaption>
            </figure>
        </slide>
    </carousel3d>
</template>

<script lang="js">
import { Carousel3d, Slide } from 'vue-carousel-3d';
import { mdiDownloadOutline } from '@mdi/js'
import Vue from 'vue'
export default Vue.extend({
	inheritAttrs:false,
    name: 'carrosselDocsItemRota',
    components:{
        carousel3d: Carousel3d,
        slide: Slide,
        baseIcon: require('@/components/Atom/Icon/BaseIcon').default,
	},

    props: {
        dados: {
            required: true,
            type: [Array, Object],
            default: () => []
        },
        id: {
            type: String,
            default: 'carousel3D'
        },
        height: {
            type: Number,
            default: 450
        },
        width: {
            type: Number,
            default: 500
        }
    },

    data() {
        return {
			mdiDownloadOutline:mdiDownloadOutline,
        }
    },

    methods: {
        
        getDataSource(documento) {
            let type = documento.type ? documento.type : 'image/jpg'
            return `data:${type};base64,${documento.documento}`
        },

        getPosicaoItem(posicao) {
            return `${posicao} / ${this.dados.length}`
        },

        /**
         * Corrige bug de tamanho dos itens do carousel
         */
        setSizeCarousel(e) {
            if(this.$refs.refCarouselDetalhesRota)
                this.$refs.refCarouselDetalhesRota.setSize()
        },

        /**
        * @listen click - executado quando acionado o ícone de donwload
        * @param {Object[]} file - dados do arquivo
        * @param {String} file.datahora  - data e hora em que o arquivo foi salvo
        * @param {Array}  file.documento - Arquivo codificado
        * @param {String} file.name      - Nome do arquivo
        * @param {String} file.type      - Tipo do arquivo
        * @return Download do arquivo | Erro
        * @author Rafael
        **/ 
        downloadDocumento(file){
            const linkSource = this.getDataSource(file)
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = file.name+'.'+(file.type?file.type:'jpg');
            downloadLink.click();
        },
    },
})
</script>

<style lang="scss">
#carrossel3D{
    &.carousel-3d-container{
        height: 100% !important;
        img{
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            height: 370px;
            object-fit: contain;
        }
        .carousel-3d-slide{
            background-color: rgba(204, 204, 204, 0.8);
            .itemCount{
                text-align:center;
                height: 30px;
                margin:0;
                line-height: 30px;
            }
        }
    }
    .carousel-3d-controls {
        .prev>span {font-size: 50px;}
        .next>span {font-size: 50px;}
    }
    figcaption{
        bottom: 0;
        width: 100%;
        height: 50px;
        text-align: -webkit-center;
        color: white;
        display: inline-flex;
        place-content: center;
        -webkit-box-align: baseline;
        align-items: center;
        background-color: dimgrey;
        .btn-download{
            border:none;
            background-color: transparent;
            height: 50px;
            width: 50px;
        }
    }
    &.downloadFilePC {
        cursor: pointer;
        color: #428bca;
    }
}
</style>