<template>
    <panelEagle id="escalasMotorista" :loading="loadingPanel">
        <div class="col-sm-12 nopadding">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiFileDocument" titulo="Escalas do colaborador"></tituloPage>
                </div>
                <div class="col-sm-6 divButtonsBasic divDontPrint">
                    <basicButtonsRelatoriosEIG
                        :disabledDropdown="!Object.keys(arrayEscalasMotoristas).length"
                        @gerarRelatorio    ="gerarRelatorio" 
                        @exportarRelatorio ="exportarRelatorio"
                        :loading           ='loadingExportar'
                        :disabled          ="$v.$invalid">
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12"><hr></div>
                    <div class="col-sm-12 nopadding row">
                        <div class="col-sm-3 nopadding">
                            <selectAll 
                                @changeSelect ="SelectEmpresas" 
                                :isMultiple   ="true" 
                                nameForRadio  ="radiosEmpresas" 
                                :labels       ="labelSelectEmpresas" 
                                :optionsArray ="optionsEmpresas" 
                                firstSelected ="EM" 
                                :selected     ="selectedEmpresa"
                                :hasSelectAll ="true">
                            </selectAll>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll 
                                @selectCriado ="SelectEscalasCriado" 
                                @changeSelect ="SelectEscalas" 
                                :isMultiple   ="true" 
                                nameForRadio  ="radiosEscalas" 
                                :labels       ="labelSelectEscalas" 
                                :optionsArray ="optionsEscalas" 
                                firstSelected ="E" 
                                :hasSelectAll ="true"
                                ref           ="E"
                                :disabled     ="$v.selectedOptEmpresas.$invalid"
                                :loading      ="carregando">
                            </selectAll>
                        </div>
                        <div class="col-sm-2 nopadding">
                            <selectAll 
                                @selectCriado ="SelectColabGrupoCriado" 
                                @changeCheck  ="changeCheckColab" 
                                @changeSelect ="SelectColabGrupo" 
                                :isMultiple   ="true" 
                                nameForRadio  ="radiosColabGrupo" 
                                :labels       ="labelSelectColabGrupo" 
                                :optionsArray ="optionsColabGrupo" 
                                firstSelected ="C" 
                                :hasSelectAll ="true"
                                ref           ="CG"
                                :disabled     ="$v.selectedOptEmpresas.$invalid"
                                :loading      ="carregando">
                            </selectAll>
                        </div>
                        <div class="col-sm-2 nopadding">
                            <selectAll 
                                @selectCriado ="SelectVeicGrupoCriado" 
                                @changeCheck  ="changeCheckVeic" 
                                @changeSelect ="SelectVeicGrupo" 
                                :isMultiple   ="true" 
                                nameForRadio  ="radiosVeicGrupo" 
                                :labels       ="labelSelectVeiculosGrupoVeiculos" 
                                :optionsArray ="optionsVeicGrupo" 
                                firstSelected ="V" 
                                :hasSelectAll ="true"
                                ref           ="VG"
                                :disabled     ="$v.selectedOptEmpresas.$invalid"
                                :loading      ="carregando">
                            </selectAll>
                        </div>
                        <div class="col-sm-2 nopadding">
                            <selectAll 
                                @selectCriado ="SelectTurnoCriado" 
                                @changeSelect ="SelectTurnos" 
                                :isMultiple   ="true" 
                                nameForRadio  ="radiosTurnos" 
                                :labels       ="labelSelectTurnos" 
                                :optionsArray ="optionsTurnos" 
                                :hasSelectAll ="true"
                                ref           ="T"
                                :disabled     ="$v.selectedOptEmpresas.$invalid"
                                :loading      ="carregando">
                            </selectAll>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
            <div class="col-sm-12 divHrDeBaixo"><hr></div>
        </div>
        <tableRelatorio>
            <slot slot="tbody">
                <statusInformation 
                    v-if="tamanhoArrayEscalas == 0"
                    typeBar="tr" 
                    :statusBar="statusbar">
                </statusInformation>
                <template v-for="(escala, e) in arrayEscalasMotoristas">
                    <!-- Badge com a escala e colaborador -->
                    <tr :key="e"
                        class="backColorTdPDF">
                        <th colspan="2" class="nopadding tdColor">
                            <span id="" class="newbadge">
                                {{e}}
                            </span>
                        </th>
                    </tr>

                    <!-- Primeira linha com informações de veículos -->
                    <tr :key="e+'_Veiculos'"
                        class="headerRegisterPDF">
                        <td class="thVeiculos">
                            Veículos
                        </td>
                        <td class="thDados">
                            {{escala.veiculos}}
                        </td>
                    </tr>

                    <!-- Dias de jornada de trabalho -->
                    <tr :key="e+'Jornada'"
                        class="headerRegisterPDF">
                        <td class="thVeiculos">
                            Jornada de trabalho
                        </td>
                        <td class="thDados">
                            {{escala.jornada[0]}}
                        </td>
                    </tr>

                    <!-- Vigência -->
                    <tr :key="e+'DataVigencia'"
                        class="headerRegisterPDF">
                        <td class="thVeiculos">
                            <span> Data de vigência </span>
                        </td>
                        <td class="thDados">
                            {{escala.dataVigencia}}
                        </td>
                    </tr>

                    <template v-for="(turnos, t) in escala.turnos"
                        class="headerRegisterPDF colorTurn">
                        <tr :key="t+'TurnoTrabalho'+escala.jornada[0]+e"
                            class="turnoTabela">
                            <!-- Turno -->
                            <td colspan="1" class="thVeiculos">
                                <span> Turno de trabalho </span>
                            </td>
                            <td colspan="1" class="thDados">
                                {{t}}
                            </td>
                        </tr>
                        <tr v-for="(dadosTurno, dt) in turnos"
                            :key="dt+'dadosTurno'+dadosTurno[0].horario+e+t">
                            <!-- Dia da semana-->
                            <td colspan="2"
                                class="dadosDia">
                                <div class="diaSemana"> {{dt}} </div> 
                                <template v-for="(dadosDia, dd) in dadosTurno">
                                    <!-- Horário e linha -->
                                    <div :key="dd+'linhaDia'"
                                        class="divHorarioLinha col-sm-12 row"> 
                                        <div class="col-sm-1">
                                            {{dadosDia.horario}}   
                                        </div>
                                        <div class="col-sm-11">
                                            {{dadosDia.linha}}   
                                        </div>
                                    </div>

                                    <!-- Dados do itinerário -->
                                    <div v-if="dadosDia.itinerario != ''"
                                        :key="dd+'Itinerario'"
                                        class="divHorarioLinha col-sm-12 row"> 
                                        <div class="col-sm-1">
                                            Itinerário  
                                        </div>
                                        <div class="col-sm-11">
                                            {{dadosDia.itinerario}}   
                                        </div>
                                    </div>
                                </template>
                            </td>
                        </tr>
                    </template>
                </template>
            </slot>
        </tableRelatorio>
    </panelEagle>
</template>

<script>
import {mdiFileDocument } from '@mdi/js'
import Vue               from 'vue'
import {required}        from 'vuelidate/lib/validators'
import {EmpresasService} from '../../../Services/auth/Empresas.service'
import {HttpRequest}     from '../../../Services/auth/HttpRequest.Service'
import {FiltrosService}  from '../../../Services/filtros/filtros.Service'
import { mapGetters } from 'vuex'

export default Vue.extend({
	name:'EscalasMotoristas',
	components:{
		'panelEagle'                : require('@/components/Atom/Panel/PanelEagle').default,
		'tituloPage'                : require('@/components/Atom/Header/Titulo').default,
		'basicButtonsRelatoriosEIG' : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
		'selectAll'                 : require('@/components/Atom/Select/SelectAll').default,
		'slideUpAndDown'            : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
		'tableRelatorio'            : require('@/components/Atom/Table/TableRelatorio').default,
		'statusInformation'         : require('@/components/Atom/StatusInformation/StatusInformation').default,
	},

	validations:{
		selectedOptEmpresas:{
			required
		},
		selectEscalas:{
			required
		},
		selectTurnos:{
			required
		},
		selectedOptColabGrupo:{
			required
		},
		selectedOptVeicGrupo:{
			required
		},
	},

	data(){
		return{
			mdiFileDocument:mdiFileDocument,
			labelSelectEmpresas:[
				{indexDFH: 'EM', description: 'Empresa *'},
			],
			labelSelectEscalas:[
				{indexDFH: 'E', description: 'Escala *'},
			],
			labelSelectColabGrupo:[
				{indexDFH: 'C', description: 'Colaborador *'},
				{indexDFH: 'GC', description: 'Grupo *'},
			],
			labelSelectVeiculosGrupoVeiculos:[
				{indexDFH: 'V', description: 'Veículo *'},
				{indexDFH: 'GV', description: 'Grupo *'},
			],labelSelectTurnos:[
				{indexDFH: 'T', description: 'Turnos *'},
			],

			selectEscalas           : [],
			selectTurnos            : [],
			selectColabGrupo        : [],
			selectVeicGrupo         : [],
            selectTurnoGrupo : [],
			optionsEmpresas         : new EmpresasService().Get(),
			optionsEscalas          : [],
			optionsTurnos           : [
                {value: 'priTurno', description: '1° Turno'},
                {value: 'segTurno', description: '2° Turno'},
                {value: 'terTurno', description: '3° Turno'},
            ],
			optionsColabGrupo       : [],
			optionsVeicGrupo        : [],
			selectedOptEmpresas     : [],
			selectedOptEscalas      : [],
			selectedOptColabGrupo   : [],
			selectedOptVeicGrupo    : [],
			selectedRadiosColabGrup : 'C',
			selectedRadiosVeicGrup  : 'V',
			arrayOptionsSelects     : [],
			arrayEscalasMotoristas  : [],
			htmlEscalasMotoristas   : '',
			tamanhoArrayEscalas     : 0,
			statusbar               : 'info',
			loadingExportar         : [false, false, false],
			loadingPanel            : false,
			carregando              : false,
            selectedEmpresa         : [],
		}
	},

	methods:{
        ...mapGetters(['getMaster']),

		SelectEmpresas(event){
			this.selectedOptEmpresas = event;
			this.$refs.E.clearAll();
			this.$refs.CG.clearAll();
			this.$refs.VG.clearAll();
			this.$refs.T.clearAll();
			this.dados_filtros();
		},

		SelectEscalasCriado(event){
			this.selectEscalas = event;
		},

		SelectEscalas(event){
			this.selectedOptEscalas = event;
		},
        
		SelectTurnos(event){
            this.selectTurnos = event;
		},
        SelectTurnoCriado(event){
            this.selectTurnoGrupo = event;
        },
		SelectColabGrupoCriado(event){
			this.selectColabGrupo = event;
		},

		SelectColabGrupo(event){
			this.selectedOptColabGrupo = event;
		},

		SelectVeicGrupoCriado(event){
			this.selectVeicGrupo = event;
		},

		SelectVeicGrupo(event){
			this.selectedOptVeicGrupo = event;
		},

		changeCheckColab(event){
			this.selectedRadiosColabGrup = event;
			if(this.arrayOptionsSelects.length != 0){
				this.optionsColabGrupo = this.arrayOptionsSelects[event];
			}
		},

		changeCheckVeic(event){
			this.selectedRadiosVeicGrup = event;
			if(this.arrayOptionsSelects.length != 0){
				this.optionsVeicGrupo = this.arrayOptionsSelects[event];
			}
		},

		async dados_filtros(){
			this.carregando = true
			var dados = [];
			dados = await new FiltrosService().dados_filtros(this.selectedOptEmpresas, ['E','C','GC','V','GV'])
			// this.optionsSelectLLP = dados[this.linhasTipo];
			this.optionsEscalas = dados['E'];
			this.arrayOptionsSelects = dados;
			this.changeCheckColab(this.selectedRadiosColabGrup);
			this.changeCheckVeic(this.selectedRadiosVeicGrup);
			this.carregando = false
		},

		async gerarRelatorio(){
            if(this.selectedOptEmpresas.length > 0 && this.selectedOptEscalas.length > 0){
				this.htmlEscalasMotoristas = '';
				this.arrayEscalasMotoristas = [];
				this.loadingPanel = true;
				var dados = await new HttpRequest().Post('/coletivos/relatorios/escalas/motorista/gerar/relatorio', 
					{
                        'escala'         : this.selectedOptEscalas,
						'turnos'         : this.selectTurnos, 
						'motoristas'     : this.selectedOptColabGrupo, 
						'buscaMotorista' : this.selectedRadiosColabGrup, 
						'veiculo'        : this.selectedOptVeicGrupo, 
						'buscaVeiculo'   : this.selectedRadiosVeicGrup
                    })
				this.tamanhoArrayEscalas = dados.data.tamanho;
                
				if(this.tamanhoArrayEscalas > 0){
                    this.arrayEscalasMotoristas = dados.data.arrayHtml
					this.arrayEscalasExcel      = dados.data.arrayExportaExcel

				}else{
					this.statusbar = 'error';
				}
				this.loadingPanel = false
			}
		},
		async exportarRelatorio(type){
			var root =  process.env.VUE_APP_ROOT;
			if(this.tamanhoArrayEscalas > 0){
				switch(type){
					case 'pdf':
						this.loadingExportar = [true, false, false]
						break;
					case 'xls':
						this.loadingExportar = [false, true, false]
						break;
					case 'csv':
						this.loadingExportar = [false, false, true]
						break;
					default:
						break;
				}
				var data = await new HttpRequest().Post('/coletivos/relatorios/escalas/motorista/exportar/relatorio', 
					{'arrayDados' : JSON.stringify(this.arrayEscalasMotoristas), 
						'arrayExcel' : this.arrayEscalasExcel,
                        'turnos': this.selectTurnos,
						'tipo'       : type});
				window.open(root+'/'+data.data);
				this.loadingExportar = [false, false, false]
			}

		}
	},

    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optionsEmpresas
        }
    }
})
</script>

<style lang="scss">
    #escalasMotorista{
        tbody>tr>th.tdColor {
            font-size: 12px;
            border-bottom: 1px solid #E5E5E5 !important;
        }
    
        tbody>tr:not(.backColorTdPDF)>td {
            padding: 3px;
            font-size: 12px;
            border: 1px solid #e5e5e5;
    
        }
    
        .thVeiculos{
            font-weight: bold !important;
            width: 140px!important;
        }
    
        .colorTurn {
            background: #CCC!important;
        }

        .headerRegisterPDF{
            background: #fff;
        }

        .turnoTabela{
            background: #cccccc;
        }

        .dadosDia{
            padding: 0px !important;
            div.diaSemana{
                background: #dddddd;
                width: 100% !important;
                padding-left: 5px;
                font-weight: bold !important;
            }
            .divHorarioLinha{
                padding: 0px;
                background: #fff !important;
                width: 100% !important;
                div{
                    padding-left: 5px;
                    border: 1px solid #d5d5d5;
                }
            }
        }
    }
</style>