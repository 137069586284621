<template>
    <panelEagle id='Feriados' :loading="loading">
        <div class="col-sm-12 nopadding row">
            <div class="col-sm-6">
                <tituloPage :icon='mdiCalendarMonth' titulo='Feriados'></tituloPage>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6">
                <basicButtonsCadastrosSC
                    :disabled='$v.$invalid'
                    tipoCancela ='button'
                    linkCancel ='listagemFeriadosJornada'
                    @salvarCadastro='salvarCadastro'>
                </basicButtonsCadastrosSC>
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class='col-sm-12 row nopadding'>
            <div class="col-sm-3 nopadding">
                <selectAll
                    :inputClass   ='{"border border-danger":$v.valueEmpresas.$anyError}'
                    @close='$v.valueEmpresas.$touch()'
                    nameForRadio  ='Selecione*'
                    :isMultiple   ='false'
                    :hasSelectAll ='false'
                    :optionsArray ='optEmpresas'
                    :labels       ='labelsEmp'
                    :selected      ='selectedEmp'
                    :value        ='valueEmpresas'
                    @changeSelect ='changeEmpresa'/>
            </div>
            <div class="col-sm-3 nopadding">
                <selectAll
                    :inputClass   ='{"border border-danger":$v.valueTipo.$anyError}'
                    @close='$v.valueTipo.$touch()'
                    nameForRadio  ='Selecione*'
                    :isMultiple   ='false'
                    :hasSelectAll ='false'
                    :optionsArray ='optTipo'
                    :labels       ='labelsTip'
                    :value        ='valueTipo'
                    firstSelected ='TI'
                    :selected     ='selectedTipo'
                    @changeSelect ='changeTipo'/>
            </div>
            <div class="col-sm-3 nopadding">
                <inputSimple
                    :inputClass='{"border border-danger":$v.valueDescicao.$anyError}'
                    label='Descrição*'
                    name='descrição'
                    :value='valueDescicao'
                    @changeInput='changeDesc'
                    @blur='$v.valueDescicao.$touch()'/>
            </div>
            <div class="col-sm-3 nopadding">
                <inputOnlyDayAndMonth
                    name = 'Data'
                    :label ='labelData'
                    :value ='valueDataEdit'
                    :isObrigatorio='true'
                    @changeInput='changeDia'/>
            </div>
            <requiredFields/>    
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
    </panelEagle>
</template>
<script lang='js'>
import{mdiCalendarMonth } from '@mdi/js'
import Vue  from 'vue'
import Vuex from '@/store/index'
import { required } from 'vuelidate/lib/validators'
import { EmpresasService } from '../../../Services/auth/Empresas.service'
import { HttpRequest } from '../../../Services/auth/HttpRequest.Service'
import { DateTime } from 'luxon'
export default Vue.extend({
    name:'CadastroDeFeriadosJornada',
    components:{
        'panelEagle'               : require('@/components/Atom/Panel/PanelEagle').default,
        'basicButtonsCadastrosSC'  : require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
        'tituloPage'               : require('@/components/Atom/Header/Titulo').default,
        'selectAll'                : require('@/components/Atom/Select/SelectAll').default,
        'inputSimple'              : require('@/components/Atom/Inputs/InputSimple').default,
        'requiredFields'           : require('@/components/Atom/Footer/RequiredFields').default,
        'inputOnlyDayAndMonth'     : require('@/components/Atom/Datas/InputOnlyDayAndMonth').default,
    },
    validations:{
        valueEmpresas:{required},
        valueTipo    :{required},
        valueDescicao:{required},
    },

    data(){
        return {
            mdiCalendarMonth:mdiCalendarMonth,
            optEmpresas : new EmpresasService().Get(),
            optTipo     :[
                {value: 'N', description: 'Nacional'},
                {value: 'R', description: 'Regional'}
            ],
            labelsEmp   :[{
                indexDFH    : 'SE',
                description : 'Empresa*'
            }],
            labelsTip   :[{
                indexDFH    : 'TI',
                description : 'Tipo*'
            }],
            labelData : 'Data*',
            valueData : '',
            valueDataEdit: '',
            usuario   : '',
            valueDescicao: '',
            valueTipo     :[],
            selectedTipo  :[],
            selectedEmp   :[],
            valueEmpresas :[],
            id            : undefined,
            loading       : false,
                   
        }
    },

    methods:{
        async salvarCadastro(){
            this.loading = true
            var obj = [{'empresa'   :this.valueEmpresas},
                {'tipo'      :this.valueTipo},
                {'descricao' :this.valueDescicao},
                {'data'      :this.valueData},
                {'id'        :this.id}]
            var dados = await new HttpRequest()
            if(this.id == undefined){
                dados.Post('/controle/jornada/cadastros/feriados/salvar', obj)
            }
            else if(this.id != undefined){
                dados.Post('/controle/jornada/cadastros/feriados/editar', obj)
            }
            this.loading = false
            this.$router.push({name:'listagemFeriadosJornada'})
        },
        changeTipo(value){
            this.valueTipo = value
        },
        changeEmpresa(value){
            this.valueEmpresas = value
        },
        changeDesc(value){
            this.valueDescicao = value
        },
        changeDia(value){
            this.valueData = value
        },

        formataData(data){
            return DateTime.fromFormat(data, 'yyyy/LL/dd').toFormat('dd/LL')
        }
           
    },
    async mounted(){
        this.id = this.$route.params.id
        this.usuraio = this.$store.getters.getMaster
        if(!this.usuraio){
            this.optTipo = [{
                value: 'R', 
                description: 'Regional'
            }]
            this.selectedTipo = [{
                value: 'R', 
                description: 'Regional'
            }]
        }
        else{
            this.optTipo = [
                {
                    value: 'N', 
                    description: 'Nacional'
                }, 
                {
                    value: 'R', 
                    description: 'Regional'
                }
            ]
            this.selectedTipo = [{
                value: 'N', 
                description: 'Nacional'
            }]
        }
        if(this.id != undefined){ // tem id
            this.loading =  true
            this.$nextTick(() => {
                new HttpRequest()
                    .Post('/controle/jornada/cadastros/feriados/buscar', {'id':this.id})
                    .then(({ data }) => {
                        this.valueDescicao = data.descricao
                        this.selectedTipo  = data.tipo
                        this.selectedEmp   = data.cliente
                        this.valueData = this.formataData(data.data)
                        this.valueDataEdit = data.data
                    }).finally(() => {
                        this.loading = false
                    })
            })
        }

    }
})


</script>
<style lang="scss">
    .espaco{
        margin-right: 100px!important;
        padding-left: 30px!important;
    }
</style>
