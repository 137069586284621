<template>
    <panelEagle id="ControleTemperatura" :loading="loadingPanel">
        <div class="col-sm-12 row nopadding">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiThermometerLines" titulo="Controle de Temperatura" />
                </div>
                <div class="col-sm-6 divDontPrint">
                    <basicButtonsRelatoriosEIG
                        :disabled="valueVeiculos.length <= 0"
                        :disabledDropdown="desabilitaExportar"
                        :loading="loadingExporta"
                        @gerarRelatorio="gerarRelatorio"
                        @exportarRelatorio="exportarRelatorio"
                    />
                </div>
            </div>
            <div class="col-sm-12"><hr /></div>
            <div class="col-sm-3 row nopadding divDontPrint">
                <div class="col-sm-12 nopadding divDontPrint">
                    <inputSingleDay @changeInput="changeInputData" name="inputSingleDay" />
                </div>
                <div class="col-sm-12 nopadding row espaco-topo">
                    <div class="col-sm-6 nopadding divDontPrint">
                        <inputSimple
                            @changeInput="changeInputHoraInicio"
                            :hasMask="true"
                            :mask="['hN:MN']"
                            :value="valueHoraInicio"
                            label="Hora Inicial"
                        />
                    </div>
                    <div class="col-sm-6 nopadding divDontPrint">
                        <inputSimple
                            @changeInput="changeInputHoraFim"
                            :hasMask="true"
                            :mask="['hN:MN']"
                            :value="valueHoraFim"
                            label="Hora Final"
                        />
                    </div>
                </div>
            </div>
            <div class="col-sm-3 row nopadding">
                <div class="col-sm-12 nopadding divDontPrint">
                    <selectAll
                        @changeSelect="changeSelectEmpresa"
                        nameForRadio="nameForRadio-camilo"
                        :isMultiple="true"
                        :hasSelectAll="true"
                        :labels="labelsEmpresa"
                        :selected="selectedEmpresa"
                        :optionsArray="optEmpresa"
                    />
                </div>
                <div class="col-sm-6 nopadding divDontPrint mt-2">
                    <inputSimple
                        :max="valueTemperaturaMaxima"
                        @changeInput="changeInputTemperaturaMinima"
                        type="number"
                        :hasMask="false"
                        label="Temperatura mínima"
                    />
                    <!-- :value="valueTemperaturaMinima" -->
                </div>
                <div class="col-sm-6 nopadding divDontPrint mt-2">
                    <inputSimple
                        :min="valueTemperaturaMinima"
                        @changeInput="changeInputTemperaturaMaxima"
                        type="number"
                        :hasMask="false"
                        label="Temperatura máxima"
                    />
                    <!-- :value="valueTemperaturaMaxima" -->
                </div>
            </div>
            <div class="col-sm-3 nopadding divDontPrint">
                <selectAll
                    ref="refVeiculos"
                    :loading="loadingVeiculos"
                    :disabled="valueEmpresa.length == 0"
                    @changeSelect="changeSelectVeiculos"
                    @changeCheck="changeCheckbox"
                    nameForRadio="pedro"
                    :isMultiple="true"
                    :hasSelectAll="true"
                    :labels="labelsVeiculo"
                    :optionsArray="optVeiculo"
                    firstSelected="V"
                />
            </div>
            <div class="col-sm-3 row">
                <div class="caixa-com-menu col-sm-12 row text-left">
                    <div class="col-sm-12 nopadding">
                        <span class="col-sm-11 nopadding">
                            <span class="cor-verde">
                                <baseIcon
                                    size="18"
                                    class="iconTitulo"
                                    :icon="mdiCheckboxBlankCircle"
                                />
                            </span>
                            Dentro da faixa
                        </span>
                    </div>
                    <div class="col-sm-12 nopadding">
                        <span class="col-sm-11 nopadding">
                            <span class="cor-azul">
                                <baseIcon
                                    size="18"
                                    class="iconTitulo"
                                    :icon="mdiCheckboxBlankCircle"
                                />
                            </span>
                            Abaixo da faixa
                        </span>
                    </div>
                    <div class="col-sm-12 nopadding">
                        <span class="col-sm-11 nopadding">
                            <span class="cor-rosa">
                                <baseIcon
                                    size="18"
                                    class="iconTitulo"
                                    :icon="mdiCheckboxBlankCircle"
                                />
                            </span>
                            Acima da faixa
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-sm-12"><hr /></div>
            <div class="col-sm-12" v-show="mostraStatusBar">
                <statusInformation :statusBar="statusBar" typeBar="div" />
            </div>
            <div
                class="col-sm-12 row text-left nopadding"
                v-for="(dados, index) in values"
                :key="index + '_veiculo'"
            >
                <div class="badzinha col-sm-12 my-auto">
                    <div class="fundo">
                        {{ index }}
                    </div>
                </div>
                <div class="col-md-12 col-sm-12 marg-bot-30px row nopadding">
                    <charts
                        name="asdasd"
                        :annotationYaxis="[
                            values[index]['marcadores'][0],
                            values[index]['marcadores'][1],
                            values[index]['marcadores'][2],
                        ]"
                        namePopUp="temperatura"
                        :values="values[index]['grafico'].temperatura"
                        :categories="values[index]['grafico'].hora"
                    />
                </div>
                <div class="col-sm-12 divImprimir tableFixHead">
                    <table class="table">
                        <thead id="theadRelatorioCommun">
                            <tr>
                                <th>Data/Hora</th>
                                <th>Temperatura</th>
                                <th>Localização</th>
                                <th>Motorista</th>
                            </tr>
                        </thead>
                        <tbody id="tBodyRelatorioCommun">
                            <tr v-for="(valores, i) in dados.registros" :key="i + '_registro'">
                                <td>{{ valores.bidataevento }}</td>
                                <td :class="valores.classe">{{ valores.bitemperatura }}°</td>
                                <td>{{ valores.biendereco }}</td>
                                <td>{{ valores.mtnome }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </panelEagle>
</template>
<script>
import { conectionError } from "@/Services/Helpers/swellHeper.js";
import { mdiThermometerLines, mdiCheckboxBlankCircle } from "@mdi/js";
import { EmpresasService } from "@/Services/auth/Empresas.service";
import { HttpRequest } from "@/Services/auth/HttpRequest.Service";
import moment from "moment";
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
    name: "ControleTemperatura",

    components: {
        baseIcon: require("@/components/Atom/Icon/BaseIcon.vue").default,
        panelEagle: require("@/components/Atom/Panel/PanelEagle").default,
        charts: require("@/components/Atom/Grafico/Charts").default,
        tituloPage: require("@/components/Atom/Header/Titulo").default,
        inputSingleDay: require("@/components/Atom/Datas/InputSingleDay").default,
        selectAll: require("@/components/Atom/Select/SelectAll").default,
        inputSimple: require("@/components/Atom/Inputs/InputSimple").default,
        statusInformation: require("@/components/Atom/StatusInformation/StatusInformation").default,
        basicButtonsRelatoriosEIG: require("@/components/Atom/Buttons/BasicButtonsRelatoriosEIG")
            .default,
    },

    data() {
        return {
            mdiCheckboxBlankCircle: mdiCheckboxBlankCircle,
            loadingPanel: false,
            mdiThermometerLines: mdiThermometerLines,
            valueTemperaturaMaxima: "",
            valueTemperaturaMinima: "",
            valueData: "",
            optEmpresa: new EmpresasService().Get(),
            optVeiculo: [],
            mostraStatusBar: true,
            values: [],
            loadingVeiculos: false,
            valueTipoVeiculo: "V",
            valueVeiculos: [],
            valueHoraInicio: "00:00",
            valueHoraFim: "23:59",
            labelsEmpresa: [{ indexDFH: "EM", description: "Empresas*" }],
            statusBar: "info",
            labelsVeiculo: [
                { indexDFH: "V", description: "Veículos*" },
                { indexDFH: "GV", description: "Grupos*" },
            ],
            valueEmpresa: [],
            loadingExporta: [false, false, false],
            uriBase: "/controle/temperatura/relatorios/temperatura/",
            selectedEmpresa: [],
        };
    },
    
    computed: {
        desabilitaExportar() {
            return !Object.values(this.values).length;
        },
    },

    methods: {
        ...mapGetters(["getMaster"]),

        changeInputTemperaturaMinima(value) {
            this.limpaRelatorio();
            this.valueTemperaturaMinima = value;
        },

        changeInputTemperaturaMaxima(value) {
            this.limpaRelatorio();
            this.valueTemperaturaMaxima = value;
        },

        changeInputData(value) {
            this.limpaRelatorio();
            this.valueData = value;
        },

        limpaRelatorio() {
            this.values = [];
            this.statusBar = "info";
            this.mostraStatusBar = true;
        },

        async exportarRelatorio(value) {
            if (!this.mostraStatusBar) {
                this.loadingExporta = [value == "pdf", value == "xls", value == "csv"];
                var valores = {
                    tipoExporta: value,
                    dados: this.values,
                    data: this.valueData,
                };
                var root = process.env.VUE_APP_ROOT;
                let uri = this.uriBase + "exporta/relatorio";
                var dados = await new HttpRequest()
                    .Post(uri, valores)
                    .then((dados) => {
                        window.open(root + "/" + dados.data.dados);
                    })
                    .finally(() => {
                        this.loadingExporta = [false, false, false];
                    });
            }
        },

        async gerarRelatorio() {
            this.loadingPanel = true;
            this.mostraStatusBar = true;
            this.statusBar = "info";
            this.values = [];
            let valores = {
                empresa: this.valueEmpresa,
                tipoVeiculo: this.valueTipoVeiculo,
                veiculos: this.valueVeiculos,
                horaInicio: this.valueHoraInicio,
                horaFim: this.valueHoraFim,
                data: this.valueData,
                tempMax: this.valueTemperaturaMaxima,
                tempMin: this.valueTemperaturaMinima,
            };
            let uri = this.uriBase + "gerar";
            await new HttpRequest()
                .Post(uri, valores)
                .then(({ data, status }) => {
                    if (status && Object.values(data.consulta).length) {
                        let valoresGrafico = data.consulta;
                        for (let veiculo in valoresGrafico) {
                            let hora = [];
                            let temperatura = [];
                            for (const index in valoresGrafico[veiculo].grafico) {
                                let vGrafico = valoresGrafico[veiculo].grafico[index];
                                let mmt = moment(vGrafico[0], "DD/MM/YYYY HH:mm:ss");
                                let horaFormatada = mmt.format("HH:mm:ss");
                                hora.push(horaFormatada);
                                temperatura.push(vGrafico[1]);
                            }
                            valoresGrafico[veiculo].grafico = {
                                temperatura: temperatura,
                                hora: hora,
                            };
                        }
                        this.values = valoresGrafico;
                        this.mostraStatusBar = false;
                    } else {
                        this.statusBar = "error";
                        setTimeout(() => {
                            this.statusBar = "info";
                        }, 5000);
                    }
                })
                .catch((error) => {
                    conectionError();
                })
                .finally(() => {
                    this.loadingPanel = false;
                });
        },

        changeCheckbox(value) {
            this.limpaRelatorio();
            this.valueTipoVeiculo = value;
            if (this.valueEmpresa.length > 0) {
                this.changeSelectEmpresa(this.valueEmpresa);
            }
        },

        changeSelectVeiculos(value) {
            this.limpaRelatorio();
            this.valueVeiculos = value;
        },

        async changeSelectEmpresa(value) {
            this.limpaRelatorio();
            this.valueEmpresa = value;
            this.optVeiculo = [];
            this.$refs.refVeiculos.$data.selectedItens = [];
            this.valueVeiculos = [];
            this.loadingVeiculos = true;
            var valores = {
                empresa: this.valueEmpresa,
                tipoVeiculo: this.valueTipoVeiculo,
            };
            await new HttpRequest()
                .Post(this.uriBase + "busca/veiculo", valores)
                .then(({ data, status }) => {
                    if (status) {
                        this.optVeiculo = data;
                    }
                })
                .finally(() => {
                    this.loadingVeiculos = false;
                });
        },

        changeInputHoraInicio(value) {
            this.limpaRelatorio();
            if (Number(value[0] + value[1]) >= 24) {
                this.valueHoraInicio = "23:59";
            } else {
                this.valueHoraInicio = value;
            }
        },

        changeInputHoraFim(value) {
            this.limpaRelatorio();
            if (Number(value[0] + value[1]) >= 24) {
                this.valueHoraFim = "23:59";
            } else {
                this.valueHoraFim = value;
            }
        },
    },

    mounted() {
        if (!this.getMaster()) {
            this.selectedEmpresa = this.optEmpresa;
        }
    },
});
</script>
<style lang="scss" scoped>
#ControleTemperatura {
    .pad-tr {
        margin-top: 15px;
    }
    .bolinha {
        margin-bottom: 5px;
        min-width: 30px;
        max-width: 30px;
        min-height: 30px;
        max-height: 30px;
        border-radius: 90%;
    }
    .fundo-verde {
        background-color: #00af94;
        max-height: 50px;
        text-align: center;
        padding-top: 15px;
        // margin-bottom: 5px;
    }
    .fundo-azul {
        background-color: #0091e2;
        max-height: 50px;
        margin-top: -90px;
        text-align: center;
        padding-top: 15px;
        // margin-bottom: 5px;
    }
    .fundo-rosa {
        background-color: #fb7676;
        max-height: 50px;
        margin-top: -145px;
        text-align: center;
        padding-top: 15px;
        // margin-bottom: 5px;
    }
    .cor-verde {
        color: #8DE0A9;
    }
    .cor-azul {
        color: #B0D6F5;
    }
    .cor-rosa {
        color: #FF968F;
    }
    .caixa-com-menu {
        font-size: 13px !important;
        position: relative;
        background-color: #2a2a2e10;
        padding: 10px !important;
        // border: 12px solid #2a2a2e00;

        .deixa-texto-no-esquema {
            top: 5px;
            position: absolute;
            left: 35px;
        }
    }
    .pad-lef-0 {
        padding-left: 0px !important;
    }
    .pad-rig-0 {
        padding-right: 0px !important;
    }
    .espaco-topo {
        margin-top: 5px;
    }
    .badzinha {
        width: 100%;
        font-size: 20px;
        text-align: left !important;
        position: relative;
        margin-bottom: 5px;
        margin-top: 5px;
        .fundo {
            padding-top: 5px;
            position: absolute;
            background-color: #777777;
            color: #fff;
            height: 30px;
            max-width: 30%;
            min-width: 30%;
            text-align: center;
            border-top: 1px solid;
            // width: 350px !important;
        }
    }
    .cor-temp-max {
        color: #fb7676;
    }
    .cor-temp-min {
        color: #0091e2;
    }
    .cor-temp-med {
        color: #00af94;
    }
    /// estilo copido da tableRealatorio
    .hrDeBaixo {
        margin-top: 140px;
    }
    .tableFixHead {
        overflow-y: auto;
        max-height: 200px !important;
        table {
            margin-bottom: 0;
            padding-right: 10px;
            padding-left: 10px;
            thead {
                th {
                    position: sticky;
                    top: -1px;
                    z-index: 1;
                    padding: 0.2rem;
                    background: #f5f5f5;
                    color: #514e4e;
                    font-weight: bold;
                    font-size: 12px;
                }
            }
            tbody tr {
                border-bottom: 1px solid #e5e5e5;
                background-color: #efefef;
            }
        }
    }
    .tableFixHead {
        text-align: left;
        .backColorTdPDF {
            border-bottom: 1px solid #e5e5e5;
            background-color: #fff;
            td {
                background-color: #fff;
            }
            th {
                background-color: #fff;
            }
            .newbadge {
                min-width: 30%;
                padding: 8px;
                font-weight: 500;
                line-height: 20px;
                margin-top: 20px;
                height: 31px;
                border-radius: 0px;
                text-align: center;
                color: #fff;
                background-color: #777;
                white-space: nowrap;
                float: left;
                margin-left: 0px;
                margin-bottom: 0px;
            }
        }
        tr {
            border-bottom: 1px solid #e5e5e5;
            background-color: #efefef;
            td {
                padding-top: 0.15rem;
                padding-bottom: 0.15rem;
                font-size: 12px;
            }
            th {
                padding-top: 0.15rem;
                padding-bottom: 0.15rem;
            }
        }
    }
}
</style>