<template>
    <panelEagle id="ListarColeta" :loading="loadingPanel">
        <div class="col-sm-12 nopadding  ">
            <div class="col-sm-12 nopadding row ">
                <div class="col-sm-6">
                    <tituloPage titulo="Coletas" :icon="mdiPackageVariantPlus"></tituloPage>
                </div>
                <div class="col-sm-6 divDontPrint">
                    <botoesEIN
                        :loading="loadingExporta"
                        tipoNovo="button"
                        linkNew="cadastrarColeta"
                        @exportarCadastro="exportarColeta"
                        :disabledDropdown="$v.dataTable.$invalid">
                    </botoesEIN>
                </div>
                <div class="col-sm-12"><hr></div>
                <div class="col-sm-5 nopadding divDontPrint">
                   <selectAll
                        :selected="selectedEmpresa"
                        nameForRadio='FiltroEmpresas'
                        :labels="labels"
                        :isMultiple='true'
                        :optionsArray='optSelectEmpresas'
                        :hasSelectAll='true'
                        @changeSelect='selecionaEmpresa'/>
                </div>
                <div class="col-sm-7 row nopadding divBotoes divDontPrint">
                    <buttonsFilters
                        label="Status" 
                        :onlyOneSelected='true'
                        :arrayButtons='filtroStatus'
                        @buttonsFiltersChange='mudaFiltroStatus'>
                    </buttonsFilters>
                </div>
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <tableListagem
            :loading='loadingTableListagem'
            :titles='titulosTable'
            :data='dataTable'
            deleteEdit='editStatus'
            @alterarStatus ='alterarStatus'
            @editarCadastro='editarColeta'>
        </tableListagem>
    </panelEagle>
</template>

<script>
import { mdiCheckBold, mdiPackageVariantPlus } from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import Vue from 'vue'
import { conectionError } from '@/Services/Helpers/swellHeper';
export default Vue.extend({
    name: 'ListagemColeta',
    validations:{
        dataTable: { required },
    },
    components:{
        panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
        tituloPage: require('@/components/Atom/Header/Titulo').default,
        botoesEIN: require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
        selectAll: require('@/components/Atom/Select/SelectAll').default,
        buttonsFilters: require('@/components/Atom/Buttons/ButtonsFilters').default,
        tableListagem: require('@/components/Atom/Table/TableListagem').default,
    },
    data(){
        return{
            loadingExporta: [false, false, false],
            mdiPackageVariantPlus: mdiPackageVariantPlus,
            mdiCheckBold: mdiCheckBold,
            loadingPanel:false,
            loadingTableListagem: false,
            url : '/roteirizador/cadastros/tipo/coleta/',
            // value
            valueFiltroStatus:[],
            valueEmpresasSelecionadas:[],
            // opt
            optSelectEmpresas : [],
            selectedEmpresa   : [],
            // Botões/titulos
            filtroStatus:[
                {'value':'A',  'width': '31.8%', 'text': 'Ativos',   'isSelected':true,  icon:mdiCheckBold},
                {'value':'I',  'width': '31.8%', 'text': 'Inativos', 'isSelected':false, 'icon':''},
                {'value':'T',  'width': '31.8%', 'text': 'Todos',    'isSelected':false, 'icon':''},
            ],            
            titulosTable:[
                {'name' : 'Descrição',     'number' : 'tcdescricao'},
                {'name' : 'Empresa',       'number' : 'clfantasia'},
                {'name' : 'Unidade de medida', 'number' : 'medida'},
            ],
            labels:[
                {indexDFH     : 'EM', 
                 description  : 'Empresas'}
            ],
            dataTable:[],
        }
    },
    methods:{
        editarColeta(value){
            this.$router.push({name:'cadastrarColeta', params:{id:value}})
        },

        /**
         *  @description Método pegar o último valor do filtro e 
         *      também alterar qual esta selecionado no momento
         *  @param {array} value - valor do filtro selecionado
         *  @author Vitor Hugo 🐨
         */
        mudaFiltroStatus(value){
            if(value[0] == 'T'){
                this.valueFiltroStatus = ['A', 'I'];
            }else{
                this.valueFiltroStatus = value;
            }
            this.filtroStatus[0].icon = ''
            this.filtroStatus[1].icon = ''
            this.filtroStatus[2].icon = ''
            if(value[0] == 'A'){
                this.filtroStatus[0].icon = mdiCheckBold
            } else if(value[0] == 'I'){
                this.filtroStatus[1].icon = mdiCheckBold
            } else if(value[0] == 'T'){
                this.filtroStatus[2].icon = mdiCheckBold
            }
            this.listarColeta()
        },
        
        selecionaEmpresa(value){
            this.valueEmpresasSelecionadas = value;
            this.listarColeta();
        },

        /**
         *  @description Método para buscar dados para listagem de coletas
         *  também cria o objeto a ser levado ao back
         *  @author Vitor Hugo 🐨
         */
        async listarColeta(){
            this.limpaDados();
            if(this.valueEmpresasSelecionadas.length > 0){
                this.loadingPanel = true
                var obj = {
                    'empresas': this.valueEmpresasSelecionadas,
                    'status'  : this.valueFiltroStatus,
                }; 
                new HttpRequest().Post(this.url+'listar', obj)
                .then(dados=>{
                    this.dataTable = dados.data.dados
                    this.loadingPanel = false
                }).catch(()=>{
                    conectionError();
                }).finally(() => this.loadingPanel = false);
            }
        },

        limpaDados(){
            this.dataTable = [];
        },

        alterarStatus(value){
            var obj = {codigo:value};
            this.loadingPanel = true;
            new HttpRequest().Post(this.url+'alterar/status', obj)
                .then(dados=>{
                this.listarColeta();
            })
        },
        /**
         * @author Função do: Vitor Hugo 🐨
         */
        exportarColeta(value){
            switch (value) {
                case 'pdf': this.loadingExporta = [true, false, false]; break;
                case 'xls': this.loadingExporta = [false, true, false]; break;
                default: this.loadingExporta = [false, false, true]; break;
            }
            var root =  process.env.VUE_APP_ROOT;
            var obj = {
                tipo:value,
                coletas:this.dataTable
            }
            new HttpRequest().Post(this.url+'exportar', obj)
                .then(dados=>{
                    window.open(root+'/'+dados.data.local); 
                }).catch(() => {
                    conectionError();
                }).finally(()=>{this.loadingExporta = [false, false, false]})
        },

        /**
         *  @description Método para quando for um usuário comum vir todas as empresas selecionadas
         *  @author Vitor Hugo 🐨
         */
        selectAllClientes(){
            this.optSelectEmpresas =  new EmpresasService().Get()
            if(!this.getMaster()){
                this.selectedEmpresa = this.optSelectEmpresas
            }
        },

        ...mapGetters(['getMaster']),
    },
    mounted(){
        this.selectedEmpresa = this.optSelectEmpresas
        this.selectAllClientes()
    }
})
</script>

<style lang="scss" scoped>
.divBotoes{
    text-align: left;
}

@media print{
    .divDontPrint{
        display: none;
    }
}
</style>