<template>
	<div class="col-sm-12" id="tabs-linhas">
		<b-tabs v-model="tabIndex">
			<!-- TAB Escolher Pontos -->
			<b-tab title="Escolher Pontos" :title-link-class="escolhePontos ? 'bg-success' : ''">
				<TabsLinhasEscolherPontos @showAlertInfoEdicaoLinha="showAlertInfoEdicaoLinha" ref="refTabsLinhasEscolherPontos"
					:controleLoading="controleLoading" :tipoPonto="tipoPonto" @buscarPontos="validacaoTab"
					:velocidadeMedia="velMedia" :buscarPontosProp="buscarPontosProp" :cliente="clienteProp"
					:tipoPontoSelecionadoNumber="tipoPontoSelecionadoNumber" :editandoLinhaProp="editandoLinhaProp"
					:selectedTipo="selectedTipo" :inputPontoSelecionado="inputPontoSelecionado"
					@alterarTipoPonto="alterarTipoPonto" />
			</b-tab>

			<!-- TAB definir horários -->
			<b-tab title="Definir horário" :title-link-class="definirHorario ? 'bg-success' : ''">
				<TabLinhasDefinirHorario ref="refTabLinhasDefinirHorario" :controleLoading="controleLoading"
					:distanciaCalculo="distanciaCalculo" @objTabela="validacaoTab" :optCalendario="optCalendario" />
			</b-tab>

			<!-- TAB informações adicionais -->
			<b-tab title="Informações adicionais" :title-link-class="informacoesAdicionais ? 'bg-success' : ''">
				<TabsLinhasInformacoesAdicionais ref="refInformaçõesAdicionais" :controleLoading="controleLoading"
					:desativaLinhaPrincipal="linhaPrincipal" @valoresTab="validacaoTab"
					:optLinhaPrincipal="optLinhaPrincipalProp" />
			</b-tab>
		</b-tabs>
	</div>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
	name: 'TabsLinhas',

	props: {
		editando: {
			type: Boolean,
			default: false
		},

		editandoLinhaProp: {
			type: Boolean,
			required: false,
		},

		tipoPontoSelecionadoNumber: {
			type: Number,
			required: false,
		},

		selectedTipo: {
			type: Array,
			required: false,
		},

		controleLoading: {
			type: Boolean,
			default: false
		},

		tipoPonto: {
			type: Boolean,
			default: false
		},

		tipoPontoSelecionado: {
			type: Boolean,
			default: false
		},

		PontosProp: {
			type: Array,
			required: false,
			default: function () { return [] }
		},

		optCalendario: {
			type: Array,
			required: false,
			default: function () { return [] }
		},

		optLinhaPrincipal: {
			type: Array,
			required: false,
			default: function () { return [] }
		},

		clienteProp: {
			type: [String, Number],
			default: ''
		},

		velMedia: {
			type: Number,
			default: 40
		},

		linhaPrincipal: {
			type: Boolean,
			default: false
		},

		inputPontoSelecionado: {
			type: Number,
			required: false,
		}
	},

	watch: {
		clienteProp() {
			if (!this.editando) {
				this.limparPontos()
				this.$refs.refTabLinhasDefinirHorario.limpaVariaveis()
				this.$refs.refInformaçõesAdicionais.limparVariaveis()
			}
		},

		PontosProp() {
			this.buscarPontosProp = this.PontosProp
		},

		optLinhaPrincipal() {
			this.optLinhaPrincipalProp = this.optLinhaPrincipal
		},
	},

	components: {
		TabLinhasDefinirHorario: require('@/components/Atom/SpecificComponents/CadastroLinhas/TabLinhasDefinirHorario.vue').default,
		TabsLinhasEscolherPontos: require('@/components/Atom/SpecificComponents/CadastroLinhas/TabsLinhasEscolherPontos.vue').default,
		TabsLinhasInformacoesAdicionais: require('@/components/Atom/SpecificComponents/CadastroLinhas/TabsLinhasInformacoesAdicionais.vue').default,
	},

	data() {
		return {
			// controla a cor das tab
			escolhePontos: false,
			definirHorario: false,
			informacoesAdicionais: false,

			optLinhaPrincipalProp: [],
			tabIndex: 0,
			buscarPontosProp: [],
			distanciaCalculo: 0,
			objTabela: [],
		}
	},

	methods: {
		arrumaValoresEditar(dados) {
			this.$refs.refTabsLinhasEscolherPontos.arrumaValoresEditar(dados)
			this.$refs.refTabLinhasDefinirHorario.arrumaValoresEditar(dados)
			this.$refs.refInformaçõesAdicionais.arrumaValoresEditar(dados)
		},

		limparPontos() {
			this.$refs.refTabsLinhasEscolherPontos.limparPontos()
		},

		copiaRastro(value) {
			this.$nextTick(() => {
				this.$refs.refTabsLinhasEscolherPontos.copiaRastro(value)
			})
		},

		/**
		 * Função para validar e liberar o botão para savlar a linha os dados informados nas 3 tabs que 
		 * tem no cadastro, Escolher Pontos, Definir Horário, Informações Adicionais.
		 * @author Desconhecido 
		*/
		validacaoTab(value, qual) {
			switch (qual) {
				case 'tab-1': this.salvaEscolherPontos(value); break;
				case 'tab-2': this.salvaObjtTabela(value); break;
				case 'tab-3': this.validaObjtTab3(value); break;
				default: break;
			}

			var number = Number(this.escolhePontos) + +!!this.definirHorario + +!!this.informacoesAdicionais;

			if (qual == 'tab-1' && value[0].length > 0) {
				number++;
			}
			this.$emit('validaTab', number);
		},

		/**
		 * Verfica se tem pontos para fazer a validação
		 * de se pode salvar ou não.
		 * Caso o tipo ponto seja 'Sem pontos' ele 
		 * desconsidera os pontos para evitar uns problemas na validação.
		 * Essa tela precisa ser refatorada urgente @_@
		 * @author Provalmente o Gui '-'
		*/
		salvaEscolherPontos(value) {
			this.escolhePontos = value[0].length >= 2;

			if (value[0].length <= 0) {
				this.escolhePontos = false;
			}

			this.distanciaCalculo = Number(value[1]);
		},

		salvaObjtTabela(value) {
			this.objTabela = value
			this.definirHorario = value.length
		},

		/**
		 * Função para validar os valores da tab 3, e deixar ela em verde quando esteja tudo informado corretmente
		 * @Param {obj} value, array contendo os valores da tab 3
		 * @author Desconhecido
		*/
		validaObjtTab3(value) {
			if (this.linhaPrincipal) {
				this.objTab3 = value;
				this.informacoesAdicionais = value.liatrasotolerado.length;
			} else if (!this.linhaPrincipal && value.linhaPrincipal != undefined) {
				this.objTab3 = value;
				this.informacoesAdicionais = value.liatrasotolerado.length;
			} else if (!this.linhaPrincipal && value.linhaPrincipal > 0) {
				this.objTab3 = value;
				this.informacoesAdicionais = value.liatrasotolerado.length;
			} else {
				this.objTab3 = [];
				this.informacoesAdicionais = 0;
			}
		},

		pegaValoresSalvar() {
			return {
				objInfoAdd: { ...this.objTab3 },
				objDefinirHorario: this.objTabela,
				objEscolherPontos: this.$refs.refTabsLinhasEscolherPontos.emiteValores()
			}
		},

		showAlertInfoEdicaoLinha(value) {
			this.$emit('showAlertInfoEdicaoLinha', value);
		},

		/**
		 * Função para mudar o valor de tipoPonto em cadastroLinhas
		 * @Param {boolean} value, booleano para mandar para CadastroLinhas.vue
		 * @author Yuri 🇧🇷
		*/
		alterarTipoPonto(value) {
			this.$emit('alterarTipoPonto', value);
		}
	},
})
</script>

<style lang="scss">
#tabs-linhas {
	.bg-success {
		background-color: rgb(175, 240, 149) !important;
	}
}
</style>
