<template>
    <panelEagle id='listarColaboradores' :loading="loadingPanel">
        <div class='col-sm-12 nopadding  '>
            <div class="col-sm-12 nopadding row ">
                <div class="col-sm-6">
                    <tituloPage titulo='Colaboradores' :icon='mdiAccountSupervisor'></tituloPage>
                </div>
                <div class="col-sm-6 divDontPrint">
                    <botoesEIN
                        :loading="loadingExporta"
                        tipoNovo="button"
                        linkNew='cadastrarColaboradores'
                        @exportarCadastro='exportarColboradores'
                        :disabledDropdown='$v.dataTable.$invalid'>
                    </botoesEIN>
                </div>
                <div class="col-sm-12"><hr></div>
                <div class="col-sm-5 nopadding divDontPrint">
                   <selectAll
                        :selected="selectedEmpresa"
                        nameForRadio='FiltroEmpresas'
                        :labels="labels"
                        :isMultiple='true'
                        :optionsArray='optSelectEmpresas'
                        :hasSelectAll='true'
                        @changeSelect='selecionaEmpresa'/>
                </div>
                <div class="col-sm-7 row nopadding divBotoes divDontPrint">
                    <buttonsFilters
                        label="Status" 
                        :onlyOneSelected='true'
                        :arrayButtons='filtroStatus'
                        @buttonsFiltersChange='mudaFiltroStatus'>
                    </buttonsFilters>
                </div>
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <tableListagem
            :loading='loadingTableListagem'
            :titles='titulosTable'
            :data='dataTable'
        >
            <template #acoes='row'>
                <!-- <simpleButton
                    type='blue'
                    width='30%'
                    title='Criar acesso aplicativo'
                    :icon='mdiCellphoneKey'
                    event='click'
                    :disabled="row.row.usacodigo != null ? true : false"
                    v-if='$store.getters.getCadastrar'
                    @click="acessoAplicativo(row.row)"
                /> -->
                <simpleButton
                    type='green'
                    width='30%'
                    title='Editar linha'
                    :icon='mdiPencil'
                    event='click'
                    v-if='$store.getters.getEditar'
                    @click='editarColaborador(row.row.codigo)'
                />
                <simpleButton
                    width='30%'
                    :title="row.row.status == 'A' ? 'Desativar' : 'Ativar'"
                    :type="row.row.status == 'A' ? 'red' : 'green'"
                    :icon="row.row.status == 'A' ? mdiCircleOffOutline : mdiCheckBold"
                    event='click'
                    v-if='$store.getters.getEditar'
                    @click='alterarStatus(row.row.codigo)'
                />
            </template>
        </tableListagem>
    </panelEagle>
</template>

<script>
import {mdiAccountSupervisor, mdiCheckBold,
	mdiPencil, mdiCellphoneKey, mdiCircleOffOutline } from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import Vue from 'vue'
export default Vue.extend({
	name: 'ListarColaborador',
	validations:{
		dataTable: { required },
	},
	components:{
		'panelEagle'     : require('@/components/Atom/Panel/PanelEagle').default,
		'tituloPage'     : require('@/components/Atom/Header/Titulo').default,
		'botoesEIN'      : require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
		'selectAll'      : require('@/components/Atom/Select/SelectAll').default,
		'buttonsFilters' : require('@/components/Atom/Buttons/ButtonsFilters').default,
		'tableListagem'  : require('@/components/Atom/Table/TableListagem').default,
		'simpleButton'   : require('@/components/Atom/Buttons/SimpleButton').default,
	},
	data(){
		return{
			firstOne:[],
			loadingExporta:[false, false, false],
			mdiAccountSupervisor:mdiAccountSupervisor,
			mdiCheckBold        : mdiCheckBold,
			mdiPencil : mdiPencil,
			mdiCellphoneKey : mdiCellphoneKey,
			mdiCircleOffOutline: mdiCircleOffOutline,
			loadingPanel:false,
			loadingTableListagem:false,
			url : '/checkpoint/cadastros/colaboradores/',

			// value
			valueFiltroListar:[],
			valueFiltroStatus:[],
			valueEmpresasSelecionadas:[],
			// opt
			optSelectEmpresas: [],
			selectedEmpresa:[],
			// Botões/titulos
			filtroStatus:[
				{'value':'A',  'width': '31.8%', 'text': 'Ativos',   'isSelected':true,  icon:mdiCheckBold},
				{'value':'I',  'width': '31.8%', 'text': 'Inativos', 'isSelected':false, 'icon':''},
				{'value':'T',  'width': '31.8%', 'text': 'Todos',    'isSelected':false, 'icon':''},
			],
			titulosTable:[
				{'name' : 'Nome',         'number' : 'mtnome'  },
				{'name' : 'Cargo',        'number' : 'ccdescricao'},
				{'name' : 'Empresa',      'number' : 'clfantasia'},
				// {'name' : 'Código de acesso',      'number' : 'usacodigo'},
			],
			labels:[
				{indexDFH     : 'EM', 
					description  : 'Empresas'}
			],
			// links
			linkExportColab   :'/administrativo/cadastros/colaboradores/exportar',
			linkListarColab   :'/administrativo/cadastros/colaboradores/listar',
			linkAlterarStatus :'/administrativo/cadastros/colaboradores/mudarStatus',
			// stuff
			dataTable:[],
		}
	},
	methods:{

		selecionaEmpresa(value){
			this.valueEmpresasSelecionadas = value;
			this.dataTable = []
			this.ListarColaborador();
		},

		editarColaborador(value){
			this.$router.push({name:'cadastrarColaboradores', params:{id:value}})
		},

		/**
         *  @description Método pegar o último valor do filtro e 
         *      também alterar qual esta selecionado no momento
         *  @param {array} value - valor do filtro selecionado
         *  @author Vitor Hugo 🐨
         */
		mudaFiltroStatus(value){
			if(value[0] == 'T'){
				this.valueFiltroStatus = ['A', 'I'];
			}else{
				this.valueFiltroStatus = value;
			}
			this.filtroStatus[0].icon = ''
			this.filtroStatus[1].icon = ''
			this.filtroStatus[2].icon = ''
			if(value[0] == 'A'){
				this.filtroStatus[0].icon = mdiCheckBold
			} else if(value[0] == 'I'){
				this.filtroStatus[1].icon = mdiCheckBold
			} else if(value[0] == 'T'){
				this.filtroStatus[2].icon = mdiCheckBold
			}
			this.ListarColaborador()
		},

		/**
         *  @description Método para buscar dados para listagem de colaboradores
         *      também cria o objeto a ser levado ao back
         *  @author Vitor Hugo 🐨
         */
		ListarColaborador(){
			if(this.valueEmpresasSelecionadas.length > 0){
				this.loadingPanel = true
				var obj = {
					'empresas': this.valueEmpresasSelecionadas,
					'status'  : this.valueFiltroStatus,
				}; 
				new HttpRequest().Post(this.url+'listar', obj)
					.then(dados=>{
						this.dataTable =  dados.data.colaboradores
						this.loadingPanel = false
					})
			}
		},

		exportarColboradores(value){
			switch (value) {
				case 'pdf': this.loadingExporta = [true, false, false]; break;
				case 'xls': this.loadingExporta = [false, true, false]; break;
				default: this.loadingExporta = [false, false, true]; break;
			}
			var root =  process.env.VUE_APP_ROOT;
			var obj = {
				tipo:value,
				colaboradores:this.dataTable
			}
			new HttpRequest().Post(this.url+'exportar', obj)
				.then(dados=>{
					window.open(root+'/'+dados.data.local); 
					this.loadingExporta = [false, false, false]
				})
		},

		/**
         *  @description Método para alterar o status dos cargos
         *  @param  value - Código do cargo
         *  @author Vitor Hugo 🐨
         */
		async alterarStatus(value){
			this.loadingPanel = true
			var obj = {codigo:value};
			new HttpRequest().Post(this.url+'alterar/status', obj)
				.then(dados=>{
					this.ListarColaborador();
					this.loadingPanel = false
				})
		},

		/**
         * @description Método salvar um código de acesso e 
         *     enviar email para o usuário usar o app do checkpoint
         * @param  {array} dados - Dados do colaborador
         * @author Vitor Hugo 🐨
         * @deprecated
         */
		acessoAplicativo(dados){
			this.loadingPanel = true
			new HttpRequest().Post(this.url+'acesso/app', dados)
				.then(dados=>{
					if(dados.code == 200){
						this.toastShow(dados.data.dados, 'warning')
					} else if (dados.code == 201){
						this.toastShow(dados.data.dados, 'success')
						this.ListarColaborador();
					} else {
						this.toastShow('Erro', 'danger')
					}
					this.loadingPanel = false
				})
		},

		toastShow(msg, type, title = ''){
			this.$bvToast.toast(`${msg}`, {
				autoHideDelay: 3000,
				variant: type,
				title: title
			})
		},

		/**
         *  @description Método para quando for um usuário comum vir todas as empresas selecionadas
         *  @author Vitor Hugo 🐨
         */
		selectAllClientes(){
			this.optSelectEmpresas =  new EmpresasService().Get()
			if(!this.getMaster()){
				this.selectedEmpresa   = this.optSelectEmpresas
			}
		},

		...mapGetters(['getMaster', 'getEditar', 'getCadastrar']),
	},
	mounted(){
		this.selectAllClientes()
	}
})
</script>

<style lang="scss" scoped>
.divBotoes{

    text-align: left;
}

@media print{
    .divDontPrint{
        display: none;
    }
}
</style>