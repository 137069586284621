/**
    Este é o componente de botões para filtros

    -> Propriedades:
        1- label - String - Legenda para o conjunto de botões - EX: 'Status'
        2- arrayButtons - Array - Conjunto de dados para compor os botões - EX: [{'value': VALOR DO BOTÃO, 'width': LARGURA DO BOTÃO EM %, 'isSelected': ESTÁ SELECIONADO(BOOLEAN), 'extraClass'?: CLASSE EXTRA, 'title'?: TITULO DO BOTÃO, 'text'?: TEXTO DO BOTÃO, 'icon'?: ICONE DO BOTÃO}]
        3- onlyOneSelected - Boolean - Será apenas 1 selecionado - EX: true/false
        4- zeroValue - [String, Number] - Caso seja necessário ser possível deselecionar os filtros
            |->     Caso você passar algo na prop, o componente passa a verificar se o usuário
            |   esta clicando em um botão que já esteja selecionado e ai "desmarca" o mesmo. 
            |->     O valor passado nessa prop também sera o emitido no evento caso os filtros sejam desmarcado

    -> Slot:
        - Sem slots

    -> Eventos:
        1- buttonsFiltersChange - Emite quando os botões do conjunto de filtro 
            1.1- valueButtons - Conjunto em array dos values dos botões selecionados
 */

<template>
    <div class="col-sm-12">
        <div class="col-sm-12 nopadding subtitle">
            {{label}}
        </div>
        <simpleButton 
            v-for="(button) in dadosButtons" 
            @click="clickButton" 
            v-bind:key="button.value" 
            event="click" 
            :width="button.width" 
            :type="(button.isSelected ? 'blue': 'white')" 
            :extraClass="button.extraClass+' buttonFilter'" 
            :value="button.value" 
            :title="button.title" 
            :text="button.text" 
            :icon="button.icon"
        />
    </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
	components:{
		'simpleButton':require('./SimpleButton').default,
	},
	props:{
		label:{
			type: String,
			required: false,
			default: '',
		},
		arrayButtons:{
			type: Array,
			required: true,
			default: function(){return []}
		},
		onlyOneSelected:{
			type: Boolean,
			default: true
		},
		zeroValue:{
			type: [String, Number, Array],
			required: false
		}
	},
	data(){
		return{
			dadosButtons:this.arrayButtons,
			valueButtons: [],
		}
	},
	methods:{
		clickButton(event = 1, value = 1){
			var buttons= this.arrayButtons;
			if(value == this.valueButtons && this.zeroValue){
				value = Number(this.zeroValue)
			}
			this.valueButtons = [];
			for(var a in this.arrayButtons){
				if(this.onlyOneSelected){
					buttons[a].isSelected = false;
				}
				if(value == buttons[a].value){
					buttons[a].isSelected = !buttons[a].isSelected ;
				}
			}
			this.dadosButtons = buttons;
			var valueButtons= [];
			for(var b in buttons){
				if(buttons[b].isSelected){
					valueButtons.push(buttons[b].value)
				}
			}
			this.valueButtons = valueButtons;
			this.changeButtonsFilters();
		},
		changeButtonsFilters(){
			var emitedValue = this.valueButtons
			if(this.valueButtons.length == 0)
				emitedValue = this.zeroValue
			this.$emit('buttonsFiltersChange', emitedValue);
		}
	},
	watch:{
		arrayButtons: function(){
			this.clickButton()
		},
		
		onlyOneSelected(){
			this.arrayButtons.forEach((btn) => {
				btn.isSelected = false
			});
			this.valueButtons = []
		}
	},
	mounted(){
		var buttons= this.arrayButtons;
		var valueButtons= [];
		for(var b in buttons){
			if(buttons[b].isSelected){
				valueButtons.push(buttons[b].value)
			}
		}
		this.valueButtons = valueButtons;
		this.changeButtonsFilters();
	}
})
</script>

<style lang="scss">
    .subtitle{
        font-size: 13px;
        text-align: left;
        margin-bottom: 2px;
        font-family: nexabook, sans-serif !important;
    }
    .blue {
        background: rgba(0, 0, 255, 0.589);
    }
    .bt-eagle{
        margin: 2px;
    }
    .botaoLoco{
        background-color: rgba(0, 162, 255, 0.589) !important;
    }
</style>
