import { render, staticRenderFns } from "./ModalSolicitaManutencaoVeiculos.vue?vue&type=template&id=6bd0003d&scoped=true"
import script from "./ModalSolicitaManutencaoVeiculos.vue?vue&type=script&lang=js"
export * from "./ModalSolicitaManutencaoVeiculos.vue?vue&type=script&lang=js"
import style0 from "./ModalSolicitaManutencaoVeiculos.vue?vue&type=style&index=0&id=6bd0003d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bd0003d",
  null
  
)

export default component.exports