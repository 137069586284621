import FacilitadorNovaRegiao from "@/components/Atom/Painel/FacilitadorNovaRegiao.vue";
import HistoricoDePosicoes from "@/components/Atom/Painel/HistoricoPosicoes.vue";
import VisualizarViagens from "@/components/Atom/Painel/Viagens.vue";
import eaglePanel from "@/components/Atom/Panel/PanelEagle.vue";
import mapaSimples from "@/components/Atom/Mapa/MapaSimples.vue";
import painelMapa from "@/components/Atom/Painel/PainelMapa.vue";
import Cluster from "@/components/Atom/Mapa/MarkerCluster.vue";
import menuMapa from "@/components/Atom/Painel/MenuMapa.vue";
import MarkerPoint from "@/components/Atom/Mapa/MarkerPoint.vue";
import infoVeiculo from "@/components/Atom/Painel/InfoVeiculo.vue";
import freeDraw from "@/components/Atom/Painel/FreeDrawMapa.vue";
import ModalHistPosicaoRegiao from "@/components/Atom/Painel/ModalHistPosicaoRegiao.vue";
import PopoverMarcador from "@/components/Atom/Painel/PopoverMarcador.vue";
import PopoverAlertas from "@/components/Atom/Painel/PopoverAlertas.vue";
import { mdiMapSearchOutline, mdiCloseThick, mdiCheckBold } from "@mdi/js";
import BaseIcon from "@/components/Atom/Icon/BaseIcon.vue";
import ClusterVeiculos from "@/components/Atom/Mapa/ClusterVeiculos.vue";
import graficoViagem from "@/components/Atom/Grafico/ChartsAreaMapa.vue";

const components = <object>{
    FacilitadorNovaRegiao,
    HistoricoDePosicoes,
    ModalHistPosicaoRegiao,
    freeDraw,
    menuMapa,
    infoVeiculo,
    MarkerPoint,
    Cluster,
    eaglePanel,
    mapaSimples,
    painelMapa,
    BaseIcon,
    PopoverMarcador,
    PopoverAlertas,
    ClusterVeiculos,
    VisualizarViagens,
    graficoViagem
};

const data = <object>{
    iconMapSearch: mdiMapSearchOutline,
    iconClose: mdiCloseThick,
    iconCheck: mdiCheckBold,
    baseUri: "/finder/mapa/",
    optClientes: [],
    queryRegiaoUser: "",
    legendaGrupoVeiculos: [],
    rastros: [],
    legendaRastros: [],
    //marcadores de veículo que vem do painel de controle
    markerVeiculos: [],
    filtroVeiculos: [],
    //pontos do usuário (C|P|R)
    markerPontos: [],
    markerBolinhas: [],
    markerAlertas: [],
    clienteSelecionado: [],
    optVeiculos: [],
    ocultaLegenda: true,
    mostraControleFree: true,
    veiculoSelecionado: false,
    seguindoVeiculo: false,
    listarPosicoes: false,
    visualizarViagens: false,
    searchRegiao: false,
    comparandoRastro: false,
    /**Controle sobre o que o usuário ta fazendo (controle de estado) */
    atividade: "",
    regioesBusca: [],
    regioesBuscaCliente: [],
    pontosBuscaCliente: [],
    placaVeiculoSelecionado: "",
    /**Variáveis que contem as coisas desenhadas no mapa */
    regsOnMap: [],
    pontosOnMapa: [],
    polisOnMapa: [],
    raiosOnMapa: [],
    markerVelocidadeOnMapa: [],
    markerParadaOnMapa: [],
    todasParadas: [],
    markerComparacao: [],
    markerVelocidadeOnMapaId: [],
    coordenadasFree: [],
    veiculoRastro: {},
    marcadorSelecionado: {},
    veiculoComparacao: '',
    tiposMarcadoresOnMapa: [],
    regiaoEditavel: [],
    rotasOnMap: [],
    veiculosRota: [],
    //permissoes do usuário
    userPermission: {},
    latlngUSer: [],
    controleVerRegioes: false,
    tickerTimerZoom: null,
    construirAlertasVeiculos: false,
    //Variaveis grafico e viagens
    rastroViagem: [],
    dadosGrafico: {},
    dadosPopulaGraficoVelocidade: [],
    grafico: false,
    infoVeiculoExibir: false,
    arrayPosicoes: [],
    paradasViagem: [],
    latLngCliqueMapa: {},
    pontoInicial: {},
    pontoFinal: {},
    voltarViagem: false,
    criarTrajetos: false,
    painelViagem: true,
    exibeAlertasRotasLidos: false,
    alertasRotas: [],
    mapaDeMarcadores: {},
    chamaRequisicaoRota: false,
    mapaRequisicaoViagem: {
        fechaViagem: false,
      },
};

export { components, data };
