export var titulosEhBotoes = {
    labelSelectVeiculos: [
        {'indexDFH':'V', 'description':'Veiculos*'},
        {'indexDFH':'GV', 'description':'Grupos*'},
    ],
    btsFiltroTempo : [
        {'value':'1',  'isSelected':false, 'width':'30%', 'text':'1 min'},
        {'value':'5',  'isSelected':false, 'width':'30%', 'text':'5 min'},
        {'value':'10', 'isSelected':false, 'width':'30%', 'text':'10 min'},
        {'value':'15', 'isSelected':false, 'width':'30%', 'text':'15 min'},
        {'value':'30', 'isSelected':false, 'width':'30%', 'text':'30 min'},
        {'value':'60', 'isSelected':false, 'width':'30%', 'text':'60 min'},
    ],
    
}