import L from "leaflet";

/**
 * Uma espécie de facilitador, possue todos os métodos do L.Marker
 * e com o tempo estou adicionando novos métodos para o uso da Eagle.
 */
export class MarkerVeiculo extends L.Marker {
    /**
     * @description A ideia dessa classe é que só passando as informações do
     * ponto, a classe já devolva um marcador completo, com ícone correto
     * e todas as opções padronizadas, com os métodos da Eagle e pã.
     * @constructs marker de ponto do usuário.
     * @augments L.Marker - marcador do leaflet.
     * @class EagleMarker.
     * @param {Object} info.
     * @throws não possuei uma das coordenadas.
     * @author Gui 🐙🍷🍺.
     */
    constructor(info, icon = "") {
        if (!info?.latitude || !info?.longitude) {
            throw Error("Ponto não possue uma ou as duas coordenadas");
        }
        /**
         * Construtor da classe L.Marker, mandando as informações do ponto
         * E já com o ícone correto selecionado
         */
        super([info.latitude, info.longitude], {
            title: info.descricao,
            icon: criaDivIcon(icon ? icon : info.icon),
            alt: info.descricao,
            riseOnHover: true,
        });
        this.id = info.veiculoId;
        this.info = info;
        // let tooltip = this.criaTooltip(infoPonto);
        // this.bindTooltip(tooltip);
    }

    /**
     * @description altera o ícone do ponto baseado nas informações do mesmo
     */
    atualizaIcone(veiculo) {
        this.info = veiculo;
        this.setIcon(criaDivIcon(veiculo.icon));
        this.setLatLng([veiculo.latitude, veiculo.longitude]);
    }

    // /**
    //  * @param {object} ponto
    //  * @param {string} ponto.podescricao
    //  * @description cria a tooltip, que substitui o title
    //  * do ponto, pois é mais fácil controlar.
    //  * @return {L.tooltip} com a descrição do ponto
    //  * @author Gui 🍺
    //  */
    // criaTooltip(ponto) {
    //     /**Add a positive x offset to move the tooltip to the right,
    //      * and a positive y offset to move it to the bottom.
    //      * Negatives will move to the left and top.
    //      */
    //     return L.tooltip({
    //         offset: [10, -20],
    //         className: "tooltipMapa",
    //         content: `
    //             <b-popover
    //                 v-for="${(veiculo, i) in markerVeiculos}"
    //                 :key="${veiculo.veiculoId+"_popover_status"+i}"
    //                 :target="status_alertas_${veiculo.veiculoId}"
    //                 triggers="hover focus">
    //                 <div
    //                     :key="${veiculo.veiculoId}___${alerta["tacor"]}"
    //                     v-for="alerta in veiculo.alertas">
    //                     ${'formataAlerta(alerta)'}
    //                 </div>
    //                 <div v-if="${!veiculo.alertas || !veiculo.alertas.length}">
    //                     Veículo não possui alertas
    //                 </div>
    //                 <div class="d-flex justify-content-center"
    //                     v-if="${veiculo.alertas && veiculo.alertas.length > 0}">
    //                     <b-button
    //                         squred
    //                         style="background-color:#007bff; color:white;"
    //                         @click="irParaAlertas(veiculo)">
    //                         Verificar
    //                     </b-button>
    //                 </div>
    //             </b-popover>
    //         `
    //     });
    // }
    
    // /**
    //  * @description altera o ícone do ponto baseado nas informações do mesmo
    //  */
    // atualizaIcone(veiculo) {
    //     this.setIcon(criaDivIcon(veiculo.icon));
    //     this.info = veiculo;
    //     this.id = veiculo.veiculoId;
    // }

    // abrirPopup() {
    //     if (!this.isPopupOpen()) this.openPopup();
    // }
}

/**
 * @description cria o marcador do ponto, do tipo div.
 * @returns {L.DivIcon} ícone do ponto.
 */
function criaDivIcon(icon) {
    return L.divIcon({
        className: "divIcon",
        html: icon,
        iconSize: [80, 80],
        iconAnchor: [40, 40],
        shadowAnchor: [0, 0],
    });
}
