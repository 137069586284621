<template>
    <panelEagle id='veiculosCliente' :loading='gerandoRel'>
        <div class="col-sm-12 nopadding">
            <div class="col-sm-12 nopadding row">
            <div class="col-sm-6">
                <tituloPage 
                :icon="mdiCar2Plus" 
                titulo="Veículos por cliente"/>
            </div>
            <div class="col-sm-6 divButtonsBasic divDontPrint">
                <basicButtonsRelatoriosEIG
                    :loading="loadingButton"
                    :disabled='$v.empresasArray.$invalid'
                    :disabledDropdown='$v.dataRelatorio.$invalid'
                    @exportarRelatorio="exportarRelatorio" 
                    @gerarRelatorio="gerarRelatorio"/>
            </div>
        </div>
        <slideUpAndDown>
            <slot slot="filters">
                <div class="col-sm-12"><hr></div>
                <div class="col-sm-12 nopadding row">
                    <div class="col-sm-4 nopadding">
                        <selectAll 
                        :isMultiple="true" 
                        nameForRadio="radiosEmpresas" 
                        :labels="labelsSelectEmpresa" 
                        :optionsArray="optionsSelectEmpresa" 
                        firstSelected="E" 
                        :hasSelectAll="true"
                        :extraClass='{"border border-danger":$v.empresasArray.$anyError}'
                        @changeSelect="SelectEmpresas" 
                        @close='$v.empresasArray.$touch()'/>
                    </div>
                    <div class="col-sm-5 nopadding">
                        <buttonsFilters 
                            :arrayButtons="arrayButtons" 
                            label="Status" 
                            @buttonsFiltersChange="changeButtonsFiltros"/>
                    </div>
                </div>
            </slot>
        </slideUpAndDown>
        </div>
        <div class="col-sm-12 divHrDeBaixo"><hr></div>
        <div class="col-sm-12">
            <tableRelatorio >
                <slot>
                    <br>
                    <thead>
                        <th/>
                        <th>Empresa</th>
                        <th>Quantidade</th>
                        <th>Mensalidade</th>
                    </thead>
                    <template v-if="exibirRelatorio">
                        <template v-for='(cliente, index) in dataRelatorio'>
                            <tr :key='index+"__cliente"'>
                                <td>
                                    <span 
                                    event='click'
                                    @click='mudaMapa(index)'
                                    v-b-toggle='decidirIdCollapse(cliente)'>
                                        <b-icon class="pose" :icon="arrChevron.get(index) ? 'chevron-down' : 'chevron-right'"/>
                                    </span>
                                </td>
                                <td v-text="index"/>
                                <td v-text='cliente.length+" veículos"'/>
                                <td v-text="'Mensalidade'" />
                            </tr>
                            <tr :key='index+"__informacoes"'>
                                <td colspan="4" class="nopadding">
                                    <b-collapse
                                        :ref='decidirIdCollapse(cliente)'
                                        menu-class='collapse_table'
                                        :key='cliente.length+"collapse"'
                                        :id='decidirIdCollapse(cliente)'
                                    >
                                        <table style="width:100%"
                                            class="collapseTable">
                                            <thead>
                                                <tr>
                                                    <th>Placa</th>
                                                    <th>Módulo</th>
                                                    <th>Modelo</th>
                                                    <th>Chip</th>
                                                    <th>Data de Instalação</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr 
                                                v-for="(veiculo, i) in cliente"
                                                :key="index+'_'+i"
                                                >
                                                    <td v-text='veiculo.veplaca'/>
                                                    <td v-text='veiculo.vemodulo' />
                                                    <td v-text='veiculo.mmdescricao' />
                                                    <td v-text='veiculo.chnumero' />
                                                    <td v-text='dataInstalacao(veiculo.vedatainstalacao)' />
                                                    <td v-text='defineStatus(veiculo.vestatus)' />
                                                </tr>
                                            </tbody>
                                        </table>
                                    </b-collapse>
                                </td>
                            </tr>
                        </template>
                    </template>
                    <template v-else>
                        <status
                            colspanForTd='4'
                            :statusBar='statusRel'
                        />
                    </template>
                </slot>
            </tableRelatorio>
        </div>
    </panelEagle>
</template>

<script >
import Vue from 'vue'
import {mdiCheckBold, mdiCar2Plus } from '@mdi/js'
import {DateTime} from 'luxon'
// import  {mdiCar2Plus } from '@mdi/js'
import {EmpresasService} from '../../../Services/auth/Empresas.service'
import {HttpRequest} from '../../../Services/auth/HttpRequest.Service'
import { required } from 'vuelidate/lib/validators'
export default Vue.extend({
	name:'veiculosPorCliente',
	components:{
		'panelEagle'               : require('@/components/Atom/Panel/PanelEagle').default,
		'tituloPage'               : require('@/components/Atom/Header/Titulo').default,
		'basicButtonsRelatoriosEIG': require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
		'slideUpAndDown'           : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
		'selectAll'                : require('@/components/Atom/Select/SelectAll').default,
		'buttonsFilters'           : require('@/components/Atom/Buttons/ButtonsFilters').default,
		'tableRelatorio'           : require('@/components/Atom/Table/TableSimples').default,
		'status'                   : require('@/components/Atom/StatusInformation/StatusInformation').default,
	},
	validations:{
		empresasArray:{ required },
		dataRelatorio: { required}
	},
	data(){
		return{
			loadingButton:[false, false, false],
			mdiCar2Plus:mdiCar2Plus,
			labelsSelectEmpresa:[
				{indexDFH: 'E', description: 'Empresa*'},
			],
			optionsSelectEmpresa: new EmpresasService().Get(),
			empresasArray:[],
			statusArray:[],
			arrayButtons:[
				{'value':'A', 'width': '32%', 'icon': '', 'extraClass': 'buttonFilterVeicPorCli', 'isSelected':false, 'text': 'Ativos', 'title':'Ativos'},
				{'value':'I', 'width': '32%', 'icon': '', 'extraClass': 'buttonFilterVeicPorCli', 'isSelected':false, 'text': 'Inativos', 'title':'Inativos'},
				{'value':'T', 'width': '32%', 'icon': mdiCheckBold, 'extraClass': 'buttonFilterVeicPorCli', 'isSelected':true , 'text': 'Todos', 'title':'Todos'},
			],
			statusRel: 'info',
			urlBase: '/administrativo/relatorios/veiculo/cliente/',
			dataRelatorio: [],
			gerandoRel:false,
			arrChevron: new Map()
		}
	},
	methods:{
		/**
         * Só pra trocar a letra representando o status
         * pela palavra que ela representa
         * @param String status ('I' ou 'A')
         * @return String ('Inativo' ou 'Ativo')
         * @author Gui 🍺
         */
		defineStatus(status){
			if(status == 'I')
				return 'Inativo'
			else 
				return 'Ativo'
		},

		/**
         * Serve para mudar o formato de data (iso) que vem
         * do banco de dados para um formato local
         * @param data no formato ISO
         * @return String ou vazia ou no novo formato
         */
		dataInstalacao(data){
			if(data){
				return DateTime.fromISO(data).toLocaleString()
			}else
				return ''
		},

		/**
         * Decide o ID do callapse, para garamtir
         * unicidade do ID, estou usando o 
         * código do cliente 
         * @param Array Cliente
         * @return String collapse_'clcodigo'
         */
		decidirIdCollapse(cliente){
			return 'collapse_'+cliente[0].clcodigo
		},

		SelectEmpresas(event){
			this.dataRelatorio = []
			this.empresasArray = event;
		},

		changeButtonsFiltros(event){
			switch (event[0]){
				case 'A':
					this.arrayButtons[0].icon = mdiCheckBold
					this.arrayButtons[1].icon = ''
					this.arrayButtons[2].icon = ''
					break;
				case 'I':
					this.arrayButtons[0].icon = ''
					this.arrayButtons[1].icon = mdiCheckBold
					this.arrayButtons[2].icon = ''
					break;
				case 'T':
					this.arrayButtons[0].icon = ''
					this.arrayButtons[1].icon = ''
					this.arrayButtons[2].icon = mdiCheckBold
					break;
				default:
					break;
			}
			this.statusArray = event;
			this.arrayButtons[0].icon = ''
			this.arrayButtons[1].icon = ''
			this.arrayButtons[2].icon = ''
			if(event == 'A'){
				this.arrayButtons[0].icon = mdiCheckBold
			} else if(event == 'I'){
				this.arrayButtons[1].icon = mdiCheckBold
			} else if(event == 'T'){
				this.arrayButtons[2].icon = mdiCheckBold
			}
		},

		/**
         * Essa função é a responsabel por mapear os 
         * estados de todos os chevrons na tabela.
         * Alterando quando eles fecham ou abrem
         * @param index do mapa ArrChevron para
         * mudar o estado do chevron
         */
		mudaMapa(index){
			let v =  !this.arrChevron.get(index)
			this.arrChevron.set(index, v)
			this.$forceUpdate()
		},

		/**
         * Quando as informações do back chegam até aqui
         * essa função cria o mapa dos novos chevrons
         */
		criaMapaChevron(arr){
			for(var i in arr)
				this.arrChevron.set(i, false)
		},

		/**
         * Faz a busca pelas informações do relatório
         */
		gerarRelatorio(){
			this.dataRelatorio = []
			this.gerandoRel = true
			this.statusRel='info'
			let uri = this.urlBase+'gerar'
			let obj = {cliente:this.empresasArray, statusCarro:this.statusArray[0]}
			new HttpRequest().Post(uri, obj)
				.then((data)=>{
					this.criaMapaChevron(data.data)
					this.dataRelatorio = data.data
					this.gerandoRel = false
					if(!this.exibirRelatorio)
						this.statusRel='error'
				})
		},

		/**
         * Manda as informações para exportação
         * @param tipo ('pdf', 'xls', 'csv')
         */
		exportarRelatorio(tipo){
			switch (tipo) {
				case 'pdf':
					this.loadingButton = [true, false, false]
					break;
				case 'xls':
					this.loadingButton = [false, true, false]
					break;
				default:
					this.loadingButton = [false, false, true]
					break;
			}
			var root =  process.env.VUE_APP_ROOT;
			let uri = this.urlBase+'exportar'                
			let obj = {data:this.dataRelatorio, para:tipo}
			if(this.exibirRelatorio){
				new HttpRequest()
					.Post(uri, obj)
					.then((data)=>{
						this.loadingButton = [false, false, false]
						open(root+'/'+data.data.local);
					})
			}else{
				this.loadingButton = [false, false, false]
			}
		}
	},
	computed: {
		/**
         * Verificação simples da quantidade de chaves que 
         * o relatório possui, transformando esse número em um
         * boolean, caso não acha chaves, o relatório esta vazio
         */
		exibirRelatorio() {
			return !!Object.keys(this.dataRelatorio).length
		}
	},
})
</script>

<style lang="scss" scoped>
    .buttonFilterVeicPorCli{
        &.bt-white{
            span{
                display: none;   
            }
        }
    }
    .backgroundTableTr {
        background-color: #ccc;
        font-weight: bolder;
    }
    .backgroundTable {
        background-color: #efefef;
        font-weight: bolder;
    }
    // .flaticon-icon171::before{
    //     font-size: 15px !important;
    //     padding: 0 !important;
    //     margin: 0 !important;
    // }
    th{
        padding-left: 1% !important;
        text-align: left !important;
    }
    td{
        padding-left: 1% !important;
        text-align: left;
    }
    .collapseTable{
        td{
            width: 16.66% !important;
            background: #fff;
        }
    }
</style>