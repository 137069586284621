<template>
    <modalEagle 
        @modalOpen="modalAberto"
        title="Download da imagem" 
        id="modalBaixarImagemChecklist">
        <slot slot="modalBody">
            <div id="div-modalBaixarImagemChecklist" class="col-sm-12 row nopadding">
                <b-overlay :show='loadingImg' :opacity='1' style="width:100%">
                    <div class="col-sm-12 nopadding d-flex justify-content-center mb-3">
                        <simpleButton
                            :disabled="!strImg.length"
                            title="Fazer download da imagem"
                            sizeIcon="30"
                            width="100%"
                            :icon="mdiFileDownload"
                            @click="baixaImg"
                            event="click"
                            type="blue"
                        />
                    </div>
                    <div v-if="strImg" class="col-sm-12 d-flex justify-content-center div-img">
                        <div class="cl-sm-12 nopadding">
                            <img id="imagem-baixar" :src="getImg(strImg)" height="700px">
                        </div>
                    </div>
                    <div v-else>
                        <div class="col-sm-12 nopadding">
                            <div class="div-sem-img col-sm-12 d-flex justify-content-center align-items-center">
                                <span style="font-size:20px">
                                    Não foi encontrada nenhuma imagem em nosso banco de dados
                                </span>
                            </div>
                        </div>
                    </div>
                </b-overlay>
            </div>
        </slot>
        <slot slot="modalFooter">
            <simpleButton 
                :icon="mdiCloseThick" 
                @click="cancelButton" 
                text="Fechar" 
                event="click"
                type="red"
            />
        </slot>
    </modalEagle>
</template>
<script lang="ts">
import Vue from 'vue'
import{mdiCloseThick, mdiFileDownload } from '@mdi/js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { conectionError } from '@/Services/Helpers/swellHeper.js'
export default Vue.extend({
    name:'modalBaixarImagemChecklist',
    components:{
        modalEagle: require('@/components/Atom/Modal/ModalEagle').default,
        simpleButton: require('@/components/Atom/Buttons/SimpleButton').default,
    },
    props: {
        codigo:{
            type: [String, Number],
            required: true,
        },
        placa:{
            type: String,
            required: true,
        },
        item:{
            type: String,
            required: true,
        },
        data:{
            type: String,
            required: true,
        },
    },
    data() {
        return {
            mdiFileDownload:mdiFileDownload,
            mdiCloseThick:mdiCloseThick,
            loadingImg:true,
            urlBase:'/manutencoes/relatorios/checklist',
            strImg:'',
        }
    },
    methods:{
        /** 
         * @listens modalOpen
         * @description faz a busta da img no banco, gera uma string dinamica para o elemento <img>
         * o atributo espera que venha em base64 e seja compativel com image (png, jpg, gif), n sei se funciona com pdf
         * @author Lucas Eduardo
         */
        async modalAberto(){
            this.loadingImg = true
            this.strImg = ''
            try{
                new HttpRequest().Post(`${this.urlBase}/pega/img`, this.codigo)
                    .then((dados)=>{
                        if(dados.status){
                            if(dados.data.arquivo){
                                this.strImg = `data:image/*;base64,${dados.data.arquivo}`
                            }else{
                                this.strImg = ""
                            }
                        }else{
                            conectionError()
                        }
                    }).finally(()=>{
                        this.loadingImg = false
                    })
            }catch(error){
                conectionError()
                this.loadingImg = false
            }
        },
        /** 
         * @listens simpleButton-Click
         * @description faz a ação de baixar a img, ele cria um elemento <a> novo, e força um click nele
         * @author Lucas Eduardo
         */
        baixaImg(){
            var a = document.createElement("a")
            a.href = this.strImg
            a.download = `${this.placa}_${this.data}_${this.item}.jpeg`
            a.click()
        },

        getImg(img){
            if(!img.length){
                return
            }
            return img
        },

        cancelButton(){
            this.$bvModal.hide('modalBaixarImagemChecklist')
        },
    }
})
</script>

<style lang="scss">
    #modalBaixarImagemChecklist{
        #div-modalBaixarImagemChecklist{
            min-height: 300px !important;
        }
        .div-sem-img{
            background-color: rgba(0, 0, 0, 0.185);
            height: 300px;
            font-family: nexaBold;
        }
        .div-img{
            max-height:600px;
            img{
                width: inherit;
                height: inherit;
                max-height:500px;
                object-fit: cover !important;
            }
        }
    }
</style>