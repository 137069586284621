<template>
    <panelEagle id='Modulos' :loading='gerandoRel'>
        <div class="col-sm-12 row nopadding">
            <div class="col-sm-6">
                <tituloPage :icon='mdiTools' titulo='Tipo de Serviço'></tituloPage>
            </div>
            <div class="col-sm-6 divDontPrint">
                <basicButtonsCadastrosEIN
                    tipoNovo='button'
                    linkNew='tipoServicoEditar'
                    :loading='exportando'
                    :disabledDropdown ='$v.dataTable.$invalid'
                    @exportarCadastro='exportarCadastro'/>
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <div class='col-sm-12 row nopadding'>
            <div class="col-sm-4 nopadding divDontPrint">
                <selectAll
                    nameForRadio  ='Selecione*'
                    :isMultiple   ='true'
                    :optionsArray ='optEmpresas'
                    :labels       ='labelsEmp'
                    :hasSelectAll ='true'
                    :selected     ="selectedEmpresa"
                    @changeSelect ='changeEmpresa'/>
            </div>
            <div class="col-sm-8 pad lado nopadding divDontPrint">
                <span class="marg">Status</span>
                <buttonsFilters
                    :arrayButtons='arrayButtonsFilters'
                    :onlyOneSelected='true'
                    @buttonsFiltersChange='buttonsFiltersChange'/>
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <div class="col-sm-12 nopadding">
            <tableListagem
                :titles='tituloTab'
                :data='dataTable'
                deleteEdit='editStatus'
                @editarCadastro='editarCadastro'
                @alterarStatus='alterarStatusCadastro'/>
        </div>
    </panelEagle>
</template>
<script>
import {mdiTools, mdiCheckBold } from '@mdi/js'
import Vue  from 'vue'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service.ts'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
export default Vue.extend({
    name:'ListagemTipoServico',
    components:{
        'panelEagle' : require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage' : require('@/components/Atom/Header/Titulo').default,
        'SelectAll'  : require('@/components/Atom/Select/SelectAll').default,
        'basicButtonsCadastrosEIN' : require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
        'buttonsFilters' : require('@/components/Atom/Buttons/ButtonsFilters').default,
        'tableListagem'  : require('@/components/Atom/Table/TableListagem').default,
    },

    validations:{
        dataTable   :{required},
    },

    data(){
        return {
            mdiCheckBold:mdiCheckBold,
            mdiTools:mdiTools,
            gerandoRel:false,
            optEmpresas : new EmpresasService().Get(),
            labelsEmp   : [{
                indexDFH    : 'EM',
                description : 'Empresas'
            }],
            tituloTab :[
                {'number':'descricao', 'name':'Descrição'},
                {'number':'cliente',   'name':'Empresa'},
            ],
            valueButtons : [],
            dataTable :[],
            arrayButtonsFilters:[
                {
                    'value': 'A',
                    'width': '32.7%', 
                    'isSelected': true, 
                    'title': 'Ativos', 
                    'text': 'Ativos',
                    'icon': '',
                },
                {
                    'value': 'I',
                    'width': '32.7%', 
                    'isSelected': false, 
                    'title': 'Inativo', 
                    'text': 'Inativo',
                    'icon': '',
                },
                {
                    'value': 'T',
                    'width': '32.7%', 
                    'isSelected': false, 
                    'title': 'Todos', 
                    'text': 'Todos',
                    'icon': '',
                },
            ],
            exportando:[false, false, false],
            selectedEmpresa: [],
        }
    },
    methods:{
        ...mapGetters(['getMaster']),
        exportarCadastro(para){
            let uri = '/manutencoes/cadastros/tipo/servico/exportar'
            let obj = {
                para:para,
                dados:this.dataTable
            }
            var root =  process.env.VUE_APP_ROOT;
            if(this.dataTable.length){
                this.mudaExportando(para)
                new HttpRequest().Post(uri, obj).then(data=>{
                    open(root+'/'+data.data.local);
                    this.mudaExportando()
                })
            }
        },
        mudaExportando(para='stop'){
            if(para=='pdf')
                this.exportando = [true, false, false]
            else if(para == 'xls')
                this.exportando = [false, true, false]
            else if(para == 'csv')
                this.exportando = [false, false, true]
            else 
                this.exportando = [false, false, false]
        },
        editarCadastro(value){
            this.$router.push({name:'tipoServicoEditar', params:{id:value}})
        },

        async changeEmpresa(value){
            if(value != null){
                this.valueEmpresa = value
            }else{
                this.valueEmpresa = []
            }
            var tipo = []
            if(this.valueButtons == "T"){
                tipo = ["A", "I"]
            }else{
                tipo = this.valueButtons
            }
            var obj = [
                {'cliente':this.valueEmpresa},
                {'tipo':tipo}
            ]
            let uri = '/manutencoes/cadastros/tipo/servico/tabela'
            this.gerandoRel = true
            new HttpRequest().Post(uri, {obj:obj})
            .then((data)=>{
                this.dataTable = data.data
                this.gerandoRel = false
            })
        },
        buttonsFiltersChange(value){
            this.valueButtons = value
            this.arrayButtonsFilters[0].icon = ''
            this.arrayButtonsFilters[1].icon = ''
            this.arrayButtonsFilters[2].icon = ''
            if(value[0] == 'A'){
                this.arrayButtonsFilters[0].icon = mdiCheckBold
            } else if(value[0] == 'I'){
                this.arrayButtonsFilters[1].icon = mdiCheckBold
            } else if(value[0] == 'T'){
                this.arrayButtonsFilters[2].icon = mdiCheckBold
            }
            this.changeEmpresa(this.valueEmpresa)
        },
        async alterarStatusCadastro(value){
            var dados = await new HttpRequest()
                .Post('/manutencoes/cadastros/tipo/servico/status', {'obj':value})
            this.changeEmpresa(this.valueEmpresa)
        }
    },

    mounted(){
        if(!this.getMaster()){
            this.selectedEmpresa = this.optEmpresas
        }
    }
})
</script>
<style lang="scss">
    .marg{
        text-align: left !important;
        margin-right: 92%;
    }
    .pad{
        margin-bottom: 10px;
    }
</style>
