

export class BreadcrumbService{

    public vue!: Vue;
    constructor(vue: Vue) {

        this.vue = vue;
    }

    Routes(){
        return this.vue.$route.matched.slice()
    }

}