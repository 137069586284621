<template>
	<div>
		<div id='second-step-atestado'>
			<form class='form-atestado'>
				<div class='first-row'>
					<label for="nome_medico" class="unselectable">
						Nome completo do médico*:
					</label>
					<input
					id='nome_medico'
					type="text"
					v-model='valueNomeMedico'
					:disabled='existeAjuste'
					:class='{"border border-danger":$v.valueNomeMedico.$anyError}'
					@blur='$v.valueNomeMedico.$touch()'
					@input='mudaMedico'>
				</div>
				<div class="second-row">
					<div class='column'>
						<label for="input-crm" class="unselectable">CRM*</label>
						<input
						type='number'
						id='input-crm'
						:disabled='existeAjuste'
						v-model='valueCrm'
						:class='{"border border-danger":$v.valueCrm.$anyError}'
						@blur='$v.valueCrm.$touch()'
						@input='mudaCrm'>
					</div>
					<div class='column'>
						<label
						for="input-cid"
						class="unselectable label-cid">CID*</label>
						<input
						type='text'
						id='input-cid'
						:disabled='existeAjuste'
						v-model='valueCid'
						:class='{"border border-danger":$v.valueCid.$anyError}'
						@blur='$v.valueCid.$touch()'
						@input='mudaCid'>
					</div>
				</div>
			</form>
			<div class='campo-anexo'>
				<simple-button
				text='Adicionar anexo'
				:icon='iconPaper'
				width='200px'
				type='aqua-marine'
				event='click'
				:disabled='existeAjuste'
				class='btn-anexo'
				@click='onPickFile'/>
				<div class='filename'>
					{{imgValue}}
				</div>
				<div class='horarios'>
					<input-horario
					v-if='!jornadaCompleta'
					@intervalo='mudaIntervaloEvento'/>
				</div>
			</div>
		</div>
		<div class="mt-2">
			<label
			for="motivo_solicitacao">
				<span class='unselectable'>
					Motivo da Solicitação*:
				</span>
			</label>
			<textarea
			name='motivo-solicitacao'
			id='motivo_solicitacao'
			:disabled='existeAjuste'
			cols='49'
			v-model='motivoAjuste'
			rows='2'
			@keyup='mudaMotivo'/>
		</div>
	</div>
</template>

<script>
import { mdiPaperclip } from '@mdi/js'
import { required } from 'vuelidate/lib/validators'
export default {
	components:{
		simpleButton: require('@/components/Atom/Buttons/SimpleButton.vue').default,
		inputHorario 	   : require('@/components/Atom/Inputs/inputHorarioInicioFinal.vue').default
	},
	props:{
		nomemedico:{
			type:String,
			default:'',
		},
		cid:{
			type:[String,Number],
			default:'',
		},
		crm:{
			type:[String, Number],
			default:'',
		},
		imgValue:{
			type:String,
			default:''
		},
		existeAjuste:{
			type: Boolean,
			default:false
		},
		motivo:{
			type: String,
			default:''
		},
		jornadaCompleta:{
			type: Boolean,
			required: true
		}
	},
	validations:{
		valueNomeMedico:{ required },
		valueCid: { required },
		valueCrm: { required }
	},
	data(){
		return {
			iconPaper : mdiPaperclip,
			valueNomeMedico:this.nomemedico,
			valueCid: this.cid,
			valueCrm: this.crm,
			motivoAjuste:this.motivo
		}
	},
	methods:{
		/**
		 * @listens intervalo - componente de inicio final
		 * @param {string[]} arrIntervalo horário de inicio e fim
		 */
		mudaIntervaloEvento(arrIntervalo){
			this.$emit('horarios', arrIntervalo)
		},

		mudaMotivo(){
			this.$emit('muda-motivo', this.motivoAjuste)
		},

		/**Avisa o stepper que o valor do nome deve ser alterado */
		mudaMedico(){
			this.$emit('nome-medico', this.valueNomeMedico)
		},

		/**Avisa o stepper que o valor do crm deve ser alterado */
		mudaCrm(){
			this.$emit('muda-crm', this.valueCrm)
		},

		/**Avisa o stepper que o valor do cid deve ser alterado */
		mudaCid(){
			this.$emit('muda-cid', this.valueCid)
		},

		/**Avisa o stepper que o usuário vai mandar um arquivo*/
		onPickFile(){
			this.$emit('pick-file')
		},
	},
	computed: {
		valido() {
			return !this.$v.$invalid 
		}
	},
}
</script>
<style lang="scss" scoped>
#second-step-atestado{
	display: flex;
	.form-atestado{
		width: 80%;
		padding: 3px;
		.second-row{
			display: flex;
			padding-top: 5px;
			input[id^=input]{
				width: 90px;
			}
		}
		.column{
			display: flex;
			flex-direction: column;
			flex: auto 
		}
	}
	.campo-anexo{
		padding-top: 5%;
		display: flex;
		width: 100%;
		flex-flow: column;
		.btn-anexo{
			height: 35px;
			align-self: flex-end;
		}
		.filename{
			height: 35px;
			margin-top: 2%;
			margin-bottom: 2%;
			padding-right: 1.3%;
			align-self: flex-end;
			overflow: hidden;
			word-break: break-all;
		}
		.horarios{
			padding-left: 22%;
		}
	}
	.second-column{
		flex: auto;
		align-self: center;
	}
	.form-atestado{
		#nome_medico{
			width: 100%;
		}
		#input-crm{
			align-self: flex-start;
			width: 95%;
		}
		#input-cid{
			align-self: flex-end;
			width: 95%;
		}
		.label-cid{
			padding-left: 5px;
		}
	}
}
.unselectable{
	user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}
</style>