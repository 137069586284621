
<template>
	<panelEagle id="monitoramentoRotasView" :loading="loadingPanel">
		<div class="col-sm-12 nopadding divDontPrint">
			<div class="col-sm-12 nopadding row">
				<div class="col-sm-6 row">
					<tituloPage :icon="mdiTelevisionGuide" titulo="Monitoramento rotas" />
				</div>
				<div class="col-sm-6" align="right">
					<simpleButton id="aplicarFiltros" width="120px" :icon="mdiPlus" type="orange" text="Aplicar filtros"
						title="Aplicar filtros">
					</simpleButton>
					<simpleButton v-if="getCadastrar()" id="justificativas" v-b-modal="'justificativaRotaModal'" width="120px"
						:icon="mdiPlus" type="green" text="Justificativas" title="Justificativas" event="click"
						@click="popoverFiltrosShow = false">
					</simpleButton>
					<simpleButton id="popoverInfo" width="120px" :icon="mdiAlertCircleOutline" type="blue" text="Legenda"
						title="Legenda">
					</simpleButton>
					<justificativaRotaModal></justificativaRotaModal>
				</div>
			</div>
			<div class="col-sm-12">
				<hr>
			</div>

			<div class="divListagemLinhas col-sm-12">

				<div v-if="arrayDados.length > 0" @click="openCloseBlockLinhasSeparadas" style="float:left">
					<b-icon :icon="iconFlecha" font-scale="1.5" />
				</div>

				<statusInformation v-if="arrayDados.length == 0" typeBar="div" :statusBar="statusBar">
				</statusInformation>
				<monitoramentoRP v-else-if="arrayDados.length > 0" v-for="(rota, index) in dadosTabela"
					@recarregaDados="recarregaDados" @linhaCriada="RotasPrincipaisCriadas" :key="index + '_' + rota.rota"
					:dados="rota" :activeLinhasParadasCollaps="activeLinhasParadas">
				</monitoramentoRP>
			</div>
			<!-- Popover informações -->

			<div>
				<b-popover target="popoverInfo" triggers="hover click" placement="auto" ref="popover">
					<div class="col-sm-12 nopadding row">
						<div class="col-sm-12 nopadding">
							<subtitle :items="itemsSubtitlePontos" title="Coleta/Entrega">
							</subtitle>
						</div>
						<div class="col-sm-12 nopadding">
							<subtitle :items="itemsSubtitleRota" title="Rota">
							</subtitle>
						</div>
						<div class="col-sm-12 nopadding">
							<subtitle :items="itemsSubtitleStatusRota" title="Status da rota">
							</subtitle>
						</div>
						<div class="col-sm-12 nopadding">
							<subtitle :items="itemsSubtitleInformacoes" title="Siglas">
							</subtitle>
						</div>
					</div>
				</b-popover>
			</div>
			<!-- Popover Filtros -->
			<div>
				<b-popover id="popoverFiltros" target="aplicarFiltros" triggers="hover click" :show.sync="popoverFiltrosShow"
					placement="top" ref="popover" @hidden="onHidden">
					<div class="col-sm-12 row nopadding">
						<div class="col-sm-12 nopadding">
							<inputRangeWithLimit id='diasMonitoramentoRota' name='dataMonitoramentoRota' titulo="Intervalo"
								:intervalo='15' :isObrigatorio="true" :value="data" @changeInput="changeInput" />
						</div>

						<div class="col-sm-12 nopadding mt-2">
							<selectAll :loading="loadingButtonEmpresa" @changeSelect="changeEmpresa" :isMultiple="true"
								nameForRadio="empresasMonitoramentoRota" :hasSelectAll="true"
								:labels="[{ indexDFH: 'EM', description: 'Empresas*' }]" :optionsArray="optionsEmpresas"
								:selected="popoverData.empresas" firstSelected="EM">
							</selectAll>
						</div>


						<div class="col-sm-12 nopadding mt-2">
							<selectAll :loading="loadingButtonVeiculo" @changeSelect="changeVeiculos" :isMultiple="true"
								nameForRadio="veiculosMonitoramentoRota" :hasSelectAll="true"
								:labels="[{ indexDFH: 'VE', description: 'Placas/Prefixo' }]" :optionsArray="optionsVeiculos"
								:selected="popoverData.veiculos" :disabled="$v.selectedEmpresas.$invalid" firstSelected="VE">
							</selectAll>
						</div>

						<div class="col-sm-12 nopadding mt-2">
							<selectAll :loading="loadingButtonCodCargas" @changeSelect="changeCodCargas" :isMultiple="true"
								nameForRadio="codcargasMonitoramentoRota" :hasSelectAll="true"
								:labels="[{ indexDFH: 'CC', description: 'Código das Cargas' }]" :optionsArray="optionsCodCargas"
								:selected="popoverData.codcargas" :disabled="$v.selectedEmpresas.$invalid" firstSelected="CC">
							</selectAll>
						</div>

						<div class="col-sm-12 mt-2 nopadding">
							<buttonsFilters @buttonsFiltersChange="changeFilterButtons" :arrayButtons="arrayButtons" label="Status"
								:onlyOneSelected="false">
							</buttonsFilters>
						</div>
						<div class="col-sm-12 nopadding mt-2" align="center">
							<simpleButton :disabled="$v.$invalid" id="filtrar" width="108px" type="blue" text="Filtrar" title="Filtrar"
								event='click' @click='filtrar'>
							</simpleButton>
							<simpleButton id="fecharFiltros" width="108px" :icon="mdiCloseThick" type="red" text="Fechar" title="Fechar"
								event='click' @click='popoverFiltrosShow = false'>
							</simpleButton>
						</div>
					</div>
				</b-popover>
			</div>
		</div>
	</panelEagle>
</template>

<script lang="js">
import Vue from 'vue'
import { mdiTelevisionGuide, mdiPlus, mdiAlertCircleOutline, mdiCloseThick, mdiMapMarkerCircle, mdiMapMarker } from '@mdi/js'
import { EmpresasService } from '../../../Services/auth/Empresas.service'
import { HttpRequest } from '../../../Services/auth/HttpRequest.Service'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { FiltrosService } from '../../../Services/filtros/filtros.Service'
import { DateTime } from 'luxon';

export default Vue.extend({
	name: 'MonitoramentoRotas',
	components: {
		'tituloPage': require('@/components/Atom/Header/Titulo').default,
		'subtitle': require('@/components/Atom/Subtitle/Subtitle').default,
		'selectAll': require('@/components/Atom/Select/SelectAll').default,
		'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
		'simpleButton': require('@/components/Atom/Buttons/SimpleButton').default,
		'inputRangeWithLimit': require('@/components/Atom/Datas/InputRangeWithLimit').default,
		'ButtonsFilters': require('@/components/Atom/Buttons/ButtonsFilters').default,
		'statusInformation': require('@/components/Atom/StatusInformation/StatusInformation').default,
		'justificativaRotaModal': require('@/components/Atom/ModalSpecific/ModalJustificativasRotas').default,
		'monitoramentoRP': require('@/components/Atom/SpecificComponents/MonitoramentoRotas/MonitoramentoRotaPrincipal').default,
	},

	validations: {
		selectedEmpresas: { required },
		// filtrosButtons: { required},
	},

	data() {
		return {
			activeLinhasParadas: false,
			iconFlecha: 'chevron-right',
			mdiPlus: mdiPlus,
			mdiTelevisionGuide: mdiTelevisionGuide,
			mdiCloseThick: mdiCloseThick,
			mdiAlertCircleOutline: mdiAlertCircleOutline,
			link: '/roteirizador/monitoramento/',
			loadingPanel: false,
			intervalo60seg: undefined,

			optionsEmpresas: new EmpresasService().Get(),
			optionsVeiculos: [],
			optionsCodCargas: [],

			selectedEmpresas: [],
			selectedVeiculos: [],
			selectedCodCargas: [],

			loadingButtonEmpresa: false,
			loadingButtonVeiculo: false,
			loadingButtonCodCargas: false,

			itemsSubtitleRota: [
				{ 'description': 'No Horário', 'spanColor': 'light-green', 'icone': '' },
				{ 'description': 'Adiantado', 'spanColor': 'blue', 'icone': '' },
				{ 'description': 'Atrasado', 'spanColor': 'red', 'icone': '' },
				{ 'description': 'Sem dados', 'spanColor': 'superGray', 'icone': '' },
			],
			itemsSubtitlePontos: [
				{ 'description': 'Realizado', 'spanColor': '', 'icone': mdiMapMarker, 'iconColor': 'iconGreen' },
				{ 'description': 'Pendente', 'spanColor': '', 'icone': mdiMapMarker, 'iconColor': 'iconBlue' },
				{ 'description': 'Justificado', 'spanColor': '', 'icone': mdiMapMarker, 'iconColor': 'iconOrange' },
				{ 'description': 'Não Realizado', 'spanColor': '', 'icone': mdiMapMarker, 'iconColor': 'iconRed' },

				{ 'description': 'Parada Realizada', 'spanColor': '', 'icone': mdiMapMarkerCircle, 'iconColor': 'iconGreen' },
				{ 'description': 'Parada Pendente', 'spanColor': '', 'icone': mdiMapMarkerCircle, 'iconColor': 'iconBlue' },
				{ 'description': 'Parada Justificada', 'spanColor': '', 'icone': mdiMapMarkerCircle, 'iconColor': 'iconOrange' },
				{ 'description': 'Parada Não Realizada', 'spanColor': '', 'icone': mdiMapMarkerCircle, 'iconColor': 'iconRed' },
			],

			itemsSubtitleInformacoes: [
				{ 'description': 'R - Realizado', 'spanColor': '', 'icone': '' },
				{ 'description': 'P - Pendente', 'spanColor': '', 'icone': '' },
				{ 'description': 'J - Justificado', 'spanColor': '', 'icone': '' },
				{ 'description': 'T - Total', 'spanColor': '', 'icone': '' },
				{ 'description': 'NF - Não finalizado', 'spanColor': '', 'icone': '' },
				{ 'description': 'NI - Não iniciado', 'spanColor': '', 'icone': '' },
				{ 'description': 'Ini - Inicio', 'spanColor': '', 'icone': '' },
			],
			itemsSubtitleStatusRota: [
				{ 'description': 'P - Pendente', 'spanColor': '', 'icone': '' },
				{ 'description': 'I - Iniciada', 'spanColor': '', 'icone': '' },
				{ 'description': 'F - Finalizada', 'spanColor': '', 'icone': '' },
				{ 'description': 'C - Em carregamento', 'spanColor': '', 'icone': '' },
			],
			arrayButtons: [
				{
					'value': 'I', 'title': 'Iniciada', 'width': '48%', 'text': 'Iniciada',
					'isSelected': true
				},
				{
					'value': 'P', 'title': 'Pendente', 'width': '48%', 'text': 'Pendente',
					'isSelected': false
				},
				{
					'value': 'F', 'title': 'Finalizada', 'width': '48%', 'text': 'Finalizada',
					'isSelected': false
				},
				{
					'value': 'C', 'title': 'Carregando', 'width': '48%', 'text': 'Carregando',
					'isSelected': false
				},
			],
			statusBar: 'info',
			arrayDados: [],
			lastRequest: '',
			data: '',
			rotasCriadas: [],
			intervaloFunc: [],
			filtrosButtons: [],
			popoverData: {
				data: '',
				empresas: [],
				veiculos: [],
				codcargas: [],
				status: [],
			},
			popoverFiltrosShow: false,
		}

	},

	methods: {
		...mapGetters(['getMaster', 'getCadastrar', 'getEditar']),

		changeInput(data) {
			this.data = data
		},

		openCloseBlockLinhasSeparadas() {

			this.activeLinhasParadas = !this.activeLinhasParadas;
			this.internalActiveLinhasParadas = this.activeLinhasParadas;
			this.iconFlecha = this.activeLinhasParadas ? 'chevron-down' : 'chevron-right';
		},

		async changeEmpresa(value) {
			this.selectedEmpresas = value;
			this.listarVeiculos(value);
			this.listarCodCargas(value);
			this.popoverFiltrosShow = true
		},

		async listarVeiculos(value) {
			this.loadingButtonVeiculo = true
			await new FiltrosService().dados_filtros(value, ['V']).then(data => {
				this.optionsVeiculos = data.V
			})
			this.loadingButtonVeiculo = false
		},

		async listarCodCargas(value) {
			this.loadingButtonCodCargas = true
			await new FiltrosService().dados_filtros(value, ['CCA']).then(data => {
				this.optionsCodCargas = data.CCA
			})
			this.loadingButtonCodCargas = false
		},

		async changeVeiculos(value) {
			this.loadingButtonCodCargas = true
			this.selectedVeiculos = value;
			this.loadingButtonCodCargas = false
		},

		changeCodCargas(value) {
			this.selectedCodCargas = value
		},

		async getInformacoesRotas() {
			this.intervalo60seg = undefined
			if (this.selectedEmpresas.length > 0 && this.data.length > 0 && this.filtrosButtons.length > 0) {
				this.loadingPanel = true
				let body = {
					'clientes': this.selectedEmpresas,
					'veiculos': this.selectedVeiculos,
					'codcargas': this.selectedCodCargas,
					'data': this.data,
					'status': this.filtrosButtons
				}
				var data = await new HttpRequest().Post(this.link + 'pegar/rotas', body)
				if (data.status && data.data) {
					this.arrayDados = data.data.rotas
					this.lastRequest = data.data.horario
					this.controleIntervalo('criar')
					if (data.data.tamanho == 0 || data.data.rotas.length <= 0) {
						this.statusBar = 'error'
					}
				} else {
					this.statusBar = 'info'
					this.arrayDados = []
					this.lastRequest = ''
				}
				this.loadingPanel = false
			} else {
				this.statusBar = 'info'
				this.arrayDados = []
				this.lastRequest = ''
			}
		},

		controleIntervalo(func) {
			if (func == 'criar') {
				if (this.intervalo60seg === undefined) {
					this.intervalo60seg = setInterval(() => {
						this.atualizaInfoRotas()
					}, 60000)
				}
			} else {
				//destruir intervalo
				if (this.intervalo60seg !== undefined) {
					clearInterval(this.intervalo60seg)
					this.intervalo60seg = undefined
				}
			}
		},

		/**
		 * Metodo para buscar atualizações e rotas novas no banco
		 * @param Objetos 
		 *  clientes = empresas selecionadas,
		 *  data = data selecionada, 
		 *  rotas = rotas que estão na tabela, 
		 *  ultima = última requisição feita,
		 *  status = filtros selecionados
		 * @return Arrays
		 *  novas = rotas novas encontradas
		 *  excluidas = rotas que foram inativadas
		 *  atualizar = dados novos encontrados nas rotas atuais
		 * @author Duan aparentemente, comentado pelo Vitor 🐨
		 */
		async atualizaInfoRotas() {
			if (this.lastRequest != '' && this.selectedEmpresas.length > 0) {
				let body = {
					'clientes': this.selectedEmpresas,
					'veiculos': this.selectedVeiculos,
					'codcargas': this.selectedCodCargas,
					'data': this.data,
					'rotas': this.rotasCriadas,
					'ultima': this.lastRequest,
					'status': this.filtrosButtons
				}
				var data = await new HttpRequest()
					.Post(this.link + 'atualizar/informacao', body)
				if (data.status && data.data) {
					var novas = data.data.rotas.novas
					var excluidas = data.data.rotas.excluidas
					var atualizar = data.data.rotas.atualizar
					var atualizarApenasRotas = data.data.rotas.atualizarRotas
					this.lastRequest = data.data.horario
					this.novasRotas(novas)
					this.excluirRotas(excluidas)
					this.atualizarRotas(atualizar)
					this.atualizarApenasRotas(atualizarApenasRotas)
					this.$nextTick(() => {
						this.$forceUpdate()
					})
				} else {
					this.$bvToast.toast('Não foi possível atualizar as informações!', {
						title: 'Falha na atualização',
						variant: 'danger',
						solid: true,
					})
				}
			}
		},

		RotasPrincipaisCriadas(conjuntoRotas) {
			this.rotasCriadas.push(conjuntoRotas)
		},

		novasRotas(novas) {
			var merged = this.arrayDados.concat(novas)
			this.arrayDados = merged
		},

		excluirRotas(excluidas) {
			for (let index = 0; index < excluidas.length; index++) {
				var posicao = this.arrayDados.findIndex((element) => {
					return element.rota == excluidas[index].rota
				})
				this.arrayDados.splice(posicao, 1);
				this.rotasCriadas.splice(posicao, 1);
			}
		},

		/**
		 * Metodo para atualizar as rotas na tabela (Ele percorre o objeto original e o que tem os dados 
		 * para atualizar inserindo os novos dados no objeto original)
		 * @param Array com os dados da rota (arrayDados)
		 * @return Array com os dados da rota alterados (arrayDados)
		 * @author Vitor Hugo 🐨
		 */
		atualizarRotas(atualizar) {
			if (atualizar.length > 0) {
				atualizar.map((itemAtualizado, indexAtualizado) => {
					const rota = itemAtualizado.rota;

					return this.arrayDados.map((itemAntigo, indexAntigo) => {
						if (itemAntigo.rota == rota) {

							itemAtualizado.pontosAtualizados.map((item) => {
								this.arrayDados[indexAntigo].pontos.push(item);
							})

							return itemAntigo;
						}
					})
				});
			}

			if (atualizar.length > 0) {
				for (let index = 0; index < atualizar.length; index++) {
					let rota = atualizar[index]
					var j = this.arrayDados.findIndex((element) => {
						return element.rota == rota.rota
					})
					let objRota = this.arrayDados[j]
					objRota.ajudante = rota.ajudante
					objRota.horaFim = rota.horaFim
					objRota.horaInicio = rota.horaInicio
					objRota.justificados = rota.justificados
					objRota.motorista = rota.motorista
					objRota.pontoSaida = rota.pontoSaida
					objRota.pontoRetorno = rota.pontoRetorno
					objRota.rotaCusto = rota.rotaCusto
					objRota.rotaPeso = rota.rotaPeso
					objRota.rotaTempo = rota.rotaTempo
					objRota.pendentes = rota.pendentes
					objRota.posicaoCarro = rota.posicaoCarro
					objRota.porcent = rota.porcent
					objRota.proximo = rota.proximo
					objRota.realizados = rota.realizados
					objRota.status = rota.status
					objRota.statusCarro = rota.statusCarro
					objRota.total = rota.total
					objRota.ultimo = rota.ultimo
					if (rota.pontosAtualizados.length > 0) {
						for (let i = 0; i < rota.pontosAtualizados.length; i++) {
							let item = rota.pontosAtualizados[i]
							var posicao = objRota.pontos.findIndex((el) => {
								return el.ponto == item.ponto
							})
							let obj = objRota.pontos[posicao]
							obj.volumes = item.volumes
							obj.previsto = item.previsto
							obj.status = item.status
							obj.nome = item.nome
							obj.kmPercorrido = item.kmPercorrido
							obj.statusPonto = item.statusPonto
							obj.statusChegadaPonto = item.statusChegadaPonto
							obj.mensagem = item.mensagem
							obj.chegada = item.chegada
							obj.parado = item.parado
							objRota.pontos[posicao] = obj
						}
					}
					this.arrayDados[j] = objRota
				}
				this.$forceUpdate()
			}
		},

		atualizarApenasRotas(rotas) {
			if (rotas.length > 0) {
				for (const rota of rotas) {
					var j = this.arrayDados.findIndex((element) => {
						return element.rota == rota.rota
					});
					let objRota = this.arrayDados[j]
					objRota.ajudante = rota.ajudante
					objRota.motorista = rota.motorista
					objRota.porcent = rota.porcent
					objRota.pontoSaida = rota.pontoSaida
					objRota.pontoRetorno = rota.pontoRetorno
					objRota.rotaTempo = rota.rotaTempo
					objRota.rotaCusto = rota.rotaCusto
					objRota.rotaPeso = rota.rotaPeso
					objRota.horaInicio = rota.horaInicio
					objRota.horaFim = rota.horaFim
					objRota.realizados = rota.realizados
					objRota.justificados = rota.justificados
					objRota.pendentes = rota.pendentes
					objRota.status = rota.status
					objRota.total = rota.total
					this.arrayDados[j] = objRota
				}
				// this.$forceUpdate()
			}
		},

		changeFilterButtons(values) {
			if (values != undefined) {
				this.filtrosButtons = values;
			} else {
				this.filtrosButtons = []
			}
		},

		recarregaDados() {
			this.getInformacoesRotas()
		},

		filtrar() {
			this.popoverFiltrosShow = false
			this.controleIntervalo('destruir')
			this.getInformacoesRotas()
		},

		/**
				* @description Evento de fechar o modal, quando este é executado,
				* as informações dos filtros são armazenadas em *this.popoverData*,
				* pois ao fechar e abrir os filtros, deve mostrar as mesmas
				* informações selecionadas. Não consegui salvar o estado do popover.
				* @author Rafa 🐈
				*/
		onHidden() {
			this.popoverData.data = this.dataSelecionada

			this.popoverData.empresas = this.optionsEmpresas
				.filter((e) => this.selectedEmpresas.includes(e.value))

			this.popoverData.veiculos = this.optionsVeiculos
				.filter((e) => this.selectedVeiculos.includes(e.value))

			this.popoverData.codcargas = this.optionsCodCargas
				.filter((e) => this.selectedCodCargas.includes(e.value))

			this.popoverData.status = this.filtrosButtons
		},
	},

	computed: {
		dadosTabela() {
			return this.arrayDados
		}
	},

	beforeDestroy() {
		clearInterval(this.intervalo60seg)
	},

	mounted() {
		if (!this.getMaster()) {
			this.filtrosButtons = ['I', 'P']
			this.data = DateTime.now().toFormat('dd/LL/yyyy - dd/LL/yyyy')
			this.optionsEmpresas.forEach(element => {
				this.selectedEmpresas.push(element.value)
			})
			this.optionsVeiculos.forEach(element => {
				this.selectedVeiculos.push(element.value)
			})
			this.optionsCodCargas.forEach(element => {
				this.selectedCodCargas.push(element.value)
			})

			this.getInformacoesRotas()
		}
	}
})
</script>

<style lang="scss">
#monitoramentoRotasView {
	.divListagemLinhas {
		padding-bottom: 10px;
	}

	.paddingBotAndTop {
		padding-bottom: 10px;
		padding-top: 10px;
		padding-left: 0;
		padding-right: 0;
	}

	.fundo {
		max-height: 55px;
		margin: 2px;
		padding-top: 5px;
		background-color: #ecb0af;
	}

	.popover-body {
		font-family: nexabook, sans-serif !important;
	}
}
</style>
