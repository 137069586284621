<template>
    <panelEagle id="Notificacoes" :loading="loadingPanel">
        <div class="col-sm-12 row nopadding">
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiBellRing" titulo="Notificações" />
                </div>
                <div class="col-sm-6 row nopadding" align="right">
                    <template v-if="getCadastrar()">
                        <div class="col-sm-12">
                            <simpleButton
                                type="green"
                                event="click"
                                @click="clickBotaoNovo"
                                :icon="mdiPlus"
                                text="Novo"
                            />
                        </div>
                    </template>
                </div>
            </div>
            <div class="col-sm-12"><hr /></div>
            <div class="col-sm-4 nopadding">
                <selectAll
                    :selected="selectedEmpresas"
                    :labels="[{ indexDFH: 'EM', description: 'Empresas*' }]"
                    @changeSelect="changeSelectEmpresa"
                    nameForRadio="pedro"
                    :isMultiple="true"
                    :hasSelectAll="true"
                    :optionsArray="optEmpresa"
                />
            </div>
            <div class="col-sm-12"><hr /></div>
            <tableListagem
                @editarCadastro="editarCadastro"
                @excluirCadastro="excluirCadastro"
                :loading="loadingTable"
                :data="dataTable"
                deleteEdit="editDelete"
                :titles="titlesTablela"
            />
            <!-- nao ta pronto ainda -->
            <modalEagle id="modalEagle" title="Notificações" @modalOpen="modalOpen">
                <slot slot="modalBody">
                    <panelEagle id="panelModal" :loading="loadingPanelModal">
                        <!-- primeiro passo -->
                        <!-- primeiro campo, select empresa e notificacao -->
                        <div class="col-sm-12 row nopadding">
                            <div class="col-md-6 col-sm-12 nopadding">
                                <selectAll
                                    :selected="selectedEmpresaModal"
                                    :disabled="disabledEmpresaModal"
                                    :loading="loadingEmpresaModal"
                                    :labels="[
                                        {
                                            indexDFH: 'EMMODA',
                                            description: 'Empresa*',
                                        },
                                    ]"
                                    @changeSelect="changeSelectEmpresaModal"
                                    nameForRadio="EmpresasModal"
                                    :isMultiple="true"
                                    :hasSelectAll="true"
                                    :optionsArray="optEmpresa"
                                />
                            </div>
                            <div class="col-md-6 col-sm-12 nopadding">
                                <selectAll
                                    :selected="selectedNotificacaoModal"
                                    :disabled="
                                        disabledNotificacaoModal || !valueEmpresaModal.length
                                    "
                                    :loading="loadingNotificacaoModal"
                                    ref="refInputDasNotificacao"
                                    :labels="[
                                        {
                                            indexDFH: 'NOTMODA',
                                            description: 'Qual notificação você deseja criar?*',
                                        },
                                    ]"
                                    @changeSelect="changeSelectCriarNotificacaoModal"
                                    nameForRadio="carlos"
                                    :isMultiple="false"
                                    :hasSelectAll="false"
                                    :optionsArray="optCriarNotificacao"
                                />
                            </div>
                        </div>
                        <!-- segundo passo -->
                        <b-overlay
                            no-center
                            :show="mostrarOverlaySegundoPasso"
                            rounded="sm"
                            class="col-sm-12 nopadding"
                        >
                            <template #overlay>
                                <b-icon
                                    variant="info"
                                    scale="2"
                                    shift-v="8"
                                    shift-h="8"
                                    class="position-absolute"
                                    style="top: 0; right: 0"
                                ></b-icon>
                            </template>
                            <div class="col-sm-12"><hr /></div>
                            <!-- CAMPO 1 -->
                            <!-- execesso de vel -->
                            <div
                                class="col-sm-12 row nopadding"
                                v-if="valueNotificacaoCriada[0] == 1"
                            >
                                <inputSimple
                                    min="10"
                                    max="999"
                                    type="number"
                                    ref="irefInputVelocidade"
                                    placeholder="Velocidade em Km"
                                    :value="valueInputVelocidadeVeiculo"
                                    @changeInput="changeInputVelocidadeVeiculo"
                                    label="Será notificado quando o(s) veículo(s) ultrapassarem a velocidade de:"
                                />
                            </div>
                            <!-- CAMPO 2 -->
                            <!-- entrada ou saida do ponto -->
                            <div
                                class="col-sm-12 row nopadding"
                                v-if="valueNotificacaoCriada[0] == 2"
                            >
                                <div class="col-sm-6 nopadding">
                                    <selectAll
                                        :selected="selectedPontos"
                                        ref="srefPontosMonitorar"
                                        :labels="[
                                            {
                                                indexDFH: 'MONITORARPONTOS',
                                                description: 'Pontos para monitorar*',
                                            },
                                        ]"
                                        @changeSelect="changeSelectMonitorarPontos"
                                        nameForRadio="carlos"
                                        :isMultiple="true"
                                        :hasSelectAll="true"
                                        :optionsArray="optMonitorarPontos"
                                    />
                                </div>
                                <div class="col-sm-6 row">
                                    <b-form-checkbox
                                        ref="crefCheckboxEntradaPonto"
                                        @change="changeCheckboxSelectPontosMonitoraEntrada"
                                        class="col-sm-6 margin-top20px"
                                        id="checkboxEntradaPonto"
                                        v-model="checkboxEntradaPontosMonitorarModal"
                                        name="checkboxEntradaPonto"
                                    >
                                        Entrada
                                    </b-form-checkbox>
                                    <b-form-checkbox
                                        ref="crefCheckboxSaidaPonto"
                                        @change="changeCheckboxSelectPontosMonitoraSaida"
                                        class="col-sm-6 margin-top20px"
                                        id="checkboxSaidaPonto"
                                        v-model="checkboxSaidaPontosMonitorarModal"
                                        name="checkboxSaidaPonto"
                                    >
                                        Saida
                                    </b-form-checkbox>
                                </div>
                            </div>
                            <!-- entrada ou saida de regiao -->
                            <!-- CAMPO 3 -->
                            <div
                                class="col-sm-12 row nopadding"
                                v-if="valueNotificacaoCriada[0] == 3"
                            >
                                <div class="col-sm-6 nopadding">
                                    <selectAll
                                        :selected="selectedRegioes"
                                        ref="srefRegioesMonitorar"
                                        :labels="[
                                            {
                                                indexDFH: 'NOTMODA',
                                                description: 'Regiões para monitorar*',
                                            },
                                        ]"
                                        @changeSelect="changeSelectRegioesMonitorar"
                                        nameForRadio="carlos"
                                        :isMultiple="true"
                                        :hasSelectAll="true"
                                        :optionsArray="optRegioesMonitorar"
                                    />
                                </div>
                                <div class="col-sm-6 row">
                                    <b-form-checkbox
                                        ref="crefCheckboxEntradaRegiao"
                                        @change="changeCheckboxSelectRegioesSelecionaEntrada"
                                        class="col-sm-6 margin-top20px"
                                        id="checkboxEntradaRegioes"
                                        v-model="checkboxEntradaRegioesSelecionarModal"
                                        name="checkboxEntradaRegioes"
                                    >
                                        Entrada
                                    </b-form-checkbox>
                                    <b-form-checkbox
                                        ref="crefCheckBoxSaidaRegiao"
                                        @change="changeCheckboxSelectRegioesSelecionaSaida"
                                        class="col-sm-6 margin-top20px"
                                        id="checkboxSaidaRegioes"
                                        v-model="checkboxSaidaRegioesSelecionarModal"
                                        name="checkboxSaidaRegioes"
                                    >
                                        Saida
                                    </b-form-checkbox>
                                </div>
                            </div>
                            <!-- temp -->
                            <!-- CAMPO 4 -->
                            <div
                                class="col-sm-12 row nopadding"
                                v-if="valueNotificacaoCriada[0] == 5"
                            >
                                <span class="col-sm-12 margin-bot-10px">
                                    Será notificado quando a(s) temperatura sairem do intervalo:
                                </span>
                                <div class="col-sm-6 nopadding">
                                    <inputSimple
                                        :value="valueInputTemperaturaMinimo"
                                        ref="irefInputSimplesTemperaturaMinima"
                                        :max="valueInputTemperaturaMaximo"
                                        type="number"
                                        @changeInput="changeInputTemperaturaMinima"
                                        label="Minimo:"
                                    />
                                </div>
                                <div class="col-sm-6 nopadding">
                                    <inputSimple
                                        :value="valueInputTemperaturaMaximo"
                                        ref="irefInputSimplesTemperaturaMaxima"
                                        :min="valueInputTemperaturaMinimo"
                                        type="number"
                                        @changeInput="changeInputTemperaturaMaxima"
                                        label="Máximo:"
                                    />
                                </div>
                            </div>
                            <!-- bateria fraca -->
                            <!-- CAMPO 5 -->
                            <div
                                class="col-sm-12 row nopadding"
                                v-if="valueNotificacaoCriada[0] == 7"
                            >
                                <inputSimple
                                    ref="irefQtdDeBateria"
                                    type="number"
                                    max="99"
                                    min="1"
                                    placeholder="Voltagem da bateria"
                                    @changeInput="changeInputBateriaFraca"
                                    :value="valueInputBateriaFraca"
                                    label="Será notificado quando a(s) voltagem da bateria for abaixo de:"
                                />
                            </div>
                            <!--  Tempo parado em ponto -->
                            <!-- CAMPO 6 -->
                            <div
                                class="col-sm-12 row nopadding"
                                v-if="valueNotificacaoCriada[0] == 11"
                            >
                                <div class="col-sm-6 nopadding">
                                    <selectAll
                                        :selected="selectedPontos"
                                        ref="srefPontosMonitorar"
                                        :labels="[
                                            {
                                                indexDFH: 'MONITORARPONTOS',
                                                description: 'Pontos para monitorar*',
                                            },
                                        ]"
                                        @changeSelect="changeSelectMonitorarPontosTempo"
                                        nameForRadio="carlos"
                                        :isMultiple="true"
                                        :hasSelectAll="true"
                                        :optionsArray="optMonitorarPontos"
                                    />
                                </div>
                                <div class="col-sm-6 nopadding">
                                    <inputSimple
                                        type="time"
                                        ref="tempoParado"
                                        placeholder="Tempo parado"
                                        :value="valueInputTempoParado"
                                        @changeInput="changeInputTempoVelocidade"
                                        label="Tempo parado*"
                                    />
                                </div>
                            </div>
                            <!-- execesso de vel regiao -->
                            <!-- CAMPO 7 -->
                            <div
                                class="col-sm-12 row nopadding"
                                v-if="valueNotificacaoCriada[0] == 12"
                            >
                                <div class="col-sm-6 nopadding">
                                    <selectAll
                                        :selected="selectedExcessoRegioes"
                                        ref="srefPontosMonitorar"
                                        :labels="[
                                            {
                                                indexDFH: 'monitorarRegiao',
                                                description: 'Regiões a monitorar*',
                                            },
                                        ]"
                                        @changeSelect="changeSelectMonitorarVelocidadeRegiao"
                                        nameForRadio="carlos"
                                        :isMultiple="true"
                                        :hasSelectAll="true"
                                        :optionsArray="optRegioesMonitorar"
                                    />
                                </div>
                                <div class="col-sm-6 nopadding">
                                    <inputSimple
                                        min="10"
                                        max="999"
                                        type="number"
                                        ref="irefInputExcessoVelocidade"
                                        placeholder="Velocidade em Km"
                                        :value="valueVelocidadeRegiao"
                                        @changeInput="changeInputVelocidadeRegiao"
                                        label="Considerar excesso acima de:*"
                                        :disabled="mostrarOverlayTerceiroPassoHabilitar"
                                    />
                                </div>
                            </div>
                            <!-- CAMPO 8 -->
                            <div
                                class="col-sm-12 row nopadding"
                                v-if="valueNotificacaoCriada[0] == 13"
                            >
                                <b-form-checkbox
                                    ref="checkBoxManutencoesProgramar"
                                    id="checkBoxManutencoesProgramar"
                                    name="checkBoxManutencoesProgramar"
                                    class="col-sm-6 pl-5"
                                    v-model="checkboxManutencaoProgramar"
                                    @change="changeCheckboxManutencaoProgramar"
                                >
                                    Manutenções a programar
                                </b-form-checkbox>
                                <b-form-checkbox
                                    ref="checkboxManutencoesVencidas"
                                    class="col-sm-6 pl-5"
                                    id="checkboxManutencoesVencidas"
                                    v-model="checkboxManutencoesVencidas"
                                    name="checkboxManutencoesVencidas"
                                    @change="changeCheckboxManutencoesVencidas"
                                >
                                    Manutenções vencidas
                                </b-form-checkbox>
                            </div>
                            <!-- func hora proibida -->
                            <!-- bateria desc       -->
                            <!-- aciona panico      -->
                            <div
                                v-if="
                                    valueNotificacaoCriada[0] == 4 ||
                                    valueNotificacaoCriada[0] == 6 ||
                                    valueNotificacaoCriada[0] == 8 ||
                                    valueNotificacaoCriada[0] == 10 ||
                                    valueNotificacaoCriada[0] == 14
                                "
                                class="col-sm-12 row nopadding"
                            >
                                <span class="col-sm-12 margin-bot-10px">
                                    Quais veículos ou grupos?
                                </span>
                                <div class="col-sm-12 nopadding row">
                                    <div class="col-sm-6 nopadding">
                                        <selectAll
                                            :selected="selectedVeiculos"
                                            ref="srefSelectAllVeiculos"
                                            :labels="[
                                                {
                                                    indexDFH: 'MODV',
                                                    description: 'Veículos*',
                                                },
                                            ]"
                                            @changeSelect="changeSelectVeiculos"
                                            nameForRadio="veiculoOuGrupoModal"
                                            :isMultiple="true"
                                            :hasSelectAll="true"
                                            :optionsArray="veiculosDoBanco"
                                        />
                                    </div>
                                    <div class="col-sm-6 nopadding">
                                        <selectAll
                                            :selected="selectedGrupoVeiculos"
                                            ref="srefSelectAllGruposVeiculos"
                                            :labels="[
                                                {
                                                    indexDFH: 'MODGV',
                                                    description: 'Grupo de veículos*',
                                                },
                                            ]"
                                            @changeSelect="changeSelectGrupoVeiculos"
                                            nameForRadio="veiculoOuGrupoModal"
                                            :isMultiple="true"
                                            :hasSelectAll="true"
                                            :optionsArray="grupoVeiculoDoBanco"
                                        />
                                    </div>
                                </div>
                            </div>
                            <!-- abertura de porta -->
                            <div
                                class="col-sm-12 row nopadding"
                                v-if="valueNotificacaoCriada[0] == 9"
                            >
                                <span class="col-sm-12 margin-bot-10px">
                                    Qual período de monitamento?
                                </span>
                                <div class="col-sm-12 nopadding">
                                    <buttonsFilters
                                        ref="brefButtonsFiltersPorta"
                                        @buttonsFiltersChange="buttonsFiltersChangeModalPortas"
                                        :onlyOneSelected="true"
                                        :arrayButtons="arrayButtonsModalPortas"
                                        label="Portas"
                                    />
                                </div>
                            </div>
                            <!-- temperatura do motor -->
                            <div
                                v-if="valueNotificacaoCriada[0] == 15"
                                class="col-sm-12 row nopadding"
                            >
                                <inputSimple
                                    min="0"
                                    max="999"
                                    type="number"
                                    ref="irefInputTemperaturaMotor"
                                    :value="valueTemperaturaMotor"
                                    @changeInput="changeTemperaturaMotor"
                                    label="Temperaturas acima de:*"
                                />
                                <!-- placeholder="Velocidade em Km" -->
                            </div>
                            <div v-if="valueNotificacaoCriada[0] == 16" class="col-sm-12">
                                <div class="mapa-input">
                                    <inputSearch
                                        class="input-search"
                                        buttonDisabled="disabled"
                                        :value="valueInputSearchMapa"
                                        label=""
                                        name="inputSearch"
                                        @keyupEnter="clickButtonSearchMapa"
                                        @clickButtonSearch="clickButtonSearchMapa"
                                    />
                                    <div class="col-sm-12 seletor-rua position-abs">
                                        <div class="col-sm-8" v-show="mostraSeletorLocalMapa">
                                            <b-list-group>
                                                <b-overlay :show="loadingEndereco">
                                                    <b-list-group-item
                                                        class="col-sm-12 padd-seletor-rua item cursor-pointer pl-0"
                                                        v-show="
                                                            enderecosMapa.length != 0 &&
                                                            !semEnderecos
                                                        "
                                                        @click="
                                                            clickListaEnderecoMapa(enderecosMapa)
                                                        "
                                                    >
                                                        {{ enderecosMapa.end }}
                                                    </b-list-group-item>
                                                    <b-list-group-item v-show="!enderecosMapa">
                                                        Endereço não encontrado
                                                    </b-list-group-item>
                                                </b-overlay>
                                            </b-list-group>
                                        </div>
                                    </div>
                                    <b-checkbox
                                        class="mt-1 p-1 float-right col-sm-4"
                                        v-model="paradas"
                                    >
                                        Considerar somente paradas
                                    </b-checkbox>
                                </div>
                                <mapaSimples
                                    :latitude="valueLatitudeMatriz"
                                    :longitude="valueLongitudeMatriz"
                                    ref="mapaEndereco"
                                    :height="350"
                                    :initialZoom="13"
                                >
                                    <markerPoint
                                        :latitude="valueLatitudeMatriz"
                                        :longitude="valueLongitudeMatriz"
                                        icon="img/matriz.png"
                                        :isDraggable="false"
                                    />
                                    <markerPoint
                                        :latitude="valueLatitude"
                                        :longitude="valueLongitude"
                                        @drag="dragMarker"
                                        @dragend="dragEnd"
                                        :isDraggable="true"
                                    >
                                        <popupMarker>
                                            <div class="col-sm-12 nopadding">
                                                <p>
                                                    Arraste para o local desejado.
                                                </p>
                                                <basicSlider
                                                    :initialValue="valueRaio"
                                                    :value="valueRaio"
                                                    :maxValue="500"
                                                    @change="changeSlider"
                                                />
                                                Raio: {{ valueRaio }} Mts
                                            </div>
                                        </popupMarker>
                                    </markerPoint>
                                    <circleSimple :center="[Number(valueLatitude), Number(valueLongitude)]" :radius="valueRaio" />
                                </mapaSimples>
                            </div>
                        </b-overlay>
                        <!-- terceiro passo -->
                        <b-overlay
                            v-if="
                                valueNotificacaoCriada[0] != 4 &&
                                valueNotificacaoCriada[0] != 6 &&
                                valueNotificacaoCriada[0] != 8 &&
                                valueNotificacaoCriada[0] != 10 &&
                                valueNotificacaoCriada[0] != 14
                            "
                            no-center
                            :show="mostrarOverlayTerceiroPasso"
                            rounded="sm"
                            class="col-sm-12 nopadding row"
                        >
                            <template #overlay>
                                <b-icon
                                    variant="info"
                                    scale="2"
                                    shift-v="8"
                                    shift-h="8"
                                    class="position-absolute"
                                    style="top: 0; right: 0"
                                ></b-icon>
                            </template>
                            <div class="col-sm-12"><hr /></div>
                            <div class="col-sm-12 margin-bot-10px">Quais veículos ou grupos?</div>
                            <div class="col-sm-12 row nopadding">
                                <div class="col-sm-6 nopadding">
                                    <selectAll
                                        :selected="selectedVeiculos"
                                        ref="srefSelectAllVeiculos"
                                        :labels="[
                                            {
                                                indexDFH: 'MODV',
                                                description: 'Veículos*',
                                            },
                                        ]"
                                        @changeSelect="changeSelectVeiculos"
                                        nameForRadio="veiculoOuGrupoModal"
                                        :isMultiple="true"
                                        :hasSelectAll="true"
                                        :optionsArray="veiculosDoBanco"
                                    />
                                </div>
                                <div class="col-sm-6 nopadding">
                                    <selectAll
                                        :selected="selectedGrupoVeiculos"
                                        ref="srefSelectAllGruposVeiculos"
                                        :labels="[
                                            {
                                                indexDFH: 'MODGV',
                                                description: 'Grupos*',
                                            },
                                        ]"
                                        @changeSelect="changeSelectGrupoVeiculos"
                                        nameForRadio="veiculoOuGrupoModal"
                                        :isMultiple="true"
                                        :hasSelectAll="true"
                                        :optionsArray="grupoVeiculoDoBanco"
                                    />
                                </div>
                            </div>
                        </b-overlay>
                        <!-- quarto passo -->
                        <b-overlay
                            no-center
                            :show="mostrarOverlayQuartoPasso"
                            rounded="sm"
                            class="col-sm-12 nopadding row"
                        >
                            <template #overlay>
                                <b-icon
                                    variant="info"
                                    scale="2"
                                    shift-v="8"
                                    shift-h="8"
                                    class="position-absolute"
                                    style="top: 0; right: 0"
                                ></b-icon>
                            </template>
                            <div class="col-sm-12"><hr /></div>
                            <span class="col-sm-12 margin-bot-10px">
                                Qual período de monitamento?
                            </span>
                            <div class="col-sm-6 nopadding">
                                <buttonsFilters
                                    ref="brefButtonsFiltersDiaDaSemana"
                                    @buttonsFiltersChange="buttonsFiltersChangeQuartoPasso"
                                    :onlyOneSelected="false"
                                    label="Dias da semana"
                                    :arrayButtons="arrayButtonsDiaDaSemana"
                                />
                            </div>
                            <div class="col-sm-6 row nopadding">
                                <div class="col-sm-4 nopadding">
                                    <inputSimple
                                        required
                                        ref="irefInputSimplesInicioDaSemana"
                                        type="time"
                                        :value="valueInicioDiaDaSemana"
                                        @changeInput="changeInputInicioDiaDaSemana"
                                        label="Inicio"
                                    />
                                </div>
                                <div class="col-sm-4 nopadding">
                                    <inputSimple
                                        required
                                        ref="irefInputSimplesFimDaSemana"
                                        type="time"
                                        @changeInput="changeInputFimDiaDaSemana"
                                        :value="valueFimDiaDaSemana"
                                        label="Fim"
                                    />
                                </div>
                            </div>
                            <div class="col-sm-5 nopadding">
                                <inputSingleDay
                                    :value="dataValidade"
                                    :clearable="true"
                                    name="inputvalidade"
                                    label="Qual será a data de validade da notificação?"
                                    @changeInput="changeDataValidade"
                                />
                            </div>
                        </b-overlay>
                        <!-- quinto passo -->
                        <b-overlay
                            no-center
                            :show="mostrarOverlayQuintoPasso"
                            rounded="sm"
                            class="col-sm-12 nopadding row"
                        >
                            <template #overlay>
                                <b-icon
                                    variant="info"
                                    scale="2"
                                    shift-v="8"
                                    shift-h="8"
                                    class="position-absolute"
                                    style="top: 0; right: 0"
                                ></b-icon>
                            </template>
                            <div class="col-sm-12" v-show="valueNotificacaoCriada.length > 0">
                                <hr />
                            </div>
                            <span class="col-sm-12 row margin-bot-10px">
                                Quem vai receber a notificação?
                            </span>
                            <div class="col-sm-6 nopadding">
                                <selectAll
                                    :selected="selectedEmail"
                                    ref="srefEnviaNotEmail"
                                    :labels="[
                                        {
                                            indexDFH: 'EMAILMODAL',
                                            description: 'Email*',
                                        },
                                    ]"
                                    @changeSelect="changeSelectEnviarNotificacaoEmail"
                                    nameForRadio="enviarNotificacaoEmail"
                                    :isMultiple="true"
                                    :hasSelectAll="true"
                                    :optionsArray="optEmailModal"
                                />
                            </div>
                            <div class="col-sm-6 nopadding">
                                <selectAll
                                    :selected="selectedTelegram"
                                    ref="srefEnviaNotTelegram"
                                    :labels="[
                                        {
                                            indexDFH: 'EMAILMODAL',
                                            description: 'Telegram*',
                                        },
                                    ]"
                                    @changeSelect="changeSelectEnviarNotificacaoTelegram"
                                    nameForRadio="enviarNotificacaoTelegram"
                                    :isMultiple="true"
                                    :hasSelectAll="true"
                                    :optionsArray="optTelegramModal"
                                />
                            </div>
                            <div class="col-sm-6 nopadding mt-2">
                                <inputSimple
                                    ref="inputEmail"
                                    label="Emails adicionais"
                                    :value="valueEmailsAdicionais"
                                    @changeInput="changeInputEmailsAdicionais"
                                    @blur="blurInputEmailsAdicionais(valueEmailsAdicionais)"
                                />
                                <div class="col-sm-12 row">
                                    <div v-show="erro" class="ml-1 mt-1 color-red row">
                                        Verifique os emails:
                                    </div>
                                    <template
                                        v-for="(dados, d) in mensagemErro"
                                        class="col-sm-12 color-red row"
                                    >
                                        <div :key="d" class="ml-1 mt-1 color-red row">
                                            ({{ dados }})
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </b-overlay>
                    </panelEagle>
                </slot>
                <slot>
                    <simpleButton
                        type="red"
                        event="click"
                        :disabled="loadingPanelModal"
                        @click="cancelButtonModal"
                        :icon="mdiCloseThick"
                        text="Cancelar"
                    />
                    <simpleButton
                        type="blue"
                        event="click"
                        @click="confirmButtonModal"
                        :icon="mdiCheckBold"
                        :disabled="$v.$invalid || validaBotaoSalvar"
                        text="Confirmar"
                    />
                </slot>
            </modalEagle>
        </div>
    </panelEagle>
</template>
<script lang="js">
import {
    mdiPlus,
    mdiBellRing,
    mdiCheckBold,
    mdiCloseThick
} from '@mdi/js'
import Vue from 'vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { conectionError } from '@/Services/Helpers/swellHeper'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { EmpresasService } from '@/Services/auth/Empresas.service'
export default Vue.extend({
    name:'Notificacao',
    components:{
        tituloPage:require('@/components/Atom/Header/Titulo').default,
        selectAll:require('@/components/Atom/Select/SelectAll').default,
        panelEagle:require('@/components/Atom/Panel/PanelEagle').default,
        inputSearch:require('@/components/Atom/Inputs/InputSearch').default,
        markerPoint:require('@/components/Atom/Mapa/MarkerPoint').default,
        mapaSimples:require('@/components/Atom/Mapa/MapaSimples').default,
        popupMarker:require('@/components/Atom/Mapa/PopupMarker').default,
        modalEagle:require('@/components/Atom/Modal/ModalEagle').default,
        inputSimple:require('@//components/Atom/Inputs/InputSimple').default,
        simpleButton:require('@/components/Atom/Buttons/SimpleButton').default,
        basicSlider: require('@/components/Atom/Buttons/BasicSlider').default,
        tableListagem:require('@/components/Atom/Table/TableListagem').default,
        circleSimple : require('@/components/Atom/Mapa/CircleSimple').default,
        inputSingleDay:require('@/components/Atom/Datas/InputSingleDay').default,
        buttonsFilters:require('@/components/Atom/Buttons/ButtonsFilters').default,
    },

    computed: {
        validaBotaoSalvar() {
            return this.erro || this.loadingPanelModal
        },

        desbloquearTerceiroPasso() {
            var notificacaoSemSegundoPasso = [4, 6, 8, 10]
            return !notificacaoSemSegundoPasso.filter(
                (v) => v != this.valueNotificacaoCriada[0]
            )
        },
    },

    validations:{
        valueEmailTelegramNotificaoModal:{
            required: requiredIf(function(){
                if(this.valueEmailNotificaoModal.length > 0 || this.valueTelegramNotificaoModal.length > 0){
                    return false
                }
                return true
            }),
        },

        valueVeiculosGrupos:{
            required: requiredIf(function(){
                if(this.valueVeiculos.length > 0 || this.valueGrupoVeiculos.length > 0){
                    return false
                }
                return true
            }),
        },

        valueButtonQuartoPasso:{
            required,
        },
    },

    data(){
        return {
            mdiPlus:mdiPlus,
            mdiBellRing:mdiBellRing,
            mdiCheckBold:mdiCheckBold,
            mdiCloseThick:mdiCloseThick,
            loadingPanel:false,
            loadingPanelModal:false,
            loadingTable:false,
            optEmpresa: new EmpresasService().Get(),
            selectedEmpresas:[],
            url: '/telemetria/nao/menu/notificacao/',
            statusBar:'info',
            titlesTablela:[
                {
                    number: 'cliente',
                    name: 'Empresa'
                },
                {
                    number: 'tipo',
                    name: 'Tipo'
                },
                {
                    number: 'dias',
                    name: 'Dias'
                },
                {
                    number: 'inicio',
                    name: 'Início'
                },
                {
                    number: 'fim',
                    name: 'Fim'
                },
                {
                    number: 'parametros',
                    name: 'Parâmetros'
                },
            ],
            dataTable:[],
            arrayButtonsDiaDaSemana:[
                {
                    'value':'0',
                    'width': '11.2%',
                    'isSelected': false,
                    'text': 'D',
                    'title': 'Domingo',
                },
                {
                    'value':'1',
                    'width': '11.2%',
                    'isSelected': true,
                    'text': 'S',
                    'title': 'Segunda',
                },
                {
                    'value':'2',
                    'width': '11.2%',
                    'isSelected': true,
                    'text': 'T',
                    'title': 'Terça',
                },
                {
                    'value':'3',
                    'width': '11.2%',
                    'isSelected': true,
                    'text': 'Q',
                    'title': 'Quarta',
                },
                {
                    'value':'4',
                    'width': '11.2%',
                    'isSelected': true,
                    'text': 'Q',
                    'title': 'Quinta',
                },
                {
                    'value':'5',
                    'width': '11.2%',
                    'isSelected': true,
                    'text': 'S',
                    'title': 'Sexta',
                },
                {
                    'value':'6',
                    'width': '11.2%',
                    'isSelected': false,
                    'text': 'S',
                    'title': 'Sábado',
                },
                // {
                // 	'value':'7',
                // 	'width': '11.2%',
                // 	'isSelected': false,
                // 	'text': 'F',
                // 	'title': 'Feriado',
                // },
            ],
            arrayButtonsModalPortas:[
                {
                    'value':'1',
                    'width': '32%',
                    'isSelected': true,
                    'text': '1',
                },
                {
                    'value':'2',
                    'width': '32%',
                    'isSelected': false,
                    'text': '2',
                },
                {
                    'value':'3',
                    'width': '32%',
                    'isSelected': false,
                    'text': '3',
                },
                {
                    'value':'4',
                    'width': '32%',
                    'isSelected': false,
                    'text': '4',
                },
                {
                    'value':'5',
                    'width': '32%',
                    'isSelected': false,
                    'text': '5',
                },
                {
                    'value':'6',
                    'width': '32%',
                    'isSelected': false,
                    'text': '6',
                },
            ],
            // primeiro campo do modal, select de empresa e notificacao
            // usa o optEmpresa padrao
            valueEmpresaModal:[],
            valueNotificacaoCriada:[],
            optCriarNotificacao:[
                {
                    value: '1',
                    description: 'Excesso de velocidade por veiculo ou grupo de veiculos'
                },
                {
                    value: '2',
                    description: 'Entrada ou saída de ponto'
                },
                {
                    value: '3',
                    description: 'Entrada ou saída de Região'
                },
                {
                    value: '4',
                    description: 'Pânico acionado'
                },
                {
                    value: '5',
                    description: 'Temperatura'
                },
                {
                    value: '6',
                    description: 'Bateria removida'
                },
                {
                    value: '7',
                    description: 'Bateria fraca'
                },
                {
                    value: '8',
                    description: 'Funcionamento em hórario proibido'
                },
                {
                    value: '9',
                    description: 'Abertura de porta fora de ponto'
                },
                {
                    value: '10',
                    description: 'Item do checklist em desacordo'
                },
                {
                    value: '11',
                    description: 'Tempo parado em ponto'
                },
                {
                    value: '12',
                    description: 'Excesso de velocidade em região'
                },
                {
                    value: '13',
                    description: 'Programação ou Vencimento da manutenção'
                },
                {
                    value: '14',
                    description: 'Bloqueio de sinal'
                },
                {
                    value: '15',
                    description: 'Temperatura do motor'
                },
                {
                    value: '16',
                    description: 'Notificação por localização'
                },

            ],
            veiculosDoBanco:[],

            // segundo campo,
            // overlay
            mostrarOverlaySegundoPasso:true,
            // velocidade

            valueInputVelocidadeVeiculo:'',

            // monitoramento de pontos
            valueSelectPontosMonitorarModal:[],
            optMonitorarPontos:[],
            checkboxSaidaPontosMonitorarModal:false,
            checkboxEntradaPontosMonitorarModal:false,

            // monitoramento de regioes
            valueSelectRegioesMonitorarModal:[],
            optRegioesMonitorar:[],
            checkboxEntradaRegioesSelecionarModal:'',
            checkboxSaidaRegioesSelecionarModal:'',

            // temperatura minima e maxima
            valueInputTemperaturaMinimo:'',
            valueInputTemperaturaMaximo:'',

            // bateria fraca
            valueInputBateriaFraca:'',

            // tempo parado
            valueInputTempoParado:'',

            // temperatura do motor/Temperaturas acima de:
            valueTemperaturaMotor:null,

            // Notificacao por localização
            enderecosMapa:[],
            mostraSeletorLocalMapa:true,
            loadingEndereco: false,
            semEnderecos:false,
            valueInputSearchMapa: '',
            valueLatitude:0,
            valueLongitude:0,
            valueLatitudeMatriz:0,
            valueLongitudeMatriz:0,
            valueRaio: 50,
            paradas:false,
            centroDoCirculo:[0,0],
            dataValidade: '',
            editandoPonto: false,

            // terceiro passo
            mostrarOverlayTerceiroPasso:true,

            // quarto passo
            mostrarOverlayQuartoPasso:true,
            valueButtonQuartoPasso:[],
            valueInicioDiaDaSemana:'',
            valueFimDiaDaSemana:'',

            // quinto passo
            mostrarOverlayQuintoPasso:true,
            valueEmailNotificaoModal:[],
            optEmailModal:[],
            valueEmailsAdicionais:'',

            valueTelegramNotificaoModal:[],
            optTelegramModal:[],

            //
            valueButtonPortas:[],
            valueVeiculos:[],
            valueGrupoVeiculos:[],
            grupoVeiculoDoBanco:[],

            selectedEmpresaModal:[],
            selectedNotificacaoModal:[],

            loadingEmpresaModal:false,
            disabledEmpresaModal:false,
            loadingNotificacaoModal:false,
            disabledNotificacaoModal:false,

            valueEmpresaForaModal:[],
            selectedVeiculos:[],
            selectedGrupoVeiculos:[],
            selectedEmail:[],
            selectedTelegram:[],
            selectedRegioes:[],
            selectedPontos:[],

            idAtual:'',

            // Excesso velocidade região
            valueSelectMonitorarVelocidadeRegiao:[],
            valueVelocidadeRegiao:'',
            selectedExcessoRegioes:[],

            // Programação ou Vencimento da manutenção
            checkboxManutencaoProgramar:false,
            checkboxManutencoesVencidas:false,
            mensagemErro:[],
            erro:[],
        }
    },

    watch: {
        
        valueLatitude:function() {
            this.centroDoCirculo = [Number(this.valueLatitude), Number(this.valueLongitude)]
        },

        valueLongitude:function() {
            this.centroDoCirculo = [Number(this.valueLatitude), Number(this.valueLongitude)]
        },

        mostrarOverlayTerceiroPasso:function(){
            if(this.$refs.srefEnviaNotTelegram != undefined){
                this.$refs.srefEnviaNotTelegram.clearAll()
            }
            if(this.$refs.srefEnviaNotEmail != undefined){
                this.$refs.srefEnviaNotEmail.clearAll()
            }
            this.limpaDiasSemana()
            if(this.$refs.srefSelectAllVeiculos != undefined){
                this.$refs.srefSelectAllVeiculos.clearAll()
            }
            if(this.$refs.srefSelectAllGruposVeiculos != undefined){
                this.$refs.srefSelectAllGruposVeiculos.clearAll()
            }
            this.mostrarOverlayQuartoPasso = true
        },

        mostrarOverlayQuartoPasso:function(){
            this.limpaDiasSemana()
            if(this.$refs.srefEnviaNotTelegram != undefined){
                this.$refs.srefEnviaNotTelegram.clearAll()
            }
            if(this.$refs.srefEnviaNotEmail != undefined){
                this.$refs.srefEnviaNotEmail.clearAll()
            }
            this.buttonsFiltersChangeQuartoPasso([])
            this.valueInicioDiaDaSemana = ''
            this.valueFimDiaDaSemana    = ''
            this.mostrarOverlayQuintoPasso = true
        },
    },

    methods:{
        ...mapGetters(['getCadastrar', 'getMaster']),

        changeSlider(value){
            this.valueRaio = value
        },

        changeDataValidade(value) {
            this.dataValidade = value
        },

        /**
         *  @listens drag - Emite evento quando o marker é arrastado
         *  @description Método altera latitude e longitude dos campos
         *  @param {object} value - valores de latitude e longitude a serem alterados
         *  @author Vitor Hugo 🐨
        */
        dragMarker(value){
            this.valueLatitude  = value['latlng'].lat
            this.valueLongitude = value['latlng'].lng
            this.centroDoCirculo = [Number(value['latlng'].lat), Number(value['latlng'].lng)]
        },

        /**
         *  @listens dragend - Emite evento quando o marker é solto
         *  @description Métodobusca o endereço a partir da latitude e longitude
         *  @author Vitor Hugo 🐨
        */
        dragEnd(){
            new HttpRequest().Post(this.url+'endereco/proximo',
                {'local':[this.valueLatitude, this.valueLongitude]})
                .then(dados=>{
                this.valueInputSearchMapa = dados.data
                this.verificaEndereco()
            })
        },

        async editarCadastro(value){
            this.idAtual = value
            this.loadingEmpresaModal = true
            this.disabledEmpresaModal = true
            this.loadingNotificacaoModal = true
            this.disabledNotificacaoModal = true
            this.$bvModal.show('modalEagle')
            var consulta = await new HttpRequest()
                .Post(this.url+'buscar/info/not', {'id':value})
            this.$nextTick(async () => {
                await this.arrumaPrimeiroPasso(consulta)
                this.loadingEmpresaModal = false
                this.disabledEmpresaModal = false
                this.loadingNotificacaoModal = false
                this.disabledNotificacaoModal = false
            })
        },

        /**
         * Recebe o valor da consulta e
         * seta qual a empresa da notificacao
         * e qual a notificacao criada
        */
        arrumaPrimeiroPasso(consulta) {
            this.valueEmpresaModal = consulta.data.notificacao.nocliente
            this.valueNotificacaoCriada = consulta.data.tipo.value
            const empresaPrincipalSalva = consulta.data.empresa.length == 0
            if (empresaPrincipalSalva)
                this.selectedEmpresaModal = this.optEmpresa
            else
                this.selectedEmpresaModal = (consulta.data.empresa)
            this.selectedNotificacaoModal = [consulta.data.tipo]
            this.$nextTick(async () =>{
                await this.arrumaSegundoPassoETerceiroPasso(consulta)
            })
        },

        async arrumaSegundoPassoETerceiroPasso(consulta) {
            if(consulta.data.parametros.parametro == 'regioes'){
                this.selectedRegioes = consulta.data.parametros.consulta
                this.valueSelectRegioesMonitorarModal = consulta.data.parametros.consulta.value
                this.checkboxSaidaRegioesSelecionarModal    = consulta.data.parametros.saida
                this.checkboxEntradaRegioesSelecionarModal  = consulta.data.parametros.entrada
            }
            if(consulta.data.parametros.parametro == 'pontos'){
                this.selectedPontos = await consulta.data.parametros.consulta
                this.valueSelectPontosMonitorarModal = await consulta.data.parametros.consulta.value
                this.checkboxSaidaPontosMonitorarModal    = await consulta.data.parametros.saida
                this.checkboxEntradaPontosMonitorarModal  = await consulta.data.parametros.entrada
            }
            if(typeof(consulta.data.parametros.velocidade) != 'undefined'){
                this.valueInputVelocidadeVeiculo = await Number(consulta.data.parametros.velocidade)
            }
            if(typeof(consulta.data.parametros.voltagem) != 'undefined'){
                // bug dos veiculos estao vindo nulos
                this.valueInputBateriaFraca = await consulta.data.parametros.voltagem
            }
            if(consulta.data.notificacao.notipo == 'porta'){
                var porta = await JSON.parse(consulta.data.notificacao.noparametros)
                for (const index in this.arrayButtonsModalPortas) {
                    this.arrayButtonsModalPortas[index].isSelected = false
                }
                this.arrayButtonsModalPortas[porta.porta-1].isSelected = true
            }
            if(consulta.data.notificacao.notipo == 'temperatura'){
                var temperaturas = await JSON.parse(consulta.data.notificacao.noparametros)
                this.valueInputTemperaturaMaximo = await temperaturas.maximo
                this.valueInputTemperaturaMinimo = await temperaturas.minimo
            }
            if(consulta.data.notificacao.notipo == 'tempoParado'){
                var tempoParado = await JSON.parse(consulta.data.notificacao.noparametros)
                this.valueSelectPontosMonitorarModal = await tempoParado.pontos
                this.valueInputTempoParado = await consulta.data.parametros.tempo
            }
            if(consulta.data.notificacao.notipo == 'excessoVelocidadeRegiao'){
                this.selectedExcessoRegioes = await consulta.data.parametros.consulta
                this.valueVelocidadeRegiao = await Number(consulta.data.parametros.velocidade)
            }
            if(typeof(consulta.data.parametros.programar) != 'undefined'){
                this.checkboxManutencaoProgramar = consulta.data.parametros.programar
            }
            if(typeof(consulta.data.parametros.vencidas) != 'undefined'){
                this.checkboxManutencoesVencidas = consulta.data.parametros.vencidas
            }
            if (consulta.data.notificacao.notipo == "temperaturaMotor") {
                this.valueTemperaturaMotor = consulta.data.parametros.temperatura
            }
            if(consulta.data.notificacao.notipo == "notificacaoPorLocalizacao") {
                this.valueLatitude = (consulta.data.parametros.latitude)
                this.valueLongitude = (consulta.data.parametros.longitude)
                let param = await JSON.parse(consulta.data.notificacao.noparametros)
                this.paradas = param.parada
                this.changeSlider(param.raio)
                this.valueInputSearchMapa = consulta.data.endereco
            }
            this.mostrarOverlaySegundoPasso  = await false
            this.mostrarOverlayTerceiroPasso = await false
            this.selectedVeiculos = await consulta.data.veiculo
            this.valueVeiculos = await consulta.data.veiculo.value
            this.selectedGrupoVeiculos = await consulta.data.grupo
            this.valueGrupoVeiculos = await consulta.data.grupo
            this.$nextTick(async ()=>{
                await this.arrumaQuartoPasso(consulta)
            })
        },

        /**
         *  @listens clickButtonSearch - Emite evento quando botão de
         *      pesquisa do input é pressionado
         *  @listens keyupEnter - Emite evento quando pressionado
         *      o botão enter no input
         *  @description Método para buscar os endereços relacionados a pesquisa
         *  @param {string} value - valor informado no input
         *  @author Vitor Hugo 🐨
         */
        clickButtonSearchMapa(value){
            this.loadingEndereco = true
            new HttpRequest().Post(this.url+'endereco',
                {'local':value})
                .then((dados) => {
                this.enderecosMapa = dados.data.enderecos
                if(!this.enderecosMapa)
                     this.semEnderecos = true
                else
                    this.semEnderecos = false
                this.mostraSeletorLocalMapa = true
            }).finally(() => { this.loadingEndereco = false })
        },


        /** @listens click - Emitido quando clicado na lista do mapa
         *  @description Método para adicionar valores as variáveis do mapa
         *  @param {object} value - valores com latitude e endereço
         *  @author Vitor Hugo 🐨
        */
         clickListaEnderecoMapa(value){
                this.$refs.mapaEndereco?.flyTo(
                    [value.lat,value.lon]
                )
            this.valueInputSearchMapa = value.end
            this.valueLatitude = value.lat
            this.valueLongitude = value.lon
            this.centroDoCirculo = [Number(value.lat), Number(value.lon)]
            this.mostraSeletorLocalMapa = false
            this.verificaEndereco()
        },


        arrumaQuartoPasso(consulta){
            this.mostrarOverlayQuartoPasso = false
            var horas = consulta.data.notificacao
            var diasSemana = consulta.data.notificacao.nodiassemana.split(',')
            var cont = 0
            for (const index in this.arrayButtonsDiaDaSemana){
                if(this.arrayButtonsDiaDaSemana[index].value == diasSemana[cont]){
                    this.arrayButtonsDiaDaSemana[index].isSelected = true
                    cont++
                }else{
                    this.arrayButtonsDiaDaSemana[index].isSelected = false
                }
            }
            this.valueButtonQuartoPasso = [diasSemana]
            this.valueInicioDiaDaSemana = moment(horas.nohorainicio, 'HH:mm').format('HH:mm')
            this.valueFimDiaDaSemana = moment(horas.nohorafim, 'HH:mm').format('HH:mm')
            if(consulta.data.notificacao?.novalidade){
                let dataValidade = consulta.data.notificacao.novalidade.split(' ')[0].split('-')
                    .reverse().join('/')
                this.changeDataValidade(dataValidade)
            }
            this.$nextTick(async () => {
                await this.arrumaQuintoPasso(consulta)
            })
        },

        arrumaQuintoPasso(consulta){
            this.selectedEmail      = consulta.data.email
            this.selectedTelegram   = consulta.data.telegram
            this.valueEmailsAdicionais = consulta.data.notificacao.noemailadicionais ?
                consulta.data.notificacao.noemailadicionais : ''
        },

        async excluirCadastro(value){
            var consulta = await new HttpRequest()
                .Post(this.url+'excluir', {'notificacao':value})
            this.changeSelectEmpresa(this.valueEmpresaForaModal)
        },

        modalOpen(){
            this.editandoPonto = false
            this.mostrarOverlaySegundoPasso  = true
            this.valueNotificacaoCriada = []
            this.limpaValuesAll()
            this.limpaValuesVar()
        },

        /**
         * Limpa todas as varaveis que existem, sem isso fica dando bugs no valores e quando salva
         */
        limpaValuesVar() {
            this.valueTemperaturaMotor = null
            this.selectedEmail = []
            this.valueVeiculos = []
            this.selectedPontos = []
            this.selectedRegioes = []
            this.selectedVeiculos = []
            this.selectedTelegram = []
            this.valueButtonPortas = ''
            this.valueEmpresaModal = ''
            this.valueGrupoVeiculos = ''
            this.valueFimDiaDaSemana = ''
            this.selectedEmpresaModal = []
            this.valueVelocidadeRegiao = ''
            this.selectedGrupoVeiculos = []
            this.valueInputTempoParado = ''
            this.valueButtonQuartoPasso = ''
            this.valueInicioDiaDaSemana = ''
            this.valueNotificacaoCriada = []
            this.valueInputBateriaFraca = ''
            this.selectedExcessoRegioes = []
            this.valueEmailNotificaoModal = ''
            this.selectedNotificacaoModal = []
            this.valueTelegramNotificaoModal = ''
            this.valueInputVelocidadeVeiculo = ''
            this.valueInputTemperaturaMaximo = ''
            this.valueInputTemperaturaMinimo = ''
            this.valueSelectPontosMonitorarModal = ''
            this.valueSelectRegioesMonitorarModal = ''
            this.checkboxEntradaPontosMonitorarModal = false
            this.checkboxSaidaRegioesSelecionarModal = false
            this.checkboxSaidaRegioesSelecionarModal = false
            this.valueSelectMonitorarVelocidadeRegiao = []
            this.checkboxEntradaRegioesSelecionarModal = false
            this.checkboxManutencaoProgramar = false
            this.checkboxManutencoesVencidas = false
            this.erro = false
            this.enderecosMapa = []
            this.mostraSeletorLocalMapa = true
            this.loadingEndereco =  false
            this.semEnderecos = false
            this.valueInputSearchMapa =  ''
            this.valueLatitude = 0
            this.valueLongitude = 0
            this.valueRaio =  50
            this.paradas = false
            this.centroDoCirculo = [0,0]
            this.dataValidade =  ''
        },

        async confirmButtonModal(){
            this.loadingPanelModal = true
            var dados = {
                "execessoVelocidade":this.valueInputVelocidadeVeiculo,        // ops
                "pontos":this.valueSelectPontosMonitorarModal,                // ops
                "entradaPontos":this.checkboxEntradaPontosMonitorarModal,     // ops
                "saidaPontos":this.checkboxSaidaRegioesSelecionarModal,       // ops
                "regioes":this.valueSelectRegioesMonitorarModal,              // ops
                "entradaRegiao":this.checkboxEntradaRegioesSelecionarModal,   // ops
                "saidaRegiao":this.checkboxSaidaRegioesSelecionarModal,       // ops
                "temperaturaMax":this.valueInputTemperaturaMaximo,            // ops
                "temperaturaMin":this.valueInputTemperaturaMinimo,            // ops
                "bateria":this.valueInputBateriaFraca,                        // ops
                "portas":this.valueButtonPortas,                              // ops
                "veiculos":this.valueVeiculos,                                // obr                           1
                "grupoVeiculos":this.valueGrupoVeiculos,                      // obr                           1
                "diaDaSemana":this.valueButtonQuartoPasso,                    // obr                           3
                "inicioDiaDaSemana":this.valueInicioDiaDaSemana,              // obr                           4
                "fimDiaDaSemana":this.valueFimDiaDaSemana,                    // obr                           5
                "email":this.valueEmailNotificaoModal,                        // obr (pelo menos 1 dos campos) 6
                "telegram":this.valueTelegramNotificaoModal,                  // obr                           7
                "empresa":this.valueEmpresaModal,                             // obr                           8
                "notificacao":this.valueNotificacaoCriada,                    // obr                           9
                "emailAdicionais":this.valueEmailsAdicionais,
                "tempoParado":this.valueInputTempoParado,
                "excessoVelocidadeRegiao":this.valueVelocidadeRegiao,
                "regioesMonitorar":this.valueSelectMonitorarVelocidadeRegiao,
                "manutencaoProgramar": this.checkboxManutencaoProgramar,
                "manutencaoVencida": this.checkboxManutencoesVencidas,
            }
            var retorno =  this.verificaValidade(dados)
            retorno.push({'id':this.idAtual})
            if (retorno[0]) {
                    let objRequisicao = { 'dados' : retorno }
                    new HttpRequest()
                        .Post(this.url+'salvar', objRequisicao)
                        .then(() => {
                            this.$bvModal.hide('modalEagle')
                            this.changeSelectEmpresa(this.valueEmpresaForaModal)
                        })
                        .catch(() => {
                            conectionError()
                        }).finally(() => {
                            setTimeout(() => {
                                this.loadingPanelModal = false
                            }, 500);
                        })

            } else {
                conectionError("Você possui valores inválidos, os verifique novamente.")
            }
        },

        cancelButtonModal(){
            this.$bvModal.hide('modalEagle')
        },

        /**
         * campos no select
         * 1 = Excesso de velocidade por veiculo ou grupo de veiculos
         * 2 = Entrada ou saída de ponto
         * 3 = Entrada ou saída de Região
         * 4 = Pânico acionado
         * 5 = Temperatura
         * 6 = Bateria removida
         * 7 = Bateria fraca
         * 8 = Funcionamento em hórario proibido
         * 9 = Abertura de porta fora de ponto
         * 10 = Item do checklist em desacordo
         * 11 = Tempo parado em ponto
         * 12 = Excesso de velocidade em região
         * 13 = Programação ou Vencimento da manutenção
         * 14 = Bloqueio de sinal
         * 15 = Temperatura do motor
         * 16 = Notificacao por localizacao
         */
        verificaValidade(value) {
            var controleVer = false
            var retorno = {
                nocliente:value.empresa,
                notipo:'',
                noveiculos:value.veiculos.toString(),
                nogrupos:value.grupoVeiculos.toString(),
                nodiassemana:value.diaDaSemana.toString(),
                nohorainicio:value.inicioDiaDaSemana.toString(),
                nohorafim:value.fimDiaDaSemana.toString(),
                noemails:value.email.toString(),
                notelegrams:value.telegram.toString(),
                noemailadicionais:value.emailAdicionais.toString(),
                noparametros:'{}',
                novalidade: this.dataValidade
            }
            if(value.email.length > 0 || value.telegram.length > 0){
                switch (value.notificacao[0]){
                    case '1':
                        if(value.execessoVelocidade > 0 && value.execessoVelocidade < 999 && value.execessoVelocidade != ''){
                            retorno.notipo = 'velocidade'
                            retorno.noparametros = `{"velocidade":${value.execessoVelocidade.toString()}}`
                            controleVer = true
                        }else{
                            controleVer = false
                        }
                        break;
                    case '2':
                        if(value.pontos.length > 0 && (this.checkboxEntradaPontosMonitorarModal || this.checkboxSaidaPontosMonitorarModal)){
                            retorno.notipo = 'pontos'
                            retorno.noparametros = `{"pontos":[${value.pontos.toString()}], "entrada":${this.checkboxEntradaPontosMonitorarModal}, "saida":${this.checkboxSaidaPontosMonitorarModal}}`
                            controleVer = true
                        }else{
                            controleVer = false
                        }
                        break;
                    case '3':
                        if(value.regioes.length > 0 && (value.entradaRegiao || value.saidaRegiao)){
                            retorno.notipo = 'regioes'
                            retorno.noparametros =`{"regioes":[${value.regioes.toString()}], "entrada":${value.entradaRegiao}, "saida":${value.saidaRegiao}}`
                            controleVer = true
                        }else{
                            controleVer = false
                        }
                        break;
                    case '4':
                        if(value.veiculos.length > 0 || value.grupoVeiculos.length > 0){
                            retorno.notipo = 'panico'
                            retorno.noparametros = `{}`
                            controleVer = true
                        }else{
                            controleVer = false
                        }
                        break;
                    case '5':
                        if(value.temperaturaMax > value.temperaturaMin){
                            retorno.notipo = 'temperatura'
                            retorno.noparametros = `{"minimo":${value.temperaturaMin}, "maximo":${value.temperaturaMax}}`
                            controleVer = true
                        }else{
                            controleVer = false
                        }
                        break;
                    case '6':
                        if(value.veiculos.length > 0 || value.grupoVeiculos.length > 0){
                            retorno.notipo = 'bateriaDesconectada'
                            controleVer = true
                        }else{
                            controleVer = false
                        }
                        break;
                    case '7':
                        if(Number(value.bateria) > 0){
                            retorno.notipo = 'bateriaFraca'
                            retorno.noparametros = `{"voltagem": ${value.bateria}}`
                            controleVer = true
                        }else{
                            controleVer = false
                        }
                        break;
                    case '8':
                        if(value.veiculos.length > 0 || value.grupoVeiculos.length > 0){
                            retorno.notipo = 'funcionamentoForaHorario'
                            controleVer = true
                        }else{
                            controleVer = false
                        }
                        break;
                    case '9':
                        if(value.portas.length > 0){
                            retorno.notipo = 'porta'
                            retorno.noparametros = `{"porta":${value.portas}}`
                            controleVer = true
                        }else{
                            controleVer = false
                        }
                        break;
                    case '10':
                        if(value.veiculos.length > 0 || value.grupoVeiculos.length > 0){
                            retorno.notipo = 'itemChecklistDesacordo'
                            controleVer = true
                        }else{
                            controleVer = false
                        }
                        break;
                    case '11':
                        if(value.pontos.length > 0 &&
                            value.tempoParado != undefined){
                            retorno.notipo = 'tempoParado'
                            retorno.noparametros = `{"pontos":[${value.pontos.toString()}], "tempo":"${value.tempoParado}"}`
                            controleVer = true
                        }else{
                            controleVer = false
                        }
                        break;
                    case '12':
                        if(value.regioesMonitorar.length > 0 &&
                            value.excessoVelocidadeRegiao != undefined && value.excessoVelocidadeRegiao > 10){
                            retorno.notipo = 'excessoVelocidadeRegiao'
                            retorno.noparametros = `{"regioes":[${value.regioesMonitorar.toString()}], "excesso":"${value.excessoVelocidadeRegiao}"}`
                            controleVer = true
                        }else{
                            controleVer = false
                        }
                        break;
                    case '13':
                        if (value.manutencaoProgramar || value.manutencaoVencida) {
                            retorno.notipo = 'manutencoes'
                            retorno.noparametros = `{"programar":${value.manutencaoProgramar}, "vencidas":${value.manutencaoVencida}}`
                            controleVer = true
                        } else {
                            controleVer = false
                        }
                        break;
                    case "14":
                        retorno.notipo = "bloqueioSinal"
                        retorno.noparametros = `{}`
                        controleVer = true
                        break;
                    case "15":
                        if (this.valueTemperaturaMotor) {
                            controleVer = true
                            retorno.notipo = "temperaturaMotor"
                            retorno.noparametros = `{"temperatura":${this.valueTemperaturaMotor}}`
                        } else {
                            controleVer = false
                        }
                        break;
                    case "16":
                        if (this.valueInputSearchMapa) {
                            controleVer = true
                            retorno.notipo = "notificacaoPorLocalizacao"
                            retorno.noparametros =`{"latitude":${this.valueLatitude},"longitude":${this.valueLongitude},"raio":${this.valueRaio},"parada":${this.paradas}}`
                        } else {
                            controleVer = false
                        }
                        break;
                    default:
                        controleVer = false
                        break;
                }
            }
            return [controleVer, retorno]
        },

        changeSelectEmpresaModal(value){
            if(this.$refs.refInputDasNotificacao)
                this.$refs.refInputDasNotificacao.clearAll()
            this.limpaValuesAll()
            this.valueEmpresaModal = value
            if(value.length > 0){
                this.mostrarOverlaySegundoPasso = false
                new HttpRequest().Post(this.url+'buscar', {'clientes':value})
                    .then((consulta) => {
                        this.optMonitorarPontos  = consulta.data.pontos
                        this.optRegioesMonitorar = consulta.data.regiao
                        this.veiculosDoBanco     = consulta.data.veiculos['V']
                        this.grupoVeiculoDoBanco = consulta.data.veiculos['GV']
                        this.optEmailModal       = consulta.data.emailEtelegram['email']
                        this.optTelegramModal    = consulta.data.emailEtelegram['telegram']
                    })
            }else{
                this.mostrarOverlaySegundoPasso = true
            }
        },

        // campo 2 notificacao
        changeSelectCriarNotificacaoModal(value) {
            this.valueNotificacaoCriada = value
            this.notificacaoPorLocalizacao()
            this.limpaValuesAll()
        },

        /**
         * Verfica Se a notificao é por localizacao e pega a latitude e long
         * da matriz. E caso o usuario troque o valo selecionado, traz o marker
         * de volta pra localização original
        */
        notificacaoPorLocalizacao() {
            const notificacaoPorLocalizacao = 16
            if (this.valueNotificacaoCriada[0] == notificacaoPorLocalizacao) {
                this.buscaLatLong();
            } else if (this.valueLatitude != this.valueLatitudeMatriz && this.idAtual == '') {
                this.valueInputSearchMapa = ''
                this.valueLatitude = this.valueLatitudeMatriz
                this.valueLongitude = this.valueLongitudeMatriz
            } else if (this.valueLatitude != this.valueLatitudeMatriz && this.editandoPonto) {
                this.valueInputSearchMapa = ''
                this.valueLatitude = this.valueLatitudeMatriz
                this.valueLongitude = this.valueLongitudeMatriz
            }
        },

        /**
         * Busca lat long da matriz da empresa principal do usuario
         *  e atribui ao mapa. Caso seja uma edição, ele apenas voa até
         * o local do ponto
        */
        buscaLatLong() {
            // this.valueLatitude = this.valueLatitudeMatriz
            // this.valueLongitude = this.valueLongitudeMatriz
            let obj = { id: this.idAtual }
            new HttpRequest().Post(this.url+'latLong', obj)
                .then((endereco) => {
                    this.valueLatitudeMatriz = Number(endereco.data.cllatitude)
                    this.valueLongitudeMatriz = Number(endereco.data.cllongitude)
                    const novoCadastro = this.idAtual == ''
                    if (novoCadastro) {
                        this.valueInputSearchMapa = endereco.data.endereco
                        this.valueLatitude = Number(endereco.data.cllatitude)
                        this.valueLongitude = Number(endereco.data.cllongitude)
                    } else {
                        setTimeout(() => {
                            this.editandoPonto = true
                            this.$refs.mapaEndereco?.flyTo(
                                [(this.valueLatitude),(this.valueLongitude)]
                            )
                        }, 1000)
                    }
                    this.verificaEndereco()
                })
        },

        /**
         * menu das refs
         * s = selectAll
         * c = checkbox
         * i = inputSimples
         * b = buttonsFilters
         */
        limpaValuesAll(){
            this.mostrarOverlayTerceiroPassoHabilitar = true
            this.mostrarOverlayTerceiroPasso = true
            this.mostrarOverlayQuartoPasso = true
            this.mostrarOverlayQuintoPasso = true
            this.valueVelocidadeRegiao = ''            
            for (var index in this.$refs){
                if(this.$refs[index] != undefined && index != 'mapaEndereco'){
                    if(index[0] == 's'){
                        this.$refs[index].clearAll()
                    }
                    if(index[0] == 'c'){
                        this.$refs[index].$data.localChecked = false
                    }
                    if(index[0] == 'i'){
                        this.$refs[index].$data.inputValue = ''
                    }
                    if(index[0] == 'b'){
                        for (const i in this.$refs[index].$data.dadosButtons){
                            this.$refs[index].$data.dadosButtons[i].isSelected = false
                        }
                        this.$refs[index].$data.valueButtons = ''
                    }
                }
            }
        },

        // segundo passo
        // campo 1 velocidade
        changeInputVelocidadeVeiculo(value){
            // this.$refs.srefSelectAllVeiculosEGrupos.clearAll()
            if(value != '' && Number(value) >= 10 && Number(value) <= 999){
                this.mostrarOverlayTerceiroPasso = false
            }else{
                this.mostrarOverlayTerceiroPasso = true
            }
            this.valueInputVelocidadeVeiculo = value
        },

        // campo 2
        changeSelectMonitorarPontos(value){
            this.valueSelectPontosMonitorarModal = value
            if(value.length > 0 && (this.checkboxEntradaPontosMonitorarModal || this.checkboxSaidaPontosMonitorarModal)){
                this.mostrarOverlayTerceiroPasso = false
            }else{
                this.mostrarOverlayTerceiroPasso = true
            }
        },

        changeCheckboxSelectPontosMonitoraEntrada(value){
            this.checkboxEntradaPontosMonitorarModal = value
            this.changeSelectMonitorarPontos(this.valueSelectPontosMonitorarModal)
        },

        changeCheckboxSelectPontosMonitoraSaida(value){
            this.checkboxSaidaPontosMonitorarModal = value
            this.changeSelectMonitorarPontos(this.valueSelectPontosMonitorarModal)
        },

        changeSelectMonitorarPontosTempo(value){
            this.valueSelectPontosMonitorarModal = value
            if(value.length > 0){
                this.mostrarOverlayTerceiroPasso = false
            }else{
                this.mostrarOverlayTerceiroPasso = true
            }
        },

        // campo 3
        changeSelectRegioesMonitorar(value){
            this.valueSelectRegioesMonitorarModal = value
            if(value.length > 0 && (this.checkboxEntradaRegioesSelecionarModal || this.checkboxSaidaRegioesSelecionarModal)){
                this.mostrarOverlayTerceiroPasso = false
            }else{
                this.mostrarOverlayTerceiroPasso = true
            }
        },

        changeCheckboxSelectRegioesSelecionaEntrada(value){
            this.checkboxEntradaRegioesSelecionarModal = value
            this.changeSelectRegioesMonitorar(this.valueSelectRegioesMonitorarModal)
        },

        changeCheckboxSelectRegioesSelecionaSaida(value){
            this.checkboxSaidaRegioesSelecionarModal = value
            this.changeSelectRegioesMonitorar(this.valueSelectRegioesMonitorarModal)
        },

        // campo 5
        changeInputTemperaturaMinima(value){
            this.valueInputTemperaturaMinimo = Number(value)
            this.verificaTemperatura()
        },

        changeInputTemperaturaMaxima(value){
            this.valueInputTemperaturaMaximo = Number(value)
            this.verificaTemperatura()
        },

        verificaTemperatura(){
            if(Number(this.valueInputTemperaturaMinimo) < Number(this.valueInputTemperaturaMaximo)){
                this.mostrarOverlayTerceiroPasso = false
            }else{
                this.mostrarOverlayTerceiroPasso = true
            }
        },

        // campo 6
        changeInputTempoVelocidade(value){
            this.valueInputTempoParado = value
            this.verificaTempoParado()
        },

        verificaTempoParado(){
            if(this.valueInputTempoParado != undefined &&
                this.valueSelectPontosMonitorarModal.length > 0){
                this.mostrarOverlayTerceiroPasso = false
            } else {
                this.mostrarOverlayTerceiroPasso = true
            }
        },

        // campo 7
        changeSelectMonitorarVelocidadeRegiao(value){
            this.valueSelectMonitorarVelocidadeRegiao = value
            this.verificaExcessoRegiao()
        },

        changeInputVelocidadeRegiao(value){
            this.valueVelocidadeRegiao = value
        },

        verificaEndereco() {
            if(this.valueInputSearchMapa && this.valueNotificacaoCriada[0] == 16){
                this.mostrarOverlayTerceiroPasso = false
            }
            else{
                this.mostrarOverlayTerceiroPasso = true
            }
        },

        verificaExcessoRegiao(){
            if(this.valueSelectMonitorarVelocidadeRegiao.length > 0){
                this.mostrarOverlayTerceiroPasso = false
            }else{
                this.mostrarOverlayTerceiroPasso = true
            }
        },

        // campo 8
        changeCheckboxManutencaoProgramar(value){
            this.checkboxManutencaoProgramar = value
            this.verificaManutencao()
        },

        changeCheckboxManutencoesVencidas(value){
            this.checkboxManutencoesVencidas = value
            this.verificaManutencao()
        },

        verificaManutencao(){
            if(this.checkboxManutencaoProgramar || this.checkboxManutencoesVencidas){
                this.mostrarOverlayTerceiroPasso = false
            }else{
                this.mostrarOverlayTerceiroPasso = true
            }
        },

        // quarto passo
        buttonsFiltersChangeQuartoPasso(value){
            this.valueButtonQuartoPasso = value
            this.tirarOverlayquintoPasso()
        },

        changeInputInicioDiaDaSemana(value){
            this.valueInicioDiaDaSemana = value
            this.tirarOverlayquintoPasso()
        },

        changeInputFimDiaDaSemana(value){
            this.valueFimDiaDaSemana = value
            this.tirarOverlayquintoPasso()
        },

        tirarOverlayquintoPasso(){
            this.mostrarOverlayQuintoPasso = true
            if(this.valueButtonQuartoPasso != undefined){
                var cond1 = this.valueButtonQuartoPasso.length > 0
                var cond2 = this.valueInicioDiaDaSemana != '' && this.valueFimDiaDaSemana != ''
                if(cond1 && cond2){
                    this.mostrarOverlayQuintoPasso = false
                }
            } else {
                if(this.valueInicioDiaDaSemana == '' && this.valueFimDiaDaSemana == ''){
                    if(this.$refs.srefEnviaNotTelegram != undefined){
                        this.$refs.srefEnviaNotTelegram.clearAll()
                    }
                    if(this.$refs.srefEnviaNotEmail != undefined){
                        this.$refs.srefEnviaNotEmail.clearAll()
                    }
                }
            }
        },

        limpaDiasSemana(){
            this.arrayButtonsDiaDaSemana[0].isSelected = false
            this.arrayButtonsDiaDaSemana[1].isSelected = false
            this.arrayButtonsDiaDaSemana[2].isSelected = false
            this.arrayButtonsDiaDaSemana[3].isSelected = false
            this.arrayButtonsDiaDaSemana[4].isSelected = false
            this.arrayButtonsDiaDaSemana[5].isSelected = false
            this.arrayButtonsDiaDaSemana[6].isSelected = false
        },

        // quinto passo
        changeSelectEnviarNotificacaoEmail(value){
            this.valueEmailNotificaoModal = value
        },

        changeSelectEnviarNotificacaoTelegram(value){
            this.valueTelegramNotificaoModal = value
        },

        /**
         * @description Adiciona uma (,) ao final de cada email digitado
         * @author Vitor Hugo
         */
        changeInputEmailsAdicionais(value){
            this.valueEmailsAdicionais = value.replaceAll(/([\w\d.]+)(?=\s{1})/gm , '$1,')
            if(this.valueEmailsAdicionais == '')
                this.mensagemErro = []
            this.erro = false
        },

        /**
         * @description Método para verificar se o email esta sendo inserido
         *  corretamente com (@) e (.), utiliza regex para fazer a verificação
         * @author Vitor Hugo
         */
        blurInputEmailsAdicionais(){
            var arrayEmails = this.valueEmailsAdicionais.split(' ')
            this.mensagemErro = []
            this.erro = false
            for (let index = 0; index < arrayEmails.length; index++) {
                var regex = /\S+@\S+\.\S+/
                if(arrayEmails[index] != '' && !regex.test(arrayEmails[index])){
                    this.mensagemErro.push(arrayEmails[index])
                    this.erro = true
                }
            }
        },

        changeSelectGrupoVeiculos(value){
            this.valueGrupoVeiculos = value
            if(value.length > 0){
                this.mostrarOverlayQuartoPasso = false
            }else{
                if(this.valueVeiculos == 0){
                    this.mostrarOverlayQuartoPasso = true
                }else{
                    this.mostrarOverlayQuartoPasso = false
                }
            }
        },

        changeSelectVeiculos(value){
            this.valueVeiculos = value
            if(value.length > 0){
                this.mostrarOverlayQuartoPasso = false
            }else{
                if(this.valueGrupoVeiculos == 0){
                    this.mostrarOverlayQuartoPasso = true
                }else{
                    this.mostrarOverlayQuartoPasso = false
                }
            }
        },

        // campo 6
        changeInputBateriaFraca(value){
            if(Number(value) < 100 && Number(value) > 0){
                this.valueInputBateriaFraca = value
                this.mostrarOverlayTerceiroPasso = false
            }else{
                this.mostrarOverlayTerceiroPasso = true
            }
        },

        // campo 9
        buttonsFiltersChangeModalPortas(value){
            this.valueButtonPortas = value
            if(value.length > 0){
                this.mostrarOverlayTerceiroPasso = false
            }else{
                this.mostrarOverlayTerceiroPasso = true
            }
        },

        changeTemperaturaMotor(value) {
            if(Number(value) <= 999 && Number(value) > 0){
                this.valueTemperaturaMotor = value
                this.mostrarOverlayTerceiroPasso = false
            }else{
                this.mostrarOverlayTerceiroPasso = true
            }
        },

        clickBotaoNovo(){
            this.limpaValuesVar()
            this.selectedEmpresaModal = this.optEmpresa
            this.idAtual = ''
            this.$bvModal.show('modalEagle')
            if(!this.getMaster()) {
                this.selectedEmpresaModal = this.optEmpresa
                let codigos = this.selectedEmpresaModal.map((obj) =>{
                    return obj.value
                })
                this.changeSelectEmpresaModal(codigos)
            }
        },

        async changeSelectEmpresa(value){
            this.valueEmpresaForaModal = value
            this.loadingPanel = true
            var dados =  await new HttpRequest()
                .Post(this.url+'listagem', {'clientes':value})
            this.dataTable = dados.data
            this.loadingPanel = false
        },
    },

    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresas = this.optEmpresa
        }
    },
})
</script>
<style lang="scss">
.mapa-input {
    max-width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
}
.marg-1px {
    margin: 1px !important;
}
.margin-top20px {
    margin-top: 20px;
}
.margin-bot-10px {
    margin-bottom: 10px;
}
.ajusta-display {
    // display:contents;
    display: inline-block;
}
.color-red {
    color: #e04b4a;
}
.input-search {
    padding: 1px !important;
    position: absolute;
    top: -2.3px;
}
.seletor-rua {
    position: absolute !important;
    padding: 1px !important;
    width: 100% !important;
    top: 53px !important;
    left: 0px;
}
.item:hover {
    box-shadow: 1px 1px #428bca;
}
.padd-seletor-rua {
    text-align: center;
    border: 1px solid #ffffff;
    background-color: #ffffff;
}
</style>
