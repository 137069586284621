<template>
    <panelEagle id="resumoFrota" :loading="loadingPanel">
        <div class="col-sm-12 nopadding ">
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiTrainCar" titulo="Resumo de frota" ></tituloPage>
                </div>
                <div class="col-sm-6 divDontPrint">
                    <basicButtonsRelatoriosEIG
                        :loading='loadingExportar'
                        :disabled="$v.$invalid"
                        @exportarRelatorio="exportarRelatorio" 
                        @gerarRelatorio="gerarRelatorio"
                        :disabledDropdown='dadosResumoFrota.length == 0 ? true : false'>
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                <div class="col-sm-12"><hr></div>
                    <div class="col-sm-12 row nopadding">
                        <div class="col-sm-3 row nopadding">
                            <div class="col-sm-12 nopadding">
                                <inputSingleDay 
                                    label="Data*"
                                    @changeInput="changeData" 
                                    id="dataResumoFrota" 
                                    name="dataResumoFrota">
                                </inputSingleDay>
                            </div>
                        </div>
                        <div class="col-sm-9 nopadding row">
                            <div class="col-sm-4 nopadding">
                                <selectAll
                                    :extraClass="{'border border-danger': $v.empresasArray.$anyError}"
                                    @close='$v.empresasArray.$touch()'
                                    @changeSelect="SelectEmpresas" 
                                    :isMultiple="true" 
                                    nameForRadio="radiosEmpresas" 
                                    :labels="labelsSelectEmpresa" 
                                    :optionsArray="optionsSelectEmpresa" 
                                    firstSelected="E" 
                                    :selected="selectedEmpresa"
                                    :hasSelectAll="true">
                                </selectAll>
                            </div>
                            <div class="col-sm-4 nopadding">
                                <selectAll
                                    :extraClass="{'border border-danger': $v.veiculosArray.$anyError}"
                                    @close='$v.veiculosArray.$touch()'
                                    :loading="loadingVeiculos"
                                    @selectCriado="selectCriado" 
                                    @changeSelect="SelectVeiculos" 
                                    :isMultiple="true" 
                                    nameForRadio="radiosVeiculos" 
                                    :labels="labelsSelectVeiculos" 
                                    :optionsArray="optionsSelectVeiculos" 
                                    firstSelected="V" 
                                    :hasSelectAll="true">
                                </selectAll>
                            </div>
                            <div class="col-sm-4 nopadding">
                                <buttonsFilters 
                                    @buttonsFiltersChange="clickBotao" 
                                    :arrayButtons="arrayButtons" 
                                    label="Tempo parado maior que:" 
                                    :onlyOneSelected="true">
                                </buttonsFilters>
                            </div>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
        </div>
        <div class="col-sm-12"><hr></div>
        <tableRelatorio :htmlRel="htmlResumoFrota">
            <slot slot="thead">
                <tr>
                    <th>Placa</th>
                    <th>Prefixo</th>
                    <th>Descrição</th>
                    <th>Data/Hora Inicial</th>
                    <th>Data/Hora Final</th>
                    <th>Tempo parado (HH:MM:SS)</th>
                    <th>Km inicial</th>
                    <th>Km final</th>
                    <th>Km total</th>
                </tr>
            </slot>
            <slot slot="tbody">
                <br>
                <statusInformation 
                    typeBar="tr" 
                    :statusBar="statusbar">
                </statusInformation>
            </slot>
        </tableRelatorio>
    </panelEagle>
</template>
<script>
    import Vue from 'vue'
    import {EmpresasService} from '@/Services/auth/Empresas.service'
    import {HttpRequest} from '@/Services/auth/HttpRequest.Service'
    import {FiltrosService} from '@/Services/filtros/filtros.Service'
    import { required } from 'vuelidate/lib/validators'
    import {mdiTrainCar } from '@mdi/js'
    import { mapGetters } from 'vuex'

    export default Vue.extend({
        name:'ResumoFrota',
        validations:{
            empresasArray:{ required },
            veiculosArray:{ required },
        },
        components:{
            'panelEagle' : require('@/components/Atom/Panel/PanelEagle').default,
            'tituloPage' : require('@/components/Atom/Header/Titulo').default,
            'selectAll' : require('@/components/Atom/Select/SelectAll').default,
            'tableRelatorio' : require('@/components/Atom/Table/TableRelatorio').default,
            'inputSingleDay' : require('@/components/Atom/Datas/InputSingleDay').default,
            'buttonsFilters' : require('@/components/Atom/Buttons/ButtonsFilters').default,
            'slideUpAndDown' : require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
            'statusInformation' : require('@/components/Atom/StatusInformation/StatusInformation').default,
            'basicButtonsRelatoriosEIG' : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
        },
        data(){
            return{
                mdiTrainCar:mdiTrainCar,
                loadingPanel:false,
                loadingVeiculos:false,
                labelsSelectEmpresa:[
                    {indexDFH: 'E', description: 'Empresa *'},
                ],
                optionsSelectEmpresa: new EmpresasService().Get(),
                labelsSelectVeiculos:[
                    {indexDFH: 'GV', description: 'Grupo de Veiculos*'},
                ],
                statusbar: 'info',
                optionsSelectVeiculos: [],
                empresasArray:[],
                veiculosArray:[],
                veiculosSelect: [],
                tempoSelecionado: '',
                dadosResumoFrota:[],
                dataSelecionada:'',
                tamanhoArrayResumoFrota: 0,
                htmlResumoFrota:'',
                arrayButtons:[
                    {'value':'1', 'title':'', 'width': '30%', 'text': '1 min', 'isSelected':true},
                    {'value':'5', 'title':'', 'width': '30%', 'text': '5 min', 'isSelected':false},
                    {'value':'10', 'title':'', 'width': '30%', 'text': '10 min', 'isSelected':false},
                    {'value':'15', 'title':'', 'width': '30%', 'text': '15 min', 'isSelected':false},
                    {'value':'30', 'title':'', 'width': '30%', 'text': '30 min', 'isSelected':false},
                    {'value':'60', 'title':'', 'width': '30%', 'text': '60 min', 'isSelected':false},
                ],
                selectedEmpresa: [],
                loadingExportar:[false, false, false],
            }
        },
        methods:{
            ...mapGetters(['getMaster']),

            SelectEmpresas(event){
                this.empresasArray = event;
                this.veiculosSelect.clearAll();
                this.dados_filtros();
            },
            SelectVeiculos(event){
                this.veiculosArray = event;
            },
            selectCriado(select){
                this.veiculosSelect = select;
            },
            clickBotao(event){
                this.tempoSelecionado = event;
            },
            changeData(event){
                this.dataSelecionada = event;
            },
            async dados_filtros(){
                this.loadingVeiculos = true
                var dados = [];
                dados = await new FiltrosService().dados_filtros(this.empresasArray, ['GV'])
                this.optionsSelectVeiculos = dados['GV'];
                this.loadingVeiculos = false
            },
            async gerarRelatorio(){
                this.loadingPanel = true          
                if(this.empresasArray.length > 0 && this.veiculosArray.length > 0 && this.tempoSelecionado.length > 0 && this.dataSelecionada != ''){
                    this.htmlResumoFrota = '';
                    this.dadosResumoFrota = [];
                    this.statusbar = 'info';
                    var data = await new HttpRequest()
                        .Post('/telemetria/relatorios/resumo/frota/gerar', 
                            {
                                'grupos':this.veiculosArray, 
                                'tempo': this.tempoSelecionado, 
                                'data': this.dataSelecionada, 
                                'vue': true
                            });
                    var dados = data.data;
                    this.tamanhoArrayResumoFrota = dados.tamanho
                    if(dados.tamanho > 0){
                        this.dadosResumoFrota = dados.resumofrotas
                        this.htmlResumoFrota = '';
                        this.montaRelatorio(dados.resumofrotas);
                    }else{
                        this.htmlResumoFrota = '';
                        this.dadosResumoFrota = [];
                        this.statusbar = 'error'
                    }
                }
                this.loadingPanel = false
            },
            montaRelatorio(dados){
                var html = ''
                for(var a in dados){
                    html += '<tr class="backColorTdPDF stayTh"><th colspan="9" class="nopadding tdColor"><span class="newbadge">'+a+'</span></th></tr>'
                    for(var b in dados[a]){
                            html += '<tr class="headerRegisterPDF">'
                                html += '<td class="tdDados">'+dados[a][b].placa+'</td>'
                                html += '<td class="tdDados">'+dados[a][b].prefix+'</td>'
                                html += '<td class="tdDados"><span>'+dados[a][b].carro+'</span></td>'
                                if(dados[a][b].HoraInicial != false){
                                    html += '<td class="tdDados"><span>'+dados[a][b].HoraInicial+'</span></td>'
                                }else{
                                    html += '<td class="tdDados"><span>00:00</span></td>'
                                }
                                if(dados[a][b].HoraFinal != false){
                                    html += '<td class="tdDados"><span>'+dados[a][b].HoraFinal+'</span></td>'
                                }else{
                                    html += '<td class="tdDados"><span>00:00</span></td>'
                                }
                                html += '<td class="tdDados"><span>'+dados[a][b].TempoParado+'</span></td>'
                                html += '<td class="tdDados"><span>'+dados[a][b].KmSaida+'</span></td>'
                                html += '<td class="tdDados"><span>'+dados[a][b].KmFinal+'</span></td>'
                                html += '<td class="tdDados"><span>'+dados[a][b].KmTotal+'</span></td>'
                            html += '</tr>'
                    }
                }
                this.htmlResumoFrota = html;
            },
            async exportarRelatorio(tipo){
                this.setLoading(tipo)
                var root =  process.env.VUE_APP_ROOT;
                if(this.tamanhoArrayResumoFrota > 0){
                    var data = await new HttpRequest()
                        .Post('/telemetria/relatorios/resumo/frota/exportar', 
                            {
                                'arrayDados':JSON.stringify(this.dadosResumoFrota), 
                                'tipo':tipo
                            });
                    window.open(root+'/'+data.data.local);
                    this.setLoading()
                }
            },

            setLoading(tipo=null){
                let arr = [false, false, false]
                if(tipo=='pdf'){
                arr[0] = true;
                }else if(tipo=='xls'){
                arr[1] = true;
                }else if(tipo=='csv'){
                arr[2] = true;
                }
                this.loadingExportar =  arr;
            },
        },

        mounted() {
            if(!this.getMaster()){
                this.selectedEmpresa = this.optionsSelectEmpresa
            }
        }
    })
</script>

<style lang="scss">
    #resumoFrota{
        .paddingLeftRight{
            padding-right: 0px!important;
            padding-left: 30px!important;
        }
    
        .blueIconLocalizar{
            color: #428bca;
            cursor: pointer;
        }
    }
</style>