<template>
    <panelEagle id='cadastroParametros'
        :loading="loadingPanel">
        <div class="col-sm-12 nopading row">
            <div class="col-sm-6 nopading pl-0">
                <tituloPage :icon="mdiAccountClock" titulo='Parâmetros'></tituloPage>
            </div>
            <div class="col-sm-6 nopading pr-0">
                <basicButtonsCadastrosSC
                    tipoCancela='button'
                    linkCancel='listagemParametros' 
                    @salvarCadastro="salvar"
                    :disabled="$v.$invalid"
                />
                 <!-- || validationTable -->
            </div>
        </div>
        <div class="col-sm-12 col-md-12"><hr></div>
        <div class="col-sm-12 row tamanho-100">
            <tabsEagle :tabs="tabsPagina" 
                class="col-sm-12">
                <!-- PRIMEIRA TAB -->
                <slot slot="tab-horarios">
                    <br>
                    <!-- BOTÕES DE FILTRO -->
                    <div class="row nopadding ">
                        <div class="col-sm-4 margin-top-10px nopadding">
                            <inputSimple
                                label="Descrição*"
                                class="pl-0"
                                :inputClass='{
                                    "border border-danger":
                                    $v.dadosJornada.jtdescricao.$anyError
                                }'
                                :value="dadosJornada.jtdescricao"
                                @blur='$v.dadosJornada.jtdescricao.$touch()'
                                @changeInput="changeDescricao"
                                />
                        </div>
                        <div class="col-sm-4 margin-top-10px nopadding">
                            <selectAll
                                nameForRadio="Empresas"
                                class="pr-0"
                                :isMultiple="false"
                                :optionsArray="optEmpresa"
                                :extraClass='{
                                    "border border-danger":
                                    $v.dadosJornada.jtcliente.$anyError
                                }'
                                :labels="[{indexDFH:'EM', description: 'Empresas*'}]"
                                :selected="selectEmpresa"
                                @close='$v.dadosJornada.jtcliente.$touch()'
                                @changeSelect="changeEmpresas"
                                />
                        </div>
                        <div class="col-sm-4 row pr-0">
                            <buttonsFilters
                                label="Status" 
                                class="nopadding"
                                :onlyOneSelected='true'
                                :arrayButtons='filtroStatus'
                                @buttonsFiltersChange='mudaFiltroStatus' 
                            />
                        </div>
                        <div class="col-sm-12 row margin-top-10px nopadding mt-3">
                            <!-- TABELA DAS CHECKBOX -->
                            <b-form-group 
                            class="col-sm-12 pl-0 pr-0" 
                            v-slot="{ ariaDescribedby }"
                            >
                                <div class="col-sm-12 nopadding">
                                    <table class="col-sm-12">
                                        <thead>
                                            <tr class="headerTable">
                                                <th>Dias trabalhados</th>
                                                <th>Dia da semana</th>
                                                <th>1° Entrada</th>
                                                <th>1° Saída</th>
                                                <th>2° Entrada</th>
                                                <th>2° Saída</th>
                                                <th>Intervalo</th>
                                                <th>Limite extra</th>
                                                <th>DSR</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(tabela, index) in dadosTabela" 
                                                :key="index"
                                                class="bodyTable">
                                                 <td class="espaco-dsr">
                                                    <b-form-checkbox
                                                        :disabled="tabela.hjtdsr == 1? true : false"
                                                        @change="changeCheckboxTrabalho(index)"
                                                        :id="tabela.id"
                                                        v-model="checkboxTrabalho[index]"
                                                        :name="tabela.id"
                                                        :value="true"
                                                        :unchecked-value="false"/>
                                                </td>
                                                <td>
                                                    {{verificaDia(tabela.hjtdiasemana)}}
                                                </td>
                                                <!-- 1° Entrada -->
                                                <td>
                                                    <inputSimple
                                                        type="time"
                                                        class="pl-1 pr-1"
                                                        name="input1Entrada"
                                                        ref='refInput1Entrada'
                                                        :value="tabela.hjtiniprimeirot"
                                                        :disabled="tabela.hjtdsr == 1 ||
                                                            !checkboxTrabalho[index]"
                                                        @blur="insereHorariosEntrada1"
                                                        @changeInput="v => changeEntrada1(v, index)"
                                                    />
                                                </td>

                                                 <!-- 1° Saída -->
                                                <td>
                                                    <inputSimple
                                                        type="time"
                                                        class="pl-1 pr-1"
                                                        name="input1Saida"
                                                        ref='refInput1Saida'
                                                        :value="tabela.hjtfimprimeirot"
                                                        :disabled="tabela.hjtdsr == 1 ||
                                                            !checkboxTrabalho[index]"
                                                        @blur="insereHorariosSaida1"
                                                        @changeInput="v => changeSaida1(v, index)"
                                                    />
                                                </td>

                                                 <!-- 2° Entrada -->
                                                <td>
                                                    <inputSimple
                                                        type="time"
                                                        class="pl-1 pr-1"
                                                        name="input2Entrada"
                                                        ref='refInput2Entrada'
                                                        :value="tabela.hjtinisegundot"
                                                        :disabled="tabela.hjtdsr == 1 ||
                                                            !checkboxTrabalho[index]"
                                                        @blur="insereHorariosEntrada2"
                                                        @changeInput="v => changeEntrada2(v, index)"
                                                    />
                                                </td>

                                                 <!-- 2° Saída -->
                                                <td>
                                                    <inputSimple
                                                        type="time"
                                                        class="pl-1 pr-1"
                                                        name="input2Saida"
                                                        ref='refInput2Saida'
                                                        :value="tabela.hjtfimsegundot"
                                                        :disabled="tabela.hjtdsr == 1 ||
                                                            !checkboxTrabalho[index]"
                                                        @blur="insereHorariosSaida2"
                                                        @changeInput="v => changeSaida2(v, index)"
                                                    />
                                                </td>

                                                <!-- Intervalo -->
                                                <td @click="alteraIntervalo(index)">
                                                    <span v-if="!tabela.hjtcalcularintervalo"
                                                        class="cursor-pointer">
                                                        Sem intervalo
                                                    </span>
                                                    <span v-else
                                                        class="cursor-pointer">
                                                        {{
                                                            calculaIntervalo(
                                                                dadosTabela[index]
                                                            )
                                                        }}
                                                    </span>
                                                </td>

                                                <!-- Limite Extra -->
                                                <td>
                                                    <inputSimple
                                                        type="time"
                                                        ref='refInputLimiteExtra'
                                                        class="pl-1 pr-1"
                                                        :value="tabela.hjtlimitehoraextra"
                                                        :disabled="tabela.hjtdsr == 1"
                                                        @blur="insereHorariosExtra"
                                                        @changeInput="v => changeExtra(v, index)"
                                                    />
                                                </td>
                                
                                                <!-- DSR -->
                                                <td class="text-left espaco-dsr">
                                                    <b-form-checkbox v-if="tabela.hjtdiasemana != 7"
                                                        class="pl-5"
                                                        :aria-describedby="ariaDescribedby"
                                                        v-model="tabela.hjtdsr"
                                                        name="some-checkbox"
                                                        :value="1"
                                                        @change="changeCheckboxHorarioDsr(index)">
                                                    </b-form-checkbox>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </b-form-group>
                        </div>
                        <!-- <div class="col-sm-12 col-md-12"><hr></div> -->
                    </div>
                </slot>
                <!-- SEGUNDA TAB -->
                <slot slot="tab-regras">
                    <br>
                    <div
                    class="col-sm-12 nopadding"
                    align="left">
                        <div class="col-12 row nopadding mb-5">
                            <div class="col-6 nopadding">
                                <inputSimple
                                @changeInput="(v) => dadosJornada.jtconvencao = v"
                                :value="dadosJornada.jtconvencao"
                                :hasMask="true"
                                :mask="['NNNNNNNNNNNNNNNNNNNNNNNNNNNNN']"
                                label="Número da convenção coletiva*" />
                            </div>
                            <div class="col-6">
                                <b-checkbox class="mt-4 pt-2" v-model="calcular66">
                                    Calcular tempo faltante de interjornada e intrajornada como extra 50%
                                    <base-icon
                                    :icon="help"
                                    size='15'
                                    v-b-tooltip.hover
                                    title="O tempo mínimo não cumprido de 11 horas de interjornada ou 1 hora de intrajornada será considerado como hora extra 50% como determina o art. 66 da CLT."/>
                                </b-checkbox>
                            </div>
                        </div>
                        <div class="col-12 row nopadding mb-5">
                            <div class="col-12 negrito">
                                <h5>
                                    Intervalo
                                </h5>
                            </div>
                            <div class="col-6 d-flex align-items-center">
                                <b-checkbox checked="d-flex align-items-center" 
                                    v-model="dadosJornada.jtintervaloflexivel">
                                    Considerar intervalo flexível
                                </b-checkbox>
                            </div>
                        </div>
                        <div class="col-sm-12 row nopadding">
                            <div class="col-sm-12 negrito">
                                <h5>
                                    Banco de horas
                                </h5>
                            </div>
                            <div class="col-sm-6 nopadding">
                                <selectAll
                                ref="refSelecioneTempoFechamento"
                                nameForRadio="Tempo para fechamento"
                                :isMultiple="false"
                                :hasSelectAll="false"
                                :optionsArray="optTempoFechamento"
                                :labels="[{
                                    indexDFH:'EM', 
                                    description: 'Tempo para fechamento'
                                }]"
                                :selected="selectTempoParaFechamento"
                                @changeSelect="changeTempoParaFechamento"/>
                            </div>
                            <div class="col-sm-3 nopadding">
                                <inputSingleDay
                                    :value="dadosJornada.jtiniciocontrolebancohoras"
                                    @changeInput="
                                        v => dadosJornada.jtiniciocontrolebancohoras = v
                                    "
                                    label="Início de controle"
                                    name="input de data"
                                />
                            </div>
                            <!-- Tempo tolerancia -->
                            <div class="col-sm-2 nopadding">
                                <inputSimple
                                    type="time"
                                    ref='refInputTempoTolerancia'
                                    :value = "dadosJornada.jttempotolerancia"
                                    label = "Tempo tolerancia"
                                    class="pl-1 pr-1"
                                    @changeInput="changeTempoTolerancia"
                                />
                                <!-- :value="tabela.jttempotolerancia" -->
                                <!-- :disabled="tabela.hjtdsr == 1" -->
                            </div>
                        </div>
                        <div class="col-sm-12 row nopadding mt-5">
                            <div class="col-sm-12 negrito">
                                <h5>
                                    Inconformidades
                                </h5>
                            </div>
                            <div class="col-sm-12 nopadding">
                                <div class="col-sm-12 negrito">
                                    Permitir registro somente em pontos específicos 
                                </div>
                                <div class="col-sm-12 row nopadding">
                                    <div class="col-sm-6 nopadding mt-2">
                                        <selectAll
                                            ref="refSelecionePontos"
                                            nameForRadio="Selecione os pontos"
                                            :isMultiple="true"
                                            :hasSelectAll="true"
                                            :optionsArray="optPontos"
                                            :labels="[{indexDFH:'EM', description: 'Selecione os pontos'}]"
                                            :selected="selectPontos"
                                            :disabled="$v.dadosJornada.jtcliente.$invalid"
                                            :loading="loadingPontos"
                                            @changeSelect="changePontos"
                                        />
                                    </div>
                                    <div class="mt-4">
                                        <simpleButton
                                            :disabled="$v.dadosJornada.jtcliente.$invalid"
                                            title  ="Novo"
                                            text   ="Novo"
                                            event  ="click" 
                                            type   ="blue" 
                                            class  ="mt-3"
                                            :icon  ="mdiPlus"
                                            @click ="modalCadastrarPonto"
                                        />
                                    </div>
                                    <div class="col-sm-2 nopadding">
                                        <inputSimple
                                            @changeInput="
                                                (v) => dadosJornada.jthorainconformidade = v
                                            "
                                            :value="dadosJornada.jthorainconformidade"
                                            type="time"
                                            label="Receber inconformidades"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </slot>
            </tabsEagle>
            <requiredFields/>
            <div class="col-sm-12"><hr></div>
        </div>
        <modalCadastrarPonto 
        idModal="modalCadastrarPonto"
        ref="modalCadastrarPonto"
        @buscarPontos="buscaPontos([dadosJornada.jtcliente])"/>
    </panelEagle>
</template>
<script>
import Vue from 'vue'
import {
    mdiAccountClock, 
    mdiCheckBold,
    mdiPlus,
    mdiHelpCircleOutline
} from '@mdi/js'
import { conectionError }  from '@/Services/Helpers/swellHeper'
import { required }        from 'vuelidate/lib/validators'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest }     from '@/Services/auth/HttpRequest.Service'
import { DateTime, Duration } from 'luxon';
import { FiltrosService } from '@/Services/filtros/filtros.Service'
export default Vue.extend({
    name:'CadastroParametros',
    components:{
        baseIcon :require('@/components/Atom/Icon/BaseIcon').default,
        panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
        inputSingleDay: require('@/components/Atom/Datas/InputSingleDay').default,
        basicButtonsCadastrosSC: require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
        tituloPage: require('@/components/Atom/Header/Titulo').default,
        inputSimple: require('@/components/Atom/Inputs/InputSimple').default,
        tabsEagle: require('@/components/Atom/Tabs/TabsEagle').default,
        selectAll: require('@/components/Atom/Select/SelectAll').default,
        requiredFields: require('@/components/Atom/Footer/RequiredFields').default,
        buttonsFilters: require('@/components/Atom/Buttons/ButtonsFilters').default,
        simpleButton: require('@/components/Atom/Buttons/SimpleButton').default,
        modalCadastrarPonto: require('@/components/Atom/ModalSpecific/ModalCadastrarPonto').default,
    },

    validations:{
        dadosJornada: {
            jtdescricao:{
                required
            },
            jtcliente:{
                required
            },
            jtconvencao:{
                required
            }
        },
        dadosTabela:(v) => {
            return v.validationTable
        }
    },

    computed:{
        /**
         *  @description Percorre o objeto para verificar se esta válido
         *  caso um dos objetos esteja inválido retorna para bloquear o botão
         *  @return {boolean} para habilitar ou desabilitar o botão
         *  @author Luscas
         *  @author Marcola mudei algumas coisas na validação
         */
        validationTable(){
            for(const key in this.dadosTabela){
                if(!this.checkboxTrabalho.find(v => v)) return false
                var obj = this.validaTabela(this.dadosTabela[key], key)
                var saida = this.validaEntradaSaidaHoras(this.dadosTabela[key])
                if(!obj) return obj
                if(!saida) return saida
            }
            return obj
        }
    },

    data(){
        return {
            calcular66:true,
            url:'/checkpoint/cadastros/parametros/',
            loadingPanel : false,
            mdiAccountClock : mdiAccountClock,
            mdiCheckBold:mdiCheckBold,
            mdiPlus:mdiPlus,
            help : mdiHelpCircleOutline,
            optEmpresa  : new EmpresasService().Get(),
            optPontos : [],
            tabsPagina:[
                {
                    title: 'Horários', 
                    value: 'horarios'
                },{
                    title: 'Regras', 
                    value: 'regras'
                },
            ],
            filtroStatus:[
                {
                    value:'A', 
                    width: '48.5%', 
                    text: 'Ativo', 
                    isSelected:true,
                    icon:mdiCheckBold
                },{
                    value:'I', 
                    width: '48.5%', 
                    text: 'Inativo', 
                    isSelected:false, icon:''
                },
            ],
            dadosJornada:{
                jtstatus: '',
                jtconvencao: '',
                jtintervaloflexivel: false,
                jtdescricao: '',
                jtcliente: [],
                jtdsrmanual: false,
                jtdsr: '',
                jttipo: 'R',
                jtcheckpoint: true,
                empresasSelecionadas: [],
                jtperiodobancohoras:1,
                jthorainconformidade:'08:00',
                jtiniciocontrolebancohoras:'',
                jttempotolerancia:'',
            },
            checkboxTrabalho:[
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
            ],
            dadosTabela:[
                {
                    'id'                   : 'checkBoxDomingo',
                    'dia'                  : 'Domingo',
                    'hjtdsr'               : 1,
                    'nameRadio'            : 'radio-domingo',
                    'hjtiniprimeirot'      : '',
                    'hjtfimprimeirot'      : '',
                    'hjtinisegundot'       : '',
                    'hjtfimsegundot'       : '',
                    'hjtcalcularintervalo' : false,
                    'hjtlimitehoraextra'   : '',
                    'hjtcodigo'            : '',
                    'hjtdiasemana'         : 0,
                },
                {
                    'id'                   : 'checkBoxSegunda',
                    'dia'                  : 'Segunda',
                    'hjtdsr'               : 0,
                    'nameRadio'            : 'radio-segunda',
                    'hjtiniprimeirot'      : '',
                    'hjtfimprimeirot'      : '',
                    'hjtinisegundot'       : '',
                    'hjtfimsegundot'       : '',
                    'hjtcalcularintervalo' : false,
                    'hjtlimitehoraextra'   : '',
                    'hjtcodigo'            : '',
                    'hjtdiasemana'         : 1,
                },
                {
                    'id'                   : 'checkBoxTerca',
                    'dia'                  : 'Terça',
                    'hjtdsr'               : 0,
                    'nameRadio'            : 'radio-terca',
                    'hjtiniprimeirot'      : '',
                    'hjtfimprimeirot'      : '',
                    'hjtinisegundot'       : '',
                    'hjtfimsegundot'       : '',
                    'hjtcalcularintervalo' : false,
                    'hjtlimitehoraextra'   : '',
                    'hjtcodigo'            : '',
                    'hjtdiasemana'         : 2,
                },
                {
                    'id'                   : 'checkBoxQuarta',
                    'dia'                  : 'Quarta',
                    'hjtdsr'               : 0,
                    'nameRadio'            : 'radio-quarta',
                    'hjtiniprimeirot'      : '',
                    'hjtfimprimeirot'      : '',
                    'hjtinisegundot'       : '',
                    'hjtfimsegundot'       : '',
                    'hjtcalcularintervalo' : false,
                    'hjtlimitehoraextra'   : '',
                    'hjtcodigo'            : '',
                    'hjtdiasemana'         : 3,
                },
                {
                    'id'                   : 'checkBoxSQuinta',
                    'dia'                  : 'Quinta',
                    'hjtdsr'               : 0,
                    'nameRadio'            : 'radio-quinta',
                    'hjtiniprimeirot'      : '',
                    'hjtfimprimeirot'      : '',
                    'hjtinisegundot'       : '',
                    'hjtfimsegundot'       : '',
                    'hjtcalcularintervalo' : false,
                    'hjtlimitehoraextra'   : '',
                    'hjtcodigo'            : '',
                    'hjtdiasemana'         : 4,
                },
                {
                    'id'                   : 'checkBoxSexta',
                    'dia'                  : 'Sexta',
                    'hjtdsr'               : 0,
                    'nameRadio'            : 'radio-sexta',
                    'hjtiniprimeirot'      : '',
                    'hjtfimprimeirot'      : '',
                    'hjtinisegundot'       : '',
                    'hjtfimsegundot'       : '',
                    'hjtcalcularintervalo' : false,
                    'hjtlimitehoraextra'   : '',
                    'hjtcodigo'            : '',
                    'hjtdiasemana'         : 5,
                },
                {
                    'id'                   : 'checkBoxSabado',
                    'dia'                  : 'Sábado',
                    'hjtdsr'               : 0,
                    'nameRadio'            : 'radio-sabado',
                    'hjtiniprimeirot'      : '',
                    'hjtfimprimeirot'      : '',
                    'hjtinisegundot'       : '',
                    'hjtfimsegundot'       : '',
                    'hjtcalcularintervalo' : false,
                    'hjtlimitehoraextra'   : '',
                    'hjtcodigo'            : '',
                    'hjtdiasemana'         : 6,
                },
                {
                    'id'                   : 'checkBoxFeriado',
                    'dia'                  : 'Feriado',
                    'hjtdsr'               : 0,
                    'nameRadio'            : 'radio-feriado',
                    'hjtiniprimeirot'      : '',
                    'hjtfimprimeirot'      : '',
                    'hjtinisegundot'       : '',
                    'hjtfimsegundot'       : '',
                    'hjtcalcularintervalo' : false,
                    'hjtlimitehoraextra'   : '',
                    'hjtcodigo'            : '',
                    'hjtdiasemana'         : 7,
                },
            ],
            valorSelecionadoEntrada1 : '',
            valorSelecionadoSaida1   : '',
            valorSelecionadoEntrada2 : '',
            valorSelecionadoSaida2   : '',
            valorSelecionadoExtra    : '',
            id                       : '',
            selectEmpresa            : [],
            selectPontos             : [],
            loadingPontos            : false,
            optTempoFechamento:[
                { value:1, description:'1 Mês' },
                { value:2, description:'2 Meses' },
                { value:3, description:'3 Meses' },
                { value:4, description:'4 Meses' },
                { value:5, description:'5 Meses' },
                { value:6, description:'6 Meses' },
            ],
            selectTempoParaFechamento:[{ 
                value:1, description:'1 Mês' 
            }]
        }
    },

    methods:{

        /**
         * faz a parte da validacao dos tempo para 
         * sempre deixar um valor selecionado
         */
        changeTempoParaFechamento(tempo){
            if(!tempo[0]){
                var filtro  = this.optTempoFechamento.filter(
                    (v) => v.value == this.dadosJornada.jtperiodobancohoras
                )
                this.selectTempoParaFechamento = filtro
                return
            }
            this.dadosJornada.jtperiodobancohoras = tempo[0]
        },

        changeDescricao(descricao){
            this.dadosJornada.jtdescricao = descricao
        },

        changeEmpresas(empresa){
            this.$refs.refSelecionePontos.clearAll()
            this.dadosJornada.jtcliente = empresa[0]
            if(empresa.length > 0)
                this.buscaPontos(empresa)
        },

        changePontos(pontos){
            this.pontosSelecionados = pontos
        },

        /**
         *	@description Método para quando alterado o check de 
         *		dias trabalhados os dados sejam apagados
         *	@param {number} index - posicao do objeto selecionado
         *	@author Vitor Hugo 🐨
         */
        changeCheckboxTrabalho(index){
            this.dadosTabela[index].hjtiniprimeirot = ''
            this.dadosTabela[index].hjtfimprimeirot = ''
            this.dadosTabela[index].hjtinisegundot = ''
            this.dadosTabela[index].hjtfimsegundot = ''
            this.dadosTabela[index].hjtlimitehoraextra = ''
        },

        // Saida1
        changeEntrada1(valor, index){
            // if(!valor) return
            this.valorSelecionadoEntrada1 = valor  
            this.dadosTabela[index].hjtiniprimeirot = valor
            this.alteraIntervalo(index)
        },

        /** 
         * Método para inserir horário nos campos de 1° Entrada que estão selecionados na tabela
         * @param Arrays Checkbox, dadosTabela (hjtiniprimeirot)
         * @return Array, dadosTabela com os campos hjtiniprimeirot inseridos com o valor informado
         * @author Vitor Hugo 🐨
         */
        insereHorariosEntrada1(){
            for (let index = 0; index < this.dadosTabela.length; index++) {
                if(this.dadosTabela[index].hjtiniprimeirot == '' &&
                        this.dadosTabela[index].hjtdsr != 1 && this.checkboxTrabalho[index]){
                    this.dadosTabela[index].hjtiniprimeirot = this.valorSelecionadoEntrada1
                }
                    
            }
        },

        //Saida1
        changeSaida1(valor, index){
            // if(!valor) return
            this.valorSelecionadoSaida1 = valor  
            this.dadosTabela[index].hjtfimprimeirot = valor
            this.alteraIntervalo(index)
        },

        /** 
         * Método para inserir horário nos campos de 1° Saída que estão selecionados na tabela
         * @param Arrays Checkbox, dadosTabela (hjtfimprimeirot)
         * @return Array, dadosTabela com os campos hjtfimprimeirot inseridos com o valor informado
         * @author Vitor Hugo 🐨
         */
        insereHorariosSaida1(){
            for (let index = 0; index < this.dadosTabela.length; index++) {
                if(this.dadosTabela[index].hjtfimprimeirot == '' &&
                        this.dadosTabela[index].hjtdsr != 1  && this.checkboxTrabalho[index]){
                    this.dadosTabela[index].hjtfimprimeirot = this.valorSelecionadoSaida1
                }
                    
            }
        },

        //Entrada2
        changeEntrada2(valor, index){
            // if(!valor) return
            this.valorSelecionadoEntrada2 = valor  
            this.dadosTabela[index].hjtinisegundot = valor
            this.alteraIntervalo(index)
        },

        /** 
         * Método para inserir horário nos campos de 2° Entrada que estão selecionados na tabela
         * @param Arrays Checkbox, dadosTabela (hjtinisegundot)
         * @return Array, dadosTabela com os campos hjtinisegundot inseridos com o valor informado
         * @author Vitor Hugo 🐨
         */
        insereHorariosEntrada2(){
            for (let index = 0; index < this.dadosTabela.length; index++) {
                if(this.dadosTabela[index].hjtinisegundot == '' &&
                        this.dadosTabela[index].hjtdsr != 1 && this.checkboxTrabalho[index]){
                    this.dadosTabela[index].hjtinisegundot = this.valorSelecionadoEntrada2
                } 
            }
        },

        //Saida2
        changeSaida2(valor, index){
            // if(!valor) return
            this.valorSelecionadoSaida2 = valor  
            this.dadosTabela[index].hjtfimsegundot = valor
            this.alteraIntervalo(index)
        },

        /** 
         * Método para inserir horário nos campos de 2° Saída que estão selecionados na tabela
         * @param Arrays Checkbox, dadosTabela (hjtfimsegundot)
         * @return Array, dadosTabela com os campos hjtfimsegundot inseridos com o valor informado
         * @author Vitor Hugo 🐨
         */
        insereHorariosSaida2(){
            for (let index = 0; index < this.dadosTabela.length; index++) {
                if(this.dadosTabela[index].hjtfimsegundot == '' &&
                        this.dadosTabela[index].hjtdsr != 1 && this.checkboxTrabalho[index]){
                    this.dadosTabela[index].hjtfimsegundot = this.valorSelecionadoSaida2
                }
                    
            }
        },

        //Extra
        changeExtra(valor, index){
            this.valorSelecionadoExtra = valor  
            this.dadosTabela[index].hjtlimitehoraextra = valor
        },
        
        changeTempoTolerancia(jttempotolerancia) {
            this.dadosJornada.jttempotolerancia = jttempotolerancia 
        },

        /** 
         * Método para inserir horário nos campos de Limite extra que estão selecionados na tabela
         * @param Arrays Checkbox, dadosTabela (hjtlimitehoraextra)
         * @return Array, dadosTabela com os campos hjtlimitehoraextra inseridos com o valor informado
         * @author Vitor Hugo 🐨
         */
        insereHorariosExtra(){
            for (let index = 0; index < this.dadosTabela.length; index++) {
                if(this.dadosTabela[index].hjtlimitehoraextra == '' &&
                        this.dadosTabela[index].hjtdsr != 1 && this.checkboxTrabalho[index]){
                    this.dadosTabela[index].hjtlimitehoraextra = this.valorSelecionadoExtra
                }
                    
            }
        },

        /** 
         * Método mostrar ou ocultar o tempo de intervalo
         * faz a validação se a jornada tem mais de 4 para poder dizer que
         * é sem intervalo
         * @listens click - emitido quando é clicado na colula de intervalo
         * @listens change - emitido quando é alterado um horario de entrada/saida
         * @param { number } index - posição do objeto
         * @author Vitor Hugo 🐨
         * @author Lucas Eduardo // Mudei umas coisa
         */
        alteraIntervalo(index){
            if(this.validaColunasNull(this.dadosTabela[index])){
                return
            }
            const {
                hjtiniprimeirot,
                hjtfimprimeirot,
                hjtinisegundot,
                hjtfimsegundot,
            } = this.dadosTabela[index]
            var entrada1   = this.retornaHoraEmMin(hjtiniprimeirot)
            var saida1     = this.retornaHoraEmMin(hjtfimprimeirot)
            var entrada2   = this.retornaHoraEmMin(hjtinisegundot)
            var saida2     = this.retornaHoraEmMin(hjtfimsegundot)
            var jornada4hrs = this.verificaJornada4hrs(
                entrada1, saida1, entrada2, saida2
            )
            if(jornada4hrs){
                this.dadosTabela[index].hjtcalcularintervalo = false
            }else{
                this.dadosTabela[index].hjtcalcularintervalo = true
            }
        },

        /**
         * Recebe as entradas e saida em minutos e verifica se é <= 4 hrs
         * @author Lucas Eduardo
         */
        verificaJornada4hrs(entrada1 = 0, saida1 = 0, entrada2 = 0, saida2 = 0){
            var primeiroTurno = Math.abs(saida1 - entrada1)
            var segundoTurno  = Math.abs(saida2 - entrada2)
            var minutosTotais = primeiroTurno + segundoTurno
            return Math.abs(minutosTotais)/60 <= 4
        },

        /**
         * Recebe uma string no formato de hora e retorna em
         * a mesma quantidade em minutos 
         * @param { string } hora - hora em HH:mm 
         * @return Number
         * @author Lucas Eduardo
         */
        retornaHoraEmMin(hora){
            if(!hora) return 
            var horaInteira = DateTime.fromFormat(hora, 'HH:mm').toObject()
            return Duration.fromObject({
                hour: horaInteira.hour,
                minute: horaInteira.minute
            }).as('minutes')
        },

        /** 
         * Método calcular o tempo de intervalo
         * @param { Object } object.hjtfimprimeirot - hora da 1° saída
         * @param { Object } object.hjtinisegundot - hora da 2° entrada
         * @return { string } Sem intervalo ou tempo calculado em horas
         * @author Vitor Hugo 🐨
         * @author Lucas Eduardo // Mudei um monte de coisa
         */
        calculaIntervalo({ hjtfimprimeirot, hjtinisegundot }){
            if(hjtfimprimeirot && hjtinisegundot){
                var horaSaida1   = DateTime.fromFormat(hjtfimprimeirot, 'HH:mm')
                var horaEntrada2 = DateTime.fromFormat(hjtinisegundot, 'HH:mm')
                var intervalo = horaEntrada2.diff(horaSaida1)
                var { hours, minutes } = intervalo.shiftTo('hours', 'minutes')
                var hora = (''+hours).length !== 1 ? hours : '0'+hours
                var minuto = (''+minutes).length !== 1 ? minutes : '0'+minutes
                var retorno = `${hora}:${minuto}`
                var horaValida = DateTime.fromFormat(retorno, 'HH:mm')
                if(!horaValida.invalid && retorno !== '00:00'){
                    return retorno
                }
                return 'Sem intervalo'
            }
            return 'Sem intervalo'
        },

        salvar(){
            this.loadingPanel = true
            var dadosTabela = this.validaDadosTabela()
            var obj = {
                'dadosTabela'  : dadosTabela,
                'dadosJornada' : this.dadosJornada,
                'id'           : this.id,
                'pontos'       : this.pontosSelecionados,
                'jtconsideraextrainterjornada': this.calcular66
            }
            new HttpRequest()
                .Post(this.url+'salvar', {'dados':obj})
                .then(dados => {
                    if(dados.code == 201){
                        this.$router.push({name:'listagemParametros'})
                    } else {
                        conectionError()
                    }
                }).catch(() => {
                    conectionError()
                }).finally(() => this.loadingPanel = false)
        },

        /**
         * valida se os valores da tabela
         * estao preenchidos, caso n ele seta o 
         * hjtcalcularintervalo como false
         * @returns this.dadosTabela
         * @author Lucas Eduardo
         */
        validaDadosTabela(){
            for(const dados of this.dadosTabela){
                var dadosNull = this.validaColunasNull(dados)
                if(dadosNull){
                    dados.hjtcalcularintervalo = false
                }
            }
            return this.dadosTabela
        },

        /**
         * valida se os dados da tabela tem alguma coisa preenchida
         * sim n usei a que ja tem, pq essa n valida a checkbox
         * @param { Object } dados - valor da linha da tabela 
         * @returns { Bolean }
         * @author Lucas Eduardo
         */
        validaColunasNull(dados){
            return !(!!dados.hjtfimprimeirot +
                    !!dados.hjtfimsegundot +
                    !!dados.hjtiniprimeirot +
                    !!dados.hjtinisegundot +
                    !!dados.hjtlimitehoraextra)
        },


        /**
         *  @description Método pegar o último valor do filtro e 
         *      também alterar qual esta selecionado no momento
         *  @param {array} value - valor do filtro selecionado
         *  @author Vitor Hugo 🐨
         */
        mudaFiltroStatus(value){
            this.dadosJornada.jtstatus = value[0];
            this.filtroStatus[0].icon = ''
            this.filtroStatus[0].isSelected = false
            this.filtroStatus[1].icon = ''
            this.filtroStatus[1].isSelected = false
            if(value[0] == 'A'){
                this.filtroStatus[0].icon = mdiCheckBold
                this.filtroStatus[0].isSelected = true
            } else{
                this.filtroStatus[1].icon = mdiCheckBold
                this.filtroStatus[1].isSelected = true
            }
        },

        /**
         *  @description Método para quando alterado o checkbox os dados sejam apagados
         *  @param {number} index - posicao do objeto selecionado
         *  @author Vitor Hugo 🐨
         */
        changeCheckboxHorarioDsr(index){
            //desmarca o checkbox de dsr
            //se o broder clicou na checkbox que tava true 
            if(!this.dadosTabela[index].hjtdsr){
                this.dadosTabela[index].hjtdsr = 0
                this.dadosJornada.jtdsrmanual = true
            }else{
                for (const arr in this.dadosTabela) {
                    this.dadosTabela[arr].hjtdsr = 0
                }
                this.dadosTabela[index].hjtdsr = 1
                this.dadosTabela[index].hjtiniprimeirot = ''
                this.dadosTabela[index].hjtfimprimeirot = ''
                this.dadosTabela[index].hjtinisegundot  = ''
                this.dadosTabela[index].hjtfimsegundot  = ''
                this.dadosTabela[index].hjtlimitehoraextra = ''
                this.dadosJornada.jtdsrmanual = false
                this.checkboxTrabalho[index] = false
            }
        },

        /**
         *  @description Método para verificar qual é o dia da semana
         *  @param {array} dia - valores dos dias da semana do banco
         *  @return {string} dia da semana
         *  @author Vitor Hugo 🐨
         */
        verificaDia(dia){
            switch (dia) {
                case 0: return 'Domingo'
                case 1: return 'Segunda-feira'
                case 2: return 'Terça-feira'
                case 3: return 'Quarta-feira'
                case 4: return 'Quinta-feira'
                case 5: return 'Sexta-feira'
                case 6: return 'Sábado'
                case 7: return 'Feriado'
                default: break;
            }
        },

        /**
         *  @description Adiciona os valores nos filtros da tela
         *  @param {object} jornada - dados dos filtros
         *  @author Vitor Hugo 🐨
         */
        ajeitaDadosJornada(jornada, pontos){
            this.calcular66 = jornada.jtconsideraextrainterjornada
            for(const k in this.dadosJornada){
                this.dadosJornada[k] = jornada[k]??this.dadosJornada[k]
            }
            this.selectTempoParaFechamento = this.optTempoFechamento.filter(
                (v) => v.value == jornada.jtperiodobancohoras
            )
            this.mudaFiltroStatus([jornada.jtstatus])
            this.selectEmpresa = [{
                value: jornada.jtcliente,
                description : jornada.clfantasia
            }]
            this.selectPontos = pontos
        },

        /**
         *  @description Adiciona os valores da tabela
         *  @param {array} tabela - dados da tabela
         *  @author Lucas Eduardo
         */
        ajeitaDadosTabela(tabela){
            var dadosCompletos = []
            for(const index in this.dadosTabela){
                var aux = {
                    ...this.dadosTabela[index],
                    ...tabela[index],
                }
                this.checkboxTrabalho[index] = !!aux.hjtiniprimeirot
                // this.dadosTabela[index] = aux
                dadosCompletos.push(aux)
            }
            this.$nextTick(() => {
                this.dadosTabela = dadosCompletos
                this.setaValoresChecks()
            })
        },

        /**
         *  @description Adiciona true nos checkbox 
         *      que tem os inputs com valores
         *  @author Vitor Hugo 🐨
         */
        setaValoresChecks(){
            this.dadosTabela.forEach(element => {
                this.checkboxTrabalho[element.hjtdiasemana] = 
                        element.hjtiniprimeirot != null ? true : false
            });
        },

        /**
         *  @description Verifica se todos os inputs estão preenchidos ou vazios
         *  @param {object} dados - dados da tabela
         *  @author Luscas
         */
        validaTabela(dados, key){
            var objV = !dados.hjtiniprimeirot +
            !dados.hjtfimprimeirot+
            !dados.hjtinisegundot+
            !dados.hjtfimsegundot
            if(this.checkboxTrabalho[key])
                return objV ===0 || objV ==2
            else 
                return true
        },
        
        validaEntradaSaidaHoras(dados, key) {
            if(dados.hjtfimprimeirot 
                && dados.hjtfimsegundot 
                && !dados.hjtiniprimeirot 
                && !dados.hjtinisegundot){
                return !dados.hjtfimprimeirot
            }else if(!dados.hjtfimprimeirot 
                && !dados.hjtfimsegundot 
                && dados.hjtiniprimeirot 
                && dados.hjtinisegundot){
                    return !dados.hjtiniprimeirot
            }else
                return true
        },

        /**
         *  @description Busca os pontos da empresa
         *  @param {array} empresa - código da empresa
         *  @author Vitor Hugo 🐨
         */
        buscaPontos(empresa){
            this.loadingPontos = true
            new FiltrosService()
                .dados_filtros(empresa, ['P'])
                .then((dados) => {
                    this.optPontos = dados.P
                }).finally(() => this.loadingPontos = false)
        },

        modalCadastrarPonto(){
            this.$refs
                .modalCadastrarPonto
                .preparaModalCadastrarPonto()
        }

    },

    mounted(){
        if(this.$route.params.id){
            this.id = this.$route.params.id
            var obj = {codigo : this.$route.params.id}
            this.loadingPanel = true
            new HttpRequest()
                .Post(this.url+'editar',obj).then(dados=>{
                    if(dados.code == 200){
                        this.ajeitaDadosJornada(dados.data.jornada, dados.data.pontos)
                        this.ajeitaDadosTabela(dados.data.tabela)
                    } else {
                        conectionError()
                    }
                    this.loadingPanel = false
                })
        }
    },

})
</script>
<style lang="scss">
#cadastroParametros{
    .headerTable{
        background: #e4e4e4;
        height: 30px;
    }
    .bodyTable{
        background: #f1f5fe;
    }
    .negrito{
        font-weight: bold;
    }
}
</style>
