<template>
 <div  class="nopadding col-sm-12 row">
      <div class="col-sm-6 row nopadding">
          <div class="col-sm-12 nopadding">
              <selectAll
                  nameForRadio="selectTarefas"
                  :labels='[{description:"Tarefas"}]'
                  :isMultiple="false"
                  :hasSelectAll="false"
                  :extraClass="{'border border-danger': $v.task.tarefa.$anyError}"
                  :optionsArray="optTarefas"
                  @close='$v.task.tarefa.value.$touch()'
                  @changeSelect='mudaTarefa'/>
          </div>
          <div class="col-sm-6 nopadding mt-3">
              <selectAll
                  nameForRadio="diaDaSemana"
                  :labels='[{description:"Dia da semana"}]'
                  :isMultiple="false"
                  :hasSelectAll="false"
                  :extraClass='{"border border-danger": $v.task.dia.$anyError}'
                  :optionsArray="optDiasDaSemana"
                  @close='$v.task.dia.value.$touch()'
                  @changeSelect='mudaDiaSemana'/>
          </div>
          <div class="col-sm-6 nopadding mt-3">
              <inputS
                  name='inputSHorario'
                  type='time'
                  label='Horário'
                  @blur='$v.task.horario.$touch()'
                  @changeInput='value=>task.horario = value'
                  :inputClass='{"border border-danger": $v.task.horario.$anyError}'/>
          </div>
          <div class="col-sm-12  row">
               <div class="col-sm-8 nopadding">
                   <b-alert
                        variant="warning"
                        class='small'
                        fade
                        @dismiss-count-down='cdAlerta'
                        :show='countDown'>
                    Esse horário ja foi inserido
                    </b-alert>
               </div>
                <div class="col-sm-4 left somepadding">
                    <botao 
                        :disabled='$v.$invalid'
                        event='click'
                        @click='confirma'
                        type='green'
                        :icon='mdiPlus'
                        text='Inserir' 
                        width='100%'/>
                </div>
          </div>
      </div>
      <div class="col-sm-6 nopadding divRightTurno">
          <slot name='table' :titles='titles'>
          </slot>
      </div>
  </div>
</template>

<script>
import {mdiPlus } from '@mdi/js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import Vue from 'vue'
import { required } from 'vuelidate/lib/validators'
export default Vue.extend({
    components: {
        'selectAll' : require('@/components/Atom/Select/SelectAll').default,
        'inputS'    : require('@/components/Atom/Inputs/InputSimple').default,
        'botao'     : require('@/components/Atom/Buttons/SimpleButton').default,
    },
    props: {
        optTarefas: {
            type: Array,
            require: true
        },
        arrayCodigos:{
            type: Array,
            require: false
        }
    },
    validations:{
        task: {
            tarefa  :{
                value: { required }
            },
            dia     :{
                value: { required }
            },
            horario :  { required }
        }
    },
    data() {
        return {
            mdiPlus:mdiPlus,
            // misc
            countDown:0,
            // 
            optDiasDaSemana: [
                {value: 0, description: 'Domingo'        },
                {value: 1, description: 'Segunda-Feira'  },
                {value: 2, description: 'Terça-Feira'    },
                {value: 3, description: 'Quarta-Feira'   },
                {value: 4, description: 'Quinta-Feira'   },
                {value: 5, description: 'Sexta-Feira'    },
                {value: 6, description: 'Sábado'         },
                {value: 7, description: 'Segunda a Sexta'},
            ],
            titles:[
                {'number': 'tarefa.description', 'name': 'Tarefa'       },
                {'number': 'dia.description',    'name': 'Dia da semana'},
                {'number': 'horario',            'name': 'Horário'      },
            ],
            task:{
                tarefa:{
                    value:'',
                    description:''
                },
                dia:{
                    value:'',
                    description:''
                },
                horario:''
            },
            codigos:[]
        }
    },
    methods: {
        cdAlerta(value){
            this.countDown = value
        },
        confirma(){
            if(this.task.dia.value != '7'){
                this.salvaTarefa(this.task)
            }else{
                for(var i = 1; i < 6; i++){
                    var aux = {
                        'dia'    : this.optDiasDaSemana[i],
                        'tarefa' : this.task.tarefa,
                        'horario': this.task.horario
                    }
                    this.salvaTarefa(aux)
                }
            }
        },
        salvaTarefa(task){
            var codigo = task.horario+''+task.dia.value
            var existe = this.arrayCodigos.find(cod=>{return cod === codigo})
            if(existe === undefined){
                task['codigo'] = codigo
                this.$emit('inserirTarefa', task)
                return
            }
            this.countDown = 5
        },
        mudaDiaSemana(array, value, description) {
            this.task.dia.description = description
            this.task.dia.value = value
        },
        mudaTarefa(array, value, description) {
            this.task.tarefa.value = value
            this.task.tarefa.description = description
        },
        removerCadastro(value){
            var index = this.tarefas.findIndex(el=>{return el.codigo === value})
            this.tarefas.splice(index, 1);
            index = this.codigos.findIndex(el=>{return el === value})
            this.codigos.splice(index, 1)
        }
    },
})
</script>

<style lang="scss" scoped>
.small{
  padding: 0  0 0 0 !important;
  margin:  2% 0 0 0 !important;
}
.left{
  text-align: left;
}
.somepadding{
  padding: 1% 0 1% 0
}
.divRightTurno{
  border-left: 1px solid #428bca;
}
</style>