<template>
    <modalEagle
        :hardToClose="false"
        :id='id'
        @modalClose="modalClose"
        @modalOpen="modalOpen"
        size="xl"
        title='Indique as telas que o usuário terá acesso'>
        <template #modalBody>
            <panelEagle id='modalCadastroPermissoesCP' 
                :loading='loadingPanelEagle'>
                <div class="col-sm-12 row">
                    <div class="col-sm-4 nopadding">
                        <inputSimple
                            :value="perfil.pedescricao"
                            name ="inputHoraInicial"
                            label ="Descrição*"
                            type ="text"
                            class="nopadding"
                            @changeInput ="(v) => perfil.pedescricao = v"
                        />
                    </div>
                    <div class="col-sm-4 nopadding">
                        <selectAll
                            ref="refEmpresa"
                            nameForRadio ="seletorEmpresaImportarRastro"
                            firstSelected ="EM"
                            :labels ="[{indexDFH:'EM',description:'Empresa*' }]"
                            :optionsArray ="optEmpresas"
                            :isMultiple ="false"
                            @changeSelect ="changeEmpresa"
                        />
                    </div>
                    <div class="col-sm-4 nopadding btn-form-group">
                        <simpleButton
                            :disabled="($v.$invalid || !checkboxValido)"
                            text='Salvar'
                            :icon='iconAtivar'
                            type='blue'
                            event='click'
                            @click='salvarPerfil'
                        />
                        <simpleButton
                            :disabled="loadingPanelEagle"
                            text='Cancelar'
                            :icon='iconCancel'
                            type='red'
                            event='click'
                            @click='fecharModal'
                        />
                    </div>
                </div>
                <div class="col-sm-12 mt-4">
                    <tableSimples :id="'tb-modal-permissoes-usuario'">
                        <thead>
                            <th>
                                <b-checkbox 
                                    v-model='checkHeader.todos.checked' 
                                    :indeterminate="checkHeader.todos.indeterminate"
                                    @change="changeCheckTodos"
                                    class='checktodos'>
                                    Opções
                                </b-checkbox>
                            </th>
                            <th>
                                <b-checkbox 
                                    v-model='checkHeader.visualizar.checked' 
                                    @change='(v) => changeCheckColuna(v, "visualizar")'
                                    :indeterminate="checkHeader.visualizar.indeterminate"
                                    class='checktodos'>
                                    Visualizar
                                </b-checkbox>
                            </th>
                            <th>
                                <b-checkbox 
                                    v-model='checkHeader.cadastrar.checked' 
                                    @change='(v) => changeCheckColuna(v, "cadastrar")'
                                    :indeterminate="checkHeader.cadastrar.indeterminate"
                                    :disabled="disableCheckAll"
                                    class='checktodos'>
                                    Cadastrar
                                </b-checkbox>
                            </th>
                            <th>
                                <b-checkbox 
                                    v-model='checkHeader.editar.checked' 
                                    @change='(v) => changeCheckColuna(v, "editar")'
                                    :indeterminate="checkHeader.editar.indeterminate"
                                    :disabled="disableCheckAll"
                                    class='checktodos'>
                                    Editar
                                </b-checkbox>
                            </th>
                            <th>
                                <b-checkbox 
                                    v-model='checkHeader.excluir.checked' 
                                    @change='(v) => changeCheckColuna(v, "excluir")'
                                    :indeterminate="checkHeader.excluir.indeterminate"
                                    :disabled="disableCheckAll"
                                    class='checktodos'>
                                    Excluir
                                </b-checkbox>
                            </th>
                        </thead>
                        <tbody>
                            <template
                                v-for='(menu, index) in permissoes'>
                                <tr v-if="true"
                                    :key='`${index}_menu_${menu.descricao}`'>
                                    <td colspan="5">
                                        {{menu.descricao}}
                                    </td>
                                </tr>
                                <tr v-for='(item, index2) in menu.itens'
                                    :key='`${index}_${index2}_item_${item.pidescricao}`'>
                                    <td class="td-descricao">
                                        {{item.pidescricao}}
                                    </td>
                                    <td class="td-valor">
                                        <div v-if="item.visualizar == null" 
                                            class="td-valor-div">
                                        </div>
                                        <b-check v-else
                                            v-model='item.visualizar'
                                            @change='v=>changeCheckItem(v,"visualizar")'>
                                        </b-check>
                                    </td>
                                    <td class="td-valor">
                                        <div v-if="item.cadastrar==null" 
                                            class="td-valor-div">
                                        </div>
                                        <b-check v-else
                                            :disabled="!item.visualizar"
                                            v-model='item.cadastrar'
                                            @change='v=>changeCheckItem(v,"cadastrar")'>
                                        </b-check>
                                    </td>
                                    <td class="td-valor">
                                        <div v-if="item.editar==null" 
                                            class="td-valor-div">
                                        </div>
                                        <b-check v-else
                                            :disabled="!item.visualizar"
                                            v-model='item.editar'
                                            @change='v=>changeCheckItem(v,"editar")'>
                                        </b-check>
                                    </td>
                                    <td class="td-valor">
                                        <div v-if="item.excluir==null" 
                                            class="td-valor-div">
                                        </div>
                                        <b-check v-else
                                            :disabled="!item.visualizar"
                                            v-model='item.excluir'
                                            @change='v=>changeCheckItem(v,"excluir")'>
                                        </b-check>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </tableSimples>
                </div>
                <div class="col-sm-12"><hr></div>
                <div class="col-sm-12 row">
                    <h4>Listagem Perfis</h4>
                </div>
                <tableListagem
                    :loading='loadingPanelEagle'
                    :titles='titlesTable'
                    :data='dataTable'
                    widthAcoes="140px"
                    deleteEdit="editDelete"
                    :pageSizes="[5, 10, 25]"
                    @excluirCadastro ='excluirPerfil'
                    @editarCadastro='editarPerfil'>
                </tableListagem>
            </panelEagle>
        </template>
        <template #modalFooter>
            <div class="col-12 row nopadding">
            </div>
        </template>
    </modalEagle>
</template>
<script>
import Vue from 'vue'
import{ mdiCancel, mdiCheck } from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { required } from 'vuelidate/lib/validators'
import { conectionError } from '@/Services/Helpers/swellHeper'

export default Vue.extend({
    name:'modalPermissoesUsuarioCP',
    components:{
        modalEagle: require('@/components/Atom/Modal/ModalEagle.vue').default,
        simpleButton: require('@/components/Atom/Buttons/SimpleButton').default,
		tableSimples : require('@/components/Atom/Table/TableSimples').default,
		selectAll : require('@/components/Atom/Select/SelectAll').default,
		inputSimple : require('@/components/Atom/Inputs/InputSimple').default,
        panelEagle : require('@/components/Atom/Panel/PanelEagle').default,
		tableListagem : require('@/components/Atom/Table/TableListagem').default,
    },
    props:{
        id: {
            type: String,
            required: true,
        },
        codigo:{
            type: Number,
            default: 0,
            required: false,
        }
    },
    validations: {
        perfil: {
            pedescricao: { required },
            pecliente: { required },
        }
    },
    data(){
        return{
            iconCancel:mdiCancel,
			iconAtivar:mdiCheck,
			optEmpresas: new EmpresasService().Get(),
			url : '/checkpoint/cadastros/usuarios/',
            titlesTable: [
                {'number': 'descricao', 'name': 'Descrição', 'width': '150px !important'},
                {'number': 'permissoes', 'name': 'Telas de acesso'},
            ],
            permissoes: [],
            perfil: {
                pecodigo:'',
                pedescricao:'',
                pecliente:''
            },
            dataTable:[],
            loadingPanelEagle:false,
            teveAlteracao:false,
            
            checkHeader: {
                todos: {
                    indeterminate: false,
                    checked: true,
                },
                visualizar: {
                    indeterminate: false,
                    checked: false,
                },
                cadastrar: {
                    indeterminate: false,
                    checked: false,
                },
                editar: {
                    indeterminate: false,
                    checked: false,
                },
                excluir: {
                    indeterminate: false,
                    checked: false,
                }
            },

        }
    },
    computed: {

        /**
         * @description verifica se checkHeader.visualizar está com ambos os valores
         * (checked, indeterminate) como false, para bloquear os demais itens das outras 
         * colunas (checkHeader.cadastrar, checkHeader.editar, checkHeader.excluir)
         * @author Rafael
         */
        disableCheckAll() {
            return !this.checkHeader.visualizar.checked 
                && !this.checkHeader.visualizar.indeterminate
        },

        /** 
         * @description verifica se existe pelo menos 1 checkbox 'visualizar' como 'true'
         * @author Rafael
         */
        checkboxValido() {
            let podeSalvar = false
            this.permissoes.forEach(e => {
                e.itens.forEach(e2 => {
                    if(e2.visualizar) {
                        podeSalvar = true
                    }
                })
            })
            return podeSalvar
        }
    },
    methods:{

        /** 
         * @description evento listener do select de empresas
         * @param {array} value
         * @author Rafael
         */
        changeEmpresa(value) {
            if(value && value[0]) {
                this.buscarAcessos(value[0])
                this.perfil.pecliente = value[0]
            } else {
                this.perfil.pecliente = ''
            }
        },

        /** 
         * @description busca os itens associados ao modulo checkpoint 
         * @author Rafael
         */
        buscarPerfisCheckpoint() {
            this.loadingPanelEagle = true
            new HttpRequest().Get(this.url+'buscar/perfis')
                .then(({data, status}) => {
                    if(status && data) {
                        this.permissoes = data.perfis
                        this.changeCheckTodos(true)
                    }
                })
                .finally(() => {
                    this.loadingPanelEagle = false
                })
        },

        /** 
         * @description busca os perfis e os itens do menu permitidos para o perfil, 
         * associados ao modulo checkpoint
         * @param {number} codigo empresa
         * @param {boolean} checkAll
         * @author Rafael
         */
        buscarAcessos(codigo, checkAll = true) {
            this.loadingPanelEagle = true
            new HttpRequest().Get(this.url+'buscar/acessos/'+codigo)
                .then(({data, status}) => {
                    if(status && data) {
                        this.dataTable = data.permissoes
                        if(checkAll)
                            this.verificarCheckTodos()
                    }
                })
                .finally(() => {
                    this.loadingPanelEagle = false
                })
        },

        /** 
         * @description busca os perfis e as permissoes de cada itens do menu,
         * associados ao modulo checkpoint
         * @param {number} codigo perfil
         * @author Rafael
         */
        carregarPerfilPermissoes(codigo){
            this.loadingPanelEagle = true
            new HttpRequest().Get(this.url+'buscar/permissoes/'+codigo)
                .then(({data, status}) => {
                    if(status && data) {
                        this.permissoes = data.perfis
                        this.perfil = data.perfil
                        this.verificarCheckTodos()
                    }
                })
                .catch((error) => {
                    conectionError()
                })
                .finally(() => {
                    this.loadingPanelEagle = false
                })
        },

        editarPerfil(codigo) {
            this.carregarPerfilPermissoes(codigo)
        },

        /** 
         * @description exclui o perfil de acesso e as permissoes do mesmo, 
         * associados ao modulo checkpoint
         * @param {number} codigo perfil
         * @author Rafael
         */
        excluirPerfil(codigo) {
            this.loadingPanelEagle = true
            new HttpRequest().Get(this.url+'excluir/perfil/'+codigo)
                .then(async ({data, status}) => {
                    if(status && data) {
                        this.teveAlteracao = true
                        await this.buscarAcessos(this.perfil.pecliente, false)
                        this.changeCheckTodos(true)
                    }
                })
                .catch((error) => {
                    conectionError()
                })
                .finally(() => {
                    this.loadingPanelEagle = false
                })
        },

        /** 
         * @description salva perfil de acesso e suas permissoes, se confirmado, 
         * recarrega a lista dos perfis da empresa selecionada
         * @author Rafael
         */
        salvarPerfil(){
            let body = {
                'perfil': this.perfil,
                'permissoes': this.permissoes,
            }
            this.loadingPanelEagle = true
            new HttpRequest().Post(this.url+'perfil/salvar', body)
                .then(async ({data, status}) => {
                    if(status && data) {
                        this.teveAlteracao = true
                        this.modalClean()
                        await this.buscarAcessos(body.perfil.pecliente, false)
                        this.changeCheckTodos(true)
                    }
                })
                .catch((error) => {
                    conectionError()
                })
                .finally(() => {
                    this.loadingPanelEagle = false
                })
        },
        
		fecharModal(){
            this.modalClean()
			this.$bvModal.hide(this.id)
		},

        modalOpen(){
            this.buscarPerfisCheckpoint()
        },

        modalClose(){
            this.modalClean()
            this.$emit('modalClose', this.teveAlteracao)
        },

        /** 
         * @description limpa as variaveis do formulario do modal
         * @author Rafael
         */
        modalClean() {
			this.codigo = 0
            this.perfil = {
                pecodigo:'',
                pedescricao:'',
                pecliente:''
            }
            this.dataTable = []
        },
        
        // FUNCIONALIDADES PERMISSÕES

        /** 
         * @description evento listener dos checkbox individuais de cada linha
         * @author Rafael
         */
        changeCheckItem(value, tipo) {
            this.changeCheckColuna(value, tipo, false)
        },

        /** 
         * @description evento listener dos checkbox no header de cada coluna
         * @author Rafael
         */
        changeCheckColuna(value, tipo, marcar = true, checkAll = true) {
            let totalItens = 0
            let totalChecked = 0
            this.permissoes.forEach(e => {
                e.itens.forEach(e2 => {
                    let ok = (e2[tipo] != null && tipo != 'visualizar' && e2.visualizar)
                    if(ok || tipo == 'visualizar') {
                        if(marcar) e2[tipo] = value
                        if(e2[tipo]) totalChecked++
                        totalItens++
                        if(tipo == 'visualizar' && !e2[tipo]) {
                            if(e2.cadastrar != null) {
                                e2.cadastrar = false
                                this.checkHeader.cadastrar.checked = false
                            }
                            if(e2.editar != null) {
                                e2.editar = false
                                this.checkHeader.editar.checked = false
                            }
                            if(e2.excluir != null) {
                                e2.excluir = false
                                this.checkHeader.excluir.checked = false
                            }
                        }
                    }
                })
            })
            let checked = totalChecked > 0 && totalItens == totalChecked
            let idtm = totalChecked > 0 && totalItens != totalChecked
            this.checkHeader[tipo].checked = checked
            this.checkHeader[tipo].indeterminate = idtm
            if(checkAll) this.verificarCheckTodos()
        },

        /** 
         * @description evento listener do checkbox de selecionar todos (th 'Opções').
         * Chama outras funções que fazem um checkup geral de cada coluna e cada item.
         * @param {boolean} value
         * @author Rafael
         */
        changeCheckTodos(value) {
            this.changeCheckColuna(value, 'visualizar', true, false)
            this.changeCheckColuna(value, 'cadastrar', true, false)
            this.changeCheckColuna(value, 'editar', true, false)
            this.changeCheckColuna(value, 'excluir', true, false)
            this.verificarCheckTodos()
        },
        
        /** 
         * @description percorre todas as permissoes para validar cada item de checkbox,
         * incluindo o header.
         * 
         * **Regras básicas:**
         * * Se marcado visualizar, habilita os demais checkbox
         * * Se desmarcado visualizar, bloqueia os demais checkbox
         * * Se pelo menos 1 estiver marcado na coluna, então 'indeterminate' = true
         * @param {boolean} value
         * @author Rafael
         */
        verificarCheckTodos() {
            let soma = {v:0,c:0,e:0,d:0}
            let unico = {v:0,c:0,e:0,d:0}
            let total = 0
            this.permissoes.forEach(e => {
                e.itens.forEach(e2 => {
                    if(e2.visualizar != null){
                        if(e2.visualizar) soma.v++
                        total++
                        unico.v++
                    }
                    if(e2.cadastrar != null){
                        if(e2.cadastrar) soma.c++
                        total++
                        if(e2.visualizar) unico.c++
                    }
                    if(e2.editar != null){
                        if(e2.editar) soma.e++
                        total++
                        if(e2.visualizar) unico.e++
                    }
                    if(e2.excluir != null){
                        if(e2.excluir) soma.d++
                        total++
                        if(e2.visualizar) unico.d++
                    }
                })
            })
            this.checkHeader.visualizar.checked = soma.v != 0
            this.checkHeader.cadastrar.checked = soma.c != 0
            this.checkHeader.editar.checked = soma.e != 0
            this.checkHeader.excluir.checked = soma.d != 0
            this.checkHeader.visualizar.indeterminate = unico.v != soma.v && soma.v > 0
            this.checkHeader.cadastrar.indeterminate = unico.c != soma.c && soma.c > 0
            this.checkHeader.editar.indeterminate = unico.e != soma.e && soma.e > 0
            this.checkHeader.excluir.indeterminate = unico.d != soma.d && soma.d > 0
            let todos = Object.values(soma).reduce((a, b) => a + b)
            let idtm = todos > 0 && todos != total
            let checkAll = todos > 0 && todos == total
            if(checkAll != this.checkHeader.todos.checked)
                this.checkHeader.todos.checked = checkAll
            this.checkHeader.todos.indeterminate = idtm
        },

    },
})
</script>

<style lang="scss">
#modalCadastroPermissoesCP{
    .btn-form-group{
        align-self: center;
        text-align: right;
    }
    .td-descricao{
        background-color:white;
        vertical-align: middle;
    }
    .td-valor{
        background-color:white;
        vertical-align: middle;
    }
    .td-valor-div{
        height:5px;
        width:20px;
        background-color:lightgrey;
    }
    .custom-control.custom-checkbox{
        label::after{
            cursor: pointer;
        }
    }
}
</style>