<template>
    <div>
        <modalEagle   
            :id="idModal"
            title="Ações"
            size='sm'>
            <template v-if="possuiFechamento[1]" slot="modalBody">
                <div
                    class="col-sm-12 p-3 fechamento"
                    :title="msgFechamento"
                >
                    Possui fechamento de {{ possuiFechamento[1] }}
                    <baseIcon :icon="mdiHelpCircleOutline" size="16"/>
                </div>
            </template>
            <template v-else slot="modalBody">
                <span class="spanJornada"> Jornada </span>
                <div class="col-sm-12 justpaddingbottom row">
                    <!-- Adicionar -->
                    <div class="col-sm-6 row inputAdicionarJornada">
                        <simpleButton 
                        title="Adicionar"
                        text="Adicionar"
                        event="click" 
                        :disabled="!desabilitaBotaoJornada || desabilitaPorEvento 
                            || loading"
                        @click="modalAdicionarJornada"
                        class="inputAdicionarJornada"
                        type="blue">
                        </simpleButton>
                    </div>
                    <!-- Remover -->
                    <div class="col-sm-6 row inputRemoverJornada">
                        <simpleButton 
                        title="Remover"
                        text="Remover"
                        event="click" 
                        :disabled="desabilitaBotaoJornada || desabilitaPorEvento 
                            || loading"
                        @click="modalRemoverJornada"
                        class="inputAdicionarJornada"
                        type="red">
                        </simpleButton>
                    </div>
                </div>
                <span class="spanJornada"> Evento </span>
                <div class="col-sm-12 row justpaddingbottom">
                    <!-- Adicionar -->
                    <div class="col-sm-6 row inputAdicionarJornada">
                        <simpleButton 
                        title="Adicionar"
                        text="Adicionar"
                        event="click" 
                        @click="modalOpcoesAdicionarEvento"
                        :disabled="!desabilitaBotaoEventos || loading"
                        class="inputAdicionarJornada"
                        type="blue">
                        </simpleButton>
                    </div>
                    <!-- Remover -->
                    <div class="col-sm-6 row inputRemoverJornada">
                        <simpleButton 
                        title="Remover"
                        text="Remover"
                        event="click" 
                        @click="modalOpcoesRemoverEvento"
                        :disabled="desabilitaBotaoEventos || loading"
                        class="inputAdicionarJornada"
                        type="red">
                        </simpleButton>
                    </div>
                </div>
            </template>
            <template>
                <simpleButton 
                :icon="mdiCloseThick" 
                text="Cancelar" 
                event="click" 
                @click="cancelButtonModalOpcoes"
                type="red">
                </simpleButton>
            </template>
        </modalEagle>
        <!-- Modal Adicionar Jornada -->
        <ModalAdicionarJornada 
            idModal="modalAdicionarJornada"
            ref='adicionarJornada'
            @atualizarRelatorio="atualizarRelatorio"/>
        <!-- Modal Remover Jornada -->
        <template>
            <modalDeleta
                id="1"
                @confirmaExclusao="excluirJornada">
            </modalDeleta>
        </template>
        <ModalAdicionarEvento
            idModal="modalAdicionarEventoJornada"
            ref='adicionarEvento'
            @atualizarRelatorio="atualizarRelatorio"/>
        <!-- Modal Remover Evento -->
        <template>
            <modalDeleta
                id="2"
                @confirmaExclusao="excluirEvento">
            </modalDeleta>
        </template>

        <modalJustificativaAjuste 
            ref="modalJustificativaAjusteDeleta"
            idModal="modalJustificativaAjusteDeleta"
            @confirmar="changeJustificativa"/>
    </div>
</template>
<script>
import Vue from 'vue'
import { 
    mdiCloseThick,
    mdiHelpCircleOutline
} from '@mdi/js'
import { required } from 'vuelidate/lib/validators'
import { HttpRequest } from '../../../Services/auth/HttpRequest.Service'
import { mapGetters } from 'vuex'
import { validaUltimoFechamentoPorDia } from "@/Services/Helpers/FechamentoHelper";
import { isArray } from '@vue/shared'
export default Vue.extend({
    name:'ModalAcoes',
    components:{
        modalEagle:require('@/components/Atom/Modal/ModalEagle').default,
        simpleButton:require('@/components/Atom/Buttons/SimpleButton').default,
        modalDeleta:require('@/components/Atom/ModalSpecific/ModalDeleta').default,
        ModalAdicionarJornada:require('@/components/Atom/ModalJornadaFolha/ModalAdicionarJornada').default,
        baseIcon: require("@/components/Atom/Icon/BaseIcon").default,
        ModalAdicionarEvento:require('@/components/Atom/ModalJornadaFolha/ModalAdicionarEventoJornada').default,
        modalJustificativaAjuste: require('@/components/Atom/ModalJornadaFolha/ModalJustificativaAjuste').default,
    },

    props: {
		idModal: {
			type: [Number, String],
			required: true
		},
    },
    
    validations:{
        desabilitaBotaoEventos:{
            required,
        },
        desabilitaBotaoJornada:{
            required,
        },
        desabilitaPorEvento:{
            required,
        },
    },

    data(){
        return{
            mdiCloseThick:mdiCloseThick,
            mdiHelpCircleOutline:mdiHelpCircleOutline,
            desabilitaBotaoEventos:false,
            desabilitaBotaoJornada:false,
            desabilitaPorEvento:false,
            dataExcluirJornada:[],
            colaboradorExcluirJornada:[],
            tipoExcluirModal:[],
            msgFechamento :
                "Após realizar fechamento não é possível alterar jornada dos colaboradores",
            empresas:[],
            eventosPermitemJornada: [2, 5, 6, 7, 8, 9],
            colabSelecionado: null,
            justificativa: null,
            loading: false,
            possuiFechamento: [],
        }
    },

    computed:{
        _getExcluir() { 
            return this.getExcluir()
        },
        _getCadastrar() { 
            return this.getCadastrar()
        },
    },

    methods: {
        ...mapGetters(["getExcluir", "getCadastrar"]),

        /**
         * @description Essa função deve ser utilizada por uma referência para "ativar"
         * essa modal, os argumentos da função são os necessários para 
         * conseguir popular a modal com as infomações corretas
         * @param {string} dataOrigem - data do campo selecionado
         * @param {array}  empresas - empresa selecionada no relatório
         * @param {object}  evento - objeto com varios dados da jornada passada
         * @author Vitor Hugo 🐨 
         */
        preparaModalAcoes(dataOrigem, empresas, evento) {
            this.initValues(dataOrigem, empresas, evento)
            this.possuiFechamento = validaUltimoFechamentoPorDia(
                evento.ultimoFechamento,
                evento.data
            )
        },

        preparaModalAcoesFolhaPonto(dataOrigem, empresas, evento) {
            this.initValues(dataOrigem, empresas, evento)
            this.possuiFechamento = validaUltimoFechamentoPorDia(
                evento.ultimoFechamento,
                evento.data,
                "dd/LL/yyyy"
            )
        },

        initValues(dataOrigem, empresas, evento) {
            this.desabilitaBotaoEventos = false
            this.desabilitaBotaoJornada = false
            this.desabilitaPorEvento = false
            this.dataExcluirJornada = []
            this.colaboradorExcluirJornada = []
            this.tipoExcluirModal = []
            this.dataOrigem = dataOrigem
            this.empresas = empresas
            this.colabSelecionado = evento.motorista

        },

        /**
         * @description Verifica se o botão de remover ou adicionar deve estar desabilitado
         * @author Rafael
         */
        modalAcoes(dados){
            this.dataExcluirJornada = dados.data
            this.colaboradorExcluirJornada = dados.motorista
            this.tipoExcluirModal = dados.tipoeventos
            this.desabilitaBotaoJornada = dados.inicio == ""
            this.desabilitaPorEvento = this.bloquearAdcJornada(Object.values(dados.tipoeventos))
            this.desabilitaBotaoEventos = dados.observacao == ""
            this.$bvModal.show(this.idModal)
        },

        /**
         * @description Verifica se o botão de remover ou adicionar deve estar desabilitado
         * @author Rafael
         */
        modalAcoesJornada(dados) {
            this.dataExcluirJornada = dados.data.split('-').reverse().join('/')
            this.colaboradorExcluirJornada = dados.motorista
            this.tipoExcluirModal = dados.tipoeventos
            this.desabilitaBotaoJornada = dados.inicio == ""
            this.desabilitaPorEvento = this.bloquearAdcJornada(Object.values(dados.tipoeventos))
            this.desabilitaBotaoEventos = dados.observacao == ""
            this.$bvModal.show(this.idModal)
        },
        
        /**
         * @description Modal Adicionar Jornada
         * @author Rafael
         */
        modalAdicionarJornada(){
            this.$refs
                .adicionarJornada
                .preparaModalAdicionarJornada(
                    this.empresas,
                    this.colabSelecionado,
                    this.dataExcluirJornada)
        },
        /**
         * @description Atualiza o relatório folha ponto e jornada trabalho
         * @author Rafael
         */
        atualizarRelatorio(){
            this.$emit('atualizarRelatorio')
        },

        /**
         * @description Modal Remover Jornada
         * @author Rafael
         */
        modalRemoverJornada() {
            this.modalRemover = 1
            this.$refs.modalJustificativaAjusteDeleta.preparaModal(this.empresas)
        },

        changeJustificativa(value) {
            this.justificativa = value && value.length ? value[0] : null
            this.$bvModal.show('modal-deleta-'+this.modalRemover)
        },

        /**
         * @description Método para excluir a jornada selecionada
         * @author Rafael
         */
        async excluirJornada(){
            this.loading = true
            var dados = await new HttpRequest()
                .Post('/controle/jornada/relatorios/folha/ponto/removerJornada', {
                    'data' : this.dataExcluirJornada,
                    'colab': this.colabSelecionado,
                    'justificativa': this.justificativa,
                })
                .catch(() => { '' })
                .finally(() => this.loading = false )
            if(dados.status){
                this.mensagem = 'Operação concluída com sucesso'
                this.loading = false
                this.$emit('atualizarRelatorio')
            } else {
                this.loading = false
                this.mensagem = 'Falha ao comunicar com o servidor!'
            }
        },

        /**
         * @description Modal Adicionar Evento
         * @author Rafael
         */
        modalOpcoesAdicionarEvento(){
            this.$refs
                .adicionarEvento
                .preparaModalAdicionarEventos(
                    this.empresas,
                    this.dataExcluirJornada,
                    this.colabSelecionado)
        },

        /**
         * @description Modal Remover Evento
         * @author Rafael
         */
        modalOpcoesRemoverEvento(){
            this.$bvModal.show('modal-deleta-'+2)
        },

        /**
         * @description Método para excluir eventos
         * @author Rafael
         */
        async excluirEvento(){
            this.loading = true
            var dados = await new HttpRequest()
                .Post('/controle/jornada/relatorios/folha/ponto/removerEvento', {
                    'data': this.dataExcluirJornada,
                    'colab': this.colabSelecionado,
                    'tipos': this.tipoExcluirModal,
                    'justificativa': this.justificativa,
                })
                .catch(() => { '' })
                .finally(() => this.loading = false )
            if(dados.status){
                this.mensagem = 'Operação concluída com sucesso'
                this.loading = false
                this.atualizarRelatorio()
            } else {
                this.loading = false
                this.mensagem = 'Falha ao comunicar com o servidor!'
            }   
        },

        cancelButtonModalOpcoes(){
            this.$bvModal.hide(this.idModal)
        },

        /**
         * @description Método para validar botão de adicionar jornada manual.
         * Identifica se há algum evento não permitido no array.
         * @param {array} eventos objetos com info de eventos_folha
         * @return {true|false}
         * @author Rafael
         */
        bloquearAdcJornada(eventos) {
            if (isArray(eventos)) {
                let naoPermitidos = eventos.filter(tipo => {
                    return !this.eventosPermitemJornada.includes(tipo)
                })
                return naoPermitidos && naoPermitidos.length > 0
            }
            return true
        },
    },
})
</script>

<style lang="scss">
    .fechamento {
        color:red; 
        text-align: center;
    }
</style>