/**
    Este componente é o datatable para listagem de páginas principais

    -> Propriedades:
        1- htmlRel - String/Html - o html em string para ser colocado no relatorio

    -> Slots:
<tr><th></th></tr>
        2- tbody - Slot para colocar o statusInformation

    -> Eventos:
        Sem eventos
        
 */
<template>
    <div style="padding: 0px !important">
        <div class="col-sm-12 divImprimir tableFixHead nopadding">
            <table class="table">
                <thead id="theadRelatorioCommun">
                    <slot name="thead"></slot>
                </thead>
                <tbody id="tBodyRelatorioCommun" v-if="htmlRel == ''">
                    <slot name="tbody"></slot>
                </tbody>
                <tbody id="tBodyRelatorioCommun" v-else-if="htmlRel != ''" v-html="htmlRel"></tbody>
                <thead id="tfootRelatorioCommun">
                    <slot name="tfoot"></slot>
                </thead>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
export default Vue.extend({
    props: {
        htmlRel: {
            type: String,
            default: ''
        }
    }
});
</script>

<style lang="scss">
.hrDeBaixo {
    margin-top: 140px;
}

.tableFixHead {
    overflow-y: auto;

    .chevron {
        max-width: 7px !important;
    }

    table {
        margin-top: 0 !important;
        margin-bottom: 0;
        padding-right: 10px;
        padding-left: 10px;
        max-height: 100% !important;
        width: 99.9% !important;

        thead {
            th {
                position: sticky;
                top: -1px;
                z-index: 1;
                padding: 0.2rem;
                background: #f5f5f5;
                color: #514e4e;
                font-weight: bold;
                font-size: 13px;
            }
        }

        tbody tr {
            border-bottom: 1px solid #e5e5e5;
            background-color: #efefef;
        }
    }

}

@media screen and (max-height: 1300px) {
    .tableFixHead {
        max-height: 1140px;
    }
}

@media screen and (max-height: 1250px) {
    .tableFixHead {
        max-height: 1090px;
    }
}

@media screen and (max-height: 1200px) {
    .tableFixHead {
        max-height: 1040px;
    }
}

@media screen and (max-height: 1150px) {
    .tableFixHead {
        max-height: 990px;
    }
}

@media screen and (max-height: 1100px) {
    .tableFixHead {
        max-height: 940px;
    }
}

@media screen and (max-height: 1050px) {
    .tableFixHead {
        max-height: 890px;
    }
}

@media screen and (max-height: 1000px) {
    .tableFixHead {
        max-height: 840px;
    }
}

@media screen and (max-height: 950px) {
    .tableFixHead {
        max-height: 790px;
    }
}

@media screen and (max-height: 900px) {
    .tableFixHead {
        max-height: 740px;
    }
}

@media screen and (max-height: 850px) {
    .tableFixHead {
        max-height: 690px;
    }
}

@media screen and (max-height: 768px) {
    .tableFixHead {
        max-height: 610px;
    }
}

@media screen and (max-height: 720px) {
    .tableFixHead {
        max-height: 560px;
    }
}

@media screen and (min-height: 1300.1px) {
    .tableFixHead {
        max-height: 1170px;
    }
}

.tableFixHead {
    text-align: left;

    .backColorTdPDF {
        border-bottom: 1px solid #e5e5e5;
        background-color: #fff;

        td {
            background-color: #fff;
        }

        th {
            background-color: #fff;
        }

        .newbadge {
            min-width: 30%;
            padding: 8px;
            font-weight: 500;
            line-height: 20px;
            margin-top: 20px;
            height: 31px;
            border-radius: 0px;
            text-align: center;
            color: #fff;
            background-color: #777;
            white-space: nowrap;
            float: left;
            margin-left: 0px;
            margin-bottom: 0px;
        }
    }

    .badgeSecundaria {
        min-width: 20%;
        padding: 8px;
        font-weight: 500;
        line-height: 20px;
        margin-top: 20px;
        height: 31px;
        border-radius: 0px;
        text-align: center;
        color: #fff;
        background-color: #a1a1a1;
        white-space: nowrap;
        float: left;
        margin-left: 0px;
        margin-bottom: 0px;
    }

    .rodaPe {
        width: 100%;
        padding: 8px;
        font-weight: 500;
        line-height: 20px;
        margin-top: 0px;
        height: 31px;
        border-radius: 0px;
        text-align: center;
        color: black;
        background-color: #afafaf;
        white-space: nowrap;
        float: left;
        margin-left: 0px;
        margin-bottom: 0px;
    }

    tr {
        border-bottom: 1px solid #e5e5e5;
        background-color: #efefef;

        td {
            padding-top: 0.15rem;
            padding-bottom: 0.15rem;
            font-size: 13px;
            border: 1px solid #e5e5e5;
        }

        th {
            padding-top: 0.15rem;
            padding-bottom: 0.15rem;
            outline: 1px solid #e5e5e5;
            font-size: 13px;
        }
    }
}</style>
