<template>
  <panelEagle id='cadastroDeChips' :loading='gerandoRel'>
    <div class="col-lg-12 row nopadding">
      <div class="col-lg-6 ">
        <titulo titulo='Listagem de Chips' :icon='icone'/>
      </div>
      <div class="col-lg-6 divDontPrint">
        <botoes
          :loading='loading'
          tipoNovo='button'
          linkNew='cadastrarChip'
          @exportarCadastro='exportar'/>
      </div>
    </div>
    <div class="col-sm-12"><hr></div>
    <div class="col-lg-12 row nopadding">
      <div class="col-lg-6  left divDontPrint nopadding">
          <bfilter
            label='Status'
            :arrayButtons='optFiltroStatus'
            @buttonsFiltersChange='mudaFiltroStatus'/>
      </div>
      <div class="col-lg-6  left divDontPrint nopadding">
          <bfilter
            class="nopadding"
            label='Módulos'
            :arrayButtons='optFiltroModulo'
            @buttonsFiltersChange='mudaFiltroModulos'/>
      </div>
    </div>
    <div class="col-sm-12"><hr></div>
    <div class="col-lg-12 nopadding">
      <tableL
      :data='dataTable'
      :titles='tituloTable'
      :hablitado='true'
      deleteEdit='editStatus'
      @alterarStatus='mudaStatus'
      @editarCadastro='editar'/>
    </div>
  </panelEagle>
</template>
<script>
import {HttpRequest} from '@/Services/auth/HttpRequest.Service'
import {mapGetters} from 'vuex'
import { mdiSimOutline, mdiCheckBold } from '@mdi/js'
import Vue from 'vue'
export default Vue.extend({
	name:'listagemChips',
	components:{
		'panelEagle'  : require('@/components/Atom/Panel/PanelEagle').default,
		'titulo'      : require('@/components/Atom/Header/Titulo').default,
		'botoes'      : require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
		'bfilter'     : require('@/components/Atom/Buttons/ButtonsFilters').default,
		'tableL'      : require('@/components/Atom/Table/TableListagem').default,
	},
	data(){
		return{
			//misc
			icone         : mdiSimOutline,
			mdiCheckBold    : mdiCheckBold,
			loading       : Array(false, false, false),
			dataTable     : [],
			filtroModulos : '',
			filtroStatus  : '',
			//Botoes:
			optFiltroStatus:[
				{text:'Ativos',   value:'A', width:'32%', isSelected:true, icon:mdiCheckBold},
				{text:'Inativos', value:'I', width:'32%', isSelected:false},
				{text:'Todos',    value:'T', width:'32%', isSelected:false},
			],
			optFiltroModulo:[
				{text:'Com Modulo',   value:'C', width:'32%', isSelected:true, icon: mdiCheckBold},
				{text:'Sem Modulo',   value:'S', width:'32%', isSelected:false},
				{text:'Todos',        value:'T', width:'32%', isSelected:false},
			],
			tituloTable: [
				{name:'Iccid',         number:'iccid'},
				{name:'Numero',        number:'chnumero'},
				{name:'Operadora',     number:'choperadora'},
				{name:'Serial Modulo', number:'mocodigo'},
			],
			//link
			link  : '/administrativo/cadastros/chips/',
			// misc
			loadingTable: false,
			gerandoRel: false,
		}
	},
	methods:{
		editar(value){
			this.$router.push({name:'cadastrarChip', params:{'id':value}})
		},
    
		mudaStatus(value){
			var rota = 'mudastatus';
			new HttpRequest().Post(this.link+rota, {'codigo':value}).then(()=>{
				this.fazRequest();
			})
		},

		mudaFiltroModulos(value){
			this.filtroModulos = value[0];
			this.optFiltroModulo[0].icon = ''
			this.optFiltroModulo[1].icon = ''
			this.optFiltroModulo[2].icon = ''
			if(value == 'C'){
				this.optFiltroModulo[0].icon = mdiCheckBold
			} else if(value == 'S'){
				this.optFiltroModulo[1].icon = mdiCheckBold
			} else if(value == 'T'){
				this.optFiltroModulo[2].icon = mdiCheckBold
			}
			this.fazRequest();
		},

		mudaFiltroStatus(value){
			this.filtroStatus = value[0];
			this.optFiltroStatus[0].icon = ''
			this.optFiltroStatus[1].icon = ''
			this.optFiltroStatus[2].icon = ''
			if(value == 'A'){
				this.optFiltroStatus[0].icon = mdiCheckBold
			} else if(value == 'I'){
				this.optFiltroStatus[1].icon = mdiCheckBold
			} else if(value == 'T'){
				this.optFiltroStatus[2].icon = mdiCheckBold
			}
			this.fazRequest();
		},

		fazRequest(){
			var obj = this.criaObj();
			this.gerandoRel = true
			var rota = 'listar'
			new HttpRequest().Post(this.link+rota, obj).then((data)=>{
				this.dataTable = data.data.chip;
				this.gerandoRel = false
			})
		},

		exportar(value){
			var root =  process.env.VUE_APP_ROOT
			var rota = 'exportar'
			this.ativaRodinha(value)
			var obj = {'para':value, 'dados':this.dataTable}
			new HttpRequest().Post(this.link+rota, obj).then((data)=>{
				window.open(root+'/'+data.data.local)
				this.ativaRodinha()
			})
		},

		ativaRodinha(tipo=null){
			if(tipo=='pdf'){
				this.loading = Array(true, false, false)
			}else if(tipo=='xls'){
				this.loading = Array(false, true, false)
			}else if(tipo=='csv'){ 
				this.loading = Array(false, false, true)
			}else{
				this.loading = Array(false, false, false)
			}
		},

		criaObj(){
			var obj = {
				status: this.filtroStatus,
				modulo: this.filtroModulos
			}
			return obj;
		},
    
		selectAllClientes(){
			if(this.getMaster())
				this.firstOne = []
			else  
				this.firstOne =  new EmpresasService().Get()
		},
		...mapGetters(['getMaster']),
	},
})
</script>

<style scoped>
.left{
  text-align: left !important;
}
</style>