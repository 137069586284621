import { RouteConfig } from 'vue-router';
import { AuthService } from '../../../Services/auth/Auth.Service';
import Cadastros from '../../Children.vue'
import NaoMenu from '../../Children.vue';
import Relatorio from '../../Children.vue'
import Layout from '../../../components/Atom/Layout/Layout.vue';
import ListagemPontuacao from '@/views/GestaoDesempenho/Cadastros/ListagemPontuacao.vue';
import CadastroPontuacao from '@/views/GestaoDesempenho/Cadastros/CadastroPontuacao.vue';
import AnaliseGeral from '@/views/GestaoDesempenho/Relatorios/AnaliseGeral.vue'
import AnaliseColaborador from '@/views/GestaoDesempenho/Relatorios/AnaliseColaborador.vue'
import Processamento from '@/views/GestaoDesempenho/NaoMenus/Processamento.vue';
import DashboardGestaoDesempenho from '@/views/GestaoDesempenho/Dashboard/DashboardGestaoDesempenho.vue'

export const ifAuthenticated = (_to: any, _from: any, next: any) => {
	if (new AuthService().isAuthenticated()) {
		next()
		return
	}
    type numero= 'N' | 'E';
    next('/login')
}

export const GestaoDesempenhoRoutes: RouteConfig =
{
	path: '/gestao/desempenho',
	name: 'driver',
	beforeEnter: ifAuthenticated,
	meta: {
		requiresAuth: true,
		Module: 'gestao/desempenho',
		breadcrumbName: 'EagleDriver'
	},
	components: { default: Layout },
	children: [
		{
			path:'cadastros',
			name: 'cadastros',
			meta: {
				Module: 'gestao/desempenho',
				breadcrumbName: 'Gestão de Desempenho'
			},
			components:  {content:Cadastros},
			children: [
				{
					path: 'pontuacao',
					name: 'pontuacao',
					meta: {
						Module: 'gestao/desempenho',
						breadcrumbName: 'Pontuação'
					},
					component: ListagemPontuacao
					// /gestao/desempenho/cadastros/pontuacao  
				},
				{
					path: 'pontuacao/novo',
					name: 'cadastroPontuacao',
					meta: {
						Module: 'gestao/desempenho',
						breadcrumbName: 'Pontuação'
					},
					component: CadastroPontuacao
					// /gestao/desempenho/cadastros/pontuacao  
				},
				{
					path: 'pontuacao/novo/:id',
					name: 'editarPontuacao',
					meta: {
						Module: 'gestao/desempenho',
						breadcrumbName: 'Gestão de Desempenho'
					},
					component: CadastroPontuacao
					// /gestao/desempenho/cadastros/pontuacao  
				}
			]
		},
		{
			path:'relatorios',
			name: 'relatorios',
			meta: {
				Module: 'gestao/desempenho',
				breadcrumbName: 'Gestão de Desempenho'
			},
			components:  {content:Relatorio},
			children: [
				{
					path: 'analise/geral',
					name: 'analiseGeral',
					meta: {
						Module: 'gestao/desempenho',
						breadcrumbName: 'Análise Geral'
					},
					component: AnaliseGeral
				},
				{
					path: 'analise/colaboradores',
					name: 'analiseColaboradores',
					meta: {
						Module: 'gestao/desempenho',
						breadcrumbName: 'Análise dos Colaboradores'
					},
					component: AnaliseColaborador
				},
			],
		},
		//Não menus
		{
			path:'',
			meta: {
				Module: 'gestao/desempenho',
				breadcrumbName: ''
			},
			components:  {content:NaoMenu},
			children: [
				{
					path: 'processamento',
					name: 'gestaoProcessamento',
					meta: {
						Module: 'gestao/desempenho',
						breadcrumbName: 'Processamento'
					},
					component: Processamento
					// /gestao/desempenho/processamento  
				},
				// Dashboard
				{
                    path: '',
                    name: 'DashboardGestaoDesempenho',
                    meta: {
                        Module: 'gestao/desempenho',
                        breadcrumbName: 'Dashboard Gestão Desempenho'
                    },
                    component: DashboardGestaoDesempenho
                },
			]
		},
	],
}
