/* eslint-disable vue/no-unused-components */
<template>
 <div>
     <eagle-menu></eagle-menu>
  </div>
</template>
<script>
import Menu from "../Menu/Menu.vue"

export default {

  name: "naveg",
  components: {
    'eagle-menu':Menu
  },methods: {
  },
};

</script>