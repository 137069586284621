<!-- 
    * dados:
    NSR - Valor sequencial de registro (clcseq)
    Tipo reg. - 2 (Valor fixo)
    Data e hora gravação - Data e hora da inserção no banco (clcdatahora)
    CPF cadastro - Valor CPF de quem cadastrou (clccpfcadastro)
    Tipo doc. - Valor 1 para CNPJ | 2 para CPF
    Documento - CNPJ | CPF (cldocumento)
    Razão social - razão social da empresa (clnome)
    Local - Local da empresa (cllogradouro+clnumero+clbairro+cinome)
    CRC-16 - Valor verificação cíclica de redundância (será feito futuramente)
 -->
<template>
    <div class="mt-4 col-12 nopadding">
        <tableRelatorio>
            <template slot="thead">
            </template>
            <template slot="tbody">
                <tr class="backColorTdPDF">
                    <td colspan="9" class="nopadding">
                        <span class="newbadge">
                            {{dados.descricao}}
                        </span>
                    </td>
                </tr>
                <tr class="trTdHeader">
                    <td>NSR</td>
                    <td>Tipo reg.</td>
                    <td>Data / hora gravação</td>
                    <td>CPF cadastro</td>
                    <td>Tipo doc.</td>
                    <td>Documento</td>
                    <td>Razão social</td>
                    <td>Local</td>
                    <td>CRC-16</td>
                </tr>
                <template v-if="dados.itens && dados.itens.length">
                    <tr v-for="(item, i) in dados.itens"
                        :key="i+'_item'">
                        <td>{{item.nsr}}</td>
                        <td>{{item.tiporegistro}}</td>
                        <td>{{item.datahoragravacao}}</td>
                        <td>{{item.cpfcadastro}}</td>
                        <td>{{item.tipodocumento}}</td>
                        <td>{{item.documento}}</td>
                        <td>{{item.razaosocial}}</td>
                        <td>{{item.local}}</td>
                        <td>{{item.crc16}}</td>
                    </tr>
                </template>
                <template v-else>
                    <tr class="paddingTd">
                        <td colspan="9" style="padding:0!important">
                            <div class="tableDataEmpty">
                                <span class="textEmpty">Sem dados</span>
                            </div>
                        </td>
                    </tr>
                </template>
            </template>
        </tableRelatorio>
    </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
    name:'registroTipo2',
    components:{
		tableRelatorio: require('@/components/Atom/Table/TableRelatorio').default,
    },
    props:{
        dados:{
            type: Object,
            required: true,
            default: function(){return {}}
        },
    },
})
</script>

<style lang="scss">
    
</style>