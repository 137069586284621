<template>
    <div class="telemetry-list">
        <div class="telemetry-list__table-container">
            <div v-if="!hasEvents" class="telemetry-list__empty">
                Nenhum evento encontrado para o intervalo selecionado
            </div>
            <table v-else class="telemetry-list__table">
                <tr>
                    <th>Data/Hora</th>
                    <th>Evento</th>
                    <th>Local</th>
                </tr>
                <tr v-for="event in computedData">
                    <td>{{ event.dateTime.toFormat('dd/MM/yy HH:mm:ss') }}</td>
                    <td>{{ event.label }}</td>
                    <td 
                        class="telemetry-list__button-cell" 
                        :class="event.latlog ? 'telemetry-list__button-cell--interactive' : ''"
                        @click="event.latlog && $emit('show-location', event.latlog)"
                    >
                        <base-icon
                            :size="12"
                            :icon="mdiCrosshairsGps" 
                        />
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { DateTime } from 'luxon'
import { computed, inject, PropType, Ref } from 'vue'

import baseIcon from "@/components/Atom/Icon/BaseIcon.vue";
import { mdiCrosshairsGps } from "@mdi/js";
import { EventCodes, EVENT_LABELS, TelemetryData, TelemetryEvent } from '@/Services/vehicle/telemetry.service'

defineEmits<{
    'show-location': (latlog: [number, number]) => void
}>()

const props = defineProps({
    eventCodes: { type: Array as PropType<EventCodes[]>, required: true }
})

const telemetryData = inject('telemetryData') as Ref<TelemetryData>

interface EventListItem {
    label: string
    dateTime: DateTime,
    latlog?: [number, number]
}
interface ZoomRange {
    min: number | null;
    max: number | null;
}

const zoomRange = inject<Ref<ZoomRange>>('zoomRange');
if (!zoomRange) {
    throw new Error('zoomRange is not provided');
}

const mapEventsToListItems = ([key, value] : [string, TelemetryEvent[]]): EventListItem[] => {
    return value.map((event: TelemetryEvent, index: number) => {
        return {
            label: EVENT_LABELS[parseInt(key) as EventCodes],
            dateTime: event.dateTime,
            latlog: event.latlog
        }
    })
}
const computedData = computed((): EventListItem[] => {
    const min = zoomRange.value.min ?? -Infinity;
    const max = zoomRange.value.max ?? Infinity;

    return Object.entries(telemetryData.value)
        .filter(([key]) => props.eventCodes.includes(parseInt(key)))
        .flatMap(([key, events]) => {
            return events.filter(event => {
                const eventTime = event.dateTime.toMillis();
                return eventTime >= min && eventTime <= max;
            })
            .map(event => ({
                label: EVENT_LABELS[parseInt(key) as EventCodes],
                dateTime: event.dateTime,
                latlog: event.latlog
            }));
        })
        .sort((a, b) => b.dateTime.toMillis() - a.dateTime.toMillis());
});

const hasEvents = computed(() => computedData.value.length > 0)

</script>
<style lang="scss">
.telemetry-list {
    width: 300px;
    padding-left: 1rem;

    &__empty {
        height: 100%;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    &__table-container {
        overflow-y: auto;
        height: 100%;
        text-align: left;
    }

    &__table {
        width: 100%;

        & tr:hover  {
            background-color: #e8e8e8;
        }

        & td {
            font-size: 12px;
        }
    }

    &__button-cell {
        width: min-content;
        text-align: center;
        color: #ccc;

        &--interactive {
            cursor: pointer;
            color: #5bc0de;
        }
    }
}
</style>