<template>
 <panel-eagle id="registro-checkpoint" :loading='loading'>
    <div class="col-12 row">
        <div class="col-4 nopadding">
            <titulo 
            :icon='mdiClipboardClockOutline'
            titulo='Registros'/>
        </div>
        <div class="col-8 nopadding">
            <basicButtonsRelatoriosEIG
            :loading='loadingExportar'
            :disabledDropdown='!Object.values(tableData).length'
            :disabled='$v.$invalid'
            @gerarRelatorio='gerarRelatorio'
            @exportarRelatorio='exportarRelatorio'>
            </basicButtonsRelatoriosEIG>
        </div>
    </div>
    <slide-up-and-down>
        <div class="col-sm-12"><hr></div>
        <div class="col-12 nopadding row mb-2">
            <div class="col-4 nopadding">
                <input-range
                :isObrigatorio='true'
                name='range-extrato'
                @changeInput='changeIntervalo'/>
            </div>
            <div class="col-4 nopadding">
                <select-all
                nameForRadio='selCliente'
                :isMultiple='true'
                :hasSelectAll='true'
                :labels='labelSelectCliente'
                :optionsArray='optClientes'
                firstSelected='MR'
                :extraClass='{
                    "border border-danger":$v.clienteSelecionado.$anyError
                }'
                @close='$v.clienteSelecionado.$touch()'
                @changeSelect='changeEmpresa'/>
            </div>
            <div class="col-4 nopadding">
                <select-all
                nameForRadio='selTrabalhador'
                ref='selTrabalhador'
                :labels='labelSelectTrabalhador'
                :isMultiple='true'
                :disabled='desabilitaSeletorT'
                :hasSelectAll='true'
                :loading='loadingTrabalhador'
                :optionsArray='optSelectWorker'
                :extraClass='{
                "border border-danger":$v.trabalhadorSelecionado.$anyError
                }'
                firstSelected='MAD'
                @close='$v.trabalhadorSelecionado.$touch()'
                @changeSelect='changeTrabalhador'
                @changeCheck ='changeCTrabalhador'/>
            </div>
        </div>
    </slide-up-and-down>
    <div class="col-sm-12"><hr></div>
    <div class="mt-2 col-12 nopadding">
        <tableRelatorio>
            <slot slot="thead">
                <tr>
                    <th>Registros</th>
                    <th>Endereço</th>
                </tr>
                <!-- <thead>
                </thead> -->
            </slot>
            <slot slot="tbody">
                <br>
                <statusBar
                    v-if ="tableData.length == 0"
                    style=""
                    colspan="0"
                    :statusBar='statusBar'
                    typeBar='tr'
                />
                <template v-else>
                    <template v-for="(dados, colaborador) in tableData">
                        <tr :key="colaborador+'badge'" class="backColorTdPDF">
                            <td colspan="20" style="padding: 0px;">
                                <span class="newbadge">
                                    {{colaborador}}
                                </span>
                            </td>
                        </tr>
                        <template v-for="(d, dias) in dados">
                            <tr :key="colaborador+dias+'dia'" class="backColorTdPDF">
                                <td colspan="20" style="padding: 0px;">
                                    <span class="badgeSecundaria">
                                        {{dias}}
                                    </span>
                                </td>
                            </tr>
                            <template v-for="(dados, index) in d">
                                <tr colspan='3' :key="index+dias+colaborador+'horas'">
                                    <td  class="horarios">
                                        {{dados.hora}}
                                    </td>
                                    <td  class="horarios">
                                        {{dados.crendereco}}
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </template>
                </template>
                <br>
            </slot>
        </tableRelatorio>
    </div>
    </panel-eagle>
</template>

<script>
import { mdiClipboardClockOutline } from '@mdi/js';
import { DateTime } from 'luxon'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { EmpresasService } from '../../../Services/auth/Empresas.service'
import { FiltrosService }  from '../../../Services/filtros/filtros.Service'
import { HttpRequest }     from '../../../Services/auth/HttpRequest.Service'
import { conectionError } from '@/Services/Helpers/swellHeper'
const urlBase = '/checkpoint/relatorios/registro/'

export default {
    name:'RegistroCheckpoint',
    components:{
        // modalDeleta   : require('@/components/Atom/ModalSpecific/ModalDeleta.vue').default,
        // modalEagle    : require('@/components/Atom/Modal/ModalEagle.vue').default,
        slideUpAndDown: require('@/components/Atom/SlideUpAndDown/SlideUpAndDown.vue').default,
        panelEagle    : require('@/components/Atom/Panel/PanelEagle').default,
        basicButtonsRelatoriosEIG : require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
        titulo        : require('@/components/Atom/Header/Titulo').default,
        selectAll     : require('@/components/Atom/Select/SelectAll').default,
        inputRange    : require('@/components/Atom/Datas/InputRangeWithLimit').default,
        tableRelatorio: require('@/components/Atom/Table/TableRelatorio').default,
        statusBar     : require('@/components/Atom/StatusInformation/StatusInformation.vue').default,
        // simpleButton  : require('@/components/Atom/Buttons/SimpleButton.vue').default,
    },
    data(){
        return{
            mdiClipboardClockOutline: mdiClipboardClockOutline,
            statusBar: 'info',
            loading: false,
            loadingExportar: [false, false, false],
            mostraTabela:false,
            loadingTrabalhador:false,
            quitarTodos: false,
            fecharTodos: false,
            tableData: [],
            //opts
            optClientes: new EmpresasService().Get(),
            optTrabalhador:{
                'MAD':[],
                'D':[]
            },
            labelTrabalhador:'MAD',
            //labels
            labelSelectTrabalhador:[
                {indexDFH:'MAD', description:'Colaborador*'},
                {indexDFH:'D', description:'Departamento'}
            ],
            labelSelectCliente:[
                {indexDFH:'cc', description:'Empresas*'}
            ],
            //selecionados
            clienteSelecionado: [],
            trabalhadorSelecionado: [],
            intervalo:'',
        }
    },
    validations:{
        clienteSelecionado:{ required },
        trabalhadorSelecionado:{ required }
    },
    methods:{
        
        gerarRelatorio(){
            var url = `${urlBase}gerar/relatorio`
            var obj = {
                intervalo: this.intervalo,
                empresas: this.clienteSelecionado,
                colaborador:this.trabalhadorSelecionado,
                tipo: this.labelTrabalhador,
            }
            this.tableData = [];
            this.loading = true;
            new HttpRequest().Post(url, obj).then((res) => {
                this.tableData = res.data ?? []
                if(Object.values(this.tableData).length == 0){
                    this.statusBar = 'error';
                }else {
                    this.statusbar = 'error2'
                }
            }).catch(() => {
                conectionError()
            }).finally(() => this.loading = false)
        },

        /**
         * @param {object} obj
         * @param {string} obj.fpultima  data no formato ISO
         * @return {string} no formato dd/mm/yyyy
         */
        formataData({fpultima}){
            var data = DateTime.fromISO(fpultima)
            return data.toFormat('dd/LL/yyyy')
        },

        /**
         * @listens changeCheck 
         * @param {'MAD'|'D'} label
         */
        changeCTrabalhador(label){
            this.tableData = []
            this.labelTrabalhador = label
        },

        /**
         * @listens changeSelect
         * @param {number[]} arr
         */
        changeTrabalhador(arr){
            this.tableData = []
            this.trabalhadorSelecionado = arr
        },
        
        /**
         * @listens changeSelect
         * @param {number[]} arr - clientes selecionados
         * @description muda o cliente selecionado e busca pelas
         * opts do seletor de funcionário
         */
        changeEmpresa(arr){
            this.tableData = []
            this.clienteSelecionado = arr 
            this.trabalhadorSelecionado = []
            this.$refs.selTrabalhador.clearAll()
            this.$v.trabalhadorSelecionado.$reset()
            // this.optTrabalhador = {
            //     'MAD':[],
            //     'A':[]
            // }
            if(arr.length){
                this.loadingTrabalhador = true
                new FiltrosService()
                    .dados_filtros(this.clienteSelecionado, ['D', 'MAD'])
                    .then((res)=>{
                        this.optTrabalhador = res
                    }).catch(()=>
                        conectionError()
                    ).finally(()=>{this.loadingTrabalhador = false})
            }
        },

        /**
         * @listens changeInput
         * @param {string} intervalo formato dd/mm/yyyy - dd/mm/yyyy
         */
        changeIntervalo(intervalo){
            this.intervalo = intervalo
        },

        exportarRelatorio(formato){
            let uri = `${urlBase}exportar/${formato}`
            let obj = {
                intervalo: this.intervalo,
                data:this.tableData
            }
            this.decideExportar(formato)
            new HttpRequest().Post(uri, obj)
                .then((res)=>{
                    var root =  process.env.VUE_APP_ROOT;
                    open(root+'/'+res.data.local)
                })
                .finally(()=>{this.decideExportar('none')})
        },

        /**
         * @param {'pdf'|'xls'|'csv'|'none'} formato
         */
        decideExportar(formato){
            this.loadingExportar = [
                formato === 'pdf',
                formato === 'xls',
                formato ==='csv'
            ]
        },
    },
    computed:{
        optSelectWorker(){
            return this.optTrabalhador[this.labelTrabalhador]
        },
        desabilitaSeletorT(){
            if(this.clienteSelecionado.length)
                return false
            return true
        },
    }
}
</script>

<style lang ='scss'>
#registro-checkpoint{	
    .horarios{
        text-align:justify !important;
        // font-size: 2vh;
        width:100vw;
    }
}

</style>