/**
    Modal de fornulário para adicionar/editar um item rota do tipo parada

    -> Propriedades:
        1- rota - Object - Objeto do tipo Rota
        2- selecionados - Array - array de objetos do tipo [ItensRota] com [Pontos]

    -> Slot:
        Sem slot

    -> Eventos:
        1- registroSalvo - Emite quando salva com sucesso - Sem parâmetro
 */
<template>
    <!-- @modalOpen="getPontos"  -->
    <modalEagle
        :hardToClose="true"
        @modalClose="modalClose" 
        title="Adicionar parada" 
        id="modal-adicionar-parada">
        <template #modal-cancel><div/></template>
        <template #modal-header-close><div/></template>
        <slot slot="modalBody">
            <panelEagle :loading="loadingPanel" id="panelAddItemRotaParada">
                <div class="col-sm-12 row nopadding">
                    <div class="col-sm-12 nopadding row">
                        <div class="col-sm-8 nopadding">
                            <!-- <div v-if="pontoSelecionadoEdicao.length">
                                <span>{{ pontoSelecionadoEdicao[0].description }}</span>
                            </div> -->
                            <selectAll
                                ref="refSelectAlPonto"
                                :disabled="pontoSelecionadoEdicao.length > 0"
                                :extraClass='{"border border-danger": 
                                    $v.pontoSelecionado.$anyError}'
                                @close='$v.pontoSelecionado.$touch()'
                                @changeSelect="changePonto" 
                                :isMultiple="false" 
                                nameForRadio="pontoCadItemRota" 
                                :hasSelectAll="false" 
                                :labels="[{indexDFH: 'P', description: 'Ponto*'}]" 
                                :optionsArray="arrayPontos" 
                                :selected="pontoSelecionadoEdicao"
                                firstSelected="P">
                            </selectAll>
                        </div>
                    </div>
                    <div class="col-sm-12 row divDeBaixo">
                        <div class="col-sm-6 nopadding">
                            <selectAll
                                ref="refSelectTipo"
                                :extraClass='{"border border-danger": 
                                    $v.tipoParada.$anyError}'
                                @close='$v.tipoParada.$touch()'
                                @changeSelect="changeTipoParada" 
                                :isMultiple="false" 
                                nameForRadio="pontoCadItemRota" 
                                :hasSelectAll="false" 
                                :labels="[{indexDFH: 'D', description: 'Tipo*'}]" 
                                :selected="tipoParadaEdicao"
                                :optionsArray="[
                                    {'value':'D', 'description': 'Descanso'},
                                    {'value':'I', 'description': 'Intervalo'}
                                ]">
                            </selectAll>
                        </div>
                        <div class="col-sm-4 nopadding">
                            <inputSimple
                                ref="refTempoParada"
                                :extraClass="'nopadding'"
                                type="time"
                                @changeInput="changeInputTempo"
                                label="Tempo médio de parada"
                                name="tempoMedioParada"
                                :value="tempoParado"
							/>
                        </div>
                    </div>
                </div>
            </panelEagle>
        </slot>
        <slot name="modal-footer">
            <!-- <div v-show="alertaPontoSalvo" class="text-center cor-vermelha letra-branca">
                <span>
                    Esta parada já está salva. Você não pode salvá-la novamente.
                </span>
            </div> -->
            <simpleButton
                :disabled="loadingPanel"
                :icon="mdiCloseThick" 
                @click="cancelButton" 
                text="Fechar" 
                event="click" 
                type="red">
            </simpleButton>
            <simpleButton
                :disabled="$v.$invalid || loadingPanel || (!editado && dadosEdicao)"
                :icon="mdiCheckBold" 
                @click="salvarParada" 
                text="Confirmar" 
                event="click" 
                type="blue">
            </simpleButton>
        </slot>
    </modalEagle>
</template>

<script lang="js">

import Vue from 'vue'
import { mdiCloseThick, mdiCheckBold } from '@mdi/js'
import { FiltrosService } from '../../../Services/filtros/filtros.Service'
import { HttpRequest } from '../../../Services/auth/HttpRequest.Service'
import { required } from 'vuelidate/lib/validators'
import { converteSegundosEmHoras } from '@/views/Roteirizador/NaoMenus/RotaManual'
import { Duration } from 'luxon'

export default Vue.extend({
	name:'ModalAddItemRotaParada',
	validations:{
		pontoSelecionado:{ required },
		tipoParada:{ required },
	},

	props:{
		rota:{
			type: [Array, Object],
			required: true,
			default: ()=>{return[]}
		},
		selecionados:{
			type: Array,
			required: true,
		},
		filtros:{
			type: Object,
			required: false,
			default: ()=>{return[]}
		},
	},

	components:{
		'selectAll': require('../Select/SelectAll').default,
		'modalEagle': require('../Modal/ModalEagle').default,
		'inputSimple': require('../Inputs/InputSimple').default,
		'panelEagle': require('@/components/Atom/Panel/PanelEagle.vue').default,
		'simpleButton': require('@/components/Atom/Buttons/SimpleButton.vue').default,
	},

	data(){
		return{
			mdiCheckBold:mdiCheckBold,
			mdiCloseThick:mdiCloseThick,
			loadingPanel:false,
			clienteCodigo: this.rota.empresa,
			arrayPontos: [],
			pontoSelecionado: '',
			tempoParado: '',
			tipoParada: [],
			isLoading: false,
			// alertaPontoSalvo:false,
            dadosEdicao: '',
            pontoSelecionadoEdicao: [],
            tipoParadaEdicao: [],
            editado: false,
		}
	},

	methods:{
		
        /**
         * @listens changeInput
         * @param {Array} value
         */
         changePonto(value){
			// this.alertaPontoSalvo = false
            this.editado = true
			this.pontoSelecionado = value[0]
		},

        /**
         * @listens changeInput
         * @param {String} value
         */
		changeInputTempo(value){
            this.editado = true
			this.tempoParado = value
		},

        /**
         * @listens changeInput
         * @param {Array} value
         */
		changeTipoParada(value){
            this.editado = true
			this.tipoParada = value
		},

        /**
         * @description Essa função deve ser chamada antes de abrir o modal,
         * principalmente quando for a edição de um pedido
         * @param {Object} dados objeto com informações de ItensRota e Ponto
         * @author Rafael
         */
        preparaModal(dados){
            if(dados) {
                this.dadosEdicao = dados
                this.setDadosEdicao()
            } else {
                this.getPontos()
            }
        },

        /**
         * @description Busca os pontos do cliente para o select de pontos
         * @author Rafael
         */
		async getPontos(){
            // this.alertaPontoSalvo = false
            this.loadingPanel = true
            var data = await new FiltrosService().dados_filtros([this.clienteCodigo], ['P'])
            if(data && data.P) {
                this.arrayPontos = data.P
            }
            this.loadingPanel = false
		},

        /**
         * @description Quando é edição do pedido, chama essa função para mapear os dados
         * @author Rafael
         */
        setDadosEdicao(){
            if(this.dadosEdicao){
                if(this.dadosEdicao.ircodigoexterno) {
                    this.pontoSelecionadoEdicao = [
                        {
                            value:this.dadosEdicao.ircodigoexterno, 
                            description:this.dadosEdicao.irnome
                        }
                    ]
                    this.arrayPontos = this.pontoSelecionadoEdicao
                    this.pontoSelecionado = this.dadosEdicao.ircodigoexterno
                }
                if(this.dadosEdicao.irtempoparado) {
                    this.tempoParado = converteSegundosEmHoras(
                        this.dadosEdicao.irtempoparado)
                }
                if(this.dadosEdicao.irinterjornada) {
                    this.tipoParadaEdicao = [{value:'D', description:'Descanso'}]
                }
                if(this.dadosEdicao.irintrajornada) {
                    this.tipoParadaEdicao = [{value:'I', description:'Intervalo'}]
                }
                if(this.tipoParadaEdicao.length){
                    this.tipoParada = [this.tipoParadaEdicao[0].value]
                }
            }
            this.$nextTick(()=>{
                this.editado = false
            })
        },

        /**
         * @description Formata e envia as informações para o servidor para salvar
         * @listens click
         * @author Rafael
         */
		async salvarParada(){
            this.loadingPanel = true
            this.tempoParado = this.tempoParado && this.tempoParado.length == 5 
                ? this.tempoParado+':00' : this.tempoParado
            let body = {
                'ponto': this.pontoSelecionado, 
                'tipo': this.tipoParada[0], 
                'tempo': this.tempoParado,
                'edicao': this.dadosEdicao ? true : false,
                'ircodigo': this.dadosEdicao ? this.dadosEdicao.ircodigo : '',
                'cliente': this.rota.empresa,
                'filtros': this.filtros,
            }
            let url = '/roteirizador/rota/manual/parada/salvar'
            try {
                const { status, data, code} = await new HttpRequest().Post(url, body)
                this.loadingPanel = false
                if(code == 201) {
                    this.mostrarMensagemSalvar()
                    this.setDadosRetorno()
                    this.$refs.refSelectAlPonto.clearAll()
                    this.$refs.refSelectTipo.clearAll()
                    this.$refs.refTempoParada.clearAll()
                    if(this.dadosEdicao) {
                        this.$emit('registroSalvo', this.dadosEdicao, true)
                        this.dadosEdicao = ''
                        this.$bvModal.hide('modal-adicionar-parada')
                    } else {
                        if(data.item) {
                            this.$emit('registroSalvo', data.item)
                        }
                        this.resetForm()
                    }
                } else {
                    this.mostrarMensagemSalvar(false)
                }
            } catch (error) {
                this.mostrarMensagemSalvar(false)
            }
		},

        setDadosRetorno(){
            if(this.dadosEdicao && this.editado){
                if(this.tempoParado) {
                    let tempo = this.tempoParado.length == 5 
                        ? this.tempoParado+':00' : this.tempoParado
                    let duracao = Duration.fromISOTime(tempo)
                    let duracaoSeg = ((duracao.hours*60+ duracao.minutes)*60)+ duracao.seconds
                    this.dadosEdicao.irtempoparado = duracaoSeg
                } else {
                    this.dadosEdicao.irtempoparado = 0
                }
                this.dadosEdicao.irinterjornada = this.tipoParada 
                    && this.tipoParada.length 
                    && this.tipoParada[0] == 'D'
                this.dadosEdicao.irintrajornada = this.tipoParada 
                    && this.tipoParada.length 
                    && this.tipoParada[0] == 'I'
            }
        },

        /**
         * @description Mostra um toast com mensagem de sucesso ou erro
         * @param {boolean} sucesso Indica se a request teve sucesso ou não
         */
        mostrarMensagemSalvar(sucesso=true) {
            if(sucesso) {
                let tipo = this.dadosEdicao ? 'alterada' : 'registrada'
                this.toastShow(`Parada ${tipo} com sucesso`, 'success', '')
            } else {
                this.toastShow('Não foi possível concluir a operação', 'danger')
            }
        },

        /**
         * @description Função para fechar o modal
         * @listens click
         * @author Rafael
         */
		cancelButton(){
			this.$bvModal.hide('modal-adicionar-parada')
		},

        /**
         * @description Limpa todas as variáveis do componente, exceto [clienteCodigo]
         * @listens modalClose
         * @author Rafael
         */
        modalClose() {
            this.resetDados()
        },

        /**
         * @description Limpa todas as variáveis do componente, exceto [clienteCodigo]
         * @author Rafael
         */
        resetDados() {
            this.resetForm()
            this.loadingPanel = false
			this.arrayPontos = []
			this.isLoading = false
            this.dadosEdicao = ''
            this.pontoSelecionadoEdicao = []
            this.tipoParadaEdicao = []
            this.editado = false
			// this.alertaPontoSalvo:false
        },

        /**
         * @description Limpa as variáveis com informações do formulário e da validação
         * @author Rafael
         */
        resetForm() {
            this.tempoParado = ''
            this.pontoSelecionado = []
            this.tipoParada = []
            this.$v.$reset()
        },

        /**
         * @description Método para mostrar o toast na tela
         * @param {string} msg - mensagem que vai aparecer no corpo do toast
         * @param {string} type - qual o tipo do toast 
         * @param {string} title - título para inserir no cabeçalho
         * @author Rafael
        */
        toastShow(msg, type, title = 'Ops!'){
            this.$bvToast.toast(`${msg}`, {
                autoHideDelay: 2500,
                variant: type,
                title: title
            })
        },

	},

	watch:{
		'rota.tempoParada'(){
            if(this.dadosEdicao && this.dadosEdicao.irtempoparado) {
                this.tempoParado = converteSegundosEmHoras(this.dadosEdicao.irtempoparado)
            } else if(this.rota.tempoParada){
                    this.tempoParado = this.rota.tempoParada
            } else {
                this.tempoParado = null
            }
		},
        'rota.empresa'(){
			this.clienteCodigo = this.rota.empresa
        },
	},

    computed: {
		formValido() {
			return this.pontoSelecionado && this.tipoParada.length
		}
    }
})
</script>

<style lang="scss">
    #modal-adicionar-parada{
        .divDeBaixo{
            padding-left: 0px !important;
            padding-right: 0px !important;
            padding-top: 10px !important;
        }
        .letra-branca{
            color: #fff !important;
        }
    }
</style>