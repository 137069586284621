/**
	Este é o componente das Tabs do vue-bootstrap customizadas para as cores da eagle

	-> Propriedades:
		1- tabs - Array - array de objetos com os nomes das tabs desejadas - 
			EX: [{
					title:'titulo da tab', 
					value:'nome do slot para colocar as informacoes'
					?disabled?:true/false
				}]

	-> Slots:
		1- tab+value do item - Tab para colocar as informações da tab

	-> Eventos:
		Sem eventos
 */
<template>
	<b-tabs 
		v-bind="$attrs" 
		v-on="$listeners"
		content-class="mt-12" 
		ref="refTabBootstrap">
		<b-tab 
			v-for="(tab, a) in tabs" 
			:key="'tab-'+a"
			:title="tab.title" 
			:disabled="tab.disabled">
			<slot :name="'tab-'+tab.value"></slot>
		</b-tab>
	</b-tabs>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
	inheritAttrs:false,
	props:{
		tabs:{
			required: true,
			type: Array,
		}
	},
})
</script>

<style lang="scss">
	.nav{
		&.nav-tabs{
			border-bottom: 1px solid #428BCA !important;

			a{
				font-weight: normal !important;
				color: #22262e !important;
				border-radius: 0px !important;

				&:hover{
					border-color: #a3ccef #a3ccef #428BCA !important;
				}

				&.active{
					border-color: #428BCA #428BCA #fff !important;
					
				}
			}
		}
	}
</style>
