<template>
    <panelEagle id="bancoHoras" :loading="loadingPanel">
        <div class="col-sm-12 nopadding">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiDatabaseClock" titulo="Banco de horas"></tituloPage>
                </div>
                <div class="col-sm-6 divButtonsBasic divDontPrint">
                    <basicButtonsRelatoriosEIG :loading="loadingExporta" :disabled="$v.$invalid"
                        @exportarRelatorio="exportarRel" @gerarRelatorio="gerarRel">
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12">
                        <hr>
                    </div>
                    <div class="col-sm-12 nopadding row">
                        <div class="col-sm-2 nopadding">
                            <inputMonth @changeInput="changeMesInicio" label="Mês inicial*" name="inputMesInicioBH">
                            </inputMonth>
                        </div>
                        <div class="col-sm-2 nopadding">
                            <inputMonth @changeInput="changeMesFinal" label="Mês final*" name="inputMesFimBH">
                            </inputMonth>
                        </div>
                        <div class="col-sm-4 nopadding">
                            <selectAll @changeSelect="changeEmpresas" :isMultiple="false" nameForRadio="radiosEmpresasBH"
                                :labels="[{ indexDFH: 'E', description: 'Empresa*' }]" :optionsArray="optionsSelectEmpresa"
                                :selected="selectedEmpresa" firstSelected="E" :hasSelectAll="false">
                            </selectAll>
                        </div>
                        <div class="col-sm-4 nopadding">
                            <selectAll ref="refColab" :loading="loadingColab" :disabled="!optionsSelectColaborador.length"
                                @changeSelect="changeColaboradores" :isMultiple="true" nameForRadio="radiosColaboradorBH"
                                :labels="[{ indexDFH: 'C', description: 'Colaboradores*' }]"
                                :optionsArray="optionsSelectColaborador" firstSelected="C" :hasSelectAll="true">
                            </selectAll>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
            <div class="col-sm-12">
                <hr>
            </div>
        </div>
        <tableRelatorio :htmlRel="htmlBancoHoras">
            <slot slot="thead">
                <th>Referência</th>
                <th>Total</th>
                <th>Crédito</th>
                <th>Débito</th>
            </slot>
            <slot slot="tbody">
                <statusInformation typeBar="tr" :statusBar="statusBar"></statusInformation>
            </slot>
        </tableRelatorio>
    </panelEagle>
</template>

<script >
import Vue from 'vue'
import { mdiDatabaseClock } from '@mdi/js'
import { EmpresasService } from '../../../Services/auth/Empresas.service'
import { HttpRequest } from '../../../Services/auth/HttpRequest.Service'
import { FiltrosService } from '../../../Services/filtros/filtros.Service'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default Vue.extend({
    name: 'BancoDeHoras',
    components: {
        'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage': require('@/components/Atom/Header/Titulo').default,
        'basicButtonsRelatoriosEIG': require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
        'slideUpAndDown': require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
        'inputMonth': require('@/components/Atom/Datas/InputMonth').default,
        'selectAll': require('@/components/Atom/Select/SelectAll').default,
        'statusInformation': require('@/components/Atom/StatusInformation/StatusInformation').default,
        'tableRelatorio': require('@/components/Atom/Table/TableRelatorio').default,
    },
    validations: {
        selectedEmpresas: { required },
        selectedColaborador: { required },
        mesInicio: { required },
        mesFim: { required },
    },
    data() {
        return {
            loadingPanel: false,
            loadingExporta: [false, false, false],
            loadingColab: false,
            mdiDatabaseClock: mdiDatabaseClock,
            optionsSelectEmpresa: new EmpresasService().Get(),
            optionsSelectColaborador: [],
            // Variaveis selecionadas
            selectedEmpresas: [],
            selectedColaborador: [],
            mesInicio: '',
            mesFim: '',
            // variaveis do relatorio
            htmlBancoHoras: '',
            statusBar: 'info',
            dadosBancoHoras: [],
            temDados: false,
            dataToExport: '',
            selectedEmpresa: [],
        }
    },
    methods: {
        ...mapGetters(['getMaster']),

        changeMesInicio(mes) {
            this.mesInicio = mes.format('MM/YYYY');

            this.limpaRelatorio();
        },
        changeMesFinal(mes) {
            this.mesFim = mes.format('MM/YYYY');

            this.limpaRelatorio();
        },
        changeEmpresas(empresas) {
            this.$refs.refColab.clearAll()
            this.optionsSelectColaborador = []
            this.selectedEmpresas = empresas;
            this.dados_filtros();

            this.limpaRelatorio();
        },
        changeColaboradores(colaboradores) {
            this.selectedColaborador = colaboradores;

            this.limpaRelatorio();
        },
        /**
        * @description consulta a variável this.temDados caso conter dados limpa os dados do relatório
        * @author Yuri 🇧🇷
        */
        limpaRelatorio() {
            if (this.temDados) {
                this.dadosBancoHoras = [];
                this.temDados = false;
                this.htmlBancoHoras = '';
                this.dataToExport = '';
            }
        },
        montaRelatorio(dados) {
            var html = '';
            for (var a in dados) {
                html += '<tr class="backColorTdPDF">'
                html += '<td class="nopadding" colspan="11">'
                html += '<span class="newbadge">' + dados[a].motorista + '</span>'
                html += '</td>'
                html += '</tr>'
                for (var b in dados[a].dados) {
                    html += '<tr class="layouttrBH">'
                    html += '<td class="tdTrMidBH">' + dados[a].dados[b].bhreferencia + '</td>'
                    html += '<td class="tdTrMidBH">' + dados[a].dados[b].bhtotal + '</td>'
                    html += '<td class="tdTrMidBH">' + dados[a].dados[b].bhcredito + '</td>'
                    html += '<td class="tdTrMidBH">' + dados[a].dados[b].bhdebito + '</td>'
                    html += '</tr>'
                }
                html += '<tr class="layoutTotBH">'
                html += '<td class="tdTrTotBH">Total:</td>'
                html += '<td class="tdTrTotBH">' + dados[a].totalTot + '</td>'
                html += '<td class="tdTrTotBH">' + dados[a].totalPos + '</td>'
                html += '<td class="tdTrTotBH">' + dados[a].totalNeg + '</td>'
                html += '</tr>'
            }

            this.htmlBancoHoras = html;
        },
        async dados_filtros() {
            this.loadingColab = true
            var dados = await new FiltrosService().dados_filtros(this.selectedEmpresas, ['C']);
            this.optionsSelectColaborador = dados['C'];
            this.loadingColab = false
        },
        async gerarRel() {
            this.loadingPanel = true
            if (this.mesInicio != '' && this.mesFim != '' && this.selectedColaborador.length > 0) {
                this.dadosBancoHoras = [];
                this.temDados = false;
                this.htmlBancoHoras = '';
                var data = await new HttpRequest().Post('/controle/jornada/relatorios/banco/horas/gerar', {
                    'dataIni': this.mesInicio,
                    'datafim': this.mesFim,
                    'id': this.selectedColaborador,
                })
                this.temDados = data.status && data.data && data.data.bancoHoras
                    && data.data.bancoHoras.length;
                if (this.temDados) {
                    this.dadosBancoHoras = data.data.bancoHoras;
                    this.dataToExport = this.mesInicio + ' - ' + this.mesFim;
                    this.montaRelatorio(this.dadosBancoHoras);
                } else {
                    this.dadosBancoHoras = [];
                    this.statusBar = 'error';
                }
            }
            this.loadingPanel = false
        },
        async exportarRel(tipo) {
            var root = process.env.VUE_APP_ROOT;
            if (this.temDados) {
                this.loadingExporta = [tipo == 'pdf', tipo == 'xls', tipo == 'csv']
                var data = await new HttpRequest().Post('/controle/jornada/relatorios/banco/horas/exportar', {
                    'arrayDados': JSON.stringify(this.dadosBancoHoras),
                    'tipo': tipo,
                    'data': this.dataToExport
                });
                if (data.status && data.data.local) {
                    window.open(root + '/' + data.data.local);
                }
                this.loadingExporta = [false, false, false]
            }
        }
    },

    mounted() {
        if (!this.getMaster()) {
            this.selectedEmpresa = this.optionsSelectEmpresa
        }
    }
})
</script>

<style lang="scss">
#bancoHoras {}
</style>