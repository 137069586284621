<template>
    <modalEagle 
        title="Detalhes da escala" 
        id="modal-detalhes-escala" 
        size="xl" 
        @modalOpen="modalOpen">
        <slot slot="modalBody">
            <b-overlay :show="show" rounded="sm">
                <div class="col-sm-12 row nopadding">
                    <div class="col-12 row nopadding">
                        <div class="col-3 nopadding">
                            <selectAll
                                :disabled="!getCadastrar()"
                                :selected="selectedVeiculosPrimeiroTurno"
                                @changeSelect="changeVeiculoPrimeiroTurno"
                                :isMultiple="false" 
                                nameForRadio="VEPT" 
                                :hasSelectAll="false" 
                                :labels="[{indexDFH: 'VEPT', description: 'Veículo do primeiro turno'}]" 
                                :optionsArray="optVeiculo" 
                                firstSelected="VEPT">
                            </selectAll>
                        </div>
                        <div class="col-3 nopadding">
                            <selectAll
                                :disabled="!getCadastrar()"
                                :selected="selectedVeiculosSegundoTurno"
                                @changeSelect="changeVeiculoSegundoTurno" 
                                :isMultiple="false" 
                                nameForRadio="VEST" 
                                :hasSelectAll="false" 
                                :labels="[{indexDFH: 'VEST', description: 'Veículo do segundo turno'}]" 
                                :optionsArray="optVeiculo" 
                                firstSelected="VEST">
                            </selectAll>
                        </div>
                        <div class="col-3 nopadding">
                            <selectAll
                                :disabled="!getCadastrar()"
                                :selected="selectedVeiculosTerceiroTurno"
                                @changeSelect="changeVeiculoTerceiroTurno" 
                                :isMultiple="false" 
                                nameForRadio="VETT" 
                                :hasSelectAll="false" 
                                :labels="[{indexDFH: 'VETT', description: 'Veículo do terceiro turno'}]" 
                                :optionsArray="optVeiculo" 
                                firstSelected="VETT">
                            </selectAll>
                        </div>
                        <div class="col-3 nopadding">
                            <selectAll
                                :disabled="!getCadastrar()"
                                :selected="selectedMotorista"
                                ref="refMotoristas"
                                @changeSelect="changeMotorista"
                                :isMultiple="false" 
                                nameForRadio="MO" 
                                :hasSelectAll="false" 
                                :labels="[{indexDFH: 'MO', description: 'Motorista'}]" 
                                :optionsArray="optMotorista" 
                                firstSelected="MO">
                            </selectAll>
                        </div>
                    </div>
                    <div class="col-sm-12 row mt-2">
                        <div class="col-sm-12 row motoristaAssociado my-auto nopadding" v-show="escalaAssociada.length">
                            <div class="col-sm-9 my-auto text-center">
                                Motorista <span class="destaque">{{motoristaAssociado}}</span>
                                está associado {{
                                    escalaAssociada.length > 1?'as escalas: ':'a escala'
                                }}
                                <span class="destaque" v-for="(value, index) in escalaAssociada" :key="value.valuejornada">
                                    {{value.descriptionjornada}}{{escalaAssociada[index+1]?',':'.'}}
                                </span>
                            </div>
                            <div class="col-sm-3 my-auto d-flex justify-content-end nopadding">
                                <simpleButton
                                    event="click" 
                                    @click="clickMotoristaCancelar"
                                    :icon="mdiCloseThick"
                                    width="120px"
                                    text="Cancelar" 
                                    type="red">
                                </simpleButton>
                                <simpleButton
                                    event="click" 
                                    @click="clickMotoristaOk"
                                    width="120px"
                                    :icon="mdiCheckBold" 
                                    text="Ok!" 
                                    type="blue">
                                </simpleButton>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 nopadding"><hr></div>
                    <div class="col-12 nopadding">
                        <table>
                            <tr id="jornada-escala" class="info-tabela-qtd trMoreBlack">
                                <td colspan="6" class="text-center">
                                    {{(escalaDetalhes.jornadaDias != '' ? 'JORNADA DE TRABALHO' : 'SEM JORNADA DE TRABALHO')}}
                                </td>
                            </tr>
                            <tr class="trMoreBlack">
                                <td colspan="1" class="tdDias">Dias: </td>
                                <td colspan="5">
                                    {{escalaDetalhes.jornadaDias}}
                                </td>
                            </tr>
                            <template v-for="(turnosArray, turno) in escalaDetalhes.turnos">
                                <!-- {{turnosArray}} --> 
                                <tr :key="turno+''+turnosArray.horario" class="tdNobackTurnoInfo tdBorderBottom">
                                    <td colspan="6" class="tdTurno">{{turno}}</td>
                                </tr>
                                <template v-for="(turnoInfo, a) in turnosArray">

                                    <tr :key="'dias'+turno+a" class="tdNobackTurnoInfo">
                                        <td colspan="1" class="tdDias">Dias: </td>
                                        <td colspan="5">{{turnoInfo.dias}}</td>
                                    </tr>
                                    <tr :key="'tempoInicio'+turno+a" class="tdNobackTurnoInfo tdBorderBottom">
                                        <td colspan="2" class="tdTempoInicio">Tempo para iniciar a primeira linha: </td>
                                        <td colspan="3">{{turnoInfo.tempoInicio}}</td>
                                    </tr>
                                    <tr :key="'horarios'+turno+a" class="tdBackMaisEscuro">
                                        <td colspan="1" class="tdHorario">Horário</td>
                                        <td colspan="1" class="">Descrição</td>
                                        <td colspan="1" class="tdhoraInicio">Hora de inicio</td>
                                        <td colspan="1" class="tdhoraInicio">Tempo estimado</td>
                                        <td colspan="1" title="Validador" class="tdDias">V</td>
                                        <td colspan="1" title="Itinerario" class="tdDias">IT</td>
                                    </tr>
                                    <tr :key="'detalhesHoraios'+turno+b+''+a" 
                                        v-for="(horarios, b) in turnoInfo.horarios" 
                                        class="trHorarios">
                                        <td colspan="1" class="tdHorario">{{horarios.horario}}</td>
                                        <td colspan="1" class="tdDescricao">{{horarios.linha}}</td>
                                        <td colspan="1" class="tdhoraInicio">{{horarios.horario}}</td>
                                        <td colspan="1" class="tdhoraInicio">{{horarios.tempoEstimado}}</td>
                                        <td colspan="1" title="Validador" class="tdDias">{{horarios.itinerario}}</td>
                                        <td colspan="1" title="Itinerario" class="tdDias">{{horarios.numItinerario}}</td>
                                    </tr>
                                </template>
                            </template>
                        </table>
                    </div>
                </div>
             </b-overlay>
        </slot>
        <slot name="modalFooter">
            <simpleButton 
                :icon="mdiCloseThick" 
                @click="cancelButton" 
                text="Cancelar" 
                event="click" 
                type="red">
            </simpleButton>
            <loadingButton
                v-if="getCadastrar()"
                :isLoading="loadingBtn"
                :disabled="!!escalaAssociada.length"
                :icon="mdiCheckBold" 
                @click="confirmButton" 
                text="Confirmar" 
                event="click" 
                type="blue">
            </loadingButton>
        </slot>
    </modalEagle>
</template>
<script lang="js">
import Vue from 'vue'
import { mapGetters } from 'vuex'
import{ mdiCheckBold, mdiCloseThick } from '@mdi/js'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { HttpRequest } from '../../../Services/auth/HttpRequest.Service'
import { conectionError } from '@/Services/Helpers/swellHeper'
import { required } from 'vuelidate/lib/validators'
export default Vue.extend({
	components:{
		selectAll:    require('@/components/Atom/Select/SelectAll').default,
		modalEagle:   require('@/components/Atom/Modal/ModalEagle').default,
		simpleButton: require('@/components/Atom/Buttons/SimpleButton').default,
		loadingButton: require('@/components/Atom/Buttons/LoadingButton').default,
	},
	props:{
		escala:{
			type: String,
			required: true,
			default: ''
		},

		escalaName:{
			type: String,
			required: true,
			default: ''
		},

		empresa:{
			type: Array,
			required: true,
		}
	},
	validations:{
		// valueVeiculoPrimeiroTurno:{ required },
		// valueVeiculoSegundoTurno:{ required },
		// valueMotorista:{ required },
	},
	mounted(){
		this.carregaDetalhes()
	},
	methods:{
		...mapGetters(['getCadastrar']),
		cancelButton(){
			this.$bvModal.hide('modal-detalhes-escala')
		},

		async confirmButton(){
			this.loadingBtn = true
			this.show = true
			var obj = {
				cod:this.escala,
				mtnome:this.codMotorista,
				esveiculopt:this.valueVeiculoPrimeiroTurno[0],
				esveiculost:this.valueVeiculoSegundoTurno[0],
                esveiculott:this.valueVeiculoTerceiroTurno[0]

			}
			var dados = await new HttpRequest()
				.Post('/coletivos/cadastros/escalas/detalhes/escala', obj)
			if(dados.data.concluido){
				this.show = false
				this.loadingBtn = false
				this.$bvModal.hide('modal-detalhes-escala')
			}else{
				conectionError()
			}
		},

		async modalOpen(){
			this.motoristaAssociado = ''
			this.escalaAssociada = []
			this.show = true
			await this.dadosFiltros()
			await this.pegaMotorista()
			await this.carregaDetalhes()
			this.show = false
		},

		clickMotoristaOk(){
			this.escalaAssociada = []
		},

		clickMotoristaCancelar(){
			this.$refs.refMotoristas.clearAll()
		},

		async pegaMotorista(){
			var dados = await new HttpRequest()
				.Post('/coletivos/cadastros/escalas/motorista/escalas', {empresa:this.empresa})
			this.optMotorista = dados.data.retorno
		},
        
		async dadosFiltros(){
			var dados = await new FiltrosService().dados_filtros(this.empresa, ['V'])
			this.optVeiculo = dados['V']
		},

		async carregaDetalhes(){
			try{
				var data = await new HttpRequest()
					.Post('/coletivos/cadastros/escalas/verificaturno', {'escala':this.escalaCod})
				this.escalaDetalhes = data.data.infoEscala
				this.selectedVeiculosPrimeiroTurno  = this.optVeiculo.filter(v=>v.value   == this.escalaDetalhes.carroPt)
				this.selectedVeiculosSegundoTurno   = this.optVeiculo.filter(v=>v.value   == this.escalaDetalhes.carroSt)
				this.selectedVeiculosTerceiroTurno  = this.optVeiculo.filter(v=>v.value   == this.escalaDetalhes.carroTt)
				this.selectedMotorista              = this.optMotorista.filter(v=>v.value == this.escalaDetalhes.motorista)
			}catch(error){
				conectionError()
			}
		},
        
		changeVeiculoPrimeiroTurno(value){
			this.valueVeiculoPrimeiroTurno = value
		},
        
		changeVeiculoSegundoTurno(value){
			this.valueVeiculoSegundoTurno = value
		},
        
		changeVeiculoTerceiroTurno(value){
			this.valueVeiculoTerceiroTurno = value
		},

        changeMotorista(valor){
			this.codMotorista = valor[0]
			this.valueMotorista = []
			this.motoristaAssociado = ''
			this.escalaAssociada = []
			this.optMotorista.filter((v)=>{
				if(v.value == valor){
					this.motoristaAssociado = v.description
					this.escalaAssociada = v.jornadaColetivo
				}
			})
			this.escalaAssociada = this.escalaAssociada.filter(v=>v.valuejornada != this.escala)
			this.valueMotorista = valor
		},
	},
	watch:{
		escala: function(){
			this.escalaCod = this.escala
			this.carregaDetalhes()
		},
	},
	data(){
		return{
			selectedVeiculosPrimeiroTurno:[],
			selectedVeiculosSegundoTurno:[],
			selectedVeiculosTerceiroTurno:[],
            selectedMotorista:[],
			loadingBtn:false,
			codMotorista:[],
			motoristaAssociado:'',
			escalaAssociada:[],
			mdiCheckBold:mdiCheckBold,
			mdiCloseThick:mdiCloseThick,
			show:false,
			escalaCod: this.escala,
			escalaDetalhes: [],
			optVeiculo:[],
			valueVeiculoPrimeiroTurno:[],
			valueVeiculoSegundoTurno:[],
			valueVeiculoTerceiroTurno:[],
			valueMotorista:[],
			optMotorista:[],
		}
	}
    
})
</script>

<style lang="scss">
#modal-detalhes-escala{

    .modal-body{
        font-size: 13px;
    
        td{
            padding: 2px;
        }
    }

    .motoristaAssociado{
        background-color: rgba(255, 0, 0, 0.39);
        .destaque{
            font-family: nexaBold;
        }
    }

    .trMoreBlack{
        background: #e2e2e2;
        border-bottom: 1px solid #cccccc;
    }

    .info-tabela-qtd{
        text-align: center;
    }
    .tdHorario{
        width: 7%;
    }
    .tdDias{
        width: 18%;
    }

    .tdhoraInicio{
        width: 10%;
    }

    .tdTempoInicio{
        width: 27%;
    }

    .tdDescricao{
        width: 22%;
    }

    .tdBackMaisEscuro{
        td{
            background-color: #e2e2e2 !important;
        }
    }

    .tdNobackTurnoInfo{
        td{
            background-color: #fff !important;
            border: none;
        }

        &.tdBorderBottom{
            border-bottom: 1px solid #f1f0f0;
        }
    }

    .trHorarios td {
        background-color: #f1f0f0;
    }
}
</style>