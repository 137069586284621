<template>
    <panelEagle id="desperdicioCombustivelView" :loading="loadingPanel">
        <div class="col-sm-12 nopadding ">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage 
                        :icon="mdiFuel" 
                        titulo="Desperdício de combustível">
                    </tituloPage>
                </div>
                <div class="col-sm-6 divButtonsBasic divDontPrint">
                    <basicButtonsRelatoriosEIG
                        :loading="loadingExporta"
                        :disabled="$v.selectedVeiculosGrupo.$invalid"
                        @exportarRelatorio="exportarRelatorio" 
                        @gerarRelatorio="gerarRel"
                        :disabledDropdown='$v.arrayDadosRelatorio.$invalid'>
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>
            <slideUpAndDown>
                <slot slot="filters">
                    <div class="col-sm-12"><hr></div>
                    <div class="col-sm-12 row nopadding mb-2">
                        <div class="col-sm-3 nopadding">
                            <inputRangeWithLimit 
                                @changeInput="changeData" 
                                name="desperdicioCombustivelData" 
                                :isObrigatorio="true"
                                opens='rigth'>
                            </inputRangeWithLimit>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll
                                @changeSelect="changeEmpresa" 
                                :isMultiple="true" 
                                nameForRadio="desperdicioCombustivelEmpresas" 
                                :hasSelectAll="true" 
                                :labels="[{indexDFH: 'EM', description: 'Empresas*'}]" 
                                :optionsArray="optionsArrayEmpresas" 
                                firstSelected="EM"
                                :selected="selectedEmpresa">
                            </selectAll>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <selectAll
                                :loading="loadingButton"
                                :disabled="!optionsSelectVeiculos.length"
                                ref="refVeiculos"
                                @changeCheck="changeCheck" 
                                @changeSelect="changeVeiculosGrupo" 
                                @selectCriado="selectVeiculosCriado" 
                                :isMultiple="true" 
                                nameForRadio="radiosVeiculosGrupoDC" 
                                :labels="labelsSelectVeiculos" 
                                :optionsArray="optionsSelectVeiculos" 
                                firstSelected="V" 
                                :hasSelectAll="true">
                            </selectAll>
                        </div>
                        <div class="col-sm-3 nopadding">
                            <inputSimple 
                                @changeInput="changeTempoMinimo" 
                                name="tempoParadoDC" 
                                label="Tempo parado maior que:" 
                                :hasMask="true" 
                                :mask="['h#:M#:M#']" 
                                value="00:00:00">
                            </inputSimple>
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
            <div class="col-sm-12"><hr></div>
        </div>
        <!-- htmlDesperdicioDeCombustivel -->
        <tableRelatorio htmlRel="">
            <slot slot="thead">
                <th>Horário inicial</th>
                <th>Horário final</th>
                <th>Tempo parado</th>
                <th>Endereço</th>
                <th>Local</th>
                <th>Região</th>
                <th>Localizar</th>
            </slot>
            <slot slot="tbody">
                <template v-for="(a, index) in arrayDadosRelatorio">
                    <tr class="badgetr backColorTdPDF" :key="index+'__algo'">
                        <td colspan="7" class="pl-0 badgePadding">
                            <span class="newbadge">
                                {{index}}
                            </span>
                        </td>
                    </tr>
                    <template v-for="(b, indexx) in arrayDadosRelatorio[index]">
                        <template v-if="b['inicio'] !== undefined">
                            <tr :key="indexx+b['inicio']">
                                <td>{{b['inicio']}}</td>
                                <td>{{b['fim']}}</td>
                                <td>{{b['tempo']}}</td>
                                <td>{{b['endereco']}}</td>
                                <td>{{b['ponto']}}</td>
                                <td>{{b['regiao']}}</td>
                                <td class="hidden-print tdDaLocalizacao borda-teste">
                                    <a class="cursor-pointer borda-teste" title="Clique para ver local no mapa" @click="clickLinkMapa(b['localizar'])">
                                        <baseIcon class="cor-azul borda-teste" size='18' :icon="mdiTarget"/>
                                    </a>
                                </td>
                            </tr>
                        </template>
                        <template v-if="b['inicio'] === undefined">
                            <tr :key="indexx+'a'+index">
                                <td colspan="7">
                                    Total: {{arrayDadosRelatorio[index].total}}
                                </td>
                            </tr>
                        </template>
                    </template>
                </template>
                    <br>
                <statusInformation 
                    v-if='arrayDadosRelatorio.length == 0' 
                    typeBar="tr" 
                    :statusBar="statusbar">
                </statusInformation>
            </slot>
        </tableRelatorio>
    </panelEagle>
</template>

<script lang="js">
import Vue from 'vue'
import {mdiFuel, mdiTarget } from '@mdi/js'
import {EmpresasService} from '@/Services/auth/Empresas.service'
import {HttpRequest} from '@/Services/auth/HttpRequest.Service'
import {FiltrosService} from '@/Services/filtros/filtros.Service'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default Vue.extend({
	name:'desperdicioCombustivel',
	validations:{
		selectedEmpresas:{ required },
		selectedVeiculosGrupo:{ required },
		arrayDadosRelatorio : { required },
	},
	components:{
		baseIcon:require('@/components/Atom/Icon/BaseIcon.vue').default,
		'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
		'tituloPage': require('@/components/Atom/Header/Titulo').default,
		'basicButtonsRelatoriosEIG':  require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
		'slideUpAndDown': require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
		'inputRangeWithLimit': require('@/components/Atom/Datas/InputRangeWithLimit').default,
		'selectAll': require('@/components/Atom/Select/SelectAll').default,
		'inputSimple': require('@/components/Atom/Inputs/InputSimple').default,
		'tableRelatorio': require('@/components/Atom/Table/TableRelatorio').default,
		'statusInformation': require('@/components/Atom/StatusInformation/StatusInformation').default,
	},
	methods:{
		...mapGetters(['getMaster']),

		clickLinkMapa(cord){
			window.open('http://maps.google.com/maps?q=loc:'+cord)
		},

		changeData(data){
			this.dataDC = data;
			this.arrayDadosRelatorio = []
		},
		changeEmpresa(selecionados){
			this.optionsSelectVeiculos = []
			this.$refs.refVeiculos.selectedItens = []
			this.selectedEmpresas = selecionados;
			// this.selectVGV.clearAll();
			this.arrayDadosRelatorio = []
			this.dados_filtros();
		},
		selectVeiculosCriado(select){
			this.selectVGV = select;
			this.arrayDadosRelatorio = []
		},
		changeVeiculosGrupo(selecionados){
			this.selectedVeiculosGrupo = selecionados;
			this.arrayDadosRelatorio = []
		},
		changeTempoMinimo(tempo){
			this.tempoMinimo = tempo;
			this.arrayDadosRelatorio = []
		},
		changeCheck(radios){
			if(this.selectedEmpresas.length > 0){
				this.optionsSelectVeiculos = this.arrayAllOptionsVeiculos[radios];
				this.radiosSelected = radios
			}
		},
		async dados_filtros(){
			this.loadingButton = true
			var dados = [];
			dados = await new FiltrosService().dados_filtros(this.selectedEmpresas, ['V', 'GV'])
			this.arrayAllOptionsVeiculos = dados;
			this.changeCheck(this.radiosSelected);
			this.loadingButton = false
		},
        
		async gerarRel(){
			if(this.selectedEmpresas.length > 0 && this.selectedVeiculosGrupo.length > 0){
				this.htmlDesperdicioDeCombustivel = '';
				this.arrayDadosRelatorio = [];
				this.loadingPanel = true
				var hora = this.tempoMinimo.length == 8 ? this.tempoMinimo : '00:00:00';
				var data = await new HttpRequest()
					.Post(
						'/telemetria/relatorios/desperdicio/combustivel/gerar', 
						{
							'clientes': this.selectedEmpresas, 
							'tipoPesquisa': this.radiosSelected, 
							'data': this.dataDC, 
							'tempoMinimo': hora, 
							'veiculos': this.selectedVeiculosGrupo
						})
				if(data.code == 200){
					this.tamanhoRelatorio = data.data.desperdicioCombustivel.length;
					this.arrayDadosRelatorio = data.data.desperdicioCombustivel;
					if(this.arrayDadosRelatorio.length == 0)
						this.statusbar = 'error';
				}else{
					this.statusbar = 'error2';
				}
			}
			this.loadingPanel = false
		},
		async exportarRelatorio(tipo){
			switch (tipo) {
				case 'pdf': this.loadingExporta = [true, false, false]; break;
				case 'xls': this.loadingExporta = [false, true, false]; break;
				default:    this.loadingExporta = [false, false, true]; break;
			}
			var root =  process.env.VUE_APP_ROOT;
			var data = await new HttpRequest()
				.Post('/telemetria/relatorios/desperdicio/combustivel/exportar', 
					{
						'arrayDados':JSON.stringify(this.arrayDadosRelatorio), 
						'tipo':tipo,
						'data':this.dataDC
					}
				);
			window.open(root+'/'+data.data.local);
			this.loadingExporta = [false, false, false]
		},
		/**
         * func descontinuada, troquei ela pro html e coloquei os padroes de icones
         * deixei ela aqui pq sim
         */
		montaRelatorio(desperdicio){
			var html = '';
			for(var a in desperdicio){
				html += '<tr class="badgetr backColorTdPDF">'
				html += '<td colspan="7" class="nonpadding badgePadding"><span class="newbadge">'+a+'</td>';
				html += '</tr>'
				for(var b in desperdicio[a]){
					html += '<tr>'
					html += '<td>'+desperdicio[a][b]['inicio']+'</td>'
					html += '<td>'+desperdicio[a][b]['fim']+'</td>'
					html += '<td>'+desperdicio[a][b]['tempo']+'</td>'
					html += '<td>'+desperdicio[a][b]['endereco']+'</td>'
					html += '<td>'+desperdicio[a][b]['ponto']+'</td>'
					html += '<td>'+desperdicio[a][b]['regiao']+'</td>'
					html += '<td class="hidden-print tdDaLocalizacao"><a title="Clique para ver local no mapa" onClick="window.open(\'http://maps.google.com/maps?q=loc:'+desperdicio[a][b]['localizar']+'\',\'_blank\')"><span class="flaticon-icon075"></span></a></td>';
					html += '</tr>'
				}
			}

			this.htmlDesperdicioDeCombustivel = html;
		}
	},
	data(){
		return{
			mdiTarget:mdiTarget,
			loadingExporta:[false, false, false],
			loadingButton:false,
			mdiFuel:mdiFuel,
			loadingPanel:false,
			dataDC: '',
			selectedEmpresas: [],
			optionsArrayEmpresas: new EmpresasService().Get(),
			labelsSelectVeiculos:[
				{indexDFH: 'V', description: 'Veiculos*'},
				{indexDFH: 'GV', description: 'Grupos*'},
			],
			optionsSelectVeiculos: [],
			arrayAllOptionsVeiculos: [],
			selectVGV: [],
			selectedVeiculosGrupo: [],
			radiosSelected: 'V',
			tempoMinimo : '',
			htmlDesperdicioDeCombustivel: '',
			arrayDadosRelatorio : [],
			tamanhoRelatorio: 0,
			statusbar: 'info',
			selectedEmpresa: [],
		}
	},

	mounted() {
		if(!this.getMaster()){
			this.selectedEmpresa = this.optionsArrayEmpresas
		}
	}
    
})
</script>

<style lang="scss">
    #desperdicioCombustivelView{
        .tdDaLocalizacao{            
            a{
                .cor-azul{
                    color: #428bca;
                    text-align: right;
                }
            }
        }
    }
</style>