/**
    Este componente é um input de periodo sem limite de tempo alterado para usar o el-date-picker

    -> Propriedades:
        1- id - String - Id do input - EX: 'inputRangeNoLimits'
        2- name - String - Name do input - EX: 'inputRangeNoLimits'
        3- isObrigatorio - Boolean - verifica se o campo é obrigatorio, se for ele coloca o (*) - EX: true/false
        4- opens - String - Para onde o calendário abre

    -> Slot:
        - Sem slots

    -> Eventos:
        1- changeInput - Emite quando o mes é alterado - Parametros:
            1.1- dateRangeFinal - data em formato 'DD/MM/YYYY - DD/MM/YYYY'
    
    -> Obs:
        1- format - Formato que o calendário mostra no input
        2- value-format - Formato que o calendario envia valores
        3- Type - Se o calendário será para selecionar periodo ou valor único
        4- clearable - Se tem a opção de apagar a data
        5- picker-options - Opções de seleção rápidas
 */

<template>
    <div class="col-sm-12">
        <div class="col-sm-12 subTitle nopadding">
            {{titulo}}<span v-if="isObrigatorio">*</span>
        </div>
        <div>
            <el-date-picker
                v-model           ='data.periodo'
                type              ='daterange'
                value-format      ='dd/MM/yyyy'
                style             ='width:100%'
                format            ='dd/MM/yyyy'
                size              ='large'
                range-separator   ='-'
                start-placeholder =''
                end-placeholder   =''
                :picker-options   ="pickerOptions"
                :disabled         ="desabilitado"
                :clearable        ='false'
                :align            ='opens'
                @change           ='changeInput'
            />
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import { DateTime } from 'luxon';
import { pickerOptions } from '@/components/Atom/Datas/datePiker.js'
export default Vue.extend({
	name: 'inputRangeNoLimits',

	data(){
		return {
			data:{
				periodo:[DateTime.now().toFormat('dd/LL/yyyy'), DateTime.now().toFormat('dd/LL/yyyy')],
			},
			pickerOptions:pickerOptions,
		}
	},
	methods: {
		changeInput(){
			this.$emit('changeInput', this.data.periodo[0]+' - '+this.data.periodo[1]);
		},
	},

	mounted(){
		this.changeInput(this.data.periodo);
	},

    watch:{
		/**
		 * Função para alterar valor da data, 
		 *  quando passado um valor pela prop value é alterado
		 * o valor da data e 
		 *  chamado o método change input para passar o valor para tela
		 * @author Vitor Hugo 🐨
		*/
		value:function(){
			var data = this.value.split(' - ')
			this.data.periodo = data
			this.changeInput(data)
		}
	},

	props:{
        value: {
			type: String,
			default: '',
		},
		opens:{
			type: String,
			default: 'center'
		},
		id:{
			type: String,
			required: false,
			default: '',
		},
		name:{
			type: String,
			required: true,
			default: '',
		},
		isObrigatorio:{
			type:Boolean,
			required: false,
			default: false
		},
		titulo:{
			type: String,
			required: false,
			default: 'Intervalo'
		},
		desabilitado:{
			type: Boolean,
			default: false,
		}
	}
})
</script>
<style lang="scss">
    .subTitle{
        font-size: 13px;
        text-align: left;
        margin-bottom: 0px;
    }
    .vue-daterange-picker{
        display: block !important;
    }
</style>