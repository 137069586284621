<template>
    <panelEagle id="Monitor" :loading='gerandoRel'>
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon='mdiMonitorDashboard' titulo='Painel de monitoramento'></tituloPage>
                </div>
                <div class="col-sm-6 divButtonsBasic divDontPrint">
                    <basicButtonsRelatoriosEIG
                    :disabled          ="$v.empresasSelecionadas.$invalid"
                    :disabledDropdown  ="$v.relatorio.$invalid"
                    :loading           ="carregandoE"
                    @gerarRelatorio    ="gerarRelatorio"
                    @exportarRelatorio ="exportarRelatorio">
                        <b-button
                        variant="outline-secondary"
                        squared
                        class='botaoScreen mr-1'
                        @click="resizeWindow">
                            <baseIcon
                            :size='18'
                            :icon='iconResize'/>
                        </b-button>
                    </basicButtonsRelatoriosEIG>
                </div>
            </div>

            <slideUpAndDown>
                <slot
                    slot="filters">
                    <div class="col-sm-12 col-md-12"><hr></div>
                    <!-- Div dos selects de cima -->
                    <div class='col-sm-12 row nopadding'>
                        <div class="tamSelect">
                            <selectAll
                                class="paddingSelect"
                                :extraClass   ="{'border border-danger':$v.empresasSelecionadas.$anyError}"
                                @close        ="$v.empresasSelecionadas.$touch()"
                                :labels       ="[{indexDFH: 'EM', description: 'Empresas*'}]"
                                nameForRadio  ='Empresas'
                                :optionsArray ='optionsEmpresas'
                                :hasSelectAll ='true'
                                :isMultiple   ='true'
                                @changeSelect ='changeEmpresa'>
                            </selectAll>
                        </div>

                        <div class="tamSelect">
                            <selectAll
                                class="paddingSelect"
                                :labels       ="[{indexDFH: 'MO', description: 'Modelos'}]"
                                nameForRadio  ='Modelos'
                                :loading='carregando'
                                :disabled='$v.empresasSelecionadas.$invalid'
                                v-bind="{
                                    hasSelectAll:false,
                                    isMultiple:false,
                                    optionsArray:optionsModelos,
                                    ref:'selectMO'
                                    }"
                                @changeSelect ='changeModelos'/>
                        </div>

                        <div class="tamSelect">
                            <selectAll
                                class="paddingSelect"
                                nameForRadio  ='Placas'
                                :labels       ="[{indexDFH: 'V', description: 'Placas'}]"
                                :loading      ='carregando'
                                :disabled     ='$v.empresasSelecionadas.$invalid'
                                :optionsArray ='optionsPlacas'
                                ref           ='selectV'
                                :hasSelectAll ='false'
                                :isMultiple   ='false'
                                @changeSelect ='changePlacas'
                            />
                        </div>

                        <div class="tamSelect mt-1">
                            <selectAll 
                                nameForRadio="inputTags" 
                                ref="selectTags" 
                                :optionsArray="optSelectTag" 
                                :disabled="carregandoTags || optSelectTag.length <= 0" 
                                :labels       ="[{indexDFH: 'TAG', description: 'Tags'}]"
                                :isLoading="carregandoTags" 
                                @changeSelect="setTag"
                                :hasSelectAll ='true'
                                :isMultiple   ='true'
                                :selected="selectedTags"
                            />
                        </div>

                        <div class="tamSelect">
                            <selectAll
                                class="paddingSelect"
                                :labels       ="[{indexDFH: 'MSA', description: 'Módulos sem associação'}]"
                                nameForRadio  ='Modulo'
                                :disabled     ='$v.empresasSelecionadas.$invalid || veiculoSelecionado'
                                :loading      ='carregando'
                                :optionsArray ='optionsModulos'
                                ref           ='selectMSA'
                                :hasSelectAll ='false'
                                :isMultiple   ='false'
                                @changeSelect ='changeModulo'/>
                        </div>
                    </div> 

                    <!-- Div dos Selects de baixo -->
                    <div class='col-sm-12 row nopadding'>  
                        <div class='tamSelect nopadding'>
                            <selectAll
                                class="paddingSelect"
                                :labels       ="[{indexDFH: 'S', description: 'Serial'}]"
                                :disabled     ='$v.empresasSelecionadas.$invalid || veiculoSelecionado || tagSelecionada'
                                nameForRadio  ='Serial'
                                :loading      ='carregando'
                                :optionsArray ='optionsSerial'
                                ref           ='selectS'
                                :hasSelectAll ='false'
                                :isMultiple   ='false'
                                :selected     ='preSelectSerial'
                                @changeSelect ='changeSerial'>
                            </selectAll>
                        </div>

                        <div class="tamSelect">
                            <selectAll
                                class="paddingSelect"
                                :labels       ="[{indexDFH: 'I', description: 'IMEI'}]"
                                nameForRadio  ='Imei'
                                :loading      ='carregando'
                                :optionsArray ='optionsImei'
                                ref           ='selectI'
                                :hasSelectAll ='false'
                                :disabled     ='$v.empresasSelecionadas.$invalid || veiculoSelecionado || tagSelecionada'
                                :isMultiple   ='false'
                                :selected     ='preSelectImei'
                                @changeSelect ='changeImei'/>
                        </div>

                        <div class="tamSelect">
                            <selectAll
                                class="paddingSelect"
                                :labels       ="[{indexDFH: 'O', description: 'Operadora'}]"
                                nameForRadio  ='Operadora'
                                :loading      ='carregando'
                                :optionsArray ='optionsOperadora'
                                ref           ='selectO'
                                :hasSelectAll ='false'
                                :isMultiple   ='false'
                                :selected     ='preSelectOperadora'
                                :disabled     ='$v.empresasSelecionadas.$invalid || veiculoSelecionado || tagSelecionada'
                                @changeSelect ='changeOperadora'/>
                        </div>

                        <div class="tamSelect">
                            <selectAll
                                class="paddingSelect"
                                :labels       ="[{indexDFH: 'CH', description: 'Chip'}]"
                                nameForRadio  ='Chip'
                                :loading      ='carregando'
                                :optionsArray ='optionsChip'
                                ref           ='selectCH'
                                :hasSelectAll ='false'
                                :isMultiple   ='false'
                                :selected     ='preSelectChip'
                                :disabled     ='$v.empresasSelecionadas.$invalid || veiculoSelecionado || tagSelecionada'
                                @changeSelect ='changeChip'/>
                        </div>

                        <div class="tamSelect">
                            <selectAll
                                class="paddingSelect"
                                :labels       ="[{indexDFH: 'IC', description: 'ICCID'}]"
                                nameForRadio  ='Iccid'
                                :loading      ='carregando'
                                :optionsArray ='optionsIccid'
                                ref           ='selectIC'
                                :hasSelectAll ='false'
                                :isMultiple   ='false'
                                :selected     ='preSelectIccid'
                                :disabled     ='$v.empresasSelecionadas.$invalid || veiculoSelecionado || tagSelecionada'
                                @changeSelect ='changeIccid'/>
                        </div>
                    </div>

                    <div class='d-flex mt-3 col-12 nopadding'> 
                        <!-- Botões de filtro Chip -->
                        <div class="nopadding col-2">
                            <span class="paddingSpan">Chip</span>
                            <div class="col-sm-12 row p-2">
                                <div class="col-sm-6 nopadding row">
                                    <ButtonsFilters 
                                        class                 = "marginButton"
                                        :onlyOneSelected      = false
                                        :arrayButtons         ='botoesChipCom'
                                        @buttonsFiltersChange ='changeBotoesChipCom'
                                        style="padding: 0px; margin: 0px"
                                    />
                                </div>
                                <div class="col-sm-6 nopadding row">
                                    <ButtonsFilters 
                                        class                 = "marginButton"
                                        :onlyOneSelected      = false
                                        :arrayButtons         ='botoesChipSem'
                                        @buttonsFiltersChange ='changeBotoesChipSem'
                                        style="padding: 0px; margin: 0px"
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- Botões de filtro Pânico -->
                        <div class="nopadding col-2">
                            <span class="paddingSpan">Pânico</span>
                            <div class="col-sm-12 row p-2">
                                <div class="col-sm-6 nopadding">
                                    <ButtonsFilters 
                                        class                 ="col-sm-6 marginButton"
                                        :onlyOneSelected      = false
                                        :arrayButtons         ='botoesPanicoAtivo'
                                        @buttonsFiltersChange ='changeBotoesPanicoAtivo'
                                        style="padding: 0px; margin: 0px"
                                    />
                                </div>
                                <div class="col-sm-6 nopadding">
                                    <ButtonsFilters 
                                        class                 ="marginButton"
                                        :onlyOneSelected      = false
                                        :arrayButtons         ='botoesPanicoInativo'
                                        @buttonsFiltersChange ='changeBotoesPanicoInativo'
                                        style="padding: 0px; margin: 0px"
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- Botões de filtro Bloqueio -->
                        <div class="nopadding col-2">
                            <span class="paddingSpan">Bloqueio</span>
                            <div class="col-sm-12 row p-2">
                                <div class="col-sm-6 nopadding row">
                                    <ButtonsFilters 
                                        class                 ="marginButton"
                                        :onlyOneSelected      = false
                                        :arrayButtons         ='botoesBloqueioAtivo'
                                        @buttonsFiltersChange ='changeBotoesBloqueioAtivo'
                                        style="padding: 0px; margin: 0px"
                                    />
                                </div>
                                <div class="col-sm-6 nopadding row">
                                    <ButtonsFilters 
                                        class                 ="marginButton"
                                        :onlyOneSelected      = false
                                        :arrayButtons         ='botoesBloqueioInativo'
                                        @buttonsFiltersChange ='changeBotoesBloqueioInativo'
                                        style="padding: 0px; margin: 0px"
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- Botões de filtro Status -->
                        <div class="nopadding col-2">
                            <span class="paddingSpan">Status</span>
                            <div class="col-sm-12 row p-2">
                                <div class="col-sm-6 nopadding row">
                                    <ButtonsFilters 
                                        class                 ="marginButton"
                                        :onlyOneSelected      = false
                                        :arrayButtons         ='botoesStatusAtivo'
                                        @buttonsFiltersChange ='changeBotoesStatusAtivo'
                                        style="padding: 0px; margin: 0px"
                                    />
                                </div>
                                <div class="col-sm-6 nopadding row">
                                    <ButtonsFilters 
                                        class                 ="marginButton"
                                        :onlyOneSelected      = false
                                        :arrayButtons         ='botoesStatusInativo'
                                        @buttonsFiltersChange ='changeBotoesStatusInativo'
                                        style="padding: 0px; margin: 0px"
                                    />
                                </div>
                            </div>
                        </div>

                        <!-- Botões de filtro para posicionar -->
                        <div class="nopadding col-4">
                            <ButtonsFilters 
                                zeroValue             ='-1'
                                label                 ='Módulos posicionados'
                                :arrayButtons         ='botoesModulos'
                                @buttonsFiltersChange ='changeBotoesModulos'
                            />
                        </div>
                    </div>
                </slot>
            </slideUpAndDown>
        <div class="col-sm-12 col-md-12"><hr></div>

    <!-- </div> -->
    <div style="margin-bottom: 10px">
        <el-row>
            <el-col :span="6" class='mr-2 divDontPrint'>
                <el-input placeholder="Digite pelo menos 3 caracteres" v-model="searchText"></el-input>
            </el-col>
        </el-row>
    </div>

    <tableRelatorio id="tabela">   
        <!-- Cabeçalho da tabela -->
        <slot slot="thead">
            <th></th>
            <th>Serial</th>
            <th>Modelo</th>
            <th>IMEI</th>
            <th>Placa</th>
            <th>Empresa</th>
            <th>ICCID</th>
            <th>Chip</th>
            <th>Tempo sem comunicar</th>
            <th>Ignição</th>
            <th>Entradas</th>
            <th>Saídas</th>
            <th>Informações adicionais</th>
        </slot>
        <slot slot="tbody">
            <!-- Corpo da tabela -->
            <statusInformation 
                v-if="relatorio.length == 0" 
                typeBar="tr" 
                :statusBar="statusbar"
                :colspanForTd="13">
            </statusInformation>
            <br/>
            <template v-for="(evento, teste) in mostrarItens">
                <tr :key="teste"
                    v-show="evento.mostrar != false"
                    v-if="true"
                    :class="evento.tempoSegundos > '43200' || evento.tempoSegundos == null ?
                                'tableVermelho' :
                                'tableBranco'">
                    <!-- Se esta ativo ou não -->
                    <td class="aling-itens color-icon">
                        <span :title="evento.mostatus == 'A' ?  'Ativo' : 'Inativo'">
                            <baseIcon 
                                :class="evento.mostatus == 'A' ? 'verde' : 'vermelho'"
                                size='18'
                                :icon="mdiCheckboxBlankCircle"/>
                                <!-- {{evento.mostatus}} -->
                        </span>
                    </td>
                    <!-- Serial -->
                    <td class="aling-itens click">
                        {{ evento.mocodigo }}
                        <a @click="serial(evento.mocodigo)">
                            <baseIcon size='18' :icon="mdiPencilBoxOutline"/>
                        </a>
                    </td>
                    <!-- Modelo do rastreador -->
                    <td
                    class="aling-itens"
                    v-text='evento.mmdescricao'/>
                    <!-- IMEI -->
                    <td
                    class="aling-itens"
                    v-text='evento.moimei'/>
                    <!-- Placa -->
                    <td class="aling-itens iccid click">
                        {{ evento.veplaca }}
                        <a @click="placa(evento.vecodigo)">
                            <baseIcon size='18' :icon="mdiPencilBoxOutline"/>
                        </a>
                    </td>
                    <!-- Empresa -->
                    <td
                    class="aling-itens"
                    v-text='evento.clfantasia'/>
                    <!-- ICCID -->
                    <td
                    class="aling-itens iccid"
                    v-text='evento.iccid'/>
                    <!-- Chip -->
                    <td class="aling-itens iccid click">
                        {{ evento.chnumero }}
                        <a @click="chip(evento.chcodigo)">
                            <baseIcon size='18' :icon="mdiPencilBoxOutline"/>
                        </a>
                    </td>
                    <!-- Tempo sem comunicar -->
                    <td
                    :title="evento.ultimaComu != '' ? 'Última comunicação: ' + evento.ultimaComu : 'Última comunicação: Status desconhecido'"
                    class="aling-itens"
                    v-text='evento.semComunicacao'/>
                    <!-- Ignição -->
                    <td
                    class="aling-itens ignicao color-icon">
                        <span :title="evento.moultimaignicao == 1 ? 'Ignição Ligada ' : 'Ignição Desligada'">
                            <baseIcon 
                                :class="evento.moultimaignicao == 1 ? 'verde' : 'vermelho'"
                                size='14'
                                :icon="mdiCheckboxBlankCircle"/>
                        </span>
                    </td>
                    <!-- Entradas -->
                    <td class="aling-itens color-icon">
                        <span :title="evento.moentrada01 == 1 ? 'Entrada 01 - Comunicação ativa ' : 'Entrada 01 - Sem comunicação'">
                            <baseIcon 
                                :class="evento.moentrada01 == 1 ? 'verde' : 'vermelho'"
                                size='14'
                                :icon="mdiCheckboxBlankCircle"/>
                        </span>
                        <span :title="evento.moentrada02 == 1 ? 'Entrada 02 - Comunicação ativa ' : 'Entrada 02 - Sem comunicação'">
                            <baseIcon 
                                :class="evento.moentrada02 == 1 ? 'verde' : 'vermelho'"
                                size='14'
                                :icon="mdiCheckboxBlankCircle"/>
                        </span>
                        <span :title="evento.moentrada03 == 1 ? 'Entrada 03 - Comunicação ativa ' : 'Entrada 03 - Sem comunicação'">
                            <baseIcon 
                                :class="evento.moentrada03 == 1 ? 'verde' : 'vermelho'"
                                size='14'
                                :icon="mdiCheckboxBlankCircle"/>
                        </span>
                        <span :title="evento.moentrada04 == 1 ? 'Entrada 04 - Comunicação ativa ' : 'Entrada 04 - Sem comunicação'">
                            <baseIcon 
                                :class="evento.moentrada04 == 1 ? 'verde' : 'vermelho'"
                                size='14'
                                :icon="mdiCheckboxBlankCircle"/>
                        </span>
                    </td>
                    <!-- Saídas -->
                    <td class="aling-itens color-icon">
                        <span :title="evento.mosaida01 == 1 ? 'Saída 01 - Comunicação ativa' : 'Saída 01 - Sem comunicação'">
                            <baseIcon 
                                :class="evento.mosaida01 == 1 ? 'verde' : 'vermelho'"
                                size='14'
                                :icon="mdiCheckboxBlankCircle"/>
                        </span>
                        <span :title="evento.mosaida02 == 1 ? 'Saída 02 - Comunicação ativa' : 'Saída 02 - Sem comunicação'">
                            <baseIcon 
                                :class="evento.mosaida02 == 1 ? 'verde' : 'vermelho'"
                                size='14'
                                :icon="mdiCheckboxBlankCircle"/>
                        </span>
                        <span :title="evento.mosaida03 == 1 ? 'Saída 03 - Comunicação ativa' : 'Saída 03 - Sem comunicação'">
                            <baseIcon 
                                :class="evento.mosaida03 == 1 ? 'verde' : 'vermelho'"
                                size='14'
                                :icon="mdiCheckboxBlankCircle"/>
                        </span>
                        <span :title="evento.mosaida04 == 1 ? 'Saída 04 - Comunicação ativa' : 'Saída 04 - Sem comunicação'">
                                <baseIcon 
                                :class="evento.mosaida04 == 1 ? 'verde' : 'vermelho'"
                                size='14'
                                :icon="mdiCheckboxBlankCircle"/>
                        </span>
                        
                    </td>
                    <!-- Informações adicionais -->
                    <td class="aling-itens color-icon">
                        <!-- Operadora   -->
                        <span :title="evento.opdescricao != null ? 'Operadora: ' + evento.opdescricao: 'Operadora: Desconhecida'">
                            <baseIcon 
                                :class="evento.opdescricao != null ? 'verde' : ''"
                                size='14'
                                :icon="mdiSim"/>
                        </span>
                        <!-- Pânico -->
                        <span :title="evento.moultimopanico == 1 ? 'Pânico ativado' : 'Pânico: Status desconhecido'">
                            <baseIcon 
                                :class="evento.moultimopanico == 1 ? 'verde' : ''"
                                size='14'
                                :icon="mdiAlert"/>
                        </span>
                        <span
                            :title="evento.moultimalat &&  evento.moultimalon != '' ? 
                                    'Latitude: ' + evento.moultimalat + ' Longitude: ' + evento.moultimalon : 
                                    'Latitude: Status desconhecido' +  'Longitude: Status desconhecido'"
                            @click="maps(evento.moultimalat, evento.moultimalon)">
                            <baseIcon
                                class="cursor-pointer cor-target"
                                :class="evento.moultimopanico == 1 ? 'verde' : ''"
                                size='14'
                                :icon="mdiTarget"/>
                        </span>
                        <!-- Refêrencia -->
                        <span :title="evento.moultimacidade ? 
                                        'Referência:  ' + evento.moultimareferencia +'   Cidade:  '+evento.moultimacidade+'   Região:  ' + evento.redescricao :
                                        'Referência:  ' + evento.moultimareferencia +'   Cidade:  '+'Região:  ' + evento.redescricao">
                            <baseIcon
                                :class="evento.redescricao != '' ? 'azul' : ''"
                                size='14'
                                :icon="mdiMapMarker"/>
                        </span>
                        <!-- Data de instalação -->
                        <span :title="evento.vedatainstalacao != '' ? 'Data de instalação: ' + evento.vedatainstalacao : 'Data de instalação: Status desconhecido'">
                            <baseIcon
                                :class="evento.vedatainstalacao != '' ? 'verde' : ''"
                                size='14'
                                :icon="mdiCog"/>
                        </span>
                        <!-- Última comunicação -->
                        <span :title="evento.ultimaComu != '' ? 'Última comunicação: ' + evento.ultimaComu : 'Última comunicação: Status desconhecido'">
                            <baseIcon
                                :class="evento.ultimaComu != '' ? 'verde' : ''"
                                size='14'
                                :icon="mdiCalendarClock"/>
                        </span>
                        <!-- Motivo -->
                        <span :title="evento.motdescricao ? 'Motivo: ' + evento.motdescricao : 'motivo: Status desconhecido'">
                            <baseIcon
                                :class="evento.motdescricao != '' ? 'amarelo' : ''"
                                size='14'
                                :icon="mdiAlertCircle"/>
                        </span>
                        <!-- Bateria -->
                        <span :title="evento.moporcentagembateria != null ? evento.moporcentagembateria + '%' :  'Sem informação'">
                            <baseIcon
                                :class="
                                    evento.moporcentagembateria != null && 
                                    evento.moporcentagembateria == 100 ? 'verde' : 
                                    evento.moporcentagembateria != 100  && 
                                    evento.moporcentagembateria != null && 
                                    evento.moporcentagembateria != 0 ? 'verde' : 'vermelho'
                                "
                                size='14'
                                :icon="
                                    evento.moporcentagembateria != null && 
                                    evento.moporcentagembateria == 100 ?
                                    mdiBatteryHigh : 
                                    evento.moporcentagembateria != 100  && 
                                    evento.moporcentagembateria != null && 
                                    evento.moporcentagembateria != 0 ?
                                    mdiBatteryMedium : 
                                    mdiBatteryLow
                                "
                            />
                        </span>
                    </td>
                </tr>
            </template>
        </slot>
        <slot slot="tfoot">
            <tr v-if="mostrarItens.length > 0">
                <td colspan="13">
                    Total de registros: {{mostrarItens.length}}
                </td>
            </tr>
        </slot>
    </tableRelatorio>

    </panelEagle>
</template>
<script>
import Vue  from 'vue'
import {
	mdiMonitorDashboard, 
	mdiCheckboxBlankCircle, 
	mdiSim, 
	mdiAlert, 
	mdiTarget,
	mdiMapMarker,
	mdiCog,
	mdiCalendarClock,
	mdiAlertCircle,
	mdiBatteryHigh,
	mdiBatteryMedium,
	mdiBatteryLow,
	mdiResize,
	mdiMoveResizeVariant,
	mdiPencilBoxOutline
} from '@mdi/js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest }     from '@/Services/auth/HttpRequest.Service'
import { FiltrosService }  from '@/Services/filtros/filtros.Service'
import { required } from 'vuelidate/lib/validators';

export default Vue.extend({
	name:'monitor',

	components:{
		'basicButtonsRelatoriosEIG': require('@/components/Atom/Buttons/BasicButtonsRelatoriosEIG').default,
		'slideUpAndDown': require('@/components/Atom/SlideUpAndDown/SlideUpAndDown').default,
		'statusInformation': require('@/components/Atom/StatusInformation/StatusInformation').default,
		'ButtonsFilters': require('@/components/Atom/Buttons/ButtonsFilters').default,
		'tableRelatorio': require('@/components/Atom/Table/TableRelatorio').default,
		'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
		'selectAll': require('@/components/Atom/Select/SelectAll').default,
		'baseIcon': require('@/components/Atom/Icon/BaseIcon.vue').default,
		'tituloPage': require('@/components/Atom/Header/Titulo').default,
		'selectTag': require('@/components/Atom/Select/SelectTags').default,
	},

	validations:{
		empresasSelecionadas: { required },
		relatorio : { required },
	},

	data() {
		return {
			mdiCheckboxBlankCircle:mdiCheckboxBlankCircle,
			mdiMonitorDashboard:mdiMonitorDashboard,
			mdiCalendarClock:mdiCalendarClock,
			mdiBatteryMedium:mdiBatteryMedium,
			mdiBatteryHigh:mdiBatteryHigh,
			mdiAlertCircle:mdiAlertCircle,
			mdiBatteryLow:mdiBatteryLow,
			mdiMapMarker:mdiMapMarker,
			iconResize: mdiResize,
			mdiTarget:mdiTarget,
			mdiAlert:mdiAlert,
			mdiCog:mdiCog,
			mdiSim:mdiSim,
			mdiPencilBoxOutline: mdiPencilBoxOutline,
			optionsEmpresas                   : new EmpresasService().Get(),
			optionsModelos                    : [],
			optionsPlacas                     : [],
			optionsSerial                     : [],
			optionsModulos                    : [],
			optionsImei                       : [],
			optionsOperadora                  : [],
			optionsChip                       : [],
			optionsIccid                      : [],
			empresasSelecionadas              : [],
			modelosSelecionados               : [],
			placasSelecionadas                : [],
			moduloSelecionado                 : [],
			serialSelecionado                 : [],
			imeiSelecionado                   : [],
			operadoraSelecionada              : '',
			chipSelecionado                   : [],
			iccidSelecionado                  : [],
			botoesModulosSelecionados         : [],
			botoesChipSelecionadosCom         : [],
			botoesChipSelecionadosSem         : [],
			botoesPanicoSelecionadosAtivo     : [],
			botoesPanicoSelecionadosInativo   : [],
			botoesBloqueioSelecionadosAtivo   : [],
			botoesBloqueioSelecionadosInativo : [],
			botoesStatusSelecionadosAtivo     : [],
			botoesStatusSelecionadosInativo   : [],
			atualiza                          : undefined,
			carregando                        : false,
			relatorio                         : [],
			statusbar                         : 'info',
			carregandoE                       :[false,false,false], 
			filter                            : null,
			fields                            : [], 
			gerandoRel                        : false,

			botoesModulos : [
				{'value':'1' , 'width':'47.7%', 'isSelected':false, 'text':'1 dia'},
				{'value':'7' , 'width':'47.7%', 'isSelected':false, 'text':'7 dias'},
				{'value':'15', 'width':'47.7%', 'isSelected':false, 'text':'15 dias'},
				{'value':'30', 'width':'47.7%', 'isSelected':false, 'text':'30 dias'},
				{'value':'0' , 'width':'98%'  , 'isSelected':false, 'text':'Nunca posicionou'},
			],
			botoesChipCom : [
				{'value':'1' , 'width':'100%', 'isSelected':true, 'text':'Com'},
			],
			botoesChipSem : [
				{'value':'1' , 'width':'100%', 'isSelected':true, 'text':'Sem'},
			],
			botoesPanicoAtivo : [
				{'value':'1' , 'width':'100%', 'isSelected':true, 'text':'Ativo'},
			],
			botoesPanicoInativo : [
				{'value':'1' , 'width':'100%', 'isSelected':true, 'text':'Inativo'},
			],
			botoesBloqueioAtivo : [
				{'value':'1' , 'width':'100%', 'isSelected':true, 'text':'Ativo'},
			],
			botoesBloqueioInativo : [
				{'value':'1' , 'width':'100%', 'isSelected':true, 'text':'Inativo'},
			],
			botoesStatusAtivo : [
				{'value':'1' , 'width':'100%', 'isSelected':true, 'text':'Ativo'},
			],
			botoesStatusInativo : [
				{'value':'1' , 'width':'100%', 'isSelected':true, 'text':'Inativo'},
			],
			preSelectImei      :[],
			preSelectSerial    :[],
			preSelectChip      :[],
			preSelectOperadora :[],
			preSelectIccid     :[],
			searchText: '',
            labelsPlacaTags: [
                {
                    indexDFH: 'TAG',
                    description: 'Tags*'
                }
            ],
            tipoSelecionado: 1,
            selectedRadio: 1,
            carregandoTags: false,
            tagsSelecionadas: [],
            optSelectTag: [],
            tagSelecionada: false,
            selectedTags: [],
		}
	},

	beforeDestroy(){
		clearInterval(this.atualiza)
	},

	methods: {
		/**
         * Eu tinha feito uma função bem mais bonitinha aqui, que controlava 
         * o tamanho da tela pelo objeto window e agia de acordo, tipo, bem massa
         * mas adivinha? Não funciona no google chrome 😅
         * Então, vai na gambia mesmo, vlw google \o
         */
		resizeWindow(){
			if(this.iconResize == mdiMoveResizeVariant)
				this.exitFullscreen()
			else 
				this.goFullscreen()
		},
		
        /**
         * Consulta e retorna as tags associadas a uma empresa.
         * 
         * Esta função envia uma requisição POST para consultar as tags associadas a uma empresa
         * e atualiza a lista de opções de seleção de tags no frontend.
         *
         * @return {void}
         * @autor Yuri 🇧🇷
         */
        async consultarTags(){
            this.carregandoTags = true;

            const url = '/administrativo/monitor/consultar/tags'
            const obj = {
                codigoEmpresa: this.empresasSelecionadas
            }

            try {
                await new HttpRequest().Post(url, obj).then((data) => {
                    this.optSelectTag = data.data;
                }).catch((error) => {
                    this.carregandoTags = false;
                }).finally(() => {
                    this.carregandoTags = false;
                });
            } catch (error) {
                this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');
                this.carregandoTags = false;
            }
        },

        setTag(tag) {
			this.tagsSelecionadas = tag;
		},

		goFullscreen(){
			this.iconResize  = mdiMoveResizeVariant
			if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) { 
				if (document.documentElement.requestFullscreen) {
					document.documentElement.requestFullscreen()
				} else if (document.documentElement.msRequestFullscreen) {
					document.documentElement.msRequestFullscreen()
				} else if (document.documentElement.mozRequestFullScreen) {
					document.documentElement.mozRequestFullScreen()
				} else if (document.documentElement.webkitRequestFullscreen)
					document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
			}
		},

		exitFullscreen(){
			this.iconResize = mdiResize
			if (document.exitFullscreen) {
				document.exitFullscreen()
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen()
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen()
			} else if (document.webkitExitFullscreen) {
				document.webkitExitFullscreen()
			}
		},
   
		changeEmpresa(empresas){
			this.empresasSelecionadas = empresas;
            this.selectedTags = [];

			if(empresas.length > 0){
				this.optionsSelect(empresas);
			}

			this.controleIntervalo('destruir');
            this.consultarTags();
		},

		changeModelos(modelos){
			this.modelosSelecionados = modelos
			if(modelos.length)
				this.filtraOpts()
			else
				this.optionsSelect(this.empresasSelecionadas)
		},

		filtraOpts(){
			var uri = '/administrativo/monitor/filtrar/dados'
			var obj = {
				cliente: this.empresasSelecionadas,
				modelos: this.modelosSelecionados
			}
			new HttpRequest().Post(uri, obj).then((data)=>{
				this.optionsPlacas = data.data.veiculos
			})
		},

		infoPlaca(){
			var uri = '/administrativo/monitor/dados/placa'
			var obj = {
				placas:this.placasSelecionadas
			}
			new HttpRequest().Post(uri, obj).then((data)=>{
				this.preSelectImei = data.data.imei
				this.preSelectSerial  = data.data.serial
				this.preSelectChip = data.data.chip
				this.preSelectOperadora = data.data.operadora
				this.preSelectIccid = data.data.iccid
			})
		},

		changePlacas(placas){
			this.placasSelecionadas = placas
			this.preSelectImei = []
			this.preSelectSerial = []
			this.preSelectChip = []
			this.preSelectOperadora = []
			this.preSelectIccid = []
			if(this.veiculoSelecionado)
				this.infoPlaca()
		},

		changeModulo(modulo){
			this.moduloSelecionado = modulo
		},

		changeSerial(serial){
			this.serialSelecionado = serial
		},

		changeImei(imei){
			this.imeiSelecionado = imei
		},

		changeOperadora(operadora){
			this.operadoraSelecionada = operadora
		},

		changeChip(chip){
			this.chipSelecionado = chip
		},

		changeIccid(iccid){
			this.iccidSelecionado = iccid
		},

		changeBotoesModulos(botoesModulos){
			this.botoesModulosSelecionados = botoesModulos
		},

		changeBotoesChipCom(botoesChip){
			this.botoesChipSelecionadosCom = botoesChip
		},

		changeBotoesChipSem(botoesChip){
			this.botoesChipSelecionadosSem = botoesChip
		},

		changeBotoesPanicoAtivo(botoesPanico){
			this.botoesPanicoSelecionadosAtivo = botoesPanico
		},

		changeBotoesPanicoInativo(botoesPanico){
			this.botoesPanicoSelecionadosInativo = botoesPanico
		},

		changeBotoesBloqueioAtivo(botoesBloqueio){
			this.botoesBloqueioSelecionadosAtivo = botoesBloqueio
		},

		changeBotoesBloqueioInativo(botoesBloqueio){
			this.botoesBloqueioSelecionadosInativo = botoesBloqueio
		},

		changeBotoesStatusAtivo(botoesStatus){
			this.botoesStatusSelecionadosAtivo = botoesStatus
		},

		changeBotoesStatusInativo(botoesStatus){
			this.botoesStatusSelecionadosInativo = botoesStatus
		},

		async gerarRelatorio(){
			this.gerandoRel = true
			if(this.empresasSelecionadas.length > 0){
				this.relatorio = [];
				if(this.botoesModulosSelecionados == -1){
					this.botoesModulosSelecionados = ''
				}
				var obj = {
					'cliente'         : this.empresasSelecionadas,
					'modelo'          : this.modelosSelecionados,
					'placa'           : this.placasSelecionadas,
					'associacao'      : this.moduloSelecionado,
					'serial'          : this.serialSelecionado,
					'imei'            : this.imeiSelecionado,
					'operadora'       : this.operadoraSelecionada,
					'chip'            : this.chipSelecionado,
					'iccid'           : this.iccidSelecionado,
					'chipCom'         : this.botoesChipSelecionadosCom,
					'chipSem'         : this.botoesChipSelecionadosSem,
					'panicoAtivo'     : this.botoesPanicoSelecionadosAtivo,
					'panicoInativo'   : this.botoesPanicoSelecionadosInativo,
					'bloqueioAtivo'   : this.botoesBloqueioSelecionadosAtivo,
					'bloqueioInativo' : this.botoesBloqueioSelecionadosInativo,
					'statusAtivo'     : this.botoesStatusSelecionadosAtivo,
					'statusInativo'   : this.botoesStatusSelecionadosInativo,
					'filtroDias'      : this.botoesModulosSelecionados,
                    'tags'            : this.tagsSelecionadas
				}
				var dados = await new HttpRequest().Post('/administrativo/monitor/gerarRelatorio', obj);
				this.relatorio = dados.data;

				this.controleIntervalo('criar')
				if(this.relatorio == ''){
					this.statusbar = 'error';
				}
				this.gerandoRel = false
			}
		},

		async atualizarRelatorio(){
			if(this.empresasSelecionadas.length > 0){
				if(this.botoesModulosSelecionados == -1){
					this.botoesModulosSelecionados = ''
				}
				var mod = {
					'cliente'         : this.empresasSelecionadas,
					'modelo'          : this.modelosSelecionados,
					'placa'           : this.placasSelecionadas,
					'associacao'      : this.moduloSelecionado,
					'serial'          : this.serialSelecionado,
					'imei'            : this.imeiSelecionado,
					'operadora'       : this.operadoraSelecionada,
					'chip'            : this.chipSelecionado,
					'iccid'           : this.iccidSelecionado,
					'chipCom'         : this.botoesChipSelecionadosCom,
					'chipSem'         : this.botoesChipSelecionadosSem,
					'panicoAtivo'     : this.botoesPanicoSelecionadosAtivo,
					'panicoInativo'   : this.botoesPanicoSelecionadosInativo,
					'bloqueioAtivo'   : this.botoesBloqueioSelecionadosAtivo,
					'bloqueioInativo' : this.botoesBloqueioSelecionadosInativo,
					'statusAtivo'     : this.botoesStatusSelecionadosAtivo,
					'statusInativo'   : this.botoesStatusSelecionadosInativo,
					'filtroDias'      : this.botoesModulosSelecionados,
                    'tags'            : this.tagsSelecionadas
				}
				var dados = await new HttpRequest()
					.Post('/administrativo/monitor/gerarRelatorio', mod);
				this.relatorio = dados.data;
				if(this.relatorio == ''){
					this.statusbar = 'error';
				}
			}
		},

		optionsSelect(arr){
			if(arr.length > 0){
				this.carregando = true
				new FiltrosService().dados_filtros(arr, 
					['V', 'MO', 'MSA', 'S', 'I', 'O', 'CH', 'IC'])
					.then((data)=>{
						this.optionsModelos   = data.MO
						this.optionsPlacas    = data.V
						this.optionsModulos   = data.MSA
						this.optionsSerial    = data.S
						this.optionsImei      = data.I
						this.optionsOperadora = data.O
						this.optionsChip      = data.CH
						this.optionsIccid     = data.IC
						this.carregando = false
					})
			}else{
				this.$refs.selectMO.clearAll()
				this.$refs.selectV.clearAll()
				this.$refs.selectMSA.clearAll()
				this.$refs.selectS.clearAll()
				this.$refs.selectI.clearAll()
				this.$refs.selectO.clearAll()
				this.$refs.selectCH.clearAll()
				this.$refs.selectIC.clearAll()
			}
		},

		async exportarRelatorio(type){
			var root =  process.env.VUE_APP_ROOT;
			this.setCarregando(type)
			if(this.relatorio != ''){
				var data = await new HttpRequest().Post('/administrativo/monitor/exportarRelatorio', 
					{'arrayDados':this.relatorio, 'tipo':type});
				window.open(root+'/'+data.data.dados);
			}
			this.setCarregando()
		},

		maps(lat, long){
			window.open('http://maps.google.com/maps?q=loc:'+lat+','+long);
		},

		serial(id){
			const {href} = this.$router.resolve({
				name:'cadastrarModulos', 
				params:{'id':id}})
			window.open(href, '_blank')
		},

		placa(id){
			const {href} = this.$router.resolve({
				name:'editarVeiculo', 
				params:{'id':id}})
			window.open(href, '_blank')
		},

		chip(id){
			const {href} = this.$router.resolve({
				name:'cadastrarChip', 
				params:{'id':id}})
			window.open(href, '_blank')
		},

		setCarregando(type = null){
			this.carregandoE = [type == 'pdf', type == 'xls', type == 'csv']
		},  

		controleIntervalo(func){
			if(func == 'criar'){
				if(this.atualiza === undefined){
					this.atualiza = setInterval(() => {
						this.atualizarRelatorio()
					}, 30000);
				}
			}else{
				//destruir intervalo
				if(this.atualiza !== undefined){
					clearInterval(this.atualiza)
					this.atualiza = undefined
				}
			}
		},

        /**
            Função para selecionar qual tipo de filtro foi 
            selecionado e mostra em tela para o usuário o inpput correspondente,
            variável que salva qual tipo foi selecionado é 
            this.tipoSelecionado.
            @param {tipo} number tipo para filtro selecionado, 
            1 = Placas
            2 = Tags
            @author Yuri 🇧🇷
        */
        selecionaTipo(tipo) {
            this.tipoSelecionado = tipo;
            this.tagsSelecionadas = [];
            this.placasSelecionadas = [];
            this.tagSelecionada = false;
        },
	},

	computed: {
		/**Só uma maneira simples de saber se tem uma placa selecionada */
		veiculoSelecionado() {
			return !!this.placasSelecionadas.length
		},

		mostrarItens() {
			let text = this.searchText.toLowerCase()
			if(this.searchText && this.searchText.length >= 3) {
				this.relatorio.map(e => 
					e.mostrar =
                        e.mocodigo && e.mocodigo.toString().toLowerCase().includes(text)
                        || e.mmdescricao && e.mmdescricao.toLowerCase().includes(text)
                        || e.moimei && e.moimei.toString().toLowerCase().includes(text)
                        || e.veplaca && e.veplaca.toLowerCase().includes(text)
                        || e.iccid && e.iccid.toString().toLowerCase().includes(text)
                        || e.chnumero && e.chnumero.toLowerCase().includes(text)
				)
			} else {
				this.relatorio.map(e => e.mostrar = true)
			}
			return this.relatorio
		}
	},
})
</script>

<style lang="scss" scoped> 
    .botaoScreen{
        margin-top: 1px;
        height: 28px;
    }
    .tamSelect{
        width: 20%; 
    }
    div.tamSelect{
        div.paddingSelect{
            font-size: 200px;
        }
    }
    .tamEmpresas{
        width: 40%;
    }
    .paddingSelect{
        padding: 5px;   
    }
    div.subTitleSelect> fieldset.form-group{
        font-size: 10px !important;
    }
    div col-sm-9.row.tamDiv{
        padding: 0px !important;
    }
    .marginButton{
        padding-left: 5px;
        padding-right: 5px;
        text-align: left;
    }
    .tableVermelho{
        background: #f5c2c2 !important;
    }
    .tableBranco{
        background: white;
        color: black;
    }
    .aling-itens{
        padding-left: 5px;
        padding-right: 2px;
        align-content: left;
        vertical-align: middle;
    }
    .iccid{
        min-width: 100px;
    }
    // span::before{
    //     margin: 0px;
    //     margin-right: 5px;
    //     font-size: 15px;
    // }
    .cor-target{
        color: #0374a8;
    }
    .click{
        font-weight: bold;
        text-align-last: justify;
        a {
            color: #078ecd !important;
            cursor: pointer;
            &:hover {
                color: #888 !important;
            }
        }
    }
    .paddingSpan{
        padding-left: 21px;
    }
    .color-icon{
        white-space: nowrap;
        color: #767676;
    }
    .vermelho{
        color: #e04b6f;
    }
    .verde{
        color: #4da424;
    }
    .azul{
        color: #3097d1;
    }
    .amarelo{
        color: #d19111;
    }
</style>
