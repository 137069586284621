<template>
    <div
	id='popup-desvio-coletivo'
	v-b-visible.once='verificaData'
	>
		<div class='d-flex justify-content-center'>
			<b class='titulo-desvio'>Desvio de rota</b>
		</div>
		<div v-if='!buscando'>
			<div>
				<b>Início:</b>
				{{infoDesvio.inicio}}
			</div>
			<div>
				<b>Fim:</b>
				{{infoDesvio.fim}}
			</div>
			<div>
				<b>Justificativa:</b>
				{{infoDesvio.justificativa}}
			</div>
			<div>
				<b>Observação:</b>
				{{infoDesvio.observacao}}
			</div>
			<div class='d-flex justify-content-center'>
				<b-button
				squared
				type='button'
				variant='info'
				class='btn-imagens-coletivos'
				:disabled='buscandoImg'
				@click='buscarImagens'>
					<b-spinner
					small
					v-if='buscandoImg'/>
					<span
					v-else>Ver Imagens</span>
				</b-button>
			</div>
		</div>
		<div
		v-else
		class='d-flex justify-content-center'>
			<b-spinner
			label='gira gira gira'/>
		</div>
    </div>
</template>

<script>
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
export default {
	name:'popup-desvio-coletivo',
	props:{
		desvio:{
			type:Number,
			required:true
		}
	},
	data() {
		return {
			infoDesvio: {
				inicio:'',
				fim:'',
				justificativa:'',
				observacao:'',
			},
			baseUri:'/finder/coletivos/',
			buscando:false,
			buscandoImg:false,
			arrayImgs:[]
		}
	},
	methods:{
		/**
		 * @param {boolean} visible
		 * @description quando o usuário abre a popup, ele busca
		 * pelas informações no back, e isso só acontece a primeira
		 * vez que o usuário abre a popup
		 */
		verificaData(visible){
			if(visible){
				var uri = `${this.baseUri}get/desvio/${this.desvio}`
				this.buscando = true
				new HttpRequest().Get(uri).then((data)=>{
					this.infoDesvio = data.data.desvio
				}).finally(()=>this.buscando = false)
			}
		},

		/**
		 * @listens click - botão 'ver imagens'
		 * @description procura por imagens do desvio de rota no back
		 */
		buscarImagens(){
			this.buscandoImg = true
			var uri = `${this.baseUri}get/imagens/${this.desvio}`
			new HttpRequest().Get(uri).then((data)=>{
				this.buscandoImg = false
				this.arrayImgs = data.data.arquivo
			})
		}
	}
}
</script>

<style lang="scss" scoped>
#popup-desvio-coletivo{
	width: fit-content;
	min-width: 200px;
	max-width: 500px;
	.titulo-desvio{
		font-size: 15px;
		font-weight: bolder;
	}
	.btn-imagens-coletivos{
		width: 100px;
	}
}

</style>