<template>
    <markerPoint
        @contextmenu="contextmenu"
        :key="index+'_marker_selecionado'"
        v-if="true"
        :id="dados.pocodigo"
        :latitude="parseFloat(dados.polatitude)"
        :longitude="parseFloat(dados.polongitude)"
        :icon="getIcon(dados.irordem, dados.irmarcador)"
        typeIcon="divIcon">
        <popupMarker>
            <div class="col-sm-12 row nopadding div-popup">
                <div class="col-sm-12 nopadding mb-3">
                    {{ dados.irnome }}
                </div>
                <div class="col-12 nopadding row">
                    <div class="col-6 nopadding">
                        <b>Peso:</b>{{formatFloat(dados.irpeso)}}kg
                    </div>
                    <div class="col-6 nopadding">
                        <b>Cubagem:</b>
                        {{dados.ircubagem}}
                    </div>
                    <div class="col-6 nopadding">
                        <b>Volume:</b>
                        {{dados.irqtde}}
                    </div>
                    <div class="col-6 nopadding">
                        <b>Pedido:</b>
                        {{dados.irdocumento}}
                    </div>
                    <div class="col-6 nopadding">
                        <b>Valor:</b>
                        {{formatFloat(dados.irvalor)}}
                    </div>
                </div>
                <div class="col-sm-8 nopadding">
                    <simpleButton
                        @click="selecionar"
                        event="click"
                        width="100%"
                        text="Incluir/Remover da rota"
                        type="blue"
                    />
                </div>
                <div class="col-sm-4 nopadding">
                    <simpleButton
                        @click="editar"
                        event="click"
                        width="100%"
                        text="Editar Ponto"
                        type="red"
                    />
                </div>
            </div>
        </popupMarker>
    </markerPoint>
</template>

<script>
import { pickColor } from '@/views/Roteirizador/NaoMenus/RotaManual.ts'

import { required } from 'vuelidate/lib/validators'

export default {
	components: {
		popupMarker: require('@/components/Atom/Mapa/PopupMarker').default,
		markerPoint: require('@/components/Atom/Mapa/MarkerPoint').default,
		simpleButton: require('@/components/Atom/Buttons/SimpleButton').default,
	},
	props: {
		dados: {
			required
		},
		index: {
			required
		}
	},
	methods: {
		getIcon(ordem, marcador) {
			return `<div class='markerRotaCircle'>
                        <div class='markerRotaCentro' 
                            style='background-color:${this.chamaPickColor(marcador)}'>
                        </div>
                        <div class='markerRotaText'>
                            ${ordem}
                        </div>
                    </div>`
			// MODELO ANTERIOR
			// return `<div>
			//     <span class='markerRotaOrdem'>${ordem}</span>
			//     <img src='img/pontoSelecionado.png'>
			// </div>`
		},
		selecionar() {
			this.$emit('selecionar')
		},
		editar() {
			this.$emit('editar')
		},
		contextmenu() {
			this.$emit('contextmenu')
		},
		chamaPickColor(str) {
			return pickColor(str)
		},
		formatFloat(value){
			if(!value) value = 0.00
			value = parseFloat(value)
			value = value.toFixed(2)
			return value
		},
	}
}
</script>