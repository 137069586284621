var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('panelEagle',{attrs:{"id":"CheckpointPontualidade","loading":_vm.loadingPanel}},[_c('div',{staticClass:"col-sm-12 row nopadding"},[_c('div',{staticClass:"col-sm-6"},[_c('tituloPage',{attrs:{"icon":_vm.mdiAlarmCheck,"titulo":"Pontualidade"}})],1),_c('div',{staticClass:"col-sm-6 divDontPrint"},[_c('basicButtonsRelatoriosEIG',{attrs:{"disabled":_vm.$v.$invalid,"loading":_vm.loadingBtn,"disabledDropdown":Object.keys(_vm.dadosRelatorio).length == 0},on:{"exportarRelatorio":_vm.exportarRelatorio,"gerarRelatorio":_vm.gerarRelatorio}})],1)]),_c('div',{staticClass:"col-sm-12"},[_c('hr')]),_c('slideUpAndDown',[_c('div',{staticClass:"col-sm-12 row nopadding"},[_c('div',{staticClass:"col-sm-4 nopadding"},[_c('inputRangeWithLimit',{ref:"refData",attrs:{"valueData":_vm.valueData,"name":"botaoData","titulo":"Intervalo*","value":_vm.valueData},on:{"changeInput":v => _vm.valueData = v}})],1),_c('div',{staticClass:"col-sm-4 nopadding"},[_c('selectAll',{attrs:{"nameForRadio":"OOOO ANA JULIA","labels":[{
                            indexDFH: 'EM', 
                            description: 'Empresas*'
                        }],"optionsArray":_vm.optionsSelectEmpresa,"isMultiple":true,"hasSelectAll":true},on:{"changeSelect":_vm.changeEmpresas}})],1),_c('div',{staticClass:"col-sm-4 nopadding"},[_c('selectAll',{ref:"refColab",attrs:{"disabled":_vm.disabledVei,"nameForRadio":"Oouououou","firstSelected":"CO","labels":[
                            {
                                indexDFH: 'CO', 
                                description: 'Colaboradores*'
                            },
                            {
                                indexDFH: 'DE', 
                                description: 'Departamentos*'
                            }
                        ],"isMultiple":true,"hasSelectAll":true,"loading":_vm.loadingColab,"optionsArray":_vm.retornaOptColabEDep},on:{"changeSelect":v => _vm.valueColabEDep = v,"changeCheck":v => _vm.checkSelecionado = v}})],1)])]),_c('div',{staticClass:"col-sm-12 nopadding mt-4"},[_c('tableRelatorio',[_vm._t("default",function(){return [(Object.keys(_vm.dadosRelatorio).length !== 0)?[_c('tr',{staticClass:"backColorTdPDF"},[_c('td',{staticStyle:{"padding":"0px"},attrs:{"colspan":"7"}},[_c('span',{staticClass:"newbadge"},[_vm._v(" "+_vm._s(_vm.valueData)+" ")])])]),_c('tr',[_c('td',{attrs:{"colspan":"7"}},[_c('div',{staticClass:"row col-sm-12 nopadding mt-5"},[_c('div',{staticClass:"col-sm-4 row"},[_c('div',{staticClass:"col-sm-12 nopadding text-center"},[_c('h4',[_vm._v(" Pontualidade ")])]),_c('div',{staticClass:"col-sm-12 nopadding"},[_c('chartsPizza',{attrs:{"widthLegenda":103,"type":"donut","label":['Não Pontual', 'Pontual'],"value":_vm.pontualidade,"colors":['#f0abab', '#bae9a8'],"widthGrafico":650,"larguraGrafico":"50%"},on:{"changeImagem":(v) => _vm.imgBase64 = v}})],1)]),_c('div',{staticClass:"col-sm-4 d-flex flex-column"},[_c('h5',{staticClass:"p-2"},[_vm._v(" Colaboradores com mais pontualidade ")]),_c('div',{staticClass:"div-lista div-colb-pontual p-2 nopadding"},[_c('ul',[_vm._l((_vm.colabMaisPontual),function(
                                                        dados
                                                    ){return [_vm._l((dados),function(val,key){return [_c('li',{key:key,staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v(" "+_vm._s(key)+" ")]),_c('span',[_vm._v(" "+_vm._s(val)+"% ")])])]})]})],2)])]),_c('div',{staticClass:"col-sm-4 d-flex flex-column"},[_c('h5',{staticClass:"p-2"},[_vm._v(" Colaboradores com menos pontualidade ")]),_c('div',{staticClass:"div-lista div-colb-atrasado p-2 nopadding"},[_c('ul',[_vm._l((_vm.colabMenosPontual),function(
                                                        dados
                                                    ){return [_vm._l((dados),function(val,key){return [_c('li',{key:key,staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v(" "+_vm._s(key)+" ")]),_c('span',[_vm._v(" "+_vm._s(val)+"% ")])])]})]})],2)])])])])])]:_vm._e(),_c('tr',{staticClass:"cabecalho"},[_c('th',[_vm._v("Data")]),_c('th',[_vm._v("Ponto previsto")]),_c('th',[_vm._v("Ponto realizado")]),_c('th',[_vm._v("Tempo de atraso")]),_c('th',[_vm._v("Cargo")]),_c('th',[_vm._v("Departamento")]),_c('th',[_vm._v("% de pontualidade")])])]},{"slot":"thead"}),_vm._t("default",function(){return [_c('br'),(Object.keys(_vm.dadosRelatorio).length == 0)?_c('statusInformation',{attrs:{"typeBar":"tr","colspanForTd":"21","statusBar":_vm.statusbar}}):[_vm._l((_vm.dadosRelatorio),function(colaboradores,c){return [(colaboradores.length)?[_c('tr',{key:c+'badge',staticClass:"backColorTdPDF"},[_c('td',{staticStyle:{"padding":"0px"},attrs:{"colspan":"21"}},[_c('span',{staticClass:"newbadge"},[_vm._v(" "+_vm._s(c)+" ")])])]),_vm._l((colaboradores),function(dados,index){return [_c('tr',{key:`${index}_${c}`},[_c('td',[_vm._v(_vm._s(dados.dia))]),_c('td',[_vm._v(_vm._s(dados.previsto))]),_c('td',[_vm._v(_vm._s(dados.realizado))]),_c('td',[_vm._v(_vm._s(dados.tempoAtrasado))]),_c('td',[_vm._v(_vm._s(dados.cargo))]),_c('td',[_vm._v(_vm._s(dados.departamento))]),_c('td',[_vm._v(_vm._s(dados.pontualidade)+"%")])])]}),_c('tr',{key:c+'_totalizadores',staticClass:"totalizador"},[_c('td',{staticClass:"text-left nopadding",attrs:{"colspan":"7"}},[_c('span',{staticClass:"rodaPe text-left"},[_vm._v(" Total de pontualidade: "+_vm._s(colaboradores.at(-1).pontualidade)+"% ")])])])]:_vm._e()]})]]},{"slot":"tbody"})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }