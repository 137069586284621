<template>
<div id='idMenuUser' :class="[{'collapsed' : collapsed}]">

    <div class="Opened" :hidden='collapsed'>
        <div class="imageCard">
            <img class="image" :src="URLBase+'/'+user.urlImage">
        </div>
        <div class="data">
          <div class="name">
              {{user.client}}
          </div>
          <div class="username">
              {{user.name}}
          </div>
        </div>
    </div>
    <div :hidden='!collapsed'>
        <div class="imageCard closed">
            <img class="imageclosed" :src="URLBase+'/'+user.urlImage"  alt="John Doe">
        </div>
    </div>

</div>
</template>
<script lang="ts">
import Vue from 'vue'
import {AuthService} from '../../../Services/auth/Auth.Service'
export default Vue.extend({
    props:{
        collapsed:{
            type:Boolean,
            required:true,default:true
        }

    },data(){
        return{
            user : new AuthService().getUser(),
            URLBase :  process.env.VUE_APP_ROOT 
        }
    }
})
</script>
<style lang="scss">
#idMenuUser{

    .Opened{
    max-height: 150px;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    //background-clip: border-box;
    //border: 1px solid white;
    //border-radius: 0.25rem;
    background-color:transparent !important;
    flex-direction: row;
    }
   
    
}
.imageCard{
    display: flex;
    position: relative; 
    border-color: white;
    background-color:transparent;
    margin: 10px;
    

    display: block;
        text-align: center;
    .image
    {
        background-clip: border-box;
        border: 1px solid white;
        border-radius: 0.25rem;
        height:  100px;
        width: 100px;
    }
    .imageclosed{
        background-clip: border-box;
        border: 1px solid white;
        border-radius: 0.25rem;
        height:  30px;
        width: 30px;
    }
}
.data{
    padding: 5px;
   text-align: center;
     .name{
        font-size: 16px;
        font-weight: 600;
    }
    .username{
        word-break:break-all;
        font-size: 13px;
        font-weight: 200;
    }
}


</style>