<template>
    <panelEagle 
        id="cadEscalasView"
        :loading='loadingPanel'>
        <div class="col-sm-12 nopadding ">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiFileTree" titulo="Escalas">
                    </tituloPage>
                </div>
                <div class="col-sm-6 divDontPrint">
                    <basicButtonsCadastrosEIN
                        :disabledDropdown ="!listagemEscalas.length"
                        :loading          ='carregando'
                        tipoNovo          ="button"
                        @novoCadastro     ="novoCadastro"
                        @exportarCadastro ="exportarEscala">
                        <simpleButton 
                            type      ="green" 
                            text      ="Tarefa" 
                            :icon     ="mdiPlus" 
                            title     ="Cadastrar tarefa" 
                            v-b-modal ="'modal-cadastro-tarefas'">
                        </simpleButton>
                        <modalTarefas ></modalTarefas>
                    </basicButtonsCadastrosEIN>
                </div>
            </div>
            <div class="col-sm-12"><hr></div>
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-4 nopadding divDontPrint">
                    <selectAll 
                        @changeSelect="changeEmpresa" 
                        :isMultiple="true" 
                        nameForRadio="empresasCadEscala" 
                        :hasSelectAll="true" 
                        :labels="[{indexDFH: 'EM', description: 'Empresas*'}]" 
                        :optionsArray="optionsArrayEmpresas"
                        :selected="selectedEmpresa"
                        firstSelected="EM">
                    </selectAll>
                </div>
                <div class="col-sm-7 nopadding divAlert row" align="right">
                    <alertSimple 
                        v-b-modal="'modal-linhas-sem-associacao'" 
                        v-if="horariosAssociados" 
                        :isLoading="false" 
                        type="red" 
                        message="Há linhas cadastradas sem nenhuma associação. Clique para visualizá-las." 
                        :hasClick="true">
                    </alertSimple>
                    <modalHorariosSemAssociacao 
                        :cliente="empresasSelecionadas">
                    </modalHorariosSemAssociacao>
                    <div :class="'col-sm-12 nopadding result-status status-'+returnClass">
                        {{returnStatus}}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 divHrDeBaixo"><hr></div>
            <tableListagem
                widthAcoes="250px"
                @excluirCadastro="excluirEscala" 
                @copiarCadastro="copiarEscala" 
                @visualizarDetalhesCadastro="visualizarDetalhesEscala" 
                @editarCadastro="editarEscala" 
                deleteEdit="detaisCopyEditDelete" 
                :data="listagemEscalas" 
                :titles="titles">
            </tableListagem>
        <modalDetalhes
            :escalaName="escalaName" 
            :escala="escalaDetalhes" 
            :empresa="empresasSelecionadas">
        </modalDetalhes>
    </panelEagle>
</template>

<script>
import Vue from 'vue'
import {EmpresasService} from '../../../../Services/auth/Empresas.service'
import {HttpRequest} from '../../../../Services/auth/HttpRequest.Service'
import { conectionError } from '@/Services/Helpers/swellHeper'
import {mdiFileTree, mdiPlus} from '@mdi/js'
import { mapGetters } from 'vuex'
export default Vue.extend({
    name: 'ListagemEscalas',
    components:{
        'panelEagle'                 : require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage'                 : require('@/components/Atom/Header/Titulo').default,
        'basicButtonsCadastrosEIN'   : require('@/components/Atom/Buttons/BasicButtonsCadastrosEIN').default,
        'selectAll'                  : require('@/components/Atom/Select/SelectAll').default,
        'tableListagem'              : require('@/components/Atom/Table/TableListagem').default,
        'alertSimple'                : require('@/components/Atom/Alerts/AlertSimple').default,
        'simpleButton'               : require('@/components/Atom/Buttons/SimpleButton').default,
        'modalTarefas'               : require('@/components/Atom/ModalSpecific/ModalTarefas').default,
        'modalHorariosSemAssociacao' : require('@/components/Atom/ModalSpecific/ModalHorariosSemAssociacao').default,
        'modalDetalhes'              : require('@/components/Atom/ModalSpecific/ModalDetalhesEscala').default,
    },

    data(){
        return {
            mdiPlus:mdiPlus,
            mdiFileTree:mdiFileTree,
            empresasSelecionadas : [],
            optionsArrayEmpresas : new EmpresasService().Get(),
            selectedEmpresa:[],
            titles:[
                {'name' : 'Código',      'number' : 'codigo', 'width':'100px'},
                {'name' : 'Descrição',   'number' : 'descricao'},
                {'name' : 'Colaborador', 'number' : 'motorista'},
                {'name' : 'Empresa',     'number' : 'empresa'},
            ],
            listagemEscalas    : [],
            horariosAssociados : false,
            returnStatus       : '',
            returnClass        : '',
            escalaDetalhes     : '',
            loadingPanel       : false,
            carregando         : [false, false, false],
            escalaName:'',
        }
    },
    methods:{
        ...mapGetters(['getMaster']),
        novoCadastro(){
            this.$router.push({name: "cadastrarEscalas"})
        },
        changeEmpresa(selecionados){
            this.empresasSelecionadas = selecionados;
            this.listagem();
        },
        async listagem(){
            this.loadingPanel = true
            var data = await new HttpRequest().Post('/coletivos/cadastros/escalas/listagem', 
                {'clientes':this.empresasSelecionadas, 'checkExist': true});
            this.listagemEscalas = data.data.escalas;
            this.horariosAssociados = data.data.horariosSemAssociado == "true" ?
                true : false;
            this.loadingPanel = false
        },
        editarEscala(value){
            this.$router.push({name:'cadastrarEscalas', params:{'escala':value}})
        },
        async excluirEscala(escala){
          var data = await new HttpRequest().Post('/coletivos/cadastros/escalas/excluir', {'escala':escala});
            this.returnStatus = data.data.result.message
            this.returnClass = data.data.result.status
            var modal = this
            this.listagem();
            setTimeout(function(){
                modal.returnStatus = '';
                modal.returnClass = '';
            }, 5000)
        },
        visualizarDetalhesEscala(escala){
            this.escalaName = (this.listagemEscalas.filter(v=>v.codigo == escala))[0].descricao
            this.escalaDetalhes = escala;
            this.$bvModal.show('modal-detalhes-escala');
        },
        copiarEscala(value){
            this.$router.push({name:'cadastrarEscalas', params:{'escala':value, 'copia': 'cp'}})
        },
        async exportarEscala(type){
            var root =  process.env.VUE_APP_ROOT;
            if(this.listagemEscalas.length > 0){
                if(type == 'pdf'){
                    this.carregando = [true, false, false];
                }else if(type == 'csv'){
                    this.carregando = [false, false, true];
                }else{
                     this.carregando = [false, true, false];
                }
                try{
                    var data = await new HttpRequest().Post('/coletivos/cadastros/escalas/exportar', 
                    {'arrayDados':JSON.stringify(this.listagemEscalas), 'tipo':type});
                    window.open(root+'/'+data.data.local);
                }catch{
                    conectionError()
                }
                this.carregando = [false, false, false]
            }
        }
    },
    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optionsArrayEmpresas
        }
    },
})
</script>

<style lang="scss">
    #cadEscalasView{
        .divAlert{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .result-status{
            text-align: center;
            font-size: 12px;
            padding-top: 5px !important;

            &.status-succes{
                color: #95b65c;
            }
            &.status-error{
                color: #e04b4a;
            }
        }
    }
    .divAlert{
        text-align: center;
    }
</style>