    <template>
    <eaglePanel id='ListagemIntegridade' :loading='gerandoRelatorio'>
        <div class="col-sm-12 divDontPrint row">
            <div class="col-sm-6 nopadding row">
                <titulo 
                    :icon='mdiDatabaseAlert'
                    titulo='Integridade'
                />
            </div>
            <div class="col-sm-6 nopadding" align="right">
                <simpleButton
                    text='Gerar'
                    type='green'
                    :icon='mdiThumbUpOutline'
                    event='click'
                    :disabled='$v.integridade.$invalid'
                    @click='gerarRelatorio'
                />
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <div class="col-sm-12 divDontPrint row nopadding">
            <div class="col-sm-3 nopadding">
                <dayPicker 
                    name='dayIntegridade'
                    @changeInput='mudaData'
                />
            </div>
            <div class="col-sm-3 nopadding">
                <selectAll
                    nameForRadio='seletorEmpresaIntegridade'
                    :labels='[{"description":"Empresas"}]'
                    :isMultiple='true'
                    :hasSelectAll='true'
                    :optionsArray='optSelectEmpresa'
                    :selected="selectedEmpresa"
                    @changeSelect='mudaEmpresaRel'
                />
            </div>
            <div class="col-sm-3 row">
                <!-- HTML da legenda sobre o significado da cor de cada 
                bolinha, e eu acredito veementemente que a cor da bolinha de 
                erro é rosa -->
                Legenda
                <div class="col-sm-12 legenda nopadding row text-left">
                    <div class="col-sm-5 nopadding mt-2 mb-2 ml-2">
                        <div class='itemLegenda small'>
                            <span class='circle green'/>
                            Ok
                        </div>
                        <div class='itemLegenda small'>
                            <span class='circle blue'/>
                            Sem dados
                        </div>
                    </div>
                    <div class="col-sm-6 nopadding mt-2 mb-2 mr-2">
                        <div class='itemLegenda small'>
                            <span class='circle pink'/>
                            Problema
                        </div>
                        <div class='itemLegenda small'>
                            <span class='circle gray'/>
                            Não processado
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3 pt-2">
                <!-- Os botões de processamento -->
                <simpleButton
                    v-if="getCadastrar() || getEditar()"
                    :disabled="!relatorio.existemErros()"
                    text='Processar paradas'
                    width='100%'
                    event='click'
                    @click="processamento('PA')"
                />
                <simpleButton
                    v-if="getCadastrar() || getEditar()"
                    :disabled="!relatorio.existemErros()"
                    text='Processar ignição'
                    width='100%'
                    event='click'
                    @click="processamento('IG')"
                />
            </div>
        </div>
        <div class="col-sm-12"><hr></div>
        <div class="col-sm-12">
            <tableSimple>
                <thead style='z-index:1000 !important'>
                    <tr>
                        <th>Veiculo</th>
                        <th>Paradas</th>
                        <th>Ignições</th>
                        <th>Pacotes</th>
                        <th>Status Processamento</th>
                        <th class='item' :class="!relatorio.existemErros() ? 'cursor-not-allowed' : ''">
                            <b-checkbox
                                v-if="getCadastrar() || getEditar()"
                                :disabled="!relatorio.existemErros()"
                                @change='selecionaTodos'
                                v-model='selecionaTodosModel'
                                :unchecked-value='false'
                                :value='true'>
                                Selecionar Todos
                            </b-checkbox>
                        </th>
                    </tr>
                </thead>
                <tbody v-if="relatorio.show()"> 
                    <br>
                    <tr v-for='(int, index) in relatorio' :key='index+"--table_integridade"'>
                        <td>
                            {{index}}
                        </td>
                        <td>
                            <span 
                                class="circle"
                                :class='int.paradaCor ? int.paradaCor : "blue"'
                                :title='int.legendaParadas'
                            />
                        </td>
                        <td>
                            <span 
                                class="circle"
                                :class='int.ignicaoCor ? int.ignicaoCor : "blue"'
                                :title='int.legendaIgnicao'
                            />
                        </td>
                        <td>
                            <span 
                                class="circle"
                                :class='int.pacotesCor ? int.pacotesCor : "blue"' 
                                :title="int.legendaPacote"
                            />
                        </td>
                        <td>
                            <span 
                                class="circle"
                                :class='int.processamentoCor ? int.processamentoCor : "blue"'
                            />
                        </td>
                        <td :class="!int.existeErro ? 'cursor-not-allowed pl-2' : 'pl-2'">
                            <b-form-checkbox
                                v-if="getCadastrar() || getEditar()"
                                :key="int.placa"
                                v-model="int.paraProcessar"
                                style="z-index:0"
                                :disabled="!int.existeErro" 
                                @change='v => changeCheckBoxTabela(v, int.placa)'
                            />
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <statusbar 
                        typeBar='tr' 
                        statusBar='info' 
                        colspanForTd='6'
                        msgCentro='Selecione ao menos uma empresa para gerar.'
                    />
                </tbody>
            </tableSimple>
        </div>
    </eaglePanel>
</template>
<script>
import {mdiThumbUpOutline, mdiDatabaseAlert } from '@mdi/js'
import { required } from 'vuelidate/lib/validators'
import eaglePanel from '@/components/Atom/Panel/PanelEagle'
import Relatorio from './relatorio.js'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { mapGetters } from 'vuex'

export default {
    name:'integridade',
    components:{
        eaglePanel,
        tableSimple   :require('@/components/Atom/Table/TableSimples').default,
        simpleButton  :require('@/components/Atom/Buttons/SimpleButton').default,
        titulo        :require('@/components/Atom/Header/Titulo').default,
        dayPicker     :require('@/components/Atom/Datas/InputSingleDay').default,
        selectAll     :require('@/components/Atom/Select/SelectAll').default,
        statusbar     :require('@/components/Atom/StatusInformation/StatusInformation').default
    },
    validations:{
        integridade:{
            cliente: { required },
            data   : { required }
        }
    },
    data(){
        return {
            mdiDatabaseAlert:mdiDatabaseAlert,
            mdiThumbUpOutline:mdiThumbUpOutline,
            uriBase:'/controle/jornada/integridade/',
            optSelectEmpresa: new EmpresasService().Get(),
            gerandoRelatorio:false,
            integridade:{
                cliente:'',
                data:''
            },
            dataIntegridade:[],
            titulosTable:[
                {'number':'1', 'name':'Veiculo'},
                {'number':'2', 'name':'Paradas'},
                {'number':'3', 'name':'Ignições'},
                {'number':'4', 'name':'Pacotes'},
                {'number':'5', 'name':'Status Processamento'},
            ],
            relatorio: new Relatorio(),
            tamIntegridade:0,
            selecionaTodosModel:false,
            value : 0,
            placas : [],
            selectedEmpresa: [],
        }
    },
    methods: {
        ...mapGetters(['getMaster', 'getCadastrar', 'getEditar']),
        /**
         * Força a tabela a trocar o valor do check, único modo que achei para alterar o valor da variável
         * Se conseguir fazer melhor parabéns
         * @author Vitor Hugo 🐨
         */
        changeCheckBoxTabela(v, indice){
            this.relatorio[indice].paraProcessar = v
            this.$forceUpdate()
        },

        /**
         * Seleciona ou desseleciona o todos os veiculos
         * que tem algum erro que pode ser resolvido pela
         * página. Depois disso força os elementos a se
         * atualizar de acordo com seus modelos
         * @author Gui 🍺🍺🍺🍺🍺
         */
        selecionaTodos(value){
            this.relatorio.selecionarTodosComErro(value)
            this.$forceUpdate()
        },

        /**
         * Cria a array com todas as placas que devem ser
         * processadas e chama a função que executa o request
         * e faz o update individualmente para cada placa na array
         */
        processamento(tipo){
            let dadosSelecionados = this.relatorio.getTodosProcessar(tipo)
            if(dadosSelecionados.processar.length){ 
                this.gerandoRelatorio = true
                this.tamIntegridade = dadosSelecionados.processar.length
            }
            dadosSelecionados.processar.forEach(p=> this.fazRequestProcessamento(p, tipo))
            dadosSelecionados.checks.forEach((placa)=>{
                this.relatorio[placa].paraProcessar = false
                this.$forceUpdate()
            })
            this.selecionaTodosModel = false
        },

        /**
         * Faz a request para reprocessar os dados da placa
         * e uma vez que a request volte, faz um update nos dados
         * do relatório
         * @param placa do veiculo a ser reprocessado
         * @param tipo 'PA' para paradas e 'IG' para ignição
         * @author Gui 🍺🍺
         */
        fazRequestProcessamento(placa, tipo){
            this.gerandoRelatorio = true
            let uri = this.uriBase+'processar'
            let obj = {
                'data'    : this.integridade.data,
                'tipoProc':tipo,
                'carro'   :placa
            }
            new HttpRequest().Post(uri, obj)
            .then(resposta=>{
                if(resposta.data.integridade.length != 0)
                    this.relatorio.updateData(placa, resposta.data.integridade[placa])
                this.tamIntegridade -= 1
                if(this.tamIntegridade == 0 ){
                    this.gerandoRelatorio = false
                }
            })
        },
        
        /**
         * Faz a primeira request que traz todas as placas
         * do usuário e depois faz uma request para cada placa
         * procurando pela integridade dos dos dados de cada uma
         */
        async gerarRelatorio() {
            this.relatorio = new Relatorio()
            this.gerandoRelatorio = true
            let uri = this.uriBase+'gerarRel'
            var placas = []
            var dados = await new HttpRequest().Post(uri, {...this.integridade})
                if(dados.status){
                    this.relatorio = new Relatorio(...dados.data.integridade)
                    this.tamIntegridade = dados.data.tamIntegridade
                    dados.data.integridade.forEach(i=>{
                        placas.push(i)
                    })
                    this.verificaIntegridade(placas)
                }
        },
        
        /**
         * Faz o request que traz as informações
         * das placas e depois
         * adiciona essas informações no objeto
         * relatório de acordo com a placa
         * @param Array placas = todas as placas do cliente
         * @return Objeto reletorio = atualiza o relatório com as novas informações
         * @author Vitor Hugo 🐨
         */
        async verificaIntegridade(placas){
            if(placas.length > 0){
                var placa = []
                var qntPlacasVez = 50
                    for (let index = 0; index < qntPlacasVez; index++) {
                        if(placas[index] != undefined) {
                            placa.push(placas[index])
                        }
                    }
                let uri = this.uriBase+'verificar/pacotes'
                let obj = {
                    'placa':placa,
                    'data':this.integridade.data
                }
                new HttpRequest().Post(uri, obj)
                .then(data=>{
                    for (const key in data.data) {
                        this.relatorio.addData(placa[key], data.data[key])
                    }
                    if(placas.length > 0){
                        placas.splice(0, qntPlacasVez)
                        setTimeout(() => {
                            this.verificaIntegridade(placas)
                        }, 200);
                    }
                })
            } else {
                this.gerandoRelatorio = false
            }
            
            
        },

        /**
         * Muda a empresa sobre a qual o relatório seja gerado
         * e já reseta o relatório em si
         */
        mudaEmpresaRel(arr){    
            this.relatorio = new Relatorio()
            this.integridade.cliente = arr
        },
        /**
         * Muda a data do "quando" o relatório deve buscar
         * informações sobre os dados da empresa
         */
        mudaData(value){
            this.integridade.data = value
        },
    },

    mounted() {
        if(!this.getMaster()){
            this.selectedEmpresa = this.optSelectEmpresa
        }
    }
}
</script>
<style lang="scss" scoped>
.acoes{
    margin: 5px
}
.legenda{
    // min-width: 310px;
    font-size: 12px;
    background-color: #dae1e9;
    .itemLegenda{
        // margin: 2%
    }
}
.circle{
    padding-right: 20px;
    margin-right: 7px;
    // border: 2px solid #fff;
    border-radius: 100%;  
}
.green{
    background-color: #a0da7c;
}
.blue{
    background-color: #83b4dd;
}
.pink{
    background-color: #f8a1a2;
}
.gray{
    background-color: #b5b5b5;
}
.cursor-not-allowed{
    cursor: not-allowed;
}

</style>