<template>
    <div id='indicador-peso-veiculo'>
        <b-progress :max="100" height="25px" animated :title='`${validaPorcentagem()} do peso máximo`'>
            <b-progress-bar :value="validaPorcentagem()" variant="info"></b-progress-bar>
            <div class="percentil-peso">
                {{ validaPorcentagem() }}
            </div>
        </b-progress>
    </div>
</template>

<script>
export default {
    props: {
        rota: {
            required: true,
            type: Object,
        }
    },
    methods:{
        /**
        * @description valida porcentagem para tratar casos que os valores
        * são nulos
        * @author Otávio 🦆 
        */
        validaPorcentagem() {
            if (this.rota.utilizacaoVeiculo == null || isNaN(this.rota.utilizacaoVeiculo)){
                return '0.00%'
            }
            return `${this.rota.utilizacaoVeiculo.toFixed(2)}%`
        }
    },
    computed: {
        pesoPercentual() {
            return `${this.rota.utilizacaoVeiculo.toFixed(2)}%`;
        }
    },
}
</script>


<style scoped>
#indicador-peso-veiculo {
    .percentil-peso {
        position: absolute;
        top: 50%; 
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 11px;
    }

    .progress-bar {
        color: black !important;
        font-size: 13px;
    }
}
</style>