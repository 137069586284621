/**
    Este é o componente básico com os botões de edição e exclusão com confirmação de exclusão.

    -> Propriedades:
        1- id - código do cadastro em questão - EX: '2'
    
    -> Slot:
        1- Sem Nome: espaço para colocar algo a mais

    -> Eventos:
        1- editarCadastro - Emite quando o botão de editar for clicado - Parametros:
            1.1- id - Código do cadastro clicado
        2- excluirCadastro - Emite quando a exclusão do cadastro for confirmada na modal - Parametros:
            2.1- id - Código do cadastro clicado
 */

<template>
    <div>
        <slot></slot>
        <simpleButton 
        v-if="editarVal" 
        @editarClick="editar(id)" 
        event="editarClick" 
        width="50px" 
        title="Editar" 
        type="green" 
        :icon="iconEditar"/>
        <simpleButton 
        v-if="excluirVal" 
        v-b-modal="'modal-deleta-'+id" 
        event="excluirClick" 
        type="red" 
        width="50px" 
        title="Excluir" 
        :icon="iconExcluir"/>
        <modalDeleta 
        @confirmaExclusao="ExcluirClick" 
        :id="id"></modalDeleta>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
import Vuex from '../../../store/index'
import {mdiPencilOutline, mdiTrashCanOutline} from '@mdi/js'
export default Vue.extend({
        components: {
            'simpleButton': require('./SimpleButton').default,
            'modalDeleta': require('../ModalSpecific/ModalDeleta').default,
        },
        props:{
            id:{
                type: [String, Number],
                required: true,
                default: ''
            },
        },
        data() {
            return {
                iconEditar: mdiPencilOutline,
                iconExcluir: mdiTrashCanOutline
            }
        },
        methods: {
            editar(id:any){
                this.$emit('editarCadastro', id)
            },
            ExcluirClick(id:any){
                this.$emit('excluirCadastro', id);
            },
        },
        computed:{
            editarVal: function(){
                return Vuex.getters.getEditar
            },
            excluirVal: function(){
                return Vuex.getters.getExcluir
            }
        }
})
</script>

<style lang="scss">
</style>